import React from 'react';
import * as PropTypes from 'prop-types';
import { CellOptions, RowDescription, RowInfos, RowLine, Line, Td } from './styles';
import Dropdown, { Item, List } from 'components/Dropdown';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { compareDates, formattedDate } from './utils';

function TableItem({ data, onClickDelete, onClickUpdate }) {
  const status = compareDates(data.expires_at);

  return (
    <>
      <RowLine>
        <Td colSpan={7}>
          <Line />
        </Td>
      </RowLine>
      <RowInfos>
        <Td>{formattedDate(data.created_at)}</Td>
        <Td>
          <span className={status || null ? "h-color--success" : "h-color--danger"}>{status || null ? 'Ativo' : 'Inativo'}</span>
        </Td>
        <Td>{data.expires_at ? data.expires_at : 'Indeterminado'}</Td>
        <Td>{data.created_by.name}</Td>
        <Td>{data.from_user.name}</Td>
        <Td>{data.to_user.name}</Td>
        <CellOptions rowSpan={2}>
          <Dropdown isDropHover>
            <Button size="medium" color="white">
              Opções <Icon name="chevron-down" />
            </Button>
            <List>
              <Item>
                <button 
                  type="button" 
                  onClick={onClickUpdate}>Editar</button>
              </Item>
              <Item>
                <button 
                  type="button" 
                  onClick={onClickDelete}>Excluir</button>
              </Item>
            </List>
          </Dropdown>
        </CellOptions>
      </RowInfos>
      <RowDescription>
        <td>
          <strong>Observação</strong>
        </td>
        <td colSpan={5}>{data.note}</td>
      </RowDescription>
    </>
  );
}

TableItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export default TableItem;
