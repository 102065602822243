import React, { createContext, useContext } from 'react';
import { Spacer, Table } from './styles';
import { useContracts } from 'pages/PlanConfig/hooks/useContracts';
import MainContract from './components/MainContract';
import SubContract from './components/SubContract';
import Nodata from './components/Nodata';

const ContractContext = createContext({
  data: null,
  fetchData: () => null,
});

export const useContractContext = () => useContext(ContractContext);

function ListContracts() {
  const { data } = useContracts();

  // Verifica se a lista de contratos está vazia
  if (!data || data.length <= 0) return <Nodata />;

  return (
    <Table>
      <tbody>
        {data.map((contract, i) => {
          const length = contract.children.length;
          const isLast = !contract.children || length <= 0;

          return (
            <>
              <MainContract
                isFirst={i === 0}
                key={contract.id}
                data={contract}
                className={isLast && 'contract--last'}
              />
              {contract.children &&
                contract.children.map((subContract, index) => {
                  const isLast = length - 1 === index;

                  return (
                    <SubContract
                      openOnStart={i === 0 && isLast}
                      key={subContract.id}
                      data={subContract}
                      className={isLast && 'contract--last'}
                    />
                  );
                })}
              <Spacer />
            </>
          );
        })}
      </tbody>
    </Table>
  );
}

export default ListContracts;
