import React from 'react';
import PropTypes from 'prop-types';
import Round from '../Round';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.string,
  hasData: PropTypes.bool,
  renderNoData: PropTypes.func
};

const defaultProps = {
  renderNoData: () => <>Nenhuma informação</>
};

export const Title = ({ children, color, icon: Icon, ...props }) => (
  <div className={'Panel__title'}>
    {Icon && (
      <Round size="small" color={color} className="Panel__icon">
        <Icon />
      </Round>
    )}
    <div {...props} className="h5 h-color--primary">
      {children}
    </div>
  </div>
);

export const Content = ({ children }) => (
  <div className="Panel__content">{children}</div>
);

const NoData = ({ children }) => (
  <div className="Panel__no-data h-bg--white-light">
    <div className="Panel__box-no-data h-bg--white h-center--absolute h-text-center">
      {children}
    </div>
  </div>
);

const Panel = ({
  children,
  className,
  title,
  icon,
  color,
  hasData,
  renderNoData,
  ...props
}) => (
  <div
    className={classnames('Panel', className, {
      [`Panel--no-data`]: !hasData
    })}
    {...props}
  >
    <Title color={color} icon={icon}>
      {title}
    </Title>
    <Content>{children}</Content>
    {!hasData && <NoData>{renderNoData()}</NoData>}
  </div>
);

Panel.propTypes = propTypes;
Panel.defaultProps = defaultProps;

export default Panel;
