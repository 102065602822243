import styled from 'styled-components';
import { rgba } from 'polished';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: grid;
  place-items: center;

  background-color: ${p => rgba(p?.color, p.alpha)};

  cursor: ${p => (!!p.onClick ? 'pointer' : 'default')};
  border-radius: inherit;
`;
