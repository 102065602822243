import { combineReducers } from 'redux';
import { createModule } from 'lib/reducer-helpers';
// Modules
import dataReducer from 'modules/data';
// Services
import * as propertiesService from 'services/properties';
import * as peopleService from 'services/people';
import { openConfirmation } from 'containers/ModalConfirmation/module';

// Cre
const createAction = createModule('propertiesMatched');

// Selectors
export const propertySelector = state =>
  state.propertyPeopleMatched.property.data;

export const propertyIsFetchingSelector = state => {
  return state.propertyPeopleMatched.property.isFetching;
};

const REQUEST_PROPERTY = createAction('property/REQUEST_PROPERTY');
const RECEIVE_PROPERTY = createAction('property/RECEIVE_PROPERTY');
const RESET = createAction('property/RESET');

export const {
  selectors: peopleSelectors,
  reducer: peopleReducer,
  actionCreators: peopleActions
} = dataReducer(`propertyPeopleMatched/people`);

const initialStateProperty = {
  data: {
    images: []
  },
  isFetching: false
};

export const reducerProperty = (state = initialStateProperty, action) => {
  switch (action.type) {
    case REQUEST_PROPERTY:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_PROPERTY:
      return {
        ...state,
        data: action.property,
        isFetching: false
      };
    case RESET:
      return initialStateProperty;
    default:
      return state;
  }
};

// Action creators
export const requestProperty = () => ({ type: REQUEST_PROPERTY });

export const receiveProperty = property => ({
  type: RECEIVE_PROPERTY,
  property
});

// Volta para o estado inicial
export const reset = () => ({ type: RESET });

// Thunks

/**
 * Reseta as informações para o estado inicial
 * @return {Function}
 */
export const resetData = () => dispatch => {
  dispatch(reset());
  dispatch(peopleActions.reset());
};

export const getInitalData = ({ property_id, ...params }) => dispatch => {
  // Limpa todas as informações
  resetData()(dispatch);

  dispatch(peopleActions.request());
  dispatch(requestProperty());

  return propertiesService
    .getOne(property_id, {
      ...params,
      include: 'people_matched',
      disable_cache: true
    })
    .then(res => {
      dispatch(receiveProperty(res.data));
      dispatch(peopleActions.receive(res?.data?.people_matched));
      return res;
    });
};

/**
 * Descarta um perfil de interesse
 * @param {Object} person
 * @return {Function}
 */
export const fetchDiscardMatched = person => dispatch => {
  // Marca que está deletando um cliente
  dispatch(peopleActions.removing(person.id));

  return peopleService
    .discardMatched(person.id, person.matched_id)
    .then(({ data }) => {
      dispatch(peopleActions.remove(person.id));
      return data;
    });
};

/**
 * Desmarca multiplos clientes como compatíveis
 * @param propertyId
 * @param matchedIds
 * @param callBack
 * @param title
 * @param submitButtonText
 * @returns {function(): Promise<AxiosResponse<*>>}
 */
export const discardMultipleMatched = ({
  propertyId,
  matchedIds,
  callBack,
  title = 'Deseja realmente descartar?',
  submitButtonText = 'Descartar'
}) => async dispatch => {
  dispatch(
    openConfirmation({
      title,
      submitButtonText,
      request: () =>
        peopleService.removeMultipleMatcheds(propertyId, matchedIds),
      onSuccess: () => {
        if (callBack) callBack();
      }
    })
  );

  return;
};

export default combineReducers({
  people: peopleReducer,
  property: reducerProperty
});
