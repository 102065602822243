import React from 'react';
import Button from 'components/Button';
import { useEnterprise } from 'pages/EnterpriseStore/context';

const SeeExample = () => {
  const { previewUrl } = useEnterprise();

  return (
    <Button
      tagName="a"
      target="_blank"
      href={previewUrl}
      color="white"
      colorText="secondary"
      className="js-button-preview"
    >
      Ver sua página
    </Button>
  );
};

export default SeeExample;
