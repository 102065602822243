import React from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const PeopleChart = ({ width, height, data }) => (
  <div style={{ width, height }}>
    <ResponsiveContainer>
      <AreaChart
        width={600}
        height={200}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="ga:yearMonth" />
        <YAxis />
        <Tooltip cursor={{ fill: '#f0f2f5' }} />
        <Area type="monotone" dataKey="Total de usuários" fill="#c4d9fd" />
        <Area type="monotone" dataKey="Novos usuários" fill="red" />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

export default PeopleChart;
