import { Input } from "components/Form";
import { CityField, CountryField, StateField } from "containers/LocationFields";
import useFormValue from "hooks/useFormValue";
import { Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";
import PropTypes from 'prop-types';
import { ModalFooter } from "components/Modal";
import Button from "components/Button";

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function FormAddZone({ handleSubmit, handleClose, change }) {
  const countryId = useFormValue('country_id');
  const stateId = useFormValue('state_id');
  const cityId = useFormValue('city_id');

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <CountryField
          required
          xs={6}
          label="País"
          name="country_id"
          stateName="state_id"
        />
        <StateField
          required
          xs={6}
          label="UF"
          name="state_id"
          countryId={countryId}
        />
      </Row>
      <Row>
        <CityField required xs={6} name="city_id" stateId={stateId} />
        <Field
          xs={6}
          required
          label="Zona"
          name="name"
          component={Input}
          placeholder="Ex.: Zona sul"
          cityId={cityId}
        />
      </Row>

      <ModalFooter style={{ marginTop: '0px' }}>
        <Button color="white" colorText="secondary" onClick={handleClose}>
          Sair
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Cadastrar
        </Button>
      </ModalFooter>
    </form>
  );
}

FormAddZone.defaultProps = defaultProps;
FormAddZone.propTypes = propTypes;

export default reduxForm({
  form: 'FormAddZone',
  enableReinitialize: true
})(FormAddZone);

