import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.node,
  divider: PropTypes.bool,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['small', 'large'])
  ]),
  children: PropTypes.node
};
const defaultProps = {
  label: null,
  divider: false,
  size: 'large',
  children: null
};

const GroupItem = ({ divider, label, size, children }) => {
  let styles = {};

  if (typeof size === 'number') {
    styles = { ...styles, width: size };
  }

  return (
    <div
      style={styles}
      className={classnames('GroupControl__item', {
        'GroupControl__item--divider': divider,
        'GroupControl__item--label': label,
        'h-flex__cell--grow': size === 'large',
        'h-flex__cell--shrink': size === 'small'
      })}
    >
      {label || children}
    </div>
  );
};

GroupItem.propTypes = propTypes;
GroupItem.defaultProps = defaultProps;

export default GroupItem;
