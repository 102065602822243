import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { change, Field } from 'redux-form';
// Components
import { Input, SelectMultiple } from 'components/Form';
// Service
import * as locationService from 'services/location';
import * as cepsService from 'services/ceps';
// Helpers
import { normalizeCep, parseSelect } from 'lib/formHelpers';

const initialState = { data: [], isFetching: false };
const defaultInputProps = {
  multi: false,
  labelKey: 'name',
  valueKey: 'id',
  parse: parseSelect('id'),
};

class OfficeLocationFields extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,

    countryId: PropTypes.string.isRequired,
    stateId: PropTypes.string.isRequired,
    cityId: PropTypes.string.isRequired,
    neighborhoodId: PropTypes.string.isRequired,

    change: PropTypes.func.isRequired,
  };

  static defaultProps = {
    name: null,

    countryId: null,
    stateId: null,
    cityId: null,
    neighborhoodId: null,
    change: null,
  };

  state = {
    countries: initialState,
    states: initialState,
    cities: initialState,
    neighborhoods: initialState,
  };

  get countryId() {
    return `${this.props.name}.country_id`;
  }

  get stateId() {
    return `${this.props.name}.state_id`;
  }

  get cityId() {
    return `${this.props.name}.city_id`;
  }

  get neighborhoodId() {
    return `${this.props.name}.neighborhood_id`;
  }

  componentDidMount() {
    const { countryId, stateId, cityId } = this.props;

    this.fetchCountries();

    // Busca os estados se tiver
    if (countryId) this.fetchStates(countryId);
    if (stateId) this.fetchCities(stateId);
    if (cityId) this.fetchNeighborhoods(cityId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.countryId !== null &&
      this.props.countryId !== prevProps.countryId
    ) {
      this.fetchStates(this.props.countryId);
    }

    if (
      this.props.stateId !== null &&
      this.props.stateId !== prevProps.stateId
    ) {
      this.fetchCities(this.props.stateId);
    }

    if (this.props.cityId !== null && this.props.cityId !== prevProps.cityId) {
      this.fetchNeighborhoods(this.props.cityId);
    }
  }

  resetByKey = (key) => {
    this.setState({
      [key]: initialState,
    });
  };

  /**
   * Seta o carregamento de uma chave específica
   * @param key
   * @param loading
   */
  setLoadingByKey = (key, loading) => {
    this.setState({
      [key]: {
        ...this.state[key],
        isFetching: loading,
      },
    });
  };

  /**
   * Seta os dados de uma chave
   * @param key
   * @param data
   */
  setDataByKey = (key, data) => {
    this.setState({
      [key]: {
        ...this.state[key],
        data,
      },
    });
  };

  // Requests
  fetchCountries = async () => {
    this.setLoadingByKey('countries', true);
    const { data } = await locationService.getCountries();
    this.setLoadingByKey('countries', false);
    this.setDataByKey('countries', data);
  };

  fetchStates = async (country_id) => {
    this.setLoadingByKey('states', true);
    const { data } = await locationService.getStates({ country_id });
    this.setLoadingByKey('states', false);
    this.setDataByKey('states', data);
  };

  fetchCities = async (state_id) => {
    this.setLoadingByKey('cities', true);
    const { data } = await locationService.getCities({ state_id });
    this.setLoadingByKey('cities', false);
    this.setDataByKey('cities', data);
  };

  fetchNeighborhoods = async (city_id) => {
    this.setLoadingByKey('neighborhoods', true);
    const { data } = await locationService.getNeighborhoods({ city_id });
    this.setLoadingByKey('neighborhoods', false);
    this.setDataByKey('neighborhoods', data);
  };

  // Event Handlers
  onChangeCountry = (e, countryId) => {
    const { change } = this.props;
    this.resetByKey('states');
    this.resetByKey('cities');
    this.resetByKey('neighborhoods');
    change(this.stateId, null);
    change(this.cityId, null);
    change(this.neighborhoodId, null);

    if (countryId) {
      this.fetchStates(countryId);
    }
  };

  onChangeState = (e, stateId) => {
    const { change } = this.props;
    this.resetByKey('cities');
    this.resetByKey('neighborhoods');
    change(this.cityId, null);
    change(this.neighborhoodId, null);

    if (stateId) {
      this.fetchCities(stateId);
    }
  };

  onChangeCity = (e, cityId) => {
    const { change } = this.props;
    this.resetByKey('neighborhoods');
    change(this.neighborhoodId, null);

    if (cityId) {
      this.fetchNeighborhoods(cityId);
    }
  };

  onChangeCep = async (e, cep, prevCep) => {
    const { change, name } = this.props;

    if (!cep || cep === prevCep) return false;

    this.resetByKey('states');
    this.resetByKey('cities');
    this.resetByKey('neighborhoods');

    change(this.countryId, null);
    change(this.stateId, null);
    change(this.cityId, null);
    change(this.neighborhoodId, null);

    if (cep.length === 9) {
      const {
        data: {
          country,
          state,
          city,
          neighborhood,
          street_address,
          street_number,
        },
      } = await cepsService.getLocation({ cep });

      if (country) change(this.countryId, country.id);
      if (state) change(this.stateId, state.id);
      if (city) change(this.cityId, city.id);
      if (neighborhood) change(this.neighborhoodId, neighborhood.id);

      if (street_address) change(`${name}.address_street`, street_address);

      if (street_number) change(`${name}.address_number`, street_number);
    }
  };

  render() {
    const { countries, states, cities, neighborhoods } = this.state;

    const { name } = this.props;

    return (
      <Row>
        <Field
          xs={2}
          label="CEP"
          name={`${name}.address_cep`}
          format={normalizeCep}
          component={Input}
          placeholder="Digite seu CEP"
          onChange={this.onChangeCep}
        />
        <Field
          {...defaultInputProps}
          xs={2}
          label="País"
          disabled={!countries.data.length}
          name={this.countryId}
          options={countries.data}
          isLoading={countries.isFetching}
          component={SelectMultiple}
          onChange={this.onChangeCountry}
        />
        <Field
          {...defaultInputProps}
          xs={2}
          label="UF"
          disabled={!states.data.length}
          name={this.stateId}
          options={states.data}
          isLoading={states.isFetching}
          component={SelectMultiple}
          onChange={this.onChangeState}
        />
        <Field
          {...defaultInputProps}
          xs={3}
          label="Cidade"
          disabled={!cities.data.length}
          name={this.cityId}
          options={cities.data}
          isLoading={cities.isFetching}
          component={SelectMultiple}
          onChange={this.onChangeCity}
        />
        <Field
          {...defaultInputProps}
          xs={3}
          label="Bairro"
          disabled={!neighborhoods.data.length}
          name={this.neighborhoodId}
          options={neighborhoods.data}
          isLoading={neighborhoods.isFetching}
          component={SelectMultiple}
        />
      </Row>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators({ changeField: change }, dispatch)
)(OfficeLocationFields);
