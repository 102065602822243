import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Modules
import {
  openModalOrulo,
  openModalOruloDisconnect,
  openModalOruloTerms,
} from 'modules/modal';
// Hooks
import { useOruloIntegration } from './useOruloIntegration';
// Components
import ListOptions from 'components/ListOptions';
import Button from 'components/Button';
// Assets
import imageOrulo from './orulo.png';
import TextLoading from 'components/TextLoading';
import { useHistory } from 'react-router';
import { useRemoveContract } from 'hooks/useRemoveContract';
import { changeDate, ITEM_TYPES } from 'services/financialv2/contracts';
import Alert from 'react-s-alert';

const DotsLoading = ({ width = 11, timeout = 500 }) => {
  const [dots, setDots] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((value) => value + '.');
    }, timeout);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (dots.length > 3) {
      setDots('.');
    }
  }, [dots]);

  return <span style={{ display: 'inline-block', width }}>{dots}</span>;
};

function Options({
  orulo,
  onConnect,
  onDisconnect,
  currentItems,
  fetchCurrentItems,
}) {
  const dispatch = useDispatch();
  const { handleOpenModal } = useRemoveContract({
    item: ITEM_TYPES.ORULO,
    onSuccess: ({ should_change_contract }) => {
      dispatch(
        openModalOruloDisconnect({
          should_change_contract,
          onSubmitSuccess: onDisconnect,
        })
      );
    },
  });

  const handleClickConnect = useCallback(() => {
    function onAccept() {
      dispatch(openModalOrulo({ onSubmitSuccess: onConnect }));
      fetchCurrentItems();
    }

    dispatch(openModalOruloTerms({ onAccept }));
  }, [dispatch, onConnect]);

  const handleClickConnectPayed = useCallback(() => {
    dispatch(openModalOrulo({ onSubmitSuccess: onConnect }));
  }, [dispatch, fetchCurrentItems]);

  const handleClickDisconnect = useCallback(() => {
    handleOpenModal({
      isCancel: false,
    });
  }, [dispatch, onDisconnect]);

  const handleClickCancel = useCallback(() => {
    handleOpenModal({
      isCancel: true,
      onSuccess: async () => {
        await changeDate({ item: ITEM_TYPES.ORULO, quantity: 0 });
        Alert.success('Órulo cancelado');
        fetchCurrentItems();
      },
    });
  }, [dispatch]);

  if (!orulo && currentItems?.has_orulo) {
    return (
      <>
        <Button
          color="danger"
          onClick={() => {
            handleClickCancel();
          }}
        >
          Cancelar
        </Button>
        <Button color="success" onClick={handleClickConnectPayed}>
          Conectar
        </Button>
      </>
    );
  }

  if (!orulo) {
    return (
      <Button color="success" onClick={handleClickConnect}>
        Contratar
      </Button>
    );
  }

  if (orulo?.is_importing) {
    return (
      <div className="h-flex h-flex--center-center">
        <span className="h-margin-right--5">
          Importando Empreendimentos <DotsLoading />
        </span>
        <Button color="danger" onClick={handleClickDisconnect}>
          Desconectar
        </Button>
      </div>
    );
  }

  return (
    <>
      <Button color="white" colorText="secondary" to="/integrations/orulo">
        Editar
      </Button>
      <Button color="danger" onClick={handleClickDisconnect}>
        Desconectar
      </Button>
    </>
  );
}

function OruloIntegration({ currentItems, fetchCurrentItems }) {
  const history = useHistory();
  const { orulo, fetchOrulo, isFetching } = useOruloIntegration();

  const onDisconnect = useCallback(() => {
    fetchOrulo();
    fetchCurrentItems();
  }, []);

  const onConnect = useCallback(() => {
    history.push('/integrations/orulo');
  }, []);

  return (
    <ListOptions.item
      isLoading={isFetching}
      image={imageOrulo}
      title="Rede Órulo"
      text="Conecte-se ao Órulo para encontrar imóveis de incorporadoras."
      renderOptions={() => {
        if (isFetching) return <TextLoading width={120} height={35} />;
        return (
          <Options
            currentItems={currentItems}
            fetchCurrentItems={fetchCurrentItems}
            orulo={orulo}
            onConnect={onConnect}
            onDisconnect={onDisconnect}
          />
        );
      }}
    />
  );
}

export default OruloIntegration;
