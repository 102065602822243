import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega as informações de site relacionadas ao rodapé
 * @param params
 * @return {Promise}
 */
export const getOne = params =>
  api.getOne('settings/sites/homepages', '', params);

/**
 * Atualiza as informações do site relacionadas ao rodapé
 * @param values
 * @return {Promise}
 */
export const update = values =>
  api
    .update('settings/sites/homepages', values)
    .then(responseMessage('Salvo!'));

/**
 * Retorna os modelos de rodapé
 * @param params
 * @return {Promise}
 */
export const getComponents = params =>
  api.getList('settings/component-examples/cards', params);

/**
 * Busca todos os modelos de componente de detalhes
 * @param params
 * @return {*|{params}}
 */
export const getComponentsDetails = params =>
  api.getList('settings/component-examples/property-details', params);

/**
 * Atualiza o modelo do detalhe de imóvel
 * @param values
 * @return {*}
 */
export const updateDetails = values =>
  api
    .update('settings/sites/property-details', values)
    .then(responseMessage('Salvo!'));

/**
 * Pega as informações da página de imóvel
 * @return {*|{params}}
 */
export const getPropertyDetails = () =>
  api.getOne('settings/sites/property-details');
