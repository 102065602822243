// Services
import * as dealsService from 'services/deals';
// Modules
import dataReducer from 'modules/data';

// Constants
export const MODULE_NAME = 'propertyRentExpired';

export const {
  reducer: propertyRentExpiredReducer,
  actionCreators: propertyRentActions
} = dataReducer(`propertyDashboard/${MODULE_NAME}`);

// Selectors
export const dataSelector = state => state.propertyDashboard[MODULE_NAME].data;
export const isFetchingSelector = state =>
  state.propertyDashboard[MODULE_NAME].meta.isFetching;

// Reducer
export const fetchPropertyRentExpired = params => dispatch => {
  dispatch(propertyRentActions.request());

  return dealsService.getDealsByExpiringRentalContracts().then(({ data }) => {
    dispatch(propertyRentActions.receive(data));
    return data;
  });
};

/**
 * Submit do formulário de renovação
 * @param values
 */
export const handleSubmitRenew = values => dispatch =>
  dealsService.updateDealEarned({
    id: values.id,
    departure_at: values.renew_date
  })(dispatch);

export default propertyRentExpiredReducer;
