import React from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
  children: null
};

const propTypes = {
  children: PropTypes.node
};

const ModalContent = ({ children }) =>
  children && <div className="Modal__content">{children}</div>;

ModalContent.defaultProps = defaultProps;
ModalContent.propTypes = propTypes;

export default ModalContent;
