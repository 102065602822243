import React from 'react';
// import PropTypes from 'prop-types';
import IndexHistoryItem from '../IndexHistoryItem';
import Table from '../../../../../components/Table';

const propTypes = {};

const defaultProps = {};

const style = {
  table: {
    marginTop: '-20px',
  },
};

const IndexHistoryTable = ({ indexes }) => (
  <Table
    style={style.table}
    data={indexes}
    isFetching={false}
    renderTitle={() => (
      <tr>
        <th>Última alteração</th>
        <th>Usuário</th>
        <th>Preço</th>
      </tr>
    )}
    renderItem={(index) => <IndexHistoryItem key={index.id} {...index} />}
  />
);

IndexHistoryTable.propTypes = propTypes;
IndexHistoryTable.defaultProps = defaultProps;

export default IndexHistoryTable;
