import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MdNotifications } from 'react-icons/md';
// Components
import ItemNav from '../../ItemNav';
import Drawer from 'components/Drawer';
import Ball from 'components/Ball';
import * as notificationsService from 'services/notifications';
import { Actions as NotificationActions } from 'modules/notifications';
import NewNotifications from 'containers/NewNotifications';

class MenuNotifications extends React.Component {
  state = {
    isOpenNotifications: false
  };

  constructor(props) {
    super(props);

    this.interval = null;
    this.title = document.title;
  }

  handleToggleDrawer = () => {
    const { isOpenNotifications } = this.state;
    this.setState({ isOpenNotifications: !isOpenNotifications });
  };

  componentDidUpdate(prev) {
    if (prev.hasNewNotifications !== this.props.hasNewNotifications) {
      this.checkNotification();
    }
  }

  componentDidMount() {
    this.checkNotification();

    if (this.props.hasNewNotifications) {
      window.addEventListener('focus', () => {
        clearInterval(this.interval);
        this.interval = setInterval(this.changeTitle, 2000);
      });
    }
  }

  changeTitle = () => {
    setTimeout(() => {
      document.title = this.title;
    }, 1000);

    document.title = 'Nova notificação';
  };

  checkNotification() {
    if (this.props.hasNewNotifications) {
      this.interval = setInterval(this.changeTitle, 2000);
    } else {
      clearInterval(this.interval);
      document.title = this.title;
    }
  }

  clearAllNotifications = () => {
    const { resetNotifications } = this.props;

    notificationsService.clearAll();

    // Limpa todas as notificações da store
    resetNotifications();

    this.handleToggleDrawer();
  };

  render() {
    const { hasNewNotifications } = this.props;
    const { isOpenNotifications } = this.state;

    return (
      <>
        <ItemNav
          onClick={this.handleToggleDrawer}
          className="Menu__item Menu__item--small"
          href="#"
          label={
            <>
              <MdNotifications style={{ fontSize: '20px' }} />
              {hasNewNotifications && <Ball className="Menu__ball" />}
            </>
          }
        />
        <Drawer
          isOpen={isOpenNotifications}
          onClickOverlay={this.handleToggleDrawer}
        >
          <NewNotifications handleToggleDrawer={this.handleToggleDrawer} />
        </Drawer>
      </>
    );
  }
}

MenuNotifications.defaultProps = {
  hasNewNotifications: false,
  onOpenNotifications: null
};

MenuNotifications.propTypes = {
  hasNewNotifications: PropTypes.bool,
  onOpenNotifications: PropTypes.func
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(NotificationActions, dispatch);

export default connect(null, mapDispatchToProps)(MenuNotifications);
