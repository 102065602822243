import { now } from 'lib/date-helpers';
import moment from 'lib/moment';
import * as user from 'services/user';
import api from '../index';

// Clientes com compromissos agendados
export const getPeopleScheduled = async (params) => {
  const response = await api.getList('calendars/dashboard', {
    add: 'id,people_name,people_id,start_date',
    sort: 'start_date',
    filter: {
      ...params?.filter,
      start_date_greater: now(),
      by_person_scheduleds: true,
    },
    limit: 15,
    offset: 1,
    ...params,
  });

  response.data = response.data?.map((schedule) => {
    const future = moment(schedule.start_date, 'DD/MM/YYYY');

    const days = moment().diff(future, 'days');

    const isExpired = days < 0;
    const isToday = days === 0;

    return {
      ...schedule,
      people: {
        id: schedule?.people_id,
        name: schedule?.people_name,
      },
      is_expired: isExpired,
      happens_today: isToday,
    };
  });

  return response;
};

// Aniversariantes da semana
export const getPeopleBirthdays = (params) =>
  api.getList('people/dashboard', {
    add: 'birth_date,cellphone_number',
    sort: 'by_birthday',
    limit: 10,
    offset: 1,
    ...params,
    filter: {
      ...params?.filter,
      by_week_birthday: true,
    },
  });

// const getUserId = () => {
//   try {
//     // Pega o id do usuário logado
//     const { id: user_id } = localStorage.getItem('current-user');

//     return user_id;
//   } catch (err) {
//     return null;
//   }
// };

// Últimos clientes com interação
export const getPeopleWithInteractions = async (params) => {
  const {
    data: { id },
  } = await user.currentUser();

  let filter = {};

  // Pega o id do usuário logado

  if (id) {
    filter = {
      ...filter,
      user_id: id,
    };
  }

  return api.getList('people/dashboard', {
    add: 'interacted_at',
    sort: '-interacted_at',
    limit: 15,
    offset: 1,
    disable_cache: true,
    ...params,
    filter,
  });
};
