import * as S from './styles';

import Button from 'components/Button';
import { MdAdd, MdDeleteOutline, MdEdit } from 'react-icons/md';
import Pagination from 'components/Pagination';
import { useDeleteCategories } from '../../../../hooks/useCategories';
import { openModalStoreCategory } from '../../../../modals/ModalStoreCategory';
import { useDispatch } from 'react-redux';
import { Nodata } from './Nodata';

export function List({ isDebit, isFetching, data, meta, onPageChange }) {
  const dispatch = useDispatch();
  const { deleteCategory } = useDeleteCategories();

  const handleEdit =
    ({ category, data, isEditing, isCategory = true }) =>
    () => {
      dispatch(
        openModalStoreCategory({
          category,
          data,
          isCategory,
          isDebit,
          isEditing,
          onSuccess: () => {
            onPageChange({ selected: 0 });
          },
        })
      );
    };

  if (data.length <= 0 && !isFetching) {
    return (
      <Nodata
        onAddCategory={() => {
          onPageChange({ selected: 0 });
        }}
      />
    );
  }

  return (
    <>
      <S.Wrapper>
        {data.map((category) => (
          <S.Item key={category.id}>
            <S.Row>
              <S.Text>
                <S.Ball color={category.color} />
                {category.title}
              </S.Text>
              <S.Actions>
                <Button
                  size="small"
                  color="white"
                  onClick={handleEdit({
                    category,
                    data: { parent_id: category.id },
                    isCategory: false,
                  })}
                >
                  <MdAdd /> Sub-categoria
                </Button>
                <Button
                  className="Button--icon"
                  disabled={category.is_default}
                  size="small"
                  color="white"
                  onClick={handleEdit({ data: category, isEditing: true })}
                >
                  <MdEdit />
                </Button>
                <Button
                  className="Button--icon"
                  disabled={category.is_default}
                  onClick={deleteCategory({
                    category,
                    onSuccess: () => onPageChange({ selected: 0 }),
                  })}
                  size="small"
                  color="white"
                  colorText="danger"
                >
                  <MdDeleteOutline className="h-color--danger" />
                </Button>
              </S.Actions>
            </S.Row>
            {category.children.length > 0 && (
              <S.WrapChildrens>
                {category.children.map((subcategory) => (
                  <S.Children>
                    <S.Text>
                      <S.Ball color={subcategory.color} />
                      {subcategory.title}
                    </S.Text>
                    <S.Actions>
                      <S.Btn
                        size="small"
                        color="white"
                        onClick={handleEdit({
                          data: subcategory,
                          category,
                          isCategory: false,
                          isEditing: true,
                        })}
                      >
                        <MdEdit />
                      </S.Btn>
                      <S.Btn
                        onClick={deleteCategory({
                          category: subcategory,
                          onSuccess: () => onPageChange({ selected: 0 }),
                        })}
                        size="small"
                        color="white"
                      >
                        <MdDeleteOutline className="h-color--danger" />
                      </S.Btn>
                    </S.Actions>
                  </S.Children>
                ))}
              </S.WrapChildrens>
            )}
          </S.Item>
        ))}

        <Pagination pagination={meta?.pagination} onPageChange={onPageChange} />
      </S.Wrapper>
    </>
  );
}
