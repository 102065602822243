import styled from 'styled-components';
import BoxPlan from '../../components/BoxPlan';

export const Card = styled(BoxPlan.cards)`
  flex-direction: column;

  p {
    color: ${p => p.theme.colors.primary};

    + p {
      margin-top: 5px;
    }
  }
`;
