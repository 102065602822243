import api from 'services/index';

/**
 * Pega os dados de localização
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const get = buildingId =>
  api.getList(`settings/sites/buildings/${buildingId}/location`);

/**
 * Atualiza os dados da localização
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = ({ id, ...values }) =>
  api.patch(`settings/sites/buildings/${id}/location`, values);
