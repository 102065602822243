import { useDispatch } from 'react-redux';
// Services
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import * as peopleService from 'services/people';
import TableData from './components/TableData';
// Modules
import { useRequest } from 'hooks/useRequest';
import { openModalGroup, openModalGroupRemove } from 'modules/modal';

function Groups() {
  const { data, isFetching, fetchData } = useRequest({
    request: peopleService.getGroupsCustom,
    initialState: [],
  });

  const dispatch = useDispatch();

  const handleDelete = (group) => async () => {
    if (group?.people_count > 0) {
      dispatch(
        openModalGroupRemove({
          group,
          onSubmitSuccess: () => {
            fetchData();
          },
        })
      );
    } else {
      await peopleService.removeGroup(group.id);
      fetchData();
    }
  };

  const handleEdit =
    ({ id }) =>
    () => {
      openModalGroup({
        id: id,
        onSubmitSuccess: (...args) => {
          fetchData();
        },
      })(dispatch);
    };

  return (
    <Wrapper.container>
      <MainTitle
        title="Categoria de cliente"
        text="Gerencie aqui todas as categorias que você cadastrou no sistema."
      />
      <TableData
        data={data}
        isFetching={isFetching}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Wrapper.container>
  );
}

Groups.propTypes = {};
Groups.defaultProps = {};

export default Groups;
