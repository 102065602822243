import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 15px;

  th.additional-items {
    text-align: left;
  }

  th.price, th.total {
    text-align: right;
  }

  td.item h3 {
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  td.item p {
    color: #5F6470;
  }

  td.total-label, td.total-price {
    font-weight: 600;
    text-align: right;
  }
`;
