import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  .Container {
    padding: 30px 30px 15px;

    .ListCheck {
      margin-top: -5px;
      margin-bottom: 10px;
    }
  }
  
  .Container--nopadding {
    padding: 0;
  }
  
  .ListOptions--custom {
    margin: -30px -30px -15px;
  }
`;
