import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import Alert from 'react-s-alert';

// Components
import { AsyncSelect } from 'components/Form';

// Context
import { useLocation } from 'containers/LocationAsyncFields/context';

// Hooks
import useFormValue from 'hooks/useFormValue';

// Services
import * as locationService from 'services/location';

const Cities = ({ label = 'Cidade', ...props }) => {
  const {
    dispatch,
    change,
    cityName,
    stateName,
    neighborhoodName,
    zoneName,
    setNeighborhoods,
    setCities,
    initialValues
  } = useLocation();

  const state = useFormValue(stateName);

  const getOptionByValue = useCallback(async id => {
    const res = await locationService.getCityByIdOnly(id);
    setCities({ data: [res.data] });
    return res;
  }, []);

  const loadOptions = useCallback(
    async (search, loadedOptions, { page }) => {
      const response = await fetchCities({
        filter: { name: search },
        offset: page
      });
      const pagination = response?.meta?.pagination;

      setCities({
        data: [...loadedOptions, ...response.data]
      });

      return {
        options: response.data,
        hasMore: pagination?.current_page < pagination?.total_pages,
        additional: {
          page: page + 1
        }
      };
    },
    [state]
  );

  const fetchCities = useCallback(
    async ({ filter, offset = 1, limit = 10 }) => {
      try {
        return locationService.getCities({
          state_id: state,
          filter,
          offset,
          limit
        });
      } catch {
        Alert.success('Problema ao buscar as cidades');
      }
    },
    [state]
  );

  const onChange = useCallback(() => {
    dispatch(change(neighborhoodName, null));
    dispatch(change(zoneName, null));
    setNeighborhoods(initialValues);
  }, [initialValues]);

  return (
    <Field
      key={`cities-${state}`}
      disabled={!state}
      label={label}
      name={cityName}
      loadOptions={loadOptions}
      component={AsyncSelect}
      labelKey="name"
      valueKey="id"
      getOptionByValue={getOptionByValue}
      onChange={onChange}
      cacheOptions={false}
      additional={{
        page: 1
      }}
      {...props}
    />
  );
};

export default Cities;
