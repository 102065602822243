import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FaWhatsapp } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
// Modules
import { openModalPerson, openModalShareProperty } from 'modules/modal';
// Styles
import { Name, ShareButton, ShareWrapper } from './styles';
import { getName } from 'lib/text';

export default function CellPerson({ person, property, onShareSuccess }) {
  const dispatch = useDispatch();

  /**
   * Abre a modal do cliente
   */
  const handleClickPerson = useCallback(() => {
    dispatch(openModalPerson(person, {}));
  }, [dispatch, person]);

  // Lida com o clique
  const handleClickCellphone = useCallback(() => {
    dispatch(
      openModalShareProperty({
        title: 'Compartilhar imóvel',
        type: 'share',
        properties: [property?.id],
        initialValues: {
          person
        },
        onSubmitSuccess: onShareSuccess
      })
    );
  }, [dispatch, person, onShareSuccess]);

  const handleClickEmail = useCallback(() => {
    dispatch(
      openModalShareProperty({
        title: 'Compartilhar imóvel',
        type: 'email',
        properties: [property?.id],
        initialValues: {
          person
        },
        onSubmitSuccess: onShareSuccess
      })
    );
  }, [property, dispatch, onShareSuccess]);

  const hasCellphone = !!person?.cellphone_number;
  const hasEmail = !!person?.email;
  const hasShareOptions = hasEmail || hasCellphone;

  return (
    <td rowSpan={person?.deals?.length} style={{ background: '#fff' }}>
      <Name as="span" onClick={handleClickPerson}>
        {getName(person?.name)}
      </Name>
      {hasShareOptions && (
        <ShareWrapper>
          {hasCellphone && (
            <ShareButton onClick={handleClickCellphone} color="success">
              <FaWhatsapp />
            </ShareButton>
          )}
          {hasEmail && (
            <ShareButton onClick={handleClickEmail} color="secondary">
              <MdEmail />
            </ShareButton>
          )}
        </ShareWrapper>
      )}
    </td>
  );
}
