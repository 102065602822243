import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Form from './components/Form';
import Input from 'components/Form/components/Input';
import { MdCheckCircle, MdEdit } from 'react-icons/md';

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > svg {
    width: 1.5em;
    height: 1.5em;
    padding: 5px;
  }
`;

function EditText({
  form,
  name,
  fieldProps,
  children,
  component,
  onSubmit,
  onSubmitSuccess,
  onSubmitFail,
  initialValues: _initialValues
}) {
  const [isEditting, setIsEditting] = useState(false);
  const [initialValues, setInitialValues] = useState(_initialValues);

  const handleToggleEdit = useCallback(() => {
    setIsEditting(prevState => !prevState);
  }, []);

  const handleSubmitSuccess = useCallback(
    (...props) => {
      if (onSubmitSuccess) onSubmitSuccess(...props);
      setIsEditting(false);
    },
    [onSubmitSuccess]
  );

  const handleSubmitFail = useCallback(
    (...props) => {
      if (onSubmitFail) onSubmitFail(...props);
      setIsEditting(false);
    },
    [onSubmitFail]
  );

  const renderChildren = useMemo(() => {
    if (typeof children === 'function') return children();
    return children;
  }, [children]);

  useEffect(() => {
    if (typeof children === 'string') {
      setInitialValues({ [name]: children });
    }
  }, [children]);

  if (!isEditting) {
    return (
      <Wrapper>
        <span>{renderChildren}</span>
        <MdEdit onClick={handleToggleEdit} />
      </Wrapper>
    );
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSubmitSuccess={handleSubmitSuccess}
      onSubmitFail={handleSubmitFail}
    >
      {({ handleSubmit }) => (
        <Wrapper>
          <Field {...fieldProps} name={name} component={component} />
          <MdCheckCircle fill="#01afad" onClick={handleSubmit} />
        </Wrapper>
      )}
    </Form>
  );
}

EditText.defaultProps = {
  form: 'form-name',
  name: 'input-name',
  component: Input,
  onSubmit: () => {},
  children: '',
  initialValues: null,
  fieldProps: {
    autoGrow: true
  }
};

EditText.propTypes = {
  form: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  children: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
  fieldProps: PropTypes.object
};

export default EditText;
