import React, { useMemo, useCallback, useState } from 'react';
import { MdClose } from 'react-icons/md';

// Components
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import Label from 'components/Label';
import TextLoading from 'components/TextLoading';

// Services
import * as portalsService from 'services/settings/portals';

// Hooks
import { usePropertiesPortal } from 'pages/PropertiesPortal/index';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// Styles
import { Wrapper } from './styles';
import Loading from 'components/Loading';
import { openModalPortalProperty } from 'modules/modal';

const ActionBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const {
    selecteds,
    totalSelecteds,
    propertiesId,
    portalOption,
    portal,
    isFetchingPortal,
    formValues,
    meta,
    updatedSelecteds,
    unselectAll,
  } = usePropertiesPortal();

  const statusLabel = useMemo(() => {
    if (totalSelecteds >= portalOption?.quantity) return 'danger';
    return 'secondary';
  }, [portalOption?.quantity, totalSelecteds]);

  const handleAddProperties = useCallback(async () => {
    setIsLoading(true);
    await portalsService.addProperties({
      portalRealEstateId: portal.id,
      portalRealEstateOptionId: portalOption.id,
      propertiesId: selecteds,
    });
    setIsLoading(false);
    history.push(`/portals/${portal?.id}`);
  }, [selecteds]);

  const handleOpenSelectProperties = useCallback(() => {
    dispatch(
      openModalPortalProperty({
        portalRealEstateId: portal?.id,
        portalName: portal?.portal?.name,
        filter: formValues,
        optionId: portalOption?.id,
        optionName: portalOption?.name,
        onSuccess: () => {
          updatedSelecteds();
        },
        propertiesId,
        pagination: meta?.pagination,
      })
    );
  }, [portal, selecteds, portalOption, meta, propertiesId]);

  return (
    <Wrapper>
      <Loading isFullScreen isVisible={isLoading} />
      <FixedBar isVisible>
        <FixedBar.item style={{ opacity: totalSelecteds > 0 ? 1 : 0 }}>
          Selecionado:{' '}
          <Label color={statusLabel}>
            {totalSelecteds}/{portalOption?.quantity}
          </Label>
        </FixedBar.item>
        <FixedBar.spacer />
        <FixedBar.item>
          <Button
            disabled={isFetchingPortal}
            color="secondary"
            onClick={handleAddProperties}
          >
            {isFetchingPortal ? (
              <TextLoading width={180} height={14} />
            ) : (
              <>
                {portalOption?.name === 'Facebook'
                  ? 'Adicionar ao Facebook'
                  : `Adicionar aos ${portalOption?.name} do ${portal?.portal?.name}`}
              </>
            )}
          </Button>
        </FixedBar.item>
        <FixedBar.spacer />
        <FixedBar.item>
          <button
            className="h-color--secondary"
            onClick={handleOpenSelectProperties}
          >
            Selecionar todos os imóveis
          </button>
          <span style={{ color: '#D3DCEB', padding: '0 10px' }}>|</span>
          <button className="h-color--danger" onClick={unselectAll}>
            <MdClose /> Desmarcar imóveis
          </button>
        </FixedBar.item>
      </FixedBar>
    </Wrapper>
  );
};

export default ActionBar;
