import React from 'react';
import { Helmet } from 'react-helmet';
import FormTitle from 'templates/Login/FormTitle';
import Button from 'components/Button';
// import { Container } from './styles';
import image from './undraw-remote.svg';

const DeviceLogout = () => {
  return (
    <>
      <div className="h-flex__cell--grow" />
      <Helmet>
        <title>Dispositivo alterado</title>
      </Helmet>
      <img
        src={image}
        alt=""
        style={{ maxWidth: '300px', marginBottom: '20px' }}
      />
      <FormTitle
        title="Seu login/senha foi usado em outro computador"
        text="Por favor, acesse novamente"
      />
      <Button to="/" color="white">
        Acessar Painel
      </Button>
      <div className="h-flex__cell--grow" />
    </>
  );
};

export default DeviceLogout;
