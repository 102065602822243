import React from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
// Lib
import { addCurrency } from 'lib/money-helpers';

const BillingChart = ({ width, height, data }) => (
  <div style={{ width, height }}>
    <ResponsiveContainer>
      <AreaChart
        width={600}
        height={200}
        data={data}
        margin={{ left: 25, right: 25 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="period" allowDuplicatedCategory={false} />
        <YAxis dataKey="value" />
        <Tooltip
          formatter={value => addCurrency(value)}
          cursor={{ fill: '#f0f2f5' }}
        />
        <Area dataKey="value" name="Total" key={data.period} fill="#c4d9fd" />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

export default BillingChart;
