import { setTypeList, typeListSelector } from 'modules/propertySearch';
import {
  Button,
  ButtonGroup,
} from 'pages/Crm2/components/LayoutChooser/styles';
import { MdFormatListBulleted, MdViewColumn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setFieldValue } from '../../helpers';

function ChangeLayout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const typeList = useSelector(typeListSelector);
  const isTypeCard = typeList === 'card';

  return (
    <ButtonGroup>
      <Button
        onClick={() => {
          setFieldValue('type_list', 'card', history);
          dispatch(setTypeList('card'));
        }}
        isActive={isTypeCard}
      >
        <MdViewColumn />
      </Button>
      <Button
        onClick={() => {
          setFieldValue('type_list', 'table', history);
          dispatch(setTypeList('table'));
        }}
        isActive={!isTypeCard}
      >
        <MdFormatListBulleted />
      </Button>
    </ButtonGroup>
  );
}

export default ChangeLayout;
