import styled, { css } from 'styled-components';
import Image from 'components/Image';
import { motion } from 'framer-motion';

// Assets
import background from './background.png';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background: url("${background}") no-repeat center center;
  background-size: cover;
  overflow: hidden;
`;

const POSITIONS = {
  NorthWest: css`
    justify-content: flex-start;
    align-items: flex-start;
  `,
  North: css`
    justify-content: center;
    align-items: flex-start;
  `,
  NorthEast: css`
    justify-content: flex-end;
    align-items: flex-start;
  `,
  West: css`
    justify-content: flex-start;
    align-items: center;
  `,
  Center: css`
    justify-content: center;
    align-items: center;
  `,
  East: css`
    justify-content: flex-end;
    align-items: center;
  `,
  SouthWest: css`
    justify-content: flex-start;
    align-items: flex-end;
  `,
  South: css`
    justify-content: center;
    align-items: flex-end;
  `,
  SouthEast: css`
    justify-content: flex-end;
    align-items: flex-end;
  `
};

const imagePosition = ({ position }) => {
  return POSITIONS[position];
};

export const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  ${imagePosition}
`;

export const Img = styled(Image).attrs(() => ({
  tagName: motion.img,
  layout: true,
  size: 'original'
}))`
  position: absolute;
  width: ${p => p.theme.size}%;
  opacity: ${p => p.theme.opacity / 100};
`;
