// Widgets.js
import { createModule } from '../lib/reducer-helpers';
import { API_BASE_URL, HTTP } from '../lib/HTTP';

const createAction = createModule('recommend');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');

// Initial State
const initialState = {
  data: [],
  meta: {
    isFetching: false,
    allSelected: false
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { isFetching: true } };
    case RECEIVE: {
      const data = action.data.map(recommend => {
        return {
          ...recommend,
          isChecked: false
        };
      });
      return { ...state, data, meta: { isFetching: false } };
    }
    default:
      return state;
  }
}

// Action Creators
export function requestRecommendations() {
  return { type: REQUEST };
}

export function receiveRecommendations(data) {
  return { type: RECEIVE, data };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export function getRecommendations() {
  return dispatch => {
    dispatch(requestRecommendations());
    HTTP.get(API_BASE_URL + 'recommends.json').then(({ data }) => {
      dispatch(receiveRecommendations(data));
    });
  };
}
