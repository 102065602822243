import styled from "styled-components";

export const ModalWrapper = styled.div`
  padding-bottom: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Items = styled.div`
  display: flex;
  margin: 50px 0;
`;

export const ItemLeft = styled.div`
  padding-right: 60px;
  border-right: 1px solid #D3DCEB;
`;

export const ItemRight = styled.div`
  padding-left: 60px;
  border-left: 1px solid #D3DCEB;
`;
