import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const Box = styled.div`
  width: 50%;
`;

export const Left = styled(Box)``;
export const Divider = styled.div`
  width: 46px;
  position: relative;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: calc(100% + 40px);
    margin-top: -20px;
    background: ${p => p.theme.borderColor};
  }
`;
export const Right = styled(Box)``;
