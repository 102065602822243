import React from 'react';
import { Number, Text, Wrapper } from './styles';
import { plural } from 'lib/text';
import { formatNumber } from '../../helpers';

function PeopleCount({ value }) {
  return (
    <Wrapper>
      <Number>{formatNumber(value)}</Number>
      <Text>
        {plural(value, 'Cliente compatível', 'Clientes compatíveis', false)}
      </Text>
    </Wrapper>
  );
}

export default PeopleCount;
