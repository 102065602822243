import React from 'react';
import CardCrm from 'components/CardCrm';
import { Draggable } from 'react-beautiful-dnd';

// import { Wrapper } from './styles';

function DealsList({ deals, stageId }) {
  if (!deals) return null;

  return deals.map((deal, index) => {
    return (
      <Draggable
        shouldRespectForcePress={true}
        draggableId={`${deal.id},${stageId}`}
        index={index}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="Pipeline__item"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CardCrm deal={deal} />
          </div>
        )}
      </Draggable>
    );
  });
}

export default DealsList;
