import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Check from 'components/Check';

const defaultProps = {
  id: null,
  input: null
};

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  input: PropTypes.object
};

export const Checkbox = props => {
  const { children, id, input, className, ...rest } = props;
  const { name, value } = input;

  return (
    <label
      {...rest}
      htmlFor={`checkbox-${id}`}
      tabIndex={-1}
      className={classnames('Checkbox', className)}
    >
      <input
        id={`checkbox-${id}`}
        type="checkbox"
        name={name}
        {...input}
        style={{
          display: 'none'
        }}
      />
      <Check checked={!!value} style={{ marginRight: children && 5 }} />
      {children}
    </label>
  );
};

Checkbox.defaultProps = defaultProps;
Checkbox.propTypes = propTypes;

export default Checkbox;
