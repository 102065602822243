import React from 'react';
// Components
import { FieldBool } from 'components/Form';

export default function SearchBy() {
  return (
    <FieldBool
      canRemoveSelf
      label="Com atividade agendada?"
      name="has_calendars"
    />
  );
}
