import React from 'react';
import { Col } from 'react-flexbox-grid';

const WrapperChart = ({ children }) => (
  <Col xs={12} style={{ zIndex: 10, position: 'relative' }}>
    {children}
  </Col>
);

export default WrapperChart;
