import Container from 'components/Container';
import { useGallery } from '../../contexts/gallery';
import SortableRegionPostGallery from '../SortableRegionPostGallery';
import FormGalleryUpload from 'components/FormGalleryUpload';
import Button from 'components/Button';
import { MdAdd } from 'react-icons/md';
import icon from './icon.svg';
import { Actions, Header, Reception } from './styles';
import { useEffect, useState } from 'react';

const Gallery = ({ descriptionId }) => {
  const [params, setParams] = useState({});

  const { data, uploadImages, type } = useGallery();

  useEffect(() => {
    if (type === 'city') {
      setParams({ city_description_id: descriptionId });
    }

    if (type === 'neighborhood') {
      setParams({ neighborhood_description_id: descriptionId });
    }
  }, [type, descriptionId]);

  const handleUploadImages = ({ files }) => {
    const totalInGallery = data.length;
    const totalCanUpload = 10 - totalInGallery;

    const _files = files?.slice(0, totalCanUpload);

    return uploadImages({ files: _files, type, ...params });
  };

  if (!data.length) {
    return (
      <Container
        style={{ paddingTop: '10px', marginTop: '20px', marginBottom: '90px' }}
      >
        <Reception>
          <img src={icon} alt="" />
          <h1>Você ainda não enviou nenhuma foto.</h1>
          <p>Que tal enviar agora?</p>
          <FormGalleryUpload
            form="Images"
            initialValues={params}
            onChange={handleUploadImages}
          >
            <Button color="secondary" size="large">
              <MdAdd /> Enviar fotos
            </Button>
          </FormGalleryUpload>
        </Reception>
      </Container>
    );
  }

  return (
    <Container
      style={{ paddingTop: '10px', marginTop: '20px', marginBottom: '90px' }}
    >
      <Header>
        <Actions>
          <FormGalleryUpload
            form="Images"
            initialValues={params}
            onChange={handleUploadImages}
            className="h-margin-left--5"
          >
            <Button
              disabled={data.length >= 10}
              color="secondary"
              size="medium"
            >
              <MdAdd /> Enviar mais imagens
            </Button>
          </FormGalleryUpload>
        </Actions>
      </Header>
      <SortableRegionPostGallery />
    </Container>
  );
};

export default Gallery;
