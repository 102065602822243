import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-direction: column;
  background: #fff;
  padding: 20px;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 5px;

    li {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 1px;
  background: #f1f4f9;
`;

const Base = styled.p`
  font-size: 14px;
`;

export const Title = styled(Base)`
  font-weight: bold;
`;

export const Text = styled(Base)`
  color: #8e8e8e;
`;

export const Highlight = styled(Base)`
  font-weight: bold;
  color: #0063c0;
`;
