import React from 'react';
// Components
// import ItemDropdown, { Item } from 'components/MainHeader2/components/ItemDropdown';
import ItemNav from 'components/MainHeader2/components/ItemNav';
import Ball from 'components/Ball';
import { useStorage } from 'hooks/useStorage';
import { useCurrentUser } from 'hooks/useCurrentUser';

function MenuCrm() {
  const currentUser = useCurrentUser();
  const { value, set } = useStorage({ key: '@tecimob/visit-crm' });

  return (
    <ItemNav
      label={
        <>
          CRM{' '}
          {!value && currentUser?.serial < 33111 && (
            <Ball className="Menu__ball" />
          )}
        </>
      }
      to="/crm/dashboard"
      active="crm"
      exact
      onClick={() => {
        set(true);
      }}
    />
  );

  // return (
  //   <MenuHeader title="CRM" to="/crm/deals" active="crm">
  //     <Item
  //       to="/crm/deals"
  //       title="Jornada de Compra"
  //       text="Ver clientes por etapa"
  //       icon={MdViewColumn}
  //       iconColor="success"
  //     />
  //     <Item
  //       to="/crm/matched/show"
  //       title="Imóveis Compatíveis"
  //       text="Novas oportunidades"
  //       icon={MdFavorite}
  //       iconColor="danger"
  //     />
  //     <Item
  //       to="/profile/search"
  //       title="Pesquisar por perfil"
  //       text="Encontre o cliente ideal"
  //       icon={MdSearch}
  //       iconColor="caution"
  //     />
  //   </MenuHeader>
  // );
}

export default MenuCrm;
