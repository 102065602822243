import Button from 'components/Button';
import React from 'react';
import PropTypes from 'prop-types';
// Constants
import { AliasShape } from 'constants/shapes';
// Components
import Table from 'components/Table';

const propTypes = {
  data: PropTypes.arrayOf(AliasShape),
  isFetching: PropTypes.bool,
  handleRemove: PropTypes.func
};

const defaultProps = {
  data: [],
  isFetching: false,
  handleRemove: null
};

const TableAccounts = ({ data, isFetching, handleRemove }) => (
  <Table
    isFetching={isFetching}
    data={data}
    renderTitle={() => (
      <tr>
        <th>Alias</th>
        <th colSpan={2}>Para</th>
      </tr>
    )}
    renderItem={({ id, address, goto }) => (
      <tr key={id} className="h-hover">
        <td>{address}</td>
        <td>{goto}</td>
        <td className="Table__cell--small">
          <Button
            color="danger"
            size="medium"
            className="h-hover__show"
            onClick={() => handleRemove(address)}
          >
            Remover
          </Button>
        </td>
      </tr>
    )}
  />
);

TableAccounts.propTypes = propTypes;
TableAccounts.defaultProps = defaultProps;

export default TableAccounts;
