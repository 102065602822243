import React from 'react';
import {
  Box,
  Content,
  InnerContent,
  Text,
  TitleContent,
  WrapIcon,
  Wrapper,
} from './styles';
import { ThemeProvider } from 'styled-components';
import FormClose from 'components/CanShowAgain/Form';
import { Close } from 'pages/NewDashboard/components/Advertisements/components/Box/styles';
import { MdClose } from 'react-icons/md';
import Popover from 'components/Popover';
import { useWizard } from 'hooks/useWizard';

export const DEFAULT_THEME = {
  borderRadius: '4px',
  fontSize: '14px',
  boxSize: '162px',
  boxPadding: '20px',
};

function AlertBig({
  canClose = true,
  storageKey,
  color,
  image,
  text,
  theme = DEFAULT_THEME,
  children,
}) {
  const { isVisible, close } = useWizard({ key: storageKey });

  if (!isVisible) return null;

  return (
    <ThemeProvider
      theme={{
        color,
        ...theme,
      }}
    >
      <Wrapper>
        <Box>
          <div>
            <WrapIcon>{image ? <img src={image} alt="" /> : null}</WrapIcon>
            <Text>{text}</Text>
          </div>
        </Box>
        <Content>{children}</Content>
        {canClose && (
          <Popover
            width={310}
            initialValues={{ not_show_again: false }}
            onSubmit={close}
            component={FormClose}
            style={{ display: 'inline' }}
          >
            <Close>
              <MdClose />
            </Close>
          </Popover>
        )}
      </Wrapper>
    </ThemeProvider>
  );
}

export { InnerContent, TitleContent };

export default AlertBig;
