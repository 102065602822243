import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
// Components
import Button from 'components/Button';
import Container from 'components/Container';
import FixedBar from 'components/FixedBar';
import { FieldBool } from 'components/Form';
import ListOptions from 'components/ListOptions';
import Loading from 'components/Loading';
import { useHistory } from 'react-router';
import FieldSelectUser from 'containers/FieldSelectUser';

const Wrapper = styled.form`
  position: relative;
`;

// const Title = styled.h2`
//   margin-bottom: 10px;
//   font-weight: 500;
//   font-size: 14px;
// `;

// const Box = styled.div`
//   background: rgba(194, 206, 226, 0.1);
//   border: 1px solid #d2dbe3;
//   border-radius: 4px;
//   padding: 10px;
// `;

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function FormOrulo({ handleSubmit, submitting }) {
  const history = useHistory();

  return (
    <Wrapper onSubmit={handleSubmit}>
      {submitting && <Loading isVisible isFullComponent />}
      {/*<Container style={{ padding: '30px' }}>*/}
      {/*  <FormSection name="filter" component={Row}>*/}
      {/*    <Col xs={6}>*/}
      {/*      <Title>*/}
      {/*        Selecione o <strong>Status:</strong>*/}
      {/*      </Title>*/}
      {/*      <Box>*/}
      {/*        <Statuses />*/}
      {/*      </Box>*/}
      {/*    </Col>*/}
      {/*    <Col xs={6}>*/}
      {/*      <Row>*/}
      {/*        <Col xs={12}>*/}
      {/*          <Title>*/}
      {/*            Selecione os <strong>Tipos de Imóvel</strong> que você deseja*/}
      {/*            trabalhar:*/}
      {/*          </Title>*/}
      {/*          <Box>*/}
      {/*            <Types />*/}
      {/*          </Box>*/}
      {/*        </Col>*/}
      {/*      </Row>*/}
      {/*    </Col>*/}
      {/*  </FormSection>*/}
      {/*</Container>*/}

      <Container
        padding
        className="h-margin-top--15"
        style={{ marginBottom: 90 }}
      >
        <ListOptions>
          <ListOptions.item title={<h4>Dados de publicação</h4>} />
          <ListOptions.item
            title="Mostrar Imóveis no site?"
            renderOptions={() => <FieldBool name="is_published" />}
          />
          <ListOptions.item
            title="Mostrar Logradouro?"
            renderOptions={() => <FieldBool name="is_street_shown" />}
          />
          <ListOptions.item
            title="Mostrar número?"
            renderOptions={() => <FieldBool name="is_street_number_shown" />}
          />
          <ListOptions.item
            title="Mostrar Bairro?"
            renderOptions={() => <FieldBool name="is_neighborhood_shown" />}
          />
          <ListOptions.item
            title="Mostrar nome do condomínio?"
            renderOptions={() => <FieldBool name="is_condominium_shown" />}
          />
          <ListOptions.item
            title="Mostrar mapa no site?"
            renderOptions={() => <FieldBool name="is_map_shown" />}
          />
          <ListOptions.item
            title="Mostrar localização exata?"
            renderOptions={() => <FieldBool name="is_exact_map_shown" />}
          />
          <ListOptions.item
            title="Mostrar complemento?"
            renderOptions={() => <FieldBool name="is_complement_shown" />}
          />
          <ListOptions.item
            title="Corretor responsável"
            renderOptions={() => <FieldSelectUser name="user_id" label={null} style={{ width: '220px' }} />}
          />
        </ListOptions>
      </Container>

      <FixedBar style={{ left: '240px' }}>
        <FixedBar.item>
          <Button
            type="button"
            color="white"
            colorText="secondary"
            onClick={history.goBack}
          >
            Voltar
          </Button>
        </FixedBar.item>
        <FixedBar.spacer />
        <FixedBar.item>
          <Button color="success" type="submit">
            Salvar
          </Button>
        </FixedBar.item>
      </FixedBar>
    </Wrapper>
  );
}

FormOrulo.defaultProps = defaultProps;
FormOrulo.propTypes = propTypes;

export default reduxForm({
  form: 'FormOrulo',
  enableReinitialize: true,
})(FormOrulo);
