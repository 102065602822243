import { responseMessage } from 'lib/service-helpers';
import api, { apiStorage } from 'services/index';

/**
 * Pega as informações de site relacionadas ao rodapé
 * @param params
 * @return {Promise}
 */
export const getOne = (params) =>
  api.getOne('settings/sites/popups', '', params);

/**
 * Atualiza as informações do site relacionadas ao rodapé
 * @param values
 * @return {Promise}
 */
export const update = async (values) => {
  try {
    if (values.image) {
      await apiStorage.uploadFile('sites/popups', { image: values.image });
    }

    if (values.image_mobile) {
      await apiStorage.uploadFile('sites/popups/mobile', {
        image: values.image_mobile || values.image,
      });
    }

    delete values.image;
    delete values.image_mobile;

    return api
      .create('settings/sites/popups', values)
      .then(responseMessage('Salvo!'));
  } catch (err) {
    throw err;
  }
};
