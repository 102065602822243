import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import * as pages from './pages';

// import from './styles';

function Posts() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`} component={pages.List} />
      <Route exact path={`${path}/store/:type/:id?`} component={pages.Store} />
      <Redirect to={`${path}/`} />
    </Switch>
  );
}

Posts.propTypes = {};
Posts.defaultProps = {};

export default Posts;
