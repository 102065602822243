import React from 'react';
import PropTypes from 'prop-types';
// Helpers
import moment from 'lib/moment';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    period: PropTypes.string,
    value: PropTypes.number
  })
};

const defaultProps = {
  data: {}
};

const numberFormat = number =>
  Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(number);

const TableItem = ({ data }) => (
  <tr className="h-hover">
    <td>{moment(data['ga:yearMonth'], 'YYYYMM').format('MM-YYYY')}</td>
    <td>{numberFormat(data['Total de usuários'])}</td>
    <td>{numberFormat(data['Novos usuários'])}</td>
  </tr>
);

TableItem.propTypes = propTypes;
TableItem.defaultProps = defaultProps;

export default TableItem;
