import React from 'react';
import Image from 'components/Image';
import { Caption } from 'components/Gallery/styles';
import { MdOutlineWarning } from 'react-icons/md';
import GalleryItem from '../GalleryItem';
// import { Wrapper } from './styles';

function GalleryImage({ onClick, src, alt, isExternal, children }) {
  return (
    <GalleryItem className="isImage" onClick={onClick}>
      <Image src={src} alt={alt} />
      {isExternal && (
        <Caption data-tip="Esta imagem não se encontra no servidores de arquivos do Tecimob contate o nosso suporte para mais informações">
          <MdOutlineWarning /> Imagem Externa
        </Caption>
      )}
      {children}
    </GalleryItem>
  );
}

export default GalleryImage;
