import React from 'react';
// Components
import MainTitle from 'components/MainTitle';
import FormPopup from '../FormPopup';
import PopupNoData from '../PopupNoData';

const propTypes = {};
const defaultProps = {};

const PopupPage = ({ formData, handleUploadPopUp, handleSubmit }) => {
  const hasImage = formData.file_url || formData.image;

  return (
    <>
      <MainTitle
        title="Pop-up"
        text="Adicione uma pop-up em seu site para divulgar promoções, avisos, ou qualquer informação que desejar."
      />
      {!hasImage ? (
        <PopupNoData handleUploadPopUp={handleUploadPopUp} />
      ) : (
        <FormPopup initialValues={formData} onSubmit={handleSubmit} />
      )}
    </>
  );
};

PopupPage.propTypes = propTypes;
PopupPage.defaultProps = defaultProps;

export default PopupPage;
