import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${(p) =>
    p.disabled &&
    css`
      text-decoration: line-through;
    `}

  p {
    font-weight: 500;
    font-size: 12px;
    color: #65686f;
    line-height: 18px;
  }
`;
