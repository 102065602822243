import React, { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { Field, FieldArray } from 'redux-form';
import Button from 'components/Button';
import { Input } from 'components/Form';
import { MdDelete, MdHelp } from 'react-icons/md';
import GroupControl, { GroupItem } from 'components/GroupControl';
import { InputWrapper, Wrapper } from './styles';

function StagesField({ fields }) {
  useEffect(() => {
    Tooltip.rebuild();
  }, [fields]);

  return (
    <div>
      <p className="h-margin-bottom--15">
        Quais etapas irão compor este funil?
      </p>

      {fields.map((stage, index) => (
        <Wrapper key={index}>
          <InputWrapper>
            <Field
              label="Nome da etapa"
              name={`${stage}.name`}
              type="text"
              component={Input}
            />
          </InputWrapper>
          <InputWrapper>
            <GroupControl
              label={
                <div>
                  Estagnar em{' '}
                  <MdHelp data-tip="Após este prazo, o cartão do negócio ira destacar com fundo vermelho" />
                </div>
              }
            >
              <GroupItem>
                <Field name={`${stage}.stagnated_after`} component={Input} />
              </GroupItem>
              <GroupItem label="Dias" size="small" />
            </GroupControl>
          </InputWrapper>
          <InputWrapper>
            <Field
              label="Descrição da etapa"
              name={`${stage}.description`}
              type="text"
              component={Input}
            />
          </InputWrapper>
          {index > 0 && (
            <button
              color="danger"
              className="h-color--danger"
              onClick={() => fields.remove(index)}
            >
              <MdDelete />
            </button>
          )}
        </Wrapper>
      ))}

      <Button
        color="tertiary"
        onClick={() => fields.push({ stagnated_after: 3 })}
      >
        Adicionar Etapa
      </Button>
    </div>
  );
}

function Stages() {
  return <FieldArray name="stages" component={StagesField} />;
}

export default Stages;
