// todo - Buscar dados
// [] - Caracteristicas dos imóveis
// [X] - Condominios
// [X] - Caracteristicas de condomínio
// [] - Portais
// [X] - Indice financeiro
// [X] - Usuários com filtro de corretor

import { isArray, mapValues } from 'lodash';
import { combineReducers } from 'redux';
// Constants
import { PROFILE } from 'constants/options';
// Helpers
import { createModule } from 'lib/reducer-helpers';
import { responseMessage } from 'lib/service-helpers';
import { replaceObjectKey } from 'lib/object-helpers';
// Services
import * as propertiesService from 'services/properties';
import * as typesService from 'services/types';
// Modules
import locationReducer, { locationSelectorsCreate, thunksCreator } from 'modules/location';
import { getProperties } from 'modules/properties';
import { openModalProperty } from 'modules/modal';

// Constants
const moduleName = 'searchFilter';

// Creators
const createAction = createModule(moduleName);
export const locationSelector = locationSelectorsCreate(moduleName);
export const locationThunks = thunksCreator(moduleName);

// Seleciona os dados iniciais do formulário de pesquisa
export const searchFilterValuesSelector = state =>
  state.searchFilter.formValues;
export const dataSelector = (name, state) => state.searchFilter.data[name].data;
export const dataFetchingSelector = (name, state) =>
  state.searchFilter.data[name].isFetching;

export const areaFieldsSelector = state => dataSelector('area_fields', state);
export const informationFieldsSelector = state =>
  dataSelector('information_fields', state);
export const roomsFieldsSelector = state => dataSelector('rooms_fields', state);

export const typesSelector = state => dataSelector('types', state);
export const subtypesSelector = state => dataSelector('subtypes', state);
export const establishmentsSelector = state =>
  dataSelector('establishments', state);
export const profilesSelector = state => dataSelector('profiles', state);
export const usersSelector = state => dataSelector('users', state);
export const characteristicsSelector = state =>
  dataSelector('characteristics', state);
export const condosSelector = state => dataSelector('condos', state);
export const condosCharacteristicsSelector = state =>
  dataSelector('condosCharacteristics', state);
export const indexesSelector = state => dataSelector('indexes', state);

const dataInitialState = { data: [], isFetching: false };

const initialState = {
  formValues: {
    transaction: '1'
  },
  data: {
    area_fields: dataInitialState,
    information_fields: dataInitialState,
    rooms_fields: dataInitialState,
    types: dataInitialState,
    subtypes: dataInitialState,
    profiles: dataInitialState,
    users: dataInitialState,
    characteristics: dataInitialState,
    condos: dataInitialState,
    establishments: dataInitialState,
    condosCharacteristics: dataInitialState,
    indexes: dataInitialState
  }
};

// Actions do reducer de dados iniciais do formulário
const RECEIVE_VALUES = createAction('RECEIVE_VALUES');
const RESET_VALUES = createAction('RESET_VALUES');

// Actions do reducer de informacao do filtro
const REQUEST_DATA = createAction('REQUEST_DATA');
const RECEIVE_DATA = createAction('RECEIVE_DATA');
const CLEAR_DATA = createAction('CLEAR_DATA');
const RESET_DATA = createAction('RESET_DATA');

// Reducers

/**
 * Reducer dos dados iniciais do formulário
 * @param state
 * @param action
 */
export function reducer(state = initialState.formValues, action) {
  switch (action.type) {
    case RECEIVE_VALUES:
      return {
        ...state,
        ...action.values
      };
    case RESET_VALUES:
      return initialState.formValues;
    default:
      return state;
  }
}

/**
 * Reducer das dados do formulário
 * eg.: types,characteristics,condos etc...
 * @param state
 * @param action
 * @return {*}
 */
export function reducerData(state = initialState.data, action) {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        [action.key]: { ...state[action.key], isFetching: true }
      };
    case RECEIVE_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          data: action.data,
          isFetching: false
        }
      };
    case CLEAR_DATA: {
      // limpa mais de uma chave
      if (isArray(action.key)) {
        return mapValues(state, (values, key) => {
          if (action.key.indexOf(key) !== -1) {
            return { ...values, data: dataInitialState };
          }

          return values;
        });
      }

      // verifica se é pra limpar varias chaves ou um específico
      return {
        ...state,
        [action.key]: { ...state[action.key], data: dataInitialState }
      };
    }
    case RESET_DATA:
      return initialState.data;
    default:
      return state;
  }
}

// Action Creators

/**
 * Seta os dados iniciais do formulario de filtro de pesquisa
 * @param {Object} values - valores do formulário
 * @example { transaction: '1', profile: '2' }
 */
export const receiveValues = values => ({
  type: RECEIVE_VALUES,
  values
});

/**
 * Reseta os dados iniciais do formulário
 */
export const resetValues = () => ({
  type: RESET_VALUES
});

/**
 * Reseta as informoções
 */
export const resetData = () => ({
  type: RESET_DATA
});

/**
 * Marca que a informação está sendo requisitada
 * @param key - chave da informação eg.: types,characteristics,condos, ...etc
 */
export const requestData = key => ({
  type: RECEIVE_DATA,
  key
});

/**
 * Recebe as informações que o formulário precisa
 * @param {String} key - chave da informação eg.: types,characteristics,condos, ...etc
 * @param {Array} data - as informações que vão ser armazenadas na chave
 */
export const receiveData = (key, data) => ({
  type: RECEIVE_DATA,
  key,
  data
});

/**
 * Limpa dados de uma chave específica
 * @param {String|Array} key - chave ou varias chaves da informação
 */
export const clearData = key => ({
  type: CLEAR_DATA,
  key
});

/**
 * Normaliza os valores do formulário para o filtro
 * @param values
 * @return {Object} - retorna os valores do formulário normalizados
 */
export const normalizeFilter = ({
  by_floor_comparision,
  by_floor_greater,
  by_floor_lower,
  by_floor_equals,
  ...values
}) => {
  // remove a referência da variavel
  let normalizedValues = { ...values };

  switch (by_floor_comparision) {
    case 'greater':
      normalizedValues = { ...normalizedValues, by_floor_greater };
      break;
    case 'lower':
      normalizedValues = { ...normalizedValues, by_floor_lower };
      break;
    case 'equals':
    default:
      normalizedValues = { ...normalizedValues, by_floor_equals };
      break;
  }

  // remove id do tipo
  normalizedValues = replaceObjectKey(
    normalizedValues,
    'type_id',
    'by_type_id'
  );

  return normalizedValues;
};

export const fetchProfiles = params => dispatch =>
  propertiesService.getProfiles(params).then(({ data: profileIds }) => {
    const profiles = PROFILE.filter(
      profile => profileIds.indexOf(profile.value) !== -1
    );
    dispatch(receiveData('profiles', profiles));
    return profiles;
  });
export const fetchTypesSubtypes = params => dispatch =>
  propertiesService.getTypesSubtypes(params).then(({ data: types }) => {
    dispatch(receiveData('types', types));
    return types;
  });

export const fetchTypes = params => dispatch =>
  typesService.getList(params).then(({ data: types }) => {
    dispatch(receiveData('types', types));
    return types;
  });

export const fetchSubtypes = (typeId, params) => dispatch =>
  typesService.getSubtypes(typeId, params).then(({ data: subtypes }) => {
    dispatch(receiveData('subtypes', subtypes));
    return subtypes;
  });

export const fetchCharacteristics = params => dispatch =>
  propertiesService.getCharacteristics(params).then(({ data }) => {
    dispatch(receiveData('characteristics', data));
    return data;
  });
export const fetchTypeInfos = (typeId, params) => dispatch =>
  propertiesService.getType(typeId, params).then(({ data }) => {
    dispatch(receiveData('area_fields', data.area_fields));
    dispatch(receiveData('information_fields', data.information_fields));
    dispatch(receiveData('rooms_fields', data.rooms_fields));
    return data;
  });
export const fetchCondos = params => dispatch =>
  propertiesService.getCondos(params).then(({ data: condos }) => {
    dispatch(receiveData('condos', condos));
    return condos;
  });
export const fetchCondosCharacteristics = params => dispatch =>
  propertiesService
    .getCondosCharacteristics(params)
    .then(({ data: characteristics }) => {
      dispatch(receiveData('condosCharacteristics', characteristics));
      return characteristics;
    });
export const fetchUsers = params => dispatch =>
  propertiesService.getUsers(params).then(({ data: users }) => {
    dispatch(receiveData('users', users));
    return users;
  });
export const fetchFinancialIndexes = params => dispatch =>
  propertiesService.getFinancialIndexes(params).then(({ data }) => {
    dispatch(receiveData('indexes', data));
    return data;
  });
export const fetchEstablishments = params => dispatch =>
  propertiesService.getEstablishments(params).then(({ data }) => {
    dispatch(receiveData('establishments', data));
    return data;
  });

/**
 * Lida com o evento de submit do formulário
 * @param values
 */
export const handleSubmitForm = values => (dispatch, getState) => {
  if (values.reference) {
    return propertiesService
      .getReference(values.reference)
      .then(({ data: property }) => {
        openModalProperty({ property })(dispatch);
        return property;
      })
      .catch(responseMessage('Não existe imóvel com essa referência!'));
  }

  // Valores do formulário sem o type_id
  const filter = normalizeFilter(values);

  if (values.type_id) {
    // busca as informacoes de rooms, areas e informations
    // campos que são necessarios para renderizar os filtros de pesquisa
    fetchTypeInfos(values.type_id, { filter })(dispatch);
  }

  fetchCharacteristics({ filter: { by_type_id: filter.by_type_id } })(dispatch);
  fetchCondos({ filter: { by_type_id: filter.by_type_id } })(dispatch);
  fetchEstablishments({ filter: { by_type_id: filter.by_type_id } })(dispatch);
  fetchCondosCharacteristics({ filter: { by_type_id: filter.by_type_id } })(
    dispatch
  );
  fetchFinancialIndexes({ filter: { by_type_id: filter.by_type_id } })(
    dispatch
  );
  fetchUsers({})(dispatch);

  return getProperties({ filter })(dispatch, getState);
};

export const handleChangeForm = values => (dispatch, getState) => {
  const filter = normalizeFilter(values);

  return getProperties({ filter })(dispatch, getState);
};

/**
 * Pega os dados iniciais do formulário
 */
export const getInitialValues = () => dispatch => {
  fetchTypes()(dispatch);
  fetchProfiles()(dispatch);
  locationThunks.getCountries()(dispatch);
};

export default combineReducers({
  formValues: reducer,
  data: reducerData,
  location: locationReducer(moduleName)
});
