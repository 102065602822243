import styled from 'styled-components';

export const Wrapper = styled.div`
  border-left: 3px solid #0063c0;
  width: 100%;
  padding: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 15px;
`;

export const BoxRight = styled.div`
  display: flex;
  flex: 1 0;
  justify-content: space-between;
  gap: 15px;
`;

export const Box = styled.div``;

export const Title = styled.div`
  flex-shrink: 0;
  min-width: 0;
  font-size: 24px;
  color: #5c5260;
`;

export const Label = styled.div`
  font-size: 16px;
  color: #5c5260;
  font-weight: bold;
`;

export const Count = styled.div`
  font-size: 26px;
`;
