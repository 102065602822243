import Loading from 'components/Loading';
import { ModalTemplate } from 'components/Modal';
import { usePix } from 'hooks/usePix';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { Wrapper } from './styles';
import PixInput from './components/PixInput';

const ModalPayPix = ({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  credit_id,
}) => {
  const { code, imageUrl, contract } = usePix({ credit_id: credit_id });

  useEffect(() => {
    contract();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isFetching={!code}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={!code}
        title={`Pagar com Pix`}
        handleClose={handleClose}
      >
        <Loading isVisible={!code} isBlock />
        {!!code && (
          <Wrapper style={{ marginTop: '-25px' }}>
            <img src={imageUrl} alt="Qr Code Pix" />
            <p>
              Abra o App do seu banco, acesse a área PIX, escolha pagar por QR
              Code e aponte a câmera do seu celular.
            </p>
            <h5>Ou copie este código QR para fazer o pagamento</h5>
            <p>
              Escolha pagar via Pix pelo seu Internet Banking ou app de
              pagamentos. Depois, cole o seguinte código:
            </p>
            <PixInput value={code} />
          </Wrapper>
        )}
      </ModalTemplate>
    </Modal>
  );
};

export default ModalPayPix;
