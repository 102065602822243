import Button from "components/Button";
import { Input } from "components/Form";
import { ModalFooter } from "components/Modal";
import { Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";

function FormZone({
  handleClose,
  isSubmitting,
  isPristine,
  handleSubmit
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Field xs={12} label="Nome" name="name" component={Input} />
      </Row>

      <ModalFooter>
        <Button onClick={handleClose} color="white" colorText="secondary">
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button
          type="submit"
          color="success"
          disabled={isSubmitting || isPristine}
        >
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormZone',
  enableReinitialize: true
})(FormZone);