import { combineReducers } from 'redux';
import { createModule, item } from '../lib/reducer-helpers';
import * as receptionSourcesService from '../services/receptionSources';

// funcao que cria actionTypes
const createAction = createModule('receptionSources');

// Action Types
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const RECEIVE_SOURCE = createAction('RECEIVE_SOURCE');

export const receptionSourcesSelector = state => state.receptionSources.data;

// Widgets.js
const initialState = {
  data: [],
  meta: {
    isFetching: false
  }
};

export function reducerReception(state = initialState.data, action) {
  switch (action.type) {
    case RECEIVE:
      return action.data;
    case RECEIVE_SOURCE:
      return item.add(state, action.option);
    default:
      return state;
  }
}

export function reducerMeta(state = initialState.meta, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    default:
      return state;
  }
}

export const requestReception = () => ({
  type: REQUEST
});

export const receiveReception = data => ({
  type: RECEIVE,
  data
});

export const receiveSource = option => ({
  type: RECEIVE_SOURCE,
  option
});

export const getReceptionSources = params => dispatch => {
  dispatch(requestReception());

  return receptionSourcesService
    .getList(params)
    .then(({ data }) => dispatch(receiveReception(data)));
};

export const addReceptionSource = name => dispatch =>
  receptionSourcesService.create(name).then(({ data }) => {
    dispatch(receiveSource(data));
    return data;
  });

export default combineReducers({
  data: reducerReception,
  meta: reducerMeta
});
