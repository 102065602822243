// Modules
import createDataReducer from 'modules/data';
// Services
import * as superHighlightsService from 'services/sites/superHighlights';

export const {
  selectors: superHighlightsSelectors,
  actionCreators: superHighlightsActions,
  reducer
} = createDataReducer('components/superHighlights');

/**
 * Busca todos os modelos de cabeçalhos
 * @param params
 * @return {Promise}
 */
export const fetchSuperHighlights = params => dispatch => {
  dispatch(superHighlightsActions.request());

  return superHighlightsService.getComponents(params).then(res => {
    dispatch(superHighlightsActions.receive(res.data));
    return res;
  });
};

export default reducer;
