import styled from 'styled-components';
import BaseTable from 'components/Table';

export const Table = styled(BaseTable)`
  th {
    white-space: nowrap;
  }

  tr:nth-child(even) td {
    background-color: #e9eef5;
  }
`;
