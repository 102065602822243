import React, { useCallback } from 'react';
import Price from 'components/Price';
import { Wrapper, Content, Img, Text } from './styles';
import StageLabel from 'components/StageLabel';
import { useDispatch } from 'react-redux';
import { openModalProperty } from 'modules/modal';

const PropertyBar = ({ property: p, stage, afterClose }) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(
      openModalProperty({
        property: p,
        afterClose: () => {
          if (afterClose) afterClose();
        }
      })
    );
  }, [p, dispatch]);

  return (
    <Wrapper onClick={onClick}>
      <Content>
        <Img src={p?.cover_image?.file_url} />
        <Text>
          {p?.title_formatted} - Ref: {p?.reference}
          <div>
            <Price transaction={p?.transaction}>{p.calculated_price}</Price>
          </div>
        </Text>
      </Content>
      {stage && <StageLabel stage={stage} />}
    </Wrapper>
  );
};

export default PropertyBar;
