import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { Select } from 'components/Form';
import { Form } from './styles';

const handleChangeSort = (history, sort) => {
  let currentSearchParams = new URLSearchParams(history.location.search);
  currentSearchParams.set('sort', sort || '-calculated_price');
  history.push({
    search: currentSearchParams.toString(),
  });
};

function FormSort() {
  return (
    <Form>
      <Field
        name="sort"
        options={[
          { label: 'Menor preço', value: 'calculated_price' },
          { label: 'Maior preço', value: '-calculated_price' },
          { label: 'Cadastro (Mais novo)', value: '-created_at' },
          { label: 'Cadastro (Mais antigo)', value: 'created_at' },
          { label: 'Atualização (Mais novo)', value: '-updated_at' },
          { label: 'Atualização (Mais antigo)', value: 'updated_at' },
        ]}
        component={Select}
      />
    </Form>
  );
}

export default compose(
  withRouter,
  reduxForm({
    form: 'FormSortSearch',
    initialValues: {
      sort: '-calculated_price',
    },
    onChange: (values, dispatch, props) => {
      handleChangeSort(props.history, values.sort);
    },
  })
)(FormSort);
