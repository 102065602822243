import { withValidation } from 'lib/validate-helpers';

export default values => {
  const { errors, validations } = withValidation(values);

  // Valida o cliente se existir o campo
  validations.required('person');

  return errors;
};
