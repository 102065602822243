import React from 'react';
import debounce from 'debounce';
import PropTypes from 'prop-types';
import Map from './Map';
import Streetview from './Streetview';
import Debug from './Debug';

const propTypes = {
  debug: PropTypes.bool,
  position: PropTypes.object,
  pov: PropTypes.object,
  height: PropTypes.number,
  isExactPlace: PropTypes.bool,
  onChange: PropTypes.func,
  handleSetPosition: PropTypes.func,
  handleSetPov: PropTypes.func,
  showStreetview: true,
};
const defaultProps = {
  debug: false,
  position: { lat: 49.2853171, lng: -123.1119202 },
  pov: { heading: 0, pitch: 0, zoom: 0 },
  height: 450,
  isExactPlace: false,
  onChange: null,
  handleSetPosition: null,
  handleSetPov: null,
};

const MapView = ({
  height,
  isExactPlace,
  showMarker,
  showStreetview,
  position,
  pov,
  zoom,
  handleDropMarker,
  handleChangePov,
  onZoomChange,
  style,
  debug,
}) => {
  return (
    <div className="MapView" style={style}>
      <div className="MapView__item">
        <Map
          zoom={zoom}
          isExactPlace={isExactPlace || showMarker}
          containerElement={<div style={{ height }} />}
          center={position}
          position={position}
          handleDropMarker={handleDropMarker}
          onZoomChange={onZoomChange}
        />
      </div>
      {showStreetview && isExactPlace && (
        <div className="MapView__item">
          <Streetview
            containerElement={<div style={{ height }} />}
            position={position}
            pov={pov}
            handleChangePov={handleChangePov && debounce(handleChangePov, 50)}
          />
        </div>
      )}
      {!!debug && <Debug pov={pov} position={position} />}
    </div>
  );
};

MapView.propTypes = propTypes;
MapView.defaultProps = defaultProps;

export default MapView;
