import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { Input } from 'components/Form';
import ListOptions from 'components/ListOptions';
import Container from 'components/Container';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const FormFinance = ({
  fileUrl,
  image,
  handleSubmit,
  submitting,
  pristine,
  history,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Container padding>
        <ListOptions>
          <ListOptions.item
            title="Título da área"
            text="Defina a frase que ficará no título da área"
          >
            <Field
              name="finance_title"
              component={Input}
              className="h-margin-top--10"
            />
          </ListOptions.item>
          <ListOptions.item
            title="Subtítulo da área"
            text="Defina a frase que ficará no subtítulo da área"
          >
            <Field
              name="finance_subtitle"
              component={Input}
              className="h-margin-top--10"
            />
          </ListOptions.item>
        </ListOptions>
      </Container>
      <FixedBar style={{ left: 240 }}>
        <Button onClick={history.goBack} color="white" colorText="secondary">
          Voltar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar
        </Button>
      </FixedBar>
    </form>
  );
};

FormFinance.defaultProps = defaultProps;
FormFinance.propTypes = propTypes;

const selector = formValueSelector('FormFinance');

const mapStateToProps = state => ({
  image: selector(state, 'image'),
  fileUrl: selector(state, 'file_url')
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'FormFinance',
    enableReinitialize: true
  }),
  withRouter
)(FormFinance);
