import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Alert from 'react-s-alert';
import { connect } from 'react-redux';
// Common Components
import { ModalTemplate } from 'components/Modal';
import FormUserPropertyTransfer from './FormUserPropertyTransfer';
// Services
import * as userService from 'services/user';
// Modules
import { getUsers } from 'modules/users';

class ModalUserPropertyTransfer extends React.Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    user: PropTypes.shape({
      people_exists: PropTypes.number,
      properties_exists: PropTypes.number,
      credits_exists: PropTypes.bool,
    }),
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null,

    user: {
      people_exists: null,
      properties_exists: null,
      credits_exists: null,
    },
  };

  handleSubmit = ({
    from_user_id,
    user_id,
    agent_id,
    people,
    properties_user,
  }) => {
    userService
      .transferUser({
        from_id: from_user_id,
        user_id,
        agent_id,
        properties_user,
        people,
      })
      .then(() => {
        this.props.getUsers();
      });
  };

  onSubmitSuccess = (...args) => {
    const { handleClose, onSubmitSuccess } = this.props;
    if (onSubmitSuccess) onSubmitSuccess(...args);

    Alert.success('Dados transferidos');

    // Fecha a modal quando termina de transferir e deletar o usuário
    handleClose();
  };

  get initialValues() {
    const { initialValues, user } = this.props;

    return {
      ...initialValues,
      properties: user.properties_exists,
      people: user.people_exists,
    };
  }

  render() {
    const {
      modalConfig,
      isOpen,
      handleClose,
      modalType,

      user,
    } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title={`Transferir dados de ${user.name}`}
          handleClose={handleClose}
        >
          <FormUserPropertyTransfer
            currentUser={user}
            onSubmit={this.handleSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
            initialValues={this.initialValues}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  getUsers,
};

export default connect(null, mapDispatchToProps)(ModalUserPropertyTransfer);
