import Button from 'components/Button';
import { Input, Select } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import FieldSelectUser from 'containers/FieldSelectUser';
import useFormValue from 'hooks/useFormValue';
import { isValidDate } from 'lib/date-helpers';
import { normalizeDate } from 'lib/formHelpers';
import moment from 'lib/moment';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import { useRealtors } from '../../hooks/useRealtors';
import { Label, WrapField } from './styles';

function Form({ handleClose, handleSubmit, change, submitting, pristine }) {  
  const { data } = useRealtors();
  const date = useFormValue('expires_at');
  const options = useFormValue('options');

  const getDate = () => {
    return isValidDate(date) ? date : moment().format('DD/MM/YYYY');
  };

  const handleAddDays = (days) => () => {
    const date = getDate('expires_at');
      change(
        'expires_at',
        moment(date, 'DD/MM/YYYY').add(days, 'days').format('DD/MM/YYYY')
      );
  };

    if (options === 1) {
      change('expires_at', null);
    }

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <FieldSelectUser label="Todo contato enviado para:" name="from_user_id" xs={6} users={data} />
        <FieldSelectUser label="Encaminhar para o corretor:" name="to_user_id" xs={6} users={data} />
      </Row>
      <Row>
        <Field
          xs={12}
          buttonTemplate
          label="Manter este encaminhamento ativo até quando?"
          name="options"
          component={Select}
          options={[
            { label: 'Indeterminado', value: 1 },
            { label: 'Definir data', value: 2 },
          ]}
          style={{ width: 260 }}
        >
          {options === 2 && (
            <WrapField>
              <Field
                name="expires_at"
                component={Input}
                normalize={normalizeDate}
                placeholder="DD/MM/YYYY"
              />
              <div className="Labels">
                <Label onClick={handleAddDays(15)}>+15</Label>
                <Label onClick={handleAddDays(30)}>+30</Label>
                <Label onClick={handleAddDays(60)}>+60</Label>
              </div>
            </WrapField>
          )}
        </Field>
      </Row>
      <Row>
        <Field
          label="Observações sobre o motivo do encaminhamento"
          xs={12}
          name="note"
          component={Input}
        />
      </Row>
      <ModalFooter withBorder>
        <Button type="button" onClick={handleClose} color="white">
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit"  disabled={submitting || pristine} color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormRedirectContact',
})(Form);
