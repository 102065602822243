import LOGOS from './logos';
import { AVALIABLE_FONTS } from 'constants/constants';

export { AVALIABLE_FONTS };

const DEFAULT_TEXTS = [
  {
    value: 'Corretor Exemplo',
    fontSize: '16px',
    fontFamily: 'Exo 2',
    fontWeight: '800',
    color: '#0183A1',
    isVisible: true,
  },
  {
    value: 'Seu site imobiliário',
    fontSize: '14px',
    fontFamily: 'Exo 2',
    fontWeight: '400',
    color: '#4C4C4C',
    isVisible: true,
  },
  {
    value: 'Creci 0000',
    fontSize: '12px',
    fontFamily: 'Exo 2',
    fontWeight: '400',
    color: '#4C4C4C',
    isVisible: true,
  },
];

const images = Object.keys(LOGOS).map((image) => {
  return {
    image: LOGOS[image],
    texts: DEFAULT_TEXTS,
  };
});

export default images;

//export default [
//  {
//    image: LOGOS.image1,
//    texts: [
//      {
//        value: 'Corretor Exemplo',
//        fontSize: '16px',
//        fontFamily: 'Open Sans',
//        fontWeight: '800',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//      {
//        value: 'Seu site imobiliário',
//        fontSize: '14px',
//        fontFamily: 'Open Sans',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//      {
//        value: 'Creci 0000',
//        fontSize: '12px',
//        fontFamily: 'Open Sans',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//    ],
//  },
//  {
//    image: LOGOS.image2,
//    texts: [
//      {
//        value: 'Corretor Exemplo',
//        fontSize: '16px',
//        fontFamily: 'Exo 2',
//        fontWeight: '800',
//        color: '#0183A1',
//        isVisible: true,
//      },
//      {
//        value: 'Seu site imobiliário',
//        fontSize: '14px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//      {
//        value: 'Creci 0000',
//        fontSize: '12px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//    ],
//  },
//  {
//    image: LOGOS.image3,
//    texts: [
//      {
//        value: 'Corretor Exemplo',
//        fontSize: '16px',
//        fontFamily: 'Exo 2',
//        fontWeight: '800',
//        color: '#0183A1',
//        isVisible: true,
//      },
//      {
//        value: 'Seu site imobiliário',
//        fontSize: '14px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//      {
//        value: 'Creci 0000',
//        fontSize: '12px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//    ],
//  },
//  {
//    image: LOGOS.image4,
//    texts: [
//      {
//        value: 'Corretor Exemplo',
//        fontSize: '16px',
//        fontFamily: 'Exo 2',
//        fontWeight: '800',
//        color: '#0183A1',
//        isVisible: true,
//      },
//      {
//        value: 'Seu site imobiliário',
//        fontSize: '14px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//      {
//        value: 'Creci 0000',
//        fontSize: '12px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//    ],
//  },
//  {
//    image: LOGOS.image5,
//    texts: [
//      {
//        value: 'Corretor Exemplo',
//        fontSize: '16px',
//        fontFamily: 'Exo 2',
//        fontWeight: '800',
//        color: '#0183A1',
//        isVisible: true,
//      },
//      {
//        value: 'Seu site imobiliário',
//        fontSize: '14px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//      {
//        value: 'Creci 0000',
//        fontSize: '12px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//    ],
//  },
//  {
//    image: LOGOS.image6,
//    texts: [
//      {
//        value: 'Corretor Exemplo',
//        fontSize: '16px',
//        fontFamily: 'Exo 2',
//        fontWeight: '800',
//        color: '#0183A1',
//        isVisible: true,
//      },
//      {
//        value: 'Seu site imobiliário',
//        fontSize: '14px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//      {
//        value: 'Creci 0000',
//        fontSize: '12px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//    ],
//  },
//  {
//    image: LOGOS.image7,
//    texts: [
//      {
//        value: 'Corretor Exemplo',
//        fontSize: '16px',
//        fontFamily: 'Exo 2',
//        fontWeight: '800',
//        color: '#0183A1',
//        isVisible: true,
//      },
//      {
//        value: 'Seu site imobiliário',
//        fontSize: '14px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//      {
//        value: 'Creci 0000',
//        fontSize: '12px',
//        fontFamily: 'Exo 2',
//        fontWeight: '400',
//        color: '#4C4C4C',
//        isVisible: true,
//      },
//    ],
//  },
//];
