import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { MdDelete, MdEdit } from 'react-icons/md';
import {
  Actions,
  ButtonLink,
  Content,
  List,
  NoDataText,
  Title,
  Wrapper,
} from './styles';
import Popover from 'components/Popover';

function ListArray({
  addText,
  renderText,
  noDataText,
  renderFields,
  renderActions,
  label,
  sort,
  input,
  formComponent: Component,
  handleRemove,
  onSubmit,
  onSubmitSuccess,
  canEdit,
  canRemove,
  ...props
}) {
  const update = useCallback(
    (index, item) => {
      try {
        input.onChange(input.value[index], item);
      } catch (e) {
        throw e;
      }
    },
    [input.value]
  );

  const remove = useCallback(
    (index) => {
      const items = input.value;

      const filterItems = items.filter((item, i) => {
        return i !== index;
      });

      input.onChange(filterItems);

      return filterItems;
    },
    [input.value]
  );

  const insert = (item) => {
    input.onChange([item, ...input.value]);
  };

  const push = (item) => {
    input.onChange([...input.value, item]);
  };

  const actions = {
    remove,
    update,
    insert,
    push,
    change: input.onChange,
  };

  const handleRenderFields = (item, index) => {
    const fieldName = `${input.name}[${index}]`;

    if (renderFields) return renderFields(fieldName, item);

    return (
      <li>
        <Content>{renderText(item)}</Content>
        <Actions>
          {renderActions &&
            renderActions(item, { currentIndex: index, actions })}
          {canEdit && (
            <Popover
              initialValues={item}
              data={item}
              currentIndex={index}
              width={240}
              onSubmit={onSubmit}
              onSubmitSuccess={onSubmitSuccess}
              handleRemove={handleRemove}
              component={Component}
              actions={actions}
            >
              <button className="h-color--primary">
                <MdEdit>{addText}</MdEdit>
              </button>
            </Popover>
          )}
          {canRemove && (
            <button
              type="button"
              className="h-color--danger"
              onClick={() => {
                handleRemove(item, {
                  actions,
                  currentIndex: index,
                  handleClose: () => {},
                });
              }}
            >
              <MdDelete />
            </button>
          )}
        </Actions>
      </li>
    );
  };

  return (
    <Wrapper>
      <Title>{label}</Title>
      {!isEmpty(input.value) ? (
        <List>{input?.value?.sort(sort)?.map(handleRenderFields)}</List>
      ) : (
        <NoDataText>{noDataText}</NoDataText>
      )}
      <Popover
        width={240}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        component={Component}
        actions={actions}
      >
        <ButtonLink>{addText}</ButtonLink>
      </Popover>
    </Wrapper>
  );
}

ListArray.defaultProps = {
  canEdit: true,
  label: null,
  addText: 'Adicionar',
  noDataText: 'Nenhuma informação',
  name: null,
  renderLabel: null,
  renderActions: null,
  sort: () => 1,
  form: {
    initialValues: null,
    onSubmit: null,
    component: null,
  },
  renderFields: null,
};

export default ListArray;
