import api from 'services';

// Criador de ação
import { createModule } from 'lib/reducer-helpers';

const createAction = createModule('prices');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const RESET = createAction('RESET');

export const pricesSelector = (state) => state.prices;

const INITIAL_STATE = {
  data: {
    subscription_price: null,
    user_price: null,
    mailbox_price: null,
    monthly_price: null,
    bs_min_value: null,
    cc_min_value: null,
    cc_max_installments_12m: null,
    cc_max_installments_6m: null,
    cc_max_installments_3m: null,
    bs_max_installments_12m: null,
    bs_max_installments_6m: null,
    bs_max_installments_3m: null,
    discount_6m: null,
    discount_12m: null,
  },
  isFetching: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case RECEIVE:
      return { ...state, data: action.data, isFetching: false };
    case RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export const request = () => ({ type: REQUEST });
export const receive = (data) => ({ type: RECEIVE, data });
export const reset = () => ({ type: RESET });

export const fetchPrices = () => async (dispatch) => {
  try {
    dispatch(request());

    const { data: prices } = await api.get('sales/prices');

    dispatch(receive(prices));

    return prices;
  } catch {
    dispatch(reset());
  }
};
