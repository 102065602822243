import React, { useCallback } from 'react';
import Header from './components/Header';
import TimelineTree from 'components/TimelineTree';
import { openModalPerson } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { useRequest } from 'hooks/useRequest';
import { getHistory } from 'services/stories';

// import { Wrapper } from './styles';

function Dashboard({ person }) {
  const { data, isFetching, fetchData } = useRequest({
    request: (category) => getHistory({ logeable_id: person?.id, category }),
  });

  const dispatch = useDispatch();

  const handleChangeFilter = (value) => {
    console.log('value: ', value);
    fetchData(value);
  };

  const handleOpenMySelf = useCallback(
    (params) => {
      dispatch(openModalPerson(person, params));
    },
    [person, dispatch]
  );

  return (
    <div>
      <Header person={person} onChangeFilter={handleChangeFilter} />
      <TimelineTree
        isFetching={isFetching}
        data={data}
        onCloseModal={handleOpenMySelf}
      />
    </div>
  );
}

export default Dashboard;
