import qs from 'qs';
import { Col, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import AlertRC from 'react-s-alert';
import { Field, reduxForm } from 'redux-form';
// Modules
import { signUp } from 'modules/login';
// Components
import Button from 'components/Button';
import { Checkbox, Input, Select } from 'components/Form';
// Templates
import FormTitle from 'templates/Login/FormTitle';
// Helpers
import { normalizeLowercase, normalizePhone } from 'lib/formHelpers';
// Validate
import { TECIMOB_RESALE_ID } from 'constants/config';
import { getObjectReferrer } from 'lib/referrer';
import { compose } from 'redux';
import validate from './validate';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import * as userService from 'services/user';
import InviteError from './components/InviteError';

const workOptions = [
  { label: 'Corretor de imóveis', value: 'Corretor de imóveis' },
  { label: 'Imobiliária', value: 'Imobiliária' },
  { label: 'Construtora', value: 'Construtora' },
  { label: 'Loteadora', value: 'Loteadora' },
  { label: 'Agência de marketing', value: 'Agência de marketing' },
  { label: 'Outro', value: 'Outro' },
];

const teamSizeOptions = [
  { label: '1 pessoa', value: '1 pessoa' },
  { label: '2 a 5 pessoas', value: '2 a 5 pessoas' },
  { label: '6 a 10 pessoas', value: '6 a 10 pessoas' },
  { label: '10 a 20 pessoas', value: '10 a 20 pessoas' },
  { label: 'Mais de 20 pessoas', value: 'Mais de 20 pessoas' },
];

const marketTimeOptions = [
  { label: 'Menos de 1 ano', value: 'Menos de 1 ano' },
  { label: '1 a 5 anos', value: '1 a 5 anos' },
  { label: '5 a 10 anos', value: '5 a 10 anos' },
  { label: 'Mais de 10 anos', value: 'Mais de 10 anos' },
  { label: 'Ainda não tenho CRECI', value: 'Ainda não tenho CRECI' },
];

const defaultProps = {};
const propTypes = {};

function SignUp({ handleSubmit, submitting, change }) {
  const [inviteError, setInviteError] = useState(null);

  const location = useLocation();

  useEffect(() => {
    async function verifyInvite() {
      const invite = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })?.invite;

      if (invite) {
        await userService
          .verifyInvite(invite)
          .then(({ data }) => {
            change('invite', invite);
            change('name', data.name);
            change('cellphone', data.cellphone);
          })
          .catch((err) => {
            setInviteError(err.response.data.error);
          });
      }
    }

    verifyInvite();
  }, [location.search]);

  if (inviteError) {
    return <InviteError error={inviteError} />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Helmet>
        <title>Cadastro</title>
      </Helmet>
      <FormTitle
        title="Vamos começar?"
        text="Sem contrato de fidelidade e sem surpresas desagradáveis."
      />
      <Row>
        <Field type="hidden" name="register_origin" component="input" />
        <Field type="hidden" name="invite" component="input" />
        <Field
          xs={12}
          label="Nome"
          name="name"
          placeholder="Digite seu nome"
          component={Input}
        />
        <Field
          xs={6}
          type="tel"
          label="Telefone"
          name="cellphone"
          component={Input}
          placeholder="Digite seu número"
          normalize={normalizePhone}
        />
        <Field
          xs={6}
          type="email"
          label="E-mail"
          name="email"
          component={Input}
          placeholder="Digite seu e-mail"
          normalize={normalizeLowercase}
        />
      </Row>
      <Row>
        <Field
          xs={6}
          type="password"
          label="Senha"
          name="password"
          component={Input}
          placeholder="Digite sua senha"
        />
        <Field
          xs={6}
          type="password"
          label="Confirmar Senha"
          name="password_confirm"
          component={Input}
          placeholder="Digite sua confirmação"
        />
      </Row>
      <Row>
        <Field
          xs={12}
          name="work_option"
          placeholder="Qual sua forma de trabalho?"
          component={Select}
          options={workOptions}
        />
        <Field
          xs={12}
          name="team_size"
          placeholder="Qual tamanho da sua equipe?"
          component={Select}
          options={teamSizeOptions}
        />
        <Field
          xs={12}
          name="market_time"
          placeholder="Qual seu tempo no mercado?"
          component={Select}
          options={marketTimeOptions}
        />
      </Row>
      <Row className="h-margin-top--5">
        <Col xs={12}>
          <Field
            type="checkbox"
            name="accept_terms"
            label={
              <>
                Concordo com{' '}
                <a
                  tabIndex={-1}
                  href={'https://tecimob.com.br/termos-de-uso'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="h-color--secondary h-link"
                >
                  Termos de uso
                </a>
                {` `}e{' '}
                <a
                  tabIndex={-1}
                  href={'https://tecimob.com.br/politica-privacidade'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="h-color--secondary h-link"
                >
                  Privacidade
                </a>
              </>
            }
            component={Checkbox}
          />
        </Col>
      </Row>
      <Button block disabled={submitting} type="submit" color="primary">
        Criar minha conta
      </Button>
    </form>
  );
}

SignUp.defaultProps = defaultProps;
SignUp.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => ({
  login: state.login,
  initialValues: {
    resale_id: TECIMOB_RESALE_ID,
    email: ownProps.match.params.email,
    register_origin: `painel tecimob`,
    accept_terms: false,
  },
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'signup',
    initialValues: {
      email: localStorage.email || '',
    },
    onSubmit: (values, dispatch, props) => {
      const search = props?.location?.search;
      const searchObj = qs.parse(search, { ignoreQueryPrefix: true });

      let currentValues = values;

      if (searchObj?.channel) {
        currentValues = {
          ...currentValues,
          lead_origin: searchObj?.channel,
        };
      }

      if (!values.accept_terms) {
        AlertRC.success('Você precisa aceitar os termos de uso para continuar');
        return false;
      }

      currentValues = {
        ...currentValues,
        http_referrer: getObjectReferrer(),
      };

      return dispatch(signUp(currentValues));
    },
    validate,
    enableReinitialize: true,
    asyncBlurFields: ['email'],
  })
)(SignUp);
