import React from 'react';
import Item from './components/Item';
// import { Container } from './styles';

const NotesList = ({
  data,
  isFetching,
  afterClose,
  onClickEdit,
  onRemoveNote,
  onUpdate,
}) => {
  if (isFetching) return null;

  return data?.map((item) => (
    <Item
      afterClose={afterClose}
      key={item?.id}
      data={item}
      onClickEdit={onClickEdit}
      onRemoveNote={onRemoveNote}
      onUpdate={onUpdate}
    />
  ));
};

export default NotesList;
