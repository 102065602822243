import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Components
import Label from 'components/Label';
import Button from 'components/Button';
import Check from 'components/Check';
import TextOverflow from 'components/TextOverflow';

class PropertyItem extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    property: PropTypes.shape({
      domain: PropTypes.string,
      status: PropTypes.number,
      statusLabel: PropTypes.string,
      main: PropTypes.bool
    })
  };

  static defaultProps = {
    property: {}
  };

  get lotDescription() {
    const { property } = this.props;
    return _.get(property, 'informations.lot_description.value') || '--';
  }

  get totalArea() {
    try {
      const {
        property: {
          areas: { total_area, ground_total_area }
        }
      } = this.props;

      if (ground_total_area) {
        return ground_total_area.value + ground_total_area.measure;
      }

      return total_area.value + total_area.measure;
    } catch {
      return '-';
    }
  }

  get renderBedrooms() {
    const { property } = this.props;
    return <td>{_.get(property, 'rooms.bedroom.title_formatted')}</td>;
  }

  get renderCheck() {
    const { property, canSelect, toggleSelected } = this.props;

    if (property.is_blocked) {
      return (
        <td>
          <Label color="danger">Bloqueado</Label>
        </td>
      );
    }

    // Verifica se pode selecionar
    if (!canSelect) return null;

    return (
      <td>
        <Check
          checked={property.isChecked}
          onClick={e => {
            e.stopPropagation();
            toggleSelected(property.id);
          }}
        />
      </td>
    );
  }

  render() {
    const {
      handleOpenProperty,
      property,
      property: { reference, calculated_price }
    } = this.props;

    return (
      <tr className="h-hover h-pointer" onClick={handleOpenProperty(property)}>
        {this.renderCheck}
        <td>{reference}</td>
        <td>
          <TextOverflow style={{ maxWidth: '200px' }}>
            {this.lotDescription}
          </TextOverflow>
        </td>
        <td>{this.totalArea}</td>
        <td>{calculated_price}</td>
        <td width={1}>
          <Button color="secondary" colorText="white" size="small">
            Ver imóvel
          </Button>
        </td>
      </tr>
    );
  }
}

export default PropertyItem;
