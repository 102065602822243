import React, { useEffect } from 'react';
import { Row } from 'react-flexbox-grid';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
import { MdAdd, MdHelp } from 'react-icons/md';
import TableStages from 'pages/Crm/pages/Config/components/TableStages';
import { useFormUserId } from 'pages/Crm/components/FormUser';
import { useDispatch } from 'react-redux';
import {
  openModalDealDelete,
  openModalDealTransfer,
  openModalStageStore,
} from 'modules/modal';
import { arrayMove } from 'react-sortable-hoc';
import * as dealsService from 'services/deals';
import Alert from 'react-s-alert';
import { Field, reduxForm } from 'redux-form';
import { Input } from 'components/Form';
import { useParams } from 'react-router-dom';
import { useStagesFunnel } from 'pages/Crm/hooks/useStagesFunnel';
import FixedBar from 'components/FixedBar';
import { useRequest } from 'hooks/useRequest';

function Funnel({ handleSubmit, initialize }) {
  const userId = useFormUserId();
  const { id: funnelId } = useParams();

  const { data, fetchStages, setData } = useStagesFunnel(funnelId, {
    initialFetch: false,
  });

  const { data: funnel } = useRequest({
    request: () => dealsService.getFunnel(funnelId),
  });

  const dispatch = useDispatch();

  const handleClickOpenStageStore = (stage) => {
    let props = {
      onSuccess: () => {
        fetchStages();
      },
    };

    if (stage) {
      props.initialValues = { funnel_id: funnelId, ...stage };
    } else {
      props.initialValues = {
        funnel_id: funnelId,
        user_id: userId,
      };
    }

    dispatch(openModalStageStore(props));
  };

  const handleChangeOrder = ({ oldIndex, newIndex, collection }) => {
    const stage = data.find((item, index) => index === oldIndex);
    const newItems = arrayMove(data, oldIndex, newIndex);
    const orderedItems = newItems.map((item, index) => ({
      ...item,
      order: index + 1,
    }));

    setData(orderedItems);

    dealsService.updatePositionStage(null, stage.id, newIndex + 1);
  };

  const handleClickUpdate = (stage) => () => {
    handleClickOpenStageStore(stage);
  };

  const handleClickRemove = (stage) => () => {
    // verifica se a etapa tem negócios
    const hasDeals = stage.people_count;

    // if (!hasDeals) {
    //   dispatch(
    //     openConfirmation({
    //       title: `Remover etapa: ${stage.name}`,
    //       text: 'Tem certeza que deseja remover essa etapa?',
    //       request: () => {
    //         return dealsService.deleteStage(stage.id);
    //       },
    //       onSuccess: () => {
    //         Alert.success('Etapa excluída com sucesso');
    //         fetchStages();
    //       },
    //     })
    //   );
    //
    //   return null;
    // }

    dispatch(
      openModalDealDelete({
        stage,
        userId,
        funnelId,
        onSuccess: () => {
          fetchStages();
        },
      })
    );
  };

  const handleClickTransfer = (stage) => () => {
    if (stage.people_count <= 0) {
      Alert.success('Nenhum negócio para transferir dessa etapa');
      return;
    }

    dispatch(
      openModalDealTransfer({
        stage,
        userId,
        funnelId,
        onSuccess: () => {
          // Atualiza a listagem de negócios
          // pra saber que ele não tem mais negócios em aberto
          fetchStages();
        },
      })
    );
  };

  useEffect(() => {
    if (userId) {
      fetchStages();
    }
  }, [userId]);

  useEffect(() => {
    if (funnel) {
      initialize({
        ...funnel,
      });
    }
  }, [funnel]);

  return (
    <Wrapper.inner>
      <Wrapper.container style={{ paddingBottom: '50px' }}>
        <form onSubmit={handleSubmit}>
          <MainTitle
            title="Configuração de funil"
            text="Lista de funis existentes"
          >
            <Button
              color="secondary"
              onClick={() => handleClickOpenStageStore()}
            >
              <MdAdd />
              Nova etapa
            </Button>
          </MainTitle>
          <Row>
            <Field xs={4} label="Nome do funil" name="name" component={Input} />
            <Field
              xs={4}
              label="Descrição do funil"
              name="description"
              component={Input}
            />
            <Field
              xs={4}
              label={
                <div>
                  Limpeza em{' '}
                  <MdHelp data-tip="Prazo em dias que o negócio será encerrado automaticamente por estagnação." />
                </div>
              }
              name="clear_after"
              component={Input}
            />
          </Row>
          <TableStages
            data={data}
            handleChangeOrder={handleChangeOrder}
            handleClickRemove={handleClickRemove}
            handleClickUpdate={handleClickUpdate}
            handleClickTransfer={handleClickTransfer}
            handleClickOpenStageStore={handleClickOpenStageStore}
          />
          <FixedBar>
            <Button color="white" to="/crm/config">
              Voltar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              Salvar
            </Button>
          </FixedBar>
        </form>
      </Wrapper.container>
    </Wrapper.inner>
  );
}

export default reduxForm({
  form: 'FormFunnel',
  onSubmit: (values) => {
    return dealsService.updateFunnel(values);
  },
  onSubmitSuccess: (response, dispatch, props) => {
    props.history.push('/crm/config');
  },
})(Funnel);
