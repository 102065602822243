import React from 'react';
import { Field } from 'redux-form';
// Components
import { Select2 } from 'components/Form';

const RealtorsField = ({ options, ...props }) => (
  <Field
    label="Corretor Responsável"
    name="user_id"
    labelKey="name"
    valueKey="id"
    options={options}
    component={Select2}
    selectedItemsLabel="corretores"
    {...props}
  />
);

export default RealtorsField;
