import Button from "components/Button";
import Container from "components/Container";
import Loading from "components/Loading";
import Wrapper from "components/Wrapper";
import { getInviteMessage } from "containers/ModalRoot/ModalRecommendation";
import { useCurrentUser } from "hooks/useCurrentUser";
import qs from 'qs';
import TableUsers from "../TableUsers";

const { default: MainTitle } = require("components/MainTitle");

function List({ pagination, data, setParams, isFetching }) {
  const handleChangePage = (page) => {
    setParams((prev) => ({ ...prev, offset: page.selected + 1 }));
  }

  const currentUser = useCurrentUser();

  const handleRecommend = () => {
    const query = qs.stringify({
      text: getInviteMessage(currentUser?.id),
    });

    window.open(`https://api.whatsapp.com/send?${query}`, '_blank').focus();
  }

  if (isFetching) return <Loading isFullScreen isVisible />;

  return (
    <Wrapper.inner>
      <Wrapper.container>
        <MainTitle
          title="Lista de recomendações"
          text="Lista dos leads gerados através da sua recomendação."
        >
          <Button color="success" onClick={handleRecommend}>
            Recomendar
          </Button>
        </MainTitle>
        <Container shadow padding style={{ marginBottom: '60px' }}>
          <TableUsers
            data={data}
            pagination={pagination}
            handleChangePage={handleChangePage}
          />
        </Container>
      </Wrapper.container>
    </Wrapper.inner>
  );
}

export default List;
