import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const CellLink = styled(Link)`
  position: absolute;
  color: inherit;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Cell = styled.td`
  position: relative;

  ${p =>
    p.hasLink &&
    css`
      :hover {
        text-decoration: underline;
      }
    `}
`;
