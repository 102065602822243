import React from 'react';
import Modal from 'react-modal';

// Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';

function ModalGroupUserRemove({
  group,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onCancel,
  onSubmitSuccess,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Remover grupo" handleClose={handleClose}>
        <Form
          data={group}
          initialValues={group}
          handleClose={handleClose}
          onCancel={onCancel}
          onSuccess={onSubmitSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalGroupUserRemove;
