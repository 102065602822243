import { MdEdit } from "react-icons/md";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
`;

export const PixKey = styled.span`
  flex: 1;
  word-break: break-all;
`;

export const EditIcon = styled(MdEdit)`
  vertical-align: center;
  margin-left: 5px;
  cursor: pointer;
`;
