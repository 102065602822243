import styled, { css } from 'styled-components';

export const Popover = styled.div`
  position: absolute;
  bottom: calc(100% + 15px);
  width: 200px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 15px;

  // Transition
  opacity: 0;
  visibility: hidden;
  translate: 0 -15px;
  transition: all 0.2s ease-in-out;
  will-change: visibility, translate, opacity;

  ${p => p.pin === 'left' && css`
    left: 0;
  `}

  ${p => p.pin === 'right' && css`
    right: 0;
  `}

  img {
    width: 100%;
    margin-top: 10px;
  }
`;

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  z-index: 5;

  a {
    display: block;
  }

  img {
    display: block;
  }

  & + & {
    margin-left: 5px;
  }

  :hover ${Popover} {
    visibility: visible;
    opacity: 1;
    translate: 0;
  }
`;

export const Title = styled.span`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  color: ${(p) => p.theme.colors.primary};
`;
