import React, { memo } from 'react';
import { Field } from 'redux-form';
// Components
import { SelectMultiple } from 'components/Form';
import Button from 'components/Button';
// Helpers
import { parseSelect } from 'lib/formHelpers';
import { getHours } from 'lib/date-helpers';
import Divider from 'components/Divider';
import Nodata from 'components/Nodata';
import { MdAdd } from 'react-icons/md';

const propTypes = {};
const defaultProps = {};

const DAYS_ON_WEEK = [
  { value: 'EVERY_DAY', label: 'Todos os dias' },
  { value: 'MON_FRI', label: 'Seg - Sex' },
  { value: 'SAT_SUN', label: 'Sáb - Dom' },
  { value: 'MONDAY', label: 'Segunda-feira' },
  { value: 'TUESDAY', label: 'Terça-feira' },
  { value: 'WEDNESDAY', label: 'Quarta-feira' },
  { value: 'THURSDAY', label: 'Quinta-feira' },
  { value: 'FRIDAY', label: 'Sexta-feira' },
  { value: 'SATURDAY', label: 'Sábado' },
  { value: 'SUNDAY', label: 'Domingo' },
];

const renderFields = (dayHour, index, fields, onSubmit, min) => {
  const hours = getHours();
  const { start, end } = fields.get(index);

  const hoursStart = hours.filter((hour) => hour.value < end);
  const hoursEnd = hours.filter((hour) => hour.value > start);

  const canShowRemove = fields.length !== min;

  return (
    <div className="HourBlock">
      <Field
        label="Dias"
        className="HourBlock__item HourBlock__item--day"
        multi={false}
        name={`${dayHour}.day`}
        options={DAYS_ON_WEEK}
        component={SelectMultiple}
        parse={parseSelect('value')}
      />
      <Field
        label="De"
        clearable={false}
        multi={false}
        className="HourBlock__item HourBlock__item--hour"
        name={`${dayHour}.start`}
        component={SelectMultiple}
        options={hoursStart}
        parse={parseSelect('value')}
      />
      <Field
        label="Até"
        clearable={false}
        multi={false}
        className="HourBlock__item HourBlock__item--hour"
        name={`${dayHour}.end`}
        component={SelectMultiple}
        options={hoursEnd}
        parse={parseSelect('value')}
      />
      {canShowRemove ? (
        <div
          className="HourBlock__action"
          onClick={() => {
            if (fields.getAll().length <= 1) {
              onSubmit([]);
            }
            fields.remove(index);
          }}
        >
          <Button type="button" color="danger">
            Remover
          </Button>
        </div>
      ) : null}
    </div>
  );
};

function HourBlocks({
  fields,
  min,
  onSubmit,
  noDataConfig = {
    title: 'Nenhum horário configurado',
    text: (
      <>
        Adicione as horas em que o usuário
        <br />
        estará disponível no seu Chat
      </>
    ),
  },
}) {
  return (
    <div>
      {fields.length > 0 ? (
        <>
          {fields.map((dayHour, index, fields) => {
            return renderFields(dayHour, index, fields, onSubmit, min);
          })}
          <Divider />
          <div className="h-flex">
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                fields.push({ day: 'EVERY_DAY', start: '08:00', end: '12:00' });
              }}
            >
              Adicionar horas
            </Button>
            <div className="h-flex__cell--grow" />
            {onSubmit && (
              <Button
                type="button"
                onClick={() => {
                  onSubmit(fields);
                }}
                color="success"
              >
                Salvar
              </Button>
            )}
          </div>
        </>
      ) : (
        <Nodata
          image={null}
          title={noDataConfig.title}
          text={noDataConfig.text}
          style={{ padding: '0' }}
        >
          <Button
            color="secondary"
            type="button"
            onClick={() => {
              fields.push({ day: 'EVERY_DAY', start: '08:00', end: '12:00' });
            }}
            style={{ marginTop: '15px' }}
          >
            <MdAdd /> Adicionar horário
          </Button>
        </Nodata>
      )}
    </div>
  );
}

HourBlocks.propTypes = propTypes;
HourBlocks.defaultProps = defaultProps;

export default memo(HourBlocks);
