import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Components
import { Input, Select } from 'components/Form';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Button from 'components/Button';
import GroupControl from 'components/GroupControl';
import GroupItem from 'components/GroupControl/components/GroupItem';
import validate from './validate';

const propTypes = {
  domain: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

const EmailAliasForm = ({ domains, handleSubmit, handleClose }) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col xs={12}>
        <GroupControl label="De" required>
          <GroupItem>
            <Field name="address" component={Input} />
          </GroupItem>
          <GroupItem size="small">
            <Field
              autoWidth
              name="domain_id"
              labelKey="email_url"
              valueKey="id"
              options={domains.data || []}
              placeholder=""
              loading={domains.isFetching}
              component={Select}
            />
          </GroupItem>
        </GroupControl>
      </Col>
    </Row>
    <Row>
      <Field disabled xs={12} label="Para" name="goto" component={Input} />
    </Row>
    <ModalFooter>
      <Button color="white" colorText="secondary" onClick={handleClose}>
        Cancelar
      </Button>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success">
        Salvar
      </Button>
    </ModalFooter>
  </form>
);

EmailAliasForm.propTypes = propTypes;

export default reduxForm({
  form: 'EmailAliasForm',
  validate,
  enableReinitialize: true
})(EmailAliasForm);
