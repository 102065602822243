// import styles from './styles'
import Box from '../../components/Box';
import { useSelector } from 'react-redux';
import {
  currentUserIsFetchingSelector,
  currentUserSelector,
} from 'modules/login';
import { useMemo, useState } from 'react';
import { DAYS_TO_SHOW_ALERT } from 'containers/CreciAlert/constants';
import { Screens } from 'containers/CreciAlert/screens';

export default function StartValidation() {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const currentUser = useSelector(currentUserSelector);
  const isFetching = useSelector(currentUserIsFetchingSelector);
  const handleClick = () => {
    setIsFormVisible(true);
  };

  const days = currentUser?.creci_expiration;
  const daysTwoDigits = days?.toString?.()?.padStart?.(2, '0');

  const message = useMemo(() => {
    if (days <= 1) {
      return (
        <>
          <strong>01</strong> dia
        </>
      );
    }

    return (
      <>
        <strong>{daysTwoDigits}</strong> dias
      </>
    );
  }, [days, daysTwoDigits]);

  // Mostrar essa tela só se estiver no terceiro dia de uso do sistema
  // Então é só pegar o days e verificar se é <= 4
  if (days >= DAYS_TO_SHOW_ALERT || isFetching) return null;

  if (isFormVisible) return <Screens.FormValidation />;

  return (
    <Box
      action={{
        onClick: handleClick,
        text: 'Iniciar Verificação',
      }}
    >
      Para fazer anúncios com Tecimob, é necessário validar seu CRECI. Você tem{' '}
      {message} para concluir o processo
    </Box>
  );
}
