import moment from 'lib/moment';

const TODAY = moment().format('DD/MM/YYYY');
const YESTERDAY = moment()
  .subtract(1, 'day')
  .format('DD/MM/YYYY');

export const getKeyName = date => {
  let key = moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');

  if (key === TODAY) {
    key = 'Hoje';
  }

  if (key === YESTERDAY) {
    key = 'Ontem';
  }

  return key;
};
