import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCategories } from '../hooks/useCategories';
import { useTemplates } from '../hooks/useTemplates';
import { useTemplateHistory } from '../hooks/useTemplateHistory';
import { DEFAULT_CATEGORY } from 'containers/ModalRoot/ModalSiteTemplate/constants';

export const TemplateSelectorContext = createContext({
  // Categories
  category: null,
  setCategory: () => null,
  categories: null,
  isFetchingCategories: null,

  // Templates
  templates: null,
  fetchTemplates: () => null,
  isFetchingTemplates: null,
  applyTemplate: (template_id) => null,

  // History
  history: null,
  isFetchingHistory: null,
  fetchHistory: () => null,

  // Modal Actions
  handleClose: () => null,
});

export const useTemplateContext = () => useContext(TemplateSelectorContext);

export function TemplateProvider({ handleClose, children }) {
  const [category, setCategory] = useState(DEFAULT_CATEGORY);

  const { categories, isFetchingCategories } = useCategories();
  const { templates, isFetchingTemplates, fetchTemplates, applyTemplate } =
    useTemplates();
  const { history, isFetchingHistory, fetchHistory } = useTemplateHistory();

  useEffect(() => {
    if (category !== 'HISTORY') fetchTemplates(category);
  }, [category]);

  const value = {
    // Categories
    category,
    setCategory,
    categories,
    isFetchingCategories,

    // Templates
    templates,
    fetchTemplates,
    isFetchingTemplates,
    applyTemplate,

    // History
    history,
    isFetchingHistory,
    fetchHistory,

    // Modal Actions
    handleClose,
  };

  const renderedChildren =
    typeof children === 'function' ? children(value) : children;

  return (
    <TemplateSelectorContext.Provider value={value}>
      {renderedChildren}
    </TemplateSelectorContext.Provider>
  );
}
