// Widgets.js
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import menuModule from './menu';
import propertyInfoReducer from './propertyInfo';
import businessReducer from '../containers/ModalRoot/ModalProperty/pages/Business/reducer';

export const { reducer: menuReducer, thunks: menuThunks } = menuModule(
  'modalProperty',
  {
    pageName: 'Record',
  }
);

export const { reducer: menuGallery, thunks: menuGalleryThunks } = menuModule(
  'modalProperty/gallery',
  { pageName: '1' }
);

// pega as informacoes do imóvel
export const infosSelector = (state) => state.modalProperty.rootInfo.infos.data;
export const isFetchingSelector = (state) =>
  state.modalProperty.rootInfo.infos.meta.isFetching;

// pega todas as imagens do imóvel
export const allImagesSelector = (state) =>
  state.modalProperty.rootInfo.images.data;

export const gallerySelector = createSelector(allImagesSelector, (images) => {
  const initialState = [
    {
      name: 'Fotos',
      data: [],
    },
    {
      name: 'Privadas',
      data: [],
    },
    {
      name: 'Planta',
      data: [],
    },
  ];

  if (!images) return {};

  return images.reduce((arr, image) => {
    const currentImage = { ...image, src: image.file_url };

    switch (image.gallery) {
      case 2:
        arr.find(({ name }) => name === 'Planta').data.push(currentImage);
        break;
      case 3:
        arr.find(({ name }) => name === 'Privadas').data.push(currentImage);
        break;
      case 1:
      default:
        arr
          .find(({ name }) => {
            return name === 'Fotos';
          })
          .data.push(currentImage);
        break;
    }

    return arr;
  }, initialState);
});

// Pega a pagina atual do menu
export const currentPageSelector = (state) =>
  state.modalProperty.menuReducer.pageName;
export const currentPageGallerySelector = (state) =>
  state.modalProperty.menuGallery.pageName;
export const dealsSelector = (state) => state.modalProperty.deals.deals.data;

export default combineReducers({
  menuReducer,
  menuGallery,
  deals: businessReducer,
  rootInfo: propertyInfoReducer,
});
