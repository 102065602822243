import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Video from 'react-youtube';
import getVideoId from 'get-video-id';
// Common Components
import { ModalTemplate } from '../../../components/Modal';

const STATUS_VIDEO = {
  PAUSED: 'paused',
  PLAYING: 'playing',
  ENDED: 'ended',
};

class ModalVideo extends Component {
  state = {
    status: STATUS_VIDEO.PAUSED,
  };

  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    playerVars: PropTypes.shape({
      autoplay: PropTypes.number,
      rel: PropTypes.number,
      playsinline: PropTypes.bool,
      color: PropTypes.string,
      iv_load_policy: PropTypes.number,
      modestbranding: PropTypes.number,
    }),
    title: PropTypes.node,
    videoUrl: PropTypes.string.isRequired,
    renderAction: PropTypes.func,
    onEnd: PropTypes.func,
  };

  static defaultProps = {
    playerVars: {
      autoplay: 1,
      rel: 0,
      playsinline: true,
      color: 'white',
      iv_load_policy: 3,
      modestbranding: 1,
    },
    title: 'Vídeo',
    videoUrl: null,
    renderAction: null,
    onEnd: null,
  };

  get title() {
    const { title } = this.props;
    return title;
  }

  get videoId() {
    const { videoUrl } = this.props;

    try {
      return getVideoId(videoUrl).id;
    } catch {
      return null;
    }
  }

  get videoOpts() {
    const { playerVars } = this.props;

    // https://developers.google.com/youtube/player_parameters
    return {
      playerVars,
    };
  }

  setVideoStatus(status) {
    this.setState({
      status,
    });
  }

  onPlay = () => {
    this.setVideoStatus(STATUS_VIDEO.PLAYING);
  };

  onEnd = () => {
    const { onEnd, handleClose } = this.props;
    this.setVideoStatus(STATUS_VIDEO.ENDED);
    if (!onEnd) return null;
    onEnd({ handleClose });
  };

  get renderAction() {
    const { renderAction, handleClose } = this.props;
    const { status } = this.state;
    if (!renderAction) return null;
    return renderAction({ status, handleClose });
  }

  handleClose = () => {
    this.props.handleClose();
    if (this.props.afterClose) this.props.afterClose();
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType, link } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={this.handleClose}
      >
        <ModalTemplate
          renderHeader={() => (
            <header className="Modal__header Modal__header--custom h-flex h-flex--center-center">
              {this.title}
              <div className="h-flex__cell h-flex__cell--grow" />
              {this.renderAction}
            </header>
          )}
          handleClose={this.handleClose}
        >
          <Video
            containerClassName="h-embed-wrapper h-16-9"
            className="h-embed"
            videoId={this.videoId}
            opts={this.videoOpts}
            onEnd={this.onEnd}
            onPlay={this.onPlay}
          />
          {link ? (
            <p style={{ textAlign: 'center', margin: '20px auto 5px' }}>
              Acessar{' '}
              <a href={link} target="_blank" className="h-link">
                Central de Ajuda
              </a>
            </p>
          ) : null}
        </ModalTemplate>
      </Modal>
    );
  }
}

export default ModalVideo;
