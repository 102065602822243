import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import { FieldBool, Input } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import PreviewStripe from 'pages/PropertyStore/pages/Publish/components/PreviewStripe';
import FieldFeaturedLists from 'containers/FieldFeaturedLists';
import useFormValue from 'hooks/useFormValue';
import { useFeaturedLists } from 'hooks/api/featuredLists';
import { useEffect } from 'react';

function FormProperty({ handleSubmit, change, handleClose, submitting }) {
  const isFeatured = useFormValue('is_featured');

  const { dataFeaturedLists, fetchFeaturedLists } = useFeaturedLists({
    with_all: false,
  });

  useEffect(() => {
    fetchFeaturedLists();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Field xs={6} label="Referência" name="reference" component={Input} />
      </Row>
        {dataFeaturedLists?.featured_lists?.length > 0 ? (
          <Row>
             <FieldBool
              xs={5}
              label="Destaque?"
              name="is_featured"
              onChange={() => {
                change('featured_list_id', null);
              }}
            />
            <FieldFeaturedLists
              disabled={!isFeatured}
              xs={6}
              label="Galeria"
              style={{ minWidth: '249px' }}
            />
          </Row>
        ): null}
      <Row>
        <Col xs={12}>
          <PreviewStripe />
        </Col>
      </Row>
      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Editar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormProperty',
  enableReinitialize: true,
})(FormProperty);
