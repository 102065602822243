import Button from "components/Button";
import { ModalContent, ModalFooter, ModalTitle } from "components/Modal";
import { useForm } from "../../contexts/form";
import { Step } from "../../styles";
import Form from './components/Form';

const Details = ({ onClose }) => {  
  const { setPage } = useForm();

  return (
    <>
      <ModalTitle text="Conteúdo do Pacote" actions={<Step>Etapa<br />1/3</Step>}>
        Detalhes da Contratação
      </ModalTitle>

      <ModalContent>
        <Form onClose={onClose} />

        <ModalFooter>
          <Button color="white" colorText="primary" onClick={onClose}>
            Fechar
          </Button>
          <div className="h-flex__cell--grow" />
          <Button type="submit" color="success" onClick={() => setPage('plan')}>
            Próxima etapa
          </Button>
        </ModalFooter>
      </ModalContent>
    </>
  );
}

export default Details;
