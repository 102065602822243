import { AsyncSelect } from "components/Form";
import { useCallback } from "react";
import { Field } from "redux-form";
import * as condosService from 'services/condos';
import Alert from 'react-s-alert';
import { Wrapper } from "./styles";

function CondominiumId({
  condoIdToBeDeleted,
  placeholder = 'Selecione o condomínio/empreendimento',
  ...props
}) {
  const getOptionByValue = useCallback(async (id) => {
    const res = await condosService.getOne(id);
    return res;
  }, []);

  const fetchData = useCallback(async ({ search, offset = 1, limit = 10 }) => {
    try {
      return condosService.getList({
        offset,
        limit,
        sort: '-updated_at',
        filter: {
          title: search,
        },
      });
    } catch {
      Alert.success('Problema ao buscar os condomínios');
    }
  }, []);

  const loadOptions = useCallback(async (search, _, { page }) => {
    const response = await fetchData({
      search,
      offset: page,
    });

    const pagination = response?.meta?.pagination;

    const condosWithoutTheOneBeingDeleted = response.data.filter(({ id }) => {
      return id !== condoIdToBeDeleted;
    });

    return {
      options: condosWithoutTheOneBeingDeleted,
      hasMore: pagination?.current_page < pagination?.total_pages,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  return (
    <Wrapper>
      <Field
        name="condominium_id"
        multi={false}
        labelKey="title"
        valueKey="id"
        getOptionByValue={getOptionByValue}
        loadOptions={loadOptions}
        component={AsyncSelect}
        placeholder={placeholder}
        additional={{
          page: 1,
        }}
        {...props}
      />
    </Wrapper>
  );
}

export default CondominiumId;
