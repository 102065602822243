import api from './index';
// Helpers
import { responseMessage } from 'lib/service-helpers';

export const ticketTransformer = ticket => {
  const hasTicketInfo = !!(
    ticket?.rate_cordiality ||
    ticket?.rate_description ||
    ticket?.rate_solution ||
    ticket?.rate_waiting
  );

  return {
    ...ticket,
    can_evaluate: !ticket.is_done || hasTicketInfo
  };
};

export const ticketsTransformer = res => {
  res.data = res?.data?.map(ticketTransformer);
  return res;
};

export const getTickets = (type, params = {}) =>
  api
    .getList('help-desk/tickets', {
      filter: { type },
      ...params
    })
    .then(ticketsTransformer);

export const getTicket = (ticketId, params) =>
  api.getOne('help-desk/tickets', ticketId, params);

export const createTicket = (data, config) =>
  api.create('help-desk/tickets', data, config);

export const updateTicket = (data, config) =>
  api.update(`help-desk/tickets`, data, config);

export const closeTicket = (ticketId, params) =>
  api
    .create(`help-desk/tickets/${ticketId}/closes`, params)
    .then(responseMessage('Pedido fechado'));

export const createInteraction = (ticketId, data, config) =>
  api.create(`help-desk/tickets/${ticketId}/interactions`, data, config);

export const uploadAttachment = (interactionId, file) =>
  api.uploadFile(
    `help-desk/tickets/interactions/${interactionId}/attachments`,
    { document: file }
  );
