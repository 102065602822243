import React from 'react';
import { compose } from 'redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Components
import Wrapper from 'components/Wrapper';
import NoContent from 'components/NoContent';
import Button from 'components/Button';
// Modules
import { currentUserSelector } from 'modules/login';
// Assets
import lockIcon from './lock.png';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string
    })
  })
};

const defaultProps = {
  match: {
    params: {
      action: null
    }
  }
};

function NoPermission({
  currentUser,
  history,
  match: {
    params: { action }
  }
}) {
  let title = 'Área restrita';

  if (action) {
    title += ` - ${action}`;
  }

  const isTecimob = _.get(currentUser, 'real_estate.resale.is_tecimob');

  return (
    <Wrapper>
      <NoContent
        image={lockIcon}
        title={title}
        text={
          <>
            Essa área não está liberada nas suas permissões.
            <br />
            Solicite que o administrador do sistema libere o acesso.
          </>
        }
      >
        <Button
          onClick={() => history.go(-1)}
          style={{ marginRight: '15px' }}
          color="white"
          colorText="secondary"
        >
          Voltar
        </Button>
        {isTecimob && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://tecimob.com.br/ajuda/gerenciar-permissoes-do-grupo/"
            className="h-link"
          >
            veja como gerenciar permissões
          </a>
        )}
      </NoContent>
    </Wrapper>
  );
}

NoPermission.propTypes = propTypes;
NoPermission.defaultProps = defaultProps;

const mapStateToProps = state => ({
  reseller: currentUserSelector(state)
});

export default compose(withRouter, connect(mapStateToProps))(NoPermission);
