import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-grow: 1;
  background: #fff;
  border-radius: 4px 0 0 4px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #dedede;
  padding: 15px 20px;
`;

export const HeaderTitle = styled.h1`
  font-size: 14px;
  font-weight: bold;
`;

export const BtnLink = styled.button`
  color: ${(p) => p.theme.colors.secondary};
  cursor: pointer;

  border-radius: 6px;
  padding: 5px 10px;
  border: 1px solid transparent;
  margin: -10px -10px -10px 0;

  :hover {
    background: #f1f1f1;
  }
`;

export const Content = styled.div`
  padding: 20px;
`;
