import React, { useCallback, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

// HOC
import { withId } from '../../HOC/withId';

// Hooks
// Services
import * as characteristicsService from 'services/propertiesPartial/condoCharacteristics';
import { transformResponse } from 'services/propertiesPartial/condoCharacteristics';

// Modules
import Button from 'components/Button';
import Container from 'components/Container';
import Loading from 'components/Loading';
import ListCheck from 'components/ListCheck';
import { useDispatch, useSelector } from 'react-redux';
import {
  characteristicsMetaSelector,
  characteristicsSelector,
  getCharacteristics,
  handleFormCharacteristicPartial,
} from 'modules/condosCharacteristics';
import MainTitle from 'components/MainTitle';
import Popover from 'components/Popover';
import FormCharacteristics from 'components/FormCharacteristics';
import { USER_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';
import useFormValue from 'hooks/useFormValue';
import { MdAdd } from 'react-icons/md';
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import { finishResponse } from 'pages/PropertyStore/helpers';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { useParams } from 'react-router';
import { openModalCharacteristicRemoveCondo } from 'modules/modal';

const CharacteristicsCondo = ({
  handleSubmit,
  initialize,
  onSubmit,
  submitting,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const typeId = useFormValue('type_id');

  const characteristics = useSelector(characteristicsSelector);
  const { isFetching } = useSelector(characteristicsMetaSelector);

  /**
   * Cadastro de uma nova característica
   * @param typeId|String
   */
  const onSubmitCharacteristic = useCallback(
    (values, d, p) => dispatch(handleFormCharacteristicPartial(values)),
    []
  );

  const handleDeleteCharacteristic = useCallback(
    (characteristic) => () => {
      dispatch(
        openModalCharacteristicRemoveCondo({
          characteristic,
          onSubmitSuccess: () => {
            dispatch(getCharacteristics());
          },
        })
      );
    },
    []
  );

  useEffect(() => {
    dispatch(getCharacteristics());
  }, []);

  const initializeForm = useCallback(async () => {
    const { data } = await characteristicsService.getOne(id);
    initialize(data);
  }, [initialize, id]);

  useEffect(() => {
    initializeForm();
  }, [id]);

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle
        title="Características do condomínio/empreendimento"
        text="Defina as características deste condomínio/empreendimento"
      >
        <Can
          run={USER_PERMISSIONS.EDIT_CHARACTERISTICS}
          yes={() => (
            <>
              <Popover
                onSubmit={onSubmitCharacteristic}
                component={FormCharacteristics}
              >
                <Button color="secondary">
                  <MdAdd /> Nova característica
                </Button>
              </Popover>
            </>
          )}
          no={() => (
            <span data-tip="Você não tem permissão para gerenciar características">
              <Button disabled>
                <MdAdd /> Nova característica
              </Button>
            </span>
          )}
        />
      </MainTitle>
      <Container>
        <Loading isVisible={isFetching} isFullComponent />
        <ListCheck
          cols={4}
          name="condo_characteristics"
          data={characteristics}
          params={{ typeId }}
          handleDeleteItem={handleDeleteCharacteristic}
        />
      </Container>

      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyCondoCharacteristics',
    initialValues: {},
    onSubmit: (values, d, p) =>
      characteristicsService.update(values).then(finishResponse(values, d, p)),
    onSubmitSuccess: (response, dispatch, p) => {
      const newResponse = transformResponse(response);

      p.initialize(newResponse?.data);

      if (!p.isEditting) {
        p.next('location');
      }
    },
  }),
  withFormConfirmation()
)(CharacteristicsCondo);
