import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import Form from './components/Form';
import * as vcardService from 'services/vcard';
import { createOrUpdate } from 'services/vcard';
import { useRequest } from 'hooks/useRequest';
import Alert from 'react-s-alert';

function ModalVcard({
  onFinally,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  user,
}) {
  const { data, isFetching } = useRequest({
    request: () => vcardService.getOne(user?.id),
    initialState: null,
  });

  const isEditting = useMemo(() => {
    return !!user?.id;
  }, [user]);

  const initialValues = useMemo(() => {
    if (data) {
      return data;
    }

    return {
      name: user?.name,
      creci: user?.creci,
      phone: user?.cellphone,
      email: user?.email,
      file_url: user?.file_url,
    };
  }, [data, user]);

  const handleSubmit = useCallback(
    (values) => {
      return createOrUpdate({ ...values, id: user?.id });
    },
    [user]
  );

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Form
        isFetching={isFetching}
        initialValues={initialValues}
        isEditting={isEditting}
        onSubmit={handleSubmit}
        onSubmitSuccess={() => {
          Alert.success('Vcard salvo com sucesso!');
          if (onFinally) onFinally();
          handleClose();
        }}
        onSubmitFail={onFinally}
        handleClose={handleClose}
      />
    </Modal>
  );
}

export default ModalVcard;
