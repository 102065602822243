import React from 'react';
import { Label, Table } from '../../styles';
import Value from '../Value';

function TableRooms({ property }) {
  const rooms = property?.rooms;

  return (
    <Table>
      <tbody>
        <tr>
          <Label>Dormitórios:</Label>
          <Value>{rooms?.bedroom?.value}</Value>
          <Label>Garagem Coberta?:</Label>
          <Value>{rooms?.garage?.extra?.is_covered?.value}</Value>
          <Label>Cozinha:</Label>
          <Value>{rooms?.kitchen?.value}</Value>
          <Label>Sala de jantar</Label>
          <Value>{rooms?.diningroom?.value}</Value>
        </tr>
        <tr>
          <Label>Sendo Suites:</Label>
          <Value>{rooms?.suite?.value}</Value>
          <Label>Box na garagem:</Label>
          <Value>{rooms?.garage?.extra?.has_box_in_garage?.value}</Value>
          <Label>Copa:</Label>
          <Value>{rooms?.pantry?.value}</Value>
          <Label>Lavabo:</Label>
          <Value>{rooms?.washbasin?.value}</Value>
        </tr>
        <tr>
          <Label>Banheiros:</Label>
          <Value>{rooms?.bathroom?.value}</Value>
          <Label>Área de serviço:</Label>
          <Value>{rooms?.service_area?.value}</Value>
          <Label>Sala de TV:</Label>
          <Value>{rooms?.tvroom?.value}</Value>
          <Label>Closet:</Label>
          <Value>{rooms?.closet?.value}</Value>
        </tr>
        <tr>
          <Label>Garagens:</Label>
          <Value>{rooms?.garage?.value}</Value>
          <Label>Dep. empregada:</Label>
          <Value>{rooms?.employeeDependency?.value}</Value>
          <Label>Sala de estar:</Label>
          <Value>{rooms?.livingroom?.value}</Value>
          <Label>Escritório:</Label>
          <Value>{rooms?.office?.value}</Value>
        </tr>
      </tbody>
    </Table>
  );
}

export default TableRooms;
