import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Components
import WizardVideo from 'components/WizardVideo';
// Containers
import DataDashboard from './containers/DataDashboard';
import AlertFrozenAccount from './containers/AlertFrozenAccount';
// Modules
import { currentUserSelector } from 'modules/login';
// Assets
import Wrapper from '../../components/Wrapper';
// Constants
import { STATUS } from 'components/WizardVideo/constants';
import Countdown from 'pages/Dashboard/components/Countdown';
import withReception from '../../HOC/withReception';
import AlertCreciContainer from 'pages/Dashboard/containers/AlertCreciContainer';
// Lib

const defaultSteps = [
  {
    id: '1',
    actionText: 'Fazer',
    videoUrl: 'https://www.youtube.com/embed/aMR5KTy5I44',
    text: 'Crie sua conta',
    status: 'finished',
    noAction: true,
  },
  {
    id: '2',
    actionTo: '/site',
    actionText: 'Fazer',
    text: 'Informe os dados de contato;',
    videoUrl: 'https://www.youtube.com/watch?v=g34jPo4Y0Ms',
    status: 'active',
  },
  {
    id: '3',
    actionTo: '/users',
    actionText: 'Fazer',
    text: 'Dados e fotos do corretor;',
    videoUrl: 'https://www.youtube.com/watch?v=N6pjpUfkJcY',
    status: 'active',
  },
  {
    id: '4',
    text: 'Personalizar site;',
    actionTo: '/site/layout/style/appearance',
    actionText: 'Fazer',
    videoUrl: 'https://www.youtube.com/embed/51neZv2zbhY',
    status: 'active',
  },
  {
    id: '5',
    text: 'Cadastrar primeiro imóvel;',
    actionTo: '/properties/store',
    actionText: 'Fazer',
    videoUrl: 'https://www.youtube.com/watch?v=xXENX6XnWlY',
    status: 'active',
  },
  {
    id: '6',
    actionTo: '/site/config/domains/pre-register',
    actionText: 'Fazer',
    text: 'Adicionar/Registrar domínio;',
    videoUrl: 'https://www.youtube.com/watch?v=TlzEHm2u1uc',
    status: 'active',
  },
  {
    id: '7',
    text: 'Configurar WhatsApp;',
    actionTo: '/site/config/chat',
    actionText: 'Fazer',
    videoUrl: 'https://www.youtube.com/watch?v=LKj7-8LtLR4',
    status: 'active',
  },
  {
    id: '8',
    text: 'Começar a divulgar seu site;',
    actionText: 'Fazer',
    videoUrl: 'https://www.youtube.com/watch?v=4mhb0HwSHlo',
    status: 'active',
  },
];

class Dashboard extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      steps: [],
    };
  }

  /**
   * Retorna se todos os passos foram concluídos
   * @return {boolean}
   */
  get isAllStepsDone() {
    const { steps } = this.state;

    // Contador de passos feitos
    let countDoneSteps = 0;

    // Total de passos que tem no array
    const totalSteps = steps.length;

    steps.forEach((step) => {
      if (step.status === 'finished') countDoneSteps++;
    });

    // Se a quantidade de passos feitos for === ao total de passos
    return countDoneSteps === totalSteps;
  }

  get canShowWizard() {
    const { reception } = this.props;
    return !this.isAllStepsDone && reception.isVisible;
  }

  get key() {
    const { currentUser } = this.props;
    return `steps-v12-${currentUser.id}`;
  }

  updateSteps() {
    const { currentUser, isFetching } = this.props;

    // Não seta o localStorage se
    // estiver buscando um usuário e se o id do usuário estiver nulo
    if (isFetching || currentUser.id === '') return null;

    // Chave atual
    const key = this.key;
    const keyValue = localStorage.getItem(key);

    if (keyValue !== null) {
      this.setState({ steps: keyValue });
      return null;
    }

    localStorage.setItem(key, defaultSteps);
    this.setState({ steps: defaultSteps });
  }

  componentDidMount() {
    this.updateSteps();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentUser.id !== prevProps.currentUser.id) {
      this.updateSteps();
    }
  }

  changeStatus = (currentStep, status) => {
    const steps = this.state.steps.map((step) => {
      if (step.id === currentStep.id) {
        return { ...step, status };
      }

      return step;
    });

    this.setState({ steps });
    localStorage.setItem(this.key, steps);
  };

  get currentStep() {
    return localStorage.getItem('current-step') || 0;
  }

  handleChangeStep = (current, index) => {
    localStorage.setItem('current-step', index);
  };

  handleCheckStep = (step) => {
    this.changeStatus(step, STATUS.FINISHED);
  };

  handleClickAction = (step) => {
    const { history } = this.props;
    this.handleCheckStep(step);
    setTimeout(() => {
      history.push(step.actionTo);
    }, 300);
  };

  /**
   * Lida com o evento de fechar o wizard para sempre
   * @param notShowAnymore
   * @returns {function(...[*]=)}
   */
  handleFinishWizard = () => (e) => {
    const { handleClickAction } = this.props;
    handleClickAction(e);
  };

  render() {
    const { showAgain, toggleVisibility } = this.props;
    return (
      <Wrapper>
        <Countdown />
        <AlertCreciContainer />
        <AlertFrozenAccount />
        {this.canShowWizard && (
          <WizardVideo
            showAgain={showAgain}
            title="Preparamos 8 passos para você deixar seu site do seu jeito."
            text="Completando o passo a passo, seu site estará com tudo o que precisa para se relacionar com seus clientes!"
            currentStep={this.currentStep}
            steps={this.state.steps}
            onChangeStep={this.handleChangeStep}
            onClickAction={this.handleClickAction}
            checkStep={this.handleCheckStep}
            toggleVisibility={toggleVisibility}
            handleFinishWizard={this.handleFinishWizard}
          />
        )}
        <DataDashboard />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: currentUserSelector(state),
  isFetching: state.login.isFetchingUser || state.login.isFetching,
});

export default compose(
  connect(mapStateToProps),
  withReception('wizard-video-dashboard')
)(Dashboard);
