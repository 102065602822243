import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;

  position: fixed;
  bottom: 95px;
  right: 20px;
  z-index: 5;
  cursor: pointer;
`;

export const Ball = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  background: #2561c2;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.15);
  display: grid;
  place-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;

  :hover {
    background: ${darken(0.05, '#2561c2')};
  }
`;

export const Number = styled.div`
  position: absolute;
  font-weight: 500;
  line-height: 19px;

  bottom: 70%;
  right: 68%;
  padding: 1px 3px;
  font-size: 16px;
  color: #fff;
  background: #f84343;
  border-radius: 4px;
`;
