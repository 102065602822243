import React, { useCallback } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Input } from 'components/Form';
import Button from 'components/Button';

const Form = ({ handleSubmit }) => {
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      return handleSubmit(e);
    },
    [handleSubmit]
  );

  return (
    <form onSubmit={onSubmit}>
      <Field
        label="Assunto do E-mail"
        name="email_subject"
        component={Input}
        placeholder="Ex.: Seu imóvel foi cadastrado em nosso site"
      />
      <Field
        label="Confirme o e-mail do proprietário"
        name="email"
        component={Input}
      />
      <Button type="submit" block color="success">
        Enviar por E-mail
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'FormSendEmail',
  enableReinitialize: true
})(Form);
