import React, { useMemo } from 'react';
import * as Card from './styles';
import TextOverflow from 'components/TextOverflow';
import noImage from 'assets/img/no-image.png';

function CardVertical({ property: p, handleClickProperty, type, ...rest }) {
  const imageUrl = useMemo(
    () => (p.cover_image ? p.cover_image.file_url : noImage),
    [p.cover_image]
  );

  const addressComplete = useMemo(() => {
    let address = '';

    if (p?.street_address !== undefined) {
      address += p?.street_address;
      if (p?.street_number !== undefined) {
        address += ' ' + p?.street_number;
      }
    }

    return address + ' ' + p?.address_formatted;
  }, []);

  return (
    <Card.Wrapper onClick={handleClickProperty}>
      <Card.ImageWrapper>
        <Card.CondoImage src={imageUrl} />
      </Card.ImageWrapper>
      <Card.Footer>
        <Card.Title>
          <TextOverflow width={200}>{p?.condominium?.title}</TextOverflow>
        </Card.Title>
        <Card.Address>{addressComplete}</Card.Address>
      </Card.Footer>
    </Card.Wrapper>
  );
}

export default CardVertical;
