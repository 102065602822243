import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import { useRequest } from 'hooks/useRequest';
import {
  openModalReservationClose,
  openModalReservationRemove,
  openModalReservationUpdate,
} from 'modules/modal';
import { useDispatch } from 'react-redux';
import ReservationList from './components/ReservationList';
import img from 'assets/img/noData/notes.svg';
import * as peopleBookingService from 'services/peopleBooking';

function Reservations({ person, handleOpenMySelf }) {
  const dispatch = useDispatch();

  const { data, isFetching } = useRequest({
    request: () => peopleBookingService.getList(person.id),
  });

  const onSubmitSuccess = () => {
    handleOpenMySelf({
      initialPage: 'Properties',
      pageProperties: 'Reservations',
    });
  };

  const handleUpdate = (reservation) => () => {
    dispatch(
      openModalReservationUpdate({
        reservation,
        property: reservation.property,
        onSubmitSuccess,
      })
    );
  };

  const handleClose = (reservation) => () => {
    dispatch(
      openModalReservationClose({
        reservation,
        property: reservation.property,
        onSubmitSuccess,
      })
    );
  };

  const handleRemove = (reservation) => () => {
    dispatch(
      openModalReservationRemove({
        reservation,
        property: reservation.property,
        onSubmitSuccess,
      })
    );
  };

  if (isFetching) {
    return <Loading isVisible isBlock />;
  }

  if (!data || data.length === 0) {
    return <Nodata image={img} title="Nenhuma Reserva encontrada." text="" />;
  }

  return (
    <ReservationList
      person={person}
      reservations={data}
      handleClose={handleClose}
      handleUpdate={handleUpdate}
      handleRemove={handleRemove}
    />
  );
}

export default Reservations;
