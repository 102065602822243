import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
import Modal from 'react-modal';
// Common Components
import FormCard from './components/FormCard';
import { hideLoading, showLoading } from 'modules/loading';
// Services
import { contractWithCreditCard } from 'services/financial/transactions';
import * as creditCardsService from 'services/financial/creditCards';

const initialValues = {
  receiving_method: 2,
  number: '',
  holder_name: '',
  expiration_date: '',
  cvv: '',
  recurring_charge: null,
};

class ModalPayment extends Component {
  state = {
    isFetching: false,
  };

  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    credit_id: PropTypes.string,
    users: PropTypes.number,
    months: PropTypes.number,
    onSubmitSuccess: PropTypes.func,
    onSubmitFail: PropTypes.func,
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null,

    credit_id: null,
    users: null,
    months: null,
    onSubmitSuccess: null,
    onSubmitFail: null,
  };

  /**
   * Verificação para saber se em seguida
   * que cadastrar o cartão pode tambem fechar o plano
   * @return {boolean}
   */
  get isContractMode() {
    const { users, months, credit_id } = this.props;
    return (!!users && !!months) || credit_id;
  }

  get params() {
    const { users, months, credit_id } = this.props;

    if (credit_id) return { credit_id };

    return {
      users,
      months,
    };
  }

  /**
   * Da o alerta de sucesso baseado nas informações da modal
   */
  messageSuccess() {
    if (!this.isContractMode) {
      Alert.success('Cartão adicionado');
    }
  }

  onSubmit = async (values) => {
    const { showLoading, hideLoading } = this.props;
    this.setState({ isFetching: true });

    try {
      //
      const res = await creditCardsService.create(values);

      // Verifica se está em modo de contratação
      if (this.isContractMode) {
        await contractWithCreditCard(this.params);
        showLoading();
      }

      this.messageSuccess();

      return res;
    } catch {
      hideLoading();
    } finally {
      this.setState({ isFetching: false });
    }
  };

  onSubmitSuccess = (...rest) => {
    const { onSubmitSuccess, handleClose } = this.props;
    if (onSubmitSuccess) onSubmitSuccess(...rest);
    handleClose();
  };

  onSubmitFail = (...rest) => {
    const { onSubmitFail } = this.props;
    if (onSubmitFail) onSubmitFail(...rest);
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;
    const { isFetching } = this.state;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <FormCard
          isContractMode={this.isContractMode}
          isLoading={isFetching}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          onSubmitSuccess={this.onSubmitSuccess}
          onSubmitFail={this.onSubmitFail}
          handleClose={handleClose}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  showLoading,
  hideLoading,
};

export default connect(null, mapDispatchToProps)(ModalPayment);
