import React from 'react';
import getVideoId from 'get-video-id';

const VideoImage = ({ src, ...props }) => {
  const { id, service } = getVideoId(src);

  switch (service) {
    case 'youtube':
      return (
        <img
          alt="Vídeo do youtube"
          {...props}
          src={`https://img.youtube.com/vi/${id}/hqdefault.jpg`}
        />
      );
    case 'vimeo':
      return (
        <img
          alt="Vídeo do Vímeo"
          {...props}
          src={`https://i.vimeocdn.com/video/${id}.jpeg`}
        />
      );
    default:
      return null;
  }
};

export default VideoImage;
