import React from 'react';
// Components
import NoContent from 'components/NoContent';
import Button from 'components/Button';
// Assets
import iconProblem from './icon-problem.svg';

const propTypes = {};
const defaultProps = {};

const NoDataProblem = ({ onClick }) => (
  <NoContent
    image={iconProblem}
    title="Meus tickets de atendimento"
    text="Um ticket leva em média um dia útil para ser atendido."
  >
    <Button color="danger" onClick={onClick}>
      Abrir Ticket
    </Button>
  </NoContent>
);

NoDataProblem.propTypes = propTypes;
NoDataProblem.defaultProps = defaultProps;

export default NoDataProblem;
