import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import Clipboard from 'react-clipboard.js';
import { MdContentCopy } from 'react-icons/md';
// Components
import GroupControl, { GroupItem } from 'components/GroupControl';
import { Input } from 'components/Form';
import Alert from 'react-s-alert';
import Form from '../Form';
// Modules
import { selector } from '../../index';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

const Copy = ({ value }) => (
  <Clipboard
    data-clipboard-text={value}
    onSuccess={() => {
      Alert.success('Url copiada');
    }}
  >
    <MdContentCopy /> Copiar
  </Clipboard>
);

const propTypes = {
  url: PropTypes.string,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};

const defaultProps = {
  url: null,
  initialValues: null,
  onSubmit: null
};

export const FormGenerateLink = ({ url, onSubmit, initialValues }) => (
  <Form onSubmit={onSubmit} initialValues={initialValues}>
    {({ pristine, submitting }) => (
      <>
        <Field label="Título" name="title" component={Input} />
        <GroupControl label="Link dos imóveis selecionados" required={false}>
          <GroupItem>
            <Field disabled name="url" component={Input} placeholder />
          </GroupItem>
          <GroupItem label={<Copy value={url} />} size="small" />
        </GroupControl>
        <ModalFooter>
          <span className="h-flex__cell--grow" />
          <Button
            type="submit"
            color="success"
            disabled={pristine || submitting}
          >
            Atualizar
          </Button>
        </ModalFooter>
      </>
    )}
  </Form>
);

FormGenerateLink.propTypes = propTypes;
FormGenerateLink.defaultProps = defaultProps;

const mapStateToProps = state => ({
  url: selector(state, 'url')
});

export default connect(mapStateToProps)(FormGenerateLink);
