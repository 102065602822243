import React, { useCallback, useContext, useMemo, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import CardImage from 'components/CardImageNew';
import { WrapItem, Wrapper } from './styles';
import Lightbox from 'react-images';
import { imageChangeSize } from 'lib/image-helpers';

export const SortableGalleryContext = React.createContext({
  data: null,
  captionName: null,
});

export const useSortableGallery = () => useContext(SortableGalleryContext);

const style = {
  lightBox: {
    container: {
      background: 'rgba(62, 88, 124, 0.85)',
    },
    arrow: {
      background: 'none',
    },
  },
};

const Item = SortableElement(
  ({
    value,
    itemIndex,
    onRemoveImage,
    onEditCaption,
    onEditCaptionSuccess,
    onClickSpin,
    handleClickOpenById,
  }) => {
    return (
      <WrapItem>
        <CardImage
          isFeatured={itemIndex === 0}
          onEditCaption={onEditCaption}
          onEditCaptionSuccess={onEditCaptionSuccess}
          onClickSpin={onClickSpin}
          image={value}
          onRemoveImage={onRemoveImage}
          onClick={handleClickOpenById}
        />
      </WrapItem>
    );
  }
);

const List = SortableContainer(
  ({
    items,
    onRemoveImage,
    onEditCaption,
    onEditCaptionSuccess,
    onClickSpin,
    handleClickOpenById,
  }) => (
    <Wrapper>
      {items?.map((value, index) => (
        <Item
          itemIndex={index}
          key={value?.id}
          index={index}
          value={value}
          onRemoveImage={onRemoveImage}
          onEditCaption={onEditCaption}
          onEditCaptionSuccess={onEditCaptionSuccess}
          onClickSpin={onClickSpin}
          handleClickOpenById={handleClickOpenById(value?.id)}
        />
      ))}
    </Wrapper>
  )
);

const SortablePropertyGallery = ({
  data,
  onRemoveImage,
  onSortEnd,
  onEditCaption,
  onEditCaptionSuccess,
  onClickSpin,
  captionName = 'caption',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const handleClickOpenById = useCallback(
    (id) => (e) => {
      const index = data.findIndex((image) => image.id === id);
      setCurrentImage(index);
      setIsOpen(true);
    },
    [data]
  );

  const handleClickNext = useCallback(() => {
    setCurrentImage((prev) => prev + 1);
  }, []);
  const handleClickPrev = useCallback(() => {
    setCurrentImage((prev) => prev - 1);
  }, []);

  const handleClickThumbnail = useCallback((current) => {
    setCurrentImage(current);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const lighboxImages = useMemo(() => {
    return data?.map(({ file_url }) => ({
      src: imageChangeSize(file_url, 'large'),
    }));
  }, [data]);

  return (
    <SortableGalleryContext.Provider
      value={{
        data,
        captionName,
      }}
    >
      <List
        axis="xy"
        distance={5}
        useDragHandle
        items={data}
        onSortEnd={onSortEnd}
        onRemoveImage={onRemoveImage}
        onEditCaption={onEditCaption}
        onEditCaptionSuccess={onEditCaptionSuccess}
        onClickSpin={onClickSpin}
        handleClickOpenById={handleClickOpenById}
      />
      <Lightbox
        images={lighboxImages}
        currentImage={currentImage}
        imageCountSeparator=" de "
        onClickThumbnail={handleClickThumbnail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        isOpen={isOpen}
        onClose={handleClose}
        backdropClosesModal
        showThumbnails
        theme={style.lightBox}
      />
    </SortableGalleryContext.Provider>
  );
};

export default SortablePropertyGallery;
