import React from 'react';
import MyTable from 'components/Table';
import TableItem from '../TableItem';
import Check from 'components/Check';

export default function Table({
  isAllSelected,
  toggleAll,
  data,
  meta,
  handleToggle
}) {
  return (
    <MyTable
      responsive
      data={data}
      isFetching={false}
      renderTitle={() => (
        <tr>
          <th className="h-sticky--left">
            <Check
              checked={isAllSelected}
              onClick={toggleAll}
              className="h-margin-right--15"
            />
            Canal
          </th>
          {meta?.columns.map(column => (
            <th key={column}>{column}</th>
          ))}
          <th className="h-sticky--right">Total</th>
        </tr>
      )}
      renderItem={(data, i) => (
        <TableItem
          key={`item-realtor-${data.id}`}
          data={data}
          handleToggle={handleToggle(data)}
        />
      )}
      renderFooter={() => {
        return (
          <tr>
            <td className="h-sticky--left">Total</td>
            {meta?.totals?.map(({ type, total }) => (
              <td key={`footer-${type}`}>{total}</td>
            ))}
            <td className="h-sticky--right">{meta?.total}</td>
          </tr>
        );
      }}
    />
  );
}
