import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SOCIAL_COLORS } from '../../constants/config';

const defaultProps = {
  tagName: null,
  to: null,
  type: 'button',
  className: null,
  size: 'large',
  color: 'primary',
  colorText: 'white',
  block: false,
  children: null,
  onClick: null,
};

const propTypes = {
  tagName: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'success',
    'danger',
    'caution',
  ]),
  colorText: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'caution',
  ]),
  block: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

const Button = ({
  tagName,
  to,
  type,
  children,
  color,
  colorText,
  size,
  className,
  onClick,
  block,
  loading,
  ...rest
}) => {
  const TagName = to ? Link : tagName || 'button';
  const isLoading = (loading !== undefined && loading !== null) || loading > 0;

  return (
    <TagName
      disabled={isLoading}
      to={to}
      type={type}
      onClick={onClick}
      className={classnames('Button', className, {
        [`h-color--${colorText}`]: colorText,
        [`Button--${color}`]: color,
        [`Button--${size}`]: size,
        'Button--block': block,
        'Button--social': SOCIAL_COLORS.includes(color),
      })}
      {...rest}
    >
      {children}
      {isLoading && (
        <div className="Progress">
          <div className="Progress__bar" style={{ width: `${loading}%` }} />
        </div>
      )}
    </TagName>
  );
};

Button.defaultProps = defaultProps;
Button.propTypes = propTypes;

export default Button;
