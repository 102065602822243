import Alert from 'react-s-alert';
// Widgets.js
import { createModule, item } from 'lib/reducer-helpers';
// Modules
import {
  openModalBankSlip,
  openModalBankSlipSuccess,
  openModalCreditCard,
  openModalHasPayment,
} from 'modules/modal';
import { hideLoading, showLoading } from 'modules/loading';
// Services
import * as bankSlipService from 'services/financial/bankSlip';
import * as creditsService from 'services/financial/credits';
import * as creditCardsService from 'services/financial/creditCards';
import * as transactionsService from 'services/financial/transactions';

// Criador de ação
const createAction = createModule('plan');

// Actions
const RESET = createAction('RESET');
const REQUEST_CARDS = createAction('REQUEST_CARDS');
const RECEIVE_CARDS = createAction('RECEIVE_CARDS');
const ADD_CARD = createAction('ADD_CARD');
const ALTER_CARD = createAction('ALTER_CARD');
const REMOVE_CARD = createAction('REMOVE_CARD');
const REQUEST_BANK_SLIP = createAction('REQUEST_BANK_SLIP');
const RECEIVE_BANK_SLIP = createAction('RECEIVE_BANK_SLIP');

// Selectors
export const getCards = (state) => state.plan.cards;
export const getBankSlip = (state) => state.plan.bankSlip;

const initialState = {
  cards: {
    data: [],
    isFetching: false,
  },
  bankSlip: {
    data: null,
    isFetching: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CARDS:
      return { ...state, cards: { ...state.cards, isFetching: true } };
    case RECEIVE_CARDS:
      return {
        ...state,
        cards: { ...state.cards, data: action.data, isFetching: false },
      };
    case REMOVE_CARD:
      return {
        ...state,
        cards: { ...state.cards, data: item.remove(state.cards.data, action) },
      };
    case ADD_CARD:
      return {
        ...state,
        cards: {
          ...state.cards,
          data: item.add(state.cards.data, action.card),
        },
      };
    case ALTER_CARD:
      return {
        ...state,
        cards: {
          ...state.cards,
          data: item.alter(state.cards.data, action.card),
        },
      };
    case REQUEST_BANK_SLIP:
      return { ...state, bankSlip: { ...state.bankSlip, isFetching: true } };
    case RECEIVE_BANK_SLIP:
      return {
        ...state,
        bankSlip: { ...state.bankSlip, data: action.data, isFetching: false },
      };

    case RESET:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const requestCards = () => ({ type: REQUEST_CARDS });
export const receiveCards = (data) => ({ type: RECEIVE_CARDS, data });
export const addCard = (card) => ({ type: ADD_CARD, card });
export const alterCard = (card) => ({ type: ALTER_CARD, card });

export const removeCard = (id) => ({ type: REMOVE_CARD, id });

export const requestBankSlip = () => ({ type: REQUEST_BANK_SLIP });
export const receiveBankSlip = (data) => ({ type: RECEIVE_BANK_SLIP, data });

// side effects, only as applicable
// e.g. thunks, epics, etc
export const fetchCards = () => async (dispatch) => {
  dispatch(requestCards());
  const { data } = await creditCardsService.getAll();
  dispatch(receiveCards(data));
};

export const fetchBankSlip = () => async (dispatch) => {
  dispatch(requestBankSlip());
  const { data } = await bankSlipService.getOne();
  dispatch(receiveBankSlip(data));
};

export const deleteCreditCard = (id) => async (dispatch) => {
  await creditCardsService.remove(id).then(() => {
    dispatch(fetchCards());
  });
};

/**
 * Contract with bank slip
 * @returns {function(...[*]=)}
 */
export const onContractWithBankSlip =
  ({ boleto_url }) =>
  (dispatch) => {
    setTimeout(() => {
      // Busca dados do boleto
      fetchBankSlip()(dispatch);

      // Abre uma nova página com a url do boleto
      window.open(boleto_url, '_blank');

      // Abre a modal de sucesso indicando que foi gerado um novo boleto
      openModalBankSlipSuccess({ bankSlipUrl: boleto_url })(dispatch);
    }, 200);
  };

export const generateDuplicateBillet = (values) => async (dispatch) => {
  try {
    // Inicia o loading
    dispatch(showLoading());

    const { data } = await transactionsService.contractWithBankSlip(values);

    // Abre a modal mostrando o link do boleto
    onContractWithBankSlip(data)(dispatch);

    return data;
  } catch (err) {
    Alert.success('Problema ao gerar o boleto');
    throw err;
  } finally {
    // Esconde o loading
    dispatch(hideLoading());
  }
};

export const onSubmitBankSlip = (values) => async (dispatch, getState) => {
  const bankSlip = await bankSlipService.getOne();

  // Se não tiver dados de boleto preenchidos
  // deve abrir a modal de cartão de boleto em modo de contratação
  if (!bankSlip?.data?.length) {
    openModalBankSlip({
      ...values,
      bank_slip_id: bankSlip?.data?.id,
      onSubmitSuccess: (response) => {
        onContractWithBankSlip(response)(dispatch);
      },
    })(dispatch);

    return null;
  }

  try {
    // Inicia o loading
    dispatch(showLoading());

    const { data } = await transactionsService.contractWithBankSlip(values);

    // Abre a modal mostrando o link do boleto
    onContractWithBankSlip(data)(dispatch);

    return data;
  } catch {
    Alert.success('Problema ao gerar o boleto');
  } finally {
    // Esconde o loading
    dispatch(hideLoading());
  }
};

/**
 * Quando contrata
 * @param card
 * @returns {function(...[*]=)}
 */
const onContractWithCard =
  ({ data: card }) =>
  (dispatch) => {
    // Recebe o cartão de crédito
    dispatch(addCard(card));

    setTimeout(() => {
      // Busca o cartão de credito
      fetchCards()(dispatch);
    }, 200);
  };

/**
 * Lida com o submit do cartão de crédito
 */
export const onSubmitCreditCard = (values) => async (dispatch, getState) => {
  const creditCards = getCards(getState());

  // Se não tiver cartão de crédito
  // deve abrir a modal de cartão de crédito em modo de contratação
  if (!creditCards.data) {
    openModalCreditCard({
      ...values,
      onSubmitSuccess: (response) => {
        onContractWithCard(response)(dispatch);
      },
    })(dispatch);

    return null;
  }

  try {
    const hasPaid = await creditsService.hasPaymentToday();

    if (hasPaid && !values.force) {
      throw new Error('HAS_PAID');
    }

    dispatch(showLoading());

    // Faz o request enviando o credito
    const res = await transactionsService.contractWithCreditCard(values);

    // Busca todas as informações da tela novamente
    fetchCards()(dispatch);
    fetchBankSlip()(dispatch);

    onContractWithCard(res)(dispatch);

    // retorna a resposta do request
    return res;
  } catch (err) {
    if (err.message === 'HAS_PAID') {
      dispatch(
        openModalHasPayment({
          onConfirm: async () => {
            await onSubmitCreditCard({ ...values, force: 1 })(
              dispatch,
              getState
            );

            window.location.reload();
          },
        })
      );

      return {};
    }
    dispatch(hideLoading());
    Alert.success(
      'Falha na comunicação com a operadora de cartão. Tente novamente em 1 minuto'
    );
  }
};

export const onSubmit =
  ({ receiving_method, ...values }) =>
  (dispatch) => {
    // Verifica se é cartão o pagamento
    if (receiving_method === 2) return dispatch(onSubmitCreditCard(values));

    // Caso contrário é boleto
    return dispatch(onSubmitBankSlip(values));
  };
