import { imagesChangeSize } from 'lib/image-helpers';
import PropTypes from 'prop-types';
import React from 'react';
// Components
// Assets
import ModalImages from 'components/ModalImages';
import { Actions } from 'modules/modalImages';
import { connect } from 'react-redux';
import GalleryImage from './components/GalleryImage';
import GalleryTour from './components/GalleryTour';
import GalleryVideo from './components/GalleryVideo';

// const style = {
//   lightBox: {
//     container: {
//       background: 'rgba(62, 88, 124, 0.85)',
//     },
//     arrow: {
//       background: 'none',
//     },
//   },
// };

class Gallery extends React.Component {
  static defaultProps = {
    data: [],
    video: null,
    tour: null,
  };

  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            src: PropTypes.string.isRequired,
          })
        ),
      })
    ),
    video: PropTypes.string,
    tour: PropTypes.string,
  };

  state = {
    currentImage: 0,
    images: [],
    isOpen: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.isOpen !== prevState.isOpen) {
      if (this.props.onChangeVisibility)
        this.props.onChangeVisibility(this.state.isOpen);
    }
  }

  /**
   * Retorna o número máximo de imagens que podem ser mostradas
   */
  get maxImages() {
    const { video, tour } = this.props;
    let index = 3;

    if (tour) index--;
    if (video) index--;

    return index;
  }

  get images() {
    const { data } = this.props;

    let images = data.reduce((current, value) => {
      const data = value.data.map((d) => ({ ...d, name: value.name }));
      current.push(...data);
      return current;
    }, []);

    const maxImages = this.maxImages;

    // Adiciona o tamanho nas imagens
    if (images) {
      images = imagesChangeSize(images, 'large');
    }

    return images.filter((value, i) => i < maxImages);
  }

  get data() {
    const { data } = this.props;
    return data.filter(({ data }) => data.length > 0);
  }

  getGalleryByName = (name) => {
    let currentPhotos = this.props.data.find(
      (gallery) => gallery.name === name
    ).data;

    return imagesChangeSize(currentPhotos, 'large', 'contain', null, 'src');
  };

  openGalleryByName =
    (name, index = 0) =>
    (e) => {
      e.stopPropagation();

      this.setState({
        currentImage: index,
        images: this.getGalleryByName(name),
        isOpen: true,
      });
    };

  close = () => {
    this.setState({ currentImage: 0, images: [], isOpen: false });
  };

  handleClickImage = (e, index, name) => {
    // Abre a primeira galeria quando clica
    this.openGalleryByName(name, index)(e);
  };

  get renderImages() {
    return this.images.map((image, i) => (
      <GalleryImage
        src={image.src}
        alt={image.alt}
        isExternal={image.is_external}
        onClick={(e) => {
          this.props.open();
        }}
      />
    ));
  }

  get renderVideo() {
    const { video } = this.props;
    if (!video) return null;
    return <GalleryVideo src={video} />;
  }

  get renderTour() {
    const { tour } = this.props;
    if (!tour) return null;
    return <GalleryTour url={tour} />;
  }

  goToNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };

  goToPrev = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };

  handleClickThumbnail = (index) => {
    this.setState({ currentImage: index });
  };

  get renderLightbox() {
    const { property } = this.props;

    return <ModalImages images={property?.images} />;
  }

  render() {
    const { style, className, onClick } = this.props;
    const data = this.data;

    if (!data || data.length === 0) return null;

    return (
      <div
        className={`Gallery ${className || ''}`}
        style={style}
        onClick={onClick}
      >
        {this.renderImages}
        {this.renderVideo}
        {this.renderTour}
        {this.renderLightbox}
      </div>
    );
  }
}

export default connect(null, {
  close: Actions.close,
  open: Actions.open,
  setCurrentImage: Actions.setCurrentImage,
})(Gallery);
