import styled, { css } from 'styled-components';
import Avatar from 'components/Avatar';
import { rgba } from 'polished';

export const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: grid;
  place-content: center;
  background: ${rgba('#fff', 0.85)};
  z-index: 5;

  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  ${p =>
    p.isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;
export const OverlayContent = styled.div``;

export const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  padding: 20px 30px;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  flex: 1 0;
  min-width: 0;

  a {
    display: block;
  }

  h2,
  p {
    margin-top: 8px;
  }

  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 14px;
  }

  p {
    font-size: 14px;
    color: ${p => p.theme.colors.primary};
  }
`;

export const WrapperImage = styled.div`
  position: relative;
  margin-right: 20px;
`;

export const Image = styled(Avatar).attrs(() => ({
  hasZoom: false,
  sizeImage: 'medium'
}))`
  width: 110px;
  height: 110px;
  line-height: 110px;
  font-size: 32px;
  border-radius: 4px;
`;

export const Social = styled.ul`
  position: relative;
  width: calc(100% + 10px);
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin-top: -12.5px;
  margin-left: -5px;
  margin-bottom: -5px;
`;

export const SocialItem = styled.li`
  a {
    display: grid;
    place-items: center;
    width: 25px;
    height: 25px;
    background-color: ${p => p.color};
    border-radius: 4px;
  }

  svg {
    color: #fff;
  }
`;
