import React, { useEffect, useMemo } from 'react';
import Tooltip from 'react-tooltip';
import {
  LinkDivider,
  LinkItem,
  Links,
  Number,
  Title,
} from 'containers/ModalRoot/ModalPropertySearch/components/AsideFilter/styles';
import { useSelector } from 'react-redux';
import { propertiesCountSelector } from 'modules/modalPropertySearch';
import TextLoading from 'components/TextLoading';
import Button from 'components/Button';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';
import { MdInfo } from 'react-icons/md';
import useFormValue from 'hooks/useFormValue';
// import { Wrapper } from './styles';

function Counts() {
  const realEstate = useCurrentRealEstate();
  const propertiesCount = useSelector(propertiesCountSelector);
  const condomoniumId = useFormValue('condominium_id');

  const isFetchingCounts = useMemo(() => {
    return (
      propertiesCount?.guru?.isFetching || propertiesCount?.orulo?.isFetching
    );
  }, [propertiesCount]);

  const allCountsAreEmpty = useMemo(() => {
    return (
      propertiesCount?.self?.count === 0 &&
      propertiesCount?.guru?.count === 0 &&
      propertiesCount?.dwv?.count === 0 &&
      propertiesCount?.orulo?.count === 0
    );
  }, [propertiesCount]);

  const hasGuru = useMemo(() => {
    return !!(
      propertiesCount?.guru?.count &&
      propertiesCount?.guru?.count > 0 &&
      realEstate.with_guru
    );
  }, [propertiesCount, realEstate]);

  const hasOrulo = useMemo(() => {
    return !!(
      propertiesCount?.orulo?.count && propertiesCount?.orulo?.count > 0
    );
  }, [propertiesCount]);

  const hasDwv = useMemo(() => {
    return !!(propertiesCount?.dwv?.count && propertiesCount?.dwv?.count > 0);
  }, [propertiesCount]);

  useEffect(() => {
    Tooltip.rebuild();
  }, [hasGuru, hasOrulo, isFetchingCounts]);

  // const hasNetworkCount = useMemo(() => {
  //   return !!(hasGuru || hasOrulo);
  // }, [hasGuru, hasOrulo]);

  return (
    <>
      <Title>Imóveis encontrados</Title>
      {propertiesCount?.self?.isFetching ? (
        <Number>
          <TextLoading />
        </Number>
      ) : (
        <Number>{propertiesCount?.self?.count || 0}</Number>
      )}
      {!condomoniumId && (
        <Links>
          {isFetchingCounts ? (
            <TextLoading height={17} />
          ) : !hasGuru && !hasOrulo && !hasDwv ? (
            <LinkItem>
              Rede{' '}
              <MdInfo data-tip="Para incluir resultado de rede, informe na pesquisa:<br/> Tipo de Imóvel, Transação, Estado e Cidade." />
            </LinkItem>
          ) : (
            <>
              {hasGuru > 0 && (
                <LinkItem>+{propertiesCount?.guru?.count} Guru</LinkItem>
              )}
              {hasOrulo && (
                <>
                  {hasGuru && <LinkDivider> | </LinkDivider>}
                  <LinkItem>+{propertiesCount?.orulo?.count} Órulo</LinkItem>
                </>
              )}
              {hasDwv && (
                <>
                  {(hasGuru || hasOrulo) && <LinkDivider> | </LinkDivider>}
                  <LinkItem>+{propertiesCount?.dwv?.count} DWV</LinkItem>
                </>
              )}
            </>
          )}
        </Links>
      )}
      <div className="h-text-center">
        <Button
          disabled={allCountsAreEmpty}
          type="submit"
          size="small"
          color="success"
          className="Button--submit"
        >
          Ver imóveis
        </Button>
      </div>
    </>
  );
}

export default Counts;
