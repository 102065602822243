import { useRequest } from 'hooks/useRequest';
import * as siteTemplatesService from 'services/settings/site-templates';
import Alert from 'react-s-alert';

/**
 * Retorna uma lista de templates
 */
export const useTemplates = () => {
  const {
    data: templates,
    setData,
    isFetching: isFetchingTemplates,
    fetchData: fetchTemplates,
  } = useRequest({
    request: (category) => siteTemplatesService.getTemplates({ category }),
    initialFetch: false,
  });

  /**
   * Remover um template
   * @param template_id
   * @returns {Promise<>}
   */
  const removeConfig = ({ template_id }) => {
    try {
      return siteTemplatesService.removeConfig({ template_id });
    } catch (err) {
      Alert.success('Erro ao remover o template');
      throw err;
    }
  };

  /**
   * Escolhe um template na lista
   * @param template_id
   * @returns {Promise<AxiosResponse<*>>}
   */
  const applyTemplate = async (template_id) => {
    return siteTemplatesService.applyTemplate({ template_id });
  };

  return {
    templates,
    isFetchingTemplates,
    fetchTemplates,
    removeConfig,
    applyTemplate,
  };
};
