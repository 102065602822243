function isEquivalent(a, b) {
  // Create arrays of property names
  let aProps = Object.getOwnPropertyNames(a);
  let bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}

export const checkIfSearchExistis = (search, searches) => {
  return searches.findIndex((item) => isEquivalent(item, search));
};

export default class LastSearches {
  constructor(data, limit = 10) {
    this._data = data;
    this._limit = limit;
  }

  get data() {
    return this._data;
  }

  add(search) {
    // verificar se a pesquisa atual já existe na lista
    let index = checkIfSearchExistis(search, this._data);

    if (index !== -1) {
      // se a pesquisa já existe, remove ela do array
      this._data.splice(index, 1);
    }

    // adiciona a nova pesquisa ao início do array
    this._data.unshift(search);

    if (this._data.length > this._limit) {
      this._data.pop();
    }
  }
}
