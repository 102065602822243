import { getFormValues, change } from 'redux-form';
import Alert from 'react-s-alert';
import { createModule, item } from '../lib/reducer-helpers';
// Services
import * as characteristicsService from 'services/characteristics';
// Modules
import { setFormData } from 'modules/propertyAdd';

const createAction = createModule('characteristics');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const REMOVE = createAction('REMOVE');

// pega todas as características
export const characteristicsSelector = state =>
  state.characteristics.characteristics;

export const characteristicsMetaSelector = state => state.characteristics.meta;

// Initial State
const initialState = {
  characteristics: [],
  meta: { isFetching: false }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE:
      return {
        ...state,
        characteristics: action.characteristics,
        meta: {
          ...state.meta,
          isFetching: false
        }
      };
    case REMOVE:
      return {
        ...state,
        characteristics: item.remove(state.characteristics, action)
      };
    default:
      return state;
  }
}

// Action Creators
export const requestCharacteristics = () => ({ type: REQUEST });
export const receiveCharacteristics = characteristics => ({
  type: RECEIVE,
  characteristics
});
export const removeCharacteristic = id => ({ type: REMOVE, id });

// side effects, only as applicable
// e.g. thunks, epics, etc
export function deleteCharacteristic(id) {
  return dispatch => {
    return characteristicsService
      .remove(id)
      .then(res => {
        // remove a característica da store
        dispatch(removeCharacteristic(id));

        return res;
      })
      .catch(() => {
        Alert.success('Erro ao remover a característica');
      });
  };
}

/**
 * Pega todas as características de um tipo de imóvel especifico
 * @param type_id
 * @return {function(*)}
 */
export function getCharacteristics(type_id) {
  return dispatch => {
    // loading das características
    dispatch(requestCharacteristics());

    // faz o request buscando as características
    return characteristicsService
      .getList({
        type_id,
        order_col: 'title',
        order_rule: 'asc'
      })
      .then(({ data: characteristics }) => {
        dispatch(receiveCharacteristics(characteristics));
        return characteristics;
      })
      .catch(() => {
        Alert.success('Problema ao buscar as características');
        dispatch(receiveCharacteristics([]));
      });
  };
}

// Cadastro de característica
export const handleFormCharacteristic = (type_id, values) => (
  dispatch,
  getState
) =>
  characteristicsService
    .create(type_id, values)
    .then(({ data }) => {
      const formdata = getFormValues('propertiesAdd')(getState());
      // atualiza a lista de características
      getCharacteristics(type_id)(dispatch);

      /* TODO Refazer essa parte */
      setFormData({
        ...formdata,
        characteristics: {
          ...formdata.characteristics,
          [data.id]: { ...data, quantity: '', isChecked: true }
        }
      })(dispatch);
      return data;
    })
    .catch(() => {
      Alert.success('Erro ao adicionar característica');
    });

// Cadastro de característica
export const handleFormCharacteristicPartial = (type_id, values) => (
  dispatch,
  getState
) => {
  const FORM = 'PropertyCharacteristics';

  return characteristicsService
    .create(type_id, values)
    .then(({ data }) => {
      // Pega os dados do formulário
      const formdata = getFormValues(FORM)(getState());

      // atualiza a lista de características
      getCharacteristics(type_id)(dispatch);

      dispatch(
        change(FORM, 'characteristics', {
          ...formdata?.characteristics,
          [data.id]: { ...data, quantity: '', isChecked: true }
        })
      );
      return data;
    })
    .catch(() => {
      Alert.success('Erro ao adicionar característica');
    });
};
