import { useState, useEffect } from 'react';

export const useImagePreload = imageUrl => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setIsLoading(false);
    };
  }, []);

  return { isLoading, setIsLoading };
};
