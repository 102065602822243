import React, { createContext, useContext, useCallback } from 'react';

// Services
import * as progressService from 'services/buildings/progress';
import * as imagesService from 'services/buildings/images';

// Hooks
import { useRequest } from 'hooks/useRequest';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useDispatch } from 'react-redux';
import { uploadFiles } from 'lib/reducer-helpers';
import { showLoading, hideLoading } from 'modules/loading';

export const ProgressContext = createContext({
  data: [],
  images: [],
  isFetching: false,
  fetchData: null,
  handleDragEnd: null,
  handleRemoveProgress: null,
  handleRemoveImage: null,
  handleUploadImages: null
});

export const ProgressProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { enterpriseId } = useEnterprise();

  const { data, isFetching, fetchData } = useRequest({
    request: progressService.get,
    params: enterpriseId,
    initialState: {}
  });

  // Remover um progresso
  const handleRemoveProgress = useCallback(
    progressId => () => {
      dispatch(
        openConfirmation({
          title: 'Remover progresso',
          text: 'Deseja realmente excluir esse progresso?',
          request: () =>
            progressService.removeProgress(enterpriseId, progressId),
          onSuccess: () => {
            fetchData();
          }
        })
      );
    },
    [enterpriseId]
  );

  // Ordenação do progresso
  const handleDragEnd = useCallback(
    ({ draggableId, order }) =>
      progressService.updateProgress({
        buildingId: enterpriseId,
        id: draggableId,
        order
      }),
    [enterpriseId]
  );

  const handleRemoveImage = useCallback(
    imageId => () => {
      dispatch(
        openConfirmation({
          title: 'Remover Imagem',
          text: 'Deseja realmente excluir essa imagem?',
          request: () =>
            imagesService.remove({ buildingId: enterpriseId, imageId }),
          onSuccess: () => {
            fetchData();
          }
        })
      );
    },
    [enterpriseId]
  );

  const handleUploadImages = useCallback(
    files => {
      return uploadFiles({
        request: imagesService.addImageProgress,
        params: (file, index) => ({
          image: file,
          name: file.name,
          buildingId: enterpriseId,
          order: index + 1
        }),
        files: files,
        onUploadStart: () => dispatch(showLoading()),
        onUploadEnd: async () => {
          fetchData();
          dispatch(hideLoading());
        }
      });
    },
    [dispatch, enterpriseId]
  );

  return (
    <ProgressContext.Provider
      value={{
        data,
        images: data?.images,
        isFetching,
        fetchData,
        handleDragEnd,
        handleRemoveProgress,
        handleRemoveImage,
        handleUploadImages
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export const useProgress = () => useContext(ProgressContext);
