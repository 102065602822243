import { withFormName } from 'HOC/withFormName';
import { Select2 } from 'components/Form';
import { Actions, Selectors } from 'containers/LocationFields/module';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { getNeighborhoods, getZones } from 'services/location';
// import { Wrapper } from './styles';

function NeighborhoodOrZone({
  label = 'Bairro',
  name = 'by_neighborhood_or_zone_id',
  onChange,
  onBlur,
  placeholder,
  cityId,
  formName,
  params,

  ...props
}) {
  const options = useSelector((state) =>
    Selectors.getData(formName, name)(state)
  );
  const isLoading = useSelector((state) =>
    Selectors.getIsFetching(formName, name)(state)
  );

  const [hasZones, setHasZones] = useState(false);

  const dispatch = useDispatch();

  const request = () => dispatch(Actions.request(formName, name));
  const receive = (data) => dispatch(Actions.receive(formName, name, data));

  const fetchNeighborhoods = async () => {
    const { data } = await getNeighborhoods({ city_id: cityId, ...params });
    return data;
  };

  const fetchZones = async () => {
    const { data } = await getZones({ city_id: cityId, ...params });
    return data;
  };

  const fetchData = async () => {
    request();

    const [zones, neighborhoods] = await Promise.all([
      fetchZones(),
      fetchNeighborhoods(),
    ]);

    if (zones.length > 0) {
      setHasZones(true);

      const groupedData = [
        {
          label: 'Zonas',
          items: zones,
        },
        {
          label: 'Bairros',
          items: neighborhoods,
        },
      ];

      return receive(groupedData);
    }

    setHasZones(false);

    return receive([{ label: 'Bairros', items: neighborhoods }]);
  };

  useEffect(() => {
    if (cityId) {
      fetchData();
    }
  }, [cityId]);

  useEffect(() => {
    dispatch(Actions.register(formName, name));

    return () => {
      dispatch(Actions.unregister(formName, name));
    };
  }, [formName, name]);

  return (
    <Field
      isLoading={isLoading}
      xs={props.xs}
      label={hasZones ? 'Zonas | Bairros' : 'Bairros'}
      disabled={options && !options.length}
      options={options}
      filter
      name={name}
      selectedItemsLabel="bairros"
      component={Select2}
      labelKey="name"
      valueKey="id"
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      optionGroupLabel="label"
      optionGroupChildren="items"
    />
  );
}

export default withFormName(NeighborhoodOrZone);
