import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import AlertRadar from 'components/AlertRadar';
import { PROPERTIES } from '../../fakeData';
import { useDispatch } from 'react-redux';
import { removeWizard } from 'services/user';

const PROPERTY = PROPERTIES[0];
const PERSON = {
  id: 'd319133f-7178-4c40-bb45-9e4b30d6700b',
};

const RADAR_COM_CLIENTES_E_IMOVEIS = {
  property: PROPERTY,
  person: PERSON,
  properties_count: 8,
  people_count: 4,
};

const RADAR_SO_CLIENTES = {
  property: PROPERTY,
  person: PERSON,
  properties_count: 1,
  people_count: 4,
};

const RADAR_SO_UM_CLIENTE = {
  property: PROPERTY,
  person: PERSON,
  people_count: 1,
};

function Alerts() {
  const dispatch = useDispatch();

  return (
    <div>
      <div
        onClick={() => {
          removeWizard('@tecimob/alert-radar');
        }}
      >
        REMOVER_STORAGE
      </div>
      <Row>
        <Col xs={12}>
          <h4 className="h-margin-bottom--5">Radar com clientes e imóveis</h4>
          <AlertRadar data={RADAR_COM_CLIENTES_E_IMOVEIS} />
        </Col>
        <Col xs={12}>
          <h4 className="h-margin-bottom--5">Radar só clientes</h4>
          <AlertRadar data={RADAR_SO_CLIENTES} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h4 className="h-margin-bottom--5">Radar só 1 cliente</h4>
          <AlertRadar data={RADAR_SO_UM_CLIENTE} />
        </Col>
      </Row>
    </div>
  );
}

export default Alerts;
