// Widgets.js
import { createModule } from '../lib/reducer-helpers';
import * as financialIndex from '../services/financialIndex';

const createAction = createModule('modalIndexHistory');

// Actions
const REQUEST_HISTORY = createAction('REQUEST_HISTORY');
const RECEIVE_HISTORY = createAction('RECEIVE_HISTORY');
const FAILED = createAction('FAILED');

// Initial State
const initialState = {
  indexes: [],
  meta: {
    isFetching: false
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_HISTORY: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isFetching: true
        }
      };
    }
    case RECEIVE_HISTORY: {
      return {
        ...state,
        indexes: action.indexes,
        meta: {
          ...state.meta,
          isFetching: false
        }
      };
    }
    case FAILED:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function requestHistory() {
  return { type: REQUEST_HISTORY };
}

export function receiveHistory(indexes) {
  return { type: RECEIVE_HISTORY, indexes };
}

export function failed() {
  return { type: FAILED };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export function getHistory(id) {
  return async dispatch => {
    // reseta os valores que vem do formulario
    dispatch(receiveHistory([]));
    // inicia o request indicando que esta procurando dados
    dispatch(requestHistory());

    financialIndex
      .getHistory(id, {
        order_col: 'created_at',
        order_rule: 'desc'
      })
      .then(({ data }) => {
        dispatch(receiveHistory(data));
      })
      .catch(() => {
        dispatch(failed());
      });
  };
}
