import * as propertiesService from 'services/properties';

export const getPropertiesByIds = (ids) => {
  return propertiesService
    .getList({
      filter: {
        by_id: ids,
      },
    })
    .then((res) => res.data);
};
