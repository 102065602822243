import React from 'react';
import MainTitle from 'components/MainTitle';

const ModalHeader = ({ title, text, children }) => (
  <MainTitle
    title={title}
    text={text}
    className="Modal__title"
    style={{
      margin: '0 0 10px',
      padding: '20px 15px'
    }}
  >
    {children}
  </MainTitle>
);

export default ModalHeader;
