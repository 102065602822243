import { useState } from 'react';

export function useAccordion(initialState) {
  const [openItems, setOpenItems] = useState(initialState);

  const toggleItem = (itemKey) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(itemKey)) {
        return prevOpenItems.filter((key) => key !== itemKey);
      } else {
        return [...prevOpenItems, itemKey];
      }
    });
  };

  return {
    openItems,
    toggleItem,
  };
}
