import React, { useEffect } from 'react';
// Components
import PropertyChart from './components/PropertyChart';
import PropertyTable from './components/PropertyTable';
// Modules
import { useReport } from '../hooks';
import { getPropertiesOruloPerTypeTransaction } from 'services/reports';
import WrapperReport from '../../components/WrapperReport';
import NoData from 'pages/Reports/components/NoData';
import iconGraph from './icon-graph.svg';
import background from './background-chart.png';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default function OruloPerTypeTransaction() {
  const { data, meta, isFetching, fetchData } = useReport({
    request: getPropertiesOruloPerTypeTransaction
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <WrapperReport
      title="Estoque de Imóveis do órulo por Tipo/Negócio"
      text="Acompanhe seu estoque de imóveis do Órulo, separados por tipo de imóvel e negócio."
      data={data}
      isFetching={isFetching}
      renderNoData={() => (
        <NoData
          iconImage={iconGraph}
          backgroundImage={background}
          text="Entenda o estoque do órulo, por transação e por corretor."
        />
      )}
      renderChart={() => (
        <PropertyChart
          width={'100%'}
          height={250}
          data={data}
          graphs={meta?.graphs}
          colors={COLORS}
        />
      )}
      renderTable={() => <PropertyTable data={data} meta={meta} />}
    />
  );
}
