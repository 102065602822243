import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 40px 20px 0;

  .FormGroup {
    margin-bottom: 0;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const Actions = styled.div`
  display: flex;
`;
