import React from 'react';
import Button from 'components/Button';
import Popover from 'components/Popover';
import Form from './Form';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { useUnity } from 'pages/EnterpriseStore/pages/Unity/contexts/UnityContext';

const ImportCondo = ({ className }) => {
  const { enterpriseId } = useEnterprise();
  const { fetchData } = useUnity();

  return (
    <Popover
      enterpriseId={enterpriseId}
      component={Form}
      onSubmitSuccess={() => {
        fetchData();
      }}
    >
      <Button color="secondary" className={className}>
        Adicionar Unidade
      </Button>
    </Popover>
  );
};

export default ImportCondo;
