import { useRequest } from 'hooks/useRequest';
import { getHistories } from '../services/histories';
import { useEffect } from 'react';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';

export function useGetHistory() {
  const realEstate = useCurrentRealEstate();

  const { data, meta, isFetching, fetchData } = useRequest({
    request: getHistories,
    initialFetch: false,
  });

  useEffect(() => {
    if (realEstate?.id) {
      fetchData({
        filter: {
          logeable_id: realEstate.id,
          category: 'financial',
        },
      });
    }
  }, [realEstate]);

  return {
    history: data,
    metaHistory: meta,
    isFetchingHistory: isFetching,
    fetchHistory: fetchData,
  };
}
