import { CATEGORIES } from 'containers/ModalRoot/ModalSiteTemplate/constants';

export const useCategories = () => {
  return {
    categories: CATEGORIES,
    isFetchingCategories: false,
    fetchCategories: () => {},
  };

  /*const {
    data: categories,
    isFetching: isFetchingCategories,
    fetchData: fetchCategories,
  } = useRequest({
    request: siteTemplatesService.getTemplateCategories,
  });

  return { categories, isFetchingCategories, fetchCategories };*/
};
