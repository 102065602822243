import { createContext, useCallback, useContext, useMemo } from 'react';
import { useRequest } from 'hooks/useRequest';
import * as propertiesService from 'services/properties';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useDispatch } from 'react-redux';
import { uploadFiles } from 'lib/reducer-helpers';
import { Actions as UploadActions } from 'modules/fileUpload';
import { arrayMove } from 'react-sortable-hoc';

export const GalleryContext = createContext({
  propertyId: null,
  data: [],
  fetchData: null,
  removeImage: null,
  removeAllImages: null,
  uploadImages: null,
  handleSortEnd: null,
  handleClickSpin: null,
  handleEditCaption: null,
  handleEditCaptionSuccess: null,
});

export const GalleryProvider = ({ propertyId, children }) => {
  const dispatch = useDispatch();

  const { data, setData, fetchData } = useRequest({
    request: propertiesService.getImages,
    initialFetch: false,
  });

  const lastOrder = useMemo(() => {
    try {
      return data[data.length - 1].order;
    } catch {
      return 0;
    }
  }, [data]);

  const removeImage = useCallback(
    ({ id, gallery, property_id }) =>
      () => {
        dispatch(
          openConfirmation({
            title: 'Deseja remover essa imagem?',
            request: () => propertiesService.removeImage(id, property_id),
            onSuccess: () => {
              fetchData(property_id, {
                filter: {
                  gallery,
                },
              });
            },
          })
        );
      },
    []
  );

  const removeAllImages = useCallback(
    ({ galleryId, id }) =>
      () => {
        dispatch(
          openConfirmation({
            title: 'Deseja remover todas as imagens?',
            request: () => propertiesService.removeGallery(id, galleryId),
            onSuccess: () => {
              fetchData(id, {
                filter: {
                  gallery: galleryId,
                },
              });
            },
          })
        );
      },
    []
  );

  const uploadImages = useCallback(
    ({ property_id, gallery, files, ...rest }) => {
      const totalInGallery = data.length;
      const totalCanUpload = 50 - totalInGallery;

      // Coloca ordem em todos os arquivos
      const _filesWithOrder = files
        ?.slice(0, totalCanUpload)
        ?.map((file, index) => {
          return {
            file,
            order: index,
          };
        });

      return uploadFiles({
        request: propertiesService.addImage,
        params: ({ file, order }) => {
          return {
            property_id,
            image: file,
            name: file.name,
            order: lastOrder + order + 1,
            gallery,
          };
        },
        files: _filesWithOrder,
        onUploadStart: () => {
          dispatch(UploadActions.uploadStart());

          _filesWithOrder.forEach(({ file }) => {
            dispatch(UploadActions.addFile(file));
          });
        },
        onUploadSuccess: (data, upload, { file }) => {
          return dispatch(UploadActions.uploadSuccess(file));
        },
        onUploadFail: (data, { file }) =>
          dispatch(UploadActions.uploadError(file)),
        onUploadEnd: async () => {
          // await propertiesService.orderImages(property_id, gallery);
          await fetchData(property_id, { filter: { gallery } });
          await propertiesService.registerAddImage(property_id);

          dispatch(UploadActions.uploadEnd());
          dispatch(UploadActions.reset());
        },
      });
    },
    [lastOrder]
  );

  const updateImage = useCallback((currentImage) => {
    setData((images) => {
      return images.map((image) => {
        if (image.id === currentImage.id) {
          return currentImage;
        }

        return image;
      });
    });
  }, []);

  const handleSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setData((items) => {
      const newItems = arrayMove(items, oldIndex, newIndex);

      // pega a imagem para ser modificada
      const image = newItems.find(
        (image, index) => [newIndex].indexOf(index) >= 0
      );

      propertiesService.alterImage({ ...image, order: newIndex + 1 });

      return newItems;
    });
  }, []);

  const handleEditCaption = useCallback((values) => {
    updateImage(values);
    propertiesService.alterImage(values);
  }, []);
  const handleEditCaptionSuccess = useCallback(() => {}, []);

  const setLoadingImage = useCallback(
    (id, value) => {
      setData((images) => {
        // Pega a imagem
        return images.map((image) => {
          if (image.id === id) {
            return { ...image, isLoading: value };
          }
          return image;
        });
      });
    },
    [data]
  );

  const handleClickSpin = useCallback(
    ({ id, property_id }) =>
      async () => {
        setLoadingImage(id, true);

        const res = await propertiesService.rotateImage({
          id,
          property_id,
          angle: -90,
        });

        // Modifica a imagem para a que é retornada do servidor
        updateImage(res.data);
      },
    []
  );

  return (
    <GalleryContext.Provider
      value={{
        propertyId,
        data,
        fetchData,
        removeImage,
        removeAllImages,
        uploadImages,
        handleSortEnd,
        handleClickSpin,
        handleEditCaption,
        handleEditCaptionSuccess,
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};

export const useGallery = () => useContext(GalleryContext);
