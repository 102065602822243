import { combineReducers } from 'redux';
// Services
import * as people from 'services/people';
// Modules
import { closeModal } from './modal';
import { getRealtors } from './realtors';
import { getGroups } from './peopleGroups';
import location, { locationSelectorsCreate, thunksCreator } from './location';
import { addPerson, updatePerson as _updatePerson } from 'modules/people';
// Helpers
import { createModule } from 'lib/reducer-helpers';

const createAction = createModule('people');

// Actions
const REQUEST_INITIAL_VALUES = createAction('REQUEST_VALUES');
const RECEIVE_INITIAL_VALUES = createAction('RECEIVE_VALUES');
const FAILED_INITIAL_VALUES = createAction('FAILED');
const RESET_FORMDATA = createAction('RESET_FORMDATA');
const SET_PERSON = createAction('SET_PERSON');
const UNSET_PERSON = createAction('UNSET_PERSON');

export const locationSelectors = locationSelectorsCreate('modalPersonAdd');
export const locationThunks = thunksCreator('modalPersonAdd');

// Initial State
const initialState = {
  person: {
    id: null,
  },
  formData: {
    is_owner: null,
    owner_id: null,
  },
  location: {
    countries: {},
    states: {},
    cities: {},
    neighborhoods: {},
  },
  meta: {
    isFetching: false,
  },
};

function reducerPerson(state = initialState.person, action) {
  switch (action.type) {
    case SET_PERSON:
      return action.person;
    case UNSET_PERSON:
      return initialState.person;
    default:
      return state;
  }
}

function reducerFormData(state = initialState.formData, action) {
  switch (action.type) {
    case RECEIVE_INITIAL_VALUES:
      return {
        ...state,
        ...action.formData,
      };
    case FAILED_INITIAL_VALUES:
    case RESET_FORMDATA:
      return initialState.formData;
    default:
      return state;
  }
}

function reducerMeta(state = initialState.meta, action) {
  switch (action.type) {
    case REQUEST_INITIAL_VALUES:
      return { ...state, isFetching: true };
    case RECEIVE_INITIAL_VALUES:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}

// Reducer

export default combineReducers({
  person: reducerPerson,
  formData: reducerFormData,
  meta: reducerMeta,
  location: location('modalPersonAdd'),
});

export const setPerson = (person) => ({ type: SET_PERSON, person });
export const unsetPerson = () => ({ type: UNSET_PERSON });

export const requestInitialValues = () => ({ type: REQUEST_INITIAL_VALUES });

export const receiveInitialValues = (formData) => ({
  type: RECEIVE_INITIAL_VALUES,
  formData,
});

export function resetFormdata() {
  return { type: RESET_FORMDATA };
}

/**
 * Insere um cliente
 * @param values
 * @return {function(*): Promise<any>}
 */
export const insertPerson = (values) => (dispatch) => {
  return people.add(values).then((res) => {
    try {
      dispatch(addPerson(res.data));
    } catch {}
    dispatch(closeModal());
    return res;
  });
};

export const updatePerson = (values) => (dispatch) =>
  people.update(values).then((res) => {
    dispatch(_updatePerson(values));
    dispatch(closeModal());
    return res;
  });

export const fetchLocation =
  ({ country_id, state_id, city_id }) =>
  (dispatch) => {
    if (country_id) locationThunks.getStates({ country_id })(dispatch);
    if (state_id) locationThunks.getCities({ state_id })(dispatch);
    if (city_id) locationThunks.getNeighborhoods({ city_id })(dispatch);
  };

export function getModalPeopleValues(id) {
  return async (dispatch, getState) => {
    // Pega o usuário que está
    const currentUser = localStorage.getItem('current-user');
    const { real_estate } = currentUser;

    // reseta os valores que vem do formulario
    dispatch(resetFormdata());

    // inicia o request indicando que esta procurando dados
    dispatch(requestInitialValues());

    // Busca todos os corretores da imobiliaria
    getRealtors()(dispatch);

    // Busca todas as categorias do cliente
    // eg.: Proprietário, Comprador ...etc
    getGroups()(dispatch);

    // Busca os paises
    locationThunks.getCountries()(dispatch);

    // busca dados no banco
    if (id) {
      people
        .getOne(id, {
          include:
            'groups,city,state,country,relateds,relateds_inverse,phones,neighborhood',
        })
        .then(({ data }) => {
          const newData = { ...data };

          if (data.neighborhood) {
            newData.country_id = data.country.id;
            newData.state_id = data.state.id;
            newData.city_id = data.city.id;
            newData.neighborhood_id = data.neighborhood.id;

            fetchLocation({
              country_id: data.country.id,
              state_id: data.state.id,
              city_id: data.city.id,
            })(dispatch);
          }

          newData.groups_id = data.groups
            ? data.groups.map((group) => group.id)
            : [];

          dispatch(setPerson(data));

          dispatch(receiveInitialValues(newData));
        });
    } else {
      const {
        login: {
          currentUser: { id: user_id, realtor },
        },
      } = getState();

      let initialValues = {};

      // Verifica se a imobiliaria tem endereço
      if (real_estate.neighborhood_id) {
        fetchLocation(real_estate.relations)(dispatch);

        initialValues.country_id = real_estate.relations.country_id;
        initialValues.state_id = real_estate.relations.state_id;
        initialValues.city_id = real_estate.relations.city_id;
      }

      // se o usuario logado for corretor seleciona ele na lista de corretores
      if (realtor) initialValues = { ...initialValues, user_id };

      initialValues = {
        ...initialValues,
        type: '1',
      };

      dispatch(receiveInitialValues(initialValues));
    }
  };
}

export function handleFormSubmit(values) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      modalPersonAdd: { formData },
      modal: {
        modalProps: { callBack },
      },
    } = state;

    const newValues = {
      ...values,
    };

    if (formData.id) {
      return updatePerson(newValues)(dispatch).then(async ({ data }) => {
        const { data: person } = await people.getOne(data.id, {
          include: 'relateds,primary_phone,user,groups',
        });
        if (callBack) callBack(person, dispatch);
        return person;
      });
    }

    return insertPerson(newValues)(dispatch).then(async ({ data }) => {
      const { data: person } = await people.getOne(data.id, {
        include: 'relateds,primary_phone,user,groups',
      });
      if (callBack) callBack(person, dispatch);
      return person;
    });
  };
}
