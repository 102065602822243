import api from 'services';
import _ from 'lodash';

/**
 * Pega os dados da descrição
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const get = async buildingId => {
  try {
    const response = await api.getList(
      `settings/sites/buildings/${buildingId}/establishments`
    );

    const establishments = response.data.establishments.map(establishment => ({
      ...establishment,
      isChecked: true
    }));

    response.data.establishments = _.keyBy(establishments, 'id');

    return response;
  } catch (err) {
    throw err;
  }
};

/**
 * Atualiza os dados da descrição
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = ({ id, ...values }) => {
  try {
    const selectedEstablishments = _(values.establishments)
      .map((item, key) => ({ id: key, ...item }))
      .filter(({ isChecked }) => isChecked)
      .value();

    return api.patch(`settings/sites/buildings/${id}/establishments`, {
      establishments: selectedEstablishments
    });
  } catch (err) {
    throw err;
  }
};
