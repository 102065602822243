export default values => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (!values.client_id) {
    errors.client_id = REQUIRED_MSG;
  }

  if (!values.client_secret) {
    errors.client_secret = REQUIRED_MSG;
  }

  if (!values.user_id) {
    errors.user_id = REQUIRED_MSG;
  }

  return errors;
};
