import Map from "components/MapView/Map";

const MAP_HEIGHT = 359;

function MapView({
  zoom,
  style,
  isExactPlace,
  showMarker,
  position,
  handleDropMarker,
  onZoomChange,
}) {
  return (
    <div className="MapView" style={style}>
      <div className="MapView__item">
        <Map
          zoom={zoom}
          isExactPlace={isExactPlace || showMarker}
          containerElement={<div style={{ height: MAP_HEIGHT }} />}
          center={position}
          position={position}
          handleDropMarker={handleDropMarker}
          onZoomChange={onZoomChange}
        />
      </div>
    </div>
  );
}

export default MapView;
