// import { Toggle } from "components/Form";
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import {
  TableData,
  ToggleableTd,
} from 'containers/ModalRoot/ModalPayment/styles';
import { formatter } from 'containers/ModalRoot/ModalPayment/utils/moneyFormatter';
import { Field } from 'redux-form';
import { Toggle } from 'components/Form';
// import { Field } from "redux-form";

const DWV = () => {
  const { hasDwv, prices, values } = useModalPayment();

  const price = `R$ ${formatter.format(prices.dwv_price)}`;

  return (
    <tr>
      <td className="item">
        <h3>Integração com banco de dados DWV</h3>
        <p>É necessário contrato com eles para acessar os dados</p>
      </td>
      {hasDwv ? (
        <>
          <td />
          <td />
          <TableData align="right">{price}</TableData>
        </>
      ) : (
        <>
          <ToggleableTd>
            <Field name="is_dwv" component={Toggle} />
          </ToggleableTd>
          <TableData align="right">{price}</TableData>
          <TableData align="right">
            {values.is_dwv ? price : 'R$ 0,00'}
          </TableData>
        </>
      )}
    </tr>
  );
};

export default DWV;
