import React from 'react';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import InputCopy from 'components/InputCopy';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 15px 40px 10px;

  p + p {
    margin-top: 15px;
  }

  .FormGroup {
    margin-top: 15px;
  }
`;

function Success({ currentPortal, handleClose }) {
  return (
    <ModalTemplate title="Integração com Portal" handleClose={handleClose}>
      <Wrapper>
        <p>
          <strong>
            Parabéns! A configuração do portal no Tecimob está concluída!
          </strong>
        </p>
        {currentPortal?.portal?.leads_link ? (
          <>
            <p>
              O próximo passo, é informar ao portal, a URL onde ele poderá
              encontrar os seus imóveis, e também a URL para que ele envie aqui
              pro seu CRM todos os contatos que você receber no portal.
            </p>
            <InputCopy
              label="1 - URL com dados dos seus imóveis"
              value={currentPortal?.xml_url}
            />
            <InputCopy
              label="2 - URL para recebimento de leads no CRM"
              value={currentPortal?.portal?.leads_link}
            />
          </>
        ) : (
          <>
            <p>
              O próximo passo, é informar ao portal, a URL onde ele poderá
              encontrar os seus imóveis.
            </p>
            <InputCopy
              label="URL com dados dos seus imóveis"
              value={currentPortal?.xml_url}
            />
          </>
        )}

        <p>
          Feito isso, basta agora selecionar os imóveis que você deseja enviar
          para o portal e aguardar o prazo de 24 horas para que a primeira carga
          seja sincronizada.
        </p>
        <p>
          <em>
            Recomendamos que nos primeiros dias de integração, você acesse a
            área de relatório de integração, no painel do portal, para
            acompanhar com está o processo de integração, ver alertas e
            sugestões de necessidade de ajuste no cadastro de algum imóvel, como
            dados requeridos ou ajustes necessários.
          </em>
        </p>
      </Wrapper>
      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button
          to={`/portals/${currentPortal?.id}`}
          type="button"
          color="success"
          onClick={handleClose}
        >
          Selecionar imóveis para envio
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

export default Success;
