import { createValidation } from 'lib/validate-helpers';

export default values => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  const { email } = createValidation(values, errors);

  email('email');

  if (!values.password) {
    errors.password = REQUIRED_MSG;
  } else if (values.password.length < 6) {
    errors.password = 'Senha deve possuir mais do que 6 caracteres';
  }

  return errors;
};
