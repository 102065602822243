import React from 'react';
import html2canvas from 'html2canvas';
import { withRouter } from 'react-router-dom';
// Components
import MainTitle from 'components/MainTitle';
import Container from 'components/Container';
import FormCreateLogo from './components/FormCreateLogo';
// Services
import * as logosService from 'services/sites/logos';
// Constants
import LOGOS from 'containers/LogosSelector/data';

const canvasToBlob = (canvas) =>
  new Promise((resolve) => {
    canvas.toBlob((blob) => resolve(blob));
  });

class LogoCreate extends React.Component {
  constructor(props) {
    super(props);

    this.logo = React.createRef();
  }
  get initialValues() {
    return {
      texts: LOGOS[0].texts,
    };
  }

  /**
   * Pega os parametros
   * @returns { device, position }
   */
  get params() {
    try {
      return this.props.match.params;
    } catch {
      return {};
    }
  }

  uploadImageByDevicePosition = (blob) => {
    const { device, position } = this.params;

    if (position === 'header') {
      return logosService.updateLogoHeader({
        image: blob,
        device,
        is_logo_shown: true,
      });
    }

    return logosService.updateLogoFooter({
      image: blob,
      device,
      is_logo_shown: true,
    });
  };

  onSubmit = async (values) => {
    // Transforma o HTML da div da logo em um canvas
    const canvas = await html2canvas(this.logo.current, {
      backgroundColor: null,
    });

    // Transforma um canvas em imagem
    return canvasToBlob(canvas).then(this.uploadImageByDevicePosition);
  };

  onSubmitSuccess = () => {
    const { push } = this.props.history;
    push('/site/layout/style/logo');
  };

  render() {
    return (
      <>
        <MainTitle
          title="Logo"
          text="Defina como seu logo aparecerá no topo e no rodapé do site."
        />
        <Container>
          <FormCreateLogo
            logoRef={this.logo}
            initialValues={this.initialValues}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
          />
        </Container>
      </>
    );
  }
}

export default withRouter(LogoCreate);
