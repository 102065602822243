import React, { useEffect, useState } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Col, Row } from 'react-flexbox-grid';
import ListOptions from 'components/ListOptions';
import { Textarea } from 'components/Form';
import Button from 'components/Button';
import FieldCalendar from './components/FieldCalendar';
import FieldPrivate from './components/FieldPrivate';
import FieldShareOptions from './components/FieldShareOptions';
import * as messagesServices from 'services/settings/messages';
import { whatsappSendUrl } from 'lib/share-helpers';
import { getOne } from 'services/people';
import api from 'services';
import { openModalPerson } from 'modules/modal';
import { useDispatch } from 'react-redux';

export const selector = formValueSelector('ModalShare');

const INITIAL_VALUES = {
  should_send_whatsapp: false,
  should_send_email: false,
  should_create_calendar: false,
  person_cellphone_ddi: '55',
  person_cellphone_iso2: 'br',
};

const openWhatsappPage = (values) => {
  if (values?.person_cellphone_number) {
    setTimeout(() => {
      window.open(
        whatsappSendUrl(
          values?.person_cellphone_number,
          values?.message,
          values?.person_cellphone_ddi
        )
      );
    }, 50);
  }
};

const getPrimaryPhone = (person) => person?.phones?.[0];

const getPersonCellphone = (person) => {
  const primaryPhone = getPrimaryPhone(person);

  return {
    number: primaryPhone?.number,
    ddi: primaryPhone?.ddi,
    iso2: primaryPhone?.iso2,
  };
};

function ModalShare({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  initialize,
  initialValues = {},
  person,
  properties_id,
  handleSubmit,
}) {
  const dispatch = useDispatch();
  const [_person, setPerson] = useState(person);

  /**
   * Retorna o texto padrão
   * @returns {Promise<string>}
   */
  const shareText = async () => {
    const {
      data: { share_properties_list, share_property },
    } = await messagesServices.getOne();

    const currentUser = localStorage.getItem('current-user');

    let text = ``;

    if (properties_id.length > 1) text = share_properties_list;
    else text = share_property;

    if (currentUser.creci) {
      text += `

${currentUser.name}
CRECI: ${currentUser.creci}
${currentUser.email}
${currentUser.cellphone}`;
    }

    return text;
  };

  const getPersonById = async (id) => {
    const { data } = await getOne(id, { include: 'phones' });
    return data;
  };

  const handleOpenPerson = () => {
    dispatch(openModalPerson(_person));
  };

  const getInitialValues = async () => {
    let initial = {};

    if (person) {
      const currentPerson = await getPersonById(person.id);
      setPerson(currentPerson);

      const cellphone = getPersonCellphone(currentPerson);

      if (cellphone.number) {
        initial.person_cellphone_number = cellphone.number;
        initial.person_cellphone_iso2 = cellphone.iso2;
        initial.person_cellphone_ddi = cellphone.ddi;
        initial.should_send_whatsapp = true;
      }

      if (currentPerson.email) {
        initial.email = currentPerson.email;
        initial.should_send_email = true;
      }
    }

    return {
      ...INITIAL_VALUES,
      ...initialValues,
      ...initial,
    };
  };

  const setInitialValues = async () => {
    const text = await shareText();

    const _initialValues = await getInitialValues();

    initialize({
      ..._initialValues,
      message: initialValues.message ? initialValues.message : text || '',
    });
  };

  useEffect(() => {
    setInitialValues();
  }, [person]);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate
          title={
            _person.name ? (
              <>
                Compartilhamento de imóvel para:{' '}
                <strong className="h-link" onClick={handleOpenPerson}>
                  {_person.name}
                </strong>
              </>
            ) : (
              'Compartilhamento de imóvel'
            )
          }
          handleClose={handleClose}
        >
          <Row className="row--divider">
            <Col xs={6} style={{ marginTop: '-20px', paddingTop: '20px' }}>
              <ListOptions
                style={{
                  width: 'calc(100% + 34px)',
                  marginLeft: '-19px',
                  marginTop: '-20px',
                }}
              >
                <FieldShareOptions />
                <FieldCalendar />
                <FieldPrivate />
              </ListOptions>
            </Col>
            <Col xs={6} style={{ marginTop: '-20px', paddingTop: '20px' }}>
              <Field
                type="simple"
                name="message"
                height={383}
                label="Mensagem"
                component={Textarea}
              />
            </Col>
          </Row>
          <ModalFooter withBorder>
            <Button
              type="button"
              color="white"
              colorText="secondary"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <div className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              Compartilhar
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </form>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalShare',
  onSubmit: (values, dispatch, props) => {
    const getDetailPageUrl = (personId) => {
      try {
        const { primary_domain_url } = localStorage.getItem('current-user');
        return `${primary_domain_url}/cliente/${personId}`;
      } catch {
        return '';
      }
    };

    const shouldSendEmail = values.should_send_email;
    const shouldSendWhatsapp = values.should_send_whatsapp;

    const person = {};

    if (shouldSendWhatsapp) {
      person.cellphone_number = values.person_cellphone_number;
    }

    if (shouldSendEmail) {
      person.email = values.email;
    }

    if (shouldSendWhatsapp) {
      let currentMessage = `${values.message}\n\n${getDetailPageUrl(
        props.person.id
      )}`;
      openWhatsappPage({ ...values, message: currentMessage });
    }

    return api.create(`people/${props.person.id}/matcheds/manually-cross`, {
      ...values,
      properties_id: props.properties_id,
      message: values.message,
      person,
    });
  },
  validate: (values) => {
    let errors = {};

    if (values.should_send_whatsapp && !values.person_cellphone_number) {
      errors.person_cellphone_number = ['Campo obrigatório'];
    }

    if (values.should_send_email && !values.email) {
      errors.email = ['Campo obrigatório'];
    }

    if (!values.email && !values.person_cellphone_number) {
      errors.email = ['Campo obrigatório'];
      errors.person_cellphone_number = ['Campo obrigatório'];
    }

    return errors;
  },
  onSubmitSuccess: (response, dispatch, props) => {
    props.handleClose();
  },
})(ModalShare);
