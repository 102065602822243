import styled from 'styled-components';

export const GridIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px 15px;
`;
export const Icon = styled.div`
  cursor: pointer;

  img {
    width: 100%;
    border-radius: 4px;
  }
`;
