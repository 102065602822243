import React from 'react';
import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import styled from 'styled-components';
import analytics from './analytics.png';
import Button from 'components/Button';
import { useHistory } from 'react-router';

export const TextWrapper = styled.div`
  p + p {
    margin-top: 10px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
`;

export const ColLeft = styled.div`
  flex-shrink: 0;
  margin-right: 46px;
`;

export const ColRight = styled.div`
  flex-grow: 1;
  min-width: 1px;
`;

function ModalAnalyticsInfo({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
}) {
  const history = useHistory();
  const handleAddScript = () => {
    history.push('/site/config/scripts/create?isAnalytics=true');
    handleClose();
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Integração com Google Analytics"
        handleClose={handleClose}
      >
        <Content>
          <ColLeft>
            <img src={analytics} alt="Logo do analytics" />
          </ColLeft>
          <ColRight>
            <TextWrapper>
              <p>
                <strong>O Google Analytics</strong>
              </p>

              <p>
                O Google Analytics é um serviço oferecido de forma gratuita pelo
                Google para monitoramento e análise de sites. Ela se integra com
                outros serviços do Google, como Ads e Search Console.
              </p>
              <p>
                Com ela é possível monitorar o perfil de quem acessa seu site,
                páginas mais acessadas, conversões, dispositivos, cidades,
                dentre outros dados.
              </p>

              <p>
                <strong>Requisitos</strong>
              </p>

              <p>
                1 - Ter uma conta ativa no Google Analytics
                <br />
                <a
                  href="https://scribehow.com/shared/Criar_conta_no_Google_Analytics_GA4__rAqm-rf4RUW1vSpbbM1rHQ"
                  className="h-link"
                  target="_blank"
                >
                  Clique aqui para saber como criar uma conta grátis
                </a>
              </p>

              <p>
                2 - Copiar o script de acompanhamento para adicionar ao site
                <br />
                <a
                  href="https://scribehow.com/shared/Pegar_script_no_Google_Analytics_para_inserir_no_site__8k3sPGU3Q6OZkQ5zlPzlzQ"
                  className="h-link"
                  target="_blank"
                >
                  Clique aqui para saber onde encontrar e copiar
                </a>
              </p>

              <p>
                3 - Adicionar o script no seu site
                <br />
                Após ter feito os 2 passos acima, clique no botão ”Inserir
                script no meu site”
              </p>
            </TextWrapper>
          </ColRight>
        </Content>
        <ModalFooter>
          <Button color="white" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button color="success" onClick={handleAddScript}>
            Inserir script no meu site
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalAnalyticsInfo;
