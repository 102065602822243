import styled from 'styled-components';

export const WrapperPrice = styled.div`
  text-align: right;
`;

export const Price = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const Link = styled.a`
  font-size: 12px;
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: underline;
`;

export const Icon = styled.img`
  width: 36px;
  height: 36px;
`;

export const Text = styled.p`
  display: flex;
  align-items: center;
  gap: 20px;
`;
