// Widgets.js
import { createModule } from 'lib/reducer-helpers';
import * as contractsService from 'services/financialv2/contracts';

const createAction = createModule('contracts');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');

// Selectors
export const contractsSelector = (state) => state.financial.contracts;

// Initial State
const initialState = {
  data: [],
  meta: {
    isFetching: false,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE:
      return {
        ...state,
        data: action.data,
        meta: { ...state.meta, isFetching: false },
      };
    default:
      return state;
  }
}

export const Actions = {
  request: () => ({ type: REQUEST }),
  receive: (data) => ({ type: RECEIVE, data }),
};

// side effects, only as applicable
// e.g. thunks, epics, etc
export const fetchContracts = (params) => async (dispatch) => {
  dispatch(Actions.request());

  const { data } = await contractsService.getList(params);

  dispatch(Actions.receive(data));
};
