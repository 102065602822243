import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import imgLoading from '../../assets/img/home-loading.svg';

const defaultProps = {
  isVisible: false,
  isBlock: false,
  isFullScreen: false,
  isFullComponent: false,
  position: 'center'
};

const propTypes = {
  isVisible: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  isFullComponent: PropTypes.bool,
  isBlock: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'center', 'right'])
};

const Loading = ({
  isVisible,
  isBlock,
  position,
  isFullScreen,
  isFullComponent,
  children,
  ...props
}) =>
  isVisible && (
    <div
      {...props}
      className={classnames('Loading', {
        'Loading--block': isBlock,
        'Loading--fullscreen': isFullScreen,
        'Loading--fullcomponent': isFullComponent,
        [`h-text-${position}`]: position
      })}
    >
      <img src={imgLoading} alt="Loading" className="Loading__img" />
      {children && (
        <p className="h-margin-top--15 h-color--primary-light">{children}</p>
      )}
    </div>
  );

Loading.defaultProps = defaultProps;
Loading.propTypes = propTypes;

export default Loading;
