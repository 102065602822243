import styled from "styled-components";

export const Wrapper = styled.div`
  width: 274px;
  height: 100%;
  background-color: #fff;
  padding: 30px;
  border-right: 1px solid ${p => p.theme.borderColor};
  overflow: auto;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
`;
