import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import * as propertyService from 'services/properties';
// Modules
import { openModalProperty, openModalShareProperty } from 'modules/modal';

// import from './styles';

function SharePropertyWhatsapp({ propertyId, children, component: Component }) {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(
      openModalShareProperty({
        type: 'share',
        properties: [propertyId],
        onClose: async () => {
          const { data: property } = await propertyService.getOne(propertyId);
          dispatch(openModalProperty({ property }));
        }
      })
    );
  }

  return <Component onClick={handleClick}>{children}</Component>;
}

SharePropertyWhatsapp.propTypes = {
  propertyId: PropTypes.string.isRequired,
  component: PropTypes.func,
  children: PropTypes.node
};

SharePropertyWhatsapp.defaultProps = {
  propertyId: null,
  children: null,
  component: Button
};

export default SharePropertyWhatsapp;
