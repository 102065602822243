import React from 'react';
import FormPosts from '../../components/FormPosts';
import * as postsAreaService from 'services/sites/posts-area';

function ContainerForm() {
  function onSubmit(values) {
    return postsAreaService.update(values);
  }

  return <FormPosts onSubmit={onSubmit} />;
}

export default ContainerForm;
