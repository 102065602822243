import { Col, Row } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
// Components
import Button from 'components/Button';
import Loading from 'components/Loading';
// Components
import CardProperty from 'components/CardProperty';
// Services
import Pagination from 'components/Pagination';
import { useRequest } from 'hooks/useRequest';
import {
  openModalDealLoss,
  openModalPerson,
  openModalProperty,
  openModalVideo,
} from 'modules/modal';
import * as peopleService from 'services/people';
import Actions from './components/Actions';
import { useOwnerId } from 'containers/ModalRoot/ModalPerson/hooks/useOwnerId';
import YoutubeImage from 'components/YoutubeImage';
import useCheckbox from 'hooks/useCheckbox';
import Header from './components/Header';

const PropertiesInterested = ({
  person: { id: people_id },
  modalPerson: { person },
  handleClose,
  handleOpenMySelf,
  afterClose,
}) => {
  const dispatch = useDispatch();
  const userOwnerId = useOwnerId();
  const {
    isChecked,
    checkedItems,
    isAllItemsChecked,
    handleToggle,
    clear,
    handleToggleAll,
  } = useCheckbox([]);

  const openMySelf = () => {
    setTimeout(() => {
      dispatch(
        openModalPerson(person, {
          initialPage: 'Properties',
          pageProperties: 'PropertiesInterested',
        })
      );
    }, 500);
  };

  const {
    data: matcheds,
    meta,
    fetchData,
    isFetching,
  } = useRequest({
    request: (params) =>
      peopleService.getDeals(person.id, {
        limit: 40,
        offset: 1,
        ...params,
        include: 'property,stage',
        sort: '-created_at',
      }),
  });

  const handleAssumeCustomer = () => {
    const currentUser = localStorage.getItem('current-user');
    dispatch(change('SelectOwner', 'user_id', currentUser.id));
  };

  if (isFetching) return <Loading isVisible />;

  if (matcheds.length === 0) {
    return (
      <Row
        middle="xs"
        style={{
          padding: '20px',
        }}
      >
        <Col xs={6}>
          <h4>Nenhum imóvel no CRM</h4>
          <p
            className="h-color--primary h-margin-top--10 h-margin-bottom--15"
            style={{ lineHeight: '1.4em' }}
          >
            Esta seção exibirá todos os imóveis associados à jornada de compra
            deste cliente.
          </p>
          <Button
            color="secondary"
            to="/properties/search"
            onClick={() => {
              handleClose();
            }}
          >
            Pesquisar Imóveis
          </Button>
        </Col>
        <Col xs={6} style={{ paddingLeft: '30px' }}>
          <YoutubeImage
            videoUrl="https://www.youtube.com/watch?v=5GdzEX5EGxo"
            onClick={() => {
              dispatch(
                openModalVideo({
                  title: 'Como utilizar o radar de oportunidades',
                  videoUrl: 'https://www.youtube.com/watch?v=5GdzEX5EGxo',
                  afterClose: handleOpenMySelf,
                })
              );
            }}
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Header
        checkedItems={checkedItems}
        isAllSelected={isAllItemsChecked(matcheds)}
        onCancel={clear}
        onLoss={() => {
          dispatch(
            openModalDealLoss(checkedItems, {
              afterClose: () => {
                openMySelf();
                if (afterClose) afterClose();
              },
            })
          );
        }}
        onSelectAll={() => {
          handleToggleAll(matcheds);
        }}
      />
      {matcheds.map((matched) => (
        <CardProperty
          isSelected={isChecked(matched.id)}
          key={matched?.id}
          type="horizontal"
          property={matched.property}
          onSelect={(...args) => {
            handleToggle(matched.id);
          }}
          handleClickProperty={() => {
            dispatch(
              openModalProperty({
                property: matched.property,
                afterClose: () => {
                  handleOpenMySelf();
                },
              })
            );
          }}
        >
          <Actions
            userId={userOwnerId}
            matched={matched}
            person={person}
            afterClose={afterClose}
          />
        </CardProperty>
      ))}

      <Pagination
        pagination={meta?.pagination}
        onPageChange={({ selected }) => {
          fetchData({ offset: selected + 1 });
        }}
      />
    </>
  );
};

export default PropertiesInterested;
