import * as postingsService from '../services/postings';
import * as movimentsService from '../services/moviments';
import { useRequest } from 'hooks/useRequest';
import { useCallback, useState } from 'react';

export function useStorePostings({ type = 1, isMoviment }) {
  const storePostings = (posting) => {
    if (isMoviment) {
      return movimentsService.updateMoviment({
        movimentId: posting.movimentId,
        postingId: posting.postingId,
        ...posting,
      });
    }

    return postingsService.storePostings({
      type,
      ...posting,
    });
  };

  return { storePostings };
}

export function useMovimentsList() {
  const { data, fetchData, isFetching, meta } = useRequest({
    request: postingsService.movimentsList,
    initialFetch: false,
  });

  const [debounceTimer, setDebounceTimer] = useState(null);

  const fetchMovimentsWithDebounce = useCallback(
    (params) => {
      if (debounceTimer) clearTimeout(debounceTimer);

      const newTimer = setTimeout(() => {
        fetchData(params);
      }, 300); // 300ms delay

      setDebounceTimer(newTimer);
    },
    [debounceTimer, fetchData]
  );

  return {
    moviments: data,
    fetchMoviments: fetchMovimentsWithDebounce,
    isFetchingMoviments: isFetching,
    movimentsMeta: meta,
  };
}
