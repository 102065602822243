import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 15px 35px;

  & + &:after {
    content: '';
    width: 1px;
    height: 53px;
    background-color: #e8eaed;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Number = styled.strong`
  font-size: 20px;
  color: #1f2937;
  font-weight: bold;
`;

export const Percent = styled.span`
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;

  svg {
    vertical-align: center;
    margin-top: -2px;
  }
`;

export const Title = styled.div`
  color: #6b7280;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
`;
