import { useCallback, useState } from 'react';
import Modal from 'react-modal';
// Components
import { ModalFooter, ModalTemplate } from '../../../components/Modal';
import Form from './components/Form';
// Services
import Button from 'components/Button';
import * as rdService from 'services/settings/crm-integration';

export default function ModalRd({
  modalConfig,
  isOpen,
  modalType,
  handleClose,

  onSubmitSuccess,
}) {
  const [isShowForm, setShowForm] = useState(false);
  const handleSubmit = useCallback(async (values) => {
    try {
      await rdService.connect(values);
      return values;
    } catch (e) {
      return e;
    }
  }, []);

  const handleSuccess = useCallback(({ client_id, ...rest }) => {
    window.location = `https://api.rd.services/auth/dialog?client_id=${client_id}&redirect_url=${process.env.REACT_APP_RD_REDIRECT_URI}`;
    if (onSubmitSuccess) onSubmitSuccess({ client_id, ...rest });
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Conectar com RD Station"
        titleActions={
          isShowForm && (
            <a
              href="https://scribehow.com/shared/Integrar_o_Tecimob_com_o_Marketing_RD_Station__MdDbux5ORfmwmolv6yz_Jg"
              target="_blank"
              className="h-link"
              rel="noreferrer"
            >
              Passo a passo
            </a>
          )
        }
        handleClose={handleClose}
      >
        {isShowForm ? (
          <Form
            onSubmit={handleSubmit}
            onSubmitSuccess={handleSuccess}
            handleClose={handleClose}
          />
        ) : (
          <>
            <p>
              Ativando este recurso, ao receber um lead no Tecimob será enviado
              o lead para a plafatorma da RD Station, para integrar{' '}
              <a
                href="https://scribehow.com/shared/Integrar_o_Tecimob_com_o_Marketing_RD_Station__MdDbux5ORfmwmolv6yz_Jg"
                target="_blank"
                className="h-link"
                rel="noreferrer"
              >
                siga estes passos
              </a>
            </p>
            <ModalFooter>
              <span className="h-flex__cell--grow" />
              <Button onClick={() => setShowForm(true)} color="success">
                Entendi
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalTemplate>
    </Modal>
  );
}
