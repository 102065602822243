import React from 'react';
import * as PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Input } from 'components/Form';
import * as peopleService from 'services/people';
import { normalizeCnpj, normalizeCpf } from 'lib/formHelpers';
// import { Container } from './styles';

const FieldCpfCnpj = ({
  ref,
  isLegalPerson = false,
  label,
  name = 'cpf_cnpj',
  onHasPerson,
  normalize,
  onBlur,
  ...props
}) => {
  const handleBlur = async (el, value) => {
    if (onHasPerson) {
      const { data: people } = await peopleService.getAll({
        filter: { cnpj_cnpj: value },
        include: 'user',
      });
      onHasPerson(people.length > 0);
    }

    if (onBlur) onBlur(el, value);
  };
  return (
    <Field
      refName={ref}
      label={label ? label : isLegalPerson ? 'CNPJ' : 'CPF'}
      name={name}
      component={Input}
      normalize={
        normalize ? normalize : isLegalPerson ? normalizeCnpj : normalizeCpf
      }
      onBlur={handleBlur}
      {...props}
    />
  );
};

FieldCpfCnpj.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  isLegalPerson: PropTypes.bool,
  onHasPerson: PropTypes.func,
};

export default FieldCpfCnpj;
