import api, { apiStorage } from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega as informações de site relacionadas ao rodapé
 * @param params
 * @return {Promise}
 */
export const getOneConfig = (params) =>
  api.getOne('settings/sites/testimonials', '', params);

/**
 * Atualiza as informações do site relacionadas ao rodapé
 * @param values
 * @return {Promise}
 */
export const updateConfig = (values) =>
  api.patch('settings/sites/testimonials/config', values);

/**
 * Pega um depoimento
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOne = ({ id }) => api.getOne('settings/sites/testimonials', id);

/**
 * Adiciona um novo depoimento
 * @param values
 * @returns {Promise<AxiosResponse<any>>}
 */
export const create = (values) =>
  api
    .create('settings/sites/testimonials', values)
    .then(responseMessage('Depoimento adicionado'));

/**
 * Atualiza um depoimento
 * @returns {Promise<AxiosResponse<any>>}
 */
export const update = (values) =>
  api
    .update('settings/sites/testimonials', values)
    .then('Depoimento atualizado');

export const uploadImage = async ({ id, image }) => {
  if (!image) return false;
  return apiStorage.uploadFile(`sites/testimonials/${id}`, { image });
};

export const createOrUpdate = async ({ image, ...values }) => {
  if (values.id) {
    const response = await uploadImage({ id: values.id, image });

    if (response === false) {
      return update(values);
    }

    return update({ ...values, file_url: response.data.file_url });
  }

  const res = await create(values);

  if (res.data.id) {
    await uploadImage({ id: res.data.id, image });
  }

  return res;
};

export const remove = ({ id }) =>
  api
    .delete('settings/sites/testimonials', id)
    .then(responseMessage('Depoimento removido'));

/**
 * Retorna os modelos de rodapé
 * @param params
 * @return {Promise}
 */
export const getComponents = (params) =>
  api.getList('settings/component-examples/testimonials', params);
