import styled, { css } from 'styled-components';

const iconStyle = ({ isChecked }) => {
  if (isChecked) {
    return css`
      color: #ff9702;
    `;
  }

  return css`
    color: ${p => p.theme.colors.primary};
    opacity: 0.5;
  `;
};

export const Star = styled.div.attrs(() => ({
  role: 'button',
  tabIndex: -1
}))`
  svg {
    font-size: 18px;
    ${iconStyle};
  }
`;
