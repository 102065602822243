// helpers
import { createValidation } from 'lib/validate-helpers';
import { removeCurrency } from 'lib/money-helpers';

export default (values, props) => {
  const errors = {};

  const validations = createValidation(values, errors);

  if (!props.isSearching) {
    validations.required(['people_id']);
  }

  if (!props.isSearching) {
    validations.required(['minimum_price', 'maximum_price']);
  }

  validations.required(['transaction']);

  if (values.maximum_price && values.minimum_price && !props.isSearching) {
    const max = removeCurrency(values.maximum_price);
    const min = removeCurrency(values.minimum_price);

    if (min > max) {
      errors.maximum_price = ['Valor máximo não pode ser menor que o mínimo'];
      errors.minimum_price = [' '];
    }

    if (values.transaction == 1 && max > min * 2) {
      errors.maximum_price = [
        'A diferença entre os valores não pode ser superior a 100%',
      ];
      errors.minimum_price = [' '];
    }

    if (values.transaction != 1 && max > min * 3) {
      errors.maximum_price = [
        'A diferença entre os valores não pode ser superior a 200%',
      ];
      errors.minimum_price = [' '];
    }
  }

  return errors;
};
