import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box, { BoxLink } from '../Box';
import { MdEdit } from 'react-icons/md';
import Button from 'components/Button';
import { Date, Wrapper } from './styles';
import { useCurrentCredit } from 'pages/PlanConfig/hooks/useCurrentCredit';
import moment, { format } from 'lib/moment';
import TextLoading from 'components/TextLoading';
import { openModalPayment, openModalUpdateContractDate } from 'modules/modal';
import { contractsSelector } from 'modules/financial/contracts';

function CreditUntil() {
  const dispatch = useDispatch();
  const {
    data: contracts,
    meta: { isFetching: isFetchingContracts },
  } = useSelector(contractsSelector);

  const hasContracts = useMemo(() => {
    if (isFetchingContracts) return false;
    return contracts && contracts.length > 0;
  }, [isFetchingContracts, contracts]);

  const {
    data,
    meta: { isFetching },
  } = useCurrentCredit();

  const handleClickRenewPlan = () => {
    dispatch(openModalPayment());
  };

  const handleClickContract = () => {
    dispatch(
      openModalPayment({
        contract: true,
      })
    );
  };

  const handleClickUpdateContractDate = () => {
    dispatch(openModalUpdateContractDate());
  };

  const renderButtonContract = () => {
    if (hasContracts) {
      return (
        <Button onClick={handleClickRenewPlan} color="secondary" size="medium">
          Renovar plano
        </Button>
      );
    }

    return (
      <Button onClick={handleClickContract} color="secondary" size="medium">
        Contratar plano
      </Button>
    );
  };

  return (
    <Box
      title="Crédito até"
      actions={() =>
        hasContracts && (
          <BoxLink onClick={handleClickUpdateContractDate}>
            <MdEdit /> Alterar data
          </BoxLink>
        )
      }
    >
      <Wrapper>
        <Date>
          {isFetching ? (
            <TextLoading width={50} />
          ) : (
            moment(data.end_at, format.date).format('DD/MM/YY')
          )}
        </Date>
        {renderButtonContract()}
      </Wrapper>
    </Box>
  );
}

export default CreditUntil;
