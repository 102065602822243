import api, { apiStorage } from 'services/index';

const baseImage = (buildingId) =>
  `settings/sites/buildings/${buildingId}/images`;
const basePlans = (buildingId) =>
  `settings/sites/buildings/${buildingId}/plans`;

export const GALLERIES = {
  IMAGES: 1,
  PLANS: 2,
  PROGRESS: 3,
};

/**
 * Ordena uma imagem indiferente de qual galeria ela pertence
 * @param buildingId
 * @param imageId
 * @param order
 * @return {Promise<AxiosResponse<any>>}
 */
export const move = ({ buildingId, imageId, order }) =>
  api.update(`${baseImage(buildingId)}/${imageId}`, { order });

/**
 * Remove uma imagem
 * @param buildingId
 * @param imageId
 * @return {Promise<AxiosResponse<any>>}
 */
export const remove = ({ buildingId, imageId }) =>
  apiStorage.delete(`sites/buildings/${buildingId}/images`, imageId);

const uploadImageByGallery = ({
  buildingId,
  image,
  gallery = GALLERIES.IMAGES,
  order,
}) =>
  apiStorage.uploadFile(`sites/buildings/${buildingId}/images`, {
    image,
    gallery,
    order,
  });

export const addImageBuildings = ({ buildingId, image, order }) =>
  uploadImageByGallery({ buildingId, image, gallery: GALLERIES.IMAGES, order });

export const addImagePlans = ({ buildingId, image, order }) =>
  uploadImageByGallery({ buildingId, image, gallery: GALLERIES.PLANS, order });

export const addImageProgress = ({ buildingId, image, order }) =>
  uploadImageByGallery({
    buildingId,
    image,
    gallery: GALLERIES.PROGRESS,
    order,
  });

/**
 * Retorna as imagens
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getImages = (buildingId) => api.get(baseImage(buildingId));

/**
 * Retorna as imagens
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = ({ id, ...values }) => api.patch(baseImage(id), values);

export const updateImage = ({ id, building_id, order, ...values }) =>
  api.update(`settings/sites/buildings/${building_id}/images/${id}`, values);

export const getPlans = (buildingId) => api.get(basePlans(buildingId));

export const updatePlans = ({ id, ...values }) =>
  api.patch(basePlans(id), values);
