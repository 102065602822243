import React from 'react';
import { reduxForm } from 'redux-form';
import { LevelOfInterest } from 'components/Form';
import * as dealsService from 'services/deals';

function FormLevelInterest({ dealId, handleSubmit }) {
  const handleChange = async (value) => {
    await dealsService.updateDeal('', dealId, {
      rate: value,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <LevelOfInterest name="rate" onChange={handleChange} />
    </form>
  );
}

export default reduxForm()(FormLevelInterest);
