import PropTypes from 'prop-types';

export function useIsTecimob() {
  return true;
}

function IsTecimob({ yes, no, children }) {
  const isTecimob = useIsTecimob();

  if (children) {
    return isTecimob ? children : null;
  }

  return isTecimob ? yes() : no();
}

IsTecimob.defaultProps = {
  yes: null,
  no: null,
  children: null,
};

IsTecimob.propTypes = {
  yes: PropTypes.func,
  no: PropTypes.func,
};

export default IsTecimob;
