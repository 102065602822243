import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Common Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/ModalEventForm';
// Containers
import SyncGoogleCalendar from 'containers/SyncGoogleCalendar';
// Service
import * as calendarsService from 'services/calendars';
// Helpers
import moment, { format } from 'lib/moment';
import Button from 'components/Button';
import { formValueSelector, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { openModalEvent, openModalPerson } from 'modules/modal';

class ModalEvent extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    initialValues: {
      duration_minutes: 60,
      reminder_minutes: 15,
      reminder_unit: 'minutes',
      color: '#00c080',
    },
    duration_options: [
      { value: 15, label: '15 minutos' },
      { value: 30, label: '30 minutos' },
      { value: 45, label: '45 minutos' },
      { value: 60, label: '1 hora' },
      { value: 90, label: '1 hora e 30 minutos' },
      { value: 120, label: '2 horas' },
      { value: 150, label: '2 horas e 30 minutos' },
      { value: 180, label: '3 horas' },
      { value: 210, label: '3 horas e 30 minutos' },
      { value: 240, label: '4 horas' },
      { value: 270, label: '4 horas e 30 minutos' },
      { value: 300, label: '5 horas' },
    ],
    isFetching: true,
  };

  durationOption = (minutes) => {
    let min = minutes % 60;
    let hours = Math.floor(minutes / 60) % 24;
    let days = Math.floor(minutes / 60 / 24);

    let label = '';
    if (days > 0) label += days === 1 ? `${days} dia` : `${days} dias`;
    if ((days > 0 && hours > 0) || (days > 0 && min > 0)) label += ` e `;
    if (hours > 0) label += hours === 1 ? `${hours} hora` : `${hours} horas`;
    if (hours > 0 && min > 0) label += ` e `;
    if (min > 0) label += min === 1 ? `${min} minuto` : `${min} minutos`;

    return {
      value: minutes,
      label,
    };
  };

  getDuration(duration_minutes) {
    const { start, end } = this.props;
    const { duration_options } = this.state;

    if (duration_minutes) {
      if (
        !duration_options.find(
          (duration) => duration.value === duration_minutes
        )
      ) {
        this.setState({
          duration_options: [
            ...duration_options,
            this.durationOption(duration_minutes),
          ],
        });
      }

      return duration_minutes;
    }

    if (start && end) {
      let minutes = moment(end).minute() - moment(start).minute();
      let hours = moment(end).hour() - moment(start).hour();
      let days = moment(end).day() - moment(start).day();
      if (days > 0) days++;
      if (minutes === 0 && hours === 0 && days === 0) days++;

      const minutesDiff = minutes;
      const hoursDiff = hours * 60;
      const daysDiff = days * 24 * 60;

      if (
        !duration_options.find(
          (duration) => duration.value === hoursDiff + minutesDiff + daysDiff
        )
      ) {
        this.setState({
          duration_options: [
            ...duration_options,
            this.durationOption(hoursDiff + minutesDiff + daysDiff),
          ],
        });
      }

      return hoursDiff + minutesDiff + daysDiff;
    }

    return 60;
  }

  async componentDidMount() {
    const { start, event, initialValues } = this.props;

    const _start = start || initialValues?.start_date;
    const _duration = initialValues?.duration_minutes || this.getDuration();

    this.setState({
      initialValues: {
        ...this.state.initialValues,
        ...initialValues,
        start_date:
          _start && moment(_start, format.datetime).format(format.datetime),
        duration_minutes: _duration,
      },
      isFetching: false,
    });

    if (event && event.id) {
      this.fetchEventData(event.id);
    }
  }

  componentWillUnmount() {
    this.setState({
      initialValues: {},
      isFetching: true,
    });
  }

  async fetchEventData(id) {
    const { data: event } = await calendarsService.getOne(id, {
      include: 'people',
    });

    this.setState(
      {
        initialValues: event,
        isFetching: false,
      },
      () => this.getDuration(event.duration_minutes)
    );
  }

  onSubmit = (values) => {
    if (values.id) return calendarsService.update(values);
    return calendarsService.create(values);
  };

  openMySelf = () => {
    const { openModalEvent, start, event, values } = this.props;

    openModalEvent({
      initialValues: {
        ...values,
      },
      event,
      start,
    });
  };

  handleClickRemove = async () => {
    const { onSubmitSuccess } = this.props;
    const { id } = this.props.event;
    this.setState({ isFetching: true });
    await calendarsService.remove(id);
    this.props.handleClose();
    if (onSubmitSuccess) onSubmitSuccess();
  };

  onSubmitSuccess = (...args) => {
    const { onSubmitSuccess } = this.props;
    this.props.handleClose();
    if (onSubmitSuccess) onSubmitSuccess(...args);
  };

  onSubmitFail = (...args) => {
    const { onSubmitFail } = this.props;
    if (onSubmitFail) onSubmitFail(...args);
  };

  render() {
    const { peopleId, modalConfig, isOpen, handleClose, modalType, event } =
      this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title="Agendar atividade"
          titleActions={
            <>
              <SyncGoogleCalendar
                render={({ onClick }) => (
                  <span onClick={onClick} className="h-link">
                    Sincronizar com o Google
                  </span>
                )}
              />
              {peopleId && (
                <Button
                  color="white"
                  onClick={() => {
                    this.props.openModalPerson(
                      { id: peopleId },
                      {
                        afterClose: () => {
                          this.openMySelf();
                        },
                      }
                    );
                  }}
                >
                  Ver vida da pessoa
                </Button>
              )}
            </>
          }
          handleClose={handleClose}
          isLoading={this.state.isFetching}
        >
          <Form
            event={event}
            handleClickRemove={this.handleClickRemove}
            handleClose={handleClose}
            initialValues={this.state.initialValues}
            durationOptions={this.state.duration_options}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
            onSubmitFail={this.onSubmitFail}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

const selector = formValueSelector('ModalEventForm');

const mapStateToProps = (state) => ({
  peopleId: selector(state, 'people_id'),
  values: getFormValues('ModalEventForm')(state),
});

const mapDispatchToProps = {
  openModalPerson,
  openModalEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEvent);
