import React from 'react';
import NoContent from 'components/NoContent';
import iconChat from './assets/icon-chat.svg';
import Button from 'components/Button';

// import { Wrapper } from './styles';

function Nodatacustom({ onClick }) {
  return (
    <NoContent
      size="323px"
      type="compact"
      image={iconChat}
      title="WhatsApp"
      text="Ative e configure o botão flutuante do Whatsapp em seu site. Com ele você facilita o contato e a agilidade na comunicação, aumentando a chance de o lead se tornar um cliente."
    >
      <Button color="success" onClick={onClick}>
        Ativar botão flutuante
      </Button>
    </NoContent>
  );
}

export default Nodatacustom;
