import styled from 'styled-components';
import Image from 'components/Image';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 5px;
  cursor: pointer;
  user-select: none;

  :first-child {
    padding-top: 0;
  }

  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const Img = styled(Image)`
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 5px;
`;
