import React from 'react';
import { Actions, BoxLink, BoxTable, Content, Title, Wrapper } from './styles';

function Box({ as, title, actions, children, className }) {
  return (
    <Wrapper as={as} className={className}>
      <header>
        <Title>{title}</Title>
        {actions && <Actions>{actions()}</Actions>}
      </header>
      <Content>{children}</Content>
    </Wrapper>
  );
}

export { BoxLink, BoxTable };

export default Box;
