import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Components
import ItemDropdown from '../../ItemDropdown';
import CardUser from './CardUser';
import Avatar from 'components/Avatar';
import TextOverflow from 'components/TextOverflow';
// Containers
import Can from 'containers/Can';
// Modules
import { changeUser, currentUserSelector } from 'modules/login';
// Constants
import { getName } from 'lib/text';
import { ORIGINAL } from 'lib/HTTP';

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    padding: '0 5px',
  },
  client: { fontSize: '12px', lineHeight: '14px', opacity: '.5' },
};

class MenuUser extends React.Component {
  static defaultProps = {
    handleChangeUser: () => {},
  };

  static propTypes = {
    handleChangeUser: PropTypes.func,
  };

  state = {
    originalUser: {},
    data: [],
    isFetching: false,
  };

  async componentDidMount() {
    const { currentUser } = this.props;
    const originalUser = localStorage.getItem('original-user');

    const isRealEstateBlocked = _.get(currentUser, 'real_estate.blocked');

    // Só busca os usuários se tiver permissão
    if (
      !isRealEstateBlocked &&
      originalUser &&
      originalUser.permissions.includes('CHANGE_CURRENT_USER')
    ) {
      this.fetchUsers();
      this.setState({ originalUser });
    }
  }

  request = () => this.setState({ isFetching: true });
  receive = (data) => this.setState({ data, isFetching: false });

  fetchUsers = async () => {
    this.request();
    const { data } = await ORIGINAL.get('users', {
      params: {
        sort: 'name',
      },
    });
    this.receive(data);
  };

  changeUser = (userId) => () => {
    const { changeUser } = this.props;
    changeUser(userId);
  };

  get hasUsers() {
    const { data } = this.state;
    return data && data.length > 0;
  }

  get renderUsers() {
    const { data } = this.state;

    return data.map((user) => (
      <CardUser key={user.id} user={user} onClick={this.changeUser(user.id)} />
    ));
  }

  render() {
    const { user } = this.props;
    const { originalUser } = this.state;

    return (
      <Can
        run="CHANGE_CURRENT_USER"
        permissions={originalUser.permissions}
        no={() => (
          <div className="MainHeader__user" style={styles.wrapper}>
            <Avatar
              hasZoom={false}
              text={user.name}
              image={user.file_url}
              color="dark-light"
              size="medium"
              style={{ marginRight: 10 }}
            />
            <span
              className="h-flex"
              style={{ flexDirection: 'column', textAlign: 'left' }}
            >
              <TextOverflow width={150} style={{ lineHeight: '14px' }}>
                {getName(user.name)}
              </TextOverflow>
              <span style={{ lineHeight: '14px' }} />
              <small style={styles.client}>
                Cliente ID: N{_.get(user, 'real_estate.serial')}
              </small>
            </span>
          </div>
        )}
        yes={() => {
          return (
            <ItemDropdown
              position="right"
              href="#"
              dropdownClassName=""
              className="Menu__item Menu__item--small"
              maxHeight="calc(90vh - 60px)"
              label={
                <div className="MainHeader__user" style={styles.wrapper}>
                  <Avatar
                    hasZoom={false}
                    text={user.name}
                    image={user.file_url}
                    color="dark-light"
                    size="medium"
                    style={{ marginRight: 10 }}
                  />
                  <span
                    className="h-flex"
                    style={{ flexDirection: 'column', textAlign: 'left' }}
                  >
                    <TextOverflow width={122} style={{ lineHeight: '14px' }}>
                      {getName(user.name)}
                    </TextOverflow>
                    <span style={{ lineHeight: '14px' }} />
                    <small style={styles.client}>
                      Cliente ID: N{_.get(user, 'real_estate.serial')}
                    </small>
                  </span>
                </div>
              }
            >
              {({ close }) =>
                this.hasUsers && (
                  <>
                    <li
                      style={{
                        padding: '10px 15px 5px',
                        background: '#fff',
                        position: 'sticky',
                        top: '0',
                        zIndex: '99',
                      }}
                    >
                      <p className="h-color--primary">Trocar por:</p>
                    </li>
                    {this.renderUsers}
                  </>
                )
              }
            </ItemDropdown>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: currentUserSelector(state),
});

const mapDispatchToProps = {
  changeUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuUser);
