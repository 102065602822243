import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Shapes
import { PROPERTY_CONDOMINIUM_SHAPE } from 'constants/shapes';
// Components
import { Overlay } from 'components/Card';
import Image from 'components/Image';
// Image
import noImage from 'assets/img/no-image.png';
import { TYPE_NETWORK } from 'constants/options';
import Label from 'components/Label';

const Title = ({ children }) => (
  <h4 className="h-color--white h-margin-bottom--5 h-text-overflow">
    {children}
  </h4>
);

const Text = ({ children }) => (
  <p
    style={{ width: '100%' }}
    className="h-color--white-light h-margin-bottom--5 h-text-overflow"
  >
    {children}
  </p>
);

class CardHorizontal extends React.Component {
  static defaultProps = {
    className: null
  };

  static propTypes = {
    className: PropTypes.string,
    property: PROPERTY_CONDOMINIUM_SHAPE.isRequired,
    handleClickProperty: PropTypes.func.isRequired
  };

  get renderRealEstate() {
    const { property } = this.props;
    if (!property.is_on_network || !property.network_type) return null;

    return (
      <Overlay.row position="top">
        <div className="h-flex__cell--grow" />
        <Overlay.item>
          <Label
            data-tip={`De: ${_.get(property, 'real_estate.name')}`}
            data-effect="solid"
            color={TYPE_NETWORK[property.network_type].color}
            size="small"
          >
            {property.network_type}
          </Label>
        </Overlay.item>
      </Overlay.row>
    );
  }

  get renderOverlay() {
    const { property } = this.props;

    return (
      <>
        <div className="CardCondominium__overlay" />
        <Overlay>
          {this.renderRealEstate}
          <Overlay.row position="bottom">
            <Overlay.item xs>
              <Title>{_.get(property, 'condominium.title')}</Title>
              <Text>{property.address_formatted}</Text>
              {/*<Price>{_.get(property, "condominium.price_range")}</Price>*/}
            </Overlay.item>
          </Overlay.row>
        </Overlay>
      </>
    );
  }

  render() {
    const { property, handleClickProperty, type, ...rest } = this.props;

    return (
      <div
        {...rest}
        onClick={handleClickProperty}
        className={`CardCondominium CardCondominium--${type}`}
      >
        <div className="CardCondominium__figure">
          <Image
            size="medium"
            type="outside"
            src={property.cover_image ? property.cover_image.file_url : noImage}
            alt={property.titleFormated}
            className="CardCondominium__img"
          />
          {this.renderOverlay}
        </div>
      </div>
    );
  }
}

export default CardHorizontal;
