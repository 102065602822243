import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Nodata from 'components/Nodata';
import Container from 'components/Container';
import Loading from 'components/Loading';

function TableData({ isFetching, data, onDelete, onEdit }) {
  if (isFetching) {
    return (
      <Container>
        <Loading isVisible isBlock />
      </Container>
    );
  }

  if (!data || data.length <= 0) {
    return (
      <Container>
        <Nodata
          image={null}
          title="Nenhuma origem de captação encontrada"
          text="Quando cadastrar uma origem de captação, ela aparecerá aqui."
        />
      </Container>
    );
  }

  return (
    <>
      <Loading isVisible={isFetching} isFullScreen />
      <table className="Table">
        <thead>
          <tr>
            <th>Nome</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((reception) => (
              <tr key={reception.id} className="h-hover">
                <td>{reception.name}</td>
                <td className="Table__cell--small">
                  <div className="h-hover__show">
                    <Button
                      color="white"
                      colorText="secondary"
                      onClick={onEdit(reception)}
                    >
                      Editar
                    </Button>
                    <Button color="danger" onClick={onDelete(reception)}>
                      Remover
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}

TableData.defaultProps = {
  data: null,
  onDelete: null,
  onEdit: null,
};

TableData.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default TableData;
