// Modules
import createDataReducer from 'modules/data';
// Services
import * as slidesService from 'services/sites/slides';

export const {
  selectors: slidesSelectors,
  actionCreators: slidesActions,
  reducer
} = createDataReducer('components/slides');

/**
 * Busca todos os modelos de cabeçalhos
 * @param params
 * @return {Promise}
 */
export const fetchSlides = params => dispatch => {
  dispatch(slidesActions.request());

  return slidesService.getComponents(params).then(res => {
    dispatch(slidesActions.receive(res.data));
    return res;
  });
};

export default reducer;
