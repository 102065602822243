import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// Modules
import {
  activeDomains as activeSelector,
  checkConnection,
  deleteDomain,
  fetchDomains,
  notActiveDomains as notActiveSelector,
  setPrimaryDomain,
} from 'modules/domains';

import { openConfirmation } from 'containers/ModalConfirmation/module';
// Components
import Container from 'components/Container';
import DomainsTable from '../../components/DomainsTable';
import ListConnectDomains from '../../components/ListConnectDomains';
import { checkDomainHasMailboxes } from 'services/settings/domains';
import { hideLoading, showLoading } from 'modules/loading';
import Loading from 'components/Loading';

function ListDomains({ history }) {
  const dispatch = useDispatch();
  const [isFetchingDns, setIsFetchingDns] = useState(false);

  const { activeDomains, notActiveDomains } = useSelector(
    (state) => ({
      activeDomains: activeSelector(state),
      notActiveDomains: notActiveSelector(state),
    }),
    shallowEqual
  );

  const checkDNS = (domain) => async () => {
    setIsFetchingDns(true);
    await checkConnection(domain)(dispatch);
    setIsFetchingDns(false);
  };

  const handleMainDomain = (domainId) => () => {
    setPrimaryDomain(domainId)(dispatch);
  };

  const handleDeleteDomain = (domain) => async () => {
    dispatch(showLoading());
    const hasMailboxes = await checkDomainHasMailboxes(domain.id);
    dispatch(hideLoading());

    dispatch(
      openConfirmation({
        title: 'Excluir domínio',
        text: (
          <>
            {hasMailboxes && (
              <div className="h-color--primary">
                As contas de e-mail relacionadas a esse domínio também serão
                excluídas.
              </div>
            )}
            <div className="h-color--primary">
              <strong>Você tem certeza que deseja excluir?</strong>
            </div>
          </>
        ),
        request: () => deleteDomain(domain)(dispatch),
        onFinally: () => {
          fetchDomains()(dispatch).then((data) => {
            if (data.length === 0) {
              history.push('/site/config/domains/pre-register');
            }
          });
        },
      })
    );
  };

  useEffect(() => {
    fetchDomains()(dispatch).then((data) => {
      if (data.length === 0) {
        history.push('/site/config/domains/pre-register');
      }
    });
  }, []);

  return (
    <>
      <Loading isVisible={isFetchingDns} isFullScreen />

      <ListConnectDomains
        domains={notActiveDomains}
        onClickRemove={handleDeleteDomain}
        onClickCheckDNS={checkDNS}
      />
      <Container padding>
        <DomainsTable
          domains={activeDomains}
          onClickCheckDNS={checkDNS}
          handleDeleteDomain={handleDeleteDomain}
          handleEditDomain={() => {}}
          handleMainDomain={handleMainDomain}
        />
      </Container>
    </>
  );
}

ListDomains.defaultProps = {
  domains: null,
  onClickCheckDomain: null,
};

ListDomains.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.object),
  onClickCheckDomain: PropTypes.func,
};

export default ListDomains;
