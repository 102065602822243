import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import Loading from 'components/Loading';
import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
// HOC
import { withDomains } from 'HOC/withDomains';

class MiddlewareDomains extends React.Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    text: PropTypes.node
  };

  static defaultProps = {
    handleClose: null,
    text:
      'Para criar uma caixa, é necessário que você tenha adicionado no mínimo um domínio. Vamos fazer isso agora?'
  };

  componentDidMount() {
    this.props.fetchDomains();
  }

  handleAddDomain = () => {
    // Fecha a modal
    this.props.handleClose();

    // Redireciona para a página de adicionar domínio
    this.props.history.push('/site/config/domains/show');
  };

  render() {
    if (this.props.domains.isFetching) return <Loading isVisible isBlock />;

    if (this.props.domains.data.length === 0) {
      return (
        <>
          {this.props.text}
          <ModalFooter>
            <Button onClick={this.props.handleClose}>Cancelar</Button>
            <span className="h-flex__cell--grow" />
            <Button onClick={this.handleAddDomain}>Adicionar Domínio</Button>
          </ModalFooter>
        </>
      );
    }

    return this.props.children(this.props.domains);
  }
}

export default compose(withRouter, withDomains)(MiddlewareDomains);
