import React, { Component, Fragment } from 'react';
import { TableRow } from '../Record';
import Block from '../../Block';
// Containers
import Can from 'containers/Can';
import { PROPERTY_PERMISSIONS } from 'constants/rules';

const propTypes = {};
const defaultProps = {};

class PrivateData extends Component {
  state = {
    isVisible: false,
  };

  handleChangeVisibility = () => {
    this.setState({
      isVisible: !this.state.isVisible,
    });
  };

  get hasExclusivity() {
    const { data } = this.props;

    if (!data.exclusive_until) {
      return 'Não';
    }

    return 'Sim até ' + data.exclusive_until;
  }

  render() {
    const { isVisible } = this.state;
    const { data, openModalPerson } = this.props;

    return (
      <Can
        run={PROPERTY_PERMISSIONS.PRIVATE}
        permissions={data.permissions}
        yes={() => (
          <div className="Block">
            <h5 className="Block__title">
              Dados Privados (
              <button
                onClick={this.handleChangeVisibility}
                className="h-color--secondary"
              >
                {isVisible ? 'Esconder' : 'Mostrar'}
              </button>
              )
            </h5>
            {isVisible && (
              <Fragment>
                <Block
                  isHtml
                  type="text"
                  title="Observações privadas"
                  text={data.private_note}
                  style={{ marginTop: 15, marginBottom: 15 }}
                />
                <Block
                  isHtml
                  type="text"
                  title="Observação de ocupação"
                  text={data.occupation_note}
                  style={{ marginTop: 15, marginBottom: 15 }}
                />
                <Block
                  isHtml
                  type="text"
                  title="Descrição da mobília"
                  text={data.furniture_note}
                  style={{ marginTop: 15, marginBottom: 15 }}
                />
                <Block
                  type="text"
                  title="Onde pegar a chave"
                  text={data.keys_location}
                  style={{ marginTop: 15, marginBottom: 15 }}
                />
                <Block
                  isHtml
                  type="text"
                  title="Comissão combinada"
                  text={data.negotiation_note}
                  style={{ marginTop: 15, marginBottom: 15 }}
                />
                <table className="table--info">
                  <tbody>
                    <TableRow
                      title="Imposto Territorial Rural"
                      value={data.itr}
                    />
                    <TableRow
                      title="Aceita Minha Casa Minha Vida?"
                      value={data.is_financeable_mcmv ? 'Sim' : 'Não'}
                    />
                    <TableRow
                      title="Autorização Formalizada"
                      value={data.has_owner_authorization ? 'Sim' : 'Não'}
                    />
                    <TableRow
                      title="Averbada"
                      value={data.is_property_titled ? 'Sim' : 'Não'}
                    />
                    <TableRow
                      title="Chave disponível"
                      value={data.is_keys_ready ? 'Sim' : 'Não'}
                    />
                    <TableRow
                      title="Com Placa"
                      value={data.has_sale_card ? 'Sim' : 'Não'}
                    />
                    <TableRow
                      title="Comissão combinada?"
                      value={data.negotiation_note ? 'Sim' : 'Não'}
                    />
                    <TableRow
                      title="Escriturada"
                      value={data.is_deeded ? 'Sim' : 'Não'}
                    />
                    <TableRow title="Matricula" value={data.matriculation} />
                    <TableRow
                      title="Mostrar Condomínio no site?"
                      value={data.is_condominium_shown ? 'Sim' : 'Não'}
                    />
                    <TableRow
                      title="Mostrar no site?"
                      value={data.is_published ? 'Sim' : 'Não'}
                    />
                    <TableRow
                      title="Imóvel com exclusividade"
                      value={this.hasExclusivity}
                    />
                    <tr className="table__row">
                      <td className="table__cell h5">Proprietário</td>
                      <td className="table__cell h-color--primary-light">
                        <button
                          onClick={() => {
                            openModalPerson(
                              {
                                ...data.people,
                                afterClose: () => {
                                  this.props.handleOpenMyself();
                                },
                              },
                              { initialPage: 'Infos' }
                            );
                          }}
                          className="h-link"
                        >
                          {data.people.name}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        )}
        no={() => null}
      />
    );
  }
}

PrivateData.propTypes = propTypes;
PrivateData.defaultProps = defaultProps;

export default PrivateData;
