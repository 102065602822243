import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;

  svg {
    color: #0084f4;
    vertical-align: middle;
    margin-right: 5px;
  }

  li {
    padding: 4px 0;
  }
`;
