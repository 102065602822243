import styled, { css } from 'styled-components';
import Button from 'components/Button';
import { rgba } from 'polished';

const Btn = styled.button`
  cursor: pointer;
`;

export const Delete = styled(Btn)`
  font-size: 18px;
  margin-right: -20px;
  margin-left: 5px;
  color: ${p => p.theme.colors.danger};
  opacity: 0;
  transition: all 0.2s;
`;

export const Icon = styled.div`
  width: 45px;
  height: 45px;
  border-radius: ${p => p.theme.borderRadius};
  display: grid;
  place-items: center;
  color: ${p => p?.theme?.color || '#000'};
  background-color: ${p => rgba(p?.theme?.color || '#000', 0.3)};

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  cursor: pointer;

  ${p =>
    !p.theme.isNew &&
    css`
      ${Icon},
      h5,
      p {
        opacity: 0.5;
      }
    `}

  :hover {
    background-color: rgba(0, 0, 0, 0.05);

    ${Delete} {
      margin-right: 0;
      opacity: 1;
    }
  }
`;

export const Container = styled.div`
  flex-grow: 1;

  h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
  }
  p {
    color: ${p => p.theme.colors.primary};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-shrink: 0;
  min-width: 0;
`;

export const Action = styled(Button)`
  padding: 5px;
  height: 25px;
  line-height: 14px;
  font-size: 14px;
`;
