import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Components
import GroupControl, { GroupItem } from 'components/GroupControl';
import { Checkbox, Input } from 'components/Form';
import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import Alert from 'components/Alert';
import { MdHelp } from 'react-icons/md';
import Tooltip from 'react-tooltip';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validateQuantity = (value) => {
  if (!value) return undefined;
  return value.quantity === '' ? 'Por favor insira uma quantidade' : undefined;
};

const renderOption = (option, key) => (
  <Col key={option.id} xs={6}>
    <GroupControl label={option.name} required={false}>
      <GroupItem>
        <Field
          key={option.id}
          name={`options.${option.id}`}
          format={(value) => {
            if (!value) return '';
            return value.quantity;
          }}
          parse={(value) => {
            const hasCharacter = /\D/.test(value);
            if (!value || hasCharacter)
              return { portal_option_id: option.id, quantity: '' };

            return {
              portal_option_id: option.id,
              quantity: parseInt(value, 10),
            };
          }}
          validate={validateQuantity}
          component={Input}
        />
      </GroupItem>
      <GroupItem label="Anúncio(s)" size="small" />
    </GroupControl>
  </Col>
);

const renderOptions = (options) =>
  options && <Row>{options.map(renderOption)}</Row>;

export const TipSendAll = () => {
  return (
    <MdHelp data-tip="Selecionando esta caixa, todos os imóveis que estiverem publicados e disponíveis para serem exibidos no seu site, serão enviados automaticamente para este portal. Ainda será possível, na edição do imóvel remover este imóvel do envio." />
  );
};

export const TipCondoInTitle = () => {
  return (
    <MdHelp
      data-tip={`Dado que a maioria dos portais não permite o envio do nome do condomínio em um campo específico, ao selecionar esta opção, o nome do condomínio será automaticamente adicionado ao título do seu imóvel ao ser enviado para o portal. Assim, quando um cliente buscar pelo nome do condomínio do imóvel, ele será exibido no resultado de pesquisa. Observação: o nome do condomínio só será mostrado para os imóveis que tiverem a opção "mostrar nome do condomínio no site" ativada no cadastro do imóvel.`}
    />
  );
};

const FormPortal = ({ options, handleSubmit, handleClose }) => {
  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Alert color="danger" className="h-margin-bottom--15">
        É necessário ter uma conta ativa contratada para conectar ao portal.
      </Alert>
      <p className="h-margin-bottom--15">
        Informe o limite de publicações que você tem em cada categoria:
      </p>
      {renderOptions(options)}
      <Field
        name="client_code"
        label="Seu código ou e-mail de cliente"
        component={Input}
      />
      <Field
        type="checkbox"
        label={
          <>
            Enviar automaticamente todos seus imóveis publicados <TipSendAll />
          </>
        }
        name="send_all"
        component={Checkbox}
      />
      <Field
        type="checkbox"
        label={
          <>
            Adicionar nome do condomínio ao título do imóvel <TipCondoInTitle />
          </>
        }
        name="condo_in_title"
        component={Checkbox}
      />
      <ModalFooter>
        <Button onClick={handleClose} color="white" colorText="secondary">
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
};

FormPortal.defaultProps = defaultProps;
FormPortal.propTypes = propTypes;

export default reduxForm({
  form: 'FormPortal',
  enableReinitialize: true,
})(FormPortal);
