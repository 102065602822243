import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  padding: 0 20px 10px;
  margin-top: 20px;
  border-radius: 4px;
`;
export const Inner = styled.div``;
export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-top: 20px;
  margin-bottom: 10px;
`;
export const Content = styled.div``;

export const Table = styled.table`
  width: calc(100% + 40px);
  margin-left: -20px;
  text-align: left;
  border-spacing: 0;

  thead {
    th {
      padding: 10px 20px;
      color: #8e8e8e;
      font-weight: 400;
    }
  }
`;

export const TrMain = styled.tr`
  td {
    padding: 15px 20px;
  }

  td {
    ${(p) =>
      !p.isExpanded &&
      css`
        border-bottom: 1px solid #f1f4f9;
      `}
  }
`;

export const TrItem = styled.tr`
  td {
    color: #8e8e8e;
    padding: 5px 20px;
  }

  + ${TrMain} td {
    //padding-top: 20px;
  }
`;
