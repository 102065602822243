import React from 'react';
import ReactDOM from 'react-dom';
import { Motion, spring } from 'react-motion';
import { Col, Row } from 'react-flexbox-grid';
import Icon from '../../../Icon';

const Pop = ({
  style,
  input,
  title,
  options,
  cols,
  handleClose,
  valueKey,
  labelKey,
  renderCheckbox
}) => (
  <div className="ListFilter" style={style}>
    <button onClick={handleClose} className="ListFilter__close">
      <Icon name="close-circle" />
    </button>
    <header className="ListFilter__header">
      <h5>{title}</h5>
    </header>
    <div className="ListFilter__container">
      <Row>
        {options.map((option, index) => (
          <Col {...cols} key={`checkbox-${option[valueKey]}`}>
            {renderCheckbox(input, labelKey, valueKey)(option, index)}
          </Col>
        ))}
      </Row>
    </div>
  </div>
);

class PopoverCheckbox extends React.Component {
  constructor() {
    super();

    this.pop = null;

    this.state = {
      isOpen: false,
      style: {}
    };

    this.body = document.querySelector('body');

    this.handleToggle = this.handleToggle.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose(e) {
    e.stopPropagation();
    this.setState({ isOpen: false });
  }

  handleToggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    let stylePop = {};

    const $el = this.props.containerRef.current;

    if ($el) {
      const { x, width, y } = $el.getBoundingClientRect();

      const left = x + width + 21;
      const top = window.pageYOffset + y + -35;
      stylePop = { left, top };
    }

    return (
      <div>
        <button
          type="button"
          className="h-margin-top--5 h-color--secondary h-text-underline"
          onClick={this.handleToggle}
        >
          Ver mais
        </button>

        {this.state.isOpen &&
          ReactDOM.createPortal(
            <React.Fragment>
              <div className="ListFilter__overlay" onClick={this.handleClose} />
              <Motion
                defaultStyle={{ opacity: 0 }}
                style={{ opacity: spring(1) }}
              >
                {interpolatingStyle => (
                  <Pop
                    title="Adicionar ao filtro"
                    cols={{
                      xs: 12,
                      sm: 6,
                      md: 4
                    }}
                    {...this.props}
                    style={{
                      ...interpolatingStyle,
                      ...stylePop
                    }}
                    handleClose={this.handleClose}
                  />
                )}
              </Motion>
            </React.Fragment>,
            document.querySelector('#popper')
          )}
      </div>
    );
  }
}

export default PopoverCheckbox;
