import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
// Components
import { SelectMultiple, Select2 } from 'components/Form/index';
// Service
import * as locationService from 'services/location';
// Modules
import { Actions, Selectors } from '../module';
// Helpers
import { parseSelect } from 'lib/formHelpers';
// HOC
import { withFormName } from 'HOC/withFormName';

class NeighborhoodField extends React.Component {
  static propTypes = {
    cityId: PropTypes.string,
    canUnregister: PropTypes.bool,

    multi: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelKey: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    params: PropTypes.object,

    normalize: PropTypes.func,
  };

  static defaultProps = {
    cityId: null,
    canUnregister: true,

    multi: false,
    formName: null,
    label: 'Bairros',
    name: 'neighborhood_id',
    component: SelectMultiple,
    valueKey: 'id',
    labelKey: 'name',
    params: {},

    normalize: parseSelect(),
  };

  constructor(props) {
    super(props);
    props.register(props.formName, props.name);
  }

  request = () => this.props.request(this.props.formName, this.props.name);
  receive = (data) =>
    this.props.receive(this.props.formName, this.props.name, data);

  /**
   * Busca os países
   * @param cityId
   * @return {Promise}
   */
  fetchNeighborhoods = async (cityId) => {
    if (!cityId) return;
    const { params } = this.props;
    this.request();
    const { data } = await locationService.getNeighborhoods({
      city_id: cityId,
      ...params,
    });
    this.receive(data);
  };

  componentWillUnmount() {
    if (this.props.canUnregister) {
      this.props.unregister(this.props.formName, this.props.name);
    }
  }

  componentDidUpdate(prevProps) {
    const { cityId: currentId } = this.props;
    const { cityId: prevId } = prevProps;

    if (currentId !== null && currentId !== prevId) {
      this.fetchNeighborhoods(currentId);
    }
  }

  render() {
    const { multi, options, onChange, onBlur } = this.props;

    if (multi) {
      return (
        <Field
          xs={this.props.xs}
          label={this.props.label}
          disabled={options && !options.length}
          options={options}
          filter
          name={this.props.name}
          selectedItemsLabel="bairros"
          component={Select2}
          labelKey="name"
          valueKey="id"
          placeholder={this.props.placeholder}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
    }

    return (
      <Field
        disabled={options && !options.length}
        options={options}
        {...this.props}
      />
    );
  }

  componentDidMount() {
    if (this.props.cityId) {
      this.fetchNeighborhoods(this.props.cityId);
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  options: Selectors.getData(ownProps.formName, ownProps.name)(state),
  isLoading: Selectors.getIsFetching(ownProps.formName, ownProps.name)(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(Actions, dispatch);

export default compose(
  withFormName,
  connect(mapStateToProps, mapDispatchToProps)
)(NeighborhoodField);
