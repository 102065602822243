import api from './index';

/**
 * Retorna os ids da localização
 * @param cep
 * @param neighborhood_id
 * @param street_address
 * @param address
 */
export const getLocation = ({ cep, neighborhood_id, street_name, address }) => {
  let params = null;

  if (address) params = { address, filter: { has_geolocation: true } };
  if (neighborhood_id)
    params = { ...params, filter: { ...params?.filter, neighborhood_id } };
  if (street_name)
    params = { ...params, filter: { ...params?.filter, street_name } };

  return api.getOne('ceps', cep, params);
};
