import React from 'react';
import { BarItemSelecteds } from 'pages/PropertiesSearch/components/FixedBarProperties/styles';
import { MdCheckCircle } from 'react-icons/md';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import { plural } from 'lib/text';
// import { Wrapper } from './styles';

function SelectedProperties() {
  const { selectedProperties } = usePortalOptions();

  return (
    <BarItemSelecteds>
      <span>
        <MdCheckCircle />{' '}
        {plural(
          selectedProperties?.length,
          'imóvel selecionado',
          'imóveis selecionados'
        )}
      </span>
    </BarItemSelecteds>
  );
}

export default SelectedProperties;
