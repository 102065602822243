import { useEffect, useState } from 'react';

export const useStorage = ({ key }) => {
  const [value, setValue] = useState();

  const set = (value) => {
    localStorage.setItem(key, value);
    setValue(value);
  };

  useEffect(() => {
    const currentValue = localStorage.getItem(key);

    if (currentValue) {
      setValue(localStorage.getItem(key));
    }
  }, []);

  return { value, set };
};
