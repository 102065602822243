import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { SelectMultiple } from 'components/Form';
import { useCategory } from 'components/NotesForm/hooks/useCategory';
import { parseSelect } from 'lib/formHelpers';
import useFormValue from 'hooks/useFormValue';
import useReduxForm from 'hooks/useReduxForm';

// import { Wrapper } from './styles';

function CategoryField() {
  const { dispatch, change } = useReduxForm();
  const categoryId = useFormValue('category_id');
  const { data } = useCategory();

  useEffect(() => {
    if (categoryId === undefined && data.length > 0) {
      dispatch(change('category_id', data?.[0]?.id));
    }
  }, [data, categoryId]);

  return (
    <Field
      clearable={false}
      multi={false}
      label="Marcar anotação como:"
      name="category_id"
      options={data}
      component={SelectMultiple}
      labelKey="name"
      valueKey="id"
      parse={parseSelect('id')}
    />
  );
}

export default CategoryField;
