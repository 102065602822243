import React, { useEffect, useMemo, useState } from 'react';
import * as S from './styles';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import { ColorPicker, Input, Select, Textarea } from 'components/Form';
import {
  PAGES,
  useModal,
} from 'containers/ModalRoot/ModalGeneratePost/context';
import PreviewPost from 'components/PreviewPost';
import useFormValue from 'hooks/useFormValue';
import * as AllModels from 'components/ModelsPost';
import {
  characteristicsToDescription,
  transformValues,
} from 'containers/ModalRoot/ModalGeneratePost/pages/GeneratePost/helpers';
import { urlToObjectURL } from 'lib/file-helpers';
import { useLogos } from 'containers/ModalRoot/ModalGeneratePost/hooks/useLogos';
import Button from 'components/Button';

const FORMATS = [
  {
    label: 'Stories Facebook /Instagram',
    name: 'Stories',
    models: AllModels.Stories,
  },
  {
    label: 'Feed Facebook /Instagram',
    name: 'Facebook',
    models: AllModels.Facebook,
  },
  {
    label: 'Feed Twitter / Linkedin',
    name: 'Twitter',
    models: AllModels.Twitter,
  },
];

function GeneratePost({ handleSubmit, initialize }) {
  const { data: logos } = useLogos();
  const [format, setFormat] = useState('Stories');

  const {
    setModels,
    property,
    image,
    models,
    selectedModelIndex,
    handleNext,
    handlePrevious,
    setPage,
  } = useModal();

  const values = useFormValue([
    'title',
    'subtitle',
    'description',
    'backgroundColor',
    'mainColor',
    'image',
    'logo',
  ]);

  const _values = useMemo(() => {
    return transformValues(values, logos);
  }, [values, logos]);

  const handleClickFormat = (formatName, models) => () => {
    setFormat(formatName);
    setModels(models);
  };

  async function initializeForm() {
    initialize({
      logo: 'none',
      image: await urlToObjectURL(image?.file_url?.large),
      title: property?.title_formatted,
      subtitle: property?.price + ' ' + property?.transactionName,
      description: characteristicsToDescription(property),
      backgroundColor: '#ffffff',
      mainColor: '#000000',
    });
  }

  const isActiveFormat = (formatName) => {
    return formatName === format;
  };

  useEffect(() => {
    initializeForm();
  }, [property, image]);

  return (
    <S.Wrapper onSubmit={handleSubmit}>
      <S.LeftSide>
        <Row>
          <Field
            xs={12}
            label="Título"
            name="title"
            component={Input}
            maxLength={80}
          />
          <Field
            xs={12}
            label="Subtítulo/Valor"
            name="subtitle"
            component={Input}
            maxLength={30}
          />
          <Field
            type="simple"
            xs={12}
            label="Texto Adicional/Observações"
            name="description"
            component={Textarea}
            maxLength={120}
          />
        </Row>
        <S.Row>
          <S.Label>Incluir Logo do</S.Label>
          <S.Option>
            <Field
              name="logo"
              options={[
                { label: 'Nenhuma', value: 'none' },
                { label: 'Topo', value: 'header' },
                { label: 'Rodapé', value: 'footer' },
              ]}
              component={Select}
              placeholder=""
            />
          </S.Option>
        </S.Row>
        <S.Row>
          <S.Label>Cor de fundo</S.Label>
          <S.Option>
            <div style={{ width: '120px' }}>
              <Field name="backgroundColor" component={ColorPicker} />
            </div>
          </S.Option>
        </S.Row>
        <S.Row>
          <S.Label>Cor principal</S.Label>
          <S.Option>
            <div style={{ width: '120px' }}>
              <Field name="mainColor" component={ColorPicker} />
            </div>
          </S.Option>
        </S.Row>

        <Button
          color="white"
          onClick={() => {
            setPage(PAGES.IMAGES_SELECT);
          }}
          style={{ marginTop: '105px' }}
        >
          Voltar
        </Button>
      </S.LeftSide>
      <S.RightSide>
        <S.Header>
          <h1>Selecione o formato</h1>
        </S.Header>
        <S.Buttons>
          {FORMATS.map((format) => (
            <S.Btn
              isActive={isActiveFormat(format.name)}
              onClick={handleClickFormat(format.name, format.models)}
            >
              {format.label}
            </S.Btn>
          ))}
        </S.Buttons>
        <S.Header>
          <h1>Selecione o modelo</h1>
          <S.Actions>
            <button onClick={handlePrevious}>Anterior</button>
            <button onClick={handleNext}>Próximo</button>
          </S.Actions>
        </S.Header>
        <PreviewPost
          component={models[selectedModelIndex]}
          componentProps={_values}
        />
      </S.RightSide>
    </S.Wrapper>
  );
}

export default reduxForm({
  form: 'GeneratePostForm',
  onSubmit: () => {},
})(GeneratePost);
