import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  strong {
    padding: 2px 5px;
    line-height: 21px;
    min-width: 40px;
    margin-right: 10px;
    background: #e0e5eb;
    display: inline-block;
  }

  span {
    font-weight: bold;
  }
`;
