import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Ball from '../Ball';
import { MdCancel } from 'react-icons/md';
import _ from 'lodash';
import Alert from 'react-s-alert';
// Component
import MaterialIcon from 'components/MaterialIcon';
// Modal
import * as modalActions from 'modules/modal';
import { readNotification, removeNotification } from 'modules/notifications';

// Helpers
import { getColorByTag } from './helpers';

import { HTTP } from 'lib/HTTP';
// Constants
import { NOTIFICATION_STATUS, NOTIFICATION_TYPES } from 'constants/constants';

import { Wrapper, Container, Icon, Actions, Action, Delete } from './styles';
import { ThemeProvider } from 'styled-components';

const Title = ({ children, read }) => (
  <h5>
    {children} {!read && <Ball animation={false} />}
  </h5>
);
const Text = ({ children }) => <p>{children}</p>;

class Notification extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      NOTIFICATION_TYPES.ALERT,
      NOTIFICATION_TYPES.CONFIRM,
      NOTIFICATION_TYPES.LINK,
      NOTIFICATION_TYPES.LINK_BLANK,
      NOTIFICATION_TYPES.MODAL
    ]).isRequired,
    status: PropTypes.oneOf([
      NOTIFICATION_STATUS.NEW,
      NOTIFICATION_STATUS.RECEIVED,
      NOTIFICATION_STATUS.READED
    ]).isRequired,
    payload: PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      message: PropTypes.string
    }).isRequired,

    // Event Handlers
    onClick: PropTypes.func
  };

  static defaultProps = {
    id: null,
    type: 'alert',
    status: NOTIFICATION_STATUS.NEW,
    payload: null,

    onClick: () => {}
  };

  get isNew() {
    return this.props.status === NOTIFICATION_STATUS.NEW;
  }

  get isReceived() {
    return this.props.status === NOTIFICATION_STATUS.RECEIVED;
  }

  get isReaded() {
    return this.props.status === NOTIFICATION_STATUS.READED;
  }

  get payload() {
    return this.props.payload;
  }

  get title() {
    return this.payload.title;
  }

  get text() {
    return this.payload.message;
  }

  get actions() {
    try {
      return this.payload.actions;
    } catch {
      return null;
    }
  }

  get canShowActions() {
    try {
      return !!this.actions && !this.isReaded;
    } catch {
      return null;
    }
  }

  get modal() {
    try {
      return this.payload.modal;
    } catch {
      return null;
    }
  }

  /**
   * Verifica se tem modal
   * @returns {boolean}
   */
  get hasModal() {
    try {
      const { type } = this.props;
      const { thunk, options } = this.modal;

      return type === NOTIFICATION_TYPES.MODAL && !!thunk && _.isArray(options);
    } catch {
      return false;
    }
  }

  /**
   * Verifica se é um link para abrir
   * @returns {boolean}
   */
  get isLink() {
    try {
      const { type } = this.props;
      return type === NOTIFICATION_TYPES.LINK;
    } catch {
      return false;
    }
  }

  /**
   * Verifica se é um link para abrir em uma nova aba
   * @returns {boolean}
   */
  get isLinkBlank() {
    try {
      const { type } = this.props;
      return type === NOTIFICATION_TYPES.LINK_BLANK;
    } catch {
      return false;
    }
  }

  get renderIcon() {
    const { icon } = this.payload;

    return (
      <Icon className="Notification__icon h-flex__cell h-flex__cell--shrink">
        <MaterialIcon name={icon} />
      </Icon>
    );
  }

  get renderActions() {
    return (
      <Actions>
        {this.canShowActions &&
          this.actions.map(({ text, color, config }, i) => (
            <Action
              key={i + '-action'}
              color="white"
              colorText={color}
              onClick={this.handleClickAction(config)}
            >
              {text}
            </Action>
          ))}
        <Delete onClick={this.handleClickDelete}>
          <MdCancel />
        </Delete>
      </Actions>
    );
  }

  handleClickDelete = e => {
    e.stopPropagation();

    // Remove a notificação
    this.props.removeNotification(this.props.id);
  };

  readNotification() {
    this.props.readNotification(this.props.id);
  }

  onClick = () => {
    const { onClick } = this.props;

    if (onClick && !this.actions) onClick();

    if (this.hasModal) {
      const { thunk, options } = this.modal;
      this.props[thunk](...options);
    }

    if (this.isLink) {
      const { link } = this.props.payload;
      this.props.history.push(link);
    }

    if (this.isLinkBlank) {
      const { link } = this.props.payload;
      window.open(link);
    }

    if (!this.actions) this.readNotification();

    return false;
  };

  /**
   * Lida com o evento de click de uma ação
   * @param config
   */
  handleClickAction = config => async () => {
    const { onClick } = this.props;
    try {
      await HTTP.request(config);
      if (onClick) onClick();
      this.readNotification();
    } catch {
      Alert.success('Problema ao executar essa ação');
    }
  };

  render() {
    return (
      <ThemeProvider
        theme={{
          isNew: this.isNew,
          color: getColorByTag(this.props.tag)
        }}
      >
        <Wrapper onClick={this.onClick}>
          {this.renderIcon}
          <Container>
            <Title read={!this.isNew}>{this.title}</Title>
            {this.text && <Text>{this.text}</Text>}
          </Container>
          {this.renderActions}
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...modalActions,
      removeNotification,
      readNotification
    },
    dispatch
  );

//export default connect(null, mapDispatchToProps)(Notification);
export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(Notification);
