import api from 'services';
import { responseMessage } from 'lib/service-helpers';

/**
 * Atualiza os dados do cartão
 * @param {string} number
 * @param {object} values
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const create = ({ number, ...values }) =>
  api.create('sales/credit-cards', {
    number: number && number.replace(/\s/g, ''),
    is_primary: true,
    ...values,
  });

export const defineAsPrimary = (id) =>
  api.patch(`sales/credit-cards/${id}/define-as-primary`);

export const remove = (id) =>
  api
    .delete('sales/credit-cards', id)
    .then(responseMessage('Cartão de crédito removido'));

/**
 * Pega os dados do cartão
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const getAll = () => api.getOne('sales/credit-cards', '');
