/**
 * Remove a mascara de dinheiro de uma string
 * @param string - String que vai ser retirada a mascara
 * @returns {number}
 */
export const removeCurrency = (string) => {
  if (!string) return 0;

  const sanitizedString = string.replace(/[^\d-]/g, '');
  const isNegative = sanitizedString.includes('-');
  const sanitizedNumber =
    parseFloat(sanitizedString.replace('-', '').replace(',', '.')) / 100;

  return isNegative ? -sanitizedNumber : sanitizedNumber;
};

/**
 * Adiciona a mascara de dinheiro no número
 * @param number - Número que vai ser colocado a mascara
 * @returns {*}
 */
export const addCurrency = (number) =>
  Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
    number
  );
