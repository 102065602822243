import React from 'react';
import Pagination from 'components/Pagination';
import TableSort from 'components/TableSort';
import Check from 'components/Check';
import useCheckbox from 'hooks/useCheckbox';
import TableCardProperty from 'components/TableCardProperty';
import TableCardPropertyDetails from 'components/TableCardPropertyDetails';
import {
  openModalProperty,
  openModalShare,
  openModalShareProperty,
} from 'modules/modal';
import { useDispatch } from 'react-redux';
import { MdCheckCircle, MdDelete, MdPerson, MdShare } from 'react-icons/md';
import * as peopleService from 'services/people';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import FixedBar from 'components/FixedBar';
import { BarItemSelecteds } from 'pages/Properties/components/FixedBarProperties/styles';
import { plural } from 'lib/text';
import Loading from 'components/Loading';
import { FaWhatsapp } from 'react-icons/fa';

function TableMatchedProperties({
  person,
  data,
  meta,
  isFetching,
  fetchData,
  afterCloseModalProperty,
}) {
  const {
    checkedItems,
    isChecked,
    isAllItemsChecked,
    handleToggle,
    handleToggleAll,
    clear,
  } = useCheckbox([]);

  const dispatch = useDispatch();

  const openProperty = (property) => () => {
    dispatch(
      openModalProperty({
        property,
        initialPage: 'Radar',
        afterClose: () => {
          if (afterCloseModalProperty) afterCloseModalProperty();
        },
      })
    );
  };

  /**
   * Descarta um imóvel
   * @param matchedId
   * @return {(function(*): void)|*}
   */
  const handleClickDiscart = (matched) => async (e) => {
    e.stopPropagation();
    dispatch(
      openConfirmation({
        title: `Descartar imóvel Ref: ${matched.property.reference}`,
        text: 'Se voce deseja descartar esse imóvel clique em Descartar',
        submitButtonText: 'Descartar',
        request: () => {
          return peopleService.discardMatched(person.id, matched.id);
        },
        onSuccess: () => {
          fetchData({ offset: 1 });
          clear();
        },
      })
    );
  };

  const handleDiscartAllSelecteds = () => {
    dispatch(
      openConfirmation({
        title: `Remover da lista ${checkedItems.length} imóveis`,
        text: 'Se voce deseja descartar todos os imóveis clique em Descartar',
        submitButtonText: 'Descartar',
        request: () => {
          return peopleService.discardMatchedes(person.id, checkedItems);
        },
        onSuccess: () => {
          fetchData({ offset: 1 });
          clear();
        },
      })
    );
  };

  return (
    <>
      <TableSort
        data={data}
        renderHeader={() => (
          <tr>
            <th align="left">
              <Check
                checked={isAllItemsChecked(data)}
                onClick={() => handleToggleAll(data)}
              />
            </th>
            <th align="left">Imóvel</th>
            <th align="left">Detalhes</th>
            <th align="left">Compartilhar</th>
            <th />
          </tr>
        )}
        renderItem={(matched) => (
          <tr key={matched?.id + '-matched'}>
            <td>
              <Check
                checked={isChecked(matched.id)}
                onClick={() => handleToggle(matched.id)}
              />
            </td>
            <td onClick={openProperty(matched.property)} className="h-pointer">
              <TableCardProperty property={matched.property} />
            </td>
            <td onClick={openProperty(matched.property)} className="h-pointer">
              <TableCardPropertyDetails property={matched.property} />
            </td>
            <td align="center">
              <a
                href="#"
                className="h-color--secondary"
                onClick={() => {
                  dispatch(
                    openModalShare({
                      person,
                      properties_id: [matched?.property?.id],
                    })
                  );
                }}
              >
                <FaWhatsapp color="#00B849" />
              </a>
            </td>
            <td>
              <button onClick={handleClickDiscart(matched)}>
                <MdDelete />
              </button>
            </td>
          </tr>
        )}
      />
      <Pagination
        pagination={meta?.pagination}
        onPageChange={({ selected }) => {
          fetchData({ offset: selected + 1 });
        }}
      />
      <FixedBar
        color="primary"
        noContainer
        isVisible={!!checkedItems.length > 0}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        style={{
          background: 'rgb(66, 88, 126)',
          zIndex: 1500,
          position: 'sticky',
          left: '-15px',
          marginLeft: '-20px',
          width: 'calc(100% + 40px)',
          marginBottom: '-20px',
        }}
      >
        <BarItemSelecteds>
          <span>
            <MdCheckCircle />{' '}
            {plural(
              checkedItems.length,
              'imóvel selecionado',
              'imóveis selecionados'
            )}
          </span>
        </BarItemSelecteds>
        <FixedBar.item>
          <FixedBar.link
            tagName="button"
            onClick={() => {
              const matcheds = checkedItems.map((matchedId) =>
                data.find((matched) => matched.id === matchedId)
              );

              const propertiesId = matcheds.map((matched) => {
                return matched.property_id;
              });

              dispatch(
                openModalShare({
                  person,
                  properties_id: propertiesId,
                })
              );

              // dispatch(
              //   openModalSendPeopleEmail({
              //     initialValues: {
              //       matcheds_id: checkedItems,
              //     },
              //     afterClose: () => {},
              //   })
              // );
            }}
          >
            <MdShare /> Compartilhar
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.item>
          <FixedBar.link
            tagName="button"
            onClick={() => {
              const matcheds = checkedItems.map((matchedId) =>
                data.find((matched) => matched.id === matchedId)
              );

              const propertiesId = matcheds.map((matched) => {
                return matched.property_id;
              });

              dispatch(
                openModalShareProperty({
                  title: 'Adicionar imóvel ao cliente',
                  properties: propertiesId,
                  type: 'add-person',
                  initialValues: {
                    person,
                  },
                })
              );
            }}
          >
            <MdPerson /> Adicionar ao CRM
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.item>
          <FixedBar.link tagName="button" onClick={handleDiscartAllSelecteds}>
            <MdDelete /> Remover da lista
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.spacer />
      </FixedBar>

      <Loading isVisible={isFetching} isFullComponent />
    </>
  );
}

export default TableMatchedProperties;
