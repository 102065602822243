import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { MdMail, MdShare } from 'react-icons/md';
import { TwitterShareButton } from 'react-share';
// Components
import Dropdown, { Item, List } from 'components/Dropdown';
import SharePropertyWhatsapp from 'containers/SharePropertyWhatsapp';
import { openModalShareProperty } from 'modules/modal';
import { useDispatch } from 'react-redux';
import Button from '../Button';
import Facebook from './components/Facebook';

const propTypes = {
  url: PropTypes.string.isRequired,
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  url: '',
  children: (
    <Button size="large" color="white" colorText="secondary">
      <MdShare className="h-color--secondary" /> Compartilhar
    </Button>
  ),
};

function ShareDropdown({
  text,
  url,
  children,
  property,
  propertyId,
  ...props
}) {
  const dispatch = useDispatch();

  const handleClickEmail = useCallback(() => {
    dispatch(
      openModalShareProperty({
        title: 'Compartilhar imóvel',
        type: 'email',
        properties: [propertyId],
      })
    );
  }, [dispatch, propertyId]);

  if (property.id === null || property.status === 'Excluído') return null;

  return (
    <Dropdown {...props}>
      {({ close }) => (
        <>
          {children}
          <List>
            <Item>
              <SharePropertyWhatsapp propertyId={propertyId} component="button">
                <FaWhatsapp /> WhatsApp
              </SharePropertyWhatsapp>
            </Item>
            <Facebook url={url} propertyId={propertyId} onClick={close} />
            <Item>
              <TwitterShareButton
                url={url}
                title={text}
                className="h-flex__cell"
                onClick={close}
              >
                <FaTwitter /> Twitter
              </TwitterShareButton>
            </Item>
            {propertyId && (
              <Item>
                <button onClick={handleClickEmail}>
                  <MdMail /> E-mail
                </button>
              </Item>
            )}
          </List>
        </>
      )}
    </Dropdown>
  );
}

ShareDropdown.propTypes = propTypes;
ShareDropdown.defaultProps = defaultProps;

export default ShareDropdown;
