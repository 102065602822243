import styled from 'styled-components';
import { darken, readableColor } from 'polished';

export const Wrapper = styled.div.attrs(() => ({
  className: 'CardStage'
}))`
  color: ${p => readableColor(darken(0.2, p?.color || '#000000'))};
  background-color: ${p => p?.color};

  .CardStage__text {
    color: ${p =>
      readableColor(
        darken(0.2, p?.color || '#000000'),
        'rgba(0,0,0,.6)',
        'rgba(255,255,255,.6)'
      )};
  }

  .CardStage__actions {
    color: ${p => readableColor(darken(0.2, p?.color || '#000000'))};
  }
`;
