// Widgets.js
import { createModule } from '../lib/reducer-helpers';
import * as user from '../services/user';

const createAction = createModule('realtors');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');

export const realtorsSelector = state => state.realtors.realtors;
export const isFetchingRealtorsSelector = state =>
  state.realtors.meta.isFetching;

// Initial State
const initialState = {
  realtors: [],
  meta: {
    isFetching: false
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isFetching: true
        }
      };
    }
    case RECEIVE: {
      return {
        ...state,
        realtors: action.realtors,
        meta: {
          ...state.meta,
          isFetching: false
        }
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function requestRealtors() {
  return { type: REQUEST };
}

export function receiveRealtors(realtors) {
  return { type: RECEIVE, realtors };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export function getRealtors(params) {
  return dispatch => {
    dispatch(requestRealtors());

    user.getRealtors(params).then(({ data }) => {
      dispatch(receiveRealtors(data));
    });
  };
}
