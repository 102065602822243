import React from 'react';
import PropTypes from 'prop-types';
// Components
import Popover from 'components/Popover';
import FormLinkSlide from './components/FormLinkSlide';
// Service
import * as slidesService from 'services/sites/slides';

class SlideAddLink extends React.Component {
  state = {
    formData: {
      opens_link_on_page: true
    },
    isFetching: true
  };

  static propTypes = {
    slideId: PropTypes.string.isRequired
  };

  static defaultProps = {
    slideId: null
  };

  fetchSlide = () =>
    slidesService.getOne({ id: this.props.slideId }).then(({ data }) => {
      this.setState({
        formData: {
          ...this.state.formData,
          link: data.link,
          opens_link_on_page: data.opens_link_on_page
        },
        isFetching: false
      });
    });

  resetState = () => {
    this.setState({
      formData: { opens_link_on_page: true },
      isFetching: true
    });
  };

  onSubmit = values => slidesService.update(values);

  onSubmitSuccess = (...rest) => {
    if (this.props.onSubmitSuccess) this.props.onSubmitSuccess(...rest);
    this.resetState();
  };

  render() {
    const { children, slideId, ...rest } = this.props;
    const { formData, isFetching } = this.state;

    return (
      <Popover
        width={250}
        initialValues={{
          id: slideId,
          ...formData
        }}
        component={FormLinkSlide}
        onSubmit={this.onSubmit}
        onSubmitSuccess={this.onSubmitSuccess}
        fetchSlide={this.fetchSlide}
        isFetching={isFetching}
        {...rest}
      >
        {children}
      </Popover>
    );
  }
}

export default SlideAddLink;
