// Widgets.js
import { createModule } from 'lib/reducer-helpers';
import { getCurrentContract } from 'services/financialv2/contracts';

const createAction = createModule('currentCredit');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');

// Selectors
export const currentCreditSelector = (state) => state.financial.currentCredit;

// Initial State
const initialState = {
  data: {
    closed_at: null,
    contract_changes: null,
    created_at: null,
    daily_cost: null,
    end_at: null,
    id: null,
    plan: null,
    start_at: null,
    type: null,
    updated_at: null,
    user_id: null,
  },
  meta: {
    isFetching: false,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE:
      return {
        ...state,
        data: action.data,
        meta: { ...state.meta, isFetching: false },
      };
    default:
      return state;
  }
}

export const Actions = {
  request: () => ({ type: REQUEST }),
  receive: (data) => ({ type: RECEIVE, data }),
};

// side effects, only as applicable
// e.g. thunks, epics, etc
export const fetchCurrentContract = () => async (dispatch) => {
  try {
    dispatch(Actions.request());

    const { data } = await getCurrentContract();

    dispatch(Actions.receive(data));
  } catch (err) {
    throw err;
  }
};
