import FormGroup from 'components/Form/components/FormGroup';
import { Error } from 'components/Form/components/ShowErrors/styles';
import { Button, Content } from 'components/ReferenceSearch/styles';
import { useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { Field } from 'redux-form';
// import { Wrapper } from './styles';

function ReferenceSearchField({
  name,
  input,
  meta,
  handleSearch,
  placeholder = 'Digite a referência',
  ...rest
}) {
  const [error, setError] = useState(null);

  const onSearch = () => {
    if (input.value.length <= 0) {
      setError('No mínimo 1 caractere');
      return false;
    }
    if (handleSearch) handleSearch(input, meta);
  };
  return (
    <FormGroup input={input} meta={meta}>
      <Content>
        <input
          {...input}
          name={name}
          placeholder="Digite a referencia, ou"
          className="FormControl"
          {...rest}
          onKeyDown={(e) => {
            setError(null);

            if (e.key === 'Enter') {
              onSearch();
            }
          }}
        />
        <Button onClick={onSearch}>
          <MdSearch />
        </Button>
      </Content>
      {error && <Error>{error}</Error>}
    </FormGroup>
  );
}

function ReferenceField({ name = 'reference', handleSearch, ...rest }) {
  return (
    <Field
      name={name}
      component={ReferenceSearchField}
      handleSearch={handleSearch}
      {...rest}
    />
  );
}

export default ReferenceField;
