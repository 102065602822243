import { useRequest } from 'hooks/useRequest';
import api from 'services/index';

const getParamsClientsWithContact = (params) => {
  // Armazena o parametro em uma constante
  const clients = params.clients_with_contact;

  // Remove esse parametro de todos os payloads
  delete params.clients_with_contact;

  switch (clients) {
    // Se for Com posição no CRM
    case 'WITH_CRM':
      params.having_deals = true;
      return params;

    // Se for Sem posição no CRM
    case 'WITHOUT_CRM':
      params.having_deals = false;
      return params;

    // Se for todos os clientes os parametros não mudam
    case 'ALL_CLIENTS':
    default:
      return params;
  }
};

export const useGetLeadsLatest = () => {
  const { data, isFetching, fetchData } = useRequest({
    request: (params) => {
      console.log('params: ', params);
      let _params = { ...params, include: 'stage' };

      if (_params.user_id === 'todos') delete _params.user_id;

      _params = getParamsClientsWithContact(_params);

      if (_params.reception_source_id === 'todos')
        delete _params.reception_source_id;

      return api.getList('reports/leads/latest', _params);
    },
    initialFetch: false,
  });

  return { data, isFetching, fetchData };
};

export const useGetReportsPropertiesSent = () => {
  const {
    data: propertiesSentData,
    meta: propertiesSentMeta,
    fetchData: fetchReportsPropertiesSent,
    isFetching: isFetchingPropertiesSent,
  } = useRequest({
    request: (params) => {
      let _params = { ...params, include: 'stage' };

      if (_params.user_id === 'todos') delete _params.user_id;

      _params = getParamsClientsWithContact(_params);

      return api.get('reports/matcheds/properties-sent', {
        ..._params,
      });
    },
    initialFetch: false,
  });

  return {
    propertiesSentData,
    propertiesSentMeta,
    fetchReportsPropertiesSent,
    isFetchingPropertiesSent,
  };
};
