import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isDirty, reset } from 'redux-form';
// Components
import Button from 'components/Button';
import Container from 'components/Container';
import Nodata from 'components/Nodata';
// Containers
// Helpers
import { getName } from 'lib/text';
// Image
import property from 'assets/img/noData/house.svg';
import CardProperty from 'components/CardProperty';
import PropertiesList from 'components/PropertiesList';
import moment, { format } from 'lib/moment';
import styled from 'styled-components';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  handleClickPerson: PropTypes.func,
  handleClickProperty: PropTypes.func,
};
const defaultProps = {
  data: [],
  handleClickPerson: null,
  handleClickProperty: null,
};

const Reservation = styled.div`
  line-height: 1.4em;
  width: 230px;
`;

// const Property = ({
//   data,
//   handleClickPerson,
//   handleClickProperty,
//   fetchProperties,
// }) => {
//   const people = _.get(data, 'people');
//   const user = _.get(data, 'user');

//   return (
//     <>
//       <tr key={`property-${data.id}`} onClick={() => handleClickProperty(data)}>
//         <td>
//           <div className="h-flex">
//             <Avatar size="large" image={(data.cover_image || {}).file_url} />
//             <div className="h-margin-left--15">
//               <p className="h-color--primary" style={{ whiteSpace: 'nowrap' }}>
//                 {data.title_formatted} -{' '}
//                 <span className="h-link">Ref.: {data.reference}</span>
//               </p>
//               <p className="h-color--primary">{data.address_formatted}</p>
//             </div>
//           </div>
//         </td>
//         <td
//           className="border-left h-text-center"
//           onClick={(e) => {
//             e.stopPropagation();
//             handleClickPerson(people);
//           }}
//         >
//           <span className="h-link">
//             {getFirstName(people ? people.name : '--')}
//           </span>
//         </td>
//         <td className="border-left h-text-center">
//           {getFirstName(user ? user.name : '--')}
//         </td>
//         <td className="border-left h-text-right">{data.calculated_price}</td>
//         <td className="border-left">
//           <Options property={data} fetchProperties={fetchProperties} />
//         </td>
//       </tr>
//       <tr key={`property-spacer-${data.id}`} className="spacer">
//         <td />
//       </tr>
//     </>
//   );
// };

// const renderProperties = (
//   properties,
//   handleClickPerson,
//   handleClickProperty,
//   fetchProperties
// ) => {
//   if (!properties && properties.length === 0) return null;

//   return properties.map((property) => (
//     <Property
//       key={property.id}
//       data={property}
//       handleClickPerson={handleClickPerson}
//       handleClickProperty={handleClickProperty}
//       fetchProperties={fetchProperties}
//     />
//   ));
// };

function TablePropertiesExcluded({
  data,
  handleClickPerson,
  handleClickProperty,
  reset,
  isDirty,
}) {
  const onClickProperty = (booking) => () => {
    handleClickProperty(booking.property);
  };

  const isBookingExpired = (booking) => {
    // Caso não esteja com data de reserva vai estar sempre ativa
    if (booking.booked_until === null) return false;

    const today = moment().startOf('day');
    const bookedUntil = moment(booking.booked_until, format.date);
    const diff = bookedUntil.diff(today, 'days');

    return diff <= 0;
  };

  if ((isDirty && !data) || data.length <= 0)
    return (
      <Container>
        <Nodata
          image={property}
          title="Nenhum imóvel encontrado"
          text={
            isDirty
              ? 'Clique em limpar filtro e tente novamente.'
              : 'Quando você reservar algum imóvel, ele aparecerá aqui.'
          }
        >
          {isDirty && (
            <Button
              color="danger"
              className="h-margin-top--15"
              onClick={() => {
                reset('PropertiesExcluded');
              }}
            >
              Limpar Filtro
            </Button>
          )}
        </Nodata>
      </Container>
    );

  return (
    <PropertiesList
      canShowStages
      getProperty={(booking) => booking.property}
      data={data}
      itemRenderer={({ type, canShowStages }) =>
        (booking) => {
          const isExpired = isBookingExpired(booking);

          return (
            <CardProperty
              canShowStages={canShowStages}
              property={booking.property}
              type={type}
              handleClickProperty={onClickProperty(booking)}
            >
              <Reservation onClick={onClickProperty(booking)}>
                <p>
                  <strong>Dados da reserva</strong>
                </p>
                <p>
                  Corretor:{' '}
                  {booking?.booking_user?.name
                    ? getName(booking?.booking_user?.name)
                    : '-'}
                </p>
                <p>
                  Cliente:{' '}
                  {booking?.person?.name ? getName(booking?.person?.name) : '-'}
                </p>
                <p>
                  Reserva:{' '}
                  {moment(booking?.created_at, format.datetime).format(
                    format.date
                  )}
                  {booking?.booked_until ? ` à ${booking?.booked_until}` : null}
                </p>
                <p>
                  Exibindo no site:{' '}
                  {booking?.property?.is_published ? 'Sim' : 'Não'}
                </p>
                <p>
                  <strong
                    className={`h-color--${isExpired ? 'danger' : 'success'}`}
                  >
                    {isExpired ? 'Expirada' : 'Ativa'}
                  </strong>
                </p>
              </Reservation>
            </CardProperty>
          );
        }}
    />
  );
}

TablePropertiesExcluded.propTypes = propTypes;
TablePropertiesExcluded.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isDirty: isDirty('PropertiesExcluded')(state),
});

const mapDispatchToProps = {
  reset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TablePropertiesExcluded);
