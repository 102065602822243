import React, { useEffect, useMemo } from 'react';
import { Select } from 'components/Form';
import { Field } from 'redux-form';
import useReduxForm from 'hooks/useReduxForm';
import { PAYMENT_METHODS } from 'constants/constants';
import { formatter } from 'hooks/usePlan';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';

const required = (value) =>
  value || typeof value === 'number' ? undefined : 'Campo obrigatório';

const FieldInstallments = ({ ...props }) => {
  const { months, plan, receiving_method } = useModalPayment();
  const { dispatch, change } = useReduxForm();

  const installments = useMemo(() => {
    if (receiving_method === PAYMENT_METHODS.BOLETO)
      return plan.installmentsSlip;

    return plan.installmentsCreditCard;
  }, [plan, receiving_method]);

  const options = useMemo(() => {
    let opt = [];

    for (let i = installments; i > 0; i--) {
      const total = formatter.format(plan.netValue / i);

      opt = [...opt, { label: `${i}x de R$${total}`, value: i }];
    }

    return opt;
  }, [plan, installments]);

  useEffect(() => {
    dispatch(change('installments', installments));
  }, [months, installments]);

  return (
    <Field
      label="Total de parcelas"
      name="installments"
      component={Select}
      options={options}
      validate={[required]}
      {...props}
    />
  );
};

export default FieldInstallments;
