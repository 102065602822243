import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
`;
export const Button = styled.button`
  display: grid;
  place-items: center;
  font-size: 24px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  text-align: center;
  width: 150px;
  text-transform: capitalize;
`;
