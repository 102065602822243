import { useMap } from "../MapSection/context";
import MapView from "../MapView";

function MapContainer({
  style,
  isExactPlace,
}) {
  const {
    position,
    pov,
    zoom,
    handleChangePov,
    handleDropMarker,
    handleZoomChange,
  } = useMap();

  return (
    <MapView
      style={style}
      isExactPlace={isExactPlace}
      position={position}
      pov={pov}
      zoom={zoom}
      handleChangePov={handleChangePov}
      handleDropMarker={handleDropMarker}
      onZoomChange={handleZoomChange}
    />
  );
}

export default MapContainer;
