import PropTypes from 'prop-types';
import { getKey } from '../lib/array-helpers';
import { LAND_TYPE, NEAR_SEA, PROFILE } from './options';

export const DEFAULT_FIELDS = {
  id: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired
};

export const ESTABLISHMENTS = PropTypes.shape({
  id: PropTypes.number
});

const getValue = getKey('value');

export const PROPERTY_SHAPE = PropTypes.shape({
  id: PropTypes.string.required,
  updated_at: PropTypes.string,
  type: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string
  }),
  sub_type: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string
  }),
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  price_financial_index: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  calculated_price: PropTypes.string,
  profile: PropTypes.oneOf(PROFILE.map(getValue)),
  land_type: PropTypes.oneOf(LAND_TYPE.map(getValue)),
  near_sea: PropTypes.oneOf(NEAR_SEA.map(getValue)),

  matriculation: PropTypes.string,

  is_property_titled: PropTypes.bool,
  is_deeded: PropTypes.bool,
  is_corner: PropTypes.bool,

  // Casa Verde e Amarela
  is_financeable_mcmv: PropTypes.bool,

  informations: PropTypes.object,
  areas: PropTypes.object, //
  rooms: PropTypes.object,

  reference: PropTypes.string,

  // Localização do imóvel
  zip_code: PropTypes.string,
  street_address: PropTypes.string,
  complement_address: PropTypes.string,
  maps_latitude: PropTypes.number,
  maps_longitude: PropTypes.number,
  maps_heading: PropTypes.number,
  maps_pitch: PropTypes.number,
  maps_zoom: PropTypes.number,

  // Condominium
  condominium: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string
  }),
  condominium_financial_index: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  taxes_price: PropTypes.string,
  taxes_financial_index: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  taxes_description: PropTypes.string,

  // Iptu
  territorial_tax_price: PropTypes.string,
  territorial_tax_financial_index: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),

  is_exchangeable: PropTypes.bool,
  video: PropTypes.string,
  tour_360: PropTypes.string,
  is_published: PropTypes.bool,
  is_home_published: PropTypes.bool,
  is_network_published: PropTypes.bool,
  is_city_shown: PropTypes.bool,
  is_neighborhood_shown: PropTypes.bool,
  is_street_shown: PropTypes.bool,
  is_exact_map_shown: PropTypes.bool,
  is_floor_shown: PropTypes.bool,
  is_apartment_number_shown: PropTypes.bool,
  is_comission_combined: PropTypes.bool,
  has_owner_authorization: PropTypes.bool,
  is_exclusive: PropTypes.bool,

  // Determina se o corretor tem a chave disponível
  is_keys_ready: PropTypes.bool,

  // tempo que ele deve revisar o imóvel
  review_every: PropTypes.number,

  // Determina se o imóvel possui placa de venda
  has_sale_card: PropTypes.bool,

  send_summary_every: PropTypes.number,

  // Notas/Observações
  deal_note: PropTypes.string,
  occupation_note: PropTypes.string,
  private_note: PropTypes.string,

  meta_title: PropTypes.string,
  meta_description: PropTypes.string,

  // proprietário do imóvel
  people: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),

  country: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),

  state: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),

  city: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),

  neighborhood: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),

  establishments: PropTypes.array
});

export const PROPERTY_CONDOMINIUM_SHAPE = PropTypes.shape({
  condominium: PropTypes.object,
  image: PropTypes.string,
  title: PropTypes.string,
  priceMin: PropTypes.string,
  priceMax: PropTypes.string
});

export const DIVIDER_SHAPE = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  spacingX: PropTypes.number,
  spacingY: PropTypes.number
};

// Validacao dos componentes Input/Select/Textarea
export const CONTROL_SHAPE = {
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  required: PropTypes.bool,
  label: PropTypes.node,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    error: PropTypes.node,
    initial: PropTypes.any,
    touched: PropTypes.bool
  })
};

export const USER_SHAPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  creci: PropTypes.string,
  admin: PropTypes.shape({
    calendar: PropTypes.bool.isRequired,
    analytics: PropTypes.bool.isRequired
  }).isRequired
});

export const PROPERTY_SUBTYPES = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string
});

export const PROPERTY_FIELDS = PropTypes.shape({
  name: PropTypes.string,
  title: PropTypes.string
});

export const PROPERTY_TYPES = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtypes: PropTypes.arrayOf(PROPERTY_SUBTYPES),
  rooms_fields: PropTypes.arrayOf(PROPERTY_FIELDS),
  information_fields: PropTypes.arrayOf(PROPERTY_FIELDS),
  area_fields: PropTypes.array
});

export const PROPERTY_PHOTO = PropTypes.shape({
  id: PropTypes.string.isRequired,
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isFetching: PropTypes.bool,
  isFeatured: PropTypes.bool,
  isEditing: PropTypes.bool,
  is_featured: PropTypes.bool,
  file_url: PropTypes.string,
  caption: PropTypes.string
});

export const STAGE_SHAPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  updated_at: PropTypes.string,
  created_at: PropTypes.string,
  active: PropTypes.bool,

  name: PropTypes.string,
  order: PropTypes.number
});

export const STAGES_SHAPE = PropTypes.arrayOf(STAGE_SHAPE);

export const DEAL_SHAPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  updated_at: PropTypes.string,
  created_at: PropTypes.string,
  active: PropTypes.bool,

  stage: STAGE_SHAPE,
  property: PROPERTY_SHAPE,
  order: PropTypes.number,
  interest: PropTypes.number
});

export const PAGINATION = PropTypes.shape({
  limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalPages: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasMore: PropTypes.bool
});

// Cores primárias do projeto
export const PrimaryColorsShape = PropTypes.oneOfType([
  PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'caution']),
  PropTypes.string
]);

export const OwnerShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  admin: PropTypes.bool.isRequired,
  email: PropTypes.string,
  realtor: PropTypes.bool,
  creci: PropTypes.string,
  cellphone: PropTypes.string,
  phone: PropTypes.string,
  auths: PropTypes.shape({
    calendar: PropTypes.bool
  }),
  preview: PropTypes.string
});

export const ReceptionSourceShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const PersonShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  is_owner: PropTypes.bool.isRequired,
  search_profile_id: PropTypes.string,
  matched_id: PropTypes.string,

  private: PropTypes.bool,
  address_street: PropTypes.string,
  address_complement: PropTypes.string,
  address_number: PropTypes.string,
  address_cep: PropTypes.string,
  cpf_cnpj: PropTypes.string,
  rg_ie: PropTypes.string,
  phone_number: PropTypes.string,
  cellphone_number: PropTypes.string,
  email: PropTypes.string,
  birth_date: PropTypes.string,
  spouse_name: PropTypes.string,
  spouse_cpf: PropTypes.string,

  reception_source: ReceptionSourceShape,
  owner: OwnerShape
});

export const AuditShape = PropTypes.shape({
  ...DEFAULT_FIELDS,
  is_informational: PropTypes.bool,
  event: PropTypes.string.isRequired,
  modified: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  user: USER_SHAPE
});

export const DomainShape = PropTypes.shape({
  ...DEFAULT_FIELDS,
  url: PropTypes.string.isRequired,
  is_primary: PropTypes.bool,
  dns: PropTypes.string,
  dns_status: PropTypes.bool
});

export const PendingRegister = PropTypes.shape({
  ...DEFAULT_FIELDS,
  url: PropTypes.string,
  name: PropTypes.string,
  cpf: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  cep: PropTypes.string,
  neighborhood_id: PropTypes.string,
  street_number: PropTypes.string,
  street_address: PropTypes.string,
  person_type: PropTypes.number,
  cnpj: (props, propName) => {
    if (props['person_type'] === 2 && props[propName] === undefined) {
      return new Error('Quando for Pessoa Juridica tem que ter CNPJ');
    }
  }
});

export const LabelShape = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PrimaryColorsShape,
  className: PropTypes.string,
  children: PropTypes.node,
  handleClick: PropTypes.func,
  style: PropTypes.object
};

export const AliasShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  address: PropTypes.string,
  goto: PropTypes.string,
  domain: PropTypes.string
});

export const MailboxShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  name: PropTypes.string,
  maildir: PropTypes.string,
  quota: PropTypes.number,
  local_part: PropTypes.string,
  domain: PropTypes.string,
  created: PropTypes.string,
  modified: PropTypes.string,
  active: PropTypes.number,
  phone: PropTypes.string,
  email_other: PropTypes.string,
  token: PropTypes.string,
  token_validity: PropTypes.string
});
