import Button from "components/Button";
import Container from "components/Container";
import { Input } from "components/Form";
import ListOptions from "components/ListOptions";
import { Row } from "react-flexbox-grid";
import { MdDelete } from "react-icons/md";
import { Field } from "redux-form";

function Tour360({ change }) {
  return (
    <Container className="Container--nopadding h-margin-top--15">
      <ListOptions>
        <ListOptions.item
          title="360º"
          text="Uma apresentação em 360º leva o cliente para conhecer melhor o imóvel sem sair de onde está."
          renderOptions={() => (
            <Button
              color="danger"
              onClick={() => {
                change('tour_360', null);
              }}
            >
              <MdDelete /> Remover 360º
            </Button>
          )}
        >
          <Row className="h-margin-top--15">
            <Field
              xs={12}
              label="Insira a URL 360° do seu imóvel"
              name="tour_360"
              component={Input}
              placeholder="Exemplo: https://roundme.com/tour/000000/view/000000/"
            />
          </Row>
        </ListOptions.item>
      </ListOptions>
    </Container>
  );
}

export default Tour360;