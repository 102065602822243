// Widgets.js
import createDataReducer from 'modules/data';
import * as permissionsService from 'services/settings/permissions';

export const {
  actionCreators: permissionsActions,
  reducer: permissionsReducer,
  selectors: permissionsSelectors
} = createDataReducer('permissions');

export const fetchPermissions = params => async dispatch => {
  dispatch(permissionsActions.request());
  const { data } = await permissionsService.getList(params);
  dispatch(permissionsActions.receive(data));
};

export default permissionsReducer;
