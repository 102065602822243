import React, { memo, useMemo, useEffect } from 'react';
import emojiStrip from 'emoji-strip';
// import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Row } from 'react-flexbox-grid';
// Components
import FormBox from 'components/FormBox';
import { Textarea, Input } from 'components/Form';
import PortalOptionsCount from 'containers/PortalOptionsCount';
import useFormValue from '../../../hooks/useFormValue';

const propTypes = {};
const defaultProps = {};

function stripHtml(html) {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

function Description({ isEditting, changeField }) {
  const description = useFormValue('description');

  const formattedDescription = useMemo(() => {
    return emojiStrip(stripHtml(description));
  }, [description]);

  useEffect(() => {
    if (!isEditting) {
      changeField('meta_description', formattedDescription.slice(0, 156));
    }
  }, [formattedDescription, isEditting]);

  return (
    <FormBox title="Detalhamento do imóvel" className="js-description">
      <Row>
        <Field
          xs={12}
          label="Titulo da página de detalhamento do imóvel"
          name="title"
          component={Input}
        />
      </Row>
      <Row>
        <Field
          growOnFocus
          growHeight={300}
          xs={12}
          labelRender={() => (
            <div className="h-flex h-flex--between h-flex--center-center">
              <label className="FormLabel">
                Texto de apresentação do imóvel
              </label>
              <PortalOptionsCount description={description} />
            </div>
          )}
          name="description"
          component={Textarea}
        />
      </Row>
    </FormBox>
  );
}

Description.defaultProps = defaultProps;
Description.propTypes = propTypes;

export default memo(Description);
