import React from 'react';
import classnames from 'classnames';
import Item from 'pages/PlanConfig/components/ListContracts/components/Item';
import {
  Dropdown,
  MainContract,
  Status,
  TextStatus,
} from 'pages/PlanConfig/components/ListContracts/styles';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

// import { Wrapper } from './styles';

function PreviousModelContract({
  className,
  setIsExpanded,
  period,
  isExpanded,
  data,
}) {
  const status = ({ closed_at }) => {
    if (closed_at === null)
      return {
        type: 'active',
        text: 'Ativo',
      };

    return {
      type: 'finished',
      text: 'Finalizado',
    };
  };

  return (
    <MainContract
      className={classnames(className, 'contract--first', {
        'contract--expanded': isExpanded,
      })}
    >
      <td width={110}>
        <Item title="Data">{data?.created_at}</Item>
      </td>
      <td width={110}>
        <Item title="Tipo">{data.type}</Item>
      </td>
      <td width={110}>
        <Item title="Recorrência">{data.plan}</Item>
      </td>
      <td width={180}>
        <Item title="Crédito até">{data.end_at}</Item>
      </td>
      <td width={110} />
      <td />
      <Status
        type="finished"
        onClick={() => {
          setIsExpanded((value) => !value);
        }}
      >
        <TextStatus>{isExpanded ? 'Ver menos' : 'Ver mais'}</TextStatus>
        <Dropdown>
          {isExpanded ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
        </Dropdown>
      </Status>
    </MainContract>
  );
}

export default PreviousModelContract;
