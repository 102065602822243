import React, { useContext } from 'react';
import {
  MdHotel as BedroomIcon,
  MdDriveEta as GarageIcon,
  MdZoomOutMap as AreaIcon
} from 'react-icons/md';
import { ContextCompare } from '../../index';
import { Cell, CellLabel } from '../Table/styles';
import RowDivider from '../RowDivider';
import { List } from './styles';
import { getPropertyInformations } from 'lib/property-helpers';

const ICON_BY_NAME = {
  bedroom: BedroomIcon,
  garage: GarageIcon,
  total_area: AreaIcon,
  ground_total_area: AreaIcon,
  built_area: AreaIcon,
  front_ground: AreaIcon,
  back_ground: AreaIcon
};

const RowPrimaryRooms = () => {
  const { properties } = useContext(ContextCompare);

  return (
    <>
      <RowDivider />
      <tr>
        <CellLabel rowSpan={2}>
          Principais <br /> Cômodos
        </CellLabel>
        {properties.map(({ id, rooms, areas }) => {
          const infos = getPropertyInformations({ rooms, areas });

          if (!infos || infos.length <= 0) {
            return <Cell key={`primary-rooms-${id}`}>--</Cell>;
          }

          return (
            <Cell key={`primary-rooms-${id}`}>
              <List>
                {infos.map(
                  ({ title, title_formated, name, value, measure }) => {
                    const Icon = ICON_BY_NAME[name];

                    if (measure) {
                      return (
                        <li key={`${name}-${id}`}>
                          <Icon size={18} /> {value}
                          {measure} ({title})
                        </li>
                      );
                    }

                    return (
                      <li key={`${name}-${id}`}>
                        <Icon size={18} /> {title_formated}
                      </li>
                    );
                  }
                )}
              </List>
            </Cell>
          );
        })}
      </tr>
    </>
  );
};

export default RowPrimaryRooms;
