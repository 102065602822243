// import styles from './styles'

import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import BoxHelp from 'components/BoxHelp';
import { useFeaturedLists } from 'hooks/api/featuredLists';
import { useEffect } from 'react';
import ListFeatureds from '../../components/ListFeatureds';

function ListFeaturedLists() {
  const {
    dataFeaturedLists,
    fetchFeaturedLists,
    handleRemoveFeaturedList,
    handleChangeOrderFeaturedList,
  } = useFeaturedLists({ with_all: true });

  useEffect(() => {
    fetchFeaturedLists();
  }, []);

  return (
    <Wrapper>
      <MainTitle
        title="Imóveis em destaque na página inicial"
        text="Adicione e organize os imóveis que irão aparecer na página inicial do seu site"
      />
      <BoxHelp
        helpLink={null}
        title="Equilibrando Imóveis e SEO: Dicas para sua Página Inicial"
        text="Por favor, tenha em mente que adicionar muitos imóveis à página inicial do seu site pode afetar negativamente o desempenho do SEO. Quanto mais imóveis são exibidos, mais pesada a página se torna, o que pode resultar em tempos de carregamento mais longos e uma experiência menos satisfatória para os visitantes. Além disso, é importante considerar que o setor imobiliário geralmente não gera compras por impulso. Recomendamos manter uma seleção cuidadosa e limitada de imóveis na página inicial para garantir uma navegação fluida e eficaz para seus clientes."
      />
      <ListFeatureds
        data={dataFeaturedLists}
        fetch={fetchFeaturedLists}
        onRemove={handleRemoveFeaturedList}
        onChangeOrder={handleChangeOrderFeaturedList}
      />
    </Wrapper>
  );
}

export default ListFeaturedLists;
