import React from 'react';
import { connect } from 'react-redux';
// Modules
import { characteristicsSelectors, fetchCharacteristics } from './module';
// Components
import { Characteristics } from 'components/PropertyFilters';

class CharacteristicsContainer extends React.Component {
  state = {
    data: [],
    isFetching: false
  };

  componentDidMount() {
    // Busca todas as caracteristicas
    this.fetchCharacteristics();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.type?.id !== this.props?.type?.id) {
      this.fetchCharacteristics();
    }
  }

  /**
   * Busca as características se tiver um tipo
   */
  fetchCharacteristics = () => {
    const { type } = this.props;

    if (type) this.props.fetchCharacteristics({ type_id: type.id });
  };

  render() {
    const { isFetching, data, type } = this.props;

    if (isFetching)
      return (
        <div className="loader-5" style={{ margin: '0 auto 10px' }}>
          <span />
        </div>
      );

    // Verifica
    if (!type || !data || data.length === 0) return null;

    return <Characteristics options={data} />;
  }
}

const mapStateToProps = state => ({
  data: characteristicsSelectors.getAll(state),
  isFetching: characteristicsSelectors.isFetching(state)
});

const mapDispatchToProps = {
  fetchCharacteristics
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CharacteristicsContainer);
