import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Modules
import {
  characteristicsSelector,
  deleteCharacteristic,
  getCharacteristics,
  handleFormCharacteristic,
} from 'modules/condosCharacteristics';
// Components
import Button from 'components/Button';
import FormBox from 'components/FormBox';
import Popover from 'components/Popover';
import ListCheck from 'components/ListCheck';
import FormCharacteristics from 'components/FormCharacteristics';
import { USER_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';

const propTypes = {
  characteristics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string
    })
  ),
  subtype: PropTypes.object,
  getCharacteristics: PropTypes.func
};

const defaultProps = {
  characteristics: []
};

class Characteristics extends React.Component {
  /**
   * Cadastro de uma nova característica
   * @param typeId|String
   */
  handleFormSubmit = condoId => values => {
    this.props.handleFormCharacteristic({ id: condoId, ...values });
  };

  /**
   * Remove uma característica
   * @param id
   * @return {function()}
   */
  handleDeleteCharacteristic = ({ id }) => () => {
    this.props.deleteCharacteristic(id);
  };

  render() {
    const { condoId, characteristics } = this.props;

    return (
      <FormBox
        title="Características do condomínio"
        className="js-condo-characteristics"
        actions={
          <Can run={USER_PERMISSIONS.EDIT_REGISTRATIONS}>
            <Popover
              onSubmit={this.handleFormSubmit(condoId)}
              component={FormCharacteristics}
            >
              <Button size="medium" color="secondary">
                Cadastrar Característica do condomínio
              </Button>
            </Popover>
          </Can>
        }
      >
        <ListCheck
          name="condos_characteristics"
          data={characteristics}
          handleDeleteItem={this.handleDeleteCharacteristic}
          params={{ condoId }}
        />
      </FormBox>
    );
  }
}

Characteristics.defaultProps = defaultProps;
Characteristics.propTypes = propTypes;

const mapStateToProps = state => ({
  characteristics: characteristicsSelector(state)
});

const mapDispatchToProps = {
  getCharacteristics,
  deleteCharacteristic,
  handleFormCharacteristic
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(Characteristics));
