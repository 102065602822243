import React from 'react';
import { Field } from 'redux-form';
// Components
import { Textarea } from 'components/Form';
import ListOptions from 'components/ListOptions';

export default function FieldPrivate() {
  return (
    <ListOptions.item title="Anotação inicial privada">
      <Field
        className="h-margin-top--15"
        name="private_notation"
        component={Textarea}
        style={{ background: 'rgba(241, 163, 49, 0.05)' }}
      />
    </ListOptions.item>
  );
}
