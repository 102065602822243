import { ModalTemplate } from "components/Modal";
import { useRequest } from "hooks/useRequest";
import { useCallback } from "react";
import Modal from 'react-modal';
import * as locationService from 'services/location';
import Form from './components/Form';

export default function ModalZoneStore({
  id,
  onSubmitSuccess,

  modalConfig,
  isOpen,
  modalType,
  handleClose
}) {
  const { data: initialValues } = useRequest({
    request: locationService.getZoneById,
    params: [id]
  });

  const isEditting = !!id;

  const handleSubmit = useCallback(values => {
    return locationService.updateZone(values);
  }, []);

  const handleSubmitSuccess = useCallback((...rest) => {
    if (onSubmitSuccess) onSubmitSuccess(...rest);
    handleClose();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={isEditting ? 'Editar Bairro' : 'Cadastrar Bairro'}
        handleClose={handleClose}
      >
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}
