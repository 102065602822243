import React from 'react';
import { Field } from 'redux-form';
import { Select } from 'components/Form';
import * as options from 'constants/options';

const FieldNearSea = () => {
  return (
    <Field
      xs={3}
      label="Próximo ao mar?"
      name="near_sea"
      component={Select}
      options={options.NEAR_SEA}
    />
  );
};

export default FieldNearSea;
