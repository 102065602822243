// Actions
import { createModule } from '../lib/reducer-helpers';

const createAction = createModule('loading');

const SHOW = createAction('SHOW');
const HIDE = createAction('HIDE');

// Initial State
const initialState = {
  isVisible: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW:
      return { ...state, isVisible: true };
    case HIDE:
      return { ...state, isVisible: false };
    default:
      return state;
  }
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export function showLoading() {
  return dispatch => dispatch({ type: SHOW });
}

export function hideLoading() {
  return dispatch => dispatch({ type: HIDE });
}
