import React, { useMemo } from 'react';
import classnames from 'classnames';
import Check from 'components/Check';
import { Wrapper, Transaction, Rooms, Remove } from './styles';
import { Actions as SelectedsActions } from 'modules/selecteds';
import { useDispatch } from 'react-redux';
import {
  AiFillCar,
  FaBed,
  HiOutlineTrash,
  MdFullscreen,
} from 'react-icons/all';
import { transformProperty, hasPropertyProblem } from './helpers';

const getAllPropertiesIds = (properties) => {
  return properties.map((property) => property.id);
};

function PropertiesTable({
  hasCheckbox = false,
  properties,
  selectedProperties,
  onClickProperty,
  handleRemoveAll,
  handleRemove,
  toggleSelectedProperty,
  handleClickCheckAll,
}) {
  const dispatch = useDispatch();

  const isAllChecked = useMemo(() => {
    const ids = getAllPropertiesIds(properties);
    return ids.every((id) => selectedProperties?.includes(id));
  }, [properties, selectedProperties]);

  const _handleClickCheckAll = () => {
    if (handleClickCheckAll) return handleClickCheckAll();

    const ids = getAllPropertiesIds(properties);

    if (isAllChecked) {
      dispatch(SelectedsActions.removeAll('properties_search', ids));
    } else {
      dispatch(SelectedsActions.addAll('properties_search', ids));
    }
  };

  const hasRemove = handleRemove && handleRemoveAll;

  return (
    <Wrapper>
      <table className="Table">
        <thead>
          <tr>
            {hasCheckbox && (
              <th className="Table__cell--small">
                <Check
                  className="Check h-pointer"
                  checked={isAllChecked}
                  onClick={_handleClickCheckAll}
                />
              </th>
            )}
            <th>Referência</th>
            <th>Tipo/Sub-tipo</th>
            <th>Situação</th>
            <th>Características</th>
            <th>Bairro|Cidade/UF</th>
            <th>Preço</th>
            {hasRemove && <th />}
          </tr>
        </thead>
        <tbody>
          {properties.map((property) => {
            const data = transformProperty(property);
            const hasProblem = hasPropertyProblem(property);

            return (
              <tr
                onClick={(e) => {
                  e.preventDefault();
                  if (onClickProperty) onClickProperty(property);
                }}
                className={classnames({
                  'has-problem': hasProblem,
                })}
              >
                {hasCheckbox && (
                  <td
                    onClick={(e) => e.stopPropagation()}
                    className="Table__cell--small"
                  >
                    <Check
                      className="Check"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (toggleSelectedProperty)
                          toggleSelectedProperty(property?.id);
                      }}
                      checked={property?.isChecked}
                    />
                  </td>
                )}
                <td>{property.reference}</td>
                <td className="h-text-nowrap">
                  {property?.type?.title}/{property?.subtype?.title}
                </td>
                <td>{property?.situation?.title}</td>
                <td>
                  <Rooms>
                    {data.bedroom && (
                      <div data-tip={data.bedroom_tip} data-place="right">
                        <FaBed /> {data.bedroom}
                      </div>
                    )}
                    {data.garage && (
                      <div>
                        <AiFillCar /> {data.garage}
                      </div>
                    )}
                    {data.primary_area && (
                      <div data-tip={data.primary_area_tip}>
                        <MdFullscreen /> {data.primary_area}
                      </div>
                    )}
                  </Rooms>
                </td>
                <td>{property?.address_formatted}</td>
                <td className="Table__cell--money">
                  <Transaction transaction={property?.transaction}>
                    {data.price}
                  </Transaction>
                </td>
                {handleRemove && (
                  <td>
                    <Remove
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        handleRemove(property)(e);
                      }}
                    >
                      <HiOutlineTrash />
                    </Remove>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Wrapper>
  );
}

export default PropertiesTable;
