import { FieldBool } from "components/Form";
import useReduxForm from "hooks/useReduxForm";
import { useCallback } from "react";

function FieldIsCondo() {
  const { dispatch, change } = useReduxForm();

  const handleChangeIsCondominium = useCallback(() => {
    // limpa o campo de condominio
    dispatch(change('condominium_id', null));
  }, [dispatch, change]);

  return (
    <FieldBool
      xs={3}
      label="Condomínio/empreendimento?"
      name="is_condo"
      onChange={handleChangeIsCondominium}
    />
  );
}

export default FieldIsCondo;
