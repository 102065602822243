import * as PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { FieldArray, change, formValueSelector, submit } from 'redux-form';
import * as whatsappContactService from 'services/sites/whatsapp-contact';
// Components
import { withFormName } from 'HOC/withFormName';
import NestTable from 'components/NestTable';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { responseMessage } from 'lib/service-helpers';
import { openModalWhatsappUserStore } from 'modules/modal';
import api from 'services';
import TableItem from './components/TableItem';
import { Col, TableRow, WrapperAll } from './styles';
import { useCallback } from 'react';

const Table = ({
  onClickUpdate,
  onClickDelete,
  onClickHours,
  onUploadFile,
  onSubmitOfficeHours,
  handleChange,
  whatsappContacts,
}) => {
  return (
    <WrapperAll>
      <TableRow>
        <Col></Col>
        <Col>Nome</Col>
        <Col>Celular</Col>
        <Col>Descrição</Col>
      </TableRow>
      <NestTable
        onChange={handleChange}
        items={whatsappContacts}
        maxDepth={1}
        style={{
          marginBottom: '60px',
        }}
        renderItem={({ item, index }) => {
          return (
            <TableItem
              index={index}
              field={`whatsapp_contacts[${index}]`}
              user={item}
              onClickUpdate={onClickUpdate}
              onClickDelete={onClickDelete}
              onClickHours={onClickHours}
              onUploadFile={onUploadFile}
              onSubmitOfficeHours={onSubmitOfficeHours}
            />
          );
        }}
      />
    </WrapperAll>
  );
}

const selector = formValueSelector('FormWhatsapp');

function TableSchedule({ fetchData, change, submit }) {
  const dispatch = useDispatch();

  const onClickUpdate = (user) => {
    dispatch(
      openModalWhatsappUserStore({
        id: user.id,
        onSubmitSuccess: fetchData,
      })
    );
  };

  const onClickHours = (index, user) => {
    change(`whatsapp_contacts.${index}.isChangeHours`, !user.isChangeHours);
  };

  const onSubmitOfficeHours = async (user, fields) => {
    const hours = fields?.getAll?.() || null;

    const res = await whatsappContactService.updateOrCreate({
      ...user,
      office_hours: hours,
    });

    fetchData();

    return res;
  };

  const onClickDelete = (user) => {
    dispatch(
      openConfirmation({
        title: 'Remover usuário',
        text: 'Deseja remover este usuário?',
        request: async () => {
          return api
            .delete('settings/sites/whatsapp-contact', user?.id)
            .then(responseMessage('Usuário removido'));
        },
        onSuccess: fetchData,
      })
    );
  };

  const onUploadFile = () => {
    submit();
  };

  const whatsappContacts = useSelector((state) =>
    selector(state, 'whatsapp_contacts')
  );

  const handleChange = useCallback(async (items, user) => {
    // Índice atual do item
    const index = items?.findIndex(({ id }) => id === user.id) + 1;

    const currentUser = {
      ...user,
      order: index
    };

    await whatsappContactService.updateOrCreate(currentUser);
    fetchData();
  }, [fetchData]);

  return (
    <FieldArray
      name="whatsapp_contacts"
      onClickUpdate={onClickUpdate}
      onClickHours={onClickHours}
      onClickDelete={onClickDelete}
      onUploadFile={onUploadFile}
      component={Table}
      handleChange={handleChange}
      whatsappContacts={whatsappContacts}
      onSubmitOfficeHours={onSubmitOfficeHours}
      rerenderOnEveryChange
    />
  );
}

TableSchedule.defaultProps = {
  change: PropTypes.func,
  submit: PropTypes.func,
};

TableSchedule.propTypes = {
  change: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  change: (field, value) => dispatch(change(ownProps.formName, field, value)),
  submit: () => dispatch(submit(ownProps.formName)),
});

export default compose(
  withFormName,
  connect(null, mapDispatchToProps)
)(TableSchedule);
