import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import { Field, reduxForm } from 'redux-form';

// Components
import { Input, Select } from 'components/Form';

// Containers
import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import FieldPerson from 'containers/FieldPerson';
import FieldSelectUser from 'containers/FieldSelectUser';
import useFormValue from 'hooks/useFormValue';

// Modules
import { closeModal } from 'modules/modal';

// Services
import CheckboxBlock from 'components/Form/components/CheckboxBlock';
import { TYPE_PERSONS } from 'constants/options';
import FieldCpfCnpj from 'containers/FIeldCpfCnpj';
import FieldCep from 'containers/FieldCep';
import FieldPhone from 'containers/FieldPhone';
import {
  CityField,
  CountryField,
  NeighborhoodField,
  StateField,
} from 'containers/LocationFields';
import { MdHelp } from 'react-icons/md';
import { compose } from 'redux';
import { getOne } from 'services/people';
import { print } from 'services/settings/sales-authorizations';
import { Divider, Left, Right, Wrapper } from './styles';
import { setDocument } from 'modules/configDocuments';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const Icon = () => {
  return (
    <span
      className="h-color--secondary h-margin-left--5"
      data-tip={`A empresa Tecimob não se<br/> responsabiliza pela validade<br/> legal dos dados que estão no<br/> documento, bem como sua<br/> cláusulas e composição.`}
    >
      <MdHelp size={18} />
    </span>
  );
};

function Form({ property, onCreatePerson, handleClose, change, handleSubmit }) {
  const personId = useFormValue('people_id');
  const country_id = useFormValue('country_id');
  const state_id = useFormValue('state_id');
  const city_id = useFormValue('city_id');
  const type = useFormValue('type');

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  async function fetchPerson() {
    const { data: person } = await getOne(personId, {
      include: 'neighborhood,city,state,country,phones',
    });

    change('name', person?.name);
    change('primary_phone', person?.cellphone_number);
    change('cpf_cnpj', person?.cpf_cnpj);
    change('cellphone', person?.cellphone);
    change(
      'address_complement',
      person?.address_complement || property?.complement_address
    );
    change('address_number', person?.address_number || property?.street_number);
    change(
      'address_street',
      person?.address_street || property?.street_address
    );
    change('country_id', person?.country?.id || property?.country?.id);
    change('state_id', person?.state?.id || property?.state?.id);
    change('city_id', person?.city?.id || property?.city?.id);
    change(
      'neighborhood_id',
      person?.neighborhood?.id || property?.neighborhood?.id
    );
    change('address_cep', person?.address_cep || property?.zip_code);
  }

  useEffect(() => {
    if (!!personId) {
      fetchPerson();
    }
  }, [personId]);

  return (
    <form onSubmit={handleSubmit}>
      <Wrapper>
        <Left>
          <Row>
            <FieldPerson
              required
              label="Selecione o cliente"
              name="people_id"
              xs={6}
              onCreatePerson={onCreatePerson}
            />
            <FieldSelectUser
              name="user_id"
              required
              xs={6}
              label="Corretor responsável"
            />
          </Row>
          <Row>
            <Field
              required
              label="Nome Completo"
              name="name"
              xs={8}
              component={Input}
            />
            <Field
              buttonTemplate
              xs={4}
              label="Tipo"
              name="type"
              options={TYPE_PERSONS}
              component={Select}
              onChange={() => {
                change('cpf_cnpj', null);
              }}
            />
          </Row>
          <Row>
            <FieldCpfCnpj
              isLegalPerson={type === 2 || type === '2'}
              required
              xs={6}
            />
            <FieldPhone required xs={6} name="primary_phone" />
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                label="Atualizar informações no cadastro do cliente"
                name="should_update_person"
                component={CheckboxBlock}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                type="checkbox"
                name="disclaimer"
                label={
                  <>
                    Termo de isenção de responsabilidade{' '}
                    <Icon data-tip="A empresa Tecimob não se responsabiliza pela validade legal dos dados que estão no documento, bem como sua cláusulas e composição. " />
                  </>
                }
                component={CheckboxBlock}
              />
            </Col>
          </Row>
        </Left>
        <Divider />
        <Right>
          <Row>
            <FieldCep
              required
              xs={4}
              label="CEP"
              name="address_cep"
              onChangeLocation={(location) => {
                if (location?.country?.id)
                  change('country_id', location?.country?.id);
                if (location?.state?.id)
                  change('state_id', location?.state?.id);
                if (location?.city?.id) change('city_id', location?.city?.id);
                if (location?.neighborhood?.id)
                  change('neighborhood_id', location?.neighborhood?.id);
              }}
            />
            <CountryField required xs={4} name="country_id" />
            <StateField
              required
              xs={4}
              name="state_id"
              countryId={country_id}
            />
          </Row>
          <Row>
            <CityField required xs={6} name="city_id" stateId={state_id} />
            <NeighborhoodField
              required
              label="Bairro"
              xs={6}
              name="neighborhood_id"
              cityId={city_id}
            />
          </Row>
          <Row>
            <Field
              required
              xs={12}
              label="Logradouro"
              name="address_street"
              component={Input}
            />
          </Row>
          <Row>
            <Field
              required
              xs={6}
              label="Número"
              name="address_number"
              component={Input}
            />
            <Field
              xs={6}
              name="address_complement"
              label="Complemento"
              component={Input}
            />
          </Row>
        </Right>
      </Wrapper>

      <ModalFooter>
        <Button color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Gerar Autorização
        </Button>
      </ModalFooter>
    </form>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

export default compose(
  withRouter,
  reduxForm({
    form: 'ModalAuthorizationSellForm',
    validate: (values) => {
      const errors = {};

      if (!values.people_id) {
        errors.people_id = ['Campo obrigatório'];
      }

      if (!values.disclaimer) {
        errors.disclaimer = ['Obrigado concordar com o termo'];
      }

      if (!values.user_id) {
        errors.user_id = ['Campo obrigatório'];
      }

      return errors;
    },
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
      const property_id = values?.property?.id;

      return print({
        property_id,
        template: 'sale_template',
        ...values,
      });
    },
    onSubmitSuccess: (response, dispatch, props) => {
      if (response?.data?.text) {
        dispatch(setDocument(response.data));

        props.history.push(`/config/documents/sales-authorization/send`);

        // Fecha a modal
        dispatch(closeModal());
      }
    },
  })
)(Form);
