import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #e0e5eb;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 30px;

  header {
    margin-bottom: 25px;
  }
`;

export const Title = styled.h2`
  font-size: 14px;
  color: #21232c;
  font-weight: 900;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #21232c;
  font-weight: 400;
`;

export const Content = styled.div``;

export const Action = styled.div``;
