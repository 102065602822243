import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Action, Content, Number, Title, Wrapper, WrapperIcon } from './styles';
import { Link } from 'react-router-dom';

export default function Count({
  href = null,
  to = null,
  color = 'primary',
  count,
  title,
  icon: Icon,
  actionText = 'Ver agora',
  onClick,
  image,
}) {
  const renderNumberOrIcon = () => {
    if (image) {
      return (
        <Number>
          <img src={image} alt="" />
        </Number>
      );
    }

    if (count !== undefined) {
      return (
        <Number>
          {count}{' '}
          {Icon && (
            <WrapperIcon>
              <Icon />
            </WrapperIcon>
          )}
        </Number>
      );
    }

    if (Icon) {
      return (
        <Number>
          <Icon />
        </Number>
      );
    }

    return null;
  };

  return (
    <ThemeProvider theme={{ color }}>
      <Wrapper onClick={onClick} as={to ? Link : 'a'} to={to} href={href}>
        <Content>
          {renderNumberOrIcon()}
          <Title>{title}</Title>
        </Content>
        <Action>{actionText}</Action>
      </Wrapper>
    </ThemeProvider>
  );
}
