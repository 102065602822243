import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  > img {
    max-width: 100%;
  }
`;

export const Box = styled.div`
  max-width: 330px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 15px;
  border: 1px solid ${p => p.theme.borderColor};
  box-shadow: ${p => p.theme.boxShadow};
  border-radius: ${p => p.theme.borderRadius};
  background-color: #fff;
  align-items: center;

  img {
    flex-shrink: 0;
    min-width: 0;
    margin-right: 20px;
  }

  p {
    line-height: 22px;
    flex: 1 0;
    color: ${p => p.theme.colors.primary};
  }
`;
