import React from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
  text: null
};

const propTypes = {
  title: PropTypes.node.isRequired,
  text: PropTypes.node
};

const FormTitle = ({ title, text }) => (
  <div className="FormHeader h-text-center h-margin-bottom--15">
    {title && <h1 className="h4 h-color--primary">{title}</h1>}
    {text && (
      <div className="h5 h-margin-top--5 h-color--primary-light">{text}</div>
    )}
  </div>
);

FormTitle.defaultProps = defaultProps;
FormTitle.propTypes = propTypes;

export default FormTitle;
