import { TableStriped } from "pages/Recommendations/components/TableUsers/styles";
import Head from "./components/Head";
import Item from "./components/Item";

function TableCondos({ data, pagination, handleChangePage, fetchData, canManage }) {
  return (
    <TableStriped
      data={data}
      renderTitle={() => <Head canManage={canManage} />}
      renderItem={(condo) => <Item key={condo.id} condo={condo} fetchData={fetchData} canManage={canManage} />}
      pagination={{
        forcePage: pagination?.current_page - 1,
        pageCount: pagination?.total_pages,
        pageRangeDisplayed: 10,
        marginPagesDisplayed: 2,
        onPageChange: handleChangePage,
      }}
      noData={{
        title: 'Você ainda não possui nenhum condomínio cadastrado',
        text: '',
      }}
    />
  );
}

export default TableCondos;
