import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdDelete } from 'react-icons/md';
// Components
import Label from 'components/Label';
import PayButton from '../../containers/PayButton';
import Textloading from 'components/TextLoading';
import { getReceivingMethod } from 'pages/PlanHistory/helpers';

const LoadingRow = () => (
  <tr>
    <td className="Table__cell--small" style={{ paddingLeft: '30px' }}>
      <Textloading width={75} height="1em" />
    </td>
    <td>
      <Textloading width={75} height="1em" />
    </td>
    <td>
      <Textloading width={75} height="1em" />
    </td>
    <td>
      <Textloading width={75} height="1em" />
    </td>
    <td className="Table__cell--small">
      <Textloading width={60} height="1em" />
      <Textloading width={60} height="1em" className="h-margin-left--10" />
    </td>
  </tr>
);

function Situation({ method, isChargeback, isReceived, canPay }) {
  if (isChargeback) return <Label color="secondary">Estorno</Label>;
  if (method === 'Bonus') return <Label color="success">Bonus</Label>;
  if (isReceived) return <Label color="success">Pago com {method}</Label>;
  if (!canPay && method === 'Cartão')
    return <Label color="secondary">Aguardando pagamento</Label>;

  return <Label color="primary">Aberto</Label>;
}

const Delete = styled.button`
  width: 30px;
  height: 30px;
  margin-left: 0.5em;
  color: ${(p) => p.theme.colors.danger};
  float: right;
  cursor: pointer;
  font-size: 20px;
  line-height: 20px;
`;

const WrapperOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

function TableCredits({
  isLoading,
  data,
  limit,
  onClickDelete,
  onPaySuccess,
  style,
}) {
  if (!data) return null;

  let loading = [];

  for (let i = 0; i < limit; i++) {
    loading = [...loading, <LoadingRow key={`loading-${i}`} />];
  }

  return (
    <table
      className="Table Table--small Table--full"
      style={{
        ...style,
        margin: '0 -30px',
        width: 'calc(100% + 60px)',
      }}
    >
      <thead>
        <tr>
          <th
            className="Table__cell--small"
            style={{
              paddingLeft: '30px',
              paddingTop: '18px',
              paddingBottom: '18px',
            }}
          >
            Vencimento
          </th>
          <th align="right">Pago em</th>
          <th align="right">Preço</th>
          <th align="right">Bônus</th>
          <th align="right">Total</th>
          <th align="left">Situação</th>
        </tr>
      </thead>
      <tbody>
        {isLoading
          ? loading
          : data.map(
              ({
                id,
                due_date,
                received_at,
                price,
                price_bonus,
                price_total,
                receiving_method,
                is_received,
                consultant_id,
                is_chargeback,
                can_delete,
              }) => {
                // Método do pagamento em texto
                const method = getReceivingMethod(receiving_method);

                // Verifica se pode pagar
                const canPay = !is_received && !is_chargeback;

                return (
                  <tr key={id}>
                    <td
                      className="Table__cell--small"
                      style={{ paddingLeft: '30px' }}
                    >
                      {due_date}
                    </td>
                    <td align="right">{received_at || '--'}</td>
                    <td align="right">{price}</td>
                    <td align="right">{price_bonus}</td>
                    <td align="right">{price_total}</td>
                    <td>
                      <WrapperOptions>
                        <Situation
                          method={method}
                          isChargeback={is_chargeback}
                          isReceived={is_received}
                          canPay={canPay}
                        />
                        {canPay && (
                          <PayButton
                            receivingMethod={receiving_method}
                            creditId={id}
                            onPaySuccess={onPaySuccess}
                          />
                        )}
                        {can_delete && (
                          <Delete
                            onClick={onClickDelete(id)}
                            data-tip="Deletar"
                          >
                            <MdDelete />
                          </Delete>
                        )}
                      </WrapperOptions>
                    </td>
                  </tr>
                );
              }
            )}
      </tbody>
    </table>
  );
}

TableCredits.propTypes = {
  data: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    due_date: PropTypes.string,
    price: PropTypes.string,
    price_bonus: PropTypes.string,
    price_total: PropTypes.string,
    is_received: PropTypes.bool,
  }),
  style: PropTypes.object,
  onClickPay: PropTypes.func,
  isLoading: PropTypes.bool,
};

TableCredits.defaultProps = {
  data: [],
  limit: 10,
  style: null,
  onClickPay: null,
  isLoading: false,
};

export default TableCredits;
