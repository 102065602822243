import api from 'services';
import { responseMessage } from 'lib/service-helpers';

const getList = (params) => api.getList('crm/notes', { ...params, sort: null });

export const getNotesPeople = (id, filter) =>
  getList({
    filter: { people_id: id, ...filter },
    include: 'property,user,stage,category',
  });

export const getNotesProperty = (id, filter) =>
  getList({
    filter: { property_id: id, ...filter },
    include: 'people,user,stage,category',
  });

export const create = (values) =>
  api.create('crm/notes', values).then(responseMessage('Nota adicionada'));

export const update = (values) =>
  api
    .patch(`crm/notes/${values.id}/note`, values)
    .then(responseMessage('Nota atualizada'));

export const updatePin = (values) =>
  api
    .patch(`crm/notes/${values.id}/is-pinned`, { is_pinned: values?.is_pinned })
    .then(responseMessage('Nota atualizada'));

export const remove = (id) =>
  api.delete('crm/notes', id).then(responseMessage('Nota removida'));
