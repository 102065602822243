import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
// Components
import Avatar from 'components/Avatar';

function CardItem({ hasAvatar, isSelectable, imageUrl, onClick, children }) {
  return (
    <li className={classnames('CardItem', { isSelectable })} onClick={onClick}>
      {hasAvatar && (
        <Avatar
          text={children}
          size="medium"
          image={imageUrl}
          className="CardItem__image"
        />
      )}
      <div className="CardItem__content">{children}</div>
    </li>
  );
}

CardItem.defaultProps = {
  hasAvatar: true,
  isSelectable: true,
  imageUrl: null,
  onClick: null,
  children: null
};

CardItem.propTypes = {
  hasAvatar: PropTypes.bool,
  isSelectable: PropTypes.bool,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
};

export default CardItem;
