import { Table, Thead } from 'containers/ModalRoot/ModalPayment/styles';
import Users from './components/Users';
import Mailboxes from './components/Mailboxes';
import { Wrapper } from './styles';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';
import { ITEM_TYPES } from 'services/financialv2/contracts';

const TableAdditionalItems = () => {
  const { item } = useModalContractExtra();

  return (
    <Wrapper>
      <Table>
        <Thead>
          <tr>
            <th className="additional-items">Itens adicionais</th>
            <th width="80">Qtd</th>
            <th width="80" className="price">
              Preço
            </th>
            <th width="80" className="total">
              Total
            </th>
          </tr>
        </Thead>
        <tbody>
          {item === ITEM_TYPES.EMAIL && <Mailboxes />}
          {item === ITEM_TYPES.USERS && <Users />}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default TableAdditionalItems;
