import styled from 'styled-components';
import TextOverflow from 'components/TextOverflow';

export const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.borderColor};
  display: flex;
`;

const ItemBase = styled.div`
  padding: 15px 0;
`;

export const Item = styled(ItemBase)`
  flex-shrink: 0;
  min-width: 1px;

  &:not(:first-child) {
    margin-left: 15px;
  }
`;

export const ItemAnnualTaxes = styled(ItemBase)`
  flex-shrink: 0;
  min-width: 1px;

  &:not(:first-child) {
    margin-left: auto;
  }
`;

export const ItemDescription = styled(ItemBase)`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 1px;
  margin-left: 15px;
`;

export const Title = styled.h2`
  font-size: 16px;
  margin-bottom: 2px;
`;
export const HelpText = styled.span`
  font-size: 12px;
  vertical-align: middle;
  display: inline-block;
  color: #979797;
`;
export const Price = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${(p) => p.theme.colors.success};
`;

export const Text = styled.p`
  font-size: 18px;
  color: #787279;
`;
export const Description = styled(TextOverflow)`
  line-height: 22px;
`;
