import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FieldFile } from 'components/Form';
import Button from 'components/Button';
import FieldFile2 from 'components/Form/components/FieldFile2';

const defaultProps = {
  value: null,
};

const propTypes = {
  value: PropTypes.string,
};

const FieldCreci = (props) => {
  return (
    <FieldFile2
      name="image_creci"
      title="Documento do CRECI"
      text="(Carteira ou Certificado PJ)"
    />
  );

  return (
    <Field
      {...props}
      height={173}
      name="image_creci"
      component={FieldFile}
      buttonText="Enviar Foto"
      text={
        <div style={{ whiteSpace: 'nowrap' }}>
          Documento do CRECI
          <br />
          (Carteira ou Certificado PJ)
        </div>
      }
      fileUrlField="creci_name"
      renderButtonChangeImage={() => (
        <Button type="button" color="white" className="DropImage__button">
          Trocar arquivo
        </Button>
      )}
    />
  );
};

FieldCreci.defaultProps = defaultProps;
FieldCreci.propTypes = propTypes;

export default FieldCreci;
