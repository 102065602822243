import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Alert from 'react-s-alert';
// Components
import Button from 'components/Button';
import ListOptions from 'components/ListOptions';
import { ModalFooter } from 'components/Modal';
import FieldCalendar from '../Fields/FieldCalendar';
import FieldMessage from '../Fields/FieldMessage';
import FieldPersonInfo from '../Fields/FieldPersonInfo';
import FieldPrivate from '../Fields/FieldPrivate';
import Form from '../Form';
// Validate
import validate from './validate';

const propTypes = {
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCreatePerson: PropTypes.func.isRequired,

  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  type: null,
  handleClose: null,
  onCreatePerson: null,

  initialValues: null,
  onSubmit: null,
};

class FormShareEmail extends React.Component {
  onSubmitSuccess = ({ person }) => {
    const { openModalPerson } = this.props;
    Alert.success('Imóveis enviados para o e-mail');
    openModalPerson(person);
  };

  render() {
    const {
      type,
      onCreatePerson,
      people,
      handleClose,
      onSubmit,
      initialValues,
    } = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        onSubmitSuccess={this.onSubmitSuccess}
        initialValues={{
          ...initialValues,
          should_send_email: true,
        }}
        validate={validate}
      >
        {({ clearField, change }) => (
          <>
            <Row className="row--divider">
              <Col xs={6} style={{ marginTop: '-20px', paddingTop: '20px' }}>
                <ListOptions
                  style={{
                    width: 'calc(100% + 34px)',
                    marginLeft: '-19px',
                    marginTop: '-20px',
                  }}
                >
                  {!people && (
                    <FieldPersonInfo
                      change={change}
                      type={type}
                      onCreatePerson={onCreatePerson}
                    />
                  )}
                  {/*<FieldOpenDeal clearField={clearField} />*/}
                  <FieldCalendar clearField={clearField} />
                  <FieldPrivate />
                </ListOptions>
              </Col>
              <Col xs={6} style={{ marginTop: '-20px', paddingTop: '20px' }}>
                <FieldMessage height={383} label="Mensagem do e-mail" />
              </Col>
            </Row>
            <ModalFooter withBorder>
              <Button
                type="button"
                color="white"
                colorText="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <div className="h-flex__cell--grow" />
              <Button type="submit" color="success">
                Compartilhar pelo e-mail
              </Button>
            </ModalFooter>
          </>
        )}
      </Form>
    );
  }
}

FormShareEmail.propTypes = propTypes;
FormShareEmail.defaultProps = defaultProps;

export default FormShareEmail;
