import React, { useCallback } from 'react';
// Components
import CellPerson from './components/CellPerson';
import CellProperty from './components/CellProperty';
import CellActions from './components/CellActions';
import ChangeStage from '../ChangeStage';
import ChangeRating from '../ChangeRating';
// Shapes
import { DealShape } from '../../shapes';
// Helpers
import { plural } from 'lib/text';
import { getDurationInDays } from 'lib/date-helpers';
// Styles
import { TableRow } from './styles';

function TableItem({ deal: person, onUpdateDeal }) {
  const getDays = useCallback(() => {
    const _days = getDurationInDays(
      person?.interacted_at,
      'DD/MM/YYYY HH:mm:ss'
    );

    if (_days === 0) {
      return 'Hoje';
    }

    return plural(_days, 'dia', 'dias');
  }, []);

  return (
    <>
      <TableRow>
        <CellPerson
          person={person}
          property={person?.property}
          onShareSuccess={onUpdateDeal}
        />
        <CellProperty
          isStagnant={person.deals[0].is_stagnant}
          property={person.deals[0].property}
        />
        <td>{getDays(person.deals[0])}</td>
        <ChangeStage deal={person.deals[0]} onSuccess={onUpdateDeal} />
        <ChangeRating deal={person.deals[0]} />
        <CellActions
          person={person}
          deal={person.deals[0]}
          onUpdateDeal={onUpdateDeal}
        />
      </TableRow>
      {person.deals.length > 1 &&
        person.deals
          .filter((deal, i) => i >= 1)
          .map((deal) => (
            <TableRow>
              <CellProperty
                isStagnant={deal.is_stagnant}
                property={deal.property}
              />
              <td>{getDays(deal)}</td>
              <ChangeStage deal={deal} onSuccess={onUpdateDeal} />
              <ChangeRating deal={deal} />
              <CellActions
                person={person}
                deal={deal}
                onUpdateDeal={onUpdateDeal}
              />
            </TableRow>
          ))}
    </>
  );
}

TableItem.defaultProps = {
  deal: null,
};

TableItem.propTypes = {
  deal: DealShape,
};

export default TableItem;
