import Button from "components/Button";
import { FieldBool, Input } from "components/Form";
import { ModalFooter } from "components/Modal";
import FieldGroups from "containers/FieldGroups";
import { normalizePhone } from "lib/formHelpers";
import { Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";
import { InstructionsText } from "./styles";
import validate from './validate';

function InvitationForm({ handleClose, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Field
          xs={6}
          required
          label="Nome"
          name="name"
          component={Input}
        />
        <FieldGroups xs={6} required />
      </Row>
      <Row>
        <Field
          xs={6}
          required
          label="Celular/WhatsApp"
          name="cellphone"
          component={Input}
          normalize={normalizePhone}
        />
        <FieldBool
          xs={3}
          label="É corretor de imóveis?"
          name="realtor"
        />
      </Row>

      <InstructionsText>Será enviado automaticamente instruções de cadastro para o WhatsApp informado.</InstructionsText>

      <ModalFooter>
        <Button
          color="white"
          colorText="danger"
          onClick={handleClose}
          className="h-flex__cell--shrink"
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Convidar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'InvitationForm',
  validate,
  enableReinitialize: true,
})(InvitationForm);
