import styled from 'styled-components';
import Image from 'components/Image';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Img = styled(Image)`
  flex-shrink: 0;
  min-width: 0;
  width: 60px;
  height: 60px;
  border-radius: 5px;
`;

export const Content = styled.div`
  margin-left: 10px;
`;
export const Title = styled.div``;
export const Text = styled.div``;
export const Price = styled.div`
  color: ${(p) => p.theme.colors.success};
  font-weight: bold;
`;
