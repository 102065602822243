import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Components
import Wrapper from 'components/Wrapper';
import Link from './components/Link';
// Constants
import { ANIMATION_NAMES } from '../../constants/config';

const defaultProps = {
  type: 'default',
  position: 'bottom',
  isVisible: true,
  animationIn: 'slideInUp',
  animationOut: 'slideOutDown',
  children: '',
  noContainer: false,
  style: {},
};

const propTypes = {
  type: PropTypes.oneOf(['default', 'sticky']),
  position: PropTypes.oneOf(['top', 'bottom']),
  isVisible: PropTypes.bool,
  animationIn: PropTypes.oneOf(ANIMATION_NAMES),
  animationOut: PropTypes.oneOf(ANIMATION_NAMES),
  children: PropTypes.node,
  noContainer: PropTypes.bool,
  style: PropTypes.object,
};

const FixedBarItem = ({ color, children, className, ...props }) => (
  <div
    {...props}
    className={classnames(
      'FixedBar__item h-flex__cell h-flex__cell--shrink',
      className,
      {
        [`FixedBar__item--${color}`]: !!color,
      }
    )}
  >
    {children}
  </div>
);

const Spacer = () => <div className="h-flex__cell h-flex__cell--grow" />;

function FixedBar({
  color = 'white',
  className,
  noContainer,
  position,
  type,
  isVisible,
  style,
  children,
  animationIn,
  animationOut,
}) {
  const [asideWidth, setWidth] = useState(250);

  useEffect(() => {
    document.querySelector('body').classList.add('has-fixed-bar');
    const aside = document.querySelector('.Aside, .FilterAside, .js-aside');

    setWidth(aside?.offsetWidth);

    return () => {
      document.querySelector('body').classList.remove('has-fixed-bar');
    };
  });

  return (
    <>
      <div
        className={classnames(
          className,
          'FixedBar',
          `FixedBar--${type}`,
          `FixedBar--${position}`,
          {
            [`FixedBar--${color}`]: color,
            [`${animationIn}`]: isVisible,
            [`${animationOut}`]: !isVisible,
          }
        )}
        style={{
          ...style,
          left: asideWidth,
        }}
      >
        {noContainer ? (
          children
        ) : (
          <Wrapper.container className="h-flex h-flex--center-center">
            {children}
          </Wrapper.container>
        )}
      </div>
    </>
  );
}

FixedBar.item = FixedBarItem;
FixedBar.link = Link;
FixedBar.spacer = Spacer;
FixedBar.defaultProps = defaultProps;
FixedBar.propTypes = propTypes;

export default FixedBar;
