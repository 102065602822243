import styled from 'styled-components';
import Button from 'components/Button';

export const Btn = styled(Button).attrs(() => ({
  color: 'white',
  colorText: 'secondary'
}))`
  margin-top: 15px;
`;

export const Layout = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  img {
    border: 1px solid transparent;
    border-radius: 4px;
  }

  :hover {
    img {
      border-color: ${p => p.theme.colors.success};
    }
  }
`;
