import React, { useMemo } from 'react';
import Modal from 'react-modal';
import Content from './components/Content';
import { useContract } from 'containers/ModalRoot/ModalUpdateContractDate/hooks/useContract';
import withProvider from 'HOC/withProvider';
import { ModalPaymentProvider, useModalPayment } from './contexts/payment';

function ModalUpdateContractDate({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
}) {
  const { page, isFetchingContract, isLoading } = useModalPayment();
  const { hasError, isSuccess, setHasError, handleSubmit } = useContract();

  const modalSize = useMemo(() => {
    return page === 'Payment' ? 500 : 420;
  }, [page]);

  if (isFetchingContract) return null;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
      style={{ content: { maxWidth: modalSize } }}
    >
      <Content
        initial={{
          receiving_method: '2',
        }}
        hasError={hasError}
        onClose={handleClose}
        isSuccess={isSuccess}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        setHasError={setHasError}
      />
    </Modal>
  );
}

export default withProvider(ModalPaymentProvider)(ModalUpdateContractDate);
