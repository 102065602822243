import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { SIZES } from '../../../../constants/config';

const defaultProps = {
  size: 'large',
  isBordered: false,
  actions: null
};
const propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(SIZES),
  isBordered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node
};

const FormBoxHeader = ({
  isBordered,
  size,
  actions,
  children,
  text,
  style
}) => (
  <header
    className={classnames('FormBox__header', {
      [`FormBox__header--${size}`]: size,
      'FormBox__header--bordered': isBordered
    })}
  >
    <div className="FormBox__title">
      <h4 style={style}>{children}</h4>
      {text && <p>{text}</p>}
    </div>
    {actions && <div className="FormBox__actions">{actions}</div>}
  </header>
);

FormBoxHeader.defaultProps = defaultProps;
FormBoxHeader.propTypes = propTypes;

export default FormBoxHeader;
