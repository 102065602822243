import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { FormLabel } from 'components/Form';
import ListOptions from 'components/ListOptions';
import PropertyItem from 'components/PropertyItem';
import { ModalTemplate } from 'components/Modal';
import styled from 'styled-components';

const Wrapper = styled.textarea`
  width: 100%;
  max-height: 300px;
  overflow: auto;
`;

const Content = styled.div`
  margin-bottom: 20px;
`;

const Note = ({ children }) => {
  return (
    <Wrapper disabled className="FormControl FormControl--textarea">
      {children}
    </Wrapper>
  );
};

const styleCols = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: -20,
  paddingTop: 20,
  paddingBottom: 15,
  marginBottom: '-20px',
};

export default function Preview({ deal, property, handleClose }) {
  return (
    <ModalTemplate title="Detalhes do negócio" handleClose={handleClose}>
      <Row className="row--divider">
        <Col xs={6} style={styleCols}>
          <Row>
            <Col xs={6}>
              <FormLabel>Cliente</FormLabel>
              <Content>{deal?.people?.name || '--'}</Content>
            </Col>
            <Col xs={6}>
              <FormLabel>Corretor</FormLabel>
              <Content>{deal?.user?.name || '--'}</Content>
            </Col>
          </Row>

          <Row>
            <Col xs={4}>
              <FormLabel>Data de entrada</FormLabel>
              <Content>{deal?.entry_at || '--'}</Content>
            </Col>
            <Col xs={4}>
              <FormLabel>Data de saída</FormLabel>
              <Content>{deal?.departure_at || '--'}</Content>
            </Col>
            <Col xs={4}>
              <FormLabel>Data de revisão</FormLabel>
              <Content>{deal?.revision_at || '--'}</Content>
            </Col>
          </Row>

          <Row>
            <Col xs={4}>
              <FormLabel>Tem garantia?</FormLabel>
              <Content>{deal?.has_guarantee ? 'Sim' : 'Não'}</Content>
            </Col>
            <Col xs={4}>
              <FormLabel>Quantos Meses?</FormLabel>
              <Content>{deal?.guarantee_months || '--'}</Content>
            </Col>
            <Col xs={4}>
              <FormLabel>Valor da garantia</FormLabel>
              <Content>{deal?.guarantee_price || '--'}</Content>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <FormLabel>Condomínio Incluso?</FormLabel>
              <Content>{deal?.is_included_condo ? 'Sim' : 'Não'}</Content>
            </Col>
            <Col xs={6}>
              <FormLabel>Preço do Condomínio</FormLabel>
              <Content>{deal?.condominium_price || '--'}</Content>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <FormLabel>IPTU mensal ou anual?</FormLabel>
              <Content>
                {deal?.territorial_tax_type === 2 ? 'Mensal' : 'Anual'}
              </Content>
            </Col>
            <Col xs={6}>
              <FormLabel>Preço IPTU</FormLabel>
              <Content>{deal?.territorial_tax_price || '--'}</Content>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <FormLabel>Preço total mensal</FormLabel>
              <Content>{deal?.price || '--'}</Content>
            </Col>
            <Col xs={6}>
              <FormLabel>Preço da Comissão</FormLabel>
              <Content>{deal?.commission || '--'}</Content>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormLabel>Outras Cobranças</FormLabel>
              <Content>{deal?.taxes_note || '--'}</Content>
            </Col>
            <Col xs={6}>
              <FormLabel>Preço Outras Cobranças</FormLabel>
              <Content>{deal?.taxes_price || '--'}</Content>
            </Col>
          </Row>
        </Col>
        <Col xs={6} style={styleCols}>
          <ListOptions style={{ margin: '-20px -20px -20px -13px' }}>
            <ListOptions.item>
              <Row>
                <Col xs={12}>
                  <FormLabel>Observação</FormLabel>
                  <Note>{deal?.taxes_price}</Note>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormLabel>
                    Observação sobre a remuneração ao proprietário
                  </FormLabel>
                  <Note>{deal?.owner_remuneration_note}</Note>
                </Col>
              </Row>
            </ListOptions.item>
            <ListOptions.item>
              <div style={{ margin: '-20px' }}>
                <PropertyItem canOpenProperty={false} property={property} />
              </div>
            </ListOptions.item>
          </ListOptions>
        </Col>
      </Row>
    </ModalTemplate>
  );
}
