import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
`;

export const Footer = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapImage = styled.div`
  position: relative;
  display: inline-block;
`;
