import React from 'react';
import PropTypes from 'prop-types';
import Timeline from 'components/Timeline';
import Button from 'components/Button';
import { Number } from './styles';

function ItemDNS({ onClick }) {
  return (
    <Timeline.item
      icon={() => <Number>3</Number>}
      iconColor="secondary"
      title="Verifique sua conexão"
      renderText={() => (
        <>
          <p>
            Clique no botão abaixo para verificar se suas configurações de
            domínio foram atualizadas.
          </p>
          <p style={{ marginTop: '5px' }}>
            <strong>Obs:</strong> domínios <strong>.com.br</strong> propagam
            entre <strong>3 e 24 horas</strong>, e domínios{' '}
            <strong>.com</strong> podem levar até <strong>72 horas</strong>.
          </p>
          <Button onClick={onClick} color="success">
            Verificar conexão
          </Button>
        </>
      )}
    />
  );
}

ItemDNS.propTypes = {
  onClick: PropTypes.func
};

ItemDNS.defaultProps = {
  onClick: null
};

export default ItemDNS;
