import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Number, Title, Wrapper } from './styles';

function CardNumber({ title, color, value }) {
  return (
    <ThemeProvider theme={{ color }}>
      <Wrapper>
        <Title>{title}</Title>
        <Number>{value}</Number>
      </Wrapper>
    </ThemeProvider>
  );
}

export default CardNumber;
