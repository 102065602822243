import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
// Components
import Wrapper from '../../components/Wrapper';
import Aside from './components/Aside';
// Rotas
import Routes from './Routes';
import { currentUserSelector, permissionsSelector } from 'modules/login';

const Config = ({ user, permissions }) => (
  <Wrapper full>
    <Helmet>
      <title>Configurações</title>
    </Helmet>
    <Aside user={user} />
    <Wrapper.inner>
      <Routes permissions={permissions} />
    </Wrapper.inner>
  </Wrapper>
);

const mapStateToProps = state => ({
  user: currentUserSelector(state),
  permissions: permissionsSelector(state)
});

export default connect(mapStateToProps)(Config);
