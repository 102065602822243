import React, { useCallback, useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { MdHelp } from 'react-icons/md';
// Components
import Table from 'components/Table';
import TableItem from './TableItem';
// Modules
import { openModalPerson, openModalProperty } from 'modules/modal';

function TableWon({ data }) {
  const dispatch = useDispatch();

  useEffect(() => {
    Tooltip.rebuild();
  }, [data]);

  const handleClickPerson = useCallback(
    (personId) => () => {
      dispatch(openModalPerson({ id: personId }));
    },
    [dispatch]
  );

  const handleClickProperty = useCallback(
    (propertyId) => () => {
      dispatch(openModalProperty({ property: { id: propertyId } }));
    },
    [dispatch]
  );

  return (
    <Table
      noData={{
        title: 'Nenhum negócio ganho',
        text: 'Você não possui nenhum negócio ganho',
      }}
      isFetching={false}
      data={data}
      renderTitle={() => (
        <tr>
          <th>Ganho em</th>
          <th>Corretor</th>
          <th>Cliente</th>
          <th>Imóvel (Ref.)</th>
          <th>Proprietário</th>
          <th width={120} className="h-text-nowrap">
            Dias (abertura - fim){' '}
            <MdHelp
              className="h-color--primary"
              data-tip={`Quantidade de dias que se<br/> passaram desde a abertura<br/> até o fim do negócio`}
            />
          </th>
        </tr>
      )}
      renderItem={(data, i) => (
        <TableItem
          key={`deal-won-${i}`}
          data={data}
          handleClickPerson={handleClickPerson}
          handleClickProperty={handleClickProperty}
        />
      )}
    />
  );
}

TableWon.defaultProps = {};
TableWon.propTypes = {};

export default TableWon;
