import { openModalRecommendationTerms } from 'modules/modal';
import { useDispatch } from 'react-redux';
import FilterRecommendations from '../FilterRecommendations';
import Pix from "../Pix";
import { RecommendationTerms, Wrapper as StyledWrapper } from './styles';

const Wrapper = ({ children }) => (
  <StyledWrapper className="FilterAside">
    {children}
  </StyledWrapper>
);

function AsideRecommendations({ setParams }) {
  const handleSubmit = (values) => setParams(values);

  const dispatch = useDispatch();

  return (
    <Wrapper>
      <div>
        <Pix />
        <FilterRecommendations onSubmit={handleSubmit} />
      </div>
      <RecommendationTerms className="FilterAside__content"> 
        <p>
          Ao recomendar, você concorda com os nossos{' '}
          <span
            className="h-link"
            onClick={() => dispatch(openModalRecommendationTerms())}
            style={{ cursor: 'pointer' }}
          >
            Termos da Recomendação.
          </span>
        </p>
      </RecommendationTerms>
    </Wrapper>
  )
}

export default AsideRecommendations
