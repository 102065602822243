import React from 'react';
import { Field } from 'redux-form';
// Components
import { Input } from 'components/Form';

export default function Title() {
  return (
    <Field
      label="Buscar por"
      name="title"
      placeholder="Digite parte do nome"
      component={Input}
    />
  );
}
