import React, { useEffect, useState } from 'react';
import useReduxForm from 'hooks/useReduxForm';
import Details from './pages/Details';
import Preview from './pages/Preview';
import Payment from './pages/Payment';
import withProvider from 'HOC/withProvider';
import { ModalUserProvider, useModalUser } from './contexts/ModalUserContext';
import PaymentError from 'containers/ModalRoot/ModalContractExtra/components/PaymentError';
import PaymentLoading from 'containers/ModalRoot/ModalContractExtra/components/PaymentLoading';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';
import { PAYMENT_METHODS } from 'constants/constants';

// import { Wrapper } from './styles';

function UserAndEmail() {
  const [canRenderPage, setCanRenderPage] = useState(false);
  const { page } = useModalUser();
  const { hasError, isLoading, quantity } = useModalContractExtra();
  const { dispatch, initialize } = useReduxForm();

  function renderPage() {
    if (!canRenderPage) return null;

    if (hasError) {
      return <PaymentError />;
    }

    if (isLoading) {
      return <PaymentLoading />;
    }

    if (page === 'Details') return <Details />;
    if (page === 'Payment') return <Payment />;
    if (page === 'Preview') return <Preview />;

    return <Details />;
  }

  useEffect(() => {
    dispatch(
      initialize({
        quantity,
        plan: 1,
        installments: 1,
        receiving_method: PAYMENT_METHODS.CREDIT_CARD,
      })
    );

    setCanRenderPage(true);
  }, [dispatch, initialize, quantity]);

  return renderPage();
}

export default withProvider(ModalUserProvider)(UserAndEmail);
