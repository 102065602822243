import React from 'react';
import { connect } from 'react-redux';
// Modules
import { fetchPropertiesForm } from 'modules/components/propertyForms';
// Components
import MainTitle from 'components/MainTitle';
import FormSiteProperties from './components/FormMap';
// Service
import * as mapsService from 'services/sites/maps';

class Properties extends React.Component {
  state = {
    formData: {},
  };

  componentDidMount() {
    // Atualiza dados do formulário
    mapsService.getOne().then(({ data }) => {
      this.setState({ formData: data });
    });
  }

  handleSubmit = (values) => mapsService.update(values);

  render() {
    return (
      <>
        <MainTitle
          title="Mapa"
          text="Defina o conteúdo que será mostrado em seu site"
        />
        <FormSiteProperties
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

const mapDispatchToProps = { fetchPropertiesForm };

export default connect(null, mapDispatchToProps)(Properties);
