import React, { useEffect } from 'react';
import { Checkbox } from 'components/Form';
import { Field } from 'redux-form';
import useFormValue from 'hooks/useFormValue';
import Tooltip from 'react-tooltip';
import { MdInfo } from 'react-icons/md';
import useReduxForm from 'hooks/useReduxForm';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';

const Help = () => {
  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  return (
    <MdInfo
      className="h-color--secondary"
      data-tip="A cobrança ocorrerá<br/>automaticamente neste cartão"
    />
  );
};

const FieldRecurringCharge = () => {
  const { dispatch, change } = useReduxForm();
  const installments = useFormValue('installments');
  const installmentsInt = parseInt(installments, 10);

  const { plan } = useModalPayment();

  useEffect(() => {
    if (installmentsInt > 1) {
      dispatch(change('recurring_charge', null));
    }
  }, [installmentsInt]);

  if (installmentsInt > 1) return <div />;

  return (
    <Field
      type="checkbox"
      name="recurring_charge"
      label={
        <>
          Ativar cobrança automática {plan.planName.toLowerCase()} <Help />
        </>
      }
      component={Checkbox}
    />
  );
};

export default FieldRecurringCharge;
