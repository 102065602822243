import { addCurrency, removeCurrency } from 'lib/money-helpers';

export const getNameTotalArea = (property) => {
  try {
    if (property?.areas?.ground_total_area?.value) return 'ground_total_area';
    if (property?.areas?.total_area?.value) return 'total_area';

    return property?.primary_area?.name;
  } catch {
    return 'private_area';
  }
};

export const getTotalArea = (property) => {
  return getAreaByName(getNameTotalArea(property), property);
};

export const getAreaByName = (name, property) => {
  try {
    return `${property.areas[name].value}${property.areas[name].measure}`;
  } catch {
    return '-';
  }
};

export const getPricePerArea = (areaName, property) => {
  try {
    const price = removeCurrency(property.calculated_price);
    const totalArea = removeCurrency(property.areas[areaName].value);

    return addCurrency(price / totalArea);
  } catch (err) {
    return '-';
  }
};

export const getPriceTotalArea = (property) => {
  return getPricePerArea(getNameTotalArea(property), property);
};
