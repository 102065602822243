import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '../../components/Button';
import Progress from '../../components/Progress';

const propTypes = {
  filesTotal: PropTypes.number,
  filesUploaded: PropTypes.number,
  helperText: PropTypes.node,
  buttonText: PropTypes.node,
  buttonColor: PropTypes.node,
  renderTitle: PropTypes.func,
  handleCancelUpload: PropTypes.func
};

const defaultProps = {
  filesTotal: null,
  filesUploaded: null,
  helperText: (
    <p className="h-color--primary-light h-margin-top--10">
      Favor, aguarde o envio.
    </p>
  ),
  buttonText: 'Cancelar',
  buttonColor: 'danger',
  renderTitle: ({ filesTotal, filesUploaded }) => (
    <p className="h-margin-bottom--15 h-color--primary">
      <strong>
        Enviando {filesUploaded} de {filesTotal} arquivos
      </strong>
    </p>
  ),
  handleCancelUpload: null
};
//

class FilesLoading extends React.Component {
  get percentage() {
    const { filesTotal, filesUploaded } = this.props;

    return Math.round((filesUploaded * 100) / filesTotal);
  }

  render() {
    const {
      filesTotal,
      filesUploaded,
      helperText,
      buttonText,
      buttonColor,
      renderTitle,
      handleCancelUpload
    } = this.props;

    return (
      filesTotal > 0 && (
        <div className="FilesLoading h-text-center">
          <div className="FilesLoading__container">
            {renderTitle(this.props)}
            <Progress
              infinite={filesUploaded === 0}
              percentage={this.percentage}
            />
            <div>{helperText}</div>
            {handleCancelUpload && (
              <div className="h-margin-top--10">
                <Button
                  size="small"
                  color={buttonColor}
                  onClick={handleCancelUpload}
                >
                  {buttonText}
                </Button>
              </div>
            )}
          </div>
        </div>
      )
    );
  }
}

FilesLoading.propTypes = propTypes;
FilesLoading.defaultProps = defaultProps;

export default FilesLoading;
