import React from 'react';
import Alert from 'react-s-alert';
import PropTypes from 'prop-types';
// Components
import Icon from 'components/Icon';

class AlertTemplate extends React.Component {
  static defaultProps = {
    classNames: null,
    styles: {}
  };

  static propTypes = {
    message: PropTypes.node.isRequired,
    classNames: PropTypes.string,
    styles: PropTypes.object,
    customFields: PropTypes.shape({
      confirmText: PropTypes.node
    })
  };

  handleConfirm = () => {
    const { onConfirm } = this.props.customFields;

    if (onConfirm) {
      onConfirm();
    }
    Alert.close(this.props.id);
  };

  render() {
    const {
      id,
      classNames,
      styles,
      message,
      customFields: { confirmText }
    } = this.props;

    return (
      <div className={classNames} id={id} style={styles}>
        <div className="s-alert-box-inner">
          <div className="h-flex__cell h-flex__cell--grow">{message}</div>
          {confirmText && (
            <div className="h-flex__cell h-flex__cell--shrink">
              <button onClick={this.handleConfirm} className="s-alert__link">
                {confirmText}
              </button>
            </div>
          )}
        </div>
        <span className="s-alert-close" onClick={this.props.handleClose}>
          <Icon name="close" />
        </span>
      </div>
    );
  }
}

export default AlertTemplate;
