import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { MdCheckCircle, MdRadioButtonUnchecked } from 'react-icons/md';

function RadioCard({ title, isSelected, component, onClick }) {
  return (
    <div
      className={classnames('RadioCard', {
        isSelected
      })}
      onClick={onClick}
    >
      {isSelected ? (
        <MdCheckCircle
          size="15px"
          color="#01AFAD"
          className="RadioCard__icon"
        />
      ) : (
        <MdRadioButtonUnchecked size="15px" className="RadioCard__icon" />
      )}
      <div className="RadioCard__title">{title}</div>
      {component && (
        <>
          <span className="h-flex__cell--grow" />
          {component({ isSelected })}
        </>
      )}
    </div>
  );
}

RadioCard.propTypes = {
  title: PropTypes.node,
  isSelected: PropTypes.bool,
  component: PropTypes.func,
  onClick: PropTypes.func
};

RadioCard.defaultProps = {
  title: 'Sem título',
  isSelected: false,
  component: null,
  onClick: null
};

export default RadioCard;
