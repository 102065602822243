import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip';

function TextOverflow({
  component: Component,
  children,
  width,
  style,
  className,
  onClick,
}) {
  const element = useRef();
  const [hasTooltip, setHasTooltip] = useState(false);
  const [propsTooltip, setPropsTooltip] = useState(null);

  useEffect(() => {
    const { offsetWidth, scrollWidth } = element?.current;

    if (width === offsetWidth || scrollWidth > offsetWidth) {
      setHasTooltip(true);
    }

    return () => {
      Tooltip.hide(element.current);
    };
  }, [element, setHasTooltip, width]);

  useEffect(() => {
    if (hasTooltip) {
      setPropsTooltip({
        'data-tip': children,
        'data-effect': 'solid',
      });
    }
  }, [setPropsTooltip, hasTooltip, children]);

  useEffect(() => {
    Tooltip.rebuild();
  }, [hasTooltip, propsTooltip]);

  return (
    <>
      <Component
        {...propsTooltip}
        onClick={onClick}
        ref={element}
        className={classnames(className, 'h-text-overflow')}
        style={{ maxWidth: width, ...style }}
      >
        {children}
      </Component>
    </>
  );
}

TextOverflow.propTypes = {
  component: PropTypes.string,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
};

TextOverflow.defaultProps = {
  component: 'span',
  children: null,
  width: '100%',
  style: null,
};

export default TextOverflow;
