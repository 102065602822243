import React, { useCallback, useState } from 'react';

export const DEFAULT_PAGES = [
  {
    name: 'Informações',
    path: '/informations',
    section: 'informations',
    isHidden: false,
    isDisabled: false,
    hasError: false,
    isFirstPage: true,
  },
  {
    name: 'Imagens',
    path: '/images',
    isHidden: false,
    isDisabled: true,
    hasError: false,
    isFirstPage: false,
  },
];

export const AsideContext = React.createContext({
  pages: DEFAULT_PAGES,
  hide: null,
  show: null,
  disable: null,
  enable: null,
  enableAll: null,
  reset: null,
  permissions: [],
});

export const AsideProvider = ({ children }) => {
  const [pages, setPages] = useState(DEFAULT_PAGES);

  const setPage = useCallback(
    (pageName, values) => {
      setPages((prevPages) => {
        return prevPages.map((page) => {
          if (page.name === pageName) {
            return {
              ...page,
              ...values,
            };
          }

          return page;
        });
      });
    },
    [setPages]
  );

  // Esconde uma página
  const hide = useCallback(
    (pageName) => setPage(pageName, { isHidden: true }),
    [setPage]
  );

  // Mostra uma pagina
  const show = useCallback(
    (pageName) => setPage(pageName, { isHidden: false }),
    [setPage]
  );

  const disable = useCallback(
    (pageName) => setPage(pageName, { isDisabled: true }),
    [setPage]
  );

  const enable = useCallback(
    (pageName) => setPage(pageName, { isDisabled: false }),
    [setPage]
  );

  const enableAll = useCallback(
    () =>
      setPages((prevPages) => {
        return prevPages.map((page) => ({ ...page, isDisabled: false }));
      }),
    [setPages]
  );

  // Reseta para o state inicial
  const reset = useCallback(() => {
    setPages(DEFAULT_PAGES);
  }, []);

  return (
    <AsideContext.Provider
      value={{
        pages,
        hide,
        show,
        disable,
        enable,
        reset,
        enableAll,
      }}
    >
      {children}
    </AsideContext.Provider>
  );
};
