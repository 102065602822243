import styled from 'styled-components';

export const Price = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const Link = styled.a`
  font-size: 12px;
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: underline;
`;

export const Icon = styled.div`
  width: 36px;
  height: 36px;
  background: #dadada;
  border-radius: 3px;
  color: #1c0c1e;
  display: grid;
  place-items: center;
`;

export const AccountInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
`;

export const Text = styled.p`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 12px;
  white-space: nowrap;
  color: #666666;
`;

export const Situation = styled.div`
  display: inline-block;
  padding: 3px 5px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #1c0c1e;
  border-radius: 2px;
`;
