import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useRequest } from 'hooks/useRequest';
import {
  selectedPropertiesSelector,
  toggleSelected,
} from 'modules/propertySearch';
import { Actions as SelectedsActions } from 'modules/selecteds';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as propertiesSearch from 'services/properties';
import * as portalsService from 'services/settings/portals';

export const useProperties = ({
  selectedOption,
  selectedPortal,
  params,
  fetchPortals,
}) => {
  const selectedProperties = useSelector(selectedPropertiesSelector);
  const dispatch = useDispatch();

  const { data, fetchData, isFetching, meta } = useRequest({
    request: propertiesSearch.searchPropertiesCondo,
    initialFetch: false,
  });

  /**
   * Busca os imóveis
   */
  const fetchProperties = (params) => {
    if (params?.filter) {
      fetchData({
        filter: {
          ...params.filter,
          doesnt_have_portal_real_estate_id: params.portalRealEstateId,
        },
        is_portals_filter: true,
        include: 'condominium',
        sort: params.sort,
        offset: params.offset,
        limit: params.limit,
      });
    }

    if (!params?.filter && params?.filter_portals?.by_portal_option_id) {
      fetchData({
        filter: {
          by_portal_option_id: params.filter_portals.by_portal_option_id,
          on_network: ['self', 'orulo', 'dwv'],
        },
        is_portals_filter: true,
        include: 'condominium',
        sort: params.sort,
        offset: params.offset,
        limit: params.limit,
      });
    }
  };

  const handleRemove = (property) => () => {
    dispatch(
      openConfirmation({
        size: 260,
        title: `Remover Imóvel`,
        text: (
          <>
            Deseja remover o imóvel de
            <br /> referência <strong>{property.reference}</strong> do{' '}
            <strong>{selectedOption.name}</strong>?
          </>
        ),
        request: () => {
          return portalsService.uncheckOne({
            portalRealEstateId: selectedPortal.id,
            propertyId: property.id,
          });
        },
        onSuccess: () => {
          fetchProperties(params);
          fetchPortals();
        },
      })
    );
  };

  const handleRemoveAll = () => {
    dispatch(
      openConfirmation({
        title: 'Remoção de imóveis',
        text: (
          <>
            Deseja remover todos os imóveis do{' '}
            <strong>{selectedOption.name}</strong>?
          </>
        ),
        request: () => {
          return portalsService.uncheckAllByPortalId({
            portalRealEstateId: selectedPortal.id,
            portalOptionId: selectedOption.id,
          });
        },
        onSuccess: () => {
          fetchProperties(params);
          fetchPortals();
        },
      })
    );
  };

  const properties = useMemo(() => {
    try {
      return data.map((p) => {
        if (selectedProperties?.includes(p?.id)) {
          return { ...p, isChecked: true };
        }

        return { ...p, isChecked: false };
      });
    } catch (err) {
      console.error('Erro ao marcar os imóveis selecionados: ', err);
      return [];
    }
  }, [data, selectedProperties]);

  const toggleSelectedProperty = (id) => {
    dispatch(toggleSelected(id));
  };

  const clearSelectedProperties = () => {
    dispatch(SelectedsActions.resetSelecteds('properties_search'));
  };

  return {
    properties,
    isFetching,
    meta,
    fetchData,
    fetchProperties,
    handleRemove,
    handleRemoveAll,
    selectedProperties,
    toggleSelectedProperty,
    clearSelectedProperties,
  };
};
