import { colors } from 'constants/styles';
import hexColorRegex from 'hex-color-regex';

export const isValidHexColor = color => {
  return hexColorRegex({ strict: true }).test(color);
};

export const getColor = color => {
  try {
    if (color.includes('#')) return color;
    return colors[color];
  } catch (err) {
    return colors.primary;
  }
};

window.isValidHexColor = isValidHexColor;
