import React, { useEffect } from 'react';

// Components
import WrapperReport from '../../components/WrapperReport';
import Chart from './components/Chart';
import Table from './components/Table';
import Form from './components/Form';

// Hooks
import { useReport } from '../hooks';

// Services
import { getPeopleChannelByTypes } from 'services/reportsPeople';

function PeopleByChannelAndType() {
  const {
    data,
    chartData,
    fetchData,
    toggle,
    isFetching,
    meta,
    isAllSelected,
    toggleAll,
    dataSelecteds
  } = useReport({
    request: getPeopleChannelByTypes,
    key: 'id'
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <WrapperReport
      title="Origem da captação e tipo de cliente"
      text="Veja quais origens de captação trouxeram mais clientes."
      data={data}
      isFetching={isFetching}
      renderForm={() => <Form onSubmit={fetchData} />}
      renderChart={() => <Chart data={chartData} meta={meta} />}
      renderTable={() => (
        <Table
          isAllSelected={isAllSelected}
          toggleAll={toggleAll}
          data={dataSelecteds}
          handleToggle={toggle}
          meta={meta}
        />
      )}
    />
  );
}

export default PeopleByChannelAndType;
