import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ModalClose from './ModalClose';
import ModalTitle from './ModalTitle';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';

const defaultProps = {
  style: null,
  title: null,
  titleActions: null,
  children: null,
  className: null,
  footerActions: null,
  isLoading: false,
};

const propTypes = {
  style: PropTypes.object,
  title: PropTypes.node,
  titleActions: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  footerActions: PropTypes.node,
  handleClose: PropTypes.func,
  isLoading: PropTypes.bool,
};

const ModalTemplate = ({
  style,
  title,
  text,
  isLoading,
  className,
  titleActions,
  handleClose,
  footerActions,
  children,
  renderHeader,
}) => (
  <div
    style={style}
    className={classnames('Modal__wrapper', className, {
      'Modal--loading': isLoading,
    })}
  >
    <ModalClose handleClose={handleClose} />
    {renderHeader
      ? renderHeader()
      : title && (
          <ModalTitle text={text} actions={titleActions}>
            {title}
          </ModalTitle>
        )}
    <ModalContent>
      {children}
      <ModalFooter>{footerActions}</ModalFooter>
    </ModalContent>
  </div>
);

ModalTemplate.defaultProps = defaultProps;
ModalTemplate.propTypes = propTypes;

export default ModalTemplate;
