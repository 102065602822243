import React from 'react';

export default function withProvider(Provider, options) {
  return Component => {
    function WithProvider(props) {
      return (
        <Provider {...options}>
          <Component {...props} />
        </Provider>
      );
    }

    // Não deixa o componente se re-renderizar
    // apenas renderiza quando muda alguma prop do componente
    return React.memo(WithProvider);
  };
}
