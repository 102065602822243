import React from 'react';
import ErrorScreen from './ErrorScreen';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      // Você pode renderizar qualquer UI alternativa
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}
