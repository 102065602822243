import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 25px;
  padding-top: 25px;

  & + & {
    border-top: 1px solid ${(p) => p.theme.borderColor};
  }
`;

export const Content = styled.div`
  display: flex;

  & + & {
    margin-top: 15px;
  }
`;

export const Observation = styled.div`
  flex: 1 0;

  p {
    white-space: pre-line;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Spacer = styled.tr`
  td {
    height: 20px;
  }
`;

export const Table = styled.table`
  width: 320px;

  td {
    height: 17px;
  }

  td:first-child {
    text-align: right;
  }

  td:last-child {
    font-weight: bold;
  }
`;

export const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
`;
