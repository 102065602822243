import React from 'react';
import FieldCondominium from 'containers/FieldCondominium';
import Button from 'components/Button';
import { reduxForm } from 'redux-form';
import * as units from 'services/buildings/units';

const Form = ({ handleSubmit }) => {
  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();

        handleSubmit(e);
      }}
    >
      <FieldCondominium label="Importar unidades" />
      <Button type="submit" color="success" block>
        Importar unidades
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'ImportCondo',
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    return units.addUnityByCondo({
      buildingId: props?.enterpriseId,
      condo_id: values?.condominium_id
    });
  }
})(Form);
