import React, { useEffect } from 'react';
import Wrapper from 'components/Wrapper';
import Filter from './components/Filter';
import { PortalOptionsProvider, usePortalOptions } from './context';
import { useQuery } from 'hooks/useQuery';
import PropertiesPortal from './components/PropertiesPortal';
import PropertiesFilter from './components/PropertiesFilter';
import withProvider from 'HOC/withProvider';

function PortalOptionSelect() {
  const params = useQuery({ withParams: true });
  const { conditions, fetchProperties } = usePortalOptions();

  useEffect(() => {
    fetchProperties(params);
  }, [params]);

  return (
    <Wrapper full>
      <Filter />
      <Wrapper.inner>
        {conditions.isSearchingByFilter ? (
          <PropertiesFilter />
        ) : (
          <PropertiesPortal />
        )}
      </Wrapper.inner>
    </Wrapper>
  );
}

export default withProvider(PortalOptionsProvider)(PortalOptionSelect);
