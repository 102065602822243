import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Modules
import { fetchTypeAndSubtypes, typesAndSubtypesSelectors } from './module';
// Components
import { TypeAndSubtype } from 'components/PropertyFilters';

function TypeAndSubtypesContainer({ change, isFetching }) {
  const dispatch = useDispatch();
  const types = useSelector(typesAndSubtypesSelectors.getAll);

  const onChange = useCallback(() => {
    change('situation_id', null);
    change('by_characteristic_id', null);
  }, [change]);

  useEffect(() => {
    dispatch(fetchTypeAndSubtypes());
  }, [dispatch]);

  return (
    <TypeAndSubtype
      isFetching={isFetching}
      options={types}
      onChange={onChange}
    />
  );
}

export default TypeAndSubtypesContainer;
