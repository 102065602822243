import React from 'react';
// Components
import ItemNav from '../../ItemNav';
import Can from 'containers/Can';
import { useLocation } from 'react-router';

function MenuReports() {
  const location = useLocation();

  // Verifica se a URL começa exatamente com "/reports" e não inclui outra rota antes
  const isActive = () => location.pathname.startsWith('/reports/');

  return (
    <Can run="VIEW_REPORTS">
      <ItemNav label="Relatórios" to="/reports" handleActive={isActive} exact />
    </Can>
  );
}

export default MenuReports;
