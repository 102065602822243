import React from 'react';
import { Select } from 'components/Form';
import { Field } from 'redux-form';
// import { Container } from './styles';

const Transaction = () => {
  return (
    <Field
      xs={3}
      name="transaction"
      label="Transação"
      component={Select}
      options={[
        { label: 'Venda', value: 1 },
        { label: 'Aluguel', value: 2 },
        { label: 'Temporada', value: 3 }
      ]}
      format={value => {
        if (!value) return;
        return parseInt(value, 10);
      }}
    />
  );
};

export default Transaction;
