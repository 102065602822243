import React from 'react';
import { Field } from 'redux-form';
// Components
import { CarouselSelect } from 'components/Form';
// Services
import * as galleryService from 'services/sites/gallery';
// Hooks
import { useRequest } from 'hooks/useRequest';

export default function FieldGallery() {
  const { data } = useRequest({
    request: galleryService.getComponents,
    params: []
  });

  return (
    <Field
      name="gallery_popup_type"
      options={data}
      component={CarouselSelect}
    />
  );
}
