import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import { useRequest } from 'hooks/useRequest';
import { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import * as partnersService from 'services/sites/partners';
import ConfigPartners from './components/ConfigPartners';
import PartnersList from './components/PartnersList';

function Posts({ handleSubmit, initialize }) {
  const [isUpdated, setIsUpdated] = useState(false);
  const { data, fetchData } = useRequest({
    request: partnersService.getOneConfig,
  });

  useEffect(() => {
    if (!isUpdated && data.is_partners_shown !== undefined) {
      setIsUpdated(true);

      initialize({
        is_partners_shown: data.is_partners_shown,
        partners_title: data.partners_title,
      });
    }
  }, [data, isUpdated]);

  return (
    <Wrapper component="form" onSubmit={handleSubmit}>
      <MainTitle
        title="Parceiros"
        text="Crie um slide de parceiros no seu site"
      />
      <ConfigPartners />
      <PartnersList data={data.partners} onUpdate={fetchData} />
    </Wrapper>
  );
}

Posts.propTypes = {};
Posts.defaultProps = {};

export default reduxForm({
  form: 'PartnersForm',
  onSubmit: (values) => {
    return partnersService.updateConfig(values);
  },
})(Posts);
