import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  cursor: move;

  .nestable-item {
    margin-top: 0;

    .nestable-list {
      margin-top: 0px;
    }
  }
`;
