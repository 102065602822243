import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Alert from 'react-s-alert';
// Components
import { SelectMultiple } from 'components/Form';
// Services
import * as typeService from 'services/types';
// Helpers
import { parseSelect } from 'lib/formHelpers';

class StickedOption extends React.PureComponent {
  handleMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  };
  handleMouseEnter = event => {
    this.props.onFocus(this.props.option, event);
  };
  handleMouseMove = event => {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  };

  get className() {
    const { option, className } = this.props;
    return classnames('help', className, {
      'Select--isGroup': option.is_type
    });
  }

  render() {
    return (
      <div
        className={this.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.title}
      >
        {this.props.children}
      </div>
    );
  }
}

class FieldTypeAndSubtype extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: props.options || [],
      isLoading: props.isLoading || []
    };
  }

  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    component: PropTypes.func.isRequired,
    params: PropTypes.object
  };

  static defaultProps = {
    multi: false,
    label: 'Tipo e Subtipo',
    name: 'by_type_or_subtype_id',
    labelKey: 'title',
    valueKey: 'id',
    component: SelectMultiple,
    parse: parseSelect('id'),

    params: { sort: 'title' }
  };

  componentDidMount() {
    const { params } = this.props;
    this.fetchData(params);
  }

  fetchData = async params => {
    try {
      this.setState({ isLoading: true });
      const { data: options } = await typeService.getGroupedTypesSubtypes(
        params
      );
      this.setState({ options, isLoading: false });
      return options;
    } catch {
      const _error = 'Erro ao buscar tipos e subtipos';
      Alert.error(_error);
      return Promise.resolve({ _error });
    }
  };

  get options() {
    const { mapOptions } = this.props;

    if (mapOptions) {
      return this.state.options.map(mapOptions);
    }

    return this.state.options;
  }

  render() {
    const { isLoading } = this.state;

    // Remove as opções para não ir no field
    const { creatable, onCreatePerson, params, ...props } = this.props;

    return (
      <Field
        multi={false}
        component={SelectMultiple}
        parse={parseSelect('id')}
        optionComponent={StickedOption}
        optionRenderer={option => {
          if (option.is_type) {
            return (
              <div
                style={{
                  padding: 12,
                  margin: '-8px -10px',
                  background: '#f3f6fa'
                }}
              >
                {option[this.props.labelKey]}
              </div>
            );
          }

          return (
            <div className={option.is_subtype && 'h-margin-left--15'}>
              {option[this.props.labelKey]}
            </div>
          );
        }}
        options={this.options}
        isLoading={isLoading}
        {...props}
      />
    );
  }
}

export default FieldTypeAndSubtype;
