import { useMemo, useState, useCallback } from 'react';
import { filterSelected, toggleSelected } from './helpers';
import { useRequest } from 'hooks/useRequest';
import { getColorByIndex } from 'lib/chart-helpers';

export function useReport({ request, key = 'id' }) {
  const { isFetching, fetchData, data, meta } = useRequest({
    request,
    initialFetch: false
  });

  const [selected, setSelected] = useState([]);

  const dataWithColor = useMemo(() => {
    setSelected([]);

    return data.map((i, index) => ({
      ...i,
      color: getColorByIndex(index)
    }));
  }, [data]);

  const dataSelecteds = useMemo(() => {
    return dataWithColor.map(item => {
      if (selected.includes(item[key])) {
        return { ...item, isSelected: true };
      }

      return { ...item, isSelected: false };
    });
  }, [dataWithColor, selected]);

  const chartData = filterSelected(dataSelecteds, selected);

  const handleSubmit = useCallback(
    ({ start_date, end_date }) => {
      fetchData({ start_date, end_date });
    },
    [fetchData]
  );

  const handleToggleChecked = useCallback(
    item => () => {
      toggleSelected(item, selected, setSelected, key);
    },
    [selected, setSelected, key]
  );

  const isAllSelected = useMemo(() => {
    return data.length === selected.length;
  }, [data, selected]);

  const handleToggleAll = useCallback(() => {
    if (isAllSelected) {
      setSelected([]);
    } else {
      setSelected(data.map(item => item[key]));
    }
  }, [data, selected, setSelected, key]);

  return {
    data: dataWithColor,
    dataSelecteds: dataSelecteds,
    meta,
    selected,
    chartData,
    isFetching,
    fetchData,
    isAllSelected,
    toggle: handleToggleChecked,
    toggleAll: handleToggleAll,
    submit: handleSubmit
  };
}
