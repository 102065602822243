import qs from 'qs';
import { IMAGE_SIZES } from '../constants/config';
import _ from 'lodash';

export { IMAGE_SIZES };

/**
 * Verifica se o tamanho de imagem é valido
 * @param size
 */
export const isValidImageSize = (size) =>
  Object.keys(IMAGE_SIZES).includes(size);

/**
 * Retorna o tamanho da imagem do órulo
 * @param size
 * @returns {string}
 */
export function getOruloSize(size) {
  switch (size) {
    case 'small':
      return 'thumb';
    case 'medium':
      return 'featured_modern_without_watermark';
    default:
      return 'large';
  }
}

/**
 * Procura a interpolação {SIZE} dentro da url e substitui pelo tamanho passado pelo parametro size
 * @return {boolean}||{String}
 * @param url
 * @param size
 * @param type
 * @param params
 */
//https://s3.amazonaws.com/uploaded.prod.corretordireto/images/properties/{orulo_size}/204872.jpg?1565817272
export const imageChangeSize = (
  url,
  size = 'medium',
  type = 'outside',
  params
) => {
  // console.log('url: ', url);
  let currentUrl = url;

  if (_.isObject(url)) {
    try {
      if (size in url) {
        return url[size];
      }

      return url.large;
    } catch {
      return url.large;
    }
  }

  if (!url) return '';

  // adiciona os parametros no fim da url da imagem
  if (params) currentUrl += qs.stringify(params);

  // size = 'original';

  type = '/outside';

  if (size === 'original') {
    type = '';
  }

  // Verifica se a url é do orulo
  const isOrulo = /{orulo_size}/.test(url);

  if (isOrulo) {
    const oruloSize = getOruloSize(size);
    return currentUrl.replace(/{orulo_size}/, oruloSize);
  }

  return currentUrl
    .replace(/\/{size}/, IMAGE_SIZES[size])
    .replace(/\/{type}/, type);
};

/**
 * Muda o tamanho das imagens dentro de um array
 * @param array|Array - array de imagens
 * @param size|string - tamanho da imagem
 * @param type|string - tipo do resize da imagem
 * @param params|Object - parametros adicionais
 * @param keyName|String - chave do objeto aonde se encontra a url da imagem
 */
export const imagesChangeSize = (
  array,
  size,
  type,
  params,
  keyName = 'file_url'
) =>
  array.map((ar) => ({
    ...ar,
    [keyName]: imageChangeSize(ar[keyName], size, type, params),
  }));
