import { createContext, useContext, useState } from "react";

export const FormContext = createContext({
  page: null,
  setPage: () => null,
});

export const FormProvider = ({ children }) => {
  const [page, setPage] = useState('details');

  return (
    <FormContext.Provider
      value={{
        page,
        setPage,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

export const useForm = () => useContext(FormContext);
