import { useHistory } from "react-router";
import TableLocations from "../TableLocations";
import { handleRemoveCity, locationsSelector } from "../../module";
import { useDispatch, useSelector } from "react-redux";
import { imageChangeSize } from "lib/image-helpers";
import noImage from 'assets/img/no-image.png';
import { Image, Location } from "./styles";
import Button from "components/Button";
import Nodata from "components/Nodata";
import search from 'assets/img/noData/search.svg';

function Cities() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { cities } = useSelector(locationsSelector);

  function handleEdit(id) {
    history.push(`/locations/update/city/id/${id}`);
  }

  function handleDelete(id) {
    dispatch(handleRemoveCity(id));
  }

  return (
    <TableLocations title="Cidades">
      {cities.length > 0 ? (
        <table className="Table">
          <tbody>
            {cities.map((item) => {
              const coverUrl = item.cover_photo?.file_url 
                ? imageChangeSize(item.cover_photo.file_url, 'medium', 'outside', {})
                : noImage

              return (
                <tr key={item.id}>
                  <td>
                    <Location>
                      <Image size="small" src={coverUrl} />
                      <span>{item.city.name} ({item.city.state.name})</span>
                    </Location>
                  </td>
                  <td className="Table__cell--small">
                    <Button
                      to={`/locations/update/city/images/${item.city.id}`}
                      color="white"
                      colorText="primary"
                    >
                      Fotos
                    </Button>
                    <Button
                      onClick={() => handleEdit(item.city.id)}
                      color="white"
                      colorText="primary"
                    >
                      Editar
                    </Button>
                    <Button
                      onClick={() => handleDelete(item.city.id)}
                      color="danger"
                    >
                      Excluir
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Nodata
          image={search}
          title="Nenhuma cidade cadastrada"
          text="Descreva sobre uma cidade para enriquecer o SEO"
        />
      )}
    </TableLocations>
  )
}

export default Cities;
