import React, { useCallback, useState, useEffect, useRef } from 'react';
import './quillConfigs';
import ReactQuill from 'react-quill';

function Complex({
  autoFocus,
  disabled,
  input,
  customToolbar: CustomToolbar,
  style,
  onFocus,
  onBlur,
  children,
  position = 'bottom',
  getRef,
  onChange,
  ...props
}) {
  const textArea = useRef();
  const [value, setValue] = useState('');
  const [timeout, setTimeoutValue] = useState(0);

  useEffect(() => {
    setValue(input.value);

    if (textArea?.current && autoFocus) {
      textArea.current.focus();
    }
  }, [textArea, autoFocus, input.value]);

  useEffect(() => {
    if (getRef) getRef(textArea);
  }, [textArea]);

  /**
   * Verifica se a textarea está vazia
   * @type {(...args: any[]) => any}
   */
  const isEmpty = useCallback(value => {
    if (!value.trim()) {
      setValue('');
      input.onChange('');
      return true;
    }
    return false;
  }, []);

  /**
   * Lida com o evento de change do ReactQuill
   * @type {(...args: any[]) => any}
   */
  const handleChange = useCallback(
    (newValue, delta, source, quill) => {
      //if (onChange) onChange(newValue, delta, source, quill);
      if (disabled) return null;
      if (source === 'user') {
        if (isEmpty(quill.getText())) {
          setValue('');
          return false;
        }
        setValue(newValue);

        setTimeoutValue(
          setTimeout(() => {
            input.onChange(newValue);
          }, 500)
        );

        clearTimeout(timeout);
      }
    },
    [disabled, input, timeout]
  );

  /**
   * Lida com o evento quando sai do ReactQuill
   * @type {(...args: any[]) => any}
   */
  const handleBlur = useCallback(
    (range, source, quill) => {
      if (disabled) return null;
      if (onBlur) onBlur(range, source, quill);
      if (isEmpty(quill.getText())) return false;
      input.onBlur(quill.getHTML());
    },
    [disabled, input]
  );

  return (
    <>
      <ReactQuill
        {...props}
        ref={textArea}
        readOnly={disabled}
        value={value}
        style={style}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={onFocus}
      />
      {CustomToolbar && <CustomToolbar>{children}</CustomToolbar>}
    </>
  );
}

Complex.defaultProps = {};
Complex.propTypes = {};

export default React.memo(Complex);
