import React, { useEffect } from 'react';
import { Aside, Content, Wrapper } from './styles';
import MainTitle from 'components/MainTitle';
import CreditUntil from './components/CreditUntil';
import CreditCards from './components/CreditCards';
import FinancialOwner from './components/FinancialOwner';
import TaxData from './components/TaxData';
import RemoveAccount from './components/RemoveAccount';
import ListContracts from './components/ListContracts';
import { useDispatch } from 'react-redux';
import { fetchCurrentContract } from 'modules/financial/currentCredit';

function PlanHistory() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrentContract());
  }, []);

  return (
    <Wrapper>
      <Content>
        <MainTitle
          title="Contratos e financeiro"
          text="Analise de contratos e pagamentos"
        />
        <ListContracts />
      </Content>
      <Aside>
        <CreditUntil />
        {/*<Charges />*/}
        <CreditCards />
        <FinancialOwner />
        <TaxData />
        <RemoveAccount />
      </Aside>
    </Wrapper>
  );
}

export default PlanHistory;
