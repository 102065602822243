import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const PeopleChart = ({ width, height, data, colors, toggleChecked }) => (
  <div style={{ width, height }}>
    <ResponsiveContainer>
      <BarChart width={600} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="period" allowDuplicatedCategory={false} />
        <YAxis dataKey="value" />
        <Tooltip cursor={{ fill: '#f0f2f5' }} />
        {data.map((dat, index) => (
          <Bar
            dataKey="value"
            data={dat.data}
            name={dat.type}
            key={dat.type}
            fill={dat.color}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default PeopleChart;
