import React, { useCallback } from 'react';
import * as Error from './styles';
import Button from 'components/Button';
import errorImg from './frontend-error.svg';

export default function ErrorScreen() {
  const handleClickReload = useCallback(() => {
    window.location = '/';
  }, []);

  return (
    <Error.Wrapper>
      <Error.TextWrapper>
        <h1>Ops, algo está errado.</h1>
        <p>
          Se você está vendo essa página, algo de errado aconteceu no sistema.
          Tente recarregar a página novamente, e se persistir, fique a vontade
          para{' '}
          <a href="#test" rel="noreferrer" className="h-link">
            entrar em contato
          </a>{' '}
          com o suporte.
        </p>
        <Button color="success" onClick={handleClickReload}>
          Recarregar a página
        </Button>
      </Error.TextWrapper>
      <Error.ImageWrapper>
        <img src={errorImg} alt="Error front-end" />
      </Error.ImageWrapper>
    </Error.Wrapper>
  );
}
