import React from 'react';
import { MdCheckCircle } from 'react-icons/md';

export default function BoxResources() {
  return (
    <div className="BoxPlan BoxPlan--left">
      <header className="BoxPlan__header">
        <h1 className="BoxPlan__title">Recursos inclusos</h1>
      </header>
      <ul className="BoxPlan__list">
        <li>
          <MdCheckCircle /> Site
        </li>
        <li>
          <MdCheckCircle /> Site com hospedagem
        </li>
        <li>
          <MdCheckCircle /> CRM completo
        </li>
        <li>
          <MdCheckCircle /> Marca D’água
        </li>
        <li>
          <MdCheckCircle /> Relatórios
        </li>
        <li>
          <MdCheckCircle /> Imóveis ilimitados
        </li>
        <li>
          <MdCheckCircle /> Emails ilimitados
        </li>
        <li>
          <MdCheckCircle /> Integração com portais
        </li>
        <li>
          <MdCheckCircle /> SSL para domínio
        </li>
        <li>
          <MdCheckCircle /> Permissões/Privacidade
        </li>
        <li>
          <MdCheckCircle /> Aplicativo Android/iOS
        </li>
      </ul>
    </div>
  );
}
