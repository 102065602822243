import styled from 'styled-components';
import iconConfetti from './assets/icon-confetti.svg';
import iconBalloon from './assets/icon-balloon.svg';

export const Wrapper = styled.div`
  position: relative;
`;

export const WrapperInner = styled.div`
  position: relative;
  display: flex;
  background-color: #fff;
  padding: 50px;
  border-radius: ${p => p.theme.borderRadius};
  overflow: hidden;
  align-items: center;
`;

export const Text = styled.div`
  margin-right: 40px;
  width: 240px;

  h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    color: ${p => p.theme.colors.primary};
    margin-bottom: 15px;
  }
`;

export const Confetti = styled.img.attrs(() => ({
  src: iconConfetti
}))`
  position: absolute;
  top: -5px;
  left: -10px;
`;

export const Balloon = styled.img.attrs(() => ({
  src: iconBalloon
}))`
  position: absolute;
  bottom: -25px;
  right: 15px;
`;

export const MainImage = styled.img`
  flex: 1 0;
`;
