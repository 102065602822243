import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Wrapper as MenuItem } from '../MenuItem/styles';

export const Wrapper = styled(motion.nav).attrs(() => ({
  initial: { opacity: 0, x: -50 },
  animate: {
    opacity: 1,
    x: 0
  },
  exit: { opacity: 0 }
}))`
  header {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 0;
    margin-bottom: 20px;

    h1 {
      font-size: 16px;
      color: #1a051d;
    }
  }
`;

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5
    }
  }
};

export const Content = styled(motion.div).attrs(() => ({
  variants: container,
  initial: 'hidden',
  animate: 'show'
}))`
  padding: 0 30px 30px;
  display: grid;
  grid-template-columns: repeat(${p => p.cols}, 1fr);
  grid-gap: 20px;
  list-style: none;

  ${p =>
    p.isHover &&
    css`
      ${MenuItem} {
        opacity: 0.3;
      }
    `}
`;

export const BackButton = styled(motion.button).attrs(() => ({
  initial: {
    opacity: 0,
    x: 15
  },
  animate: {
    opacity: 1,
    x: '0',
    transition: {
      duration: 0.3,
      delay: 0.1,
      when: 'beforeChildren'
    }
  },
  exit: {
    x: 15,
    opacity: 0,
    transition: { duration: 0.3, when: 'afterChildren' }
  }
}))`
  color: #0084f4;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
