/**
 * Retorna as colunas de um relatório
 * @param data
 * @param itemsKey
 * @param labelKey
 * @returns {*[]|*}
 */
export const getColumns = (data, { itemsKey, labelKey }) => {
  try {
    return data?.[0]?.[itemsKey].map(item => item[labelKey]);
  } catch (e) {
    return [];
  }
};

/**
 * Retorna os totais de um relatório
 * @param data
 * @param itemsKey
 * @param labelKey
 * @param valueKey
 * @returns {{}[]|*[]}
 */
export const getTotals = (data, { itemsKey, labelKey, valueKey }) => {
  if (!data) return [];

  let totalsObject = data.reduce((acc, item) => {
    item[itemsKey].forEach(item => {
      const label = item[labelKey];
      const value = item[valueKey];

      acc[label] = acc[label] ? acc[label] + value : value;
    });

    return acc;
  }, {});

  return Object.keys(totalsObject).map(key => {
    return {
      [labelKey]: key,
      [valueKey]: totalsObject[key]
    };
  });
};

/**
 * Retorna o total de tudo
 * @param data
 * @returns {number|*}
 */
export const getTotal = (data, { itemsKey, labelKey, valueKey }) => {
  try {
    return data.reduce((acc, item) => {
      return acc + item.total;
    }, 0);
  } catch {
    return 0;
  }
};

/**
 * Transforma a resposta vinda da API dos relatórios
 * @param response
 * @param config
 * @returns {*}
 */
export const transformResponse = (
  response,
  config = { itemsKey: 'groups', labelKey: 'type', valueKey: 'total' }
) => {
  try {
    const data = JSON.parse(JSON.stringify(response.data));

    // Cria o objeto meta
    response.meta = {};

    // Adiciona as colunas no meta
    response.meta.columns = getColumns(data, config);

    // Pega os totais
    response.meta.totals = getTotals(data, config);

    // Pega o total geral da tabela
    response.meta.total = getTotal(data, config);

    return response;
  } catch (e) {
    return response;
  }
};
