import React, { useContext } from 'react';
import { ContextCompare } from '../../index';
import { CellLabel } from '../Table/styles';
import { Title, Address, Price, CustomCell } from './styles';

const RowImage = () => {
  const { properties } = useContext(ContextCompare);

  return (
    <tr>
      <CellLabel rowSpan={2}>
        Informações <br /> Básicas
      </CellLabel>
      {properties.map(
        ({
          id,
          transaction,
          title_formatted,
          address_formatted,
          calculated_price
        }) => (
          <CustomCell key={`image-${id}`}>
            <Title>{title_formatted}</Title>
            <Address>{address_formatted}</Address>
            <Price transaction={transaction}>
              {calculated_price} - {transaction === 1 ? 'Venda' : 'Locação'}
            </Price>
          </CustomCell>
        )
      )}
    </tr>
  );
};

export default RowImage;
