import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, getFormValues } from 'redux-form';
import _ from 'lodash';
// HOC
import { withPagination } from 'HOC/withPagination';
// Components
import GroupControl, { GroupItem } from 'components/GroupControl';
import Pagination from 'components/Pagination';
import FormWrapper from 'components/FormWrapper';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import TablePropertiesSold from './components/TablePropertiesSold';
import { CheckboxGroup, InputDate } from 'components/Form';
import {
  FilterContent,
  FilterTitle,
  FilterWrapper,
} from 'components/FilterAside';
// Containers
import FieldTypeAndSubtype from 'containers/FieldTypeAndSubtype';
import FieldPerson from 'containers/FieldPerson';
import FieldSelectUser from 'containers/FieldSelectUser';
import * as Fields from 'components/PropertyFilters';
import * as Filters from 'components/PropertyFilters';
// Modules
import {
  getPropertiesWithPagination,
  propertiesIsFetchingSelector,
  propertiesSelector,
  resetProperties,
} from 'modules/properties';
import { Actions as FilterActions } from 'pages/Properties/Filter/module';
import { openModalPerson, openModalProperty } from 'modules/modal';
import { hideLoading, showLoading } from 'modules/loading';
import FieldCondominium from 'containers/FieldCondominium';
import { isReferenceNotChanged } from 'lib/property-helpers';
import FormSortProperties from 'components/FormSortProperties';

const defaultParams = {
  filter: {},
  limit: 50,
  offset: 1,
};

class PropertiesSold extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  componentDidMount() {
    // Busca os dados do imóvel inicial
    this.fetchProperties({
      filter: this.initialValues,
    });
  }

  componentWillUnmount() {
    this.props.resetProperties();
  }

  fetchProperties = async (params = defaultParams) => {
    // Mostra o loading
    this.props.showLoading();

    let sort =
      localStorage.getItem('@tecimob/filter-properties') || '-updated_at,id';

    if (params.filter.sort) {
      sort = params.filter.sort;
      delete params.filter.sort;
    }

    const res = await this.props.getPropertiesWithPagination(
      {
        ...params,
        include: 'last_earned_deal',
        sort,
        filter: {
          ...params.filter,
          // Adiciona o status 2 de vendido para todos os requests
          status: 2,
        },
        limit: params.limit || defaultParams.limit,
        offset: params.offset || defaultParams.offset,
      },
      'properties-sold'
    );

    // Seta a paginação
    this.props.setPagination(res.meta);

    this.props.hideLoading();

    return res;
  };

  onChangePage = ({ selected }) => {
    const { values } = this.props;
    this.fetchProperties({
      filter: values,
      offset: selected + 1,
    });
  };

  onSubmit = async (values) => {
    return this.fetchProperties({
      filter: values,
    });
  };

  handleClickPerson = (person) => {
    this.props.openModalPerson(person);
  };

  handleClickProperty = (property) => {
    this.props.openModalProperty({
      property,
      afterClose: () => {
        this.fetchProperties();
      },
    });
  };

  get createdGreaterEquals() {
    try {
      return this.props.earnedDeal.created_greater_equals;
    } catch {
      return null;
    }
  }

  get createdLowerEquals() {
    try {
      return this.props.earnedDeal.created_lower_equals;
    } catch {
      return null;
    }
  }

  get currentUser() {
    try {
      return localStorage.getItem('current-user');
    } catch {
      return {};
    }
  }

  get initialValues() {
    try {
      const { country_id: by_country_id, state_id: by_state_id } = _.get(
        this.currentUser,
        'real_estate.relations'
      );

      return {
        by_country_id,
        by_state_id,
      };
    } catch {
      return {};
    }
  }

  render() {
    const { pagination } = this.props;

    return (
      <Wrapper full>
        <FormWrapper
          as={FilterWrapper}
          form="PropertiesSold"
          onSubmit={this.onSubmit}
          initialValues={this.initialValues}
          onChange={(values, dispatch, ownProps, prevValues) => {
            if (isReferenceNotChanged(values, prevValues)) {
              this.props.hideReference();
            }
          }}
        >
          {() => (
            <>
              <FilterTitle>Filtrar</FilterTitle>
              <FilterContent>
                <Fields.Reference />
                <FieldTypeAndSubtype />
                <FieldPerson
                  creatable={false}
                  label="Proprietários"
                  params={{
                    filter: { has_properties: 'sold' },
                  }}
                />
                <Fields.Price />
                <GroupControl required={false} label="Entrada entre">
                  <GroupItem>
                    <Field
                      isClearable
                      selectsStart
                      selected={this.createdGreaterEquals}
                      startDate={this.createdGreaterEquals}
                      endDate={this.createdLowerEquals}
                      name="by_earned_deal.earned_greater_equals"
                      component={InputDate}
                    />
                  </GroupItem>
                  <GroupItem size={10} divider />
                  <GroupItem>
                    <Field
                      isClearable
                      selected={this.createdLowerEquals}
                      startDate={this.createdGreaterEquals}
                      endDate={this.createdLowerEquals}
                      name="by_earned_deal.earned_lower_equals"
                      component={InputDate}
                    />
                  </GroupItem>
                </GroupControl>
                <Filters.NewLocation />
                <Fields.StreetAddress />
                <Fields.EarnedDeal />
                <FieldSelectUser
                  parse={null}
                  render={({ options, ...params }) => (
                    <div>
                      <label className="FormLabel">Corretor Responsável</label>
                      <CheckboxGroup {...params} limit={4} options={options} />
                    </div>
                  )}
                />
                <div className="h-margin-top--15">
                  <Fields.Database hasGuru={false} />
                  <FieldCondominium hideEmpty filter={{ status: 2 }} />
                  <Fields.Furniture />
                </div>
              </FilterContent>
            </>
          )}
        </FormWrapper>

        <Wrapper.inner>
          <Wrapper.container style={{ maxWidth: '985px' }}>
            <MainTitle
              title="Imóveis Vendidos"
              text="Cadastre, edite, gerencie tudo que precisar para dar ao seu cliente as melhores informações possíveis!"
            >
              <FormSortProperties mainForm="PropertiesSold" />
            </MainTitle>
            {!this.props.isFetching && (
              <TablePropertiesSold
                data={this.props.properties}
                fetchProperties={this.onChangePage}
                handleClickProperty={this.handleClickProperty}
                handleClickPerson={this.handleClickPerson}
              />
            )}
            <Pagination
              forcePage={pagination.current_page - 1}
              pageCount={pagination.total_pages}
              pageRangeDisplayed={10}
              marginPagesDisplayed={2}
              onPageChange={this.onChangePage}
            />
          </Wrapper.container>
        </Wrapper.inner>
      </Wrapper>
    );
  }
}

const selector = formValueSelector('PropertiesSold');

const mapStateToProps = (state) => ({
  earnedDeal: selector(state, 'by_earned_deal'),
  properties: propertiesSelector(state),
  values: getFormValues('PropertiesSold')(state),
  isFetching: propertiesIsFetchingSelector(state),
});

const mapDispatchToProps = {
  hideReference: FilterActions.hideReference,
  propertiesIsFetchingSelector,
  getPropertiesWithPagination,
  showLoading,
  hideLoading,
  resetProperties,
  openModalPerson,
  openModalProperty,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPagination('properties-sold', { limit: 50 })
)(PropertiesSold);
