import React, { useEffect, useMemo, useState } from 'react';
import Item from 'pages/PlanConfig/components/ListContracts/components/Item';
import {
  Dropdown,
  MainContract,
  Status,
  TextStatus,
} from 'pages/PlanConfig/components/ListContracts/styles';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import classnames from 'classnames';
import Detail from '../Detail';
import PreviousModelContract from 'pages/PlanConfig/components/ListContracts/components/PreviousModelContract';

// import { Wrapper } from './styles';

function MainContractComponent({ isFirst, data, className, fetchData }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const period = ({ start_at, end_at }) => {
    if (!end_at) return start_at;
    return `${start_at} à ${end_at}`;
  };

  const status = ({ closed_at }) => {
    if (closed_at === null)
      return {
        type: 'active',
        text: 'Ativo',
      };

    return {
      type: 'finished',
      text: 'Finalizado',
    };
  };

  const STATUS = useMemo(() => {
    return status(data);
  }, [data]);

  useEffect(() => {
    if (isFirst) {
      setIsExpanded(true);
    }
  }, [isFirst]);

  const renderContract = () => {
    if (data.type === 'Modelo anterior') {
      return (
        <PreviousModelContract
          data={data}
          className={className}
          isExpanded={isExpanded}
          period={period}
          setIsExpanded={setIsExpanded}
        />
      );
    }

    return (
      <MainContract
        className={classnames(className, 'contract--first', {
          'contract--expanded': isExpanded,
        })}
      >
        <td width={110}>
          <Item title="Data">{data?.created_at}</Item>
        </td>
        <td width={110}>
          <Item title="Tipo">{data.type}</Item>
        </td>
        <td width={110}>
          <Item title="Recorrência">{data.plan}</Item>
        </td>
        <td width={180}>
          <Item title="Período do contrato">{period(data)}</Item>
        </td>
        <td width={110}>
          <Item title="Valor">{data.credits_sum_price}</Item>
        </td>
        <td>
          <Item title="Gerado por">{data?.user?.name || '--'}</Item>
        </td>
        <Status
          type={STATUS.type}
          onClick={() => {
            setIsExpanded((value) => !value);
          }}
        >
          <TextStatus>{STATUS.text}</TextStatus>
          <Dropdown>
            {isExpanded ? (
              <MdExpandLess size={20} />
            ) : (
              <MdExpandMore size={20} />
            )}
          </Dropdown>
        </Status>
      </MainContract>
    );
  };

  return (
    <>
      {renderContract()}
      {isExpanded && <Detail contract={data} />}
    </>
  );
}

export default MainContractComponent;
