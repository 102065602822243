import React, { useCallback, useEffect } from 'react';
import Layout from 'pages/Crm/components/Layout';
import TablePeople from './components/TablePeople';
import { Content } from './styles';
import { useRequest } from 'hooks/useRequest';
import * as propertiesService from 'services/properties';
import Loading from 'components/Loading';
import { useFormUserId } from '../../components/FormUser';
import { plural } from 'lib/text';
import { useDispatch, useSelector } from 'react-redux';
import { countsSelector } from 'pages/Crm/modules/asideCrm';
import Button from 'components/Button';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import * as propertiesMatchedService from 'services/properties-matched';

function MatchedPeople() {
  const dispatch = useDispatch();
  const { data: counts } = useSelector(countsSelector);
  const userId = useFormUserId();

  const { data, isFetching, meta, fetchData } = useRequest({
    request: ({ sort = 'by_name', offset, user_id }) => {
      return propertiesService.peopleMatched({
        user_id,
        sort,
        offset,
      });
    },
    initialFetch: false,
  });

  const handleDiscartAll = useCallback(() => {
    dispatch(
      openConfirmation({
        size: '470px',
        title: 'Descartar todos os clientes',
        request: () => {
          return propertiesMatchedService.removeAllMatcheds({
            user_id: userId,
          });
        },
        onSuccess: () => {
          fetchData({ user_id: userId });
        },
        text: 'Tem certeza que deseja descartar todos clientes com imóveis compatíveis? Isso irá limpar a tabela de clientes e imóveis compatíveis',
      })
    );
  }, [userId]);

  const fetch = ({ offset, sort }) => {
    fetchData({ user_id: userId, offset, sort });
  };

  useEffect(() => {
    if (userId) {
      fetch({ offset: 1, user_id: userId, sort: 'by_name' });
    }
  }, [userId]);

  return (
    <Layout
      title={
        <>
          <strong>Radar de oportunidades</strong> |{' '}
          <strong>Clientes compatíveis</strong>
          {counts.matchedPropertiesCount > 0 && (
            <>
              {' | '}
              {plural(
                counts.matchedPropertiesCount,
                'imóvel',
                'imóveis'
              )} | {plural(counts?.matchedCount, 'pessoa', 'pessoas')}
            </>
          )}
        </>
      }
      renderActions={() =>
        data.length > 0 ? (
          <Button onClick={handleDiscartAll} size="medium" color="danger">
            Descartar todos
          </Button>
        ) : null
      }
    >
      <Content>
        <TablePeople
          userId={userId}
          data={data}
          meta={meta}
          fetchData={fetch}
        />
        <Loading isVisible={isFetching} isFullScreen />
      </Content>
    </Layout>
  );
}

export default MatchedPeople;
