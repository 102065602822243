import React from 'react';
import Modal from 'react-modal';

// Common Components
import { ModalTemplate } from 'components/Modal';
import Button from 'components/Button';

// Assets
import iconSuccess from './icon-success.svg';

// Styles
import { Text, Title, Wrapper } from './styles';

function ModalSuccess({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  title,
  text,
  size,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate>
        <Wrapper>
          <img src={iconSuccess} alt="Icone de sucesso" />
          {title && <Title>{title}</Title>}
          {text && <Text>{text}</Text>}
          <Button size="medium" color="white" onClick={handleClose}>
            Fechar
          </Button>
        </Wrapper>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalSuccess;
