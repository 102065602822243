import React, { useCallback, useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { MdAccessTime } from 'react-icons/md';
// Components
import classnames from 'classnames';
import Avatar from 'components/Avatar';
import Loading from 'components/Loading';
import Button from 'components/Button';
import { getAdminTable } from 'services/dashboard';
import { useRequest } from 'hooks/useRequest';
import { getFirstName } from 'lib/text';
import { useDispatch } from 'react-redux';
import { changeUser } from 'modules/login';
import { USER_PERMISSIONS } from 'constants/rules';
import { Cell, CellLink } from './styles';
import Can from 'containers/Can';
import * as links from './helpers';

const propTypes = {};
const defaultProps = {};

const CellValue = ({
  hasLink = false,
  hasCaution = false,
  className,
  children,
  ...props
}) => (
  <Cell
    hasLink={hasLink}
    className={classnames(className, 'cell-value', {
      'h-color--caution': hasCaution,
    })}
    {...props}
  >
    <strong>{children}</strong>
  </Cell>
);

const Item = ({ user, index: i, handleChangeUser }) => {
  const originalUser = localStorage.getItem('original-user');
  const currentUser = localStorage.getItem('current-user');

  return (
    <>
      {i !== 0 && (
        <tr key={`${user.id}-spacer`} className="spacer">
          <td />
        </tr>
      )}
      <tr key={`${user.id}-colinfo`}>
        <td>
          <div className="h-flex">
            <Avatar
              place="top"
              hasZoom={!!user.file_url}
              rounded
              size="large"
              image={user.file_url}
              text={user.name}
            />
            <div className="h-margin-left--10">
              <h5 className="h-text-overflow" style={{ maxWidth: '100%' }}>
                {getFirstName(user.name)}
              </h5>
              <p className="h-color--primary " style={{ fontSize: 12 }}>
                <MdAccessTime
                  color="#0063C0"
                  data-tip="Data do último acesso <br/>do usuário ao sistema."
                  style={{ marginTop: '-1px', marginRight: '1px' }}
                />
                {user?.last_login_at || 'Nenhum acesso'}
              </p>
            </div>
          </div>
        </td>

        {/* Total de clientes */}
        <CellValue hasLink>
          <CellLink to={links.peopleTotal(user)} />
          {user?.people_total}
        </CellValue>

        {/* Total de imóveis */}
        <CellValue hasLink className="border-left">
          <CellLink to={links.propertiesTotal(user)} />
          {user?.properties_total}
        </CellValue>

        {/* Imóveis para revisar */}
        <CellValue hasLink hasCaution={user?.properties_to_review > 0}>
          <CellLink to={links.propertiesToReview(user)} />
          {user?.properties_to_review}
        </CellValue>

        {/* Nutrições para clientes */}
        <CellValue
          hasLink
          hasCaution={user.matcheds_people > 0}
          className="border-left"
        >
          <CellLink to={links.peopleMatched(user)} />
          {user?.matcheds_people}
        </CellValue>

        {/* Nutrições para imóveis */}
        <CellValue hasLink hasCaution={user.matcheds_properties > 0}>
          <CellLink to={links.propertiesMatched(user)} />
          {user?.matcheds_properties}
        </CellValue>

        {/* Crm novos */}
        <CellValue hasLink className="border-left">
          <CellLink to={links.crm(user)} />
          {user.deals_new}
        </CellValue>

        {/* Crm Abertos */}
        <CellValue hasLink>
          <CellLink to={links.crm(user)} />
          {user.deals_attending}
        </CellValue>

        {/* Crm Estagnados */}
        <CellValue hasLink hasCaution={user?.deals_stagnated > 0}>
          <CellLink to={links.crm(user)} />
          {user?.deals_stagnated}
        </CellValue>

        <CellValue>
          {currentUser?.id !== user?.id && (
            <Can
              run={USER_PERMISSIONS.CHANGE_CURRENT_USER}
              permissions={originalUser.permissions}
            >
              <Button
                onClick={handleChangeUser(user.id)}
                size="small"
                color="white"
                colorText="secondary"
              >
                Acessar
              </Button>
            </Can>
          )}
        </CellValue>
      </tr>
    </>
  );
};

const renderUsers = (users, handleChangeUser) =>
  users.map((user, i) => {
    return (
      <Item
        key={user.id}
        user={user}
        index={i}
        handleChangeUser={handleChangeUser}
      />
    );
  });

const TableAdmin = () => {
  const dispatch = useDispatch();

  const { data, meta, isFetching } = useRequest({
    request: getAdminTable,
  });

  const handleChangeUser = useCallback(
    (userId) => () => {
      dispatch(changeUser(userId));
    },
    []
  );

  useEffect(() => {
    setTimeout(() => {
      Tooltip.rebuild();
    }, 100);
  }, [data]);

  if (isFetching) return <Loading isVisible />;

  return (
    <table className="TableAdmin">
      <thead>
        <tr>
          <th width={200} align="left">
            Nome
          </th>
          <th>Clientes</th>
          <th colSpan={2}>Imóveis</th>
          <th colSpan={2}>Compatíveis</th>
          <th colSpan={3}>CRM</th>
          <th width={105} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="nome" />
          <th scope="clientes">Total</th>
          <th scope="imoveis" className="border-left">
            Total
          </th>
          <th scope="imoveis" className="h-text-nowrap">
            A Revisar
          </th>
          <th scope="compativeis" className="border-left">
            Clientes
          </th>
          <th scope="compativeis">Imóveis</th>
          <th scope="crm" className="border-left">
            Novos
          </th>
          <th scope="crm">Abertos</th>
          <th scope="crm">Estagnados</th>
          <th />
        </tr>
        {renderUsers(data, handleChangeUser)}
      </tbody>
      <tfoot>
        <tr>
          <td />
          <td>{meta?.totals?.people_total}</td>
          <td className="border-left">{meta?.totals?.properties_total}</td>
          <td>{meta?.totals?.properties_to_review}</td>
          <td className="border-left">{meta?.totals?.matcheds_people}</td>
          <td>{meta?.totals?.matcheds_properties}</td>
          <td className="border-left">{meta?.totals?.deals_new}</td>
          <td>{meta?.totals?.deals_attending}</td>
          <td>{meta?.totals?.deals_stagnated}</td>
          <td />
        </tr>
      </tfoot>
    </table>
  );
};

TableAdmin.propTypes = propTypes;
TableAdmin.defaultProps = defaultProps;

export default TableAdmin;
