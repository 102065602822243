import React, { useMemo } from 'react';
import times from 'lodash/times';
import { Wrapper, Title, Image, Content, Steps, Step, Button } from './style';

function Tooltip({
  continuous,
  index,
  step,
  size,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps
}) {
  const isLastStep = useMemo(() => {
    return index + 1 === size;
  }, [index, size]);

  const canShowSteps = useMemo(() => {
    return size > 1;
  }, [size]);

  return (
    <Wrapper {...tooltipProps}>
      {step?.image && <Image src={step.image} alt="" />}
      {step?.title && <Title>{step?.title}</Title>}
      {step?.content && <Content>{step?.content}</Content>}
      {continuous && !isLastStep ? (
        <Button color="primary" {...primaryProps}>
          {step.nextText || 'Próximo'}
        </Button>
      ) : (
        <Button color="success" {...primaryProps}>
          {step.nextText || 'Finalizar'}
        </Button>
      )}
      {!continuous && (
        <Button color="success" {...closeProps}>
          {step.nextText || 'Finalizar'}
        </Button>
      )}
      {canShowSteps && (
        <Steps>
          {times(size, currentIndex => (
            <Step isActive={index === currentIndex} />
          ))}
        </Steps>
      )}
    </Wrapper>
  );
}

export default Tooltip;
