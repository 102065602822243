import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const TableMonths = ({ data, onClickRow }) => (
  <table
    className="Table"
    style={{ margin: '-20px -19px -20px', width: 'calc(100% + 40px)' }}
  >
    <thead>
      <tr>
        <th>Data</th>
        <th>Usuários</th>
        <th>E-mail</th>
        <th>Preço</th>
        <th>Saldo</th>
      </tr>
    </thead>
    <tbody>
      {data &&
        data.map((extract) => (
          <tr key={`table-months-${extract.id}`}>
            <td>{extract.date}</td>
            <td>{extract.users_count ?? '---'}</td>
            <td>{extract.mailboxes_count ?? '---'}</td>
            <td
              className={classnames({
                'h-color--success': extract.type === 'credit',
                'h-color--danger': extract.type === 'debit',
              })}
            >
              {extract.price}
            </td>
            <td>{extract.balance}</td>
          </tr>
        ))}
    </tbody>
  </table>
);

TableMonths.defaultProps = {
  data: null,
  onClickRow: null,
};

TableMonths.propTypes = {
  data: PropTypes.array,
  onClickRow: PropTypes.func,
};

export default TableMonths;
