import React from 'react';
// Components
import MenuRouter, { MenuLink } from 'components/MenuRouter';
// import PropTypes from 'prop-types';

const GalleryMenu = ({ propertyId }) => (
  <MenuRouter>
    <MenuLink exact to={`/properties/store/imagesArea/${propertyId}/gallery/1`}>
      FOTOS DO IMÓVEL
    </MenuLink>
    <MenuLink exact to={`/properties/store/imagesArea/${propertyId}/gallery/2`}>
      FOTOS PLANTAS
    </MenuLink>
    <MenuLink exact to={`/properties/store/imagesArea/${propertyId}/gallery/3`}>
      FOTOS PRIVADAS
    </MenuLink>
  </MenuRouter>
);

export default GalleryMenu;
