import _ from 'lodash';
import axios from 'axios';
import Alert from 'react-s-alert';
import api from 'services';
import { responseMessage } from 'lib/service-helpers';
import { SubmissionError } from 'redux-form';

/**
 * Retorna todos os status
 */
export const STATUSES = [
  { label: 'Em construção', value: 'under_construction' },
  { label: 'Novo', value: 'ready' },
  { label: 'Usado', value: 'used' }
];

/**
 * Instancia da API do órulo
 * @type {AxiosInstance}
 */
export const apiOrulo = axios.create({
  baseURL: 'https://www.orulo.com.br/api/v2/'
});

/**
 * Seta o token de autenticação do órulo
 * @param accessToken
 * @param typeToken
 */
export default function setAuthorizationToken(
  accessToken,
  typeToken = 'Bearer'
) {
  if (accessToken) {
    localStorage.setItem('@orulo/access_token', accessToken);
    apiOrulo.defaults.headers.common.Authorization = `${typeToken} ${accessToken}`;
  } else {
    localStorage.removeItem('@orulo/access_token');
    delete apiOrulo.defaults.headers.common.Authorization;
  }
}

/**
 * Redireciona para pagina de autorização
 */
export async function authorize() {
  // const redirectURL = await getRedirectUri();
  // window.location.href = `https://www.orulo.com.br/oauth/authorize?client_id=${ORULO_CLIENT_ID}&redirect_uri=${redirectURL}&response_type=code`;
}

const transformCities = cities => {
  return cities.map(city => ({ label: city, value: city }));
};

const transformTypes = types => {
  return _.map(types, (item, key) => ({ label: item, value: key }));
};

/**
 * Se autentica no órulo
 * @param code
 * @returns {Promise<void>}
 */
export async function login({ client_id, client_secret, user_id }) {
  try {
    const res = await api.create('settings/networks/orulo', {
      client_id,
      client_secret,
      user_id,
    });

    setAuthorizationToken(res?.data?.token);
    return res;
  } catch {
    Alert.success('client_id ou client_secret estão incorretos');

    throw new SubmissionError({
      client_id: 'Campo incorreto',
      client_secret: 'Campo incorreto'
    });
  }
}

export async function getToken() {
  try {
    const res = await api.getOne('settings/networks/orulo', '');
    setAuthorizationToken(res?.data?.token);
    return res;
  } catch {
    console.error('Erro ao buscar os dados de conexão com órulo');
    return {};
  }
}

/**
 * Atualiza as informações do órulo
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function update(values) {
  delete values.id;
  return api.update('settings/networks/orulo', values);
}

/**
 * Retorna todos os tipos do orulo
 * @returns {Promise<Array|*[]>}
 */
export async function getTypes() {
  try {
    const { data } = await apiOrulo.get('buildings/types/list');

    let allTypes = {
      ...data?.commercial,
      ...data?.residential
    };

    // Transforma a resposta dos tipos pra ficar melhor de manipular no formulário
    return transformTypes(allTypes);
  } catch (err) {
    console.error('Erro ao buscar os tipos');
    return [];
  }
}

/**
 * Retorna as cidades por estado
 * @param state
 * @returns {Promise<T>}
 */
export async function getCitiesByState(state) {
  return apiOrulo.get('addresses/cities', { params: { state } });
}

/**
 * Retorna todos os estados autorizados
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getStates() {
  return apiOrulo.get('addresses/states');
}

/**
 * Busca todos os estados/cidades
 * @example
 * ["SC/Araranguá", "SC/Florianópolis"]
 * @returns {Promise<{}|[]>}
 */
export async function getAllCities() {
  try {
    let response = [];

    // Busca todos os estados
    const {
      data: { states }
    } = await getStates();

    for (let state of states) {
      // Busca as cidades
      const {
        data: { cities }
      } = await getCitiesByState(state);

      for (let city of cities) {
        response = [...response, `${state}/${city}`];
      }
    }

    return transformCities(response);
  } catch (err) {
    return [];
  }
}

export async function remove(values) {
  return api
    .delete('settings/networks/orulo', '', { params: values })
    .then(responseMessage('Integração com órulo removida'));
}
