// Widgets.js
import { createModule } from '../lib/reducer-helpers';

const createAction = createModule('mapView');

// Actions
const SET_POSITION = createAction('SET_POSITION');
const SET_POV = createAction('SET_POV');
const SET_ZOOM = createAction('SET_ZOOM');

export const mapPositionSelector = state => state.mapView.position;
export const mapPovSelector = state => state.mapView.pov;
export const mapZoomSelector = state => state.mapView.zoom;

const initialState = {
  position: { lat: 28.214726781757083, lng: -48.702918143585975 },
  pov: { heading: 0, pitch: 0, zoom: 0 },
  zoom: 15
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_POSITION:
      return { ...state, position: { ...state.position, ...action.position } };
    case SET_POV:
      return { ...state, pov: { ...state.pov, ...action.pov } };
    case SET_ZOOM:
      return { ...state, zoom: action.zoom };
    default:
      return state;
  }
}

// Action Creators
export const setPosition = location => {
  const position = {
    lat: parseFloat(location?.lat),
    lng: parseFloat(location?.lng)
  };

  return { type: SET_POSITION, position };
};

export const setPov = pov => ({ type: SET_POV, pov });
export const setZoom = zoom => ({ type: SET_ZOOM, zoom });

// side effects, only as applicable
// e.g. thunks, epics, etc
export const handleSetPosition = position => dispatch => {
  if (!position?.lat || !position?.lng) return false;

  dispatch(setPosition(position));
};

export const handleSetPov = pov => dispatch => {
  dispatch(setPov(pov));
};

export const handleSetZoom = zoom => dispatch => {
  dispatch(setZoom(zoom));
};
