// Modules
import dataReducer from 'modules/data';
// Services
import { getPeoplePerPeriod } from 'services/reports';

// Selector
export const peoplePerPeriodSelector = state =>
  state.reports.peoplePerPeriod.data;
export const isFetchingPeoplePerPeriodSelector = state =>
  state.reports.peoplePerPeriod.meta.isFetching;

const {
  reducer: peoplePerPeriodReducer,
  actionCreators: peoplePerPeriodActions
} = dataReducer(`reports/peoplePerPeriod`);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(peoplePerPeriodActions.request());

  getPeoplePerPeriod(params).then(res => {
    dispatch(peoplePerPeriodActions.receive(res.data));

    return res.data;
  });
};

export default peoplePerPeriodReducer;
