import React from 'react';
import * as pages from '../../pages';
import { Route, Switch } from 'react-router';

function Pages() {
  return (
    <Switch>
      <Route exact path="/crm/deals" component={pages.Deals} />
      <Route exact path="/crm/people" component={pages.People} />
      <Route exact path="/crm/bookeds" component={pages.Booked} />
      <Route exact path="/crm/matched-people" component={pages.MatchedPeople} />
      <Route exact path="/crm/config" component={pages.Config} />
      <Route exact path="/crm/funnel/:id" component={pages.Funnel} />
      <Route exact path="/crm/history" component={pages.History} />
      <Route exact path="/crm/dashboard" component={pages.Dashboard} />
      <Route exact path="/crm/contacts" component={pages.Contacts} />
      <Route
        exact
        path="/crm/report-shared-properties"
        component={pages.ReportSharedProperties}
      />
      <Route
        exact
        path="/crm/matched-properties"
        component={pages.MatchedProperties}
      />
    </Switch>
  );
}

export default Pages;
