import FilterAside from "components/FilterAside";
import { isEmpty, isEqual } from "lodash";
import { reduxForm } from "redux-form";
import * as Fields from './fields';

function FilterCondos({ handleSubmit }) {
  return (
    <FilterAside title="Filtro por nome">
      <form onSubmit={handleSubmit}>
        <Fields.Title />
      </form>
    </FilterAside>
  );
}

let timeout = 0;

export default reduxForm({
  form: 'FilterCondos',
  enableReinitialize: true,
  onChange: (values, _, ownProps, prevValues) => {
    if (isEmpty(prevValues) || isEqual(values, prevValues)) return;

    clearTimeout(timeout);
    timeout = setTimeout(() => ownProps.onSubmit(values), 350);
  },
})(FilterCondos);
