import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .FormGroup {
    margin-bottom: 0;
  }

  .Field--area {
    width: 58px;
  }
`;

export const Divider = styled.div`
  width: 5px;
  height: 1px;
  background: ${(p) => p.theme.colors.primary};
`;
