import _ from 'lodash';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';

/**
 * Adiciona uma mensagem no fim do request de uma promisse
 * retornando no final a resposta
 * @param message
 * @param config
 */
export const responseMessage = (message, config = {}) => res => {
  const isError = _.has(res, 'response');

  if (_.isObject(message)) {
    const status = res.response ? res.response.status : res.status;

    if (message[status]) {
      Alert.success(message[status], config);
    }
  } else {
    Alert.success(message, config);
  }

  if (res instanceof SubmissionError) throw res;

  if (isError) {
    return Promise.reject(res);
  }

  return res;
};

export const getName = (obj, { field, fieldName, data }) => {
  if (obj[field]) {
    return {
      ...obj,
      [fieldName]: data.find(v => {
        // eslint-disable-next-line
        return v.value == obj[field];
      }).label
    };
  }
  return obj;
};

export const handleFormErrors = err => {
  const {
    response: {
      status,
      data: { message }
    }
  } = err;

  // Verifica se tem algum erro de validação
  if (status === 400) {
    throw new SubmissionError(message);
  }

  return Promise.reject(err);
};

/**
 * Filtra uma lista de informações
 * usada para filtrar as constantes
 * @param {Array} data - todas as informações
 * @param {Array} selected - array de numeros que vao ser filtrados
 * @param {String} dataKey - o nome da chave que ele vai procurar
 */
export const filterSelected = ({ data, selected, dataKey = 'value' }) =>
  data.filter(value => selected.indexOf(value[dataKey]) !== -1);
