import Alert from 'react-s-alert';
import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { reduxForm } from 'redux-form';
import * as condosService from 'services/condos';

function ModalCharacteristicRemoveCondo({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  characteristic,
  initialize,
  handleSubmit,
}) {
  useEffect(() => {
    initialize(characteristic);
  }, [characteristic]);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate
          title={`Remover característica do condomínio`}
          handleClose={handleClose}
        >
          <p>
            Tem certeza de que deseja remover a característica{' '}
            <strong>{characteristic.title}</strong>, a qual será excluída em
            todos os imóveis vinculados?
          </p>
          {characteristic?.properties_count > 0 && (
            <p>Esta característica será removida de todos os imóveis</p>
          )}

          <ModalFooter>
            <Button onClick={handleClose} color="white">
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="danger">
              Remover
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </form>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalCharacteristicRemoveCondo',
  onSubmit: async (values, dispatch, props) => {
    return condosService
      .removeCondosCharacteristics(values.id)
      .then((res) => {
        if (props.onSubmitSuccess) props.onSubmitSuccess(res, dispatch, props);
        props.handleClose();
        Alert.success('Característica removida');
        // condosService.createCondosCharacteristics();
      })
      .catch((err) => {
        Alert.success('Erro ao remover a característica');
      });
  },
})(ModalCharacteristicRemoveCondo);
