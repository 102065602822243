import * as logosSvg from '../assets/logos-svg-15-03-2024';

export default {
  // ...logos,
  ...logosSvg,
  // ...logos,
  // image1,
  // image2,
  // image3,
  // image4,
  // image5,
  // image6,
  // image7,
  // image13,
  // image14,
  // image15,
  // image16,
  // image17,
  // image18,
  // image19,
  // image20,
  // image21,
};
