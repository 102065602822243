// Modules
import createDataReducer from 'modules/data';
// Services
import * as testimonialsService from 'services/sites/testimonials';

export const {
  selectors: testimonialsSelectors,
  actionCreators: testimonialsActions,
  reducer
} = createDataReducer('components/testimonials');

/**
 * Busca todos os modelos de cabeçalhos
 * @param params
 * @return {Promise}
 */
export const fetchTestimonials = params => dispatch => {
  dispatch(testimonialsActions.request());

  return testimonialsService.getComponents(params).then(res => {
    dispatch(testimonialsActions.receive(res.data));
    return res;
  });
};

export default reducer;
