import classnames from 'classnames';
import React from 'react';
import { Col } from 'react-flexbox-grid';
// Components
import Title from '../Title';
import Text from '../Text';

const propTypes = {};
const defaultProps = {
  xs: 0
};

const Wrapper = ({ children, className, block, ...props }) => (
  <Col
    className={classnames('ListInfo__item', className, {
      'ListInfo__item--block': block
    })}
    {...props}
  >
    {children}
  </Col>
);

const Item = ({ title, text, children, ...props }) =>
  children ? (
    <Wrapper {...props}>{children}</Wrapper>
  ) : (
    <Wrapper {...props}>
      {title && <Title>{title}</Title>}
      {text && <Text>{text}</Text>}
    </Wrapper>
  );

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;

export default Item;
