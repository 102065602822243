import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment, { format } from 'lib/moment';
import Table from 'components/Table';
// Services
import { getAllDeals } from 'services/properties';
import { removeDeal } from 'services/deals';
// Assets
import house from 'assets/img/noData/house.svg';
import Label from 'components/Label';
import { useDispatch } from 'react-redux';
import { openModalPerson } from 'modules/modal';
import Button from 'components/Button';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { openConfirmation } from 'containers/ModalConfirmation/module';

const defaultProps = {
  property: { id: null },
};

const propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

const styles = {
  table: { margin: '0 -19px', width: 'calc(100% + 40px)' },
};

function Status({ name, color }) {
  if (name) {
    switch (name) {
      case 'Perdido':
        return <Label color="danger">{name}</Label>;
      case 'Ganho':
        return <Label color="success">{name}</Label>;
      case 'Em atendimento':
        return <Label color="caution">{name}</Label>;
      default:
        return (
          <Label kind="solid" color={color}>
            {name}
          </Label>
        );
    }
  }

  return name || '--';
}

function Deals({ infos: p, property: { id: propertyId }, handleOpenMyself }) {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const [deals, setDeals] = useState({
    data: [],
    isFetching: false,
  });

  const fetchAll = useCallback(async () => {
    setDeals({ isFetching: true });

    const { data } = await getAllDeals(propertyId);

    setDeals({
      data,
      isFetching: false,
    });
  }, [propertyId, setDeals]);

  const remove = async (dealId) => {
    dispatch(
      openConfirmation({
        title: 'Remover negócio',
        text: 'Tem certeza que deseja remover esse negócio?',
        submitButtonText: 'Remover',
        request: () => removeDeal(dealId),
        onSuccess: () => {
          fetchAll();
        },
      })
    );
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <div>
      <Table
        style={styles.table}
        data={deals.data}
        isFetching={deals.isFetching}
        renderTitle={() => (
          <tr>
            <th>Última movimentação</th>
            <th>Corretor</th>
            <th>Cliente</th>
            <th>Etapa</th>
          </tr>
        )}
        renderItem={({
          id,
          last_moviment,
          stage_name,
          person_name,
          people_id,
          user_name,
          user_id,
          can_delete,
          stage_color,
        }) => {
          return (
            <tr key={id} className="h-hover">
              <td>
                {moment(last_moviment, 'YYYY-MM-DD HH:mm:ss').format(
                  format.datetime
                )}
              </td>
              <td>{user_name || '--'}</td>
              <td>
                <span
                  className="h-link"
                  onClick={() => {
                    dispatch(
                      openModalPerson({
                        id: people_id,
                        afterClose: () => {
                          handleOpenMyself();
                        },
                      })
                    );
                  }}
                >
                  {person_name || '--'}
                </span>
              </td>
              <td width="1px">
                <Status name={stage_name} color={stage_color} />
              </td>
              <td style={{ width: '1px' }}>
                {can_delete && (
                  <Button
                    onClick={() => remove(id)}
                    color="danger"
                    size="small"
                  >
                    Remover
                  </Button>
                )}
              </td>
            </tr>
          );
        }}
        noData={{
          title: 'Sem negócios',
          text: 'Nenhum negócio encontrado',
          image: house,
        }}
      />
    </div>
  );
}

Deals.defaultProps = defaultProps;
Deals.propTypes = propTypes;

export default Deals;
