import { plural } from 'lib/text';
import { networkSelector, setNetwork } from 'modules/propertySearch';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import {
  Divider,
  Link,
  Results,
} from 'pages/PropertiesSearch/components/Header/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// import { Wrapper } from './styles';

function ResultsComponent({ params, properties }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const networkActive = useSelector(networkSelector);
  const { meta } = usePortalOptions();

  const handleClickNetwork = (network) => () => {
    dispatch(setNetwork(network));
    let currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.delete('filter[on_network][]');
    currentSearchParams.set('filter[on_network][]', network);
    currentSearchParams.set('offset', 1);
    history.push({
      search: currentSearchParams.toString(),
    });
  };

  return (
    <Results>
      <Link
        className={networkActive === 'self' && 'active'}
        href="#"
        onClick={handleClickNetwork('self')}
      >
        {plural(
          params?.filter?.count?.self?.count || meta?.pagination?.total,
          'Imóvel encontrado',
          'Imóveis encontrados'
        )}
      </Link>
      {params?.filter?.count?.orulo?.count > 0 && (
        <>
          <Divider> | </Divider>
          <Link
            className={networkActive === 'orulo' && 'active'}
            href="#"
            onClick={handleClickNetwork('orulo')}
          >
            {params?.filter?.count?.orulo?.count} Órulo
          </Link>
        </>
      )}
      {params?.filter?.count?.dwv?.count > 0 && (
        <>
          <Divider> | </Divider>
          <Link
            className={networkActive === 'dwv' && 'active'}
            href="#"
            onClick={handleClickNetwork('dwv')}
          >
            {params?.filter?.count?.dwv?.count} DWV
          </Link>
        </>
      )}
    </Results>
  );
}

export default ResultsComponent;
