import styled from 'styled-components';

export const Wrapper = styled.div`
  color: #1a051d;
  border-bottom: 1px solid #dedede;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0 5px 30px;
`;

export const List = styled.div`
  list-style: none;
  padding-top: 5px;
  padding-bottom: 15px;
  max-height: 250px;
  overflow: auto;

  .Avatar {
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 6px;
    margin-right: 10px;
  }

  p {
    color: #1a051d;
  }
`;

export const Action = styled.button.attrs(() => ({
  type: 'button'
}))`
  cursor: pointer;
  padding: 5px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  transition: background-color 0.2s ease-in-out;
  will-change: background-color;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
