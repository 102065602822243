import React from 'react';
import Button from '../Button';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const language = {
  ptBr: {
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    weekdaysLong: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    weekdaysShort: ['Do', '2ª', '3ª', '4ª', '5ª', '6ª', 'Sa'],
    firstDayOfWeek: 0,
  },
};

const Navbar = ({ onPreviousClick, onNextClick, className }) => (
  <div className={`h-flex ${className}`} style={{ marginBottom: 15 }}>
    <Button color="white" colorText="primary" onClick={() => onPreviousClick()}>
      ← Anterior
    </Button>
    <div className="h-flex__cell--grow" />
    <Button color="white" colorText="primary" onClick={() => onNextClick()}>
      Próximo →
    </Button>
  </div>
);

const defaultProps = {
  initialValues: [],
  navbarElement: <Navbar />,
};

const removeSelectedDays = (currentDay) => (day) => {
  return !DateUtils.isSameDay(day, currentDay);
};

class MultipleDayPicker extends React.Component {
  state = {
    mouseDown: false,
    selectedDays: this.props.initialValues,
  };

  get selectedDays() {
    const { value } = this.props.input;

    if (!value) return [];

    return value.map((value) => new Date(value));
  }

  handleDayClick = (currentDay, { selected }) => {
    let currentDays = this.selectedDays;
    if (selected) {
      currentDays = currentDays.filter(removeSelectedDays(currentDay));
    } else {
      currentDays = [...currentDays, currentDay];
    }

    if (this.props.input) {
      this.props.input.onChange(currentDays);
    }
  };

  handleMouseDown = (...values) => {
    this.handleDayClick(...values);
    this.setState({ ...this.state, mouseDown: true });
  };

  handleMouseUp = () => {
    this.setState({ ...this.state, mouseDown: false });
  };

  handleMouseEnter = (...values) => {
    if (this.state.mouseDown) {
      this.handleDayClick(...values);
    }
  };

  render() {
    return (
      <div className="MultipleDayPicker">
        <DayPicker
          {...language.ptBr}
          disabledDays={this.props.disabledDays}
          fixedWeeks
          pagedNavigation
          onDayMouseDown={this.handleMouseDown}
          onDayMouseUp={this.handleMouseUp}
          onDayMouseEnter={this.handleMouseEnter}
          selectedDays={this.selectedDays}
          numberOfMonths={12}
          navbarElement={this.props.navbarElement}
          onWeekClick={(...values) => {}}
        />
      </div>
    );
  }
}

MultipleDayPicker.defaultProps = defaultProps;

export default MultipleDayPicker;
