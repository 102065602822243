// import styles from './styles'

import { Col, Row } from 'react-flexbox-grid';
import Button from 'components/Button';
import { getProperty } from 'containers/ModalRoot/ModalPropertyDuplicateMulti/helpers/getProperty';
import { MdAdd } from 'react-icons/md';

export default function AddProperty({ fields, property: currentProperty }) {
  return (
    <Row end="xs" className="h-margin-bottom--15">
      <Col xs>
        <Button
          color="caution"
          type="button"
          onClick={async () => {
            const skip_references = fields.getAll().reduce((acc, property) => {
              acc.push(property.reference);
              return acc;
            }, []);

            const property = await getProperty({
              ...currentProperty,
              skip_references,
            });

            fields.push(property);
          }}
        >
          <MdAdd /> Adicionar Imóvel
        </Button>
      </Col>
    </Row>
  );
}
