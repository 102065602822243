import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Services
import * as characteristicsService from 'services/characteristics';
import TableCharacteristics from './components/TableCharacteristics';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
// Modules
import {
  openModalCharacteristicRemove,
  openModalCharacteristics,
} from 'modules/modal';

function Characteristics() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  async function fetchCharacteristics() {
    setIsFetching(true);
    const { data } = await characteristicsService.getAllCustom();
    setData(data);
    setIsFetching(false);
  }

  const handleDelete = (characteristic) => async () => {
    dispatch(
      openModalCharacteristicRemove({
        characteristic,
        onSubmitSuccess: () => {
          fetchCharacteristics();
        },
      })
    );
  };

  const handleEdit =
    ({ id, type }) =>
    () => {
      openModalCharacteristics({
        characteristicId: id,
        typeId: type.id,
        onSubmitSuccess: ({ data: currentCharacteristic }) => {
          const characteristics = data.map((characteristic) => {
            if (currentCharacteristic.id === characteristic.id) {
              return currentCharacteristic;
            }
            return characteristic;
          });

          setData(characteristics);
        },
      })(dispatch);
    };

  useEffect(() => {
    fetchCharacteristics();
  }, []);

  return (
    <Wrapper.container>
      <MainTitle
        title="Características de imóvel"
        text="Gerencie aqui todos os estabelecimentos que você cadastrou no sistema."
      />
      <TableCharacteristics
        data={data}
        isFetching={isFetching}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Wrapper.container>
  );
}

Characteristics.propTypes = {};
Characteristics.defaultProps = {};

export default Characteristics;
