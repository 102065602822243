import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRequest } from 'hooks/useRequest';
import { Field } from 'redux-form';
import * as portalsService from 'services/settings/portals';
import { Select } from 'components/Form';

function PortalField({ portalRealEstateId }) {
  const { data, fetchData } = useRequest({
    request: portalsService.getOneRelation,
    params: portalRealEstateId,
    initialFetch: false
  });

  useEffect(() => {
    if (portalRealEstateId) {
      fetchData(portalRealEstateId);
    }
  }, [portalRealEstateId, fetchData]);

  if (!data?.portal_options) return null;

  return (
    <Field
      label="Tipo do anúncio"
      name="by_portal_option_id"
      options={[
        { name: 'Não selecionado', id: 'is_portal' },
        ...data?.portal_options
      ]}
      component={Select}
      labelKey="name"
      valueKey="id"
    />
  );
}

PortalField.defaultProps = {
  portalRealEstateId: null
};

PortalField.propTypes = {
  portalRealEstateId: PropTypes.string.isRequired
};

export default React.memo(PortalField);
