import styled from 'styled-components';
import { readableColor } from 'polished';
import TextOverflow from 'components/TextOverflow';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Image = styled.div`
  margin-right: 20px;
  position: relative;
`;
export const Stripe = styled(TextOverflow)`
  position: absolute;
  width: 100%;
  top: 0;
  padding: 5px;
  color: ${p => readableColor(p.color)};
  background: ${p => p.color};
  text-align: center;
  border-radius: 4px 4px 0 0;
`;
export const Content = styled.div`
  min-width: 210px;

  .FormGroup {
    margin-left: 0 !important;
    margin-bottom: 5px;
  }
`;
