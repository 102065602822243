import WebFont from 'webfontloader';

// Action
import { createModule } from 'lib/reducer-helpers';
import { AVALIABLE_FONTS } from 'constants/constants';

const createAction = createModule('googlefonts');

const REQUEST_FONTS = createAction('REQUEST_FONTS');
const RECEIVE_FONTS = createAction('RECEIVE_FONTS');

const initialState = {
  isFetching: false,
  isLoaded: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FONTS:
      return {
        isFetching: true,
        isLoaded: false
      };
    case RECEIVE_FONTS:
      return {
        isFetching: false,
        isLoaded: true
      };
    default:
      return state;
  }
};

export const loadFonts = () => (dispatch, getState) => {
  const state = getState();

  // Se já estiver carregado
  if (state.googlefonts.isLoaded) return false;

  dispatch({ type: REQUEST_FONTS });

  const families = AVALIABLE_FONTS.map(({ family }) => family);

  WebFont.load({
    google: {
      families
    }
  });

  dispatch({ type: RECEIVE_FONTS });
};

export default reducer;
