import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Modules
import {
  openModalPeopleImport,
  openModalPerson,
  openModalPersonAdd,
} from 'modules/modal';
import { fetchPeopleInteractions } from './components/PeopleWithInteraction/module';
import { fetchPeopleEvents } from './components/PeopleScheduled/module';
import { updatePeopleDashboard } from './module';
// Components
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
// Containers
import PeopleBirthday from './components/PeopleBirthday';
import PeopleScheduled from './components/PeopleScheduled';
import PeopleWithInteraction from './components/PeopleWithInteraction';
import PropertiesWithClients from 'containers/PeopleDashboard/components/PropertiesWithClients';
import Button from 'components/Button';
import Help from './components/Help';

class PeopleDashboard extends React.Component {
  static propTypes = {
    openModalPersonAdd: PropTypes.func.isRequired,
  };

  static defaultProps = {
    openModalPersonAdd: null,
  };

  /**
   * Abre a modal de cadastro de cliente
   * @param person
   */
  handleOpenModalPersonAdd = (person) => () =>
    this.props.openModalPersonAdd(person, (currentPerson) => {
      // Abre a modal depois que termina de cadastrar
      this.props.openModalPerson(currentPerson);

      // Atualiza a lista das ultimas clientes com interações
      this.props.fetchPeopleInteractions();
    });

  /**
   * Abre a modal de cliente
   * @param person
   */
  openModalPerson = (person) => () =>
    this.props.openModalPerson({
      ...person,
      afterClose: () => {
        this.props.fetchPeopleEvents();
      },
      onSubmitSuccessEdit: () => {
        this.props.updatePeopleDashboard();
      },
    });

  render() {
    return (
      <Wrapper.inner>
        <Wrapper.container>
          <Help />
          <MainTitle
            title="Meus Clientes"
            text="Cadastre, edite, gerencie tudo que precisar para dar ao seu cliente as melhores informações possíveis!"
          >
            <Button onClick={this.props.openModalPeopleImport}>
              Importar Clientes
            </Button>
          </MainTitle>
          <Row>
            <Col xs={12} sm={6}>
              <PropertiesWithClients />
              <PeopleScheduled openModalPerson={this.openModalPerson} />
              <PeopleBirthday openModalPerson={this.openModalPerson} />
            </Col>
            <Col xs={12} sm={6}>
              <PeopleWithInteraction openModalPerson={this.openModalPerson} />
            </Col>
          </Row>
        </Wrapper.container>
      </Wrapper.inner>
    );
  }
}

export default connect(null, {
  openModalPersonAdd,
  openModalPeopleImport,
  fetchPeopleEvents,
  openModalPerson,
  fetchPeopleInteractions,
  updatePeopleDashboard,
})(PeopleDashboard);
