import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Common Components
import FormRent from './components/FormRent';
// Services
import * as dealsService from 'services/deals';
// Modules
import { fetchPeople, fetchUsers, handleFormSubmit } from './module';
import Preview from './components/Preview';
import moment, { format } from 'lib/moment';

class ModalPropertyRent extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    // Pode ou não ter
    deal: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),

    person: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    property: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,

    onSubmitSuccess: PropTypes.func,
    onSubmitFail: PropTypes.func
  };

  static defaultProps = {
    deal: { id: null },
    person: { id: null },
    property: { id: null },
    onSubmitSuccess: null,
    onSubmitFail: null
  };

  state = {
    initialValues: {}
  };

  componentDidMount() {
    this.getInitialValues();
  }

  get isEditting() {
    return _.get(this.props, 'deal.id') !== null;
  }

  get title() {
    if (this.isEditting) {
      return 'Editando negócio';
    }

    return 'Parabéns por ter fechado o negócio!';
  }

  getInitialValues = async () => {
    const { deal, initialValues, person, property } = this.props;

    // Se existir deal retorna os dados do negocio
    if (deal.id !== null) {
      const { data } = await dealsService.getDealEarned(deal.id);

      this.setState({
        initialValues: data
      });

      return data;
    }

    this.setState({
      initialValues: {
        ...initialValues,
        people_id: person.id,
        property_id: property.id,
        user_id: localStorage.getItem('current-user').id,
        calculated_price: property.calculated_price,
        remove_search_profile: false,
        remove_deals: false,
        has_guarantee: false,
        is_included_condo: false,
        territorial_tax_type: 2,
        earned_at: moment().format(format.date)
      }
    });
  };

  render() {
    const {
      modalConfig,
      isOpen,
      handleClose,
      modalType,

      // Property
      property,

      // Deal
      deal,

      isPreview,

      onSubmitSuccess,
      handleFormSubmit
    } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        {isPreview ? (
          <Preview deal={deal} property={property} handleClose={handleClose} />
        ) : (
          <FormRent
            deal={deal}
            isEditting={this.isEditting}
            initialValues={this.state.initialValues}
            onCreatePerson={this.onCreatePerson}
            onSubmit={handleFormSubmit}
            onSubmitSuccess={onSubmitSuccess}
            property={property}
            handleClose={handleClose}
          />
        )}
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  fetchPeople,
  fetchUsers,
  handleFormSubmit
};

export default connect(null, mapDispatchToProps)(ModalPropertyRent);
