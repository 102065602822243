import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
import { Field, formValues, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Constants
import { PROFILE, SITUATION, TYPE_TRANSACTION } from 'constants/options';
// Helpers
import { parseSelect } from 'lib/formHelpers';
// Modules
import { handleNewClient, valuesSelector } from '../../module';
// Common Components
import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import Check from 'components/Check';
// Form Components
import { Input, Select, SelectMultiple, Toggle } from 'components/Form';
import CheckboxList from 'components/Form/components/CheckboxList';
import RequiredLabel from '../RequiredLabel';
import GroupControl, { GroupItem } from 'components/GroupControl';
import AreaField from './AreaField';
// Validation
import validate from './validate';

const defaultProps = {
  submitting: false,

  people: [],
  characteristics: [],
  condoCharacteristics: [],
  neighborhoods: [],

  values: {
    by_room_greater_equals: {},
  },
};
const propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,

  people: PropTypes.arrayOf(PropTypes.object),
  characteristics: PropTypes.arrayOf(PropTypes.object),
  condoCharacteristics: PropTypes.arrayOf(PropTypes.object),
  neighborhoods: PropTypes.arrayOf(PropTypes.object),
};

const ListRequired = ({ title, data, name, input, options }) => {
  return (
    <div className="h-margin-bottom--10">
      <h5 className="h-margin-bottom--5">{title}</h5>
      <Row tagName="ul">
        {options.map((option, i) => {
          // Name do input
          const name = `${input.name}.${option.id}`;
          const nameChecked = `${name}.checked`;
          const nameRequired = `${name}.is_required`;

          let checked = false,
            is_required = false;

          // Verifica se a opcao existe
          // dentro do valor do input
          if (_.has(data, option.id)) {
            checked = data[option.id].checked;
            is_required = data[option.id].is_required;
          }

          // data ? data[option.id].checked ? 'checked' : 'not-checked' : null;
          return (
            <Col xs={0} className="h-margin-bottom--5">
              <label>
                <Field
                  name={nameChecked}
                  type="checkbox"
                  component="input"
                  style={{ display: 'none' }}
                  onChange={(e, value) => {
                    if (!value) {
                      input.onChange({
                        ...input.value,
                        [option.id]: { is_required: false },
                      });
                    }
                  }}
                />
                <Check checked={checked} /> {option.title}
              </label>
              <span data-tip="Clique para marcar<br /> o item como INDISPENSÁVEL<br /> para o cliente. Assim, imóveis<br /> sem este item serão ignorados">
                <RequiredLabel
                  onChange={(e, value) => {
                    if (value) {
                      input.onChange({
                        ...input.value,
                        [option.id]: {
                          checked: true,
                        },
                      });
                    }
                  }}
                  name={nameRequired}
                  checked={is_required}
                  style={{
                    marginLeft: 10,
                  }}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Tooltip
        disable={localStorage.getItem('message-required')}
        html
        effect="solid"
        afterShow={() => {
          localStorage.setItem('message-required', true);
        }}
      />
    </div>
  );
};

class ProfileForm extends React.Component {
  render() {
    const {
      hasSide,

      handleSubmit,
      handleClose,
      submitting,

      people,
      condos,
      characteristics,
      condosCharacteristics,
      establishments,
      roomsFields,
      areaFields,

      countries,
      states,
      cities,
      neighborhoods,

      prices,
      // all for values
      initialValues: { area_name },

      // handlers
      handleNewClient,

      inputCharacteristics,
      inputEstablishments,
      inputCondos,
      inputNeighborhoods,

      ...props
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <p className="h-color--primary h-margin-bottom--15">
          Os dados abaixo serão salvos para o cliente, e assim que cadastrar um
          imóvel com características compatíveis, você será avisado.
        </p>

        <Row className={hasSide ? 'row--divider' : 'row'}>
          <Col xs={hasSide ? 6 : 12}>
            <Row>
              <Field
                creatable
                multi={false}
                xs={6}
                label="Cliente"
                name="people_id"
                options={people}
                valueKey="id"
                labelKey="name"
                parse={parseSelect('id')}
                component={SelectMultiple}
                onNewOptionClick={handleNewClient}
              />
              <Field
                multi={false}
                xs={6}
                label="Validade"
                name="expiry_date"
                component={SelectMultiple}
                options={[
                  { id: '3', title: '3 Meses' },
                  { id: '6', title: '6 Meses' },
                  { id: '12', title: '12 Meses' },
                ]}
                valueKey="id"
                labelKey="title"
                parse={parseSelect('id')}
              />
            </Row>

            <Row>
              <Field
                buttonTemplate
                xs={6}
                name="transaction"
                label="Transação"
                component={Select}
                options={TYPE_TRANSACTION}
              />
              <Field
                multi
                xs={6}
                label="Condomínio"
                name="condos_id"
                component={SelectMultiple}
                parse={parseSelect('id')}
                options={condos}
                valueKey="id"
                labelKey="title"
              />
            </Row>

            <Row>
              {roomsFields &&
                roomsFields.map(({ title, name }) => (
                  <Field
                    multi={false}
                    xs={4}
                    label={title}
                    name={`rooms.${name}`}
                    component={SelectMultiple}
                    options={[
                      { value: '1', label: '1 ou mais' },
                      { value: '2', label: '2 ou mais' },
                      { value: '3', label: '3 ou mais' },
                      { value: '4', label: '4 ou mais' },
                      { value: '5', label: '5 ou mais' },
                    ]}
                  />
                ))}
            </Row>

            <Row>
              <Col xs={6}>
                <GroupControl label="Preço" required={false}>
                  <GroupItem>
                    <Field valueFormat name="minimum_price" component={Input} />
                  </GroupItem>
                  <GroupItem size={7} divider />
                  <GroupItem>
                    <Field valueFormat name="maximum_price" component={Input} />
                  </GroupItem>
                </GroupControl>
              </Col>
              <Col xs={6}>
                <AreaField
                  areaName={area_name}
                  fields={areaFields}
                  clear={props.reset}
                  change={props.change}
                />
              </Col>
            </Row>
            <Row>
              <Field
                xs={6}
                label="Situação"
                name="situations"
                component={SelectMultiple}
                options={SITUATION}
                parse={parseSelect('value')}
              />
              <Field
                xs={6}
                label="Perfil"
                name="profiles"
                component={SelectMultiple}
                options={PROFILE}
                parse={parseSelect('value')}
              />
            </Row>

            <Row>
              <Field
                xs={6}
                label="Aceita Minha Casa Minha Vida?"
                name="is_financeable_mcmv"
                component={Toggle}
              />
              <Field
                xs={6}
                label="Incluir minha rede?"
                name="is_on_network"
                component={Toggle}
              />
            </Row>
          </Col>
          {hasSide && (
            <Col xs={6}>
              <Field
                title="Características"
                name="characteristics"
                data={inputCharacteristics}
                options={characteristics}
                component={ListRequired}
              />

              <Field
                title="Características do Condomínio"
                name="condos_characteristics"
                data={inputCondos}
                options={condosCharacteristics}
                component={ListRequired}
              />

              <Field
                title="Estabelecimentos"
                name="establishments"
                data={inputEstablishments}
                options={establishments}
                component={ListRequired}
              />

              <Field
                labelKey="name"
                valueKey="id"
                title="Localização - Bairros"
                name="neighborhoods_id"
                options={neighborhoods}
                component={CheckboxList}
              />
            </Col>
          )}
        </Row>

        <ModalFooter>
          <Button color="white" colorText="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar Perfil
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

ProfileForm.defaultProps = defaultProps;
ProfileForm.propTypes = propTypes;

const selector = formValueSelector('ProfileForm');

const FormContainer = reduxForm({
  form: 'ProfileForm',
  validate,
  enableReinitialize: true,
})(ProfileForm);

const mapStateToProps = (state) => ({
  prices: selector(state, 'minimum_price', 'maximum_price'),
  inputNeighborhoods: selector(state, 'neighborhoods'),
  inputCharacteristics: selector(state, 'characteristics'),
  inputEstablishments: selector(state, 'establishments'),
  inputCondos: selector(state, 'condos_characteristics'),
  values: formValues('ProfileForm')(state),
  initialValues: valuesSelector(state),
});

const mapDispatchToProps = {
  handleNewClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
