import React, { createElement, useState } from 'react';
import Modal from 'react-modal';
import Menu from 'components/Menu';
import { ModalTemplate } from 'components/Modal';
import * as Pages from './pages';
import { Content } from './styles';

function ModalPersonOrulo({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  propsModal,
  reopenModal,
}) {
  const [page, setPage] = useState('Infos');

  const onClickMenu = (page) => {
    setPage(page);
  };

  return (
    <Modal
      {...modalConfig}
      className={{
        ...modalConfig.className,
        base: 'Modal Modal--medium',
      }}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={propsModal?.modalPerson?.person?.name}
        handleClose={handleClose}
      >
        <Menu
          border
          currentPage={page}
          handleClickItem={onClickMenu}
          style={{
            margin: '-20px -19px 0',
            borderTop: '0 none',
          }}
        >
          <Menu.item component="Infos">DADOS</Menu.item>
          <Menu.item component="Properties">IMÓVEIS</Menu.item>
        </Menu>
        <Content>
          {createElement(Pages[page], {
            ...propsModal,
            reopenModal: reopenModal,
            onSubmitSuccessEdit: propsModal.person.onSubmitSuccessEdit,
          })}
        </Content>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalPersonOrulo;
