import styled from 'styled-components';

export const Wrapper = styled.div`
  header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  img {
    max-width: 76px;
    max-height: 35px;
    object-fit: contain;
  }

  table {
    width: 100%;
    border: 0 none;
    border-collapse: collapse;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    td {
      padding: 20px;
      background-color: #fff;
    }

    tr:first-child td {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    tr:last-child td {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    tr + tr {
      td {
        border-top: 1px solid ${(p) => p.theme.borderColor};
      }
    }
  }

  & + & {
    margin-top: 15px;
  }
`;

export const CellSmall = styled.td`
  width: 1px;
  white-space: nowrap;
`;
