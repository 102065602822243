import React from 'react';
import { connect } from 'react-redux';
// Components
import Loading from 'components/Loading';
import MainTitle from 'components/MainTitle';
import FormFooter from './components/FormFooter';
// Services
import * as footersService from 'services/sites/footers';
import * as financialIndexes from 'services/financialIndex';
// Modules
import { fetchFooters, footersSelectors } from 'modules/components/footers';

const propTypes = {};
const defaultProps = {};

class Footer extends React.Component {
  state = {
    formValues: {},
    indexes: [],
    isFetchingIndexes: [],
    isFetching: true,
  };

  async componentDidMount() {
    // Busca todos os modelos de headers
    await this.props.fetchFooters();
    await this.fetchFinancialIndexes();

    // Busca os dados do formulário
    footersService.getOne().then(({ data }) => {
      this.setState({ isFetching: false });
      this.setValues(data);
    });
  }

  fetchFinancialIndexes = (params = { filter: {} }) => {
    this.setState({ isFetchingIndexes: true });

    return financialIndexes
      .getList({
        ...params,
        filter: { by_defaults: false, ...params.filter },
      })
      .then(({ data }) => {
        this.setState({ indexes: data });
      })
      .finally(() => {
        this.setState({ isFetchingIndexes: false });
      });
  };

  /**
   * Seta os valores no formulário
   * @param data
   */
  setValues = (data) => this.setState({ formValues: data });

  /**
   * Lida com o evento de submit do formulário
   * @param values - os valores do formulário
   * @return {Promise}
   */
  handleSubmit = (values) => footersService.update(values);

  render() {
    const { indexes, isFetchingIndexes, isFetching } = this.state;

    return (
      <>
        <MainTitle
          title="Rodapé"
          text="Defina o modelo de rodapé que será utilizado em seu site. Após isso, configure para que fique da maneira que desejar."
        />
        {isFetching ? (
          <Loading isVisible />
        ) : (
          <FormFooter
            footers={this.props.footers}
            indexes={indexes}
            isFetchingIndexes={isFetchingIndexes}
            initialValues={this.state.formValues}
            onSubmit={this.handleSubmit}
          />
        )}
      </>
    );
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  footers: footersSelectors.getAll(state),
});

const mapDispatchToProps = {
  fetchFooters,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
