import styled, { css } from 'styled-components';
import { opacify } from 'polished';
import FormGroup from '../FormGroup';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;
`;

export const FormGroupCustom = styled(FormGroup)`
  ${(p) =>
    p.theme.disabled &&
    css`
      pointer-events: none;

      ${Wrapper} {
        opacity: 0.4;
      }
    `}
`;

const itemBall = () => {
  const itemChecked = (p) => {
    if (p.isChecked) {
      return css`
        color: #fff;
        background-color: ${(p) => p.theme.colors.primary};
        border-color: transparent;
      `;
    }
  };

  return css`
    text-indent: -0.15em;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid ${(p) => p.theme.borderColor};

    &:focus {
      border-color: ${(p) => opacify('.54', p.theme.colors.primary)};
    }

    ${itemChecked};
  `;
};

const itemSquare = () => {
  const itemChecked = (p) => {
    if (p.isChecked) {
      return css`
        color: #fff;
        background-color: ${(p) => p.theme.colors.success};
      `;
    }
  };

  return css`
    background-color: #e0e5eb;
    border-radius: 4px;
    min-width: 32px;
    height: 32px;
    margin-top: 7px;

    & + & {
      margin-left: 5px;
    }

    ${itemChecked};
  `;
};

const itemTheme = (p) => {
  if (p.theme.type === 'square') return itemSquare();
  return itemBall();
};

export const Item = styled.button.attrs(() => ({
  type: 'button',
}))`
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;

  text-align: center;
  font-size: 14px;
  min-width: 22px;
  height: 22px;
  line-height: 20px;
  cursor: pointer;

  ${itemTheme};
`;
