import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import MainTitle from 'components/MainTitle';
import FormPost from './components/FormPost';
// Services
import * as postsService from 'services/sites/posts';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function Store() {
  const { id, type } = useParams();
  const [initialValues, setInitialValues] = useState({ type });
  const history = useHistory();

  const onSubmit = useCallback(values =>
    values.id ? postsService.update(values) : postsService.create(values)
  );

  const onSubmitSuccess = useCallback(() => {
    history.goBack();
  });

  useEffect(() => {
    async function fetchInitialValues() {
      const response = await postsService.getOne(id);

      // Seta os valores iniciais
      setInitialValues(response.data);
    }

    if (id) {
      fetchInitialValues();
    }
  }, [id]);

  return (
    <>
      <MainTitle
        title="Criar Postagem"
        text="Crie uma nova postagem para ser publicada em seu site"
      />
      <FormPost
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
      />
    </>
  );
}

Store.defaultProps = defaultProps;
Store.propTypes = propTypes;

export default Store;
