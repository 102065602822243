import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 15px 15px 15px 0px;
  margin: -15px 0 -15px 10px;

  .Avatar {
    margin-right: 10px;
  }
`;
export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  svg {
    position: absolute;
    top: 50%;
    right: -25px;
    transform: translateY(-50%);
    font-size: 20px;
    opacity: 0.3;
  }
`;
export const Title = styled.h5`
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
`;
export const Text = styled.small`
  font-size: 12px;
  opacity: 0.5;
  line-height: 14px;
  font-weight: 600;
`;

export const MenuWrapper = styled.div`
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  background: #fff;
  margin-top: 5px;
`;

export const Action = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: 100%;
  padding: 15px 30px;
  text-align: left;
  color: ${p => p.theme.colors.secondary};
  transition: background-color 0.2s ease-in-out;
  will-change: background-color;
  cursor: pointer;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  svg {
    font-size: 20px;
    margin-left: 5px;
  }
`;
