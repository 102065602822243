import React from 'react';
import PayButton from '../PayButton';

// import { Wrapper } from './styles';

function Options({ credit, contract }) {
  if (
    (credit.receiving_method === 3 && contract.type === 'Modelo anterior') ||
    credit.situation === 'Cancelado'
  )
    return false;

  return (
    !credit.is_received && (
      <PayButton
        receivingMethod={credit.receiving_method}
        creditId={credit.id}
        onPaySuccess={() => {}}
      />
    )
  );
}

export default Options;
