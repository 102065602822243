import { useQrcode } from "hooks/useQrcode";
import { Popover, Title, Wrapper } from "./styles";

function Poplink({ pin = 'left', link, children }) {
  const imageUrl = useQrcode(link);

  return (
    <Wrapper>
      <Popover ariaHidden={true} pin={pin}>
        <Title>Use a câmera do celular</Title>
        <img src={imageUrl} alt="Aplicativo" />
      </Popover>
      {children}
    </Wrapper>
  );
}

export default Poplink;
