import React, { useCallback } from 'react';
import Header from './components/Header';
import TimelineTree from 'components/TimelineTree';
import { openModalProperty } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { useRequest } from 'hooks/useRequest';
import { getHistory } from 'services/stories';

// import { Wrapper } from './styles';

function History({ property }) {
  const { data, isFetching, fetchData } = useRequest({
    request: (category) => getHistory({ logeable_id: property?.id, category }),
  });

  const dispatch = useDispatch();

  const handleChangeFilter = (value) => {
    fetchData(value);
  };

  const handleOpenMySelf = useCallback(
    (params) => {
      dispatch(openModalProperty({ property, ...params }));
    },
    [property, dispatch]
  );

  return (
    <div>
      <Header property={property} onChangeFilter={handleChangeFilter} />
      <TimelineTree
        isFetching={isFetching}
        data={data}
        onCloseModal={handleOpenMySelf}
      />
    </div>
  );
}

export default History;
