import styled from 'styled-components';
import { rgba } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius:5px;
  border-bottom-left-radius: 0; 
  border-bottom-right-radius: 0; 
  border: 1px solid #e1e4e8;

  &:nth-child(odd) {
    background: ${props => props.typeTransaction === 1 ? rgba(49, 208, 132, 0.05) : rgba(255, 151, 2, 0.05)};
  }

  .Avatar {
    width: 50px;
    height: 50px;
    margin-right: 8px;
    flex-shrink: 0;
    min-width: 1px;
  }
`;

export const PropertyPrice = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  align-items: center;
  gap: 7px;
  align-items: stretch;
  background: ${props => props.typeTransaction === 1 ? '#31d084' : '#FF9702'};
  color: #fff;
  margin-bottom: 5px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-weight: 500;

  span {
    text-align: center;
  }
`;

export const Content = styled.div`
  color: #000000;
`;

export const Rooms = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;

  svg {
    margin-top: -3px;
  }

  div + div {
    margin-left: 15px;
  }
`;
