import { useRequest } from 'hooks/useRequest';
import * as dashboardService from '../services/dashboard';

export const useMovimentsMonthly = () => {
  const { data, fetchData, isFetching } = useRequest({
    request: dashboardService.movimentsMonthlyDashboard,
    initialFetch: false,
  });

  return {
    movimentsMonthly: data,
    fetchMoviments: fetchData,
    movimentsMonthlyIsFetching: isFetching,
  };
};

export const useMovimentsProjection = () => {
  const { data, fetchData, isFetching } = useRequest({
    request: dashboardService.movimentsMonthlyProjection,
    initialFetch: false,
  });

  return {
    projection: data,
    fetchProjection: fetchData,
    projectionIsFetching: isFetching,
  };
};

export const useBillsMonthly = () => {
  const { data, fetchData, meta, isFetching } = useRequest({
    request: dashboardService.creditCardBillsMonthlyDashboard,
    initialFetch: false,
  });

  return {
    billsMonthly: data,
    fetchBills: fetchData,
    billsMonthlyMeta: meta,
    billsMonthlyIsFetching: isFetching,
  };
};
