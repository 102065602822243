import React from 'react';
// Components
import Nodata from 'components/Nodata';
import Loading from 'components/Loading';
import CardProperty from 'components/CardProperty';
// Assets
import house from './house-key.svg';

class PropertiesOfClient extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  componentDidMount() {
    this.props.fetchPropertiesPeople(this.props.person.id);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { matcheds: properties, isFetching } = this.props;

    if (isFetching) return <Loading isVisible />;

    if (properties.length === 0) {
      return (
        <Nodata
          style={{ maxWidth: 330 }}
          image={house}
          title="Nenhum imóvel encontrado."
          text="Quando algum imóvel for cadastrado e seu cliente for o proprietário, ele aparecerá aqui."
        />
      );
    }

    return properties.map((property) => {
      const condoTitle = property?.condominium?.title;
      let addressFormatted = property?.address_formatted;

      if (condoTitle) {
        addressFormatted += ` - ${condoTitle}`;
      }

      return (
        <CardProperty
          key={property?.id + 'properties-client'}
          type="horizontal"
          property={{
            ...property,
            address_formatted: addressFormatted,
          }}
          handleClickProperty={() => {
            this.props.openModalProperty({
              property: property,
              afterClose: () => {
                this.props.handleOpenMySelf();
              },
            });
          }}
        />
      );
    });
  }
}
export default PropertiesOfClient;
