import Bowser from 'bowser';

export const getBrowserName = () => {
  const { parsedResult } = Bowser.getParser(window.navigator.userAgent);
  return parsedResult?.browser?.name?.toLowerCase().replace(/\s/g, '-') || '';
};

export const addBrowserInHTML = () => {
  const html = document.querySelector('html');
  const browserName = getBrowserName();
  html.classList.add('is-' + browserName);
};
