import { ThemeProvider } from 'styled-components';
import * as S from './styles';

const Model2 = ({
  image,
  title,
  subtitle,
  description,
  logoUrl,
  backgroundColor,
  mainColor,
}) => {
  return (
    <ThemeProvider
      theme={{
        fontSize: '32px',
        backgroundColor,
        mainColor,
        logoUrl,
      }}
    >
      <S.Wrapper>
        <S.Image src={image} alt="" />
        <S.BoxWrapper>
          <S.BoxInner>
            <S.BoxHeader>
              <S.Title>{title}</S.Title>
            </S.BoxHeader>
            <S.Price>{subtitle}</S.Price>
            {description && (
              <S.Description>
                {description?.map((item) => (
                  <div>{item}</div>
                ))}
              </S.Description>
            )}
            <S.Spacer />
            {!!logoUrl ? (
              <S.Footer>
                <img src={logoUrl} alt="" />
              </S.Footer>
            ) : null}
          </S.BoxInner>
        </S.BoxWrapper>
      </S.Wrapper>
    </ThemeProvider>
  );
};

export default Model2;
