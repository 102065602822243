import PropTypes from 'prop-types';
import { Field } from 'redux-form';
// Components
import { SelectMultiple } from 'components/Form';
// Helpers
import { parseSelect } from 'lib/formHelpers';
// Services
import * as locationService from 'services/location';
import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';

function ZoneField({ name, cityId, options: propsOptions, valueKey, ...props }) {
  const { data: zones, fetchData } = useRequest({
    request: ({ cityId }) =>
      locationService.getZones({
        city_id: cityId,
        count: 'properties',
        with_defaults: true,
      }),
    initialFetch: false,
  });

  useEffect(() => {
    if (cityId) fetchData({ cityId });
  }, [cityId, fetchData]);

  const options = propsOptions || zones;

  return (
    <Field
      multi={false}
      name={name}
      options={options}
      disabled={!cityId}
      valueKey={valueKey}
      parse={parseSelect(valueKey)}
      {...props}
    />
  );
}

ZoneField.propTypes = {
  city: PropTypes.shape({
    zones: PropTypes.arrayOf(PropTypes.object)
  }),
  name: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  component: PropTypes.func,
  normalize: PropTypes.func
};

ZoneField.defaultProps = {
  label: 'Zona',
  name: 'zone_id',
  component: SelectMultiple,
  valueKey: 'value',
  labelKey: 'label'
};

export default ZoneField;
