import styled, { css } from 'styled-components';
import { Content as ContentMenu } from '../../styles';
import { getColor } from 'lib/color-helpers';

export const ContentInner = styled(ContentMenu)`
  left: 100%;
  top: 0;
  transform: none;
  transition: all 0.2s ease-in-out;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.2s ease-in-out;
  user-select: none;
  cursor: pointer;

  @media all and (min-height: 691px) {
    padding: 20px;
  }

  @media all and (min-height: 651px) and (max-height: 690px) {
    padding: 15px;
  }

  @media all and (max-height: 650px) {
    padding: 12px;
  }

  :hover {
    background: #fff;
  }

  ${ContentInner} {
    opacity: 0;
    visibility: hidden;
  }

  &:hover ${ContentInner} {
    opacity: 1;
    visibility: visible;
  }

  & + & {
    border-top: 1px solid #dedede;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

const iconColor = ({ color }) => {
  const _color = getColor(color);

  return css`
    background-color: ${_color};
  `;
};

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 15px;
  font-size: 20px;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  min-width: 0;
  color: #fff !important;

  svg {
    color: #fff !important;
    fill: #fff !important;
  }

  ${iconColor};
`;

export const WrapperIconDrop = styled.div`
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

export const Title = styled.div`
  font-size: 14px;
  color: #1c0c1e;
  margin-bottom: 5px;
  white-space: nowrap;
`;

export const Text = styled.div`
  font-size: 12px;
  color: #5c5c5c;
  font-weight: 100;
  white-space: nowrap;
`;
