// import styles from './styles'

import { Field, FieldArray, reduxForm } from 'redux-form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import { Col, Row } from 'react-flexbox-grid';
import { FieldBool, Input } from 'components/Form';
import { Fields } from '../../Fields';
import { useModal } from '../../hooks/useModal';
import Remove from '../../components/Remove';
import Item from '../../components/Item';
import AddProperty from '../../components/AddProperty';

const renderProperties = ({ fields, currentProperty }) => {
  return (
    <div>
      <AddProperty property={currentProperty} fields={fields} />
      {fields.map((property, index) => (
        <Item key={index}>
          <Remove fields={fields} index={index} />

          <Row>
            <Fields.Reference xs={3} name={`${property}.reference`} />
            <Fields.Price xs={3} name={`${property}.price`} />
            <Fields.Measure
              xs={2}
              name={`${property}.areas.${currentProperty?.primary_area?.name}`}
            />
          </Row>
          <Row className="h-margin-bottom--5">
            <Col xs={12}>
              <strong>Cômodos</strong>
            </Col>
          </Row>
          <Row>
            <Field
              xs={2}
              label="Dormitórios"
              name={`${property}.rooms.bedroom.value`}
              component={Input}
            />
            <Field
              xs={2}
              label="Sendo suíte"
              name={`${property}.rooms.suite.value`}
              component={Input}
            />
            <Field
              xs={2}
              label="Banheiros"
              name={`${property}.rooms.bathroom.value`}
              component={Input}
            />
            <Field
              xs={2}
              label="Garagem"
              name={`${property}.rooms.garage.value`}
              component={Input}
            />
            <FieldBool
              label="Garagem coberta"
              xs={2}
              name={`${property}.rooms.garage.extra.is_covered.value`}
            />
          </Row>
        </Item>
      ))}
    </div>
  );
};

function FormHouse({ handleSubmit, handleClose }) {
  const { property: currentProperty } = useModal();

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="properties"
        currentProperty={currentProperty}
        component={renderProperties}
      />

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Concluído
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormHouse',
  enableReinitialize: true,
})(FormHouse);
