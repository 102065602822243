import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Content = styled.div`
  position: relative;
  flex: 1 0;
  overflow: auto;
`;
