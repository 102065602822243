import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
`;

export const Reception = styled.div`
  padding: 25px;
  text-align: center;

  img {
    margin: 0 auto 15px;
  }

  h1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #1c0c1e;
    margin-bottom: 10px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
  }
`;
