import { useImages } from 'components/ModalImages/hooks/useImages';
import { AnimatePresence } from 'framer-motion';
import { Actions, Selectors } from 'modules/modalImages';
import { MdClose } from 'react-icons/md/index';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from './components/Carousel';
import GridImage from './components/GridImage';
import { Btn, CloseBtn, Content, Header, Wrapper } from './styles';

const CONFIG = {
  className: {
    base: 'Modal Modal--full-screen',
    afterOpen: 'Modal--after-open',
    beforeClose: 'Modal--before-close',
  },
  overlayClassName: {
    base: `ModalOverlay`,
    afterOpen: 'ModalOverlay--after-open',
    beforeClose: 'ModalOverlay--before-close',
  },
};

const CUSTOM_STYLES = {
  overlay: {
    padding: 0,
    backgroundColor: 'rgba(28, 12, 30, 0.95)',
    zIndex: 3050,
  },
};

function ModalImages({ images }) {
  const dispatch = useDispatch();
  const isOpen = useSelector(Selectors.isOpen);
  const isOpenCarousel = useSelector(Selectors.isOpenCarousel);

  const {
    gallery,
    setGallery,
    currentGallery,
    imagesLength,
    privateLength,
    plantsLength,
  } = useImages(images);

  const changeGallery = (galleryName) => () => {
    setGallery(galleryName);
  };

  return (
    <Modal {...CONFIG} style={CUSTOM_STYLES} isOpen={isOpen}>
      <Wrapper>
        <Header>
          <div className="h-flex__cell--grow" />
          <div>
            {imagesLength > 0 ? (
              <Btn
                isActive={gallery === 'images'}
                onClick={changeGallery('images')}
              >
                Fotos({imagesLength})
              </Btn>
            ) : null}
            {plantsLength > 0 ? (
              <Btn
                isActive={gallery === 'plants'}
                onClick={changeGallery('plants')}
              >
                Plantas({plantsLength})
              </Btn>
            ) : null}
            {privateLength > 0 ? (
              <Btn
                isActive={gallery === 'private'}
                onClick={changeGallery('private')}
              >
                Privadas({privateLength})
              </Btn>
            ) : null}
          </div>
          <div className="h-flex__cell--grow" />
          <CloseBtn onClick={() => dispatch(Actions.close())}>
            <MdClose />
          </CloseBtn>
        </Header>

        <Content>
          <Carousel
            images={currentGallery}
            isOpen={isOpenCarousel}
            onClose={() => {
              dispatch(Actions.closeCarousel());
            }}
          />

          <AnimatePresence exitBeforeEnter>
            {currentGallery?.map((image, i) => {
              return (
                <GridImage
                  key={image.id}
                  image={image}
                  onClick={() => {
                    dispatch(Actions.setCurrentImage(i));
                    dispatch(Actions.openCarousel());
                  }}
                />
              );
            })}
          </AnimatePresence>
        </Content>
      </Wrapper>
    </Modal>
  );
}

export default ModalImages;
