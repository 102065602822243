import React from 'react';
import PropTypes from 'prop-types';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

const propTypes = {
  checked: PropTypes.bool,
};

const defaultProps = {
  checked: false,
};

const Check = ({ checked, ...props }) =>
  checked ? (
    <MdCheckBox
      className="Check Check--checked h-color--success h-pointer"
      {...props}
    />
  ) : (
    <MdCheckBoxOutlineBlank
      className="Check Check--unchecked h-pointer"
      {...props}
    />
  );

Check.defaultProps = defaultProps;
Check.propTypes = propTypes;

export default Check;
