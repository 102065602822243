import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const defaultProps = {};

export const FilterWrapper = ({
  as: Component = 'div',
  children,
  className,
  ...props
}) => (
  <Component {...props} className={classnames('FilterAside', className)}>
    {children}
  </Component>
);
export const FilterTitle = ({ children }) => (
  <h4 className="FilterAside__title">{children}</h4>
);
export const FilterFooter = ({ children }) => (
  <>
    <div className="FilterAside__footer">{children}</div>
  </>
);
export const FilterContent = ({ children, style }) => (
  <div className="FilterAside__content" style={style}>
    {children}
  </div>
);

const FilterAside = ({ title, children, className }) => (
  <FilterWrapper className={className}>
    {title && <FilterTitle>{title}</FilterTitle>}
    <FilterContent>{children}</FilterContent>
  </FilterWrapper>
);

FilterAside.wrapper = FilterWrapper;
FilterAside.title = FilterTitle;
FilterAside.content = FilterContent;
FilterAside.footer = FilterFooter;

FilterAside.propTypes = propTypes;
FilterAside.defaultProps = defaultProps;

export default FilterAside;
