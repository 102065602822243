import React from 'react';
import Alert from 'react-s-alert';
// Components
import LoginHeader from './LoginHeader';
import App from './components/App';

export default function Login({ children }) {
  return (
    <div className="Login">
      <Alert stack={{ limit: 3 }} />
      <div className="Login__content">
        <LoginHeader />
        <div className="Login__form-wrapper">{children}</div>
      </div>
      <App />
    </div>
  );
}
