import { openConfirmation } from 'containers/ModalConfirmation/module';
import { createModule, item, uploadFiles } from 'lib/reducer-helpers';
import { hideLoading, showLoading } from 'modules/loading';
import * as locationService from 'services/location';

const createAction = createModule('addressesLocations');

// Actions
const REQUEST_CITIES = createAction('REQUEST_CITIES');
const RECEIVE_CITIES = createAction('RECEIVE_CITIES');

const REQUEST_NEIGHBORHOODS = createAction('REQUEST_NEIGHBORHOODS');
const RECEIVE_NEIGHBORHOODS = createAction('RECEIVE_NEIGHBORHOODS');

const REMOVE_CITY = createAction('REMOVE_CITY');
const REMOVE_NEIGHBORHOOD = createAction('REMOVE_NEIGHBORHOOD');

const initialState = {
  locations: {
    cities: [],
    neighborhoods: [],
  },
  meta: {
    isFetchingCities: false,
    isFetchingNeighborhoods: false,
  }
};

// Selectors
export const locationsSelector = state => state.addressesLocations.locations;

export const isFetchingCitiesSelector = state => state.addressesLocations.meta.isFetchingCities;
export const isFetchingNeighborhoodsSelector = state => state.addressesLocations.meta.isFetchingNeighborhoods;

export const isFetchingLocationsSelector = state => state.addressesLocations.meta.isFetchingCities || state.addressesLocations.meta.isFetchingNeighborhoods;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CITIES:
      return {
        ...state,
        meta: { ...state.meta, isFetchingCities: true }
      };
    case RECEIVE_CITIES:
      return {
        ...state,
        locations: {
          ...state.locations,
          cities: action.cities,
        },
        meta: { ...state.meta, isFetchingCities: false }
      };
    case REQUEST_NEIGHBORHOODS:
      return {
        ...state,
        meta: { ...state.meta, isFetchingNeighborhoods: true }
      };
    case RECEIVE_NEIGHBORHOODS:
      return {
        ...state,
        locations: {
          ...state.locations,
          neighborhoods: action.neighborhoods,
        },
        meta: { ...state.meta, isFetchingNeighborhoods: false }
      };
    case REMOVE_CITY:
      return {
        ...state,
        locations: {
          ...state.locations,
          cities: item.remove(state.locations.cities, action, 'city_id'),
        }
      };
    case REMOVE_NEIGHBORHOOD:
      return {
        ...state,
        locations: {
          ...state.locations,
          neighborhoods: item.remove(state.locations.neighborhoods, action, 'neighborhood_id'),
        }
      };
    default:
      return state;
  }
}

// Action Creators
export const requestCities = () => ({ type: REQUEST_CITIES });
export const receiveCities = (cities) => ({ type: RECEIVE_CITIES, cities });
export const requestNeighborhoods = () => ({ type: REQUEST_NEIGHBORHOODS });
export const receiveNeighborhoods = (neighborhoods) => ({ type: RECEIVE_NEIGHBORHOODS, neighborhoods });
export const removeCity = (id) => ({ type: REMOVE_CITY, city_id: id });
export const removeNeighborhood = (id) => ({ type: REMOVE_NEIGHBORHOOD, neighborhood_id: id });

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getCitiesDescriptions = params => async dispatch => {
  dispatch(requestCities());

  return locationService.getCitiesDescriptions(params).then(({ data }) => {
    dispatch(receiveCities(data));
  });
};

export const getNeighborhoodsDescriptions = params => async dispatch => {
  dispatch(requestNeighborhoods());

  return locationService.getNeighborhoodsDescriptions(params).then(({ data }) => {
    dispatch(receiveNeighborhoods(data));
  });
};

export const getLocations = (citiesParams, neighborhoodsParams) => async dispatch => {
  dispatch(getCitiesDescriptions(citiesParams));
  dispatch(getNeighborhoodsDescriptions(neighborhoodsParams));
}

export const handleRemoveCity = (id, params = {}) => dispatch => {
  dispatch(
    openConfirmation({
      title: 'Deseja excluir a cidade?',
      text: 'Essa ação não poderá ser desfeita',
      request: () => locationService.removeCityDescription(id),
      ...params,
      onSuccess: () => {
        if (params?.onSuccess) params.onSuccess();
        dispatch(removeCity(id));
      }
    })
  );
}

export const handleRemoveNeighborhood = (id, params = {}) => dispatch => {
  dispatch(
    openConfirmation({
      title: 'Deseja excluir o bairro?',
      text: 'Essa ação não poderá ser desfeita',
      request: () => locationService.removeNeighborhoodDescription(id),
      ...params,
      onSuccess: () => {
        if (params.onSuccess) params.onSuccess();
        dispatch(removeNeighborhood(id));
      }
    })
  );
}

export const handleAddCity = (values) => async dispatch => {
  const response = await locationService.createOrUpdateCityDescription(values);

  await uploadFiles({
    request: locationService.addImageCityDescription,
    params: (file, index) => ({
      image: file,
      name: file.name,
      cityId: response.data.id,
      order: index + 1,
    }),
    files: values.photos,
    onUploadStart: () => dispatch(showLoading()),
    onUploadEnd: async () => {
      dispatch(getLocations());
      dispatch(hideLoading());
    }
  });
}

export const handleFormCity = (values) => async dispatch => {
  if (!values.id) return dispatch(handleAddCity(values));
}
