import React from 'react';
import Menu from '../../components/Menu';
import MenuItem from '../../components/MenuItem';
import {
  MdBurstMode,
  MdFormatPaint,
  MdSettings,
  MdWebAsset,
} from 'react-icons/md';
import { FaBuilding } from 'react-icons/fa';
import { COLORS } from 'components/Menus/constants';
import Can from 'containers/Can';
import { colors } from 'constants/styles';
import { openModalSiteTemplate } from 'modules/modal';
import { useDispatch } from 'react-redux';

const MenuPeople = () => {
  const dispatch = useDispatch();

  return (
    <Menu title="Site" canBack>
      <Can run="EDIT_SITE_APPEARANCE">
        <MenuItem
          title="Modelos prontos"
          text="Escolha ou crie layouts exclusivos"
          icon={MdBurstMode}
          color={colors.success}
          onClick={() => {
            dispatch(openModalSiteTemplate());
          }}
        />
        <MenuItem
          icon={MdFormatPaint}
          to="/site/layout"
          title="Aparência do site"
          text="Personalize sua aparência"
          color={COLORS.lightPurple}
        />
      </Can>
      <Can run="EDIT_SITE">
        <MenuItem
          icon={MdSettings}
          to="/site/config"
          title="Configurações"
          text="Configure domínios e recursos"
          color={COLORS.green}
        />
      </Can>
      <Can run="EDIT_SITE_APPEARANCE">
        <MenuItem
          icon={MdWebAsset}
          to="/site/content-site"
          title="Conteúdo do site"
          text="Personalize partes do seu site"
          color={COLORS.purple}
        />
        <MenuItem
          to="/enterprise"
          icon={FaBuilding}
          color={COLORS.blue}
          title="Hotsite"
          text="Crie páginas dedicadas"
        />
      </Can>
    </Menu>
  );
};

export default MenuPeople;
