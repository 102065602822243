import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import JoyRide, { ACTIONS, LIFECYCLE } from 'react-joyride';
// Components
import Beacon from 'components/Beacon';
import Tooltip2 from './components/Tooltip2';

function Wizard({ callback, onClose, ...props }) {
  const handleCallback = useCallback((callbackProps) => {
    if (callback) callback(callbackProps);

    const { action, lifecycle } = callbackProps;

    const isClosed =
      action === ACTIONS.CLOSE && lifecycle === LIFECYCLE.COMPLETE;
    const isFinished = action === ACTIONS.RESET && lifecycle === LIFECYCLE.INIT;

    if ((isClosed || isFinished) && onClose) {
      onClose(callbackProps);
    }
  }, []);

  return (
    <JoyRide
      continuous
      spotlightPadding={0}
      styles={{
        offset: '25px',
        options: {
          beaconSize: '37px',
          arrowColor: '#1A051D',
          backgroundColor: '#1A051D',
          primaryColor: 'rgba(62, 88, 124, 0.65)',
          textColor: '#3e587c',
          width: 300,
          zIndex: 99192039,
          boxShadow: '0 2px 15px 0 rgba(62, 88, 124, 0.65)',
        },
      }}
      beaconComponent={Beacon}
      tooltipComponent={Tooltip2}
      {...props}
      scrollOffset={90}
      callback={handleCallback}
    />
  );
}

Wizard.propTypes = {
  beaconComponent: PropTypes.node,
  callback: PropTypes.func,
  continuous: PropTypes.bool,
  debug: PropTypes.bool,
  disableCloseOnEsc: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  disableOverlayClose: PropTypes.bool,
  disableScrolling: PropTypes.bool,
  disableScrollParentFix: PropTypes.bool,
  floaterProps: PropTypes.bool,
  getHelpers: PropTypes.func,
  hideBackButton: PropTypes.bool,
  locale: PropTypes.shape({
    back: PropTypes.string,
    close: PropTypes.string,
    last: PropTypes.string,
    next: PropTypes.string,
    skip: PropTypes.string,
  }),
  run: PropTypes.bool,
  scrollOffset: PropTypes.number,
  scrollToFirstStep: PropTypes.bool,
  showProgress: PropTypes.bool,
  showSkipButton: PropTypes.bool,
  spotlightClicks: PropTypes.bool,
  spotlightPadding: PropTypes.number,
  stepIndex: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node,
      disableBeacon: PropTypes.bool,
      event: PropTypes.string,
      isFixed: PropTypes.bool,
      offset: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      placement: PropTypes.oneOf([
        'top',
        'top-start',
        'top-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'left',
        'left-start',
        'left-end',
        'right',
        'right-start',
        'right-end',
        'auto',
        'center',
      ]),
      placementBeacon: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
      styles: PropTypes.object,
      target: PropTypes.oneOf([PropTypes.element, PropTypes.string]).isRequired,
      title: PropTypes.node,
    })
  ).isRequired,
  tooltipComponent: PropTypes.node,
};

Wizard.defaultProps = {
  run: true,
  continuous: true,
  disableCloseOnEsc: false,
};

export default Wizard;
