import styled, { css } from 'styled-components';

export const background = ({ theme: { background } }) => {
  if (background) {
    return css`
      background: ${background};
    `;
  }

  return css`
    background-image: linear-gradient(
        45deg,
        #cccccc 25%,
        transparent 25%,
        transparent 75%,
        #cccccc 75%,
        #cccccc
      ),
      linear-gradient(
        45deg,
        #cccccc 25%,
        transparent 25%,
        transparent 75%,
        #cccccc 75%,
        #cccccc
      );
    background-size: 10px 10px;
    background-position: 0 0, 5px 5px;
  `;
};

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.theme.width}px;
  height: ${p => p.theme.height}px;
  border-radius: 4px;
  padding: ${p => p.theme.padding}px;

  ${background};

  cursor: ${p => (!!p.onClick ? 'pointer' : 'default')};

  img {
    max-width: 100%;
    max-height: 100%;
    opacity: ${p => p.theme.opacity / 100};
  }
`;
