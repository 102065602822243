import React from 'react';
import {
  BarBackground,
  BarContent,
  Footer,
  Label,
  Value,
  Wrapper,
} from './styles';
import { ThemeProvider } from 'styled-components';
import { getConfigByValue } from 'lib/rating-helpers';

const DEFAULT_THEME = {
  barSize: '20px',
  ballSize: '37px',
  numberSize: '14px',
};

function Bar({ theme = DEFAULT_THEME, min = 0, max = 10, value }) {
  const config = getConfigByValue(value, min, max);

  return (
    <ThemeProvider
      theme={{
        ...theme,
        value,
        max,
        min,
        width: config.width,
        color: config.color,
      }}
    >
      <Wrapper>
        <Label>{config.label}</Label>
        <BarContent>
          <BarBackground>
            <Value>{value}</Value>
          </BarBackground>
        </BarContent>
        <Footer>
          <div>{min}</div>
          <div>{max}</div>
        </Footer>
      </Wrapper>
    </ThemeProvider>
  );
}

export default Bar;
