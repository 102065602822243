import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { permissionsSelector } from 'modules/login';

export function useCan({ run: action, permissions: ownPermissions }) {
  const usersPermissions = useSelector(permissionsSelector);

  const permissions = useMemo(() => ownPermissions || usersPermissions, [
    ownPermissions,
    usersPermissions
  ]);

  return useMemo(() => {
    if (!permissions) return false;
    return permissions.includes(action);
  }, [permissions, action]);
}
