import React from 'react';
import { MdWarning } from 'react-icons/md';
import PopoverRenew from './components/PopoverRenew';
// Helpers
import PropertiesList from 'containers/PropertyDashboard/containers/PropertiesList';
import * as dealsService from 'services/deals';
import { getPropertiesRentExpired } from 'services/dashboards/properties';

function PanelPropertyRentExpired({ updatedTime, updateTime }) {
  async function handleSubmitRenew(values) {
    await dealsService.updateDealEarned({
      id: values.id,
      departure_at: values.renew_date
    });

    updateTime();
  }

  return (
    <PropertiesList
      key="rent-expired"
      title="Contratos de aluguéis vencendo"
      icon={MdWarning}
      color="caution"
      renderNoData={() => <>Nenhum contrato de alugar para renovar</>}
      updatedTime={updatedTime}
      request={getPropertiesRentExpired}
      limit={3}
      afterClose={updateTime}
      renderText={deal => <p>Vence em: {deal.departure_at}</p>}
      renderAction={({ item }) => (
        <PopoverRenew
          data={{
            id: item?.earned_deal_id,
            renew_date: item?.departure_at
          }}
          onSubmit={handleSubmitRenew}
        >
          Renovar
        </PopoverRenew>
      )}
    />
  );
}

export default PanelPropertyRentExpired;
