import {
  funnelsIsFetchingSelector,
  funnelsSelector,
  getFunnel,
} from 'modules/funnel';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useFunnel = ({ userId } = {}) => {
  const dispatch = useDispatch();
  const funnels = useSelector(funnelsSelector);
  const isFetching = useSelector(funnelsIsFetchingSelector);

  useEffect(() => {
    let params = {};

    if (userId) {
      params = {
        user_id: userId,
      };
    }

    dispatch(getFunnel(params));
  }, [userId]);

  return { funnels, isFetching };
};
