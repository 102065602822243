import styled, { css } from 'styled-components';

const IMAGE_SIZE = 55;

export const Image = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
  margin-right: 0;
  cursor: pointer;

  background-color: #f1f1f1;
  background-image: url(${p => p.image});
  background-size: cover;
  background-repeat: no-repeat;

  :before {
    content: "${p => (p.image ? 'Trocar' : 'Enviar')}";
    z-index: 5;
    color: #fff;
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  ${p =>
    p.image &&
    css`
      :after,
      :before {
        opacity: 0;
        transition: all 0.2s;
      }

      :hover {
        :after,
        :before {
          opacity: 1;
        }
      }
    `}
`;
