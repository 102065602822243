import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
// Components
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import FieldPeopleGroup from 'containers/FieldPeopleGroups';
import FieldSelectUser from 'containers/FieldSelectUser';

const required = (value) => {
  return value || typeof value === 'number' ? undefined : 'Campo obrigatório';
};

function FormRdConfig({
  handleClose,
  defaultValues,
  handleSubmit,
  initialize,
}) {
  useEffect(() => {
    initialize(defaultValues);
  }, [defaultValues]);

  return (
    <form onSubmit={handleSubmit}>
      <Row className="h-margin-bottom--10">
        <Col xs={12}>
          <p>Selecione as categorias de clientes que devem ser integradas:</p>
        </Col>
      </Row>
      <Row style={{ marginBottom: '-15px' }}>
        <FieldPeopleGroup validate={required} name="groups_id" multi xs={12} />
        <FieldSelectUser
          validate={required}
          showAllUsers
          name="recipient_user_id"
          xs={12}
        />
      </Row>
      <ModalFooter>
        <Button
          onClick={handleClose}
          type="button"
          color="white"
          colorText="secondary"
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

FormRdConfig.defaultProps = {};
FormRdConfig.propTypes = {};

export default reduxForm({
  form: 'FormRdConfig',
})(FormRdConfig);
