import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

// Components
import Container from 'components/Container';
import MainTitle from 'components/MainTitle';

// HOC
import { withId } from '../../HOC/withId';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Services
import * as cadastralSituationService from 'services/propertiesPartial/cadastralSituation';
import * as situationsService from 'services/cadastralSituations';
import ListCheck from 'components/ListCheck';
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import Loading from 'components/Loading';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { useParams } from 'react-router';

const CadastralSituations = ({
  handleSubmit,
  onSubmit,
  initialize,
  submitting
}) => {
  const { id } = useParams();

  const { data, fetchData } = useRequest({
    request: cadastralSituationService.getOne,
    initialFetch: false
  });

  const { data: situations } = useRequest({
    request: situationsService.getList
  });

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    initialize(data);
  }, [data]);

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle
        title="Documentos/Certificados que o imóvel possui"
        text="Defina todos os documentos disponíveis deste imóvel."
      />
      <Container>
        <ListCheck name="cadastral_situations" data={situations} />
      </Container>

      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyCadastralSituations',
    onSubmit: (values, d, p) => cadastralSituationService.update(values),
    onSubmitSuccess: (response, dispatch, p) => {
      p.initialize(response?.data);
      p.next('private');
    }
  }),
  withFormConfirmation()
)(CadastralSituations);
