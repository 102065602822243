import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Components
import Image from 'components/Image';
import { ColLeft, ColRight, Wrapper } from './styles';

export const Title = ({ children }) => (
  <h4 className="NoContent__title">{children}</h4>
);

export const Text = ({ children }) => (
  <p className="NoContent__text h-color--primary">{children}</p>
);

const defaultProps = {
  size: '550px',
  reversed: false,
  full: false,
  image: null,
  title: null,
  text: null,
  children: null,
  style: null,
  styleWrapper: null,
};

const propTypes = {
  type: PropTypes.oneOf(['simple', 'compact']),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  reversed: PropTypes.bool,
  full: PropTypes.bool,
  image: PropTypes.string,
  title: PropTypes.node,
  text: PropTypes.node,
  children: PropTypes.node,
  style: PropTypes.object,
  styleWrapper: PropTypes.object,
};

export const NoContent = ({
  full,
  reversed,
  type = 'simple',
  size,
  image,
  title,
  text,
  children,
  styleImage,
  style,
  styleWrapper,
}) => {
  if (type === 'compact') {
    return (
      <Wrapper className="NoContent">
        <ColLeft size={size}>
          <Title>{title}</Title>
          <Text>{text}</Text>
          {children}
        </ColLeft>
        {image && (
          <ColRight>
            <img src={image} alt="" />
          </ColRight>
        )}
      </Wrapper>
    );
  }

  return (
    <div
      className={classnames('NoContent', {
        'NoContent--full': full,
        'NoContent--reversed': reversed,
      })}
      style={style}
    >
      <div
        className="NoContent__wrapper"
        style={{ maxWidth: size, ...(styleWrapper || {}) }}
      >
        {image && (
          <div className="NoContent__image">
            <Image src={image} alt={title || 'Imagem'} style={styleImage} />
          </div>
        )}
        <div className="NoContent__container">
          {title && <Title>{title}</Title>}
          {text && <Text>{text}</Text>}
          {children}
        </div>
      </div>
    </div>
  );
};

NoContent.defaultProps = defaultProps;
NoContent.propTypes = propTypes;
NoContent.title = Title;
NoContent.text = Text;

export default NoContent;
