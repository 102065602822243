import React from 'react';
import { Box, Buttons } from './styles';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { openModalPayment } from 'modules/modal';
import { shareWhatsapp } from 'components/ChatBox';

function Nodata() {
  const dispatch = useDispatch();

  const handleClickContract = () => {
    dispatch(openModalPayment());
  };

  return (
    <div>
      <Box>
        <p>
          No momento você não possui nenhum contrato ativo.
          <br />
          Clique abaixo para Contratar.
        </p>
        <Buttons>
          <Button onClick={handleClickContract} block color="secondary">
            Contratar o Tecimob
          </Button>
          <Button
            tagName="a"
            target="_blank"
            href={shareWhatsapp('554896049333')}
            rel="noreferrer"
            block
            color="success"
          >
            Conversar com consultor
          </Button>
        </Buttons>
      </Box>
    </div>
  );
}

export default Nodata;
