import { Wrapper } from './styles';
// import Avatar from 'components/Avatar';
import { handleUploadImage } from 'modules/users';
import { useDispatch } from 'react-redux';
import UserAvatar from '../UserAvatar';

function AvatarCell({ user }) {
  const dispatch = useDispatch();

  const uploadImage = (user) => async (file) => {
    dispatch(handleUploadImage(user, file));
  };

  return (
    <Wrapper>
      <UserAvatar user={user} handleUploadImage={uploadImage} />
    </Wrapper>
  );
}

export default AvatarCell;
