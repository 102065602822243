import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import Container from 'components/Container';
import ListOptions from 'components/ListOptions';
import { FieldBool } from 'components/Form';
import FixedBar from 'components/FixedBar';
import Aside from 'pages/Config/pages/Permissions/components/Aside';
import { ListItem } from 'pages/Config/pages/Permissions/components/ListAside';
import Loading from 'components/Loading';
import { Item, Text } from './styles';
import subItem from './sub-item.svg';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

class FormPermissions extends React.Component {
  state = {
    // usado para saber qual área está selecionada
    titleArea: null,
  };

  /**
   * Pega as sections da área selecionada
   * @return {Object}
   */
  get sections() {
    try {
      const currentArea = this.getAreaSelected();
      return currentArea.sections;
    } catch {
      return null;
    }
  }

  componentDidMount() {
    this.selectFirstArea();
  }

  selectFirstArea = () => {
    const { areas } = this.props;
    try {
      this.setState({ titleArea: areas[0].title });
    } catch {
      console.warn('Não foi possível selecionar a primeira área da lista');
    }
  };

  /**
   * Retorna a área selecionada
   * @return {null|*}
   */
  getAreaSelected() {
    const { areas } = this.props;
    const { titleArea } = this.state;
    try {
      return areas.find(({ title }) => title === titleArea);
    } catch {
      return null;
    }
  }

  /**
   * Seta o id de uma área
   * @param id
   */
  setAreaByTitle = (title) => () => {
    this.setState({ titleArea: title });
  };

  /**
   * Marca todas as permissões do formulario
   * @param {array<string>} permissionNames
   * @return {null}
   */
  checkAll = (permissionNames) => {
    const { change } = this.props;
    if (!permissionNames) return null;

    permissionNames.forEach((name) => {
      change(`permissions[${name}]`, true);
    });
  };

  /**
   * Desmarca todas as permissões do formulario
   * @param {array<string>}permissionNames
   * @return {null}
   */
  uncheckAll = (permissionNames) => {
    const { change } = this.props;
    if (!permissionNames) return null;

    permissionNames.forEach((name) => {
      change(`permissions[${name}]`, false);
    });
  };

  handleChangePermission = (permission) => (e, value) => {
    value
      ? this.checkAll(permission.allows)
      : this.uncheckAll(permission.denies);
  };

  renderPermissions(permissions, level = 1) {
    if (!permissions) return null;

    return permissions.map((permission) => {
      const hasPermissions = permission.permissions.length > 0;

      const hasBorder = !!hasPermissions || level > 1;

      return (
        <>
          <Item
            level={level}
            data-key={permission.name}
            hasBorder={hasBorder}
            key={permission.name}
            title={
              level <= 1 ? (
                <strong>
                  {permission.title} {}
                </strong>
              ) : (
                <Text style={{ fontWeight: 'normal' }}>
                  <img src={subItem} alt="" /> {permission.title}
                </Text>
              )
            }
            renderOptions={() => (
              <FieldBool
                data-permission={permission.name}
                name={`permissions[${permission.name}]`}
                onChange={this.handleChangePermission(permission)}
                style={{ width: '134px' }}
              />
            )}
          />
          {permission.permissions &&
            this.renderPermissions(permission.permissions, level + 1)}
        </>
      );
    });
  }

  get renderAreas() {
    const { titleArea } = this.state;
    const { areas } = this.props;

    if (!areas) return null;

    return (
      <Aside title="Áreas">
        {areas.map(({ title }) => (
          <ListItem
            isActive={titleArea === title}
            key={`${title}-area`}
            onClick={this.setAreaByTitle(title)}
          >
            {title}
          </ListItem>
        ))}
      </Aside>
    );
  }

  get renderSections() {
    if (!this.sections) return null;

    return this.sections.map((group) => (
      <Container padding style={{ marginBottom: 20 }}>
        <ListOptions>
          <ListOptions.item title={group.title} />
          {this.renderPermissions(group.permissions)}
        </ListOptions>
      </Container>
    ));
  }

  render() {
    const { submitting, handleSubmit } = this.props;

    return (
      <>
        {this.renderAreas}
        <form onSubmit={handleSubmit} className="Permissions__content">
          {submitting && <Loading isVisible isFullComponent />}
          {this.renderSections}
          <FixedBar style={{ left: '240px' }}>
            <FixedBar.spacer />
            <FixedBar.item>
              <Button type="submit" color="success">
                Salvar
              </Button>
            </FixedBar.item>
          </FixedBar>
        </form>
      </>
    );
  }
}

FormPermissions.defaultProps = defaultProps;
FormPermissions.propTypes = propTypes;

export default reduxForm({
  enableReinitialize: true,
})(FormPermissions);
