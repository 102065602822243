import React from 'react';
import TablePayments from './components/TablePayments';
import TableServices from './components/TableServices';
import DescriptionUpdate from './components/DescriptionUpdate';
import { ColLeft, ColRight, Content, Wrapper } from './styles';
import * as contractsService from 'services/financialv2/contracts';
import { useRequest } from 'hooks/useRequest';
import Loading from 'components/Loading';

function Detail({ contract }) {
  const { data, fetchData, isFetching } = useRequest({
    request: () => contractsService.getContract({ id: contract.id }),
    initialState: {},
  });

  if (isFetching) {
    return (
      <Wrapper>
        <td colSpan={7}>
          <Content>
            <ColLeft>
              <Loading isVisible={isFetching} />
            </ColLeft>
            <ColRight>
              <Loading isVisible={isFetching} />
            </ColRight>
          </Content>
        </td>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <td colSpan={7}>
        <Loading isVisible={isFetching} isBlock />
        <Content>
          <ColLeft>
            <TableServices contract={data} />
          </ColLeft>
          <ColRight>
            <DescriptionUpdate contract={data} />
            <TablePayments
              data={data.credits}
              values={data.values}
              contract={contract}
              onCancel={fetchData}
            />
          </ColRight>
        </Content>
      </td>
    </Wrapper>
  );
}

export default Detail;
