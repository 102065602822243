import React, { useMemo, useEffect, useState } from 'react';
import { map } from 'lodash';
import { Row, Col } from 'react-flexbox-grid';
import CountryField from 'containers/LocationFields/CountryField';
import StateField from 'containers/LocationFields/StateField';
import CityField from 'containers/LocationFields/CityField';
import { Field } from 'redux-form';
import { Input, Checkbox } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import { normalizeSearch } from 'lib/text';
import { useDebouncedCallback } from 'use-lodash-debounce';
import { FieldsKey } from 'containers/LocationFields/constants';
import { useDispatch, useSelector } from 'react-redux';
import * as locationService from 'services/location';
import { getNeighborhoods } from 'services/location';
import { Actions as LocationActions, Selectors as LocationSelector } from 'containers/LocationFields/module';
import useReduxForm from 'hooks/useReduxForm';
// import { Container } from './styles';

const Location = ({ formName }) => {
  const [selecteds, setSelecteds] = useState([]);

  const cityId = useFormValue('city_id');
  const country_id = useFormValue('country_id');
  const state_id = useFormValue('state_id');
  const neighborhoodIds = useFormValue(`neighborhoods_id`);
  const filter = useFormValue('filter_neighborhoods');

  const dispatch = useDispatch();
  const { change } = useReduxForm();

  // Pega os bairros
  const neighborhoods = useSelector(state =>
    LocationSelector.getData(formName, FieldsKey.neighborhood)(state)
  );

  async function fetchNeighborhood(cityId, ids) {
    if (!ids || ids.length <= 0) {
      return setSelecteds([]);
    }

    const { data } = await getNeighborhoods({
      city_id: cityId,
      filter: { id: ids }
    });

    setSelecteds(data);
  }

  const handleChangeCity = (e, city_id) => {
    if (!city_id) return;
    dispatch(change('neighborhoods', {}));
  };

  const fetchNeighborhoods = async ({ name, city_id }) => {
    // Marca que está carregando os bairros
    dispatch(LocationActions.request(formName, FieldsKey.neighborhood));

    const res = await locationService.getNeighborhoods({
      filter: { name },
      city_id
    });

    // Marca que recebeu os dados corretamente
    // e popula os bairros
    dispatch(
      LocationActions.receive(formName, FieldsKey.neighborhood, res.data)
    );

    return res;
  };

  const filterNormalized = useMemo(() => normalizeSearch(filter), [filter]);

  const filteredNeighborhoods = useMemo(() => {
    if (!neighborhoods) return [];
    if (!filterNormalized) return neighborhoods;

    return neighborhoods.filter(({ name }) =>
      normalizeSearch(name)?.includes(filterNormalized)
    );
  }, [filterNormalized, neighborhoods]);

  const handleChange = (e, value) => {
    if (value.length > 5) {
      fetchNeighborhoods({ name: value, city_id: cityId });
    }
  };

  const handleChangeDebounced = useDebouncedCallback(handleChange, 800);

  useEffect(() => {
    fetchNeighborhood(cityId, neighborhoodIds);
  }, [cityId, neighborhoodIds]);

  useEffect(() => {
    dispatch(LocationActions.register('ProfileForm', FieldsKey.neighborhood));
  }, [dispatch]);

  return (
    <>
      <Row>
        <CountryField xs={3} name="country_id" />
        <StateField xs={3} name="state_id" countryId={country_id} />
        <CityField
          xs={6}
          name="city_id"
          stateId={state_id}
          onChange={handleChangeCity}
        />
      </Row>
      {!!cityId && (
        <Row>
          <Field
            label="Buscar bairro"
            xs={6}
            name="filter_neighborhoods"
            component={Input}
            placeholder="Ex.: Centro"
            onChange={handleChangeDebounced}
          />
        </Row>
      )}

      <Row>
        {filteredNeighborhoods &&
          filteredNeighborhoods.map((neighborhood, i) => (
            <Col key={neighborhood.id} xs={3}>
              <Field
                id={neighborhood.id}
                type="checkbox"
                name={`neighborhoods_id`}
                label={neighborhood.name}
                component={Checkbox}
                className="ListCheck__item"
                inputValue={neighborhood.id}
              >
                {neighborhood.name}
              </Field>
            </Col>
          ))}
      </Row>

      {selecteds?.length > 0 && (
        <Row>
          <Col xs={12}>
            <p className="h-margin-bottom--10">
              <strong>Bairros selecionados:</strong>
            </p>
            <Row>
              {map(selecteds, neighborhood => {
                if (!neighborhood?.id) return null;

                return (
                  <Col key={neighborhood.id} xs={3}>
                    <Field
                      className="ListCheck__item"
                      id={neighborhood?.id}
                      type="checkbox"
                      name={`neighborhoods_id`}
                      label={neighborhood?.name}
                      component={Checkbox}
                      inputValue={neighborhood?.id}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Location;
