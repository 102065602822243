import { useModalPayment } from "containers/ModalRoot/ModalPayment/contexts/payment";
import { formatter } from "containers/ModalRoot/ModalPayment/utils/moneyFormatter";

const Total = () => {
  const { total } = useModalPayment();

  return (
    <tr>
      <td></td>
      <td></td>
      <td className="total-label">Total</td>
      <td className="total-price">R$ {formatter.format(total)}</td>
    </tr>
  );
}

export default Total;