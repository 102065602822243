import React from 'react';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Form from './components/Form';
import { useRequest } from 'hooks/useRequest';
import { getOne, update } from 'services/settings/search-profile-config';

function Profile() {
  const { data: initialValues, fetchData } = useRequest({
    request: getOne,
  });

  return (
    <Wrapper.container>
      <MainTitle
        title="Perfil de interesse de cliente"
        text="Defina as configurações padrão utilizadas na geração do perfil de cliente"
      />
      <Form
        initialValues={initialValues}
        onSubmit={update}
        onSubmitSuccess={() => {
          fetchData();
        }}
      />
    </Wrapper.container>
  );
}

export default Profile;
