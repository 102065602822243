import React from 'react';
// Components
import { ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import CardProperty from 'components/CardProperty';
import StarsRating from 'components/StarsRating';
import ListAudits from 'components/ListAudits';
import Popover from 'components/Popover';
import FormMoveDeal from 'components/FormMoveDeal';

const propTypes = {};

const defaultProps = {};

const buttonProps = {
  className: 'ButtonGroup__action',
  size: 'small',
  color: 'white',
  colorText: 'secondary',
  style: { marginLeft: '0' }
};

const ModalInfo = ({
  audits,
  events,
  infos,
  interest,
  funnels,
  isVisibleEvent,
  handleChangeModal,
  handleClickToggleEvent,
  handleClickGain,
  handleSubmitMoveDeal,
  handleSubmitEvent,
  handleClose,
  children,
  handleSetInterest,
  openModalProperty,
  ...props
}) => (
  <ModalTemplate
    isLoading={props.isFetching}
    renderHeader={() => (
      <header className="Modal__header Modal__header--custom h-flex h-flex--center-center">
        <div className="h-flex__cell h-flex__cell--shrink">
          <h4 className="Modal__title h-margin-bottom--10">
            {infos.person.name}
            <small className="h-margin-left--10">
              <StarsRating
                rating={interest}
                onChange={value =>
                  handleSetInterest(props.stage.id, props.id, value)
                }
              />
            </small>
          </h4>
          <p className="Modal__text">
            {infos.person.phone_number}
            {infos.person.email && (
              <React.Fragment>
                {' '}
                /{' '}
                <a
                  href={`mailto:${infos.person.email}`}
                  className="h-color--secondary"
                >
                  {infos.person.email}
                </a>
              </React.Fragment>
            )}
          </p>
        </div>
        <div className="h-flex__cell h-flex__cell--grow" />
        <div className="h-flex">
          <div className="ButtonGroup">
            <Button {...buttonProps}>Enviar E-mail</Button>
            <Popover
              onSubmitSuccess={props.onSubmitSuccess}
              funnels={funnels}
              component={FormMoveDeal}
              onSubmit={values => {
                const {
                  id: dealId,
                  stage: { id: stageId }
                } = props;
                return handleSubmitMoveDeal(stageId, dealId, values);
              }}
            >
              <Button {...buttonProps}>Posição no Funil</Button>
            </Popover>
          </div>
        </div>
      </header>
    )}
    handleClose={handleClose}
  >
    <CardProperty
      type="horizontal"
      property={infos.property}
      onClick={() => openModalProperty({ property: infos.property })}
    >
      <Button
        size="small"
        color="success"
        onClick={handleClickGain(infos.property)}
      >
        Ganho
      </Button>
      <Button
        size="small"
        color="danger"
        onClick={() => handleChangeModal('ModalLoss')}
      >
        Perdido
      </Button>
    </CardProperty>
    <ListAudits data={audits} style={{ marginTop: 15 }} />
  </ModalTemplate>
);

ModalInfo.propTypes = propTypes;
ModalInfo.defaultProps = defaultProps;

export default ModalInfo;
