import React from 'react';
import { connect } from 'react-redux';
// Helpers
// Modules
import { openModalPerson, openModalProperty } from 'modules/modal';
// Components
import Table from 'components/Table';
// Services
import * as propertiesMatched from 'services/properties-matched';

class Send extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    data: [],
    isFetching: true,
  };

  componentDidMount() {
    this.fetchMatcheds();
  }

  fetchMatcheds = async () => {
    const { property } = this.props;
    this.setState({ isFetching: true });
    const { data: properties } = await propertiesMatched.getMatcheds(property);
    this.setState({ data: properties, isFetching: false });
  };

  handleOpenPerson = (person) => {
    this.props.openModalPerson({
      ...person,
      afterClose: () => {
        this.props.openModalProperty({
          property: { ...this.props.infos },
        });
      },
    });
  };

  render() {
    const { data } = this.state;

    return (
      <Table
        style={{ width: 'calc(100% + 40px)', marginLeft: '-19px' }}
        data={data}
        renderTitle={() => (
          <tr>
            <th>Data do envio</th>
            <th>Canal</th>
            <th>Corretor</th>
            <th>Cliente</th>
          </tr>
        )}
        renderItem={({ id, sent_with, sent_at, user, person }) => (
          <tr key={id}>
            <td>{sent_at}</td>
            <td>{sent_with}</td>
            <td>{user ? user.name : '-'}</td>
            <td>
              <span
                className="h-link"
                onClick={() => this.handleOpenPerson(person)}
              >
                {person ? person.name : '-'}
              </span>
            </td>
          </tr>
        )}
      />
    );
  }
}

const mapDispatchToProps = {
  openModalPerson,
  openModalProperty,
};

export default connect(null, mapDispatchToProps)(Send);
