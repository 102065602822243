import React from 'react';
import { useRouteMatch } from 'react-router';
import MenuRouter, { MenuLink } from 'components/MenuRouter';
import { useGallery } from 'components/PropertyGallery/context';
// import { Container } from './styles';

const Menu = () => {
  useGallery();
  let route = useRouteMatch();

  return (
    <MenuRouter color="secondary" className="h-margin-bottom--15">
      <MenuLink exact to={`${route.url}/gallery/1`}>
        FOTOS DO IMÓVEL
      </MenuLink>
      <MenuLink exact to={`${route.url}/gallery/2`}>
        FOTOS PLANTAS
      </MenuLink>
      <MenuLink exact to={`${route.url}/gallery/3`}>
        FOTOS PRIVADAS
      </MenuLink>
    </MenuRouter>
  );
};

export default Menu;
