import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment-duration-format';

moment.locale('pt-br');

export const format = {
  date: 'DD/MM/YYYY',
  time: 'HH:mm',
  datetime: 'DD/MM/YYYY HH:mm',
  datetimezone: 'DD/MM/YYYY HH:mm ZZ'
};

export default moment;
