import Button from 'components/Button';
import { FieldImage, Input } from 'components/Form';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import { Field, reduxForm } from 'redux-form';
import * as partnersService from 'services/sites/partners';

const normalizeUrl = (value) => {
  if (!value) {
    return '';
  }

  // Remove espaços em branco no começo e final do valor
  value = value.trim();

  if (value.startsWith('https://') && value === 'https://') {
    return '';
  }

  if (value && !value.startsWith('https://')) {
    return `https://${value}`;
  }

  return value;
};

function ModalPartnersStore({
  handleSubmit,
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  id,
  submitting,
  initialize,
}) {
  const { data, isFetching, fetchData } = useRequest({
    request: () => partnersService.getOne({ id }),
    initialFetch: false,
    initialState: {},
  });

  const isEditting = id !== null;

  useEffect(() => {
    initialize(data);
  }, [data, initialize]);

  useEffect(() => {
    if (!!id) {
      fetchData();
    }
  }, [isEditting]);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={isEditting ? 'Editando parceiro' : 'Novo parceiro'}
        handleClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={5}>
              <FieldImage
                imageSize="small"
                name="image"
                text={
                  <>
                    Envie uma imagem
                    <br />
                    <span
                      className="h-text-nowrap"
                      style={{ color: 'inherit' }}
                    >
                      Tamanho recomendado 200x200px
                    </span>
                  </>
                }
                fileUrlField="file_url"
              />
            </Col>
            <Col xs={7}>
              <Field label="Título" xs={12} name="title" component={Input} />
              <Field
                xs={12}
                label="Url do site"
                name="url"
                component={Input}
                placeholder="Digite o link"
                normalize={normalizeUrl}
              />
            </Col>
          </Row>

          <ModalFooter>
            <Button color="white" onClick={handleClose}>
              Fechar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button color="success" disabled={submitting} type="submit">
              {isEditting ? 'Editar parceiro' : 'Adicionar parceiro'}
            </Button>
          </ModalFooter>
        </form>
      </ModalTemplate>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalPartnersStore',
  onSubmit: (values) => partnersService.createOrUpdate(values),
})(ModalPartnersStore);
