import React, { useEffect } from 'react';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import WizardEnterprise from 'containers/Wizards/WizardEnterprise';
import useReduxForm from 'hooks/useReduxForm';
import { useHistory } from 'react-router';

const FormFixedBar = () => {
  const history = useHistory();
  const { change, submit, submitting } = useReduxForm();
  const { setIsNextPage, prevPage, hasPrev } = useEnterprise();

  useEffect(() => {
    change('next_page', true);
  }, [change]);

  return (
    <FixedBar left={240}>
      <WizardEnterprise />
      {hasPrev ? (
        <FixedBar.item>
          <Button
            disabled={submitting}
            type="button"
            color="white"
            colorText="secondary"
            onClick={prevPage}
          >
            Anterior
          </Button>
        </FixedBar.item>
      ) : (
        <FixedBar.item>
          <Button
            disabled={submitting}
            type="button"
            color="white"
            colorText="secondary"
            onClick={() => history.goBack(-1)}
          >
            Voltar
          </Button>
        </FixedBar.item>
      )}
      <FixedBar.spacer />
      <FixedBar.item>
        <Button
          disabled={submitting}
          className="js-next-button"
          type="submit"
          color="white"
          colorText="primary"
        >
          Salvar
        </Button>
      </FixedBar.item>
      <FixedBar.item>
        <Button
          disabled={submitting}
          className="js-next-button"
          type="button"
          color="success"
          onClick={async () => {
            setIsNextPage(true);

            setTimeout(() => {
              submit();
            }, 200);
          }}
        >
          Salvar e próximo
        </Button>
      </FixedBar.item>
    </FixedBar>
  );
};

export default FormFixedBar;
