import { createContext, useContext, useState } from 'react';

export const ModalContractExtraContext = createContext({
  item: '',
  preview: {},
  preview2: {},
  setPreview2: () => null,
  handleClose: () => null,
  onSuccess: () => null,
  isLoading: false,
  isSuccess: false,
  hasError: false,
  setIsLoading: () => null,
  setIsSuccess: () => null,
  setHasError: () => null,
  isCancel: false,
});

export const ModalContractExtraProvider = ({
  item,
  preview,
  handleClose,
  onSuccess,
  isLoading,
  isSuccess,
  hasError,
  setIsLoading,
  setIsSuccess,
  setHasError,
  children,
  isCancel,
}) => {
  const [preview2, setPreview2] = useState(null);

  return (
    <ModalContractExtraContext.Provider
      value={{
        handleClose,
        item,
        preview,
        preview2,
        setPreview2,
        onSuccess,
        isLoading,
        isSuccess,
        hasError,
        setIsLoading,
        setIsSuccess,
        setHasError,
        isCancel,
      }}
    >
      {children}
    </ModalContractExtraContext.Provider>
  );
};

export const useModalContractExtra = () =>
  useContext(ModalContractExtraContext);
