import styled from 'styled-components';
import { em } from 'polished';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.color};
  height: calc(100% + 31px);
  width: 80px;
  margin: -15px;
  color: #fff;
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 16px;
`;
export const NumberBig = styled.span`
  font-size: ${em(16)};
  font-weight: bold;
`;
export const NumberSmall = styled.span`
  font-size: ${em(12)};
`;
export const Label = styled.div`
  font-weight: 500;
  font-size: ${em(12)};
  margin-top: ${em(15)};
`;
