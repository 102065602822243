import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { connect } from 'react-redux';
import Aside from 'pages/Config/pages/Permissions/components/Aside';
import GroupAdd from 'pages/Config/pages/Permissions/containers/GroupAdd';
import { ListItem } from 'pages/Config/pages/Permissions/components/ListAside';
import { openModalGroupUserRemove } from 'modules/modal';
// Modules
import {
  fetchGroupsWithoutDefault,
  groupsSelectors,
  removeGroup,
} from 'modules/groups';

class GroupsList extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  async componentDidMount() {
    const { fetchGroupsWithoutDefault } = this.props;
    const { data } = await fetchGroupsWithoutDefault();

    if (data) {
      this.selectFirstGroup();
    }
  }

  selectFirstGroup() {
    const { groups } = this.props;
    this.changeGroup(groups[0].id);
  }

  changeGroup = (groupId) => {
    const { onChangeGroup } = this.props;
    this.setState({ groupId }, () => {
      if (onChangeGroup) onChangeGroup(groupId);
    });
  };

  handleClickGroup = (groupId) => () => {
    this.changeGroup(groupId);
  };

  handleClickRemove = (group) => async (e) => {
    e.stopPropagation();
    const { fetchGroupsWithoutDefault, openModalGroupUserRemove } = this.props;

    openModalGroupUserRemove({
      group,
      onSubmitSuccess: async () => {
        await fetchGroupsWithoutDefault();
        this.selectFirstGroup();
      },
    });
  };

  onAddGroupSuccess = (props) => {};

  get renderGroups() {
    const { groupId, groups } = this.props;

    if (!groups) return null;

    return groups.map((group) => (
      <ListItem
        isActive={groupId === group.id}
        key={group.id}
        onClick={this.handleClickGroup(group.id)}
        renderOption={() => (
          <>
            <GroupAdd group={group}>
              <MdEdit className="h-color--primary" />
            </GroupAdd>
            <MdDelete
              className="h-color--danger"
              onClick={this.handleClickRemove(group)}
            />
          </>
        )}
      >
        {group.title}
      </ListItem>
    ));
  }

  render() {
    return (
      <Aside
        title="Grupos"
        renderAction={() => <GroupAdd onSuccess={this.onAddGroupSuccess} />}
      >
        {this.renderGroups}
      </Aside>
    );
  }
}

const mapStateToProps = (state) => ({
  groups: groupsSelectors.getAll(state),
  isFetching: groupsSelectors.isFetching(state),
});

const mapDispatchToProps = {
  fetchGroupsWithoutDefault,
  openModalGroupUserRemove,
  removeGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsList);
