import React, { useEffect, useCallback, useState } from 'react';
import Tooltip from 'react-tooltip';
// Components
import Label from '../../components/Label';
// Styles
import { Wrapper } from './styles';
import Button from 'components/Button';
import { checkPropertyDescription } from 'services/properties';

const PORTALS = [
  {
    name: 'Zap',
    min: 50,
    max: 3000,
    remaining: 3000,
    tooltip: 'Entre 50 à 3000 caracteres',
    colorDefault: 'secondary'
  },
  {
    name: 'OLX',
    min: 0,
    max: 6000,
    remaining: 6000,
    tooltip: 'Até 6000 caracteres',
    colorDefault: 'secondary'
  }
];

function PortalOptionsCount({ description }) {
  const [data, setData] = useState(PORTALS);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  const check = useCallback(async () => {
    setIsFetching(true);
    const response = await checkPropertyDescription(description);
    setData(response.data);
    setIsFetching(false);
  }, [description]);

  return (
    <Wrapper>
      {data?.map(({ name, max, colorDefault, remaining, tooltip }) => {
        let color = colorDefault;

        if (remaining < 0 && !colorDefault) {
          color = 'danger';
        }

        if (remaining > 0 && !colorDefault) {
          color = 'success';
        }

        return (
          <Label key={name} color={color} data-tip={tooltip}>
            {name}: {max - remaining}/{max}
          </Label>
        );
      })}
      <Button
        disabled={isFetching}
        onClick={check}
        color="success"
        size="small"
      >
        Contar caracteres
      </Button>
    </Wrapper>
  );
}

export default PortalOptionsCount;
