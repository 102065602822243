import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { contractChange } from 'services/financialv2/contracts';
import { PAYMENT_METHODS } from 'constants/constants';

export const usePix = ({ item, quantity }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [code, setCode] = useState(null);
  const [price, setPrice] = useState('R$ ---');
  const [generatedCreditId, setGeneratedCreditId] = useState(null);

  /**
   * Contrata com pix
   * @return {Promise<void>}
   */
  const contract = async () => {
    // if (process.env.NODE_ENV === 'development') {
    //   setCode(pixMock.data.pix_qr_code);
    //   setGeneratedCreditId(pixMock.data.credit_id);
    //   return;
    // }

    try {
      const {
        response: {
          data: { pix_qr_code, price, credit_id },
        },
      } = await contractChange({
        item,
        receiving_method: PAYMENT_METHODS.PIX,
        installments: 1,
        quantity,
      });

      setGeneratedCreditId(credit_id);
      setCode(pix_qr_code);
      setPrice(price);
    } catch (err) {
      console.error('err', err);
    }
  };

  useEffect(() => {
    if (code) {
      QRCode.toDataURL(code, { width: 140, margin: 0 }, function (_, url) {
        setImageUrl(url);
      });
    }
  }, [code]);

  return { price, code, imageUrl, contract, creditId: generatedCreditId };
};
