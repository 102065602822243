import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Aside from './components/Aside';
import Pages from './components/Pages';
import { Wrapper, Content, Inner } from './styles';
import { EnterpriseStoreProvider } from './context';
import { getId } from 'services/buildings';
import Loading from 'components/Loading';

const EnterpriseStore = () => {
  const params = useParams();
  const [id, setId] = useState(params?.id);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    async function fetchId() {
      setIsFetching(true);

      const _id = await getId();

      setIsFetching(false);
      setId(_id);
    }

    if (!params.id) {
      fetchId();
    }
  }, [params?.id]);

  return (
    <EnterpriseStoreProvider enterpriseId={id}>
      <Wrapper>
        <Aside />
        <Content>
          <Loading isVisible={isFetching} isFullComponent />
          <Inner>{!!id && <Pages />}</Inner>
        </Content>
      </Wrapper>
    </EnterpriseStoreProvider>
  );
};

export default EnterpriseStore;
