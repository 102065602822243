import Alert from 'components/Alert';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useRequest } from 'hooks/useRequest';
import { useState } from 'react';
import { useHistory } from 'react-router';
import api from 'services';

function AlertFrozenAccount() {
  const history = useHistory();
  const currentUser = useCurrentUser();
  const { data, isFetching } = useRequest({
    request: () => api.get('real-estates/financial-info'),
  });

  const [isVisible, setIsVisible] = useState(
    !window.sessionStorage.getItem('@tecimob/frozen-account')
  );

  if (
    currentUser?.real_estate?.resale?.name === 'Apple' ||
    !isVisible ||
    isFetching ||
    !data?.message
  )
    return false;

  return (
    <Alert
      style={{ marginTop: '15px' }}
      color={data.color}
      html={data.message}
      onClick={() => {
        // Redireciona todas as mensagens pro histórico de pagamentos
        history.push('/plan/history');
      }}
      handleClose={() => {
        setIsVisible(false);
        window.sessionStorage.setItem('@tecimob/frozen-account', true);
      }}
    />
  );
}

AlertFrozenAccount.defaultProps = {};
AlertFrozenAccount.propTypes = {};

export default AlertFrozenAccount;
