// Modules
import createDataReducer from 'modules/data';
// Services
import * as headersService from 'services/sites/headers';

export const {
  selectors: headerImagesSelectors,
  actionCreators: headerImagesActions,
  reducer
} = createDataReducer('components/headerImages');

export const fetchMainImages = params => dispatch => {
  dispatch(headerImagesActions.request());

  return headersService.getImages(params).then(res => {
    dispatch(headerImagesActions.receive(res.data));
    return res;
  });
};

/**
 * Busca todos os modelos de cabeçalhos
 * @param params
 * @return {Promise}
 */
export const fetchHeadersImages = params => dispatch =>
  fetchMainImages({ ...params, filter: { type: 1 } })(dispatch);

export const fetchFooterImages = params => dispatch =>
  fetchMainImages({ ...params, filter: { type: 2 } })(dispatch);

export const deleteHeaderImage = id => dispatch => {
  dispatch(headerImagesActions.removing(id));

  return headersService
    .deleteImage(id)
    .then(res => {
      dispatch(headerImagesActions.remove(id));
      return res;
    })
    .catch(res => {
      return res;
    });
};

export default reducer;
