import React, { createRef, useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import * as S from './styles';
import Button from 'components/Button';
import { MdDownload } from 'react-icons/all';
// import { canvasToBlob, downloadBlob } from 'components/PreviewPost/helpers';
// import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import { MdZoomIn, MdZoomOut } from 'react-icons/md';

function PreviewPost({ component: Component, componentProps }) {
  const [_animations, setAnimations] = useState(null);

  const [isDownloading, setIsDownloading] = useState(false);
  const componentRef = createRef(null);

  const handleDownload = (animations) => async () => {
    const component = componentRef.current;
    setIsDownloading(true);

    // Deixa o componente com o tamanho original
    animations.setTransform(0, 0, 1, 0);

    // Transforma o HTML da div da logo em uma imagem em formato base64
    domtoimage
      .toBlob(component)
      .then(function (blob) {
        // Transforma para blob
        const url = window.URL.createObjectURL(blob);

        animations.centerView(0.2, 0);

        // Cria um link temporário e simula o clique para iniciar o download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'imagem.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsDownloading(false);
      })
      .catch(function (error) {
        console.error('Erro ao converter div para imagem:', error);
        setIsDownloading(false);
      });
  };

  useEffect(() => {
    if (_animations) {
      _animations.centerView(0.2, 0);
    }
  }, [Component]);

  return (
    <S.Wrapper>
      <TransformWrapper
        initialScale={0.2}
        defaultPositionX={0}
        defaultPositionY={0}
        limitToBounds
        minScale={0.2}
        maxScale={1}
        centerOnInit
        onInit={(animations) => {
          setAnimations(animations);
        }}
      >
        {(animations) => {
          return (
            <>
              <S.Content>
                <TransformComponent
                  wrapperStyle={{
                    position: 'relative',
                    width: '438px',
                    height: '420px',
                  }}
                >
                  <div ref={componentRef} className="js-download">
                    <Component {...componentProps} />
                  </div>
                </TransformComponent>
                <S.Zoom>
                  <button onClick={() => animations.zoomOut()}>
                    <MdZoomOut />
                  </button>
                  <button onClick={() => animations.zoomIn()}>
                    <MdZoomIn />
                  </button>
                </S.Zoom>
                {isDownloading && (
                  <S.Downloading>
                    <span>Carregando</span>
                  </S.Downloading>
                )}
              </S.Content>
              <S.Footer>
                <Button onClick={handleDownload(animations)} color="success">
                  <MdDownload /> Fazer Download
                </Button>
              </S.Footer>
            </>
          );
        }}
      </TransformWrapper>
    </S.Wrapper>
  );
}

export default PreviewPost;
