import DWV from './components/DWV';
import Mailboxes from './components/Mailboxes';
import Orulo from './components/Orulo';
// import RedirectMails from "./components/RedirectMails";
import { Table, Thead } from 'containers/ModalRoot/ModalPayment/styles';
import Total from './components/Total';
import Package from './components/Package';
import Users from './components/Users';
import { Wrapper } from './styles';

const TableAdditionalItems = () => {
  return (
    <Wrapper>
      <Table>
        <Thead>
          <tr>
            <th className="additional-items">Itens</th>
            <th width="80">Qtd</th>
            <th width="80" className="price">
              Preço
            </th>
            <th width="80" className="total">
              Total
            </th>
          </tr>
        </Thead>
        <tbody>
          <Package />
          <Users />
          <Mailboxes />
          <Orulo />
          <DWV />
          <Total />
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default TableAdditionalItems;
