import React from 'react';
import * as PropTypes from 'prop-types';
import useReduxForm from 'hooks/useReduxForm';
import { useSelector } from 'react-redux';
import { getFormSubmitErrors, getFormSyncErrors } from 'redux-form';
import { Error } from './styles';

const ShowErrors = ({ fields }) => {
  const { form, submitFailed } = useReduxForm();

  // Seleciona todos os erros do formulário
  const submitErrors = useSelector(state => getFormSubmitErrors(form)(state));
  const syncErrors = useSelector(state => getFormSyncErrors(form)(state));
  const formErrors = { ...submitErrors, ...syncErrors };

  const errors = Object.keys(formErrors)
    .map(key => ({
      name: key,
      error: formErrors[key]
    }))
    .filter(({ name }) => name.includes(fields));

  if (errors.length <= 0 || !submitFailed) return null;

  return errors.map(({ name, error }) => <Error key={name}>{error}</Error>);
};

ShowErrors.propTypes = {
  fields: PropTypes.array
};

export default ShowErrors;
