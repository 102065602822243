import styled from 'styled-components';
import { Wrapper as PropertyBar } from 'components/NotesList/components/PropertyBar/styles';
import { Wrapper as PersonBar } from 'components/NotesList/components/PersonBar/styles';

export const Wrapper = styled.div`
  display: flex;

  .Select-control {
    width: 140px;
  }

  .Select-control,
  .Select-input {
    height: 30px;
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    line-height: 30px;
  }

  label {
    font-weight: 400;
  }
`;

export const Form = styled.form`
  margin-bottom: 15px;

  ${PropertyBar},
  ${PersonBar} {
    margin-top: -18px;
    border: 1px solid ${(p) => p.theme.borderColor};
    border-top: 0 none;
    padding: 12px 15px 10px 15px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
