import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { Select2 } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import * as characteristicsService from 'services/characteristics';

function FieldMultiCharacteristics({ typeId, ...props }) {
  const { data, fetchData, isFetching } = useRequest({
    request: (typeId) => characteristicsService.getList({ type_id: typeId }),
    initialFetch: false,
  });

  useEffect(() => {
    if (typeId) {
      fetchData(typeId);
    }
  }, [typeId]);

  useEffect(() => {}, [data]);

  return (
    <Field
      isLoading={isFetching}
      disabled={!typeId}
      filter
      name="by_characteristic_id"
      label="Requisito do imóvel"
      options={data}
      component={Select2}
      selectedItemsLabel="características"
      placeholder="Selecione"
      {...props}
    />
  );
}

export default FieldMultiCharacteristics;
