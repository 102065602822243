import styled from 'styled-components';

const SPACING = 6;

export const Wrapper = styled.div`
  width: calc(100% + 12px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  margin: -${SPACING}px;
`;

export const Item = styled.div`
  padding: ${SPACING}px;

  @media all and (min-width: 1367px) {
    width: ${100 / 3}%;
  }

  @media all and (min-width: 1201px) and (max-width: 1366px) {
    width: 50%;
  }

  @media all and (max-width: 1200px) {
    width: 100%;
  }
`;
