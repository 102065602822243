import React from 'react';
import { usePropertiesPortal } from 'pages/PropertiesPortal/index';

// Components
import CardProperty from 'components/CardProperty';

// Styles
import { Wrapper, Item } from './styles';
import { openModalProperty } from 'modules/modal';
import { useDispatch } from 'react-redux';

const ListProperties = () => {
  const dispatch = useDispatch();
  const { properties, toggle } = usePropertiesPortal();

  return (
    <Wrapper>
      {properties?.map(property => (
        <Item key={property.id}>
          <CardProperty
            handleClickProperty={() => {
              dispatch(openModalProperty({ property }));
            }}
            handleClickChecked={() => {
              toggle(property.id);
            }}
            key={property.id}
            property={property}
          />
        </Item>
      ))}
    </Wrapper>
  );
};

export default React.memo(ListProperties);
