import { analyticsV3, analyticsV4 } from 'services/analytics';

const setGoogleAnalytics = data => {
  const currentUser = localStorage.getItem('current-user');

  localStorage.setItem('current-user', {
    ...currentUser,
    real_estate: {
      ...currentUser.real_estate,
      google_analytics: {
        ...currentUser.real_estate.google_analytics,
        ...data
      }
    }
  });
};

const setAccessToken = (access_token = '') => {
  setGoogleAnalytics({ access_token });
  return false;
};

export const setAnalyticsAuthToken = (accessToken, typeToken = 'Bearer') => {
  if (accessToken) {
    setAccessToken(accessToken);
    analyticsV4.defaults.headers.common[
      'Authorization'
    ] = `${typeToken} ${accessToken}`;
    analyticsV3.defaults.headers.common[
      'Authorization'
    ] = `${typeToken} ${accessToken}`;
  } else {
    delete analyticsV4.defaults.headers.common['Authorization'];
    delete analyticsV3.defaults.headers.common['Authorization'];
  }
};

/**
 * Retorna o objeto que guarda as informações do
 * @returns {string|string | string}
 */
export const getGoogleAnalytics = () => {
  try {
    return localStorage.getItem('current-user').real_estate.google_analytics;
  } catch {
    return {
      access_token: '',
      refresh_token: '',
      expires_in: '',

      account_id: '',
      management_id: '',
      profile_id: '', // view

      // Relations
      real_estate_id: ''
    };
  }
};

export const getAccessToken = () => {
  try {
    return getGoogleAnalytics().access_token;
  } catch {
    return '';
  }
};

export const getViewId = () => {
  try {
    return getGoogleAnalytics().profile_id;
  } catch {
    return '';
  }
};

/**
 * Transforma um column header em uma lista de colunas simples
 * @param columnHeader
 * @returns {Array|String}
 */
export const transformColumnHeader = columnHeader => {
  const {
    dimensions,
    metricHeader: { metricHeaderEntries }
  } = columnHeader;

  // Cria o array das colunas
  let columns = [];

  // Verifica se tem dimensões
  if (dimensions) columns = [...dimensions];

  // Adiciona os outros dados do cabeçalho nas colunas
  metricHeaderEntries.forEach(metric => columns.push(metric.name));
  return columns;
};

/**
 * Transforma as linhas da resposta para um formato mais simples
 * @param reportRows
 * @param columnHeader
 * @returns {Array|Object}
 */
export const transformReportRows = (reportRows, columnHeader) => {
  let rows = [];

  if (reportRows)
    reportRows.forEach(({ dimensions, metrics }, i) => {
      let currentIndex = 0;

      if (dimensions)
        dimensions.forEach(dimension => {
          rows[i] = {
            ...rows[i],
            [columnHeader[currentIndex]]: dimension
          };
          currentIndex++;
        });

      metrics.forEach(({ values }) => {
        values.forEach(value => {
          rows[i] = {
            ...rows[i],
            [columnHeader[currentIndex]]: value
          };
          currentIndex++;
        });
      });
    });

  return rows;
};

/**
 * Transform reports
 * @param reports
 * @returns {Array|Object}
 */
export const transformReports = reports => {
  return (
    reports &&
    reports.map(report => {
      let currentReport = report;
      const {
        columnHeader,
        data: { rows }
      } = currentReport;

      // Transforma as colunas
      currentReport.columnHeader = transformColumnHeader(columnHeader);
      currentReport.data.rows = transformReportRows(
        rows,
        currentReport.columnHeader
      );

      return currentReport;
    })
  );
};
