import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { map, size } from 'lodash';
import Tooltip from 'react-tooltip';
// Components
import Loading from '../Loading';
import Nodata from '../Nodata';
import Pagination from '../Pagination';

class Table extends React.Component {
  static defaultProps = {
    data: null,
    type: 'default',
    isFetching: false,
    noData: null,
    responsive: false,

    renderTitle: null,
    renderItem: null
  };

  static propTypes = {
    type: PropTypes.oneOf(['default', 'full']),
    data: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.arrayOf(PropTypes.array)
    ]),
    renderTitle: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    responsive: PropTypes.bool,
    noData: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.string,
      children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
    })
  };

  componentDidUpdate() {
    Tooltip.rebuild();
  }

  renderBody() {
    const { data, renderItem } = this.props;
    return <tbody>{map(data, renderItem)}</tbody>;
  }

  get renderFooter() {
    const { renderFooter } = this.props;
    if (!renderFooter) return null;
    return <tfoot>{renderFooter()}</tfoot>;
  }

  render() {
    const {
      type,
      className,
      data,
      isFetching,
      renderTitle,
      renderItem,
      noData,
      pagination,
      paginationProps,
      onPageChange,
      responsive,
      ...rest
    } = this.props;

    const hasData = size(data) > 0;

    // verifica se está carregando
    // se estiver retorna o elemento de loading
    if (isFetching && !hasData) return <Loading isVisible />;

    // fallback para quando não tem nenhuma informação
    if (noData && !hasData) return <Nodata {...noData} />;

    const Wrapper = ({ children }) =>
      responsive ? (
        <div className={'Table--responsive'}>{children}</div>
      ) : (
        <>{children}</>
      );

    return (
      <Wrapper>
        <Loading isVisible={isFetching} isFullComponent />
        <table
          {...rest}
          className={classnames(
            'Table',
            {
              [`Table--${type}`]: type
            },
            className
          )}
        >
          {renderTitle && <thead>{renderTitle()}</thead>}
          {this.renderBody()}
          {this.renderFooter}
        </table>
        {paginationProps && (
          <Pagination
            pagination={paginationProps}
            onPageChange={onPageChange}
          />
        )}
        {pagination && <Pagination {...pagination} />}
      </Wrapper>
    );
  }
}

export default memo(Table);
