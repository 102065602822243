import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import FieldSelectUser from 'containers/FieldSelectUser';
import { Wrapper } from './styles';
import { WrapperDateRange } from 'pages/Reports/pages/PeoplePerPeriod/components/Form/styles';
import { FieldDateRange } from 'components/Form';
import { useCan } from 'hooks/useCan';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function Form({ handleSubmit }) {
  const canViewOthers = useCan({
    run: 'VIEW_OTHER_REPORTS',
  });

  //const { start_date, end_date } = useFormValue(['start_date', 'end_date']);

  return (
    <Wrapper onSubmit={handleSubmit}>
      <WrapperDateRange>
        <FieldDateRange canClear={false} label="Intervalo" />
      </WrapperDateRange>
      {canViewOthers && (
        <FieldSelectUser
          label="Corretor"
          style={{ width: 200 }}
          clearable={false}
          options={[{ id: '', name: 'Todos' }]}
          params={{
            filter: {
              realtor: true,
            },
            sort: 'by_name',
          }}
        />
      )}
    </Wrapper>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

export default reduxForm({
  form: 'FilterReports',
  enableReinitialize: true,
  destroyOnUnmount: true,
  onChange: (values, dipatch, props) => {
    props.submit();
  },
})(Form);
