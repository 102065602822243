import React from 'react';
import Modal from 'react-modal';
import { Col, Row } from 'react-flexbox-grid';

import { ModalTemplate } from 'components/Modal';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Button from 'components/Button';
import Divider from 'components/Divider';
import FormTransfer from '../FormTransfer';
import { STATUS_CRECI } from '../../../../../constants/constants';

class ModalBlocked extends React.Component {
  /**
   * Verifica se o usuário pelo bloqueador do sistema
   * Ex.: Quando acaba o limite de dias
   * @return {boolean}
   */
  get isBlockedBySystem() {
    const { ownerUser } = this.props;
    return (
      ownerUser.creci_status === STATUS_CRECI.BLOCKED_BY_SYSTEM ||
      ownerUser.creci_status === STATUS_CRECI.BLOCKED_BY_SUPPORT
    );
  }

  /**
   * Verifica o usuário foi bloqueado pelo suporte
   * @return {boolean}
   */
  get isBlockedBySupport() {
    const { ownerUser } = this.props;
    return ownerUser.creci_status === STATUS_CRECI.BLOCKED_BY_SUPPORT;
  }

  /**
   * Verifica se o imóvel é meu
   * @return {boolean}
   */
  get isMyProperty() {
    const { currentUser, ownerUser } = this.props;
    return currentUser.id === ownerUser.id;
  }

  /**
   * Verifica se pode checar o creci
   * @return {boolean}
   */
  get canCheckCreci() {
    return this.isBlockedBySystem;
  }

  /**
   * Verifica se pode ou não transferir o imóvel
   * @return {boolean}
   */
  get canTransferProperty() {
    const { currentUser } = this.props;
    return (currentUser.permissions || []).includes(
      'OTHER_PERSON_CHANGE_REALTOR'
    );
  }

  get renderText() {
    if (this.canCheckCreci && this.canTransferProperty) {
      if (this.isMyProperty) {
        return `Você pode verificar abaixo, ou se preferir, selecionar outro corretor para ser o novo responsável pelos seus imóveis`;
      } else {
        return `Você pode verificá-lo abaixo, ou se preferir, selecionar outro corretor para ser o novo responsável pelos imóveis dele`;
      }
    }

    if (this.canTransferProperty) {
      if (this.isMyProperty) {
        return 'Você pode selecionar outro corretor para ser o novo responsável pelos seus imóveis';
      } else {
        return 'Você pode selecionar outro corretor para ser o novo responsável pelos imóveis dele';
      }
    }

    return null;
  }

  render() {
    const {
      isOpen,
      modalConfig,
      modalType,
      handleCloseModal,
      ownerUser,
      handleToogleTransferProperty,
      handleTransferProperty,
      handleOpenModalCreci,
    } = this.props;

    return (
      <Modal
        {...modalConfig}
        className="Modal Modal--large"
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleCloseModal}
        style={{ content: { maxWidth: 554 } }}
      >
        <ModalTemplate title="Imóvel bloqueado" handleClose={handleCloseModal}>
          <p className="h-color--primary">
            Os imóveis serão exibidos após a validação do CRECI de um corretor.
            {/*{this.renderText}*/}
          </p>
          <Divider right={-20} left={-20} width={'calc(100% + 40px)'} />
          <Row>
            <Col xs={6}>
              <strong className="h-color--primary">Corretor Atual</strong>
              <p className="h-color--primary">{ownerUser.name}</p>
            </Col>
            <Col xs={6}>
              <div className="h-flex h-flex--right">
                {/*{this.canTransferProperty && (*/}
                {/*  <Button*/}
                {/*    className="h-text-nowrap"*/}
                {/*    type="button"*/}
                {/*    color="secondary"*/}
                {/*    colorText="white"*/}
                {/*    onClick={handleToogleTransferProperty}*/}
                {/*  >*/}
                {/*    Alterar Corretor*/}
                {/*  </Button>*/}
                {/*)}*/}
                {this.canCheckCreci && (
                  <Button
                    className="h-text-nowrap"
                    type="button"
                    color="primary"
                    colorText="white"
                    onClick={handleOpenModalCreci}
                  >
                    Verificar CRECI
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          {this.canTransferProperty && this.props.transferProperty && (
            <>
              <Divider right={-19} left={-19} width={'calc(100% + 39px)'} />
              <FormTransfer
                ownerUser={ownerUser}
                handleClose={handleCloseModal}
                onSubmit={handleTransferProperty}
                initialValues={{
                  quantity_properties: 'one',
                }}
              />
            </>
          )}
          {!this.canTransferProperty && (
            <>
              <Divider right={-19} left={-19} width={'calc(100% + 39px)'} />
              <ModalFooter>
                <Button
                  type="button"
                  color="white"
                  colorText="secondary"
                  onClick={handleCloseModal}
                >
                  Cancelar
                </Button>
                <span className="h-flex__cell--grow" />
                <Button
                  type="submit"
                  color="success"
                  onClick={handleCloseModal}
                >
                  Entendi
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalTemplate>
      </Modal>
    );
  }
}

export default ModalBlocked;
