import React, { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import TableSort, { CollumHeader } from 'components/TableSort';
import { openModalPerson } from 'modules/modal';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { MdInfo } from 'react-icons/md';
import { colors } from 'constants/styles';

// import { Wrapper } from './styles';

const Info = ({ data }) => {
  if (!data?.properties_available) {
    return (
      <MdInfo
        size={18}
        color={colors.secondary}
        data-tip="Algum imóvel desse cliente não está mais disponível"
      />
    );
  }
  return null;
};

function TablePeople({ data, meta, fetchData }) {
  const dispatch = useDispatch();

  useEffect(() => {
    Tooltip.rebuild();
  }, [data]);

  const openPerson = (person, params) => (e) => {
    e.stopPropagation();
    dispatch(openModalPerson({ ...person, afterClose: fetchData }, params));
  };

  return (
    <TableSort
      data={data}
      meta={meta}
      fetchData={fetchData}
      renderHeader={() => (
        <tr>
          <CollumHeader width={250} align="left" filterBy="by_name">
            Cliente
          </CollumHeader>
          <CollumHeader
            width={100}
            className="Table__cell--small"
            align="center"
            filterBy="interacted_at"
          >
            Última interação
          </CollumHeader>
          <th width={100} align="center">
            Imóveis no Funil
          </th>
          <th width={100} align="center">
            No radar
          </th>
          <th width={100} align="center">
            Enviados
          </th>
          <th width={100} align="center">
            Descartados
          </th>
        </tr>
      )}
      renderItem={(data) => (
        <tr
          className={classnames('h-pointer', {
            'is-danger': !data?.properties_available,
          })}
          onClick={openPerson(data, {
            initialPage: 'Properties',
            pageProperties: 'PropertiesNewMatched',
          })}
        >
          <td align="left">
            {data?.name} <Info data={data} />
          </td>
          <td align="center">{data?.interacted_at}</td>
          <td align="center">
            <span
              className="h-link"
              onClick={openPerson(data, {
                initialPage: 'Properties',
              })}
            >
              {data?.deals_count}
            </span>
          </td>
          <td
            align="center"
            onClick={openPerson(data, {
              initialPage: 'Properties',
              pageProperties: 'PropertiesNewMatched',
            })}
          >
            <span className="h-link">{data?.matcheds_new_count}</span>
          </td>
          <td
            align="center"
            onClick={openPerson(data, {
              initialPage: 'Properties',
              pageProperties: 'PropertiesSended',
            })}
          >
            <span className="h-link">{data?.matcheds_sent_count}</span>
          </td>
          <td
            align="center"
            onClick={openPerson(data, {
              initialPage: 'Properties',
              pageProperties: 'PropertiesDiscarted',
            })}
          >
            <span className="h-link">{data?.matcheds_discarted_count}</span>
          </td>
        </tr>
      )}
    />
  );
}

export default TablePeople;
