import styled from 'styled-components';

export const Wrapper = styled.div``;
export const Title = styled.div``;
export const Options = styled.div`
  margin-top: 10px;

  .Label {
    margin-right: 8px;

    :last-child {
      margin-right: 0;
    }
  }
`;
