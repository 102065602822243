import api from 'services';
import _ from 'lodash';
import { responseMessage } from 'lib/service-helpers';

/**
 * Lista todos os créditos de um usuário logado
 * @return {*|Promise<AxiosResponse<T>>}
 */
export const getAll = (params) =>
  api.getList('sales/credits', _.merge(params, { sort: '-due_date' }));

/**
 * Pega um credito pelo id
 * @param id
 * @return {*|Promise<AxiosResponse<T>>}
 */
export const getOne = (id) => api.getOne('sales/credits', id);

/**
 * Remove um crédito
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const remove = (id) =>
  api.delete('sales/credits', id).then(responseMessage('Removido!'));

/**
 * Verifica se ele já pagou alguma coisa no mesmo dia
 * @return {boolean}
 */
export const hasPaymentToday = async () => {
  try {
    const { data } = await getAll({
      filter: {
        created_today: true,
        receiving_method: 2,
      },
    });
    return data?.length > 0;
  } catch {
    return false;
  }
};
