import React from 'react';
import color from 'color';
import { connect } from 'react-redux';
import { Row } from 'react-flexbox-grid';
// Components
import Logo from './components/Logo';
import MenuLeft from './components/MenuLeft';
import MenuRight from './components/MenuRight';
// Modules
import {
  getHeaderBackground,
  getHeaderLogoImage,
  logoutUser,
} from 'modules/login';
import {
  newNotificationsSelector,
  receiveAllNotifications,
} from 'modules/notifications';
import {
  openModalEvent,
  openModalOpenDeal,
  openModalPerson,
  openModalPersonAdd,
  openModalSupport,
} from 'modules/modal';

import { fetchEvents } from 'modules/events';
import { ThemeProvider } from 'styled-components';
import { isVisibleSelector, mainSearchActions } from 'modules/mainSearch';
import { Header } from './styles';

export const MainHeaderContext = React.createContext({
  searchOpen: false,
  toggleSearchOpen: null,
});

class MainHeader extends React.Component {
  get themeHeader() {
    const { headerBackground } = this.props;
    const colorHeader = color(headerBackground);
    return colorHeader.isDark() ? 'MainHeader--light' : 'MainHeader--dark';
  }

  get styleHeader() {
    const { headerBackground: backgroundColor } = this.props;
    return { backgroundColor };
  }

  get currentUser() {
    return this.props.login.currentUser;
  }

  get realEstate() {
    try {
      return this.currentUser.real_estate;
    } catch {
      return {};
    }
  }

  get resale() {
    try {
      return this.realEstate.resale;
    } catch {
      return {};
    }
  }

  constructor() {
    super();

    this.header = React.createRef();

    this.state = {
      isSearchOpen: false,
    };
  }

  toggleSearchOpen = () => {
    this.props.toggle();
  };

  openModalEvent = () => {
    this.props.openModalEvent({
      onSubmitSuccess: () => {
        this.props.fetchEvents();
      },
    });
  };

  onOpenNotifications = (e) => {
    const { receiveAllNotifications } = this.props;
    receiveAllNotifications();
  };

  handleClickNewClient = (e) => {
    const { openModalPersonAdd, openModalPerson } = this.props;

    e.preventDefault();
    openModalPersonAdd(null, (person) => {
      openModalPerson(person);
    });
  };

  handleClickNewSchedule = (e) => {
    const { openModalEvent } = this.props;
    e.preventDefault();
    openModalEvent({});
  };

  handleClickLogoff = (e) => {
    e.preventDefault();
    const { logoutUser } = this.props;

    logoutUser();
  };

  render() {
    const { logoUrl, headerBackground, openModalSupport, hasNewNotifications } =
      this.props;

    const headerHeight = this.header?.current?.clientHeight || 60;

    return (
      <ThemeProvider
        theme={{ headerHeight, bgHeader: headerBackground || '#0084F4' }}
      >
        <MainHeaderContext.Provider
          value={{
            searchOpen: this.props.isVisible,
            toggleSearchOpen: this.toggleSearchOpen,
          }}
        >
          <Header
            ref={this.header}
            className={`MainHeader ${this.themeHeader} h-bg--secondary js-header`}
            style={this.styleHeader}
          >
            <Row
              className="h-container h-container--padding h-flex h-flex--center-center"
              between="xs"
            >
              <Logo url={logoUrl} />
              <MenuLeft
                user={this.currentUser}
                resale={this.resale}
                openModalSupport={openModalSupport}
              />
              <MenuRight
                handleClickLogoff={this.handleClickLogoff}
                hasNewNotifications={hasNewNotifications}
                user={this.currentUser}
                onOpenNotifications={this.onOpenNotifications}
                handleClickNewClient={this.handleClickNewClient}
                handleClickNewSchedule={this.handleClickNewSchedule}
              />
            </Row>
          </Header>
        </MainHeaderContext.Provider>
      </ThemeProvider>
    );
  }
}
//

const mapStateToProps = (state) => ({
  login: state.login,
  isVisible: isVisibleSelector(state),
  headerBackground: getHeaderBackground(state),
  logoUrl: getHeaderLogoImage(state),
  hasNewNotifications: newNotificationsSelector(state),
});

const mapDispatchToProps = {
  fetchEvents,
  logoutUser,
  openModalPersonAdd,
  openModalPerson,
  openModalOpenDeal,
  openModalEvent,
  openModalSupport,
  receiveAllNotifications,
  ...mainSearchActions,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(MainHeader);
