import React from 'react';
// import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';

import ModalInfo from './components/ModalInfo';
import ModalGain from './components/ModalGain';
import ModalLoss from './components/ModalLoss';
import ModalCongratulation from './components/ModalCongratulation';

import { closeModal, openModalDeal, openModalProperty, openModalPropertyRent } from 'modules/modal';

import {
  dealsAuditsSelectors,
  fetchDealAudits,
  funnelsSelector,
  getFunnels,
  getInfos,
  getReasons,
  handleSetInterest,
  handleSubmitGain,
  handleSubmitLoss,
  handleSubmitMoveDeal,
  infosFetchingSelector,
  infosSelector,
  interestSelector,
  peopleSelector,
  reasonsSelector,
  usersSelector,
} from './reducer';

const defaultProps = {};

const propTypes = {};

const modal = {
  ModalInfo,
  ModalGain,
  ModalLoss,
  ModalCongratulation
};

class ModalDeal extends React.Component {
  state = {
    modal: 'ModalInfo',
    realtorName: ''
  };

  componentDidMount() {
    const {
      id: dealId,
      stage: { id: stageId }
    } = this.props;

    // Busca o histórico do negócio
    this.props.fetchDealAudits(dealId);

    // Pega os motivos
    this.props.getReasons();

    // Busca por todas as etapas do funil
    this.props.getFunnels();

    // Pega as informações do negocio
    this.props.getInfos(stageId, dealId);
  }

  handleChangeProperty = () => {
    this.setState({
      ...this.state
    });
  };

  handleChangeModal = modal => {
    this.setState({
      ...this.state,
      modal
    });
  };

  handleClickGain = property => () => {
    const { id, funnelId, stage } = this.props;

    if (property.transaction === 2) {
      this.props.openModalPropertyRent({
        person: this.props.infos.person,
        property,
        deal_id: id,
        stage,
        funnelId,
        onSubmitSuccess: () => {
          if (this.props.afterClose) this.props.afterClose();
          Alert.success('Negócio fechado!');
        }
      });
      return false;
    }

    this.handleChangeModal('ModalGain');
  };

  handleSubmitGain = values => {
    const {
      id: dealId,
      stage: { id: stageId }
    } = this.props;
    this.props
      .handleSubmitGain(stageId, dealId, values)
      .then(() => {
        this.handleChangeModal('ModalCongratulation');
      })
      .catch(() => {
        this.props.closeModal();
      });
  };

  handleSubmitLoss = values => {
    const {
      id: dealId,
      stage: { id: stageId }
    } = this.props;
    this.props.handleSubmitLoss(stageId, dealId, values).then(() => {
      if (this.props.afterClose) this.props.afterClose();
      this.props.closeModal();
    });
  };

  handleChangeRealtor = e => {
    const userId = e.target.value;
    const currentUser = this.props.users.find(user => user.id === userId);

    this.setState({
      realtorName: currentUser.name
    });

    return currentUser;
  };

  render() {
    const { modalConfig, isOpen, modalType, handleClose } = this.props;

    const modalClassName = {
      base: 'Modal Modal--person'
    };

    if (this.state.modal !== 'ModalInfo') {
      modalClassName.base = 'Modal Modal--medium';
    }

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        className={modalClassName}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        {React.createElement(modal[this.state.modal], {
          ...this.state,
          ...this.props,
          realtorName: this.state.realtorName,
          handleClose,
          handleClickGain: this.handleClickGain,
          handleChangeRealtor: this.handleChangeRealtor,
          handleChangeModal: this.handleChangeModal,
          handleChangeProperty: this.handleChangeProperty,
          handleSubmitGain: this.handleSubmitGain,
          handleSubmitLoss: this.handleSubmitLoss
        })}
      </Modal>
    );
  }
}

ModalDeal.defaultProps = defaultProps;
ModalDeal.propTypes = propTypes;

const mapStateToProps = state => ({
  audits: dealsAuditsSelectors.getAll(state),
  interest: interestSelector(state),
  infos: infosSelector(state),
  funnels: funnelsSelector(state),
  isFetching: infosFetchingSelector(state),
  reasons: reasonsSelector(state),
  people: peopleSelector(state),
  users: usersSelector(state)
});

const mapDispatchToProps = {
  getFunnels,
  getReasons,
  getInfos,
  closeModal,
  handleSubmitGain,
  handleSubmitLoss,
  handleSubmitMoveDeal,
  handleSetInterest,
  openModalDeal,
  openModalProperty,
  openModalPropertyRent,
  fetchDealAudits
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeal);
