import { ThemeProvider } from 'styled-components';
import * as S from './styles';

const Model3 = ({
  image,
  title,
  subtitle,
  description,
  logoUrl,
  backgroundColor,
  mainColor,
}) => {
  return (
    <ThemeProvider
      theme={{
        fontSize: '32px',
        backgroundColor,
        mainColor,
        logoUrl,
      }}
    >
      <S.Wrapper>
        <S.Image src={image} alt="" />
        <S.Background />
        <S.Box>
          <S.HeaderBox>
            <S.Title>{title}</S.Title>
            <S.Price>{subtitle}</S.Price>
          </S.HeaderBox>
          <S.Description>
            {description?.map((item) => (
              <div>{item}</div>
            ))}
          </S.Description>
          {!!logoUrl && (
            <S.Footer>
              <img src={logoUrl} alt="" />
            </S.Footer>
          )}
        </S.Box>
      </S.Wrapper>
    </ThemeProvider>
  );
};

export default Model3;
