import React from 'react';
import { Wrapper, Title, Options } from './styles';
import Label from 'components/Label';

function FilterNotes({ filter, setFilter, stages, onChangeFilter }) {
  const handleClickFilter = (id) => () => {
    setFilter(id);
    if (onChangeFilter) onChangeFilter(id);
  };

  return (
    <Wrapper>
      <Title>Filtrar por Etapas</Title>
      <Options>
        <Label isChecked={filter === 'all'} onClick={handleClickFilter('all')}>
          Tudo
        </Label>
        {stages?.map(({ id, name, color }) => (
          <Label
            isChecked={filter === id}
            key={`stage-${id}`}
            color={color}
            onClick={handleClickFilter(id)}
          >
            {name}
          </Label>
        ))}
      </Options>
    </Wrapper>
  );
}

export default FilterNotes;
