import React from 'react';
import { Field } from 'redux-form';
// Components
import { Counter } from 'components/Form';

export default function BoxUsers() {
  return (
    <div className="BoxPlan BoxPlan--middle">
      <header className="BoxPlan__header">
        <h5 className="BoxPlan__title">Número de corretores</h5>
        <p className="BoxPlan__text">Adicione o nº de corretores:</p>
      </header>
      <Field max={999} name="users" component={Counter} />
      <p className="BoxPlan__text">
        <strong>Obs:</strong> você poderá adicionar mais corretores no futuro,
        caso necessário.
      </p>
    </div>
  );
}
