import { plural } from 'lib/text';
import { useDispatch } from 'react-redux';
// Modules
import { openModalPayment } from 'modules/modal';
// Containers
import { useIsTecimob } from 'containers/IsTecimob';

import { shareWhatsapp } from 'components/ChatBox';
import { useCurrentUser } from 'hooks/useCurrentUser';
import timer from './timer.png';

export default function Countdown() {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const isTecimob = useIsTecimob();

  const openPayment = () => {
    dispatch(openModalPayment());
  };

  // Verifica se a revenda é tecimob
  if (!isTecimob) return null;
  if (!currentUser || !currentUser.real_estate) return null;

  const daysToTest = currentUser?.real_estate?.days_to_test;
  const is_paying = currentUser?.real_estate?.is_paying;

  if (is_paying || !daysToTest || daysToTest < 0) return null;

  return (
    <div
      className="Countdown"
      style={{ marginTop: '25px', marginBottom: '15px' }}
    >
      <div className="Countdown__icon">
        <img src={timer} alt="" />
      </div>
      <div className="Countdown__text">
        <p className="h-color--primary">
          Você tem mais {plural(daysToTest, 'dia', 'dias')} para testar os
          nossos recursos. Deseja contratar seu plano agora?{' '}
          <span onClick={openPayment} className="h-link">
            Clique aqui.
          </span>
        </p>
        <p className="h-color--primary h-margin-top--5">
          Gostaria de conversar com um consultor?{' '}
          <a
            target="_blank"
            href={shareWhatsapp('554896049333')}
            className="h-link" rel="noreferrer"
          >
            Clique aqui.
          </a>
        </p>
      </div>
    </div>
  );
}
