import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import { AsyncSelect } from 'components/Form';
import * as propertiesService from 'services/properties';
import * as searchService from 'services/searchbar';
import Alert from 'react-s-alert';
import Option from './components/Item';
import SingleValue from './components/SingleValue';

const Property = ({
  label = 'Imóvel',
  name = 'property_id',
  filter = {},
  placeholder = 'Procure por endereço ou nome do condomínio',
  ...props
}) => {
  const fetchData = useCallback(async ({ search, offset = 1, limit = 10 }) => {
    try {
      return searchService.searchByProperties(search, {
        offset,
        limit,
        filter,
        sort: '-updated_at',
      });
    } catch {
      Alert.success('Problema ao buscar os imóveis');
    }
  }, []);

  const getOptionByValue = useCallback(async (id) => {
    const res = await propertiesService.getOne(id);
    return res;
  }, []);

  const loadOptions = useCallback(async (search, loadedOptions, { page }) => {
    const response = await fetchData({
      search,
      offset: page,
    });

    const pagination = response?.meta?.pagination;

    return {
      options: response.data,
      hasMore: pagination?.current_page < pagination?.total_pages,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  return (
    <Field
      label={label}
      name={name}
      labelKey="title_formatted"
      valueKey="id"
      getOptionByValue={getOptionByValue}
      loadOptions={loadOptions}
      component={AsyncSelect}
      components={{ Option, SingleValue }}
      placeholder={placeholder}
      additional={{
        page: 1,
      }}
      {...props}
    />
  );
};

export default Property;
