import React from 'react';
import Popover from 'components/Popover';
import Form from './components/Form';
import { useEnterprise } from 'pages/EnterpriseStore/context';

const PopoverProgress = ({
  data = null,
  children,
  onSubmitSuccess = () => {}
}) => {
  const { enterpriseId } = useEnterprise();

  return (
    <Popover
      buildingId={enterpriseId}
      initialValues={data}
      component={Form}
      onSubmitSuccess={onSubmitSuccess}
    >
      {children}
    </Popover>
  );
};

export default PopoverProgress;
