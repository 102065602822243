import React from 'react';
import { Field, initialize, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { CarouselSelect } from 'components/Form';
import Navigator from 'components/Navigator';

const defaultProps = {
  components: null,
  handleSubmit: null
};

const propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired
};

const FormPropertyDetail = ({
  components,
  handleSubmit,
  submitting,
  pristine
}) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="property_details_type"
      component={CarouselSelect}
      options={components}
      renderOption={component => (
        <div>
          <Navigator style={{ maxHeight: 'calc(100vh - 250px)' }}>
            <img src={component.file_url} alt="teste" />
          </Navigator>
        </div>
      )}
    />
    <FixedBar style={{ left: 240 }}>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success" disabled={submitting}>
        Salvar
      </Button>
    </FixedBar>
  </form>
);

FormPropertyDetail.defaultProps = defaultProps;
FormPropertyDetail.propTypes = propTypes;

export default reduxForm({
  form: 'FormPropertyDetail',
  onSubmitSuccess: (res, dispatch) => {
    // Reinicializa o formulário com os valores
    dispatch(initialize('FormPropertyDetail', res.data, false));
  },
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(FormPropertyDetail);
