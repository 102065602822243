import React from 'react';
import PropTypes from 'prop-types';

function FilterBox({ isLoading, title, children }) {
  return (
    <div className="FilterAside__box">
      {/*<Loading isVisible={isLoading} isFullComponent />*/}
      {!!title && (
        <div>
          <label className="FormLabel" style={{ whiteSpace: 'nowrap' }}>
            {title}
          </label>
        </div>
      )}
      {children}
    </div>
  );
}

FilterBox.defaultProps = {
  isLoading: false,
  title: null,
  children: null
};

FilterBox.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.node,
  children: PropTypes.node
};

export default FilterBox;
