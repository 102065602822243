import React from 'react';
import Box, { BoxLink } from 'pages/PlanConfig/components/Box';
import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import { useRequest } from 'hooks/useRequest';
import * as bankSlipService from 'services/financial/bankSlip';
import { useDispatch } from 'react-redux';
import { openModalBankSlip } from 'modules/modal';

const BoxCustom = styled(Box)`
  p {
    line-height: 22px;
  }
`;

// import { Wrapper } from './styles';

function TaxData() {
  const dispatch = useDispatch();

  const { data, fetchData } = useRequest({
    request: bankSlipService.getOne,
    initialState: {},
  });

  const city = data?.neighborhood?.city;
  const state = data?.neighborhood?.state;

  const handleClickOpenModal = () => {
    dispatch(
      openModalBankSlip({
        bank_slip_id: data.id,
        onSubmitSuccess: () => {
          fetchData();
        },
      })
    );
  };

  return (
    <BoxCustom
      title="Dados Fiscais"
      actions={() => (
        <BoxLink onClick={handleClickOpenModal}>
          <MdEdit /> Editar dados
        </BoxLink>
      )}
    >
      <p>{data.name && <span>{data.name}</span>}</p>
      <p>
        {data.street && data.street_number && (
          <span>
            {data.street} {data.street_number}
          </span>
        )}
      </p>
      <p>
        {city && state && (
          <span>
            {city.name} - {state.acronym} | CEP {data.zipcode}
          </span>
        )}
      </p>
      <p>{data.cpf_cnpj && <span>{data.cpf_cnpj}</span>}</p>
    </BoxCustom>
  );
}

export default TaxData;
