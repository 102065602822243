import Button from 'components/Button';
import { Textarea } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import FieldPerson from 'containers/FieldPerson';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import ReservationField from './ReservationField';

// const LabelRemove = () => {
//   return (
//     <div>
//       Remover da lista de compatíveis?{' '}
//       <MdInfo
//         className="h-margin-left--5 h-color--secondary"
//         data-tip="Caso este imóvel esteja pendente na lista de nutrição de clientes compatíveis, ele será removido."
//       />
//     </div>
//   );
// };

function Form({ handleSubmit, handleClose, change }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <FieldPerson xs={6} name="people_id" />
        <ReservationField change={change} />
      </Row>
      <Row>
        <Field
          label="Observações sobre o motivo da reserva"
          type="simple"
          xs={12}
          name="booking_note"
          component={Textarea}
        />
      </Row>
      <Row>
        <Field
          label="Observações sobre o motivo do encerramento da reserva"
          type="simple"
          xs={12}
          name="closing_note"
          component={Textarea}
        />
      </Row>
      <ModalFooter withBorder>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormReservationCreate',
})(Form);
