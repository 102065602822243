import React, { useState, useCallback, useEffect } from 'react';
import { Wrapper } from 'pages/EnterpriseStore/pages/Progress/components/TableProgress/styles';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { reorder } from 'modules/pipeline';
// import { Container } from './styles';

const TableDrag = ({
  data,
  droppableId = 'droppableId',
  idKey = 'id',
  orderKey = 'order',
  onDragEnd,
  renderHead = () => {},
  renderItem = () => {}
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleDragEnd = useCallback(result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setItems(prevItems => {
      return reorder(prevItems, result.source.index, result.destination.index);
    });

    if (onDragEnd)
      onDragEnd({
        draggableId: result?.draggableId,
        order: result?.destination.index + 1
      });
  }, []);

  if (!items || items?.length <= 0) return null;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Wrapper className="Table">
        {renderHead && <thead>{renderHead()}</thead>}
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {items?.map((item, index) => {
                return (
                  <Draggable
                    key={`${item[idKey]}-${index}`}
                    draggableId={item[idKey]}
                    index={index}
                  >
                    {provided => (
                      <tr
                        key={`${item[idKey]}-${index}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="h-hover"
                      >
                        {renderItem(item)}
                      </tr>
                    )}
                  </Draggable>
                );
              })}
            </tbody>
          )}
        </Droppable>
      </Wrapper>
    </DragDropContext>
  );
};

export default TableDrag;
