import { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
// Common Components
import Loading from 'components/Loading';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';
import { openModalPayment } from 'modules/modal';
import ModalHasInstallment from './components/ModalHasInstallment';
import ModalNoInstallment from './components/ModalNoInstallment';
import ModalSchedule from './components/ModalSchedule';

function ModalContract({
  schedule,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
}) {
  const dispatch = useDispatch();
  const { realEstate, isFetching } = useCurrentRealEstate({
    fetch: true,
    params: { include: 'financial_info' },
  });

  const [isScheduling, setSchedule] = useState(schedule);

  const hasInstallment = realEstate?.financial_info?.has_installment;

  function handleClickSchedule() {
    setSchedule(!isScheduling);
  }
  function handleClickContact() {
    window.open('https://tecimob.com.br/suporte/');
  }
  function handleClickNo() {
    dispatch(openModalPayment());

    // fecha a modal
    handleClose();
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      {isFetching ? (
        <Loading isFullScreen />
      ) : isScheduling ? (
        <ModalSchedule handleClose={handleClose} />
      ) : hasInstallment ? (
        <ModalHasInstallment
          onClickSchedule={handleClickSchedule}
          onClickContact={handleClickContact}
          handleClose={handleClose}
        />
      ) : (
        <ModalNoInstallment
          onClickNo={handleClickNo}
          onClickYes={handleClickSchedule}
          handleClose={handleClose}
        />
      )}
    </Modal>
  );
}

export default ModalContract;
