import api, { apiStorage } from 'services/index';

/**
 * Lista de todas as linguagens disponíveis
 * @param params
 */
export const getLanguages = (params) =>
  api.getList('settings/sites/languages', params);

/**
 * Pega as informações de site relacionadas a cabeçalhos
 * @param params
 * @return {Promise}
 */
export const getOne = (params) =>
  api.getOne('settings/sites/headers', '', params);

/**
 * Atualiza as informações do site relacionadas a cabeçalhos
 * @param values
 * @return {Promise}
 */
export const update = (values) => api.update('settings/sites/headers', values);

/**
 * Cria uma nova imagem
 * @param values
 * @return {Promise}
 */
export const createImage = (values) =>
  apiStorage.uploadFile('sites/main-images', values);

/**
 * Deleta uma imagem
 * @param id -
 * @return {Promise}
 */
export const deleteImage = (id) => apiStorage.delete('sites/main-images', id);

/**
 * Pega as imagens
 * @param params
 * @return {*|{params}}
 */
export const getImages = (params) =>
  api.getOne('settings/sites/main-images', '', params);

/**
 * Retorna os modelos de cabeçalho
 * @param params
 * @return {Promise}
 */
export const getComponents = (params) =>
  api.getList('settings/component-examples/headers', params);
