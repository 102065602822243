import { combineReducers } from 'redux';

import headerImagesReducer from './headerImages';
import headersReducer from './headers';
import propertiesReducer from './properties';
import propertyDetailsReducer from './propertyDetails';
import propertyFormsReducer from './propertyForms';
import searchesReducer from './searches';
import slidesReducer from './slides';
import footersReducer from './footers';
import testimonialsReducer from './testimonials';
import superHighlightsReducer from './superHighlights';

export default combineReducers({
  headerImages: headerImagesReducer,
  headers: headersReducer,
  properties: propertiesReducer,
  propertyDetails: propertyDetailsReducer,
  propertyForms: propertyFormsReducer,
  searches: searchesReducer,
  slides: slidesReducer,
  footers: footersReducer,
  testimonials: testimonialsReducer,
  superHighlights: superHighlightsReducer
});
