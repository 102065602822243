import React from 'react';
import { Item, Wrapper } from './styles';
import { openModalPerson, openModalProperty } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { setPage } from 'modules/modalPerson';
import { menuThunks } from 'modules/modalProperty';

function Timeline({ item, contentKey, timeKey, onCloseModal }) {
  const dispatch = useDispatch();
  const data = item[contentKey];

  function handleClick(event) {
    const dataAction = event.target.getAttribute('data-action');

    if (!dataAction) return;

    const action = JSON.parse(dataAction);

    switch (action.type) {
      case 'setPersonPage': {
        dispatch(setPage(action.page));
        break;
      }
      case 'setPropertyPage': {
        dispatch(menuThunks.setPage(action.page));
        break;
      }
      case 'openModalPerson': {
        dispatch(
          openModalPerson(action.person, {
            initialPage: action.initialPage,
            pageProperties: action.pageProperties,
            afterClose: onCloseModal,
          })
        );

        break;
      }

      case 'openModalProperty': {
        dispatch(
          openModalProperty({
            property: action.property,
            initialPage: action.initialPage,
            afterClose: onCloseModal,
          })
        );

        break;
      }

      default:
        break;
    }
  }

  return (
    <Wrapper onClick={handleClick}>
      {data.map((item, i) => (
        <Item
          key={i + item?.id}
          dangerouslySetInnerHTML={{
            __html: `<strong class="timeline__hour">${item.time}</strong> ${item.description}`,
          }}
        />
      ))}
    </Wrapper>
  );
}

export default Timeline;
