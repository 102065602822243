import { useCallback, useState } from 'react';
import { MdClose, MdEdit, MdRefresh, MdStar } from 'react-icons/md';
// Components
import Label from 'components/Label';
// Styles
import FormCaption from 'components/CardImage/components/FormCaption';
import Popover from 'components/Popover';
import TextOverflow from 'components/TextOverflow';
import { SortableHandle } from 'react-sortable-hoc';
import { ThemeProvider } from 'styled-components';
import {
  Actions,
  Container,
  Delete,
  Img,
  Legend,
  WrapperLegend,
} from './styles';
import { useSortableGallery } from 'components/SortablePropertyGallery';

const Handle = SortableHandle(({ children }) => children);

const CardImage = ({
  isLoading,
  isFeatured,
  image,
  onEditCaption,
  onEditCaptionSuccess,
  onClickSpin,
  onRemoveImage,
  onClick,
}) => {
  const { captionName } = useSortableGallery();
  const [isEditting, setIsEditting] = useState(false);

  const onSuccess = useCallback((...rest) => {
    setIsEditting(false);
    if (onEditCaptionSuccess) onEditCaptionSuccess(...rest);
  });

  return (
    <ThemeProvider
      theme={{
        isLoading: image?.isLoading || isLoading,
        isEditting,
        isFeatured,
      }}
    >
      <Handle>
        <Container className="h-hover" onClick={onClick}>
          <Delete
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (onRemoveImage) {
                onRemoveImage(image)(e);
              }
            }}
          >
            <MdClose />
          </Delete>
          <Actions>
            {isFeatured && (
              <Label
                colorText="white"
                color="caution"
                kind="solid"
                size="small"
                noTransparency
              >
                <MdStar />
                Capa
              </Label>
            )}
            {!!onClickSpin && (
              <Label
                color="primary"
                kind="solid"
                size="small"
                className="h-hover__show h-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (onClickSpin) onClickSpin(image)(e);
                }}
              >
                <MdRefresh />
                Girar
              </Label>
            )}
          </Actions>
          <Img
            key={image?.id + image?.updated_at}
            src={image?.file_url}
            alt="Image"
            className="image"
          />
          {!!onEditCaption && (
            <WrapperLegend>
              <Popover
                captionName={captionName}
                captionInputPlaceholder="Digite a sua legenda"
                initialValues={image}
                form={`caption-${image?.id}`}
                handleToggleEdit={() => {
                  setIsEditting(false);
                }}
                onSubmit={onEditCaption}
                onSubmitSuccess={onSuccess}
                component={FormCaption}
              >
                <Legend
                  isVisible={!!image?.[captionName]}
                  onClick={() => setIsEditting(true)}
                >
                  <MdEdit />
                  <TextOverflow>
                    {image?.[captionName] || 'Legenda'}
                  </TextOverflow>
                </Legend>
              </Popover>
            </WrapperLegend>
          )}
        </Container>
      </Handle>
    </ThemeProvider>
  );
};

export default CardImage;
