export const RECEIVING_METHODS = {
  1: 'Boleto',
  2: 'Cartão',
  3: 'Bonus',
  4: 'Pix',
};

export const getReceivingMethod = (receivingMethod) => {
  try {
    return RECEIVING_METHODS[receivingMethod];
  } catch {
    return null;
  }
};
