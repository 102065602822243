import Alert from 'react-s-alert';
import { createModule, item } from '../lib/reducer-helpers';
// Services
import * as cadastralSituationsService from 'services/cadastralSituations';
// Modules

const createAction = createModule('cadastralSituations');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const REMOVE = createAction('REMOVE');

// pega todas as características
export const cadastralSituationsSelector = state =>
  state.cadastralSituations.cadastralSituations;

// Initial State
const initialState = {
  cadastralSituations: [],
  meta: { isFetching: false }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE:
      return {
        ...state,
        cadastralSituations: action.cadastralSituations,
        meta: {
          ...state.meta,
          isFetching: false
        }
      };
    case REMOVE:
      return {
        ...state,
        cadastralSituations: item.remove(state.cadastralSituations, action)
      };
    default:
      return state;
  }
}

// Action Creators
export const requestCadastralSituations = () => ({ type: REQUEST });
export const receiveCadastralSituations = cadastralSituations => ({
  type: RECEIVE,
  cadastralSituations
});
export const removeCadastralSituations = id => ({ type: REMOVE, id });

/**
 * Pega todas as situações cadastrais de um imóvel
 * @return {function(*)}
 */
export function getCadastralSituations() {
  return dispatch => {
    dispatch(requestCadastralSituations());

    return cadastralSituationsService
      .getList({
        order_col: 'title',
        order_rule: 'asc'
      })
      .then(({ data: cadastralSituations }) => {
        dispatch(receiveCadastralSituations(cadastralSituations));
        return cadastralSituations;
      })
      .catch(() => {
        Alert.success('Problema ao buscar as situações cadastrais');
        dispatch(receiveCadastralSituations([]));
      });
  };
}
