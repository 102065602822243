import React from 'react';
import { reduxForm } from 'redux-form';
import { CustomFieldDateRange } from 'pages/Crm/pages/History/components/Filter/styles';
import { Form } from './styles';
import moment from 'lib/moment';
import { DEFINED_RANGES } from 'components/Form/components/FieldDateRange';

function Filter({ disabled, handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit}>
      <CustomFieldDateRange
        disabled={disabled}
        isInline
        canClear={false}
        startDateName="start_date"
        endDateName="end_date"
        staticRanges={[
          DEFINED_RANGES.CURRENT_MONTH,
          DEFINED_RANGES.LAST_MONTH,
          DEFINED_RANGES.LAST_30_DAYS,
          DEFINED_RANGES.LAST_60_DAYS,
          DEFINED_RANGES.LAST_90_DAYS,
          DEFINED_RANGES.CURRENT_YEAR,
          DEFINED_RANGES.PAST_YEAR,
        ]}
        dateRangeProps={{
          maxDate: moment().subtract(1, 'day').toDate(),
        }}
      />
    </Form>
  );
}

export default reduxForm({
  form: 'FilterVisitsSite',
})(Filter);
