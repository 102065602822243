import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Image = styled.div`
  width: 370px;
  flex-shrink: 0;
  margin-right: 15px;
`;

export const WrapperFields = styled.div`
  flex-grow: 1;
`;
