/**
 * Retorna o placeholder para um tipo de nacionalidade
 */
export const customPlaceholder = (placeholder, { name, iso2 }) => {
  switch (iso2) {
    case 'br':
      return '(99) 99999-9999';
    case 'ru':
      return '9 (999) 999-99-99';
    case 'us':
    case 'ca':
      return '(999) 999-9999';
    case 'cn':
    case 'pt':
      return '999 9999 9999';
    default:
      return placeholder;
  }
};
