import img from 'assets/img/noData/notes.svg';
import Alert from 'components/Alert';
import Button from 'components/Button';
import FilterNotes from 'components/FilterNotes';
import { getStages } from 'components/FilterNotes/helpers';
import Loading from 'components/Loading';
import { ModalSubtitle } from 'components/Modal';
import Nodata from 'components/Nodata';
import NotesForm from 'components/NotesForm';
import NotesList from 'components/NotesList';
import { useCan } from 'hooks/useCan';
import { useRequest } from 'hooks/useRequest';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialize } from 'redux-form';
import * as notesService from 'services/crm/notes';
// import { Container } from './styles';

const Notes = ({ infos, person, stage, handleOpenMyself, addNote }) => {
  const [filter, setFilter] = useState('all');
  const [stages, setStages] = useState([]);

  const canSeePersonNotes = useCan({
    run: 'DYNAMIC_PERSON_NOTES',
    permissions: infos.permissions,
  });

  const dispatch = useDispatch();
  const [isAddNote, setIsAddNote] = useState(false);

  const { data, isFetching, fetchData } = useRequest({
    request: (f) => {
      return notesService.getNotesProperty(infos?.id, f);
    },
    initialFetch: false,
  });

  const handleSubmit = useCallback((values) => {
    if (values?.id) {
      return notesService.update({ ...values, property_id: infos?.id });
    }

    return notesService.create({ ...values, property_id: infos?.id });
  }, []);

  const fetchNotes = (filter) => {
    let filterObj = {};

    if (filter !== 'all') {
      filterObj = { stage_id: filter };
    }

    fetchData(filterObj);
  };

  const handleSubmitSuccess = () => {
    setIsAddNote(false);
    fetchNotes(filter);
  };

  const onClickEdit = (note) => () => {
    // Mostra a nota
    setIsAddNote(true);

    // Inicializa o formulário com o valor da nota
    dispatch(
      initialize('NotesForm', {
        ...note,
        category_id: note?.category?.id,
      })
    );
  };

  useEffect(() => {
    fetchData().then(({ data }) => {
      setStages(getStages(data));
    });
  }, []);

  useEffect(() => {
    if (addNote) {
      setIsAddNote(true);
    }
  }, [addNote]);

  if (isFetching) {
    return <Loading isVisible isBlock />;
  }

  return (
    <div>
      {!canSeePersonNotes && (
        <Alert color="secondary" style={{ marginTop: '20px' }}>
          Você está vendo somente anotações dos seus clientes
        </Alert>
      )}

      {data?.length <= 0 && !isAddNote && (
        <Nodata
          image={img}
          title="Nenhuma Anotação encontrada."
          text="Adicione a primeira anotação no botão abaixo:"
        >
          <Button
            className="h-margin-top--10"
            color="secondary"
            onClick={() => {
              setIsAddNote(true);
            }}
          >
            Adicionar anotação
          </Button>
        </Nodata>
      )}

      {isAddNote && (
        <div className="h-margin-top--15">
          <NotesForm
            stage={stage}
            person={person}
            initialValues={{
              stage_id: stage?.id,
              people_id: person?.id,
            }}
            handleHideForm={() => setIsAddNote(false)}
            onSubmit={handleSubmit}
            onSubmitSuccess={handleSubmitSuccess}
          />
        </div>
      )}

      {data?.length > 0 && (
        <>
          {!isAddNote && (
            <ModalSubtitle
              titleRenderer={() => (
                <FilterNotes
                  filter={filter}
                  setFilter={setFilter}
                  notes={data}
                  stages={stages}
                  onChangeFilter={(filter) => {
                    setFilter(filter);
                    fetchNotes(filter);
                  }}
                />
              )}
            >
              <Button color="secondary" onClick={() => setIsAddNote(true)}>
                Adicionar anotação
              </Button>
            </ModalSubtitle>
          )}

          <NotesList
            data={data}
            onClickEdit={onClickEdit}
            onUpdate={fetchNotes}
            afterClose={handleOpenMyself}
          />
        </>
      )}
    </div>
  );
};

export default Notes;
