import React, { useState } from 'react';

export const Context = React.createContext({
  id: '',
  setId: null
});

export const IdProvider = ({ children }) => {
  const [id, setId] = useState('');
  return <Context.Provider value={{ id, setId }}>{children}</Context.Provider>;
};
