import styled from 'styled-components';

export const Wrapper = styled.div``;
export const Inner = styled.div``;

export const Zoom = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  svg {
    font-size: 25px;
  }

  button {
    display: grid;
    place-items: center;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const Downloading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  font-size: 32px;
`;

export const Content = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.3);

  &:hover ${Zoom} {
    opacity: 1;
  }
`;
