import * as contractsService from 'services/financialv2/contracts';
import { ITEM_TYPES } from 'services/financialv2/contracts';
import { useRequest } from 'hooks/useRequest';
import { useCallback } from 'react';

/**
 * Retorna o contrato atual
 */
export const useCurrentContract = () => {
  const {
    data: currentContract,
    isFetching: isFetchingCurrentContract,
    fetchData: fetchCurrentContract,
  } = useRequest({
    request: contractsService.getCurrentContract,
    initialState: {},
  });

  return { currentContract, isFetchingCurrentContract, fetchCurrentContract };
};

export const useCurrentItems = () => {
  const {
    data: currentItems,
    isFetching: isFetchingCurrentItems,
    fetchData: fetchCurrentItems,
  } = useRequest({
    request: contractsService.getCurrentItems,
    initialState: {},
  });

  return { currentItems, isFetchingCurrentItems, fetchCurrentItems };
};

export const usePreview1 = () => {
  const { data: preview1, fetchData: fetchPreview1 } = useRequest({
    request: contractsService.changePreview1,
    initialFetch: false,
    initialState: {},
  });

  const handleFetchPreview1 = useCallback(
    ({ item = ITEM_TYPES.USERS, is_adding = false }) => {
      return fetchPreview1({ item, is_adding });
    },
    []
  );

  return { preview1, fetchPreview1: handleFetchPreview1, ITEM_TYPES };
};
