import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import FormGroup from '../FormGroup';
import { Wrapper } from './styles';

const Select2 = ({
  xs,
  sm,
  md,
  lg,
  label,
  formGroupClassName,
  required,
  disabled,
  input,
  meta,
  hidden,
  isLoading,
  isPrivate,
  options,
  labelKey = 'title',
  valueKey = 'id',
  optionLabel = 'title',
  optionValue = 'id',
  maxSelectedLabels = 1,
  selectedItemsLabel = 'itens',
  placeholder = 'Selecione',
  emptyFilterMessage = 'Nenhum resultado',
  ...props
}) => {
  const handleChange = (e) => {
    input.onChange(e.value);
  };

  const selectedLabel = () => {
    if (input?.value?.length > 1) {
      return `${input?.value?.length} ${selectedItemsLabel}`;
    }

    return selectedItemsLabel;
  };

  return (
    <FormGroup
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      labelText={label}
      className={formGroupClassName}
      isRequired={required}
      isDisabled={disabled}
      input={input}
      meta={meta}
      hidden={hidden}
      isLoading={isLoading}
      isPrivate={isPrivate}
    >
      <Wrapper>
        <MultiSelect
          {...props}
          disabled={disabled}
          optionLabel={labelKey || optionLabel}
          optionValue={valueKey || optionValue}
          value={options?.length <= 0 ? '' : input.value}
          options={options}
          onChange={handleChange}
          maxSelectedLabels={maxSelectedLabels}
          selectedItemsLabel={selectedLabel()}
          placeholder={placeholder}
        />
      </Wrapper>
    </FormGroup>
  );
};

export default Select2;
