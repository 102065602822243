import { openModalBankSlipSuccess } from 'modules/modal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as bankSlipService from 'services/financial/bankSlip';
import * as creditCardsService from 'services/financial/creditCards';
import * as transactionService from 'services/financial/transactions';
import { SubmissionError } from 'redux-form';

export const useContract = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dispatch = useDispatch();

  const contractBankSlip = async (values) => {
    try {
      await bankSlipService.create(values);

      const {
        data: { boleto_url },
      } = await transactionService.contractWithBankSlip(values);

      if (boleto_url) {
        window.open(boleto_url, '_blank');

        openModalBankSlipSuccess({ bankSlipUrl: boleto_url })(dispatch);
      }

      setIsSuccess(true);
    } catch (err) {
      if (err instanceof SubmissionError) {
        throw err;
      }
      setHasError(err);
      console.log('Erro ao contratar plano com boleto');
    }
  };

  const contractWithCreditCard = async (values) => {
    try {
      if (!values.card) await creditCardsService.create(values);

      const res = await transactionService.contractWithCreditCard(values);

      if (!!res.data.alerts) {
        throw new Error('Erro ao pagar com cartão de crédito');
      }

      setIsSuccess(true);
      return res;
    } catch (err) {
      if (err instanceof SubmissionError) {
        throw err;
      }
      console.log('Erro ao contratar plano com cartão de crédito');
      setHasError(err);
    }
  };

  return {
    hasError,
    setHasError,
    isSuccess,
    contractWithCreditCard,
    contractBankSlip,
  };
};

export default useContract;
