import React from 'react';
import ListInfo from '../../../../../../../components/ListInfo';
import { Phones } from '../../../styles';
import isEmpty from 'lodash/isEmpty';
import { FaWhatsapp } from 'react-icons/fa';
import Flag from 'components/Flag';
// import { Wrapper } from './styles';

function Infos({ modalPerson: { person }, openModalPerson }) {
  const renderGroupPerson = (groups) => {
    return groups ? groups.map((group) => group.name).join(', ') : '--';
  };

  const renderPhones = (phones) => {
    if (isEmpty(phones)) return 'Nenhum telefone';

    return phones
      .map(({ number, iso2, description, is_whatsapp }) => {
        return (
          <span>
            <Flag iso2={iso2} style={{ marginRight: '5px' }} />
            {number}({description})
            {is_whatsapp && <FaWhatsapp className="h-color--success" />}
          </span>
        );
      })
      .reduce((acc, x) => {
        return acc === null ? [x] : [acc, ', ', x];
      }, null);
  };

  const renderRelateds = () => {
    const allRelateds = person?.relateds;

    if (isEmpty(allRelateds)) return 'Nenhum cliente relacionado';

    return allRelateds
      .map(({ id, name, description }) => {
        return (
          <span>
            <span
              onClick={() => openModalPerson({ id }, { initialPage: 'Infos' })}
              className="h-link"
            >
              {name}
            </span>
            ({description})
          </span>
        );
      })
      .reduce((acc, x) => {
        return acc === null ? [x] : [acc, ', ', x];
      }, null);
  };

  return (
    <div>
      <ListInfo>
        <ListInfo.row>
          <ListInfo.item
            title="Categoria"
            text={renderGroupPerson(person.groups)}
          />
          <ListInfo.item
            title="Telefones"
            text={<Phones>{renderPhones(person.phones)}</Phones>}
          />
        </ListInfo.row>
        <ListInfo.row>
          <ListInfo.item title="E-mail" text={person.email || '--'} />
          <ListInfo.item title="Aniversário" text={person.birth_date || '--'} />
          <ListInfo.item title="Tipo" text={person.type_name || '--'} />
          <ListInfo.item title="CPF" text={person.cpf_cnpj || '--'} />
          <ListInfo.item title="RG" text={person.rg_ie || '--'} />
          <ListInfo.item title="Profissão" text={person.profession || '--'} />
        </ListInfo.row>
        <ListInfo.row>
          <ListInfo.item
            title="UF"
            text={(person.state && person.state.name) || '--'}
          />
          <ListInfo.item
            title="Cidade"
            text={(person.city && person.city.name) || '--'}
          />
          <ListInfo.item
            title="Bairro"
            text={(person.neighborhood && person.neighborhood.name) || '--'}
          />
          <ListInfo.item
            title="Logradouro"
            text={person.address_street || '--'}
          />
          <ListInfo.item
            title="Complemento"
            text={person.address_complement || '--'}
          />
          <ListInfo.item title="Nº" text={person.address_number || '--'} />
          <ListInfo.item title="CEP" text={person.address_cep || '--'} />
        </ListInfo.row>
      </ListInfo>
      <ListInfo>
        <ListInfo.row>
          <ListInfo.item title="Clientes ligados" text={renderRelateds()} />
        </ListInfo.row>
      </ListInfo>
    </div>
  );
}

export default Infos;
