import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
// Components
import Select from '../Select';
// Constants
import { BOOL } from 'constants/options';

const propTypes = {
  canRemoveSelf: PropTypes.bool,
  buttonTemplate: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object)
};
const defaultProps = {
  canRemoveSelf: false,
  buttonTemplate: true,
  component: Select,
  options: BOOL,
  responsive: true
};

const FieldBool = ({ ...props }) => <Field {...props} />;

FieldBool.propTypes = propTypes;
FieldBool.defaultProps = defaultProps;

export default FieldBool;
