import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Wrapper from 'components/Wrapper';
// Pages
import List from './pages/List';
import SalesAuthorization from './pages/SalesAuthorization';
import RentAuthorization from './pages/RentAuthorization';
import { Redirect } from 'react-router';

const Documents = () => {
  return (
    <Wrapper.container>
      <Switch>
        <Route path="/config/documents/list" component={List} />
        <Route
          path="/config/documents/sales-authorization"
          component={SalesAuthorization}
        />
        <Route
          path="/config/documents/rent-authorization"
          component={RentAuthorization}
        />
        <Redirect to="/config/documents/list" />
      </Switch>
    </Wrapper.container>
  );
};

export default Documents;
