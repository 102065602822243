import React from 'react';
import Wrapper from 'components/Wrapper';
import TableFunnels from './components/TableFunnels';
import { useFunnel } from 'pages/Crm/hooks/useFunnel';
import ConfigFunnel from './components/ConfigFunnel';

function Config() {
  useFunnel();

  return (
    <Wrapper.inner>
      <Wrapper.container style={{ paddingBottom: '50px' }}>
        <TableFunnels />
        <ConfigFunnel />
      </Wrapper.container>
    </Wrapper.inner>
  );
}

export default Config;
