import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { InputDate } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import moment, { format } from 'lib/moment';
import { isToday } from 'lib/date-helpers';
// Validate
import validate from './validate';
import { useSelector } from 'react-redux';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const styles = {
  input: {
    marginTop: '15px'
  }
};

const selector = formValueSelector('FormSchedule');

function FormSchedule({ handleClose, handleSubmit }) {
  const dateTime = useSelector(state => selector(state, 'best_time'));

  //
  const _isToday = isToday(dateTime, format.datetime);

  const minDate = moment()
    .hours(_isToday ? moment().hour() + 1 : 7)
    .minutes(0);
  const maxDate = moment()
    .hours(17)
    .minutes(0);

  return (
    <form onSubmit={handleSubmit}>
      Selecione qual o melhor dia e hora para receber a ligação de um consultor:
      <Field
        showTimeSelect={dateTime}
        name="best_time"
        label="Dia e hora"
        style={styles.input}
        minDate={moment()}
        timeIntervals={60}
        minTime={minDate}
        maxTime={maxDate}
        component={InputDate}
      />
      <ModalFooter>
        <Button color="white" colorText="primary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Concluir
        </Button>
      </ModalFooter>
    </form>
  );
}

FormSchedule.defaultProps = defaultProps;
FormSchedule.propTypes = propTypes;

export default reduxForm({
  form: 'FormSchedule',
  validate,
  enableReinitialize: true
})(FormSchedule);
