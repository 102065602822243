import styled from 'styled-components';

export const Wrapper = styled.div`
  .p-placeholder {
    font-size: 13px;
    padding-right: 0;
  }
  .Button + .Button {
    margin-left: 0;
    margin-top: 10px;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #1c0c1e;
  margin-bottom: 10px;
`;

export const BtnGroup = styled.div`
  display: flex;

  .Button {
    padding: 0 10px;
    margin: 0;

    :first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;
