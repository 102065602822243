import { mapValues } from 'lodash';

/*
  #CAMPOS SEM ALTERAÇÃO

  with_condo_price
  is_financeable_mcmv
  is_property_titled
  is_deeded
  is_exchangeable
  transaction
  profiles
  minimum_price
  maximum_price
 */

export const profileToSearch = ({
  with_condo_price,
  is_financeable_mcmv,
  is_property_titled,
  is_deeded,
  is_exchangeable,
  transaction,
  profiles,
  minimum_price,
  maximum_price,
  ..._values
}) => {
  const payload = {
    by_actives: true,
    with_condo_price,
    is_financeable_mcmv,
    is_property_titled,
    is_deeded,
    is_exchangeable,
    transaction,
    profiles,
    minimum_price,
    maximum_price
  };

  if (_values.type_or_subtype_id)
    payload.by_type_or_subtype_id = _values.type_or_subtype_id;

  if (_values.situations_id) payload.by_situation_id = _values.situations_id;
  if (_values.state_id) payload.by_state_id = _values.state_id;

  if (_values.city_id) payload.by_city_id = _values.city_id;
  if (_values.neighborhoods)
    payload.by_neighborhood_id = Object.keys(_values.neighborhoods);

  if (_values.establishments)
    payload.by_establishment_id = Object.keys(_values.establishments);

  if (_values.characteristics)
    payload.by_characteristic_id = Object.keys(_values.characteristics);

  if (_values.rooms) {
    payload.by_room_greater_equals = mapValues(
      _values.rooms,
      room => room.value
    );
  }

  if (_values.areas) {
    payload.by_area = mapValues(
      _values.areas,
      ({ minimum, maximum, measure }) => {
        if (minimum || maximum) {
          return {
            min: minimum,
            max: maximum,
            measure
          };
        }
      }
    );
  }

  return payload;
};
