import React from 'react';
// Components
import Wizard from 'components/Wizard';
import { withWizard } from 'HOC/withWizard';
// HOC

const STEPS = [
  {
    target: '.js-visao-geral',
    title: 'Visão geral do funil escolhido',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    isFixed: true,
    placement: 'right-start',
  },
  {
    target: '.js-funnel-select',
    title: 'Escolha qual funil será analisado',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    isFixed: true,
    placement: 'right-start',
  },
  {
    target: '.js-user-select',
    title:
      'Escolha entre 1 ou todos corretores para uma visão agregada dos dados',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    isFixed: true,
    placement: 'right-start',
  },
  {
    target: '.js-button-config',
    title: 'Gerencie seus funis e configurações padrão',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    isFixed: true,
    placement: 'right-start',
  },
];

function WizardCrmLayout({ onClose }) {
  return <Wizard steps={STEPS} onClose={onClose} />;
}
export default withWizard('wizard/crm-dashboard-2')(WizardCrmLayout);
// export default WizardCrmLayout;
