import api from './';
import { COLORS_HEXADECIMAL } from 'constants/config';

export const transformResponseResults = (res) => {
  if (res.data) {
    res.meta = {};

    // Pega todos os periodos
    if (res?.data[0]?.data) {
      res.meta.periods = res.data[0].data.map(({ period }) => period);
    }

    res.meta.results = res.data.reduce((acc, item) => {
      // Inicializa caso não exista
      if (!acc.total) acc.total = 0;

      item.data.forEach(({ period, value }) => {
        // Inicializa caso não exista
        if (!acc[period]) acc[period] = 0;
        acc[period] += value;
        acc.total += value;
      });

      return acc;
    }, {});
  }

  return res;
};

// Pega a soma
const getTotal = (acc, item) => acc + item.total;
const getTotalPeriods = (data) => {
  const totals = data.reduce((acc, item) => {
    item.periods.forEach(({ period, value }) => {
      const accPeriod = acc[period] || 0;
      acc[period] = parseInt(accPeriod, 10) + value;
    });

    return acc;
  }, {});

  return Object.keys(totals).map((key) => ({
    period: key,
    value: totals[key],
  }));
};

// Pega todos os periodos
const getPeriods = (data) => {
  try {
    return data[0].periods.map(({ period }) => period);
  } catch (err) {
    return [];
  }
};

// Pega os tipos
const getTypes = (data) => {
  try {
    return data.map(({ type }) => type);
  } catch (err) {
    return [];
  }
};

const getGraphPeriods = (data) => {
  const periods = data.reduce((acc, item) => {
    item.periods.forEach(({ period, value }) => {
      // Pega o valor do periodo
      const currentValue = acc?.[period]?.[item.type] || 0;

      acc[period] = {
        ...acc[period],
        [item.type]: currentValue + value,
      };
    });

    return acc;
  }, {});

  return Object.keys(periods).map((key) => ({
    month: key,
    ...periods[key],
  }));
};

/**
 * Pega o relatório de Imóveis por período
 * @param params
 * @return {*}
 */
export const getPropertiesPerPeriod = async (params) => {
  try {
    const response = await api.get('reports/properties/per-period', {
      ...params,
    });

    // Total de todas as colunas
    const total = response.data.reduce(getTotal, 0);

    // Pega o total dos periodos
    const totals = getTotalPeriods(response.data);

    // Pega todos os períodos
    const periods = getPeriods(response.data);

    // Pega todos os períodos
    const types = getTypes(response.data);

    // Pega o gráfico de periodos
    const graphPeriods = getGraphPeriods(response.data);

    response.meta = {
      total,
      totals,
      periods,
      types,
      graphPeriods,
    };

    return response;
  } catch (err) {
    throw err;
  }
};

/**
 * Pega o relatório de Imóveis por período
 * @param params
 * @return {*}
 */
export const getPropertiesPerPrice = async (params) => {
  try {
    const response = await api.get('reports/properties/per-period-sum', {
      ...params,
    });

    // Total de todas as colunas
    const total = response.data.reduce(getTotal, 0);

    // Pega o total dos periodos
    const totals = getTotalPeriods(response.data);

    // Pega todos os períodos
    const periods = getPeriods(response.data);

    // Pega todos os períodos
    const types = getTypes(response.data);

    // Pega o gráfico de periodos
    const graphPeriods = getGraphPeriods(response.data);

    response.meta = {
      total,
      totals,
      periods,
      types,
      graphPeriods,
    };

    return response;
  } catch (err) {
    throw err;
  }
};

const TYPES = {
  venda: 'Venda',
  locacao: 'Locação',
  temporada: 'Temporada',
};

/**
 * Pega o relatório de Imóveis por tipo e transação
 * @param params
 * @return {*}
 */
export const getPropertiesPerTypeTransaction = async (params) => {
  try {
    const response = await api.get('reports/properties/per-type-transaction', {
      ...params,
    });

    const totals = response.data.reduce(
      (acc, item) => {
        const locacao = acc.locacao || 0;
        const temporada = acc.temporada || 0;
        const venda = acc.venda || 0;
        const total = acc.total || 0;

        return {
          ...acc,
          locacao: locacao + parseInt(item.locacao, 10),
          temporada: temporada + parseInt(item.temporada, 10),
          venda: venda + parseInt(item.venda, 10),
          total: total + parseInt(item.total, 10),
        };
      },
      {
        locacao: 0,
        temporada: 0,
        venda: 0,
        total: 0,
      }
    );

    const by_type = response.data.reduce((acc, item) => {
      const type = acc[item.type] || 0;
      return {
        ...acc,
        [item.type]: type + parseInt(item.total, 10),
      };
    }, []);

    response.meta = {
      totals,
      graphs: {
        by_transaction: Object.keys(totals).map((key, index) => {
          if (key === 'total') return null;

          return {
            type: TYPES[key],
            value: totals[key],
            color: COLORS_HEXADECIMAL[index % COLORS_HEXADECIMAL.length],
          };
        }),
        by_type: Object.keys(by_type).map((key, index) => {
          return {
            type: key,
            value: by_type[key],
            color: COLORS_HEXADECIMAL[index % COLORS_HEXADECIMAL.length],
          };
        }),
      },
    };

    return response;
  } catch (err) {
    throw err;
  }
};

export const getPropertiesOruloPerTypeTransaction = (params) =>
  getPropertiesPerTypeTransaction({
    ...params,
    by_network: 'orulo',
  });

export const getPropertiesDwvPerTypeTransaction = (params) =>
  getPropertiesPerTypeTransaction({
    ...params,
    by_network: 'dwv',
  });

/**
 * Pega o relatório de clientes por período
 * @param params
 * @return {*}
 */
export const getPeoplePerPeriod = (params) =>
  api.get('reports/people/per-period', { ...params });

/**
 * Pega o relatório de clientes por canal
 * @param params
 * @return {*}
 */
export const getPeoplePerChannel = (params) =>
  api.get('reports/people/per-channel', { ...params });

/**
 * Pega o relatório de negócios por corretor
 * @param params
 * @return {*}
 */
export const getDealPerBroker = (params) =>
  api.get('reports/business/per-broker', { ...params });

/**
 * Pega o relatório de negócios por canal
 * @param params
 * @return {*}
 */
export const getDealPerChannel = (params) =>
  api.get('reports/business/per-channel', { ...params });

/**
 * Pega o relatório de motivos de perda dos negócios
 * @param params
 * @return {*}
 */
export const getDealPerReasonLoss = (params) =>
  api.get('reports/business/per-loss-reason', { ...params });

/**
 * Pega o relatório de faturamento por corretor
 * @param params
 * @return {*}
 */
export const getBillingPerBroker = (params) =>
  api
    .get('reports/billing/per-broker', { ...params })
    .then(transformResponseResults);

/**
 * Pega o relatório de faturamento por comissão
 * @param params
 * @return {*}
 */
export const getBillingPerCommission = (params) =>
  api
    .get('reports/billing/per-commission', { ...params })
    .then(transformResponseResults);

/**
 * Pega o relatório de faturamento por comissão da imobiliária
 * @param params
 * @return {*}
 */
export const getBillingPerRealEstateCommission = (params) =>
  api.get('reports/billing/per-real-estate-commission', params).then((res) => {
    res.meta = {
      total: res.data.reduce((acc, item) => {
        acc += item?.value;
        return acc;
      }, 0),
    };
    return res;
  });

/**
 * Pega o relatório de faturamento pelo tipo do imóvel/transação
 * @param params
 * @return {*}
 */
export const getBillingPerTypeTransaction = (params) => {
  return api
    .get('reports/billing/per-type-transaction', {
      ...params,
    })
    .then(transformResponseResults);
};

/**
 * Pega a lista de negócios perdidos
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLossDeals = (params) =>
  api.get('reports/business/per-loss-deal', params);

/**
 * Pega a lista de negócios ganhos
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWonDeals = (params) => {
  const _params = { ...params };

  if (_params.transaction === 3 || _params.transaction === '3')
    delete _params.transaction;
  return api.get('reports/business/per-won-deal', _params);
};
