import { Col, Row } from 'react-flexbox-grid';
import { MdClose } from 'react-icons/md';

export default function Remove({ fields, index }) {
  if (fields.length === 1) return null;

  return (
    <Row end={'xs'}>
      <Col>
        <button type="button" onClick={() => fields.remove(index)}>
          <MdClose />
        </button>
      </Col>
    </Row>
  );
}
