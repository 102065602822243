// Widgets.js
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { arrayMove } from 'react-sortable-hoc';
// Helpers
import { createModule, uploadFiles } from 'lib/reducer-helpers';
// Modules
import uploadReducer, { getUploadActions, uploadSelector } from './upload';
import { Actions as UploadActions } from './fileUpload';
// Services
import * as propertiesService from 'services/properties';

export const currentGallerySelector = state =>
  state.propertyGallery.currentGallery;
export const gallerySelector = state => state.propertyGallery.gallery;
export const allImagesSelector = createSelector(
  [currentGallerySelector, gallerySelector],
  (currentGallery, gallery) => {
    return gallery[currentGallery];
  }
);
export const imagesSelector = createSelector(allImagesSelector, images =>
  images.map((image, i) =>
    i === 0 ? { ...image, isFeatured: true } : { ...image, isFeatured: false }
  )
);

export const isFetchingSelector = state =>
  state.propertyGallery.meta.isFetching;

// Actions
const createAction = createModule('propertyGallery');
const uploadActions = getUploadActions('propertyGallery');
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const RECEIVE_IMAGE = createAction('RECEIVE_IMAGE');

// Image actions
const LOADING_IMAGE = createAction('LOADING_IMAGE');
const LOADED_IMAGE = createAction('LOADED_IMAGE');

const ALTER_IMAGE = createAction('ALTER_IMAGE');
const REMOVE_IMAGE = createAction('REMOVE_IMAGE');
const REMOVE_ALL_IMAGES = createAction('REMOVE_ALL_IMAGES');
const ORDER_IMAGE = createAction('ORDER_IMAGE');
const ROTATE_IMAGE = createAction('ROTATE_IMAGE');

// Sort actions
const UPDATE_ORDER = createAction('UPDATE_ORDER');

// Bool actions
const TOGGLE_EDITING = createAction('TOGGLE_EDITING');

const SET_GALLERY = createAction('SET_GALLERY');

const initialState = {
  images: [],
  gallery: {
    1: [],
    2: [],
    3: []
  },
  currentGallery: 1,
  meta: {
    isFetching: false
  }
};

const metaReducer = (state = initialState.meta, action) => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
};

const imageInitialState = {
  isFeatured: false,
  isFetching: false,
  isEditing: false,
  imageAngle: 0
};

function reducerCurrentGallery(state = initialState.currentGallery, action) {
  switch (action.type) {
    case SET_GALLERY:
      return action.gallery;
    default:
      return state;
  }
}

function reducer(state = initialState.gallery, action) {
  switch (action.type) {
    case RECEIVE:
      return {
        ...state,
        [action.gallery]: action.images.map(image => {
          return {
            ...image,
            ...imageInitialState
          };
        })
      };

    case ORDER_IMAGE:
      return {
        ...state,
        [action.gallery]: arrayMove(
          state[action.gallery],
          action.indexes.oldIndex,
          action.indexes.newIndex
        ).map((image, i) => ({
          ...image,
          order: i + 1
        }))
      };
    case RECEIVE_IMAGE:
      return {
        ...state,
        [action.gallery]: [
          ...state,
          {
            ...action.image,
            ...imageInitialState,
            isFeatured: action.image.is_featured
          }
        ]
      };

    case TOGGLE_EDITING:
      return {
        ...state,
        [action.gallery]: [
          ...state[action.gallery].map(image => {
            // verifica se o id da imagem é === ao que foi enviado no payload
            if (image.id === action.id) {
              return { ...image, isEditing: !image.isEditing };
            }

            return image;
          })
        ]
      };
    case UPDATE_ORDER:
      return {
        ...state,
        [action.gallery]: [
          ...state[action.gallery].sort((imgPrev, imgNext) => {
            if (imgPrev.order < imgNext.order) {
              return -1;
            }

            if (imgPrev.order > imgNext.order) {
              return 1;
            }

            return 0;
          })
        ]
      };
    case ROTATE_IMAGE:
      return {
        ...state,
        [action.gallery]: state[action.gallery].map(image => {
          // verifica se o id da imagem é === ao que foi enviado no payload
          if (image.id === action.id) {
            return { ...image, imageAngle: action.imageAngle };
          }
          return image;
        })
      };
    case ALTER_IMAGE:
      return {
        ...state,
        [action.gallery]: [
          ...state[action.gallery].map(image => {
            if (image.id === action.values.id) {
              return {
                ...image,
                ...action.values
              };
            }
            return image;
          })
        ]
      };

    case REMOVE_ALL_IMAGES: {
      return {
        ...state,
        [action.gallery]: []
      };
    }

    case REMOVE_IMAGE: {
      const { order } = state[action.gallery].find(
        image => image.id === action.id
      );

      return {
        ...state,
        [action.gallery]: state[action.gallery]
          .map(image => {
            // diminui
            if (image.order > order) {
              return { ...image, order: image.order - 1 };
            }
            return image;
          })
          .filter(image => image.id !== action.id)
      };
    }
    case LOADING_IMAGE:
      return {
        ...state,
        [action.gallery]: state[action.gallery].map(image =>
          image.id === action.id ? { ...image, isFetching: true } : image
        )
      };
    case LOADED_IMAGE:
      return {
        ...state,
        [action.gallery]: state[action.gallery].map(image =>
          image.id === action.id ? { ...image, isFetching: false } : image
        )
      };
    default:
      return state;
  }
}

export default combineReducers({
  //images: reducer,
  gallery: reducer,
  meta: metaReducer,
  currentGallery: reducerCurrentGallery,
  upload: uploadReducer('propertyGallery')
});

// Action Creators
export function requestPropertyImages() {
  return { type: REQUEST };
}

export function receivePropertyImages(images, gallery) {
  return { type: RECEIVE, images, gallery };
}

export function receivePropertyImage(image, gallery) {
  return { type: RECEIVE_IMAGE, image, gallery };
}

export function toggleEditing(id, gallery) {
  return { type: TOGGLE_EDITING, id, gallery };
}

export function rotateImage(id, imageAngle, gallery) {
  return { type: ROTATE_IMAGE, id, imageAngle, gallery };
}

export function loadingImage(id, gallery) {
  return { type: LOADING_IMAGE, id, gallery };
}

export function loadedImage(id, gallery) {
  return { type: LOADED_IMAGE, id, gallery };
}

export function alterImage(values, gallery) {
  return { type: ALTER_IMAGE, values, gallery };
}

export function setGallery(gallery) {
  return { type: SET_GALLERY, gallery };
}

export function removeAllImages(gallery) {
  return { type: REMOVE_ALL_IMAGES, gallery };
}

// side effects, only as applicable
// e.g. thunks, epics, etc'

export function handleChangeRotation(id, property_id, currentAngle, gallery) {
  return dispatch => {
    //const imageAngle = rotate(currentAngle);
    dispatch(loadingImage(id, gallery));

    propertiesService
      .rotateImage({
        id,
        property_id,
        angle: -90
      })
      .then(({ data }) => {
        dispatch(alterImage(data, gallery));
      })
      .finally(() => {
        dispatch(loadedImage(id, gallery));
      });
  };
}

/**
 * Lida com o formulario de edição de caption
 * @param values
 * @return {function(*)}
 */
export function handleSubmitEdit(values, gallery) {
  return dispatch => {
    dispatch(alterImage(values, gallery));
    propertiesService.alterImage(values);
  };
}

/**
 * Lida com a listagem de imagens
 * @param property_id
 * @param gallery
 * @return {function(*)}
 */
export function getPropertyImages(property_id, gallery) {
  return dispatch => {
    dispatch(requestPropertyImages());

    return propertiesService
      .getImages(property_id, {
        filter: { gallery }
      })
      .then(({ data }) => {
        dispatch(receivePropertyImages(data, gallery));
        return data;
      });
  };
}

export const orderImages = order => dispatch => {
  dispatch(requestPropertyImages());

  return propertiesService.getImages({ order }).then(() => {});
};

/**
 * Lida com o formulario de upload de imagens
 * @param property_id
 * @param gallery
 * @param files
 * @param lastOrder
 * @return {function(*=)}
 */
export function handleFormChange({ property_id, gallery, files, lastOrder }) {
  return async (dispatch, getState) => {
    const { cancelToken } = uploadSelector('propertyGallery')(getState());

    const _filesWithOrder = files?.map((file, index) => {
      return {
        file,
        order: index
      };
    });

    return uploadFiles({
      cancelToken,
      request: propertiesService.addImage,
      params: ({ file, order }) => {
        return {
          property_id,
          image: file,
          name: file.name,
          order: lastOrder + order + 1,
          gallery
        };
      },
      files: _filesWithOrder,
      onUploadStart: ({ total }) => {
        dispatch(UploadActions.uploadStart());

        _filesWithOrder.forEach(({ file }) => {
          dispatch(UploadActions.addFile(file));
        });
      },
      onUploadSuccess: (data, upload, { file }) => {
        if (upload.success === 1) {
          propertiesService.registerAddImage(property_id);
        }
        return dispatch(UploadActions.uploadSuccess(file));
      },
      onUploadFail: (data, { file }) =>
        dispatch(UploadActions.uploadError(file)),
      onUploadEnd: async () => {
        // await propertiesService.orderImages(property_id, gallery);
        await getPropertyImages(property_id, gallery)(dispatch);

        dispatch(UploadActions.uploadEnd());
        dispatch(uploadActions.resetUpload());
      }
    });
  };
}

/**
 * Lida com ordenacao das imagens
 * @param indexes
 * @param property_id
 * @return {function(*, *)}
 */
export function orderImage(indexes, property_id, gallery) {
  return (dispatch, getState) => {
    // orderna as imagens
    dispatch({ type: ORDER_IMAGE, indexes, gallery });

    // pega todas as imagens
    const images = imagesSelector(getState());

    // pega o indice da posicao da imagem
    let { newIndex } = indexes;

    // pega a imagem para ser modificada
    const newImage = images.find(
      (image, index) => [newIndex].indexOf(index) >= 0
    );

    // altera
    propertiesService.alterImage({
      ...newImage,
      order: newIndex + 1,
      property_id
    });
  };
}

/**
 * Deleta uma imagem
 * @param id
 * @param property_id
 * @param gallery
 * @return {function(*)}
 */
export function handleDeleteImage(id, property_id, gallery) {
  return dispatch => {
    dispatch(loadingImage(id, gallery));

    return propertiesService
      .removeImage(id, property_id)
      .then(() => {
        dispatch({ type: REMOVE_IMAGE, id, gallery });
      })
      .finally(() => {
        dispatch(loadedImage(id, gallery));
      });
  };
}

/**
 * Inverte o status de edição
 * @param id
 * @return {function(*)}
 */
export function handleToggleEdit(id, gallery) {
  return dispatch => {
    dispatch(toggleEditing(id, gallery));
  };
}
