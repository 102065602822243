import { combineReducers } from 'redux';
// Modules
import peopleBirthdayReducer, {
  fetchPeopleWithBirthday
} from './components/PeopleBirthday/module';
import peoplePositionFunnelReducer, {
  fetchPeoplePositionFunnel
} from './components/PeoplePositionFunnel/module';
import peopleScheduledReducer, {
  fetchPeopleEvents
} from './components/PeopleScheduled/module';
import peopleWithInteractionReducer, {
  fetchPeopleInteractions
} from './components/PeopleWithInteraction/module';

export const updatePeopleDashboard = () => dispatch => {
  fetchPeopleWithBirthday()(dispatch);
  fetchPeoplePositionFunnel()(dispatch);
  fetchPeopleEvents()(dispatch);
  fetchPeopleInteractions()(dispatch);
};

// Root reducer
export default combineReducers({
  peopleBirthday: peopleBirthdayReducer,
  peoplePositionFunnel: peoplePositionFunnelReducer,
  peopleScheduled: peopleScheduledReducer,
  peopleWithInteraction: peopleWithInteractionReducer
});
