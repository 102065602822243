import _ from 'lodash';
import { createModule } from 'lib/reducer-helpers';

// Actions
const createAction = createModule('formData');

export const ActionTypes = {
  REGISTER_FORM_DATA: createAction('REGISTER_FORM_DATA'),
  REQUEST_DATA: createAction('REQUEST_FORM_DATA'),
  RECEIVE_DATA: createAction('RECEIVE_FORM_DATA'),
  UNREGISTER_FORM_DATA: createAction('UNREGISTER_FORM_DATA')
};

const initialFormData = {
  data: null,
  meta: {
    isFetching: false
  }
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.REGISTER_FORM_DATA:
      return {
        ...state,
        [action.formName]: initialFormData
      };

    case ActionTypes.REQUEST_DATA:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          isFetching: true
        }
      };

    case ActionTypes.RECEIVE_DATA:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          data: action.data,
          isFetching: false
        }
      };

    case ActionTypes.UNREGISTER_FORM_DATA:
      return _.omit(state, action.formName);

    default:
      return state;
  }
}

export const Selectors = {
  getData: (state, formName) => {
    try {
      return state.formData[formName].data;
    } catch {
      return null;
    }
  },
  getIsFetching: (state, formName) => {
    try {
      return state.formData[formName].isFetching;
    } catch {
      return null;
    }
  }
};

export const Actions = {
  registerFormData: formName => ({
    type: ActionTypes.REGISTER_FORM_DATA,
    formName
  }),
  unregisterFormData: formName => ({
    type: ActionTypes.UNREGISTER_FORM_DATA,
    formName
  }),
  requestFormData: formName => ({ type: ActionTypes.REQUEST_DATA, formName }),
  receiveFormData: (formName, data) => ({
    type: ActionTypes.RECEIVE_DATA,
    formName,
    data
  })
};
