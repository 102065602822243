import PropertiesDiscarted from './PropertiesDiscarted';
import PropertiesOfClient from './PropertiesOfClient';
import PropertiesNewMatched from './PropertiesNewMatched';
import PropertiesSended from './PropertiesSended';
import PropertiesInterested from './PropertiesInterested';
import PropertiesNegotiated from './PropertiesNegotiated';
import Reservations from './Reservations';

export default {
  PropertiesDiscarted,
  PropertiesOfClient,
  PropertiesNewMatched,
  PropertiesSended,
  PropertiesInterested,
  PropertiesNegotiated,
  Reservations,
};
