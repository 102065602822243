import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import Icon from '../Icon';
import FormLabel from '../Form/components/FormLabel';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {
  children: null
};

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exited: { display: 'none' }
};

class Expand extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isOpen: props.isOpen
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: prevProps.isOpen });
    }
  }

  handleToggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { label, children, controled } = this.props;
    const { isOpen: isOpenState } = this.state;

    const isOpen = controled || isOpenState;

    return (
      <div className="Expand">
        <div className="Expand__header" onClick={this.handleToggle}>
          <FormLabel>{label}</FormLabel>
          <button
            type="button"
            className="Expand__button"
            style={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
              transition: 'transform .2s'
            }}
          >
            <Icon name="chevron-down" />
          </button>
        </div>

        <Transition in={isOpen} timeout={duration}>
          {state => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state]
              }}
              className="Expand__container"
            >
              {children}
            </div>
          )}
        </Transition>
      </div>
    );
  }
}

Expand.propTypes = propTypes;
Expand.defaultProps = defaultProps;

export default Expand;
