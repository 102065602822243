import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
// Components
import Button from 'components/Button';
import FormBox from 'components/FormBox';
import { Input, SelectMultiple, Toggle } from 'components/Form';
// Modules
import { getPeople, peopleSelector } from 'modules/people';
import { openModalPersonAdd } from 'modules/modal';
// Helpers
import { parseCheckbox, parseSelect } from 'lib/formHelpers';

const defaultProps = {
  fields: []
};
const propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object)
};

class Owner extends React.Component {
  render() {
    const { people, canSendResume } = this.props;

    return (
      <FormBox
        className="js-owner"
        title="Proprietário"
        actions={
          <Button
            color="secondary"
            size="medium"
            onClick={() => {
              this.props.openModalPersonAdd(null, person => {
                // Atualiza a lista de clientes
                this.props.getPeople();

                // Seleciona o cliente que ele acabou de cadastrar
                this.props.change('people_id', person.id);
              });
            }}
          >
            Cadastrar Proprietário
          </Button>
        }
      >
        <Row>
          <Field
            multi={false}
            required
            xs={3}
            label="Selecione o proprietário"
            name="people_id"
            component={SelectMultiple}
            options={people}
            valueKey="id"
            labelKey="name"
            parse={parseSelect('id')} //
          />

          <Col xs={4}>
            <Row>
              <Field
                xs
                label="Enviar resumo?"
                name="can_send_resume"
                component={Toggle}
                parse={parseCheckbox}
                onChange={this.handleChangeResume}
              />
              {canSendResume && (
                <Field
                  xs={7}
                  type="number"
                  label="A cada quantos dias"
                  name="resume_days"
                  component={Input}
                />
              )}
            </Row>
          </Col>
        </Row>
      </FormBox>
    );
  }
}

Owner.defaultProps = defaultProps;
Owner.propTypes = propTypes;

const selector = formValueSelector('propertiesAdd');

const mapStateToProps = state => ({
  people: peopleSelector(state),
  canSendResume: selector(state, 'can_send_resume')
});

const mapDispatchToProps = {
  getPeople,
  openModalPersonAdd
};

export default connect(mapStateToProps, mapDispatchToProps)(Owner);
