import React from 'react';
import PropTypes from 'prop-types';
import { PROPERTY_SHAPE } from 'constants/shapes';
// Components
import CardVertical from './components/CardVertical';
import CardHorizontal from './components/CardHorizontal';

const defaultProps = {
  type: 'vertical',
  handleClickProperty: null,
  handleClickFeatured: null,
  handleClickChecked: null
};

const propTypes = {
  type: PropTypes.oneOf(['vertical', 'horizontal']),
  property: PROPERTY_SHAPE.isRequired,
  handleClickProperty: PropTypes.func,
  handleClickFeatured: PropTypes.func,
  handleClickChecked: PropTypes.func
};

const CardCondominium = props =>
  props.type === 'vertical' ? (
    <CardVertical {...props} />
  ) : (
    <CardHorizontal {...props} />
  );

CardCondominium.defaultProps = defaultProps;
CardCondominium.propTypes = propTypes;

export default CardCondominium;
