import React, { useState, createElement } from 'react';
import { ModalTemplate } from 'components/Modal';
import TextOverflow from 'components/TextOverflow';
import Modal from 'react-modal';
import Infos from './components/Infos';
import Properties from './components/PropertiesOfClient';

const pages = {
  Infos,
  Properties,
};

const style = {
  menu: {
    display: 'flex',
    margin: '-20px -20px 20px -20px',
    borderBottom: '1px solid #e0e5eb',
  },
  item: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    padding: '17px 15px',
    color: 'rgba(62, 88, 124, 0.54)',
  },
  itemActive: {
    color: '#0063c0',
    backgroundColor: 'rgba(0, 99, 192, 0.1)',
  },
};

const ModalPersonOrulo = ({ props, handleClose, reopenModal }) => {
  const [page, setPage] = useState('Infos');

  const {
    modalConfig,
    isOpen,
    modalType,
    modalPerson,
    modalPerson: {
      person,
      meta: { isFetching },
    },
  } = props;

  return (
    <Modal
      {...modalConfig}
      className={{
        ...modalConfig.className,
        base: 'Modal Modal--person',
      }}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={modalPerson.meta.isFetching}
        handleClose={handleClose}
        renderHeader={() => (
          <header className="Modal__header Modal__header--custom h-flex h-flex--center-center">
            <h4 {...props} className="Modal__title h-pointer">
              <TextOverflow width={370} style={{ verticalAlign: 'middle' }}>
                {person.name}
              </TextOverflow>{' '}
            </h4>
          </header>
        )}
      >
        <div style={style.menu}>
          <button onClick={() => setPage('Infos')}>Dados</button>
          <button onClick={() => setPage('Properties')}>Imóveis</button>
        </div>
        {/*{createElement(pages[page], {*/}
        {/*  ...props,*/}
        {/*  reopenModal: reopenModal,*/}
        {/*  onSubmitSuccessEdit: props.person.onSubmitSuccessEdit,*/}
        {/*})}*/}
      </ModalTemplate>
    </Modal>
  );
};

export default ModalPersonOrulo;
