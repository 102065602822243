import FieldCondominium from 'containers/FieldCondominium';
import { useCondos } from 'hooks/useCondos';
import { useEffect } from 'react';

function CondoMultiSelect({ filter, ...props }) {
  const { fetchData } = useCondos({
    initialFetch: false,
  });

  useEffect(() => {
    if (props.network === 'self') {
      fetchData({ filter: { on_network: ['self'], ...filter } });
    } else {
      fetchData({ filter: { on_network: ['self', props.network], ...filter } });
    }
  }, [props.network]);

  if (props.network === 'orulo') {
    return (
      <FieldCondominium
        label={null}
        name="condominium_id"
        placeholder="Selecione o condomínio, ou"
        hasOrulo={props.network === 'orulo'}
        filter={{
          on_network: ['orulo'],
        }}
        {...props}
      />
    );
  }

  if (props.network === 'dwv') {
    return (
      <FieldCondominium
        label={null}
        name="condominium_id"
        placeholder="Selecione o condomínio, ou"
        hasOrulo={props.network === 'dwv'}
        filter={{
          on_network: ['dwv'],
        }}
        {...props}
      />
    );
  }

  return (
    <FieldCondominium
      label={null}
      name="condominium_id"
      placeholder="Selecione o condomínio, ou"
      hasOrulo={props.network === 'orulo'}
      {...props}
    />
  );
}

export default CondoMultiSelect;
