import React from 'react';
import { connect } from 'react-redux';
// Modules
import { condosCharacteristicsSelectors, fetchCondoCharacteristics } from './module';
// Components
import { CondoCharacteristics } from 'components/PropertyFilters';

class CondosCharateristicContainer extends React.Component {
  componentDidMount() {
    // Busca todas as caracteristicas
    this.props.fetchCondoCharacteristics();
  }

  render() {
    const { data } = this.props;
    return <CondoCharacteristics options={data} />;
  }
}

const mapStateToProps = state => ({
  data: condosCharacteristicsSelectors.getAll(state)
});

const mapDispatchToProps = {
  fetchCondoCharacteristics
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CondosCharateristicContainer);
