import React from 'react';
import { connect } from 'react-redux';
// Modules
import {
  fetchProperties,
  propertiesSelectors,
} from 'modules/components/properties';
// Components
import MainTitle from 'components/MainTitle';
import FormSiteProperties from './components/FormSiteProperties';
// Service
import * as propertiesService from 'services/sites/properties';

class Properties extends React.Component {
  state = {
    formData: {},
  };

  async componentDidMount() {
    await this.props.fetchProperties();

    propertiesService.getOne().then(({ data }) => {
      this.setState({ formData: data });
    });
  }

  handleSubmit = (values) => propertiesService.update(values);

  render() {
    return (
      <>
        <MainTitle
          title="Imóveis da página inicial (home)"
          text="Defina como será o estilo da miniatura do imóvel na capa inicial do seu site."
        />
        <FormSiteProperties
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          components={this.props.components}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  components: propertiesSelectors.getAll(state),
});

const mapDispatchToProps = {
  fetchProperties,
};

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
