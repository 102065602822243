import React from 'react';
import { Wrapper } from './styles';
import ReservationItem from '../ReservationItem';

function ReservationList({
  person,
  reservations,
  handleUpdate,
  handleClose,
  handleRemove,
}) {
  return (
    <Wrapper>
      {reservations.map((reservation) => (
        <ReservationItem
          person={person}
          key={reservation.id}
          handleUpdate={handleUpdate}
          handleClose={handleClose}
          handleRemove={handleRemove}
          reservation={reservation}
        />
      ))}
    </Wrapper>
  );
}

export default ReservationList;
