import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { Select } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import { getBusiness } from 'services/settings/facebook';
// import { Wrapper } from './styles';

function FieldFacebookBusiness({ facebook }) {
  const { data, fetchData } = useRequest({
    request: getBusiness,
    initialFetch: false,
  });

  useEffect(() => {
    if (facebook.accessToken) {
      fetchData({
        user_id: facebook.userID,
        access_token: facebook.accessToken,
      });
    }
  }, [facebook]);

  return (
    <Field
      xs={7}
      required
      disabled={!data}
      placeholder={!data ? 'Nenhuma empresa encontrada' : 'Selecione'}
      name="business_id"
      label="Empresa"
      valueKey="id"
      labelKey="name"
      options={data}
      component={Select}
    />
  );
}

export default FieldFacebookBusiness;
