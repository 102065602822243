import React from 'react';
import { connect } from 'react-redux';
// Modules
import { handleAddWizard } from 'modules/login';
// Lib
import Store from 'lib/Store';

export const withWizard = key => Component => {
  class WithWizard extends React.Component {
    state = {
      isVisible: false
    };

    componentDidMount() {
      setTimeout(() => {
        this.setWizard();
      }, 500);
    }

    async setWizard() {
      // Chave atual
      const keyValue = await Store.get(`${key}-wizard`);

      if (!!keyValue) {
        this.setState({ isVisible: false });
        return null;
      }

      this.setState({ isVisible: true });
    }

    onClose = async () => {
      const { handleAddWizard } = this.props;
      handleAddWizard(`${key}-wizard`);
      this.setState({ isVisible: false });
    };

    render() {
      const { isVisible } = this.state;

      if (!isVisible) return null;

      return <Component {...this.props} onClose={this.onClose} />;
    }
  }

  const mapDispatchToProps = {
    handleAddWizard
  };

  return connect(null, mapDispatchToProps)(WithWizard);
};
