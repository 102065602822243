import React, { useCallback } from 'react';
import TablePosts from 'pages/Site/pages/ContentSite/pages/Posts/components/TablePosts';
import { useDispatch } from 'react-redux';
// Modules
import {
  fetchPosts,
  orderPost,
  removePost,
  toggleStatus,
} from 'pages/Site/pages/ContentSite/pages/Posts/module';
// HOC's
import { withPagination } from 'HOC/withPagination';
import { openConfirmation } from 'containers/ModalConfirmation/module';

function ContainerPosts({ posts, pagination }) {
  const dispatch = useDispatch();

  const handleChange = useCallback((items, post) => {
    // Índice atual do item
    const index = items?.findIndex(({ id }) => id === post.id) + 1;

    const currentPost = {
      ...post,
      order: index,
    };

    return dispatch(orderPost(currentPost));
  }, []);

  function onDelete(post) {
    return async function () {
      dispatch(
        openConfirmation({
          title: 'Deseja realmente remover a postagem?',
          text: 'Se você tem certeza clique em remover',
          request: () => {
            dispatch(removePost(post.id));
          },
        })
      );
    };
  }

  function onChangeStatus(post) {
    return function () {
      dispatch(toggleStatus(post));
    };
  }

  function onChangePage(page) {
    const params = {
      ...pagination,
      offset: page.selected + 1,
    };

    dispatch(fetchPosts(params));
  }

  return (
    <TablePosts
      data={posts}
      pagination={pagination}
      onChange={handleChange}
      onChangeStatus={onChangeStatus}
      onChangePage={onChangePage}
      onDelete={onDelete}
    />
  );
}

export default withPagination('posts', {
  limit: 1000,
})(ContainerPosts);
