// libs
import { createValidation } from 'lib/validate-helpers';

export default values => {
  const errors = {};
  const validations = createValidation(values, errors);

  validations.required(['link', 'title']);

  if (values.link && !values.link.includes('http')) {
    errors.link = 'Adicione http:// ou https:// no link.';
  }

  return errors;
};
