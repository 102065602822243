import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node
};
const defaultProps = {
  children: null
};

const Text = ({ children }) => <div className="ListInfo__text">{children}</div>;

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
