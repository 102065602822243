import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';

const propTypes = {
  handleClose: PropTypes.func.isRequired
};

const ModalClose = ({ color, handleClose }) => {
  if (!handleClose) return null;

  return (
    <button type="button" className="Modal__close" onClick={handleClose}>
      <Icon color={color} name="close-circle" />
    </button>
  );
};

ModalClose.propTypes = propTypes;

export default ModalClose;
