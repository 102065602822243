import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

export const CTooltip = styled.div`
  padding: 5px;
  background: #fff;
  border: 1px solid ${(p) => p.theme.borderColor};
`;

export const Right = styled(Col)`
  display: grid;
  place-items: center;
`;

export const Table = styled.table`
  width: 100%;
  cursor: default;

  td {
    padding: 5px;
  }
`;

export const Ball = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(p) => p.color};
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
`;
