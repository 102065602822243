import React from 'react';
import { MdOutlineReceipt } from 'react-icons/all';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
  margin-top: 110px;
`;

const Text = styled.div`
  width: 405px;
  margin: 20px auto 0;
  font-size: 16px;
  color: #5f6470;
`;

function Nodata() {
  return (
    <Wrapper>
      <div>
        <div>
          <MdOutlineReceipt size={60} color="#D3DCEB" />
        </div>
        <Text>
          Você ainda não possui um histórico. Após aplicar um modelo da galeria,
          você poderá acompanhar o histórico de modelos utilizados nesta página.
        </Text>
      </div>
    </Wrapper>
  );
}

export default Nodata;
