// Widgets.js
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import { formValueSelector } from 'redux-form';
import * as cepsService from 'services/ceps';
import { createModule } from '../lib/reducer-helpers';

import * as locationService from '../services/location';

const ACTION_SUFFIX = ['countries', 'states', 'cities', 'neighborhoods'];

const initialState = {
  data: [],
  isFetching: false
};

export const locationSelectorsCreate = module => ({
  ...ACTION_SUFFIX.reduce(
    (array, action) => ({
      ...array,
      [`${action}Selector`]: state => state[module].location[action].data,
      [`${action}IsFetchingSelector`]: state =>
        state[module].location[action].isFetching
    }),
    {}
  )
});

const locationKeySelector = (key, formName) => state => {
  try {
    return state.locations[formName][key];
  } catch {
    return { data: [], isFetching: false };
  }
};

const fieldIdSelector = (key = 'country_id', formName) => state => {
  const selector = formValueSelector(formName);
  return selector(state, key);
};

export const locationSelector = (key = 'country_id', formName) =>
  createSelector(
    [locationKeySelector(key, formName), fieldIdSelector(key, formName)],
    (data, id) => {
      try {
        return data.data.find(d => d.id === id);
      } catch {
        return {};
      }
    }
  );

/**
 * Cria todas as acões
 * @param module
 * @param actionSuffix
 * @return {{REQUEST, RECEIVE}}
 */
const createActions = (module, actionSuffix = '') => {
  const createActionModule = createModule(module);

  return {
    REQUEST: createActionModule(`REQUEST_${actionSuffix}`),
    RECEIVE: createActionModule(`RECEIVE_${actionSuffix}`),
    RESET: createActionModule(`RESET_${actionSuffix}`)
  };
};

export const reducerLocation = (module, actionSuffix) => (
  state = initialState,
  action
) => {
  const actions = createActions(module, actionSuffix);

  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.RECEIVE:
      return {
        ...state,
        data: action.payload,
        isFetching: false
      };
    case actions.RESET:
      return initialState;
    default:
      return state;
  }
};

export const actionsCreators = module => {
  let actionCreators = {};

  ACTION_SUFFIX.forEach(actionSuffix => {
    const actions = createActions(module, actionSuffix);
    actionCreators = {
      ...actionCreators,
      [actionSuffix]: {
        request: () => ({ type: actions.REQUEST }),
        receive: data => ({ type: actions.RECEIVE, payload: data }),
        reset: () => ({ type: actions.RESET })
      }
    };
  });

  return actionCreators;
};

export const thunksCreator = module => {
  const actions = actionsCreators(module);

  return {
    request: actions.request,
    receive: actions.receive,
    reset: actions.reset,
    getCountries: params => dispatch => {
      dispatch(actions.countries.request());
      return locationService.getCountries(params).then(({ data }) => {
        dispatch(actions.countries.receive(data));
        return data;
      });
    },
    getStates: params => dispatch => {
      dispatch(actions.states.request());
      return locationService.getStates(params).then(({ data }) => {
        dispatch(actions.states.receive(data));
        return data;
      });
    },
    getDefaultStates: params => dispatch => {
      dispatch(actions.states.request());
      return locationService.getDefaultStates(params).then(({ data }) => {
        dispatch(actions.states.receive(data));
        return data;
      });
    },
    getCities: params => dispatch => {
      dispatch(actions.cities.request());
      return locationService.getCities(params).then(({ data }) => {
        dispatch(actions.cities.receive(data));
        return data;
      });
    },
    getNeighborhoods: params => dispatch => {
      dispatch(actions.neighborhoods.request());
      return locationService.getNeighborhoods(params).then(({ data }) => {
        dispatch(actions.neighborhoods.receive(data));
        return data;
      });
    },
    getLocation: cep => () => {
      return cepsService.getLocation({ cep }).then(({ data }) => data);
    }
  };
};

export default module =>
  combineReducers({
    countries: reducerLocation(module, 'countries'),
    states: reducerLocation(module, 'states'),
    cities: reducerLocation(module, 'cities'),
    neighborhoods: reducerLocation(module, 'neighborhoods')
  });
