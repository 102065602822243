import React, { useCallback } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { move } from 'services/buildings/slides';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import CardPhoto from 'pages/EnterpriseStore/pages/Slides/components/CardPhoto';
import { Wrapper, WrapItem } from './styles';

const Item = SortableElement(({ data, name, handleRemoveImage }) => {
  return (
    <WrapItem>
      <CardPhoto
        data={data}
        name={name}
        handleRemoveImage={handleRemoveImage}
      />
    </WrapItem>
  );
});

const Images = SortableContainer(({ fields, handleRemoveImage }) => {
  return (
    <Wrapper>
      {fields.map((name, index, fields) => {
        const image = fields.get(index);
        return (
          <Item
            key={image.id}
            index={index}
            data={image}
            name={name}
            handleRemoveImage={handleRemoveImage(image.id)}
          />
        );
      })}
    </Wrapper>
  );
});

const Content = ({ fields, onSortEnd, handleRemoveImage }) => {
  const { enterpriseId } = useEnterprise();

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      try {
        fields.move(oldIndex, newIndex);
        const currentImage = fields.get(newIndex);
        move({
          buildingId: enterpriseId,
          imageId: currentImage.id,
          order: newIndex + 1
        }).then(() => {
          if (onSortEnd) onSortEnd();
        });
      } catch {
        return null;
      }
    },
    [enterpriseId]
  );

  return (
    <Images
      axis="xy"
      distance={1}
      fields={fields}
      handleRemoveImage={handleRemoveImage}
      onSortEnd={handleSortEnd}
    />
  );
};

export default Content;
