import React from 'react';
import { Field } from 'redux-form';
import { Select2 } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import * as condosService from 'services/condos';

function FieldMultiCondoCharacteristics(props) {
  const { data, isFetching } = useRequest({
    request: () => condosService.getCondosCharacteristics(),
  });

  return (
    <Field
      isLoading={isFetching}
      filter
      name="by_condos_characteristic_id"
      label="Requisito do condomínio"
      options={data}
      component={Select2}
      selectedItemsLabel="características"
      placeholder="Selecione"
      {...props}
    />
  );
}

export default FieldMultiCondoCharacteristics;
