import styled from 'styled-components';

export const Wrapper = styled.form`
  img {
    margin: 0 auto 20px;
  }
`;
export const Row = styled.div`
  display: flex;
`;

const Col = styled.div`
  width: 50%;
`;

export const ColLeft = styled(Col)`
  padding-right: 20px;
`;
export const ColRight = styled(Col)`
  border-left: 1px solid #dedede;
  padding-left: 19px;
`;

export const Ball = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 16px;
  background: ${p => p.color};
  color: #ffffff;
  text-align: center;
`;

export const Content = styled.div`
  margin-top: 15px;

  .Button {
    margin-bottom: 10px;
  }
`;

export const Text = styled.div`
  line-height: 22px;
`;
export const File = styled.div`
  margin-top: 10px;
  color: ${p => p.theme.colors.secondary};

  svg {
    margin-right: 5px;
  }
`;
