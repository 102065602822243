import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const LeadsGenerated = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  flex-direction: column;
  background-color: #f9f9f9;
  border-left: 5px solid #0063c0;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1);
`;

export const LeadsContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LeadsTitle = styled.h2`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #0063c0;
`;

export const LeadsNumber = styled.span`
  font-size: 122px;
  color: #0056b3;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2;
  margin-left: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
`;

export const TableHeader = styled.th`
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px 15px;
  background: #f5f5f6;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1c0c1e;
`;

export const TableNodata = styled.td`
  padding: 20px;
  text-align: center;
`;

export const TableData = styled.td`
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;

  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  cursor: pointer;
`;

export const SeeMore = styled(Link)`
  padding: 10px;
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0084f4;
  text-decoration-line: underline;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  &:hover {
    text-decoration: underline;
  }
`;
