import React from 'react';
// Component
import MyTable from '../../../../../../components/Table';
import TableItem from '../TableItem';
import Check from 'components/Check';

const Table = ({ isAllSelected, toggleAll, data, meta, handleToggleItem }) => (
  <MyTable
    responsive
    data={data}
    isFetching={false}
    renderTitle={() => (
      <tr>
        <th className="h-sticky--left">
          <Check
            checked={isAllSelected}
            className="h-margin-right--15"
            onClick={toggleAll}
          />
          Canal
        </th>
        {meta?.columns?.map((period, index) => (
          <th key={`period-${index}`}>{period}</th>
        ))}
        <th className="h-sticky--right">Total</th>
      </tr>
    )}
    renderItem={data => (
      <TableItem
        key={`item-${data.period}`}
        data={data}
        handleToggleItem={handleToggleItem(data)}
      />
    )}
    renderFooter={() => (
      <tr>
        <td className="h-sticky--left">Total</td>
        {meta?.totals?.map(({ period, total }, index) => (
          <td key={`totals-${index}`}>{total}</td>
        ))}
        <td className="h-sticky--right">{meta.total}</td>
      </tr>
    )}
  />
);

export default Table;
