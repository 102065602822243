import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import pages from './pages';
import { withPermission } from 'HOC/withPermission';

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/style/apparence`} component={pages.Apparence} />
      <Route path={`${path}/style/logo`} component={pages.Logo} />
      <Route path={`${path}/style/header/:device`} component={pages.Header} />
      <Route path={`${path}/style/menu`} component={pages.Menu} />
      <Route path={`${path}/style/search/:device`} component={pages.Search} />
      <Route
        path={`${path}/style/slides/create`}
        exact
        component={pages.SlideForm}
      />
      <Route
        exact
        path={`${path}/style/slides/property/:property_id`}
        render={({ match }) => (
          <Redirect
            to={`/site/content-site/slides/property/${match.params.property_id}`}
          />
        )}
      />
      <Route
        exact
        path={`${path}/style/slide/update/:slide_id`}
        component={pages.SlideForm}
      />
      <Route
        exact
        path={`${path}/style/properties`}
        component={pages.Properties}
      />
      <Route path={`${path}/style/banner/:device`} component={pages.Banner} />
      <Route path={`${path}/style/footer`} component={pages.Footer} />
      <Route path={`${path}/style/about`} component={pages.About} />
      <Route path={`${path}/style/finance`} component={pages.Finance} />
      <Route path={`${path}/style/watermark`} component={pages.Watermark} />
      <Route
        path={`${path}/style/logo-options/:type`}
        component={pages.LogoOptions}
      />
      <Route
        path={`${path}/style/script/:navigation_id?`}
        component={pages.ScriptForm}
      />
      <Route
        path={`${path}/style/property_detail`}
        component={pages.PropertyDetail}
      />
      <Redirect to={`${path}/style/apparence`} />
      <Redirect
        from={`${path}/property_detail/component`}
        to={`${path}/style/property_detail`}
      />
    </Switch>
  );
};

export default withPermission({
  rules: ['EDIT_SITE_APPEARANCE'],
  isPage: true,
})(Routes);
