import styled from 'styled-components';

export const Box = styled.div`
  display: block;
  padding: 15px;
  text-align: center;
  border: 1px solid #dedede;
  margin-bottom: 15px;
  border-radius: 4px;
`;
