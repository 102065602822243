import React, { useEffect } from 'react';
import Box from 'pages/Crm/components/Box';
import { usePeriod } from 'pages/Crm/pages/Dashboard/hooks/usePeriod';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useRequest } from 'hooks/useRequest';
import api from 'services';
import { selector } from 'pages/Crm/components/FormUser';
import Placeholder from './Placeholder';
import Loading from 'components/Loading';
import { useFormFunnelId } from 'pages/Crm/pages/People';
import { useSelector } from 'react-redux';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          border: '1px solid #999',
          padding: '10px',
        }}
      >
        <p>{`Quantidade: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

function getYAxisWidth(data) {
  const greaterWord = data.reduce(
    (maior, item) => (item.reason.length > maior.length ? item.reason : maior),
    ''
  );

  return greaterWord.length * 5 + 40;
}

function ReasonsOfLoss() {
  const date = usePeriod();
  const userId = useSelector((state) => selector(state, 'user_id')?.user_id);
  const funnelId = useFormFunnelId();

  const { data, isFetching, fetchData } = useRequest({
    request: ({ start_date, end_date, user_id, funnel_id }) => {
      return api.getList('crm/dashboard/loss-reasons', {
        user_id,
        funnel_id,
        start_date,
        end_date,
      });
    },
    initialFetch: false,
  });

  const yAxisWidth = getYAxisWidth(data);

  useEffect(() => {
    if ((date.start_date || date.end_date) && funnelId) {
      fetchData({
        user_id: userId,
        funnel_id: funnelId,
        start_date: date.start_date,
        end_date: date.end_date,
      });
    }
  }, [date, userId, funnelId]);

  return (
    <Box title="Principais motivos de perda" text="Contabilizado por motivo">
      <Loading isVisible={isFetching} isFullComponent />
      {data && data.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={data}
            layout="vertical"
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid horizontal={false} />
            <XAxis type="number" />
            <YAxis
              width={yAxisWidth}
              dataKey="reason"
              type="category"
              allowDataOverflow
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="lost_deals_count" fill="#F84343" barSize={24} />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Placeholder />
      )}
    </Box>
  );
}

export default ReasonsOfLoss;
