import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// Components
import Button from 'components/Button';
import NoContent from 'components/NoContent';
import PopupPage from './components/PopupPage';
// Services
import { fetchPopup, handleSubmitPopup } from './module';
// HOC
import withReception from 'HOC/withReception';
// Assets
import popup from './popup.png';

class Properties extends React.Component {
  state = {
    image: null
  };

  componentDidMount() {
    // Busca os popups
    this.props.fetchPopup();
  }

  handleUploadPopUp = image => this.setState({ image: image[0] });

  render() {
    const { image } = this.state;
    const {
      reception,
      handleClickAction,
      data,
      handleSubmitPopup
    } = this.props;

    if (reception.isVisible) {
      return (
        <NoContent
          full
          reversed
          image={popup}
          title="Pop-up"
          text={
            <>
              Adicione uma pop-up em seu site
              <br /> para divulgar promoções, avisos, ou qualquer informação que
              desejar.
            </>
          }
        >
          <Button color="secondary" size="large" onClick={handleClickAction}>
            Entendi
          </Button>
        </NoContent>
      );
    }

    return (
      <PopupPage
        formData={{ active: true, image, ...data }}
        handleSubmit={handleSubmitPopup}
        handleUploadPopUp={this.handleUploadPopUp}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.site.popup.data,
  isFetching: state.site.popup.isFetching
});

const mapDispatchToProps = {
  fetchPopup,
  handleSubmitPopup
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withReception('site/popup', true)
)(Properties);
