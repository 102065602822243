// Modules
import dataReducer from 'modules/data';
// Helpers
import { filterOrAll } from '../helpers';
// Services
import { getBillingPerBroker } from 'services/reports';

export const {
  reducer: billingPerBrokerReducer,
  actionCreators: billingPerBrokerActions,
  selectors: billingPerBrokerSelectors
} = dataReducer(`reports/billingPerBroker`);

export const chartDataSelector = filterOrAll(billingPerBrokerSelectors);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(billingPerBrokerActions.request());

  getBillingPerBroker(params).then(res => {
    dispatch(billingPerBrokerActions.receive(res.data));

    return res.data;
  });
};

export const toggleChecked = item => dispatch => {
  dispatch(billingPerBrokerActions.toggleSelected(item.id));
};

export default billingPerBrokerReducer;
