import { createModule } from '../lib/reducer-helpers';
import { API_BASE_URL, HTTP } from '../lib/HTTP';

const createAction = createModule('personStories');

/**
 * Notes
 */
const REQUEST_STORIES = createAction('REQUEST');
const RECEIVE_STORIES = createAction('RECEIVE');

const RESET_STORIES = createAction('RESET_STORIES');

// Initial State
const initialState = {
  stories: [],
  meta: {
    isFetching: false,
    showForm: true
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_STORIES:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE_STORIES:
      return {
        ...state,
        stories: action.stories,
        meta: { ...state.meta, isFetching: false }
      };
    case RESET_STORIES:
      return initialState;
    default:
      return state;
  }
}

/**
 * Action Creators
 */
export function requestStories() {
  return { type: REQUEST_STORIES };
}

export function receiveStories(stories) {
  return { type: RECEIVE_STORIES, stories };
}

export function resetStories() {
  return { type: RESET_STORIES };
}

/**
 * Stories
 */
export function getStories() {
  return dispatch => {
    dispatch(requestStories());

    return HTTP.get(`${API_BASE_URL}stories.json`).then(({ data }) =>
      dispatch(receiveStories(data))
    );
  };
}

export function clearStories() {
  return dispatch => {
    dispatch(resetStories());
  };
}
