import React from 'react';
// Components
import { FieldBool } from 'components/Form';

const TransactionField = ({ canRemoveSelf, onChange }) => (
  <FieldBool
    canRemoveSelf={canRemoveSelf}
    label="Transação"
    name="transaction"
    onChange={onChange}
    options={[
      { label: 'Venda', value: 1 },
      { label: 'Aluguel', value: 2 },
    ]}
  />
);

export default TransactionField;
