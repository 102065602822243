import React from 'react';
import Select, { Async, AsyncCreatable, Creatable } from 'react-select-plus';
import removeAccents from 'remove-accents';
import Alert from 'react-s-alert';
import { MdAddCircle } from 'react-icons/md';
// Components
import menuRenderer from './menuRenderer';
import FormGroup from '../FormGroup';
// Constants

const defaultProps = {
  disabled: false,
  multi: true,
  creatable: false,
  label: null,
  required: false,
  placeholder: 'Selecione',
  options: [],
  searchPromptText: 'Digite para pesquisar',
  noResultsText: 'Sem resultados',
  addText: 'Adicionar',
  children: null,
  isLoading: false,
};

const propTypes = {};

class SelectMultiple extends React.Component {
  state = {
    searching: '',
  };

  onChange = (e) => {
    if (this.props.handleChange)
      return this.props.handleChange({
        input: this.props.input,
        event: e,
      });

    this.props.input.onChange(e);
  };

  newOption = ({ label, labelKey, valueKey }) => {
    return { [valueKey]: label, [labelKey]: label };
  };

  isOptionUnique = ({ options, option }) => {
    const { input, labelKey } = this.props;
    return ![...options, input.value].some(($input) => {
      const inputLabel = $input[`${labelKey}`];
      const optionLabel = option[`${labelKey}`];

      if (inputLabel === undefined || optionLabel === undefined) return false;
      if (inputLabel.type === 'div') return false;
      return (
        removeAccents(inputLabel.toLowerCase()) ===
        removeAccents(optionLabel.toLowerCase())
      );
    });
  };

  onInputChange = (input) => {
    this.setState({ searching: input });
    if (this.props.onInputChange) this.props.onInputChange(input);
  };

  handleClickNewOptionEmpty = () => {
    Alert.success('Para inserir basta digitar no campo');
  };

  handleNewOption = () => {
    const { searching: label } = this.state;
    const { labelKey, valueKey } = this.props;

    // Cria uma nova opção
    const option = this.newOption({
      label,
      labelKey,
      valueKey,
    });

    // this.input.select.closeMenu();
    this.props.onNewOptionClick(option);
    return option;
  };

  promptTextCreator = (labelText, onClick) => (
    <div className="ReactSelect__option" onClick={onClick}>
      {this.state.searching}{' '}
      <strong className="h-color--secondary">
        <MdAddCircle /> {this.props.addText}
      </strong>
    </div>
  );

  creatableOptions = () => {
    const { creatable } = this.props;
    if (creatable) {
      return {
        noResultsText: this.promptTextCreator(
          this.state.searching,
          this.handleNewOption
        ),
        menuRenderer: menuRenderer({
          promptTextCreator: this.promptTextCreator,
          onNewOptionClick: this.handleNewOption,
        }),
      };
    }

    return {};
  };

  renderComponent = () => {
    const { loadOptions, creatable } = this.props;

    if (loadOptions) {
      return creatable ? AsyncCreatable : Async;
    }

    return creatable ? Creatable : Select;
  };

  render() {
    const {
      xs,
      sm,
      md,
      lg,
      required,
      input,
      label,
      disabled,
      formGroupClassName,
      meta,
      loadOptions,
      isLoading,
      hidden,
      isPrivate,
      styleFormGroup,
      ...rest
    } = this.props;

    const SelectComponent = this.renderComponent();

    return (
      <FormGroup
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        labelText={label}
        className={formGroupClassName}
        isRequired={required}
        isDisabled={disabled}
        input={input}
        meta={meta}
        hidden={hidden}
        isLoading={isLoading}
        isPrivate={isPrivate}
        style={styleFormGroup}
      >
        <SelectComponent
          {...rest}
          {...this.creatableOptions()}
          onBlur={() => {
            input.onBlur(undefined);
          }}
          disabled={disabled}
          value={input.value}
          onChange={this.onChange}
          newOptionCreator={this.newOption}
          isOptionUnique={this.isOptionUnique}
          loadOptions={loadOptions}
          onNewOptionClick={this.handleNewOption}
          onInputChange={this.onInputChange}
          isLoading={isLoading}
          promptTextCreator={this.promptTextCreator}
        />
      </FormGroup>
    );
  }
}

SelectMultiple.defaultProps = defaultProps;
SelectMultiple.propTypes = propTypes;

export default SelectMultiple;
