import styled from 'styled-components';
import { darken, readableColor } from 'polished';

export const Stage = styled.div`
  display: inline-block;
  background-color: ${p => p.color} !important;
  color: ${p => readableColor(darken(0.2, p.color || '#fff'))};
  padding: 25px 15px;
  margin: -10px -20px -10px;
  white-space: nowrap;

  svg {
    font-size: 18px;
    vertical-align: middle;
  }
`;
