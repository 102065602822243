import styled from 'styled-components';
import { readableColor } from 'polished';
import SearchBar from 'components/SearchBar';
import { Input } from 'components/SearchBar/styles';

export const Search = styled(SearchBar)`
  margin-right: 15px;

  ${Input} {
    min-width: 525px;
    height: 31px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Btn = styled.button`
  padding: 0 15px;
  position: relative;
  line-height: 55px;
  cursor: pointer;
  color: #fff;

  svg {
    font-size: 20px;
    color: ${p => readableColor(p?.theme?.bgHeader)};
  }
`;
