import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
// Services
import * as headersService from 'services/sites/headers';
import * as bannerService from 'services/sites/banner';
// Components
import FormBanner from '../../components/FormBanner';
import Loading from 'components/Loading';
// Modules
import { deleteHeaderImage, fetchMainImages, headerImagesSelectors } from 'modules/components/headerImages';
import { headersSelectors } from 'modules/components/headers';
// Helpers
import { responseMessage } from 'lib/service-helpers';

class FormDevice extends React.Component {
  static propTypes = {
    form: PropTypes.string,
    device: PropTypes.oneOf(['mobile', 'desktop'])
  };

  static defaultProps = {
    form: 'FormBannerDesktop',
    device: 'desktop'
  };

  state = {
    initialValues: {},
    isFetching: true
  };

  /**
   * Retorna o tipo das imagens do banner
   * @return {String} type
   */
  get type() {
    const { device } = this.props;

    if (device === 'desktop') {
      return 2;
    }

    return 4;
  }

  fetchImagesByDevice = () =>
    this.props.fetchMainImages({
      filter: { type: this.type }
    });

  async componentDidMount() {
    // Busca as imagens do header
    await this.fetchImagesByDevice();

    // Busca os dados do formulário
    bannerService
      .getOne({
        device: this.props.device
      })
      .then(({ data }) => {
        this.setState({
          initialValues: data,
          isFetching: false
        });
      });
  }

  handleSubmitSuccess = (result, dispatch) => {
    // Reinicializa o formulário com os valores
    dispatch(
      initialize(
        this.props.form,
        {
          ...result.data,
          can_show_banner: !!result.data.footer_banner_id,
          device: this.props.device,
          image: null
        },
        false
      )
    );

    this.setState(result.data);
  };

  handleSubmit = values => {
    if (values.image) {
      return headersService
        .createImage({ image: values.image, type: this.type })
        .then(responseMessage('Imagem adicionada'))
        .then(() => {
          // Busca as imagens do header
          this.fetchImagesByDevice();

          return { data: values };
        });
    }

    return bannerService.update({
      ...values,
      device: this.props.device
    });
  };

  handleRemoveImage = id => e => {
    e.stopPropagation();
    this.props.deleteHeaderImage(id);
  };

  /**
   * Pega os valores iniciais do formulário
   * @returns {{device: string}}
   */
  get initialValues() {
    const { initialValues } = this.state;
    const { device } = this.props;

    return {
      footer_banner_open_on_page: true,
      ...initialValues,
      can_show_banner: !!initialValues.footer_banner_id,
      device // Adiciona o device nos valores iniciais
    };
  }

  render() {
    const { form, headers, images } = this.props;
    const { isFetching } = this.state;

    if (isFetching) return <Loading isVisible />;

    return (
      <FormBanner
        form={form}
        onSubmit={this.handleSubmit}
        onSubmitSuccess={this.handleSubmitSuccess}
        initialValues={this.initialValues}
        headers={headers}
        images={images}
        handleRemoveImage={this.handleRemoveImage}
      />
    );
  }
}

const mapStateToProps = state => ({
  headers: headersSelectors.getAll(state),
  images: headerImagesSelectors.getAll(state)
});

const mapDispatchToProps = {
  fetchMainImages,
  deleteHeaderImage
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDevice);
