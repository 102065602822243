import React from 'react';
// Components
import MainTitle from 'components/MainTitle';
// Components
import GlobalForm from './components/GlobalForm';
// Service
import * as globalConfigService from 'services/sites/general';

class GlobalConfig extends React.Component {
  state = {
    formValues: {}
  };

  componentDidMount() {
    globalConfigService.getOne().then(({ data }) => {
      this.setState({ formValues: data });
      return data;
    });
  }

  handleSubmit = values => globalConfigService.update(values);

  render() {
    return (
      <>
        <MainTitle
          title="Configurações Gerais"
          text="Configure seu site da maneira que desejar para atender as necessidades do seu cliente."
        />
        <GlobalForm
          onSubmit={this.handleSubmit}
          initialValues={this.state.formValues}
        />
      </>
    );
  }
}

export default GlobalConfig;
