import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 25px 0 0;

  img {
    margin: 0 auto 15px;
  }

  h5,
  p {
    margin-bottom: 10px;
  }

  p {
    line-height: 20px;
  }
`;
