import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PropertyItem from 'components/PropertyItem';
import { HasMore, Wrapper } from './styles';

function PropertiesList({
  title,
  icon,
  color,
  renderNoData,
  key,

  request,
  params,
  limit,
  updatedTime,
  dataExtractor,
  renderAction,
  afterClose,
  renderText,
  isDanger,
  hideActions,
}) {
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const [offset, setOffset] = useState(1);

  const fetchData = useCallback(
    async (p) => {
      let _params = { ...params, ...p };

      // Verifica se tem limite
      // se tiver adiciona o offset junto
      if (limit) {
        _params = { ..._params, limit, offset: p?.offset || offset };
      }

      try {
        const { data, meta } = await request(_params);
        setData((prevData) => [...prevData, ...data]);

        if (meta?.pagination) {
          const { current_page, total_pages } = meta.pagination;
          const hasMore = current_page < total_pages;
          setHasMore(hasMore);
        }
      } catch (err) {
        throw err;
      }
    },
    [limit, offset, setData, setHasMore]
  );

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + 1);
  }, [setOffset]);

  useEffect(() => {
    setData([]);
    fetchData({ offset: 1 });
  }, [updatedTime, setData, setOffset]);

  useEffect(() => {
    if (offset > 1) {
      fetchData();
    }
  }, [offset]);

  return (
    <Panel
      renderNoData={renderNoData}
      title={title}
      icon={icon}
      hasData={!!data.length}
      color={color}
    >
      <Wrapper>
        {data?.map((item) => {
          const property = dataExtractor(item);

          return (
            <PropertyItem
              isDanger={isDanger?.(item)}
              key={key + property?.id}
              property={property}
              item={item}
              renderAction={renderAction}
              afterClose={afterClose}
              hideActions={hideActions}
            >
              {renderText?.(item)}
            </PropertyItem>
          );
        })}
        {hasMore && <HasMore onClick={loadMore}>Ver mais</HasMore>}
      </Wrapper>
    </Panel>
  );
}

PropertiesList.defaultProps = {
  request: null,
  params: {},
  canShowMore: false,
  renderAction: null,
  renderText: null,
  dataExtractor: (property) => property,
};

PropertiesList.propTypes = {
  request: PropTypes.func.isRequired,
  params: PropTypes.object,
  canShowMore: PropTypes.bool,
  renderAction: PropTypes.func,
  renderText: PropTypes.func,
  dataExtractor: PropTypes.func.isRequired,
};

export default memo(PropertiesList);
