import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega os dados de site relacionado
 * @param param
 * @return {*|{params}}
 */
export const getOne = (param) => api.getOne('settings/sites/whatsapp', param);

/**
 * Atualiza os dados de whatsapp
 * @param values
 * @return {*}
 */
export const update = (values) =>
  api.create('settings/sites/whatsapp', values).then(responseMessage('Salvo!'));
