import React from 'react';
import PropTypes from 'prop-types';

// import from './styles';

function SelectOption({
  className,
  title,
  isFocused,
  onFocus,
  onSelect,
  option,
  children
}) {
  function handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    onSelect(option, event);
  }

  function handleMouseEnter(event) {
    onFocus(option, event);
  }

  function handleMouseMove(event) {
    if (isFocused) return;
    onFocus(option, event);
  }

  return (
    <div
      className={className}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      title={title}
    >
      {children}
    </div>
  );
}

SelectOption.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  option: PropTypes.object.isRequired
};
SelectOption.defaultProps = {
  children: null,
  className: null,
  isDisabled: null,
  isFocused: null,
  isSelected: null,
  onFocus: null,
  onSelect: null,
  option: []
};

export default SelectOption;
