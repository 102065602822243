import React from 'react';
import TableSort, { CollumHeader } from 'components/TableSort';
import useCheckbox from 'hooks/useCheckbox';
import Check from 'components/Check';
import { MdCheckCircle, MdDelete, MdEmail } from 'react-icons/md';
import { BarItemSelecteds } from 'pages/Properties/components/FixedBarProperties/styles';
import { plural } from 'lib/text';
import FixedBar from 'components/FixedBar';
import { openModalPerson, openModalSendPeopleEmail } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import * as peopleService from 'services/people';

// import { Wrapper } from './styles';

function TablePeople({ userId, data, meta, fetchData }) {
  const dispatch = useDispatch();

  const {
    checkedItems,
    isChecked,
    isAllItemsChecked,
    handleToggle,
    handleToggleAll,
    clear,
  } = useCheckbox([]);

  const handleDiscard = (person) => {
    dispatch(
      openConfirmation({
        title: `Remover da lista: ${person.name}`,
        text: `Se voce deseja descartar ${person.name} clique em Descartar`,
        submitButtonText: 'Descartar',
        request: () => {
          return peopleService.discardMatchedsById([person.id], userId);
        },
        onSuccess: () => {
          fetchData({ offset: 1 });
          clear();
        },
      })
    );
  };

  const handleDiscartAllSelecteds = () => {
    dispatch(
      openConfirmation({
        title: `Remover da lista ${checkedItems.length} clientes`,
        text: 'Se voce deseja descartar todos os clientes clique em Descartar',
        submitButtonText: 'Descartar',
        request: () => {
          return peopleService.discardMatchedsById(checkedItems, userId);
        },
        onSuccess: () => {
          fetchData({ offset: 1 });
          clear();
        },
      })
    );
  };

  const handleClick = (person, params) => () => {
    dispatch(openModalPerson(person, params));
  };

  return (
    <>
      <TableSort
        data={data}
        meta={meta}
        fetchData={fetchData}
        renderHeader={() => (
          <tr>
            <th className="Table__cell--small">
              <Check
                checked={isAllItemsChecked(data)}
                onClick={() => handleToggleAll(data)}
              />
            </th>
            <CollumHeader width={250} align="left" filterBy="by_name">
              Cliente
            </CollumHeader>
            <CollumHeader
              width={100}
              className="Table__cell--small"
              align="center"
              filterBy="interacted_at"
            >
              Última interação
            </CollumHeader>
            <th width={100} align="center">
              Imóveis no Funil
            </th>
            <th width={100} align="center">
              No radar
            </th>
            <th width={100} align="center">
              Enviados
            </th>
            <th width={100} align="center">
              Descartados
            </th>
            <th className="Table__cell--small" />
          </tr>
        )}
        renderItem={(data) => (
          <tr>
            <td
              className="h-pointer Table__cell--small"
              onClick={() => handleToggle(data.id)}
            >
              <Check checked={isChecked(data.id)} />
            </td>
            <td
              align="left"
              className="h-pointer"
              onClick={handleClick(data, {
                initialPage: 'Properties',
                pageProperties: 'PropertiesNewMatched',
              })}
            >
              {data?.name}
            </td>
            <td
              align="center"
              className="h-pointer"
              onClick={handleClick(data)}
            >
              {data?.interacted_at}
            </td>
            <td
              align="center"
              className="h-pointer"
              onClick={handleClick(data, {
                initialPage: 'Properties',
              })}
            >
              <span className="h-link">{data?.deals}</span>
            </td>
            <td
              align="center"
              className="h-pointer"
              onClick={handleClick(data, {
                initialPage: 'Properties',
                pageProperties: 'PropertiesNewMatched',
              })}
            >
              <span className="h-link">{data?.matcheds_new}</span>
            </td>
            <td
              align="center"
              className="h-pointer"
              onClick={handleClick(data, {
                initialPage: 'Properties',
                pageProperties: 'PropertiesSended',
              })}
            >
              <span className="h-link">{data?.matcheds_sent}</span>
            </td>
            <td
              align="center"
              className="h-pointer"
              onClick={handleClick(data, {
                initialPage: 'Properties',
                pageProperties: 'PropertiesDiscarted',
              })}
            >
              <span className="h-link">{data?.matcheds_discarted}</span>
            </td>
            <td className="Table__cell--small">
              <button
                onClick={() => {
                  handleDiscard(data);
                }}
              >
                <MdDelete />
              </button>
            </td>
          </tr>
        )}
      />
      <FixedBar
        color="primary"
        noContainer
        isVisible={!!checkedItems.length > 0}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        style={{
          background: 'rgb(66, 88, 126)',
          zIndex: 1500,
        }}
      >
        <BarItemSelecteds>
          <span>
            <MdCheckCircle />{' '}
            {plural(
              checkedItems.length,
              'cliente selecionado',
              'clientes selecionados'
            )}
          </span>
        </BarItemSelecteds>
        <FixedBar.item>
          <FixedBar.link
            tagName="button"
            onClick={() => {
              dispatch(
                openModalSendPeopleEmail({
                  initialValues: {
                    people_id: checkedItems,
                  },
                  userId,
                  afterClose: () => {
                    fetchData({ offset: 1, sort: 'by_name' });
                    clear();
                  },
                })
              );
            }}
          >
            <MdEmail /> Enviar por e-mail e remover da lista
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.item>
          <FixedBar.link tagName="button" onClick={handleDiscartAllSelecteds}>
            <MdDelete /> Remover da lista
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.spacer />
      </FixedBar>
    </>
  );
}

export default TablePeople;
