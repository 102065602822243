import React, { useCallback, useEffect, useState } from 'react';
// Components
import Wrapper from 'components/Wrapper';
import { useRequest } from 'hooks/useRequest';
import * as BackupService from 'services/settings/backup';
import CardExport from './components/CardExport';
import BackupSocket from 'lib/Socket/Backup';

function Backup() {
  const [isSubmitting, setSubmission] = useState(false);

  const {
    data: backups,
    isFetching,
    fetchData,
  } = useRequest({
    request: () => BackupService.getBackups(),
  });

  const handleGenerateBackup = useCallback(async () => {
    setSubmission(true);
    await BackupService.generateBackup();
    fetchData();
  }, []);

  const onFinally = useCallback(() => {
    setSubmission(false);
    fetchData();
  }, []);

  useEffect(() => {
    const backup = new BackupSocket();

    backup.onBackupFinished(onFinally);
    backup.onBackupFailed(onFinally);

    return () => {
      backup.disconnect();
    };
  }, []);

  return (
    <Wrapper.container>
      <CardExport
        data={backups}
        isSubmitting={isSubmitting}
        onGenerateBackup={handleGenerateBackup}
      />
    </Wrapper.container>
  );
}

export default Backup;
