import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BtnLink, Content, Header, HeaderTitle, Wrapper } from './styles';
import MainFields from '../MainFields';
import AdvancedSearch from '../AdvancedSearch';

// Modules
import {
  isShowAdvancedSearchSelector,
  toggleAdvancedSearch,
} from 'modules/modalPropertySearch';
import { useModalPropertySearch } from 'containers/ModalRoot/ModalPropertySearch/context';

function MainFilter() {
  const { handleUpdateCount } = useModalPropertySearch();

  const isShowAdvancedSearch = useSelector(isShowAdvancedSearchSelector);
  const dispatch = useDispatch();

  const handleToggleSearchFields = () => {
    dispatch(toggleAdvancedSearch());
  };

  useEffect(() => {
    handleUpdateCount();
  }, []);

  return (
    <Wrapper>
      <Header>
        <HeaderTitle>Pesquisa de imóvel</HeaderTitle>
        <BtnLink type="button" onClick={handleToggleSearchFields}>
          {isShowAdvancedSearch
            ? 'Esconder campos de pesquisa'
            : 'Mais opções de pesquisa'}
        </BtnLink>
      </Header>
      <Content>
        <MainFields />
        {isShowAdvancedSearch && <AdvancedSearch />}
      </Content>
    </Wrapper>
  );
}

export default MainFilter;
