import React, { useEffect } from 'react';
import Layout from 'pages/Crm/components/Layout';
import TablePeople from './components/TablePeople';
import { Content } from './styles';
import { usePeople } from 'pages/Crm/hooks/usePeople';
import { useFormUserId } from '../../components/FormUser';
import Loading from 'components/Loading';
import { plural } from 'lib/text';
import { useSelector } from 'react-redux';
import Filter from './components/Filter';
import { formValueSelector } from 'redux-form';
import { useCount } from './hooks/useCount';

export const selector = formValueSelector('FilterPeople');

export const useFormFunnelId = () => {
  return useSelector((state) => selector(state, 'funnel_id'));
};

function People() {
  const { countPeople, countProperties } = useCount();
  const userId = useFormUserId();
  const funnelId = useFormFunnelId();

  const { data, isFetching, meta, fetchData } = usePeople({
    filter: {
      by_user_id: userId,
      by_funnel_id: funnelId,
      having_staged_deals: true,
    },
  });

  const handleFetchData = ({ offset = 1, sort = 'by_name' } = {}) => {
    fetchData({
      filter: {
        by_user_id: userId,
        by_funnel_id: funnelId,
        having_staged_deals: true,
      },
      offset,
      sort,
    });
  };

  useEffect(() => {
    if (userId && funnelId) {
      handleFetchData();
    }
  }, [userId, funnelId]);

  return (
    <Layout
      title={
        <>
          <strong>Negócios em andamento</strong> |{' '}
          <strong>Lista de clientes</strong> |{' '}
          {plural(countProperties.count, 'imóvel', 'imóveis')} |{' '}
          {plural(countPeople.count, 'cliente', 'clientes')}
        </>
      }
      renderActions={() => <Filter />}
    >
      <Content>
        <TablePeople data={data} meta={meta} fetchData={handleFetchData} />
        <Loading isVisible={isFetching} isFullScreen />
      </Content>
    </Layout>
  );
}

export default People;
