import React, { memo } from 'react';
import { formValueSelector, reduxForm } from 'redux-form';
import { DEFINED_RANGES } from 'components/Form/components/FieldDateRange';
import { CustomFieldDateRange } from 'pages/Crm/pages/History/components/Filter/styles';
import { Actions, Form, Title } from './styles';
import moment, { format } from 'lib/moment';

export const selector = formValueSelector('FormPeriod2');

function FormPeriod({ handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit}>
      <Title>Análise de resultado por período</Title>
      <Actions>
        <CustomFieldDateRange
          isInline
          canClear={false}
          startDateName="start_date"
          endDateName="end_date"
          staticRanges={[
            DEFINED_RANGES.CURRENT_MONTH,
            DEFINED_RANGES.LAST_MONTH,
            DEFINED_RANGES.LAST_30_DAYS,
            DEFINED_RANGES.LAST_60_DAYS,
            DEFINED_RANGES.LAST_90_DAYS,
            DEFINED_RANGES.CURRENT_YEAR,
            DEFINED_RANGES.PAST_YEAR,
          ]}
        />
      </Actions>
    </Form>
  );
}

export default reduxForm({
  form: 'FormPeriod2',
  enableReinitialize: true,
  initialValues: {
    start_date: moment().startOf('year').format(format.date),
    end_date: moment().format(format.date),
  },
})(memo(FormPeriod));
