import React from 'react';
import PropTypes from 'prop-types';
import { Legend } from '../../../styles';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.number
  })
};

const defaultProps = {
  data: {}
};

const PropertyItem = ({
  data: { type, venda, locacao, temporada, total, color }
}) => (
  <tr className="h-hover">
    <td className="h-sticky--left h-text-nowrap h-flex h-flex--center-center">
      <Legend color={color} />
      {type}
    </td>
    <td>{venda}</td>
    <td>{locacao}</td>
    <td>{temporada}</td>
    <td>{total}</td>
  </tr>
);

PropertyItem.propTypes = propTypes;
PropertyItem.defaultProps = defaultProps;

export default PropertyItem;
