import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
// Lib
import { addCurrency } from 'lib/money-helpers';

const BillingChart = ({ width, height, data, colors, toggleChecked }) => (
  <div style={{ width, height }}>
    <ResponsiveContainer>
      <BarChart
        width={600}
        height={300}
        data={data}
        margin={{ left: 25, right: 25 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="period" allowDuplicatedCategory={false} />
        <YAxis dataKey="value" />
        <Tooltip
          cursor={{ fill: '#f0f2f5' }}
          formatter={value => addCurrency(value)}
        />
        {data.map((dat, index) => (
          <Bar
            dataKey="value"
            data={dat.data}
            name={dat.broker}
            key={`${dat.broker}-${index}-per-broker`}
            fill={dat.color}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default BillingChart;
