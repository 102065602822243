import { Wrapper } from './styles';

function InfosCell({ invite }) {
  return (
    <Wrapper>
      <div>
        <p>{invite.fromUser.name}</p>
      </div>
      <div>
        <p>{invite.group.title}</p>
      </div>
    </Wrapper>
  );
}

export default InfosCell;
