import React from 'react';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { openModalProperty, openModalShare } from 'modules/modal';

// import { Wrapper } from './styles';

function Action({ data }) {
  const dispatch = useDispatch();

  const handleOpenModalPerson = () => {
    dispatch(
      openModalShare({
        person: { id: data?.person?.id },
        properties_id: [data?.property?.id],
      })
    );
  };

  const handleOpenModalProperty = () => {
    dispatch(
      openModalProperty({
        property: {
          id: data?.property?.id,
        },
        initialPage: 'Radar',
      })
    );
  };

  if (data?.people_count === 1) {
    return (
      <Button size="medium" color="success" onClick={handleOpenModalPerson}>
        Compartilhar
      </Button>
    );
  }

  return (
    <Button size="medium" color="success" onClick={handleOpenModalProperty}>
      Ver clientes
    </Button>
  );
}

export default Action;
