import React from 'react';
import Menu from '../../components/Menu';
import MenuItem from '../../components/MenuItem';
import { MdFavorite, MdSearch, MdViewColumn } from 'react-icons/md';

const MenuCrm = () => {
  return (
    <Menu title="Clientes" canBack>
      <MenuItem
        icon={MdViewColumn}
        to="/crm/deals"
        title="Atendimentos"
        text="Seu funil de vendas"
        color="#00C48C"
      />
      <MenuItem
        to="/crm/matched/show"
        icon={MdFavorite}
        color="#FF647C"
        title="Imóveis compatíveis"
        text="Envie para os seus clientes"
      />
      <MenuItem
        to="/profile/search"
        icon={MdSearch}
        color="#fd9526"
        title="Pesquisar por perfil"
        text="Encontre o cliente ideal"
      />
    </Menu>
  );
};

export default MenuCrm;
