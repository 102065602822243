import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  margin-bottom: 15px;

  .MainTitle {
    margin: 0;
    padding: 15px 30px 15px 30px;
    border-bottom: 1px solid #dedede;

    button {
      margin: 0;
    }

    p {
      color: ${p => p.theme.colors.primary};
    }
  }

  .Timeline__title {
    margin-bottom: 0.5em;
  }

  .Timeline__container {
    padding-bottom: 10px;

    p {
      color: ${p => p.theme.colors.primary};
    }
  }

  .Button {
    margin-top: 15px;
  }
`;

export const Container = styled.div`
  padding: 25px 30px 15px 30px;
`;

export const Dns = styled.div`
  display: inline-block;
  padding: 15px;
  color: ${p => p.theme.colors.success} !important;
  border: 1px solid #dedede;
  border-radius: ${p => p.theme.borderRadius};
  margin: 10px 0;

  p {
    color: inherit !important;
  }
`;

export const Number = styled.span`
  color: #fff;
`;
