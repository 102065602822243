import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change } from 'redux-form';
// Components
import MainTitle from 'components/MainTitle';
import FormPermissions from './components/FormPermissions';
// Modules
import { fetchPermissions, permissionsSelectors } from 'modules/permissions';
import { getCurrentUser } from 'modules/login';
// Services
import * as groupsService from 'services/settings/groups';
// Containers
import GroupsList from './containers/GroupsList';
import Loading from 'components/Loading';
import { withPermission } from 'HOC/withPermission';

const transformPermissions = permissionsNames => {
  return permissionsNames.reduce((obj, item) => {
    obj[item] = true;
    return obj;
  }, {});
};

class Permissions extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    groupId: null,
    initialValues: {}
  };

  async componentDidMount() {
    const { fetchPermissions } = this.props;

    // Busca todas as permissões
    fetchPermissions();
  }

  onSubmit = async values => {
    const { getCurrentUser } = this.props;
    const res = await groupsService.update(values);
    // Atualiza os dados do usuário caso altere as informações
    getCurrentUser();
    return res;
  };

  setInitialData({ permissions, ...values }) {
    this.setState({
      initialValues: {
        ...values,
        permissions: transformPermissions(permissions)
      }
    });
  }

  handleChangeGroup = async groupId => {
    this.setState({ groupId: null }, () => {
      this.setState({ groupId });
    });

    const { data } = await groupsService.getOne(groupId);
    this.setInitialData(data);
  };

  getPermissionIds(permissions, obj = {}) {
    permissions.forEach(permission => {
      obj[permission.name] = false;
      if (permission.permissions)
        return this.getPermissionIds(permission.permissions, obj);
    });

    return obj;
  }

  getPermissionsIds() {
    const { permissions: areas } = this.props;

    let permissions = {};

    areas.forEach(({ sections }) => {
      sections.forEach(section => {
        permissions = {
          ...permissions,
          ...this.getPermissionIds(section.permissions)
        };
      });
    });

    return permissions;
  }

  get initialValues() {
    return {
      ...this.state.initialValues,
      permissions: {
        ...this.getPermissionsIds(),
        ...this.state.initialValues.permissions
      }
    };
  }

  render() {
    const { groupId } = this.state;
    const { permissions, isFetching } = this.props;

    return (
      <div style={{ maxWidth: '1024px', margin: '0 auto' }}>
        <MainTitle
          title="Permissões"
          text="Definem o que um grupo de usuários pode ou não fazer dentro do sistema."
        />
        <div className="Permissions">
          <div className="Permissions__row">
            <GroupsList
              groupId={groupId}
              onChangeGroup={this.handleChangeGroup}
            />
            {groupId && !isFetching ? (
              <FormPermissions
                form={`FormPermissions--${groupId}`}
                groupId={groupId}
                areas={permissions}
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
              />
            ) : (
              <div className="Permissions__content">
                <Loading isVisible isBlock />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  permissions: permissionsSelectors.getAll(state),
  isFetching: permissionsSelectors.isFetching(state)
});

const mapDispatchToProps = {
  fetchPermissions,
  change,
  getCurrentUser
};

export default compose(
  withPermission({
    rules: ['EDIT_GROUP'],
    isPage: true
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(Permissions);
