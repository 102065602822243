import { DEFINED_RANGES } from 'components/Form/components/FieldDateRange';

export const defaultFinancialRanges = [
  DEFINED_RANGES.TODAY,
  DEFINED_RANGES.CURRENT_MONTH,
  DEFINED_RANGES.LAST_MONTH,
  DEFINED_RANGES.NEXT_MONTH,
  DEFINED_RANGES.NEXT_30_DAYS,
  DEFINED_RANGES.NEXT_60_DAYS,
  DEFINED_RANGES.NEXT_90_DAYS,
  DEFINED_RANGES.LAST_30_DAYS,
  DEFINED_RANGES.LAST_60_DAYS,
  DEFINED_RANGES.LAST_90_DAYS,
  DEFINED_RANGES.THIS_YEAR,
  DEFINED_RANGES.LAST_YEAR,
];
