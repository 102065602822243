import React from 'react';
import { getFirstName } from 'lib/text';

const EventWrapper = ({
  children,
  event: { people, color, summary, onClick },
}) => (
  <div
    className="rbc-event rbc-event--custom"
    style={{
      ...children.props.style,
      backgroundColor: color,
    }}
    onClick={children.props.onClick}
    onDoubleClick={children.props.onDoubleClick}
  >
    <div className="rbc-event-content">
      <div className="rbc-event-item">
        <span title={summary}>{summary}</span>
        {!!people?.name && <strong>{getFirstName(people.name)}</strong>}
      </div>
    </div>
  </div>
);

export default EventWrapper;
