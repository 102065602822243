import React from 'react';
import { Content, Text, Title, Wrapper } from './styles';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import styled from 'styled-components';
import TextOverflow from 'components/TextOverflow';

export const Percent = styled.span`
  color: #6c757d;
  vertical-align: middle;
`;

// const data = [
//   { name: 'Grupo A', value: 400 },
//   { name: 'Grupo B', value: 300 },
//   { name: 'Grupo C', value: 300 },
//   { name: 'Grupo D', value: 200 },
//   { name: 'Grupo E', value: 100 },
// ];

const COLORS = ['#3B86F6', '#58C1A1', '#F5BE4C', '#EF8751', '#8784D2'];

function GraphChannelPeople({ data }) {
  if (!data) return null;

  return (
    <Wrapper>
      <Title>Canal de origem dos clientes</Title>
      <Text>Distribuição por canal</Text>
      <Content>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              // innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              // paddingAngle={5}
              dataKey="people_count"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              iconSize={10}
              iconType="circle"
              formatter={(value, entry) => {
                const { payload } = entry;
                const percent = payload.percent;
                return (
                  <span>
                    <TextOverflow
                      width={120}
                      style={{ verticalAlign: 'middle' }}
                    >
                      {value}
                    </TextOverflow>{' '}
                    <strong style={{ verticalAlign: 'middle' }}>
                      {payload.people_count}
                    </strong>{' '}
                    <Percent>({percent}%)</Percent>
                  </span>
                );
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </Content>
    </Wrapper>
  );
}

export default GraphChannelPeople;
