import { useRequest } from 'hooks/useRequest';
import * as featuredListsService from 'services/sites/featuredLists';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useCallback, useMemo } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import Alert from 'react-s-alert';

export const useFeaturedLists = ({ with_all = false }) => {
  const dispatch = useDispatch();

  const {
    data: dataFeaturedLists,
    fetchData: fetchFeaturedLists,
    isFetching: isFetchingFeaturedList,
    setData,
  } = useRequest({
    request: () => featuredListsService.getFeaturedLists({ with_all }),
    initialFetch: false,
  });

  const handleRemoveFeaturedList =
    ({ id }) =>
    () => {
      dispatch(
        openConfirmation({
          title: 'Deseja remover a galeria?',
          text: 'Ao excluir a galeria, todos os imóveis que estão dentro dessa galeria serão removidos da página inicial do site. Deseja continuar?',
          submitButtonText: 'Excluir',
          submitButtonColor: 'danger',
          request: () => featuredListsService.removeFeaturedList(id),
          onSuccess: () => {
            // Atualiza a lista
            fetchFeaturedLists();
          },
        })
      );
    };

  const handleChangeOrderFeaturedList = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(
      dataFeaturedLists.featured_lists,
      oldIndex,
      newIndex
    );

    // pega a imagem para ser modificada
    const item = newItems.find(
      (image, index) => [newIndex].indexOf(index) >= 0
    );

    setData((state) => ({
      ...state,
      featured_lists: newItems,
    }));

    featuredListsService.upsertFeaturedList({ ...item, order: newIndex + 1 });
  };

  return useMemo(
    () => ({
      dataFeaturedLists,
      fetchFeaturedLists,
      isFetchingFeaturedList,
      handleRemoveFeaturedList,
      handleChangeOrderFeaturedList,
    }),
    [
      dataFeaturedLists,
      fetchFeaturedLists,
      isFetchingFeaturedList,
      handleRemoveFeaturedList,
      handleChangeOrderFeaturedList,
    ]
  );
};

export const useFeaturedList = () => {
  const {
    data: dataFeaturedList,
    fetchData: fetchFeaturedList,
    isFetching: isFetchingFeaturedList,
    setData,
  } = useRequest({
    request: featuredListsService.getFeaturedList,
    initialFetch: false,
  });

  const handleRemoveProperty = useCallback(({ id }) => {
    setData((items) => {
      return {
        ...items,
        properties: items.properties.filter((p) => p.id !== id),
      };
    });
  }, []);

  const handleSortEndProperties = useCallback(({ oldIndex, newIndex }) => {
    setData((items) => {
      const newItems = arrayMove(items.properties, oldIndex, newIndex);

      return { ...items, properties: newItems };
    });
  }, []);

  const handleUpdateProperties = useCallback(
    async (id) => {
      const property_ids = dataFeaturedList.properties.map((item) => {
        return item.id;
      });

      const response = await featuredListsService.updateFeaturedListsProperties(
        {
          id,
          property_ids,
        }
      );

      Alert.success('Galeria atualizada!');

      return response;
    },
    [dataFeaturedList]
  );

  return useMemo(
    () => ({
      dataFeaturedList,
      fetchFeaturedList,
      isFetchingFeaturedList,
      handleSortEndProperties,
      handleRemoveProperty,
      handleUpdateProperties,
    }),
    [
      dataFeaturedList,
      fetchFeaturedList,
      isFetchingFeaturedList,
      handleSortEndProperties,
    ]
  );
};
