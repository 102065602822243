import React from 'react';
import { Btn, Wrapper } from './styles';
import { MdAccessTime, MdOutlineDownloadForOffline } from 'react-icons/md';
import { downloadFile } from 'lib/storage-helpers';
import { getFirstName } from 'lib/text';
import moment from 'lib/moment';

function fileName(name) {
  const now = new Date(); // Obtém a data e hora atual

  const dia = String(now.getDate()).padStart(2, '0'); // Dia com zero à esquerda, se necessário
  const mes = String(now.getMonth() + 1).padStart(2, '0'); // Mês com zero à esquerda, pois começa em 0
  const ano = now.getFullYear().toString().slice(-2); // Últimos dois dígitos do ano
  const hora = String(now.getHours()).padStart(2, '0'); // Hora com zero à esquerda, se necessário
  const minuto = String(now.getMinutes()).padStart(2, '0'); // Minuto com zero à esquerda, se necessário

  const dataHoraFormatada = `${dia}-${mes}-${ano}.${hora}:${minuto}`;

  return `${name}-${dataHoraFormatada}`;
}

function ListBackups({ data }) {
  return (
    <Wrapper>
      <p>
        <strong>Arquivos disponíveis</strong> (Limitado a 3)
      </p>
      <table>
        <thead>
          <tr>
            <th align="left">Gerado por</th>
            <th align="left">Data e hora</th>
            <th align="right" />
          </tr>
        </thead>
        <tbody>
          {data.map((backup) => (
            <tr>
              <td align="left">{getFirstName(backup?.user?.name)}</td>
              <td align="left">
                {moment(backup?.created_at, 'DD/MM/YYYY HH:mm:ss').format(
                  'DD/MM/YYYY HH[h]mm'
                )}
              </td>
              <td align="right">
                {backup.is_exporting ? (
                  <Btn color="caution">
                    <MdAccessTime /> Aguarde
                  </Btn>
                ) : (
                  <Btn
                    as="a"
                    href={downloadFile(backup.file_url, fileName('backup'))}
                    color="success"
                  >
                    <MdOutlineDownloadForOffline /> Baixar
                  </Btn>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
}

export default ListBackups;
