import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.p`
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  margin-bottom: 0.5em;
  margin-top: 20px;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
`;
