import Button from "components/Button";
import Container from "components/Container";
import Loading from "components/Loading";
import MainTitle from "components/MainTitle";
import Wrapper from "components/Wrapper";
import { USER_PERMISSIONS } from "constants/rules";
import { useCan } from "hooks/useCan";
import { MdAdd } from "react-icons/md";
import TableCondos from "../TableCondos";

function List({ data, pagination, isFetching, handleChangePage, fetchData }) {
  const canManage = useCan({
    run: USER_PERMISSIONS.EDIT_CONDOS,
  });

  if (isFetching) return <Loading isFullScreen isVisible />;

  return (
    <Wrapper.inner>
      <Wrapper.container>
        <MainTitle
          title="Condomínios/empreendimentos"
          text="Listagem de condomínios e empreendimentos cadastrados"
        >
          {canManage && (
            <Button
              color="secondary"
              to="/condos/store"
            >
              <MdAdd /> Novo condomínio
            </Button>
          )}
        </MainTitle>
        <Container shadow padding style={{ marginBottom: '60px' }}>
          <TableCondos
            data={data}
            pagination={pagination}
            handleChangePage={handleChangePage}
            fetchData={fetchData}
            canManage={canManage}
          />
        </Container>
      </Wrapper.container>
    </Wrapper.inner>
  );
}

export default List;
