import React from 'react';
import TableSort from 'components/TableSort';
import Check from 'components/Check';
import TableCardProperty from 'components/TableCardProperty';
import TableCardPropertyDetails from 'components/TableCardPropertyDetails';
import { openModalProperty, openModalSendPeopleEmail } from 'modules/modal';
import { MdCheckCircle, MdDelete, MdEmail } from 'react-icons/md';
import useCheckbox from 'hooks/useCheckbox';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import * as propertiesService from 'services/properties';
import { BarItemSelecteds } from 'pages/Properties/components/FixedBarProperties/styles';
import { plural } from 'lib/text';
import FixedBar from 'components/FixedBar';

function addZero(number) {
  if (number === 0) return '--';
  return number.toString().padStart(2, '0');
}

// import { Wrapper } from './styles';

function TablePeople({ userId, data, meta, fetchData }) {
  const dispatch = useDispatch();

  const {
    checkedItems,
    isChecked,
    isAllItemsChecked,
    handleToggle,
    handleToggleAll,
    clear,
  } = useCheckbox([]);

  /**
   * Descarta um imóvel
   * @param matchedId
   * @return {(function(*): void)|*}
   */
  const handleClickDiscart = (property) => async (e) => {
    e.stopPropagation();
    dispatch(
      openConfirmation({
        title: `Descartar imóvel Ref: ${property.reference}`,
        text: 'Se voce deseja descartar esse imóvel clique em Descartar',
        submitButtonText: 'Descartar',
        request: () => {
          return propertiesService.discardMatcheds([property.id], userId);
        },
        onSuccess: () => {
          fetchData({ user_id: userId, offset: 1 });
          clear();
        },
      })
    );
  };

  const handleDiscartAllSelecteds = () => {
    dispatch(
      openConfirmation({
        title: `Remover da lista ${checkedItems.length} imóveis`,
        text: 'Se voce deseja descartar todos os imóveis clique em Descartar',
        submitButtonText: 'Descartar',
        request: () => {
          return propertiesService.discardMatcheds(checkedItems, userId);
        },
        onSuccess: () => {
          fetchData({ user_id: userId, offset: 1 });
          clear();
        },
      })
    );
  };

  const handleOpenProperty = (property, props) => (e) => {
    dispatch(
      openModalProperty({
        property,
        afterClose: fetchData,
        initialPage: 'Radar',
        ...props,
      })
    );
  };

  return (
    <>
      <TableSort
        data={data}
        meta={meta}
        fetchData={fetchData}
        renderHeader={() => (
          <tr>
            <th align="left">
              <Check
                checked={isAllItemsChecked(data)}
                onClick={() => handleToggleAll(data)}
              />
            </th>
            <th align="left">Imóvel</th>
            <th align="left">Detalhes</th>
            <th align="left">No Radar</th>
            <th align="left" colSpan={2}>
              Compartilhar
            </th>
          </tr>
        )}
        renderItem={(property) => (
          <tr key={property.id}>
            <td>
              <Check
                checked={isChecked(property.id)}
                onClick={() => handleToggle(property.id)}
              />
            </td>
            <td className="h-pointer" onClick={handleOpenProperty(property)}>
              <TableCardProperty property={property} />
            </td>
            <td className="h-pointer" onClick={handleOpenProperty(property)}>
              <TableCardPropertyDetails property={property} />
            </td>
            <td align="center">{addZero(property?.matcheds_count)}</td>
            <td onClick={handleOpenProperty(property)}>
              <a href="#" className="h-color--secondary">
                Compartilhar
              </a>
            </td>
            <td>
              <button onClick={handleClickDiscart(property)}>
                <MdDelete />
              </button>
            </td>
          </tr>
        )}
      />
      <FixedBar
        color="primary"
        noContainer
        isVisible={!!checkedItems.length > 0}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        style={{
          background: 'rgb(66, 88, 126)',
          zIndex: 1500,
        }}
      >
        <BarItemSelecteds>
          <span>
            <MdCheckCircle />{' '}
            {plural(
              checkedItems.length,
              'imóvel selecionado',
              'imóveis selecionados'
            )}
          </span>
        </BarItemSelecteds>
        <FixedBar.item>
          <FixedBar.link
            tagName="button"
            onClick={() => {
              dispatch(
                openModalSendPeopleEmail({
                  initialValues: {
                    properties_id: checkedItems,
                  },
                  userId,
                  afterClose: () => {
                    fetchData();
                    clear();
                  },
                })
              );
            }}
          >
            <MdEmail /> Enviar por e-mail e remover da lista
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.item>
          <FixedBar.link tagName="button" onClick={handleDiscartAllSelecteds}>
            <MdDelete /> Remover da lista
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.spacer />
      </FixedBar>
    </>
  );
}

export default TablePeople;
