import api from 'services';
import _ from 'lodash';
import { getChecked } from 'lib/object-helpers';

const transformData = (values) => {
  let newValues = { ...values };

  if (values.characteristics) {
    newValues = {
      ...newValues,
      characteristics: getChecked(newValues.characteristics),
    };
  }

  return newValues;
};

export const transformResponse = (res) => {
  res.data.is_condo = !!res.data.condominium_id;

  res.data.characteristics = _(res.data.characteristics)
    .map((item) => ({
      ...item,
      isChecked: true,
    }))
    .keyBy('id')
    .value();

  return res;
};

export const getOne = (id) =>
  api
    .getOne(`properties/partial/${id}/characteristics`, '')
    .then(transformResponse);

export const update = ({ id, ...data }) =>
  api.patch(`properties/partial/${id}/characteristics`, transformData(data));
