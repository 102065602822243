import React from 'react';
import { useSelector } from 'react-redux';
// Modules
import { cadastralSituationsSelector } from 'modules/cadastralSituations';
// Components
import FormBox from 'components/FormBox';
import ListCheck from 'components/ListCheck';

function CadastralSituations() {
  const data = useSelector(cadastralSituationsSelector);

  return (
    <FormBox
      title="Documentos/Certificados que o imóvel possui"
      className="js-cadastral-situations"
    >
      <ListCheck name="cadastral_situations" data={data} />
    </FormBox>
  );
}

export default CadastralSituations;
