import React from 'react';
import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Header } from './styles';
import TableProperties from './components/TableProperties';
import useCheckbox from 'hooks/useCheckbox';
import Button from 'components/Button';
import * as dealsService from 'services/deals';
import { responseMessage } from 'lib/service-helpers';

function ModalDealsMoveProperty$({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,

  properties,
  person,
  sourceStage,
  destinationStage,
  destIndex = 1,
  onSubmitSuccess,
}) {
  const {
    checkedItems,
    isChecked,
    isAllItemsChecked,
    handleToggle,
    handleToggleAll,
    clear,
  } = useCheckbox([]);

  const deals = person.deals;

  const handleMove = async () => {
    await dealsService
      .massUpdate({
        deals_id: checkedItems,
        stage_id: destinationStage.id,
        order: destIndex + 1,
        people_id: person.id,
      })
      .then(
        responseMessage(
          `Negócios movidos para a etapa ${destinationStage.name}`
        )
      );

    if (onSubmitSuccess) onSubmitSuccess();
    handleClose();
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Escolha os imóveis que vão mudar de etapa"
        handleClose={handleClose}
      >
        <Header>
          <div>
            <strong>De</strong>
            <span>{sourceStage.name}</span>
          </div>
          <div>
            <strong>Para</strong>
            <span>{destinationStage.name}</span>
          </div>
        </Header>
        <TableProperties
          data={deals}
          handleToggleAll={handleToggleAll}
          handleToggle={handleToggle}
          clear={clear}
          isAllItemsChecked={isAllItemsChecked}
          isChecked={isChecked}
          checkedItems={checkedItems}
        />
        <ModalFooter>
          <Button type="button" color="white" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button
            disabled={checkedItems.length <= 0}
            type="button"
            color="success"
            onClick={handleMove}
          >
            Mover
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalDealsMoveProperty$;
