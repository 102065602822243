import { ModalTemplate } from 'components/Modal';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';
import { useCallback } from 'react';
import Modal from 'react-modal';
import Form from './components/Form';
import * as locationService from 'services/location';
import Alert from 'react-s-alert';

function ModalAddZone({
  initialValues = {},
  onClose,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onAddZone,
}) {
  const currentRealEstate = useCurrentRealEstate();

  const _handleClose = (res, dispatch, props) => {
    handleClose();
    if (onClose) onClose(res, dispatch, props);
  };

  const onSubmit = useCallback((props) => {
    // Service de localização de criação da zona
    return locationService.createZone(props);
  }, []);

  const onSubmitSuccess = useCallback((res, dispatch, props) => {
    // Verifica se tem algo no callBack da função
    if (onAddZone) onAddZone(res, dispatch, props);

    // Alerta para o usuário
    Alert.success('Zona adicionada!');

    // Fecha a modal quando cadastra uma zona
    _handleClose(res, dispatch, props);
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={_handleClose}
    >
      <ModalTemplate title="Cadastrar zona" handleClose={_handleClose}>
        <p className="h-margin-bottom--15">
          Digite abaixo o nome da nova zona
        </p>
        <Form
          initialValues={{
            ...currentRealEstate?.relations,
            ...initialValues,
          }}
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
          handleClose={_handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalAddZone;
