// import styles from './styles'
import Box from '../../components/Box';
import { STORAGE_KEY } from 'containers/CreciAlert/constants';
import { MdAccessTime } from 'react-icons/md';

export default function CheckingCreci() {
  return (
    <Box
      canClose
      storageKey={STORAGE_KEY}
      label={{
        icon: <MdAccessTime />,
        text: 'Verificar CRECI',
      }}
    >
      Seus documentos estão aguardando verificação. O prazo de verificação é de
      1 dia útil.
    </Box>
  );
}
