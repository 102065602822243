import api from './index';
import { responseMessage } from 'lib/service-helpers';

export const getOne = ({ id }) => api.getOne(`properties/characteristics`, id);

export const getList = ({ type_id, ...params }) =>
  api.getList(`properties/characteristics`, {
    ...params,
    filter: { ...params?.filter, type_id },
    sort: params?.sort || 'by_title',
  });

export const getAllCustom = (params) =>
  api.getList('properties/characteristics', {
    ...params,
    with_defaults: false,
    include: 'type',
    sort: 'by_title',
    count: 'properties',
  });

export const create = (type_id, data) =>
  api
    .create(`properties/characteristics`, { ...data, type_id })
    .then(responseMessage('Característica cadastrada'));

export const update = (type_id, data) =>
  api
    .update(`properties/characteristics`, { ...data, type_id })
    .then(responseMessage('Característica atualizada'));

export const transfer = (fromId, toId) =>
  api.create(`properties/characteristics/${fromId}/transfers/${toId}`);

export const remove = (id) =>
  api
    .delete(`properties/characteristics`, id)
    .then(responseMessage('Característica removida'));
