import React, { useEffect } from 'react';
import TableSort from 'components/TableSort';
import { useTemplateContext } from 'containers/ModalRoot/ModalSiteTemplate/contexts/TemplateContext';
import Nodata from './Nodata';
import Loading from 'components/Loading';

// import { Wrapper } from './styles';

function TableHistory() {
  const {
    history,
    isFetchingHistory,
    fetchHistory,
    applyTemplate,
    fetchTemplates,
  } = useTemplateContext();

  const getVersion = (index) => {
    return history.length - index;
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  if (isFetchingHistory) return <Loading isVisible isBlock />;

  if (!history || history?.length <= 0) return <Nodata />;

  return (
    <div>
      <TableSort
        data={history}
        renderHeader={() => (
          <tr>
            <th width={67}>Versão</th>
            <th width={160} align={'left'}>
              Data e hora
            </th>
            <th align={'left'}>Descrição</th>
            <th width={90} align={'left'}>
              Opções
            </th>
          </tr>
        )}
        renderItem={(item, index) => (
          <tr key={item?.id}>
            <td align={'center'}>{getVersion(index)}</td>
            <td align={'left'}>{item.created_at}</td>
            <td align={'left'}>{item.title}</td>
            <td align={'left'}>
              {item.is_selected ? (
                'Selecionado'
              ) : (
                <span
                  className="h-link"
                  onClick={async () => {
                    if (item?.id) {
                      await applyTemplate(item?.id);
                      fetchHistory();
                    }
                  }}
                >
                  Restaurar
                </span>
              )}
            </td>
          </tr>
        )}
      />
    </div>
  );
}

export default TableHistory;
