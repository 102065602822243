import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
// Components
import { FieldBool, InputDate } from 'components/Form';
import ListOptions from 'components/ListOptions';
// Selector
import { selector } from '../../index';

function FieldCalendar({ canCreateCalendar, clearField }) {
  return (
    <ListOptions.item
      title="Agendar retorno?"
      renderOptions={() => (
        <>
          <FieldBool
            name="should_create_calendar"
            onChange={(e, value) => {
              clearField('calendar_start_date')();
            }}
          />
          <Field
            autoComplete={false}
            showTimeSelect
            disabled={!canCreateCalendar}
            minDate={Date.now()}
            name="calendar_start_date"
            placeholder="Digite a data e"
            component={InputDate}
          />
        </>
      )}
    />
  );
}

FieldCalendar.propTypes = {
  clearField: PropTypes.func.isRequired,
  canCreateCalendar: PropTypes.bool
};

FieldCalendar.defaultProps = {
  clearField: null,
  canCreateCalendar: null
};

const mapStateToProps = state => ({
  canCreateCalendar: selector(state, 'should_create_calendar')
});

export default connect(mapStateToProps)(FieldCalendar);
