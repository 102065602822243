import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IoMdCloseCircle as MdClose } from 'react-icons/io';

const drawerRoot = document.getElementById('drawers');

export const Title = ({ onClickOverlay, children }) => (
  <header className="Drawer__header">{children}</header>
);

export const Content = ({ children }) => (
  <div className="Drawer__content">{children}</div>
);

class Drawer extends React.Component {
  static title = Title;
  static content = Content;

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node,

    onClickOverlay: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    children: null,

    onClickOverlay: () => {}
  };

  constructor(props) {
    super(props);

    this.createWrapper();
  }

  get classNames() {
    return classnames('Drawer', {
      'is-opened': this.props.isOpen,
      'is-closed': !this.props.isOpen
    });
  }

  updateClassNames() {
    this.drawer.className = this.classNames;
  }

  createWrapper() {
    this.drawer = document.createElement('div');
    this.updateClassNames();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen, onOpen, onClose } = this.props;

    if (this.props !== prevProps) {
      this.updateClassNames();
    }

    // Verifica se tem alguma mudança
    // se tiver chama o callback se existir
    if (isOpen !== prevProps.isOpen) {
      isOpen ? onOpen && onOpen() : onClose && onClose();
    }
  }

  componentDidMount() {
    drawerRoot.appendChild(this.drawer);
  }

  componentWillUnmount() {
    drawerRoot.removeChild(this.drawer);
  }

  handleClickOverlay = () => {
    const { onClickOverlay } = this.props;
    if (onClickOverlay) onClickOverlay(this.drawer);
  };

  get renderOverlay() {
    return (
      <div className="Drawer__overlay" onClick={this.handleClickOverlay} />
    );
  }

  render() {
    return ReactDOM.createPortal(
      <>
        <div className="Drawer__wrapper">
          <div className="Drawer__inner">
            <button
              type="button"
              className="Drawer__close"
              onClick={this.props.onClickOverlay}
            >
              <MdClose />
            </button>
            {this.props.children}
          </div>
        </div>
        {this.renderOverlay}
      </>,
      this.drawer
    );
  }
}

export default Drawer;
