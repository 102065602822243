import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 436px;
  padding: 16px 16px 0 16px;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  table {
    border-spacing: 0;

    th {
      text-align: left;
      padding-bottom: 5px;
    }

    tbody td {
      padding: 2px 0;
    }

    img {
      display: inline;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Text = styled.div`
  font-size: 12px;
  color: #6b7280;
  font-weight: bold;
`;

export const Bars = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Bar = styled.div`
  width: 90%;
  height: 32px;
  line-height: 32px;
  position: relative;
  padding-left: 10px;
  color: #1f2937;
  background: linear-gradient(
      to right,
      #eff6ff ${(p) => p['data-percent']},
      #fff ${(p) => p['data-percent']}
    )
    no-repeat;

  & + & {
    margin-top: 3px;
  }
`;

export const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const SeeMore = styled.a`
  width: 100%;
  text-align: center;
  padding: 16px;
  color: #6b7280;
`;
