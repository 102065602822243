// Widgets.js
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import { createModule } from '../lib/reducer-helpers';
import * as financialIndex from '../services/financialIndex';
import { closeModal } from './modal';
import msg from '../lib/msg';

const createAction = createModule('modalFinancial');

// Actions
const REQUEST_INITIAL_VALUES = createAction('REQUEST_VALUES');
const RECEIVE_INITIAL_VALUES = createAction('RECEIVE_VALUES');
const FAILED_INITIAL_VALUES = createAction('FAILED');

// Initial State
const initialState = {
  formData: {},
  meta: {
    isFetching: false
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_INITIAL_VALUES: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isFetching: true
        }
      };
    }
    case RECEIVE_INITIAL_VALUES: {
      return {
        ...state,
        formData: action.formData,
        meta: {
          ...state.meta,
          isFetching: false
        }
      };
    }
    case FAILED_INITIAL_VALUES:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function requestInitialValues() {
  return { type: REQUEST_INITIAL_VALUES };
}

export function receiveInitialValues(formData) {
  return { type: RECEIVE_INITIAL_VALUES, formData };
}

// side effects, only as applicable
// e.g. thunks, epics, etc

export function getInitialValues(id) {
  return async dispatch => {
    // reseta os valores que vem do formulario
    dispatch(receiveInitialValues({}));
    // inicia o request indicando que esta procurando dados
    dispatch(requestInitialValues());

    if (id) {
      financialIndex.getOne(id).then(({ data }) => {
        dispatch(
          receiveInitialValues({
            ...data,
            alert: !!data.renew_date ? '1' : '2'
          })
        );
      });
    } else {
      dispatch(
        receiveInitialValues({
          alert: '1',
          symbol: '$'
        })
      );
    }
  };
}

export function insert(values) {
  return dispatch => {
    return financialIndex
      .create(values)
      .then(({ data }) => {
        dispatch(closeModal());
        return data;
      })
      .catch(() => {
        throw new SubmissionError({
          _error: 'Não foi possivel adicionar o índice'
        });
      });
  };
}

export function update(values) {
  return dispatch => {
    return financialIndex
      .update(values)
      .then(({ data }) => {
        Alert.success(msg.update(values));
        dispatch(closeModal());
        return data;
      })
      .catch(() => {
        throw new SubmissionError({
          _error: 'Não foi possivel alterar'
        });
      });
  };
}

export function handleFormSubmit(values) {
  return (dispatch, getState) => {
    const {
      modalFinancial: { formData },
      modal: {
        modalProps: { callBack }
      }
    } = getState();

    if (formData.id) {
      return update(values)(dispatch).then(data => {
        if (callBack) callBack(data, dispatch);
      });
    }

    return insert(values)(dispatch).then(person => {
      if (callBack) callBack(person, dispatch);
    });
  };
}
