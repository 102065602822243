// Components
import Container from 'components/Container';
import TablePeopleStriped from 'components/TablePeopleStriped';
import PeopleContainer from '../PeopleContainer';
import SortPeople from '../SortPeople';

const propTypes = {};
const defaultProps = {};

const PeopleList = ({
  people,
  pagination,
  isFetching,

  // Event Handlers
  handleOpenModalPerson,
  handlePersonDelete,
  handlePersonInative,
  handlePersonActive,
  handlePersonEdit,
  handleChangePage,
  handleResetFilter,
}) => {
  return (
    <PeopleContainer
      isSearching
      pagination={pagination}
      renderActions={() => <SortPeople />}
    >
      <Container shadow padding style={{ marginBottom: '60px' }}>
        <TablePeopleStriped
          people={people}
          pagination={pagination}
          isFetching={isFetching}
          handleOpenPerson={handleOpenModalPerson}
          handleOpenModalPerson={handleOpenModalPerson}
          handlePersonInative={handlePersonInative}
          handlePersonActive={handlePersonActive}
          handlePersonDelete={handlePersonDelete}
          handlePersonEdit={handlePersonEdit}
          handleChangePage={handleChangePage}
          handleResetFilter={handleResetFilter}
        />
      </Container>
    </PeopleContainer>
  );
};

PeopleList.propTypes = propTypes;
PeopleList.defaultProps = defaultProps;

export default PeopleList;
