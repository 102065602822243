import React from 'react';
import Content, { ListCount } from '../Content';
import Count from '../Count';
import {
  openModalImport,
  openModalInviteUserReception,
  openModalSiteTemplate,
} from 'modules/modal';
import { useDispatch } from 'react-redux';
import Check from 'components/Check';
import Button from 'components/Button';
import styled from 'styled-components';

import collections from './assets/collections.png';
import importExport from './assets/import-export.png';
import input from './assets/input.png';
import internet from './assets/internet-connection.png';
import organization from './assets/organization.png';
import place from './assets/place-marker.png';
import withReception from 'HOC/withReception';

const CustomButton = styled(Button)`
  height: 30px;
`;

// import { Wrapper } from './styles';

function Steps({
  showAgain,
  toggleVisibility,
  reception,
  handleClickAction,
  show,
  hide,
  handleAddWizard,
}) {
  const dispatch = useDispatch();

  const handleOpenInviteUsers = () => {
    dispatch(openModalInviteUserReception());
  };

  const handleClickImport = () => {
    dispatch(openModalImport());
  };

  if (!reception?.isVisible) return false;

  return (
    <Content
      className="js-primeiros-passos"
      title="Passos Iniciais para o Sucesso do Seu Site"
      actions={
        <>
          <label onClick={toggleVisibility} style={{ marginRight: '15px' }}>
            <Check checked={showAgain} /> Não mostrar novamente
          </label>
          <CustomButton
            size="small"
            color="success"
            onClick={handleClickAction}
          >
            Fechar
          </CustomButton>
        </>
      }
    >
      <ListCount isHighlight>
        <Count
          image={place}
          title={
            <>
              Dados da
              <br />
              Empresa
            </>
          }
          actionText="Editar"
          to="site/config/realestate"
        />
        <Count
          image={collections}
          title={
            <>
              Personalizar
              <br />
              Site
            </>
          }
          actionText="Personalizar"
          onClick={() => {
            dispatch(openModalSiteTemplate());
          }}
        />
        <Count
          image={organization}
          title={
            <>
              Convidar
              <br />
              Equipe
            </>
          }
          actionText="Enviar convites"
          onClick={handleOpenInviteUsers}
        />
        <Count
          image={importExport}
          title={
            <>
              Rodízio
              <br /> de Leads
            </>
          }
          actionText="Configurar"
          to="site/config/crm/own"
        />
        <Count
          image={internet}
          title={
            <>
              Configurar
              <br />
              Domínio
            </>
          }
          actionText="Configurar"
          to="site/config/domains"
        />
        <Count
          image={input}
          title={
            <>
              Importar dados
              <br />
              de outro sistema
            </>
          }
          actionText="Informações"
          onClick={handleClickImport}
        />
      </ListCount>
    </Content>
  );
}

export default withReception('wizard-video-dashboard-10')(Steps);
