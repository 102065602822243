import React, { useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import SearchBar from 'components/SearchBar';
import MenuNotifications from './components/MenuNotifications';
import MenuPrimary from './components/MenuPrimary';
import MenuActionsNew from './components/MenuActionsNew';
import MenuUser from './components/MenuUser';
import MenuProvider from 'components/Menus/context';
import MenuConfigs from 'components/MainHeader2/components/MenuItems/MenuConfigs';
import { Link } from 'react-router-dom';
import { Wrapper, Content, LogoImg, Spacer, Divider, MenuItem, ButtonSite, SearchWrapper } from './styles';
import { getHeaderBackground, getHeaderLogoImage, currentUserSelector } from 'modules/login';
import { ThemeProvider } from 'styled-components';
import UserProvider from './contexts/UserContext';

export default function MainHeader() {
  const header = useRef();
  const logoImage = useSelector(getHeaderLogoImage);
  const background = useSelector(getHeaderBackground);
  const currentUser = useSelector(currentUserSelector);

  const headerHeight = useMemo(() => {
    return header?.current?.clientHeight || 55;
  }, [header?.current]);

  return (
    <ThemeProvider theme={{ headerHeight, bgHeader: background || '#0084F4' }}>
      <UserProvider>
        <MenuProvider>
          <Wrapper ref={header} className="MainHeader">
            <Content>
              <Link to="/dashboard">
                <LogoImg
                  key={logoImage}
                  src={logoImage}
                  alt="Logo da imobiliária"
                />
              </Link>
              <SearchWrapper>
                <SearchBar />
              </SearchWrapper>
              <Spacer />
              <MenuPrimary />
              <Divider />
              <MenuActionsNew />
              <MenuItem className="js-button-site">
                <ButtonSite href={currentUser?.primary_domain_url}>
                  Ver site
                </ButtonSite>
              </MenuItem>
              <Divider />
              <MenuNotifications />
              <Divider />
              <MenuUser />
              <Divider />
              <MenuConfigs />
            </Content>
          </Wrapper>
        </MenuProvider>
      </UserProvider>
      {/*<Wizard />*/}
    </ThemeProvider>
  );
}
