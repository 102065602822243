import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { change, getFormValues, initialize, reset } from 'redux-form';
import MainForm from './components/MainForm';
// Modules
import { Actions as LocationActions } from 'containers/LocationFields/module';
import {
  Actions as FormActions,
  getIsReference,
  getIsSearching,
  handleSaveProfile,
  handleSubmit
} from './module';
// HOC
import WizardPortal from 'containers/Wizards/WizardPortal';
import { withFormData } from 'HOC/withFormData';
import { clearUrlParams } from 'lib/url-helpers';
import { propertiesIsFetchingSelector } from 'modules/properties';

class Filter extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      typeOrSubtypes: PropTypes.arrayOf(PropTypes.object),
      characteristics: PropTypes.arrayOf(PropTypes.object),
      establishments: PropTypes.arrayOf(PropTypes.object),
    }),
  };

  static defaultProps = {
    data: {
      typeOrSubtypes: null,
      characteristics: null,
      establishments: null,
    },
  };

  state = {
    form: 'MainForm',
    isReference: false,
  };

  get status() {
    try {
      const {
        status,
        match: { params },
      } = this.props;

      return status || params.status;
    } catch {
      return null;
    }
  }

  get realEstate() {
    const currentUser = localStorage.getItem('current-user');
    return currentUser.real_estate;
  }

  get location() {
    let storagedData = localStorage.getItem('PropertiesFilter');

    if (storagedData) {
      storagedData = JSON.parse(storagedData);
    }

    try {
      if (this.status) return {};
      const { relations } = this.realEstate;
      return {
        by_country_id: relations.country_id,
        ...storagedData,
      };
    } catch {
      return {};
    }
  }

  get initialValues() {
    const { location } = this.props;

    if (location?.search) {
      const searchObject = qs.parse(location?.search, {
        ignoreQueryPrefix: true,
        arrayLimit: 99999,
      });

      let initialValues = searchObject?.initialValues;

      initialValues = {
        ...initialValues,
        transaction: initialValues?.transaction
          ? parseInt(initialValues?.transaction, 10)
          : '',
      };

      return initialValues;
    }

    let values = {};

    if (!this.status) {
      values = {
        ...values,
        by_floor_comparision: 'lower',
      };
    }
    return {
      ...values,
      status: this.status,
      ...this.location,
    };
  }

  componentDidMount() {
    const { location } = this.props;

    const searchObject = qs.parse(location?.search, {
      ignoreQueryPrefix: true,
    });

    if (searchObject._openModalProperty) {
    } else if (this.status || (location?.search && !searchObject?.property)) {
      this.handleSubmit(this.initialValues);
    }
  }

  componentWillUnmount() {
    this.handleResetSearch();
  }

  handleClickToggleReference = () => {
    const { change, searchEnd, isReference, hideReference, showReference } =
      this.props;

    searchEnd();

    if (isReference) {
      change('FilterProperties', 'reference', null);
      return hideReference();
    }

    // Mostra o formulário de referência
    showReference();
  };

  handleResetSearch = () => {
    clearUrlParams();
    this.props.reset('FilterProperties');
    this.props.searchEnd();
    this.props.hideReference();
  };

  handleSaveProfile = async () => {
    const { values, handleSaveProfile, history } = this.props;
    const { data } = await handleSaveProfile(values);

    history.push(`/profile/create?${qs.stringify({ formData: data })}`);
  };

  handleSubmit = ({
    by_floor_comparision,
    by_floor_lower,
    by_floor_equals,
    by_floor_greater,
    ...formValues
  }) => {
    const { handleSubmit, realEstatePortalId } = this.props;

    let values = formValues;

    switch (by_floor_comparision) {
      case 'greater':
        values = { ...values, by_floor_greater };
        break;
      case 'lower':
        values = { ...values, by_floor_lower };
        break;
      case 'equals':
      default:
        values = { ...values, by_floor_equals };
        break;
    }

    let include = 'user';

    if (realEstatePortalId) {
      include = 'portal_real_estate_options,condominium';
    }

    localStorage.setItem(
      'PropertiesFilter',
      JSON.stringify({ by_state_id: formValues.by_state_id }),
    );

    return handleSubmit(values, include, realEstatePortalId);
  };

  onSubmitSuccess = (values, dispatch, props) => {
    // this.props.change("FilterProperties", "reference", null);
  };

  render() {
    const { isFetching, realEstatePortalId, isSearching, isReference, data } =
      this.props;

    return (
      <>
        <MainForm
          isReference={isReference}
          isSearching={isSearching}
          initialValues={this.initialValues}
          data={data}
          realEstatePortalId={realEstatePortalId}
          onSubmit={isFetching ? null : this.handleSubmit}
          onSubmitSuccess={this.onSubmitSuccess}
          handleClickToggleReference={this.handleClickToggleReference}
          handleResetSearch={this.handleResetSearch}
          handleSaveProfile={this.handleSaveProfile}
          hideReference={this.props.hideReference}
        />
        <WizardPortal />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: propertiesIsFetchingSelector(state),
  isReference: getIsReference(state),
  isSearching: getIsSearching(state),
  values: getFormValues('FilterProperties')(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...LocationActions,
      ...FormActions,
      handleSubmit,
      reset,
      handleSaveProfile,
      change,
      initialize,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormData('FilterProperties'),
  withRouter
)(Filter);
