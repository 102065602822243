// Modules
import dataReducer from 'modules/data';
// Helpers
import { filterOrAll } from '../helpers';
import { getBillingPerCommission } from 'services/reports';

export const {
  reducer: billingPerCommissionReducer,
  actionCreators: billingPerCommissionActions,
  selectors: billingPerCommissionSelectors
} = dataReducer(`reports/billingPerCommission`);

// Selector
export const chartDataSelector = filterOrAll(billingPerCommissionSelectors);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(billingPerCommissionActions.request());

  getBillingPerCommission(params).then(res => {
    dispatch(billingPerCommissionActions.receive(res.data));

    return res.data;
  });
};

export const toggleChecked = item => dispatch => {
  dispatch(billingPerCommissionActions.toggleSelected(item.id));
};

export default billingPerCommissionReducer;
