import React from 'react';
import { Field } from 'redux-form';
import Input from '../../../../../components/Form/components/Input';
import Select from '../../../../../components/Form/components/Select';
import GroupControl from '../../../../../components/GroupControl';
import GroupItem from '../../../../../components/GroupControl/components/GroupItem';
import { SelectArea } from '../../../../../pages/Properties/SearchFilter/components/FilterForm/fields';

const emptyArea = { name: 'total_area', title: 'Área Total', measures: ['m²'] };

export default class AreaField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      field: this.getFieldByName(this.props.areaName)
    };

    this.handleClickItem = this.handleClickItem.bind(this);
    this.clearFields = this.clearFields.bind(this);
  }

  componentDidMount() {}

  getFieldByName(name) {
    return this.props.fields.find(field => name === field.name) || emptyArea;
  }

  clearFields({ name, measures }) {
    this.props.change('minimum_area', '');
    this.props.change('maximum_area', '');

    this.props.change('area_measure', measures[0]);
    this.props.change('area_name', name);
  }

  handleClickItem(field) {
    return e => {
      e.stopPropagation();
      this.clearFields(field);
      this.setState({ field });
    };
  }

  render() {
    const { fields } = this.props;
    const { field } = this.state;

    return field ? (
      <GroupControl
        required={false}
        label={
          <SelectArea
            currentField={field}
            options={fields}
            handleClick={this.handleClickItem}
          />
        }
      >
        <Field type="hidden" name="area_name" component="input" />
        <GroupItem>
          <GroupControl>
            <GroupItem>
              <Field key={field.name} name="minimum_area" component={Input} />
            </GroupItem>
            <GroupItem divider size={7} />
            <GroupItem>
              <Field key={field.name} name="maximum_area" component={Input} />
            </GroupItem>
          </GroupControl>
        </GroupItem>
        <GroupItem>
          <Field
            autoWidth
            placeholder=""
            name="area_measure"
            component={Select}
            options={field.measures.map(measure => ({
              label: measure,
              value: measure
            }))}
          />
        </GroupItem>
      </GroupControl>
    ) : null;
  }
}
