import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const defaultProps = {
  children: null,
  style: null,
  withBorder: false
};

const propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  withBorder: PropTypes.bool
};

const ModalFooter = ({ withBorder, children, style }) =>
  children && (
    <div
      className={classnames('Modal__footer', {
        'Modal__footer--border': withBorder
      })}
      style={style}
    >
      {children}
    </div>
  );

ModalFooter.defaultProps = defaultProps;
ModalFooter.propTypes = propTypes;

export default ModalFooter;
