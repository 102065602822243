import * as S from './styles';
import Button from 'components/Button';

export function Selector({ imageUrl, onClick }) {
  return (
    <S.Wrapper>
      <S.Content>
        {imageUrl ? <S.Image src={imageUrl} alt="Icon" /> : null}
        <S.Overlay onClick={onClick}>
          <Button color="white" size="medium">
            Alterar icone
          </Button>
        </S.Overlay>
      </S.Content>
    </S.Wrapper>
  );
}
