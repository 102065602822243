// Widgets.js
import { createModule } from 'lib/reducer-helpers';

const createAction = createModule('modalImages');

// Actions
const OPEN = createAction('OPEN');
const CLOSE = createAction('CLOSE');
const OPEN_CAROUSEL = createAction('OPEN_CAROUSEL');
const CLOSE_CAROUSEL = createAction('CLOSE_CAROUSEL');
const TOGGLE = createAction('TOGGLE');
const SET_CURRENT_IMAGE = createAction('SET_CURRENT_IMAGE');
const SET_GALLERY = createAction('SET_GALLERY');

// pega todos os estabelecimentos
export const Selectors = {
  isOpen: (state) => state.modalImages.isOpen,
  isOpenCarousel: (state) => state.modalImages.isOpenCarousel,
  currentImage: (state) => state.modalImages.currentImage,
  gallery: (state) => state.modalImages.gallery,
};

// Initial State
const initialState = {
  isOpen: false,
  isOpenCarousel: false,
  currentImage: 0,
  gallery: 'images',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CAROUSEL: {
      return {
        ...state,
        isOpen: true,
        isOpenCarousel: true,
      };
    }

    case CLOSE_CAROUSEL: {
      return {
        ...state,
        isOpen: true,
        isOpenCarousel: false,
      };
    }

    case OPEN: {
      return {
        ...initialState,
        isOpen: true,
      };
    }
    case CLOSE: {
      return {
        ...initialState,
        isOpen: false,
      };
    }
    case TOGGLE: {
      return {
        ...initialState,
        isOpen: !state.isOpen,
      };
    }

    case SET_CURRENT_IMAGE:
      return { ...state, currentImage: action.currentImage };

    case SET_GALLERY:
      return { ...state, gallery: action.gallery };

    default:
      return state;
  }
}

export const Actions = {
  toggle: () => ({ type: TOGGLE }),
  open: () => ({ type: OPEN }),
  close: () => ({ type: CLOSE }),
  openCarousel: () => ({ type: OPEN_CAROUSEL }),
  closeCarousel: () => ({ type: CLOSE_CAROUSEL }),
  setCurrentImage: (currentImage) => ({
    type: SET_CURRENT_IMAGE,
    currentImage,
  }),
  setGallery: (gallery) => ({
    type: SET_GALLERY,
    gallery,
  }),
};
