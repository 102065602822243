import styled from 'styled-components';

export const WrapperNoData = styled.div``;
export const Blur = styled.div`
  filter: blur(7px);
  pointer-events: none;
`;

export const Message = styled.div`
  padding: 20px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  text-align: center;
  z-index: 5;
`;
