import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  background: #fff;
`;
export const Content = styled.div`
  flex: 1 0;
  min-width: 1px;
  padding: 20px;

  .MainTitle {
    margin-top: 0;
  }
`;
export const Aside = styled.aside`
  width: 280px;
  height: 100%;
  border-left: 1px solid #dedede;
  padding: 10px;
`;
