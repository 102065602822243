import React from 'react';
import Link from './components/Link';
import { ListAside } from 'components/ListAside';
import { Wrapper, Title } from './styles';
import { useRouteMatch, withRouter } from 'react-router';
import { useId } from '../../hooks/useId';
import { useAside } from '../../hooks/useAside';

const Aside = params => {
  let { path: basePath } = useRouteMatch();
  const { id } = useId();
  const { pages } = useAside();

  return (
    <Wrapper className="Aside">
      <Title>Passo a passo:</Title>
      <ListAside>
        {pages.map(({ name, path, isDisabled, isHidden, hasError }) => (
          <Link
            hasError={hasError}
            isHidden={isHidden}
            isDisabled={isDisabled}
            to={`${basePath}${path}/${id}`}
          >
            {name}
          </Link>
        ))}
      </ListAside>
    </Wrapper>
  );
};

export default withRouter(Aside);
