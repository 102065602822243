import PropTypes from 'prop-types';
import React from 'react';
import { Field, FormSection, reduxForm } from 'redux-form';
// Components
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { Checkbox } from 'components/Form';
import GroupControl, { GroupItem } from 'components/GroupControl';
import styled from 'styled-components';

const Form = styled.form`
  @media all and (max-width: 1440px) {
    .GroupControl__controls {
      flex-direction: column;
      white-space: nowrap;
    }
  }
`;

const renderItem =
  (indexes) =>
  ({
    id,
    reference,
    type,
    condominium,
    informations,
    cover_image,
    calculated_price,
    neighborhood,
  }) =>
    (
      <FormSection key={`properties-${id}`} name={`properties.${id}`}>
        <tr>
          <td>
            <Field name="id" type="hidden" component="input" value={id} />
            <Avatar size="large" image={cover_image?.file_url} />
          </td>
          <td>{reference}</td>
          <td>{type?.title}</td>
          <td>{condominium?.title || '--'}</td>
          <td>{neighborhood?.name}</td>
          <td>{informations?.apartment_number?.value || '--'}</td>
          <td>{calculated_price}</td>
          <td>
            <GroupControl>
              <GroupItem>
                <Field
                  type="checkbox"
                  label="Mostrar no site"
                  name={`is_published`}
                  component={Checkbox}
                />
              </GroupItem>
              <GroupItem>
                <Field
                  type="checkbox"
                  label="Mostrar Logradouro?"
                  name={`is_street_shown`}
                  component={Checkbox}
                />
              </GroupItem>
            </GroupControl>
          </td>
        </tr>
      </FormSection>
    );

class TablePropertyPrice extends React.Component {
  static defaultProps = {
    data: [],
    indexes: [],
  };

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    indexes: PropTypes.arrayOf(PropTypes.object),
  };

  get renderItems() {
    const { data, indexes } = this.props;
    return data.map(renderItem(indexes));
  }

  render() {
    const { handleSubmit, handleToggle } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <table className="Table">
          <thead>
            <tr>
              <th />
              <th>Ref.</th>
              <th>Imóvel</th>
              <th>Condomínio</th>
              <th>Bairro</th>
              <th>Unidade</th>
              <th>Preço do Imóvel</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>{this.renderItems}</tbody>
        </table>
        <FixedBar
          isVisible
          position="bottom"
          style={{
            left: '240px',
          }}
        >
          <FixedBar.item>
            <Button
              type="button"
              color="white"
              colorText="secondary"
              onClick={handleToggle}
            >
              Voltar
            </Button>
          </FixedBar.item>
          <FixedBar.spacer />
          <FixedBar.item>
            <Button type="submit" color="success">
              Salvar Alterações
            </Button>
          </FixedBar.item>
        </FixedBar>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'TablePropertyPublish',
  enableReinitialize: true,
})(TablePropertyPrice);
