import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row } from 'react-flexbox-grid';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { Input, Textarea } from 'components/Form';
import Button from 'components/Button';
import ModalFooter from 'components/Modal/components/ModalFooter';
import validate from './validate';

class FormSendMail extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func
  };

  state = {
    people: []
  };

  render() {
    const { handleSubmit, handleClose } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Field
            required
            xs={12}
            name="subject"
            label="Assunto do E-mail"
            component={Input}
          />
          <Field
            required
            xs={12}
            name="complement"
            label="Escreva apenas um complemento para o cliente:"
            placeholder="Complemento"
            onChange={this.handleDescription}
            component={Textarea}
          />
        </Row>
        <ModalFooter>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success">
            Enviar
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

const selector = formValueSelector('FormSendMail');

const mapStateToProps = state => ({
  model: selector(state, 'model'),
  complement: selector(state, 'complement')
});

export default compose(
  reduxForm({
    form: 'FormSendMail',
    validate,
    enableReinitialize: true
  }),
  connect(mapStateToProps)
)(FormSendMail);
