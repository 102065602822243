import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import FormSeo from '../FormSeo';
import FormSite from '../FormSite';
import FormStripe from '../FormStripe';
import FormAddress from '../FormAddress';
import FormMap from '../FormMap';
import FormPortal from '../FormPortal';
import { ModalFooter } from 'components/Modal';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export const Forms = {
  FormSite,
  FormStripe,
  FormAddress,
  FormMap,
  FormPortal,
  FormSeo
};

const MainForm = ({
  goNextPage,
  handleClose,
  currentForm,
  handleSubmit,
  ...props
}) => (
  <form onSubmit={handleSubmit}>
    {React.createElement(Forms[currentForm], {
      handleClose,
      ...props
    })}
    <ModalFooter>
      <Button
        type="button"
        color="white"
        colorText="secondary"
        onClick={handleClose}
      >
        Cancelar
      </Button>
      <span className="h-flex__cell--grow" />
      {['FormSeo', 'FormPortal'].includes(currentForm) ? (
        <Button
          key="submit"
          type="submit"
          color="success"
          disabled={props.submitting}
        >
          Finalizar
        </Button>
      ) : (
        <Button key="button" type="button" color="success" onClick={goNextPage}>
          Próximo
        </Button>
      )}
    </ModalFooter>
  </form>
);

MainForm.defaultProps = defaultProps;
MainForm.propTypes = propTypes;

export default compose(
  reduxForm({
    form: 'PublicationWizard',
    enableReinitialize: true
  })
)(MainForm);
