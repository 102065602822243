import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
// Components
import FormBox from 'components/FormBox';
import Checkbox from 'components/Checkbox';
import Loading from 'components/Loading';

const propTypes = {};
const defaultProps = {};

const Establishments = ({ data: establishments, type, isFetching }) => (
  <FormBox title="Estabelecimentos">
    <Loading isBlock isVisible={isFetching} />

    <Row>
      {establishments &&
        establishments.map(establishment => (
          <Col
            key={`establishments-${establishment.id}`}
            xs={3}
            className="ListCheck__item"
          >
            <Field
              id={establishment.id}
              type="checkbox"
              name={`establishments.${establishment.id}`}
              className="h-margin-right--5"
              parse={value => {
                if (!value) return null;
                return establishment;
              }}
              component={Checkbox}
            >
              {establishment.title}
            </Field>
          </Col>
        ))}
    </Row>
  </FormBox>
);

Establishments.propTypes = propTypes;
Establishments.defaultProps = defaultProps;

export default Establishments;
