import { USER_PERMISSIONS } from 'constants/rules';
import { useCan } from 'hooks/useCan';
import InfosCell from './components/InfosCell';
import InviteInfoCell from './components/InviteInfoCell';
import OptionsCell from './components/OptionsCell';
import UserDataCell from './components/UserDataCell';
import { Table } from './styles';

function TableUsers({ invites }) {
  const canEditUser = useCan({
    run: USER_PERMISSIONS.EDIT_USER,
  });

  return (
    <Table
      renderTitle={() => (
        <tr>
          <th width={320}>
            Nome
            <br />
            Celular/WhatsApp
          </th>
          <th width={300}>
            Convidado por <br />
            Para o grupo
          </th>
          <th>
            Data do convite
            <br />
            Validade do convite
          </th>
        </tr>
      )}
      data={invites}
      renderItem={(invite) => {
        return (
          <tr key={invite.id}>
            <UserDataCell invite={invite} />
            <InfosCell invite={invite} />
            <InviteInfoCell invite={invite} />
            {canEditUser && (
              <OptionsCell invite={invite} canEditUser={canEditUser} />
            )}
          </tr>
        );
      }}
    />
  );
}

export default TableUsers;
