import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
// Helpers
import { imageChangeSize } from 'lib/image-helpers';

function DropimageOverlay({
  proportion,
  width,
  height,
  fileUrl,
  children,
  bgSize,
  imageSize = 'large',
}) {
  let style = {
    width,
    height,
  };

  if (proportion) {
    style = {
      ...style,
      paddingBottom: `${proportion * 100}%`,
    };
  }

  if (height) {
    style = {
      ...style,
      paddingBottom: 0,
    };
  }

  if (fileUrl) {
    style = {
      ...style,
      backgroundImage: `url(${imageChangeSize(fileUrl, imageSize, 'inside')})`,
      backgroundSize: bgSize,
    };
  }

  return (
    <div
      className={classnames('DropImage', {
        'DropImage--overlay h-hover': fileUrl,
      })}
      style={style}
    >
      <div
        className={classnames('DropImage__content', {
          'h-hover__show': fileUrl,
        })}
      >
        {children}
      </div>
    </div>
  );
}

DropimageOverlay.defaultProps = {
  fileUrl: null,
  children: null,
  bgSize: 'contain',
};

DropimageOverlay.propTypes = {
  fileUrl: PropTypes.string,
  children: PropTypes.node,
  bgSize: PropTypes.oneOf(['contain', 'cover']),
};

export default DropimageOverlay;
