export const getFullAddress = ({
  country,
  state,
  city,
  neighborhood,
  street_address,
  street_number
}) => {
  let address = '';

  if (country?.name) address += country?.name;
  if (state?.name) address += ' ' + state?.name;
  if (city?.name) address += ' ' + city?.name;
  if (neighborhood?.name) address += ' ' + neighborhood?.name;
  if (street_address) address += ' ' + street_address;
  if (street_number) address += ' ' + street_number;

  return address;
};
