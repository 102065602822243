import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row } from 'react-flexbox-grid';

const propTypes = {
  background: PropTypes.bool,
  children: PropTypes.node
};

const defaultProps = {
  background: false,
  children: null
};

export const OverlayRow = ({ children, position, className, ...props }) => (
  <Row
    {...props}
    className={classnames('Overlay__row', className, {
      [`Overlay__row--${position}`]: position
    })}
  >
    {children}
  </Row>
);

export const OverlayItem = ({ children, hide, show, ...props }) => (
  <Col
    {...props}
    className={classnames('Overlay__item', {
      'h-hover__hide': hide,
      'h-hover__show': show
    })}
  >
    {children}
  </Col>
);

const Overlay = ({ background, children, className, ...rest }) => (
  <div
    {...rest}
    className={classnames('Overlay', className, {
      'Overlay--background': background
    })}
  >
    {children}
  </div>
);

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;
Overlay.row = OverlayRow;
Overlay.item = OverlayItem;

export default Overlay;
