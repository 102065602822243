import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useRequest } from 'hooks/useRequest';
import { uploadFiles } from 'lib/reducer-helpers';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Actions as UploadActions } from 'modules/fileUpload';
import * as condosService from 'services/condos';
import { arrayMove } from 'react-sortable-hoc';

export const GalleryContext = createContext({});

export const GalleryProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { data, setData, fetchData } = useRequest({
    request: condosService.getImages,
    initialFetch: false,
  });

  const lastOrder = useMemo(() => {
    try {
      return data[data.length - 1].order;
    } catch {
      return 0;
    }
  }, [data]);

  const removeImage = useCallback(
    ({ id, gallery, condo_id }) =>
      () => {
        dispatch(
          openConfirmation({
            title: 'Deseja remover essa imagem?',
            request: () => condosService.removeImage(id, condo_id),
            onSuccess: () => {
              fetchData(condo_id, {
                filter: {
                  gallery,
                },
              });
            },
          })
        );
      },
    []
  );

  const removeAllImages = useCallback(
    ({ id }) =>
      () => {
        dispatch(
          openConfirmation({
            title: 'Deseja remover todas as imagens?',
            request: () => condosService.removeAllImages(id),
            onSuccess: () => fetchData(id),
          })
        );
      },
    []
  );

  const uploadImages = useCallback(
    ({ condoId, files }) => {
      const totalInGallery = data.length;
      const totalCanUpload = 50 - totalInGallery;

      // Coloca ordem em todos os arquivos
      const _filesWithOrder = files
        ?.slice(0, totalCanUpload)
        ?.map((file, index) => {
          return {
            file,
            order: index,
          };
        });

      return uploadFiles({
        request: condosService.addImage,
        params: ({ file, order }) => {
          return {
            condo_id: condoId,
            image: file,
            name: file.name,
            order: lastOrder + order + 1,
          };
        },
        files: _filesWithOrder,
        onUploadStart: () => {
          dispatch(UploadActions.uploadStart());

          _filesWithOrder.forEach(({ file }) => {
            dispatch(UploadActions.addFile(file));
          });
        },
        onUploadSuccess: (data, upload, { file }) => {
          return dispatch(UploadActions.uploadSuccess(file));
        },
        onUploadFail: (data, { file }) =>
          dispatch(UploadActions.uploadError(file)),
        onUploadEnd: async () => {
          await fetchData(condoId);
          dispatch(UploadActions.uploadEnd());
          dispatch(UploadActions.reset());
        },
      });
    },
    [lastOrder]
  );

  const updateImage = useCallback((currentImage) => {
    setData((images) => {
      return images.map((image) => {
        if (image.id === currentImage.id) {
          return currentImage;
        }

        return image;
      });
    });
  }, []);

  const handleSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setData((items) => {
      const newItems = arrayMove(items, oldIndex, newIndex);

      // pega a imagem para ser modificada
      const image = newItems.find((_, index) => [newIndex].indexOf(index) >= 0);

      condosService.alterImage({ ...image, order: newIndex + 1 });

      return newItems;
    });
  }, []);

  const handleEditCaption = useCallback((values) => {
    updateImage(values);
    condosService.alterImage(values);
  }, []);

  const handleEditCaptionSuccess = useCallback(() => {}, []);

  const setLoadingImage = useCallback(
    (id, value) => {
      setData((images) => {
        // Pega a imagem
        return images.map((image) => {
          if (image.id === id) {
            return { ...image, isLoading: value };
          }
          return image;
        });
      });
    },
    [data]
  );

  return (
    <GalleryContext.Provider
      value={{
        data,
        fetchData,
        removeImage,
        removeAllImages,
        uploadImages,
        handleSortEnd,
        handleEditCaption,
        handleEditCaptionSuccess,
        setLoadingImage,
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};

export const useGallery = () => useContext(GalleryContext);
