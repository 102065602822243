import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
// Modules
import { Actions, Selectors } from 'modules/formData';

export const withFormData = key => Component => {
  class withFormData extends React.Component {
    constructor(props) {
      super(props);

      // Registra as informações do formulário
      props.registerFormData(key);
    }

    componentWillUnmount() {
      this.props.unregisterFormData(key);
    }

    requestFormData = () => {
      this.props.requestFormData(key);
    };

    receiveFormData = data => {
      this.props.receiveFormData(key, data);
    };

    render() {
      const { formData, ...rest } = this.props;

      return (
        <Component
          {...rest}
          formData={formData}
          requestFormData={this.requestFormData}
          receiveFormData={this.receiveFormData}
        />
      );
    }
  }

  const mapStateToProps = state => ({
    formData: {
      data: Selectors.getData(state, key),
      isFetching: Selectors.getIsFetching(state, key)
    }
  });

  const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

  return compose(connect(mapStateToProps, mapDispatchToProps))(withFormData);
};
