import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Components
import { openModalProperty } from '../../../modules/modal';
import { ModalTemplate } from '../../../components/Modal';

const propTypes = {
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

const defaultProps = {
  handleSubmit: () => {}
};

class ModalPropertyPrint extends Component {
  handleClose = () => {
    this.props.openModalProperty({ property: this.props.property });
  };

  render() {
    const { modalConfig, isOpen, modalType, property } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={this.handleClose}
      >
        <ModalTemplate
          title={`Imprimir imóvel: ${property.reference}`}
          handleClose={this.handleClose}
        ></ModalTemplate>
      </Modal>
    );
  }
}

ModalPropertyPrint.propTypes = propTypes;
ModalPropertyPrint.defaultProps = defaultProps;

const mapDispatchToProps = { openModalProperty };

export default connect(null, mapDispatchToProps)(ModalPropertyPrint);
