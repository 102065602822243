import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Components
import { FileInput } from 'components/Form';
import Button from 'components/Button';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormUploadSlides = ({
  submit,
  handleSubmit,
  style,
  styleButtonCreateSlide,
  handleClickCreateSlide,
  handleClickSendSlide,
}) => (
  <form onSubmit={handleSubmit} style={{ display: 'inline-block', ...style }}>
    <Row>
      <Col xs={0}>
        <Button
          style={styleButtonCreateSlide}
          type="button"
          to="/site/content-site/slides/create"
          color="secondary"
          onClick={handleClickCreateSlide}
        >
          Criar Slide
        </Button>
      </Col>
      <Col xs={0}>
        <Field name="files" component={FileInput}>
          <Button
            type="button"
            color="white"
            colorText="secondary"
            onClick={handleClickSendSlide}
          >
            Enviar Slide
          </Button>
        </Field>
      </Col>
    </Row>
  </form>
);

FormUploadSlides.defaultProps = defaultProps;
FormUploadSlides.propTypes = propTypes;

export default reduxForm({
  form: 'FormUploadSlides',
  enableReinitialize: true,
})(FormUploadSlides);
