import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

export function createRoute(path, component, config = { exact: true }) {
  return { path, component, ...config };
}

function SwitchRoutes({ routes, children }) {
  return (
    <Switch>
      {routes && routes.map((route, i) => <Route key={i} {...route} />)}
      {children}
    </Switch>
  );
}

SwitchRoutes.defaultProps = {
  routes: []
};

SwitchRoutes.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired
    })
  )
};

export default SwitchRoutes;
