import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  //overflow: auto;
  //height: 534px;
  //width: calc(100% + 40px);
  //margin-left: -20px;
  //margin-top: -20px;
  //padding: 0 20px 20px;
  //margin-bottom: -19px;
`;
export const Header = styled.div`
  display: flex;
  background: #fff;
  justify-content: space-between;
  z-index: 15;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const Text = styled.div`
  font-size: 14px;
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  max-height: 460px;
  overflow: auto;
  padding: 20px;
  margin: 20px -20px -20px;
`;

export const Image = styled.div`
  position: relative;
  padding-bottom: 100%;
  cursor: pointer;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const overlayVisibility = ({ theme }) => {
  if (theme?.isSelected) {
    return null;
  }

  return css`
    visibility: hidden;
    opacity: 0;

    ${Image}:hover & {
      visibility: visible;
      opacity: 1;
    }
  `;
};

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: grid;
  place-items: center;

  ${overlayVisibility};
`;
