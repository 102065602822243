import Button from 'components/Button';
import { useDispatch } from 'react-redux';
// Modules
import { openModalPayment } from 'modules/modal';

function ContractButton() {
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(openModalPayment());
  };

  return (
    <Button color="secondary" onClick={openModal}>
      Gerar Pagamento
    </Button>
  );
}

export default ContractButton;
