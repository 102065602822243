import Button from 'components/Button';
import { ModalClose, ModalContent } from 'components/Modal';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { receiveUser } from 'modules/login';
import { closeModal } from 'modules/modal';
import qs from 'qs';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { updateMe } from 'services/user';
import confetti from './assets/confetti.svg';
import gift from './assets/gift.svg';
import Alert from 'react-s-alert';
import {
  Bonus,
  Confetti,
  Content,
  DisableTag,
  Gift,
  Heading,
  Highlight,
  Split,
  Title,
} from './styles';

export const getInviteMessage = (userId) => {
  return `Olá colega corretor, gostaria de recomendar o Tecimob. Este criador de sites é perfeito para quem quer ter um site profissional integrado ao WhatsApp e com um CRM completo, por apenas R$100 por mês. O Tecimob é fácil de usar, e usando este o link abaixo, vais poder testar 7 dias grátis e ainda receberá R$50 em bônus caso decida contratar. Não perca esta oportunidade de impulsionar seu negócio imobiliário!

https://tecimob.com.br/modelos/?channel=${userId}`;
};

function ModalRecommendation({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
}) {
  const currentUser = useCurrentUser();

  const shareToWhatsapp = () => {
    const query = qs.stringify({
      text: getInviteMessage(currentUser?.id),
    });

    window.open(`https://api.whatsapp.com/send?${query}`, '_blank').focus();
  };

  const dispatch = useDispatch();

  const seeMyRecommendations = () => {
    dispatch(closeModal());
  };

  const handleToggleTag = () => {
    const nextState = !currentUser?.recommendation_button_show;
    try {
      updateMe({ recommendation_button_show: nextState });
      dispatch(
        receiveUser({
          ...currentUser,
          recommendation_button_show: nextState,
        })
      );
      Alert.success(
        `Tag lateral ${nextState ? 'ativada' : 'desativada'} com sucesso!`
      );
    } catch (error) {
      Alert.error(`Erro ao ${nextState ? 'ativar' : 'desativar'} tag lateral.`);
    }
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <div className="Modal__wrapper">
        <ModalClose handleClose={handleClose} />
        <Confetti src={confetti} alt="Confete" />
        <ModalContent>
          <Split>
            <Heading>
              <Gift src={gift} alt="Presente" />
              <Title>
                Quer ganhar <Bonus>R$100,00</Bonus>
                <br />
                de presente depositado
                <br />
                diretamente no seu PIX?
              </Title>
              <DisableTag className="h-link" onClick={handleToggleTag}>
                {currentUser?.recommendation_button_show
                  ? 'Desativar tag lateral'
                  : 'Ativar tag lateral'}
              </DisableTag>
            </Heading>
            <Content>
              <p>
                <Highlight>O que preciso fazer para ganhar?</Highlight>
                <br />É simples! Nos ajude a conquistar um novo cliente!
              </p>
              <p>
                Se você gosta do nosso trabalho, nos recomende através da sua
                rede de relacionamentos.
              </p>
              <p>
                Clique no botão <Highlight>”Recomendar”</Highlight> e selecione
                no whatsapp o seu amigo corretor ou grupo que você participa.
              </p>
              <p>
                Através desse link, saberemos que ele se cadastrou por sua
                recomendação. Assim que ele fizer seu primeiro pagamento, se
                tornando nosso cliente, vamos depositar{' '}
                <Highlight>R$100,00</Highlight> diretamente no seu PIX, e pra
                ele daremos <Highlight>R$50,00</Highlight> reais de bônus, em
                créditos no Tecimob.
              </p>
              <p>
                Não existe nenhum limite de recomendações, quanto mais, melhor!
                Bora começar?
              </p>

              <div className="h-margin-top--15">
                <Button color="success" onClick={shareToWhatsapp}>
                  Recomendar Tecimob
                </Button>
                <Button
                  color="secondary"
                  to="/recommendations"
                  onClick={seeMyRecommendations}
                >
                  Ver minhas recomendações
                </Button>
              </div>
            </Content>
          </Split>
        </ModalContent>
      </div>
    </Modal>
  );
}

export default ModalRecommendation;
