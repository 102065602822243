import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import _ from 'lodash';
import Loading from '../Loading';
import Item from './Item';
import { ListCheckCollum } from './styles';

const defaultProps = {
  itemsCol: {
    xs: 3,
  },
  name: null,
  data: [],
  handleDeleteItem: null,
  className: {
    wrapper: 'ListCheck',
    item: 'ListCheck__item',
  },
  params: {},
  isFetching: false,
};

const propTypes = {
  itemsCol: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
  }),
  name: PropTypes.string.isRequired,
  handleDeleteItem: PropTypes.func,
  className: PropTypes.object,
  params: PropTypes.object,
  isFetching: PropTypes.bool,
};

function ListCheck({
  cols = null,
  data,
  name,
  params,
  className,
  isFetching,
  itemsCol,
  handleDeleteItem,
}) {
  if (isFetching) {
    return <Loading isVisible={isFetching} />;
  }

  if (cols) {
    return (
      <ListCheckCollum className={`${className.wrapper}`}>
        {_.chunk(data, Math.ceil(data.length / cols)).map((collum, i) => {
          return (
            <Col key={name + i} xs={parseInt(12 / cols, 10)}>
              {collum.map((item) => (
                <Item
                  values={item}
                  className={className}
                  name={name}
                  handleDeleteItem={handleDeleteItem}
                  params={params}
                />
              ))}
            </Col>
          );
        })}
      </ListCheckCollum>
    );
  }

  return (
    <Row className={className.wrapper}>
      {data.map((item) => {
        return (
          <Item
            values={item}
            className={className}
            name={name}
            itemsCol={itemsCol}
            handleDeleteItem={handleDeleteItem}
            params={params}
          />
        );
      })}
    </Row>
  );
}

ListCheck.defaultProps = defaultProps;
ListCheck.propTypes = propTypes;

export default memo(ListCheck);
