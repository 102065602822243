import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,

  className: PropTypes.string,
  activeClassName: PropTypes.string
};

const defaultProps = {
  to: null,
  children: null,
  activeClassName: 'TreeNav__item--is-active',
  className: 'TreeNav__item'
};

const TreeNavLink = ({ children, ...rest }) => (
  <NavLink {...rest}>{children}</NavLink>
);

TreeNavLink.propTypes = propTypes;
TreeNavLink.defaultProps = defaultProps;

export default TreeNavLink;
