import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
// Services
import * as dealsService from 'services/deals';
// Components
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
import Container from 'components/Container';
import Divider from 'components/Divider';
import Popover from 'components/Popover';
import FormStageAdd from 'components/FormStageAdd';
import StagesDraggable from 'components/StagesDraggable';

import { openModalDealDelete } from 'modules/modal';
import {
  getStages,
  handleAddStage,
  handleDragEndStage,
  handleRemoveStage,
  handleUpdateStage,
  isFetchingSelector,
  resetPipeline,
  stagesSelector,
} from 'modules/pipeline';

const propTypes = {
  getStages: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  openModalDealDelete: PropTypes.func.isRequired
};
const defaultProps = {};

class ConfigFunnel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: {}
    };

    this.currentUser = localStorage.getItem('current-user');
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.handleDeleteFunnel = this.handleDeleteFunnel.bind(this);
    this.redirectToDefaultFunnel = this.redirectToDefaultFunnel.bind(this);
  }

  get funnelId() {
    return this?.currentUser?.default_crm_stage?.funnel?.id;
  }

  componentDidMount() {
    this.props.getStages({ id: this.funnelId });
    this.updateFormStagnant();
  }

  componentWillUnmount() {
    this.props.resetPipeline();
  }

  redirectToDefaultFunnel() {
    this.props.history.push(
      `/crm/show/${this.currentUser.default_crm_stage.funnel.id}`
    );
  }

  /**
   * Atualiza as informacoes do formulario de estagnado
   */
  updateFormStagnant() {
    dealsService
      .getFunnel(this.funnelId)
      .then(({ data: funnel }) => {
        this.setState({ initialValues: funnel });
      })
      .catch(() => {
        Alert.error('Esse funil não existe mais!');
        this.redirectToDefaultFunnel();
      });
  }

  handleEditSubmit(values) {
    return dealsService
      .updateFunnel({
        id: this.funnelId,
        ...values
      })
      .then(({ data: funnel }) => {
        return funnel;
      });
  }

  /**
   * Deleta o funil que está sendo editado
   */
  handleDeleteFunnel() {
    return dealsService
      .deleteFunnel(this.funnelId)
      .then(() => this.redirectToDefaultFunnel());
  }

  render() {
    const {
      isLoading,
      stages,
      handleRemoveStage,
      handleUpdateStage,
      handleDragEndStage
    } = this.props;

    // verifica se pode renderizar o botao de excluir funil
    const canRenderDeleteFunnel =
      this.currentUser.default_crm_stage.funnel.id !== this.funnelId;

    return (
      <Wrapper>
        <Helmet>
          <title>Configuração Funil</title>
        </Helmet>
        <MainTitle
          title="Configurar Funil"
          text="Crie etapas simples, baseadas nas atividades do seu dia a dia de trabalho."
        >
          <Popover
            onSubmit={values => {
              this.props.handleAddStage(this.funnelId, values);
            }}
            component={FormStageAdd}
          >
            <Button type="button" color="secondary">
              Adicionar Etapa
            </Button>
          </Popover>
        </MainTitle>
        <Container>
          <StagesDraggable
            funnelId={this.funnelId}
            isLoading={isLoading}
            stages={stages}
            handleRemoveStage={handleRemoveStage}
            handleUpdateStage={handleUpdateStage}
            handleDragEnd={handleDragEndStage}
          />

          <h5 className="h-margin-bottom--10">Dicas</h5>
          <p className="h-margin-bottom--5">
            Crie etapas simples, baseadas nas atividades do seu dia a dia de
            trabalho;
          </p>
          <p className="h-margin-bottom--5">
            Para ordenar as etapas, arraste-as para o local desejado. Caso
            queira adicionar outras etapas, clique em Adicionar Etapa;
          </p>
          <p className="h-margin-bottom--5">
            Para renomear ou apagar uma etapa, apenas clique no nome dela;
          </p>
          <p className="h-margin-bottom--5">
            Não crie etapas para ‘Ganho’ ou ‘Perdido’, essas ações já existem e
            você pode encontrá-las arrastando um negócio.
          </p>

          {/*<div style={{ width: 320 }}>*/}
          {/*<FormStagnant*/}
          {/*initialValues={this.state.initialValues}*/}
          {/*onSubmit={this.handleEditSubmit}*/}
          {/*/>*/}
          {/*</div>*/}

          {canRenderDeleteFunnel && (
            <React.Fragment>
              <Divider borderStyle="dashed" spaceX={15} />
              <Button onClick={this.handleDeleteFunnel} color="danger">
                Excluir Funil
              </Button>
            </React.Fragment>
          )}
        </Container>
      </Wrapper>
    );
  }
}

ConfigFunnel.propTypes = propTypes;
ConfigFunnel.defaultProps = defaultProps;

const mapStateToProps = state => ({
  stages: stagesSelector(state),
  isLoading: isFetchingSelector(state)
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      getStages,
      openModalDealDelete,
      resetPipeline,
      handleAddStage,
      handleRemoveStage,
      handleDragEndStage,
      handleUpdateStage
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigFunnel);
