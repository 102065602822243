import React, { useMemo } from 'react';
import { Label } from 'containers/ModalRoot/ModalProperty/pages/Record/components/RoomsAndMeasures/styles';
import Value from 'containers/ModalRoot/ModalProperty/pages/Record/components/RoomsAndMeasures/components/Value';
import { MdInfo } from 'react-icons/md';
import _ from 'lodash';

// import { Wrapper } from './styles';

function PricePerArea({ property }) {
  const totalArea = property?.prices_per_area?.total_area;

  function removeTotalArea(item, key) {
    if (key === 'total_area') return false;
    return true;
  }

  function renderTip(item) {
    return `
        <tr>
          <td align="right">${item?.title}:</td>
          <td>R$ ${item?.price}</td>
        </tr>
      `;
  }

  const tip = useMemo(() => {
    let _tip = `Preços por m²<table>`;

    const items = _.chain(property?.prices_per_area)
      .filter(removeTotalArea)
      .map(renderTip)
      .value();

    _tip += items.concat();

    _tip += `</table>`;

    return _tip;
  }, [property]);

  return (
    <tr>
      <Label>Preço m²:</Label>
      <Value>
        {totalArea?.price ? `R$ ${totalArea?.price}` : '--'}{' '}
        <MdInfo data-tip={tip} />
      </Value>
    </tr>
  );
}

export default PricePerArea;
