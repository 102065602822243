import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { WrapperProperties } from '../../styles';
import PropertyItem from './PropertyItem';

export default SortableContainer(
  ({ handleClickRemove, isDragging, properties, ...props }) => {
    const canDelete = properties?.length > 2;

    return (
      <WrapperProperties
        isDragging={isDragging}
        className="js-properties-wrapper"
      >
        {properties?.map((property, index) => (
          <PropertyItem
            canDelete={canDelete}
            isFocused={property.isFocused}
            className={`js-property-${property.id}`}
            key={`item-${property.id}`}
            lol={index}
            index={index}
            property={property}
            handleClickRemove={handleClickRemove}
          />
        ))}
      </WrapperProperties>
    );
  }
);
