import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { change, Field, formValueSelector } from 'redux-form';
// Service
import * as locationService from 'services/location';
// Modules
import { Actions, Selectors } from '../module';
// HOC
import { withFormName } from 'HOC/withFormName';
// Constants
import { defaultPropsLocation, propTypesLocation } from '../constants';

class CountryField extends React.Component {
  static propTypes = {
    ...propTypesLocation,
    canUnregister: PropTypes.bool.isRequired,
    stateName: PropTypes.string.isRequired,
    cityName: PropTypes.string.isRequired,
    neighborhoodName: PropTypes.string.isRequired,
    canClearValues: PropTypes.bool.isRequired
  };

  static defaultProps = {
    ...defaultPropsLocation,
    canUnregister: true,
    label: 'País',
    name: 'country_id',
    stateName: 'state_id',
    cityName: 'city_id',
    neighborhoodName: 'neighborhood_id',
    canClearValues: true
  };

  constructor(props) {
    super(props);

    // Registra o pais na store do redux
    props.register(props.formName, props.name);
  }

  request = () => this.props.request(this.props.formName, this.props.name);
  receive = data =>
    this.props.receive(this.props.formName, this.props.name, data);
  clear = fieldKey => this.props.clear(this.props.formName, fieldKey);
  change = (fieldName, value) =>
    this.props.change(this.props.formName, fieldName, value);

  clearFields = () => {
    const { stateName, cityName, neighborhoodName } = this.props;

    this.clear([stateName, cityName, neighborhoodName]);

    this.change(stateName, null);
    this.change(cityName, null);
    this.change(neighborhoodName, null);
  };

  /**
   * Busca os países
   * @param args
   * @return {Promise}
   */
  fetchCountries = async (...args) => {
    this.request();
    const { data } = await locationService.getCountries(...args);
    this.receive(data);
  };

  componentWillUnmount() {
    const { name } = this.props;
    if (this.props.canUnregister) {
      // Remove a chave do pais lá da store de localização
      this.props.unregister(this.props.formName, name);
    }
  }

  handleChange = (e, nextValue) => {
    const { canClearValues, onChange, value } = this.props;

    // Executa o evento de onChange caso tenha sido enviado
    // Caso contrário não faz absolutamente nada
    if (onChange) onChange(e, nextValue);

    // Limpa os campos se puder
    if (canClearValues && nextValue !== value) {
      this.clearFields();
    }
  };

  render() {
    const { onChange, ...props } = this.props;

    return <Field onChange={this.handleChange} {...props} />;
  }

  componentDidMount() {
    this.fetchCountries();
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);
  return {
    value: selector(state, ownProps.name),
    options: Selectors.getData(ownProps.formName, ownProps.name)(state),
    isLoading: Selectors.getIsFetching(ownProps.formName, ownProps.name)(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...Actions, change }, dispatch);

export default compose(
  withFormName,
  connect(mapStateToProps, mapDispatchToProps)
)(CountryField);
