const { default: styled } = require("styled-components");

export const Wrapper = styled.div`
  width: calc(100% + 39px);
  padding-top: 15px;
  margin-top: -15px;
  margin-left: -19px;
  margin-bottom: 25px;

  .Table tr th,
  .Table tr td {
    background: transparent;
    padding-left: 20px;
    padding-right: 10px;
  }

  h4 {
    margin-left: 20px;
  }

  &:nth-child(even) {
    background: #f1f1f1;
  }
`;
