import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
// Components
import { Input } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

function FormRd({ handleClose, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row style={{ marginBottom: '-15px' }}>
        <Field xs={6} label="Client ID" name="client_id" component={Input} />
        <Field
          xs={6}
          label="Client Secret"
          name="client_secret"
          component={Input}
        />
      </Row>
      <ModalFooter>
        <Button type="button" color="white" colorText="secondary">
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Conectar
        </Button>
      </ModalFooter>
    </form>
  );
}

FormRd.defaultProps = {};
FormRd.propTypes = {};

export default reduxForm({
  form: 'FormRd',
  enableReinitialize: true
})(FormRd);
