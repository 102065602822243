import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: 0 -5px;
`;

export const Item = styled.div`
  padding: 0 5px;
`;

export const Number = styled.div`
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  background-color: ${p => p.theme.colors.primary};
`;
