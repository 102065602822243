import React, { useEffect } from 'react';
import Modal from 'react-modal';
import Alert from 'react-s-alert';

// Constants
import { PROPERTY_PERMISSIONS } from 'constants/rules';

// Components
import { ModalTemplate } from 'components/Modal';
import Can from 'containers/Can';
import Header from './components/Header';
import Nodata from 'components/Nodata';
import Form from './components/Form';

// Services
import * as networkPropertyService from 'services/network-property';

// Images
import house from 'assets/img/noData/house.svg';
import { useRequest } from 'hooks/useRequest';

function ModalPropertyPublication({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  property,
  onSubmitSuccess,
}) {
  const {
    data: initialValues,
    isFetching,
    fetchData,
  } = useRequest({
    request: networkPropertyService.getOne,
    initialFetch: false,
  });

  const handleSubmit = (values) => {
    return networkPropertyService
      .update({ ...values, property_id: property.id })
      .then(() => {
        handleClose();
      });
  };

  useEffect(() => {
    if (property.id) {
      fetchData(property.id);
    }
  }, [property, fetchData]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
      style={{
        maxWidth: 'auto',
      }}
    >
      <ModalTemplate
        isLoading={isFetching}
        handleClose={handleClose}
        renderHeader={() => <Header property={property} />}
      >
        <Can
          run={PROPERTY_PERMISSIONS.PUBLISH}
          permissions={property.permissions}
          yes={() => (
            <Form
              property={property}
              initialValues={{
                ...initialValues,
                id: initialValues.property_id,
              }}
              onSubmit={handleSubmit}
              onSubmitSuccess={() => {
                if (onSubmitSuccess) onSubmitSuccess();
                Alert.success('Imóvel publicado');
              }}
              handleClose={handleClose}
            />
          )}
          no={() => (
            <>
              <Nodata
                image={house}
                title="Voce não tem permissão para publicar esse imóvel"
                text={
                  <>
                    Essa área não está liberada nas suas permissões.
                    <br />
                    Solicite que o administrador do sistema libere o acesso.
                  </>
                }
              />
            </>
          )}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalPropertyPublication;
