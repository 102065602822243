import React from 'react';
import SelectOption from 'components/SelectOption';

function Option({ option, ...props }) {
  return (
    <SelectOption {...props} option={option}>
      <div style={{ fontFamily: option.family }}>{option.family}</div>
    </SelectOption>
  );
}

export default Option;
