import { withValidation } from 'lib/validate-helpers';

export default (values, ...args) => {
  const { errors, validations } = withValidation(values, ...args);

  validations.required([
    'name',
    'email',
    'address_cep',
    'state_id',
    'city_id',
    'neighborhood_id',
    'address_number',
    'address_number'
  ]);
  //if (values.phones) {
  //  validations.requiredArrayField('phones', ['operadora', 'phone']);
  //}

  return errors;
};
