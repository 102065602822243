import React from 'react';
import classnames from 'classnames';
import TableSort, { CollumHeader } from 'components/TableSort';
import { openModalPerson, openModalProperty } from 'modules/modal';
import { useDispatch } from 'react-redux';

// import { Wrapper } from './styles';

const getReference = (reference) => {
  return reference.replace(/-removido-.*/, '');
};

function TablePeople({ data, meta, fetchData }) {
  const dispatch = useDispatch();

  const openPerson = (person, params) => (e) => {
    e.stopPropagation();
    dispatch(openModalPerson({ ...person, afterClose: fetchData }, params));
  };

  return (
    <TableSort
      data={data}
      meta={meta}
      fetchData={fetchData}
      renderHeader={() => (
        <tr>
          <CollumHeader
            align="left"
            filterBy="by_person_name"
            style={{ width: 240 }}
          >
            Cliente
          </CollumHeader>
          <CollumHeader
            style={{ width: 160 }}
            align="center"
            filterBy="by_person_interacted_at"
          >
            Última interação
          </CollumHeader>
          <CollumHeader
            style={{ width: 160 }}
            align="center"
            filterBy="updated_at"
          >
            Data encerrado
          </CollumHeader>
          <th style={{ width: 125 }} align="left">
            Etapa
          </th>
          <th style={{ width: 125 }} align="left">
            Imóvel
          </th>
          <CollumHeader align="left" filterBy="status">
            Motivo
          </CollumHeader>
        </tr>
      )}
      renderItem={(data) => {
        const isRemoved = data.property_reference.includes('-removido-');

        return (
          <tr
            className="h-pointer"
            onClick={openPerson(
              { id: data.person_id },
              {
                initialPage: 'Properties',
                pageProperties: 'PropertiesNewMatched',
              }
            )}
          >
            <td align="left">{data?.person_name}</td>
            <td align="center">{data?.person_interacted_at}</td>
            <td align="center">{data?.updated_at}</td>
            <td align="left">{data?.stage_name}</td>
            <td
              align="left"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (isRemoved) return false;

                dispatch(
                  openModalProperty({ property: { id: data?.property_id } })
                );
              }}
            >
              <span className={isRemoved ? '' : 'h-link'}>
                {getReference(data?.property_reference)}
              </span>
            </td>
            <td align="left">
              <span
                className={classnames({
                  'h-color--danger': data.status === 'Negócio perdido',
                  'h-color--success': data.status === 'Negócio ganho',
                })}
              >
                {data.status}
              </span>
            </td>
          </tr>
        );
      }}
    />
  );
}

export default TablePeople;
