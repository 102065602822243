import React from 'react';
// Styless
import { Apps, Content, Phone, Text, Title, Wrapper } from './styles';
// Assets
import phone from './iphone.png';
import googlePlayImage from './google-play.png';
import appStoreImage from './app-store.png';

export default function App() {
  return (
    <Wrapper>
      <Content>
        <Title>Atenda seus clientes de onde estiver</Title>
        <Text>
          Selecione os imóveis que interessarem ao seu cliente, faça anotações e
          compartilhe facilmente pelo WhatsApp.
        </Text>
        <Apps>
          <a
            href="https://play.google.com/store/apps/details?id=com.tecimob_app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googlePlayImage} alt="Tecimob - Google Play" />
          </a>
          <a
            href="https://apps.apple.com/us/app/tecimob/id1486403299"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appStoreImage} alt="Tecimob - AppStore" />
          </a>
        </Apps>
      </Content>
      <Phone src={phone} alt="Telefone Celular" />
    </Wrapper>
  );
}
