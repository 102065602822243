import React, { useEffect, useState } from 'react';
import Tooltip from 'react-tooltip';
import { getFirstName } from 'lib/text';
import { MdPerson } from 'react-icons/md';

// import from './styles';

const COLORS = {
  primary: '#0088FE',
  success: '#00C49F',
  danger: '#FFBB28'
};

function PersonTooltip({ person, currentUser }) {
  const [tooltip, setTooltip] = useState(null);

  useEffect(() => {
    Tooltip.rebuild();
  }, [tooltip]);

  useEffect(() => {
    function renderTooltip() {
      if (!person.user_id)
        return setTooltip({
          text: 'Sem corretor responsável',
          color: COLORS.danger
        });

      if (person.user_id === currentUser.id) {
        return setTooltip({
          text: 'Meu cliente',
          color: COLORS.success
        });
      }

      setTooltip({
        text: `Cliente do: ${getFirstName(person?.user?.name)}`,
        color: COLORS.primary
      });
    }

    renderTooltip();
  }, [person]);

  if (!tooltip) return null;

  return (
    <MdPerson
      color={tooltip.color}
      data-tip={tooltip.text}
      data-place="right"
    />
  );
}

PersonTooltip.propTypes = {};
PersonTooltip.defaultProps = {};

export default PersonTooltip;
