import Container from 'components/Container';
import MainTitle from 'components/MainTitle';
import FieldSelectPeople from 'containers/FieldPerson';
import FieldSelectUser from 'containers/FieldSelectUser';
import FieldSituation from 'containers/FieldSituation';
import { useCallback, useEffect, useMemo } from 'react';
import { Row } from 'react-flexbox-grid';
import Tooltip from 'react-tooltip';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withId } from '../../HOC/withId';
import * as Fields from './fields';

// Hooks
import { FieldBool } from 'components/Form';
import useFormValue from 'hooks/useFormValue';

// Services
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import Loading from 'components/Loading';
import FieldCondosAsync from 'containers/FieldCondosAsync';
import { useRequest } from 'hooks/useRequest';
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import { finishResponse } from 'pages/PropertyStore/helpers';
import { MdInfo } from 'react-icons/md';
import { useParams } from 'react-router';
import * as propertiesService from 'services/properties';
import * as initialInformationsService from 'services/propertiesPartial/initialInformations';
import * as typesService from 'services/types';
import validate from './validate';

const Informations = ({
  hide,
  show,
  handleSubmit,
  initialize,
  onSubmit,
  submitting,
}) => {
  const { data: types, isFetching: isFetchingType } = useRequest({
    request: typesService.getTypesAndSubtypes,
  });

  const { id } = useParams();
  const typeId = useFormValue('type_id');
  const subtypeId = useFormValue('subtype_id');
  const isCondo = useFormValue('is_condo');

  const subtype = useMemo(() => {
    return types.find(({ options }) => {
      return options.find(({ value }) => {
        return value === subtypeId?.value || value === subtypeId;
      });
    });
  }, [types, subtypeId]);

  const getInitialValues = useCallback(async () => {
    let initialValues = {
      is_property_titled: false,
      is_deeded: false,
      is_corner: false,
      has_furniture: false,
      is_condo: false,
    };

    const {
      data: { reference },
    } = await propertiesService.getReference();

    if (reference) {
      initialValues = { ...initialValues, reference };
    }

    // Pega o usuário que está logado
    const currentUser = localStorage.getItem('current-user');

    if (currentUser?.realtor) {
      initialValues = { ...initialValues, user_id: currentUser?.id };
    }

    initialize(initialValues);
  });

  const initializeForm = useCallback(async () => {
    const { data } = await initialInformationsService.getOne(id);

    let newValues = {
      is_condo: false,
      ...data,
    };

    if (data.condominium_id) {
      newValues = {
        is_condo: true,
        ...data,
      };
    }

    initialize(newValues);
  }, [initialize, id]);

  useEffect(() => {
    Tooltip.rebuild();
    // Busca dados somente se tiver id na url
    if (id) {
      initializeForm();
    } else {
      getInitialValues();
    }
  }, [id]);

  useEffect(() => {
    if (subtype?.rooms_fields?.length <= 0) {
      hide('Cômodos');
    } else {
      show('Cômodos');
    }
  }, [subtype]);

  const onAddCondo = () => {
    window.open('/condos/store', '_blank');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle
        title="Informações iniciais"
        text="Defina as informações com precisão para os seus clientes."
      />
      <Container>
        <Row>
          <Fields.Reference />
          <Fields.IsCondo />
          {isCondo && (
            <FieldCondosAsync
              reloadOnOpen
              xs={6}
              label="Condomínio/empreendimento"
              creatable
              onNewOptionClick={onAddCondo}
              required
            />
          )}
        </Row>
        <Row>
          <FieldSelectPeople
            checkGroupOwner
            isPrivate
            xs={3}
            label={
              <>
                Proprietário<span className="h-color--danger"> *</span>{' '}
                (privado){' '}
                <MdInfo
                  className="h-color--secondary"
                  data-tip="O proprietário não será mostrado em seu site"
                />
              </>
            }
            name="people_id"
          />
          <FieldSelectUser
            required
            xs={3}
            label="Corretor Responsável"
            name="user_id"
          />
          <FieldSelectUser
            params={{
              filter: {
                realtor: null,
              },
            }}
            xs={3}
            label="Agenciador"
            name="agent_id"
          />
          <Fields.Subtype isFetching={isFetchingType} options={types} />
          <Fields.Profile />
          <FieldSituation
            xs={3}
            typeId={subtype?.type_id || typeId}
            multiSelect={false}
          />
          <Fields.YearBuilding />
          <Fields.Incorporation />
          <Fields.CondoPosition subtype={subtype} />
          <Fields.SolarPosition />
          <Fields.LandType />
          <Fields.NearSea />
          <Fields.DeliveryForecast />
          <Fields.TypeFields fields={subtype?.information_fields} />

          <FieldBool xs={3} label="Averbado" name="is_property_titled" />
          <FieldBool xs={3} label="Escriturado" name="is_deeded" />
          <FieldBool xs={3} label="Esquina" name="is_corner" />
          <FieldBool
            canRemoveSelf
            xs={3}
            label="Tem mobília"
            name="has_furniture"
          />
        </Row>
      </Container>
      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyInformations',
    validate,
    onSubmit: (values, dispatch, props) => {
      // Se não tiver ID
      if (!props?.id) {
        return initialInformationsService.create(values);
      }

      return initialInformationsService
        .update(values)
        .then(finishResponse(values, dispatch, props));
    },
    onSubmitSuccess: (response, dispatch, p) => {
      let newValues = {
        is_condo: false,
        ...response?.data,
      };
      if (response?.data?.condominium_id) {
        newValues = {
          ...response.data,
          is_condo: true,
        };
      }

      p.initialize(newValues);

      if (!p.isEditting) {
        p.enableAll();
        p.setId(newValues.id);
        p.next('rooms', newValues.id);
      }
    },
  }),
  withFormConfirmation()
)(Informations);
