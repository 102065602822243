import React from 'react';
import Modal from 'react-modal';
// Components
import Button from 'components/Button';
import ModalTemplate from 'components/Modal/components/ModalTemplate';
// Assets
import imgSearch from 'assets/img/noData/search-empty.svg';

const propTypes = {};
const defaultProps = {};

const PersonRegistered = ({
  person,
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  meta,

  handleClickAction
}) => (
  <Modal
    {...modalConfig}
    className={{
      base: 'Modal Modal--small Modal--center',
      afterOpen: 'Modal--after-open',
      beforeClose: 'Modal--before-close'
    }}
    overlayClassName={{
      base: `ModalOverlay ModalOverlay--modal-center`,
      afterOpen: 'ModalOverlay--after-open',
      beforeClose: 'ModalOverlay--before-close'
    }}
    isOpen={isOpen}
    contentLabel={modalType}
    onRequestClose={handleClose}
  >
    <ModalTemplate
      isLoading={meta.isFetching}
      handleClose={handleClose}
      title="Esse cliente já possui cadastro!"
      style={{ width: 400, margin: '0 auto' }}
    >
      <div className="h-flex h-flex--center-center">
        <div className="h-flex__cell h-flex__cell--shrink">
          <img src={imgSearch} alt="" />
        </div>
        <div className="h-flex__cell--grow">
          <div className="h-padding-left--15">
            <p className="h-color--primary h-margin-bottom--5">
              <strong>Nome: {person.name}</strong>
            </p>
            <p className="h-color--primary h-margin-bottom--5">
              <strong>
                {person.user
                  ? `Corretor responsável: ${person.user.name}`
                  : 'Sem corretor responsável'}
              </strong>
            </p>

            <Button
              color="secondary"
              size="medium"
              onClick={handleClickAction(person)}
            >
              Ver Vida do Cliente
            </Button>
          </div>
        </div>
      </div>
    </ModalTemplate>
  </Modal>
);

PersonRegistered.propTypes = propTypes;
PersonRegistered.defaultProps = defaultProps;

export default PersonRegistered;
