import { Field } from 'redux-form';
import { Selector } from './components/Selector';
import { ModalIcons } from './components/ModalIcons';
import { useGetIcons } from 'features/Financial/hooks/useIcons';
import { useEffect, useMemo, useState } from 'react';

function IconSelector({ input, meta }) {
  const [isOpen, setIsOpen] = useState(false);

  const { icons, getIconById } = useGetIcons();

  const icon = useMemo(() => {
    try {
      return getIconById(input.value);
    } catch {
      return null;
    }
  }, [icons, input]);

  useEffect(() => {
    if (!input.value && icons.length > 0) {
      input.onChange(icons?.[0]?.id);
    }
  }, [input, icons]);

  return (
    <>
      <Selector imageUrl={icon?.file_url} onClick={() => setIsOpen(true)} />
      <ModalIcons
        isOpen={isOpen}
        icons={icons}
        onClose={() => setIsOpen(false)}
        onSelect={(icon) => {
          input.onChange(icon.id);
          setIsOpen(false);
        }}
      />
    </>
  );
}

export function FieldIcon({ name = 'icon_id' }) {
  return <Field name={name} component={IconSelector} />;
}
