import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdArrowDropDown } from 'react-icons/md';
// Modules
import { fetchData, removeScript, scriptsSelector } from './module';
import { openConfirmation } from 'containers/ModalConfirmation/module';
// Components
import Button from 'components/Button';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Container from 'components/Container';
import Table from 'components/Table';
import Dropdown, { Item, List } from 'components/Dropdown';
// Assets
import code from 'assets/img/noData/coding.svg';
import ReactToggle from 'react-toggle';
import { updateActiveScript } from 'services/scripts';

const ScriptsPages = ({ fetchData, data, removeScript, openConfirmation }) => {
  const [activeStates, setActiveStates] = useState(false);

  useEffect(() => {
    // Equivalent to componentDidMount
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const newActiveStates = {};
    if (data) {
      data.forEach(script => {
        newActiveStates[script.id] = script.active || false;
      });
      setActiveStates(newActiveStates);
    }
  }, [data]);

  const handleClickRemoveScript = (script) => () => {
    openConfirmation({
      title: 'Deseja realmente remover o script?',
      request: () => {
        removeScript(script);
      },
    });
  };

  const onChangeStatus = (script) => {
    const newActiveStates = {
      ...activeStates,
      [script.id]: !activeStates[script.id],
    };

    setActiveStates(newActiveStates);

    updateActiveScript(newActiveStates[script.id], script.id);
  };

  return (
    <Wrapper.container>
      <MainTitle
        title="Códigos e Scripts"
        text="Adicione códigos e scripts de terceiros em seu site."
      >
        <Button to="/site/config/scripts/create" color="secondary">
          Adicionar código
        </Button>
      </MainTitle>
      <Container padding>
        <Table
          noData={{
            image: code,
            title: 'Nenhum Script adicionado',
            text: 'Você não adicionou nenhum código.',
          }}
          data={data}
          isFetching={false}
          renderTitle={() => (
            <tr>
              <th>Nome</th>
              <th>Posição</th>
              <th>Local</th>
              <th />
            </tr>
          )}
          renderItem={(script) => (
            <tr key={script.id}>
              <td>{script.name}</td>
              <td>{script.positionName}</td>
              <td>{script.locationName}</td>
              <td>
                <div>
                  <ReactToggle
                    checked={activeStates[script.id] || false}
                    className={`react-toggle--medium h-margin-right--10`}
                    onChange={() => onChangeStatus(script)}
                    icons={false}
                  />
                  { activeStates[script.id] ? 'Ativado' : 'Inativado' }
                </div>
              </td>
              <td className="Table__cell--small">
                <Dropdown isDropHover>
                  <Button size="medium" color="white" colorText="secondary">
                    Opções <MdArrowDropDown />
                  </Button>
                  <List>
                    <Item>
                      <Link to={`/site/config/scripts/update/${script.id}`}>
                        Editar
                      </Link>
                    </Item>
                    <Item>
                      <button onClick={handleClickRemoveScript(script)}>
                        Excluir
                      </button>
                    </Item>
                  </List>
                </Dropdown>
              </td>
            </tr>
          )}
        />
      </Container>
    </Wrapper.container>
  );
};

const mapStateToProps = (state) => ({
  data: scriptsSelector.getAll(state),
});

const mapDispatchToProps = {
  fetchData,
  removeScript,
  openConfirmation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScriptsPages);
