import React from 'react';
import { reduxForm } from 'redux-form';
// Components
import FieldReasons from '../FieldReasons';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

function Form({ reasonId, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <FieldReasons label="Selecionar Motivo" excludeId={reasonId} />

      <ModalFooter>
        <Button color="white" colorText="secondary">
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="danger">
          Excluir
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormLossReasonRemove',
  enableReinitialize: true
})(Form);
