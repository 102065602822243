import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
`;

export const Header = styled.div`
  display: flex;
`;

export const Item = styled.div``;
export const ItemTitle = styled.div``;
export const ItemNumber = styled.div``;

export const Content = styled.div`
  position: relative;
`;

export const Expand = styled.button`
  text-align: center;
  background: #d3dceb;
  font-size: 12px;
  color: #6b7280;
  padding: 5px;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
`;

export const TooltipC = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;
