import React, { useCallback } from 'react';
import { addMinutes } from 'date-fns';
import { Actions, Btn, Title, Wrapper } from './styles';
import SyncGoogleCalendar from 'containers/SyncGoogleCalendar';
import Button from 'components/Button';
import { FaGoogle } from 'react-icons/fa';
import { openModalEvent } from 'modules/modal';
import { useDispatch } from 'react-redux';

const Toolbar = ({
  date,
  label,
  onNavigate,
  onView,
  view,
  views,
  localizer,
  ...props
}) => {
  const dispatch = useDispatch();
  const goToBack = () => {
    switch (view) {
      case 'day':
        date.setDate(date.getDate() - 1);
        break;
      case 'week':
        date.setDate(date.getDate() - 7);
        break;

      case 'month':
      default:
        date.setMonth(date.getMonth() - 1);
        break;
    }

    onNavigate('prev');
  };

  const goToNext = () => {
    switch (view) {
      case 'day':
        date.setDate(date.getDate() + 1);
        break;
      case 'week':
        date.setDate(date.getDate() + 7);
        break;

      case 'month':
      default:
        date.setMonth(date.getMonth() + 1);
        break;
    }
    onNavigate('next');
  };

  const goToCurrent = () => {
    const now = new Date();
    switch (view) {
      case 'day':
      case 'week':
        date.setDate(now.getDate());
        date.setMonth(now.getMonth());
        date.setFullYear(now.getFullYear());
        break;
      case 'month':
      default:
        date.setMonth(now.getMonth());
        date.setFullYear(now.getFullYear());
        break;
    }
    onNavigate('current');
  };

  const handleOpenModal = useCallback(() => {
    const start = new Date();
    const end = addMinutes(new Date(), '30');

    dispatch(
      openModalEvent({
        start,
        end,
      })
    );
  }, []);

  return (
    <Wrapper>
      <div>
        <Actions>
          <Btn onClick={goToCurrent}>Hoje</Btn>
          <Btn onClick={goToBack}>Anterior</Btn>
          <Btn onClick={goToNext}>Próximo</Btn>
        </Actions>
      </div>
      <Title>{label}</Title>
      <div>
        <Button color="success" onClick={handleOpenModal}>
          Agendar Compromisso
        </Button>
        <SyncGoogleCalendar
          render={({ onClick }) => (
            <Button
              color="secondary"
              onClick={onClick}
              style={{ marginRight: '15px' }}
            >
              <FaGoogle style={{ marginRight: '5px' }} />
              Sincronizar com o Google Agenda
            </Button>
          )}
        />
        <Actions>
          {views.map((viewName) => (
            <Btn isActive={viewName === view} onClick={() => onView(viewName)}>
              {localizer.messages[viewName]}
            </Btn>
          ))}
        </Actions>
      </div>
    </Wrapper>
  );
};

export default Toolbar;
