import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
// Common Components
import { ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
// Assets
import video from 'assets/img/video.png';

const propTypes = {
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const defaultProps = {
  modalType: null,
  modalConfig: null,
  isOpen: false,
  handleClose: null,
};

function ModalHowToAddDeal({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  history,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Adicionar Negócio" handleClose={handleClose}>
        Para adicionar um cliente na jornada, é necessário que ele possua
        imóveis no crm. Veja como fazer:
        <img src={video} alt="Vídeo Url" style={{ margin: '25px auto' }} />
        <div className="h-text-center">
          <Button
            onClick={() => {
              // redireciona para listagem de imóveis
              history.push('/properties/search');

              // fecha a modal
              handleClose();
            }}
          >
            Ir para imóveis
          </Button>
        </div>
      </ModalTemplate>
    </Modal>
  );
}

ModalHowToAddDeal.propTypes = propTypes;
ModalHowToAddDeal.defaultProps = defaultProps;

export default withRouter(ModalHowToAddDeal);
