import styled from 'styled-components';

export const BtnGroup = styled.div`
  width: 200px;
  display: flex;
  margin-top: 5px;

  .Button + .Button {
    margin-left: 5px;
  }
`;

export const Container = styled.div`
  display: flex;
  margin-left: 15px;

  form {
    width: 155px;
    margin-right: 15px;
  }
`;

export const Btn = styled.a`
  color: ${(p) => p.theme.colors.secondary};
  line-height: 30px;

  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
`;
