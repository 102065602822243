import { useEffect } from 'react';
import { SelectMultiple } from 'components/Form';
import FieldPerson from 'containers/FieldPerson';
import { withRouter } from 'react-router-dom';
import useReduxForm from 'hooks/useReduxForm';

const getParamsByNetwork = (network) => {
  if (network === 'orulo') {
    return {
      filter: { by_orulo_owner: true },
    };
  }

  if (network === 'dwv') {
    return {
      filter: { by_dwv_owner: true },
    };
  }
};

function FieldPersonOrulo({ history, network, ...props }) {
  const { change, dispatch } = useReduxForm();
  const handleChangePerson = (personId) => {
    const params = new URLSearchParams(window.location.search);

    if (personId?.id) {
      params.set('filter[people_id]', personId?.id);
    } else {
      params.delete('filter[people_id]');
    }

    history.replace({
      pathname: history.location.pathname,
      search: params.toString(),
    });
  };

  const params = getParamsByNetwork(network);

  useEffect(() => {
    if (network === 'guru' || network === 'self') {
      handleChangePerson(null);
      dispatch(change('people_id', null));
    }
  }, [network]);

  if (network !== 'orulo' && network !== 'dwv') return null;

  return (
    <FieldPerson
      creatable={false}
      name="people_id"
      label=""
      params={params}
      component={SelectMultiple}
      placeholder="Construtoras"
      onChange={handleChangePerson}
    />
  );
}

export default withRouter(FieldPersonOrulo);
