import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
// Components
import Radio from 'components/Radio/index';

const defaultProps = {
  id: null,
  input: null
};

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  input: PropTypes.object,
  radioValue: PropTypes.any
};

export const RadioButton = props => {
  const { disabled, input, label, className, ...rest } = props;

  return (
    <label
      {...rest}
      tabIndex={-1}
      className={classnames('FormGroup', 'RadioButton', className, {
        'RadioButton--disabled': disabled
      })}
    >
      <input
        {...input}
        type="radio"
        style={{
          display: 'none'
        }}
      />
      <Radio checked={input.checked} />
      <span>{label}</span>
    </label>
  );
};

RadioButton.defaultProps = defaultProps;
RadioButton.propTypes = propTypes;

export default RadioButton;
