import React from 'react';
import Box from '../Box';
import Table from './components/Table';
import Button from 'components/Button';
import { useCreditCards } from 'pages/PlanConfig/hooks/useCreditCards';
import Nodata from './components/Nodata';
import RecurringCharge from './components/RecurringCharge';

function CreditCards() {
  const {
    data,
    handleClickPrimaryCard,
    handleClickRemove,
    handleClickAddCard,
  } = useCreditCards();

  const hasCard = data && data.length > 0;

  return (
    <Box title="Cartões de crédito">
      <Table
        data={data}
        handleClickPrimaryCard={handleClickPrimaryCard}
        handleClickRemove={handleClickRemove}
      />
      {hasCard && <RecurringCharge />}
      {!hasCard && <Nodata />}
      <Button
        onClick={handleClickAddCard}
        size="medium"
        color="tertiary"
        className="h-margin-top--10"
      >
        Adicionar cartão
      </Button>
    </Box>
  );
}

export default CreditCards;
