import React from 'react';
import styled, { css } from 'styled-components';
import Lottie from 'lottie-react';
import animationData from 'pages/PropertyStore/animations/loading-ai.json';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: grid;
  place-items: center;
  z-index: 1100;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;

  ${(p) =>
    p.isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  svg {
    width: 50%;
  }
`;

const Content = styled.div`
  width: 60%;
  text-align: center;
`;

const Title = styled.div`
  font-size: 20px;
  text-align: center;
  margin-bottom: 5px;
`;

const Text = styled.p`
  margin-bottom: 15px;
`;

function Loading({ isVisible }) {
  return (
    <Wrapper isVisible={isVisible}>
      <Content>
        <Title>Gerando descrição com ChatGPT</Title>
        <Text>O processo pode levar até 2 minutos</Text>
        <Lottie
          style={{
            width: '300px',
            margin: '0 auto',
          }}
          animationData={animationData}
          loop
        />
      </Content>
    </Wrapper>
  );
}

export default Loading;
