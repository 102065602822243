import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
// Components
import LocationImageProperty from 'components/LocationImageProperty';
import ListOptions from 'components/ListOptions';
import { FieldBool } from 'components/Form';
import Nodata from 'components/Nodata';
import Button from 'components/Button';

// Modules
import { closeModal } from 'modules/modal';

// Assets
import house from 'assets/img/undraw/house.svg';

const propTypes = {};
const defaultProps = {};

const FormMap = ({ property, location, change, closeModal }) => {
  const hasLocation = property?.maps_latitude && property?.maps_longitude;

  return (
    <ListOptions
      style={{
        width: 'calc(100% + 40px)',
        margin: '-20px 0 0 -20px',
        borderBottom: '1px solid #DEDEDE'
      }}
    >
      {hasLocation ? (
        <>
          <ListOptions.item
            title="Mostrar mapa no site?"
            renderOptions={() => (
              <FieldBool
                name="is_map_shown"
                onChange={(e, value) => {
                  if (!value) {
                    change('is_exact_map_shown', false);
                    change('is_streetview_shown', false);
                  }
                }}
              />
            )}
          />
          <ListOptions.item
            title="Mostrar localização exata?"
            renderOptions={() => (
              <FieldBool
                name="is_exact_map_shown"
                onChange={() => {
                  change('is_map_shown', true);
                }}
              />
            )}
          />
          <ListOptions.item
            title="Mostrar StreetView no site?"
            renderOptions={() => (
              <FieldBool
                name="is_streetview_shown"
                onChange={() => {
                  change('is_map_shown', true);
                }}
              />
            )}
          />

          <LocationImageProperty
            property={{
              ...property,
              ...location
            }}
          />
        </>
      ) : (
        <Nodata
          image={house}
          title="Esse imóvel não possui latitude e longitude"
          text={
            <p className="h-color--primary">
              Encontre a localização dele no mapa e tente publicá-lo novamente.
            </p>
          }
        >
          <Button
            to={`/properties/store/location/${property?.id}`}
            style={{ marginTop: '10px' }}
            onClick={closeModal}
          >
            Editar localização
          </Button>
        </Nodata>
      )}
    </ListOptions>
  );
};

FormMap.propTypes = propTypes;
FormMap.defaultProps = defaultProps;

const selector = formValueSelector('PublicationWizard');

const mapStateToProps = state => ({
  location: selector(
    state,
    'is_map_shown',
    'is_exact_map_shown',
    'is_streetview_shown'
  )
});

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(FormMap);
