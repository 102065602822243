import qs from 'qs';

/**
 * Força uma string vir com HTTPS na frente
 * @param url
 * @returns {string|*}
 */
export const forceHttps = (url) => {
  url = url.replace(/^http:\/\//i, 'https://');

  if (!/^https?:\/\//i.test(url)) {
    return 'https://' + url;
  }

  return url;
};

/**
 * Pega a url atual sem os parametros
 * @return {string} - url sem os parametros
 */
export const getCleanUrl = () =>
  window.location.protocol +
  '//' +
  window.location.host +
  window.location.pathname;

/**
 * Limpa os parametros da url
 */
export const clearUrlParams = () => {
  window.history.replaceState({}, document.title, getCleanUrl());
};

/**
 * Atualiza a queryString com novos parametros
 * @param values
 */
export const updateSearch = (values) => {
  const queryString = qs.stringify(values);
  const pageUrl = '?' + queryString;
  window.history.pushState('', '', pageUrl);
};

export const getParam = (key) =>
  new URLSearchParams(window.location.search).get(key);

export const addParam = (key, value) => {
  const search = new URLSearchParams(window.location.search);
  search.set(key, value);

  window.history.replaceState(
    null,
    null,
    window.location.pathname + '?' + search.toString()
  );
};

export const removeParam = (key) => {
  const search = new URLSearchParams(window.location.search);
  search.delete(key);

  const hasSearch = search.toString().length > 0;

  let url = window.location.pathname;

  if (hasSearch) {
    url += '?' + search.toString();
  }

  window.history.replaceState(null, null, url);
};
