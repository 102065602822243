import { useState, useEffect } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { getRealEstate } from 'services/realEstate';

export function useCurrentRealEstate({ fetch = false, params = {} } = {}) {
  const [isFetching, setIsFetching] = useState(false);
  const currentUser = useCurrentUser();
  const [realEstate, setRealEstate] = useState(currentUser.real_estate);

  async function fetchRealEstate(params) {
    try {
      setIsFetching(true);
      // Pega as informações da imobiliária
      const { data: _realEstate } = await getRealEstate(params);

      // seta a imobiliária que foi buscada
      setRealEstate(_realEstate);
    } catch {
      console.error('Erro ao buscar dados da imobiliária');
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    if (fetch) {
      fetchRealEstate(params);
    }
  }, [fetch]);

  // Se estiver para buscar dados da imobiliaria
  // Retorna um objeto ao invés de direto a imobiliária
  if (fetch) {
    return { realEstate, fetchRealEstate, isFetching };
  }

  return realEstate;
}
