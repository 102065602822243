import React, { Component } from 'react';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import qs from 'qs';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import { loginUser } from 'modules/login';
import { Input } from 'components/Form';
import Button from 'components/Button';
import FormTitle from 'templates/Login/FormTitle';
// validacao do formulario
import validate from './validate';
import IsTecimob from 'containers/IsTecimob';

const defaultProps = {};
const propTypes = {};

class SignIn extends Component {
  get token() {
    try {
      return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        .token;
    } catch {
      return '';
    }
  }

  setSecretRecaptcha = async () => {
    //const { secret, change } = this.props;
    //change('recaptcha', secret);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    //this.setSecretRecaptcha();
  }

  async componentDidMount() {
    const token = this.token;

    if (token) {
      localStorage.setItem('access_token', token);
      window.location.reload();
    }
  }

  render() {
    const { handleSubmit, submitting, login } = this.props;

    return (
      <>
        <div className="h-flex__cell--grow" />
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>Login</title>
          </Helmet>
          <FormTitle
            title="Bem-Vindo(a) de volta!"
            text="Faça login na sua conta"
          />
          <Row>
            <Field
              autoFocus
              xs={12}
              tabIndex={1}
              label="E-mail"
              name="email"
              component={Input}
              placeholder="Digite seu e-mail"
            />
            <Col xs={12} style={{ position: 'relative' }}>
              {!login.resellerError && (
                <Link
                  to="recovery"
                  className="h-color--secondary"
                  style={{
                    position: 'absolute',
                    right: '.5em',
                    top: 0,
                    zIndex: 5
                  }}
                >
                  Esqueceu sua senha?
                </Link>
              )}
              <Field
                tabIndex={2}
                ref={input => (this.inputPassword = input)}
                type="password"
                label="Senha"
                name="password"
                component={Input}
                placeholder="Digite a sua senha"
              />
            </Col>
          </Row>
          <Button block disabled={submitting} type="submit" color="primary">
            Acessar sua conta
          </Button>
          {/*<FormSocial />*/}
        </form>
        <div className="h-flex__cell--grow" />
        <IsTecimob>
          <div>
            <span>Cliente da versão anterior?</span>
            <a
              className="h-link h-margin-left--5"
              href="https://painel.tecimob.com.br/sistema/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Acesse aqui
            </a>
          </div>
        </IsTecimob>
      </>
    );
  }
}

SignIn.defaultProps = defaultProps;
SignIn.propTypes = propTypes;

SignIn = reduxForm({
  form: 'signin',
  initialValues: {
    email: localStorage.email || ''
  },
  validate,
  onSubmit: (values, dispatch) => {
    return dispatch(loginUser(values));
  }
})(SignIn);

const mapStateToProps = state => ({
  login: state.login
});

export default compose(connect(mapStateToProps))(SignIn);
