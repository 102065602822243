import React from 'react';
import { MdLock } from 'react-icons/md';
// Components
import Avatar from 'components/Avatar';

/**
 * Renderiza o avatar do imóvel
 * @param images
 * @return {*}
 */
export const renderAvatar = cover_image => {
  if (!cover_image) return null;
  if (!cover_image.file_url) return null;
  return <Avatar image={cover_image.file_url} className="h-margin-right--10" />;
};

/**
 * Renderiza a celula de um imóvel
 * @param property
 * @return {*}
 * @constructor
 */
export const PropertyCell = ({ data }) => (
  <td>
    <div className="h-flex h-flex--center-center">
      {renderAvatar(data.cover_image)}
      <span>{data.title_formatted}</span>
      {data.is_blocked && (
        <MdLock
          className="h-margin-left--5 h-color--danger"
          data-tip="Esse imóvel está bloqueado"
          data-effect="solid"
        />
      )}
    </div>
  </td>
);

export const renderDanger = (text, bool) => {
  if (!bool) return text;
  return <span className="h-color--danger">{text}</span>;
};
