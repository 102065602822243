import React from 'react';
import Button from 'components/Button';
import iconCalendar from './icon-calendar.png';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 40px;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-top: 57px;

  h4 {
    margin-bottom: 10px;
  }

  button {
    margin-top: 15px;
  }
`;

function Reception({ onClick }) {
  return (
    <Wrapper className="h-flex">
      <div className="h-flex__cell--grow">
        <h4>Agendar visitas</h4>
        <p>
          Configurando o agendamento de visitas, seu cliente pode
          <br /> pré-agendar uma visita pelo seu site, de acordo com os
          <br /> dias e horários estabelecidos.{' '}
          <a
            href="https://scribehow.com/shared/Ativar_o_agendamento_de_visitas_no_site__sO22AFm7QXaDno0wbvjEHA?referrer=documents"
            target="_blank"
            className="h-link"
            rel="noreferrer noopener"
          >
            Saiba mais
          </a>
        </p>
        <Button type="button" color="success" onClick={onClick}>
          Ativar agendamento de visitas
        </Button>
      </div>
      <div>
        <img src={iconCalendar} alt="" />
      </div>
    </Wrapper>
  );
}

export default Reception;
