import { combineReducers } from 'redux';
// Helpers
import { createModule } from 'lib/reducer-helpers';
// Services
import api from 'services';
import dataReducer from 'modules/data';
import { getFormValues } from 'redux-form';
import { getPagination, setPagination } from 'modules/pagination';

const createAction = createModule('crm2');

export const MODES = {
  vertical: 'vertical',
  horizontal: 'horizontal',
};

// Tipos de ações que tem no reducer
const Types = {
  SET_VIEW_MODE: createAction('SET_VIEW_MODE'),
};

const initialState = {
  deals: {
    data: [],
    meta: {
      isFetching: false,
    },
  },
  viewMode: localStorage.getItem('@tecimob/crm-view-mode') || 'vertical',
};

export const {
  selectors: dealsSelector,
  reducer: dealsReducer,
  actionCreators: dealsActions,
} = dataReducer('crm2/deals');

export const viewModeSelector = (state) => state?.crm2?.viewMode;

function reducerViewMode(state = initialState.viewMode, action) {
  switch (action.type) {
    case Types.SET_VIEW_MODE: {
      return action.mode;
    }
    default:
      return state;
  }
}

export const Actions = {
  setViewType: (mode) => {
    localStorage.setItem('@tecimob/crm-view-mode', mode);

    return { type: Types.SET_VIEW_MODE, mode };
  },
};

export const transformDealsFilter = ({
  by_people_search,
  rate,
  by_user_id,
  stage_id,
  sort,
  has_calendars,
  ...params
}) => {
  let values = {};

  if (by_people_search) values = { ...values, by_search: by_people_search };
  if (rate) values = { ...values, rate: rate };
  if (by_user_id) values = { ...values, by_user_id: by_user_id };
  if (stage_id) values = { ...values, stage_id: stage_id };

  if (has_calendars === true) {
    values = { ...values, having: ['person.calendars'], doesnt_having: null };
  }

  if (has_calendars === false) {
    values = { ...values, doesnt_having: ['person.calendars'], having: null };
  }

  return { ...values, ...params };
};

/**
 * Pega os dados da pipeline vertical
 */
export const getDeals = (values) => async (dispatch, getState) => {
  const state = getState();

  const pagination = getPagination(state, 'crm-horizontal');

  const filter = getFormValues('FilterCrm')(getState());

  let currentValues = transformDealsFilter({
    ...values,
    ...filter,
  });

  const offset = currentValues?.offset;

  if (currentValues.by_search) {
    currentValues.by_people_search = currentValues.by_search;
    delete currentValues.by_search;
  }

  delete currentValues.offset;

  try {
    // Marca que está buscando os negócios
    dispatch(dealsActions.request());

    const { data: deals, meta } = await api.getList('crm/deals/vertical-list', {
      filter: currentValues,
      sort: values?.sort || null,
      // include: 'deals',
      // count: 'calendars',
      ...pagination,
      offset: offset || pagination.offset,
    });

    // Seta a paginação dos negócios
    dispatch(setPagination('crm-horizontal', meta.pagination));

    // Adiciona os negócios na store
    dispatch(dealsActions.receive(deals));
  } catch {
    // Se der algum problema reseta a store
    // para o estado inicial
    dispatch(dealsActions.reset());
  }
};

export default combineReducers({
  viewMode: reducerViewMode,
  deals: dealsReducer,
});
