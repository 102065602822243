import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from 'react-tooltip';
// Constants
import { COLORS, SIZES } from '../../constants/config';
// Image
import Image from 'components/Image';
// Helpers
import { getInitials } from '../../lib/text';

const defaultProps = {
  className: null,
  image: null,
  text: '',
  rounded: false,
  color: 'primary',
  size: 'medium',
  style: {},
  onClick: null,
  hasZoom: true,
};

const propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  rounded: PropTypes.bool,
  color: PropTypes.oneOf(COLORS),
  size: PropTypes.oneOf(SIZES),
  style: PropTypes.object,
  onClick: PropTypes.func,
  hasZoom: PropTypes.bool,
};

const Component = ({ image, text, hasZoom, size, place = 'right' }) => {
  // Se não existir imagem tem que retornar
  // pelo menos as iniciais do texto que ele envia
  if (!image) return getInitials(text);

  // As props padrão do componente
  let defaultProps = {
    size: size,
    src: image,
    className: 'Avatar__img',
    alt: 'text',
  };

  // Se existir a propriedade zoom
  // tem que adicionar as coisas relacionadas ao tooltip
  if (hasZoom) {
    defaultProps = {
      ...defaultProps,
      'data-tip': true,
      'data-for': `avatar-${image}`,
    };
  }

  return (
    <div className="Avatar__image">
      <Image key={image} {...defaultProps} />
      {hasZoom && (
        <Tooltip
          id={`avatar-${image}`}
          type="dark"
          effect="solid"
          place={place}
          className="Tooltip--image"
        >
          <Image src={image} alt={text} className="Tooltip__image" />
        </Tooltip>
      )}
    </div>
  );
};

const Avatar = ({
  image,
  sizeImage = 'small',
  text,
  className,
  color,
  size,
  place = 'right',
  rounded,
  style,
  onClick,
  hasZoom,
}) => {
  const TagName = onClick ? 'button' : 'div';

  return (
    <TagName
      className={classnames('Avatar', className, {
        'Avatar--rounded': rounded,
        [`Avatar--${color}`]: color,
        [`Avatar--${size}`]: size,
      })}
      style={style}
      onClick={onClick}
    >
      <Component
        key={image}
        size={sizeImage}
        image={image}
        text={text}
        place={place}
        hasZoom={hasZoom}
      />
    </TagName>
  );
};

Avatar.defaultProps = defaultProps;
Avatar.propTypes = propTypes;

export default Avatar;
