import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import CompanyDataForm from './components/CompanyDataForm';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
// Service
import * as realEstateService from 'services/realEstate';
// Modules
import {
  companyDataSelector,
  fetchData,
  handleFormSubmit,
  locationThunks,
} from './module';

import { setColorHeader, setLogoHeader } from 'modules/login';

const propTypes = {};
const defaultProps = {};

const CompanyData = () => {
  const [isLoading, setIsLoading] = useState(false);

  const data = useSelector((state) => companyDataSelector.getAll(state));
  const isFetching = useSelector((state) =>
    companyDataSelector.isFetching(state)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  const handleDeleteLogo = (change) => () => {
    realEstateService.removeLogo().then(() => {
      dispatch(setLogoHeader(''));
      change('file_url', null);
      change('image', null);
    });
  };

  const handleChangeHeaderBackground = (e, color) => {
    if (!color) return;
    dispatch(setColorHeader(color));
  };

  const handleChangeImage = (e, image) => {
    if (!image) return;
    dispatch(setLogoHeader(image.preview));
  };

  const handleGetLocation = () => {
    dispatch(locationThunks.getLocation());
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    return dispatch(handleFormSubmit(values));
  };

  return (
    <Wrapper.container>
      <MainTitle
        title="Dados da Empresa"
        text="Envie seu logo, adicione os dados do seu negócio e conecte suas redes sociais."
      />
      <CompanyDataForm
        initialValues={data}
        isFetching={isLoading}
        onSubmit={handleSubmit}
        onSubmitSuccess={() => {
          setIsLoading(false);
        }}
        onSubmitFail={() => {
          setIsLoading(false);
        }}
        handleDeleteLogo={handleDeleteLogo}
        handleChangeHeaderBackground={handleChangeHeaderBackground}
        handleChangeImage={handleChangeImage}
        handleGetLocation={handleGetLocation}
      />
    </Wrapper.container>
  );
};

CompanyData.propTypes = propTypes;
CompanyData.defaultProps = defaultProps;

export default CompanyData;
