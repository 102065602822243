import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
// Components
import StarsRating from 'components/StarsRating';
import FormGroup from 'components/Form/components/FormGroup';

// import {} from './styles';

function Rating({
  formGroupClassName,
  label,
  required,
  disabled,
  input,
  meta,
  labelRender,

  canClear,
  start,
  end
}) {
  const handleChange = useCallback(
    value => {
      if (value === input.value && canClear) {
        input.onChange(null);
        return false;
      }

      input.onChange(value);
    },
    [input]
  );

  return (
    <FormGroup
      className={formGroupClassName}
      labelText={label}
      isRequired={required}
      isDisabled={disabled}
      input={input}
      meta={meta}
      labelRender={labelRender}
    >
      <StarsRating
        rating={input.value}
        start={start}
        end={end}
        onChange={handleChange}
      />
    </FormGroup>
  );
}

Rating.defaultProps = {
  start: 0,
  end: 5,
  canClear: true
};
Rating.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  canClear: PropTypes.bool
};

export default Rating;
