import React from 'react';
import Status from '../Status';
import Crm from '../Crm';
import Booked from '../Booked';

function Text({ property }) {
  return (
    <>
      {property.condominium ? property.condominium.title + ' | ' : ''}
      {property?.address_formatted}
      <Status property={property} />
      <Crm property={property} />
      <Booked property={property} />
    </>
  );
}

export default Text;
