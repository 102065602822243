import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideRecoveryMessage, sendEmailPassword } from '../../modules/login';

import RecoveryForm from './RecoveryForm';
import RecoveryMsg from './RecoveryMsg';

const defaultProps = {};
const propTypes = {
  login: PropTypes.object.isRequired,
  hideRecoveryMessage: PropTypes.func.isRequired
};

class Recovery extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.props.hideRecoveryMessage();
  }
  handleSubmit(values, dispatch) {
    return dispatch(sendEmailPassword(values));
  }

  get initialValues() {
    const { login } = this.props;

    return {
      email: localStorage.email,
      resale_id: login.reseller.id
    };
  }

  render() {
    const {
      login: {
        recovery: { email, isVisible }
      }
    } = this.props;

    return isVisible ? (
      <RecoveryMsg email={email} />
    ) : (
      <RecoveryForm
        initialValues={this.initialValues}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => ({
  login: state.login
});

const mapDispatchToProps = dispatch => ({
  hideRecoveryMessage: email => {
    dispatch(hideRecoveryMessage(email));
  }
});

Recovery.defaultProps = defaultProps;
Recovery.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Recovery);
