// import styles from './styles'

import ReactModal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import React, { useMemo } from 'react';
import classnames from 'classnames';
import * as S from './styles';
import Button from 'components/Button';

export function ModalIcons({ icons, isOpen, onClose, onSelect }) {
  const className = useMemo(() => {
    return {
      base: classnames('Modal Modal--medium'),
      afterOpen: 'Modal--after-open',
      beforeClose: 'Modal--before-close',
    };
  }, []);

  const overlayClassName = useMemo(() => {
    return {
      base: `ModalOverlay ModalOverlay--modal-center`,
      afterOpen: 'ModalOverlay--after-open',
      beforeClose: 'ModalOverlay--before-close',
    };
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      className={className}
      overlayClassName={overlayClassName}
      style={{
        content: {
          width: 413,
        },
      }}
    >
      <ModalTemplate title="Selecione o icone">
        <S.GridIcons>
          {icons.map((icon) => (
            <S.Icon
              key={icon.id}
              onClick={() => {
                if (onSelect) onSelect(icon);
              }}
            >
              <img src={icon.file_url} alt="Icon" />
            </S.Icon>
          ))}
        </S.GridIcons>
        <ModalFooter>
          <Button color="white" onClick={onClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </ReactModal>
  );
}
