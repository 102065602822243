import React, { useMemo } from 'react';
import Content, { ListCount } from '../Content';
import Count from '../Count';
import {
  MdHome,
  MdImportExport,
  MdPersonAdd,
  MdReportProblem,
  MdSearch,
} from 'react-icons/md';
import { useRequest } from 'hooks/useRequest';
import api from 'services';
import { getCurrentWeek } from 'lib/date-helpers';
import * as qs from 'qs';
import { useDispatch } from 'react-redux';
import { openModalPersonAdd, openModalPropertySearch } from 'modules/modal';

// import { Wrapper } from './styles';

function Shorcuts() {
  const dispatch = useDispatch();

  const { data } = useRequest({
    request: () => api.get('users/me?include=dashboard'),
  });

  const { weekStart, weekEnd } = useMemo(() => {
    return getCurrentWeek();
  }, []);

  const searchBirthday = useMemo(() => {
    return qs.stringify({
      initialValues: {
        by_week_birthday: true,
      },
    });
  }, [weekStart, weekEnd]);

  const handleAddPerson = () => {
    dispatch(openModalPersonAdd(null));
  };

  const handleOpenSearch = () => {
    dispatch(openModalPropertySearch());
  };

  return (
    <Content title="Atalhos">
      <ListCount>
        <Count
          icon={MdSearch}
          title={
            <>
              Pesquisar
              <br />
              Imóveis
            </>
          }
          onClick={handleOpenSearch}
        />
        <Count
          icon={MdHome}
          title={
            <>
              Cadastrar
              <br />
              Imóvel
            </>
          }
          to="properties/store"
        />
        <Count
          icon={MdPersonAdd}
          title={
            <>
              Cadastrar
              <br />
              Cliente
            </>
          }
          onClick={handleAddPerson}
        />
        <Count
          icon={MdImportExport}
          title={
            <>
              Portais
              <br />
              Imobiliários
            </>
          }
          to="/portals"
          actionText="Selecionar Imóveis"
        />
        <Count
          icon={data?.dashboard?.new_matcheds > 0 && MdReportProblem}
          color="danger"
          count={data?.dashboard?.new_matcheds ?? '--'}
          title={
            <>
              Radar de <br />
              Oportunidades
            </>
          }
          to="/crm/matched-properties"
        />
        <Count
          color="secondary"
          count={data?.dashboard?.birthdays ?? '--'}
          title={
            <>
              Aniversariantes na
              <br />
              semana
            </>
          }
          to={`/people/show?${searchBirthday}`}
        />
      </ListCount>
    </Content>
  );
}

export default Shorcuts;
