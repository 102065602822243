import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import FieldSelectUser from 'containers/FieldSelectUser';
import { Col, Row } from 'react-flexbox-grid';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function Form({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={6}>
          <FieldSelectUser
            label="Corretor"
            style={{ width: 200 }}
            clearable={false}
            options={[{ id: '', name: 'Todos' }]}
            params={{
              filter: {
                realtor: true,
                having: ['properties'],
              },
              sort: 'by_name',
            }}
          />
        </Col>

        <Col xs={6}>
          <FieldSelectUser
            label="Agenciador"
            name="agent_id"
            style={{ width: 200 }}
            clearable={false}
            options={[{ id: '', name: 'Todos' }]}
            params={{
              filter: {
                realtor: null,
              },
              sort: 'by_name',
            }}
          />
        </Col>
      </Row>
    </form>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

export default reduxForm({
  form: 'FormSelectUser',
  enableReinitialize: false,
  destroyOnUnmount: false,
  onChange: (values, dipatch, props) => {
    props.submit();
  },
})(Form);
