import React from 'react';
import Button from 'components/Button';
import PopoverProgress from 'pages/EnterpriseStore/components/PopoverProgress';
import { useProgress } from '../../contexts/ProgressContext';
import TableDrag from 'components/TableDrag';

const TableProgress = ({ data }) => {
  const { handleDragEnd, handleRemoveProgress, fetchData } = useProgress();

  return (
    <TableDrag
      data={data}
      renderHead={() => (
        <tr>
          <th>Nome</th>
          <th>Porcentagem</th>
        </tr>
      )}
      renderItem={progress => (
        <>
          <td>{progress?.name}</td>
          <td>{progress?.percent}</td>
          <td className="Table__cell--small">
            <div className="h-hover__show">
              <PopoverProgress
                data={progress}
                onSubmitSuccess={() => {
                  fetchData();
                }}
              >
                <Button
                  color="white"
                  colorText="secondary"
                  className="h-margin-right--10"
                >
                  Editar
                </Button>
              </PopoverProgress>
              <Button
                color="danger"
                onClick={handleRemoveProgress(progress?.id)}
              >
                Remover
              </Button>
            </div>
          </td>
        </>
      )}
      onDragEnd={handleDragEnd}
    />
  );
};

export default TableProgress;
