import React, { useState } from 'react';

export const DashboardContext = React.createContext({
  refreshKey: 0,
  refresh: () => null,
});

export const DashboardProvider = ({ children }) => {
  const [refreshKey, setRefreshkey] = useState(0);

  const refresh = () => {
    setRefreshkey((key) => key + 1);
  };

  return (
    <DashboardContext.Provider
      value={{
        refreshKey,
        refresh,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return React.useContext(DashboardContext);
};
