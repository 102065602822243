import React from 'react';
import { Helmet } from 'react-helmet';
// Components
import Aside from './components/Aside';
import Routes from './Routes';
import Wrapper from 'components/Wrapper';

export default function Reports() {
  return (
    <Wrapper full>
      <Helmet>
        <title>Tecimob - Relatórios</title>
      </Helmet>
      <Aside />
      <Wrapper.inner>
        <Routes />
      </Wrapper.inner>
    </Wrapper>
  );
}
