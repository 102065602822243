import { isValidReference } from 'services/properties';

/**
 * Pega a próxima referencia
 * @returns {Promise<void>}
 */
const getNextReference = async (reference, params) => {
  const res = await isValidReference(reference, params);
  return res.data.reference;
};

export const getProperty = async (property) => {
  let _params = {};

  if (property?.skip_references) {
    _params.skip_references = property.skip_references;
  }

  if (property?.informations?.apartment_number) {
  }

  return {
    reference: await getNextReference(property.reference, _params),
    price: property.price,
    informations: {
      lot_number: property.informations.lot_number,
      floor: property.informations.floor,
      apartment_number: property?.informations?.apartment_number,
    },
    areas: {
      [property?.primary_area?.name]: property.primary_area,
    },
    rooms: {
      bedroom: property.rooms.bedroom,
      suite: property.rooms.suite,
      bathroom: property.rooms.bathroom,
      garage: property.rooms.garage,
    },
  };
};
