// Widgets.js
import { createModule } from '../lib/reducer-helpers';
import * as people from '../services/people';

const createAction = createModule('peopleGroups');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');

const REQUEST_NEW_TYPE = createAction('REQUEST_NEW_TYPE');
const RECEIVE_NEW_TYPE = createAction('RECEIVE_NEW_TYPE');

// Initial State
const initialState = {
  groups: [],
  meta: {
    isFetching: false,
    isFetchingTypes: false
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST: {
      return {
        ...state,
        groups: action.groups,
        meta: {
          ...state.meta,
          isFetching: true
        }
      };
    }
    case RECEIVE: {
      return {
        ...state,
        groups: action.groups,
        meta: {
          ...state.meta,
          isFetching: false
        }
      };
    }
    case REQUEST_NEW_TYPE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isFetchingTypes: true
        }
      };
    }
    case RECEIVE_NEW_TYPE: {
      return {
        ...state,
        groups: [...state.groups, action.option],
        meta: {
          ...state.meta,
          isFetchingTypes: false
        }
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function requestTypes() {
  return { type: REQUEST };
}

export function receiveTypes(groups) {
  return { type: RECEIVE, groups };
}

export function requestNewType() {
  return { type: REQUEST_NEW_TYPE };
}

export function receiveNewType(option) {
  return { type: RECEIVE_NEW_TYPE, option };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getGroups = () => dispatch => {
  dispatch(requestTypes());
  people.getGroups().then(({ data }) => dispatch(receiveTypes(data)));
};

export function addGroup(typeName) {
  return dispatch => {
    requestNewType();
    return people.addGroup(typeName).then(({ data }) => {
      const { id, name } = data;
      dispatch(receiveNewType({ id, name }));
      return data;
    });
  };
}
