import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RecommendationTerms = styled.div`
  padding-bottom: 20px;
`;
