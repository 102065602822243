import { rgba } from "polished";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15px;
  border: 1px solid ${(p) => rgba(p.theme.colors.success, 0.2)};
  border-radius: 10px;

  cursor: pointer;

  transition: border-color 0.2s;

  &:hover {
    border: 1px solid ${(p) => p.theme.colors.success};
  }

  ${(props) => props.active && css`
    border: 1px solid ${(p) => p.theme.colors.success};
    background: ${(p) => rgba(p.theme.colors.success, 0.1)};
  `}
`;

export const Name = styled.h2`
  font-size: 16px;
  margin-top: 10px;
`;
