import React from 'react';
import { BarItemSelecteds } from 'pages/Properties/components/FixedBarProperties/styles';
import { MdCheckCircle, MdDelete, MdPerson } from 'react-icons/md';
import { plural } from 'lib/text';
import FixedBar from 'components/FixedBar';
import { openModalSendPeopleEmail } from 'modules/modal';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

// import { Wrapper } from './styles';

const Overflow = styled.div`
  overflow: hidden;
  position: sticky;
  margin-left: -19px;
  width: calc(100% + 39px);
  margin-bottom: -20px;
  margin-top: 10px;
`;

function MatchedBar({ checkedItems, person, handleDiscartAllSelecteds }) {
  const dispatch = useDispatch();

  return (
    <Overflow>
      <FixedBar
        color="primary"
        noContainer
        isVisible={!!checkedItems.length > 0}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        style={{
          background: 'rgb(66, 88, 126)',
          zIndex: 1500,
          position: 'sticky',
          width: 'calc(100% + 40px)',
        }}
      >
        <BarItemSelecteds>
          <span>
            <MdCheckCircle />{' '}
            {plural(
              checkedItems.length,
              'imóvel selecionado',
              'imóveis selecionados'
            )}
          </span>
        </BarItemSelecteds>
        <FixedBar.item>
          <FixedBar.link
            tagName="button"
            onClick={() => {
              dispatch(
                openModalSendPeopleEmail({
                  initialValues: {
                    matcheds_id: checkedItems,
                  },
                  afterClose: () => {},
                })
              );
            }}
          >
            <MdPerson /> Enviar por e-mail e remover da lista
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.item>
          <FixedBar.link tagName="button" onClick={handleDiscartAllSelecteds}>
            <MdDelete /> Remover da lista
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.spacer />
      </FixedBar>
    </Overflow>
  );
}

export default MatchedBar;
