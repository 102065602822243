import React from 'react';
import SortablePropertyGallery from 'components/SortablePropertyGallery';
import { Row } from 'react-flexbox-grid';
import { response } from './data';

function CardCondoPage() {
  return (
    <Row>
      <SortablePropertyGallery data={response.data} />
      {/*{response.data.map((image, i) => (*/}
      {/*  <Col xs={2} key={image.id}>*/}
      {/*    <CardImage*/}
      {/*      isFeatured={i === 0}*/}
      {/*      image={image}*/}
      {/*      onDelete={() => {*/}
      {/*        console.log('ON DELETE');*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*))}*/}
    </Row>
  );
}

CardCondoPage.defaultProps = {};
CardCondoPage.propTypes = {};

export default CardCondoPage;
