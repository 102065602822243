import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
// Components
import { Input, Select, Textarea, Toggle } from 'components/Form';
import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
// Containers
import FieldPerson from 'containers/FieldPerson';
// Validate
import validate from './validate';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDealGain = ({
  people,
  users,
  handleSubmit,
  handleChangeModal,
  handleChangeRealtor,
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <FieldPerson xs={6} name="people_id" />
      <Field
        required
        xs={6}
        valueFormat
        label="Preço do Negócio"
        name="price"
        isAllowed={(values) => {
          const { floatValue } = values;
          return floatValue >= 5 && floatValue <= 10000;
        }}
        component={Input}
      />
    </Row>
    <Row>
      <Field
        required
        xs={6}
        label="Corretor"
        name="user_id"
        component={Select}
        options={users}
        valueKey="id"
        labelKey="name"
        onChange={handleChangeRealtor}
      />
      <Field
        required
        xs={6}
        valueFormat
        label="Valor da Comissão"
        name="commission"
        component={Input}
      />
    </Row>
    <Row>
      <Field xs={12} label="Observação" name="note" component={Textarea} />
    </Row>
    <h5 className="h-margin-bottom--5">
      Remover da lista de nutrição do perfil de busca?
    </h5>
    <p>
      Marcando essa opção, seu cliente não irá mais receber ofertas de imóvel
      por e-mail, tendo em vista que já concretizou o negócio.
    </p>
    <Field name="remove_search_profile" component={Toggle} />
    <ModalFooter>
      <Button
        color="white"
        colorText="secondary"
        onClick={() => handleChangeModal('ModalInfo')}
      >
        Cancelar
      </Button>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success">
        Finalizar
      </Button>
    </ModalFooter>
  </form>
);

FormDealGain.defaultProps = defaultProps;
FormDealGain.propTypes = propTypes;

export default reduxForm({
  form: 'FormDealGain',
  validate,
  enableReinitialize: true,
})(FormDealGain);
