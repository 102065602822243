import Wrapper from "components/Wrapper";
import List from './components/List';

function Locations() {
  return (
    <Wrapper>
      <List />
    </Wrapper>
  );
}

export default Locations;
