import React from 'react';
import { MdMoreVert } from 'react-icons/md';
import {
  Item,
  Header,
  Title,
  Transaction,
  Table,
  Label,
  Value,
  Content,
  Description,
} from '../../styles';
import Button from 'components/Button';
import Dropdown, { List, Item as DropItem } from 'components/Dropdown';
import moment, { format } from 'lib/moment';
import { useDispatch } from 'react-redux';
import { openModalPropertySell } from 'modules/modal';
import { useCan } from 'hooks/useCan';

function ItemSell({ deal, onCloseModalUpdate, onRemoveDeal }) {
  const dispatch = useDispatch();
  const canRemoveDeal = useCan({
    run: 'DYNAMIC_DESTROY',
    permissions: deal?.permissions,
  });

  const canEditDeal = useCan({
    run: 'DYNAMIC_EDIT',
    permissions: deal?.permissions,
  });

  const handleClickUpdate = () => {
    dispatch(
      openModalPropertySell({
        property: deal?.property,
        deal,
        personId: deal?.people_id,
        userId: deal?.user_id,
        onSubmitSuccess: onCloseModalUpdate,
      })
    );
  };

  return (
    <Item>
      <Header>
        <Title>
          Registro de <Transaction color="success">Venda</Transaction>
        </Title>
        <Dropdown isDropHover>
          <Button size="medium" color="white">
            Opções
            <MdMoreVert />
          </Button>
          <List>
            {canEditDeal && (
              <DropItem>
                <button onClick={handleClickUpdate}>Editar</button>
              </DropItem>
            )}
            {canRemoveDeal && (
              <DropItem>
                <button onClick={onRemoveDeal(deal?.id)}>Excluir</button>
              </DropItem>
            )}
          </List>
        </Dropdown>
      </Header>
      <Content>
        <Table>
          <tr>
            <Label>Data do registro:</Label>
            <Value>
              {deal?.created_at
                ? moment(deal?.created_at, format.datetime).format(format.date)
                : '-'}
            </Value>
          </tr>
          <tr>
            <Label>Data do negócio:</Label>
            <Value>{deal?.earned_at || '-'}</Value>
          </tr>
          <tr>
            <Label>Corretor:</Label>
            <Value>{deal?.user?.name}</Value>
          </tr>
          <tr>
            <Label>Cliente:</Label>
            <Value>{deal?.people?.name}</Value>
          </tr>
        </Table>
        <Table style={{ width: '246px' }}>
          <tr>
            <Label>Valor do negócio:</Label>
            <Value price>{deal?.price || '-'}</Value>
          </tr>
          <tr>
            <Label>Valor da comissão:</Label>
            <Value price>{deal?.commission || '-'}</Value>
          </tr>
          <tr>
            <Label>Valor imobiliária:</Label>
            <Value price>{deal?.real_estate_commission || '-'}</Value>
          </tr>
        </Table>
        <Description>
          <strong>Observação:</strong> {deal?.note || '-'}
        </Description>
      </Content>
    </Item>
  );
}

export default ItemSell;
