import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
`;

export const Btn = styled.button`
  all: unset;

  background: ${(p) => p.theme.colors.success};
  color: #fff;
  cursor: pointer;

  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  ${(p) =>
    p.disabled &&
    css`
      background: #000;
    `}

  span {
    font-size: 14px;
    line-height: 0;
  }
`;

export const Qtd = styled.span`
  position: relative;
  top: -1px;
  font-size: 16px;
  font-weight: 600;
  width: 19px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
`;
