import React from 'react';
import Item from './components/Item';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Content, Header, TooltipC, Wrapper } from './styles';
import { humanizeSeconds } from 'lib/date-helpers';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipC>
        <p>{payload[0].payload.date}</p>
        <p className="label">{`Visitantes: ${payload[0].value}`}</p>
      </TooltipC>
    );
  }

  return null;
};

const CustomYAxisTick = (props) => {
  const { x, y, payload } = props;
  const value = Math.round(payload.value); // Arredonde o valor para o número inteiro mais próximo
  return (
    <text x={x} y={y} dy={-10} textAnchor="middle" fill="#666">
      {value}
    </text>
  );
};

function Graph({ timeseries, aggregate }) {
  return (
    <Wrapper>
      <Header>
        <Item
          title="VISITAS ÚNICAS"
          value={aggregate?.visitors?.value}
          percent={aggregate?.visitors?.change}
        />
        <Item
          title="TOTAL DE VISITAS"
          value={aggregate?.visits?.value}
          percent={aggregate?.visits?.change}
        />
        <Item
          title="VISUALIZAÇÕES"
          value={aggregate?.pageviews?.value}
          percent={aggregate?.pageviews?.change}
        />
        <Item
          title="MÉDIA POR VISITA"
          value={aggregate?.views_per_visit?.value}
          percent={aggregate?.views_per_visit?.change}
        />
        <Item
          title="MÉDIA DE TEMPO"
          value={humanizeSeconds(aggregate?.visit_duration?.value)}
          percent={aggregate?.visit_duration?.change}
        />
      </Header>
      <Content>
        <ResponsiveContainer height={320}>
          <AreaChart
            data={timeseries}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#DCE1F3" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#DCE1F3" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              // tick={CustomXAxisTick}
              interval={Math.ceil(timeseries?.length / 10)}
              dataKey="date_formated"
            />
            <YAxis allowDecimals={false} />
            <Tooltip content={<CustomTooltip />} />
            <Area
              label="Teste"
              type="linear"
              dataKey="visitors"
              stroke="#576AC2"
              fillOpacity={1}
              strokeWidth={3}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Content>
    </Wrapper>
  );
}

export default Graph;
