import React from 'react';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Dropdown, { List, Item } from 'components/Dropdown';
import Pagination from 'components/Pagination';
import { size } from 'lodash';
import Nodata from 'components/Nodata';
import Loading from 'components/Loading';

// import from './styles';

function TablePeople({
  isFetching,
  data,
  noData,
  pagination,
  onClickPerson,
  onPageChange,
  onClickEdit,
  onClickRemove,
}) {
  const hasData = size(data) > 0;

  if (isFetching) return <Loading isVisible isBlock />;

  if (!hasData && noData) {
    return <Nodata {...noData} />;
  }

  return (
    <>
      <table className="Table">
        <thead>
          <tr>
            <td>Nome</td>
            <td>Corretor</td>
            <td>Última Interação</td>
            <td>Origem</td>
            <td width={1} />
          </tr>
        </thead>
        <tbody>
          {data?.map((person) => (
            <tr key={person.id} onClick={() => onClickPerson(person)}>
              <td>{person?.name}</td>
              <td>{person?.user?.name || '--'}</td>
              <td>{person?.updated_at}</td>
              <td>{person?.reception_source?.name || 'Indefinido'}</td>
              <td>
                <Dropdown
                  isDropHover
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <Button color="white" colorText="primary">
                    Opcões <Icon name="chevron-down" />
                  </Button>
                  <List>
                    {onClickEdit && (
                      <Item>
                        <button onClick={() => onClickEdit(person)}>
                          Editar
                        </button>
                      </Item>
                    )}
                    {onClickRemove && (
                      <Item>
                        <button onClick={() => onClickRemove(person)}>
                          Excluir
                        </button>
                      </Item>
                    )}
                  </List>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pagination && (
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      )}
    </>
  );
}

TablePeople.propTypes = {};
TablePeople.defaultProps = {};

export default TablePeople;
