import api from 'services';

/**
 * Listagem de débitos agrupados por mês
 */
export const getDebitsByMonth = () => api.getList('sales/debits/monthly');

/**
 * Busca o extrato dos pagamentos
 */
export const getExtract = (params) => api.getList('sales/extract', params);

/**
 * Busca os debitos
 * @param month
 * @param year
 */
export const getDebitsByMonthYear = ({ month, year }) =>
  api.getList('sales/debits/', {
    filter: {
      month: `created_at,${month}`,
      year: `created_at,${year}`,
    },
  });

/**
 * Pega um débito pelo id
 * @param id
 * @return {*|Promise<AxiosResponse<T>>}
 */
export const getDebit = (id) => api.getOne('sales/debits', id);
