import React from 'react';
// Components
import MainTitle from 'components/MainTitle';
// Components
import SeoForm from './components/SeoForm';
// Service
import * as seoService from 'services/sites/seo';

class Seo extends React.Component {
  state = {
    formValues: {},
  };

  componentDidMount() {
    seoService.getOne().then(({ data }) => {
      this.setState({ formValues: data });
      return data;
    });
  }

  handleSubmit = (values) => seoService.update(values);

  render() {
    return (
      <>
        <MainTitle title="Seo" text="Configure o SEO do seu site." />
        <SeoForm
          onSubmit={this.handleSubmit}
          initialValues={this.state.formValues}
        />
      </>
    );
  }
}

export default Seo;
