import React, { useMemo, useCallback } from 'react';
import { Row } from 'react-flexbox-grid';
import CountryField from 'containers/LocationFields/CountryField';
import StateField from 'containers/LocationFields/StateField';
import CityField from 'containers/LocationFields/CityField';
import FormBox from 'components/FormBox';
import NeighborhoodsSelect from '../../components/NeighborhoodsSelect';
import Button from 'components/Button';
// import { Container } from './styles';

const MultipleLocationContainer = ({ fields, change }) => {
  const total = useMemo(() => {
    return fields.length;
  }, [fields]);

  const isLast = useCallback(
    index => {
      return index === total - 1;
    },
    [total]
  );

  if (!fields) return null;
  
  return (
    <>
      {fields?.map((location, index, fields) => {
        const values = fields.get(index);
        return (
          <FormBox
            styleHeader={{ width: '100%' }}
            title="Localização"
            actions={
              index > 0 && (
                <Button color="danger" onClick={() => fields.remove(index)}>
                  Remover
                </Button>
              )
            }
          >
            <Row>
              <CountryField xs={3} name={`${location}.country_id`} />
              <StateField
                xs={3}
                name={`${location}.state_id`}
                countryId={values.country_id}
              />
              <CityField
                xs={3}
                name={`${location}.city_id`}
                stateId={values.state_id}
              />
            </Row>
            <NeighborhoodsSelect
              change={change}
              fields={fields}
              name={location}
              cityId={values.city_id}
            />

            {isLast(index) && (
              <Button
                type="button"
                onClick={() => fields.push({})}
                style={{ marginTop: '15px' }}
              >
                Adicionar outra cidade
              </Button>
            )}
          </FormBox>
        );
      })}
    </>
  );
};

export default MultipleLocationContainer;
