import { createSelector } from 'reselect';
// Modules
import { openConfirmation } from 'containers/ModalConfirmation/module';
// Services
import * as condosService from '../services/condos';
// Helpers
import { createModule, item } from '../lib/reducer-helpers';
import { objToArray } from '../lib/object-helpers';
import { checkedSelector } from '../lib/array-helpers';

export const condosSelector = state => state.condos.condos;
export const isFetchingCondosSelector = state => state.condos.meta.isFetching;
export const condoCharacteristicsSelector = id =>
  createSelector(condosSelector, condos => {
    if (id) {
      const condo = condos.find(condo => condo.id === id);

      if (condo) {
        return condo.condos_characteristics;
      }

      return null;
    }
  });

const createAction = createModule('condos');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const ADD_CONDO = createAction('ADD_CONDO');
const ALTER_CONDO = createAction('ALTER_CONDO');
const REMOVE_CONDO = createAction('REMOVE_CONDO');

const initialState = {
  condos: [],
  meta: {
    isFetching: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE:
      return {
        ...state,
        condos: action.condos,
        meta: { ...state.meta, isFetching: false }
      };
    case REMOVE_CONDO:
      return {
        ...state,
        condos: item.remove(state.condos, action)
      };
    case ADD_CONDO:
      return {
        ...state,
        condos: item.add(state.condos, action.condo)
      };
    case ALTER_CONDO:
      return {
        ...state,
        condos: item.alter(state.condos, action.condo)
      };
    default:
      return state;
  }
}

// Action Creators
export const requestCondos = () => ({ type: REQUEST });
export const receiveCondos = condos => ({ type: RECEIVE, condos });
export const addCondo = condo => ({ type: ADD_CONDO, condo });
export const alterCondo = condo => ({ type: ALTER_CONDO, condo });
export const removeCondo = id => ({ type: REMOVE_CONDO, id });

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getCondos = params => dispatch => {
  dispatch(requestCondos());

  return condosService
    .getList({
      include: 'establishments',
      sort: 'title',
      ...params
    })
    .then(({ data }) => {
      dispatch(receiveCondos(data));
    });
};

/**
 * Adiciona um condominio na lista
 * @param condo
 */
export const handleAddCondo = condo => dispatch => dispatch(addCondo(condo));

/**
 * Remove um condomínio da lista
 * @param id
 */
export const handleRemoveCondo = (id, params) => dispatch => {
  dispatch(
    openConfirmation({
      title: 'Deseja Excluir o Condomínio?',
      text: 'Essa ação não poderá ser desfeita',
      request: () => condosService.remove(id),
      ...params,
      onSuccess: () => {
        // Verifica se tem onSuccess
        if (params.onSuccess) params.onSuccess();

        // Remove o condomínio da store
        dispatch(removeCondo(id));
      }
    })
  );
};

export function handleFormCondo({ condos_characteristics, ...rest }) {
  return dispatch => {
    const values = {
      ...rest,
      condos_characteristics: objToArray(condos_characteristics).filter(
        checkedSelector()
      )
    };

    const isEditting = !!rest.id;

    if (isEditting) {
      return condosService.update(values).then(res => {
        dispatch(alterCondo(res.data));
        return res;
      });
    }

    return condosService.create(values).then(res => {
      dispatch(addCondo(res.data));
      return res;
    });
  };
}
