import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

// Components
import Container from 'components/Container';
import { FieldBool, Input, Select } from 'components/Form';
import GroupControl, { GroupItem } from 'components/GroupControl';
import MainTitle from 'components/MainTitle';
import ResultValue from 'pages/PropertyAdd/components/ResultValue';

// HOC
import { withId } from '../../HOC/withId';

// Hooks
import useFormValue from 'hooks/useFormValue';
import { useRequest } from 'hooks/useRequest';

// Services
import * as indexesService from 'services/financialIndex';
import * as financialService from 'services/propertiesPartial/financial';

// Constants
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import Button from 'components/Button';
import Loading from 'components/Loading';
import MultipleDayPicker from 'components/MultipleDayPicker';
import { TYPE_TRANSACTION } from 'constants/options';
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import { finishResponse } from 'pages/PropertyStore/helpers';
import { MdInfo } from 'react-icons/md';
import { useParams } from 'react-router';
import { Tip } from './styles';
import FieldWarranties from 'containers/FieldWarranties';

const isRealIndex = (id, indexes) => {
  if (!indexes || indexes?.length <= 0) return null;

  return indexes.find((index) => id === index.id && index.name === 'Real');
};

const Navbar = ({ onPreviousClick, onNextClick, className }) => (
  <>
    <div className={`h-flex ${className} h-flex`} style={{ marginBottom: 15 }}>
      <div className="h-flex__cell h-flex__cell--shrink Form--inline">
        <Field label="Máximo de hóspedes" name="max_people" component={Input} />
      </div>
      <div className="h-flex__cell--grow" />
      <Button
        color="white"
        colorText="primary"
        onClick={() => onPreviousClick()}
      >
        ← Anterior
      </Button>
      <Button color="white" colorText="primary" onClick={() => onNextClick()}>
        Próximo →
      </Button>
    </div>
    <p className="FormLabel">Marque os dias que o imóvel NÃO está disponível</p>
  </>
);

const Financial = ({
  handleSubmit,
  initialize,
  change,
  onSubmit,
  submitting,
}) => {
  const {
    price,
    transaction,
    price_financial_index_id,
    is_exchangeable,
    is_price_shown,
    is_season_available,
    is_shown_previous_price,
  } = useFormValue([
    'price',
    'transaction',
    'territorial_tax_price',
    'condominium_price',
    'taxes_price',
    'price_financial_index_id',
    'territorial_tax_financial_index_id',
    'condominium_financial_index_id',
    'taxes_financial_index_id',
    'change',
    'is_exchangeable',
    'is_price_shown',
    'is_season_available',
    'is_shown_previous_price',
    'previous_price',
  ]);

  const now = new Date();

  const isRent = useMemo(
    () => transaction === '2' || transaction === 2,
    [transaction]
  );

  const sizes = useMemo(() => {
    if (isRent) {
      return {
        territorial_tax_price: 3,
        territorial_tax_type: 3,
        condominium_price: 3,
      };
    }

    return {
      territorial_tax_price: 3,
      territorial_tax_type: 3,
      condominium_price: 3,
    };
  }, [isRent]);

  const { data: _indexes } = useRequest({
    request: indexesService.getList,
    params: {
      sort: '-real_estate_id',
    },
  });

  const { data, fetchData } = useRequest({
    request: financialService.getOne,
    initialFetch: false,
    initialState: {},
  });
  const { id } = useParams();

  const indexes = _indexes.map((index) => {
    if (!index.is_default) {
      return {
        ...index,
        symbol: index.name,
      };
    }

    return index;
  });

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const firstFinancialId = useMemo(() => {
    try {
      return indexes?.[0]?.id;
    } catch {
      return null;
    }
  }, [indexes]);

  useEffect(() => {
    let initialValues = data;

    if (!initialValues?.transaction) {
      initialValues.transaction = localStorage.getItem('transaction') || '1';
    }

    if (!initialValues?.price_financial_index_id) {
      initialValues.price_financial_index_id = firstFinancialId;
    }

    if (!initialValues?.territorial_tax_financial_index_id) {
      initialValues.territorial_tax_financial_index_id = firstFinancialId;
    }

    if (!initialValues?.condominium_financial_index_id) {
      initialValues.condominium_financial_index_id = firstFinancialId;
    }

    if (!initialValues?.taxes_financial_index_id) {
      initialValues.taxes_financial_index_id = firstFinancialId;
    }

    initialize({ is_season_available: false, ...initialValues });
  }, [data, firstFinancialId]);

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle
        title="Preço"
        text="Defina o preço deste imóvel e outras informações importantes para o seu cliente."
      />
      <Container>
        <Row className="no-transition">
          <Field
            xs={3}
            label="Tipo do negócio"
            name="transaction"
            component={Select}
            options={TYPE_TRANSACTION}
            placeholder=""
            onChange={() => {
              change('season_bookings', null);
            }}
          />
          <Col xs={3}>
            <GroupControl
              label={
                <>
                  Preço do Imóvel
                  <span
                    data-html
                    className="h-color--secondary h-margin-left--5"
                    data-tip="O Preço do imóvel sempre será apresentado em Reais no seu site.<br/>
Você pode gerenciar os índices financeiros acessando o Menu Ajustes / Índices Financeiros"
                  >
                    <MdInfo />
                  </span>
                </>
              }
            >
              <GroupItem>
                <Field
                  valueFormat
                  prefix=""
                  name="price"
                  component={Input}
                  inputProps={{
                    decimalScale: isRealIndex(price_financial_index_id, indexes)
                      ? 2
                      : 4,
                  }}
                />
              </GroupItem>
              <GroupItem size="small">
                <Field
                  autoWidth
                  name="price_financial_index_id"
                  component={Select}
                  valueKey="id"
                  labelKey="symbol"
                  options={indexes}
                  placeholder=""
                />
              </GroupItem>
            </GroupControl>
            <ResultValue
              financialIndex={price_financial_index_id}
              indexes={indexes}
              price={price}
            />
          </Col>

          <FieldBool
            canRemoveSelf={false}
            xs={3}
            label="Mostrar preço no site?"
            name="is_price_shown"
            onChange={(value) => {
              if (value === false) {
                change('price_alternative_text', 'Consulte');
              } else {
                change('price_alternative_text', '');
              }
            }}
          />

          <Field
            disabled={is_price_shown}
            xs={3}
            label="Mostrar no lugar do preço:"
            name="price_alternative_text"
            component={Input}
            maxLength={120}
            placeholder="Consulte..."
          />

          <FieldBool
            buttonTemplate
            xs={3}
            name="is_shown_previous_price"
            label={
              <span style={{ fontSize: '13.5px' }}>
                Mostrar alteração de preço no site?
              </span>
            }
            onChange={(value) => {
              if (value === false) {
                change('previous_price', '');
              }
            }}
          />

          <Field
            xs={3}
            disabled={!is_shown_previous_price}
            valueFormat
            prefix=""
            label="Preço anterior(riscado)"
            name="previous_price"
            component={Input}
            placeholder="Digite o preço atual"
          />

          <Field
            xs={sizes.territorial_tax_price}
            valueFormat
            prefix=""
            label={
              <>
                {'Preço do IPTU'}
                <span
                  data-html
                  className="h-color--secondary h-margin-left--5"
                  data-tip="Caso o preço do IPTU seja informado como ANUAL, ele não será <br/>
              somado ao custo mensal do imóvel e será apresentado ao lado, como custo anual."
                >
                  <MdInfo />
                </span>
              </>
            }
            name="territorial_tax_price"
            component={Input}
            placeholder="Digite o preço do iptu"
          />
          <Col xs={sizes.territorial_tax_type}>
            <FieldBool
              label="Período"
              name="territorial_tax_type"
              canRemoveSelf
              options={[
                { label: 'Anual', value: 1 },
                { label: 'Mensal', value: 2 },
              ]}
            />
          </Col>
          <Field
            label="Preço Condomínio"
            xs={sizes.condominium_price}
            valueFormat
            prefix=""
            name="condominium_price"
            component={Input}
          />
          {!isRent && (
            <>
              <FieldBool
                buttonTemplate
                xs={3}
                name="has_finance"
                label="Está financiado?"
                onChange={(e, value) => {
                  if (value) {
                    change('is_financeable', true);
                  }
                }}
              />
              <FieldBool
                buttonTemplate
                xs={3}
                name="is_financeable"
                label="Aceita financiamento?"
              />
              <FieldBool
                xs={3}
                label="Minha Casa Minha Vida?"
                name="is_financeable_mcmv"
              />
            </>
          )}
          <Field
            xs={3}
            valueFormat
            prefix=""
            label="Total mensal em taxas (se houver)"
            name="taxes_price"
            component={Input}
          />
          {isRent ? <FieldWarranties xs={3} /> : null}
          <Field
            xs={isRent ? 9 : 3}
            label="Descrição das Taxas"
            name="taxes_description"
            placeholder="Descrição das taxas"
            component={Input}
          />
          {isRent && (
            <>
              <FieldBool
                label="Imóvel de Temporada?"
                xs={3}
                canRemoveSelf={false}
                name="is_season_available"
              />
              {is_season_available && (
                <Col xs={12}>
                  <Field
                    name="season_bookings"
                    component={MultipleDayPicker}
                    disabledDays={[{ before: now }]}
                    navbarElement={<Navbar />}
                  />
                </Col>
              )}
            </>
          )}
        </Row>

        {!isRent && (
          <Row>
            <FieldBool
              buttonTemplate
              xs={3}
              name="is_exchangeable"
              label="Aceita permuta?"
            />
            <Field
              disabled={!is_exchangeable}
              required={is_exchangeable}
              xs={9}
              label="Descrição das Permutas"
              name="exchange_note"
              placeholder="Descrição das Permutas"
              component={Input}
            />
          </Row>
        )}
      </Container>

      <p></p>
      <Tip className="h-margin-top--15">
        Dica: Para adicionar uma segunda transação, utilize o recurso ”Duplicar
        Imóvel” nas opções da ”Vida do imóvel” e edite apenas o preço.{' '}
        <a
          href="https://scribehow.com/shared/Duplicar_o_imovel__E1dVMjm8Sh2XTsCP2BmixQ?referrer=search"
          target="_blank"
          rel="noopener noreferrer"
          className="h-link"
        >
          Saiba mais
        </a>
      </Tip>

      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyFinancial',
    onSubmit: (values, d, p) => {
      if (values.transaction === '2') {
        values.is_exchangeable = false;
      }

      return financialService.update(values).then(finishResponse(values, d, p));
    },
    onSubmitSuccess: (response, dispatch, props) => {
      props.initialize(response?.data);

      if (!props.isEditting) {
        props.next('characteristics');
      }
    },
  }),
  withFormConfirmation()
)(Financial);
