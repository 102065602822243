import React, { useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Containers
import FieldSelectUser from 'containers/FieldSelectUser';
// Components
import Button from 'components/Button';
import ModalFooter from 'components/Modal/components/ModalFooter';
import { STATUS_CRECI } from '../../../constants/constants';
import { FormLabel } from 'components/Form';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormUserDelete = ({
  currentUser,
  handleSubmit,
  submitting,
  handleClose,
}) => {
  const firstName = currentUser.name.split(' ')[0];

  const msg = useMemo(() => {
    if (currentUser?.people_exists && currentUser?.properties_exists) {
      return `A quem será atribuída a responsabilidade pelos imóveis e clientes deste corretor que está sendo excluído?`;
    }

    if (currentUser?.people_exists) {
      return `A quem será atribuída a responsabilidade pelos clientes deste corretor que está sendo excluído?`;
    }

    return `A quem será atribuída a responsabilidade pelos imóveis deste corretor que está sendo excluído?`;
  }, [currentUser]);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <FormLabel>{msg}</FormLabel>
        </Col>
      </Row>
      <Row>
        <FieldSelectUser
          xs={6}
          label={null}
          name="user_id"
          filter={(user) =>
            user.id !== currentUser.id &&
            user.creci_status === STATUS_CRECI.VERIFIED
          }
          params={{ filter: {} }}
        />
      </Row>

      {currentUser?.properties_agent_exists ? (
        <>
          <Row>
            <Col xs={12}>
              <FormLabel>
                A quem será atribuída a responsabilidade pelo agenciamento dos
                imóveis que estavam com esta pessoa?
              </FormLabel>
            </Col>
          </Row>
          <Row>
            <FieldSelectUser
              label={null}
              xs={6}
              name="agent_id"
              filter={(user) => user.id !== currentUser.id}
              params={{ filter: {} }}
            />
          </Row>
        </>
      ) : null}

      <ModalFooter>
        <Button
          type="button"
          color="white"
          colorText="secondary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="danger" disabled={submitting}>
          Mover e apagar {firstName}
        </Button>
      </ModalFooter>
    </form>
  );
};

FormUserDelete.defaultProps = defaultProps;
FormUserDelete.propTypes = propTypes;

export default reduxForm({
  form: 'FormUserDelete',
  enableReinitialize: true,
  validate: (values, props) => {
    const errors = {};

    if (props?.currentUser?.properties_exists && !values.user_id) {
      errors.user_id = 'Campo obrigatório';
    }

    if (props?.currentUser?.properties_agent_exists && !values.agent_id) {
      errors.agent_id = 'Campo obrigatório';
    }
    return errors;
  },
})(FormUserDelete);
