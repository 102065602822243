import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Pages
import pages from './pages';
import { USER_PERMISSIONS } from 'constants/rules';

class Routes extends React.Component {
  hasPermission(permission) {
    const { permissions } = this.props;
    return permissions.includes(permission);
  }

  get redirect() {
    if (this.hasPermission('EDIT_MESSAGE')) {
      return '/config/defaultTexts';
    }

    if (this.hasPermission('EDIT_WATERMARK')) {
      return '/config/watermark/show';
    }

    if (this.hasPermission('VIEW_BACKUPS')) {
      return '/config/bkp/show';
    }

    if (this.hasPermission('EDIT_INTEGRATION')) {
      return '/integrations';
    }

    if (this.hasPermission(USER_PERMISSIONS.EDIT_ESTABLISHMENTS)) {
      return '/config/registrations/establishments';
    }

    if (this.hasPermission(USER_PERMISSIONS.EDIT_CHARACTERISTICS)) {
      return '/config/registrations/characteristics';
    }

    if (this.hasPermission(USER_PERMISSIONS.EDIT_LOSS_REASONS)) {
      return '/config/registrations/lossReasons';
    }

    if (this.hasPermission(USER_PERMISSIONS.EDIT_CONDOS)) {
      return '/config/registrations/condos';
    }

    if (this.hasPermission(USER_PERMISSIONS.EDIT_ADDRESSES)) {
      return '/config/registrations/neighborhoods';
    }

    if (this.hasPermission(USER_PERMISSIONS.EDIT_PEOPLE_GROUPS)) {
      return '/config/registrations/groups';
    }

    return '/no-permission';
  }

  render() {
    return (
      <Switch>
        {/* Opções */}
        <Route path="/config/logo/:type" component={pages.LogoOptions} />

        {/* Modelos de contrato */}
        <Route
          exact={false}
          path="/config/documents"
          component={pages.Documents}
        />

        {/* Textos padrões da imobiliaria */}
        <Route path="/config/defaultTexts" component={pages.DefaultTexts} />

        {/* Usuários */}
        <Route path="/config/users/permissions" component={pages.Permissions} />

        {/* Backup */}
        <Route path="/config/bkp/show" component={pages.Backup} />

        {/* Analytics */}
        <Route path="/config/analytics/show" component={pages.Analytics} />

        {/* Registrations */}
        <Route path="/config/registrations" component={pages.Registrations} />

        <Route path="/config/general" component={pages.General} />

        {/* Registrations */}
        <Route path="/config/properties" component={pages.Properties} />

        {/* Profiles */}
        <Route path="/config/profile" component={pages.Profile} />

        {/* CRM */}
        <Redirect from="/config/crm/show" to="/site/config/crm" />

        <Redirect from="/config/portals" to="/portals" />
        <Redirect to={this.redirect} />
      </Switch>
    );
  }
}

export default Routes;
