import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const Item = styled.li`
  padding: 20px;
`;

export const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Account = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const Icon = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 4px;
`;
export const Text = styled.p`
  color: #666666;
  font-size: 12px;
`;

export const ItemAction = styled.div``;

export const Label = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  color: #4c555f;
`;

export const Value = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const RowBottom = styled.div`
  padding: 10px;
  background: #f5f5f6;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
`;
