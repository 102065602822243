import React from 'react';
import { connect } from 'react-redux';
// Components
import DomainsPreRegister from '../../components/DomainsPreRegister';
// Modules
import { openModalAddDomain, openModalRegisterDomain } from 'modules/modal';

const PreRegister = ({
  openModalAddDomain,
  openModalRegisterDomain,
  history
}) => (
  <DomainsPreRegister
    handleClickOpenAddDomain={() => {
      openModalAddDomain({
        onSubmitSuccess: () => history.push('/site/config/domains/show')
      });
    }}
    handleClickRegisterDomain={() => {
      openModalRegisterDomain({
        onSubmitSuccess: () => history.push('/site/config/domains/show')
      });
    }}
  />
);

const mapDispatchToProps = {
  openModalRegisterDomain,
  openModalAddDomain
};

export default connect(null, mapDispatchToProps)(PreRegister);
