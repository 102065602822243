import { createModule } from 'lib/reducer-helpers';

const createAction = createModule('supportChat');

export const PAGES = {
  INITIAL: 'Initial',
  SELECT_SECTOR: 'SelectSector',
  FORM: 'Form',
};

export const SECTORS = {
  SUPPORT: 1,
  COMMERCIAL: 2,
  FINANCIAL: 3,
  OTHER: 4,
};

export const SECTORS_NAME = {
  [SECTORS.SUPPORT]: 'Suporte técnico',
  [SECTORS.COMMERCIAL]: 'Comercial',
  [SECTORS.FINANCIAL]: 'Financeiro',
  [SECTORS.OTHER]: 'Outros assuntos',
};

const initialState = {
  isOpen: false,
  page: PAGES.INITIAL,
  sector: null,
};

const OPEN = createAction('OPEN');
const CLOSE = createAction('CLOSE');
const SET_SECTOR = createAction('SET_SECTOR');
const CHANGE_PAGE = createAction('CHANGE_PAGE');

export const isOpenSelector = (state) => state.supportChat.isOpen;
export const pageSelector = (state) => state.supportChat.page;
export const sectorSelector = (state) => state.supportChat.sector;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      return { ...initialState, isOpen: true };
    case CLOSE:
      return { ...state, isOpen: false };
    case CHANGE_PAGE:
      return { ...state, page: action.page };
    case SET_SECTOR:
      return { ...state, sector: action.sector };
    default:
      return state;
  }
}

export const Actions = {
  open: () => ({
    type: OPEN,
  }),
  close: () => ({
    type: CLOSE,
  }),
  changePage: (page) => ({
    type: CHANGE_PAGE,
    page,
  }),
  setSector: (sector) => ({
    type: SET_SECTOR,
    sector,
  }),
};
