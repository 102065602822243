import PropTypes from 'prop-types';
import { Item } from '../styles';

const defaultProps = {
  isChecked: false,
  children: null,
};

const propTypes = {
  isChecked: PropTypes.bool,
  children: PropTypes.node,
};

const PillItem = ({ children, isChecked, ...props }) => (
  <Item {...props} isChecked={isChecked}>
    {children}
  </Item>
);

PillItem.propTypes = propTypes;
PillItem.defaultProps = defaultProps;

export default PillItem;
