import Table from 'components/Table';
import * as S from './styles';
import { Nodata } from './Nodata';
import TextOverflow from 'components/TextOverflow';
import { MdOutlineDelete, MdOutlineMode } from 'react-icons/all';
import {
  useModalMovimentDestroy,
  useModalMovimentDetail,
  useModalPosting,
} from 'features/Financial';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { useMovimentPay } from '../../../../hooks/useMoviments';
import { useHistory } from 'react-router';
import classnames from 'classnames';

export function TableMoviments({ data }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleOpenModalPosting } = useModalPosting();
  const { handleOpenModalMovimentDestroy } = useModalMovimentDestroy();
  const { handleOpenModalMovimentDetail } = useModalMovimentDetail();
  const { handleMovimentPay } = useMovimentPay();

  const handleClickCell = (e) => {
    e.stopPropagation();
  };

  const hasCreditCardId = (item, classNames) => {
    return classnames(classNames, {
      'h-link': item.credit_card_id,
    });
  };

  if (!data || data.length <= 0) {
    return <Nodata />;
  }

  return (
    <S.WrapperTable>
      <Table
        data={data}
        renderTitle={() => (
          <tr>
            <th width={124}>Data</th>
            <th width={120}>Ocorrência</th>
            <th width={234}>Descrição</th>
            <th width={110} className="Table__cell--money">
              Valor
            </th>
            <th width={80} align="center">
              Situação
            </th>
            <th width={80} />
          </tr>
        )}
        renderItem={(item) => {
          return (
            <tr
              key={item.id}
              onClick={() => {
                if (!item.credit_card_id) {
                  handleOpenModalMovimentDetail({
                    movimentId: item.id,
                    postingId: item.posting_id,
                  });
                } else {
                  history.push(
                    `/financial/credit-card/${item.credit_card_id}/${item.id}`
                  );
                }
              }}
            >
              <td className={hasCreditCardId(item)}>{item.billing_date}</td>
              <td className={hasCreditCardId(item)}>{item.ocurrency}</td>
              <td className={hasCreditCardId(item)}>
                <S.Description>
                  <S.Ball
                    color={item?.category.color}
                    data-tip={item?.category.title}
                  />
                  <TextOverflow width={140}>{item?.description}</TextOverflow>
                </S.Description>
              </td>
              <td
                className={hasCreditCardId(
                  item,
                  'Table__cell--money h-text-nowrap'
                )}
              >
                {item.value}
              </td>
              <td onClick={handleClickCell}>
                {item.situation ? (
                  <S.Situation
                    style={{ cursor: item.can_edit ? 'pointer' : 'default' }}
                    variant={item.situation}
                    onClick={() => {
                      handleMovimentPay({
                        moviment: item,
                        onSuccess: () => {
                          dispatch(submit('FilterPostings'));
                        },
                      });
                    }}
                  >
                    {item.situation}
                  </S.Situation>
                ) : null}
              </td>
              <td onClick={handleClickCell}>
                {item.can_edit ? (
                  <S.Actions>
                    <S.Action
                      onClick={() => {
                        handleOpenModalPosting({
                          movimentId: item.id,
                          postingId: item.posting_id,
                          onSuccess: () => {
                            dispatch(submit('FilterPostings'));
                          },
                        });
                      }}
                    >
                      <MdOutlineMode />
                    </S.Action>
                    <S.Action
                      onClick={() => {
                        handleOpenModalMovimentDestroy({
                          movimentId: item.id,
                          postingId: item.posting_id,
                          onSuccess: () => {
                            dispatch(submit('FilterPostings'));
                          },
                        });
                      }}
                    >
                      <MdOutlineDelete className="h-color--danger" />
                    </S.Action>
                  </S.Actions>
                ) : null}
              </td>
            </tr>
          );
        }}
      />
    </S.WrapperTable>
  );
}
