import React from 'react';
import Bar from './components/Bar';
import { mix } from 'polished';
import { Wrapper } from './styles';

function StagesList({ data }) {
  // Function to calculate color based on index
  const calculateColor = (index) => {
    const initialColor = '#00274C'; // Initial color
    const finalColor = '#1972C6'; // Final color

    return mix(index / (data.length - 1), initialColor, finalColor);
  };

  // Função para calcular o tamanho com base no índice
  const calculateSize = (index) => {
    const maxSize = 100; // Tamanho máximo
    const minSize = 60; // Tamanho mínimo
    const stepSize = (maxSize - minSize) / (data.length - 1);
    const size = maxSize - index * stepSize;
    return `${Math.min(Math.max(size, minSize), maxSize)}`; // Garantindo que o tamanho esteja entre o mínimo e o máximo
  };

  if (!data) return null;

  return (
    <Wrapper>
      {data.map((item, index) => (
        <Bar
          key={'stages-list' + item.id}
          size={calculateSize(index)}
          color={calculateColor(index)}
          data={item}
        />
      ))}
    </Wrapper>
  );
}

export default StagesList;
