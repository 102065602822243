import React from 'react';
import { Label, NumberBig, NumberSmall, Wrapper } from './styles';
import { getPropsByValue } from 'lib/rating-helpers';
import { ThemeProvider } from 'styled-components';

function RatingNumber({ value, max = 10, onClick }) {
  const props = getPropsByValue(value);

  return (
    <ThemeProvider
      theme={{
        color: props.color,
      }}
    >
      <Wrapper onClick={onClick}>
        <div>
          <div>
            <NumberBig>{value}</NumberBig> / <NumberSmall>{max}</NumberSmall>
          </div>
          <Label>{props.label}</Label>
        </div>
      </Wrapper>
    </ThemeProvider>
  );
}

export default RatingNumber;
