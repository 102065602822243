import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';
import { isInputDirty } from '../../../../lib/formHelpers';
import { cleanObject } from '../../../../lib/helpers';
// componentes de formulario
import Errors from '../Errors';
import FormCount from '../FormCount';
import FormLabel from '../FormLabel';

const propTypes = {
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  formGroupClassName: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  labelText: PropTypes.node,
  hideError: PropTypes.bool,
  maxLength: PropTypes.number,
  meta: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }).isRequired,
  children: PropTypes.node,
};

const defaultProps = {
  isRequired: false,
  isDisabled: false,
  hideError: false,
  children: null,
  labelText: null,
  maxLength: null,
  formGroupClassName: null,
  input: {
    value: null,
  },
  meta: {
    active: null,
    touched: null,
    error: null,
  },
};

function FormGroup({
  xs,
  sm,
  md,
  lg,
  hideError,
  maxLength,
  labelText,
  isRequired,
  isDisabled,
  isLoading,
  isPrivate,
  className,
  formGroupClassName,
  input,
  style,
  labelRender,
  hidden,
  meta,
  meta: { active, touched, error },
  children,
  ...rest
}) {
  // se tiver alguma das props xs/sm/md/lg ele usa o componente col
  const TagName = xs || sm || md || lg ? Col : 'div';

  // pega as colunas do input
  const sizes = {
    ...cleanObject({
      xs,
      sm,
      md,
      lg,
    }),
  };

  // verifica se tem erro o componente
  const hasError = !!(error && touched);

  // verifica se o input está sujo
  const isDirty = isInputDirty(input);

  return (
    <TagName
      {...sizes}
      {...rest}
      style={style}
      className={classnames('FormGroup', formGroupClassName, className, {
        'has-error': hasError,
        'no-label': !labelText && !labelRender,
        'is-dirty': isDirty,
        'is-active': active,
        'is-disabled': isDisabled,
        'is-hidden': hidden,
        'is-loading': isLoading,
        'is-private': isPrivate,
      })}
    >
      {maxLength && (
        <FormCount active={active} maxLength={maxLength} input={input} />
      )}
      {labelRender ? (
        labelRender(isRequired)
      ) : (
        <FormLabel htmlFor={input.name} required={isRequired}>
          {labelText}
        </FormLabel>
      )}
      {children}
      {!hideError && <Errors meta={meta} />}
    </TagName>
  );
}

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;

export default FormGroup;
