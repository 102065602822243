//import { convert, flatten } from "react-sortly";
import { CancelToken } from 'axios';
import { createModule, item } from 'lib/reducer-helpers';
// Services
import * as navigationsService from 'services/sites/navigations';

// Actions
const createAction = createModule('menu');

const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const ALTER_ITEM = createAction('ALTER_ITEM');
const REMOVE_ITEM = createAction('REMOVE_ITEM');

const initialState = {
  data: [],
  meta: { isFetching: {} },
};

// Selectors
export const getMenu = (state) => state.site.menu.data;

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        meta: { isFetching: true },
      };
    case RECEIVE:
      return {
        ...state,
        data: action.data,
        meta: { isFetching: false },
      };
    case ALTER_ITEM:
      return {
        ...state,
        data: item.alter(state.data, action.menu),
      };
    case REMOVE_ITEM:
      return {
        ...state,
        data: item.remove(state.data, action.menu),
      };
    default:
      return state;
  }
}

// Action Creators
export const requestMenu = () => ({ type: REQUEST });
export const receiveMenu = (data) => ({ type: RECEIVE, data: data });
export const alterItem = (menu) => ({ type: ALTER_ITEM, menu });
export const removeItem = (menu) => ({ type: REMOVE_ITEM, menu });

export const fetchMenu = (params) => (dispatch) => {
  dispatch(requestMenu());

  return navigationsService
    .getAll({
      ...params,
      sort: '',
    })
    .then((res) => {
      dispatch(receiveMenu(res.data));
      return res;
    });
};

/**
 * Inverte o status de ativo do menu
 * deixa todos os menus que são filhos invisíveis também
 * @param {string} position
 * @param {object} menu
 * @return {function(*): Promise<any>}
 */
export const handleToggleMenu = (position, menu) => async (dispatch) => {
  const statusKey = position === 'header' ? 'status_header' : 'status_footer';

  const toggledMenu = { ...menu, [statusKey]: !menu[statusKey] };

  // Altera o status na store do menu
  dispatch(alterItem(toggledMenu));

  try {
    const response = await navigationsService.update(toggledMenu);
    dispatch(fetchMenu());
    return response;
  } catch (e) {
    // se o request der errado volta para o status anterior
    dispatch(alterItem(menu));
  }
};

/**
 * Remove um item de menu da lista
 * @param menu
 * @return {Function}{Promise}
 */
export const handleRemoveMenu = (menu) => (dispatch) => {
  // Remove o menu da lista
  dispatch(removeItem(menu));

  // Remove um menu em específico
  return navigationsService.remove(menu.id).catch(() => {
    // Caso de algo errado quando deletar o menu
    // Atualiza a lista de menus novamente
    fetchMenu()(dispatch);
  });
};

let cancel;

export const handleSortChange = (navigations) => (dispatch) => {
  if (cancel !== undefined) cancel('REQUEST CANCELADO');

  dispatch(receiveMenu(navigations));

  return navigationsService
    .update(
      { navigations },
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      }
    )
    .then((res) => {
      dispatch(receiveMenu(res.data));
      return res;
    });
};
