import { useState, useCallback, useMemo } from 'react';

export const useSelecteds = () => {
  // Armazena os selecionados em um estado
  const [selecteds, setSelecteds] = useState([]);

  // Retorna o total de selecionados
  const totalSelecteds = useMemo(() => {
    return selecteds?.length;
  }, [selecteds]);

  // Retorna se o valor está selecionado
  const isSelected = useCallback(
    value => {
      return selecteds?.includes(value);
    },
    [selecteds]
  );

  // Seleciona um valor idependente se já está selecionado
  const select = useCallback(
    value => {
      setSelecteds(_selecteds => {
        // Se o valor que está sendo selecionado já existe na lista
        // não precisa adicionar ele novamente
        if (isSelected(value)) return _selecteds;

        // Se não adiciona o valor que está sendo selecionado
        return [..._selecteds, value];
      });
    },
    [isSelected]
  );

  // Seleciona um array de valores
  const selectArray = useCallback(values => {
    values.forEach(value => {
      select(value);
    });
  });

  // Seleciona um valor idependente se já está selecionado
  const unselect = useCallback(value => {
    setSelecteds(_selecteds => {
      return _selecteds.filter(currentValue => currentValue !== value);
    });
  }, []);

  // Desmarca todos os valores
  const unselectAll = useCallback(() => {
    setSelecteds(() => []);
  }, [setSelecteds]);

  // Inverte o status com base se o elemento está ou não na lista
  const toggle = useCallback(
    value => {
      if (isSelected(value)) {
        unselect(value);
      } else {
        select(value);
      }
    },
    [select, unselect, isSelected]
  );

  return {
    selecteds,
    totalSelecteds,
    setSelecteds,
    toggle,
    select,
    selectArray,
    unselect,
    unselectAll
  };
};
