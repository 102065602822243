import React, { useCallback } from 'react';
import { reduxForm, Field } from 'redux-form';
import { InputPhone } from 'components/Form';
import Button from 'components/Button';
import useFormValue from 'hooks/useFormValue';
// import { Container } from './styles';

const Form = ({ handleSubmit, change }) => {
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      return handleSubmit(e);
    },
    [handleSubmit]
  );

  const iso2 = useFormValue('iso2');

  return (
    <form onSubmit={onSubmit}>
      <Field
        defaultCountry={iso2 || 'br'}
        onSelectFlag={(status, countryData) => {
          change(`ddi`, countryData.dialCode);
          change(`iso2`, countryData.iso2);
        }}
        label="Confirme o número do proprietário"
        name="phone"
        component={InputPhone}
      />
      <Button type="submit" block color="success">
        Enviar por WhatsApp
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'FormSendWhatsapp',
  enableReinitialize: true
})(Form);
