import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import GroupControl from 'components/GroupControl';
import GroupItem from 'components/GroupControl/components/GroupItem';
import { Field } from 'redux-form';
import { Input, Textarea } from 'components/Form';
import seo from 'pages/Site/pages/ContentSite/pages/Posts/pages/Store/components/FormPost/seo.png';
import FormBox from 'components/FormBox';
import styled from 'styled-components';
// import { Wrapper } from './styles';

const Number = styled.div`
  display: inline-block;
  color: #fff;
  background-color: #0063c0;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  font-size: 9px;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: -1px;
`;

const baseUrl = `https://seusite.com.br/postagem/`;

function FormSectionSeo({ setPristine }) {
  return (
    <FormBox
      style={{ marginTop: '15px' }}
      title="SEO"
      text="Preencha os campos abaixo para otimizar o SEO da página."
    >
      <Row middle="xs">
        <Col xs={7}>
          <Row>
            <Col xs={12}>
              <GroupControl
                label={
                  <>
                    <Number>1</Number> URL da postagem
                  </>
                }
              >
                <GroupItem label={baseUrl} size="small" />
                <GroupItem>
                  <Field
                    tabIndex={3}
                    name="link"
                    component={Input}
                    placeholder="Digite a url"
                    onChange={() => {
                      setPristine(true);
                    }}
                    parse={(value) => {
                      if (!value) return '';
                      return value
                        .toLowerCase()
                        .replace(/[^-\w\s]/g, '')
                        .replace(/(\s|-)+/g, '-');
                    }}
                  />
                </GroupItem>
              </GroupControl>
            </Col>
            <Field
              xs={12}
              tabIndex={4}
              label={
                <>
                  <Number>2</Number> Título da página para o Google{' '}
                  <a
                    href="https://support.google.com/webmasters/answer/35624?hl=pt-BR"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-link"
                  >
                    (saiba mais)
                  </a>
                </>
              }
              name="meta_title"
              placeholder="Digite o título"
              component={Input}
              maxLength={120}
              onChange={() => {
                setPristine(true);
              }}
            />

            <Field
              tabIndex={5}
              xs={12}
              type="simple"
              label={
                <>
                  <Number>3</Number> Descrição para o Google
                </>
              }
              name="meta_description"
              placeholder="Digite a descrição"
              maxLength={200}
              component={Textarea}
            />
          </Row>
        </Col>
        <Col xs={5}>
          <img
            src={seo}
            alt=""
            style={{ marginLeft: '20px', width: 'calc(100% - 20px)' }}
          />
        </Col>
      </Row>
    </FormBox>
  );
}

export default FormSectionSeo;
