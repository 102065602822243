import { createModule } from 'lib/reducer-helpers';
// Services
import * as popupsService from 'services/sites/popups';

// Actions
const createAction = createModule('popup');

const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');

const initialState = {
  data: {},
  isFetching: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return {
        data: {},
        isFetching: true
      };
    case RECEIVE:
      return {
        data: action.data,
        isFetching: true
      };
    default:
      return state;
  }
}

export const requestPopup = () => ({ type: REQUEST });
export const receivePopup = data => {
  let newData = data;

  if (newData.file_url) {
    newData = {
      ...newData,
      image: { preview: newData.file_url }
    };
  }

  return { type: RECEIVE, data: newData };
};

// Busca as informações relacionadas a popup
export const fetchPopup = params => dispatch => {
  dispatch(requestPopup());

  return popupsService.getOne(params).then(({ data }) => {
    dispatch(receivePopup(data));
    return data;
  });
};

export const handleSubmitPopup = values => dispatch => {
  let newValues = { ...values };

  // Verifica se a url for === tem que remover image
  if (newValues.file_url === newValues.image.preview) {
    delete newValues.image;
  }

  return popupsService.update(newValues).then(({ data }) => {
    dispatch(receivePopup(data));
    return data;
  });
};

export const handleUploadPopup = image => dispatch => {
  dispatch(receivePopup({}));
};
