import React from 'react';
// import { useFacebookIntegration } from 'hooks/useFacebookIntegration';
import { FacebookShareButton } from 'react-share';
import { FaFacebook } from 'react-icons/fa';
import { Item } from 'components/Dropdown';
// import * as facebookService from 'services/settings/facebook';
// import { Wrapper } from './styles';

function Facebook({ url, onClick, propertyId }) {
  // const { data, isFetching } = useFacebookIntegration();
  //
  // const handleClick = async () => {
  //   if (onClick) onClick();
  //   await facebookService.publishProperty(propertyId);
  // };
  //
  // if (isFetching) return null;

  // if (data.publication_permitted) {
  //   return (
  //     <Item onClick={handleClick}>
  //       <button>
  //         <FaFacebook /> Facebook
  //       </button>
  //     </Item>
  //   );
  // }

  return (
    <Item>
      <FacebookShareButton url={url} className="h-flex__cell" onClick={onClick}>
        <FaFacebook /> Facebook
      </FacebookShareButton>
    </Item>
  );
}

export default Facebook;
