import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega as informações de site relacionadas ao rodapé
 * @param params
 * @return {Promise}
 */
export const getOne = params =>
  api.getOne('settings/sites/footers', '', params);

/**
 * Atualiza as informações do site relacionadas ao rodapé
 * @param values
 * @return {Promise}
 */
export const update = values =>
  api.update('settings/sites/footers', values).then(responseMessage('Salvo!'));

/**
 * Retorna os modelos de rodapé
 * @param params
 * @return {Promise}
 */
export const getComponents = params =>
  api.getList('settings/component-examples/footers', params);
