export default values => {
  const errors = {};

  if (!values.image_creci && !values.creci_url)
    errors.image_creci = ['Campo obrigatório'];

  if (!values.image_document && !values.document_url)
    errors.image_document = ['Campo obrigatório'];

  return errors;
};
