import React, { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { Form, reduxForm } from 'redux-form';
import FieldSelectUser from 'containers/FieldSelectUser';
import { MdHelp } from 'react-icons/md';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';
import TextLoading from 'components/TextLoading';
import api from 'services';
import BoxPlan from 'pages/PlanHistory/components/BoxPlan';
// import { Wrapper } from './styles';

const Skeleton = () => (
  <Form>
    <TextLoading width="234px" height="18px" className="h-margin-bottom--5" />
    <TextLoading width="100%" height="35px" className="h-margin-bottom--15" />
  </Form>
);

function AccountableFinanceForm({ submit, initialize, handleSubmit }) {
  const { realEstate, isFetching } = useCurrentRealEstate({
    fetch: true,
  });

  useEffect(() => {
    if (realEstate.financial_user_id) {
      initialize({ financial_user_id: realEstate.financial_user_id });
    }
  }, [realEstate]);

  useEffect(() => {
    if (isFetching === false) {
      Tooltip.rebuild();
    }
  }, [isFetching]);

  if (isFetching) return <Skeleton />;

  return (
    <Form onSubmit={handleSubmit}>
      <BoxPlan title="Detalhes do faturamento">
        <FieldSelectUser
          clearable={false}
          params={{
            filter: {
              realtor: null,
            },
          }}
          label={
            <>
              Pessoa responsável pelo financeiro{' '}
              <MdHelp
                className="h-color--secondary"
                data-tip="Emails e avisos serão encaminhados para esta pessoa."
              />
            </>
          }
          name="financial_user_id"
          onChange={() => {
            setTimeout(() => {
              submit();
            }, 200);
          }}
        />
      </BoxPlan>
    </Form>
  );
}

export default reduxForm({
  form: 'AccountableFinanceForm',
  onSubmit: (values) => {
    return api.patch('sales/user', {
      financial_user_id: values.financial_user_id,
    });
  },
})(AccountableFinanceForm);
