import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Select } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

function Form({ hasPagination, handleSubmit, handleClose }) {
  return (
    <form onSubmit={handleSubmit}>
      {hasPagination && (
        <Field
          name="option"
          options={[
            { name: 'Todas as páginas', value: 'all_pages' },
            { name: 'Página atual', value: 'current_page' }
          ]}
          placeholder=""
          labelKey="name"
          valueKey="value"
          component={Select}
        />
      )}

      <ModalFooter>
        <Button
          type="button"
          color="white"
          colorText="secondary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Selecionar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'ModalPortalProperty',
  initialValues: {
    option: 'all_pages'
  },
  enableReinitialize: true
})(Form);
