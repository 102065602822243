import Alert from 'react-s-alert';
import { openModalProperty } from 'modules/modal';

export const finishResponse = (values, dispatch, props) => (res) => {
  Alert.success('Salvo!');

  if (values.isFinishing) {
    props?.history?.push('/properties/search');

    dispatch(
      openModalProperty({
        property: {
          id: values?.id,
        },
      })
    );
  }

  return res;
};

const PAGES = [
  'Informações',
  'Cômodos',
  'Medidas',
  'Preço',
  'Condomínio',
  'Características do Imóvel',
  'Proximidades',
  'Localização',
  'Descrição',
  'Complementos',
  'Dados privativos',
  'Imagens',
  'Publicação',
];

export const goNextPagePermissions = (currentPage, permissions) => {
  let pages = PAGES;

  if (!permissions.includes('DYNAMIC_DOCUMENTS')) {
    pages = pages.filter((page) => page === 'Anexar Documentos');
  }

  if (!permissions.includes('DYNAMIC_PUBLISH')) {
    pages = pages.filter((page) => page === 'Publicação');
  }

  return pages;
};
