import {
  RadioButton,
  SelectBox,
  SelectMultiple as Select,
} from 'components/Form';
import ListOptions from 'components/ListOptions';
import { parseSelect } from 'lib/formHelpers';
import { connect } from 'react-redux';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import validate from './validate';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { MdInfo } from 'react-icons/md';
import { useEffect } from 'react';
import Tooltip from 'react-tooltip';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const InfoOption3 = () => {
  return (
    <MdInfo
      data-tip={`O último corretor a receber contato será o responsável pelo cliente,
          <br />
          se for usar esta opção, recomendamos liberar todas as permissões
          <br />
          no menu gerenciamento de permissões, nas configurações de usuários`}
    />
  );
};

const ConfigCrmForm = ({
  has_time_max,
  handleSubmit,
  realtors,
  isFetching,
  isFetchingRealtor,
  orulo_fields,
  ...props
}) => {
  const emptySelect = (opcao) => () => {
    props.change(`orulo.${opcao}_realtor_id`, null);
  };

  const toString = (value) => {
    if (typeof value === 'boolean') return value.toString();
    if (!value) return '';
    return value.toString();
  };

  const handleChangeSelectRealtors = (formSection) => () => {
    props.change(
      `${formSection}.client_realtors_id`,
      realtors.map(({ id }) => id)
    );
  };

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="orulo">
        <ListOptions noSpacingTitle title="Imóveis com transação de VENDA">
          <ListOptions.item title="Novos clientes que fizerem contato através do site devem ser encaminhados para:">
            <div className="h-margin-top--10">
              <Field
                label="Para o corretor responsável pelo imóvel contactado"
                name="site_realtor_option"
                value="1"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('site')}
                format={toString}
              />
              <Field
                label="Um corretor específico"
                name="site_realtor_option"
                value="2"
                type="radio"
                component={RadioButton}
                format={toString}
                onChange={emptySelect('site')}
              />
              {orulo_fields?.orulo?.site_realtor_option &&
                orulo_fields?.orulo?.site_realtor_option.toString() === '2' && (
                  <Field
                    multi={false}
                    name="site_realtor_id"
                    component={Select}
                    options={realtors}
                    valueKey="id"
                    labelKey="name"
                    isLoading={isFetchingRealtor}
                    parse={parseSelect('id')}
                    style={{
                      width: '230px',
                      margin: '10px 0',
                    }}
                  />
                )}
              <Field
                label="Executar rodízio entre TODOS os corretores"
                name="site_realtor_option"
                value="3"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('site')}
                format={toString}
              />
              <Field
                label="Executar rodízio entre corretores SELECIONADOS"
                name="site_realtor_option"
                value="4"
                type="radio"
                component={RadioButton}
                onChange={handleChangeSelectRealtors('orulo')}
                format={toString}
              />
              {orulo_fields?.orulo?.site_realtor_option === 4 ||
              orulo_fields?.orulo?.site_realtor_option === '4' ? (
                <Field
                  minOptions={1}
                  name="rotation_select_users_id"
                  component={SelectBox}
                  options={realtors}
                  isLoading={isFetchingRealtor}
                  valueKey="id"
                  labelKey="name"
                  style={{ width: 250 }}
                />
              ) : null}
            </div>
          </ListOptions.item>
          <ListOptions.item title="Contatos recebidos através do site, onde o cliente JÁ POSSUI corretor responsável, devem ser encaminhados para:">
            <div className="h-margin-top--10">
              <Field
                label="Para o corretor responsável pelo cliente"
                name="client_realtor_option"
                value="1"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('client')}
                format={toString}
              />
              <Field
                label={
                  <>
                    Para o corretor responsável pelo imóvel contactado{' '}
                    <InfoOption3 />
                  </>
                }
                name="client_realtor_option"
                value="3"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('rental', 'client')}
                format={toString}
              />
              <Field
                label="Um corretor específico"
                name="client_realtor_option"
                value="2"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('client')}
                format={toString}
              />
              {orulo_fields?.orulo?.client_realtor_option &&
                orulo_fields?.orulo?.client_realtor_option.toString() ===
                  '2' && (
                  <Field
                    multi={false}
                    name="client_realtor_id"
                    component={Select}
                    options={realtors}
                    isLoading={isFetchingRealtor}
                    valueKey="id"
                    labelKey="name"
                    parse={parseSelect('id')}
                    style={{
                      width: '230px',
                      margin: '10px 0',
                    }}
                  />
                )}
            </div>
          </ListOptions.item>
        </ListOptions>
      </FormSection>

      <FixedBar style={{ left: 242 }}>
        <span className="h-flex__cell--grow" />
        <Button disabled={isFetching} type="submit" color="success">
          Salvar
        </Button>
      </FixedBar>
    </form>
  );
};

ConfigCrmForm.defaultProps = defaultProps;
ConfigCrmForm.propTypes = propTypes;

const selector = formValueSelector('ConfigCrmFormOrulo');

const mapStateToProps = (state) => ({
  has_time_max: selector(state, 'has_time_max'),
  orulo_fields: selector(
    state,
    'orulo.site_realtor_option',
    'orulo.client_realtor_option'
  ),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'ConfigCrmFormOrulo',
    enableReinitialize: true,
    initialValues: {
      site_realtor_option: '1',
      site_realtor_id: null,
      client_realtor_option: '1',
      client_realtor_id: null,
      is_changing_realtor: 'false',
      change_realtor_days: null,
      change_realtor_id: null,
    },
    validate,
  })(ConfigCrmForm)
);
