import { MdInfo } from 'react-icons/md';
import { TableData } from 'containers/ModalRoot/ModalPayment/styles';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import { formatter } from 'containers/ModalRoot/ModalPayment/utils/moneyFormatter';
import { Field } from 'redux-form';
import Amount from 'containers/ModalRoot/ModalPayment/components/Amount';
import { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';

const InfoEmail = () => {
  const tip = `Você pode escolher entre duas<br/>
opções para gerenciar suas contas<br/>
de e-mail com seu domínio:<br/>
<ul>
  <li><strong>Caixa Local: </strong>Hospedada pelo<br/> Tecimob, essa opção tem um<br/> custo e oferece até 1GB de armazenamento.</li>
  <li><strong>Caixa Conectada: </strong>Gratuita, esta<br/> opção utiliza os servidores do<br/> Gmail ou do Hotmail para enviar e<br/> receber e-mails com seu domínio.<br/> Se optar por essa modalidade,<br/> você pode deixar a quantidade como zero.</li>
</ul>`;

  return (
    <MdInfo className="h-margin-left--5 h-color--secondary" data-tip={tip} />
  );
};

const Mailboxes = () => {
  const { mailboxes_count } = useCurrentRealEstate();

  const { mailboxesTotalPrice, prices } = useModalPayment();

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  return (
    <tr>
      <td className="item">
        <h3>
          Contas de e-mail <InfoEmail />
        </h3>
        <p>Limitado a 1GB por conta</p>
      </td>
      <td>
        <Field
          min={mailboxes_count}
          name="mailboxes_number"
          component={Amount}
        />
      </td>
      <TableData align="right">
        R$ {formatter.format(prices.mailbox_price)}
      </TableData>
      <TableData align="right">
        R$ {formatter.format(mailboxesTotalPrice)}
      </TableData>
    </tr>
  );
};

export default Mailboxes;
