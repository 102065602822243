import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Pages
import pages from './pages';
import { useRouteMatch } from 'react-router';

const Routes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/show`} component={pages.ListDomains} />
      <Route path={`${path}/pre-register`} component={pages.PreRegister} />
      <Redirect to={`${path}/show`} />
    </Switch>
  );
};

export default Routes;
