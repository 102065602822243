import * as S from './styles';

export function AccountInfo({ account }) {
  if (!account?.id) return null;

  return (
    <S.Wrapper>
      <S.Icon src={account?.icon?.file_url} />
      <S.Content>
        <S.Title>{account?.title}</S.Title>
        <S.Text>
          Fecha dia {account?.closing_day} | Vence dia {account?.due_day}
        </S.Text>
      </S.Content>
    </S.Wrapper>
  );
}
