import React from 'react';
import Modal from 'react-modal';
// Components
import { ModalTemplate } from 'components/Modal';
// Assets
import trophy from 'assets/img/receptions/trophy.svg';

const ModalDealSuccess = ({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  // Data
  user
}) => (
  <Modal
    {...modalConfig}
    isOpen={isOpen}
    contentLabel={modalType}
    onRequestClose={handleClose}
  >
    <ModalTemplate
      title="Parabéns por ter fechado negócio!"
      handleClose={handleClose}
    >
      <div style={{ textAlign: 'center' }}>
        <img
          src={trophy}
          alt="Icone de troféu"
          style={{ margin: '0 auto 15px' }}
        />
        <p className="h-color--primary">
          <strong>{user.name}</strong>, parabéns por ter fechado o negócio!
          <br />
          Esperamos que você feche ainda muitos outros e<br />
          estaremos aqui para te ajudar.
        </p>
      </div>
    </ModalTemplate>
  </Modal>
);

export default ModalDealSuccess;
