import React, { useState, useCallback } from 'react';
import SelectLink from 'components/SelectLink';
import { Pill } from 'components/Form';
import { Field } from 'redux-form';
import useReduxForm from 'hooks/useReduxForm';
import { isTypeGroundSelector } from 'modules/modalPropertySearch';
import { useDispatch, useSelector } from 'react-redux';

const options = [
  { name: 'bedroom', title: 'Dormitórios' },
  { name: 'suite', title: 'Sendo suítes' },
  { name: 'garage', title: 'Garagens' },
];

const LabelRooms = ({ nameField, label, filters, onChange }) => {
  return (
    <div className="h-flex" style={{ width: 200 }}>
      <div className="h-flex__cell--grow">{label}</div>
      <div className="h-flex__cell--shrink" style={{ marginTop: '-2px' }}>
        <SelectLink
          value={filters[nameField]}
          options={[
            { label: 'Maior ou igual', value: 'greater_equals' },
            { label: 'Exatamente', value: 'equals' },
            { label: 'Menor ou igual', value: 'lower_equals' },
          ]}
          onChange={onChange(nameField)}
        />
      </div>
    </div>
  );
};

function Rooms({ onChange, onBlur }) {
  const dispatch = useDispatch();
  const { change } = useReduxForm();
  const isGround = useSelector(isTypeGroundSelector);

  const [filters, setFilters] = useState({
    bedroom: 'greater_equals',
    suite: 'greater_equals',
    garage: 'greater_equals',
  });

  const handleChangeFilter = useCallback(
    (fieldName) =>
      ({ value }, prevValue) => {
        dispatch(change(`by_room.${fieldName}.filter`, value));
        setFilters((prevFilters) => ({
          ...prevFilters,
          [fieldName]: value,
        }));
      },
    []
  );

  const handleChange = (name) => () => {
    if (onChange) onChange(name);
  };

  return options.map(({ name, title }) => {
    return (
      <Field
        disabled={isGround}
        type="square"
        label={
          <LabelRooms
            label={title}
            onChange={handleChangeFilter}
            filters={filters}
            nameField={name}
          />
        }
        name={`by_room.${name}.value`}
        component={Pill}
        onChange={handleChange(name)}
      />
    );
  });
}

export default Rooms;
