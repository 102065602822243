import api from '../index';

// Imóveis que precisam de revisão
export const getPropertiesRevision = (params) => {
  const currentUser = localStorage.getItem('current-user');

  return api.getList('properties/dashboard', {
    sort: '-next_review_at,id',
    filter: {
      by_to_review_in: 10,
      user_id: currentUser?.id,
      status: 1,
      ...params?.filter,
    },
    limit: params?.limit || 3,
    offset: params?.offset || 1,
    add: 'next_review_at',
  });
};

// Contratos de aluguéis vencendo
export const getPropertiesRentExpired = (params) =>
  api.getList('properties/dashboard', {
    sort: 'departure_at',
    filter: {
      by_expiring_rental_contracts: true,
      ...params?.filter,
    },
    limit: 3,
    offset: 1,
    add: 'departure_at,earned_deal_id',
    ...params,
  });

// Imóveis com exclusividade vencendo
export const getPropertiesExclusivityExpired = (params) =>
  api.getList('properties/dashboard', {
    sort: 'exclusive_until',
    filter: {
      by_expiring_exclusivity: true,
      status: 1,
      ...params?.filter,
    },
    limit: 3,
    offset: 1,
    add: 'exclusive_until,earned_deal_id',
    ...params,
  });

// Últimos imóveis atualizados
export const getPropertiesRecent = (params) =>
  api.getList('properties/dashboard', {
    sort: '-interacted_at,id',
    limit: 5,
    offset: 1,
    add: 'rate,interacted_at',
    disable_cache: true,
    filter: {
      status: 1,
    },
    ...params,
  });

// Últimos imóveis negociados
export const getPropertiesDeal = (params) => {
  return api.getList('properties/dashboard', {
    sort: '-earned_at',
    filter: {
      by_earned: true,
    },
    limit: 5,
    offset: 1,
    add: 'earned_at',
    ...params,
  });
};

// Últimos imóveis reservados
export const getPropertiesBookings = (params) => {
  return api.getList('properties/bookings-active', {
    limit: 5,
    offset: 1,
    ...params,
  });
};
