import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import pages from './pages';
import { useId } from 'pages/PropertyStore/hooks/useId';

const Routes = () => {
  let { path } = useRouteMatch();
  let { id } = useId();

  const redirectPath = useMemo(() => {
    return `${path}/informations${id ? `/${id}` : ''}`;
  }, [id]);

  return (
    <Switch>
      <Route
        path={`${path}/informations/:id?`}
        component={pages.Informations}
      />
      <Route
        path={`${path}/characteristics/:id`}
        component={pages.Characteristics}
      />
      <Route
        path={`${path}/characteristics-condo/:id`}
        component={pages.CharacteristicsCondo}
      />
      <Route
        path={`${path}/establishments/:id`}
        component={pages.Establishments}
      />

      <Route path={`${path}/rooms/:id`} component={pages.Rooms} />
      <Route path={`${path}/location/:id`} component={pages.Location} />
      <Route path={`${path}/measures/:id`} component={pages.Measures} />
      <Route path={`${path}/financial/:id`} component={pages.Financial} />
      <Route path={`${path}/description/:id`} component={pages.Description} />
      <Route path={`${path}/complements/:id`} component={pages.Complements} />
      <Route
        path={`${path}/cadastral-situation/:id`}
        component={pages.CadastralSituation}
      />
      <Route path={`${path}/publish/:id`} component={pages.Publish} />
      <Route path={`${path}/private/:id`} component={pages.Private} />
      <Route
        path={[`${path}/imagesArea/:id`, `${path}/images/:id`]}
        component={pages.ImagesArea}
      />

      <Redirect from={`${path}/id/:id`} to={`${path}/informations/:id`} />
      <Redirect to={redirectPath} />
    </Switch>
  );
};

export default Routes;
