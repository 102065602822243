import React, { useMemo } from 'react';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import { useModalUser } from 'containers/ModalRoot/ModalContractRemove/pages/UserAndEmail/contexts/ModalUserContext';
import PlanItem from '../../components/PlanItem';
import { Infos } from '../../components/PlanItem/styles';
import { usePreview2 } from 'containers/ModalRoot/ModalContractRemove/pages/UserAndEmail/hooks/usePreview2';
import Loading from 'components/Loading';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractRemove/contexts/modalContractExtra';
import { ITEM_TYPES } from 'services/financialv2/contracts';

// import { Wrapper } from './styles';

function Details() {
  const { data, isFetching } = usePreview2();
  const { handleClose } = useModalUser();
  const { item, isCancel } = useModalContractExtra();

  const text = useMemo(() => {
    switch (item) {
      case ITEM_TYPES.DWV:
        return 'Escolha essa opção caso for reativar sua integração novamente após desconectar';
      case ITEM_TYPES.ORULO:
        return 'Escolha essa opção caso for reativar sua integração novamente após desconectar';
      case ITEM_TYPES.EMAIL:
        return 'Escolha essa opção caso já tenha em vista o cadastro de um novo email';
      case ITEM_TYPES.USERS:
      default:
        return 'Escolha essa opção caso já tenha em vista o cadastro de um novo corretor/usuário';
    }
  }, [item]);

  return (
    <ModalTemplate
      title="Alteração de contrato"
      text="Escolha entre manter ou alterar seu prazo de crédito"
      handleClose={handleClose}
    >
      {isFetching ? (
        <Loading isVisible isBlock />
      ) : (
        <>
          <p>
            Atualmente seu contrato terminará em <strong>{data?.end_at}</strong>
            , daqui <strong>{data?.end_in}</strong> dias.
          </p>
          <p>
            O recurso excluído, tem um custo de{' '}
            <strong>{data?.monthly_cost}</strong> ao mês.
          </p>
          <p>
            O total deste recurso no seu prazo final, gera um crédito de{' '}
            <strong>{data?.total_cost}</strong>
          </p>

          {!isCancel ? (
            <p className="h-margin-top--15">
              <strong>Qual opção você prefere?</strong>
            </p>
          ) : null}

          <Infos className="h-margin-top--5">
            {isCancel ? (
              <PlanItem
                className="h-margin-top--10"
                label="Usar o crédito para estender a data final do meu contrato"
                text={
                  <>
                    Utilizar seu crédito irá ampliar a data final do seu
                    contrato em
                    <br />
                    Sua nova data de crédito final irá mudar para
                  </>
                }
                value={2}
                price={
                  <>
                    +{data?.change_days} dias
                    <br /> {data?.new_end_at}
                  </>
                }
              />
            ) : (
              <>
                <PlanItem
                  label="1 - Não alterar meu contrato e manter minha data final como esta"
                  text={text}
                  value={1}
                />
                <PlanItem
                  label="2 - Usar o crédito para estender a data final do meu contrato"
                  text={
                    <>
                      Utilizar seu crédito irá ampliar a data final do seu
                      contrato em
                      <br />
                      Sua nova data de crédito final irá mudar para
                    </>
                  }
                  value={2}
                  price={
                    <>
                      +{data?.change_days} dias
                      <br /> {data?.new_end_at}
                    </>
                  }
                />
              </>
            )}
          </Infos>

          <ModalFooter>
            <Button type="button" color="white" onClick={handleClose}>
              Voltar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              Prosseguir
            </Button>
          </ModalFooter>
        </>
      )}
    </ModalTemplate>
  );
}

export default Details;
