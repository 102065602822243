import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Common Components
import { ModalTemplate } from '../../../components/Modal';
import FormIssue from './components/FormIssue';
// Modules
import { Actions } from 'modules/fileUpload';
// Service
import * as helpDeskService from 'services/helpDesk';
// Constants
import { TYPES } from 'pages/Help/constants';

class ModalIssue extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    type: PropTypes.oneOf([TYPES.PROBLEM, TYPES.SUGESTION])
  };

  static defaultProps = {
    type: TYPES.PROBLEM
  };

  get title() {
    const { type } = this.props;

    if (type === TYPES.PROBLEM) {
      return 'Relatar Problema';
    }

    return 'Enviar Sugestão';
  }

  onSubmit = async ({ documents, ...values }) => {
    const { addFile, uploadSuccess } = this.props;

    // Cria o ticket para pegar a resposta
    // e poder iniciar o upload dos arquivos
    const { data } = await helpDeskService.createTicket(values);

    if (documents) {
      documents.forEach(document => {
        helpDeskService
          .uploadAttachment(data.interactions[0].id, document)
          .then(() => {
            uploadSuccess(document);
          });
        addFile(document);
      });
    }

    return data;
  };

  onSubmitSuccess = res => {
    const { onSubmitSuccess } = this.props;
    if (onSubmitSuccess) onSubmitSuccess(res);
    this.props.handleClose();
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType, type } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate title={this.title} handleClose={handleClose}>
          <FormIssue
            initialValues={{
              type
            }}
            type={type}
            handleClose={handleClose}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  ...Actions
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalIssue);
