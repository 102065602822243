import { useEffect, useState } from 'react';
import { getWizard } from 'services/user';
import { handleAddWizard } from 'modules/login';
import { useDispatch } from 'react-redux';

export const useWizard = ({ key }) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  const KEY = `@tecimob/${key}`;

  const getVisibility = () => {
    return getWizard(KEY) || sessionStorage.getItem(KEY);
  };

  const close = async ({ not_show_again = false }) => {
    setIsVisible(false);

    if (not_show_again) {
      return dispatch(handleAddWizard(KEY));
    }

    sessionStorage.setItem(KEY, true);
  };

  useEffect(() => {
    setIsVisible(!getVisibility());
  }, []);

  return { isVisible, close };
};
