import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Link)`
  padding: 20px;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  span {
    vertical-align: middle;
    margin-top: -5px;
  }
`;

export const Number = styled.strong`
  font-size: 35px;
  font-weight: 600;
  color: ${p => p.theme.colors.success};
`;

export const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${p => p.theme.colors.primary};
`;
