import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
import Table from './components/Table';
import { useDispatch } from 'react-redux';
import { openModalCrmRedirectContact } from 'modules/modal';
// import { Wrapper } from './styles';

function ContactsSection({ fetchData, onSaveForm }) {
  const dispatch = useDispatch();

   function handleOpenModalRedirectContact() {
    dispatch(openModalCrmRedirectContact({
      onSaveForm
    }));
   }

  return (
    <>
      <MainTitle
        title="Redirecionamento de contatos"
        text="Todos os contatos da origem serão encaminhados ao destinatário selecionado"
      >
        <Button onClick={handleOpenModalRedirectContact} color="success">Adicionar</Button>
      </MainTitle>
      <Table data={fetchData} onSaveForm={onSaveForm} />
    </>
  );
}

export default ContactsSection;
