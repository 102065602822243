// Widgets.js
import Alert from 'react-s-alert';
import { closeModal } from './modal';
import * as characteristics from '../services/characteristics';
import { getCharacteristics } from './characteristics';

// Initial State
const initialState = {};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // do reducer stuff
    default:
      return state;
  }
}

// side effects, only as applicable
// e.g. thunks, epics, etc
// TODO remover daqui não faz sentido essa funcao aqui
export function handleFormSubmit(values, dispatch) {
  return characteristics
    .create(values)
    .then(() => {
      getCharacteristics()(dispatch);
      dispatch(closeModal());
    })
    .catch(() => {
      Alert.success('Não foi possivel adicionar a característica');
    });
}
