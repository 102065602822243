import styled from 'styled-components';

export const Wrapper = styled.div`
  width: calc(100% + 39px);
  margin-left: -19px;
  padding: 15px 20px;

  & + & {
    border-top: 1px solid ${(p) => p.theme.borderColor};
  }
`;
