import React from 'react';
import Box from 'containers/TableLastLeadsReceived/components/Box';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Ball, CTooltip, Right, Table } from './styles';
import { Col, Row } from 'react-flexbox-grid';

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#8884d8',
  '#FFC658',
  '#82ca9d',
  '#FF6B6B',
  '#D9BF77',
  '#FF5D5D',
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <CTooltip>
        <p>
          {payload[0].payload.name}: {payload[0].payload.count}
        </p>
      </CTooltip>
    );
  }

  return null;
};

function Graph({ receptionSource }) {
  return (
    <Box title="Origens por canal">
      <Row>
        <Col xs={6}>
          {receptionSource?.length > 0 && (
            <ResponsiveContainer height={250}>
              <PieChart margin={{ left: 25, right: 25 }}>
                <Pie
                  data={receptionSource}
                  fill="#8884d8"
                  dataKey="percent"
                  nameKey={'type'}
                >
                  {receptionSource.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          )}
        </Col>
        <Right xs={6}>
          <Table>
            {receptionSource?.map((entry, index) => (
              <tr>
                <td align={'center'} width={30}>
                  <Ball color={COLORS[index % COLORS.length]} />
                </td>
                <td>{entry.name}</td>
                <td width={70} align="left">
                  {entry.count}
                </td>
              </tr>
            ))}
          </Table>
        </Right>
      </Row>
    </Box>
  );
}

export default Graph;
