import React from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
  className: 'Dropdown__list',
  children: ''
};

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const List = ({ children, className, ...rest }) => (
  <ul className={className} {...rest}>
    {children}
  </ul>
);

List.defaultProps = defaultProps;
List.propTypes = propTypes;

export default List;
