import { createValidation } from 'lib/validate-helpers';

export default values => {
  const errors = {};

  const validations = createValidation(values, errors);

  validations.email('email');
  validations.required('name');
  validations.required('doc');
  validations.required('phone');
  validations.required('cep');
  validations.required('uf');
  validations.required('city');
  validations.required('neighborhood');
  validations.required('address');
  validations.required('number');

  return errors;
};
