import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 40px;
  padding: 40px 0 40px 40px;
  justify-content: space-between;

  p {
    line-height: 1.4em;
  }
`;

export const Col = styled.div`
  min-width: 0;
`;

export const ColLeft = styled(Col)`
  max-width: 50%;
  width: ${(p) => p.size || '50%'};
  flex-shrink: 0;
`;

export const ColRight = styled(Col)`
  width: 50%;
  text-align: center;
  flex-grow: 1;

  img {
    margin: 0 auto;
  }
`;
