import Modal from 'react-modal';
import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import logo from '../../assets/logo.png';
import styled from 'styled-components';
import Button from 'components/Button';

export const Wrapper = styled(Row)`
  p {
    margin-bottom: 15px;
  }

  ul {
    margin-bottom: 15px;
    list-style-position: inside;
    margin-left: 5px;
  }
`;

function TermsOfUse({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  onAcceptTerms,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
      style={{
        content: {
          maxWidth: 862,
        },
      }}
    >
      <ModalTemplate
        title="Integração com Pleno Imob"
        handleClose={handleClose}
        style={{
          width: '862px',
        }}
      >
        <Wrapper>
          <Col xs={3}>
            <img src={logo} alt="Logo pleno imob" />
          </Col>
          <Col xs={9}>
            <p>
              Pleno Imob fornece recursos administrativos para imobiliárias e
              corretores de imóveis. Saiba mais visitando{' '}
              <a
                href="https://www.plenoimob.com.br"
                target="_blankPleno-IMOB2 1.png"
                className="h-link"
              >
                https://www.plenoimob.com.br
              </a>
            </p>
            <p>
              <strong>Integrações ativas</strong>
            </p>
            <ul>
              <li>Agendamento de visitas;</li>
              <li>Controle de locação;</li>
            </ul>
            <p>
              <strong>Funcionamento</strong>
            </p>
            <p>
              De posse com uma conta com a Pleno Imob, clique em conectar e
              informe seu domínio Pleno Imob para fazermos a ativação da
              integração. Após ativado a integração, será adicionado novos
              botões no detalhamento dos imóveis disponíveis para ”Locação”, que
              ao serem clicados, levarão o visitante do seu site para uma página
              externa, pertencente ao Pleno Imob para poderem proceder com a
              ação selecionada.
            </p>
            <p>
              <strong>Legal</strong>
            </p>
            <p>
              Pleno Imob é uma empresa externa, independente, sem ligação
              jurídica com Tecimob. Não fazemos recomendação nem prestamos
              suporte sobre o produto.
            </p>
          </Col>
        </Wrapper>
        <ModalFooter>
          <Button type="button" onClick={handleClose} color="white">
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button color="success" onClick={onAcceptTerms}>
            Li e estou de acordo, avançar para integração
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default TermsOfUse;
