import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

const defaultProps = {
  width: '60px',
  height: '18px'
};

const TextLoading = ({ width, height, className }) => {
  return (
    <span
      className={classnames('TextLoading', className)}
      style={{
        width,
        height
      }}
    />
  );
};

TextLoading.propTypes = propTypes;
TextLoading.defaultProps = defaultProps;

export default TextLoading;
