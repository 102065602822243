import React from 'react';
// Containers
import FieldSelectUser from 'containers/FieldSelectUser';
// Constants
import { USER_PERMISSIONS } from 'constants/rules';
// Hooks
import { useCan } from 'hooks/useCan';

export default function Realtor() {
  const canView = useCan({
    run: USER_PERMISSIONS.VIEW_OTHER_DEAL
  });

  if (!canView) return null;

  return <FieldSelectUser label="Corretor" name="by_user_id" />;
}
