// import styles from './styles'

import { useIndexRecurrencyTypes } from '../../hooks/useRecurrencyTypes';
import React from 'react';
import { SelectMultiple } from 'components/Form';
import { Field } from 'redux-form';
import { parseSelect } from 'lib/formHelpers';

function FieldRecurrencyType({
  name = 'recurrency_type_id',
  options,
  excludeId,
  ...props
}) {
  const { recurrencyTypes } = useIndexRecurrencyTypes();

  return (
    <Field
      multi={false}
      name={name}
      component={SelectMultiple}
      options={recurrencyTypes}
      labelKey="title"
      valueKey="id"
      parse={parseSelect('id')}
      {...props}
    />
  );
}

export { FieldRecurrencyType };
