import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import {
  CarouselSelect,
  ColorPicker,
  Input,
  Select,
  Textarea,
} from 'components/Form';
import Container from 'pages/Site/components/ContainerSite';
import ListOptions from 'components/ListOptions';
import FieldLanguage from '../FieldLanguage';
import HeaderImageField from '../HeaderImageField';
// Constants
import { BOOL, TYPE_SHOW_EMAIL } from 'constants/options';
import { DEVICES } from 'containers/UploadLogo';
import UploadButton from 'components/UploadButton';
import { MdInfo } from 'react-icons/md';
import useFormValue from 'hooks/useFormValue';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FieldBool = (props) => (
  <Field
    buttonTemplate
    canRemoveSelf={false}
    component={Select}
    options={BOOL}
    style={{ width: '120px' }}
    {...props}
  />
);

const FieldBoolEmail = (props) => (
  <Field
    {...props}
    canRemoveSelf={false}
    buttonTemplate
    component={Select}
    options={TYPE_SHOW_EMAIL}
    style={{ width: '120px' }}
  />
);

const FormHeader = ({
  device,
  headers,
  images,
  header_type,

  handleSubmit,
  change,
  handleRemoveImage,
  header_is_title_shown,
  header_is_video_shown,
  header_is_video_mobile,
  is_translate_shown,
  submitting,
  pristine,
  ...props
}) => {
  const [isShowPhone, setIsShowPhone] = useState(true);
  const [isShowEmail, setIsShowEmail] = useState(true);
  const [isHeaderImageShown, setIsHeaderImageShow] = useState(true);

  const isEmail = useFormValue('header_is_email_shown');
  const isPhones = useFormValue('header_is_phones_shown');
  const isShowImage = useFormValue('header_image_shown');

  useEffect(() => {
    setIsShowEmail(isEmail);
    setIsShowPhone(isPhones);
    setIsHeaderImageShow(isShowImage);
  }, [isEmail, isPhones, isShowImage]);

  return (
    <form onSubmit={handleSubmit}>
      {headers && (
        <Field
          name="header_type"
          component={CarouselSelect}
          options={headers}
          className="h-margin-bottom--15"
          value="test"
        />
      )}
      <Container>
        <ListOptions>
          <ListOptions.item
            title={
              <>
                Redes Sociais{' '}
                <Link
                  className="h-color--secondary"
                  to="/site/config/realestate#redes-sociais"
                >
                  (Gerenciar)
                </Link>
              </>
            }
            text="Mostrar as redes sociais no topo do site?"
            renderOptions={() => <FieldBool name="header_is_social_shown" />}
          />
          <ListOptions.item
            title="Multi Linguagem"
            text="Disponibilizar seu site traduzido em Inglês e Espanhol?"
            renderOptions={() => (
              <>
                {is_translate_shown && <FieldLanguage />}
                <FieldBool name="is_translate_shown" />
              </>
            )}
          />
          <ListOptions.item
            title="Frase do topo"
            text="Edite a frase que ficará por cima da imagem."
            renderOptions={() => (
              <>
                {header_is_title_shown && (
                  <>
                    <span style={{ alignSelf: 'center', marginRight: 5 }}>
                      Tamanho da Fonte
                    </span>
                    <div style={{ width: '60px', marginRight: 15 }}>
                      <Field
                        type="number"
                        name="header_title_size"
                        placeholder=" "
                        component={Input}
                        normalize={(value) => {
                          if (!value) return '';
                          if (value > 2000) {
                            return '120';
                          }
                          return value;
                        }}
                      />
                    </div>
                    <Field
                      width={140}
                      name="header_title_color"
                      component={ColorPicker}
                      colors={[
                        { name: '30303E', hex: '#30303E' },
                        { name: 'FFFFFF', hex: '#FFFFFF' },
                        { name: '000000', hex: '#000000' },
                        { name: '6E829D', hex: '#6E829D' },
                      ]}
                    />
                  </>
                )}
                <FieldBool
                  name="header_is_title_shown"
                  onChange={() => {
                    change('header_title', null);
                  }}
                />
              </>
            )}
          >
            {header_is_title_shown && (
              <div className="h-margin-top--15">
                <Field type="simple" name="header_title" component={Textarea} />
              </div>
            )}
          </ListOptions.item>

          <ListOptions.item
            title="Fundo do topo"
            text="Adicionar fundo no topo?"
            renderOptions={() => (
              <Field
                name="header_background_color"
                canClear
                component={ColorPicker}
                placeholder="Automático"
              />
            )}
          />

          {/* <ListOptions.item
            title="Dados de contato"
            text="Mostrar seus dados de contato?"
            renderOptions={() => <FieldBool name="header_is_contacts_shown" />}
          /> */}
          <ListOptions.item
            title="Opacidade na imagem do topo"
            text="Adicionar opacidade na imagem do topo?"
            renderOptions={() => <FieldBool name="header_has_opacity" />}
          />
          <ListOptions.item
            title="Mostrar telefones?"
            renderOptions={() => <FieldBool name="header_is_phones_shown" />}
          />
          {isShowPhone && (
            <ListOptions.item
              title="Agrupar telefones?"
              renderOptions={() => (
                <FieldBool name="header_is_contacts_grouped" />
              )}
            />
          )}
          <ListOptions.item
            title="Mostrar e-mail?"
            renderOptions={() => <FieldBool name="header_is_email_shown" />}
          />
          {isShowEmail && (
            <ListOptions.item
              title={
                <>
                  Deixar e-mail visível ou protegido?
                  <span
                    data-html
                    className="h-color--secondary h-margin-left--5"
                    data-tip="Ao optar por proteger seu endereço de e-mail, em vez do seu e-mail ser diretamente visível,
                   um link será exibido com o texto 'Ver e-mail'. Ao clicar nesse link, o endereço de e-mail será revelado.
                   Essa abordagem ajuda a evitar que robôs que rastreiam a internet coletem seu e-mail e o adicionem a listas de spam"
                  >
                    <MdInfo />
                  </span>
                </>
              }
              renderOptions={() => (
                <FieldBoolEmail name="header_is_email_protected" />
              )}
            />
          )}
          {device === DEVICES.DESKTOP && (
            <ListOptions.item
              title="Vídeo do topo"
              text="Defina o vídeo que ficará no fundo do topo do seu site."
              renderOptions={() => <FieldBool name="header_is_video_shown" />}
            >
              {header_is_video_shown && (
                <Field
                  className="h-margin-top--15"
                  name="header_video_url"
                  component={Input}
                  placeholder="Digite a url do vídeo"
                />
              )}
            </ListOptions.item>
          )}
          <ListOptions.item
            title="Mostrar imagem do topo?"
            renderOptions={() => <FieldBool name="header_image_shown" />}
          />
          {isHeaderImageShown && (
            <ListOptions.item
              title={
                device === DEVICES.DESKTOP
                  ? 'Imagem do topo (Recomendado: 1920x450px)'
                  : 'Imagem do topo (Recomendado 600x300px com conteúdo centralizado em 360px)'
              }
              text={
                header_is_video_mobile
                  ? 'Escolha a imagem que irá ficar quando estiver sendo carregado o vídeo'
                  : 'Escolha a imagem que será usada no topo, ou envie a sua própria'
              }
              renderOptions={() => {
                return (
                  <UploadButton
                    onDropAccepted={(files) => {
                      change('image', files[0]);

                      setTimeout(async () => {
                        await props.submit();
                        change('header_file_url', null);
                        change('image', null);
                      }, 100);
                    }}
                  >
                    <Button color="secondary">Enviar Imagem</Button>
                  </UploadButton>
                );
              }}
            />
          )}
          {isHeaderImageShown && (
            <ListOptions.item>
              <Field
                device={device}
                name="header_image_id"
                options={images}
                component={HeaderImageField}
                handleRemoveImage={handleRemoveImage}
                onChange={() => {
                  change('header_is_video_shown', false);
                  change('header_video_url', '');
                }}
              />
            </ListOptions.item>
          )}
        </ListOptions>

        <FixedBar style={{ left: 240 }}>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar
          </Button>
        </FixedBar>
      </Container>
    </form>
  );
};

FormHeader.defaultProps = defaultProps;
FormHeader.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.form);

  return {
    device: selector(state, 'device'),
    header_type: selector(state, `header_type`),
    is_translate_shown: selector(state, `is_translate_shown`),
    header_is_title_shown: selector(state, `header_is_title_shown`),
    header_is_video_mobile: selector(state, `header_is_video_mobile`),
    header_is_video_shown: selector(state, `header_is_video_shown`),
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })
)(FormHeader);
