// Services
import * as scriptsService from 'services/scripts';
// Modules
import dataReducer from 'modules/data';

export const {
  actionCreators: scriptsActions,
  reducer: scriptsReducer,
  selectors: scriptsSelector
} = dataReducer('site/scripts');

/**
 * Busca todos os scripts
 * @return {Promise} - retorna a resposta contendo todos os scripts
 */
export const fetchData = () => async dispatch => {
  // Inicia o request adicionado loading na página
  dispatch(scriptsActions.request());

  const { data } = await scriptsService.getList();

  // Quando finaliza o request idependente se funcionou ou não remove o efeito de loading
  dispatch(scriptsActions.receive(data));
};

/**
 * Remove um script
 * @param script
 * @return {Function}
 */
export const removeScript = script => async dispatch => {
  dispatch(scriptsActions.removing(script.id));
  await scriptsService.remove(script.id);
  dispatch(scriptsActions.remove(script.id));
};

/**
 * Submit
 */
export const handleSubmit = values => async dispatch => {};

export default scriptsReducer;
