import React from 'react';
import { Prompt } from 'react-router-dom';

export const withFormConfirmation =
  (msg = 'As alterações não foram salvas, sair mesmo assim?') =>
  (Component) =>
  ({ submitting, pristine, ...props }) => {
    const isBlocking = submitting || pristine;

    return (
      <>
        <Prompt when={!isBlocking} message={() => msg} />
        <Component submitting={submitting} pristine={pristine} {...props} />
      </>
    );
  };
