import React, { useCallback } from 'react';
import { Input } from 'components/Form';
import { Field } from 'redux-form';
import { normalizeCep } from 'lib/formHelpers';
import { getLocation } from 'services/ceps';
import Button from 'components/Button';

import { Wrapper } from './styles';
import useFormValue from 'hooks/useFormValue';

function FieldCep({
  label = 'CEP',
  name = 'cep',
  onChange,
  onClickSearch,
  onChangeLocation,
  ...props
}) {
  const cep = useFormValue(name);

  const handleChange = useCallback(
    async (e, value) => {
      if (!!onChangeLocation) {
        if (value.length === 9) {
          const { data } = await getLocation({ cep: value });

          onChangeLocation(data);
        }
      }
      if (onChange) onChange(e, value);
    },
    [onChange]
  );

  const handleClickSearch = useCallback(async () => {
    const { data } = await getLocation({ cep });
    if (cep.length === 9) {
      onClickSearch(data);
    } else {
      onClickSearch({});
    }
  }, [cep, onClickSearch]);

  if (!onClickSearch) {
    return (
      <Field
        {...props}
        label="CEP"
        name={name}
        onChange={handleChange}
        component={Input}
        parse={normalizeCep}
      />
    );
  }

  return (
    <Wrapper>
      <Field
        {...props}
        label={label}
        name={name}
        onChange={handleChange}
        component={Input}
        parse={normalizeCep}
      />
      {onClickSearch && <Button onClick={handleClickSearch}>Buscar</Button>}
    </Wrapper>
  );
}

FieldCep.propTypes = {};
FieldCep.defaultProps = {};

export default FieldCep;
