import { API_PDF } from 'constants/config';
import qs from 'qs';
import api from 'services';
import * as propertiesService from 'services/properties';
import * as messagesService from 'services/settings/messages';
import { address_complete } from 'containers/ModalRoot/ModalProperty/Block';
import { imageChangeSize } from 'lib/image-helpers';

export const fichaDeVisita = async ({
  person_id,
  property,
  properties,
  cellphone_number,
  cpf_cnpj,
  user_id,
}) => {
  const currentUser = localStorage.getItem('current-user');

  const { data: messages } = await messagesService.getOne();

  const {
    data: { logo_url, person, user },
  } = await api.getOne('properties/visit-forms', '', {
    person_id,
    user_id,
  });

  let objParams = {
    logo_url: imageChangeSize(logo_url || currentUser?.real_estate?.file_url),
    person: {
      name: person?.name,
      cpf_cnpj: cpf_cnpj || person?.cpf_cnpj,
      cellphone_number: cellphone_number || person?.cellphone_number,
    },
    user: {
      name: user?.name,
      creci: user?.creci,
    },
    property: {},
    visit_form: messages?.visit_form,
  };

  /**
   * Verifica se está enviando um imóvel só ou vários
   */
  if (property) {
    objParams.property = {
      reference: property?.reference,
      title: property?.title_formatted,
      price: property?.calculated_price,
      condominium_title: property?.condominium?.title,
      apartment_number: property?.informations?.apartment_number?.value,
      address: address_complete({
        complement_address: property?.complement_address,
        zip_code: property?.zip_code,
        address_formatted: property?.address_formatted,
        street_address: property?.street_address,
        street_number: property?.street_number,
      }),
    };
  }

  if (properties) {
    const { data } = await propertiesService.getList(
      {
        with_all_data: true,
        filter: { by_id: properties },
        include: 'neighborhood,condominium',
      },
      true
    );

    if (data) {
      objParams.properties = data.map((p) => ({
        reference: p?.reference,
        title: p?.title_formatted,
        price: p?.calculated_price,
        condominium_title: p?.condominium?.title,
        apartment_number: p?.informations?.apartment_number?.value,
        address: address_complete({
          complement_address: p?.complement_address,
          zip_code: p?.zip_code,
          address_formatted: p?.address_formatted,
          street_address: p?.street_address,
          street_number: p?.street_number,
        }),
      }));
    }
  }

  const params = qs.stringify(objParams);

  window.open(`${API_PDF}/visita?${params}`);
};
