import _ from 'lodash';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import { createSelector } from 'reselect';
// Helpers
import { createModule } from 'lib/reducer-helpers';
import setAuthorizationToken, {
  setOriginalToken,
} from 'lib/setAuthorizationToken';
// Modules
import { hideLoading, showLoading } from 'modules/loading';
import { setAuth, TYPES_AUTH } from './authentications';
import { Actions as GoogleActions } from './google';
import { closeModal, openModalPaymentSuccess } from './modal';
// Services
import { setAnalyticsAuthToken } from 'lib/analytics';
import Events from 'lib/Socket/Events';
import api from 'services';
import * as auth from 'services/auth';
import * as calendars from 'services/calendars';
import { updateRealEstateLocation } from 'services/realEstate';
import * as user from 'services/user';
import Store, { TypesInstance } from '../lib/Store';
// Image

const createAction = createModule('login');

export const currentUserSelector = (state) => state.login.currentUser;
export const realEstateSelector = (state) =>
  state?.login?.currentUser?.real_estate;
export const resellerSelector = (state) => state.login.reseller;
export const permissionsSelector = (state) =>
  state.login.currentUser.permissions;
export const funnelIdSelector = createSelector(
  currentUserSelector,
  (currentUser) => {
    return _.get(currentUser, 'default_crm_stage.funnel_id');
  }
);

/**
 * Retorna se a imobiliária está bloqueada s/n
 * @param state
 * @return {boolean|*}
 */
export const getIsBlocked = (state) => {
  try {
    return state.login.currentUser.real_estate.blocked;
  } catch {
    return false;
  }
};

export const googleAnalyticsSelector = (state) => {
  try {
    return state.login.currentUser.real_estate.google_analytics;
  } catch {
    return {};
  }
};

export const currentUserIsFetchingSelector = (state) => state.login.isFetching;

export const getHeaderBackground = createSelector(
  currentUserSelector,
  (currentUser) =>
    currentUser.real_estate
      ? currentUser.real_estate.header_background
      : '#0063c0'
);

export const getHeaderLogoImage = createSelector(
  currentUserSelector,
  (currentUser) =>
    (currentUser.real_estate && currentUser.real_estate.file_url) || null
);

export const getHeaderType = createSelector(
  currentUserSelector,
  (currentUser) =>
    (currentUser.real_estate && currentUser.real_estate.header_type) || null
);

export const userAuthSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.auths
);

// Actions
const LOGIN_REQUEST = createAction('LOGIN_REQUEST');
const LOGIN_SUCCESS = createAction('LOGIN_SUCCESS');
const LOGIN_FAILURE = createAction('LOGIN_FAILURE');

const HAS_PERMISSIONS = createAction('HAS_PERMISSIONS');
const NO_PERMISSIONS = createAction('NO_PERMISSIONS');

const SIGNUP_REQUEST = createAction('SIGNUP_REQUEST');
const SIGNUP_SUCCESS = createAction('SIGNUP_SUCCESS');
const SIGNUP_FAILURE = createAction('SIGNUP_FAILURE');

const REQUEST_USER = createAction('REQUEST_USER');
const RECEIVE_USER = createAction('RECEIVE_USER');

const LOGOUT_REQUEST = createAction('LOGOUT_REQUEST');
export const LOGOUT_SUCCESS = createAction('LOGOUT_SUCCESS');

const RECOVERY_REQUEST = createAction('RECOVERY_REQUEST');
const RECOVERY_MSG_SHOW = createAction('RECOVERY_MSG_VISIBLE');
const RECOVERY_MSG_HIDE = createAction('RECOVERY_MSG_HIDDEN');
const RECOVERY_SEND_EMAIL_SUCCESS = createAction('RECOVERY_SEND_EMAIL_SUCCESS');

const RESET_PASSWORD_REQUEST = createAction('RESET_PASSWORD_REQUEST');
const PASSWORD_RESET_SUCCESS = createAction('PASSWORD_RESET_SUCCESS');
const PASSWORD_RESET_FAILURE = createAction('PASSWORD_RESET_FAILURE');

const SET_GOOGLE_OAUTH = createAction('SET_GOOGLE_OAUTH');

const SET_COLOR_HEADER = createAction('SET_COLOR_HEADER');
const SET_HEADER_TYPE = createAction('SET_HEADER_TYPE');
const SET_LOGO_HEADER = createAction('SET_LOGO_HEADER');
const SET_REAL_ESTATE = createAction('SET_REAL_ESTATE');

export const RESET_GOOGLE_ANALYTICS = createAction('RESET_GOOGLE_ANALYTICS');
export const RECEIVE_GOOGLE_ANALYTICS = createAction(
  'RECEIVE_GOOGLE_ANALYTICS'
);

export const REQUEST_RESELLER = createAction('REQUEST_RESELLER');
export const RECEIVE_RESELLER = createAction('RECEIVE_RESELLER');
export const REQUEST_RESELLER_FAIL = createAction('REQUEST_RESELLER_FAIL');

// Bloqueia a imobiliaria
export const BLOCK_REAL_ESTATE = createAction('BLOCK_REAL_ESTATE');
export const UNBLOCK_REAL_ESTATE = createAction('UNBLOCK_REAL_ESTATE');

// Wizard
export const ADD_WIZARD = createAction('ADD_WIZARD');

// Initial State
const initialState = {
  currentUser: localStorage.getItem('current-user')
    ? JSON.parse(localStorage.getItem('current-user'))
    : {
        header_color: '#0063c0',
        id: '',
        name: '',
        email: '',
        creci: '',
        realtor: false,
        is_admin: false,
        has_person_privacy: false,
        real_estate: {
          blocked: false,
          header_background: null,
          google_analytics: null,
          header_type: 'Header2',
        },
        default_crm_stage: {
          id: null,
          funnel: {
            id: null,
            name: null,
          },
        },
        auths: {
          calendar: false,
        },
      },
  recovery: {
    email: '',
    isVisible: false,
  },
  isAuthenticated: false,
  isFetching: false,
  isFetchingUser: false,
  isFetchingSignUp: false,
  isFetchingRecovery: false,
  isFetchingReseller: true,
  hasPermissions: false,
  primary_domain_url: null,
  reseller: {
    id: '',
    header_logo_url: '',
    service_terms_link: '',
  },
  resellerError: false,
};

// Reducer
export default function reducer(
  state = {
    ...initialState,
    isAuthenticated: !!localStorage.getItem('access_token'),
  },
  action
) {
  switch (action.type) {
    case ADD_WIZARD: {
      // Current user
      const currentUser = {
        ...state?.currentUser,
        wizards: {
          ...state?.currentUser?.wizards,
          [action.wizardName]: true,
        },
      };

      localStorage.setItem('current-user', currentUser);

      return {
        ...state,
        currentUser,
      };
    }
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        hasPermissions: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        hasPermissions: true,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: true,
        hasPermissions: false,
      };
    case REQUEST_USER:
      return {
        ...state,
        isFetchingUser: true,
      };
    case RECEIVE_USER:
      return {
        ...state,
        currentUser: action.user,
        isFetchingUser: false,
      };
    case SIGNUP_REQUEST:
      return {
        ...state,
        isFetchingSignUp: true,
      };
    case RECOVERY_REQUEST:
      return {
        ...state,
        isFetchingRecovery: true,
      };
    case RECOVERY_MSG_SHOW:
      return {
        ...state,
        recovery: {
          email: action.email,
          isVisible: true,
        },
      };
    case RECOVERY_MSG_HIDE:
      return {
        ...state,
        recovery: {
          email: '',
          isVisible: false,
        },
      };
    case SET_COLOR_HEADER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          real_estate: {
            ...state.currentUser.real_estate,
            header_background: action.color,
          },
        },
      };
    case SET_LOGO_HEADER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          real_estate: {
            ...state.currentUser.real_estate,
            file_url: action.fileUrl,
          },
        },
      };
    case SET_HEADER_TYPE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          real_estate: {
            ...state.currentUser.real_estate,
            header_type: action.headerType,
          },
        },
      };
    case SET_REAL_ESTATE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          header_color: action.realEstate.header_color,
          real_estate: {
            ...state?.currentUser?.real_estate,
            ...action?.realEstate,
          },
        },
      };
    case RECOVERY_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isFetchingRecovery: false,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetchingRecovery: true,
      };
    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_RESET_FAILURE:
      return {
        ...state,
        isFetchingRecovery: false,
      };
    case HAS_PERMISSIONS:
      return {
        ...state,
        hasPermissions: true,
      };
    case NO_PERMISSIONS:
      return {
        ...state,
        hasPermissions: false,
      };
    case SET_GOOGLE_OAUTH:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          auths: {
            ...state.currentUser.auths,
            calendar: true,
          },
        },
      };
    case RESET_GOOGLE_ANALYTICS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          real_estate: {
            ...state.currentUser.real_estate,
            google_analytics: null,
          },
        },
      };
    case RECEIVE_GOOGLE_ANALYTICS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          real_estate: {
            ...state.currentUser.real_estate,
            google_analytics: {
              ...state.currentUser.real_estate.google_analytics,
              ...action.data,
            },
          },
        },
      };
    case REQUEST_RESELLER: {
      return {
        ...state,
        isFetchingReseller: true,
        resellerError: false,
      };
    }
    case RECEIVE_RESELLER: {
      return {
        ...state,
        reseller: action.payload,
        isFetchingReseller: false,
      };
    }
    case REQUEST_RESELLER_FAIL: {
      return {
        ...state,
        reseller: initialState.reseller,
        resellerError: true,
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...initialState,
        reseller: state.reseller,
        resellerError: state.resellerError,
      };
    }

    case BLOCK_REAL_ESTATE: {
      return {
        ...state,
        real_estate: {
          ...state.real_estate,
          blocked: true,
        },
      };
    }

    case UNBLOCK_REAL_ESTATE: {
      return {
        ...state,
        real_estate: {
          ...state.real_estate,
          blocked: false,
        },
      };
    }

    case LOGIN_FAILURE:
    case SIGNUP_SUCCESS:
    case SIGNUP_FAILURE: {
      return {
        ...initialState,
        reseller: state.reseller,
        resellerError: state.resellerError,
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function requestLogin() {
  return { type: LOGIN_REQUEST };
}

export function receiveLogin() {
  return { type: LOGIN_SUCCESS };
}

export function loginError() {
  return { type: LOGIN_FAILURE };
}

export function requestLogout() {
  return { type: LOGOUT_REQUEST };
}

export function receiveLogout() {
  localStorage.removeItem('session');
  localStorage.removeItem('access_token');
  localStorage.removeItem('original_token');
  localStorage.removeItem('current-user');
  localStorage.removeItem('@tecimob/central');
  sessionStorage.clear();

  return { type: LOGOUT_SUCCESS };
}

export function requestUser() {
  return { type: REQUEST_USER };
}

export function receiveUser(currentUser) {
  const user = localStorage.getItem('current-user');
  const userUpdated = {
    ...user,
    ...currentUser,
  };

  localStorage.setItem('current-user', userUpdated);

  return { type: RECEIVE_USER, user: userUpdated };
}

export function requestSignUp() {
  return { type: REQUEST_USER };
}

export function requestSendEmailRecovery() {
  return { type: RECOVERY_REQUEST };
}
export function showRecoveryMessage({ email }) {
  return { type: RECOVERY_MSG_SHOW, email };
}
export function hideRecoveryMessage() {
  return { type: RECOVERY_MSG_HIDE };
}
export function sendEmailSuccess() {
  return { type: RECOVERY_SEND_EMAIL_SUCCESS };
}

export function requestPasswordReset() {
  return { type: RESET_PASSWORD_REQUEST };
}
export function passwordResetSuccess() {
  return { type: PASSWORD_RESET_SUCCESS };
}
export function passwordResetFailure() {
  return { type: PASSWORD_RESET_FAILURE };
}

export function setColorHeader(color) {
  return { type: SET_COLOR_HEADER, color };
}

export function setLogoHeader(fileUrl) {
  return { type: SET_LOGO_HEADER, fileUrl };
}

export function setHeaderType(headerType) {
  return { type: SET_HEADER_TYPE, headerType };
}

export const requestReseller = () => ({
  type: REQUEST_RESELLER,
});

export const receiveReseller = (payload) => ({
  type: RECEIVE_RESELLER,
  payload,
});

export const requestResellerFail = () => ({
  type: REQUEST_RESELLER_FAIL,
  resellerHasError: true,
});

export const blockRealEstate = () => ({
  type: BLOCK_REAL_ESTATE,
});

export const unblockRealEstate = () => ({
  type: UNBLOCK_REAL_ESTATE,
});

export const addWizard = (wizardName) => {
  // Adiciona o wizard no usuário local
  Store.set(wizardName, true, TypesInstance.local);

  return {
    type: ADD_WIZARD,
    wizardName,
  };
};

const updateAnalyticsData = (data) => {
  try {
    const currentUser = localStorage.getItem('current-user');
    localStorage.setItem('current-user', {
      ...currentUser,
      real_estate: {
        ...currentUser.real_estate,
        google_analytics: {
          ...currentUser.real_estate.google_analytics,
          ...data,
        },
      },
    });
  } catch {
    return null;
  }
};

const removeAnalyticsData = () => {
  try {
    const currentUser = localStorage.getItem('current-user');
    localStorage.setItem('current-user', {
      ...currentUser,
      real_estate: { ...currentUser.real_estate, google_analytics: null },
    });
  } catch {
    return null;
  }
};

export function resetGoogleAnalytics() {
  removeAnalyticsData();
  return { type: RESET_GOOGLE_ANALYTICS };
}

export function receiveGoogleAnalytics(data) {
  updateAnalyticsData(data);
  return { type: RECEIVE_GOOGLE_ANALYTICS, data };
}

export function setRealEstate(realEstate) {
  const currentUser = localStorage.getItem('current-user');
  localStorage.setItem('current-user', {
    ...currentUser,
    real_estate: { ...currentUser.real_estate, ...realEstate },
  });

  return { type: SET_REAL_ESTATE, realEstate };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('original-user');

  dispatch(closeModal());
  dispatch(receiveLogout());
};

const setAuthentications = (currentUser) => (dispatch) => {
  if (currentUser.real_estate.google_analytics) {
    setAnalyticsAuthToken(
      currentUser.real_estate.google_analytics.access_token
    );

    // Google Refresh Token
    dispatch(
      GoogleActions.setGoogleRefreshToken(
        currentUser.real_estate.google_analytics.refresh_token
      )
    );
    dispatch(
      GoogleActions.setGoogleAccessToken(
        currentUser.real_estate.google_analytics.access_token
      )
    );
  }

  // Atualiza as autenticações
  dispatch(setAuth(TYPES_AUTH.GOOGLE_CALENDAR, currentUser.auths.calendar));
  dispatch(
    setAuth(TYPES_AUTH.RD_STATION, currentUser?.real_estate?.auths?.rdstation)
  );

  if (_.has(currentUser, 'real_estate.auths.orulo')) {
    dispatch(setAuth(TYPES_AUTH.ORULO, currentUser.real_estate.auths.orulo));
  }

  dispatch(
    setAuth(
      TYPES_AUTH.GOOGLE_ANALYTICS,
      !!currentUser.real_estate.google_analytics
    )
  );
};

const updateWizards = (wizards) => {
  const storageWizard = Store.getAll();

  Store.set({
    ...storageWizard,
    ...wizards,
  });
};

export function getCurrentUser(access_token = null, session = null) {
  return async (dispatch) => {
    dispatch(requestUser());

    if (access_token) {
      // localStorage.removeItem('current-user');
    }

    const currentUser = localStorage.getItem('current-user');
    const originalUser = localStorage.getItem('original-user');

    // Verifica se tem algum usuário no localStorage
    if (currentUser) {
      if (!originalUser) {
        localStorage.setItem('original-user', currentUser);
      }

      // Atualiza a lista de wizards
      updateWizards(currentUser?.wizards);

      // Autenticação
      setAuthentications(currentUser)(dispatch);

      // Recebe um usuário do localStorage
      dispatch(receiveUser(currentUser));
    }

    // Busca dados do usuário
    return user.me().then((res) => {
      if (window.Tawk_API) {
        window.Tawk_API.onLoad = () => {
          window.Tawk_API.setAttributes(
            {
              name: res.data.name,
              email: res.data.email,
              serial: res.data.real_estate.serial,
            },
            function (error) {}
          );
        };
      }

      if (!originalUser) {
        localStorage.setItem('original-user', res.data);
      }

      // Recebe um usuário
      dispatch(receiveUser(res.data));

      // Atualiza a lista de wizards
      updateWizards(res?.data?.wizards);

      // Atualiza as autenticações
      setAuthentications(res.data)(dispatch);

      // Atualiza a localizaçào da imobiliária
      updateRealEstateLocation();

      return res;
    });
  };
}

/**
 * Lida com o sucesso do pagamento
 * @param dispatch
 */
const onPaymentSuccess =
  (dispatch) =>
  ({ item }) => {
    dispatch(getCurrentUser());
    dispatch(openModalPaymentSuccess({ item }));
    dispatch(hideLoading());
  };

export const listenSocketEvents = (accessToken) => (dispatch) => {
  const events = new Events(accessToken);

  // Adiciona o evento de pagamento com sucesso
  events.onPaymentSuccess(onPaymentSuccess(dispatch));

  events.onRefresh(() => {
    window.location.reload();
  });
};

/**
 * Função responsavel por lidar com o login do usuario dentro do sistema
 * @param user {Object}
 * @return {function(*)}
 */
export const loginUser = (user) => async (dispatch) => {
  localStorage.removeItem('original-user');
  localStorage.removeItem('current-user');
  localStorage.removeItem('@tecimob/central');

  // Inicia o loading de login
  dispatch(requestLogin());

  try {
    // Tenta autenticar o usuário
    const {
      data: { access_token, token_type, session },
    } = await auth.login(user);

    listenSocketEvents(access_token)(dispatch);

    // seta os headers de autenticacao
    setAuthorizationToken(access_token, token_type);

    // Busca o usuário logado
    const { data: currentUser } = await getCurrentUser(
      access_token,
      session
    )(dispatch);

    // Seta o usuário original quando faz login
    localStorage.setItem('original-user', currentUser);

    setOriginalToken(access_token, token_type);

    // dispatch(fetchPrices());

    // Finaliza o request do login
    dispatch(receiveLogin());
  } catch (err) {
    const status = _.get(err, 'response.status');

    if (status === 401) {
      //Alert.success('Usuário ou senha inválidos');
    }
    dispatch(loginError(user));
    throw new SubmissionError({
      _error: 'E-mail ou senha não estão corretos',
    });
  }
};

export const setToken =
  ({ access_token, session, token_type }) =>
  async (dispatch) => {
    localStorage.removeItem('original-user');
    localStorage.removeItem('current-user');

    listenSocketEvents(access_token)(dispatch);

    // seta os headers de autenticacao
    setAuthorizationToken(access_token, token_type);

    // Busca o usuário logado
    const { data: currentUser } = await getCurrentUser(
      access_token,
      session
    )(dispatch);

    // Seta o usuário original quando faz login
    localStorage.setItem('original-user', currentUser);

    setOriginalToken(access_token, token_type);
  };

export const signUp = (values) => async (dispatch) => {
  localStorage.removeItem('original-user');
  localStorage.removeItem('current-user');

  dispatch(requestSignUp());

  try {
    let token_type, access_token, session;

    if (values.invite) {
      ({
        data: { token_type, access_token, session },
      } = await user.signupWithInvite(values.invite, values));
    } else {
      ({
        data: { token_type, access_token, session },
      } = await auth.signup(values));
    }

    listenSocketEvents(access_token)(dispatch);

    // seta os headers de autenticacao
    setAuthorizationToken(access_token, token_type);

    // Busca o usuário logado
    const { data: currentUser } = await getCurrentUser(
      access_token,
      session
    )(dispatch);

    // Seta o usuário original quando faz login
    localStorage.setItem('original-user', currentUser);

    setOriginalToken(access_token, token_type);

    // Finaliza o request do login
    dispatch(receiveLogin());
  } catch (err) {
    throw err;
  }
};

/**
 * Recuperar senha
 * @param values
 * @param {String} values.email
 * @param {String} values.resale_id
 * @returns {function(*): Promise<T | never>}
 */
export function sendEmailPassword(values) {
  return (dispatch) => {
    dispatch(requestSendEmailRecovery());

    return user
      .sendEmailPassword(values)
      .then(() => {
        dispatch(showRecoveryMessage(values));
        dispatch(sendEmailSuccess());
      })
      .catch(() => {
        throw new SubmissionError({ email: 'Esse e-mail não existe' });
      });
  };
}

export function resetPassword(values) {
  return (dispatch) => {
    dispatch(requestPasswordReset());

    return user
      .resetPassword(values)
      .then(() => {
        dispatch(passwordResetSuccess());
      })
      .catch(() => {
        dispatch(passwordResetFailure());
        throw new SubmissionError({ password_confirm: 'Token está invalido' });
      });
  };
}

export function setGoogleAuth(code) {
  return (dispatch) =>
    calendars.setOauthCode(code).then(() => {
      dispatch(setAuth(TYPES_AUTH.GOOGLE_CALENDAR, true));
    });
}

export function disconnectGoogleCalendar() {
  return (dispatch) =>
    calendars.disconnect().then(() => {
      dispatch(setAuth(TYPES_AUTH.GOOGLE_CALENDAR, false));
    });
}

export const loginByAccessToken = (accessToken) => async (dispatch) => {
  localStorage.removeItem('original-user');
  localStorage.removeItem('current-user');

  // Inicia o loading de login
  dispatch(requestLogin());

  try {
    // seta os headers de autenticacao
    setAuthorizationToken(accessToken);

    // Busca o usuário pelo access_token
    const { data: currentUser } = await user.loginByAccessToken(accessToken);

    // Seta o usuário original quando faz login
    localStorage.setItem('original-user', currentUser);

    setOriginalToken(accessToken);

    // Finaliza o request do login
    dispatch(receiveLogin());
  } catch (err) {
    console.dir(err);
    const status = _.get(err, 'response.status');

    if (status === 401) {
      Alert.success('Usuário ou senha inválidos');
    }
    dispatch(loginError(user));
    throw new SubmissionError({
      _error: 'E-mail ou senha não estão corretos',
    });
  }
};

/**
 * Troca o usuário logado para
 * @param {string} userId -
 * @return {Function}
 */
export const changeUser = (userId) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const {
      data: { access_token, session },
    } = await user.getAccessTokenByUserId(userId);

    setAuthorizationToken(access_token);
    await getCurrentUser(access_token, session)(dispatch);
    window.location.reload(true);
  } catch (e) {
    dispatch(hideLoading());
    console.warn('Erro ao pegar o access token!');
  }
};

/**
 * Busca o revendedor baseado na url base
 * @param hostname
 * @returns {Function}
 */
export const fetchReseller = (hostname) => async (dispatch) => {
  dispatch(requestReseller());

  try {
    const { data: reseler } = await api.getOne(
      `resales/by-panel-domain/${hostname}`
    );
    dispatch(receiveReseller(reseler));
  } catch {
    dispatch(requestResellerFail());
  }
};

/**
 * Adiciona um wizard no banco de dados
 * @param wizardName
 * @returns {function(...[*]=)}
 */
export const handleAddWizard = (wizardName) => async (dispatch) => {
  try {
    // Adiciona wizard no banco
    await user.addWizard(wizardName);

    // Atualiza store e localStorage
    dispatch(addWizard(wizardName));
  } catch (e) {
    console.error('Erro ao adicionar wizard no banco de dados');
  }
};
