import Button from "components/Button";
import NoContent from "components/NoContent";
import { useCallback } from "react";
import { MdSettings } from "react-icons/md";
import image from './image.svg';

function Reception({ onClick }) {
  const handleClickAdd = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  return (
    <NoContent
      title="SEO"
      text="As informações de SEO são muito importantes para que o seu imóvel possa ser encontrado no Google. Nós já preenchemos esta parte automaticamente, mas você também pode configurar manualmente."
      image={image}
    >
      <Button
        color="primary"
        className="h-margin-bottom--10"
        onClick={handleClickAdd}
      >
        <MdSettings /> Configurar SEO
      </Button>
    </NoContent>
  );
}

export default Reception;
