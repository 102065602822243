import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactModal from 'react-modal';
// Components
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
// Modules
import { cancelRequest, getModalConfirmation } from './module';

const propTypes = {
  modalSize: PropTypes.oneOfType([
    PropTypes.oneOf(['small', 'medium', 'large']),
    PropTypes.number,
  ]),
  modalPosition: PropTypes.oneOf(['top', 'center', 'bottom']),
  config: PropTypes.shape({
    title: PropTypes.string,
    request: PropTypes.func,
    text: PropTypes.node,
    cancelButtonText: PropTypes.string,
    submitButtonText: PropTypes.string,
    onSuccess: PropTypes.func,
    onFail: PropTypes.func,
    onFinally: PropTypes.func,
    onCancel: PropTypes.func,
  }),
};

const defaultProps = {
  modalSize: 'small',
  modalPosition: 'center',
  config: {
    title: null,
    request: null,
    text: null,
    cancelButtonText: null,
    submitButtonText: null,
    onSuccess: null,
    onFail: null,
    onFinally: null,
    onCancel: null,
  },
};

class ModalConfirmation extends React.Component {
  state = {
    isFetching: false,
  };

  get className() {
    const { modalSize, modalPosition } = this.props;

    return {
      base: classnames('Modal Modal--confirmation', {
        [`Modal--${modalSize}`]: typeof modalSize === 'string',
        [`Modal--${modalPosition}`]: modalPosition,
      }),
      afterOpen: 'Modal--after-open',
      beforeClose: 'Modal--before-close',
    };
  }

  get overlayClassName() {
    const { modalPosition } = this.props;

    return {
      base: `ModalOverlay ModalOverlay--modal-${modalPosition} ModalOverlay--confirmation`,
      afterOpen: 'ModalOverlay--after-open',
      beforeClose: 'ModalOverlay--before-close',
    };
  }

  onSuccess = (...args) => {
    const { onSuccess } = this.props.config;
    if (onSuccess) onSuccess(...args);
  };

  onFail = (...args) => {
    const { onFail } = this.props.config;
    if (onFail) onFail(...args);
  };

  onFinally = (...args) => {
    const { onFinally } = this.props.config;
    if (onFinally) onFinally(...args);
    this.handleClose();
  };

  handleClickRequest = async () => {
    const {
      config: { request },
    } = this.props;

    try {
      this.setState({ isFetching: true });
      await request();
      this.onSuccess();
    } catch {
      this.onFail();
    } finally {
      this.setState({ isFetching: false });
      this.onFinally();
    }
  };

  handleClose = () => {
    const {
      config: { onCancel },
    } = this.props;
    this.props.cancelRequest();
    if (onCancel) onCancel();
  };

  get title() {
    try {
      return this.props.config.title;
    } catch {
      return 'Deletar';
    }
  }

  get renderText() {
    const { text } = this.props.config;
    return text ? (
      <p className="h-color--primary" style={{ lineHeight: '22px' }}>
        {text}
      </p>
    ) : null;
  }

  get submitButtonText() {
    try {
      const { submitButtonText } = this.props.config;

      return submitButtonText;
    } catch {
      return null;
    }
  }

  get cancelButtonText() {
    try {
      const { cancelButtonText } = this.props.config;

      return cancelButtonText;
    } catch {
      return null;
    }
  }

  render() {
    const {
      size,
      cancelButtonColor,
      cancelButtonColorText,
      submitButtonColorText,
      submitButtonColor,
      disableSubmitButton,
    } = this.props.config;

    return (
      <ReactModal
        isOpen={this.props.config.isOpen}
        className={this.className}
        overlayClassName={this.overlayClassName}
        style={{
          content: {
            maxWidth: size,
          },
        }}
      >
        <ModalTemplate
          isLoading={this.state.isFetching}
          title={this.title}
          handleClose={this.handleClose}
        >
          {this.renderText}
          <ModalFooter>
            {this.cancelButtonText ? (
              <Button
                color={cancelButtonColor || 'danger'}
                colorText={cancelButtonColorText || 'white'}
                onClick={this.handleClose}
              >
                {this.cancelButtonText}
              </Button>
            ) : null}
            <span className="h-flex__cell--grow" />
            <Button
              disabled={disableSubmitButton}
              color={submitButtonColor || 'danger'}
              colorText={submitButtonColorText || 'white'}
              onClick={this.handleClickRequest}
            >
              {this.submitButtonText}
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </ReactModal>
    );
  }
}

ModalConfirmation.propTypes = propTypes;
ModalConfirmation.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  config: getModalConfirmation(state),
});

const mapDispatchToProps = { cancelRequest };

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmation);
