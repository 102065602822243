import React from 'react';
import Container, { Header } from 'components/Container';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { closeModal, openModalPartnersStore } from 'modules/modal';
import {
  Image,
  Person,
} from 'pages/Site/pages/ContentSite/pages/Testimonials/components/TestimonialsList/styles';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import * as partnersService from 'services/sites/partners';
import Nodata from 'components/Nodata';

function PartnersList({ data, onUpdate }) {
  const dispatch = useDispatch();

  const openModal = (partner) => () => {
    dispatch(
      openModalPartnersStore({
        id: partner?.id || null,
        onSubmitSuccess: (res) => {
          dispatch(closeModal());
          if (onUpdate) onUpdate(res);
        },
      })
    );
  };

  const handleClickRemove = (partner) => () => {
    dispatch(
      openConfirmation({
        title: 'Remover parceiro?',
        text: `Você irá remover o parceiro: ${partner.title}`,
        cancelButtonText: 'Cancelar',
        submitButtonText: 'Remover parceiro',
        request: () => partnersService.remove({ id: partner.id }),
        onSuccess: () => onUpdate(),
      })
    );
  };

  if (data?.length <= 0) {
    return (
      <Container padding style={{ marginTop: '25px' }}>
        <Nodata
          image={null}
          title="Nenhum parceiro"
          text="Adicione o primeiro parceiro abaixo"
        >
          <Button onClick={openModal()} className="h-margin-top--15">
            Cadastrar parceiro
          </Button>
        </Nodata>
      </Container>
    );
  }

  return (
    <Container padding style={{ marginTop: '25px' }}>
      <Header>
        <h4>Parceiros</h4>
        <Button onClick={openModal()}>Cadastrar parceiro</Button>
      </Header>

      <table className="Table">
        <thead>
          <tr>
            <th>Título</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((partner) => (
            <tr key={partner.id + partner.file_url}>
              <td>
                <Person className="h-text-nowrap">
                  <Image size="small" src={partner.file_url} />
                  <span>{partner.title}</span>
                </Person>
              </td>
              <td className="Table__cell--small">
                <Button
                  onClick={openModal(partner)}
                  color="white"
                  colorText="primary"
                >
                  Editar
                </Button>
                <Button
                  onClick={handleClickRemove(partner)}
                  color="white"
                  colorText="danger"
                >
                  Remover
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

export default PartnersList;
