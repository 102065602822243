import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';
import ListOptions from 'components/ListOptions';
import { FormLabel } from 'components/Form';
import PropertyItem from 'components/PropertyItem';
import { ModalTemplate } from 'components/Modal';

const Content = styled.div`
  margin-bottom: 20px;
`;

const Wrapper = styled.textarea`
  width: 100%;
  max-height: 300px;
  overflow: auto;
`;

const Note = ({ children }) => {
  return (
    <Wrapper disabled className="FormControl FormControl--textarea">
      {children}
    </Wrapper>
  );
};

function PreviewMode({ deal, property, handleClose }) {
  return (
    <ModalTemplate title="Detalhes do negócio" handleClose={handleClose}>
      <Row
        className="row--divider"
        style={{ marginTop: '-20px', marginBottom: '-20px' }}
      >
        <Col xs={6} style={{ marginTop: '-20px', paddingTop: '20px' }}>
          <ListOptions
            style={{
              width: 'calc(100% + 34px)',
              marginLeft: '-19px'
            }}
          >
            <ListOptions.item>
              <Row>
                <Col xs={6}>
                  <FormLabel>Nome do cliente</FormLabel>
                  <Content>{deal?.people?.name || '--'}</Content>
                </Col>
                <Col xs={6}>
                  <FormLabel>Preço</FormLabel>
                  <Content>{deal?.price || '--'}</Content>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormLabel>Usuário</FormLabel>
                  <Content>{deal?.user?.name || '--'}</Content>
                </Col>
                <Col xs={6}>
                  <FormLabel>Comissão</FormLabel>
                  <Content>{deal?.commission || '--'}</Content>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormLabel>Comissão da imobiliária</FormLabel>
                  <Content>{deal?.real_estate_commission || '--'}</Content>
                </Col>
                <Col xs={6}>
                  <FormLabel>Data do negócio</FormLabel>
                  <Content>{deal?.earned_at || '--'}</Content>
                </Col>
              </Row>
            </ListOptions.item>
          </ListOptions>
        </Col>
        <Col xs={6} style={{ paddingTop: '17px' }}>
          <Row>
            <Col xs={12}>
              <FormLabel>Observação</FormLabel>
              <Note>{deal?.note}</Note>
            </Col>
          </Row>
          <Row className="h-margin-top--10">
            <Col xs={12}>
              <h5>Imóvel negociado:</h5>
              <div style={{ marginLeft: '-15px' }}>
                <PropertyItem canOpenProperty={false} property={property} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </ModalTemplate>
  );
}

export default PreviewMode;
