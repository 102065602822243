import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import { PROPERTY_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { Link } from 'react-router-dom';
import DuplicateProperty from './components/DuplicateProperty';
import MoveProperty from './components/MoveProperty';
// import { Wrapper } from './styles';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDispatch } from 'react-redux';
import * as plenoService from 'services/integrations/pleno';
import Alert from 'react-s-alert';
import { clearSearch } from 'modules/propertySearch';
import { useHistory } from 'react-router';
import { useSuperlogica } from 'hooks/useSuperlogica';
import { openModalGeneratePost } from 'modules/modal';

function OptionsProperty({
  property,
  onlyCloseModal,
  handleOpenModalPropertyExcluded,
  handleOpenModalPublication,
  handleOpenModalSalesAuthorization,
  handleOpenModalPropertyBook,
  handleOpenModalPropertyEditOrulo,
  handleOpenModalDuplicate,
  handleOpenModalVisit,
  openModalShareProperty,
  afterClose,
  handleReactivate,
}) {
  const { isConnected: isSuperlogica, sendProperty: sendPropertySuperlogica } =
    useSuperlogica();
  const history = useHistory();
  const isSell = property.transaction === 1 || property.transaction === '1';

  // Verifica se o imóvel é ativo
  const isPropertyActive = property.status === 'Disponível';
  const canShareProperty = !!openModalShareProperty && isPropertyActive;

  const isOrulo = property.network_type === 'Órulo';
  const isDWV = property.network_type === 'DWV';

  const oruloId = property?.network_property_id;
  const oruloUrl = `https://www.orulo.com.br/buildings/${oruloId}`;

  const dispatch = useDispatch();

  const currentUser = useCurrentUser();

  // Se usuário for corretor, irá inserir o id no link de impressão
  var urlPrint = `${currentUser?.primary_domain_url}/imprimir/${property?.id}`;

  if (currentUser?.realtor && property?.user.id !== currentUser?.id) {
    urlPrint = urlPrint + '?userId=' + currentUser?.id;
  }

  const isPleno = currentUser.real_estate.auths.plenoimob;

  const handleGeneratePost = () => {
    dispatch(
      openModalGeneratePost({
        property,
        onSuccess: () => {
          afterClose();
        },
      })
    );
  };

  const handleRegisterInPleno = () => {
    dispatch(
      openConfirmation({
        title: 'Enviar imóvel ao Pleno Imob?',
        text: 'Deseja enviar esse imóvel para o Pleno Imob?',
        request: () => {
          plenoService.sendProperty(property.id).then(() => {
            Alert.success('Imóvel enviado no Pleno Imob.');
          });
        },
        submitButtonColor: 'success',
        submitButtonText: 'Enviar ao Pleno Imob',
      })
    );
  };

  const handleRegisterInSuperlogica = () => {
    dispatch(
      openConfirmation({
        title: 'Enviar imóvel para Superlógica?',
        text: 'Deseja enviar esse imóvel para Superlógica?',
        request: () => {
          sendPropertySuperlogica({ id: property.id }).then(() => {
            Alert.success('Imóvel enviado para Superlógica.');
          });
        },
        submitButtonColor: 'success',
        submitButtonText: 'Enviar para Superlógica',
      })
    );
  };

  if (property.status === 'Excluído') {
    return (
      <Dropdown isDropHover className="h-margin-right--5">
        <Button color="white" size="large">
          Opções <Icon name="chevron-down" />
        </Button>
        <Dropdown.list>
          <Dropdown.item>
            <button onClick={handleOpenModalPropertyExcluded(property)}>
              Excluir definitivamente
            </button>
          </Dropdown.item>
          <MoveProperty property={property} afterClose={afterClose} />
          <DuplicateProperty
            property={property}
            handleOpenModalDuplicate={handleOpenModalDuplicate}
            dropdownItemText="Enviar uma cópia para rascunho"
            disabledMessage="Não é possível fazer cópia enquanto estiver fazendo upload de fotos"
          />
        </Dropdown.list>
      </Dropdown>
    );
  }

  return (
    <Dropdown isDropHover className="h-margin-right--5">
      {({ close }) => (
        <>
          <Button color="white" size="large">
            Opções <Icon name="chevron-down" />
          </Button>
          <Dropdown.list>
            {oruloId && !isDWV && (
              <Dropdown.item>
                <a href={oruloUrl} target="_blank" rel="noopener noreferrer">
                  Ver imóvel no site do órulo
                </a>
              </Dropdown.item>
            )}
            <Dropdown.item>
              <a
                href={property.site_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver imóvel no site
              </a>
            </Dropdown.item>
            {canShareProperty && (
              <Dropdown.item
                onClick={() =>
                  openModalShareProperty({
                    title: 'Adicionar imóvel ao cliente',
                    properties: [property.id],
                    type: 'add-person',
                  })
                }
              >
                <button>Adicionar ao Funil do CRM</button>
              </Dropdown.item>
            )}
            <Can run="DYNAMIC_BOOKING" permissions={property.permissions}>
              <Dropdown.item>
                <button onClick={handleOpenModalPropertyBook(property)}>
                  Reservar
                </button>
              </Dropdown.item>
            </Can>
            <Dropdown.divider />
            <Can
              run="DYNAMIC_EDIT"
              permissions={property.permissions}
              yes={() =>
                isOrulo || isDWV ? (
                  <Dropdown.item>
                    <button
                      type="button"
                      onClick={handleOpenModalPropertyEditOrulo(property)}
                    >
                      Editar dados
                    </button>
                  </Dropdown.item>
                ) : (
                  <Dropdown.item>
                    <Link
                      to={`/properties/update/id/${property.id}`}
                      onClick={(event) => {
                        if (!event.metaKey && !event.ctrlKey) {
                          dispatch(clearSearch(history));
                        }
                        onlyCloseModal();
                      }}
                    >
                      Editar dados
                    </Link>
                  </Dropdown.item>
                )
              }
              no={() => null}
            />
            {!isOrulo && !isDWV && (
              <Dropdown.item>
                <Link
                  to={`/properties/update/imagesArea/${property.id}`}
                  onClick={onlyCloseModal}
                >
                  Adicionar Fotos
                </Link>
              </Dropdown.item>
            )}
            <DuplicateProperty
              property={property}
              handleOpenModalDuplicate={handleOpenModalDuplicate}
            />
            <Dropdown.item>
              <Link
                to={`/site/content-site/slides/property/${property.id}`}
                onClick={() => {
                  onlyCloseModal();
                }}
              >
                Gerar slide
              </Link>
            </Dropdown.item>
            {/*<Dropdown.item>*/}
            {/*  <button onClick={handleGeneratePost}>*/}
            {/*    Gerar imagens Rede social*/}
            {/*  </button>*/}
            {/*</Dropdown.item>*/}
            {isOrulo && (
              <Can
                run={PROPERTY_PERMISSIONS.PUBLISH}
                permissions={property.permissions}
              >
                {(condition) =>
                  condition && (
                    <>
                      <Dropdown.item>
                        <button onClick={handleOpenModalPublication(property)}>
                          Editar Publicação
                        </button>
                      </Dropdown.item>
                    </>
                  )
                }
              </Can>
            )}

            <Dropdown.divider />

            <Dropdown.item>
              <a href={urlPrint} target="_blank" rel="noreferrer">
                Imprimir
              </a>
            </Dropdown.item>

            <Dropdown.item>
              <button onClick={handleOpenModalVisit}>
                Imprimir ficha de visita
              </button>
            </Dropdown.item>

            {!isOrulo && !isDWV && (
              <Dropdown.item>
                <button onClick={handleOpenModalSalesAuthorization}>
                  Imprimir Autorização de {isSell ? 'venda' : 'locação'}
                </button>
              </Dropdown.item>
            )}

            <Dropdown.divider />

            <Dropdown.item>
              <button onClick={handleOpenModalPropertyExcluded(property)}>
                {property.status === 'Excluído'
                  ? 'Excluir definitivamente'
                  : 'Mover para inativos'}
              </button>
            </Dropdown.item>

            <MoveProperty
              property={property}
              afterClose={afterClose}
              handleReactivate={handleReactivate}
            />

            {isPleno && (
              <>
                <Dropdown.divider />
                <Dropdown.item>
                  <button onClick={handleRegisterInPleno}>
                    Pleno Imob - Enviar dados
                  </button>
                </Dropdown.item>
              </>
            )}
            {isSuperlogica && (
              <>
                <Dropdown.divider />
                <Dropdown.item>
                  <button onClick={handleRegisterInSuperlogica}>
                    Superlógica - Enviar dados
                  </button>
                </Dropdown.item>
              </>
            )}
          </Dropdown.list>
        </>
      )}
    </Dropdown>
  );
}

export default OptionsProperty;
