import React from 'react';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Input } from 'components/Form';
import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import validate from './validate';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

function GroupForm({ isEditting, handleSubmit, handleClose, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Field
          xs={12}
          label="Motívo da perda"
          name="reason"
          component={Input}
        />
      </Row>
      <ModalFooter>
        <Button color="white" colorText="primary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          {isEditting ? 'Editar' : 'Cadastrar'}
        </Button>
      </ModalFooter>
    </form>
  );
}

GroupForm.defaultProps = defaultProps;
GroupForm.propTypes = propTypes;

export default reduxForm({
  form: 'GroupForm',
  validate,
  enableReinitialize: true
})(GroupForm);
