import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';
import FormGroup from '../FormGroup';

import { Clear, Color, Cover, Pop, Wrapper } from './styles';
import { ModalClose } from 'components/Modal';

const propTypes = {
  width: PropTypes.number,
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hex: PropTypes.string.isRequired,
    })
  ),
  canClear: PropTypes.bool,
};

const defaultProps = {
  width: 170,
  colors: [
    { name: '0B2037', hex: '#0B2037' },
    { name: '176EC8', hex: '#176EC8' },
    { name: 'AAAAAA', hex: '#AAAAAA' },
    { name: '2D1457', hex: '#2D1457' },
    { name: '01AFAD', hex: '#01AFAD' },
    { name: 'FDBD39', hex: '#fdbd39' },
    { name: '894949', hex: '#894949' },
    { name: 'FF4136', hex: '#FF4136' },
  ],
  position: 'bottom',
  triangle: 'top-right',
  placeholder: '#ffffff',
  canClear: false,
};

const percentToHex = (hexa, p) => {
  if (p === 100) return hexa;
  const intValue = Math.round((p / 100) * 255); // map percent to nearest integer (0 - 255)
  const hexValue = intValue.toString(16); // get hexadecimal representation
  return hexa + hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
};

function ColorPicker({
  xs,
  sm,
  md,
  lg,
  label,
  disabled,
  required,
  input,
  meta,
  placeholder,
  colors,
  position,
  canClear,
}) {
  const [value, setValue] = useState('');
  const [isVisible, setIsVisible] = useState();

  const handleClickToggle = useCallback(() => {
    setIsVisible((prevState) => !prevState);
  }, [setIsVisible]);

  const handleClickClose = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const handleChangeComplete = useCallback(
    ({ hex, rgb: { a: alpha } }) => {
      input.onChange(percentToHex(hex, alpha * 100));
    },
    [input]
  );

  const handleClickRemove = useCallback(() => {
    input.onChange(null);
  }, [input]);

  const canShowRemove = useMemo(() => {
    return canClear && input.value;
  }, [canClear, input]);

  useEffect(() => {
    setValue(input.value);
  }, [input.value]);

  return (
    <FormGroup
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      labelText={label}
      isDisabled={disabled}
      isRequired={required}
      input={input}
      meta={meta}
    >
      <Wrapper>
        <Color onClick={handleClickToggle}>
          <span
            className="FormControl__color h-color--primary"
            style={{ backgroundColor: value || 'transparent' }}
          />
          <input
            type="text"
            className="h-color--primary"
            value={value ? value.toUpperCase() : placeholder}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={(e) => {
              if (window.isValidHexColor(e.target.value)) {
                setValue(e.target.value);
                input.onChange(e.target.value);
              } else {
                setValue('#ffffff');
                input.onChange('#ffffff');
              }
            }}
          />
        </Color>
        {canShowRemove && (
          <Clear onClick={handleClickRemove}>
            <MdClose />
          </Clear>
        )}
      </Wrapper>
      {isVisible ? (
        <>
          <Cover onClick={handleClickClose} />
          <Pop position={position}>
            <ModalClose color="primary" handleClose={handleClickClose} />
            <SketchPicker
              presetColors={colors.map((color) => color.hex)}
              color={value}
              onChangeComplete={handleChangeComplete}
            />
          </Pop>
        </>
      ) : null}
    </FormGroup>
  );
}

ColorPicker.propTypes = propTypes;
ColorPicker.defaultProps = defaultProps;

export default memo(ColorPicker);
