import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import { AsyncSelect } from 'components/Form';
import api from 'services';
import Alert from 'react-s-alert';

// import { Wrapper } from './styles';

function FieldRealEstates({
  name = 'real_estate_id',
  filter,
  onChange,
  ...props
}) {
  // const { data, isFetching } = useRequest({
  //   request: () => api.get('properties/real-estates'),
  // });

  async function fetchData({ filter, offset = 1, limit = 100 }) {
    try {
      delete filter.real_estate_id;

      return api.get('properties/real-estates', {
        filter,
        offset,
        limit,
      });
    } catch {
      Alert.success('Problema ao buscar as imobiliárias');
    }
  }

  const loadOptions = useCallback(async (search, loadedOptions, { page }) => {
    delete filter?.count;

    const response = await fetchData({
      filter: { office_name: search, ...filter },
      offset: page,
    });

    const pagination = response?.meta?.pagination;

    return {
      options: response.data,
      hasMore: pagination?.current_page < pagination?.total_pages,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  const getOptionByValue = useCallback((id) => {
    return api.getOne('properties/real-estates', id);
  }, []);

  return (
    <Field
      name={name}
      component={AsyncSelect}
      loadOptions={loadOptions}
      valueKey="id"
      labelKey="name"
      getOptionByValue={getOptionByValue}
      onChange={onChange}
      placeholder="Selecione a imobiliária"
      additional={{
        page: 1,
      }}
    />
  );
}

export default FieldRealEstates;
