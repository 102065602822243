import styled from 'styled-components';

export const Wrapper = styled.ul`
  background: #fff;
  list-style: none;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  gap: 10px;

  .Button {
    margin-left: 0;
  }

  .Button--icon {
    padding: 0 5.5px;
  }
`;

export const Ball = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(p) => p.color};
  margin-right: 20px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapChildrens = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: -15px;
`;

export const Children = styled(Row)`
  padding: 5px 0 5px 40px;

  ${Ball} {
    width: 15px;
    height: 15px;
  }
`;

export const Btn = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 16px;
  border-radius: 4px;

  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
