import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Ducks
// import { } from '../../../ducks/';
import {
  getInitialValues,
  handleFormSubmit
} from '../../../modules/modalUserPassword';
// components do sistema
import { ModalTemplate } from '../../../components/Modal';
import UserPasswordForm from './components/UserPasswordForm';

const defaultProps = {
  handleFormSubmit: () => {}
};

const propTypes = {
  handleFormSubmit: PropTypes.func,
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

class ModalUserPassword extends Component {
  componentDidMount() {
    const { id, getInitialValues } = this.props;
    getInitialValues(id);
  }

  render() {
    const {
      id,
      modalConfig,
      isOpen,
      handleClose,
      modalType,
      modalUserPassword,
      handleFormSubmit
    } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          isLoading={modalUserPassword.meta.isFetching}
          title="Alterar senha"
          handleClose={handleClose}
        >
          <UserPasswordForm
            initialValues={modalUserPassword.formData}
            userId={id}
            handleClose={handleClose}
            onSubmit={handleFormSubmit}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

ModalUserPassword.defaultProps = defaultProps;
ModalUserPassword.propTypes = propTypes;

const mapStateToProps = state => ({
  modalUserPassword: state.modalUserPassword
});

const mapDispatchToProps = {
  handleFormSubmit,
  getInitialValues
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUserPassword);
