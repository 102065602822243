import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Modules
import {
  openModalDwv,
  openModalDwvDisconnect,
  openModalDwvTerms,
} from 'modules/modal';
// Hooks
import { useDwvIntegration } from 'pages/Integrations/containers/DwvIntegration/useDwvIntegration';
// Components
import ListOptions from 'components/ListOptions';
import Button from 'components/Button';
// Assets
import image from './dwv-logo.png';
import TextLoading from 'components/TextLoading';
import { useHistory } from 'react-router';
import { useRemoveContract } from 'hooks/useRemoveContract';
import { changeDate, ITEM_TYPES } from 'services/financialv2/contracts';
import Alert from 'react-s-alert';

const DotsLoading = ({ width = 11, timeout = 500 }) => {
  const [dots, setDots] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((value) => value + '.');
    }, timeout);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (dots.length > 3) {
      setDots('.');
    }
  }, [dots]);

  return <span style={{ display: 'inline-block', width }}>{dots}</span>;
};

function Options({
  data,
  onConnect,
  onDisconnect,
  currentItems,
  fetchCurrentItems,
}) {
  const dispatch = useDispatch();

  const { handleOpenModal } = useRemoveContract({
    item: ITEM_TYPES.DWV,
    currentItems,
    onSuccess: ({ should_change_contract }) => {
      dispatch(
        openModalDwvDisconnect({
          should_change_contract,
          onSubmitSuccess: onDisconnect,
        })
      );
    },
  });

  const handleClickConnect = useCallback(() => {
    function onAccept() {
      dispatch(openModalDwv({ onSubmitSuccess: onConnect }));
      fetchCurrentItems();
    }

    dispatch(openModalDwvTerms({ onAccept }));
  }, [dispatch, onConnect]);

  const handleClickConnectPayed = useCallback(() => {
    dispatch(openModalDwv({ onSubmitSuccess: onConnect }));
  }, [dispatch, fetchCurrentItems]);

  const handleClickDisconnect = useCallback(() => {
    handleOpenModal({
      isCancel: false,
      onSuccess: ({ should_change_contract }) => {
        dispatch(
          openModalDwvDisconnect({
            should_change_contract,
            onSubmitSuccess: onDisconnect,
          })
        );
      },
    });
  }, [dispatch, onDisconnect]);

  const handleClickCancel = useCallback(() => {
    handleOpenModal({
      isCancel: true,
      onSuccess: async () => {
        await changeDate({ item: ITEM_TYPES.DWV, quantity: 0 });
        Alert.success('DWV cancelado');
        fetchCurrentItems();
      },
    });
  }, [dispatch, onDisconnect]);

  if (!data?.user_id && currentItems?.has_dwv) {
    return (
      <>
        <Button
          color="danger"
          onClick={() => {
            handleClickCancel();
          }}
        >
          Cancelar
        </Button>
        <Button color="success" onClick={handleClickConnectPayed}>
          Conectar
        </Button>
      </>
    );
  }

  if (!data?.user_id) {
    return (
      <Button color="success" onClick={handleClickConnect}>
        Contratar
      </Button>
    );
  }

  if (data?.is_importing) {
    return (
      <div className="h-flex h-flex--center-center">
        <span className="h-margin-right--5">
          Importando Empreendimentos <DotsLoading />
        </span>
        <Button color="danger" onClick={handleClickDisconnect}>
          Desconectar
        </Button>
      </div>
    );
  }

  return (
    <>
      <Button color="white" colorText="secondary" to="/integrations/dwv">
        Editar
      </Button>
      <Button color="danger" onClick={handleClickDisconnect}>
        Desconectar
      </Button>
    </>
  );
}

function DwvIntegration({ currentItems, fetchCurrentItems }) {
  const history = useHistory();
  const { data, fetch, isFetching } = useDwvIntegration();

  const onDisconnect = () => {
    fetch();
    fetchCurrentItems();
  };

  const onConnect = useCallback(() => {
    history.push('/integrations/dwv');
  }, []);

  return (
    <ListOptions.item
      isLoading={isFetching}
      image={image}
      title="Rede DWV"
      text="Conecte-se ao DWV para encontrar imóveis de incorporadoras."
      renderOptions={() => {
        if (isFetching) return <TextLoading width={120} height={35} />;

        return (
          <Options
            fetchCurrentItems={fetchCurrentItems}
            currentItems={currentItems}
            data={data}
            onConnect={onConnect}
            onDisconnect={onDisconnect}
          />
        );
      }}
    />
  );
}

export default DwvIntegration;
