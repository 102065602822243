import React from 'react';
import Layout from 'pages/Crm/components/Layout';
import Table from './components/Table';
import { Content } from './styles';
import Loading from 'components/Loading';
import { useSelector } from 'react-redux';
import Filter from './components/Filter';
import { formValueSelector } from 'redux-form';
import { useGetLeadsLatest } from 'hooks/api/crm';
import { Col, Row } from 'react-flexbox-grid';
import Count from 'containers/TableLastLeadsReceived/components/Count';
import Graph from 'containers/TableLastLeadsReceived/components/Graph';
import moment, { format } from 'lib/moment';

export const selectorFormUser = formValueSelector('FormUser');

function Contacts() {
  // const currentUser = useCurrentUser();
  const userId = useSelector((state) => selectorFormUser(state, 'user_id'));

  const { data, isFetching, fetchData } = useGetLeadsLatest();

  const initialValues = {
    start_date: moment().subtract(30, 'days').format(format.date),
    end_date: moment().format(format.date),
    reception_source_id: 'todos',
    clients_with_contact: 'ALL_CLIENTS',
  };

  return (
    <Layout
      title="Contatos recebidos (Leads)"
      renderActions={
        <Filter
          userId={userId}
          initialValues={initialValues}
          onChange={(values) => {
            console.log(userId);
            fetchData({
              user_id: userId,
              ...values,
            });
          }}
        />
      }
    >
      <Content>
        {!isFetching && data?.leads_list?.length > 0 && (
          <Row>
            <Col xs={4}>
              <Count totalCount={data?.total_count} />
            </Col>
            <Col xs={8}>
              <Graph receptionSource={data?.reception_source_list} />
            </Col>
          </Row>
        )}
        <Table data={data} />
        <Loading isVisible={isFetching} isFullScreen />
      </Content>
    </Layout>
  );
}

export default Contacts;
