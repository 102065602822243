import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const listVariants = {
  hidden: { rotateX: 90, opacity: 0 },
  visible: { rotateX: 0, opacity: 1 }
};

export const Wrapper = styled(motion.div).attrs(({ isOpen }) => ({
  variants: listVariants,
  animate: isOpen ? 'visible' : 'hidden',
  transition: { ease: 'easeInOut' }
}))`
  color: ${p => p.theme.colors.primary};
  padding: 15px 0;
  position: absolute;
  top: 45px;
  width: 100%;
  left: 0;
  list-style: none;
  background: #fff;
  opacity: 0;
  transform-origin: 50% 0;
  z-index: 2500;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  max-height: 500px;
  overflow: auto;

  ${({ isOpen }) => {
    if (!isOpen)
      return css`
        pointer-events: none;
      `;
  }}
`;

const overlayVisibility = ({ isOpen }) => {
  if (isOpen)
    return css`
      opacity: 1;
      visibility: visible;
    `;

  return css`
    opacity: 0;
    visibility: hidden;
  `;
};

export const Overlay = styled.div`
  position: fixed;
  top: ${p => p.theme.headerHeight}px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: opacity 0.2s, visibility 0.2s;
  will-change: opacity, visibility;
  z-index: 2000;

  ${overlayVisibility}
`;

export const ContentList = styled.div`
  padding: 15px 30px;

  h1 {
    font-size: 16px;
    font-weight: 600;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
  }

  header {
    margin-bottom: 10px;
  }
`;
