import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
import Alert from 'react-s-alert';
// Components
import { SelectMultiple } from 'components/Form';
// Services
import * as peopleService from 'services/people';
// Modules
import { openModalPersonAdd } from 'modules/modal';
// HOC
import { withFormName } from 'HOC/withFormName';
// Helpers
import { parseSelect } from 'lib/formHelpers';
import PersonOption from 'components/PersonOption';

class FieldSelectPeople extends React.Component {
  constructor(props) {
    super(props);

    this.timeout = 0;

    this.state = {
      options: props.options || [],
      isLoading: false,
    };
  }

  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    component: PropTypes.func.isRequired,
    params: PropTypes.object,
    limit: PropTypes.number,
    checkGroupOwner: PropTypes.bool,
    checkGroupByName: PropTypes.string,

    onCreatePerson: PropTypes.func,
    onClickNew: PropTypes.func,
  };

  static defaultProps = {
    multi: false,
    label: 'Cliente',
    name: 'people_id',
    labelKey: 'name',
    valueKey: 'id',
    component: SelectMultiple,
    parse: parseSelect('id'),
    limit: 20,
    checkGroupOwner: false,
    checkGroupByName: '',

    // Adicionar um novo cliente
    creatable: true,
    onCreatePerson: null,
    onClickNew: null,

    params: {
      sort: 'name',
    },
  };

  request() {
    this.setState({ isLoading: true });
  }

  add(person) {
    this.setState({
      options: [...this.state.options, person],
      isLoading: false,
    });
  }

  receive(options) {
    this.setState({
      options: [...this.state.options, ...options],
      isLoading: false,
    });
  }

  /**
   * Verifica se o id está na lista
   * @param idOrPerson
   * @returns {boolean|null}
   */
  hasIdInOptions(idOrPerson) {
    const { options } = this.state;

    if (idOrPerson === null) return null;

    if (typeof idOrPerson === 'object') {
      return !!options.find((person) => person.id === idOrPerson.id);
    }
    return !!options.find((person) => person.id === idOrPerson);
  }

  async componentDidMount() {
    const { personId, limit } = this.props;

    await this.fetchPeople({
      limit,
      offset: 1,
      sort: '-updated_at',
    });

    // Verifica se o id está na lista

    if (!!personId) {
      const hasPerson = this.hasIdInOptions(personId);

      if (!hasPerson) {
        this.fetchPerson(personId);
      }
    }
  }

  componentDidUpdate({ personId: prevPersonId }) {
    const { personId, params } = this.props;

    // Verifica se o valor anterior é diferente do próximo
    const isDifferent = personId !== prevPersonId;

    // Verifica se o id está na lista
    const hasPerson = !this.hasIdInOptions(personId);

    // Ele pode buscar a informação do cliente se o site está diferente
    if (isDifferent && hasPerson) {
      this.fetchPerson(personId, params);
    }
  }

  /**
   * Busca os dados de um cliente específico
   * @param personId
   * @param params
   * @returns {Promise<{_error: string}>}
   */
  fetchPerson = async (personId, params) => {
    let _params = {
      ...this.props.params,
      ...params,
    };

    if (personId === null) return;

    try {
      this.request();
      const { data: person } = await peopleService.getOne(personId, _params);
      this.add(person);
      return person;
    } catch {
      const _error = 'Erro ao buscar dados de um cliente';
      Alert.error(_error);
      return Promise.resolve({ _error });
    }
  };

  /**
   * Busca os dados do cliente
   * @param params
   * @returns {Promise<{_error: string}>}
   */
  fetchPeople = async (params) => {
    const { filter } = this.props;

    let _params = {
      ...this.props.params,
      ...params,
    };

    if (filter) {
      _params.filter = {
        ...params.filter,
        ...filter,
      };
    }

    // Limpa tudo sempre antes de buscar
    this.setState({
      options: [],
    });

    try {
      this.request();
      const { data: options } = await peopleService.getAll(_params);
      this.receive(options);
      return options;
    } catch {
      const _error = 'Erro ao buscar clientes!';
      Alert.error(_error);
      return Promise.resolve({ _error });
    }
  };

  onNewOptionClick = (currentPerson) => {
    const {
      checkGroupOwner,
      checkGroupByName,
      onCreatePerson,
      onNewOptionClick,
      onClickNew,
      name,
    } = this.props;

    if (onClickNew) onClickNew(currentPerson);
    if (onNewOptionClick) return onNewOptionClick(currentPerson);

    return this.props.openModalPersonAdd(
      { name: currentPerson.name },
      (person) => {
        // Verifica se tem algum callback para executar quando
        // adiciona um novo cliente na lista
        if (onCreatePerson) onCreatePerson(person);

        // Atualiza a lista de clientes
        this.props.change(name, person.id);
      },
      {
        checkGroupOwner,
        checkGroupByName,
      }
    );
  };

  onInputChange = (value) => {
    Tooltip.hide();
    clearTimeout(this.timeout);

    // Verifica se o length é maior que 3
    if (value.length < 3) return false;

    this.timeout = setTimeout(() => {
      this.fetchPeople({
        filter: { ...this.props?.params?.filter, by_search: value },
        limit: 30,
        offset: 1,
      });
    }, 200);
  };

  /**
   * Retorna as opções necessárias para quando o input é do tipo creatable
   * @return {Object} - Retorna as props relacionadas a creatable
   */
  get creatableOptions() {
    const { creatable } = this.props;

    if (creatable) {
      return {
        creatable,
        onNewOptionClick: this.onNewOptionClick,
      };
    }

    return {};
  }

  get options() {
    const { mapOptions } = this.props;

    if (mapOptions) {
      return this.state.options.map(mapOptions);
    }

    return this.state.options;
  }

  render() {
    const { isLoading } = this.state;

    // Remove as opções para não ir no field
    const { creatable, onCreatePerson, params, ...props } = this.props;

    return (
      <Field
        onInputChange={this.onInputChange}
        options={this.options}
        isLoading={isLoading}
        optionComponent={PersonOption}
        filterOption={() => true}
        {...this.creatableOptions}
        {...props}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // Pega o selector do formulário
  const selector = formValueSelector(ownProps.formName);

  // Pega o nome do campo padrão
  const fieldName = ownProps.name || 'people_id';

  return {
    personId: selector(state, fieldName),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const changeField = (...args) => change(ownProps.formName, ...args);
  return bindActionCreators(
    {
      openModalPersonAdd,
      change: changeField,
    },
    dispatch
  );
};

export default compose(
  withFormName,
  connect(mapStateToProps, mapDispatchToProps)
)(FieldSelectPeople);
