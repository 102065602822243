import React from 'react';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import styled from 'styled-components';
import Button from 'components/Button';
// import { Wrapper } from './styles';

const Wrapper = styled.div`
  padding: 15px 40px 10px;

  p + p {
    margin-top: 15px;
  }

  ul {
    margin-top: 15px;
    margin-left: 10px;
    list-style: inside;
  }
`;

function Initial({ handleClose, changePage }) {
  return (
    <ModalTemplate title="Adicionar portal" handleClose={handleClose}>
      <Wrapper>
        <p>
          A opção ”Adicionar portal” é utilizada para você poder incluir portais
          que não são suportados inicialmente, normalmente novos portais ou
          portais regionais.
        </p>
        <p>
          <strong>Como funciona a integração</strong>
        </p>
        <p>
          Diariamente geramos um arquivo no formato XML com o conteúdo dos seus
          imóveis selecionados, para que o portal faça a leitura e cadastro ou
          atualização dos seus imóveis.
        </p>
        <p>
          <strong>Modelo de comunicação suportado</strong>
        </p>
        <p>
          Para a comunicação entre o Tecimob e o portal cadastrado, ele precisa
          fazer a leitura de arquivo XML seguindo o padrão de alguns destes
          modelos abaixo:
        </p>
        <ul>
          <li>Padrão Vivareal; ou</li>
          <li>Padrão ZAP Imóveis; ou</li>
          <li>Padrão Imovelweb; ou</li>
          <li>Padrão OLX</li>
        </ul>
      </Wrapper>
      <ModalFooter>
        <Button color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button color="success" type="button" onClick={changePage('Form')}>
          Avançar
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

export default Initial;
