import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div).attrs(() => ({
  layout: true,
  initial: { height: 0 },
  animate: { height: 'auto' },
  exit: { height: 0 },
  transition: { duration: 0.3, ease: 'easeInOut' },
}))`
  overflow: hidden;
  margin-bottom: 15px;

  .timeline__hour {
    font-size: 14px;
    line-height: 1em;
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px;
  }
`;

export const Item = styled.div`
  position: relative;
  padding-left: 40px;
  padding-top: 10px;

  a {
    color: #0084f4;
    text-decoration: underline;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 24px;
    top: 0;
    width: 1px;
    height: 100%;
    background: #adb7c5;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 3px;
    height: 3px;
    background: #adb7c5;
    position: absolute;
    left: 23px;
    top: 17px;
  }
`;
