import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../components/Icon';
import { Actions } from './styles';

const defaultProps = {
  defaultIconColor: '#0063c0',

  limit: null,
  limitText: 'Ver tudo',
  data: [{}],
  renderItem: null,
  renderText: null,
};

const propTypes = {
  defaultIconColor: PropTypes.string,
  limit: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func,
  renderText: PropTypes.func,
};

const Item = ({
  title,
  text,
  icon: IconC,
  iconColor,
  iconColorHex,
  renderText,
  renderActions,
}) => (
  <li className="Timeline__item">
    <div className="Timeline__iconWrapper">
      <div
        className={`Timeline__icon h-bg--${iconColor}`}
        style={{ backgroundColor: iconColorHex }}
      >
        {typeof IconC === 'string' ? (
          <Icon name={IconC} color="white" />
        ) : (
          <IconC color="#fff" style={{ marginTop: '-3px' }} />
        )}
      </div>
    </div>
    <div className="Timeline__container">
      <p className="Timeline__title">{title}</p>
      {renderText ? renderText() : <p>{text}</p>}
    </div>
    {renderActions && <Actions>{renderActions()}</Actions>}
  </li>
);

const getMaxItems = (limit) => (d, index) => index < limit;
const getMinItems = (limit) => (d, index) => index >= limit;

class Timeline extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };

    this.getItems = this.getItems.bind(this);
    this.showItems = this.showItems.bind(this);
  }

  showItems() {
    this.setState({ isOpen: true });
  }

  getItems() {
    const { data, limit, renderItem } = this.props;
    let totalItems = data.length;
    let hasMore = totalItems > limit && limit !== null;

    // verifica se tem limite a lista
    if (hasMore) {
      const dataMax = data.filter(getMaxItems(limit));
      const dataMin = data.filter(getMinItems(limit));

      return (
        <React.Fragment>
          {dataMax.map(renderItem)}
          {this.state.isOpen && dataMin.map(renderItem)}
        </React.Fragment>
      );
    }

    // se não tiver limite lista todos os items
    return data.map(renderItem);
  }

  render() {
    const { data, limit, limitText, style, children } = this.props;
    let totalItems = data.length;
    let hasMore =
      !children && totalItems > limit && limit !== null && !this.state.isOpen;

    return (
      <div style={style} className="TimelineWrapper">
        <ul className="Timeline">{children ? children : this.getItems()}</ul>
        {hasMore && (
          <button
            className="Timeline__link h-color--secondary h-text-underline"
            onClick={this.showItems}
          >
            {limitText}
          </button>
        )}
      </div>
    );
  }
}

Timeline.defaultProps = defaultProps;
Timeline.propTypes = propTypes;

Timeline.item = Item;

export default Timeline;
