import React from 'react';
import PropTypes from 'prop-types';
// Components
import CardProfile from 'components/CardProfile';

const propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object)
};
const defaultProps = {
  profiles: null
};

const ListProfiles = ({ title, profiles, handleClickDelete }) => {
  return profiles.map(profile => (
    <CardProfile
      data={profile}
      isRemoving={profile.isRemoving}
      handleClickDelete={handleClickDelete}
    />
  ));
};

ListProfiles.propTypes = propTypes;
ListProfiles.defaultProps = defaultProps;

export default ListProfiles;
