import api from './';
//import { getScret } from 'services/recaptcha';

/**
 * Autentica o usuario no sistema
 * @param user
 * @return {AxiosPromise|*}
 */
export const login = async user => {
  //const secret = await getScret();
  return api.create('auths', user);
};

/**
 * Mudar o usuário logado
 */

/**
 * Cadastra um usuário
 * @param values
 * @return {AxiosPromise|*}
 */
export const signup = async values => {
  //const secret = await getScret();
  return api.create('signup', values);
};

/**
 * Invalida tokens anteriores
 * @return {boolean|AxiosPromise|*}
 */
export const logout = session => api.delete('auths', session);

export const connectOrulo = values =>
  api.create('auths/networks/orulo', values);
export const disconnectOrulo = () => api.delete('auths/networks/orulo');

// Verifica se o usuário pode fazer algo no sistema
export const verifyToken = () => api.get('auths/verify-token');

// export const isAllowed = (right, currentUser = localStorage.getItem('current-user')) => currentUser.role.includes('admin') || currentUser.rights.includes(right);
