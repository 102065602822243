import React from 'react';
import Menu from '../../components/Menu';
import MenuItem from '../../components/MenuItem';
import { MdPerson, MdSearch } from 'react-icons/md';

const MenuPeople = () => {
  return (
    <Menu title="Clientes" canBack>
      <MenuItem
        icon={MdPerson}
        to="/people/show"
        title="Resumo"
        text="Todos os seus clientes"
      />
      <MenuItem
        to="/profile/search"
        icon={MdSearch}
        color="#00C48C"
        title="Pesquisar por perfil"
        text="Encontre o cliente ideal"
      />
    </Menu>
  );
};

export default MenuPeople;
