import React, { useState } from 'react';
import Alert from 'react-s-alert';
import Box from '../../components/Box';
import PreviewImage from 'components/PreviewImage';
import { useRequest } from 'hooks/useRequest';
import * as logosService from 'services/sites/logos';
import Overlay from 'components/Overlay';
import Button from 'components/Button';
import UploadButton from 'components/UploadButton';

const Favicon = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { data: logo, fetchData } = useRequest({
    request: logosService.getFavicon,
    initialState: {}
  });

  const onDropAccepted = async files => {
    setIsLoading(true);

    try {
      await logosService.uploadFavicon({ image: files[0] });
      await fetchData();
    } catch (err) {
      Alert.success('Erro ao enviar imagem');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      isLoading={isLoading}
      title="Para aba do navegador (Favicon)"
      text="Medida: 48x48px"
    >
      <UploadButton onDropAccepted={onDropAccepted}>
        <PreviewImage
          key={logo?.favicon_url}
          className="h-hover"
          url={logo?.favicon_url}
        >
          <Overlay
            alpha={!!logo?.favicon_url ? 0.6 : 0}
            className={!!logo?.favicon_url && 'h-hover__show'}
          >
            {!!logo?.favicon_url ? (
              <Button color="white">Alterar</Button>
            ) : (
              <Button color="secondary">Adicionar</Button>
            )}
          </Overlay>
        </PreviewImage>
      </UploadButton>
    </Box>
  );
};

export default Favicon;
