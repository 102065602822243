export default (values) => {
  const errors = {};

  if (values.loss_reason_id === 'other' && !values.reason) {
    errors.reason = ['Campo obrigatório'];
  }

  if (!values.loss_reason_id) {
    errors._error = 'Campo é obrigatório';
  }

  return errors;
};
