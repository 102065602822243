import React, { useCallback, useState } from 'react';
import { Number, Wrapper } from './styles';
import { useDispatch } from 'react-redux';

// Services
import * as userService from 'services/user';

// Modules
import { openModalSatisfaction } from 'modules/modal';
import { receiveUser } from 'modules/login';
import { useCurrentUser } from 'hooks/useCurrentUser';

const Rating = () => {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const colorByIndex = (index) => {
    if (index >= 0 && index <= 6) return 'danger';
    if (index >= 7 && index <= 8) return 'caution';
    return 'success';
  };

  const handleClick = useCallback(async (rate) => {
    setIsLoading(true);
    // Envia
    const { data } = await userService.sendSatisfaction({ rate: rate });

    dispatch(
      receiveUser({
        ...currentUser,
        should_ask_satisfaction: false,
      })
    );

    dispatch(
      openModalSatisfaction({
        satisfaction: data,
      })
    );

    setIsLoading(false);
  }, []);

  return (
    <Wrapper>
      {[...Array(11)].map((x, index) => (
        <Number
          key={'RATING-' + index}
          color={colorByIndex(index)}
          onClick={() => {
            if (!isLoading) {
              handleClick(index);
            }
          }}
        >
          {index}
        </Number>
      ))}
    </Wrapper>
  );
};

export default Rating;
