import React, { useEffect, useState } from 'react';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
// Components
import { Input } from 'components/Form';
import SelectPortal from '../SelectPortal';
import { ModalFooter } from 'components/Modal';
// Hooks
import useFormValue from 'hooks/useFormValue';
// Services
import * as portalsService from 'services/settings/portals';
import validate from './validate';
import Button from 'components/Button';

function FormPortalAdd({ handleSubmit, change, handleClose }) {
  const modelId = useFormValue('portal_model_id');

  const [portals, setPortals] = useState([]);
  const [loading, setLoading] = useState(false);

  // Busca todos os portais
  // para popular o select com as opções necessárias
  async function fetchPortals() {
    setLoading(true);
    const { data } = await portalsService.getList({
      filter: {
        by_patterns: true,
      },
    });

    setLoading(false);
    setPortals(data);
  }

  // Quando mudar um valor do campo modelId deve resetar
  // os valores das options
  useEffect(() => {
    change('options', null);
  }, [modelId]);

  // Busca os dados do portal
  // no componentDidMount
  useEffect(() => {
    fetchPortals();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <p className="h-margin-bottom--15">
        Preencha abaixo os dados do portal que deseja adicionar
      </p>
      <Row>
        <Field xs={6} label="Nome do Portal" name="name" component={Input} />
        <Field
          xs={6}
          label="Seu código ou e-mail de cliente"
          name="client_code"
          component={Input}
        />
      </Row>
      <SelectPortal data={portals} isLoading={loading} />
      <ModalFooter>
        <Button onClick={handleClose} color="white" colorText="secondary">
          Cancelar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormPortalAdd',
  validate,
  enableReinitialize: true,
})(FormPortalAdd);
