import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { Col, Row } from 'react-flexbox-grid';
// Components
import { ModalTemplate } from '../../../components/Modal';
import Button from 'components/Button';
// Modules
import { openModalPostLayout, openModalPostLinkStore } from 'modules/modal';
// Styles
import { Wrapper } from './styles';
// Assets
import postBlank from './assets/post-blank.png';
import postLink from './assets/post-link.png';

export default function ModalPostOptions({
  modalConfig,
  isOpen,
  modalType,
  handleClose
}) {
  const dispatch = useDispatch();

  // Lida com o clique de criar um post a partir de uma página
  const handleClickPostBlank = useCallback(() => {
    dispatch(openModalPostLayout());
  }, [dispatch]);

  // Lida com o clique de criar um post a partir de um link
  const handleClickPostLink = useCallback(() => {
    dispatch(openModalPostLinkStore());
  }, [dispatch]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Criar postagem a partir de:"
        handleClose={handleClose}
      >
        <Row>
          <Col xs={6}>
            <Wrapper onClick={handleClickPostBlank}>
              <img src={postBlank} alt="Post Blank" />
              <h1>Criar Postagem</h1>
              <p>
                Crie uma <br />
                postagem do zero.
              </p>
              <Button color="success">Selecionar</Button>
            </Wrapper>
          </Col>
          <Col xs={6}>
            <Wrapper onClick={handleClickPostLink}>
              <img src={postLink} alt="Post Link" />
              <h1>Um link</h1>
              <p>
                Cole o link de uma
                <br /> postagem de outro site.
              </p>
              <Button color="success">Selecionar</Button>
            </Wrapper>
          </Col>
        </Row>
      </ModalTemplate>
    </Modal>
  );
}
