import React from 'react';
import ListOptions from 'components/ListOptions';
import { Field, formValueSelector } from 'redux-form';
import { Input, Select } from 'components/Form';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import Container from 'pages/Site/components/ContainerSite';
import { useSelector } from 'react-redux';
import useReduxForm from 'hooks/useReduxForm';
// import { Wrapper } from './styles';

const selector = formValueSelector('PartnersForm');

function ConfigParners() {
  const { submitting } = useReduxForm();

  const is_partners_shown = useSelector((state) =>
    selector(state, 'is_partners_shown')
  );

  const style = {
    opacity: is_partners_shown ? 1 : 0.3,
    pointerEvents: is_partners_shown ? 'auto' : 'none',
  };

  return (
    <Container>
      <ListOptions>
        <ListOptions.item
          title="Mostrar Parceiros na página inicial"
          text="Mostrar na página inicial uma galeria com parceiros a sua escolha."
          renderOptions={() => (
            <Field
              canRemoveSelf={false}
              buttonTemplate
              name="is_partners_shown"
              component={Select}
              options={[
                { value: true, label: 'Mostrar' },
                { value: false, label: 'Esconder', _color: 'danger' },
              ]}
            />
          )}
        />
        <ListOptions.item
          title="Título da área"
          text="Edite o título que ficará acima dos parceiros."
          style={style}
        >
          <div className="h-margin-top--10">
            <Field name="partners_title" component={Input} />
          </div>
        </ListOptions.item>
      </ListOptions>
      <FixedBar style={{ left: 240 }}>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar
        </Button>
      </FixedBar>
    </Container>
  );
}

export default ConfigParners;
