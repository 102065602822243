import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import { Input, Textarea, Toggle } from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import { ModalFooter } from '../../../../../components/Modal';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const FormDealGain = ({
  lossReasonId,
  reasons,
  handleSubmit,
  handleChangeModal,
  ...rest
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Row className="h-margin-bottom--10">
        <Col xs={12}>
          <ul className="h-list--none">
            {reasons &&
              reasons.map(reason => (
                <li key={reason.id}>
                  <label>
                    <Field
                      type="radio"
                      name="loss_reason_id"
                      component="input"
                      value={reason.id}
                      onChange={() => rest.change('reason', null)}
                    />{' '}
                    {reason.reason}
                  </label>
                </li>
              ))}
            <li>
              <label>
                <Field
                  type="radio"
                  name="loss_reason_id"
                  component="input"
                  value="other"
                />{' '}
                Outro
              </label>
            </li>
            {lossReasonId === 'other' && (
              <li>
                <Row className="h-margin-top--10">
                  <Field
                    xs={4}
                    name="reason"
                    component={Input}
                    placeholder="Digite o motivo aqui"
                  />
                </Row>
              </li>
            )}
          </ul>
        </Col>
      </Row>
      <Row>
        <Field
          xs={12}
          valueFormat
          label="Observação"
          name="loss_note"
          component={Textarea}
        />
      </Row>
      <h5 className="h-margin-bottom--5">
        Remover da lista de nutrição do perfil de busca?
      </h5>
      <p>
        Marcando essa opção, seu cliente não irá mais receber ofertas de imóvel
        por e-mail, tendo em vista que já concretizou o negócio.
      </p>
      <Field name="remove_search_profile" component={Toggle} />
      <ModalFooter>
        <Button
          color="white"
          colorText="secondary"
          onClick={() => handleChangeModal('ModalInfo')}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Finalizar
        </Button>
      </ModalFooter>
    </form>
  );
};

FormDealGain.defaultProps = defaultProps;
FormDealGain.propTypes = propTypes;

const FormDealGainContainer = reduxForm({
  form: 'FormDealLoss',
  enableReinitialize: true
})(FormDealGain);

const selector = formValueSelector('FormDealLoss');

const mapStateToProps = state => ({
  lossReasonId: selector(state, 'loss_reason_id')
});

export default connect(mapStateToProps)(FormDealGainContainer);
