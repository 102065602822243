import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  type: PropTypes.oneOf(['raised', 'rounded']),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percentage: PropTypes.number,
  showPercentage: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
  count: PropTypes.string
};

const defaultProps = {
  type: 'rounded',
  percentage: null,
  showPercentage: true,
  color: 'secondary',
  label: null,
  count: null
};

const Label = ({ className, color, children, ...rest }) => (
  <span
    {...rest}
    className={classnames('Progress__label', className, 'h-text-right', {
      [`h-color--${color}`]: color
    })}
  >
    {children}
  </span>
);

class Progress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0
    };

    this.setWidth = this.setWidth.bind(this);
  }
  componentDidMount() {
    this.setWidth();
    window.addEventListener('resize', this.setWidth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth);
  }

  setWidth = () => {
    this.setState({ width: this.progress.offsetWidth });
  };

  render() {
    const {
      height,
      infinite,
      type,
      label,
      count,
      percentage,
      showPercentage,
      color
    } = this.props;

    return (
      <div
        ref={el => {
          this.progress = el;
        }}
        className={classnames('Progress', {
          'Progress--label': label,
          'Progress--infinite': infinite,
          [`Progress--${color}`]: color,
          [`Progress--${type}`]: type
        })}
        style={{ height: height }}
      >
        {count && (
          <Label className="Progress__label--left Progress__label--count">
            {count}
          </Label>
        )}
        <div className="Progress__bar" style={{ width: `${percentage}%` }}>
          {showPercentage && (
            <span className="Progress__percentage">{percentage}%</span>
          )}
          {label && (
            <Label
              className="Progress__label--left"
              style={{ width: this.state.width }}
            >
              {label}
            </Label>
          )}
        </div>
        {label && (
          <Label className="Progress__label--right" color="primary">
            {label}
          </Label>
        )}
      </div>
    );
  }
}

Progress.propTypes = propTypes;
Progress.defaultProps = defaultProps;

export default Progress;
