import React from 'react';
import { Field } from 'redux-form';
import { Select } from 'components/Form';
import * as options from 'constants/options';

const FieldCondoPosition = ({ subtype }) => {
  if (!subtype || subtype?.title !== 'Apartamento') return null;

  return (
    <Field
      xs={3}
      label="Posição"
      name="condo_position"
      component={Select}
      options={options.CONDO_POSITION}
    />
  );
};

export default FieldCondoPosition;
