import React, { useContext, useMemo } from 'react';
import FormGroup from '../FormGroup';
import { Wrapper, Row, Square } from './styles';

const Context = React.createContext({ value: null, onChange: null });

const Item = ({ value }) => {
  const input = useContext(Context);

  const isSelected = useMemo(() => {
    return value === input.value;
  }, [value, input]);

  return (
    <Square isSelected={isSelected} onClick={() => input.onChange(value)} />
  );
};

const Position = ({
  required,
  maxLength,
  formGroupClassName,
  label,
  disabled,
  meta,
  input
}) => {
  return (
    <Context.Provider value={input}>
      <FormGroup
        className={formGroupClassName}
        maxLength={maxLength}
        isRequired={required}
        isDisabled={disabled}
        input={input}
        meta={meta}
        labelText={label}
      >
        <Wrapper>
          <Row>
            <Item value="NorthWest" />
            <Item value="North" />
            <Item value="NorthEast" />
          </Row>
          <Row>
            <Item value="West" />
            <Item value="Center" />
            <Item value="East" />
          </Row>
          <Row>
            <Item value="SouthWest" />
            <Item value="South" />
            <Item value="SouthEast" />
          </Row>
        </Wrapper>
      </FormGroup>
    </Context.Provider>
  );
};

export default Position;
