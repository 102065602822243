import { createModule } from 'lib/reducer-helpers';

// Actions
const createAction = createModule('reception');
const SHOW = createAction('SHOW');
const HIDE = createAction('HIDE');

// Initial State
const initialState = { isVisible: true };

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW:
      return { ...state, isVisible: true };
    case HIDE:
      return { ...state, isVisible: false };
    default:
      return state;
  }
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export const show = () => dispatch => dispatch({ type: SHOW });
export const hide = () => dispatch => dispatch({ type: HIDE });
