import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 25px 25px 0;
  justify-content: space-between;
`;

export const Results = styled.div`
  display: flex;
`;

export const Link = styled.button`
  font-size: 16px;
  font-weight: bold;

  &:not(.active) {
    color: #0063c0;
    text-decoration: underline;
    cursor: pointer;
  }

  &.active {
    color: #000;
    cursor: default;
    pointer-events: none;
  }
`;

export const Divider = styled.div`
  padding: 0 10px;
  font-weight: bold;
`;

export const Actions = styled.div`
  display: flex;

  select {
    height: 35px;
  }

  .FormGroup {
    margin-bottom: 0;
  }
`;
