import withProvider from 'HOC/withProvider';
import { openModalPaymentSuccess } from 'modules/modal';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { FormProvider } from '../../contexts/form';
import Payment from '../../pages/Payment';
import PaymentError from '../../pages/PaymentError';
import PaymentLoading from '../../pages/PaymentLoading';
import UpdateContract from '../../pages/UpdateContract';
import useFormValue from 'hooks/useFormValue';
import { useModalPayment } from 'containers/ModalRoot/ModalUpdateContractDate/contexts/payment';

const Content = ({
  initialize,
  hasError,
  setHasError,
  initial,
  isLoading,
  paymentMethod,
  isSuccess,
  handleSubmit,
  ...props
}) => {
  const receiving_method = useFormValue('receiving_method');
  const { page } = useModalPayment();

  const dispatch = useDispatch();

  useEffect(() => {
    initialize(initial);
  }, []);

  useEffect(() => {
    if (!isSuccess) return;
    dispatch(openModalPaymentSuccess({ receivingMethod: receiving_method }));
  }, [isSuccess]);

  function renderContent() {
    if (hasError) {
      return (
        <PaymentError setHasError={setHasError} handleClose={props.onClose} />
      );
    }

    if (isLoading) {
      return (
        <PaymentLoading
          receivingMethod={receiving_method}
          handleClose={props.onClose}
        />
      );
    }

    switch (page) {
      case 'Payment':
        return <Payment handleClose={props.onClose} {...props} />;
      case 'UpdateContract':
      default:
        return <UpdateContract handleClose={props.onClose} {...props} />;
    }
  }

  return <form onSubmit={handleSubmit}>{renderContent()}</form>;
};

export default compose(
  withProvider(FormProvider),
  reduxForm({
    form: 'PaymentForm',
    forceUnregisterOnUnmount: true,
  })
)(Content);
