import React, { useContext } from 'react';
import { ContextCompare } from '../../index';
import { Cell, CellLabel } from '../Table/styles';
import RowDivider from '../../components/RowDivider';

const RowImage = () => {
  const { properties } = useContext(ContextCompare);

  return (
    <>
      <RowDivider />
      <tr>
        <CellLabel rowSpan={2}>Situação</CellLabel>
        {properties.map(({ id, rooms, areas, situation }, index) => {
          return (
            <Cell key={`situations-${id}-${index}`}>{situation?.title}</Cell>
          );
        })}
      </tr>
    </>
  );
};

export default RowImage;
