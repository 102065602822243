import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { selector } from '../index';
// Components
import MultipleDayPicker from 'components/MultipleDayPicker';
import Button from 'components/Button';
import FormBox from 'components/FormBox';
import { FieldBool, Input } from 'components/Form';

const defaultProps = {
  fields: []
};
const propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object)
};

const Navbar = ({ onPreviousClick, onNextClick, className }) => (
  <div className={`h-flex ${className} h-flex`} style={{ marginBottom: 15 }}>
    <div className="h-flex__cell h-flex__cell--shrink Form--inline">
      <Field label="Máximo de hóspedes" name="max_people" component={Input} />
    </div>
    <div className="h-flex__cell--grow" />
    <Button color="white" colorText="primary" onClick={() => onPreviousClick()}>
      ← Anterior
    </Button>
    <Button color="white" colorText="primary" onClick={() => onNextClick()}>
      Próximo →
    </Button>
  </div>
);

class Info extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isVisible: props.isVisible
    };
  }
  render() {
    return (
      <FormBox
        title="Temporada"
        className="js-season"
        actions={
          <div>
            <FieldBool canRemoveSelf={false} name="is_season_available" />
          </div>
        }
      >
        {this.props.is_season_available && (
          <Field
            name="seasonal_availability"
            component={MultipleDayPicker}
            navbarElement={<Navbar />}
          />
        )}
      </FormBox>
    );
  }
}

Info.defaultProps = defaultProps;
Info.propTypes = propTypes;

const mapStateToProps = state => ({
  is_season_available: selector(state, 'is_season_available')
});

export default connect(mapStateToProps)(Info);
