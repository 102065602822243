import React, { useCallback } from 'react';
import ReactToggle from 'react-toggle';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Dropdown, { Item, List } from 'components/Dropdown';
import { useDispatch } from 'react-redux';
import { MdExpandMore } from 'react-icons/md';
// Components
import Button from 'components/Button';
import NestTable from 'components/NestTable';
// Modules
import { openModalPostLinkStore } from 'modules/modal';
// Styles
import {
  Actions,
  ColStatus,
  ColTitle,
  DragIcon,
  TableRow,
  Wrapper,
  WrapperAll,
} from './styles';
import { useCurrentUser } from 'hooks/useCurrentUser';

function TablePosts({ data, onChange, onDelete, onChangeStatus }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const { path } = useRouteMatch();

  const handleOpenPost = (post) => () => {
    const domainBase = currentUser.primary_domain_url;

    if (post?.is_internal_link === false) {
      window.open(post.link, '_blank');
    } else {
      window.open(`${domainBase}/postagem/${post.link || post.id}`, '_blank');
    }
  };

  const handleClickUpdate = useCallback(
    (post) => () => {
      if (post?.is_internal_link === false) {
        dispatch(
          openModalPostLinkStore({
            id: post.id,
          })
        );

        return;
      }

      history.push(`${path}store/${post.type}/${post.id}`);
    },
    [dispatch, history, path]
  );

  if (!data || data.length === 0) return null;

  return (
    <WrapperAll>
      <TableRow>
        <ColTitle>Postagem</ColTitle>
        <ColStatus>Status</ColStatus>
      </TableRow>
      <NestTable
        onChange={onChange}
        items={data}
        maxDepth={1}
        style={{
          marginBottom: '60px',
        }}
        renderItem={({ item: post }) => {
          return (
            <Wrapper>
              <DragIcon />
              <ColTitle>{post.title}</ColTitle>
              <Actions>
                <div>
                  <ReactToggle
                    checked={post.status}
                    className={`react-toggle--medium h-margin-right--10`}
                    onChange={onChangeStatus(post)}
                    icons={false}
                  />
                  {post.status ? 'Ativa' : 'Inativa'}
                </div>
                <div>
                  <Dropdown isDropHover>
                    <Button size="medium" color="white" colorText="secondary">
                      Opções <MdExpandMore />
                    </Button>
                    <List>
                      <Item>
                        <button onClick={handleOpenPost(post)}>
                          Ver postagem
                        </button>
                      </Item>
                      <Item>
                        <button onClick={handleClickUpdate(post)}>
                          Editar
                        </button>
                      </Item>
                      <Item>
                        <button onClick={onDelete(post)}>Deletar</button>
                      </Item>
                    </List>
                  </Dropdown>
                </div>
              </Actions>
            </Wrapper>
          );
        }}
      />
    </WrapperAll>
  );
}

TablePosts.propTypes = {};
TablePosts.defaultProps = {
  data: [],
};

export default TablePosts;
