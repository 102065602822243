// import styles from './styles'
import FixedBar from 'components/FixedBar';
import Selecteds from './components/Selecteds';
import Button from 'components/Button';

export default function FixedBarComponent({
  params,
  properties,
  handleUncheckAll,
  handleClickRemoveSelected,
  onAddProperties,
}) {
  return (
    <FixedBar
      noContainer
      isVisible={!!properties.length > 0}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      style={{ left: '250px', background: '#42587E', zIndex: 1500 }}
    >
      <Selecteds properties={properties} />
      <FixedBar.spacer />
      <FixedBar.item>
        <button
          className="h-link h-margin-right--15"
          onClick={handleUncheckAll}
        >
          Desmarcar todos
        </button>
        <Button color="success" onClick={onAddProperties}>
          Adicionar imóveis
        </Button>
      </FixedBar.item>
    </FixedBar>
  );
}
