import React from 'react';
import PropTypes from 'prop-types';
// Components
import ItemNav from '../ItemNav';
import Dropdown, { Divider, Item, List } from 'components/Dropdown';

function ItemDropdown({
  open,
  href,
  active,
  position,
  label,
  to,
  exact,
  activeClassName,
  className,
  children,
  dropdownClassName,
  onClick,
  maxHeight = '650px',
}) {
  return (
    <Dropdown
      open={open}
      position={position}
      className={dropdownClassName}
      isDropHover
    >
      {(events) => (
        <>
          <ItemNav
            href={href}
            active={active}
            label={label}
            exact={exact}
            to={to}
            activeClassName={activeClassName}
            className={className}
            onClick={onClick}
          />
          <List>
            <div style={{ maxHeight, overflow: 'auto' }}>
              {typeof children === 'function' ? children(events) : children}
            </div>
          </List>
        </>
      )}
    </Dropdown>
  );
}

ItemDropdown.defaultProps = {
  active: null,
  position: 'center',
  label: null,
  exact: true,
  activeClassName: 'is-active',
  dropdownClassName: 'Dropdown--header Menu__item Menu__item--hover',
  className: '',
  children: null,
};

ItemDropdown.propTypes = {
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  position: PropTypes.string,
  label: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['primary', 'color']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  exact: PropTypes.bool,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  onClick: PropTypes.func,
};

export { List, Item, Divider };
export default ItemDropdown;
