import React, { Component, createRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, isDirty, reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
// Components
import FixedBar from 'components/FixedBar';
import { Code, Input, SelectMultiple } from 'components/Form';
import Button from 'components/Button';
import Container from 'components/Container';
// Helpers
import { parseSelect } from 'lib/formHelpers';

class ScriptsPagesForm extends Component {
  constructor(props) {
    super(props);
    this.editorRef = createRef();
  }

  componentDidMount() {
    if (this.props.isAnalytics) {
      this.editorRef.current.editor.focus();
    }
  }

  render() {
    const { handleSubmit, isSubmitting, isAnalytics } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Field
              xs={4}
              label="Nome"
              name="name"
              component={Input}
              disabled={isAnalytics}
            />
            <Field
              multi={false}
              xs={4}
              label="Local da publicação"
              name="trigger"
              component={SelectMultiple}
              options={[{ value: 'all-pages', label: 'Todas as páginas' }]}
              parse={parseSelect('value')}
            />
            <Field
              multi={false}
              xs={4}
              label="Posição"
              name="position"
              component={SelectMultiple}
              options={[
                { value: '1', label: 'Head' },
                { value: '2', label: 'Início do Body' },
                { value: '3', label: 'Fim do Body' },
              ]}
              parse={parseSelect('value')}
            />
          </Row>
          <Field
            focus={this.props.isAnalytics}
            refName={this.editorRef}
            mode="html"
            name="code"
            component={Code}
          />
        </Container>
        <FixedBar style={{ left: 242 }}>
          <Button
            to={`/site/config/scripts/show`}
            disabled={isSubmitting}
            color="white"
            colorText="secondary"
          >
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button disabled={isSubmitting} type="submit" color="success">
            Salvar
          </Button>
        </FixedBar>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  codeIsDirty: isDirty('ScriptsPagesForm')(state, 'script_code'),
});

export default compose(
  reduxForm({
    form: 'ScriptsPagesForm',
    enableReinitialize: true,
  }),
  connect(mapStateToProps)
)(ScriptsPagesForm);
