import React from 'react';
import { Wrapper } from '../../styles';
import Item from '../Item';

function Skeleton() {
  return (
    <Wrapper>
      <Item isLoading />
      <Item isLoading />
      <Item isLoading />
    </Wrapper>
  );
}

export default Skeleton;
