import React, { useMemo } from 'react';
import { MdCopyAll } from 'react-icons/all';
import Clipboard from 'react-clipboard.js';
import Alert from 'react-s-alert';
import isEmpty from 'lodash/isEmpty';

// import { Wrapper } from './styles';

function CopyDataPerson({ person }) {
  const handleSuccess = () => {
    Alert.success('Copiado para área de transferência (Ctrl+C)');
  };

  const getPhones = (phones) => {
    if (isEmpty(phones)) return null;

    return phones.reduce((acc, phone) => {
      acc = `${acc}\n${phone.number} ${
        phone.description ? phone.description : ''
      }`;

      return acc;
    }, '');
  };

  const clipBoardText = useMemo(() => {
    let text = person.name;
    const phones = getPhones(person.phones);

    if (phones) {
      text += phones;
    }

    if (person.email) {
      text += '\n' + person.email;
    }

    return text;
  }, [person]);

  return (
    <Clipboard data-clipboard-text={clipBoardText} onSuccess={handleSuccess}>
      <MdCopyAll
        size={16}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Clipboard>
  );
}

export default CopyDataPerson;
