import Button from 'components/Button';
import Container from 'components/Container';
import Nodata from 'components/Nodata';
import UploadButton from 'components/UploadButton';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { useProgress } from 'pages/EnterpriseStore/pages/Progress/contexts/ProgressContext';
import { useCallback, useMemo } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import houses from '../../houses.svg';
import CardPhoto from '../CardPhoto';
import { Actions, Header, ListItem, Wrapper } from './styles';

const Item = SortableElement(({ name, data }) => {
  return (
    <ListItem key={name}>
      <CardPhoto name={name} data={data} />
    </ListItem>
  );
});

const Content = SortableContainer(({ fields }) => (
  <Wrapper>
    {fields?.map((name, index, fields) => {
      const data = fields.get(index);
      return (
        <Item
          key={`${data.id}-${index}`}
          index={index}
          data={data}
          name={name}
        />
      );
    })}
  </Wrapper>
));

const PhotosProgress = ({ member, index, fields }) => {
  const { enterpriseId } = useEnterprise();
  const { handleUploadImages } = useProgress();
  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      try {
        fields.move(oldIndex, newIndex);
        // const currentImage = fields.get(newIndex);
        // move({
        //   buildingId: enterpriseId,
        //   imageId: currentImage.id,
        //   order: newIndex + 1
        // });
      } catch {
        return null;
      }
    },
    [enterpriseId]
  );

  const hasImages = useMemo(() => {
    return fields?.getAll()?.length > 0;
  }, [fields]);

  if (!hasImages)
    return (
      <Container className="h-margin-top--15">
        <Nodata
          image={houses}
          title=""
          text={
            <>
              Envie imagens do progresso da obra
              <br /> e mantenha seus clientes atualizados
            </>
          }
        >
          <UploadButton
            onDropAccepted={(files) => {
              handleUploadImages(files);
            }}
          >
            <Button color="success" className="h-margin-top--15">
              Enviar imagens
            </Button>
          </UploadButton>
        </Nodata>
      </Container>
    );

  return (
    <Container className="h-margin-top--15">
      <Header>
        <div>
          <h4>Fotos do progresso</h4>
          <p>Recomendado: 1920x1080px</p>
        </div>

        <Actions>
          <UploadButton
            onDropAccepted={(files) => {
              handleUploadImages(files);
            }}
          >
            <Button color="success">
              {hasImages ? 'Adicionar mais imagens' : 'Enviar imagens'}
            </Button>
          </UploadButton>
        </Actions>
      </Header>
      <div style={{ margin: '-15px' }}>
        <Content
          axis="xy"
          distance={1}
          fields={fields}
          onSortEnd={handleSortEnd}
        />
      </div>
    </Container>
  );
};

export default PhotosProgress;
