import api from 'services';
import { responseMessage } from 'lib/service-helpers';

export const getList = (params) => api.getList('settings/groups', params);
export const getOne = (groupId) => api.getOne('settings/groups', groupId);
export const create = (group) =>
  api
    .create('settings/groups', group)
    .then(responseMessage('Grupo adicionado'));

export const updateGroupUser = (userId, groupId) =>
  api
    .create(`users/${userId}`, { group_id: groupId })
    .then(responseMessage('Grupo do usuário alterado'));

export const update = (data) =>
  api.update('settings/groups', data).then(responseMessage('Grupo alterado'));

export const remove = (groupId) =>
  api
    .delete('settings/groups', groupId)
    .then(responseMessage('Grupo removido'));

export const transfer = (fromId, toId) =>
  api.create(`settings/groups/${fromId}/transfers/${toId}`);
