import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Components
import CardAction from 'components/CardAction';
import Button from 'components/Button';
// Assets
import registerDomain from 'assets/img/domains/register-domain.svg';
import addDomain from 'assets/img/domains/add-domain.svg';

const defaultProps = {
  handleClickRegisterDomain: null,
  handleClickOpenAddDomain: null
};

const propTypes = {
  handleClickRegisterDomain: PropTypes.func,
  handleClickOpenAddDomain: PropTypes.func
};

const DomainsPreRegister = ({
  handleClickRegisterDomain,
  handleClickOpenAddDomain
}) => (
  <Row>
    <Col xs={6}>
      <CardAction
        image={registerDomain}
        title={'Quero Registrar um Dominío'}
        text={
          'Ajudamos você a verificar a disponibilidade e registrar seu domínio .com.br'
        }
        onClick={() => handleClickRegisterDomain()}
      >
        <Button color="success">Registrar domínio</Button>
      </CardAction>
    </Col>
    <Col xs={6}>
      <CardAction
        image={addDomain}
        title={'Já possuo um domínio'}
        text={'Já possui um domínio e quer utilizá-lo conosco?'}
        onClick={() => handleClickOpenAddDomain()}
      >
        <Button color="success">Adicionar domínio</Button>
      </CardAction>
    </Col>
  </Row>
);

DomainsPreRegister.defaultProps = defaultProps;
DomainsPreRegister.propTypes = propTypes;

export default DomainsPreRegister;
