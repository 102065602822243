import api from './index';
import { STORAGE } from 'lib/HTTP';
import { getFormData } from '../lib/formHelpers';
import { responseMessage } from 'lib/service-helpers';

export const getOne = () => STORAGE.get('settings/watermarks');

export const create = params =>
  api.create('settings/watermarks', getFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export const update = data =>
  STORAGE.put('settings/watermarks', data).then(
    responseMessage(`Marca d'água atualizada`)
  );
export const remove = data =>
  STORAGE.delete('settings/watermarks', {
    data
  }).then(responseMessage(`Marca d'água removida`));
