import React from 'react';
import PropTypes from 'prop-types';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

class ViewsGraph extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        month: PropTypes.string.isRequired,
        hits: PropTypes.string.isRequired
      })
    ),
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isLoading: false
  };

  render() {
    const { data } = this.props;

    return (
      <div style={{ width: '100%', height: 300, position: 'relative' }}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Area
              name="Visitas"
              type="monotone"
              dataKey="hits"
              stroke="#8884d8"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default ViewsGraph;
