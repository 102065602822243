import { initialize } from 'redux-form';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import { createModule } from 'lib/reducer-helpers';
// Helpers
import { normalizeInitialValues, normalizeItem } from './helpers';
// Modules
import createDataReducer from '../../../modules/data';
import { closeModal, openModalPersonAdd, openModalProfile } from '../../../modules/modal';
// Services
import * as peopleService from 'services/people';
import * as condosService from 'services/condos';
import * as profileSearchService from 'services/profileSearch';

// Criação do mudulo
const createAction = createModule('modalProfile');

const {
  reducer: peopleReducer,
  actionCreators: peopleActions
} = createDataReducer('modalProfile/people');

const {
  reducer: condosReducer,
  actionCreators: condosActions
} = createDataReducer('modalProfile/condos');

// Action Types
const RECEIVE_VALUES = createAction('RECEIVE_VALUES');
const SET_VALUE = createAction('SET_VALUE');

const RECEIVE_DATA = createAction('RECEIVE_DATA');
const SET_KEEP_VALUES = createAction('SET_KEEP_VALUES');
const UNSET_KEEP_VALUES = createAction('UNSET_KEEP_VALUES');

// Selectors
export const peopleSelector = state => state.modalProfile.people.data;
export const condosSelector = state => state.modalProfile.condos.data;

export const dataSelector = state => state.modalProfile.formInfo.data;
export const valuesSelector = state => state.modalProfile.formInfo.values;

export const dataValuesSelector = createSelector(
  [valuesSelector, dataSelector],
  (values, data) => ({
    ...data,
    characteristics: normalizeItem({
      values,
      data,
      valueName: 'characteristics_id',
      dataName: 'characteristics'
    }),
    establishments: normalizeItem({
      values,
      data,
      valueName: 'establishments_id',
      dataName: 'establishments'
    }),
    condosCharacteristics: normalizeItem({
      values,
      data,
      valueName: 'condos_characteristics_id',
      dataName: 'condosCharacteristics'
    }),
    countries: normalizeItem({
      values,
      data,
      valueName: 'country_id',
      dataName: 'countries'
    }),
    states: normalizeItem({
      values,
      data,
      valueName: 'state_id',
      dataName: 'states'
    }),
    cities: normalizeItem({
      values,
      data,
      valueName: 'city_id',
      dataName: 'cities'
    }),
    neighborhoods: normalizeItem({
      values,
      data,
      valueName: 'neighborhoods_id',
      dataName: 'neighborhoods'
    })
  })
);

// Reducer
const initialState = {
  data: {},
  values: {},
  keepValues: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_DATA:
      return {
        ...state,
        data: action.payload
      };
    case RECEIVE_VALUES:
      return {
        ...state,
        values: action.payload
      };
    case SET_VALUE:
      return {
        ...state,
        values: {
          ...action.payload,
          ...state.values
        }
      };
    case SET_KEEP_VALUES:
      return {
        ...state,
        keepValues: true
      };
    case UNSET_KEEP_VALUES:
      return {
        ...state,
        keepValues: false
      };
    default:
      return state;
  }
};

// Action Creators

// Thunks
export const receiveData = data => dispatch =>
  dispatch({
    type: RECEIVE_DATA,
    payload: data
  });

export const receiveValues = data => dispatch =>
  dispatch({
    type: RECEIVE_VALUES,
    payload: normalizeInitialValues(data)
  });

export const setValue = data => dispatch =>
  dispatch({
    type: SET_VALUE,
    payload: data
  });

export const setKeepValues = () => dispatch =>
  dispatch({
    type: SET_KEEP_VALUES
  });

export const unsetKeepValues = () => dispatch =>
  dispatch({
    type: UNSET_KEEP_VALUES
  });

/**
 * Busca todas as clientes
 * @param params
 * @return {Promise}
 */
export const fetchPeople = params => dispatch => {
  dispatch(peopleActions.request());

  return peopleService
    .getAll({
      filter: {
        by_user_id: localStorage.getItem('current-user').id
      },
      ...params
    })
    .then(({ data: people }) => {
      dispatch(peopleActions.receive(people));
      return people;
    });
};

export const fetchCondos = params => dispatch => {
  dispatch(condosActions.request());

  return condosService.getList(params).then(({ data: condos }) => {
    dispatch(condosActions.receive(condos));
    return condos;
  });
};

export const reinitializeForm = values => dispatch =>
  dispatch(initialize('ProfileForm', values));

/**
 * Busca os dados iniciais da modal
 * @return {Function}
 */
export const getInitialData = () => dispatch => {
  try {
    // Busca todas as clientes
    fetchPeople()(dispatch);
    fetchCondos()(dispatch);
  } catch (err) {
    throw err;
  }
};

export const handleNewClient = ({ name }) => dispatch => {
  setKeepValues()(dispatch);

  // abre modal de cliente
  openModalPersonAdd({ name }, ({ id }) => {
    unsetKeepValues()(dispatch);
    setValue({ people_id: id })(dispatch);

    //
    openModalProfile({ _keepValues: true })(dispatch);

    // atualiza a lista de clientes
    fetchPeople()(dispatch);
  })(dispatch);
};

/**
 * Lida com o submit do formulário de cadastrar novo perfil
 * @param values - todos os valores do formulário
 * @return {Function}
 */
export const handleFormSubmit = ({ people_id, ...values }) => dispatch =>
  profileSearchService.create(people_id, values).then(res => {
    dispatch(closeModal());
    return res;
  });

export default combineReducers({
  people: peopleReducer,
  condos: condosReducer,
  formInfo: reducer
});
