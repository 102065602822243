import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
// Components
import Button from '../../components/Button';

import logo from 'assets/img/logo-login.png';

const defaultProps = {};

const propTypes = {
  location: PropTypes.shape({
    pathName: PropTypes.string,
  }).isRequired,
};

const LoginHeader = (props) => {
  const { location } = props;
  const { pathname } = location;

  return (
    <header className="LoginHeader h-flex h-flex--center-center">
      <Link to="/">
        <img
          src={logo}
          alt="Logo sistema"
          width={108}
          className="LoginHeader__brand"
        />
      </Link>
      <span className="h-flex__cell--grow" />
      <div className="h-flex LoginHeader__actions">
        {pathname.includes('signup') || pathname.includes('device-logout') ? (
          <>
            <span className="h-color--primary-light h-margin-right--15">
              Já possui uma conta?
            </span>
            <Button to="/" color="white" colorText="secondary">
              Faça Login
            </Button>
          </>
        ) : (
          <>
            <span className="h-color--primary-light h-margin-right--15">
              Não possui uma conta?
            </span>
            <Button to="/signup" color="white" colorText="secondary">
              Crie Agora
            </Button>
          </>
        )}
      </div>
    </header>
  );
};

LoginHeader.defaultProps = defaultProps;
LoginHeader.propTypes = propTypes;

const mapStateToProps = (state) => ({
  login: state.login,
});

//export default withRouter(LoginHeader);
export default compose(withRouter, connect(mapStateToProps))(LoginHeader);
