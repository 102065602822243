export default (values) => {
  const errors = {};

  // console.log('values: ', values);
  //
  // console.log('values?.groups_id: ', values?.groups_id);
  //
  // if (!values?.groups_id) {
  //   errors.groups_id = ['Campo obrigatório'];
  // }

  return errors;
};
