import styled from 'styled-components';

export const Wrapper = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  & + & {
    margin-top: 20px;
  }
`;

export const Title = styled.h2`
  font-size: 16px;
  height: 25px;
  line-height: 25px;
`;

export const Cards = styled.div`
  border: 1px solid #d2dbe3;
  border-radius: 4px;
  padding: 15px;
  background: #fff;
`;

export const Card = styled.div`
  display: flex;
  justify-content: ${(p) =>
    p.position === 'center' ? 'center' : 'space-between'};
  align-items: center;

  text-align: ${(p) => p.position === 'center' && 'center'};

  font-size: 12px;

  padding-bottom: 10px;

  .define-as-primary {
    visibility: hidden;
  }

  &:hover {
    .define-as-primary {
      visibility: visible;
    }
  }

  .h-link {
    font-size: 12px;
  }

  .icon-brand {
    width: 22px;
    height: 22px;
  }

  .primary-card {
    display: flex;
    gap: 3px;
    align-items: center;
    width: 75px;
  }
`;
