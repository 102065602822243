import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Alert from 'react-s-alert';
import GLogin from 'components/GLogin';
import Button from 'components/Button';
// Service
import * as googleService from 'services/google';
// Lib
import { setAnalyticsAuthToken } from 'lib/analytics';
// Modules
import {
  Actions as GoogleActions,
  updateGoogleAnalytics
} from 'modules/google';

const propTypes = {
  autoLoad: PropTypes.bool,
  render: PropTypes.func,
  onSuccess: PropTypes.func
};

const defaultProps = {
  autoLoad: null,
  render: ({ onClick }) => <Button onClick={onClick}>Integrar</Button>,
  onSuccess: null
};

function GoogleAnalyticsLogin({ autoLoad, onSuccess, render }) {
  const dispatch = useDispatch();

  const handleSuccess = async ({ code }) => {
    if (onSuccess) onSuccess(code);

    const { refresh_token, access_token } = await googleService.getTokens(code);

    await updateGoogleAnalytics({ refresh_token, access_token })(dispatch);
    setAnalyticsAuthToken(access_token);

    // Seta os tokens
    dispatch(GoogleActions.setGoogleRefreshToken(refresh_token));
    dispatch(GoogleActions.setGoogleAccessToken(refresh_token));
  };

  const handleFail = (...rest) => {
    Alert.success('Problema ao se conectar');
  };

  return (
    <GLogin
      autoLoad={autoLoad}
      scope="profile email https://www.googleapis.com/auth/analytics.readonly"
      onSuccess={handleSuccess}
      onFailure={handleFail}
      render={render}
    />
  );
}

GoogleAnalyticsLogin.propTypes = propTypes;
GoogleAnalyticsLogin.defaultProps = defaultProps;

export default GoogleAnalyticsLogin;
