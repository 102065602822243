import { imagesChangeSize } from 'lib/image-helpers';
import { Actions, Selectors } from 'modules/modalImages';
import Lightbox from 'react-images';
import { useDispatch, useSelector } from 'react-redux';
// import { Wrapper } from './styles';

const style = {
  lightBox: {
    container: {
      background: 'rgba(62, 88, 124, 0.85)',
    },
    arrow: {
      background: 'none',
    },
  },
};

function Carousel({ images, isOpen, onClose }) {
  const dispatch = useDispatch();
  const currentImage = useSelector(Selectors.currentImage);

  const setCurrentImage = (index) => {
    dispatch(Actions.setCurrentImage(index));
  };

  const goToNext = () => {
    setCurrentImage(currentImage + 1);
  };

  const goToPrev = () => {
    setCurrentImage(currentImage - 1);
  };

  const handleClickThumbnail = (index) => {
    setCurrentImage(index);
  };

  const close = () => {
    if (onClose) onClose();
  };

  return (
    <Lightbox
      imageCountSeparator=" de "
      currentImage={currentImage}
      onClickThumbnail={handleClickThumbnail}
      onClickNext={goToNext}
      onClickPrev={goToPrev}
      images={imagesChangeSize(images, 'original').map((image) => ({
        ...image,
        src: image.file_url,
      }))}
      isOpen={isOpen}
      onClose={close}
      backdropClosesModal
      showThumbnails
      theme={style.lightBox}
    />
  );
}

export default Carousel;
