import api from './index';
import { responseMessage } from 'lib/service-helpers';

export const getList = (params) =>
  api.getList('establishments', {
    ...params,
    sort: 'by_title',
  });

export const getOne = (id) => api.getOne('establishments', id);

export const getAllCustom = (params) =>
  api.getList('establishments', {
    ...params,
    with_defaults: false,
    count: 'properties,condominiuns',
    sort: 'by_title',
  });

export const create = (data) => api.create('establishments', data);
export const update = (data) =>
  api
    .update('establishments', data)
    .then(responseMessage('Estabelecimento alterado!'));

export const transfer = (fromId, toId) =>
  api.create(`establishments/${fromId}/transfers/${toId}`);

export const remove = (id) =>
  api
    .delete('establishments', id)
    .then(responseMessage('Estabelecimento removido'));
