import React, { useEffect, useState } from 'react';
// Components
import ListOptions from 'components/ListOptions';
import { FieldBool } from 'components/Form';

const propTypes = {};
const defaultProps = {};

const FormAddress = ({ type, change }) => {
  const [hasFloor, setFloor] = useState(false);
  const [hasApartmentNumber, setApartmentNumber] = useState(false);

  useEffect(() => {
    type.information_fields.forEach((info) => {
      if (info.name === 'apartment_number') setApartmentNumber(true);
      if (info.name === 'floor') setFloor(true);
    });
  }, []);

  return (
    <ListOptions
      style={{
        width: 'calc(100% + 40px)',
        margin: '-20px 0 0 -20px',
        borderBottom: '1px solid #DEDEDE',
      }}
    >
      <ListOptions.item
        title="Mostrar Logradouro?"
        renderOptions={() => (
          <FieldBool
            name="is_street_shown"
            onChange={(e, value) => {
              if (!!value) {
                change('is_neighborhood_shown', true);
              }
            }}
          />
        )}
      />
      <ListOptions.item
        title="Mostrar Bairro?"
        renderOptions={() => (
          <FieldBool
            name="is_neighborhood_shown"
            onChange={(e, value) => {
              if (!value) {
                change('is_street_shown', false);
                change('is_condominium_shown', false);
              }
            }}
          />
        )}
      />
      <ListOptions.item
        title="Mostrar Número e Complemento?"
        renderOptions={() => <FieldBool name="is_complement_shown" />}
      />
      <ListOptions.item
        title="Mostrar nome do condomínio?"
        renderOptions={() => (
          <FieldBool
            name="is_condominium_shown"
            onChange={(e, value) => {
              if (!!value) {
                change('is_neighborhood_shown', true);
              }
            }}
          />
        )}
      />
      {hasFloor && (
        <ListOptions.item
          title={
            type.title === 'Sala'
              ? 'Mostrar andar da sala'
              : 'Mostrar andar do apartamento'
          }
          renderOptions={() => <FieldBool name="is_floor_shown" />}
        />
      )}
      {hasApartmentNumber && (
        <ListOptions.item
          title="Mostrar o número da unidade"
          renderOptions={() => <FieldBool name="is_apartment_number_shown" />}
        />
      )}
    </ListOptions>
  );
};

FormAddress.propTypes = propTypes;
FormAddress.defaultProps = defaultProps;

export default FormAddress;
