import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Alert from 'react-s-alert';
import { connect } from 'react-redux';
// Services
import * as userService from 'services/user';
// Components
import { ModalTemplate } from 'components/Modal';
import Loading from 'components/Loading';
import FormCreci from './components/FormCreci';
// Modules
import { handleUploadDocuments } from 'modules/users';

class ModalCreci extends Component {
  static defaultProps = {
    userId: null,
  };

  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  state = {
    user: { id: null },
    creci: null,
    document: null,
    isLoading: true,
  };

  async componentDidMount() {
    const user = await this.fetchUser();

    this.setState({
      creci: user.creci_url || null,
      document: user.document_url || null,
      document_name: user.document_name || null,
      creci_name: user.creci_name || null,
    });
  }

  fetchUser = async () => {
    const { userId } = this.props;

    this.setState({ isLoading: true });

    try {
      // Busca o usuário
      const { data: user } = await userService.getOne(userId, {
        include: 'group',
      });
      this.setState({ user });
      return user;
    } finally {
      this.setState({ isLoading: false });
    }
  };

  get initialValues() {
    const { creci, document, document_name, creci_name } = this.state;

    return {
      creci_url: creci,
      document_url: document,
      document_name,
      creci_name,
    };
  }

  handleLoading = (bool) => {
    this.setState({ isLoading: bool });
  };

  onSubmit = async ({ image_document, image_creci }) => {
    const { user } = this.state;
    const { handleUploadDocuments } = this.props;

    this.handleLoading(true);

    try {
      if (image_document) {
        await handleUploadDocuments(user, {
          image: image_document,
          field: 'document_url',
        });
      }

      if (image_creci) {
        await handleUploadDocuments(user, {
          image: image_creci,
          field: 'creci_url',
        });
      }
    } catch {
      Alert.success('Erro ao salvar o CRECI');
    } finally {
      this.handleLoading(false);
    }
  };

  onSubmitSuccess = () => {
    this.props.handleClose();
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;

    const { isLoading } = this.state;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate title={'Verificar CRECI'} handleClose={handleClose}>
          <Loading isFullComponent isVisible={isLoading} />
          <FormCreci
            initialValues={this.initialValues}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  handleUploadDocuments,
};

export default connect(null, mapDispatchToProps)(ModalCreci);
