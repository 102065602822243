import React from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
  className: 'Dropdown__item',
  children: ''
};
const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const Item = props => <li {...props}>{props.children}</li>;

Item.defaultProps = defaultProps;
Item.propTypes = propTypes;

export default Item;
