import React, { useContext, useCallback, useMemo, useEffect } from 'react';
import { ContentList } from '../List/styles';
import {
  Wrapper,
  Card,
  Image,
  CardContent,
  Title,
  Price,
  Address,
} from './styles';
import { SearchBarContext } from 'components/SearchBar/context';
import { useDispatch } from 'react-redux';
import { openModalProperty } from 'modules/modal';
import { useInView } from 'react-intersection-observer';

function PropertiesResults({ data }) {
  const dispatch = useDispatch();
  const { search, loadMoreByKey } = useContext(SearchBarContext);

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 1,
  });

  const total = useMemo(() => {
    return data?.length;
  }, [data]);

  const openProperty = useCallback(
    (id) => () => {
      dispatch(openModalProperty({ property: { id } }));
    },
    []
  );

  useEffect(() => {
    if (inView) {
      loadMoreByKey('properties');
    }
  }, [inView]);

  if (!data || data.length <= 0) return null;

  return (
    <ContentList>
      <header>
        <h1>Imóveis</h1>
      </header>
      <Wrapper>
        {data.map(
          (
            {
              id,
              reference,
              cover_image,
              title_formatted,
              address_formatted,
              calculated_price,
              transaction,
              street_address,
              complement_address,
              informations,
              type,
            },
            i
          ) => {
            let _props = {};

            if (i + 1 === total) {
              _props = {
                ref,
              };
            }

            return (
              <Card
                key={'PROPERTY-RESULT-' + id}
                {..._props}
                onClick={openProperty(id)}
              >
                <Image src={cover_image?.file_url} />
                <CardContent>
                  <Title
                    search={search}
                    text={`${title_formatted} Ref: ${reference}`}
                  />
                  <Price>
                    {calculated_price} -{' '}
                    {transaction === 1 ? 'Venda' : 'Locação'}
                  </Price>
                  <Address search={search}>{address_formatted}</Address>
                  {informations?.apartment_number?.value && (
                    <p>
                      {type.title} {informations?.apartment_number?.value}
                    </p>
                  )}
                  <Address
                    search={search}
                    text={`${street_address || ''} ${complement_address || ''}`}
                  />
                </CardContent>
              </Card>
            );
          }
        )}
      </Wrapper>
    </ContentList>
  );
}

export default PropertiesResults;
