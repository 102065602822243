import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AliasShape } from 'constants/shapes';
// Modules
import { aliasSelector, fetchAlias, removeAlias } from './module';
// Components
import Container from 'components/Container';
import TableAlias from './components/TableAlias';

class Alias extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(AliasShape),
    isFetching: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isFetching: null
  };

  componentDidMount() {
    this.props.fetchAlias();
  }

  onRemove = async address => {
    this.props.removeAlias(address);
  };

  render() {
    if (this.props.data.length === 0) return null;

    return (
      <Container padding className="h-margin-top--15">
        <TableAlias
          data={this.props.data}
          isFetching={this.props.isFetching}
          handleRemove={this.onRemove}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  data: aliasSelector.getAll(state),
  isFetching: aliasSelector.isFetching(state)
});

const mapDispatchToProps = {
  fetchAlias,
  removeAlias
};

export default connect(mapStateToProps, mapDispatchToProps)(Alias);
