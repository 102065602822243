export default values => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (!values.password) {
    errors.password = REQUIRED_MSG;
  } else if (values.password.length < 6) {
    errors.password = 'Senha deve possuir mais do que 6 caracteres';
  }

  if (!values.password_confirm) {
    errors.password_confirm = REQUIRED_MSG;
  }

  if (values.password !== values.password_confirm) {
    errors.password_confirm = 'Senhas não conferem';
  }

  return errors;
};
