import React, { createContext, useContext, useMemo, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

// Components
import MainTitle from 'components/MainTitle';
import ActionBar from './components/ActionBar';
import Wrapper from 'components/Wrapper';
import PortalPropertiesFilter from 'components/PortalPropertiesFilter';
import ListProperties from './components/ListProperties';
import TextLoading from 'components/TextLoading';

// Services
import * as propertiesService from 'services/properties';
import * as portalsService from 'services/settings/portals';

// Hooks
import { useRequest } from 'hooks/useRequest';
import { usePropertiesSelecteds } from './hooks/usePropertiesSelecteds';
import Pagination from 'components/Pagination';

export const PropertiesPortalContext = createContext({
  portal: null,
  unselectAll: null,
  portalOption: null,
  selecteds: [],
  updatedSelecteds: [],
  totalSelecteds: [],
  properties: [],
  propertiesId: [],
  toggle: null,
  isFetchingPortal: null,
  formValues: null,
  meta: null
});

const PropertiesPortal = () => {
  const [formValues, setFormValues] = useState({
    transaction: 1
  });
  const { portalRealEstateId, portalOptionId } = useParams();
  const {
    selecteds,
    toggle,
    totalSelecteds,
    unselectAll,
    updatedSelecteds
  } = usePropertiesSelecteds({
    portalRealEstateId,
    portalOptionId
  });

  const { data: portal, isFetching: isFetchingPortal } = useRequest({
    request: portalsService.getOneRelation,
    params: portalRealEstateId,
    initialState: {}
  });

  // Pega os dados dos imóveis
  const { data, fetchData, meta } = useRequest({
    request: propertiesService.getListPropertyPortals,
    params: {
      limit: 10,
      offset: 1,
      filter: formValues
    }
  });

  // Pega o portalOption com base o id que está na url
  const portalOption = useMemo(() => {
    return portal?.options?.find(portal => portal.id === portalOptionId);
  }, [portalOptionId, portal]);

  // Pega os imóveis adicionado se ele está selecionado
  const properties = useMemo(() => {
    return data?.map(property => {
      if (selecteds.includes(property.id)) {
        return {
          ...property,
          isChecked: true
        };
      }

      return property;
    });
  }, [selecteds, data]);

  // Pega todos os id dos imóveis
  const propertiesId = useMemo(() => {
    return data?.map(({ id }) => id);
  }, [data]);

  const handlePageChange = useCallback(
    ({ selected }) => {
      fetchData({
        filter: formValues,
        limit: 10,
        offset: selected + 1
      });
    },
    [formValues]
  );

  return (
    <PropertiesPortalContext.Provider
      value={{
        selecteds,
        unselectAll,
        updatedSelecteds,
        properties: properties,
        propertiesId,
        toggle,
        totalSelecteds,
        portal,
        portalOption,
        isFetchingPortal,
        formValues,
        meta
      }}
    >
      <Wrapper full>
        <Helmet>
          <title>Portal</title>
        </Helmet>

        <PortalPropertiesFilter
          portalRealEstateId={portal?.id}
          form="PropertiesPortalForm"
          initialValues={{
            transaction: 1
          }}
          onSubmit={values => {
            setFormValues(values);

            fetchData({
              filter: values,
              offset: 1,
              limit: 10
            });
          }}
        />
        <Wrapper.inner>
          <MainTitle
            title={
              isFetchingPortal ? (
                <TextLoading width={200} />
              ) : (
                `Selecionando imóveis para '${portalOption?.name}' do ${portal?.portal?.name}`
              )
            }
          />
          <ListProperties />
          <Pagination
            pagination={meta?.pagination}
            onPageChange={handlePageChange}
          />
          <ActionBar />
        </Wrapper.inner>
      </Wrapper>
    </PropertiesPortalContext.Provider>
  );
};

export const usePropertiesPortal = () => useContext(PropertiesPortalContext);

export default PropertiesPortal;
