import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { MdInfo } from 'react-icons/md';
import { Row } from 'react-flexbox-grid';
import { Field, FieldArray } from 'redux-form';
// Component
import FormBox from 'components/FormBox';
import FieldPhones from '../FieldPhones';
import Button from 'components/Button';
import { FieldBool, Input, Select } from 'components/Form';
// Containers
import OfficeLocationFields from '../../containers/OfficeLocationFields';
// Helpers
import { normalizeCnpj, normalizeCpf } from 'lib/formHelpers';
// Constants
import { TYPE_PERSONS } from 'constants/options';
import ListOptions from 'components/ListOptions';

const countryIdByIndex = (index, data) => {
  try {
    return data[index].country_id;
  } catch {
    return '';
  }
};

const stateIdByIndex = (index, data) => {
  try {
    return data[index].state_id;
  } catch {
    return '';
  }
};

const cityIdByIndex = (index, data) => {
  try {
    return data[index].city_id;
  } catch {
    return '';
  }
};

const ListWrapper = styled.div`
  .ListOptions__wrapper {
    width: calc(100% + 60px);
    margin-left: -30px;
    border-top: 1px solid #e1e6ec;
    border-bottom: 1px solid #e1e6ec;
    margin-top: 15px;
  }

  .ListOptions__item {
    padding: 15px 30px;
  }
`;

class FieldOffices extends React.Component {
  static isLegalPerson(currentField) {
    return currentField.type === '2' || currentField.type === 2;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    ReactTooltip.rebuild();
  }

  render() {
    const { fields, handleMainPhone, change, offices } = this.props;

    // Verifica se tem algum Field se não tiver nenhum
    if (!fields) return null;

    return fields.map((office, index) => {
      const currentField = fields.get(index);
      const isLegalPerson = FieldOffices.isLegalPerson(currentField);

      return (
        <FormBox title="Dados de contato principais">
          <Row>
            <Field
              xs={3}
              required
              label="Nome fantasia"
              name={`${office}.name`}
              component={Input}
              placeholder="Nome fantasia"
            />
            <Field
              xs={3}
              label="E-mail principal para contato"
              name={`${office}.email`}
              component={Input}
              placeholder="E-mail para contato"
            />
            <Field
              xs={3}
              canRemoveSelf={false}
              buttonTemplate
              name={`${office}.type`}
              label="Tipo de pessoa"
              component={Select}
              options={TYPE_PERSONS}
              placeholder={null}
              onChange={() => {
                change(`${office}.cpf_cnpj`, null);
              }}
            />
            <Field
              xs={3}
              disabled={!currentField.type}
              name={`${office}.cpf_cnpj`}
              label={
                <>
                  {isLegalPerson ? 'CNPJ' : 'CPF'}{' '}
                  <span
                    data-tip="Emissão de boleto e NF"
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <MdInfo />
                  </span>
                </>
              }
              placeholder={isLegalPerson ? 'Digite o CNPJ' : 'Digite o CPF'}
              component={Input}
              normalize={isLegalPerson ? normalizeCnpj : normalizeCpf}
            />
          </Row>
          <OfficeLocationFields
            name={office}
            countryId={countryIdByIndex(index, offices)}
            stateId={stateIdByIndex(index, offices)}
            cityId={cityIdByIndex(index, offices)}
            change={change}
          />
          <Row>
            <Field
              xs={6}
              label="Logradouro"
              name={`${office}.address_street`}
              component={Input}
              placeholder="Logradouro"
            />
            <Field
              xs={3}
              label="Número"
              name={`${office}.address_number`}
              component={Input}
              placeholder="Número"
            />
            <Field
              xs={3}
              label="Complemento"
              name={`${office}.address_complement`}
              component={Input}
              placeholder="Complemento"
            />
          </Row>
          <FieldArray
            change={change}
            indexOffice={index}
            name={`${office}.phones`}
            handleMainPhone={handleMainPhone}
            component={FieldPhones}
          />
          <ListWrapper>
            <ListOptions>
              <ListOptions.item
                title="Mostrar endereço no site?"
                text="Mostrar este endereço na página de contato e no rodapé do seu site"
                renderOptions={() => (
                  <FieldBool name={`${office}.is_address_shown`} />
                )}
              />
              <ListOptions.item
                title={`Mostrar ${isLegalPerson ? 'CNPJ' : 'CPF'} no site?`}
                text={`Mostrar este ${
                  isLegalPerson ? 'CNPJ' : 'CPF'
                } no rodapé do seu site`}
                renderOptions={() => (
                  <FieldBool name={`${office}.is_cpf_cnpj_shown`} />
                )}
              />
            </ListOptions>
          </ListWrapper>
          {currentField.is_primary ? (
            <Button
              className="h-margin-top--15"
              color="secondary"
              size="medium"
              onClick={() => {
                fields.push({
                  type: '1',
                  is_address_shown: false
                });
              }}
            >
              Adicionar outro endereço
            </Button>
          ) : (
            <Button
              className="h-margin-top--15"
              color="danger"
              size="medium"
              onClick={() => {
                fields.remove(index);
              }}
            >
              Remover endereço
            </Button>
          )}
        </FormBox>
      );
    });
  }
}

export default FieldOffices;
