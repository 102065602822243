import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 20px 5px;
  border-radius: 8px;
  flex-direction: column;
  background-color: #f9f9f9;
  border-left: 5px solid #0063c0;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #0063c0;
`;

const Content = styled.div``;

function Box({ title, children, style }) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Content style={style}>{children}</Content>
    </Wrapper>
  );
}

export default Box;
