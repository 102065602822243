import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-icons/md';

const defaultProps = {
  name: 'MdImage'
};

const propTypes = {
  name: PropTypes.string
};

const MaterialIcon = ({ name, ...props }) => {
  try {
    if (typeof Icons[name] !== 'function') throw new Error('Icone não existe');
    return React.createElement(Icons[name], props);
  } catch {
    return null;
  }
};

MaterialIcon.defaultProps = defaultProps;
MaterialIcon.propTypes = propTypes;

export default MaterialIcon;
