import {
  Content,
  Inner,
  Table as TableWrapper,
  Title,
  TrItem,
  TrMain,
  Wrapper,
} from './styles';

export const Table = {
  Wrapper: TableWrapper,
  Tr: TrMain,
  TrItem,
};

export const Box = {
  Wrapper,
  Inner,
  Title,
  Content,
  Table,
};
