import styled from 'styled-components';
import { MdDragIndicator } from 'react-icons/md';

export const WrapperAll = styled.div`
  margin-bottom: 40px;

  td:first-child {
    padding-left: 15px !important;
  }

  td:last-child {
    padding-right: 15px !important;
  }
`;

export const DragIcon = styled(MdDragIndicator)`
  margin-right: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #dedede;

  .nestable-list .nestable-list .nestable-item & {
    border-left: 1px solid #dedede;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-left: 15px;
  }
`;

const Col = styled.div``;

export const TableRow = styled.div`
  display: flex;
  padding: 14px 10px;
  background-color: #fff;

  ${Col} {
    font-weight: 600;
  }
`;

export const ColTitle = styled(Col)`
  flex-grow: 1;
`;

export const ColStatus = styled(Col)`
  flex-shrink: 0;
  min-width: 200px;
`;
