import React, { useCallback } from 'react';

// Components
import Rating from './components/Rating';

// Modules
import { receiveUser } from 'modules/login';

// Services
import * as usersService from 'services/user';

// Styles
import { Wrapper, Content, TextLeft, TextRight, Btn } from './styles';
import { MdClose } from 'react-icons/all';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDispatch } from 'react-redux';

const SatisfactionBar = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const handleClose = useCallback(() => {
    dispatch(
      receiveUser({
        ...currentUser,
        should_ask_satisfaction: false,
      })
    );

    usersService.postponeSatisfaction();
  }, [currentUser, dispatch]);

  // Verifica se pode mostrar a barra de satisfação
  if (!currentUser?.should_ask_satisfaction) return false;

  return (
    <Wrapper>
      <Content>
        <TextLeft>
          O Quanto você está
          <br />
          gostando do Tecimob?
        </TextLeft>
        <Rating />
        <TextRight>
          Sua opinião é importante
          <br />
          para nossa evolução.
        </TextRight>
        <Btn onClick={handleClose}>
          <MdClose />
          Fechar
        </Btn>
      </Content>
    </Wrapper>
  );
};

export default SatisfactionBar;
