import React, { createContext, useContext, useCallback, useEffect } from 'react';
import { useRequest } from 'hooks/useRequest';

// Service
import * as unitsService from 'services/buildings/units';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import useReduxForm from 'hooks/useReduxForm';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';

export const UnityContext = createContext({
  data: [],
  isFetching: null,
  fetchData: null
});

export const UnityProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { initialize } = useReduxForm();
  const { enterpriseId } = useEnterprise();

  const { data, isFetching, setData, fetchData } = useRequest({
    request: unitsService.get,
    params: enterpriseId,
    isFetching: true
  });

  const remove = useCallback(
    propertyId => () => {
      unitsService.remove(enterpriseId, propertyId);

      setData(_data => {
        const properties = _data?.properties?.filter(
          ({ id }) => id !== propertyId
        );

        return {
          ..._data,
          properties
        };
      });
    },
    [data]
  );

  const removeAll = useCallback(() => {
    dispatch(
      openConfirmation({
        size: 290,
        title: 'Deseja remover todas as unidades?',
        submitButtonText: 'Remover todas',
        request: () => {
          unitsService.removeAll(enterpriseId);
          setData([]);
        }
      })
    );
  }, [enterpriseId]);

  useEffect(() => {
    dispatch(initialize({ id: enterpriseId, ...data }));
  }, [data]);

  return (
    <UnityContext.Provider
      value={{ data, isFetching, fetchData, remove, removeAll }}
    >
      {children}
    </UnityContext.Provider>
  );
};

export const useUnity = () => useContext(UnityContext);
