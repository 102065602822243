import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Field, formValueSelector, reduxForm } from 'redux-form';
// Components
import { Input, Select } from 'components/Form';
import Button from 'components/Button';
import Loading from 'components/Loading';
// Constants
import { TYPE_PERSONS } from 'constants/options';
// Lib
import { normalizeCnpj, normalizeCpf } from 'lib/formHelpers';

import validate from './validate';
import {
  CityField,
  CountryField,
  NeighborhoodField,
  StateField,
} from 'containers/LocationFields';
import FieldCep from 'containers/FieldCep';

const defaultProps = {
  isContractMode: null,
  isLoading: null,
  handleSubmit: null,
  handleClose: null,
};

const propTypes = {
  isContractMode: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

class FormBankSlip extends React.Component {
  constructor(props) {
    super(props);

    this.cpfCnpj = React.createRef();
  }

  // Pega o id do país
  get countryId() {
    return this.getIdField('country_id');
  }

  // Pega o id do estado
  get stateId() {
    return this.getIdField('state_id');
  }

  // Pega o id da cidade
  get cityId() {
    return this.getIdField('city_id');
  }

  // Pega o id do bairro
  get neighborhoodId() {
    return this.getIdField('neighborhood_id');
  }

  /**
   * Pega id de um campo em específico
   * @param fieldName
   */
  getIdField(fieldName) {
    const { location } = this.props;
    return location[fieldName];
  }

  get isNormalPerson() {
    const { type } = this.props;
    return type === 1 || type === '1';
  }

  get cpfCnpjLabel() {
    if (this.isNormalPerson) return 'CPF';
    return 'CNPJ';
  }

  get normalizeCpfCnpj() {
    return this.isNormalPerson ? normalizeCpf : normalizeCnpj;
  }

  handleChangeType = () => {
    const { change } = this.props;
    change('cpf_cnpj', null);

    this.cpfCnpj.current.focus();
  };

  render() {
    const { handleSubmit, isContractMode, isLoading, change } = this.props;
    const countryId = this.countryId;
    const stateId = this.stateId;
    const cityId = this.cityId;

    return (
      <form onSubmit={handleSubmit}>
        <Loading isVisible={isLoading} isFullComponent position={'center'} />
        <Row>
          <Field
            xs={12}
            label="Nome que deve sair no boleto"
            name="name"
            component={Input}
          />
          <Field
            buttonTemplate
            xs={5}
            label="Cliente"
            name="type"
            options={TYPE_PERSONS}
            component={Select}
            onChange={this.handleChangeType}
          />
          <Field
            refName={this.cpfCnpj}
            xs={7}
            label={this.cpfCnpjLabel}
            name="cpf_cnpj"
            component={Input}
            normalize={this.normalizeCpfCnpj}
          />
          <FieldCep
            xs={4}
            name="zipcode"
            onChangeLocation={(d) => {
              if (d?.neighborhood) change('neighborhood_id', d.neighborhood.id);
              if (d?.city) change('city_id', d.city.id);
              if (d?.state) change('state_id', d.state.id);
              if (d?.country) change('country_id', d.country.id);
              if (d?.street_address) change('street', d.street_address);
            }}
          />
          <Field xs={5} label="Logradouro" name="street" component={Input} />
          <Field xs={3} label="Número" name="street_number" component={Input} />
          <CountryField
            canUnregister={false}
            xs={4}
            label="País"
            name="country_id"
            stateName="state_id"
            cityName="city_id"
            neighborhoodName="neighborhood_id"
          />
          <StateField
            canUnregister={false}
            xs={4}
            label="UF"
            name="state_id"
            countryId={countryId}
            cityName="city_id"
            neighborhoodName="neighborhood_id"
          />
          <CityField
            canUnregister={false}
            xs={4}
            name="city_id"
            stateId={stateId}
            neighborhoodName="neighborhood_id"
          />
          <NeighborhoodField
            canUnregister={false}
            canClearValues
            multi={false}
            xs={12}
            label="Bairro"
            name="neighborhood_id"
            cityId={cityId}
          />

          <Col xs={12}>
            <Button type="submit" block color="success">
              {isContractMode ? 'Finalizar pagamento' : 'Salvar dados'}
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

FormBankSlip.defaultProps = defaultProps;
FormBankSlip.propTypes = propTypes;

const selector = formValueSelector('FormBankSlip');

const mapStateToProps = (state) => ({
  type: selector(state, 'type'),
  location: selector(
    state,
    'country_id',
    'state_id',
    'city_id',
    'neighborhood_id'
  ),
});

export default compose(
  reduxForm({
    form: 'FormBankSlip',
    validate,
    enableReinitialize: true,
  }),
  connect(mapStateToProps)
)(FormBankSlip);
