import { Select } from 'components/Form';
import { Field } from 'redux-form';
// import { Wrapper } from './styles';

function Sort() {
  return (
    <Field
      name="sort"
      label="Ordenar"
      placeholder=""
      options={[
        {
          label: 'Ordenação por nome',
          value: 'by_person_name',
        },
        {
          label: 'Data da última interação',
          value: '-by_person_interacted_at',
        },
      ]}
      component={Select}
    />
  );
}

export default Sort;
