import React, { useCallback } from 'react';
import { Actions, Img, Overlay, OverlayContent, Text, Wrapper } from './styles';
import { useImageUpload } from 'hooks/useImageUpload';
import Button from 'components/Button';
import Recommended from './components/Recommended';
import { ThemeProvider } from 'styled-components';
import UploadButton from 'components/UploadButton';
import { useDispatch } from 'react-redux';
import useReduxForm from 'hooks/useReduxForm';
import useFormValue from 'hooks/useFormValue';

// Function to convert Blob to Data URL
const blobToDataURL = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(blob);
  });
};

export default function FieldImageModal({
  height = null,
  recommended = null,
  text = 'Envie uma imagem',
  hasRemove = false,
  imageType = 'cover',
  aspectRatio = [16, 9],
  name = 'image',
  nameFileUrl = 'file_url',
  cropperOptions,
  onUpload,
  size = 'medium',
  onRemove = null,
  handleRemove: _handleRemove,
  style,
  actions,
}) {
  const dispatch = useDispatch();
  const { change } = useReduxForm();
  const fileUrl = useFormValue(nameFileUrl);

  let _cropperOptions = { ...cropperOptions, aspectRatio: null };

  if (aspectRatio !== null) {
    _cropperOptions = {
      ..._cropperOptions,
      aspectRatio: aspectRatio[0] / aspectRatio[1],
    };
  }

  const { remove } = useImageUpload({
    name,
    nameFileUrl,
    cropperOptions: _cropperOptions,
    onUpload,
  });

  const handleRemove = useCallback(
    (e) => {
      if (_handleRemove) {
        _handleRemove(e);
      } else {
        remove(e);
      }
      if (onRemove) onRemove();
    },
    [remove, onRemove, _handleRemove]
  );

  const onDropAccepted = async (files) => {
    const file = files[0];

    // Muda o campo que tem o blob da imagem
    dispatch(change(name, file));

    // Convert the file to a data URL
    const dataUrl = await blobToDataURL(file);

    // Use the data URL as needed
    dispatch(change(nameFileUrl, dataUrl));
  };

  return (
    <ThemeProvider
      theme={{
        hasFile: !!fileUrl,
        hasRemove,
        imageType,
        height,
      }}
    >
      <UploadButton onDropAccepted={onDropAccepted}>
        <Wrapper key={fileUrl} aspectRatio={aspectRatio} style={style}>
          {!!fileUrl && <Img size={size} src={fileUrl} alt="Imagem" />}
          <Overlay>
            <OverlayContent>
              {recommended && <Recommended>{recommended}</Recommended>}
              {!fileUrl && text && <Text>{text}</Text>}
              <Actions actions={actions}>
                <Button
                  type="button"
                  color="white"
                  colorText="primary"
                  size="large"
                >
                  {fileUrl ? 'Trocar imagem' : 'Enviar imagem'}
                </Button>
                {!!fileUrl && hasRemove && (
                  <Button
                    onClick={handleRemove}
                    type="button"
                    color="danger"
                    size="large"
                  >
                    Remover imagem
                  </Button>
                )}
              </Actions>
            </OverlayContent>
          </Overlay>
        </Wrapper>
      </UploadButton>
    </ThemeProvider>
  );
}
