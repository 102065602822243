import React from 'react';
// Components
import MainTitle from 'components/MainTitle';
// Components
import ApparenceForm from './components/ApparenceForm';
// Service
import * as appearenceService from 'services/sites/appearence';

class Apparence extends React.Component {
  state = {
    formValues: {},
  };

  componentDidMount() {
    appearenceService.getOne().then(({ data }) => {
      this.setState({ formValues: data });
      return data;
    });
  }

  handleSubmit = (values) => appearenceService.update(values);

  render() {
    return (
      <>
        <MainTitle
          title="Aparência"
          text="Configure a aparência do seu site."
        />
        <ApparenceForm
          onSubmit={this.handleSubmit}
          initialValues={this.state.formValues}
        />
      </>
    );
  }
}

export default Apparence;
