import React from 'react';
import PropTypes from 'prop-types';
import { MdHelp } from 'react-icons/md';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
import ReactTooltip from 'react-tooltip';
// Components
import { FieldBool, Input, RadioButton, Textarea } from 'components/Form';
import Button from 'components/Button';
import ListOptions from 'components/ListOptions';
import { ModalFooter } from 'components/Modal';
// Services
import * as dealsService from 'services/deals';
// Validate
import validate from './validate';
import Can from 'containers/Can';

class FormDealGain extends React.Component {
  static defaultProps = {
    handleSubmit: null,
    lossReasonId: null,
    handleClose: null
  };

  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    lossReasonId: PropTypes.string.isRequired
  };

  state = {
    reasons: [],
    isFetching: true
  };

  componentDidMount() {
    this.fetchReasons();

    ReactTooltip.rebuild();
  }

  componentDidUpdate(prevProps, { reasons: prevReasons }, snapshot) {
    const { reasons } = this.state;

    if (prevReasons !== reasons) {
      this.selectFirstReason();
    }
  }

  selectFirstReason() {
    const { autofill } = this.props;
    const { reasons } = this.state;

    // Se não tiver nenhum motivo não seleciona nada
    if (!reasons) return null;

    // Seleciona a primeiro motivo
    autofill('loss_reason_id', reasons[0].id);
  }

  fetchReasons = async () => {
    const { data: reasons } = await dealsService.getLossReasons();
    this.setState({ reasons, isFetching: false });
    return reasons;
  };

  get isAnotherReason() {
    return this.props.lossReasonId === 'other';
  }

  get renderReasons() {
    const { change } = this.props;
    const { reasons } = this.state;

    if (!reasons) return null;

    return reasons.map(reason => (
      <li key={reason.id} className="h-margin-bottom--10">
        <Field
          type="radio"
          name="loss_reason_id"
          component={RadioButton}
          value={reason.id}
          onChange={() => change('reason', null)}
          label={reason.reason}
        />
      </li>
    ));
  }

  render() {
    const { anyTouched, handleClose, handleSubmit, error } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <ListOptions
          style={{
            width: 'calc(100% + 40px)',
            marginLeft: '-20px',
            marginTop: '-20px'
          }}
        >
          <ListOptions.item>
            <p className="h-color--primary h-margin-bottom--15">
              Selecione o motivo:
            </p>
            <ul className="h-list--none">
              {this.renderReasons}
              <Can run="EDIT_LOSS_REASONS">
                <li>
                  <Field
                    label="Outro"
                    type="radio"
                    name="loss_reason_id"
                    component={RadioButton}
                    value="other"
                  />
                </li>

                {this.isAnotherReason && (
                  <li>
                    <Row className="h-margin-top--10">
                      <Field
                        xs={12}
                        name="reason"
                        component={Input}
                        placeholder="Digite o motivo aqui"
                      />
                    </Row>
                  </li>
                )}
              </Can>
            </ul>
            <Row className="h-margin-bottom--10">
              <Col>
                {anyTouched && error && (
                  <div className="h-color--danger">{error}</div>
                )}
              </Col>
            </Row>
          </ListOptions.item>
          <ListOptions.item>
            <Field
              valueFormat
              label="Observação"
              name="loss_note"
              component={Textarea}
            />
          </ListOptions.item>
          <ListOptions.item
            title={
              <>
                Remover perfis existentes{' '}
                <MdHelp
                  className="h-color--secondary"
                  style={{ display: 'inline-block' }}
                  data-html
                  data-effect="solid"
                  data-tip={`Marcando essa opção, seu cliente<br /> não irá mais receber ofertas de 
                    imóvel por<br /> e-mail, tendo em vista que já concretizou o negócio.
                  `}
                />
              </>
            }
            renderOptions={() => <FieldBool name="remove_search_profile" />}
          />
        </ListOptions>

        <ModalFooter withBorder>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success">
            Concluir
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

const FormDealGainContainer = reduxForm({
  form: 'FormDealLoss',
  validate,
  enableReinitialize: true
})(FormDealGain);

const selector = formValueSelector('FormDealLoss');

const mapStateToProps = state => ({
  lossReasonId: selector(state, 'loss_reason_id')
});

export default connect(mapStateToProps)(FormDealGainContainer);
