import React from 'react';
import styled, { css } from 'styled-components';
import Lottie from 'lottie-react';
import animationData from 'pages/PropertyStore/animations/error.json';
import Button from 'components/Button';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: grid;
  place-items: center;
  z-index: 1100;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;

  ${(p) =>
    p.isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  svg {
    width: 50%;
  }
`;

const Content = styled.div`
  width: 60%;
  text-align: center;
`;

const Title = styled.div`
  font-size: 20px;
  text-align: center;
  margin-bottom: 5px;
`;

const Text = styled.p`
  margin-bottom: 15px;
`;

function Error({ isVisible, error, setError }) {
  return (
    <Wrapper isVisible={isVisible}>
      <Content>
        <Title>Erro ao gerar conteúdo</Title>
        <Text>Fique tranquilo não foi descontado nenhum crédito</Text>
        <div
          style={{
            width: '250px',
            height: '220px',
            margin: '-30px auto 0',
          }}
        >
          {isVisible && (
            <Lottie
              style={{
                width: '250px',
              }}
              animationData={animationData}
              loop={false}
            />
          )}
        </div>
        <Button
          color="secondary"
          onClick={() => {
            setError(null);
            error.generateDescription();
          }}
        >
          Tentar novamente
        </Button>
        <Button
          color="white"
          onClick={() => {
            setError(null);
          }}
        >
          Cancelar
        </Button>
      </Content>
    </Wrapper>
  );
}

export default Error;
