// Modules
import createDataReducer from 'modules/data';
// Services
import * as searchesService from 'services/sites/searches';

export const {
  selectors: searchesSelectors,
  actionCreators: searchesActions,
  reducer
} = createDataReducer('components/searches');

/**
 * Busca todos os modelos de cabeçalhos
 * @param params
 * @return {Promise}
 */
export const fetchSearches = params => dispatch => {
  dispatch(searchesActions.request());

  return searchesService.getComponents(params).then(res => {
    dispatch(searchesActions.receive(res.data));
    return res;
  });
};

export default reducer;
