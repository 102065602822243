import React, { useEffect } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Row, Col } from 'react-flexbox-grid';

// Components
import MainTitle from 'components/MainTitle';
import { Input, Textarea } from 'components/Form';
import SeeExample from '../../components/SeeExample';
import FormFixedBar from '../../components/FormFixedBar';
import Content from '../../components/Content';
import GroupControl, { GroupItem } from 'components/GroupControl';
import FieldUrl from 'containers/FieldUrl';
import GoogleResult from 'components/GoogleResult';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';

// Hooks
import { useRequest } from 'hooks/useRequest';
import useFormValue from 'hooks/useFormValue';
import { useEnterprise } from 'pages/EnterpriseStore/context';

// HOC's
import { withFormConfirmation } from 'HOC/withFormConfirmation';

// Services
import { update, get } from 'services/buildings/seo';

const Description = ({ handleSubmit, change, initialize }) => {
  const { enterpriseId } = useEnterprise();
  const { data, isFetching } = useRequest({
    request: get,
    params: enterpriseId
  });

  const { link, meta_title, meta_description } = useFormValue([
    'link',
    'meta_title',
    'meta_description'
  ]);

  useEffect(() => {
    initialize({
      id: enterpriseId,
      ...data
    });
  }, [data, enterpriseId]);

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle title="Seo" text="Defina os dados de SEO do seu hotsite.">
        <SeeExample />
      </MainTitle>

      <Content isFetching={isFetching}>
        <Row middle="xs">
          <Col xs={6}>
            <Row>
              <Col xs={12}>
                <GroupControl label="URL Personalizada">
                  <GroupItem label="seusite.com.br/" size="small" />
                  <GroupItem>
                    <FieldUrl name="link" />
                  </GroupItem>
                </GroupControl>
              </Col>
            </Row>
            <Row>
              <Field
                xs={12}
                label={
                  <>
                    Título da página{' '}
                    <a
                      href="https://support.google.com/webmasters/answer/35624?hl=pt-BR"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-link"
                    >
                      (saiba mais)
                    </a>
                  </>
                }
                name="meta_title"
                placeholder="Digite o título"
                component={Input}
                maxLength={120}
              />
              <Field
                xs={12}
                type="simple"
                label="Descrição"
                name="meta_description"
                placeholder="Digite a descrição"
                maxLength={156}
                component={Textarea}
              />
            </Row>
          </Col>
          <Col xs={6}>
            <GoogleResult
              url={`www.seusite.com.br/${link ? link : ''}`}
              title={meta_title || 'Nome do Hotsite'}
              description={meta_description}
            />
          </Col>
        </Row>
      </Content>

      <FormFixedBar />
    </form>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
    onSubmit: update
  }),
  withFormConfirmation()
)(Description);
