import React from 'react';
// import { Wrapper } from './styles';

function Debug({ position, pov }) {
  return (
    <div
      style={{
        fontSize: '12px',
        backgroundColor: 'rgba(255,255,255, .5)',
        position: 'absolute',
        top: 10,
        right: 10,
        padding: 10,
        zIndex: 10,
      }}
    >
      <p className="h-margin-bottom--10">Position</p>
      <pre className="h-color--primary">
        {JSON.stringify(position, null, 2)}
      </pre>
      <p className="h-margin-bottom--10">Pov</p>
      <pre className="h-color--primary">{JSON.stringify(pov, null, 2)}</pre>
    </div>
  );
}

export default Debug;
