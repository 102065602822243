import React from 'react';
import PropTypes from 'prop-types';
import Check from 'components/Check';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    period: PropTypes.string,
    value: PropTypes.number
  })
};

const defaultProps = {
  data: {}
};

const TableItem = (
  { data: { type, periods, total, isSelected }, handleToggleItem },
  indexChannel
) => (
  <tr className="h-hover" onClick={handleToggleItem}>
    <td className="h-sticky--left">
      <Check checked={isSelected} className="h-margin-right--15" />
      {type}
    </td>
    {periods.length > 0 &&
      periods?.map(({ period, total }, indexPeriod) => (
        <td key={`${indexPeriod}-${type}`}>{total}</td>
      ))}
    <td className="h-sticky--right">{total}</td>
  </tr>
);

TableItem.propTypes = propTypes;
TableItem.defaultProps = defaultProps;

export default TableItem;
