export const validate = (values) => {
  let errors = {};

  if (!values.title) errors.title = ['Campo obrigatório'];

  if (values.is_automatic === 'AUTO') {
    if (!values.type) errors.type = ['Campo obrigatório'];
    if (!values.limit) errors.limit = ['Campo obrigatório'];
    if (!values.property_sort) errors.property_sort = ['Campo obrigatório'];
  }

  return errors;
};
