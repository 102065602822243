import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 -5px -5px -5px;
`;

export const Square = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: 20px;
  height: 20px;
  margin: 5px;
  background: #a1d1fb;
  border-radius: 4px;
  cursor: pointer;

  ${p =>
    p.isSelected &&
    css`
      background: #0084f4;
    `}
`;

export const Row = styled.div`
  display: flex;
`;
