import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { FieldImage, Textarea } from 'components/Form';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormAbout = ({
  fileUrl,
  image,
  handleSubmit,
  submitting,
  pristine,
  history,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={7}>
          <Field
            height={450}
            name="description"
            component={Textarea}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ header: [1, 2, 3] }],
                [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
                ['link'],
                ['clean'],
              ],
            }}
          />
        </Col>
        <Col xs={5}>
          <Field
            hasRemove
            height={450}
            name="image"
            text={
              <>
                Envie uma imagem para a página sobre nós
                <br />
                <br />
                <strong>Tamanho recomendado</strong>: 350x450px
              </>
            }
            component={FieldImage}
            fileUrlField="file_url"
          />
        </Col>
      </Row>
      <FixedBar style={{ left: 240 }}>
        <Button onClick={history.goBack} color="white" colorText="secondary">
          Voltar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar
        </Button>
      </FixedBar>
    </form>
  );
};

FormAbout.defaultProps = defaultProps;
FormAbout.propTypes = propTypes;

const selector = formValueSelector('FormAbout');

const mapStateToProps = (state) => ({
  image: selector(state, 'image'),
  fileUrl: selector(state, 'file_url'),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'FormAbout',
    enableReinitialize: true,
  }),
  withRouter
)(FormAbout);
