import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 945px;
  padding: 100px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  width: 44%;
  margin-right: 35px;

  h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: ${p => p.theme.colors.primary};
    line-height: 1.6em;
  }

  .Button {
    margin-top: 15px;
  }
`;

export const ImageWrapper = styled.div`
  width: calc(56% - 35px);
`;
