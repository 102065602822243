import React, { useEffect } from 'react';
import { reduxForm, Field } from 'redux-form';

// Components
import Reception from 'pages/EnterpriseStore/components/Reception';
import { Input } from 'components/Form';
import FormFixedBar from '../../components/FormFixedBar';

// Services
import { get, update } from 'services/buildings/name';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Context
import { useEnterprise } from 'pages/EnterpriseStore/context';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';

import image from './reception-start.png';

const Start = ({ handleSubmit, initialize }) => {
  const { enterpriseId } = useEnterprise();
  const { data } = useRequest({
    request: get,
    params: enterpriseId,
    initialState: {}
  });

  useEffect(() => {
    initialize(data);
  }, [data]);

  return (
    <form onSubmit={handleSubmit}>
      <Reception
        image={image}
        title="Vamos começar"
        text="Defina o nome da página para você localizá-la em sua lista de páginas:"
      >
        <Field
          name="name"
          label="Nome da página"
          placeholder="Ex: Residencial Montanha da Gruta"
          component={Input}
        />
      </Reception>
      <FormFixedBar />
    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: update
})(Start);
