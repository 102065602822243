import qs from 'qs';

export const changeUrl = ({
  history,
  queryString,
  callBack,
  limit = 50,
  offset = 1,
  sort = '-calculated_price',
}) => {
  const params = new URLSearchParams(
    queryString || history.location.search || ''
  );

  if (callBack) callBack(params);

  params.set('limit', limit);
  params.set('offset', offset);
  params.set('sort', sort);

  history.replace({
    pathname: history.location.pathname,
    search: params.toString(),
  });
};

export const changeUrlObj = ({
  history,
  queryString,
  callBack,
  limit = 50,
  offset = 1,
  sort = '-calculated_price',
}) => {
  const params = qs.parse(queryString || history.location.search || '', {
    ignoreQueryPrefix: true,
  });

  if (callBack) callBack(params);

  params.limit = limit;
  params.offset = offset;
  params.sort = sort;

  history.replace({
    pathname: history.location.pathname,
    search: qs.stringify(params),
  });
};
