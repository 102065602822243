export const PAGES = {
  IMAGES_SELECT: 'imagesSelect',
  POST_GENERATOR: 'postGenerator',
};

const DEFAULT_PAGE = PAGES.IMAGES_SELECT;
const DEFAULT_IMAGE = null;
// const DEFAULT_IMAGE = {
//   updated_at: '25/01/2024 20:00:16',
//   created_at: '24/01/2024 08:37:06',
//   id: '97c91aa8-9c0a-4dfc-b071-850dbf973060',
//   gallery: 1,
//   caption: null,
//   order: 2,
//   property_id: '60b42c6e-0e68-4bc6-997d-77671fc5d56a',
//   file_url: {
//     large:
//       'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/60b42c6e-0e68-4bc6-997d-77671fc5d56a/images/97c91aa8-9c0a-4dfc-b071-850dbf9730601706096226eSRm.jpg',
//     medium:
//       'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/60b42c6e-0e68-4bc6-997d-77671fc5d56a/images/600x450/outside/97c91aa8-9c0a-4dfc-b071-850dbf9730601706096226eSRm.jpg',
//   },
//   is_external: false,
// };

export { DEFAULT_PAGE, DEFAULT_IMAGE };
