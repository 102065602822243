import React from 'react';
import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/Button';
import { Checkbox } from 'components/Form';

function ModalChatGpt({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  onConfirm,
  handleSubmit,
}) {
  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate
          title="Instruções e recomendações sobre o uso do gerador"
          handleClose={handleClose}
        >
          <p>
            A geração automática de descrição é feita através do ChatGPT versão
            3.5
          </p>
          <p>&nbsp;</p>
          <p>
            Todo dia de aniversário da sua conta, lhe forneceremos 10 créditos
            (Não cumulativo). Caso precise de mais, você pode comprar pacotes de
            créditos; ou
            <br />
            <br />
            Acesse o ChatGPT no seu navegador, gere a descrição, copie e cole no
            Tecimob.
            <br /> Link de acesso ao ChatGPT:{' '}
            <a
              href="https://chat.openai.com"
              target="_blank"
              className="h-link"
            >
              https://chat.openai.com
            </a>
          </p>
          <p>
            <br />
          </p>
          <p>
            Antes de fazer uso do gerador, certifique-se que já tenha preenchido
            todos os <br /> dados do imóvel para que o texto gerado seja
            criativo e completo.
          </p>

          <Field
            type="checkbox"
            label="Não mostrar esta janela novamente"
            name="dont_show_again"
            component={Checkbox}
            className="h-margin-top--15"
          />

          <ModalFooter>
            <Button type="button" color="white" onClick={handleClose}>
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              Gerar descrição
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </form>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalChatGpt',
  onSubmit: async (values, dispatch, props) => {
    localStorage.setItem(
      '@tecimob/chat-gpt/dont_show_again',
      values?.dont_show_again
    );

    if (props?.onConfirm) props?.onConfirm();

    return true;
  },
  onSubmitSuccess: (response, dispatch, props) => {
    props.handleClose();
  },
})(ModalChatGpt);
