import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import { Input, Select } from 'components/Form';
import { parseValueBool } from 'lib/formHelpers';

class FormLinkSlide extends React.Component {
  static defaultProps = {};
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchSlide();
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form
        onSubmit={(e, ...rest) => {
          e.preventDefault();
          e.stopPropagation();
          return handleSubmit(...rest);
        }}
      >
        <Field autoFocus label="Cole o link" name="link" component={Input} />
        <Field
          canRemoveSelf={false}
          buttonTemplate
          label="Abrir link na:"
          name="opens_link_on_page"
          component={Select}
          placeholder=""
          options={[
            { label: 'Mesma Página', value: true },
            { label: 'Outra Página', value: false },
          ]}
          parse={parseValueBool}
        />
        <Button block type="submit" color="success">
          Salvar
        </Button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'FormLinkSlide',
  enableReinitialize: true,
})(FormLinkSlide);
