import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { openModalSMTPConfig } from 'modules/modal';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

function ModalSMTPAlert({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
}) {
  const dispatch = useDispatch();

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Alerta de limite de envio de e-mail atingido!"
        handleClose={handleClose}
      >
        <p>
          Você atingiu o limite máximo de envio de e-mail de nutrição do
          Tecimob. Nosso serviço possui um limite por usuário de 5.000 e-mail a
          cada 30 dias.
        </p>
        <br />
        <p>
          Para grandes volumes de envio de e-mail, é necessário utilizar um
          servidor de envio de e-mails dedicado a e-mail marketing. Segue uma
          lista de empresas dedicadas para você conhecer e contratar:
        </p>
        <br />
        <p>
          -Locaweb (
          <a
            href="https://www.locaweb.com.br/email-marketing-locaweb"
            className="h-link"
            target="_blank"
            rel="noreferrer"
          >
            https://www.locaweb.com.br/email-marketing-locaweb
          </a>
          )
        </p>
        <p>
          -Sendgrind (
          <a
            href="https://sendgrid.com/solutions/email-marketing"
            className="h-link"
            target="_blank"
            rel="noreferrer"
          >
            https://sendgrid.com/solutions/email-marketing
          </a>
          )
        </p>
        <p>
          -Amazon SES (
          <a
            href="https://aws.amazon.com/pt/ses/"
            className="h-link"
            target="_blank" rel="noreferrer"
          >
            https://aws.amazon.com/pt/ses/
          </a>
          )
        </p>
        <br />
        <p>
          Após a contração do serviço, clique em configurar SMTP para informar
          seus dados de acesso ao serviço e liberar maior volume de envios.
        </p>
        <ModalFooter>
          <Button type="button" onClick={handleClose} color="white">
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button
            color="secondary"
            onClick={() => {
              dispatch(openModalSMTPConfig());
            }}
          >
            Configurar SMTP
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalSMTPAlert;
