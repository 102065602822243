import React, { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { openModalPerson } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { MdInfo } from 'react-icons/md';
import { colors } from 'constants/styles';
import Nodata from 'containers/TableLastLeadsReceived/components/Nodata';
import { getName } from 'lib/text';
import { Table } from 'containers/TableLastLeadsReceived/styles';

const Stage = ({ stage }) => {
  if (!stage) return '-';

  if (stage === 'Negócio perdido')
    return <span className="h-color--danger">{stage}</span>;
  if (stage === 'Negócio ganho')
    return <span className="h-color--success">{stage}</span>;
  return stage;
};

const Info = ({ data }) => {
  if (!data?.properties_available) {
    return (
      <MdInfo
        size={18}
        color={colors.secondary}
        data-tip="Algum imóvel desse cliente não está mais disponível"
      />
    );
  }
  return null;
};

function TableComponent({ data, fetchData }) {
  const dispatch = useDispatch();

  useEffect(() => {
    Tooltip.rebuild();
  }, [data]);

  const openPerson = (person, params) => (e) => {
    e.stopPropagation();
    dispatch(openModalPerson({ ...person, afterClose: fetchData }, params));
  };

  return (
    <Table>
      <thead>
        <tr>
          <th width={155}>Data</th>
          <th>Cliente</th>
          <th>Origem</th>
          <th width={175}>Corretor responsável</th>
          <th width={145}>Posição do CRM</th>
          <th width={175}>Última interação</th>
        </tr>
      </thead>
      {data?.leads_list?.length <= 0 ? (
        <tbody>
          <tr>
            <td colSpan={6}>
              <Nodata />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {data?.leads_list?.map((item) => (
            <tr
              key={'leads_list' + item.id}
              onClick={() => {
                dispatch(openModalPerson({ id: item.person_id }));
              }}
              className="h-pointer"
            >
              <td>{item.created_at}</td>
              <td>{getName(item.person_name)}</td>
              <td>{item.reception_source_name}</td>
              <td>{item.user_name}</td>
              <td>
                <Stage stage={item?.stage} />
              </td>
              <td>
                {item.person_interacted_at} | {item.person_interacted_since}
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </Table>
  );
}

export default TableComponent;
