import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Content = styled.div`
  position: relative;
  flex: 1 0;
  overflow: auto;
`;

export const Inner = styled.div`
  width: 100%;
  max-width: 945px;
  margin: 0 auto 80px;
`;
