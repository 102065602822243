import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  border-radius: 4px;
`;
export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const Td = styled.td`
  padding: 15px 20px;

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;

export const Tr = styled.tr`
  & + & ${Td} {
    border-top: 1px solid #f1f4f9;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 5px;
  white-space: nowrap;
  justify-content: flex-end;
`;
