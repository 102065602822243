import React, { useEffect } from 'react';
import { Row } from 'react-flexbox-grid';
import { reduxForm } from 'redux-form';
import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { plural } from 'lib/text';
import * as establishmentsService from 'services/establishments';
import Button from 'components/Button';
import FieldEstablishments from 'containers/FieldEstablishments';

function ModalEstablishmentRemove({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  establishment,
  initialize,
  handleSubmit,
}) {
  useEffect(() => {
    initialize(establishment);
  }, [establishment]);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate
          title={`Remover Estabelecimento - ${establishment.title}`}
          handleClose={handleClose}
        >
          {establishment.properties_count > 0 ||
          establishment.condominiuns_count > 0 ? (
            <>
              Esse estabelecimento está ligada a{' '}
              <ul className="h-list">
                {establishment.properties_count > 0 && (
                  <li>
                    {plural(
                      establishment.properties_count,
                      'imóvel',
                      'imóveis'
                    )}
                  </li>
                )}
                {establishment.condominiuns_count > 0 && (
                  <li>
                    {plural(
                      establishment.condominiuns_count,
                      'condomínio',
                      'condomínios'
                    )}
                  </li>
                )}
              </ul>
              <br />
              Defina para qual estabelecimento deseja transferi-los:
              <Row className="h-margin-top--15">
                <FieldEstablishments
                  label="Selecione o estabelecimento"
                  xs={6}
                  name="to_id"
                  excludeIds={[establishment.id]}
                />
              </Row>
            </>
          ) : (
            <>Tem certeza que deseja remover esse estabelecimento?</>
          )}

          <ModalFooter>
            <Button onClick={handleClose} color="white">
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="danger">
              Remover
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </form>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalCharacteristicRemove',
  onSubmit: async (values, dispatch, props) => {
    // Se existir pessoas
    // tem que transferir o grupo pra outro lugar
    if (values?.to_id) {
      await establishmentsService.transfer(values?.id, values?.to_id);
    }

    return establishmentsService.remove(values.id).then((res) => {
      if (props.onSubmitSuccess) props.onSubmitSuccess(res, dispatch, props);
      props.handleClose();
    });
  },
})(ModalEstablishmentRemove);
