import React, { useCallback } from 'react';
import Alert from 'react-s-alert';
import Modal from 'react-modal';

// Components
import Form from './components/Form';

// Services
import * as userService from 'services/user';

function ModalSatisfaction({
  satisfaction,
  modalConfig,
  isOpen,
  handleClose,
  modalType
}) {
  const onSubmit = useCallback(
    values =>
      userService.updateSatisfaction({
        id: satisfaction.id,
        ...values
      }),
    [satisfaction]
  );

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Form
        handleClose={handleClose}
        onSubmit={onSubmit}
        onSubmitSuccess={() => {
          Alert.success('Obrigado!');
          handleClose();
        }}
      />
    </Modal>
  );
}

export default ModalSatisfaction;
