import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
// Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
// Services
import * as locationService from 'services/location';
import { useRequest } from 'hooks/useRequest';

export default function ModalCityDelete({
  id,
  state_id,
  onSubmitSuccess,

  modalConfig,
  isOpen,
  modalType,
  handleClose,
}) {
  const { data: citys } = useRequest({
    request: () =>
      locationService.getCities({
        include: 'state',
        count: 'properties,people,search_profiles',
        with_defaults: false,
      }),
  });

  const city = useMemo(() => {
    return citys.find((city) => {
      return city.id === id;
    });
  }, [citys]);

  const options = useMemo(() => {
    return citys.filter((city) => {
      return city.id !== id;
    });
  }, [citys]);

  const handleSubmit = ({ recipient_id }) => {
    return locationService.transferAndRemoveCity(state_id, id, recipient_id);
  };

  const handleSubmitSuccess = useCallback((...rest) => {
    if (onSubmitSuccess) onSubmitSuccess(rest);
    handleClose();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Excluir cidade" handleClose={handleClose}>
        <Form
          city={city}
          options={options}
          id={id}
          state_id={state_id}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}
