import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
// Components
import Button from 'components/Button';
import * as Fields from 'components/PropertyFilters';
// Containers
import Establishments from '../../containers/Establishments';
import Characteristics from '../../containers/Characteristics';
import TypesAndSubtypes from '../../containers/TypesAndSubtypes';
import FinancialIndexes from '../../containers/FinancialIndexes';
import Realtors from '../../containers/Realtors';
import CondosCharacteristics from '../../containers/CondosCharacteristics';
// Modules
import { typeSelector } from '../../containers/TypesAndSubtypes/module';
import { isReferenceNotChanged } from 'lib/property-helpers';

export const Wrapper = ({ onSubmit, children }) => (
  <form onSubmit={onSubmit} className="FilterAside FilterAside--withFooter">
    {children}
  </form>
);
export const Title = ({ children }) => (
  <div className="FilterAside__title">{children}</div>
);
export const Content = ({ children }) => (
  <div className="FilterAside__content">{children}</div>
);
const Footer = ({ children, style }) => (
  <div className="FilterAside__footer" style={style}>
    {children}
  </div>
);

class MainForm extends React.Component {
  static defaultProps = {};

  static propTypes = {};

  handleChangeTypeOrSubtype = () => {
    const { change } = this.props;

    change('rooms', null);
    change('areas', null);
    change('condos_characteristics', null);
  };

  get renderFooter() {
    const { isSearching, handleSaveProfile, handleResetSearch, initialize } =
      this.props;

    if (isSearching) {
      return (
        <Footer>
          <Row className="row--5">
            <Col xs={6}>
              <Button
                block
                type="button"
                color="danger"
                onClick={() => {
                  initialize();
                  handleResetSearch();
                }}
              >
                Limpar
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                type="button"
                block
                color="primary"
                onClick={handleSaveProfile}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Footer>
      );
    }

    return (
      <Footer>
        <Button type="submit" block color="secondary">
          Encontrar imóveis
        </Button>
      </Footer>
    );
  }

  render() {
    const { realEstatePortalId, type, handleSubmit, change } = this.props;

    return (
      <Wrapper onSubmit={handleSubmit}>
        <Title>Pesquisar</Title>
        <Content>
          <Fields.Reference />
          <Fields.Condominium hideEmpty />
          <TypesAndSubtypes change={change} />
          <Fields.Transaction canRemoveSelf />

          {/*/!* Type *!/*/}
          {type && type.rooms_fields && (
            <Fields.Rooms options={type.rooms_fields} />
          )}

          <Fields.Price />
          <Fields.Location />

          <Characteristics type={type} change={change} />
          <CondosCharacteristics />

          <Fields.IsExchangeable />
          <Fields.Furniture />

          <Fields.Situations type={type} />
          <Fields.Profile />

          {/*/!* Type *!/*/}
          {type && type.area_fields && (
            <Fields.Area options={type.area_fields} />
          )}

          {/*/!* Type *!/*/}

          <Fields.StreetAddress />
          <Establishments />

          <Fields.Financial />
          <Fields.Age />
          <Fields.Floor />
          <Fields.NearSea />
          <Fields.IsDeeded />
          {realEstatePortalId && (
            <Fields.Portal portalRealEstateId={realEstatePortalId} />
          )}
        </Content>
        <Title>Amplitude</Title>
        <Content>
          <Fields.Database />
          <Fields.PersonOrulo />
        </Content>
        <Title>Manutenção</Title>
        <Content>
          <FinancialIndexes />
          <Fields.Updated />
          <Fields.Revision />
          <Fields.HasExclusivity />
          <Fields.Exclusivity />
          <Realtors />
          <Fields.NotAvailable />
        </Content>
        {this.renderFooter}
      </Wrapper>
    );
  }
}

export const selector = formValueSelector('FilterProperties');

const mapStateToProps = (state) => ({
  type: typeSelector(state),
});

let timeout = null;

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'FilterProperties',
    enableReinitialize: true,
    initialValues: {
      by_country_id: 'dfb22ce9-8112-4e60-bc92-ecdba85b1ddc',
    },
    onChange: (values, dispatch, ownProps, prevValues) => {
      if (isReferenceNotChanged(values, prevValues)) {
        ownProps.hideReference();
      }

      if (values === prevValues) return;
      if (ownProps.isSearching) {
        clearTimeout(timeout);
        timeout = setTimeout(
          () => ownProps?.onSubmit && ownProps.onSubmit(values),
          800
        );
      }
    },
  })
)(MainForm);
