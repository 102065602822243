import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';

function FooterActions({
  isLast,
  handleSubmit,
  onSubmit,
  submitText = 'Salvar e Adicionar Fotos',
}) {
  let { path: basePath } = useRouteMatch();

  const dispatch = useDispatch();
  const { push } = useHistory();

  const isEditing = useMemo(() => {
    return basePath?.includes('update');
  }, [basePath]);

  const renderButtons = () => {
    if (isEditing) {
      return (
        <Button
          type="submit"
          color="success"
          // onClick={(e) => {
          //   e.preventDefault();
          //
          //   dispatch(
          //     openModalBoolean({
          //       title: 'Aplicar alterações em todos os imóveis associados?',
          //       text: 'Deseja aplicar as alterações em todos os imóveis associados?',
          //       onYesClick: handleSubmit((values, dispatch, props) => {
          //         return onSubmit({ ...values, should_update_properties: true }, dispatch, props);
          //       }),
          //       onNoClick: handleSubmit((values, dispatch, props) => {
          //         return onSubmit({ ...values, should_update_properties: false }, dispatch, props);
          //       }),
          //     }),
          //   );
          // }}
        >
          Salvar
        </Button>
      );
    }

    if (isLast) {
      return (
        <Button type="submit" color="success">
          Salvar
        </Button>
      );
    }

    return (
      <Button type="submit" color="success">
        {submitText}
      </Button>
    );
  };

  return (
    <FixedBar left={274}>
      <Button
        type="button"
        color="white"
        colorText="secondary"
        onClick={() => {
          dispatch(
            openConfirmation({
              size: 324,
              title: 'Deseja mesmo sair do cadastro?',
              text: 'As informações que você salvou não serão perdidas.',
              cancelButtonText: 'Voltar',
              submitButtonText: 'Sair',
              request: () => {
                push('/properties/condos');
              },
            })
          );
        }}
      >
        Cancelar
      </Button>
      <FixedBar.spacer />
      {renderButtons()}
    </FixedBar>
  );
}

export default FooterActions;
