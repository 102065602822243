import { last, map } from 'lodash';
import castArray from 'lodash/castArray';

import {
  ACCEPT_ALL_FILES,
  ACCEPT_DOCUMENTS,
  ACCEPT_PHOTOS,
} from 'constants/config';
import Alert from 'react-s-alert';
import axios from 'axios';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { plural } from 'lib/text';

// Tamanho de referência pra um megabyte
const MEGABYTE = 1048576;

// Mínimo tamanho permitido para arquivos
export const MIN_SIZE = 0;

// Máximo tamanho permitido para arquivos
export const MAX_SIZE = MEGABYTE * 10;

// Retorna as variaveis constantes padrão relacionadas a arquivos
export { ACCEPT_PHOTOS, ACCEPT_DOCUMENTS, ACCEPT_ALL_FILES };

/**
 * Pega o nome de um arquivo
 * @param file
 * @return {*}
 */
export function getFileName(file) {
  return file.name;
}

/**
 * Pega todos os nomes de arquivos
 * @param files
 * @return {*}
 */
export function getFilesNames(files) {
  return map(files, getFileName);
}

/**
 * Pega os arquivos que tem erro e categoriza os erros
 * @param files
 * @param options
 * @return {{bySizeMax: Array, bySizeMin: Array}}
 */
export function getFilesWithErrors(
  files,
  options = {
    maxSize: MAX_SIZE,
    minSize: MIN_SIZE,
  }
) {
  let errors = {
    bySizeMax: [],
    bySizeMin: [],
  };

  castArray(files).map((file) => {
    // Verifica se o tamanho do arquivo é maior do que o permitido
    if (file.size > options.maxSize) {
      errors.bySizeMax = [...errors.bySizeMax, file];
    }

    // Verifica se o tamanho do arquivo é menor que o permitido
    if (file.size <= options.minSize) {
      errors.bySizeMin = [...errors.bySizeMin, file];
    }

    return null;
  });

  return errors;
}

/**
 * Lida com o evento de rejeição dos arquivos do react-dropzone
 * @param {function} callback
 * @param {function} dispatch
 */
export const onDropRejected =
  (callback = null, dispatch) =>
  (files, event) => {
    const castedFiles = castArray(files);

    // Executa o handler caso exista
    if (callback) callback(castedFiles, event);

    let { bySizeMax } = getFilesWithErrors(castedFiles);

    // Verifica se tem algum arquivo com erro de tamanho
    if (bySizeMax.length > 0) {
      console.log(bySizeMax.length);
      const text = plural(
        bySizeMax.length,
        'O arquivo abaixo exede o limite de 10mb por imagem:',
        'Os arquivos abaixo excedem o limite de 10MB por imagem:',
        false
      );

      dispatch(
        openConfirmation({
          title: 'Limite de tamanho de imagem',
          text: (
            <>
              <p>{text}</p>
              <ul
                style={{
                  listStyle: 'inside',
                  margin: '5px 0 5px',
                  fontWeight: 'bold',
                }}
              >
                {getFilesNames(bySizeMax).map((fileName, key) => (
                  <li key={key + fileName}>{fileName}</li>
                ))}
              </ul>
              <p>É necessário reduzir o tamanho da imagem antes de enviar.</p>
              <a
                target="_blank"
                href="https://ajuda.tecimob.com.br/reduzir-suas-fotos-sem-perder-qualidade/"
                className="h-link"
              >
                Saiba mais clicando clicando aqui
              </a>
            </>
          ),
          cancelButtonText: false,
          submitButtonText: 'Fechar',
          submitButtonColor: 'white',
        })
      );
    } else {
      Alert.success('Formato do arquivo inválido');
    }
  };

/**
 * Verifica se a url do arquivo
 * @param fileUrl
 */
export const isImage = (fileUrl) => /(png|jpg|jpeg|gif|webp)/.test(fileUrl);

/**
 * Retorna a extenção do arquivo
 * @param fileUrl
 * @returns {string}
 */
export const getFileExtension = (fileUrl) => {
  try {
    last(fileUrl.split('.'));
  } catch {
    return '';
  }
};

export const fileDownload = async (response, fileName = 'text.txt') => {
  const blob = new Blob([response.data]);

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName; // Set the desired filename
  document.body.appendChild(link);

  // Simulate a click on the link to trigger the download
  link.click();

  // Clean up by revoking the temporary URL
  window.URL.revokeObjectURL(url);
};

export function urlToObjectURL(url) {
  // Utiliza o Axios para fazer a requisição GET para a URL da imagem
  return axios
    .get(url, {
      responseType: 'blob', // Define o tipo de resposta como blob
    })
    .then((response) => {
      // Cria um URL local para o Blob
      return URL.createObjectURL(response.data);
    })
    .catch((error) => {
      // Se ocorrer algum erro, retorna null
      console.error('Erro ao obter dados da imagem:', error);
      return null;
    });
}
