import React from 'react';
// Components
import Wizard from 'components/Wizard';
import { withWizard } from 'HOC/withWizard';
// HOC

const STEPS = [
  //{
  //  target: '.js-wizard-menu',
  //  title: 'Menu Principal',
  //  content: 'Aqui você gerencia seus imóveis clientes e agenda.',
  //  disableOverlayClose: true,
  //  spotlightClicks: false,
  //  disableBeacon: true
  //},
  //{
  //  target: '.js-wizard-ajustes',
  //  title: 'Ajustes',
  //  content:
  //    'Aqui você vai personalizar a aparência do seu site, cadastrar corretores, entre outros ajustes.',
  //  disableOverlayClose: true,
  //  spotlightClicks: false
  //},
  //{
  //  target: '.js-video-step',
  //  title: 'Site Campeão',
  //  content: 'Esses são os passos recomendados para você ter um site campeão.',
  //  disableOverlayClose: true,
  //  spotlightClicks: false
  //},
  {
    target: '.js-wizard-button-site',
    title: 'Site',
    content:
      'Seu site já está pronto! Sempre que quiser acessá-lo, clique aqui.',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
  },
  {
    target: '.js-primeiros-passos',
    title: 'Primeiros passos',
    content: 'Conclua estes passos para personalizar seu site.',
    disableOverlayClose: true,
    spotlightClicks: false,
    placement: 'top',
    // disableBeacon: true,
  },
  //{
  //  target: '.js-search',
  //  title: 'Pesquisa ampla',
  //  content: 'Busque facilmente por clientes, imóveis e condomínios.',
  //  disableOverlayClose: true,
  //  spotlightClicks: false
  //},
  //{
  //  target: '.js-wizard-button-new',
  //  title: 'Cadastros',
  //  content: 'Cadastre seus imóveis, clientes e agende compromissos.',
  //  disableOverlayClose: true,
  //  spotlightClicks: false
  //},
  //{
  //  target: '.js-wizard-ajuda',
  //  title: 'Ajuda',
  //  content: 'Se tiver dúvidas, aqui tem nossos dados de contato.',
  //  disableOverlayClose: true,
  //  spotlightClicks: false
  //},
  //{
  //  target: '.js-vcard',
  //  title: 'Cartão Virtual',
  //  content:
  //    'Crie um cartão virtual com seus dados para enviar aos seus clientes.',
  //  disableOverlayClose: true,
  //  spotlightClicks: false
  //},
];

function WizardDashboard({ onClose }) {
  return <Wizard steps={STEPS} onClose={onClose} />;
}

export default withWizard('wizard/dashboard-1')(WizardDashboard);
// export default WizardDashboard;
