import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MdNotifications } from 'react-icons/md';
import Ball from 'components/Ball';
import { Wrapper } from './styles';
import Drawer from 'components/Drawer';
import NewNotifications from 'containers/NewNotifications';
import { newNotificationsSelector } from 'modules/notifications';

const MenuNotifications = () => {
  const [isOpen, setIsOpen] = useState(false);
  const hasNewNotifications = useSelector(newNotificationsSelector);

  const toggleDrawerVisibility = useCallback(() => {
    setIsOpen(_isOpen => !_isOpen);
  }, [setIsOpen]);

  return (
    <>
      <Wrapper onClick={toggleDrawerVisibility}>
        {hasNewNotifications && <Ball />}
        <MdNotifications />
      </Wrapper>
      <Drawer isOpen={isOpen} onClickOverlay={toggleDrawerVisibility}>
        <NewNotifications handleToggleDrawer={toggleDrawerVisibility} />
      </Drawer>
    </>
  );
};

export default MenuNotifications;
