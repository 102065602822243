import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Services
import * as locationService from 'services/location';
// Components
import Table from './components/Table';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
// Modules
import { openModalCityDelete, openModalCityStore } from 'modules/modal';
import { openConfirmation } from 'containers/ModalConfirmation/module';

export default function Cities() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const fetch = useCallback(async () => {
    setIsFetching(true);
    const { data } = await locationService.getCities({
      include: 'state',
      count: 'properties,people,search_profiles',
      with_defaults: false,
    });
    setData(data);
    setIsFetching(false);
  }, []);

  const openModalDelete = useCallback(
    (id, state_id) => () => {
      dispatch(
        openModalCityDelete({
          id,
          state_id,
          onSubmitSuccess: () => {
            fetch();
          },
        })
      );
    },
    []
  );

  const handleDelete =
    ({ id, state_id, properties_count, people_count, search_profiles_count }) =>
    async () => {
      const total = properties_count + people_count + search_profiles_count;

      if (total > 0) {
        return openModalDelete(id, state_id)();
      }

      dispatch(
        openConfirmation({
          title: 'Remover cidade',
          text: 'Deseja realmente remover a cidade?',
          request: () => locationService.removeCity(state_id, id),
          onSuccess: () => {
            // Remove da lista
            const _data = data.filter((data) => data.id !== id);

            setData(_data);
          },
        })
      );
    };

  const handleEdit =
    ({ id }) =>
    () => {
      openModalCityStore({
        id,
        onSubmitSuccess: ({ data: currentData }) => {
          const _data = data.map((d) => {
            if (currentData?.id === d?.id) {
              return {
                ...d,
                ...currentData,
              };
            }
            return d;
          });

          setData(_data);
        },
      })(dispatch);
    };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Wrapper.container>
      <MainTitle
        title="Cidades"
        text="Gerencie aqui todas as cidades que você cadastrou no sistema."
      />
      <Table
        data={data}
        isFetching={isFetching}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Wrapper.container>
  );
}
