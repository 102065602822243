import Alert from 'components/Alert';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import Pagination from 'components/Pagination';
import { openModalPortalPropertiesSelect } from 'modules/modal';
import { WrapperNoData } from 'pages/PortalsOptionSelect/components/PropertiesPortal/styles';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import noproperties from 'pages/PortalsOptionSelect/no-properties.png';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { change } from 'redux-form';
import FixedBarPortals from '../FixedBarPortals';
import Header from '../Header';
import Properties from '../Properties';
// import { Wrapper } from './styles';

const hasPropertiesCount = (count) => {
  return (
    count?.self?.count > 0 || count?.guru?.count > 0 || count?.orulo?.count > 0 || count?.dwv?.count > 0
  );
};

function PropertiesFilter() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedPortal, params, properties, meta, isFetching } =
    usePortalOptions();

  const hasProperties =
    properties.length > 0 || hasPropertiesCount(params?.filter?.count);

  const handlePageChange = ({ selected }) => {
    let currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.set('offset', selected + 1);
    history.push({
      search: currentSearchParams.toString(),
    });
  };

  const handleClickCheckAll = () => {
    dispatch(
      openModalPortalPropertiesSelect({
        properties,
        params: {
          ...params,
          filter: {
            ...params.filter,
            by_available_for_portals: true,
            doesnt_have_portal_real_estate_id: selectedPortal.id,
          },
        },
      })
    );
  };

  const handleClearSearch = (option) => {
    // Pega os parametros da url
    const searchParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    if (!searchParams.filter_portals) {
      searchParams.filter_portals = {};
    }

    searchParams.filter_portals.by_portal_option_id = option.portal_option_id;

    // remove os filtros da url
    if (searchParams.filter) {
      delete searchParams.filter;
    }

    // remove os counts da url
    if (searchParams.counts) {
      delete searchParams.counts;
    }

    history.replace({
      pathname: history.location.pathname,
      search: qs.stringify(searchParams, { addQueryPrefix: false }),
    });

    dispatch(change('PropertiesPortalForm', 'reference', ''));
    dispatch(change('PropertiesPortalForm', 'condominium_id', ''));
    dispatch(change('PropertiesPortalForm', 'street_address', ''));
  };

  if (!hasProperties) {
    return (
      <WrapperNoData>
        <Nodata image={noproperties} title="Nenhum imóvel selecionado" text="">
          <Button color="danger" onClick={handleClearSearch}>
            Limpar pesquisa
          </Button>
        </Nodata>
        <Loading isVisible={isFetching} isFullScreen />
      </WrapperNoData>
    );
  }

  return (
    <div>
      <Header />
      <Alert color="danger" style={{ marginBottom: '15px' }}>
        Imóveis que já estão sendo enviados para este portal serão omitidos
        desta lista de resultados
      </Alert>
      <Properties handleClickCheckAll={handleClickCheckAll} />

      {params?.offset && (
        <Pagination
          forcePage={params?.offset - 1}
          pageCount={meta?.pagination?.total_pages}
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
        />
      )}
      <Loading isVisible={isFetching} isFullScreen />
      <FixedBarPortals />
    </div>
  );
}

export default PropertiesFilter;
