import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Content from './components/Content';
import { ModalContractExtraProvider } from './contexts/modalContractExtra';
import { fetchPrices } from 'modules/prices';
import { useDispatch } from 'react-redux';
import * as creditCardsService from 'services/financial/creditCards';
import * as contractsService from 'services/financialv2/contracts';
import { PAYMENT_METHODS } from 'constants/constants';

function ModalContractExtra({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  item = 'Usuários',
  preview = {
    need_change_contract: true,
    subscription_price: 80,
    user_price: 19.9,
    mailbox_price: 4.9,
    orulo_price: 24.9,
    dwv_price: 24.9,
  },
  isCancel = false,
  onSuccess,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dispatch = useDispatch();

  const contractWithCreditCard = async (values) => {
    try {
      if (!values.card) await creditCardsService.create(values);

      const res = await contractsService.contractChange({
        item,
        receiving_method: PAYMENT_METHODS.CREDIT_CARD,
        ...values,
      });

      if (!!res?.data?.alerts) {
        throw new Error('Erro ao pagar com cartão de crédito');
      }

      setIsSuccess(true);
      return res;
    } catch (err) {
      console.log(err);
      console.log('Erro ao contratar plano com cartão de crédito');
      setHasError(true);
    }
  };

  const handleSubmit = useCallback(
    async (values) => {
      try {
        // Usar o crédito para estender a data final do meu contrato
        if (values.plan === 2) {
          return { should_change_contract: true };
        } else {
          // Não alterar meu contrato e manter minha data final como esta
          return { should_change_contract: false };
        }
      } catch (err) {
        return {};
      }
    },
    [contractWithCreditCard]
  );

  useEffect(() => {
    // Pega todos os valores
    dispatch(fetchPrices());
  }, [dispatch]);

  return (
    <ModalContractExtraProvider
      onSuccess={onSuccess}
      item={item}
      preview={preview}
      handleClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      hasError={hasError}
      setIsLoading={setIsLoading}
      setIsSuccess={setIsSuccess}
      setHasError={setHasError}
      isCancel={isCancel}
    >
      <Modal
        {...modalConfig}
        isFetching={isFetching}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <Content
          onSubmit={handleSubmit}
          onSubmitSuccess={(...args) => {
            handleClose();
            if (onSuccess) onSuccess(...args);
          }}
        />
      </Modal>
    </ModalContractExtraProvider>
  );
}

export default ModalContractExtra;
