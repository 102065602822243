import React from 'react';
import { Field } from 'redux-form';
import FormGroup from 'components/Form/components/FormGroup';
import classnames from 'classnames';
import { Ball, Bar, Wrapper } from './styles';

const getSize = (inputValue) => {
  switch (inputValue) {
    case 1:
      return 0;
    case 4:
      return 72;
    case 3:
      return 48;
    case 2:
      return 24;
    default:
      return 100;
  }
};

function LevelOfInterest({ label, meta, input, ...rest }) {
  const inputValue = input.value || 1;

  const size = getSize(inputValue);

  const className = (value) =>
    classnames({
      active: value <= inputValue,
    });

  const onClick = (value) => () => {
    input.onChange(value);
  };

  return (
    <FormGroup {...rest} labelText={label} meta={meta}>
      <Wrapper>
        <Ball className={className(1)} onClick={onClick(1)}>
          <span>1</span>
        </Ball>
        <Ball className={className(2)} onClick={onClick(2)}>
          <span>2</span>
        </Ball>
        <Ball className={className(3)} onClick={onClick(3)}>
          <span>3</span>
        </Ball>
        <Ball className={className(4)} onClick={onClick(4)}>
          <span>4</span>
        </Ball>
        <Ball className={className(5)} onClick={onClick(5)}>
          <span>5</span>
        </Ball>
        <Bar size={size} />
      </Wrapper>
    </FormGroup>
  );
}

function FieldLevelOfInterest({
  label = 'Nível de interesse',
  name = 'level_interest',
  ...rest
}) {
  return (
    <Field label={label} name={name} component={LevelOfInterest} {...rest} />
  );
}

export default FieldLevelOfInterest;
