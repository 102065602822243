import React from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
  children: ''
};

const propTypes = {
  children: PropTypes.node
};

const FixedBarItem = ({ children }) => (
  <div className="FixedBar__item">{children}</div>
);

FixedBarItem.defaultProps = defaultProps;
FixedBarItem.propTypes = propTypes;

export default FixedBarItem;
