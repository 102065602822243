import api from 'services';
import { responseMessage } from 'lib/service-helpers';
import { transformParams, transformResponse } from 'services/properties';

/**
 * Pega lista de todas as reservas do imóvel
 * @param propertyId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = (propertyId) =>
  api.getList(`properties/${propertyId}/bookings`, {
    include: 'booking_user,closing_user,person',
  });

/**
 * Pega os dados de uma reserva
 * @param propertyId
 * @param bookingId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOne = (propertyId, bookingId) =>
  api.getOne(`properties/${propertyId}/bookings/${bookingId}`);

/**
 * Gerar uma nova reserva
 * @param property_id
 * @param values
 * @returns {Promise<AxiosResponse<any>>}
 */
export const create = ({ property_id, ...values }) =>
  api
    .create(`properties/${property_id}/bookings`, values)
    .then(responseMessage('Reserva adicionada'));

/**
 * Atualizar uma reserva
 * @param property_id
 * @param id
 * @param values
 * @returns {Promise<AxiosResponse<any>>}
 */
export const update = ({ property_id, id, ...values }) =>
  api
    .update(`properties/${property_id}/bookings/${id}`, values)
    .then(responseMessage('Reserva atualizada'));

/**
 * Fechar reserva
 * @param property_id
 * @param id
 * @param values
 * @returns {Promise<AxiosResponse<any>>}
 */
export const close = ({ property_id, id, ...values }) =>
  api
    .create(`properties/${property_id}/bookings/${id}/close`, values)
    .then(responseMessage('Reserva fechada'));

/**
 * Remover reserva (só admin pode remover)
 * @param property_id
 * @param id
 * @param values
 * @returns {Promise<AxiosResponse<any>>}
 */
export const remove = ({ property_id, id, ...values }) =>
  api
    .delete(`properties/${property_id}/bookings/${id}`, '', { data: values })
    .then(responseMessage('Reserva removida'));

/**
 * Retorna uma lista de imoveis
 * @param params
 */
export const getListBooked = (params = { filter: {} }) => {
  return api
    .getList('properties/bookings-active', {
      ...transformParams(params),
      include: 'person,booking_user',
    })
    .then(transformResponse);
};

/**
 * Retorna uma lista de imoveis
 * @param params
 */
export const getListBookedOld = (params = { filter: {} }) => {
  return api
    .getList('properties/bookings', {
      ...transformParams(params),
      include: 'person,booking_user',
    })
    .then(transformResponse);
};
