import React from 'react';
import { Wrapper, Text, Actions } from './styles';

function Item({ text, renderOptions }) {
  return (
    <Wrapper>
      <Text>{text}</Text>
      <Actions>{renderOptions && renderOptions()}</Actions>
    </Wrapper>
  );
}

export default Item;
