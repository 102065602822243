import React from 'react';
import { NavLink } from 'react-router-dom';
import { Item, Ball } from 'components/ListAside';

const Link = ({ isHidden, hasError, isDisabled, to, children }) => {
  if (isHidden) return null;

  return (
    <Item
      hasError={hasError}
      as={NavLink}
      to={to}
      activeClassName="isActive"
      isDisabled={isDisabled}
    >
      <Ball /> <div>{children}</div>
    </Item>
  );
};

export default Link;
