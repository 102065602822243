import { createContext, useContext, useMemo, useState } from 'react';
import * as contractsService from 'services/financialv2/contracts';
import { ITEM_TYPES } from 'services/financialv2/contracts';

export const ModalContractExtraContext = createContext({
  currentItems: {},
  item: '',
  quantity: 1,
  preview: {},
  preview2: {},
  setPreview2: () => null,
  handleClose: () => null,
  onSuccess: () => null,
  isLoading: false,
  isSuccess: false,
  hasError: false,
  setIsLoading: () => null,
  setIsSuccess: () => null,
  setHasError: () => null,
  changeDate: () => null,
});

export const ModalContractExtraProvider = ({
  currentItems,
  item,
  preview,
  handleClose,
  onSuccess,
  isLoading,
  isSuccess,
  hasError,
  setIsLoading,
  setIsSuccess,
  setHasError,
  children,
}) => {
  const [preview2, setPreview2] = useState(null);

  const changeDate = (quantity) => {
    return contractsService.changeDate({
      item,
      quantity,
    });
  };

  // Quantidade máxima de usuários/emails
  const quantity = useMemo(() => {
    if (item === ITEM_TYPES.USERS) {
      return currentItems.users_quantity;
    }

    if (item === ITEM_TYPES.EMAIL) {
      return currentItems.emails_quantity;
    }

    return 1;
  }, [currentItems, item]);

  return (
    <ModalContractExtraContext.Provider
      value={{
        currentItems,
        quantity,
        handleClose,
        item,
        preview,
        preview2,
        setPreview2,
        onSuccess,
        isLoading,
        isSuccess,
        hasError,
        setIsLoading,
        setIsSuccess,
        setHasError,
        changeDate,
      }}
    >
      {children}
    </ModalContractExtraContext.Provider>
  );
};

export const useModalContractExtra = () =>
  useContext(ModalContractExtraContext);
