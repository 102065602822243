import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip';
import _ from 'lodash';
import { Col, Row } from 'react-flexbox-grid';
// Components
import ListInfo from 'components/ListInfo';
import Label from 'components/Label';
import Header from './components/Header';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    property: PropTypes.shape({}).isRequired,
  }),
  handleClickDelete: PropTypes.func,
};
const defaultProps = {
  data: {},
  handleClickDelete: null,
};

const LabelItem = ({ children }) => (
  <Col xs={0}>
    <Label color="white">{children}</Label>
  </Col>
);
const ListLabel = ({ data, labelKey = 'title' }) =>
  _.size(data) ? (
    <Row className="row--5">
      {data.map((value, i) => (
        <LabelItem key={`${value}-${i}`}>{value[labelKey]}</LabelItem>
      ))}
    </Row>
  ) : null;

class CardProfile extends React.Component {
  componentDidMount() {
    Tooltip.rebuild();
  }

  get renderRooms() {
    const { data } = this.props;

    return (
      data.rooms && (
        <>
          {data.rooms.bedroom && (
            <ListInfo.item
              title={'Dormitório'}
              text={`${data.rooms.bedroom.value} ou mais`}
            />
          )}
          {data.rooms.suite && (
            <ListInfo.item
              title={'Suíte'}
              text={`${data.rooms.suite.value} ou mais`}
            />
          )}
          {data.rooms.garage && (
            <ListInfo.item
              title={'Garagem'}
              text={`${data.rooms.garage.value} ou mais`}
            />
          )}
        </>
      )
    );
  }

  get renderAreas() {
    const { data } = this.props;

    if (data.primary_area) {
      const { title, maximum, minimum, measure } = data.primary_area;
      let text = '';

      if (minimum) {
        text += maximum ? 'De ' : 'Mínimo ';
        text += `${minimum}${measure}`;
      }
      if (maximum) {
        text += minimum ? ' até ' : 'Até ';
        text += `${maximum}${measure}`;
      }

      return <ListInfo.item title={title} text={text} />;
    }

    return null;
  }

  get renderCondominium() {
    const { condominium } = this.props.data;
    if (!condominium) return null;

    return (
      <ListInfo.row>
        <ListInfo.item>
          <ListInfo.title>Condomínio</ListInfo.title>
          <ListLabel data={condominium} labelKey="name" />
        </ListInfo.item>
      </ListInfo.row>
    );
  }

  render() {
    const { data, isRemoving, handleClickDelete } = this.props;

    return (
      <ListInfo>
        <Header
          data={data}
          isRemoving={isRemoving}
          handleClickDelete={handleClickDelete}
        />
        <ListInfo.row>
          {data.expiry_date && (
            <ListInfo.item title="Ativo até" text={data.expiry_date} />
          )}
          {this.renderRooms}
          {this.renderAreas}
        </ListInfo.row>
      </ListInfo>
    );
  }
}

CardProfile.propTypes = propTypes;
CardProfile.defaultProps = defaultProps;

export default CardProfile;
