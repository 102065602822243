import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
`;

export const WrapImage = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${(3 / 4) * 100}%;

  img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const BtnDelete = styled.button`
  cursor: pointer;
  position: absolute;
  top: -12.5px;
  right: -12.5px;
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
  font-size: 16px;
  border-radius: 50%;
  color: #fff;
  background-color: ${p => p.theme.colors.danger};
`;

export const Content = styled.div`
  background: #ffffff;
  border: 1px solid #d3dceb;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 13px 15px 15px;

  .FormGroup {
    margin-bottom: 10px;
  }

  .FormGroup:last-child {
    margin-bottom: 0;
  }
`;
