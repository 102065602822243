import React, { useEffect, useMemo } from 'react';
import { SelectMultiple } from 'components/Form';
import { Field } from 'redux-form';
import * as S from './styles';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openModalStoreAccount } from '../../modals/ModalStoreAccount';
import { useGetAccounts } from '../../hooks/useAccounts';
import { parseSelect } from 'lib/formHelpers';

const valueRenderer = (account) => (
  <S.Wrapper>
    {account.icon ? <S.Icon src={account.icon.file_url} /> : null}
    <S.Title>{account.title}</S.Title>
  </S.Wrapper>
);

function FieldAccount({
  creatable = true,
  type = 1,
  label = 'Conta',
  name = 'account_id',
  options,
  excludeId,
  additionalOption,
  onCreate,
  onClickNew,
  ...props
}) {
  const dispatch = useDispatch();
  const { accounts, allAccounts, fetchAccounts } = useGetAccounts({ type });

  const handleNewAccount = (value) => {
    if (onClickNew) return onClickNew(value);

    dispatch(
      openModalStoreAccount({
        data: {
          title: value.title,
        },
        type: 1,
        canChangeType: true,
        onSuccess: (res) => {
          if (onCreate) onCreate(res);
        },
      })
    );
  };

  const groupedOptions = useMemo(() => {
    let filteredOptions = options ?? accounts;

    if (excludeId) {
      filteredOptions = filteredOptions.filter((item) => item.id !== excludeId);
    }

    if (additionalOption) {
      filteredOptions = [additionalOption, ...filteredOptions];
    }

    if (type === 'all') {
      return allAccounts;
    }

    return filteredOptions;
  }, [allAccounts, options, accounts, excludeId, additionalOption, type]);

  useEffect(() => {
    if (!options) {
      fetchAccounts({ limit: null });
    }
  }, [options]);

  const optionRenderer = (option) => {
    if (option.optGroup) {
      return (
        <S.OptionGroup
          style={{
            fontWeight: 'bold',
            color: '#333',
            backgroundColor: '#f0f0f0',
            padding: '8px 12px',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {option.title}
        </S.OptionGroup>
      );
    }

    return (
      <S.Wrapper>
        {option.icon ? <S.Icon src={option.icon.file_url} /> : null}
        <S.Title>{option.title}</S.Title>
      </S.Wrapper>
    );
  };

  const handleChange = ({ event, input }) => {
    // Ignora a seleção de opções de grupo
    if (event && event?.optGroup) {
      return;
    }

    input.onChange(event);
  };

  return (
    <Field
      creatable={creatable}
      onNewOptionClick={handleNewAccount}
      multi={false}
      name={name}
      label={label}
      component={SelectMultiple}
      options={groupedOptions}
      labelKey="title"
      valueKey="id"
      valueRenderer={valueRenderer}
      optionRenderer={optionRenderer}
      parse={parseSelect('id')}
      handleChange={handleChange} // Usa a função handleChange para ignorar seleções de optGroup
      {...props}
    />
  );
}

FieldAccount.propTypes = {
  type: PropTypes.oneOf([1, 2, 'all']),
};

export { FieldAccount };
