export default (values, props) => {
  const errors = {};

  if (props.property.reference === values.reference) {
    errors.reference = 'Essa referência já existe';
  }

  if (values.reference && values.reference.length > 19) {
    errors.reference = 'Máximo 19 caracteres';
  }

  return errors;
};
