import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// Components
import Label from 'components/Label';
// Services
import * as dealsService from 'services/deals';
// Hooks
import { useRequest } from 'hooks/useRequest';

const defaultProps = {
  dealId: null
};

const propTypes = {
  dealId: PropTypes.string.isRequired
};

function DealAudits({ dealId, params }) {
  const { data } = useRequest({
    request: _params => {
      return dealsService.getDealAudits(dealId, _params);
    },
    params
  });

  const renderItens = useMemo(() => {
    if (!data) return null;

    return data
      .filter(({ meta_data }) => !!meta_data?.new)
      .map(({ formatted_date, meta_data: { new: newData, old } }) => (
        <li className="TimelineLabel__item">
          <Label color={!!old ? 'default' : 'success'} size="small">
            {formatted_date}
          </Label>{' '}
          {newData}
        </li>
      ));
  }, [data]);

  return <ul className="TimelineLabel">{renderItens}</ul>;
}

DealAudits.defaultProps = defaultProps;
DealAudits.propTypes = propTypes;

export default DealAudits;
