import React from 'react';
import BoxHelp from 'components/BoxHelp';

// import { Wrapper } from './styles';

const TOPICS = [
  {
    text: 'Cadastrar imóvel',
    link: 'https://scribehow.com/shared/Cadastrar_imovel__98f-PV5DTImG2FgLzohneQ?referrer=documents',
    videoUrl: 'https://www.youtube.com/watch?v=z9ndlWzZ_Eo&ab_channel=Tecimob',
  },
  {
    text: 'Compartilhar imóveis pelo Whatsapp',
    link: 'https://scribehow.com/shared/Compartilhar_imovel_pelo_WhatsApp__TCSlhwP6Tm2QKwhuiOCBnw?referrer=documents',
  },
  {
    text: 'Pesquisar imóveis por características',
    link: 'https://scribehow.com/shared/Pesquisar_imoveis_por_caracteristicas__MNWPeEkXRbC4tNYUCPb9pw?referrer=documents',
  },
  {
    text: 'Editar informações do imóvel',
    link: 'https://scribehow.com/shared/Editar_informacoes_do_imovel__MhlSoS01QQutVAaBWShq1g?referrer=documents',
  },
  {
    text: 'Inativar e excluir um imóvel',
    link: 'https://scribehow.com/shared/Inativar_e_excluir_imovel__HYdcNw-dRd6b4tVJCS_Lgw?referrer=documents',
  },
];

function Help() {
  return (
    <BoxHelp
      storageKey="property-help"
      topics={TOPICS}
      helpLink="https://tecimob.com.br/ajuda/imoveis/"
      style={{
        marginTop: '25px',
      }}
    />
  );
}

export default Help;
