import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Components
import PropertyExcludedForm from './components/PropertyExcludedForm';
import ModalTemplate from 'components/Modal/components/ModalTemplate';
// Modules
import { openModalProperty } from 'modules/modal';
// Services
import * as propertiesService from 'services/properties';
import * as notesService from 'services/crm/notes';
import Can from 'containers/Can';
import { PROPERTY_PERMISSIONS } from 'constants/rules';
import { SubmissionError } from 'redux-form';

class ModalPropertyExcluded extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  handleClose = () => {
    this.props.openModalProperty({ property: this.props.property });
  };

  isExcluded() {
    const { status } = this.props.property;
    return status === 'Excluído';
  }

  handleSubmitPropertyExcludedForm = async (values) => {
    if (this.isExcluded()) {
      return propertiesService.removeProperty(this.props.property.id);
    }

    if (!values.note) {
      throw new SubmissionError({ note: 'Campo obrigatório' });
    }

    await notesService.create({
      property_id: this.props.property.id,
      note: values.note,
    });

    return propertiesService.inativeProperty(this.props.property.id);
  };

  handleSubmitSuccess = (...args) => {
    if (this.props.onSubmitSuccess) this.props.onSubmitSuccess(...args);
    this.props.handleClose();
  };

  get title() {
    if (this.isExcluded()) {
      return 'Excluir definitivamente';
    }

    return 'Inativar Imóvel';
  }

  render() {
    const { modalConfig, isOpen, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={this.handleClose}
      >
        <Can
          run={
            this.isExcluded()
              ? PROPERTY_PERMISSIONS.DELETE
              : PROPERTY_PERMISSIONS.TRASH
          }
          permissions={this.props.property.permissions}
          yes={() => (
            <ModalTemplate title={this.title} handleClose={this.handleClose}>
              <PropertyExcludedForm
                isExcluded={this.isExcluded()}
                onSubmit={this.handleSubmitPropertyExcludedForm}
                onSubmitSuccess={this.handleSubmitSuccess}
                handleClose={this.handleClose}
              />
            </ModalTemplate>
          )}
          no={() => (
            <ModalTemplate
              title="Sem permissão para deletar"
              handleClose={this.handleClose}
            >
              Essa área não está liberada nas suas permissões. Solicite que o
              administrador do sistema libere o acesso.
            </ModalTemplate>
          )}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  openModalProperty,
};

export default connect(null, mapDispatchToProps)(ModalPropertyExcluded);
