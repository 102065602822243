import api from 'services/index';

const baseProgress = buildingId =>
  `settings/sites/buildings/${buildingId}/progress`;

/**
 * Pega os dados dos progressos
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const get = buildingId => api.getList(baseProgress(buildingId));

/**
 * Atualiza os dados da sessão de progresso
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = ({ id, ...values }) =>
  api.patch(baseProgress(id), values);

// Remove um progresso
export const removeProgress = (buildingId, progressId) => {
  return api.delete(baseProgress(buildingId), progressId);
};

/**
 * Adiciona um progresso
 * @param buildingId
 * @param values
 * @return {Promise<AxiosResponse<any>>}
 */
export const createProgress = ({ buildingId, ...values }) =>
  api.create(baseProgress(buildingId), values);

/**
 * Atualiza um progresso
 * @param buildingId
 * @param values
 * @return {Promise<AxiosResponse<any>>}
 */
export const updateProgress = ({ buildingId, ...values }) =>
  api.update(baseProgress(buildingId), values);
