import React from 'react';
import { WrapperTable } from './styles';

// import from './styles';

const cols = [
  'Nome',
  'Celular',
  'E-mail',
  'Dt. Nascimento',
  'Tipo Pessoa',
  'CPF/CNPJ',
  'RG/IE',
  'Profissão',
  'Origem Captação',
  'CEP',
  'UF',
  'Cidade',
  'Bairro',
  'Logradouro',
  'Número',
  'Complemento',
  'Anotação'
];

function TablePreview({ data }) {
  return (
    <WrapperTable>
      <table className="Table">
        <thead>
          <tr>
            {cols.map(colname => (
              <th key={colname}>{colname}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => (
            <tr>
              {cols.map(colname => (
                <td key={colname + i}>{item[colname] || '--'}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </WrapperTable>
  );
}

TablePreview.propTypes = {};
TablePreview.defaultProps = {};

export default TablePreview;
