import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 15px;
`;
export const Content = styled.div`
  margin-top: 15px;
`;
export const Title = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const Text = styled.div`
  font-size: 12px;
`;
