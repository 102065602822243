import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
// Components
import FormDealGain from './components/FormDealGain';
// Modules
import { openModalPropertySell } from 'modules/modal';
import moment, { format } from 'lib/moment';
// Service
import * as dealsService from 'services/deals';
import PreviewMode from 'containers/ModalRoot/ModalPropertySell/components/PreviewMode';

class ModalPropertySell extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    deal: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    personId: PropTypes.string,
    userId: PropTypes.string,
    isPreview: PropTypes.bool,
    property: PropTypes.shape({
      id: PropTypes.string.isRequired,
      calculated_price: PropTypes.string.isRequired,
    }).isRequired,

    onSubmitSuccess: PropTypes.func,
    onSubmitFail: PropTypes.func,
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null,

    deal: null,
    personId: null,
    userId: null,
    isPreview: false,
    property: {
      id: null,
      calculated_price: null,
    },

    onSubmitSuccess: null,
    onSubmitFail: null,
  };

  get isEditting() {
    return this.props?.deal?.id;
  }

  get title() {
    if (this.isEditting) {
      return 'Editando negócio';
    }

    return 'Parabéns por ter fechado o negócio!';
  }

  onSubmit = (values) => dealsService.dealEarn(values);

  onSubmitSuccess = () => {
    const { onSubmitSuccess, handleClose } = this.props;
    if (onSubmitSuccess) onSubmitSuccess();
    handleClose();
  };

  onSubmitFail = () => {
    const { onSubmitFail } = this.props;
    if (onSubmitFail) onSubmitFail();
  };

  onCreatePerson = (person) => {
    const { values } = this.props;

    this.props.openModalPropertySell({
      initialValues: {
        ...values,
        people_id: person.id,
      },
    });
  };

  get initialValues() {
    const { deal, personId, userId, property, initialValues } = this.props;

    return (
      initialValues || {
        people_id: personId,
        property_id: property.id,
        user_id: userId || localStorage.getItem('current-user').id,
        calculated_price: property.calculated_price,
        remove_search_profile: false,
        remove_deals: false,
        earned_at: moment().format(format.date),
        ...deal,
      }
    );
  }

  render() {
    const {
      isPreview,
      modalConfig,
      isOpen,
      handleClose,
      modalType,
      property,
      deal,
    } = this.props;

    const initialValues = this.initialValues;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        {isPreview ? (
          <PreviewMode
            handleClose={handleClose}
            property={property}
            deal={deal}
          />
        ) : (
          <FormDealGain
            title={this.title}
            isEditting={this.isEditting}
            isPreview={isPreview}
            property={property}
            deal={deal}
            initialValues={this.initialValues}
            handleClose={handleClose}
            onCreatePerson={this.onCreatePerson}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
            onSubmitFail={this.onSubmitFail}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  values: getFormValues('FormDealGain')(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModalPropertySell,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalPropertySell);
