import React, { useMemo } from 'react';
import { Table, Title } from '../../styles';
import Options from '../Options';
import { getReceivingMethod } from 'lib/financial-helpers';
import { useDispatch } from 'react-redux';
import { openModalCancelInstallment } from 'modules/modal';
import { fetchCurrentContract } from 'modules/financial/currentCredit';
import { fetchContracts } from 'modules/financial/contracts';

function TablePayments({
  title = 'Pagamentos',
  data,
  values,
  contract,
  onCancel,
}) {
  const dispatch = useDispatch();

  const total = useMemo(() => {
    if (!data) return null;
    return values.final_price;
  }, [data]);

  const totalCreditPaid = useMemo(() => {
    return data?.filter((item) => item.situation === 'Pago').length || 0;
  }, [data]);

  const totalCreditOpen = useMemo(() => {
    return data?.filter((item) => item.situation === 'Aberto').length || 0;
  }, [data]);

  const totalCreditOverdue = useMemo(() => {
    return data?.filter((item) => item.situation === 'Vencido').length || 0;
  }, [data]);

  const canCancelInstallment = useMemo(() => {
    return totalCreditOpen > 0 || totalCreditOverdue > 0;
  }, [totalCreditOpen, totalCreditOverdue]);

  const openModal = (params) => {
    dispatch(
      openModalCancelInstallment({
        contract,
        ...params,
        onSuccess: () => {
          dispatch(fetchCurrentContract());
          dispatch(fetchContracts());
          if (onCancel) onCancel();
        },
      })
    );
  };

  const handleClickCancelOneInstallment = () => {
    openModal({ isOneInstallment: true, size: 300 });
  };

  const handleClickCancelInstallment = () => {
    openModal();
  };

  const getSituation = (credit) => {
    const situation = credit.situation;

    switch (situation) {
      case 'Cancelado':
        return <span className="h-color--danger">Cancelado</span>;
      case 'Aberto':
        return <span className="h-color--success">Aberto</span>;
      case 'Vencido':
        return <span className="h-color--danger">Vencido</span>;
      default:
        return (
          <span>
            {situation}
            {credit.installments > 1 ? ` em ${credit.installments}x` : null}
          </span>
        );
    }
  };

  if (!data || data.length <= 0) return null;

  return (
    <>
      <Title>{title}</Title>
      <Table>
        <thead>
          <tr>
            <th>Forma</th>
            <th>Vencimento</th>
            <th>Pagamento</th>
            <th className="Table__cell--money">Valor</th>
            <th>Situação</th>
            <th className="Table__cell--small">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((credit) => (
            <tr key={contract.id + credit.id}>
              <td>{getReceivingMethod(credit.receiving_method)}</td>
              <td>{credit.due_date}</td>
              <td>{credit.received_at}</td>
              <td className="Table__cell--money">{credit.price}</td>
              <td>{getSituation(credit)}</td>
              <td className="Table__cell--small">
                <Options contract={contract} credit={credit} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            {canCancelInstallment ? (
              <>
                <td colSpan={2}>
                  {(totalCreditOpen === 1 || totalCreditOverdue === 1) &&
                  data.length === 1 ? (
                    <a
                      href="#"
                      className="h-color--danger"
                      onClick={handleClickCancelOneInstallment}
                    >
                      Cancelar boleto
                    </a>
                  ) : (
                    <a
                      href="#"
                      className="h-color--danger"
                      onClick={handleClickCancelInstallment}
                    >
                      Cancelar parcelamento
                    </a>
                  )}
                </td>
              </>
            ) : (
              <td colSpan={2} />
            )}
            {data.length > 0 && (
              <>
                <td className="Table__cell--money">
                  <strong>Total</strong>
                </td>
                <td className="Table__cell--money">
                  <strong>{total}</strong>
                </td>
                <td colSpan={2} />
              </>
            )}
          </tr>
        </tfoot>
      </Table>
    </>
  );
}

export default TablePayments;
