import { useRequest } from 'hooks/useRequest';
import { changePreview2_2 } from 'services/financialv2/contracts';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractRemove/contexts/modalContractExtra';
import { useEffect } from 'react';

export const usePreview2 = () => {
  const { item, setPreview2 } = useModalContractExtra();

  const { data, isFetching } = useRequest({
    request: changePreview2_2,
    params: {
      item,
      quantity: 1,
      is_adding: false,
    },
  });

  useEffect(() => {
    setPreview2(null);
  }, []);

  useEffect(() => {
    setPreview2(data);
  }, [data]);

  return { data, isFetching };
};
