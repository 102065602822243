import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
// Components
import Errors from '../Errors';
import { Wrapper } from './styles';

const defaultProps = {
  id: null,
  input: null,
  onIcon: MdCheckBox,
  offIcon: MdCheckBoxOutlineBlank,
};

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  input: PropTypes.object,
  radioValue: PropTypes.any,
  onIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  offIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

function Checkbox({
  disabled,
  inputValue,
  input,
  meta,
  label,
  className,
  color,
  onIcon: OnIcon,
  offIcon: OffIcon,
  colorOn = '#000000',
  colorOff = '#000000',
  ...rest
}) {
  // verifica se tem erro o componente
  const hasError = !!(meta?.error && meta?.touched);

  let inputProps = {
    disabled,
  };

  if (inputValue) {
    const checked = input?.value.includes(inputValue);

    inputProps = {
      ...inputProps,
      checked,
      value: inputValue,
      onChange: (event) => {
        const arr = [...input.value];

        if (event.target.checked) {
          arr.push(inputValue);
        } else {
          arr.splice(arr.indexOf(inputValue), 1);
        }
        return input.onChange(arr);
      },
    };
  } else {
    inputProps = {
      ...inputProps,
      ...input,
    };
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      e.stopPropagation();

      inputProps.onChange(!input.value);
    }
  };

  return (
    <Wrapper
      {...rest}
      color={color}
      className={classnames('FormGroup', 'Checkbox', className, {
        'is-disabled': inputProps?.disabled,
        'has-error': hasError,
      })}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <input {...inputProps} type="checkbox" style={{ display: 'none' }} />
      {inputProps.checked ? (
        <OnIcon color={colorOn} />
      ) : (
        <OffIcon color={colorOff} />
      )}
      <span>{label}</span>
      <Errors meta={meta} />
    </Wrapper>
  );
}

Checkbox.defaultProps = defaultProps;
Checkbox.propTypes = propTypes;

export default Checkbox;
