import React, { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import {
  Content,
  Header,
  Title,
  Wrapper,
} from 'containers/GraphVisitsSite/styles';
import Filter from 'containers/GraphVisitsSite/components/Filter';
import moment, { format } from 'lib/moment';
import Item from 'containers/GraphVisitsSite/components/Graph/components/Item';
import * as Graph from 'containers/GraphVisitsSite/components/Graph/styles';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { Blur, Message, WrapperNoData } from './styles';
import Button from 'components/Button';
import { IoMdGlobe } from 'react-icons/all';
import shuffle from 'lodash/shuffle';
// import { Wrapper } from './styles';

const DATA = [
  { date_formated: 'jan 01', visitors: 300 },
  { date_formated: 'jan 02', visitors: 200 },
  { date_formated: 'jan 03', visitors: 400 },
  { date_formated: 'jan 04', visitors: 100 },
  { date_formated: 'jan 05', visitors: 200 },
  { date_formated: 'jan 06', visitors: 300 },
  { date_formated: 'jan 07', visitors: 400 },
  { date_formated: 'jan 08', visitors: 100 },
  { date_formated: 'jan 09', visitors: 250 },
  { date_formated: 'jan 10', visitors: 400 },
  { date_formated: 'jan 11', visitors: 500 },
  { date_formated: 'jan 12', visitors: 200 },
];

function Nodata() {
  const [data, setData] = useState(DATA);

  useEffect(() => {
    const interval = setInterval(() => {
      setData((d) => shuffle(d));
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Wrapper>
      <Loading isVisible={false} isFullComponent />
      <Header>
        <Title>Visitas no site</Title>
        <Filter
          disabled
          initialValues={{
            start_date: moment().subtract(8, 'days').format(format.date),
            end_date: moment().subtract(1, 'days').format(format.date),
          }}
        />
      </Header>
      <Content>
        <Graph.Wrapper>
          <Graph.Header>
            <Item title="VISITAS ÚNICAS" value={0} />
            <Item title="TOTAL DE VISITAS" value={0} />
            <Item title="VISUALIZAÇÕES" value={0} />
            <Item title="MÉDIA POR VISITA" value={0} />
            <Item title="MÉDIA DE TEMPO" value={0} />
          </Graph.Header>
          <WrapperNoData>
            <Graph.Content>
              <Message>
                Para visualizar os dados de acesso ao seu site, <br />é
                necessário ter um domínio próprio ativado
                <Button
                  color="tertiary"
                  to="/site/config/domains"
                  size="medium"
                  className="h-margin-top--15"
                >
                  <IoMdGlobe /> Configurar meu domínio
                </Button>
              </Message>
              <Blur>
                <ResponsiveContainer height={320}>
                  <AreaChart
                    data={data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#DCE1F3"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#DCE1F3"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis
                      // tick={CustomXAxisTick}
                      dataKey="date_formated"
                    />
                    <YAxis allowDecimals={false} />
                    <Area
                      label="Teste"
                      type="linear"
                      dataKey="visitors"
                      stroke="#576AC2"
                      fillOpacity={1}
                      strokeWidth={3}
                      fill="url(#colorUv)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Blur>
            </Graph.Content>
          </WrapperNoData>
        </Graph.Wrapper>
      </Content>
    </Wrapper>
  );
}

export default Nodata;
