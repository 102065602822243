import React, { useCallback, useEffect } from 'react';
// Components
import DealChart from './components/DealChart';
import DealTable from './components/DealTable';
import WrapperReport from '../../components/WrapperReport';
// Hooks
import { useReport } from '../hooks';
// Service
import { getDealPerBroker } from 'services/reports';
import Form from 'pages/Reports/components/FormInterval';
import moment from 'lib/moment';

export default function DealPerBroker() {
  const {
    data,
    chartData,
    isFetching,
    toggle,
    fetchData,
    isAllSelected,
    toggleAll,
    dataSelecteds,
  } = useReport({
    request: getDealPerBroker,
  });

  const handleSubmit = useCallback(
    (values) => {
      fetchData(values);
    },
    [fetchData]
  );

  useEffect(() => {
    const startDate = moment()
      .subtract(5, 'month')
      .startOf('month')
      .format('DD/MM/YYYY');

    const endDate = moment().endOf('month').format('DD/MM/YYYY');

    fetchData({
      period: 'previousMonth',
      start_date: startDate,
      end_date: endDate,
    });
  }, []);

  return (
    <WrapperReport
      title="Negócios ganhos por Corretor"
      text="Acompanhe o desempenho individual de cada corretor."
      data={data}
      isFetching={isFetching}
      renderForm={() => <Form onSubmit={handleSubmit} />}
      renderChart={() => (
        <DealChart width={'100%'} height={250} data={chartData} />
      )}
      renderTable={() => (
        <DealTable
          data={dataSelecteds}
          isAllSelected={isAllSelected}
          toggleAll={toggleAll}
          toggleChecked={toggle}
        />
      )}
    />
  );
}
