import React, { useContext } from 'react';
import { map, isEmpty } from 'lodash';
import { ContextCompare } from '../../index';
import { Cell, CellLabel } from '../Table/styles';
import RowDivider from '../RowDivider';
import { List } from './styles';
import { getSecondaryRooms } from 'lib/property-helpers';

const RowSecondaryRooms = () => {
  const { properties } = useContext(ContextCompare);

  return (
    <>
      <RowDivider />
      <tr>
        <CellLabel rowSpan={2}>
          Outros <br /> Cômodos
        </CellLabel>
        {properties.map(({ id, rooms }) => {
          const secondaryRooms = getSecondaryRooms(rooms);

          return (
            <Cell>
              <List>
                {!isEmpty(secondaryRooms)
                  ? map(secondaryRooms, ({ title_formated, name }) => {
                      return <li key={`${name}-${id}`}>{title_formated}</li>;
                    })
                  : '--'}
              </List>
            </Cell>
          );
        })}
      </tr>
    </>
  );
};

export default RowSecondaryRooms;
