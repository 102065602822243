import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  .FormControl {
    border-radius: 4px 0 0 4px;
    cursor: pointer;
  }

  .Button {
    border-radius: 0 4px 4px 0;
  }

  > button {
    width: 100%;
    display: flex;
  }
`;
