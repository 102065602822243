import api from '../index';
// Helpers
import { responseMessage } from 'lib/service-helpers';

const transformResponse = (res) => {
  if (!res.data) return res;

  res.data = res.data.map((domain) => {
    domain.email_url = `@${domain.raw_url}`;
    return domain;
  });

  return res;
};

export const getList = (params) =>
  api
    .getList('settings/domains', {
      ...params,
      sort: '-created_at',
    })
    .then(transformResponse);
export const getOne = (id) => api.getOne('settings/domains', id);
export const create = (values) =>
  api
    .create('settings/domains', values)
    .then(responseMessage('Domínio adicionado'));
export const update = (values) =>
  api
    .update('settings/domains', values)
    .then(responseMessage('Domínio alterado'));
export const remove = (id) =>
  api.delete('settings/domains', id).then(responseMessage('Domínio excluido'));

/**
 * Verifica se domínio é válido
 * @param url
 * @returns {Promise}
 */
export const checkDomain = (url) =>
  api.create('settings/domains/availabilities', { url });

/**
 * Seta um domínio como primário
 * @param id
 * @param values
 * @return {Promise}
 */
export const setDomainPrimary = (id, values = { id: null }) =>
  api
    .update(`settings/domains/${id}/primaries`, values)
    .then(responseMessage('Domínio alterado para primário'));

/**
 * Cria um registro de domínio pendentes
 * @param values
 * @param params
 * @return {Promise<any> | void | Thenable<any> | PromiseLike<T | never> | Promise<T | never>}
 */
export const createPendingRegister = (values, params) =>
  api
    .create('settings/domains/pending-registers', values, params)
    .then(responseMessage('Domínio registrado'));

/**
 * Força verificação de DNS
 * @param domain
 * @returns {Promise<AxiosResponse<T>>}
 */
export const verifyDNS = (domainId) =>
  api.create(`settings/domains/${domainId}/verify-dns`);

export const checkDomainHasMailboxes = async (domainId) => {
  try {
    const {
      data: { total },
    } = await api.get(
      `integrations/mail-server/mailboxes/${domainId}/has-accounts`
    );
    return !!total;
  } catch (err) {
    console.error(err);
    return false;
  }
};
