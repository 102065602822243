import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${(p) => p.theme.borderColor};
`;
export const Box = styled.div`
  flex-grow: 1;
  min-width: 1px;

  &:not(:first-child) {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid ${(p) => p.theme.borderColor};
  }
`;
export const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export const Table = styled.table`
  width: 100%;
`;
export const Label = styled.td`
  text-align: right;
`;
export const Value = styled.td`
  font-weight: bold;
  padding: 0 5px;
`;
