import React from 'react';
import { Text } from './styles';

const Price = ({ size = 14, children, transaction, className }) => {
  return (
    <Text size={size} transaction={transaction} className={className}>
      {children}
    </Text>
  );
};

export default Price;
