import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px;
  border-left: 4px solid ${(p) => p.theme.color};
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.05);
`;

export const Title = styled.div`
  font-size: 16px;
  color: #5c5260;
`;
export const Number = styled.div`
  font-size: 26px;
  font-weight: bold;
  text-align: right;
`;
