import React from 'react';
import { Field } from 'redux-form';
// Components
import { Input } from 'components/Form';
import Button from 'components/Button';
import ListOptions from 'components/ListOptions';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import styled from 'styled-components';
import { DragIcon } from 'pages/Site/pages/ContentSite/pages/Posts/components/TablePosts/styles';

const Container = styled.div`
  border-top: 1px solid ${(p) => p.theme.borderColor};
`;

const Handle = styled.div`
  cursor: grab;
  margin-top: -5px;
  margin-right: 4px;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">
    <DragIcon />
  </Handle>
));

const SortableItem = SortableElement(({ fields, field, i, style, ...rest }) => (
  <ListOptions.item {...rest} style={{ ...style, listStyle: 'none' }}>
    <div className="h-flex h-flex--center-center">
      <RowHandler />
      <span className="h-margin-right--15">Link do Vídeo</span>
      <div className="h-flex__cell--grow">
        <Field name={field} component={Input} />
      </div>
      <Button
        color="danger"
        onClick={() => {
          fields.remove(i);
        }}
        className="h-margin-left--15"
      >
        Remover
      </Button>
    </div>
  </ListOptions.item>
));

const SortableList = SortableContainer(({ fields, style, ...rest }) => (
  <Container>
    {fields.map((field, index) => (
      <SortableItem
        i={index}
        index={index}
        key={`item-${index}`}
        field={field}
        style={style}
        fields={fields}
        {...rest}
      />
    ))}
  </Container>
));

const VideosField = ({ fields, style, ...rest }) => (
  <>
    <SortableList
      lockAxis="y"
      useDragHandle
      fields={fields}
      axis="y"
      onSortEnd={({ oldIndex, newIndex }) => {
        fields.move(oldIndex, newIndex);
      }}
      style={style}
      {...rest}
    />
    <ListOptions.item
      style={style}
      title={
        <Button color="secondary" onClick={() => fields.push([''])}>
          Adicionar Outro Vídeo
        </Button>
      }
    />
  </>
);

export default VideosField;
