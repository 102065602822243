import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled(NavLink)`
  display: block;
  position: relative;
  padding: 5px 5px 5px 23px;
  cursor: pointer;
  color: #1c0c1e;

  :after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    position: absolute;
    left: -3px;
    top: 12px;
    background: #0084f4;
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    z-index: 5;
  }

  &.active {
    color: #0084f4;

    :after {
      visibility: visible;
      opacity: 1;
    }
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
