import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const defaultProps = {
  children: null,
  className: null
};

const WrapperContainer = ({ children, className, ...rest }) => (
  <div className={classnames('Wrapper__container', className)} {...rest}>
    {children}
  </div>
);

WrapperContainer.propTypes = propTypes;
WrapperContainer.defaultProps = defaultProps;

export default WrapperContainer;
