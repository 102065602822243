import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Label from 'components/Label';
import Button from 'components/Button';
import Check from 'components/Check';
import { plural } from 'lib/text';
import { getPropertyStatus } from 'lib/property-helpers';

class PropertyItem extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    property: PropTypes.shape({
      domain: PropTypes.string,
      status: PropTypes.number,
      statusLabel: PropTypes.string,
      main: PropTypes.bool,
    }),
  };

  static defaultProps = {
    property: {},
  };

  /**
   * Retorna o número do apartamento
   */
  get apartmentNumber() {
    const { property } = this.props;
    return _.get(property, 'informations.apartment_number.value') || '--';
  }

  get garage() {
    const { property } = this.props;
    return _.get(property, 'rooms.garage.value') || '--';
  }

  get totalArea() {
    try {
      const {
        property: {
          areas: { total_area },
        },
      } = this.props;
      return total_area.value + total_area.measure;
    } catch {
      return '-';
    }
  }

  get privateArea() {
    try {
      const {
        property: {
          areas: { private_area },
        },
      } = this.props;
      return private_area.value + private_area.measure;
    } catch {
      return '-';
    }
  }

  get builtArea() {
    try {
      const {
        property: {
          areas: { built_area },
        },
      } = this.props;
      return built_area.value + built_area.measure;
    } catch {
      return '-';
    }
  }

  get renderBedrooms() {
    const { property } = this.props;
    const bedroom = _.get(property, 'rooms.bedroom.value');
    const suite = _.get(property, 'rooms.suite.value');

    let text = '--';

    if (bedroom) {
      text = bedroom;
    }

    if (suite) {
      text += plural(
        suite,
        ` (sendo ${suite} suíte)`,
        ` (sendo ${suite} suítes)`,
        false
      );
    }

    return <td>{text}</td>;
  }

  get renderCheck() {
    const { property, canSelect, toggleSelected } = this.props;

    if (property.is_blocked) {
      return (
        <td>
          <Label color="danger">Bloqueado</Label>
        </td>
      );
    }

    // Verifica se pode selecionar
    if (!canSelect) return null;

    return (
      <td>
        <Check
          checked={property.isChecked}
          onClick={(e) => {
            e.stopPropagation();
            toggleSelected(property.id);
          }}
        />
      </td>
    );
  }

  render() {
    const {
      editted,
      handleOpenProperty,
      property,
      property: { reference, calculated_price },
    } = this.props;

    const status = getPropertyStatus(property);

    return (
      <tr
        className={classnames('h-hover h-pointer', {
          'Table__row--selected': editted,
        })}
        onClick={handleOpenProperty(property)}
      >
        {this.renderCheck}
        <td>{this.apartmentNumber}</td>
        <td>
          {reference}{' '}
          {status.length > 0 &&
            status.map((item) => {
              if (item.text !== 'Reservado') return null;

              return (
                <span className={`h-color--${item.color}`}> | {item.text}</span>
              );
            })}
        </td>
        {this.renderBedrooms}
        <td>{this.garage}</td>
        <td>{this.builtArea}</td>
        <td>{this.privateArea}</td>
        <td>{this.totalArea}</td>
        <td>{calculated_price}</td>
        <td width={1}>
          <Button color="secondary" colorText="white" size="small">
            Ver imóvel
          </Button>
        </td>
      </tr>
    );
  }
}

export default PropertyItem;
