import React, { createContext, useContext, useMemo, useState } from 'react';
import map from 'lodash/map';
import pages from './pages';
import { useTabs } from 'pages/EnterpriseStore/hooks/useTabs';
import { DEFAULT_PAGE } from 'pages/EnterpriseStore/constants';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';

export const EnterpriseStoreContext = createContext({
  isNextPage: null,
  setIsNextPage: null,
  enterpriseId: null,
  currentPage: null,
  pages: null,
  pagesArray: null,
  setPage: null,
  nextPage: null,
  prevPage: null,
  hasPrev: null,
  hasNext: null,
  previewUrl: null
});

export const EnterpriseStoreProvider = ({ enterpriseId, children }) => {
  const { primary_domain } = useCurrentRealEstate();

  const [isNextPage, setIsNextPage] = useState(false);

  const { page, setPage, nextPage, prevPage, hasPrev, hasNext } = useTabs({
    defaultPage: DEFAULT_PAGE,
    pages: map(pages, (page, key) => key)
  });

  const pagesArray = useMemo(() => {
    return map(pages, (page, key) => ({
      ...page,
      key
    }));
  }, [pages]);

  const previewUrl = useMemo(() => {
    return `${primary_domain?.url}/hotsite/${enterpriseId}?with-non-actives=true`;
  }, [primary_domain, enterpriseId]);

  return (
    <EnterpriseStoreContext.Provider
      value={{
        previewUrl,
        enterpriseId,
        currentPage: page,
        pages,
        pagesArray,
        setPage,
        nextPage,
        prevPage,
        hasPrev,
        hasNext,
        isNextPage,
        setIsNextPage
      }}
    >
      {children}
    </EnterpriseStoreContext.Provider>
  );
};

export const useEnterprise = () => {
  return useContext(EnterpriseStoreContext);
};
