// import * as S from './styles'

import { openModal } from 'modules/modal';
import { ModalTemplate } from 'components/Modal';
import Modal from 'react-modal';
import FormMovimentPay from './components/FormMovimentPay';
import { useMovimentPay, useShowMoviment } from '../../hooks/useMoviments';
import { useEffect, useMemo } from 'react';

export const openModalMovimentPay =
  ({ movimentId, postingId, onSuccess }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalMovimentPay',
        size: 470,
        position: 'center',
        props: {
          movimentId,
          postingId,
          onSuccess,
        },
      })
    );
  };

export function ModalMovimentPay({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  movimentId,
  postingId,
  onSuccess,
}) {
  const { handlePayMoviment } = useMovimentPay();
  const { moviment, movimentIsFetching, fetchMoviment } = useShowMoviment();

  useEffect(() => {
    if (movimentId && postingId) {
      fetchMoviment({ postingId, movimentId });
    }
  }, [movimentId, postingId]);

  const initialValues = useMemo(() => {
    return {
      movimentId,
      postingId,
      account_id: moviment.account_id,
      value: moviment.value,
      billing_date: moviment.billing_date,
    };
  }, [moviment]);

  if (movimentIsFetching) return false;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Confirmar pagamento" handleClose={handleClose}>
        <FormMovimentPay
          initialValues={initialValues}
          onSubmit={handlePayMoviment}
          handleClose={handleClose}
          onSubmitSuccess={() => {
            if (onSuccess) onSuccess(moviment);
            handleClose();
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}
