import { useState } from 'react';

const useCheckbox = (initialState = []) => {
  const [checkedItems, setCheckedItems] = useState(initialState);

  // Função para alternar o estado de marcação de um item com base no seu ID
  const handleToggle = (id) => {
    setCheckedItems((prevState) => {
      if (prevState.includes(id)) {
        // Se o item já estiver marcado, remove-o da lista de itens marcados
        return prevState.filter((itemId) => itemId !== id);
      } else {
        // Se o item não estiver marcado, adiciona-o à lista de itens marcados
        return [...prevState, id];
      }
    });
  };

  // Função para alternar o estado de marcação de todos os itens
  const handleToggleAll = (data) => {
    if (checkedItems.length === data.length) {
      // Se todos os itens estiverem marcados, desmarca todos
      setCheckedItems([]);
    } else {
      // Se nem todos os itens estiverem marcados, marca todos
      const allIds = data.map((item) => item.id);
      setCheckedItems(allIds);
    }
  };

  // Função para verificar se um item específico está marcado com base no seu ID
  const isChecked = (id) => checkedItems.includes(id);

  // Função para verificar se todos os itens estão marcados
  const isAllItemsChecked = (data) => {
    return data.every((item) => checkedItems.includes(item.id));
  };

  // Funcão para limpar todos os itens que estão marcados no momento
  const clear = () => {
    setCheckedItems([]);
  };

  return {
    checkedItems,
    handleToggle,
    handleToggleAll,
    isChecked,
    isAllItemsChecked,
    clear,
  };
};

export default useCheckbox;
