import React from 'react';
import PropTypes from 'prop-types';
import { MdEdit } from 'react-icons/md';
// Components
import Popover from 'components/Popover';
import FormUpdateDocument from '../../components/FormUpdateDocument';
// Services
import * as propertiesService from 'services/properties';

class UpdateDocumentName extends React.PureComponent {
  onSubmitSuccess = (...rest) => {
    const { onSuccess } = this.props;

    // Invoca a funcão quando da certo o submit
    if (onSuccess) onSuccess(...rest);
  };

  render() {
    const { children } = this.props;

    return (
      <Popover
        onSubmit={({ name }) => {
          const { documentId, propertyId } = this.props;
          return propertiesService.updateDocument({
            id: documentId,
            property_id: propertyId,
            name,
          });
        }}
        onSubmitSuccess={this.onSubmitSuccess}
        component={FormUpdateDocument}
        initialValues={{
          name: children,
        }}
      >
        <div className="h-link">
          {children} <MdEdit />
        </div>
      </Popover>
    );
  }
}

UpdateDocumentName.defaultProps = {
  documentId: null,
  propertyId: null,
  onSuccess: null,
};

UpdateDocumentName.propTypes = {
  documentId: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

export default UpdateDocumentName;
