import React, { useContext, useEffect } from 'react';
import { Wrapper } from './styles';
import { AnimatePresence } from 'framer-motion';
import { MenusContext } from './context';

export { MenusContext };

function Menus() {
  const { Menu, back } = useContext(MenusContext);

  useEffect(() => {
    return () => {
      back();
    };
  }, []);

  return (
    <Wrapper>
      <AnimatePresence>
        <Menu />
      </AnimatePresence>
    </Wrapper>
  );
}

export default Menus;
