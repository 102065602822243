import Alert from 'react-s-alert';
import { useRequest } from 'hooks/useRequest';
import { getRealEstate, updateGuru } from 'services/realEstate';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';

export const useGuru = () => {
  const dispatch = useDispatch();

  const { data: realEstate, fetchData } = useRequest({
    request: getRealEstate,
    initialState: {},
  });

  const disconnect = () => {
    dispatch(
      openConfirmation({
        title: 'Desconectar do portal GURU?',
        text: 'Ao desconectar o portal, nenhum imóvel seu será enviado.',
        request: () => updateGuru(false),
        onSuccess: () => {
          Alert.success('Portal GURU desconectado');
          fetchData();
        },
      })
    );
  };

  const connect = async () => {
    await updateGuru(true);
    Alert.success('Portal GURU conectado');
    fetchData();
  };

  return {
    isConnected: realEstate?.with_guru_portal,
    disconnect,
    connect,
  };
};
