import { responseMessage } from 'lib/service-helpers';
import api, { apiStorage } from 'services/index';

/**
 * Pega as informações de site relacionadas ao rodapé
 * @param params
 * @return {Promise}
 */
export const getOneConfig = (params) =>
  api.getOne('settings/sites/super-highlights', '', params);

export const updateConfig = (values) =>
  api.patch('settings/sites/super-highlights/config', values);

/**
 * Faz upload da imagem do super destaque pro storage
 * @param id
 * @param image
 * @returns {Promise<boolean|AxiosResponse<any>>}
 */
export const uploadImage = async ({ id, image }) => {
  if (!image) return true;
  return apiStorage.uploadFile(`sites/super-highlights/${id}`, { image });
};

/**
 * Faz upload da imagem do super destaque pro storage
 * @param id
 * @param image
 * @returns {Promise<boolean|AxiosResponse<any>>}
 */
export const uploadImageMobile = async ({ id, image_mobile }) => {
  if (!image_mobile) return true;
  return apiStorage.uploadFile(`sites/super-highlights/${id}/mobile`, {
    image: image_mobile,
  });
};

/**
 * Pega as informações de site relacionadas ao rodapé
 * @param params
 * @return {Promise}
 */
export const getOne = ({ id }) =>
  api.getOne('settings/sites/super-highlights', id);

/**
 * Adiciona um novo super destaque
 * @param values
 * @returns {Promise<AxiosResponse<any>>}
 */
export const create = (values) =>
  api
    .create('settings/sites/super-highlights', values)
    .then(responseMessage('Super Destaque adicionado'));

/**
 * Edita um super destaque
 * @param values
 * @returns {Promise<AxiosResponse<any>>}
 */
export const update = (values) =>
  api
    .update('settings/sites/super-highlights', values)
    .then(responseMessage('Super Destaque editado'));

/**
 * Adiciona ou cria outro super destaque
 * @param image
 * @param image_mobile
 * @param values
 * @returns {Promise<AxiosResponse<*>>}
 */
export const createOrUpdate = async ({ image, image_mobile, ...values }) => {
  if (values.id) {
    await uploadImage({ id: values.id, image });
    await uploadImageMobile({ id: values.id, image_mobile });
    return update(values);
  }

  const res = await create({
    ...values,
    opens_link_on_page:
      values.opens_link_on_page === 'true' ||
      values.opens_link_on_page === true,
  });

  if (res.data.id) {
    await uploadImage({ id: res.data.id, image });
    await uploadImageMobile({ id: res.data.id, image_mobile });
  }

  return res;
};

/**
 * Remove um super destaque
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const remove = ({ id }) =>
  api
    .delete('settings/sites/super-highlights', id)
    .then(responseMessage('Super destaque removido'));

/**
 * Retorna os modelos de rodapé
 * @param params
 * @return {Promise}
 */
export const getComponents = (params) =>
  api.getList('settings/component-examples/super-highlights', params);
