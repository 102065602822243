import React from 'react';
import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import { openModalCreditsChatGpt } from 'modules/modal';
import { useDispatch } from 'react-redux';
import TableSort from 'components/TableSort';
import { useRequest } from 'hooks/useRequest';
import { getHistory } from 'services/openai';
import Nodata from 'components/Nodata';

function ModalChatGptExtract({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  onSuccess,
}) {
  const { data } = useRequest({
    request: getHistory,
  });
  const dispatch = useDispatch();

  const handleClickBuyCredits = () => {
    dispatch(
      openModalCreditsChatGpt({
        onSuccess,
      })
    );
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Extrato de consumo" handleClose={handleClose}>
        {data?.length > 0 ? (
          <TableSort
            data={data}
            renderHeader={() => (
              <tr>
                <th align="left">Data</th>
                <th align="left">Tipo de crédito</th>
                <th align="left">Usuário</th>
                <th align="left">Imóvel</th>
                <th align="left">Tom</th>
              </tr>
            )}
            renderItem={(item) => (
              <tr>
                <td>{item.created_at}</td>
                <td>{item.type}</td>
                <td>{item.user}</td>
                <td>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-link"
                  >
                    {item.reference}
                  </a>
                </td>
                <td>{item.tone}</td>
              </tr>
            )}
          />
        ) : (
          <Nodata
            image={null}
            title="Nenhum consumo"
            text="Você ainda não utilizou o ChatGPT nenhuma vez"
          />
        )}
        <ModalFooter>
          <Button color="white" onClick={handleClose}>
            Voltar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button color="caution" onClick={handleClickBuyCredits}>
            Comprar créditos
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalChatGptExtract;
