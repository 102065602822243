import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
// Components
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import { DetailItem } from 'components/DetailList';
import Gallery from 'components/Gallery';
import Block from '../../Block';
import PrivateData from './PrivateData';
import PriceBar from './components/PriceBar';
import RoomsAndMeasures from './components/RoomsAndMeasures';
// Modules
import { openModalPerson } from 'modules/modal';
import {
  currentPageGallerySelector,
  gallerySelector,
  menuGalleryThunks,
} from 'modules/modalProperty';
// Helpers
import { PROPERTY_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';
import DetailsProperty from 'containers/ModalRoot/ModalProperty/pages/Record/components/DetailsProperty';
import { whatsappSendUrl } from 'lib/share-helpers';
import { getFirstName, getName } from 'lib/text';
import { currentUserSelector } from 'modules/login';

const defaultProps = {
  setPage: null,
};

const propTypes = {
  setPage: PropTypes.func,
};

const getAreaReducedName = ({ title, name }) => {
  if (name === 'ground_total_area') return 'Terreno Área Total';

  switch (title) {
    case 'Terreno Fundo':
      return 'Fundo';
    case 'Terreno Esquerda':
      return 'Esquerda';
    case 'Terreno Direita':
      return 'Direita';
    case 'Terreno Frente':
      return 'Frente';
    default:
      return title;
  }
};

export const TableRow = ({ title, value }) => {
  return value ? (
    <tr className="table__row">
      <td className="table__cell h5">{title}</td>
      <td className="table__cell h-color--primary-light">{value}</td>
    </tr>
  ) : null;
};

class Record extends Component {
  /**
   * Lida com a mudança de galeria
   * @param page
   */
  handleChangePage = (page) => {
    this.props.setPage(page);
  };

  // Renderiza um item na lista
  renderItem = (item) => {
    return (
      <Block.item>
        {item.value} {item.title}
      </Block.item>
    );
  };

  textBedrooms = () => {
    const { rooms } = this.props.infos;

    if (rooms) {
      return `${rooms.bedrooms && rooms.bedrooms.value}${
        rooms.suites && ` sendo ${rooms.suites.value} suítes`
      }`;
    }
  };

  get property() {
    const { infos, property } = this.props;
    return infos || property;
  }

  get isOnNetwork() {
    return this.property.is_on_network;
  }

  get renderRealtor() {
    const { currentUser } = this.props;

    const property = this.property;

    // Verifica se o imóvel está na rede
    // se estiver precisa de uns dados extras de contato
    if (this.isOnNetwork) {
      const cellphone = _.get(property, 'user.cellphone');
      const url = _.get(property, 'real_estate.primary_domain.url');
      const rawUrl = _.get(property, 'real_estate.primary_domain.raw_url');
      const ownerName = _.get(property, 'user.name');
      const propertyUrl = `${url}/detalhes/imovel-da-rede/${property.id}`;

      return (
        <div className="h-flex h-flex--center-center h-margin-bottom--15">
          <Avatar
            image={_.get(property, 'user.file_url')}
            text={ownerName}
            className="h-margin-right--10"
          />
          <div className="h-flex__cell h-flex__cell--grow">
            <h5>{getName(ownerName)}</h5>
            <p>CRECI {_.get(property, 'user.creci')}</p>
            {url && (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="h-link"
              >
                {rawUrl}
              </a>
            )}
          </div>
          {cellphone && (
            <Button
              color="success"
              target="_blank"
              tagName="a"
              href={whatsappSendUrl(
                cellphone,
                `Olá ${getFirstName(ownerName)}, sou o corretor ${
                  currentUser.name
                } e quero conversar sobre este imóvel que encontrei na Rede Guru: ${propertyUrl}`
              )}
            >
              Conversar
            </Button>
          )}
        </div>
      );
    }

    return (
      <div className="h-flex h-flex--center-center h-margin-bottom--15">
        <Avatar
          image={_.get(property, 'user.file_url')}
          text={_.get(property, 'user.name')}
          className="h-margin-right--10"
        />
        <div className="h-flex__cell h-flex__cell--grow">
          <h5>Corretor Responsável</h5>
          <p>{_.get(property, 'user.name')}</p>
        </div>
      </div>
    );
  }

  get canShowGallery() {
    const {
      infos: { images, video, tour_360 },
    } = this.props;
    return (images && images.length > 0) || video || tour_360;
  }

  get renderGallery() {
    const {
      gallery,
      infos: { video, tour_360 },
      infos,
    } = this.props;

    if (!this.canShowGallery) return null;

    return (
      <Gallery
        property={infos}
        data={gallery}
        video={video}
        tour={tour_360}
        style={{ marginTop: '30px' }}
        onChangeVisibility={(isOpen) => {
          this.props.changeGalleryVisibility(isOpen);
        }}
      />
    );
  }

  get renderAreas() {
    const { areas } = this.property;

    return _.map(areas, (area) => (
      <DetailItem
        iconName="crop-free"
        name={getAreaReducedName(area)}
        text={`${area.value} ${area.measure}`}
      />
    ));
  }

  render() {
    const { infos } = this.props;
    const property = this.property;

    return (
      <div>
        {this.renderGallery}
        <PriceBar property={infos} />
        <RoomsAndMeasures property={infos} />

        <Row>
          <Col xs={7}>
            <Block
              type="list"
              title="Características do imóvel"
              items={infos.characteristics}
              itemRenderer={this.renderItem}
            />
            <Block
              keyExtractor={(item) => item.name}
              type="list"
              title="Proximidades"
              items={infos.establishments}
              itemRenderer={this.renderItem}
            />
            {infos?.condominium?.id ? (
              <Block
                isHtml
                type="text"
                title={infos.condominium.title}
                text={infos.condominium.description}
                videoUrl={infos.condominium.video}
              />
            ) : null}
            {infos.condo_characteristics && (
              <Block
                type="list"
                title="Características do condomínio"
                items={infos.condo_characteristics}
                itemRenderer={({ title, quantity }) => (
                  <Block.item>
                    {quantity && `${quantity} `}
                    {title}
                  </Block.item>
                )}
              />
            )}

            {infos.is_exchangeable && (
              <Block
                type="text"
                title="Descrição da permuta"
                text={infos.exchange_note}
              />
            )}
            {_.get(infos, 'informations.lot_description') && (
              <Block
                type="text"
                title="Descrição do lote"
                text={_.get(infos, 'informations.lot_description.value')}
              />
            )}
            <Block
              isHtml
              type="text"
              title="Descrição do imóvel"
              text={infos.description}
            />
            <Can
              run={PROPERTY_PERMISSIONS.PRIVATE}
              permissions={property.permissions}
            >
              <Block
                type="map"
                title="Localização"
                property={infos}
                location={{
                  country: infos.country,
                  state: infos.state,
                  city: infos.city,
                  neighborhood: infos.neighborhood,
                  address_formatted: infos.address_formatted,
                  street_address: infos.street_address,
                  street_number: infos.street_number,
                  zip_code: infos.zip_code,
                  complement_address: infos.complement_address,
                }}
                isExactPlace={infos.is_exact_map_shown}
                position={{
                  lat: parseFloat(infos.maps_latitude),
                  lng: parseFloat(infos.maps_longitude),
                }}
                pov={{
                  heading: infos.maps_heading,
                  pitch: infos.maps_pitch,
                  zoom: infos.maps_zoom,
                }}
              />
            </Can>

            {(!property.is_on_network ||
              property?.network_type === 'Órulo' ||
              property?.network_type === 'DWV') && (
              <PrivateData
                data={property}
                handleOpenMyself={this.props.handleOpenMyself}
                openModalPerson={this.props.openModalPerson}
              />
            )}
          </Col>

          <DetailsProperty props={this.props} property={this.props.infos} />
        </Row>
      </div>
    );
  }
}

Record.defaultProps = defaultProps;
Record.propTypes = propTypes;

const mapStateToProps = (state) => ({
  currentUser: currentUserSelector(state),
  currentGallery: currentPageGallerySelector(state),
  gallerySelector: gallerySelector(state),
});

const mapDispatchToProps = {
  setPage: menuGalleryThunks.setPage,
  openModalPerson,
};

export default connect(mapStateToProps, mapDispatchToProps)(Record);
