import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { compose } from 'redux';
import { Field, initialize, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import Input from 'components/Form/components/Input';
import Textarea from 'components/Form/components/Textarea';
import ListOptions from 'components/ListOptions';
import Container from 'pages/Site/components/ContainerSite';
// Assets
import seo from 'containers/ModalRoot/ModalPropertyPublicationBkp/components/FormSeo/seo.png';
// Validate
import validate from './validate';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const SeoForm = ({ handleSubmit, submitting, pristine }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Container padding>
        <ListOptions>
          <ListOptions.item>
            <Row middle="xs">
              <Col xs={8}>
                <Row>
                  <Field
                    xs={12}
                    label={
                      <>
                        Título da página{' '}
                        <a
                          href="https://support.google.com/webmasters/answer/35624?hl=pt-BR"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h-link"
                        >
                          (saiba mais)
                        </a>
                      </>
                    }
                    name="title"
                    placeholder="Digite o título"
                    component={Input}
                    maxLength={120}
                    className="h-margin-bottom--15"
                  />
                  <Field
                    xs={12}
                    type="simple"
                    label="Descrição"
                    name="meta_description"
                    placeholder="Digite a descrição"
                    maxLength={156}
                    component={Textarea}
                  />
                </Row>
              </Col>
              <Col xs={4}>
                <img
                  src={seo}
                  alt=""
                  style={{ marginLeft: '20px', width: 'calc(100% - 20px)' }}
                />
              </Col>
            </Row>
          </ListOptions.item>
        </ListOptions>
        <FixedBar style={{ left: 240 }}>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar
          </Button>
        </FixedBar>
      </Container>
    </form>
  );
};

SeoForm.defaultProps = defaultProps;
SeoForm.propTypes = propTypes;

export default compose(
  reduxForm({
    form: 'SeoForm',
    validate,
    onSubmitSuccess: (res, dispatch) => {
      // Reinicializa o formulário com os valores
      dispatch(initialize('SeoForm', res.data, false));
    },
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
  })
)(SeoForm);
