import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ListOptions from 'components/ListOptions';
import { FieldBool, Select, ColorPicker, Slider } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import PreviewImage from 'components/PreviewImage';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import { TYPES } from 'services/sites/logos';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function Form({ logo, handleSubmit, position, autofill, handleClose }) {
  const has_logo_padding = useFormValue('has_logo_padding');
  const logo_opacity = useFormValue('logo_opacity');
  const background_color = useFormValue('background_color');
  const logo_padding = useFormValue('logo_padding');

  return (
    <form onSubmit={handleSubmit}>
      <div className="h-flex h-flex--center">
        <PreviewImage
          size={[270, 140]}
          padding={logo_padding}
          url={logo?.file_url}
          background={background_color}
          opacity={logo_opacity}
        />
      </div>

      <ListOptions>
        <ListOptions.item
          title="Adicionar borda?"
          renderOptions={() => (
            <>
              <FieldBool
                name="has_logo_padding"
                onChange={() => {
                  autofill('logo_padding', 5);
                }}
              />
            </>
          )}
        />
        {has_logo_padding && (
          <ListOptions.item
            title="Tamanho da borda:"
            renderOptions={() => (
              <Field
                buttonTemplate
                type="number"
                component={Select}
                name="logo_padding"
                options={[
                  { label: 'Pequeno', value: 5 },
                  { label: 'Médio', value: 10 },
                  { label: 'Grande', value: 15 }
                ]}
              />
            )}
          />
        )}
        <ListOptions.item
          title="Cor do fundo"
          renderOptions={() => (
            <Field
              canClear
              name="background_color"
              component={ColorPicker}
              placeholder="Transparente"
            />
          )}
        />
        {position === TYPES.HEADER && (
          <ListOptions.item
            title="Opacidade da logo"
            renderOptions={() => (
              <div style={{ width: 200 }}>
                <Field
                  name="logo_opacity"
                  min={0}
                  max={100}
                  component={Slider}
                />
              </div>
            )}
          />
        )}
      </ListOptions>
      <ModalFooter>
        <Button type="button" onClick={handleClose} color="white">
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

export default reduxForm({
  form: 'FormModalLogoConfig',
  enableReinitialize: true
})(Form);
