export const checkIfExistis = (property, properties) => {
  return properties.findIndex((p) => p.id === property.id);
};

export default class LastProperties {
  constructor(data, limit = 10) {
    this._data = data;
    this._limit = limit;
  }

  get data() {
    return [...this._data];
  }

  add(property) {
    // verificar se a pesquisa atual já existe na lista
    let index = checkIfExistis(property, this._data);

    if (index !== -1) {
      // se a pesquisa já existe, remove ela do array
      this._data.splice(index, 1);
    }

    // adiciona a nova pesquisa ao início do array
    this._data.unshift({ ...property });

    if (this._data.length > this._limit) {
      this._data.pop();
    }
  }
}
