import React from 'react';
import { Financial, Header, Panel } from 'features/Financial';
import * as S from './styles';
import { useMonthlyReport } from '../../hooks/useMonthlyReport';
import moment, { format } from 'lib/moment';
import { BoxData } from './components/BoxData';
import { FilterMonthly } from './components/FilterMonthly';
import { Chart } from './components/BarChart';
import { Nodata } from './Nodata';

export function Monthly() {
  const { monthly, monthlyChart, fetchMonthly, isFetchingMonthly } =
    useMonthlyReport();

  const noData = monthly.length <= 0;

  const initialValues = {
    start_date: moment().startOf('month').format(format.date),
    end_date: moment().endOf('month').format(format.date),
  };

  return (
    <>
      <Header.Header>
        <Header.Title>Relatórios</Header.Title>
        <Header.Actions>
          <FilterMonthly
            initialValues={initialValues}
            onSubmit={fetchMonthly}
            onChange={fetchMonthly}
          />
        </Header.Actions>
      </Header.Header>
      <Financial.Content isFetching={isFetchingMonthly}>
        {noData ? (
          <Nodata />
        ) : (
          <>
            <Panel.Wrapper>
              <Panel.Header>
                <Panel.Title>Receitas X Despesas</Panel.Title>
              </Panel.Header>
              <Panel.Content>
                <S.Charts>
                  <Chart data={monthlyChart} />
                </S.Charts>
              </Panel.Content>
            </Panel.Wrapper>

            <BoxData data={monthly} />
          </>
        )}
      </Financial.Content>
    </>
  );
}
