import React, { useMemo, useCallback } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
// Components
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import Container from 'components/Container';
import Button from 'components/Button';
import TablePeople from 'components/TablePeople';
import TextLoading from 'components/TextLoading';
// Hooks
import useSearch from 'hooks/useSearch';
import { useRequest } from 'hooks/useRequest';
// Services
import * as peopleService from 'services/people';
// Helpers
import { plural } from 'lib/text';
// Modules
import { openModalPersonAdd, openModalPerson, openModalPersonRemove } from 'modules/modal';

function PeopleSearchProfile() {
  const dispatch = useDispatch();
  const filter = useSearch(false);
  const { data, fetchData, isFetching, meta, changePage } = useRequest({
    request: peopleService.searchPeopleByProfile,
    params: {
      limit: 10,
      offset: 1,
      filter: {
        ...filter,
        by_actives: true
      }
    }
  });

  const queryString = useMemo(() => {
    const token = localStorage.getItem('access_token');
    return qs.stringify({ filter, token });
  }, [filter]);

  const isLoading = useMemo(() => {
    return isFetching && isEmpty(data);
  }, [isFetching, data]);

  const title = useMemo(() => {
    if (isLoading) {
      return <TextLoading width={150} height={14} />;
    }

    const total = meta?.pagination?.total;

    if (total > 0) {
      return `${plural(total, 'cliente encontrado', 'clientes encontrados')}`;
    }

    return 'Nenhum Resultado';
  }, [meta, isLoading]);

  const handleClickEdit = useCallback(person => {
    dispatch(
      openModalPersonAdd(person, () => {
        fetchData();
      })
    );
  }, []);

  const handleClickRemove = useCallback(async currentPerson => {
    dispatch(
      openModalPersonRemove({
        id: currentPerson?.id,
        onSuccess: fetchData
      })
    );
  }, []);

  const exportLink = useMemo(() => {
    const apiUrl = process?.env?.REACT_APP_API_URL;

    return `${apiUrl}/api/people/xlsx-by-search-profiles?${queryString}`;
  }, [queryString]);

  return (
    <Wrapper>
      <MainTitle
        title={title}
        text="Encontramos esses clientes com perfil compatível a sua pesquisa."
      >
        <Button to="/profile/search" color="white" colorText="secondary">
          Refazer pesquisa
        </Button>
        <Button tagName="a" href={exportLink} target="_blank">
          Exportar clientes
        </Button>
      </MainTitle>
      <Container padding>
        <TablePeople
          isFetching={isLoading}
          noData={{
            title: 'Nenhum cliente encontrado',
            text: 'Por favor tente refazer a busca',
            children: (
              <Button className="h-margin-top--10" to="/profile/search">
                Refazer pesquisa
              </Button>
            )
          }}
          data={data}
          pagination={meta?.pagination}
          onClickPerson={person => {
            dispatch(openModalPerson(person, { initialPage: 'SearchProfile' }));
          }}
          onPageChange={({ selected }) => {
            changePage(selected + 1);
          }}
          onClickEdit={handleClickEdit}
          onClickRemove={handleClickRemove}
        />
      </Container>
    </Wrapper>
  );
}

export default PeopleSearchProfile;
