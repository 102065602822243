import React from 'react';
// Components
import Button from 'components/Button';
import Nodata from 'components/Nodata';
import UploadButton from 'components/UploadButton';
import Container from 'pages/Site/components/ContainerSite';
// Assets
import noData from 'assets/img/noData/popup.svg';

const propTypes = {};
const defaultProps = {};

const PopupNoData = ({ handleUploadPopUp }) => (
  <Container>
    <Nodata
      image={noData}
      title="Você ainda não enviou nenhuma pop-up."
      text={
        <>
          Que tal começar agora{' '}
          <strong>(recomendado: 1200px de largura e 600px de altura)</strong>
        </>
      }
    >
      <UploadButton onDrop={handleUploadPopUp} className="h-margin-top--15">
        <Button color="success">Enviar Imagem</Button>
      </UploadButton>
    </Nodata>
  </Container>
);

PopupNoData.propTypes = propTypes;
PopupNoData.defaultProps = defaultProps;

export default PopupNoData;
