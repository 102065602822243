import api from '../index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Atualiza o responsável pelo financeiro
 * @param financial_user_id
 * @return {Promise<AxiosResponse<*>>}
 */
export const updateFinancialUser = ({ financial_user_id }) =>
  api
    .patch('sales/user', { financial_user_id })
    .then(responseMessage('Usuário responsável atualizado'));
