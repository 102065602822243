import { createValidation } from 'lib/validate-helpers';
import { validatePassword } from 'lib/password-helpers';

export default values => {
  const errors = {};

  const validations = createValidation(values, errors);

  validations.required(['name', 'email', 'password', 'password_confirm']);

  if (
    (values.password_confirm || '').length >= (values.password || '').length
  ) {
    validations.password(['password', 'password_confirm']);
  }

  if (values.password) {
    if (values.password.length < 3) {
      errors.password = 'Tem que ter pelo menos 3 dígitos';
    }
  }

  if (values.password_confirm) {
    if (values.password_confirm.length < 3) {
      errors.password_confirm = 'Tem que ter pelo menos 3 dígitos';
    }
  }

  const { password_confirm, password } = validatePassword({
    password: values.password,
    passwordConfirm: values.password_confirm
  });

  errors.password = password;
  errors.password_confirm = password_confirm;

  return errors;
};
