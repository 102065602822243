import styled from 'styled-components';
import CustomImage from 'components/Image';
import { Text as Price } from 'components/Price/styles';

export const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  align-self: stretch;
`;

export const Image = styled(CustomImage)`
  width: 74px;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

export const Content = styled.div`
  ${Price} {
    display: block;
    margin-top: 3px;
  }
`;

export const Title = styled.h2`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
`;

export const Text = styled.div`
  font-size: 12px;
  color: #272727;
`;
