export const CRECI = [
  {
    value: 'Menos de 1 ano',
    label: 'Menos de 1 ano',
  },
  {
    value: 'Entre 1 e 5 anos',
    label: 'Entre 1 e 5 anos',
  },
  {
    value: 'Mais de 5 anos',
    label: 'Mais de 5 anos',
  },
  {
    value: 'Ainda não tenho',
    label: 'Ainda não tenho',
  },
];

export const TYPE_PERSONS = [
  {
    value: '1',
    label: 'Física',
  },
  {
    value: '2',
    label: 'Juridica',
  },
];

/**
 * Usado pra renderizar as options booleanas
 */
export const BOOL = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não', _color: 'danger' },
];

/**
 * Mostrar se o em-ail é protegido ou visível
 */
export const TYPE_SHOW_EMAIL = [
  { value: true, label: 'Protegido' },
  { value: false, label: 'Visível', _color: 'danger' },
];

/**
 * Perfil de interesse
 */
export const PROFILE = [
  { value: 1, label: 'Residencial' },
  { value: 2, label: 'Comercial' },
  { value: 3, label: 'Residencial/Comercial' },
  { value: 4, label: 'Industrial' },
  { value: 5, label: 'Rural' },
  { value: 6, label: 'Temporada' },
];

/**
 * Situacão do imóvel
 */

export const SITUATION = [
  { value: 7, label: 'Pronto para morar' },
  { value: 6, label: 'Em construção' },
  { value: 4, label: 'Lançamento' },
  { value: 9, label: 'Breve lançamento' },
  { value: 5, label: 'Na planta' },
  { value: 1, label: 'Novo' },
  { value: 8, label: 'Reformado' },
  { value: 3, label: 'Semi-novo' },
  { value: 13, label: 'Indefinido' },
  { value: 2, label: 'Usado' },

  { value: 10, label: 'Com vegetação' },
  { value: 11, label: 'Desnivelado' },
  { value: 14, label: 'Planta nativa' },
  { value: 15, label: 'Pronto para construir' },
  { value: 16, label: 'Semi-aterrado' },
];

export const SITUATIONS = {
  'de2b549c-3b7b-48c4-9a66-2ca36a0e8b06': 'Pronto para morar',
  'd0e9401d-5596-4af2-905b-5a7f50ffddfd': 'Em construção',
  'a5d9de20-ea2d-42f2-a84e-0130ed48e8ee': 'Lançamento',
  '9b80b3ba-2083-4782-aa91-7f5f258c1d43': 'Breve lançamento',
  'df582dd4-169d-465d-8492-a2b2aaab68c9': 'Na planta',
  '24cd263d-539d-426c-b88d-61e684d249ab': 'Novo',
  '559f648c-c273-4930-935d-6686fe2674b5': 'Reformado',
  'eb9348c6-4f38-4312-a526-d0c5747b961e': 'Semi-novo',
  '21a4b3af-6cc4-420c-80e9-88aca8154d65': 'Indefinido',
  'd43494bb-7ca9-4a00-a7ff-e6da7bc24cca': 'Usado',
  'c8d83cf1-c472-4c33-93c2-4a74f6df6537': 'Com vegetação',
  'e323543a-71b1-421b-a3d9-7e4bef218d25': 'Desnivelado',
  '54120a10-7121-4c60-8a16-d6493a3907fa': 'Planta nativa',
  '43dccb1b-2bc1-4b49-88d4-21e40b686329': 'Pronto para construir',
  'ca39e75c-54fb-4545-a6cf-c4d1ba0e1373': 'Semi Aterrado',
};

/**
 * Tipo do terreno
 */
export const LAND_TYPE = [
  { value: 1, label: 'Plano' },
  { value: 2, label: 'Aclive' },
  { value: 3, label: 'Declive' },
];

/**
 * Próximo ao mar
 */
export const NEAR_SEA = [
  { value: 0, label: 'Vista para o mar' },
  { value: 1, label: 'Frente para o mar' },
  { value: 2, label: 'Quadra do mar' },
  { value: 3, label: 'Próximo ao mar' },
];

export const CONDO_POSITION = [
  { value: 1, label: 'Frente' },
  { value: 2, label: 'Fundos' },
  { value: 3, label: 'Lateral' },
];

export const TYPE_TRANSACTION = [
  { value: '1', label: 'Venda' },
  { value: '2', label: 'Aluguel' },
];

export const CURRENCY_SYMBOL = [
  { label: '$ - Dólar (USD)', value: '$' },
  { label: '€ - Euro (EUR)', value: '€' },
  { label: '¥ - Iene (JPY)', value: '¥' },
  { label: '£ - Libra (GBP)', value: '£' },
  { label: 'CUB', value: 'CUB' },
];

export const TYPE_NETWORK = {
  Guru: {
    color: '#7001ac',
  },
  Órulo: {
    color: '#0063C0',
  },
};
