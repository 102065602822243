import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
// Modules
import { localAccountsReducer, localAccountsSelector } from './containers/LocalAccounts/module';
import { accountsReducer, accountsSelector } from './containers/Accounts/module';
import { aliasReducer } from './containers/Alias/module';

export const hasDataSelector = createSelector(
  [localAccountsSelector.getAll, accountsSelector.getAll],
  (localAccounts, accounts) => {
    return localAccounts.length !== 0 || accounts.length !== 0;
  }
);

export default combineReducers({
  localAccounts: localAccountsReducer,
  accounts: accountsReducer,
  alias: aliasReducer
});
