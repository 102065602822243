import React, { useCallback, useMemo } from 'react';
import { Row } from 'react-flexbox-grid';
import { getFormValues, reduxForm } from 'redux-form';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { LevelOfInterest, Property } from 'components/Form';
import FieldStages from 'containers/FieldStages';
import FieldSelectPeople from 'containers/FieldPerson';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { openModalAddDeal } from 'modules/modal';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useCan } from 'hooks/useCan';
import FieldAllStages from 'containers/FieldAllStages';
import { parseSelect } from 'lib/formHelpers';
//import * as crm from 'services/crm';

const Form = ({
  funnelId,
  userId,
  onSuccess,
  initialize,
  handleSubmit,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const values = useSelector((state) => getFormValues('ModalAddDeal')(state));
  const canOtherPersonDeal = useCan({ run: 'OTHER_PERSON_DEAL' });

  const onCreatePerson = useCallback(
    (person) => {
      const valuesForm = JSON.parse(
        sessionStorage.getItem('@tecimob/create_person')
      );

      dispatch(
        openModalAddDeal({
          initialValues: {
            ...valuesForm,
            people_id: person?.id,
            user_id: userId,
          },
          onSuccess,
        })
      );

      sessionStorage.removeItem('@tecimob/create_person');
    },
    [dispatch]
  );

  let filterUsers = useMemo(() => {
    if (!canOtherPersonDeal)
      return {
        user_id: userId || currentUser?.id,
      };

    return {};
  }, [canOtherPersonDeal, userId, currentUser]);

  const onClickNew = useCallback(() => {
    sessionStorage.setItem('@tecimob/create_person', JSON.stringify(values));
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <ModalTemplate title="Adicionar negócio" handleClose={handleClose}>
        <p className="h-margin-bottom--15">
          Para adicionar um negócio manualmente ao CRM, preencha os campos:
        </p>
        <Row>
          {!funnelId ? (
            <FieldAllStages
              label="Posição"
              xs={6}
              parse={parseSelect('stage_id')}
            />
          ) : (
            <FieldStages
              label="Posição"
              xs={6}
              userId={userId}
              funnelId={funnelId}
            />
          )}
          <LevelOfInterest xs={6} name="rate" />
        </Row>
        <Row>
          <FieldSelectPeople
            xs={12}
            onClickNew={onClickNew}
            onCreatePerson={onCreatePerson}
            filter={filterUsers}
          />
        </Row>
        <Row>
          <Property label="Encontre o imóvel" xs={12} />
        </Row>
        <ModalFooter>
          <Button color="white" colorText="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <div className="h-flex__cell--grow" />
          <Button type="submit" color="success">
            Adicionar Negócio
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </form>
  );
};

export default reduxForm({
  form: 'ModalAddDeal',
  enableReinitialize: true,
})(Form);
