import styled from 'styled-components';

export const WrapperPrice = styled.div`
  text-align: right;
`;

export const Price = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #666666;
`;

export const Link = styled.a`
  font-size: 12px;
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: underline;
`;

export const Icon = styled.img`
  width: 36px;
  height: 36px;
`;

export const Text = styled.p`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: 14px;
`;

export const Date = styled.p`
  font-size: 14px;
  color: #666666;
`;
