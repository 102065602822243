import styled from "styled-components";

export const AmountUnits = styled.span`
  font-weight: bold;
`;

export const OptionsWrapper = styled.td`
  padding: 15px;
  width: 1%;

  .Button {
    white-space: nowrap;
  }

  div:first-child {
    margin-bottom: 5px;
  }
`;
