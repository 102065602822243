// Services
import * as propertiesService from '../../../../services/properties';
// Modules
import dataReducer from '../../../../modules/data';

// Constants
export const MODULE_NAME = 'propertyRevision';

export const {
  reducer: propertyRevisionReducer,
  actionCreators: propertyRevisionActions
} = dataReducer(`propertyDashboard/${MODULE_NAME}`);

// Selectors
export const dataSelector = state => state.propertyDashboard[MODULE_NAME].data;
export const isFetchingSelector = state =>
  state.propertyDashboard[MODULE_NAME].meta.isFetching;

// Reducer
export const fetchPropertyRevision = (params = { filter: {} }) => dispatch => {
  dispatch(propertyRevisionActions.request());

  return propertiesService
    .getList({
      limit: 10,
      offset: 1,
      ...params,
      filter: { ...params.filter, by_to_review_in: 10 }
    })
    .then(({ data }) => {
      dispatch(propertyRevisionActions.receive(data));
      return data;
    });
};

export const fetchAlterProperty = params => dispatch =>
  propertiesService.alterProperty(params).then(({ data }) => {
    fetchPropertyRevision()(dispatch);
    return data;
  });

/**
 * Submit do formulário de renovação
 * @param values
 */
export const handleSubmitRenew = values => dispatch =>
  fetchAlterProperty({
    id: values.id,
    next_review_at: values.next_review_at
  })(dispatch);

/**
 * Desativa a renovação
 * @param values
 */
export const handleDisableRenovation = property => dispatch =>
  fetchAlterProperty({
    id: property.id,
    next_review_at: null
  })(dispatch);

export default propertyRevisionReducer;
