import React from 'react';
import CardCondominium from 'components/CardCondominium';
import { Col, Row } from 'react-flexbox-grid';
import { PROPERTIES } from '../../fakeData';

function CardCondoPage() {
  return (
    <Row>
      {PROPERTIES.map(property => (
        <Col xs={6}>
          <CardCondominium property={property} />
        </Col>
      ))}
    </Row>
  );
}

CardCondoPage.defaultProps = {};
CardCondoPage.propTypes = {};

export default CardCondoPage;
