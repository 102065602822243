import FieldSituation from 'containers/FieldSituation';
import { Row } from 'react-flexbox-grid';
import { useTypeId } from './useTypeId';

const SituationsField = ({ typeOrSubtypeId, type, onChange }) => {
  const typeId = useTypeId(typeOrSubtypeId);

  return (
    <Row>
      <FieldSituation
        multi
        xs={12}
        typeId={typeOrSubtypeId ? typeId : type?.type_id}
        onChange={onChange}
      />
    </Row>
  );
};

export default SituationsField;
