import React from 'react';
import Clipboard from 'react-clipboard.js';
import Button from 'components/Button';
import { Wrapper } from './styles';
import Alert from 'react-s-alert';

function InputCopy({
  label,
  value,
  successMsg = 'Copiado para área de transferencia',
}) {
  return (
    <div className="FormGroup">
      <label className="FormLabel">{label}</label>
      <Wrapper>
        <Clipboard
          data-clipboard-text={value}
          onClick={() => {
            if (successMsg) Alert.success(successMsg);
          }}
        >
          <input readOnly value={value} className="FormControl" />
          <Button color="success">Copiar</Button>
        </Clipboard>
      </Wrapper>
    </div>
  );
}

export default InputCopy;
