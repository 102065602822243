import React from 'react';
import PropTypes from 'prop-types';
import Check from '../../../../../../components/Check';
import { Legend } from '../../../styles';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    reason: PropTypes.string,
    data: PropTypes.array
  })
};

const defaultProps = {
  data: {}
};

const calcTotal = (total, current) => total + current.value;

const DealItem = ({
  toggleChecked,
  data,
  data: { reason, data: periods, isSelected, color }
}) => (
  <tr role="button" className="h-hover h-pointer" onClick={toggleChecked(data)}>
    <td className="h-sticky--left h-text-nowrap h-flex h-flex--center-center">
      <Check checked={isSelected} className="h-margin-right--15" />
      <Legend color={color} />
      {reason}
    </td>
    {periods.map((period, index) => (
      <td key={`values-${index}-${period.value}`}>{period.value}</td>
    ))}
    <td className="h-sticky--right">{periods.reduce(calcTotal, 0)}</td>
  </tr>
);

DealItem.propTypes = propTypes;
DealItem.defaultProps = defaultProps;

export default DealItem;
