import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #d3dceb;
`;
export const Content = styled.div`
  max-width: ${props => props.maxContentWidth || '322px'};

  .Button {
    margin-left: 0;
  }
`;
export const Title = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: #1c0c1e;
  margin-bottom: 10px;
`;
export const Text = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #1c0c1e;
  margin-bottom: 10px;
`;
