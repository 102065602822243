import styled from 'styled-components';
import ListOptions from 'components/ListOptions';

export const List = styled(ListOptions)`
  img {
    width: 50px !important;
    height: 41px;
    object-fit: contain;
  }
`;
