import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Components
import PropertyMultipleForm from './components/PropertyMultipleForm';
import ModalTemplate from 'components/Modal/components/ModalTemplate';
// Services
import * as propertiesService from 'services/properties';
import { plural } from 'lib/text';
import Loading from 'components/Loading';
import TextLoading from 'components/TextLoading';

function ModalPropertyMultipleExcluded({
  propertiesId,
  onSubmitSuccess,
  modalConfig,
  isOpen,
  modalType,
  handleClose
}) {
  const [reasons, setReasons] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const allowedProperties = useMemo(
    () => propertiesId?.length - reasons?.length,
    [reasons, propertiesId]
  );

  // Verifica se pode ou não remover os imóveis
  const canRemove = useMemo(() => allowedProperties > 0, [allowedProperties]);

  // Monta o título da modal
  const title = useMemo(() => {
    if (isFetching) {
      return <TextLoading width={200} height={19} />;
    }
    if (allowedProperties === 0) {
      return 'Você não pode remover nenhum imóvel';
    }
    return `Inativar ${plural(allowedProperties, 'imóvel', 'imóveis')}`;
  }, [isFetching, allowedProperties]);

  // Lida com o submit do formulário
  const handleSubmit = useCallback(() => {
    return propertiesService.inativeMultipleProperties(propertiesId);
  }, [propertiesId]);

  //
  const handleSubmitSuccess = useCallback(() => {
    if (onSubmitSuccess) onSubmitSuccess();
    handleClose();
  }, [onSubmitSuccess]);

  const fetchCanDeleteProperties = useCallback(async () => {
    setIsFetching(true);
    const { data } = await propertiesService.canInativeProperties(propertiesId);
    setReasons(data);
    setIsFetching(false);
  }, [propertiesId]);

  useEffect(() => {
    fetchCanDeleteProperties();
  }, [propertiesId]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={isFetching}
        title={title}
        handleClose={handleClose}
      >
        {isFetching ? (
          <Loading isBlock />
        ) : (
          <PropertyMultipleForm
            handleClose={handleClose}
            allowedProperties={allowedProperties}
            reasons={reasons}
            propertiesId={propertiesId}
            canRemove={canRemove}
            onSubmit={handleSubmit}
            onSubmitSuccess={handleSubmitSuccess}
          />
        )}
      </ModalTemplate>
    </Modal>
  );
}

ModalPropertyMultipleExcluded.defaultProps = {
  propertiesId: [],
  handleClose: null
};

ModalPropertyMultipleExcluded.propTypes = {
  propertiesId: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ModalPropertyMultipleExcluded;
