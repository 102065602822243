import { useRequest } from 'hooks/useRequest';
import api from 'services';
import { useEffect } from 'react';

export const useCount = ({ start_date, end_date, funnel_id, user_id }) => {
  const {
    data: countPeople,
    fetchData: fetchPeople,
    isFetching: isFetchingPeople,
  } = useRequest({
    request: (params) => api.getList('crm/dashboard/count-people', params),
    initialFetch: false,
  });

  const {
    data: countDeals,
    fetchData: fetchDeals,
    isFetching: isFetchingDeals,
  } = useRequest({
    request: (params) => api.getList('crm/dashboard/count-deals', params),
    initialFetch: false,
  });

  useEffect(() => {
    if (funnel_id && start_date && end_date) {
      const payload = { user_id, start_date, end_date, funnel_id };

      fetchDeals(payload);
      fetchPeople(payload);
    }
  }, [funnel_id, start_date, end_date, user_id]);

  return { countPeople, isFetchingPeople, countDeals, isFetchingDeals };
};
