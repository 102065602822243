import styled from 'styled-components';
import Container from 'components/Container';

export const Wrapper = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: 40px;
  align-items: center;
  margin-top: 50px;
`;

export const Aside = styled.div`
  width: 358px;

  h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
  }

  .Button {
    margin-top: 30px;
  }
`;
export const BoxImage = styled.div``;
