// libs
import { isValidYoutubeUrl } from '../../lib/text';
import { createValidation } from 'lib/validate-helpers';

export default (values) => {
  const errors = {};

  const validations = createValidation(values, errors);

  validations.required([
    'reference',
    'people_id',
    'user_id',
    'subtype_id',
    'profile',
    'situation_id',
    'country_id',
    'state_id',
    'city_id',
    'neighborhood_id',
    'price',
  ]);

  if (values.reference && values.reference.length > 19) {
    errors.reference = 'Máximo 19 caracteres';
  }

  if (values.price && values.price === '0,00') {
    errors.price = 'O preço precisa ser maior que 0.';
  }

  if (values.is_commission_combined && !values.negotiation_note) {
    errors.negotiation_note = 'Campo obrigatório';
  }

  if (values.is_exchangeable) {
    validations.required(['taxes_permuta', 'exchange_note']);
  }

  if (values.video && !isValidYoutubeUrl(values.video || '')) {
    errors.video = 'Url do video não é valida';
  }

  return errors;
};
