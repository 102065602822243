import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Modules
import {
  getStages,
  handleDragEnd,
  handleDragStart,
  handleRemoveDeal,
  isDraggingSelector,
  isFetchingSelector,
  stagesSelector,
  loadMore,
} from 'modules/pipeline';
// Components
import Header from '../Header';
import Pipeline from 'components/Pipeline';
import { openModalPerson } from 'modules/modal';
import * as dealsService from 'services/deals';

function PipelineContainer({ funnelId }) {
  const dispatch = useDispatch();
  const stages = useSelector(stagesSelector);
  const pipelineIsFetching = useSelector(isFetchingSelector);
  const pipelineIsDragging = useSelector(isDraggingSelector);

  // Busca a pipeline
  const getPipeline = useCallback(
    async (filter) => {
      dispatch(getStages({ id: funnelId }, { filter }));
    },
    [dispatch, funnelId]
  );

  const _handleDragStart = useCallback(
    (...args) => {
      dispatch(handleDragStart(...args));
    },
    [dispatch]
  );

  const _handleDragEnd = useCallback(
    (...args) => {
      dispatch(handleDragEnd(...args));
    },
    [dispatch]
  );

  const _handleDelete = useCallback(
    (stageId, dealId, indexDeal) => {
      dispatch(handleRemoveDeal(stageId, dealId, indexDeal));
    },
    [dispatch]
  );

  const _handleClickDeal = useCallback(
    (deal) => {
      dispatch(
        openModalPerson({
          ...deal.person,
          afterClose: () => {
            // Atualiza a pipeline quando fecha a modal de cliente
            getPipeline();
          },
        })
      );
    },
    [dispatch, getPipeline]
  );

  const _handleCloseDeal = useCallback(
    async (dealId, stageId) => {
      try {
        if (dealId) {
          await dealsService.gainDeal(stageId, dealId);
        }
      } finally {
        getPipeline();
      }
    },
    [getPipeline]
  );

  const _handleClickLoss = useCallback(
    (dealId, stageId) => async () => {
      try {
        await dealsService.gainDeal(stageId, dealId);
      } finally {
        getPipeline();
      }
    },
    [getPipeline]
  );

  const _loadMore = useCallback(
    (stageId) => () => {
      dispatch(loadMore(stageId));
    },
    []
  );

  useEffect(() => {
    getPipeline({ onMount: true });
  }, []);

  return (
    <div className="PipelineWrapper">
      <Header onAddDeal={getPipeline} />

      <Pipeline
        isDragging={pipelineIsDragging}
        isLoading={pipelineIsFetching}
        funnelId={funnelId}
        stages={stages}
        handleDragStart={_handleDragStart}
        handleDragEnd={_handleDragEnd}
        handleClickDeleteDeal={_handleDelete}
        handleClickDeal={_handleClickDeal}
        onCloseDeal={_handleCloseDeal}
        onClickLoss={_handleClickLoss}
        updateListStages={getPipeline}
        onAfterClosePerson={getPipeline}
        loadMore={_loadMore}
      />
    </div>
  );
}

PipelineContainer.defaultProps = {};
PipelineContainer.propTypes = {};

export default PipelineContainer;
