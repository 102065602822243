import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend } from 'recharts';
import { COLORS_HEXADECIMAL } from 'constants/config';

const PropertyChart = ({
  width,
  height,
  data,
  meta,
  colors,
  selected,
  toggleChecked
}) => {
  const _types = useMemo(() => {
    try {
      if (!selected || selected.length <= 0) return meta.types;

      return meta.types.filter(type => selected.includes(type));
    } catch (err) {
      return meta.types;
    }
  }, [selected, meta]);

  return (
    <div style={{ width, height }}>
      <ResponsiveContainer>
        <BarChart
          width={600}
          height={300}
          data={meta?.graphPeriods}
          margin={{ left: 25, right: 25 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" allowDuplicatedCategory={false} />
          <YAxis />
          <Tooltip cursor={{ fill: '#f0f2f5' }} />
          <Legend />
          {_types.map((type, index) => (
            <Bar
              dataKey={type}
              key={type}
              fill={COLORS_HEXADECIMAL[index % COLORS_HEXADECIMAL.length]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PropertyChart;
