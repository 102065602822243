import React from 'react';
import { Wrapper, Page, Title, Nodata } from './styles';
import { useFacebookLeads } from '../../context';
import ListFormsFacebook from '../ListFormsFacebook';

function ListPagesFacebook({ change }) {
  const { pages } = useFacebookLeads();

  if (!pages || pages?.length === 0) {
    return <Nodata>Nenhuma página encontrada</Nodata>;
  }

  return (
    <Wrapper>
      {pages.map((page, i) => (
        <Page key={page.id}>
          <Title>{page.name}</Title>
          <ListFormsFacebook page={page} indexPage={i} change={change} />
        </Page>
      ))}
    </Wrapper>
  );
}

export default ListPagesFacebook;
