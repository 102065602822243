import styled from 'styled-components';

export const Wrapper = styled.div`
  width: calc(100% + 15px);
  display: flex;
  flex-wrap: wrap;
  margin: 10px -7.5px 10px;
`;

export const WrapItem = styled.div`
  padding: 7.5px;
  width: calc(100% / 6);
`;
