import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
// Components
import MainTitle from 'components/MainTitle';
import Container from 'components/Container';
import Loading from 'components/Loading';
import Button from 'components/Button';
import TextLoading from 'components/TextLoading';
import ListInteractions from './components/ListInteractions';
// Modules
import { openModalInteraction, openModalServiceEvaluation } from 'modules/modal';
// Services
import * as helpDeskService from 'services/helpDesk';
// Constants
import { TYPES } from 'pages/Help/constants';

class Detail extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    data: {},
    isFetching: true
  };

  /**
   * Retorna o id do ticket
   */
  get id() {
    try {
      return this.props.match.params.id;
    } catch {
      return null;
    }
  }

  /**
   * Retorna o tipo do ticket
   * @returns {string|*}
   */
  get type() {
    try {
      return this.props.match.params.type;
    } catch {
      return TYPES.PROBLEM;
    }
  }

  get title() {
    const { title, serial } = this.state.data;
    return `${serial} - ${title}`;
  }

  componentDidMount() {
    this.fetchDataByType();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Atualiza os dados quando o id da url muda
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchDataByType();
    }
  }

  fetchDataByType = async params => {
    this.setState({ isFetching: true });
    const { data } = await helpDeskService.getTicket(this.id, params);
    this.setState({ data, isFetching: false });
    return data;
  };

  get renderContainer() {
    const { data, isFetching } = this.state;

    const { created_at, responsible, interactions } = data;

    return (
      <>
        <Loading isVisible={isFetching} isFullComponent position="center" />
        <p className="h-color--primary">
          Aberto por:{' '}
          {responsible ? (
            responsible.name
          ) : (
            <TextLoading width={90} height="1em" />
          )}{' '}
          em {created_at}
        </p>
        <ListInteractions data={interactions} />
      </>
    );
  }

  onClickAddInteraction = () => {
    this.props.openModalInteraction({
      type: this.type,
      ticketId: this.id,
      onSubmitSuccess: async () => {
        this.fetchDataByType();
      }
    });
  };

  openModalServiceEvaluation = async () => {
    const { history, openModalServiceEvaluation } = this.props;

    openModalServiceEvaluation({
      ticketId: this.id,
      onFinally: () => {
        history.push(`/help/${this.type}/list`);
      }
    });
  };

  onClickCloseTicket = async () => {
    await helpDeskService.closeTicket(this.id);
    this.openModalServiceEvaluation();
  };

  render() {
    const { isFetching } = this.state;

    return (
      <>
        <MainTitle
          title={this.title}
          text="Acompanhe abaixo o histórico de alterações no seu chamado."
          isFetching={isFetching}
        >
          {!isFetching && (
            <>
              {!this.state.data?.can_evaluate && (
                <Button onClick={this.openModalServiceEvaluation}>
                  Avaliar atendimento
                </Button>
              )}
              {!this.state.data.is_done && (
                <Button color="danger" onClick={this.onClickCloseTicket}>
                  Fechar chamado
                </Button>
              )}
              <Button color="secondary" onClick={this.onClickAddInteraction}>
                {this.state.data.is_done
                  ? 'Reabrir chamado'
                  : 'Adicionar Comentário'}
              </Button>
            </>
          )}
        </MainTitle>
        <Container>{this.renderContainer}</Container>
      </>
    );
  }
}

//export default Detail;

const mapDispatchToProps = {
  openModalInteraction,
  openModalServiceEvaluation
};

export default compose(withRouter, connect(null, mapDispatchToProps))(Detail);
