import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
// Modules
import { closeModal } from 'modules/modal';
// components do sistema
import Button from 'components/Button';
import { Input, Select } from 'components/Form';
import { ModalFooter, ModalTemplate } from 'components/Modal';

const propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

const defaultProps = {};

function ModalPropertyDelete({
  modalConfig,
  isOpen,
  handleClose,
  handleSubmit,
  modalType,
  submitting
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Mover imóvel para excluído"
        handleClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Row>
            <Field
              xs={6}
              name="reason"
              label="Motivo da exclusão"
              component={Select}
              option={[
                { label: 'teste', value: '2' },
                { label: 'Outro', value: '1' }
              ]}
            />
            <Field
              xs={6}
              name="reason_text"
              label="Digite o motivo"
              component={Input}
            />
          </Row>
          <ModalFooter>
            <Button color="white" colorText="primary" onClick={handleClose}>
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success" disabled={submitting}>
              Mover para excluído
            </Button>
          </ModalFooter>
        </form>
      </ModalTemplate>
    </Modal>
  );
}

ModalPropertyDelete.propTypes = propTypes;
ModalPropertyDelete.defaultProps = defaultProps;

export default reduxForm({
  form: 'ModalPropertyDelete',
  enableReinitialize: true,
  onSubmitSuccess: (value, dispatch) => {
    dispatch(closeModal());
  }
})(ModalPropertyDelete);
