import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// Components
import MainTitle from 'components/MainTitle';
import NoContent from 'components/NoContent';
import Button from 'components/Button';
// Container
import FormDevice from './container/FormDevice';
// Modules
import {
  deleteHeaderImage,
  fetchFooterImages,
  headerImagesSelectors,
} from 'modules/components/headerImages';
// HOC
import withReception from 'HOC/withReception';
// Assets
import banner from './banner.png';
import { withRouter } from 'react-router';

class Banner extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    formData: {},
  };

  get renderForm() {
    const device = this.props.match.params.device;

    if (device === 'desktop') {
      return (
        <FormDevice
          key="FormBannerDesktop"
          form="FormBannerDesktop"
          device="desktop"
        />
      );
    }

    return (
      <FormDevice
        key="FormBannerMobile"
        form="FormBannerMobile"
        device="mobile"
      />
    );
  }

  render() {
    const { reception, handleClickAction } = this.props;

    if (reception.isVisible) {
      return (
        <NoContent
          full
          reversed
          image={banner}
          title="Banner"
          text={
            <>
              Imagem que fica localizada acima
              <br /> do rodapé do seu site.
            </>
          }
        >
          <Button color="secondary" size="large" onClick={handleClickAction}>
            Entendi
          </Button>
        </NoContent>
      );
    }

    return (
      <>
        <MainTitle
          title={`Banner ${this.props.match.params.device}`}
          text="Envie uma imagem que ficará localizada acima do rodapé do seu site."
        />
        {this.renderForm}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  images: headerImagesSelectors.getAll(state),
});

const mapDispatchToProps = {
  fetchFooterImages,
  deleteHeaderImage,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withReception('site/banner', true)
)(Banner);
