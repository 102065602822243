import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
// Componentes Gerais
import Nodata from 'components/Nodata';
// Components específicos
import Card from 'containers/Card'; //
// Assets
import searchEmpty from 'assets/img/noData/search-empty.svg';

const defaultProps = {
  type: 'vertical'
};

const propTypes = {
  hasFilter: PropTypes.bool
};

const PropertiesList = ({
  properties,
  hasFilter,
  toggleSelectedProperty,
  handleDeleteProperty,
  type
}) => {
  let styleLength = {};

  let xs, sm, md, lg;

  if (type === 'horizontal') {
    xs = 12;
    sm = 6;
    md = 6;
    lg = 6;
  } else {
    xs = 12;
    sm = 6;
    md = 6;
  }

  const className = classnames('propertyList__item', {
    [`col-xs-${xs}`]: xs,
    [`col-sm-${sm}`]: sm,
    [`col-md-${md}`]: md,
    [`col-lg-${lg}`]: lg
  });

  let noDataProps = {
    title: 'Nenhum imóvel cadastrado.',
    text:
      "Clique em 'Refazer a pesquisa' ao lado para iniciar uma nova pesquisa"
  };

  if (hasFilter) {
    noDataProps = {
      title: 'Nenhum imóvel encontrado.',
      text:
        "Clique em 'Refazer a pesquisa' ao lado para iniciar uma nova pesquisa"
    };
  }

  return (
    <Row
      className={`propertyList propertyList--${type}`}
      style={{ ...styleLength, position: 'relative' }}
    >
      {!isEmpty(properties) ? (
        properties.map(property => (
          <Col key={`properties-${property.id}`} className={className}>
            <Card
              type={type}
              property={property}
              handleDeleteProperty={handleDeleteProperty}
              toggleSelectedProperty={toggleSelectedProperty}
            />
          </Col>
        ))
      ) : (
        <Nodata
          position="left"
          image={searchEmpty}
          style={{ maxWidth: '480px' }}
          {...noDataProps}
        />
      )}
    </Row>
  );
};

PropertiesList.defaultProps = defaultProps;
PropertiesList.propTypes = propTypes;

export default memo(PropertiesList);
