import { transformPropertiesToTypes } from "services/condos";
import PropertiesTable from "../PropertiesTable";
import PropertiesTableGroundArea from "../PropertiesTableGroundArea";
import { Wrapper } from "./styles";

const getTableByType = typeName => {
  switch (typeName) {
    case 'Terreno':
      return PropertiesTableGroundArea;
    default:
      return PropertiesTable;
  }
};

function List({
  properties,
  handleOpenProperty,
  canSelect,
  handleSelectProperty,
}) {
  const types = transformPropertiesToTypes(properties);

  return (
    <div>
      {types.map(type => {
        const Table = getTableByType(type.title);

        return (
          <Wrapper key={type?.typeId}>
            <h4>{type?.title ?? '-'}</h4>
            <Table
              handleOpenProperty={handleOpenProperty}
              properties={type.properties}
              canSelect={canSelect}
              handleSelectProperty={handleSelectProperty}
            />
          </Wrapper>
        );
      })}
    </div>
  );
}

export default List;
