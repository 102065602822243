import { Header } from 'features/Financial/components/Header';
import { TimeLine } from './components/TimeLine';
import { Financial } from 'features/Financial';

export function ListHistory() {
  return (
    <>
      <Header.Header>
        <Header.Title>Históricos</Header.Title>
      </Header.Header>
      <Financial.Content>
        <TimeLine />
      </Financial.Content>
    </>
  );
}
