import styled from 'styled-components';
import { Wrapper } from 'components/TimelineTree/components/Timeline/styles';

export const Content = styled.div`
  position: relative;
  padding: 25px;
  max-width: 1084px;
  margin: 15px auto 0;
`;

export const Bar = styled.div`
  position: absolute;
  width: 1px;
  background: #adb7c5;
  top: 0;
  left: 24px;
  bottom: 0;
`;

export const Item = styled(Wrapper)`
  position: relative;
  width: 100%;
  padding-left: 45px;

  table {
    overflow: auto;
  }
`;

export const Ball = styled.div`
  position: absolute;
  top: 42%;
  left: -23px;
  width: 3px;
  height: 3px;
  background: #adb7c5;
  border-radius: 50%;
`;
