import React from 'react';
import PropTypes from 'prop-types';
import ReactYoutube from 'react-youtube';
import classnames from 'classnames';

const defaultProps = {
  className: null,
  proportion: 9 / 16,
  youtubeProps: {
    opts: {
      width: '100%',
      height: 'auto'
    }
  }
};

const propTypes = {
  proportion: PropTypes.oneOf([3 / 4, 9 / 16]),
  className: PropTypes.string,
  youtubeProps: PropTypes.shape({
    videoId: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    opts: PropTypes.object,
    onReady: PropTypes.func,
    onPlay: PropTypes.func,
    onEnd: PropTypes.func,
    onError: PropTypes.func,
    onStateChange: PropTypes.func,
    onPlaybackRateChange: PropTypes.func,
    onPlaybackQualityChange: PropTypes.func
  })
};

const YoutubeVideo = ({ className, proportion, youtubeProps }) => (
  <div
    className={classnames('h-embed-wrapper', className)}
    style={{
      paddingBottom: `${proportion * 100}%`
    }}
  >
    <ReactYoutube className="h-embed" {...youtubeProps} />
  </div>
);

YoutubeVideo.defaultProps = defaultProps;
YoutubeVideo.propTypes = propTypes;

export default YoutubeVideo;
