import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 300px;
  overflow: auto;
  margin: -20px -20px -15px;
  padding: 20px;
`;

export const Actions = styled.div`
  display: flex;
  margin: 15px -20px -20px;
  padding: 15px 20px;
  border-top: 1px solid #dedede;

  .Button {
    flex-grow: 1;
  }
`;
