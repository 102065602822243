import { MdClose } from 'react-icons/md';
// Components
// Styles
import { SortableHandle } from 'react-sortable-hoc';
import { ThemeProvider } from 'styled-components';
import { Container, Delete, Img, Reference } from './styles';

const Handle = SortableHandle(({ children }) => children);

const CardImageProperty = ({ property, onRemove }) => {
  return (
    <ThemeProvider
      theme={{
        property,
      }}
    >
      <Handle>
        <Container className="h-hover">
          <Delete
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (onRemove) {
                onRemove(property, e);
              }
            }}
          >
            <MdClose />
          </Delete>
          <Img
            key={property?.id + property?.updated_at}
            src={property?.images?.[0]?.file_url}
            alt="Image"
            className="image"
          />
          <Reference>Ref.: {property.reference}</Reference>
        </Container>
      </Handle>
    </ThemeProvider>
  );
};

export default CardImageProperty;
