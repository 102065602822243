import React, { useEffect, useState } from 'react';
import { ButtonGoogle, ButtonWhatsapp, MapActions, Wrapper, WrapperMap } from './styles';
import MapLeaflet from 'components/MapLeaflet';
import SortProperties from './components/SortProperties';
import MarkerNumber from 'components/MapLeaflet/components/MarkerNumber';
import { FaWhatsapp } from 'react-icons/fa';
import googleIcon from './google-icon.svg';
import { googleRouteDirections } from 'lib/google-maps-helper';
import Popover from 'components/Popover';
import { scrollToElement } from 'lib/animation-helpers';
import { whatsappSendUrl } from 'lib/share-helpers';
import WrapperFormChooseNumber from 'components/FormChooseNumber';

function PropertyItinerary({ properties, isFetching }) {
  const [_properties, setProperties] = useState(properties);

  async function getPropertiesLink() {
    const link = await googleRouteDirections(
      {
        lat: _properties?.[0].maps_latitude,
        lng: _properties?.[0].maps_longitude
      },
      _properties.map(({ maps_latitude: lat, maps_longitude: lng }) => ({
        lat,
        lng
      })),
      { yName: 'maps_latitude', xName: 'maps_longitude' }
    );

    return link;
  }
  //
  async function handleOpenGoogle() {
    const link = await getPropertiesLink();
    window.open(link);
  }
  //
  async function handleShareWhatsapp({ person, cellphone_number }) {
    const link = await getPropertiesLink();

    let message = '';

    if (person) {
      message += `Olá ${person.name}, `;
    }

    message += `segue roteiro de visita dos imóveis: 
    
${link}`;

    const whatsappUrl = whatsappSendUrl(cellphone_number, message);

    window.open(whatsappUrl);
  }
  //
  /**
   * Remove focus de todos os imóveis
   */
  function removeAllFocus() {
    setProperties(properties => {
      return properties.map(property => ({
        ...property,
        isFocused: false
      }));
    });
  }

  /**
   * Adiciona focus em um imóvel
   * @param property
   */
  function addPropertyFocus(property) {
    removeAllFocus();

    scrollToElement(`.js-property-${property.id}`, '.js-properties-wrapper', {
      noOffset: true
    });

    setProperties(properties => {
      return properties.map(p => {
        if (p.id === property.id) {
          return { ...p, isFocused: true };
        }
        return p;
      });
    });
  }

  useEffect(() => {
    setProperties(properties);
  }, [properties]);

  return (
    <Wrapper>
      <SortProperties
        isFetching={isFetching}
        properties={_properties}
        setProperties={setProperties}
      />
      <WrapperMap className="js-wrapper-map">
        <MapLeaflet
          properties={_properties}
          renderProperties={({ property: p, index }) => {
            return (
              <MarkerNumber
                key={p.id}
                number={index + 1}
                position={[p.maps_latitude, p.maps_longitude]}
                onClick={() => {
                  addPropertyFocus(p);
                }}
              />
            );
          }}
        />
        <MapActions className="js-map-actions">
          <ButtonGoogle
            data-tip="Ver no google maps"
            onClick={handleOpenGoogle}
          >
            <img width={25} src={googleIcon} alt="" />
          </ButtonGoogle>
          <Popover
            onSubmit={values => values}
            onSubmitSuccess={handleShareWhatsapp}
            component={WrapperFormChooseNumber}
          >
            <ButtonWhatsapp data-tip="Compartilhar no whatsapp">
              <FaWhatsapp />
            </ButtonWhatsapp>
          </Popover>
        </MapActions>
      </WrapperMap>
    </Wrapper>
  );
}

PropertyItinerary.propTypes = {};
PropertyItinerary.defaultProps = {};

export default PropertyItinerary;
