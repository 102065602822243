import React from 'react';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import { Col, Row } from 'react-flexbox-grid';
import icone from 'containers/ModalRoot/ModalFacebookStore/icone.png';
import { useFacebookStore } from 'containers/ModalRoot/ModalFacebookStore/FacebookStoreContext';

const Help = ({ handleClose }) => {
  const { handleOnAuth: onAuth } = useFacebookStore();
  return (
    <div>
      <Row>
        <Col xs={5}>
          <img src={icone} alt="" style={{ marginTop: '-15px' }} />
        </Col>
        <Col xs={7}>
          <p>
            Um catálogo é um local em que você armazena informações sobre
            imóveis que deseja anunciar ou vender no Facebook.
          </p>
          <br />
          <p>
            Você pode conectar o catálogo a diferentes tipos de anúncios e
            canais de vendas para promover os seus imóveis, incluindo anúncios
            dinâmicos, Lojas do Facebook e muito mais.
          </p>
          <br />
          <p className="h-color--danger">
            Importante, antes de clicar em conectar, você precisa:
          </p>
          <br />
          <p>
            1 - Ter uma página profissional ativa dentro do seu perfil
            <br />
            <a
              href="https://scribehow.com/shared/Criar_pagina_no_Facebook__3cpXWCg-QbGPaAAnyrDi7Q"
              className="h-link"
              target="_blank"
              rel="noreferrer"
            >
              Clique aqui para saber como fazer
            </a>
          </p>
          <br />
          <p>
            2 - Criar uma loja dentro da sua página
            <br />{' '}
            <a
              href="https://scribehow.com/shared/Criar_LojaCatalogo_no_Facebook__Ng3FycQSTzeYpCyCQLzWJQ"
              className="h-link"
              target="_blank"
              rel="noreferrer"
            >
              Clique aqui para saber como fazer
            </a>
          </p>
          <br />
          <p>
            Após ter feito esses dois passos, clique em conectar para autorizar
            a integração do Tecimob com sua página
          </p>
        </Col>
      </Row>

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button type="submit" color="success" onClick={onAuth}>
          Autorizar Facebook
        </Button>
      </ModalFooter>
    </div>
  );
};

export default Help;
