import * as S from './styles';
import Button from 'components/Button';
import { MdOutlineMood, MdOutlineMoodBad } from 'react-icons/md';
import { IoMdSwap } from 'react-icons/all';
import { useModalPosting } from '../../../../hooks/useModalPosting';
import { openModalAccountTransfer } from '../../../../modals/ModalAccountTransfer';
import { useDispatch } from 'react-redux';
import { useDashboardContext } from '../../hooks/useDashboardContext';

export function QuickAccess() {
  const { refresh } = useDashboardContext();
  const dispatch = useDispatch();
  const { handleOpenModalPosting } = useModalPosting();

  const onSuccess = refresh;

  const handleClickTransfer = () => () => {
    dispatch(
      openModalAccountTransfer({
        onSuccess,
      })
    );
  };

  return (
    <S.Wrapper>
      <S.Left>Acesso rápido</S.Left>
      <S.Right>
        {/*<Button color="white" size="small">*/}
        {/*  <MdTrendingUp /> Ver relatórios*/}
        {/*</Button>*/}
        <Button
          color="white"
          size="small"
          onClick={() => {
            handleOpenModalPosting({
              type: 1,
              onSuccess,
            });
          }}
        >
          <MdOutlineMood className="h-color--success" /> Receita
        </Button>
        <Button
          color="white"
          size="small"
          onClick={() => {
            handleOpenModalPosting({
              type: 2,
              onSuccess,
            });
          }}
        >
          <MdOutlineMoodBad className="h-color--danger" /> Despesa
        </Button>
        <Button color="white" size="small" onClick={handleClickTransfer()}>
          <IoMdSwap /> Transferencia
        </Button>
      </S.Right>
    </S.Wrapper>
  );
}
