import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import { Wrapper, Caption } from './styles';

function GridImage({ image, onClick }) {
  return (
    <Wrapper
      onClick={() => {
        if (onClick) onClick(image);
      }}
    >
      <Image src={image?.file_url} alt={image?.caption || 'Imagem'} />
      {image?.caption ? <Caption>{image?.caption}</Caption> : null}
    </Wrapper>
  );
}

GridImage.propTypes = {
  image: PropTypes.shape({
    caption: PropTypes.string,
    created_at: PropTypes.string,
    file_url: PropTypes.string,
    gallery: PropTypes.oneOf([1, 2, 3]),
    id: PropTypes.string,
    order: PropTypes.number,
    property_id: PropTypes.string,
    updated_at: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default GridImage;
