import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import { Marker, Map, TileLayer } from 'react-leaflet';

// Components
import MainTitle from 'components/MainTitle';
import SeeExample from '../../components/SeeExample';
import FormFixedBar from '../../components/FormFixedBar';
import Container from 'components/Container';
import { FieldBool, Input } from 'components/Form';
import CountryField from 'containers/LocationFields/CountryField';
import StateField from 'containers/LocationFields/StateField';
import CityField from 'containers/LocationFields/CityField';
import NeighborhoodField from 'containers/LocationFields/NeighborhoodField';
import FieldCep from 'containers/FieldCep';

// Services
import { get, update } from 'services/buildings/location';

// Hooks
import useFormValue from 'hooks/useFormValue';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { useMapLocation } from 'hooks/useMapLocation';
import { useRequest } from 'hooks/useRequest';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';

// Styles
import { WrapSearchButton, BtnSearchMap, Divider } from './styles';
import { useReception } from 'hooks/useReception';
import Reception from 'pages/EnterpriseStore/components/Reception';
import image from './reception-location.png';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { Actions } from 'containers/LocationFields/module';

const pointerIcon = (icon) =>
  new window.L.DivIcon({
    html: `
    <svg width="28" height="40">
      <use href="#${icon}"></use>
    </svg>
  `,
    className: 'MapIcon',
    popupAnchor: [0, -45],
    iconAnchor: [14, 40],
    iconSize: [28, 40],
  });

const Localization = ({ handleSubmit, submit, change, initialize }) => {
  const { enterpriseId } = useEnterprise();

  const { isVisible, handleClose } = useReception({
    key: 'Enterprise--localization',
    canShowAgain: false,
  });

  const isMapShow = useFormValue('is_map_show');

  const { location, setLocation, searchByAddress } = useMapLocation({
    formName: FORM_NAME,
  });

  const { data } = useRequest({
    request: get,
    params: enterpriseId,
    initialState: {},
  });

  const { country_id, state_id, city_id, maps_latitude, maps_longitude } =
    useFormValue([
      'country_id',
      'state_id',
      'city_id',
      'maps_latitude',
      'maps_longitude',
    ]);

  const setLatLng = useCallback(
    (lat, lng) => {
      change('maps_latitude', lat);
      change('maps_longitude', lng);
    },
    [change]
  );

  useMemo(() => {
    if (location?.latitude || location?.longitude) {
      setLatLng(location?.latitude, location?.longitude);
    }
  }, [location]);

  const handleClickSearch = useCallback(
    (values) => {
      change('country_id', values.country.id);
      change('state_id', values.state.id);
      change('city_id', values.city.id);
      change('neighborhood_id', values.neighborhood.id);
      change('address_street', values.street_address);
    },
    [change]
  );

  const dispatch = useDispatch();

  const handleRemoveLocation = () => {
    const resetValues = {
      id: enterpriseId,
      address_complement: null,
      address_number: null,
      address_street: null,
      is_map_show: true,
      maps_latitude: null,
      maps_longitude: null,
      maps_zoom: null,
      zip_code: null,
      country_id: null,
      state_id: null,
      city_id: null,
      neighborhood_id: null,
    };

    dispatch(
      Actions.clear(FORM_NAME, [
        'country_id',
        'state_id',
        'city_id',
        'neighborhood_id',
      ])
    );

    Object.entries(resetValues).forEach(([key, value]) => {
      change(key, value);
    });

    setTimeout(() => submit(), 100);
  };

  const handleChangeMarker = useCallback((event) => {
    if (event?.target) {
      const { lat, lng } = event.target.getLatLng();
      setLatLng(lat, lng);
    }
  }, []);

  useEffect(() => {
    initialize(data);
  }, [data, setLocation]);

  if (isVisible) {
    return (
      <Reception
        image={image}
        title="Localização"
        text="Adicione a localização dos imóveis da sua página."
      >
        <Button onClick={handleClose} color="success">
          Adicionar localização
        </Button>
      </Reception>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Localização"
        text="Adicione a localização dos imóveis da sua página."
      >
        <SeeExample />
      </MainTitle>

      <Container>
        <Row>
          <Col xs={4}>
            <FieldCep name="zip_code" onClickSearch={handleClickSearch} />
          </Col>
        </Row>
        <Row>
          <CountryField canClearValues={false} xs={3} name="country_id" />
          <StateField
            canClearValues={false}
            xs={3}
            name="state_id"
            countryId={country_id}
          />
          <CityField
            canClearValues={false}
            xs={3}
            name="city_id"
            stateId={state_id}
          />
          <NeighborhoodField
            canClearValues={false}
            xs={3}
            name="neighborhood_id"
            cityId={city_id}
          />
        </Row>
        <Row>
          <Field
            xs={6}
            label="Logradouro"
            name="address_street"
            component={Input}
          />
          <Field
            xs={2}
            label="Número"
            name="address_number"
            component={Input}
          />
          <Field
            xs={4}
            label="Complemento"
            name="address_complement"
            component={Input}
          />
        </Row>
        <WrapSearchButton>
          <div className="Form--inline">
            <FieldBool
              label="Mostrar mapa no site?"
              type="checkbox"
              name="is_map_show"
            />
            <BtnSearchMap
              style={{ marginLeft: 15 }}
              onClick={() => {
                change('is_map_show', true);
                searchByAddress();
              }}
            >
              Buscar no mapa
            </BtnSearchMap>
            <Button
              type="button"
              style={{ marginLeft: 15 }}
              color="danger"
              onClick={() => handleRemoveLocation()}
            >
              Remover
            </Button>
          </div>
        </WrapSearchButton>

        {!!maps_latitude && !!maps_longitude && isMapShow && (
          <>
            <Divider />
            <Map
              center={[maps_latitude, maps_longitude]}
              zoom={16}
              scrollWheelZoom={false}
              style={{ height: 400 }}
            >
              <TileLayer
                url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              />
              <Marker
                draggable
                onDragEnd={handleChangeMarker}
                position={[maps_latitude, maps_longitude]}
                icon={pointerIcon('building')}
              />
            </Map>
          </>
        )}
      </Container>

      <FormFixedBar />
    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: update,
})(Localization);
