// Modules
import dataReducer from 'modules/data';
// Services
import { getPeopleScheduled } from 'services/dashboards/people';

const MODULE_NAME = 'peopleScheduled';

export const {
  reducer: peopleFunnelReducer,
  actionCreators: peopleFunnelActions
} = dataReducer(`peopleDashboard/${MODULE_NAME}`);

// Selectors
export const dataSelector = state => state.peopleDashboard[MODULE_NAME].data;
export const isFetchingSelector = state =>
  state.peopleDashboard[MODULE_NAME].meta.isFetching;

// Reducer
export const fetchPeopleEvents = params => dispatch => {
  dispatch(peopleFunnelActions.request());

  return getPeopleScheduled().then(({ data }) => {
    dispatch(peopleFunnelActions.receive(data));
  });
};

export default peopleFunnelReducer;
