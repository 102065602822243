import styled from 'styled-components';
import { Cell } from '../Table/styles';

export const Title = styled.h1`
  font-size: 16px;
`;
export const Address = styled.address`
  font-size: 14px;
  font-style: normal;
  margin: 8px 0;
`;

const colorPrice = ({ transaction, theme }) => {
  if (transaction === 1) {
    return theme?.colors?.success;
  }

  return theme?.colors?.caution;
};

export const Price = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${colorPrice};
`;

export const CustomCell = styled(Cell)`
  position: sticky;
  top: -20px;
  background-color: #fff;
`;
