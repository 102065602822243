import styled, { css } from 'styled-components';

function positionPop({ position }) {
  switch (position) {
    case 'top':
      return css`
        bottom: 100%;
      `;
    default:
      return css`
        top: 100%;
      `;
  }
}

export const Wrapper = styled.div`
  display: flex;
`;

export const Color = styled.button.attrs(() => ({
  type: 'button',
  className: 'FormControl FormControl--colorpicker'
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  .FormControl__color {
    margin-top: 0;
  }

  input {
    flex: 1 0;
    height: 38px;
    border: 0 none;
  }
`;

export const Pop = styled.div`
  position: absolute;
  z-index: 50;
  left: 50%;
  transform: translateX(-50%);
  ${positionPop}
`;

export const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
`;

export const Clear = styled.button.attrs(() => ({
  type: 'button'
}))`
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
