import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';
import Tooltip from 'react-tooltip';
import { addCurrency } from 'lib/money-helpers';

export const convertValue = value => {
  try {
    if (!value) return 0.0;

    let newValue = value;

    // Remove os pontos
    newValue = newValue?.replace(/(R\$)|(\.)|(\s)/g, '');
    newValue = newValue?.replace(/,/g, '.');

    return parseFloat(newValue);
  } catch {
    return 0.0;
  }
};

function ResultValue({ price, indexes, financialIndex }) {
  useEffect(() => {
    Tooltip.rebuild();
  }, [price, indexes, financialIndex]);

  if (!financialIndex || indexes.length <= 0 || !price) return null;
  const currentIndex = indexes.find(index => financialIndex === index.id);
  if (!currentIndex) return null;

  const { name, value } = currentIndex;

  const _price = convertValue(price);
  const _indexPrice = convertValue(value);

  // Verifica se não tem valor
  // e se o nome do indice financeiro é real retorna null
  if (name === 'Real') return null;

  let total = addCurrency(_price * _indexPrice);

  return (
    <Wrapper data-tip={`${_price} X ${_indexPrice}`}>Total: {total}</Wrapper>
  );
}

ResultValue.propTypes = {
  financialIndex: PropTypes.string,
  indexes: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string
};
ResultValue.defaultProps = {
  financialIndex: null,
  indexes: [],
  value: null
};

export default ResultValue;
