// Modules
import dataReducer from 'modules/data';
import { filterOrAll } from 'pages/Reports/pages/helpers';
// Services
import { getDealPerBroker } from 'services/reports';

export const {
  reducer: dealPerBrokerReducer,
  actionCreators: dealPerBrokerActions,
  selectors: dealPerBrokerSelectors
} = dataReducer(`reports/dealPerBroker`);

export const chartDataSelector = filterOrAll(dealPerBrokerSelectors);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(dealPerBrokerActions.request());

  return getDealPerBroker(params).then(res => {
    dispatch(dealPerBrokerActions.receive(res.data));

    return res.data;
  });
};

export const toggleChecked = item => dispatch => {
  dispatch(dealPerBrokerActions.toggleSelected(item.id));
};

export default dealPerBrokerReducer;
