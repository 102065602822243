import React from 'react';
import PropTypes from 'prop-types';
// Components
import Popover from 'components/Popover';
import Button from 'components/Button';
import FormRenew from 'components/FormRenew';

const propTypes = {
  data: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    renew_date: PropTypes.string.isRequired
  }).isRequired,
  children: PropTypes.node.isRequired
};

const defaultProps = {
  children: 'Renovar'
};

const PopoverRevision = ({ data, children, onSubmit }) => {
  return (
    <Popover
      width={290}
      data={data}
      initialValues={data}
      onSubmit={onSubmit}
      component={FormRenew}
    >
      <Button
        size="small"
        color="white"
        colorText="secondary"
        className="h-hover__show"
      >
        {children}
      </Button>
    </Popover>
  );
};

PopoverRevision.propTypes = propTypes;
PopoverRevision.defaultProps = defaultProps;

export default PopoverRevision;
