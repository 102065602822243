import styled from 'styled-components';
import Image from 'components/Image';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 216px;
  height: calc(100% - 15px);
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  user-select: none;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  flex: 1 0;
  min-height: 0;
  position: relative;
`;

export const CondoImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Footer = styled.div`
  color: #fff;
  padding: 10px 20px;
  background: ${p => p.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: 500;
  margin-right: 40px;
`;

export const Address = styled.address`
  font-size: 14px;
  font-style: normal;
`;
