import qs from 'qs';
import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export function useQuery({ withParams = false } = {}) {
  const [query, setQuery] = useState({});
  const { search } = useLocation();
  const params = useParams();

  useEffect(() => {
    let _query = {};

    if (withParams) {
      _query = params;
    }

    _query = {
      ..._query,
      ...qs.parse(search, { ignoreQueryPrefix: true }),
    };

    setQuery(_query);
  }, [search]);

  return query;
}
