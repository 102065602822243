import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// Helpers

function Overlay({ width, height, fileUrl, children }) {
  let style = {
    width,
    height
  };

  if (height) {
    style = {
      ...style,
      paddingBottom: 0
    };
  }

  return (
    <div className={classnames('DropImage')} style={style}>
      <div className="DropImage__content">{children}</div>
    </div>
  );
}

Overlay.defaultProps = {
  fileUrl: null,
  children: null
};

Overlay.propTypes = {
  fileUrl: PropTypes.string,
  children: PropTypes.node
};

export default Overlay;
