import React from 'react';
import { connect } from 'react-redux';
// Modules
import { hide, show } from 'modules/reception';
import { handleAddWizard } from 'modules/login';
// Lib
import Store, { TypesInstance } from 'lib/Store';

const withReception = (key, showAgain) => Component => {
  class WithReception extends React.Component {
    state = { showAgain: showAgain || false };

    async componentDidMount() {
      const { show, hide } = this.props;
      const sessionField = await Store.get(key, TypesInstance.session);

      if (sessionField) {
        hide();
      } else {
        !(await Store.get(key)) ? show() : hide();
      }
    }

    componentWillUnmount() {
      const { hide } = this.props;
      hide();
    }

    setStorage = () => {
      const { handleAddWizard } = this.props;
      const { showAgain } = this.state;

      // Se estiver marcado para não mostrar novamente
      // adiciona no banco
      if (showAgain) {
        handleAddWizard(key);
        return;
      }

      Store.set(key, true, TypesInstance.session);
    };

    handleClickAction = e => {
      e.preventDefault();
      this.setStorage();
      this.props.hide();
    };

    // Lida com o evento de mudar a visibilidade do
    toggleVisibility = () =>
      this.setState({ showAgain: !this.state.showAgain });

    onChangeVisibility = () => this.toggleVisibility();

    render() {
      return (
        <Component
          setStorage={this.setStorage}
          showAgain={this.state.showAgain}
          onChangeVisibility={this.onChangeVisibility}
          toggleVisibility={this.toggleVisibility}
          handleClickAction={this.handleClickAction}
          {...this.props}
        />
      );
    }
  }

  return connect(
    state => ({
      reception: state.reception
    }),
    { show, hide, handleAddWizard }
  )(WithReception);
};

export default withReception;
