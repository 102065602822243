import * as iconsService from 'features/Financial/services/icons';
import { useRequest } from 'hooks/useRequest';
import { useCallback } from 'react';

export const useGetIcons = () => {
  const {
    data: icons,
    isFetching: isFetchingIcons,
    meta: metaIcons,
    fetchData: fetchIcons,
  } = useRequest({
    request: iconsService.indexIcons,
  });

  const getIconById = useCallback(
    (id) => {
      return icons.find((icon) => icon.id === id);
    },
    [icons]
  );

  return {
    icons,
    isFetchingIcons,
    metaIcons,
    fetchIcons,
    getIconById,
  };
};
