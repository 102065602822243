// Widgets.js
import { createModule } from 'lib/reducer-helpers';
import * as crmService from 'services/crm';

const createAction = createModule('asideCrm');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const SHOW_WIZARD = createAction('SHOW_WIZARD');

// Selectors
export const countsSelector = (state) => state.asideCrm;
export const canShowWizardSelector = (state) => state.asideCrm.canShowWizard;

// Initial State
const initialState = {
  data: {
    bookedPropertiesCount: {
      properties_count: 0,
      people_count: 0,
      properties_price_sum: 0,
    },
    matchedCount: 0,
    matchedPropertiesCount: 0,
    peopleCount: 0,
    propertiesPeopleCount: 0,
  },
  isFetching: false,
  canShowWizard: false,
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE:
      return {
        ...state,
        data: action.data,
        meta: { ...state.meta, isFetching: false },
      };
    case SHOW_WIZARD:
      return {
        ...state,
        canShowWizard: true,
      };
    default:
      return state;
  }
}

export const Actions = {
  request: () => ({ type: REQUEST }),
  receive: (data) => ({ type: RECEIVE, data }),
  showWizard: () => ({ type: SHOW_WIZARD }),
};

// side effects, only as applicable
// e.g. thunks, epics, etc
export const fetchCounts = (userId) => async (dispatch) => {
  dispatch(Actions.request());

  const counts = await crmService.counts({ user_id: userId });

  dispatch(Actions.receive(counts));
};
