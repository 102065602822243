import React, { useState, useEffect } from 'react';
import * as Proptypes from 'prop-types';
import Box from '../Box';
import Button from 'components/Button';
import { MdSettings, MdRemoveCircle, MdCheckCircle } from 'react-icons/md';
import PreviewImage from 'components/PreviewImage';
import Overlay from 'components/Overlay';
import { useRequest } from 'hooks/useRequest';
import { getLogo, DEVICES, TYPES, updateLogo } from 'services/sites/logos';
import { Btn, ButtonConfig } from './styles';
import { useDispatch } from 'react-redux';
import { openModalLogoConfig } from 'modules/modal';
import { useHistory } from 'react-router-dom';

const Logo = ({ device, type, title, onLoad }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: logo, fetchData, isFetching } = useRequest({
    request: () => getLogo({ device, type })
  });

  const [isActive, setIsActive] = useState(logo?.is_logo_shown);

  useEffect(() => {
    setIsActive(logo?.is_logo_shown);

    if (onLoad) onLoad(logo);
  }, [logo]);

  const toggleLogoVisibility = async () => {
    setIsActive(prev => !prev);

    try {
      await updateLogo({ ...logo, device, type, is_logo_shown: !isActive });
    } catch {
      return false;
    }
  };

  const openModal = async () => {
    dispatch(
      openModalLogoConfig({
        title: title,
        logo,
        device,
        type,
        onSubmitSuccess: () => {
          fetchData();
        }
      })
    );
  };

  return (
    <Box
      isLoading={isFetching}
      title={title}
      text="Medida: 270x140px"
      actions={() => (
        <>
          {isActive ? (
            <Btn color="success" onClick={toggleLogoVisibility}>
              <MdCheckCircle />
              Ativado
            </Btn>
          ) : (
            <Btn color="danger" onClick={toggleLogoVisibility}>
              <MdRemoveCircle />
              Desativado
            </Btn>
          )}

          <ButtonConfig onClick={openModal} color="white">
            <MdSettings />
          </ButtonConfig>
        </>
      )}
    >
      <PreviewImage
        size={[270, 140]}
        url={logo?.file_url}
        isLoading={isFetching}
        className="h-hover"
        background={logo?.background_color}
        opacity={logo?.logo_opacity}
        padding={logo?.logo_padding}
      >
        <Overlay
          onClick={() => {
            history.push(`/site/layout/style/logo/options/${device}/${type}`);
          }}
          className={!!logo?.file_url && 'h-hover__show'}
        >
          <Button color="white">Alterar Logo</Button>
        </Overlay>
      </PreviewImage>
    </Box>
  );
};

Logo.propTypes = {
  device: Proptypes.oneOf([DEVICES.MOBILE, DEVICES.DESKTOP]),
  type: Proptypes.oneOf([TYPES.HEADER, TYPES.FOOTER]),
  title: Proptypes.string
};

export default Logo;
