import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Common Components
import FormBankSlip from './components/FormBankSlip';
// Services
import * as bankSlipService from 'services/financial/bankSlip';
import ModalTemplate from 'components/Modal/components/ModalTemplate';
import { contractWithBankSlip } from 'services/financial/transactions';

const initialValues = {
  name: null,
  type: '1',
  cpf_cnpj: null,
  should_send_email: true,
};

class ModalBankSlip extends Component {
  state = {
    initialValues,
    isFetching: false,
  };

  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    bank_slip_id: PropTypes.string,
    credit_id: PropTypes.string,
    users: PropTypes.number,
    months: PropTypes.number,
    onSubmitSuccess: PropTypes.func,
    onSubmitFail: PropTypes.func,
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null,

    bank_slip_id: null,
    credit_id: null,
    users: null,
    months: null,
    onSubmitSuccess: null,
    onSubmitFail: null,
  };

  get initialValues() {
    const { initialValues } = this.state;
    return initialValues;
  }

  /**
   * Verifica se o formulário esta em 'modo de contratação'
   * @return {boolean}
   */
  get isContractMode() {
    const { users, months, credit_id } = this.props;

    return (!!users && !!months) || credit_id;
  }

  componentDidMount() {
    const { bank_slip_id } = this.props;

    // if (bank_slip_id) {
    this.fetchData();
    // }
  }

  /**
   * Atualiza os dados do formulário
   * @return {Promise<void>}
   */
  fetchData = async () => {
    this.setState({ isFetching: true });

    try {
      const { data: initialValues } = await bankSlipService.getOne();
      this.setState({ initialValues });
    } finally {
      this.setState({ isFetching: false });
    }
  };

  get params() {
    const { users, months, credit_id } = this.props;

    if (credit_id)
      return {
        credit_id,
      };

    return { users, months };
  }

  onSubmit = async (values) => {
    this.setState({ isFetching: true });

    try {
      const { data: bankSlip } = await bankSlipService.create(values);

      // Verifica se está em modo de contratação
      if (this.isContractMode) {
        const { data: pagarMe } = await contractWithBankSlip(this.params);

        return {
          ...pagarMe,
          ...bankSlip,
        };
      }

      return bankSlip;
    } finally {
      this.setState({ isFetching: false });
    }
  };

  onSubmitSuccess = (...rest) => {
    const { onSubmitSuccess, handleClose } = this.props;
    if (onSubmitSuccess) onSubmitSuccess(...rest);
    handleClose();
  };

  onSubmitFail = (...rest) => {
    const { onSubmitFail } = this.props;
    if (onSubmitFail) onSubmitFail(...rest);
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;
    const { isFetching } = this.state;

    return (
      <Modal
        {...modalConfig}
        isLoading={isFetching}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate title="Dados do boleto" handleClose={handleClose}>
          <FormBankSlip
            isContractMode={this.isContractMode}
            isLoading={isFetching}
            initialValues={this.initialValues}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
            onSubmitFail={this.onSubmitFail}
            handleClose={handleClose}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

export default ModalBankSlip;
