import React from 'react';
import Modal from 'react-modal';
// Services
import * as dwvService from 'services/integrations/dwv';
// Common Components
import { ModalTemplate } from 'components/Modal';
import FormDwvDisconnect from './components/FormDwvDisconnect';

function ModalDwvDisconnect({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onSubmitSuccess,
  should_change_contract,
}) {
  function onSubmit(values) {
    return dwvService.disconnect(values);
  }

  function handleSubmitSuccess() {
    if (onSubmitSuccess) onSubmitSuccess();
    handleClose();
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Remover integração DWV" handleClose={handleClose}>
        <FormDwvDisconnect
          handleClose={handleClose}
          onSubmit={onSubmit}
          initialValues={{
            should_change_contract,
            should_destroy_properties: true,
          }}
          onSubmitSuccess={handleSubmitSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalDwvDisconnect;
