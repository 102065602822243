import styled, { css } from 'styled-components';

const getLabelColor = ({ color }) => {
  if (color === 'success') {
    return css`
      color: #31d084;
      border-color: #31d084;
      background-color: #def7eb;
    `;
  }

  if (color === 'danger') {
    return css`
      color: #f95656;
      border-color: #f95656;
      background-color: #fed9d9;
    `;
  }

  return css`
    color: #0084f4;
    border-color: #0084f4;
    background-color: #cce6fd;
  `;
};

export const Text = styled.div`
  color: #0063c0;
  text-decoration: underline;

  ${(p) =>
    p.theme.isActive &&
    css`
      font-weight: bold;
    `}
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  margin: 0 -5px;

  :hover {
    background: rgb(55 118 198 / 10%);
    border-radius: 5px;
  }

  //& + & {
  //  margin-top: 5px;
  //}
`;

export const Label = styled.div`
  padding: 0 5px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  height: 20px;
  line-height: 18px;
  vertical-align: middle;

  ${getLabelColor}
`;
