import { Wrapper } from './styles';

const AddCard = ({ onAddCard }) => {
  return (
    <Wrapper onClick={onAddCard}>
      <p>[ + ]</p>
      <p>Novo Cartão</p>
    </Wrapper>
  );
}

export default AddCard;
