import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Components
import ModalTemplate from 'components/Modal/components/ModalTemplate';

import AddEmailAccountForm from './components/AddEmailAccountForm';
// Services
import * as emailService from 'services/settings/email';
// Containers
import MiddlewareDomains from 'containers/MiddlewareDomains';

class ModalAddEmailAccount extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    onSubmitSuccess: PropTypes.func,
    onSubmitFail: PropTypes.func,
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null,

    onSubmitSuccess: null,
    onSubmitFail: null,
  };

  handleSubmit = (values) => {
    return emailService.createMailbox(values);
  };

  onSubmitSuccess = () => {
    if (this.props.onSubmitSuccess) this.props.onSubmitSuccess();

    // Fecha a modal
    this.props.handleClose();
  };

  onSubmitFail = () => {
    if (this.props.onSubmitFail) this.props.onSubmitFail();
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title="Criar conta de e-mail local"
          handleClose={handleClose}
        >
          <MiddlewareDomains handleClose={handleClose}>
            {(domains) => {
              return domains ? (
                <AddEmailAccountForm
                  onSubmit={this.handleSubmit}
                  onSubmitSuccess={this.onSubmitSuccess}
                  onSubmitFail={this.onSubmitFail}
                  initialValues={{
                    domain_id: domains?.data?.[0]?.id,
                  }}
                  domains={domains}
                  handleClose={handleClose}
                />
              ) : null;
            }}
          </MiddlewareDomains>
        </ModalTemplate>
      </Modal>
    );
  }
}

export default ModalAddEmailAccount;
