import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Modules
import {
  currentUserIsFetchingSelector,
  currentUserSelector,
} from 'modules/login';
// Components
import AlertCreci from '../../components/AlertCreci';
// Helpers
import { LocalStore } from 'lib/Store';

function AlertCreciContainer() {
  const [isVisible, setVisible] = useState(false);

  const isFetching = useSelector(currentUserIsFetchingSelector);

  const { id, creci_expiration, creci_status } =
    useSelector(currentUserSelector);

  useEffect(() => {
    if (!isFetching && !!id) {
      LocalStore.get('alert-creci').then((value) => {
        if (value === undefined) {
          setVisible(true);
        } else {
          setVisible(value);
        }
      });
    }
  }, [isFetching, id]);

  async function handleClose() {
    await LocalStore.set('alert-creci', false);
    setVisible(false);
  }

  // Se o usuário já tiver o creci verificado
  // nem precisa renderizar mais nada
  if ([1, 4, 5].indexOf(creci_status) === -1) return null;

  return (
    <AlertCreci
      userId={id}
      status={creci_status}
      isOpen={isVisible}
      days={creci_expiration}
      onClose={handleClose}
    />
  );
}

export default AlertCreciContainer;
