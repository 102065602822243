import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 200px;
  max-height: 490px;
  overflow: auto;
  background: #ffffff;
  border-left: 1px solid #d3dceb;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  .FormGroup {
    margin-bottom: 0 !important;
  }
`;
export const Header = styled.header`
  padding: 30px 30px 20px;
  border-bottom: 1px solid #d3dceb;
`;

export const Title = styled.h1`
  font-size: 16px;
  margin-bottom: 5px;
`;

export const Bar = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: 100%;
  border-bottom: 1px solid #d3dceb;
  text-align: left;
  padding: 20px 20px 20px 30px;
  font-weight: 600;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.03);
  }

  svg {
    float: right;
    font-size: 20px;
    margin-bottom: -3px;
  }
`;
