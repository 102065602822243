import React from 'react';
import { Wrapper } from './styles';
import { useRequest } from 'hooks/useRequest';
import api from 'services';
import Item from './components/Item';
import Skeleton from './components/Skeleton';
import iconData from './assets/icon-data.png';
import contatos from './assets/contatos-recebidos.png';
import visitas from './assets/visitas-recebidas.png';
import moment, { format } from 'lib/moment';

function Analysis({ property }) {
  const { data, isFetching } = useRequest({
    request: () => api.get(`properties/${property.id}/analysis`),
  });

  if (isFetching) {
    return <Skeleton />;
  }

  return (
    <Wrapper>
      <Item
        title="Visitantes e Visualizações"
        value={`${data?.visitors} | ${data?.pageviews}`}
        image={visitas}
      />
      <Item
        title="Contatos e Taxa Interação"
        value={`${data?.leads_count} | ${data?.interaction_rate}%`}
        image={contatos}
      />
      <Item
        title="Data de cadastro"
        value={moment(property.created_at, format.datetime).format(format.date)}
        image={iconData}
      />
    </Wrapper>
  );
}

export default Analysis;
