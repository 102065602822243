import styled from 'styled-components';
import { borderRadius } from 'polished';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  border: 1px solid ${(p) => p.theme.color};
  border-radius: ${(p) => p.theme.borderRadius};
  margin-bottom: 15px;
`;

export const Box = styled.div`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  background-color: ${(p) => p.theme.color};
  padding: ${(p) => p.theme.boxPadding};
  width: ${(p) => p.theme.boxSize};

  img {
    margin: 0 auto;
  }
`;

export const Text = styled.div`
  margin-top: 10px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: ${(p) => p.theme.fontSize};
`;

export const Content = styled.div`
  background: #fff;
  flex: 1 0;
  ${(p) => borderRadius('right', p.theme.borderRadius)}
`;

export const WrapIcon = styled.div``;

export const InnerContent = styled.div`
  padding: ${(p) => p.theme.boxPadding};
`;

export const TitleContent = styled.h2`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`;
