import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import {
  Image,
  Person,
} from 'pages/Site/pages/ContentSite/pages/SuperHighlights/components/SuperhighlightList/styles';
import Button from 'components/Button';
import styled from 'styled-components';
import { DragIcon } from 'pages/Site/pages/ContentSite/pages/Posts/components/TablePosts/styles';
import TextOverflow from 'components/TextOverflow';
// import { Wrapper } from './styles';

const Handle = styled.div`
  cursor: grab;
  margin-top: -5px;
  margin-right: 4px;
`;

const WrapInfos = styled.div`
  display: flex;
  align-items: center;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">
    <DragIcon />
  </Handle>
));

function TableRow({ data, handleClickUpdate, handleClickRemove }) {
  return (
    <tr key={data.id + data.file_url}>
      <td>
        <WrapInfos>
          <RowHandler />
          <Person className="h-text-nowrap">
            <Image size="medium" src={data.file_url} />
            <TextOverflow width={450}>{data.title}</TextOverflow>
          </Person>
        </WrapInfos>
      </td>
      <td className="Table__cell--small">
        <Button
          onClick={handleClickUpdate(data)}
          color="white"
          colorText="primary"
        >
          Editar
        </Button>
        <Button
          onClick={handleClickRemove(data)}
          color="white"
          colorText="danger"
        >
          Remover
        </Button>
      </td>
    </tr>
  );
}

export default TableRow;
