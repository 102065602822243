export default values => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (!values.email) {
    errors.email = REQUIRED_MSG;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'E-mail invalido';
  }

  return errors;
};
