import { useCallback, useMemo } from 'react';
import { reduxForm } from 'redux-form';
// Components
import { FieldBool } from 'components/Form';

function FormPayment({ realEstate, handleSubmit }) {
  const onChange = useCallback(() => {
    setTimeout(() => {
      handleSubmit();
    }, 100);
  }, [handleSubmit]);

  const canRecurringCharge = useMemo(() => {
    return realEstate?.plan === 1;
  }, [realEstate]);

  if (!canRecurringCharge) return false;

  return (
    <form onSubmit={handleSubmit}>
      <FieldBool
        formGroupClassName="h-margin-top--10"
        label="Ativar cobrança automática?"
        name="recurring_charge"
        onChange={onChange}
        style={{ width: 170 }}
      />
    </form>
  );
}

FormPayment.defaultProps = {};
FormPayment.propTypes = {};

export default reduxForm({
  form: 'FormPayment',
  enableReinitialize: true,
})(FormPayment);
