import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;

  & + & {
    border-top: 1px solid #dedede;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  color: #333;
  margin-bottom: 0.2em;

  svg {
    vertical-align: top;
    margin-top: -3px;
  }
`;

export const Text = styled.div`
  font-size: 12px;
  color: #3a3a3a;

  ${p =>
    p.noCellphone &&
    css`
      color: #8d8d8d;
    `}
`;
