import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const defaultProps = {
  iconName: '',
  name: '',
  text: ''
};

const propTypes = {
  iconName: PropTypes.string,
  name: PropTypes.node,
  text: PropTypes.node
};

const DetailItem = ({ iconName, name, text }) => (
  <Col tagName="li" className="DetailList__item">
    <Row middle="xs">
      {iconName && (
        <Col>
          <span className="DetailList__icon">
            <Icon name={iconName} size="medium" />
          </span>
        </Col>
      )}
      <Col>
        {name && <h5 className="DetailList__name">{name}</h5>}
        {text && <p className="DetailList__text">{text}</p>}
      </Col>
    </Row>
  </Col>
);

DetailItem.defaultProps = defaultProps;
DetailItem.propTypes = propTypes;

export default DetailItem;
