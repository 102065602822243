import { Reference } from './components/Reference';
import { Price } from './components/Price';
import { Measure } from './components/Measure';
import { Unity } from './components/Unity';
import { Floor } from './components/Floor';

export const Fields = {
  Reference,
  Price,
  Measure,
  Unity,
  Floor,
};
