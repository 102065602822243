import Analytics from './Analytics';
import DefaultTexts from './DefaultTexts';
import Financial from './Financial';
import LogoOptions from './LogoOptions';
import Backup from './Backup';
import Permissions from './Permissions';
import Registrations from './Registrations';
import Properties from './Properties';
import General from './General';
import Documents from './Documents';
import Profile from './Profile';

export default {
  General,
  Documents,
  Analytics,
  DefaultTexts,
  Financial,
  LogoOptions,
  Backup,
  Permissions,
  Registrations,
  Properties,
  Profile,
};
