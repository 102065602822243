import { rgba } from "polished";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  padding: 15px;
  border: 1px solid ${(p) => rgba(p.theme.colors.success, 0.2)};
  border-radius: 10px;

  cursor: pointer;

  transition: border-color 0.2s;

  &:hover {
    border: 1px solid ${(p) => p.theme.colors.success};
  }

  ${(props) => props.active && css`
    border: 1px solid ${(p) => p.theme.colors.success};
    background: ${(p) => rgba(p.theme.colors.success, 0.1)};
  `}
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-right: 20px;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Info = styled.p`
  color: #5F6470;

  strong {
    color: #000;
  }
`;

export const Title = styled.h2`
  font-size: 16px;
`;

export const PricesLabels = styled(Infos)`
  text-align: right;
`;

export const Prices = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const OldPrice = styled.p`
  text-decoration: line-through;
`;

export const NewPrice = styled.p`
  font-weight: bold;
  color: #000;
`;

export const Discount = styled.div`
  background: ${(p) => p.theme.colors.caution};
  font-weight: bold;
  color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
`;
