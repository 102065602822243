import styled from 'styled-components';
import FilterAside from 'components/FilterAside';

export const Aside = styled(FilterAside)`
  max-width: 270px;

  .p-multiselect-label {
    font-family: Barlow, Arial;
    font-size: 13px;
  }

  .FilterAside__content {
    padding-top: 20px;
  }

  .Button + .Button {
    margin-left: 0;
    margin-top: 5px;
  }
`;
