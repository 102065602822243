import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// Components
import ReduxForm from './ReduxForm';

export default class FormWrapper extends React.Component {
  static propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    debounceSubmit: PropTypes.bool,
    debounceDelay: PropTypes.number,
    children: PropTypes.func.isRequired
  };

  static defaultProps = {
    initialized: true,
    as: 'form',
    debounceSubmit: true,
    debounceDelay: 300,
    children: () => null
  };

  constructor(props) {
    super(props);
    this.timeout = props.debounceDelay || null;
  }

  onChange = (values, dispatch, props, previousValues) => {
    const { debounceSubmit, debounceDelay } = this.props;
    if (debounceSubmit) {
      if (_.isEmpty(previousValues)) return null;

      if (!previousValues) return null;

      const { submit } = props;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => submit(), debounceDelay);
    }

    if (this.props.onChange)
      this.props.onChange(values, dispatch, props, previousValues);
  };

  render() {
    const {
      onSubmit,
      debounceDelay,
      debounceSubmit,
      children,
      initialized,
      ...rest
    } = this.props;

    if (!initialized) return <ReduxForm {...rest}>{children}</ReduxForm>;

    return (
      <ReduxForm {...rest} onSubmit={onSubmit} onChange={this.onChange}>
        {children}
      </ReduxForm>
    );
  }
}
