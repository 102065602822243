import Button from 'components/Button';
import ListOptions from 'components/ListOptions';
import logo from './logo.png';
import { useSuperlogica } from 'hooks/useSuperlogica';

function SuperlogicaIntegration() {
  const { isConnected, connect, disconnect } = useSuperlogica();

  return (
    <ListOptions.item
      image={logo}
      imageStyle={{
        width: 42,
      }}
      title={`Superlógica`}
      text="Gestão de contratos de locação para imobiliárias e incorporadoras"
      renderOptions={() => {
        if (!isConnected) {
          return (
            <Button onClick={connect} color="success">
              Conectar
            </Button>
          );
        }

        return (
          <Button color="danger" onClick={disconnect}>
            Desconectar
          </Button>
        );
      }}
    />
  );
}

export default SuperlogicaIntegration;
