import React, { useState } from 'react';

export const PathContext = React.createContext({
  path: '',
  setPath: null
});

export const PathProvider = ({ children }) => {
  const [path, setPath] = useState('');
  return <PathContext.Provider value={{ path, setPath }}>{children}</PathContext.Provider>;
};
