import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 15px;
`;

export const Btn = styled.button`
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 25px;
  font-size: 16px;
  color: #fff;
  border: 1px solid #fff;
  margin: 0 5px;
  transition: all 0.3s ease-in-out;
  will-change: background, color;

  ${(p) =>
    p.isActive &&
    css`
      background: #fff;
      color: #000;
    `}
`;

export const Header = styled.header`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled(motion.div).attrs(() => ({
  initial: { opacity: 0, x: 0 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -20 },
  transition: { duration: 0.2 },
}))`
  display: grid;
  grid-gap: 15px;
  padding: 15px;

  @media all and (max-width: 1366px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media all and (min-width: 1367px) {
    grid-template-columns: repeat(9, 1fr);
  }
`;

export const CloseBtn = styled.div`
  border-radius: 50%;
  margin: 5px 5px 0 0;
  width: 55px;
  height: 55px;
  display: grid;
  place-items: center;
  color: #fff;
  font-size: 25px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-top: 0 none;
  border-right: 0 none;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.4);
  }
`;
