import React from 'react';
// Services
import * as financialService from 'services/financial';
// Components
import { ModalTemplate } from 'components/Modal';
import FormSchedule from '../FormSchedule';
import moment from 'lib/moment';

function ModalSchedule({ handleClose }) {
  function onSubmit(values) {
    return financialService.consultantContact(values);
  }

  function onSubmitSuccess() {
    handleClose();
  }

  // Best Time
  const best_time = moment().format('DD/MM/YYYY');

  return (
    <ModalTemplate title="Contratar plano" handleClose={handleClose}>
      <FormSchedule
        initialValues={{ best_time }}
        onSubmitSuccess={onSubmitSuccess}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
    </ModalTemplate>
  );
}

ModalSchedule.propTypes = {};
ModalSchedule.defaultProps = {};

export default ModalSchedule;
