import React from 'react';
import { Wrapper } from './styles';
// import ItemDealLoss from './components/ItemDealLoss';
import ItemSell from './components/ItemSell';
import ItemRent from './components/ItemRent';
import Nodata from 'components/Nodata';
import house from 'assets/img/noData/house.svg';

function Table({ data, onRemoveDeal, onCloseModalUpdate }) {
  if (!data || data.length <= 0)
    return (
      <Nodata
        title="Sem negócios"
        text="Nenhum negócio encontrado."
        image={house}
      />
    );

  return (
    <Wrapper>
      {data.map((deal) => {
        if (deal.transaction === 2) {
          return (
            <ItemRent
              deal={deal}
              onRemoveDeal={onRemoveDeal}
              onCloseModalUpdate={onCloseModalUpdate}
            />
          );
        }

        return (
          <ItemSell
            deal={deal}
            onRemoveDeal={onRemoveDeal}
            onCloseModalUpdate={onCloseModalUpdate}
          />
        );
      })}
    </Wrapper>
  );
}

export default Table;
