import React from 'react';
import { connect } from 'react-redux';
// Modules
import { getPagination, registerPagination, setPagination, unregisterPagination } from 'modules/pagination';

export const withPagination = (name, payload) => Component => {
  class Pagination extends React.Component {
    constructor(props) {
      super(props);
      props.registerPagination(name, payload);
    }

    componentWillUnmount() {
      this.props.unregisterPagination(name);
    }

    setPagination = payload => this.props.setPagination(name, payload);

    render() {
      return <Component {...this.props} setPagination={this.setPagination} />;
    }
  }

  const mapStateToProps = state => ({
    pagination: getPagination(state, name)
  });

  const mapDispatchToProps = {
    registerPagination,
    unregisterPagination,
    setPagination
  };

  return connect(mapStateToProps, mapDispatchToProps)(Pagination);
};
