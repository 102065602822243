import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
// Components
import Form from './components/Form';
// Services
import * as postsService from 'services/sites/posts';
// Modules
import { fetchPosts } from 'pages/Site/pages/ContentSite/pages/Posts/module';

export default function ModalPostOptions({
  modalConfig,
  isOpen,
  modalType,
  handleClose,

  id,
  onSubmitSuccess,
  onSubmitFail,
}) {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    opens_link_on_page: false,
  });

  const handleSubmit = useCallback((values) => {
    if (id) {
      return postsService.update(values);
    }
    return postsService.create(values);
  }, []);

  const handleSubmitSuccess = useCallback(
    (...rest) => {
      if (onSubmitSuccess) onSubmitSuccess(rest);
      dispatch(fetchPosts());
      handleClose();
    },
    [dispatch, handleClose, onSubmitSuccess]
  );

  const handleSubmitFail = useCallback((...rest) => {
    if (onSubmitFail) onSubmitFail(rest);
  });

  useEffect(() => {
    async function fetchInitialValues() {
      const response = await postsService.getOne(id);

      // Seta os valores iniciais
      setInitialValues(response.data);
    }

    if (id) {
      fetchInitialValues();
    }
  }, [id, setInitialValues, id]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Form
        isEditting={!!id}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={handleSubmitFail}
        handleClose={handleClose}
      />
    </Modal>
  );
}
