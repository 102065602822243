import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Common Components
import { ModalTemplate } from 'components/Modal';
import FormLink from './components/FormLink';
// Modules
import { fetchMenu } from 'pages/Site/pages/Layout/pages/Menu/module';
// Services
import * as navigationsService from 'services/sites/navigations';
// Constants
import { TYPES_MENU } from 'pages/Site/constants';

class ModalMenuLink extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null
  };

  /**
   * Lida com o evento de submit do formulario de adicionar link
   * tanto pra editar quanto pra cadastrar
   * @param values
   * @return {values}
   */
  handleSubmit = values =>
    values.id
      ? navigationsService.update(values)
      : navigationsService.create(values);

  /**
   * Função que é invocada logo quando termina o request de cadastrar/editar um menu
   * @param args - normalmente vem uma resposta do cadastro/edicao
   */
  handleSubmitSuccess = (...args) => {
    const { onSubmitSuccess } = this.props;

    // Atualiza a lista de menus depois que adiciona um novo
    this.props.fetchMenu();

    // Verifica se tem alguma funcao de onSubmitSuccess
    // Se tiver executa passando os parametros
    if (onSubmitSuccess) onSubmitSuccess(...args);

    // Fecha a modal quando termina de cadastrar/editar
    this.props.handleClose();
  };

  /**
   * Função que é invocada quando dá algum problema no request de cadastrar/editar
   */
  handleSubmitFail = () => {
    //this.props.handleClose();
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType, menu } = this.props;

    const isEditting = !!(menu && menu.id);

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate title="A partir de um link" handleClose={handleClose}>
          <p className="h-margin-bottom--15">
            Você pode criar uma página a partir de um filtro do seu site, por
            exemplo, quando quiser criar uma página de um resultado específico.
            Ou então, pode adicionar também links para páginas externas.
          </p>
          <FormLink
            isEditting={isEditting}
            initialValues={{
              type: TYPES_MENU.LINK,
              opens_link_on_page: true,
              ...menu
            }}
            onSubmit={this.handleSubmit}
            onSubmitSuccess={this.handleSubmitSuccess}
            onSubmitFail={this.handleSubmitFail}
            handleClose={handleClose}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

export default connect(null, { fetchMenu })(ModalMenuLink);
