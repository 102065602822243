import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '../../../../../components/Button';
import ModalTemplate from '../../../../../components/Modal/components/ModalTemplate';

const propTypes = {
  handleClose: PropTypes.func
};
const defaultProps = {
  handleClose: () => {}
};

const RegisterDomainAlert = ({ handleClose, onSubmit }) => (
  <ModalTemplate
    title="Importante"
    handleClose={handleClose}
    footerActions={
      <>
        <Button
          color="white"
          className="h-color--secondary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow"></span>
        <Button color="success" onClick={onSubmit}>
          Continuar
        </Button>
      </>
    }
  >
    <p className={'h-color--primary h-margin-bottom--15'}>
      Todos domínios com final .br são registrado oficialmente pelo órgão
      federal registro.br. Eles cobram uma taxa anual de R$ 40,00 para fazer o
      registro em seu nome.
    </p>

    <p className={'h-color--primary h-margin-bottom--15'}>
      Nós fazemos somente o intermédio entre você e o registro.br.
    </p>

    <p className={'h-color--primary h-margin-bottom--15'}>
      O domínio é uma propriedade sua, registrada em seu nome e pode, a qualquer
      tempo ser utilizado com qualquer outra plataforma que você escolher.
    </p>
  </ModalTemplate>
);

RegisterDomainAlert.propTypes = propTypes;
RegisterDomainAlert.defaultProps = defaultProps;

export default RegisterDomainAlert;
