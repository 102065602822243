import React from 'react';
// Components
import StarsRating from 'components/StarsRating';
import Table from 'components/Table';

const propTypes = {};
const defaultProps = {};

const TableDealsOpened = ({ data, isLoading, onClick }) => (
  <Table
    type="full"
    data={data}
    isFetching={isLoading}
    renderTitle={() => (
      <tr>
        <th>Aberto em</th>
        <th>Cliente</th>
        <th>Etapa</th>
        <th>Rating</th>
      </tr>
    )}
    renderItem={item => (
      <tr key={item.id} className="h-pointer" onClick={onClick(item)}>
        <td>{item.created_at}</td>
        <td>{item.person.name}</td>
        <td>
          {item.stage && `${item.stage.funnel.name} - ${item.stage.name}`}
        </td>
        <td>
          <StarsRating rating={item.interest} />
        </td>
      </tr>
    )}
    noData={{
      title: 'Sem negócios',
      text: 'Sem nenhum negócio em andamento'
    }}
  />
);

TableDealsOpened.propTypes = propTypes;
TableDealsOpened.defaultProps = defaultProps;

export default TableDealsOpened;
