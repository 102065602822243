import React, { useCallback } from 'react';
import { isPristine, reset } from 'redux-form';
// Components
import Nodata from 'components/Nodata';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button';
import Container from 'components/Container';
// import PropTypes from 'prop-types';
// import {} from './styles';

function NoDeals() {
  const dispatch = useDispatch();

  const isSearching = !useSelector(isPristine('FilterCrm'));

  const resetFilter = useCallback(() => {
    dispatch(reset('FilterCrm'));
  }, [dispatch]);

  if (isSearching) {
    return (
      <Container>
        <Nodata
          title="Nenhum negócio foi encontrado"
          text="Clique em limpar filtro e tente novamente"
        >
          <Button
            onClick={resetFilter}
            color="danger"
            className="h-margin-top--10"
          >
            Limpar filtro
          </Button>
        </Nodata>
      </Container>
    );
  }

  return (
    <Container>
      <Nodata
        title="Nenhum negócio"
        text="Você ainda não possui nenhum negócio ativo"
      />
    </Container>
  );
}

NoDeals.defaultProps = {};
NoDeals.propTypes = {};

export default NoDeals;
