import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import WrapperInner from './components/WrapperInner';
import WrapperContainer from './components/WrapperContainer';

const propTypes = {
  position: PropTypes.oneOf(['column', 'row']),
  className: PropTypes.string,
  children: PropTypes.node,
  full: PropTypes.bool,
  component: PropTypes.string,
  styleContainer: PropTypes.object
};

const defaultProps = {
  position: 'row',
  className: null,
  children: null,
  full: false,
  component: 'div',
  styleContainer: {}
};

const Wrapper = ({
  component: Component,
  full,
  position,
  className,
  children,
  styleContainer,
  ...rest
}) => (
  <Component
    className={classnames('Wrapper', className, {
      [`Wrapper--${position}`]: position
    })}
    {...rest}
  >
    {full ? (
      children
    ) : (
      <WrapperInner>
        <WrapperContainer style={{ ...styleContainer, marginBottom: 0 }}>
          {children}
        </WrapperContainer>
      </WrapperInner>
    )}
  </Component>
);

Wrapper.inner = WrapperInner;
Wrapper.container = WrapperContainer;

Wrapper.propTypes = propTypes;
Wrapper.defaultProps = defaultProps;

export default Wrapper;
