import React from 'react';
import { Wrapper, Img } from './styles';
import noImage from 'components/PropertyItem/no-image.png';
import {
  TextWrapper,
  Title,
  Price,
  Address,
} from 'components/PropertyItem/styles';
import TextOverflow from 'components/TextOverflow';
import { getPropertyStatus } from 'lib/property-helpers';

const Item = ({ data: p, innerProps, ...props }) => {
  const status = getPropertyStatus(p);

  return (
    <Wrapper {...innerProps}>
      <Img src={p?.cover_image?.file_url || noImage} />
      <TextWrapper>
        <Title>
          {p?.type?.title} - Ref.: {p.reference}{' '}
          {status.map((s) => {
            if (s.text === '') return null;
            return (
              <span>
                {' '}
                - <span className={`h-color--${s.color}`}>{s.text}</span>
              </span>
            );
          })}
        </Title>
        <Price transaction={p.transaction}>{p.calculated_price}</Price>
        <Address>
          <TextOverflow width="100%">{p.address_formatted}</TextOverflow>
        </Address>
      </TextWrapper>
    </Wrapper>
  );
};

export default Item;
