import Dropdown, { Item, List } from 'components/Dropdown';
import { useCallback, useMemo } from 'react';
import { MdKeyboardArrowDown as IconDrop } from 'react-icons/md';
import { Link, Wrapper } from './styles';

const SelectLink = ({
  value,
  options,
  labelKey = 'label',
  valueKey = 'value',
  onChange,
  closeOnChange = true,
}) => {
  const selectedOption = useMemo(() => {
    return options.find((option) => option[valueKey] === value);
  }, [value, options]);

  const handleClickItem = useCallback(
    (option, close) => () => {
      if (onChange) onChange(option, value);
      if (closeOnChange) close();
    },
    [value]
  );

  return (
    <Wrapper>
      <Dropdown
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {({ close }) => (
          <>
            <Link>
              {selectedOption?.[labelKey]} <IconDrop />
            </Link>
            <List>
              {options?.map((option) => (
                <Item key={option?.[valueKey]}>
                  <button
                    type="button"
                    onClick={handleClickItem(
                      { value: option?.[valueKey], label: option?.[labelKey] },
                      close
                    )}
                  >
                    {option?.[labelKey]}
                  </button>
                </Item>
              ))}
            </List>
          </>
        )}
      </Dropdown>
    </Wrapper>
  );
};

export default SelectLink;
