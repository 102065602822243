import styled from "styled-components";

export const Content = styled.div`
  margin: 140px 0;
  text-align: center;

  h2 {
    font-size: 24px;
    color: ${(p) => p.theme.colors.danger};
  }

  p {
    margin: 15px 0;
    font-style: italic;
  }
`;
