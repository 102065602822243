import api from 'services';

/**
 * @typedef {Object} TemplatesModel
 * @property {string} id - O ID do modelo.
 * @property {string} title - O título do modelo.
 * @property {string} url - A URL do modelo.
 * @property {string|null} image - A imagem do modelo (ou null se não houver imagem).
 * @property {string} category - A categoria do modelo.
 * @property {boolean} is_selected - Indica se o modelo está selecionado.
 */

/**
 * @typedef {Object} TemplateData
 * @property {TemplateData[]} data - Um array de objetos representando modelos de sites.
 */

/**
 * Retorna a lista de templates disponíveis
 * @returns {Promise<TemplateData>}
 */
export const getTemplates = ({ category }) =>
  api.getList('settings/sites/templates', { category });

/**
 * @typedef {Object} TemplateData
 * @property {string} id - O ID da configuração.
 * @property {string} created_at - A data de criação da configuração.
 * @property {boolean} is_selected - Indica se a configuração está selecionada.
 */

/**
 * @typedef {Object} TemplateResponse
 * @property {TemplateData[]} data - Um array de objetos representando as configurações.
 */

/**
 * Retorna as configurações salvas anteriormente
 * @returns {Promise<TemplateResponse>}
 */
export const getHistory = (params) =>
  api.getList('settings/sites/templates/previous', params);

/**
 * @typedef {("URBAN"|"RURAL"|"BEACH"|"OTHER")} TemplateCategory
 * Enumeração dos possíveis valores de categoria para um template.
 */

/**
 * @typedef {Object} TemplateCategoriesResponse
 * @property {TemplateCategory[]} data - Um array de strings representando as categorias de templates.
 */

/**
 * Retorna as categorias disponíveis para os templates.
 * @returns {Promise<TemplateCategoriesResponse>} Uma Promise que resolve com a resposta da API.
 */
export const getTemplateCategories = () =>
  api.get('settings/sites/templates/categories');

/**
 * Aplica o template no site
 * @param template_id
 */
export const applyTemplate = ({ template_id }) =>
  api.create(`settings/sites/templates/${template_id}`);

/**
 * Remove a configuração atual do template
 * @param template_id
 * @returns {Promise<>}
 */
export const removeConfig = ({ template_id }) =>
  api.delete(`settings/sites/templates/${template_id}`);
