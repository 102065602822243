import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import NavAside from 'components/NavAside';
import TreeNav from 'components/TreeNav';
import Wrapper from 'components/Wrapper';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as pages from './pages';
import LastSearches from 'lib/LastSearches';
import { openModalPropertyDuplicateMulti } from 'modules/modal';
import { useDispatch } from 'react-redux';

export const property = {
  updated_at: '07/05/2024 15:08:50',
  created_at: '08/02/2023 14:48:07',
  id: '276f31b9-7f5b-4c88-8da5-54ddc042ab36',
  calculated_price: 'R$200.000,00',
  previous_price: null,
  condominium_price: null,
  taxes_price: null,
  territorial_tax_price: null,
  territorial_tax_type: 1,
  total_price: 'R$200.000,00',
  transaction: 1,
  reference: '99191947',
  profile: 1,
  situation_id: 'de2b549c-3b7b-48c4-9a66-2ca36a0e8b06',
  land_type: null,
  near_sea: null,
  solar_position_id: null,
  is_property_titled: false,
  is_deeded: true,
  is_corner: false,
  is_on_network: false,
  is_season_available: false,
  primary_area: {
    name: 'private_area',
    title: '\u00c1rea Privativa',
    value: '120,00',
    measure: 'm\u00b2',
  },
  areas: {
    built_area: {
      name: 'built_area',
      title: '\u00c1rea Constru\u00edda',
      value: '140,00',
      measure: 'm\u00b2',
    },
    total_area: {
      name: 'total_area',
      title: '\u00c1rea Total',
      value: '190,00',
      measure: 'm\u00b2',
    },
    private_area: {
      name: 'private_area',
      title: '\u00c1rea Privativa',
      value: '120,00',
      measure: 'm\u00b2',
    },
  },
  rooms: {
    suite: {
      name: 'suite',
      title: 'Sendo su\u00edte',
      value: '2',
      title_formated: '2 Su\u00edtes',
    },
    garage: {
      name: 'garage',
      title: 'Garagem',
      value: '2',
      title_formated: '2 Garagens',
    },
    bedroom: {
      name: 'bedroom',
      title: 'Dormit\u00f3rio',
      value: '3',
      title_formated: '3 Dormit\u00f3rios, sendo 2 su\u00edtes',
    },
    bathroom: {
      name: 'bathroom',
      title: 'Banheiro',
      value: '1',
      title_formated: '1 Banheiro',
    },
  },
  prices_per_area: {
    built_area: {
      title: '\u00c1rea Constru\u00edda',
      price: '1.428,57',
    },
    total_area: {
      title: '\u00c1rea Total',
      price: '1.052,63',
    },
    private_area: {
      title: '\u00c1rea Privativa',
      price: '1.666,67',
    },
  },
  is_financeable_mcmv: null,
  has_finance: false,
  is_financeable: false,
  taxes_description: null,
  tour_360: 'https://tour360.be/W6MDjUwXskI',
  send_summary_every: null,
  meta_title: null,
  meta_description: 'Teste de descri\u00e7\u00e3o',
  custom_url: null,
  description:
    '<p>As informa\u00e7\u00f5es est\u00e3o sujeitas a altera\u00e7\u00f5es. Consulte o corretor respons\u00e1vel para ficar informado.</p><p>As informa\u00e7\u00f5es est\u00e3o sujeitas a altera\u00e7\u00f5es. Consulte o corretor respons\u00e1vel para ficar informado.</p><p>As informa\u00e7\u00f5es est\u00e3o sujeitas a altera\u00e7\u00f5es. Consulte o corretor respons\u00e1vel para ficar informado.</p><p>As informa\u00e7\u00f5es est\u00e3o sujeitas a altera\u00e7\u00f5es. Consulte o corretor respons\u00e1vel para ficar informado.</p>',
  title_formatted: 'Apartamento Padr\u00e3o',
  max_people: null,
  real_estate_id: 'fc01331f-6142-4db2-a187-a4d09f69610b',
  network_type: 'Guru',
  can_send_summary: false,
  delivery_forecast: null,
  lifetime: 2016,
  is_exchangeable: false,
  exchange_note: null,
  incorporation: null,
  video_embed_url: 'https://youtube.com/embed/CkAmJAvD7nQ',
  zone_id: null,
  status: 'Dispon\u00edvel',
  site_link:
    'https://sites.tecimob-dev.com.br/imovel/apartamento-a-venda-no-bairro-centro-tubaraosc/99191947',
  is_grouped_condo: false,
  active: true,
  has_furniture: false,
  created_on_site: false,
  warranties: null,
  formatted_condo_position: null,
  matriculation: null,
  informations: {
    floor: {
      name: 'floor',
      title: 'Andar',
      value: '4',
    },
    apartment_number: {
      name: 'apartment_number',
      title: 'Unidade',
      value: '304',
    },
  },
  address_formatted: 'Centro - Tubar\u00e3o/SC',
  zip_code: '88701-610',
  street_address: 'Rua Santos Dumont',
  street_number: '83',
  complement_address: null,
  maps_latitude: -28.484760632379,
  maps_longitude: -49.01753882119,
  maps_heading: null,
  maps_pitch: null,
  maps_zoom: 15,
  maps_street_zoom: null,
  video: 'https://www.youtube.com/watch?v=CkAmJAvD7nQ',
  occupation_note: '',
  furniture_note: '',
  private_note: '',
  negotiation_note: null,
  is_published: false,
  is_home_published: null,
  is_network_published: true,
  is_neighborhood_shown: true,
  is_street_shown: true,
  is_complement_shown: true,
  is_street_number_shown: true,
  is_exact_map_shown: false,
  is_map_shown: false,
  is_streetview_shown: false,
  is_floor_shown: false,
  is_apartment_number_shown: false,
  is_commission_combined: false,
  is_keys_ready: false,
  is_condominium_shown: true,
  is_applying_watermark: false,
  has_owner_authorization: false,
  has_sale_card: false,
  should_apply_watermark: false,
  price_financial_index_id: 'eae1796b-bc96-43b1-b574-0ee7528b9ae4',
  subtype_id: '51d2b64a-8967-4d77-815b-6d109ef6765b',
  user_id: 'f0cf6170-c6a8-4392-a737-7426007d00b4',
  people_id: 'f33a36e0-1318-4676-abf5-0930ae90800f',
  condominium_id: null,
  neighborhood_id: 'f939bc44-2ab5-49d0-bf6b-c8de67da4a2b',
  exclusive_until: null,
  is_exclusive: false,
  is_featured: false,
  is_exclusivity_expired: false,
  is_renovation_expired: true,
  next_review_at: '12/02/2023',
  last_review_at: '08/02/2023',
  network_property_id: null,
  keys_location: null,
  price: 'R$200.000,00',
  static_street_view_url: null,
  stripe_text: 'Pronto para morar',
  stripe_background: '#53952a',
  is_draft: false,
  is_blocked: false,
  is_price_shown: true,
  price_alternative_text: null,
  title: null,
  itr: null,
  country: {
    updated_at: '08/04/2019 14:44:03',
    created_at: '08/04/2019 14:44:03',
    id: 'dfb22ce9-8112-4e60-bc92-ecdba85b1ddc',
    name: 'Brasil',
  },
  state: {
    updated_at: '08/04/2019 14:44:03',
    created_at: '08/04/2019 14:44:03',
    id: '3fa3f716-8361-4263-89ce-6a57f6c93c96',
    name: 'Santa Catarina',
    acronym: 'SC',
    country_id: 'dfb22ce9-8112-4e60-bc92-ecdba85b1ddc',
  },
  city: {
    updated_at: '2024-05-26 01:44:54',
    created_at: '2019-04-08 14:44:29',
    id: '445cb4ba-f419-456c-85ce-57ed60c8df0f',
    name: 'Tubar\u00e3o',
    real_estate_id: null,
    state_id: '3fa3f716-8361-4263-89ce-6a57f6c93c96',
    state: {
      updated_at: '08/04/2019 14:44:03',
      created_at: '08/04/2019 14:44:03',
      id: '3fa3f716-8361-4263-89ce-6a57f6c93c96',
      name: 'Santa Catarina',
      acronym: 'SC',
      country_id: 'dfb22ce9-8112-4e60-bc92-ecdba85b1ddc',
    },
  },
  real_estate: {
    updated_at: '30/07/2024 15:50:54',
    created_at: '09/04/2019 13:58:17',
    id: 'fc01331f-6142-4db2-a187-a4d09f69610b',
    blocked: false,
    name: 'Desenvolvedores',
    contact_email: 'feuser@tecimob.com.br',
    neighborhood_id: 'f55d8226-e0ea-488f-a8b6-84872e875482',
    address_cep: '88704-010',
    address_street: 'Rua Pedro Lemos',
    address_number: '1033',
    address_complement: 'Casa',
    social_medias: {
      facebook: 'https://facebook.com',
      twitter: 'https://twitter.com/vela_men',
      blog: 'https://blog.tecimob.com.br',
      tiktok:
        'https://www.tiktok.com/@gustta_shoow/video/7140363873563020550?is_from_webapp=1&sender_device=pc',
    },
    has_coordinates: true,
    file_url:
      'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/tecimob/mailing/logo_tecimob.png',
    header_background: '#176ec8',
    header_type: 'Header1',
    maps_latitude: '-28.4832849',
    maps_longitude: '-49.0261889',
    is_address_shown: true,
    type: '1',
    cpf_cnpj: '070.357.079-05',
    serial: 2,
    resale_id: '3390c2f2-cc7a-4f15-8213-05fed687eee7',
    is_paying: true,
    mailboxes_count: 1,
    redirect_mails_count: 0,
    users_count: 6,
    with_grouped_condos: true,
    with_guru: true,
    with_guru_portal: true,
    recurring_charge: false,
    plan: 12,
    receiving_method: 1,
    has_installment: false,
    financial_user_id: '129cfc1d-9087-40e6-97b2-df3ada9b4489',
    primary_domain: {
      updated_at: '10/04/2024 09:00:29',
      created_at: '10/04/2024 08:37:37',
      id: 'f1639ac5-5a12-4f67-9d5d-5cf409c06378',
      url: 'https://sites.tecimob-dev.com.br',
      raw_url: 'sites.tecimob-dev.com.br',
      dns: null,
      dns_status: 3,
      is_primary: true,
      is_using_www: false,
      ns1: 'connie.ns.cloudflare.com',
      ns2: 'roan.ns.cloudflare.com',
    },
  },
  type: {
    updated_at: '13/05/2019 16:31:20',
    created_at: '08/04/2019 14:44:03',
    id: '5d217d2e-4167-4fab-98a0-230fa5a2150d',
    title: 'Apartamento',
    rooms_fields: [
      {
        name: 'bedroom',
        title: 'Dormit\u00f3rio',
        is_default: true,
      },
      {
        name: 'suite',
        title: 'Sendo su\u00edte',
        is_default: true,
      },
      {
        name: 'bathroom',
        title: 'Banheiro',
        is_default: false,
      },
      {
        name: 'garage',
        extra: [
          {
            name: 'is_covered',
            title: 'Garagem coberta?',
          },
          {
            name: 'has_box_in_garage',
            title: 'Possui box na garagem?',
          },
        ],
        title: 'Garagem',
        is_default: true,
      },
      {
        name: 'tvroom',
        title: 'Sala de TV',
        is_default: false,
      },
      {
        name: 'diningroom',
        title: 'Sala de jantar',
        is_default: false,
      },
      {
        name: 'livingroom',
        title: 'Sala de estar',
        is_default: false,
      },
      {
        name: 'washbasin',
        title: 'Lavabo',
        is_default: false,
      },
      {
        name: 'service_area',
        title: '\u00c1rea de servi\u00e7o',
        is_default: false,
      },
      {
        name: 'kitchen',
        title: 'Cozinha',
        is_default: false,
      },
      {
        name: 'closet',
        title: 'Closet',
        is_default: false,
      },
      {
        name: 'office',
        title: 'Escrit\u00f3rio',
        is_default: false,
      },
      {
        name: 'employeeDependency',
        title: 'Depend\u00eancia p/empregada',
        is_default: false,
      },
      {
        name: 'pantry',
        title: 'Copa',
        is_default: false,
      },
    ],
    information_fields: [
      {
        name: 'floor',
        title: 'Andar',
      },
      {
        name: 'apartment_number',
        title: 'Unidade',
      },
      {
        name: 'units_per_floor',
        title: 'Unidades por andar',
      },
      {
        name: 'floors_number',
        title: 'Total de andares',
      },
      {
        name: 'towers_number',
        title: 'Total de torres',
      },
    ],
    area_fields: [
      {
        name: 'built_area',
        title: '\u00c1rea Constru\u00edda',
        measures: ['m\u00b2', 'ha'],
        is_primary: false,
      },
      {
        name: 'private_area',
        title: '\u00c1rea Privativa',
        measures: ['m\u00b2', 'ha'],
        is_primary: true,
      },
      {
        name: 'total_area',
        title: '\u00c1rea Total',
        measures: ['m\u00b2', 'ha'],
        is_primary: false,
      },
    ],
  },
  neighborhood: {
    updated_at: '2024-05-26 06:27:59',
    created_at: '2019-04-08 14:44:29',
    id: 'f939bc44-2ab5-49d0-bf6b-c8de67da4a2b',
    name: 'Centro',
    city_id: '445cb4ba-f419-456c-85ce-57ed60c8df0f',
    real_estate_id: null,
    city: {
      updated_at: '2024-05-26 01:44:54',
      created_at: '2019-04-08 14:44:29',
      id: '445cb4ba-f419-456c-85ce-57ed60c8df0f',
      name: 'Tubar\u00e3o',
      real_estate_id: null,
      state_id: '3fa3f716-8361-4263-89ce-6a57f6c93c96',
      state: {
        updated_at: '08/04/2019 14:44:03',
        created_at: '08/04/2019 14:44:03',
        id: '3fa3f716-8361-4263-89ce-6a57f6c93c96',
        name: 'Santa Catarina',
        acronym: 'SC',
        country_id: 'dfb22ce9-8112-4e60-bc92-ecdba85b1ddc',
      },
    },
    state: {
      updated_at: '08/04/2019 14:44:03',
      created_at: '08/04/2019 14:44:03',
      id: '3fa3f716-8361-4263-89ce-6a57f6c93c96',
      name: 'Santa Catarina',
      acronym: 'SC',
      country_id: 'dfb22ce9-8112-4e60-bc92-ecdba85b1ddc',
    },
    country: {
      updated_at: '08/04/2019 14:44:03',
      created_at: '08/04/2019 14:44:03',
      id: 'dfb22ce9-8112-4e60-bc92-ecdba85b1ddc',
      name: 'Brasil',
    },
  },
  permissions: [
    'DYNAMIC_PERSON_NOTES',
    'DYNAMIC_EDIT',
    'DYNAMIC_DOCUMENTS',
    'DYNAMIC_ASSOCIATE_TO_OTHER',
    'DYNAMIC_PUBLISH',
    'DYNAMIC_PUBLISH_PORTAL',
    'DYNAMIC_DELETE',
    'DYNAMIC_BOOKING',
    'DYNAMIC_PRIVATE',
    'DYNAMIC_RENT',
    'DYNAMIC_SELL',
    'DYNAMIC_TRASH',
    'DYNAMIC_REACTIVE',
    'DYNAMIC_NOTES',
    'DYNAMIC_ANALYSIS',
    'DYNAMIC_STORIES',
  ],
  situation: {
    updated_at: '07/02/2020 08:25:45',
    created_at: '07/02/2020 08:25:45',
    id: 'de2b549c-3b7b-48c4-9a66-2ca36a0e8b06',
    title: 'Pronto para morar',
    order: 15,
  },
  subtype: {
    updated_at: '08/04/2019 14:44:03',
    created_at: '08/04/2019 14:44:03',
    id: '51d2b64a-8967-4d77-815b-6d109ef6765b',
    title: 'Padr\u00e3o',
    type: {
      updated_at: '13/05/2019 16:31:20',
      created_at: '08/04/2019 14:44:03',
      id: '5d217d2e-4167-4fab-98a0-230fa5a2150d',
      title: 'Apartamento',
      rooms_fields: [
        {
          name: 'bedroom',
          title: 'Dormit\u00f3rio',
          is_default: true,
        },
        {
          name: 'suite',
          title: 'Sendo su\u00edte',
          is_default: true,
        },
        {
          name: 'bathroom',
          title: 'Banheiro',
          is_default: false,
        },
        {
          name: 'garage',
          extra: [
            {
              name: 'is_covered',
              title: 'Garagem coberta?',
            },
            {
              name: 'has_box_in_garage',
              title: 'Possui box na garagem?',
            },
          ],
          title: 'Garagem',
          is_default: true,
        },
        {
          name: 'tvroom',
          title: 'Sala de TV',
          is_default: false,
        },
        {
          name: 'diningroom',
          title: 'Sala de jantar',
          is_default: false,
        },
        {
          name: 'livingroom',
          title: 'Sala de estar',
          is_default: false,
        },
        {
          name: 'washbasin',
          title: 'Lavabo',
          is_default: false,
        },
        {
          name: 'service_area',
          title: '\u00c1rea de servi\u00e7o',
          is_default: false,
        },
        {
          name: 'kitchen',
          title: 'Cozinha',
          is_default: false,
        },
        {
          name: 'closet',
          title: 'Closet',
          is_default: false,
        },
        {
          name: 'office',
          title: 'Escrit\u00f3rio',
          is_default: false,
        },
        {
          name: 'employeeDependency',
          title: 'Depend\u00eancia p/empregada',
          is_default: false,
        },
        {
          name: 'pantry',
          title: 'Copa',
          is_default: false,
        },
      ],
      information_fields: [
        {
          name: 'floor',
          title: 'Andar',
        },
        {
          name: 'apartment_number',
          title: 'Unidade',
        },
        {
          name: 'units_per_floor',
          title: 'Unidades por andar',
        },
        {
          name: 'floors_number',
          title: 'Total de andares',
        },
        {
          name: 'towers_number',
          title: 'Total de torres',
        },
      ],
      area_fields: [
        {
          name: 'built_area',
          title: '\u00c1rea Constru\u00edda',
          measures: ['m\u00b2', 'ha'],
          is_primary: false,
        },
        {
          name: 'private_area',
          title: '\u00c1rea Privativa',
          measures: ['m\u00b2', 'ha'],
          is_primary: true,
        },
        {
          name: 'total_area',
          title: '\u00c1rea Total',
          measures: ['m\u00b2', 'ha'],
          is_primary: false,
        },
      ],
    },
  },
  images: [
    {
      updated_at: '22/03/2023 13:42:35',
      created_at: '08/02/2023 14:51:56',
      id: '63667428-f9da-4d6f-9485-5960400bf250',
      gallery: 1,
      caption: null,
      order: 1,
      property_id: '276f31b9-7f5b-4c88-8da5-54ddc042ab36',
      file_url: {
        large:
          'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/276f31b9-7f5b-4c88-8da5-54ddc042ab36/images/63667428-f9da-4d6f-9485-5960400bf2501679503355eAth.jpg',
        medium:
          'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/276f31b9-7f5b-4c88-8da5-54ddc042ab36/images/600x450/outside/63667428-f9da-4d6f-9485-5960400bf2501679503355eAth.jpg',
      },
      is_external: false,
    },
    {
      updated_at: '22/03/2023 13:42:35',
      created_at: '08/02/2023 14:51:56',
      id: '7cec26bb-4629-4834-a961-d54aedeea284',
      gallery: 1,
      caption: null,
      order: 2,
      property_id: '276f31b9-7f5b-4c88-8da5-54ddc042ab36',
      file_url: {
        large:
          'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/276f31b9-7f5b-4c88-8da5-54ddc042ab36/images/7cec26bb-4629-4834-a961-d54aedeea2841679503355yuG1.jpg',
        medium:
          'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/276f31b9-7f5b-4c88-8da5-54ddc042ab36/images/600x450/outside/7cec26bb-4629-4834-a961-d54aedeea2841679503355yuG1.jpg',
      },
      is_external: false,
    },
    {
      updated_at: '22/03/2023 13:42:35',
      created_at: '08/02/2023 14:51:56',
      id: 'b21748a6-78ed-490e-911d-a8f618ae604b',
      gallery: 1,
      caption: null,
      order: 3,
      property_id: '276f31b9-7f5b-4c88-8da5-54ddc042ab36',
      file_url: {
        large:
          'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/276f31b9-7f5b-4c88-8da5-54ddc042ab36/images/b21748a6-78ed-490e-911d-a8f618ae604b1679503355ILEd.jpg',
        medium:
          'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/276f31b9-7f5b-4c88-8da5-54ddc042ab36/images/600x450/outside/b21748a6-78ed-490e-911d-a8f618ae604b1679503355ILEd.jpg',
      },
      is_external: false,
    },
    {
      updated_at: '22/03/2023 13:42:35',
      created_at: '08/02/2023 14:51:56',
      id: '4b5ae6d2-570c-4070-b5ca-7c06102a58e3',
      gallery: 1,
      caption: null,
      order: 4,
      property_id: '276f31b9-7f5b-4c88-8da5-54ddc042ab36',
      file_url: {
        large:
          'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/276f31b9-7f5b-4c88-8da5-54ddc042ab36/images/4b5ae6d2-570c-4070-b5ca-7c06102a58e31679503355Evez.jpg',
        medium:
          'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/276f31b9-7f5b-4c88-8da5-54ddc042ab36/images/600x450/outside/4b5ae6d2-570c-4070-b5ca-7c06102a58e31679503355Evez.jpg',
      },
      is_external: false,
    },
    {
      updated_at: '22/03/2023 13:42:36',
      created_at: '08/02/2023 14:51:57',
      id: 'e946cc98-df75-4e02-9d58-35f343d8ca75',
      gallery: 1,
      caption: null,
      order: 5,
      property_id: '276f31b9-7f5b-4c88-8da5-54ddc042ab36',
      file_url: {
        large:
          'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/276f31b9-7f5b-4c88-8da5-54ddc042ab36/images/e946cc98-df75-4e02-9d58-35f343d8ca751679503355mCIl.jpg',
        medium:
          'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/276f31b9-7f5b-4c88-8da5-54ddc042ab36/images/600x450/outside/e946cc98-df75-4e02-9d58-35f343d8ca751679503355mCIl.jpg',
      },
      is_external: false,
    },
  ],
  stages: [],
  solar_position: null,
  condo_characteristics: [],
  characteristics: [
    {
      updated_at: '17/02/2020 10:06:23',
      created_at: '08/04/2019 14:44:03',
      id: '18861796-51a8-4228-8b4b-45b8a76bba81',
      title: 'Aquecimento solar',
      is_default: true,
      has_quantity: false,
      quantity: null,
    },
  ],
  establishments: [],
  condominium: null,
  people: {
    updated_at: '16/02/2024 15:26:45',
    created_at: '09/06/2022 18:31:55',
    id: 'f33a36e0-1318-4676-abf5-0930ae90800f',
    name: 'Billie Joe',
    user_id: '8d5a1556-ead8-45f3-aeb0-48d76b4e4489',
    is_owner: false,
    active: true,
    is_on_network: false,
    interacted_at: '07/05/2024',
    status: '1',
    address_street: 'Avenida Obedy C\u00e2ndido Vieira',
    address_complement: null,
    address_number: '440',
    address_cep: '94930-660',
    cpf_cnpj: '106.264.439-54',
    rg_ie: null,
    email: null,
    birth_date: '25/01/2001',
    search_profile_id: null,
    matched_id: null,
    type: '1',
    profession: null,
    description: null,
  },
  agent: {
    updated_at: '02/08/2024 09:56:41',
    created_at: '09/04/2019 14:14:33',
    id: 'f0cf6170-c6a8-4392-a737-7426007d00b4',
    name: 'Thiago Nunes',
    email: 'nunes@tecimob.com.br',
    realtor: true,
    creci: '232323',
    cellphone: '(48) 9 9651-3040',
    phone: null,
    real_estate_id: 'fc01331f-6142-4db2-a187-a4d09f69610b',
    file_url:
      'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/users/{size}/{type}/f0cf6170-c6a8-4392-a737-7426007d00b4/Mqyu1TtHNTMXeV9ip4vdO43vZ3VcaQPT9EPcSuoh.jpeg',
    serial: 16,
    creci_status: 3,
    creci_url: null,
    creci_name: null,
    document_url: null,
    document_name: null,
    creci_expiration: null,
    active: true,
    group_id: '0476891b-ae71-4842-9e4f-18de7cee7d66',
    wizards: {
      'wizard/dashboard-1-wizard': true,
      'crm/funnel': true,
      'wizard/crm-layout-wizard': true,
      'config/financial': true,
      'wizard/enterprise-store2-wizard': true,
      'wizard-video-dashboard-10': true,
      'site/popup': true,
      'site/banner': true,
      'wizard/crm-dashboard-2-wizard': true,
      'properties/revision': true,
      'config/texts': true,
      'site/superhighlights': true,
      EmailAccounts: true,
    },
    should_ask_satisfaction: false,
    recommendation_button_show: true,
    recommendation_pix: 'douglaspigoulart@gmail.com',
  },
  user: {
    updated_at: '02/08/2024 09:56:41',
    created_at: '09/04/2019 14:14:33',
    id: 'f0cf6170-c6a8-4392-a737-7426007d00b4',
    name: 'Thiago Nunes',
    email: 'nunes@tecimob.com.br',
    realtor: true,
    creci: '232323',
    cellphone: '(48) 9 9651-3040',
    phone: null,
    real_estate_id: 'fc01331f-6142-4db2-a187-a4d09f69610b',
    file_url:
      'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grq6lwb4htd1/b/tecimob-production/o/media/fc01331f-6142-4db2-a187-a4d09f69610b/users/{size}/{type}/f0cf6170-c6a8-4392-a737-7426007d00b4/Mqyu1TtHNTMXeV9ip4vdO43vZ3VcaQPT9EPcSuoh.jpeg',
    serial: 16,
    creci_status: 3,
    creci_url: null,
    creci_name: null,
    document_url: null,
    document_name: null,
    creci_expiration: null,
    active: true,
    group_id: '0476891b-ae71-4842-9e4f-18de7cee7d66',
    wizards: {
      'wizard/dashboard-1-wizard': true,
      'crm/funnel': true,
      'wizard/crm-layout-wizard': true,
      'config/financial': true,
      'wizard/enterprise-store2-wizard': true,
      'wizard-video-dashboard-10': true,
      'site/popup': true,
      'site/banner': true,
      'wizard/crm-dashboard-2-wizard': true,
      'properties/revision': true,
      'config/texts': true,
      'site/superhighlights': true,
      EmailAccounts: true,
    },
    should_ask_satisfaction: false,
    recommendation_button_show: true,
    recommendation_pix: 'douglaspigoulart@gmail.com',
  },
  rate: {
    rate: 10,
    fields: [
      {
        key: 'lifetime',
        title: 'Ano de constru\u00e7\u00e3o',
        value: true,
      },
      {
        key: 'bedroom',
        title: 'C\u00f4modos - Dormit\u00f3rio',
        value: true,
      },
      {
        key: 'bathroom',
        title: 'C\u00f4modos - Banheiro',
        value: true,
      },
      {
        key: 'areas',
        title: 'Medidas',
        value: true,
      },
      {
        key: 'price',
        title: 'Pre\u00e7o',
        value: true,
      },
      {
        key: 'zip_code',
        title: 'CEP',
        value: true,
      },
      {
        key: 'street_address',
        title: 'Logradouro',
        value: true,
      },
      {
        key: 'floor',
        title: 'Andar',
        value: true,
      },
      {
        key: 'description_300',
        title: 'Descri\u00e7\u00e3o com no m\u00ednimo 300 caract\u00e9res',
        value: true,
      },
      {
        key: 'images_5',
        title: 'Ao menos 5 imagens',
        value: true,
      },
    ],
  },
  matcheds_sent_count: 4,
  matcheds_new_count: 0,
  documents_count: 0,
  deals_without_pipeline_count: 1,
  notes_count: 1,
  notes_auth_count: 1,
  bookings_active_count: 0,
};

function Tests() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      openModalPropertyDuplicateMulti({
        property,
      })
    );

    const search = new LastSearches(
      [
        { transaction: '1', price: 'R$ 100.00' },
        { transaction: '2', price: 'R$ 50.00' },
      ],
      10
    );

    search.add({ transaction: '1', price: 'R$ 11.00' });
    search.add({ transaction: '1', price: 'R$ 12.00' });
    search.add({ transaction: '1', price: 'R$ 13.00' });
    search.add({ transaction: '1', price: 'R$ 14.00' });
    search.add({ transaction: '1', price: 'R$ 15.00' });
    search.add({ transaction: '1', price: 'R$ 16.00' });
    search.add({ transaction: '1', price: 'R$ 17.00' });
    search.add({ transaction: '1', price: 'R$ 18.00' });
    search.add({ transaction: '1', price: 'R$ 19.00' });
    search.add({ transaction: '1', price: 'R$ 20.00' });
    search.add({ transaction: '1', price: 'R$ 21.00' });
    search.add({ transaction: '1', price: 'R$ 22.00' });
    search.add({ transaction: '1', price: 'R$ 23.00' });
    search.add({ transaction: '1', price: 'R$ 24.00' });
    search.add({ transaction: '1', price: 'R$ 25.00' });
    search.add({ transaction: '1', price: 'R$ 11.00' });
    search.add({ transaction: '1', price: 'R$ 11.00' });
  }, []);

  return (
    <Wrapper full>
      <Helmet>
        <title>Testes</title>
      </Helmet>
      <NavAside>
        <TreeNav.link to="/tests/labels">Labels</TreeNav.link>
        <TreeNav.link to="/tests/alerts">Alertas</TreeNav.link>
        <TreeNav to="/tests/default" title="Testes normais" />
        <TreeNav to="/tests/cards" title="Cards">
          <TreeNav.link to="/tests/cards/card-property">
            Card do imóvel
          </TreeNav.link>
          <TreeNav.link to="/tests/cards/card-condo">
            Card do condomínio
          </TreeNav.link>
          <TreeNav.link to="/tests/cards/card-image">
            Card da imagem do imóvel
          </TreeNav.link>
          <TreeNav.link to="/tests/cards/card-crm">Cards do Crm</TreeNav.link>
        </TreeNav>
      </NavAside>
      <Wrapper.inner>
        <Wrapper.container>
          <Switch>
            <Route path="/tests/default" component={pages.DefaultTests} />
            <Route path="/tests/labels" component={pages.Labels} />
            <Route path="/tests/alerts" component={pages.Alerts} />
            <Route
              path="/tests/cards/card-property"
              component={pages.CardProperty}
            />
            <Route path="/tests/cards/card-condo" component={pages.CardCondo} />
            <Route path="/tests/cards/card-image" component={pages.CardImage} />
            <Route path="/tests/cards/card-crm" component={pages.CardCrm} />
            <Redirect to="/tests/default" />
          </Switch>
        </Wrapper.container>
      </Wrapper.inner>
    </Wrapper>
  );
}

export default Tests;
