import Button from 'components/Button';
import { ModalContent, ModalTemplate } from 'components/Modal';
import { Content } from './styles';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';

const PaymentError = () => {
  const { setHasError } = useModalContractExtra();

  return (
    <ModalTemplate title="Pagamento" text="Falha">
      <ModalContent>
        <Content>
          <h2>Falha na cobrança</h2>
          <p>
            Verifique se existe alguma mensagem no aplicativo do seu banco,
            <br />
            ou entre em contato com sua operadora de cartão e tente novamente
          </p>
          <Button
            color="tertiary"
            size="small"
            onClick={() => setHasError(false)}
          >
            Voltar
          </Button>
        </Content>
      </ModalContent>
    </ModalTemplate>
  );
};

export default PaymentError;
