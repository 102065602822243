import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapText = styled.div`
  width: 400px;

  h2 {
    font-size: 16px;
  }

  p {
    margin: 15px 0;
  }
`;
export const WrapImage = styled.div``;

export const Label = styled.label`
  user-select: none;
  margin-left: 15px;
  cursor: pointer;

  input {
    margin-right: 5px;
    vertical-align: middle;
  }
`;
