// Widgets.js
import { createModule } from 'lib/reducer-helpers';

const createAction = createModule('modalConfirmation');

// Actions
const OPEN = createAction('OPEN');
const CANCEL = createAction('CANCEL');

// Selectors
export const getModalConfirmation = (state) => state.modalConfirmation;

const initialState = {
  title: 'Deletar',
  cancelButtonText: 'Cancelar',
  submitButtonText: 'Remover',
  text: null,
  request: null,
  onSuccess: null,
  onFail: null,
  onFinally: null,
  onCancel: null,
  isOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        size: action.size,
        title: action.title,
        text: action.text,
        cancelButtonText: action.cancelButtonText,
        cancelButtonColor: action.cancelButtonColor,
        cancelButtonColorText: action.cancelButtonColorText,
        submitButtonText: action.submitButtonText,
        submitButtonColor: action.submitButtonColor,
        submitButtonColorText: action.submitButtonColorText,
        disableSubmitButton: action.disableSubmitButton,
        request: action.request,
        onSuccess: action.onSuccess,
        onFail: action.onFail,
        onFinally: action.onFinally,
        onCancel: action.onCancel,
        isOpen: true,
      };
    case CANCEL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators

/**
 * Abre a confirmação
 * @param {String|number} size
 * @param {String|required} title
 * @param {Function|required} request
 * @param {Node|required} text
 * @param {String} cancelButtonText
 * @param {String} submitButtonText
 * @param {String} submitButtonColor
 * @param {String} cancelButtonColor
 * @param {String} cancelButtonColorText
 * @param {String} submitButtonColorText
 * @param {Boolean} disableSubmitButton
 * @param {Function} onSuccess
 * @param {Function} onFail
 * @param {Function} onFinally
 * @param {Function} onCancel
 */
export const openConfirmation = ({
  size,
  title,
  text,
  cancelButtonText = 'Cancelar',
  cancelButtonColor = 'white',
  cancelButtonColorText = 'secondary',
  submitButtonText = 'Remover',
  submitButtonColor = 'danger',
  submitButtonColorText = 'white',
  disableSubmitButton = false,
  request,
  onSuccess,
  onFail,
  onFinally,
  onCancel,
}) => ({
  size,
  type: OPEN,
  title,
  text,
  cancelButtonText,
  submitButtonText,
  submitButtonColor,
  cancelButtonColor,
  cancelButtonColorText,
  submitButtonColorText,
  disableSubmitButton,
  request,
  onSuccess,
  onFail,
  onFinally,
  onCancel,
});

export const cancelRequest = () => ({ type: CANCEL });
