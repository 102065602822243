export const response = {
  data: [
    {
      updated_at: '13/04/2021 10:43:01',
      created_at: '13/04/2021 10:43:01',
      id: 'a2912b72-5abf-49f9-811a-9e890e964706',
      gallery: 1,
      caption: 'teste com texto bem grande pra testar os 3 pontos',
      order: 2,
      is_featured: true,
      property_id: '465c5285-b291-4335-a566-711534bc7b6c',
      file_url:
        'http://tecimob-development.s3-website-us-east-1.amazonaws.com/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/465c5285-b291-4335-a566-711534bc7b6c/images/{size}/{type}/VnRKYFwwiHtNDkg6WoHcljNDdFgq2O2rIlfIULUB.jpg'
    },
    {
      updated_at: '13/04/2021 10:43:01',
      created_at: '13/04/2021 10:43:01',
      id: '30ac2606-32bd-4b5f-99e4-cb5c379c11ee',
      gallery: 1,
      caption: 'Sala de estar',
      order: 3,
      property_id: '465c5285-b291-4335-a566-711534bc7b6c',
      file_url:
        'http://tecimob-development.s3-website-us-east-1.amazonaws.com/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/465c5285-b291-4335-a566-711534bc7b6c/images/{size}/{type}/cWqbr2T82KsHa2FEdIV24rBDDdN5J8Khh2Jzncev.jpg'
    },
    {
      updated_at: '13/04/2021 10:43:01',
      created_at: '13/04/2021 10:43:01',
      id: '6e5268fb-425b-4942-8d91-9ec0669d018f',
      gallery: 1,
      caption: null,
      order: 4,
      property_id: '465c5285-b291-4335-a566-711534bc7b6c',
      file_url:
        'http://tecimob-development.s3-website-us-east-1.amazonaws.com/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/465c5285-b291-4335-a566-711534bc7b6c/images/{size}/{type}/Ish2dOTIo63OJvydqvdqszGgsD4NRgQgv8ddTmAF.jpg'
    },
    {
      updated_at: '13/04/2021 10:43:01',
      created_at: '13/04/2021 10:43:01',
      id: 'd684bf22-5712-47ae-8e0b-2d25f3e3faa2',
      gallery: 1,
      caption: null,
      order: 5,
      property_id: '465c5285-b291-4335-a566-711534bc7b6c',
      file_url:
        'http://tecimob-development.s3-website-us-east-1.amazonaws.com/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/465c5285-b291-4335-a566-711534bc7b6c/images/{size}/{type}/6Ahu37inTl745ImbRaZnuqACEDn2heN5XcBIWq7o.jpg'
    },
    {
      updated_at: '13/04/2021 10:43:01',
      created_at: '13/04/2021 10:43:01',
      id: '3835bb25-2e9d-4120-beb8-41ad4b537cb5',
      gallery: 1,
      caption: null,
      order: 6,
      property_id: '465c5285-b291-4335-a566-711534bc7b6c',
      file_url:
        'http://tecimob-development.s3-website-us-east-1.amazonaws.com/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/465c5285-b291-4335-a566-711534bc7b6c/images/{size}/{type}/PdxnOxnjWgQgbo4djKZgMddHTGNlFX2tH0iin8CO.jpg'
    },
    {
      updated_at: '13/04/2021 10:43:01',
      created_at: '13/04/2021 10:43:01',
      id: '0212d46d-1da7-4e24-9408-59098b861744',
      gallery: 1,
      caption: null,
      order: 7,
      property_id: '465c5285-b291-4335-a566-711534bc7b6c',
      file_url:
        'http://tecimob-development.s3-website-us-east-1.amazonaws.com/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/465c5285-b291-4335-a566-711534bc7b6c/images/{size}/{type}/7O7gU4TLrcCA7pjqrxLDuYZSdC2LQ9eS1boAY6uJ.jpg'
    },
    {
      updated_at: '13/04/2021 10:43:01',
      created_at: '13/04/2021 10:43:01',
      id: '9528386f-9574-4737-ab11-91cec24cb40d',
      gallery: 1,
      caption: null,
      order: 8,
      property_id: '465c5285-b291-4335-a566-711534bc7b6c',
      file_url:
        'http://tecimob-development.s3-website-us-east-1.amazonaws.com/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/465c5285-b291-4335-a566-711534bc7b6c/images/{size}/{type}/JSfSIh5YrCkTuY6Eof0JUiuOlce5k9SOahL3llz1.jpg'
    },
    {
      updated_at: '13/04/2021 10:43:01',
      created_at: '13/04/2021 10:43:01',
      id: '143cf8ba-0f38-4d24-9a31-ed72492fd936',
      gallery: 1,
      caption: null,
      order: 9,
      property_id: '465c5285-b291-4335-a566-711534bc7b6c',
      file_url:
        'http://tecimob-development.s3-website-us-east-1.amazonaws.com/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/465c5285-b291-4335-a566-711534bc7b6c/images/{size}/{type}/rLDAf0LhH318O6e9jeMkMNoQXToqhJxi37vwJvCB.jpg'
    },
    {
      updated_at: '13/04/2021 10:43:02',
      created_at: '13/04/2021 10:43:01',
      id: '36c7a926-bcc1-4e1c-81e2-9de2ff0e327f',
      gallery: 1,
      caption: null,
      order: 10,
      property_id: '465c5285-b291-4335-a566-711534bc7b6c',
      file_url:
        'http://tecimob-development.s3-website-us-east-1.amazonaws.com/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/465c5285-b291-4335-a566-711534bc7b6c/images/{size}/{type}/WJ8lEnRS6tPCanG2pMkxQdhlqsuuEi9MQJIqzduh.jpg'
    },
    {
      updated_at: '13/04/2021 10:43:01',
      created_at: '13/04/2021 10:43:01',
      id: 'cb0d2980-4f49-43e0-948d-cc1193874905',
      gallery: 1,
      caption: null,
      order: 11,
      property_id: '465c5285-b291-4335-a566-711534bc7b6c',
      file_url:
        'http://tecimob-development.s3-website-us-east-1.amazonaws.com/media/fc01331f-6142-4db2-a187-a4d09f69610b/properties/465c5285-b291-4335-a566-711534bc7b6c/images/{size}/{type}/8tBfhzUsUonI3pXYAPFSu2uUE9aVQJ0C6MYAFySg.jpg'
    }
  ]
};
