import Button from "components/Button";
import { Input } from "components/Form";
import GroupControl, { GroupItem } from "components/GroupControl";
import useReduxForm from "hooks/useReduxForm";
import { normalizeCep } from "lib/formHelpers";
import { useCallback } from "react";
import { Col, Row } from "react-flexbox-grid";
import { Field } from "redux-form";
import * as cepsService from 'services/ceps';
import Alert from 'react-s-alert';
import { useLocation } from "containers/LocationAsyncFields/context";

function Cep({ onChangeCep }) {
  const { change, dispatch } = useReduxForm();

  const {
    countryName,
    stateName,
    cityName,
    neighborhoodName,
    reset
  } = useLocation();

  const fetchCep = useCallback(
    async cep => {
      try {
        const { data } = await cepsService.getLocation({ cep });

        reset();

        dispatch(change(countryName, data?.country?.id));
        dispatch(change(stateName, data?.state?.id));
        dispatch(change(cityName, data?.city?.id));
        dispatch(change(neighborhoodName, data?.neighborhood?.id));

        dispatch(change('street_address', data?.street_address));
        dispatch(change('street_number', data?.street_number));

        if (onChangeCep) onChangeCep(data);
      } catch (e) {
        if (e?.response?.status === 404) {
          Alert.success(`Ops! Não encontramos dados para o CEP ${cep}.`);
        }
      }
    },
    [countryName, stateName, cityName, neighborhoodName]
  );

  const handleChangeZipCode = useCallback((_, value, prevValue) => {
    if (!value || value === prevValue) return false;
    if (value.length === 9) {
      fetchCep(value);
    }
  }, []);

  return (
    <Row>
      <Col xs={4}>
        <GroupControl label="CEP">
          <GroupItem>
            <Field
              name="zip_code"
              component={Input}
              normalize={normalizeCep}
              onKeyPress={e => {
                if (e.which === 13) e.preventDefault();
              }}
              onChange={handleChangeZipCode}
            />
          </GroupItem>
          <GroupItem size="small">
            <Button className="h-margin-left--10">Buscar</Button>
          </GroupItem>
        </GroupControl>
      </Col>
    </Row>
  );
}

export default Cep;
