import React, { useMemo } from 'react';
import { getFirstName, getName } from 'lib/text';
import Clipboard from 'react-clipboard.js';
import Alert from 'react-s-alert';
import { MdContentCopy, MdEmail, MdPhone } from 'react-icons/md';
import Dropdown, { Item as DropdownItem, List } from 'components/Dropdown';
import Icon from 'components/Icon';
import Groups from '../Groups';
import {
  IconWhatsapp,
  Info,
  ListNames,
  NameCell,
  Options,
  Overflow,
} from 'components/TablePeopleStriped/styles';
import { clearPhone } from 'components/ChatBox';
import qs from 'qs';
import flags from 'country-flag-icons/react/3x2';
import { countries } from 'country-flag-icons';

function Item({
  person,
  handlePersonDelete,
  handlePersonEdit,
  handlePersonInative,
  handlePersonActive,
  handleOpenModalPerson,
}) {
  const handleClickEmail = (e) => {
    e.stopPropagation();
  };

  const shareWhatsappURL = useMemo(() => {
    const query = qs.stringify({
      // text: `Olá, ${getFirstName(person.name)}`,
      text: '',
      phone: clearPhone(
        person?.primary_phone?.ddi + person?.primary_phone?.number
      ),
    });

    return `https://api.whatsapp.com/send?${query}`;
  }, [person?.primary_phone]);

  const Flag = ({ size = 14, style, iso2, ...props }) => {
    const iso2Upper = iso2.toUpperCase();

    // Verifica se tem a bandeira na biblioteca
    // Também verifica se o número é brasileiro se for não aparece bandeira
    if (!countries.includes(iso2Upper) || iso2Upper === 'BR') return null;

    return React.createElement(
      flags[iso2Upper],
      { ...props, style: { width: size, ...style } },
      null
    );
  };

  const CAN_SHOW_INACTIVE = person.status === '1';
  const CAN_SHOW_ACTIVE = person.status === '0';

  return (
    <tr key={`person-${person.id}`}>
      <NameCell
        onClick={() => handleOpenModalPerson && handleOpenModalPerson(person)()}
      >
        <ListNames>
          <li>
            <p>
              <strong className="name-link">{getName(person?.name)}</strong>
            </p>
            {person.status === '0' && (
              <strong className="h-color--danger">Inativado</strong>
            )}
          </li>
          {person?.relateds?.map(({ id, name, description }) => {
            const firstName = getFirstName(name);

            return (
              <li key={id}>
                <span data-tip={firstName !== name ? name : undefined}>
                  {firstName}
                </span>{' '}
                {description && <span>({description})</span>}
              </li>
            );
          })}
        </ListNames>
      </NameCell>
      <td>
        {person?.primary_phone?.number && (
          <Info>
            <p>
              {person?.primary_phone?.is_whatsapp ? (
                <>
                  <a href={shareWhatsappURL} target="_blank" rel="noreferrer">
                    <Flag
                      iso2={person?.primary_phone?.iso2}
                      style={{ marginRight: '5px' }}
                    />
                    <IconWhatsapp
                      className="icon-left"
                      data-tip="Abrir no Whatsapp Web"
                    />

                    {person?.primary_phone?.number}
                  </a>
                </>
              ) : (
                <>
                  <MdPhone className="icon-left" />
                  {person?.primary_phone?.number}
                </>
              )}

              <Clipboard
                data-clipboard-text={person?.primary_phone?.number}
                onSuccess={() => {
                  Alert.success('Telefone copiado');
                }}
              >
                <MdContentCopy className="icon-right" />
              </Clipboard>
            </p>
          </Info>
        )}
        {person?.email && (
          <Info>
            <p>
              <a href={`mailto:${person?.email}`} onClick={handleClickEmail}>
                <MdEmail className="icon-left" data-tip="Enviar e-mail" />{' '}
                <Overflow width={200}>{person?.email}</Overflow>
              </a>{' '}
              <Clipboard
                data-clipboard-text={person?.email}
                onSuccess={() => {
                  Alert.success('E-mail copiado');
                }}
              >
                <MdContentCopy className="icon-right" />
              </Clipboard>
            </p>
          </Info>
        )}
      </td>
      <td>
        <p>
          <strong data-tip={person?.user?.name}>
            {getFirstName(person?.user?.name) || 'Sem corretor'}
          </strong>
        </p>
        <p>{person?.interacted_at ?? '-'}</p>
      </td>
      <td>
        <p>
          <Groups groups={person?.groups} />
        </p>
        <p>{person?.reception_source?.name || 'Indefinido'}</p>
      </td>
      <td>
        <Dropdown
          isDropHover
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Options size="medium" color="white" colorText="primary">
            Opcões <Icon name="chevron-down" />
          </Options>
          <List>
            {handlePersonEdit && (
              <DropdownItem>
                <button onClick={() => handlePersonEdit(person, person.name)}>
                  Editar
                </button>
              </DropdownItem>
            )}
            {CAN_SHOW_INACTIVE && handlePersonInative && (
              <DropdownItem>
                <button onClick={() => handlePersonInative(person)}>
                  Inativar
                </button>
              </DropdownItem>
            )}
            {CAN_SHOW_ACTIVE && handlePersonActive && (
              <DropdownItem>
                <button onClick={() => handlePersonActive(person)}>
                  Reativar
                </button>
              </DropdownItem>
            )}
            {handlePersonDelete && (
              <DropdownItem>
                <button
                  onClick={() => handlePersonDelete(person.id, person.name)}
                >
                  Excluir
                </button>
              </DropdownItem>
            )}
          </List>
        </Dropdown>
      </td>
    </tr>
  );
}

export default Item;
