import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
// Components
import { Input } from 'components/Form';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Button from 'components/Button';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

const EmailAliasForm = ({ handleSubmit, handleClose }) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Field
        type="password"
        xs={6}
        label="Nova Senha"
        name="password"
        component={Input}
      />
      <Field
        type="password"
        xs={6}
        label="Confirmar"
        name="password_confirm"
        component={Input}
      />
    </Row>
    <ModalFooter>
      <Button color="white" colorText="secondary" onClick={handleClose}>
        Cancelar
      </Button>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success">
        Salvar
      </Button>
    </ModalFooter>
  </form>
);

EmailAliasForm.propTypes = propTypes;

export default reduxForm({
  form: 'EmailChangePassword',
  validate,
  enableReinitialize: true
})(EmailAliasForm);
