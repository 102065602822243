import api from 'services';

const transformData = data => {
  let newData = { ...data };

  // Seta o subtype_id
  newData.subtype_id = newData?.subtype_id?.value || newData.subtype_id;
  return newData;
};

export const getOne = id =>
  api.getOne(`properties/partial/${id}/initial-informations`, '');
export const create = data =>
  api.create('properties/partial', transformData(data));

export const update = ({ id, ...data }) =>
  api.patch(
    `properties/partial/${id}/initial-informations`,
    transformData(data)
  );
