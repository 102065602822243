import React, { useState } from 'react';
import { Field } from 'redux-form';
import * as PropTypes from 'prop-types';

// Components
import { Textarea } from 'components/Form';
import VariablesBar from 'components/VariablesBar';

// Hooks
import useReduxForm from 'hooks/useReduxForm';

import { Wrapper, FieldContent } from './styles';

const FieldVariables = ({ name, variables }) => {
  const { change, dispatch } = useReduxForm();
  const [ref, setRef] = useState(null);

  return (
    <Wrapper>
      <FieldContent>
        <Field
          name={name}
          height={450}
          position="top"
          component={Textarea}
          getRef={ref => {
            setRef(ref);
          }}
        />
      </FieldContent>
      <VariablesBar
        textareaRef={ref}
        variables={variables}
        onChange={html => {
          dispatch(change(name, html));
        }}
      />
    </Wrapper>
  );
};

FieldVariables.propTypes = {
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string
    })
  )
};

export default FieldVariables;
