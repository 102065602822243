import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  position: PropTypes.oneOf(['start', 'center', 'end']),
  positionBar: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.node
};

const defaultProps = {
  className: null,
  size: 'small',
  color: 'primary',
  position: 'center',
  positionBar: 'bottom',
  children: null
};

const MenuLink = ({ children, linkClassName, ...rest }) => (
  <NavLink {...rest}>
    <span className={linkClassName}>{children}</span>
  </NavLink>
);

MenuLink.defaultProps = {
  activeClassName: 'is-active',
  className: 'Menu__item',
  linkClassName: 'Menu__link',
  children: null
};
MenuLink.propTypes = {
  linkClassName: PropTypes.string,
  children: PropTypes.node
};

const MenuRouter = ({
  className,
  size,
  color,
  position,
  positionBar,
  children,
  ...rest
}) => (
  <div
    {...rest}
    className={classnames('Menu', className, {
      [`Menu--${size}`]: size,
      [`Menu--${color}`]: color,
      [`Menu--${position}`]: position,
      [`Menu--${positionBar}`]: positionBar
    })}
  >
    {children}
  </div>
);

MenuRouter.Link = MenuLink;
MenuRouter.propTypes = propTypes;
MenuRouter.defaultProps = defaultProps;

export { MenuLink };
export default MenuRouter;
