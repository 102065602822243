import * as propertyService from '../../../../services/properties';
// Modules
import dataReducer from '../../../../modules/data';

// Constants
export const MODULE_NAME = 'propertyDeal';

export const {
  reducer: propertyDealReducer,
  actionCreators: propertyDealActions
} = dataReducer(`propertyDashboard/${MODULE_NAME}`);

// Selectors
export const dataSelector = state => state.propertyDashboard[MODULE_NAME].data;
export const isFetchingSelector = state =>
  state.propertyDashboard[MODULE_NAME].meta.isFetching;

// Reducer
export const fetchPropertyDeals = params => dispatch => {
  dispatch(propertyDealActions.request());

  return propertyService
    .getPropertiesLastedWinnings(params)
    .then(({ data }) => {
      dispatch(propertyDealActions.receive(data));
      return data;
    });
};

export default propertyDealReducer;
