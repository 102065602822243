import React, { memo } from 'react';
// Components
import RadioCard from './components/RadioCard';

class RadioCardWrapper extends React.Component {
  setValue = value => {
    const {
      input: { onChange }
    } = this.props;

    onChange(value);
  };

  onClickRadio = value => () => {
    this.setValue(value);
  };

  get renderOptions() {
    const {
      input: { value },
      options,
      labelKey,
      valueKey,
      componentKey
    } = this.props;

    return options.map(option => (
      <RadioCard
        key={`radio-card-${option[labelKey]}-${option[valueKey]}`}
        isSelected={value === option[valueKey]}
        title={option[labelKey]}
        component={option[componentKey]}
        onClick={this.onClickRadio(option[valueKey])}
      />
    ));
  }

  render() {
    const { options } = this.props;

    if (!options) return null;

    return <div className="RadioCardWrapper">{this.renderOptions}</div>;
  }
}

export default memo(RadioCardWrapper);
