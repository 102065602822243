// import styles from './styles'

import { Field, reduxForm } from 'redux-form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import { Row } from 'react-flexbox-grid';
import { Fields } from 'features/Financial';
import { Input, InputDate } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import { useGetAccounts } from '../../../../hooks/useAccounts';
import { useEffect } from 'react';

function FormAccountTransfer({
  handleSubmit,
  handleClose,
  change,
  isPristine,
  dirty,
}) {
  const { accounts, fetchAccounts } = useGetAccounts({ type: 1 });

  const originId = useFormValue('origin_id');

  useEffect(() => {
    fetchAccounts({ limit: null });
  }, []);

  const handleChangeOriginId = () => {
    change('destiny_id', null);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Fields.Account
          creatable={false}
          required
          xs={6}
          label="Conta de origem"
          name="origin_id"
          onChange={handleChangeOriginId}
          options={accounts}
        />
        <Fields.Account
          creatable={false}
          required
          xs={6}
          label="Conta de destino"
          name="destiny_id"
          excludeId={originId}
          options={accounts}
        />
      </Row>
      <Row>
        <Field
          required
          xs={6}
          valueFormat
          prefix="R$ "
          inputProps={{
            decimalScale: 2,
          }}
          label="Valor"
          name="value"
          component={Input}
        />
        <Field required xs={6} name="date" label="Data" component={InputDate} />
      </Row>

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button disabled={!dirty} type="submit" color="success">
          Transferir
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({ form: 'FormAccountTransfer' })(FormAccountTransfer);
