import api, { apiStorage } from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega as informações de site relacionadas ao rodapé
 * @param params
 * @return {Promise}
 */
export const getOne = (params) =>
  api.getOne('settings/sites/about', '', params);

/**
 * Editar a pagina de sobre
 */
export const update = async ({ id, image, file_url, ...values }) => {
  if (file_url === null && !image) {
    await apiStorage.delete('sites/about');
  }
  if (image) {
    await apiStorage.uploadFile('sites/about', { image });
  }

  return api
    .create('settings/sites/about', values)
    .then(responseMessage('Alterações efetuadas!'));
};
