import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import PropTypes from 'prop-types';
import {
  change,
  getFormMeta,
  initialize,
  reset,
  submit,
  touch,
} from 'redux-form';
// Helpers
import msg from 'lib/msg';
// Modules
import {
  openModalPerson,
  openModalPersonAdd,
  openModalSendMail,
} from 'modules/modal';
import { getRealtors } from 'modules/realtors';
import { addGroup, getGroups } from 'modules/peopleGroups';
import {
  getReceptionSources,
  receptionSourcesSelector,
} from 'modules/receptionSources';
import {
  activePerson,
  clearSearch,
  deletePerson,
  getPeople,
  inativePerson,
  isSearchingSelector,
  reverseDelete,
  setSearch,
  unsetIsSearching,
  updatePerson,
} from 'modules/people';
import { getPerson } from 'modules/modalPerson';
import { hideLoading, showLoading } from 'modules/loading';
import { getPagination, registerPagination } from 'modules/pagination';
// Components
import FilterAside from 'components/FilterAside';
import Wrapper from 'components/Wrapper';
import PeopleFilterForm, { selector } from './components/PeopleFilterForm';
import PeopleList from './components/PeopleList';
// Containers
import PeopleDashboard from 'containers/PeopleDashboard';

import { clearUrlParams } from 'lib/url-helpers';
import * as qs from 'qs';

let timeout = null;

class People extends Component {
  static defaultProps = {
    isSearching: false,
    people: [],
  };

  static propTypes = {
    hasSearch: PropTypes.bool,

    people: PropTypes.array,
    getPeople: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
    getRealtors: PropTypes.func.isRequired,
    deletePerson: PropTypes.func.isRequired,
    reverseDelete: PropTypes.func.isRequired,
    openModalPersonAdd: PropTypes.func.isRequired,
    setSearch: PropTypes.func.isRequired,
  };

  paginationDefault = {
    offset: 1,
    limit: 50,
  };

  // Main State
  state = {
    values: {},
    searching: false,
    canFetch: false,
  };

  componentDidMount() {
    const { change } = this.props;
    const { search } = this.props.location;
    const searchObj = qs.parse(search, { ignoreQueryPrefix: true });

    if (searchObj.person) {
      this.props.openModalPerson({ id: searchObj.person });
      clearUrlParams();
    } else {
      if (search && !searchObj._openModalPerson) {
        const initial = { filter: searchObj?.initialValues };
        setTimeout(() => {
          change('PeopleFilterForm', 'test', true);
        }, 200);
        this.handleChangeFilter(initial);
      }
    }

    // Registra a paginacao da cliente
    this.props.registerPagination('people');

    if (search === '?createUser') {
      this.handleOpenModal();
      clearUrlParams();
    }

    this.props.getGroups();
    this.props.getRealtors();
    this.props.getReceptionSources();

    this.setState({
      values: { filter: { by_user_id: this.props.user_id } },
    });

    // this.handleOpenModalPerson()()
  }

  fetchPeople = (params) => {
    // Mostra o loading
    this.props.showLoading();

    return this.props.getPeople(params).finally(() => {
      // Esconde o loading
      this.props.hideLoading();
    });
  };

  handleChangeFilter = (values) => {
    const { currentUser } = this.props;

    let _values = values;

    if (!currentUser.permissions.includes('OTHER_PERSON_DATA')) {
      _values = {
        ..._values,
        filter: { ..._values.filter, by_user_id: currentUser.id },
      };
    }
    this.setState({ values, searching: false });

    this.fetchPeople({
      ..._values,
      ...this.paginationDefault,
    }).catch(() => {
      Alert.success(msg.serverError(), {
        timeout: 1000,
      });
    });
  };

  handleChangeSearch = (e, value, prevValue) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      const isEmpty = prevValue?.length === 1 && value?.length === 0;

      if (value?.length >= 3 || isEmpty) {
        this.props.submit('PeopleFilterForm');
      } else {
        Alert.success('Mínimo 3 caracteres');
      }
    }, 300);
  };

  handleChangeUpdatedAt = (...rest) => {
    setTimeout(() => {
      if (
        this.props?.values?.filter?.interacted_greater_equals &&
        this.props?.values?.filter?.interacted_lower_equals
      ) {
        this.props.submit('PeopleFilterForm');
      }
    }, 200);
  };

  handleChangeCreatedAt = (...rest) => {
    setTimeout(() => {
      if (
        this.props?.values?.filter?.created_greater_equals &&
        this.props?.values?.filter?.created_lower_equals
      ) {
        this.props.submit('PeopleFilterForm');
      }
    }, 200);
  };

  handleChangeInteraction = (e, value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (value?.length > 0) {
        this.props.submit('PeopleFilterForm');
      }
    }, 300);
  };

  handleChange = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.props.submit('PeopleFilterForm');
    }, 300);
  };

  handleResetFilter = () => {
    this.props.clearSearch();
  };

  handlePersonDelete = (id, name) => {
    this.props.deletePerson(id);
  };

  handlePersonInative = (person) => {
    this.props.inativePerson(person);
  };

  handlePersonActive = (person) => {
    this.props.activePerson(person);
  };

  handlePersonReverseDelete = (id, name) => {
    this.props.reverseDelete(id).then(() => {
      Alert.success(msg.reactivate({ name }), {
        timeout: 2000,
      });
    });
  };

  /**
   * Lida com a edição de um cliente
   * @param person
   */
  handlePersonEdit = (person) => {
    this.props.openModalPersonAdd(person, (values, dispatch) => {
      updatePerson(values)(dispatch);
    });
  };

  /**
   * Abre a modal de pesoa
   */
  handleOpenModal = () => {
    this.props.openModalPersonAdd(null, (values, dispatch) => {
      this.fetchPeople({ offset: 1 });
    });
  };

  get isSearching() {
    return this.props.isSearching;
  }

  /**
   * Quando ele clica na paginacao
   * @param page
   */
  handleChangePage = (page) => {
    const params = {
      ...this.paginationDefault,
      ...this.state.values,
      offset: page.selected + 1,
    };

    this.fetchPeople(params).catch(() => {
      Alert.success(msg.serverError(), {
        timeout: 1000,
      });
    });
  };

  /**
   * Abre a modal de cliente
   * @param person
   */
  handleOpenModalPerson = (person) => () => {
    this.props.openModalPerson(person);
  };

  renderContainer = () => {
    if (this.isSearching) {
      return PeopleList;
    }

    return PeopleDashboard;
  };

  get initialValues() {
    const {
      location: { search },
      currentUser,
      user_id,
    } = this.props;

    if (search) {
      const searchObj = qs.parse(search, { ignoreQueryPrefix: true });

      if (searchObj?.initialValues) {
        return { filter: searchObj?.initialValues };
      }
    }

    // Verifica se o usuário atual é corretor
    // Se não for pode marcar na lista
    if (!currentUser.realtor) return null;

    return {
      filter: { status: 1, by_user_id: user_id },
    };
  }

  render() {
    const { groups, realtors, receptionSources } = this.props;

    return (
      <Wrapper full>
        <Helmet>
          <title>Clientes</title>
        </Helmet>
        <FilterAside title="Filtrar Clientes">
          <PeopleFilterForm
            initialValues={this.initialValues}
            onSubmit={this.handleChangeFilter}
            groups={groups}
            realtors={realtors}
            receptionSources={receptionSources}
            handleChange={this.handleChange}
            handleChangeSearch={this.handleChangeSearch}
            handleChangeUpdatedAt={this.handleChangeUpdatedAt}
            handleChangeCreatedAt={this.handleChangeCreatedAt}
            handleChangeInteraction={this.handleChangeInteraction}
          />
        </FilterAside>
        {React.createElement(this.renderContainer(), {
          ...this.props,
          handleChangeInteraction: this.handleChangeInteraction,
          handleChangeFilter: this.handleChangeFilter,
          handlePersonDelete: this.handlePersonDelete,
          handlePersonInative: this.handlePersonInative,
          handlePersonActive: this.handlePersonActive,
          handlePersonReverseDelete: this.handlePersonReverseDelete,
          handlePersonEdit: this.handlePersonEdit,
          handleOpenModal: this.handleOpenModal,
          handleChangePage: this.handleChangePage,
          handleOpenModalPerson: this.handleOpenModalPerson,
          handleResetFilter: this.handleResetFilter,
        })}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  isSearching: isSearchingSelector(state),
  meta: getFormMeta('PeopleFilterForm')(state),
  people: state.people.people,
  search: state.people.search,
  pagination: getPagination(state, 'people'),
  values: selector(
    state,
    'filter.created_greater_equals',
    'filter.created_lower_equals',
    'filter.interacted_greater_equals',
    'filter.interacted_lower_equals'
  ),
  isFetching: state.people.isFetching,
  deleting: state.people.deleting,
  realtors: state.realtors.realtors,
  groups: state.peopleGroups.groups,
  user_id: state.login.currentUser.id,
  currentUser: state.login.currentUser,
  receptionSources: receptionSourcesSelector(state),
});

const mapDispatchToProps = {
  activePerson,
  showLoading,
  touch,
  change,
  initialize,
  reset,
  hideLoading,
  getPerson,
  reverseDelete,
  setSearch,
  deletePerson,
  inativePerson,
  getPeople,
  openModalPersonAdd,
  openModalPerson,
  getGroups,
  getRealtors,
  addGroup,
  getReceptionSources,
  openModalSendMail,
  registerPagination,
  clearSearch,
  unsetIsSearching,
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(People);
