import React from 'react';

import PeopleChart from './components/PeopleChart';
import PeopleTable from './components/PeopleTable';

import { useReport } from '../hooks';
import { getPeoplePerChannel } from 'services/reports';
import WrapperReport from '../../components/WrapperReport';

export default function PeoplePerChannel() {
  const {
    data,
    chartData,
    isFetching,
    toggle,
    submit,
    toggleAll,
    isAllSelected,
    dataSelecteds
  } = useReport({
    request: getPeoplePerChannel
  });

  return (
    <WrapperReport
      title="Captação de Clientes por Canal"
      text="Veja qual dos canais de divulgação captam mais clientes."
      data={data}
      submit={submit}
      isFetching={isFetching}
      renderChart={() => (
        <PeopleChart width={'100%'} height={250} data={chartData} />
      )}
      renderTable={() => (
        <PeopleTable
          data={dataSelecteds}
          toggleAll={toggleAll}
          isAllSelected={isAllSelected}
          toggleChecked={toggle}
        />
      )}
    />
  );
}
