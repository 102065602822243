import React, { useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
// Components
import { Input } from 'components/Form';
import Button from 'components/Button';
import FieldGroups from 'containers/FieldGroups';

const defaultProps = {
  groups: []
};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const FormGroupStore = ({ group, handleSubmit }) => {
  const isEditting = useMemo(() => {
    return !!group?.id;
  }, [group]);
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        {!isEditting && (
          <FieldGroups xs={12} label="Copiar de" name="copy_group_id" />
        )}
        <Field
          xs={12}
          label="Nome do Grupo"
          name="title"
          component={Input}
          placeholder="Digite o nome do grupo"
        />
        <Col xs={12}>
          <Button block type="submit" color="success">
            {isEditting ? 'Editar grupo' : 'Criar Grupo'}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

FormGroupStore.defaultProps = defaultProps;
FormGroupStore.propTypes = propTypes;

export default reduxForm({
  form: 'FormGroupStore',
  enableReinitialize: true
})(FormGroupStore);
