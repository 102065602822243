import styled from "styled-components";

export const WrapperAll = styled.div`
  margin-bottom: 40px;
`;


export const Col = styled.div`

`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 70px 183px 187px 170px;
  padding: 14px 10px;
  background-color: #fff;

  ${Col} {
    font-weight: 600;
  }
`;

