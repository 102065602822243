import styled from 'styled-components';

export const Phones = styled.div`
  display: inline-block;

  svg {
    vertical-align: middle;
    margin-top: -2px;
  }
`;
