import api from 'services/index';
import { apiStorage } from 'services/index'

const baseUrl = (building_id, id = '') =>
  `sites/buildings/${building_id}/documents/${id}`;

const baseUrlGet = (building_id, id = '') =>
  `settings/sites/buildings/${building_id}/documents/${id}`;

/**
 * Cadastra um documento no hotsite
 * @param buildingId
 * @param file
 * @return {Promise<AxiosResponse<any>>}
 */
export const upload = ({ building_id, file }) =>
  apiStorage.uploadFile(baseUrl(building_id), { file: file });

/**
 * Edita um documento no hotsite
 * @param building_id
 * @param id
 * @param name
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = ({ building_id, id, name }) =>
  api.update(baseUrlGet(building_id, id), { name });

/**
 * Exclui um documento no hotsite
 * @param building_id
 * @param id
 * @return {Promise<AxiosResponse<any>>}
 */
export const remove = ({ building_id, id }) =>
  apiStorage.delete(baseUrl(building_id, id));

/**
 *
 * @param building_id
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = ({ building_id }) => api.getList(baseUrlGet(building_id));

/**
 * Edita um título dos documentos do hotsite
 * @param building_id
 * @param values
 * @return {Promise<AxiosResponse<any>>}
 */
export const updateDocuments = ({ id, ...values }) =>
  api.patch(baseUrlGet(id), values);
