import * as S from './styles';
import * as Box from '../Box';

export function BillInformations({ bill }) {
  return (
    <S.Wrapper>
      <S.BoxLeft>
        <S.CardInfo>
          <S.Icon src={bill?.credit_card?.icon_url} />
          <S.Content>
            <S.Title>{bill?.credit_card?.title}</S.Title>
            <S.Text>
              Valor pago {bill?.paid_value ? bill?.paid_value : 'R$ 0,00'}
            </S.Text>
            {bill?.situation ? <S.Badge>{bill?.situation}</S.Badge> : null}
          </S.Content>
        </S.CardInfo>
      </S.BoxLeft>
      <S.BoxRight>
        <Box.Wrapper>
          <Box.List>
            <li>
              <span>Saldo mês anterior</span>
              <span>{bill?.previous_unpaid_value}</span>
            </li>
            <li>
              <span>Fatura atual</span>
              <span>{bill?.current_value}</span>
            </li>
            <li>
              <span>Fechamento</span>
              <span>{bill?.closing_date}</span>
            </li>
          </Box.List>
        </Box.Wrapper>
        <Box.Wrapper>
          <Box.Content>
            <Box.Title>Vencimento</Box.Title>
            <Box.BigText>{bill?.billing_date}</Box.BigText>
          </Box.Content>
        </Box.Wrapper>
        <Box.Wrapper>
          <Box.Content>
            <Box.Title>Valor da fatura</Box.Title>
            <Box.BigText>{bill?.value}</Box.BigText>
          </Box.Content>
        </Box.Wrapper>
      </S.BoxRight>
    </S.Wrapper>
  );
}
