import api from './index';
import { responseMessage } from '../lib/service-helpers';

export const create = (name) => api.create('reception-sources', { name });

export const getOne = (id, params) =>
  api.getOne('reception-sources', id, params);

export const getList = (params) =>
  api.getList('reception-sources', {
    ...params,
    sort: 'order,name',
  });

/**
 * Pega todas as origens de captação customizadas
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getAllCustom = ({ count, ...params }) => {
  let _params = {
    sort: 'order,name',
    with_defaults: false,
    ...params,
  };

  if (count !== false) {
    _params.count = 'people';
  }

  return getList(_params);
};

export const transfer = (fromId, toId) =>
  api.create(`reception-sources/${fromId}/transfers/${toId}`);

export const update = (data) =>
  api
    .update('reception-sources', data)
    .then(responseMessage('Origem da captação alterada'));
export const remove = (id) =>
  api
    .delete('reception-sources', id)
    .then(responseMessage('Origem da captação removida'));
