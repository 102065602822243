import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

const propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.func.isRequired
};

const defaultProps = {
  as: null,
  className: null,
  children: () => null
};

function ReduxForm({
  as: Component,
  handleSubmit,
  children,
  className,
  ...props
}) {
  return (
    <Component className={className} onSubmit={handleSubmit}>
      {children({ ...props })}
    </Component>
  );
}

ReduxForm.defaultProps = defaultProps;
ReduxForm.propTypes = propTypes;

export default reduxForm()(ReduxForm);
