import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import Alert from 'react-s-alert';

// Common Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';

// Services
import { create } from 'services/settings/portals/partnerships';

// Hooks
import { useCurrentUser } from 'hooks/useCurrentUser';

function ModalPromotionPortal({
  portalName,
  title,
  text,
  image,
  modalConfig,
  isOpen,
  handleClose,
  modalType
}) {
  const currentUser = useCurrentUser();
  const [initialValues, setInitialValues] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //const getRegion = useCallback(async () => {
  //  const neighborhood_id = currentUser?.real_estate?.neighborhood_id;
  //
  //  if (!neighborhood_id) return '';
  //
  //  try {
  //    const { data: neighborhood } = await getNeighborhoodByIdOnly(
  //      neighborhood_id
  //    );
  //
  //    return neighborhood?.city?.name + ' / ' + neighborhood?.state?.acronym;
  //  } catch {
  //    return '';
  //  }
  //}, [currentUser?.real_estate?.relations]);

  const onSubmit = useCallback(create, []);

  const fetchInitialValues = useCallback(async () => {
    setIsLoading(true);

    //const address = await getRegion();

    setIsLoading(false);

    setInitialValues({
      name: currentUser?.name,
      email: currentUser?.email,
      phone: currentUser?.cellphone || currentUser?.phone,
      cpf_cnpj: currentUser?.real_estate?.cpf_cnpj,
      portal_name: portalName
    });
  }, [currentUser]);

  useEffect(() => {
    fetchInitialValues();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={isLoading}
        renderHeader={() => (
          <header className="Modal__header Modal__header--custom h-flex h-flex--center-center">
            <h4 className="Modal__title">{title}</h4>
            <span className="h-flex__cell--grow" />
            {image && <img src={image} alt={title} style={{ maxHeight: 33 }} />}
          </header>
        )}
        handleClose={handleClose}
      >
        <p className="h-color--primary h-margin-bottom--10">{text}</p>
        <Form
          onSubmit={onSubmit}
          onSubmitSuccess={() => {
            Alert.success('Dados enviados!');
            handleClose();
          }}
          handleClose={handleClose}
          initialValues={initialValues}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalPromotionPortal;
