import Button from "components/Button";
import { USER_PERMISSIONS } from "constants/rules";
import Can from "containers/Can";
import { useLocation } from "containers/LocationAsyncFields/context";
import useFormValue from "hooks/useFormValue";
import useReduxForm from "hooks/useReduxForm";
import { openModalAddCity, openModalAddNeighborhood, openModalAddZone } from "modules/modal";
import { useCallback } from "react";
import { MdAdd } from "react-icons/md";

const LocationActions = () => {
  const { dispatch } = useReduxForm();

  const {
    refreshOptions,
    countryName,
    stateName,
    cityName,
  } = useLocation();

  const countryId = useFormValue(countryName);
  const stateId = useFormValue(stateName);
  const cityId = useFormValue(cityName);

  const handleAddCity = useCallback(() => {
    dispatch(
      openModalAddCity({
        onAddCity: (res, _, props) => {
          refreshOptions();

          // dispatch(change(countryName, props.values.country_id));
          // dispatch(change(stateName, props.values.state_id));
          // dispatch(change(cityName, res.data?.id));
          // dispatch(change(neighborhoodName, res.data?.first_neighborhood?.id));
          // dispatch(change(zoneName, null));
        },
        initialValues: {
          country_id: countryId,
          state_id: stateId,
        },
      })
    );
  }, [
    dispatch,
    // change,
    refreshOptions,
    dispatch,
    // countryName,
    // stateName,
    // cityName,
    // neighborhoodName,
    // zoneName,
    countryId,
    stateId,
  ]);

  const handleAddNeighborhood = useCallback(() => {
    dispatch(
      openModalAddNeighborhood({
        onAddNeighborhood: (res, _, props) => {
          refreshOptions();

          // dispatch(change(countryName, props.values.country_id));
          // dispatch(change(stateName, props.values.state_id));
          // dispatch(change(cityName, props.values.city_id));
          // dispatch(change(neighborhoodName, res.data?.id));
          // dispatch(change(zoneName, null));
        },
        initialValues: {
          country_id: countryId,
          state_id: stateId,
          city_id: cityId,
        },
      })
    );
  }, [
    dispatch,
    // change,
    refreshOptions,
    // countryName,
    // stateName,
    // cityName,
    // neighborhoodName,
    // zoneName,
    countryId,
    stateId,
    cityId,
  ]);

  const handleAddZone = useCallback(() => {
    dispatch(
      openModalAddZone({
        onAddZone: (res, _, props) => {
          refreshOptions();

          // dispatch(change(countryName, props.values.country_id));
          // dispatch(change(stateName, props.values.state_id));
          // dispatch(change(cityName, props.values.city_id));
          // dispatch(change(neighborhoodName, null));
          // dispatch(change(zoneName, res.data?.id));
        },
        initialValues: {
          country_id: countryId,
          state_id: stateId,
          city_id: cityId,
        },
      })
    );
  }, [
    dispatch,
    // change,
    refreshOptions,
    // countryName,
    // stateName,
    // cityName,
    // neighborhoodName,
    // zoneName,
    countryId,
    stateId,
    cityId,
  ]);

  return (
    <Can
      run={USER_PERMISSIONS.EDIT_ADDRESSES}
      yes={() => (
        <>
          <Button onClick={handleAddCity} color="secondary">
            <MdAdd /> Nova cidade
          </Button>
          <Button onClick={handleAddNeighborhood} color="secondary">
            <MdAdd /> Novo bairro
          </Button>
          <Button onClick={handleAddZone} color="secondary">
            <MdAdd /> Nova zona
          </Button>
        </>
      )}
      no={() => (
        <span data-tip="Você não tem permissão para<br /> gerenciar bairros/cidades">
          <Button color="secondary" disabled>
            <MdAdd /> Nova cidade
          </Button>
          <Button color="secondary" disabled>
            <MdAdd /> Novo bairro
          </Button>
          <Button onClick={handleAddZone} color="secondary">
            <MdAdd /> Nova zona
          </Button>
        </span>
      )}
    />
  );
};

export default LocationActions;
