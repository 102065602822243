import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const Left = styled.div`
  width: 62%;
  position: relative;
  margin-right: 30px;
`;

export const Right = styled.div`
  position: relative;
  width: 38%;
`;

export const Box = styled.div`
  border-radius: ${p => p.theme.borderRadius};
  padding: 15px 15px 0;
  border: 1px solid ${p => p.theme.borderColor};

  margin-bottom: 15px;
`;
