import io from 'socket.io-client';

// Pega o caminho base da url do socket
const BASE_SOCKET_URL = process.env.REACT_APP_SOCKET_BASE;

export let CONNECTIONS = {};

export default class Socket {
  path = null;
  params = null;

  constructor(accessToken) {
    this.accessToken = accessToken || localStorage.getItem('access_token');
  }

  addConnection() {
    if (CONNECTIONS[this.path]) {
      return CONNECTIONS[this.path];
    }

    CONNECTIONS = {
      ...CONNECTIONS,
      [this.path]: io.connect(`${BASE_SOCKET_URL}/${this.path}`, {
        ...this.params,
        reconnectionDelay: 20000,
        reconnectionDelayMax: 20000
      })
    };

    return CONNECTIONS[this.path];
  }

  getConnection() {
    return CONNECTIONS[this.path];
  }

  connect(path, params) {
    this.path = path;
    this.params = params;

    const connection = this.addConnection(path, params);

    if (this.accessToken) {
      connection.on('connect', () => {
        connection.emit('auth', this.accessToken);
      });
    }
  }

  /**
   * Register a new handler for the given event.
   * @param {string} eventName
   * @param {function} callBack
   */
  on(eventName, callBack) {
    this.getConnection().on(eventName, callBack);
  }

  /**
   * Desconecta o Socket atual
   */
  disconnect() {
    this.getConnection().disconnect();
  }
}
