import React from 'react';
import PropTypes from 'prop-types';
// Components
import Header from './components/Header';
import Item from './components/Item';
import Row from './components/Row';
import Text from './components/Text';
import Title from './components/Title';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const defaultProps = {
  className: 'ListInfo'
};

const ListInfo = ({ children, ...rest }) => <div {...rest}>{children}</div>;

ListInfo.propTypes = propTypes;
ListInfo.defaultProps = defaultProps;
ListInfo.row = Row;
ListInfo.item = Item;
ListInfo.title = Title;
ListInfo.text = Text;
ListInfo.header = Header;

export default ListInfo;
