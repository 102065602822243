import { COLORS } from 'components/Menus/constants';
import { resellerSelector } from 'modules/login';
import { openModalSupport } from 'modules/modal';
import { TYPES } from 'pages/Help/constants';
import { useCallback, useMemo } from 'react';
import { IoMdHelp } from 'react-icons/io';
import { MdBuild, MdPeople, MdPerson, MdWarning } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '../../components/Menu';
import MenuItem from '../../components/MenuItem';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';

const MenuHelp = () => {
  const realEstate = useCurrentRealEstate();

  const canScheduleTraining = useMemo(() => {
    return realEstate.can_schedule_training;
  }, [realEstate]);

  const dispatch = useDispatch();

  const openSuport = useCallback(() => {
    dispatch(openModalSupport());
  }, []);

  const resale = useSelector(resellerSelector);

  return (
    <Menu title="Ajuda" canBack>
      <MenuItem
        icon={MdBuild}
        color={COLORS.green}
        to="/people/show"
        title="Falar com suporte"
        text="Nossos dados de contato"
        onClick={openSuport}
      />
      {resale?.faq_url && (
        <MenuItem
          href={resale?.faq_url}
          target="_blank"
          icon={IoMdHelp}
          color={COLORS.black}
          title="Central de ajuda"
          text="Manual de uso"
        />
      )}
      {canScheduleTraining && (
        <MenuItem
          href="https://tecimob.com.br/ajuda/agendamentos/"
          target="_blank"
          icon={MdPeople}
          color={COLORS.purple}
          title="Agendar treinamento"
          text="Agende uma reunião com nosso time"
        />
      )}
      <MenuItem
        to={`/help/${TYPES.SUGESTION}/list`}
        icon={MdPerson}
        color={COLORS.blue}
        title="Sugerir novos recursos"
        text="Nos ajude a melhorar"
      />
      <MenuItem
        to={`/help/${TYPES.PROBLEM}/list`}
        icon={MdWarning}
        color={COLORS.red}
        title="Relatar um problema"
        text="Informe um problema de uso"
      />
    </Menu>
  );
};

export default MenuHelp;
