import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Row } from 'react-flexbox-grid';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Field, reduxForm } from 'redux-form';
import { Input, Textarea } from 'components/Form';
import Button from 'components/Button';
import api from 'services';
import { useRequest } from 'hooks/useRequest';
import * as messagesService from 'services/settings/messages';

function ModalSendEmail({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  handleSubmit,
  initialize,
  initialValues,
}) {
  const { data } = useRequest({
    request: messagesService.getOne,
  });

  useEffect(() => {
    initialize({
      complement: data.matched,
      ...initialValues,
    });
  }, [data]);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Enviar e-mail" handleClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <Row>
            <Field xs={12} label="Título" name="subject" component={Input} />
            <Field
              type="simple"
              xs={12}
              label="Descrição"
              name="complement"
              component={Textarea}
            />
          </Row>
          <ModalFooter withBorder={false}>
            <Button type="button" color="white" onClick={handleClose}>
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              Enviar
            </Button>
          </ModalFooter>
        </form>
      </ModalTemplate>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalSendPeopleEmail',
  onSubmit: (values, dispatch, props) => {
    if (values.matcheds_id) {
      return api.create('people/forwardings/matcheds/email', {
        ...values,
        user_id: props?.userId,
      });
    }
    if (values.properties_id) {
      return api.create('properties/forwardings/matcheds', {
        ...values,
        user_id: props?.userId,
      });
    }
    return api.create('people/forwardings/matcheds-by-people/email', {
      ...values,
      user_id: props.userId,
    });
  },
  onSubmitSuccess: (res, dispatch, props) => {
    props.handleClose();
    if (props.afterClose) props.afterClose();
  },
})(ModalSendEmail);
