import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';
// Components
// Assets
import PaymentSuccess from '../ModalPayment/pages/PaymentSuccess';
import { ModalClose } from 'components/Modal';

const propTypes = {
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

const defaultProps = {
  modalType: null,
  modalConfig: null,
  isOpen: null,
  handleClose: null
};

function ModalCreditCardSuccess({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  history
}) {
  function handleClickClose() {
    window.location = '/dashboard';
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClickClose}
    >
      <div className="Modal__wrapper">
        <ModalClose handleClose={handleClose} />
        <PaymentSuccess />
      </div>
    </Modal>
  );
}

ModalCreditCardSuccess.propTypes = propTypes;
ModalCreditCardSuccess.defaultProps = defaultProps;

export default withRouter(ModalCreditCardSuccess);
