import Loading from "components/Loading";
import MainTitle from "components/MainTitle";
import { withFormConfirmation } from "HOC/withFormConfirmation";
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import { finishResponse } from "pages/PropertyStore/helpers";
import { withId } from "pages/PropertyStore/HOC/withId";
import { useEffect } from "react";
import { useParams } from "react-router";
import { compose } from "redux";
import { reduxForm } from 'redux-form';
import Tour360 from "./components/Tour360";
import Video from './components/Video';
import * as complementiesService from 'services/propertiesPartial/complementies';
import { useRequest } from "hooks/useRequest";

const Complements = ({ handleSubmit, initialize, change, onSubmit, submitting }) => {
  const { id } = useParams();

  const { data, fetchData, isFetching } = useRequest({
    request: complementiesService.getOne,
    initialFetch: false,
    initialState: {}
  });

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    initialize(data);
  }, [data]);

  if (isFetching) return <Loading isFullComponent isVisible />;

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle
        title="Complementos"
        text="Configure dados complementares do seu imóvel."
      />
      <Video change={change} />
      <Tour360 change={change} />
      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
}

export default compose(
  withId,
  reduxForm({
    form: 'PropertyComplements',
    onSubmit: async (values, d, p) => complementiesService.update(values).then(finishResponse(values, d, p)),
    onSubmitSuccess: (response, dispatch, p) => {
      p.initialize(response?.data);
      if (!p.isEditting) {
        p.next('private');
      }
    }
  }),
  withFormConfirmation()
)(Complements);
