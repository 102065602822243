import * as propertiesService from '../../services/properties';

const isValidReference = reference =>
  propertiesService
    .isValidReference(reference)
    .then(({ data: { has_reference } }) => {
      if (has_reference) {
        throw { reference: 'Essa referência já existe.' }; // eslint-disable-line no-throw-literal
      }
    });

const asyncValidate = (values, dispatch, props, blurredField) => {
  return new Promise(resolve => {
    if (blurredField === 'reference') {
      if (values.id) {
        return resolve(true);
      }

      return resolve(isValidReference(values.reference));
    }
    return resolve(true);
  });
};

export default asyncValidate;
