import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { Form } from './styles';
import FieldReceptionSources from 'containers/FieldReceptionSources';
import ClientsWithContact from 'containers/TableLastLeadsReceived/components/Filter/Fields/ClientsWithContact';
import { CustomFieldDateRange } from 'pages/Crm/pages/History/components/Filter/styles';

function Filter({ userId, change, handleSubmit }) {
  useEffect(() => {
    change('user_id_fake', userId);
  }, [userId]);

  return (
    <Form onSubmit={handleSubmit}>
      <FieldReceptionSources
        autoFocus={false}
        all
        label=""
        options={[{ name: 'Todos canais', id: 'todos' }]}
        placeholder="Todos canais"
      />
      <ClientsWithContact />
      <CustomFieldDateRange
        isInline
        canClear={false}
        startDateName="start_date"
        endDateName="end_date"
      />
    </Form>
  );
}

export default reduxForm({
  form: 'FilterContacts',
  enableReinitialize: true,
})(Filter);
