import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
// Components
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { FieldImage, Input, Select, Textarea } from 'components/Form';
import Button from 'components/Button';
// Styles
import { Image, Wrapper, WrapperFields } from './styles';
// Validate
import validate from './validate';
import { parseValueBool } from 'lib/formHelpers';

function Form({ isEditting, handleSubmit, submitting, pristine, handleClose }) {
  return (
    <ModalTemplate
      title="Criar notícia a partir de um link"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <p className="h-color--primary h-margin-bottom--15">
          Você pode criar postagens a partir de links de outros sites, de redes
          sociais, blogs, e sobre qualquer assunto que possa interessar o seu
          cliente.
        </p>
        <Wrapper>
          <Image>
            <FieldImage
              text="Envie uma imagem com a medida 370x275px."
              name="cover_image"
              fileUrlField="cover_file_url"
              renderButtonSend={() => (
                <Button
                  color="white"
                  colorText="secondary"
                  className="h-margin-top--15"
                >
                  Enviar Imagem
                </Button>
              )}
            />
          </Image>
          <WrapperFields>
            <Row>
              <Field xs={12} name="title" label="Título" component={Input} />
            </Row>
            <Row>
              <Field
                xs={6}
                label="Cole o link"
                name="link"
                component={Input}
                placeholder="Digite o link"
              />
              <Field
                xs={6}
                label="Abrir link na:"
                name="opens_link_on_page"
                component={Select}
                placeholder={''}
                options={[
                  { label: 'Mesma página', value: true },
                  { label: 'Outra página', value: false },
                ]}
                parse={parseValueBool}
              />
            </Row>
            <Row>
              <Field
                height={115}
                type="simple"
                xs={12}
                label="Descrição para o site"
                name="description"
                placeholder="Digite a descrição"
                component={Textarea}
              />
            </Row>
          </WrapperFields>
        </Wrapper>
        <ModalFooter>
          <Button
            type="button"
            onClick={handleClose}
            color="white"
            colorText="secondary"
          >
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button
            type="submit"
            disabled={submitting || pristine}
            color="success"
          >
            {isEditting ? 'Editar postagem' : 'Criar postagem'}
          </Button>
        </ModalFooter>
      </form>
    </ModalTemplate>
  );
}

Form.defaultProps = {};
Form.propTypes = {};

export default reduxForm({
  form: 'FormPostLink',
  enableReinitialize: true,
  validate,
})(Form);
