import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Modal from 'react-modal';
import { Content } from './styles';
import oruloLogo from './orulologo.svg';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { usePrices } from '../ModalPayment/hooks/usePrices';
import { fetchPrices } from 'modules/prices';
import Loading from 'components/Loading';
import { formatter } from 'hooks/usePlan';
import { useChangeContract } from 'hooks/useChangeContract';
import { ITEM_TYPES } from 'services/financialv2/contracts';

const ModalOruloTerms = ({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onAccept,
}) => {
  const { handleOpenModal } = useChangeContract({
    item: ITEM_TYPES.ORULO,
    onSuccess: () => {
      if (onAccept) onAccept();
    },
  });

  const dispatch = useDispatch();

  function handleAccept() {
    handleOpenModal();
  }

  useEffect(() => {
    dispatch(fetchPrices());
  }, [dispatch]);

  const [prices, isFetchingPrices] = usePrices();

  if (isFetchingPrices) return <Loading isVisible isFullScreen />;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Integração com banco de dados Órulo"
        handleClose={handleClose}
      >
        <Content>
          <img src={oruloLogo} alt="Órulo" />

          <div>
            <h3>Integração com banco de dados Órulo</h3>
            <p>
              Ativando este recurso, os imóveis selecionados no banco de dados
              da Órulo serão incorporados automaticamente para publicação junto
              com seus imóveis cadastrados no Tecimob.
            </p>

            <h3>Requisitos e custo</h3>
            <ul>
              <li>
                Contratar o serviço com a Órulo para receber uma chave para
                integração;
              </li>
              <li>
                O Tecimob cobra o valor de{' '}
                <strong>R${formatter.format(prices.orulo_price)}</strong> mensal
                para manter a conexão ativa;
              </li>
            </ul>

            <h3>Informações</h3>
            <ul>
              <li>
                Antes de fazer a integração, faça a seleção dos imóveis no
                painel da Órulo;
              </li>
              <li>
                Após a integração, aguarde 3h para que os imóveis estejam
                disponíveis no Tecimob;
              </li>
              <li>
                Alterações nos imóveis selecionados no Órulo levam até 24h para
                atualizar no Tecimob;
              </li>
            </ul>

            <h3>Funcionamento</h3>
            <p>
              Para localizar os imóveis integrados, utilize a ”Pesquisa de
              imóveis por características”
            </p>
            <p>
              É necessário preencher estes 4 requisitos:{' '}
              <strong>Tipo, Transação, UF, Cidade</strong>
            </p>
          </div>
        </Content>

        <ModalFooter>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button onClick={handleAccept} color="success">
            Li e estou de acordo. Avançar para integração
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
};

export default ModalOruloTerms;
