import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

// Components
import Container from 'components/Container';
import { FieldBool, Input } from 'components/Form';
import MainTitle from 'components/MainTitle';
import {
  Cities,
  Countries,
  Neighborhoods,
  States,
  Zones,
} from 'containers/LocationAsyncFields';
import Cep from './components/Cep';

// HOC
import withProvider from 'HOC/withProvider';
import { withId } from '../../HOC/withId';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Services
import * as locationService from 'services/propertiesPartial/location';

// Contexts
import ListOptions from 'components/ListOptions';
import { LocationProvider } from 'containers/LocationAsyncFields/context';
import FooterActions from 'pages/PropertyStore/components/FooterActions';

// Styles
import Loading from 'components/Loading';
import LocationActions from 'components/LocationActions';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import {
  handleSetPosition,
  handleSetPov,
  handleSetZoom,
} from 'modules/mapView';
import { finishResponse } from 'pages/PropertyStore/helpers';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import MapSection from './components/MapSection';
import { WrapperOptions } from './styles';

const Location = ({
  handleSubmit,
  initialize,
  change,
  onSubmit,
  submitting,
}) => {
  const dispatch = useDispatch();
  const [hasFloor, setFloor] = useState(false);
  const [hasLotDescription, setHasLotDescription] = useState(false);
  const [hasLotNumber, setHasLotNumber] = useState(false);
  const [hasApartmentNumber, setApartmentNumber] = useState(false);
  const [hasUnitsPerFloor, setUnitsPerFloor] = useState(false);
  const [hasFloorsNumber, setFloorsNumber] = useState(false);
  const [hasTowersNumber, setTowersNumber] = useState(false);

  const { id } = useParams();
  const { data, fetchData } = useRequest({
    request: locationService.getOne,
    initialFetch: false,
    initialState: {},
  });

  useEffect(() => {
    data?.type?.information_fields?.forEach((info) => {
      if (info?.name === 'lot_description') setHasLotDescription(true);
      if (info?.name === 'lot_number') setHasLotNumber(true);
      if (info?.name === 'apartment_number' || info?.name === 'lot_number')
        setApartmentNumber(true);

      if (info?.name === 'floor') setFloor(true);
      if (info?.name === 'units_per_floor') setUnitsPerFloor(true);
      if (info?.name === 'floors_number') setFloorsNumber(true);
      if (info?.name === 'towers_number') setTowersNumber(true);
    });
  }, [data]);

  const apartmentNumber = useMemo(() => {
    try {
      return data?.type?.information_fields?.find(
        ({ name }) => name === 'apartment_number'
      );
    } catch {
      return false;
    }
  }, [data]);

  const floor = useMemo(() => {
    try {
      return data?.type?.information_fields?.find(
        ({ name }) => name === 'floor'
      );
    } catch {
      return false;
    }
  }, [data]);

  const hasNumber = useMemo(() => {
    return !!apartmentNumber?.title;
  }, [apartmentNumber]);

  // const hasCondo = useMemo(() => {
  //   return data?.condominium_id;
  // }, [data]);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    if (data?.maps_latitude || data?.maps_longitude) {
      dispatch(
        handleSetPosition({
          lat: data?.maps_latitude,
          lng: data?.maps_longitude,
        })
      );
    }

    if (data?.maps_zoom) {
      dispatch(handleSetZoom(data?.maps_zoom));
    }

    if (data?.maps_pitch || data?.maps_street_zoom || data?.maps_heading) {
      const pov = {
        heading: data?.maps_heading,
        pitch: data?.maps_pitch,
        zoom: data?.maps_street_zoom,
      };

      dispatch(handleSetPov(pov));
    }

    const hasLocation = !!data?.maps_latitude || !!data?.maps_longitude;

    initialize({
      ...data,
      is_shared_address: false,
      locate_map: hasLocation,
    });
  }, [data]);

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle
        title="Localização"
        text="Adicione a localização deste imóvel e defina o que será mostrado ou não em seu site."
      >
        <LocationActions />
      </MainTitle>
      <Container>
        <Cep />
        <Row style={{ flexWrap: 'nowrap' }}>
          <Countries xs={2} />
          <States xs={2} />
          <Cities xs={3} />
          <Neighborhoods xs={3} />
          <Zones xs={2} />
        </Row>
        <Row>
          <Col xs={7}>
            <Row>
              <Field
                xs={9}
                label="Logradouro"
                name="street_address"
                component={Input}
              />
              <Field
                xs={3}
                label="Número"
                name="street_number"
                component={Input}
              />
            </Row>
          </Col>
          <Field
            xs={5}
            label="Complemento"
            name="complement_address"
            component={Input}
          />
        </Row>
        <Row>
          {hasNumber && (
            <Field
              xs={3}
              label="Nº ou identificação da Unidade"
              name="informations.apartment_number.value"
              component={Input}
            />
          )}
          {hasLotNumber && (
            <Field
              xs={3}
              label="Unidade"
              name="informations.lot_number.value"
              component={Input}
            />
          )}
          {hasLotDescription && (
            <Field
              xs={4}
              label="Descrição do lote"
              name="informations.lot_description.value"
              component={Input}
            />
          )}
          {hasFloor && (
            <Field
              xs={2}
              label={floor.title}
              name="informations.floor.value"
              component={Input}
            />
          )}
          {hasUnitsPerFloor && (
            <Field
              xs={2}
              label="Unidades por andar"
              name="informations.units_per_floor.value"
              component={Input}
            />
          )}
          {hasFloorsNumber && (
            <Field
              xs={2}
              label="Total de andares"
              name="informations.floors_number.value"
              component={Input}
            />
          )}
          {hasTowersNumber && (
            <Field
              xs={2}
              label="Total de torres"
              name="informations.towers_number.value"
              component={Input}
            />
          )}
        </Row>

        {/*<Row center="xs">
          <Col xs={12} style={{ textAlign: 'left' }}>
            {hasCondo && (
              <Field
                type="checkbox"
                responsive={false}
                name="is_shared_address"
                component={Checkbox}
                label={
                  <span className="h-color--primary">
                    Definir este endereço para todos os imóveis no condomínio
                  </span>
                }
              />
            )}
          </Col>
        </Row>*/}

        <WrapperOptions>
          <ListOptions>
            {hasFloor && (
              <ListOptions.item
                title={
                  data?.type?.title === 'Sala'
                    ? 'Mostrar andar da sala'
                    : 'Mostrar andar do apartamento'
                }
                renderOptions={() => <FieldBool name="is_floor_shown" />}
              />
            )}
            {hasApartmentNumber && (
              <ListOptions.item
                title="Mostrar o número da unidade"
                renderOptions={() => (
                  <FieldBool name="is_apartment_number_shown" />
                )}
              />
            )}

            <ListOptions.item
              title="Mostrar Logradouro no site?"
              renderOptions={() => (
                <FieldBool
                  name="is_street_shown"
                  onChange={(e, value) => {
                    if (!!value) {
                      change('is_neighborhood_shown', true);
                    }
                  }}
                />
              )}
            />
            <ListOptions.item
              title="Mostrar Bairro no site?"
              renderOptions={() => (
                <FieldBool
                  name="is_neighborhood_shown"
                  onChange={(e, value) => {
                    if (!value) {
                      change('is_street_shown', false);
                      change('is_condominium_shown', false);
                    }
                  }}
                />
              )}
            />
            <ListOptions.item
              title="Mostrar complemento no site?"
              renderOptions={() => <FieldBool name="is_complement_shown" />}
            />
            <ListOptions.item
              title="Mostrar número no site?"
              renderOptions={() => <FieldBool name="is_street_number_shown" />}
            />
            <ListOptions.item
              title="Mostrar nome do Condomínio no site?"
              renderOptions={() => (
                <FieldBool
                  name="is_condominium_shown"
                  onChange={(e, value) => {
                    if (!!value) {
                      change('is_neighborhood_shown', true);
                    }
                  }}
                />
              )}
            />
          </ListOptions>
        </WrapperOptions>
      </Container>

      <MapSection isStreetviewInitiallyActive={!!data?.is_streetview_active} />

      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyLocation',
    // initialValues: {
    //   is_shared_address: false,
    //   locate_map: false,
    // },
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmit: (values, d, p) =>
      locationService.update(values).then(finishResponse(values, d, p)),
    onSubmitSuccess: (response, dispatch, p) => {
      const hasLocation =
        !!response?.data?.maps_latitude || !!response?.data?.maps_longitude;

      p.initialize({
        is_shared_address: false,
        locate_map: hasLocation,
        ...response?.data,
      });
      if (!p.isEditting) {
        p.next('establishments');
      }
    },
  }),
  withProvider(LocationProvider),
  withFormConfirmation()
)(Location);
