import FixedBar from 'components/FixedBar';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import ClearSelecteds from './components/ClearSelecteds';
import SelectOption from './components/SelectOption';
import RemoveSelecteds from './components/RemoveSelecteds';
import SelectedProperties from './components/SelectedProperties';
// import { Wrapper } from './styles';

function FixedBarPortals({ isPortal }) {
  const { selectedProperties } = usePortalOptions();

  const isVisible = selectedProperties?.length > 0;

  return (
    <FixedBar
      color="primary"
      noContainer
      isVisible={isVisible}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      style={{ left: '250px', background: '#42587E', zIndex: 1500 }}
    >
      <SelectedProperties properties={selectedProperties} />
      <SelectOption />
      {isPortal && <RemoveSelecteds />}
      <FixedBar.spacer />
      <ClearSelecteds />
    </FixedBar>
  );
}

export default FixedBarPortals;
