import React, { useState } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import SortList from './SortList';
import WizardItinerary from 'containers/Wizards/WizardItinerary';

function SortPropertiesContainer({ isFetching, properties, setProperties }) {
  const [isDragging, setIsDragging] = useState(false);

  function onSortStart() {
    setIsDragging(true);
  }

  function onSortEnd({ oldIndex, newIndex }) {
    setIsDragging(false);
    setProperties(arrayMove(properties, oldIndex, newIndex));
  }

  function handleClickRemove(propertyId) {
    return function(e) {
      e.preventDefault();
      e.stopPropagation();
      setProperties(properties?.filter(property => property.id !== propertyId));
    };
  }

  return (
    <>
      <SortList
        distance={1}
        isDragging={isDragging}
        lockToContainerEdges
        lockOffset="50%"
        lockAxis="y"
        axis="y"
        properties={properties}
        onSortEnd={onSortEnd}
        onSortStart={onSortStart}
        handleClickRemove={handleClickRemove}
      />
      <WizardItinerary isLoaded={!isFetching} />
    </>
  );
}

export default SortPropertiesContainer;
