import Alert from 'react-s-alert';
import { createSelector } from 'reselect';
// Modules
import { openModalFinancialDelete } from 'modules/modal';
// Services
import * as financialIndexService from '../services/financialIndex';
// Helpers
import msg from '../lib/msg';
import { createModule, item } from '../lib/reducer-helpers';

// Action
const createAction = createModule('financialIndex');

// Actions
const ADD_INDEX = createAction('ADD');
const ALTER_INDEX = createAction('ALTER');

const REQUEST_INDEXES = createAction('REQUEST');
const RECEIVE_INDEXES = createAction('RECEIVE');

const REQUEST_INDEX_DELETE = createAction('REQUEST_DELETE');
const RECEIVE_INDEX_DELETE = createAction('RECEIVE_DELETE');

// retorna todos os indices
export const indexesSelector = state => state.financialIndex.indexes;
export const isFetchingSelector = state => state.financialIndex.meta.isFetching;

// retorna o index com maior numero de propriedades
export const indexDefaultSelector = createSelector(indexesSelector, indexes =>
  indexes.reduce(
    (prevIndex, nextIndex) => {
      // verifica se o proximo indice tem mais imóveis que o anterior
      if (nextIndex.properties > prevIndex.properties) {
        // retorna o proximo indice caso ele seja maior que o anterior
        return { id: nextIndex.id, properties: nextIndex.properties };
      }

      // retorna o anterior caso ele seja maior
      return { id: prevIndex.id, properties: prevIndex.properties };
    },
    {
      id: null,
      properties: -1
    }
  )
);

// Initial State
const initialState = {
  indexes: [],
  deleting: {
    id: null,
    name: null
  },
  meta: {
    isFetching: true
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_INDEX:
      return {
        ...state,
        indexes: item.addUp(state.indexes, action.index)
      };
    case ALTER_INDEX:
      return {
        ...state,
        indexes: item.alter(state.indexes, action.index)
      };
    case REQUEST_INDEXES:
      return {
        ...state,
        meta: { ...state.meta, isFetching: true }
      };
    case RECEIVE_INDEXES:
      return {
        ...state,
        indexes: action.indexes,
        meta: { ...state.meta, isFetching: false }
      };
    case REQUEST_INDEX_DELETE:
      return {
        ...state,
        indexes: item.alter(state.indexes, action, () => ({ isDeleting: true }))
      };
    case RECEIVE_INDEX_DELETE:
      return {
        ...state,
        indexes: item.remove(state.indexes, action),
        deleting: {
          ...state.deleting,
          id: action.id,
          name: action.name
        }
      };
    // do reducer stuff
    default:
      return state;
  }
}

// Action Creators
export const receiveIndex = currentIndex => ({
  type: ADD_INDEX,
  index: currentIndex
});
export const alterIndex = currentIndex => ({
  type: ALTER_INDEX,
  index: currentIndex
});
export const toggleIndexPublic = currentIndex => ({
  type: ALTER_INDEX,
  index: { ...currentIndex, is_site_shown: !currentIndex.is_site_shown }
});
export const requestIndexes = () => ({ type: REQUEST_INDEXES });
export const receiveIndexes = indexes => ({ type: RECEIVE_INDEXES, indexes });
export const requestIndexDelete = id => ({ type: REQUEST_INDEX_DELETE, id });
export const receiveIndexDelete = id => ({ type: RECEIVE_INDEX_DELETE, id });

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getIndexes = params => dispatch => {
  dispatch(requestIndexes());

  return financialIndexService.getList(params).then(({ data }) => {
    dispatch(receiveIndexes(data));
    return data;
  });
};

export const handleTogglePublic = index => dispatch => {
  // Cria um novo indice com o status de publicação invertido
  const newIndex = { ...index, is_site_shown: !index.is_site_shown };

  // Dispara uma ação pra store invertendo o status de publico
  dispatch(alterIndex(newIndex));

  // Faz o request alterando o status
  return financialIndexService.update(newIndex).catch(() => {
    // Se por um acaso der problema na alteração do indice
    // Ele volta pro estado anterior
    dispatch(alterIndex(index));
  });
};

export const addIndex = currentIndex => dispatch =>
  dispatch(receiveIndex(currentIndex));
export const updateIndex = currentIndex => dispatch =>
  dispatch(alterIndex(currentIndex));

export const deleteIndex = (id, name, callBack) => async dispatch => {
  try {
    // Marca que os indices financeiros estão sendo buscados
    dispatch(requestIndexDelete(id));

    // Tenta remover um indice financeiro
    await financialIndexService.remove(id);
    Alert.info('Deletando índice', {
      customFields: {
        id,
        confirmText: 'Desfazer',
        onConfirm: () => {
          reverseDelete(id)(dispatch);
        }
      }
    });
    dispatch(receiveIndexDelete(id));
  } catch ({ response: { status, data } }) {
    if (status === 424) {
      const { data: financialIndex } = await financialIndexService.getOne(id);

      openModalFinancialDelete({
        financialIndex,
        initialValues: { from_financial_index_id: id },
        message: data.message,
        onSubmitSuccess: () => {
          // Atualiza a lista de usuários quando termina de cadastrar
          getIndexes({ filter: { by_defaults: false } })(dispatch);
        }
      })(dispatch);

      return;
    }
    Alert.info('Opa!! você não pode excluir esse índice');
  }
};

export const reverseDelete = id => dispatch =>
  financialIndexService.reactivate(id).then(({ data }) => {
    Alert.success(msg.reactivate(data));
    dispatch(addIndex(data));
    return data;
  });
