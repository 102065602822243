import React from 'react';
// Component
import Table from 'components/Table';
import BillingItem from '../BillingItem';
import { addCurrency } from 'lib/money-helpers';

const BillingTable = ({ data, meta, toggleChecked }) => (
  <Table
    responsive
    data={data}
    isFetching={false}
    renderTitle={() => (
      <tr>
        <th className="h-sticky--left">Período</th>
        <th className="h-sticky--right Table__cell--money">Total</th>
        <th width={400} />
      </tr>
    )}
    renderItem={(data, index) => (
      <BillingItem
        key={`item-${data.broker}-${index}`}
        data={data}
        toggleChecked={toggleChecked}
      />
    )}
    renderFooter={() => (
      <tr>
        <td className="h-sticky--left" />
        <td className="Table__cell--money h-sticky--right">
          {addCurrency(meta?.total)}
        </td>
        <td />
      </tr>
    )}
  />
);

export default BillingTable;
