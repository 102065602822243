import { plural } from 'lib/text';
import { networkSelector, setNetwork } from 'modules/propertySearch';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ChangeLayout from '../ChangeLayout';
import FormSort from '../FormSort';
import { Actions, Divider, Link, Results, Wrapper } from './styles';

function Header({ params, meta }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const networkActive = useSelector(networkSelector);

  const handleClickNetwork = (network) => () => {
    setTimeout(() => {
      dispatch(setNetwork(network));
    }, 200);
    let currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.delete('filter[on_network][]');
    currentSearchParams.set('filter[on_network][]', network);
    currentSearchParams.set('offset', 1);
    history.push({
      search: currentSearchParams.toString(),
    });
  };

  return (
    <Wrapper>
      <Results>
        <Link
          className={networkActive === 'self' && 'active'}
          href="#"
          onClick={handleClickNetwork('self')}
        >
          {plural(
            params?.filter?.count?.self?.count || meta?.pagination?.total,
            'Imóvel encontrado',
            'Imóveis encontrados'
          )}
        </Link>
        {params?.filter?.count?.guru?.count > 0 && (
          <>
            <Divider> | </Divider>
            <Link
              className={networkActive === 'guru' && 'active'}
              href="#"
              onClick={handleClickNetwork('guru')}
            >
              {params?.filter?.count?.guru?.count} Guru
            </Link>
          </>
        )}
        {params?.filter?.count?.orulo?.count > 0 && (
          <>
            <Divider> | </Divider>
            <Link
              className={networkActive === 'orulo' && 'active'}
              href="#"
              onClick={handleClickNetwork('orulo')}
            >
              {params?.filter?.count?.orulo?.count} Órulo
            </Link>
          </>
        )}
        {params?.filter?.count?.dwv?.count > 0 && (
          <>
            <Divider> | </Divider>
            <Link
              className={networkActive === 'dwv' && 'active'}
              href="#"
              onClick={handleClickNetwork('dwv')}
            >
              {params?.filter?.count?.dwv?.count} DWV
            </Link>
          </>
        )}
      </Results>
      <Actions>
        <FormSort />
        <ChangeLayout />
      </Actions>
    </Wrapper>
  );
}

export default Header;
