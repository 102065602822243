import React from 'react';
import { connect } from 'react-redux';
// Components
import Measures from '../../components/Measures';
// Modules
import { measuresSelector } from '../../module';

class MeasuresContainer extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  componentDidUpdate(prevProps) {
    //if (nextMeasures !== null && nextMeasures !== prevMeasures) {
    //  nextMeasures.map(({ name }) => {
    //    this.props.change(`areas.${name}.name`, name);
    //  })
    //}
  }

  render() {
    const { measures, change } = this.props;
    if (!measures) return null;

    return <Measures change={change} areas={measures} />;
  }
}

const mapStateToProps = state => ({
  measures: measuresSelector(state)
});

export default connect(mapStateToProps)(MeasuresContainer);
