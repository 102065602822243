import React from 'react';
// Components
import NoContent from 'components/NoContent';
import Button from 'components/Button';
// Assets
import iconSugestion from './icon-sugestion.svg';

const propTypes = {};
const defaultProps = {};

const NoDataSugestion = ({ onClick }) => (
  <NoContent
    image={iconSugestion}
    title="Sugestão"
    text={
      <>
        Dê sugestões de recursos ou melhorias
        <br /> para agilizar o seu trabalho.
      </>
    }
  >
    <Button color="success" onClick={onClick}>
      Enviar Sugestão
    </Button>
  </NoContent>
);

NoDataSugestion.propTypes = propTypes;
NoDataSugestion.defaultProps = defaultProps;

export default NoDataSugestion;
