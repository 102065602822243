import api, { apiStorage } from 'services/index';
import { responseMessage } from 'lib/service-helpers';

export const getOneConfig = (params) =>
  api.getOne('settings/sites/partners', '', params);

export const updateConfig = (values) =>
  api.update('settings/sites/partners', values).then(responseMessage('Salvo'));

export const getOne = ({ id }) => api.getOne('settings/sites/partners', id);

export const create = (values) =>
  api
    .create('settings/sites/partners', values)
    .then(responseMessage('Parceiro adicionado'));

/**
 * Atualiza um depoimento
 * @returns {Promise<AxiosResponse<any>>}
 */
export const update = (values) =>
  api
    .update('settings/sites/partners', values)
    .then(responseMessage('Parceiro atualizado'));

export const uploadImage = async ({ id, image }) => {
  if (!image) return true;
  return apiStorage.uploadFile(`sites/partners/${id}`, { image });
};

export const createOrUpdate = async ({ image, ...values }) => {
  if (values.id) {
    await uploadImage({ id: values.id, image });
    return update(values);
  }

  const res = await create(values);

  if (res.data.id) {
    await uploadImage({ id: res.data.id, image });
  }

  return res;
};

export const remove = ({ id }) =>
  api
    .delete('settings/sites/partners', id)
    .then(responseMessage('Parceiro removido'));
