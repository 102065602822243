import createDataReducer from 'modules/data';
// Service
import * as condosService from 'services/condos';

export const {
  reducer: condosCharacteristicsReducer,
  selectors: condosCharacteristicsSelectors,
  actionCreators: condosCharacteristicsActions
} = createDataReducer('filter/condosCharacteristics');

/**
 * Busca as características do condomínio
 * @returns {Function}
 */
export const fetchCondoCharacteristics = params => async dispatch => {
  dispatch(condosCharacteristicsActions.request());
  const { data } = await condosService.getCondosCharacteristics(params);
  dispatch(condosCharacteristicsActions.receive(data));
};

export default condosCharacteristicsReducer;
