import React from 'react';
import PropTypes from 'prop-types';
import { MdEmail, MdDelete } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
// Components
import Table from 'components/Table';
import IconBall from 'components/IconBall';
import Check from 'components/Check';
//import Dropdown, { List, Divider, Item } from 'components/Dropdown';

// Containers
//import PersonFieldChange from 'containers/PersonFieldChange';

const propTypes = {
  handleToggleSelected: PropTypes.func.isRequired
};
const defaultProps = {
  handleToggleSelected: null
};

const Btn = ({ children, ...rest }) => (
  <IconBall type="rounded" size="large" className="h-margin-left--5" {...rest}>
    {children}
  </IconBall>
);

const TablePeople = ({
  data,
  openSendMail,
  onClickDiscardPerson,
  handleToggleSelected,
  onClickPerson,
  shareWhatsapp
}) => (
  <Table
    type="full"
    data={data}
    renderTitle={() => (
      <tr>
        <th />
        <th style={{ paddingLeft: 0 }}>Nome</th>
        <th>Telefone</th>
        <th colSpan={2}>E-mail</th>
      </tr>
    )}
    renderItem={person => (
      <tr
        key={`person-${person.id}`}
        className="h-hover"
        onClick={onClickPerson(person)}
      >
        <td
          className="Table__cell--small"
          style={{ paddingRight: 5 }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Check
            checked={person?.isSelected}
            onClick={handleToggleSelected(person)}
          />
        </td>
        <td className="h-link" style={{ paddingLeft: 0 }}>
          {person.name}
        </td>
        <td>{person?.primary_phone?.number || <p>Não informado</p>}</td>
        <td>{person.email || <p>Não informado</p>}</td>
        <td className="Table__cell--small">
          <div className="h-flex" onClick={e => e.stopPropagation()}>
            {person?.primary_phone?.number && (
              <Btn color="success" onClick={shareWhatsapp(person)}>
                <FaWhatsapp />
              </Btn>
            )}
            {person.email && (
              <Btn color="default" onClick={openSendMail([person])}>
                <MdEmail />
              </Btn>
            )}
            <Btn color="danger" onClick={onClickDiscardPerson(person)}>
              <MdDelete />
            </Btn>
          </div>
        </td>
      </tr>
    )}
  />
);

TablePeople.propTypes = propTypes;
TablePeople.defaultProps = defaultProps;

export default TablePeople;
