import dataReducer from 'modules/data';
// Service
import * as emailService from 'services/settings/email';

export const {
  reducer: aliasReducer,
  selectors: aliasSelector,
  actionCreators: aliasActions
} = dataReducer(`email/alias`);

/**
 * Busca todos os alias
 * @param params
 * @returns {Function}
 */
export const fetchAlias = params => async dispatch => {
  dispatch(aliasActions.request());

  try {
    const { data } = await emailService.getAliases();
    dispatch(aliasActions.receive(data));
  } catch {
    dispatch(aliasActions.reset());
  }
};

/**
 * Remove um alias da lista
 * @param address
 * @returns {Function}
 */
export const removeAlias = address => async dispatch => {
  dispatch(aliasActions.removing(address, 'address'));

  try {
    await emailService.removeAlias(address);
    dispatch(aliasActions.remove(address, 'address'));
  } catch {
    dispatch(aliasActions.reset());
  }
};
