import React from 'react';
import { Portal } from 'react-portal';
import PropTypes from 'prop-types';
import { Manager, Popper, Target } from 'react-popper';

class Popover extends React.Component {
  static propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    popperOptions: PropTypes.object,
    children: PropTypes.node.isRequired,
    isControlled: PropTypes.bool,
    component: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.object,
      PropTypes.func
    ]).isRequired
  };

  static defaultProps = {
    width: 240,
    isControlled: false,
    onOpen: null,
    onClose: null,
    popperOptions: {
      className: 'Popover',
      placement: 'bottom'
    }
  };

  state = {
    isOpen: false
  };

  get isOpen() {
    const { isControlled } = this.props;

    if (isControlled) return this.props.isOpen;
    return this.state.isOpen;
  }

  open = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ isOpen: true }, () => {
      if (this.props.onOpen) this.props.onOpen();
    });
  };

  close = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({ isOpen: false }, () => {
      if (this.props.onClose) this.props.onClose();
    });
  };

  onSubmitSuccess = (...args) => {
    const { onSubmitSuccess } = this.props;

    if (onSubmitSuccess) onSubmitSuccess(...args);

    // fecha a pop quando da sucesso
    this.close();
  };

  render() {
    const {
      width,
      popperOptions,
      component: Component,
      children,
      style,
      ...rest
    } = this.props;

    const isOpen = this.isOpen;

    return (
      <Manager style={{ display: 'inline-block', ...style }}>
        <Target onClick={this.open} style={{ display: 'inherit' }}>
          {children}
        </Target>
        {isOpen && (
          <React.Fragment>
            <Portal>
              <div className="Popover__overlay" onClick={this.close} />
              <Popper
                {...popperOptions}
                style={{ width }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Component
                  {...rest}
                  onSubmitSuccess={this.onSubmitSuccess}
                  handleOpen={this.open}
                  handeClose={this.close}
                  handleClose={this.close}
                />
              </Popper>
            </Portal>
          </React.Fragment>
        )}
      </Manager>
    );
  }
}

export default Popover;
