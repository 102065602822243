import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
// Components
import GroupControl, { GroupItem } from 'components/GroupControl';
import {
  Input,
  InputDate,
  Select,
  SelectMultiple,
  Textarea,
} from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
// Containers
import FieldPerson from 'containers/FieldPerson';
// Helpers
import { normalizeNumbers } from 'lib/formHelpers';
import validate from './validate';

const style = (color) => ({
  square: {
    display: 'inline-block',
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: color,
    verticalAlign: 'middle',
  },
});

const ColorOption = ({ name, color }) => (
  <div>
    <span style={style(color).square} /> {name}
  </div>
);

function ModalEventForm({
  event,
  handleClickRemove,
  person,
  handleSubmit,
  change,
  durationOptions,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <FieldPerson xs={12} />
      </Row>
      <Row>
        <Field xs={12} label="Título" name="summary" component={Input} />
      </Row>
      <Row>
        <Field
          showTimeSelect
          timeIntervals={15}
          xs={6}
          label="Data início"
          name="start_date"
          component={InputDate}
        />
        <Field
          xs={6}
          label="Duração"
          name="duration_minutes"
          component={Select}
          options={durationOptions}
        />
      </Row>
      <Row>
        <Col xs={6}>
          <GroupControl required label="Alertar em">
            <GroupItem>
              <Field
                className="FormControl"
                min={0}
                type="number"
                name="reminder_minutes"
                component={Input}
                normalize={normalizeNumbers}
              />
            </GroupItem>
            <GroupItem size="small">
              <Field
                autoWidth
                name="reminder_unit"
                component={Select}
                placeholder=""
                options={[
                  { label: 'minuto(s)', value: 'minutes' },
                  { label: 'hora(s)', value: 'hours' },
                ]}
              />
            </GroupItem>
          </GroupControl>
        </Col>
        <Field
          clearable={false}
          multi={false}
          creatable={false}
          xs={6}
          label="Prioridade"
          name="color"
          component={SelectMultiple}
          labelKey="name"
          valueKey="color"
          parse={(value) => {
            if (value) return value.color;
            return value;
          }}
          options={[
            {
              name: 'Normal',
              color: '#0063c0',
            },
            {
              name: 'Adiável',
              color: '#00c080',
            },
            {
              name: 'Urgente',
              color: '#f14667',
            },
          ]}
          valueRenderer={ColorOption}
          optionRenderer={ColorOption}
        />
      </Row>
      <Row>
        <Field
          xs={12}
          label="Observação"
          name="description"
          component={Textarea}
        />
      </Row>
      <ModalFooter>
        {event && event.id && (
          <Button
            color="white"
            colorText="danger"
            onClick={handleClickRemove}
            className="h-flex__cell--shrink"
          >
            Remover evento
          </Button>
        )}
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'ModalEventForm',
  validate,
  enableReinitialize: true,
})(ModalEventForm);
