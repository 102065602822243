import React from 'react';
import { MdMenuBook } from 'react-icons/md';
// Helpers
import PropertiesList from 'containers/PropertyDashboard/containers/PropertiesList';
import { getPropertiesBookings } from 'services/dashboards/properties';

export default function PanelPropertyDeal({ updatedTime, updateTime }) {
  return (
    <PropertiesList
      dataExtractor={(booked) => booked.property}
      key="property-deal"
      title="Últimos imóveis reservados"
      icon={MdMenuBook}
      color="danger"
      renderNoData={() => <>Nenhum imóvel reservado até o momento</>}
      updatedTime={updatedTime}
      request={getPropertiesBookings}
      limit={5}
      afterClose={updateTime}
      renderText={(booking) => {
        return <p>Reservado até: {booking.booked_until || '-'}</p>;
      }}
    />
  );
}
