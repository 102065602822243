export const getStages = (notes) => {
  try {
    const stagesObject = notes.reduce((acc, note) => {
      if (!note?.stage?.id) {
        return acc;
      }
      return { ...acc, [note?.stage?.id]: note.stage };
    }, {});

    return Object.keys(stagesObject).map((key) => ({
      ...stagesObject[key],
    }));
  } catch {
    return [];
  }
};
