import Button from "components/Button";
import { ModalFooter } from "components/Modal";
import ZoneField from "containers/LocationFields/ZoneField";
import { plural } from "lib/text";
import { useMemo } from "react";
import { Row } from "react-flexbox-grid";
import { reduxForm } from "redux-form";
import validate from './validate';

function FormZone({
  zone,
  options,
  handleClose,
  isSubmitting,
  isPristine,
  handleSubmit
}) {
  const counts = useMemo(() => {
    let acc = [];

    if (zone?.properties_count) {
      acc = [
        ...acc,
        plural(zone?.properties_count, 'imóvel', 'imóveis')
      ];
    }

    return acc;
  }, [zone]);

  return (
    <form onSubmit={handleSubmit}>
      <p className="h-color--primary">Essa zona está sendo utilizada em:</p>
      <ul style={{ listStylePosition: 'inside', margin: '10px 0' }}>
        {counts?.map((reason, i) => (
          <li key={`reason-${i}`}>{reason}</li>
        ))}
      </ul>
      <p className="h-color--primary" style={{ marginBottom: '15px' }}>
        Escolha para qual zona transferi-lo:
      </p>
      <Row style={{ marginBottom: '-15px' }}>
        <ZoneField
          xs={12}
          label="Transferir para"
          name="recipient_id"
          cityId={zone?.city_id}
          options={options}
          labelKey="name"
          valueKey="id"
        />
      </Row>

      <ModalFooter>
        <Button onClick={handleClose} color="white" colorText="secondary">
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button
          type="submit"
          color="success"
          disabled={isSubmitting || isPristine}
        >
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormZone',
  enableReinitialize: true,
  validate
})(FormZone);
