import { Header } from 'features/Financial/components/Header';
import { Financial } from 'features/Financial';
import { useIndexCreditCardBills } from '../../hooks/useCreditCard';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { AccountInfo } from './components/AccountInfo';
import { TableBills } from './components/TableBills';
import moment from 'lib/moment';

export function ListBills() {
  const history = useHistory();
  const { accountId } = useParams();
  const { bills, billsMeta, billsIsFetching, fetchBills } =
    useIndexCreditCardBills();

  useEffect(() => {
    if (accountId) {
      fetchBills({ id: accountId, year: moment().format('YYYY') });
    }
  }, [accountId]);

  return (
    <>
      <Header.Header>
        <Header.Title>Cartões</Header.Title>
        <Header.Actions>
          <Financial.AddPosting
            initialValues={{ account_id: accountId }}
            onSuccess={() => {
              history.push(`/financial/credit-card/${accountId}`);
            }}
          />
        </Header.Actions>
      </Header.Header>
      <Financial.Content isFetching={billsIsFetching}>
        <Financial.DateSelector
          initialValue={moment().format('YYYY')}
          type="year"
          onChange={(value, formatedValue) => {
            fetchBills({ id: accountId, year: formatedValue });
          }}
        />
        <AccountInfo account={billsMeta?.account} />
        <TableBills data={bills} account={billsMeta?.account} />
      </Financial.Content>
    </>
  );
}
