import React, { useCallback } from 'react';
import _ from 'lodash';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { isSubmitting } from 'redux-form';
// Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
// Services
import * as portalsService from 'services/settings/portals';
import { plural } from 'lib/text';

function ModalPortalProperty({
  pagination,
  portalRealEstateId,
  portalName,
  optionName,
  optionId,
  propertiesId,
  filter,
  onSuccess,

  modalConfig,
  isOpen,
  modalType,
  handleClose
}) {
  const isFormSubmitting = useSelector(isSubmitting('ModalPortalProperty'));
  const hasPagination = pagination?.total_pages > 1;

  const handleSubmit = useCallback(({ option }) => {
    if (option === 'all_pages' || !hasPagination) {
      let _filter = filter;

      if (_.isEmpty(filter)) {
        _filter = {
          ..._filter,
          by_portal_real_estate_id: portalRealEstateId
        };
      }

      return portalsService.addProperties({
        portalRealEstateId,
        portalRealEstateOptionId: optionId,
        filter: _filter
      });
    }

    return portalsService.addProperties({
      portalRealEstateId,
      portalRealEstateOptionId: optionId,
      propertiesId: propertiesId
    });
  }, []);

  const handleSubmitSuccess = useCallback(() => {
    if (onSuccess) onSuccess();
    handleClose();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFormSubmitting}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={`Selecionar imóveis para o portal ${portalName}`}
        handleClose={handleClose}
      >
        <p className="h-color--primary h-margin-bottom--10">
          {!hasPagination
            ? 'Deseja selecionar todos os imóveis?'
            : `Deseja selecionar apenas os imóveis dessa página ou todos os imóveis de todas as ${plural(
                pagination?.total_pages,
                'página',
                'páginas'
              )}?`}
        </p>
        <Form
          hasPagination={hasPagination}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalPortalProperty;
