import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import Info from '../../components/Info';
// Modules
import { fetchTypeAndSubtypes, typesAndSubtypesSelector, typeSelector } from '../../module';
// Shapes
import { PersonShape } from 'constants/shapes';
import { formValueSelector } from 'redux-form';

class InfoContainer extends React.Component {
  state = {
    isFocusMinPrice: false,
    isFocusMaxPrice: false
  };

  static propTypes = {
    people: PropTypes.arrayOf(PersonShape)
  };

  static defaultProps = {
    people: []
  };

  componentDidMount() {
    // Busca todos os tipos e subtipos
    this.props.fetchTypeAndSubtypes();
  }

  getType = typeOrSubtypeId => {
    const { typesAndSubtypes } = this.props;

    const typeOrSubtype = typesAndSubtypes.find(
      type => type.id === typeOrSubtypeId
    );
    return typesAndSubtypes.find(type => type.id === typeOrSubtype.type_id);
  };

  handleChangeTypeOrSubtype = async (e, typeOrSubtypeId) => {
    const { typeSelected, change } = this.props;

    if (!typeOrSubtypeId) {
      // Reseta os campos de comodos e estab
      change('areas', {});
      change('characteristics', {});
      return false;
    }

    const type = this.getType(typeOrSubtypeId);

    if (typeSelected?.type_id !== type.type_id) {
      change('situations_id', null);
    }

    type.area_fields.forEach(area => {
      this.props.change(`areas.${area.name}`, {
        ...area,
        measure: area.measures[0]
      });
    });
  };

  handleFocusMinPrice = bool => () => {
    this.setState({ isFocusMinPrice: bool });
  };

  handleFocusMaxPrice = bool => () => {
    this.setState({ isFocusMaxPrice: bool });
  };

  render() {
    const {
      isSearching,
      transaction,
      typeSelected,
      typesAndSubtypes,
      change
    } = this.props;

    return (
      <Info
        isSearching={isSearching}
        transaction={transaction}
        typeSelected={typeSelected}
        change={change}
        typesAndSubtypes={typesAndSubtypes}
        isFocusMinPrice={this.state.isFocusMinPrice}
        isFocusMaxPrice={this.state.isFocusMaxPrice}
        handleFocusMinPrice={this.handleFocusMinPrice}
        handleFocusMaxPrice={this.handleFocusMaxPrice}
        handleChangeTypeOrSubtype={this.handleChangeTypeOrSubtype}
      />
    );
  }
}

const selector = formValueSelector('ProfileForm');

const mapStateToProps = state => ({
  transaction: selector(state, 'transaction'),
  typesAndSubtypes: typesAndSubtypesSelector.getAll(state),
  typeSelected: typeSelector(state)
});

const mapDispatchToProps = {
  fetchTypeAndSubtypes
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoContainer);
