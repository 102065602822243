import React, { useCallback } from 'react';
// Components
import MainTitle from 'components/MainTitle';
import FormFinance from './components/FormFinance';
// Services
import * as financeService from 'services/settings/finance';
// HOC
import { useRequest } from 'hooks/useRequest';
// Contexts
import BankProvider, { BankContext } from './BankProvider';
import TableBanks from './components/TableBanks';
import Button from 'components/Button';

export default function Finance() {
  const { data: initialValues } = useRequest({
    request: financeService.getFinance,
    initialState: {}
  });

  const handleSubmit = useCallback(financeService.updateFinance, []);

  return (
    <BankProvider>
      <BankContext.Consumer>
        {({ openModalBank }) => (
          <>
            <MainTitle
              title="Editar página Financie"
              text="Adicione nessa página os bancos que você indica para que seus clientes simulem finaciamentos."
            >
              <Button color="secondary" onClick={openModalBank}>
                Adicionar Banco
              </Button>
            </MainTitle>
            <FormFinance
              initialValues={initialValues}
              onSubmit={handleSubmit}
            />
            <TableBanks />
          </>
        )}
      </BankContext.Consumer>
    </BankProvider>
  );
}
