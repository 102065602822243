import React from 'react';
import { ModalTemplate } from 'components/Modal';
import FormPortal from 'containers/ModalRoot/ModalPortal/components/FormPortal';
// import { Wrapper } from './styles';

function Form({
  handleClose,
  portal,
  options,
  initialValues,
  handleSubmit,
  onSubmitSuccess,
  onSubmitFail,
}) {
  return (
    <ModalTemplate
      title={`Conectar com ${portal.name}`}
      handleClose={handleClose}
    >
      <FormPortal
        handleClose={handleClose}
        options={options}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSubmitSuccess={onSubmitSuccess}
        onSubmitFail={onSubmitFail}
      />
    </ModalTemplate>
  );
}

export default Form;
