import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { FaWhatsapp } from 'react-icons/fa';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
// Modules
import { openModalPersonAdd } from 'modules/modal';
// Components
import ListInfo from 'components/ListInfo';
import { ModalSubtitle } from 'components/Modal';
import Button from 'components/Button';
import Can from 'containers/Can';
import { PERSON_PERMISSIONS } from 'constants/rules';
import RequestPermission from 'containers/ModalRoot/ModalPerson/components/RequestPermission';
import { countries } from 'country-flag-icons';
import flags from 'country-flag-icons/react/3x2';

import { Phones } from './styles';

const propTypes = {
  openModalPersonAdd: PropTypes.func.isRequired,
};
const defaultProps = {};

const Flag = ({ size = 20, style, iso2, ...props }) => {
  const iso2Upper = iso2.toUpperCase();

  // Verifica se tem a bandeira na biblioteca
  // Também verifica se o número é brasileiro se for não aparece bandeira
  if (!countries.includes(iso2Upper) || iso2Upper === 'BR') return null;

  return React.createElement(
    flags[iso2Upper],
    { ...props, style: { width: size, ...style } },
    null
  );
};

// Infos
class Infos extends React.Component {
  componentDidMount() {
    ReactTooltip.rebuild();
  }

  handleOpenModalPerson = (id, callBack) => () => {
    this.props.openModalPersonAdd(id, callBack);
  };

  renderGroupPerson = (groups) => {
    return groups ? groups.map((group) => group.name).join(', ') : '--';
  };

  renderPhones = (phones) => {
    if (isEmpty(phones)) return 'Nenhum telefone';

    return phones
      .map(({ number, iso2, description, is_whatsapp }) => {
        return (
          <span>
            <Flag iso2={iso2} style={{ marginRight: '5px' }} />
            {number}({description})
            {is_whatsapp && <FaWhatsapp className="h-color--success" />}
          </span>
        );
      })
      .reduce((acc, x) => {
        return acc === null ? [x] : [acc, ', ', x];
      }, null);
  };

  get renderRelateds() {
    const {
      modalPerson: { person },
      openModalPerson,
    } = this.props;

    const allRelateds = person?.relateds;

    if (isEmpty(allRelateds)) return 'Nenhum cliente relacionado';

    return allRelateds
      .map(({ id, name, description }) => {
        return (
          <span>
            <span
              onClick={() => openModalPerson({ id }, { initialPage: 'Infos' })}
              className="h-link"
            >
              {name}
            </span>
            ({description})
          </span>
        );
      })
      .reduce((acc, x) => {
        return acc === null ? [x] : [acc, ', ', x];
      }, null);
  }

  render() {
    const {
      modalPerson: { person },
      onSubmitSuccessEdit,
    } = this.props;

    return (
      <Can
        run={PERSON_PERMISSIONS.PRIVATE}
        permissions={person.permissions}
        no={() => <RequestPermission person={person} />}
        yes={() => (
          <div>
            <ModalSubtitle title="Dados do cliente">
              <Can
                run={PERSON_PERMISSIONS.EDIT}
                permissions={person.permissions}
              >
                {(condition) => {
                  let tooltipProps = {};
                  if (!condition) {
                    tooltipProps = {
                      'data-tip':
                        'Você não tem permissão para editar esse cliente',
                    };
                  }
                  return (
                    <span style={{ display: 'inline-block' }} {...tooltipProps}>
                      <Button
                        disabled={!condition}
                        color="secondary"
                        size="medium"
                        onClick={this.handleOpenModalPerson(person, () => {
                          if (onSubmitSuccessEdit) onSubmitSuccessEdit();
                        })}
                      >
                        Editar Dados
                      </Button>
                    </span>
                  );
                }}
              </Can>
            </ModalSubtitle>
            <ListInfo>
              <ListInfo.row>
                <ListInfo.item
                  title="Categoria"
                  text={this.renderGroupPerson(person.groups)}
                />
                <ListInfo.item
                  title="Telefones"
                  text={<Phones>{this.renderPhones(person.phones)}</Phones>}
                />
              </ListInfo.row>
              <ListInfo.row>
                <ListInfo.item title="E-mail" text={person.email || '--'} />
                <ListInfo.item
                  title="Aniversário"
                  text={person.birth_date || '--'}
                />
                <ListInfo.item title="Tipo" text={person.type_name || '--'} />
                <ListInfo.item title="CPF" text={person.cpf_cnpj || '--'} />
                <ListInfo.item title="RG" text={person.rg_ie || '--'} />
                <ListInfo.item
                  title="Profissão"
                  text={person.profession || '--'}
                />
              </ListInfo.row>
              <ListInfo.row>
                <ListInfo.item
                  title="UF"
                  text={(person.state && person.state.name) || '--'}
                />
                <ListInfo.item
                  title="Cidade"
                  text={(person.city && person.city.name) || '--'}
                />
                <ListInfo.item
                  title="Bairro"
                  text={
                    (person.neighborhood && person.neighborhood.name) || '--'
                  }
                />
                <ListInfo.item
                  title="Logradouro"
                  text={person.address_street || '--'}
                />
                <ListInfo.item
                  title="Complemento"
                  text={person.address_complement || '--'}
                />
                <ListInfo.item
                  title="Nº"
                  text={person.address_number || '--'}
                />
                <ListInfo.item title="CEP" text={person.address_cep || '--'} />
              </ListInfo.row>
            </ListInfo>
            <ListInfo>
              <ListInfo.row>
                <ListInfo.item
                  title="Clientes ligados"
                  text={this.renderRelateds}
                />
              </ListInfo.row>
            </ListInfo>
          </div>
        )}
      />
    );
  }
}

Infos.propTypes = propTypes;
Infos.defaultProps = defaultProps;

export default connect(null, { openModalPersonAdd })(Infos);
