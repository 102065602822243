import styled from 'styled-components';

export const Wrapper = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin: -2px -4px -8px -4px;
`;

export const Item = styled.div`
  line-height: 20px;
  padding: 4px;

  svg {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    color: ${(p) => p.theme.colors.secondary};
    margin-right: 0.2em;
  }
`;
