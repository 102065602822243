import {
  Discount,
  Info,
  Infos,
  Main,
  NewPrice,
  OldPrice,
  Prices,
  PricesLabels,
  Title,
  Wrapper,
} from 'containers/ModalRoot/ModalPayment/pages/Plan/components/PlanItem/styles';
import { Field } from 'redux-form';

const Item = ({ label, value, input, values }) => {
  const checked = value === input.value;

  function handleSelect() {
    input.onChange(value);
  }

  return (
    <Wrapper active={checked} onClick={handleSelect}>
      <Main>
        <Infos>
          <Title>{label}</Title>
          <Info>Gere {values?.credits} descrições com ChatGPT</Info>
          <Info>Pagamento único</Info>
        </Infos>

        <PricesLabels>
          {values?.oldPrice && <Info>De</Info>}
          {values?.discount && (
            <Discount>{values?.discount} de desconto</Discount>
          )}
          {values?.price && <NewPrice>Por</NewPrice>}
        </PricesLabels>
      </Main>

      <Prices>
        {values?.oldPrice && <OldPrice>{values.oldPrice}</OldPrice>}
        {values?.price && <Info>- {values.price}</Info>}
        <NewPrice>{values?.newPrice}</NewPrice>
      </Prices>
    </Wrapper>
  );
};

export const FieldItem = ({ name, label, value, values }) => {
  return (
    <Field
      type="checkbox"
      name={name}
      label={label}
      props={{
        value,
      }}
      values={values}
      component={Item}
    />
  );
};

export default FieldItem;
