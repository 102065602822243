import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, initialize, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import Textarea from 'components/Form/components/Textarea';
import { Select } from 'components/Form';
import ListOptions from 'components/ListOptions';
import Container from 'pages/Site/components/ContainerSite';
// Constants
import { BOOL } from 'constants/options';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const GlobalForm = ({
  is_on_maintenance_mode,
  handleSubmit,
  submitting,
  pristine
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Container padding style={{ marginBottom: 15 }}>
        <ListOptions title="Geral">
          <ListOptions.item
            title="Site em manutenção"
            text="Substitui provisoriamente o seu site por uma página indicando que o mesmo está em manutenção."
            renderOptions={() => (
              <Field
                canRemoveSelf={false}
                buttonTemplate
                name="is_on_maintenance_mode"
                component={Select}
                options={BOOL}
              />
            )}
          />
          {is_on_maintenance_mode && (
            <ListOptions.item
              title="Texto site em manutenção"
              text="Digite o texto que será exibido no site enquanto ele estiver em manutenção."
            >
              <Field
                className="h-margin-top--10"
                name="maintenance_message"
                component={Textarea}
              />
            </ListOptions.item>
          )}
        </ListOptions>
        <FixedBar style={{ left: 240 }}>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar
          </Button>
        </FixedBar>
      </Container>
    </form>
  );
};

GlobalForm.defaultProps = defaultProps;
GlobalForm.propTypes = propTypes;

const selector = formValueSelector('GlobalForm');

const mapStateToProps = state => ({
  is_on_maintenance_mode: selector(state, 'is_on_maintenance_mode')
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'GlobalForm',
    onSubmitSuccess: (res, dispatch) => {
      // Reinicializa o formulário com os valores
      dispatch(initialize('GlobalForm', res.data, false));
    },
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
  })
)(GlobalForm);
