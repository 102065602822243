import Button from 'components/Button';
import Container, { Header } from 'components/Container';
import Nodata from 'components/Nodata';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { closeModal, openModalSuperhighlightStore } from 'modules/modal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-tooltip';
import { formValueSelector } from 'redux-form';
import * as superhighlightsService from 'services/sites/superHighlights';
import TableSuperHighlights from '../TableSuperHighlights';

const selector = formValueSelector('FormSuperHighlights');

function SuperhighlightList({ superhighlights, fetch, handleChangeOrder }) {
  const dispatch = useDispatch();
  const type = useSelector((state) => selector(state, 'super_highlights_type'));

  useEffect(() => {
    Tooltip.rebuild();
  }, [superhighlights]);

  const openModal = (id = null) => {
    dispatch(
      openModalSuperhighlightStore({
        id,
        type,
        superhighlights,
        onSubmitSuccess: () => {
          dispatch(closeModal());
          fetch();
        },
      })
    );
  };

  const update =
    ({ id }) =>
    () => {
      openModal(id);
    };

  const handleRemove =
    ({ id }) =>
    () => {
      dispatch(
        openConfirmation({
          title: 'Remover super destaque?',
          text: `Você irá remover o super destaque `,
          cancelButtonText: 'Cancelar',
          submitButtonText: 'Remover super destaque',
          request: () => superhighlightsService.remove({ id }),
          onSuccess: () => fetch(),
        })
      );
    };

  if (superhighlights?.length <= 0) {
    return (
      <Container padding style={{ marginTop: '25px' }}>
        <Nodata
          image={null}
          title="Nenhum super destaque"
          text="Adicione o primeiro super destaque a baixo"
        >
          <Button onClick={() => openModal()} className="h-margin-top--15">
            Cadastrar super destaque
          </Button>
        </Nodata>
      </Container>
    );
  }

  return (
    <Container padding style={{ marginTop: '25px' }}>
      <Header>
        <h4>Super destaques</h4>
        <div
          data-tip={
            superhighlights?.length >= 3
              ? 'Você não pode cadastrar mais que 3 super destaques'
              : null
          }
        >
          <Button
            disabled={superhighlights?.length >= 3}
            onClick={() => openModal()}
          >
            Cadastrar super destaque
          </Button>
        </div>
      </Header>
      <TableSuperHighlights
        data={superhighlights}
        handleChangeOrder={handleChangeOrder}
        handleClickUpdate={update}
        handleClickRemove={handleRemove}
      />
    </Container>
  );
}

export default SuperhighlightList;
