import React from 'react';
import { Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
// Components
import { Input } from 'components/Form';

class PriceField extends React.Component {
  state = {
    isFocusMin: false,
    isFocusMax: false,
  };

  /**
   * Pega o tamanho da coluna input de valor máximo
   * @return {number}
   */
  get maxSize() {
    const { isFocusMin, isFocusMax } = this.state;

    if (isFocusMin) return 4;
    if (!isFocusMax) return 6;

    return 8;
  }

  /**
   * Pega o tamanho da coluna input de valor mínimo
   * @return {number}
   */
  get minSize() {
    const { isFocusMin, isFocusMax } = this.state;

    if (isFocusMax) return 4;
    if (!isFocusMin) return 6;

    return 8;
  }

  render() {
    return (
      <Row className="row--5">
        <Field
          xs={this.minSize}
          valueFormat
          label="Preço"
          name="price.greater_equals"
          placeholder="R$0,00"
          component={Input}
          onFocus={() => {
            this.setState({ isFocusMin: true });
          }}
          onBlur={(e) => {
            if (this.props.onBlur) this.props.onBlur(e);
            this.setState({ isFocusMin: false });
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (this.props.onBlur) this.props.onBlur(e);
            }
          }}
        />
        <Field
          xs={this.maxSize}
          valueFormat
          label="&nbsp;"
          name="price.lower_equals"
          placeholder="R$0,00"
          component={Input}
          onFocus={() => {
            this.setState({ isFocusMax: true });
          }}
          onBlur={(e) => {
            if (this.props.onBlur) this.props.onBlur(e);
            this.setState({ isFocusMax: false });
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (this.props.onBlur) this.props.onBlur(e);
            }
          }}
        />
      </Row>
    );
  }
}

export default PriceField;
