import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import Container from 'components/Container';
import FixedBar from 'components/FixedBar';
import { Code, FieldBool, Input, Textarea } from 'components/Form';
import GroupControl, { GroupItem } from 'components/GroupControl';
// Validate
import validate from './validate';

const defaultProps = {
  users: null,
  isFetchingUsers: false,

  components: null,
  isFetchingComponents: false,
};

const propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
  isFetchingComponents: PropTypes.bool,

  handleSubmit: PropTypes.func.isRequired,
};

const FormSlides = ({ handleSubmit, submitting, pristine }) => {
  // const { link, meta_title, meta_description } = useFormValue([
  //   'meta_title',
  //   'meta_description',
  //   'link',
  // ]);

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Field
            required
            xs={10}
            label="Título do menu"
            name="label"
            component={Input}
          />
          <FieldBool xs={2} label="Pagina ativa?" name="status" />
        </Row>
        <Row>
          <Col xs={12}>
            <GroupControl label="URL da página" required={false}>
              <GroupItem
                label={localStorage.getItem('current-user').primary_domain_url}
                size="small"
              />
              <GroupItem>
                <Field name="link" component={Input} />
              </GroupItem>
            </GroupControl>
          </Col>
        </Row>
        <Row>
          <Field
            xs={12}
            label="Insira seu código"
            name="code"
            component={Code}
          />
        </Row>
        <h3 className="h-margin-bottom--15">SEO</h3>
        <Row middle="xs">
          <Col xs={6}>
            <Row>
              <Field
                xs={12}
                label={
                  <>
                    Título da página{' '}
                    <a
                      href="https://support.google.com/webmasters/answer/35624?hl=pt-BR"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-link"
                    >
                      (saiba mais)
                    </a>
                  </>
                }
                name="meta_title"
                placeholder="Digite o título"
                component={Input}
                maxLength={120}
              />
              <Field
                xs={12}
                type="simple"
                label="Descrição"
                name="meta_description"
                placeholder="Digite a descrição"
                maxLength={156}
                component={Textarea}
              />
            </Row>
          </Col>
          {/*<Col xs={6}>*/}
          {/*  <GoogleResult*/}
          {/*    url={`www.seusite.com.br/${link || ''}`}*/}
          {/*    title={meta_title || 'Título da página'}*/}
          {/*    description={meta_description || ''}*/}
          {/*  />*/}
          {/*</Col>*/}
        </Row>
      </Container>
      <FixedBar style={{ left: 240 }}>
        <Button
          type="button"
          to="/site/layout/style/menu"
          color="white"
          colorText="primary"
        >
          Voltar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar Página
        </Button>
      </FixedBar>
    </form>
  );
};

FormSlides.defaultProps = defaultProps;
FormSlides.propTypes = propTypes;

export default compose(
  reduxForm({
    form: 'FormScript',
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate,
  })
)(FormSlides);
