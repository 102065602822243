// import * as S from './styles'
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export function Chart({ data }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data}>
        <XAxis dataKey="title" />
        <YAxis />
        <Tooltip formatter={(value) => `R$ ${value.toFixed(2)}`} />
        <Legend />
        <Bar dataKey="credits_raw" fill="#31D084" name="Créditos" />
        <Bar dataKey="debits_raw" fill="#F84343" name="Débitos" />
      </BarChart>
    </ResponsiveContainer>
  );
}
