import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import moment from 'lib/moment';
// Components
import ModalFooter from 'components/Modal/components/ModalFooter';
import GroupControl, { GroupItem } from 'components/GroupControl';
import { Input, Select, Textarea, InputDate } from 'components/Form';
import Button from 'components/Button';
// Container
import FieldPerson from 'containers/FieldPerson';
import FieldSelectUser from 'containers/FieldSelectUser';
// Helpers
import { minMax, normalizeDate } from 'lib/formHelpers';
import { addCurrency, removeCurrency } from 'lib/money-helpers';

import validate from './validate';
import ListOptions from 'components/ListOptions';
import FieldBool from 'components/Form/components/FieldBool';
import { MdHelp } from 'react-icons/md';
import PropertyItem from '../../../../../components/PropertyItem';
import { ModalTemplate } from 'components/Modal';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const styleCols = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: -20,
  paddingTop: 20,
  paddingBottom: 0,
};

const HelpProfile = () => (
  <MdHelp
    data-effect="solid"
    data-tip={`Você não será mais avisado para<br/> entrar em contato com esse <br/> cliente ao cadastrar um imóvel.`}
    style={{ display: 'inline-block' }}
    className="h-color--secondary"
  />
);
const HelpDeals = () => (
  <MdHelp
    data-effect="solid"
    data-tip={`Remover da jornarda de<br/> compra os outros negócios<br/> envolvendo esse cliente.`}
    style={{ display: 'inline-block' }}
    className="h-color--secondary"
  />
);

class FormRent extends React.Component {
  get isEditting() {
    return this.props?.deal?.id;
  }

  get title() {
    if (this.isEditting) {
      return 'Editando negócio';
    }

    return 'Parabéns por ter fechado o negócio!';
  }

  /**
   * Retorna o valor do imóvel
   * @return {number}
   */
  get propertyPrice() {
    if (this.props.property) {
      return removeCurrency(this.props.property.calculated_price);
    }

    return 0;
  }

  /**
   * Retorna a taixa do imóvel
   * @return {number}
   */
  get territorialTax() {
    const { territorial_tax_type: type, territorial_tax_price: price } =
      this.props;

    const newPrice = removeCurrency(price);

    // Verifica se o tipo é mensal ou anual
    // Quando é anual tem que retornar o valor da taixa de IPTU / 12
    switch (type) {
      // Anual
      case '1':
        return newPrice / 12;
      // Mensal
      case '2':
        return newPrice;
      default:
        return 0;
    }
  }

  /**
   * Pega a taxa de condomínio se tiver
   * @return {number}
   */
  get condominiumPrice() {
    return removeCurrency(this.props.condominium_price);
  }

  /**
   * Pega a taixa de condomínio se tiver
   * @return {number}
   */
  get anotherTaxes() {
    return removeCurrency(this.props.taxes_price);
  }

  /**
   * Seta o valor do input do valor total mensal
   * @param value
   */
  setPrice = (value) => {
    this.props.change('price', addCurrency(value));
  };

  handleUpdatePrice = () => {
    setTimeout(() => {
      // Pega o valor do imóvel sem formatação
      const { propertyPrice, condominiumPrice, territorialTax, anotherTaxes } =
        this;

      const total =
        propertyPrice + condominiumPrice + territorialTax + anotherTaxes;

      this.setPrice(total);
    }, 50);
  };

  render() {
    const {
      // Form Props
      pristine,
      property,
      submitting,

      // Event Handlers
      handleSubmit,
      handleClose,
      onCreatePerson,

      // Field values
      has_guarantee,
      taxes_note,
      is_included_condo,
      entry_at,
      departure_at,
      isEditting,
    } = this.props;

    return (
      <ModalTemplate title={this.title} handleClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <Row className="row--divider">
            <Col xs={6} style={styleCols}>
              <Row>
                <FieldPerson
                  xs={4}
                  name="people_id"
                  onCreatePerson={onCreatePerson}
                />
                <FieldSelectUser xs={4} label="Corretor" />
                <Field
                  required
                  xs={4}
                  label="Data do Negócio"
                  name="earned_at"
                  component={InputDate}
                />
              </Row>

              <Row>
                <Field
                  required
                  xs={4}
                  label="Data de entrada"
                  name="entry_at"
                  component={Input}
                  normalize={normalizeDate}
                  placeholder="DD/MM/AAAA"
                  maxDate={moment(departure_at, 'DD/MM/YYYY').toDate()}
                  selected={entry_at}
                  selectsStart
                  startDate={entry_at}
                  endDate={departure_at}
                />
                <Field
                  xs={4}
                  label="Data de saída"
                  name="departure_at"
                  component={Input}
                  normalize={normalizeDate}
                  placeholder="DD/MM/AAAA"
                  minDate={moment(entry_at, 'DD/MM/YYYY').toDate()}
                  selected={departure_at}
                  selectsEnd
                  startDate={entry_at}
                  endDate={departure_at}
                />
                <Field
                  isClearable
                  xs={4}
                  label="Data de revisão"
                  name="revision_at"
                  component={Input}
                  normalize={normalizeDate}
                  placeholder="DD/MM/AAAA"
                  dateFormat={'DD/MM/YYYY'}
                />
              </Row>

              <Row>
                <FieldBool xs={4} label="Com garantia?" name="has_guarantee" />

                <Field
                  xs={4}
                  type="number"
                  label="De quantos meses?"
                  name="guarantee_months"
                  component={Input}
                  disabled={!has_guarantee}
                  format={minMax(0, 32760)}
                />

                <Col xs={4}>
                  <GroupControl label="Preço da garantia">
                    <GroupItem label="R$" size="small" />

                    <GroupItem>
                      <Field
                        valueFormat
                        name="guarantee_price"
                        component={Input}
                        disabled={!has_guarantee}
                      />
                    </GroupItem>
                  </GroupControl>
                </Col>
              </Row>

              <Row>
                <FieldBool
                  xs={6}
                  label="Condomínio Incluso no Aluguel?"
                  name="is_included_condo"
                />

                <Col xs={6}>
                  <GroupControl label="Preço do Condomínio">
                    <GroupItem label="R$" size="small" />
                    <GroupItem>
                      <Field
                        valueFormat
                        name="condominium_price"
                        component={Input}
                        disabled={!is_included_condo}
                        onChange={this.handleUpdatePrice}
                      />
                    </GroupItem>
                  </GroupControl>
                </Col>
              </Row>

              <Row>
                <Field
                  canRemoveSelf={false}
                  buttonTemplate
                  xs={6}
                  label="IPTU mensal ou anual?"
                  name="territorial_tax_type"
                  component={Select}
                  options={[
                    { value: 2, label: 'Mensal' },
                    { value: 1, label: 'Anual' },
                  ]}
                  onChange={this.handleUpdatePrice}
                />

                <Col xs={6}>
                  <GroupControl label="Preço IPTU">
                    <GroupItem label="R$" size="small" />
                    <GroupItem>
                      <Field
                        valueFormat
                        name="territorial_tax_price"
                        component={Input}
                        onChange={this.handleUpdatePrice}
                      />
                    </GroupItem>
                  </GroupControl>
                </Col>
              </Row>

              <Row>
                <Col xs={6}>
                  <Field
                    valueFormat
                    label="Preço total mensal"
                    name="price"
                    component={Input}
                  />
                </Col>
                <Col xs={6}>
                  <GroupControl label="Preço da Comissão">
                    <GroupItem label="R$" size="small" />
                    <GroupItem>
                      <Field valueFormat name="commission" component={Input} />
                    </GroupItem>
                  </GroupControl>
                </Col>
              </Row>
              <Row className="h-margin-bottom--15">
                <Field
                  xs={6}
                  label="Outras Cobranças"
                  name="taxes_note"
                  component={Input}
                />

                <Col xs={6}>
                  <GroupControl label="Preço Outras Cobranças">
                    <GroupItem label="R$" size="small" />
                    <GroupItem>
                      <Field
                        valueFormat
                        name="taxes_price"
                        component={Input}
                        disabled={!taxes_note}
                        onChange={this.handleUpdatePrice}
                      />
                    </GroupItem>
                  </GroupControl>
                </Col>
              </Row>
            </Col>
            <Col xs={6} style={styleCols}>
              <ListOptions style={{ margin: '-20px -20px -20px -13px' }}>
                <ListOptions.item>
                  <Row className="h-margin-bottom--15">
                    <Field
                      type="simple"
                      xs={12}
                      label="Observação"
                      name="note"
                      placeholder="Digite a observação"
                      component={Textarea}
                      height={82}
                    />
                  </Row>
                  <Row>
                    <Field
                      type="simple"
                      xs={12}
                      label="Observação sobre a remuneração ao proprietário"
                      name="owner_remuneration_note"
                      placeholder="Digite a observação"
                      component={Textarea}
                      height={82}
                    />
                  </Row>
                </ListOptions.item>
                <ListOptions.item
                  title={
                    <>
                      Remover perfil existentes? <HelpProfile />
                    </>
                  }
                  renderOptions={() => (
                    <FieldBool name="remove_search_profile" />
                  )}
                />
                <ListOptions.item
                  title={
                    <>
                      Remover outros negócios com esse cliente? <HelpDeals />
                    </>
                  }
                  renderOptions={() => <FieldBool name="remove_deals" />}
                />
                <ListOptions.item>
                  <div style={{ margin: '-20px' }}>
                    <PropertyItem canOpenProperty={false} property={property} />
                  </div>
                </ListOptions.item>
              </ListOptions>
            </Col>
          </Row>
          <ModalFooter withBorder>
            <Button onClick={handleClose} color="white" colorText="secondary">
              Cancelar
            </Button>
            <div className="h-flex__cell--grow" />
            <Button
              type="submit"
              color="success"
              disabled={pristine || submitting}
            >
              {isEditting ? 'Editar' : 'Finalizar'}
            </Button>
          </ModalFooter>
        </form>
      </ModalTemplate>
    );
  }
}

FormRent.defaultProps = defaultProps;
FormRent.propTypes = propTypes;

const selector = formValueSelector('FormRent');

const Form = reduxForm({
  form: 'FormRent',
  enableReinitialize: true,
  validate,
})(FormRent);

const mapStateToProps = (state) => ({
  has_guarantee: selector(state, 'has_guarantee'),
  owner_remuneration_note: selector(state, 'owner_remuneration_note'),
  is_included_condo: selector(state, 'is_included_condo'),
  entry_at: selector(state, 'entry_at'),
  departure_at: selector(state, 'departure_at'),
  taxes_note: selector(state, 'taxes_note'),
  taxes_price: selector(state, 'taxes_price'),
  condominium_price: selector(state, 'condominium_price'),
  territorial_tax_type: selector(state, 'territorial_tax_type'),
  territorial_tax_price: selector(state, 'territorial_tax_price'),
});

export default connect(mapStateToProps)(Form);
