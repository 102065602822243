import React from 'react';
// Components
import Box from 'components/Box';
import TextLoading from 'components/TextLoading';
// Services
import * as financialService from 'services/financial';
import { plural } from 'lib/text';

const Title = ({ children }) => (
  <h5 className="h-text-nowrap h-margin-bottom--5" style={{ fontWeight: 500 }}>
    {children}
  </h5>
);

const Text = ({ isLoading, children }) => (
  <h1>{isLoading ? <TextLoading width={90} height="1em" /> : children}</h1>
);

class InfosContainer extends React.Component {
  state = {
    data: {
      sum_credits: '',
      sum_debits: '',
      debit_daily: '',
      credit_until: '',
      balance: '',
    },
    isFetching: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ isFetching: true });

    try {
      const { data } = await financialService.getDashboardData();
      this.setState({ data });
    } finally {
      this.setState({ isFetching: false });
    }
  };

  get mailboxCount() {
    try {
      const {
        data: { mailboxes_count },
      } = this.state;

      if (mailboxes_count === 0) return 'Nenhuma conta de e-mail local';

      return plural(
        mailboxes_count.toString().padStart(2, '0'),
        'Email local',
        'Emails local'
      );
    } catch {
      return <TextLoading width={90} height="1em" />;
    }
  }

  get usersCount() {
    try {
      const {
        data: { users_count },
      } = this.state;

      if (users_count === 0) return 'Nenhuma usuário';

      return plural(
        users_count.toString().padStart(2, '0'),
        'Usuário',
        'Usuários'
      );
    } catch {
      return <TextLoading width={90} height="1em" />;
    }
  }

  render() {
    const {
      data: { credit_until, debit_daily, balance },
      isFetching,
    } = this.state;

    return (
      <Box withBorder spacing={20}>
        <Box.item>
          <Title>Conteúdo da cobrança</Title>
          <Title>
            <strong>{this.usersCount}</strong>
          </Title>
          <Title>
            <strong>{this.mailboxCount}</strong>
          </Title>
        </Box.item>
        <Box.item>
          <Title>Débito diário</Title>
          <Text isLoading={isFetching}>{debit_daily}</Text>
        </Box.item>
        <Box.item>
          <Title>Saldo Atual</Title>
          <Text isLoading={isFetching}>{balance}</Text>
        </Box.item>
        <Box.item>
          <Title>Duração do crédito</Title>
          <Text isLoading={isFetching}>{credit_until}</Text>
        </Box.item>
      </Box>
    );
  }
}

export default InfosContainer;
