import React from 'react';
import Modal from 'react-modal';
// Components
import { ModalTemplate } from '../../../components/Modal';
import Form from './components/Form';
// Services
import * as rdService from 'services/settings/crm-integration';
import { useRequest } from 'hooks/useRequest';

export default function ModalRdConfig({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
}) {
  const { data } = useRequest({
    request: rdService.get,
  });

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Selecionar Categorias" handleClose={handleClose}>
        <Form
          defaultValues={data}
          onSubmit={rdService.update}
          onSubmitSuccess={() => {
            handleClose();
          }}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}
