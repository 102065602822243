import styled from 'styled-components';
import Image from 'components/Image';

export const Wrapper = styled.div`
  display: flex;
`;

export const Img = styled(Image)`
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 5px;
`;
