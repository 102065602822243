import { useCallback } from 'react';
import { Field } from 'redux-form';
import Alert from 'react-s-alert';

// Components
import { AsyncSelect } from 'components/Form';

// Context
import { useLocation } from 'containers/LocationAsyncFields/context';

// Hooks
import useFormValue from 'hooks/useFormValue';

// Services
import * as locationService from 'services/location';

const Neighborhoods = ({ label = 'Bairro', ...props }) => {
  const { neighborhoodName, cityName, setNeighborhoods } = useLocation();
  const city = useFormValue(cityName);

  const getOptionByValue = useCallback(async id => {
    setNeighborhoods(prevState => ({ data: prevState.data, isFetching: true }));
    const res = await locationService.getNeighborhoodByIdOnly(id);
    setNeighborhoods({ data: [res.data], isFetching: false });
    return res;
  }, []);

  const fetchNeighborhoods = useCallback(
    async ({ filter, offset = 1, limit = 10 }) => {
      try {
        return locationService.getNeighborhoods({
          city_id: city,
          filter,
          offset,
          limit
        });
      } catch {
        Alert.success('Problema ao buscar os bairros');
      }
    },
    [city]
  );

  const loadOptions = useCallback(
    async (search, loadedOptions, { page }) => {
      const response = await fetchNeighborhoods({
        filter: { name: search },
        offset: page
      });
      const pagination = response?.meta?.pagination;

      setNeighborhoods({
        data: [...loadedOptions, ...response.data]
      });

      return {
        options: response.data,
        hasMore: pagination?.current_page < pagination?.total_pages,
        additional: {
          page: page + 1
        }
      };
    },
    [city]
  );

  return (
    <Field
      key={`neighborhoods-${city}`}
      disabled={!city}
      label={label}
      name={neighborhoodName}
      loadOptions={loadOptions}
      component={AsyncSelect}
      labelKey="name"
      valueKey="id"
      getOptionByValue={getOptionByValue}
      additional={{
        page: 1
      }}
      {...props}
    />
  );
};

export default Neighborhoods;
