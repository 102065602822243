import Head from "./components/Head";
import Item from "./components/Item";
import { TableStriped } from "./styles";

function TableUsers({ data, pagination, handleChangePage }) {
  return (
    <TableStriped
      data={data}
      renderTitle={() => <Head />}
      renderItem={(user) => <Item key={user.id} user={user} />}
      pagination={{
        forcePage: pagination?.current_page - 1,
        pageCount: pagination?.total_pages,
        pageRangeDisplayed: 10,
        marginPagesDisplayed: 2,
        onPageChange: handleChangePage,
      }}
      noData={{
        title: 'Você ainda não possui nenhuma recomendação',
        text: '',
      }}
    />
  );
}

export default TableUsers;
