import styled from 'styled-components';

export const Form = styled.form`
  background: #edeef2;
  padding: 5px 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-size: 14px;
`;

export const Actions = styled.div``;
