import React, { useState } from 'react';
import Modal from 'react-modal';
import api from 'services';
import { ModalTemplate, ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { openModalPayment } from 'modules/modal';

const ModalInstallmentOpen = ({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const clearInstallments = async () => {
    setIsLoading(true);

    // Remove todos os créditos em aberto
    await api.delete('financial/credits/open');

    dispatch(openModalPayment());
  };

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={isLoading}
        title="Parcelamento Aberto"
        handleClose={handleClose}
      >
        <p>
          <strong>Atenção!</strong> Atualmente você possui um parcelamento em
          aberto. É necessário remover as parcelas em aberto para fazer uma nova
          contratação.
        </p>

        <ModalFooter>
          <Button color="white" onClick={handleClose}>
            Voltar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button
            disabled={isLoading}
            color="danger"
            onClick={clearInstallments}
          >
            Limpar parcelas
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
};

export default ModalInstallmentOpen;
