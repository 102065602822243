import FixedBar from 'components/FixedBar';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
// import { Wrapper } from './styles';

function ClearSelecteds() {
  const { clearSelectedProperties } = usePortalOptions();

  return (
    <FixedBar.item>
      <FixedBar.link tagName="button" onClick={clearSelectedProperties}>
        Limpar selecionados
      </FixedBar.link>
    </FixedBar.item>
  );
}

export default ClearSelecteds;
