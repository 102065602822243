// import styles from './styles'

import Button from 'components/Button';
import Dropdown, { Item, List } from 'components/Dropdown';
import { useModalPosting } from '../../hooks/useModalPosting';

export function AddPosting({ initialValues, onSuccess }) {
  const { handleOpenModalPosting } = useModalPosting();

  return (
    <Dropdown>
      <Button type="button" color="secondary" size="small">
        Adicionar
      </Button>
      <List>
        <Item>
          <button
            type="button"
            className="h-color--success"
            onClick={() => {
              handleOpenModalPosting({
                type: 1,
                posting: initialValues,
                onSuccess,
              });
            }}
          >
            Receita
          </button>
        </Item>
        <Item>
          <button
            type="button"
            className="h-color--danger"
            onClick={() => {
              handleOpenModalPosting({
                type: 2,
                posting: initialValues,
                onSuccess,
              });
            }}
          >
            Despesa
          </button>
        </Item>
      </List>
    </Dropdown>
  );
}
