import React, { Component } from 'react';
import Tooltip from 'react-tooltip';
import Alert from 'react-s-alert';
import PropTypes from 'prop-types';
import {
  MdCancel,
  MdCheckCircle,
  MdEdit,
  MdEmail,
  MdHome,
  MdKeyboardArrowUp,
  MdLink,
  MdMap,
  MdPerson,
  MdPrint,
  MdPublic,
  MdShare,
  MdSwapHoriz,
} from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
// Components
import Dropdown, { Item, List } from 'components/Dropdown';
import FixedBar from 'components/FixedBar';
import IconBall from 'components/IconBall';
import TableProperties from '../TableProperties';
// Services
import * as propertiesService from 'services/properties';
// Helpers
import { plural } from 'lib/text';
import { whatsappSendUrl } from 'lib/share-helpers';
import { BarItemSelecteds, UncheckAll } from './styles';

const propTypes = {};
const defaultProps = {};

const LinkWithIcon = ({ icon, iconColor, title, text, onClick }) => (
  <Item>
    <button onClick={onClick}>
      <div className="h-flex h-flex--center-center">
        <IconBall type="rounded" size="large" color={iconColor}>
          {icon()}
        </IconBall>
        <div className="h-margin-left--15">
          <h5>{title}</h5>
          <p>{text}</p>
        </div>
      </div>
    </button>
  </Item>
);

class FixedBarProperties extends Component {
  static propTypes = {
    properties: PropTypes.arrayOf(PropTypes.string),
    handleUncheckAll: PropTypes.func,
    handleClickRemoveSelected: PropTypes.func,
  };

  static defaultProps = {
    properties: null,
    handleUncheckAll: null,
    handleClickRemoveSelected: null,
  };

  state = {
    currentProperties: [],
    isFetching: false,
  };

  fetchProperties = () => {
    // Pega os ids dos imóveis
    const propertiesIds = this.props.properties;

    // Fetching
    this.setState({ isFetching: true });

    if (!propertiesIds || propertiesIds.length === 0) {
      return new Promise((resolve) => resolve());
    }

    return propertiesService
      .getList({
        filter: { by_id: propertiesIds },
        include: 'neighborhood',
      })
      .then(({ data: properties }) => {
        this.setState({
          currentProperties: properties,
          isFetching: false,
        });
      });
  };

  openItineraryWhatsapp = async () => {
    const { cellphone } = localStorage.getItem('current-user');
    const url = await this.makeItinerary();
    window.open(whatsappSendUrl(cellphone, url), '_blank', null, false);
  };

  openItinerary = async () => {
    const { openModalItinerary, properties } = this.props;
    openModalItinerary({ propertiesIds: properties });
  };

  makeItinerary = async () => {
    const { properties } = this.props;

    try {
      return await propertiesService.propertiesItinerary(properties);
    } catch (err) {
      if (err.code === 1) {
        Alert.success(
          'Você precisa permitir que a gente acesse a sua localização!'
        );
      }
    }
  };

  componentDidMount() {
    this.fetchProperties();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Atualiza a lista de imóveis quando há mudança na lista de selecionados
    if (prevProps.properties !== this.props.properties) {
      this.fetchProperties();
      Tooltip.rebuild();
    }
  }

  /**
   * Retorna a quantidade de imóveis
   * @returns {number}
   */
  get propertiesLength() {
    try {
      return this.props.properties.length;
    } catch {
      return 0;
    }
  }

  /**
   * Retorna se a barra pode ou não estar visível
   * @returns {boolean}
   */
  get isVisible() {
    try {
      return this.propertiesLength > 0;
    } catch {
      return false;
    }
  }

  /**
   * Retorna o texto que fica no contador de propriedades
   * @returns {string|string}
   */
  get countProperties() {
    return plural(
      this.propertiesLength,
      'imóvel selecionado',
      'imóveis selecionados'
    );
  }

  renderUncheckAll = (close) => {
    const { handleUncheckAll } = this.props;
    const length = this.propertiesLength;

    if (!handleUncheckAll && length <= 0) return null;
    return (
      <UncheckAll
        onClick={() => {
          close();
          handleUncheckAll();
        }}
      >
        <MdCancel /> Remover Todos
      </UncheckAll>
    );
  };

  get renderSelecteds() {
    const { currentProperties } = this.state;
    const { handleClickRemoveSelected } = this.props;

    return (
      <Dropdown position="center-top" isDropHover>
        {({ close }) => (
          <>
            <BarItemSelecteds>
              <span>
                <MdCheckCircle /> {this.countProperties}
              </span>
            </BarItemSelecteds>
            <List>
              <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                <TableProperties
                  properties={currentProperties}
                  handleClickRemoveSelected={handleClickRemoveSelected}
                />
              </div>
              {this.renderUncheckAll(close)}
            </List>
          </>
        )}
      </Dropdown>
    );
  }

  render() {
    const { properties, handleClickSendProperties, handleClickCompare } =
      this.props;
    const length = properties?.length;

    return (
      <FixedBar
        color="primary"
        noContainer
        isVisible={this.isVisible}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        style={{ left: '250px', background: '#42587E', zIndex: 1500 }}
      >
        {this.renderSelecteds}
        <FixedBar.spacer />
        <FixedBar.item>
          <Dropdown
            isDropHover
            position="center-top"
            className="Dropdown--header"
          >
            <FixedBar.link tagName="button">
              <MdShare /> Compartilhar
            </FixedBar.link>
            <List>
              <LinkWithIcon
                title="Compartilhar pelo Whatsapp"
                icon={FaWhatsapp}
                iconColor="success"
                onClick={handleClickSendProperties('share')}
              />
              <LinkWithIcon
                title="Compartilhar por e-mail"
                icon={MdEmail}
                iconColor="secondary"
                onClick={handleClickSendProperties('email')}
              />
              <LinkWithIcon
                icon={MdLink}
                iconColor="primary"
                title="Gerar Link para o seu site"
                text="Abre os imóveis selecionados em seu site"
                onClick={handleClickSendProperties('generate-link')}
              />
            </List>
          </Dropdown>
        </FixedBar.item>
        <FixedBar.item>
          <FixedBar.link
            tagName="button"
            onClick={handleClickSendProperties(
              'add-person',
              'Adicionar a um cliente'
            )}
          >
            <MdPerson /> Adicionar ao CRM
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.item
          data-tip={length < 2 ? 'Adicione mais um imóvel para comparar.' : ''}
        >
          <FixedBar.link
            tagName="button"
            onClick={length >= 2 && handleClickCompare}
          >
            <MdSwapHoriz /> Comparar
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.item>
          <FixedBar.link
            data-tip={
              length < 2 ? 'Adicione mais um imóvel para criar um roteiro.' : ''
            }
            onClick={length >= 2 && this.openItinerary}
            tagName="button"
          >
            <MdMap /> Roteiro de visita
          </FixedBar.link>
        </FixedBar.item>
        <FixedBar.item>
          <Dropdown
            isDropHover
            position="center-top"
            className="Dropdown--header"
          >
            <FixedBar.link tagName="button">
              Opções <MdKeyboardArrowUp />
            </FixedBar.link>
            <List>
              <LinkWithIcon
                title="Alterar Preço"
                text="Altere o preço de vários imóveis"
                icon={MdEdit}
                iconColor="caution"
                onClick={this.props.handleToggleEditting}
              />
              <LinkWithIcon
                title="Inativar"
                text="Inative vários imóveis"
                icon={MdHome}
                iconColor="danger"
                onClick={this.props.handleRemoveAll}
              />
              <LinkWithIcon
                title="Publicar"
                text="Publicar vários imóveis"
                icon={MdPublic}
                iconColor="primary"
                onClick={this.props.handleTogglePublish}
              />
              <LinkWithIcon
                title="Imprimir ficha de visita"
                icon={MdPrint}
                iconColor="secondary"
                onClick={this.props.handlePrintVisit}
              />
            </List>
          </Dropdown>
        </FixedBar.item>
        <FixedBar.spacer />
      </FixedBar>
    );
  }
}

FixedBarProperties.propTypes = propTypes;
FixedBarProperties.defaultProps = defaultProps;

export default FixedBarProperties;
