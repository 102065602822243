import React, { useCallback, useEffect, useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';

// Components
import MainTitle from 'components/MainTitle';
import { Input } from 'components/Form';
import SeeExample from '../../components/SeeExample';
import FormFixedBar from '../../components/FormFixedBar';
import Content from '../../components/Content';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';

// Services
import * as imagesService from 'services/buildings/images';
import { useRequest } from 'hooks/useRequest';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { compose } from 'redux';
import ListOptions from 'components/ListOptions';
import UploadButton from 'components/UploadButton';
import Button from 'components/Button';
import { uploadFiles } from 'lib/reducer-helpers';
import { hideLoading, showLoading } from 'modules/loading';
import { useDispatch } from 'react-redux';
import Reception from 'pages/EnterpriseStore/components/Reception';
import image from './reception-plans.png';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import SortablePropertyGallery from 'components/SortablePropertyGallery';
import { arrayMove } from 'react-sortable-hoc';

const Images = ({ handleSubmit, initialize }) => {
  const dispatch = useDispatch();
  const { enterpriseId } = useEnterprise();
  const { data, setData, isFetching, fetchData } = useRequest({
    request: imagesService.getPlans,
    params: enterpriseId,
  });

  useEffect(() => {
    initialize(data);
  }, [data]);

  const hasImages = useMemo(() => {
    return data?.plans?.length > 0;
  }, [data?.plans]);

  const onDropAccepted = useCallback(
    (files) => {
      const length = data?.plans?.length
        ? parseInt(data?.plans?.length, 10)
        : 0;

      return uploadFiles({
        request: imagesService.addImagePlans,
        params: (file, index) => ({
          image: file,
          name: file.name,
          buildingId: enterpriseId,
          order: length + index + 1,
        }),
        files: files,
        onUploadStart: () => dispatch(showLoading()),
        onUploadEnd: async () => {
          fetchData();
          dispatch(hideLoading());
        },
      });
    },
    [fetchData, data]
  );

  const handleSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setData((items) => {
      const newItems = arrayMove(items.plans, oldIndex, newIndex);

      // pega a imagem para ser modificada
      const image = newItems.find(
        (image, index) => [newIndex].indexOf(index) >= 0
      );

      imagesService.move({
        buildingId: enterpriseId,
        imageId: image.id,
        order: newIndex,
      });

      return { ...items, plans: newItems };
    });
  }, []);

  const handleRemoveImage = (imageId) => () => {
    dispatch(
      openConfirmation({
        title: 'Remover Imagem',
        text: 'Deseja realmente excluir essa imagem?',
        request: () =>
          imagesService.remove({
            imageId: imageId.id,
            buildingId: enterpriseId,
          }),
        onSuccess: () => {
          fetchData();
        },
      })
    );
  };

  const handleEditCaption = async (values) => {
    // console.log(values);
    // console.log(values);
    await imagesService.updateImage(values);
    fetchData();
  };

  if (!hasImages) {
    return (
      <Reception
        image={image}
        title="Plantas"
        text="Todas as plantas do empreendimento. As plantas das unidades estarão no detalhamento de cada unidade."
      >
        <UploadButton
          onDropAccepted={onDropAccepted}
          style={{ display: 'inline-block' }}
        >
          <Button color="success">Adicionar plantas</Button>
        </UploadButton>
        <p className="h-margin-top--10">
          <em>Tamanho recomendado: 1920x1080px</em>
        </p>
        <p className="h-margin-top--5">
          <em>Tipo de arquivo: JPG, PNG, JPEG</em>
        </p>
      </Reception>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle title="Plantas" text="Todas as plantas do empreendimento.">
        <UploadButton
          onDropAccepted={onDropAccepted}
          style={{ display: 'inline-block' }}
        >
          <Button color="success">Adicionar mais imagens</Button>
        </UploadButton>
        <SeeExample />
      </MainTitle>

      <Content padding isFetching={isFetching}>
        <ListOptions>
          <ListOptions.item>
            <Field
              autoFocus
              label="Título da área"
              name="plans_title"
              component={Input}
            />
          </ListOptions.item>
          <ListOptions.item>
            <p>Recomendado: 1920x1080px</p>
            <SortablePropertyGallery
              data={data.plans}
              captionName="title"
              onRemoveImage={handleRemoveImage}
              onSortEnd={handleSortEnd}
              onEditCaption={handleEditCaption}
              // onEditCaptionSuccess={handleEditCaptionSuccess}
            />
          </ListOptions.item>
        </ListOptions>
      </Content>

      <FormFixedBar />
    </form>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
    onSubmit: imagesService.updatePlans,
  }),
  withFormConfirmation()
)(Images);
