import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { PAYMENT_METHODS } from 'constants/constants';
import api from 'services/index';

export const usePix = ({ item, installments, quantity }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [code, setCode] = useState(null);
  const [price, setPrice] = useState('R$ ---');
  const [generatedCreditId, setGeneratedCreditId] = useState(null);

  /**
   * Contrata com pix
   * @return {Promise<void>}
   */
  const contract = async () => {
    // if (process.env.NODE_ENV === 'development') {
    //   setCode(pixMock.data.pix_qr_code);
    //   setGeneratedCreditId(pixMock.data.credit_id);
    //   return;
    // }

    try {
      const {
        data: { price, pix_qr_code, credit_id: creditId },
      } = await api.create('sales/contracts/change', {
        item,
        receiving_method: PAYMENT_METHODS.PIX,
        installments: 1,
        quantity,
        is_adding: true,
      });

      setGeneratedCreditId(creditId);
      setCode(pix_qr_code);
      setPrice(price);
    } catch (err) {
      console.error('err', err);
    }
  };

  useEffect(() => {
    if (code) {
      QRCode.toDataURL(code, { width: 140, margin: 0 }, function (_, url) {
        setImageUrl(url);
      });
    }
  }, [code]);

  return { price, code, imageUrl, contract, creditId: generatedCreditId };
};
