import { useRequest } from 'hooks/useRequest';
import * as bookingService from 'services/propertiesBooking';

export const usePropertiesBooked = (_params) => {
  const { data, meta, fetchData, isFetching } = useRequest({
    request: (params) =>
      bookingService.getListBooked({
        include: 'person,booking_user',
        filter: {
          ..._params?.filter,
          ...params?.filter,
        },
        limit: params?.limit || 20,
        offset: params?.offset || 1,
      }),
    initialFetch: false,
  });

  return { data, meta, fetchData, isFetching };
};
