import React, { createRef, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import { Input } from 'components/Form';

const FormUpdateDocument = ({ handleSubmit }) => {
  const [isFocused, setIsFocused] = useState(false);
  const $input = createRef();

  useEffect(() => {
    if ($input.current && !isFocused) {
      $input.current.focus();
      $input.current.select();
      setIsFocused(true);
    }
  }, [$input, isFocused]);

  return (
    <form
      onSubmit={(e, ...rest) => {
        e.preventDefault();
        e.stopPropagation();
        return handleSubmit(...rest);
      }}
    >
      <Row>
        <Field refName={$input} xs={12} name="name" component={Input} />
        <Col xs={12}>
          <Button type="submit" color="success" block>
            Salvar
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default reduxForm({
  form: 'FormUpdateDocument',
  enableReinitialize: true,
})(FormUpdateDocument);
