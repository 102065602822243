import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useSearch from 'hooks/useSearch';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import FormOrulo from './components/FormOrulo';
import * as oruloService from 'services/orulo';
import Loading from 'components/Loading';

function Orulo() {
  const history = useHistory();
  const search = useSearch(true);
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  async function getToken() {
    try {
      // se tiver o code na url
      if (search?.code) {
        setIsFetching(true);
        const { data } = await oruloService.login(search.code);
        setData(data);
        return data;
      }

      const { data } = await oruloService.getToken();
      setData(data);
      return data;
    } catch {
      history.goBack();
    } finally {
      setIsFetching(false);
    }
  }

  function handleSubmit(values) {
    return oruloService.update(values);
  }

  function onSubmitSuccess() {
    history.push('/integrations');
  }

  useEffect(() => {
    // Verifica se está importando se estiver redireciona para pagina de integrações
    if (data?.is_importing) {
      history.push('/integrations');
    }
  }, [data]);

  useEffect(() => {
    getToken();
  }, []);

  return (
    <Wrapper.container>
      <MainTitle
        title="Configure sua integração com o Órulo"
        text="Defina os tipos de imóveis que você deseja importar do Órulo."
      />
      {isFetching ? (
        <Loading isVisible />
      ) : (
        <FormOrulo
          initialValues={data}
          onSubmit={handleSubmit}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
    </Wrapper.container>
  );
}

Orulo.propTypes = {};
Orulo.defaultProps = {};

export default Orulo;
