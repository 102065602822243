import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Field } from 'redux-form';
import { Input } from 'components/Form';
import { TaxesRent } from 'containers/ModalRoot/ModalPropertySearch/components/TaxesRent';

class PriceFieldRent extends React.Component {
  state = {
    isFocusMin: false,
    isFocusMax: false,
  };

  /**
   * Pega o tamanho da coluna input de valor máximo
   * @return {number}
   */
  get maxSize() {
    const { isFocusMin, isFocusMax } = this.state;

    if (isFocusMin) return 4;
    if (!isFocusMax) return 6;

    return 8;
  }

  /**
   * Pega o tamanho da coluna input de valor mínimo
   * @return {number}
   */
  get minSize() {
    const { isFocusMin, isFocusMax } = this.state;

    if (isFocusMax) return 4;
    if (!isFocusMin) return 6;

    return 8;
  }

  render() {
    const { typeSaleOrRent } = this.props;
    // const shouldRenderTaxesRent = typeSaleOrRent === 2;

    return (
      <Row className="row--5">
        <Col xs={12}>
          <label style={{ 
            display: 'flex', 
            justifyContent: 'flex-start', 
            alignItems: 'flex-start', 
            gap: '55px', 
            paddingBottom: '6px' 
          }}>
          Preço {typeSaleOrRent === 2 && (<TaxesRent onChange={this.props.onBlur} />)}
          </label>
        </Col>
          <Field
            xs={this.minSize}
            valueFormat
            name="price.greater_equals"
            placeholder="R$0,00"
            component={Input}
            onFocus={() => {
              this.setState({ isFocusMin: true });
            }}
            onBlur={(e) => {
             if (this.props.onBlur) this.props.onBlur(e);
              this.setState({ isFocusMin: false });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (this.props.onBlur) this.props.onBlur(e);
              }
            }}
          />
          <Field
            xs={this.maxSize}
            valueFormat
            name="price.lower_equals"
            placeholder="R$0,00"
            component={Input}
            onFocus={() => {
              this.setState({ isFocusMax: true });
            }}
            onBlur={(e) => {
              if (this.props.onBlur) this.props.onBlur(e);
              this.setState({ isFocusMax: false });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (this.props.onBlur) this.props.onBlur(e);
              }
            }}
          />
      </Row>
    );
  }
}

export default PriceFieldRent;
