import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FieldFile } from 'components/Form';
import Button from 'components/Button';

const defaultProps = {
  value: null,
};

const propTypes = {
  value: PropTypes.string,
};

const FieldDocument = () => (
  <Field
    height={173}
    name="image_document"
    buttonText="Enviar Documento"
    text={<>Envie uma foto do seu RG ou CNH.</>}
    fileUrlField="document_name"
    component={FieldFile}
    renderButtonChangeImage={() => (
      <Button type="button" color="white" className="DropImage__button">
        Trocar arquivo
      </Button>
    )}
  />
);

FieldDocument.defaultProps = defaultProps;
FieldDocument.propTypes = propTypes;

export default FieldDocument;
