import { useCallback } from 'react';
import Modal from 'react-modal';
// Services
import * as dwvService from 'services/integrations/dwv';
// Common Components
import { ModalTemplate } from 'components/Modal';
import FormConnectDwv from './components/FormConnectDwv';

function ModalDwv({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onSubmitFail,
  onSubmitSuccess,
}) {
  const handleSubmit = useCallback((values) => {
    return dwvService.connect(values);
  }, []);

  const handleSuccess = useCallback(() => {
    if (onSubmitSuccess) onSubmitSuccess();

    // Fecha a modal quando da certo o request
    handleClose();
  }, []);

  const handleFail = useCallback(() => {
    if (onSubmitFail) onSubmitFail();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Conectar-se ao DWV" handleClose={handleClose}>
        <FormConnectDwv
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSuccess}
          onSubmitFail={handleFail}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalDwv;
