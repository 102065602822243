import React from 'react';
import Container from 'components/Container';
import Menu from './components/Menu';
import { GalleryProvider } from './context';
import Gallery from './components/Gallery';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router';
// import { Container } from './styles';

const PropertyGallery = ({ propertyId }) => {
  let route = useRouteMatch();

  return (
    <GalleryProvider propertyId={propertyId}>
      <Container
        style={{ paddingTop: '10px', marginTop: '20px', marginBottom: '90px' }}
      >
        <Menu />
        <Switch>
          <Route
            path={`${route.path}/gallery/:galleryId`}
            component={Gallery}
          />
          <Redirect to={`${route.url}/gallery/1`} />
        </Switch>
      </Container>
    </GalleryProvider>
  );
};

export default PropertyGallery;
