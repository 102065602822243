import React, { useCallback, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
// Components
// Container
import { CityField, NeighborhoodField, StateField } from 'containers/LocationFields';
// Modules
import { Actions as LocationActions, Selectors as LocationSelector } from 'containers/LocationFields/module';
import useFormValue from 'hooks/useFormValue';
import useReduxForm from 'hooks/useReduxForm';
import ZoneField from 'containers/LocationFields/ZoneField';

function LocationField() {
  const { form } = useReduxForm();
  const states = useSelector(LocationSelector.getData(form, 'by_state_id'));

  const dispatch = useDispatch();
  const countryId = useFormValue('by_country_id');
  const stateId = useFormValue('by_state_id');
  const cityId = useFormValue('by_city_id');

  const state = useMemo(() => {
    return states?.find(state => state.id === stateId);
  }, [states, stateId]);

  const reset = useCallback(() => {
    dispatch(change(form, 'by_state_id', null));
    dispatch(change(form, 'by_city_id', null));
    dispatch(change(form, 'neighborhood_id', null));
    dispatch(change(form, 'zone_type', null));
    dispatch(LocationActions.clear(form, ['by_city_id', 'neighborhood_id']));
  }, [dispatch, change]);

  const hasFields = state?.acronym;

  return (
    <>
      <label className="FormLabel">Localização</label>
      {hasFields ? (
        <div className="h-flex h-margin-bottom--5">
          <span style={{ color: 'rgba(62, 88, 124, 0.54)' }}>
            Brasil - {state?.acronym}
          </span>
          <span className="h-flex__cell--grow" />
          <button type="button" className="h-link" onClick={reset}>
            Alterar
          </button>
        </div>
      ) : (
        <>
          {/* <CountryField
            canUnregister={false}
            name="by_country_id"
            neighborhoodName="neighborhood_id"
            cityName="by_city_id"
            stateName="by_state_id"
          /> */}
          <StateField
            canUnregister={false}
            countryId={countryId}
            name="by_state_id"
            neighborhoodName="neighborhood_id"
            cityName="by_city_id"
            noCountry
          />
        </>
      )}
      <CityField
        canUnregister={false}
        stateId={stateId}
        name="by_city_id"
        neighborhoodName="neighborhood_id"
      />
      <NeighborhoodField multi={true} cityId={cityId} name="neighborhood_id" />
      <ZoneField cityId={cityId} labelKey="name" valueKey="id" />
    </>
  );
}

export default connect()(LocationField);
