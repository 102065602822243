import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import { MdHelp } from 'react-icons/md';
// Components
import { InputDate, FieldBool, Input, Textarea } from 'components/Form';
import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import ListOptions from 'components/ListOptions';
// Containers
import FieldPerson from 'containers/FieldPerson';
import FieldSelectUser from 'containers/FieldSelectUser';
// Validate
import validate from './validate';
import PropertyItem from '../../../../../components/PropertyItem';

const defaultProps = {
  handleSubmit: null,
  handleClose: null,
};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const HelpProfile = () => (
  <MdHelp
    data-effect="solid"
    data-tip={`Você não será mais avisado para<br/> entrar em contato com esse <br/> cliente ao cadastrar um imóvel.`}
    style={{ display: 'inline-block' }}
    className="h-color--secondary"
  />
);
const HelpDeals = () => (
  <MdHelp
    data-effect="solid"
    data-tip={`Remover da jornarda de<br/> compra os outros negócios<br/> envolvendo esse cliente.`}
    style={{ display: 'inline-block' }}
    className="h-color--secondary"
  />
);

const FormDealGain = ({
  property,
  title,
  handleClose,
  handleSubmit,
  handleChangeRealtor,
  onCreatePerson,
}) => (
  <ModalTemplate title={title} handleClose={handleClose}>
    <form onSubmit={handleSubmit}>
      <Row className="row--divider" style={{ marginTop: '-20px' }}>
        <Col xs={6} style={{ marginTop: '-20px', paddingTop: '20px' }}>
          <ListOptions
            style={{
              width: 'calc(100% + 34px)',
              marginLeft: '-19px',
            }}
          >
            <ListOptions.item>
              <Row className="h-margin-bottom--15">
                <FieldPerson
                  creatable
                  required
                  xs={6}
                  name="people_id"
                  onCreatePerson={onCreatePerson}
                />
                <Field
                  required
                  xs={6}
                  valueFormat
                  label="Preço do Negócio"
                  name="price"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 5 && floatValue <= 10000;
                  }}
                  component={Input}
                />
              </Row>
              <Row className="h-margin-bottom--15">
                <FieldSelectUser
                  xs={6}
                  label="Corretor"
                  name="user_id"
                  onChange={handleChangeRealtor}
                />
                <Field
                  required
                  valueFormat
                  xs={6}
                  label="Valor da Comissão"
                  name="commission"
                  component={Input}
                />
              </Row>
              <Row>
                <Field
                  required
                  valueFormat
                  xs={6}
                  label="Comissão da imobiliária"
                  name="real_estate_commission"
                  component={Input}
                />
                <Field
                  required
                  xs={6}
                  label="Data do Negócio"
                  name="earned_at"
                  component={InputDate}
                />
              </Row>
            </ListOptions.item>
            <ListOptions.item
              title={
                <>
                  Remover perfis existentes? <HelpProfile />
                </>
              }
              renderOptions={() => <FieldBool name="remove_search_profile" />}
            />
            <ListOptions.item
              title={
                <>
                  Remover outros negócios com esse cliente? <HelpDeals />
                </>
              }
              renderOptions={() => <FieldBool name="remove_deals" />}
            />
          </ListOptions>
        </Col>
        <Col xs={6} style={{ paddingTop: '17px' }}>
          <Row>
            <Field
              type="simple"
              xs={12}
              label="Observação"
              name="note"
              component={Textarea}
              height={238}
            />
          </Row>
          <Row>
            <Col xs={12}>
              <h5>Imóvel negociado:</h5>
              <div style={{ marginLeft: '-15px' }}>
                <PropertyItem canOpenProperty={false} property={property} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalFooter withBorder>
        <Button color="white" colorText="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Finalizar
        </Button>
      </ModalFooter>
    </form>
  </ModalTemplate>
);

FormDealGain.defaultProps = defaultProps;
FormDealGain.propTypes = propTypes;

export default reduxForm({
  form: 'FormDealGain',
  validate,
  enableReinitialize: true,
})(FormDealGain);
