import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// HOC's
import withReception from 'HOC/withReception';
// Components
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
import Container from 'components/Container';
import FinancialTable from './components/FinancialTable';
import FinancialReception from './components/FinancialReception';
// Modules
import { openModalFinancial, openModalFinancialDelete, openModalIndexHistory } from 'modules/modal';
import {
  addIndex,
  deleteIndex,
  getIndexes,
  handleTogglePublic,
  indexesSelector,
  reverseDelete,
  updateIndex,
} from 'modules/financialIndex';
import { withPermission } from 'HOC/withPermission';

class Financial extends Component {
  static propTypes = {
    getIndexes: PropTypes.func.isRequired,
    openModalFinancial: PropTypes.func.isRequired,
    addIndex: PropTypes.func.isRequired,
    updateIndex: PropTypes.func.isRequired,
    openModalIndexHistory: PropTypes.func.isRequired,
    deleteIndex: PropTypes.func.isRequired,
    reverseDelete: PropTypes.func.isRequired,
    handleClickAction: PropTypes.func.isRequired
  };

  static defaultProps = {};

  componentDidMount() {
    this.props.getIndexes({ filter: { by_defaults: false } });
  }

  openModalInsert = () => {
    this.props.openModalFinancial(null, index => {
      this.props.addIndex(index);
    });
  };

  openModalUpdate = id => {
    this.props.openModalFinancial(id, index => {
      this.props.updateIndex(index);
    });
  };

  openModalIndexHistory = id => {
    this.props.openModalIndexHistory(id);
  };

  handleDeleteIndex = (id, name) => {
    this.props.deleteIndex(id, name, () => {
      this.props.reverseDelete(id);
    });
  };

  render() {
    const {
      onChangeVisibility,
      handleClickAction,
      reception,
      indexes
    } = this.props;

    return (
      <div>
        {reception.isVisible ? (
          <FinancialReception
            onChangeVisibility={onChangeVisibility}
            handleClickAction={handleClickAction}
          />
        ) : (
          <Wrapper.container>
            <MainTitle
              title="Índices financeiros"
              text="Cadastre os índices financeiros que serão utilizados no seu negócio."
            >
              <Button color="secondary" onClick={this.openModalInsert}>
                Adicionar Indicador
              </Button>
            </MainTitle>
            <Container padding>
              <FinancialTable
                indexes={indexes}
                handleOpenModal={this.openModalInsert}
                handleOpenModalUpdate={this.openModalUpdate}
                openModalIndexHistory={this.openModalIndexHistory}
                handleDeleteIndex={this.handleDeleteIndex}
                handleTogglePublic={this.props.handleTogglePublic}
              />
            </Container>
          </Wrapper.container>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  indexes: indexesSelector(state)
});

const mapDispatchToProps = {
  openModalFinancial,
  openModalIndexHistory,
  getIndexes,
  addIndex,
  updateIndex,
  deleteIndex,
  reverseDelete,
  handleTogglePublic,
  openModalFinancialDelete
};

export default compose(
  withPermission({
    rules: ['EDIT_FINANCIAL_INDEX'],
    isPage: true
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withReception('config/financial', true)
)(Financial);
