import React from 'react';
import classnames from 'classnames';

const Round = ({ size, color, className, children }) => (
  <div
    className={classnames('Round', 'h-color--white', className, {
      [`h-bg--${color}`]: color,
      [`Round--${size}`]: size
    })}
  >
    {children}
  </div>
);

export default Round;
