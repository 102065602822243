import React from 'react';
import { ColLeft, ColRight, Number, Text, Wrapper } from './styles';
import { ThemeProvider } from 'styled-components';

function Bar({ size, color, data }) {
  return (
    <ThemeProvider theme={{ background: color, size }}>
      <Wrapper>
        <ColLeft>
          <Text>{data.name}</Text>
        </ColLeft>
        <ColRight>
          <Number>{data.people_count}</Number>
          <Text>{data.price_sum}</Text>
        </ColRight>
      </Wrapper>
    </ThemeProvider>
  );
}

export default Bar;
