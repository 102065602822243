import styled, { css } from 'styled-components';
import { em } from 'polished';
import { HEIGHT, WIDTH } from '../../constants';
import theme from 'components/ModelsPost/theme';

export const Wrapper = styled.div`
  position: relative;
  width: ${em(WIDTH, 32)};
  height: ${em(HEIGHT, 32)};
  font-family: 'Montserrat', sans-serif;
  color: ${(p) => p.theme.mainColor};
  font-size: ${(p) => p.theme.fontSize};
`;

export const Image = styled.img`
  width: inherit;
  height: inherit;
  object-fit: cover;
`;

export const Box = styled.div`
  padding: ${em(30, 32)};
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${(p) => p.theme.backgroundColor};
  border-radius: ${em(6, 32)} 0 0 0;
  ${(p) =>
    p.theme.logoUrl &&
    css`
      margin-bottom: ${em(151, 32)};
    `}
`;

export const Title = styled.div`
  font-size: ${em(40, 32)};
  font-weight: 600;
  text-align: center;
`;

export const Price = styled.div`
  font-size: ${em(26, 32)};
  font-weight: 400;
  text-align: center;
`;

export const Text = styled.div`
  font-size: ${em(20, 32)};
  font-weight: 400;
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  margin-top: ${em(15, 32)};
`;

export const LogoWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #fff;
  height: ${em(151, 32)};
  display: grid;
  place-items: center;

  img {
    height: ${em(theme.logoSize, 32)};
    margin: 0 auto;
  }
`;
