import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { useState } from 'react';
import Modal from 'react-modal';
import image from './assets/image-whatsapp.png';
import { Label, WrapImage, WrapText, Wrapper } from './styles';

function ModalExtension({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
}) {
  const [notShowAgain, setNotShowAgain] = useState(false);

  const close = () => {
    handleClose();

    if (notShowAgain) {
      localStorage.setItem('@tecimob/chrome-extension', true);
    } else {
      sessionStorage.setItem('@tecimob/chrome-extension', true);
    }
  };

  const handleClickDownload = () => {
    close();
  };

  const handleChange = (e) => {
    const target = e.target;
    const value = target.checked;
    setNotShowAgain(value);
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Plugin de atendimento do WhatsApp"
        handleClose={close}
      >
        <Wrapper>
          <WrapText>
            <p>- Seu CRM dentro do seu WhatsApp Web</p>
            <p>
              Tenha as principais funções do seu CRM diretamente no seu WhatsApp
              Web. Atenda com mais qualidade e produtividade, podendo conhecer o
              seu cliente em um clique, compartilhar imóveis, conhecer o
              histórico e também seu perfil.
            </p>
            <p>- Mensagens prontas para envio</p>
            <p>
              Padronize as suas respostas utilizando modelos prontos que estão a
              sua disposição.
            </p>
          </WrapText>
          <WrapImage>
            <img src={image} alt="Imagem extensão" />
          </WrapImage>
        </Wrapper>
        <ModalFooter>
          <Button type="button" color="white" onClick={close}>
            Fechar
          </Button>
          <Label>
            <input
              type="checkbox"
              checked={notShowAgain}
              name="not_show_again"
              onChange={handleChange}
            />{' '}
            Não mostrar novamente
          </Label>
          <span className="h-flex__cell--grow" />
          <Button
            tagName="a"
            color="success"
            href="https://chrome.google.com/webstore/detail/tecimob/agmpfkakhlepfjdmfddjeophdnidpjhm?hl=pt-BR"
            onClick={handleClickDownload}
            target="_blank"
          >
            Download
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalExtension;
