import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';
// Common Components
import Button from 'components/Button';
import CardAction from 'components/CardAction';
import { ModalTemplate } from 'components/Modal';
// Modules
import { openModalMenuLink } from 'modules/modal';
// Assets
import coding from 'assets/img/receptions/coding.svg';
import link from 'assets/img/receptions/link.svg';

class ModalCreatePage extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title="Criar página a partir de"
          handleClose={handleClose}
        >
          <Row>
            <Col xs={6}>
              <CardAction
                to="/site/layout/style/script"
                title="Código"
                text="Crie uma página nova com códigos"
                image={coding}
                onClick={handleClose}
              >
                <Button type="button" color="success">
                  Selecionar
                </Button>
              </CardAction>
            </Col>
            <Col xs={6}>
              <CardAction
                image={link}
                title="Um Link"
                text="Cole um link do seu site ou de outra página."
                onClick={this.props.openModalMenuLink}
              >
                <Button type="button" color="success">
                  Selecionar
                </Button>
              </CardAction>
            </Col>
          </Row>
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = {
  openModalMenuLink
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreatePage);
