import React from 'react';
import { WrapperLink } from './styles';

function NavItem({ active, to, text, icon, className }) {
  return (
    <WrapperLink
      to={to}
      activeClassName="isActive"
      active={active}
      className={className}
    >
      {icon && icon()}
      <span>{text}</span>
    </WrapperLink>
  );
}

export default NavItem;
