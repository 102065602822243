import React, { createContext, useContext, useEffect, useState } from 'react';
import { Table } from './styles';
import Pagination from 'components/Pagination';

export { default as CollumHeader } from './components/CollumHeader';

export const TableSortContext = createContext({
  filter: '',
  direction: '',
  setFilter: () => null,
  setDirection: () => null,
});

const getSort = (filter, direction) => {
  if (direction === 'desc') {
    return `-${filter}`;
  }

  if (direction === 'asc') return filter;

  return null;
};

function TableSort({
  data,
  meta,
  renderHeader,
  renderItem,
  fetchData,
  ...props
}) {
  const [isFiltering, setIsFiltering] = useState(false);
  const [filter, setFilter] = useState(null);
  const [direction, setDirection] = useState(null);

  const onPageChange = ({ selected }) => {
    fetchData({
      sort: getSort(filter, direction),
      direction,
      offset: selected + 1,
    });
  };

  useEffect(() => {
    if (isFiltering && fetchData)
      fetchData({ sort: getSort(filter, direction), direction });
  }, [isFiltering, filter, direction]);

  return (
    <TableSortContext.Provider
      value={{
        filter,
        setFilter,
        direction,
        setDirection,
        setIsFiltering,
      }}
    >
      <Table {...props}>
        <thead>{renderHeader()}</thead>
        <tbody>{data.map(renderItem)}</tbody>
      </Table>
      {meta?.pagination && (
        <Pagination pagination={meta.pagination} onPageChange={onPageChange} />
      )}
    </TableSortContext.Provider>
  );
}

export const useTableSort = () => useContext(TableSortContext);

export default TableSort;
