import React from 'react';

const IconPix = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.5182 17.295C16.7353 17.295 15.9991 16.9891 15.4455 16.4339L12.4527 13.431C12.2426 13.2196 11.8763 13.2203 11.6663 13.431L8.66253 16.4449C8.1089 17 7.37265 17.3059 6.58975 17.3059H6L9.79042 21.1092C10.9742 22.2969 12.8936 22.2969 14.0774 21.1092L17.8788 17.295H17.5182Z"
        fill="#000"
      />
      <path
        d="M6.58967 6.69113C7.37258 6.69113 8.10884 6.99689 8.66238 7.55176L11.6662 10.5643C11.8825 10.7812 12.2358 10.7821 12.4527 10.564L15.4455 7.56267C15.9991 7.00781 16.7354 6.70214 17.5183 6.70214H17.8788L14.0774 2.89034C12.8936 1.70325 10.9742 1.70325 9.79038 2.89034L6 6.69113H6.58967Z"
        fill="#000"
      />
      <path
        d="M21.1101 9.77904L18.8076 7.46373C18.7569 7.48417 18.702 7.49693 18.6441 7.49693H17.5973C17.056 7.49693 16.5262 7.71762 16.1438 8.10241L13.144 11.1189C12.8634 11.4012 12.4946 11.5425 12.126 11.5425C11.7572 11.5425 11.3886 11.4012 11.1079 11.1192L8.09688 8.09144C7.7144 7.70664 7.1847 7.48596 6.64343 7.48596H5.35621C5.30136 7.48596 5.25 7.47291 5.20156 7.45456L2.88993 9.77904C1.70336 10.9721 1.70336 12.9066 2.88993 14.0999L5.20147 16.4243C5.24991 16.4059 5.30136 16.3929 5.35621 16.3929H6.64343C7.1847 16.3929 7.7144 16.1722 8.09688 15.7874L11.1076 12.7599C11.6518 12.2132 12.6004 12.2129 13.144 12.7602L16.1438 15.7763C16.5262 16.1612 17.056 16.3819 17.5973 16.3819H18.6441C18.7021 16.3819 18.7569 16.3946 18.8077 16.415L21.1101 14.0999C22.2966 12.9066 22.2966 10.9721 21.1101 9.77904Z"
        fill="#000"
      />
    </svg>
  );
};

export default IconPix;
