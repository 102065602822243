import React from 'react';
import Check from 'components/Check';
// import { Container } from './styles';

const TableItem = ({ data, handleToggle }) => {
  return (
    <tr role="button" onClick={handleToggle}>
      <td className="h-sticky--left">
        <Check checked={data.isSelected} className="h-margin-right--15" />{' '}
        {data.user}
      </td>
      {data?.groups?.map(({ type, total }) => (
        <td key={`${data.id}-${type}`}>{total}</td>
      ))}
      <td className="h-sticky--right">{data?.total}</td>
    </tr>
  );
};

export default TableItem;
