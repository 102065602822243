import React from 'react';
import classnames from 'classnames';
import { getPropertyStatus } from 'lib/property-helpers';
import { Wrapper, Divider } from './styles';

function Status({ property, separator = '|' }) {
  const status = getPropertyStatus(property);

  if (status?.[0]?.text === '' || !status) return null;

  return status?.map(({ color, text }, i) => (
    <Wrapper key={i + text}>
      <Divider>{` ${separator} `}</Divider>
      <span
        className={classnames({
          [`h-color--${color}`]: color,
        })}
      >
        {text}
      </span>
    </Wrapper>
  ));
}

export default Status;
