import React from 'react';
import { ModalTemplate } from 'components/Modal';
import Button from 'components/Button';

// import { Wrapper } from './styles';

function Error({ handleClose }) {
  return (
    <ModalTemplate title={`Erro ao buscar histórico`} handleClose={handleClose}>
      <div>Ocorreu algum problema ao buscar as informações</div>
      <Button color="white" className="h-margin-top--15" onClick={handleClose}>
        Fechar
      </Button>
    </ModalTemplate>
  );
}

export default Error;
