import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import ListOptions from 'components/ListOptions';

const subItemsStyle = ({ level, theme }) => {
  if (level > 1) {
    return css`
      background-color: ${rgba(theme.colors.primary, 0.05)};
    `;
  }
};

const levelStyle = ({ level }) => {
  return css`
    padding: 10px 15px;
  `;
};

const borderStyle = ({ hasBorder, theme }) => {
  if (hasBorder) {
    return css`
      border-left: 3px solid ${theme.colors.primary};
    `;
  }
};

export const Item = styled(ListOptions.item)`
  ${subItemsStyle};
  ${levelStyle};
  ${borderStyle};
`;

export const Text = styled.span`
  img {
    display: inline-block;
    margin-right: 10px;
  }
`;
