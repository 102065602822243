import React from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';

import FormBox from '../../../components/FormBox';
import Divider from '../../../components/Divider';
import { ImageCheck, Input, Textarea, Toggle } from '../../../components/Form';
import GroupControl, { GroupItem } from '../../../components/GroupControl';

import imovelWeb from '../../../assets/img/portals/imovelweb.png';
import mercadolivre from '../../../assets/img/portals/mercadolivre.png';
import olx from '../../../assets/img/portals/olx.png';
import vivareal from '../../../assets/img/portals/vivareal.png';
import zap from '../../../assets/img/portals/zap.png';

const defaultProps = {};
const propTypes = {};

const Publication = () => {
  return (
    <FormBox title="Publicação" className="js-publication">
      <Row>
        <Field
          xs={3}
          label="Mostra imóvel no site?"
          name="is_published"
          component={Toggle}
        />
        <Field
          xs={3}
          label="Destacar na capa do site?"
          name="is_home_published"
          component={Toggle}
        />
        <Field
          xs={3}
          label="Mostrar para rede?"
          name="is_network_published"
          component={Toggle}
        />
      </Row>
      <Row>
        <Col xs={12}>
          <Divider spacingY={5} borderStyle="dashed" />
        </Col>
        <FormBox.header size="small">Localização</FormBox.header>
      </Row>
      <Row>
        <Field
          xs
          label="Mostrar Logradouro?"
          name="is_street_shown"
          component={Toggle}
        />
        <Field
          xs
          label="Mostrar Bairro?"
          name="is_neighborhood_shown"
          component={Toggle}
        />
        <Field
          xs
          label="Mostrar condomínio"
          name="is_condominium_shown"
          component={Toggle}
        />
        <Field xs label="Andar" name="is_floor_shown" component={Toggle} />
        <Field
          xs
          label="Número do Apto"
          name="is_apartment_number_shown"
          component={Toggle}
        />
      </Row>
      <Row>
        <Col xs={12}>
          <Divider spacingY={5} borderStyle="dashed" />
        </Col>
        <FormBox.header size="small">Portais</FormBox.header>
      </Row>
      <Row>
        <Field
          xs={12}
          label="Título do imóvel para os portais"
          name="titulo_portais"
          component={Input}
          placeholder="Digite o título"
        />
      </Row>
      <Field
        name="portais"
        colItems={{
          xs: 2
        }}
        options={[
          {
            id: '1',
            title: 'VivaReal',
            image: vivareal,
            options: [{ name: 'isSelected', label: 'Selecionar' }]
          },
          {
            id: '2',
            title: 'Olx',
            image: olx,
            options: [{ name: 'isSelected', label: 'Selecionar' }]
          },
          {
            id: '3',
            title: 'Zap Imóveis',
            image: zap,
            options: [
              { name: 'isSelected', label: 'Normal' },
              { name: 'isFeatured', label: 'Destaque' },
              { name: 'isSuper', label: 'Super Des.' }
            ]
          },
          {
            id: '4',
            title: 'Mercado Livre',
            image: mercadolivre,
            options: [
              { name: 'isSelected', label: 'Normal' },
              { name: 'isGolden', label: 'Ouro' },
              { name: 'isDiamond', label: 'Diamante' }
            ]
          },
          {
            id: '5',
            title: 'Imóvel Web',
            image: imovelWeb,
            options: [
              { name: 'isSelected', label: 'Normal' },
              { name: 'isWeb', label: 'Web' },
              { name: 'isHome', label: 'Home' }
            ]
          }
        ]}
        component={ImageCheck}
      />
      <Row>
        <Col xs={12}>
          <Divider spacingY={5} borderStyle="dashed" />
        </Col>
        <FormBox.header size="small">SEO</FormBox.header>
      </Row>
      <Row>
        <Col xs={8}>
          <Row>
            <Field
              xs={12}
              label={
                <>
                  Título da página{' '}
                  <a
                    href="https://support.google.com/webmasters/answer/35624?hl=pt-BR"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-link"
                  >
                    (saiba mais)
                  </a>
                </>
              }
              name="meta_title"
              placeholder="Digite o título"
              component={Input}
              maxLength={120}
            />
            <Col xs={12}>
              <GroupControl label="URL Personalizada">
                <GroupItem label="corretorescoligados.com.br/" size="small" />
                <GroupItem>
                  <Field
                    name="property_url"
                    component={Input}
                    placeholder="Digite a url"
                  />
                </GroupItem>
              </GroupControl>
            </Col>
            <Field
              xs={12}
              type="simple"
              label="Descrição"
              name="meta_description"
              placeholder="Digite a descrição"
              maxLength={120}
              component={Textarea}
            />
          </Row>
        </Col>
      </Row>
    </FormBox>
  );
};

Publication.defaultProps = defaultProps;
Publication.propTypes = propTypes;

export default Publication;
