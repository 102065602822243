import React, { useMemo } from 'react';
import { Wrapper, Logo, Box, Title, Url, Description } from './styles';

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str;
}

const DEFAULT = {
  URL: 'www.seusite.com.br/',
  TITLE: 'Título do seu imóvel no Google',
  DESCRIPTION:
    'Apartamento com excelente localização, próximo a praia e ao comércio. Rua paralela a avenida, com muitos restaurantes próximos'
};

const GoogleResult = ({
  url = DEFAULT.URL,
  title = DEFAULT.TITLE,
  description = DEFAULT.DESCRIPTION
}) => {
  const clearedUrl = useMemo(() => {
    if (!url || url === '') return DEFAULT.URL;
    return url.replace(/(^\w+:|^)\/\//, '');
  }, [url]);

  const baseUrl = useMemo(() => {
    try {
      return clearedUrl.split('/')[0];
    } catch {
      return clearedUrl;
    }
  }, [clearedUrl]);

  const pathParams = useMemo(() => {
    try {
      let params = clearedUrl.split('/');
      params.shift();
      params = params.join('/');

      if (params === '') return null;

      return ' › ' + params.replaceAll('/', ' › ');
    } catch (err) {
      return null;
    }
  }, [clearedUrl]);

  const descriptionFormatted = useMemo(() => {
    if (!description) return DEFAULT.DESCRIPTION;
    return truncate(description, 154);
  }, [description]);

  return (
    <Wrapper>
      <Logo />
      <Box>
        <Url>
          {baseUrl}
          <span>{pathParams}</span>
        </Url>
        <Title>{title || DEFAULT.TITLE}</Title>
        <Description>{descriptionFormatted}</Description>
      </Box>
    </Wrapper>
  );
};

export default GoogleResult;
