import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from 'components/Wrapper';
import NoContent from 'components/NoContent';
import finacialIndex from 'assets/img/receptions/financial-index.svg';
import Button from 'components/Button';

const propTypes = {
  handleClickAction: PropTypes.func.isRequired
};

const defaultProps = {};

const FinancialReception = ({ handleClickAction }) => (
  <Wrapper.container>
    <NoContent
      title="Índices Financeiros"
      text="Cadastre os índices financeiros que serão utilizados no cadastro dos seus imóveis."
      image={finacialIndex}
    >
      <Button
        type="button"
        color="secondary"
        colorText="white"
        onClick={handleClickAction}
      >
        Entendi
      </Button>
    </NoContent>
  </Wrapper.container>
);

FinancialReception.propTypes = propTypes;
FinancialReception.defaultProps = defaultProps;

export default FinancialReception;
