import { useEffect, useState } from 'react';
import { MdHelp } from 'react-icons/md';
import { Field } from 'redux-form';
// Components
import Box from 'components/FilterBox';
import { Checkbox } from 'components/Form';
// Service
import * as dwvService from 'services/integrations/dwv';
import * as oruloService from 'services/orulo';
// Modules

const helpGuru = (
  <MdHelp
    data-html
    data-effect="solid"
    data-tip={`
      Com a rede, você terá a disposição <br/>
      todos os imóveis cadastrados por<br/> 
      outros corretores na sua região.
    `}
  />
);

const DatabaseField = ({ hasGuru }) => {
  const [hasOrulo, setHasOrulo] = useState(false);
  const [hasDwv, setHasDwv] = useState(false);

  useEffect(() => {
    async function fetchHasOrulo() {
      try {
        const res = await oruloService.getToken();
        setHasOrulo(!!res?.data);
      } catch {
        setHasOrulo(false);
      }
    }

    async function fetchHasDwv() {
      try {
        const res = await dwvService.get();
        setHasDwv(!!res?.data);
      } catch {
        setHasDwv(false);
      }
    }

    fetchHasOrulo();
    fetchHasDwv();
  }, []);

  return (
    <Box title="Banco de dados">
      <Field
        type="checkbox"
        label="Meus Imóveis"
        inputValue="self"
        name="on_network"
        component={Checkbox}
      />
      {hasGuru && (
        <Field
          type="checkbox"
          label={<>Rede Guru {helpGuru}</>}
          inputValue="guru"
          name="on_network"
          component={Checkbox}
          className="h-margin-top--5"
        />
      )}
      {hasOrulo && (
        <Field
          type="checkbox"
          label="Rede Órulo"
          inputValue="orulo"
          name="on_network"
          component={Checkbox}
          className="h-margin-top--5"
        />
      )}
      {hasDwv && (
        <Field
          type="checkbox"
          label="DWV"
          inputValue="dwv"
          name="on_network"
          component={Checkbox}
          className="h-margin-top--5"
        />
      )}
    </Box>
  );
};

DatabaseField.defaultProps = {
  hasGuru: true,
};

export default DatabaseField;
