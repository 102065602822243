import React from 'react';
import classnames from 'classnames';
import Modal from 'react-modal';
import { ModalClose } from 'components/Modal';
import Button from 'components/Button';
import Image from 'components/Image';
import { Wrapper, Footer, WrapImage } from './styles';

function ModalPreviewImageUser({
  imageUrl,
  handleChangeImage,

  modalConfig,
  sizeModal,
  isOpen,
  modalType,
  handleClose,
}) {
  return (
    <Modal
      {...modalConfig}
      className={classnames('Modal', {
        [`Modal--${sizeModal}`]: sizeModal,
      })}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Wrapper>
        <WrapImage>
          <Image src={imageUrl} alt="" />
          <ModalClose handleClose={handleClose} />
        </WrapImage>
        <Footer>
          <Button color="success" onClick={handleChangeImage}>
            Alterar Imagem
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
}

export default ModalPreviewImageUser;
