import React, { useEffect } from 'react';
import Modal from 'react-modal';

// Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Services
import * as people from 'services/people';

import { PERSON_PERMISSIONS } from 'constants/rules';
import Alert from 'react-s-alert';
import Loading from 'components/Loading';

function ModalPersonRemove({
  id,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onCancel,
  onSuccess,
}) {
  const { data, isFetching } = useRequest({
    request: () =>
      people.getOne(id, {
        include: 'properties_count',
      }),
    initialState: {},
  });

  useEffect(() => {
    if (
      !isFetching &&
      data?.permissions &&
      !data?.permissions?.includes(PERSON_PERMISSIONS.DELETE)
    ) {
      Alert.success('Você não tem permissão de deletar esse cliente');
      return false;
    }
  }, [data, isFetching]);

  if (isFetching) return <Loading isVisible isFullScreen />;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={isFetching}
        title="Deseja excluir este cliente permanentemente?"
        handleClose={handleClose}
      >
        <Form
          handleClose={handleClose}
          person={data}
          initialValues={data}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalPersonRemove;
