import React from 'react';
import NoContent from 'components/NoContent';
import noDataReport from 'assets/img/noData/report.svg';

const NoChart = () => (
  <NoContent
    image={noDataReport}
    text="Você não possui informações suficientes para exibir esse relatório."
    size={430}
  />
);

export default NoChart;
