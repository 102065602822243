import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DIVIDER_SHAPE } from '../../constants/shapes';

const defaultProps = {
  top: 15,
  bottom: 15,
  left: 0,
  right: 0,
  spacingX: null,
  spacingY: null,
  width: '100%',
  borderStyle: 'solid',
  colorText: 'primary-light',
  style: {}
};

const propTypes = {
  ...DIVIDER_SHAPE,
  borderStyle: PropTypes.oneOf(['solid', 'dashed', 'dotted']),
  width: PropTypes.string,
  colorText: PropTypes.string,
  style: PropTypes.object
};

const Divider = ({
  width,
  style,
  spacingX,
  spacingY,
  top,
  left,
  bottom,
  right,
  borderStyle,
  children,
  colorText
}) => {
  const styles = {
    width,
    marginTop: spacingY || top,
    marginBottom: spacingY || bottom,
    marginLeft: spacingX || left,
    marginRight: spacingX || right,
    ...style
  };

  return (
    <div
      className={classnames('Divider', {
        [`Divider--${borderStyle}`]: borderStyle
      })}
      style={styles}
    >
      {children && (
        <span
          className={classnames('Divider__text', {
            [`h-color--${colorText}`]: colorText
          })}
        >
          {children}
        </span>
      )}
    </div>
  );
};

Divider.defaultProps = defaultProps;
Divider.propTypes = propTypes;

export default Divider;
