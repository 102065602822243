import Button from 'components/Button';
import { RadioButton } from 'components/Form';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { hideLoading, showLoading } from 'modules/loading';
import { Actions as SelectedsActions } from 'modules/selecteds';
import Modal from 'react-modal';
import { Field, reduxForm } from 'redux-form';
import api from 'services';
import styled from 'styled-components';

const RadioList = styled.div`
  margin-top: 15px;
  margin-bottom: 25px;

  .FormGroup {
    margin-bottom: 0;
  }
`;

function ModalPortalPropertiesSelect({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  handleSubmit,
}) {
  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate title="Seleção de imóveis" handleClose={handleClose}>
          Quais imóveis voce gostaria de selecionar
          <RadioList>
            <Field
              type="radio"
              name="option"
              label="Todos imóveis desta página"
              value="current_page"
              component={RadioButton}
            />
            <Field
              type="radio"
              name="option"
              label="Todos imóveis desta página e das páginas seguintes também"
              value="all_pages"
              component={RadioButton}
            />
          </RadioList>
          <ModalFooter>
            <Button type="button" color="white" onClick={handleClose}>
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              Selecionar
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </form>
    </Modal>
  );
}

const getPropertyIds = (properties) => properties.map((p) => p.id);

export default reduxForm({
  form: 'FormModalProperties',
  initialValues: {
    option: 'current_page',
  },
  onSubmit: async (values, dispatch, props) => {
    const propertyIds = getPropertyIds(props.properties);
    const filter = props.params.filter;

    dispatch(showLoading());

    const setAllIds = (ids) => {
      dispatch(SelectedsActions.setAll('properties_search', ids));
    };

    if (values.option === 'current_page') {
      setAllIds(propertyIds);
    } else {
      delete filter?.count;
      const response = await api.getList(
        'settings/portals-options-properties/property-ids',
        {
          filter,
        }
      );

      setAllIds(response.data);
    }

    dispatch(hideLoading());
    props.handleClose();

    return true;
  },
})(ModalPortalPropertiesSelect);
