import Button from 'components/Button';
import { Input } from 'components/Form';
import FieldImageModal from 'components/Form/components/FieldImageModal';
import { ModalFooter } from 'components/Modal';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import FieldPhone from 'containers/FieldPhone';

function FormModalWhatsapp({ id, handleSubmit, handleClose }) {
  // const fileUrl = user.image ? user.image.preview : user.file_url;

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={6}>
          <FieldImageModal
            hasRemove
            style={{ height: '228px', display: 'grid', placeItems: 'center' }}
            aspectRatio={[1, 1]}
            imageSize="medium"
            name="image"
            fileUrlField="file_url"
            text={null}
            actions="column"
          />
          <p className="h-margin-top--5">
            <strong>Recomendado 90x90px</strong>
          </p>
        </Col>
        <Col xs={6}>
          <Row>
            <Field
              xs={12}
              label="Nome"
              name={`name`}
              component={Input}
              placeholder="Digite o nome"
            />
            <FieldPhone xs={12} label="Telefone" name="phone" />
            <Field
              xs={12}
              label="Descrição ou setor"
              placeholder="Digite a descrição"
              name="description"
              component={Input}
            />
          </Row>
        </Col>
      </Row>
      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          {id ? 'Editar' : 'Salvar'}
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormModalWhatsapp',
})(FormModalWhatsapp);
