import React from 'react';
import { isDirty, reset } from 'redux-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
// Components
import Button from 'components/Button';
import Container from 'components/Container';
import Avatar from 'components/Avatar';
import Nodata from 'components/Nodata';
// Containers
import Options from '../../containers/Options';
// Helpers
import { getFirstName } from 'lib/text';
// Image
import property from 'assets/img/noData/house.svg';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  handleClickPerson: PropTypes.func,
  handleClickProperty: PropTypes.func,
};
const defaultProps = {
  data: [],
  handleClickPerson: null,
  handleClickProperty: null,
};

const Property = ({
  data,
  handleClickPerson,
  handleClickProperty,
  fetchProperties,
}) => {
  const people = _.get(data, 'people');
  const user = _.get(data, 'user');

  return (
    <>
      <tr
        key={`property-excluded-${data.id}`}
        onClick={() => handleClickProperty(data)}
      >
        <td>
          <div className="h-flex">
            <Avatar
              hasZoom={false}
              size="large"
              image={(data.cover_image || {}).file_url}
            />
            <div className="h-margin-left--15">
              <p className="h-color--primary" style={{ whiteSpace: 'nowrap' }}>
                {data.title_formatted} -{' '}
                <span className="h-link">Ref.: {data.reference}</span>
              </p>
              <p className="h-color--primary">{data.address_formatted}</p>
            </div>
          </div>
        </td>
        <td
          className="border-left h-text-center"
          onClick={(e) => {
            e.stopPropagation();
            handleClickPerson(people);
          }}
        >
          <span className="h-link">
            {getFirstName(people ? people.name : '--')}
          </span>
        </td>
        <td className="border-left h-text-center">
          {getFirstName(user ? user.name : '--')}
        </td>
        <td className="border-left h-text-right">{data.calculated_price}</td>
        <td className="border-left">
          <Options property={data} fetchProperties={fetchProperties} />
        </td>
      </tr>
      <tr key={`property-spacer-${data.id}`} className="spacer">
        <td />
      </tr>
    </>
  );
};

const renderProperties = (
  properties,
  handleClickPerson,
  handleClickProperty,
  fetchProperties
) => {
  if (!properties && properties.length === 0) return null;

  return properties.map((property) => (
    <Property
      key={property.id}
      data={property}
      handleClickPerson={handleClickPerson}
      handleClickProperty={handleClickProperty}
      fetchProperties={fetchProperties}
    />
  ));
};

function TablePropertiesExcluded({
  data,
  fetchProperties,
  handleClickPerson,
  handleClickProperty,
  reset,
  isDirty,
}) {
  if ((isDirty && !data) || data.length <= 0)
    return (
      <Container>
        <Nodata
          image={property}
          title="Nenhum imóvel encontrado"
          text={
            isDirty
              ? 'Clique em limpar filtro e tente novamente.'
              : 'Quando você excluir algum imóvel, ele aparecerá aqui.'
          }
        >
          {isDirty && (
            <Button
              color="danger"
              className="h-margin-top--15"
              onClick={() => {
                reset('PropertiesExcluded');
              }}
            >
              Limpar Filtro
            </Button>
          )}
        </Nodata>
      </Container>
    );

  return (
    <table className="TableAdmin" style={{ tableLayout: 'auto' }}>
      <thead>
        <tr>
          <th align="left">Imóveis</th>
          <th style={{ width: '1px' }}>Proprietário</th>
          <th style={{ width: '1px' }}>Responsável</th>
          <th style={{ width: '1px' }} className="h-text-right">
            Preço
          </th>
          <th style={{ width: '1px' }}>Opções</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="nome" />
          <th scope="cliente" className="border-left">
            Nome
          </th>
          <th scope="corretor" className="border-left">
            Nome
          </th>
          <th scope="valor" className="border-left h-text-right">
            Total
          </th>
          <th scope="opcoes" className="border-left" />
        </tr>
        {renderProperties(
          data || [],
          handleClickPerson,
          handleClickProperty,
          fetchProperties
        )}
      </tbody>
    </table>
  );
}

TablePropertiesExcluded.propTypes = propTypes;
TablePropertiesExcluded.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isDirty: isDirty('PropertiesExcluded')(state),
});

const mapDispatchToProps = {
  reset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TablePropertiesExcluded);
