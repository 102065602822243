// import styles from './styles'
import Box from '../../components/Box';
import { STORAGE_KEY } from 'containers/CreciAlert/constants';
import { MdDone } from 'react-icons/md';

export default function Success() {
  return (
    <Box
      canClose
      color="#31D084"
      storageKey={STORAGE_KEY}
      label={{
        icon: <MdDone />,
        text: 'CRECI Verificado',
      }}
    >
      O processo de verificação foi concluído, seu CRECI foi validado.
    </Box>
  );
}
