import React from 'react';
import { MdDelete } from 'react-icons/md';
import FormRelatedPeople from 'components/FormRelatedPeople';
import { ListArray } from 'components/Form';
import { Field } from 'redux-form';

export default function FieldPhones() {
  return (
    <Field
      canEdit={false}
      label="Pessoas ligadas"
      addText="Ligar pessoa"
      name="relateds"
      noDataText="Você ainda não ligou nenhuma pessoa a esse cliente"
      renderText={({ name, description }) => {
        let text = name;

        if (description) {
          text += ` (${description})`;
        }

        return text;
      }}
      renderActions={(related, { currentIndex, actions }) => {
        return (
          <button
            type="button"
            className="h-color--danger"
            onClick={() => actions.remove(currentIndex)}
          >
            <MdDelete />
          </button>
        );
      }}
      formComponent={FormRelatedPeople}
      handleRemove={(person, { actions, currentIndex, handleClose }) => {
        actions.remove(currentIndex);
        handleClose();
      }}
      onSubmit={(related, dispatch, { currentIndex, actions }) => {
        let data = {
          id: related?.person?.id,
          name: related?.person?.name,
          description: related.description
        };

        if (currentIndex) {
          actions.update(currentIndex, data);
        } else {
          actions.push(data);
        }
        return data;
      }}
      component={ListArray}
    />
  );
}
