// Widgets.js
import { combineReducers } from 'redux';
// Services
import * as people from '../services/people';
import * as auditsService from '../services/audits';
// Modules
import dataReducer from 'modules/data';
import menuModule from './menu';
import { clearDocuments } from './personDocuments';
import { clearEvents } from './personEvents';
import { clearNotes } from './personNotes';
import { clearStories } from './personStories';
import { profilesReducer } from 'containers/ModalRoot/ModalPerson/components/SearchProfile/module';
// Helpers
import { createModule } from '../lib/reducer-helpers';

// Constants
const PEOPLE_RELATIONS = people.PEOPLE_RELATIONS;

export const {
  actionCreators: personAuditsActions,
  reducer: personAuditsReducer,
  selectors: personAuditsSelector,
} = dataReducer('modalPerson/personAudits');

export const { reducer: reducerMenuCrm, thunks: menuCrmThunks } =
  menuModule('menuCrm');

// Selectors
export const relationsSelector = (state) => state.modalPerson.relations;
export const personSelector = (state) => state.modalPerson.person;

const createAction = createModule('modalPerson');

// Actions
// Menu da modal
const SET_PAGE = createAction('SET_PAGE');
const SET_FILTER_DASHBOARD = createAction('SET_FILTER_DASHBOARD');

// Person
const REQUEST_PERSON = createAction('REQUEST_PERSON');
const RECEIVE_PERSON = createAction('RECEIVE_PERSON');

// Others
const RESET_MODAL = createAction('RESET_MODAL');

// Owners
const SET_OWNER = createAction('SET_OWNER');

// Relations
const REQUEST_RELATIONS = createAction('REQUEST_RELATIONS');
const RECEIVE_RELATIONS = createAction('RECEIVE_RELATIONS');
const REQUEST_RELATIONS_FAIL = createAction('REQUEST_RELATIONS_FAIL');

/**
 * Event Actions
 */

// Initial State
const initialState = {
  currentPage: 'Notes',
  filterDashboard: 'ALL',
  relations: {
    data: {
      [PEOPLE_RELATIONS.crm_properties]: 0,
      [PEOPLE_RELATIONS.calendars]: 0,
      [PEOPLE_RELATIONS.sent_properties]: 0,
      [PEOPLE_RELATIONS.pending_nutritions]: 0,
    },
    meta: { isFetching: false },
  },
  person: {
    id: '',
    user_id: '',
    name: '',
  },
  users: [],
  followers: [],
  owner: '',
  meta: {
    isFetching: false,
    isFetchingFollowers: false,
    showEventForm: false,
    showNotesForm: false,
  },
};

export function reducerFilterDashboard(
  state = initialState.filterDashboard,
  action
) {
  switch (action.type) {
    case SET_FILTER_DASHBOARD:
      return action.filter;
    default:
      return state;
  }
}

export function reducerPerson(state = initialState.person, action) {
  switch (action.type) {
    case RECEIVE_PERSON:
      return action.person;
    case RESET_MODAL:
      return initialState.person;
    default:
      return state;
  }
}

export function reducerPage(state = initialState.currentPage, action) {
  switch (action.type) {
    case SET_PAGE:
      return action.pageName;
    case RESET_MODAL:
      return initialState.currentPage;
    default:
      return state;
  }
}

export function reducerOwner(state = initialState.owner, action) {
  switch (action.type) {
    case SET_OWNER:
      return action.id || initialState.owner;
    case RESET_MODAL:
      return initialState.owner;
    default:
      return state;
  }
}

// Reducer
export function reducerMeta(state = initialState.meta, action) {
  switch (action.type) {
    case REQUEST_PERSON:
      return { ...state, isFetching: true };
    case RECEIVE_PERSON:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}

export function reducerRelations(state = initialState.relations, action) {
  switch (action.type) {
    case REQUEST_RELATIONS:
      return {
        ...state,
        meta: { isFetching: true },
      };
    case RECEIVE_RELATIONS:
      return {
        ...state,
        data: action.payload,
        meta: { isFetching: false },
      };
    case REQUEST_RELATIONS_FAIL:
      return initialState.relations;
    default:
      return state;
  }
}

export default combineReducers({
  currentPage: reducerPage,
  filterDashboard: reducerFilterDashboard,
  relations: reducerRelations,
  personAudits: personAuditsReducer,
  person: reducerPerson,
  owner: reducerOwner,
  meta: reducerMeta,
  profiles: profilesReducer,
  menuCrm: reducerMenuCrm,
});

/**
 * Action Creators
 */
export const requestPerson = () => ({ type: REQUEST_PERSON });
export const receivePerson = (person) => ({ type: RECEIVE_PERSON, person });

export const requestRelations = () => ({ type: REQUEST_RELATIONS });
export const receiveRelations = (payload) => ({
  type: RECEIVE_RELATIONS,
  payload,
});
export const requestRelationsFail = () => ({ type: REQUEST_RELATIONS_FAIL });

export const setOwner = (id) => ({ type: SET_OWNER, id });
export const resetModal = () => ({ type: RESET_MODAL });

// side effects, only as applicable
// e.g. thunks, epics, etc
export const setPage = (pageName) => (dispatch) =>
  dispatch({ type: SET_PAGE, pageName });

export const setFilterDashboard = (filter) => (dispatch) =>
  dispatch({ type: SET_FILTER_DASHBOARD, filter });

export const getPerson = (person) => (dispatch) => {
  dispatch(receivePerson(person));
};

/**
 * Pega as informações de um cliente
 * @param personId
 * @param params
 * @return {Promise}
 */
export const getPersonInfo = (personId, params) => (dispatch) => {
  dispatch(requestPerson());

  return people.getOne(personId, params).then(({ data: person }) => {
    dispatch(receivePerson(person));
    return person;
  });
};

export const fetchPersonAudits = (person) => (dispatch) => {
  if (!person) return false;

  dispatch(personAuditsActions.request());

  return auditsService.getListPersonAudits(person).then(({ data: audits }) => {
    dispatch(personAuditsActions.receive(audits));
  });
};

/**
 * Busca todas as relações de um cliente
 * @param {Object} person - o cliente que vai ser buscado o count das relações
 * @return {Function}
 */
export const fetchPersonCountRelations = (person) => (dispatch) => {
  dispatch(requestRelations());

  return people
    .peopleRelationCounts(person.id, {
      [PEOPLE_RELATIONS.crm_properties]: null,
      [PEOPLE_RELATIONS.calendars]: null,
      [PEOPLE_RELATIONS.sent_properties]: null,
      [PEOPLE_RELATIONS.pending_nutritions]: null,
    })
    .then(({ data }) => {
      dispatch(receiveRelations(data));
    })
    .catch(() => {
      dispatch(requestRelationsFail());
    });
};

export function clearModal() {
  return (dispatch) => {
    clearDocuments()(dispatch);
    clearEvents()(dispatch);
    clearNotes()(dispatch);
    clearStories()(dispatch);
    dispatch(resetModal());
  };
}
