import * as S from './styles';
import { Field } from 'redux-form';
import { ThemeProvider } from 'styled-components';
import FormGroup from 'components/Form/components/FormGroup';

const DEFAULT_COLORS = [
  '#FF9315',
  '#FF0000',
  '#0084F4',
  '#1C0C1E',
  '#FFCD40',
  '#31D084',
  '#9E32A0',
  '#F60094',
  '#AB0002',
  '#787279',
  '#00569F',
  '#1DFFD6',
  '#3C611F',
  '#BEA7FF',
];

export function ColorPallete({ label, columns, meta, input, colors }) {
  const onChangeValue = (value) => () => {
    input.onChange(value);
  };

  return (
    <ThemeProvider
      theme={{
        columns,
      }}
    >
      <FormGroup labelText={label} meta={meta}>
        <S.Wrapper>
          {colors.map((color, index) => (
            <ThemeProvider
              theme={{
                isActive: color === input.value,
              }}
            >
              <S.Item key={color + index} onClick={onChangeValue(color)}>
                <S.Color color={color} />
              </S.Item>
            </ThemeProvider>
          ))}
        </S.Wrapper>
      </FormGroup>
    </ThemeProvider>
  );
}

export default function FieldColorPallete({
  label = 'Cor',
  columns = 7,
  name = 'color',
  colors = DEFAULT_COLORS,
}) {
  return (
    <Field
      label={label}
      columns={columns}
      name={name}
      colors={colors}
      component={ColorPallete}
    />
  );
}
