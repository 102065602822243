import * as people from '../services/people';
import { createModule } from '../lib/reducer-helpers';

const createAction = createModule('personInfos');
const REQUEST_PERMISSION = createAction('REQUEST_PERMISSION');
const RESET_INFOS = createAction('RESET');

export const permissionSelector = state => state.personInfos.hasPermission;

// Initial State
const initialState = { hasPermission: false };

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PERMISSION:
      return { ...state, hasPermission: true };
    case RESET_INFOS:
      return initialState;
    default:
      return state;
  }
}

export const requestPermission = () => ({ type: REQUEST_PERMISSION });
export const resetInfos = () => ({ type: RESET_INFOS });

export const getPermission = id => dispatch =>
  people.requestPermission(id).then(() => {
    dispatch(requestPermission());
  });
