import React from 'react';
import { Row } from 'react-flexbox-grid';
import { Field, Form, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import Input from 'components/Form/components/Input';
import { ModalFooter } from 'components/Modal';
// Services
import validate from './validate';
import FieldSelectUser from 'containers/FieldSelectUser';

const FormConnectOrulo = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <Row>
      <Field xs={6} label="Client ID" name="client_id" component={Input} />
      <Field
        xs={6}
        label="Client Secret"
        name="client_secret"
        component={Input}
      />
    </Row>
    <FieldSelectUser
      name="user_id"
      label="Corretor responsável"
    />
    <ModalFooter>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success">
        Conectar
      </Button>
    </ModalFooter>
  </Form>
);

export default reduxForm({
  form: 'FormConnectOrulo',
  validate
})(FormConnectOrulo);
