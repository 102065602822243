import React from 'react';
import { useDocument } from 'pages/Print/hooks/useDocument';
// import { Container } from './styles';

const RentAuthorization = () => {
  const { text, logo_file_url } = useDocument('@tecimob/rent-authorization');

  return (
    <div>
      <img
        src={logo_file_url}
        alt="Logo da imobiliária"
        style={{ height: '80px', marginBottom: '10px' }}
      />
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default RentAuthorization;
