import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 15px;
  background: #fff;
  z-index: 5;

  ${(p) =>
    p.theme.isFullScreen &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 15px;
    `}
`;

export const Title = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
`;

export const LeftContext = styled.div`
  display: flex;
  align-items: center;
`

export const IconSearch = styled.img`
  margin-right: 5px;
`

export const Content = styled.div``;

export const NoContent = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  padding: 35px 0 20px;
`;
