import api from 'services/index';
// Helpers
import { responseMessage } from 'lib/service-helpers';

const RESOURCE = 'settings/lgpd';

export const getOne = (values) => api.getOne(RESOURCE, '', values);

export const update = ({ id, ...values }) =>
  api
    .update(RESOURCE, values)
    .then(responseMessage('Configurações atualizadas'))
    .catch(responseMessage('Erro ao atualizar os textos'));
