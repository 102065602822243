import React, { Component, memo } from 'react';
import { Field } from 'redux-form';
import Icon from '../../../Icon';
import PopoverCheckbox from './PopoverCheckbox';
import TextOverflow from 'components/TextOverflow';
import Tooltip from 'react-tooltip';

//
const getOptionsLower = limit => (option, index) => limit > index;
const getOptionsGreater = limit => (option, index) => limit <= index;

const renderCheckbox = (input, labelKey, valueKey) => (option, index) => {
  const { name, onChange } = input;
  const inputValue = input.value;

  const label = option[labelKey];
  const value = option[valueKey];

  const handleChange = event => {
    const arr = [...inputValue];
    if (event.target.checked) {
      arr.push(value);
    } else {
      arr.splice(arr.indexOf(value), 1);
    }
    return onChange(arr);
  };
  const checked = inputValue.includes(value);
  return (
    <label
      key={`checkbox-${index}`}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <input
        type="checkbox"
        name={`${name}[${index}]`}
        value={value}
        checked={checked}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <Icon
        name={checked ? 'check-square' : 'square-o'}
        className="h-margin-right--5"
        style={{ flexShrink: 0 }}
      />
      <TextOverflow>{label}</TextOverflow>
    </label>
  );
};

class CheckboxGroup extends Component {
  constructor(props) {
    super(props);
    this.field = this.field.bind(this);

    this.checkboxGroup = React.createRef();
  }

  componentDidMount() {
    Tooltip.rebuild();
  }

  field(props) {
    const { input, meta, options, valueKey, labelKey, limit } = props;
    let currentOptions = options;
    let hasMoreOptions = null;

    const { touched, error } = meta;

    // Quantidade de opcoes que tem
    const optionsLength = options ? options.length : 0;

    // verifica se tem limite
    const hasMore = optionsLength > limit;

    // se tiver mais opcoes que o limite
    // deixa aparecendo só as primeiras opções
    if (hasMore) {
      currentOptions = currentOptions.filter(getOptionsLower(limit));
      hasMoreOptions = options.filter(getOptionsGreater(limit));
    }

    const checkboxes =
      currentOptions &&
      currentOptions.map((option, index) => (
        <li key={`checkbox-${option[valueKey]}`} style={{ marginBottom: 5 }}>
          {renderCheckbox(input, labelKey, valueKey)(option, index)}
        </li>
      ));

    return (
      <div ref={this.checkboxGroup} className="CheckboxGroup">
        <ul style={{ listStyle: 'none' }}>{checkboxes}</ul>
        {hasMore && (
          <PopoverCheckbox
            {...props}
            containerRef={this.checkboxGroup}
            options={hasMoreOptions}
            renderCheckbox={renderCheckbox}
          />
        )}
        {touched && error && <p className="error">{error}</p>}
      </div>
    );
  }

  render() {
    return <Field {...this.props} type="checkbox" component={this.field} />;
  }
}

export default memo(CheckboxGroup);
