import createDataReducer from 'modules/data';

import * as condosService from 'services/condos';

export const {
  reducer: condominiumReducer,
  selectors: condominiumSelectors,
  actionCreators: condominiumActions
} = createDataReducer('filter/condominium');

export const fetchCondominium = () => async dispatch => {
  dispatch(condominiumActions.request());
  const { data } = await condosService.getList();
  dispatch(condominiumActions.receive(data));
};

export default condominiumReducer;
