import styled from 'styled-components';
import FixedBar from 'components/FixedBar';

export const BarItemSelecteds = styled(FixedBar.item)`
  margin-left: -15px;
  display: grid;
  place-items: center;
  line-height: 20px;
  height: 61px;
  background-color: #0a4ed6;
  color: #fff;
  font-weight: 600;
  padding: 0 20px;
`;

export const Number = styled.span`
  padding: 3px;
  background: #fff;
  border-radius: 25px;
  margin-left: 10px;
  color: #0a4ed6;
`;

export const UncheckAll = styled.button`
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.borderColor};
  line-height: 48px;
  display: block;
  text-align: left;
  padding: 0 15px;
  color: ${(p) => p.theme.colors.danger};
  cursor: pointer;

  :hover {
    background-color: #f1f1f1;
  }
`;
