import StartValidation from './StartValidation';
import FormValidation from './FormValidation';
import CheckingCreci from './CheckingCreci';
import Success from './Success';
import Fail from './Fail';

export const Screens = {
  StartValidation,
  FormValidation,
  CheckingCreci,
  Success,
  Fail,
};
