import React from 'react';
import { compose } from 'redux';
// Components
import MainTitle from 'components/MainTitle';
// Containers
import FormContainer from './containers/FormContainer';
// HOC
import { withRouter } from 'react-router';

const propTypes = {};
const defaultProps = {};

class Search extends React.Component {
  get renderForm() {
    const device = this.props.match.params.device;

    if (device === 'desktop') {
      return (
        <FormContainer
          key="FormSearchDesktop"
          form="FormSearchDesktop"
          device="desktop"
        />
      );
    }

    return (
      <FormContainer
        key="FormSearchMobile"
        form="FormSearchMobile"
        device="mobile"
      />
    );
  }

  render() {
    const device = this?.props?.match?.params?.device;

    return (
      <>
        <MainTitle
          title={`Pesquisa ${device}`}
          text="Escolha o tipo de pesquisa que será utilizado em seu site. Após, defina quais campos aparecerão e quais serão omitidos para o seu cliente."
        />
        {this.renderForm}
      </>
    );
  }
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

export default compose(withRouter)(Search);
