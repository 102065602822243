import React, { useState } from 'react';
import Loading from 'components/Loading';
import Filter from './components/Filter';
import Graph from './components/Graph';
import moment, { format } from 'lib/moment';
import { STATUS } from 'lib/HTTP';
import { Content, ExpandedDetails, Header, Title, Wrapper } from './styles';
import { Col, Row } from 'react-flexbox-grid';
import { Expand } from 'containers/GraphVisitsSite/components/Graph/styles';
import { useChart } from 'containers/GraphVisitsSite/hooks/useChart';
import GraphBoxBar from 'containers/GraphVisitsSite/components/GraphBoxBar';
import { formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import { useTimeseries } from 'containers/GraphVisitsSite/hooks/useTimeseries';
import Nodata from './components/Nodata';

// import { Wrapper } from './styles';

const selector = formValueSelector('FilterVisitsSite');

function GraphVisitsSite() {
  const formValues = useSelector((state) =>
    selector(state, 'start_date', 'end_date')
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const { timeseries, fetchTimeseries, response } = useTimeseries('timeseries');

  const { data: aggregate, fetchData: fetchAggregate } = useChart('aggregate');
  const { data: source, fetchData: fetchSource } = useChart('source');
  const { data: device, fetchData: fetchDevice } = useChart('device');

  const handleChange = (values) => {
    if (!values) return null;

    if (isExpanded) {
      fetchSource(values);
      fetchDevice(values);
    }

    fetchTimeseries(values);
    fetchAggregate(values);
  };

  const handleToggleExpanded = () => {
    if (formValues && !isExpanded) {
      fetchSource(formValues);
      fetchDevice(formValues);
    }

    setIsExpanded((state) => !state);
  };

  if (response?.status === STATUS.NO_CONTENT) {
    return <Nodata />;
  }

  return (
    <Wrapper>
      <Loading isVisible={false} isFullComponent />
      <Header>
        <Title>Visitas no site</Title>
        <Filter
          initialValues={{
            start_date: moment().subtract(8, 'days').format(format.date),
            end_date: moment().subtract(1, 'days').format(format.date),
          }}
          onChange={handleChange}
        />
      </Header>
      <Content>
        <Graph timeseries={timeseries} aggregate={aggregate} />
        <Expand onClick={handleToggleExpanded}>
          {isExpanded ? '- DETALHES' : '+ DETALHES'}
        </Expand>
        {isExpanded && (
          <ExpandedDetails>
            <Row>
              <Col xs={6}>
                <GraphBoxBar
                  title="Origem do Tráfego"
                  text="Origem"
                  data={source}
                  renderItem={(item) => (
                    <>
                      <img
                        src={`https://analytics.gerenciarimoveis-cf.com.br/favicon/sources/${item.source}`}
                        alt="source"
                      />{' '}
                      {item.source}
                    </>
                  )}
                />
              </Col>
              <Col xs={6}>
                <GraphBoxBar
                  title="Acesso por dispositivo"
                  text="Dispositivo"
                  data={device}
                  valueKey="visitors"
                  labelKey="device"
                  renderDataValue={(item) =>
                    `${item.visitors} (${item?.percentage}%)`
                  }
                />
              </Col>
            </Row>
          </ExpandedDetails>
        )}
      </Content>
    </Wrapper>
  );
}

export default GraphVisitsSite;
