import React from 'react';
// Components
import { FieldInterval } from 'components/Form';

const ExclusivityField = (props) => {
  return (
    <FieldInterval
      title="Período de exclusividade"
      nameMax="by_exclusive_until_lower_equals"
      nameMin="by_exclusive_until_greater_equals"
      {...props}
    />
  );
};

export default ExclusivityField;
