import api from 'services/index';

/**
 * Pega os dados da descrição
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const get = buildingId =>
  api.getList(`settings/sites/buildings/${buildingId}/description`);

/**
 * Atualiza os dados da descrição
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = ({ id, ...values }) =>
  api.patch(`settings/sites/buildings/${id}/description`, values);
