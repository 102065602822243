import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Service
import * as portalsService from 'services/settings/portals';
// HOC
import { withFormData } from 'HOC/withFormData';
import Form from './containers/Form';
import Success from 'containers/ModalRoot/ModalPortalAdd/containers/Success';

const pages = {
  Form: {
    size: 460,
    component: Form,
  },
  Success: {
    size: 735,
    component: Success,
  },
};

class ModalPortal extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    portal: PropTypes.object.isRequired,
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null,

    portal: {
      portal_real_estate: {
        id: null,
      },
    },
    formData: {
      data: {
        portal_options: [],
      },
    },
  };

  state = {
    page: 'Form',
    portal: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  /**
   * Busca as informações do portal
   * @return {Promise<void>}
   */
  fetchData = async () => {
    const { portal, requestFormData, receiveFormData } = this.props;

    requestFormData();

    // Verifica se a imobiliaria já se conectou com esse portal
    // se existir portal_real_estate_id significa que já possui uma conexão
    if (portal?.portal_real_estate?.id) {
      // Busca as informações da relação
      const { data } = await portalsService.getOneRelation(
        portal?.portal_real_estate?.id
      );

      receiveFormData({
        ...data,
        options: _.keyBy(data.options, 'portal_option_id'),
        portal_id: data.id,
        portal_real_estate: {
          id: portal.portal_real_estate?.id,
        },
      });

      return;
    }

    const { data } = await portalsService.getOne(portal.id);
    receiveFormData(data);
  };

  handleSubmit = async (values) => {
    if (values?.portal_real_estate?.id) {
      return portalsService.update(values);
    }

    return portalsService.create(values);
  };

  setPage = (page) => {
    this.setState({ ...this.state, page });
  };

  setPortal = (portal) => {
    this.setState({ ...this.state, portal });
  };

  changePage = (page) => () => {
    this.setPage(page);
  };

  onSubmitSuccess = (res) => {
    if (this.props.onSubmitSuccess) this.props.onSubmitSuccess(res);

    if (res.config.method === 'put') {
      this.props.handleClose();
      return false;
    }
    this.setPortal(res.data);
    this.setPage('Success');
  };

  onSubmitFail = (...rest) => {
    if (this.props.onSubmitFail) this.props.onSubmitFail(...rest);
  };

  get initialValues() {
    return this.props.formData.data;
  }

  get options() {
    try {
      return this.props.formData.data.portal_options;
    } catch {
      return [];
    }
  }

  render() {
    const {
      modalConfig,
      isOpen,
      handleClose,
      modalType,

      portal,
      formData: { isFetching },
    } = this.props;

    const { page } = this.state;

    const currentPage = pages[page];

    return (
      <Modal
        {...modalConfig}
        isFetching={isFetching}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
        style={{ content: { maxWidth: currentPage.size } }}
      >
        {React.createElement(currentPage.component, {
          modalConfig,
          isFetching,
          isOpen,
          modalType,
          handleClose,
          changePage: this.changePage,
          portal,
          currentPortal: this.state.portal,
          setPortal: this.setPortal,
          options: this.options,
          initialValues: this.initialValues,
          handleSubmit: this.handleSubmit,
          onSubmitSuccess: this.onSubmitSuccess,
          onSubmitFail: this.onSubmitFail,
        })}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormData('portal')
)(ModalPortal);
