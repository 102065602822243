import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { change, formValueSelector, reduxForm } from 'redux-form';
// Lib
import { scrollToFirstError } from 'lib/formHelpers';
// Components
import FixedBar from 'components/FixedBar';
import Wrapper from 'components/Wrapper';
import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
import Aside from './components/Aside';
// Modules
import {
  getInitialState,
  handleFormSubmit,
  typesSelector,
  getCity,
  getNeighborhood,
  getState,
} from 'modules/propertyAdd';
// import { openModalCondominium } from 'modules/modal';
import { indexDefaultSelector } from 'modules/financialIndex';
import { currentUserSelector } from 'modules/login';
// Validate
import validate from './validate';
import asyncValidate from './asyncValidate';
// Form Sections
import * as Section from './formSections';
import { scrollToElement } from 'lib/animation-helpers';
import { createMetaTitle } from './helpers';

// valor padrao do campo de subtipo
const subtypeDefault = {
  label: null,
  value: null,
  group: {
    id: null,
    label: null,
    title: null,
    area_fields: [],
    subtypes: [],
    rooms_fields: [],
    information_fields: [],
  },
};

const propTypes = {
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  getInitialState: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  subtype: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    group: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      title: PropTypes.string,
      area_fields: PropTypes.array,
      subtypes: PropTypes.arrayOf(PropTypes.object),
      rooms_fields: PropTypes.arrayOf(PropTypes.object),
      information_fields: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

const defaultProps = {
  subtype: subtypeDefault,
};

class PropertyAdd extends Component {
  constructor(props) {
    super(props);

    // cria uma instancia do evento do redux-form change
    this.change = change.bind(null, 'propertiesAdd');
    this.changeField = this.changeField.bind(this);
  }

  async componentDidMount() {
    if (this.props.match.params) {
      const { property_id } = this.props.match.params;
      await this.props.getInitialState(property_id).catch(() => {
        Alert.success('Problema ao buscar os dados do imóvel');
        this.props.history.push('/properties/search');
      });

      if (this.props?.location?.hash) {
        scrollToElement('.js-location', '.Wrapper__inner', { marginTop: 15 });
      }

      return false;
    }

    // pega os dados iniciais do formulario
    this.props.getInitialState();

    return true;
  }

  get isEditting() {
    return !!this.props?.match?.params?.property_id;
  }

  get typeId() {
    try {
      return this.props.subtype.group.id;
    } catch {
      return null;
    }
  }

  get subtype() {
    try {
      return this.props.subtype;
    } catch {
      return null;
    }
  }

  updateMetaTitle = () => {
    if (this.isEditting) return null;

    const { change, transaction, subtype, city, state, neighborhood } =
      this.props;

    const metaTitle = createMetaTitle({
      subtype,
      transaction,
      city,
      state,
      neighborhood,
    });

    // Modifica com base nos valores preenchidos
    change('meta_title', metaTitle);
  };

  updateTitle = () => {
    if (this.isEditting) return null;
    const { change, subtype } = this.props;

    const title = createMetaTitle({
      subtype,
    });

    change('title', title);
  };

  /**
   * Muda um campo do cadastro de imovel
   * @param field
   * @param value
   */
  changeField(field, value) {
    this.props.dispatch(this.change(field, value));
  }

  /**
   * Retorna true se a transação selecionada for aluguel
   * @return {boolean}
   */
  get isTransactionRent() {
    const { transaction } = this.props;
    return transaction === '2' || transaction === 2;
  }

  render() {
    const {
      subtype,
      change,
      condominium_id,
      selector,
      submitting,
      handleSubmit,
      handleFormSubmit,
    } = this.props;

    const hasSubtype = subtype && !!subtype.value;
    const hasCondo = !!condominium_id;
    const isRent = this.isTransactionRent;

    return (
      <Wrapper
        full
        id={'PropertyAddForm'}
        styleContainer={{ paddingBottom: 20 }}
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Helmet>
          <title>Imóvel</title>
        </Helmet>
        <Aside
          condoId={condominium_id}
          navigateToSection={this.navigateToSection}
          hasSubtype={hasSubtype}
          isRent={isRent}
        />
        <Wrapper.inner>
          <Wrapper.container style={{ paddingBottom: 59 }}>
            <MainTitle
              title="Cadastro de imóveis"
              text={`
                Cadastre seu imóvel com atenção e dê as melhores
                informações possíveis para o seu cliente
              `}
            />
            <Section.Info
              updateMetaTitle={this.updateMetaTitle}
              updateTitle={this.updateTitle}
              typeId={subtype.group.id}
              selector={selector}
              fields={subtype.group.information_fields}
              changeField={this.changeField}
              change={change}
            />
            <Section.Condos change={change} changeField={this.changeField} />
            {condominium_id && (
              <Section.CondoCharacteristics condoId={condominium_id} />
            )}
            <Section.Characteristics
              isVisible={hasSubtype}
              typeId={subtype.group.id}
              changeField={this.changeField}
            />
            <Section.Establishments
              hasCondo={hasCondo}
              changeField={this.changeField}
            />
            <Section.Rooms
              isVisible={hasSubtype}
              fields={subtype.group.rooms_fields}
            />
            <Section.Location
              updateMetaTitle={this.updateMetaTitle}
              hasCondo={hasCondo}
              changeField={this.changeField}
              fields={subtype.group.information_fields}
            />
            <Section.Measures
              isVisible={hasSubtype}
              fields={subtype.group.area_fields}
            />
            <Section.Price
              updateMetaTitle={this.updateMetaTitle}
              change={change}
            />
            {isRent && <Section.Season change={change} />}
            <Section.Description
              changeField={this.changeField}
              isEditting={this.isEditting}
            />
            <Section.Seo changeField={this.changeField} />
            <Section.Video />
            <Section.Tour />
            <Section.CadastralSituations changeField={this.changeField} />
            <Section.PrivateData changeField={this.changeField} />
            <FixedBar style={{ left: 242 }}>
              <Button
                color="white"
                colorText="primary"
                onClick={this.props.history.goBack}
              >
                Cancelar
              </Button>
              <span className="h-flex__cell--grow" />
              <Button
                type="submit"
                color="success"
                disabled={submitting}
                onClick={handleSubmit((values, dispatch, props) =>
                  handleFormSubmit(
                    {
                      ...values,
                      button: 'submit',
                    },
                    props
                  )
                )}
              >
                {this.isEditting ? 'Editar imóvel' : 'Finalizar Cadastro'}
              </Button>
            </FixedBar>
          </Wrapper.container>
        </Wrapper.inner>
      </Wrapper>
    );
  }
}

PropertyAdd.defaultProps = defaultProps;
PropertyAdd.propTypes = propTypes;

PropertyAdd = reduxForm({
  form: 'propertiesAdd',
  validate,
  asyncValidate,
  touchOnBlur: true,
  touchOnChange: true,
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  asyncBlurFields: ['reference'],
  onChange: (values) => {},
  onSubmitFail: scrollToFirstError('.Wrapper__inner'),
})(PropertyAdd);

export const selector = formValueSelector('propertiesAdd');

const mapStateToProps = (state) => {
  const indexId = indexDefaultSelector(state).id;
  const currentUser = currentUserSelector(state);

  return {
    selector: (field) => selector(state, field),
    comodos: selector(state, 'comodos'),
    financialIndex: indexId,
    subtype: selector(state, 'subtype_id') || subtypeDefault,
    characteristicsForm: selector(state, 'characteristics'),
    condominium_id: selector(state, 'condominium_id'),
    transaction: selector(state, 'transaction'),
    types: typesSelector(state),
    state: getState(state),
    city: getCity(state),
    neighborhood: getNeighborhood(state),
    initialValues: {
      is_shared_address: false,
      is_exact_map_shown: false,
      is_map_shown: false,
      is_streetview_shown: false,
      user_id: currentUser.realtor ? currentUser.id : null,
      transaction: localStorage.getItem('transaction') || '1',
      comission_type: 'R$',
      ...state.propertyAdd.reducer.formData,
    },
  };
};

const mapDispatchToProps = {
  getInitialState,
  handleFormSubmit,
  // openModalCondominium
};

export default connect(mapStateToProps, mapDispatchToProps, null)(PropertyAdd);
