import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
// Components
import FormGroup from '../FormGroup';
import Complex from './components/Complex';
import Simple from './components/Simple';

const Components = {
  complex: Complex,
  simple: Simple
};

function Textarea({
  xs,
  sm,
  md,
  lg,
  label,
  labelRender,
  required,
  disabled,
  maxLength,
  type,
  input,
  onFocus,
  onBlur,
  growOnFocus,
  growHeight,
  meta,
  height,
  canExceed,
  style,
  formGroupClassName,
  position = 'bottom',
  ...props
}) {
  const [isFocused, setFocused] = useState(false);

  const _style = useMemo(() => {
    let styleObj = style;

    let _height = height;

    if (growOnFocus) {
      _height = !isFocused ? _height : growHeight;
    }

    return {
      ...styleObj,
      height: _height,
      transition: 'all .2s'
    };
  }, [isFocused, growOnFocus, growHeight, height, style]);

  const handleFocus = useCallback(
    event => {
      if (onFocus) onFocus(event);
      setFocused(true);
    },
    [setFocused, onFocus]
  );

  const handleBlur = useCallback(
    event => {
      if (onBlur) onBlur(event);
      setFocused(false);
    },
    [setFocused, onBlur]
  );

  const inputProps = useMemo(() => {
    let _props = {
      ...props,
      disabled,
      input,
      meta,
      isFocused,
      onFocus: handleFocus,
      onBlur: handleBlur,
      style: _style
    };

    if (!canExceed) {
      _props = {
        ..._props,
        maxLength
      };
    }

    return _props;
  }, [
    disabled,
    input,
    meta,
    isFocused,
    handleFocus,
    handleBlur,
    _style,
    canExceed,
    maxLength
  ]);

  return (
    <FormGroup
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      maxLength={maxLength}
      labelText={label}
      isRequired={required}
      isDisabled={disabled}
      input={input}
      meta={{
        ...meta,
        active: type === 'simple' ? meta.active : isFocused
      }}
      labelRender={labelRender}
      className={classnames({
        [`toolbar--${position}`]: position
      })}
      formGroupClassName={formGroupClassName}
    >
      {React.createElement(Components[type], inputProps)}
    </FormGroup>
  );
}

Textarea.defaultProps = {
  type: 'complex',
  theme: 'snow',
  label: null,
  height: 120,
  required: false,
  maxLength: null,
  canExceed: false,
  className: null,
  placeholder: 'Digite a observação',
  autoFocus: false,
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
      ['link'],
      ['clean']
    ]
  }
};

export default React.memo(Textarea);
