import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: -15px;
`;

export const WrapVideo = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

export const BtnRemove = styled.button`
  width: 24px;
  height: 24px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
  background: ${p => p.theme.colors.danger};
  color: #fff;
  cursor: pointer;
`;
