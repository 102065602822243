import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from 'recharts';
import { Wrapper } from './styles';
import { getColorByIndex } from 'lib/chart-helpers';

const PeopleChart = ({ data, meta }) => {
  return (
    <Wrapper>
      <ResponsiveContainer>
        <BarChart width={600} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="user" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="total">
            {data.map((entry, index) => (
              <Cell fill={getColorByIndex(index)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default PeopleChart;
