import React from 'react';
import PropTypes from 'prop-types';
// Components
import Plan from './Plan';

class ListPlans extends React.Component {
  get options() {
    const { users, options, isSelectedBillet } = this.props;

    if (isSelectedBillet) {
      return options.map(month => {
        if (month.value === 1 && users < 3) {
          return { ...month, disabled: true };
        }

        return month;
      });
    }

    return options;
  }
  get renderPlans() {
    const {
      receivingMethod,
      users,
      options,
      input: { value: selected, onChange }
    } = this.props;
    if (!options) return null;

    return this.options.map(({ name, value, disabled }) => (
      <Plan
        receivingMethod={receivingMethod}
        key={`${name}-${value}`}
        disabled={disabled}
        selected={selected}
        name={name}
        value={value}
        users={users}
        onClick={() => {
          onChange(value);
        }}
      />
    ));
  }
  render() {
    return <ul className="ListPlans">{this.renderPlans}</ul>;
  }
}

ListPlans.propTypes = {
  isSelectedBillet: PropTypes.bool,
  users: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number
    })
  )
};
ListPlans.defaultProps = {
  isSelectedBillet: false,
  users: 1,
  options: [
    { name: 'Anual', value: 12 },
    { name: 'Semestral', value: 6 },
    { name: 'Trimestral', value: 3 },
    { name: 'Mensal', value: 1 }
  ]
};

export default ListPlans;
