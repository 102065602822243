import React from 'react';
import { Wrapper, Content, Title, Text } from './styles';

const NoContent = ({ image = null, title, text, children, maxContentWidth }) => {
  return (
    <Wrapper>
      <Content maxContentWidth={maxContentWidth}>
        <Title>{title}</Title>
        <Text>{text}</Text>
        {children}
      </Content>
      <img src={image} alt="Postagens sobre região" />
    </Wrapper>
  );
};

export default NoContent;
