import styled, { css } from 'styled-components';
import { Wrapper as Count } from '../Count/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const Header = styled.div`
  padding: 5px 15px;
  background: #edeef2;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
`;
export const Actions = styled.div``;

export const ListCount = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
  justify-items: start;

  ${Count} {
    flex: 1 0;
  }

  ${(p) =>
    p.isHighlight &&
    css`
      padding: 10px;
      background: #d3dceb;
      border-radius: 10px;
    `}
`;

export const Inner = styled.div``;
