import React from 'react';
import { Row } from 'react-flexbox-grid';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { Input } from 'components/Form';

// Containers
import { CountryField, StateField, CityField } from 'containers/LocationFields';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import useFormValue from 'hooks/useFormValue';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function FormAddCity({ handleSubmit, handleClose }) {
  const countryId = useFormValue('country_id');
  const stateId = useFormValue('state_id');

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <CountryField
          required
          xs={6}
          label="País"
          name="country_id"
          stateName="state_id"
        />
        <StateField
          required
          xs={6}
          label="UF"
          name="state_id"
          countryId={countryId}
        />
      </Row>
      <Row>
        <CityField required xs={6} name="city_id" stateId={stateId} />
        <Field
          xs={6}
          required
          label="Bairro"
          name="name"
          component={Input}
          placeholder="Ex.: Centro"
        />
      </Row>

      <ModalFooter style={{ marginTop: '0px' }}>
        <Button color="white" colorText="secondary" onClick={handleClose}>
          Sair
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Cadastrar
        </Button>
      </ModalFooter>
    </form>
  );
}

FormAddCity.defaultProps = defaultProps;
FormAddCity.propTypes = propTypes;

export default reduxForm({
  form: 'FormAddCity',
  enableReinitialize: true
})(FormAddCity);
