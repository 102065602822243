import Button from 'components/Button';
import Dropdown, { Item as DropdownItem, List } from 'components/Dropdown';
import { STATUS_PROPERTY } from 'constants/constants';
import { openModalCondoDelete } from 'modules/modal';
import qs from 'qs';
import { MdExpandMore } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { OptionsWrapper } from './styles';

const Count = ({ condo, name, onClick }) => {
  return (
    <td>
      {condo[name] > 0 ? (
        <span
          className="h-link"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        >
          Ver ({condo[name]})
        </span>
      ) : (
        '--'
      )}
    </td>
  );
};

function Item({ condo, fetchData, canManage }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDeleteCondo = () => {
    dispatch(
      openModalCondoDelete({
        condo,
        onSuccess: fetchData,
      })
    );
  };

  const handleEditCondo = () => {
    history.push(`/condos/update/id/${condo.id}`);
  };

  const address = `${condo.street_address ? `${condo.street_address} - ` : ''}${
    condo.neighborhood?.city?.name ?? ''
  }/${condo.neighborhood?.city?.state?.acronym ?? ''}`;

  const commonFilters = {
    filter: {
      condominium_id: [condo.id],
    },
    limit: 50,
    offset: 1,
    sort: '-calculated_price',
  };

  const handleOpenSearchByStatus = (status) => {
    const filter = qs.stringify({
      ...commonFilters,
      filter: {
        ...commonFilters.filter,
        status,
        without_draft_scope: true,
      },
    });

    history.push(`/properties/search?${filter}`);
  };

  const handleSearchByAvailable = () => {
    handleOpenSearchByStatus(STATUS_PROPERTY.AVAILABLE);
  };

  const handleSearchByRented = () => {
    handleOpenSearchByStatus(STATUS_PROPERTY.RENTED);
  };

  const handleSearchBySold = () => {
    handleOpenSearchByStatus(STATUS_PROPERTY.SOLD);
  };

  const handleSearchByExcluded = () => {
    handleOpenSearchByStatus(STATUS_PROPERTY.EXCLUDED);
  };

  return (
    <tr>
      <td>
        <p>
          <strong>{condo.title}</strong>
        </p>
        <p>{address}</p>
      </td>
      <Count
        name="available_and_draft_properties_count"
        onClick={handleSearchByAvailable}
        condo={condo}
      />
      <Count
        name="rented_properties_count"
        onClick={handleSearchByRented}
        condo={condo}
      />
      <Count
        name="sold_properties_count"
        onClick={handleSearchBySold}
        condo={condo}
      />
      <Count
        name="excluded_properties_count"
        onClick={handleSearchByExcluded}
        condo={condo}
      />
      {canManage && (
        <OptionsWrapper>
          <Dropdown isDropHover>
            <Button color="white" size="medium">
              Opções <MdExpandMore />
            </Button>
            <List>
              <DropdownItem>
                <button onClick={handleEditCondo}>Editar</button>
              </DropdownItem>
              <DropdownItem>
                <button onClick={handleDeleteCondo}>Excluir</button>
              </DropdownItem>
            </List>
          </Dropdown>
        </OptionsWrapper>
      )}
    </tr>
  );
}

export default Item;
