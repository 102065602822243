import api from 'services';
import Alert from 'react-s-alert';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { createModule } from 'lib/reducer-helpers';
// Service
import * as propertiesService from 'services/properties';
// Modules
import { Selectors as LocationSelector } from 'containers/LocationFields/module';
import { getProperties } from 'modules/properties';
import { openModalProperty } from 'modules/modal';
import { hideLoading, showLoading } from 'modules/loading';
// Reducers
import characteristics from './containers/Characteristics/module';
import condosCharacteristics from './containers/CondosCharacteristics/module';
import condominium from './containers/Condominium/module';
import establishments from './containers/Establishments/module';
import financialIndexes from './containers/FinancialIndexes/module';
import realtors from './containers/Realtors/module';
import typesAndSubtypes from './containers/TypesAndSubtypes/module';
import { formValueSelector } from 'redux-form';
import { updateSearch } from 'lib/url-helpers';

// Retorna o id da cidade selecionada no formulário
export const cityIdSelector = form => state => {
  const _selector = formValueSelector(form);
  return _selector(state, 'by_city_id');
};

// Retorna a cidade selecionada
export const citySelector = (formName = 'FilterProperties', cityKey = 'city') =>
  createSelector(
    [LocationSelector.getData(formName, cityKey), cityIdSelector(formName)],
    (cities, cityId) => {
      try {
        return cities.find(city => city.id === cityId);
      } catch {
        return { name: 'Cidade' };
      }
    }
  );

const createAction = createModule('form');

const SEARCH_START = createAction('SEARCH_START');
const SEARCH_END = createAction('SEARCH_END');
const SHOW_REFERENCE = createAction('SHOW_REFERENCE');
const HIDE_REFERENCE = createAction('HIDE_REFERENCE');

export const getIsSearching = state => state.filter.form.isSearching;
export const getIsReference = state => state.filter.form.isReference;

const initialValues = {
  isSearching: false,
  isReference: false
};

const reducerForm = (state = initialValues, action) => {
  switch (action.type) {
    case SEARCH_START:
      return { ...state, isSearching: true };
    case SEARCH_END:
      return { ...state, isSearching: false };
    case SHOW_REFERENCE:
      return { ...state, isReference: true };
    case HIDE_REFERENCE:
      return { ...state, isReference: false };
    default:
      return state;
  }
};

export const Actions = {
  searchStart: () => ({ type: SEARCH_START }),
  searchEnd: () => ({ type: SEARCH_END }),
  showReference: () => ({ type: SHOW_REFERENCE }),
  hideReference: () => ({ type: HIDE_REFERENCE })
};

export const handleSubmitReference = async (values, dispatch) => {
  try {
    const { data: property } = await propertiesService.getReference(
      values.reference
    );

    // Abre a modal do imóvel se achar
    openModalProperty({ property })(dispatch);

    // Esconde a referência do imóvel
    dispatch(Actions.hideReference());

    return property;
  } catch {
    Alert.success('Esse imóvel não existe!');
  }
};

/**
 * Lida com o evento de submit do formulário de filtro de imóveis
 * @param values
 * @param include
 * @param portalRealEstateId
 * @return {Function}
 */
export const handleSubmit = (
  values,
  include = '',
  portalRealEstateId
) => async (dispatch, getState) => {
  let sort = null;
  let currentValues = values;

  // Remove o filtro caso tenha ordenação
  if (currentValues?.sort) {
    sort = currentValues?.sort;
    delete currentValues.sort;
  }

  // Quando tiver referência
  // ignora todos os outros valores a serem filtrados
  if (currentValues?.reference) {
    currentValues = {
      reference: currentValues.reference
    };
  }

  // Marca que está procurando a pesquisa
  dispatch(Actions.searchStart());

  let params = { filter: currentValues, include, sort };

  // Atualiza a queryString sempre que da um submit
  updateSearch({ initialValues: currentValues });

  if (portalRealEstateId) {
    params = {
      sort: 'calculated_price,by_type_name,id',
      ...params,
      with_grouped_condos: ''
    };
  }

  return getProperties(params)(dispatch, getState);
};

/**
 * Evento para salvar a pesquisa
 * @param values
 * @return {function(*): Promise<any>}
 */
export const handleSaveProfile = values => async dispatch => {
  dispatch(showLoading());

  try {
    return await api.create(
      '/people/search-profiles/by-property-filter',
      values
    );
  } finally {
    dispatch(hideLoading());
  }
};

export default combineReducers({
  form: reducerForm,
  characteristics,
  condosCharacteristics,
  condominium,
  establishments,
  financialIndexes,
  realtors,
  typesAndSubtypes
});
