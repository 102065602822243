import ReactTooltip from 'react-tooltip';
import { getFormValues, reset } from 'redux-form';
// Helpers
import { createModule } from '../lib/reducer-helpers';
// Modules
import { setPagination as setPaginationP } from './pagination';
// Services
import * as people from '../services/people';
// Lib
import { openModalPersonRemove } from 'modules/modal';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import React from 'react';

// Actions
const createAction = createModule('people');
const REQUEST_PEOPLE = createAction('REQUEST');
const RECEIVE_PEOPLE = createAction('RECEIVE');

const ADD_PERSON = createAction('ADD_PERSON');
const ALTER_PERSON = createAction('ALTER_PERSON');

const REQUEST_PERSON_DELETE = createAction('REQUEST_DELETE');
const RECEIVE_PERSON_DELETE = createAction('RECEIVE_DELETE');

const SET_SEARCH = createAction('SET_SEARCH');
const SET_PAGINATION = createAction('SET_PAGINATION');
const SET_IS_SEARCHING = createAction('SET_IS_SEARCHING');
const UNSET_IS_SEARCHING = createAction('UNSET_IS_SEARCHING');

export const peopleSelector = (state) => state.people.people;
export const peopleIsFetching = (state) => state.people.people;

// Initial State
const initialState = {
  people: [],
  isFetching: false,
  search: [],
  pagination: {
    total_pages: 0,
    hasMore: false,
  },
  deleting: {
    id: null,
    name: null,
  },
  isSearching: false,
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_SEARCHING: {
      return {
        ...state,
        isSearching: true,
      };
    }
    case UNSET_IS_SEARCHING: {
      return {
        ...state,
        isSearching: false,
      };
    }
    case REQUEST_PEOPLE: {
      return {
        ...state,
        isFetching: true,
        isSearching: true,
      };
    }
    case RECEIVE_PEOPLE: {
      return {
        ...state,
        people: action.people,
        isFetching: false,
      };
    }
    case ADD_PERSON: {
      return {
        ...state,
        people: [action.person, ...state.people],
      };
    }
    case ALTER_PERSON: {
      const currentPeople = state.people.map((person) => {
        if (person.id === action.person.id) {
          return {
            ...action.person,
          };
        }
        return person;
      });

      return {
        ...state,
        people: currentPeople,
      };
    }
    case REQUEST_PERSON_DELETE: {
      const people = state.people.map((person) => {
        if (action.id === person.id) {
          return {
            ...person,
            isDeleting: true,
          };
        }

        return person;
      });

      return {
        ...state,
        people,
      };
    }
    case RECEIVE_PERSON_DELETE: {
      const currentPeople = state.people.filter(
        (person) => person.id !== action.id
      );

      return {
        ...state,
        people: currentPeople,
        deleting: {
          ...state.deleting,
          id: action.id,
          name: action.name,
        },
      };
    }
    case SET_SEARCH: {
      return {
        ...state,
        search: action.values,
      };
    }
    case SET_PAGINATION: {
      return {
        ...state,
        pagination: action.pagination,
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function requestPeople() {
  return { type: REQUEST_PEOPLE };
}

export function receivePeople(people) {
  return { type: RECEIVE_PEOPLE, people };
}

export function receivePerson(person) {
  return { type: ADD_PERSON, person };
}

export function requestPersonDelete(id) {
  return { type: REQUEST_PERSON_DELETE, id };
}

export function receivePersonDelete(id) {
  return { type: RECEIVE_PERSON_DELETE, id };
}

export function alterPerson(person) {
  return { type: ALTER_PERSON, person };
}

export function setSearch(values) {
  return { type: SET_SEARCH, values };
}

export function setPagination(pagination) {
  return { type: SET_PAGINATION, pagination };
}

export function setIsSearching() {
  return { type: SET_IS_SEARCHING };
}

export function unsetIsSearching() {
  return { type: UNSET_IS_SEARCHING };
}

export const isSearchingSelector = (state) => state.people.isSearching;

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getPeople = (params) => (dispatch, getState) => {
  // inicia o request indicando que esta procurando dados
  dispatch(requestPeople());

  // pega os valores do filtro de pesquisa do cliente
  let formValues = getFormValues('PeopleFilterForm')(getState());

  if (
    formValues &&
    formValues.filter &&
    formValues.filter.by_user_id === null
  ) {
    delete formValues.filter.by_user_id;
  }

  return people
    .getSearch({
      limit: 50,
      offset: 1,
      count: 'relateds',
      ...params,
      include: 'relateds,primary_phone,user,groups',
      formValues: {
        ...formValues,
        filter: {
          ...params?.filter,
          ...formValues?.filter,
        },
      },
    })
    .then(({ data, meta: { pagination } }) => {
      dispatch(setPagination(pagination));
      dispatch(setPaginationP('people', pagination));
      dispatch(receivePeople(data));
      ReactTooltip.rebuild();
    });
};

export const addPerson = (person) => (dispatch) => {
  ReactTooltip.rebuild();
  dispatch(receivePerson(person));
};

export const updatePerson = (person) => (dispatch) => {
  dispatch(alterPerson(person));
};

export const reverseDelete = (id) => (dispatch, getState) =>
  people.reactivate(id).then(({ data }) => {
    getPeople()(dispatch, getState);
    return data;
  });

export const clearSearch = () => (dispatch) => {
  dispatch(reset('PeopleFilterForm'));
  setTimeout(() => {
    dispatch(unsetIsSearching());
  }, 900);
};

export const inativePerson = (person) => async (dispatch, getState) => {
  dispatch(
    openConfirmation({
      size: '460px',
      title: `Deseja mover este cliente para lista de inativos?`,
      text: (
        <>
          <div>Ele deixará de aparecer nas listas de seleção de clientes</div>
          <div>
            Negócios existentes no CRM serão fechados e os perfis desativados
          </div>
        </>
      ),
      request: () => {
        return people.inactiveStatus(person.id);
      },
      onSuccess: () => {
        dispatch(getPeople());
      },
      submitButtonText: 'Inativar',
      submitButtonColor: 'danger',
    })
  );
};

export const activePerson = (person) => async (dispatch, getState) => {
  dispatch(
    openConfirmation({
      title: `Reativar cliente`,
      text: `Tem certeza que deseja reativar o cliente: ${person.name}?`,
      request: () => {
        return people.reactivateStatus(person.id);
      },
      onSuccess: () => {
        dispatch(getPeople());
      },
      submitButtonText: 'Reativar',
      submitButtonColor: 'success',
    })
  );
};

export const deletePerson = (id) => async (dispatch, getState) => {
  const { people: currentPeople, search, pagination } = getState().people;

  dispatch(requestPersonDelete(id));

  dispatch(
    openModalPersonRemove({
      id,
      onSuccess: () => {
        dispatch(receivePersonDelete(id));

        // quando não tiver mais nenhuma
        if (currentPeople.length === 1 && pagination.total_pages > 1) {
          dispatch(
            setSearch({
              ...search,
              offset: search.offset - 1,
              limit: 50,
            })
          );
        }
      },
      onCancel: () => getPeople()(dispatch, getState),
      onFinally: () => getPeople()(dispatch, getState),
    })
  );
};
