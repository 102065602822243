import { FieldBool, FieldInterval } from 'components/Form';
import * as Filters from 'components/PropertyFilters';
import { useModalPropertySearch } from 'containers/ModalRoot/ModalPropertySearch/context';
import { realtorsSelector, usersSelector } from 'modules/modalPropertySearch';
import { Col, Row } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import { Wrapper } from './styles';

function AdvancedSearch() {
  const { handleUpdateCount } = useModalPropertySearch();
  const { data: users, isFetching } = useSelector(usersSelector);
  const realtors = useSelector(realtorsSelector);

  return (
    <Wrapper>
      <Row>
        <Col xs={3}>
          <Filters.Realtors
            onChange={handleUpdateCount}
            isFetching={isFetching}
            options={realtors}
          />
          <Filters.Agent
            onChange={handleUpdateCount}
            isFetching={isFetching}
            options={users}
          />
          <FieldBool
            canRemoveSelf
            label="Averbado"
            name="is_property_titled"
            onChange={handleUpdateCount}
          />
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            label="Escriturado"
            name="is_deeded"
          />
        </Col>
        <Col xs={3}>
          <FieldInterval
            onChange={handleUpdateCount}
            title="Período de cadastro"
            nameMax="created_lower_equals"
            nameMin="created_greater_equals"
          />
          <FieldInterval
            onChange={handleUpdateCount}
            title="Período de modificação"
            nameMax="updated_lower_equals"
            nameMin="updated_greater_equals"
          />
          <Filters.Revision onChange={handleUpdateCount} />
          <Filters.Exclusivity onChange={handleUpdateCount} />
        </Col>
        <Col xs={3}>
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            label="Visível no site?"
            name="is_published"
          />
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            label="Mostra valor no site?"
            name="is_price_shown"
          />
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            label="Com exclusividade?"
            name="by_exclusivity"
          />
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            label="E-mail mensal ativado?"
            name="should_send_owner_report"
          />
        </Col>
        <Col xs={3}>
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            label="Autorização formalizada?"
            name="has_owner_authorization"
          />
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            label="Com placa?"
            name="has_sale_card"
          />
          <Filters.FinancialIndexes onChange={handleUpdateCount} />
        </Col>
      </Row>
    </Wrapper>
  );
}

export default AdvancedSearch;
