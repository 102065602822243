import React, { useCallback, useContext, useMemo } from 'react';
import { Col } from 'react-flexbox-grid';
// Components
import MenuProperties from '../MenuItems/MenuProperties';
import MenuPeople from '../MenuItems/MenuPeople';
import MenuSchedule from '../MenuItems/MenuSchedule';
import MenuCrm from '../MenuItems/MenuCrm';
import MenuReports from '../MenuItems/MenuReports';
import MenuHelp from '../MenuItems/MenuHelp';
import MenuSite from '../MenuItems/MenuSite';
import { MainHeaderContext } from 'components/MainHeader2/index';
import { useDispatch, useSelector } from 'react-redux';
import { resellerSelector } from 'modules/login';
import { openModalSupport } from 'modules/modal';
import ItemNav from 'components/MainHeader2/components/ItemNav';
import MenuFinancial from 'components/MainHeader2/components/MenuItems/MenuFinancial';

function MenuLeft() {
  const dispatch = useDispatch();
  const resale = useSelector(resellerSelector);
  const { searchOpen } = useContext(MainHeaderContext);

  const faqUrl = useMemo(() => {
    try {
      return resale.faq_url;
    } catch {
      return null;
    }
  }, [resale]);

  const handleClickSupport = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(openModalSupport());
    },
    [dispatch]
  );

  if (searchOpen) return null;

  return (
    <Col tagName="nav" className="Menu Menu--top Menu--white js-wizard-menu">
      <ItemNav label="Início" to="/dashboard" exact />
      <MenuProperties />
      <MenuPeople />
      <MenuCrm />
      <MenuSchedule />
      <MenuReports />
      <MenuSite />
      <MenuHelp faqUrl={faqUrl} handleClickSupport={handleClickSupport} />
      <MenuFinancial />
    </Col>
  );
}

export default MenuLeft;
