import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import Characteristics from '../../components/Characteristics';
// Modules
import { characteristicsSelector, fetchCharacteristics, typeSelector } from '../../module';

const CharacteristicsContainer = () => {
  const dispatch = useDispatch();
  const typeSelected = useSelector(typeSelector);
  const characteristics = useSelector(characteristicsSelector.getAll);

  useEffect(() => {
    if (typeSelected?.id) {
      dispatch(fetchCharacteristics({ type_id: typeSelected?.id }));
    }
  }, [typeSelected?.id]);

  if (!typeSelected?.id) return null;

  return <Characteristics type={typeSelected} data={characteristics} />;
};

export default memo(CharacteristicsContainer);
