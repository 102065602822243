import React from 'react';
import { Field } from 'redux-form';
import { Select } from 'components/Form';
import * as options from 'constants/options';

const FieldLandType = () => {
  return (
    <Field
      xs={3}
      label="Terreno"
      name="land_type"
      component={Select}
      options={options.LAND_TYPE}
      buttonTemplate
    />
  );
};

export default FieldLandType;
