import api from 'services/index';

export const movimentsMonthlyDashboard = () => {
  return api.get('financial/moviments-monthly-dashboard');
};

export const movimentsMonthlyProjection = () => {
  return api.get('financial/moviments-monthly-projection');
};

export const creditCardBillsMonthlyDashboard = () => {
  return api.get('financial/credit-card-bills-monthly-dashboard');
};
