import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

export const Aside = styled.div`
  width: 280px;
  background: #fff;
  border-right: 1px solid #ecedf2;
  padding: 20px;
  overflow: auto;
  position: relative;
`;

export const Content = styled.div`
  overflow: auto;
  flex: 1 0;
  padding: 0 20px 90px;
`;
