import React from 'react';
import { Field } from 'redux-form';
import FormGroup from 'components/Form/components/FormGroup';
import { Item, Text, Label } from './styles';
import { ThemeProvider } from 'styled-components';

const getLabelColor = ({ properties_count, quantity }) => {
  if (properties_count === quantity) return 'success';
  if (properties_count > quantity) return 'danger';
  return 'secondary';
};

const FieldTypePortal = ({
  label,
  options,
  input,
  meta,
  valueKey = 'portal_option_id',
}) => {
  const handleClick = (option) => (e) => {
    input.onChange(option[valueKey]);
  };

  return (
    <FormGroup labelText={label} meta={meta}>
      {options?.map((option) => {
        return (
          <ThemeProvider
            theme={{
              isActive: input.value === option[valueKey],
            }}
          >
            <Item onClick={handleClick(option)}>
              <Text>{option.name}</Text>
              <Label color={getLabelColor(option)}>
                {option.properties_count}/{option.quantity}
              </Label>
            </Item>
          </ThemeProvider>
        );
      })}
    </FormGroup>
  );
};

function TypePortal({
  label = 'Listar por tipo de destaque',
  options,
  onChange,
}) {
  return (
    <Field
      label={label}
      name="by_portal_option_id"
      options={options}
      component={FieldTypePortal}
      valueKey="portal_option_id"
      onChange={onChange}
    />
  );
}

export default TypePortal;
