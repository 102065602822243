import React, { useState } from 'react';
import { Label, Labels, Title, Wrapper } from './styles';
import { useRequest } from 'hooks/useRequest';
import api from 'services';

function FiltersHistoryContainer({
  logeable_id,
  title = 'Filtros',
  defaultValue = 'all',
  onChange,
}) {
  const { data: filters } = useRequest({
    request: () =>
      api.get('stories/categories', {
        filter: {
          logeable_id: logeable_id,
        },
      }),
  });

  const [value, setValue] = useState(defaultValue);

  const handleClick = (filter) => () => {
    setValue(filter.id);
    if (onChange) onChange(filter.id);
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Labels>
        {[{ id: 'all', name: 'Tudo' }, ...filters]?.map((filter) => {
          const isActive = filter.id === value;

          return (
            <Label
              className={isActive ? 'is-enabled' : 'is-disabled'}
              key={`label-${filter.id}`}
              onClick={handleClick(filter)}
            >
              {filter.name}
            </Label>
          );
        })}
      </Labels>
    </Wrapper>
  );
}

export default FiltersHistoryContainer;
