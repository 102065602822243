import React from 'react';
import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
// import { Wrapper } from './styles';
import { TemplateProvider } from './contexts/TemplateContext';
import Aside from './components/Aside';
import Content from './components/Content';
import Button from 'components/Button';
import { ModalContent } from './styles';

const ModalSiteModel = ({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
}) => {
  return (
    <TemplateProvider handleClose={handleClose}>
      <Modal
        {...modalConfig}
        isFetching={isFetching}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate title={'Modelos de site'} handleClose={handleClose}>
          <ModalContent>
            <Aside />
            <Content />
          </ModalContent>
          <ModalFooter>
            <span className="h-flex__cell--grow" />
            <Button color="white" onClick={handleClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </Modal>
    </TemplateProvider>
  );
};

export default ModalSiteModel;
