import React from 'react';
import qs from 'qs';
import { ThemeProvider } from 'styled-components';
import { IconChat } from './styles';

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

/**
 * Deixa só os digitos do telefone
 * @param phone
 * @returns {string}
 */
export const clearPhone = phone => {
  try {
    return `${phone.replace(/\D/g, '')}`;
  } catch {
    return '';
  }
};

export const shareWhatsapp = (number, text = null) => {
  const query = qs.stringify({ text, phone: clearPhone(number) });
  return `https://api.whatsapp.com/send?${query}`;
};

const ChatBox = ({ isBoxOpen = true }) => {
  return (
    <ThemeProvider
      theme={{
        isBoxOpen
      }}
    >
      <IconChat target="_blank" href={shareWhatsapp('554896049333')}>
        <img src="/img/chat/whatsapp.svg" alt="Icon Chat" />
      </IconChat>
    </ThemeProvider>
  );
};

export default ChatBox;
