import Loading from "components/Loading";
import MainTitle from "components/MainTitle";
import { withId } from "pages/RegionPostStore/HOC/withId";
import { useEffect } from "react";
import { useParams } from "react-router";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import * as locationService from 'services/location';
import FooterActions from "../../components/FooterActions";
import FormLocation from "./components/FormLocation";
import { transformCityResponse, transformNeighborhoodResponse } from "./transformers";
import validate from './validate';
import { finishResponse } from "pages/RegionPostStore/helpers";

const Informations = ({ handleSubmit, submitting, onSubmit, initialize, isEditting }) => {
  const { id, type } = useParams();

  useEffect(() => {
    async function fetchInitialValues() {
      let response;

      if (type === 'city') {
        response = await locationService.getOneCityDescription(id);

        initialize({
          country_id: response.data.city.state.country_id,
          state_id: response.data.city.state_id,
          city_id: response.data.city_id,
          description: response.data.description,
          photos: response.data.photos,
        });
      } else {
        response = await locationService.getOneNeighborhoodDescription(id);

        initialize({
          country_id: response.data.neighborhood.city.state.country_id,
          state_id: response.data.neighborhood.city.state_id,
          city_id: response.data.neighborhood.city.id,
          neighborhood_id: response.data.neighborhood_id,
          description: response.data.description,
          photos: response.data.photos,
        });
      }
    }

    if (id) fetchInitialValues();
  }, [id]);

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />

      <MainTitle
        title="Criar postagem sobre região"
        text="Crie uma nova postagem de região para ser exibida na página dos imóveis"
      />
      <FormLocation isEditting={isEditting} />

      <FooterActions
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />
    </form>
  );
}

export default compose(
  withId,
  reduxForm({
    form: 'RegionInformations',
    validate,
    onSubmit: async (values, dispatch, p) => {
      if (p.type === 'city') {
        return await locationService
          .createOrUpdateCityDescription(values)
          .then(finishResponse(values, dispatch, p));
      }

      if (p.type === 'neighborhood') {
        return await locationService
          .createOrUpdateNeighborhoodDescription(values)
          .then(finishResponse(values, dispatch, p));
      }
    },
    onSubmitSuccess: (response, _, p) => {
      const isCity = p.type === 'city';

      p.initialize(isCity
        ? transformCityResponse(response?.data) 
        : transformNeighborhoodResponse(response?.data)
      );

      if (!p.isEditting) {
        const idKey = isCity ? 'city_id' : 'neighborhood_id';

        p.enableAll();
        p.setId(response?.data[idKey]);
        p.next('images', response?.data[idKey]);
      }
    },
  }),
)(Informations);
