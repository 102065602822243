import styled from 'styled-components';
import { rgba } from 'polished';

export const Wrapper = styled.tr`
  td:last-child {
    padding: 5px 15px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0;
  border-top: 1px dotted ${rgba('#1c0c1e', 0.3)};
`;
