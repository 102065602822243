import React from 'react';
import { connect } from 'react-redux';
// Modules
import { fetchPropertiesForm } from 'modules/components/propertyForms';
// Components
import MainTitle from 'components/MainTitle';
import FormSiteProperties from './components/FormSiteProperties';
// Service
import * as propertyFormsService from 'services/sites/propertyForms';
import { withHighlightedButtons, withRequiredFields } from './helpers';

class Properties extends React.Component {
  state = {
    formData: {},
  };

  componentDidMount() {
    this.props.fetchPropertiesForm();

    // Atualiza dados do formulário
    propertyFormsService.getOne().then(({ data }) => {
      const newData = {
        ...withHighlightedButtons(data),
        ...withRequiredFields(data),
      };
      this.setState({ formData: newData });
    });
  }

  handleSubmit = (values) => propertyFormsService.update(values);

  render() {
    return (
      <>
        <MainTitle
          title="Formulário de contato"
          text="Defina como será o formulário de contato presente na página de detalhes do imóvel."
        />
        <FormSiteProperties
          component={this.props.component}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          components={this.props.components}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  component: state.components.propertyForms.data,
  isFetching: state.components.propertyForms.meta.isFetching,
});

const mapDispatchToProps = { fetchPropertiesForm };

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
