import Button from 'components/Button';
import {
  FieldBool,
  FieldImage,
  Input,
  Select,
  Textarea,
} from 'components/Form';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { useRequest } from 'hooks/useRequest';
import { parseValueBool } from 'lib/formHelpers';
import { closeModal } from 'modules/modal';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import { Field, reduxForm } from 'redux-form';
import * as superHighlightsService from 'services/sites/superHighlights';
import { WrapperUploadImage } from './styles';
import Menu from 'components/Menu';

const SIZE_INFOS = {
  SuperHighlight1: {
    1: { tip: '1350x440px', aspectRatio: null },
    2: { tip: '615x440px', aspectRatio: null },
    3: { tip: '405x440px', aspectRatio: null },
  },
  SuperHighlight2: {
    1: { tip: '1366x440px', aspectRatio: null },
    2: { tip: '710x440px', aspectRatio: null },
    3: { tip: '473x440px', aspectRatio: null },
  },
};

function ModalSuperhighlightStore({
  handleSubmit,
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  id,
  submitting,
  initialize,
  type,
  superhighlights,
}) {
  const [page, setPage] = useState('desktop');

  const renderPage = () => {
    if (page === 'desktop') {
      return (
        <WrapperUploadImage>
          <FieldImage
            recommended={SIZE_INFOS[type][slidesLength].tip}
            hasRemove={false}
            aspectRatio={SIZE_INFOS[type][slidesLength].aspectRatio}
            imageSize="medium"
            name="image"
            fileUrlField="file_url"
          />
          <div
            className="Form--inline h-margin-top--10"
            style={{ justifyContent: 'flex-start' }}
          >
            <FieldBool
              name="should_apply_watermark"
              label="Aplicar marca d'água"
            />
          </div>
          <p className="h-margin-top--10">
            <strong>Tamanhos recomendados:</strong>
          </p>
          <p>1 Destaque: {SIZE_INFOS[type][1].tip}</p>
          <p>2 Destaques: {SIZE_INFOS[type][2].tip}</p>
          <p>3 Destaques: {SIZE_INFOS[type][3].tip}</p>
        </WrapperUploadImage>
      );
    }

    return (
      <WrapperUploadImage>
        <FieldImage
          // recommended={SIZE_INFOS[type][slidesLength].tip}
          hasRemove={false}
          // aspectRatio={SIZE_INFOS[type][slidesLength].aspectRatio}
          imageSize="medium"
          name="image_mobile"
          fileUrlField="mobile_file_url"
        />
        <div
          className="Form--inline h-margin-top--10"
          style={{ justifyContent: 'flex-start' }}
        >
          <FieldBool
            name="should_apply_watermark"
            label="Aplicar marca d'água"
          />
        </div>
        {/*<p className="h-margin-top--10">
          <strong>Tamanhos recomendados:</strong>
        </p>
        <p>1 Destaque: {SIZE_INFOS[type][1].tip}</p>
        <p>2 Destaques: {SIZE_INFOS[type][2].tip}</p>
        <p>3 Destaques: {SIZE_INFOS[type][3].tip}</p>*/}
      </WrapperUploadImage>
    );
  };

  const slidesLength = useMemo(() => {
    if (!id) return superhighlights.length + 1;
    return superhighlights.length;
  }, [id, superhighlights]);

  const { data, isFetching, fetchData } = useRequest({
    request: () => superHighlightsService.getOne({ id }),
    initialFetch: false,
    initialState: {
      opens_link_on_page: true,
      should_apply_watermark: false,
    },
  });

  const isEditting = id !== null;

  useEffect(() => {
    initialize(data);
  }, [data, initialize]);

  useEffect(() => {
    if (!!id) {
      fetchData();
    }
  }, [isEditting]);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={isEditting ? 'Editando super destaque' : 'Novo super destaque'}
        handleClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={6}>
              <Menu
                modal
                currentPage={page}
                style={{ marginBottom: '10px' }}
                handleClickItem={(currentPage) => {
                  setPage(currentPage);
                }}
              >
                <Menu.item component="desktop">DESKTOP</Menu.item>
                <Menu.item component="mobile">MOBILE</Menu.item>
              </Menu>
              {renderPage()}
            </Col>
            <Col xs={6}>
              <Row>
                <Field label="Título" xs={12} name="title" component={Input} />
              </Row>
              <Row>
                <Field xs={7} label="Link" name={`link`} component={Input} />
                <Field
                  xs={5}
                  label="Abrir link na:"
                  name={`opens_link_on_page`}
                  component={Select}
                  placeholder={''}
                  options={[
                    { label: 'Mesma página', value: true },
                    { label: 'Outra página', value: false },
                  ]}
                  parse={parseValueBool}
                />
              </Row>
              <Row>
                <Field
                  type="simple"
                  xs={12}
                  label="Descrição"
                  name={`description`}
                  component={Textarea}
                  className="h-margin-bottom--15"
                />
              </Row>
            </Col>
          </Row>

          <ModalFooter>
            <Button color="white" onClick={handleClose}>
              Fechar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button color="success" disabled={submitting} type="submit">
              {isEditting
                ? 'Editar super destaque'
                : 'Adicionar super destaque'}
            </Button>
          </ModalFooter>
        </form>
      </ModalTemplate>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalSuperhighlightStore',
  validate: (values) => {
    let errors = {};
    if (!values.id && !values.image) {
      errors.image = ['Imagem é obrigatória'];
      errors._error = ['Imagem é obrigatória'];
    }

    return errors;
  },
  onSubmit: (values) => {
    return superHighlightsService.createOrUpdate(values);
  },
  onSubmitSuccess: (res, dispatch, props) => {
    dispatch(closeModal());
    if (props?.onSubmitSuccess) props?.onSubmitSuccess(res);
  },
})(ModalSuperhighlightStore);
