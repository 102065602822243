import { format, parse } from "date-fns";
import moment from 'moment';

export function formattedDate(value) {
  return format(parse(value, 'dd/MM/yyyy HH:mm:ss', new Date()), 'dd/MM/yyyy');  
}

export function compareDates(apiDate) {

  if (!apiDate) {
    return true;
  }

  const apiMoment = moment(apiDate, 'DD/MM/YYYY HH:mm:ss');

  const currentDate = moment();

  const isGreaterThanCurrent = apiMoment.isAfter(currentDate);

  return isGreaterThanCurrent;
}
