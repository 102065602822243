import React, { useContext } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { WrapItem, Wrapper } from 'components/SortablePropertyGallery/styles';
import CardImage from 'components/CardImageProperty';

export const SortableGalleryContext = React.createContext({
  data: null,
  captionName: null,
  onRemove: null,
  onSortEnd: null,
});

export const useSortableGallery = () => useContext(SortableGalleryContext);

const Item = SortableElement(({ value, itemIndex }) => {
  const { onRemove } = useSortableGallery();

  return (
    <WrapItem>
      <CardImage property={value} onRemove={onRemove} />
    </WrapItem>
  );
});

const List = SortableContainer(({ items }) => (
  <Wrapper>
    {items?.map((value, index) => (
      <Item itemIndex={index} key={value?.id} index={index} value={value} />
    ))}
  </Wrapper>
));

export default function SortableProperties({ data, onRemove, onSortEnd }) {
  return (
    <SortableGalleryContext.Provider
      value={{
        data,
        onRemove,
        onSortEnd,
      }}
    >
      <List
        axis="xy"
        distance={5}
        useDragHandle
        items={data}
        onSortEnd={onSortEnd}
      />
    </SortableGalleryContext.Provider>
  );
}
