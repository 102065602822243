import styled from 'styled-components';

export const Wrapper = styled.td`
  padding: 15px;

  p {
    display: flex;
    align-items: center;

    > svg:first-child {
      margin-right: 4px;
    }

    > svg:last-child {
      margin-left: 4px;
    }
  }

  .h-text-overflow {
    vertical-align: middle;
  }

  div:first-child {
    margin-bottom: 5px;
  }
`;
