import React from 'react';
// import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Form from './components/Form';

import { ModalTemplate } from '../../../components/Modal';
import { plural } from 'lib/text';
import { useRequest } from '../../../hooks/useRequest';
import * as dealsService from 'services/deals';

const ModalDealDelete = ({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  handleFormSubmit,
  stage,
  funnels,
  userId,
  funnelId,
  onSuccess,
  ...props
}) => {
  const { data: funnelsAndStages } = useRequest({
    request: dealsService.getAllStages,
  });

  return (
    <Modal
      {...modalConfig}
      {...props}
      isOpen={isOpen}
      className={{
        base: 'Modal Modal--medium',
      }}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={`Transferir negócios da etapa: "${stage.name}"`}
        handleClose={handleClose}
      >
        <p className="h-margin-bottom--15">
          Selecione uma das etapas existentes para onde as
          <br />{' '}
          <strong>{plural(stage.people_count, 'pessoa', 'pessoas')}</strong> de
          “{stage.name}” serão movidos.
        </p>
        <Form
          handleClose={handleClose}
          currentStage={stage}
          funnelsAndStages={funnelsAndStages}
          stage={stage}
          onSubmit={handleFormSubmit}
          onSuccess={onSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
};

export default ModalDealDelete;
