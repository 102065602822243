import React from 'react';
import { Row } from 'react-flexbox-grid';
import Container from 'components/Container';
import ListOptions from 'components/ListOptions';
import { FieldBool } from 'components/Form';
import FieldTypeAndSubtype from 'containers/FieldTypeAndSubtype';
import Transaction from '../Transaction';
import Price from '../Price';
import Location from '../Location';
import { Wrapper } from './styles';
import useFormValue from 'hooks/useFormValue';

const Profile = ({ formName }) => {
  const withFormProfile = useFormValue('contact_form_with_profile');

  return (
    <Wrapper>
      <Container padding>
        <ListOptions>
          <ListOptions.item
            title="Gerar perfil para os clientes que fizerem contato a partir deste Hotsite?"
            text="Defina se o sistema deve gerar um perfil com as características dos imóveis presentes neste Hotiste."
            renderOptions={() => <FieldBool name="contact_form_with_profile" />}
          >
            {withFormProfile && (
              <>
                <Row style={{ marginTop: '20px' }}>
                  <FieldTypeAndSubtype
                    xs={3}
                    name="type_or_subtype_id"
                    label="Tipo de Negócio"
                  />
                  <Transaction />
                  <Price
                    label="Preço mínimo"
                    name="minimum_price"
                    placeholder="Digite"
                  />
                  <Price
                    label="Preço máximo"
                    name="maximum_price"
                    placeholder="Digite"
                  />
                </Row>
                <Location formName={formName} />
              </>
            )}
          </ListOptions.item>
        </ListOptions>
      </Container>
    </Wrapper>
  );
};

export default Profile;
