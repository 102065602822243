import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
import ReactTooltip from 'react-tooltip';
import moment from '../../../../../lib/moment';
import { CURRENCY_SYMBOL } from '../../../../../constants/options';
import { ModalFooter } from '../../../../../components/Modal';

import {
  FormError,
  Input,
  InputDate,
  Select,
} from '../../../../../components/Form';
import Button from '../../../../../components/Button';

import validate from './validate';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

class FinancialForm extends React.Component {
  componentDidMount() {
    ReactTooltip.rebuild();
  }
  render() {
    const { id, alert, handleClose, error, submitting, handleSubmit } =
      this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Field xs={3} required label="Nome" name="name" component={Input} />
          <Field
            xs={3}
            label="Sigla"
            name="symbol"
            component={Select}
            placeholder=""
            options={CURRENCY_SYMBOL}
          />
          <Field
            valueFormat
            xs={6}
            prefix="R$ "
            type="text"
            required
            label="Preço"
            name="value"
            component={Input}
          />
          <Field
            xs={6}
            required
            label="Alertar para atualizar?"
            name="alert"
            component={Select}
            placeholder={null}
            options={[
              { label: 'Sim', value: 1 },
              { label: 'Não', value: 2 },
            ]}
            onChange={(e, value) => {
              if (value === '2') {
                this.props.change('renew_date', null);
              }
            }}
          />
          <Field
            xs={6}
            required
            label="Quando?"
            name="renew_date"
            minDate={moment()}
            component={InputDate}
            disabled={alert === '2'}
          />
        </Row>
        <FormError>{error}</FormError>
        <ModalFooter>
          <Button color="white" colorText="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            {id ? 'Editar' : 'Cadastrar'}
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

FinancialForm.defaultProps = defaultProps;
FinancialForm.propTypes = propTypes;

const selector = formValueSelector('FinancialForm');

export default connect((state, ownProps) => ({
  initialValues: ownProps.initialValues,
  alert: selector(state, 'alert'),
}))(
  reduxForm({
    form: 'FinancialForm',
    validate,
    enableReinitialize: true,
  })(FinancialForm)
);
