import React from 'react';
import classnames from 'classnames';
// import { Wrapper } from './styles';

function GalleryItem({ children, className, onClick }) {
  return (
    <div
      className={classnames(`Gallery__item`, className, {
        'h-pointer': onClick,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default GalleryItem;
