import React from 'react';
import { Wrapper } from './styles';
import { AiFillCar, FaBed, MdFullscreen } from 'react-icons/all';
import { Rooms } from 'components/PropertiesTable/styles';
import { transformProperty } from 'components/PropertiesTable/helpers';
import Price from 'components/Price';

function TableCardPropertyDetails({ property }) {
  const data = transformProperty(property);

  return (
    <Wrapper>
      <Rooms>
        {data.bedroom && (
          <div data-tip={data.bedroom_tip} data-place="right">
            <FaBed /> {data.bedroom}
          </div>
        )}
        {data.garage && (
          <div>
            <AiFillCar /> {data.garage}
          </div>
        )}
        {data.primary_area && (
          <div data-tip={data.primary_area_tip}>
            <MdFullscreen /> {data.primary_area}
          </div>
        )}
      </Rooms>
      <Price transaction={property.transaction}>
        {property.calculated_price}
      </Price>
    </Wrapper>
  );
}

export default TableCardPropertyDetails;
