// Modules
import dataReducer from 'modules/data';
import { filterOrAll } from 'pages/Reports/pages/helpers';
// Services
import { getDealPerChannel } from 'services/reports';

export const {
  reducer: dealPerChannelReducer,
  actionCreators: dealPerChannelActions,
  selectors: dealPerChannelSelectors
} = dataReducer(`reports/dealPerChannel`);

export const chartDataSelector = filterOrAll(dealPerChannelSelectors);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(dealPerChannelActions.request());

  return getDealPerChannel(params).then(res => {
    dispatch(dealPerChannelActions.receive(res.data));

    return res.data;
  });
};

export const toggleChecked = item => dispatch => {
  dispatch(dealPerChannelActions.toggleSelected(item.id));
};

export default dealPerChannelReducer;
