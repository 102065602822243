import withProvider from 'HOC/withProvider';
import { useMemo } from 'react';
import Modal from 'react-modal';
import * as facebookService from 'services/settings/facebook';
import AuthModal from './components/AuthModal';
import Form from './components/Form';
import { FacebookLeadsProvider, useFacebookLeads } from './context';

function ModalFacebookLeads({ isOpen, modalType, handleClose, modalConfig }) {
  const {
    facebook,
    setIsFetching,
    pages,
    accessToken,
    facebookUserId,
    isConnected,
  } = useFacebookLeads();

  const findPageById = (facebook_id) => {
    return facebook?.lead_pages?.find(
      (page) => facebook_id === page.facebook_id
    );
  };

  const initialValues = useMemo(() => {
    const _pages = pages?.map((page) => ({
      ...page,
      facebook_id: page.facebook_id || page.id,
    }));

    const lead_pages = _pages?.map((page) => {
      const current_page = findPageById(page.facebook_id);

      return {
        ...page,
        ...current_page,
        // forms: _.merge({ ...page?.forms }, { ...current_page?.forms }),
        forms: page.forms.map((form) => {
          if (current_page?.forms) {
            const currentForm = current_page.forms.find(
              (_form) => _form.form_id === form.id
            );

            return { ...form, ...currentForm };
          }

          return form;
        }),
      };
    });

    return {
      facebook_user_id: facebook?.facebook_user_id,
      ...facebook,
      lead_pages,
    };
  }, [facebook, pages]);

  const connectFacebook = async (values) => {
    try {
      setIsFetching(true);

      values.lead_pages = values?.lead_pages?.map((page) => ({
        ...page,
        facebook_id: page.facebook_id || page.id,
        forms: page?.forms.map((form) => ({
          ...form,
          form_id: form.form_id || form.id,
        })),
      }));

      return facebookService.connectLeads({
        long_lived_access_token: accessToken,
        facebook_user_id: facebookUserId,
        user_id: values?.user_id,
        lead_pages: values.lead_pages,
      });
    } catch (e) {
      setIsFetching(false);
      throw e;
    }
  };

  const handleSubmit = async (values) => {
    try {
      const res = await connectFacebook(values);

      handleClose();

      return res;
    } catch (err) {
      setIsFetching(false);
      throw err;
    }
  };

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
      style={{ content: { maxWidth: isConnected ? 940 : 720 } }}
    >
      {isConnected ? (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          handleClose={handleClose}
        />
      ) : (
        <AuthModal handleClose={handleClose} />
      )}
    </Modal>
  );
}

export default withProvider(FacebookLeadsProvider)(ModalFacebookLeads);
