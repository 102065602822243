import { Th } from "./styles";

function Head() {
  return (
    <tr>
      <Th>
        Data do cadastro
        <br />
        Identificação
      </Th>
      <Th>
        Nome fantasia
        <br />
        Usuário vinculado
      </Th>
      <Th>
        Celular <br />
        E-mail
      </Th>
      <Th>
        Situação do cadastro
      </Th>
      <Th>
        Situação do repasse
        <br />
        Data do pagamento
      </Th>
    </tr>
  );
}

export default Head;