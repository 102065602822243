import styled, { css } from 'styled-components';
import { stripUnit } from 'polished';

function subtract(value, sub) {
  return stripUnit(value) - sub + 'px';
}

function division(value, div) {
  return stripUnit(value) / div + 'px';
}

export const Wrapper = styled.div``;
export const BarContent = styled.div`
  width: 100%;
  height: ${(p) => p.theme.barSize};
  background: #e8e7e8;
`;
export const BarBackground = styled.div`
  position: relative;
  width: ${(p) => p.theme.width};
  background-color: ${(p) => p.theme.color};
  height: inherit;
`;
export const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${(p) => p.theme.color};
`;

const getValuePosition = ({ theme: { value, min, max, ballSize } }) => {
  switch (value) {
    case min:
      return css`
        left: -5px;
      `;
    case max:
      return css`
        right: -5px;
      `;
    default:
      return css`
        left: calc(100% - ${(p) => division(p.theme.ballSize, 2)});
      `;
  }
};

export const Value = styled.div`
  ${getValuePosition};
  position: absolute;

  top: -50%;
  background: #ffffff;
  border: 2px solid ${(p) => p.theme.color};
  width: ${(p) => p.theme.ballSize};
  height: ${(p) => p.theme.ballSize};
  line-height: ${(p) => subtract(p.theme.ballSize, 4)};
  text-align: center;
  border-radius: 50%;
`;

export const Footer = styled.div`
  color: #d7d2d7;
  display: flex;
  justify-content: space-between;
  margin: 9px 0 0;
`;
