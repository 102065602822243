import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import Popover from 'components/Popover';
import FormGroupStore from '../../components/FormGroupStore';
// Modules
import { addGroup, updateGroup } from 'modules/groups';
// Services

// import from './styles';

class GroupAdd extends React.Component {
  static propTypes = {
    group: PropTypes.string,
    onSuccess: PropTypes.func,
    onFail: PropTypes.func
  };

  static defaultProps = {
    group: null,
    onSubmit: null,
    onFail: null
  };

  onSubmit = values => {
    const { group } = this.props;

    if (group?.id) {
      return this.props.updateGroup({ id: values.id, title: values.title });
    }

    return this.props.addGroup(values);
  };
  onSubmitSuccess = (...rest) => {
    const { onSuccess } = this.props;
    if (onSuccess) onSuccess(rest);
  };
  onSubmitFail = (...rest) => {
    const { onFail } = this.props;
    if (onFail) onFail(rest);
  };

  render() {
    const { group, children } = this.props;

    return (
      <Popover
        onSubmit={this.onSubmit}
        onSubmitSuccess={this.onSubmitSuccess}
        onSubmitFail={this.onSubmitFail}
        initialValues={group || {}}
        group={group}
        component={FormGroupStore}
        style={{ display: 'block' }}
      >
        {children || (
          <Button block color="secondary">
            Novo Grupo
          </Button>
        )}
      </Popover>
    );
  }
}

const mapDispatchToProps = {
  addGroup,
  updateGroup
};

export default connect(null, mapDispatchToProps)(GroupAdd);
