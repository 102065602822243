import { Content, Group, Name, Text, Wrapper } from './styles';
// import Avatar from 'components/Avatar';
import { MdPerson } from 'react-icons/md';

function AvatarCell({ user }) {
  return (
    <Wrapper>
      <Content>
        <Text>
          <Name>{user?.name}</Name>
          <Group>
            <MdPerson /> {user?.group?.title}
          </Group>
        </Text>
      </Content>
    </Wrapper>
  );
}

export default AvatarCell;
