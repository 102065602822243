import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
// import classnames from 'classnames';

const defaultProps = {
  className: null,
  colItems: {
    xs: 3
  }
};

const propTypes = {
  colItems: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      )
    }).isRequired
  )
};

const ImageCheckItem = ({
  id,
  input,
  name,
  title,
  image,
  options,
  colItems,
  ...rest
}) => (
  <Col
    key={id}
    tagName="li"
    className="ImageCheck__item"
    {...rest}
    {...colItems}
  >
    <div className="ImageCheck__wrapper">
      <div className="ImageCheck__header">
        <img src={image} alt={title} className="ImageCheck__image" />
      </div>
      <div className="ImageCheck__footer">
        {options &&
          options.map((option, i) => (
            <label
              key={`${title}-${option.name}-${i}`}
              className="ImageCheck__action"
            >
              <Field
                type="radio"
                name={`${name}[${id}].checked`}
                value={option.name}
                className="h-margin-right--5"
                component="input"
              />
              {option.label}
            </label>
          ))}
      </div>
    </div>
  </Col>
);

class ImageCheck extends React.Component {
  render() {
    const { colItems, options, input, meta, ...rest } = this.props;

    return (
      <Row {...rest} tagName="ul" className="ImageCheck">
        {options.map(option => (
          <ImageCheckItem
            key={option.title}
            name={input.name}
            input={input}
            colItems={colItems}
            {...option}
          />
        ))}
      </Row>
    );
  }
}

ImageCheck.defaultProps = defaultProps;
ImageCheck.propTypes = propTypes;

export default memo(ImageCheck);
