import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Common Components
import { ModalTemplate } from '../../../components/Modal';
import FormInteraction from './components/FormInteraction';
// Services
import * as helpDeskService from 'services/helpDesk';
// Modules
import { Actions } from 'modules/fileUpload';
// Constants
import { TYPES } from 'pages/Help/constants';

class ModalInteraction extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    type: PropTypes.oneOf([TYPES.PROBLEM, TYPES.SUGESTION]),
    ticketId: PropTypes.string.isRequired
  };

  static defaultProps = {
    type: TYPES.PROBLEM,
    ticketId: null
  };

  get ticketId() {
    return this.props.ticketId;
  }

  onSubmit = async ({ documents, ...values }) => {
    const { uploadSuccess, addFile } = this.props;

    const { data } = await helpDeskService.createInteraction(
      this.ticketId,
      values
    );

    if (documents) {
      documents.forEach(document => {
        helpDeskService.uploadAttachment(data.id, document).then(() => {
          uploadSuccess(document);
        });

        addFile(document);
      });
    }

    return data;
  };

  onSubmitSuccess = res => {
    const { onSubmitSuccess } = this.props;
    if (onSubmitSuccess) onSubmitSuccess(res);
    this.props.handleClose();
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate title="Enviar Comentário" handleClose={handleClose}>
          <FormInteraction
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  ...Actions
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalInteraction);
