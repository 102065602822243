import Container from 'components/Container';
import Tooltip from 'react-tooltip';
import { FieldBool } from 'components/Form';
import ListOptions from 'components/ListOptions';
import useFormValue from 'hooks/useFormValue';
import useReduxForm from 'hooks/useReduxForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Map from '../Map';
import StreetView from '../StreetView';
import MapProvider from './context';
import * as cepsService from 'services/ceps';
import { handleSetPosition } from 'modules/mapView';
import { getFullAddress } from '../Map/helpers';
import { useLocation } from 'containers/LocationAsyncFields/context';
import Button from 'components/Button';
import { MdInfo } from 'react-icons/md';

const MapSection = ({ isStreetviewInitiallyActive }) => {
  const [canShowMap, setCanShowMap] = useState(false);
  const [isAdjustingStreetview, setIsAdjustingStreetview] = useState(false);
  const [isStreetviewActive, setIsStreetviewActive] = useState(false);

  useEffect(
    () => setIsStreetviewActive(isStreetviewInitiallyActive),
    [isStreetviewInitiallyActive]
  );

  const mapsLatitude = useFormValue('maps_latitude');
  const mapsLongitude = useFormValue('maps_longitude');
  const isMapShown = useFormValue('locate_map');

  const canShowMapOnSite = !!mapsLatitude && !!mapsLongitude;

  const { dispatch, change } = useReduxForm();

  useEffect(() => {
    Tooltip.rebuild();
  }, [isMapShown]);

  const handleChangeMapView = useCallback(
    ({ lat, lng }, { heading, pitch, zoom }) => {
      if (lat) dispatch(change('maps_latitude', lat));
      if (lng) dispatch(change('maps_longitude', lng));
      if (heading) dispatch(change('maps_heading', heading));
      if (pitch) dispatch(change('maps_pitch', pitch));
      if (zoom) dispatch(change('maps_street_zoom', zoom));
    },
    [dispatch, change]
  );

  const onZoomChange = useCallback(
    ({ zoom }) => {
      dispatch(change('maps_zoom', zoom));
    },
    [dispatch, change]
  );

  const { country, state, city, neighborhood } = useLocation();
  const { neighborhood_id, street_address, street_number } = useFormValue([
    'neighborhood_id',
    'street_address',
    'street_number',
  ]);

  const fullAddress = useMemo(() => {
    return getFullAddress({
      country,
      state,
      city,
      neighborhood,
      street_address,
      street_number,
    });
  }, [country, state, city, neighborhood, street_address, street_number]);

  const clearMapFields = () => {
    dispatch(change('maps_latitude', null));
    dispatch(change('maps_longitude', null));
    dispatch(change('maps_heading', null));
    dispatch(change('maps_pitch', null));
    dispatch(change('maps_street_zoom', null));
    dispatch(change('is_map_shown', false));
    dispatch(change('is_exact_map_shown', false));
    dispatch(change('is_streetview_active', false));
    dispatch(change('is_streetview_shown', false));
  };

  const handleChangeLocateMap = async (value) => {
    if (!value) return clearMapFields();

    try {
      setCanShowMap(true);
      const res = await cepsService.getLocation({
        neighborhood_id,
        address: fullAddress,
        street_name: street_address,
      });

      const location = {
        lat: res?.data?.[0]?.latitude || 0,
        lng: res?.data?.[0]?.longitude || 0,
      };

      dispatch(handleSetPosition(location));
      handleChangeMapView(location, {});
    } catch (e) {
      return null;
    }
  };

  const handleAdjustStreetview = (value) => {
    setIsAdjustingStreetview(value);
    if (value) setCanShowMap(true);
  };

  const handleRemoveStreetview = useCallback(() => {
    setIsStreetviewActive(false);
    handleAdjustStreetview(false);
    dispatch(change('maps_street_zoom', null));
    dispatch(change('maps_heading', null));
    dispatch(change('is_streetview_active', false));
  }, [dispatch, change]);

  return (
    <MapProvider onChange={handleChangeMapView} onZoomChange={onZoomChange}>
      <Container className="Container--nopadding h-margin-top--15">
        <ListOptions>
          <ListOptions.item
            title="Localizar imóvel no mapa?"
            renderOptions={() => (
              <FieldBool name="locate_map" onChange={handleChangeLocateMap} />
            )}
          />

          <Map
            isMapShown={isMapShown}
            canShowMap={canShowMap}
            setCanShowMap={setCanShowMap}
          />

          {isMapShown && (
            <>
              {canShowMapOnSite && (
                <ListOptions.item
                  title="Mostrar mapa no site?"
                  renderOptions={() => (
                    <FieldBool
                      name="is_map_shown"
                      onChange={(e, value) => {
                        if (!value) {
                          change('is_exact_map_shown', false);
                          change('is_streetview_shown', false);
                        }
                      }}
                    />
                  )}
                />
              )}

              <ListOptions.item
                title={
                  <>
                    Mostrar localização exata?{' '}
                    <MdInfo data-tip="Caso opte em NÃO mostrar a localização exata, será exibo um circulo em volta da área do imóvel." />
                  </>
                }
                renderOptions={() => (
                  <FieldBool
                    name="is_exact_map_shown"
                    onChange={() => {
                      change('is_map_shown', true);
                    }}
                  />
                )}
              />

              {isStreetviewActive ? (
                <ListOptions.item
                  title="Ajustar StreetView?"
                  renderOptions={() => (
                    <>
                      <Button
                        color="secondary"
                        onClick={() => handleAdjustStreetview(true)}
                      >
                        Ajustar
                      </Button>
                      <Button color="danger" onClick={handleRemoveStreetview}>
                        Remover
                      </Button>
                    </>
                  )}
                />
              ) : (
                <ListOptions.item
                  title="Ativar StreetView?"
                  renderOptions={() => (
                    <FieldBool
                      name="is_streetview_active"
                      onChange={(value) => handleAdjustStreetview(value)}
                    />
                  )}
                />
              )}

              {isAdjustingStreetview && (
                <>
                  <StreetView />

                  <ListOptions.item
                    title="Mostrar StreetView no site?"
                    renderOptions={() => (
                      <FieldBool
                        name="is_streetview_shown"
                        onChange={() => {
                          change('is_map_shown', true);
                        }}
                      />
                    )}
                  />
                </>
              )}
            </>
          )}
        </ListOptions>
      </Container>
    </MapProvider>
  );
};

export default MapSection;
