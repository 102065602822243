// import styles from './styles'

import { Field, reduxForm } from 'redux-form';
import { FieldColorPallete, Input } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import * as S from './styles';

function FormStoreCategory({ texts, handleSubmit, handleClose }) {
  return (
    <form onSubmit={handleSubmit}>
      {texts.categoryTitle ? (
        <S.Category>
          <strong>Categoria:</strong> {texts.categoryTitle}
        </S.Category>
      ) : null}
      <Field
        maxLength={40}
        label={texts.labelTitle}
        name="title"
        component={Input}
      />
      <FieldColorPallete label="Cor" name="color" />

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({ form: 'FormStoreCategory' })(FormStoreCategory);
