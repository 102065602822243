import styled from 'styled-components';
import { borderRadius } from 'polished';

export const Wrapper = styled.div`
  margin: -15px -15px -15px -15px;
`;

export const HasMore = styled.div`
  width: 100%;
  padding: 15px;
  color: ${p => p.theme.colors.secondary};
  text-decoration: underline;
  border-width: 1px 0 0 0;
  text-align: center;
  border-style: solid;
  border-color: #e1e6ec;
  background-color: #ffffff;
  cursor: pointer;
  ${borderRadius('bottom', '2px')};
`;
