import { useState, useEffect } from 'react';

export function useHashChange(hash) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const handleHashChange = () => {
      setIsOpenModal(window.location.hash.includes(hash));
    };
    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return isOpenModal;
}
