import api from 'services';

const BASE = 'settings/plenoimob';

/**
 * Pega as informações da integração
 * @returns {Promise<AxiosResponse<never>>}
 */
export const getOne = async () => {
  try {
    return await api.getOne(BASE);
  } catch (e) {
    return { data: {} };
  }
};

export const sendProperty = async (propertyId) => {
  return await api.create(`/settings/plenoimob/store-property/${propertyId}`)
}

export const sendPerson = async (personId) => {
  return await api.create(`/settings/plenoimob/store-person/${personId}`)
}

/**
 *
 * @param values
 * @returns {Promise<AxiosResponse<*>>}
 */
export const connect = (values) => api.create(BASE, values);

/**
 * Remove a integração
 * @returns {Promise<AxiosResponse<never>>}
 */
export const disconnect = () => api.delete(BASE);
