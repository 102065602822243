import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Icone
import Icon from '../../../Icon';

const defaultProps = {
  onDidMount: null
};
const propTypes = {
  onDidMount: PropTypes.func
};

const Checkbox = props => {
  const { input, index, option, valueKey, labelKey, handleChange } = props;

  const { name } = input;
  const inputValue = input.value;

  // pega o texto do checkbox
  const label = option[labelKey];

  // pega o valor do checkbox
  const value = option[valueKey];

  // verifica se está checado
  const checked = inputValue.indexOf(value) !== -1;

  return (
    <label key={`checkbox-${index}`}>
      <input
        type="checkbox"
        name={`${name}[${index}]`}
        value={value}
        checked={checked}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <Icon
        name={checked ? 'check-square' : 'square-o'}
        className="h-margin-right--5"
      />
      <span>{label}</span>
    </label>
  );
};

class CheckboxList extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.onDidMount && this.props.options.length)
      this.props.onDidMount();
  }

  handleChange = option => event => {
    const { input, valueKey } = this.props;

    const arr = [...input.value];
    const value = option[valueKey];

    if (event.target.checked) {
      arr.push(value);
    } else {
      arr.splice(arr.indexOf(value), 1);
    }
    return input.onChange(arr);
  };

  render() {
    const {
      mode,
      cols,
      title,
      input,
      options,
      labelKey,
      valueKey
    } = this.props;

    // Retorna nulo caso não tenha nenhuma informação
    if (!options.length) return null;

    return (
      <div
        className={classnames({
          'CheckboxList--horizontal': mode === 'horizontal'
        })}
      >
        {title && <h5 className="h-margin-bottom--5">{title}</h5>}

        <Row style={{ margin: '0 -5px 15px' }}>
          {options.map((option, index) => (
            <Col {...cols} style={{ padding: 5 }}>
              <Checkbox
                index={index}
                input={input}
                option={option}
                valueKey={valueKey}
                labelKey={labelKey}
                handleChange={this.handleChange(option)}
              />
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

CheckboxList.defaultProps = defaultProps;
CheckboxList.propTypes = propTypes;

export default memo(CheckboxList);
