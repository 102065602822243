const getLocation = ({ maps_latitude, maps_longitude }) => [
  maps_latitude,
  maps_longitude
];

/**
 * Pega os bounds
 * @param properties
 */
export const getBounds = properties => properties.map(getLocation);

/**
 * Verifica se todas as localizações estão diferentes
 * @param properties
 */
export function isAllLocationsDifferent(properties) {
  let hasBounds = false;

  properties.reduce((curr, next) => {
    const currentLat = curr.maps_latitude;
    const currentLon = curr.maps_longitude;

    const nextLat = next.maps_latitude;
    const nextLon = next.maps_longitude;

    if (currentLat !== nextLat || currentLon !== nextLon) hasBounds = true;

    return next;
  });

  return hasBounds;
}

/**
 * Pega as props necessárias para fazer o bound funcionar corretamente
 * @param properties
 * @param defaultZoom
 * @returns {{bounds: *}|{center: [*, *], zoom: number}}
 */
export const getBoundProps = (properties, defaultZoom = 10) => {
  const [firstProperty] = properties;
  const hasBounds = isAllLocationsDifferent(properties);

  if (hasBounds) {
    return {
      bounds: getBounds(properties)
    };
  }

  return {
    center: getLocation(firstProperty),
    zoom: defaultZoom
  };
};
