import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

const defaultProps = {};
const propTypes = {};

const Title = styled.h1`
  text-align: center;
  margin: 25px 0 10px;
`;

const Text = styled.p`
  text-align: center;
  line-height: 1.4em;
`;

const Container = styled.div`
  ul {
    margin-top: 0.5em;
    margin-left: 18px;
    color: inherit;

    li {
      color: rgba(62, 88, 124, 0.54);
      margin-bottom: 0.5em;
    }
  }
`;

function BlankPage() {
  return (
    <>
      <Helmet title="Termos de uso" />
      <Title>Termos de Uso</Title>
      <Text>
        Estes são os termos que determinarão nossa relação antes de utilizar
        <br /> é necessário que você leia, entenda e concorde com estes termos.
      </Text>
      <Container className="termos-de-uso">
        <section className="termos-de-uso__SectionTerms-u2pywt-0 ixOcjp">
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Aceite dos Termos</h3>
              <p>
                Este Contrato de Licença de Usuário Final (“EULA”) é um acordo
                legal entre o licenciado (pessoa física ou jurídica) (o
                “LICENCIADO”) e a CODEX SISTEMAS, pessoa jurídica de direito
                privado, inscrita no CNPJ sob n. 15.512.782/0001-15, com sede na
                Rua Simeão Esmeraldino de Menezes 400, Uniparque (Módulo 38),
                CEP 88.704-090, bairro Dehon, cidade Tubarão - Santa Catarina -
                Brasil (a “LICENCIANTE”) para uso do programa de computador
                denominado TECIMOB, disponibilizado neste ato pela LICENCIANTE
                (o “SOFTWARE”), pelo prazo determinado pelo LICENCIADO no ato do
                licenciamento do SOFTWARE, o que inclui o programa de computador
                e pode incluir os meios físicos associados, quaisquer materiais
                impressos, e qualquer documentação “online” ou eletrônica. Ao
                utilizar o SOFTWARE, mesmo que parcial ou a título de teste, o
                licenciado estará vinculado aos termos deste EULA, concordando
                com os mesmos, principalmente CONSENTINDO COM O ACESSO, COLETA,
                USO, ARMAZENAMENTO, TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS
                INFORMAÇÕES do LICENCIADO para a integral execução das
                funcionalidades ofertadas pelo SOFTWARE. Em caso de discordância
                dos termos aqui apresentados, a utilização do SOFTWARE deve ser
                imediatamente interrompida.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Nossa sede fica na cidade de Tubarão em Santa Catarina e usando
                o TECIMOB você concorda com os termos deste contrato.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Público Alvo</h3>
              <p>
                O SOFTWARE deve ser de uso exclusivo para corretores de imóveis,
                grupos de corretores de imóveis ou imobiliárias devidamente
                registrados no CRECI.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Se você não possui um CRECI, você não pode utilizar o TECIMOB.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Da Propriedade Intelectual</h3>
              <p>
                O LICENCIADO não adquire, pelo presente instrumento, nenhum
                direito de propriedade intelectual ou outros direitos
                exclusivos, incluindo patentes, desenhos, marcas, direitos
                autorais ou direitos sobre informações confidenciais ou segredos
                de negócio, sobre ou relacionados ao SOFTWARE ou nenhuma parte
                dele. O LICENCIADO também não adquire nenhum direito sobre ou
                relacionado ao SOFTWARE ou qualquer componente dele, além dos
                direitos expressamente licenciados ao mesmo sob o presente EULA
                ou em qualquer outro contrato mutuamente acordado por escrito
                que o LICENCIADO possa ter celebrado com a LICENCIANTE.
                Quaisquer direitos não expressamente concedidos sob o presente
                instrumento são reservados. Também será de propriedade exclusiva
                da LICENCIANTE ou esta devidamente licenciado, todo o conteúdo
                disponibilizado no site, incluindo, mas não se limitando,
                textos, gráficos, imagens, logos, ícones, fotografias, conteúdo
                editorial, notificações, softwares e qualquer outro material.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                A propriedade do produto TECIMOB é da empresa CODEX Sistemas que
                cede o direito de você usá-lo para gerir sua carteira de
                clientes e imóveis.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Declaração de Vontade</h3>
              <p>
                O LICENCIADO declara ter ciência dos direitos e obrigações
                decorrentes do presente EULA, constituindo este instrumento o
                acordo completo entre as partes. Declara, ainda, ter lido,
                compreendido e aceito todos os termos e condições. O LICENCIADO
                declara que foi devidamente informado da política de
                confidencialidade e ambientes de proteção de informações
                confidenciais, dados pessoais e registros de acesso, consentindo
                livre e expressamente às ações de coleta, uso, armazenamento e
                tratamento das referidas informações e dados.
                <br />O LICENCIADO declara estar ciente de que as operações que
                corresponderem à aceitação do presente EULA, de determinadas
                opções, bem como de rescisão e demais alterações, serão
                registradas nos bancos de dados da LICENCIANTE, juntamente com a
                data e hora em que o aceite foi manifestado pelo LICENCIADO,
                podendo tal informação ser utilizada como prova,
                independentemente de outra formalidade.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Aceitando esses termos você concorda com todas as cláusulas e
                reconhece que seus dados serão tratados com a máxima
                confidencialidade.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Licença de Uso do Software</h3>
              <p>
                Sujeito aos termos e condições do presente instrumento, este
                EULA concede ao LICENCIADO uma licença revogável, não exclusiva
                e intransferível para usar o SOFTWARE. O LICENCIADO não poderá
                utilizar e nem permitir o uso do SOFTWARE para uma outra
                finalidade que não seja o processamento de suas informações ou
                de pessoas jurídicas indicadas no ato do cadastramento. Em
                nenhuma hipótese o LICENCIADO terá acesso ao código fonte do
                SOFTWARE ora licenciado, por este se tratar de propriedade
                intelectual da LICENCIANTE.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Nós poderemos fazer este mesmo contrato com inúmeras pessoas.
                Nós vamos disponibilizar para você todas as funcionalidades
                adquiridas e vamos trabalhar duro para sempre surpreende-lo.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Do Uso de Dados (LGPD)</h3>
              <p>
                O LICENCIANTE reserva o direito de limitar o espaço utilizado
                para armazenamento de informações em disco do LICENCIADO em 10
                GB (Gigabytes). O envio dos imóveis para nutrição dos clientes
                do LICENCIADO será limitado a 5 mil envios por usuário de conta
                no período de 30 dias. A renovação dos envios será realizada na
                exata data em que se completarem 30 dias corridos do referido
                envio, ou seja, a quantidade de disparos realizados em um
                determinado dia serão liberadas apenas no mesmo dia no mês
                subsequente.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Você terá o limite de armazenamento de até 10 gb de informações
                salvas em nossos servidores. <br />
                Cada usuário da sua conta pode enviar 5 mil imóveis por mês por
                e-mail para seus clientes, e a quantidade de envios feitos em um
                dia será liberado no mesmo dia no mês seguinte (ou seja, não
                precisa aguardar 30 dias para liberar todos os 5 mil envios).
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Das Restrições</h3>
              <p>
                Em hipótese alguma é permitido ao LICENCIADO ou a terceiros, de
                forma geral:
              </p>
              <ul>
                <li>
                  copiar, ceder, sublicenciar, vender, dar em locação ou em
                  garantia, reproduzir, doar, alienar de qualquer forma,
                  transferir total ou parcialmente, sob quaisquer modalidades,
                  gratuita ou onerosamente, provisória ou permanentemente, o
                  SOFTWARE objeto deste EULA, assim como seus módulos, partes,
                  manuais ou quaisquer informações relativas ao mesmo;
                </li>
                <li>
                  retirar ou alterar, total ou parcialmente, os avisos de
                  reserva de direito existente no SOFTWARE e na documentação;
                </li>
                <li>
                  praticar de engenharia reversa, descompilação ou desmontagem
                  do SOFTWARE.
                </li>
              </ul>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Você não poderá dar, emprestar, vender ou fazer cópia do TECIMOB
                para ninguém, mas poderá indicar para todos os seus amigos e
                conhecidos.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Do Prazo</h3>
              <p>
                O presente EULA entra em vigor na data de seu aceite pelo
                LICENCIADO e vigorará pelo prazo indeterminado.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Este contrato não tem prazo para acabar e você poderá terminar
                nossa relação a qualquer momento.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Da Remuneração e Forma de Pagamento</h3>
              <p>
                O LICENCIADO deve pagar à LICENCIANTE o valor do respectivo
                plano escolhido de acordo com a periodicidade definida entre as
                opções de pagamento disponibilizadas ao LICENCIADO no ato da
                contratação.
                <br /> Caso o LICENCIADO, no decorrer da vigência do presente
                instrumento, opte por outro plano de licenciamento, os valores
                serão alterados de acordo com o respectivo plano escolhido.
                <br /> A falta de pagamento nas datas determinadas para seu
                vencimento acarretará na suspensão de acesso ao SOFTWARE até que
                as pendências financeiras sejam regularizadas.
                <br /> Em caso de atraso no pagamento, a LICENCIANTE tem direito
                de utilizar os meios de contato cabíveis para contatar o
                LICENCIADO a fim de regularizar a sua situação, bem como
                avisá-lo de vencimentos ou renovações do plano próximo às suas
                respectivas datas. Eventuais atrasos no pagamento não
                ocasionarão em juros, entretanto os dias em que o LICENCIADO
                permanecer em atraso e o SOFTWARE estiver disponível para uso
                serão restituídos na nova contratação ou renovação do plano
                escolhido.
                <br /> Caso a suspensão permaneça por prazo superior a 60
                (sessenta) dias, a LICENCIANTE poderá excluir integralmente as
                informações lançadas no SOFTWARE pelo LICENCIADO. A LICENCIANTE
                poderá fazer todo tipo de ajuste no conteúdo e valor dos planos
                oferecidos, bem como criação de novos planos ou exclusão de
                planos existentes. Os pagamentos feitos via de cartão de
                crédito, serão debitados de forma recorrente automática ao final
                de cada período, para um novo período no mesmo prazo e plano
                vigente atualmente na conta do LICENCIADO.
                <br /> Para pagamentos via boleto bancário, será encaminhado um
                novo boleto em 05 (cinco) dias antes do vencimento do período
                contratado pelo LICENCIADO, onde os pagamentos efetuados levarão
                de 01 (um) a 03 (três) dias úteis para serem compensados.
                <br />
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Vamos avisar você sobre vencimentos e renovações por Whatsapp,
                telefone, e-mail ou por onde for necessário para que você não
                seja prejudicado.
                <br />
                <br />
                Caso você atrase alguma renovação por esquecimento, nós vamos
                deixar seu software ativo por alguns dias e no próximo pagamento
                este valor será restituído.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Restituição das Informações</h3>
              <p>
                Suspenso o acesso ao SOFTWARE, a LICENCIANTE manterá as
                informações do LICENCIADO lançadas no mesmo pelo período de 30
                (trinta) dias, contados da suspensão de acesso. Neste período, a
                LICENCIANTE tornará as informações do LICENCIADO disponíveis
                para serem extraídas do SOFTWARE em formato .xls.
                <br />
                Passados 30 (trinta) dias da suspensão do presente contrato,
                todas as INFORMAÇÕES do LICENCIADO, incluindo clientes e dados
                de imóveis, em poder da LICENCIANTE serão excluídos
                permanentemente do banco de dados da LICENCIANTE, tendo sido
                extraídas ou não pelo LICENCIADO.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Se por algum motivo não identificarmos seu pagamento, após 30
                dias teremos o direito de excluir as suas informações e você
                terá este prazo de 30 dias para reaver as informações que você
                colocou no sistema.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Das Obrigações do Licenciado</h3>
              <p>Obriga-se o LICENCIADO a:</p>
              <ul>
                <li>
                  Possuir as necessárias autorizações de seus clientes para a
                  inserção de dados a eles referentes, bem como dos imóveis de
                  sua propriedade, sendo o LICENCIADO o único responsável pela
                  inserção de tais informações;
                </li>
                <li>
                  Responder pelas informações inseridas no SOFTWARE, pelo
                  cadastramento, permissões, senhas e modo de utilização de seus
                  usuários. A LICENCIANTE em hipótese alguma será responsável
                  pelo conteúdo (informações, senhas, cópias de informações,
                  etc) sobre o SOFTWARE, não sendo, portanto, estas informações
                  revisadas em momento algum. A responsabilidade pelas
                  informações do SOFTWARE é sempre do LICENCIADO.
                </li>
                <li>
                  Certificar-se de que não está proibido por determinação legal
                  e/ou contratual de passar INFORMAÇÕES PESSOAIS, bem como
                  quaisquer outros dados à LICENCIANTE, necessários para a
                  execução do serviço oferecido por este EULA.
                </li>
                <li>
                  Não utilizar o SOFTWARE de qualquer forma que possa implicar
                  em ilícito, infração, violação de direitos ou danos à
                  LICENCIANTE ou terceiros.
                </li>
                <li>
                  Não publicar, enviar ou transmitir qualquer arquivo que
                  contenha vírus, worms, cavalos de tróia ou qualquer outro
                  programa que possa contaminar, destruir ou interferir no om
                  funcionamento do SOFTWARE.
                </li>
                <li>
                  Caso o LICENCIADO acredite que seu login e senha de acesso ao
                  Site tenham sido roubados ou sejam de conhecimento de outras
                  pessoas, por qualquer razão, deverá imediatamente comunicar a
                  LICENCIANTE, sem prejuízo da alteração de senha imediata por
                  meio do Site.
                </li>
              </ul>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Você não poderá anunciar nenhum imóvel sem o consentimento do
                proprietário.
                <br />
                Você precisa guardar bem seus dados de acesso e gerenciar com
                cuidado as permissões dos usuários que você liberar para usar o
                TECIMOB.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Das Obrigações do Licenciante</h3>
              <p>Obriga-se o LICENCIANTE a:</p>
              <ul>
                <li>
                  A LICENCIANTE garante ao LICENCIADO que o SOFTWARE deverá
                  funcionar regularmente, se respeitadas as condições de uso
                  definidas na documentação, sendo também resguardado o tempo de
                  atividade de no mínimo 95% a cada 30 dias. Na ocorrência de
                  falhas de programação (“bugs”), a LICENCIANTE obrigar-se-á a
                  corrigir tais falhas, podendo à seu critério, substituir a
                  cópia dos Programas com falhas por cópias corrigidas;
                </li>
                <li>
                  Fornecer, ato contínuo ao aceite deste EULA, acesso ao
                  SOFTWARE pelo prazo estabelecido entre as Partes;
                </li>
                <li>
                  Suspender o acesso ao SOFTWARE que esteja desrespeitando as
                  regras de conteúdo aqui estabelecidas ou as normas legais em
                  vigor ou ainda, ao final do prazo de validade deste
                  instrumento, independentemente de aviso prévio;
                </li>
                <li>
                  Alterar as especificações e/ou características do SOFTWARE
                  licenciados para a melhoria e/ou correções de erros;
                </li>
                <li>
                  Disponibilizar acesso aos serviços de suporte compreendido das
                  09:00h as 17:00h (pelo horário de Brasília) via whatsapp,
                  através de correio eletrônico (suporte@tecimob.com.br) ou o
                  pelo fone: (48) 9-9604-9333, para esclarecimento de dúvidas de
                  ordem não funcional diretamente relacionadas a problemas no
                  SOFTWARE.
                </li>
                <li>
                  Manter as INFORMAÇÕES DE CONTA e PESSOAIS do LICENCIANDO, bem
                  como registros de acesso, em sigilo, sendo que as referidas
                  INFORMAÇÕES serão armazenadas em ambiente seguro, sendo
                  respeitadas a intimidade, a vida privada, a honra e a imagem
                  do LICENCIADO, conforme regras da Lei nº 12.965/2014
                </li>
              </ul>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                O Software ficará ativo por no mínimo 95% do tempo a cada 30
                dias.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Da Retomada dos Softwares</h3>
              <p>
                A LICENCIANTE se reserva o direito de retomar o SOFTWARE, objeto
                deste EULA nos casos em que o LICENCIADO use o SOFTWARE de forma
                diversa daquela estipulada no presente instrumento, sem que o
                LICENCIADO tenha direito e restituição dos valores pagos.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                É justo que se você não cumprir com o que está neste termo nós
                podemos encerrar sua conta no TECIMOB.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Das Garantias Limitadas</h3>
              <p>
                O SOFTWARE é fornecido “no estado em que se encontra“ e
                “conforme a disponibilidade“, com todas as falhas e sem garantia
                de qualquer espécie.
                <br />
                O SOFTWARE esta sempre em evolução, é impossível para nós
                garantirmos que o software irá funcionar a 100% do tempo com 0%
                de erro, razão pela qual você se compromete a nos acionar para
                corrigir as falhas verificadas, em substituição de nos pedir
                qualquer ressarcimento ou indenização.
                <br />
                Fazemos backup de todos os dados em nossos servidores, mas
                falhas podem ocorrer, mantenha sempre backup de todos os seus
                dados inseridos no sistema.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Sempre cuidaremos dos seus dados e faremos backup das
                informações, mas erros podem acontecer e acontecem, sempre
                guarde muito bem seus dados.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>
                Consentimento livre, expresso e informado para acesso à
                informações confidenciais e dados pessoais
              </h3>
              <p>
                O LICENCIADO ao aceitar utilizar o SOFTWARE, além de aceitar
                integralmente o “EULA“, também consente, livre e expressamente,
                que a LICENCIANTE colete, use, armazene e faça o tratamento de
                suas INFORMAÇÕES, incluindo seus dados pessoais, os quais serão
                necessários para que o serviço ofertado seja prestado em sua
                integralidade.
                <br />
                Para tanto, o LICENCIADO consente, livre e expressamente, em
                fornecer os dados que permitam o acesso a suas INFORMAÇÕES para
                que o SOFTWARE execute todas as funções para as quais foi
                projetado.
                <br />O LICENCIADO, por meio deste EULA e fornecendo as
                INFORMAÇÕES DE CONTA, autoriza e consente expressamente que a
                LICENCIANTE acesse suas INFORMAÇÕES DE CONTA na qualidade de
                mandatária.
                <br />A LICENCIANTE declara que todas as INFORMAÇÕES DE CONTA
                serão utilizadas única e exclusivamente visualização das
                Informações, sendo absolutamente vedada a LICENCIANTE a
                realização de quaisquer transações.
                <br />O LICENCIADO consente que quando acessar o site da
                LICENCIANTE, esta poderá coletar informações técnicas de
                navegação, tais como tipo de navegador do computador utilizado
                para acesso ao Site, endereço de protocolo de Internet, páginas
                visitadas e tempo médio gasto no Site. Tais informações poderão
                ser usadas para orientar o próprio LICENCIADO e melhorar os
                serviços ofertados.
                <br />O LICENCIADO consente livre e expressamente que suas
                INFORMAÇÕES poderão ser transferidas a terceiros em decorrência
                da venda, aquisição, fusão, reorganização societária ou qualquer
                outra mudança no controle da LICENCIANTE. A LICENCIANTTE,
                contudo, compromete-se, nestes casos, a informar o LICENCIADO.
                <br />O LICENCIADO consente livre e expressamente que a
                LICENCIANTE utilize cookies apenas para controlar a audiência e
                a navegação em seu Site e possibilitar a identificação de
                serviços segmentados e personalizados ao perfil do LICENCIADO. A
                LICENCIANTE garante que estas informações coletadas por meio de
                cookies são estatísticas e não pessoais, bem como que não serão
                utilizadas para propósitos diversos dos expressamente previstos
                neste EULA, comprometendo-se a adotar todas as medidas
                necessárias a fim de evitar o acesso e o uso de tais informações
                por quaisquer terceiros, sem a devida autorização.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Aceitando nossos termos de uso, você permite que possamos
                acessar seus dados registrados em nosso banco de dados. Nós
                utlizaremos destas informações para melhorar sempre sua
                experiência na utilização do TECIMOB bem com a tomamos como
                parametros para definir quais novos módulos desenvolver.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Da Rescisão</h3>
              <p>
                O LICENCIADO poderá rescindir o presente contrato a qualquer
                tempo, bastando deixar de efetuar novos pagamentos para próximo
                período. Para os planos de licenciamento com pagamento
                antecipado, caso o LICENCIADO decida rescindir o presente
                contrato antes do término do prazo contratado, será reembolsado
                80% (oitenta por cento) do saldo restante do contrato. O
                restante será considerado como multa para cobrir os custos
                operacionais pelo cancelamento antecipado.
                <br />
                No caso de rescisão do presente contrato, os dados pessoais,
                bancários, financeiros e demais informações do LICENCIADO serão
                excluídos permanentemente 30 dias após a data da rescisão.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Você poderá cancelar seu contrato conosco a qualquer momento,
                basta deixar de fazer seus pagamentos.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Das disposições Legais</h3>
              <p>
                O LICENCIADO não poderá prestar serviços a terceiros utilizando
                o SOFTWARE da LICENCIANTE sem autorização prévia e expressa da
                mesma. Desta forma, o SOFTWARE não pode operar sob o regime de
                multi empresa.
                <br />
                Caso o LICENCIADO venha a desenvolver um novo módulo ou produto
                que caracterize cópia, de todo ou em parte, quer seja do
                dicionário de dados, quer seja do programa, será considerado
                como parte do software fornecido pela LICENCIANTE, ficando,
                portanto, sua propriedade incorporada pela LICENCIANTE e seu uso
                condicionado a estas cláusulas contratuais;
                <br />
                Este EULA obriga as partes e seus sucessores e somente o
                LICENCIADO possui licença não exclusiva para a utilização do
                SOFTWARE, sendo-lhe, entretanto, vedado transferir os direitos e
                obrigações impostos por este instrumento. Tal limitação, no
                entanto, não atinge a LICENCIANTE, que poderá, a qualquer tempo,
                ceder, no todo ou em parte, os direitos e obrigações inerentes
                ao presente EULA;
                <br />A tolerância de uma parte para com a outra quanto ao
                descumprimento de qualquer uma das obrigações assumidas neste
                contrato não implicará em novação ou renúncia de direito. A
                parte tolerante poderá, a qualquer tempo, exigir da outra parte
                o fiel e cabal cumprimento deste contrato;
                <br />
                Não constituem causa de rescisão contratual, o não cumprimento
                das obrigações aqui assumidas em decorrência de fatos que
                independam da vontade das partes, tais como os que configuram o
                caso fortuito e a força maior previstos no artigo 393 do Código
                Civil Brasileiro;
                <br />
                Se qualquer disposição deste EULA for considerada nula,
                anulável, inválida ou inoperante, nenhuma outra disposição deste
                EULA será afetada como consequência disso e, portanto, as
                disposições restantes deste EULA permanecerão em pleno vigor e
                efeito como se tal disposição nula, anulável, inválida ou
                inoperante não estivesse contida neste EULA;
                <br />O LICENCIADO concorda que a LICENCIANTE possa divulgar o
                fechamento contrato para fins comerciais, fazendo menção ao nome
                e à marca do LICENCIADO em campanhas comerciais, podendo,
                inclusive, divulgar mensagens enviadas de forma escrita ou oral,
                por telefone, para uso em sites, jornais, revistas e outras
                campanhas, enquanto vigorar o presente EULA. O LICENCIADO
                aceita, ainda, receber notificações via correio eletrônico sobre
                treinamentos, parcerias e campanhas relacionadas ao SOFTWARE;
                <br />
                Neste ato, o LICENCIANTE expressamente autoriza a LICENCIADA a
                colher e utilizar seus dados técnicos e operacionais presentes
                no SOFTWARE, para fins de estudos e melhorias no SOFTWARE.
                <br />A LICENCIANTE poderá, ao seu exclusivo critério, a
                qualquer tempo, e sem a necessidade de comunicação prévia ao
                Usuário:
              </p>
              <ul>
                <li>
                  Encerrar, modificar ou suspender, total ou parcialmente, o
                  acesso do LICENCIADO ao SOFTWARE, quando referido acesso ou
                  cadastro estiver em violação das condições estabelecidas neste
                  Termo de Uso;
                </li>
                <li>
                  Excluir, total ou parcialmente, as informações cadastradas
                  pelo LICENCIADO que não estejam em consonância com as
                  disposições deste Termo de Uso;
                </li>
                <li>
                  Acrescentar, excluir ou modificar o Conteúdo oferecido no
                  site.
                </li>
              </ul>
              <p>
                A LICENCIANTE ainda poderá, ao seu exclusivo critério,
                suspender, modificar ou encerrar as atividades do SOFTWARE,
                mediante comunicação prévia ao LICENCIADO, disponibilizando
                formas e alternativas de extrair as informações, salvo nas
                hipóteses de caso fortuito ou força maior.
                <br />A LICENCIANTE poderá, por meio de comunicação no e-mail
                indicado pelo LICENCIADO em seu cadastro, ou por meio de aviso
                no site definir preços para oferecimento de determinados
                conteúdos e/ou serviços, ainda que inicialmente tenham sido
                ofertados de forma gratuita, sendo a utilização dos mesmos, após
                o referido aviso, considerada como concordância do Usuário com
                tais preços.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Estamos reforçando o que já falamos anteriormente inclusive que
                os dados que você informou no TECIMOB são seus e não vamos
                usa-los de forma isolada para “bisbilhotar” sua vida.
                <br />
                <br />
                Nós ficamos tão felizes que você é nosso cliente que queremos
                falar isso para todo mundo.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Das modificações no presente termo</h3>
              <p>
                O LICENCIANTE poderá modificar, alterar e/ou ajustar estes
                termos a qualquer tempo. Essas modificações, alterações e/ou
                ajustes deverão ser efetivos e imediatos assim que estes se
                tornem públicos. Para este efeito, o USUÁRIO deverá rever os
                termos de uso periodicamente, sendo certo que o acesso ou uso
                contínuo do SOFTWARE pelo LICENCIADO subordina-se à aceitação
                dos termos em vigor.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                As vezes ajustes são necessários, as coisas mudam, estes termos
                também
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Da Lei Aplicável</h3>
              <p>
                Este EULA será regido, interpretado e se sujeitará às leis
                brasileiras e, em caso de inadimplência das obrigações ora
                contratadas, LICENCIADO e LICENCIANTE, desde logo elegem, de
                forma irrevogável e irretratável, o foro da Comarca da Cidade de
                Tubarão, Estado de Santa Catarina, para dirimir quaisquer
                dúvidas ou controvérsias oriundas deste CONTRATO, com a exclusão
                de qualquer outro, por mais privilegiado que seja. Caso seja
                dado motivo a indenização, o valor devido não excederá o valor
                equivalente aos doze (12) meses anteriores ao fato que gerou a
                responsabilização.
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Este contrato se sujeita às leis brasileiras e caso houver algum
                problema judicial ele deverá ser resolvido em Tubarão-SC.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Das definições</h3>
              <p>
                Os termos utilizados neste contrato deverão ser interpretados e
                usados conforme definições abaixo, seja no singular ou plural:
                <br />
                Informações de conta: informações e dados relativos a cartões de
                crédito, logins, senhas e demais informações necessárias para
                acessar, coletar, armazenar, usar e tratar as informações
                obtidas.
                <br />
                Informações pessoais: qualquer informação disponibilizada pelo
                LICENCIADO que o identifique, tais como: nome, endereço, data de
                nascimento, número de telefone, fax, e-mail, número de
                documentos, etc.
                <br />
                Informações: entende-se todas as informações do LICENCIADO
                relacionadas a informações pessoais, financeiras e de conta.
                <br />
                Licenciado: pessoa física ou jurídica, com plena capacidade de
                contratar, que acessa o SOFTWARE da LICENCIANTE por meio do
                site, realizando seu cadastro, aceitando os termos do presente
                EULA e usufruindo das funcionalidades oferecidos.
                <br />
                Software: software de propriedade exclusiva da LICENCIANTE,
                cujas funcionalidades e serviços estão disponibilizados pelo
                site, por meio do qual as Informações Financeiras do LICENCIADO
                serão fornecidas diretamente por ele ou coletadas diretamente
                nos sites das instituições financeiras de maneira automatizada
              </p>
            </div>
            <div className="termos-de-uso__Right-u2pywt-3 gMpjqt">
              <h3>Basicamente,</h3>
              <p>
                Um pequeno dicionário do que cada uma dessas palavras querem
                dizer perante este termo.
              </p>
            </div>
          </article>
          <article className="termos-de-uso__Term-u2pywt-1 caKhHK">
            <div className="termos-de-uso__Left-u2pywt-2 gYGfzF">
              <h3>Data da última revisão do documento</h3>
              <p>Tubarão, 22 de Setembro de 2022.</p>
            </div>
          </article>
        </section>
      </Container>
    </>
  );
}

BlankPage.defaultProps = defaultProps;
BlankPage.propTypes = propTypes;

export default BlankPage;
