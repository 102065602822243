import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { MdAddCircle } from 'react-icons/md';
// Image
import { FileInput } from 'components/Form';
// Helpers
import { imageChangeSize } from 'lib/image-helpers';

const propTypes = {
  name: PropTypes.string.isRequired,
  fileUrl: PropTypes.string
};

const defaultProps = {
  name: 'image',
  fileUrl: null
};

const NoPhoto = () => (
  <div>
    <MdAddCircle />
    <p>Foto</p>
  </div>
);

const FieldAvatarUpload = ({ fileUrl, name, onChange }) => (
  <div
    className="AvatarUpload h-hover"
    style={{
      backgroundImage: `url(${imageChangeSize(fileUrl, 'small', 'cover')})`
    }}
  >
    <Field
      name={name}
      component={FileInput}
      parse={value => {
        if (!value) return;
        return value[0];
      }}
      onChange={(...rest) => {
        if (onChange) {
          setTimeout(() => onChange(...rest), 10);
        }
      }}
    >
      <div
        className={classNames({
          AvatarUpload__noImage: !fileUrl,
          AvatarUpload__hasImage: fileUrl
        })}
      >
        {!fileUrl ? <NoPhoto /> : <span className="h-hover__show">Trocar</span>}
      </div>
    </Field>
  </div>
);

FieldAvatarUpload.propTypes = propTypes;
FieldAvatarUpload.defaultProps = defaultProps;

export default FieldAvatarUpload;
