import React from 'react';
import ModalDefault from '../ModalDefault';
import ModalHeader from '../ModalDefault/components/ModalHeader';

export default (props) => {
  const { matcheds_new_count } = props.infos;

  return (
    <ModalDefault
      {...props}
      renderHeader={() => <ModalHeader property={props.infos} />}
    />
  );
};
