import React from 'react';
// Components
import Container from 'pages/Site/components/ContainerSite';
import FormAbout from './components/FormAbout';
import MainTitle from 'components/MainTitle';
// Services
import * as aboutService from 'services/sites/about';
// HOC
import { withFormData } from 'HOC/withFormData';

class About extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  componentDidMount() {
    this.props.requestFormData();

    aboutService.getOne().then(res => {
      this.props.receiveFormData(res.data);
      return res;
    });
  }

  handleSubmit = values => aboutService.update(values);

  handleSubmitSuccess = res => {
    this.props.receiveFormData(res.data);
  };

  render() {
    const { formData } = this.props;

    return (
      <>
        <MainTitle
          title="Editar Página Sobre Nós"
          text="Edite, ordene, ou crie páginas novas para o menu do seu site."
        />
        <Container padding={false}>
          <FormAbout
            initialValues={formData.data}
            onSubmit={this.handleSubmit}
            onSubmitSuccess={this.handleSubmitSuccess}
          />
        </Container>
      </>
    );
  }
}

export default withFormData('About')(About);
