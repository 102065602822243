import React from 'react';
import { Row } from 'react-flexbox-grid';
import { reduxForm, Field } from 'redux-form';
import { Input } from 'components/Form';
import validate from './validate';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import { normalizePhone } from 'lib/formHelpers';

const Form = ({ handleSubmit, handleClose, isSubmitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Field xs={6} required label="Nome" name="name" component={Input} />
        <Field xs={6} required label="E-mail" name="email" component={Input} />
      </Row>
      <Row>
        <Field
          xs={6}
          required
          label="Telefone"
          name="phone"
          component={Input}
          parse={normalizePhone}
        />
        <Field
          xs={6}
          required
          label="CPF/CNPJ"
          name="cpf_cnpj"
          component={Input}
        />
      </Row>
      <ModalFooter style={{ marginTop: '5px' }}>
        <Button
          type="button"
          onClick={handleClose}
          color="white"
          colorText="secondary"
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={isSubmitting}>
          Enviar dados
        </Button>
      </ModalFooter>
    </form>
  );
};

export default reduxForm({
  form: 'FormPromotionPortal',
  validate,
  enableReinitialize: true
})(Form);
