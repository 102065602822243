import styled from 'styled-components';

export const Wrapper = styled.form`
  display: flex;
  background: #ffffff;
  border: 1px solid #d3dceb;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .FormGroup {
    margin-bottom: 0;
  }
`;
export const FieldContent = styled.div`
  padding: 20px;
  flex-grow: 1;
`;
