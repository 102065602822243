import React, { useCallback, useEffect } from 'react';
import { reduxForm, initialize } from 'redux-form';

// Components
import MainTitle from 'components/MainTitle';
import SeeExample from '../../components/SeeExample';
import FormFixedBar from '../../components/FormFixedBar';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';
import Container from 'components/Container';
import ListCheck from 'components/ListCheck';

// Services
import * as characteristicsService from 'services/buildings/characteristics';
import * as condosService from 'services/condos';
import { useRequest } from 'hooks/useRequest';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import Button from 'components/Button';
import Can from 'containers/Can';
import { USER_PERMISSIONS } from 'constants/rules';
import Popover from 'components/Popover';
import FormCharacteristics from 'components/FormCharacteristics';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';

const Characteristics = ({ handleSubmit, initialize }) => {
  const dispatch = useDispatch();
  const { enterpriseId } = useEnterprise();

  const { data, isFetching, fetchData } = useRequest({
    request: condosService.getCondosCharacteristics,
  });

  const { data: initialValues } = useRequest({
    request: characteristicsService.get,
    params: enterpriseId,
  });

  const handleAddCharacteristic = useCallback((data) => {
    return condosService.createCondosCharacteristics(data);
  }, []);

  const handleAddCharacteristicSuccess = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteItem = useCallback(
    ({ id, title, ...rest }) =>
      async () => {
        try {
          dispatch(
            openConfirmation({
              title: `Remover característica`,
              text: `Deseja realmente remover a característica ${title}?`,
              request: () => condosService.removeCondosCharacteristics(id),
              onSuccess: () => {
                fetchData();
              },
            })
          );
        } catch {
          return false;
        }
      },
    [fetchData]
  );

  useEffect(() => {
    initialize(initialValues);
  }, [initialValues]);

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Características"
        text="Defina as características do empreendimento."
      >
        <Can run={USER_PERMISSIONS.EDIT_REGISTRATIONS}>
          <Popover
            onSubmit={handleAddCharacteristic}
            onSubmitSuccess={handleAddCharacteristicSuccess}
            component={FormCharacteristics}
          >
            <Button size="medium" color="secondary">
              Cadastrar Característica do condomínio
            </Button>
          </Popover>
        </Can>
        <SeeExample />
      </MainTitle>

      <Container>
        <ListCheck
          cols={4}
          name="characteristics"
          data={data}
          isFetching={isFetching}
          handleDeleteItem={handleDeleteItem}
        />
      </Container>

      <FormFixedBar />
    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: async (values, dispatch) => {
    await characteristicsService.update(values);
    dispatch(initialize(FORM_NAME, values));
  },
})(Characteristics);
