import createDataReducer from 'modules/data';
import * as financialIndexService from 'services/financialIndex';

export const {
  reducer: financialIndexesReducer,
  selectors: financialIndexesSelectors,
  actionCreators: financialIndexesActions
} = createDataReducer('filter/financialIndexes');

export const fetchFinancialIndexes = params => async dispatch => {
  dispatch(financialIndexesActions.request());
  const { data } = await financialIndexService.getList(params);
  dispatch(financialIndexesActions.receive(data));
};

export default financialIndexesReducer;
