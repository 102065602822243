import { PAYMENT_METHODS } from 'constants/constants';
import useFormValue from 'hooks/useFormValue';
import { MdCreditCard } from 'react-icons/md';
import { Field } from 'redux-form';
import PaymentMethod from './components/PaymentMethod';
import IconPix from './components/PaymentMethod/IconPix';
import CreditCard from './pages/CreditCard';
import Pix from './pages/Pix';
import { SectionHeader, Step } from 'containers/ModalRoot/ModalPayment/styles';
import { PaymentMethodForm, PaymentMethods } from './styles';
import React from 'react';
import { ModalTemplate } from 'components/Modal';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';

const Payment = () => {
  const { handleClose } = useModalContractExtra();
  const receiving_method = useFormValue('receiving_method');

  function renderPaymentMethodForm() {
    switch (receiving_method) {
      case PAYMENT_METHODS.CREDIT_CARD:
        return <CreditCard />;
      case PAYMENT_METHODS.PIX:
        return <Pix />;
      default:
        return <CreditCard />;
    }
  }

  return (
    <ModalTemplate
      title="Pagamento"
      text="Escolha a forma de pagamento"
      handleClose={handleClose}
      titleActions={
        <Step>
          Etapa
          <br />
          3/3
        </Step>
      }
    >
      <SectionHeader>Forma de pagamento</SectionHeader>

      <PaymentMethods>
        <Field
          name="receiving_method"
          label="Cartão Crédito"
          component={PaymentMethod}
          icon={MdCreditCard}
          props={{ value: PAYMENT_METHODS.CREDIT_CARD }}
        />

        <Field
          name="receiving_method"
          label="Pix"
          component={PaymentMethod}
          icon={IconPix}
          props={{ value: PAYMENT_METHODS.PIX }}
        />
      </PaymentMethods>

      <PaymentMethodForm>{renderPaymentMethodForm()}</PaymentMethodForm>
    </ModalTemplate>
  );
};

export default Payment;
