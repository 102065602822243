export default (values, props, fields) => {
  const errors = {};

  const fieldsRequired = [
    'won_people_id',
    'won_price',
    'won_user_id',
    'won_user_commission'
  ];

  fieldsRequired.map(field => {
    if (!values[field]) {
      errors[field] = 'Campo obrigatório';
    }
    return field;
  });

  return errors;
};
