import { useRequest } from 'hooks/useRequest';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { Card } from './styles';

import { openModalBankSlip } from 'modules/modal.js';
import { MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import * as bankSlipService from 'services/financial/bankSlip';
import { NoCard } from 'pages/PlanHistory/containers/CreditCardContainer/styles';
import Button from 'components/Button';
import BoxPlan from '../../components/BoxPlan';

export default function BillingDetails() {
  const dispatch = useDispatch();

  const { data, setData } = useRequest({
    request: bankSlipService.getOne,
    initialState: {},
  });

  const handleClickUpdate = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        openModalBankSlip({
          bank_slip_id: data?.id,
          onSubmitSuccess: (bankSlip) => {
            setData(bankSlip);
          },
        })
      );
    },
    [dispatch, data]
  );

  const isLegalPerson = useMemo(() => {
    return data?.type === '2' || data?.type === 2;
  }, [data]);

  if (_.isEmpty(data)) return null;

  if (!data.id) {
    return (
      <div className="h-margin-top--15">
        <h5 className="h-margin-bottom--10">
          Dados para emissão da Nota Fiscal
        </h5>
        <BoxPlan.cards position="center">
          <NoCard>
            Você ainda não configurou <br />
            seus dados de faturamento
            <div>
              <Button
                size="small"
                className="h-margin-top--10"
                onClick={handleClickUpdate}
              >
                Informar dados
              </Button>
            </div>
          </NoCard>
        </BoxPlan.cards>
      </div>
    );
  }

  return (
    <div>
      <h5 className="h-margin-bottom--10">Dados para emissão da Nota Fiscal</h5>
      <Card>
        <p>
          Nome: <strong>{data?.name}</strong>
        </p>
        <p>
          {isLegalPerson ? 'CNPJ' : 'CPF'}: <strong>{data?.cpf_cnpj}</strong>
        </p>
        <p>
          <a href="./" className="h-link" onClick={handleClickUpdate}>
            <MdEdit /> Editar dados
          </a>
        </p>
      </Card>
    </div>
  );
}
