import { HTTP } from 'lib/HTTP';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import { bindActionCreators } from 'redux';
// Modules
import {
  openModalPerson,
  openModalSendMail,
  openModalShareProperty,
} from 'modules/modal';
import { getOne } from 'services/settings/messages';
import {
  discardMultipleMatched,
  fetchDiscardMatched,
  getInitalData,
  peopleActions,
  peopleSelectors,
  propertyIsFetchingSelector,
  propertySelector,
  resetData,
} from './module';
// Components
import { plural } from 'lib/text';
import ListPropertyMatched from './components/ListPropertyMatched';

class PropertyPeopleMatched extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps?.match?.params?.property_id !==
      this.props?.match?.params?.property_id
    ) {
      this.fetchData();
    }
  }

  fetchData = () => {
    const {
      match: { params },
      history,
    } = this.props;

    // Busca dados iniciais
    this.props
      .getInitalData(params)
      .then(({ data }) => {
        // Se não tem mais nenhum cliente
        // redireciona para a página de properties-matched/show
        if (!data?.people_matched?.length) {
          history.push('/crm/matched-properties');
        }
      })
      .catch(() => {
        Alert.success('Problema ao buscar os dados do imóvel');
        history.push('/crm/matched-properties');
      });
  };

  handleToggleSelected = (person) => () => {
    // return this.props.toggleSelected(person);
    if (person.isSelected) {
      return this.props.removeSelected(person.id);
    }

    return this.props.addSelected(person.id);
  };

  openSendMail = (people) => (e) => {
    e.stopPropagation();
    this.props.openModalSendMail({
      people,
      onSubmitSuccess: () => {
        // Atualiza a listagem quando da tudo certo no envio de e-mail
        this.fetchData();
      },
    });
  };

  onClickDiscardPerson = (person) => async (e) => {
    e.stopPropagation();
    await this.props.fetchDiscardMatched(person);
    this.fetchData();
  };

  message = async () => {
    const {
      data: { matched },
    } = await getOne();
    return matched;
  };

  shareWhatsapp = (person) => async (e) => {
    e.stopPropagation();

    const { openModalShareProperty } = this.props;
    const { property } = this.props;

    openModalShareProperty({
      title: 'Compartilhar imóvel',
      type: 'share',
      properties: [property?.id],
      initialValues: {
        person: {
          ...person,
          cellphone_number: person.primary_phone.number,
          person_cellphone_ddi: person.primary_phone.ddi,
          person_cellphone_iso2: person.primary_phone.iso2,
        },
        person_cellphone_ddi: person.primary_phone.ddi,
        person_cellphone_iso2: person.primary_phone.iso2,
      },
      onSubmitSuccess: () => {
        HTTP.post(
          `properties/matcheds/${person.matched_id}/send-whatsapp`
        ).then(() => {
          this.fetchData();
        });
      },
    });
  };

  /**
   * Abre a modal e se existir alguma alteração atualiza a lista de usuário
   * @param person
   */
  onClickPerson = (person) => async () => {
    const { openModalPerson } = this.props;

    openModalPerson(person, {
      afterClose: this.fetchData,
    });
  };

  getPeopleSelected = () => {
    return this.props.people.filter((person) => person.isSelected);
  };

  getMatchedIds = () =>
    this.getPeopleSelected().map(({ matched_id }) => matched_id);

  getAllMatchedIds = () =>
    this.props.people.map(({ matched_id }) => matched_id);

  handleDiscardSelecteds = () => {
    const { discardMultipleMatched, property } = this.props;

    // Pega os ids que estão selecionados
    const matchedIds = this.getMatchedIds();

    discardMultipleMatched({
      propertyId: property?.id,
      matchedIds,
      callBack: () => {
        const totalDiscarted = matchedIds.length;

        this.fetchData();
        Alert.success(
          plural(totalDiscarted, 'cliente descartado', 'clientes descartados')
        );
      },
    });
  };

  handleDiscardAll = () => {
    const { discardMultipleMatched, property } = this.props;

    // Pega os ids que estão selecionados
    const matchedIds = this.getAllMatchedIds();

    discardMultipleMatched({
      propertyId: property?.id,
      matchedIds,
      callBack: () => {
        this.fetchData();
        Alert.success('Todos os clientes descartados!');
      },
      title: 'Deseja realmente descartar todos?',
      submitButtonText: 'Descartar todos',
    });
  };

  render() {
    const { isFetching, people, property, selected } = this.props;

    return (
      <>
        <Helmet>
          <title>Imóveis</title>
        </Helmet>
        <ListPropertyMatched
          isFetching={isFetching}
          people={people}
          property={property}
          selected={selected}
          openSendMail={this.openSendMail}
          onClickDiscardPerson={this.onClickDiscardPerson}
          shareWhatsapp={this.shareWhatsapp}
          handleToggleSelected={this.handleToggleSelected}
          onClickPerson={this.onClickPerson}
          handleDiscardSelecteds={this.handleDiscardSelecteds}
          handleDiscardAll={this.handleDiscardAll}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  property: propertySelector(state),
  isFetching: propertyIsFetchingSelector(state),
  people: peopleSelectors.getAllWithSelected(state),
  selected: peopleSelectors.getSelected(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...peopleActions,
      getInitalData,
      openModalPerson,
      resetData,
      fetchDiscardMatched,
      openModalSendMail,
      discardMultipleMatched,
      openModalShareProperty,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyPeopleMatched);
