import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactPagination from 'react-paginate';

import Icon from '../../components/Icon';

const propTypes = {
  position: PropTypes.oneOf(['left', 'center', 'right'])
};

const defaultProps = {
  scrollToTop: true,
  position: 'center',
  previousLabel: (
    <div>
      <Icon name="chevron-left" />
      Anterior
    </div>
  ),
  nextLabel: (
    <div>
      Próximo
      <Icon name="chevron-right" />
    </div>
  ),
  pageClassName: 'Pagination__item',
  breakClassName: 'Pagination__break',
  pageLinkClassName: 'Pagination__link',
  activeClassName: 'is-active',
  disabledClassName: 'is-disabled',
  previousClassName: 'Pagination__directions Pagination__prev',
  nextClassName: 'Pagination__directions Pagination__next',
  pageRangeDisplayed: 10,
  marginPagesDisplayed: 2
};

function Pagination({
  scrollToTop,
  pagination,
  marginPagesDisplayed,
  pageRangeDisplayed,
  position,
  className,
  pageCount,
  onPageChange,
  pageClassName,
  breakClassName,
  pageLinkClassName,
  activeClassName,
  disabledClassName,
  previousClassName,
  nextClassName,
  previousLabel,
  nextLabel,
  ...props
}) {
  const defaultProps = useMemo(() => ({
    containerClassName: classnames('Pagination', className, {
      [`h-flex--${position}`]: position
    }),
    pageRangeDisplayed,
    marginPagesDisplayed,
    onPageChange: (...rest) => {
      if (onPageChange) onPageChange(...rest);

      if (scrollToTop) {
        const $wrapper = document.querySelector('.Wrapper__inner.parent');

        if ($wrapper) {
          $wrapper.scrollTo(0, 0);
        }
      }
    },
    pageClassName,
    breakClassName,
    pageLinkClassName,
    activeClassName,
    disabledClassName,
    previousClassName,
    nextClassName,
    position,
    previousLabel,
    nextLabel
  }));

  if (pagination) {
    const hasPagination = pagination.total_pages > 1;
    if (!hasPagination) return null;
    return (
      <ReactPagination
        {...defaultProps}
        forcePage={pagination.current_page - 1}
        pageCount={pagination.total_pages}
      />
    );
  }

  return pageCount > 1 ? (
    <ReactPagination {...defaultProps} pageCount={pageCount} {...props} />
  ) : null;
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
