import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  td {
    padding: 20px;
  }

  tr ~ tr td {
    border-top: 1px solid #f1f4f9;
  }
`;

export const CellSmall = styled.td`
  width: 0;
  white-space: nowrap;
  text-align: right;
`;
export const Account = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const Icon = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 4px;
`;
export const Text = styled.p``;
export const Price = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #0063c0;
`;
