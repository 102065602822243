export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export const COLORS = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  SUCCESS: 'success',
  DANGER: 'danger',
  CAUTION: 'caution'
};

export const COLOR_BY_NAME = {
  [COLORS.DEFAULT]: '#E2E2E2',
  [COLORS.PRIMARY]: '#1c0c1e',
  [COLORS.SECONDARY]: '#0084f4',
  [COLORS.TERTIARY]: '#595bd3',
  [COLORS.SUCCESS]: '#31d084',
  [COLORS.DANGER]: '#f84343',
  [COLORS.CAUTION]: '#fd9526'
};
