import {
  CityField,
  StateField
} from 'containers/LocationFields';
import NeighborhoodOrZone from 'containers/NeighborhoodOrZone';
import useFormValue from 'hooks/useFormValue';
import { Row } from 'react-flexbox-grid';
import { GroupFields } from './styles';

function NewLocationField({ onChange }) {
  // const { form } = useReduxForm();

  // const cities = useSelector(LocationSelector.getData(form, 'by_city_id'));

  const stateId = useFormValue('by_state_id');
  const cityId = useFormValue('by_city_id');

  // Pega a cidade que está selecionada
  // const city = useMemo(() => {
  //   return cities?.find((city) => city.id === cityId);
  // }, [cities, cityId]);

  return (
    <>
      <GroupFields>
        <StateField
          noCountry
          canUnregister={false}
          name="by_state_id"
          neighborhoodName="by_neighborhood_or_zone_id"
          cityName="by_city_id"
          placeholder="UF"
          labelKey="acronym"
          formGroupClassName="FormGroup--state"
          onChange={onChange}
        />
        <CityField
          canUnregister={false}
          stateId={stateId}
          name="by_city_id"
          neighborhoodName="by_neighborhood_or_zone_id"
          placeholder="Cidade"
          className="city_field"
          formGroupClassName="FormGroup--city"
          onChange={onChange}
        />
      </GroupFields>
      <Row>
        <NeighborhoodOrZone xs={12} cityId={cityId} onChange={onChange} />
      </Row>
    </>
  );
}

export default NewLocationField;
//
