import React from 'react';
import PropTypes from 'prop-types';

// import from './styles';

function TableData({ mailboxes, users }) {
  return (
    <div
      style={{
        maxHeight: '410px',
        overflowY: 'auto',
        margin: '-20px -21px -20px -19px',
      }}
    >
      <table className="Table">
        <thead>
          <tr>
            <th style={{ position: 'sticky', top: '0' }}>Serviços</th>
            <th style={{ position: 'sticky', top: '0' }}>Preço</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map(({ id, name, price }) => (
              <tr key={id}>
                <td>{name}</td>
                <td>{price}</td>
              </tr>
            ))}
          {mailboxes &&
            mailboxes.map(({ mailbox, price }) => (
              <tr key={mailbox}>
                <td>{mailbox}</td>
                <td>{price}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

TableData.propTypes = {
  mailboxes: PropTypes.arrayOf(
    PropTypes.shape({
      mailbox: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    })
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    })
  ),
};
TableData.defaultProps = {};

export default TableData;
