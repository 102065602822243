import React from 'react';
import { MdAttachment } from 'react-icons/md';

import CardList from 'components/CardList';

const propTypes = {};
const defaultProps = {};

const renderFiles = files =>
  files &&
  files.length > 0 && (
    <div
      style={{
        margin: '10px -20px -15px',
        padding: '9px 20px 10px',
        borderTop: '1px solid #D2DBE3'
      }}
    >
      {files.map(({ file_name, file_url }) => (
        <a
          href={file_url}
          download={file_name}
          target="_blank"
          rel="noopener noreferrer"
          className="h-link h-margin-right--5 h-margin-bottom--5"
          style={{ display: 'inline-block' }}
        >
          <MdAttachment />
          {file_name}
        </a>
      ))}
    </div>
  );

const ListInteractions = ({ data }) => {
  if (!data || data.length === 0) return null;
  return data.map(
    ({ id, created_at, message, responsible, status, is_me, attachments }) => (
      <CardList
        key={id}
        actionRenderer={() => {}}
        className="h-margin-top--15"
        style={{
          background: is_me ? '#FFFFFF' : 'rgba(0, 99, 192, 0.05)'
        }}
      >
        <CardList.content>
          <div
            className="h-color--primary"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </CardList.content>
        <CardList.footer>
          {created_at} | <strong>{responsible.name}</strong> |{' '}
          <span style={{ color: status.color }}>{status.title}</span>
        </CardList.footer>
        {renderFiles(attachments)}
      </CardList>
    )
  );
};

ListInteractions.propTypes = propTypes;
ListInteractions.defaultProps = defaultProps;

export default ListInteractions;
