import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Cria uma categoria
 * @param title
 * @param color
 * @param is_debit
 * @param parent_id
 */
export const storeCategory = ({ title, color, is_debit, parent_id }) => {
  const message = parent_id
    ? 'Sub-categoria cadastrada'
    : 'Categoria cadastrada';

  return api
    .create('financial/categories', {
      title,
      color,
      is_debit,
      parent_id,
    })
    .then(responseMessage(message));
};

/**
 * Atualiza a categoria
 * @param id
 * @param title
 * @param color
 * @param parent_id
 */
export const updateCategory = ({ id, title, color, parent_id }) => {
  const message = parent_id ? 'Sub-categoria editada' : 'Categoria editada';

  return api
    .update(`financial/categories/${id}`, {
      color,
      title,
    })
    .then(responseMessage(message));
};

/**
 * Lista as categorias
 * @param filter
 * @param props
 */
export const indexCategory = ({ filter, ...props }) => {
  return api.get(`financial/categories`, {
    filter,
    include: 'children',
    ...props,
  });
};

/**
 * Busca apenas uma categoria
 * @param id
 */
export const showCategory = (id) => api.getOne('financial/categories', id);

export const deleteCategory = ({ id, parent_id }) => {
  const message = parent_id ? 'Sub-categoria deletada' : 'Categoria deletada';

  return api.delete('financial/categories', id).then(responseMessage(message));
};
