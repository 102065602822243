import api from '../index';

/**
 * @typedef Preview
 * @param {string} Nome
 * @param {string} Celular
 * @param {string} E-mail
 * @param {string} Dt. Nascimento
 * @param {string} Tipo Pessoa
 * @param {string} CPF/CNPJ
 * @param {string} RG/IE
 * @param {string} Profissão
 * @param {string} Origem Captação
 * @param {string} CEP
 * @param {string} UF
 * @param {string} Cidade
 * @param {string} Bairro
 * @param {string} Logradouro
 * @param {string} Número
 * @param {string} Complemento
 * @param {string} Anotação
 */

/**
 * @typedef Importation
 * @param {string} updated_at
 * @param {string} created_at
 * @param {string} id
 * @param {string} user_id
 * @param {string} real_estate_id
 * @param {boolean} should_capitalize_words
 * @param {boolean} should_update_existing
 * @param {Preview[]} Preview
 */

/**
 * Cria uma nova importação
 * @param data
 * @returns {Promise<Importation>}
 */
export const createImport = data => api.updateWithFile('people/imports', data);

/**
 * Confirma uma importação
 * @param id - id da importação
 * @param data -
 * @returns {Promise}
 */
export const confirmImport = ({
  id,
  should_capitalize_words,
  should_update_existing
}) =>
  api.create(`people/imports/${id}/confirm`, {
    should_capitalize_words,
    should_update_existing
  });

/**
 * Cancela uma importação em andamento
 * @param id
 * @returns {Promise}
 */
export const cancelImport = id => api.delete(`people/imports/${id}`);
