import Loading from 'components/Loading';
import { useRequest } from 'hooks/useRequest';
import { openModalCondoProperties, openModalProperty } from 'modules/modal';
import { toggleSelected } from 'modules/propertySearch';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { getGrouped } from 'services/condos';
import List from './components/List';
import { CustomModalTemplate } from './styles';

function ModalCondoProperties({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  condoId,
  text,
  filter,
  canSelect = true,
}) {
  const dispatch = useDispatch();

  const { data, isFetching } = useRequest({
    request: () => getGrouped(condoId, { filter }),
  });

  const openMyself = () => {
    dispatch(openModalCondoProperties({
      condoId,
      text,
      filter,
    }));
  }

  const handleOpenProperty = (property) => {
    dispatch(openModalProperty({
      property,
      afterClose: () => openMyself(),
    }));
  }

  const handleSelectProperty = (id) => {
    dispatch(toggleSelected(id));
  }

  return (
    <Modal
      {...modalConfig}
      className="Modal Modal--large"
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <CustomModalTemplate
        title={data?.title ?? '-'}
        text={text}
        handleClose={handleClose}
      >
        <Loading isVisible={isFetching} isBlock />
        <List
          properties={data?.properties ?? []}
          handleOpenProperty={handleOpenProperty}
          handleSelectProperty={handleSelectProperty}
          canSelect={canSelect}
        />
      </CustomModalTemplate>
    </Modal>
  );
}

export default ModalCondoProperties;
