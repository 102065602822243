import Wrapper from "components/Wrapper";
import Aside from "./components/Aside";
import { AsideProvider } from "./contexts/aside";
import { IdProvider } from "./contexts/id";
import { PathProvider } from "./contexts/path";
import Routes from "./Routes";
import { InnerWrapper } from "./styles";

const CondoStore = () => {
  return (
    <PathProvider>
      <IdProvider>
        <AsideProvider>
          <Wrapper full>
            <Aside />
            <InnerWrapper>
              <Wrapper.container>
                <Routes />
              </Wrapper.container>
            </InnerWrapper>
          </Wrapper>
        </AsideProvider>
      </IdProvider>
    </PathProvider>
  );
}

export default CondoStore;
