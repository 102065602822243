export function calculatePercentage(value, min, max) {
  if (value < min || value > max) {
    throw new Error('The default value is outside the specified range.');
  }

  return ((value - min) / (max - min)) * 100;
}

export function getPropsByValue(value) {
  switch (true) {
    case value > 8:
      return {
        label: 'Ótimo',
        color: '#31D084',
      };
    case value > 6:
      return { label: 'Bom', color: '#ffd501' };
    case value > 4:
      return { label: 'Regular', color: '#FF9702' };
    default:
      return { label: 'Ruim', color: '#F84343' };
  }
}

export function getConfigByValue(value, min, max) {
  const percentage = calculatePercentage(value, min, max);
  const props = getPropsByValue(value);

  return {
    width: percentage + '%',
    color: props.color,
    label: props.label,
  };
}
