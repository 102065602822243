import {
  HelpText,
  Item,
  ItemAnnualTaxes,
  Price,
  Title,
  Wrapper,
} from './styles';
import Warranties from 'containers/ModalRoot/ModalProperty/pages/Record/components/Warranties';

function PriceBar({ property }) {
  const territorialTaxType =
    property.territorial_tax_type === 2 ? 'Mensal' : 'Anual';

  return (
    <Wrapper>
      <Item>
        <Title>
          Preço <HelpText>({property.transactionName})</HelpText>
        </Title>
        <Price>{property.calculated_price || '-'}</Price>
      </Item>
      <Item>
        <Title>Condomínio</Title>
        <Price>{property.condominium_price || '-'}</Price>
      </Item>
      <Item>
        <Title>
          Taxas <HelpText>(Mensal)</HelpText>
        </Title>
        <Price>{property.taxes_price || '-'}</Price>
      </Item>
      {property.territorial_tax_type === 2 && (
        <Item>
          <Title>
            IPTU <HelpText>'(Mensal)</HelpText>
          </Title>
          <Price>{property.territorial_tax_price || '-'}</Price>
        </Item>
      )}
      {!property.territorial_tax_type && (
        <Item>
          <Title>
            IPTU <HelpText>(Mensal)</HelpText>
          </Title>
          <Price>{property.territorial_tax_price || '-'}</Price>
        </Item>
      )}
      {property.transaction === 2 && (
        <Item>
          <Title>Total</Title>
          <Price>{property.total_price || '-'}</Price>
        </Item>
      )}
      <Warranties property={property} />
      {property.territorial_tax_price && property.territorial_tax_type === 1 ? (
        <>
          <span className="h-flex__cell--grow" />
          <ItemAnnualTaxes>
            <Title>
              IPTU{' '}
              {property.territorial_tax_type === 1 && (
                <HelpText>({territorialTaxType})</HelpText>
              )}
            </Title>
            <Price>{property.territorial_tax_price || '-'}</Price>
          </ItemAnnualTaxes>
        </>
      ) : (
        ''
      )}
    </Wrapper>
  );
}

export default PriceBar;
