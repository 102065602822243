import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
// Components
import { Input } from '../Form';
import Button from '../Button';
import Label from '../Label';
// Moment
import moment from '../../lib/moment';
// Helpers
import { normalizeDate } from '../../lib/formHelpers';
import { isValidDate } from '../../lib/date-helpers';

// Validate

const LabelPop = ({ children, ...props }) => (
  <Label {...props} type="button" className="h-margin-right--5">
    {children}
  </Label>
);

class FormPropertyRenovation extends React.Component {
  static defaultProps = {};
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  state = {
    canDisableRenovation: false,
  };

  getDate() {
    const { next_review_at_field } = this.props;
    return isValidDate(next_review_at_field)
      ? next_review_at_field
      : moment().format('DD/MM/YYYY');
  }

  /**
   * Adiciona dias no input
   * @param days
   * @return {Function}
   */
  handleAddDays = (days) => () => {
    const { change } = this.props;
    const date = this.getDate();

    change(
      'next_review_at',
      moment(date, 'DD/MM/YYYY').add(days, 'days').format('DD/MM/YYYY')
    );
  };

  render() {
    const {
      data: { created_at, next_review_at },
      submitFunc,
      handleSubmit,
    } = this.props;

    return (
      <Row
        tagName="form"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <Col xs={12} className="h-margin-bottom--10">
          <p>
            <strong className="h-color--primary">Data de cadastro:</strong>{' '}
            {created_at}
          </p>
          <p>
            <strong className="h-color--primary">Data de renovação:</strong>{' '}
            {next_review_at}
          </p>
        </Col>
        <Field
          xs={12}
          label="Nova data"
          name="next_review_at"
          component={Input}
          normalize={normalizeDate}
        />
        <Col xs={12} className="h-margin-bottom--10">
          <LabelPop handleClick={this.handleAddDays(30)}>+30</LabelPop>
          <LabelPop handleClick={this.handleAddDays(60)}>+60</LabelPop>
          <LabelPop handleClick={this.handleAddDays(90)}>+90</LabelPop>
          dias
        </Col>
        <Col xs={12}>
          <Button type="submit" block color="success">
            Atualizar
          </Button>
          <p className="h-text-center">
            <button
              type="submit"
              onClick={handleSubmit((values) =>
                submitFunc({ ...values, isDisabling: true })
              )}
              className="h-color--secondary h-margin-top--10"
            >
              Desativar renovação
            </button>
          </p>
        </Col>
      </Row>
    );
  }
}

// Selector do formulário
const selector = formValueSelector('FormRenovation');

// Wrapper do formulário de renovação
const WrapperFormRenovation = reduxForm({
  form: 'FormRenovation',
  enableReinitialize: true,
})(FormPropertyRenovation);

export default connect((state) => ({
  next_review_at_field: selector(state, 'next_review_at'),
}))(WrapperFormRenovation);
