import React from 'react';
import styled from 'styled-components';
import { BsPeopleFill } from 'react-icons/bs';

const Wrapper = styled.div`
  width: 100%;
  height: 250px;
  display: grid;
  place-items: center;
`;

const Inner = styled.div`
  display: flex;
  width: 615px;
  align-items: center;
`;

const Icon = styled(BsPeopleFill)`
  width: 100px;
  height: 100px;
  margin-right: 15px;
  color: #5f6470;
`;

const Title = styled.h2`
  font-size: 14px;
  margin-bottom: 10px;
`;

const Text = styled.p``;

function Nodata() {
  return (
    <Wrapper>
      <Inner>
        <Icon />
        <div>
          <Title>Tabela de Leads</Title>
          <Text>
            Aqui são apresentados todos os seus leads recebidos, através de
            todos os canais <br />
            Não há registro de nenhum lead no período selecionado
          </Text>
        </div>
      </Inner>
    </Wrapper>
  );
}

export default Nodata;
