// Widgets.js
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import { createModule } from '../lib/reducer-helpers';
import * as user from '../services/user';
import { closeModal } from './modal';
import msg from '../lib/msg';
import { setToken } from 'modules/login';

const createAction = createModule('modalUserPassword');

// Actions
const REQUEST_INITIAL_VALUES = createAction('REQUEST_VALUES');
const RECEIVE_INITIAL_VALUES = createAction('RECEIVE_VALUES');
const FAILED_INITIAL_VALUES = createAction('FAILED');

// Initial State
const initialState = {
  formData: {},
  meta: {
    isFetching: false
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_INITIAL_VALUES: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isFetching: true
        }
      };
    }
    case RECEIVE_INITIAL_VALUES: {
      return {
        ...state,
        formData: action.formData,
        meta: {
          ...state.meta,
          isFetching: false
        }
      };
    }
    case FAILED_INITIAL_VALUES:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function requestInitialValues() {
  return { type: REQUEST_INITIAL_VALUES };
}

export function receiveInitialValues(formData) {
  return { type: RECEIVE_INITIAL_VALUES, formData };
}

// side effects, only as applicable
// e.g. thunks, epics, etc

export function getInitialValues(id) {
  return async dispatch => {
    // reseta os valores que vem do formulario
    dispatch(receiveInitialValues({}));
    // inicia o request indicando que esta procurando dados
    dispatch(requestInitialValues());

    user.getOne(id).then(({ data }) => {
      dispatch(receiveInitialValues(data));
    });
  };
}

export function updateUserPassword(values) {
  return dispatch => {
    return user
    .update(values)
    .then(async res => {
      if (res?.data?.access_token) {
        await setToken(res?.data)(dispatch);
      }

      Alert.success(msg.update(values));
      dispatch(closeModal());
      return res;
    })
    .catch(() => {
      throw new SubmissionError({
        password: 'Não foi possivel alterar a sua senha'
      });
    });
  };
}

export function handleFormSubmit(values) {
  return dispatch => updateUserPassword(values)(dispatch);
}
