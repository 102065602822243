import React, { useCallback } from 'react';
import Alert from 'react-s-alert';
import Modal from 'react-modal';

// Common Components
import Form from './components/Form';
import { ModalTemplate } from 'components/Modal';

// Services
import * as locationService from 'services/location';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';

function ModalAddCity({
  onClose,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onAddCity,
  initialValues,
}) {
  const _handleClose = (res, dispatch, props) => {
    handleClose();
    if (onClose) onClose(res, dispatch, props);
  };

  const currentRealEstate = useCurrentRealEstate();

  const onSubmit = useCallback((values) => {
    // Service de localização de criação da cidade
    return locationService.createCity(values);
  }, []);

  const onSubmitSuccess = useCallback((res, dispatch, props) => {
    // Verifica se tem algo no callBack da função
    if (onAddCity) onAddCity(res, dispatch, props);

    // Alerta para o usuário
    Alert.success('Cidade e bairro adicionados!');

    // Fecha a modal quando cadastra uma cidade
    _handleClose(res, dispatch, props);
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={_handleClose}
    >
      <ModalTemplate title="Cadastrar cidade" handleClose={_handleClose}>
        <p className="h-margin-bottom--15">
          Digite abaixo o nome da nova cidade
        </p>
        <Form
          initialValues={initialValues ?? currentRealEstate?.relations}
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
          handleClose={_handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalAddCity;
