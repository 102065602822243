import Button from 'components/Button';
import Panel from 'components/Panel';
import Table from 'components/Table';
import { useCan } from 'hooks/useCan';
import { useRequest } from 'hooks/useRequest';
import { MdShare } from 'react-icons/md';
import { getListConnectedPortals } from 'services/settings/portals';
import styled from 'styled-components';
// import { Wrapper } from './styles';

const CustomButton = styled(Button)`
  background-color: #0084f4;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
`;

function PanelPropertyPortals() {
  const { data, isFetching } = useRequest({
    request: getListConnectedPortals,
  });
  const canPublish = useCan({ run: 'PUBLISH_PORTAL_PROPERTY' });
  const canPublishOther = useCan({ run: 'PUBLISH_OTHER_PORTAL_PROPERTY' });

  const hasData = data.length > 0;

  if (!hasData || isFetching) return null;
  if (!canPublish && !canPublishOther) return null;

  return (
    <Panel
      hasData={hasData}
      title="Seleção de imóveis para portal"
      icon={MdShare}
      color="primary"
      renderNoData={() => <>Nenhum Portal conectado</>}
    >
      <Table
        size="small"
        type="full"
        data={data}
        isFetching={isFetching}
        renderItem={(data) => {
          return (
            <tr className="h-hover">
              <td>
                {data.portal.file_url ? (
                  <img
                    src={data.portal.file_url}
                    alt={data.portal.name}
                    height="20px"
                  />
                ) : (
                  data.portal.name
                )}
              </td>
              <td className="Table__cell--small">
                <CustomButton
                  disabled={!canPublish && !canPublishOther}
                  color="tertiary"
                  size="medium"
                  className="h-hover__show"
                  to={`/portals/${data.id}`}
                >
                  Selecionar imóveis
                </CustomButton>
              </td>
            </tr>
          );

          // return (
          //   <tr key={id} className="h-hover h-pointer">
          //     <td width={20}>
          //       <span className="h-text-overflow">{name}</span>
          //     </td>
          //     <td>{renderDanger(renew_date, is_renovation_expired)}</td>
          //     <td className="Table__cell--small">
          //       <Button
          //         size="small"
          //         color="white"
          //         colorText="secondary"
          //         className="h-hover__show"
          //         onClick={this.handleOpenModalFinancial(id)}
          //       >
          //         Atualizar
          //       </Button>
          //     </td>
          //   </tr>
          // );
        }}
      />
    </Panel>
  );
}

export default PanelPropertyPortals;
