// import styles from './styles'
import AlertBig, { InnerContent, TitleContent } from 'components/AlertBig';
import { reduxForm } from 'redux-form';
import iconAlert from '../../assets/icon-alert.png';
import { Col, Row } from 'react-flexbox-grid';
import { Fields } from '../../fields';
import React, { useMemo } from 'react';
import Button from 'components/Button';
import useFormValue from 'hooks/useFormValue';
import { openModalSuccess } from 'modules/modal';
import Alert from 'react-s-alert';
import { handleUploadDocuments, updateUser } from 'modules/users';
import { STORAGE_KEY } from 'containers/CreciAlert/constants';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'modules/login';
import * as userService from 'services/user';

function FormValidation({ handleSubmit }) {
  const currentUser = useSelector(currentUserSelector);
  const { image_creci, image_document } = useFormValue([
    'image_creci',
    'image_document',
  ]);

  const days = currentUser?.creci_expiration;
  const daysTwoDigits = days?.toString?.()?.padStart?.(2, '0');

  const message = useMemo(() => {
    if (days <= 1) {
      return (
        <>
          <strong className="h-color--caution">01</strong> dia restante
        </>
      );
    }

    return (
      <>
        <strong className="h-color--caution">{daysTwoDigits}</strong> dias
        restantes
      </>
    );
  }, [days, daysTwoDigits]);

  const isDisabled = !image_creci || !image_document;

  return (
    <form onSubmit={handleSubmit} className="h-margin-top--15">
      <AlertBig
        image={iconAlert}
        color="#FBA240"
        storageKey={STORAGE_KEY}
        text="Verificar CRECI"
      >
        <InnerContent>
          <TitleContent>
            Você tem {message} para verificar seu CRECI!
          </TitleContent>
          <p>
            <strong>Por que verificar meu CRECI?</strong>
          </p>
          <p>
            O Tecimob é projetado especificamente para imobiliárias e corretores
            de imóveis. Para garantir que o sistema seja utilizado por
            profissionais devidamente habilitados, exigimos que pelo menos um
            corretor tenha seu CRECI validado por nossa equipe. Após essa
            validação inicial, os demais corretores cadastrados poderão ser
            validados diretamente por um administrador da sua conta. Este
            processo assegura que nossa plataforma seja utilizada exclusivamente
            por corretores autorizados, mantendo a qualidade e a confiança no
            serviço oferecido.
            <br />
            <br />
            Caso nenhum CRECI seja validado, os anúncios serão omitidos do site
            até a conclusão do processo de validação.
            <br />
            <br />
            Agradecemos a compreensão e colaboração.
          </p>

          <Row className="h-margin-top--15">
            <Col xs={6}>
              <Fields.Creci />
            </Col>
            <Col xs={6}>
              <Fields.Document />
            </Col>
          </Row>
          <Row end="xs" className="h-margin-top--15">
            <Col xs>
              <Button type="submit" disabled={isDisabled} color="success">
                Enviar para verificação
              </Button>
            </Col>
          </Row>
        </InnerContent>
      </AlertBig>
    </form>
  );
}

export default reduxForm({
  form: 'FormCreciValidation',
  onSubmit: async ({ image_document, image_creci }, dispatch, props) => {
    const currentUser = localStorage.getItem('current-user');

    try {
      if (!image_creci || !image_document) {
        Alert.success('Voce precisa preencher os 2 campos');
        return null;
      }

      if (image_document) {
        await dispatch(
          handleUploadDocuments(currentUser, {
            image: image_document,
            field: 'document_url',
            noUpdate: true,
          })
        );
      }

      if (image_creci) {
        await dispatch(
          handleUploadDocuments(currentUser, {
            image: image_creci,
            field: 'creci_url',
            noUpdate: true,
          })
        );
      }

      // Pega as informações do usuário atualizadas
      // pra mudar o current user que está no estado do redux
      const { data: updatedUser } = await userService.currentUser();

      dispatch(
        updateUser({
          ...updatedUser,
          document_url: updatedUser.document_url,
          creci_url: updatedUser.creci_url,
          creci_status: updatedUser.creci_status,
          creci_expiration: updatedUser.creci_expiration,
        })
      );

      dispatch(
        openModalSuccess({
          title: 'Envio concluido',
          text: 'Seus documentos foram encaminhados para verificação, você pode acompanhar o processo na página inicial',
        })
      );
    } catch {
      Alert.success('Erro ao enviar documentos');
    }
  },
})(FormValidation);
