export const CLASSNAME = {
  base: 'Modal Modal--small Modal--center',
  afterOpen: 'Modal--after-open',
  beforeClose: 'Modal--before-close',
};

export const OVERLAY_CLASSNAME = {
  base: `ModalOverlay ModalOverlay--modal-center`,
  afterOpen: 'ModalOverlay--after-open',
  beforeClose: 'ModalOverlay--before-close',
};
