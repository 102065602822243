import img from 'assets/img/noData/notes.svg';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import { useRequest } from 'hooks/useRequest';
import {
  openModalReservationClose,
  openModalReservationCreate,
  openModalReservationRemove,
  openModalReservationUpdate,
} from 'modules/modal';
import { useDispatch } from 'react-redux';
import * as propertiesBooking from 'services/propertiesBooking';
import ReservationList from './components/ReservationList';

function Reservations({ property, afterClose }) {
  const dispatch = useDispatch();

  const { data, isFetching } = useRequest({
    request: () => propertiesBooking.getList(property.id),
  });

  const handleUpdate = (reservation) => () => {
    dispatch(openModalReservationUpdate({ reservation, property, afterClose }));
  };

  const handleCreate = () => {
    dispatch(openModalReservationCreate({ property, afterClose }));
  };

  const handleClose = (reservation) => () => {
    dispatch(openModalReservationClose({ reservation, property, afterClose }));
  };

  const handleRemove = (reservation) => () => {
    dispatch(openModalReservationRemove({ reservation, property, afterClose }));
  };

  if (isFetching) {
    return <Loading isVisible isBlock />;
  }

  if (!data || data.length === 0) {
    return (
      <Nodata
        image={img}
        title="Nenhuma Reserva encontrada."
        text="Reserve esse imóvel usando o botão abaixo"
      >
        <Button
          className="h-margin-top--10"
          color="secondary"
          onClick={handleCreate}
        >
          Reservar imóvel
        </Button>
      </Nodata>
    );
  }

  return (
    <ReservationList
      reservations={data}
      handleClose={handleClose}
      handleUpdate={handleUpdate}
      handleRemove={handleRemove}
    />
  );
}

export default Reservations;
