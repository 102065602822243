import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import { getHistory } from '../../../modules/modalIndexHistory';
// components do sistema
import { ModalTemplate } from '../../../components/Modal';
import IndexHistoryTable from './components/IndexHistoryTable';
import Error from './components/Error';

const propTypes = {
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const defaultProps = {};

class ModalIndexHistory extends Component {
  componentDidMount() {
    const { id } = this.props;
    this.props.getHistory(id);
  }

  render() {
    const { modalConfig, isOpen, handleClose, modalType, indexes, isFetching } =
      this.props;

    if (isFetching) return null;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        {indexes?.[0] ? (
          <ModalTemplate
            title={`Histórico ${indexes?.[0]?.name}`}
            handleClose={handleClose}
          >
            <IndexHistoryTable indexes={indexes} />
          </ModalTemplate>
        ) : (
          <Error handleClose={handleClose} />
        )}
      </Modal>
    );
  }
}

ModalIndexHistory.propTypes = propTypes;
ModalIndexHistory.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  indexes: state.modalIndexHistory.indexes,
  isFetching: state.modalIndexHistory.meta.isFetching,
});
const mapDispatchToProps = {
  getHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalIndexHistory);
