export function withHighlightedButtons(data) {
  let newData = data;

  if (newData.form_is_email_featured && newData.form_is_whatsapp_featured) {
    newData['highlighted_button'] = 3;
  } else if (newData.form_is_email_featured) {
    newData['highlighted_button'] = 2;
  } else if (newData.form_is_whatsapp_featured) {
    newData['highlighted_button'] = 1;
  }

  return newData;
}

export function withRequiredFields(data) {
  let newData = data;

  if (newData.form_is_required_email && newData.form_is_required_phone) {
    newData['required_fields'] = 3;
  } else if (newData.form_is_required_email) {
    newData['required_fields'] = 2;
  } else if (newData.form_is_required_phone) {
    newData['required_fields'] = 1;
  }

  return newData;
}

