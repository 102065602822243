import React, { useEffect } from 'react';
import Box from 'pages/Crm/components/Box';
import StagesList from '../StagesList';
import CardNumber from '../CardNumber';
import { Col, Row } from 'react-flexbox-grid';
import { useRequest } from 'hooks/useRequest';
import { selector } from 'pages/Crm/components/FormUser';
import api from 'services';
import { useFormFunnelId } from 'pages/Crm/pages/People';
import Loading from 'components/Loading';
import GraphChannelPeople from '../GraphChannelPeople';
import { RightSide } from './styles';
import { useSelector } from 'react-redux';

function DealsByStage() {
  const userId = useSelector((state) => selector(state, 'user_id')?.user_id);
  const funnelId = useFormFunnelId();

  const { data, isFetching, fetchData } = useRequest({
    request: ({ funnel_id, user_id }) => {
      return api.getList(`crm/dashboard/funnels-with-details`, {
        user_id,
        funnel_id,
      });
    },
    initialFetch: false,
  });

  useEffect(() => {
    if (funnelId) {
      fetchData({ funnel_id: funnelId, user_id: userId });
    }
  }, [userId, funnelId]);

  return (
    <Box title="Etapas do Funil" text="Quantidade de negócios e VGV por etapa">
      <Loading isVisible={isFetching} isFullComponent />
      <Row>
        <Col lg={6} md={12}>
          <StagesList data={data?.stages} />
        </Col>
        <Col md={12} lg={5} lgOffset={1}>
          <RightSide>
            <Row>
              <Col xs={4}>
                <CardNumber
                  title="Clientes"
                  value={data.people_count}
                  color="#0063C0"
                />
              </Col>
              <Col xs={4}>
                <CardNumber
                  title="Estagnados"
                  value={data.stagnant_count}
                  color="#F84343"
                />
              </Col>
              <Col xs={4}>
                <CardNumber
                  title="Imóveis"
                  value={data.properties_count}
                  color="#0063C0"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <GraphChannelPeople data={data.reception_sources} />
              </Col>
            </Row>
          </RightSide>
        </Col>
      </Row>
    </Box>
  );
}

export default DealsByStage;
