import React from 'react';
import { Field } from 'redux-form';
// Components
import Box from 'components/FilterBox';
import { Input } from 'components/Form';
import GroupControl, { GroupItem } from 'components/GroupControl';

const propTypes = {};
const defaultProps = {};

const AgeField = () => (
  <Box title="Idade do imóvel">
    <GroupControl label="" required={false}>
      <GroupItem>
        <Field name="by_lifetime" component={Input} />
      </GroupItem>
      <GroupItem label="Anos" size="small" />
    </GroupControl>
  </Box>
);

AgeField.propTypes = propTypes;
AgeField.defaultProps = defaultProps;

export default AgeField;
