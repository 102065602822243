import React from 'react';
import Countdown from 'pages/Dashboard/components/Countdown';
import AlertFrozenAccount from 'pages/Dashboard/containers/AlertFrozenAccount';
import { useCan } from 'hooks/useCan';
// import { Container } from './styles';

const DashboardAlerts = () => {
  const canRealEstateFinancial = useCan({ run: 'REAL_ESTATE_FINANCIAL' });

  if (!canRealEstateFinancial) return null;

  return (
    <>
      <Countdown />
      <AlertFrozenAccount />
    </>
  );
};

export default DashboardAlerts;
