import React, { Component } from 'react';
import { Row } from 'react-flexbox-grid';
// import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Input } from '../../components/Form';
import Button from '../../components/Button';
import FormTitle from '../../templates/Login/FormTitle';

import validate from './validate';

const defaultProps = {};
const propTypes = {
  // handleSubmit: PropTypes.func,
};

class RecoveryForm extends Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormTitle
          title="Recupere sua senha"
          text="Insira seu e-mail abaixo que enviaremos as instruções necessárias para recuperar sua senha."
        />
        <Row>
          <Field
            autoFocus
            xs={12}
            label="E-mail"
            type="email"
            name="email"
            placeholder="Digite seu e-mail"
            component={Input}
          />
        </Row>
        <Button type="submit" block color="primary">
          Recuperar Senha
        </Button>
      </form>
    );
  }
}

RecoveryForm = reduxForm({
  form: 'recovery',
  enableReinitialize: true,
  validate
})(RecoveryForm);

RecoveryForm.defaultProps = defaultProps;
RecoveryForm.propTypes = propTypes;

export default RecoveryForm;
