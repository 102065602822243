import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px;
  background: #f9fbfc;
  border-radius: 5px;

  & + & {
    margin-top: 10px;
  }

  header {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
`;
export const Title = styled.h1`
  font-size: 14px;
  flex: 1 0;
  font-weight: 800;
`;
export const Content = styled.div``;
export const Actions = styled.div`
  flex-shrink: 0;
  min-width: 0;
`;
export const BoxLink = styled.a`
  color: #0084f4;
  text-decoration: underline;
  cursor: pointer;
`;

export const BoxTable = styled.table`
  width: 100%;

  button {
    cursor: pointer;
  }

  td {
    padding: 0 2.5px;
    white-space: nowrap;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;
