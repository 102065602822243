import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import FieldPortal from 'containers/FieldPortal';
import { useHistory } from 'react-router';
import qs from 'qs';
import { Aside } from './styles';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import FieldTypePortal from 'components/FieldTypePortal';
import FilterCharacteristics from '../FilterCharacteristics';
import { changeUrlObj } from 'lib/url-router-helpers';
import { useQuery } from 'hooks/useQuery';

const getPortalById = (options, id) => {
  return options.find((option) => option.id === id);
};

function Filter({ change, autofill, initialize }) {
  const history = useHistory();
  const params = useQuery({ withParams: true });
  const { selectedPortal, setPortals, portals } = usePortalOptions();

  useEffect(() => {
    if (params?.portalRealEstateId) {
      autofill('portal_id', params?.portalRealEstateId);
    }

    if (params?.filter_portals?.by_portal_option_id) {
      autofill(
        'by_portal_option_id',
        params?.filter_portals?.by_portal_option_id
      );
    }

    if (params?.filter?.reference)
      autofill('reference', params?.filter?.reference);

    if (params?.filter?.street_address)
      autofill('street_address', params?.filter?.street_address);

    if (params?.filter?.condominium_id)
      autofill('condominium_id', params?.filter?.condominium_id);
  }, [params]);

  useEffect(() => {
    const search = history.location.search;
    const searchParams = new URLSearchParams(search);

    if (
      params?.portalRealEstateId &&
      !searchParams.has('filter_portals[by_portal_option_id]')
    ) {
      const searchParams = {
        filter_portals: {
          by_portal_option_id: selectedPortal?.options?.[0]?.portal_option_id,
        },
        offset: 1,
        limit: 50,
      };

      autofill(
        'by_portal_option_id',
        selectedPortal?.options?.[0]?.portal_option_id
      );

      history.replace({
        pathname: history.location.pathname,
        search: qs.stringify(searchParams, { addQueryPrefix: false }),
      });
    }
  }, [history, selectedPortal]);

  /**
   * Limpa todos os campos de características
   */
  const clearFieldsCharacteristics = () => {
    change('reference', '');
    change('condominium_id', '');
    change('street_address', '');
  };

  /**
   * Atualiza o portal na url
   * @param event
   * @param value
   */
  const handleChangePortal = (event, value) => {
    const portal = getPortalById(portals, value);
    const portalOptionId = portal?.options[0].portal_option_id;
    history.push(
      encodeURI(
        `/portals/${value}?filter_portals[by_portal_option_id]=${portalOptionId}&limit=50&offset=1`
      )
    );

    // Altera o portal_option_id para o primeiro que tem dentro da lista
    // de opções do portal
    change('by_portal_option_id', portalOptionId);

    // Limpa os campos de características
    clearFieldsCharacteristics();
  };

  /**
   * Atualiza a opção do portal
   * @param portal_option_id
   */
  const handleChangeOption = (portal_option_id) => {
    changeUrlObj({
      history,
      callBack: (params) => {
        try {
          delete params.filter;
          if (!params.filter_portals) params.filter_portals = {};
          params.filter_portals.by_portal_option_id = portal_option_id;
        } catch (err) {
          console.error(err);
        }
      },
    });

    // Limpa os campos de características
    clearFieldsCharacteristics();
  };

  return (
    <Aside>
      <FieldPortal
        onChange={handleChangePortal}
        options={portals}
        onLoadOptions={setPortals}
      />
      <FieldTypePortal
        options={selectedPortal?.options}
        portal={selectedPortal}
        onChange={handleChangeOption}
      />
      <FilterCharacteristics change={change} />
    </Aside>
  );
}

export default reduxForm({
  form: 'PropertiesPortalForm',
})(Filter);
