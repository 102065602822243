import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  .FormGroup {
    margin-right: 10px !important;
  }

  .FormControl,
  .Button {
    height: 30px;
    line-height: 30px;
  }

  .GroupControl__item--divider {
    line-height: 30px;
    height: 30px;
  }

  .ButtonGroup__action {
    height: 30px;
    line-height: 26px;
  }
`;
