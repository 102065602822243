import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Record from './components/Record';
import ModalClose from 'components/Modal/components/ModalClose';
import ModalContent from 'components/Modal/components/ModalContent';
import ModalHeader from './components/ModalHeader';
import Divider from 'components/Divider';
import Loading from 'components/Loading';
// Modules
import {
  fetchProperties,
  getProperties,
  propertyGroupSelector
} from './module';

import { propertiesSelecteds } from 'modules/properties';
import { Actions } from 'modules/selecteds';

import { openModalProperty, openModalPropertyGroup } from 'modules/modal';

const propTypes = {
  property: PropTypes.shape({
    subtype: PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired
};

class ModalPropertyGroup extends Component {
  componentDidMount() {
    let { filter, editted } = this.props;

    if (editted) {
      filter = null;
    }

    this.props.fetchProperties(this.props.property.condominium_id, filter);
  }

  openMySelf = () => {
    const { property, openModalPropertyGroup } = this.props;

    openModalPropertyGroup({ property });
  };

  /**
   * Controla o fechamento da modal
   */
  handleCloseModal = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  handleOpenProperty = property => () => {
    this.props.openModalProperty({
      property,
      afterClose: () => {
        this.openMySelf();
      }
    });
  };

  /**
   * Retorna se pode ou não selecionar um imóvel na lista
   * @returns {boolean}
   */
  get canSelect() {
    return !!this.props.selecteds;
  }

  get properties() {
    const { propertyGroup, selecteds } = this.props;

    // Verifica se tem seleção de imóveis
    if (selecteds && propertyGroup) {
      const { properties } = propertyGroup;
      return (
        properties &&
        properties.map(property => {
          if (selecteds.indexOf(property.id) !== -1) {
            return { ...property, isChecked: true };
          }
          return property;
        })
      );
    }

    return propertyGroup.properties;
  }

  toggleSelected = id => {
    this.props.toggleSelected('properties', id);
  };

  get title() {
    if (this.props.propertyGroup) {
      return this.props.propertyGroup.title;
    }

    return 'Sem título';
  }

  get renderContent() {
    const { editted, property, isFetching } = this.props;

    if (isFetching) {
      return <Loading isVisible={isFetching} />;
    }

    return (
      <Record
        editted={editted}
        property={property}
        canSelect={this.canSelect}
        toggleSelected={this.toggleSelected}
        properties={this.properties}
        handleOpenProperty={this.handleOpenProperty}
      />
    );
  }

  render() {
    const { isOpen, modalType, modalConfig } = this.props;

    return (
      <Modal
        {...modalConfig}
        className="Modal Modal--large"
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={this.handleCloseModal}
      >
        <div className="Modal__wrapper">
          <ModalClose handleClose={this.handleCloseModal} />
          <ModalHeader
            title={this.title}
            text={this.props.property.address_formatted}
          />
          <Divider top={-5} bottom={30} />
          <ModalContent>{this.renderContent}</ModalContent>
        </div>
      </Modal>
    );
  }
}

ModalPropertyGroup.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => ({
  filter: getFormValues('FilterProperties')(state),
  propertyGroup: propertyGroupSelector.getAll(state),
  isFetching: propertyGroupSelector.isFetching(state),
  properties: getProperties(state, ownProps),
  selecteds: propertiesSelecteds(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProperties,
      openModalPropertyGroup,
      openModalProperty,
      ...Actions
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalPropertyGroup);
