import React, { useContext } from 'react';
import { MdClose } from 'react-icons/md';
import { ContextCompare } from '../../index';
import Image from 'components/Image';
import { Cell, CellLabel } from '../Table/styles';
import { Wrapper, Remove } from './styles';

const RowImage = () => {
  const { properties, handleRemove } = useContext(ContextCompare);

  return (
    <tr>
      <CellLabel />
      {properties.map(({ id, cover_image }) => (
        <Cell key={`image-${id}`}>
          <Wrapper>
            <Image
              size="medium"
              type="outside"
              src={cover_image?.file_url}
              alt="Imagem do imóvel"
            />
            <Remove onClick={handleRemove(id)}>
              <MdClose />
            </Remove>
          </Wrapper>
        </Cell>
      ))}
    </tr>
  );
};

export default RowImage;
