import { createContext, useContext } from "react";
import {
  handleSetPosition,
  handleSetPov,
  handleSetZoom,
  mapPositionSelector,
  mapPovSelector,
  mapZoomSelector,
} from "modules/mapView";
import { connect } from "react-redux";

export const MapContext = createContext();

function MapProvider({
  children,
  position,
  pov,
  zoom,
  onChange,
  handleSetPosition,
  // handleSetPov,
  handleSetZoom,
  onZoomChange,
}) {
  const setMap = ({ lat, lng, heading, pitch, zoom }) => {
    const mapPosition = {
      lat: lat ? lat() : position?.lat,
      lng: lng ? lng() : position?.lng,
    };

    const mapPov = {
      heading: heading || pov?.heading,
      pitch: pitch || pov?.pitch,
      zoom: zoom || pov?.zoom,
    };

    onChange && onChange(mapPosition, mapPov);
    handleSetPosition(mapPosition);
    // handleSetPov(mapPov);
  }

  const handleChangePov = (pov, position) => {
    setMap({ ...pov, ...position });
  }

  const handleDropMarker = ({ latLng }) => {
    setMap(latLng);
  };

  const handleZoomChange = (zoom) => {
    if (onZoomChange) onZoomChange(zoom);
    handleSetZoom(zoom.zoom);
  };

  return (
    <MapContext.Provider value={{
      setMap,
      handleChangePov,
      handleDropMarker,
      handleZoomChange,
      position,
      zoom,
      pov,
    }}>
      {children}
    </MapContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  position: mapPositionSelector(state),
  pov: mapPovSelector(state),
  zoom: mapZoomSelector(state),
});

const mapDispatchToProps = {
  handleSetPov,
  handleSetPosition,
  handleSetZoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapProvider);

export function useMap() {
  return useContext(MapContext);
}
