import React from 'react';
import CardCrm from 'components/CardCrmBkp';
import { Col, Row } from 'react-flexbox-grid';

function CardCrmPage() {
  return (
    <Row>
      <Col xs={3}>
        <CardCrm
          title="Brooklyn Simmons"
          dealId=""
          person={{
            name: 'Brooklyn Simmons',
          }}
          property={{
            reference: '100',
            title_formatted: 'Apartamento',
          }}
        />
      </Col>
    </Row>
  );
}

export default CardCrmPage;
