import React, { useCallback, useEffect, useState } from 'react';
import {
  MdDirectionsCar as IconGarage,
  MdFullscreen as IconArea,
  MdHotel as IconBedroom,
} from 'react-icons/md';
import { Item, Wrapper } from './styles';
import Tooltip from 'react-tooltip';

function FeaturedInfos({ property: p }) {
  const [items, setItems] = useState([]);

  const addItem = useCallback((icon, text, tooltip) => {
    setItems((prevItems) => [...prevItems, { icon, text, tooltip }]);
  }, []);

  useEffect(() => {
    Tooltip.rebuild();

    if (p?.rooms?.bedroom) {
      let bedroomText = p?.rooms?.bedroom.value;
      let tooltip = '';

      if (p?.rooms?.suite) {
        tooltip += `${p?.rooms?.bedroom?.value} quarto(s) e ${p?.rooms?.suite?.value} sendo suite(s)`;
        bedroomText += ` | ${p?.rooms?.suite?.value}`;
      }

      addItem(IconBedroom, bedroomText, tooltip);
    }

    if (p?.rooms?.garage) {
      addItem(IconGarage, p?.rooms?.garage?.value, 'Garagens');
    }

    if (p?.areas?.primary_area) {
      const area = p?.areas?.primary_area;
      addItem(IconArea, `${area?.value}${area?.measure}`, area?.title);
    }
  }, []);

  return (
    <Wrapper>
      {items.map(({ icon: Icon, text, tooltip }, i) => (
        <Item key={`featured-info-${p.id}-${i}`} data-tip={tooltip}>
          <Icon />
          {text}
        </Item>
      ))}
    </Wrapper>
  );
}

export default FeaturedInfos;
