import React, { useContext, useMemo } from 'react';
import { ContextCompare } from 'containers/ModalRoot/ModalPropertyCompare/index';
import { Wrapper, Divider } from './styles';

const RowDivider = ({ name }) => {
  const { properties } = useContext(ContextCompare);

  const size = useMemo(() => {
    return properties.length;
  }, [properties]);

  return (
    <Wrapper>
      <td colSpan={size}>
        <Divider />
      </td>
    </Wrapper>
  );
};

export default RowDivider;
