import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { Col, Row } from 'react-flexbox-grid';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';

// Components
import Container from 'components/Container';
import MainTitle from 'components/MainTitle';
import { FieldBool, Toggle, Input } from 'components/Form';

// HOC
import { withId } from '../../HOC/withId';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Services
import * as roomsService from 'services/propertiesPartial/rooms';
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import { finishResponse } from 'pages/PropertyStore/helpers';
import Loading from 'components/Loading';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { useParams } from 'react-router';

// Modules

const positiveNumber = value => {
  return value >= 0 && value;
};

const normalFields = is_boolean => ({
  xs: 12,
  sm: 6,
  md: 3,
  type: 'number',
  normalize: positiveNumber,
  component: is_boolean ? Toggle : Input,
  isVisible: false
});

const Rooms = ({
  hide,
  next,
  handleSubmit,
  initialize,
  onSubmit,
  submitting
}) => {
  //const dispatch = useDispatch();
  const { data, fetchData } = useRequest({
    request: roomsService.getOne,
    initialFetch: false,
    initialState: {}
  });
  const { id } = useParams();

  const fetch = useCallback(async () => {
    if (id) {
      const res = await fetchData(id);

      // Se não tiver nenhum comodo
      // muda para a próxima página
      if (res?.data?.type?.rooms_fields.length <= 0) {
        next('measures');
        hide('Cômodos');
      }
    }
  }, [id]);

  useEffect(() => {
    fetch();
  }, [id]);

  useEffect(() => {
    initialize(data);
  }, [data]);

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle
        title="Cômodos"
        text="Defina as quantidades de cada cômodo deste imóvel."
      />
      <Container>
        <Row>
          {data?.type?.rooms_fields?.map(
            ({ id, title, name, extra, is_boolean }) => (
              <>
                <Field
                  {...normalFields(is_boolean)}
                  key={`rooms-${id}-${name}`}
                  label={title}
                  name={`rooms.${name}.value`}
                />

                {extra &&
                  _.map(extra, ({ title: fieldTitle, name: fieldName }) => (
                    <Col xs={3} key={`${fieldName}-${name}`}>
                      <FieldBool
                        name={`rooms.${name}.extra.${fieldName}.value`}
                        label={fieldTitle}
                      />
                    </Col>
                  ))}
              </>
            )
          )}
        </Row>
      </Container>

      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyRooms',
    initialValues: {
      is_condo: false,
      rooms: {
        garage: {
          extra: {
            has_box_in_garage: false,
            is_covered: false
          }
        }
      }
    },
    onSubmit: (values, d, p) =>
      roomsService.update(values).then(finishResponse(values, d, p)),
    onSubmitSuccess: (response, dispatch, p) => {
      p.initialize(response?.data);

      if (!p.isEditting) {
        p.next('measures');
      }
    }
  }),
  withFormConfirmation()
)(Rooms);
