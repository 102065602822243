import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
// Common Components
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import { useIsTecimob } from 'containers/IsTecimob';

function ModalDailyDebits({
  request,
  isRemoving,
  onConfirm,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  text
}) {
  const isTecimob = useIsTecimob();
  const [data, setData] = useState({});

  async function fetchData() {
    const data = await request(isRemoving);
    setData(data);
  }

  function handleConfirm() {
    return onConfirm({ handleClose });
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!isTecimob) {
      handleConfirm();
    }
  }, [isTecimob, handleConfirm]);

  if (!isTecimob) return null;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Alterações no débito diário"
        handleClose={handleClose}
      >
        <p className="h-color--primary">{text}</p>

        <table
          className="Table"
          style={{ width: "calc(100% + 39px)", margin: "0 -18px 0" }}
        >
          <thead>
            <tr>
              <th />
              <th>Débito diário</th>
              <th>Data Final</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Antes</strong>
              </td>
              <td>{data.old_debit_daily}</td>
              <td>{data.old_credit_until}</td>
            </tr>
            <tr>
              <td>
                <strong>Depois</strong>
              </td>
              <td>{data.new_debit_daily}</td>
              <td>{data.new_credit_until}</td>
            </tr>
          </tbody>
        </table>
        <ModalFooter>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button color="success" onClick={handleConfirm}>
            Continuar
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalDailyDebits;
