import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const defaultProps = {
  className: null,
  currentPage: null,
  component: null,
  children: null
};

const propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.string,
  component: PropTypes.string,
  children: PropTypes.node
};

const MenuItem = props => {
  const { className, currentPage, component, children, ...rest } = props;

  return (
    <li
      className={classnames('Menu__item', className, {
        'is-active': currentPage === component
      })}
    >
      <button type="button" {...rest} className="Menu__link">
        {children}
      </button>
    </li>
  );
};

MenuItem.defaultProps = defaultProps;
MenuItem.propTypes = propTypes;

export default MenuItem;
