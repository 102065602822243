import React, { useEffect, useState } from 'react';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { FieldBool, Input } from 'components/Form';
import Container from 'components/Container';
import ListOptions from 'components/ListOptions';

import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import FieldCardPosts from './components/FieldCardPosts';
// Services
import * as postsAreaService from 'services/sites/posts-area';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const FormPosts = ({ handleSubmit, initialize, submitting }) => {
  const [isFetching, setFetching] = useState(true);
  async function fetchFormData() {
    setFetching(true);
    const { data } = await postsAreaService.getOne();
    setFetching(false);
    initialize(data);
  }

  useEffect(() => {
    fetchFormData();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      {!isFetching && <FieldCardPosts />}
      <Container padding style={{ marginBottom: '25px' }}>
        <ListOptions>
          <ListOptions.item
            title="Mostrar postagens no site?"
            text="Defina se deve mostrar as postagens na página inicial do site."
            renderOptions={() => <FieldBool name="is_posts_shown" />}
          />
          <ListOptions.item
            title="Título da área"
            text="Edite o título que ficará acima das postagens."
          >
            <Row className="h-margin-top--10">
              <Field xs={12} name="posts_title" component={Input} />
            </Row>
          </ListOptions.item>
          <FixedBar style={{ left: 256 }}>
            <Button color="white" colorText="secondary">
              Voltar
            </Button>
            <FixedBar.spacer />
            <Button type="submit" color="success" disabled={submitting}>
              Salvar
            </Button>
          </FixedBar>
        </ListOptions>
      </Container>
    </form>
  );
};

FormPosts.defaultProps = defaultProps;
FormPosts.propTypes = propTypes;

export default reduxForm({
  form: 'FormPosts'
})(FormPosts);
