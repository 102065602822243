import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: ${p => (p.size ? p.size : '100%')};
  display: flex;
  border: 1px solid #d3dceb;
  justify-content: stretch;
  border-radius: 4px;
`;
export const Button = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: grid;
  width: 50px;
  place-items: center;
  cursor: pointer;
  font-size: 24px;

  :hover {
    background-color: #f9f9f9;
  }

  :first-child {
    border-radius: 4px 0 0 4px;
    border-right: 1px solid #d3dceb;
  }

  :last-child {
    border-radius: 0 4px 4px 0;
    border-left: 1px solid #d3dceb;
  }

  ${p =>
    p.isDisabled &&
    css`
      background-color: #e9eef5 !important;
      color: #d3dceb;
    `}
`;
export const Content = styled.div`
  padding: 10px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const Number = styled.div`
  font-size: 42px;
  font-weight: 900;
`;
export const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
`;
