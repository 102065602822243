import CardProperty from 'components/CardProperty';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import house from 'containers/ModalRoot/ModalPerson/components/Properties/pages/PropertiesOfClient/house-key.svg';
import { useRequest } from 'hooks/useRequest';
import { getList } from 'services/properties';
// import { Wrapper } from './styles';

function Properties({ person, isFetching, openModalProperty, reopenModal }) {
  const { data: properties } = useRequest({
    request: getList,
    params: {
      filter: {
        people_id: person.id,
        status: '1,2,3,4',
        without_draft_scope: true,
        on_network: ['orulo'],
      },
      include: 'condominium',
      sort: 'status,-updated_at',
    },
  });

  if (isFetching) return <Loading isVisible />;

  if (properties.length === 0) {
    return (
      <Nodata
        style={{ maxWidth: 330 }}
        image={house}
        title="Nenhum imóvel encontrado."
        text="Quando algum imóvel for cadastrado e seu cliente for o proprietário, ele aparecerá aqui."
      />
    );
  }

  return properties.map((property) => {
    const condoTitle = property?.condominium?.title;
    let addressFormatted = property?.address_formatted;

    if (condoTitle) {
      addressFormatted += ` - ${condoTitle}`;
    }

    return (
      <CardProperty
        type="horizontal"
        property={{
          ...property,
          address_formatted: addressFormatted,
        }}
        handleClickProperty={() => {
          openModalProperty({
            property: property,
            afterClose: () => {
              reopenModal();
            },
          });
        }}
      >
        {/*<Button color="tertiary" size="medium">*/}
        {/*  Ver imóvel no Órulo*/}
        {/*</Button>*/}
      </CardProperty>
    );
  });
}

export default Properties;
