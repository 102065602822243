import React, { useEffect } from 'react';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openModalPayment } from 'modules/modal';

function Contract() {
  const dispatch = useDispatch();

  const history = useHistory();
  useEffect(() => {
    history.push('/plan/history');
    dispatch(openModalPayment());
  }, []);

  return <Loading isVisible isFullScreen />;
}

export default Contract;
