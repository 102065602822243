import api from 'services';
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega as informações da dashboard de histórico de pagamentos
 * @return {*|Promise<AxiosResponse<T>>}
 */
export const getDashboardData = () =>
  api.getOne('real-estates/financial-dashboard', '');

/**
 *
 * @param best_time
 * @returns {Promise<AxiosResponse<T>>}
 */
export const consultantContact = ({ best_time }) =>
  api
    .create('sales/consultant-contact', { best_time })
    .then(
      responseMessage(
        'Recebemos o seu contato! Agora, é só aguardar a ligação do consultor.',
        { delay: 10000 }
      )
    );
