import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '../Form';
import GroupItem from './components/GroupItem';

const defaultProps = {
  children: '',
  required: false
};
const propTypes = {
  required: PropTypes.bool,
  children: PropTypes.node
};

const GroupControl = ({ label, required, children: fields, style }) => (
  <div className="GroupControl" style={style}>
    <FormLabel required={required}>{label}</FormLabel>
    <div className="GroupControl__controls">{fields}</div>
  </div>
);

GroupControl.defaultProps = defaultProps;
GroupControl.propTypes = propTypes;
GroupControl.item = GroupItem;

export { GroupItem };
export default GroupControl;
