import { responseMessage } from 'lib/service-helpers';
import api, { apiStorage } from './';
import { SubmissionError } from 'redux-form';
import { API_URL, HTTP, ORIGINAL } from '../lib/HTTP';
import Alert from 'react-s-alert';
import _ from 'lodash';
import { STATUS_CRECI } from '../constants/constants';
import { getPosition } from 'lib/helpers';

export const getList = (params) =>
  api.getList('users', { ...params, sort: 'by_name' });

export const getOne = (id, params) => api.getOne('users', id, params);

export const currentUser = (params) => {
  const isCentral = localStorage.getItem('@tecimob/central');

  params = {
    ...params,
    should_update_login: !isCentral,
  };

  return api.getOne('users/me', '', params);
};

/**
 * Retorna o usuario logado baseado no token que foi setado na instancia do HTTP
 * @return {AxiosPromise}
 */
export const me = (params) => {
  const isCentral = localStorage.getItem('@tecimob/central');

  params = {
    ...params,
    should_update_login:
      params?.should_update_login === false ? false : !isCentral,
  };

  return api
    .getOne('users/me', '', {
      ...params,
      include:
        'default_crm_stage.funnel,real_estate.primary_domain,real_estate.resale,real_estate.phones,group',
    })
    .then((res) => {
      res.data = {
        ...res.data,
        primary_domain_url: _.get(res.data, 'real_estate.primary_domain.url'),
        header_background: _.get(res.data, 'real_estate.header_background'),
        permissions: res?.data?.group?.permissions,
        is_admin: res?.data?.group?.title === 'Administrador',
      };
      return res;
    });
};

export const getRealtors = (params) => {
  let currentParams = {
    ...params,
    filter: {
      creci_status: `${STATUS_CRECI.NOT_VERIFIED},${STATUS_CRECI.WAITING},${STATUS_CRECI.VERIFIED}`,
      realtor: true,
    },
  };

  if (params && params.filter) {
    currentParams = {
      ...currentParams,
      filter: { ...currentParams.filter, ...params.filter },
    };
  }

  return api.getList('users', currentParams);
};

export const postponeSatisfaction = () =>
  api.create('users/me/postpone-satisfaction');

const storeUserTransformer = (values) => {
  // Cria uma nova copia dos valores do formulário
  const _values = { ...values };

  // Se não for corretor remove o creci
  if (!_values.realtor) delete _values?.creci;

  return _values;
};

/**
 * Adiciona um usuario
 * @param values
 * @return {Promise}
 */
export const add = (values) =>
  api.create('users', storeUserTransformer(values));

/**
 * Atualiza um usuário
 * @param id
 * @param values
 * @returns {Promise}
 */
export const updateWithoutPassword = ({ id, ...values }) =>
  api.create(`users/${id}`, storeUserTransformer(values));

/**
 * Atualiza um usuário
 * @param id
 * @param values
 * @returns {Promise}
 */
export const update = ({ id, ...values }) =>
  api.patch(`users/${id}/password`, storeUserTransformer(values));

/**
 * Envia email para o cliente com instruções de como recuperar a senha
 * envia o email mesmo se o usuário não existir
 * @param {String} email
 * @param {String} resale_id
 * @return {Promise}
 */
export const sendEmailPassword = ({ email, resale_id }) =>
  api.create('users/passwords', { email, resale_id });

/**
 * Reseta a senha
 * @param {String} token
 * @param {String} email
 * @param {String} password
 * @return {AxiosPromise}
 */
export const resetPassword = (values) => api.update('users/passwords', values);

/**
 * Verifica se o token que reseta a senha realmente está valido
 * @param email
 * @param token
 * @return {AxiosPromise}
 */
export const isValidResetToken = ({ email, token }) =>
  HTTP.get(`${API_URL}users/passwords/${email}/${token}`);

/**
 * Reativa um usuario
 * @param id
 * @return {AxiosPromise|*}
 */
export const reactivate = (id) => api.create(`users/${id}/reactivate`);
export const setGoogleAuth = (token) => api.update('users/google-auth', token);

/**
 * Remove um usuário
 * @param id
 * @param params
 * @return {boolean|AxiosPromise|*}
 */
export const remove = (id, params) =>
  api.delete('users', id, {
    params,
  });

/**
 * Transfere todos os negócios/imóveis/clientes atrelados para outro usuário
 * @param from_id - usuário que voce quer transferir as informações
 * @param user_id - pra qual usuário voce quer transferir
 * @param agent_id
 * @param values
 * @return {*}
 */
export const transferUser = ({ from_id, user_id, agent_id, ...values }) => {
  return api.create(`users/${from_id}/transfers`, {
    user_id,
    agent_id,
    ...values,
  });
};

/**
 * Transfere todos os imóveis atrelados para outro usuário
 * @param from - usuário que voce quer transferir as informações
 * @param to - pra qual usuário voce quer transferir
 * @param values
 * @return {*}
 */
export const transferPropertiesUser = async (from, to, values) => {
  return api.create(`users/${from}/transfer/${to}`, values);
};

/**
 * Transfere e deleta um usuário
 * @param from
 * @param to
 * @param should_change_contract
 * @return {Promise<boolean|AxiosPromise|*>}
 */
export const transferAndDelete = async (
  { from_id, user_id, agent_id },
  should_change_contract = false
) => {
  try {
    await transferUser({
      from_id,
      user_id,
      agent_id,
      people: true,
      properties_user: true,
    });

    return api.delete('users', from_id, {
      params: {
        should_change_contract,
      },
    });
  } catch {
    Alert.success('Erro ao deletar');
    return SubmissionError({
      _error: 'Erro ao deletar esse usuário',
    });
  }
};

/**
 * Faz upload de imagem de um usuário
 * @param user_id - id do usuário que voce quer atualizar a imagem
 * @param image -
 * @returns {Promise<void>}
 */
export const uploadImage = (user_id, image) =>
  apiStorage
    .uploadFile(`users/${user_id}/profile`, { image })
    .then(responseMessage('Imagem enviado'));

/**
 * Remove a imagem de um usuário
 * @param user_id - id do usuário que voce quer apagar a imagem
 */
export const removeImage = (user_id) =>
  api
    .update(`users/${user_id}`, { file_url: '' })
    .then(responseMessage('Imagem removida'));

/**
 * Faz upload dos documentos de um usuário
 * @param user_id - id do usuário que voce quer atualizar a imagem
 * @param params
 * @returns {Promise<void>}
 */
export const uploadDocument = (user_id, params) =>
  apiStorage.uploadFile(`users/${user_id}/creci-documents`, params);

/**
 * Pega o accessToken de um usuário
 * @param {string} userId
 * @return {Promise}
 */
export const getAccessTokenByUserId = async (userId) => {
  try {
    return await ORIGINAL.get(`users/${userId}/access-token`);
  } catch (err) {
    return err;
  }
};

export const loginByAccessToken = async (accessToken) => {
  try {
    const response = await getList({
      filter: {
        desktop_api_token: accessToken,
      },
    });

    response.data = response.data[0];

    return response;
  } catch (e) {
    console.warn('Problema ao realizar login pelo token');
  }
};

export const getUserPosition = async () => {
  try {
    const user = localStorage.getItem('current-user');

    if (user?.real_estate?.latitude && user?.real_estate?.longitude) {
      return { latitude: user.latitude, longitude: user.longitude };
    }

    const position = await getPosition();

    return position.coords;
  } catch (err) {
    throw err;
  }
};

/**
 * Retorna a revenda do domínio
 * @returns {Promise<string>}
 */
export const getReseller = async () => {
  try {
    const reseller = localStorage.getItem('reseller');
    if (reseller) {
      return reseller;
    }

    const { hostname } = window.location;
    const { data } = await api.getOne(`resales/by-panel-domain/${hostname}`);
    localStorage.setItem('reseller', data);
  } catch (err) {
    throw err;
  }
};

export const updateMe = (values) => api.update('users/me', values);

export const getWizard = (wizardName) => {
  const currentUser = localStorage.getItem('current-user');
  const wizards = currentUser?.wizards;

  return wizardName in wizards;
};

/**
 * Adiciona um wizard na lista
 * @param wizardName - nome que vai ser adicionado na lista
 * @returns {Promise<void>}
 */
export const addWizard = async (wizardName) => {
  try {
    // Pega o usuário que está logado no momento
    const currentUser = localStorage.getItem('current-user');

    // Lista de wizards atualizada
    const wizards = {
      ...currentUser?.wizards,
      [wizardName]: true,
    };

    // Atualiza o localStorage
    localStorage.setItem('current-user', {
      ...currentUser,
      wizards,
    });

    return api.update('users/me', {
      wizards,
    });
  } catch (e) {
    console.error('Deu algum problema na hora de adicionar wizard no banco');
  }
};

/**
 * Adiciona um wizard na lista
 * @param wizardName - nome que vai ser adicionado na lista
 * @returns {Promise<void>}
 */
export const removeWizard = async (wizardName) => {
  try {
    // Pega o usuário que está logado no momento
    const currentUser = localStorage.getItem('current-user');

    delete currentUser?.wizards?.[wizardName];

    localStorage.setItem('current-user', {
      ...currentUser,
      wizards: currentUser?.wizards,
    });

    return api.update('users/me', {
      wizards: currentUser?.wizards,
    });
  } catch (e) {
    console.error('Deu algum problema na hora de adicionar wizard no banco');
  }
};

export const sendSatisfaction = (values) =>
  api.create('users/satisfactions', values);

export const updateSatisfaction = (values) =>
  api.update('users/satisfactions', values);

export const getRecommendations = (params) =>
  api.getList('users/recommendations', params);

export const getInvites = (params) => api.getList('users/invites', params);

export const createInvite = (values) => api.create('users/invites', values);

export const deleteInvite = (inviteId) =>
  api.delete(`users/invites/${inviteId}`);

export const verifyInvite = (inviteId) =>
  api.get(`users/invites/${inviteId}/verify`);

export const signupWithInvite = (inviteId, values) =>
  api.create(`users/invites/${inviteId}/use`, values);
