import Button from 'components/Button';
import { RadioButton } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import useFormValue from 'hooks/useFormValue';
import useReduxForm from 'hooks/useReduxForm';
import { Field, reduxForm } from 'redux-form';
import * as Fields from './fields';
import { CondoTitle, Warning, Wrapper } from './styles';

const actions = [
  {
    value: '1',
    label: 'Apenas desassociar, deixando o imóvel sem condomínio/empreendimento',
  },
  {
    value: '2',
    label: 'Associar os imóveis a outro condomínio/empreeendimento',
  },
];

function Form({ condo, handleSubmit, handleClose }) {
  const { change } = useReduxForm();

  const action = useFormValue('action');

  const toString = (value) => {
    if (typeof value === 'boolean') return value.toString();
    if (!value) return '';
    return value.toString();
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <p>Confirma a exclusão do condomínio/empreendimento?</p>
      <CondoTitle>{condo.title}</CondoTitle>

      {condo.properties_count > 0 && (
        <>
          <p className="h-margin-top--15">Existem imóveis associados a este condomínio/empreendimento.</p>
          <p className="h-margin-bottom--15">O que você gostaria de fazer com estes imóveis?</p>

          {actions.map((action) => (
            <Field
              key={action.value}
              type="radio"
              name="action"
              component={RadioButton}
              value={action.value}
              onChange={() => change('condominium_id', null)}
              label={action.label}
              format={toString}
            />
          ))}

          {action && action === '2' && (
            <Fields.CondominiumId condoIdToBeDeleted={condo.id} />
          )}
        </>
      )}

      <Warning>Esta ação não pode ser desfeita</Warning>

      <ModalFooter withBorder>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="danger">
          {action === '2' ? 'Associar e excluir' : 'Excluir'}
        </Button>
      </ModalFooter>
    </Wrapper>
  );
}

export default reduxForm({
  form: 'ModalCondoDelete',
  initialValues: {
    action: '1',
  },
  enableReinitialize: true,
})(Form);

