import PropertiesTable from 'components/PropertiesTable';
import { openModalProperty } from 'modules/modal';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import PropertiesList from 'pages/PropertiesSearch/components/PropertiesList';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
// import { Wrapper } from './styles';

function Properties({ handleClickCheckAll, handleRemove, handleRemoveAll }) {
  const dispatch = useDispatch();
  const {
    layout,
    properties,
    params,
    selectedProperties,
    toggleSelectedProperty,
  } = usePortalOptions();

  const handleClickProperty = (property) => {
    dispatch(openModalProperty({ property }));
  };

  const props = useMemo(() => {
    let _params = {
      properties: properties,
      filter: params.filter,
      onClickProperty: handleClickProperty,
    };

    if (!!params.filter) {
      _params = {
        ..._params,
        hasCheckbox: true,
        selectedProperties,
        toggleSelectedProperty,
        handleClickCheckAll,
      };
    } else {
      _params = {
        ..._params,
        hasCheckbox: true,
        selectedProperties,
        toggleSelectedProperty,
        handleClickCheckAll,
        handleDeleteProperty: handleRemove,
        handleRemove,
        handleRemoveAll,
      };
    }

    return _params;
  }, [
    properties,
    selectedProperties,
    handleRemove,
    handleRemoveAll,
    handleClickCheckAll,
    toggleSelectedProperty,
    params,
  ]);

  if (layout === 'card') {
    return (
      <PropertiesList {...props} type="vertical" rowClassName="propertyList" />
    );
  }

  return <PropertiesTable {...props} />;
}

export default Properties;
