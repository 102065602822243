import React from 'react';
// Components
import NavAside from 'components/NavAside';
import TreeNav from 'components/TreeNav';
import { useOrulo } from 'hooks/useOrulo';
import { useDwv } from 'hooks/useDwv';
import { RiTeamFill } from 'react-icons/ri';
import Can from 'containers/Can';

const Aside = () => {
  const { hasIntegration: hasOruloIntegration } = useOrulo();
  const { hasIntegration: hasDwvIntegration } = useDwv();

  return (
    <NavAside>
      <Can run="VIEW_PROPERTY_REPORTS">
        <TreeNav to="/reports/property" title="Imóveis" icon="receipt">
          <TreeNav.link to="/reports/property/per-type-transaction">
            Meu estoque
          </TreeNav.link>
          <TreeNav.link to="/reports/property/per-period">
            Captação (Quantidade)
          </TreeNav.link>
          <TreeNav.link to="/reports/property/per-period-sum">
            Captação (Preço)
          </TreeNav.link>
          {hasOruloIntegration && (
            <TreeNav.link to="/reports/property/orulo-per-type-transaction">
              Órulo
            </TreeNav.link>
          )}
          {hasDwvIntegration && (
            <TreeNav.link to="/reports/property/dwv-per-type-transaction">
              DWV
            </TreeNav.link>
          )}
        </TreeNav>
      </Can>
      <Can run="VIEW_PEOPLE_REPORTS">
        <TreeNav to="/reports/people" title="Clientes" icon="accounts-alt">
          <TreeNav.link to="/reports/people/by-realtor">
            Por corretor
          </TreeNav.link>
          <TreeNav.link to="/reports/people/per-period">
            Captação por período
          </TreeNav.link>
          <TreeNav.link to="/reports/people/by-channel-type">
            Origem da captação
          </TreeNav.link>
        </TreeNav>
      </Can>
      <TreeNav to="/reports/admin/info" title="Equipe" icon={RiTeamFill}>
        <TreeNav.link to="/reports/admin/info">Informações</TreeNav.link>
      </TreeNav>
      <Can run="VIEW_BUSINESS_REPORTS">
        <TreeNav to="/reports/deal" title="Negócios" icon="receipt">
          <TreeNav.link to="/reports/deal/per-channel">Por Canal</TreeNav.link>
          <TreeNav.link to="/reports/deal/per-broker">
            Por Corretor
          </TreeNav.link>
          <TreeNav.link to="/reports/deal/per-reason-loss">
            Motivos da Perda
          </TreeNav.link>
          <TreeNav.link to="/reports/deal/won">Ganhos</TreeNav.link>
          <TreeNav.link to="/reports/deal/loss">Perdidos</TreeNav.link>
        </TreeNav>
      </Can>
      <Can run="VIEW_BILLING_REPORTS">
        <TreeNav to="/reports/billing" title="Faturamento" icon="money">
          <TreeNav.link to="/reports/billing/per-type-transaction">
            Por Tipo Imóvel/Transação
          </TreeNav.link>
          <TreeNav.link to="/reports/billing/per-broker">
            Por Corretor
          </TreeNav.link>
          <TreeNav.link to="/reports/billing/per-commission">
            Comissão por Corretor
          </TreeNav.link>
          <TreeNav.link to="/reports/billing/per-realestate-commission">
            Comissão da Imobiliária
          </TreeNav.link>
        </TreeNav>
      </Can>
      {/*<TreeNav to="/reports/analytics" title="Analytics" icon="receipt">*/}
      {/*  <TreeNav.link to="/reports/analytics/new-users">*/}
      {/*    Novos usuários*/}
      {/*  </TreeNav.link>*/}
      {/*  <TreeNav.link to="/reports/analytics/visits">Acessos</TreeNav.link>*/}
      {/*</TreeNav>*/}
    </NavAside>
  );
};

export default Aside;
