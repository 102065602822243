import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import {
  Action,
  Actions,
  Content,
  PersonName,
  StagnantDays,
  Wrapper,
} from './styles';
import Properties from './components/Properties';
import { MdCheckCircle, MdWarning } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { openModalEvent, openModalPerson } from 'modules/modal';
import { ThemeProvider } from 'styled-components';
import Tooltip from 'react-tooltip';
import { usePipeline } from 'components/Pipeline/hooks/usePipeline';

function CardCrm({ deal }) {
  const dispatch = useDispatch();
  const { onAfterClosePerson } = usePipeline();

  const handleClick = () => {
    dispatch(
      openModalPerson(
        {
          id: deal?.id,
        },
        {
          initialPage: 'Properties',
          afterClose: onAfterClosePerson,
        }
      )
    );
  };

  const handleAddNewSchedule = (e) => {
    e.stopPropagation();

    dispatch(
      openModalEvent({
        initialValues: {
          people_id: deal?.id,
        },
        onSubmitSuccess: onAfterClosePerson,
      })
    );
  };

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  return (
    <ThemeProvider
      theme={{
        isStagnant: deal?.stagnant_days > 0,
      }}
    >
      <Wrapper onClick={handleClick}>
        <Content>
          <PersonName>{deal?.name}</PersonName>
          <Properties data={deal} />
        </Content>
        <Actions>
          {deal?.has_calendar > 0 && (
            <Action data-tip="Compromisso na agenda">
              <MdCheckCircle color="#6AC259" />
            </Action>
          )}
          {deal?.has_calendar === 0 && (
            <Action
              data-tip="Sem compromisso na agenda"
              onClick={handleAddNewSchedule}
            >
              <MdWarning color="#FFDF5C" />
            </Action>
          )}
          {deal?.stagnant_days && (
            <StagnantDays>{deal.stagnant_days}d</StagnantDays>
          )}
        </Actions>
      </Wrapper>
    </ThemeProvider>
  );
}

CardCrm.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    is_stagnant: PropTypes.bool,
    stagnant_days: PropTypes.number,
    has_calendar: PropTypes.bool,
    deals_count: PropTypes.number,
    order: PropTypes.number,
    deals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        property: PropTypes.shape({
          id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          subtype: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
          address_formatted: PropTypes.string.isRequired,
          cover_image_url: PropTypes.string,
        }),
      })
    ),
  }),
};

export default CardCrm;
