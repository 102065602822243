import React from 'react';
import { ModalTemplate } from '../../../../../components/Modal';
import trophy from '../../../../../assets/img/receptions/trophy.svg';

const propTypes = {};

const defaultProps = {};

const ModalGain = ({ realtorName, handleClose, ...props }) => (
  <ModalTemplate
    title="Parabéns por ter fechado negócio!"
    handleClose={handleClose}
  >
    <div style={{ textAlign: 'center' }}>
      <img
        src={trophy}
        alt="Icone de troféu"
        style={{ margin: '0 auto 15px' }}
      />
      <p className="h-color--primary">
        <strong>{realtorName}</strong>, parabéns por ter fechado o negócio!
        <br />
        Esperamos que você feche ainda muitos outros e<br />
        estaremos aqui para te ajudar.
      </p>
    </div>
  </ModalTemplate>
);

ModalGain.propTypes = propTypes;
ModalGain.defaultProps = defaultProps;

export default ModalGain;
