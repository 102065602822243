import { createContext, useContext, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import * as facebookService from 'services/settings/facebook';

export const FacebookContext = createContext({
  data: null,
  facebook: null,
  setFacebook: null,
  isConnected: null,
  setIsConnected: null,
  isFetching: null,
  setIsFetching: null,
  noPermission: null,
  setNoPermission: null,
  hasPages: null,
  setHasPages: null,
  handleOnAuth: null,
  pages: null,
  setPages: null,
  catalogs: null,
  setCatalogs: null,
  hasError: null,
  setHasError: null,
});

export const FacebookStoreProvider = ({ children }) => {
  const [facebook, setFacebook] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [noPermission, setNoPermission] = useState(false);
  const [pages, setPages] = useState([]);
  const [catalogs, setCatalogs] = useState([]);

  const { data } = useRequest({
    request: facebookService.getIntegration,
  });

  const handleOnAuth = async () => {
    setIsFetching(true);

    try {
      const response = await facebookService.login([
        'pages_show_list',
        'business_management',
        'catalog_management',
        'pages_show_list',
        'leads_retrieval',
        'pages_manage_metadata',
        'pages_read_engagement',
        'pages_manage_ads',
      ]);

      await facebookService;

      setFacebook(response?.authResponse);
      setIsConnected(true);
      setIsFetching(false);
    } catch (err) {
      setNoPermission(true);
      setIsFetching(false);
    }
  };

  return (
    <FacebookContext.Provider
      value={{
        data,
        facebook,
        setFacebook,
        isConnected,
        setIsConnected,
        isFetching,
        setIsFetching,
        noPermission,
        setNoPermission,
        handleOnAuth,
        pages,
        setPages,
        catalogs,
        setCatalogs,
        hasError,
        setHasError,
      }}
    >
      {children}
    </FacebookContext.Provider>
  );
};

export const useFacebookStore = () => useContext(FacebookContext);
