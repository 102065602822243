import { Field } from 'redux-form';
import { Select2 } from 'components/Form';

export const WARRANTIES = [
  { label: 'Depósito de Segurança', value: 'Depósito de Segurança' },
  { label: 'Fiador', value: 'Fiador' },
  { label: 'Garantia de Seguro', value: 'Garantia de Seguro' },
  { label: 'Carta de Garantia', value: 'Carta de Garantia' },
  { label: 'Títulos de Capitalização', value: 'Títulos de Capitalização' },
];

export default function FieldWarranties({ ...props }) {
  return (
    <Field
      name="warranties"
      label="Garantias"
      labelKey="label"
      valueKey="value"
      options={WARRANTIES}
      component={Select2}
      // parse={(value) => {
      //   if (!value) return null;
      //   return [value];
      // }}
      {...props}
    />
  );
}
