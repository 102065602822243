import React from 'react';
import { Field } from 'redux-form';
import { Checkbox } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
// import { Container } from './styles';

const FormUnBooking = ({ handleClose, submitting }) => {
  return (
    <>
      <p>
        Ao cancelar a reserva, a marcação <strong>RESERVADO</strong> será
        removida e o imóvel estará novamente disponível no Radar de perfil de
        interesse.
      </p>
      <Field
        type="checkbox"
        className="h-margin-top--15"
        label="Mostrar este imóvel no site"
        name="should_change_publish"
        component={Checkbox}
      />
      <ModalFooter>
        <Button color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button color="danger" disabled={submitting} type="submit">
          Cancelar Reserva
        </Button>
      </ModalFooter>
    </>
  );
};

export default FormUnBooking;
