import * as S from './styles';
import { MdOutlineReceipt } from 'react-icons/all';

export function Nodata({ icon: Icon = MdOutlineReceipt, title, children }) {
  return (
    <S.Wrapper>
      {Icon ? (
        <S.Icon>
          <Icon />
        </S.Icon>
      ) : null}
      {title ? <S.Title>{title}</S.Title> : null}
      {children}
    </S.Wrapper>
  );
}
