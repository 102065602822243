import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;

  /* &:nth-child(odd) {
    background: #f3f5f7;
  } */

  ${(props) =>
    props.transaction === 'Venda'
      ? css`
          border-left: 2px solid #31d084;
          background: rgba(49, 208, 132, 0.05);
        `
      : props.transaction === 'Locação'
      ? css`
          border-left: 2px solid #ff9702;
          background: rgba(255, 151, 2, 0.05);
        `
      : css``}

  ${(p) =>
    p.canSearch &&
    css`
      color: #575757;
      cursor: pointer;
    `}

  span + span:before {
    content: ', ';
  }
`;

export const SearchValueTitle = styled.span`
  font-weight: 700; 
  color: '#5C5260';
`;
