/**
 * [] - Salvar pesquisa
   [] - cliente se nao encontrar tem q cadastrar
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import classnames from 'classnames';
// Modules
import {
  condosSelector,
  dataValuesSelector,
  getInitialData,
  handleFormSubmit,
  peopleSelector,
  receiveData,
  receiveValues,
  reinitializeForm,
} from './module';
import { closeModal } from 'modules/modal';
// components do sistema
import { ModalTemplate } from 'components/Modal';
// Component Form
import ProfileForm from './components/ProfileForm';

class ModalProfile extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func,

    data: PropTypes.shape({
      types: PropTypes.arrayOf(PropTypes.object),
      people: PropTypes.arrayOf(PropTypes.object),
      characteristics: PropTypes.arrayOf(PropTypes.object),
      condoCharacteristics: PropTypes.arrayOf(PropTypes.object),
      condos: PropTypes.arrayOf(PropTypes.object)
    })
  };

  static defaultProps = {
    closeModal: null,
    data: {
      types: [],
      people: [],
      characteristics: [],
      condosCharacteristics: [],
      condos: []
    }
  };

  state = {
    hasSide: true
  };

  componentDidMount() {
    const {
      _keepValues,
      getInitialData,
      receiveData,
      receiveValues,
      data,
      values
    } = this.props;

    getInitialData();

    const {
      characteristics: o1,
      condosCharacteristics: o2,
      establishments: o3,
      countries: o4,
      states: o5,
      cities: o6,
      neighborhoods: o7
    } = data;

    if (data) {
      if (!o1 && !o2 && !o3 && !o4 && !o5 && !o6 && !o7) {
        this.setState({ hasSide: false });
      }
    }

    if (!_keepValues) {
      receiveData(data);
      receiveValues(values);
    }
  }

  getTitle = () => 'Salvar Perfil';

  render() {
    const {
      modalConfig,
      isOpen,
      closeModal,
      modalType,
      people,
      handleFormSubmit
    } = this.props;

    const { hasSide } = this.state;

    const sizeModal = hasSide ? 'large' : 'medium';

    return (
      <Modal
        {...modalConfig}
        className={classnames('Modal', {
          [`Modal--${sizeModal}`]: sizeModal
        })}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={closeModal}
      >
        <ModalTemplate title={this.getTitle()} handleClose={closeModal}>
          <ProfileForm
            hasSide={hasSide}
            // dataValues
            {...this.props.dataValues}
            // Form Data
            people={people}
            condos={this.props.condos}
            // Event Handlers
            handleClose={closeModal}
            onSubmit={handleFormSubmit}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  people: peopleSelector(state),
  condos: condosSelector(state),
  dataValues: dataValuesSelector(state)
});

const mapDispatchToProps = {
  getInitialData,
  closeModal,
  receiveData,
  receiveValues,
  handleFormSubmit,
  reinitializeForm
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalProfile);
