// import styles from './styles'

import { Field } from 'redux-form';
import { Input } from 'components/Form';
import React from 'react';

export function Price({ name, label = 'Preço', ...props }) {
  return (
    <Field
      {...props}
      valueFormat
      prefix=""
      label={label}
      name={name}
      component={Input}
      inputProps={{
        decimalScale: 2,
      }}
    />
  );
}
