import { combineReducers } from 'redux';
// Services
import * as peopleService from 'services/people';
// Modules
import dataReducer from 'modules/data';

// Selector
export const peopleSelector = state => state.modalPropertySendMail.people.data;
export const isFetchingPeopleSelector = state =>
  state.modalPropertySendMail.people.meta.isFetching;

export const modelsSelector = state => state.modalPropertySendMail.models.data;
export const isFetchingModelSelector = state =>
  state.modalPropertySendMail.models.meta.isFetching;

const { reducer: peopleReducer, actionCreators: peopleActions } = dataReducer(
  `modalPropertySendMail/clients`
);

const { reducer: modelsReducer, actionCreators: modelsActions } = dataReducer(
  `modalPropertySendMail/models`
);

// Thunks
export const fetchPeople = params => dispatch => {
  dispatch(peopleActions.request());

  return peopleService.getAll(params).then(({ data }) => {
    dispatch(peopleActions.receive(data));
    return data;
  });
};

export const fetchModels = params => dispatch => {
  const models = [
    {
      value: '1',
      label: 'Modelo do joaozinho',
      description:
        'Olá Joaozinho, vi que você ficou interessado nesse modelo de imóvel'
    },
    {
      value: '2',
      label: 'Modelo da juquinha',
      description:
        'Olá Juquinha, vi que você se interessou nesse modelo de imóvel'
    }
  ];

  dispatch(modelsActions.request());
  return new Promise(resolve => {
    dispatch(modelsActions.receive(models));
    resolve(models);
  });
};

export const handleSubmitFormSendMail = values => dispatch => {
  return new Promise(resolve => {
    resolve(values);
  });
};

export const handleSubmitFormSaveModel = values => dispatch => {
  return new Promise(resolve => {
    resolve(values);
  });
};

export default combineReducers({
  people: peopleReducer,
  models: modelsReducer
});
