// import * as S from './styles'

import { Table } from '../../../../../../components/Box';
import * as S from '../../styles';
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md';
import React, { useMemo, useState } from 'react';

export function Item({ data }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded((v) => !v);
  };

  const hasChildren = useMemo(() => {
    return data.children.length > 0;
  }, [data]);

  return (
    <>
      <Table.Tr isExpanded={isExpanded} onClick={toggleExpanded}>
        <td>
          <S.Category>
            {hasChildren ? (
              <S.Arrow>
                {isExpanded ? <MdArrowDropDown /> : <MdArrowRight />}
              </S.Arrow>
            ) : (
              <S.Arrow />
            )}
            <S.Ball color={data.color} />
            <div>{data.title}</div>
          </S.Category>
        </td>
        <td align="right">{data.value}</td>
        <td align="center">{data.percent}</td>
      </Table.Tr>
      {isExpanded ? (
        <>
          {data.children.map(({ id }) => (
            <Table.TrItem key={id}>
              <td>
                <S.SubCategory>Luz</S.SubCategory>
              </td>
              <td align="right">-R$ 50,00</td>
              <td align="center">50%</td>
            </Table.TrItem>
          ))}
          <tr height="10px">
            <td />
          </tr>
        </>
      ) : null}
    </>
  );
}
