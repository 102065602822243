import { Input } from "components/Form";
import { maxLength, normalizeCreditCard, normalizeMonthYearFull } from "lib/formHelpers";
import { Row } from "react-flexbox-grid";
import { Field } from "redux-form";
import FieldInstallments from "../../../../components/FieldInstallments";
import FieldRecurringCharge from "../../../../components/FieldRecurringCharge";

const required = (value) =>
  value || typeof value === 'number' ? undefined : 'Campo obrigatório';

const CreateCard = () => {
  return (
    <>
      <Row>
        <Field
          xs={12}
          label="Nome no cartão"
          name="holder_name"
          component={Input}
          validate={[required]}
        />
      </Row>
      <Row>
        <Field
          xs={6}
          label="Número do cartão"
          name="number"
          component={Input}
          normalize={normalizeCreditCard}
          placeholder="0000 0000 0000 0000"
          validate={[required]}
        />
        <Field
          xs={3}
          label="Validade"
          name="expiration_date"
          component={Input}
          normalize={normalizeMonthYearFull}
          placeholder="MM/AAAA"
          validate={[required]}
        />
        <Field
          xs={3}
          label="CVC"
          name="cvv"
          component={Input}
          normalize={maxLength(4)}
          placeholder="000"
          validate={[required]}
        />
      </Row>
      <Row>
        <FieldInstallments xs={12} />
      </Row>

      <FieldRecurringCharge />
    </>
  );  
}

export default CreateCard;
