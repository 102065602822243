import * as S from './styles';
import PropTypes from 'prop-types';
import { useWizard } from 'hooks/useWizard';
import Popover from 'components/Popover';
import FormClose from 'components/CanShowAgain/Form';
import { Close } from 'pages/NewDashboard/components/Advertisements/components/Box/styles';
import { MdClose } from 'react-icons/md';
import React from 'react';
import { ThemeProvider } from 'styled-components';

function Box({
  color = '#FFA500',
  canClose = false,
  storageKey,
  label,
  children,
  action,
}) {
  const { isVisible, close } = useWizard({ key: storageKey });

  if (!isVisible) return null;

  return (
    <ThemeProvider
      theme={{
        color,
      }}
    >
      <S.Wrapper>
        {label?.text ? (
          <S.Action>
            {label?.icon} {label?.text}
          </S.Action>
        ) : null}
        <S.Text>{children}</S.Text>
        {action ? (
          <S.Action onClick={action.onClick}>{action.text}</S.Action>
        ) : null}
        {canClose && (
          <Popover
            width={310}
            initialValues={{ not_show_again: false }}
            onSubmit={close}
            component={FormClose}
            style={{ display: 'inline' }}
          >
            <Close>
              <MdClose />
            </Close>
          </Popover>
        )}
      </S.Wrapper>
    </ThemeProvider>
  );
}

Box.propTypes = {
  children: PropTypes.node,
  label: PropTypes.shape({
    icon: PropTypes.node,
    text: PropTypes.string,
  }),
  storageKey: PropTypes.string,
  action: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.node,
  }),
};

export default Box;
