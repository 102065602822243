import _ from 'lodash';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { combineReducers } from 'redux';
import Alert from 'react-s-alert';
// Helpers
import { createModule } from '../lib/reducer-helpers';
import { getChecked } from '../lib/object-helpers';
// Modules
import { hideLoading, showLoading } from './loading';
import locationReducer, {
  locationSelectorsCreate,
  thunksCreator,
} from './location';
import { getRealtors } from './realtors';
import { getEstablishments } from './establishments';
import { getCharacteristics } from './characteristics';
import { getCadastralSituations } from './cadastralSituations';
import { getCharacteristics as getCondoCharacteristics } from './condosCharacteristics';
import { getIndexes } from './financialIndex';
import { setPosition, setPov, setZoom } from './mapView';
// Services
import * as propertiesService from '../services/properties';
import * as typesService from '../services/types';
import * as cepsService from '../services/ceps';
import * as messagesService from '../services/settings/messages';
import { openModalProperty, openModalPropertyGroup } from 'modules/modal';

// Selectors
const formSelector = formValueSelector('propertiesAdd');
export const locationSelector = locationSelectorsCreate('modalPersonAdd');
export const locationThunks = thunksCreator('modalPersonAdd');

export const getNeighborhoods = (state) =>
  locationSelector.neighborhoodsSelector(state);
export const getNeighborhoodId = (state) =>
  formSelector(state, 'neighborhood_id');

export const getNeighborhood = createSelector(
  [getNeighborhoods, getNeighborhoodId],
  (neighborhoods, neighborhood_id) => {
    return (
      neighborhoods.find(
        (neighborhood) => neighborhood.id === neighborhood_id
      ) || {}
    );
  }
);

export const getCities = (state) => locationSelector.citiesSelector(state);
export const getStates = (state) => locationSelector.statesSelector(state);

export const getCityId = (state) => formSelector(state, 'city_id');
export const getStateId = (state) => formSelector(state, 'state_id');

export const getCity = createSelector(
  [getCities, getCityId],
  (cities, cityId) => {
    return cities.find((city) => city.id === cityId) || {};
  }
);

export const getState = createSelector(
  [getStates, getStateId],
  (states, stateId) => {
    return states.find((state) => state.id === stateId) || {};
  }
);

// Actions
const createAction = createModule('propertyAdd');
const REQUEST_FORM_DATA = createAction('REQUEST_FORM_DATA');
const UPDATE_FORM_DATA = createAction('UPDATE_FORM_DATA');
const SHOW_VIDEO = createAction('SHOW_VIDEO');
const HIDE_VIDEO = createAction('HIDE_VIDEO');
const RECEIVE_TYPES = createAction('RECEIVE_TYPES');
const RESET_FORM = createAction('RESET_FORM');
const REQUEST_LOCATION = createAction('REQUEST_LOCATION');
const REQUEST_LOCATION_SUCCESS = createAction('REQUEST_LOCATION_SUCCESS');

export const typeIdSelector = (state) => formSelector(state, 'type_id');
export const typesSelector = (state) => state.propertyAdd.reducer.types;
export const videoSelector = (state) => state.propertyAdd.reducer.video;

// Initial State
const videoInitialState = {
  videoId: null,
  meta: {
    isVisible: false,
  },
};
const initialState = {
  formData: {},
  types: [],
  video: videoInitialState,
  meta: {
    isFetchingFormData: false,
    isFetchingLocation: false,
  },
};

// Reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FORM_DATA:
      return {
        ...state,
        meta: { ...state.meta, isFetchingFormData: true },
      };
    case UPDATE_FORM_DATA:
      return {
        ...state,
        formData: { ...state.formData, ...action.formData },
      };
    case SHOW_VIDEO:
      return {
        ...state,
        video: { videoId: action.videoId, meta: { isVisible: true } },
      };
    case HIDE_VIDEO:
      return { ...state, video: videoInitialState };
    case RECEIVE_TYPES:
      return { ...state, types: action.types };
    case REQUEST_LOCATION:
      return {
        ...state,
        meta: { ...state.meta, isFetchingLocation: true },
      };
    case REQUEST_LOCATION_SUCCESS:
      return {
        ...state,
        meta: { ...state.meta, isFetchingLocation: false },
      };
    case RESET_FORM:
      return initialState;
    default:
      return state;
  }
}

// Root reducer
export default combineReducers({
  reducer,
  location: locationReducer('propertyAdd'),
});

// Action Creators
export const requestFormData = () => ({ type: REQUEST_FORM_DATA });
export const receiveTypes = (types) => ({ type: RECEIVE_TYPES, types });
export const updateFormData = (formData) => ({
  type: UPDATE_FORM_DATA,
  formData,
});
export const requestLocation = () => ({ type: REQUEST_LOCATION });
export const requestLocationSuccess = () => ({
  type: REQUEST_LOCATION_SUCCESS,
});

// Thunks
/**
 * Atualiza os dados do formulário
 * @param formData
 */
export const setFormData = (formData) => (dispatch) =>
  dispatch(updateFormData(formData));

/**
 * Mostra o vídeo no formulario de cadastro/edicao de imóvel
 * @param videoId
 */
export const showVideo = (videoId) => (dispatch) =>
  dispatch({ type: SHOW_VIDEO, videoId });

/**
 * Esconde o vídeo da sessão do formulario
 */
export const hideVideo = () => (dispatch) => dispatch({ type: HIDE_VIDEO });

/**
 * Reseta o formulário de cadastro/edicao de imóvel
 */
export const resetForm = () => (dispatch) => dispatch({ type: RESET_FORM });

/**
 * Pega todos os tipos/subtipos dos imóveis
 * @return Promise
 */
export const getTypes = () => (dispatch) =>
  typesService.getTypesAndSubtypes().then(({ data }) => {
    dispatch(receiveTypes(data));
    return data;
  });

/**
 * Busca a localizacao de um CEP
 * @param cep
 * @param params
 * @return Promise
 */
export const handleChangeCep = (cep, params) => async (dispatch) => {
  dispatch(requestLocation());

  try {
    const { data } = await cepsService.getLocation({ cep, ...params });
    return data;
  } catch (e) {
    if (e.response.status === 404) {
      Alert.success(`Ops! Não encontramos dados para o CEP ${cep}.`);
    }
  } finally {
    dispatch(requestLocationSuccess());
  }
};

/**
 * Pega a proxima referência valida dos imóveis
 * @return Promise
 */
const getReference = () => (dispatch) =>
  propertiesService.getReference().then(({ data: { reference } }) => {
    setFormData({ reference })(dispatch);
  });

const getDescription = () => (dispatch) =>
  messagesService.getOne().then(({ data: { property_description } }) => {
    setFormData({ description: property_description })(dispatch);
  });

export const fetchEstablishments = () => (dispatch) =>
  getEstablishments()(dispatch).then((establishments) => {
    const newEstablishments = _.keyBy(
      establishments.map((establishment) => ({
        ...establishment,
        isChecked: false,
      })),
      'id'
    );

    setFormData({
      establishments: newEstablishments,
    })(dispatch);

    return newEstablishments;
  });

/**
 * Busca os dados baseados no que está preenchido no imóvel
 * @param city_id
 * @param country_id
 * @param state_id
 * @param neighborhood_id
 * @return {Function}
 */
export const fetchLocation =
  ({ city_id, country_id, state_id }) =>
  async (dispatch) => {
    locationThunks.getCountries()(dispatch);

    if (country_id) {
      locationThunks.getStates({ country_id })(dispatch);
    }

    if (state_id) {
      locationThunks.getCities({ state_id })(dispatch);
    }

    if (city_id) {
      locationThunks.getNeighborhoods({ city_id })(dispatch);
    }
  };

const DEFAULT_POSITION = {
  maps_latitude: -23.55052,
  maps_longitude: -46.633309,
  maps_zoom: 15,
};

/**
 * Pega a latitude e longitude de um imóvel
 * @return {{ lat, lng, maps_zoom }}
 */
const getMapPosition = () => {
  // Pega o usuário que está logado
  const currentUser = localStorage.getItem('current-user');

  // Pega a imobiliária do usuário
  const { real_estate } = currentUser;

  // Se tiver bairro e não estiver editando
  if (real_estate.neighborhood_id) {
    return {
      maps_latitude: real_estate.maps_latitude,
      maps_longitude: real_estate.maps_longitude,
      maps_zoom: DEFAULT_POSITION.maps_zoom,
    };
  }

  return DEFAULT_POSITION;
};

/**
 * Seta a localização de um imóvel no cadastro/edição
 * @param {string} maps_latitude
 * @param {string} maps_longitude
 * @param {string} maps_zoom
 * @param {Number|float} maps_heading
 * @param {Number|float} maps_pitch
 * @param {Number} maps_street_zoom
 * @return {Function}
 */
const setMapPosition =
  ({
    maps_latitude,
    maps_longitude,
    maps_zoom,
    maps_heading,
    maps_pitch,
    maps_street_zoom,
  }) =>
  (dispatch) => {
    // Form data
    let formData = {};
    if (maps_latitude) formData = { ...formData, maps_latitude };
    if (maps_longitude) formData = { ...formData, maps_longitude };
    if (maps_zoom) formData = { ...formData, maps_zoom };

    // Position of View
    let pov = {};
    if (maps_heading) pov = { ...pov, heading: maps_heading };
    if (maps_pitch) pov = { ...pov, pitch: maps_pitch };
    if (maps_street_zoom) pov = { ...pov, zoom: maps_street_zoom };

    // Position
    let position = {};
    if (maps_latitude) position = { ...position, lat: maps_latitude };
    if (maps_longitude) position = { ...position, lng: maps_longitude };

    // Seta a posição do mapa
    if (!_.isEmpty(position)) {
      dispatch(setPosition(position));
    }

    // Muda o zoom do mapa
    if (maps_zoom) {
      dispatch(setZoom(maps_zoom));
    }

    // StreetView
    if (!_.isEmpty(pov)) {
      dispatch(setPov(pov));
    }

    setFormData(formData)(dispatch);
  };

/**
 * Pega os dados iniciais do formulário de cadastro de imóvel
 * @param property_id
 * @return {function(*=)}
 */
export function getInitialState(property_id) {
  return (dispatch, getState) =>
    new Promise(async (resolve, reject) => {
      const isEditting = !!property_id;

      dispatch(showLoading());

      const promises = [];

      // Pega o usuário que está logado
      const currentUser = localStorage.getItem('current-user');

      // Pega a imobiliária
      const { real_estate } = currentUser;

      // reseta os dados do formulário antes de iniciar a busca dos dados iniciais
      resetForm()(dispatch);
      dispatch(requestFormData());

      setFormData({
        is_price_shown: true,
        has_finance: false,
        is_financeable: false,
        price_alternative_text: 'Consulte',
      })(dispatch);

      // Busca a ultima referência cadastrada caso não seja o editar imóvel
      if (!isEditting) {
        // pega a referência
        promises.push(getReference()(dispatch));

        // Seta o texto padrão da descrição
        promises.push(getDescription()(dispatch));

        // Retorna a posição do mapa baseado nas informações que a imobiliaria tem
        const position = getMapPosition(property_id);

        setMapPosition(position)(dispatch);
      }

      // pega todos os tipos de imóveis
      promises.push(getTypes()(dispatch));

      // Pega todos os indíces financeiros
      // Seleciona o primeiro indice na lista como principal
      promises.push(
        getIndexes({
          sort: '-real_estate_id',
        })(dispatch).then((data) => {
          // const indexId = indexDefaultSelector(getState()).id;
          const id = data[0].id;

          setFormData({
            price_financial_index_id: id,
            territorial_tax_financial_index_id: id,
            condominium_financial_index_id: id,
            taxes_financial_index_id: id,
          })(dispatch);
        })
      );

      // pega todos os corretores
      promises.push(getRealtors()(dispatch));

      // Pega as caracteristicas do condomínio
      promises.push(getCondoCharacteristics()(dispatch));

      // Pega as situações cadastrais do imóvel
      promises.push(getCadastralSituations()(dispatch));

      // Pega todos os estabelecimentos
      let establishments = await fetchEstablishments()(dispatch, getState);

      // Se a imobiliaria tem bairro
      // e não estiver editando um imóvel
      if (real_estate.neighborhood_id && !property_id) {
        const { country_id, state_id, city_id } = real_estate.relations;

        // Busca os dados com base as relações da imobiliária
        fetchLocation(real_estate.relations)(dispatch);

        // Seta os dados no formulário de cadastro de imóvel
        setFormData({
          neighborhood_id: real_estate.neighborhood_id,
          country_id,
          state_id,
          city_id,
          has_tour: false,
        })(dispatch);
      }

      if (property_id) {
        promises.push(
          propertiesService
            .getOne(property_id, {
              include:
                'relations,subtype,characteristics,condominium,cadastral_situations',
            })
            .then(({ data }) => {
              const {
                condominium,
                condominium_id,
                relations,
                subtype,
                characteristics,
                cadastral_situations,
                areas,
                tour_360,
                ...property
              } = data;

              const { establishments_id, ...restRelations } = relations;

              let newCharacteristics = {};
              let newAreas = {};
              let newCadastralSituations = {};
              let condos_characteristics = {};

              let { area_fields } = subtype.type;

              if (area_fields) {
                newAreas = _(area_fields)
                  .map((area) => ({
                    ...area,
                    measure: area.measures[0],
                  }))
                  .keyBy('name')
                  .value();
              }

              // Busca todas as caracteristicas do tipo
              getCharacteristics(subtype.type.id)(dispatch);

              // Busca todas as informações necessárias para mostrar a seção de localização
              fetchLocation({
                ...restRelations,
                neighborhood_id: property.neighborhood_id,
              })(dispatch);

              setMapPosition(data)(dispatch);

              // Transforma array pra objeto
              if (areas) {
                newAreas = {
                  ...newAreas,
                  ..._.keyBy(areas, 'name'),
                };
              }

              // verifica se tem caracteristicas
              if (characteristics && characteristics.length > 0) {
                newCharacteristics = _.keyBy(
                  characteristics.map((value) => ({
                    ...value,
                    isChecked: true,
                  })),
                  'id'
                );
              }

              // verifica se tem situações cadastrais
              if (cadastral_situations && cadastral_situations.length > 0) {
                newCadastralSituations = _.keyBy(
                  cadastral_situations.map((value) => ({
                    ...value,
                    isChecked: true,
                  })),
                  'id'
                );
              }

              // verifica se tem estabelecimentos
              if (establishments_id) {
                establishments = _(establishments_id)
                  .map((establishmentId) => ({
                    id: establishmentId,
                    isChecked: true,
                  }))
                  .keyBy('id')
                  .value();
              }

              if (condominium && condominium.condos_characteristics) {
                condos_characteristics = _(condominium.condos_characteristics)
                  .map((item) => ({
                    ...item,
                    isChecked: true,
                  }))
                  .keyBy('id')
                  .value();
              }

              setFormData({
                ...restRelations,
                ...property,
                characteristics: newCharacteristics,
                cadastral_situations: newCadastralSituations,
                condos_characteristics,
                establishments,
                areas: newAreas,
                is_condo: !!condominium_id,
                condominium_id,
                tour_360,
                has_tour: !!tour_360,
                subtype_id: {
                  label: subtype.title,
                  value: subtype.id,
                  group: { ...subtype.type },
                },
              })(dispatch);

              setTimeout(() => {}, 5000);
            })
        );
      }

      Promise.all(promises)
        .catch(() => {
          reject();
        })
        .finally(() => {
          dispatch(hideLoading());
          resolve();
        });
    });
}

// const redirect = (values, props) =>

const getLocation = async (params) => {
  try {
    const { data } = await cepsService.getLocation(params);

    if (_.isArray(data)) {
      return { latitude: data?.[0]?.latitude, longitude: data?.[0]?.longitude };
    }

    return { latitude: data.latitude, longitude: data.longitude };
  } catch (err) {
    console.error('Problema ao buscar a localização', err);
    return { latitude: 0, longitude: 0 };
  }
};

export const handleFormSubmit =
  (values, props) => async (dispatch, getState) => {
    // Se tiver id significa que está editando um imóvel
    const isPropertyEditting = !!values.id;
    let newValues = values;

    // Caso não tenha latitude ou longitude
    // busca pelos parametros
    if (!values?.maps_latitude || !values?.maps_longitude) {
      const { latitude, longitude } = await getLocation({
        cep: values?.zip_code,
        neighborhood_id: values?.neighborhood_id,
        street_name: values?.street_address,
      });

      newValues = {
        ...newValues,
        maps_latitude: latitude,
        maps_longitude: longitude,
      };
    }

    if (values.characteristics) {
      newValues = {
        ...newValues,
        characteristics: getChecked(values.characteristics),
      };
    }

    if (values.cadastral_situations) {
      newValues = {
        ...newValues,
        cadastral_situations: getChecked(values.cadastral_situations),
      };
    }

    if (values.condos_characteristics) {
      newValues = {
        ...newValues,
        condos_characteristics: getChecked(values.condos_characteristics),
      };
    }

    if (values.establishments) {
      newValues = {
        ...newValues,
        establishments: getChecked(values.establishments),
      };
    }

    newValues = {
      ...newValues,
      subtype_id: newValues?.subtype_id?.value,
    };

    if (isPropertyEditting) {
      try {
        const { data } = await propertiesService.alterProperty(newValues);

        if (data.condominium_id) {
          openModalPropertyGroup({ editted: data.id, property: data })(
            dispatch
          );
        } else {
          // abre a modal de vida do imóvel
          openModalProperty({ property: data })(dispatch);
        }

        props.history.push('/properties/search');
        return data;
      } catch (err) {
        console.error('Problema na edição de imóvel', newValues);
        throw err;
      }
    }

    try {
      const { data } = await propertiesService.addProperty(newValues);
      props.history.push(`/properties/revision/show`);
      return data;
    } catch (err) {
      console.error('Problema no cadastro de imóvel', newValues);
      throw err;
    }
  };
