import React, { useCallback, useEffect } from 'react';
// Components
import DealChart from './components/DealChart';
import DealTable from './components/DealTable';
// Constantes
import { useReport } from '../hooks';
import { getDealPerChannel } from 'services/reports';
import WrapperReport from '../../components/WrapperReport';
import Form from '../../components/FormInterval';
import moment from 'lib/moment';

export default function DealPerChannel() {
  const { data, chartData, fetchData, isFetching, toggle } = useReport({
    request: getDealPerChannel,
  });

  const handleSubmit = useCallback(
    (values) => {
      fetchData(values);
    },
    [fetchData]
  );

  useEffect(() => {
    const startDate = moment()
      .subtract(5, 'month')
      .startOf('month')
      .format('DD/MM/YYYY');

    const endDate = moment().endOf('month').format('DD/MM/YYYY');

    fetchData({
      period: 'previousMonth',
      start_date: startDate,
      end_date: endDate,
    });
  }, []);

  return (
    <WrapperReport
      title="Negócios ganhos por canal"
      text="Veja qual dos canais de divulgação tem o melhor desempenho."
      data={data}
      isFetching={isFetching}
      renderForm={() => <Form onSubmit={handleSubmit} />}
      renderChart={() => (
        <DealChart width={'100%'} height={250} data={chartData} />
      )}
      renderTable={() => <DealTable data={data} toggleChecked={toggle} />}
    />
  );
}
