import { combineReducers } from 'redux';
import { createModule } from '../lib/reducer-helpers';
import * as peopleService from '../services/people';
import { STATUS } from '../services/deals';

const createAction = createModule('personCrm');

// Action Types
const REQUEST_DEALS = createAction('REQUEST_DEALS');
const RECEIVE_DEALS = createAction('RECEIVE_DEALS');

// Selectors
export const dealsSelector = state => state.personCrm.deals.data;
export const isFetchingDealsSelector = state =>
  state.personCrm.deals.meta.isFetching;

const initialState = {
  deals: {
    data: [],
    meta: {
      isFetching: false
    }
  }
};

export function reducerDeals(state = initialState.deals, action) {
  switch (action.type) {
    case REQUEST_DEALS:
      return {
        ...state,
        meta: { isFetching: true }
      };
    case RECEIVE_DEALS:
      return {
        ...state,
        data: action.payload,
        meta: { isFetching: false }
      };
    default:
      return state;
  }
}

// Reducer
export default combineReducers({
  deals: reducerDeals
});

// Action Creators
export const requestDeals = () => ({
  type: REQUEST_DEALS
});

export const receiveDeals = payload => ({
  type: RECEIVE_DEALS,
  payload
});

// Thunks
export const getDeals = (person, status, params) => dispatch => {
  dispatch(requestDeals());

  return peopleService
    .getDeals(person.id, { ...params, filter: { status } })
    .then(({ data: deals }) => {
      dispatch(receiveDeals(deals));

      return deals;
    });
};

export const getOpenedDeals = (person, params) => dispatch =>
  getDeals(person, STATUS.OPENED, params)(dispatch);
export const getGainDeals = (person, params) => dispatch =>
  getDeals(person, STATUS.GAIN, {
    ...params,
    include: 'stage'
  })(dispatch);
export const getLossDeals = (person, params) => dispatch =>
  getDeals(person, STATUS.LOSS, {
    ...params,
    include: 'stage,loss_reason'
  })(dispatch);
