import React from 'react';
// Components
import { FieldInterval } from 'components/Form';

function RevisionField(props) {
  return (
    <FieldInterval
      title="Período de revisão"
      nameMax="by_next_review_at_lower_equals"
      nameMin="by_next_review_at_greater_equals"
      {...props}
    />
  );
}

export default RevisionField;
