import React from 'react';
import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
import * as propertiesBooking from 'services/propertiesBooking';
import { useDispatch } from 'react-redux';
import { openModalProperty } from 'modules/modal';

function ModalReservationCreate({
  reservation,
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  afterClose,
  onClose,
  onSubmitSuccess,
}) {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    return propertiesBooking.update({ property_id: property?.id, ...values });
  };

  const close = () => {
    handleClose();

    if (onSubmitSuccess) {
      return onSubmitSuccess();
    }

    if (onClose) {
      afterClose();
      return;
    }
    dispatch(
      openModalProperty({ initialPage: 'Reservations', property, afterClose })
    );
  };

  const { property, ...initialValues } = reservation;

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Edição de reserva"
        text="Sinalize aos corretores que existe um cliente em fase de negociação com este imóvel"
        handleClose={handleClose}
      >
        <Form
          initialValues={{
            ...initialValues,
          }}
          onSubmit={handleSubmit}
          handleClose={close}
          onSubmitSuccess={() => {
            if (onSubmitSuccess) {
              return onSubmitSuccess();
            }
            if (onClose) {
              handleClose();
              afterClose();
              return;
            }
            dispatch(openModalProperty({ property, afterClose }));
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalReservationCreate;
