import { combineReducers } from 'redux';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
// Modules
import dataReducer from 'modules/data';
import { closeModal } from 'modules/modal';
// Services
import * as peopleService from 'services/people';
import * as userService from 'services/user';
import * as dealsService from 'services/deals';

export const {
  selectors: peopleSelectors,
  actionCreators: peopleActions,
  reducer: peopleReducer
} = dataReducer('modalPropertyRent/people');

export const {
  selectors: usersSelectors,
  actionCreators: usersActions,
  reducer: usersReducer
} = dataReducer('modalPropertyRent/users');

export const formSelector = formValueSelector('FormRent');
export const getNumberOfMonths = state =>
  formSelector(state, 'number_of_months');
export const getTotal = createSelector([getNumberOfMonths]);

/**
 * Busca todas as clientes
 * @param params - os parametros do request
 * @return {Function|Array} - retorna a lista de clientes
 */
export const fetchPeople = params => dispatch => {
  dispatch(peopleActions.request());

  return peopleService.getAll(params).then(({ data: people }) => {
    dispatch(peopleActions.receive(people));
    return people;
  });
};

/**
 * Busca todos os corretores que tem na imobiliária
 * @param params
 * @return {Function|Array} - retorna a lista de usuários
 */
export const fetchUsers = params => dispatch => {
  dispatch(usersActions.request());

  return userService.getRealtors(params).then(({ data: users }) => {
    dispatch(usersActions.receive(users));
    return users;
  });
};

/**
 * Lida com o submit do formulário de fechar o negocio
 * @param values
 * @return {Function}
 */
export const handleFormSubmit = values => dispatch => {
  const onSubmitSuccess = res => {
    dispatch(closeModal());
    return res;
  };

  // Verifica se tem id
  if (values.id) {
    return dealsService.updateDealEarned(values).then(onSubmitSuccess);
  }

  return dealsService.dealEarn(values).then(onSubmitSuccess);
};

export default combineReducers({
  people: peopleReducer,
  users: usersReducer
});
