import React, { useCallback, useState, useMemo, useEffect, useContext } from 'react';
import map from 'lodash/map';
import { Overlay, Wrapper } from './styles';
import Results from '../Results';
import PeopleResults from '../PeopleResults';
import NoResults from '../NoResults';
import PropertiesResults from '../PropertiesResults';
import CondosResults from '../CondosResults';
import { SearchBarContext } from 'components/SearchBar/context';

const ComponentByKey = {
  people: PeopleResults,
  properties: PropertiesResults,
  condos: CondosResults
};

const List = ({ data, isOpen }) => {
  const { search, setHoverList, reset } = useContext(SearchBarContext);

  const [activeResult, setActiveResult] = useState(null);

  useEffect(() => {
    setActiveResult(null);
  }, [search]);

  const handleClickLabel = useCallback(key => {
    setActiveResult(prevResult => {
      if (prevResult === key) return null;
      return key;
    });
  }, []);

  const filteredData = useMemo(() => {
    let _data = map(data, (results, key) => {
      return { key, results };
    });

    if (activeResult) {
      return _data.filter(({ key }) => {
        return key === activeResult;
      });
    }

    return _data;
  }, [data, activeResult]);

  return (
    <>
      <Wrapper
        isOpen={isOpen}
        animate="open"
        onMouseEnter={() => setHoverList(true)}
        onMouseLeave={() => {
          setHoverList(false);
          setTimeout(() => {
            reset();
          }, 500);
        }}
      >
        <NoResults />
        <Results data={data} onClickLabel={handleClickLabel} />
        {filteredData.map(({ key, results: { data: results, meta } }) => {
          const Component = ComponentByKey[key];
          return <Component data={results} meta={meta} />;
        })}
      </Wrapper>
      <Overlay isOpen={isOpen} />
    </>
  );
};

export default React.memo(List);
