// Widgets.js
import { createModule } from '../lib/reducer-helpers';

const initialState = { pageName: 'Home' };

/**
 * Cria as ações do reducer
 * @param createAction
 */
const createReducerActions = createAction => ({
  SET_PAGE: createAction('SET_PAGE')
});

/**
 * Cria um reducer de menu a partir de um objeto de ações
 * @param actions
 */
const reducer = (actions, currentState) => (state = currentState, action) => {
  switch (action.type) {
    case actions.SET_PAGE:
      return { ...state, pageName: action.pageName };
    default:
      return state;
  }
};

/**
 * Cria funções thunks
 * @param action
 */
const createMenuThunk = actions => ({
  setPage: pageName => dispatch =>
    dispatch({ type: actions.SET_PAGE, pageName })
});

export default (reducerName, state = initialState) => {
  const createAction = createModule(reducerName);
  const actions = createReducerActions(createAction);

  return {
    reducer: reducer(actions, state),
    thunks: createMenuThunk(actions)
  };
};
