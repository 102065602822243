import Alert from 'react-s-alert';
// Widgets.js
import createDataReducer from 'modules/data';
import * as groupsService from 'services/settings/groups';

export const {
  actionCreators: groupsActions,
  reducer: groupsReducer,
  selectors: groupsSelectors,
} = createDataReducer('groups');

export const fetchGroups = (params) => async (dispatch) => {
  dispatch(groupsActions.request());
  const res = await groupsService.getList(params);
  dispatch(groupsActions.receive(res.data));
  return res;
};

export const fetchGroupsWithoutDefault = () => (dispatch) =>
  fetchGroups({ count: 'users', with_defaults: false })(dispatch);

export const addGroup = (group) => async (dispatch) => {
  try {
    const { data } = await groupsService.create(group);
    dispatch(groupsActions.add(data));
  } catch {
    Alert.success('Falha ao adicionar um novo grupo');
  }
};

export const updateGroup = (group) => async (dispatch) => {
  try {
    const { data } = await groupsService.update(group);
    dispatch(groupsActions.alter(data));
  } catch {
    Alert.success('Falha ao alterar um grupo');
  }
};

export const removeGroup = (groupId) => async (dispatch) => {
  dispatch(groupsActions.removing(groupId));

  try {
    await groupsService.remove(groupId);
    dispatch(groupsActions.remove(groupId));
  } catch {
    Alert.success('Falha ao adicionar um novo grupo');
  }
};

export default groupsReducer;
