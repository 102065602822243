import Button from 'components/Button';
import { FormLabel } from 'components/Form';
import CondoMultiSelect from 'containers/CondoMultiSelect';
import ReferenceSearchField from 'containers/ReferenceSearchField';
import { useQuery } from 'hooks/useQuery';
import { isEmpty } from 'lib/object-helpers';
import { changeUrl, changeUrlObj } from 'lib/url-router-helpers';
import { openModalPropertySearch } from 'modules/modal';
import { networkSelector, setNetwork } from 'modules/propertySearch';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import FieldStreetAddress from 'pages/PropertiesSearch/components/FieldStreetAddress';
import qs from 'qs';
import { useMemo } from 'react';
import { MdInfo } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import FieldPersonOrulo from 'pages/PropertiesSearch/components/FieldPersonOrulo';
// import { Wrapper } from './styles';

const HasExpiryDateTitle = () => (
  <>
    Pesquisa por referência{' '}
    <span
      data-tip={`
        Você pode procurar por mais<br /> 
        de uma referência ao mesmo tempo<br /> 
        separando-as por uma vírgula. Ex: 101, 102.<br /> 
        Ou então, por uma referência exata. Ex: "101", "102".`}
      data-place="right"
      className="h-color--secondary"
    >
      <MdInfo />
    </span>
  </>
);

function FilterCharacteristics({ change }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const network = useSelector(networkSelector);
  const params = useQuery({ withParams: true });
  const { portalRealEstateId } = useParams();
  const { conditions } = usePortalOptions();

  // Limpa todos os campos da pesquisa
  const handleClearSearch = () => {
    // Pega os parametros da url
    const searchParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    // remove os filtros da url
    if (searchParams.filter) {
      delete searchParams.filter;
    }

    // remove os counts da url
    if (searchParams.counts) {
      delete searchParams.counts;
    }

    history.replace({
      pathname: history.location.pathname,
      search: qs.stringify(searchParams, { addQueryPrefix: false }),
    });

    change('reference', '');
    change('condominium_id', '');
    change('street_address', '');
  };

  const handleChangeStreetAddress = (input) => {
    changeUrlObj({
      history,
      callBack: (params) => {
        if (!params.filter) params.filter = {};

        params.filter.street_address = input.value;
      },
    });
  };

  // Lida com o evento quando pesquisa por referência
  const handleChangeReference = (input) => {
    changeUrl({
      history,
      callBack: (params) => {
        params.set('filter[reference]', input.value);
        params.set(
          'filter[doesnt_have_portal_real_estate_id]',
          portalRealEstateId
        );
      },
    });
  };

  const handleChangeCondo = (selectedCondos) => {
    changeUrlObj({
      history,
      callBack: (params) => {
        params.filter = params.filter || {};
        params.filter.condominium_id = selectedCondos;
        change('reference', '');
      },
    });
  };

  // Lida com o click pra abrir a modal de pesquisa
  const handleClickSearch = () => {
    dispatch(
      openModalPropertySearch({
        isPortals: true,
        filter: {
          ...params?.filter,

          // Adiciona esse filtro para remover todos
          // os imóveis que já estão selecionados no portal
          doesnt_have_portal_real_estate_id: params?.portalRealEstateId,
        },
        onSubmit: (values, dispatch, props) => {
          const count = props.propertiesCount;
          const modalSearch = localStorage.getItem('@tecimob/modalSearch');

          if (values.by_state_id || values.by_city_id) {
            localStorage.setItem('@tecimob/modalSearch', {
              ...modalSearch,
              by_state_id: values?.by_state_id || null,
              by_city_id: values?.by_city_id || null,
            });
          }

          const searchParams = qs.parse(history.location.search, {
            ignoreQueryPrefix: true,
          });

          const filter = qs.stringify({
            ...searchParams,
            filter: { ...values, count },
            limit: 50,
            offset: 1,
            sort: '-calculated_price',
          });

          dispatch(setNetwork('self'));

          props.history.push(`/portals/${portalRealEstateId}?${filter}`);

          props.handleClose();
        },
      })
    );
  };

  const title = useMemo(() => {
    if (conditions.isSearchingByReference) return <HasExpiryDateTitle />;
    if (conditions.isSearchingByCondo) return 'Pesquisa por condomínio';
    if (conditions.isSearchingByCharacteristics)
      return 'Pesquisa por características';
    if (conditions.isSearchingByStreetAddress) return 'Pesquisa por logradouro';

    return 'Adicionar imóvel ao portal';
  }, [
    conditions.isSearchingByCharacteristics,
    conditions.isSearchingByReference,
    conditions.isSearchingByCondo,
    conditions.isSearchingByStreetAddress,
  ]);

  // Verifica se pode mostrar o Select de condomínio
  const canShowCondo =
    network === 'orulo' ||
    (network !== 'guru' &&
      !conditions.isSearchingByReference &&
      !conditions.isSearchingByStreetAddress &&
      !conditions.isSearchingByCharacteristics);

  return (
    <div>
      <FormLabel>{title}</FormLabel>
      {conditions.canSearchByReference && (
        <ReferenceSearchField handleSearch={handleChangeReference} />
      )}
      {conditions.canShowStreetAddress && (
        <FieldStreetAddress handleSubmit={handleChangeStreetAddress} />
      )}

      {conditions.isSearchingByCharacteristics && network === 'orulo' && (
        <FieldPersonOrulo network={network} />
      )}

      {canShowCondo && (
        <CondoMultiSelect
          network={network}
          filter={{ status: 1 }}
          onChange={handleChangeCondo}
        />
      )}

      {!conditions.isSearchingByReference && (
        <>
          {!isEmpty(params.filter) ? (
            <Button
              block
              size="medium"
              color="success"
              onClick={handleClickSearch}
            >
              Filtrar resultado da pesquisa
            </Button>
          ) : (
            <Button
              size="medium"
              block
              color="success"
              onClick={handleClickSearch}
            >
              Pesquisar por características
            </Button>
          )}
        </>
      )}

      {conditions.canShowClearSearch && (
        <Button block size="medium" onClick={handleClearSearch} color="danger">
          Limpar pesquisa
        </Button>
      )}
    </div>
  );
}

export default FilterCharacteristics;
