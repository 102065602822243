import React, { useEffect, useMemo } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Select } from 'components/Form';
import { Form } from './styles';
import { useFunnel } from 'pages/Crm/hooks/useFunnel';
import { useFormUserId } from 'pages/Crm/components/FormUser';
import Button from 'components/Button';
import { MdAdd, MdSettings, MdUploadFile } from 'react-icons/md';
import { useExportFunnels } from 'pages/Crm/pages/Deals/hooks/useExportFunnels';
import { useDispatch, useSelector } from 'react-redux';
import { openModalAddDeal } from 'modules/modal';
import Can from 'containers/Can';

export const selector = formValueSelector('FilterPeople');

export const useFormFunnelId = () => {
  return useSelector((state) => selector(state, 'funnel_id'));
};

function Filter({ change, handleSubmit }) {
  const dispatch = useDispatch();
  const userId = useFormUserId();
  const funnelId = useFormFunnelId();
  const { funnels } = useFunnel({ userId });

  const handleClickAddDeal = () => {
    dispatch(
      openModalAddDeal({
        funnelId,
      })
    );
  };

  const handleChangeFunnel = (event, value) => {
    // Atualiza sempre para o ultimo funnel id selecionado
    localStorage.setItem('@tecimob/funnel_id', value);
  };

  const getFunnelId = () => {
    try {
      const selectedFunnelId = localStorage.getItem('@tecimob/funnel_id');

      const hasFunnelInList = funnels.find((funnel) => {
        return funnel.id === selectedFunnelId;
      });

      // Verifica se existe o funil do localStorage dentro da lista de funis carregados
      if (hasFunnelInList) {
        return selectedFunnelId;
      }

      const funnelsLength = funnels?.length - 1;

      return funnels?.[funnelsLength]?.id;
    } catch (err) {
      console.error(err);
      return funnels?.[0]?.id;
    }
  };

  const currentFunnelId = useMemo(() => {
    return getFunnelId();
  }, [funnels]);

  useEffect(() => {
    change('funnel_id', currentFunnelId);
  }, [currentFunnelId]);

  const { isLoading, handleClickExport } = useExportFunnels({
    userId,
    funnelId,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="funnel_id"
        labelKey="name"
        valueKey="id"
        options={funnels}
        component={Select}
        formGroupClassName="h-margin-right--15"
        placeholder=""
        onChange={handleChangeFunnel}
        className="js-funnel-select"
      />
      <Button color="secondary" size={'medium'} onClick={handleClickAddDeal}>
        <MdAdd /> Negócio
      </Button>
      <Button
        disabled={isLoading}
        size="medium"
        color="white"
        onClick={handleClickExport}
      >
        <MdUploadFile /> Exportar
      </Button>

      <Can run="EDIT_FUNNEL">
        <Button
          className="Button--small js-button-config"
          size="medium"
          to={`/crm/config`}
          color="white"
        >
          <MdSettings />
        </Button>
      </Can>
    </Form>
  );
}

export default reduxForm({
  form: 'FilterPeople',
  enableReinitialize: true,
  initialValues: {
    funnel_id: localStorage.getItem('@tecimob/funnel_id'),
  },
})(Filter);
