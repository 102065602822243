/**
 * Verifica se a string tem um caractere especial
 * @param text
 * @return {boolean}
 */
export const hasSpecialCharacter = text => {
  return /\W|_/g.test(text);
};

/**
 * Verifica se a string tem pelo menos uma letra
 * @param text
 * @return {boolean}
 */
export const hasCharacter = text => {
  return /[a-zA-Z]/g.test(text);
};

/**
 * Verifica se a string tem pelo menos um número
 * @param text
 * @return {boolean}
 */
export const hasNumber = text => {
  return /[0-9]/g.test(text);
};

/**
 * Valida a senha
 * @param passwordName
 * @param passwordConfirmName
 * @param password
 * @param passwordConfirm
 * @return {{}|{[p: string]: string}}
 */
export const validatePassword = ({
  passwordName = 'password',
  passwordConfirmName = 'password_confirm',
  password,
  passwordConfirm
}) => {
  // Verifica se a senha é fraca
  let errors = { [passwordName]: null, [passwordConfirmName]: null };

  function addError(fieldName, error) {
    errors[fieldName] =
      errors[fieldName] === null ? [error] : [...errors?.[fieldName], error];
  }

  if (password !== passwordConfirm) {
    addError(passwordName, 'Senhas diferentes');
    addError(passwordConfirmName, 'Senhas diferentes');
  }

  // Verifica se a senha Tem caracteres especiais
  if (!hasSpecialCharacter(password)) {
    addError(passwordName, 'Deve ter pelo menos um caracter especial');
  }

  // Verifica se a senha tem pelo menos um caractere maiusculo
  if (!hasCharacter(password)) {
    addError(passwordName, 'Deve possuir pelo menos uma letra');
  }

  // Verifica se a senha tem pelo menos um caractere maiusculo
  if (!hasNumber(password)) {
    addError(passwordName, 'Deve possuir pelo menos um número');
  }

  return errors;
};
