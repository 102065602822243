import React from 'react';
import { connect } from 'react-redux';
// Components
import ModalSubtitle from 'components/Modal/components/ModalSubtitle';
import Button from 'components/Button';
import Table from 'components/Table';
import DocumentsForm from './DocumentsForm';
import RequestPermission from './RequestPermission';
// Containers
import Can from 'containers/Can';
// Modules
import { getDocuments, handleFormChange, removeDocument } from 'modules/personDocuments';
// Helpers
import { plural } from 'lib/text';
// Assets
import img from 'assets/img/noData/folder.svg';
import { PERSON_PERMISSIONS } from 'constants/rules';

const propTypes = {};
const defaultProps = {};

class Documents extends React.Component {
  componentDidMount() {
    this.fetchDocuments();
  }

  get canFetchDocuments() {
    const {
      modalPerson: { person }
    } = this.props;
    return person.permissions.includes(PERSON_PERMISSIONS.DOCUMENTS);
  }

  fetchDocuments() {
    if (this.canFetchDocuments) {
      this.props.getDocuments(this.props.people_id);
    }
  }

  handleFormChange = ({ documents }) => {
    const { people_id } = this.props;
    this.props.handleFormChange({ people_id, documents });
  };

  handleRemoveDocument = id => () => {
    const { people_id } = this.props;
    this.props.removeDocument({ id, people_id });
  };

  render() {
    const {
      modalPerson: { person },
      documents,
      upload
    } = this.props;

    const hasDocuments = documents.length > 0;

    return (
      <Can
        run={PERSON_PERMISSIONS.DOCUMENTS}
        permissions={person.permissions}
        no={() => <RequestPermission person={person} />}
        yes={() => (
          <div>
            {hasDocuments && (
              <ModalSubtitle
                title={plural(documents.length, 'documento', 'documentos')}
              >
                <DocumentsForm
                  upload={upload}
                  onChange={this.handleFormChange}
                />
              </ModalSubtitle>
            )}
            <Table
              type="full"
              data={documents}
              isFetching={false}
              renderTitle={() => (
                <tr>
                  <th>Data</th>
                  <th>Nome</th>
                  <th>Enviado por</th>
                  <th width="1" />
                </tr>
              )}
              renderItem={({
                id,
                name,
                extension,
                file_url,
                user,
                created_at
              }) => (
                <tr key={`modal-documents-${id}`} className="h-hover">
                  <td>{created_at}</td>
                  <td>
                    <a
                      href={file_url}
                      download={name}
                      className="h-color--secondary h-text-overflow h-text-underline"
                      style={{ display: 'inline-block', maxWidth: 190 }}
                    >
                      {name}
                    </a>
                  </td>
                  <td>{user.name}</td>
                  <td>
                    <Button
                      className="h-hover__show"
                      color="danger"
                      onClick={this.handleRemoveDocument(id)}
                      size="small"
                    >
                      Excluir
                    </Button>
                  </td>
                </tr>
              )}
              noData={{
                title: 'Nenhum Documento encontrado.',
                text: 'Adicione o primeiro documento no botão abaixo:',
                image: img,
                children: (
                  <DocumentsForm
                    column
                    className="h-margin-top--10"
                    upload={upload}
                    onChange={this.handleFormChange}
                  />
                )
              }}
            />
          </div>
        )}
      />
    );
  }
}

Documents.propTypes = propTypes;
Documents.defaultProps = defaultProps;

const mapStateToProps = state => ({
  people_id: state.modalPerson.person.id,
  documents: state.personDocuments.documents,
  upload: state.personDocuments.upload
});

const mapDispatchToProps = {
  getDocuments,
  removeDocument,
  handleFormChange
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
