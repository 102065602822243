import { useCallback, useEffect, useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';

// Components
import { Input } from 'components/Form';
import MainTitle from 'components/MainTitle';
import Content from '../../components/Content';
import FormFixedBar from '../../components/FormFixedBar';
import SeeExample from '../../components/SeeExample';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';

// Services
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import Button from 'components/Button';
import ListOptions from 'components/ListOptions';
import UploadButton from 'components/UploadButton';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useRequest } from 'hooks/useRequest';
import { uploadFiles } from 'lib/reducer-helpers';
import { hideLoading, showLoading } from 'modules/loading';
import Reception from 'pages/EnterpriseStore/components/Reception';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import * as documentsService from 'services/buildings/documents';
import image from './image.svg';

import TableDocuments from './components/TableDocuments';

const Documents = ({ handleSubmit, initialize }) => {
  const dispatch = useDispatch();
  const { enterpriseId } = useEnterprise();
  const { data, isFetching, fetchData } = useRequest({
    request: () => documentsService.getList({ building_id: enterpriseId }),
  });

  useEffect(() => {
    initialize(data);
  }, [data]);

  const hasDocuments = useMemo(() => {
    return data?.documents?.length > 0;
  }, [data?.documents]);

  const onDropAccepted = useCallback(
    (files) => {
      // const length = data?.documents?.length
      //   ? parseInt(data?.documents?.length, 10)
      //   : 0;

      return uploadFiles({
        request: documentsService.upload,
        params: (file, index) => ({
          file,
          name: file.name,
          building_id: enterpriseId,
        }),
        files,
        onUploadStart: () => dispatch(showLoading()),
        onUploadEnd: async () => {
          fetchData();
          dispatch(hideLoading());
        },
      });
    },
    [fetchData, data]
  );

  const handleRemove = useCallback(
    async (id) => {
      dispatch(
        openConfirmation({
          title: 'Remover Documento',
          text: 'Deseja realmente excluir esse documento?',
          request: () =>
            documentsService.remove({ building_id: enterpriseId, id }),
          onSuccess: () => {
            fetchData();
          },
        })
      );
    },
    [enterpriseId]
  );

  if (!hasDocuments) {
    return (
      <Reception
        style={{ padding: '80px 50px 80px 0', justifyContent: 'space-between' }}
        styleImage={{ flex: '0', width: '247px' }}
        styleText={{ maxWidth: '322px' }}
        styleContent={{ maxWidth: '422px' }}
        image={image}
        title="Anexar Documentos"
        text="Anexe documentos relevantes deste hotsite."
      >
        <UploadButton
          onDropAccepted={onDropAccepted}
          style={{ display: 'inline-block' }}
        >
          <Button color="success">Enviar documentos</Button>
        </UploadButton>
      </Reception>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Anexar documentos"
        text="Anexe todos os documentos disponíveis do hotsite."
      >
        <UploadButton
          onDropAccepted={onDropAccepted}
          style={{ display: 'inline-block' }}
        >
          <Button color="success">Adicionar mais documentos</Button>
        </UploadButton>
        <SeeExample />
      </MainTitle>

      <Content padding isFetching={isFetching}>
        <ListOptions>
          <ListOptions.item>
            <Field
              autoFocus
              label="Título da área"
              name="documents_title"
              component={Input}
            />
          </ListOptions.item>
          <ListOptions.item>
            <TableDocuments
              data={data}
              enterpriseId={enterpriseId}
              onRemove={handleRemove}
              onEditSuccess={() => {
                fetchData();
              }}
            />
          </ListOptions.item>
        </ListOptions>
      </Content>

      <FormFixedBar />
    </form>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
    onSubmit: documentsService.updateDocuments,
  }),
  withFormConfirmation()
)(Documents);
