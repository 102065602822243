import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
// Components
import FilterField from 'components/FilterField';
import { SelectMultiple } from 'components/Form';
import * as typesService from 'services/types';
// Helpers
import { parseSelect } from 'lib/formHelpers';

class StickedOption extends React.PureComponent {
  handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  };
  handleMouseEnter = (event) => {
    this.props.onFocus(this.props.option, event);
  };
  handleMouseMove = (event) => {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  };

  get className() {
    const { option, className } = this.props;
    return classnames('help', className, {
      'Select--isGroup': option.is_type,
    });
  }

  render() {
    return (
      <div
        className={this.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.title}
      >
        {this.props.children}
      </div>
    );
  }
}

const TypeAndSubtype = ({
  onInputKeyDown,
  isFetching,
  options,
  onChange,
  onLoad,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchTypes() {
      const res = await typesService.getGroupedTypesSubtypes();
      setData(res.data);

      if (onLoad) onLoad(res.data);
    }

    if (!options) {
      fetchTypes();
    }
  }, []);

  return (
    <FilterField
      isLoading={isFetching}
      multi={false}
      label="Tipo e Subtipo"
      name="by_type_or_subtype_id"
      valueKey="id"
      labelKey="title"
      component={SelectMultiple}
      options={options || data}
      parse={parseSelect('id')}
      onChange={onChange}
      optionComponent={StickedOption}
      onInputKeyDown={onInputKeyDown}
      valueRenderer={(option) => {
        if (option?.is_subtype) {
          return (
            <div>
              {option?.type?.title}/{option.title}
            </div>
          );
        }

        return <div>{option.title}</div>;
      }}
      optionRenderer={(option) => {
        if (option.is_type) {
          return (
            <div
              style={{
                padding: 12,
                margin: '-8px -10px',
                background: '#f3f6fa',
              }}
            >
              {option.title}
            </div>
          );
        }

        return (
          <div className={option.is_subtype && 'h-margin-left--15'}>
            {option?.title}
          </div>
        );
      }}
    />
  );
};

export default TypeAndSubtype;
