// Modules
import Alert from 'react-s-alert';
import createDataReducer from 'modules/data';
import { hideLoading, showLoading } from 'modules/loading';
// Service
import * as slidesService from 'services/sites/slides';
// Helpers
import { uploadFiles } from 'lib/reducer-helpers';

export const {
  actionCreators: slidesAction,
  reducer: slidesReducer,
  selectors: slidesSelector
} = createDataReducer('site/slides');

export const fetchSlides = params => dispatch => {
  dispatch(slidesAction.request());

  return slidesService.getList(params).then(res => {
    dispatch(slidesAction.receive(res.data));
    return res;
  });
};

export const reactivateSlide = slideId => dispatch =>
  slidesService.reactivate(slideId).then(res => {
    Alert.success('Slide Reativado');
    fetchSlides()(dispatch);
    return res;
  });

export const handleDeleteSlide = ({ id }, params) => dispatch => {
  dispatch(slidesAction.removing(id));

  return slidesService.remove(id, params).then(res => {
    Alert.success('Imagem deletada', {
      customFields: {
        id,
        confirmText: 'Desfazer',
        onConfirm: () => {
          reactivateSlide(id)(dispatch);
        }
      }
    });
    dispatch(slidesAction.remove(id));
    return res;
  });
};

/**
 * Ordenação dos slides
 * @param slide
 * @param oldIndex
 * @param newIndex
 * @return {Function}
 */
export const handleSortSlide = (
  slide,
  oldIndex,
  newIndex
) => async dispatch => {
  dispatch(slidesAction.order(oldIndex, newIndex));
  await slidesService.update({ id: slide.id, order: newIndex + 1 });
};

/**
 * Lida com o formulario de upload de imagens
 * @param property_id
 * @param gallery
 * @param files
 * @return {function(*=)}
 */
export const handleUploadImages = ({ files }) => async dispatch =>
  uploadFiles({
    request: slidesService.create,
    params: file => ({ image: file, name: file.name, is_sent: true }),
    files: files,
    onUploadStart: () => dispatch(showLoading()),
    onUploadEnd: async () => {
      await fetchSlides()(dispatch);
      dispatch(hideLoading());
    }
  });

export default slidesReducer;
