import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 195px;
  flex-shrink: 0;

  background: #ffffff;
  border-right: 1px solid #edeef2;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.borderColor};
`;

export const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.borderColor};
`;
