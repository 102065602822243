import React from 'react';
import Alert from 'react-s-alert';
import { connect } from 'react-redux';
// Modules
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { disconnectGoogleCalendar } from 'modules/login';
import { authSelector, TYPES_AUTH } from 'modules/authentications';
// Components
import ListOptions from 'components/ListOptions';
import Button from 'components/Button';
// Containers
import SyncGoogleCalendar from 'containers/SyncGoogleCalendar';
// Assets
import googleCalendar from './google-calendar.png';

class AnalyticsIntegration extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {};

  get hasIntegration() {
    return this.props.hasIntegration;
  }

  handleClickDisconnect = async () => {
    const { openConfirmation, disconnectGoogleCalendar } = this.props;

    openConfirmation({
      request: () => disconnectGoogleCalendar(),
      title: 'Deseja realmente desconectar?',
      submitButtonText: 'Desconectar',
      onSuccess: () => {
        Alert.success('Agenda desconectada');
      }
    });
  };

  renderOptions = () => {
    if (this.hasIntegration) {
      return (
        <Button color="danger" onClick={this.handleClickDisconnect}>
          Desconectar
        </Button>
      );
    }

    // Redireciona para o formulário de integração com órulo
    return (
      <SyncGoogleCalendar
        render={({ onClick }) => (
          <Button onClick={onClick} color="success">
            Conectar
          </Button>
        )}
      />
    );
  };

  render() {
    const { isFetching } = this.state;

    return (
      <ListOptions.item
        isLoading={isFetching}
        image={googleCalendar}
        title="Google Agenda"
        text="Conecte seu sistema a agenda do Google e não perca mais compromissos."
        renderOptions={this.renderOptions}
      />
    );
  }
}

const mapStateToProps = state => ({
  hasIntegration: authSelector(TYPES_AUTH.GOOGLE_CALENDAR)(state)
});

const mapDispatchToProps = {
  openConfirmation,
  disconnectGoogleCalendar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsIntegration);
