import React, { useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import MainTitle from 'components/MainTitle';
import SeeExample from 'pages/EnterpriseStore/components/SeeExample';
import { reduxForm } from 'redux-form';
import { FORM_NAME } from 'pages/EnterpriseStore/constants';
import useFormValue from 'hooks/useFormValue';
import Video from 'components/Video';
import FormFixedBar from 'pages/EnterpriseStore/components/FormFixedBar';
import withReception from 'HOC/withReception';
import Reception from '../../components/Reception';
import { get, update } from 'services/buildings/video';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { useRequest } from 'hooks/useRequest';
import Content from 'pages/EnterpriseStore/components/Content';
import AddVideo from './components/AddVideo';
import receptionVideo from './reception-video.png';
import { MdDelete } from 'react-icons/md';
import { Wrapper, WrapVideo, BtnRemove } from './styles';

const VideoPage = ({ handleSubmit, initialize, array }) => {
  const { enterpriseId } = useEnterprise();
  const { data: initialValues, isFetching } = useRequest({
    request: get,
    params: enterpriseId
  });
  const videosUrl = useFormValue('videos_url');

  const hasVideo = useMemo(() => {
    return !!videosUrl && videosUrl.length > 0;
  }, [videosUrl]);

  useEffect(() => {
    initialize(initialValues);
  }, [initialValues]);

  return (
    <form onSubmit={handleSubmit}>
      {!hasVideo ? (
        <Reception
          image={receptionVideo}
          title="Vídeo"
          text="Um vídeo bem feito faz a diferença entre o cliente querer visitar um imóvel ou não."
        >
          <AddVideo />
        </Reception>
      ) : (
        <>
          <MainTitle
            title="Video"
            text="Um vídeo bem feito faz a diferença entre o cliente querer visitar um imóvel ou não."
          >
            {hasVideo && <AddVideo color="secondary" />}
            <SeeExample />
          </MainTitle>
          <Content isFetching={isFetching}>
            <Wrapper>
              <Row>
                {videosUrl?.map((url, index) => (
                  <Col xs={6}>
                    <WrapVideo>
                      <Video key={url} url={url} />
                      <BtnRemove
                        type="button"
                        onClick={() => {
                          array.remove('videos_url', index);
                        }}
                      >
                        <MdDelete />
                      </BtnRemove>
                    </WrapVideo>
                  </Col>
                ))}
              </Row>
            </Wrapper>
          </Content>
        </>
      )}

      <FormFixedBar />
    </form>
  );
};

export default compose(
  withReception('enterprise/video'),
  reduxForm({
    form: FORM_NAME,
    onSubmit: update
  })
)(VideoPage);
