import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MdStar } from 'react-icons/md';
// Styles
import { Star } from './styles';

const propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  rating: PropTypes.number,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['inline', 'block'])
};

const defaultProps = {
  onChange: null,
  type: 'inline',
  start: 0,
  end: 3,
  rating: 0,
  className: 'StarsRating h-color--caution',
  itemClassName: 'StarsRating__item'
};

const StarsRating = ({
  type,
  start,
  end,
  rating,
  className,
  itemClassName,
  onChange,
  ...rest
}) => {
  let renderedStars = [];

  for (let i = start; i < end; i += 1) {
    renderedStars = [
      ...renderedStars,
      <Star
        isChecked={rating > i}
        key={`star-${i}`}
        onClick={() => {
          if (onChange) onChange(i + 1);
        }}
        className={itemClassName}
      >
        <MdStar />
      </Star>
    ];
  }

  return (
    <div>
      <div
        {...rest}
        className={classnames(className, {
          [`StarsRating--${type}`]: type
        })}
      >
        <div className="StarsRating__wrapper">{renderedStars}</div>
      </div>
    </div>
  );
};

StarsRating.propTypes = propTypes;
StarsRating.defaultProps = defaultProps;

export default StarsRating;
