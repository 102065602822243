import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import image from './crm-image.png';
import Button from 'components/Button';
import { Field, reduxForm } from 'redux-form';
import { Checkbox } from 'components/Form';
import { KEY } from 'pages/Crm/hooks/useModalCrmReception';
import { FaYoutube } from 'react-icons/fa';

const Wrapper = styled.form`
  border-radius: 4px;
  position: relative;
  display: flex;
  background: linear-gradient(to right, #0084f4 0 27%, #fff 0 80%);
  font-family: Inter, sans-serif;
`;

const Aside = styled.div`
  flex-shrink: 0;

  h2 {
    font-size: 14px;
    margin-top: 40px;
    margin-left: 45px;
    color: #fff;
    margin-bottom: 30px;
    font-weight: 700;
  }

  img {
    margin-left: 70px;
  }
`;

const Content = styled.div`
  flex: 1 0;
  padding: 40px 35px 45px 50px;

  h2,
  h3 {
    color: #21232c;
  }

  h2 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    color: #65686f;
    line-height: 18px;
  }

  h3 {
    font-size: 12px;
    margin-top: 10px;
  }

  ul {
    margin-left: 15px;
    margin-top: 5px;
    color: #65686f;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 15px 0 -25px;

  .Button {
    font-family: Inter, sans-serif !important;
  }

  .FormGroup {
    margin-bottom: 0;
    margin-right: 15px;
    span {
      font-size: 12px;
      color: #65686f;
      font-family: Inter, sans-serif;
    }
  }
`;

function ModalCrmReception({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  handleSubmit,
}) {
  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Wrapper onSubmit={handleSubmit}>
        <Aside>
          <h2>Gestão do relacionamento</h2>
          <img src={image} alt="Crm image" />
        </Aside>
        <Content>
          <h2>Bem vindo a Fase 02 das atualizações no seu CRM!</h2>
          <p>
            Atualizamos seu CRM para melhorar a gestão dos seus negócios,
            permitindo o acesso aos dados e facilitando o relacionamento com
            seus clientes.
          </p>

          <h3>Atualizações da Fase 02</h3>
          <ul>
            <li>Possibilidade de criação de múltiplos funis de jornada</li>
            <li>
              Padronização dos funis, para que todos os corretores passem pela
              mesma jornada
            </li>
            <li>
              Nova dashboard com métricas de acompanhamento de funil e
              performance
            </li>
          </ul>
          <h3>Notas sobre a atualização</h3>
          <ul>
            <li>
              Anteriormente, cada corretor possuía apenas um funil personalizado
              para sua jornada de atendimento, permitindo uma adaptação às suas
              preferências de trabalho. Agora, introduzimos a capacidade de
              criar múltiplos funis, com estrutura compartilhada entre todos os
              corretores da equipe. Isso garante que todos sigam os mesmos
              passos e facilite a geração de relatórios com base em processos
              consistentes.
            </li>
            <li>
              Implementamos o "Funil de Atendimento" como padrão para todos os
              clientes, contendo os passos essenciais. Se você não personalizou
              seu funil anteriormente, seus negócios foram automaticamente
              migrados para este novo. Caso tenha feito personalizações, criamos
              um funil com seu nome, preservando suas etapas e negócios.
              Recomendamos editar seu funil personalizado e migrar os negócios
              para o padrão, sugerindo também remover o personalizado para
              manter o ambiente de trabalho padronizado.
            </li>
          </ul>
          <p className="h-margin-top--10">
            <a
              href="https://www.youtube.com/watch?v=uSockt14SQw"
              target="_blank"
              className="h-link"
            >
              <FaYoutube
                size={18}
                color="#DD2C28"
                className="h-margin-right--5"
                style={{ verticalAlign: 'middle' }}
              />
              Assista ao vídeo das novidades
            </a>
          </p>
          <Footer>
            <Field
              label="Não mostrar novamente"
              type="checkbox"
              name="not_show_again"
              component={Checkbox}
            />
            <Button type="submit" color="white">
              Fechar
            </Button>
          </Footer>
        </Content>
      </Wrapper>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalFormCrmReception',
  onSubmit: ({ not_show_again }, dispatch, props) => {
    if (not_show_again) {
      localStorage.setItem(KEY, true);
    }

    props.handleClose();

    return true;
  },
})(ModalCrmReception);
