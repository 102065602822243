import React from 'react';
import { Content, Footer, Wrapper } from './styles';
import Item from './components/Item';
import { useTemplateContext } from '../../contexts/TemplateContext';
import icon from './assets/icon.svg';
import { MdHistory } from 'react-icons/all';
import { useHistory } from 'react-router';
import { REDIRECT_LAYOUT_PAGE } from 'containers/ModalRoot/ModalSiteTemplate/constants';

function Aside() {
  const { categories, category, setCategory, handleClose } =
    useTemplateContext();

  const history = useHistory();

  const handleCreateModel = () => {
    handleClose();
    history.push(REDIRECT_LAYOUT_PAGE);
  };

  const handleSelectCategory = (category) => () => {
    setCategory(category);
  };

  return (
    <Wrapper>
      <Content>
        {categories.map((item) => (
          <Item
            key={item.value}
            isActive={category === item.value}
            onClick={handleSelectCategory(item.value)}
          >
            {item.label}
          </Item>
        ))}
      </Content>
      <span className="h-flex__cell--grow" />
      <Footer>
        <Item onClick={handleCreateModel}>
          <img src={icon} alt="" />
          Personalizar
        </Item>
        <Item
          isActive={category === 'HISTORY'}
          onClick={handleSelectCategory('HISTORY')}
        >
          <MdHistory size={18} />
          Histórico de versões
        </Item>
      </Footer>
    </Wrapper>
  );
}

export default Aside;
