import React from 'react';
import PropTypes from 'prop-types';
// Components
import FormGroup from '../FormGroup';
import { Input } from './styles';

const defaultProps = {
  size: 28
};

const propTypes = {
  size: PropTypes.number
};

function BigInput({
  disabled,
  required,
  input,
  meta,
  onChange,
  placeholder,
  ...anotherProps
}) {
  return (
    <FormGroup
      {...anotherProps}
      input={input}
      meta={meta}
      isRequired={required}
      isDisabled={disabled}
    >
      <Input placeholder={placeholder} size={28} {...input} />
    </FormGroup>
  );
}

BigInput.defaultProps = defaultProps;
BigInput.propTypes = propTypes;

export default BigInput;
