// Widgets
import { createSelector } from 'reselect';
import { createModule } from '../lib/reducer-helpers';
import * as userService from '../services/user';
// import * as peopleService from '../services/people';

export const usersSelector = state => state.realtorSelect.data;
export const realtorSelector = createSelector(
  usersSelector,
  users => users && users.find(user => user.isSelected)
);

const createAction = createModule('realtorSelect');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');

const SELECT = createAction('SELECT');

const initialState = {
  data: [],
  meta: {
    isFetching: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { ...state.meta, isFetching: false } };
    case RECEIVE:
      return { ...state, data: action.payload };
    case SELECT:
      return {
        ...state,
        data: state.data.map(data => {
          if (data.id === action.id) {
            return { ...data, isSelected: true };
          }

          return { ...data, isSelected: false };
        })
      };
    default:
      return state;
  }
}

// Action Creators
export function requestRealtors() {
  return { type: REQUEST };
}

export function receiveRealtors(payload) {
  return { type: RECEIVE, payload };
}

export function selectRealtor(id) {
  return { type: SELECT, id };
}

// side effects, only as applicable
// e.g. thunks, epics, etc

/**
 * Pega os seguidores de acordo com o tipo especificado
 * @param type - type
 * @param id
 * @return {function(*)}
 */
export function getRealtors(ownerId) {
  return dispatch => {
    // pega o usuario que foi enviado
    // se não pega o usuario que está logado
    const currentUserId = ownerId || localStorage.getItem('current-user').id;

    dispatch(requestRealtors());

    // Pega todos os usuários disponíveis
    return userService
      .getRealtors()
      .then(({ data }) => {
        dispatch(receiveRealtors(data));
        return data;
      })
      .then(data => {
        dispatch(selectRealtor(currentUserId));
        return data;
      });
  };
}

export function setRealtor(id) {
  return dispatch => dispatch(selectRealtor(id));
}

export function handleClickUser(user) {
  return dispatch => {
    // verifica se o cliente ja existe
    if (user.person_id) {
    }

    return dispatch(selectRealtor(user.id));
  };
}
