import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import * as propertiesService from 'services/properties';
// Components
import { FieldBool } from 'components/Form';

const defaultProps = {
  handleSubmit: null
};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

let FormApplyWatermark = ({ isOrulo, blocked, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field type="hidden" name="id" component="input" />
      {blocked ? (
        <p>Aplicando marca d'água...</p>
      ) : (
        <>
          <FieldBool
            disabled={isOrulo}
            label="Aplicar marca d'água"
            name="should_apply_watermark"
          />
          {isOrulo && `Você não pode aplicar marca d'água em imóveis do Órulo.`}
        </>
      )}
    </form>
  );
};

FormApplyWatermark.defaultProps = defaultProps;
FormApplyWatermark.propTypes = propTypes;

let timeout;
export default compose(
  reduxForm({
    form: 'FormApplyWatermark',
    onSubmit: values => {
      return propertiesService.alterProperty(values);
    },
    onChange: (values, dispatch, props, prevValues) => {
      if (_.isEmpty(prevValues)) return null;
      clearTimeout(timeout);
      timeout = setTimeout(() => props.submit(), 300);
    }
  })
)(FormApplyWatermark);
