import { withValidation } from 'lib/validate-helpers';

export default (values, ...args) => {
  const { errors, validations } = withValidation(values, ...args);

  if (values.site_realtor_option === '2' || values.site_realtor_option === 2) {
    validations.required('site_realtor_id');
  }

  if (
    values.client_realtor_option === '2' ||
    values.client_realtor_option === 2
  ) {
    validations.required('client_realtor_id');
  }

  if (
    values.is_changing_realtor === 'true' ||
    values.is_changing_realtor === true
  ) {
    validations.required(['change_realtor_id', 'change_realtor_days']);
    if (values.change_realtor_days < 1)
      errors.change_realtor_days = 'Minimo 1 dia';
  }

  return errors;
};
