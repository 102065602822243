import { ModalTemplate } from 'components/Modal';
import { useRequest } from 'hooks/useRequest';
import { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import Form from './components/Form';
import * as locationService from 'services/location';

export default function ModalZoneDelete({
  id,
  onSubmitSuccess,

  modalConfig,
  isOpen,
  modalType,
  handleClose
}) {
  const { data: zones } = useRequest({
    request: () =>
      locationService.getZones({
        count: 'properties',
        with_defaults: false,
      })
  });

  const zone = useMemo(() => {
    return zones.find(zone => {
      return zone.id === id;
    });
  }, [zones]);
  
  const options = useMemo(() => {
    return zones.filter(zone => {
      return zone.id !== id;
    });
  }, [zones]);

  const handleSubmitSuccess = useCallback((...rest) => {
    if (onSubmitSuccess) onSubmitSuccess(rest);
    handleClose();
  }, []);

  const handleSubmit = useCallback(
    ({ recipient_id }) => {
      return locationService.transferAndRemoveZone(id, recipient_id);
    },
    [id]
  );

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Excluir zona" handleClose={handleClose}>
        <Form
          zone={zone}
          options={options}
          id={id}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}
