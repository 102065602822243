import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Components
import { ModalTemplate } from 'components/Modal';
import Label from 'components/Label';
import FormSendMail from './components/FormSendMail';
// Services
import * as propertiesMatchedService from 'services/properties-matched';
import * as messagesService from 'services/settings/messages';

const propTypes = {
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

class ModalSendMail extends Component {
  state = {
    initialValues: {}
  };

  async componentDidMount() {
    this.setState({
      initialValues: await this.initialValues()
    });
  }

  handleClose = () => {
    this.props.handleClose();
  };

  renderPeople() {
    const { people } = this.props;

    if (!people) return null;

    return (
      <div className="FormGroup">
        <p className="FormLabel">Para:</p>
        <ul className="List">
          {people.map(person => (
            <li className="List__item">
              <Label color="white">{person.email}</Label>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  handleFormSubmit = async values => {
    const { matched, people, property, properties, handleClose } = this.props;

    try {
      // Verifica se
      if (people || matched) {
        return propertiesMatchedService.sendMailMatchedPeople(values);
      }

      // Se tem um imóvel significa que
      // é pra enviar pra todas as clientes que deram match
      if (property) {
        return propertiesMatchedService.sendMailMatched(property, values);
      }

      properties.forEach(currentProperty => {
        propertiesMatchedService.sendMailMatched(currentProperty, values);
      });
    } catch (err) {
    } finally {
      handleClose();
    }
  };

  handleSubmitSuccess = () => {
    const { onSubmitSuccess } = this.props;

    // Verifica se tem alguma
    // função para executar quando da certo o request do submit
    if (onSubmitSuccess) onSubmitSuccess();
  };

  async initialValues() {
    const { people, matched } = this.props;

    let initialValues = {};

    const {
      data: { matched: matchedText }
    } = await messagesService.getOne();

    initialValues = {
      ...initialValues,
      complement: matchedText
    };

    if (people) {
      initialValues = {
        ...initialValues,
        matcheds_id: _.map(people, 'matched_id')
      };
    }

    if (matched) {
      initialValues = {
        ...initialValues,
        matcheds_id: [matched.id]
      };
    }

    return initialValues;
  }

  render() {
    const { modalConfig, isOpen, modalType, properties, people } = this.props;

    const { initialValues } = this.state;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={this.handleClose}
      >
        <ModalTemplate title="Enviar por e-mail" handleClose={this.handleClose}>
          <p className="h-color--primary h-margin-bottom--15">
            <strong>Obs:</strong> Seus dados de contato e os dados do imóvel são
            preenchidos automaticamente no e-mail.
          </p>
          {this.renderPeople()}
          <FormSendMail
            initialValues={initialValues}
            onSubmit={this.handleFormSubmit}
            onSubmitSuccess={this.handleSubmitSuccess}
            handleClose={this.handleClose}
            people={people}
            properties={properties}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

ModalSendMail.propTypes = propTypes;

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSendMail);
