import React, { useMemo } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { plural } from 'lib/text';
import { useDispatch } from 'react-redux';
import { openModalFunnelStore } from 'modules/modal';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { handleRemoveFunnel } from 'modules/funnel';
import { getFunnel } from 'services/deals';

const Description = styled.div`
  font-size: 12px;
  color: #757575;
`;

function TableRow({ data }) {
  const dispatch = useDispatch();

  const handleOpenModal = (funnel) => () => {
    dispatch(
      openModalFunnelStore({
        funnel,
      })
    );
  };

  const handleRemove = (funnel) => async () => {
    const { data: funnelData } = await getFunnel(funnel.id, {
      count: 'deals,crms,default_properties',
    });

    const canRemove =
      funnelData.crms_count === 0 &&
      funnelData.deals_count === 0 &&
      funnelData.default_properties_count === 0;

    const text = canRemove ? (
      'Tem certeza que deseja remover esse funil?'
    ) : (
      <div>
        Existem pendencias na exclusão de funil
        <ul style={{ listStyle: 'none' }}>
          {funnelData.crms_count > 0 && (
            <li>
              - Este é o funil padrão para todo contato recebido e uma das
              transações do imóvel. Altere o funil padrão nas configurações
              abaixo
            </li>
          )}
          {funnelData.deals_count > 0 && (
            <li>
              - Existem <strong>{funnelData.deals_count}</strong> negócio(s)
              dentro desse funil que precisam ser migrados
            </li>
          )}
          {funnelData.default_properties_count > 0 && (
            <li>
              - Algum imóvel está usando etapa desse funil para receber leads
            </li>
          )}
        </ul>
      </div>
    );

    dispatch(
      openConfirmation({
        request: () => {
          return dispatch(handleRemoveFunnel(funnel));
        },
        title: `Remover funil: ${funnel.name}`,
        text: text,
        submitButtonText: 'Remover',
        submitButtonColor: 'danger',
        cancelButtonText: 'Cancelar',
        disableSubmitButton: !canRemove,
      })
    );
  };

  const stagnantAfter = useMemo(() => {
    if (data.clear_after === '0' || data.clear_after === 0)
      return 'No mesmo dia';
    return plural(data.clear_after, 'Dia', 'Dias');
  }, [data]);

  return (
    <tr key={data.id + data.file_url} className="Table__row--sort">
      <td>
        {data.name}
        <Description>{data.description}</Description>
      </td>
      <td>{stagnantAfter}</td>
      <td className="Table__cell--small">
        <Button to={`/crm/funnel/${data.id}`} size="medium" color="white">
          Editar
        </Button>
        <Button onClick={handleRemove(data)} size="medium" color="danger">
          Remover
        </Button>
      </td>
    </tr>
  );
}

export default TableRow;
