import Button from 'components/Button';
import Dropdown, { Item as DropItem, List } from 'components/Dropdown';
import { useCan } from 'hooks/useCan';
import moment, { format } from 'lib/moment';
import { openModalPropertyRent } from 'modules/modal';
import { MdMoreVert } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import {
  Content,
  Description,
  Header,
  Item,
  Label,
  Table,
  Title,
  Transaction,
  Value,
} from '../../styles';

function ItemRent({ deal, onCloseModalUpdate, onRemoveDeal }) {
  const dispatch = useDispatch();
  const canRemoveDeal = useCan({
    run: 'DYNAMIC_DESTROY',
    permissions: deal?.permissions,
  });

  const canEditDeal = useCan({
    run: 'DYNAMIC_EDIT',
    permissions: deal?.permissions,
  });

  const handleClickUpdate = () => {
    dispatch(
      openModalPropertyRent({
        deal: deal,
        property: deal?.property,
        person: deal?.people,
        onSubmitSuccess: onCloseModalUpdate,
      })
    );
  };

  return (
    <Item>
      <Header>
        <Title>
          Registro de <Transaction color="caution">Locação</Transaction>
        </Title>
        {(canEditDeal || canRemoveDeal) && (
          <Dropdown isDropHover>
            <Button size="medium" color="white">
              Opções
              <MdMoreVert />
            </Button>
            <List>
              {canEditDeal && (
                <DropItem>
                  <button onClick={handleClickUpdate}>Editar</button>
                </DropItem>
              )}
              {canRemoveDeal && (
                <DropItem>
                  <button onClick={onRemoveDeal(deal?.id)}>Excluir</button>
                </DropItem>
              )}
            </List>
          </Dropdown>
        )}
      </Header>
      <Content>
        <Table>
          <tr>
            <Label>Data do registro:</Label>
            <Value>
              {deal?.created_at
                ? moment(deal?.created_at, format.datetime).format(format.date)
                : '-'}
            </Value>
          </tr>
          <tr>
            <Label>Data do negócio:</Label>
            <Value>{deal?.earned_at || '-'}</Value>
          </tr>
          <tr>
            <Label>Corretor:</Label>
            <Value>{deal?.user?.name}</Value>
          </tr>
          <tr>
            <Label>Cliente:</Label>
            <Value>{deal?.people?.name}</Value>
          </tr>
          <tr>
            <Label />
            <Value />
          </tr>
          <tr>
            <Label>Data entrada</Label>
            <Value>{deal?.entry_at || '-'}</Value>
          </tr>
          <tr>
            <Label>Data saída:</Label>
            <Value>{deal?.departure_at}</Value>
          </tr>
          <tr>
            <Label>Data revisão:</Label>
            <Value>{deal?.revision_at}</Value>
          </tr>
          <tr>
            <Label />
            <Value />
          </tr>
          <tr>
            <Label>Com Garantia:</Label>
            <Value>{deal?.has_guarantee ? 'Sim' : 'Não'}</Value>
          </tr>
          <tr>
            <Label>Quantos meses?</Label>
            <Value>{deal?.guarantee_months || '-'}</Value>
          </tr>
          <tr>
            <Label>Valor garantia</Label>
            <Value>{deal?.guarantee_price || '-'}</Value>
          </tr>
        </Table>
        <Table style={{ width: '226px' }}>
          <tr>
            <Label>Valor da comissão:</Label>
            <Value price>{deal?.commission || '-'}</Value>
          </tr>
          <tr>
            <Label>IPTU recorrência:</Label>
            <Value price>
              {deal?.territorial_tax_type === 2 ? 'Mensal' : 'Anual'}
            </Value>
          </tr>
          <tr>
            <Label>Valor do IPTU:</Label>
            <Value price>{deal?.territorial_tax_price}</Value>
          </tr>
          <tr>
            <Label>Outras cobranças:</Label>
            <Value price>{deal?.taxes_note || '-'}</Value>
          </tr>
          <tr>
            <Label>Valor:</Label>
            <Value price>{deal?.taxes_price || '-'}</Value>
          </tr>
          <tr>
            <Label>Valor total mensal:</Label>
            <Value price>{deal?.price || '-'}</Value>
          </tr>
        </Table>
        <Description>
          <strong>Observação:</strong> {deal?.note || '-'}
          <br />
          <br />
          <strong>Observação sobre repasse proprietário:</strong>{' '}
          {deal?.owner_remuneration_note || '-'}
        </Description>
      </Content>
    </Item>
  );
}

export default ItemRent;
