// Modules
import createDataReducer from 'modules/data';
// Services
import * as propertiesService from 'services/sites/properties';

export const {
  selectors: propertiesSelectors,
  actionCreators: propertiesActions,
  reducer
} = createDataReducer('components/properties');

/**
 * Busca todos os modelos de cabeçalhos
 * @param params
 * @return {Promise}
 */
export const fetchProperties = params => dispatch => {
  dispatch(propertiesActions.request());

  return propertiesService.getComponents(params).then(res => {
    dispatch(propertiesActions.receive(res.data));
    return res;
  });
};

export default reducer;
