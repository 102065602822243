import moment from 'lib/moment';
import qs from 'qs';

export const peopleTotal = (user) => {
  const initialValues = qs.stringify({
    initialValues: {
      by_user_id: user?.id,
    },
  });

  return `/people/show?${initialValues}`;
};

export const propertiesTotal = (user) => {
  const initialValues = qs.stringify({
    filter: {
      user_id: user?.id,
    },
    limit: 50,
    offset: 1,
    sort: '-calculated_price',
  });

  return `/properties/search?${initialValues}`;
};

export const propertiesToReview = (user) => {
  const initialValues = qs.stringify({
    filter: {
      user_id: user?.id,
      by_next_review_at_lower_equals: moment().format('DD/MM/YYYY'),
    },
    limit: 50,
    offset: 1,
    sort: '-calculated_price',
  });

  return `/properties/search?${initialValues}`;
};

export const propertiesMatched = (user) => {
  // const initialValues = qs.stringify({
  //   initialValues: {
  //     by_matched_user_id: user?.id,
  //   },
  // });

  return `/crm/matched-properties`;
};

export const peopleMatched = (user) => {
  const initialValues = qs.stringify({ by_user_id: user?.id });

  return `/crm/matched-people?${initialValues}`;
};

export const crm = (user) => {
  const initialValues = qs.stringify({ by_user_id: user?.id });

  return `/crm/deals?${initialValues}`;
};
