import React, { useCallback, useEffect } from 'react';
import Modal from 'react-modal';

// Common Components
import { ModalTemplate } from 'components/Modal';
import Table from './components/Table';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Service
import * as propertiesService from 'services/properties';

export const ContextCompare = React.createContext({
  properties: [],
  handleRemove: null,
  isFetching: null
});

function ModalPropertyCompare({
  propertiesIds,
  handleRemove: handleRemoveProperty,
  modalConfig,
  isOpen,
  handleClose,
  modalType
}) {
  const { data, remove, isFetching } = useRequest({
    request: () => {
      return propertiesService.getList({
        filter: {
          by_id: propertiesIds
        },
        include: 'establishments',
        with_all_data: true
      });
    },
    isFetching: true
  });

  const handleRemove = useCallback(
    id => () => {
      // Callback para remover um imóvel
      if (handleRemoveProperty) handleRemoveProperty(id);

      // Remove o imóvel do state da modal
      remove(id);
    },
    [remove]
  );

  useEffect(() => {
    if (!isFetching && data.length < 2) {
      handleClose();
    }
  }, [data, isFetching]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Comparar imóveis" handleClose={handleClose}>
        <ContextCompare.Provider
          value={{ isFetching, properties: data, handleRemove }}
        >
          <Table />
        </ContextCompare.Provider>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalPropertyCompare;
