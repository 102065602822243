import React from 'react';
import PropTypes from 'prop-types';
import { MdAttachMoney } from 'react-icons/md';
import { connect } from 'react-redux';
// Components
import Button from 'components/Button';
import Panel from 'components/Panel';
import Table from 'components/Table';
// Modules
import {
  deleteIndex,
  getIndexes,
  indexesSelector,
  isFetchingSelector,
  updateIndex,
} from 'modules/financialIndex';
import { openModalFinancial } from 'modules/modal';
// Helpers
import { renderDanger } from '../../helpers';
import TextOverflow from 'components/TextOverflow';

class PanelFinancialIndex extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    data: [],
    isFetching: false,
  };

  componentDidMount() {
    this.updateIndexes();
  }

  updateIndexes() {
    this.props.getIndexes({
      sort: 'renew_date',
      filter: { by_renovations: true },
    });
  }

  /**
   * Deleta um indice financeiro
   * @param id - id do indice financeiro
   * @param name - nome do indice
   * @return {Function|Promise}
   */
  fetchDeleteIndex = (id, name) => () =>
    this.props.deleteIndex(id, name).then((res) => {
      this.updateIndexes();
      return res;
    });

  /**
   * Abre a modal de indice financeiro
   * @param id
   * @return {Function}
   */
  handleOpenModalFinancial = (id) => () => {
    const { openModalFinancial } = this.props;

    openModalFinancial(id, () => {
      this.updateIndexes();
    });
  };

  render() {
    const { data, isFetching } = this.props;

    // Verifica se tem informação
    const hasData = data.length > 0;

    return (
      <Panel
        hasData={hasData}
        title="Índices Financeiros"
        icon={MdAttachMoney}
        color="primary"
        renderNoData={() => <>Nenhum Índice Financeiro para atualizar</>}
      >
        <Table
          size="small"
          type="full"
          data={data}
          isFetching={isFetching}
          renderTitle={() => (
            <tr>
              <th>Nome</th>
              <th colSpan={2}>Atualizar em</th>
            </tr>
          )}
          renderItem={({ id, name, renew_date, is_renovation_expired }) => (
            <tr key={id} className="h-hover h-pointer">
              <td width={180}>
                <TextOverflow width={180}>{name}</TextOverflow>
              </td>
              <td className="Table__cell--small">
                {renderDanger(renew_date, is_renovation_expired)}
              </td>
              <td className="Table__cell--small">
                <Button
                  size="small"
                  color="white"
                  colorText="secondary"
                  className="h-hover__show"
                  onClick={this.handleOpenModalFinancial(id)}
                >
                  Atualizar
                </Button>
              </td>
            </tr>
          )}
        />
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  data: indexesSelector(state),
  isFetching: isFetchingSelector(state),
});

const mapDispatchToProps = {
  getIndexes,
  deleteIndex,
  updateIndex,
  openModalFinancial,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PanelFinancialIndex);
