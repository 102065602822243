import React from 'react';
// Components
import Wizard from 'components/Wizard';
// HOC
import { withWizard } from 'HOC/withWizard';

const STEPS = [
  {
    target: '.js-layout-chooser',
    title: 'Escolha o layout',
    content: 'Escolha entre uma visão de "funil" ou "listagem"',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    isFixed: true,
    placement: 'right-start'
  }
];

function WizardCrmLayout({ onClose }) {
  return <Wizard steps={STEPS} onClose={onClose} />;
}
export default withWizard('wizard/crm-layout')(WizardCrmLayout);
