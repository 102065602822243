import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Services
import * as establishmentsService from 'services/establishments';
// Components
import { ModalTemplate } from 'components/Modal';
import FormEstablishment from './components/FormEstablishment';

function ModalEstablishment({
  establishmentId,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onSubmitSuccess,
  onSubmitFail
}) {
  const [initialValues, setValues] = useState({});

  async function fetchInitialValues() {
    if (establishmentId) {
      const { data } = await establishmentsService.getOne(establishmentId);
      setValues(data);
    }
  }

  useEffect(() => {
    if (establishmentId) {
      fetchInitialValues();
    }
  }, []);

  function handleSubmitSuccess() {
    if (onSubmitSuccess) onSubmitSuccess();
    handleClose();
  }

  function handleSubmitFail() {
    if (onSubmitFail) onSubmitFail();
    handleClose();
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Estabelecimento" handleClose={handleClose}>
        <FormEstablishment
          handleClose={handleClose}
          initialValues={initialValues}
          onSubmit={establishmentsService.update}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFail}
        />
      </ModalTemplate>
    </Modal>
  );
}

ModalEstablishment.defaultProps = {
  establishmentId: null
};

ModalEstablishment.propTypes = {
  establishmentId: PropTypes.string.isRequired
};

export default ModalEstablishment;
