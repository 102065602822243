import React from 'react';
import VideoImage from 'components/VideoImage';
import { FaPlay } from 'react-icons/fa';
import GalleryItem from 'components/Gallery/components/GalleryItem';
// import { Wrapper } from './styles';

function GalleryVideo({ src }) {
  return (
    <GalleryItem className="isVideo">
      <a href={src} target="_blank" rel="noopener noreferrer">
        <VideoImage src={src} />
        <div className="Gallery__icon">
          <FaPlay />
        </div>
      </a>
    </GalleryItem>
  );
}

export default GalleryVideo;
