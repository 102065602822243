import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {
  children: null
};

const ListOptionsWrapper = ({ children }) => (
  <ul className="ListOptions__wrapper">{children}</ul>
);

ListOptionsWrapper.propTypes = propTypes;
ListOptionsWrapper.defaultProps = defaultProps;

export default ListOptionsWrapper;
