import api from 'services';

const transformResponse = res => {
  return res;
};

export const getOne = id =>
  api.getOne(`properties/partial/${id}/location`, '').then(transformResponse);

export const update = ({ id, ...data }) =>
  api.patch(`properties/partial/${id}/location`, data);
