import styled from 'styled-components';

export const Wrapper = styled.tr`
  td {
    background: #fff;
    border: 1px solid #d3dceb;
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Col = styled.div`
  padding: 20px;
`;

export const ColRight = styled(Col)`
  flex: 1 0;
  min-width: 50%;
`;

export const ColLeft = styled(Col)`
  flex-shrink: 0;
  min-width: 500px;
`;

export const Title = styled.h2`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  .Table__cell--money {
    text-align: right !important;
  }

  & + & {
    margin-top: 10px;
  }

  th,
  td {
    padding: 5px;
  }

  tbody td {
    border: 0 none;
    color: #1c0c1e;
  }

  tbody tr ~ tr td {
    border-top: 1px solid #efeff3;
  }

  thead th {
    text-align: left;
    background: #f9fbfc;
    border-top: 1px solid #efeff3;
    border-bottom: 1px solid #efeff3;

    :first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left: 1px solid #efeff3;
    }

    :last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid #efeff3;
    }
  }

  tfoot td {
    border: 0 none;
  }

  tfoot tr:first-child td {
    border-top: 1px solid #efeff3;
  }
`;
