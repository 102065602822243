import styled from 'styled-components';
import background from './background.png';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  
  display: flex;
  

  flex: 1;
  height: 100vh;
  
  background-color: #2a66c3;
  background-image: url("${background}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  
  @media all and (min-width: 1281px) {
    justify-content: center;
    align-items: center;    
  }
  
  @media all and (max-width: 1280px) {
    justify-content: center;
    align-items: center;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 420px;
  color: #fff;

  @media all and (min-width: 1025px) {
    padding-right: 20px;
  }

  @media all and (min-width: 1366px) {
    right: 10%;
  }

  @media all and (max-width: 1024px) {
    font-size: 20px;
    padding: 25px;
  }
`;

export const Title = styled.div`
  font-size: 38px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 10px;

  @media all and (max-width: 1024px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 5px;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  line-height: 30px;
`;

export const Apps = styled.div`
  display: flex;
  margin-top: 15px;

  a + a {
    margin-left: 15px;
  }
`;

export const Phone = styled.img`
  position: absolute;
  top: 60%;
  transform: translateY(-40%);
  left: 50%;

  @media all and (max-width: 1440px) {
    display: none;
  }
  `;