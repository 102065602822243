import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 5px;
  max-height: ${(p) => p.maxHeight}px;
  overflow: auto;
  border: 1px solid #000;
  background-color: #f9fafc;
  display: flex;
  flex-direction: column;

  label {
    cursor: pointer;
    width: 100%;
    padding: 3px;

    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;
