import React from 'react';
// Components
import Wizard from 'components/Wizard';
// HOC
import { withWizard } from 'HOC/withWizard';
import { useHistory } from 'react-router';

const STEPS = [
  {
    target: '.FilterAside',
    title: 'Filtro de imóveis',
    content: 'Faça uma pesquisa para selecionar os imóveis',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    isFixed: true,
    placement: 'right-start',
  },
];

function WizardPortal({ onClose }) {
  const history = useHistory();
  if (history?.location?.pathname === '/properties/search') return null;
  return <Wizard steps={STEPS} onClose={onClose} />;
}
export default withWizard('wizard/dashboard-portal')(WizardPortal);
