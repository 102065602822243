import React, { useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';
import { Input, Select } from 'components/Form';
import * as portalsService from 'services/settings/portals';
import GroupControl, { GroupItem } from 'components/GroupControl';

const validateQuantity = value => {
  if (!value) return undefined;
  return value.quantity === '' ? 'Por favor insira uma quantidade' : undefined;
};

const renderOption = (option, index) => (
  <Col key={option.id} xs={6}>
    <GroupControl label={option.name} required={false}>
      <GroupItem>
        <Field
          key={option.id}
          name={`options[${index}]`}
          format={value => {
            if (!value) return '';
            return value.quantity;
          }}
          parse={value => {
            if (!value) return { portal_option_id: option.id, quantity: '' };
            return {
              portal_option_id: option.id,
              quantity: parseInt(value, 10)
            };
          }}
          validate={validateQuantity}
          component={Input}
        />
      </GroupItem>
      <GroupItem label="Anúncio(s)" size="small" />
    </GroupControl>
  </Col>
);

function SelectPortal({ data, isLoading }) {
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);

  // Busca as opções do portal
  async function fetchOptions(e, portalId) {

    try {
      const { data } = await portalsService.getOne(portalId);

      const options = data.portal_options.map(item => ({
        portal_option_id: item.id,
        quantity: 0
      }));

      dispatch(change('FormPortalAdd', 'options', options));

      setOptions(data.portal_options);
    } catch {
      dispatch(change('FormPortalAdd', 'options', []));
      setOptions([]);
    }
  }

  return (
    <Row>
      <Field
        required
        xs={6}
        name="portal_model_id"
        label="Padrão do XML"
        valueKey="id"
        labelKey="name"
        options={data}
        isLoading={isLoading}
        onChange={fetchOptions}
        component={Select}
      />
      {options && options.map(renderOption)}
    </Row>
  );
}

export default SelectPortal;
