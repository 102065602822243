import styled from 'styled-components';
import Button from 'components/Button';

export const Wrapper = styled.div``;
export const Title = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 16px;
`;

export const Content = styled.div`
  flex-grow: 1;
`;

export const Actions = styled.div`
  display: flex;
  flex-shrink: 0;

  svg {
    font-size: 16px;
  }

  button,
  div {
    margin-left: 3px;
  }

  button {
    cursor: pointer;
  }
`;

export const List = styled.ul`
  list-style: none;

  li {
    display: flex;
    margin-bottom: 8px;
  }
`;

export const NoDataText = styled.div`
  margin-bottom: 10px;
`;

export const ButtonLink = styled(Button).attrs(() => ({
  color: 'white',
  colorText: 'secondary',
  size: 'small'
}))``;
