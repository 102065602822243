import React from 'react';
import { useSelector } from 'react-redux';
import { authSelector, TYPES_AUTH } from 'modules/authentications';

export const RdStationContext = React.createContext({
  hasIntegration: false
});

export default function RdStationProvider({ children }) {
  const hasIntegration = useSelector(authSelector(TYPES_AUTH.RD_STATION));

  return (
    <RdStationContext.Provider
      value={{
        hasIntegration
      }}
    >
      {children}
    </RdStationContext.Provider>
  );
}
