// Widgets.js
import axios from 'axios';
import { createSelector } from 'reselect';
// Helpers
import { createModule } from '../lib/reducer-helpers';

const { token, cancel } = axios.CancelToken.source();

const initialState = {
  cancelToken: token,
  cancelFunction: cancel,
  total: 0,
  success: 0,
  error: 0
};

export const uploadSelector = module => state => state[module].upload;

// Retorna
export const loadingSelector = module =>
  createSelector(uploadSelector(module), ({ total, success, error }) =>
    total ? ((success + error) * 100) / total : undefined
  );

/**
 * Retorna as ações do upload
 * @param module
 */
const createActions = module => {
  const createAction = createModule(module);

  return {
    SET_TOTAL_FILES: createAction('SET_TOTAL_FILES'),
    UPLOAD_SUCCESS: createAction('UPLOAD_SUCCESS'),
    UPLOAD_FAILED: createAction('UPLOAD_FAILED'),
    RESET_UPLOAD: createAction('RESET_UPLOAD')
  };
};

/**
 * Reducer de upload
 * @param module
 */
export default module => (state = initialState, action) => {
  const actions = createActions(module);

  switch (action.type) {
    case actions.SET_TOTAL_FILES:
      return { ...state, total: action.total, success: 0, error: 0 };
    case actions.UPLOAD_SUCCESS:
      return { ...state, success: state.success + 1 };
    case actions.UPLOAD_FAILED:
      return { ...state, error: state.error + 1 };
    case actions.RESET_UPLOAD:
      return initialState;
    default:
      return state;
  }
};

/**
 * Retorna as ações correspondentes ao upload
 * @param module
 */
export const getUploadActions = module => {
  const actions = createActions(module);

  return {
    setTotalFiles: total => ({ type: actions.SET_TOTAL_FILES, total }),
    uploadSuccess: () => ({ type: actions.UPLOAD_SUCCESS }),
    uploadFailed: () => ({ type: actions.UPLOAD_FAILED }),
    resetUpload: () => ({ type: actions.RESET_UPLOAD })
  };
};

// side effects, only as applicable
// e.g. thunks, epics, etc
