import { userAuthSelector } from 'modules/login';
import { authSelector, TYPES_AUTH } from 'modules/authentications';
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// Modules
import { openModalEvent } from 'modules/modal';
import { eventsSelectors, fetchEvents } from 'modules/events';
// Components
import Wrapper from 'components/Wrapper';
import NoContent from 'components/NoContent';
import Button from 'components/Button';
import BigCalendar from 'components/BigCalendar';
// Containers
import SyncGoogleCalendar from 'containers/SyncGoogleCalendar';
//
import { arraysEqual } from 'lib/array-helpers';
// Assets
import agenda from './agenda.png';

class Schedule extends React.Component {
  state = {
    hasIntegration: sessionStorage.getItem('google-calendar') || false,
    isLoading: true,
  };

  async componentDidMount() {
    this.props.fetchEvents();
    this.scrollToTime();
  }

  scrollToTime = () => {
    const body = document.querySelector('.MainContainer');
    body.scrollTop = 365;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { events: nextEvents } = this.props;
    const { events: prevEvents } = prevProps;

    if (!arraysEqual(nextEvents, prevEvents)) {
      this.props.fetchEvents();
    }
  }

  onSelectSlot = ({ start, end }) =>
    this.props.openModalEvent({
      start,
      end,
      onSubmitSuccess: () => {
        this.props.fetchEvents();
      },
    });
  onSelectEvent = (event) =>
    this.props.openModalEvent({
      event,
      onSubmitSuccess: () => {
        this.props.fetchEvents();
      },
    });

  render() {
    const { isAuthenticated } = this.props;
    const { hasIntegration } = this.state;

    if (!hasIntegration && !isAuthenticated) {
      return (
        <Wrapper.container>
          <NoContent
            image={agenda}
            title="Agenda"
            text="Seja lembrado sempre que um evento com um cliente estiver próximo. Sincronizando com o Google Agenda, você será avisado também no seu celular."
          >
            <SyncGoogleCalendar
              render={({ onClick }) => (
                <Button color="secondary" onClick={onClick}>
                  Sincronizar com o Google Agenda
                </Button>
              )}
            />
            <div className="h-margin-top--15">
              <span
                className="h-link"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  sessionStorage.setItem('google-calendar', true);
                  this.setState({
                    hasIntegration: true,
                  });
                }}
              >
                Usar sem sincronizar
              </span>
            </div>
          </NoContent>
        </Wrapper.container>
      );
    }

    return (
      <div style={{ background: '#fff', padding: '15px' }}>
        <Helmet>
          <title>Agenda</title>
        </Helmet>
        <BigCalendar
          defaultView="week"
          height="100%"
          events={this.props.events}
          onSelectSlot={this.onSelectSlot}
          onSelectEvent={this.onSelectEvent}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  events: eventsSelectors.getAll(state),
  auth: userAuthSelector(state),
  isAuthenticated: authSelector(TYPES_AUTH.GOOGLE_CALENDAR)(state),
});

const mapDispatchToProps = {
  openModalEvent,
  fetchEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
