// Widgets.js
import Alert from 'react-s-alert';
import { createSelector } from 'reselect';
import { createModule } from '../lib/reducer-helpers';
import * as notifications from '../services/notifications';
import alert from '../assets/unsure.mp3';
// Constants
import { NOTIFICATION_STATUS } from 'constants/constants';
// Helpers
import { item } from 'lib/reducer-helpers';
import * as notificationsService from 'services/notifications';

const sound = new Audio(alert);

export const notificationsSelector = (state) =>
  state.notifications.notifications;
export const newNotificationsSelector = createSelector(
  notificationsSelector,
  (notifications) => {
    let hasNotifications = false;

    notifications &&
      notifications.forEach((notification) => {
        if (notification.status === NOTIFICATION_STATUS.NEW) {
          hasNotifications = true;
        }
      });

    return hasNotifications;
  }
);

// Actions
const createAction = createModule('notifications');

const REQUEST_NOTIFICATIONS = createAction('REQUEST_NOTIFICATIONS');
const RECEIVE_NOTIFICATIONS = createAction('RECEIVE_NOTIFICATIONS');
const RECEIVE_NEW_NOTIFICATIONS = createAction('RECEIVE_NEW_NOTIFICATIONS');
const SET_PAGINATION = createAction('SET_PAGINATION');

const NEW_NOTIFICATION = createAction('NEW_NOTIFICATION');
const READ_NOTIFICATION = createAction('READ_NOTIFICATION');

const REMOVE_NOTIFICATION = createAction('REMOVE_NOTIFICATION');
const RECEIVE_ALL_MESSAGES = createAction('RECEIVE_ALL_MESSAGES');

const RESET_NOTIFICATIONS = createAction('RESET_NOTIFICATIONS');

// Initial State
const initialState = {
  notifications: [],
  pagination: {
    total: 0,
    per_page: 5,
    current_page: 0,
    total_pages: 0,
  },
  meta: {
    isFetching: false,
    hasHistory: false,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_NOTIFICATIONS:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, ...action.notifications],
        meta: {
          ...state.meta,
          ...action.meta,
          isFetching: false,
        },
      };
    case RECEIVE_NEW_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [...state.notifications, ...action.notifications],
        meta: {
          ...state.meta,
          hasHistory:
            action.meta.pagination.total_pages >=
            state.pagination.current_page + 1,
          isFetching: false,
        },
      };
    }

    case NEW_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.notification, ...state.notifications],
      };
    }
    case READ_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification.id === action.id) {
            return { ...notification, status: NOTIFICATION_STATUS.READED };
          }

          return notification;
        }),
      };
    }

    case RECEIVE_ALL_MESSAGES: {
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification.status === NOTIFICATION_STATUS.NEW) {
            return { ...notification, status: NOTIFICATION_STATUS.RECEIVED };
          }
          return notification;
        }),
      };
    }

    case REMOVE_NOTIFICATION: {
      return {
        ...state,
        notifications: item.remove(
          state.notifications,
          { id: action.id },
          'id'
        ),
      };
    }

    case SET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.pagination,
        },
      };
    case RESET_NOTIFICATIONS: {
      return initialState;
    }
    default:
      return state;
  }
}

// Action Creators

export const Actions = {
  requestNotifications: () => ({ type: REQUEST_NOTIFICATIONS }),
  receiveNotifications: ({ data, meta }) => ({
    type: RECEIVE_NOTIFICATIONS,
    notifications: data,
    meta,
  }),
  receiveNotification: (notification) => {
    sound.pause();
    sound.currentTime = 0;
    sound.play();
    return { type: NEW_NOTIFICATION, notification };
  },
  readNotification: (notificationId) => ({
    type: READ_NOTIFICATION,
    id: notificationId,
  }),
  resetNotifications: () => ({ type: RESET_NOTIFICATIONS }),
  removeNotification: (notificationId) => ({
    type: REMOVE_NOTIFICATION,
    id: notificationId,
  }),
  receiveAllNotifications: () => ({ type: RECEIVE_ALL_MESSAGES }),
};

// side effects, only as applicable
// e.g. thunks, epics, etc
export function addNotification(notification) {
  return (dispatch) => {
    sound.pause();
    sound.currentTime = 0;
    sound.play();

    dispatch(Actions.receiveNotification(notification));
  };
}

export const removeNotification = (notificationId) => async (dispatch) => {
  dispatch(Actions.removeNotification(notificationId));
  await notifications.remove(notificationId);
};

export const readNotification = (notificationId) => async (dispatch) => {
  dispatch(Actions.readNotification(notificationId));
  await notifications.update({
    id: notificationId,
  });
};

const getParams = (filter) => {
  let params = {};

  if (filter === 'new-feature') {
    filter = ['new-feature', 'others'];
  }

  if (filter) {
    params = {
      tag: filter,
    };
  }

  return params;
};

export function fetchNotifications(params) {
  return async (dispatch) => {
    dispatch(Actions.requestNotifications());

    // Busca todas as notificacoes novas
    return notifications
      .getList(params)
      .then((res) => {
        // recebe as novas notificacoes
        dispatch(Actions.receiveNotifications(res));

        return res;
      })
      .catch((e) => {
        // marca que n recebeu notificacao nenhuma para não ficar loading na tela
        dispatch(Actions.resetNotifications());

        // avisa o usuario que deu algum problema no servidor
        Alert.success('não foi possivel buscar as notificações');

        return e;
      });
  };
}

export const receiveAllNotifications = (filter) => async (dispatch) => {
  dispatch(Actions.receiveAllNotifications());
  await notifications.receiveAll(getParams(filter));
};

export const clearAllNotifications = (filter) => async (dispatch) => {
  // Limpa todas as notificações
  notificationsService.clearAll(getParams(filter));

  // Limpa todas as notificações da store
  dispatch(Actions.resetNotifications());
};

export function filterNotifications(params) {
  return async (dispatch) => {
    dispatch(Actions.resetNotifications());
    dispatch(fetchNotifications(params));
  };
}
