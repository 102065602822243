import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
// Components
import { Checkbox, SelectMultiple } from 'components/Form';
import Alert from 'components/Alert';
// Helpers
import { parseSelect } from 'lib/formHelpers';
// Assets
import guru from 'assets/img/logo-guru.png';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

//const validateId = index => (value, allValue) => {
//  if (allValue.portal_real_estate_options && !value) {
//    const { isChecked } = allValue.portal_real_estate_options[index];
//
//    if (isChecked && !value) {
//      return 'Campo obrigatório';
//    }
//  }
//
//  return undefined;
//};

const getFieldByIndex = (fields, index) => {
  try {
    return fields[index];
  } catch {
    return null;
  }
};

const propertyHasDangerPrice = (property, zap) => {
  let errors = [];

  if (!property.is_price_shown) {
    errors = [
      ...errors,
      'O imóvel está marcado para não mostrar preço no site'
    ];
  }

  if (property.is_season_available && zap) {
    errors = [...errors, 'Zap Imóveis não aceita imóveis de temporada'];
  }

  return errors;
};

const FormPortal = ({
  property,
  is_published,
  handleClose,
  fieldOptions,
  array: { remove },
  portals,
  change
}) => {
  const zap = useMemo(() => {
    return _.find(portals, ({ id, portal }) => {
      try {
        if (portal.name === 'Zap Imóveis') {
          return fieldOptions[id].is_checked;
        }

        return false;
      } catch {
        return false;
      }
    });
  }, [fieldOptions, portals]);

  const dangers = useMemo(() => {
    return propertyHasDangerPrice(property, zap);
  }, [property, portals, zap]);

  if (!is_published) {
    return (
      <Alert color="danger">
        Este imóvel esta cadastrado como privado, pra não ser publicado em seu
        site.
        <br /> Imóveis privados não podem ser encaminhados para portais
        imobiliários
      </Alert>
    );
  }

  return (
    <>
      <Alert color="secondary" className="h-margin-bottom--15">
        Deseja integrar com um portal que não está listado abaixo?{' '}
        <Link
          to="/config/portals/show"
          target="_blank"
          className="h-color--secondary h-text-underline"
        >
          Clique aqui
        </Link>{' '}
        para ver mais.
      </Alert>

      {dangers.length > 0 && (
        <Alert color="danger" className="h-margin-bottom--15">
          {dangers.map((msg, index) => (
            <div key={`msg-error-${index}`}>{msg}</div>
          ))}
        </Alert>
      )}

      <table
        className="Table Table--default"
        style={{
          width: 'calc(100% + 39px)',
          marginLeft: '-19px'
        }}
      >
        <tbody>
          <tr>
            <td>
              <div className="h-flex h-flex--center-center">
                <Field
                  type="checkbox"
                  name="is_network_published"
                  component={Checkbox}
                  className="h-margin-right--5"
                  onChange={(e, value) => {
                    if (value) {
                      change('is_published', true);
                    }
                  }}
                />
                <img src={guru} alt="Guru" style={{ height: '33px' }} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        className="Table"
        style={{ width: 'calc(100% + 39px)', marginLeft: '-19px' }}
      >
        <tbody>
          {portals.map(({ options, id, portal: { name, file_url } }) => {
            const field = getFieldByIndex(fieldOptions, id);

            return (
              <tr key={id}>
                <td>
                  <div className="h-flex h-flex--center-center">
                    <Field
                      type="checkbox"
                      name={`portal_real_estate_options.${id}.is_checked`}
                      component={Checkbox}
                      className="h-margin-right--5"
                    />
                    {file_url ? (
                      <img
                        src={file_url}
                        alt={name}
                        style={{ height: '33px' }}
                      />
                    ) : (
                      name
                    )}
                  </div>
                </td>
                <td
                  className="Table__cell--small"
                  style={{ verticalAlign: 'middle' }}
                >
                  {field && field.is_checked && (
                    <Field
                      multi={false}
                      name={`portal_real_estate_options.${id}.id`}
                      component={SelectMultiple}
                      options={options}
                      labelKey="name"
                      valueKey="id"
                      style={{ width: '200px' }}
                      parse={parseSelect('id')}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

FormPortal.defaultProps = defaultProps;
FormPortal.propTypes = propTypes;

const selector = formValueSelector('PublicationWizard');

const mapStateToProps = state => ({
  fieldOptions: selector(state, 'portal_real_estate_options'),
  is_published: selector(state, 'is_published')
});

export default connect(mapStateToProps)(FormPortal);
