import React from 'react';
import { Table, Title } from '../../styles';

function TableServices({ contract }) {
  return (
    <>
      <Title>Serviços contratados</Title>
      <Table>
        <thead>
          <tr>
            <th>Itens</th>
            <th>Qtd</th>
            <th className="Table__cell--money">Preço</th>
            <th className="Table__cell--money">Total</th>
          </tr>
        </thead>
        <tbody>
          {contract?.items?.list?.map((item) => (
            <tr key={item.item}>
              <td>{item.item}</td>
              <td>{item?.quantity?.toString()?.padStart('2', '0')}</td>
              <td className="Table__cell--money">{item.unity_price}</td>
              <td className="Table__cell--money">{item.total_price}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Table>
        <tfoot>
          {contract?.values?.monthly_price && (
            <tr>
              <td colSpan={2} />
              <td width={130}>
                <strong>Total mensal</strong>
              </td>
              <td width={90} className="Table__cell--money h-text-nowrap">
                <strong>{contract?.values?.monthly_price}</strong>
              </td>
            </tr>
          )}
          {contract?.values?.total_price && (
            <tr>
              <td colSpan={2} />
              <td width={130}>
                <strong>Total {contract?.plan?.toLowerCase?.()}</strong>
              </td>
              <td width={90} className="Table__cell--money h-text-nowrap">
                <strong>{contract?.values?.total_price}</strong>
              </td>
            </tr>
          )}
          {!!contract?.values?.discount_price &&
            contract?.values?.discount_price !== 'R$ 0,00' && (
              <tr>
                <td colSpan={2} />
                <td width={130}>
                  Desconto de {contract?.values?.discount_percent}
                </td>
                <td width={90} className="Table__cell--money h-text-nowrap">
                  {contract?.values?.discount_price}
                </td>
              </tr>
            )}
          {contract?.type !== 'Modelo anterior' &&
            contract?.values?.final_price && (
              <tr>
                <td colSpan={2} />
                <td width={130}>
                  <strong>Total</strong>
                </td>
                <td width={90} className="Table__cell--money h-text-nowrap">
                  <strong>{contract?.values?.final_price}</strong>
                </td>
              </tr>
            )}
        </tfoot>
      </Table>
    </>
  );
}

export default TableServices;
