import api from 'services';
import { responseMessage } from 'lib/service-helpers';

export const getNotificationResponse = (data) => {
  if (data.notification_email_active && data.notification_whatsapp_active) {
    return 3;
  }

  if (data.notification_email_active) {
    return 2;
  }

  return 1;
};

export const getOne = (params) =>
  api.getList('settings/sites/contact-forms', params).then((res) => {
    res.data = {
      ...res.data,
      notification_response: getNotificationResponse(res.data),
    };

    return res;
  });

const transformValues = (values) => {
  if (values.notification_response === 3) {
    return {
      ...values,
      notification_whatsapp_active: true,
      notification_email_active: true,
    };
  }

  if (values.notification_response === 2) {
    return {
      ...values,
      notification_email_active: true,
      notification_whatsapp_active: false,
    };
  }

  return {
    ...values,
    notification_whatsapp_active: true,
    notification_email_active: false,
  };
};

/**
 * Update contact forms
 * @param values
 * @return {*}
 */
export const update = (values) =>
  api
    .update('settings/sites/contact-forms', transformValues(values))
    .then(responseMessage('Salvo!'));

/**
 * Retorna o modelo de rodape
 * @param params
 * @return {Promise}
 */
export const getComponent = (params) =>
  api.getOne('settings/component-examples/contact-forms', '', params);
