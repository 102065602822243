import React from 'react';
import { Row as RowGrid } from 'react-flexbox-grid';
import classnames from 'classnames';

const propTypes = {};
const defaultProps = {};

const Row = ({ children, className, ...rest }) => (
  <RowGrid
    start="xs"
    {...rest}
    className={classnames('ListInfo__row', className)}
  >
    {children}
  </RowGrid>
);

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
