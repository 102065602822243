import React from 'react';
import PersonItem from 'components/PersonItem';
import SelectOption from 'components/SelectOption';

function PersonOption({ option, ...props }) {
  const currentUser = localStorage.getItem('current-user');

  return (
    <SelectOption {...props} option={option}>
      <PersonItem person={option} currentUser={currentUser} />
    </SelectOption>
  );
}

export default PersonOption;
