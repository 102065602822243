import _ from 'lodash';
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
// Widgets.js
import { createModule } from '../lib/reducer-helpers';
import { getPagination, setPagination } from './pagination';
// Service
import * as propertiesService from 'services/properties';
import * as bookingService from 'services/propertiesBooking';
// Modules
import { Actions as FilterActions } from 'pages/Properties/Filter/module';

const createAction = createModule('properties');

// seleciona todos os imóveis
export const propertiesSelector = (state) => state.properties.data;
export const propertiesSelecteds = (state) => {
  try {
    return state.selecteds.properties;
  } catch {
    return null;
  }
};
export const isNetworkSelector = (state) => state.properties.meta.isNetwork;
export const propertiesIsFetchingSelector = (state) =>
  state.properties.meta.isFetching;

export const getPropertiesSelecteds = (allPropertiesSelector) =>
  createSelector(
    [allPropertiesSelector, propertiesSelecteds],
    (properties, selecteds) => {
      if (!properties || !selecteds) return properties;

      return properties.map((property) => {
        if (selecteds.indexOf(property.id) !== -1) {
          return { ...property, isChecked: true };
        }
        return property;
      });
    }
  );

export const getPropertySelecteds = getPropertiesSelecteds(propertiesSelector);

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const REMOVE = createAction('REMOVE');
const TOGGLE_FEATURED = createAction('TOOGLE_FEATURED');

const SET_NETWORK = createAction('SET_NETWORK');
const UNSET_NETWORK = createAction('UNSET_NETWORK');

const SET_PROPERTY_LOADING = createAction('SET_PROPERTY_LOADING');
const UNSET_PROPERTY_LOADING = createAction('UNSET_PROPERTY_LOADING');
const RESET = createAction('RESET');

// Initial State
const initialState = {
  data: [],
  meta: {
    isFetching: false,
    isNetwork: false,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          isFetching: true,
        },
      };
    case RECEIVE:
      return {
        ...state,
        data: action.properties.map((property) => ({
          ...property,
          isLoading: false,
        })),
        meta: {
          ...state.meta,
          isFetching: false,
        },
      };
    case REMOVE:
      return {
        ...state,
        data: state.data.filter((property) => property.id !== action.id),
      };
    case TOGGLE_FEATURED:
      return {
        ...state,
        data: state.data.map((property) => {
          if (property.id === action.id) {
            return { ...property, is_featured: !property.is_featured };
          }
          return property;
        }),
      };

    case SET_NETWORK:
      return {
        ...state,
        meta: { ...state.meta, isNetwork: true },
      };

    case UNSET_NETWORK:
      return {
        ...state,
        meta: { ...state.meta, isNetwork: false },
      };

    case SET_PROPERTY_LOADING:
      return {
        ...state,
        data: state.data.map((property) =>
          property.id === action.id
            ? { ...property, isLoading: true }
            : property
        ),
      };

    case UNSET_PROPERTY_LOADING:
      return {
        ...state,
        data: state.data.map((property) =>
          property.id === action.id
            ? { ...property, isLoading: false }
            : property
        ),
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
}

// Action Creators
export const requestProperties = () => ({ type: REQUEST });
export const receiveProperties = (properties) => ({
  type: RECEIVE,
  properties,
});
export const toggleFeaturedProperty = (id) => ({ type: TOGGLE_FEATURED, id });
export const removeProperty = (id) => ({ type: REMOVE, id });

export const setNetwork = () => ({ type: SET_NETWORK });
export const unsetNetwork = () => ({ type: UNSET_NETWORK });

export const setPropertyLoading = (property) => ({
  type: SET_PROPERTY_LOADING,
  id: property.id,
});
export const unsetPropertyLoading = (property) => ({
  type: UNSET_PROPERTY_LOADING,
  id: property.id,
});

export const resetProperties = () => ({ type: RESET });

export const fetchToggleFeatured = (property) => (dispatch) => {
  dispatch(setPropertyLoading(property));
  return propertiesService.fetchToggleFeatured(property).then((res) => {
    dispatch(unsetPropertyLoading(property));
    dispatch(toggleFeaturedProperty(property.id));
    return res;
  });
};

/**
 * Verifica se pode setar o modo de listagem para network
 * @param filter
 * @param dispatch
 * @return {null}
 */
const checkNetwork = (params, dispatch) => {
  dispatch(unsetNetwork());

  // verifica se tem a chave on_network dentro de params
  if (!_.hasIn(params, 'filter.on_network')) return;

  // se for orulo não pode aparecer gerencia de parceiros
  if (_.includes(params.filter.on_network, ['orulo'])) return;

  // checa o modo de layout para network
  dispatch(setNetwork());
};

export const cleanProperties = () => (dispatch) => {
  dispatch(receiveProperties([]));
  dispatch(FilterActions.searchEnd());
};

export const getPropertiesPortal =
  (params, portalRealEstateId) => async (dispatch, getState) => {
    const state = getState();
    const values = getFormValues('FilterPropertiesPortal')(state);
    const { sort } = getFormValues('FilterSortProperties')(state);

    delete params?.filter?.sort;
    delete values?.sort;

    // Marca que está buscando os imóveis
    dispatch(requestProperties());

    // Pega a paginação que está criada
    const pagination = getPagination(state, 'properties-portal');

    try {
      const newParams = {
        ...params,
        with_grouped_condos: '',
        include: 'portal_real_estate_options,condominium',
        filter: {
          ...values,
          ...params?.filter,
          by_available_for_portals: true,
        },
        is_portals_filter: true,
        ...pagination,
        offset:
          params?.offset || pagination?.current_page || pagination?.offset,
        sort: sort || 'calculated_price,by_type_name,id',
      };

      newParams.filter = JSON.parse(JSON.stringify(newParams.filter));

      if (newParams.filter?.by_portal_option_id === 'is_portal') {
        newParams.filter.by_portal_option_id = null;
        newParams.filter.doesnt_have_portal_real_estate_id = portalRealEstateId;
      }

      const { data: properties, meta } =
        await propertiesService.getListWithCondos(newParams);

      // Recebe todas as propriedades
      dispatch(receiveProperties(properties));

      if (meta && meta.pagination) {
        dispatch(setPagination('properties-portal', meta.pagination));
      }

      return properties;
    } catch {
      dispatch(receiveProperties([]));
    }
  };

// side effects, only as applicable
// e.g. thunks, epics, etc
export function getProperties(
  params,
  paginationName = 'properties',
  filterName
) {
  return async (dispatch, getState) => {
    const state = getState();
    const values = getFormValues('FilterProperties')(state);

    delete values?.by_floor_comparision;

    // Marca que está buscando os imóveis
    dispatch(requestProperties());

    // Pega a paginação que está criada
    const pagination = getPagination(state, paginationName);

    // Checa se tem imóveis
    checkNetwork(params, dispatch);

    try {
      const newParams = {
        filter: { ...values },
        ...pagination,
        ...params,
      };

      const { data: properties, meta } =
        await propertiesService.getListWithCondos(newParams);

      // Recebe todas as propriedades
      dispatch(receiveProperties(properties));

      if (meta && meta.pagination) {
        dispatch(setPagination(paginationName, meta.pagination));
      }

      return properties;
    } catch {
      dispatch(receiveProperties([]));
    }
  };
}

/**
 * Busca por imóveis
 * @param params - parametros da requisição
 * @param paginationKey - chave da paginação
 * @returns {function(*, *): Promise<T | never>}
 */
export function getPropertiesWithPagination(params, paginationKey) {
  return (dispatch) => {
    dispatch(requestProperties());

    return propertiesService
      .getListWithCondos(params)
      .then((res) => {
        // Recebe todas as propriedades
        dispatch(receiveProperties(res.data));

        if (res.meta && res.meta.pagination) {
          dispatch(setPagination(paginationKey, res.meta.pagination));
        }

        return res;
      })
      .catch((err) => {
        dispatch(receiveProperties([]));
      });
  };
}

export function getPropertiesBooked(params, paginationKey) {
  return (dispatch) => {
    dispatch(requestProperties());

    return bookingService
      .getListBookedOld(params)
      .then((res) => {
        // Recebe todas as propriedades
        dispatch(receiveProperties(res.data));

        if (res.meta && res.meta.pagination) {
          dispatch(setPagination(paginationKey, res.meta.pagination));
        }

        return res;
      })
      .catch((err) => {
        dispatch(receiveProperties([]));
      });
  };
}
