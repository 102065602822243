import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Toggle } from 'components/Form';
import Button from 'components/Button';
import api from 'services';
import { setRealEstate } from 'modules/login';

// import { Wrapper } from './styles';

function FormRecurringCharge({ handleSubmit, initialize, handleClose }) {
  useEffect(() => {
    initialize({
      recurring_charge:
        localStorage.getItem('current-user').real_estate?.recurring_charge,
    });
  }, [initialize]);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        label="Cobrança automática?"
        name="recurring_charge"
        component={Toggle}
      />
      <div className="h-flex">
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'FormRecurringCharge',
  onSubmit: async (values, dispatch, props) => {
    const response = await api.create('sales/charge', {
      recurring_charge: values.recurring_charge,
    });

    dispatch(
      setRealEstate({
        recurring_charge: values.recurring_charge,
      })
    );

    return response;
  },
})(FormRecurringCharge);
