import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { onDropRejected } from 'lib/file-helpers';
import { useDispatch } from 'react-redux';
import * as S from './styles';
import Button from 'components/Button';
import { MdUpload } from 'react-icons/all';
import { ThemeProvider } from 'styled-components';
import { MdAttachment } from 'react-icons/md';
import { MdClose } from 'react-icons/md/index';

function FileComponent({
  title,
  text,
  name,
  input,
  multiple,
  onDropReject,
  uploadButtonText,
  ...dropzoneProps
}) {
  const dispatch = useDispatch();

  // Verifica se tem arquivo enviado
  const hasFileUploaded = !!input?.value?.name;

  const handleDropFile = (filesToUpload) => {
    if (!filesToUpload) return;

    if (multiple) {
      return input.onChange(filesToUpload);
    }

    input.onChange(filesToUpload[0]);
  };

  const handleRemoveFile = (e) => {
    e.stopPropagation();
    input.onChange(null);
  };

  return (
    <ThemeProvider
      theme={{
        hasFileUploaded: hasFileUploaded,
      }}
    >
      <S.Wrapper>
        <Dropzone
          {...dropzoneProps}
          className="Dropzone2"
          onDrop={handleDropFile}
          onDropRejected={onDropRejected(onDropReject, dispatch)}
        >
          <S.Title>{title}</S.Title>
          {text ? <S.Text>{text}</S.Text> : null}
          {input?.value?.name ? (
            <S.UploadedFile>
              <MdAttachment size={18} color="#31D084" /> {input?.value?.name}
              <S.ButtonClose onClick={handleRemoveFile}>
                <MdClose size={18} />
              </S.ButtonClose>
            </S.UploadedFile>
          ) : (
            <Button color="white" size="medium" className="Button__dropzone">
              <MdUpload
                size={18}
                color="#31D084"
                className="h-margin-right--5"
              />
              {uploadButtonText}
            </Button>
          )}
        </Dropzone>
      </S.Wrapper>
    </ThemeProvider>
  );
}

function FieldFile2({
  name,
  title,
  text,
  maxFiles,
  onDropReject,
  uploadButtonText = 'Enviar arquivo',
}) {
  return (
    <Field
      title={title}
      text={text}
      name={name}
      maxFiles={maxFiles}
      onDropReject={onDropReject}
      uploadButtonText={uploadButtonText}
      component={FileComponent}
    />
  );
}

FieldFile2.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  maxFiles: PropTypes.number,
  onDropRejected: PropTypes.func,
  uploadButtonText: PropTypes.string,
};

export default FieldFile2;
