import { useRequest } from 'hooks/useRequest';
import * as openaiService from 'services/openai';

export const useChatGptCredits = () => {
  const { data, fetchData, isFetching } = useRequest({
    request: openaiService.getCredits,
  });

  return { data, fetchData, isFetching };
};
