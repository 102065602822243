export const PERSON_PERMISSIONS = {
  DOCUMENTS: 'DYNAMIC_DOCUMENTS',
  SEARCH_PROFILES: 'DYNAMIC_SEARCH_PROFILES',
  PRIVATE: 'DYNAMIC_PRIVATE',
  EDIT: 'DYNAMIC_EDIT',
  NOTES: 'DYNAMIC_NOTES',
  CALENDAR: 'DYNAMIC_CALENDAR',
  PROPERTY: 'DYNAMIC_PROPERTY',
  DISCARD_MATCHED: 'DYNAMIC_DISCARD_MATCHED',
  DEAL: 'DYNAMIC_DEAL',
  DISCARD_INTERESTED: 'DYNAMIC_DISCARD_INTERESTED',
  CHANGE_REALTOR: 'DYNAMIC_CHANGE_REALTOR',
  DELETE: 'DYNAMIC_DELETE'
};

export const PROPERTY_PERMISSIONS = {
  ASSOCIATE_TO_OTHER: 'DYNAMIC_ASSOCIATE_TO_OTHER',
  EDIT: 'DYNAMIC_EDIT',
  PUBLISH: 'DYNAMIC_PUBLISH',
  DELETE: 'DYNAMIC_DELETE',
  DOCUMENTS: 'DYNAMIC_DOCUMENTS',
  PRIVATE: 'DYNAMIC_PRIVATE',
  RENT: 'DYNAMIC_RENT',
  SELL: 'DYNAMIC_SELL',
  TRASH: 'DYNAMIC_TRASH',
  REACTIVE: 'DYNAMIC_REACTIVE'
};

export const USER_PERMISSIONS = {
  REGISTER_PROPERTY: 'REGISTER_PROPERTY',
  ASSOCIATE_PROPERTY_TO_OTHER: 'ASSOCIATE_PROPERTY_TO_OTHER',
  PUBLISH_PROPERTY: 'PUBLISH_PROPERTY',
  DELETE_PROPERTY: 'DELETE_PROPERTY',
  OTHER_PROPERTY_DOCUMENTS: 'OTHER_PROPERTY_DOCUMENTS',
  OTHER_PROPERTY_PRIVATE_DATA: 'OTHER_PROPERTY_PRIVATE_DATA',
  EDIT_OTHER_PROPERTY: 'EDIT_OTHER_PROPERTY',
  PUBLISH_OTHER_PROPERTY: 'PUBLISH_OTHER_PROPERTY',
  RENT_OTHER_PROPERTY: 'RENT_OTHER_PROPERTY',
  SELL_OTHER_PROPERTY: 'SELL_OTHER_PROPERTY',
  TRASH_OTHER_PROPERTY: 'TRASH_OTHER_PROPERTY',
  OTHER_REVIEW_PROPERTY: 'OTHER_REVIEW_PROPERTY',
  OTHER_RENTED_PROPERTY: 'OTHER_RENTED_PROPERTY',
  EDIT_PEOPLE_GROUPS: 'EDIT_PEOPLE_GROUPS',
  REACTIVE_OTHER_RENTED_PROPERTY: 'REACTIVE_OTHER_RENTED_PROPERTY',
  OTHER_SOLD_PROPERTY: 'OTHER_SOLD_PROPERTY',
  REACTIVE_OTHER_SOLD_PROPERTY: 'REACTIVE_OTHER_SOLD_PROPERTY',
  OTHER_TRASHED_PROPERTY: 'OTHER_TRASHED_PROPERTY',
  REACTIVE_OTHER_TRASHED_PROPERTY: 'REACTIVE_OTHER_TRASHED_PROPERTY',
  DELETE_OTHER_PROPERTY: 'DELETE_OTHER_PROPERTY',

  // REGISTRATIONS
  EDIT_REGISTRATIONS: 'EDIT_REGISTRATIONS',
  EDIT_ESTABLISHMENTS: 'EDIT_ESTABLISHMENTS',
  EDIT_CHARACTERISTICS: 'EDIT_CHARACTERISTICS',
  EDIT_RECEPTION_SOURCES: 'EDIT_RECEPTION_SOURCES',
  EDIT_LOSS_REASONS: 'EDIT_LOSS_REASONS',
  EDIT_CONDOS: 'EDIT_CONDOS',
  EDIT_ADDRESSES: 'EDIT_ADDRESSES',

  // CRM
  VIEW_OTHER_DEAL: 'VIEW_OTHER_DEAL',

  // PERSON
  OTHER_PERSON_PROPERTY: 'OTHER_PERSON_PROPERTY',
  DELETE_PERSON: 'DELETE_PERSON',
  OTHER_PERSON_DOCUMENTS: 'OTHER_PERSON_DOCUMENTS',
  OTHER_PERSON_SEARCH_PROFILES: 'OTHER_PERSON_SEARCH_PROFILES',
  OTHER_PERSON_DATA: 'OTHER_PERSON_DATA',
  EDIT_OTHER_PERSON: 'EDIT_OTHER_PERSON',
  OTHER_PERSON_NOTES: 'OTHER_PERSON_NOTES',
  OTHER_PERSON_CALENDAR: 'OTHER_PERSON_CALENDAR',
  OTHER_PERSON_DISCARD_MATCHED: 'OTHER_PERSON_DISCARD_MATCHED',
  OTHER_PERSON_DEAL: 'OTHER_PERSON_DEAL',
  OTHER_PERSON_DISCARD_INTERESTED: 'OTHER_PERSON_DISCARD_INTERESTED',
  OTHER_PERSON_CHANGE_REALTOR: 'OTHER_PERSON_CHANGE_REALTOR',
  DELETE_OTHER_PERSON: 'DELETE_OTHER_PERSON',

  // Dashboards
  VIEW_MAIN_DASHBOARD: 'VIEW_MAIN_DASHBOARD',

  // REAL ESTATE
  EDIT_REAL_ESTATE: 'EDIT_REAL_ESTATE',
  REAL_ESTATE_FINANCIAL: 'REAL_ESTATE_FINANCIAL',

  EDIT_MESSAGE: 'EDIT_MESSAGE',
  EDIT_USER: 'EDIT_USER',
  EDIT_FINANCIAL_INDEX: 'EDIT_FINANCIAL_INDEX',
  EDIT_WATERMARK: 'EDIT_WATERMARK',
  EDIT_PORTAL: 'EDIT_PORTAL',
  EDIT_BACKUP: 'VIEW_BACKUPS',
  EDIT_INTEGRATION: 'EDIT_INTEGRATION',
  EDIT_DOMAIN: 'EDIT_DOMAIN',
  EDIT_GROUP: 'EDIT_GROUP',
  EDIT_SITE: 'EDIT_SITE',
  CHANGE_CURRENT_USER: 'CHANGE_CURRENT_USER',
  VIEW_REPORTS: 'VIEW_REPORTS',
  EDIT_EMAIL: 'EDIT_EMAIL',
  EDIT_FUNNEL: 'EDIT_FUNNEL'
};

export const myself = {
  properties: {
    register: 'REGISTER_PROPERTY',
    associateToOther: 'ASSOCIATE_PROPERTY_TO_OTHER',
    publish: 'PUBLISH_PROPERTY',
    delete: 'DELETE_PROPERTY'
  },
  people: {
    delete: 'DELETE_PERSON'
  },
  users: {
    editUserGroup: 'EDIT_USER_GROUP',
    editGroup: 'EDIT_GROUP'
  }
};

export const others = {
  properties: {
    edit: 'EDIT_OTHER_PROPERTY',
    listDocuments: 'OTHER_PROPERTY_DOCUMENTS',
    listPrivateData: 'OTHER_PROPERTY_PRIVATE_DATA',
    publish: 'PUBLISH_OTHER_PROPERTY',
    rent: 'RENT_OTHER_PROPERTY',
    sell: 'SELL_OTHER_PROPERTY',
    trash: 'TRASH_OTHER_PROPERTY',
    listRented: 'OTHER_RENTED_PROPERTY',
    reactiveRented: 'REACTIVE_OTHER_RENTED_PROPERTY',
    listSold: 'OTHER_SOLD_PROPERTY',
    reactiveSold: 'REACTIVE_OTHER_SOLD_PROPERTY',
    listTrashed: 'OTHER_TRASHED_PROPERTY',
    reactiveTrashed: 'REACTIVE_OTHER_TRASHED_PROPERTY',
    delete: 'DELETE_OTHER_PROPERTY'
  },
  people: {
    listDocuments: 'OTHER_PERSON_DOCUMENTS',
    listProfiles: 'OTHER_PERSON_SEARCH_PROFILES',
    listData: 'OTHER_PERSON_DATA',
    edit: 'EDIT_OTHER_PERSON',
    listNotes: 'OTHER_PERSON_NOTES',
    listCalendar: 'OTHER_PERSON_CALENDAR',
    listProperty: 'OTHER_PERSON_PROPERTY',
    listDiscard: 'OTHER_PERSON_DISCARD_MATCHED',
    changePositionFunnel: 'OTHER_PERSON_DEAL',
    discardInterested: 'OTHER_PERSON_DISCARD_INTERESTED',
    changeRealtor: 'OTHER_PERSON_CHANGE_REALTOR',
    delete: 'DELETE_OTHER_PERSON'
  }
};
