import React from 'react';
// Components
import Wizard from 'components/Wizard';
// HOC
import { withWizard } from 'HOC/withWizard';

const STEPS = [
  {
    target: '.js-properties-wrapper',
    title: 'Ordenar',
    content: 'Arraste os imóveis para ordenar.',
    disableOverlayClose: false,
    spotlightClicks: false,
    disableBeacon: true,
    placement: 'right-center'
  },
  {
    target: '.js-wrapper-map',
    title: 'Mapa',
    content: 'Aqui estão os imóveis listados ao lado.',
    disableOverlayClose: false,
    spotlightClicks: false,
    disableBeacon: true,
    placement: 'left-center'
  },
  {
    target: '.js-map-actions',
    title: 'Compartilhamento',
    content:
      'Você pode compartilhar o roteiro de visita pelo WhatsApp ou visualizar diretamente no Google.',
    disableOverlayClose: false,
    spotlightClicks: false,
    disableBeacon: true,
    placement: 'top-center'
  }
];

function WizardItinerary({ isLoaded, onClose }) {
  if (!isLoaded) return null;
  return <Wizard steps={STEPS} onClose={onClose} />;
}
export default withWizard('wizard/itinerary')(WizardItinerary);
