// import styles from './styles'

import { Field, reduxForm } from 'redux-form';
import { Input } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import { Fields } from 'features/Financial';
import Button from 'components/Button';
import { Col, Row } from 'react-flexbox-grid';
import { useMemo } from 'react';

function FormStoreAccount({ isEditting, type, handleSubmit, handleClose }) {
  const isAccount = useMemo(() => {
    return type === '1' || type === 1;
  }, [type]);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={5}>
          <Fields.Icon />
        </Col>
        <Col xs={7}>
          <Field
            maxLength={30}
            label="Nome da conta"
            name="title"
            component={Input}
          />
          {!isEditting ? (
            <Field
              valueFormat
              label="Saldo inicial"
              name="initial_balance"
              component={Input}
              prefix="R$ "
              inputProps={{
                decimalScale: 2,
              }}
            />
          ) : null}
        </Col>
      </Row>

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({ form: 'FormStoreAccount' })(FormStoreAccount);
