import React, { useCallback } from 'react';
// Components
import MainTitle from 'components/MainTitle';
import ChartPeriodForm from '../../components/ChartPeriodForm';
import Container from 'components/Container';
import Wrapper from 'components/Wrapper';
import TableLoss from './components/TableLoss';
import FieldSelectUser from 'containers/FieldSelectUser';

const defaultProps = {};
const propTypes = {};

function DealLoss() {
  const handleSubmit = useCallback(() => {}, []);

  return (
    <Wrapper.container>
      <MainTitle
        title="Negócios Perdidos"
        text="O histórico de todos os seus negócios perdidos."
      >
        <ChartPeriodForm onSubmit={handleSubmit}>
          <FieldSelectUser
            name="user_id"
            className="h-margin-right--15"
            style={{ width: 180 }}
          />
        </ChartPeriodForm>
      </MainTitle>
      <Container padding>
        <TableLoss />
      </Container>
    </Wrapper.container>
  );
}

DealLoss.defaultProps = defaultProps;
DealLoss.propTypes = propTypes;

export default DealLoss;
