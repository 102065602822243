import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../Icon';
import { useNavLink } from 'components/NavAside/hooks/useNavLink';

const propTypes = {
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  children: PropTypes.node,

  className: PropTypes.string,
  activeClassName: PropTypes.string,
};

const defaultProps = {
  to: null,
  icon: 'account',
  children: null,

  className: 'NavAside__item',
  activeClassName: 'NavAside__item--is-active',
};

const NavAsideLink = ({
  children,
  icon: IconC,
  to,
  disabled,
  active,
  activeClassName,
  className,
  ...rest
}) => {
  const { Component, componentProps } = useNavLink({
    to,
    disabled,
    active,
    activeClassName,
    className,
    rest,
  });

  return (
    <Component {...componentProps}>
      <span className="NavAside__item_icon">
        {typeof IconC === 'function' ? (
          <IconC className={'Icon Icon--small'} />
        ) : (
          <Icon name={IconC} />
        )}
      </span>{' '}
      {children}
    </Component>
  );
};

NavAsideLink.propTypes = propTypes;
NavAsideLink.defaultProps = defaultProps;

export default NavAsideLink;
