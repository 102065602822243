import styled from 'styled-components';

export const Item = styled.li`
  label {
    padding: 0.3em;
    width: 100%;
    cursor: pointer;
  }
`;

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;
