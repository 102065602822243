import React, { useState } from 'react';
import Modal from 'react-modal';
import ModalHasPaidCredits from './ModalHasPaidCredits';
import ModalNoPaidCredits from './ModalNoPaidCredits';
import { useRequest } from 'hooks/useRequest';
import { cancelCredits, creditsPreview } from 'services/financialv2/contracts';
import Loading from 'components/Loading';
import Alert from 'react-s-alert';

function ModalCancelInstallment({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  contract,
  onSuccess,
  isOneInstallment = false,
}) {
  const [isRequesting, setIsRequesting] = useState(false);

  const { data, isFetching } = useRequest({
    request: () => creditsPreview({ id: contract.id }),
  });

  const handleCancel = async () => {
    // Não faz nada enquanto não finalizou o request
    if (isRequesting) return false;

    try {
      setIsRequesting(true);
      await cancelCredits({ id: contract?.id });
      if (isOneInstallment) {
        Alert.success('Boleto excluído');
      } else {
        Alert.success('Pacote cancelado');
      }
      handleClose();
      if (onSuccess) onSuccess();
    } finally {
      // Remove o requesting independente se deu erro sim/não
      setIsRequesting(false);
    }
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      {isFetching ? (
        <Loading isVisible isBlock />
      ) : data?.has_paid_credits === false ? (
        <ModalNoPaidCredits
          isOneInstallment={isOneInstallment}
          data={data}
          handleClose={handleClose}
          handleCancel={handleCancel}
        />
      ) : (
        <ModalHasPaidCredits
          isOneInstallment={isOneInstallment}
          data={data}
          handleClose={handleClose}
          handleCancel={handleCancel}
        />
      )}
    </Modal>
  );
}

export default ModalCancelInstallment;
