import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 10px;
  padding: 10px;
  background: #fff;

  width: 140px;
  height: 80px;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border: 1px solid #DADADA;

  text-align: center;
`;
