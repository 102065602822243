import React from 'react';
import TableSort from 'components/TableSort';
import Check from 'components/Check';
import TableCardProperty from '../TableCardProperty';

// import { Wrapper } from './styles';

function TableProperties({
  data,
  handleToggle,
  handleToggleAll,
  isAllItemsChecked,
  isChecked,
}) {
  const getTitleFormatted = (type, subtype) => {
    if (type === subtype) return type;
    return `${type} - ${subtype}`;
  };

  const getCoverImage = (property) => {
    return {
      file_url: property.cover_image_url,
    };
  };

  const getProperty = (property) => {
    return {
      ...property,
      cover_image: {
        file_url: property.cover_image_url,
      },
      title_formatted: property?.title_formatted,
    };
  };

  return (
    <TableSort
      hasCheckbox
      data={data}
      renderHeader={() => (
        <tr>
          <td>
            <Check
              size={18}
              checked={isAllItemsChecked(data)}
              onClick={() => handleToggleAll(data)}
            />
          </td>
          <td>Imóvel</td>
        </tr>
      )}
      renderItem={({ id, property }) => {
        return (
          <tr key={id}>
            <td>
              <Check
                size={18}
                checked={isChecked(id)}
                onClick={() => handleToggle(id)}
              />
            </td>
            <td>
              <TableCardProperty property={getProperty(property)} />
            </td>
          </tr>
        );
      }}
    />
  );
}

export default TableProperties;
