import React from 'react';
import Dropdown, { List, Item } from 'components/Dropdown';
import Button from 'components/Button';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useCan } from 'hooks/useCan';

function ReservationOptions({
  reservation,
  handleUpdate,
  handleClose,
  handleRemove,
}) {
  const canDestroy = useCan({
    run: 'DYNAMIC_DESTROY',
    permissions: reservation.permissions,
  });

  const canEdit = useCan({
    run: 'DYNAMIC_EDIT',
    permissions: reservation.permissions,
  });

  const isActive = !reservation.closed_at;

  if (!canDestroy && !canEdit) return null;

  return (
    <Dropdown isDropHover>
      <Button type="button" size="medium" color="white">
        Opções <MdKeyboardArrowDown />
      </Button>
      <List>
        {canEdit && (
          <>
            <Item>
              <button onClick={handleUpdate && handleUpdate(reservation)}>
                Editar
              </button>
            </Item>
            {isActive && (
              <Item>
                <button onClick={handleClose && handleClose(reservation)}>
                  Encerrar
                </button>
              </Item>
            )}
          </>
        )}
        {canDestroy && (
          <Item>
            <button onClick={handleRemove && handleRemove(reservation)}>
              Excluir
            </button>
          </Item>
        )}
      </List>
    </Dropdown>
  );
}

export default ReservationOptions;
