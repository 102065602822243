import { ModalContent, ModalFooter, ModalTitle } from "components/Modal";
import { Step } from "../../styles";
import Button from "components/Button";
import { useForm } from "../../contexts/form";
import List from './components/List';

const Plan = () => {
  const { setPage } = useForm();

  return (
    <>
      <ModalTitle text="Hora de pensar no longo prazo!" actions={<Step>Etapa<br />2/3</Step>}>
        Prazo de Contratação
      </ModalTitle>

      <ModalContent>
        <List />

        <ModalFooter>
          <Button color="white" colorText="primary" onClick={() => setPage('details')}>
            Voltar
          </Button>
          <div className="h-flex__cell--grow" />
          <Button type="submit" color="success" onClick={() => setPage('payment')}>
            Ir para pagamento
          </Button>
        </ModalFooter>
      </ModalContent>
    </>
  );
}

export default Plan;
