import { ThemeProvider } from 'styled-components';
import * as S from './styles';
import { MdCheck } from 'react-icons/md';

const Model4 = ({
  image,
  title,
  subtitle,
  description,
  logoUrl,
  backgroundColor,
  mainColor,
}) => {
  console.log(description);
  return (
    <ThemeProvider
      theme={{
        fontSize: '32px',
        backgroundColor,
        mainColor,
        logoUrl,
      }}
    >
      <S.Wrapper>
        {logoUrl && (
          <S.Logo>
            <img src={logoUrl} alt="" />
          </S.Logo>
        )}
        <S.Image src={image} alt="" />
        <S.Box>
          <S.Title>{title}</S.Title>
          <S.Price>{subtitle}</S.Price>
          {description ? (
            <S.Description>
              {description.map((item, key) => (
                <li key={key + item}>
                  <MdCheck />
                  {item}
                </li>
              ))}
            </S.Description>
          ) : null}
        </S.Box>
      </S.Wrapper>
    </ThemeProvider>
  );
};

export default Model4;
