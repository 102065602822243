import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import Table from 'components/Table';
import { MdArrowDropDown as Arrow } from 'react-icons/md';
import Dropdown, { Item, List } from 'components/Dropdown';
import EditText from 'components/EditText';
import { Input } from 'components/Form';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      address: PropTypes.string,
      goto: PropTypes.string,
    })
  ),
  isFetching: PropTypes.bool,
  handleRemove: PropTypes.func,
};

const defaultProps = {
  data: [],
  isFetching: false,
  handleRemove: null,
};

const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'E-mail incorreto'
    : undefined;

const TableAccounts = ({
  data,
  isFetching,
  handleUpdate,
  handleUpdateSuccess,
  handleUpdatePassword,
  handleRemove,
}) => (
  <Table
    isFetching={isFetching}
    data={data}
    renderTitle={() => (
      <tr>
        <th>De</th>
        <th colSpan={2}>Para</th>
      </tr>
    )}
    renderItem={({ address, goto }) => (
      <tr key={address}>
        <td>{address}</td>
        <td>
          <EditText
            form={`table-accounts-${address}`}
            name="goto"
            onSubmit={handleUpdate(address)}
            onSubmitSuccess={handleUpdateSuccess}
            component={Input}
            inputProps={{
              autoGrow: true,
              validate: email,
            }}
          >
            {goto}
          </EditText>
        </td>
        <td className="Table__cell--small">
          <Dropdown isDropHover>
            <Button color="white" colorText="primary" size="medium">
              Opções <Arrow />
            </Button>
            <List>
              <Item>
                <button onClick={handleUpdatePassword(address)}>
                  Alterar Senha
                </button>
              </Item>
              <Item>
                <button onClick={() => handleRemove(address)}>Excluir</button>
              </Item>
            </List>
          </Dropdown>
        </td>
      </tr>
    )}
  />
);

TableAccounts.propTypes = propTypes;
TableAccounts.defaultProps = defaultProps;

export default TableAccounts;
