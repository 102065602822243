import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Pages
import pages from './pages';

const Routes = () => (
  <Switch>
    <Route path="/help/:type/list" component={pages.List} />
    <Route path="/help/:type/detail/:id" component={pages.Detail} />
    <Redirect to="/help/problem/list" />
  </Switch>
);

export default Routes;
