import { changePreview1, ITEM_TYPES } from 'services/financialv2/contracts';
import { openModalContractExtra } from 'modules/modal';
import { useDispatch } from 'react-redux';

export const useChangeContract = ({ item = ITEM_TYPES.USERS, onSuccess }) => {
  const dispatch = useDispatch();

  const fetchChangePreview = async () => {
    const { data } = await changePreview1({ item });
    return data;
  };

  const openModal = ({ preview, onSuccess }) => {
    dispatch(
      openModalContractExtra({
        item,
        preview,
        onSuccess,
      })
    );
  };

  const handleOpenModal = async () => {
    const preview = await fetchChangePreview();

    if (preview?.need_change_contract) {
      openModal({
        preview,
        onSuccess,
      });
      return false;
    }

    onSuccess();
  };

  return { fetchChangePreview, openModal, handleOpenModal };
};
