import React from 'react';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import ModalFooter from 'components/Modal/components/ModalFooter';
import Button from 'components/Button';
import { SelectMultiple } from 'components/Form';
// Helpers
import { parseSelect } from 'lib/formHelpers';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const FormFinancialDelete = ({
  financialIndexes,
  isFetching,
  message,
  financialIndex,
  handleSubmit,
  submitting,
  handleClose
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <p>
        Antes de apagar, por favor, selecione qual índice existente herderá os
        imóveis de <strong>{financialIndex.name}</strong>.
      </p>
      {message}
      <Row>
        <Field
          xs={6}
          multi={false}
          name="to_financial_index_id"
          component={SelectMultiple}
          options={financialIndexes}
          isLoading={isFetching}
          parse={parseSelect('id')}
          labelKey="name"
          valueKey="id"
        />
      </Row>
      <ModalFooter>
        <Button
          type="button"
          color="white"
          colorText="secondary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="danger" disabled={submitting}>
          Mover e apagar {financialIndex.name}
        </Button>
      </ModalFooter>
    </form>
  );
};

FormFinancialDelete.defaultProps = defaultProps;
FormFinancialDelete.propTypes = propTypes;

export default reduxForm({
  form: 'FormFinancialDelete',
  enableReinitialize: true
})(FormFinancialDelete);
