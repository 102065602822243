import { combineReducers } from 'redux';
// Modules
import billingPerBrokerReducer from './pages/BillingPerBroker/module';
import billingPerCommissionReducer from './pages/BillingPerCommission/module';
import billingPerTypeTransactionReducer from './pages/BillingPerTypeTransaction/module';

import dealPerBrokerReducer from './pages/DealPerBroker/module';
import dealPerChannelReducer from './pages/DealPerChannel/module';
import dealPerReasonLossReducer from './pages/DealPerReasonLoss/module';

import peoplePerPeriodReducer from './pages/PeoplePerPeriod/module';
import peoplePerChannelReducer from './pages/PeoplePerChannel/module';

import propertyPerPeriodReducer from './pages/PropertyPerPeriod/module';
import propertyPerTypeTransactionReducer from './pages/PropertyPerTypeTransaction/module';

import analyticsNewUsersReducer from './pages/AnalyticsNewUsers/module';

export default combineReducers({
  billingPerBroker: billingPerBrokerReducer,
  billingPerCommission: billingPerCommissionReducer,
  billingPerTypeTransaction: billingPerTypeTransactionReducer,

  dealPerBroker: dealPerBrokerReducer,
  dealPerChannel: dealPerChannelReducer,
  dealPerReasonLoss: dealPerReasonLossReducer,

  peoplePerPeriod: peoplePerPeriodReducer,
  peoplePerChannel: peoplePerChannelReducer,

  propertyPerPeriod: propertyPerPeriodReducer,
  propertyPerTypeTransaction: propertyPerTypeTransactionReducer,

  analyticsNewUsers: analyticsNewUsersReducer
});
