import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
// Components
import { Input, Select } from 'components/Form';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Button from 'components/Button';
import GroupControl from 'components/GroupControl';
import GroupItem from 'components/GroupControl/components/GroupItem';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const AddEmailAccountForm = ({ domains, handleSubmit, handleClose }) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Field required label="Nome" name="name" component={Input} xs={12} />
    </Row>
    <Row>
      <Col xs={12}>
        <GroupControl required label="E-mail">
          <GroupItem>
            <Field
              name="local_part"
              component={Input}
              autoComplete="new-email"
            />
          </GroupItem>
          <GroupItem size="small">
            <Field
              autoWidth
              name="domain_id"
              labelKey="email_url"
              valueKey="id"
              options={domains.data || []}
              placeholder=""
              component={Select}
            />
          </GroupItem>
        </GroupControl>
      </Col>
    </Row>
    <Row>
      <Field
        label="Senha"
        name="password"
        component={Input}
        xs={6}
        required
        type="password"
        autoComplete="new-password"
      />
      <Field
        label="Confirmar Senha"
        name="password_confirm"
        component={Input}
        xs={6}
        required
        type="password"
        autoComplete="new-password"
      />
    </Row>
    <ModalFooter>
      <Button color="white" colorText="secondary" onClick={handleClose}>
        Cancelar
      </Button>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success">
        Salvar
      </Button>
    </ModalFooter>
  </form>
);

AddEmailAccountForm.propTypes = propTypes;

export default reduxForm({
  form: 'AddEmailAccountForm',
  validate,
  enableReinitialize: true
})(AddEmailAccountForm);
