import TextLoading from 'components/TextLoading';
import Results from 'pages/PortalsOptionSelect/components/Results';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import FormSort from 'pages/PropertiesSearch/components/FormSort';
import ChangeLayout from '../ChangeLayout';
import { Actions, Title, Wrapper } from './styles';

function Header() {
  const { selectedPortal, selectedOption, properties, conditions, params } =
    usePortalOptions();

  return (
    <Wrapper>
      {!conditions.isSearchingByFilter ? (
        <Title>
          {!selectedPortal?.name ? (
            <TextLoading width={80} />
          ) : (
            `${selectedPortal?.name} | ${selectedOption?.name}`
          )}
        </Title>
      ) : (
        <Results params={params} properties={properties} />
      )}
      <Actions>
        <FormSort />
        <ChangeLayout />
      </Actions>
    </Wrapper>
  );
}

export default Header;
