import { useRequest } from 'hooks/useRequest';
import * as peopleService from 'services/people';

export const usePeople = (params) => {
  const { data, isFetching, meta, fetchData } = useRequest({
    request: ({ filter, sort = 'by_name', offset = 1 }) => {
      return peopleService.peopleCrm({
        ...params,
        filter: filter || params?.filter || null,
        sort,
        offset,
        limit: 25,
      });
    },
    initialFetch: false,
  });

  return { data, isFetching, meta, fetchData };
};
