import React, { useEffect } from 'react';
import { Field, initialize } from 'redux-form';
import { Select } from 'components/Form';
import FormSort from 'components/FormSort';
import { useDispatch } from 'react-redux';

// import { Wrapper } from './styles';

export const DEFAULT_OPTIONS = [
  { label: 'Atualização (Mais novo)', value: '-updated_at,id' },
  { label: 'Atualização (Mais antigo)', value: 'updated_at,id' },
  { label: 'Menor preço', value: 'calculated_price,id' },
  { label: 'Maior preço', value: '-calculated_price,id' },
  { label: 'Cadastro (Mais novo)', value: '-created_at,id' },
  { label: 'Cadastro (Mais antigo)', value: 'created_at,id' },
];

function FormSortContainer({
  key = '@tecimob/filter-properties',
  mainForm,
  options = DEFAULT_OPTIONS,
  onChange,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    const filter = localStorage.getItem(key) || '-updated_at';

    dispatch(
      initialize('PropertiesSort', {
        sort: filter,
      })
    );
  }, []);

  return (
    <FormSort form="PropertiesSort" mainForm={mainForm}>
      {({ handleChangeSelect }) => (
        <Field
          label="Ordenar por"
          placeholder=""
          name="sort"
          options={options}
          onChange={(event, value, ...rest) => {
            if (onChange) onChange(event, value, ...rest);

            localStorage.setItem(key, value);
            handleChangeSelect('sort')(event, value, ...rest);
          }}
          component={Select}
        />
      )}
    </FormSort>
  );
}

export default FormSortContainer;
