// import styles from './styles'

import { Route, Switch } from 'react-router-dom';
import { FinancialScreen, Reports } from 'features/Financial';
import React from 'react';

export function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/financial/dashboard"
        component={FinancialScreen.Dashboard}
      />
      <Route
        exact
        path="/financial/categories/:type"
        component={FinancialScreen.ListCategories}
      />
      <Route
        exact
        path="/financial/accounts"
        component={FinancialScreen.ListAccounts}
      />
      <Route
        exact
        path="/financial/credit-card"
        component={FinancialScreen.ListCards}
      />
      <Route
        exact
        path="/financial/credit-card/:accountId"
        component={FinancialScreen.ListBills}
      />
      <Route
        exact
        path="/financial/credit-card/:accountId/:billId"
        component={FinancialScreen.DetailBill}
      />
      <Route
        exact
        path="/financial/postings"
        component={FinancialScreen.ListPostings}
      />
      <Route
        exact
        path="/financial/history"
        component={FinancialScreen.ListHistory}
      />
      <Route
        exact
        path="/financial/reports/categories"
        component={Reports.Categories}
      />
      <Route exact path="/financial/reports/flow" component={Reports.Monthly} />
    </Switch>
  );
}
