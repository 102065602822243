import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Actions } from '../modules/asideCrm';
import { openModalCrmReception } from 'modules/modal';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';

export const KEY = '@tecimob/modal-crm-reception-2';
export const SERIAL = 39593;

export const useModalCrmReception = () => {
  const dispatch = useDispatch();
  const realEstate = useCurrentRealEstate();

  useEffect(() => {
    const storageModalCrmReception = localStorage.getItem(KEY);
    if (!storageModalCrmReception && realEstate.serial <= SERIAL) {
      dispatch(
        openModalCrmReception({
          afterClose: () => {
            dispatch(Actions.showWizard());
          },
        })
      );
    } else {
      dispatch(Actions.showWizard());
    }
  }, []);
};
