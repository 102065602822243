import * as Panel from '../Panel';
import * as S from './styles';
import { useBillsMonthly } from 'features/Financial/hooks/useDashboard';
import { useEffect } from 'react';
import moment from 'lib/moment';
import { capitalize } from 'lib/text';
import Button from 'components/Button';
import { AiOutlineFileText } from 'react-icons/all';
import { useDashboardContext } from '../../hooks/useDashboardContext';

export function PanelCreditcards() {
  const { refreshKey } = useDashboardContext();

  const { billsMonthly, fetchBills, billsMonthlyMeta, billsMonthlyIsFetching } =
    useBillsMonthly({
      type: 2,
    });

  useEffect(() => {
    fetchBills();
  }, [refreshKey]);

  return (
    <Panel.Wrapper>
      <Panel.Header>
        <Panel.Title>
          Fatura de {capitalize(moment().add(1, 'month').format('MMMM'))}
        </Panel.Title>
        <Panel.Price>{billsMonthlyMeta.bills_sum}</Panel.Price>
      </Panel.Header>
      <Panel.Content>
        <Panel.ContentTitle>Meus cartões</Panel.ContentTitle>
        <S.List>
          <tbody>
            {billsMonthly.map((bill) => (
              <S.Item key={bill.id}>
                <S.CardInfo>
                  <S.Account>
                    <S.Icon src={bill?.icon_url} />
                    <div>
                      <S.Title>{bill.account_name}</S.Title>
                      <S.Text>
                        Fecha dia {bill.closing_day} | Vence dia {bill.due_day}
                      </S.Text>
                    </div>
                  </S.Account>
                  <S.ItemAction>
                    <Button
                      size="small"
                      color="white"
                      to={`/financial/credit-card/${bill.account_id}/${bill.id}`}
                    >
                      <AiOutlineFileText /> Ver fatura
                    </Button>
                  </S.ItemAction>
                </S.CardInfo>

                <S.RowBottom>
                  <div>
                    <S.Label>Limite disponível</S.Label>
                    <S.Value>{bill.limit_available}</S.Value>
                  </div>
                  <div>
                    <S.Label>Fatura atual</S.Label>
                    <S.Value>{bill.value}</S.Value>
                  </div>
                </S.RowBottom>
              </S.Item>
            ))}
          </tbody>
        </S.List>
      </Panel.Content>
      <Panel.Footer>
        <Panel.FullButton to="/financial/credit-card">
          Gerenciar cartões
        </Panel.FullButton>
      </Panel.Footer>
    </Panel.Wrapper>
  );
}
