import React from 'react';
import Item from './components/Item';
import { Wrapper, Title, Content } from './styles';

function ListForm({ title = '', children }) {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <Content>{children}</Content>
    </Wrapper>
  );
}

export { Item };
export default ListForm;
