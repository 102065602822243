import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { locationSelector } from 'modules/location';
import useFormValue from 'hooks/useFormValue';
import { getLocation } from 'services/ceps';
import useReduxForm from 'hooks/useReduxForm';
import { showLoading, hideLoading } from 'modules/loading';

export const useMapLocation = ({
  formName,
  cepKey = 'cep',
  countryKey = 'country_id',
  stateKey = 'state_id',
  cityKey = 'city_id',
  neighborhoodKey = 'neighborhood_id',
  addressStreetKey = 'address_street',
  addressNumberKey = 'address_number',
  addressComplementKey = 'address_complement'
}) => {
  if (!formName) throw new Error('Form name is required!');

  const dispatch = useDispatch();
  const { change } = useReduxForm();

  const [location, setLocation] = useState(null);

  const values = useFormValue([
    cepKey,
    addressStreetKey,
    addressNumberKey,
    addressComplementKey,
    neighborhoodKey
  ]);

  const fetchData = useCallback(
    debounce(async ({ cep, address, streetName, neighborhoodId }) => {
      try {
        dispatch(showLoading());
        let params = {
          address
        };

        if (cep?.length >= 9 && !address) {
          params = {
            ...params,
            cep
          };
        }

        if (neighborhoodId) {
          params = {
            ...params,
            neighborhood_id: neighborhoodId
          };
        }

        if (streetName) {
          params = {
            ...params,
            street_name: streetName
          };
        }

        const { data } = await getLocation(params);

        if (data.length <= 0) return null;

        const location = data[0];

        if (location?.country?.id)
          dispatch(change('country_id', location.country.id));
        if (location?.state?.id)
          dispatch(change('state_id', location.state.id));
        if (location?.city?.id) dispatch(change('city_id', location.city.id));
        if (location?.neighborhood?.id)
          dispatch(change('neighborhood_id', location.neighborhood.id));
        if (location?.maps_latitude)
          dispatch(change('maps_latitude', location?.maps_latitude));
        if (location?.maps_longitude)
          dispatch(change('maps_longitude', location?.maps_longitude));

        setLocation(data[0]);
      } finally {
        dispatch(hideLoading());
      }
    }, 1000),
    []
  );

  const country = useSelector(locationSelector(countryKey, formName));
  const state = useSelector(locationSelector(stateKey, formName));
  const city = useSelector(locationSelector(cityKey, formName));
  const neighborhood = useSelector(locationSelector(neighborhoodKey, formName));

  const address = useMemo(() => {
    let _address = '';

    if (!neighborhood?.name) return '';

    const street = values[addressStreetKey];
    const number = values[addressNumberKey];
    const complement = values[addressComplementKey];

    if (!!country) _address += country.name + ' ';
    if (!!state) _address += state.name + ' ';
    if (!!city) _address += city.name + ' ';
    if (!!neighborhood) _address += neighborhood.name + ' ';
    if (street) _address += street + ' ';
    if (number) _address += number + ' ';
    if (complement) _address += complement + ' ';

    return _address;
  }, [values, country, state, city, neighborhood]);

  return {
    location,
    address,
    setLocation,
    searchByAddress: () => {
      fetchData({ address, neighborhoodId: values[neighborhoodKey] });
    }
  };
};
