import React from 'react';
import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { openModalCreditsChatGpt } from 'modules/modal';

function ModalChatGptNoCredits({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  onSuccess,
}) {
  const dispatch = useDispatch();

  const handleClickBuyCredits = () => {
    dispatch(
      openModalCreditsChatGpt({
        onSuccess,
      })
    );
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Sem créditos do ChatGPT" handleClose={handleClose}>
        <p>Você usou todos créditos disponíveis de uso do ChatGPT.</p>
        <p>
          <br />
        </p>
        <p>
          Todo dia de aniversário da sua conta, lhe forneceremos 10 créditos
          (Não cumulativo).
          <br />
          Caso precise de mais, você pode comprar pacotes de créditos; ou
        </p>
        <p>
          <br />
        </p>
        <p>
          Acesse o ChatGPT no seu navegador, gere a descrição, copie e cole no
          Tecimob.
          <br />
          Link de acesso ao ChatGPT:{' '}
          <a href="https://chat.openai.com" target="_blank" className="h-link">
            https://chat.openai.com
          </a>
        </p>
        <ModalFooter>
          <Button type="button" color="white" onClick={handleClose}>
            Voltar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button color="caution" type="button" onClick={handleClickBuyCredits}>
            Comprar créditos
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalChatGptNoCredits;
