import React, { useMemo, useCallback } from 'react';
import { compose } from 'redux';
// Components
import GeneralForm from './components/GeneralForm';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
// Services
import * as realEstateService from 'services/realEstate';
import * as generalService from 'services/settings/general';
import { withPermission } from 'HOC/withPermission';
import { useRequest } from 'hooks/useRequest';

function General() {
  const { data: realEstate, isFetching } = useRequest({
    request: realEstateService.getRealEstate
  });

  const initialValues = useMemo(() => {
    return realEstate;
  }, [realEstate]);

  const handleSubmit = useCallback(generalService.update, []);

  return (
    <Wrapper.container>
      <MainTitle
        title="Sistema"
        text="Edite os textos padronizados do sistema de acordo com sua preferência e agilize o seu dia a dia."
      />
      <GeneralForm
        isFetching={isFetching}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </Wrapper.container>
  );
}

export default compose(
  withPermission({
    rules: ['EDIT_MESSAGE'],
    isPage: true
  })
)(General);
