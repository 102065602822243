import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import FormPortalAdd from '../../components/FormPortalAdd';
import { ModalTemplate } from 'components/Modal';
// Modules
//import { fetchData } from "pages/Portals/module";
// Services
import * as portalsService from 'services/settings/portals';

export default function Form({
  handleClose,
  changePage,
  onSubmitSuccess: onSuccess,
}) {
  const dispatch = useDispatch();

  const onSubmit = useCallback((values) => {
    return portalsService.addPortal(values);
  }, []);

  const onSubmitSuccess = useCallback(
    (res) => {
      if (onSuccess) onSuccess(res);
    },
    [dispatch, handleClose]
  );

  return (
    <ModalTemplate title="Adicionar portal" handleClose={handleClose}>
      <FormPortalAdd
        handleClose={handleClose}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        changePage={changePage}
      />
    </ModalTemplate>
  );
}
