import styled from 'styled-components';

export const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.02);
  padding: 15px 20px 5px;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: -20px;
  border-top: 1px solid #dedede;
`;
