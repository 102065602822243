import React, { useMemo } from 'react';
import { Field } from 'redux-form';
// Components
import { Input, ColorPicker } from 'components/Form';
import { Wrapper, Image, Stripe, Content } from './styles';
// Assets
import imageExample from './image-example.png';
import useFormValue from 'hooks/useFormValue';

const PreviewStripe = () => {
  const { stripe_background, stripe_text } = useFormValue([
    'stripe_background',
    'stripe_text'
  ]);

  const color = useMemo(() => {
    return stripe_background || '#ffffff';
  }, [stripe_background]);

  return (
    <Wrapper>
      <Image>
        {stripe_text && <Stripe color={color}>{stripe_text}</Stripe>}
        <img src={imageExample} alt="Imagem de exemplo" />
      </Image>
      <Content>
        <Field
          label="Texto da tarja"
          name="stripe_text"
          component={Input}
          placeholder="Ex.: Ótima localização"
        />
        <Field
          label="Cor da tarja"
          name="stripe_background"
          component={ColorPicker}
        />
      </Content>
    </Wrapper>
  );
};

export default PreviewStripe;
