import { createSelector } from 'reselect';
import createDataReducer from 'modules/data';
// Services
import * as typesService from 'services/types';
// Selectors
import { selector } from '../../components/MainForm';
import { selector as filterPortal } from 'components/PortalPropertiesFilter';

export const {
  reducer: typesAndSubtypesReducer,
  selectors: typesAndSubtypesSelectors,
  actionCreators: typesAndSubtypesActions,
} = createDataReducer('filter/typesAndSubtypes');

/**
 * Seleciona um type
 * @return type
 */
export const typeByFormSelector = (selector) => {
  // Campo que contem o valor tanto de um subtype quanto de um type
  const getTypeOrSubtypeId = (state) =>
    selector(state, 'by_type_or_subtype_id');

  return createSelector(
    [getTypeOrSubtypeId, typesAndSubtypesSelectors.getAll],
    (typeOrSybtypeId, typesAndSubtypes) => {
      if (!typeOrSybtypeId) return null;

      let type = {};

      typesAndSubtypes.every((typeAndSubtype) => {
        type = typeAndSubtype.is_type ? typeAndSubtype : type;
        if (typeAndSubtype.id === typeOrSybtypeId) return false;
        return true;
      });

      return type;
    }
  );
};

/**
 * Seleciona um type
 * @return type
 */
export const typeSelector = typeByFormSelector(selector);
export const typePortalSelector = typeByFormSelector(filterPortal);

/**
 * Busca os tipos e subtipos juntos
 * @param params
 * @return {Promise}
 */
export const fetchTypeAndSubtypes = (params) => (dispatch) => {
  dispatch(typesAndSubtypesActions.request());

  return typesService.getGroupedTypesSubtypes(params).then(({ data }) => {
    dispatch(typesAndSubtypesActions.receive(data));
    return data;
  });
};

export default typesAndSubtypesReducer;
