import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Select2, SelectMultiple } from 'components/Form';
import { getSituations } from 'services/properties';
import { withFormName } from 'HOC/withFormName';
import { parseSelect } from 'lib/formHelpers';

function FieldSituation({
  multi,
  multiSelect,
  required,
  formName,
  typeId,
  name,
  ...props
}) {
  const [isFetching, setIsFetching] = useState(false);
  const [situations, setSituations] = useState();

  // Select tem que ficar desabilitado caso
  // não tenha o tipo selecionado
  const disabled = !typeId;

  async function fetchSituations(id) {
    try {
      setIsFetching(true);

      const { data } = await getSituations({
        filter: { by_type_id: id },
        sort: 'order',
      });

      // Seta as situações
      setSituations(data);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    if (typeId) {
      fetchSituations(typeId);
    }
  }, [typeId]);

  if (multi && !multiSelect) {
    return (
      <Field
        {...props}
        required={required}
        disabled={disabled}
        isLoading={isFetching}
        filter
        label="Situação"
        name={name}
        options={situations}
        component={Select2}
        valueKey="id"
        labelKey="title"
        selectedItemsLabel="situações"
        placeholder="Selecione"
      />
    );
  }

  return (
    <Field
      multi={multiSelect}
      required={required}
      disabled={disabled}
      label="Situação"
      name={name}
      valueKey="id"
      labelKey="title"
      options={situations}
      component={SelectMultiple}
      parse={parseSelect('id')}
      {...props}
    />
  );
}

FieldSituation.propTypes = {
  required: PropTypes.bool,
  typeId: PropTypes.string,
  formName: PropTypes.string,
  name: PropTypes.string,
  multi: PropTypes.bool,
};

FieldSituation.defaultProps = {
  required: true,
  typeId: null,
  formName: null,
  name: 'situation_id',
  multi: false,
};

export default withFormName(FieldSituation);
