import React from 'react';
import styled from 'styled-components';
import { MdLocationPin } from 'react-icons/md';

const Wrapper = styled.div`
  width: 100%;
  height: 135px;
  display: grid;
  place-items: center;
  text-align: center;

  svg {
    font-size: 40px;
    margin-bottom: 5px;
  }
`;

function Nolocation() {
  return (
    <Wrapper>
      <div>
        <MdLocationPin />
        <p>
          A localização esta sendo
          <br /> definida por condomínio
        </p>
      </div>
    </Wrapper>
  );
}

export default Nolocation;
