import guru from 'assets/img/logo-guru.png';
import { SelectMultiple } from 'components/Form';
import { parseSelect } from 'lib/formHelpers';
import { Name } from 'pages/PropertyStore/pages/Publish/components/ListPortals/styles';
import { Field } from 'redux-form';
import FieldPortalOptions from '../FieldPortalOptions';
// import { Container } from './styles';

const ConnectedPortals = ({ data, showGuru }) => {
  return (
    <table className="Table">
      <thead>
        <tr>
          <th>Portal</th>
          <th className="Table__cell--small">Tipo de anúncio</th>
        </tr>
      </thead>
      <tbody>
        {showGuru && (
          <tr>
            <td>
              <img src={guru} alt="Guru" />
            </td>
            <td>
              <Field
                multi={false}
                name={`is_network_published`}
                responsive={false}
                component={SelectMultiple}
                options={[{ value: true, label: 'Destaque' }]}
                parse={parseSelect('value')}
                style={{ width: 250 }}
              />
            </td>
          </tr>
        )}
        {data?.map(({ id, name, file_url, portal_id, portal_real_estate }) => {
          return (
            <tr key={id}>
              <td>
                {file_url ? (
                  <img src={file_url} alt={name} />
                ) : (
                  <Name>{name}</Name>
                )}
              </td>

              <td className="Table__cell--small">
                {portal_real_estate?.options?.length > 0 && (
                  <FieldPortalOptions portalRealEstate={portal_real_estate} />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ConnectedPortals;
