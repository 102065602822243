import styled from 'styled-components';

export const Wrapper = styled.td`
  padding: 15px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Text = styled.div``;

export const Name = styled.p`
  font-size: 14px;
  color: #1c0c1e;
  margin-bottom: 3px;
`;

export const Group = styled.p`
  font-size: 12px;
  color: #363636;
`;
