// Components
import { SelectMultiple } from 'components/Form';
import { parseSelect } from 'lib/formHelpers';
import { Field } from 'redux-form';

const propTypes = {};
const defaultProps = {};

const EstablishmentsField = ({ options }) => {
  if (!options || options?.length <= 0) return null;

  return (
    <Field
      name="by_establishment_id"
      label="Estabelecimentos Próximos."
      options={options}
      component={SelectMultiple}
      valueKey="id"
      labelKey="title"
      parse={parseSelect('id')}
      closeOnSelect={false}
    />
  );
};

EstablishmentsField.propTypes = propTypes;
EstablishmentsField.defaultProps = defaultProps;

export default EstablishmentsField;
