import React from 'react';
// import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import {
  getInitialValues,
  handleFormSubmit,
} from '../../../modules/modalFinancial';

import { ModalTemplate } from '../../../components/Modal';
import FinancialForm from './components/FinancialForm';

const defaultProps = {};

const propTypes = {};

class ModalFinancial extends React.Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  componentDidMount() {
    this.props.getInitialValues(this.props.id);
  }
  handleFormSubmit(values) {
    return this.props.handleFormSubmit(values);
  }
  render() {
    const {
      id,
      modalConfig,
      meta,
      isOpen,
      modalType,
      handleClose,
      initialValues,
    } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title={id ? 'Atualizar Índice' : 'Adicionar Índice'}
          handleClose={handleClose}
          isLoading={meta.isFetching}
        >
          <FinancialForm
            id={id}
            onSubmit={this.handleFormSubmit}
            handleClose={handleClose}
            initialValues={initialValues}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

ModalFinancial.defaultProps = defaultProps;
ModalFinancial.propTypes = propTypes;

const mapStateToProps = (state) => ({
  initialValues: state.modalFinancial.formData,
  meta: state.modalFinancial.meta,
});

const mapDispatchToProps = {
  handleFormSubmit,
  getInitialValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFinancial);
