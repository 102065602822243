import React from 'react';
// Components
import ListProfiles from '../../components/ListProfiles';
import Nodata from 'components/Nodata';
// Assets
import search from 'assets/img/noData/search.svg';

const ProfileActive = ({
  profiles,
  buttonCreateProfile,
  handleDeleteProfile
}) => {
  if (!profiles.length)
    return (
      <Nodata
        style={{ padding: '20px 0', maxWidth: 330 }}
        image={search}
        title="Nenhum perfil de busca ativo."
        text="Adicionando um perfil de busca, você será avisado quando um imóvel compatível com as características do seu cliente for encontrado."
      >
        <div className="h-margin-top--15">{buttonCreateProfile()}</div>
      </Nodata>
    );

  return (
    <ListProfiles
      profiles={profiles}
      handleClickDelete={handleDeleteProfile}
      buttonCreateProfile={buttonCreateProfile}
    />
  );
};

export default ProfileActive;
