import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Components
import { Input } from 'components/Form';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Button from 'components/Button';
import ModalTemplate from 'components/Modal/components/ModalTemplate';
import validate from './validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const PreRegisterDomainForm = ({ handleClose, handleSubmit }) => (
  <ModalTemplate title="Registrar domínio" handleClose={handleClose}>
    <form onSubmit={handleSubmit}>
      <Field label="Domínio" name="url" component={Input} />
      <ModalFooter>
        <Button color="white" colorText="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow"></span>
        <Button type="submit" color="success">
          Registrar
        </Button>
      </ModalFooter>
    </form>
  </ModalTemplate>
);

PreRegisterDomainForm.propTypes = propTypes;

const selector = formValueSelector('PreRegisterDomainForm');

const mapStateToProps = state => ({
  url: selector(state, 'url')
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'PreRegisterDomainForm',
    validate,
    enableReinitialize: true
  })(PreRegisterDomainForm)
);
