import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  background: #ffffff;
  border-radius: 4px;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const BoxLeft = styled.div`
  flex-shrink: 0;
`;

export const BoxRight = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 20px;
  min-width: 0;

  > div {
    flex: 1 0;
    min-width: 185px;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  gap: 20px;
`;

export const Icon = styled.img`
  width: 36px;
  height: 36px;
`;

export const Content = styled.div``;

export const Title = styled.h2`
  font-size: 14px;
  margin-bottom: 5px;
`;

export const Text = styled.p`
  font-size: 12px;
  color: #4c555f;
`;

export const Badge = styled.span`
  display: inline-block;
  padding: 3px 5px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #1c0c1e;
  border-radius: 4px;
  margin-top: 10px;
`;
