import ReservationOptions from '../ReservationOptions';
import { Content, Header, Observation, Table, Title, Wrapper } from './styles';

function ReservationItem({
  person,
  reservation = {},
  handleUpdate,
  handleClose,
  handleRemove,
}) {
  const isActive = !reservation.closed_at;

  return (
    <Wrapper>
      <Header>
        <Title>
          Reserva{' '}
          <span className={isActive ? 'h-color--success' : 'h-color--danger'}>
            {isActive ? 'Ativa' : 'Encerrada'}
          </span>
        </Title>
        <ReservationOptions
          reservation={reservation}
          handleUpdate={handleUpdate}
          handleClose={handleClose}
          handleRemove={handleRemove}
        />
      </Header>
      <Content>
        <Table>
          <tr>
            <td width={100}>Data do Registro:</td>
            <td>{reservation.created_at}</td>
          </tr>
          <tr>
            <td>Reservado até:</td>
            <td>{reservation?.booked_until || '-'}</td>
          </tr>
          <tr>
            <td>Corretor/Usuário:</td>
            <td>{reservation?.booking_user?.name || '-'}</td>
          </tr>
          <tr>
            <td>Referencia:</td>
            <td>{reservation?.property?.reference || '-'}</td>
          </tr>
        </Table>
        <Observation>
          <p>
            <strong>Observações da reserva:</strong>
          </p>
          <p>{reservation?.booking_note || '-'}</p>
        </Observation>
      </Content>
      {isActive === false ? (
        <Content>
          <Table>
            <tr>
              <td width={100}>Situação:</td>
              <td>{isActive ? 'Ativa' : 'Encerrada'}</td>
            </tr>
            <tr>
              <td>Encerrado em:</td>
              <td>{reservation?.closed_at}</td>
            </tr>
            <tr>
              <td>Corretor/Usuário:</td>
              <td>{reservation?.closing_user?.name || '-'}</td>
            </tr>
          </Table>
          <Observation>
            <p>
              <strong>Observações do encerramento:</strong>
            </p>
            <p>{reservation?.closing_note || '-'}</p>
          </Observation>
        </Content>
      ) : null}
    </Wrapper>
  );
}

export default ReservationItem;
