import React from 'react';
import { Col, Divider, Text, Wrapper } from './styles';
import BtnLoss from '../BtnLoss';
import Checkbox from 'components/CardProperty/components/CardVertical/components/Checkbox';
import { plural } from 'lib/text';

function Header({
  isAllSelected,
  checkedItems,
  onCancel,
  onSelectAll,
  onLoss,
}) {
  return (
    <Wrapper>
      <Col>
        <span className="h-link" onClick={onSelectAll}>
          <Checkbox
            checked={isAllSelected}
            size={18}
            style={{ marginTop: '-3px' }}
          />
          Selecionar Tudo
        </span>
        <Divider> | </Divider>
        <span className="h-link" onClick={onCancel}>
          Desmarcar todos
        </span>
      </Col>
      <Col>
        {checkedItems && (
          <>
            <Text>
              {plural(checkedItems.length, 'Selecionado', 'Selecionados')}
            </Text>
            <BtnLoss onClick={onLoss} />
          </>
        )}
      </Col>
    </Wrapper>
  );
}

export default Header;
