import React from 'react';
import { Row } from 'react-flexbox-grid';
import { reduxForm } from 'redux-form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
// Services
import * as groupsService from 'services/settings/groups';
import Loading from 'components/Loading';
import FieldUserGroups from 'containers/FieldUserGroups';

// import { Container } from './styles';

const Form = ({ handleSubmit, data, onCancel, handleClose, submitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      {submitting && <Loading isVisible isFullScreen />}
      {data.users_count > 0 ? (
        <>
          Este grupo está ligado a {data.users_count} usuários.
          <br />
          Defina para qual grupo deseja transferi-los:
          <Row className="h-margin-top--15">
            <FieldUserGroups
              xs={12}
              label="Selecione a grupo"
              name="group_id"
              excludeIds={[data?.id]}
            />
          </Row>
        </>
      ) : (
        <p>Você tem certeza que deseja remover esse grupo?</p>
      )}

      <ModalFooter>
        <Button
          color="white"
          colorText="secondary"
          type="button"
          onClick={() => {
            handleClose();
            if (onCancel) onCancel();
          }}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button disabled={submitting} type="submit" color="danger">
          Remover
        </Button>
      </ModalFooter>
    </form>
  );
};

export default reduxForm({
  form: 'ModalGroupUserRemove',
  validate: (values) => {
    let errors = {};

    if (values?.users_count > 0 && !values?.group_id) {
      errors.group_id = ['Campo obrigatório'];
    }

    return errors;
  },
  onSubmit: async (values) => {
    if (values?.users_count) {
      await groupsService.transfer(values?.id, values?.group_id);
    }

    return groupsService.remove(values?.id);
  },
  onSubmitSuccess: (response, dispatch, props) => {
    props.handleClose();
    if (props?.onSuccess) props?.onSuccess(response);
  },
})(Form);
