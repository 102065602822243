import React from 'react';
import FormDealGain from '../FormDealGain';
// Components
import { ModalTemplate } from 'components/Modal';

const propTypes = {};

const defaultProps = {};

const ModalGain = ({ handleClose, ...props }) => (
  <ModalTemplate
    isLoading={props.isFetching}
    title="Parabéns por ter fechado negócio!"
    handleClose={handleClose}
  >
    <FormDealGain
      onSubmit={props.handleSubmitGain}
      initialValues={{
        people_id: props.infos.person.id,
        user_id: localStorage.getItem('current-user').id
      }}
      {...props}
    />
  </ModalTemplate>
);

ModalGain.propTypes = propTypes;
ModalGain.defaultProps = defaultProps;

export default ModalGain;
