import React, { useCallback } from 'react';
import { change, Field, SubmissionError } from 'redux-form';
import { FaWhatsapp } from 'react-icons/fa';
import FormPhone from 'components/FormPhone';
import { ListArray } from 'components/Form';
import useReduxForm from 'hooks/useReduxForm';
import { useDispatch } from 'react-redux';
import useFormValue from 'hooks/useFormValue';
import isEmpty from 'lodash/isEmpty';
import * as peopleService from 'services/people';
import { whatsappSendUrl } from 'lib/share-helpers';

export default function FieldPhones({ person, onHasPhone }) {
  const dispatch = useDispatch();
  const { form } = useReduxForm();
  const phones = useFormValue('phones');
  const hasPhones = !phones?.length > 0;

  const handleOpenWhatsapp = (phone) => () => {
    console.log(phone);
    const url = whatsappSendUrl(phone.number, '', phone.ddi);

    window.open(url, '_blank');
  };

  const handleChange = useCallback(
    (fieldName, value) => {
      dispatch(change(form, fieldName, value));
    },
    [form, phones]
  );

  return (
    <Field
      canRemove
      label="Telefones"
      addText="Adicionar Telefone"
      name="phones"
      noDataText="Você ainda não cadastrou nenhum telefone"
      sort={({ is_primary }) => {
        if (is_primary) return -1;
        return 0;
      }}
      renderText={({ number, description }) => {
        let text = number;

        if (description) {
          text += ` (${description})`;
        }

        return text;
      }}
      renderActions={(cellPhone) => (
        <>
          <div
            className="h-pointer h-color--success"
            onClick={handleOpenWhatsapp(cellPhone)}
          >
            <FaWhatsapp />
          </div>
        </>
      )}
      formComponent={FormPhone}
      handleRemove={(phone, { actions, currentIndex, handleClose }) => {
        const items = actions.remove(currentIndex);

        if (phone.is_primary && items.length > 0) {
          dispatch(
            change(form, 'phones[0]', { ...items[0], is_primary: true })
          );
        }

        handleClose();
      }}
      onSubmit={async (phone, dispatch, { currentIndex, actions }) => {
        const {
          person: currentPerson,
          has_phone,
          is_valid,
        } = await peopleService.hasPhones({
          number: phone?.number,
          people_id: person?.id,
          iso: phone?.iso,
          ddi: phone?.ddi,
        });

        if (!is_valid) {
          throw new SubmissionError({ number: 'Número invalido' });
        }

        if (has_phone) {
          if (onHasPhone)
            onHasPhone({
              person: currentPerson,
              has_phone,
              is_valid,
            });
          throw new SubmissionError({ number: 'Telefone já existe' });
        }

        let _phones = phones || [];

        // Verifica se o telefone é primário
        // Se for remove todos os outros de primário
        if (phone.is_primary && !isEmpty(_phones)) {
          _phones = _phones.map((phone) => ({ ...phone, is_primary: false }));
          actions.change(_phones);
        }

        // Quando está atualizando o telefone
        if (currentIndex !== undefined) {
          if (!phone.is_primary && hasPhones) {
            // Marca o primeiro telefone da lista como primário
            // Caso não tenha nenhum outro
            handleChange(`phones[0]`, { ...phones[0], is_primary: true });
          }

          handleChange(`phones[${currentIndex}]`, phone);
        } else {
          // se o telefone for primário
          // deixa ele na primeira posição
          if (phone.is_primary) {
            // Deixa o telefone na primeira posição
            actions.change([phone, ..._phones]);
          } else {
            actions.change([..._phones, phone]);
          }
        }
        return phone;
      }}
      component={ListArray}
    />
  );
}
