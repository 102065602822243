import React from 'react';
import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
import LayoutChooser from '../LayoutChooser';
import Can from 'containers/Can';
import { USER_PERMISSIONS } from 'constants/rules';
import { useDispatch } from 'react-redux';
import { openModalAddDeal } from 'modules/modal';
import { MdAdd } from 'react-icons/md';

// import PropTypes from 'prop-types';

function Header({ onAddDeal }) {
  const dispatch = useDispatch();

  return (
    <MainTitle
      title="Negócios"
      text="Acompanhe o progresso do seu cliente pelas etapas do Funil."
    >
      <div className="h-flex">
        <Button
          color="secondary"
          onClick={() => {
            dispatch(
              openModalAddDeal({
                onSuccess: () => {
                  onAddDeal();
                }
              })
            );
          }}
        >
          <MdAdd /> Adicionar negócio ao CRM
        </Button>
        <Can run={USER_PERMISSIONS.EDIT_FUNNEL}>
          <Button to={`/crm/funnel`} color="primary">
            Configurar Funil
          </Button>
        </Can>
        <LayoutChooser />
      </div>
    </MainTitle>
  );
}

Header.defaultProps = {};
Header.propTypes = {};

export default Header;
