import React, { useMemo } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import { DragIcon } from 'pages/Site/pages/ContentSite/pages/Posts/components/TablePosts/styles';
import { plural } from 'lib/text';
import { MdDelete, MdInfo } from 'react-icons/md';
// import { Wrapper } from './styles';

const Handle = styled.div`
  cursor: grab;
  margin-top: -5px;
  margin-right: 4px;
`;

const WrapInfos = styled.div`
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  font-size: 12px;
  color: #757575;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">
    <DragIcon />
  </Handle>
));

const Icon = ({ data }) => {
  if (data.crms_count > 0) {
    return (
      <MdInfo
        style={{ marginTop: '-3px' }}
        data-tip="Esta é a etapa que o negócio é aberto definida pelo fluxo padrão."
      />
    );
  }

  if (data.default_properties_count > 0) {
    return (
      <MdInfo
        style={{ marginTop: '-3px' }}
        data-tip={plural(
          data.default_properties_count,
          'Existe 1 imóvel que aponta para esta etapa/funil.',
          `Existem ${data.default_properties_count} imóveis que apontam para esta etapa/funil.`,
          false
        )}
      />
    );
  }

  return null;
};

function TableRow({
  data,
  handleClickUpdate,
  handleClickTransfer,
  handleClickRemove,
}) {
  const stagnantAfter = useMemo(() => {
    if (data.stagnated_after === '0' || data.stagnated_after === 0)
      return 'No mesmo dia';
    return plural(data.stagnated_after, 'Dia', 'Dias');
  }, [data]);

  const peopleCount = useMemo(() => {
    try {
      if (!data?.people_count || data?.people_count === 0) return '--';

      return data?.people_count?.toString().padStart(2, '0');
    } catch {
      return '--';
    }
  }, [data?.people_count]);

  return (
    <tr key={data.id + data.file_url} className="Table__row--sort">
      <td className="Table__cell--small">
        <RowHandler />
      </td>
      <td>
        <div>
          {data.name} <Icon data={data} />
        </div>
        {data.description && <Description>{data.description}</Description>}
      </td>
      <td>{stagnantAfter}</td>
      <td>{peopleCount}</td>
      <td className="Table__cell--small">
        <a
          href="#"
          className="h-link h-margin-right--10"
          onClick={handleClickUpdate(data)}
        >
          Editar etapa
        </a>
        <a
          href="#"
          className="h-link h-margin-right--10"
          onClick={handleClickTransfer(data)}
        >
          Migrar dados
        </a>
        <a
          className="h-color--primary"
          href="#"
          onClick={handleClickRemove(data)}
        >
          <MdDelete />
        </a>
      </td>
    </tr>
  );
}

export default TableRow;
