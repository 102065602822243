import api from 'services';
import _ from 'lodash';
import * as propertiesService from 'services/properties';
// Services
import { responseMessage } from 'lib/service-helpers';
import { SubmissionError } from 'redux-form';

export const getList = (params) =>
  api.getList('settings/portals', {
    filter: {
      without_zap: true,
    },
    include: 'portal_real_estate,portal_model',
    sort_by_portal_real_estate: true,
    sort: 'name',
    ...params,
  });

export const getPortalOptions = async (portalOptions, values) => {
  try {
    // Pega os ids dos portal option
    const portalOptionsId = portalOptions.map(({ id }) => id);

    // Cria um buffer de promises
    let promises = [];

    portalOptionsId.forEach((id) => {
      promises.push(
        propertiesService.getList({
          filter: {
            ...values,
            // Adiciona o filtro por portal_option_id
            by_portal_option_id: id,
          },
        })
      );
    });

    return {
      data: [],
    };
  } catch (err) {
    return { data: [] };
  }
};

export const getFacebook = async () => {
  try {
    const res = await getList();
    return res?.data?.find((portal) => portal.name === 'Facebook');
  } catch (err) {
    return null;
  }
};

export const getOne = (id, params) =>
  api.getOne('settings/portals', id, params).then((res) => {
    res.data = {
      ...res.data,
      portal_id: res.data.id,
    };

    return res;
  });

export const getListConnectedPortals = (params) =>
  api.getList('settings/portals-real-estates', params);

const filterEmptyQuantity = (option) => {
  return !(option.quantity === 0 && option.properties_count === 0);
};

export const getListConnectedPortalsSelect = async (params) => {
  const response = await getListConnectedPortals(params);

  response.data = response.data.map((portal) => {
    return {
      ...portal,
      name: portal.portal.name,
      options: portal.options.filter(filterEmptyQuantity),
    };
  });

  return response;
};

export const getOneRelation = (portalRealEstateId, params) =>
  api
    .getOne('settings/portals-real-estates', portalRealEstateId, params)
    .then((res) => {
      res.data = {
        ...res.data,
        portal_id: res.data.id,
        portal_real_estate_id: portalRealEstateId,
      };
      return res;
    });

export const formatData = (data) => ({
  ...data,
  options: _.map(data.options),
});

export const create = (data) =>
  api
    .create('settings/portals-real-estates', formatData(data))
    .then(responseMessage('Cadastrado com sucesso'));

export const update = ({ id, portal_real_estate_id, ...data }) =>
  api
    .update(
      `settings/portals-real-estates/${portal_real_estate_id}`,
      formatData(data)
    )
    .then(responseMessage('Atualizado com sucesso'));

export const addPortal = (values) =>
  api
    .create('settings/portals', values)
    .then(responseMessage('Portal adicionado'));

export const deletePortal = (id) =>
  api
    .delete('settings/portals', id)
    .then(responseMessage('Portal removido!'))
    .catch(responseMessage('Erro ao remover portal'));

/**
 * Desativa um portal_real_estate
 * @param portalRealEstateId
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const disableIntegration = (portalRealEstateId) =>
  api
    .delete(`settings/portals-real-estates/${portalRealEstateId}`)
    .then(responseMessage('Desconectado'));

/**
 * Desmarca todos os imóveis do portal
 * @param portalRealEstateId
 */
export const uncheckAll = async ({ portalRealEstateId }) => {
  try {
    return api.create('settings/portals-options-properties/sync', {
      portal_real_estate_id: portalRealEstateId,
    });
  } catch {
    return {};
  }
};

/**
 * Desmarca todos os imóveis do portal
 * @param portalRealEstateId
 * @param portalOptionId
 */
export const uncheckAllByPortalId = async ({
  portalRealEstateId,
  portalOptionId,
}) => {
  try {
    return api.create('settings/portals-options-properties/delete', {
      portal_real_estate_id: portalRealEstateId,
      portal_real_estate_option_id: portalOptionId,
    });
  } catch {
    return {};
  }
};

/**
 * Desmarca qualquer opção do portal
 * @param portalRealEstateId
 * @param propertyId
 * @returns {Promise<AxiosResponse<T>>|{}}
 */
export const uncheckOne = ({ portalRealEstateId, propertyId }) => {
  try {
    return api.create('settings/portals-options-properties/delete', {
      portal_real_estate_id: portalRealEstateId,
      property_id: propertyId,
    });
  } catch {
    return {};
  }
};

/**
 * Adiciona um imóvel
 * @param portalRealEstateId
 * @param portalRealEstateOptionId
 * @param propertiesId
 * @param filter
 */
export const addProperties = async ({
  portalRealEstateId,
  portalRealEstateOptionId,
  propertiesId,
  filter,
}) => {
  try {
    let payload = {
      portal_real_estate_id: portalRealEstateId,
      portal_real_estate_option_id: portalRealEstateOptionId,
    };

    // Se não tiver nenhuma desses parametros
    // deve retornar um erro
    if (!filter && !propertiesId) {
      throw new SubmissionError({ option: 'Você deve selecionar uma opção' });
    }

    if (filter) payload.filter = JSON.parse(JSON.stringify(filter));

    if (payload?.filter?.by_portal_option_id === 'is_portal') {
      payload.filter.by_portal_option_id = null;
      payload.filter.doesnt_have_portal_real_estate_id = portalRealEstateId;
    }

    if (propertiesId) {
      payload = {
        ...payload,
        properties_id: propertiesId,
      };
    }

    return api.create('settings/portals-options-properties/sync', payload);
  } catch (e) {
    throw e;
  }
};

export const getPropertiesByPortalId = async (portalOptionId) => {
  try {
    const response = await api.get(
      'settings/portals-options-properties/properties',
      {
        portal_real_estate_option_id: portalOptionId,
      }
    );

    response.data = response.data.map(({ id }) => id);

    return response;
  } catch {
    return { data: [] };
  }
};
