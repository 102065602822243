import React from 'react';
import Box from '../Box';
import styled from 'styled-components';

export const LeadsContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LeadsNumber = styled.span`
  font-size: 122px;
  color: #0056b3;
`;

function Count({ totalCount }) {
  return (
    <Box
      title="Leads gerados no período"
      style={{
        display: 'flex',
        flex: '1',
        minHeight: '250px',
      }}
    >
      <LeadsContent>
        <LeadsNumber>{totalCount?.toString()?.padStart(2, '0')}</LeadsNumber>
      </LeadsContent>
    </Box>
  );
}

export default Count;
