export const transformDescription = (description) => {
  if (!description) return null;
  return description.split('\n');
};

const getLogoUrl = (logo, logos) => {
  try {
    return logos[logo].file_url;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const transformValues = (values, logos) => {
  values.description = transformDescription(values.description);

  values.logoUrl = getLogoUrl(values.logo, logos);

  return values;
};

export const characteristicsToDescription = (property) => {
  if (!property?.characteristics) return '';
  return '';
};
