import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const DealChart = ({ width, height, data, colors, toggleChecked }) => (
  <div style={{ width, height }}>
    <ResponsiveContainer>
      <BarChart
        width={600}
        height={300}
        data={data}
        margin={{ left: 25, right: 25 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="type" allowDuplicatedCategory={false} />
        <YAxis dataKey="value" />
        <Tooltip cursor={{ fill: '#f0f2f5' }} />
        {data.map((dat, index) => (
          <Bar
            dataKey="value"
            data={dat.data}
            name={dat.channel}
            key={dat.channel}
            fill={dat.color}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default DealChart;
