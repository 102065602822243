import api from './index';

/**
 * Categorias que podem ser filtradas do historico
 * @param logeable_id
 * @returns {Promise<AxiosResponse<never>>}
 */
export const getCategories = async ({ logeable_id }) => {
  try {
    const res = await api.get('stories/categories', {
      filter: {
        logeable_id,
      },
    });

    res.data = [{ id: 'all', name: 'Tudo' }, ...res.data];

    return res;
  } catch (err) {
    throw err;
  }
};

/**
 * Historico
 * @param logeable_id
 * @param category
 * @returns {Promise<AxiosResponse<never>>}
 */
export const getHistory = async ({ logeable_id, category }) => {
  try {
    return api.get('stories', {
      filter: {
        logeable_id,
        category: category !== 'all' ? category : null,
      },
    });
  } catch (err) {
    throw err;
  }
};
