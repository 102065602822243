import React from 'react';
import classnames from 'classnames';
import { MdDoNotDisturbAlt } from 'react-icons/md';
// Helpers
import PropertiesList from 'containers/PropertyDashboard/containers/PropertiesList';
import { getPropertiesExclusivityExpired } from 'services/dashboards/properties';

export default function PanelPropertyExclusivityExpired({
  updatedTime,
  updateTime
}) {
  return (
    <PropertiesList
      key="exclusivity-expired"
      title="Imóveis com exclusividade vencendo"
      icon={MdDoNotDisturbAlt}
      color="danger"
      renderNoData={() => <>Nenhum contrato de exclusividade para renovar</>}
      updatedTime={updatedTime}
      request={getPropertiesExclusivityExpired}
      limit={3}
      afterClose={updateTime}
      isDanger={property => property.is_exclusivity_expired}
      renderText={property => {
        const isExpired = property.is_exclusivity_expired;

        return (
          <p>
            <span
              className={classnames({
                'h-color--danger': isExpired
              })}
            >
              {isExpired ? 'Venceu em' : 'Vence em'}: {property.exclusive_until}
            </span>
          </p>
        );
      }}
    />
  );
}
