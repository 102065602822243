import React from 'react';
import Menu from '../../components/Menu';
import MenuItem from '../../components/MenuItem';
import { MdShare, MdPerson, MdEmail, MdAttachMoney, MdSettings } from 'react-icons/md';
import Can from 'containers/Can';
import { COLORS } from 'components/Menus/constants';

const MenuPeople = () => {
  return (
    <Menu title="Ajustes" canBack>
      <MenuItem
        color={COLORS.purple}
        icon={MdSettings}
        to="/config"
        title="Geral"
        text="Configurações gerais"
      />
      <MenuItem
        icon={MdPerson}
        to="/users"
        title="Corretores/Usuários"
        text="Cadastre novos usuários"
      />
      <MenuItem
        icon={MdShare}
        color={COLORS.yellow}
        to="/portals"
        title="Portais Imobiliários"
        text="Conecte-se com portais"
      />
      <Can run="EDIT_MESSAGE">
        <MenuItem
          color={COLORS.blue}
          icon={MdEmail}
          to="/emails"
          title="Contas de e-mail"
          text="Crie contas de e-mail"
        />
      </Can>
      <MenuItem
        color={COLORS.green}
        icon={MdAttachMoney}
        to="/plan/history"
        title="Financeiro"
        text="Seu plano e pagamentos"
      />
    </Menu>
  );
};

export default MenuPeople;
