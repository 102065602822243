import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
// Services
import * as searchesService from 'services/sites/searches';
// Components
import FormSearch from '../../components/FormSearch';
import Loading from 'components/Loading';
// Modules
import { fetchFields, handleFormSubmit, searchSelector, toggleStatusField } from '../../module';

import { fetchSearches, searchesSelectors } from 'modules/components/searches';

class FormContainer extends React.Component {
  static propTypes = {
    form: PropTypes.string
  };

  static defaultProps = {
    form: 'FormSearchDesktop'
  };

  state = {
    initialValues: {},
    isFetching: true
  };

  /**
   * Pega os valores iniciais do formulário
   * @returns {{device: string}}
   */
  get initialValues() {
    const { initialValues } = this.state;
    const { device } = this.props;

    return {
      ...initialValues,
      device // Adiciona o device nos valores iniciais
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  /**
   * Seta os valores no formulário
   * @param data
   */
  setValues = data => this.setState({ initialValues: data });

  /**
   * Busca form data
   * @param device
   * @returns {Promise<any>}
   */
  fetchData = async () => {
    const { device } = this.props;

    // Atualiza a lista de campos
    this.props.fetchFields({ device });

    // Busca os components
    await this.props.fetchSearches({ device });

    searchesService
      .getList({ device })
      .then(res => {
        this.setState({
          initialValues: {
            ...res.data,
            search_type: res.data.search_type,
            has_subtype_filter: res.data.has_subtype_filter
          }
        });
      })
      .then(() => {
        this.setState({ isFetching: false });
      });
  };

  handleSubmit = values => {
    const { device } = this.props;
    return this.props.handleFormSubmit({ ...values, device });
  };

  handleSubmitSuccess = (result, dispatch) => {
    // Reinicializa o formulário com os valores
    dispatch(
      initialize(
        this.props.form,
        {
          ...result.data,
          device: this.props.device
        },
        false
      )
    );

    this.setState(result.data);
  };

  handleStatusChange = menu => {
    this.props.toggleStatusField(menu, this.props.device);
  };

  render() {
    const { form, fields, components } = this.props;
    const { isFetching } = this.state;

    if (isFetching) return <Loading isVisible />;

    return (
      <FormSearch
        fields={fields}
        components={components}
        form={form}
        onSubmit={this.handleSubmit}
        onSubmitSuccess={this.handleSubmitSuccess}
        initialValues={this.initialValues}
        handleStatusChange={this.handleStatusChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  fields: searchSelector.getAll(state),
  components: searchesSelectors.getAll(state)
});

const mapDispatchToProps = {
  handleFormSubmit,
  fetchFields,
  fetchSearches,
  toggleStatusField
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
