import styled from 'styled-components';

export const WrapperCredit = styled.div`
  margin-top: -10px;

  header {
    margin-bottom: 5px;
  }
`;

export const Details = styled.div`
  text-align: right;

  p {
    color: ${p => p.theme.colors.primary};
    margin: 8px 0;
  }
`;
