import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import html2canvas from 'html2canvas';
import { withRouter } from 'react-router-dom';
// Components
import MainTitle from 'components/MainTitle';
import Container from 'components/Container';
import FormCreateLogo from './components/FormCreateLogo';
import Wrapper from 'components/Wrapper';
// Container
import { TYPES } from 'containers/UploadLogo';
// Services
import * as logosService from 'services/sites/logos';
// Modules
import { receiveUser } from 'modules/login';
// Constants
import LOGOS from 'containers/LogosSelector/data';

const canvasToBlob = (canvas) =>
  new Promise((resolve) => {
    canvas.toBlob((blob) => resolve(blob));
  });

class LogoCreate extends React.Component {
  constructor(props) {
    super(props);
    this.logo = React.createRef();
  }

  get initialValues() {
    return { texts: LOGOS[0].texts };
  }

  /**
   * Pega os parametros
   * @returns { device, type }
   */
  get params() {
    try {
      return this.props.match.params;
    } catch {
      return {};
    }
  }

  uploadImageByType = (blob) => {
    const { device, type } = this.params;
    return logosService.uploadLogo(type, device, blob, true);
  };

  onSubmit = async (values) => {
    // Transforma o HTML da div da logo em um canvas
    const canvas = await html2canvas(this.logo.current, {
      backgroundColor: null,
      scale: 7,
    });

    // Transforma um canvas em imagem
    return canvasToBlob(canvas).then(this.uploadImageByType);
  };

  updateRealEstateLogo = (realEstate) => {
    this.props.receiveUser({
      ...localStorage.getItem('current-user'),
      real_estate: realEstate,
    });
  };

  onSubmitSuccess = (res) => {
    const { push } = this.props.history;
    const { type } = this.params;

    switch (type) {
      case TYPES.HEADER:
      case TYPES.FOOTER:
      case TYPES.WHATSAPP:
        push('/site/layout/style/logo');
        break;
      case TYPES.REAL_ESTATE: {
        this.updateRealEstateLogo(res.data);
        push('/config/general');
        break;
      }
      case TYPES.WATERMARK:
        push('/site/layout/style/watermark');
        break;
      default:
        throw new Error('Tipo invalido');
    }
  };

  render() {
    return (
      <Wrapper>
        <MainTitle
          title="Logo"
          text="Defina como o seu logo aparecerá no topo do seu site, no rodapé, e nas suas redes sociais"
        />
        <Container>
          <FormCreateLogo
            logoRef={this.logo}
            initialValues={this.initialValues}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
          />
        </Container>
      </Wrapper>
    );
  }
}

export default compose(withRouter, connect(null, { receiveUser }))(LogoCreate);
