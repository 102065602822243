import React from 'react';
import { MdArrowForward } from 'react-icons/md';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Modules
import { openModalVideo } from 'modules/modal';
// Components
import YoutubeImage from 'components/YoutubeImage';
import Button from 'components/Button';

const propTypes = {
  videoUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  onClickAction: PropTypes.func,
};

const defaultProps = {
  videoUrl: null,
  title: null,
  children: null,
  onClickAction: null,
};

const WizardVideo = ({
  onClickAction,
  children,
  title,
  videoUrl,
  openModalVideo,
}) => {
  return (
    <div className="WizardVideoItem">
      {title && <h4 className="WizardVideoItem__title">{title}</h4>}
      {videoUrl && (
        <YoutubeImage
          videoUrl={videoUrl}
          onClick={() => {
            openModalVideo({
              title,
              videoUrl,
              renderAction: ({ status, handleClose }) => {
                const isEnded = status === 'ended';

                return (
                  <span style={{ position: 'relative' }}>
                    {isEnded && (
                      <MdArrowForward
                        className="h-color--danger h-animation-arrow-right"
                        style={{
                          verticalAlign: 'middle',
                          position: 'absolute',
                          right: '100%',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          fontSize: '40px',
                          zIndex: 9,
                        }}
                      />
                    )}
                    <Button
                      color="success"
                      onClick={(e) => {
                        onClickAction(e);
                        handleClose();
                      }}
                    >
                      Fazer
                    </Button>
                  </span>
                );
              },
            });
          }}
          style={{ margin: '15px 0' }}
        />
      )}
      <footer className="WizardVideoItem__footer">{children}</footer>
    </div>
  );
};

WizardVideo.propTypes = propTypes;
WizardVideo.defaultProps = defaultProps;

const mapDispatchToProps = {
  openModalVideo,
};

export default connect(null, mapDispatchToProps)(WizardVideo);
