import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useEffect, useContext, useCallback, useMemo } from 'react';
import { Context } from '../contexts/id';
import { useAside } from 'pages/PropertyStore/hooks/useAside';
import { useRouteMatch } from 'react-router';

export function withId(Component) {
  return props => {
    let { path: basePath } = useRouteMatch();
    const { setId } = useContext(Context);
    const { id } = useParams();
    const { enableAll, hide, show, permissions } = useAside();
    const history = useHistory();
    const location = useLocation();

    const isEditting = useMemo(() => basePath?.includes('update'), [basePath]);
    const BASE = useMemo(() => {
      if (isEditting) {
        return `/properties/update/`;
      }
      return `/properties/store/`;
    }, [isEditting]);

    const DEFAULT_PAGE = useMemo(() => {
      return `${BASE}informations`;
    }, [BASE]);

    const next = useCallback(
      (pageName, idParam) => {
        history.push(`${BASE}${pageName}/${id || idParam}`);
      },
      [id, BASE]
    );

    useEffect(() => {
      if (!id) {
        // Verifica se é a página default
        if (location.pathname !== DEFAULT_PAGE) {
          // Se não tiver id e não for a página default redireciona para a pagina default
          history.push(`${location.pathname}${DEFAULT_PAGE}`);
        }
      } else {
        // Seta o id do context
        setId(id);

        // Habilita todas as páginas por que já tem ID
        enableAll();
      }
    }, [id, location, history, enableAll]);

    return (
      <Component
        permissions={permissions}
        pathname={location.pathname}
        history={history}
        id={id}
        setId={setId}
        enableAll={enableAll}
        next={next}
        show={show}
        hide={hide}
        isEditting={isEditting}
        {...props}
      />
    );
  };
}
