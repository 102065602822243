import { parseValueSelect } from 'lib/formHelpers';
import React from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
// Components
import FormBox from 'components/FormBox';
import GroupControl, { GroupItem } from 'components/GroupControl';
import { Input, Select } from 'components/Form';

const propTypes = {};
const defaultProps = {};

//const renderField = ({ fields, measures }) => {
//  return fields.map((name, index, fields) => {
//    const { title, measures } = fields.get(index);
//
//    return (
//      <Col key={`measures-${name}`} xs={4}>
//        <GroupControl label={`${title} (Mín. e Máx)`}>
//          <GroupItem>
//            <Field
//              prefix=""
//              valueFormat
//              name={`${name}.minimum`}
//              component={Input}
//              placeholder="Indiferente"
//            />
//          </GroupItem>
//          <GroupItem divider size={5} />
//          <GroupItem>
//            <Field
//              prefix=""
//              valueFormat
//              name={`${name}.maximum`}
//              component={Input}
//              placeholder="Indiferente"
//            />
//          </GroupItem>
//          <GroupItem size="small">
//            <Field
//              autoWidth
//              name={`${name}.measure`}
//              placeholder=""
//              labelKey="label"
//              valueKey="value"
//              options={measures.map(measure => ({
//                label: measure,
//                value: measure,
//              }))}
//              component={Select}
//              parse={parseValueSelect('value')}
//            />
//          </GroupItem>
//        </GroupControl>
//      </Col>
//    )
//  })
//}

const Measures = ({ areas, change }) => {
  return (
    <FormBox title="Medidas">
      <Row>
        {areas &&
          areas.map(({ name, title, measures }) => {
            return (
              <Col key={`areas-${name}`} xs={4}>
                <GroupControl label={`${title} (Mín. e Máx)`}>
                  <Field type="hidden" name="name" component="input" />
                  <GroupItem>
                    <Field
                      prefix=""
                      valueFormat
                      name={`areas.${name}.minimum`}
                      component={Input}
                      placeholder="Indiferente"
                    />
                  </GroupItem>
                  <GroupItem divider size={5} />
                  <GroupItem>
                    <Field
                      prefix=""
                      valueFormat
                      name={`areas.${name}.maximum`}
                      component={Input}
                      placeholder="Indiferente"
                    />
                  </GroupItem>
                  <GroupItem size="small">
                    <Field
                      autoWidth
                      name={`areas.${name}.measure`}
                      placeholder=""
                      labelKey="label"
                      valueKey="value"
                      options={measures.map(m => ({
                        label: m,
                        value: m
                      }))}
                      component={Select}
                      parse={parseValueSelect('value')}
                    />
                  </GroupItem>
                </GroupControl>
              </Col>
            );
          })}
      </Row>
    </FormBox>
  );
};

Measures.propTypes = propTypes;
Measures.defaultProps = defaultProps;

export default Measures;
