import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  p {
    color: #646979;
    font-size: 12px;
  }
`;

function PropertyDetails({ property }) {
  console.log('property: ', property);

  return (
    <Wrapper>
      <p>
        {property?.reference} - {property.title_formatted} -{' '}
        {property.profileName} - {property?.situation?.title}
      </p>
      <p>{property.address_formatted}</p>
    </Wrapper>
  );
}

export default PropertyDetails;
