import React, { useCallback } from 'react';
import {
  Actions,
  Content,
  Header,
  InnerContainer,
  Title,
  Wrapper,
} from './styles';

function Layout({ title, renderActions, children, renderContent }) {
  const _renderActions = useCallback(() => {
    if (typeof renderActions === 'function') {
      return renderActions();
    }
    return renderActions;
  }, [renderActions]);

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>

        {_renderActions && <Actions>{_renderActions()}</Actions>}
      </Header>
      {renderContent ? renderContent() : <Content>{children}</Content>}
    </Wrapper>
  );
}

export { InnerContainer };

export default Layout;
