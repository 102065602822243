import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
// Components
import { Input } from 'components/Form';
import Button from 'components/Button';
import Label from 'components/Label';
// Moment
import moment from 'lib/moment';
// Helpers
import { normalizeDate } from 'lib/formHelpers';
import { isValidDate } from 'lib/date-helpers';

// Validate
const LabelPop = ({ children, ...props }) => (
  <Label {...props} type="button" className="h-margin-right--5">
    {children}
  </Label>
);

class FormRenew extends React.Component {
  static defaultProps = {};
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  };

  getDate() {
    const { renew_date } = this.props;
    return isValidDate(renew_date) ? renew_date : moment().format('DD/MM/YYYY');
  }

  /**
   * Adiciona dias no input
   * @param days
   * @return {Function}
   */
  handleAddDays = days => () => {
    const { change } = this.props;
    const date = this.getDate();

    change(
      'renew_date',
      moment(date, 'DD/MM/YYYY')
        .add(days, 'days')
        .format('DD/MM/YYYY')
    );
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Row
        tagName="form"
        onClick={e => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <Field
          xs={12}
          label="Renovar até quando"
          name="renew_date"
          component={Input}
          normalize={normalizeDate}
        />
        <Col xs={12} className="h-margin-bottom--10">
          <LabelPop handleClick={this.handleAddDays(15)}>+15</LabelPop>
          <LabelPop handleClick={this.handleAddDays(30)}>+30</LabelPop>
          <LabelPop handleClick={this.handleAddDays(60)}>+60</LabelPop>
          dias
        </Col>
        <Col xs={12}>
          <Button type="submit" block color="success">
            Renovar
          </Button>
        </Col>
      </Row>
    );
  }
}

// Selector do formulário
const selector = formValueSelector('FormRenew');

// Wrapper do formulário de renovação
const WrapperFormRenew = reduxForm({
  form: 'FormRenew',
  enableReinitialize: true
})(FormRenew);

export default connect(state => ({
  renew_date: selector(state, 'renew_date')
}))(WrapperFormRenew);
