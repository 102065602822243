import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MdClose } from 'react-icons/md';

class Alert extends Component {
  static defaultProps = {
    isOpen: true,
    children: null,
    color: 'success',
    handleClose: null
  };

  static propTypes = {
    color: PropTypes.oneOf([
      'success',
      'tertiary',
      'info',
      'danger',
      'caution',
      'default',
      'primary',
      'secondary'
    ]),
    isOpen: PropTypes.bool,
    children: PropTypes.node,
    handleClose: PropTypes.func
  };

  render() {
    const {
      className,
      isOpen,
      color,
      children,
      handleClose,
      html,
      onClick,
      ...rest
    } = this.props;

    const alertClassName = classnames(
      'Alert',
      className,
      {
        [`Alert--${color}`]: color,
        'h-pointer': !!onClick
      },
      'h-flex',
      'h-flex--center-center'
    );

    let propsDiv = {
      className: 'h-flex__cell h-flex__cell--grow'
    };

    if (!!html) {
      propsDiv = {
        ...propsDiv,
        dangerouslySetInnerHTML: { __html: html }
      };
    }

    if (!isOpen) return null;

    return (
      <div
        {...rest}
        onClick={onClick}
        tabIndex={-1}
        role="button"
        className={alertClassName}
      >
        <div {...propsDiv}>{children}</div>
        <div />
        {handleClose && (
          <div
            className="h-pointer h-flex__cell--shrink"
            onClick={e => {
              e.stopPropagation();

              if (handleClose) handleClose(e);
            }}
          >
            <MdClose />
          </div>
        )}
      </div>
    );
  }
}

export default Alert;
