import React from 'react';
import styled from 'styled-components';
import { reduxForm } from 'redux-form';

const Wrapper = styled.form`
  display: inline-block;
`;

function Form(props) {
  return (
    <Wrapper onSubmit={props.handleSubmit}>{props.children(props)}</Wrapper>
  );
}

export default reduxForm({
  enableReinitialize: true
})(Form);
