import { Select } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { Field } from 'redux-form';
import * as portalsService from 'services/settings/portals';

function FieldPortal({
  label = 'Selecione o portal',
  onLoadOptions,
  options,
  ...props
}) {
  const { data, isFetching, fetchData } = useRequest({
    request: portalsService.getListConnectedPortalsSelect,
    onSuccess: ({ data }) => {
      if (onLoadOptions) onLoadOptions(data);
    },
    initialFetch: false,
  });

  useEffect(() => {
    if (!options) {
      fetchData().then(({ data }) => {
        if (onLoadOptions) onLoadOptions(data);
      });
    }
  }, [fetchData, options]);

  return (
    <Field
      {...props}
      label={label}
      isLoading={isFetching}
      name="portal_id"
      component={Select}
      options={options || data}
      valueKey="id"
      labelKey="name"
      placeholder=""
    />
  );
}

export default FieldPortal;
