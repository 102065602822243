import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import _ from 'lodash';

import { Col, Row } from 'react-flexbox-grid';
import { Input, Toggle } from 'components/Form';
// Components
import FormBox from 'components/FormBox';

const defaultProps = {
  fields: {}
};

const propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  isVisible: PropTypes.bool
};

const positiveNumber = value => {
  return value >= 0 && value;
};

const normalFields = is_boolean => ({
  xs: 12,
  sm: 6,
  md: 2,
  type: 'number',
  normalize: positiveNumber,
  component: is_boolean ? Toggle : Input,
  isVisible: false
});

const Rooms = ({ fields, isVisible }) =>
  isVisible && (
    <FormBox className="js-rooms" title="Comodos">
      <Row>
        {fields.map(({ id, title, name, extra, is_boolean }) => (
          <>
            <Field
              {...normalFields(is_boolean)}
              key={`rooms-${id}-${name}`}
              label={title}
              name={`rooms.${name}.value`}
            />

            {extra &&
              _.map(extra, ({ title: fieldTitle, name: fieldName }) => (
                <Col xs={0} key={`${fieldName}-${name}`}>
                  <Field
                    name={`rooms.${name}.extra.${fieldName}.value`}
                    label={fieldTitle}
                    component={Toggle}
                  />
                </Col>
              ))}
          </>
        ))}
      </Row>
    </FormBox>
  );

Rooms.defaultProps = defaultProps;
Rooms.propTypes = propTypes;

export default Rooms;
