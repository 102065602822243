import FixedBar from 'components/FixedBar';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useQuery } from 'hooks/useQuery';
import { plural } from 'lib/text';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import { useDispatch } from 'react-redux';
import * as portalsService from 'services/settings/portals';

// const LinkWithIcon = ({ icon, iconColor, title, text, onClick }) => (
//   <Item>
//     <button onClick={onClick}>
//       <div className="h-flex h-flex--center-center">
//         <IconBall type="rounded" size="large" color={iconColor}>
//           {icon()}
//         </IconBall>
//         <div className="h-margin-left--15">
//           <h5>{title}</h5>
//           <p>{text}</p>
//         </div>
//       </div>
//     </button>
//   </Item>
// );

function RemoveSelecteds() {
  const params = useQuery({ withParams: true });
  const dispatch = useDispatch();
  const { fetchProperties } = usePortalOptions();

  const {
    selectedProperties,
    selectedPortal,
    fetchPortals,
    clearSelectedProperties,
  } = usePortalOptions();

  const remove = () => {
    dispatch(
      openConfirmation({
        title: `Excluir selecionados`,
        text: `Deseja remover ${plural(
          selectedProperties?.length,
          'imóvel',
          'imóveis'
        )}`,
        request: () =>
          portalsService.uncheckOne({
            portalRealEstateId: selectedPortal.id,
            propertyId: selectedProperties,
          }),
        submitButtonColor: 'danger',
        submitButtonText: `Excluir`,
        onSuccess: () => {
          fetchPortals();
          fetchProperties(params);
          clearSelectedProperties();
        },
      })
    );
  };

  return (
    <FixedBar.item>
      <FixedBar.link tagName="button" onClick={remove}>
        Excluir
      </FixedBar.link>
    </FixedBar.item>
  );
}

export default RemoveSelecteds;
