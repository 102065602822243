const RECEIVING_METHODS = {
  1: 'Boleto',
  2: 'Cartão',
  3: 'Bonus',
  4: 'Pix',
};

const PLANS = {
  1: 'Mensal',
  3: 'Trimestral',
  6: 'Semestral',
  12: 'Anual',
};

export const getReceivingMethod = (receivingMethod) => {
  try {
    return RECEIVING_METHODS[receivingMethod];
  } catch {
    return '-';
  }
};

export const getPlan = (plan) => {
  try {
    return PLANS[plan];
  } catch {
    return '-';
  }
};
