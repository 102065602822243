import { Info, Infos, Main, NewPrice, Prices, Title, Wrapper } from './styles';
import { Field } from 'redux-form';

const PlanItem = ({ label, text, value, input, price }) => {
  const checked = value === input.value;

  function handleSelect() {
    console.log('input: ', input);
    console.log('value: ', value);
    input.onChange(value);
  }

  return (
    <Wrapper active={checked} onClick={handleSelect}>
      <Main>
        <Infos>
          <Title>{label}</Title>
          <Info>{text}</Info>
        </Infos>
      </Main>

      <Prices>
        <NewPrice>{price}</NewPrice>
      </Prices>
    </Wrapper>
  );
};

const FieldPlanItem = ({ label, text, value, price }) => (
  <Field
    name="plan"
    label={label}
    text={text}
    props={{ value }}
    price={price}
    component={PlanItem}
  />
);

export default FieldPlanItem;
