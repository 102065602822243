import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
// Components
import Ball from 'components/Ball';
import ListOptions from 'components/ListOptions';
import whatsapp from '../../img/whatsapp.png';

const propTypes = {};
const defaultProps = {};

const Reception = () => (
  <ListOptions.item>
    <Row>
      <Col xs={7}>
        <div style={{ marginTop: '10px', marginBottom: '30px' }}>
          <h5 className="h-margin-bottom--5">
            <Ball animation={false} color="success" /> Não é anônimo
          </h5>
          <p>
            Seu cliente irá fazer contato pelo próprio WhatsApp. A partir daí,
            você terá informações importantes que não teria num chat
            convencional.
          </p>
        </div>

        <div style={{ marginBottom: '30px' }}>
          <h5 className="h-margin-bottom--5">
            <Ball animation={false} color="success" /> Praticidade
          </h5>
          <p>
            Por estar utilizando o WhatsApp, tanto você quanto o seu cliente
            podem retomar a conversa com facilidade pelo aplicativo.
          </p>
        </div>

        <div>
          <h5 className="h-margin-bottom--5">
            <Ball animation={false} color="success" /> Histórico
          </h5>
          <p>
            Saiba tudo o que você conversou com o seu cliente meses atrás sem
            dificuldades acessando seu histórico do WhatsApp.
          </p>
        </div>
      </Col>
      <Col xs={5} className="h-flex" style={{ justifyContent: 'flex-end' }}>
        <img src={whatsapp} alt="" />
      </Col>
    </Row>
  </ListOptions.item>
);

Reception.propTypes = propTypes;
Reception.defaultProps = defaultProps;

export default Reception;
