import _ from 'lodash';

/**
 * Altera o nome de uma chave do objeto
 * @param obj - o objeto aonde vai ser procurado o array
 * @param from - chave do objeto aonde vai ser buscado a informacao
 * @param to - para qual chave vai a informação
 * @return {{}}
 */
export const replaceObjectKey = (obj, from, to) => {
  const newValues = { ...obj };
  let value = null;

  if (obj[from]) value = newValues[from];

  // remove a chave
  delete newValues[from];

  return { ...newValues, [to]: value };
};

export const isEmpty = (obj = {}) => _.isEmpty(obj);

/**
 * Pega um objeto pelo indice
 * @param obj
 * @param index
 */
export const getObjectByIndex = (obj, index) => obj[Object.keys(obj)[index]];

/**
 * Retorna a primeira chave de um objeto
 * @param obj
 * @return {*}
 */
export const getFirstObject = obj => getObjectByIndex(obj, 0);

/**
 * Pega todos os itens dentro de um objeto que estão checados
 * @param data|Object
 * @param checked|String
 * @param identifier|String
 * @return Object
 */
export const getChecked = (data, checked = 'isChecked', identifier = 'id') =>
  _(data)
    .map((item, key) => ({
      [identifier]: key,
      ...item
    }))
    .filter(checked)
    .value();

export const objToArray = (data, key = 'id') => {
  return data
    ? Object.keys(data).reduce((arr, value) => {
        // pega o item dentro do objeto
        const current = data[value];

        return [
          ...arr,
          {
            [`${key}`]: value,
            ...current
          }
        ];
      }, [])
    : [];
};
