import { useEffect, useState } from 'react';

const KEY_BANNER = '@tecimob/chrome-extension-banner';

export const useChromeExtension = () => {
  const [canShowBanner, setCanShowBanner] = useState(true);

  const handleOnClose = ({ not_show_again }) => {
    setCanShowBanner(false);

    if (not_show_again) {
      localStorage.setItem(KEY_BANNER, true);
    } else {
      sessionStorage.setItem(KEY_BANNER, true);
    }
  };

  useEffect(() => {
    const sessionBanner = sessionStorage.getItem(KEY_BANNER);
    const localBanner = localStorage.getItem(KEY_BANNER);

    if (sessionBanner === true || localBanner === true) {
      setCanShowBanner(false);
    }
  }, []);

  return { canShowBanner, handleOnClose };
};
