import styled from 'styled-components';

export const StagesWrapper = styled.div`
  display: inline-block;
  margin: 0 5px;

  .Label + .Label {
    margin-left: 5px;
  }
`;
