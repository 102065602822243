// Sevices
import * as crmService from 'services/settings/crm';
import Alert from 'react-s-alert';
// Modules
import dataReducer from 'modules/data';

export const {
  reducer: crmConfigReducer,
  selectors: crmConfigSelector,
  actionCreators: crmConfigActions
} = dataReducer(`site/crmConfig`);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(crmConfigActions.request());

  return crmService.getConfig().then(({ data }) => {
    dispatch(crmConfigActions.receive(data));
    return data;
  });
};

export const handleSubmit = values => dispatch => {
  dispatch(crmConfigActions.request());

  return crmService.update(values).then(({ data }) => {
    dispatch(crmConfigActions.receive(data));
    Alert.success('Configurações Salvas');
    return data;
  });
};

export default crmConfigReducer;
