import React, { Component } from 'react';
import { compose } from 'redux';
// Components
import Button from 'components/Button';
import NoContent from 'components/NoContent';
import DefaultTextsForm from './components/DefaultTextsForm';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
// Services
import * as messagesService from 'services/settings/messages';
// HOC
import withReception from 'HOC/withReception';
// Assets
import documents from 'assets/img/noData/documents.svg';
import { withPermission } from 'HOC/withPermission';

class DefaultTexts extends Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    initialValues: {},
    isFetching: true
  };

  componentDidMount() {
    this.fetchTexts();
  }

  fetchTexts = params => {
    this.setState({ isFetching: true });

    return messagesService
      .getOne(params)
      .then(({ data }) => {
        this.setState({ initialValues: data });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  };

  onSubmit = values =>
    messagesService.update(values).then(({ data }) => {
      this.setState({ initialValues: data });
      return data;
    });

  render() {
    const { initialValues, isFetching } = this.state;
    const { reception, handleClickAction } = this.props;

    if (reception.isVisible) {
      return (
        <Wrapper.container>
          <NoContent
            image={documents}
            title="Textos Padrão"
            text="São os textos padrão do sistemas, encontrados em determinados pontos de usos do painel. Personalize-os e agilize sua rotina!"
          >
            <Button
              type="button"
              color="secondary"
              colorText="white"
              onClick={handleClickAction}
            >
              Entendi
            </Button>
          </NoContent>
        </Wrapper.container>
      );
    }

    return (
      <Wrapper.container>
        <MainTitle
          title="Textos"
          text="Edite os textos padronizados do sistema de acordo com sua preferência e agilize o seu dia a dia."
        />
        <DefaultTextsForm
          isFetching={isFetching}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
        />
      </Wrapper.container>
    );
  }
}

export default compose(
  withPermission({
    rules: ['EDIT_MESSAGE'],
    isPage: true
  }),
  withReception('config/texts', true)
)(DefaultTexts);
