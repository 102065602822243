import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import { renderTextItem } from 'containers/LogoCreator';
import DATA from './data';
// Helpers
import { loadAvaliableFonts } from './helpers';

class LogosSelector extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func
  };

  static defaultProps = {
    onSelect: null
  };

  state = {
    selected: 0
  };

  componentDidMount() {
    // Carrega todas as fontes que são necessárias
    // para renderizar todos os tipos de logos
    loadAvaliableFonts();
  }

  onSelect = index => () => {
    if (this.props.onSelect) this.props.onSelect(DATA[index]);

    // Seleciona o item na lista
    this.setState({ selected: index });

    return index;
  };

  render() {
    const { selected } = this.state;

    return (
      <Row className="LogosSelector">
        {DATA.map((data, i) => {
          const isSelected = selected === i;

          if (i !== 0 && i % 4 === 0) {
          }

          return (
            <>
              {i % 4 === 0 && <div className="LogosSelector__divider" />}

              <Col
                xs={3}
                onClick={this.onSelect(i)}
                className="LogosSelector__item h-text-center"
              >
                <img src={data.image} alt="" className="h-image-center" />
                <div>{data.texts.map(renderTextItem)}</div>
                <Button
                  size="medium"
                  color={isSelected ? 'success' : 'primary'}
                >
                  {isSelected ? 'Selecionado' : 'Selecionar'}
                </Button>
              </Col>
            </>
          );
        })}
      </Row>
    );
  }
}

export default LogosSelector;
