import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
// Components
import { FieldBool, InputDate } from 'components/Form';
import ListOptions from 'components/ListOptions';
// Selector
import { selector } from '../../index';
import useReduxForm from 'hooks/useReduxForm';

function FieldCalendar() {
  const dispatch = useDispatch();
  const { change } = useReduxForm();
  const canCreateCalendar = useSelector((state) =>
    selector(state, 'should_create_calendar')
  );

  const clearField = () => {
    dispatch(change('calendar_start_date', ''));
  };

  return (
    <ListOptions.item
      title="Agendar retorno?"
      renderOptions={() => (
        <>
          <FieldBool
            name="should_create_calendar"
            onChange={(e, value) => {
              clearField();
            }}
          />
          <Field
            autoComplete={false}
            showTimeSelect
            disabled={!canCreateCalendar}
            minDate={Date.now()}
            name="calendar_start_date"
            placeholder="Digite a data e"
            component={InputDate}
          />
        </>
      )}
    />
  );
}

export default FieldCalendar;
