import Socket from 'lib/Socket';

export default class Backup extends Socket {
  constructor(accessToken) {
    super(accessToken);

    // Conecta no canal de notificações
    this.connect('events');
  }

  onBackupFinished(callBack) {
    this.on('backupFinished', callBack);
  }

  onBackupFailed(callBack) {
    this.on('backupFailed', callBack);
  }
}
