import React from 'react';
import { MdAttachMoney } from 'react-icons/md';
// Helpers
import PropertiesList from 'containers/PropertyDashboard/containers/PropertiesList';
import moment, { format } from 'lib/moment';
import { getPropertiesDeal } from 'services/dashboards/properties';

export default function PanelPropertyBooked({ updatedTime, updateTime }) {
  return (
    <PropertiesList
      key="property-deal"
      title="Últimos imóveis negociados"
      icon={MdAttachMoney}
      color="caution"
      renderNoData={() => <>Nenhum imóvel reservado até o momento</>}
      updatedTime={updatedTime}
      request={getPropertiesDeal}
      limit={5}
      afterClose={updateTime}
      renderText={(deal) => {
        const earnedAt = moment(deal.earned_at, 'YYYY-MM-DD HH:mm:ss').format(
          format.date
        );
        return <p>Atualizado: {earnedAt}</p>;
      }}
    />
  );
}
