import styled from 'styled-components';

const color = ({ theme: { color, colors } }) => {
  return colors[color];
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${color};
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: ${p => p.theme.colors.primary};
`;

export const Content = styled.div`
  padding: 15px;
  text-align: center;
`;

export const Number = styled.span`
  position: relative;
  display: inline-block;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 4px;
`;
export const Title = styled.h1`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const WrapperIcon = styled.div`
  position: absolute;
  bottom: 5px;
  right: -8px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 14px;
  background: ${color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

export const Action = styled.div`
  display: block;
  padding: 8px 10px;
  text-align: center;
  border-top: 1px solid #d7e0eb;
  color: #0063c0;
  cursor: pointer;
`;
