import React from 'react';
import PropTypes from 'prop-types';
import Check from '../../../../../../components/Check';
import { Legend } from '../../../styles';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.array
  })
};

const defaultProps = {
  data: {}
};

const calcTotal = (total, current) => total + current.value;

const PeopleItem = ({
  toggleChecked,
  data,
  data: { isSelected, type, data: periods, color }
}) => (
  <tr role="button" className="h-hover h-pointer" onClick={toggleChecked(data)}>
    <td className="h-sticky--left h-text-nowrap h-flex h-flex--center-center">
      <Legend color={color} />
      <Check checked={isSelected} className="h-margin-right--15" />
      {type}
    </td>
    {periods.map((period, index) => (
      <td key={`values-${index}-${period.value}`}>{period.value}</td>
    ))}
    <td className="h-sticky--right">{periods.reduce(calcTotal, 0)}</td>
  </tr>
);

PeopleItem.propTypes = propTypes;
PeopleItem.defaultProps = defaultProps;

export default PeopleItem;
