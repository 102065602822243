import React, { useEffect, useState } from 'react';
import TermsOfUse from './components/TermsOfUse';
import ModalForm from './components/ModalForm';

function ModalPleno(props) {
  const [isTermsAccepted, setTerms] = useState(false);

  const handleAcceptTerms = () => {
    setTerms(true);
  };

  useEffect(() => {
    setTerms(localStorage.getItem('@tecimob/terms-pleno'));
  }, []);

  return isTermsAccepted ? (
    <ModalForm {...props} />
  ) : (
    <TermsOfUse {...props} onAcceptTerms={handleAcceptTerms} />
  );
}

export default ModalPleno;
