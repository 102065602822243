import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
// Components
import FormBox from 'components/FormBox';
import Checkbox from 'components/Checkbox';

const propTypes = {};
const defaultProps = {};

const Characteristics = ({ name = 'characteristics', isCondo, data, type }) => (
  <FormBox
    title={
      isCondo
        ? 'Características do condomínio'
        : `Características - ${type.title}`
    }
  >
    <Row>
      {data &&
        data.map((characteristic) => (
          <Col key={characteristic.id} xs={3} className="ListCheck__item">
            <Field
              id={characteristic.id}
              type="checkbox"
              name={`${name}.${characteristic.id}`}
              component={Checkbox}
              parse={(value) => {
                if (!value) return null;
                return characteristic;
              }}
            >
              {characteristic.title}
            </Field>
          </Col>
        ))}
    </Row>
  </FormBox>
);

Characteristics.propTypes = propTypes;
Characteristics.defaultProps = defaultProps;

export default Characteristics;
