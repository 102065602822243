import Button from 'components/Button';
import Container, { Header } from 'components/Container';
import Nodata from 'components/Nodata';
import Table from '../Table';
import { openModalFeaturedGallery } from 'modules/modal';
import { useDispatch } from 'react-redux';

function ListFeatureds({ data, fetch, onChangeOrder, onUpdate, onRemove }) {
  const dispatch = useDispatch();

  const handleOpenModal =
    ({ id }) =>
    () => {
      dispatch(
        openModalFeaturedGallery({
          id,
          onSuccess: fetch,
        })
      );
    };

  if (data?.length <= 0) {
    return (
      <Container padding style={{ marginTop: '25px' }}>
        <Nodata
          image={null}
          title="Nenhuma galeria"
          text="Adicione sua primeira galeria"
        >
          <Button
            color="secondary"
            className="h-margin-top--15"
            onClick={handleOpenModal({})}
          >
            Adicionar Galeria
          </Button>
        </Nodata>
      </Container>
    );
  }

  return (
    <>
      <Container padding style={{ marginTop: '25px' }}>
        <Header>
          <h4>Galerias de imóveis</h4>
          <div>
            <Button color="secondary" onClick={handleOpenModal({})}>
              Adicionar Galeria
            </Button>
          </div>
        </Header>
        <Table
          data={data.featured_lists}
          handleChangeOrder={onChangeOrder}
          handleClickUpdate={handleOpenModal}
          handleClickRemove={onRemove}
        />
      </Container>
    </>
  );
}

export default ListFeatureds;
