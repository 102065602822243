import React from 'react';
import { Row } from 'react-flexbox-grid';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { Textarea } from 'components/Form';
import { ModalTemplate, ModalFooter } from 'components/Modal';
import Button from 'components/Button';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function Form({ handleSubmit, submitting, handleClose }) {
  return (
    <form onSubmit={handleSubmit}>
      <ModalTemplate
        title="Obrigado pela sua resposta!"
        handleClose={handleClose}
      >
        <p>
          Para entendermos melhor sua nota, diga no campo abaixo o que podemos
          fazer para lhe atender cada vez melhor:
        </p>

        <Row className="h-margin-top--15">
          <Field
            type="simple"
            xs={12}
            label="Sugestão"
            name="sugestion"
            component={Textarea}
          />
        </Row>
        <ModalFooter style={{ marginTop: 0 }}>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disable={submitting}>
            Enviar
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </form>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

export default reduxForm({
  form: 'FormSatisfaction',
  enableReinitialize: true
})(Form);
