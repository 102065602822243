import _ from 'lodash';
import { createModule } from '../lib/reducer-helpers';

const createAction = createModule('pagination');

// Actions
const REGISTER_PAGINATION = createAction('REGISTER_PAGINATION');
const UNREGISTER_PAGINATION = createAction('UNREGISTER_PAGINATION');
const SET_PAGINATION = createAction('SET_PAGINATION');

// Paginação
export const getPagination = (state, name) => state.pagination[name] || {};

const initialState = {
  limit: 10,
  offset: 1,
  total_pages: 0,
  has_more: false
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case REGISTER_PAGINATION:
      return {
        ...state,
        [action.name]: {
          ...initialState,
          ...action.payload
        }
      };
    case UNREGISTER_PAGINATION:
      return _.omit(state, action.name);
    case SET_PAGINATION:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          ...action.payload
        }
      };
    default:
      return state;
  }
}

// Action Creators

// Registra uma paginação
export const registerPagination = (name, payload) => ({
  type: REGISTER_PAGINATION,
  name,
  payload
});
export const unregisterPagination = (name, payload) => ({
  type: UNREGISTER_PAGINATION,
  name,
  payload
});

/**
 * Seta a paginacao
 * @param name
 * @param payload
 */
export const setPagination = (name, payload) => ({
  type: SET_PAGINATION,
  name,
  payload
});
