import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import { openModal } from 'modules/modal';
import { useTexts } from './hooks/useTexts';
import { useStoreCategories } from '../../hooks/useCategories';
import FormStoreCategory from './components/FormStoreCategory';
import { useMemo } from 'react';

export const openModalStoreCategory =
  ({ category, data, isEditing, isCategory, isDebit, onSuccess, onClose }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalStoreCategory',
        size: 290,
        position: 'center',
        props: {
          category,
          data,
          isEditing,
          isDebit,
          isCategory,
          onSuccess,
          onClose,
        },
      })
    );
  };

export function ModalStoreCategory({
  modalConfig,
  isOpen,
  handleClose: _handleClose,
  modalType,

  data,
  category,
  isEditing = false,
  isDebit,
  isCategory,
  onSuccess,
  onClose,
}) {
  const texts = useTexts({ data, category, isEditing, isCategory });

  const { storeCategory } = useStoreCategories({
    isDebit,
    isEditing,
  });

  const handleClose = () => {
    _handleClose();

    if (onClose) onClose();
  };

  const initialValues = useMemo(() => {
    if (!isEditing && !data) {
      return {
        title: '',
        color: '#FFFFFF',
      };
    }

    return data;
  }, [data, isEditing]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title={texts.titleModal} handleClose={handleClose}>
        <FormStoreCategory
          initialValues={initialValues}
          texts={texts}
          handleClose={handleClose}
          onSubmit={storeCategory}
          onSubmitSuccess={(res) => {
            if (onSuccess) onSuccess(res);
            handleClose();
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}
