import React from 'react';
import { Row } from 'react-flexbox-grid';
import { reduxForm } from 'redux-form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
// Services
import * as people from 'services/people';
import Loading from 'components/Loading';
import FieldPeopleGroups from 'containers/FieldPeopleGroups';

// import { Container } from './styles';

const Form = ({ handleSubmit, data, onCancel, handleClose, submitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      {submitting && <Loading isVisible isFullScreen />}
      {data.people_count > 0 ? (
        <>
          Esta categoria está ligada a {data.people_count} clientes.
          <br />
          Defina para qual categoria deseja transferi-los:
          <Row className="h-margin-top--15">
            <FieldPeopleGroups
              xs={12}
              label="Selecione a categoria"
              name="group_id"
              excludeIds={[data?.id]}
            />
          </Row>
        </>
      ) : (
        <p>Você tem certeza que deseja remover essa categoria?</p>
      )}

      <ModalFooter>
        <Button
          color="white"
          colorText="secondary"
          type="button"
          onClick={() => {
            handleClose();
            if (onCancel) onCancel();
          }}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button disabled={submitting} type="submit" color="danger">
          Remover
        </Button>
      </ModalFooter>
    </form>
  );
};

export default reduxForm({
  form: 'ModalGroupRemove',
  validate: values => {
    let errors = {};

    // Se tiver pessoas para deletar
    // Fica marcado como obrigatório o campo de group_id
    if (values?.people_count > 0 && !values?.group_id) {
      errors.group_id = ['Campo obrigatório'];
    }

    return errors;
  },
  onSubmit: async values => {
    // Se existir pessoas
    // tem que transferir o grupo pra outro lugar
    if (values?.people_count) {
      await people.groupTransfer(values?.id, values?.group_id);
    }

    return people.removeGroup(values?.id);
  },
  onSubmitSuccess: (response, dispatch, props) => {
    props.handleClose();

    // Verifica se tem a propriedade onSuccess
    if (props?.onSuccess) props?.onSuccess(response);
  }
})(Form);
