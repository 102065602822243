import Dropdown, { Item as DropItem, List } from 'components/Dropdown';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useCallback } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TiPin, TiPinOutline } from 'react-icons/ti';
import { useDispatch } from 'react-redux';
import * as notesService from 'services/crm/notes';
import { remove } from 'services/crm/notes';
import PersonBar from '../PersonBar';
import PropertyBar from '../PropertyBar';
import {
  Action,
  Actions,
  Ball,
  Content,
  Footer,
  Inner,
  Text,
  UserInfo,
  Wrapper,
} from './styles';
import Label from 'components/Label';

const Item = ({
  data: {
    note,
    user: { name },
    created_at,
    is_pinned,
    property,
    people,
    stage,
    can_edit,
    category,
  },
  data,
  onUpdate,
  onClickEdit,
  onRemoveNote,
  afterClose,
}) => {
  const dispatch = useDispatch();

  const handleClickEdit = useCallback(
    (e) => {
      if (onClickEdit) onClickEdit(data)(e);
    },
    [data]
  );

  const onClickPin = useCallback(async () => {
    await notesService.updatePin({ id: data?.id, is_pinned: !data?.is_pinned });
    if (onUpdate) onUpdate();
  }, [data]);

  const handleClickRemove = useCallback(
    async (e) => {
      dispatch(
        openConfirmation({
          title: 'Remover nota?',
          text: (
            <>
              Se você realmente deseja remover essa nota clique em{' '}
              <strong>Remover</strong>
            </>
          ),
          request: () => remove(data.id),
          onSuccess: () => {
            if (onRemoveNote) onRemoveNote();
            if (onUpdate) onUpdate();
          },
        })
      );
    },
    [data]
  );

  return (
    <Wrapper>
      <Inner>
        <Content>
          <Text
            dangerouslySetInnerHTML={{
              __html: note,
            }}
          />
          <Footer>
            <UserInfo>
              {created_at} - {name}
            </UserInfo>
            {category ? <Label>{category.name}</Label> : null}
          </Footer>
        </Content>
        <Actions>
          <Action>
            <Ball onClick={() => onClickPin(data)}>
              {is_pinned ? <TiPin /> : <TiPinOutline />}
            </Ball>
          </Action>
          {can_edit && (
            <Action>
              <Dropdown position="right" isDropHover>
                <Ball>
                  <MdKeyboardArrowDown />
                </Ball>
                <List>
                  <DropItem>
                    <button onClick={handleClickEdit}>Editar</button>
                  </DropItem>
                  <DropItem>
                    <button onClick={handleClickRemove}>Remover</button>
                  </DropItem>
                </List>
              </Dropdown>
            </Action>
          )}
        </Actions>
      </Inner>

      {!!property && (
        <PropertyBar
          property={property}
          stage={stage}
          afterClose={afterClose}
        />
      )}
      {!!people && (
        <PersonBar person={people} stage={stage} afterClose={afterClose} />
      )}
    </Wrapper>
  );
};

export default Item;
