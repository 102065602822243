// import styles from './styles'
import Box from '../../components/Box';
import { STORAGE_KEY } from 'containers/CreciAlert/constants';
import { MdErrorOutline } from 'react-icons/md';
import { useState } from 'react';
import { Screens } from '../../screens';

export default function Fail() {
  const [validate, setValidate] = useState(false);

  if (validate) {
    return <Screens.FormValidation />;
  }

  return (
    <Box
      canClose
      color="#F84343"
      storageKey={STORAGE_KEY}
      label={{
        icon: <MdErrorOutline />,
        text: 'Falha na verificação',
      }}
    >
      <p>
        O processo de verificação do seu CRECI falhou. Reinicie o processo de
        validação{' '}
        <a
          href=""
          className="h-link"
          onClick={(e) => {
            e.preventDefault();
            setValidate(true);
          }}
        >
          clicando aqui
        </a>
      </p>
      <p>
        Passa saber mais informações sobre a falha{' '}
        <a href="" className="h-link">
          clique aqui
        </a>
      </p>
    </Box>
  );
}
