import React from 'react';
import Tooltip from 'react-tooltip';
// Components
import ListOptions from 'components/ListOptions';
import { FieldBool } from 'components/Form';

const propTypes = {};
const defaultProps = {};

class FormSite extends React.Component {
  componentDidMount() {
    Tooltip.rebuild();
  }

  render() {
    const { change } = this.props;

    return (
      <ListOptions
        style={{
          width: 'calc(100% + 40px)',
          margin: '-20px 0 0 -20px',
          borderBottom: '1px solid #DEDEDE'
        }}
      >
        <ListOptions.item
          title="Mostrar Imóvel no site?"
          text="Determine se o imóvel será publicado em seu site."
          renderOptions={() => (
            <FieldBool
              name="is_published"
              onChange={() => {
                change('is_featured', false);
                change('is_network_published', false);
              }}
            />
          )}
        />
        <ListOptions.item
          title="Destaque?"
          text="Defina se esse imóvel aparecerá na sessão de ‘imóveis em destaque’ na página inicial do seu site."
          renderOptions={() => (
            <FieldBool
              name="is_featured"
              onChange={() => {
                change('is_published', true);
              }}
            />
          )}
        />
      </ListOptions>
    );
  }
}

FormSite.propTypes = propTypes;
FormSite.defaultProps = defaultProps;

export default FormSite;
