import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1048px;
  margin: 0 auto;

  .Check {
    font-size: 25px;
  }

  .Table tr th,
  .Table tr td {
    padding: 10px;
  }

  .Table tr td {
    cursor: pointer;
  }

  .has-problem > td {
    background: #f4d0d3 !important;
  }

  .Table tr th {
    white-space: nowrap;
  }

  .Table tbody tr:nth-child(even) td {
    background: #e9eef5;
  }
`;

export const getColorTransaction = ({ theme, transaction }) => {
  if (transaction === 1 || transaction === '1') {
    return css`
      color: ${theme.colors.success};
    `;
  }

  return css`
    color: ${theme.colors.caution};
  `;
};

export const Transaction = styled.div`
  font-weight: bold;
  color: #000;
`;

export const Rooms = styled.div`
  display: flex;
  justify-content: flex-start;

  > div {
    flex-shrink: 0;
    min-width: 50px;
  }

  svg {
    font-size: 20px;
  }
`;

export const Remove = styled.button`
  font-size: 18px;
  cursor: pointer;
`;
