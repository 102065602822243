import React, { memo } from 'react';
// Hooks
import { useDetectRefresh } from 'hooks/useDetectRefresh';

function DetectRefresh({ when, message }) {
  useDetectRefresh({
    when,
    message
  });

  return <></>;
}

DetectRefresh.defaultProps = {
  message: 'Deseja realmente atualizar a página?'
};

export default memo(DetectRefresh);
