import { openConfirmation } from "containers/ModalConfirmation/module";
import { uploadFiles } from "lib/reducer-helpers";
import { Actions as UploadActions } from 'modules/fileUpload';
import { createContext, useCallback, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { arrayMove } from "react-sortable-hoc";
import * as locationService from 'services/location';

export const GalleryContext = createContext({});

export const GalleryProvider = ({ children, data, type, setData, fetchData }) => {
  const dispatch = useDispatch();

  const lastOrder = useMemo(() => {
    try {
      return data[data.length - 1].order;
    } catch {
      return 0;
    }
  }, [data]);

  const removeImage = useCallback(
    ({ id }) => () => {
      dispatch(
        openConfirmation({
          title: 'Deseja remover essa imagem?',
          request: () => type === 'city'
            ? locationService.removeCityDescriptionImage(id)
            : locationService.removeNeighborhoodDescriptionImage(id),
          onSuccess: () => fetchData(),
        }),
      );
    },
    [type]
  );

  const uploadImages = useCallback(
    ({ files, ...params }) => {
      // Coloca ordem em todos os arquivos
      const _filesWithOrder = files?.map((file, index) => {
        return {
          file,
          order: index
        };
      });

      return uploadFiles({
        request: type === 'city' ? locationService.addImageCityDescription : locationService.addImageNeighborhoodDescription,
        params: ({ file, order }) => {
          return {
            image: file,
            name: file.name,
            order: lastOrder + order + 1,
            ...params,
          };
        },
        files: _filesWithOrder,
        onUploadStart: () => {
          dispatch(UploadActions.uploadStart());

          _filesWithOrder.forEach(({ file }) => {
            dispatch(UploadActions.addFile(file));
          });
        },
        onUploadSuccess: (data, upload, { file }) => {
          return dispatch(UploadActions.uploadSuccess(file));
        },
        onUploadFail: (data, { file }) =>
          dispatch(UploadActions.uploadError(file)),
        onUploadEnd: async () => {
          await fetchData();
          dispatch(UploadActions.uploadEnd());
          dispatch(UploadActions.reset());
        }
      });
    },
    [lastOrder]
  );

  const handleSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setData(items => {
      const newItems = arrayMove(items, oldIndex, newIndex);

      // pega a imagem para ser modificada
      const image = newItems.find(
        (_, index) => [newIndex].indexOf(index) >= 0
      );

      if (type === 'city') {
        locationService.alterImageCityDescription({ ...image, order: newIndex + 1 });
      } else {
        locationService.alterImageNeighborhoodDescription({ ...image, order: newIndex + 1 });
      }

      return newItems;
    });
  }, []);

  const setLoadingImage = useCallback(
    (id, value) => {
      setData(images => {
        // Pega a imagem
        return images.map(image => {
          if (image.id === id) {
            return { ...image, isLoading: value };
          }
          return image;
        });
      });
    },
    [data]
  );

  return (
    <GalleryContext.Provider
      value={{
        data,
        fetchData,
        type,
        removeImage,
        uploadImages,
        handleSortEnd,
        setLoadingImage,
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
}

export const useGallery = () => useContext(GalleryContext);
