import * as PropTypes from 'prop-types';
import { MdReportProblem } from 'react-icons/md';
import { useDispatch } from 'react-redux';
// Modules
import { openModalCreci } from 'modules/modal';
// Components
import Alert from 'components/Alert';
// Helpers
import { plural } from 'lib/text';

function AlertCreci({ userId, status, isOpen, days, onClose }) {
  const dispatch = useDispatch();

  if (days > 6) return null;

  function handleClickCreci() {
    openModalCreci({ userId })(dispatch);
  }

  const alertProps = {
    isOpen,
    handleClose: onClose,
    style: { marginTop: '25px', marginBottom: '15px' },
  };

  if (status === 4 || status === 5) {
    return (
      <Alert {...alertProps} color="danger">
        Seu CRECI está bloqueado.{' '}
        <span className="h-link" onClick={handleClickCreci}>
          Clique aqui
        </span>{' '}
        e envie seus documentos para verificá-lo.
      </Alert>
    );
  }

  // if (status === 5) {
  //   return (
  //     <Alert {...alertProps} color="danger">
  //       Seu CRECI está bloqueado.{' '}
  //       <Link to="help/1/list?openModal=true" className="h-link">
  //         Clique aqui
  //       </Link>{' '}
  //       e entre em contato com o suporte.
  //     </Alert>
  //   );
  // }

  if (!days) return null;

  return (
    <Alert {...alertProps} color="info">
      <MdReportProblem style={{ marginRight: '10px' }} />
      Você tem {plural(days, 'dia restante', 'dias restantes')} para verificar o
      seu CRECI.{' '}
      <span className="h-link" onClick={handleClickCreci}>
        Clique aqui
      </span>{' '}
      para enviar seus documentos e verificá-lo.
    </Alert>
  );
}

AlertCreci.defaultProps = {
  userId: null,
  isOpen: null,
  days: null,
  onClose: null,
};

AlertCreci.propTypes = {
  userId: PropTypes.string,
  isOpen: PropTypes.bool,
  days: PropTypes.number,
  onClose: PropTypes.func,
};

export default AlertCreci;
