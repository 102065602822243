import React from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
// Components
import Button from 'components/Button';
import Wrapper from 'components/Wrapper';
import NoContent from 'components/NoContent';
// HOC
import withReception from 'HOC/withReception';
// Modules
import { currentUserSelector } from 'modules/login';
import heart from 'assets/img/receptions/heart.png';

function Reception({ reception: { isVisible }, handleClickAction }) {
  const currentUser = useSelector(currentUserSelector);

  if (!isVisible || currentUser?.real_estate?.is_paying)
    return <Redirect to="/dashboard" />;

  return (
    <Wrapper.inner>
      <Wrapper.container>
        <NoContent
          size={610}
          title="Bem-vindo(a)!"
          text={
            <>
              Agora você tem em mãos uma ferramenta com tudo o que precisa para
              vender mais. Durante os próximos <strong>7 dias</strong>, você
              poderá testar todos os recursos gratuitamente. <br />
              Aproveite ao máximo!
            </>
          }
          image={heart}
        >
          <Button
            type="button"
            color="secondary"
            colorText="white"
            onClick={handleClickAction}
          >
            Começar Agora
          </Button>
        </NoContent>
      </Wrapper.container>
    </Wrapper.inner>
  );
}

export default compose(
  withRouter,
  withReception('dashboard/welcome-v4', true)
)(Reception);
