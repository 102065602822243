import React from 'react';
import { SelectMultiple } from 'components/Form';
import { Field } from 'redux-form';
import { parseSelect } from 'lib/formHelpers';

function FieldSituations({ name = 'situation', options, excludeId, ...props }) {
  return (
    <Field
      multi={false}
      name={name}
      component={SelectMultiple}
      placeholder="Todas as situações"
      options={[
        { label: 'Pago', value: 'Pago' },
        { label: 'Aberto', value: 'Aberto' },
        { label: 'Vencido', value: 'Vencido' },
      ]}
      parse={parseSelect('value')}
      {...props}
    />
  );
}

export { FieldSituations };
