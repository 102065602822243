import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import PropertyItem from 'components/PropertyItem';
import { MdDelete } from 'react-icons/md';

export default SortableElement(
  ({
    canDelete,
    handleClickRemove,
    className,
    property,
    isFocused,
    ...props
  }) => {
    return (
      <PropertyItem
        canOpenProperty={false}
        className={className}
        isFocused={isFocused}
        property={property}
        hideActions={false}
        renderAction={() => {
          if (!canDelete) return false;

          return (
            <div onClick={handleClickRemove(property.id)}>
              <MdDelete color="#ff0000" />
            </div>
          );
        }}
      />
    );
  }
);
