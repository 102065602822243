import React, { useContext } from 'react';
import { Cell, CellLabel } from '../Table/styles';
import RowDivider from '../RowDivider';
import { List } from './styles';
import { ContextCompare } from 'containers/ModalRoot/ModalPropertyCompare/index';

const RowPrimaryRooms = () => {
  const { properties } = useContext(ContextCompare);

  return (
    <>
      <RowDivider />
      <tr>
        <CellLabel rowSpan={2}>Proximidades</CellLabel>
        {properties.map(({ id, establishments }) => {
          return (
            <Cell key={`establishments-${id}`}>
              <List>
                {establishments && establishments.length > 0
                  ? establishments.map(({ id: idEstablishment, title }) => {
                      return <li key={`${id}-${idEstablishment}`}>{title}</li>;
                    })
                  : '--'}
              </List>
            </Cell>
          );
        })}
      </tr>
    </>
  );
};

export default RowPrimaryRooms;
