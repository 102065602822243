import createDataReducer from 'modules/data';
import * as userService from 'services/user';

export const {
  reducer: realtorsReducer,
  selectors: realtorsSelectors,
  actionCreators: realtorsActions
} = createDataReducer('filter/realtors');

export const fetchRealtors = params => async dispatch => {
  dispatch(realtorsActions.request());
  const { data } = await userService.getRealtors(params);
  dispatch(realtorsActions.receive(data));
};

export default realtorsReducer;
