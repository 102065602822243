import Streetview from "components/MapView/Streetview";
import { debounce } from "debounce";
import { useMap } from "../MapSection/context";
import { Wrapper } from './styles';

const STREET_VIEW_HEIGHT = 359;

function StreetView({ style }) {
  const { position, pov, handleChangePov } = useMap();

  return (
    <Wrapper>
      <div className="MapView" style={style}>
        <div className="MapView__item">
          <Streetview
            containerElement={<div style={{ height: STREET_VIEW_HEIGHT }} />}
            position={position}
            pov={pov}
            handleChangePov={handleChangePov && debounce(handleChangePov, 50)}
          />
        </div>
      </div>
    </Wrapper>
  );
}

export default StreetView;
