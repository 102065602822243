// Modules
import dataReducer from 'modules/data';
// Service
import * as analyticsService from 'services/analytics';
// Helpers
import { getViewId, transformReports } from 'lib/analytics';

const {
  reducer: analyticsNewUsersReducer,
  actionCreators: analyticsNewUsersActions
} = dataReducer(`reports/analyticsNewUsers`);

const transformResponse = ({ data: { reports } }) => {
  try {
    return transformReports(reports)[0].data.rows;
  } catch {
    return [];
  }
};

// Thunks
export const fetchData = params => dispatch => {
  dispatch(analyticsNewUsersActions.request());

  return analyticsService
    .search({
      reportRequests: [
        {
          viewId: getViewId(),
          dateRanges: [
            {
              startDate: '2018-05-18',
              endDate: '2019-09-18'
            }
          ],
          metrics: [
            {
              expression: 'ga:users'
            },
            {
              expression: 'ga:newUsers'
            }
          ],
          dimensions: [
            {
              name: 'ga:yearMonth'
            }
          ]
        }
      ]
    })
    .then(transformResponse)
    .then(data => {
      dispatch(analyticsNewUsersActions.receive(data));
      return data;
    });
};

export default analyticsNewUsersReducer;
