import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { SelectMultiple } from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import { ModalFooter } from '../../../../../components/Modal';
import { parseSelect } from '../../../../../lib/formHelpers';
import { Checkbox } from 'components/Form';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const normalizeGroupedData = (data, stage) =>
  data
    .filter(({ id }) => id !== stage.id)
    .map(({ id, name }) => ({
      id,
      name,
    }));

const FormDealDelete = ({
  currentStage,
  stageData,
  funnelsAndStages,
  handleSubmit,
  handleClose,
  submitting,
}) => {
  const canRemove =
    stageData.crms_count === 0 &&
    stageData.deals_count === 0 &&
    stageData.default_properties_count === 0;

  return (
    <form onSubmit={handleSubmit}>
      {stageData.deals_count > 0 && (
        <Field
          label="Mover negócios para:"
          multi={false}
          name="stage_id"
          options={funnelsAndStages}
          labelKey="name"
          valueKey="stage_id"
          component={SelectMultiple}
          valueRenderer={(group) => {
            return `${group.group.name} -> ${group.name}`;
          }}
          parse={parseSelect('stage_id')}
        />
      )}
      {stageData.default_properties_count > 0 &&
        stageData.deals_count === 0 &&
        stageData.crms_count === 0 && (
          <Field
            label="Atualizar imóveis ligados a esta etapa para o padrão do sistema"
            type="checkbox"
            name="update_default_properties"
            component={Checkbox}
          />
        )}
      <ModalFooter>
        <Button color="white" colorText="primary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button
          disabled={stageData.crms_count > 0 || submitting}
          type="submit"
          color="danger"
        >
          {stageData.deals_count > 0 ? 'Mover e apagar Etapa' : 'Apagar Etapa'}
        </Button>
      </ModalFooter>
    </form>
  );
};

FormDealDelete.defaultProps = defaultProps;
FormDealDelete.propTypes = propTypes;

export default reduxForm({
  form: 'FormDealDelete',
  enableReinitialize: true,
})(FormDealDelete);
