import styled from 'styled-components';
import CustomImage from 'components/Image';

export const Image = styled(CustomImage)`
  width: 35px;
  height: 35px;
  border-radius: 4px;
  margin-right: 10px;
`;

export const Person = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
