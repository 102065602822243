import React from 'react';
import { Wrapper, Text } from './styles';
import Label from 'components/Label';

const Recommended = ({ children }) => {
  return (
    <Wrapper>
      <Text>Recomendado:</Text>{' '}
      <Label kind="solid" color="success">
        {children}
      </Label>
    </Wrapper>
  );
};

export default Recommended;
