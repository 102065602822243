import React, { useEffect } from 'react';

// Components
import WrapperReport from '../../components/WrapperReport';
import Chart from './components/Chart';
import Table from './components/Table';

// Hooks
import { useReport } from '../hooks';

// Services
import { getRealtorsByPeopleType } from 'services/reportsPeople';

function PeopleByRealtor() {
  const {
    data,
    dataSelecteds,
    toggleAll,
    isAllSelected,
    chartData,
    fetchData,
    toggle,
    isFetching,
    meta
  } = useReport({
    request: getRealtorsByPeopleType,
    key: 'id'
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <WrapperReport
      title="Clientes por corretor"
      text="Veja quantos clientes cada corretor possui por tipo."
      data={data}
      isFetching={isFetching}
      renderChart={() => <Chart data={chartData} meta={meta} />}
      renderTable={() => (
        <Table
          toggleAll={toggleAll}
          isAllSelected={isAllSelected}
          data={dataSelecteds}
          handleToggle={toggle}
          meta={meta}
        />
      )}
    />
  );
}

export default PeopleByRealtor;
