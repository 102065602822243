import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  background-color: ${p => p.theme.colors.danger};
  color: #fff;
  padding: 5px;
`;

export const Btn = styled.button`
  text-decoration: underline;
  color: #fff;
  margin: 0 3px;
  border-radius: 4px;
  cursor: pointer;
`;

export const Close = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;

  svg {
    width: 27px;
    height: 27px;
    padding: 5px;
  }
`;
