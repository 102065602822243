// import * as S from './styles'

import { Table } from '../../../../../../components/Box';
import * as S from '../../styles';
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md';
import React, { useMemo, useState } from 'react';

// Helper to parse currency string and get numerical value
const parseCurrency = (value) => {
  const numericValue = parseFloat(
    value.replace('R$', '').replace(',', '.').trim()
  );
  return isNaN(numericValue) ? 0 : numericValue;
};

export function Item({ data }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const hasChildren = useMemo(() => {
    return data?.days?.length > 0;
  }, [data]);

  const toggleExpanded = () => {
    if (!hasChildren) return false;
    setIsExpanded((v) => !v);
  };

  return (
    <>
      <Table.Tr isExpanded={isExpanded} onClick={toggleExpanded}>
        <td>
          <S.Category>
            {hasChildren ? (
              <S.Arrow>
                {isExpanded ? <MdArrowDropDown /> : <MdArrowRight />}
              </S.Arrow>
            ) : (
              <S.Arrow />
            )}
            <div>{data.title}</div>
          </S.Category>
        </td>
        <td align="right">{data.credits}</td>
        <td align="right">{data.debits}</td>
        <td align="right">
          <S.Result value={parseCurrency(data.result)}>{data.result}</S.Result>
        </td>
        <td align="right">{data.balance}</td>
      </Table.Tr>
      {isExpanded ? (
        <>
          {data.days.map((day) => (
            <Table.TrItem key={day.id}>
              <td>
                <S.SubCategory>{day.title}</S.SubCategory>
              </td>
              <td align="right">{day.credits}</td>
              <td align="right">{day.debits}</td>
              <td align="right">{day.result}</td>
              <td align="right">{day.balance}</td>
            </Table.TrItem>
          ))}
          <tr height="10px">
            <td />
          </tr>
        </>
      ) : null}
    </>
  );
}
