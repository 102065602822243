import { formatter } from 'containers/ModalRoot/ModalPayment/utils/moneyFormatter';
import Amount from 'containers/ModalRoot/ModalPayment/components/Amount';
import { TableData } from 'containers/ModalRoot/ModalPayment/styles';
import { Field } from 'redux-form';
import { usePrices } from 'containers/ModalRoot/ModalPayment/hooks/usePrices';
import useFormValue from 'hooks/useFormValue';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';

const Users = () => {
  const quantity = useFormValue('quantity');
  const { preview } = useModalContractExtra();
  const [{ user_price }] = usePrices();

  return (
    <tr>
      <td className="item">
        <h5>Corretores/usuários</h5>
      </td>
      <td>
        <Field
          min={preview.users_quantity}
          name="quantity"
          component={Amount}
        />
      </td>
      <TableData align="right">R$ {formatter.format(user_price)}</TableData>
      <TableData align="right">
        R$ {formatter.format(quantity * user_price)}
      </TableData>
    </tr>
  );
};

export default Users;
