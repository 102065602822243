import React, { useContext, useCallback } from 'react';
import Avatar from 'components/Avatar';
import { Wrapper, MenuWrapper, Content, Title, Text, Action } from './styles';
import Dropdown, { DropdownContext } from 'components/MainHeader3/components/Dropdown';
import ChangeUser from './components/ChangeUser';
import { useSelector, useDispatch } from 'react-redux';
import { currentUserSelector } from 'modules/login';
import { getName } from 'lib/text';
import { UserContext } from 'components/MainHeader3/contexts/UserContext';
import { openModalUser } from 'modules/modal';

const Menu = () => {
  const dispatch = useDispatch();
  const { currentUser, canChangeUser } = useContext(UserContext);
  const { close } = useContext(DropdownContext);

  const handleClickUpdateUser = useCallback(() => {
    close();
    dispatch(openModalUser(currentUser.id));
  }, []);

  return (
    <MenuWrapper>
      {canChangeUser && <ChangeUser />}
      <Action onClick={handleClickUpdateUser}>Editar dados</Action>
    </MenuWrapper>
  );
};

const MenuUser = () => {
  const currentUser = useSelector(currentUserSelector);

  return (
    <Dropdown width={222} position="right" component={Menu}>
      <Wrapper>
        <Avatar
          hasZoom={false}
          text={currentUser.name}
          image={currentUser?.file_url}
        />
        <Content>
          <Title>{getName(currentUser.name)}</Title>
          <Text>Cliente ID: N{currentUser?.real_estate?.serial}</Text>
        </Content>
      </Wrapper>
    </Dropdown>
  );
};

export default MenuUser;
