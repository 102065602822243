import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Image from './components/Image';
import Overlay from './components/Overlay';

const propTypes = {
  property: PropTypes.object
};
const defaultProps = {
  property: {
    isChecked: false,
    isFeatured: false
  }
};

export const Title = ({ children, ...props }) => (
  <h1 {...props} className="CardProperty__title h5 h-color--primary">
    {children}
  </h1>
);

export const Text = ({ children, ...props }) => (
  <p {...props} className="CardProperty__text h-color--primary-light">
    {children}
  </p>
);

export const List = ({ children, ...props }) => (
  <ul {...props} className="CardPropertyList h-list--none">
    {children}
  </ul>
);

export const Container = ({ children, ...props }) => (
  <div {...props} className="CardProperty__details">
    {children}
  </div>
);

export const ListItem = ({ children, ...props }) => (
  <li {...props} className="CardPropertyList__item">
    {children || '--'}
  </li>
);

const Card = ({ property, children, ...props }) => (
  <div
    {...props}
    role="button"
    tabIndex={-1}
    className={classnames('CardProperty', 'CardProperty--vertical', 'h-hover', {
      'CardProperty--featured': property.isFeatured,
      'CardProperty--checked': property.isChecked,
      'CardProperty--blocked': property.is_blocked
    })}
  >
    {children}
  </div>
);

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;
Card.title = Title;
Card.text = Text;
Card.list = List;
Card.listItem = ListItem;
Card.container = Container;
Card.image = Image;

export { Image, Overlay };
export default Card;
