import React, { useMemo } from 'react';
import Layout from 'pages/Crm/components/Layout';
import { Ball, Bar, Content, Item } from './styles';
import Loading from 'components/Loading';
import { plural } from 'lib/text';
import { useDispatch, useSelector } from 'react-redux';
import { countsSelector } from 'pages/Crm/modules/asideCrm';
import TimelineTree, { DEFAULT_THEME } from 'components/TimelineTree';
import TableCardProperty from 'components/TableCardProperty';
import TableCardPropertyDetails from 'components/TableCardPropertyDetails';
import TableSort from 'components/TableSort';
import { openModalPerson, openModalProperty } from 'modules/modal';
import { useGetReportsPropertiesSent } from 'hooks/api/crm';
import Filter from './components/Filter';
import moment, { format } from 'lib/moment';
import { formValueSelector } from 'redux-form';
import TextOverflow from 'components/TextOverflow';
import _ from 'lodash';

export const selectorFormUser = formValueSelector('FormUser');

function MatchedProperties() {
  const dispatch = useDispatch();
  const { data: counts } = useSelector(countsSelector);
  const userId = useSelector((state) => selectorFormUser(state, 'user_id'));

  const {
    propertiesSentData,
    propertiesSentMeta,
    isFetchingPropertiesSent,
    fetchReportsPropertiesSent,
  } = useGetReportsPropertiesSent();

  const initialValues = {
    start_date: moment().subtract(90, 'days').format(format.date),
    end_date: moment().format(format.date),
    user_id: userId,
    clients_with_contact: 'ALL_CLIENTS',
  };

  const handleOpenProperty = (property, props) => (e) => {
    dispatch(
      openModalProperty({
        property,
        initialPage: 'Radar',
        ...props,
      })
    );
  };

  const handleOpenPerson = (person) => (e) => {
    if (person.id) {
      dispatch(openModalPerson(person));
    }
  };

  const defaultOpenItens = useMemo(() => {
    return _.range(propertiesSentData?.length);
  }, [propertiesSentData]);

  console.log(defaultOpenItens);

  return (
    <Layout
      title={
        <>
          <strong>Imóveis compartilhados</strong>
          {propertiesSentMeta.sents_count > 0 && (
            <>
              {' | '}
              {plural(
                propertiesSentMeta.sents_count,
                'compartilhamento',
                'compartilhamentos'
              )}{' '}
              |{' '}
              {plural(propertiesSentMeta.properties_count, 'imóvel', 'imóveis')}{' '}
              | {plural(propertiesSentMeta.people_count, 'cliente', 'clientes')}
            </>
          )}
        </>
      }
      renderActions={() => {
        return (
          <Filter
            userId={userId}
            initialValues={initialValues}
            onChange={(values) => {
              fetchReportsPropertiesSent({
                user_id: userId,
                ...values,
              });
            }}
          />
        );
      }}
    >
      <Content>
        {!isFetchingPropertiesSent ? (
          <TimelineTree
            defaultOpenItems={defaultOpenItens}
            theme={{
              ...DEFAULT_THEME,
              maxHeight: 'auto',
              minHeight: 'auto',
            }}
            data={propertiesSentData}
            renderItem={(item) => (
              <Item>
                <Bar />
                <TableSort
                  data={item.properties}
                  renderHeader={() => (
                    <tr>
                      <th align="left">Imóvel</th>
                      <th align="left" width={120}>
                        Detalhes do imóvel
                      </th>
                      <th align="left" width={220}>
                        Corretor
                      </th>
                      <th align="left" width={220}>
                        Clientes
                      </th>
                    </tr>
                  )}
                  renderItem={(property) => (
                    <tr key={property.id}>
                      <td
                        className="h-pointer"
                        style={{ position: 'relative' }}
                        onClick={handleOpenProperty(property)}
                      >
                        <Ball />
                        <TableCardProperty property={property} />
                      </td>
                      <td
                        className="h-pointer"
                        onClick={handleOpenProperty(property)}
                      >
                        <TableCardPropertyDetails property={property} />
                      </td>
                      <td>
                        {property.users.map((user) => (
                          <TextOverflow
                            width={175}
                            key={property?.id + user?.id}
                          >
                            {user?.name}
                          </TextOverflow>
                        ))}
                      </td>
                      <td>
                        {property.people.map((people) => (
                          <p
                            key={property?.id + people?.id}
                            className="h-link"
                            onClick={handleOpenPerson(people)}
                          >
                            <TextOverflow width={195}>
                              {people?.name}
                            </TextOverflow>
                          </p>
                        ))}
                      </td>
                    </tr>
                  )}
                />
              </Item>
            )}
          />
        ) : null}
      </Content>
      <Loading isVisible={isFetchingPropertiesSent} isFullScreen />
    </Layout>
  );
}

export default MatchedProperties;
