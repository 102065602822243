import Container from "components/Container";
import { FormLabel, Textarea } from "components/Form";
import { CityField, NeighborhoodField, StateField } from "containers/LocationFields";
import useFormValue from "hooks/useFormValue";
import { useId } from "pages/RegionPostStore/hooks/useId";
import { Col, Row } from "react-flexbox-grid";
import { useParams } from "react-router";
import { Field } from "redux-form";
import { Wrapper } from './styles';

function FormLocation() {
  const { type } = useParams();

  const { id } = useId();

  const { state_id, city_id } = useFormValue([
    'state_id',
    'city_id',
  ]);

  const addressFieldsSize = type === 'neighborhood' ? 4 : 6;

  return (
    <Wrapper>
      <Container style={{ marginBottom: '15px' }}>
        <Row>
          <StateField
            xs={addressFieldsSize}
            name="state_id"
            disabled={!!id}
            noCountry
          />
          <CityField
            xs={addressFieldsSize}
            name="city_id"
            stateId={state_id}
            disabled={!!id}
          />
          {type === 'neighborhood' && (
            <NeighborhoodField
              xs={addressFieldsSize}
              name="neighborhood_id"
              cityId={city_id}
              disabled={!!id}
            />
          )}
        </Row>
      </Container>
      <Container>
        <Col xs={12}>
          <FormLabel>Descreva a região</FormLabel>
          <Field
            tabIndex={2}
            height={600}
            xsOffset={1}
            name="description"
            placeholder="Digite a descrição"
            component={Textarea}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons

                [{ list: 'ordered' }, { list: 'bullet' }, 'link'],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],

                ['clean'], // remove formatting button
              ],
            }}
          />
        </Col>
      </Container>
    </Wrapper>
  );
}

export default FormLocation;
