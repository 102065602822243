import React from 'react';
import { Box, BoxRight, Count, Label, Title, Wrapper } from './styles';

function CardCount({ title, count, countGain, countLoss }) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <BoxRight>
        <Box>
          <Label>Iniciados</Label>
          <Count>{count}</Count>
        </Box>
        <Box>
          <Label>Ganhos</Label>
          <Count>{countGain}</Count>
        </Box>
        <Box>
          <Label>Perdidos</Label>
          <Count>{countLoss}</Count>
        </Box>
      </BoxRight>
    </Wrapper>
  );
}

export default CardCount;
