const colors = {
  'new-contact': '#6979F8',
  'new-feature': '#FF843C',
  'new-matched': '#0084F4',
  others: '#1C0C1E'
};

export const getColorByTag = tag => {
  try {
    return colors[tag];
  } catch {
    return colors.others;
  }
};
