import { isEqual } from "lodash";
import { reduxForm } from "redux-form";
import * as Fields from './fields';

const Title = ({ children }) => (
  <div className="FilterAside__title">{children}</div>
);
const Content = ({ children }) => (
  <div className="FilterAside__content">{children}</div>
);

function FilterRecommendations({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Title>Filtrar recomendações</Title>
      <Content>
        <Fields.PaymentStatus />
      </Content>
    </form>
  );
}

export default reduxForm({
  form: 'FilterRecommendations',
  enableReinitialize: true,
  onChange: (values, _, ownProps, prevValues) => {
    if (isEqual(values, prevValues)) return;
    ownProps.onSubmit(values);
  },
})(FilterRecommendations);
