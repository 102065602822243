import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Header } from './styles';

const defaultProps = {
  border: false,
  shadow: true,
  padding: false,
  className: '',
  children: '',
};

const propTypes = {
  className: PropTypes.string,
  border: PropTypes.bool,
  shadow: PropTypes.bool,
  padding: PropTypes.bool,
  children: PropTypes.node,
};

export { Header };

const Container = ({
  padding,
  className,
  border,
  shadow,
  children,
  style,
  size = 'small',
  ...props
}) => (
  <div
    {...props}
    className={classnames('Container', className, {
      'Container--border': border,
      'Container--padding': padding,
      'Container--shadow': shadow,
      [`Container--${size}`]: size,
    })}
    style={style}
  >
    {children}
  </div>
);

Container.defaultProps = defaultProps;
Container.propTypes = propTypes;

export default Container;
