import styled from 'styled-components';

export const Wrapper = styled.form`
  display: flex;
  margin-top: -20px;
  margin-bottom: -20px;

  .Modal__footer {
    margin-top: 5px;
  }
`;

export const Spacer = styled.div`
  flex: 1 0;
`;

const Side = styled.div`
  display: flex;
  flex-direction: column;
  //width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const LeftSide = styled(Side)`
  padding-right: 20px;
  width: 52%;
`;

export const RightSide = styled(Side)`
  width: 48%;
  padding-left: 20px;
  border-left: 1px solid #e3e3e5;
`;
