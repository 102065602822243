import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { Checkbox, ColorPicker, Input, SelectMultiple } from 'components/Form';
// Containers
import LogoCreator from 'containers/LogoCreator';
import FieldFontSelect from 'containers/FieldFontSelect';
import LogosSelector from 'containers/LogosSelector';
// Constants
import LOGOS from 'containers/LogosSelector/data';
// Helpers
import { parseSelect } from 'lib/formHelpers';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

class FormCreateLogo extends React.Component {
  state = {
    currentImage: LOGOS[0].image,
    currentTexts: LOGOS[0].texts
  };

  render() {
    const { handleSubmit, texts, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs>
            <LogoCreator
              logoRef={this.props.logoRef}
              defaultImagePosition={[0, 10]}
              defaultTextPosition={[80, 95]}
              defaultAlignText="center"
              width={295}
              height={180}
              image={this.state.currentImage}
              texts={texts}
              onChange={dataUrl => {
                this.props.change('image', { preview: dataUrl });
              }}
            />
          </Col>
          <Col>
            <img src={this.state.previewImage} alt="" />
            <table style={{ width: '615px' }} className="h-table">
              <thead>
                <tr style={{ textAlign: 'left' }}>
                  <th width={150}>Texto</th>
                  <th>Fonte</th>
                  <th width={80}>Tamanho</th>
                  <th width={90}>Estilo</th>
                  <th width={115}>Cor</th>
                </tr>
              </thead>
              <tbody>
                {[0, 1, 2].map(numero => (
                  <FormSection name={`texts[${numero}]`}>
                    <tr key={numero}>
                      <td>
                        <Field
                          disabled={!texts[numero].isVisible}
                          name="value"
                          component={Input}
                        />
                      </td>
                      <td>
                        <FieldFontSelect
                          disabled={!texts[numero].isVisible}
                          clearable={false}
                          name="fontFamily"
                          parse={parseSelect('family')}
                        />
                      </td>
                      <td>
                        <Field
                          disabled={!texts[numero].isVisible}
                          clearable={false}
                          multi={false}
                          name="fontSize"
                          component={SelectMultiple}
                          labelKey="size"
                          valueKey="size"
                          options={[
                            { size: '12px' },
                            { size: '14px' },
                            { size: '16px' },
                            { size: '18px' },
                            { size: '20px' },
                            { size: '22px' },
                            { size: '24px' },
                            { size: '26px' }
                          ]}
                          parse={parseSelect('size')}
                        />
                      </td>
                      <td>
                        <Field
                          disabled={!texts[numero].isVisible}
                          clearable={false}
                          multi={false}
                          name="fontWeight"
                          component={SelectMultiple}
                          labelKey="weightName"
                          valueKey="size"
                          options={[
                            { size: '400', weightName: 'Normal' },
                            { size: '500', weightName: 'Medium' },
                            { size: '800', weightName: 'Bold' }
                          ]}
                          parse={parseSelect('size')}
                        />
                      </td>
                      <td>
                        <Field
                          disabled={!texts[numero].isVisible}
                          multi={false}
                          name="color"
                          component={ColorPicker}
                        />
                      </td>
                      <td>
                        <Field
                          type="checkbox"
                          style={{ marginLeft: '10px' }}
                          name="isVisible"
                          component={Checkbox}
                          onIcon={MdVisibility}
                          offIcon={MdVisibilityOff}
                        />
                      </td>
                    </tr>
                  </FormSection>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>

        <LogosSelector
          onSelect={({ image, texts }) => {
            this.setState({ currentImage: image });
            this.props.change('texts', texts);
          }}
        />
        <FixedBar style={{ left: '240px' }}>
          <FixedBar.item>
            <Button type="button" color="white" colorText="secondary">
              Cancelar
            </Button>
          </FixedBar.item>
          <FixedBar.spacer />
          <FixedBar.item>
            <Button disabled={submitting} type="submit" color="success">
              Salvar
            </Button>
          </FixedBar.item>
        </FixedBar>
      </form>
    );
  }
}

FormCreateLogo.defaultProps = defaultProps;
FormCreateLogo.propTypes = propTypes;

const selector = formValueSelector('FormCreateLogo');

const mapStateToProps = state => ({
  texts: selector(state, 'texts')
});

export default compose(
  reduxForm({
    form: 'FormCreateLogo',
    enableReinitialize: true
  }),
  connect(mapStateToProps)
)(FormCreateLogo);
