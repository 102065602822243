import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Row, Col } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { FieldImage, Input, Textarea } from 'components/Form';
import Button from 'components/Button';
import * as testimonialsService from 'services/sites/testimonials';
import { useRequest } from 'hooks/useRequest';
import { closeModal } from 'modules/modal';

function ModalTestimonialsStore({
  handleSubmit,
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  id,
  submitting,
  initialize,
}) {
  const { data, isFetching, fetchData } = useRequest({
    request: () => testimonialsService.getOne({ id }),
    initialFetch: false,
    initialState: {},
  });

  const isEditting = id !== null;

  useEffect(() => {
    initialize(data);
  }, [data, initialize]);

  useEffect(() => {
    if (!!id) {
      fetchData();
    }
  }, [isEditting]);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={isEditting ? 'Editando depoimento' : 'Novo depoimento'}
        handleClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={5}>
              <FieldImage
                imageSize="small"
                name="image"
                fileUrlField="file_url"
              />
            </Col>
            <Col xs={7}>
              <Field
                label="Nome da pessoa"
                xs={12}
                name="person_name"
                component={Input}
              />
              <Field
                label="Depoimento"
                type="simple"
                xs={12}
                name="description"
                placeholder="Digite o depoímento"
                component={Textarea}
              />
            </Col>
          </Row>

          <ModalFooter>
            <Button color="white" onClick={handleClose}>
              Fechar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button color="success" disabled={submitting} type="submit">
              {isEditting ? 'Editar depoimento' : 'Adicionar depoimento'}
            </Button>
          </ModalFooter>
        </form>
      </ModalTemplate>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalTestimonialsStore',
  onSubmit: (values) => {
    return testimonialsService.createOrUpdate(values);
  },
  onSubmitSuccess: (res, dispatch, props) => {
    dispatch(closeModal());
    if (props?.onSubmitSuccess) props?.onSubmitSuccess(res);
  },
})(ModalTestimonialsStore);
