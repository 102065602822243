import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import { Form } from 'containers/ModalRoot/ModalPayment/pages/Payment/styles';
import { SectionHeader } from 'containers/ModalRoot/ModalPayment/styles';
import { useCreditCard } from 'hooks/useCreditCard';
import useReduxForm from 'hooks/useReduxForm';
import { useEffect, useState } from 'react';
import CardsSelect from './components/CardsSelect';
import CreateCard from './components/CreateCard';
import Loading from 'components/Loading';

const CreditCard = ({ setPage }) => {
  const [isSelectingCard, setIsSelectingCard] = useState(true);

  const { change, dispatch, getValues, initialize } = useReduxForm();

  const { data: creditCards, isFetching } = useCreditCard();

  const primaryCreditCard = (creditCards ?? []).find((card) => card.is_primary);

  useEffect(() => {
    const values = getValues();

    if (!primaryCreditCard) return;

    initialize({
      ...values,
      card: primaryCreditCard.id,
    });
  }, [primaryCreditCard]);

  function renderForm() {
    if (isSelectingCard && creditCards.length > 0) {
      return (
        <>
          <CardsSelect
            creditCards={creditCards}
            onAddCard={() => {
              dispatch(change('card', null));
              setIsSelectingCard(false);
            }}
          />
          {/*<Row className="h-margin-top--15">*/}
          {/*  <FieldInstallments xs={12} />*/}
          {/*</Row>*/}
        </>
      );
    }

    return <CreateCard />;
  }

  if (isFetching) return <Loading isVisible isBlock />;

  return (
    <>
      <SectionHeader>Dados do cartão</SectionHeader>

      <Form>{renderForm()}</Form>

      <ModalFooter>
        <Button
          color="white"
          colorText="primary"
          onClick={() => setPage('UpdateContract')}
        >
          Voltar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Finalizar pagamento
        </Button>
      </ModalFooter>
    </>
  );
};

export default CreditCard;
