import React from 'react';
import { Wrapper } from './styles';

const Overlay = ({
  color = '#1C0C1E',
  alpha = 0.6,
  className,
  onClick,
  children
}) => {
  return (
    <Wrapper
      color={color}
      alpha={alpha}
      onClick={onClick}
      className={className}
    >
      <div>{children}</div>
    </Wrapper>
  );
};

export default Overlay;
