import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  background: #fff;
  border: 1px solid #d3dceb;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 60px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1 0;
  padding: 0 50px;
  justify-content: center;
  align-items: center;
`;

export const WrapText = styled.div``;

export const Image = styled.img`
  flex: 1 0;
`;

export const Title = styled.h1`
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
`;
