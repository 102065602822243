import React, { useCallback } from 'react';
import MainTitle from 'components/MainTitle';
// import { useDispatch } from 'react-redux';
import FormGallery from './components/FormGallery';
// Services
import * as galleryService from 'services/sites/gallery';
// Hooks
import { useRequest } from 'hooks/useRequest';

function Gallery() {
  const { data: initialValues } = useRequest({
    request: galleryService.getFormData,
  });

  const handleSubmit = useCallback(
    (values) => galleryService.update(values),
    []
  );

  const handleSubmitSuccess = useCallback(() => {}, []);

  return (
    <>
      <MainTitle
        title="Restrição de fotos da galeria do imóvel"
        text="Restrinja a quantidade de imagens exibidas antes de solicitar a identificação do cliente."
      />
      <FormGallery
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
      />
    </>
  );
}

export default Gallery;
