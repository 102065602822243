import { useState } from 'react';
import api from 'services';
import { fileDownload } from 'lib/file-helpers';
import Alert from 'react-s-alert';
import { getOne } from 'services/user';
import moment from 'lib/moment';
import { getFirstName } from 'lib/text';

export const useExport = ({
  resource,
  filter,
  sort,
  userId,
  payload,
  fileName = 'arquivo',
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClickExport = async () => {
    try {
      setIsLoading(true);

      // Pega o usuário que está fazendo o pedido de download
      const { data: user } = await getOne(userId);

      const data = moment().format('DD.MM.YY_HH-mm');

      const response = await api.create(resource, payload, {
        params: {
          filter,
          sort,
        },
        responseType: 'blob',
      });

      await fileDownload(
        response,
        `${fileName}-${getFirstName(user?.name).toLowerCase()}-${data}.xls`
      );

      setIsLoading(false);
    } catch (err) {
      Alert.success('Erro ao baixar arquivo');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleClickExport,
  };
};
