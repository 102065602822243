import styled, { css } from 'styled-components';
import { getColor } from 'lib/color-helpers';

const colorMixin = ({ color }) => {
  if (color) {
    const colorHex = getColor(color);

    return css`
      background: ${colorHex};
      padding: 5px;
      width: 100%;
      border-radius: 4px;
    `;
  }
};

export const Wrapper = styled.label`
  display: inline-block;
  ${colorMixin};

  :focus svg {
    outline: 1px solid #a1a1a1;
    border-radius: 4px;
  }
`;
