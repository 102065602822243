import React from 'react';
// Components
import MainTitle from '../../../../components/MainTitle';
import Wrapper from '../../../../components/Wrapper';
import { plural } from 'lib/text';

const propTypes = {};
const defaultProps = {
  title: 'Meus Clientes',
  text:
    'Cadastre, edite, gerencie tudo que precisar para dar ao seu cliente as melhores informações possíveis!'
};

const PeopleContainer = ({
  isSearching,
  pagination,
  title,
  text,
  renderActions,
  children
}) => (
  <Wrapper.inner>
    <Wrapper.container>
      {isSearching ? (
        <MainTitle
          title={
            pagination?.total
              ? plural(pagination?.total, 'resultado', 'resultados')
              : '--'
          }
          text={text}
        >
          {renderActions && renderActions()}
        </MainTitle>
      ) : (
        <MainTitle title={title} text={text}>
          {renderActions && renderActions()}
        </MainTitle>
      )}
      {children}
    </Wrapper.container>
  </Wrapper.inner>
);

PeopleContainer.propTypes = propTypes;
PeopleContainer.defaultProps = defaultProps;

export default PeopleContainer;
