import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Select } from 'components/Form';
import { CustomFieldDateRange, Form } from './styles';
import moment, { format } from 'lib/moment';
import { MdUploadFile } from 'react-icons/md';
import Button from 'components/Button';
import { useFormUserId } from 'pages/Crm/components/FormUser';
import { useExportDealsClosed } from '../../../Deals/hooks/useExportDealsClosed';

function Filter({ handleSubmit }) {
  const userId = useFormUserId();
  const { isLoading, handleClickExport } = useExportDealsClosed({ userId });

  return (
    <Form onSubmit={handleSubmit}>
      <Button
        disabled={isLoading}
        size="medium"
        color="white"
        onClick={handleClickExport}
        className="h-margin-right--10"
      >
        <MdUploadFile /> Exportar
      </Button>
      <Field
        buttonTemplate
        name="by_inactive_status"
        component={Select}
        labelKey="label"
        valueKey="value"
        options={[
          { id: 'a1', label: 'Todos', value: 'all' },
          { id: 'a2', label: 'Ganhos', value: 'won' },
          { id: 'a3', label: 'Perdidos', value: 'lost' },
          { id: 'a4', label: 'Removidos', value: 'removed' },
        ]}
      />
      <CustomFieldDateRange
        isInline
        canClear={false}
        popperOptions={{
          placement: 'bottom',
        }}
        startDateName="updated_greater_equals"
        endDateName="updated_lower_equals"
      />
    </Form>
  );
}

export default reduxForm({
  form: 'FilterDealHistory',
  enableReinitialize: true,
  initialValues: {
    by_inactive_status: 'all',
    updated_greater_equals: moment().subtract(30, 'days').format(format.date),
    updated_lower_equals: moment().format(format.date),
  },
})(Filter);
