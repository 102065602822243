import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Services
import * as receptionSourcesService from 'services/receptionSources';
import TableData from './components/TableData';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
// Modules
import {
  openModalReceptionSource,
  openModalReceptionSourceRemove,
} from 'modules/modal';

// import from './styles';

function Characteristics() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  async function fetchReceptionSources() {
    setIsFetching(true);
    const { data } = await receptionSourcesService.getAllCustom({
      count: false,
    });
    setData(data);
    setIsFetching(false);
  }

  const update = (_data) => {
    const newData = data.map((d) => {
      if (_data.id === d.id) {
        return _data;
      }
      return d;
    });

    setData(newData);
  };

  const handleDelete = (receptionSource) => async () => {
    dispatch(
      openModalReceptionSourceRemove({
        receptionSource,
        onSubmitSuccess: () => {
          fetchReceptionSources();
        },
      })
    );
    //await receptionSourcesService.remove(id);
    //
    //const _data = data.filter(({ id: _id }) => _id !== id);
    //
    //setData(_data);
  };

  const handleEdit =
    ({ id }) =>
    () => {
      openModalReceptionSource({
        id: id,
        onSubmitSuccess: ({ data: _data }) => {
          update(_data);
        },
      })(dispatch);
    };

  useEffect(() => {
    fetchReceptionSources();
  }, []);

  return (
    <Wrapper.container>
      <MainTitle
        title="Origem da captação de cliente"
        text="Gerencie aqui todas as origens de captação que você cadastrou no sistema."
      />
      <TableData
        data={data}
        isFetching={isFetching}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Wrapper.container>
  );
}

Characteristics.propTypes = {};
Characteristics.defaultProps = {};

export default Characteristics;
