import React, { useEffect, useState } from 'react';
import api from 'services';
import Graph from '../Graph';
import { useRequest } from 'hooks/useRequest';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from 'recharts';
import Nodata from 'containers/ModalRoot/ModalProperty/pages/Reports/components/Nodata';

function ContactsGraph({ propertyId }) {
  const [days, setDays] = useState(15);
  const { data, fetchData, isFetching } = useRequest({
    request: (period) =>
      api.get(`properties/${propertyId}/leads-per-day`, {
        period: period !== 'Tudo' ? period : null,
      }),
    initialFetch: false,
  });
  const handleChangeDays = (day) => {
    fetchData(day);
    setDays(day);
  };

  useEffect(() => {
    fetchData(15);
  }, []);

  return (
    <Graph
      isFetching={isFetching}
      title="Contatos recebidos (Soma do site e portais)"
      onChangeDays={handleChangeDays}
    >
      <div style={{ width: '100%', height: 300, position: 'relative' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={500} height={300} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis dataKey="total" allowDecimals={false} />
            <Tooltip />
            <Bar dataKey="total" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
        {data.length <= 0 && !isFetching && (
          <Nodata>Nenhum contato no imóvel nos últimos {days} dias</Nodata>
        )}
      </div>
    </Graph>
  );
}

export default ContactsGraph;
