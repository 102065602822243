import styled from "styled-components";

export const CondoTitle = styled.p`
  font-weight: bold;
`;

export const Warning = styled.p`
  margin: 20px 0;
  font-style: italic;
`;

export const Wrapper = styled.form`
  p {
    line-height: 20px;
  }

  .FormGroup {
    margin-bottom: 0;
  }

  .RadioButton + .RadioButton {
    margin-top: 5px;
  }
`;
