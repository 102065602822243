import { useRequest } from 'hooks/useRequest';
import * as facebookService from 'services/settings/facebook';

export const usePages = () => {
  const { data, isFetching, setData, fetchData } = useRequest({
    request: (accessToken) =>
      facebookService.getPagesLeadsWithForms({ access_token: accessToken }),
  });

  // const pages = data?.map((page) => {
  //   page.forms = page?.forms
  //     ?.map((form) => {
  //       if (!form.option) {
  //         return { ...form };
  //       }

  //       return form;
  //     })
  //     ?.filter((form) => form.status !== 'ARCHIVED');

  //   return page;
  // });

  return {
    pages: data,
    isFetchingPages: isFetching,
    fetchPages: fetchData,
    setPages: setData,
  };
};
