import React from 'react';
// Components
import Popover from 'components/Popover';
import DealAudits from '../DealAudits';

function DealHistory({ dealId, params, children, onSuccess }) {
  return (
    <Popover dealId={dealId} params={params} component={DealAudits}>
      {children}
    </Popover>
  );
}

DealHistory.defaultProps = {};
DealHistory.propTypes = {};

export default DealHistory;
