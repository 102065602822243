import React from 'react';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Containers
import FieldSelectUser from 'containers/FieldSelectUser';
import { Select } from 'components/Form';
// Components
import Button from 'components/Button';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Divider from 'components/Divider';
import validate from './validate';
import { STATUS_CRECI } from '../../../../../constants/constants';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const FormTransfer = ({ ownerUser, handleSubmit, handleClose }) => {
  // const firstName = currentUser.name.split(' ')[0];

  return (
    <form onSubmit={handleSubmit}>
      {/*<p>Selecione qual corretor existente herderá os imóveis de <strong>{firstName}</strong>.</p>*/}
      <Row className="h-margin-top--15">
        <FieldSelectUser
          xs={6}
          label={'Transferir para:'}
          name="to_user_id"
          filter={user => user.id !== ownerUser.id}
          params={{ filter: { creci_status: STATUS_CRECI.VERIFIED } }}
        />
        <Field
          xs={6}
          label={<>&nbsp;</>}
          name="quantity_properties"
          component={Select}
          options={[
            { value: 'one', label: 'Apenas esse' },
            { value: 'all', label: 'Transferir todos' }
          ]}
          buttonTemplate
        />
      </Row>
      <Divider right={-19} left={-19} width={'calc(100% + 39px)'} />
      <ModalFooter>
        <Button
          type="button"
          color="white"
          colorText="secondary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Finalizar
        </Button>
      </ModalFooter>
    </form>
  );
};

FormTransfer.defaultProps = defaultProps;
FormTransfer.propTypes = propTypes;

export default reduxForm({
  form: 'FormTransfer',
  validate,
  enableReinitialize: true
})(FormTransfer);
