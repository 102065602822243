import React, { useState, useCallback } from 'react';
import { Wrapper, Overlay, Container } from './styles';
import { AnimatePresence } from 'framer-motion';

export const DropdownContext = React.createContext({
  isVisible: null,
  setIsVisible: null,
  close: null
});

const Dropdown = ({
  isOpen,
  className,
  width = 550,
  position = 'center',
  children,
  onClick,
  component: Component,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  const _isOpen = isOpen || isVisible;

  return (
    <DropdownContext.Provider value={{ isVisible, setIsVisible, close }}>
      <Wrapper
        className={className}
        onMouseEnter={() => {
          setIsVisible(true);
        }}
        onMouseLeave={() => {
          setIsVisible(false);
        }}
      >
        {typeof children === 'function'
          ? children({ isVisible, setIsVisible, close })
          : children}
        <AnimatePresence>
          {_isOpen && (
            <Container width={width} position={position}>
              <Component {...props} />
            </Container>
          )}
        </AnimatePresence>
      </Wrapper>
      <AnimatePresence>{_isOpen && <Overlay />}</AnimatePresence>
    </DropdownContext.Provider>
  );
};

export default Dropdown;
