import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { SelectMultiple } from 'components/Form';
// Constants
import { AVALIABLE_FONTS } from 'constants/constants';
import { loadFonts } from 'modules/googlefonts';
import Option from './components/Option';
import Value from './components/Value';
import { parseSelect } from 'lib/formHelpers';

class FieldFontSelect extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        kind: PropTypes.string.isRequired,
        family: PropTypes.string,
        category: PropTypes.string,
        variants: PropTypes.arrayOf(PropTypes.string),
        subsets: PropTypes.arrayOf(PropTypes.string),
        version: PropTypes.string,
        lastModified: PropTypes.string,
        files: PropTypes.objectOf(PropTypes.object)
      })
    )
  };

  static defaultProps = {
    name: null,
    options: AVALIABLE_FONTS,
    labelKey: 'family',
    valueKey: 'family'
  };

  componentDidMount() {
    this.props.loadFonts();
  }

  render() {
    return (
      <Field
        {...this.props}
        multi={false}
        options={this.props.options}
        component={SelectMultiple}
        optionComponent={Option}
        valueComponent={Value}
        parse={parseSelect('family')}
      />
    );
  }
}

export default connect(null, {
  loadFonts
})(FieldFontSelect);
