import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, initialize, reduxForm } from 'redux-form';
// Components
import CarouselSelect from 'components/Form/components/CarouselSelect';
import Button from 'components/Button';
import Container from 'pages/Site/components/ContainerSite';
import FixedBar from 'components/FixedBar';
import ListOptions from 'components/ListOptions';
// Components
import { Input, Select } from 'components/Form';
import Loading from 'components/Loading';

const selector = formValueSelector('FormTestimonials');

const FormTestimonials = ({
  isFetching,
  testimonials,
  submitting,
  handleSubmit,
  initialize,
  formValues,
}) => {
  const is_testimonials_shown = useSelector((state) =>
    selector(state, 'is_testimonials_shown')
  );
  const style = {
    opacity: is_testimonials_shown ? 1 : 0.3,
    pointerEvents: is_testimonials_shown ? 'auto' : 'none',
  };

  useEffect(() => {
    initialize(formValues);
  }, [initialize, formValues]);

  if (isFetching) {
    return <Loading isVisible isFullScreen />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="testimonials_type"
        options={testimonials}
        component={CarouselSelect}
        className="h-margin-bottom--15"
      />
      <Container>
        <ListOptions>
          <ListOptions.item
            title="Mostrar Depoimentos na página inicial"
            text="Mostrar na página inicial uma galeria com depoimentos a sua escolha."
            renderOptions={() => (
              <Field
                canRemoveSelf={false}
                buttonTemplate
                name="is_testimonials_shown"
                component={Select}
                options={[
                  { value: true, label: 'Mostrar' },
                  { value: false, label: 'Esconder', _color: 'danger' },
                ]}
              />
            )}
          />
          <ListOptions.item
            title="Título da área"
            text="Edite o título que ficará acima dos depoimentos."
            style={style}
          >
            <div className="h-margin-top--10">
              <Field name="testimonials_title" component={Input} />
            </div>
          </ListOptions.item>
        </ListOptions>
        <FixedBar style={{ left: 240 }}>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar
          </Button>
        </FixedBar>
      </Container>
    </form>
  );
};

export default reduxForm({
  form: 'FormTestimonials',
  onSubmitSuccess: (res, dispatch) => {
    // Reinicializa o formulário com os valores
    dispatch(initialize('FormTestimonials', res.data));
  },
})(FormTestimonials);
