import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
  background-color: ${p => p.theme.colors.primary};
  padding: 0 23px;
`;
export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 945px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 23px 0;
`;

const Text = styled.div`
  color: #fff;
`;

export const TextLeft = styled(Text)`
  font-weight: 900;
  margin-right: 30px;
`;

export const TextRight = styled(Text)`
  margin-left: 30px;
`;

export const Btn = styled.button`
  position: absolute;
  bottom: 100%;
  right: 0;
  background: ${p => p.theme.colors.primary};
  color: #fff;
  padding: 4px 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;

  :hover {
    background: #000;
  }

  svg {
    margin-right: 4px;
    vertical-align: middle;
  }
`;
