import _ from 'lodash';

export const transformData = (values) => {
  const transformEstablishments = (establishments) => {
    let data = [];
    if (establishments) {
      data = Object.entries(establishments).reduce((reducer, [id, item]) => {
        if (!item.isChecked) return reducer;

        const reduced = {
          ...reducer,
          [id]: {
            ...item,
            id,
          },
        };

        return reduced;
      }, {});
    }

    return data;
  };

  const transformCharacteristics = (characteristics) => {
    let data = [];
    if (characteristics) {
      data = Object.entries(characteristics).reduce((reducer, [id, item]) => {
        if (!item.isChecked) return reducer;

        const reduced = {
          ...reducer,
          [id]: {
            ...item,
            id,
          },
        };

        return reduced;
      }, {});
    }

    return data;
  };

  const establishments = transformEstablishments(values.establishments);
  const characteristics = transformCharacteristics(
    values.condos_characteristics
  );

  return {
    ...values,
    establishments,
    condos_characteristics: characteristics,
  };
};

export const transformResponse = (data) => {
  const establishments = _(data.relations.establishments_id)
    .map((id) => ({
      id,
      isChecked: true,
    }))
    .keyBy('id')
    .value();

  const characteristics = _(data.relations.condos_characteristics_id)
    .map((item) => ({
      ...item,
      isChecked: true,
    }))
    .keyBy('id')
    .value();

  const newValues = {
    id: data.id,
    title: data.title,
    condos_characteristics: characteristics,
    establishments,
    description: data.description,
    zip_code: data.zip_code,
    country_id: data.relations.country_id,
    state_id: data.relations.state_id,
    city_id: data.relations.city_id,
    zone_id: data.zone_id,
    neighborhood_id: data.neighborhood_id,
    street_address: data.street_address,
    street_number: data.street_number,
    complement_address: data.complement_address,
    video: data.video,
  };

  return newValues;
};
