import React from 'react';
import { Field } from 'redux-form';
// Components
import { Input } from 'components/Form';
import GroupControl, { GroupItem } from 'components/GroupControl';

function UpdatedField() {
  return (
    <GroupControl label="Atualizado em">
      <GroupItem>
        <Field type="number" name="updated_last_days" component={Input} />
      </GroupItem>
      <GroupItem label="Dias" size="small" />
    </GroupControl>
  );
}

UpdatedField.defaultProps = {};
UpdatedField.propTypes = {};

export default UpdatedField;
