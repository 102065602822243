import React from 'react';
import { Wrapper, Item } from './styles';
import { useRequest } from 'hooks/useRequest';
import { getMatched } from 'services/people';

function Detail({ matchedId }) {
  const { data } = useRequest({
    request: getMatched,
    params: [matchedId],
    initialState: {}
  });

  return (
    <Wrapper>
      <Item>
        <h2>Motívo da perda</h2>
        <p>{data?.lost_deal?.lossReason?.reason || '--'}</p>
      </Item>
      <Item>
        <h2>Observação</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: data?.lost_deal?.loss_note || '--'
          }}
        />
      </Item>
    </Wrapper>
  );
}

Detail.propTypes = {};
Detail.defaultProps = {};

export default Detail;
