import styled from 'styled-components';
import Highlight from 'components/Highlight';
import CustomImage from 'components/Image';

export const Wrapper = styled.div``;

export const Item = styled.button`
  display: block;
  width: 100%;
  padding: 10px 0;
  text-align: left;
  cursor: pointer;
`;

export const Card = styled.button`
  width: 100%;
  display: flex;
  padding: 20px;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
  color: #1a051d;
  cursor: pointer;

  & + & {
    margin-top: 15px;
  }
`;
export const Image = styled(CustomImage)`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 15px;
`;
export const CardContent = styled.div`
  text-align: left;
`;
export const Title = styled(Highlight)`
  font-size: 16px;
  font-weight: 600;
`;
export const Price = styled.p`
  color: ${p => p.theme.colors.success};
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
`;
export const Address = styled(Highlight)`
  display: block;
  line-height: 1.4em;
`;
