import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${(p) => p.theme.size}%;
  background: ${(p) => p.theme.background};
  border-radius: 4px;
  color: #ffffff;
  min-width: 270px;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  & + & {
    margin-top: 5px;
  }
`;

export const ColLeft = styled.div``;
export const ColRight = styled.div`
  text-align: right;
`;
export const Text = styled.div`
  font-size: 14px;
`;
export const Number = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
`;
