import Button from 'components/Button';
import FormGalleryUpload from 'components/FormGalleryUpload';
import { useEffect, useMemo } from 'react';
import { MdAdd, MdDelete } from 'react-icons/md';
import { useParams } from 'react-router';
import SortableCondoGallery from '../SortableCondoGallery';
import { useGallery } from './context';
import { Actions, Header, Reception } from './styles';
import icon from './icon.svg';
import { getIsUploading } from 'modules/fileUpload';
import { useSelector } from 'react-redux';

const { default: Container } = require('components/Container');

function Gallery() {
  const {
    data,
    fetchData,
    removeAllImages,
    uploadImages,
    removeImage,
    handleSortEnd,
    handleEditCaption,
    handleEditCaptionSuccess,
  } = useGallery();

  const isUploading = useSelector(getIsUploading);
  const { id } = useParams();

  const downloadImagesUrl = useMemo(() => {
    const apiUrl = process?.env?.REACT_APP_API_STORAGE;
    const token = localStorage.getItem('access_token');

    return `${apiUrl}/api/properties/condos/${id}/images/download?token=${token}`;
  }, [id]);

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const handleUploadImages = (data) =>
    uploadImages({ files: data.files, condoId: id });

  if (!data.length) {
    return (
      <Container
        style={{ paddingTop: '10px', marginTop: '20px', marginBottom: '90px' }}
      >
        <Reception>
          <img src={icon} alt="" />
          <h1>Você ainda não enviou nenhuma foto.</h1>
          <p>Que tal enviar agora?</p>
          <FormGalleryUpload
            disabled={isUploading}
            form="Images"
            initialValues={{
              condoId: id,
            }}
            onChange={(...props) => {
              if (isUploading) return null;
              handleUploadImages(...props);
            }}
          >
            <Button disabled={isUploading} color="secondary" size="large">
              <MdAdd /> Enviar fotos
            </Button>
          </FormGalleryUpload>
        </Reception>
      </Container>
    );
  }

  return (
    <Container
      style={{ paddingTop: '10px', marginTop: '20px', marginBottom: '90px' }}
    >
      <Header>
        <div />
        <Actions>
          <Button
            color="danger"
            size="medium"
            onClick={removeAllImages({ id })}
          >
            <MdDelete /> Remover todas
          </Button>
          <Button
            size="medium"
            tagName="a"
            href={downloadImagesUrl}
            target="_blank"
          >
            Baixar imagens
          </Button>
          <FormGalleryUpload
            form="Images"
            initialValues={{
              condoId: id,
            }}
            onChange={(...rest) => {
              if (isUploading) return null;
              handleUploadImages(...rest);
            }}
            className="h-margin-left--5"
          >
            <Button
              disabled={isUploading || data.length >= 50}
              color="secondary"
              size="medium"
            >
              <MdAdd /> Enviar mais imagens
            </Button>
          </FormGalleryUpload>
        </Actions>
      </Header>
      <SortableCondoGallery
        data={data}
        onRemoveImage={removeImage}
        onSortEnd={handleSortEnd}
        onEditCaption={handleEditCaption}
        onEditCaptionSuccess={handleEditCaptionSuccess}
      />
    </Container>
  );
}

export default Gallery;
