import { filter } from 'lodash';
import { createSelector } from 'reselect';

/**
 * Filtra todos os items que estão selecionados
 * Se não retorna a lista inteira
 * @param {Array|Object} items
 * @param {Array} selected
 * @returns {Array}
 */
export const filterSelected = (items, selected) => {
  return selected.length ? filter(items, 'isSelected') : items;
};

/**
 * @param {Object} item
 * @param {Array} selected
 * @param {Function} callback
 * @param {String|Number} key
 * @returns {Array}
 */
export const toggleSelected = (item, selected, callback, key) => {
  if (selected.includes(item[key])) {
    callback(prevState => prevState.filter(i => i !== item[key]));
    item.isSelected = false;
  } else {
    callback(prevState => [...prevState, item[key]]);
    item.isSelected = true;
  }
};

/**
 *
 * @param {Array} selectors
 * @returns {Array}
 */
export const filterOrAll = selectors =>
  createSelector(
    [selectors.getAllWithSelected, selectors.getSelected],
    filterSelected
  );
