import React, { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
// Components
import FixedBar from 'components/FixedBar';
import Loading from 'components/Loading';

import Button from 'components/Button';
// HOC
import Overlay from 'components/Card/components/Overlay';
import ColorPicker from 'components/Form/components/ColorPicker';
import { MdClose } from 'react-icons/md/index';
import FormBox from 'components/FormBox';
import useFormValue from 'hooks/useFormValue';
import noImage from 'assets/img/noData/photo.svg';
import Image from 'components/Image';
import { useDispatch } from 'react-redux';
import { setLogoHeader, setColorHeader, setHeaderType, setRealEstate } from 'modules/login';
import * as realEstateService from 'services/realEstate';
import { CarouselSelect } from 'components/Form';
import { HEADERS } from 'pages/Config/pages/General/constants';

const Logo = ({ src, children, className }) => (
  <div
    className={`FormUpload__logo ${className}`}
    style={{ background: '#E1E6EC' }}
  >
    <Image src={src} alt="" size="original" className="FormUpload__image" />
    {children}
  </div>
);

const NoImageUploaded = ({ color }) => (
  <>
    <Logo src={noImage} color={color}>
      <p className="FormUpload__text">
        Você ainda não enviou seu <br />
        logo para o sistema.
      </p>
    </Logo>
    <Button
      to="/config/logo/real-estate"
      type="button"
      color="secondary"
      size="medium"
    >
      Enviar
    </Button>
  </>
);

function GeneralForm({
  change,
  handleSubmit,
  isFetching,
  submitting,
  pristine
}) {
  const dispatch = useDispatch();
  const image = useFormValue('image');
  const file_url = useFormValue('file_url');
  const header_background = useFormValue('header_background');

  const imageUrl = image ? image.preview : file_url;

  const handleChangeHeaderBackground = useCallback(
    (e, color) => {
      if (!color) return;
      dispatch(setColorHeader(color));
    },
    [dispatch]
  );

  const handleChangeImage = useCallback(
    (e, image) => {
      if (!image) return;
      dispatch(setLogoHeader(image.preview));
    },
    [dispatch]
  );

  const handleDeleteLogo = useCallback(() => {
    realEstateService.removeLogo().then(() => {
      dispatch(setLogoHeader(''));

      // Reseta os dois campos relacionados a imagem
      change('file_url', null);
      change('image', null);
    });
  }, [dispatch, change]);

  useEffect(() => {
    return () => {
      realEstateService.getRealEstate().then(({ data: realEstate }) => {
        dispatch(setRealEstate(realEstate));
      });
    };
  }, []);

  const hasImage = image || file_url;

  return (
    <form onSubmit={handleSubmit} style={{ position: 'relative' }}>
      <Loading isVisible={isFetching} isFullComponent />

      <Field
        name="header_type"
        component={CarouselSelect}
        options={HEADERS}
        srcKey="image"
        valueKey="name"
        onChange={value => {
          dispatch(setHeaderType(value));
        }}
      />

      <FormBox title="Logo para o sistema">
        <p>
          Será usado no topo do sistema, deixando ele com a cara da sua empresa.
        </p>
        <div
          className="h-flex h-flex--center-center"
          style={{ justifyContent: 'center' }}
        >
          <div style={{ marginRight: '30px' }}>
            {!hasImage ? (
              <NoImageUploaded onChange={handleChangeImage} />
            ) : (
              <Logo
                src={imageUrl}
                className="h-hover"
                style={{ background: '#F1F4F9' }}
              >
                <Overlay
                  style={{ backgroundColor: 'rgba(62, 88, 124, 0.85)' }}
                  className="h-hover__show"
                >
                  <Overlay.row position="center">
                    <span className="h-flex__cell--grow" />
                    <Overlay.item>
                      <div className="h-flex">
                        <Button
                          to="/config/logo/real-estate"
                          color="white"
                          colorText="secondary"
                          type="button"
                        >
                          Alterar
                        </Button>
                        <Button
                          type="button"
                          color="danger"
                          onClick={handleDeleteLogo}
                        >
                          Remover
                        </Button>
                      </div>
                    </Overlay.item>
                    <span className="h-flex__cell--grow" />
                  </Overlay.row>
                </Overlay>
              </Logo>
            )}
          </div>
          <div className="Form--inline">
            <label className="FormLabel">Cor do fundo do topo</label>
            <Field
              classNameFormGroup="h-margin-bottom--0"
              name="header_background"
              component={ColorPicker}
              placeholder="Transparente"
              onChange={handleChangeHeaderBackground}
              style={{ margin: 0 }}
            />
            {header_background !== '#0063C0' && (
              <button
                type="button"
                onClick={() => {
                  change('header_background', '#0063C0');
                }}
              >
                <MdClose />
              </button>
            )}
          </div>
        </div>
      </FormBox>

      <FixedBar style={{ left: 256 }}>
        <span className="h-flex__cell--grow" />
        <Button disabled={submitting || pristine} type="submit" color="success">
          Salvar
        </Button>
      </FixedBar>
    </form>
  );
}

export default compose(
  reduxForm({
    form: 'GeneralForm',
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
  })
)(GeneralForm);
