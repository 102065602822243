import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .FormGroup div {
    display: flex;
    align-items: center;
  }

  input {
    width: 90px;
  }
`;
