import React from 'react';
import FormDealLoss from '../FormDealLoss';
import { ModalTemplate } from '../../../../../components/Modal';

const propTypes = {};

const defaultProps = {};

const ModalLoss = ({ handleClose, ...props }) => (
  <ModalTemplate
    isLoading={props.isFetching}
    title="Qual o motivo da perda?"
    handleClose={handleClose}
  >
    <FormDealLoss onSubmit={props.handleSubmitLoss} {...props} />
  </ModalTemplate>
);

ModalLoss.propTypes = propTypes;
ModalLoss.defaultProps = defaultProps;

export default ModalLoss;
