import styled from 'styled-components';

export const Nav = styled.div`
  margin-top: 20px;
`;

export const NavTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Title = styled.h2`
  font-size: 16px;
`;

export const Aside = styled.div`
  width: 255px;
  height: 100%;
  background: #fff;
  border-right: 1px solid #e9e5eb;
  padding: 30px;
  flex-shrink: 0;
`;
