import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { FileInput } from '../../../../components/Form';
import Button from '../../../../components/Button';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const DocumentsForm = ({ column, upload, handleSubmit, className }) => {
  const progress = ((upload.success + upload.error) / upload.total) * 100 || 0;
  const isLoading = upload.total > 0;

  return (
    <form onSubmit={handleSubmit} className={className}>
      <div
        className={`h-flex h-flex--center-center ${column &&
          'h-flex--collumn'}`}
      >
        <Field accept={null} multiple name="documents" component={FileInput}>
          <Button
            color="secondary"
            disabled={isLoading}
            loading={upload.total ? progress : null}
          >
            Enviar Documento
          </Button>
        </Field>
        {upload.total > 0 && (
          <span className="h-margin-left--10">
            Enviando {upload.success + upload.error}/{upload.total} arquivos
          </span>
        )}
      </div>
    </form>
  );
};

DocumentsForm.defaultProps = defaultProps;
DocumentsForm.propTypes = propTypes;

export default reduxForm({
  form: 'DocumentsForm',
  enableReinitialize: true
})(DocumentsForm);
