import styled from 'styled-components';

export const Content = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: scale(0) translateX(-50%);
  flex-grow: 1;
  z-index: 1000;
  transition: all 0.2s ease-in-out;
  transform-origin: 0 0;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 2000;

  .Menu__item {
    display: block;
    height: 55px;
    line-height: 55px;
  }

  &:not(.is-open) > ${Content} {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
  }

  &.is-open > ${Content} {
    opacity: 1;
    visibility: visible;
    transform: scale(1) translateX(-50%);
  }
`;

export const ContentInner = styled.div`
  background: #f8f8f8;
  border-radius: 6px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
`;

export const Spacing = styled.div`
  height: 10px;
  width: 100%;
`;
