import * as creditCardsService from 'services/financial/creditCards';
import * as contractsService from 'services/financialv2/contracts';
import { useCallback, useState } from 'react';
import { PAYMENT_METHODS } from 'constants/constants';
import { openModalHasPayment } from 'modules/modal';
import { useModalPayment } from 'containers/ModalRoot/ModalUpdateContractDate/contexts/payment';
import { useDispatch } from 'react-redux';

export const useContract = () => {
  const dispatch = useDispatch();
  const { setIsLoading } = useModalPayment();

  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);

  const contractWithCreditCard = async (values) => {
    try {
      let card;

      if (!values.card) {
        const { data } = await creditCardsService.create(values);

        card = data;
      }

      const res = await contractsService.extend({
        credit_card_id: card?.id,
        ...values,
      });

      if (!!res.data.alerts) {
        throw new Error('Erro ao pagar com cartão de crédito');
      }

      setIsSuccess(true);
      return res;
    } catch (err) {
      console.log('Erro ao contratar plano com cartão de crédito');
      setHasError(true);
    }
  };

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setIsLoading(true);

        if (values.receiving_method === PAYMENT_METHODS.CREDIT_CARD) {
          await contractWithCreditCard(values);
        }

        setIsLoading(false);
      } catch (err) {
        if (err.message === 'HAS_PAID') {
          dispatch(
            openModalHasPayment({
              onConfirm: async () => {
                await handleSubmit({ ...values, force: 1 });

                window.location.reload();
              },
            })
          );

          return {};
        }
      }
    },
    [contractWithCreditCard]
  );

  return { isSuccess, hasError, setHasError, handleSubmit };
};
