import React, { useCallback, useEffect } from 'react';
import ReactAlert from 'react-s-alert';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import PreviewImage from './components/PreviewImage';
import Container from 'components/Container';
import Reception from './components/Reception';
import { Box, Content, Left, Right } from './styles';
import { FieldBool, Position, Slider } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
// Services
import * as watermarkService from 'services/watermark';
import { useRequest } from 'hooks/useRequest';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Alert from 'components/Alert';
import FixedBar from 'components/FixedBar';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useDispatch } from 'react-redux';
import { openModalWatermarkRemove } from 'modules/modal';

const Watermark = ({ submitting, handleSubmit, initialize, change }) => {
  const dispatch = useDispatch();
  const { is_ready_to_apply, opacity, proportion, position, is_applying } =
    useFormValue([
      'is_ready_to_apply',
      'opacity',
      'proportion',
      'position',
      'is_applying',
    ]);

  const { data, fetchData, isFetching } = useRequest({
    request: watermarkService.getOne,
    initialState: {},
  });

  const handleRemove = useCallback(() => {
    dispatch(
      openModalWatermarkRemove({
        onSuccess: fetchData,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    initialize(
      {
        ...data,
      },
      false
    );
  }, [data]);

  if (isFetching) return <Loading isVisible isFullComponent />;

  if (!data || data.length <= 0)
    return (
      <Wrapper.container>
        <Reception />
      </Wrapper.container>
    );

  return (
    <form onSubmit={handleSubmit}>
      <Wrapper.container>
        <MainTitle
          title="Marca d’água"
          text={`
            Faz com que seus clientes identifiquem facilmente sua
            marca, além de impedir que alguém copie suas fotos!
          `}
        >
          {!is_ready_to_apply && (
            <Button
              to="/site/layout/style/logo-options/watermark"
              color="secondary"
            >
              Enviar outra imagem
            </Button>
          )}
        </MainTitle>
        <Container>
          <Content>
            <Left>
              <PreviewImage
                image={data?.file_url}
                opacity={opacity}
                size={proportion}
                position={position}
              />
            </Left>
            <Right>
              {is_applying ? (
                <Alert color="secondary" style={{ marginBottom: '20px' }}>
                  Aplicando marca d'água
                </Alert>
              ) : is_ready_to_apply ? (
                <div>
                  <Alert color="secondary" style={{ marginBottom: '20px' }}>
                    Ao alterar a Marca D’água, a antiga será subtituída pela
                    nova imagem enviada.
                  </Alert>
                  <Row>
                    <Col xs={6}>
                      <Button
                        block
                        onClick={() => {
                          change('is_ready_to_apply', false);
                        }}
                        color="secondary"
                      >
                        Editar Marca D'água
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button
                        block
                        to="/site/layout/style/logo-options/watermark"
                        color="primary"
                      >
                        Enviar outra imagem
                      </Button>
                    </Col>
                  </Row>
                  <Button
                    type="button"
                    block
                    color="transparent"
                    onClick={handleRemove}
                    colorText="danger"
                    style={{ marginLeft: '0' }}
                  >
                    Excluir Marca D’água
                  </Button>
                </div>
              ) : (
                <>
                  <Box>
                    <Field
                      showPercentage
                      label="Opacidade da marca d'água"
                      name="opacity"
                      min={0}
                      max={100}
                      component={Slider}
                    />
                  </Box>

                  <Box>
                    <Field
                      showPercentage
                      label="Tamanho da marca d'água"
                      name="proportion"
                      min={0}
                      max={100}
                      component={Slider}
                    />
                  </Box>

                  <Field
                    label="Posição de marca d'água"
                    name="position"
                    component={Position}
                  />

                  <FieldBool
                    required
                    responsive={false}
                    canRemoveSelf
                    name="should_apply"
                    label="Aplicar nas imagens já cadastradas"
                    size={250}
                  />
                </>
              )}
            </Right>
          </Content>
        </Container>
        {!is_ready_to_apply && (
          <FixedBar>
            <FixedBar.spacer />
            <Button disabled={submitting} type="submit" color="success">
              Usar marca d'água
            </Button>
          </FixedBar>
        )}
      </Wrapper.container>
    </form>
  );
};

export default reduxForm({
  form: 'WatermarkForm',
  onSubmit: async (values, dispatch, props) => {
    if (values?.should_apply) {
      dispatch(
        openConfirmation({
          title: 'Aplicar Marca D’água',
          text: (
            <>
              A aplicação da <strong>marca d’água</strong>, pode levar minutos
              ou até horas, dependendo do volume de imagens do seu banco de
              dados. Apenas clique em aplicar, se estiver certo que a posição e
              a imagem a ser estampada estejam aprovadas.
            </>
          ),
          request: async () => {
            try {
              const { data } = await watermarkService.update(values);
              props.initialize({ ...data, should_apply: false });
            } catch {
              ReactAlert.success(`Erro ao atualizar marca d'água`);
            }
          },
          onSuccess: (res) => {},
          submitButtonText: `Aplicar marca d'água`,
          submitButtonColor: 'success',
          cancelButtonText: 'Cancelar',
        })
      );
    } else {
      try {
        const { data } = await watermarkService.update(values);
        props.initialize({ ...data, should_apply: false });
      } catch (err) {
        const data = err?.response?.data;

        throw new SubmissionError(data);
      }
    }
    // return dispatch();
  },
})(Watermark);
