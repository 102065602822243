import React, { useState } from 'react';
import { size } from 'lodash/collection';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { MdDelete, MdEdit } from 'react-icons/md';
import CardStage from '../CardStageBkp';
import Loading from '../Loading';
import FormStageAdd from '../FormStageAdd';
import Popover from '../Popover';
import styled from 'styled-components';
import { readableColor } from 'polished';

const Btn = styled.button`
  color: ${(p) => readableColor(p.color)};
`;

const BtnDanger = styled.button`
  color: ${(p) => readableColor(p.color, '#000', 'white')};
`;

const propTypes = {
  funnelId: PropTypes.string,
  isLoading: PropTypes.bool,
  stages: PropTypes.objectOf(PropTypes.object),
  handleDragEnd: PropTypes.func.isRequired,
  handleRemoveStage: PropTypes.func.isRequired,
};

const defaultProps = {
  funnelId: null,
  isLoading: null,
  stages: null,
};

const styles = {
  stage: {
    marginRight: '10px',
    flexShrink: 0,
  },
};

const StagesDraggable = ({
  funnelId,
  isLoading,
  stages,
  handleDragEnd,
  handleRemoveStage,
  handleUpdateStage,
}) => {
  const [isDragDisabled, setDragDisabled] = useState(false);

  const hasStages = size(stages) > 0;

  if (isLoading) return <Loading isVisible />;

  return (
    <DragDropContext
      onDragEnd={(result) => handleDragEnd(result, funnelId, stages)}
    >
      <Droppable droppableId="stage" direction="horizontal">
        {(provided, snapshot) => (
          <ul
            ref={provided.innerRef}
            className="h-list--none h-flex"
            style={{ overflow: 'auto', marginBottom: '15px' }}
            {...provided.droppableProps}
          >
            {hasStages &&
              Object.keys(stages).map((key, index) => {
                const stage = stages[key];
                const { id, name } = stage;

                return (
                  <Draggable
                    isDragDisabled={isDragDisabled}
                    key={id}
                    draggableId={id}
                    index={index}
                  >
                    {({
                      innerRef,
                      draggableProps: {
                        style: styleDraggable,
                        ...restDraggableProps
                      },
                      dragHandleProps,
                    }) => (
                      <li
                        ref={innerRef}
                        className="h-flex__cell"
                        {...restDraggableProps}
                        {...dragHandleProps}
                        style={{
                          ...styleDraggable,
                          marginRight: 10,
                          flexShrink: 0,
                          maxWidth: 200,
                        }}
                      >
                        <CardStage
                          title={name}
                          color={stage?.color}
                          style={styles.stage}
                        >
                          <Popover
                            initialValues={stage}
                            onSubmit={(values) => {
                              return handleUpdateStage(funnelId, id, values);
                            }}
                            onOpen={() => {
                              setDragDisabled(true);
                            }}
                            onClose={() => {
                              setDragDisabled(false);
                            }}
                            component={FormStageAdd}
                            saveButtonText="Editar etapa"
                          >
                            <Btn
                              color={stage?.color}
                              type="button"
                              className="h-margin-right--5"
                            >
                              <MdEdit />
                            </Btn>
                          </Popover>

                          {handleRemoveStage && (
                            <BtnDanger
                              color={stage?.color}
                              onClick={() => handleRemoveStage(funnelId, stage)}
                            >
                              <MdDelete />
                            </BtnDanger>
                          )}
                        </CardStage>
                      </li>
                    )}
                  </Draggable>
                );
              })}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

StagesDraggable.propTypes = propTypes;
StagesDraggable.defaultProps = defaultProps;

export default StagesDraggable;
