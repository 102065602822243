import React from 'react';
import classnames from 'classnames';
import InputAutosize from 'react-input-autosize';
import { MdAdd, MdRemove } from 'react-icons/md';

class Counter extends React.PureComponent {
  static defaultProps = {
    min: 1,
    max: 9999,
    initialValue: 1
  };

  setValue(value) {
    let currentValue = parseInt(value, 10);
    const {
      max,
      min,
      input: { onChange }
    } = this.props;

    if (!value) {
      onChange(min);
      return null;
    }

    // Não invoca o change se o valor for maior que o maximo
    if (currentValue > max || currentValue < min) return null;

    onChange(currentValue);
  }

  handleChangeInput = e => {
    this.setValue(e.target.value);
  };

  handleClickAdd = () => {
    const {
      input: { value }
    } = this.props;

    this.setValue(value + 1);
  };

  handleClickRemove = () => {
    const {
      input: { value }
    } = this.props;

    this.setValue(value - 1);
  };

  get isMinimun() {
    const {
      input: { value },
      min
    } = this.props;

    return value === min;
  }

  get isMaximum() {
    const {
      input: { value },
      max
    } = this.props;

    return value === max;
  }

  render() {
    const { input } = this.props;
    const { value } = input;

    return (
      <div className="Counter">
        <button
          type="button"
          onClick={this.handleClickRemove}
          className={classnames('Counter__button', {
            isDisabled: this.isMinimun
          })}
        >
          <span className="Counter__ball">
            <MdRemove />
          </span>
        </button>
        <InputAutosize
          className="Counter__number"
          onChange={this.handleChangeInput}
          value={value}
        />
        <button
          type="button"
          onClick={this.handleClickAdd}
          className={classnames('Counter__button', {
            isDisabled: this.isMaximum
          })}
        >
          <span className="Counter__ball">
            <MdAdd />
          </span>
        </button>
      </div>
    );
  }
}

export default Counter;
