import React from 'react';
import PropTypes from 'prop-types';
// Components
import ItemButton from '../../ItemButton';

function MenuViewSite({ url }) {
  return (
    <ItemButton>
      <a
        href={url}
        className="Button Button--medium Button--success h-color--white js-wizard-button-site"
        rel="noopener noreferrer"
        target="_blank"
        style={{ whiteSpace: 'nowrap' }}
      >
        Ver Site
      </a>
    </ItemButton>
  );
}

MenuViewSite.defaultProps = {
  url: null
};

MenuViewSite.propTypes = {
  url: PropTypes.string
};

export default MenuViewSite;
