// Modules
import createDataReducer from 'modules/data';
// Services
import * as propertiesService from 'services/sites/properties';

export const {
  selectors: propertyDetailsSelectors,
  actionCreators: propertyDetailsActions,
  reducer
} = createDataReducer('components/propertyDetails');

/**
 * Busca todos os modelos de cabeçalhos
 * @param params
 * @return {Promise}
 */
export const fetchComponents = params => dispatch => {
  dispatch(propertyDetailsActions.request());

  return propertiesService.getComponentsDetails(params).then(res => {
    dispatch(propertyDetailsActions.receive(res.data));
    return res;
  });
};

export default reducer;
