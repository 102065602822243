import React from 'react';
import { Field } from 'redux-form';
import { Input } from 'components/Form';
import { normalizeDate } from 'lib/formHelpers';
import { Col } from 'react-flexbox-grid';
import { Label } from 'containers/ModalRoot/ModalCrmRedirectContact/components/Form/styles';
import useFormValue from 'hooks/useFormValue';
import { isValidDate } from 'lib/date-helpers';
import moment from 'lib/moment';
import { Wrapper } from './styles';
import { MdInfo } from 'react-icons/md';

const LabelField = () => {
  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      Reservar até qual data?{' '}
      <MdInfo
        className="h-margin-left--5 h-color--secondary"
        data-tip="Quando esta data chegar, você receberá uma notificação. A reserva não é cancelada automaticamente."
      />
    </div>
  );
};

function ReservationField({ name = 'booked_until', change }) {
  const date = useFormValue(name);

  const getDate = () => {
    return isValidDate(date) ? date : moment().format('DD/MM/YYYY');
  };

  const handleAddDays = (days) => () => {
    const date = getDate(name);

    change(
      name,
      moment(date, 'DD/MM/YYYY').add(days, 'days').format('DD/MM/YYYY')
    );
  };

  return (
    <Col xs={6}>
      <Wrapper>
        <Field
          label={<LabelField />}
          name={name}
          component={Input}
          normalize={normalizeDate}
        >
          <div className="h-margin-left--10">
            <Label onClick={handleAddDays(15)}>+15</Label>
            <Label onClick={handleAddDays(30)}>+30</Label>
            <Label onClick={handleAddDays(60)}>+60</Label>
          </div>
        </Field>
      </Wrapper>
    </Col>
  );
}

export default ReservationField;
