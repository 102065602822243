import styled from 'styled-components';

export const RowInfos = styled.tr`
  td {
    padding-top: 5px;
  }
`;

export const RowDescription = styled.tr`
  td {
    padding-bottom: 5px;
  }
`;

export const Td = styled.td`
  padding-left: 10px;
`

export const CellOptions = styled.td.attrs(() => ({
  className: 'Table__cell--small',
}))`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  vertical-align: middle;

  button {
    padding-left: 15px;
    padding-right: 8px;
  }
`;

export const RowLine = styled.tr`
  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  :first-child {
    display: none;
  }
`;

export const Line = styled.div`
  border-top: 1px solid #e1e6ec;
  width: 100%;
`;
