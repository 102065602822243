import React, { useRef } from 'react';
import Alert from 'react-s-alert';
import Clipboard from 'react-clipboard.js';
import { Wrapper, Copy } from './styles';
import Button from 'components/Button';

const PixInput = ({ value }) => {
  const $input = useRef();
  return (
    <>
      <Wrapper>
        <input
          ref={$input}
          readOnly
          value={value}
          onClick={() => $input?.current?.select()}
        />
        <Clipboard
          data-clipboard-text={value}
          onClick={() => {
            Alert.success('Código copiado');
          }}
        >
          <Copy>Copiar</Copy>
        </Clipboard>
      </Wrapper>
      <Clipboard
        data-clipboard-text={value}
        onClick={() => {
          Alert.success('Código copiado');
        }}
      >
        <Button color="tertiary" className="h-margin-top--10">
          Copiar código
        </Button>
      </Clipboard>
    </>
  );
};

export default PixInput;
