import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  background: #ffffff;
  border-left: 4px solid ${(p) => p.color || '#0063c0'};
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.05);
  min-height: 70px;
  min-width: 140px;
`;

export const List = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
  color: #4c555f;

  li {
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-size: 14px;
  color: #5c5260;
  font-weight: 400;
`;

export const BigText = styled.p`
  font-size: 20px;
  align-self: flex-end;
`;
