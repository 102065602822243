import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 39px);
  padding: 10px 20px;
  background: #f5f5f6;
  margin: -20px -19px 30px;
`;
