import api from 'services';
import _ from 'lodash';

export const getOne = id =>
  api.getOne(`properties/partial/${id}/areas`, '').then(res => {
    return res;
  });

const getArea = (area, areaFields) => {
  const currentArea = areaFields.find(({ name }) => {
    return name === area?.name;
  });

  // Verifica se dentro das medidas disponíveis
  if (!currentArea?.measures?.includes(area?.measure)) {
    return {
      ...area,
      measure: currentArea?.measures?.[0]
    };
  }

  return area;
};

const transformData = data => {
  const areaFields = data?.type?.area_fields;

  const areasArray = _.map(data?.areas, (area, areaName) => {
    return getArea({ ...area, name: areaName }, areaFields);
  });

  // Seta as areas corrigidas
  data.areas = _.keyBy(areasArray, 'name');

  return data;
};

export const update = ({ id, ...data }) => {
  return api.patch(`properties/partial/${id}/areas`, transformData(data));
};
