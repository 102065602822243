import React, { useCallback } from 'react';
import Modal from 'react-modal';
import Form from './components/Form';
import * as crmService from 'services/crm';

function ModalAddDeal({
  userId,
  funnelId,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onSuccess,
  initialValues = {},
}) {
  const onSubmit = useCallback((values) => {
    return crmService.create(values);
  }, []);

  const onSubmitSuccess = useCallback(
    (...rest) => {
      handleClose();
      if (onSuccess) onSuccess(...rest);
    },
    [onSuccess]
  );

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Form
        userId={userId}
        funnelId={funnelId}
        initialValues={{
          ...initialValues,
          user_id: userId,
        }}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        onSuccess={onSubmitSuccess}
        handleClose={handleClose}
      />
    </Modal>
  );
}

export default ModalAddDeal;
