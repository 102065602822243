import api, { apiStorage } from 'services';

/**
 * Pega as informações do v-card
 * @returns {Promise}
 */
export const getOne = async (id) => {
  try {
    return api.get(`users/${id}/vcards/`);
  } catch {
    return { data: null };
  }
};

/**
 * Cria um v-card ou atualiza um existente
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createOrUpdate = async ({ id, image, user, ...data }) => {
  try {
    const response = await api.create(`users/${id}/vcards`, data);

    if (image) {
      apiStorage.uploadFile(`users/${id}/vcards`, { image });
    }

    return response;
  } catch (err) {
    throw err;
  }
};
