import { withValidation } from 'lib/validate-helpers';

export default values => {
  const { errors, validations } = withValidation(values);

  validations.required(['number', 'holder_name', 'expiration_date', 'cvv']);

  if (values?.recurring_charge === null) {
    errors.recurring_charge = ['Defina a recorrência'];
  }

  return errors;
};
