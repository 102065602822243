import api, { apiStorage } from 'services/index';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';

export const getList = (params) =>
  api.getList('settings/sites/slide-home-items', { ...params, sort: null });
export const getOne = ({ id, ...params }) =>
  api.getOne('settings/sites/slide-home-items', id, params);

export const uploadImage = ({ id, image }) =>
  apiStorage.uploadFile(`sites/slide-home-items/${id}`, { image });

export const create = async ({ image, ...values }) => {
  try {
    if (!image && !values?.image_id) {
      throw new SubmissionError({ image: 'Imagem obrigatória' });
    }
    // Faz o cadastro do slide
    const {
      data: { id },
    } = await api.create('settings/sites/slide-home-items', values);

    if (!values?.image_id) {
      await uploadImage({ id, image });
    }

    Alert.success('Slide cadastrado');
  } catch (err) {
    if (err?.response?.data?.image) {
      throw new SubmissionError({ image: 'Problema no upload da imagem' });
    }
    throw err;
  }
};
export const update = async ({ image, ...values }) => {
  try {
    // Faz o cadastro do slide
    const {
      data: { id },
    } = await api.create(
      `settings/sites/slide-home-items/${values.id}`,
      values
    );

    if (!!image) {
      await uploadImage({ id, image });
    }

    Alert.success('Slide Atualizado');
  } catch (err) {
    if (err?.response?.data?.image) {
      throw new SubmissionError({ image: 'Problema no upload da imagem' });
    }
    throw err;
  }
};
export const remove = (slideId, params) =>
  api.delete(`settings/sites/slide-home-items/${slideId}`, params);
export const reactivate = (slideId, params) =>
  api.create(`settings/sites/slide-home-items/${slideId}/reactivate`, params);
export const getComponents = (params) =>
  api.getList('settings/component-examples/slide-home-items', params);
