import { createSelector } from 'reselect';
// Modules
import dataReducer from '../../../../modules/data';
// Services
import * as dealsService from '../../../../services/deals';

// Helpers
// import { createModule } from '../../../../lib/reducer-helpers';

const MODULE_NAME = 'peoplePositionFunnel';

export const {
  reducer: peopleFunnelReducer,
  actionCreators: peopleFunnelActions
} = dataReducer(`peopleDashboard/${MODULE_NAME}`);

// Selectors
export const dataSelector = state => state.peopleDashboard[MODULE_NAME].data;
export const isFetchingSelector = state =>
  state.peopleDashboard[MODULE_NAME].meta.isFetching;

export const currentFunnelSelector = createSelector(
  [dataSelector],
  data => data.find(item => item.isSelected) || {}
);

export const funnelsSelector = createSelector(
  [dataSelector],
  funnels => funnels.filter(funnel => !funnel.isSelected) || []
);

export const stagesSelector = createSelector(
  [currentFunnelSelector],
  currentFunnel => currentFunnel.stages
);

// Reducer
export const fetchPeoplePositionFunnel = params => dispatch => {
  dispatch(peopleFunnelActions.request());

  return dealsService
    .getFunnels({
      ...params,
      include: 'stages'
    })
    .then(({ data }) => {
      dispatch(peopleFunnelActions.receive(data));

      // Marca o primeiro como selecionado
      dispatch(peopleFunnelActions.alter({ ...data[0], isSelected: true }));
      return data;
    });
};

export const selectFunnel = funnel => dispatch => {
  dispatch(peopleFunnelActions.alterAll({ isSelected: false }));
  dispatch(peopleFunnelActions.alter({ ...funnel, isSelected: true }));
};

export default peopleFunnelReducer;
