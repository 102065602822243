import React from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const Chart = ({ width, height, data }) => (
  <div style={{ width, height }}>
    <ResponsiveContainer>
      <AreaChart
        width={600}
        height={200}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="ga:yearMonth" />
        <YAxis />
        <Tooltip cursor={{ fill: '#f0f2f5' }} />
        <Area
          type="monotone"
          name="Visualizações"
          dataKey="ga:pageviews"
          fill="#c4d9fd"
        />
        <Area
          type="monotone"
          name="Total de usuários"
          dataKey="ga:newUsers"
          fill="red"
        />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

export default Chart;
