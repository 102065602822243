import { ModalTemplate } from 'components/Modal';
import Modal from 'react-modal';
import Form from './components/Form';
import * as condosService from 'services/condos';

function ModalCondoDelete({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  condo,
  onSuccess,
}) {
  const handleSubmit = (data) => {
    if (data.action === '2' && data.condominium_id) {
      return condosService.transferAndDelete(condo.id, data.condominium_id);
    }

    return condosService.remove(condo.id);
  }

  const handleSuccess = () => {
    if (onSuccess) onSuccess();
    handleClose();
  }

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Exclusão de condomínio/empreendimento"
        handleClose={handleClose}
      >
        <Form
          condo={condo}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalCondoDelete;
