import styled, { css } from 'styled-components';
import Image from 'components/Image';
import Label from 'components/Label';

// const aspectRatio = ({ theme: { height }, aspectRatio }) => {
//   if (height) {
//     return css`
//       height: ${height}px;
//     `;
//   }

//   const [X, Y] = aspectRatio;

//   return css`
//     padding-bottom: ${(Y / X) * 100}%;
//   `;
// };

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.2s ease-in-out;

  ${({ theme: { hasFile } }) => {
    if (hasFile) return null;

    return css`
      opacity: 1;
      visibility: visible;
      background-color: #d3dceb;
    `;
  }}
`;

export const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;

  ${(p) =>
    p.actions === 'column'
      ? css`
          flex-direction: column;

          .Button + .Button {
            margin: 10px 0 0;
          }
        `
      : null}
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${(p) => (p.theme.hasFile ? '#fff' : p.theme.colors.primary)};
  margin-bottom: 15px;
  text-align: center;
`;

export const Img = styled(Image)`
  max-width: 100%;
  object-fit: cover;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  position: relative;
  display: block;

  :hover ${Overlay} {
    opacity: 1;
    visibility: visible;
  }
`;

export const WrapperRecomend = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const Recomended = styled(Label).attrs(() => ({
  color: 'success',
}))``;
