import React from 'react';
import PropTypes from 'prop-types';

function BoxItem({ children, spacing, style }) {
  return (
    <div className="MainBox__item" style={{ padding: spacing, ...style }}>
      {children}
    </div>
  );
}

BoxItem.propTypes = {
  spacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

BoxItem.defaultProps = {
  spacing: null,
  children: null
};

export default BoxItem;
