import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  background: #f9fbfc;
  border-spacing: 0;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  overflow: hidden;

  .is-danger td {
    background: #ffcbcb !important;
  }

  thead tr th {
    background: #f4f4f4;
  }

  td,
  th {
    padding: 10px;
  }

  td {
    border-top: 1px solid #d8d8d8;
  }

  td button {
    cursor: pointer;
  }
`;
