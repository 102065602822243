// import * as S from './styles'

import { openModal } from 'modules/modal';
import { useDestroyMoviment, useShowMoviment } from '../../hooks/useMoviments';
import { useEffect, useMemo } from 'react';
import { ModalTemplate } from 'components/Modal';
import FormMovimentDestroy from './components/FormMovimentDestroy';
import Modal from 'react-modal';

export const openModalMovimentDestroy =
  ({ movimentId, postingId, onSuccess }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalMovimentDestroy',
        size: 470,
        position: 'center',
        props: {
          movimentId,
          postingId,
          onSuccess,
        },
      })
    );
  };

export function ModalMovimentDestroy({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  movimentId,
  postingId,
  onSuccess,
}) {
  const { moviment, movimentIsFetching, fetchMoviment } = useShowMoviment();
  const { destroyMoviment } = useDestroyMoviment();

  const hasRecurrency = useMemo(() => {
    return moviment?.posting?.installments !== 1;
  }, [moviment]);

  useEffect(() => {
    if (movimentId && postingId) {
      fetchMoviment({ postingId, movimentId });
    }
  }, [movimentId, postingId]);

  const onSubmitSuccess = (res) => {
    if (onSuccess) onSuccess(res);
    handleClose();
  };

  if (movimentIsFetching) return null;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Excluir lançamento" handleClose={handleClose}>
        <FormMovimentDestroy
          hasRecurrency={hasRecurrency}
          handleClose={handleClose}
          onSubmit={(values) => {
            return destroyMoviment({
              postingId,
              movimentId,
              ...values,
            });
          }}
          onSubmitSuccess={onSubmitSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}
