import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega os dados das unidades
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const get = buildingId =>
  api.getList(`settings/sites/buildings/${buildingId}/units`);

/**
 * Atualiza os dados da unidade
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = ({ id, ...values }) => {
  return api.patch(`settings/sites/buildings/${id}/units`, values);
};

/**
 * Remove um imóvel da listagem de unidades
 * @param buildingId
 * @param propertyId
 * @return {Promise<AxiosResponse<any>>}
 */
export const remove = (buildingId, propertyId) =>
  api
    .delete(`settings/sites/buildings/${buildingId}/units`, '', {
      params: {
        property_id: propertyId
      }
    })
    .then(responseMessage('Unidade removida'));

/**
 * Remove todos os imóveis da listagem de unidades
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const removeAll = buildingId =>
  api
    .delete(`settings/sites/buildings/${buildingId}/units`, '')
    .then(responseMessage('Unidades removidas'));

/**
 * Adiciona um imóvel pela referencia
 * @param buildingId
 * @param reference
 * @return {Promise<AxiosResponse<any>>}
 */
export const addUnity = ({ buildingId, reference }) =>
  api
    .create(`settings/sites/buildings/${buildingId}/units-by-property`, {
      reference
    })
    .then(responseMessage('Unidade adicionada'));

/**
 * Adiciona vários imóveis pelo id do condo
 * @param buildingId
 * @param condo_id
 * @return {Promise<AxiosResponse<any>>}
 */
export const addUnityByCondo = ({ buildingId, condo_id }) =>
  api
    .create(`settings/sites/buildings/${buildingId}/units-by-condo`, {
      condo_id
    })
    .then(responseMessage('Unidades adicionadas'));
