import { Header } from 'features/Financial/components/Header';
import { Financial } from 'features/Financial';
import { TableMoviments } from './components/TableMoviments';
import { useMovimentsList } from 'features/Financial/hooks/usePostings';
import { MetaInformations } from './components/MetaInformations';
import { FilterPostings } from './components/FilterPostings';
import moment, { format } from 'lib/moment';

export function ListPostings() {
  const { fetchMoviments, isFetchingMoviments, moviments, movimentsMeta } =
    useMovimentsList();

  const initialValues = {
    start_at: moment().startOf('month').format(format.date),
    end_at: moment().endOf('month').format(format.date),
  };

  return (
    <>
      <Header.Header>
        <Header.Title>Lançamentos</Header.Title>
        <Header.Actions>
          <FilterPostings
            onSubmit={fetchMoviments}
            initialValues={initialValues}
            onAddPosting={fetchMoviments}
            onChange={fetchMoviments}
          />
        </Header.Actions>
      </Header.Header>
      <Financial.Content isFetching={isFetchingMoviments}>
        <TableMoviments data={moviments} />
        <MetaInformations meta={movimentsMeta} />
      </Financial.Content>
    </>
  );
}
