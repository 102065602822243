import React, { useEffect } from 'react';
import Alert from 'react-s-alert';
import Tooltip from 'react-tooltip';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import { Input, Select } from 'components/Form';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import SendTest from '../SendTest';
import * as smtpService from 'services/settings/smtp';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import useFormValue from 'hooks/useFormValue';

function FormSMTP({
  canRemove,
  isChangedForm,
  handleSubmit,
  handleClose,
  initialize,
  setIsLoading,
  setIsChangedForm,
  submitting,
  change,
  submit,
  onSubmitSuccess,
}) {
  const dispatch = useDispatch();
  const disabled = useFormValue('disabled');

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  const handleRemoveSMTP = async () => {
    dispatch(
      openConfirmation({
        title: 'Remover Configuração SMTP',
        text: 'Tem certeza que deseja remover a configuração do SMTP?',
        submitButtonText: 'Remover configuração',
        cancelButtonText: 'Cancelar',
        request: async () => {
          setIsLoading(true);
          const res = await smtpService.destroy();
          handleClose();
          return res;
        },
        onSuccess: () => {
          if (onSubmitSuccess) onSubmitSuccess();
        },
      })
    );
  };

  const reactivateSMTP = () => {
    submit({ disabled: false });
  };

  const handleClickSave = (e) => {
    if (isDisabled) {
      e.preventDefault();

      Alert.success(
        'Você precisa enviar um e-mail teste antes de salvar a configuração.'
      );
    }
  };

  const isDisabled = isChangedForm || submitting;

  return (
    <ModalTemplate
      title="Configuração de SMTP"
      titleActions={
        disabled ? (
          <Button onClick={reactivateSMTP} color="white">
            Reabilitar
          </Button>
        ) : null
      }
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <p className="h-margin-bottom--15">
          Informe seus dados de conexão com servidor de envio de e-mails:
        </p>

        <Row>
          <Field
            xs={7}
            required
            type="text"
            label="Endereço do servidor SMTP (HOST)"
            name="host"
            component={Input}
          />
          <Col xs={5}>
            <Row>
              <Field
                required
                xs={6}
                label="Porta"
                name="port"
                component={Input}
              />
              <Field
                required
                xs={6}
                label="Segurança"
                name="encryption"
                component={Select}
                placeholder=""
                options={[
                  { label: 'SSL', value: 'ssl' },
                  { label: 'TLS', value: 'tls' },
                ]}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Field
            required
            xs={7}
            label="Usuário (USERNAME)"
            name="username"
            component={Input}
          />
          <Field
            required
            xs={5}
            label="Senha (PASSWORD)"
            name="password"
            component={Input}
          />
        </Row>
        <Row>
          <Field
            required
            xs={12}
            label="Nome do remetente"
            name="from_name"
            component={Input}
          />
          <Field
            required
            xs={12}
            label="E-mail do remetente"
            name="from_address"
            component={Input}
          />
        </Row>
        <ModalFooter>
          <Button type="button" color="white" onClick={handleClose}>
            Cancelar
          </Button>
          <div className="h-flex__cell--grow" />
          {canRemove && (
            <Button type="button" color="danger" onClick={handleRemoveSMTP}>
              Excluir
            </Button>
          )}
          <SendTest
            setIsLoading={setIsLoading}
            onSuccess={({ to, ...data }) => {
              setIsChangedForm(false);
              setIsLoading(false);
            }}
          />

          <div
            data-tip={
              isDisabled
                ? 'É preciso enviar e-mail de teste <br/> antes de salvar a configuração do SMTP'
                : undefined
            }
          >
            <Button
              onClick={handleClickSave}
              disabled={isDisabled}
              type="submit"
              color="success"
            >
              Salvar
            </Button>
          </div>
        </ModalFooter>
      </form>
    </ModalTemplate>
  );
}

export default reduxForm({
  form: 'FormSMTP',
  enableReinitialize: true,
})(FormSMTP);
