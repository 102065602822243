import * as S from './styles';
import {
  Header,
  Title,
  Wrapper,
} from 'containers/TableLastLeadsReceived/styles';
import React from 'react';
import { useGetLeadsByDays } from 'hooks/api/dashboard';
import { useDispatch } from 'react-redux';
import { openModalPerson } from 'modules/modal';
import { NavLink } from 'react-router-dom';

export default function LeadsComponent() {
  const dispatch = useDispatch();
  const { leads } = useGetLeadsByDays();

  const displayedLeads = leads?.leads_list?.slice(0, 4);

  const handleOpenModalPerson = (personId) => () => {
    dispatch(openModalPerson({ id: personId }));
  };

  return (
    <Wrapper>
      <Header>
        <Title>Contatos recebidos nos últimos 7 dias</Title>
      </Header>
      <S.Container>
        <S.LeadsGenerated>
          <S.LeadsTitle>Leads Gerados no período</S.LeadsTitle>
          <S.LeadsContent>
            <S.LeadsNumber>
              {leads?.total_count?.toString()?.padStart(2, '0')}
            </S.LeadsNumber>
          </S.LeadsContent>
        </S.LeadsGenerated>
        <S.TableContainer>
          <S.Table>
            <thead>
              <tr>
                <S.TableHeader width={124}>Data</S.TableHeader>
                <S.TableHeader width={130}>Canal de origem</S.TableHeader>
                <S.TableHeader>Cliente</S.TableHeader>
              </tr>
            </thead>
            <tbody>
              {displayedLeads?.length <= 0 && (
                <tr>
                  <S.TableNodata colSpan={3}>
                    <p>Nenhum lead gerado nos ultimos 7 dias</p>
                    <NavLink
                      to="/crm/contacts"
                      className="h-link h-margin-top--10"
                      style={{ display: 'inline-block' }}
                    >
                      Ver contatos anteriores
                    </NavLink>
                  </S.TableNodata>
                </tr>
              )}
              {displayedLeads?.map((item, index) => (
                <tr key={index} onClick={handleOpenModalPerson(item.person_id)}>
                  <S.TableData>{item.created_at}</S.TableData>
                  <S.TableData>{item.reception_source_name}</S.TableData>
                  <S.TableData>{item.person_name}</S.TableData>
                </tr>
              ))}
            </tbody>
          </S.Table>
          {leads?.leads_list?.length > 1 && (
            <S.SeeMore to="/crm/contacts">Ver mais</S.SeeMore>
          )}
        </S.TableContainer>
      </S.Container>
    </Wrapper>
  );
}
