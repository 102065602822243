import React from 'react';
import TextLoading from 'components/TextLoading';
import { Number } from './styles';

function NumberCm({ isFetching, children }) {
  if (isFetching) {
    return <TextLoading width={50} height={14} />;
  }

  return <Number>{children}</Number>;
}

export default NumberCm;
