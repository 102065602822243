import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Constants
import { MENU_ITEMS } from '../../constants';
// Modules
import {
  openModalPerson,
  openModalProperty,
  openModalPropertyRent,
  openModalPropertySell,
  openModalShareProperty,
  openModalVisit,
} from 'modules/modal';
// Components
import Loading from 'components/Loading';
import ModalMenu from '../ModalMenu';
import OptionsProperty from '../OptionsProperty';
import ModalHeader from './components/ModalHeader';
// Modal Components
import ModalClose from 'components/Modal/components/ModalClose';
import ModalContent from 'components/Modal/components/ModalContent';
// Containers
import { myself, others } from 'containers/Can';
// HOC
import { withPermission } from 'HOC/withPermission';

export const propTypes = {
  menuItems: PropTypes.object,
};

export const defaultProps = {
  menuItems: MENU_ITEMS,
};

class ModalDefault extends React.Component {
  get property() {
    const { infos, property } = this.props;
    return infos || property || null;
  }

  get isMyProperty() {
    const { id } = localStorage.getItem('current-user');
    return this.property.user_id === id;
  }

  get canRegister() {
    return this.props.permissions[myself.properties.register];
  }

  get canEditOther() {
    return this.props.permissions[others.properties.edit];
  }

  get canEdit() {
    return this.canRegister || (this.canEditOther && this.isMyProperty);
  }

  get isSell() {
    return this.property.transaction === 1 || this.property.transaction === '1';
  }

  get renderDropdown() {
    const { infos } = this.props;
    return <OptionsProperty {...this.props} infos={infos} property={infos} />;
  }

  get renderHeader() {
    const {
      infos,
      renderHeader,
      openModalShareProperty,
      openModalProperty,
      openModalPerson,
    } = this.props;

    // Verifica se tem função para renderizar header
    // se existir invoca ela passando como argumento as props
    if (renderHeader) return renderHeader(this.props);

    return (
      <ModalHeader
        property={infos}
        title={infos.title_formatted}
        text={infos.address_formatted}
        openModalShareProperty={openModalShareProperty}
        openModalProperty={openModalProperty}
        openModalPerson={openModalPerson}
      >
        {this.renderDropdown}
      </ModalHeader>
    );
  }

  get isOnNetwork() {
    const property = this.property;
    return property?.is_on_network;
  }

  get renderLoading() {
    return this.props.isFetching && <Loading isVisible />;
  }

  get menuItems() {
    const isOnNetwork = this.isOnNetwork;
    const {
      deals_without_pipeline_count,
      documents_count,
      matcheds_sent_count,
      notes_count,
      notes_auth_count,
      network_type,
      bookings_active_count,
      matcheds_new_count,
    } = this.property;

    let menus = {};

    if (isOnNetwork && network_type !== 'Órulo' && network_type !== 'DWV')
      return {
        FICHA: 'Record',
        [`RADAR (${matcheds_new_count})`]: 'Radar',
      };

    if (!this.property?.permissions) {
      return menus;
    }

    if (isOnNetwork && (network_type === 'Órulo' || network_type === 'DWV')) {
      menus = {
        FICHA: 'Record',
        [`CRM (${deals_without_pipeline_count || 0})`]: 'Deals',
        [`ENVIOS (${matcheds_sent_count || 0})`]: 'Send',
      };

      if (this.property.permissions.includes('DYNAMIC_STORIES')) {
        menus = {
          ...menus,
          HISTÓRICO: 'History',
        };
      }

      if (this.property.permissions.includes('DYNAMIC_NOTES')) {
        menus = {
          ...menus,
          [`ANOTAÇÕES (${notes_auth_count || 0})`]: 'Notes',
        };
      }

      return menus;
    }

    menus = {
      FICHA: 'Record',
      [`RADAR (${matcheds_new_count})`]: 'Radar',
      [`CRM (${deals_without_pipeline_count || 0})`]: 'Crm',
    };

    if (this.property.permissions.includes('DYNAMIC_NOTES')) {
      menus = {
        ...menus,
        [`ANOTAÇÕES (${notes_count || 0})`]: 'Notes',
      };
    }

    menus = {
      ...menus,
      [`DOCUMENTOS (${documents_count || 0})`]: 'Documents',
      [`ENVIOS (${matcheds_sent_count || 0})`]: 'Send',

      [`RESERVAS (${bookings_active_count || 0})`]: 'Reservations',
      [`NEGÓCIOS`]: 'Deals',
    };

    if (this.property.permissions.includes('DYNAMIC_STORIES')) {
      menus = {
        ...menus,
        HISTÓRICO: 'History',
      };
    }

    if (this.property.permissions.includes('DYNAMIC_ANALYSIS')) {
      menus = {
        ...menus,
        [`ESTATÍSTICAS`]: 'Reports',
      };
    }

    return menus;
  }

  get renderMenu() {
    const { currentPage, handleChangeMenuItem } = this.props;

    return (
      <ModalMenu
        currentPage={currentPage}
        items={this.menuItems}
        onClickItem={handleChangeMenuItem}
      />
    );
  }

  render() {
    const {
      // Modal Props
      isOpen,
      modalConfig,
      modalType,

      // Event Handlers
      handleCloseModal,
      handleRenderPage,

      // Bool Fields
      isFetching,
    } = this.props;

    return (
      <Modal
        {...modalConfig}
        className="Modal Modal--large"
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleCloseModal}
      >
        <div className="Modal__wrapper">
          <ModalClose handleClose={handleCloseModal} />
          {this.renderHeader}
          <ModalContent>
            {this.renderMenu}
            {this.renderLoading}
            {!isFetching && handleRenderPage(this.props)}
          </ModalContent>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  openModalProperty,
  openModalPerson,
  openModalPropertySell,
  openModalPropertyRent,
  openModalShareProperty,
  openModalVisit,
};

export default compose(
  withPermission({
    rules: [myself.properties.register, others.properties.edit],
  }),
  connect(null, mapDispatchToProps)
)(ModalDefault);
