import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Imagens de exemplo da popup da galeria
 * @param params
 * @return {Promise}
 */
export const getComponents = params => {
  return api.getList('settings/component-examples/gallery-popups', {
    sort: ''
  });
};

/**
 * Pega os dados iniciais
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getFormData = () => api.getOne('settings/sites/galleries', '');

/**
 * Atualizando os dados do formulário
 * @param values
 * @returns {Promise<AxiosResponse<T>>}
 */
export const update = values =>
  api
    .update('settings/sites/galleries', values)
    .then(responseMessage('Salvo!'));
