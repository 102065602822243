import React from 'react';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Modules
import {
  fetchBankSlip,
  fetchCards,
  getBankSlip,
  getCards,
  onSubmit,
  receiveBankSlip,
  addCard,
  requestBankSlip,
} from 'modules/plan';
// Services
import * as creditsService from 'services/financial/credits';
// Components
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import FormPlanInstallment from 'components/FormPlanInstallment';
import { getCurrentUser } from 'modules/login';

class PlanContract extends React.Component {
  static defaultProps = {
    match: {
      params: {
        months: null,
        users: null
      }
    }
  };

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        credit_id: PropTypes.string
      })
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      credit: {},
      initialValues: {
        receiving_method: 2
      },
      isFetching: false
    };
  }

  get creditId() {
    try {
      return this.props.match.params.credit_id;
    } catch {
      throw new Error('credit_id não informado!');
    }
  }

  /**
   * Retorna os dados do boleto
   * @return {{}|*}
   */
  get bankSlip() {
    try {
      return this.props.bankSlip.data;
    } catch {
      return {};
    }
  }

  /**
   * Retorna os dados do cartão de crédito
   * @return {{}|*}
   */
  get creditCard() {
    try {
      return this.props.creditCard.data;
    } catch {
      return {};
    }
  }

  /**
   * Checa se tem as informações do cartão de crédito
   * @return {boolean}
   */
  get hasCreditCard() {
    return !!this.creditCard.id;
  }

  /**
   * Checa se tem as informações de boleto bancário
   * @return {boolean}
   */
  get hasBankSlip() {
    return !!this.bankSlip.id;
  }

  componentDidMount() {
    this.fetchAllData();
  }

  fetchAllData = () => {
    const { fetchBankSlip, fetchCards } = this.props;

    // Busca os dados do boleto
    fetchBankSlip();

    // Busca os dados do cartão de crédito
    fetchCards();

    // Busca os dados do crédito que vai ser pago
    this.fetchCredit();
  };

  fetchCredit = async () => {
    this.setState({ isFetching: true });

    try {
      const { data: credit } = await creditsService.getOne(this.creditId);
      this.setState({ credit });
    } catch (e) {
      Alert.success('Problema ao buscar dados do crédito');
    } finally {
      this.setState({ isFetching: false });
    }
  };

  onSubmit = values => {
    return this.props.onSubmit({ credit_id: this.creditId, ...values });
  };

  onAddCardSuccess = ({ data: card }) => {
    const { addCard } = this.props;
    addCard(card);
  };

  onBankSlipSuccess = bankSlip => {
    const { requestBankSlip, receiveBankSlip } = this.props;
    if (bankSlip.id) {
      requestBankSlip();
      receiveBankSlip(bankSlip);
    }
  };

  render() {
    const { bankSlip, creditCard } = this.props;
    const { credit, isFetching } = this.state;

    return (
      <Wrapper.inner>
        <Wrapper.container>
          <MainTitle
            title="Pagamento de parcela"
            text="Escolha o método de pagamento abaixo."
          />
          <FormPlanInstallment
            credit={credit}
            bankSlip={bankSlip}
            creditCard={creditCard}
            isLoading={isFetching}
            initialValues={this.state.initialValues}
            onSubmit={this.onSubmit}
            onAddCardSuccess={this.onAddCardSuccess}
            onBankSlipSuccess={this.onBankSlipSuccess}
          />
        </Wrapper.container>
      </Wrapper.inner>
    );
  }
}

const mapStateToProps = state => ({
  bankSlip: getBankSlip(state),
  creditCard: getCards(state)
});

const mapDispatchToProps = {
  onSubmit,
  getCurrentUser,
  fetchBankSlip,
  fetchCards,
  addCard,
  requestBankSlip,
  receiveBankSlip
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PlanContract);
