import React from 'react';
import { compose } from 'redux';
import { Row } from 'react-flexbox-grid';
import { reduxForm, Field } from 'redux-form';

// Component
import MainTitle from 'components/MainTitle';
import FormFixedBar from '../../components/FormFixedBar';
import { Textarea } from 'components/Form';
import ListOptions from 'components/ListOptions';
import TableUnitys from './components/TableUnitys';
import ImportCondo from './components/ImportCondo';
import ImportProperty from './components/ImportProperty';

// Services
import * as unitsService from 'services/buildings/units';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';
import { UnityProvider, useUnity } from 'pages/EnterpriseStore/pages/Unity/contexts/UnityContext';
import Content from 'pages/EnterpriseStore/components/Content';
import withProvider from 'HOC/withProvider';
import Reception from 'pages/EnterpriseStore/components/Reception';
import Button from 'components/Button';
import image from './reception-unity.png';
import { useReception } from 'hooks/useReception';

const CustomContent = ({ children }) => {
  const { isFetching } = useUnity();

  return (
    <Content padding isFetching={isFetching}>
      {children}
    </Content>
  );
};

const ProgressPage = ({ handleSubmit }) => {
  const { isVisible, handleClose } = useReception({
    key: 'UnityReception',
    canShowAgain: false
  });

  if (isVisible)
    return (
      <Reception
        image={image}
        title="Unidades"
        text="Se for um condominio, adicione todos os imóveis que o compõe."
      >
        <Button onClick={handleClose}>Adicionar Unidade</Button>
      </Reception>
    );

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Unidade"
        text="Se for um condominio, adicione todos os imóveis que o compõe."
      >
        <ImportProperty />
        <ImportCondo />
      </MainTitle>

      <CustomContent>
        <ListOptions>
          <ListOptions.item>
            <TableUnitys />
          </ListOptions.item>
          <ListOptions.item title="Texto complementar">
            <Row className="h-margin-top--15">
              <Field
                type="simple"
                xs={12}
                name="units_description"
                component={Textarea}
              />
            </Row>
          </ListOptions.item>
        </ListOptions>
      </CustomContent>
      <FormFixedBar />
    </form>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
    onSubmit: unitsService.update
  }),
  withProvider(UnityProvider)
)(ProgressPage);
