import React, { useEffect, useRef } from 'react';
import Tooltip from 'react-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  type: PropTypes.oneOf(['default', 'rounded']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string
};

const defaultProps = {
  type: 'default',
  size: 'medium',
  color: 'primary'
};

const IconBall = ({
  disabled,
  className,
  type,
  color,
  size,
  style,
  children,
  onClick,
  tooltip = '',
  tooltipPosition = 'bottom'
}) => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  return (
    <div
      ref={tooltipRef}
      data-tip={tooltip}
      data-place={tooltipPosition}
      className={classNames('IconBall', className, {
        'IconBall--disabled': disabled,
        [`IconBall--${type}`]: type,
        [`IconBall--${size}`]: size,
        [`IconBall--${color}`]: color,
        'h-pointer': !!onClick
      })}
      onClick={e => {
        if (onClick) onClick(e);
        Tooltip.hide(tooltipRef.current);
      }}
      style={style}
    >
      {children}
    </div>
  );
};

IconBall.propTypes = propTypes;
IconBall.defaultProps = defaultProps;

export default IconBall;
