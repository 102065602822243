import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useSearch from 'hooks/useSearch';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import FormDwv from './components/FormDwv';
import * as dwvService from 'services/integrations/dwv';
import Loading from 'components/Loading';

function Dwv() {
  const history = useHistory();
  const search = useSearch(true);
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  async function getToken() {
    try {
      // se tiver o code na url
      if (search?.token) {
        setIsFetching(true);
        const { data } = await dwvService.connect({ token: search.token });
        setData(data);
        return data;
      }

      const { data } = await dwvService.get();
      setData(data);
      return data;
    } catch {
      history.goBack();
    } finally {
      setIsFetching(false);
    }
  }

  function handleSubmit(values) {
    return dwvService.update(values);
  }

  function onSubmitSuccess() {
    history.push('/integrations');
  }

  useEffect(() => {
    // Verifica se está importando se estiver redireciona para pagina de integrações
    if (data?.is_importing) {
      history.push('/integrations');
    }
  }, [data]);

  useEffect(() => {
    getToken();
  }, []);

  return (
    <Wrapper.container>
      <MainTitle
        title="Configure sua integração com o DWV"
        text="Defina os tipos de imóveis que você deseja importar do DWV."
      />
      {isFetching ? (
        <Loading isVisible />
      ) : (
        <FormDwv
          initialValues={data}
          onSubmit={handleSubmit}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
    </Wrapper.container>
  );
}

Dwv.propTypes = {};
Dwv.defaultProps = {};

export default Dwv;
