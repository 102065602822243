// Services
import * as profileSearchService from 'services/profileSearch';
// Modules
import createDataReducer from 'modules/data';

// Selectors
export const profilesSelector = (state) => state.modalPerson.profiles.data;
export const isFetchingProfilesSelector = (state) =>
  state.modalPerson.profiles.meta.isFetching;

export const { reducer: profilesReducer, actionCreators: profilesActions } =
  createDataReducer('modalPerson/searchProfile');

/**
 * Busca todos os perfil de interesse
 * @param person
 * @param params
 */
export const fetchProfiles = (person, params) => (dispatch) => {
  dispatch(profilesActions.request());

  return profileSearchService.getList(person.id, params).then(({ data }) => {
    dispatch(profilesActions.receive(data));
  });
};

export const deleteProfile = (profile, remove_compatible) => (dispatch) => {
  dispatch(profilesActions.removing(profile.id));

  return profileSearchService
    .remove(profile.people_id, profile.id, { remove_compatible })
    .then(() => {
      dispatch(profilesActions.remove(profile.id));
    });
};
