import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
// Components
import TablePropertyPrice from 'components/TablePropertyPrice';
// Services
import * as propertiesService from 'services/properties';
import * as financialIndexService from 'services/financialIndex';

class ChangePrice extends React.Component {
  static defaultProps = {
    properties: [],
    handleToggleVisibility: []
  };

  static propTypes = {
    properties: PropTypes.arrayOf(PropTypes.string),
    handleToggleVisibility: PropTypes.func
  };

  state = {
    properties: [],
    indexes: [],
    isFetching: false,
    initialValues: {}
  };

  componentDidMount() {
    this.fetchProperties();
    this.fetchFinancialIndex();
  }

  getInitialValues(properties) {
    return properties.reduce(
      (obj, property) => {
        return {
          ...obj,
          properties: {
            ...obj.properties,
            [property.id]: {
              ...property
            }
          }
        };
      },
      {
        properties: {}
      }
    );
  }

  fetchProperties = async () => {
    this.setState({ isFetching: true });

    const { data } = await propertiesService.getList({
      filter: { by_id: this.props.properties },
      include: 'condominium',
      with_all_data: 'true'
    });

    this.setState({
      initialValues: this.getInitialValues(data),
      properties: data,
      isFetching: false
    });
  };

  fetchFinancialIndex = async () => {
    const { data } = await financialIndexService.getList();
    this.setState({ indexes: data });
  };

  onSubmit = ({ properties }) =>
    propertiesService.propertiesMassUpdate(properties);

  onSubmitSuccess = () => {
    Alert.success('Valores alterados!');
    this.props.handleToggleVisibility();
  };

  render() {
    const { properties, indexes, initialValues } = this.state;

    return (
      <TablePropertyPrice
        initialValues={initialValues}
        data={properties}
        indexes={indexes}
        onSubmit={this.onSubmit}
        onSubmitSuccess={this.onSubmitSuccess}
        handleToggleVisibility={this.props.handleToggleVisibility}
      />
    );
  }
}

export default ChangePrice;
