/**
 * Retorna um novo array com os valores da chave requisitada
 * @param key - chave que tem que procurar dentro do objeto
 */
export const getKey = key => value => value[key];

export const checkedSelector = (checkedLabel = 'isChecked') => data =>
  data[checkedLabel] === true;

/**
 * Transform a array to object
 * @param data
 */
export const arrayToObject = (data, key = 'id') =>
  data &&
  data.reduce(
    (obj, value) => ({
      ...obj,
      [value[key]]: {
        ...value
      }
    }),
    {}
  );

/**
 * Verifica se os arrays são iguais
 * @param a1
 * @param a2
 * @returns {boolean}
 */
export const arraysEqual = (a1, a2) =>
  JSON.stringify(a1) === JSON.stringify(a2);
