import styled, { css } from 'styled-components';

const styleModal = ({ modal }) => {
  if (modal) {
    return css`
      margin-top: -5px;
      width: calc(100% + 39px);
      margin-left: -19px;

      .Menu__item {
        padding: 5px 4px 5px;
      }

      .Menu__item.is-active .Menu__link {
        color: #fff !important;
        background: ${(p) => p.theme.colors.secondary};
        border-color: ${(p) => p.theme.colors.secondary};
      }

      .Menu__link {
        padding: 5px 10px;
        border: 1px solid #dedede;
        border-radius: 25px;
        height: 30px;
        line-height: 18px;
        transition: all 0.2s ease-in-out;
      }

      .Menu__link::after {
        display: none;
      }
    `;
  }
};

export const Wrapper = styled.div`
  ${styleModal};
`;
