import React from 'react';
import AlertRadar from 'components/AlertRadar';
import { useRadar } from 'containers/AlertRadarContainer/hooks/useRadar';

// import { Wrapper } from './styles';

function AlertRadarContainer() {
  const { data, isFetching } = useRadar();

  if (data.properties_count === 0 || isFetching) return null;

  return <AlertRadar data={data} />;
}

export default AlertRadarContainer;
