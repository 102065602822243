import React from 'react';
import { Table } from './styles';
import { useSelector } from 'react-redux';
import { usersSelector } from 'modules/users';
import AvatarCell from './components/AvatarCell';
import InfosCell from './components/InfosCell';
import CreciCell from './components/CreciCell';
import OptionsCell from './components/OptionsCell';
import UserDataCell from './components/UserDataCell';
import userIcon from 'pages/Users/assets/icons/user.svg';

function TableUsers() {
  const users = useSelector(usersSelector);

  return (
    <Table
      renderTitle={() => (
        <tr>
          <th width={20}>
            <img src={userIcon} alt="Avatar" />
          </th>
          <th width={320}>
            Nome
            <br />
            Grupo
          </th>
          <th width={300}>
            E-mail <br />
            Celular/WhatsApp
          </th>
          <th>
            Situação
            <br />
            CRECI
          </th>
        </tr>
      )}
      data={users}
      renderItem={(user) => {
        return (
          <tr key={user.id}>
            <AvatarCell user={user} />
            <UserDataCell user={user} />
            <InfosCell user={user} />
            <CreciCell user={user} />
            <OptionsCell user={user} />
          </tr>
        );
      }}
    />
  );
}

export default TableUsers;
