import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  margin: 10px 0;
`;
export const Icon = styled.img`
  width: 45px;
  height: 45px;
`;
export const Content = styled.div``;

export const Title = styled.div`
  font-size: 18px;
  color: ${(p) => p.theme.colors.primary};
`;

export const Text = styled.div`
  font-size: 12px;
  color: #666;
`;
