import { Wrapper } from './styles';
import icon from './icon.svg';

const InviteError = ({ error }) => {
  return (
    <Wrapper>
      <img src={icon} alt="Icon Error" />
      <h4>Erro!</h4>
      <p>{error}</p>
    </Wrapper>
  );
}

export default InviteError;
