import styled from 'styled-components';
import ContainerComponent from 'components/Container';

export const Container = styled(ContainerComponent)`
  padding: 30px;

  .FormGroup {
    margin-bottom: 0;
  }
`;
