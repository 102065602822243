import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 0;
  overflow: auto;
`;

export const Title = styled.h1`
  font-size: 14px;
  flex: 1 0;
  line-height: 24px;
`;

export const Header = styled.div`
  height: 50px;
  padding: 0 45px 0 30px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #e0e5eb;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;
`;

export const Actions = styled.div`
  flex-shrink: 0;

  .Button {
    svg {
      margin-left: -10px;
    }
  }

  .Button--small svg {
    margin-right: -10px;
  }

  .Button--active {
    background: #d3dceb !important;
  }

  .FormGroup {
    min-width: 180px;
    margin: -8px 0 -8px 0;
  }
`;
export const Content = styled.div`
  position: relative;
  padding-bottom: 50px;
`;

export const InnerContainer = styled.div`
  padding: 25px;
  max-width: 1063px;
  margin: 0 auto;
`;
