import React from 'react';
import _ from 'lodash';
// Components
import Menu from 'components/Menu';
import styled from 'styled-components';

const styleMenu = {
  width: 'calc(100% + 39px)',
  marginLeft: -19,
  position: 'sticky',
  top: 0,
  backgroundColor: '#fff',
  zIndex: '5',
};

const WrapperMenu = styled.div`
  .Menu__item {
    padding: 0 14px;
  }
`;

export default ({ currentPage, items, onClickItem }) => (
  <WrapperMenu>
    <Menu
      border
      currentPage={currentPage}
      handleClickItem={onClickItem}
      style={styleMenu}
    >
      {items &&
        _.map(items, (value, key) => (
          <Menu.item key={key} component={value}>
            {key}
          </Menu.item>
        ))}
    </Menu>
  </WrapperMenu>
);
