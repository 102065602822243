import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useGallery } from 'components/PropertyGallery/context';
import SortablePropertyGallery from 'components/SortablePropertyGallery';
import Button from 'components/Button';
import { MdAdd, MdDelete } from 'react-icons/md';
import { Actions, Header, Reception } from './styles';
import FormGalleryUpload from 'components/FormGalleryUpload';
import icon from './ico.svg';

const Gallery = () => {
  const {
    data,
    fetchData,
    removeAllImages,
    removeImage,
    uploadImages,
    handleSortEnd,
    handleEditCaption,
    handleEditCaptionSuccess,
  } = useGallery();
  const params = useParams();

  const downloadImagesUrl = useMemo(() => {
    const apiUrl = process?.env?.REACT_APP_API_STORAGE;
    const token = localStorage.getItem('access_token');

    return `${apiUrl}/api/properties/${params?.id}/images/download?gallery=${params?.galleryId}&token=${token}`;
  }, [params]);

  useEffect(() => {
    if (params?.galleryId && params?.id) {
      fetchData(params?.id, {
        filter: {
          gallery: params?.galleryId,
        },
      });
    }
  }, [params?.galleryId, params?.id]);

  if (data?.length <= 0 || !data) {
    return (
      <Reception>
        <img src={icon} alt="" />
        <h1>Você ainda não enviou nenhuma foto.</h1>
        <p>Que tal enviar agora?</p>
        <FormGalleryUpload
          form="FormReception"
          key="form-upload-reception"
          initialValues={{
            property_id: params?.id,
            gallery: params?.galleryId,
          }}
          onChange={uploadImages}
          className="h-margin-left--15"
        >
          <Button color="secondary" size="large">
            <MdAdd /> Enviar fotos
          </Button>
        </FormGalleryUpload>
      </Reception>
    );
  }

  return (
    <>
      <Header>
        {params?.galleryId === '3' ? (
          <p>
            *As fotos desta aba não aparecem no site.
            <br />
            São apenas para visualização interna.
          </p>
        ) : (
          <p>*A primeira imagem abaixo será a capa do seu imóvel no site.</p>
        )}
        <Actions>
          <Button
            color="danger"
            size="medium"
            onClick={removeAllImages(params)}
          >
            <MdDelete /> Remover todas
          </Button>
          <Button
            size="medium"
            tagName="a"
            href={downloadImagesUrl}
            target="_blank"
          >
            Baixar imagens
          </Button>
          <FormGalleryUpload
            form="FormReceptionFiles"
            initialValues={{
              property_id: params?.id,
              gallery: params?.galleryId,
            }}
            maxFiles={50 - data.length}
            onChange={uploadImages}
            className="h-margin-left--5"
          >
            <Button
              disabled={data.length >= 50}
              color="secondary"
              size="medium"
            >
              <MdAdd /> Enviar mais imagens
            </Button>
          </FormGalleryUpload>
        </Actions>
      </Header>
      <SortablePropertyGallery
        data={data}
        onRemoveImage={removeImage}
        onSortEnd={handleSortEnd}
        // onClickSpin={handleClickSpin}
        onEditCaption={handleEditCaption}
        onEditCaptionSuccess={handleEditCaptionSuccess}
      />
    </>
  );
};

export default Gallery;
