import React from 'react';
import { Field } from 'redux-form';
// Components
import Box from 'components/FilterBox';
import { Checkbox } from 'components/Form';

const FinancialField = () => (
  <Box title="Financeiro">
    <Field
      type="checkbox"
      label="Minha Casa Minha Vida"
      name="is_financeable_mcmv"
      component={Checkbox}
      parse={(value) => {
        if (!value) return null;
        return value;
      }}
    />
    <Field
      type="checkbox"
      label="Está financiado"
      name="has_finance"
      component={Checkbox}
      parse={(value) => {
        if (!value) return null;
        return value;
      }}
    />
    <Field
      type="checkbox"
      label="Aceita financiamento"
      name="is_financeable"
      component={Checkbox}
      parse={(value) => {
        if (!value) return null;
        return value;
      }}
    />
  </Box>
);

export default FinancialField;
