function Head({ canManage }) {
  return (
    <tr>
      <th>
        <p>Condomínio/Empreendimento</p>
        <p>Localização</p>
      </th>
      <th>
        <p>Imóveis</p>
        <p>Disponíveis</p>
      </th>
      <th>
        <p>Imóveis</p>
        <p>Alugados</p>
      </th>
      <th>
        <p>Imóveis</p>
        <p>Vendidos</p>
      </th>
      <th>
        <p>Imóveis</p>
        <p>Inativos</p>
      </th>
      {canManage && <th>Opções</th>}
    </tr>
  );
}

export default Head;
