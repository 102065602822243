import React, { useCallback, useEffect } from 'react';
import { Field } from 'redux-form';
import { SelectMultiple } from 'components/Form';
import * as locationService from 'services/location';
import { useLocation } from 'containers/LocationAsyncFields/context';

// Helpers
import { parseSelect } from 'lib/formHelpers';

const Countries = ({ label = 'País', ...props }) => {
  const {
    dispatch,
    change,
    stateName,
    cityName,
    neighborhoodName,
    countryName,
    zoneName,
    countries,
    setCountries,
    setStates,
    setCities,
    setNeighborhoods,
    initialValues,
  } = useLocation();

  const onChangeCountries = useCallback(
    () => {
      dispatch(change(stateName, null));
      dispatch(change(cityName, null));
      dispatch(change(neighborhoodName, null));
      dispatch(change(zoneName, null));

      setStates(initialValues);
      setCities(initialValues);
      setNeighborhoods(initialValues);
    },
    [initialValues]
  );

  const fetchCountries = useCallback(async () => {
    setCountries({ isFetching: true });
    const res = await locationService.getCountries();
    setCountries({ data: res?.data });
  }, [setCountries]);

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <Field
      multi={false}
      label={label}
      name={countryName}
      component={SelectMultiple}
      isLoading={countries?.isFetching}
      options={countries?.data}
      labelKey="name"
      valueKey="id"
      onChange={onChangeCountries}
      parse={parseSelect('id')}
      {...props}
    />
  );
};

export default Countries;
