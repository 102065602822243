import React, { useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
// import PropTypes from 'prop-types';
import { IconWrapper, Wrapper } from './styles';

import low from './low.svg';
import medium from './medium.svg';
import high from './high.svg';

function Satisfaction({ size, input, style }) {
  const setValue = useCallback(
    value => () => {
      input.onChange(value);
    },
    [input]
  );

  return (
    <ThemeProvider theme={{ size }}>
      <Wrapper style={style}>
        <IconWrapper
          isSelected={input.value === 3}
          onClick={setValue(3)}
          color="success"
        >
          <img src={high} alt="High" />
        </IconWrapper>
        <IconWrapper
          isSelected={input.value === 2}
          onClick={setValue(2)}
          color="caution"
        >
          <img src={medium} alt="Medium" />
        </IconWrapper>
        <IconWrapper
          isSelected={input.value === 1}
          onClick={setValue(1)}
          color="danger"
        >
          <img src={low} alt="Low" />
        </IconWrapper>
      </Wrapper>
    </ThemeProvider>
  );
}

Satisfaction.defaultProps = {};
Satisfaction.propTypes = {};

export default Satisfaction;
