import React from 'react';
// Components
import FilterField from 'components/FilterField';
import { Input } from 'components/Form';

const StreetAddressField = ({ onPressEnter, onKeyDown, ...props }) => (
  <FilterField
    label="Logradouro"
    name="street_address"
    component={Input}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        if (onPressEnter) onPressEnter(e);
      }

      if (onKeyDown) onKeyDown(e);
    }}
    {...props}
  />
);

export default StreetAddressField;
