import React from 'react';
import { Financial, Header, Panel } from 'features/Financial';
import { DonutChart } from './components/DonutChart';
import * as S from './styles';
import { useCategoriesReport } from '../../hooks/useCategoriesReport';
import moment, { format } from 'lib/moment';
import { BoxData } from './components/BoxData';
import { FilterCategories } from './components/FilterCategories';
import { Nodata } from './Nodata';

export function Categories() {
  const {
    categories,
    dataIncome,
    dataExpenses,
    isFetchingCategories,
    fetchCategories,
  } = useCategoriesReport();

  const hasCredits = categories?.credits?.length > 0;
  const hasDebits = categories?.debits?.length > 0;
  const noData = !hasCredits && !hasDebits;

  const initialValues = {
    start_date: moment().startOf('month').format(format.date),
    end_date: moment().endOf('month').format(format.date),
  };

  return (
    <>
      <Header.Header>
        <Header.Title>Relatórios</Header.Title>
        <Header.Actions>
          <FilterCategories
            initialValues={initialValues}
            onSubmit={fetchCategories}
            onChange={fetchCategories}
          />
        </Header.Actions>
      </Header.Header>
      <Financial.Content isFetching={isFetchingCategories}>
        {noData ? (
          <Nodata />
        ) : (
          <>
            <Panel.Wrapper>
              <Panel.Header>
                <Panel.Title>Categorias</Panel.Title>
              </Panel.Header>
              <Panel.Content>
                <S.Charts>
                  <DonutChart data={dataIncome} label="Receitas" />
                  <DonutChart data={dataExpenses} label="Despesas" />
                </S.Charts>
              </Panel.Content>
            </Panel.Wrapper>

            <BoxData data={categories} />
          </>
        )}
      </Financial.Content>
    </>
  );
}
