import React from 'react';
import Alert from 'react-s-alert';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import api from 'services';

// Modules
import { logoutUser } from 'modules/login';

// Common Components
import Form from './components/Form';

function ModalRemoveAccount({ modalConfig, isOpen, handleClose, modalType }) {
  const dispatch = useDispatch();

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Form
        onSubmit={values => {
          return api.delete('real-estates/me', '', { params: values });
        }}
        onSubmitSuccess={() => {
          dispatch(logoutUser());

          setTimeout(() => {
            Alert.success('Imobiliária excluída!');
          }, 1000);
        }}
        handleClose={handleClose}
      />
    </Modal>
  );
}

export default ModalRemoveAccount;
