import api from 'services';

/**
 * Atualiza os dados do cartão
 * @param values
 * @param values.name {string}
 * @param values.type {number}
 * @param values.cpf_cnpj {string}
 * @param values.should_send_email {boolean}
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const create = ({ type, ...values }) =>
  api.create('sales/customers', {
    type: type ? parseInt(type, 10) : type,
    ...values,
  });

const transform = (res) => {
  let newResponse = res;

  if (newResponse.data.type) {
    newResponse.data.type = newResponse.data.type.toString();
  }

  return newResponse;
};

/**
 * Pega os dados do cartão
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const getOne = () =>
  api
    .getOne('sales/customers', '', {
      include: 'neighborhood',
    })
    .then(transform);
