import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Text = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

export const Percentage = styled.span`
  color: ${p => p.theme.colors.secondary};
  font-weight: 600;
`;
