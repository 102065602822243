import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form';
// Components
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import GroupControl, { GroupItem } from 'components/GroupControl';
import { Input, Select } from 'components/Form';
import Avatar from 'components/Avatar';

const getUnity = (informations) => {
  if (informations?.lot_number?.value) return informations?.lot_number?.value;
  if (informations?.apartment_number?.value)
    return informations?.apartment_number?.value;

  return '--';
};

const renderItem =
  (indexes) =>
  ({
    id,
    reference,
    type,
    condominium,
    informations,
    cover_image,
    neighborhood,
  }) =>
    (
      <FormSection key={`properties-${id}`} name={`properties.${id}`}>
        <tr>
          <td>
            <Avatar size="large" image={cover_image?.file_url} />
          </td>
          <td>{reference}</td>
          <td>{type?.title}</td>
          <td>{condominium?.title || '--'}</td>
          <td>{neighborhood?.name}</td>
          <td>{getUnity(informations)}</td>
          <td>
            <GroupControl>
              <GroupItem>
                <Field valueFormat prefix="" name={`price`} component={Input} />
              </GroupItem>
              <GroupItem size="small">
                <Field
                  autoWidth
                  name={`price_financial_index_id`}
                  component={Select}
                  valueKey="id"
                  labelKey="symbol"
                  options={indexes}
                  placeholder=""
                />
              </GroupItem>
            </GroupControl>
          </td>
          <td>
            <GroupControl>
              <GroupItem>
                <Field
                  valueFormat
                  prefix=""
                  name={`condominium_price`}
                  component={Input}
                />
              </GroupItem>
              <GroupItem size="small">
                <Field
                  autoWidth
                  name={`condominium_financial_index_id`}
                  component={Select}
                  valueKey="id"
                  labelKey="symbol"
                  options={indexes}
                  placeholder=""
                />
              </GroupItem>
            </GroupControl>
          </td>
        </tr>
      </FormSection>
    );

class TablePropertyPrice extends React.Component {
  static defaultProps = {
    data: [],
    indexes: [],
  };

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    indexes: PropTypes.arrayOf(PropTypes.object),
  };

  get renderItems() {
    const { data, indexes } = this.props;
    return data.map(renderItem(indexes));
  }

  render() {
    const { handleSubmit, handleToggleVisibility } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <table className="Table">
          <thead>
            <tr>
              <th />
              <th>Ref.</th>
              <th>Imóvel</th>
              <th>Condomínio</th>
              <th>Bairro</th>
              <th>Unidade</th>
              <th>Preço do Imóvel</th>
              <th>Preço do Condomínio</th>
            </tr>
          </thead>
          <tbody>{this.renderItems}</tbody>
        </table>
        <FixedBar
          isVisible
          position="bottom"
          style={{
            left: '240px',
          }}
        >
          <FixedBar.item>
            <Button
              type="button"
              color="white"
              colorText="secondary"
              onClick={handleToggleVisibility}
            >
              Voltar
            </Button>
          </FixedBar.item>
          <FixedBar.spacer />
          <FixedBar.item>
            <Button type="submit" color="success">
              Salvar Alterações
            </Button>
          </FixedBar.item>
        </FixedBar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'TablePropertyPrice',
  enableReinitialize: true,
})(TablePropertyPrice);
