import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;
export const Title = styled.div``;

export const OptionGroup = styled.div`
  font-weight: bold;
  color: #333;
  background: #f0f0f0;
  padding: 8px 12px;
  margin: -8px -10px;
`;
