import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 5px;

  .Label + .Label {
    margin-left: 15px;
  }

  .Button {
    margin-left: 15px;
  }
`;
