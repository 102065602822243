import React from 'react';
import PropTypes from 'prop-types';
import { DIVIDER_SHAPE } from '../../constants/shapes';

const defaultProps = {
  top: 5,
  bottom: 5,
  left: 0,
  right: 0,
  spacingX: null,
  spacingY: null,
  children: ''
};

const propTypes = {
  ...DIVIDER_SHAPE,
  children: PropTypes.node
};

const Divider = ({
  children,
  top,
  bottom,
  left,
  right,
  spacingX,
  spacingY
}) => {
  const style = {
    marginTop: spacingY || top,
    marginBottom: spacingY || bottom,
    marginLeft: spacingX || left,
    marginRight: spacingX || right
  };

  return (
    <div className="Dropdown__divider" style={style}>
      {children}
    </div>
  );
};

Divider.defaultProps = defaultProps;
Divider.propTypes = propTypes;

export default Divider;
