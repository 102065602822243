// import styles from './styles'
import React, { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import {
  Item,
  Text,
  Title,
} from 'containers/ModalRoot/ModalProperty/pages/Record/components/PriceBar/styles';
import { MdInfo } from 'react-icons/md';

export default function Warranties({ property }) {
  useEffect(() => {
    Tooltip.rebuild();
  }, [property.warranties]);

  if (property?.warranties?.length <= 0 || !property?.warranties) return null;

  if (property?.warranties?.length === 1)
    return (
      <>
        <span className="h-flex__cell--grow" />
        <Item>
          <Title>Garantia</Title>
          <Text>{property?.warranties[0]}</Text>
        </Item>
      </>
    );

  const formattedWarranties = `<ul>${property?.warranties
    .map((warranty) => `<li>${warranty}</li>`)
    .join('')}</ul>`;

  return (
    <>
      <span className="h-flex__cell--grow" />
      <Item>
        <Title>Garantia</Title>
        <Text>
          {property?.warranties?.length} modalidades{' '}
          <MdInfo data-html data-tip={formattedWarranties} />
        </Text>
      </Item>
    </>
  );
}
