import React, { useCallback } from 'react';
import { MdRefresh } from 'react-icons/md';
import PropTypes from 'prop-types';
// Components
import Popover from 'components/Popover';
import FormChangeStage from './components/FormChangeStage';
// Services
import { transferDeal } from 'services/deals';

import { Stage } from './styles';

function ChangeStage({ deal, onSuccess }) {
  const handleSubmit = useCallback(
    ({ stage_id }) => {
      return transferDeal(deal?.id, stage_id);
    },
    [deal]
  );

  return (
    <td>
      <Popover
        width={240}
        onSubmit={handleSubmit}
        onSubmitSuccess={onSuccess}
        component={FormChangeStage}
        funnelId={deal?.stage?.funnel_id}
      >
        <Stage color={deal?.stage?.color}>
          {deal?.stage?.name} <MdRefresh />
        </Stage>
      </Popover>
    </td>
  );
}

ChangeStage.defaultProps = {
  stage: null,
  onSuccess: null
};

ChangeStage.propTypes = {
  stage: PropTypes.object,
  onSuccess: PropTypes.func
};

export default ChangeStage;
