import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdCheckCircle, MdPlayCircleFilled } from 'react-icons/md';
// Components
import Button from 'components/Button';
import NoContent from 'components/NoContent';
import Wrapper from 'components/Wrapper';
// Containers
import Accounts from './containers/Accounts';
import LocalAccounts from './containers/LocalAccounts';
// Modules
import {
  closeModal,
  openModalAddEmailAccount,
  openModalContractExtra,
  openModalEmailBoxRedirected,
} from 'modules/modal';

import { fetchAccounts as fetchLocalAccounts } from './containers/LocalAccounts/module';
import { fetchAccounts } from './containers/Accounts/module';

import { hasDataSelector } from './module';
// HOC
import withReception from 'HOC/withReception';
// Assets
import email from './email.png';
import google from './google.png';
import { withPermission } from 'HOC/withPermission';
import IsTecimob from 'containers/IsTecimob';
import BoxHelpEmail from 'pages/EmailAccounts/components/BoxHelpEmail';
import { compose } from 'redux';
import { useCurrentItems, usePreview1 } from 'hooks/api/contracts';
import Alert from 'components/Alert';
import SAlert from 'react-s-alert';

const EmailAccounts = ({ reception, handleClickAction }) => {
  const { currentItems, fetchCurrentItems } = useCurrentItems();
  const dispatch = useDispatch();
  const hasData = useSelector(hasDataSelector);
  const { fetchPreview1, ITEM_TYPES } = usePreview1();

  const handleOpenModalManage = async () => {
    const { data: preview } = await fetchPreview1({
      item: ITEM_TYPES.EMAIL,
      is_adding: false,
    });

    dispatch(
      openModalContractExtra({
        item: ITEM_TYPES.EMAIL,
        currentItems,
        preview,
        onSuccess: () => {
          fetchCurrentItems();
          SAlert.success('Contrato alterado');
          dispatch(closeModal());
        },
      })
    );
  };

  const handleOpenAddEmail = useCallback(() => {
    dispatch(
      openModalAddEmailAccount({
        onSubmitSuccess: () => {
          dispatch(fetchLocalAccounts());
        },
      })
    );
  }, [dispatch]);

  const handleOpenRedirectedEmail = useCallback(() => {
    dispatch(
      openModalEmailBoxRedirected({
        onSubmitSuccess: () => {
          dispatch(fetchAccounts());
        },
      })
    );
  }, [dispatch]);

  const renderNoData = () => {
    if (hasData) return null;

    return (
      <>
        {currentItems?.emails_quantity > 0 && (
          <Alert color="secondary">
            <div className="h-flex">
              <div className="h-flex__cell--grow">
                Você está utilizando <strong>0</strong> das{' '}
                <strong>{currentItems?.emails_quantity}</strong> caixas de
                e-mail local contratadas.
              </div>
              <button
                className="h-link h-flex__cell--shrink"
                onClick={handleOpenModalManage}
              >
                Gerenciar total de assentos contratados
              </button>
            </div>
          </Alert>
        )}
        <NoContent
          size={600}
          image={google}
          title="Conecte-se gratuitamente ao Gmail"
          text="Envie e receba emails através da interface amigável do Gmail com o seu e-mail personalizado. Ex: contato@seusite.com.br"
          styleWrapper={{
            alignItems: 'flex-start',
          }}
        >
          <ul className="h-list--none">
            <li className="h-margin-top--5">
              <MdCheckCircle color="#01AFAD" /> Ilimitadas contas de e-mail
            </li>
            <li className="h-margin-top--10">
              <MdCheckCircle color="#01AFAD" /> <strong>15gb</strong> de espaço
            </li>
            <li className="h-margin-top--10">
              <MdCheckCircle color="#01AFAD" /> Aplicativo para celular
            </li>
          </ul>
          <div className="h-margin-top--15 h-margin-bottom--15">
            <Button color="caution" onClick={handleOpenRedirectedEmail}>
              Conectar ao Gmail
            </Button>
            <IsTecimob>
              <Button
                tagName="a"
                href="https://tecimob.com.br/ajuda/criar-caixa-de-e-mail/"
                target="_blank"
                color="primary"
              >
                <MdPlayCircleFilled color="#fff" /> Veja como funciona
              </Button>
            </IsTecimob>
          </div>
          <p className="h-color--primary">
            Ou,{' '}
            <span className="h-link" onClick={handleOpenAddEmail}>
              Crie uma conta
            </span>{' '}
            de e-mail local (R$4,90 cada).
          </p>
        </NoContent>
      </>
    );
  };

  if (reception.isVisible) {
    return (
      <Wrapper.container>
        <NoContent
          image={email}
          title="E-mail"
          text="Integre seu e-mail personalizado com o Gmail ou outlook, ou então, crie uma caixa de e-mail própria."
        >
          <Button
            type="button"
            color="secondary"
            colorText="white"
            onClick={handleClickAction}
          >
            Entendi
          </Button>
        </NoContent>
      </Wrapper.container>
    );
  }

  return (
    <Wrapper.container>
      <div style={{ marginBottom: '40px' }}>
        {currentItems?.emails_quantity > 0 && (
          <Alert color="secondary" className="h-margin-top--15">
            <div className="h-flex">
              <div className="h-flex__cell--grow">
                Você está utilizando <strong>0</strong> das{' '}
                <strong>{currentItems?.emails_quantity}</strong> caixas de
                e-mail local contratadas.
              </div>
              <button
                className="h-link h-flex__cell--shrink"
                onClick={handleOpenModalManage}
              >
                Gerenciar total de assentos contratados
              </button>
            </div>
          </Alert>
        )}
        <BoxHelpEmail />
        {renderNoData()}
        <Accounts />
        <LocalAccounts
          currentItems={currentItems}
          fetchCurrentItems={fetchCurrentItems}
        />
      </div>
    </Wrapper.container>
  );
};

export default compose(
  withPermission({
    rules: ['EDIT_EMAIL'],
    isPage: true,
  }),
  withReception('EmailAccounts', true)
)(EmailAccounts);
