import qs from 'qs';

export const sleep = (timeout = 2000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};

/**
 * Pega a posição exata de um elemento DOM na tela
 * @param el
 * @return {{top: *, left: *}}
 */
export const offset = (el) => {
  const { top, left } = el.getBoundingClientRect();
  const { scrollTop, scrollLeft } = document.documentElement;

  return {
    top: top + scrollTop,
    left: left + scrollLeft,
  };
};

/**
 * Remove uma chave do objeto se o valor dela está null ou undefined
 * @param obj
 * @return {*}
 */
export const cleanObject = (obj) => {
  Object.getOwnPropertyNames(obj).map((propName) => {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
    return true;
  });

  return obj;
};

export const time = (timeToConvert, from, to) => {
  if (from === 'minutes') {
    switch (to) {
      case 'weeks':
        return timeToConvert / 10080;
      case 'days':
        return timeToConvert / 1440;
      case 'hours':
        return timeToConvert / 60;
      case 'minutes':
      default:
        return timeToConvert;
    }
  }

  if (from === 'seconds') {
    if (to === 'minutes') {
      return timeToConvert / 60;
    }
  }

  switch (from) {
    case 'weeks':
      return timeToConvert * 10080;
    case 'days':
      return timeToConvert * 1440;
    case 'hours':
    default:
      return timeToConvert * 60;
  }
};

export const rotate = (rotation) => {
  if (rotation >= 270) return 0;
  return rotation ? rotation + 90 : 90;
};

/**
 * Atualiza os parametros da url
 * @param pathname - caminho root da url
 * @param params - parametros que vao ser adicionados na url
 */
export const updateUrl = (pathname, params) =>
  window.history.pushState(
    pathname,
    null,
    qs.stringify(params, {
      addQueryPrefix: true,
    })
  );

let timeout;

/**
 * Delay em uma funcao
 * @param cb
 * @param delay
 */
export const timeoutDebounce = (cb, delay = 300) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => cb(), delay);
};

/**
 * Is Mobile
 * @return {boolean}
 */
export function isMobile() {
  // Get the screen width
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  // Set a threshold for the width that we consider as a tablet
  const tabletThreshold = 768; // You can adjust this value as needed

  // Check if the device is a touch device
  const isTouchDevice =
    'ontouchstart' in window || navigator.maxTouchPoints > 0;

  // Check if the screen width is below the tablet threshold and if it is a touch device
  return screenWidth < tabletThreshold && isTouchDevice;
}

/**
 * Pega a posição do mapa
 * @returns {Promise<unknown>}
 */
export const getPosition = async () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (error) => {
        reject(error);
      }
    );
  });

const getEventsHidden = () => {
  var hidden, visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  return { hidden, visibilityChange };
};

export const detectVisibility = (callback) => {
  if (!callback) return null;
  const { hidden, visibilityChange } = getEventsHidden();

  const listener = () => {
    const isHidden = document[hidden];
    callback(isHidden);
  };

  document.addEventListener(visibilityChange, listener, false);

  return listener;
};

export const undetectVisibility = (listener) => {
  const { visibilityChange } = getEventsHidden();
  return document.removeEventListener(visibilityChange, listener, false);
};
