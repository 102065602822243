import { useMemo } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { useId } from "./hooks/useId";
import pages from './pages';

const Routes = () => {
  let { path } = useRouteMatch();
  let { id } = useId();

  const redirectPath = useMemo(() => {
    return `${path}/informations${id ? `/${id}` : ''}`;
  }, [path, id]);

  return (
    <Switch>
      <Route
        path={`${path}/informations/:id?`}
        component={pages.Informations}
      />
      <Route
        path={`${path}/images/:id?`}
        component={pages.Images}
      />

      <Redirect from={`${path}/id/:id`} to={`${path}/informations/:id`} />
      <Redirect to={redirectPath} />
    </Switch>
  );
}

export default Routes;
