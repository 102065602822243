import {
  MdAdd,
  MdAttachMoney,
  MdBorderColor,
  MdDelete,
  MdHome,
  MdLabel,
  MdOutlineSaveAlt,
  MdSearch,
} from 'react-icons/md';
// Components
// import ItemDropdown, { Item } from '../../ItemDropdown';
import MenuHeader, { Item } from 'components/MenuHeader';

function MenuProperties() {
  // const handleClearSearch = () => {
  //   dispatch(clearSearch(history));
  // };

  return (
    <MenuHeader title="Imóveis" to="/properties/search" active="/properties/">
      <Item
        icon={MdAdd}
        iconColor="secondary"
        title="Novo imóvel"
        text="Cadastre um novo imóvel"
        to="/properties/store"
      />
      <Item
        to={`/properties/search`}
        title="Pesquisar"
        text="Encontre imóveis cadastrados"
        icon={MdSearch}
        iconColor="tertiary"
      />
      <Item
        to={`/properties/condos`}
        title="Condomínios/Empreendimentos"
        text="Condomínios e empreendimentos"
        icon={MdHome}
        iconColor="success"
      />
      <Item
        to="/properties/rented"
        icon={MdHome}
        iconColor="primary"
        title="Mais imóveis"
        text="Imóveis por situação"
      >
        <Item
          to="/properties/rented"
          title="Alugados"
          text="Seus imóveis alugados"
          icon={MdLabel}
          iconColor="caution"
        />
        <Item
          to="/properties/sold"
          title="Vendidos"
          text="Seus imóveis vendidos"
          icon={MdAttachMoney}
          iconColor="success"
        />
        <Item
          to="/properties/booked"
          title="Reservados"
          text="Seus imóveis reservados"
          icon={MdOutlineSaveAlt}
          iconColor="secondary"
        />
        <Item
          to="/properties/revision/show"
          title="Rascunhos"
          text="Imóveis aguardando revisão"
          icon={MdBorderColor}
          iconColor="primary"
        />
        <Item
          to="/properties/excluded"
          title="Inativos"
          text="Seus imóveis inativos"
          icon={MdDelete}
          iconColor="danger"
        />
      </Item>
    </MenuHeader>
  );

  /*return (
    <ItemDropdown
      onClick={handleClearSearch}
      active="properties"
      label="Imóveis"
      to="/properties/search"
    >
      {({ close }) => (
        <>
          <Item onClick={close}>
            <LinkWithIcon
              to={`/properties/store`}
              title="Novo Imóvel"
              text="Cadastre um novo imóvel."
              icon={MdAdd}
              iconColor="secondary"
            />
          </Item>
          <Item onClick={close}>
            <LinkWithIcon
              onClick={handleClearSearch}
              to={`/properties/search`}
              title="Pesquisar"
              text="Pesquisar imóveis por características."
              icon={MdSearch}
              iconColor="tertiary"
            />
          </Item>
          <Item onClick={close}>
            <LinkWithIcon
              to={`/properties/condos`}
              title="Condomínios/Empreendimentos"
              text="Gerenciar condomínios e listar imóveis."
              icon={MdHome}
              iconColor="success"
            />
          </Item>
          <Item onClick={close}>
            <LinkWithIcon
              to="/properties/rented"
              title="Alugados"
              text="Seus imóveis alugados."
              icon={MdLabel}
              iconColor="caution"
            />
          </Item>
          <Item onClick={close}>
            <LinkWithIcon
              to="/properties/sold"
              title="Vendidos"
              text="Seus imóveis vendidos."
              icon={MdAttachMoney}
              iconColor="success"
            />
          </Item>
          <Item onClick={close}>
            <LinkWithIcon
              to="/properties/booked"
              title="Reservados"
              text="Seus imóveis reservados."
              icon={MdOutlineSaveAlt}
              iconColor="secondary"
            />
          </Item>
          <Item onClick={close}>
            <LinkWithIcon
              to="/properties/revision/show"
              title="Rascunhos"
              text="Imóveis aguardando revisão."
              icon={MdBorderColor}
              iconColor="primary"
            />
          </Item>
          <Item onClick={close}>
            <LinkWithIcon
              to="/properties/excluded"
              title="Inativos"
              text="Seus imóveis inativos."
              icon={MdDelete}
              iconColor="danger"
            />
          </Item>
        </>
      )}
    </ItemDropdown>
  );*/
}

MenuProperties.defaultProps = {};

MenuProperties.propTypes = {};

export default MenuProperties;
