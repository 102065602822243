import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Row, Col } from 'react-flexbox-grid';
// Components
import FormBox from 'components/FormBox';
import { Textarea, Input } from 'components/Form';
import GroupControl from 'components/GroupControl';
import GroupItem from 'components/GroupControl/components/GroupItem';
import GoogleResult from 'components/GoogleResult';
import useFormValue from 'hooks/useFormValue';
import FieldUrl from 'containers/FieldUrl';

const propTypes = {};
const defaultProps = {};

function Seo({ changeField }) {
  const [isVisible, setIsVisible] = useState(false);

  const { custom_url, meta_title, meta_description } = useFormValue([
    'custom_url',
    'meta_title',
    'meta_description'
  ]);

  return (
    <FormBox
      title="Apresentação do imóvel nos resultados do Google (SEO)"
      className="js-seo"
    >
      <Row middle="xs">
        <Col xs={6}>
          <Row>
            <Field
              xs={12}
              label={
                <>
                  Título da página{' '}
                  <a
                    href="https://support.google.com/webmasters/answer/35624?hl=pt-BR"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-link"
                  >
                    (saiba mais)
                  </a>
                </>
              }
              name="meta_title"
              placeholder="Digite o título"
              component={Input}
              maxLength={120}
            />
            <Field
              xs={12}
              type="simple"
              label="Descrição"
              name="meta_description"
              placeholder="Digite a descrição"
              maxLength={156}
              component={Textarea}
            />
            {isVisible ? (
              <Col xs={12}>
                <GroupControl label="URL Personalizada">
                  <GroupItem label="seusite.com.br/detalhes/" size="small" />
                  <GroupItem>
                    <FieldUrl name="custom_url" />
                  </GroupItem>
                </GroupControl>
                <button
                  className="h-color--secondary"
                  onClick={() => {
                    changeField('custom_url', '');
                    setIsVisible(false);
                  }}
                >
                  Manter padrão
                </button>
              </Col>
            ) : (
              <Col xs={12}>
                <button
                  className="h-color--secondary"
                  onClick={() => {
                    setIsVisible(true);
                  }}
                >
                  Modificar URL
                </button>
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={6}>
          <GoogleResult
            url={`www.seusite.com.br/detalhes/${custom_url ? custom_url : ''}`}
            title={meta_title}
            description={meta_description}
          />
        </Col>
      </Row>
    </FormBox>
  );
}

Seo.defaultProps = defaultProps;
Seo.propTypes = propTypes;

export default Seo;
