import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// Modules
import { authsSelector } from 'modules/authentications';

function HasIntegration({ scope, yes, no }) {
  const auths = useSelector(authsSelector);
  return auths[scope] ? yes() : no();
}

HasIntegration.propTypes = {
  scope: PropTypes.oneOf(['calendar', 'analytics', 'orulo']).isRequired,
  yes: PropTypes.func.isRequired,
  no: PropTypes.func.isRequired
};

HasIntegration.defaultProps = {
  scope: null
};

export default HasIntegration;
