import React from 'react';
import PersonTooltip from './components/PersonTooltip';
import { Text, Title, Wrapper } from './styles';

function PersonItem({ person, currentUser }) {
  return (
    <Wrapper role="button" className="h-hover">
      <Title>
        {person?.name}{' '}
        <PersonTooltip currentUser={currentUser} person={person} />
      </Title>
      <Text noCellphone={!person?.cellphone_number}>
        {person?.cellphone_number || 'Sem telefone'}
      </Text>
    </Wrapper>
  );
}

PersonItem.propTypes = {};
PersonItem.defaultProps = {};

export default PersonItem;
