import useReduxForm from './useReduxForm';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

export default function useFormValue(name) {
  const { form } = useReduxForm();
  const selector = formValueSelector(form);

  return useSelector(state => {
    if (Array.isArray(name)) {
      return selector(state, ...name);
    }
    return selector(state, name);
  });
}
