import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { SelectMultiple } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import { getList as getGroups } from 'services/settings/groups';
import { parseSelect } from 'lib/formHelpers';
// import { Container } from './styles';

const FieldUserGroup = ({
  multi = false,
  required = false,
  autoFocus = true,
  creatable = false,
  label = 'Grupo',
  name = 'group_id',
  params = {},
  excludeIds,
  onLoad,
  ...props
}) => {
  const { data, isFetching } = useRequest({
    request: getGroups,
    params,
  });

  useEffect(() => {
    if (data?.length > 0 && !!onLoad) {
      onLoad(data);
    }
  }, [onLoad, data]);

  return (
    <Field
      {...props}
      multi={multi}
      required={required}
      autoFocus={autoFocus}
      creatable={creatable}
      label={label}
      valueKey="id"
      labelKey="title"
      parse={parseSelect('id')}
      isLoading={isFetching}
      name={name}
      options={data.filter((item) => {
        if (excludeIds) {
          return !item?.id?.includes(excludeIds);
        }

        return true;
      })}
      component={SelectMultiple}
    />
  );
};

export default FieldUserGroup;
