import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  src: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  proportion: PropTypes.oneOf([3 / 4, 9 / 16]),
  className: PropTypes.string,
  iframeParams: PropTypes.object,
  onClick: PropTypes.func
};

const defaultProps = {
  src: null,
  thumbnailUrl: null,
  proportion: 9 / 16,
  className: null,
  iframeParams: null,
  onClick: null
};

const Embed = ({
  src,
  thumbnailUrl,
  proportion,
  className,
  embedParams,
  onClick,
  ...props
}) => {
  const [hasThumb, setThumb] = useState(!!thumbnailUrl);

  return (
    <div
      className={classnames('h-embed-wrapper', className)}
      style={{ paddingBottom: `${proportion * 100}%` }}
      onClick={onClick}
    >
      {hasThumb ? (
        <img
          className="h-embed"
          src={thumbnailUrl}
          alt=""
          onClick={() => {
            setThumb(false);
          }}
        />
      ) : (
        <iframe
          {...embedParams}
          title={src}
          frameBorder="none"
          className="h-embed"
          src={src}
          {...props}
        />
      )}
    </div>
  );
};

Embed.defaultProps = defaultProps;
Embed.propTypes = propTypes;

export default Embed;
