import api, { apiStorage } from '../index';
import Alert from 'react-s-alert';
import { responseMessage } from 'lib/service-helpers';

// Pega todos os bancos
export const getBanks = (params) =>
  api.getList('settings/sites/banks', { ...params, sort: 'created_at' });

export const getBankById = (id) => api.getOne('settings/sites/banks', id);

// Inverte o status
export const toggleStatus = ({ id, status }) =>
  api.create(`settings/sites/banks/${id}`, { status: !status });

// Adiciona um banco
export const createBank = async ({ image, ...values }) => {
  const res = await api.create('settings/sites/banks', values);

  if (res?.data?.id && !!image) {
    await uploadImage({ id: res.data.id, image });
  }

  Alert.success('Banco adicionado');

  return res;
};

// Atualiza um banco
export const updateBank = async ({ image, ...values }) => {
  if (values.id && !!image) {
    await uploadImage({ id: values.id, image });
  }

  return api
    .create(`settings/sites/banks/${values.id}`, values)
    .then(responseMessage('Banco atualizado'));
};

// Remove um banco
export const removeBankById = (bankId) =>
  api
    .delete('settings/sites/banks', bankId)
    .then(responseMessage('Banco removido'));

export const uploadImage = ({ id, image }) =>
  apiStorage.uploadFile(`sites/banks/${id}`, { image });

// Pega os dados de financiamento
export const getFinance = () => api.getOne('settings/sites/finance');

// Atualiza os dados de financiamento
export const updateFinance = (values) =>
  api
    .update('settings/sites/finance', values)
    .then(responseMessage('Dados atualizados'));
