import styled from 'styled-components';

export const Wrapper = styled.label`
  padding: 15px;
  background: gray;
  display: flex;
  background: #e9eef5;
  border: 1px solid #dce3ef;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
`;
