import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Card from 'react-credit-cards';
import { Col, Row } from 'react-flexbox-grid';
import { MdLock } from 'react-icons/md';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import { Input } from 'components/Form';
import Loading from 'components/Loading';
import { ModalClose } from 'components/Modal';
// Helpers
import {
  maxLength,
  normalizeCreditCard,
  normalizeMonthYearFull,
} from 'lib/formHelpers';

import validate from './validate';

const defaultProps = {
  isContractMode: null,
  creditCard: {},
  handleSubmit: null,
  handleClose: null,
};

const propTypes = {
  isContractMode: PropTypes.bool,
  creditCard: PropTypes.shape({
    number: PropTypes.string,
    holder_name: PropTypes.string,
    expiration_date: PropTypes.string,
    cvv: PropTypes.string,
  }),
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

class FormCard extends React.Component {
  state = {
    focus: null,
  };

  componentDidMount() {
    Tooltip.rebuild();
  }

  setFocus = (field) => () => {
    this.setState({ focus: field });
  };

  clearFocus = () => this.setState({ focus: null });

  render() {
    const { isContractMode, isLoading, creditCard, handleSubmit, handleClose } =
      this.props;
    const { focus } = this.state;

    return (
      <form
        onSubmit={handleSubmit}
        className={classnames('FormCard', { isLoading })}
      >
        <Loading isVisible={isLoading} isFullComponent />
        <ModalClose handleClose={handleClose} />
        <div className="FormCard__fields">
          <h4 className="FormCard__title">Dados do cartão</h4>
          <Row>
            <Field
              xs={12}
              label="Número"
              name="number"
              component={Input}
              onFocus={this.setFocus('number')}
              onBlur={this.clearFocus}
              normalize={normalizeCreditCard}
              placeholder="0000 0000 0000 0000"
            />
          </Row>
          <Row>
            <Field
              xs={12}
              label="Nome como está no cartão"
              name="holder_name"
              component={Input}
              onFocus={this.setFocus('name')}
              onBlur={this.clearFocus}
              placeholder="Digite seu nome"
            />
          </Row>
          <Row>
            <Field
              xs={6}
              label="Validade"
              name="expiration_date"
              component={Input}
              onFocus={this.setFocus('expiry')}
              onBlur={this.clearFocus}
              normalize={normalizeMonthYearFull}
              placeholder="MM/AAAA"
            />
            <Field
              xs={6}
              label="CVV"
              name="cvv"
              component={Input}
              onFocus={this.setFocus('cvc')}
              onBlur={this.clearFocus}
              normalize={maxLength(4)}
              placeholder="000"
            />
          </Row>
          {/*<HasRecurringCharge>*/}
          {/*  <Row>*/}
          {/*    <Col xs={12}>*/}
          {/*      <FieldBool*/}
          {/*        label={*/}
          {/*          <>*/}
          {/*            Ativar cobrança automática{' '}*/}
          {/*            <MdHelp data-tip="O sistema cuidará dos<br/> pagamentos automaticamente<br/> pra você, com este cartão." />*/}
          {/*          </>*/}
          {/*        }*/}
          {/*        name="recurring_charge"*/}
          {/*        style={{*/}
          {/*          width: 150*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</HasRecurringCharge>*/}
          <Row>
            <Col xs={12}>
              <Button type="submit" block color="success">
                {isContractMode ? 'Finalizar pagamento' : 'Cadastrar cartão'}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="FormCard__card-container">
          <div className="FormCard__security">
            <span>
              <MdLock color="#01AFAD" /> Área segura
            </span>
          </div>
          <Card
            name={creditCard.holder_name}
            cvc={creditCard.cvv}
            expiry={creditCard.expiration_date}
            number={creditCard.number}
            focused={focus}
            locale={{ valid: 'valido até' }}
            placeholders={{ name: 'Seu nome aqui', expiry: 'Validade' }}
          />
        </div>
      </form>
    );
  }
}

FormCard.defaultProps = defaultProps;
FormCard.propTypes = propTypes;

const selector = formValueSelector('FormCard');

const mapStateToProps = (state) => ({
  creditCard: {
    holder_name: selector(state, 'holder_name') || '',
    cvv: selector(state, 'cvv') || '',
    expiration_date: selector(state, 'expiration_date') || '',
    number: selector(state, 'number') || '',
  },
});

export default compose(
  reduxForm({
    form: 'FormCard',
    validate,
    enableReinitialize: true,
  }),
  connect(mapStateToProps)
)(FormCard);
