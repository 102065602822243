import Container from 'components/Container';
import Header from './components/Header';
import TableUsers from './components/TableUsers';
import React from 'react';

function RegisteredUsers() {
  return (
    <>
      <Header />
      <Container padding>
        <TableUsers />
      </Container>
    </>
  );
}

export default RegisteredUsers;
