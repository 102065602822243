import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { FileInput } from 'components/Form';
import Button from 'components/Button';

class FormGalleryUpload extends React.PureComponent {
  static defaultProps = {
    children: null
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    children: PropTypes.node
  };

  render() {
    const { loading, handleSubmit, children } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field multiple name="files" component={FileInput}>
          <Button loading={loading} color="secondary">
            {children}
          </Button>
        </Field>
      </form>
    );
  }
}

export default reduxForm({
  form: 'FormPropertyImage',
  enableReinitialize: true
})(FormGalleryUpload);
