import React from 'react';
import { Wrapper, Box } from './styles';

function Nodata({ children }) {
  return (
    <Wrapper>
      <Box>{children}</Box>
    </Wrapper>
  );
}

export default Nodata;
