import React from 'react';
import { Wrapper, WrapText, Content, Image, Title, Text } from './styles';
import Loading from 'components/Loading';
import { useImagePreload } from 'hooks/useImagePreload';

const Reception = ({
  image,
  title,
  text,
  children,
  style,
  styleText,
  styleContent,
  styleImage,
}) => {
  const { isLoading } = useImagePreload(image);

  if (isLoading) {
    return <Loading isFullScreen />;
  }

  return (
    <Wrapper style={style}>
      <Content style={styleContent}>
        <WrapText style={styleText}>
          <Title>{title}</Title>
          <Text>{text}</Text>
          {children}
        </WrapText>
      </Content>
      <Image src={image} style={styleImage} />
    </Wrapper>
  );
};

export default Reception;
