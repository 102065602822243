import { Select } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import { Field } from 'redux-form';
import { getSolarPositions } from 'services/properties';

const FieldSolarPosition = () => {
  const { data } = useRequest({
    request: getSolarPositions,
    params: {
      sort: 'order',
    },
  });

  return (
    <Field
      xs={3}
      label="Posição solar"
      name="solar_position_id"
      component={Select}
      valueKey="id"
      labelKey="title"
      options={data}
    />
  );
};

export default FieldSolarPosition;
