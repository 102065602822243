import { createValidation } from 'lib/validate-helpers';

export default (values) => {
  const errors = {};

  const { email, required, password } = createValidation(values, errors);

  required([
    'name',
    'cellphone',
    'password',
    'password_confirm',
    'work_option',
    'team_size',
    'market_time',
  ]);

  email('email');

  // Validacao de senha/confirmação de senha
  // Validação de senha fraca
  password(['password', 'password_confirm']);

  if (values?.password && values?.password?.length < 6) {
    errors.password = 'Senha deve possuir mais do que 5 caracteres';
  }

  if (!values.accept_terms) {
    errors.accept_terms = 'Você precisa aceitar os termos para continuar';
  }

  return errors;
};
