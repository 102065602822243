import styled from "styled-components";

export const Delete = styled.button`
  cursor: pointer;
`;

export const NoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
