import { ModalContent, ModalTitle } from 'components/Modal';
import { Content, Wrapper } from './styles';
import successIcon from './success.svg';
import { PAYMENT_METHODS } from 'constants/constants';
import ReactConfetti from 'react-confetti';

const PaymentSuccess = ({ receivingMethod }) => {
  return (
    <Wrapper>
      <ModalTitle text="Sucesso">Pagamento</ModalTitle>

      <ModalContent>
        <ReactConfetti width="630" height="509" recycle={false} />

        <Content>
          <img src={successIcon} alt="Sucesso" />

          <h2>Sucesso</h2>

          <p className="text">Obrigado pela parceria!</p>

          {receivingMethod === PAYMENT_METHODS.BOLETO && (
            <p className="payment-method-text">
              Enviamos os boletos para seu e-mail
            </p>
          )}
        </Content>
      </ModalContent>
    </Wrapper>
  );
};

export default PaymentSuccess;
