import styled, { css } from 'styled-components';

const isActive = ({ isActive }) => {
  if (isActive) {
    return css`
      font-weight: 700;
      color: #0063c0;

      &:after {
        transform: translateX(0);
      }
    `;
  }
};

export const Wrapper = styled.div`
  position: relative;
  padding: 12px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #707686;
  cursor: pointer;
  user-select: none;
  overflow: hidden;

  :after {
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #0063c0;
    transform: translateX(3px);
    transition: transform 0.3s ease-in-out;
  }

  ${isActive};

  & + & {
    border-top: 1px solid ${(p) => p.theme.borderColor};
  }

  border-color: ${(p) => p.theme.borderColor};

  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  svg {
    margin-right: 5px;
  }
`;
