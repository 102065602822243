import React from 'react';
import CardProperty from 'components/CardProperty';
import { PROPERTIES } from '../../fakeData';

function CardPropertyPage() {
  return (
    <div>
      <CardProperty type="vertical" property={PROPERTIES[0]} />
      <div>
        {PROPERTIES.map(property => (
          <CardProperty
            type="horizontal"
            message="Cadastrado em 17/01/2020"
            property={property}
          >
            <div>
              <h5>Detalhes da transação</h5>
              <p>
                <span className="h-color--danger">Perdido</span> em: 29/02/05
              </p>
              <p>Cliente achou que tava caro de + junio</p>
            </div>
          </CardProperty>
        ))}
      </div>
    </div>
  );
}

CardPropertyPage.defaultProps = {};
CardPropertyPage.propTypes = {};

export default CardPropertyPage;
