import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  .Dropzone2 {
    width: 100%;
    background: #ffffff;
    border: 1px dashed #d3dceb;
    border-radius: 4px;
    padding: 20px;

    ${(p) =>
      p?.theme?.hasFileUploaded &&
      css`
        background: rgba(49, 208, 132, 0.03);
        border: 1px solid #31d084;
      `}
  }

  .Button__dropzone {
    display: block;
    margin: 10px auto 0;
  }
`;
export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1c0c1e;
  text-align: center;
`;
export const Text = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #6b7280;
  margin-top: 5px;
`;

export const UploadedFile = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 35px;
  margin-top: 10px;
`;

export const ButtonClose = styled.button`
  display: inline-block;
  cursor: pointer;
`;
