import React from 'react';
import { connect } from 'react-redux';
// Components
import Rooms from '../../components/Rooms';
// Modules
import { roomsSelector } from '../../module';

class RoomsContainer extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  render() {
    const { rooms } = this.props;
    if (!rooms || !rooms.length) return null;

    return <Rooms data={rooms} />;
  }
}

const mapStateToProps = state => ({
  rooms: roomsSelector(state)
});

export default connect(mapStateToProps)(RoomsContainer);
