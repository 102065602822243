import { useEffect, useState } from 'react';
import * as dwvService from 'services/integrations/dwv';

export function useDwvIntegration() {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  async function fetch() {
    try {
      setIsFetching(true);
      const { data } = await dwvService.get();
      setData(data);
    } catch {
      setData(null);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  return { data, isFetching, fetch };
}
