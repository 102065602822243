import React from 'react';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Input, Select } from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import { ModalFooter } from '../../../../../components/Modal';

import validate from './validate';
import { parseValueBool } from 'lib/formHelpers';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

function CharacteristicsForm({
  isEditting,
  handleSubmit,
  handleClose,
  submitting
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Field xs={6} label="Titulo" name="title" component={Input} />
        <Field
          xs={6}
          label={<div>Perguntar Quantidade? </div>}
          name="has_quantity"
          component={Select}
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false }
          ]}
          placeholder=""
          parse={parseValueBool}
        />
      </Row>
      <ModalFooter>
        <Button color="white" colorText="primary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          {isEditting ? 'Editar' : 'Cadastrar'}
        </Button>
      </ModalFooter>
    </form>
  );
}

CharacteristicsForm.defaultProps = defaultProps;
CharacteristicsForm.propTypes = propTypes;

export default reduxForm({
  form: 'CharacteristicsForm',
  validate,
  enableReinitialize: true
})(CharacteristicsForm);
