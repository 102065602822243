import React, { useContext } from 'react';
import { ContextCompare } from '../../index';
import { Cell, CellLabel } from '../Table/styles';
import RowDivider from '../../components/RowDivider';

const RowTaxes = () => {
  const { properties } = useContext(ContextCompare);

  return (
    <>
      <RowDivider />
      <tr>
        <CellLabel rowSpan={2}>Taxas</CellLabel>
        {properties.map(
          ({
            id,
            calculated_territorial_tax_price,
            territorial_tax_type,
            calculated_condominium_price,
            calculated_taxes_price,
            taxes_description
          }) => {
            if (
              !calculated_taxes_price &&
              !calculated_condominium_price &&
              !calculated_taxes_price
            ) {
              return <Cell key={`taxes-${id}`}>--</Cell>;
            }

            return (
              <Cell key={`taxes-${id}`}>
                {calculated_territorial_tax_price && (
                  <div>
                    IPTU - {calculated_territorial_tax_price}
                    {territorial_tax_type == '2' ? ' /Mês' : ' /Ano'}
                  </div>
                )}
                {calculated_condominium_price && (
                  <div>Condomínio - {calculated_condominium_price} /Mês</div>
                )}
                {calculated_taxes_price && (
                  <div>
                    {taxes_description || 'Outras taxas'} -{' '}
                    {calculated_taxes_price}
                  </div>
                )}
              </Cell>
            );
          }
        )}
      </tr>
    </>
  );
};

export default RowTaxes;
