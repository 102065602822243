import Button from 'components/Button';
import Overlay from 'components/Overlay';
import PreviewImage from 'components/PreviewImage';
import UploadButton from 'components/UploadButton';
import { useRequest } from 'hooks/useRequest';
import { useState } from 'react';
import Alert from 'react-s-alert';
import * as logosService from 'services/sites/logos';
import Box from '../../components/Box';

const LogoOpenGraph = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { data: logo, fetchData } = useRequest({
    request: logosService.getWhatsappLogo,
    initialState: {},
  });

  const onDropAccepted = async (files) => {
    setIsLoading(true);

    try {
      await logosService.uploadWhatsappLogo({ image: files[0] });
      await fetchData();
    } catch (err) {
      Alert.success('Erro ao enviar imagem');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      isLoading={isLoading}
      title="Usada ao compartilhar no WhatsApp"
      text="Mínimo: 300x300px"
    >
      <UploadButton onDropAccepted={onDropAccepted}>
        <PreviewImage
          key={logo?.whatsapp_logo_url}
          className="h-hover"
          url={logo?.whatsapp_logo_url}
        >
          <Overlay
            alpha={!!logo?.whatsapp_logo_url ? 0.6 : 0}
            className={!!logo?.whatsapp_logo_url && 'h-hover__show'}
          >
            {!!logo?.whatsapp_logo_url ? (
              <Button color="white">Alterar</Button>
            ) : (
              <Button color="secondary">Adicionar</Button>
            )}
          </Overlay>
        </PreviewImage>
      </UploadButton>
    </Box>
  );
};

export default LogoOpenGraph;
