import styled, { css } from 'styled-components';

export const Overlay = styled.div`
  border-radius: 4px;
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6);

  ${(p) =>
    p.theme.isSelected
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}

  transition: all 0.3s ease-in-out;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  border-radius: 4px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));

  &:hover ${Overlay} {
    opacity: 1;
    visibility: visible;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: 4px;
    object-position: center top;
  }
`;

export const Btn = styled.button`
  display: block;
  width: 110px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary};

  &.Btn--center {
    margin: 10px auto 0;
  }

  & + & {
    margin-top: 10px;
  }
`;

export const Text = styled.div`
  color: #fff;
`;

export const Icon = styled.span`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-top: -4px;
  margin-right: 5px;

  svg {
    position: relative;
    z-index: 5;
  }

  &:after {
    content: '';
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    background-color: #fff;
  }
`;
