import styled from 'styled-components';

export const Wrapper = styled.div``;
export const Text = styled.div`
  font-size: 14px;
  color: #65686f;
`;
export const Number = styled.div`
  font-size: 22px;
  color: ${(p) => p.theme.color};
  margin-bottom: 5px;
`;
