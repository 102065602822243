import Button from 'components/Button';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import Pagination from 'components/Pagination';
import { openModalPortalPropertiesSelect } from 'modules/modal';
import FixedBarPortals from 'pages/PortalsOptionSelect/components/FixedBarPortals';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import noproperties from '../../no-properties.png';
import Header from '../Header';
import Properties from '../Properties';
import { WrapperNoData } from './styles';

function PropertiesPortal() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    selectedOption,
    properties,
    params,
    meta,
    handleClickSearch,
    handleRemove,
    isFetching,
    handleRemoveAll,
  } = usePortalOptions();

  const handleClickCheckAll = () => {
    dispatch(
      openModalPortalPropertiesSelect({
        properties,
        params: {
          ...params,
          filter: {
            by_portal_option_id: selectedOption.portal_option_id,
            on_network: ['self', 'orulo', 'dwv'],
            by_available_for_portals: true,
            // doesnt_have_portal_real_estate_id: selectedPortal.id,
          },
        },
      })
    );
  };

  const handlePageChange = ({ selected }) => {
    let currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.set('offset', selected + 1);
    history.push({
      search: currentSearchParams.toString(),
    });
  };

  if (!properties || properties.length <= 0) {
    return (
      <WrapperNoData>
        <Nodata image={noproperties} title="Nenhum imóvel selecionado" text="">
          <Button color="success" onClick={handleClickSearch}>
            Adicionar Imóvel ao portal
          </Button>
        </Nodata>
        <Loading isVisible={isFetching} isFullScreen />
      </WrapperNoData>
    );
  }

  return (
    <div>
      <Header />
      <Properties
        handleClickCheckAll={handleClickCheckAll}
        handleRemove={handleRemove}
        handleRemoveAll={handleRemoveAll}
      />
      {params?.offset && (
        <Pagination
          forcePage={params?.offset - 1}
          pageCount={meta?.pagination?.total_pages}
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
        />
      )}
      <Loading isVisible={isFetching} isFullScreen />
      <FixedBarPortals isPortal />
    </div>
  );
}

export default PropertiesPortal;
