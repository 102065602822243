import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import RCAlert from 'react-s-alert';
// Modules
import { hasIntegration, isLoggedSelector, updateGoogleAnalytics } from 'modules/google';
import { currentUserSelector, googleAnalyticsSelector } from 'modules/login';
// Containers
import GoogleAnalyticsLogin from 'containers/GoogleAnalyticsLogin';
// Components
import Alert from 'components/Alert';
import FormAccountSelect from './components/FormAccountSelect';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Container from 'components/Container';
import NoContent from 'components/NoContent';
// Modules
import { getGoogleAnalytics } from 'lib/analytics';

const styleAlert = {
  marginTop: '25px'
};

class Analytics extends Component {
  state = {
    noAnalyticsAccount: false
  };

  handleError = () => {
    this.setState({ noAnalyticsAccount: true });
  };

  get canShowReception() {
    return !this.props.isLogged || this.state.noAnalyticsAccount;
  }

  get renderAlert() {
    if (!this.state.noAnalyticsAccount) return null;

    return (
      <Alert color="caution" style={styleAlert}>
        Você não possui uma conta no Google Analytics. Clique aqui para criar
        uma conta, e tente sincronizar novamente.
      </Alert>
    );
  }

  get initialValues() {
    try {
      return getGoogleAnalytics();
    } catch {
      return {};
    }
  }

  onSubmit = values => this.props.updateGoogleAnalytics(values);
  onSubmitSuccess = () => {
    RCAlert.success('Dados do analytics atualizados');
  };

  render() {
    if (this.props.hasIntegration) {
      return <Redirect to="/reports/analytics/new-users" />;
    }

    if (this.canShowReception) {
      return (
        <Wrapper.container>
          {this.renderAlert}
          <NoContent
            title="Analytics"
            text="É o endereço do seu site na internet. Normalmente inicia com www e termina com .com ou .com.br. É essencial para melhorar sua posição no Google, além de ajudar na divulgação e memorização do site!"
          >
            <GoogleAnalyticsLogin
              onSubmit={this.onSubmit}
              onSuccess={() => {
                this.setState({ noAnalyticsAccount: false });
              }}
            />
          </NoContent>
        </Wrapper.container>
      );
    }

    return (
      <Wrapper.container>
        <MainTitle
          title="Integração com Google Analytics"
          text="Faz com que seus clientes identifiquem facilmente sua marca, além de impedir que alguém copie suas fotos!"
        />
        <Container>
          <FormAccountSelect
            initialValues={this.initialValues}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
            onError={this.handleError}
          />
        </Container>
      </Wrapper.container>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: isLoggedSelector(state),
  hasIntegration: hasIntegration(state),
  currentUser: currentUserSelector(state),
  googleAnalytics: googleAnalyticsSelector(state)
});

const mapDispatchToProps = {
  updateGoogleAnalytics
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
