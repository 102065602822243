import { createModule } from 'lib/reducer-helpers';

const createAction = createModule('mainSearch');

const TOGGLE = createAction('TOGGLE');
const OPEN = createAction('OPEN');
const CLOSE = createAction('CLOSE');

export const isVisibleSelector = (state) => state.mainSearch.isVisible;

const initialState = {
  isVisible: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE:
      return {
        ...state,
        isVisible: !state.isVisible,
      };

    case OPEN:
      return {
        ...state,
        isVisible: true,
      };

    case CLOSE:
      return {
        ...state,
        isVisible: false,
      };

    default:
      return state;
  }
}

export const mainSearchActions = {
  toggle: () => ({
    type: TOGGLE,
  }),
  open: () => ({
    type: TOGGLE,
  }),
  close: () => ({
    type: TOGGLE,
  }),
};
