import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'qs';
import moment from 'lib/moment';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import setAuthorizationToken, {
  setOriginalToken,
} from './lib/setAuthorizationToken';
// Components
import Loading from 'components/Loading';
import SwitchRoutes from 'components/SwitchRoutes';
// Containers
import ModalRoot from './containers/ModalRoot';
// Modules
import {
  currentUserSelector,
  fetchReseller,
  getCurrentUser,
  getIsBlocked,
  listenSocketEvents,
} from './modules/login';
import {
  openModalAlerta,
  openModalCreditCardSuccess,
  openModalNewFeature,
  openModalPerson,
  openModalProperty,
} from 'modules/modal';

import {
  Actions as SelectedsActions,
  selectedsSelector,
} from 'modules/selecteds';

import { hideLoading } from 'modules/loading';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Actions as GoogleActions } from './modules/google';
// Templates
import Main from './templates/Main';
import BlankPage from './templates/BlankPage';
import Login from './templates/Login';
// Routes
import {
  authenticatedRoutes,
  blockedRoutes,
  notAuthenticatedRoutes,
} from './routes';
import * as pages from './pages';
import Print from './pages/Print';

// Services
import * as authService from 'services/auth';

// Helpers
import { isMobile } from 'lib/helpers';
import { setReferrerSource } from 'lib/referrer';
import { addBrowserInHTML } from 'lib/browser-helpers';
import { getParam } from 'lib/url-helpers';
import { fetchPrices } from 'modules/prices';
import SelectedProperties from 'containers/SelectedProperties';

const propTypes = {
  login: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
};

class App extends Component {
  state = {
    hasRegistered: false,
  };

  constructor(props) {
    super(props);

    const searchObj = this.searchObj;

    // Não é permitido usar o sistema
    // quando tiver logado pelo mobile
    if (isMobile()) {
      document.querySelector('body').classList.add('mobile');
    }

    setReferrerSource(window.location.search);

    // Verifica se tem um token na querystring
    // se tiver ele seta o token no localStorage
    if (this.token) {
      localStorage.setItem('@tecimob/central', true);

      if (searchObj?.consultant_id) {
        localStorage.setItem(
          '@tecimob/consultant_id',
          searchObj?.consultant_id
        );
      }
      this.resetTokens();
      this.setToken(this.token).then(() => {
        window.location.replace(window.location.origin);
      });
      return;
    }

    // Seta o token no localStorage
    this.setToken(localStorage.getItem('access_token'));
    this.originalToken = localStorage.getItem('original_token');
  }

  handleFocusWindow = () => {
    const verifyToken = localStorage.getItem('verify-token');

    if (
      !!verifyToken &&
      moment(moment(verifyToken).format()).isAfter(moment().format())
    )
      return null;

    authService.verifyToken();

    const nextDate = moment().add(10, 'minutes').format();

    localStorage.setItem('verify-token', nextDate);
  };

  componentDidMount() {
    const token = localStorage.getItem('access_token');
    const { listenSocketEvents } = this.props;

    const personId = getParam('_openModalPerson');
    const propertyId = getParam('_openModalProperty');

    if (personId) {
      this.props.openModalPerson({
        id: personId,
      });
    }

    if (propertyId) {
      this.props.openModalProperty({
        property: { id: propertyId },
      });
    }

    if (token) {
      listenSocketEvents(token);
    }

    window.addEventListener('focus', this.handleFocusWindow);

    // Adiciona o browser atual em uma classe
    addBrowserInHTML();
    this.fetchReseller();

    const propertiesSearch = localStorage.getItem(
      `@tecimob/${this?.props?.currentUser?.id}/properties_search`
    );

    // Adiciona uma nova lista de seleção
    this.props.registerSelected('properties_search', propertiesSearch);
    this.props.registerSelected('properties_search2', []);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.handleFocusWindow);
  }

  /**
   * Busca os dados da revenda
   */
  fetchReseller() {
    const { fetchReseller } = this.props;

    // Atualiza as informações do revendedor
    fetchReseller(window.location.hostname);
  }

  resetTokens() {
    localStorage.removeItem('original-user');
    localStorage.removeItem('current-user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('original_token');
  }

  /**
   * Seta o token no localStorage
   * @param token
   */
  async setToken(token) {
    const { getCurrentUser } = this.props;

    if (!token) return null;

    const originalUser = localStorage.getItem('original-user');

    if (!originalUser) {
      setOriginalToken(token);
    }

    // Seta o token de autorização
    setAuthorizationToken(token);

    // Pega o usuário logado pelo token
    const { data: user } = await getCurrentUser(token);

    if (!originalUser) {
      localStorage.setItem('original-user', user);
    }
  }

  /**
   * Seta o token original
   * @param token
   */
  set originalToken(token) {
    // original
    setOriginalToken(token);
  }

  get searchObj() {
    try {
      const { search } = this.props.location;

      return qs.parse(search, { ignoreQueryPrefix: true });
    } catch {
      return '';
    }
  }

  get token() {
    try {
      const { search } = this.props.location;

      return qs.parse(search, { ignoreQueryPrefix: true }).token;
    } catch {
      return '';
    }
  }

  get isRouteOfTerms() {
    const { location } = this.props;
    return location.pathname === '/termos-de-uso';
  }

  get isRouteLoading() {
    const { location } = this.props;
    return location.pathname.includes('/loading');
  }

  get isRoutePrint() {
    const { location } = this.props;
    return location.pathname.includes('/print');
  }

  get renderPrint() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route exact={false} path="/print" component={Print} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }

  get renderTerms() {
    return (
      <BrowserRouter>
        <div>
          <BlankPage />
          <Switch>
            <Route exact path="/termos-de-uso" render={() => <div />} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }

  get renderLoading() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route exact path="/loading/:token" component={pages.Loading} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }

  get authenticatedRoutes() {
    const { isBlocked } = this.props;

    if (isMobile()) {
      window.location.href = 'https://tecimob.com.br/download';
      return null;
    }

    if (isBlocked) {
      return (
        <SwitchRoutes routes={blockedRoutes}>
          <Redirect to="/plan/finished" />
        </SwitchRoutes>
      );
    }

    return (
      <SwitchRoutes routes={authenticatedRoutes}>
        <Redirect to="/reception" />
      </SwitchRoutes>
    );
  }

  render() {
    const { loading, login } = this.props;
    const { isVisible, isFetching } = loading;

    // Verifica se é a rota sobre termos de uso
    // se for renderiza um layout totalmente diferente
    // focado para essa página específica
    if (this.isRouteOfTerms) return this.renderTerms;
    if (this.isRouteLoading) return this.renderLoading;
    if (this.isRoutePrint) return this.renderPrint;

    // Verifica se está realmente autenticado
    if (login.isAuthenticated) {
      return (
        <BrowserRouter>
          <div>
            <Loading isFullScreen isVisible={isFetching || isVisible} />
            <ModalRoot />
            {/*<ChatApp />*/}
            <SelectedProperties />
            {/*<SupportChat />*/}
            <Main>{this.authenticatedRoutes}</Main>
          </div>
        </BrowserRouter>
      );
    }

    return (
      <BrowserRouter>
        <div>
          <Loading isFullScreen isVisible={login.isFetching} />
          <Login>
            <SwitchRoutes routes={notAuthenticatedRoutes}>
              <Redirect to="/" />
            </SwitchRoutes>
          </Login>
        </div>
      </BrowserRouter>
    );
  }
}

App.propTypes = propTypes;

const mapStateToProps = (state) => ({
  isBlocked: getIsBlocked(state),
  currentUser: currentUserSelector(state),
  login: state.login,
  loading: state.loading,
  propertiesSearch: selectedsSelector('properties_search')(state),
});

const mapDispatchToProps = {
  listenSocketEvents,
  fetchReseller,
  getCurrentUser,
  openModalCreditCardSuccess,
  openModalPerson,
  openModalAlerta,
  openModalProperty,
  openModalNewFeature,
  hideLoading,
  fetchPrices,
  setGoogleRefreshToken: GoogleActions.setGoogleRefreshToken,
  registerSelected: SelectedsActions.registerSelected,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
