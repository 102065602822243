export default {
  create: ({ name }) =>
    name ? `${name} foi inserido(a)` : 'Adicionado com sucesso',
  update: ({ name, password }) => {
    if (password) {
      return name ? `Senha do(a) ${name} foi alterada` : 'Senha foi alterada';
    }
    return name ? `${name} foi alterado(a)` : 'Alterado com sucesso';
  },
  delete: ({ name }) =>
    name ? `${name} foi excluído(a)` : 'Excluído com sucesso',
  reactivate: ({ name }) => (name ? `${name} foi restaurado(a)` : 'Reativado'),
  serverError: statusCode => {
    if (statusCode) {
      return 'Você não tem permissão para fazer isso';
    }
    return 'Problema no servidor';
  }
};

export const MSGS = {
  DEFAULT_ERROR: 'Voçê não tem permissão para fazer isso',
  DEFAULT_SUCCESS: 'Cadastrado(a) com sucesso'
};
