import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  .FormGroup {
    flex: 1 0;
    min-width: 0;
  }

  .Button {
    margin-top: 22px;
    margin-left: 15px;
    flex-shrink: 0;
    min-width: 0;
  }
`;
