// Assets
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useRequest } from 'hooks/useRequest';
import { useDispatch } from 'react-redux';
import { removeDealEarned } from 'services/deals';
import { getAllEarnedDeals } from 'services/properties';
import Table from './components/Table';

function Deals({
  infos: property,
  property: { id: propertyId },
  handleOpenMyself,
}) {
  const dispatch = useDispatch();

  const { data, fetchData, isFetching } = useRequest({
    request: () => getAllEarnedDeals(propertyId),
  });

  const handleOnCloseModalUpdate = () => {
    handleOpenMyself();
  };

  const handleRemoveDeal = (dealId) => async () => {
    dispatch(
      openConfirmation({
        title: 'Remover negócio',
        text: 'Deseja realmente remover esse negócio?',
        request: () => removeDealEarned(dealId),
        onSuccess: () => {
          fetchData();
        },
      })
    );
  };

  return (
    <Table
      isFetching={isFetching}
      data={data}
      onRemoveDeal={handleRemoveDeal}
      onCloseModalUpdate={handleOnCloseModalUpdate}
    />
  );
}

export default Deals;
