import api from 'services';

/**
 * Exibir detalhes da integração
 * @returns {Promise<AxiosResponse<never>>}
 */
export const get = () =>
  api.get('settings/networks/dwv').catch((response) => response);

/**
 * Cadastrar nova integração (conectar)
 * @param {Object} values
 * @param {string} values.token
 * @returns {Promise<AxiosResponse<*>>}
 */
export const connect = (values) => api.create('settings/networks/dwv', values);

/**
 * Remover integração (Desconectar)
 * @param {Object} params
 * @param {string} params.should_destroy_properties
 * @returns {Promise<AxiosResponse<never>>}
 */
export const disconnect = (params) =>
  api.delete('settings/networks/dwv', '', { params });

/**
 * Atualizar dados da integração
 * @param {Object} values
 * @param {string} values.id
 * @param {boolean} values.is_street_shown
 * @param {boolean} values.is_exact_map_shown
 * @param {boolean} values.is_map_shown
 * @param {boolean} values.is_published
 * @param {boolean} values.is_neighborhood_shown
 * @param {boolean} values.is_condominium_shown
 * @returns {Promise<AxiosResponse<*>>}
 */
export const update = ({ id, ...values }) =>
  api.update('settings/networks/dwv', values);
