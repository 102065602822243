import React from 'react';
import { Item, Ball } from 'components/ListAside';
import { NavHashLink } from 'react-router-hash-link';

const Link = ({ isHidden, hasError, isDisabled, to, children, ...props }) => {
  if (isHidden) return null;

  return (
    <Item
      hasError={hasError}
      as={NavHashLink}
      to={to}
      activeClassName="isActive"
      isDisabled={isDisabled}
      {...props}
    >
      <Ball /> <div>{children}</div>
    </Item>
  );
};

export default Link;
