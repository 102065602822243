import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Row } from 'react-flexbox-grid';
import GalleryItem from '../GalleryItem';

const GalleryList = SortableContainer(
  ({ images, handleClickOpenById, ...rest }) => (
    <Row>
      {images.map((image, index) => (
        <GalleryItem
          {...rest}
          key={`item-${image.id}-${image.isEditing}-${image.updated_at}`}
          index={index}
          data={image}
          handleClickOpenById={handleClickOpenById}
          useDragHandle
        />
      ))}
    </Row>
  )
);

export default GalleryList;
