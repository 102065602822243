import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { change, Field, formValueSelector } from 'redux-form';
// Service
import * as locationService from 'services/location';
// Modules
import { Actions, Selectors } from '../module';
// HOC
import { withFormName } from 'HOC/withFormName';
// Constants
import { defaultPropsLocation, propTypesLocation } from '../constants';

class CityField extends React.Component {
  static propTypes = {
    ...propTypesLocation,
    canUnregister: PropTypes.bool,
    stateId: PropTypes.string,
    neighborhoodName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    ...defaultPropsLocation,
    canUnregister: true,
    label: 'Cidade',
    name: 'city_id',
    canClearValues: true,
    neighborhoodName: 'neighborhood_id',
  };

  constructor(props) {
    super(props);

    props.register(props.formName, props.name);
  }

  componentDidMount() {
    if (this.props.stateId) this.fetchCities(this.props.stateId);
  }

  componentWillUnmount() {
    if (this.props.canUnregister) {
      this.props.unregister(this.props.formName, this.props.name);
    }
  }

  componentDidUpdate(prevProps) {
    const { stateId: currentId } = this.props;
    const { stateId: prevId } = prevProps;

    if (currentId !== null && currentId !== prevId) {
      this.fetchCities(currentId);
    }
  }

  request = () => this.props.request(this.props.formName, this.props.name);
  receive = (data) =>
    this.props.receive(this.props.formName, this.props.name, data);
  change = (fieldName, value) =>
    this.props.change(this.props.formName, fieldName, value);
  clear = (fieldKey) => this.props.clear(this.props.formName, fieldKey);

  /**
   * Busca os países
   * @param stateId
   * @return {Promise}
   */
  fetchCities = async (stateId) => {
    const { params } = this.props;
    if (!stateId) return false;

    this.request();
    const { data } = await locationService.getCities({
      state_id: stateId,
      ...params,
    });
    this.receive(data);
  };

  clearFields = () => {
    const { neighborhoodName } = this.props;
    this.clear([neighborhoodName]);
    this.change(neighborhoodName, null);
  };

  handleChange = (e, nextValue) => {
    const { canClearValues, onChange, value } = this.props;

    // Executa o evento de onChange caso tenha sido enviado
    // Caso contrário não faz absolutamente nada
    if (onChange) onChange(e, nextValue);

    // Limpa os campos se puder
    if (canClearValues) this.clearFields();

    // Limpa os campos se puder
    if (canClearValues && nextValue !== value) {
      this.clearFields();
    }
  };

  render() {
    const { onChange, options, params, ...props } = this.props;

    return (
      <Field
        disabled={options && !options.length}
        options={options}
        {...props}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);

  return {
    value: selector(state, ownProps.name),
    options: Selectors.getData(ownProps.formName, ownProps.name)(state),
    isLoading: Selectors.getIsFetching(ownProps.formName, ownProps.name)(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...Actions, change }, dispatch);

export default compose(
  withFormName,
  connect(mapStateToProps, mapDispatchToProps)
)(CityField);
