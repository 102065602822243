import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from 'hooks/useRequest';
// Services
import * as financeService from 'services/settings/finance';
// Modules
import { openModalBankStore } from 'modules/modal';

export const BankContext = React.createContext({
  banks: null,
  isFetching: null,
  fetchBanks: null,
  openModalBank: null,
  toggleStatus: null,
  handleRemoveById: null,
  handleEditById: null
});

export default function BankProvider({ children }) {
  const dispatch = useDispatch();

  const { data: banks, isFetching, fetchData: fetchBanks } = useRequest({
    request: financeService.getBanks
  });

  const openModalBank = useCallback(() => {
    dispatch(
      openModalBankStore({
        onSubmitSuccess: () => {
          fetchBanks();
        }
      })
    );
  }, [dispatch]);

  const toggleStatus = useCallback(
    bank => () => {
      return financeService.toggleStatus(bank).then(() => fetchBanks());
    },
    [fetchBanks]
  );

  const handleRemoveById = useCallback(
    id => async () => {
      await financeService.removeBankById(id);
      fetchBanks();
    },
    [fetchBanks]
  );

  const handleEditById = useCallback(
    id => () => {
      dispatch(
        openModalBankStore({
          id,
          onSubmitSuccess: () => {
            fetchBanks();
          }
        })
      );
    },
    [dispatch, fetchBanks]
  );

  return (
    <BankContext.Provider
      value={{
        banks,
        isFetching,
        fetchBanks,
        openModalBank,
        toggleStatus,
        handleRemoveById,
        handleEditById
      }}
    >
      {children}
    </BankContext.Provider>
  );
}
