import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;
export const Header = styled.div`
  display: flex;
  cursor: pointer;
  color: #1c0c1e;
  padding: 1.07143em 2.14286em;
  font-weight: 500;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const Text = styled.div`
  flex: 1 0;
  text-align: left;
`;

export const Content = styled.div`
  position: relative;
  padding-left: 39px;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: height 0.3s, opacity 0.3s;

  ${({ isVisible }) =>
    isVisible &&
    css`
      height: auto;
      visibility: visible;
      opacity: 1;
      margin-bottom: 10px;
    `}

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 38px;
    width: 1px;
    height: 100%;
    background-color: #e0e5eb;
  }
`;
