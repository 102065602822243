import Table from "components/Table";
import { selectedPropertiesSelector } from "modules/propertySearch";
import { useSelector } from "react-redux";
import PropertyItem from "./components/PropertyItem";

function PropertiesTable({
  properties,
  handleOpenProperty,
  canSelect,
  handleSelectProperty,
}) {
  const selecteds = useSelector(selectedPropertiesSelector);

  return (
    <div>
      <Table
        data={properties}
        isFetching={false}
        renderTitle={() => (
          <tr>
            {canSelect && <th />}
            <th>Número</th>
            <th>Ref</th>
            <th>Dormitórios</th>
            <th>Garagens</th>
            <th>Área Construída</th>
            <th>Área Privativa</th>
            <th>Área Total</th>
            <th>Transação</th>
            <th colSpan={2}></th>
          </tr>
        )}
        renderItem={(property) => (
          <PropertyItem
            key={property.id}
            handleOpenProperty={handleOpenProperty}
            property={property}
            canSelect={canSelect}
            selecteds={selecteds}
            handleSelectProperty={handleSelectProperty}
          />
        )}
      />
    </div>
  );
}

export default PropertiesTable;
