import styled from 'styled-components';
import Button from 'components/Button';

export const ButtonConfig = styled(Button)`
  padding: 0 10px;
`;

export const Btn = styled(Button)`
  svg {
    margin-top: -2px;
    margin-right: 3px;
  }
`;
