import React, { useState } from 'react';
import { Title } from '../../styles';
import { Wrapper } from './styles';

function DescriptionUpdate({ contract }) {
  const [isShowDetails, setShowDetails] = useState(false);

  if (!contract?.changes_description?.short) return null;

  return (
    <Wrapper>
      <Title>Descrição da alteração</Title>
      <div
        dangerouslySetInnerHTML={{
          __html: contract?.changes_description?.short,
        }}
      />
      <p>
        <a
          href="#"
          className="h-link"
          onClick={(e) => {
            e.preventDefault();
            setShowDetails((isShowing) => !isShowing);
          }}
        >
          {isShowDetails
            ? 'Esconder detalhes do cálculo'
            : 'Ver detalhes do cálculo'}
        </a>
      </p>
      {isShowDetails && (
        <div
          dangerouslySetInnerHTML={{
            __html: contract?.changes_description?.long,
          }}
        />
      )}
    </Wrapper>
  );
}

export default DescriptionUpdate;
