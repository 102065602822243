// Widgets.js
import { createModule } from '../../../lib/reducer-helpers';
import * as dealsService from '../../../services/deals';

const createAction = createModule('modalDealDelete');

// funnels selector
export const funnelsSelector = (state) => state.modalDealDelete.funnels;

// Actions
const REQUEST_FUNNELS = createAction('REQUEST_FUNNELS');
const RECEIVE_FUNNELS = createAction('RECEIVE_FUNNELS');

const initialState = {
  funnels: [],
  meta: {
    isFetching: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FUNNELS:
      return {
        ...state,
        meta: { ...state.meta, isFetching: true },
      };
    case RECEIVE_FUNNELS:
      return {
        ...state,
        funnels: action.funnels,
        meta: { ...state.meta, isFetching: false },
      };
    default:
      return state;
  }
}

// Action Creators
export function requestFunnels() {
  return { type: REQUEST_FUNNELS };
}

export function receiveFunnels(funnels) {
  return { type: RECEIVE_FUNNELS, funnels };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getFunnels = (params) => (dispatch) => {
  dispatch(requestFunnels());

  return dealsService
    .getFunnels({
      ...params,
      include: 'stages',
    })
    .then(({ data: funnels }) => {
      dispatch(receiveFunnels(funnels));
    });
};

/**
 * Move os negócios
 * @param stage
 * @param values
 */
export const handleDeleteStage = (stage, values) => (dispatch) => {
  dealsService.moveDeals(stage, values);
};
