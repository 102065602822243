import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Pages
import pages from './pages';
// Containers
import AnalyticsIntegration from 'containers/AnalyticsIntegration';
import { withPermission } from 'HOC/withPermission';

function Integrations() {
  return (
    <Switch>
      <Route exact path="/integrations/list" component={pages.Integrations} />
      <Route
        exact
        path="/integrations/analytics"
        render={() => <AnalyticsIntegration redirectTo="/integrations/list" />}
      />
      <Route exact path="/integrations/orulo" component={pages.Orulo} />
      <Route exact path="/integrations/dwv" component={pages.Dwv} />
      <Route exact path="/integrations/rdstation" component={pages.RdStation} />
      <Redirect to="/integrations/list" />
    </Switch>
  );
}

export default withPermission({
  rules: ['EDIT_INTEGRATION'],
  isPage: true,
})(Integrations);
