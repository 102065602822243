import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${p => p.theme.borderRadius};
  }
`;

export const Remove = styled.div`
  display: grid;
  place-items: center;
  position: absolute;
  top: -10px;
  right: -10.5px;
  width: 25px;
  height: 25px;
  text-align: center;
  background-color: ${p => p.theme.colors.danger};
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
`;
