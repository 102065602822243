import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from 'modules/loading';

export const useLoading = () => {
  const dispatch = useDispatch();

  return {
    show: () => dispatch(showLoading()),
    hide: () => dispatch(hideLoading())
  };
};
