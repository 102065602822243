import api from 'services/index';

export const categoriesReport = ({
  start_date,
  end_date,
  situation,
  posting,
  category_id,
  account_id,
  people_id,
}) => {
  return api.get('financial/reports/categories', {
    start_date,
    end_date,
    situation,
    posting,
    category_id,
    account_id,
    people_id,
  });
};
