import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
// Components
import ForEach from 'components/ForEach';
import LocationImageProperty from 'components/LocationImageProperty';
import Embed from 'components/Embed';
import { FaYoutube } from 'react-icons/fa';
import { Col, Row } from 'react-flexbox-grid';

const propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  keyExtractor: PropTypes.func,
  itemRenderer: PropTypes.func,
  textLimit: PropTypes.number,
};
const defaultProps = {
  title: null,
  type: 'list',
  children: null,
  keyExtractor: (el) => el.id,
  itemRenderer: (item) => <Item>{item.value}</Item>,
  textLimit: 500,
};

const Item = ({ children, ...props }) => (
  <li {...props} className="Block__list-item">
    {children}
  </li>
);

export const address_complete = ({
  complement_address,
  zip_code,
  address_formatted,
  street_address,
  street_number,
}) => {
  let address = [];
  let complement = '';

  if (street_address) {
    address = [...address, `${street_address}`];
  }

  if (street_number) {
    address = [...address, ` ${street_number}`];
  }

  if (address_formatted) {
    address = [...address, address_formatted];
  }

  if (zip_code) {
    address = [...address, zip_code];
  }

  const newAddress = address.join(', ');

  if (complement_address) {
    complement = ` | Complemento: ${complement_address}`;
  }

  return `${newAddress}${complement}`;
};

const ReadMoreText = ({ text, textLimit, isHtml, videoUrl }) => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  if (!isHtml) {
    const textToDisplay =
      showMore || text.length <= textLimit
        ? text
        : `${text.substring(0, textLimit)}...`;
    return (
      <p className="Block__text">
        {textToDisplay}
        {videoUrl ? (
          <div>
            <a href={videoUrl} className="h-link">
              <FaYoutube /> Ver vídeo
            </a>
          </div>
        ) : null}
        {text.length > textLimit && (
          <span onClick={toggleShowMore} className="Block__show-more">
            {showMore ? ' Ler menos' : ' Ler mais'}
          </span>
        )}
      </p>
    );
  }

  const truncatedHtml =
    showMore || text.length <= textLimit
      ? text
      : `${text.substring(0, textLimit)}...`;

  return (
    <div className="Block__text">
      <div dangerouslySetInnerHTML={{ __html: truncatedHtml }} />
      <Row between="xs" style={{ padding: '0 7px', marginTop: '5px' }}>
        {text.length > textLimit && (
          <Col>
            <span onClick={toggleShowMore} className="h-link h-margin-top--5">
              {showMore ? ' Ler menos' : ' Ler mais'}
            </span>
          </Col>
        )}

        {videoUrl ? (
          <Col>
            <a href={videoUrl} target="_blank" rel="noopener noreferrer">
              <FaYoutube color="#ff0000" style={{ margin: '-3px 4px 0 0' }} />
              <span className="h-link">Ver vídeo</span>
            </a>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

const renderType = {
  list: ({ items, keyExtractor, itemRenderer }) => {
    return (
      <ul className="Block__list">
        <ForEach
          data={items}
          keyExtractor={keyExtractor}
          itemRenderer={itemRenderer}
        />
      </ul>
    );
  },
  text: ({ isHtml, text, textLimit, videoUrl }) => (
    <ReadMoreText
      text={text}
      textLimit={textLimit}
      isHtml={isHtml}
      videoUrl={videoUrl}
    />
  ),
  map: ({ property, isExactPlace, location, position, pov, ...rest }) => (
    <>
      <p className="h-margin-bottom--15 h-color--primary">
        {address_complete(location)}
      </p>
      <LocationImageProperty property={property} />
    </>
  ),
  video: ({ videoUrl }) => <Embed proportion={3 / 4} src={videoUrl} />,
};

const canRender = ({ type, ...rest }) => {
  switch (type) {
    case 'list': {
      if (rest.items) {
        return !isEmpty(rest.items);
      }
      return false;
    }
    case 'text':
      return !!rest.text;
    case 'map':
      return !!rest.position;
    case 'video':
      return !!rest.videoUrl;
    default:
      return false;
  }
};

const Block = ({ title, type, children, style, ...rest }) =>
  canRender({ type, ...rest }) && (
    <div className={`Block Block--${type}`} style={style}>
      <h5 className="Block__title">{title}</h5>
      {renderType[type](rest)}
    </div>
  );

Block.propTypes = propTypes;
Block.defaultProps = defaultProps;
Block.item = Item;

export default Block;
