import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  padding: 0.5em;
  background-color: rgba(241, 244, 249, 0.5);
  border: 0 none;
  font-size: ${p => p.size}px;
  font-weight: 700;
`;
