import React from 'react';
import Button from 'components/Button';
import { Field, reduxForm } from 'redux-form';
import { Checkbox } from 'components/Form';
import { Wrapper } from './styles';

function FormClose({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Wrapper>
        <Button
          type="submit"
          color="white"
          colorText="danger"
          className="h-margin-right--15"
        >
          Fechar
        </Button>
        <Field
          label="Não mostrar novamente"
          type="checkbox"
          name="not_show_again"
          component={Checkbox}
        />
      </Wrapper>
    </form>
  );
}

export default reduxForm({
  form: 'FormClose',
  enableReinitialize: true
})(FormClose);
