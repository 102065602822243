import styled from 'styled-components';

export const LoadMap = styled.div`
  position: relative;

  img {
    width: 100%;
  }

  .Button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const MapWrapper = styled.div`
  padding: 20px;
  padding-top: 0;
`;
