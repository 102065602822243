import React, { useEffect, useMemo } from 'react';
import Tooltip from 'react-tooltip';
import Label from 'components/Label';
import { StagesWrapper } from './styles';

const StageLabel = ({ stage }) => (
  <Label kind="solid" color={stage?.color}>
    {stage?.name}
  </Label>
);

const StageComponent = ({ stages, stage, max }) => {
  const stagesTotal = stages?.length || 0;
  const hasMore = stagesTotal > max;

  const stagesList = useMemo(() => {
    if (max) return stages?.slice(0, max);
    return stages;
  }, [stages]);

  const stagesLeft = useMemo(() => {
    if (!max) return null;
    return stages?.slice(max, stagesTotal);
  }, [stages, stagesTotal, max]);

  const tooltip = useMemo(() => {
    try {
      return stagesLeft.reduce((acc, stage) => {
        acc += `${stage.name}<br/>`;
        return acc;
      }, '');
    } catch {
      return null;
    }
  }, [stagesLeft]);

  useEffect(() => {
    if (tooltip) {
      Tooltip.rebuild();
    }
  }, [tooltip]);

  if (stages?.length > 0) {
    return (
      <StagesWrapper>
        {stagesList?.map((stage) => (
          <StageLabel key={stage?.name} stage={stage} />
        ))}
        {hasMore && (
          <Label
            kind="solid"
            data-place="bottom"
            color="primary"
            data-tip={tooltip}
          >
            +{stagesTotal - max}
          </Label>
        )}
      </StagesWrapper>
    );
  }

  if (stage?.name) {
    return <StageLabel key={stage?.name} stage={stage} />;
  }

  return null;
};

export default StageComponent;
