import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-lodash-debounce';
import { useRequest } from 'hooks/useRequest';
import { getNeighborhoods } from 'services/location';
import { normalizeSearch } from 'lib/text';
import useFormValue from 'hooks/useFormValue';
import { Field } from 'redux-form';
import { Input, Checkbox } from 'components/Form';
import { Row, Col } from 'react-flexbox-grid';
import Loading from 'components/Loading';
// import { Container } from './styles';

const NeighborhoodSelect = ({ fields, name, cityId, change }) => {
  const [initialized, setInitialized] = useState(false);
  const [selecteds, setSelecteds] = useState([]);

  const filter = useFormValue(`${name}.filter`);
  const neighborhoodIds = useFormValue(`${name}.neighborhoods_id`);
  const filterNormalized = useMemo(() => normalizeSearch(filter), [filter]);

  const { data: neighborhoods, fetchData, isFetching } = useRequest({
    request: getNeighborhoods,
    initialFetch: false
  });

  async function fetchNeighborhood(cityId, ids) {
    if (!ids || ids.length <= 0) {
      return setSelecteds([]);
    }

    const { data } = await getNeighborhoods({
      city_id: cityId,
      filter: { id: ids }
    });

    setSelecteds(data);
  }

  useEffect(() => {
    fetchNeighborhood(cityId, neighborhoodIds);
  }, [cityId, neighborhoodIds]);

  useEffect(() => {
    if (neighborhoodIds?.length > 0 && cityId && !initialized) {
      fetchData({ city_id: cityId, filter: { id: neighborhoodIds } });

      // Marca como inicializado para não executar esse request novamente
      setInitialized(true);
    }
  }, [cityId, initialized, neighborhoodIds]);

  const filteredNeighborhoods = useMemo(() => {
    if (!neighborhoods) return [];
    if (!filterNormalized)
      return neighborhoods?.filter(({ id }) => !neighborhoodIds?.includes(id));

    return neighborhoods.filter(({ id, name }) => {
      return (
        !neighborhoodIds?.includes(id) &&
        normalizeSearch(name)?.includes(filterNormalized)
      );
    });
  }, [filterNormalized, neighborhoods, neighborhoodIds]);

  const handleChange = useCallback(
    (e, value) => {
      if (value.length > 2) {
        fetchData({ city_id: cityId, filter: { name: value } });
      }
    },
    [fetchData, cityId]
  );

  const handleChangeDebounced = useDebouncedCallback(handleChange, 800);

  return (
    <>
      {!!cityId && (
        <Row>
          <Field
            label={'Buscar bairro'}
            xs={6}
            name={`${name}.filter`}
            component={Input}
            placeholder="Ex.: Centro"
            onChange={handleChangeDebounced}
          />
        </Row>
      )}
      <Row>
        {isFetching && <Loading isVisible isBlock />}
        {filteredNeighborhoods &&
          filteredNeighborhoods.map((neighborhood, i) => {
            return (
              <Col key={neighborhood.id} xs={3} className="ListCheck__item">
                <Field
                  id={neighborhood.id}
                  type="checkbox"
                  name={`${name}.neighborhoods_id`}
                  label={neighborhood.name}
                  component={Checkbox}
                  inputValue={neighborhood.id}
                />
              </Col>
            );
          })}
      </Row>
      {!!selecteds.length > 0 && (
        <Row>
          <Col xs={12}>
            <p className="h-margin-bottom--10">
              <strong>Bairros selecionados:</strong>
            </p>
            <Row>
              {selecteds.map(neighborhood => (
                <Col key={neighborhood.id} xs={3} className="ListCheck__item">
                  <Field
                    id={neighborhood.id}
                    type="checkbox"
                    name={`${name}.neighborhoods_id`}
                    label={neighborhood.name}
                    component={Checkbox}
                    inputValue={neighborhood.id}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default NeighborhoodSelect;
