import { useRequest } from 'hooks/useRequest';
import * as siteTemplatesService from 'services/settings/site-templates';

export const useTemplateHistory = () => {
  const {
    data: history,
    isFetching: isFetchingHistory,
    fetchData: fetchHistory,
  } = useRequest({
    request: () => siteTemplatesService.getHistory(),
    initialFetch: false,
  });

  return { history, isFetchingHistory, fetchHistory };
};
