import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

// Components
import { FieldDateRange } from 'components/Form';
import moment from 'moment';
import { WrapperDateRange } from 'pages/Reports/pages/PeoplePerPeriod/components/Form/styles';
import { Wrapper } from './styles';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function Form({ handleSubmit, children }) {
  return (
    <Wrapper onSubmit={handleSubmit}>
      {children}
      <WrapperDateRange>
        <FieldDateRange canClear={false} label="Intervalo" />
      </WrapperDateRange>
    </Wrapper>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

const startDate = moment()
  .subtract(5, 'month')
  .startOf('month')
  .format('DD/MM/YYYY');

const endDate = moment().endOf('month').format('DD/MM/YYYY');

export default reduxForm({
  form: 'FilterReports',
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: {
    start_date: startDate,
    end_date: endDate,
  },
  onChange: (values, dipatch, props) => {
    props.submit();
  },
})(Form);
