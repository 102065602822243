import React, { useEffect } from 'react';
import Box from 'pages/Crm/components/Box';
import { usePeriod } from 'pages/Crm/pages/Dashboard/hooks/usePeriod';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useRequest } from 'hooks/useRequest';
import api from 'services';
import { selector } from 'pages/Crm/components/FormUser';
import Loading from 'components/Loading';
import Placeholder from 'pages/Crm/pages/Dashboard/components/LostByStage/Placeholder';
import { useFormFunnelId } from 'pages/Crm/pages/People';
import { useSelector } from 'react-redux';

// Custom tick component to rotate labels
const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        transform="rotate(-45)"
        fill="#666"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          border: '1px solid #999',
          padding: '10px',
        }}
      >
        <p>{`Quantidade: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

function ReasonsOfLoss() {
  const date = usePeriod();
  const userId = useSelector((state) => selector(state, 'user_id')?.user_id);
  const funnelId = useFormFunnelId();

  const { data, isFetching, fetchData } = useRequest({
    request: ({ start_date, end_date, user_id, funnel_id }) => {
      return api.getList('crm/dashboard/lost-by-stage', {
        user_id,
        funnel_id,
        start_date,
        end_date,
      });
    },
    initialFetch: false,
  });

  useEffect(() => {
    if ((date.start_date || date.end_date) && funnelId) {
      fetchData({
        user_id: userId,
        funnel_id: funnelId,
        start_date: date.start_date,
        end_date: date.end_date,
      });
    }
  }, [date, userId, funnelId]);

  if (data.length <= 0) {
    return <Placeholder />;
  }

  return (
    <Box
      title="Negócios Perdidos por Etapa"
      text="Análise de percentual de perdas em cada etapa"
    >
      <Loading isVisible={isFetching} isFullComponent />
      {data && data.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={data}
            layout="horizontal"
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            overflow="visible"
          >
            <CartesianGrid vertical={false} />
            <YAxis type="number" />
            <XAxis
              dataKey="name"
              type="category"
              interval={0}
              tick={{ dy: 10 }}
              angle={-15}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="deals_count"
              barSize={55}
              label={({ value, x, y, width }) => {
                const percent = data.find(
                  (item) => item.deals_count === value
                ).percent;

                return percent >= 5 ? (
                  <text
                    x={x + width / 2}
                    y={y}
                    dy={-10}
                    textAnchor="middle"
                    fill="#fff"
                  >
                    <tspan x={x + width / 2} dy="1.2em">
                      {value}
                    </tspan>
                    <tspan
                      x={x + width / 2}
                      dy="1.2em"
                    >{`(${percent}%)`}</tspan>
                  </text>
                ) : null;
              }}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    index === 0
                      ? '#FF9702'
                      : index === data.length - 1
                      ? '#31D084'
                      : '#FF9702'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : null}
    </Box>
  );
}

export default ReasonsOfLoss;
