import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Content = styled.div`
  display: flex;

  .FormControl {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Button = styled.div`
  display: grid;
  place-items: center;
  min-width: 40px;
  height: 40px;
  border: 1px solid #dedede;
  border-left: 0 none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  font-size: 20px;

  .is-dirty & {
    border-color: rgba(28, 12, 30, 0.54);
  }

  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;
