import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { Input, FormError } from 'components/Form';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Button from 'components/Button';
// Validate
import validate from './validate';
import { useDispatch } from 'react-redux';
import { openModalRegisterDomain } from 'modules/modal';
import useFormValue from 'hooks/useFormValue';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const AddDomainForm = ({ handleSubmit, handleClose, error }) => {
  const dispatch = useDispatch();
  const url = useFormValue('url');

  return (
    <form onSubmit={handleSubmit}>
      <Field label="Domínio" name="url" component={Input} />
      {error?.[0] === 'DOMAIN_NOT_REGISTERED' && (
        <FormError isVisible>
          O sistema não detectou este domínio como registrado.
          <br />
          Caso seja um domínio novo, use o{' '}
          <button
            type="button"
            className="h-link"
            onClick={() => {
              dispatch(
                openModalRegisterDomain({
                  url
                })
              );
            }}
          >
            Registrar um domínio
          </button>
        </FormError>
      )}
      <ModalFooter>
        <Button color="white" colorText="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Adicionar
        </Button>
      </ModalFooter>
    </form>
  );
};

AddDomainForm.propTypes = propTypes;

export default reduxForm({
  form: 'AddDomainForm',
  validate,
  enableReinitialize: true
})(AddDomainForm);
