import React from 'react';
import * as PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Wrapper } from './styles';
import Image from 'components/Image';
import loading from 'assets/img/loading.svg';

const PreviewImage = ({
  type = null,
  sizeImage = 'original',
  url,
  background,
  opacity,
  size = [200, 200],
  padding = 0,
  className,
  onClick,
  children,
  isLoading
}) => {
  const width = size[0];
  const height = size[1];

  return (
    <ThemeProvider
      theme={{
        padding,
        width,
        height,
        background,
        opacity
      }}
    >
      <Wrapper onClick={onClick} className={className}>
        {isLoading ? (
          <img src={loading} alt="Alt" />
        ) : (
          url && (
            <Image type={type} size={sizeImage} src={url} alt="Preview Image" />
          )
        )}
        {children}
      </Wrapper>
    </ThemeProvider>
  );
};

PreviewImage.propTypes = {
  opacity: PropTypes.number,
  padding: PropTypes.number,
  background: PropTypes.string,

  size: PropTypes.array,
  url: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node
};

export default PreviewImage;
