import withProvider from 'HOC/withProvider';
import { PAYMENT_METHODS } from 'constants/constants';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';
import { openModalHasPayment } from 'modules/modal';
import { useCallback } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import Content from './components/Content';
import { ModalPaymentProvider, useModalPayment } from './contexts/payment';
import useContract from './hooks/useContract';
import * as creditsService from 'services/financial/credits';
import { SubmissionError } from 'redux-form';
import { useCurrentContract } from 'hooks/api/contracts';
import Loading from 'components/Loading';

function ModalPayment({ modalConfig, isOpen, handleClose, modalType }) {
  const { isLoading, setIsLoading, hasDwv, hasOrulo } = useModalPayment();

  const dispatch = useDispatch();

  const { users_count, mailboxes_count, redirect_mails_count } =
    useCurrentRealEstate();

  const { currentContract, isFetchingCurrentContract } = useCurrentContract();

  const {
    hasError,
    isSuccess,
    setHasError,
    contractBankSlip,
    contractWithCreditCard,
  } = useContract();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        // Verifica se teve pagamento hoje
        const hasPaid = await creditsService.hasPaymentToday();

        if (hasPaid && !values.force) {
          throw new Error('HAS_PAID');
        }

        setIsLoading(true);

        switch (values.receiving_method) {
          case PAYMENT_METHODS.CREDIT_CARD:
            await contractWithCreditCard(values);
            break;
          default:
            await contractBankSlip(values);
            break;
        }

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);

        if (err instanceof SubmissionError) {
          throw err;
        }

        if (err.message === 'HAS_PAID') {
          console.log('values: ', values);

          dispatch(
            openModalHasPayment({
              onConfirm: async () => {
                await handleSubmit({ ...values, force: 1 });

                window.location.reload();
              },
            })
          );

          return {};
        }
      }
    },
    [contractWithCreditCard, contractBankSlip]
  );

  if (isFetchingCurrentContract) return <Loading isVisible isFullScreen />;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Content
        initial={{
          receiving_method: '2',
          users: currentContract?.items?.users_quantity || users_count,
          additional_users:
            currentContract?.items?.users_quantity || users_count,
          months: 12,
          mailboxes_number:
            currentContract?.items?.emails_quantity || mailboxes_count || 0,
          redirect_mails_number: redirect_mails_count ?? 0,
          is_orulo: currentContract?.items?.has_orulo || hasOrulo,
          is_dwv: currentContract?.items?.has_dwv || hasDwv,
          consultant_id: localStorage.getItem('@tecimob/consultant_id'),
        }}
        hasError={hasError}
        onClose={handleClose}
        isSuccess={isSuccess}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        setHasError={setHasError}
      />
    </Modal>
  );
}

export default withProvider(ModalPaymentProvider)(ModalPayment);
