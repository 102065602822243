import React from 'react';
import { Field } from 'redux-form';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { Content, Label, Wrapper } from './styles';
import { ThemeProvider } from 'styled-components';
import Errors from 'components/Form/components/Errors';

function RadioListField({
  variant,
  label,
  options,
  meta,
  input,
  onChange,
  errors,
  ...rest
}) {
  return (
    <ThemeProvider
      theme={{
        variant,
      }}
    >
      <Wrapper>
        <Label>{label}</Label>
        <Content className="RadioList__content">
          {options.map((option, index) => {
            return (
              <label
                key={index}
                onClick={() => {
                  if (option.value === input.value) {
                    input.onChange(null);
                    return null;
                  }
                  if (onChange) onChange(option.value);
                  input.onChange(option.value);
                }}
              >
                {option.value === input.value ? (
                  <MdRadioButtonChecked />
                ) : (
                  <MdRadioButtonUnchecked />
                )}
                <span>{option.label}</span>
              </label>
            );
          })}
        </Content>
      </Wrapper>
      <Errors meta={meta} />
    </ThemeProvider>
  );
}

function RadioList({ variant = 'default', label, options, onChange, ...rest }) {
  return (
    <Field
      {...rest}
      variant={variant}
      label={label}
      options={options}
      onChange={onChange}
      component={RadioListField}
    />
  );
}

export default RadioList;
