import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  & + & {
    margin-top: 10px;
  }

  h2 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    color: ${p => p.theme.colors.primary};
  }
`;
