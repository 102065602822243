// Components
import { SelectMultiple } from 'components/Form';
import { parseSelect } from 'lib/formHelpers';
import { Field } from 'redux-form';


const propTypes = {};
const defaultProps = {};

const CharacteristicsField = ({ options }) => {
  if (!options || options?.length <= 0) return null;

  return (
    <Field
      name="by_characteristic_id"
      label="Caracteristicas do imóvel"
      options={options}
      component={SelectMultiple}
      valueKey="id"
      labelKey="title"
      parse={parseSelect('id')}
      closeOnSelect={false}
    />
  );
};

CharacteristicsField.propTypes = propTypes;
CharacteristicsField.defaultProps = defaultProps;

export default CharacteristicsField;
