import styled from 'styled-components';
import { em as _em, rgba } from 'polished';
import { HEIGHT, WIDTH } from '../../constants';
import theme from 'components/ModelsPost/theme';

export const em = (pixels) => _em(pixels, 32);

export const Wrapper = styled.div`
  position: relative;
  width: ${em(WIDTH)};
  height: ${em(HEIGHT)};
  font-family: 'Montserrat', sans-serif;
  font-size: ${(p) => p.theme.fontSize};
`;

export const Image = styled.img`
  width: inherit;
  height: inherit;
  object-fit: cover;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(p) => rgba(p.theme.backgroundColor, 0.8)};
  padding: ${em(30)} 0;
  margin-bottom: ${em(50)};
`;

export const Title = styled.div`
  font-size: ${em(22)};
  font-weight: 600;
  color: ${(p) => p.theme.mainColor};
`;

export const Price = styled.div`
  margin-top: ${em(15)};
  font-size: ${em(22)};
`;

export const Description = styled.ul`
  width: 80%;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: ${em(35)} auto 0;
  gap: ${em(30)};

  li {
    flex-shrink: 1;
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: ${em(20)};
    vertical-align: middle;

    svg {
      color: ${(p) => p.theme.colors.success};
    }
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: ${em(50)};
  right: ${em(50)};

  img {
    height: ${em(theme.logoSize)};
  }
`;
