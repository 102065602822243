import React from 'react';
import { connect } from 'react-redux';
// Component
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
import TableMenu from '../../components/TableMenu';
// Modules
import {
  fetchMenu,
  getMenu,
  handleRemoveMenu,
  handleSortChange,
  handleToggleMenu,
} from './module';

import {
  openModalCreatePage,
  openModalMenuLink,
  openModalNegotiationPageConfig,
} from 'modules/modal';
// Constants
import { TYPES_MENU } from 'pages/Site/constants';

class Menu extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    formData: {},
    isFetching: {},
  };

  componentDidMount() {
    this.props.fetchMenu();
  }

  handleToggleMenu = (menu) => {
    this.props.handleToggleMenu(menu);
  };

  handleToggle = (position = 'header', menu) => {
    this.props.handleToggleMenu(position, menu);
  };

  handleUpdateMenu = (menu) => {
    const { push } = this.props.history;

    if (menu.link === '/financie') {
      push('/site/layout/style/finance');
      return;
    }

    if (menu.link === '/negocie') {
      this.props.openModalNegotiationPageConfig({ menu });
      return;
    }

    switch (menu.type) {
      case TYPES_MENU.SCRIPT:
        push(`/site/layout/style/script/${menu.id}`);
        break;
      case TYPES_MENU.LINK:
        this.props.openModalMenuLink({ menu });
        break;
      case TYPES_MENU.ABOUT:
        push(`/site/layout/style/about`);
        break;
      default:
        break;
    }
  };

  handleRemoveMenu = (menu) => {
    this.props.handleRemoveMenu(menu);
  };

  handleClickCreatePage = () => {
    this.props.openModalCreatePage();
  };

  render() {
    const { menu, handleSortChange } = this.props;

    return (
      <>
        <MainTitle
          title="Menu"
          text="Edite, ordene, ou crie páginas novas para o menu do seu site."
        >
          <Button color="secondary" onClick={this.handleClickCreatePage}>
            Criar Página
          </Button>
        </MainTitle>
        <TableMenu
          data={menu}
          onChange={handleSortChange}
          handleUpdateMenu={this.handleUpdateMenu}
          handleToggleMenu={this.handleToggle}
          handleRemoveMenu={this.handleRemoveMenu}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: getMenu(state),
});

const mapDispatchToProps = {
  fetchMenu,
  handleSortChange,
  openModalCreatePage,
  handleToggleMenu,
  handleRemoveMenu,
  openModalMenuLink,
  openModalNegotiationPageConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
