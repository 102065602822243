import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
// Components
import { Input } from 'components/Form';
import FieldImage from 'components/Form/components/FieldImage';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

function FormBankStore({ isEditting, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Field
          required
          xs={6}
          label="Nome do banco"
          name="name"
          component={Input}
        />
        <Field
          xs={6}
          label="Link simular financiamento"
          name="link"
          component={Input}
        />
      </Row>
      <Row>
        <Col xs={12}>
          <FieldImage
            proportion={80 / 220}
            bgSize="cover"
            fileUrlField="file_url"
            name="image"
            text={
              <>
                Envie uma imagem <br />
                com a medida 220x80.
              </>
            }
            renderButtonSend={() => (
              <Button
                type="button"
                color="secondary"
                className="DropImage__button"
              >
                Enviar imagem
              </Button>
            )}
            style={{ marginBottom: '15px' }}
          />
        </Col>
      </Row>

      <ModalFooter>
        <Button color="white" colorText="secondary">
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          {isEditting ? 'Editar banco' : 'Adicionar banco'}
        </Button>
      </ModalFooter>
    </form>
  );
}

FormBankStore.defaultProps = {};
FormBankStore.propTypes = {};

export default reduxForm({
  form: 'FormBankStore',
  enableReinitialize: true
})(FormBankStore);
