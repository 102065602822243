import React from 'react';
import AceEditor from 'react-ace';
import PropTypes from 'prop-types';
// Constants
import { CONTROL_SHAPE } from 'constants/shapes';
// Components
import FormGroup from '../FormGroup';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-xcode';

const defaultProps = {
  label: null,
  required: false,
  maxLength: null,
  className: null,
  formGroupClassName: null,

  width: '100%',
  theme: 'xcode',
  mode: 'html',
  fontSize: 14,
  showPrintMargin: true,
  showGutter: true,
  highlightActiveLine: true,
  setOptions: {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: false,
    enableSnippets: false,
    showLineNumbers: true,
    tabSize: 2
  },
  defaultOptions: {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: false,
    enableSnippets: false,
    showLineNumbers: true,
    tabSize: 2
  },
  style: {
    borderRadius: '4px',
    border: '1px solid #e3e3e3'
  }
};

const propTypes = {
  ...CONTROL_SHAPE,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  autoFocus: PropTypes.bool
};

class Code extends React.Component {
  handleBlur = () => {
    const { input } = this.props;
    input.onBlur(input.value);
  };

  render() {
    const {
      refName,
      required,
      maxLength,
      formGroupClassName,
      label,
      input,
      disabled,
      meta,
      labelRender,
      setOptions,
      defaultOptions,
      ...rest
    } = this.props;

    const { xs, sm, md, lg } = rest;

    let formGroupProps = {};

    if (xs) formGroupProps = { ...formGroupProps, xs };
    if (sm) formGroupProps = { ...formGroupProps, sm };
    if (md) formGroupProps = { ...formGroupProps, md };
    if (lg) formGroupProps = { ...formGroupProps, lg };

    return (
      <FormGroup
        {...formGroupProps}
        className={formGroupClassName}
        maxLength={maxLength}
        labelText={label}
        isRequired={required}
        isDisabled={disabled}
        input={input}
        meta={meta}
        labelRender={labelRender}
      >
        <AceEditor
          {...input}
          ref={refName}
          id={input.name}
          setOptions={{
            ...defaultOptions,
            ...setOptions
          }}
          onBlur={this.handleBlur}
          {...rest}
        />
      </FormGroup>
    );
  }
}

Code.defaultProps = defaultProps;
Code.propTypes = propTypes;

export default Code;
