import api, { apiStorage } from './index';
import { responseMessage } from 'lib/service-helpers';
import { SubmissionError } from 'redux-form';
import Alert from 'react-s-alert';

/**
 * @param url - url do recurso
 * @param key - chave aonde vai gravar os dados
 * @param id - identificador aonde vai salvar dentro do localStorage
 * @param params - parametros de busca
 * @return {Promise|{ data: {...} }}
 */
const getList = ({ url, filter = {}, params }) => {
  let currentFilter = filter;

  if (params && params.filter) {
    currentFilter = {
      ...currentFilter,
      ...params.filter,
    };
  }

  return api.getList(url, {
    ...params,
    filter: currentFilter,
    sort: 'name',
  });
};

export const getCountries = (params) =>
  getList({
    key: 'countries',
    url: `addresses/countries`,
    params,
  });

export const getStates = ({ country_id, ...params }) =>
  getList({
    key: 'states',
    url: `addresses/states`,
    filter: { country_id },
    params,
  });

export const getDefaultStates = (params) =>
  getList({
    key: 'states',
    url: `addresses/states`,
    params,
  });

export const getCities = ({ state_id, ...params }) =>
  getList({
    key: 'cities',
    url: 'addresses/cities',
    filter: { state_id },
    params,
  });

export const getNeighborhoods = ({ city_id, filter = {}, ...params }) => {
  return getList({
    url: `addresses/neighborhoods`,
    filter: { ...filter, city_id },
    params,
  });
};

export const getZones = ({ city_id, filter = {}, ...params }) => {
  return getList({
    url: 'addresses/zones',
    filter: { ...filter, city_id },
    params,
  });
};

export const createCountry = (data) => api.create('countries', data);
export const createState = ({ country_id, ...params }) =>
  api.create(`countries/${country_id}/states`, params);
export const createCity = ({ state_id, ...params }) =>
  api.create(`states/${state_id}/cities`, params);

export const createNeighborhood = ({ city_id, ...params }) =>
  api.create(`cities/${city_id}/neighborhoods`, params);

  export const createZone = (params) =>
  api.create('addresses/zones', params);

export const updateNeighborhood = (values) =>
  api
    .update(`addresses/neighborhoods`, values)
    .then(responseMessage('Bairro Alterado'));

export const updateZone = (values) =>
  api
    .update(`addresses/zones`, values)
    .then(responseMessage('Zona Alterada'));

export const updateCity = ({ id, state_id, ...values }) =>
  api
    .update(`states/${state_id}/cities/${id}`, values)
    .then(responseMessage('Cidade alterada'));

export const removeCity = (state_id, city_id) =>
  api
    .delete(`states/${state_id}/cities/${city_id}`)
    .then(responseMessage('Cidade removida.'));
export const removeNeighborhood = (neighborhood_id) =>
  api
    .delete(`addresses/neighborhoods/${neighborhood_id}`)
    .then(responseMessage('Bairro removido.'));

export const removeZone = (zone_id) =>
  api
    .delete(`addresses/zones/${zone_id}`)
    .then(responseMessage('Zona removida.'));

export const getNeighborhoodById = (id) =>
  api.getOne(`addresses/neighborhoods/${id}`, '', {
    count: 'properties,people,search_profiles',
    with_defaults: false,
  });

export const getNeighborhoodByIdOnly = (id) =>
  api
    .getList(`addresses/neighborhoods`, {
      filter: {
        id,
      },
    })
    .then((res) => {
      res.data = res.data[0];
      return res;
    });

export const getZoneById = (id) =>
  api.getOne(`addresses/zones/${id}`, '', {
    count: 'properties',
    with_defaults: false,
  });

export const getZoneByIdOnly = (id) =>
  api
    .getList(`addresses/zones`, {
      filter: {
        id,
      },
    })
    .then((res) => {
      res.data = res.data[0];
      return res;
    });

export const getCityByIdOnly = (id) =>
  api
    .getList(`addresses/cities`, {
      filter: {
        id,
      },
    })
    .then((res) => {
      res.data = res.data[0];
      return res;
    });

export const transferCity = (fromCityid, toCityId) => {
  if (!fromCityid || !toCityId) throw new Error('Parametros invalidos');

  return api.create(`addresses/cities/${fromCityid}/transfers/${toCityId}`);
};

export const transferNeighborhood = (
  neighborhoodId,
  recipientNeighborhoodId
) => {
  if (!neighborhoodId || !recipientNeighborhoodId)
    throw new Error('Parametros invalidos');

  return api.create(
    `addresses/neighborhoods/${neighborhoodId}/transfers/${recipientNeighborhoodId}`
  );
};

export const transferZone = (
  zoneId,
  recipientZoneId
) => {
  if (!zoneId || !recipientZoneId)
    throw new Error('Parametros invalidos');

  return api.create(
    `addresses/zones/${zoneId}/transfers/${recipientZoneId}`
  );
};

export const transferAndRemoveCity = async (
  stateId,
  cityId,
  recipientCityId
) => {
  try {
    if (cityId === recipientCityId) {
      throw new SubmissionError({
        recipient_id: 'Você precisa escolher um bairro diferente',
      });
    }

    // Transfere todos os imóveis/perfil desse bairro
    await transferCity(cityId, recipientCityId);

    // Depois remove ele
    return removeCity(stateId, cityId);
  } catch (e) {
    throw e;
  }
};

export const transferAndRemoveNeighborhood = async (
  neighborhoodId,
  recipientNeighborhoodId
) => {
  try {
    if (neighborhoodId === recipientNeighborhoodId) {
      throw new SubmissionError({
        recipient_id: 'Você precisa escolher um bairro diferente',
      });
    }

    // Transfere todos os imóveis/perfil desse bairro
    await transferNeighborhood(neighborhoodId, recipientNeighborhoodId);

    // Depois remove ele
    return removeNeighborhood(neighborhoodId);
  } catch (e) {
    throw e;
  }
};

export const transferAndRemoveZone = async (
  zoneId,
  recipientZoneId
) => {
  try {
    if (zoneId === recipientZoneId) {
      throw new SubmissionError({
        recipient_id: 'Você precisa escolher uma zona diferente',
      });
    }

    // Transfere todos os imóveis/perfil dessa zona
    await transferZone(zoneId, recipientZoneId);

    // Depois remove ela
    return removeZone(zoneId);
  } catch (e) {
    throw e;
  }
};

export const getCitiesDescriptions = async (params) => 
  getList({
    url: 'addresses/cities-descriptions',
    params,
  });

export const getNeighborhoodsDescriptions = async (params) => 
  getList({
    url: 'addresses/neighborhoods-descriptions',
    params,
  });

export const createOrUpdateCityDescription = async (values) => api
  .create('/addresses/cities-descriptions', values)
  .then(responseMessage('Sucesso!'));

export const createOrUpdateNeighborhoodDescription = async (values) => api
  .create('/addresses/neighborhoods-descriptions', values)
  .then(responseMessage('Sucesso!'));

export const getOneCityDescription = async (id) => api
  .get(`/addresses/cities/${id}/description`, {
    include: 'city.state',
  })

export const getOneNeighborhoodDescription = async (id) => api
  .get(`/addresses/neighborhoods/${id}/description`)

export const removeCityDescription = async (id) => api
  .delete(`/addresses/cities/${id}/description`)
  .then(responseMessage('Cidade removida.'));

export const removeNeighborhoodDescription = async (id) => api
  .delete(`/addresses/neighborhoods/${id}/description`)
  .then(responseMessage('Bairro removido.'));

export const addImageCityDescription = async ({ image, cityId, order, ...params }) =>
  apiStorage.uploadFile(`/addresses/city-photos`, {
    image,
    city_description_id: cityId,
    order,
    ...params,
  });

export const addImageNeighborhoodDescription = async ({ image, cityId, order, ...params }) =>
  apiStorage.uploadFile(`/addresses/neighborhood-photos`, {
    image,
    neighborhood_description_id: cityId,
    order,
    ...params,
  });

export const alterImageCityDescription = ({ id, ...params }) =>
  apiStorage.put(`/addresses/city-photos/${id}`, params);

export const alterImageNeighborhoodDescription = ({ id, ...params }) =>
  apiStorage.put(`/addresses/neighborhood-photos/${id}`, params);

export const removeCityDescriptionImage = async (id) => {
  try {
    const response = await apiStorage.delete(
      `/addresses/city-photos`,
      id
    );
    Alert.success('Imagem removida');
    return response;
  } catch (e) {
    Alert.success('Erro ao remover imagem');
    throw e;
  }
};

export const removeNeighborhoodDescriptionImage = async (id) => {
  try {
    const response = await apiStorage.delete(
      `/addresses/neighborhood-photos`,
      id
    );
    Alert.success('Imagem removida');
    return response;
  } catch (e) {
    Alert.success('Erro ao remover imagem');
    throw e;
  }
};

