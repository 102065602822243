import React from 'react';
import PropTypes from 'prop-types';
import {
  MdCheckCircle as Checked,
  MdRadioButtonUnchecked as Unchecked,
} from 'react-icons/md';

const propTypes = {
  checked: PropTypes.bool,
};

const defaultProps = {
  checked: false,
};

const Radio = ({ checked, ...props }) =>
  checked ? (
    <Checked
      className="RadioButton__radio RadioButton__radio--checked"
      {...props}
    />
  ) : (
    <Unchecked className="RadioButton__radio" {...props} />
  );

Radio.defaultProps = defaultProps;
Radio.propTypes = propTypes;

export default Radio;
