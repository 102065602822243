import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { Select } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import { getCatalogs } from 'services/settings/facebook';
import useFormValue from 'hooks/useFormValue';
// import { Wrapper } from './styles';

function FieldFacebookCatalog({ facebook }) {
  const businessId = useFormValue('business_id');
  const pageId = useFormValue('page_id');

  const { data, fetchData } = useRequest({
    request: getCatalogs,
    initialFetch: false,
  });

  useEffect(() => {
    if (facebook.accessToken && businessId) {
      fetchData({
        business_id: businessId,
        page_access_token: facebook.accessToken,
      });
    }
  }, [facebook, pageId, businessId]);

  if (!pageId) return false;

  return (
    <Field
      xs={7}
      required
      disabled={!data}
      placeholder={!data ? 'Nenhuma empresa encontrada' : 'Selecione'}
      name="catalog_id"
      label="Catalogos"
      valueKey="id"
      labelKey="name"
      options={data}
      component={Select}
    />
  );
}

export default FieldFacebookCatalog;
