export const transformCityResponse = (data) => {
  return {
    state_id: data.city.state_id,
    city_id: data.city_id,
    description: data.description,
    photos: data.photos,
  };
}

export const transformNeighborhoodResponse = (data) => {
  return {
    state_id: data.neighborhood.city.state_id,
    city_id: data.neighborhood.city_id,
    neighborhood_id: data.neighborhood_id,
    description: data.description,
    photos: data.photos,
  };
}
