import React from 'react';
import { MdAdd } from 'react-icons/md';
// Helpers
import PropertiesList from 'containers/PropertyDashboard/containers/PropertiesList';
import moment, { format } from 'lib/moment';
import { getPropertiesRecent } from 'services/dashboards/properties';
import RatingNumber from 'components/RatingNumber';
import { openModalProperty } from 'modules/modal';
import { useDispatch } from 'react-redux';

function PanelPropertyRecent({ updatedTime, updateTime }) {
  const dispatch = useDispatch();

  return (
    <PropertiesList
      key="last-properties"
      title="Últimos Imóveis atualizados"
      icon={MdAdd}
      color="primary"
      renderNoData={() => <>Nenhum imóvel atualizado até o momento</>}
      updatedTime={updatedTime}
      request={getPropertiesRecent}
      limit={5}
      afterClose={updateTime}
      hideActions={false}
      renderAction={({ item, property }) => {
        return (
          <RatingNumber
            value={item?.rate?.rate}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              dispatch(
                openModalProperty({
                  property,
                  isOpenRate: true,
                  afterClose: () => {
                    updateTime();
                  },
                })
              );
            }}
          />
        );
      }}
      renderText={(property) => {
        const updatedAt = moment(
          property.interacted_at,
          format.datetime
        ).format(format.date);

        return <p>Atualizado: {updatedAt}</p>;
      }}
    />
  );
}

export default PanelPropertyRecent;
