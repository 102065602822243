import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import * as locationService from 'services/location';
import Gallery from './components/Gallery';
import { GalleryProvider } from './contexts/gallery';
import { compose } from 'redux';
import { withId } from 'pages/RegionPostStore/HOC/withId';
import FooterActions from '../../components/FooterActions';
import { reduxForm } from 'redux-form';
import MainTitle from 'components/MainTitle';
import Loading from 'components/Loading';
import { REDIRECT_TO } from 'pages/RegionPostStore/constants';

const Images = ({ handleSubmit, onSubmit, submitting }) => {
  const { id, type } = useParams();

  const [photos, setPhotos] = useState([]);
  const [descriptionId, setDescriptionId] = useState(null);

  const fetchData = useCallback(async () => {
    let response;

    if (type === 'city') {
      response = await locationService.getOneCityDescription(id);
    } else {
      response = await locationService.getOneNeighborhoodDescription(id);
    }

    // Seta os valores iniciais
    if (response.data?.photos && response.data?.id) {
      setDescriptionId(response.data.id);

      const orderedPhotos = response.data.photos.sort(
        (a, b) => a.order - b.order
      );
      setPhotos(orderedPhotos);
    }
  }, [type, id]);

  useEffect(() => {
    if (id) fetchData();
  }, [id, fetchData]);

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle title="Imagens" text="Envie fotos da região" />
      <GalleryProvider
        data={photos}
        setData={setPhotos}
        fetchData={fetchData}
        type={type}
      >
        <Gallery descriptionId={descriptionId} />
      </GalleryProvider>

      <FooterActions
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        submitText="Finalizar"
        isLast
      />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'RegionImages',
    onSubmit: (values, dispatch, props) => {
      props.history.push(REDIRECT_TO);
      return false;
    },
  })
)(Images);
