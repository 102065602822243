import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';
import { MdTrendingUp } from 'react-icons/md';
// Modules
import { openModalProperty } from 'modules/modal';
import { fetchFunnels, funnelsSelector } from './module';
import { fetchPropertyFunnels } from './components/PanelPropertyFunnel/module';
import { fetchPropertyRevision } from './components/PanelPropertyRevision/module';
import { fetchPropertyRentExpired } from './components/PanelPropertyRentExpired/module';
import { fetchPropertyExclusivityExpired } from './components/PanelPropertyExclusivityExpired/module';
import { fetchPropertyDeals } from './components/PanelPropertyDeal/module';
import { fetchPropertyRecent } from './components/PanelPropertyRecent/module';
// Components
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
// Containers
import PanelPropertyPortals from './components/PanelPropertyPortals';
import PanelFinancialIndex from './components/PanelFinancialIndex';
import PanelPropertyRevision from './components/PanelPropertyRevision';
import PanelPropertyRentExpired from './components/PanelPropertyRentExpired';
import PanelPropertyRecent from './components/PanelPropertyRecent';
import PanelPropertyExclusivityExpired from './components/PanelPropertyExclusivityExpired';
import PanelPropertyBooked from './components/PanelPropertyBooked';
import PanelPropertyDeal from './components/PanelPropertyDeal';
import { currentUserSelector } from 'modules/login';
import PropertiesWithClients from 'containers/PropertyDashboard/components/PropertiesWithClients';
import fichaCadastro from 'pages/Properties/ficha-cadastro.docx';
import Help from './components/Help';

// Containers
class PeopleDashboard extends React.Component {
  static propTypes = {};
  static defaultProps = {
    limitPropertyRevision: 10,
    limitPropertyRentExpired: 10,
    limitPropertyExclusivityExpired: 10,
    limitPropertyDeals: 10,
    limitPropertyRecent: 30,
  };

  state = {
    currentFunnel: {
      id: null,
      name: null,
    },
    updatedTime: new Date().getTime(),
  };

  /**
   * Busca por um funil
   * @param id
   */
  fetchDeals = (funnel) => {
    if (funnel) {
      // busca os imoveis no funil
      this.props.fetchPropertyFunnels(funnel.id);

      // Marca o primeiro funil como padrao
      this.setState({
        currentFunnel: funnel,
      });
    }
  };

  openModalProperty = (property) => () => {
    this.props.openModalProperty({
      property,
      afterClose: this.refreshDashboard,
    });
  };

  refreshDashboard = () => {
    const {
      limitPropertyRevision,
      limitPropertyRentExpired,
      limitPropertyExclusivityExpired,
      limitPropertyDeals,
      limitPropertyRecent,
    } = this.props;

    this.updateTime();

    this.props.fetchPropertyRevision({ limit: limitPropertyRevision });
    this.props.fetchPropertyRentExpired({ limit: limitPropertyRentExpired });
    this.props.fetchPropertyExclusivityExpired({
      limit: limitPropertyExclusivityExpired,
    });
    this.props.fetchPropertyDeals({ limit: limitPropertyDeals });
    this.props.fetchPropertyRecent({ limit: limitPropertyRecent });
  };

  // Seleciona o funil
  selectFunnel = (funnel) => () => {
    this.fetchDeals(funnel);
  };

  updateTime = () => {
    this.setState({ updatedTime: new Date().getTime() });
  };

  afterClose = () => {
    this.updateTime();
  };

  render() {
    const { updatedTime } = this.state;
    const { currentUser } = this.props;

    return (
      <Wrapper.inner>
        <Wrapper.container>
          <Help />
          <MainTitle
            title="Meus Imóveis"
            text="Cadastre, edite, gerencie tudo que precisar para dar ao seu cliente as melhores informações possíveis!"
          >
            <Button to={fichaCadastro} target="_blank">
              Imprimir Ficha de cadastro
            </Button>
            <Button to="/reports/property/per-type-transaction">
              <MdTrendingUp /> Ver estoque
            </Button>
          </MainTitle>
          <Row>
            <Col xs={6}>
              <PropertiesWithClients />
              <PanelFinancialIndex />
              <PanelPropertyPortals />
              <PanelPropertyRevision
                currentUser={currentUser}
                updatedTime={updatedTime}
                updateTime={this.updateTime}
              />
              <PanelPropertyBooked
                updatedTime={updatedTime}
                updateTime={this.updateTime}
              />
              <PanelPropertyRentExpired
                updatedTime={updatedTime}
                updateTime={this.updateTime}
              />
              <PanelPropertyExclusivityExpired
                updatedTime={updatedTime}
                updateTime={this.updateTime}
              />
            </Col>
            <Col xs={6}>
              <PanelPropertyRecent
                updatedTime={updatedTime}
                updateTime={this.updateTime}
              />
              <PanelPropertyDeal
                updatedTime={updatedTime}
                updateTime={this.updateTime}
              />
            </Col>
          </Row>
        </Wrapper.container>
      </Wrapper.inner>
    );
  }
}

const mapStateToProps = (state) => ({
  funnels: funnelsSelector(state),
  currentUser: currentUserSelector(state),
});

const mapDispatchToProps = {
  openModalProperty,
  fetchFunnels,
  fetchPropertyFunnels,
  fetchPropertyRevision,
  fetchPropertyRentExpired,
  fetchPropertyExclusivityExpired,
  fetchPropertyDeals,
  fetchPropertyRecent,
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleDashboard);
