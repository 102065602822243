import { Wrapper } from './styles';

function UserDataCell({ invite }) {
  return (
    <Wrapper>
      <div>
        <p>{invite.name}</p>
      </div>
      <div>
        <p>{invite.cellphone}</p>
      </div>
    </Wrapper>
  );
}

export default UserDataCell;
