import styled from 'styled-components';

export const WrapField = styled.div`
  display: flex;
  width: 300px;
  margin-left: 10px;

  .FormGroup {
    width: 105px;
  }

  .Labels {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    height: 38px;
  }
`;

export const Label = styled.span`
  display: inline-block;
  border: 1px solid ${(p) => p.theme.borderColor};
  border-radius: 25px;
  color: ${(p) => p.theme.colors.secondary};
  padding: 2px 4px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;

  & + & {
    margin-left: 5px;
  }
`;
