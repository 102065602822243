import api from 'services';
import { responseMessage } from 'lib/service-helpers';

export const getOne = () => api.getOne('settings/sites/posts-area');
export const update = values =>
  api
    .update('settings/sites/posts-area', values)
    .then(responseMessage('Salvo!'));

export const components = () =>
  api.getOne('settings/component-examples/card-posts');
