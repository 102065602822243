import Socket from 'lib/Socket';

export default class Orulo extends Socket {
  constructor(accessToken) {
    super(accessToken);

    // Conecta no canal de notificações
    this.connect('events');
  }

  onImportFinished(callBack) {
    this.on('oruloImportFinished', callBack);
  }

  onBuildingImported(callBack) {
    this.on('oruloBuildingImported', callBack);
  }
}
