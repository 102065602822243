import { HTTP, STORAGE } from '../lib/HTTP';
import { getFormData } from '../lib/formHelpers';
import objectToFormdata from 'object-to-formdata';

export const STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  INVALID_PARAMS: 400,
  NOT_AUTHORIZED: 401,
  ACCESS_DENIED: 403,
  NOT_SUPPORTED_METHOD: 405,
  INFORMATION_NOT_FOUND: 422,
  SERVER_ERROR: 500,
};

const defaultParams = {
  sort: '-updated_at',
};

export const apiStorage = {
  delete: (resource, id = '', config = null) =>
    STORAGE.delete(`${resource}/${id}`, config),
  uploadFile: (resource, params) => {
    const fd = getFormData(params);

    return STORAGE.post(resource, fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  patch: (resource, data, config = null) =>
    STORAGE.patch(`${resource}`, data, config),
  put: (resource, data, config = null) =>
    STORAGE.put(`${resource}`, data, config),
  updateWithFile: (resource, data, config = null) => {
    const fd = objectToFormdata(data, { indices: true });

    return STORAGE.post(`${resource}/${data.id || ''}`, fd, {
      ...config,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};

const api = {
  get: (resource, params, config) => HTTP.get(resource, { params, ...config }),
  getOne: (resource, id = '', params = null) =>
    HTTP.get(`${resource}/${id}`, { params }),
  getList: (resource, params, config) => {
    return HTTP.get(resource, {
      params: { ...defaultParams, ...params },
      ...config,
    });
  },
  getSearch: (resource, params) => {
    if (params !== undefined) {
      return HTTP.post(`${resource}/search`, {
        ...defaultParams,
        limit: 5,
        offset: 1,
        ...params,
      });
    }
    return HTTP.get(resource);
  },
  getSearch2: (resource, params) =>
    HTTP.get(resource, {
      params: {
        sort: ['-updated_at'].join(','),
        offset: 1,
        ...params,
      },
    }),
  create: (resource, data, config = null) => HTTP.post(resource, data, config),
  patch: (resource, data, config = null) =>
    HTTP.patch(`${resource}`, data, config),
  update: (resource, data, config = null) =>
    HTTP.put(`${resource}/${data?.id || ''}`, data, config),
  updateWithFile: (resource, data, config = null) => {
    const fd = objectToFormdata(data, { indices: true });

    return HTTP.post(`${resource}/${data.id || ''}`, fd, {
      ...config,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  delete: (resource, id = '', config = null) =>
    HTTP.delete(`${resource}/${id}`, config),
  reactivate: (resource, id, config = { headers: {} }) =>
    HTTP.post(`${resource}/${id}`, config),
  uploadFile: (resource, params) => {
    const fd = getFormData(params);

    return HTTP.post(resource, fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};

export default api;
