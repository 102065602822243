import React, { useCallback, useState, useContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import { MdUndo, MdRedo, MdZoomIn, MdZoomOut } from 'react-icons/md';
import { ModalClose } from 'components/Modal';
import Cropper from 'react-cropper';
import classnames from 'classnames';
import {
  Wrapper,
  Content,
  Footer,
  Actions,
  ActionsRight,
  Dropzone,
  DropContent,
  Btn,
  ButtonLink,
} from './styles';
import imageDrop from './imageDrop.svg';
import Button from 'components/Button';
import { UploadImageContext } from 'components/ModalImage/context';
import Alert from 'components/Alert';
import { blobToFile } from 'components/ModalImage/helpers';

const ModalImage = () => {
  const { isOpen, close, options, imagePreview, setImagePreview } =
    useContext(UploadImageContext);

  const [isReady, setIsReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cropper, setCropper] = useState();

  const handleSetImage = useCallback(
    (img) => {
      console.dir(img);
      setImagePreview(img.preview);
    },
    [setImagePreview]
  );

  const handleOnDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      handleSetImage(acceptedFiles[0]);
    },
    [handleSetImage]
  );

  const rotateImage = useCallback(
    (position) => () => {
      if (position === 'left') {
        cropper.rotate(45);
      } else {
        cropper.rotate(-45);
      }
    },
    [cropper]
  );

  const zoomIn = useCallback(() => {
    cropper.zoom(0.1);
  }, [cropper]);

  const zoomOut = useCallback(() => {
    cropper.zoom(-0.1);
  }, [cropper]);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);

    try {
      const image = cropper?.getCroppedCanvas({
        maxWidth: 1920,
        maxHeight: 1080,
        imageSmoothingEnabled: false,
      });

      image.toBlob(async (blob) => {
        // Cria um arquivo
        const file = blobToFile(blob, 'filename');

        // Verifica se tem evento de submit nas opções
        if (options?.onSubmit)
          await options?.onSubmit({
            imagePreview,
            file,
            dataUrl: image.toDataURL(),
          });

        // Fecha a modal caso de certo o submit
        close();

        // Se não só retorna o preview da imagem e o arquivo Blob
        return { imagePreview, file, dataUrl: image.toDataURL() };
      });
    } catch (err) {
      Alert.success('Não conseguimos cortar essa imagem');
    } finally {
      setIsSubmitting(false);
    }
  }, [imagePreview, cropper, options]);

  const reset = useCallback(() => {
    setImagePreview(null);
    setCropper(null);
  }, []);

  const clearImage = useCallback(() => {
    setImagePreview(null);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsReady(true);
      }, 200);
    } else {
      setIsReady(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isReady) {
      reset();
    }
  }, [isReady]);

  return (
    <ReactModal
      isOpen={isOpen}
      className={{
        base: classnames('Modal Modal--image'),
        afterOpen: 'Modal--after-open',
        beforeClose: 'Modal--before-close',
      }}
      overlayClassName={{
        base: `ModalOverlay ModalOverlay--image ModalOverlay--modal-center`,
        afterOpen: 'ModalOverlay--after-open',
        beforeClose: 'ModalOverlay--before-close',
      }}
    >
      <Wrapper>
        <ModalClose handleClose={close} />
        <Content>
          {imagePreview && isReady ? (
            <Cropper
              checkCrossOrigin={false}
              src={imagePreview}
              style={{ height: 400, width: '100%' }}
              // Cropper.js options
              initialAspectRatio={1}
              guides={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              {...options.cropperOptions}
            />
          ) : (
            <Dropzone onDrop={handleOnDrop} className="Drop">
              <DropContent>
                <img src={imageDrop} alt="" />
                <p>Arraste uma imagem ou selecione uma</p>
                <Button>Selecionar imagem</Button>
              </DropContent>
            </Dropzone>
          )}
        </Content>
        <Footer>
          <Actions disabled={!imagePreview}>
            <Btn onClick={rotateImage('left')}>
              <div>
                <MdUndo />
                <span>Girar Esquerda</span>
              </div>
            </Btn>
            <Btn onClick={rotateImage('right')}>
              <div>
                <MdRedo />
                <span>Girar Direita</span>
              </div>
            </Btn>
            <Btn onClick={zoomIn}>
              <div>
                <MdZoomIn />
                <span>Zoom +</span>
              </div>
            </Btn>
            <Btn onClick={zoomOut}>
              <div>
                <MdZoomOut />
                <span>Zoom -</span>
              </div>
            </Btn>
          </Actions>
          <ActionsRight>
            {imagePreview && (
              <ButtonLink onClick={clearImage}>Trocar Imagem</ButtonLink>
            )}
            <Button
              onClick={close}
              type="button"
              color="white"
              colorText="secondary"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              type="button"
              color="success"
              disabled={!imagePreview || isSubmitting}
            >
              Salvar
            </Button>
          </ActionsRight>
        </Footer>
      </Wrapper>
    </ReactModal>
  );
};

export default ModalImage;
