import React from 'react';
// Component
import Check from 'components/Check';
import PropertyCell from 'pages/Reports/components/PropertyCell';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import {
  Cell,
  CellCheck,
  CellNumber,
  RowDivider,
  RowPrimary,
  RowSecondary,
  Table,
  Wrapper,
} from './styles';

const selector = formValueSelector('ChartPeriodForm');

const PropertyTable = ({
  data,
  meta,
  isAllSelected,
  toggleAll,
  toggleChecked,
  colors,
}) => {
  const user_id = useSelector((state) => selector(state, 'user_id'));

  return (
    <Wrapper>
      <Table>
        <thead>
          <tr>
            <th>
              <Check checked={isAllSelected} onClick={toggleAll} />
            </th>
            <th>Tipo de Imóveis</th>
            {meta?.periods.map((period) => (
              <CellNumber key={`period-th-${period}`} as="th">
                {period}
              </CellNumber>
            ))}
            <CellNumber as="th" stickyRight>
              Total
            </CellNumber>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const key = `${item.type}-${index}`;
            const canShowDivider = index !== 0;
            const length = item.periods.length;

            return (
              <>
                {!!canShowDivider && (
                  <RowDivider>
                    <td colSpan={length + 3} />
                  </RowDivider>
                )}
                <RowPrimary key={key}>
                  <CellCheck valign="top">
                    <Check
                      key={`${key}-${
                        item.isSelected ? 'selected' : 'unselected'
                      }`}
                      checked={item.isSelected}
                      onClick={toggleChecked(item)}
                    />
                  </CellCheck>
                  <Cell>{item.type}</Cell>
                  {item.periods.map(({ period, value }, i) => {
                    const [month, year] = period.split('/');

                    return (
                      <CellNumber key={`${key}-period-${i}`}>
                        <PropertyCell
                          filter={{
                            by_type_or_subtype_id: item.type_id,
                            month: `created_at,${month}`,
                            year: `created_at,${year}`,
                            with_all_status: true,
                            without_draft_scope: true,
                            user_id: [user_id],
                          }}
                        >
                          {value}
                        </PropertyCell>
                      </CellNumber>
                    );
                  })}
                  <CellNumber stickyRight>{item.total}</CellNumber>
                </RowPrimary>
                {item.transactions &&
                  item.transactions.map(
                    ({ type, total, periods, transactions }, index) => (
                      <RowSecondary key={`${key}-secondary-${index}`}>
                        <CellCheck />
                        <Cell>{type}</Cell>
                        {periods.map(({ value, period }, i) => {
                          const [month, year] = period.split('/');

                          let filter = {
                            by_type_or_subtype_id: item.type_id,
                            transaction: type === 'Venda' ? 1 : 2,
                            month: `created_at,${month}`,
                            year: `created_at,${year}`,
                            with_all_status: true,
                            without_draft_scope: true,
                            user_id: [user_id],
                          };

                          // Remove transação do filtro caso o cadastro do imóvel esteja incompleto
                          if (type === 'Indefinido') {
                            filter.transaction = 0;
                          }

                          return (
                            <CellNumber key={`${key}-secondary-period-${i}`}>
                              <PropertyCell filter={filter}>
                                {value}
                              </PropertyCell>
                            </CellNumber>
                          );
                        })}
                        <CellNumber stickyRight>{total}</CellNumber>
                      </RowSecondary>
                    )
                  )}
              </>
            );
          })}
        </tbody>
        <tr height={15}>
          <td />
        </tr>
        <tfoot>
          <tr>
            <Cell colSpan={2}>TOTAL</Cell>
            {meta?.totals.map(({ period, value }) => {
              return (
                <CellNumber key={`total-period-${period}`}>{value}</CellNumber>
              );
            })}
            <CellNumber stickyRight>{meta?.total}</CellNumber>
          </tr>
        </tfoot>
      </Table>
    </Wrapper>
  );
};

export default React.memo(PropertyTable);
