import React, { useEffect } from 'react';
import Alert from 'react-s-alert';
import Wrapper from 'components/Wrapper';
import Loading from 'components/Loading';
import MainTitle from 'components/MainTitle';
// Services
import * as crmService from 'services/settings/crm-integration';
// Modules
import { addAuth, TYPES_AUTH } from 'modules/authentications.js';
// Hooks
import useSearch from 'hooks/useSearch';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { openModalRdConfig } from 'modules/modal';

function RdStation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useSearch(false);

  const refreshCode = async () => {
    try {
      await crmService.refreshCode(params.code);

      dispatch(addAuth(TYPES_AUTH.RD_STATION));
      history.push('config/integrations');
      dispatch(openModalRdConfig());
    } catch (e) {
      Alert.success('Ocorreu um problema com a integração');
      throw e;
    }
  };

  useEffect(() => {
    if (params.code) {
      refreshCode();
    }
  }, []);

  return (
    <Wrapper.container>
      <MainTitle title="Integração com RD Station" />
      <Loading isVisible />
    </Wrapper.container>
  );
}

export default RdStation;
