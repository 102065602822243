import React, { useCallback, useEffect } from 'react';
import Layout from 'pages/Crm/components/Layout';
import Table from './components/Table';
import { Content } from './styles';
import { useRequest } from 'hooks/useRequest';
import * as propertiesService from 'services/properties';
import { useFormUserId } from 'pages/Crm/components/FormUser';
import Loading from 'components/Loading';
import { plural } from 'lib/text';
import { useDispatch, useSelector } from 'react-redux';
import { countsSelector } from 'pages/Crm/modules/asideCrm';
import Button from 'components/Button';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import * as propertiesMatchedService from 'services/properties-matched';

function MatchedProperties() {
  const dispatch = useDispatch();

  const { data: counts } = useSelector(countsSelector);
  const userId = useFormUserId();
  const { data, meta, fetchData, isFetching } = useRequest({
    request: ({ user_id, ...params }) =>
      propertiesService.propertiesMatched({
        ...params,
        user_id: user_id,
      }),
    initialFetch: false,
  });

  const handleDiscartAll = useCallback(() => {
    dispatch(
      openConfirmation({
        size: '470px',
        title: 'Descartar todos os imóveis',
        request: () => {
          return propertiesMatchedService.removeAllMatcheds({
            user_id: userId,
          });
        },
        onSuccess: () => {
          fetchData({ user_id: userId });
        },
        text: (
          <>
            Tem certeza que deseja descartar todos imóveis com clientes
            compatíveis? Isso irá limpar a tabela de clientes e imóveis
            compatíveis
          </>
        ),
      })
    );
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchData({ offset: 1, user_id: userId, sort: 'by_name' });
    }
  }, [userId]);

  return (
    <Layout
      title={
        <>
          <strong>Radar de oportunidades</strong> |{' '}
          <strong>Imóveis compatíveis</strong>
          {counts.matchedPropertiesCount > 0 && (
            <>
              {' | '}
              {plural(
                counts.matchedPropertiesCount,
                'imóvel',
                'imóveis'
              )} | {plural(counts?.matchedCount, 'pessoa', 'pessoas')}
            </>
          )}
        </>
      }
      renderActions={() =>
        data.length > 0 ? (
          <Button onClick={handleDiscartAll} size="medium" color="danger">
            Descartar todos
          </Button>
        ) : null
      }
    >
      <Content>
        <Table
          userId={userId}
          data={data}
          meta={meta}
          fetchData={(params) => fetchData({ user_id: userId, ...params })}
        />
      </Content>
      <Loading isVisible={isFetching} isFullScreen />
    </Layout>
  );
}

export default MatchedProperties;
