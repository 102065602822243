import Button from 'components/Button';
import CreditCardFlag from 'components/CreditCardFlag';
import Icon from 'components/Icon';
import { useRequest } from 'hooks/useRequest';
import { openModalCreditCard } from 'modules/modal';
import { deleteCreditCard, fetchCards, getCards } from 'modules/plan';
import FormPayment from 'pages/PlanHistory/components/FormPayment';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services';
import * as creditCardsService from 'services/financial/creditCards';
import * as realEstateService from 'services/realEstate';
import BoxPlan from 'pages/PlanHistory/components/BoxPlan';
import { Delete, NoCard } from './styles';

function CreditCardContainer({ onAddCardSuccess, onAddCardFail }) {
  const {
    data: realEstate,
    fetchData,
    setData,
  } = useRequest({
    request: realEstateService.getRealEstate,
    params: {
      include: 'financial_info',
    },
    initialFetch: false,
  });

  const handleSubmit = useCallback((values) => {
    return api.create('financial/recurring-charge', {
      active: values.recurring_charge,
    });
  }, []);

  const handleSubmitSuccess = useCallback(
    ({ data }) => {
      setData({ ...realEstate, ...data });
    },
    [realEstate]
  );

  const dispatch = useDispatch();
  const { data: creditCards } = useSelector(getCards);

  const hasCreditCard = creditCards?.length > 0;

  useEffect(() => {
    fetchData();
  }, [creditCards]);

  const handleRemoveCard = useCallback(
    (id) => {
      dispatch(deleteCreditCard(id));
    },
    [dispatch]
  );

  const handleClickAddCard = useCallback(() => {
    // Abre a modal de cartão de crédito
    dispatch(
      openModalCreditCard({
        onSubmitFail: () => {
          if (onAddCardFail) onAddCardFail();
        },
        onSubmitSuccess: () => {
          if (onAddCardSuccess) onAddCardSuccess();
          dispatch(fetchCards());
        },
      })
    );
  }, [dispatch, onAddCardFail, onAddCardSuccess]);

  const handleDefinePrimary = async (card) => {
    await creditCardsService.defineAsPrimary(card.id).then(() => {
      dispatch(fetchCards());
    });
  };

  useEffect(() => {
    // Busca s cartões de crédito
    dispatch(fetchCards());
  }, [dispatch]);

  return (
    <div className="h-margin-top--15">
      <h5 className="h-margin-bottom--10">Cartão de crédito</h5>
      {hasCreditCard ? (
        <BoxPlan.cards>
          {creditCards.map((creditCard) => (
            <BoxPlan.card>
              {/* <MdCreditCard size={20} /> */}
              <CreditCardFlag className="icon-brand" name={creditCard.brand} />
              <span>Final *{creditCard.number}</span>
              {creditCard.is_primary ? (
                <div className="primary-card">
                  <Icon name="check-circle" className="h-color--success" />
                  <span>Padrão</span>
                </div>
              ) : (
                <button
                  type="button"
                  className="h-link define-as-primary"
                  onClick={() => handleDefinePrimary(creditCard)}
                >
                  Definir padrão
                </button>
              )}
              <Delete
                onClick={() => handleRemoveCard(creditCard.id)}
                data-tip="Deletar"
              >
                <MdDeleteOutline size={20} />
              </Delete>
            </BoxPlan.card>
          ))}
          <Button
            color="tertiary"
            size="small"
            onClick={handleClickAddCard}
            block
          >
            Adicionar cartão
          </Button>
        </BoxPlan.cards>
      ) : (
        <BoxPlan.cards position="center">
          <NoCard>
            Você ainda não
            <br />
            adicionou cartão.
            <div>
              <Button
                size="small"
                className="h-margin-top--10"
                onClick={handleClickAddCard}
              >
                Adicionar cartão
              </Button>
            </div>
          </NoCard>
        </BoxPlan.cards>
      )}
      {hasCreditCard && (
        <FormPayment
          realEstate={realEstate}
          initialValues={{
            recurring_charge: realEstate.recurring_charge,
          }}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
        />
      )}
    </div>
  );
}

CreditCardContainer.propTypes = {
  onAddCardSuccess: PropTypes.func,
  onAddCardFail: PropTypes.func,
};
CreditCardContainer.defaultProps = {
  onAddCard: null,
  onAddCardFail: null,
};

export default CreditCardContainer;
