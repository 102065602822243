import React from 'react';
import { MdOutlineMoodBad } from 'react-icons/md';
import { colors } from 'constants/styles';
import { ButtonCustom } from '../../styles';

// import { Wrapper } from './styles';

function BtnLoss({ onClick, block = true, ...props }) {
  return (
    <ButtonCustom
      onClick={onClick}
      block={block}
      size="medium"
      color="white"
      {...props}
    >
      <MdOutlineMoodBad color={colors.danger} /> Perdido
    </ButtonCustom>
  );
}

export default BtnLoss;
