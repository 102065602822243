import React from 'react';
// import PropTypes from 'prop-types';

const propTypes = {};

const defaultProps = {};

const IndexHistoryItem = ({
  created_at,
  id,
  name,
  renew_date,
  user_id,
  user_name,
  value
}) => (
  <tr>
    <td>{created_at}</td>
    <td>{user_name}</td>
    <td>{value}</td>
  </tr>
);

IndexHistoryItem.propTypes = propTypes;
IndexHistoryItem.defaultProps = defaultProps;

export default IndexHistoryItem;
