import React from 'react';
import PropTypes from 'prop-types';
// Components
import CardItem from 'components/CardItem';
// Helpers
import { getName } from 'lib/text';

function CardUser({ user, onClick }) {
  return (
    <CardItem imageUrl={user.file_url} onClick={onClick}>
      {getName(user.name)}
    </CardItem>
  );
}

CardUser.defaultProps = {
  user: {
    id: null
  },
  onClick: null
};

CardUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func
};

export default CardUser;
