import React, { memo } from 'react';
import PropTypes from 'prop-types';

import PillItem from './components/PillItem';
import { FormGroupCustom, Wrapper } from './styles';
import { ThemeProvider } from 'styled-components';

const defaultProps = {
  type: 'ball',
  labelKey: 'label',
  valueKey: 'value',
  options: [
    { label: '+1', value: 1 },
    { label: '+2', value: 2 },
    { label: '+3', value: 3 },
    { label: '+4', value: 4 },
    { label: '+5', value: 5 },
  ],
};

const propTypes = {
  type: PropTypes.oneOf(['ball', 'square']),
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

class Pill extends React.Component {
  constructor() {
    super();

    this.handleClickItem = this.handleClickItem.bind(this);
  }

  /**
   * Lida com o evento de click do item
   * @param value
   * @return {function()}
   */
  handleClickItem(value) {
    const { input } = this.props;
    return () => {
      if (value === input.value) return input.onChange(null);
      return input.onChange(value);
    };
  }

  /**
   * Retorna o valor do input se esta checado ou não
   * @param option
   * @return {boolean}
   */
  itemIsChecked(option) {
    const { input, valueKey } = this.props;
    return input.value == option[valueKey];
  }

  /**
   * Renderiza os items na lista
   * @return {*}
   */
  renderItems() {
    const { input, options, labelKey, valueKey } = this.props;

    return (
      options &&
      options.map((option) => (
        <PillItem
          key={`${input.name}-${option[valueKey]}`}
          isChecked={this.itemIsChecked(option)}
          onClick={this.handleClickItem(option[valueKey])}
        >
          {option[labelKey]}
        </PillItem>
      ))
    );
  }

  render() {
    const { type, required, formGroupClassName, label, disabled } = this.props;

    return (
      <ThemeProvider
        theme={{
          type,
          disabled,
        }}
      >
        <FormGroupCustom
          className={formGroupClassName}
          labelText={label}
          isRequired={required}
          isDisabled={disabled}
        >
          <Wrapper className="Pill">{this.renderItems()}</Wrapper>
        </FormGroupCustom>
      </ThemeProvider>
    );
  }
}

Pill.defaultProps = defaultProps;
Pill.propTypes = propTypes;

export default memo(Pill);
