import React, { memo } from 'react';
import defaultFilterOptions from 'react-select-plus/lib/utils/defaultFilterOptions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector, getFormInitialValues } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
// Components
import FormBox from 'components/FormBox';
import { FieldBool, Input, Select, SelectMultiple } from 'components/Form';
// Containers
// Modules
import { getPeople, peopleSelector } from 'modules/people';
import { openModalPersonAdd } from 'modules/modal';
import { getCharacteristics } from 'modules/characteristics';
import { typesSelector } from 'modules/propertyAdd';
import { realtorsSelector } from 'modules/realtors';
import Can, { myself } from 'containers/Can';
// Helpers
import { normalizeNumbers } from 'lib/formHelpers';
// Constants
import * as options from 'constants/options';
import FieldSituation from 'containers/FieldSituation';
import { canShowDeadline } from 'lib/property-helpers';
import { normalizeSearch } from 'lib/text';

const defaultProps = {
  fields: []
};
const propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object)
};

const cols = {
  xs: 12,
  sm: 6,
  md: 3
};

const getInputTypeByName = name => {
  switch (name) {
    case 'floor':
      return 'number';
    default:
      return 'text';
  }
};

const renderFields = ({ name, title, size }) => (
  <Field
    {...cols}
    type={getInputTypeByName(name)}
    key={name}
    lg={size}
    label={title}
    name={`informations.${name}.value`}
    component={Input}
  />
);

class Info extends React.Component {
  getCharacteristics = (type_id, canChange = true) => {
    this.props.getCharacteristics(type_id).then(characteristics => {
      // marca todas as características isChecked: false
      const newCharacteristics = characteristics.reduce(
        (obj, { id }) => ({
          ...obj,
          [`${id}`]: {
            id,
            isChecked: false,
            quantity: ''
          }
        }),
        {}
      );

      if (canChange) {
        // seta as novas características
        this.props.changeField('characteristics', newCharacteristics);
      }
    });
  };

  /**
   * Lida com a mudanca de subtipo
   * @param value
   */
  handleChangeSubtypeId = value => {
    const { initialValues, changeField } = this.props;

    if (value?.group) {
      // area_fields - todas as medidas
      const { type_id, area_fields } = value.group;

      if (initialValues?.type?.id === value?.group?.type_id) {
        changeField('characteristics', initialValues.characteristics);
        changeField('establishments', initialValues.establishments);
        changeField('informations', initialValues.informations);
        changeField('rooms', initialValues.rooms);
        changeField('areas', initialValues.areas);
        changeField('situation_id', initialValues.situation_id);

        // pega todas as características
        this.getCharacteristics(type_id, false);
      } else {
        // pega todas as características
        this.getCharacteristics(type_id);

        // limpa as características selecionadas
        changeField('characteristics', {});
        changeField('establishments', {});
        changeField('informations', {});
        changeField('rooms', {});
        changeField('areas', {});
        changeField('situation_id', null);
      }

      // verifica se tem as medidas
      if (area_fields) {
        area_fields
          .reduce((arr, field) => arr.concat(field), []) // 'junta' todos os campos
          .map(field => {
            // marca as tipos de medidas default para cada medida
            changeField(`areas.${field.name}.measure`, field.measures[0]);

            return field;
          });
      }
    }
  };

  handleChangeResume = () => {
    this.props.changeField('resume_days', null);
  };

  /**
   * Função que retorna se pode ou não mostrar o campo de prazo de entrega
   * @return {Boolean}
   */
  canShowDeadline = () => canShowDeadline(this.props.situation);

  /**
   * Renderiza os itens domoda
   * @return {boolean}
   */
  get renderFields() {
    const { fields } = this.props;

    // verifica se tem os campos de informacao
    const hasFields = fields.length > 0;

    return (
      hasFields &&
      fields.filter(({ name }) => name !== 'apartment_number').map(renderFields)
    );
  }

  handleInsertPerson = ({ name }) => {
    this.props.openModalPersonAdd({ name }, null, {
      checkGroupOwner: true
    });
  };

  render() {
    const {
      types,
      realtors,
      typeId,
      updateTitle,
      updateMetaTitle
    } = this.props;

    return (
      <FormBox className="js-information" title="Informações">
        <Row>
          <Field
            {...cols}
            label="Código de referência"
            name="reference"
            component={Input}
            maxLength={19}
          />

          <Can run={myself.properties.associateToOther}>
            {condition => (
              <Field
                {...cols}
                required
                label="Corretor/Agenciador"
                name="user_id"
                component={Select}
                options={realtors}
                valueKey="id"
                labelKey="name"
                disabled={!condition}
              />
            )}
          </Can>

          <Field
            {...cols}
            required
            multi={false}
            label="Tipo/Subtipo"
            name="subtype_id"
            component={SelectMultiple}
            onChange={e => {
              this.handleChangeSubtypeId(e);
            }}
            onBlur={() => {
              updateMetaTitle();
              updateTitle();
            }}
            options={types}
            valueRenderer={({ label, type_name, group }) => {
              return `${group?.title || type_name} - ${label}`;
            }}
            filterOptions={(items, search, excludeOptions, props) => {
              const formattedSearch = normalizeSearch(search);

              // Procura pelos tipos
              const types = items?.filter(({ type_name }) => {
                const formattedTypename = normalizeSearch(type_name);
                return formattedTypename?.includes(formattedSearch);
              });

              if (types?.length > 0) {
                return types;
              }

              return defaultFilterOptions(items, search, excludeOptions, props);
            }}
          />
          <Field
            {...cols}
            required
            label="Perfil do imóvel"
            name="profile"
            options={options.PROFILE}
            component={Select}
          />
          <FieldSituation {...cols} typeId={typeId} />
          {this.canShowDeadline() && (
            <Field
              {...cols}
              label="Previsão de Entrega"
              name="delivery_forecast"
              component={Input}
            />
          )}

          <Col {...cols}>
            <Field
              label="Ano da construção"
              type="number"
              name="lifetime"
              component={Input}
              normalize={normalizeNumbers}
              placeholder="Ex.: 2015"
            />
          </Col>

          <Field
            {...cols}
            label="Incorporação"
            placeholder="Digite o número"
            name="incorporation"
            component={Input}
          />
          <Field
            {...cols}
            label="Posição solar"
            name="solar_position"
            component={Select}
            // options={options.SOLAR_POSITION}
          />
          <Field
            {...cols}
            label="Terreno"
            name="land_type"
            component={Select}
            options={options.LAND_TYPE}
            buttonTemplate
          />
          <Field
            {...cols}
            xs={2}
            label="Próximo ao mar?"
            name="near_sea"
            component={Select}
            options={options.NEAR_SEA}
          />
          {this.renderFields}
          <FieldBool xs={3} label="Averbado" name="is_property_titled" />
          <FieldBool xs={3} label="Escriturado" name="is_deeded" />
          <FieldBool xs={3} label="Esquina" name="is_corner" />
          <FieldBool
            canRemoveSelf
            xs={3}
            label="Tem mobília"
            name="has_furniture"
          />
        </Row>
      </FormBox>
    );
  }
}

Info.defaultProps = defaultProps;
Info.propTypes = propTypes;

const selector = formValueSelector('propertiesAdd');

const mapStateToProps = state => ({
  situation: selector(state, 'situation_id'),
  initialValues: getFormInitialValues('propertiesAdd')(state),
  types: typesSelector(state),
  people: peopleSelector(state),
  realtors: realtorsSelector(state)
});

const mapDispatchToProps = {
  getPeople,
  getCharacteristics,
  openModalPersonAdd
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Info));
