import React from 'react';
import Modal from 'react-modal';

// Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
import { useRequest } from '../../../hooks/useRequest';
import * as receptionSourcesService from 'services/receptionSources';

function ModalGroupRemove({
  receptionSource,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onCancel,
  onSubmitSuccess,
}) {
  const { data: receptionSourceData, isFetching } = useRequest({
    request: () => {
      return receptionSourcesService.getOne(receptionSource?.id, {
        count: 'people',
      });
    },
  });

  if (isFetching) return null;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Excluir origem da captação"
        handleClose={handleClose}
      >
        <Form
          data={receptionSourceData}
          initialValues={receptionSourceData}
          handleClose={handleClose}
          onCancel={onCancel}
          onSuccess={onSubmitSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalGroupRemove;
