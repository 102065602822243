import { createContext, useCallback, useState } from 'react';

export const UploadImageContext = createContext({
  isOpen: null,
  open: null,
  close: null,
  options: {
    imageUrl: null,
    onSubmit: null,
    onSuccess: null,
    onCancel: null,
    cropperOptions: {
      cropBoxResizable: true,
      aspectRatio: null,
    },
  },
  setOptions: null,
});

export const UploadImageProvider = ({ children }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState({
    imageUrl: null,
    cropperOptions: {
      aspectRatio: 1,
    },
  });

  const open = useCallback(
    (_options) => {
      if (_options) setOptions(_options);
      setIsOpen(true);
    },
    [setIsOpen]
  );

  const close = useCallback(() => {
    setIsOpen(false);
    setOptions({});
  }, [setIsOpen]);

  return (
    <UploadImageContext.Provider
      value={{
        isOpen,
        open,
        close,
        options,
        setOptions,
        imagePreview,
        setImagePreview,
      }}
    >
      {children}
    </UploadImageContext.Provider>
  );
};
