import { Input } from "components/Form";
import { Field } from "redux-form";

function Title() {
  return (
    <Field
      xs={6}
      label="Nome do condomínio/empreendimento"
      name="title"
      component={Input}
    />
  );
}

export default Title;
