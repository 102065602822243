import React from 'react';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
// Components
import Button from 'components/Button';
import ModalFooter from 'components/Modal/components/ModalFooter';
// Containers
import CountryField from 'containers/LocationFields/CountryField';
import StateField from 'containers/LocationFields/StateField';
import CityField from 'containers/LocationFields/CityField';
import NeighborhoodField from 'containers/LocationFields/NeighborhoodField';
// Validations
import validate from './validate';

const defaultProps = {
  handleClose: null,
  handleSubmit: null
};
const propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const FormLocation = ({
  countryId,
  stateId,
  cityId,
  handleClose,
  handleSubmit,
  submitting,

  handleAddCity,
  handleClickDeleteCity,
  handleClickDeleteNeighborhood,
  handleAddNeighborhood
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <CountryField name="country_id" xs={6} />
      <StateField name="state_id" xs={6} countryId={countryId} />
      <CityField
        creatable
        xs={6}
        name="city_id"
        stateId={stateId}
        onNewOptionClick={handleAddCity(stateId)}
        //optionComponent={Option}
        optionRenderer={option => (
          <div style={{ width: '100%' }} className="h-flex">
            {option.name}
            <span className="h-flex__cell--grow" />
            {option.real_estate_id && (
              <MdClose onMouseDown={handleClickDeleteCity(option, stateId)} />
            )}
          </div>
        )}
      />
      <NeighborhoodField
        disabled={!cityId}
        creatable
        name="neighborhood_id"
        xs={6}
        cityId={cityId}
        onNewOptionClick={handleAddNeighborhood(cityId)}
        optionRenderer={option => (
          <div style={{ width: '100%' }} className="h-flex">
            {option.name}
            <span className="h-flex__cell--grow" />
            {option.real_estate_id && (
              <MdClose
                onMouseDown={handleClickDeleteNeighborhood(option, cityId)}
              />
            )}
          </div>
        )}
      />
    </Row>
    <ModalFooter>
      <Button color="white" colorText="primary" onClick={handleClose}>
        Cancelar
      </Button>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success" disabled={submitting}>
        Salvar
      </Button>
    </ModalFooter>
  </form>
);

FormLocation.defaultProps = defaultProps;
FormLocation.propTypes = propTypes;

export const selector = formValueSelector('FormLocation');

export default compose(
  connect(state => ({
    countryId: selector(state, 'country_id'),
    stateId: selector(state, 'state_id'),
    cityId: selector(state, 'city_id')
  })),
  reduxForm({
    form: 'FormLocation',
    validate,
    enableReinitialize: true
  })
)(FormLocation);
