import PropTypes from 'prop-types';

export const PersonShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cellphone_number: PropTypes.string,
  email: PropTypes.string
});

export const PropertyShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: { title: PropTypes.string.isRequired },
  calculated_price: PropTypes.string.isRequired
});

export const StageShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
});

export const DealShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  person: PersonShape,
  property: PropertyShape,
  stage: StageShape,
  interactions: PropTypes.any.isRequired,
  rating: PropTypes.any.isRequired,
  calendars_count: PropTypes.any
});
