import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Input } from 'components/Form';
import Button from 'components/Button';
import { minMax } from 'lib/formHelpers';
import { createProgress, updateProgress } from 'services/buildings/progress';
import GroupControl, { GroupItem } from 'components/GroupControl';

const Form = ({ initialValues, handleSubmit }) => {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        return handleSubmit(e);
      }}
    >
      <Field label="Nome" name="name" component={Input} />
      <GroupControl label="Porcentagem" required={false}>
        <GroupItem>
          <Field
            type="number"
            name="percent"
            component={Input}
            normalize={minMax(0, 100)}
          />
        </GroupItem>
        <GroupItem label="%" size="small" />
      </GroupControl>
      <Button type="submit" color="success" block>
        {!!initialValues?.id ? 'Editar' : 'Adicionar'}
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'ProgressForm',
  onSubmit: (values, dispatch, props) => {
    if (props.initialValues?.id) {
      return updateProgress({ buildingId: props?.buildingId, ...values });
    }

    return createProgress({ buildingId: props.buildingId, ...values });
  }
})(Form);
