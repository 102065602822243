import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import Button from '../../../../../components/Button';
import { Input } from '../../../../../components/Form';
import { ModalFooter, ModalTemplate } from '../../../../../components/Modal';
import validate from './validate';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const PropertyDuplicateForm = ({
  property,
  submitting,
  handleSubmit,
  handleClose
}) => {
  let _props = {};

  if (!submitting) {
    _props = {
      handleClose
    };
  }

  return (
    <ModalTemplate
      title={`Duplicar o imóvel: ${property.reference}`}
      {..._props}
    >
      <form onSubmit={handleSubmit}>
        <Row>
          <Field
            xs={12}
            name="reference"
            label="Referência do novo imóvel"
            maxLength={19}
            component={Input}
          />
        </Row>
        <ModalFooter>
          {!submitting && (
            <Button color="white" colorText="primary" onClick={handleClose}>
              Cancelar
            </Button>
          )}
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            {submitting ? 'Duplicando' : 'Duplicar imóvel'}
          </Button>
        </ModalFooter>
      </form>
    </ModalTemplate>
  );
};

PropertyDuplicateForm.defaultProps = defaultProps;
PropertyDuplicateForm.propTypes = propTypes;

export default reduxForm({
  enableReinitialize: true,
  validate
})(PropertyDuplicateForm);
