import { responseMessage } from 'lib/service-helpers';
import api from '../';

export const getConfig = params => api.getList('settings/crm', params);

export const update = ({ id, ...values }) => api.update('settings/crm', values);

export const getRedirectContacts = params => api.getList('users/crm-redirects', {
  ...params,
  include: 'to_user,from_user,created_by'
});

export const createContactRedirect = (values) => {
  return api.create('users/crm-redirects', values).then(responseMessage('Salvo!'));
};

export const updateContactRedirect = (values) => {
  return api.update(`users/crm-redirects`, values).then(responseMessage('Editado!'));
}


