import React from 'react';
import { Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
// Component
import Button from 'components/Button';
import { InputPhone, SelectMultiple, Toggle } from 'components/Form';
// Helpers
import { parseSelect } from 'lib/formHelpers';

const FieldPhones = ({ change, indexOffice, fields, handleMainPhone }) => (
  <>
    {fields.map((phone, index) => {
      const { iso2 } = fields.get(index);

      return (
        <Row key={`${index}`}>
          <Field
            multi={false}
            xs={3}
            label="Operadora"
            name={`${phone}.operadora`}
            component={SelectMultiple}
            placeholder="Operadora"
            options={[
              { label: 'Tim', value: 'Tim' },
              { label: 'Claro', value: 'Claro' },
              { label: 'Vivo', value: 'Vivo' },
              { label: 'Oi', value: 'Oi' },
              { label: 'Porto Conecta', value: 'Porto Conecta' },
              { label: 'Nextel', value: 'Nextel' },
              { label: 'CTBC', value: 'CTBC' },
              { label: 'Algar', value: 'Algar' },
              { label: 'Sercomtel', value: 'Sercomtel' }
            ]}
            parse={parseSelect('value')}
          />
          <Field
            defaultCountry={iso2}
            required
            xs={3}
            label="Telefone"
            name={`${phone}.phone`}
            component={InputPhone}
            onSelectFlag={(status, countryData) => {
              change(`${phone}.ddi`, countryData.dialCode);
              change(`${phone}.iso2`, countryData.iso2);
            }}
          />
          <Field
            style={{ marginLeft: '20px' }}
            label="Principal"
            name={`${phone}.main`}
            component={Toggle}
            onChange={handleMainPhone(indexOffice, index)}
          />
          <Field
            style={{ marginLeft: '20px' }}
            label="WhatsApp"
            name={`${phone}.whatsapp`}
            component={Toggle}
          />
          <Button
            style={{ marginLeft: '30px', marginTop: '24px' }}
            color="danger"
            size="medium"
            onClick={() => fields.remove(index)}
          >
            Remover
          </Button>
        </Row>
      );
    })}
    <Button
      color="primary"
      size="medium"
      onClick={() => {
        fields.push({ ddi: '+55', iso2: 'br', main: false, whatsapp: false });
      }}
    >
      Novo Telefone
    </Button>
  </>
);

export default FieldPhones;
