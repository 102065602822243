import Wrapper from 'components/Wrapper';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { getInvites } from 'modules/invites';
import { getUsers } from 'modules/users';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import InvitedUsers from './components/InvitedUsers';
import RegisteredUsers from './components/RegisteredUsers';

function Users() {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  useEffect(() => {
    dispatch(getUsers());
  }, [currentUser, dispatch]);

  useEffect(() => {
    dispatch(getInvites());
  }, [dispatch]);

  return (
    <Wrapper>
      <RegisteredUsers />
      <InvitedUsers />
    </Wrapper>
  );
}

export default Users;
