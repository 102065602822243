import React, { useEffect } from 'react';
import Box from 'pages/PlanConfig/components/Box';
import { reduxForm } from 'redux-form';
import FieldSelectUser from 'containers/FieldSelectUser';
import styled from 'styled-components';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { updateFinancialUser } from 'services/financialv2/user';
import { getCurrentUser } from 'modules/login';
import { useDispatch } from 'react-redux';

const CustomBox = styled(Box)`
  .FormGroup {
    margin-bottom: 0 !important;
  }
`;

// import { Wrapper } from './styles';

function FinancialOwner({ handleSubmit, initialize }) {
  const dispatch = useDispatch();
  const { real_estate } = useCurrentUser();

  useEffect(() => {
    initialize({ financial_user_id: real_estate?.financial_user_id });
  }, []);

  const handleChangeUser = async (user) => {
    try {
      await updateFinancialUser({ financial_user_id: user.id });
      dispatch(getCurrentUser());
    } catch {
      console.error(
        `Erro ao atualizar o usuário responsável para o id: ${user.id}`
      );
    }
  };

  return (
    <CustomBox title="Responsável financeiro">
      <form onSubmit={handleSubmit}>
        <FieldSelectUser
          clearable={false}
          label=""
          name="financial_user_id"
          placeholder="Nome do corretor/usuário"
          onChange={handleChangeUser}
          params={{
            filter: {},
          }}
        />
      </form>
    </CustomBox>
  );
}

export default reduxForm({
  form: 'FinancialOwner',
})(FinancialOwner);
