import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 240px;
  height: 100%;
  background-color: #fff;
  padding: 30px;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-left: -5px;
`;
