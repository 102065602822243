import CardImage from "components/CardImageNew";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useGallery } from "../../contexts/gallery";
import { WrapItem, Wrapper } from './styles';

const Item = SortableElement(
  ({
    value,
    itemIndex,
    onRemoveImage,
  }) => {
    return (
      <WrapItem>
        <CardImage
          isFeatured={itemIndex === 0}
          image={value}
          onRemoveImage={onRemoveImage}
        />
      </WrapItem>
    );
  }
);

const List = SortableContainer(
  ({
    items,
    onRemoveImage,
  }) => (
    <Wrapper>
      {items?.map((value, index) => (
        <Item
          itemIndex={index}
          key={value?.id}
          index={index}
          value={value}
          onRemoveImage={onRemoveImage}
        />
      ))}
    </Wrapper>
  )
);


const SortableRegionPostGallery = () => {
  const {
    data,
    handleSortEnd,
    removeImage,
  } = useGallery();

  return (
    <List
      axis="xy"
      distance={5}
      useDragHandle
      items={data}
      onSortEnd={handleSortEnd}
      onRemoveImage={removeImage}
    />
  );
}

export default SortableRegionPostGallery;
