import React from 'react';
import { Link, Redirect } from 'react-router-dom';
// Components
import Wrapper from 'components/Wrapper';
import Container from 'components/Container';
import MainTitle from 'components/MainTitle';
import HasIntegration from 'containers/HasIntegration';
import AnalyticsIntegration from 'containers/AnalyticsIntegration';
// Helpers
import { getViewId } from 'lib/analytics';
import AnalyticsLastVisitors from './components/AnalyticsLastVisitors';
import { TYPES_AUTH } from 'modules/authentications';

function DataDashboard() {
  return (
    <Wrapper.container style={{ marginTop: 30 }}>
      <HasIntegration
        scope={TYPES_AUTH.GOOGLE_ANALYTICS}
        yes={() => {
          if (!getViewId()) {
            return <Redirect to="/integrations/analytics" />;
          }

          return (
            <Container padding>
              <MainTitle
                title="Bem vindo(a) de volta"
                text={
                  <>
                    Abaixo você tem a disposição um breve resumo do seu sistema.
                    Para mais relatórios,{' '}
                    <Link to="/reports" href="#" className="h-link">
                      clique aqui.
                    </Link>
                  </>
                }
              />
              <AnalyticsLastVisitors />
            </Container>
          );
        }}
        no={() => <AnalyticsIntegration redirectTo="/integrations/analytics" />}
      />
    </Wrapper.container>
  );
}

export default DataDashboard;
