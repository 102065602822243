import React from 'react';
import MainTitle from 'components/MainTitle';
import ShareDropdown from 'components/ShareDropdown';
import Title from '../Title';
import Text from '../Text';

const style = { margin: '0 0 10px', padding: '20px 15px' };

function ModalHeader({ property, children }) {
  return (
    <MainTitle
      isFetching={!property.title_formatted}
      title={<Title property={property} />}
      text={<Text property={property} />}
      className="Modal__title"
      style={style}
    >
      <ShareDropdown
        property={property}
        propertyId={property.id}
        url={property ? property.site_link : ''}
      />
      {children}
    </MainTitle>
  );
}

export default ModalHeader;
