import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  padding: 30px 20px 10px 20px;
  justify-content: space-between;
`;
export const Title = styled.h1`
  font-size: 16px;
`;
export const BackButton = styled.button`
  font-size: 14px;
  padding: 15px;
  width: 100%;
  background: none;
  border: 0 none;
  text-align: left;
  border-bottom: 1px solid ${(p) => p.theme.borderColor};
  cursor: pointer;

  :hover {
    background: #f1f1f1;
  }

  svg {
    font-size: 20px;
    vertical-align: middle;
  }
`;

export const List = styled.div``;

export const Item = styled.div`
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  padding: 2px 0 2px 20px;

  :hover {
    color: ${(p) => p.theme.colors.secondary};
  }
`;
