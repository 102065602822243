import styled, { css } from 'styled-components';

const color = p => {
  if (p.transaction === '1' || p.transaction === 1) {
    return css`
      color: ${p.theme.colors.success};
    `;
  }

  return css`
    color: ${p.theme.colors.caution};
  `;
};

export const Text = styled.strong`
  font-weight: 700;
  ${color};
  font-size: ${p => p.size}px;
`;
