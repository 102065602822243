import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Input } from 'components/Form';
import Button from 'components/Button';
import * as SMTPService from 'services/settings/smtp';

function FormTest({ handleSubmit }) {
  return (
    <form
      onSubmit={(e, ...rest) => {
        e.preventDefault();
        e.stopPropagation();
        return handleSubmit(...rest);
      }}
    >
      <Field label="Para" name="to" component={Input} />
      <Button type="submit" block color="success">
        Testar
      </Button>
    </form>
  );
}

export default reduxForm({
  form: 'FormTest',
  onSubmit: async (values, dispatch, props) => {
    try {
      props.setIsLoading(true);
      await SMTPService.test(values);
      return values;
    } catch (err) {
      throw err;
    }
  },
})(FormTest);
