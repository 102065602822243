export default values => {
  const errors = {};

  if (values.country_id) {
    if (!values.neighborhood_id) {
      errors.neighborhood_id = 'Campo obrigatório quando tem país';
    }
  }

  return errors;
};
