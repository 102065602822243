import styled from 'styled-components';
import Button from 'components/Button';

export const Divider = styled.div`
  width: calc(100% + 30px);
  border-top: 1px solid ${p => p.theme.borderColor};
  margin-left: -15px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const WrapSearchButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BtnSearchMap = styled(Button).attrs(() => ({
  color: 'secondary'
}))``;
