import Container from "components/Container";
import MainTitle from "components/MainTitle";
import { Row } from "react-flexbox-grid";
import * as Fields from '../../fields';

function Informations() {
  return (
    <section id="informations">
      <MainTitle
        title="Cadastro de condomínio/empreendimento"
        text="Ao cadastrar um imóvel, será possível selecionar um condomínio/empreendimento para reaproveitamento de dados e fotos"
      />
      <Container>
        <Row>
          <Fields.Title />
        </Row>
      </Container>
    </section>
  );
}

export default Informations;