import { useRequest } from 'hooks/useRequest';
import { useState } from 'react';
import Modal from 'react-modal';
import * as smtpService from 'services/settings/smtp';
import Form from './components/Form';

// const _initialValues = {
//   host: 'mail.meusemails.com.br',
//   port: '465',
//   encryption: 'ssl',
//   username: 'app@gerenciarimoveis.com.br',
//   password: 'cdx@*k15',
//   from_name: 'Back End',
//   from_address: 'app@gerenciarimoveis.com.br',
// };

const _initialValues = {
  encryption: 'ssl',
};

function ModalSMTPConfig({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  onSubmitSuccess,
}) {
  const [canRemove, setCanRemove] = useState(false);

  const [isChangedForm, setIsChangedForm] = useState(true);
  const { data: initialValues } = useRequest({
    request: smtpService.show,
    initialState: {},
    onSuccess: (res) => {
      if (res.data.host) {
        setCanRemove(true);
      }
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      return smtpService.updateOrCreate(values);
    } catch (err) {
      throw err;
    }
  };

  const handleSubmitSuccess = () => {
    handleClose();
    if (onSubmitSuccess) onSubmitSuccess();
  };

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Form
        canRemove={canRemove}
        setCanRemove={setCanRemove}
        isChangedForm={isChangedForm}
        setIsChangedForm={setIsChangedForm}
        onChange={() => {
          setIsChangedForm(true);
        }}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        handleClose={handleClose}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        initialValues={{ ..._initialValues, ...initialValues }}
      />
    </Modal>
  );
}

export default ModalSMTPConfig;
