import Button from 'components/Button';
import { FieldBool } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import { useEffect } from 'react';
import { Row } from 'react-flexbox-grid';
import { reduxForm } from 'redux-form';

function Form({ reservation, handleSubmit, handleClose, change }) {
  useEffect(() => {
    if (reservation.closed_at === null) {
      change('should_change_publish', true);
    }
  }, [reservation]);

  return (
    <form onSubmit={handleSubmit}>
      <p>Deseja realmente excluir essa reserva?</p>
      {reservation.closed_at === null && (
        <Row className="h-margin-top--15">
          <FieldBool
            xs={12}
            label="Publicar o imóvel no Site?"
            name="should_change_publish"
            style={{ width: 130 }}
          />
        </Row>
      )}
      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="danger">
          Remover
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormReservationCreate',
})(Form);
