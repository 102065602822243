import React, { useCallback, useMemo } from 'react';
import { MdWarning } from 'react-icons/md';
// Components
import PopoverRevision from './component/PopoverRevision';
// Helpers
import PropertiesList from 'containers/PropertyDashboard/containers/PropertiesList';
// Services
import { getPropertiesRevision } from 'services/dashboards/properties';
import moment, { format } from 'lib/moment';
import * as classnames from 'classnames';
import * as propertiesService from 'services/properties';

function PanelPropertyRevision({ currentUser, updatedTime, updateTime }) {
  const handleSubmit = useCallback(
    async (property) => {
      if (property.isDisabling) {
        await propertiesService.nextReview({
          id: property.id,
          next_review_at: null,
        });
      } else {
        await propertiesService.nextReview(property);
      }

      updateTime();
    },
    [updateTime]
  );

  let params = useMemo(() => {
    if (!currentUser?.is_admin) {
      return {
        filter: {
          user_id: currentUser?.id,
          by_to_review_in: 0,
        },
      };
    }

    return {
      filter: {
        by_to_review_in: 0,
      },
    };
  }, [currentUser]);

  return (
    <PropertiesList
      key="panel-property-revision"
      title="Imóveis que precisam de revisão"
      icon={MdWarning}
      color="caution"
      renderNoData={() => <>Nenhum imóvel para revisar</>}
      updatedTime={updatedTime}
      request={getPropertiesRevision}
      limit={3}
      params={params}
      renderText={(property) => {
        const now = moment();
        const isDanger = moment(property.next_review_at).isSameOrBefore(now);
        const date = moment(property.next_review_at).format(format.date);

        return (
          <p
            className={classnames({
              'h-color--danger': isDanger,
            })}
          >
            Revisar em: {date}
          </p>
        );
      }}
      afterClose={() => {
        updateTime();
      }}
      renderAction={({ property }) => (
        <PopoverRevision
          propertyId={property.id}
          onSubmit={handleSubmit}
          submitFunc={handleSubmit}
        />
      )}
    />
  );
}

export default PanelPropertyRevision;
