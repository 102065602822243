import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { generatePix } from 'services/openai';
import { pixMock } from 'containers/ModalRoot/ModalPayment/pages/Payment/pages/Pix/mock';

export const usePix = (plan) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [code, setCode] = useState(null);
  const [generatedCreditId, setGeneratedCreditId] = useState(null);

  /**
   * Contrata com pix
   * @return {Promise<void>}
   */
  const contract = async () => {
    if (process.env.NODE_ENV === 'development') {
      setCode(pixMock.data.pix_qr_code);
      setGeneratedCreditId(pixMock.data.credit_id);
      return;
    }

    const {
      data: { pix_qr_code, openai_id: creditId },
    } = await generatePix({ plan });

    setGeneratedCreditId(creditId);
    setCode(pix_qr_code);
  };

  useEffect(() => {
    if (code) {
      QRCode.toDataURL(code, { width: 140, margin: 0 }, function (_, url) {
        setImageUrl(url);
      });
    }
  }, [code]);

  return { code, imageUrl, contract, creditId: generatedCreditId };
};
