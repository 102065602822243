import React from 'react';
import { Field } from 'redux-form';
import { Row } from 'react-flexbox-grid';
// Components
import FormBox from 'components/FormBox';
import { Select } from 'components/Form';

const propTypes = {};
const defaultProps = {};

const Rooms = ({ data }) => (
  <FormBox title="Cômodos">
    <Row>
      {data.map(({ title, name }) => (
        <Field
          key={`rooms-${name}`}
          xs={2}
          label={title}
          name={`rooms.${name}.value`}
          component={Select}
          placeholder="Indiferente"
          options={[
            { value: '1', label: '1 ou mais' },
            { value: '2', label: '2 ou mais' },
            { value: '3', label: '3 ou mais' },
            { value: '4', label: '4 ou mais' },
            { value: '5', label: '5 ou mais' }
          ]}
        />
      ))}
    </Row>
  </FormBox>
);

Rooms.propTypes = propTypes;
Rooms.defaultProps = defaultProps;

export default Rooms;
