import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Label from 'components/Label';
import Loading from 'components/Loading';
import Container from 'components/Container';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import BoxPaymentMethod from 'components/FormChangePlan/components/BoxPaymentMethod';

const defaultProps = {
  isLoading: false,
  form: null,
  type: 'select',
  receiving_method: null,

  credit: null,
  creditCard: null,
  bankSlip: null,

  change: null,

  handleSubmit: null,
  onAddCardSuccess: null,
  onAddCardFail: null,

  onBankSlipSuccess: null,
  onBankSlipFail: null,
};

const propTypes = {
  isLoading: PropTypes.bool,
  form: PropTypes.string.isRequired,
  receiving_method: PropTypes.oneOf([1, 2]),

  credit: PropTypes.shape({
    id: PropTypes.string.isRequired,
    is_received: PropTypes.bool,
    price: PropTypes.string,
    price_bonus: PropTypes.string,
    price_total: PropTypes.string,
  }),
  creditCard: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.string,
      brand: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
  }),
  bankSlip: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string,
      real_estate_id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.number,
      cpf_cnpj: PropTypes.string,
      should_send_email: PropTypes.bool,
    }),
    isFetching: PropTypes.bool,
  }),

  handleSubmit: PropTypes.func.isRequired,
  onAddCardSuccess: PropTypes.func,
  onAddCardFail: PropTypes.func,

  onBankSlipSuccess: PropTypes.func,
  onBankSlipFail: PropTypes.func,
};

const FormPlanInstallment = ({
  credit,
  isLoading,
  creditCard,
  bankSlip,
  handleSubmit,
  onAddCardSuccess,
  onAddCardFail,
  onBankSlipSuccess,
  onBankSlipFail,
  history,
}) => {
  const isSubmitDisabled = isLoading || credit.is_received;

  return (
    <form onSubmit={handleSubmit} className="FormChangePlan">
      <Loading isVisible={isLoading} position="center" isFullComponent />
      <Container className="FormChangePlan__container">
        <BoxPaymentMethod
          creditCard={creditCard}
          bankSlip={bankSlip}
          onAddCardSuccess={onAddCardSuccess}
          onAddCardFail={onAddCardFail}
          onBankSlipSuccess={onBankSlipSuccess}
          onBankSlipFail={onBankSlipFail}
        />
        <div className="BoxPlan BoxPlan--table" style={{ width: '50%' }}>
          <h4>Parcela</h4>
          <table
            className="Table"
            style={{ margin: '10px -30px 0', width: 'calc(100% + 61px)' }}
          >
            <thead>
              <tr>
                <th style={{ paddingLeft: '30px' }}>Preço</th>
                <th>Bônus</th>
                <th>Total</th>
                <th>Situação</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ paddingLeft: '30px' }}>{credit.price}</td>
                <td>{credit.price_bonus}</td>
                <td>{credit.price_total}</td>
                <td>
                  {!credit.is_received && <Label color="primary">Aberto</Label>}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <FixedBar>
          <FixedBar.item>
            <Button
              color="white"
              colorText="secondary"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancelar
            </Button>
          </FixedBar.item>
          <FixedBar.spacer />
          <FixedBar.item>
            <Button disabled={isSubmitDisabled} type="submit" color="success">
              Contratar
            </Button>
          </FixedBar.item>
        </FixedBar>
      </Container>
    </form>
  );
};

FormPlanInstallment.defaultProps = defaultProps;
FormPlanInstallment.propTypes = propTypes;

export default compose(
  withRouter,
  reduxForm({
    form: 'FormPlanInstallment',
    enableReinitialize: true,
  })
)(FormPlanInstallment);
