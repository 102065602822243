import React from 'react';
import { PERSON_PERMISSIONS } from 'constants/rules';
import SelectStage from '../SelectStage';
import { MdHelp } from 'react-icons/md';
import Button from 'components/Button';
import _ from 'lodash';
import Can from 'containers/Can';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useRequest } from 'hooks/useRequest';
import * as dealService from 'services/deals';
import {
  closeModal,
  openModalDealLoss,
  openModalEvent,
  openModalPerson,
  openModalPropertyRent,
  openModalPropertySell,
} from 'modules/modal';
import { Btn, BtnGroup, Container } from './styles';
import FormLevelInterest from 'containers/ModalRoot/ModalPerson/components/Properties/pages/PropertiesInterested/components/FormLevelInterest';
import schedule from './schedule.svg';
import anotation from './anotation.svg';
import BtnGain from 'containers/ModalRoot/ModalPerson/components/Properties/pages/PropertiesInterested/components/BtnGain';
import BtnLoss from 'containers/ModalRoot/ModalPerson/components/Properties/pages/PropertiesInterested/components/BtnLoss';

function Actions({ userId, person, matched, afterClose }) {
  const { data: stages, isFetching: isFetchingStages } = useRequest({
    request: () => dealService.getAllStages(),
  });

  const dispatch = useDispatch();

  const openMySelf = () => {
    setTimeout(() => {
      dispatch(
        openModalPerson(person, {
          initialPage: 'Properties',
          pageProperties: 'PropertiesInterested',
        })
      );
    }, 500);
  };

  const openPersonChat = (e) => {
    e.stopPropagation();

    dispatch(closeModal());

    setTimeout(() => {
      dispatch(
        openModalPerson(person, {
          initialPage: 'Notes',
          property: matched.property,
          stage: matched.stage,
          addNote: true,
        })
      );
    }, 500);
  };

  const handleAssumeCustomer = () => {
    const currentUser = localStorage.getItem('current-user');
    dispatch(change('SelectOwner', 'user_id', currentUser.id));
  };

  const handleChangeStage =
    ({ id, property_id }) =>
    async (stage) => {
      await dealService.updateDeal(stage?.stage_id, id, {
        property_id,
        people_id: person?.id,
        stage_id: stage?.stage_id,
      });
    };

  const onPropertyGain = () => {
    if (matched.property.transaction === 1) {
      dispatch(
        openModalPropertySell({
          property: matched.property,
          personId: matched.people_id,
          afterClose: () => {
            openMySelf();
            if (afterClose) afterClose();
          },
        })
      );
    } else {
      dispatch(
        openModalPropertyRent({
          property: matched.property,
          person: {
            id: matched.people_id,
          },
          afterClose: () => {
            openMySelf();
            if (afterClose) afterClose();
          },
        })
      );
    }
  };

  const onDealLoss = () => {
    dispatch(
      openModalDealLoss(matched.id, {
        afterClose: () => {
          openMySelf();
          if (afterClose) afterClose();
        },
      })
    );
  };

  const onSchedule = () => {
    dispatch(
      openModalEvent({
        initialValues: {
          people_id: person.id,
        },
        afterClose: () => {
          openMySelf();
          if (afterClose) afterClose();
        },
      })
    );
  };

  const updateMatched =
    ({ id: matched_id }) =>
    async (e) => {
      e.stopPropagation();
    };

  if (matched.property.status !== 'Disponível')
    return (
      <BtnGroup>
        <BtnGain onClick={onPropertyGain} />
        <BtnLoss onClick={onDealLoss} />
      </BtnGroup>
    );

  return (
    <>
      <Container>
        <div>
          <FormLevelInterest
            dealId={matched.id}
            form={`Form-${matched.id}`}
            initialValues={{ rate: matched.rate }}
          />
          <div>
            <Btn href="#" onClick={openPersonChat}>
              <img src={anotation} alt="" /> Fazer anotação
            </Btn>
          </div>
          <div>
            <Btn href="#" onClick={onSchedule}>
              <img src={schedule} alt="" /> Agendar Atividade
            </Btn>
          </div>
        </div>
        <div>
          <Can
            run={PERSON_PERMISSIONS.DEAL}
            permissions={person.permissions}
            yes={() => (
              <>
                {person.user_id ? (
                  <div>
                    <div className="h-color--primary h-text-left h-margin-bottom--5">
                      <strong>Posição no funil</strong>
                    </div>
                    <SelectStage
                      options={stages}
                      isLoading={isFetchingStages}
                      value={matched?.stage_id}
                      onChange={handleChangeStage(matched)}
                      style={{ width: 200 }}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="h-margin-bottom--10">
                      <p className="h-color--primary">
                        Cliente sem corretor responsável{' '}
                        <MdHelp
                          data-tip={
                            'Para definir uma etapa na<br> jornada de compra assuma esse cliente'
                          }
                          className="h-color--secondary"
                        />
                      </p>
                    </div>
                    <Button
                      onClick={handleAssumeCustomer}
                      color="success"
                      size="small"
                    >
                      Assumir cliente
                    </Button>
                  </div>
                )}
              </>
            )}
            no={() => {
              const stage = _.get(matched, 'deal.stage');

              if (!stage) return null;

              return (
                <div className="h-color--primary h-text-right h-margin-bottom--5">
                  <strong>Posição no funil</strong>
                  <div>{stage.name}</div>
                </div>
              );
            }}
          />
          <BtnGroup>
            <BtnGain onClick={onPropertyGain} />
            <BtnLoss onClick={onDealLoss} />
          </BtnGroup>
        </div>
      </Container>
    </>
  );
}

export default Actions;
