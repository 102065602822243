import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Col, Row } from 'react-flexbox-grid';
import { reduxForm } from 'redux-form';
// Components
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import FieldCreci from './FieldCreci';
import FieldDocument from './FieldDocument';
import validate from './validate';

const defaultProps = {
  handleSubmit: null,
};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormCreci = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col xs={6}>
        <FieldCreci />
      </Col>
      <Col xs={6}>
        <FieldDocument />
      </Col>
    </Row>
    <ModalFooter>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success">
        Enviar documentos
      </Button>
    </ModalFooter>
  </form>
);

FormCreci.defaultProps = defaultProps;
FormCreci.propTypes = propTypes;

export default compose(
  reduxForm({
    form: 'FormCreci',
    enableReinitialize: true,
    validate,
  })
)(FormCreci);
