import React, { useEffect } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Field, reduxForm } from 'redux-form';
import { Input } from 'components/Form';
import GroupControl, { GroupItem } from 'components/GroupControl';
import Button from 'components/Button';
import * as dealsService from 'services/deals';
import validate from './validate';
import { MdHelp } from 'react-icons/md';
import Tooltip from 'react-tooltip';
import Stages from './components/Stages';

function ModalFunnelStore({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  handleSubmit,
  initialValues,
  initialize,
  submitting,
}) {
  const inputNameRef = React.createRef();
  const isEditting = !!initialValues?.id;

  useEffect(() => {
    setTimeout(() => {
      Tooltip.rebuild();
    }, 200);

    if (inputNameRef?.current) {
      inputNameRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (!isEditting) {
      initialize({ clear_after: 120, stages: [{ stagnated_after: 3 }] });
    }
  }, [isEditting]);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={isEditting ? 'Editar funil' : 'Criar funil'}
        handleClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Row>
            <Field
              refName={inputNameRef}
              xs={7}
              label="Nome do funil"
              name="name"
              component={Input}
            />

            <Col xs={5}>
              <GroupControl
                label={
                  <div>
                    Limpeza em{' '}
                    <MdHelp data-tip="Prazo em dias que o negócio será encerrado automaticamente por estagnação." />
                  </div>
                }
              >
                <GroupItem>
                  <Field name="clear_after" component={Input} />
                </GroupItem>
                <GroupItem label="Dias" size="small" />
              </GroupControl>
            </Col>
          </Row>

          <Field
            label="Descrição"
            name="description"
            component={Input}
            placeholder="Digite a descrição do funil"
          />

          {!isEditting && <Stages />}

          <ModalFooter>
            <Button color="white" type="button" onClick={handleClose}>
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button disabled={submitting} color="success" type="submit">
              {isEditting ? 'Editar funil' : 'Criar funil'}
            </Button>
          </ModalFooter>
        </form>
      </ModalTemplate>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalFunnelStore',
  validate,
  onSubmit: (values) => {
    if (values.id) {
      return dealsService.updateFunnel(values);
    }

    return dealsService.createFunnel(values);
  },
  onSubmitSuccess: (res, dispatch, props) => {
    if (props.onSuccess) props.onSuccess(res, dispatch, props);
    props.handleClose();
  },
})(ModalFunnelStore);
