import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
// Components
import FixedBar from 'components/FixedBar';
import Loading from 'components/Loading';
import ListOptions from 'components/ListOptions';

import Button from 'components/Button';
import { Textarea } from 'components/Form';
import Container from 'components/Container';
// HOC
import { withFormConfirmation } from 'HOC/withFormConfirmation';

const TextArea = ({ ...props }) => (
  <div className="h-margin-top--10">
    <Field
      type="simple"
      component={Textarea}
      style={{ height: '120px' }}
      {...props}
    />
  </div>
);

class DefaultTextsForm extends Component {
  static defaultProps = {
    handleSubmit: null,
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, isFetching, submitting, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit} style={{ position: 'relative' }}>
        <Loading isVisible={isFetching} isFullComponent />
        <Container padding>
          <ListOptions>
            <ListOptions.item
              title="Compartilhar Imóveis seleção de imóveis"
              text="Defina o texto que será enviado ao seu cliente ao compartilhar um imóvel."
            >
              <TextArea
                name="share_properties_list"
                disabled={isFetching || submitting}
              />
            </ListOptions.item>
            <ListOptions.item
              title="Enviar Imóvel Individual"
              text="Texto padrão que será enviado junto com o imóvel para o seu cliente."
            >
              <TextArea name="share_property" />
            </ListOptions.item>
            <ListOptions.item
              title="Imóvel Compatível"
              text="Texto que será enviado para o seu cliente junto com os imóveis compatíveis com ele."
            >
              <TextArea name="matched" disabled={isFetching || submitting} />
            </ListOptions.item>
            <ListOptions.item
              title="Descrição do imóvel"
              text="Texto padrão que fica no cadastro/edição de imóvel logo antes de cadastrar"
            >
              <TextArea
                type="complex"
                name="property_description"
                disabled={isFetching || submitting}
              />
            </ListOptions.item>
            <ListOptions.item
              title="E-mail enviado para o proprietário após publicação do imóvel"
              text="Texto padrão que será enviado no e-mail para o proprietário juntamente com o link do imóvel."
            >
              <TextArea
                type="simple"
                name="property_owner"
                disabled={isFetching || submitting}
              />
            </ListOptions.item>
            <ListOptions.item
              title="E-mail recorrente do imóvel, enviado para o proprietário"
              text="Texto padrão que vem logo antes da assinatura do corretor."
            >
              <TextArea
                type="simple"
                name="property_owner_monthly"
                disabled={isFetching || submitting}
              />
            </ListOptions.item>
            <ListOptions.item
              title="Ficha de visita"
              text="Texto padrão que será adicionado ao final da ficha de visita."
            >
              <TextArea
                type="complex"
                name="visit_form"
                disabled={isFetching || submitting}
              />
            </ListOptions.item>
          </ListOptions>
        </Container>

        <FixedBar style={{ left: 256 }}>
          <span className="h-flex__cell--grow" />
          <Button
            disabled={submitting || pristine}
            type="submit"
            color="success"
          >
            Salvar
          </Button>
        </FixedBar>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'DefaultTextsForm',
    keepDirtyOnReinitialize: false,
    enableReinitialize: true,
  }),
  withFormConfirmation()
)(DefaultTextsForm);
