import Check from 'components/Check';
import React from 'react';
import PropTypes from 'prop-types';
import { Legend } from '../../../styles';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    channel: PropTypes.string,
    deals: PropTypes.array
  })
};

const defaultProps = {
  data: {}
};

const calcTotal = (total, current) => total + current.value;

const DealItem = ({
  toggleChecked,
  data,
  data: { isSelected, channel, data: deals, color }
}) => (
  <tr role="button" className="h-hover h-pointer" onClick={toggleChecked(data)}>
    <td className="h-sticky--left h-text-nowrap h-flex h-flex--center-center">
      <Legend color={color} />
      <Check checked={isSelected} className="h-margin-right--15" />
      {channel}
    </td>
    {deals.map((deal, index) => (
      <td key={`values-${index}-${deal.value}`}>{deal.value}</td>
    ))}
    <td className="h-sticky--right">{deals.reduce(calcTotal, 0)}</td>
  </tr>
);

DealItem.propTypes = propTypes;
DealItem.defaultProps = defaultProps;

export default DealItem;
