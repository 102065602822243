import Box from 'components/FilterBox';
import { Input } from "components/Form";
import { useCurrentUser } from 'hooks/useCurrentUser';
import useReduxForm from 'hooks/useReduxForm';
import { receiveUser } from 'modules/login';
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { updateMe } from "services/user";
import Alert from 'react-s-alert';
import { Container, EditIcon, PixKey } from "./styles";

const Title = ({ children }) => (
  <div className="FilterAside__title">{children}</div>
);
const Content = ({ children }) => (
  <div className="FilterAside__content">{children}</div>
);

function Pix({ handleSubmit }) {
  const [isChanging, setIsChanging] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const currentUser = useCurrentUser();

  const dispatch = useDispatch();
  const { initialize } = useReduxForm();

  useEffect(() => {
    if (!isInitialized && currentUser) {
      initialize({
        pix: currentUser.recommendation_pix,
      });
      setIsInitialized(true);
    }
  }, [currentUser, isInitialized, initialize]);

  function handleChange() {
    setIsChanging(true);
  }

  async function onSubmit(e, ...rest) {
    e.preventDefault();
    setIsChanging(false);

    try {
      const updatedUser = await handleSubmit(...rest);
      dispatch(receiveUser({
        ...currentUser,
        recommendation_pix: updatedUser.data.recommendation_pix,
      }));
      Alert.success('PIX atualizado com sucesso!');
    } catch (error) {
      Alert.error('Erro ao atualizar o pix.');
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Title>PIX para depósito</Title>
      <Content>
        <Box>
          {!isChanging ? (
            <Container>
              <PixKey>{currentUser?.recommendation_pix ?? 'Não informado'}</PixKey>
              <EditIcon onClick={handleChange} aria-label="Editar" title="Editar" />
            </Container>
          ) : (
            <Field
              autoFocus
              label="Chave PIX"
              name="pix"
              component={Input}
            />
          )}
        </Box>
      </Content>
    </form>
  );
}

export default reduxForm({
  form: 'Pix',
  onSubmit: (values) => {
    return updateMe({ recommendation_pix: values.pix });
  },
})(Pix);
