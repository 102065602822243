import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Common Components
import { ModalTemplate } from 'components/Modal';
import Loading from 'components/Loading';
import TableMonths from './TableMonths';
// Modules
import { openModalDebitsByDay, openModalDebitsMonth } from 'modules/modal';
// Service
import * as debitsService from 'services/financial/debits';

class ModalDebitsMonth extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    monthName: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired
  };

  static defaultProps = {
    monthName: 'Janeiro',
    month: '1',
    year: '2019'
  };

  state = {
    data: [],
    isFetching: false
  };

  fetchData = async () => {
    const { month, year } = this.props;
    this.setState({ isFetching: true });

    try {
      const { data } = await debitsService.getDebitsByMonthYear({
        month,
        year
      });

      this.setState({ data });
    } finally {
      this.setState({ isFetching: false });
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  /**
   * Abre a própria modal
   */
  openModal = () => {
    const { openModalDebitsMonth, monthName, month, year } = this.props;

    openModalDebitsMonth({ monthName, month, year });
  };

  onClickDebit = ({ id, created_at }) => {
    const { openModalDebitsByDay } = this.props;

    openModalDebitsByDay({
      debitId: id,
      date: created_at,
      onAfterClose: () => {
        this.openModal();
      }
    });
  };

  render() {
    const {
      modalConfig,
      isOpen,
      handleClose,
      modalType,
      monthName
    } = this.props;

    const { data, isFetching } = this.state;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title={`Debitos do mês de ${monthName}`}
          handleClose={handleClose}
        >
          <Loading isVisible={isFetching} position="center" isBlock />
          {!isFetching && (
            <TableMonths data={data} onClickRow={this.onClickDebit} />
          )}
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  openModalDebitsByDay,
  openModalDebitsMonth
};

export default connect(null, mapDispatchToProps)(ModalDebitsMonth);
