import { createContext, useContext, useState } from 'react';
import { GalleryProvider } from 'components/PropertyGallery/context';
import * as AllModels from 'components/ModelsPost';
import _ from 'lodash';
import {
  DEFAULT_IMAGE,
  DEFAULT_PAGE,
  PAGES,
} from 'containers/ModalRoot/ModalGeneratePost/constants';

export { PAGES };

export const ModalGeneratePostContext = createContext({
  pages: PAGES,
  image: null,
  setImage: (image) => null,
  property: {},
  page: '',
  setPage: (pageName) => null,
  models: [],
  setModels: (models) => null,
  handlePrevious: () => null,
  handleNext: () => null,
  selectedModelIndex: 0,
});

export function ModalGeneratePostProvider({ property, children }) {
  const [models, _setModels] = useState(_.toArray(AllModels.Stories));
  const [selectedModelIndex, setSelectedModelIndex] = useState(0);

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [image, setImage] = useState(DEFAULT_IMAGE);

  const setModels = (models) => {
    setSelectedModelIndex(0);
    _setModels(_.toArray(models));
  };

  const handlePrevious = () => {
    setSelectedModelIndex((prevIndex) =>
      prevIndex === 0 ? models.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setSelectedModelIndex((prevIndex) =>
      prevIndex === models.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <ModalGeneratePostContext.Provider
      value={{
        image,
        setImage,
        pages: PAGES,
        property,
        page,
        setPage,
        models: _.toArray(models),
        setModels,
        handlePrevious,
        handleNext,
        selectedModelIndex,
      }}
    >
      <GalleryProvider propertyId={property.id}>{children}</GalleryProvider>
    </ModalGeneratePostContext.Provider>
  );
}

export const useModal = () => useContext(ModalGeneratePostContext);
