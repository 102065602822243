import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { openModalRemoveAccount } from 'modules/modal';

const Btn = styled.button`
  border: 1px solid #f84343;
  color: #f84343;
  font-size: 12px;
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #ffe5e5;
  }
`;

function RemoveAccount() {
  const dispatch = useDispatch();
  const handleClickRemoveAccount = () => {
    dispatch(openModalRemoveAccount());
  };

  return (
    <Btn onClick={handleClickRemoveAccount}>Excluir minha conta e dados</Btn>
  );
}

export default RemoveAccount;
