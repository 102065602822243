import Container from 'components/Container';
import { Checkbox, Input } from 'components/Form';
import LocationActions from 'components/LocationActions';
import MainTitle from 'components/MainTitle';
import {
  Cities,
  Countries,
  Neighborhoods,
  States,
  Zones,
} from 'containers/LocationAsyncFields';
import { Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
import Cep from './components/Cep';

function Location() {
  return (
    <section id="location">
      <MainTitle
        title="Localização"
        text="Defina a localização do condomínio/empreendimento"
      >
        <LocationActions />
      </MainTitle>

      <Container>
        <Cep />
        <Row>
          <Countries xs={2} />
          <States xs={2} />
          <Cities xs={3} />
          <Neighborhoods xs={3} />
          <Zones xs={2} />
        </Row>
        <Row>
          <Field
            xs={9}
            label="Logradouro"
            name="street_address"
            component={Input}
          />
          <Field xs={3} label="Número" name="street_number" component={Input} />
        </Row>
        <Row style={{ margin: 0 }}>
          <Field
            xs={12}
            type="checkbox"
            responsive={false}
            name="update_properties_adress"
            component={Checkbox}
            color="secondary"
            colorOn="#fff"
            colorOff="#fff"
            label={
              <span className="h-color--white">
                Ao salvar, aplicar para todos os imóveis ligados a este
                condomínio
              </span>
            }
          />
        </Row>
      </Container>
    </section>
  );
}

export default Location;
