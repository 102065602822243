import React, { Component } from 'react';
import Alert from 'react-s-alert';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import qs from 'qs';
// Modules
import { loadingSelector, uploadSelector } from 'modules/upload';
import { getIsUploading } from 'modules/fileUpload';
import {
  getPropertyImages,
  handleChangeRotation,
  handleDeleteImage,
  handleFormChange,
  handleSubmitEdit,
  handleToggleEdit,
  imagesSelector,
  isFetchingSelector,
  orderImage,
  setGallery,
  removeAllImages,
} from 'modules/propertyGallery';
// Components
import Ball from 'components/Ball';
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import FilesLoading from 'components/FilesLoading';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import Container from 'components/Container';
import GalleryMenu from './components/GalleryMenu';
import GalleryAside from './components/GalleryAside';
import FormGalleryUpload from './components/FormGalleryUpload';
import GalleryCollection from 'components/GalleryCollection';
import DetectRefresh from 'components/DetectRefresh';
// Service
import * as propertiesService from 'services/properties';
// Modules
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { openModalProperty } from 'modules/modal';
// Assets
import image from 'assets/img/noData/photo.svg';
import watermark from './watermark.png';
import { PROPERTY_PERMISSIONS } from 'constants/rules';
import { plural } from 'lib/text';
import { WatermarkLoading } from './styles';

const propTypes = {
  getPropertyImages: PropTypes.func.isRequired,
  handleSubmitEdit: PropTypes.func.isRequired,
  handleToggleEdit: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      gallery: PropTypes.string.isRequired,
      property_id: PropTypes.string.isRequired,
    }),
  }),
};
const defaultProps = {
  getPropertyImages: null,
  handleToggleEdit: null,
  handleDeleteImage: null,
  match: {
    params: {
      gallery: null,
      property_id: null,
    },
  },
};

class PropertiesGallery extends Component {
  state = {
    hasImageUploaded: localStorage.getItem('has-image-uploaded'),
    property: {
      data: {},
      isFetching: false,
    },
  };

  get params() {
    return this.props.match.params;
  }

  get propertyId() {
    try {
      return this.params.property_id;
    } catch {
      return '';
    }
  }

  get gallery() {
    try {
      return this.params.gallery;
    } catch {
      return '';
    }
  }

  get isApplying() {
    const { property } = this.state;
    return property.data.is_applying_watermark;
  }

  async componentDidMount() {
    const { initialize } = this.props;
    const property = await this.fetchProperty();

    this.props.getPropertyImages(this.propertyId, this.gallery);
    this.props.setGallery(this.gallery);
    this.buttonText(this.gallery);

    initialize('FormApplyWatermark', {
      id: property.id,
      should_apply_watermark: property.should_apply_watermark,
    });
  }

  /**
   * Lida com o success do submit do formulário
   */
  onChangeWatermark = async () => {
    this.fetchProperty();
  };

  fetchProperty = async () => {
    this.setState({
      property: {
        data: {},
        isFetching: true,
      },
    });

    try {
      const { data: property } = await propertiesService.getOne(
        this.propertyId
      );

      this.setState({
        property: {
          data: property,
          isFetching: false,
        },
      });

      return property;
    } catch {
      this.setState({
        property: {
          data: {},
          isFetching: false,
        },
      });
    }
  };

  async hasPermissions(property_id) {
    const { history } = this.props;

    const { data: property } = await propertiesService.getOne(property_id);
    const hasPermissions = !!property && !!property.permissions;

    if (hasPermissions) {
      const permission = property.permissions.includes(
        PROPERTY_PERMISSIONS.EDIT
      );
      if (!permission) history.replace('/no-permission');
    }

    return property.includes;
  }

  componentDidUpdate(prevProps) {
    const { property_id, gallery: prevGallery } = prevProps.match.params;
    const { gallery: nextGallery } = this.props.match.params;

    if (nextGallery !== prevGallery) {
      this.props.setGallery(nextGallery);
      this.props.getPropertyImages(property_id, nextGallery);
    }
  }

  handleOrderImages = (indexes) => {
    const { property_id, gallery } = this.props.match.params;
    this.props.orderImage(indexes, property_id, gallery);
  };

  /**
   * Atualiza o texto do campo do botão
   * @param type_id
   */
  buttonText = (gallery) => {
    switch (gallery) {
      case '2':
        this.uploadText = 'Enviar fotos da planta';
        break;
      case '3':
        this.uploadText = 'Enviar fotos privadas';
        break;
      case '1':
      default:
        this.uploadText = 'Enviar fotos do imóvel';
        break;
    }
  };

  handleToggleEdit = (id) => () => {
    const { gallery } = this.props.match.params;
    this.props.handleToggleEdit(id, gallery);
  };

  handleSubmitEdit = (id) => (values) => {
    const { property_id, gallery } = this.props.match.params;

    this.props.handleSubmitEdit(
      {
        ...values,
        id,
        property_id,
      },
      gallery
    );
  };

  handleDeleteImage = (id) => (e) => {
    e.stopPropagation();
    const { property_id, gallery } = this.props.match.params;
    this.props.handleDeleteImage(id, property_id, gallery);
  };

  handleChangeRotation = (id) => (imageAngle) => {
    const { property_id, gallery } = this.props.match.params;
    this.props.handleChangeRotation(id, property_id, imageAngle, gallery);
  };

  get lastImage() {
    try {
      const { images } = this.props;

      return images[images.length - 1];
    } catch {
      return null;
    }
  }

  get lastOrder() {
    try {
      return this.lastImage.order;
    } catch {
      return 0;
    }
  }

  handleUpload = (values) => {
    this.props.handleFormChange({
      ...values,
      lastOrder: this.lastOrder,
    });
    localStorage.setItem('has-image-uploaded', true);
    this.setState({ hasImageUploaded: true });
  };

  removeGallery = () => {
    const {
      match: {
        params: { property_id, gallery },
      },
      openConfirmation,
      removeAllImages,
    } = this.props;

    openConfirmation({
      title: 'Remover Galeria',
      text: 'Remover todas as imagens dessa galeria?',
      request: () => {
        propertiesService.removeGallery(property_id, gallery).then(() => {
          removeAllImages(gallery);
        });
        return true;
      },
      onSuccess: () => {
        removeAllImages(gallery);
        Alert.success('Imagens removidas');
      },
    });
  };

  get renderBallUpload() {
    if (!this.state.hasImageUploaded) {
      return (
        <Ball
          size={10}
          color="caution"
          style={{ position: 'absolute', left: '-5px', bottom: '-5px' }}
        />
      );
    }
    return null;
  }

  get renderFormUpload() {
    const { gallery, property_id } = this.props.match.params;
    const { loading } = this.props;

    return (
      <FormGalleryUpload
        loading={loading}
        initialValues={{ property_id, gallery }}
        onChange={this.handleUpload}
        style={{ position: 'relative' }}
      >
        {this.uploadText}
        {this.renderBallUpload}
      </FormGalleryUpload>
    );
  }

  get searchParams() {
    try {
      return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    } catch {
      return {};
    }
  }

  handleClickBack = () => {
    const { property_id } = this.props?.match?.params;
    const {
      openModalProperty,
      history: { goBack },
    } = this.props;

    openModalProperty({ property: { id: property_id } });
    goBack();
  };

  get renderFixedBar() {
    const {
      history: { push },
    } = this.props;
    const searchParams = this.searchParams;

    return (
      <FixedBar style={{ left: 250 }}>
        <FixedBar.item>
          {!!searchParams?.backTo ? (
            <Button
              to={searchParams?.backTo}
              color="white"
              colorText="secondary"
            >
              Voltar
            </Button>
          ) : (
            <Button
              onClick={this.handleClickBack}
              color="white"
              colorText="secondary"
            >
              Voltar
            </Button>
          )}
        </FixedBar.item>
        <FixedBar.spacer />
        <FixedBar.item>
          <Button
            type="button"
            onClick={() => {
              const property = this.state.property.data;
              if (property?.is_published) {
                if (property?.condominium_id) {
                  push(`/properties/search?condo=${property?.condominium_id}`);
                } else {
                  push(`/properties/search?property=${property?.id}`);
                }

                return null;
              }

              push(`/properties/revision/show?property=${property.id}`);
            }}
            color="success"
          >
            Salvar
          </Button>
        </FixedBar.item>
      </FixedBar>
    );
  }

  get downloadUrl() {
    const apiUrl = process?.env?.REACT_APP_API_URL;
    const token = localStorage.getItem('access_token');

    return `${apiUrl}/api/properties/${this.propertyId}/images/download?gallery=${this.gallery}&token=${token}`;
  }

  render() {
    const { gallery, property_id } = this.props.match.params;
    const { images, isFetching, upload, isUploading } = this.props;
    const { property } = this.state;

    const hasImages = images.length > 0;

    // atualiza o texto do botão
    this.buttonText(gallery);

    return (
      <Wrapper full>
        <Helmet>
          <title>Galeria</title>
        </Helmet>
        <DetectRefresh
          when={isUploading}
          message="Deseja realmente cancelar o processo de upload?"
        />
        <GalleryAside
          property={property}
          onChangeWatermark={this.onChangeWatermark}
        />
        <Wrapper.inner style={{ paddingBottom: '60px' }}>
          <Wrapper.container>
            <MainTitle
              title={`Galeria de Fotos`}
              text="Adicione boas fotos aos seus imóveis"
            >
              <div className="h-flex">
                {hasImages && (
                  <Button tagName="a" href={this.downloadUrl} target="_blank">
                    Baixar imagens
                  </Button>
                )}
                {this.renderFormUpload}
              </div>
            </MainTitle>
            <Container padding style={{ marginBottom: 100 }}>
              {this.isApplying ? (
                <WatermarkLoading isVisible isFullComponent>
                  <img src={watermark} alt="" />
                </WatermarkLoading>
              ) : (
                <>
                  {upload && (
                    <FilesLoading
                      filesTotal={upload.total}
                      filesUploaded={upload.success + upload.error}
                      handleCancelUpload={upload.cancelFunction}
                    />
                  )}
                  <div
                    className="h-flex__cell--grow"
                    style={{ display: 'block', padding: '5px 0 15px' }}
                  >
                    <GalleryMenu propertyId={property_id} />
                    {hasImages && (
                      <div
                        className="h-flex h-flex--center-center"
                        style={{ padding: 15 }}
                      >
                        <span className="h-color--primary">
                          {plural(images.length, 'imagem', 'imagens')}
                        </span>
                        <span className="h-flex__cell--grow" />
                        <span>
                          <Button
                            onClick={this.removeGallery}
                            size="small"
                            color="danger"
                          >
                            Remover todas as imagens
                          </Button>
                        </span>
                      </div>
                    )}
                    <div className="h-container--padding">
                      {images.length === 0 && !isFetching && (
                        <Nodata
                          image={image}
                          title="Nenhuma foto adicionada"
                          text="Que tal começar agora?"
                        />
                      )}
                      <Loading isVisible={isFetching} />
                      {!isFetching && (
                        <GalleryCollection
                          hasFeatured={gallery === '1'}
                          images={images}
                          handleToggleEdit={this.handleToggleEdit}
                          handleSubmitEdit={this.handleSubmitEdit}
                          handleDeleteImage={this.handleDeleteImage}
                          handleChangeRotation={this.handleChangeRotation}
                          onSortEnd={this.handleOrderImages}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </Container>
          </Wrapper.container>
        </Wrapper.inner>
        {this.renderFixedBar}
      </Wrapper>
    );
  }
}

PropertiesGallery.propTypes = propTypes;
PropertiesGallery.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isUploading: getIsUploading(state),
  images: imagesSelector(state),
  upload: uploadSelector('propertyGallery')(state),
  loading: loadingSelector('propertyGallery')(state),
  isFetching: isFetchingSelector(state),
});

const mapDispatchToProps = {
  handleSubmitEdit,
  openModalProperty,
  openConfirmation,
  handleFormChange,
  handleChangeRotation,
  handleDeleteImage,
  getPropertyImages,
  handleToggleEdit,
  orderImage,
  setGallery,
  removeAllImages,
  initialize,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PropertiesGallery);
