import React from 'react';
import { connect } from 'react-redux';
import CardProperty from '../../../../../components/CardProperty/index';
import Button from '../../../../../components/Button';
import Nodata from '../../../../../components/Nodata';
import {
  dealsSelector,
  getGainDeals,
  getLossDeals,
  getOpenedDeals,
  isFetchingDealsSelector,
} from '../../../../../modules/personCrm';

import { openModalDeal } from '../../../../../modules/modal';
import Menu from '../../../../../components/Menu';
import Loading from '../../../../../components/Loading';

const propTypes = {};

const defaultProps = {};

const CardLoss = ({ deal, updated_at }) => (
  <CardProperty
    type="horizontal"
    property={deal.property}
    message={
      <div>
        <h5>Detalhes da transação</h5>
        <p>
          <span className="h-color--danger">Perdido</span> em: {deal.updated_at}
        </p>
      </div>
    }
  />
);

const CardGain = ({ deal, updated_at }) => (
  <CardProperty
    type="horizontal"
    property={deal.property}
    message={
      <div>
        <h5>Detalhes da transação</h5>
        <p>
          <span className="h-color--success">Ganho</span> em: {deal.updated_at}{' '}
          - Comissão: Vilson R$6.000,00
        </p>
        <div dangerouslySetInnerHTML={{ __html: deal.won_observation }} />
      </div>
    }
  />
);

const CardOpened = ({ deal, openModalDeal }) => (
  <CardProperty type="horizontal" property={deal.property}>
    <Button size="small" color="secondary" onClick={() => openModalDeal(deal)}>
      Ver interações
    </Button>
  </CardProperty>
);

const Cards = {
  PropertiesOpened: CardOpened,
  PropertiesGain: CardGain,
  PropertiesLoss: CardLoss,
};

class Crm extends React.Component {
  constructor() {
    super();

    this.state = {
      page: 'PropertiesOpened',
    };

    this.handleClickItem = this.handleClickItem.bind(this);
  }

  componentDidMount() {
    this.props.getOpenedDeals(this.props.person);
  }

  handleClickItem(component) {
    switch (component) {
      case 'PropertiesGain': {
        this.props.getGainDeals(this.props.person);
        break;
      }
      case 'PropertiesLoss': {
        this.props.getLossDeals(this.props.person);
        break;
      }
      case 'PropertiesOpened':
      default: {
        this.props.getOpenedDeals(this.props.person);
      }
    }
    this.setState({ page: component });
  }

  render() {
    const { deals, isFetching, openModalDeal } = this.props;

    let styleMenu = {
      marginBottom: 15,
    };

    if (isFetching) {
      styleMenu = {
        ...styleMenu,
        pointerEvents: 'none',
      };
    }

    return (
      <div>
        <Menu
          modal
          currentPage={this.state.page}
          handleClickItem={this.handleClickItem}
          style={styleMenu}
        >
          <Menu.item component="PropertiesOpened">EM ABERTO</Menu.item>
          <Menu.item component="PropertiesGain">NEGÓCIOS GANHOS</Menu.item>
          <Menu.item component="PropertiesLoss">NEGÓCIOS PERDIDOS</Menu.item>
        </Menu>
        {isFetching ? (
          <Loading isVisible />
        ) : deals && deals.length > 0 ? (
          deals.map((deal) =>
            React.createElement(Cards[this.state.page], {
              deal,
              openModalDeal,
            })
          )
        ) : (
          <Nodata title="Nenhum negócio" text="" />
        )}
      </div>
    );
  }
}

Crm.propTypes = propTypes;
Crm.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  deals: dealsSelector(state),
  isFetching: isFetchingDealsSelector(state),
});

const mapDispatchToProps = {
  openModalDeal,
  getOpenedDeals,
  getGainDeals,
  getLossDeals,
};

export default connect(mapStateToProps, mapDispatchToProps)(Crm);
