import { plural } from 'lib/text';
import qs from 'qs';

export const getStatusName = (status) => {
  switch (status) {
    case '1':
    case 1:
      return 'Disponíveis';
    case '2':
    case 2:
      return 'Vendidos';
    case '3':
    case 3:
      return 'Alugados';
    case '4':
    case 4:
      return 'Excluídos';
    default:
      return 'Indefinido';
  }
};

export const transformQuery = (query) => {
  let _query = typeof query === 'object' ? query : qs.parse(query);

  const filter = _query.filter;

  let obj = {};

  if (filter?.transaction) {
    obj.transaction =
      filter.transaction === 1 || filter.transaction === '1'
        ? 'Venda'
        : 'Locação';
  } else {
    obj.transaction = null;
  }

  if (filter?.type_name) {
    obj.type_name = filter.type_name;
  }

  if (filter?.price?.greater_equals) {
    obj.price_min = filter?.price?.greater_equals.replace('R$', '');
  }

  if (filter?.price?.lower_equals) {
    obj.price_max = filter?.price?.lower_equals.replace('R$', '');
  }

  if (filter?.status) {
    obj.status = getStatusName(filter.status);
  }

  if (filter?.by_room?.bedroom?.value) {
    obj.bedroom = plural(
      filter?.by_room?.bedroom?.value,
      'Dormitório',
      'Dormitórios'
    );
  }

  if (filter?.by_room?.suite?.value) {
    obj.suite = plural(filter?.by_room?.suite?.value, 'Suíte', 'Suítes');
  }

  if (filter?.by_room?.garage?.value) {
    obj.garage = plural(filter?.by_room?.garage?.value, 'Garagem', 'Garagens');
  }

  if (filter?.count?.self?.count) {
    if (
      filter?.count?.self?.count === 0 ||
      filter?.count?.self?.count === '0'
    ) {
      obj.count = `Nenhum imóvel em ${obj.status}`;
    } else {
      obj.count = plural(
        filter?.count?.self?.count,
        `imóvel em ${obj.status}`,
        `imóveis em ${obj.status}`
      );
    }
  }

  obj.ground_total_area = '';

  if (filter?.by_area?.ground_total_area?.greater_equals) {
    obj.ground_total_area =
      'Àrea total: ' +
      filter?.by_area?.ground_total_area?.greater_equals +
      filter?.by_area?.ground_total_area?.measure;
  }

  if (filter?.by_area?.ground_total_area?.lower_equals) {
    obj.ground_total_area =
      obj.ground_total_area +
      ' até ' +
      filter?.by_area?.ground_total_area?.lower_equals +
      filter?.by_area?.ground_total_area?.measure;
  }

  if (filter?.by_type_or_subtype_id) {
    obj.type_id = filter?.by_type_or_subtype_id;
  }

  if (filter?.price?.lower_equals && !filter?.price?.greater_equals) {
    obj.price_field = `Preço até ${filter?.price?.lower_equals}`;
  }

  if (filter?.price?.greater_equals && !filter?.price?.lower_equals) {
    obj.price_field = `acima de ${filter?.price?.greater_equals}`;
  }

  if (filter?.price?.greater_equals && filter?.price?.lower_equals) {
    obj.price_field = `${filter?.price?.greater_equals} até ${filter?.price?.lower_equals} reais`;
  }

  if (filter?.transaction) {
    obj.transactionTitle =
      filter.transaction === 1 || filter.transaction === '1'
        ? 'a venda'
        : 'a locação';
  } else {
    obj.transactionTitle = '';
  }

  return obj;
};
