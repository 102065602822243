import React from 'react';
// Components
import Wrapper from 'components/Wrapper';
import NoContent from 'components/NoContent';
import Button from 'components/Button';
// Assets
import funnel from 'assets/img/receptions/funnel.svg';

export default function ReceptionCrm({ handleClickAction }) {
  return (
    <Wrapper.inner>
      <Wrapper.container>
        <NoContent
          title="Funil"
          text="Com o funil, você pode perceber visualmente em que etapa se encontra cada um dos clientes que você está negociando, e conduzi-los com mais facilidade até a finalização da venda."
          image={funnel}
        >
          <Button
            type="button"
            color="secondary"
            colorText="white"
            onClick={handleClickAction}
          >
            Entendi
          </Button>
        </NoContent>
      </Wrapper.container>
    </Wrapper.inner>
  );
}
