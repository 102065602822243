import styled from 'styled-components';

export const WrapperNoData = styled.div`
  display: grid;
  place-items: center;
  height: 100%;

  .Nodata__title {
    font-size: 20px;
  }
`;
