import React, { Component } from 'react';
import _, { map } from 'lodash';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Components
import { ModalTemplate } from 'components/Modal';
import Menu from 'components/Menu';
import MainForm from './components/MainForm';
// Services
import * as propertiesService from 'services/properties';
import * as portalsService from 'services/settings/portals';
// HOC
import { withFormData } from 'HOC/withFormData';
import Can from 'containers/Can';
import Nodata from 'components/Nodata';
import house from 'assets/img/noData/house.svg';
import { PROPERTY_PERMISSIONS } from 'constants/rules';

const styleMenu = {
  marginTop: -20,
  marginBottom: 20,
  borderTop: '0 none',
  width: 'calc(100% + 40px)',
  marginLeft: '-20px',
};

const fields = [
  'is_published',
  'is_featured',
  'stripe_background',
  'is_street_shown',
  'is_neighborhood_shown',
  'is_complement_shown',
  'is_condominium_shown',
  'is_floor_shown',
  'is_apartment_number_shown',
  'is_map_shown',
  'is_exact_map_shown',
  'is_streetview_shown',
  'is_network_published',
  'portal_real_estate_options',
];

class ModalPropertyPublication extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    property: {},
  };

  state = {
    currentPage: 'FormSite',
    portals: [],
  };

  pages = [
    'FormSite',
    'FormStripe',
    'FormAddress',
    'FormMap',
    'FormPortal',
    'FormSeo',
  ];

  handleClickMenuItem = (page) => this.setState({ currentPage: page });

  fetchPropertyData = async (params) => {
    this.props.requestFormData();

    try {
      const { data } = await propertiesService.getOne(
        this.props.property.id,
        params
      );

      delete data.status;

      if (data?.is_draft) {
        const publicationData = localStorage.getItem('@tecimob/publication');
        this.props.receiveFormData({
          ...data,
          ...publicationData,
        });
      } else {
        this.props.receiveFormData(data);
      }
    } catch {
      this.props.receiveFormData({});
    }
  };

  async componentDidMount() {
    if (!localStorage.getItem('@tecimob/publication')) {
      localStorage.setItem('@tecimob/publication', {
        stripe_background: '#3E587C',
        is_published: true,
        is_featured: true,
        is_network_published: true,
      });
    }

    // Busca os dados do imóvel
    this.fetchPropertyData({
      include: 'cover_image,portal_real_estate_options',
    });

    const { data: portals } = await portalsService.getListConnectedPortals();
    this.setState({ portals });
  }

  /**
   * Altera um imóvel
   * @param values
   */
  handleSubmit = (values) => {
    const publicationValues = _.pick(values, fields);
    localStorage.setItem('@tecimob/publication', publicationValues);

    return propertiesService.publicProperty(values).then(() => {
      // Fecha a modal
      this.props.handleClose();
    });
  };

  getCurrentIndex = () => {
    const { currentPage } = this.state;
    return this.pages.indexOf(currentPage);
  };

  getPagenameByIndex = (index) => this.pages[index];

  goNextPage = (e) => {
    e.preventDefault();

    const currentIndex = this.getCurrentIndex();
    const nextPageName = this.getPagenameByIndex(currentIndex + 1);

    this.handleClickMenuItem(nextPageName);
  };

  renderTitle = () => {
    const { title_formatted, calculated_price } = this.property;

    return (
      <div className="Modal__header">
        <h4 className="Modal__title">
          Publicar Imóvel - {title_formatted} -{' '}
          <span className="h-color--success">{calculated_price}</span>
        </h4>
        <p className="h-margin-top--5">
          Defina abaixo quais dados você deseja que apareçam em seu site e os
          que você deseja omitir.
        </p>
      </div>
    );
  };

  get property() {
    const { formData } = this.props;
    if (formData.data) return formData.data;
    return this.props.property;
  }

  get type() {
    try {
      return this.property.subtype.type;
    } catch {
      return null;
    }
  }

  get initialValues() {
    let property = this.property;

    if (property && property.is_draft === false) return property;

    if (!property?.maps_latitude && !property?.maps_longitude) {
      property = {
        ...property,
        is_map_shown: false,
        is_exact_map_shown: false,
        is_streetview_shown: false,
      };
    }

    return {
      ...property,
      stripe_background: property ? property.stripe_background : '#3E587C',
    };
  }

  onSubmitSuccess = (...params) => {
    if (this.props.onSubmitSuccess) this.props.onSubmitSuccess(...params);
  };

  onSubmitFail = (e, dispatch, submitError, props) => {
    if (e.custom_url) {
      this.handleClickMenuItem('FormSeo');
      props.touch(`custom_url`);
      return false;
    }

    if (e.portal_real_estate_options) {
      this.handleClickMenuItem('FormPortal');

      map(e.portal_real_estate_options, (values, key) => {
        props.touch(`portal_real_estate_options[${key}].id`);
      });
    }
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType, formData } =
      this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          isLoading={formData.isFetching}
          handleClose={handleClose}
          renderHeader={this.renderTitle}
        >
          <Can
            run={PROPERTY_PERMISSIONS.PUBLISH}
            permissions={this.property.permissions}
            yes={() => (
              <>
                <Menu
                  border
                  currentPage={this.state.currentPage}
                  handleClickItem={this.handleClickMenuItem}
                  style={styleMenu}
                >
                  <Menu.item component="FormSite">SITE</Menu.item>
                  <Menu.item component="FormStripe">TARJA</Menu.item>
                  <Menu.item component="FormAddress">ENDEREÇO</Menu.item>
                  <Menu.item component="FormMap">MAPA</Menu.item>
                  <Menu.item component="FormPortal">PORTAIS</Menu.item>
                </Menu>

                <MainForm
                  portals={this.state.portals}
                  onSubmit={this.handleSubmit}
                  onSubmitSuccess={this.onSubmitSuccess}
                  onSubmitFail={this.onSubmitFail}
                  initialValues={this.initialValues}
                  handleClose={handleClose}
                  currentForm={this.state.currentPage}
                  property={this.property}
                  type={this.type}
                  goNextPage={this.goNextPage}
                />
              </>
            )}
            no={() => (
              <>
                <Nodata
                  image={house}
                  title="Voce não tem permissão para publicar esse imóvel"
                  text={
                    <>
                      Essa área não está liberada nas suas permissões.
                      <br />
                      Solicite que o administrador do sistema libere o acesso.
                    </>
                  }
                />
              </>
            )}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

export default withFormData('PropertyPublication')(ModalPropertyPublication);

/**
 273eff9d-2a29-4cc8-b825-492ea97ec34c: {id: "7abf61d8-bb73-4e3c-a9a5-d8c7404f1680"}
 72661ac1-9538-4786-a90b-93c7b0c64ff5: {id: "fdaef9ba-2386-4354-b486-4fe0b5777c90"}
 c9cd3ae2-303c-46d8-bccd-0fd23aebb4d7: {id: "28b77d88-d198-4990-b682-a5f42a09adc5"}
 */

//4a131be9-a14e-4788-b842-ce99c1e79579: {isChecked: true, id: "d490e87a-6555-4585-8672-556d318d21ab"}
//273eff9d-2a29-4cc8-b825-492ea97ec34c: {id: "7abf61d8-bb73-4e3c-a9a5-d8c7404f1680"}
//72661ac1-9538-4786-a90b-93c7b0c64ff5: {id: "fdaef9ba-2386-4354-b486-4fe0b5777c90"}
//c6b49af2-37ad-42b3-9711-a01caeef9d22: {isChecked: true, id: "69075dc5-44af-43b0-9e5f-41f46dc179a5"}
//c9cd3ae2-303c-46d8-bccd-0fd23aebb4d7: {id: "28b77d88-d198-4990-b682-a5f42a09adc5"}
//eddb0ec1-1836-44c1-bf7d-278d29c93c4d: {isChecked: true, id: "4839f581-378c-4626-aff6-7b59994a0037"}
