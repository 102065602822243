import { useRequest } from 'hooks/useRequest';
import * as movimentsService from '../services/moviments';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { openModalMovimentPay } from '../modals/ModalMovimentPay';

export const useShowMoviment = () => {
  const { data, isFetching, fetchData } = useRequest({
    request: ({ postingId, movimentId }) => {
      return movimentsService.showMoviment({ postingId, movimentId });
    },
    initialFetch: false,
    initialState: {},
  });

  return {
    moviment: data,
    movimentIsFetching: isFetching,
    fetchMoviment: fetchData,
  };
};

export const useDestroyMoviment = () => {
  const destroyMoviment = ({ postingId, movimentId, repeatment_type }) => {
    return movimentsService.destroyMoviment({
      postingId,
      movimentId,
      repeatment_type,
    });
  };

  return { destroyMoviment };
};

export const useMovimentPay = () => {
  const dispatch = useDispatch();

  const handlePayMoviment = ({ movimentId, postingId, ...values }) => {
    return movimentsService.payMoviment({
      movimentId: movimentId,
      postingId: postingId,
      ...values,
    });
  };

  const openCancelMoviment = ({ moviment, onSuccess }) => {
    if (!moviment.can_edit) return null;

    dispatch(
      openConfirmation({
        title: 'Cancelar pagamento',
        text: (
          <>
            Cancelar esse pagamento vai reabrir o lançamento.
            <br />
            Tem certeza de que deseja continuar?
          </>
        ),
        submitButtonText: 'Efetuar cancelamento',
        request: () => {
          return movimentsService.removePayment({
            movimentId: moviment?.id,
            postingId: moviment?.posting_id,
          });
        },
        onSuccess,
      })
    );
  };

  const handleMovimentPay = ({ moviment, onSuccess }) => {
    if (moviment.situation === 'Pago') {
      return openCancelMoviment({ moviment, onSuccess });
    }

    // Chamar modal de confirmação de pagamento
    dispatch(
      openModalMovimentPay({
        movimentId: moviment?.id,
        postingId: moviment?.posting_id,
        onSuccess,
      })
    );
  };

  return { handlePayMoviment, handleMovimentPay };
};
