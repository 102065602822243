import axios from 'axios';
import api from 'services';
// Constants
import { GOOGLE_CLIENT_ID, GOOGLE_CLIENT_SECRET, REDIRECT_URI } from 'constants/config';

/**
 * Cria uma instancia da URL base do google
 * @type {AxiosInstance}
 */
export const googleApi = axios.create({
  baseURL: 'https://www.googleapis.com/'
});

const responseData = ({ data }) => data;

/**
 * Atualiza o token do analytics da imobiliária
 * @returns {*|AxiosPromise<any>}
 */
export const updateRefreshToken = async data => {
  await api.create('oauth/analytics', data);
  return data;
};

export const removeAnalytics = async () => {
  await api.delete('oauth/analytics');
};

/**
 * Pega os token com base o code
 * @param code
 * @returns {AxiosPromise<any>}
 */
export const getTokens = async code =>
  googleApi
    .post('oauth2/v4/token', {
      code,
      redirect_uri: REDIRECT_URI,
      client_id: GOOGLE_CLIENT_ID,
      client_secret: GOOGLE_CLIENT_SECRET,
      scope: '',
      grant_type: 'authorization_code'
    })
    .then(responseData)
    .then(updateRefreshToken);

/**
 * Atualiza o token de acesso
 * @param refreshToken
 * @returns {Promise<AxiosPromise<any>>}
 */
export const refreshAcessToken = async refreshToken =>
  googleApi
    .post('oauth2/v4/token', {
      client_id: GOOGLE_CLIENT_ID,
      client_secret: GOOGLE_CLIENT_SECRET,
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    })
    .then(responseData);
