import { useRequest } from 'hooks/useRequest';
import * as superlogicaService from 'services/integrations/superlogica';
import { useMemo } from 'react';
import { openModalSuperlogica } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import Alert from 'react-s-alert';

export const useSuperlogica = () => {
  const dispatch = useDispatch();

  const { data, isFetching, fetchData } = useRequest({
    request: superlogicaService.getOne,
  });

  const isConnected = useMemo(() => {
    if (isFetching) return false;
    try {
      return !!data.id;
    } catch {
      return false;
    }
  }, [data, isFetching]);

  const connect = async () => {
    dispatch(
      openModalSuperlogica({
        onSuccess: () => {
          fetchData();
        },
      })
    );
  };

  const disconnect = async () => {
    dispatch(
      openConfirmation({
        request: () => superlogicaService.disconnect(),
        title: 'Deseja realmente desconectar?',
        submitButtonText: 'Desconectar',
        onSuccess: () => {
          Alert.success('Superlogica desconectado');
          fetchData();
        },
      })
    );
  };

  const sendPerson = ({ id, type }) => {
    return superlogicaService.sendPerson({ id, type });
  };

  const sendProperty = ({ id }) => {
    return superlogicaService.sendProperty({ id });
  };

  return {
    data,
    isConnected,
    isFetching,
    fetchData,
    connect,
    disconnect,
    sendPerson,
    sendProperty,
  };
};
