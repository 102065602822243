import React from 'react';
// Components
import Loading from 'components/Loading';
import FormScript from '../../components/FormScript';
import MainTitle from 'components/MainTitle';
// Service
import * as navigationsService from 'services/sites/navigations';
// Constants
import { TYPES_MENU } from 'pages/Site/constants';

class ScriptForm extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    formData: {},
    isFetching: false
  };

  componentDidMount() {
    const { navigation_id } = this.props.match.params;

    if (navigation_id) {
      this.setState({ isFetching: true });

      return navigationsService.getOne(navigation_id).then(({ data }) => {
        this.setState({ formData: data, isFetching: false });
      });
    }
  }

  onSubmit = values =>
    values.id
      ? navigationsService.update(values)
      : navigationsService.create(values);

  onSubmitSuccess = (res, dispatch) => {
    this.props.history.push('/site/layout/style/menu');
  };

  render() {
    const { formData } = this.state;

    return (
      <>
        <MainTitle
          title="Criar Página"
          text="Coloque aqui o script que vai ser executado na página"
        />
        {this.state.isFetching ? (
          <Loading isVisible />
        ) : (
          <FormScript
            initialValues={{
              type: TYPES_MENU.SCRIPT,
              status: true,
              page: {
                code: `<style>/* Definições de estilo */</style>

<!-- Cole seu html -->

<script>/* Cole aqui códigos javascript */</script>`
              },
              ...formData
            }}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
          />
        )}
      </>
    );
  }
}

export default ScriptForm;
