import { useMemo } from 'react';
import { Value as Wrapper } from '../../styles';

function Value({ children }) {
  const value = useMemo(() => {
    try {
      switch (typeof children) {
        case 'string':
        case 'number':
          return children ? children : '-';
        case 'boolean':
          return children ? 'Sim' : 'Não';
        case 'object':
          return children;
        default:
          return '-';
      }
    } catch (err) {
      return '-';
    }
  }, [children]);

  return <Wrapper>{value}</Wrapper>;
}

export default Value;
