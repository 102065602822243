import React from 'react';
// Components
import Row from '../Row';
import Item from '../Item';
import Title from '../Title';

const propTypes = {};
const defaultProps = {};

const Header = ({ title, renderTitle, children }) => (
  <Row start="xs">
    <Item xs={12} className="ListInfo__header">
      {renderTitle ? renderTitle() : <Title>{title}</Title>}
      {children ? (
        <>
          <div className="h-flex__cell--grow" />
          {children}
        </>
      ) : null}
    </Item>
  </Row>
);

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
