import React from 'react';
import Icon from 'components/Icon';
// import { Wrapper } from './styles';

const Checkbox = (props) => {
  const { input, index, option, valueKey, labelKey, handleChange } = props;

  const { name } = input;
  const inputValue = input.value;

  // pega o texto do checkbox
  const label = option[labelKey];

  // pega o valor do checkbox
  const value = option[valueKey];

  // verifica se está checado
  const checked = inputValue.indexOf(value) !== -1;

  return (
    <label key={`checkbox-${index}`}>
      <input
        type="checkbox"
        name={`${name}[${index}]`}
        value={value}
        checked={checked}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <Icon
        name={checked ? 'check-square' : 'square-o'}
        className="h-margin-right--5"
      />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
