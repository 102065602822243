import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  border-radius: 10px;
  padding: 10px;
  background: ${(p) => p.color ?? '#4C4B62'};

  width: 140px;
  height: 80px;

  cursor: pointer;

  .icon-card {
    width: 32px;
    height: 30px;
  }
  
  border: 2px solid ${(p) => p.color ?? '#4C4B62'};
  transition: border-color 0.2s;

  &:hover {
    border-color: ${(p) => p.theme.colors.success};
  }

  ${(props) => props.active && css`
    border-color: ${(p) => p.theme.colors.success};
  `}
`;

export const Number = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
`;
