import React from 'react';
import Button from 'components/Button';
import { Wrapper } from './styles';
import { useUnity } from 'pages/EnterpriseStore/pages/Unity/contexts/UnityContext';

const TableUnitys = () => {
  const { data, remove, removeAll } = useUnity();

  return (
    <Wrapper className="Table">
      <thead>
        <tr>
          <th>Título</th>
          <th>Preço</th>
          <th>Área Total</th>
          <th>Status</th>
          <th width={1}>
            {data?.properties?.length > 0 && (
              <Button
                color="danger"
                size="small"
                style={{ margin: '-23px 0 -23px' }}
                onClick={removeAll}
              >
                Remover todas
              </Button>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.properties?.map((property, i) => {
          return (
            <tr key={property?.id + i} className="h-hover">
              <td>{property.title}</td>
              <td>{property.price}</td>
              <td>{property.total_area || '--'}</td>
              <td>{property.status}</td>
              <td className="Table__cell--small">
                <div className="h-hover__show">
                  <Button color="danger" onClick={remove(property?.id)}>
                    Remover
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Wrapper>
  );
};

export default TableUnitys;
