// Widgets.js
import { keyBy, mapValues } from 'lodash';
import { createModule } from '../lib/reducer-helpers';

const createAction = createModule('visibility');

// Actions
const REGISTER = createAction('REGISTER');
const SHOW = createAction('SHOW');
const HIDE = createAction('HIDE');
const HIDE_ALL = createAction('HIDE_ALL');
const RESET = createAction('RESET');

const initialState = {};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER: {
      // inicializa todos os componentes com visibilidade false
      const components = mapValues(keyBy(action.components), () => false);

      return {
        ...state,
        [action.name]: { ...state[action.name], ...components }
      };
    }
    case SHOW:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          [action.component]: true
        }
      };
    case HIDE:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          [action.component]: false
        }
      };
    case HIDE_ALL: {
      // seta todos os valores dentro do objeto para false
      const components = mapValues(state[action.name], () => false);

      return {
        ...state,
        [action.name]: components
      };
    }
    case RESET:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
/**
 * Registra os componentes que vão ser controlados a visibilidade
 * @param {String} name - nome do grupo
 * @param {Array} components - array com as chaves dos componentes
 */
export const registerComponents = (name, components) => ({
  type: REGISTER,
  name,
  components
});

/**
 * Mostra um componente específico
 * @param {String} name - nome do grupo
 * @param {String} component
 */
export const showComponent = (name, component) => ({
  type: SHOW,
  name,
  component
});

/**
 * Esconde um componente específico
 * @param {String} name - nome do grupo de componentes
 * @param {String} componentshowComponent
 */
export const hideComponent = (name, component) => ({
  type: HIDE,
  name,
  component
});

/**
 * Esconde todos os components
 * @param name - nome do grupo
 */
export const hideAllComponents = name => ({
  type: HIDE_ALL,
  name
});

export const show = (key, component) => dispatch => {
  dispatch(hideAllComponents(key));
  dispatch(showComponent(key, component));
};
