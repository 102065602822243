import CardImage from 'components/CardImageNew';
import { useCallback } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { WrapItem, Wrapper } from './styles';
import { SortableGalleryContext } from 'components/SortablePropertyGallery';

const Item = SortableElement(
  ({
    value,
    itemIndex,
    onRemoveImage,
    onEditCaption,
    onEditCaptionSuccess,
    handleClickOpenById,
  }) => {
    return (
      <WrapItem>
        <CardImage
          isFeatured={itemIndex === 0}
          onEditCaption={onEditCaption}
          onEditCaptionSuccess={onEditCaptionSuccess}
          image={value}
          onRemoveImage={onRemoveImage}
          onClick={handleClickOpenById}
        />
      </WrapItem>
    );
  }
);

const List = SortableContainer(
  ({
    items,
    onRemoveImage,
    onEditCaption,
    onEditCaptionSuccess,
    handleClickOpenById,
  }) => (
    <Wrapper>
      {items?.map((value, index) => (
        <Item
          itemIndex={index}
          key={value?.id}
          index={index}
          value={value}
          onRemoveImage={onRemoveImage}
          onEditCaption={onEditCaption}
          onEditCaptionSuccess={onEditCaptionSuccess}
          handleClickOpenById={handleClickOpenById(value?.id)}
        />
      ))}
    </Wrapper>
  )
);

function SortableCondoGallery({
  data,
  onSortEnd,
  onRemoveImage,
  onEditCaption,
  onEditCaptionSuccess,
  captionName = 'caption',
}) {
  const handleClickOpenById = useCallback(
    (id) => (e) => {
      // const index = data.findIndex(image => image.id === id);
      // setCurrentImage(index);
      // setIsOpen(true);
    },
    [data]
  );

  return (
    <SortableGalleryContext.Provider
      value={{
        data,
        captionName,
      }}
    >
      <List
        axis="xy"
        distance={5}
        useDragHandle
        items={data}
        onSortEnd={onSortEnd}
        onRemoveImage={onRemoveImage}
        onEditCaption={onEditCaption}
        onEditCaptionSuccess={onEditCaptionSuccess}
        handleClickOpenById={handleClickOpenById}
      />
    </SortableGalleryContext.Provider>
  );
}

export default SortableCondoGallery;
