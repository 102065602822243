import React from 'react';
import { reduxForm } from 'redux-form';
import UserAndEmail from '../../pages/UserAndEmail';
import Integrations from '../../pages/Integrations';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';
import { ITEM_TYPES } from 'services/financialv2/contracts';

function Content({ handleSubmit }) {
  const { item } = useModalContractExtra();

  const renderPage = () => {
    switch (item) {
      case ITEM_TYPES.EMAIL:
      case ITEM_TYPES.USERS:
        return <UserAndEmail />;
      default:
        return <Integrations />;
    }
  };

  return <form onSubmit={handleSubmit}>{renderPage()}</form>;
}

export default reduxForm({
  form: 'ModalContractExtra',
  forceUnregisterOnUnmount: true,
})(Content);
