import React, { useCallback } from 'react';
import _ from 'lodash';
import { ContentList } from '../List/styles';
import { ListLabels, Label } from './styles';

const labelByKey = {
  properties: 'Imóveis',
  people: 'Clientes',
  condos: 'Condomínios'
};

const Results = ({ data = {}, onClickLabel }) => {
  const handleClickLabel = useCallback(
    key => () => {
      if (onClickLabel) onClickLabel(key);
    },
    []
  );

  if (!data) return null;

  const filterData = _(data)
    .map((item, key) => ({ key, ...item }))
    .filter(({ meta }) => meta?.pagination?.total > 0)
    .value();

  if (!filterData || Object.keys(filterData).length <= 1) return null;

  return (
    <ContentList>
      <header>
        <h2>Resultados:</h2>
      </header>
      <ListLabels>
        {_.map(filterData, ({ key, meta }) => (
          <Label onClick={handleClickLabel(key)}>
            {labelByKey[key]} ({meta?.pagination?.total})
          </Label>
        ))}
      </ListLabels>
    </ContentList>
  );
};

export default Results;
