import styled from 'styled-components';

export const GroupFields = styled.div`
  display: flex;

  .FormGroup--state {
    width: 73px;
  }

  .FormGroup--city {
    flex-grow: 1;
    margin-left: 10px;
  }
`;
