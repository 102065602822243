import React, { useState, useCallback } from 'react';
import { Field } from 'redux-form';
// Components
import { Pill } from 'components/Form';
import SelectLink from 'components/SelectLink';
import { useDispatch } from 'react-redux';
import useReduxForm from 'hooks/useReduxForm';

const ALLOWED_ROOMS = ['bedroom', 'suite', 'garage'];

// Filtra só os comodos que são permitidos filtrar na pesquisa
const filterAllowedRooms = option => ALLOWED_ROOMS.indexOf(option.name) !== -1;

const Rooms = ({ options }) => {
  const dispatch = useDispatch();
  const { change } = useReduxForm();

  const [filters, setFilters] = useState({
    bedroom: 'by_room_greater_equals',
    suite: 'by_room_greater_equals',
    garage: 'by_room_greater_equals'
  });

  const reset = useCallback(() => {
    dispatch(change('by_room_equals', null));
    dispatch(change('by_room_greater_equals', null));
    dispatch(change('by_room_lower_equals', null));
  }, [dispatch]);

  const handleChange = useCallback(
    fieldName => ({ value }, prevValue) => {
      reset();
      setFilters(prevFilters => ({
        ...prevFilters,
        [fieldName]: value
      }));
    },
    []
  );

  if (!options || options?.length <= 0) return null;

  return options.filter(filterAllowedRooms).map((field, index) => (
    <Field
      key={`${field.name}${index}`}
      label={
        <div className="h-flex" style={{ width: 200 }}>
          <div className="h-flex__cell--grow">{field.title} </div>
          <div className="h-flex__cell--shrink" style={{ marginTop: '-2px' }}>
            <SelectLink
              value={filters[field.name]}
              options={[
                { label: 'Maior ou igual', value: 'by_room_greater_equals' },
                { label: 'Exatamente', value: 'by_room_equals' },
                { label: 'Menor ou igual', value: 'by_room_lower_equals' }
              ]}
              onChange={handleChange(field.name)}
            />
          </div>
        </div>
      }
      name={`${filters[field.name]}[${field.name}]`}
      component={Pill}
    />
  ));
};

export default Rooms;
