import React from 'react';
import Modal from 'react-modal';
// Services
import * as oruloService from 'services/orulo';
// Common Components
import { ModalTemplate } from 'components/Modal';
import FormOruloDisconnect from './components/FormOruloDisconnect';

function ModalOruloDisconnect({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onSubmitSuccess,
  should_change_contract,
}) {
  function onSubmit(values) {
    return oruloService.remove(values);
  }

  function handleSubmitSuccess() {
    if (onSubmitSuccess) onSubmitSuccess();
    handleClose();
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Remover integração Órulo" handleClose={handleClose}>
        <FormOruloDisconnect
          handleClose={handleClose}
          onSubmit={onSubmit}
          initialValues={{
            should_change_contract,
            should_destroy_properties: true,
          }}
          onSubmitSuccess={handleSubmitSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalOruloDisconnect;
