import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node
};
const defaultProps = {
  children: null
};

const Title = ({ children, ...rest }) => (
  <div {...rest} className="ListInfo__title">
    {children}
  </div>
);

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
