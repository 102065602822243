import React from 'react';
import { Field } from 'redux-form';
import { Input } from 'components/Form';

const FieldUrl = ({ name }) => {
  return (
    <Field
      name={name}
      component={Input}
      placeholder="Digite a url"
      normalize={value => {
        if (!value) return '';
        return value
          .toLowerCase()
          .replace(/[^-\w\s]/g, '')
          .replace(/(\s|-)+/g, '-');
      }}
    />
  );
};

export default FieldUrl;
