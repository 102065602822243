import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
// Components
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import { FieldImage, FormLabel, Textarea } from 'components/Form';
import FormSectionSEO from './components/FormSectionSEO';
import FormSectionContent from './components/FormSectionContent';

import Container from 'components/Container';
// Services
import * as postsService from 'services/sites/posts';

function FormPost({ postId, handleSubmit, initialize, change }) {
  const [pristine, setPristine] = useState(!!postId);
  const history = useHistory();

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  /**
   * Busca os dados do formulário
   * @param id
   * @returns {Promise<void>}
   */
  const fetchFormData = useCallback(
    async (id) => {
      const { data } = await postsService.getOne(id);
      initialize(data);
    },
    [initialize]
  );

  useEffect(() => {
    if (postId) {
      fetchFormData(postId);
    }
  }, [postId]);

  return (
    <form onSubmit={handleSubmit}>
      <Container style={{ marginBottom: '15px' }}>
        <Row>
          <Col xs={4}>
            <FormLabel>Imagem para capa do site</FormLabel>
            <FieldImage
              hasRemove
              proportion={275 / 370}
              bgSize="cover"
              fileUrlField="cover_file_url"
              name="cover_image"
              text={
                <>
                  Envie uma imagem <br />
                  com a medida 370x275px.
                </>
              }
              style={{ marginBottom: '15px' }}
            />
          </Col>
          <Col xs={8}>
            <Field
              label="Descrição para a capa do site"
              type="simple"
              xs={12}
              maxLength={200}
              height={220}
              xsOffset={1}
              name="description"
              component={Textarea}
            />
          </Col>
        </Row>
      </Container>
      <FormSectionContent pristine={pristine} change={change} />
      <FormSectionSEO setPristine={setPristine} />

      <FixedBar style={{ left: 256 }}>
        <FixedBar.item>
          <Button color="white" colorText="secondary" onClick={handleBack}>
            Cancelar
          </Button>
        </FixedBar.item>
        <FixedBar.spacer />
        <FixedBar.item>
          <Button tabIndex={6} type="submit" color="success">
            Salvar Postagem
          </Button>
        </FixedBar.item>
      </FixedBar>
    </form>
  );
}

export default reduxForm({
  form: 'FormPost',
  enableReinitialize: true,
})(FormPost);
