import noImage from 'assets/img/no-image.png';
import search from 'assets/img/noData/search.svg';
import Button from "components/Button";
import Nodata from "components/Nodata";
import { imageChangeSize } from "lib/image-helpers";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { handleRemoveNeighborhood, locationsSelector } from "../../module";
import TableLocations from "../TableLocations";
import { Image, Location } from "./styles";

function Neighborhoods() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { neighborhoods } = useSelector(locationsSelector);

  function handleEdit(id) {
    history.push(`/locations/update/neighborhood/id/${id}`);
  }

  function handleDelete(id) {
    dispatch(handleRemoveNeighborhood(id));
  }

  return (
    <TableLocations title="Bairros">
      {neighborhoods.length > 0 ? (
        <table className="Table">
          <tbody>
            {neighborhoods.map((item) => {
              const coverUrl = item.cover_photo?.file_url 
                ? imageChangeSize(item.cover_photo.file_url, 'medium', 'outside', {})
                : noImage

              return (
                <tr key={item.id}>
                  <td>
                    <Location>
                      <Image size="small" src={coverUrl} />
                      <span>{item.neighborhood.name} ({item.neighborhood.city.name})</span>
                    </Location>
                  </td>
                  <td className="Table__cell--small">
                    <Button
                      to={`/locations/update/neighborhood/images/${item.neighborhood.id}`}
                      color="white"
                      colorText="primary"
                    >
                      Fotos
                    </Button>
                    <Button
                      onClick={() => handleEdit(item.neighborhood.id)}
                      color="white"
                      colorText="primary"
                    >
                      Editar
                    </Button>
                    <Button
                      onClick={() => handleDelete(item.neighborhood.id)}
                      color="danger"
                    >
                      Excluir
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Nodata
          image={search}
          title="Nenhum bairro cadastrado"
          text="Descreva sobre um bairro para enriquecer o SEO"
        />
      )}
    </TableLocations>
  );
}

export default Neighborhoods;
