import { AsyncSelect } from 'components/Form';
import { useLocation } from 'containers/LocationAsyncFields/context';
import useFormValue from 'hooks/useFormValue';
import { useCallback } from 'react';
import { Field } from 'redux-form';
import Alert from 'react-s-alert';
// import { Container } from './styles';
import * as locationService from 'services/location';

const Zones = ({ label = 'Zona', ...props }) => {
  const { zoneName, cityName, setZones } = useLocation();
  const city = useFormValue(cityName);

  const getOptionByValue = useCallback(async id => {
    setZones(prevState => ({ data: prevState.data, isFetching: true }));
    const res = await locationService.getZoneByIdOnly(id);
    setZones({ data: [res.data], isFetching: false });
    return res;
  }, []);

  const fetchZones = useCallback(
    async ({ filter, offset = 1, limit = 10 }) => {
      try {
        return locationService.getZones({
          city_id: city,
          filter,
          offset,
          limit
        });
      } catch {
        Alert.success('Problema ao buscar as zonas.');
      }
    },
    [city]
  );

  const loadOptions = useCallback(
    async (search, loadedOptions, { page }) => {
      const response = await fetchZones({
        filter: { name: search },
        offset: page
      });
      const pagination = response?.meta?.pagination;

      setZones({
        data: [...loadedOptions, ...response.data]
      });

      return {
        options: response.data,
        hasMore: pagination?.current_page < pagination?.total_pages,
        additional: {
          page: page + 1
        }
      };
    },
    [city]
  );

  return (
    <Field
      key={`zones-${city}`}
      disabled={!city}
      label={label}
      name={zoneName}
      loadOptions={loadOptions}
      component={AsyncSelect}
      labelKey="name"
      valueKey="id"
      getOptionByValue={getOptionByValue}
      additional={{
        page: 1
      }}
      {...props}
    />
  );
};

export default Zones;
