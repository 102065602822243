import React from 'react';
// import { Wrapper } from './styles';

function Groups({ groups }) {
  const length = groups?.length;

  if (length > 2) {
    return (
      <p>
        <span data-tip={groups?.map(({ name }) => name).join(', ')}>
          {length} categorias
        </span>
      </p>
    );
  }

  return <p>{groups?.map(({ name }) => name).join(', ')}</p>;
}

export default Groups;
