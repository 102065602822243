import Modal from 'react-modal';

// Common Components
import { ModalTemplate } from 'components/Modal';
import { reduxForm } from 'redux-form';
import api from 'services';
import FormBooking from './FormBooking';
import FormUnbooking from './FormUnbooking';

function ModalPropertyBook({
  handleSubmit,
  property,
  submitting,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate
          isLoading={submitting}
          title={property?.is_booked ? 'Cancelar reserva' : 'Reservar imóvel'}
          handleClose={handleClose}
        >
          {property.is_booked ? (
            <FormUnbooking handleClose={handleClose} submitting={submitting} />
          ) : (
            <FormBooking handleClose={handleClose} submitting={submitting} />
          )}
        </ModalTemplate>
      </form>
    </Modal>
  );
}

export default reduxForm({
  form: 'FormPropertyBook',
  enableReinitialize: true,
  initialValues: {
    should_change_publish: false,
  },
  onSubmit: (values, dispatch, props) => {
    return api.patch(`properties/partial/${props.property.id}/booking`, {
      is_booked: !props.property.is_booked,
      ...values,
    });
  },
  onSubmitSuccess: (res, dispatch, props) => {
    props.handleClose();
  },
})(ModalPropertyBook);
