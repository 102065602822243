import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Components
import Box from 'components/Box';
import Button from 'components/Button';
import Container from 'components/Container';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
// Containers
import BillingDetails from './containers/BillingDetails';
import ContractButton from './containers/ContractButton';
import CreditCardContainer from './containers/CreditCardContainer';
import CreditsContainer from './containers/CreditsContainer';
import InfosContainer from './containers/InfosContainer';
import RemoveAccount from './containers/RemoveAccount';
// Modules
import { useCurrentUser } from 'hooks/useCurrentUser';
import { openModalExtract } from 'modules/modal';
import AccountableFinanceForm from 'pages/PlanHistory/containers/AccountableFinanceForm';
import { useHistory } from 'react-router-dom';
import { Aside } from './styles';
// Services
// Hooks

export default function PlanHistory() {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const history = useHistory();

  async function handleOpenDebits() {
    dispatch(openModalExtract());
  }

  useEffect(() => {
    if (currentUser?.real_estate?.resale?.name === 'Apple') {
      history.replace('/no-permission');
    }
  }, [currentUser]);

  return (
    <Wrapper.inner>
      <Wrapper.container style={{ maxWidth: '1024px' }}>
        <MainTitle
          title="Histórico de pagamentos"
          text="Acompanhe seu histórico de pagamentos e, se desejar, adicione mais créditos."
        >
          <Button onClick={handleOpenDebits}>Mostrar Extrato</Button>
          <ContractButton />
        </MainTitle>
        <InfosContainer />
        <Container padding style={{ marginTop: '20px', marginBottom: '40px' }}>
          <Box withBorder spacing={30}>
            <CreditsContainer />
            <Box.item
              spacing={30}
              style={{ maxWidth: '335px', paddingBottom: 0 }}
            >
              <Aside>
                <div>
                  <AccountableFinanceForm />
                  <BillingDetails />
                  <CreditCardContainer />
                </div>

                <RemoveAccount />
              </Aside>
            </Box.item>
          </Box>
        </Container>
      </Wrapper.container>
    </Wrapper.inner>
  );
}
