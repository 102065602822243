import { useRequest } from '../../../../../hooks/useRequest';
import * as crmService from '../../../../../services/crm';
import { useEffect } from 'react';
import { useFormUserId } from '../../../components/FormUser';
import { useFormFunnelId } from '../';

export const useCount = () => {
  const userId = useFormUserId();
  const funnelId = useFormFunnelId();

  const { data: countPeople, fetchData: fetchCountPeople } = useRequest({
    request: (user_id, funnel_id) =>
      crmService.getPeopleCount({
        filter: {
          by_user_id: user_id,
          by_funnel_id: funnel_id,
          having_staged_deals: true,
        },
      }),
    initialState: {},
    initialFetch: false,
  });

  const { data: countProperties, fetchData: fetchCountProperties } = useRequest(
    {
      request: (user_id, funnel_id) =>
        crmService.getPropertiesCount({
          filter: {
            by_funnel_user_id: user_id,
            by_funnel_id: funnel_id,
            on_network: 'all',
          },
        }),
      initialState: {},
      initialFetch: false,
    }
  );

  useEffect(() => {
    if (funnelId && userId) {
      fetchCountPeople(userId, funnelId);
      fetchCountProperties(userId, funnelId);
    }
  }, [funnelId, userId]);

  return { countPeople, countProperties };
};
