export const createTitle = ({ subtype }) => {
  let title = '';

  if (subtype?.type_name) {
    title += subtype?.type_name + ' ' + subtype?.label;
  }

  return title;
};

/**
 * Cria um título padrão atravéz das características do imóvel
 * @return string
 */
export const createMetaTitle = ({
  state,
  city,
  neighborhood,
  transaction,
  subtype
}) => {
  let title = '';

  if (subtype?.type_name) {
    title += subtype?.type_name + ' ' + subtype?.label;
  }

  if (transaction) {
    title += transaction === '1' ? ' à venda' : ' para alugar';
  }

  if (neighborhood?.name) {
    title += ' em ' + city.name + '/' + state.acronym;
  }

  return title;
};
