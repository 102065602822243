import Button from 'components/Button';
import Container from 'components/Container';
import FormCharacteristics from 'components/FormCharacteristics';
import ListCheck from 'components/ListCheck';
import MainTitle from 'components/MainTitle';
import Popover from 'components/Popover';
import { USER_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';
import {
  characteristicsSelector,
  getCharacteristics,
  handleFormCharacteristic,
} from 'modules/condosCharacteristics';
import { openModalCharacteristicRemoveCondo } from 'modules/modal';
import { useId } from 'pages/CondoStore/hooks/useId';
import { useCallback, useEffect, useRef } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { Checkbox } from 'components/Form';
import { Row } from 'react-flexbox-grid';

function CondoCharacteristics() {
  const { id: condoId } = useId();

  const characteristics = useSelector(characteristicsSelector);

  const dispatch = useDispatch();

  const handleAddCondoCharacteristic = useCallback(
    (values) => {
      dispatch(
        handleFormCharacteristic({
          id: condoId,
          ...values,
        })
      );
    },
    [condoId]
  );

  const handleDeleteCharacteristic = useCallback(
    (characteristic) => () => {
      dispatch(
        openModalCharacteristicRemoveCondo({
          characteristic,
          onSubmitSuccess: () => {
            dispatch(getCharacteristics());
          },
        })
      );
    },
    []
  );

  useEffect(() => {
    dispatch(getCharacteristics());
  }, [condoId]);

  const characteristicsRef = useRef();

  // useEffect(() => {
  //   setTimeout(() => {
  //     characteristicsRef.current.scrollIntoView({ behavior: 'smooth' })
  //   }, 1000);
  // }, []);

  return (
    <section id="characteristics" ref={characteristicsRef}>
      <MainTitle
        title="Características do condomínio/empreendimento"
        text="Defina as características deste condomínio/empreendimento"
      >
        <Can run={USER_PERMISSIONS.EDIT_REGISTRATIONS}>
          <Popover
            onSubmit={handleAddCondoCharacteristic}
            component={FormCharacteristics}
          >
            <Button color="secondary">
              <MdAdd /> Adicionar característica do condomínio
            </Button>
          </Popover>
        </Can>
      </MainTitle>

      <Container>
        <ListCheck
          cols={4}
          name="condos_characteristics"
          data={characteristics}
          handleDeleteItem={handleDeleteCharacteristic}
          params={{ condoId }}
        />
        <Row style={{ margin: '15px 0 0' }}>
          <Field
            xs={12}
            type="checkbox"
            responsive={false}
            name="update_properties_characteristics"
            component={Checkbox}
            color="secondary"
            colorOn="#fff"
            colorOff="#fff"
            label={
              <span className="h-color--white">
                Ao salvar, aplicar para todos os imóveis ligados a este
                condomínio
              </span>
            }
          />
        </Row>
      </Container>
    </section>
  );
}

export default CondoCharacteristics;
