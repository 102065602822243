import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// Components
import PropertiesTable from '../PropertiesTable';
import PropertiesTableGroundArea from '../PropertiesTableGroundArea';
// Services
import { transformPropertiesToTypes } from 'services/condos';

const Wrapper = styled.div`
  width: calc(100% + 39px);
  padding-top: 15px;
  margin-top: -15px;
  margin-left: -19px;
  margin-bottom: 25px;

  .Table tr th,
  .Table tr td {
    background: transparent;
  }

  h4 {
    margin-left: 20px;
  }

  &:nth-child(even) {
    background: #f1f1f1;
  }
`;

const defaultProps = {
  toggleSelected: null,
  canSelect: null,
  properties: null,
  handleOpenProperty: null
};

const propTypes = {
  property: PropTypes.shape({
    condominium_id: PropTypes.string.isRequired,
    type: PropTypes.shape({
      title: PropTypes.string
    })
  }).isRequired,
  toggleSelected: PropTypes.bool,
  canSelect: PropTypes.bool,
  properties: PropTypes.arrayOf(PropTypes.object),
  handleOpenProperty: PropTypes.func
};

const getTableByType = typeName => {
  switch (typeName) {
    case 'Terreno':
      return PropertiesTableGroundArea;
    default:
      return PropertiesTable;
  }
};

function Record({
  toggleSelected,
  canSelect,
  properties,
  handleOpenProperty,
  editted
}) {
  const types = transformPropertiesToTypes(properties);

  return (
    <div style={{ marginTop: '-29px', marginBottom: '-45px' }}>
      {types.map(type => {
        const Table = getTableByType(type.title);

        return (
          <Wrapper key={type?.typeId}>
            <h4>{type?.title}</h4>
            <Table
              editted={editted}
              canSelect={canSelect}
              toggleSelected={toggleSelected}
              handleOpenProperty={handleOpenProperty}
              properties={type.properties}
            />
          </Wrapper>
        );
      })}
    </div>
  );
}

Record.defaultProps = defaultProps;
Record.propTypes = propTypes;

export default Record;
