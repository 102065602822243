import { Col, Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';

// Components
import Button from 'components/Button';
import Divider from 'components/Divider';
import { FormBoxHeader } from 'components/FormBox';
import MapContainer from '../MapContainer';
import location from './location.png';
import { LoadMap, MapWrapper } from './styles';

const Map = ({ isMapShown, canShowMap, setCanShowMap }) => {
  return (
    <>
      <Field type="hidden" name="maps_latitude" component="input" />
      <Field type="hidden" name="maps_longitude" component="input" />
      <Field type="hidden" name="maps_heading" component="input" />
      <Field type="hidden" name="maps_pitch" component="input" />
      <Field type="hidden" name="maps_zoom" component="input" />

      <Row>
        <Col xs={12}>
          <Divider borderStyle="solid" style={{ margin: 0 }} />
        </Col>
      </Row>

      {isMapShown && (
        <MapWrapper>
          <Row>
            <Col xs={12}>
              <FormBoxHeader size="small">
                Mapa
              </FormBoxHeader>
            </Col>
          </Row>

          {canShowMap ? (
            <MapContainer isExactPlace />
          ) : (
            <LoadMap>
              <img src={location} alt="Mapa" />
              <Button onClick={() => setCanShowMap(true)}>Carregar mapa</Button>
            </LoadMap>
          )}
        </MapWrapper>
      )}
    </>
  );
};

export default Map;
