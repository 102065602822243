import { REDIRECT_URI } from 'constants/config';
import Alert from 'react-s-alert';
import api from './index';
// Helpers
import { responseMessage } from 'lib/service-helpers';

/**
 * Status dos eventos
 * 1 - Aberto
 * 2 - Finalizado
 * 3 - Cancelado
 */
export const EVENT_OPEN = 1;
export const EVENT_FINISHED = 2;
export const EVENT_CANCELED = 3;

export const setOauthCode = (code) =>
  api.create('oauth/calendars', {
    code,
    destination: REDIRECT_URI,
  });

/**
 * Desconecta da agenda do google calendar do usuário logado
 * @return {*|Promise<AxiosResponse<T>>}
 */
export const disconnect = () => api.delete('oauth/calendars');

export const getList = (params) => api.getList('calendars', params);
export const getSearch = (params) =>
  api.getSearch('calendars', {
    limit: '',
    offset: '',
    ...params,
  });
export const getOne = (id, params) => api.getOne('calendars', id, params);
export const create = async (data) => {
  try {
    // const { data: person } = await peopleService.getOne(data.people_id);

    return api
      .create('calendars', {
        ...data,
        // summary: data.summary + ' - ' + getFirstName(person.name),
        timezone: new Date().getTimezoneOffset() * -1,
      })
      .then(responseMessage('Evento criado'));
  } catch {
    Alert.success('Erro ao criar evento');
  }
};

export const update = (data) =>
  api
    .update('calendars', data)
    .then(responseMessage('Evento alterado'))
    .catch(responseMessage('Erro ao alterar evento'));
export const remove = (id) =>
  api.delete('calendars', id).then(responseMessage('Evento removido'));
export const reactivate = (id) =>
  api
    .reactivate('calendars', id)
    .then(() => api.update('calendars', { id, status: EVENT_OPEN }));
export const cancelEvent = (id) =>
  api
    .update('calendars', { id, status: EVENT_CANCELED })
    .then(() => remove(id));
export const finishEvent = (id) =>
  api
    .update('calendars', { id, status: EVENT_FINISHED })
    .then(() => remove(id));

/**
 * Pega todos os eventos pelo id
 * @param personId
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getEventsByPersonId = (personId, params) =>
  api.getList(`people/${personId}/calendars`, params);
