import api from './';

/**
 * Pega o log de ações de um cliente específico
 * @param person - cliente que você precisa listar o histórico
 * @param params - configurações da rota
 * @return {Promise} - Retorna uma lista de audits
 */
export const getListPersonAudits = (person, params) =>
  api.getList(`people/${person.id}/audits`, params);

/**
 * Pega o log de ações de um imóvel específico
 * @param property
 * @param params
 * @return {Promise} - Retorna uma lista de audits
 */
export const getListPropertyAudits = (property, params) =>
  api.getList(`properties/${property.id}/audits`, params);

/**
 * Pega o log de ações da área de negócios
 * @param deal
 * @param params
 * @return {Promise}
 */
export const getListDealsAudits = (deal, params) =>
  api.getList(`crm/deals/${deal.id}/audits`, params);
