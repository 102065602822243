import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;

  .Modal__footer {
    margin-top: -15px !important;
  }
`;

export const BoxLeft = styled.div`
  flex-shrink: 0;
  padding: 20px 20px 30px 0;
  margin-top: -20px;
  margin-bottom: -20px;

  width: 50%;
`;

export const BoxRight = styled.div`
  padding: 20px 20px 30px 0;
  width: calc(50% + 20px);
  margin-right: -20px;
  margin-top: -20px;
  margin-bottom: -20px;
`;

export const Form = styled.form`
  .Modal__footer {
    border-top: 1px solid #dedede;
  }
`;

export const Box = styled.div`
  padding: 20px;
  flex-shrink: 0;
  & + & {
    border-top: 1px solid #dedede;
  }
`;

export const WrapSeeForm = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -17px 0 -12px;
  position: relative;
  z-index: 100;
`;

export const SeeFullForm = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: block;
  align-self: flex-end;
  padding: 10px 15px;
  color: ${(p) => p.theme.colors.secondary};
  text-decoration: underline;
  cursor: pointer;

  :hover {
    color: #000;
    background: rgba(0, 0, 0, 0.1);
  }
`;
