import React from 'react';
import CardProperty from 'components/CardProperty';
import { getName } from 'lib/text';
import moment, { format } from 'lib/moment';
import PropertiesList from 'components/PropertiesList';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  openModalProperty,
  openModalReservationRemove,
  openModalReservationUpdate,
} from 'modules/modal';
import Button from 'components/Button';
import { MdArrowDropDown } from 'react-icons/md';
import Dropdown, { Item, List } from 'components/Dropdown';
import Nodata from 'components/Nodata';
import house from 'assets/img/noData/house.svg';
import Loading from 'components/Loading';
// import { Wrapper } from './styles';

const Reservation = styled.div`
  line-height: 1.4em;
  width: 260px;
  margin-right: 20px;
`;

const Wrap = styled.div`
  display: flex;
  align-items: flex-start;

  .Button {
    margin-bottom: 0;
  }
`;

function BookedList({ data, isFetching, filter, fetchData }) {
  const dispatch = useDispatch();

  const onClickProperty = (booking) => () => {
    dispatch(
      openModalProperty({
        property: booking.property,
      })
    );
  };

  const handleUpdate = (reservation) => () => {
    // const newReservation = omit(reservation, 'property');

    dispatch(
      openModalReservationUpdate({
        reservation,
        onClose: true,
        afterClose: () => {
          fetchData({ filter });
        },
      })
    );
  };

  const handleRemove = (reservation) => () => {
    dispatch(
      openModalReservationRemove({
        reservation,
        property: reservation.property,
        afterClose: () => {
          fetchData({ filter });
        },
      })
    );
  };

  const isBookingExpired = (booking) => {
    // Caso não esteja com data de reserva vai estar sempre ativa
    if (booking.booked_until === null) return false;

    const today = moment().startOf('day');
    const bookedUntil = moment(booking.booked_until, format.date);
    const diff = bookedUntil.diff(today, 'days');

    return diff <= 0;
  };

  if (!data || data.length <= 0)
    return <Nodata image={house} title="Nenhum imóvel reservado" text="" />;

  return (
    <>
      <PropertiesList
        canShowStages
        getProperty={(booking) => booking.property}
        data={data}
        itemRenderer={({ type, canShowStages }) =>
          (booking) => {
            const isExpired = isBookingExpired(booking);

            return (
              <CardProperty
                canShowStages={canShowStages}
                property={booking.property}
                type={type}
                handleClickProperty={onClickProperty(booking)}
              >
                <Wrap>
                  <Reservation onClick={onClickProperty(booking)}>
                    <p>
                      <strong>Dados da reserva</strong>
                    </p>
                    <p>
                      Corretor:{' '}
                      {booking?.booking_user?.name
                        ? getName(booking?.booking_user?.name)
                        : '-'}
                    </p>
                    <p>
                      Cliente:{' '}
                      {booking?.person?.name
                        ? getName(booking?.person?.name)
                        : '-'}
                    </p>
                    <p>
                      Reserva:{' '}
                      {moment(booking?.created_at, format.datetime).format(
                        format.date
                      )}
                      {booking?.booked_until
                        ? ` à ${booking?.booked_until}`
                        : null}
                    </p>
                    <p>
                      Exibindo no site:{' '}
                      {booking?.property?.is_published ? 'Sim' : 'Não'}
                    </p>
                    <p>
                      <strong
                        className={`h-color--${
                          isExpired ? 'danger' : 'success'
                        }`}
                      >
                        {isExpired ? 'Expirada' : 'Ativa'}
                      </strong>
                    </p>
                  </Reservation>
                  <Dropdown isDropHover>
                    <Button color="white" colorText="primary">
                      Opções <MdArrowDropDown />
                    </Button>
                    <List>
                      <Item>
                        <button type="button" onClick={handleUpdate(booking)}>
                          Editar
                        </button>
                      </Item>
                      <Item>
                        <button type="button" onClick={handleRemove(booking)}>
                          Deletar
                        </button>
                      </Item>
                    </List>
                  </Dropdown>
                </Wrap>
              </CardProperty>
            );
          }}
      />
      <Loading isVisible={isFetching} isFullScreen />
    </>
  );
}

export default BookedList;
