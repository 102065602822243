import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
// Components
import Box from 'components/FilterBox';
import { Input, Select } from 'components/Form';
import GroupControl, { GroupItem } from 'components/GroupControl';

const propTypes = {};
const defaultProps = {};

const comparision = [
  { label: 'Maior que', value: 'greater' },
  { label: 'Menor que', value: 'lower' },
  { label: 'Igual', value: 'equals' }
];

const FloorField = ({ byFloorComparsion }) => (
  <Box title="Andar do imóvel">
    <GroupControl required={false}>
      <GroupItem size="small">
        <Field
          autoWidth
          name="by_floor_comparision"
          placeholder=""
          labelKey="label"
          valueKey="value"
          options={comparision}
          component={Select}
        />
      </GroupItem>
      <GroupItem>
        <Field name={`by_floor_${byFloorComparsion}`} component={Input} />
      </GroupItem>
    </GroupControl>
  </Box>
);

FloorField.propTypes = propTypes;
FloorField.defaultProps = defaultProps;

const selector = formValueSelector('FilterProperties');

const mapStateToProps = state => ({
  byFloorComparsion: selector(state, 'by_floor_comparision')
});

export default connect(mapStateToProps)(FloorField);
