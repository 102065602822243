import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';
import { Field, formValueSelector } from 'redux-form';
// Modules
import { getPeople, peopleSelector } from 'modules/people';
import { normalizeDate } from 'lib/formHelpers';
// Components
import Label from 'components/Label';
import { Input, Select, Textarea, Toggle } from 'components/Form';
import Divider from 'components/Divider';
import FormBox from 'components/FormBox';
// Helpers
import { isValidDate } from 'lib/date-helpers';
import moment from 'lib/moment';
// Constants
import { BOOL } from 'constants/options';
import FieldPerson from 'containers/FieldPerson';
import { openModalPersonAdd } from 'modules/modal';

const defaultProps = {
  people: [{}],
  canSendResume: false,
  isCombinedComission: false
};

const propTypes = {
  people: PropTypes.arrayOf(PropTypes.object),
  changeField: PropTypes.func.isRequired,
  canSendResume: PropTypes.bool,
  isCombinedComission: PropTypes.bool
};

const LabelRevision = ({ children, ...props }) => (
  <Label {...props} type="button" className="h-margin-right--5">
    {children}
  </Label>
);

class PrivateData extends React.Component {
  componentDidMount() {
    this.props.getPeople();
  }

  get isGround() {
    try {
      return this.props.subtype.group.title === 'Terreno';
    } catch {
      return false;
    }
  }

  handleChangeExclusive = () => {
    this.props.changeField('exclusive_until', null);
  };

  handleChangeHasKey = () => {
    this.props.changeField('keys_location', null);
  };

  handleChangeComission = e => {
    this.props.changeField('negotiation_note', null);
  };

  getDate(field) {
    const dateField = this.props[field];

    return isValidDate(dateField) ? dateField : moment().format('DD/MM/YYYY');
  }

  handleAddDays = (field, days) => () => {
    const { changeField } = this.props;
    const date = this.getDate(field);

    changeField(
      field,
      moment(date, 'DD/MM/YYYY')
        .add(days, 'days')
        .format('DD/MM/YYYY')
    );
  };

  handleInsertPerson = ({ name }) => {
    this.props.openModalPersonAdd({ name }, null, {
      checkGroupOwner: true
    });
  };

  render() {
    const { is_commission_combined, is_exclusive } = this.props;

    return (
      <FormBox isPrivate title="Dados Privados" className="js-privateData">
        <Row>
          <FieldPerson
            xs={3}
            required
            label="Proprietário"
            onClickNew={this.handleInsertPerson}
            checkGroupOwner
            onCreatePerson={person => {
              // Atualiza a lista de clientes
              this.props.getPeople();
              this.props.changeField('people_id', person.id);
            }}
          />
        </Row>
        <Row>
          <Col xs={12}>
            <Divider spacingY={5} borderStyle="dashed" />
          </Col>
          <FormBox.header size="small">Financeiro</FormBox.header>
          <Field
            xs={3}
            buttonTemplate
            label="Comissão Combinada"
            name="is_commission_combined"
            onChange={this.handleChangeComission}
            options={BOOL}
            component={Select}
          />
          <Field
            xs={9}
            growOnFocus
            growHeight={250}
            label="Observação sobre a negociação"
            name="negotiation_note"
            component={Textarea}
            disabled={!is_commission_combined}
          />
        </Row>

        <Row>
          <Col xs={12}>
            <Divider spacingY={5} borderStyle="dashed" />
          </Col>
          <FormBox.header size="small">Imóvel</FormBox.header>
          <Field
            xs={3}
            label="Matrícula"
            placeholder="Digite o número"
            name="matriculation"
            component={Input}
          />
          <Field
            xs={3}
            label="Imposto Territorial Rural"
            name="itr"
            component={Input}
          />
        </Row>

        <Row>
          <Col xs={12}>
            <Divider spacingY={5} borderStyle="dashed" />
          </Col>
          <FormBox.header size="small">Observações</FormBox.header>
          <Field
            xs={6}
            label="Dados sobre ocupação/desocupação"
            growOnFocus
            growHeight={250}
            name="occupation_note"
            component={Textarea}
          />
          <Field
            xs={6}
            growOnFocus
            growHeight={250}
            label="Observação privada sobre o imóvel"
            name="private_note"
            component={Textarea}
          />
          {!this.isGround && (
            <Field
              xs={6}
              growOnFocus
              growHeight={250}
              label="Descrição da mobília"
              name="furniture_note"
              component={Textarea}
            />
          )}
        </Row>
        <Row>
          <Col xs={12}>
            <Divider spacingY={5} borderStyle="dashed" />
          </Col>
          <FormBox.header size="small">Compromissos</FormBox.header>
        </Row>
        <Row>
          <Col xs={0}>
            <Field
              label="Autorização formalizada?"
              name="has_owner_authorization"
              component={Toggle}
            />
          </Col>
          <Col xs={0}>
            <Field label="Com placa" name="has_sale_card" component={Toggle} />
          </Col>
          <Col xs={0}>
            <Row>
              <Col xs={0}>
                <Field
                  label="Exclusividade"
                  name="is_exclusive"
                  component={Toggle}
                  onChange={this.handleChangeExclusive}
                />
              </Col>
              {is_exclusive && (
                <Col xs={0}>
                  <Field
                    label="Até quando?"
                    name="exclusive_until"
                    component={Input}
                    normalize={normalizeDate}
                  />
                  <Col className="h-margin-bottom--10">
                    <LabelRevision
                      handleClick={this.handleAddDays('exclusive_until', 30)}
                    >
                      +30
                    </LabelRevision>
                    <LabelRevision
                      handleClick={this.handleAddDays('exclusive_until', 60)}
                    >
                      +60
                    </LabelRevision>
                    <LabelRevision
                      handleClick={this.handleAddDays('exclusive_until', 90)}
                    >
                      +90
                    </LabelRevision>
                    dias
                  </Col>
                </Col>
              )}
            </Row>
          </Col>
          <Col xs={0}>
            <Field
              xs={12}
              label="Próxima data de revisão"
              name="next_review_at"
              component={Input}
              normalize={normalizeDate}
            />
            <Col xs={12} className="h-margin-bottom--10">
              <LabelRevision
                handleClick={this.handleAddDays('next_review_at', 30)}
              >
                +30
              </LabelRevision>
              <LabelRevision
                handleClick={this.handleAddDays('next_review_at', 60)}
              >
                +60
              </LabelRevision>
              <LabelRevision
                handleClick={this.handleAddDays('next_review_at', 90)}
              >
                +90
              </LabelRevision>
              dias
            </Col>
          </Col>
        </Row>
        <Row>
          <Field
            xs={3}
            buttonTemplate
            label="Chave disponível?"
            name="is_keys_ready"
            onChange={this.handleChangeHasKey}
            component={Select}
            options={BOOL}
          />
          <Field
            xs={9}
            label="Onde pegar?"
            name="keys_location"
            component={Input}
          />
        </Row>
      </FormBox>
    );
  }
}

PrivateData.defaultProps = defaultProps;
PrivateData.propTypes = propTypes;

const selector = formValueSelector('propertiesAdd');

const mapStateToProps = state => ({
  subtype: selector(state, 'subtype_id'),
  people: peopleSelector(state),
  canSendResume: selector(state, 'can_send_resume'),
  next_review_at: selector(state, 'next_review_at'),
  is_commission_combined: selector(state, 'is_commission_combined'),
  exclusive_until: selector(state, 'exclusive_until'),
  isKeysReady: selector(state, 'is_keys_ready'),
  is_exclusive: selector(state, 'is_exclusive')
});

const mapDispatchToProps = {
  getPeople,
  openModalPersonAdd
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateData);
