import React from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Components
import GroupControl, { GroupItem } from 'components/GroupControl';
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import ListOptions from 'components/ListOptions';
import Container from 'pages/Site/components/ContainerSite';
import {
  FieldBool,
  FieldImage,
  Input,
  InputDate,
  Select,
} from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import { parseValueBool } from 'lib/formHelpers';
import FieldSelectUser from 'containers/FieldSelectUser';

const CustomLabel = styled.label`
  font-weight: 400;
  color: ${(p) => p.theme?.colors?.primary};
`;

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormPopup = ({ handleSubmit, submitting }) => {
  const trigger = useFormValue('trigger');
  const is_form_shown = useFormValue('is_form_shown');

  const isTimeout = trigger === 1 || trigger === '1';

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xsOffset={1} xs={5}>
          <FieldImage
            label="Popup Desktop"
            text={
              <>
                <p className="h-color--white">Recomendado:</p>
                <p className="h-color--white h-text-nowrap">
                  No máximo: 1200px de largura
                </p>
                <p className="h-color--white">No máximo: 600px de altura</p>
              </>
            }
            name="image"
            className="h-margin-bottom--15"
          />
        </Col>
        <Col xs={5}>
          <FieldImage
            label="Popup Mobile"
            name="image_mobile"
            fileUrlField="mobile_file_url"
            text={
              <>
                <p className="h-color--white">Recomendado:</p>
                <p className="h-color--white h-text-nowrap">
                  No máximo: 600px de largura
                </p>
              </>
            }
            className="h-margin-bottom--15"
          />
        </Col>
      </Row>
      <Container>
        <ListOptions>
          <ListOptions.item
            title="Mostrar Popup"
            text="Mostrar popup na página?"
            renderOptions={() => (
              <Field
                buttonTemplate
                canRemoveSelf={false}
                name="active"
                component={Select}
                options={[
                  { value: true, label: 'Mostrar' },
                  { value: false, label: 'Esconder', _color: 'danger' },
                ]}
              />
            )}
          />
          <ListOptions.item title="Título da pop-up">
            <Field
              className="h-margin-top--5"
              name="title"
              placeholder="Digite o título"
              component={Input}
            />
          </ListOptions.item>
          <ListOptions.item title="Subtítulo da pop-up">
            <Field
              className="h-margin-top--5"
              name="subtitle"
              placeholder="Digite o subtítulo"
              component={Input}
            />
          </ListOptions.item>
          <ListOptions.item title="Link da Imagem">
            <Row style={{ marginTop: '5px' }}>
              <Field
                xs={8}
                label={
                  <CustomLabel>
                    Diga para qual página redirecionar ao clicar na imagem.
                  </CustomLabel>
                }
                name="link"
                component={Input}
              />
              <Field
                xs={4}
                label="Abrir link na:"
                name="opens_link_on_page"
                component={Select}
                placeholder=""
                options={[
                  { label: 'Mesma página', value: true },
                  { label: 'Outra página', value: false },
                ]}
                parse={parseValueBool}
              />
            </Row>
          </ListOptions.item>
          <ListOptions.item
            title="Formulário de coleta de dados"
            text="Ativar um formulário que coleta telefone, e-mail e nome do cliente."
            renderOptions={() => (
              <>
                {is_form_shown && (
                  <FieldSelectUser
                    label={null}
                    name="contact_form_user_id"
                    style={{ width: 200 }}
                    clearable={false}
                    placeholder="Corretor responsável"
                    params={{
                      filter: {
                        realtor: true,
                      },
                      sort: 'by_name',
                    }}
                  />
                )}
                <FieldBool name="is_form_shown" />
              </>
            )}
          />
          <ListOptions.item title="Link do formulário">
            <Row style={{ marginTop: '5px' }}>
              <Field
                xs={8}
                label={
                  <CustomLabel>
                    Diga para qual página redirecionar o cliente após clicar no
                    botão 'enviar'.
                  </CustomLabel>
                }
                name="form_link"
                component={Input}
              />
              <Field
                xs={4}
                label="Abrir link na:"
                name="form_opens_link_on_page"
                component={Select}
                placeholder=""
                options={[
                  { label: 'Mesma página', value: true },
                  { label: 'Outra página', value: false },
                ]}
                parse={parseValueBool}
              />
            </Row>
          </ListOptions.item>
          <ListOptions.item
            title="Gatilho para ativar pop-up"
            text="Escolha em que momento a pop-up deve ser ativada."
            renderOptions={() => (
              <>
                <Field
                  name="trigger"
                  options={[
                    { label: 'Depois de "X" segundos', value: 1 },
                    { label: 'Ao rolar a página', value: 2 },
                    { label: 'Ao sair do site', value: 3 },
                  ]}
                  placeholder=""
                  labelKey="label"
                  valueKey="value"
                  component={Select}
                />
                {isTimeout && (
                  <GroupControl
                    required={false}
                    style={{ width: '155px', marginLeft: '5px' }}
                  >
                    <GroupItem>
                      <Field type="number" name="timeout" component={Input} />
                    </GroupItem>
                    <GroupItem label="segundos" size="small" />
                  </GroupControl>
                )}
              </>
            )}
          />
          <ListOptions.item
            title="Pop-up com validade?"
            text="Sua pop-up será desativada automaticamente na data desejada."
            renderOptions={() => (
              <>
                <FieldBool label="&nbsp;" name="has_expiration" />
                <Field
                  label="Data de expiração da pop-up:"
                  name="expiry_date"
                  component={InputDate}
                  minDate={new Date()}
                />
              </>
            )}
          />
        </ListOptions>
      </Container>
      <FixedBar style={{ left: 240 }}>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar
        </Button>
      </FixedBar>
    </form>
  );
};

FormPopup.defaultProps = defaultProps;
FormPopup.propTypes = propTypes;

export default reduxForm({
  form: 'FormPopup',
  enableReinitialize: true,
})(FormPopup);
