import React, { useCallback } from 'react';
import classnames from 'classnames';
import { isDirty } from 'redux-form';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { Wrapper, Title } from './styles';
import { ListAside as List, Item, Ball } from 'components/ListAside';
import { useSelector } from 'react-redux';
import { FORM_NAME } from 'pages/EnterpriseStore/constants';

const AsideWrapper = () => {
  const dirty = useSelector(isDirty(FORM_NAME));
  const { currentPage, pagesArray, setPage } = useEnterprise();

  const handleClickItem = useCallback(
    key => () => {
      if (dirty) {
        if (
          !window.confirm(
            'Deseja realmente trocar de página? seus dados não serão salvos'
          )
        ) {
          return false;
        }
      }
      setPage(key);
    },
    [setPage, dirty]
  );

  return (
    <Wrapper className="Aside js-aside-enterprise">
      <Title>Passo a passo:</Title>
      <List>
        {pagesArray.map(({ key, name }, index) => (
          <Item
            className={classnames({
              isActive: currentPage === key
            })}
            key={key + index}
            isActive={currentPage === key}
            onClick={handleClickItem(key)}
          >
            <Ball /> <div>{name}</div>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

export default AsideWrapper;
