// Modules
import dataReducer from 'modules/data';
import { filterOrAll } from '../helpers';
// Services
import { getPeoplePerChannel } from 'services/reports';

export const {
  reducer: peoplePerChannelReducer,
  actionCreators: peoplePerChannelActions,
  selectors: peoplePerChannelSelectors
} = dataReducer(`reports/peoplePerChannel`);

export const chartDataSelector = filterOrAll(peoplePerChannelSelectors);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(peoplePerChannelActions.request());

  getPeoplePerChannel(params).then(res => {
    dispatch(peoplePerChannelActions.receive(res.data));

    return res.data;
  });
};

export const toggleChecked = item => dispatch => {
  dispatch(peoplePerChannelActions.toggleSelected(item.id));
};
export default peoplePerChannelReducer;
