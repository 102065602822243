import React from 'react';
// Components
import Wrapper from 'components/Wrapper';
// Routes
import Routes from './Routes';
import RoutesResales from '../HelpResales/Routes';
import { useIsTecimob } from 'containers/IsTecimob';

export const Help = () => {
  const isTecimob = useIsTecimob();
  return <Wrapper>{isTecimob ? <Routes /> : <RoutesResales />}</Wrapper>;
};

export default Help;
