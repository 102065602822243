import { createElement, useEffect } from 'react';
import Tooltip from 'react-tooltip';
import PropTypes from 'prop-types';

const propTypes = {
  limit: PropTypes.number.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired,
  itemRenderer: PropTypes.func.isRequired,
  emptyRenderer: PropTypes.func.isRequired,
  keyExtractor: PropTypes.func.isRequired
};

const defaultProps = {
  limit: 0,
  data: null,
  itemRenderer: null,
  emptyRenderer: () => null,
  keyExtractor: el => el.id
};

/**
 * Renderiza todos os itens
 * @param data
 * @param itemRenderer
 * @param keyExtractor
 * @param rest
 */

const filterLimit = limit => limit > 0 && ((data, i) => i < limit);

const renderItems = ({ data, limit, itemRenderer, keyExtractor, ...rest }) => {
  if (Array.isArray(data)) {
    let newData = data;

    if (limit) {
      newData = data.filter(filterLimit(limit));
    }

    return newData.map(d =>
      createElement(itemRenderer, { key: keyExtractor(d), ...d, ...rest })
    );
  }

  return Object.keys(data).map(key =>
    createElement(itemRenderer, { key, ...data[key], ...rest })
  );
};

const ForEach = ({ emptyRenderer, ...props }) => {
  useEffect(() => {
    Tooltip.rebuild();
  }, [props.data]);

  return props.data ? renderItems(props) : emptyRenderer(props);
};

ForEach.propTypes = propTypes;
ForEach.defaultProps = defaultProps;

export default ForEach;
