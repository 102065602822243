import React from 'react';
import { Wrapper, Title, Header } from './styles';
import SelectDays from '../SelectDays';
import Loading from 'components/Loading';

function Graph({ title, children, onChangeDays, isFetching }) {
  return (
    <Wrapper>
      <Loading isFullComponent isVisible={isFetching} />
      <Header>
        <Title>{title}</Title>
        <SelectDays defaultDays={15} onChange={onChangeDays} />
      </Header>
      {children}
    </Wrapper>
  );
}

export default Graph;
