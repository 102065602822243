import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NotificationsSocket from 'lib/Socket/Notifications';
import {
  Actions,
  clearAllNotifications,
  fetchNotifications,
  filterNotifications,
  readNotification,
  receiveAllNotifications,
} from 'modules/notifications';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { getColorByTag } from 'components/Notification/helpers';

export const useNotifications = ({ onLoadMore }) => {
  const [filter, setFilter] = useState(null);

  const { notifications, meta, pagination } = useSelector(
    (state) => state.notifications
  );
  const dispatch = useDispatch();

  const hasMore = useMemo(() => {
    return meta?.pagination?.total_pages > meta?.pagination?.current_page;
  }, [meta]);

  const filters = useMemo(() => {
    let filtersArray = [];
    try {
      const { counts } = meta;

      if (counts?.['new-contact']) {
        filtersArray = [
          ...filtersArray,
          {
            title: 'Contato',
            tag: 'new-contact',
            value: counts?.['new-contact'],
            color: getColorByTag('new-contact'),
            isSelected: filter === 'new-contact',
          },
        ];
      }

      if (counts?.['new-matched']) {
        filtersArray = [
          ...filtersArray,
          {
            title: 'Compatíveis',
            tag: 'new-matched',
            value: counts?.['new-matched'],
            color: getColorByTag('new-matched'),
            isSelected: filter === 'new-matched',
          },
        ];
      }

      if (counts?.['new-feature'] || counts?.others) {
        let newFeature = counts?.['new-feature']
          ? parseInt(counts?.['new-feature'], 10)
          : 0;
        let others = counts.others ? parseInt(counts.others, 10) : 0;

        filtersArray = [
          ...filtersArray,
          {
            title: 'Outros',
            tag: 'others',
            value: newFeature + others,
            color: getColorByTag('others'),
            isSelected: filter === 'new-feature' || filter === 'others',
          },
        ];
      }

      return filtersArray;
    } catch {
      return [];
    }
  }, [meta, filter]);

  const handleReadNotification = useCallback(
    (id) => () => {
      dispatch(readNotification(id));
    },
    []
  );

  const readAll = useCallback(
    ({ filter }) => {
      dispatch(receiveAllNotifications(filter));
      setFilter(null);
    },
    [dispatch]
  );

  const clearAll = useCallback(
    ({ filter, onSuccess }) => {
      dispatch(
        openConfirmation({
          title: 'Limpar todas as notificações?',
          request: async () => {
            dispatch(clearAllNotifications(filter));
            setFilter(null);
            return false;
          },
          onSuccess,
        })
      );
    },
    [dispatch]
  );

  /**
   * Carrega mais notificações
   * @type {function(): void}
   */
  const loadMore = useCallback(async () => {
    let _filter = {};

    if (filter) {
      _filter = {
        tag: filter,
      };
    }

    await fetchNotifications({
      limit: meta?.pagination?.per_page,
      offset: meta?.pagination?.current_page + 1,
      filter: _filter,
    })(dispatch);

    if (onLoadMore) onLoadMore();
  }, [meta, onLoadMore, filter]);

  useEffect(() => {
    // Limpa todas as notificações
    dispatch(Actions.resetNotifications());

    if (!!filter) {
      // Pega todas as notificações
      dispatch(
        filterNotifications({
          offset: 1,
          limit: 10,
          filter: {
            tag: filter,
          },
        })
      );
    } else {
      // Pega todas as notificações
      dispatch(
        fetchNotifications({
          offset: 1,
          limit: 10,
        })
      );
    }
  }, [filter]);

  useEffect(() => {
    const notificationSocket = new NotificationsSocket();

    notificationSocket.on('new', (data) => {
      dispatch(Actions.receiveNotification(data));

      if (Notification.permission === 'granted') {
        new Notification(data.title, {
          vibrate: true,
          body: data?.message,
        });
      }
    });

    return () => {
      notificationSocket.disconnect();
    };
  }, []);

  const requestPermission = () => {
    try {
      Notification.requestPermission();
    } catch {
      return null;
    }
  };

  useEffect(() => {
    requestPermission();
  }, []);

  return {
    notifications,
    meta,
    pagination,
    handleReadNotification,
    loadMore,
    hasMore,
    readAll,
    clearAll,
    filters,
    setFilter,
    filter,
  };
};
