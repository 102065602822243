import ModalTitle from './components/ModalTitle';
import ModalSubtitle from './components/ModalSubtitle';
import ModalContent from './components/ModalContent';
import ModalClose from './components/ModalClose';
import ModalFooter from './components/ModalFooter';
import ModalTemplate from './components/ModalTemplate';

export {
  ModalTitle,
  ModalSubtitle,
  ModalContent,
  ModalFooter,
  ModalClose,
  ModalTemplate
};
