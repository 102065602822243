import React from 'react';
import { Wrapper, Divider, Text } from './styles';
import { plural } from 'lib/text';

function Crm({ property }) {
  const countStages = property?.stages?.length;

  if (countStages <= 0) return null;

  return (
    <Wrapper>
      <Divider>{' | '}</Divider>
      <Text>{plural(countStages, 'Posição no CRM', 'Posições no CRM')}</Text>
    </Wrapper>
  );
}

export default Crm;
