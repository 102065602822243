import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 25px 15px 0;

  img {
    margin: 0 auto 10px;
  }

  .Button {
    margin-top: 15px;
  }
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const Text = styled.div`
  font-size: 14px;
`;
