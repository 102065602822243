import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdFormatListBulleted, MdViewColumn } from 'react-icons/md';

// Modules
import { Actions, MODES, viewModeSelector } from '../../reducer';

// Styles
import { Button, ButtonGroup } from './styles';
import WizardCrmLayout from 'containers/Wizards/WizardCrmLayout';

function LayoutChooser() {
  const dispatch = useDispatch();
  const viewMode = useSelector(viewModeSelector);

  const isHorizontal = useMemo(() => MODES.horizontal === viewMode, [viewMode]);

  const handleChangeViewMode = useCallback(
    (mode) => () => {
      dispatch(Actions.setViewType(mode));
    },
    [dispatch]
  );

  return (
    <div>
      <ButtonGroup className="js-layout-chooser">
        <Button
          onClick={handleChangeViewMode(MODES.horizontal)}
          isActive={isHorizontal}
        >
          <MdFormatListBulleted />
        </Button>
        <Button
          onClick={handleChangeViewMode(MODES.vertical)}
          isActive={!isHorizontal}
        >
          <MdViewColumn />
        </Button>
      </ButtonGroup>
      <WizardCrmLayout />
    </div>
  );
}

export default LayoutChooser;
