import React from 'react';
import Modal from 'react-modal';
// Common Components
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';

function ModalHasPayment({
  onConfirm,
  modalConfig,
  isOpen,
  handleClose,
  modalType
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Alerta de pagamento duplicado"
        handleClose={handleClose}
      >
        <p>
          Já temos um registro de um pagamento seu no dia de hoje. Gostaria de
          realmente proceder com um novo pagamento?
        </p>
        <ModalFooter>
          <Button color="success" onClick={onConfirm}>
            Pagar mesmo assim
          </Button>
          <span className="h-flex__cell--grow" />
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalHasPayment;
