import { FieldBool } from 'components/Form';
import Loading from 'components/Loading';
import MainTitle from 'components/MainTitle';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { withId } from 'pages/CondoStore/HOC/withId';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { Watermark } from './styles';
import * as condosService from 'services/condos';
import { useParams } from 'react-router';
import FooterActions from 'pages/CondoStore/components/FooterActions';
import { useCallback, useEffect } from 'react';
import Gallery from './components/Gallery';
import { GalleryProvider } from './components/Gallery/context';
import { usePath } from 'pages/CondoStore/hooks/usePath';
import Alert from 'components/Alert';

function Images({ handleSubmit, onSubmit, submitting, initialize }) {
  const { id } = useParams();

  const { setPath } = usePath();

  useEffect(() => {
    setPath('/images');
  }, []);

  const initializeForm = useCallback(async () => {
    const { data } = await condosService.getOne(id);

    initialize({
      should_apply_watermark: data.should_apply_watermark,
    });
  }, [initialize, id]);

  useEffect(() => {
    if (id) initializeForm();
  }, [id]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Loading isVisible={submitting} isFullComponent />
        <MainTitle
          title="Imagens"
          text="Envie fotos da estrutura do condomínio/empreendimento"
        >
          <Watermark className="h-flex h-flex--center-center">
            <div>Aplicar marca d'água?</div>
            <FieldBool
              name="should_apply_watermark"
              style={{ marginBottom: 0 }}
              onChange={(value) => {
                condosService.updateImageArea(id, {
                  should_apply_watermark: value,
                });
              }}
            />
          </Watermark>
        </MainTitle>
        <Alert color="danger">
          Estas imagens serão incluidas automaticamente ao final da galeria de
          fotos dos imóveis ligados a este condomínio.
        </Alert>
        <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} isLast />
      </form>
      <GalleryProvider>
        <Gallery />
      </GalleryProvider>
    </>
  );
}

export default compose(
  withId,
  reduxForm({
    form: 'CondoImages',
    onSubmit: (values, dispatch, props) => {
      props.history.push('/properties/condos');
      return false;
    },
  }),
  withFormConfirmation()
)(Images);
