// import styles from './styles'

import { Aside } from '../Aside';
import * as S from './styles';

export function Layout({ children }) {
  return (
    <S.Wrapper>
      <Aside />
      <S.Content>{children}</S.Content>
    </S.Wrapper>
  );
}
