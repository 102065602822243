import styled from 'styled-components';

export const Title = styled.p`
  color: #fff;
  font-size: 10px;
  margin-bottom: 2px;
`;

export const Text = styled.p`
  color: #fff;
  font-size: 12px;
  font-weight: bold;
`;
