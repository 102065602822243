import React from 'react';
import Button from 'components/Button';

const TableDocuments = () => {
  return (
    <table className="Table">
      <thead>
        <tr>
          <th>Nome</th>
          <th width={1}>Opções</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Autorização de venda</td>
          <td>
            <Button
              size="medium"
              color="white"
              to="/config/documents/sales-authorization"
            >
              Editar
            </Button>
          </td>
        </tr>
        <tr>
          <td>Autorização de locação</td>
          <td>
            <Button
              size="medium"
              color="white"
              to="/config/documents/rent-authorization"
            >
              Editar
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableDocuments;
