import React from 'react';
import { Col } from 'react-flexbox-grid';
import { SortableElement } from 'react-sortable-hoc';
import CardImage from '../../../CardImageNew';

const GalleryItem = SortableElement(
  ({
    data: {
      id,
      order,
      file_url,
      caption,
      isFeatured,
      isEditing,
      isFetching,
      imageAngle
    },
    data,
    cols,
    handleToggleEdit,
    handleSubmitEdit,
    handleChangeRotation,
    handleClickOpenById,
    handleDeleteImage,
    hasFeatured
  }) => (
    <Col {...cols}>
      <CardImage image={data} />
    </Col>
  )
);

export default GalleryItem;
