import React, { useMemo } from 'react';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Wrapper, Overlay, Background, Content } from './styles';
import Dot from './components/Dot';
import { COLORS, SIZES } from './constants';
import { getColor } from 'lib/color-helpers';
import { colors } from 'constants/styles';
import { MdCheck } from 'react-icons/md';

const Tag = ({
  isChecked,
  color = COLORS.PRIMARY,
  colorText = null,
  size = SIZES.MEDIUM,
  onClick,
  handleClick,
  children,
  className,
  style,
  noTransparency,
  ...props
}) => {
  const currentColor = useMemo(() => {
    if (color) return getColor(color) || colors.primary;
  }, [color]);

  return (
    <ThemeProvider
      theme={{ colorText, colorName: color, color: currentColor, size }}
    >
      <Wrapper
        {...props}
        className={classnames('Label', className)}
        onClick={handleClick || onClick}
        style={style}
        noTransparency={noTransparency}
      >
        <Overlay>
          <Background />
          <Dot color={currentColor} noTransparency={noTransparency} />
        </Overlay>
        <Content>
          {children} {isChecked && <MdCheck />}
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
};

Tag.propTypes = {
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'tertiary',
    'success',
    'danger',
    'caution',
  ]),
  colorText: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  noTransparency: PropTypes.bool,
};

export default Tag;
