import axios from 'axios';
import { store } from '../';
// Modules
import { refreshAccessToken } from 'modules/google';
// Services
import * as googleService from 'services/google';

const analyticsV4 = axios.create({
  baseURL: 'https://analyticsreporting.googleapis.com/v4/'
});

const analyticsV3 = axios.create({
  baseURL: 'https://www.googleapis.com/analytics/v3/'
});

const responseSuccess = res => res;
const responseReject = instance => async err => {
  const {
    config,
    response: {
      status,
      data: {
        error: { message }
      }
    }
  } = err;
  const { refresh_token } = store.getState().google;

  if (refresh_token && status === 401) {
    if (message === 'Invalid Credentials') {
      throw err;
    }
    try {
      // Atualiza o refresh token
      const { access_token } = await refreshAccessToken(refresh_token);

      return instance.request({
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${access_token}`
        }
      });
    } catch {
      await googleService.removeAnalytics();
      throw err;
    }
  }

  throw err;
};

analyticsV4.interceptors.response.use(
  responseSuccess,
  responseReject(analyticsV4)
);

analyticsV3.interceptors.response.use(
  responseSuccess,
  responseReject(analyticsV3)
);

export const search = (data, config) =>
  analyticsV4.post('reports:batchGet', data, config);

/**
 * Pegar as contas
 * @param params
 * @param config
 * @returns {AxiosPromise<any>}
 */
export const getAccounts = (params, config) => {
  return analyticsV3.get('management/accounts', {
    params,
    ...config
  });
};

/**
 * Pega todas as propriedades de uma conta
 * @param accountId
 * @param params
 * @param config
 * @returns {AxiosPromise<any>}
 */
export const getProperties = (accountId, params, config) =>
  analyticsV3.get(`management/accounts/${accountId}/webproperties`, {
    params,
    ...config
  });

/**
 * Busca os perfils de uma determinada propriedade
 * @param accountId
 * @param propertyId
 * @param params
 * @param config
 * @returns {AxiosPromise<any>}
 */
export const getProfiles = (accountId, propertyId, params, config) =>
  analyticsV3.get(
    `management/accounts/${accountId}/webproperties/${propertyId}/profiles`,
    {
      params,
      ...config
    }
  );

export { analyticsV4, analyticsV3 };
