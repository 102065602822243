import Dropdown, { Item, List } from 'components/Dropdown';
import FixedBar from 'components/FixedBar';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { plural } from 'lib/text';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { change } from 'redux-form';
import * as portalsService from 'services/settings/portals';

// const LinkWithIcon = ({ icon, iconColor, title, text, onClick }) => (
//   <Item>
//     <button onClick={onClick}>
//       <div className="h-flex h-flex--center-center">
//         <IconBall type="rounded" size="large" color={iconColor}>
//           {icon()}
//         </IconBall>
//         <div className="h-margin-left--15">
//           <h5>{title}</h5>
//           <p>{text}</p>
//         </div>
//       </div>
//     </button>
//   </Item>
// );

function SelectOption() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    selectedProperties,
    fetchPortals,
    selectedPortal,
    clearSelectedProperties,
  } = usePortalOptions();

  // Limpa todos os campos da pesquisa
  const handleClearSearch = (option) => {
    // Pega os parametros da url
    const searchParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    searchParams.filter_portals.by_portal_option_id = option.portal_option_id;

    // remove os filtros da url
    if (searchParams.filter) {
      delete searchParams.filter;
    }

    // remove os counts da url
    if (searchParams.counts) {
      delete searchParams.counts;
    }

    history.replace({
      pathname: history.location.pathname,
      search: qs.stringify(searchParams, { addQueryPrefix: false }),
    });

    dispatch(change('PropertiesPortalForm', 'reference', ''));
    dispatch(change('PropertiesPortalForm', 'condominium_id', ''));
    dispatch(change('PropertiesPortalForm', 'street_address', ''));
  };

  const syncIds = (option) => () => {
    dispatch(
      openConfirmation({
        title: `Atribuição de imóveis`,
        text: `Deseja atribuir ${plural(
          selectedProperties?.length,
          'imóvel',
          'imóveis'
        )} ao portal ${selectedPortal.name}`,
        request: () =>
          portalsService.addProperties({
            portalRealEstateId: option.portal_real_estate_id,
            portalRealEstateOptionId: option.id,
            propertiesId: selectedProperties,
          }),
        submitButtonColor: 'success',
        submitButtonText: `Atribuir para: ${option.name}`,
        onSuccess: () => {
          fetchPortals();
          clearSelectedProperties();
          handleClearSearch(option);
        },
      })
    );
  };

  return (
    <FixedBar.item style={{ marginLeft: '50px' }}>
      <Dropdown isDropHover position="center-top" className="Dropdown--header">
        <FixedBar.link tagName="button">Atribuir selecionados</FixedBar.link>
        <List>
          {selectedPortal?.options?.map((option) => (
            <Item>
              <button onClick={syncIds(option)}>{option.name}</button>
            </Item>
          ))}
        </List>
      </Dropdown>
    </FixedBar.item>
  );
}

export default SelectOption;
