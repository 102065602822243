import React, { useContext } from 'react';
import { MdApps } from 'react-icons/md';
import Menus from 'components/Menus';
import Dropdown from '../Dropdown';
import { MenuItem } from '../../styles';
import { MenusContext } from 'components/Menus/context';

const MenuPrimary = () => {
  const { menu, back } = useContext(MenusContext);

  return (
    <Dropdown position="right" component={Menus}>
      {({ isVisible, setIsVisible, close }) => (
        <MenuItem
          className="js-main-menu"
          onClick={() => {
            if (!isVisible) return setIsVisible(true);

            if (menu === 'MenuDefault') {
              close();
            } else {
              back();
            }
          }}
        >
          <MdApps /> Menu
        </MenuItem>
      )}
    </Dropdown>
  );
};

export default MenuPrimary;
