import CardProperty from 'components/CardProperty';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import Pagination from 'components/Pagination';
import house from 'containers/ModalRoot/ModalPerson/components/Properties/pages/PropertiesSended/house-send.svg';
import { useRequest } from 'hooks/useRequest';
import * as peopleService from 'services/people';
import Button from 'components/Button';
import { openModalAddDeal } from 'modules/modal';
import { useDispatch } from 'react-redux';
// import { Container } from './styles';

const STATUS = {
  NEW: 1,
  SENDED: 2,
  DISCARDED: 3,
};

const PropertiesSended = ({ person, openModalProperty, handleOpenMySelf }) => {
  const dispatch = useDispatch();

  const {
    data: matcheds,
    meta,
    fetchData,
    isFetching,
  } = useRequest({
    request: (params) =>
      peopleService.getPeopleMatched(person.id, {
        filter: { by_status: STATUS.SENDED },
        sort: '-created_at,id',
        include: 'user',
        offset: 1,
        limit: 40,
        ...params,
      }),
  });

  if (isFetching) return <Loading isVisible />;

  if (matcheds.length === 0) {
    return (
      <Nodata
        style={{ maxWidth: 255 }}
        image={house}
        title="Nenhum imóvel enviado."
        text="Quando algum imóvel for enviado para o seu cliente, ele aparecerá aqui."
      />
    );
  }

  return (
    <>
      {matcheds.map((matched, i) => {
        return (
          <CardProperty
            key={`${i}-${matched.id}`}
            type="horizontal"
            property={matched.property}
            handleClickProperty={() => {
              openModalProperty({
                property: matched.property,
                afterClose: () => {
                  handleOpenMySelf();
                },
              });
            }}
            message={`Enviado em: ${matched?.sent_at} por ${matched?.user?.name}`}
          >
            {matched.has_deal === false && (
              <Button
                color="success"
                onClick={() => {
                  dispatch(
                    openModalAddDeal({
                      userId: matched?.user_id,
                      initialValues: {
                        people_id: person?.id,
                        property_id: matched?.property_id,
                      },
                      onSuccess: () => {
                        handleOpenMySelf();
                      },
                    })
                  );
                }}
              >
                Adicionar ao CRM
              </Button>
            )}
          </CardProperty>
        );
      })}
      <Pagination
        pagination={meta?.pagination}
        onPageChange={({ selected }) => {
          fetchData({ offset: selected + 1 });
        }}
      />
    </>
  );
};

export default PropertiesSended;
