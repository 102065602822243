import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

function ListItem({ isActive, children, onClick, renderOption }) {
  return (
    <div
      onClick={onClick}
      className={classnames('ListAside__item h-hover', { isActive })}
    >
      <div className="ListAside__text">{children}</div>
      {renderOption && (
        <div className="ListAside__options h-hover__show">{renderOption()}</div>
      )}
    </div>
  );
}

ListItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  renderOption: PropTypes.func
};

ListItem.defaultProps = {
  children: null,
  onClick: null,
  renderOption: null
};

export default ListItem;
