import { useState, useEffect, useCallback } from 'react';
import Store, { TypesInstance } from 'lib/Store';
import { handleAddWizard } from 'modules/login';
import { useDispatch } from 'react-redux';

export const useReception = ({ key, canShowAgain = false }) => {
  const dispatch = useDispatch();
  const [showAgain, setShowAgain] = useState(canShowAgain);
  const [isVisible, setVisibility] = useState(false);

  const show = useCallback(() => {
    setVisibility(true);
  }, [setVisibility]);

  const hide = useCallback(() => {
    setVisibility(false);
  }, [setVisibility]);

  const setStorage = useCallback(() => {
    if (showAgain) {
      dispatch(handleAddWizard(key));
      return;
    }

    // Adiciona no sessionStorage
    Store.set(key, true, TypesInstance.session);
  }, [key, showAgain]);

  const handleClose = useCallback(
    e => {
      e.preventDefault();
      setStorage();
      hide();
    },
    [setStorage, hide]
  );

  useEffect(() => {
    async function didMount() {
      const sessionField = await Store.get(key, TypesInstance.session);

      if (sessionField) {
        hide();
      } else {
        !(await Store.get(key)) ? show() : hide();
      }
    }

    didMount();

    return () => {
      hide();
    };
  }, [show, hide]);

  return {
    showAgain,
    setShowAgain,
    isVisible,
    hide,
    show,
    handleClose
  };
};
