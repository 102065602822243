import React, { useState, useCallback } from 'react';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import FormGroup from '../FormGroup';
import { Label, Text, Percentage } from './styles';

const Slider = ({
                  required,
                  maxLength,
                  formGroupClassName,
                  label,
                  disabled,
                  meta,
                  input,
                  onChange,
                  ...anotherProps
                }) => {
  const [percentage, setPercentage] = useState(input.value || 0);

  const handleChange = useCallback(value => {
    setPercentage(value);
  }, []);

  const handleAfterChange = useCallback(value => {
    if (onChange) onChange(value);
    input.onChange(value);
  }, []);

  return (
    <FormGroup
      className={formGroupClassName}
      maxLength={maxLength}
      isRequired={required}
      isDisabled={disabled}
      input={input}
      meta={meta}
      labelRender={() => (
        <Label>
          <Text>{label}</Text>
          <Percentage className="h-color--primary-light">
            {percentage}%
          </Percentage>
        </Label>
      )}
    >
      <RcSlider
        {...anotherProps}
        trackStyle={{ backgroundColor: '#0084F4', height: 6 }}
        handleStyle={{
          borderColor: '#A1D1FB',
          height: 16,
          width: 16,
          backgroundColor: '#A1D1FB'
        }}
        railStyle={{
          background: '#D3DCEB'
        }}
        onChange={handleChange}
        onAfterChange={handleAfterChange}
        value={percentage}
      />
    </FormGroup>
  );
};

export default Slider;
