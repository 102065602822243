import React, { memo, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Pages
import Config from './pages/Config';
import ContentSite from './pages/ContentSite';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { withPermission } from 'HOC/withPermission';
import Layout from 'pages/Site/pages/Layout';

const Routes = () => {
  const { permissions } = useCurrentUser();

  const redirectUrl = useMemo(() => {
    if (permissions.includes('EDIT_SITE_APPEARANCE')) return '/site/layout';
    if (permissions.includes('EDIT_SITE')) return '/site/config';

    return '/';
  }, [permissions]);

  if (!permissions) return null;

  return (
    <Switch>
      <Route
        path="/site/config"
        exact={false}
        component={withPermission({ rules: ['EDIT_SITE'], isPage: true })(
          Config
        )}
      />
      <Route
        path="/site/content-site"
        exact={false}
        component={withPermission({
          rules: ['EDIT_SITE_APPEARANCE'],
          isPage: true,
        })(ContentSite)}
      />
      <Route path="/site/layout" exact={false} component={Layout} />
      <Redirect to={redirectUrl} />
    </Switch>
  );
};

export default memo(Routes);
