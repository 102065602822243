import Informations from './Informations';
import Characteristics from './Characteristics';
import CharacteristicsCondo from './CharacteristicsCondo';
import Establishments from './Establishments';
import Rooms from './Rooms';
import Location from './Location';
import Measures from './Measures';
import Financial from './Financial';
import Description from './Description';
import Complements from './Complements';
import CadastralSituation from './CadastralSituation';
import ImagesArea from './ImagesArea';
import Publish from './Publish';
import Private from './Private';

export default {
  Informations,
  Characteristics,
  CharacteristicsCondo,
  Establishments,
  Rooms,
  Location,
  Measures,
  Financial,
  Description,
  Complements,
  CadastralSituation,
  ImagesArea,
  Publish,
  Private,
};
