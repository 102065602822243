import React from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
  children: null,
  actions: null,
};

const propTypes = {
  children: PropTypes.node,
  actions: PropTypes.node,
};

const ModalTitle = ({ children, text, actions }) => (
  <div className={`Modal__header ${actions && 'h-flex h-flex--center-center'}`}>
    <div>
      <h4 className={'Modal__title'}>{children}</h4>
      {text && <p className="Modal__text h-margin-top--5">{text}</p>}
    </div>
    {actions && (
      <>
        <span className="h-flex__cell--grow" />
        {actions}
      </>
    )}
  </div>
);

ModalTitle.defaultProps = defaultProps;
ModalTitle.propTypes = propTypes;

export default ModalTitle;
