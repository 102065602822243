import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
// Constants
import { COLORS_PRIMARY } from '../../constants/config';

const propTypes = {
  size: PropTypes.number,
  color: PropTypes.oneOf(COLORS_PRIMARY),
  className: PropTypes.string,
  animation: PropTypes.oneOf([false, 'pulse'])
};

const defaultProps = {
  size: 8,
  color: 'danger',
  animation: 'pulse',
  className: undefined,
  style: undefined
};

const Ball = ({ size, color, style, animation, className, ...rest }) => (
  <div
    {...rest}
    style={{ width: size, height: size, ...style }}
    className={classnames('Ball', className, {
      [`h-bg--${color}`]: color,
      [`Ball--${animation}`]: animation
    })}
  />
);

Ball.propTypes = propTypes;
Ball.defaultProps = defaultProps;

export default Ball;
