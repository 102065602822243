import React from 'react';
import { PROPERTY_PERMISSIONS } from 'constants/rules';
import UploadingFiles from 'containers/UploadingFiles';
import Dropdown from 'components/Dropdown';
import Alert from 'react-s-alert';
import Can from 'containers/Can';
// import { Wrapper } from './styles';

function DuplicateProperty({
  property,
  handleOpenModalDuplicate,
  dropdownItemText = 'Duplicar Imóvel',
  disabledMessage = 'Não é possível duplicar imóvel enquanto estiver fazendo upload de fotos',
}) {
  return (
    <Can run={PROPERTY_PERMISSIONS.EDIT} permissions={property.permissions}>
      <UploadingFiles>
        {(files) => {
          let isDisabled = false;
          if (files.length > 0) {
            isDisabled = true;
          }

          return (
            <Dropdown.item>
              <button
                onClick={(e) => {
                  if (isDisabled) {
                    e.preventDefault();
                    Alert.success(disabledMessage);
                    return false;
                  }
                  handleOpenModalDuplicate(property)(e);
                }}
              >
                {isDisabled ? (
                  <span style={{ textDecoration: 'line-through' }}>
                    {dropdownItemText}
                  </span>
                ) : dropdownItemText}
              </button>
            </Dropdown.item>
          );
        }}
      </UploadingFiles>
    </Can>
  );
}

export default DuplicateProperty;
