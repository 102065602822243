import React, { memo, useCallback, useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
// Modules
import {
  characteristicsSelector,
  handleFormCharacteristic,
  getCharacteristics,
  characteristicsMetaSelector,
} from 'modules/characteristics';
// Components
import Button from 'components/Button';
import FormBox from 'components/FormBox';
import Popover from 'components/Popover';
import ListCheck from 'components/ListCheck';
import FormCharacteristics from 'components/FormCharacteristics';
import Can from 'containers/Can';
import { USER_PERMISSIONS } from 'constants/rules';
import { detectVisibility, undetectVisibility } from 'lib/helpers';
import Loading from 'components/Loading';

function Characteristics({ typeId, isVisible }) {
  const dispatch = useDispatch();
  const characteristics = useSelector(characteristicsSelector);
  const { isFetching } = useSelector(characteristicsMetaSelector);

  /**
   * Cadastro de uma nova característica
   * @param typeId|String
   */
  const onSubmitCharacteristic = useCallback(
    values => dispatch(handleFormCharacteristic(typeId, values)),
    [typeId]
  );

  const handleFocusPage = useCallback(() => {
    const handle = detectVisibility(isVisible => {
      if (!isVisible) {
        dispatch(getCharacteristics(typeId));

        undetectVisibility(handle);
      }
    });
  }, [typeId, dispatch]);

  useEffect(() => {
    if (isVisible) {
      Tooltip.rebuild();
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <FormBox
      title="Características do imóvel"
      className="js-characteristics"
      actions={
        <Can
          run={USER_PERMISSIONS.EDIT_CHARACTERISTICS}
          yes={() => (
            <>
              <Button
                size="medium"
                className="h-margin-right--15"
                tagName="a"
                href="/config/registrations/characteristics"
                onClick={handleFocusPage}
                target="_blank"
              >
                Gerenciar
              </Button>
              <Popover
                onSubmit={onSubmitCharacteristic}
                component={FormCharacteristics}
              >
                <Button color="secondary">Cadastrar Característica</Button>
              </Popover>
            </>
          )}
          no={() => (
            <span data-tip="Você não tem permissão para gerenciar características">
              <Button disabled>Cadastrar Característica</Button>
            </span>
          )}
        />
      }
    >
      <Loading isVisible={isFetching} isFullComponent />
      <ListCheck
        name="characteristics"
        data={characteristics}
        params={{ typeId }}
      />
    </FormBox>
  );
}

export default memo(Characteristics);
