import PropertyPerPeriod from './PropertyPerPeriod';
import PropertyPricePeriod from './PropertyPricePeriod';
import PropertyPerTypeTransaction from './PropertyPerTypeTransaction';
import OruloPerTypeTransaction from './OruloPerTypeTransaction';
import DwvPerTypeTransaction from './DwvPerTypeTransaction';

import PeoplePerChannel from './PeoplePerChannel';
import PeoplePerPeriod from './PeoplePerPeriod';
import PeopleByRealtor from './PeopleByRealtor';
import PeopleByChannelAndType from './PeopleByChannelAndType';

import DealPerChannel from './DealPerChannel';
import DealPerBroker from './DealPerBroker';
import DealPerReasonLoss from './DealPerReasonLoss';
import DealLoss from './DealLoss';
import DealWon from './DealWon';

import BillingPerTypeTransaction from './BillingPerTypeTransaction';
import BillingPerBroker from './BillingPerBroker';
import BillingPerCommission from './BillingPerCommission';
import BillingPerRealEstateCommission from './BillingPerRealEstateCommission';

import Analytics from './Analytics';
import AnalyticsNewUsers from './AnalyticsNewUsers';
import AnalyticsVisits from './AnalyticsVisits';

import AdminInfo from './AdminInfo';

export default {
  Analytics,
  PropertyPerPeriod,
  PropertyPricePeriod,
  OruloPerTypeTransaction,
  DwvPerTypeTransaction,
  PropertyPerTypeTransaction,
  PeoplePerPeriod,
  PeopleByRealtor,
  PeopleByChannelAndType,
  PeoplePerChannel,
  DealPerChannel,
  DealPerBroker,
  DealPerReasonLoss,
  DealLoss,
  DealWon,
  BillingPerTypeTransaction,
  BillingPerBroker,
  BillingPerCommission,
  BillingPerRealEstateCommission,
  AnalyticsNewUsers,
  AnalyticsVisits,
  AdminInfo,
};
