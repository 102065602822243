import React, { Component } from 'react';
import Button from '../../../../../components/Button';
import Table from '../../../../../components/Table';
// import PropTypes from 'prop-types';

const defaultProps = {};
const propTypes = {};

class Shipping extends Component {
  render() {
    return (
      <div>
        <Table
          data={[
            {
              id: '1',
              data: '28/07/17',
              name: 'Vilson Luiz Back',
              user: 'Marcus de Souza Marcos'
            }
          ]}
          isFetching={false}
          renderTitle={() => (
            <tr>
              <th>Data do envio</th>
              <th>Por</th>
              <th>Para</th>
              <th>E-mail</th>
            </tr>
          )}
          renderItem={({ id, data, name, user }) => (
            <tr key={id}>
              <td>{data}</td>
              <td>{name}</td>
              <td>{user}</td>
              <td>
                <Button color="white" size="medium">
                  Opções
                </Button>
              </td>
            </tr>
          )}
        />
      </div>
    );
  }
}

Shipping.defaultProps = defaultProps;
Shipping.propTypes = propTypes;

export default Shipping;
