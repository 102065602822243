import { useChart } from 'containers/GraphVisitsSite/hooks/useChart';
import { useMemo } from 'react';
import moment from 'lib/moment';

export const useTimeseries = () => {
  const { data, fetchData: fetchTimeseries, response } = useChart('timeseries');

  const timeseries = useMemo(() => {
    try {
      return data?.map((timeserie) => {
        return {
          ...timeserie,
          date_formated: moment(timeserie.date, 'DD/MM/YYYY').format('MMM DD'),
        };
      });
    } catch {
      return null;
    }
  }, [data]);

  return { timeseries, fetchTimeseries, response };
};
