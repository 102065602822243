import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
import { FieldBool, Textarea } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

function Form({ handleSubmit, handleClose }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <FieldBool
          xs={6}
          label="Publicar o imóvel no Site?"
          name="should_change_publish"
          style={{ width: 130 }}
        />
      </Row>
      <Row>
        <Field
          label="Observações sobre o encerramento da reserva"
          type="simple"
          xs={12}
          name="closing_note"
          component={Textarea}
        />
      </Row>
      <ModalFooter withBorder>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormReservationCreate',
})(Form);
