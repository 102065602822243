import React, { useCallback, useState, useEffect } from 'react';
import { Field } from 'redux-form';
import Alert from 'react-s-alert';
import { AsyncSelect } from 'components/Form';
import { getCondos } from 'services/properties';
import { getOne } from 'services/condos';

function FieldCondominium({
  label = 'Condomínio',
  filter: filterProp,
  hasOrulo,
  name,
  hideEmpty,
  params: paramsProps,
  ...props
}) {
  const [hasData, setHasData] = useState(false);
  const [params, setParams] = useState(paramsProps);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCondos({ filter: filterProp, limit: 1, offset: 1 }).then((res) => {
      const total = res?.meta?.pagination?.total;

      if (total > 0) {
        setHasData(true);
      }
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    if (hasOrulo) {
      setParams({
        ...paramsProps,
        on_network: ['orulo', 'self'],
        ...filterProp,
      });
    } else {
      setParams(paramsProps);
    }
  }, [hasOrulo]);

  async function fetchData({ filter, offset = 1, limit = 5 }) {
    try {
      return getCondos({
        ...params,
        filter: { ...filter, ...filterProp },
        offset,
        limit,
      });
    } catch {
      Alert.success('Problema ao buscar os condomínios');
    }
  }

  const getOptionByValue = useCallback((id) => {
    return getOne(id);
  }, []);

  const loadOptions = useCallback(
    async (search, loadedOptions, { page }) => {
      const response = await fetchData({
        filter: { by_main_search: search, ...params },
        offset: page,
      });

      const pagination = response?.meta?.pagination;

      return {
        options: response.data,
        hasMore: pagination?.current_page < pagination?.total_pages,
        additional: {
          page: page + 1,
        },
      };
    },
    [params]
  );

  if (hideEmpty && !hasData) return null;

  return (
    <Field
      isLoading={isLoading}
      label={label}
      name="condominium_id"
      loadOptions={loadOptions}
      labelKey="title"
      valueKey="id"
      component={AsyncSelect}
      getOptionByValue={getOptionByValue}
      additional={{
        page: 1,
        hasOrulo,
      }}
      {...props}
    />
  );
}

export default FieldCondominium;
