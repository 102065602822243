import React, { useCallback, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { MdHome } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Wrapper, Content, IconWrapper, Container, Title, Text } from './styles';
import { DropdownContext } from 'components/MainHeader3/components/Dropdown';

const MenuItem = ({
  to,
  href,
  target = null,
  color = '#1A051D',
  icon: Icon = MdHome,
  title,
  text,
  onClick
}) => {
  const { close } = useContext(DropdownContext);

  const handleClick = useCallback(
    e => {
      if (onClick) {
        onClick(e);
      } else {
        if (close) close();
      }
    },
    [onClick]
  );

  return (
    <ThemeProvider theme={{ color }}>
      <Wrapper
        as={href ? 'a' : to ? Link : 'button'}
        href={href}
        to={to}
        target={target}
        onClick={handleClick}
      >
        <Content>
          <IconWrapper>{Icon && <Icon />}</IconWrapper>
          <Container>
            <Title>{title}</Title>
            <Text>{text}</Text>
          </Container>
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
};

export default MenuItem;
