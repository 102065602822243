import api from 'services';
import { responseMessage } from 'lib/service-helpers';

/**
 * @typedef Variable
 * @type {object}
 * @property {string} title - Título da variavel
 * @property {string} variable - variavel eg.: PESSOA.NOME
 */

/**
 * @typedef Groups
 * @type {object}
 * @property {string} title - Nome do grupo da variavel
 * @property {Variable[]} - Variáveis
 */

/**
 * Retorna todas as variavéis que são permitidas
 *
 * @return {Promise<AxiosResponse<Groups>>}
 */
export const getVariables = () =>
  api.getOne('settings/sales-authorizations/variables');

/**
 * Pega os dados do formulário de template
 * @return {Promise<AxiosResponse<Groups>>}
 */
export const getFormData = () => api.getOne('settings/sales-authorizations');

/**
 * Update template
 * @param values
 * @return {Promise<AxiosResponse<Groups>>}
 */
export const updateTemplate = values =>
  api
    .create('settings/sales-authorizations', { ...values })
    .then(responseMessage('Template atualizado!'));

/**
 * Imprimir autorização de venda
 * @param values
 * @return {Promise<AxiosResponse<any>>}
 */
export const print = values =>
  api.create('settings/sales-authorizations/print', values);
