import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  font-family: Inter, sans-serif;
  display: flex;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  margin: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.05);

  ${(p) =>
    p.theme.isStagnant &&
    css`
      background: #fef2f0;
    `}
`;
export const Content = styled.div`
  flex-grow: 1;
`;

export const PersonName = styled.div`
  line-height: 21px;
  margin-bottom: 5px;
  color: #21233c;
  font-size: 14px;
  font-weight: 600;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  flex-shrink: 0;
  min-width: 1px;
`;

export const Action = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #fff;
  display: grid;
  place-items: center;
  margin-top: 10px;
`;

export const StagnantDays = styled.span`
  padding: 0 6px 0 6px;
  background: #d83c38;
  border-radius: 8px;
  min-width: 14px;
  line-height: 16px;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  margin-top: 10px;
`;
