import React, { useMemo } from 'react';
import ListOptions from 'components/ListOptions';
import Container from 'components/Container';
import { FaWhatsapp } from 'react-icons/fa';
import { Box, Center, Close, Inner, WhatsappIcon, Wrapper } from './styles';
import { MdClose } from 'react-icons/md/index';

function Preview({ value, contacts }) {
  const image = useMemo(() => {
    try {
      if (contacts.length === 1) {
        return contacts[0].file_url;
      }

      return false;
    } catch {
      return false;
    }
  }, [contacts]);

  return (
    <Container padding style={{ marginBottom: '20px' }}>
      <ListOptions>
        <ListOptions.item
          title="Prévia"
          text="Veja como a frase ficará no seu site"
        >
          <Center>
            <div>
              <Wrapper>
                <Box>
                  <Inner>
                    <Close size={'14px'}>
                      <MdClose />
                    </Close>
                    {value}
                  </Inner>
                </Box>
                <WhatsappIcon size="18px" image={image}>
                  <FaWhatsapp />
                </WhatsappIcon>
              </Wrapper>
            </div>
          </Center>
        </ListOptions.item>
      </ListOptions>
    </Container>
  );
}

export default Preview;
