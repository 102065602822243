import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const WrapperLink = styled(NavLink)`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 6px;
  color: #242424;
  margin-left: -6px;
  border-radius: 5px;

  &.isActive {
    font-weight: bold;
  }

  :hover {
    background: rgba(0, 0, 0, 0.05);
  }

  svg {
    margin-right: 5px;
  }
`;
