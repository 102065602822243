import React from 'react';
import PropTypes from 'prop-types';
// Components
import TextOverflow from 'components/TextOverflow';

import { Wrapper } from './styles';
import { MdInfo } from 'react-icons/md';

const propTypes = {
  title: PropTypes.node.isRequired,
  price: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
  price: null,
};

const CardStage = ({ title, price, children, style, color }) => (
  <Wrapper color={color} style={style}>
    <div className="CardStage__title" title={title}>
      <TextOverflow width="100%">{title}</TextOverflow>
      <p className="CardStage__text">
        {price}{' '}
        <MdInfo data-tip="O Total não contabiliza imóveis repetidos na etapa" />
      </p>
    </div>
    <div className="CardStage__actions">{children}</div>
  </Wrapper>
);

CardStage.propTypes = propTypes;
CardStage.defaultProps = defaultProps;

export default CardStage;
