import styled, { css } from 'styled-components';
import Drop from 'react-dropzone';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;

  border-radius: 4px;
`;

export const Actions = styled.div`
  display: flex;
  flex: 1 0;
  min-height: 0;

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const Dropzone = styled(Drop)`
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: grid;
  place-content: center;
  background: #f1f1f1;
`;

export const DropContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 350px;
    margin-bottom: 15px;
  }

  p {
    color: ${p => p.theme.colors.primary};
    text-align: center;
  }

  .Button {
    margin-top: 15px;
  }
`;

export const Btn = styled.button.attrs(() => ({
  type: 'button'
}))`
  padding: 0 10px;
  height: 55px;
  display: grid;
  place-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: ${p => p.theme.colors.secondary};
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  svg {
    font-size: 20px;
  }

  span {
    display: block;
  }
`;

export const ActionsRight = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const ButtonLink = styled.button.attrs(() => ({
  type: 'button'
}))`
  color: ${p => p.theme.colors.secondary};
  text-decoration: underline;
  padding: 15px;
  cursor: pointer;
`;
