import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Modules
import { getDeals, MODES, viewModeSelector } from './reducer';
// Components
import ReceptionCrm from './components/ReceptionCrm';
import Wrapper from 'components/Wrapper';
import FilterCrm from 'components/FilterCrm';
import PipelineContainer from './components/PipelineContainer';
import DealsHorizontal from './components/DealsHorizontal';
import withReception from 'HOC/withReception';
import { useCan } from 'hooks/useCan';
import { USER_PERMISSIONS } from 'constants/rules';
import { funnelIdSelector } from 'modules/login';
import { getStages } from 'modules/pipeline';
import * as userService from 'services/user';
import useSearch from 'hooks/useSearch';

function Crm({ reception, handleClickAction }) {
  const queryObject = useSearch(false);
  const _funnelId = useSelector(funnelIdSelector);
  const [funnelId, setFunnelId] = useState(queryObject?.funnel_id || _funnelId);
  const currentUser = localStorage.getItem('current-user');
  const isFetchingUser = useSelector((state) => state.login.isFetchingUser);
  const canViewOtherDeals = useCan({
    run: USER_PERMISSIONS.VIEW_OTHER_DEAL,
  });
  const dispatch = useDispatch();
  const mode = useSelector(viewModeSelector);

  const getFunnelId = useCallback(
    async (userId) => {
      const { data: user } = await userService.getOne(userId, {
        include: 'default_crm_stage.funnel',
      });

      setFunnelId(user?.default_crm_stage?.funnel_id);

      return user?.default_crm_stage?.funnel_id;
    },
    [setFunnelId]
  );

  useEffect(() => {
    if (queryObject?.initialValues?.by_user_id) {
      getFunnelId(queryObject?.initialValues?.by_user_id);
    }
  }, [queryObject]);

  const handleSubmit = useCallback(
    async (values) => {
      let funnel = funnelId;

      if (mode === MODES.horizontal) {
        // Busca os negocios com base os valores do formulário
        dispatch(getDeals(values));
      } else {
        if (values?.by_user_id) {
          funnel = await getFunnelId(values?.by_user_id);
        }

        // Busca os negocios com base os valores do formulário
        dispatch(
          getStages({ id: funnel }, { include: 'deals_list', filter: values })
        );
      }
    },
    [dispatch, mode, funnelId]
  );

  const initialValues = useMemo(() => {
    if (isFetchingUser) return {};

    if (canViewOtherDeals) {
      return {
        by_user_id: localStorage.getItem('current-user').id,
        ...queryObject.initialValues,
      };
    }

    return {};
  }, [isFetchingUser, currentUser, canViewOtherDeals, queryObject]);

  const Component = useMemo(() => {
    if (mode === MODES.horizontal) {
      return DealsHorizontal;
    }

    return PipelineContainer;
  }, [mode]);

  if (reception.isVisible)
    return <ReceptionCrm handleClickAction={handleClickAction} />;

  return (
    <Wrapper full>
      <FilterCrm
        funnelId={funnelId}
        mode={mode}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
      <Component funnelId={funnelId} initialValues={initialValues} />
    </Wrapper>
  );
}

export default withReception('crm/funnel', true)(Crm);
