import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Pages
import * as pages from './pages';

function Routes() {
  return (
    <Switch>
      <Route
        path="/config/registrations/establishments"
        component={pages.Establishments}
      />
      <Route
        path="/config/registrations/characteristics"
        component={pages.Characteristics}
      />
      <Route
        path="/config/registrations/neighborhoods"
        component={pages.Neighborhoods}
      />
      <Route
        path="/config/registrations/zones"
        component={pages.Zones}
      />
      <Route path="/config/registrations/cities" component={pages.Cities} />
      <Route
        path="/config/registrations/receptionSources"
        component={pages.ReceptionSources}
      />
      <Route path="/config/registrations/groups" component={pages.Groups} />
      <Route
        path="/config/registrations/lossReasons"
        component={pages.LossReasons}
      />
      <Redirect to="/config/registrations/establishments" />
    </Switch>
  );
}

export default Routes;
