import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';
import { usePlan } from 'hooks/usePlan';
import { createContext, useContext, useState } from 'react';
import { usePrices } from '../hooks/usePrices';
import useIntegrations from '../hooks/useIntegrations';
import { formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';

// Selector do formulário de pagamento
const selector = formValueSelector('PaymentForm');

export const ModalPaymentContext = createContext({
  isPaying: false,
  receiving_method: null,
  plan: {
    netMonthlyPrice: null,
    grossMonthlyPrice: null,
    totalNetValue: null,
    netValue: null,
    grossValue: null,
    totalGrossValue: null,
    percentage: null,
    totalPercentage: null,
    totalDiscount: null,
    planName: null,
    installmentsSlip: null,
    totalInstallmentsSlip: null,
    installmentsCreditCard: null,
    totalInstallmentsCreditCard: null,
  },
  isLoading: false,
  setIsLoading: null,
  prices: {
    subscription_price: null,
    user_price: null,
    mailbox_price: null,
    monthly_price: null,
    bs_min_value: null,
    cc_min_value: null,
    cc_max_installments_12m: null,
    cc_max_installments_6m: null,
    cc_max_installments_3m: null,
    bs_max_installments_12m: null,
    bs_max_installments_6m: null,
    bs_max_installments_3m: null,
    discount_6m: null,
    discount_12m: null,
    orulo_price: null,
    dwv_price: null,
  },
  values: {
    months: null,
    users: null,
    mailboxes_number: null,
    redirect_mails_number: null,
    receiving_method: null,
  },
  isFetchingPrices: false,
  usersTotalPrice: null,
  mailboxesTotalPrice: null,
  total: null,
  hasOrulo: null,
  hasDwv: null,
  getOruloPrice: () => null,
  getDwvPrice: () => null,
});

export const ModalPaymentProvider = ({ children }) => {
  const { is_paying: isPaying } = useCurrentRealEstate();

  const [isLoading, setIsLoading] = useState(false);

  const { hasOrulo, hasDwv } = useIntegrations();

  const [prices, isFetchingPrices] = usePrices();

  const values = useSelector((state) =>
    selector(
      state,
      'months',
      'users',
      'mailboxes_number',
      'redirect_mails_number',
      'receiving_method',
      'additional_users',
      'is_orulo',
      'is_dwv'
    )
  );

  const getOruloPrice = () => {
    if (hasOrulo) return prices.orulo_price;
    return values.is_orulo ? prices.orulo_price : 0;
  };

  const getDwvPrice = () => {
    if (hasDwv) return prices.dwv_price;
    return values.is_dwv ? prices.dwv_price : 0;
  };

  const usersTotalPrice = prices.user_price * values.additional_users;
  const additionalUsersTotalPrice = prices.user_price * values.additional_users;

  const mailboxesTotalPrice = prices.mailbox_price * values.mailboxes_number;

  const oruloPrice = getOruloPrice();
  const dwvPrice = getDwvPrice();

  const getTotal = () => {
    return (
      prices.subscription_price +
      usersTotalPrice +
      mailboxesTotalPrice +
      oruloPrice +
      dwvPrice
    );
  };

  const total = getTotal();

  const [plan] = usePlan({
    months: values.months || 1,
    users: values.additional_users,
    mailboxes: values.mailboxes_number,
    prices,
    orulo: getOruloPrice(),
    dwv: getDwvPrice(),
  });

  return (
    <ModalPaymentContext.Provider
      value={{
        isPaying,
        receiving_method: values.receiving_method,
        plan,
        isLoading,
        setIsLoading,
        prices,
        values,
        isFetchingPrices,
        usersTotalPrice,
        additionalUsersTotalPrice,
        mailboxesTotalPrice,
        total,
        hasOrulo,
        hasDwv,
        getOruloPrice,
        getDwvPrice,
      }}
    >
      {children}
    </ModalPaymentContext.Provider>
  );
};

export const useModalPayment = () => useContext(ModalPaymentContext);
