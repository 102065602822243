import React from 'react';
import { Wrapper, Box } from './styles';

const NoData = ({ backgroundImage, iconImage, text }) => {
  return (
    <Wrapper>
      <img src={backgroundImage} alt="Gráficos" />
      <Box>
        <img src={iconImage} alt="Icone" />
        <p>{text}</p>
      </Box>
    </Wrapper>
  );
};

export default NoData;
