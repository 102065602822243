import styled, { css } from 'styled-components';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';

export const Wrapper = styled.div`
  display: inline-block;
`;

export const IconUp = styled(MdArrowUpward)`
  color: #939393;

  ${(p) =>
    p.active &&
    css`
      color: #000;
    `}
`;

export const IconDown = styled(MdArrowDownward)`
  color: #939393;

  ${(p) =>
    p.active &&
    css`
      color: #000;
    `}
`;
