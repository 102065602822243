import React from 'react';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
// import { Wrapper } from './styles';

function ErrorIntegration({ handleClose }) {
  return (
    <>
      Não foi possível realizar a integração de catálogo, verifique se você
      selecionou o catálogo da sua loja, ou se todas as permissões foram
      concedidas e tente novamente
      <ModalFooter>
        <span className="h-flex__cell--grow" />
        <Button color="white" onClick={handleClose}>
          Fechar
        </Button>
      </ModalFooter>
    </>
  );
}

export default ErrorIntegration;
