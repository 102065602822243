import React from 'react';
import { DateWrapper, Diff, Icon, Title, Wrapper } from './styles';
import { ThemeProvider } from 'styled-components';
import { MdOutlineArrowDropDown, MdOutlineReceipt } from 'react-icons/all';
import Timeline from './components/Timeline';
import { useAccordion } from 'components/TimelineTree/hooks/useAccordion';
import { AnimatePresence } from 'framer-motion';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';

export const DEFAULT_THEME = {
  titleSize: '16px',
  valueSize: '14px',
  iconSize: '24px',
  spacing: '15px',
  borderColor: '#ADB7C5',
  maxHeight: '450px',
  minHeight: '387px',
};

function TimelineTree({
  isFetching = false,
  data = [],
  titleKey = 'date',
  timeKey = 'time',
  contentKey = 'stories',
  valueKey = '__html',
  theme = DEFAULT_THEME,
  defaultOpenItems = [0],
  onCloseModal,
  renderItem,
}) {
  const { openItems, toggleItem } = useAccordion(defaultOpenItems);

  if (isFetching) {
    return <Loading isVisible isBlock />;
  }

  if (!isFetching && data?.length <= 0) {
    return (
      <Nodata
        title="Nenhuma informação disponível para o filtro selecionado."
        text="Tente alterar o filtro acima."
        icon={MdOutlineReceipt}
        iconSize={70}
        iconColor="#D3DCEB"
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {data?.map((item, index) => {
          const isOpen = openItems.includes(index);

          return (
            <DateWrapper>
              <Title onClick={() => toggleItem(index)}>
                <Icon
                  layout
                  initial={{ rotate: 0 }}
                  animate={{ rotate: isOpen ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <MdOutlineArrowDropDown />
                </Icon>
                {item[titleKey]}
                <Diff>{item.date_diff}</Diff>
              </Title>
              <AnimatePresence>
                {isOpen &&
                  (renderItem ? (
                    renderItem(item, index) // Render custom component
                  ) : (
                    <Timeline
                      item={item}
                      timeKey={timeKey}
                      contentKey={contentKey}
                      valueKey={valueKey}
                      onCloseModal={onCloseModal}
                    />
                  ))}
              </AnimatePresence>
            </DateWrapper>
          );
        })}
      </Wrapper>
    </ThemeProvider>
  );
}

export default TimelineTree;
