import React from 'react';
import { Field } from 'redux-form';
// Components
import { SelectMultiple } from 'components/Form';
// Hooks
import { useRequest } from 'hooks/useRequest';
// Services
import { getLanguages } from 'services/sites/headers';

function FieldLanguage() {
  const { data, isFetching } = useRequest({
    request: getLanguages
  });

  return (
    <Field
      multi
      isLoading={isFetching}
      name="languages"
      placeholder="Selecione as linguas"
      options={data}
      valueKey="id"
      labelKey="name"
      component={SelectMultiple}
      style={{ width: 350 }}
    />
  );
}

FieldLanguage.defaultProps = {};
FieldLanguage.propTypes = {};

export default FieldLanguage;
