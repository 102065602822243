import { useRequest } from 'hooks/useRequest';
// Components
import { Select2 } from 'components/Form';
import { Field } from 'redux-form';
import * as financialIndex from 'services/financialIndex';

function FinancialIndexesField(props) {
  const { data } = useRequest({ request: financialIndex.getList });

  return (
    <Field
      label="Índice Financeiro"
      name="price_financial_index_id"
      labelKey="name"
      valueKey="id"
      options={data}
      component={Select2}
      selectedItemsLabel="indices"
      {...props}
    />
  );
}

export default FinancialIndexesField;
