import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
// Components
import TextLoading from 'components/TextLoading';

const defaultProps = {
  title: '',
  isFetching: false,
  text: '',
  className: '',
  children: '',
  style: {},
  renderComponent: null,
  xsChild: null
};

const propTypes = {
  title: PropTypes.node,
  isFetching: PropTypes.bool,
  text: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  renderComponent: PropTypes.func
};

const MainTitle = ({
  isFetching,
  title,
  text,
  children,
  className,
  style,
  renderComponent,
  xsChild
}) => (
  <Row className={classnames('MainTitle', className)} style={style} middle="xs">
    <Col xs>
      <h1 className="MainTitle__title h4 h-color--primary">
        {isFetching ? <TextLoading width="20%" height="1em" /> : title}
      </h1>
      <p className="MainTitle__text h-margin-top--5 h-color--primary">
        {isFetching ? <TextLoading width="35%" height="1em" /> : text}
      </p>
      {renderComponent && renderComponent()}
    </Col>
    {xsChild ? (
      <Col xs={xsChild} className="MainTitle__actions">
        {children}
      </Col>
    ) : (
      <Col className="MainTitle__actions">{children}</Col>
    )}
  </Row>
);

MainTitle.defaultProps = defaultProps;
MainTitle.propTypes = propTypes;

export default MainTitle;
