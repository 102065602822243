import styled from 'styled-components';

export const Date = styled.div`
  font-size: 24px;
  font-weight: 600;

  margin-right: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Button {
    padding: 0 10px;
    flex-shrink: 0;
    line-height: 24px;
    height: 24px;
  }
`;
