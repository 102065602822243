import React from 'react';
// Component
import LocationImage from 'components/LocationImage';

const propTypes = {};
const defaultProps = {};

const LocationImageProperty = ({ property }) => (
  <LocationImage
    markerType="pin"
    property={property}
    lat={property.maps_latitude}
    lng={property.maps_longitude}
    heading={property.maps_heading}
    pitch={property.maps_pitch}
    zoom={property.maps_street_zoom}
    mapUrl={property.static_map_url}
    streetViewUrl={property.static_street_view_url}
  />
);

LocationImageProperty.propTypes = propTypes;
LocationImageProperty.defaultProps = defaultProps;

export default LocationImageProperty;
