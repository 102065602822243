import { HTTP, ORIGINAL, STORAGE } from './HTTP';

export function setOriginalToken(accessToken, typeToken = 'Bearer') {
  if (accessToken) {
    localStorage.setItem('original_token', accessToken);
    ORIGINAL.defaults.headers.common.Authorization = `${typeToken} ${accessToken}`;
  } else {
    localStorage.removeItem('original_token');
    delete ORIGINAL.defaults.headers.common.Authorization;
  }
}

export default function setAuthorizationToken(
  accessToken,
  typeToken = 'Bearer'
) {
  if (accessToken) {
    localStorage.setItem('access_token', accessToken);
    HTTP.defaults.headers.common.Authorization = `${typeToken} ${accessToken}`;
    STORAGE.defaults.headers.common.Authorization = `${typeToken} ${accessToken}`;
  } else {
    localStorage.removeItem('access_token');
    delete HTTP.defaults.headers.common.Authorization;
    delete STORAGE.defaults.headers.common.Authorization;
  }
}
