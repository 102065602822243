import { useRequest } from 'hooks/useRequest';
import * as creditCardsService from 'services/financialv2/creditCards';
import { useDispatch } from 'react-redux';
import { openModalCreditCard } from 'modules/modal';
import { openConfirmation } from 'containers/ModalConfirmation/module';

export const useCreditCards = () => {
  const dispatch = useDispatch();

  const {
    data,
    fetchData,
    update,
    setData,
    remove,
    isFetching,
    setIsFetching,
    meta,
  } = useRequest({
    request: creditCardsService.getAll,
  });

  const handleClickPrimaryCard =
    ({ id }) =>
    async () => {
      try {
        setIsFetching(true);
        await creditCardsService.defineAsPrimary(id);
        setData((data) => {
          return data.map((card) => {
            if (card.id === id) return { ...card, is_primary: true };
            return { ...card, is_primary: false };
          });
        });
      } catch {
        console.error(
          `Erro ao tentar atualizar o cartão primário do ID: ${id}`
        );
      }
    };

  const handleClickRemove = (creditCard) => async () => {
    dispatch(
      openConfirmation({
        request: () => creditCardsService.remove(creditCard.id),
        title: 'Remover cartão de crédito',
        text: `Se você deseja realmente remover o cartão de crédito com o final: ${creditCard.number}`,
        submitButtonText: 'Sim, excluir cartão',
        cancelButtonText: 'Não excluir cartão',
        onSuccess: () => {
          fetchData();
        },
      })
    );
  };

  const handleClickAddCard = () => {
    dispatch(
      openModalCreditCard({
        onSubmitSuccess: () => {
          fetchData();
        },
      })
    );
  };

  return {
    data,
    isFetching,
    meta,
    handleClickPrimaryCard,
    handleClickRemove,
    handleClickAddCard,
  };
};
