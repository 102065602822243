import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FieldImage, Input, Textarea } from 'components/Form';
import Button from 'components/Button';
import { Field } from 'redux-form';
import Container from 'components/Container';

function FormSectionContent({ pristine, change }) {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <FieldImage
            hasRemove
            bgSize="cover"
            fileUrlField="file_url"
            height={220}
            name="image"
            text={
              <>
                Envie uma imagem para
                <br /> a sua postagem
                <br />(<strong>Recomendado:</strong> 1150x400px)
              </>
            }
            renderButtonSend={() => (
              <Button
                type="button"
                color="secondary"
                className="DropImage__button"
              >
                Enviar imagem
              </Button>
            )}
            style={{ marginBottom: '15px' }}
          />
        </Col>
        <Field
          xs={12}
          name="title"
          maxLength={120}
          placeholder="Digite o título da postagem"
          component={Input}
          onChange={(e, value) => {
            if (pristine) return null;

            change('meta_title', value);
            change(
              'link',
              value
                .trim()
                .toLowerCase()
                .normalize('NFD')
                .replace(/[^-\w\s]/g, '')
                .replace(/(\s|-)+/g, '-')
            );
          }}
        />
        <Field
          tabIndex={2}
          height={600}
          xs={12}
          xsOffset={1}
          name="content"
          component={Textarea}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons

              [{ list: 'ordered' }, { list: 'bullet' }, 'link'],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ['clean'], // remove formatting button
            ],
          }}
        />
      </Row>
    </Container>
  );
}

export default FormSectionContent;
