import React, { useCallback } from 'react';
import NoContent from 'pages/PropertyStore/components/NoContent';
import image from './image.svg';
import Button from 'components/Button';
import { MdEdit } from 'react-icons/md';
import IsTecimob from 'containers/IsTecimob';

const Reception = ({ onAddDescription }) => {
  const handleClickAdd = useCallback(() => {
    if (onAddDescription) onAddDescription();
  }, [onAddDescription]);

  return (
    <NoContent
      title="Descrição"
      text="Uma boa descrição faz a diferença na hora de encher os olhos do visitante do seu site. Use as palavras certas, cite todas as características positivas do imóvel e encante o seu futuro cliente."
      image={image}
    >
      <IsTecimob>
        <div className="h-margin-bottom--15">
          Leia:{' '}
          <a
            href="https://tecimob.com.br/blog/6-dicas-para-elaborar-uma-descricao-para-imoveis-do-seu-site/"
            target="_blank"
            className="h-link"
            rel="noreferrer"
          >
            6 dicas para elaborar uma boa descrição
          </a>
        </div>
      </IsTecimob>
      <Button
        color="secondary"
        className="h-margin-bottom--10"
        onClick={handleClickAdd}
      >
        <MdEdit /> Escrever Descrição
      </Button>
    </NoContent>
  );
};

export default Reception;
