import { useRequest } from 'hooks/useRequest';
import * as dealsService from 'services/deals';

export const useStagesFunnel = (funnelId, props) => {
  const { data, fetchData, meta, setData, isFetching } = useRequest({
    request: () => dealsService.getStagesByFunnelId({ funnel_id: funnelId }),
    ...props,
  });

  return {
    data,
    fetchStages: fetchData,
    meta,
    setData,
    isFetching,
  };
};
