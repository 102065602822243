import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { change, Field, formValueSelector } from 'redux-form';
// Service
import * as locationService from 'services/location';
// Modules
import { Actions, Selectors } from '../module';
// HOC
import { withFormName } from 'HOC/withFormName';
// Constants
import { defaultPropsLocation, propTypesLocation } from '../constants';

class StateField extends React.Component {
  static propTypes = {
    ...propTypesLocation,
    canUnregister: PropTypes.bool,
    countryId: PropTypes.string,
    canClearValues: PropTypes.bool.isRequired,
    cityName: PropTypes.string.isRequired,
    neighborhoodName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    ...defaultPropsLocation,
    canUnregister: true,
    label: 'UF',
    name: 'state_id',
    canClearValues: true,
    cityName: 'city_id',
    neighborhoodName: 'neighborhood_id',
  };

  constructor(props) {
    super(props);

    // Registra o estado na store
    props.register(props.formName, props.name);
  }

  componentDidMount() {
    if (this.props.countryId || this.props.noCountry) {
      this.fetchStates(this.props.countryId);
    }
  }

  componentWillUnmount() {
    if (this.props.canUnregister) {
      this.props.unregister(this.props.formName, this.props.name);
    }
  }

  componentDidUpdate(prevProps) {
    const { countryId: currentId } = this.props;
    const { countryId: prevId } = prevProps;

    if (currentId !== prevId && currentId !== null) {
      this.fetchStates(currentId);
    }
  }

  /**
   * Verifica se o campo deve ficar desabilitado
   * @return {boolean}
   */
  isDisabled = () => this.props.options && !this.props.options.length;

  request = () => this.props.request(this.props.formName, this.props.name);
  receive = (data) =>
    this.props.receive(this.props.formName, this.props.name, data);
  change = (fieldName, value) =>
    this.props.change(this.props.formName, fieldName, value);
  clear = (fieldKey) => this.props.clear(this.props.formName, fieldKey);

  // Limpa os campos de Cidade e Bairro caso mude o valor do Estado
  clearFields = () => {
    const { cityName, neighborhoodName } = this.props;

    this.clear([cityName, neighborhoodName]);
    this.change(cityName, null);
    this.change(neighborhoodName, null);
  };

  /**
   * Busca os países
   * @param countryId
   * @return {Promise}
   */
  fetchStates = async (countryId) => {
    const { params } = this.props;

    this.request();
    const { data } = await locationService.getStates({
      country_id: countryId,
      ...params,
    });
    this.receive(data);
  };

  handleChange = (e, nextValue) => {
    const { canClearValues, onChange, value } = this.props;

    // Executa o evento de onChange caso tenha sido enviado
    // Caso contrário não faz absolutamente nada
    if (onChange) onChange(e, nextValue);

    // Limpa os campos se puder
    if (canClearValues && nextValue !== value) {
      this.clearFields();
    }
  };

  render() {
    return (
      <Field
        disabled={this.isDisabled()}
        {...this.props}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);

  return {
    value: selector(state, ownProps.name),
    options: Selectors.getData(ownProps.formName, ownProps.name)(state),
    isLoading: Selectors.getIsFetching(ownProps.formName, ownProps.name)(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...Actions, change }, dispatch);

export default compose(
  withFormName,
  connect(mapStateToProps, mapDispatchToProps)
)(StateField);
