import PropTypes from 'prop-types';
import { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Components
import Button from 'components/Button';
import Popover from 'components/Popover';
import PaymentOptions from './components/PaymentOptions';
// Modules
import {
  openModalBankSlip,
  openModalPayInstallment,
  openModalPayPix,
} from 'modules/modal';
import { generateDuplicateBillet } from 'modules/plan';
// Socket
import { PAYMENT_METHODS } from 'constants/constants';
import Events from 'lib/Socket/Events';
import * as bankSlipService from 'services/financial/bankSlip';

function PayButton({ receivingMethod, creditId, className, onPaySuccess }) {
  const dispatch = useDispatch();

  // Verifica se é um cartão de crédito
  // const isCreditCard = receivingMethod === 2 || receivingMethod === "2";

  // Lida com o clique quando vai pagar com cartão de crédito
  const payWithCreditCard = useCallback(() => {
    dispatch(
      openModalPayInstallment({
        receivingMethod: PAYMENT_METHODS.CREDIT_CARD,
        creditId,
        onSuccess: () => {
          if (onPaySuccess) onPaySuccess();
        },
      })
    );
  }, [dispatch, receivingMethod, creditId, onPaySuccess]);

  const payWithPix = () => {
    dispatch(
      openModalPayPix({
        credit_id: creditId,
        onSuccess: () => {},
      })
    );
  };

  const onClickPayBillet = useCallback(async () => {
    try {
      await generateDuplicateBillet({
        receiving_method: PAYMENT_METHODS.BOLETO,
        credit_id: creditId,
      })(dispatch);
    } catch (err) {
      const { data } = await bankSlipService.getOne();

      dispatch(
        openModalBankSlip({
          bank_slip_id: data?.id,
          credit_id: creditId,
          onSubmitSuccess: () => {
            generateDuplicateBillet({
              receiving_method: PAYMENT_METHODS.BOLETO,
              credit_id: creditId,
            })(dispatch);
          },
        })
      );
    }
  }, [dispatch, receivingMethod, creditId]);

  useEffect(() => {
    let events = new Events();

    events.onPaymentSuccess(() => {
      if (onPaySuccess) onPaySuccess();
    });
  }, [onPaySuccess]);

  // Verifica se é bonus
  // Se for não tem opção de pagar
  if (receivingMethod === 3) return false;

  return (
    <Popover
      onClickPayBillet={onClickPayBillet}
      onClickPayCreditCard={payWithCreditCard}
      onClickPayPix={payWithPix}
      component={PaymentOptions}
    >
      <Button color="success" size="small" className={className}>
        Pagar
      </Button>
    </Popover>
  );
}

PayButton.defaultProps = {
  receivingMethod: null,
  className: 'h-margin-left--5',
};

PayButton.propTypes = {
  receivingMethod: PropTypes.oneOf(['1', '2', 1, 2, '4', 4]),
  className: PropTypes.string,
};

export default memo(PayButton);
