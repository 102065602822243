import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Components
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import Button from 'components/Button';
import ModalFooter from 'components/Modal/components/ModalFooter';
import validate from './validate';
import { Textarea } from 'components/Form';

const CustomToolbar = ({ children }) => (
  <div id="toolbar-notes" className="ql-toolbar ql-snow ql-toolbar--custom">
    <div className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
    </div>
    <div className="h-flex__cell--grow" />
    <div className="ql-formats">{children}</div>
  </div>
);

class PropertyExcludedForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func,
  };

  handleChangeReason = () => {
    if (!!this.props.description) {
      this.props.changeFieldValue('description', null);
    }
  };

  get message() {
    const { isExcluded } = this.props;

    if (isExcluded) {
      return (
        <p>
          Ao excluir esse imóvel, todos os negócios, compromissos e dados
          ligados a ele serão excluídos. Deseja continuar?
        </p>
      );
    }

    return (
      <p>
        Ao inativar esse imóvel, ele não será mais exibido no site. Deseja
        continuar?
      </p>
    );
  }

  render() {
    const { isExcluded, handleSubmit, handleClose } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {this.message}

        {!isExcluded ? (
          <div className="h-margin-top--15">
            <Field
              label="Motivo da inativação"
              name="note"
              component={Textarea}
              modules={{
                toolbar: {
                  container: '#toolbar-notes',
                },
              }}
              customToolbar={CustomToolbar}
            />
          </div>
        ) : null}

        <ModalFooter>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="danger">
            {isExcluded ? 'Excluir imóvel' : 'Mover para inativos'}
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

const selector = formValueSelector('PropertyExcludedForm');

const mapStateToProps = (state) => ({
  reason: selector(state, 'reason'),
  description: selector(state, 'description'),
});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) => {
    dispatch(change('PropertyExcludedForm', field, value));
  },
});

export default compose(
  reduxForm({
    form: 'PropertyExcludedForm',
    validate,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(PropertyExcludedForm);

//export default connect(mapStateToProps, mapDispatchToProps)(wrapperSendMailForm);
