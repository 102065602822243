import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
// Components
import Button from 'components/Button';
import CardAction from 'components/CardAction';
import Loading from 'components/Loading';
import UploadButton from './components/UploadButton';
// Modules
import { openModalGallery } from 'modules/modal';
import { receiveUser, setLogoHeader } from 'modules/login';
// Services
import * as logosService from 'services/sites/logos';
import { TYPES } from 'services/sites/logos';
// Assets
import imageCollection from 'assets/img/site/image-collection.svg';
import penAndMeasures from 'assets/img/site/pen-and-measure.svg';
import uploadImage from 'assets/img/site/upload-image.svg';

export { TYPES };

export const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

class UploadLogo extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf([
      TYPES.WATERMARK,
      TYPES.REAL_ESTATE,
      TYPES.HEADER,
      TYPES.FOOTER,
    ]).isRequired,
    device: PropTypes.oneOf([DEVICES.MOBILE, DEVICES.DESKTOP]),

    onUploadSuccess: PropTypes.func,
    onSelectLogo: PropTypes.func,
  };

  static defaultProps = {
    type: TYPES.REAL_ESTATE,
    device: null,

    onUploadSuccess: null,
    onSelectLogo: null,
  };

  state = {
    isFetching: false,
  };

  get createLogoUrl() {
    const { type, device } = this.props;
    const BASE = '/logo/create/';
    if (device) return `${BASE}${type}/${device}`;
    return `${BASE}${type}`;
  }

  updateRealEstateLogo(realEstate) {
    this.props.setLogoHeader(realEstate.file_url);
    const currentUser = localStorage.getItem('current-user');
    this.props.receiveUser({
      ...currentUser,
      real_estate: realEstate,
    });
  }

  onUploadSuccess = (res) => {
    const { type, history, onUploadSuccess } = this.props;
    if (onUploadSuccess) return onUploadSuccess(history);

    switch (type) {
      case TYPES.HEADER:
      case TYPES.FOOTER:
      case TYPES.WHATSAPP:
        history.push('/site/layout/style/logo');
        break;
      case TYPES.REAL_ESTATE: {
        this.updateRealEstateLogo(res.data);
        history.push('/site/config/realestate');
        break;
      }
      case TYPES.WATERMARK:
        history.push('/site/layout/style/watermark');
        break;
      default:
        throw new Error('Tipo invalido');
    }
  };

  handleUploadLogo = async (files) => {
    const image = files[0];

    const { type, device, onUploadSuccess, history } = this.props;
    this.setState({ isFetching: true });
    try {
      const res = await logosService.uploadLogo(type, device, image);
      this.onUploadSuccess(res);
      if (onUploadSuccess) onUploadSuccess(history);
    } finally {
      this.setState({ isFetching: false });
    }
  };

  render() {
    return (
      <Row>
        <Col xs={4}>
          <CardAction
            title="Criar Logo"
            text="Crie um logo a partir dos nossos modelos prontos."
            to={this.createLogoUrl}
            image={penAndMeasures}
          >
            <Button>Criar meu logo</Button>
          </CardAction>
        </Col>
        <Col xs={4}>
          <CardAction
            title="Enviar Logo Pronta"
            text={
              <>
                Envie seu logo para o seu site.
                <br />
                Medida recomendada 1920px de largura
              </>
            }
            image={uploadImage}
          >
            <UploadButton
              type={this.props.type}
              onDrop={this.handleUploadLogo}
            />
            <Loading isVisible={this.state.isFetching} isFullComponent />
          </CardAction>
        </Col>
        <Col xs={4}>
          <CardAction
            image={imageCollection}
            title="Minhas logos"
            text={
              <>
                Escolha um logo a partir dos
                <br /> modelos que você já criou.
              </>
            }
          >
            <Button
              onClick={() => {
                this.props.openModalGallery({
                  type: this.props.type,
                  device: this.props.device,
                  onSelect: this.props.onSelectLogo,
                });
              }}
            >
              Escolher logo pronta
            </Button>
          </CardAction>
        </Col>
      </Row>
    );
  }
}

export default compose(
  withRouter,
  connect(null, { receiveUser, openModalGallery, setLogoHeader })
)(UploadLogo);
