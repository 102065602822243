import FormGroup from 'components/Form/components/FormGroup';
import { Error } from 'components/Form/components/ShowErrors/styles';
import { Button, Content } from 'components/ReferenceSearch/styles';
import { useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { Field } from 'redux-form';
// import { Wrapper } from './styles';

function StreetAddressField({
  name,
  input,
  meta,
  handleSubmit,
  placeholder = 'Digite o logradouro, ou',
  ...rest
}) {
  const [error, setError] = useState(null);

  const onSearch = () => {
    if (input.value.length < 3) {
      setError('No mínimo 3 caracteres');
      return false;
    }

    if (handleSubmit) handleSubmit(input, meta);
  };

  return (
    <FormGroup input={input} meta={meta}>
      <Content>
        <input
          {...input}
          name={name}
          placeholder={placeholder}
          className="FormControl"
          {...rest}
          onKeyDown={(e) => {
            setError(null);

            if (e.key === 'Enter') {
              onSearch();
            }
          }}
          onBlur={() => {
            setError(null);
          }}
        />
        <Button onClick={onSearch}>
          <MdSearch />
        </Button>
      </Content>
      {error && <Error>{error}</Error>}
    </FormGroup>
  );
}

function StreetAddress({ name = 'street_address', handleSubmit, ...rest }) {
  return (
    <Field
      name={name}
      component={StreetAddressField}
      handleSubmit={handleSubmit}
      {...rest}
    />
  );
}

export default StreetAddress;
