import React, { useEffect } from 'react';
import Layout from 'pages/Crm/components/Layout';
import { Content } from './styles';
import BookedList from './components/BookedList';
import { usePropertiesBooked } from './hooks/usePropertiesBooked';
import { useFormUserId } from '../../components/FormUser';
import Loading from 'components/Loading';
import { useSelector } from 'react-redux';
import { countsSelector } from '../../modules/asideCrm';
import { plural } from 'lib/text';

function Booked() {
  const userId = useFormUserId();
  const {
    data: { bookedPropertiesCount },
  } = useSelector(countsSelector);

  const { data, isFetching, fetchData } = usePropertiesBooked();

  useEffect(() => {
    if (userId) {
      fetchData({ filter: { booking_user_id: userId } });
    }
  }, [userId]);

  return (
    <Layout
      title={
        <>
          <strong>Negócios em andamento</strong> |{' '}
          <strong>Imóveis reservados</strong> |{' '}
          {plural(bookedPropertiesCount?.properties_count, 'Imóvel', 'Imóveis')}{' '}
          | {plural(bookedPropertiesCount?.people_count, 'Cliente', 'Clientes')}{' '}
          | {bookedPropertiesCount?.properties_price_sum}
        </>
      }
    >
      <Content>
        <BookedList
          filter={{ booking_user_id: userId }}
          data={data}
          isFetching={isFetching}
          fetchData={fetchData}
        />
        <Loading isVisible={isFetching} isFullScreen />
      </Content>
    </Layout>
  );
}

export default Booked;
