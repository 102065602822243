import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background-color: #1a051d;
  border-radius: 8px;
  width: 294px;
  padding: 30px;
  color: #fff;
  text-align: center;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
`;
export const Title = styled.h1`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.5em;
`;

export const Content = styled.div`
  font-size: 14px;
  color: #fff;
  line-height: 22px;
`;

export const Image = styled.img`
  margin: 0 auto 15px;
`;

export const Steps = styled.ul`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
`;

export const Step = styled.li`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${p => (p.isActive ? '#00C48C' : '#E5E7FA')};

  & + & {
    margin-left: 8px;
  }
`;

const colors = {
  primary: css`
    background-color: #6979f8;
  `,
  success: css`
    background-color: #00c48c;
  `
};

export const Button = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: 100%;
  max-width: 180px;
  height: 40px;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 15px;
  ${p => colors[p.color]}
`;
