import qs from 'qs';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
// Components
import Wrapper from 'components/Wrapper';
import ListPropertyMatched from './components/ListPropertyMatched';
// Modules
import { hideLoading, showLoading } from 'modules/loading';
import { openModalSendMail } from 'modules/modal';
import api from 'services';
import {
  fetchProperties,
  fetchUsers,
  handleChangeFormProperty,
  propertiesSelector,
  removeAllProfiles,
  removeProfile,
  usersSelector,
} from './module';

class PropertyMatched extends React.Component {
  state = {
    count: {
      value: 0,
      isFetching: false,
    },
    pagination: {
      total: null,
      per_page: null,
      current_page: 1,
      total_pages: null,
    },
  };

  componentDidMount() {
    // busca todos os imóveis que foram encontrados
    this.updateProperties();

    this.updateCount();
  }

  updateCount = async () => {
    this.setState({ count: { value: 0, isFetching: true } });

    const {
      data: { count_sent_30_days },
    } = await api.getOne('properties/matcheds/count-sents');

    this.setState({ count: { value: count_sent_30_days, isFetching: false } });
  };

  updateProperties = async (selected = 0) => {
    const { location, showLoading, hideLoading, fetchProperties } = this.props;

    const search = qs.parse(location?.search, { ignoreQueryPrefix: true });

    // Mostra Loading na tela inteira
    showLoading();

    const res = await fetchProperties(
      { filter: search.initialValues },
      selected + 1
    );

    this.setState({
      pagination: res.meta.pagination,
    });

    // Idependente do resultado do request
    // ele tem que esconder a modal
    hideLoading();

    return res;
  };

  onPageChange = ({ selected }) => {
    this.updateProperties(selected);
  };

  openModalSendMail = (...args) => this.props.openModalSendMail(...args);

  handleSendPropertySuccess = () => {
    this.updateProperties();

    Alert.success('Email Enviado');
  };

  sendProperty = (property) => () =>
    this.openModalSendMail({
      property,
      onSubmitSuccess: this.handleSendPropertySuccess,
    });
  sendAllProperties = (properties) => () =>
    this.openModalSendMail({ properties });
  sendPeople = (people) => () => this.openModalSendMail({ people });

  handleClickDischart = (profile) => () => this.props.removeProfile(profile);
  handleDiscardAll = () => {
    this.props.removeAllProfiles();
  };

  /**
   * Função que cuida da busca de imóveis compatíveis
   * Baseados nos usuários que foram selecionados
   *
   * @param {Object} values - todos os valores do formulário
   */
  handleChangeFilter = (values) => this.props.handleChangeFormProperty(values);

  render() {
    const { properties } = this.props;

    return (
      <Wrapper full>
        <Helmet>
          <title>Imóveis</title>
        </Helmet>
        <ListPropertyMatched
          count={this.state.count}
          data={properties}
          sendProperty={this.sendProperty}
          sendAllProperties={this.sendAllProperties}
          sendPeople={this.sendPeople}
          handleClickDischart={this.handleClickDischart}
          handleDiscardAll={this.handleDiscardAll}
          pagination={this.state.pagination}
          onPageChange={this.onPageChange}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  formValues: propertiesSelector.getAll(state),
  properties: propertiesSelector.getAll(state),
  currentUser: localStorage.getItem('current-user'),
  users: usersSelector.getAll(state),
});

const mapDispatchToProps = {
  showLoading,
  hideLoading,
  fetchUsers,
  fetchProperties,
  handleChangeFormProperty,
  removeProfile,
  openModalSendMail,
  removeAllProfiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyMatched);
