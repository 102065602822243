import { useEffect, useState, useCallback, useMemo } from 'react';
import * as oruloService from 'services/orulo';

export function useOrulo() {
  const [orulo, setOrulo] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  const hasIntegration = useMemo(() => {
    return !!orulo && !isFetching;
  }, [orulo, isFetching]);

  const fetchOrulo = useCallback(async () => {
    try {
      setIsFetching(true);
      const { data } = await oruloService.getToken();
      setOrulo(data);
    } finally {
      setIsFetching(false);
    }
  }, [setOrulo, setIsFetching]);

  useEffect(() => {
    fetchOrulo();
  }, []);

  return { orulo, hasIntegration, isFetching, fetchOrulo };
}
