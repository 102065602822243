import React from 'react';
// Components
import { FieldBool } from 'components/Form';

const HasExclusivityField = () => (
  <FieldBool
    canRemoveSelf
    type="checkbox"
    label="Com exclusividade?"
    name="by_exclusivity"
  />
);

export default HasExclusivityField;
