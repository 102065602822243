import React, { useMemo } from 'react';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import { useHistory, useRouteMatch } from 'react-router';
import useFormValue from 'hooks/useFormValue';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
// import { Container } from './styles';

const FooterActions = ({
  isLast,
  handleSubmit,
  onSubmit,
  submitText = 'Salvar e Próximo',
}) => {
  let { path: basePath } = useRouteMatch();

  const dispatch = useDispatch();
  const { push } = useHistory();
  const isDraft = useFormValue('is_draft');

  const isEditting = useMemo(() => {
    return basePath?.includes('update');
  }, [basePath]);

  return (
    <FixedBar left={274}>
      <Button
        type="button"
        color="white"
        colorText="secondary"
        onClick={() => {
          if (isDraft) {
            dispatch(
              openConfirmation({
                size: 324,
                title: 'Imóvel em rascunho',
                text: 'Ao cancelar o cadastro, este imóvel poderá ser encontrado na área de rascunhos',
                cancelButtonText: 'Voltar',
                submitButtonColor: 'success',
                submitButtonText: 'Entendi',
                request: () => {
                  push('/properties/revision/show');
                },
              })
            );
          } else {
            dispatch(
              openConfirmation({
                size: 324,
                title: 'Deseja mesmo sair do cadastro?',
                text: 'As informações que você salvou não serão perdidas.',
                cancelButtonText: 'Voltar',
                submitButtonText: 'Sair',
                request: () => {
                  push('/properties/search');
                },
              })
            );
          }
        }}
      >
        Cancelar
      </Button>
      <FixedBar.spacer />
      {isEditting && !isLast ? (
        <>
          <Button type="submit" color="success">
            Salvar
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={handleSubmit((values, dispatch, props) => {
              return onSubmit(
                {
                  ...values,
                  isFinishing: true,
                },
                dispatch,
                props
              );
            })}
          >
            Finalizar
          </Button>
        </>
      ) : (
        <>
          <Button type="submit" color="success">
            {submitText}
          </Button>
        </>
      )}
    </FixedBar>
  );
};

export default FooterActions;
