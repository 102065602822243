import React from 'react';
import { Content, Number, Percent, Title, Wrapper } from './styles';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import TextLoading from 'components/TextLoading';

function Item({ title, value, percent }) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Content>
        {value !== null ? (
          <Number>{value}</Number>
        ) : (
          <Number>
            <TextLoading width={50} height={20} />
          </Number>
        )}

        {percent && (
          <Percent>
            {percent > 0 ? (
              <IoMdArrowRoundUp size={12} className="h-color--success" />
            ) : (
              <IoMdArrowRoundDown size={12} className="h-color--danger" />
            )}
            {percent}%
          </Percent>
        )}
      </Content>
    </Wrapper>
  );
}

export default Item;
