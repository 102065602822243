import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Services
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import * as establishmentsService from 'services/establishments';
import TableEstablishments from './components/TableEstablishments';
// Modules
import {
  openModalEstablishment,
  openModalEstablishmentRemove
} from 'modules/modal';

// import from './styles';

function Establishments() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  async function fetchEstablishments() {
    setIsFetching(true);
    const { data } = await establishmentsService.getAllCustom();
    setData(data);
    setIsFetching(false);
  }

  const handleDelete = (establishment) => async () => {
    dispatch(
      openModalEstablishmentRemove({
        establishment,
        onSubmitSuccess: () => {
          fetchEstablishments();
        },
      })
    );
  };

  const handleEdit = (establishment) => () => {
    openModalEstablishment({
      establishmentId: establishment.id,
      onSubmitSuccess: () => {
        fetchEstablishments();
      },
    })(dispatch);
  };

  useEffect(() => {
    fetchEstablishments();
  }, []);

  return (
    <Wrapper.container>
      <MainTitle
        title="Estabelecimentos próximos ao imóvel"
        text="Gerencie aqui todos os estabelecimentos que você cadastrou no sistema."
      />
      <TableEstablishments
        data={data}
        isFetching={isFetching}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Wrapper.container>
  );
}

Establishments.propTypes = {};
Establishments.defaultProps = {};

export default Establishments;
