import api, { apiStorage } from 'services/index';
import { STORAGE } from 'lib/HTTP';

export const TYPES = {
  WATERMARK: 'watermark',
  REAL_ESTATE: 'real-estate',
  HEADER: 'header',
  FOOTER: 'footer',
  WHATSAPP: 'whatsapp',
};

export const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

export const getLogoHeader = async (params) => {
  let res = await api.getOne('settings/sites/logos/header', '', params);

  return {
    ...res,
    data: {
      ...res.data,
      logo_opacity: res.data.logo_opacity ? res.data.logo_opacity : 0,
    },
  };
};
export const updateLogoHeader = ({ id, ...values }) =>
  api.updateWithFile('settings/sites/logos/header', {
    ...values,
    logo_opacity: values.logo_opacity ? values.logo_opacity : 0,
  });

export const getLogoFooter = (params) =>
  api.getOne('settings/sites/logos/footer', '', params);
export const updateLogoFooter = ({ id, ...values }) =>
  api.updateWithFile('settings/sites/logos/footer', values);

export const getLogo = ({ device, type }) => {
  if (type === TYPES.HEADER) return getLogoHeader({ type, device });
  return getLogoFooter({ type, device });
};

export const updateLogo = ({ id, type, ...values }) => {
  if (type === TYPES.HEADER) return updateLogoHeader({ id, ...values });
  return updateLogoFooter({ id, ...values });
};

const isValidDevice = (device) => {
  if (!device) return true;
  return [DEVICES.MOBILE, DEVICES.DESKTOP, 'all'].indexOf(device) >= 0;
};

/**
 * Envia uma imagem pela posição dela e o dispositivo
 * @param type - ['header', 'footer', 'real-estate', 'watermark']
 * @param device - ['mobile', 'desktop']
 * @param image - {Blob}
 * @param isAll - boolean
 * @returns {*|AxiosPromise<any>}
 */
export const uploadLogo = async (type, device, image, isAll = false) => {
  if (!isValidDevice(device)) throw new Error('Dispositívo invalido');

  try {
    const {
      data: { id },
    } = await apiStorage.uploadFile('settings/logos', { image });

    switch (type) {
      case TYPES.HEADER: {
        if (isAll) {
          return api.update(`settings/logos/${id}/site-logo-create`);
        }
        return api.update(`settings/logos/${id}/${device}-header`);
      }
      case TYPES.FOOTER: {
        if (isAll) {
          return api.update(`settings/logos/${id}/site-logo-create`);
        }
        return api.update(`settings/logos/${id}/${device}-footer`);
      }
      case TYPES.REAL_ESTATE: {
        return api.update(`settings/logos/${id}/real-estate`);
      }
      case TYPES.WATERMARK: {
        return STORAGE.patch('settings/watermarks/logo', {
          logo_id: id,
        });
      }
      case TYPES.WHATSAPP: {
        return api.update(`settings/logos/${id}/site-whatsapp-logo`);
      }
      default:
        throw new Error('Tipo invalido');
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Seleciona uma logo na galeria passando o ID
 * @param type
 * @param device
 * @param id
 */
export const selectLogoById = (type, device, id) => {
  if (!isValidDevice(device)) throw new Error('Dispositívo invalido');
  switch (type) {
    case TYPES.HEADER:
      return api.update(`settings/logos/${id}/${device}-header`);
    case TYPES.FOOTER:
      return api.update(`settings/logos/${id}/${device}-footer`);
    case TYPES.REAL_ESTATE:
      return api.update(`settings/logos/${id}/real-estate`);
    case TYPES.WATERMARK:
      return api.update(`settings/logos/${id}/watermark`);
    default:
      throw new Error('Tipo invalido');
  }
};

export const getWhatsappLogo = () => api.getOne('settings/sites/whatsapp-logo');
export const removeWhatsappLogo = () =>
  api.delete('settings/sites/whatsapp-logo');

export const uploadWhatsappLogo = ({ image }) =>
  apiStorage.uploadFile(`sites/whatsapp-logo`, { image });

export const getFavicon = () => api.getOne('settings/sites/favicon');
export const uploadFavicon = ({ image }) =>
  apiStorage.uploadFile('sites/favicons', { image });
export const removeFavicon = () => apiStorage.delete('sites/favicons');
