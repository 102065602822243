import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
// Services
import * as peopleService from 'services/people';
// Components
import { ModalTemplate } from 'components/Modal';
import GroupForm from './components/GroupForm';

function ModalGroup({
  id,
  onSubmitSuccess,
  onSubmitFail,
  afterClose,
  modalConfig,
  isOpen,
  modalType,
  handleClose
}) {
  const [initialValues, setValues] = useState({});
  const [isFetching, setFetching] = useState(false);

  // Verifica se está em modo de edição a modal
  const isEditting = !!id;

  const fetchGroup = useCallback(async () => {
    try {
      setFetching(true);

      const { data } = await peopleService.getGroup(id);

      setValues(data);
    } finally {
      setFetching(false);
    }
  }, [id, setFetching, setValues]);

  useEffect(() => {
    fetchGroup();
  }, []);

  function handleSubmit(values) {
    if (isEditting) {
      return peopleService.updateGroup(values);
    }
    return peopleService.addGroup(values);
  }

  function handleCloseModal() {
    if (afterClose) afterClose();
  }

  function handleSubmitSuccess(...res) {
    handleClose();
    if (onSubmitSuccess) onSubmitSuccess(...res);
    handleCloseModal();
  }

  function handleSubmitFail(...res) {
    if (onSubmitFail) onSubmitFail(...res);
    handleCloseModal();
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      className={{
        base: 'Modal Modal--small'
      }}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={isFetching}
        title={isEditting ? 'Editar grupo' : 'Cadastrar grupo'}
        handleClose={handleClose}
      >
        <GroupForm
          initialValues={initialValues}
          isEditting={isEditting}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFail}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}

ModalGroup.defaultProps = {
  typeId: null
};

ModalGroup.propTypes = {};

export default ModalGroup;
