import styled from 'styled-components';

export const Wrapper = styled.label`
  position: relative;
  display: inline-block;

  select {
    display: none;
  }
`;

export const Link = styled.button`
  color: ${(p) => p.theme.colors.secondary};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
