import React, { useState } from 'react';
import Item from 'pages/PlanConfig/components/ListContracts/components/Item';
import {
  Dropdown,
  SubContract,
  TdDropdown,
} from 'pages/PlanConfig/components/ListContracts/styles';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import Detail from '../Detail';

// import { Wrapper } from './styles';

function SubContractComponent({ data, className, openOnStart = false }) {
  const [isExpanded, setIsExpanded] = useState(openOnStart);

  const status = ({ closed_at }) => {
    if (closed_at === null)
      return {
        type: 'active',
        text: 'Ativo',
      };

    return {
      type: 'finished',
      text: 'Finalizado',
    };
  };

  const STATUS = status(data);

  return (
    <>
      <SubContract isExpanded={isExpanded} className={className}>
        <td>
          <Item title="Data">{data.created_at}</Item>
        </td>
        <td colSpan={2}>
          <Item title="Tipo">{data.type}</Item>
        </td>
        <td colSpan={2}>
          <Item title="Nova data final">{data.end_at}</Item>
        </td>
        <td>
          <Item title="Alterado por">{data?.user?.name || '--'}</Item>
        </td>
        <TdDropdown>
          <Dropdown
            onClick={() => {
              setIsExpanded((value) => !value);
            }}
            style={{ float: 'right' }}
          >
            {isExpanded ? (
              <MdExpandLess size={20} />
            ) : (
              <MdExpandMore size={20} />
            )}
          </Dropdown>
        </TdDropdown>
      </SubContract>
      {isExpanded && <Detail contract={data} />}
    </>
  );
}

export default SubContractComponent;
