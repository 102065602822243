import React from 'react';
import PropTypes from 'prop-types';
// Components
import Icon from 'components/Icon';
import Ball from 'components/Ball';
import Button from 'components/Button';
import Dropdown, { Item, List } from 'components/Dropdown';

const propTypes = {
  index: PropTypes.shape({
    name: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
  }).isRequired,
};

const defaultProps = {};

const FinancialItem = ({
  index,
  index: {
    id,
    name,
    properties,
    updated_at,
    value,
    renew_date,
    is_site_shown,
    is_renovation_expired,
  },
  openModalIndexHistory,
  handleOpenModalUpdate,
  handleDeleteIndex,
  handleTogglePublic,
}) => (
  <tr>
    <td>{name}</td>
    <td>{updated_at}</td>
    <td>{value}</td>
    <td>
      {is_renovation_expired ? (
        <span className="h-color--danger">
          <Ball animation={false} color="danger" /> {renew_date}
        </span>
      ) : (
        renew_date
      )}
    </td>
    <td>{properties}</td>
    <td width={1}>
      <Dropdown isDropHover>
        <Button color="white" colorText="primary" size="medium">
          Opções <Icon name="chevron-down" />
        </Button>
        <List>
          <Item>
            <button onClick={() => openModalIndexHistory(id)}>Histórico</button>
          </Item>
          <Item>
            <button onClick={() => handleOpenModalUpdate(id)}>Editar</button>
          </Item>
          <Item>
            <button onClick={() => handleDeleteIndex(id)}>Excluir</button>
          </Item>
        </List>
      </Dropdown>
    </td>
  </tr>
);

FinancialItem.propTypes = propTypes;
FinancialItem.defaultProps = defaultProps;

export default FinancialItem;
