import React from 'react';
import { Field } from 'redux-form';
import { Checkbox } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
// import { Container } from './styles';

const FormBooking = ({ handleClose, submitting }) => {
  return (
    <>
      <p>
        Ao reservar um imóvel, dentro do painel, será mostrado no resultado de
        pesquisa e na vida do imóvel a marcação <strong>RESERVADO</strong>.
        Enquanto reservado, o imóvel não entrará mais no Radar de perfil de
        interesse.
      </p>
      <Field
        type="checkbox"
        className="h-margin-top--15"
        label="Não mostrar mais este imóvel no site e portais"
        name="should_change_publish"
        component={Checkbox}
      />
      <ModalFooter>
        <Button color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button color="success" disabled={submitting} type="submit">
          Reservar
        </Button>
      </ModalFooter>
    </>
  );
};

export default FormBooking;
