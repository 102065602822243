import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
// Services
import * as headersService from 'services/sites/headers';
// Components
import FormHeader from '../../components/FormHeader';
import Loading from 'components/Loading';
// Modules
import { deleteHeaderImage, fetchHeadersImages, headerImagesSelectors } from 'modules/components/headerImages';
import { fetchHeaders, headersSelectors } from 'modules/components/headers';
// Helpers
import { responseMessage } from 'lib/service-helpers';

class FormDevice extends React.Component {
  static propTypes = {
    form: PropTypes.string,
    device: PropTypes.oneOf(['mobile', 'desktop'])
  };

  static defaultProps = {
    form: 'FormHeaderDesktop',
    device: 'desktop'
  };

  state = {
    initialValues: {},
    isFetching: true
  };

  async componentDidMount() {
    this.fetchData();
  }

  /**
   * Seta os valores no formulário
   * @param data
   */
  setValues = data => this.setState({ initialValues: data });

  /**
   * Busca form data
   * @param device
   * @returns {Promise<any>}
   */
  fetchData = async () => {
    const { device } = this.props;

    // Busca todos os modelos de headers
    await this.props.fetchHeaders({ device });

    // Busca as imagens do header
    await this.props.fetchHeadersImages({ device });

    return headersService
      .getOne({ device })
      .then(({ data }) => {
        this.setState({ initialValues: data });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  };

  handleSubmit = ({ image, ...values }) => {
    const { device } = this.state;

    if (image) {
      return headersService
        .createImage({ image, type: 1 })
        .then(responseMessage('Imagem adicionada'))
        .then(() => {
          // Busca as imagens do header
          this.props.fetchHeadersImages({ device });

          return { data: values };
        });
    }
    return headersService.update({ ...values }).then(responseMessage('Salvo!'));
  };

  handleRemoveImage = id => e => {
    e.stopPropagation();
    this.props.deleteHeaderImage(id);
  };

  handleSubmitSuccess = (result, dispatch) => {
    // Reinicializa o formulário com os valores
    dispatch(
      initialize(
        this.props.form,
        {
          ...result.data,
          device: this.props.device
        },
        false
      )
    );

    this.setState(result.data);
  };

  /**
   * Pega os valores iniciais do formulário
   * @returns {{device: string}}
   */
  get initialValues() {
    const { initialValues } = this.state;
    const { device } = this.props;

    return {
      ...initialValues,
      device // Adiciona o device nos valores iniciais
    };
  }

  render() {
    const { form, headers, images } = this.props;
    const { isFetching } = this.state;

    if (isFetching) return <Loading isVisible />;

    return (
      <FormHeader
        form={form}
        onSubmit={this.handleSubmit}
        onSubmitSuccess={this.handleSubmitSuccess}
        initialValues={this.initialValues}
        headers={headers}
        images={images}
        handleRemoveImage={this.handleRemoveImage}
      />
    );
  }
}

const mapStateToProps = state => ({
  headers: headersSelectors.getAll(state),
  images: headerImagesSelectors.getAll(state)
});

const mapDispatchToProps = {
  fetchHeaders,
  fetchHeadersImages,
  deleteHeaderImage
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDevice);
