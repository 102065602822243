import React from 'react';
import { Field } from 'redux-form';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Select from '../../../../../components/Form/components/Select';
// Helpers
import { parseSelect } from '../../../../../lib/formHelpers';
// Form components
import Expand from '../../../../../components/Expand';
import { CheckboxGroup, Input, InputDate, Pill, RadioButton, SelectMultiple } from '../../../../../components/Form';

import GroupControl, { GroupItem } from '../../../../../components/GroupControl';
// Components
import Dropdown from '../../../../../components/Dropdown';
// Options
import FieldSituation from 'containers/FieldSituation';

export const FieldInterval = ({
  title,
  startDate,
  endDate,

  nameMin,
  nameMax
}) => (
  <Expand isOpen={false} label={title}>
    <GroupControl>
      <GroupItem>
        <Field
          selectsStart
          startDate={startDate}
          endDate={endDate}
          format={null}
          name={nameMin}
          component={InputDate}
        />
      </GroupItem>
      <GroupItem divider size={10} />
      <GroupItem>
        <Field
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          format={null}
          name={nameMax}
          component={InputDate}
        />
      </GroupItem>
    </GroupControl>
  </Expand>
);

export const FieldCheckboxGroup = ({ isOpen, title, data, ...props }) => (
  <Expand isOpen={isOpen} label={title}>
    <CheckboxGroup
      limit={5}
      valueKey="id"
      labelKey="title"
      {...props}
      options={data}
    />
  </Expand>
);

export const FieldSelect = ({ title, name, data, ...props }) => (
  <Expand isOpen={false} label={title}>
    <Field
      multi={false}
      name={name}
      options={data}
      valueKey="id"
      labelKey="title"
      {...props}
      component={SelectMultiple}
    />
  </Expand>
);

export const Rooms = ({ title, fields }) =>
  fields.length ? (
    <Expand isOpen label="Cômodos">
      {fields.length &&
        fields.map((field, index) => (
          <Field
            key={`${field.name}${index}`}
            label={field.title}
            name={`by_room_greater_equals[${field.name}]`}
            component={Pill}
          />
        ))}
    </Expand>
  ) : null;

export const SelectArea = ({ currentField, title, options, handleClick }) => (
  <Dropdown>
    {({ handleClose }) => {
      return (
        <React.Fragment>
          <div
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {currentField.title}
            {options && <MdKeyboardArrowDown />}
          </div>
          <Dropdown.list>
            {options &&
              options
                .filter(option => currentField.name !== option.name)
                .map(option => (
                  <Dropdown.item>
                    <button
                      type="button"
                      onClick={e => {
                        handleClose();
                        handleClick(option)(e);
                      }}
                    >
                      {option.title}
                    </button>
                  </Dropdown.item>
                ))}
          </Dropdown.list>
        </React.Fragment>
      );
    }}
  </Dropdown>
);

export class Area extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      field: props.fields ? props.fields[0] : { name: '', measures: ['m'] }
    };

    this.handleClickItem = this.handleClickItem.bind(this);
    this.clearFields = this.clearFields.bind(this);
  }

  componentDidMount() {
    this.clearFields(this.state.field);
  }

  clearFields(field) {
    if (field) {
      const { name, measures } = field;

      this.props.change(`by_area.measure`, measures[0]);
      this.props.change(`by_area.name`, name);
    }
  }

  handleClickItem(field) {
    return e => {
      e.stopPropagation();

      this.clearFields(field);

      this.setState({ field });
    };
  }

  render() {
    const { fields } = this.props;
    const { field } = this.state;

    return field ? (
      <Expand
        isOpen
        label={
          <SelectArea
            title={field.title}
            currentField={field}
            handleClick={this.handleClickItem}
            options={fields}
          />
        }
      >
        <GroupControl required={false}>
          <GroupItem>
            <GroupControl>
              <GroupItem>
                <Field
                  key={field.name}
                  name={`by_area.greater_equals`}
                  component={Input}
                />
              </GroupItem>
              <GroupItem divider size={7} />
              <GroupItem>
                <Field
                  key={field.name}
                  name={`by_area.lower_equals`}
                  component={Input}
                />
              </GroupItem>
            </GroupControl>
          </GroupItem>
          <GroupItem>
            <Field
              autoWidth
              placeholder=""
              name={`by_area.measure`}
              component={Select}
              options={field.measures.map(measure => ({
                label: measure,
                value: measure
              }))}
            />
          </GroupItem>
        </GroupControl>
      </Expand>
    ) : null;
  }
}

export const Situation = ({ typeId }) => (
  <Expand isOpen={false} label="Situação">
    <FieldSituation typeId={typeId} />
  </Expand>
);

export const PropertyCharacteristics = ({ data }) =>
  data && (
    <FieldCheckboxGroup
      isOpen
      title="Caracteristicas do imóvel"
      name="by_characteristic_id"
      data={data}
    />
  );

export const Condos = ({ data }) =>
  data && (
    <FieldSelect
      title="Condomínio"
      name="condominium_id"
      data={data}
      multi={false}
      parse={parseSelect('id')}
    />
  );

export const CondosCharacteristics = ({ data }) =>
  data.length ? (
    <FieldCheckboxGroup
      isOpen
      title="Características do Cond."
      name="by_condos_characteristic_id"
      data={data}
    />
  ) : null;

export const Establishments = ({ data }) =>
  data.length ? (
    <FieldCheckboxGroup
      isOpen
      title="Estabelecimentos"
      name="by_establishment_id"
      data={data}
    />
  ) : null;

//export const Financial = () => (
//  <FieldCheckboxGroup
//    title="Financiamentos"
//    name="is_financeable_mcmv"
//    labelKey="label"
//    valueKey="value"
//    data={[ { value: true, label: 'Casa Verde e Amarela' } ]}
//  />
//)

export const Financial = () => (
  <Expand isOpen label="Financiamentos">
    <label>
      <Field type="checkbox" name="is_financeable_mcmv" component="input" />{' '}
      Minha casa minha vida
    </label>
  </Expand>
);

export const Portals = ({ data }) => (
  <FieldCheckboxGroup
    title="Portais"
    name="by_portal_id"
    data={[
      { id: 'portal-1', title: 'Portal 1' },
      { id: 'portal-2', title: 'Portal 2' },
      { id: 'portal-3', title: 'Portal 3' },
      { id: 'portal-4', title: 'Portal 4' }
    ]}
  />
);

const FieldNetwork = ({ change, currentValue, label, value, ...props }) => (
  <li>
    <Field
      {...props}
      className="h-margin-right--5"
      type="radio"
      name="on_network"
      component={RadioButton}
      label={label}
      value={value}
      onClick={e => {
        if (value === currentValue) {
          setTimeout(() => change('on_network', null), 50);
        }
      }}
    />
  </li>
);

export const PropertyNetwork = ({ change, onNetwork }) => (
  <Expand isOpen label="Rede de imóveis">
    <ul className="h-list--none">
      <FieldNetwork
        change={change}
        currentValue={onNetwork}
        label="Parceiros"
        value="favoriteds"
      />
      <FieldNetwork
        change={change}
        currentValue={onNetwork}
        label="Órulo"
        value="orulo"
      />
      <FieldNetwork
        change={change}
        currentValue={onNetwork}
        label="Toda a rede"
        value="ALL"
      />
    </ul>
  </Expand>
);

export const FinancialIndexes = ({ data }) =>
  data && (
    <FieldCheckboxGroup
      title="Índice Financeiro"
      name="price_financial_index_id"
      labelKey="name"
      data={data}
    />
  );

export const Revision = ({ startDate, endDate }) => (
  <FieldInterval
    title="Data de Revisão"
    nameMax="by_revision_date_lower_equals"
    nameMin="by_revision_date_greater_equals"
    startDate={startDate}
    endDate={endDate}
  />
);

export const Exclusivity = ({ startDate, endDate }) => (
  <FieldInterval
    title="Com exclusividade entre"
    nameMax="exclusivity_date_max"
    nameMin="exclusivity_date_min"
    startDate={startDate}
    endDate={endDate}
  />
);

export const Realtors = ({ data }) => (
  <FieldCheckboxGroup
    title="Corretor Responsável"
    name="user_id"
    labelKey="name"
    data={data}
  />
);
