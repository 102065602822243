import _ from 'lodash';
import { createModule } from 'lib/reducer-helpers';
import { FieldsKey } from './constants';

const createAction = createModule('locations');

// Action Types
const REGISTER = createAction('REGISTER');
const UNREGISTER = createAction('UNREGISTER');
const CLEAR = createAction('CLEAR');

const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const REMOVE = createAction('REMOVE');

export { FieldsKey };

export const Selectors = {
  getData: (formName, key) => state => {
    try {
      return state.locations[formName][key].data;
    } catch (e) {
      return [];
    }
  },
  getIsFetching: (formName, key) => state => {
    try {
      return state.locations[formName][key].isFetching;
    } catch (e) {
      return false;
    }
  }
};

// Main Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          [action.key]: {
            data: [],
            isFetching: false
          }
        }
      };

    case REQUEST:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          [action.key]: {
            ...(state[action.formName]
              ? state[action.formName][action.key]
              : {}),
            isFetching: true
          }
        }
      };
    case RECEIVE:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          [action.key]: {
            data: action.data,
            isFetching: false
          }
        }
      };
    case REMOVE:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          [action.key]: {
            ...state[action.formName][action.key],
            data: _.filter(
              state[action.formName][action.key].data,
              item => item.id !== action.id
            )
          }
        }
      };

    case CLEAR:
      return {
        ...state,
        [action.formName]: _.omit(state[action.formName], action.key)
      };

    case UNREGISTER:
      return _.omit(state, action.formName);
    default:
      return state;
  }
}

// Action Creators
export const Actions = {
  register: (formName, key) => ({
    type: REGISTER,
    formName,
    key
  }),
  unregister: formName => ({ type: UNREGISTER, formName }),
  request: (formName, key) => ({
    type: REQUEST,
    formName,
    key
  }),
  remove: (formName, key, id) => ({
    type: REMOVE,
    formName,
    key,
    id
  }),
  receive: (formName, key, data) => ({
    type: RECEIVE,
    formName,
    key,
    data
  }),
  clear: (formName, key) => ({ type: CLEAR, formName, key })
};
