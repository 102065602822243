export const finishResponse = (values, dispatch, props) => res => {
  // if (values.isFinishing) {
  //   props?.history?.push('/properties/show');

  //   dispatch(
  //     openModalProperty({
  //       property: {
  //         id: values?.id
  //       }
  //     })
  //   );
  // }

  return res;
};
