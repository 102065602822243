import React from 'react';
import { Wrapper, Img } from './styles';
import noImage from 'components/PropertyItem/no-image.png';

const SingleValue = ({ data, children, ...props }) => {
  return (
    <Wrapper {...props}>
      <Img src={data?.images?.[0]?.file_url || noImage} />
      {data?.type?.title} - Ref.: {data?.reference}
    </Wrapper>
  );
};

export default SingleValue;
