import { createSelector } from 'reselect';
// Modules
import dataReducer from 'modules/data';
// Services
import * as domainsService from 'services/settings/domains';
import { showLoading, hideLoading } from 'modules/loading';

// Modules
export const {
  actionCreators: domainsActions,
  reducer: domainsReducer,
  selectors: domainsSelector
} = dataReducer('domains');

export const activeDomains = createSelector(
  [domainsSelector.getAll],
  domains => {
    return domains.filter(
      domain => [2, 3, 4].indexOf(domain.dns_status) !== -1
    );
  }
);

export const notActiveDomains = createSelector(
  [domainsSelector.getAll],
  domains => domains.filter(domain => [1].indexOf(domain.dns_status) !== -1)
);

// Thunks

/**
 * Busca uma lista de domínios
 * @param params
 */
export const fetchDomains = params => async dispatch => {
  dispatch(domainsActions.request());

  // Faz o request buscando a lista de domínios
  const { data } = await domainsService.getList(params);

  // Recebe
  dispatch(domainsActions.receive(data));

  return data;
};

/**
 * Deleta um domínio da lista de domínios atuais
 * @param domain
 * @return {Function}
 */
export const deleteDomain = domain => async dispatch => {
  dispatch(domainsActions.removing(domain.id));
  await domainsService.remove(domain.id);
  dispatch(domainsActions.remove(domain.id));
};

/**
 * Seta um domínio como principal
 * @param domain
 */
export const setPrimaryDomain = domain => async dispatch => {
  const res = await domainsService.setDomainPrimary(domain.id);

  // Atualiza a lista de domínios
  fetchDomains()(dispatch);

  return res;
};

/**
 * Verifica a conexão de um domínio específico
 * @param domainId
 * @returns {function(...[*]=)}
 */
export const checkConnection = domainId => async dispatch => {
  const { data: domain } = await domainsService.verifyDNS(domainId);
  dispatch(domainsActions.alter(domain));
};

/**
 * @param values
 * @returns {function(*): *}
 */
export const handleSubmitRegisterDomain = values => async dispatch => {
  dispatch(showLoading());
  try {
    const res = await domainsService.create(values);
    return res;
  } catch (err) {
    throw err;
  } finally {
    dispatch(hideLoading());
  }
};

export default domainsReducer;
