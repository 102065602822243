import TimelineTree, { DEFAULT_THEME } from 'components/TimelineTree';
import * as S from './styles';
import { useGetHistory } from 'features/Financial/hooks/useHistory';

export function TimeLine() {
  const { history, isFetchingHistory } = useGetHistory();

  return (
    <S.Wrapper>
      <TimelineTree
        isFetching={isFetchingHistory}
        data={history}
        theme={{
          ...DEFAULT_THEME,
          maxHeight: 'auto',
        }}
      />
    </S.Wrapper>
  );
}
