import styled from 'styled-components';

export const Ball = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(p) => p.color};
`;

export const Category = styled.div`
  display: flex;
  gap: 10px;
`;

export const SubCategory = styled.div`
  padding-left: 41px;
`;

export const Arrow = styled.div`
  width: 20px;
  height: 20px;
  margin-left: -15px;
  font-size: 20px;
  display: grid;
  place-items: center;
  margin-right: -5px;
`;
