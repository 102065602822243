import styled from 'styled-components';
import Button from 'components/Button';
import { darken } from 'polished';

export const Wrapper = styled.div``;
export const Title = styled.div.attrs(() => ({
  className: 'FormLabel',
}))`
  display: block;
`;

export const ChatGptButton = styled(Button)`
  background-color: #8e33cf;

  :hover:not([disabled]),
  .Dropdown--open &:not([disabled]),
  :active:not([disabled]),
  :focus:not([disabled]) {
    background-color: ${darken(0.2, '#8e33cf')} !important;
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Credits = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  cursor: pointer;

  .text {
    font-size: 10px;
  }
`;

export const IconCredit = styled.div`
  display: grid;
  place-items: center;
  width: 45px;
  height: 40px;
  color: #fff;
  background: #ffac46;
  border-radius: 4px;
  margin-right: 10px;
`;
