import React, { useEffect, useState } from 'react';
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, initialize, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import CarouselSelect from 'components/Form/components/CarouselSelect';
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { ColorPicker, Select, SelectMultiple, Textarea } from 'components/Form';
import Container from 'pages/Site/components/ContainerSite';
import ListOptions from 'components/ListOptions';
// Constants
import { BOOL, TYPE_SHOW_EMAIL } from 'constants/options';
import { parseSelect } from 'lib/formHelpers';
import useFormValue from 'hooks/useFormValue';
import { MdInfo } from 'react-icons/md';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const CustomToolbar = ({ children }) => (
  <div id="toolbar" className="ql-toolbar ql-snow ql-toolbar--custom">
    <div className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-link" />
    </div>
    <div className="h-flex__cell--grow" />
    <div className="ql-formats">{children}</div>
  </div>
);

const FieldBool = (props) => (
  <Field
    {...props}
    canRemoveSelf={false}
    buttonTemplate
    component={Select}
    options={BOOL}
    style={{ width: '120px' }}
  />
);

const FieldBoolEmail = (props) => (
  <Field
    {...props}
    canRemoveSelf={false}
    buttonTemplate
    component={Select}
    options={TYPE_SHOW_EMAIL}
    style={{ width: '120px' }}
  />
);

const FormHeader = ({
  footers,
  indexes,
  isFetchingIndexes,
  is_financial_index_shown,

  handleSubmit,
  change,
  submitting,
  pristine,
}) => {
  const [isShowEmail, setIsShowEmail] = useState(true);

  const isEmail = useFormValue('footer_is_email_shown');

  useEffect(() => {
    setIsShowEmail(isEmail);
  }, [isEmail]);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="footer_type"
        options={footers}
        component={CarouselSelect}
        className="h-margin-bottom--15"
      />
      <Container>
        <ListOptions>
          <ListOptions.item
            title="Cor do rodapé"
            text="Defina a cor que será utilizada no rodapé do seu site."
            renderOptions={() => (
              <Field name="footer_background_color" component={ColorPicker} />
            )}
          />
          {/*<ListOptions.item*/}
          {/*title="Menu"*/}
          {/*text="Mostrar o seu menu no rodapé do site?"*/}
          {/*renderOptions={() => <FieldBool name="footer_is_menu_shown"/>}*/}
          {/*/>*/}
          <ListOptions.item
            title={
              <>
                Redes Sociais{' '}
                <Link
                  className="h-color--secondary"
                  to="/site/config/realestate#redes-sociais"
                >
                  (Gerenciar)
                </Link>
              </>
            }
            text="Mostrar as redes sociais no rodapé do site?"
            renderOptions={() => <FieldBool name="footer_is_social_shown" />}
          />
          {/* <ListOptions.item
            title="Dados de contato"
            text="Mostrar os seus dados de contato (telefones e e-mail) no rodapé do site?"
            renderOptions={() => <FieldBool name="footer_is_contacts_shown" />}
          /> */}
          <ListOptions.item
            title={
              <>
                Índices financeiros{' '}
                <Link className="h-color--secondary" to="/financial">
                  (Gerenciar)
                </Link>
              </>
            }
            text="Mostrar os índices financeiros no rodapé do seu site?"
            renderOptions={() => (
              <>
                <Field
                  isLoading={isFetchingIndexes}
                  name="financial_indexes_id"
                  component={SelectMultiple}
                  options={indexes}
                  parse={parseSelect('id')}
                  style={{ width: '180px' }}
                  valueKey="id"
                  labelKey="name"
                  disabled={!is_financial_index_shown || indexes.length === 0}
                />
                <FieldBool
                  name="footer_is_financial_index_shown"
                  onChange={(e, value) => {
                    if (value && indexes.length === 0) {
                      Alert.success(
                        'Voce não possui nenhum índice financeiro cadastrado.'
                      );
                    }
                  }}
                />
              </>
            )}
          />
          <ListOptions.item
            title="Mostrar telefones?"
            renderOptions={() => <FieldBool name="footer_is_phones_shown" />}
          />
          <ListOptions.item
            title="Mostrar E-mail?"
            renderOptions={() => <FieldBool name="footer_is_email_shown" />}
          />
          {isShowEmail && (
            <ListOptions.item
              title={
                <>
                  Deixar e-mail visível ou protegido?
                  <span
                    data-html
                    className="h-color--secondary h-margin-left--5"
                    data-tip="Ao optar por proteger seu endereço de e-mail, em vez do seu e-mail ser diretamente visível,
                  um link será exibido com o texto  'Ver e-mail'. Ao clicar nesse link, o endereço de e-mail será revelado. Essa abordagem ajuda a evitar que robôs que rastreiam a internet coletem seu e-mail e o adicionem a listas de spam."
                  >
                    <MdInfo />
                  </span>
                </>
              }
              renderOptions={() => (
                <FieldBoolEmail name="footer_is_email_protected" />
              )}
            />
          )}
          <ListOptions.item
            title="Deixar filiais agrupadas?"
            renderOptions={() => <FieldBool name="footer_is_offices_grouped" />}
          />
          <ListOptions.item
            title="Anotação de rodapé"
            text="Área para breve anotação ou inclusão de algum indicador"
            renderOptions={() => (
              <div style={{ maxWidth: 302 }}>
                <Field
                  style={{ width: 302 }}
                  name="footer_text"
                  type="complex"
                  component={Textarea}
                  customToolbar={CustomToolbar}
                  modules={{
                    toolbar: {
                      container: '#toolbar',
                    },
                  }}
                />
              </div>
            )}
          />
        </ListOptions>
        <FixedBar style={{ left: 240 }}>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar
          </Button>
        </FixedBar>
      </Container>
    </form>
  );
};

FormHeader.defaultProps = defaultProps;
FormHeader.propTypes = propTypes;

const selector = formValueSelector('FormHeader');

const mapStateToProps = (state) => ({
  has_header_title: selector(state, 'has_header_title'),
  is_financial_index_shown: selector(state, 'footer_is_financial_index_shown'),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'FormHeader',
    onSubmitSuccess: (res, dispatch) => {
      // Reinicializa o formulário com os valores
      dispatch(initialize('FormHeader', res.data, false));
    },
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
  })
)(FormHeader);
