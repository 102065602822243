import React from 'react';
import TextOverflow from 'components/TextOverflow';
import * as PropTypes from 'prop-types';
import Actions from '../Actions';

const Title = ({ dealId, title, handleClickCloseDeal, handleClickLoss }) => {
  return (
    <div className="CardCrm__title">
      <TextOverflow width="120px" className="h-flex__cell--shrink">
        {title}
      </TextOverflow>
      <div className="h-flex__cell--grow" />
      <Actions
        dealId={dealId}
        handleClickCloseDeal={handleClickCloseDeal}
        handleClickLoss={handleClickLoss}
      />
    </div>
  );
};

Title.propTypes = {
  handleClickCloseDeal: PropTypes.func.isRequired,
  handleClickLoss: PropTypes.func.isRequired
};

export default Title;
