// helpers
import { createValidation } from 'lib/validate-helpers';

export default values => {
  const errors = {};

  const validations = createValidation(values, errors);

  validations.required(['label', 'script_code']);

  return errors;
};
