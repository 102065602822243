import qs from 'qs';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { change, getFormValues } from 'redux-form';
// Modules
import {
  openModalItinerary,
  openModalProperty,
  openModalPropertyCompare,
  openModalPropertyGroup,
  openModalPropertyMultipleExcluded,
  openModalPropertySendMail,
  openModalShareProperty,
  openModalVisit,
} from 'modules/modal';
import {
  getProperties,
  getPropertySelecteds,
  isNetworkSelector,
  propertiesIsFetchingSelector,
  propertiesSelecteds,
} from 'modules/properties';
import { Actions, getIsSearching } from 'pages/Properties/Filter/module';
import { Actions as SelectedsActions } from 'modules/selecteds';

// Components
import Loading from 'components/Loading';
import MainTitle from 'components/MainTitle';
import Pagination from 'components/Pagination';
import Wrapper from 'components/Wrapper';
import PropertyDashboard from 'containers/PropertyDashboard';
import ChangePrice from './ChangePrice';
import ChangePublish from './ChangePublish';
import Filter from './Filter';
import PropertiesList from './PropertiesList';
import FixedBarProperties from './components/FixedBarProperties';
// HOC
import { withPagination } from 'HOC/withPagination';

// Services
import * as propertiesService from 'services/properties';

// Helpers
import { plural } from 'lib/text';
import { clearUrlParams } from 'lib/url-helpers';

// Assets

class Properties extends Component {
  static defaultProps = {
    properties: null,

    getProperties: null,
    registerPagination: null,
    isVisible: {},
    hasFilter: {},
  };

  state = {
    multiplePropertyEditting: false,
    multiplePropertyPublish: false,
    type: 'vertical',
    status: null,
    isFetching: false,
  };

  get canRenderDashboard() {
    const { isSearching } = this.props;
    return !isSearching;
  }

  async componentDidMount() {
    const { location } = this.props;
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (search?.property || search?.condo) {
      clearUrlParams();

      if (search?.condo) {
        this.props.openModalPropertyGroup({
          property: { condominium_id: search?.condo },
        });
      } else {
        const { data: property } = await propertiesService.getOne(
          search.property
        );

        this.props.openModalProperty({ property });
      }
    }
  }

  componentWillMount() {
    this.loadType();
  }

  handleChangeType = (type) => () => {
    localStorage.setItem('typeList', type);
    this.setState({ type });
  };

  loadType = () => {
    const type = localStorage.getItem('typeList') || 'vertical';
    this.setState({ type });
  };

  handleToggleEditting = () => {
    this.setState({
      multiplePropertyEditting: !this.state.multiplePropertyEditting,
    });
  };

  handleTogglePublish = () => {
    this.setState({
      multiplePropertyPublish: !this.state.multiplePropertyPublish,
    });
  };

  handlePrintVisit = () => {
    const { openModalVisit, selectedProperties } = this.props;

    openModalVisit({
      properties: selectedProperties,
    });
  };

  handleRemoveAll = () => {
    const { openModalPropertyMultipleExcluded, selectedProperties } =
      this.props;

    openModalPropertyMultipleExcluded({
      propertiesId: selectedProperties,
      onSubmitSuccess: () => {
        this.props.resetSelecteds();
        // this.onChangePage({ selected: 0 });
      },
    });
  };

  onChangePage = (page) => {
    let params = {
      // filter: normalizeFilter(values),
      offset: page.selected + 1,
    };

    return this.props.getProperties(params);
  };

  handleDeleteProperty = () => {
    this.onChangePage({ selected: 0 });
  };

  toggleSelectedProperty = (id) => {
    this.props.toggleSelected(id);
  };

  handleClickAlterPrice = () => {};

  handleClickCompare = () => {
    const { unsetSelected, openModalPropertyCompare, selectedProperties } =
      this.props;

    openModalPropertyCompare({
      handleRemove: (id) => {
        unsetSelected(id);
      },
      propertiesIds: selectedProperties,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const nextStatus = this.props.match.params.status;
    const prevStatus = prevProps.match.params.status;

    if (nextStatus !== prevStatus) {
      this.setState({ status: nextStatus });
    }
  }

  handleOpenModalSendMail = (properties) => () =>
    this.props.openModalPropertySendMail({ properties });

  get renderMainTitle() {
    const { pagination } = this.props;

    const title = plural(
      pagination?.total || 0,
      'Resultado encontrado',
      'Resultados encontrados'
    );

    return <MainTitle title={title} />;
  }

  get renderProperties() {
    const {
      properties,
      pagination,
      isFetching,
      hasFilter,
      selectedProperties,
      openModalItinerary,
    } = this.props;
    const { multiplePropertyPublish, multiplePropertyEditting } = this.state;

    if (multiplePropertyEditting) {
      return (
        <ChangePrice
          properties={[...selectedProperties, 'to_edit']}
          handleToggleVisibility={this.handleToggleEditting}
        />
      );
    }

    if (multiplePropertyPublish) {
      return (
        <ChangePublish
          properties={[...selectedProperties, 'to_edit']}
          handleToggle={this.handleTogglePublish}
        />
      );
    }

    return (
      <>
        <PropertiesList
          type="vertical"
          properties={properties}
          hasFilter={!!hasFilter}
          selectedProperties={selectedProperties}
          toggleSelectedProperty={this.toggleSelectedProperty}
          handleDeleteProperty={this.handleDeleteProperty}
          handleOpenModalSendMail={this.handleOpenModalSendMail}
          rowClassName="propertyList"
        />
        <Loading isFullScreen isVisible={isFetching} />
        <Pagination
          forcePage={pagination.current_page - 1}
          pageCount={pagination.total_pages}
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          onPageChange={this.onChangePage}
        />
        <FixedBarProperties
          properties={selectedProperties}
          openModalItinerary={openModalItinerary}
          handleRemoveAll={this.handleRemoveAll}
          handleToggleEditting={this.handleToggleEditting}
          handleTogglePublish={this.handleTogglePublish}
          handlePrintVisit={this.handlePrintVisit}
          handleClickRemoveSelected={(property) => {
            this.props.unsetSelected(property.id);
          }}
          handleUncheckAll={() => {
            this.props.resetSelecteds();
          }}
          handleClickSendProperties={(type, title, props) => () => {
            this.props.openModalShareProperty({
              title,
              type,
              ...props,
              properties: selectedProperties,
            });
          }}
          handleClickAlterPrice={this.handleClickAlterPrice}
          handleClickCompare={this.handleClickCompare}
        />
      </>
    );
  }

  render() {
    return (
      <Wrapper full>
        <Helmet>
          <title>Imóveis</title>
        </Helmet>
        <Filter status={this.state.status} />
        {this.canRenderDashboard ? (
          <PropertyDashboard />
        ) : (
          <Wrapper.inner>
            <Wrapper.container style={{ maxWidth: '100%' }}>
              {this.renderMainTitle}
              {this.renderProperties}
            </Wrapper.container>
          </Wrapper.inner>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  isSearching: getIsSearching(state),
  values: getFormValues('FilterProperties')(state),
  hasFilter: state.visibility.filterProperties,
  isNetwork: isNetworkSelector(state),
  properties: getPropertySelecteds(state),
  isFetching: propertiesIsFetchingSelector(state),
  selectedProperties: propertiesSelecteds(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProperties,
      change,
      openModalProperty,
      openModalPropertyGroup,
      openModalItinerary,
      openModalPropertySendMail,
      openModalShareProperty,
      openModalPropertyMultipleExcluded,
      openModalPropertyCompare,
      openModalVisit,
      setSelected: (value) => SelectedsActions.setSelected('properties', value),
      unsetSelected: (value) =>
        SelectedsActions.unsetSelected('properties', value),
      toggleSelected: (value) =>
        SelectedsActions.toggleSelected('properties', value),
      resetSelecteds: (value) =>
        SelectedsActions.resetSelecteds('properties', value),
      ...Actions,
    },
    dispatch
  );

export default compose(
  withPagination('properties', {
    limit: 50,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(Properties);
