import React from 'react';
import { Field } from 'redux-form';
import { SelectMultiple } from 'components/Form';
import { parseSelect } from 'lib/formHelpers';

const checkIsDisabled = (option) => {
  if (option.quantity <= option.properties_count) {
    return { ...option, disabled: true };
  }

  return option;
};

const transformOptions = (option) => {
  return {
    ...option,
    name: `${option.name} (uso ${option.properties_count} de ${option.quantity})`,
  };
};

const getPortalOptionById = (id, options) => {
  return options.find((option) => option.id === id);
};

function FieldPortalOptions({ portalRealEstate }) {
  const options = portalRealEstate?.options
    .map(checkIsDisabled)
    .map(transformOptions);

  return (
    <Field
      canClear
      multi={false}
      name={`portal_real_estate_options.${portalRealEstate?.id}`}
      labelKey="name"
      valueKey="id"
      component={SelectMultiple}
      options={options}
      style={{ width: 250 }}
      valueRenderer={(option) => {
        const optionSelected = getPortalOptionById(
          option.id,
          portalRealEstate.options
        );

        return (
          <div>
            {optionSelected.name} (uso {optionSelected.properties_count} de{' '}
            {optionSelected.quantity})
          </div>
        );
      }}
      parse={parseSelect('id')}
    />
  );
}

export default FieldPortalOptions;
