import React from 'react';
import { Close, Text, Title, Wrapper } from './styles';
import { MdClose } from 'react-icons/md/index';
import Popover from 'components/Popover';
import FormClose from 'components/CanShowAgain/Form';

function Box({ title, text, children, handleClose }) {
  return (
    <Wrapper>
      <Popover
        width={310}
        initialValues={{ not_show_again: false }}
        onSubmit={handleClose}
        component={FormClose}
        style={{ display: 'inline' }}
      >
        <Close>
          <MdClose />
        </Close>
      </Popover>
      <Title>{title}</Title>
      <Text>{text}</Text>
      {children}
    </Wrapper>
  );
}

export default Box;
