import Container from "components/Container";
import { Input, Textarea } from "components/Form";
import GoogleResult from "components/GoogleResult";
import GroupControl, { GroupItem } from "components/GroupControl";
import MainTitle from "components/MainTitle";
import FieldUrl from "containers/FieldUrl";
import useFormValue from "hooks/useFormValue";
import { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { Field } from "redux-form";
import Reception from "./components/Reception";

function Seo({ change, hasSeoReception, setHasSeoReception }) {
  const [isVisible, setIsVisible] = useState(false);

  const { custom_url, meta_title, meta_description } = useFormValue([
    'custom_url',
    'meta_title',
    'meta_description'
  ]);

  return (
    <>
      {hasSeoReception ? (
        <Reception
          onClick={() => {
            setHasSeoReception(false);
          }}
        />
      ) : (
        <>
          <MainTitle
            title="Apresentação do imóvel nos resultados do Google (SEO)"
            text="Edite as informações abaixo com muita atenção, pois elas são muito importantes na busca deste imóvel no Google."
          />
          <Container>
            <Row middle="xs">
              <Col xs={6}>
                <Row>
                  <Field
                    xs={12}
                    label={
                      <>
                        Título da página{' '}
                        <a
                          href="https://support.google.com/webmasters/answer/35624?hl=pt-BR"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h-link"
                        >
                          (saiba mais)
                        </a>
                      </>
                    }
                    name="meta_title"
                    placeholder="Digite o título"
                    component={Input}
                    maxLength={120}
                  />
                  <Field
                    xs={12}
                    type="simple"
                    label="Descrição"
                    name="meta_description"
                    placeholder="Digite a descrição"
                    maxLength={156}
                    component={Textarea}
                  />
                  {isVisible ? (
                    <Col xs={12}>
                      <GroupControl label="URL Personalizada">
                        <GroupItem
                          label="seusite.com.br/detalhes/"
                          size="small"
                        />
                        <GroupItem>
                          <FieldUrl name="custom_url" />
                        </GroupItem>
                      </GroupControl>
                      <button
                        className="h-color--secondary"
                        onClick={() => {
                          change('custom_url', '');
                          setIsVisible(false);
                        }}
                      >
                        Manter padrão
                      </button>
                    </Col>
                  ) : (
                    <Col xs={12}>
                      <button
                        className="h-color--secondary"
                        onClick={() => {
                          setIsVisible(true);
                        }}
                      >
                        Modificar URL
                      </button>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={6}>
                <GoogleResult
                  url={`www.seusite.com.br/detalhes/${
                    custom_url ? custom_url : ''
                  }`}
                  title={meta_title}
                  description={meta_description}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default Seo;
