import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
// Services
import * as analyticsService from 'services/analytics';
// Components
import { SelectMultiple } from 'components/Form';
import Button from 'components/Button';
// Helpers
import { parseSelect } from 'lib/formHelpers';
// Validate
import validate from './validate';

const initialValues = {
  data: [],
  loading: false
};

class FormAccountSelect extends React.Component {
  static defaultProps = {
    handleSubmit: null,
    values: {
      account_id: null,
      management_id: null,
      analytics_profile_id: null
    }
  };

  static propTypes = {
    handleSubmit: PropTypes.func,
    values: PropTypes.shape({
      account_id: PropTypes.string,
      management_id: PropTypes.string,
      analytics_profile_id: PropTypes.string
    })
  };

  state = {
    accounts: initialValues,
    properties: initialValues,
    profiles: initialValues
  };

  componentDidMount() {
    if (!this.props.initialValues) return null;
    const { management_id, account_id } = this.props.initialValues;
    this.fetchAccounts();

    if (account_id) {
      this.fetchProperties(account_id);
    }

    if (account_id && management_id) {
      this.fetchProfiles(account_id, management_id);
    }
  }

  get isFetching() {
    return this.state.countFetching > 0;
  }

  request(key) {
    this.setState({
      [key]: {
        ...this.state[key],
        loading: true
      }
    });
  }

  receive(key, data) {
    this.setState({
      [key]: {
        data,
        loading: false
      }
    });
  }

  clear(key) {
    this.setState({
      [key]: initialValues
    });
  }

  fetchAccounts = async params => {
    this.request('accounts');
    try {
      const res = await analyticsService.getAccounts(params);
      this.receive('accounts', res.data.items);
    } catch (err) {
      if (err.response.status === 403) {
        if (this.props.onError) this.props.onError();
      }
    }
  };

  fetchProperties = async (accountId, params) => {
    this.request('properties');
    const {
      data: { items: properties }
    } = await analyticsService.getProperties(accountId, params);
    this.receive('properties', properties);
  };

  fetchProfiles = async (accountId, propertyId, params) => {
    this.request('profiles');
    const {
      data: { items: profiles }
    } = await analyticsService.getProfiles(accountId, propertyId, params);
    this.receive('profiles', profiles);
  };

  handleChangeAccount = (e, accountId) => {
    this.props.change('management_id', null);
    this.props.change('profile_id', null);
    this.clear('properties');
    this.clear('profiles');

    if (!accountId) {
      return null;
    }

    // Busca as properties
    this.fetchProperties(accountId);
  };

  handleChangeManagement = (e, managementId) => {
    const {
      values: { account_id }
    } = this.props;
    this.props.change('profile_id', null);
    this.clear('profiles');

    if (!managementId || !account_id) {
      return null;
    }

    // Busca os perfil
    this.fetchProfiles(account_id, managementId);
  };

  get isManagementDisabled() {
    const {
      values: { account_id }
    } = this.props;
    return !account_id;
  }

  get isProfileDisabled() {
    const {
      values: { account_id, management_id }
    } = this.props;
    return !account_id || !management_id;
  }

  render() {
    const { handleSubmit } = this.props;
    const { accounts, properties, profiles } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <div className="h-flex">
          <div className="h-flex__cell--grow">
            <Field
              multi={false}
              label="Conta"
              name="account_id"
              component={SelectMultiple}
              options={accounts.data}
              labelKey="name"
              valueKey="id"
              onChange={this.handleChangeAccount}
              isLoading={accounts.loading}
              className="h-margin-right--15"
              parse={parseSelect('id')}
            />
          </div>
          <div className="h-flex__cell--grow">
            <Field
              multi={false}
              disabled={this.isManagementDisabled}
              label="Propriedade"
              name="management_id"
              component={SelectMultiple}
              options={properties.data}
              labelKey="name"
              valueKey="id"
              onChange={this.handleChangeManagement}
              isLoading={properties.loading}
              parse={parseSelect('id')}
              className="h-margin-right--15"
            />
          </div>
          <div className="h-flex__cell--grow">
            <Field
              multi={false}
              disabled={this.isProfileDisabled}
              label="Visão"
              name="profile_id"
              component={SelectMultiple}
              options={profiles.data}
              valueKey="id"
              labelKey="name"
              isLoading={profiles.loading}
              parse={parseSelect('id')}
              className="h-margin-right--15"
            />
          </div>
          <div className="h-flex__cell--shrink">
            <Button type="submit" color="success" style={{ marginTop: '23px' }}>
              Salvar
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

const selector = formValueSelector('FormAccount');

const mapStateToProps = state => ({
  values: selector(state, 'account_id', 'management_id', 'profile_id')
});

export default compose(
  reduxForm({
    form: 'FormAccount',
    enableReinitialize: true,
    validate
  }),
  connect(mapStateToProps)
)(FormAccountSelect);
