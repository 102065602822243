import { useRequest } from 'hooks/useRequest';
import * as logosService from 'services/settings/logos';

export const useLogos = () => {
  const { data } = useRequest({
    request: () => logosService.getLogosPost(),
    initialState: {
      header: {},
      footer: {},
    },
  });

  return { data };
};
