import React from 'react';
// Component
import MyTable from 'components/Table';
import TableItem from '../TableItem';

const Table = ({ data }) => (
  <MyTable
    responsive
    data={data}
    isFetching={false}
    renderTitle={() => (
      <tr>
        <th>Mês</th>
        <th>Total de usuários</th>
        <th>Novos usuários</th>
      </tr>
    )}
    renderItem={(item, i) => {
      return <TableItem key={`analytics-${i}`} data={item} />;
    }}
  />
);

export default Table;
