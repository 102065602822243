import React from 'react';
import { useRequest } from 'hooks/useRequest';
import * as dealService from 'services/deals';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { SelectMultiple } from 'components/Form';
// import { Wrapper } from './styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
`;

const Title = styled.div`
  font-size: 11px;
  line-height: 11px;
  margin-bottom: 3px;
  color: #757575;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 14px;
`;

const valueRenderer = (group) => {
  return (
    <Wrapper>
      <Title>{group.group.name}</Title>
      <Text>{group.name}</Text>
    </Wrapper>
  );
};

const FieldAllStages = (props) => {
  const { data: stages, isFetching: isFetchingStages } = useRequest({
    request: () => dealService.getAllStages(),
  });

  return (
    <Field
      multi={false}
      isFetching={isFetchingStages}
      clearable={false}
      name="stage_id"
      component={SelectMultiple}
      valueRenderer={valueRenderer}
      labelKey="name"
      valueKey="stage_id"
      placeholder="Selecione uma posição"
      options={stages}
      {...props}
    />
  );
};

export default FieldAllStages;
