import React from 'react';
import * as PropTypes from 'prop-types';
import { IconDown, IconUp, Wrapper } from './styles';

function SortIcon({ direction }) {
  return (
    <Wrapper direction={direction}>
      <IconUp active={direction === 'asc'} />
      <IconDown active={direction === 'desc'} />
    </Wrapper>
  );
}

SortIcon.propTypes = {
  direction: PropTypes.oneOf(['asc', 'desc', null]),
};

export default SortIcon;
