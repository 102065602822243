import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Nodata from 'components/Nodata';
import Container from 'components/Container';
import Loading from '../../../../../../../../components/Loading';

function Table({ data, onDelete, onEdit, isFetching }) {
  if (isFetching) {
    return (
      <Container>
        <Loading isVisible isBlock />
      </Container>
    );
  }

  if (!data || data.length <= 0) {
    return (
      <Container>
        <Nodata
          image={null}
          title="Nenhuma Cidade cadastrada"
          text="Quando cadastrar uma cidade, ela aparecerá aqui."
        />
      </Container>
    );
  }

  return (
    <table className="Table">
      <thead>
        <tr>
          <th width={1}>UF</th>
          <th>Nome</th>
          <th>Quantidade</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((city) => (
            <tr key={city.id} className="h-hover">
              <td>{city?.state?.acronym}</td>
              <td>{city.name}</td>
              <td>
                {city.properties_count +
                  city.people_count +
                  city?.search_profiles_count}
              </td>
              <td className="Table__cell--small">
                <div className="h-hover__show">
                  <Button
                    color="white"
                    colorText="secondary"
                    onClick={onEdit(city)}
                  >
                    Editar
                  </Button>
                  <Button color="danger" onClick={onDelete(city)}>
                    Remover
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

Table.defaultProps = {
  data: null,
  onDelete: null,
  onEdit: null,
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
