import { Table } from 'features/Financial';
import * as S from './styles';
import { Nodata } from './Nodata';
import { AiOutlineFileText, MdOutlineReceipt } from 'react-icons/all';
import Button from 'components/Button';

export function TableBills({ data, account }) {
  if (!data || data.length <= 0) return <Nodata />;

  return (
    <Table.List data={data}>
      {(item) => {
        const paidValue = item.paid_value;
        return (
          <Table.Tr>
            <Table.Td>
              <S.AccountInfo>
                <S.Icon>
                  <MdOutlineReceipt />
                </S.Icon>
                <div>
                  <S.Title>{item.name}</S.Title>
                  <S.Text>
                    Fechamento {item.closing_date} | Vencimento{' '}
                    {item.billing_date}
                  </S.Text>
                </div>
              </S.AccountInfo>
            </Table.Td>
            <Table.Td width={200}>
              <S.Title>{item.value}</S.Title>
              {paidValue ? <S.Text>Valor pago {paidValue}</S.Text> : null}
            </Table.Td>
            <Table.Td width={165}>
              {item.situation ? (
                <S.Situation>{item.situation}</S.Situation>
              ) : null}
            </Table.Td>
            <Table.Td width={165}>
              <Table.Actions>
                <Button
                  color="white"
                  to={`/financial/credit-card/${account.id}/${item.id}`}
                >
                  <AiOutlineFileText /> Ver fatura
                </Button>
              </Table.Actions>
            </Table.Td>
          </Table.Tr>
        );
      }}
    </Table.List>
  );
}
