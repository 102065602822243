import React from 'react';
import Alert from 'react-s-alert';
import Modal from 'react-modal';

// Common Components
import { ModalTemplate } from 'components/Modal';

// Components
import Form from './components/Form';

// Services
import { updateLogo } from 'services/sites/logos';

function ModalLogoConfig({
  logo,
  title,
  device,
  type,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onSubmitSuccess
}) {
  const handleSuccess = () => {
    handleClose();
    if (onSubmitSuccess) onSubmitSuccess();
    Alert.success('Logo atualizada!');
  };

  const handleSubmit = values => {
    return updateLogo({ id: values?.id, device, type, ...values });
  };

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title={title} handleClose={handleClose}>
        <Form
          logo={logo}
          device={device}
          position={type}
          initialValues={logo}
          onSubmit={handleSubmit}
          handleClose={handleClose}
          onSubmitSuccess={handleSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalLogoConfig;
