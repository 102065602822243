import React from 'react';
import moment, { format } from 'lib/moment';
import Popover from 'components/Popover';
import * as documentsService from 'services/buildings/documents';
import FormUpdateDocument from 'containers/ModalRoot/ModalProperty/pages/Documents/components/FormUpdateDocument';
import { MdEdit, MdKeyboardArrowDown } from 'react-icons/md';
import Button from 'components/Button';
import Dropdown, { List, Item } from 'components/Dropdown';
// import { Container } from './styles';

const TableDocuments = ({ enterpriseId, data, onRemove, onEditSuccess }) => {
  return (
    <table className="Table">
      <thead>
        <tr>
          <th width={150}>Data</th>
          <th>Nome</th>
          <th width={1} />
        </tr>
      </thead>
      <tbody>
        {data.documents.map((item) => (
          <tr className="h-hover">
            <td>{moment(item.updated_at).format(format.datetime)}</td>
            <td>
              <Popover
                onSubmit={({ name }) => {
                  return documentsService.update({
                    building_id: enterpriseId,
                    id: item.id,
                    name,
                  });
                }}
                onSubmitSuccess={onEditSuccess}
                component={FormUpdateDocument}
                initialValues={{
                  name: item.name,
                }}
              >
                <div className="h-link">
                  {item.name} <MdEdit />
                </div>
              </Popover>
            </td>
            <td>
              <Dropdown isDropHover>
                <Button color="white" colorText="secondary">
                  Opções <MdKeyboardArrowDown />
                </Button>
                <List>
                  {item.file_url && (
                    <Item>
                      <a href={item.file_url} download>
                        Ver arquivo
                      </a>
                    </Item>
                  )}
                  <Item>
                    <button
                      type="button"
                      className="h-color--danger"
                      onClick={() => onRemove(item.id)}
                    >
                      Excluir
                    </button>
                  </Item>
                </List>
              </Dropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableDocuments;
