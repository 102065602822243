import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { CarouselSelect } from 'components/Form';
// Services
import * as postsAreaService from 'services/sites/posts-area';

function FieldCardPosts() {
  const [cards, setCards] = useState();

  async function fetchPosts() {
    const { data } = await postsAreaService.components();
    setCards(data);
  }

  useEffect(() => {
    fetchPosts();
  }, []);

  if (!cards) return null;

  return (
    <Field
      name="posts_card_type"
      options={cards}
      component={CarouselSelect}
      className="h-margin-bottom--15"
    />
  );
}

FieldCardPosts.propTypes = {};
FieldCardPosts.defaultProps = {};

export default FieldCardPosts;
