import React from 'react';
import { ReactComponent as Elo } from './assets/elo.svg';
import { ReactComponent as Diners } from './assets/diners-club.svg';
import { ReactComponent as Mastercard } from './assets/mastercard.svg';
import { ReactComponent as Visa } from './assets/visa.svg';
// import { Container } from './styles';

const CARDS_COMPONENTS = {
  elo: Elo,
  diners: Diners,
  mastercard: Mastercard,
  visa: Visa,
};

const CreditCardFlag = ({ name = 'mastercard', ...props }) => {
  if (!Object.keys(CARDS_COMPONENTS).includes(name)) return <div />;
  return React.createElement(CARDS_COMPONENTS[name], props);
};

export default CreditCardFlag;
