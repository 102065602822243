import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Modules
import { openModalProperty, openModalPropertyRent } from 'modules/modal';
// Components
import Dropdown, { Item, List } from 'components/Dropdown';
import Button from 'components/Button';
// Service
import * as propertiesService from 'services/properties';
import { PROPERTY_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';

const defaultProps = {
  property: null
};

const propTypes = {
  property: PropTypes.object
};

function Options({
  property,
  openModalProperty,
  openModalPropertyRent,
  fetchProperties
}) {
  return (
    <Dropdown isDropHover>
      <Button
        tagName="a"
        target="_blank"
        color="white"
        colorText="secondary"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        Opções
      </Button>
      <List>
        <Item>
          <button
            onClick={e => {
              e.stopPropagation();
              openModalProperty({
                property,
                afterClose: () => {
                  fetchProperties({ selected: 0 });
                }
              });
            }}
          >
            Ver
          </button>
        </Item>
        <Can
          run={PROPERTY_PERMISSIONS.REACTIVE}
          permissions={property.permissions}
        >
          <Item>
            <button
              onClick={e => {
                e.stopPropagation();

                propertiesService.reactivateProperty(property.id).then(() => {
                  fetchProperties({ selected: 0 });
                });
              }}
            >
              Mover para disponível
            </button>
          </Item>
        </Can>
      </List>
    </Dropdown>
  );
}

Options.defaultProps = defaultProps;
Options.propTypes = propTypes;

const mapDispatchToProps = {
  openModalProperty,
  openModalPropertyRent
};

export default connect(null, mapDispatchToProps)(Options);
