import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/Button';
import { Col, Row } from 'react-flexbox-grid';
import { FormLabel, Input, RadioButton, Select, Select2 } from 'components/Form';
import * as featuredListsServices from 'services/sites/featuredLists';
import { useRequest } from 'hooks/useRequest';
import { useEffect, useMemo } from 'react';
import useFormValue from 'hooks/useFormValue';
import * as S from './styles';
import { validate } from './validate';

function ModalFeaturedGallery({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  id,
  handleSubmit,
  initialize,
  change,
}) {
  const is_automatic = useFormValue('is_automatic');

  const isAutomatic = useMemo(() => {
    return is_automatic === 'AUTO';
  }, [is_automatic]);

  const { data, fetchData } = useRequest({
    request: () => featuredListsServices.getFeaturedList(id),
    initialFetch: false,
  });

  const isEditting = !!id;

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      if (data.is_automatic === 'MANUAL') {
        delete data.type;
        delete data.limit;
        delete data.property_sort;
      }
      initialize(data);
    } else {
      initialize({
        is_automatic: 'MANUAL',
      });
    }
  }, [id, data]);

  const clearFields = () => {
    change('type', null);
    change('limit', null);
    change('property_sort', null);
  };

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={isEditting ? 'Editar galeria' : 'Adicionar galeria'}
      >
        <form onSubmit={handleSubmit}>
          <Row>
            <Field
              maxLength={40}
              xs={12}
              label="Título da Galeria"
              name="title"
              placeholder="Digite o título desejado"
              component={Input}
            />
            <Col xs={12}>
              <FormLabel>
                Quais imóveis devem ser inseridos na galeria?
              </FormLabel>
              <S.RadioList>
                <Field
                  type="radio"
                  name="is_automatic"
                  label="Vou escolher e ordenar manualmente"
                  value="MANUAL"
                  component={RadioButton}
                  onChange={clearFields}
                />
                <Field
                  type="radio"
                  name="is_automatic"
                  label="O sistema deve preencher automáticamente"
                  value="AUTO"
                  component={RadioButton}
                />
              </S.RadioList>
            </Col>
          </Row>
          {isAutomatic ? (
            <>
              <Row className="h-margin-top--15">
                <Field
                  xs={8}
                  name="type"
                  label="Quais imóveis devem ser incluídos?"
                  component={Select}
                  options={[
                    { label: 'Últimos imóveis cadastrados', value: 'Novos' },
                    { label: 'Últimos imóveis editados', value: 'Editados' },
                  ]}
                />
                <Field
                  xs={4}
                  name="limit"
                  label="Quantidade"
                  component={Select}
                  options={[
                    { label: '40 imóveis', value: 40 },
                    { label: '32 imóveis', value: 32 },
                    { label: '24 imóveis', value: 24 },
                    { label: '16 imóveis', value: 16 },
                    { label: '8 imóveis', value: 8 },
                  ]}
                />
              </Row>
              <Row>
              <Field
                xs={5}
                name="transactions"
                label="Quais transações ?"
                component={Select2}
                options={[
                  { title: 'Venda', id: '1' },
                  { title: 'Locação', id: '2' },
                  { title: 'Temporada', id: '3' },
                ]}
                 selectedItemsLabel="transações"
              />
                <Field
                  xs={7}
                  name="property_sort"
                  label="Qual a forma de ordenação da galeria?"
                  options={[
                    {
                      label: 'Preço (Maior para menor)',
                      value: 'price,desc',
                    },
                    {
                      label: 'Preço (Menor para maior)',
                      value: 'price,asc',
                    },
                    {
                      label:
                        'Data cadastro (Mais recentes)',
                      value: 'created_at,desc',
                    },
                    {
                      label:
                        'Data cadastro (Mais antigos)',
                      value: 'created_at,asc',
                    },
                    {
                      label:
                        'Data alteração (Mais recentes)',
                      value: 'interacted_at,desc',
                    },
                    {
                      label:
                        'Data alteração (Mais antigos)',
                      value: 'interacted_at,asc',
                    },
                  ]}
                  component={Select}
                />
              </Row>
            </>
          ) : null}
          <ModalFooter>
            <Button type="button" onClick={handleClose} color="white">
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              {isEditting ? 'Editar galeria' : 'Adicionar galeria'}
            </Button>
          </ModalFooter>
        </form>
      </ModalTemplate>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalFeaturedGallery',
  validate,
  onSubmit: (values) => {
    return featuredListsServices.upsertFeaturedList(values);
  },
  onSubmitSuccess: (res, dispatch, props) => {
    if (props.onSuccess) props.onSuccess(res, dispatch, props);

    // Fecha a modal
    props.handleClose();
  },
})(ModalFeaturedGallery);
