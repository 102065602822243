import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import Menu from 'components/Menu';
import TableDealsClosed from './components/TableDealsClosed';
import TableDealsOpened from './components/TableDealsOpened';
// Modules
import { dealsSelector, isFetchingSelector } from 'modules/modalProperty';
import { openModalDeal } from 'modules/modal';

import { fetchLossDeals, fetchOpenedDeals } from './reducer';
// import PropTypes from 'prop-types';

const defaultProps = {};
const propTypes = {
  fetchOpenedDeals: PropTypes.func.isRequired,
  fetchLossDeals: PropTypes.func.isRequired,
};

class Business extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: props.menu || 'DealsOpened',
    };

    this.setMenu = this.setMenu.bind(this);
    this.handleClickDeal = this.handleClickDeal.bind(this);
  }

  componentDidMount() {
    // busca dados dos negocios em aberto
    this.setMenu(this.props.menu || 'DealsOpened');
  }

  setMenu(menu) {
    if (menu === 'DealsOpened') {
      // busca dados dos negocios em aberto
      this.props.fetchOpenedDeals(this.props.infos.id);
    } else {
      // busca dados dos negócios fechados
      this.props.fetchLossDeals(this.props.infos.id);
    }

    this.setState({
      ...this.state,
      menu,
    });
  }

  handleClickDeal(deal) {
    return () => this.props.openModalDeal(deal);
  }

  renderPage() {
    if (this.state.menu === 'DealsOpened') {
      return (
        <TableDealsOpened
          onClick={this.handleClickDeal}
          data={this.props.deals}
        />
      );
    }

    return (
      <TableDealsClosed
        onClick={this.handleClickDeal}
        data={this.props.deals}
      />
    );
  }

  render() {
    return (
      <div>
        <Menu
          modal
          currentPage={this.state.menu}
          handleClickItem={this.setMenu}
        >
          <Menu.item component="DealsOpened">EM ANDAMENTO</Menu.item>
          <Menu.item component="DealsLoss">PERDIDOS</Menu.item>
        </Menu>
        {this.renderPage()}
      </div>
    );
  }
}

Business.defaultProps = defaultProps;
Business.propTypes = propTypes;

const mapStateToProps = (state) => ({
  deals: dealsSelector(state),
  isFetching: isFetchingSelector(state),
});

const mapDispatchToProps = {
  openModalDeal,
  fetchOpenedDeals,
  fetchLossDeals,
};

export default connect(mapStateToProps, mapDispatchToProps)(Business);
