import React from 'react';
import { Icon } from '../../styles';

function BaseIcon({ size, children, ...props }) {
  return (
    <Icon
      width={size}
      height={size}
      viewBox={`0 0 30 30`}
      fill="none"
      {...props}
    >
      {children}
    </Icon>
  );
}

export default BaseIcon;
