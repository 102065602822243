import React from 'react';
import { Field, reduxForm } from 'redux-form';

// Components
import { Textarea } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

// Hooks
import NumberRating from 'components/Form/components/NumberRating';

// Validate
import validate from './validate';

function Form({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        required
        label="O tempo de espera para ser atendido foi satisfatório?"
        name="rate_waiting"
        component={NumberRating}
        size={90}
      />

      <Field
        required
        label="Como foi a cordialidade do atendente?"
        name="rate_cordiality"
        component={NumberRating}
        size={90}
      />

      <Field
        required
        label="Satisfação com a solução dada"
        name="rate_solution"
        component={NumberRating}
        size={90}
      />

      <Field
        label="Gostaria de deixar algum comentário?"
        type="simple"
        name="rate_description"
        component={Textarea}
      />
      <ModalFooter>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Enviar avaliação
        </Button>
      </ModalFooter>
    </form>
  );
}

Form.defaultProps = {};
Form.propTypes = {};

export default reduxForm({
  form: 'FormEvaluation',
  validate,
  enableReinitialize: true
})(Form);
