import React, { useState } from 'react';
import Modal from 'react-modal';
import Form from './containers/Form';
import Initial from './containers/Initial';
import Success from './containers/Success';

const pages = {
  Initial: {
    size: 665,
    component: Initial,
  },
  Form: {
    size: 460,
    component: Form,
  },
  Success: {
    size: 735,
    component: Success,
  },
};

function ModalPortalAdd({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  onSubmitSuccess,
}) {
  const [page, setPage] = useState('Initial');
  const [portal, setPortal] = useState(null);
  const currentPage = pages[page];

  const changePage = (pageName) => () => {
    setPage(pageName);
  };

  const handleSubmitSuccess = (res) => {
    if (onSubmitSuccess) onSubmitSuccess(res);
    setPortal(res.data);
    setPage('Success');
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
      style={{ content: { maxWidth: currentPage.size } }}
    >
      {React.createElement(currentPage.component, {
        modalConfig,
        isFetching,
        isOpen,
        modalType,
        handleClose,
        onSubmitSuccess: handleSubmitSuccess,
        changePage,
        currentPortal: portal,
        setPortal,
      })}
    </Modal>
  );
}

export default ModalPortalAdd;
