import React, { useEffect } from 'react';
import Layout from 'pages/Crm/components/Layout';
import Table from './components/Table';
import Filter from './components/Filter';
import { Content } from './styles';
import { useFilter } from 'pages/Crm/pages/History/hooks/useFilter';
import { useFormUserId } from 'pages/Crm/components/FormUser';
import { useRequest } from 'hooks/useRequest';
import { getDealsInactives } from 'services/crm';
import Loading from 'components/Loading';
import { plural } from 'lib/text';

function History() {
  const { by_inactive_status, updated_greater_equals, updated_lower_equals } =
    useFilter();

  const userId = useFormUserId();

  const { data, isFetching, meta, fetchData } = useRequest({
    request: ({ filter, offset, sort }) =>
      getDealsInactives({
        filter,
        sort,
        offset,
      }),
  });

  const handleFetchData = ({ offset, sort } = {}) => {
    fetchData({
      filter: {
        by_person_user_id: userId,
        by_inactive_status,
        updated_greater_equals,
        updated_lower_equals,
      },
      offset,
      sort,
    });
  };

  useEffect(() => {
    if (userId && by_inactive_status) {
      handleFetchData();
    }
  }, [
    userId,
    by_inactive_status,
    updated_greater_equals,
    updated_lower_equals,
  ]);

  return (
    <Layout
      title={
        <>
          <strong>Negócios encerrados</strong> |{' '}
          <strong>Lista de clientes</strong>
          {meta?.properties_count > 0 && (
            <>
              {' | '}
              {plural(meta?.properties_count, 'imóvel', 'imóveis')} |{' '}
              {plural(meta?.people_count, 'pessoa', 'pessoas')}
            </>
          )}
        </>
      }
      renderActions={() => <Filter />}
    >
      <Content>
        <Table data={data} meta={meta} fetchData={handleFetchData} />
        <Loading isVisible={isFetching} isFullScreen />
      </Content>
    </Layout>
  );
}

export default History;
