import api, { apiStorage } from 'services/index';

/**
 * Pega os dados do cabeçalho
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const get = (buildingId) =>
  api.getList(`settings/sites/buildings/${buildingId}/top`);

/**
 * Atualiza os dados do cabecalho
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = async ({ id, image, ...values }) => {
  if (image) {
    await apiStorage.uploadFile(`sites/buildings/${id}/cover`, { image });
  }
  //if (!values.image) return true;
  return api.create(`settings/sites/buildings/${id}/top`, values);
};

/**
 * Remove a imagem do topo
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const remove = (buildingId) =>
  apiStorage.delete(`sites/buildings/${buildingId}/cover`);
