import React from 'react';
import Modal from 'react-modal';
import { reduxForm } from 'redux-form';
import { ModalTemplate } from 'components/Modal';

function ModalUpdateContract({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  handleSubmit,
}) {
  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Alteração de contrato"
        text="Adição de assento para corretor/usuário"
        handleClose={handleClose}
        titleActions={() => (
          <>
            <p>Etapa</p>
            <p>1/3</p>
          </>
        )}
      ></ModalTemplate>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalUpdateContract',
})(ModalUpdateContract);
