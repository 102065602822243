import React from 'react';
import BaseIcon from '../BaseIcon';

function IconChecked({ size = 30, bgColor = 'white', ...props }) {
  return (
    <BaseIcon size={size} {...props}>
      <path
        d="M23.75 3.75H6.25C4.875 3.75 3.75 4.875 3.75 6.25V23.75C3.75 25.125 4.875 26.25 6.25 26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V6.25C26.25 4.875 25.125 3.75 23.75 3.75ZM23.75 23.75H6.25V6.25H23.75V23.75Z"
        fill="#0084F4"
      />
      <path d="M6.25 23.75H23.75V6.25H6.25V23.75Z" fill={bgColor} />
      <path
        d="M12.0312 17.6328L8.77344 14.375L7.66406 15.4766L12.0312 19.8438L21.4062 10.4687L20.3047 9.36719L12.0312 17.6328Z"
        fill="#0084F4"
      />
    </BaseIcon>
  );
}

export default IconChecked;
