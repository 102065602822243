// libs
import { createValidation } from 'lib/validate-helpers';

export default values => {
  const errors = {};
  const validations = createValidation(values, errors);

  validations.required(['rate_waiting', 'rate_cordiality', 'rate_solution']);

  return errors;
};
