import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

const PropertyChart = ({ graphs, height, data, colors, hasOruloDate }) => (
  <div className="h-flex">
    <div style={{ flex: 1, height }}>
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          fontWeight: 'bold'
        }}
      >
        Por Tipo
      </span>
      <ResponsiveContainer>
        <PieChart margin={{ left: 25, right: 25 }}>
          <Pie
            data={graphs.by_type}
            fill="#8884d8"
            dataKey="value"
            nameKey={'type'}
          >
            {data.map((dat, index) => (
              <Cell key={index} fill={dat.color} />
            ))}
          </Pie>
          <Tooltip cursor={{ fill: '#f0f2f5' }} />
        </PieChart>
      </ResponsiveContainer>
    </div>
    <div style={{ flex: 1, height }}>
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          fontWeight: 'bold'
        }}
      >
        Por Transação
      </span>
      <ResponsiveContainer>
        <PieChart margin={{ left: 25, right: 25 }}>
          <Pie
            data={graphs.by_transaction}
            fill="#8884d8"
            dataKey="value"
            nameKey={'type'}
          >
            {graphs.by_transaction.map((dat, index) => (
              <Cell key={index} fill={dat?.color} />
            ))}
          </Pie>
          <Tooltip cursor={{ fill: '#f0f2f5' }} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </div>
);

export default PropertyChart;
