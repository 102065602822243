import React from 'react';
// import { Container } from './styles';

const Dot = ({ color, noTransparency }) => {
  return (
    <svg
      stroke={color}
      fill={color}
      viewBox="0 0 9 25"
      preserveAspectRatio="none"
      fill-opacity={noTransparency ? 1 : 0.2}
    >
      <use href="#arrow" />
    </svg>
  );
};

//const Dot = ({ color }) => {
//  return (
//    <svg viewBox="0 0 9 25" preserveAspectRatio="none">
//      <g clip-path="url(#clip0)">
//        <path
//          d="M-11 0.5H-1.33546C-0.181396 0.5 0.898492 1.06891 1.55113 2.02071L7.09697 10.1088C7.88833 11.2629 7.91617 12.7775 7.16777 13.9599L1.52706 22.8718C0.885454 23.8855 -0.230643 24.5 -1.43033 24.5H-11C-12.933 24.5 -14.5 22.933 -14.5 21V4C-14.5 2.067 -12.933 0.5 -11 0.5Z"
//          fill={color}
//          fill-opacity="0.2"
//          stroke={color}
//        />
//      </g>
//      <defs>
//        <clipPath id="clip0">
//          <rect width="9" height="25" fill="white" />
//        </clipPath>
//      </defs>
//    </svg>
//  );
//};

export default Dot;
