import Alert from 'react-s-alert';
import { createModule } from '../lib/reducer-helpers';
import { getName } from '../lib/text';
import moment, { format } from '../lib/moment';
import * as peopleService from '../services/people';
import * as calendarsService from '../services/calendars';
import { time } from '../lib/helpers';

const createAction = createModule('personEvents');

const REQUEST_FORM_DATA = createAction('REQUEST_FORM_DATA');
const RECEIVE_FORM_DATA = createAction('RECEIVE_FORM_DATA');

const REQUEST_EVENTS = createAction('REQUEST_EVENTS');
const RECEIVE_EVENTS = createAction('RECEIVE_EVENTS');

const RECEIVE_EVENT = createAction('RECEIVE_EVENT');
const UPDATE_EVENT = createAction('UPDATE_EVENT');

const SHOW_EVENT_FORM = createAction('SHOW_EVENT_FORM');
const HIDE_EVENT_FORM = createAction('HIDE_EVENT_FORM');

const CANCEL_EVENT_REQUEST = createAction('CANCEL_EVENT_REQUEST');
const CANCEL_EVENT_SUCCESS = createAction('CANCEL_EVENT_SUCCESS');

const FINISH_EVENT_REQUEST = createAction('FINISH_EVENT_REQUEST');
const FINISH_EVENT_SUCCESS = createAction('FINISH_EVENT_SUCCESS');

const RESET_EVENTS = createAction('RESET_EVENTS');

export const eventsSelector = (state) => state.personEvents.events;

// Initial State
const initialState = {
  formData: {},
  events: [],
  meta: {
    isFetching: false,
    isFetchingFormData: false,
    showEventForm: false,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FORM_DATA:
      return { ...state, meta: { ...state.meta, isFetchingFormData: true } };
    case RECEIVE_FORM_DATA:
      return {
        ...state,
        formData: action.formData,
        meta: {
          ...state.meta,
          isFetchingFormData: false,
        },
      };
    case SHOW_EVENT_FORM:
      return { ...state, meta: { ...state.meta, showEventForm: true } };
    case HIDE_EVENT_FORM:
      return { ...state, meta: { ...state.meta, showEventForm: false } };
    case RECEIVE_EVENT:
      return {
        ...state,
        events: [...state.events, action.event],
        meta: { ...state.meta, isFetching: false, showEventForm: false },
      };
    case UPDATE_EVENT: {
      const newEvents = state.events.map((event) => {
        if (event.id === action.event.id) {
          return action.event;
        }
        return event;
      });

      return {
        ...state,
        events: newEvents,
        meta: { ...state.meta, showEventForm: false },
      };
    }
    case REQUEST_EVENTS:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE_EVENTS:
      return {
        ...state,
        events: action.events,
        meta: { ...state.meta, isFetching: false },
      };
    case FINISH_EVENT_SUCCESS:
    case CANCEL_EVENT_SUCCESS: {
      const newEvents = state.events.filter((event) => event.id !== action.id);
      return {
        ...state,
        events: newEvents,
        meta: { ...state.meta, showEventForm: false },
      };
    }
    case FINISH_EVENT_REQUEST:
    case CANCEL_EVENT_REQUEST: {
      const newEvents = state.events.map((event) => {
        if (event.id === action.id) return { ...event, isLoading: true };
        return event;
      });
      return { ...state, events: newEvents };
    }
    case RESET_EVENTS:
      return initialState;
    default:
      return state;
  }
}

/**
 * Action Creators
 */
const receiveFormData = (formData) => ({ type: RECEIVE_FORM_DATA, formData });
const showEventFormAction = () => ({ type: SHOW_EVENT_FORM });
const hideEventFormAction = () => ({ type: HIDE_EVENT_FORM });
const requestEvents = () => ({ type: REQUEST_EVENTS });
const receiveEvents = (events) => ({ type: RECEIVE_EVENTS, events });
const receiveEvent = (event) => ({ type: RECEIVE_EVENT, event });

const alterEvent = (event) => {
  Alert.success('Evento Alterado');
  return { type: UPDATE_EVENT, event };
};

const cancelEventRequest = (id) => ({ type: CANCEL_EVENT_REQUEST, id });
const cancelEventSuccess = (id) => ({ type: CANCEL_EVENT_SUCCESS, id });
const finishEventRequest = (id) => ({ type: FINISH_EVENT_REQUEST, id });
const finishEventSuccess = (id) => ({ type: FINISH_EVENT_SUCCESS, id });
const resetEvents = () => {
  return { type: RESET_EVENTS };
};

// side effects, only as applicable
// e.g. thunks, epics, etc
export const fetchPerson = (eventId, personId) => async (dispatch) => {
  try {
    const { data } = await peopleService.getEvent({
      event_id: eventId,
      person_id: personId,
    });

    const { start_date, end_date, ...rest } = data;

    const startDate = moment(start_date, format.datetime);
    const endDate = moment(end_date, format.datetime);
    const duration = endDate.diff(startDate, 'seconds');

    dispatch(
      receiveFormData({
        date_start: start_date,
        date_end: end_date,
        duration,
        ...rest,
      })
    );
  } catch {
    Alert.success('Erro ao buscar os dados do cliente');
  }
};

export const showEventForm = (event) => (dispatch) => {
  // Reseta os dados do formulário antes de cadastrar/editar
  dispatch(receiveFormData({}));

  // Mostra o formulário de cadastro
  dispatch(showEventFormAction());

  // Pega os dados do cliente
  const { people } = event;

  // Verifica se tem id do evento
  if (event.id) {
    return fetchPerson(event.id, people.id)(dispatch);
  }

  dispatch(
    receiveFormData({
      type: 1,
      color: '#0063c0',
      duration: '900',
      summary: `Ligar para ${getName(people.name)}`,
      reminder_minutes: '15',
      reminder_unit: 'minutes',
      date_start: moment().format(format.datetime),
      person_name: getName(people.name),
      people_id: people.id,
    })
  );
};

/**
 * Esconde o formulário de cadastro/edição de evento
 * @return {Function}
 */
export function hideEventForm() {
  return (dispatch) => {
    dispatch(receiveFormData({}));
    dispatch(hideEventFormAction());
  };
}

/**
 * Restaura um evento da lista
 * @param event
 * @return {function(*): T}
 */
export const restaureEvent = (event) => async (dispatch) => {
  const { data } = await peopleService.reactivateEvent(event);
  dispatch(receiveEvent(data));
  Alert.success(`${data.summary} restaurado(a)`);
  return data;
};

/**
 * Cancela um evento
 * @param event
 * @return {function(*=): Promise<T | never>}
 */
export function cancelEvent(event) {
  return (dispatch) => {
    dispatch(cancelEventRequest(event.id));

    return peopleService
      .cancelEvent(event)
      .then(() => {
        Alert.success('Evento cancelado', {
          customFields: {
            id: event.id,
            confirmText: 'Desfazer',
            onConfirm: () => {
              restaureEvent(event)(dispatch);
            },
          },
        });

        dispatch(cancelEventSuccess(event.id));
      })
      .catch(() => {
        Alert.success('Erro ao finalizar evento');
      });
  };
}

/**
 * Finaliza um evento
 * @param event
 * @return {function(*=): Promise<AxiosResponse<T> | never>}
 */
export function finishEvent(event) {
  return (dispatch) => {
    dispatch(finishEventRequest(event.id));

    return peopleService
      .finishEvent(event)
      .then(() => {
        Alert.success('Evento finalizado', {
          customFields: {
            id: event.id,
            confirmText: 'Desfazer',
            onConfirm: () => {
              restaureEvent(event)(dispatch);
            },
          },
        });

        dispatch(finishEventSuccess(event.id));
      })
      .catch(() => {
        return Alert.success('Erro ao finalizar evento');
      });
  };
}

/**
 * Cria um evento
 * @param values
 * @return {Function}
 */
export const createEvent = (values) => async (dispatch) => {
  try {
    const { data } = await peopleService.createEvent(values);
    dispatch(receiveEvent(data));
    Alert.success('Evento Criado');
  } catch {
    Alert.success('Não foi possível adicionar o evento');
    dispatch(hideEventForm());
  }
};

/**
 * Atualiza um evento
 * @param values
 * @return {Function}
 */
export const updateEvent = (values) => async (dispatch) => {
  try {
    const { data } = await peopleService.updateEvent(values);
    dispatch(alterEvent(data));
  } catch {
    Alert.success('Não foi possível alterar o evento');
  }
};

/**
 * Lida com o evento de submit do formulario de cadastro/edicao de evento
 * @param values
 * @return {Function}
 */
export const handleEventFormSubmit = (values) => async (dispatch) => {
  const { duration, date_start, ...rest } = values;
  const isEditting = !!values.id;
  let formData = { ...rest, start_date: date_start };

  if (duration) {
    formData = {
      ...formData,
      duration_minutes: time(duration, 'seconds', 'minutes'),
    };
  }

  if (!isEditting) {
    return createEvent(formData)(dispatch);
  }

  return updateEvent(formData)(dispatch);
};

export function getEvents(personId, params) {
  return (dispatch) => {
    dispatch(requestEvents());

    return calendarsService
      .getEventsByPersonId(personId, {
        ...params,
        sort: 'start_date',
      })
      .then(({ data }) => {
        dispatch(receiveEvents(data));
      });
  };
}

export function clearEvents() {
  return (dispatch) => {
    dispatch(resetEvents());
  };
}
