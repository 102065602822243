import React from 'react';
import ListTemplates from '../ListTemplates';
import TableHistory from '../TableHistory';
import { useTemplateContext } from '../../contexts/TemplateContext';
import { Wrapper } from './styles';

function Content() {
  const { category } = useTemplateContext();

  if (category === 'HISTORY') {
    return (
      <Wrapper>
        <TableHistory />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ListTemplates />
    </Wrapper>
  );
}

export default Content;
