import { useDispatch } from 'react-redux';
import { openModalMovimentDestroy } from '../modals/ModalMovimentDestroy';

export const useModalMovimentDestroy = () => {
  const dispatch = useDispatch();

  const handleOpenModalMovimentDestroy = ({
    postingId,
    movimentId,
    onSuccess,
  }) => {
    dispatch(
      openModalMovimentDestroy({
        postingId,
        movimentId,
        onSuccess,
      })
    );
  };

  return { handleOpenModalMovimentDestroy };
};
