import { useMemo } from 'react';

export const useTexts = ({ category, data, isEditing, isCategory }) => {
  return useMemo(() => {
    if (!isCategory) {
      if (data?.id && isEditing) {
        return {
          titleModal: 'Editar sub-categoria',
          labelTitle: 'Nome da sub-categoria',
          categoryTitle: category?.title,
        };
      }

      return {
        titleModal: 'Criar sub-categoria',
        labelTitle: 'Nome da sub-categoria',
        categoryTitle: category?.title,
      };
    }

    if (data?.id) {
      return {
        titleModal: 'Alterar categoria',
        labelTitle: 'Nome da categoria',
      };
    }

    return {
      titleModal: 'Criar categoria',
      labelTitle: 'Nome da categoria',
    };
  }, [data]);
};
