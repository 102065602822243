import styled from 'styled-components';
import ListOptions from 'components/ListOptions';

export const Wrapper = styled.form``;

export const WrapperTable = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;

  th,
  td {
    white-space: nowrap;
  }
  overflow-x: auto;
`;

export const List = styled(ListOptions)`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 1px solid #dedede;

  .ListOptions__item {
    padding: 15px 20px;
  }

  .FormGroup {
    margin-bottom: 0;
  }
`;
