import CompanyData from './CompanyData';
import Chat from './Chat';
import Domains from './Domains';
import LogoOptions from './LogoOptions';
import Popup from './Popup';
import Gallery from './Gallery';
import Seo from './Seo';
import ConfigCrmOwn from './ConfigCrmOwn';
import ConfigCrmOrulo from './ConfigCrmOrulo';
import ConfigCrmDwv from './ConfigCrmDwv';
import ScriptsPages from './ScriptsPages';
import ScriptsPagesForm from './ScriptsPagesForm';
import GlobalConfig from './GlobalConfig';
import Lgpd from './Lgpd';
import PropertyContact from './PropertyContact';
import VisitSchedule from './VisitSchedule';

export default {
  CompanyData,
  Chat,
  Domains,
  LogoOptions,
  Popup,
  Gallery,
  Seo,
  ConfigCrmOwn,
  ConfigCrmOrulo,
  ConfigCrmDwv,
  ScriptsPages,
  ScriptsPagesForm,
  GlobalConfig,
  Lgpd,
  PropertyContact,
  VisitSchedule,
};
