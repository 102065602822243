// Modules
import createDataReducer from 'modules/data';
// Services
import * as headersService from 'services/sites/headers';

export const {
  selectors: headersSelectors,
  actionCreators: headersActions,
  reducer
} = createDataReducer('components/headers');

/**
 * Busca todos os modelos de cabeçalhos
 * @param params
 * @return {Promise}
 */
export const fetchHeaders = params => dispatch => {
  dispatch(headersActions.request());

  return headersService.getComponents(params).then(res => {
    dispatch(headersActions.receive(res.data));
    return res;
  });
};

export default reducer;
