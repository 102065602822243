import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
// Modules
import { openModalProperty } from 'modules/modal';
// Containers
import Property from './components/Property';
import Title from './components/Title';

const defaultProps = {
  dealId: null,
  title: 'Sem título',
  isStagnant: false,
  property: null,
  person: null,

  onCloseDeal: null,
  onClickLoss: null,
  onClickCard: null
};
const propTypes = {
  dealId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isStagnant: PropTypes.bool,

  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }),

  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title_formatted: PropTypes.string.isRequired,
    calculated_price: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    reference: PropTypes.string
  }).isRequired,

  onCloseDeal: PropTypes.func,
  onClickLoss: PropTypes.func,
  onClickCard: PropTypes.func
};

const CardCrm = ({
  stage,
  dealId,
  isStagnant,
  property,
  onCloseDeal,
  onClickLoss,
  onClickCard,
  width,
  title,
  person,
  ...rest
}) => {
  const dispatch = useDispatch();

  /**
   * Abre o imóvel relacionado ao negócio
   * @type {(function(*))|*}
   */
  const openProperty = useCallback(
    e => {
      e.stopPropagation();
      dispatch(
        openModalProperty({
          property,
          initialPage: 'Notes',
          addNote: true,
          stage,
          person
        })
      );
    },
    [property]
  );

  const handleClickCloseDeal = useCallback(
    handleClose => e => {
      e.stopPropagation();

      // Fecha a popover
      handleClose();

      if (onCloseDeal) onCloseDeal(property);
    },
    [onCloseDeal]
  );

  const handleClickLoss = useCallback(
    handleClose => e => {
      e.stopPropagation();
      handleClose();

      // Se tiver o click de perdido
      if (onClickLoss) onClickLoss();
    },
    [onClickLoss]
  );

  useEffect(() => {
    // Rebuild do component
    ReactTooltip.rebuild();
  }, []);

  return (
    <div
      {...rest}
      className={classnames('CardCrm CardCrm--canDelete h-hover', {
        'CardCrm--danger': isStagnant
      })}
      style={{ width }}
      onClick={onClickCard}
    >
      <div className="CardCrm__content">
        <Title
          dealId={dealId}
          title={title}
          handleClickLoss={handleClickLoss}
          handleClickCloseDeal={handleClickCloseDeal}
        />
        <Property
          stage={stage}
          person={person}
          property={property}
          openProperty={openProperty}
        />
      </div>
    </div>
  );
};

CardCrm.defaultProps = defaultProps;
CardCrm.propTypes = propTypes;

export default CardCrm;
