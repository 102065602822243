import React from 'react';
import PropTypes from 'prop-types';
import ReactToggle from 'react-toggle';

import { CONTROL_SHAPE } from '../../../../constants/shapes';

import FormGroup from '../FormGroup';

const defaultProps = {
  label: null,
  required: false,
  className: null,
  formGroupClassName: null
};

const propTypes = {
  ...CONTROL_SHAPE,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxLength: PropTypes.number,
  type: PropTypes.string,
  autoFocus: PropTypes.bool
};

const Toggle = props => {
  const {
    required,
    maxLength,
    formGroupClassName,
    label,
    input,
    disabled,
    size,
    meta,
    style,
    ...anotherProps
  } = props;

  let styleWrapper = { lineHeight: '35px' };

  switch (size) {
    case 'small':
      styleWrapper = { lineHeight: '15px' };
      break;
    case 'large':
      styleWrapper = { lineHeight: '45px' };
      break;
    case 'medium':
    default:
      styleWrapper = { lineHeight: '35px' };
  }

  return (
    <FormGroup
      {...anotherProps}
      className={formGroupClassName}
      maxLength={maxLength}
      labelText={label}
      isRequired={required}
      isDisabled={disabled}
      input={input}
      meta={meta}
      style={style}
    >
      <div style={styleWrapper}>
        <ReactToggle
          checked={!!input.value}
          onChange={input.onChange}
          icons={false}
          className={`react-toggle--${size}`}
        />
      </div>
    </FormGroup>
  );
};

Toggle.defaultProps = defaultProps;
Toggle.propTypes = propTypes;

export default Toggle;
