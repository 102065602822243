import React from 'react';
import { reduxForm } from 'redux-form';
// Components
import FilterAside from 'components/FilterAside';
import * as Fields from './fields';
import { isEmpty, isEqual } from 'lodash';
import { MODES } from 'pages/Crm2/reducer';

function FilterCrm({ funnelId, handleSubmit, change, mode }) {
  return (
    <FilterAside title="Pesquisar">
      <form onSubmit={handleSubmit}>
        <Fields.SearchBy />
        <Fields.Rating />
        <Fields.Realtor />
        <Fields.LeadOrigin creatable={false} name="by_reception_source_id" />
        <Fields.Stage change={change} funnelId={funnelId} />
        <Fields.WithScheduledActivity />
        {mode === MODES.horizontal && <Fields.Sort />}
      </form>
    </FilterAside>
  );
}

let timeout = 0;

export default reduxForm({
  form: 'FilterCrm',
  enableReinitialize: true,
  onChange: (values, dispatch, ownProps, prevValues) => {
    if (isEmpty(prevValues) || isEqual(values, prevValues)) return;

    clearTimeout(timeout);
    timeout = setTimeout(() => ownProps.onSubmit(values), 350);
  },
})(FilterCrm);
