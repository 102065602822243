import styled from 'styled-components';

export const Wrapper = styled.table`
  width: 100%;
  border-spacing: 0;
  padding: 40px;

  th,
  td {
    :first-child {
      padding-left: 20px;
    }

    :last-child {
      padding-right: 20px;
    }
  }

  th {
    padding: 10px;
  }

  tr th {
    background-color: #e8eaef;
    text-align: left;
  }
`;
