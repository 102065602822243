export default values => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (values.is_condo && !values.condominium_id) {
    errors.condominium_id = REQUIRED_MSG;
  }

  return errors;
}
