import React, { Component } from 'react';
import { Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Components
import validate from './validate';
import PropertySaveModelForm from '../PropertySaveModelForm';
import { SelectMultiple, Textarea } from 'components/Form';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import Button from 'components/Button';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Popover from 'components/Popover';

class PropertySendMailForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func
  };

  handleModel = e => {
    if (!!e.value) {
      this.props.change('description', e.description);
    } else {
      this.props.change('description', null);
    }
  };

  handleDescription = () => {
    if (!!this.props.model) {
      this.props.change('model', null);
    }
  };

  render() {
    const {
      handleSubmit,
      handleClose,
      handleSubmitSaveModelForm,
      description,
      models,
      people,
      isFetchingPeople,
      isFetchingModel
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Field
            multi
            required
            xs={6}
            name="people"
            label="Cliente"
            placeholder="Selecione"
            component={SelectMultiple}
            options={people}
            valueKey="id"
            labelKey="name"
            isLoading={isFetchingPeople}
          />
          <Field
            xs={6}
            name="model"
            label="Modelo de resposta"
            placeholder="Selecione"
            component={SelectMultiple}
            multi={false}
            isLoading={isFetchingModel}
            options={models}
            onChange={this.handleModel}
          />
        </Row>
        <Row>
          <Field
            xs={12}
            name="description"
            label="Escreva um complemento para o cliente:"
            placeholder="Complemento"
            onChange={this.handleDescription}
            required
            component={Textarea}
          />
        </Row>
        <ModalFooter>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Popover
            onSubmit={handleSubmitSaveModelForm}
            component={PropertySaveModelForm}
          >
            <Button
              disabled={!description}
              className="h-margin-right--15"
              color="success"
            >
              Salvar Modelo
            </Button>
          </Popover>
          <Button type="submit" color="success">
            Enviar
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

const selector = formValueSelector('PropertySendMailForm');

const mapStateToProps = state => ({
  model: selector(state, 'model'),
  description: selector(state, 'description')
});

export default compose(
  reduxForm({
    form: 'PropertySendMailForm',
    validate,
    enableReinitialize: true
  }),
  connect(mapStateToProps)
)(PropertySendMailForm);
