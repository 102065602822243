import { search } from 'services/analytics';
// Helpers
import moment, { format } from 'lib/moment';
import { ORIGINAL } from 'lib/HTTP';

const transformRowsHitsOnPage = (rows, { startDate, endDate }) => {
  if (!rows) return [];

  // Pega o mês atual
  const currentMonth = moment(startDate).format('MM');

  // Pega o mês anterior da data inicial
  const prevMonth = moment(startDate)
    .subtract(1, 'month')
    .format('MM');

  // Resultado não acumulado
  const result = rows.reduce((acc, { dimensions, metrics }) => {
    const [day, month] = dimensions;
    const value = metrics[0].values[0];

    // Pega a chave aonde fica as informações do dia
    const key = `${day}-${currentMonth}`;

    // Pega a chave do mês
    const monthKey = month === prevMonth ? 'prev' : 'current';

    return {
      ...acc,
      [key]: {
        ...acc[key],
        [monthKey]: value || '0'
      }
    };
  }, {});

  // Transforma objeto para array
  const transformToArray = key => ({ month: key, ...result[key] });

  // Acumula os valores
  const accumulate = (acc, item, index) => {
    let prev = item.prev ? parseInt(item.prev, 10) : 0;
    let current = item.current ? parseInt(item.current, 10) : 0;

    if (index === 0) {
      return [
        ...acc,
        {
          ...item,
          prev: prev,
          current: current
        }
      ];
    }

    return [
      ...acc,
      {
        ...item,
        prev: parseInt(acc[index - 1].prev, 10) + prev,
        current: parseInt(acc[index - 1].current, 10) + current
      }
    ];
  };

  return Object.keys(result)
    .map(transformToArray)
    .reduce(accumulate, []);
};

//const transformRowsHitsOnPage = (rows, { startDate, endDate }) => {
//  if (!rows) return [];
//
//  const currentMonth = moment(startDate).format('MM');
//  const prevCurrentMonth = moment(startDate)
//    .subtract(1, 'month')
//    .format('MM');
//
//  const rowsDevice = rows.reduce((acc, row) => {
//    const [day, month] = row['dimensions'];
//    const value = row.metrics[0].values[0];
//    const date = moment(`${day}${currentMonth}`, 'DDMM').format('DD/MM');
//    const isCurrentMonth = month === currentMonth;
//
//    acc[date] = {
//      month: date,
//      ...acc[date]
//    };
//
//    if (isCurrentMonth) {
//      acc[date] = {
//        ...acc[date],
//        currentMonth: {
//          ...acc[date].currentMonth,
//          value
//        }
//      };
//    } else {
//      acc[date] = {
//        ...acc[date],
//        prevMonth: {
//          ...acc[date].prevMonth,
//          value
//        }
//      };
//    }
//
//    return acc;
//  }, {});
//
//  const rowsDeviceArray = toArray(rowsDevice);
//  const rowWithTotal = rowsDeviceArray.reduce((acc, row, i) => {
//    const mobile = row?.currentMonth?.mobile || 0;
//    const desktop = row?.currentMonth?.desktop || 0;
//
//    const prevMobile = row?.prevMonth?.mobile || 0;
//    const prevDesktop = row?.prevMonth?.desktop || 0;
//
//    const mobileInt = parseInt(mobile, 10);
//    const desktopInt = parseInt(desktop, 10);
//
//    const prevMobileInt = parseInt(prevMobile, 10);
//    const prevDesktopInt = parseInt(prevDesktop, 10);
//
//    const total = mobileInt + desktopInt;
//    const prevTotal = prevMobileInt + prevDesktopInt;
//
//    if (i === 0) {
//      if (!row?.currentMonth) {
//        return [
//          ...acc,
//          {
//            ...row,
//            prevTotal
//          }
//        ];
//      }
//
//      return [
//        ...acc,
//        {
//          ...row,
//          total,
//          prevTotal
//        }
//      ];
//    }
//
//    if (!row?.currentMonth) {
//      return [
//        ...acc,
//        {
//          ...row,
//          prevTotal: parseInt(acc[i - 1].prevTotal, 10) + prevTotal
//        }
//      ];
//    }
//
//    return [
//      ...acc,
//      {
//        ...row,
//        total: parseInt(acc[i - 1].total, 10) + total,
//        prevTotal: parseInt(acc[i - 1].prevTotal, 10) + prevTotal
//      }
//    ];
//  }, []);
//
//  return rowWithTotal.filter(row => row.month !== 'Invalid date');
//};

const reportHitsOnPage = ({ viewId, startDate, endDate }) => {
  const prevMonth = moment(startDate).subtract(1, 'month');
  const prevStartDate = prevMonth.startOf('month').format('YYYY-MM-DD');

  return {
    viewId,
    dateRanges: [{ startDate: prevStartDate, endDate }],
    metrics: [
      {
        expression: 'ga:pageviews',
        alias: 'Acessos'
      }
    ],
    dimensions: [{ name: 'ga:day' }, { name: 'ga:month' }],
    orderBys: [{ fieldName: 'ga:day' }],
    includeEmptyRows: true
  };
};

const transformDashboardResponse = (startDate, endDate) => async ({
  data: { reports }
}) => {
  const [hits] = reports;

  return {
    hits: transformRowsHitsOnPage(hits.data.rows, { startDate, endDate })
  };
};

export const getCurrentMonthData = async ({ viewId, startDate, endDate }) => {
  const response = await search({
    reportRequests: [
      reportHitsOnPage({ viewId, startDate, endDate })
      //{
      //  viewId,
      //  dateRanges: [{ startDate, endDate }],
      //  metrics: [
      //    {
      //      expression: 'ga:newUsers',
      //      alias: 'Usuários'
      //    },
      //    {
      //      expression: 'ga:pageviews',
      //      alias: 'Visitas'
      //    }
      //  ],
      //  dimensions: [
      //    {
      //      name: 'ga:deviceCategory'
      //    }
      //  ],
      //  includeEmptyRows: true,
      //  hideValueRanges: true,
      //  hideTotals: false
      //}
    ]
  });

  return transformDashboardResponse(startDate, endDate)(response);
};

export const getHitsData = async ({
  viewId,
  startDate: start,
  endDate: end
}) => {
  const startDate = moment(start, format.date).format('YYYY-MM-DD');
  const endDate = moment(end, format.date).format('YYYY-MM-DD');

  const { hits, byDevice } = await getCurrentMonthData({
    viewId,
    startDate,
    endDate
  });

  return {
    data: hits,
    byDevice
  };
};

export const getAllDashboardData = async ({ viewId, startDate, endDate }) => {
  //// Cria uma instancia do cache
  //const cache = new AnalyticsCache('dashboard_data3');
  //
  //// Adiciona a data inicial e final
  //// para que possa diferenciar o cache
  //cache.setDate(startDate, endDate);
  //
  //// Verifica se tem informação
  //// Se tiver retorna o que está no localStorage
  //if (cache.hasData()) {
  //  return cache.getData();
  //}

  // Pega as informações do mês passado
  const { data, byDevice } = await getHitsData({ viewId, startDate, endDate });

  //// Seta o cache
  //cache.setData({
  //  data,
  //  byDevice
  //});

  return {
    data,
    byDevice
  };
};

const fieldsAcc = [
  'people_total',
  'properties_total',
  'properties_to_review',
  'matcheds_people',
  'matcheds_properties',
  'deals_new',
  'deals_attending',
  'deals_stagnated'
];

export const getAdminTable = async () => {
  const res = await ORIGINAL.get('users/dashboard', {
    params: { sort: 'name' }
  });

  const totals = res.data.reduce(
    (acc, user) => {
      fieldsAcc.forEach(fieldName => {
        acc[fieldName] = acc[fieldName] + user[fieldName];
      });

      return acc;
    },
    {
      people_total: 0,
      properties_total: 0,
      properties_to_review: 0,
      matcheds_people: 0,
      matcheds_properties: 0,
      deals_new: 0,
      deals_attending: 0,
      deals_stagnated: 0
    }
  );

  return {
    ...res,
    meta: { totals }
  };
};
