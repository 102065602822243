import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import { ColorPicker, Input } from '../../components/Form';
import GroupControl, { GroupItem } from '../../components/GroupControl';
import Button from '../../components/Button';
import validate from './validate';

const defaultProps = {
  saveButtonText: 'Adicionar etapa'
};
const propTypes = {
  saveButtonText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

class FormStageAdd extends React.Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form
        onSubmit={(e, ...rest) => {
          e.preventDefault();
          e.stopPropagation();
          return handleSubmit(...rest);
        }}
      >
        <Field autoFocus label="Nome da etapa" name="name" component={Input} />

        <GroupControl label="Considerar Estagnado Após">
          <GroupItem>
            <Field name="stagnated_after" component={Input} />
          </GroupItem>
          <GroupItem label="Dias" size="small" />
        </GroupControl>

        <Field label="Cor da etapa" name="color" component={ColorPicker} />

        <Button block type="submit" color="success">
          {this.props.saveButtonText}
        </Button>
      </form>
    );
  }
}

FormStageAdd.defaultProps = defaultProps;
FormStageAdd.propTypes = propTypes;

export default reduxForm({
  form: 'FormStageAdd',
  validate,
  enableReinitialize: true
})(FormStageAdd);
