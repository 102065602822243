import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 40px 20px 20px;
`;

export const Inner = styled.div`
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  max-width: 900px;
`;
