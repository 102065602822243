import styled, { css } from 'styled-components';

export const Ball = styled.span`
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  background-color: ${p => p.theme.colors.primary};
  margin-right: 10px;
  z-index: 5;

  .isActive & {
    background-color: ${p => p.theme.colors.secondary};
  }
`;

export const Item = styled.button.attrs(() => ({
  type: 'button'
}))`
  margin: 0 -15px;
  display: flex;
  position: relative;
  width: calc(100% + 30px);
  text-align: left;
  padding: 10px 15px;
  cursor: pointer;
  align-items: center;
  border-radius: 4px;
  color: ${p => p.theme.colors.primary};

  ${p =>
    p.isDisabled &&
    css`
      color: #9f9f9f;
      pointer-events: none;

      ${Ball} {
        background-color: #9f9f9f;
      }
    `}

  ${p =>
    p.hasError &&
    !p.isDisabled &&
    css`
      color: ${p.colors.danger};
    `}

  & + & {
    :after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      background-color: ${p => p.theme.borderColor};
      top: -16px;
      left: 19px;
    }
  }

  &.isActive {
    color: ${p => p.theme.colors.secondary};
    font-weight: 700;

    > div {
      z-index: 10;
    }

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #e9eef5;
      border-radius: 4px;
      z-index: 5;
    }
  }
`;

export const ListAside = styled.div`
  margin: -8px -5px;
`;
