import * as userService from '../services/user';
import ReactTooltip from 'react-tooltip';
import msg from '../lib/msg';
import Alert from 'react-s-alert';
import { openConfirmation } from 'containers/ModalConfirmation/module';

const { createModule } = require("lib/reducer-helpers");

const createAction = createModule('invites');

const ADD_INVITE = createAction('ADD');

const REQUEST_INVITES = createAction('REQUEST');
const RECEIVE_INVITES = createAction('RECEIVE');

const REQUEST_INVITE_DELETE = createAction('REQUEST_DELETE');
const RECEIVE_INVITE_DELETE = createAction('RECEIVE_DELETE');

export const invitesSelector = (state) => state.invites.invites;
export const isFetchingSelector = (state) => state.invites.meta.isFetching;

const initialState = {
  invites: [],
  meta: {
    isFetching: false,
  },
  deleting: {
    id: null,
    name: null,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_INVITE: {
      return {
        ...state,
        invites: [action.invite, ...state.invites],
      };
    }
    case REQUEST_INVITES: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isFetching: true,
        },
      };
    }
    case RECEIVE_INVITES: {
      return {
        ...state,
        invites: action.invites,
        meta: {
          ...state.meta,
          isFetching: false,
        },
      };
    }
    case REQUEST_INVITE_DELETE: {
      const invites = state.invites.map((invite) => {
        if (action.id === invite.id) {
          return {
            ...invite,
            isDeleting: true,
          };
        }

        return invite;
      });

      return {
        ...state,
        invites,
      };
    }
    case RECEIVE_INVITE_DELETE: {
      return {
        ...state,
        invites: state.invites.filter((invite) => invite.id !== action.id),
        deleting: {
          ...state.deleting,
          id: action.id,
          name: action.name,
        },
      };
    }
    // do reducer stuff
    default:
      return state;
  }
}

// Action Creators
export const receiveInvite = (currentInvite) => ({
  type: ADD_INVITE,
  user: currentInvite,
});

export const requestInvites = () => ({ type: REQUEST_INVITES });
export const receiveInvites = (invites) => ({ type: RECEIVE_INVITES, invites });
export const requestInviteDelete = (id) => ({ type: REQUEST_INVITE_DELETE, id });
export const receiveInviteDelete = (id) => ({ type: RECEIVE_INVITE_DELETE, id });

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getInvites = (params) => (dispatch) => {
  dispatch(requestInvites());

  return userService
    .getInvites(params)
    .then(({ data }) => {
      dispatch(receiveInvites(data));
      ReactTooltip.rebuild();
      return data;
    });
};

export const addInvite = (currentInvite) => (dispatch) => {
  dispatch(receiveInvite(currentInvite));
};

const onSuccessInviteDelete = (user) => async (dispatch) => {
  Alert.success(msg.delete({ name: user.name }));

  dispatch(receiveInviteDelete(user.id));
};


export const deleteInvite = (invite) => async (dispatch) => {
  dispatch(requestInviteDelete(invite.id));

  dispatch(
    openConfirmation({
      title: 'Excluir convite?',
      text: 'Deseja realmente excluir esse convite?',
      request: () => userService.deleteInvite(invite.id),
      onSuccess: () => {
        onSuccessInviteDelete(invite)(dispatch);
      },
    })
  );
};
