import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialize } from 'redux-form';
// Modules
import { getNotes, handleFormSubmit } from 'modules/personNotes';
// Components
import Nodata from 'components/Nodata';
import { ModalSubtitle } from 'components/Modal';
import Button from 'components/Button';
import NotesForm from 'components/NotesForm';
// Assets
import img from 'assets/img/noData/notes.svg';
import NotesList from 'components/NotesList';
import FilterNotes from 'components/FilterNotes';
import { getStages } from 'components/FilterNotes/helpers';
import { useCategory } from 'components/NotesForm/hooks/useCategory';

const Notes = ({
  modalPerson: { person },
  property,
  stage,
  addNote,
  reopenModal,
}) => {
  const { data: categories } = useCategory();

  const [filter, setFilter] = useState('all');
  const [stages, setStages] = useState([]);
  const notes = useSelector((state) => state.personNotes.notes);

  const people_id = useSelector((state) => state.modalPerson.person.id);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (values) =>
    dispatch(handleFormSubmit({ ...values, people_id: people_id }));

  const handleSubmitSuccess = () => {
    setIsFormVisible(false);
    getNotes(people_id)(dispatch).then((data) => {
      setStages(getStages(data));
    });
  };

  const fetchNotes = (filter) => {
    let filterObj = {};

    if (filter !== 'all') {
      filterObj = { stage_id: filter };
    }

    dispatch(getNotes(people_id, filterObj));
  };

  const hasNotes = notes.length > 0;

  useEffect(() => {
    getNotes(people_id)(dispatch).then((data) => {
      setStages(getStages(data));
    });

    if (addNote) {
      setIsFormVisible(true);
    }
  }, [people_id]);

  useEffect(() => {
    if (notes.length === 0 && filter !== 'all') {
      setFilter('all');

      getNotes(people_id)(dispatch).then((data) => {
        setStages(getStages(data));
      });
    }
  }, [notes]);

  return (
    <div>
      {isFormVisible && (
        <NotesForm
          property={property}
          stage={stage}
          initialValues={{
            property_id: property?.id,
            stage_id: stage?.id,
            category_id: property?.category?.id,
          }}
          handleHideForm={() => setIsFormVisible(false)}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
        />
      )}
      {hasNotes ? (
        <div>
          {!isFormVisible && (
            <ModalSubtitle
              titleRenderer={() => (
                <FilterNotes
                  filter={filter}
                  setFilter={setFilter}
                  notes={notes}
                  stages={stages}
                  onChangeFilter={(filter) => {
                    setFilter(filter);
                    fetchNotes(filter);
                  }}
                />
              )}
            >
              <Button
                color="secondary"
                onClick={() => {
                  setIsFormVisible(true);
                }}
              >
                Adicionar anotação
              </Button>
            </ModalSubtitle>
          )}
          <NotesList
            person={person}
            data={notes}
            onUpdate={() => fetchNotes(filter)}
            afterClose={reopenModal}
            onClickEdit={(note) => () => {
              // Mostra a nota
              setIsFormVisible(true);

              // Inicializa o formulário com o valor da nota
              dispatch(
                initialize('NotesForm', {
                  ...note,
                  category_id: note?.category?.id,
                })
              );
            }}
          />
        </div>
      ) : (
        !isFormVisible && (
          <Nodata
            image={img}
            title="Nenhuma Anotação encontrada."
            text="Adicione a primeira anotação no botão abaixo:"
          >
            <Button
              className="h-margin-top--10"
              color="secondary"
              onClick={() => {
                setIsFormVisible(true);
              }}
            >
              Adicionar anotação
            </Button>
          </Nodata>
        )
      )}
    </div>
  );
};

export default Notes;
