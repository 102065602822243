import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import Icon from '../Icon';
import TreeNavLink from './components/TreeNavLink';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  links: PropTypes.array,
  isLink: PropTypes.bool
};

const defaultProps = {
  className: 'TreeNav',
  isLink: false,
  to: null
};

const TreeNavTitle = ({ children, isActive, isLink, to }) => {
  const hasLink = isLink && to;
  let Component = 'h2';

  if (hasLink) {
    Component = NavLink;
  }

  return (
    <Component
      to={to}
      className={classnames('TreeNav__title', {
        'TreeNav__title--is-active': isActive
      })}
    >
      {children}
    </Component>
  );
};

const hasPathname = name => (match, location) =>
  location.pathname.includes(name);

const TreeNav = ({
  style,
  isLink,
  children,
  to,
  title,
  icon,
  match,
  location,
  isActive
}) => (
  <div className="TreeNav" style={style}>
    <TreeNavTitle
      to={to}
      isLink={isLink}
      isActive={isActive || hasPathname(to)(match, location)}
    >
      <span className="TreeNav__item_icon">
        {typeof icon === 'function' ? icon() : <Icon name={icon} />}
      </span>
      {title}
    </TreeNavTitle>
    <div className="TreeNav__container">{children}</div>
  </div>
);

TreeNav.propTypes = propTypes;
TreeNav.defaultProps = defaultProps;
TreeNav.link = TreeNavLink;
TreeNav.title = TreeNavTitle;

export default withRouter(TreeNav);
export { TreeNavLink };
