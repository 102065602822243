import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  padding: 25px;

  img {
    margin: 0 auto;
  }

  h1 {
    font-size: 16px;
    color: #1a051d;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: #1a051d;
  }
`;
