import React from 'react';
// Components
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
// Components
import CardProperty from 'components/CardProperty';
// Assets
import house from './house-send.svg';

class PropertiesSended extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  componentDidMount() {
    this.props.fetchPropertiesNegotiated(this.props.person.id);
  }

  updateMatched = matched => async e => {
    e.stopPropagation();

    await this.props.updateMatched(matched);

    this.props.fetchPropertiesNegotiated(this.props.person.id);
  };

  render() {
    const { matcheds, isFetching } = this.props;

    if (isFetching) return <Loading isVisible />;

    if (matcheds.length === 0) {
      return (
        <Nodata
          style={{ maxWidth: 255 }}
          image={house}
          title="Nenhum imóvel negociado."
          text="Quando algum imóvel for negociado com o seu cliente, ele aparecerá aqui."
        />
      );
    }

    return matcheds.map((matched, i) => {
      return (
        <CardProperty
          key={`${i}-${matched.id}`}
          type="horizontal"
          property={matched.property}
          message={`Negociado em: ${matched.created_at}`}
          handleClickProperty={() => {
            this.props.openModalProperty({
              property: matched.property,
              afterClose: () => {
                this.props.handleOpenMySelf();
              }
            });
          }}
        />
      );
    });
  }
}

export default PropertiesSended;
