import React from 'react';
import tour from 'assets/img/tour.jpg';
import GalleryItem from '../GalleryItem';
// import { Wrapper } from './styles';

function GalleryTour({ url }) {
  return (
    <GalleryItem className="isTour">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={tour} alt="Tour" />
      </a>
    </GalleryItem>
  );
}

export default GalleryTour;
