import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Wrapper, WrapImage, BtnDelete, Content } from './styles';
import Image from 'components/Image';
import { Input } from 'components/Form';
import { Field } from 'redux-form';
import { useProgress } from 'pages/EnterpriseStore/pages/Progress/contexts/ProgressContext';

const CardPhoto = ({ name, data }) => {
  const { handleRemoveImage } = useProgress();

  return (
    <Wrapper>
      <WrapImage>
        <Image src={data.file_url} alt="" />
      </WrapImage>
      <Content>
        <Field label="Título" name={`${name}.title`} component={Input} />
      </Content>
      <BtnDelete
        type="button"
        onClick={handleRemoveImage && handleRemoveImage(data.id)}
      >
        <MdDelete />
      </BtnDelete>
    </Wrapper>
  );
};

export default CardPhoto;
