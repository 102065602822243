import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Modules
import {
  handleSetPosition,
  handleSetPov,
  handleSetZoom,
  mapPositionSelector,
  mapPovSelector,
  mapZoomSelector,
} from 'modules/mapView';
// Components
import MapView from 'components/MapView';

class MapContainer extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    position: PropTypes.object,
    pov: PropTypes.object,
    height: PropTypes.number,
    isExactPlace: PropTypes.bool,
    onChange: PropTypes.func,
    handleSetPosition: PropTypes.func.isRequired,
    handleSetPov: PropTypes.func.isRequired,
  };

  static defaultProps = {
    debug: true,
    position: { lat: 49.2853171, lng: -123.1119202 },
    pov: { heading: 0, pitch: 0, zoom: 0 },
    zoom: 15,
    height: 450,
    isExactPlace: false,
    onChange: null,
    handleSetPosition: null,
    handleSetPov: null,
  };

  /**
   * Seta a posição/ponto de vista no mapa
   * @param lat
   * @param lng
   * @param pov
   */
  setMap = ({ lat, lng, heading, pitch, zoom }) => {
    const { handleSetPosition, handleSetPov, onChange } = this.props;

    const position = {
      lat: lat ? lat() : this.props.position.lat,
      lng: lng ? lng() : this.props.position.lng,
    };

    const pov = {
      heading: heading || this.props.pov.heading,
      pitch: pitch || this.props.pov.pitch,
      zoom: zoom || this.props.pov.zoom,
    };

    onChange && onChange(position, pov);
    handleSetPosition(position);
    handleSetPov(pov);
  };

  /**
   * Lida com a mudança da posição do
   * @param latLng
   */
  handleDropMarker = ({ latLng }) => {
    this.setMap(latLng);
  };

  /**
   * Lida com a alteração do ponto de vista do StreetView
   * @param pov|Object - { heading: Number, pitch: Number, zoom: Number }
   */
  handleChangePov = (pov) => {
    this.setMap(pov);
  };

  onZoomChange = (zoom) => {
    if (this.props.onZoomChange) this.props.onZoomChange(zoom);

    // Muda o zoom do mapa
    this.props.handleSetZoom(zoom.zoom);
  };

  /**
   * Renderiza o debug do mapa
   * ajuda a verificar se os dados estão corretos
   * @returns {Node}
   */
  renderDebug = () => {
    const { debug, position, pov } = this.props;

    if (!debug) return null;

    return (
      <div
        style={{
          fontSize: '12px',
          backgroundColor: 'rgba(255,255,255, .5)',
          position: 'absolute',
          top: 10,
          right: 10,
          padding: 10,
          zIndex: 10,
        }}
      >
        <p className="h-margin-bottom--10">Position</p>
        <pre className="h-color--primary">
          {JSON.stringify(position, null, 2)}
        </pre>
        <p className="h-margin-bottom--10">Pov</p>
        <pre className="h-color--primary">{JSON.stringify(pov, null, 2)}</pre>
      </div>
    );
  };

  render() {
    const { height, isExactPlace, position, pov, zoom, style, showStreetview } =
      this.props;

    return (
      <MapView
        style={style}
        height={height}
        isExactPlace={isExactPlace}
        position={position}
        pov={pov}
        zoom={zoom}
        handleChangePov={this.handleChangePov}
        handleDropMarker={this.handleDropMarker}
        onZoomChange={this.onZoomChange}
        showStreetview={showStreetview}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  position: mapPositionSelector(state),
  pov: mapPovSelector(state),
  zoom: mapZoomSelector(state),
});

const mapDispatchToProps = {
  handleSetPov,
  handleSetPosition,
  handleSetZoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
