import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Divider = styled.div`
  width: 10px;
  height: 1px;
  background: #dedede;
`;
