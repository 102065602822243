import FormGroup from 'components/Form/components/FormGroup';
import { Input, Select } from 'components/Form/index';
import useReduxForm from 'hooks/useReduxForm';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Field } from 'redux-form';
import { Divider, Wrapper } from './styles';

function AreaField({
  withMeasures,
  hideError,
  formGroupClassName,
  maxLength,
  label,
  required,
  disabled,
  input,
  labelRender,
  groupName = 'by_area',
  name,
  onBlur,
  ...anotherProps
}) {
  const dispatch = useDispatch();
  const { autofill } = useReduxForm();

  const options = useMemo(() => {
    if (withMeasures) {
      return [
        { label: 'm²', value: 'm²' },
        { label: 'ha', value: 'ha' },
      ];
    }

    return [{ label: 'm', value: 'm' }];
  }, [withMeasures]);

  useEffect(() => {
    if (withMeasures) {
      dispatch(autofill(`${groupName}.${name}.measure`, 'm²'));
    } else {
      dispatch(autofill(`${groupName}.${name}.measure`, 'm'));
    }
  }, [withMeasures]);

  return (
    <FormGroup
      {...anotherProps}
      hideError={hideError}
      className={formGroupClassName}
      maxLength={maxLength}
      labelText={label}
      isRequired={required}
      isDisabled={disabled}
      labelRender={labelRender}
    >
      <Wrapper>
        <Field
          valueFormat
          prefix=" "
          name={`${groupName}.${name}.greater_equals`}
          component={Input}
          inputProps={{
            fixedDecimalScale: true,
            thousandSeparator: '.',
            decimalScale: 0,
          }}
          onBlur={onBlur}
        />
        <Divider />
        <Field
          valueFormat
          prefix=" "
          name={`${groupName}.${name}.lower_equals`}
          component={Input}
          inputProps={{
            fixedDecimalScale: true,
            thousandSeparator: '.',
            decimalScale: 0,
          }}
          onBlur={onBlur}
        />
        <Divider />
        <Field
          className="Field--area"
          name={`${groupName}.${name}.measure`}
          component={Select}
          placeholder=""
          options={options}
          onBlur={onBlur}
        />
      </Wrapper>
    </FormGroup>
  );
}

export default AreaField;
