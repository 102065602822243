import React from 'react';
import { Field, reduxForm } from 'redux-form';
// Components
import { FieldBool, Pill } from 'components/Form';
// Assets
import Container from 'components/Container';
import ListForm, { Item } from 'components/ListForm';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';

const OPTIONS_1 = [
  { label: '1', value: 1 },
  { label: '3', value: 3 },
  { label: '6', value: 6 },
  { label: '9', value: 9 },
  { label: '12', value: 12 },
];

const OPTIONS_2 = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
];

const OPTIONS_3 = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 },
  { label: '50', value: 50 },
];

function FormPropertyConfig({ handleSubmit, submitting, pristine }) {
  return (
    <form onSubmit={handleSubmit}>
      <Container padding style={{ marginTop: '15px' }}>
        <ListForm title="Geração automática de perfil">
          <Item
            text="Gerar perfil automaticamente ao receber contato em um imóvel?"
            renderOptions={() => (
              <FieldBool size="small" name="create_on_contact" />
            )}
          />
          <Item
            text="Gerar perfil automaticamente ao compartilhar um imóvel?"
            renderOptions={() => <FieldBool name="create_on_share" />}
          />
        </ListForm>
        <ListForm title="Caracteristicas do perfil">
          <Item
            text="Além do tipo do imóvel, considerar também o sub-tipo?"
            renderOptions={() => <FieldBool name="use_subtype" />}
          />
          <Item
            text="Além de pais, estado e cidade, incluir também o bairro?"
            renderOptions={() => <FieldBool name="use_neighborhood" />}
          />
        </ListForm>
        <ListForm title="Validade do perfil">
          <Item
            text="Se o imóvel  estiver na transação de VENDA, o perfil deve ter uma validade de quantos meses?"
            renderOptions={() => (
              <Field
                type="square"
                name="sale_expiration_months"
                options={OPTIONS_1}
                component={Pill}
              />
            )}
          />
          <Item
            text="Se o imóvel  estiver na transação de LOCAÇÃO, o perfil deve ter uma validade de quantos meses?"
            renderOptions={() => (
              <Field
                type="square"
                name="rent_expiration_months"
                options={OPTIONS_1}
                component={Pill}
              />
            )}
          />
          <Item
            text="Se o imóvel  estiver na transação de TEMPORADA, o perfil deve ter uma validade de quantos meses?"
            renderOptions={() => (
              <Field
                type="square"
                name="season_expiration_months"
                options={OPTIONS_1}
                component={Pill}
              />
            )}
          />
        </ListForm>
        <ListForm title="Intervalo de preço">
          <Item
            text="Se o imóvel  estiver na transação de VENDA, o preço deve ter uma variação de quantos porcento?"
            renderOptions={() => (
              <Field
                type="square"
                name="sale_price_percent"
                options={OPTIONS_2}
                component={Pill}
              />
            )}
          />
          <Item
            text="Se o imóvel  estiver na transação de LOCAÇÃO, o preço deve ter uma variação de quantos porcento?"
            renderOptions={() => (
              <Field
                type="square"
                name="rent_price_percent"
                options={OPTIONS_3}
                component={Pill}
              />
            )}
          />
          <Item
            text="Se o imóvel  estiver na transação de TEMPORADA, o preço deve ter uma variação de quantos porcento?"
            renderOptions={() => (
              <Field
                type="square"
                name="season_price_percent"
                options={OPTIONS_3}
                component={Pill}
              />
            )}
          />
        </ListForm>
      </Container>

      <FixedBar>
        <FixedBar.spacer />
        <FixedBar.item>
          <Button
            type="submit"
            color="success"
            disabled={submitting || pristine}
          >
            Salvar
          </Button>
        </FixedBar.item>
      </FixedBar>
    </form>
  );
}

export default reduxForm({
  form: 'FormProfileConfig',
  enableReinitialize: true,
})(FormPropertyConfig);
