// Widgets.js
import { change, getFormValues } from 'redux-form';
import Alert from 'react-s-alert';
import { createModule, item } from '../lib/reducer-helpers';
import * as condosService from '../services/condos';

const createAction = createModule('condosCharacteristics');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const REMOVE = createAction('REMOVE');

// Selectors
export const characteristicsSelector = (state) =>
  state.condosCharacteristics.characteristics;
export const isFetchingSelector = (state) =>
  state.condosCharacteristics.meta.isFetching;
export const characteristicsMetaSelector = (state) =>
  state.characteristics.meta;

// Initial State
const initialState = {
  characteristics: [],
  meta: {
    isFetching: false,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE:
      return {
        ...state,
        characteristics: action.characteristics,
        meta: { ...state.meta, isFetching: false },
      };
    case REMOVE:
      return {
        ...state,
        characteristics: item.remove(state.characteristics, action),
      };
    default:
      return state;
  }
}

// Action Creators
export function requestCharacteristics() {
  return { type: REQUEST };
}

export function receiveCharacteristics(characteristics) {
  return { type: RECEIVE, characteristics };
}

export function removeCharacteristic(id) {
  return { type: REMOVE, id };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export const deleteCharacteristic = (id) => (dispatch) =>
  condosService
    .removeCondosCharacteristics(id)
    .then((res) => {
      // remove a característica da store
      dispatch(removeCharacteristic(id));

      // mostra mensagem avisando que a característica foi removida com sucesso
      Alert.success('Característica removida');

      return res;
    })
    .catch(() => {
      Alert.success('Erro ao remover a característica');
    });

/**
 * Pega todas as características de um tipo de imóvel especifico
 * @param type_id
 * @return {function(*)}
 */
export function getCharacteristics(params) {
  return (dispatch) => {
    // loading das características
    dispatch(requestCharacteristics());

    // faz o request buscando as características
    return condosService
      .getCondosCharacteristics({
        sort: 'by_title',
        ...params,
      })
      .then(({ data: characteristics }) => {
        dispatch(receiveCharacteristics(characteristics));
        return characteristics;
      })
      .catch(() => {
        Alert.success('Problema ao buscar as características');
        dispatch(receiveCharacteristics([]));
      });
  };
}

/**
 * Lida com o cadastro de caracteristica
 * @param values
 * @return Promise
 */
export function handleFormCharacteristic(values) {
  return (dispatch) =>
    condosService
      .createCondosCharacteristics(values)
      .then(({ data }) => {
        Alert.success(`Característica ${values.title} adicionada`);
        // atualiza a lista de características
        getCharacteristics()(dispatch);
        return data;
      })
      .catch(() => {
        Alert.success('Erro ao adicionar característica');
      });
}

// Cadastro de característica
export const handleFormCharacteristicPartial =
  (values) => (dispatch, getState) => {
    const FORM = 'PropertyCondoCharacteristics';

    return condosService
      .createCondosCharacteristics(values)
      .then(({ data }) => {
        // Pega os dados do formulário
        const formdata = getFormValues(FORM)(getState());

        // atualiza a lista de características
        getCharacteristics()(dispatch);

        dispatch(
          change(FORM, 'condo_characteristics', {
            ...formdata?.condo_characteristics,
            [data.id]: { ...data, quantity: '', isChecked: true },
          })
        );
        return data;
      })
      .catch(() => {
        Alert.success('Erro ao adicionar característica do condomínio');
      });
  };
