import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { MdLock, MdLockOpen } from 'react-icons/md';
import { Field } from 'redux-form';

const propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool
};

const defaultProps = {
  name: null,
  checked: null
};

const RequiredLabel = ({ style, name, checked, ...rest }) => (
  <label
    className={classnames('RequiredLabel', {
      RequiredLabel__success: !checked,
      RequiredLabel__danger: checked
    })}
    style={style}
  >
    <Field
      {...rest}
      name={name}
      type="checkbox"
      component="input"
      style={{ display: 'none' }}
    />
    {checked ? <MdLock /> : <MdLockOpen />}
  </label>
);

RequiredLabel.propTypes = propTypes;
RequiredLabel.defaultProps = defaultProps;

export default RequiredLabel;
