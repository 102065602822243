import { removeCurrency } from './money-helpers';
import { isValid as isValidCPF } from '@fnando/cpf';
import { isValid as isValidCNPJ } from '@fnando/cnpj';
import { validate as validateEmail } from 'isemail';
import { LAZY_PASSWORDS } from '../constants/constants';

// Messages
export const MESSAGES = {
  required: 'Campo obrigatório',
  select: 'Por favor selecione uma opção',
  email: 'E-mail inválido',
  equals: 'Campos não conferem',
  password: 'Senhas não conferem',
  passwordWeak: 'Senha muito fraca',
  cpf: 'CPF inválido',
  cnpj: 'CNPJ inválido',
  date: 'Data inválida',

  // Price Messages
  priceRange: {
    rangeMinMax: 'Descupe mas a faixa de preço ...',
    valueMinGreaterThanMax: 'Valor mínimo deve ser menor que o valor máximo.',
    valueMaxLowerThanMin: 'Valor máximo deve ser menor que o valor mínimo',
  },
};

/**
 * Abstrai a criação de validações dos formulários
 * @param values
 * @return {Object} - retorna os erros e as validações dos formulários
 */
export const withValidation = (values) => {
  const errors = {};
  const validations = createValidation(values, errors);
  return { errors, validations };
};

// Errors
const required = (values, errors, message) => (name, anotherMessage) => {
  if (Array.isArray(name)) {
    validateRequiredArray(values, errors, name, message, anotherMessage);
  } else {
    if (!validateRequired(values[name])) {
      errors[name] = anotherMessage || message;
    }
  }
};

const requiredArrayField =
  (values, errors, message) => (array, fields, anotherMessage) => {
    validateArrayField(
      values,
      errors,
      message,
      validateRequired,
      array,
      fields,
      anotherMessage
    );
  };

const emailArrayField =
  (values, errors, message) => (array, fields, anotherMessage) => {
    validateArrayField(
      values,
      errors,
      message,
      validateEmail,
      array,
      fields,
      anotherMessage
    );
  };

const email = (values, errors, message) => (name, anotherMessage) => {
  if (!values[name] || !validateEmail(values[name])) {
    errors[name] = anotherMessage || message;
  }
};

const checkWeakness =
  (values, errors, message = MESSAGES.passwordWeak) =>
  (name) => {
    if (LAZY_PASSWORDS.includes(values[name])) {
      errors[name] = message;
    }
  };

const equals = (values, errors, message) => (names, anotherMessage) => {
  let error = false;
  names.reduce((prev, current) => {
    if (values[prev] !== values[current]) {
      error = true;
    }
    return current;
  });

  if (error) {
    names.forEach((name) => {
      errors[name] = errors[name] || ' ';
    });
    errors[names[0]] = anotherMessage || message;
  }

  checkWeakness(values, errors)(names[0]);
};

const cpf = (values, errors, message) => (name, anotherMessage) => {
  if (!validateCPF(values[name])) {
    errors[name] = anotherMessage || message;
  }
};

const cnpj = (values, errors, message) => (name, anotherMessage) => {
  if (!validateCNPJ(values[name])) {
    errors[name] = anotherMessage || message;
  }
};

// Validators
const validateRequiredArray = (
  values,
  errors,
  names,
  message,
  anotherMessage
) => {
  names.forEach((name) => {
    if (!validateRequired(values[name])) {
      errors[name] = anotherMessage || message;
    }
  });
};

const validateRequired = (value) => {
  if (!!Array.isArray(value) && !!value.length) return true; //Se for um array
  if (!!value) return true; //Se for qualquer outra coisa

  return false;
};

const date = (values, errors, message) => (name, anotherMessage) => {
  if (!values[name]) return true;
  if (
    !/(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/([12][0-9]{3}|[0-9]{2})$/.test(
      values[name]
    )
  ) {
    //if(!/(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/([12][0-9]{3})$/.test(values[name]) ) {
    errors[name] = anotherMessage || message;
  }
};

const validateArrayField = (
  values,
  errors,
  message,
  validation,
  array,
  fields,
  anotherMessage
) => {
  const arrayErrors = [];
  if (values[array]) {
    values[array].forEach((arr, index) => {
      const fieldError = {};
      fields.forEach((field) => {
        if (!validation(arr[field])) {
          fieldError[field] = anotherMessage || message;
          arrayErrors[index] = {
            ...(!!errors[array] ? errors[array][index] : {}),
            ...fieldError,
          };
        }
      });
    });
    if (arrayErrors.length) {
      errors[array] = arrayErrors;
    }
  }
};

/**
 * Valida faixa de preço entre dois inputs
 * @param values - todos os valores do formulário
 * @param errors - erros do formulário
 * @param message - mensagem que deve ser mostrada quando der erro
 * @return {Function} - Retorna a validacao do campo
 */
const priceRange =
  (values, errors, message) =>
  (nameMin, nameMax, percentage, anotherMessage) => {
    // pega a mensagem que ele enviar diferente
    const msg = anotherMessage || message;

    // Verifica se tem valor minimo e valor máximo
    if (values[nameMin] && values[nameMax]) {
      const min = removeCurrency(values[nameMin]);
      const max = removeCurrency(values[nameMax]);

      if (min > max) {
        errors[nameMin] = msg.valueMinGreaterThanMax;
        errors[nameMax] = ' ';
      }

      if (max > min * (percentage + 1)) {
        errors[nameMin] = msg.rangeMinMax;
        errors[nameMax] = ' ';
      }
    }
  };

const validateCPF = (value) => isValidCPF(value);
const validateCNPJ = (value) => isValidCNPJ(value);

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

/**
 * Função que retorna um objeto com as funções para validar o formulário
 * @param values - Valores do formulário
 * @param errors - Objeto vazio para setar os erros do formulário
 * @returns {{required: *, requiredSelect: *, email: *, equals: *, password: *, cpf: *, cnpj: *, priceRange: Function}}
 */
export const createValidation = (...params) => ({
  required: required(...params, MESSAGES.required),
  requiredSelect: required(...params, MESSAGES.select),
  requiredArrayField: requiredArrayField(...params, MESSAGES.required),
  email: email(...params, MESSAGES.email),
  emailArrayField: emailArrayField(...params, MESSAGES.email),
  equals: equals(...params, MESSAGES.equals),
  password: equals(...params, MESSAGES.password),
  cpf: cpf(...params, MESSAGES.cpf),
  cnpj: cnpj(...params, MESSAGES.cnpj),
  priceRange: priceRange(...params, MESSAGES.priceRange),
  date: date(...params, MESSAGES.date),
});
