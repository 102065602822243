import styled from "styled-components";

export const PixWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
`;

export const QrCode = styled.div`
  width: 250px;
  height: 250px;
  padding: 15px;

  img {
    width: 100%;
  }
`;

export const Instructions = styled.div`
  text-align: center;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  h2 {
    font-size: 16px;
  }

  p {
    max-width: 230px;
  }
`;

export const FailedVerification = styled.div`
  text-align: center;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  h2 {
    font-size: 16px;
    color: ${(p) => p.theme.colors.danger};
  }

  p {
    max-width: 230px;
  }
`;

export const Value = styled.p`
  font-weight: 600;
  text-align: center;
  margin-top: 6px;
`;
