import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// Components
import Loading from 'components/Loading';
import NoContent from 'components/NoContent';
import Nodata from 'components/Nodata';
import MainTitle from 'components/MainTitle';
import Container from 'pages/Site/components/ContainerSite';
import ListSlides from './components/ListSlides';
import FormUploadSlides from './components/FormUploadSlides';
// Modules
import {
  fetchSlides,
  handleDeleteSlide,
  handleSortSlide,
  handleUploadImages,
  slidesSelector,
} from './module';
// HOC
import withReception from 'HOC/withReception';
// Assets
import ribbon from 'assets/img/noData/ribbon.svg';
import image from './slides.png';

class Slides extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  componentDidMount() {
    this.props.fetchSlides();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items, handleSortSlide } = this.props;

    handleSortSlide(items[oldIndex], oldIndex, newIndex);
  };

  handleRemoveSlide = (slide) => () => {
    this.props.handleDeleteSlide(slide);
  };

  handleUploadImages = (values) => {
    this.props.setStorage();
    this.props.hide();
    this.props.handleUploadImages(values);
  };

  render() {
    const { reception, handleClickAction, items, isFetching, history } =
      this.props;

    if (reception.isVisible && items.length <= 0) {
      return (
        <NoContent
          full
          reversed
          image={image}
          title="Slides"
          text="Crie slides para divulgar seus imóveis ou outros links. Se preferir, envie um slide pronto."
        >
          <FormUploadSlides
            handleClickCreateSlide={(e) => {
              handleClickAction(e);
              history.push('/site/content-site/slides/create');
            }}
            onChange={this.handleUploadImages}
          />
        </NoContent>
      );
    }

    return (
      <>
        <MainTitle
          title="Slides"
          text="Crie seus slides a partir da nossa ferramenta ou envie seus slides prontos."
        >
          {!!items.length && (
            <FormUploadSlides
              styleButtonCreateSlide={{ marginRight: '-15px' }}
              onChange={this.handleUploadImages}
            />
          )}
        </MainTitle>
        <Container padding={false}>
          {!items.length && (
            <Nodata
              title="Você ainda não criou nem enviou nenhum slide."
              text="Que tal começar agora?"
              image={ribbon}
            >
              <FormUploadSlides
                onChange={this.handleUploadImages}
                style={{ marginTop: 15 }}
              />
            </Nodata>
          )}

          {isFetching ? (
            <Loading isVisible />
          ) : (
            <ListSlides
              axis="xy"
              distance={5}
              items={items}
              handleRemoveSlide={this.handleRemoveSlide}
              onSortEnd={this.onSortEnd}
            />
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  items: slidesSelector.getAll(state),
  isFetching: slidesSelector.isFetching(state),
});

const mapDispatchToProps = {
  fetchSlides,
  handleDeleteSlide,
  handleSortSlide,
  handleUploadImages,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withReception('site/slides', true)
)(Slides);
