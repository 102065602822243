import { Input, Select } from 'components/Form';
import FormGroup from 'components/Form/components/FormGroup';
import SelectLink from 'components/SelectLink';
import useReduxForm from 'hooks/useReduxForm';
import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { Divider, Wrapper } from './styles';

const Label = ({ label, value, areaFields, handleChange }) => {
  return (
    <div className="h-flex" style={{ width: 200 }}>
      <div className="h-flex__cell--grow">{label}</div>
      {areaFields && (
        <div className="h-flex__cell--shrink" style={{ marginTop: '-2px' }}>
          <SelectLink
            value={value}
            valueKey="name"
            labelKey="title"
            options={areaFields}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};

const getAreaByName = (name, areas) => {
  return areas?.find((area) => area.name === name);
};

function FieldGround({
  label = 'Área',
  name: groupName = 'by_area',
  type,
  onBlur,
}) {
  const { change, dispatch } = useReduxForm();
  const [name, setName] = useState(null);
  const areaFields = type?.area_fields || type?.type?.area_fields;
  const isDisabled = !areaFields;
  const selectedArea = getAreaByName(name, areaFields);

  const primaryArea = areaFields?.find((area) => area.is_primary);

  useEffect(() => {
    if (type && primaryArea?.name !== null) {
      setName(primaryArea?.name);
    }

    if (primaryArea?.name === undefined) {
      setName(areaFields?.[0]?.name);
    }
  }, [type, primaryArea, areaFields]);

  useEffect(() => {
    if (selectedArea?.measures) {
      dispatch(
        change(`${groupName}.${name}.measure`, selectedArea?.measures[0])
      );
    }
  }, [name, selectedArea]);

  const handleChange = (area) => {
    dispatch(change(groupName, {}));
    setName(area.value);
  };

  return (
    <FormGroup
      isDisabled={isDisabled}
      labelText={React.createElement(Label, {
        areaFields,
        value: name,
        label,
        handleChange,
      })}
    >
      <Wrapper>
        <Field
          disabled={isDisabled}
          valueFormat
          prefix=" "
          name={`${groupName}.${name}.greater_equals`}
          component={Input}
          inputProps={{
            fixedDecimalScale: true,
            thousandSeparator: '.',
            decimalScale: 0,
          }}
          onBlur={onBlur}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if (onBlur) onBlur(e);
            }
          }}
        />
        <Divider />
        <Field
          disabled={isDisabled}
          valueFormat
          prefix=" "
          name={`${groupName}.${name}.lower_equals`}
          component={Input}
          inputProps={{
            fixedDecimalScale: true,
            thousandSeparator: '.',
            decimalScale: 0,
          }}
          onBlur={onBlur}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (onBlur) onBlur(e);
            }
          }}
        />
        <Divider />
        <Field
          disabled={!selectedArea?.measures}
          className="Field--area"
          name={`${groupName}.${name}.measure`}
          component={Select}
          placeholder=""
          options={selectedArea?.measures?.map((item) => {
            return { value: item, label: item };
          })}
          onChange={onBlur}
        />
      </Wrapper>
    </FormGroup>
  );
}

export default FieldGround;
