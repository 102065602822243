import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Modules
import { openModalProperty } from 'modules/modal';

function PropertyShow() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      openModalProperty({
        property: { id },
        afterClose: () => {
          window.close();
        }
      })
    );
  }, [dispatch]);

  return <div />;
}

export default PropertyShow;
