import styled from 'styled-components';

export const Wrapper = styled.div`
  width: calc(100% + 39px);
  display: flex;
  flex-direction: column;
  margin-left: -19px;
`;

export const Page = styled.div`
  padding: 15px 0;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  padding: 0 20px;
`;

export const Nodata = styled.div`
  padding: 15px 0;
  font-size: 18px;
  font-weight: bold;
`;
