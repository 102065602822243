import { changePreview1, ITEM_TYPES } from 'services/financialv2/contracts';
import { openModalContractRemove } from 'modules/modal';
import { useDispatch } from 'react-redux';

export const useRemoveContract = ({ item = ITEM_TYPES.USERS, onSuccess }) => {
  const dispatch = useDispatch();

  const fetchChangePreview = async () => {
    const { data } = await changePreview1({ item, is_adding: false });
    return data;
  };

  const handleOpenModal = async (
    { isCancel = false, onSuccess: _onSuccess } = { isCancel: false }
  ) => {
    const preview = await fetchChangePreview();

    if (preview?.need_change_contract) {
      dispatch(
        openModalContractRemove({
          item,
          isCancel,
          onSuccess: _onSuccess ? _onSuccess : onSuccess,
        })
      );

      return false;
    }

    if (onSuccess) onSuccess({ should_change_contract: false });
  };

  return { handleOpenModal };
};
