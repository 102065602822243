import React, { useState } from 'react';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { plural } from 'lib/text';
import Button from 'components/Button';

// import { Wrapper } from './styles';

function ModalHasPaidCredits({ data, handleClose, handleCancel }) {
  const [isShow, setIsShow] = useState(false);

  const toggle = (e) => {
    e.preventDefault();
    setIsShow((state) => !state);
  };

  return (
    <ModalTemplate title="Cancelamento de contrato" handleClose={handleClose}>
      <p>
        No Tecimob você não tem nenhum tipo de obrigação de pagamento. Então não
        tem problema em adequar seu contrato as suas necessidades financeiras
        atuais.
        <br />
        <br />
        Vamos converter a parte que já foi pago do seu contrato em dias de
        crédito.
        <br />
        <br />
        Cancelando seu pacote hoje, não haverá mais nenhuma fatura para
        pagamento e sua nova data de renovação será em{' '}
        <strong>{data.new_end_at}</strong>
      </p>
      <p className="h-margin-top--5 h-margin-bottom--5">
        <a href="#" onClick={toggle} className="h-link">
          {isShow ? 'Esconder detalhes do cálculo' : 'Ver detalhes do cálculo'}
        </a>
      </p>
      {isShow && (
        <>
          <p>
            O valor a deduzir é <strong>{data.credits_price}</strong> das
            parcelas + <strong>{data.credits_bonus}</strong> do desconto, total{' '}
            <strong>{data.credits_total}</strong>
          </p>
          <p>
            O custo mensal do seu pacote é de{' '}
            <strong>{data.monthly_price}</strong>. A redução será de{' '}
            <strong>{data.substract_days}</strong>{' '}
            {plural(data.substract_days, 'dia', 'dias', false)}.
          </p>
          <p>
            Seu saldo final que era <strong>{data.old_end_at}</strong> mudará
            para <strong>{data.new_end_at}</strong>.
          </p>
        </>
      )}
      <ModalFooter>
        <Button type="button" onClick={handleClose} color="white">
          Voltar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button color="danger" onClick={handleCancel}>
          Cancelar meu pacote
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

export default ModalHasPaidCredits;
