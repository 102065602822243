import React, { Component, memo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
import Transition from 'react-transition-group/Transition';
import { MdDelete, MdEdit } from 'react-icons/md';
// Modules
// import { openModalCondominium } from 'modules/modal';
import { fetchLocation } from 'modules/propertyAdd';
import { condoCharacteristicsSelector, condosSelector, handleAddCondo, handleRemoveCondo } from 'modules/condos';
import { characteristicsSelector } from 'modules/condosCharacteristics';
import { establishmentsSelector } from 'modules/establishments';
import { openConfirmation } from 'containers/ModalConfirmation/module';
// Components
import ForEach from 'components/ForEach';
import FormBox from 'components/FormBox';
import { Toggle } from 'components/Form';
import Button from 'components/Button';
import Can from 'containers/Can';
import { USER_PERMISSIONS } from 'constants/rules';
import FieldCondosAsync from 'containers/FieldCondosAsync';

const propTypes = {
  characteristics: PropTypes.arrayOf(PropTypes.object),
  isCondo: PropTypes.bool.isRequired,
  condoId: PropTypes.string,
  condos: PropTypes.arrayOf(PropTypes.object),
  changeField: PropTypes.func.isRequired,
  // openModalCondominium: PropTypes.func.isRequired
};

const defaultProps = {
  characteristics: [],
  condos: [],
  isCondo: false,
  condoId: null
};

const List = ({ keyPrefix, cols, data, ...rest }) => (
  <Row tagName="ul" style={{ marginTop: '20px' }} {...rest}>
    <ForEach
      data={data}
      itemRenderer={({ id, title }) => (
        <Col {...cols} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          {title}
        </Col>
      )}
    />
  </Row>
);

List.defaultProps = {
  cols: { xs: 3 }
};

const duration = 150;

const defaultStyle = {
  transition: `all ${duration}ms`,
  opacity: 0,
  transform: 'translateX(-5%)'
};

const transitionStyles = {
  entering: { opacity: 0, transform: 'translateX(5%)' },
  entered: { opacity: 1, transform: 'translateX(0)' }
};

class Condominium extends Component {
  resetCharacteristics = () => {
    const { characteristics, change } = this.props;

    _.map(characteristics, characteristic => {
      change(`condos_characteristics.${characteristic.id}`, {
        id: characteristic.id,
        quantity: '',
        isChecked: false
      });
    });
  };

  resetEstablishments = () => {
    const { establishments, change } = this.props;

    _.map(establishments, establishment => {
      change(`establishments.${establishment.id}`, {
        id: establishment.id,
        quantity: '',
        isChecked: false
      });
    });
  };

  resetLocation = () => {
    const { change } = this.props;

    change('zip_code', '');
    change('street_number', '');
    change('street_address', '');
    change('complement_address', '');
    change('country_id', '');
    change('state_id', '');
    change('city_id', '');
    change('neighborhood_id', '');
  };

  reset = () => {
    // this.resetEstablishments();
    //this.resetLocation();
  };

  handleChangeIsCondominium = () => {
    // limpa o campo de condominio
    this.props.change('condominium_id', null);

    // Reseta as caracteristicas e estabelecimentos
    this.reset();
  };

  handleChangeCondo = (e, value) => {
    const { condos, change } = this.props;

    if (!value) return false;

    // Pega o condomínio que foi selecionado
    const condo = condos.find(condo => condo.id === value);
    const {
      condos_characteristics,
      establishments,
      relations,
      complement_address,
      street_address,
      street_number,
      zip_code,
      neighborhood_id
    } = condo;

    if (condos_characteristics) this.resetCharacteristics();
    if (establishments) this.resetEstablishments();
    if (neighborhood_id) this.resetLocation();

    _.map(establishments, establishment => {
      change(`establishments.${establishment.id}`, {
        id: establishment.id,
        quantity: establishment.quantity,
        isChecked: true
      });
    });

    // Tem que sempre marcar as caracteristicas que são do condomínio
    _.map(condos_characteristics, characteristic => {
      change(`condos_characteristics.${characteristic.id}`, {
        id: characteristic.id,
        quantity: characteristic.quantity,
        isChecked: true
      });
    });

    if (!relations) return null;

    const { country_id, state_id, city_id } = relations;

    // Busca todas as localizações
    this.props.fetchLocation(relations);

    if (country_id) change('country_id', country_id);
    if (state_id) change('state_id', state_id);
    if (city_id) change('city_id', city_id);
    if (neighborhood_id) change('neighborhood_id', neighborhood_id);

    if (zip_code) change('zip_code', zip_code);
    if (street_number) change('street_number', street_number);
    if (street_address) change('street_address', street_address);
    if (complement_address) change('complement_address', complement_address);
  };

  handleOpenModalCondo = id => () => {
    // this.props.openModalCondominium({
    //   id,
    //   onSubmitSuccess: values => {
    //     this.reset();
    //     // seleciona o novo
    //     this.props.changeField('is_condo', true);
    //     this.props.changeField('condominium_id', values.id);
    //   }
    // });
  };

  /**
   * Lida com a remocao do condomínio
   * @param id
   */
  handleRemoveCondo = id => () => {
    this.props.handleRemoveCondo(id, {
      onSuccess: () => {
        this.props.changeField('condominium_id', null);
      },
      onFinally: () => {
        this.reset();
      }
    });
  };

  render() {
    const { isCondo, condoId } = this.props;

    // verifica se pode mostrar ou não a lista de características
    const canShowDelete = !!condoId && isCondo;

    return (
      <FormBox
        title="Condomínio"
        className="js-condos"
        actions={
          <Can
            run={USER_PERMISSIONS.EDIT_CONDOS}
            yes={() => (
              <Button
                size="medium"
                color="secondary"
                onClick={this.handleOpenModalCondo()}
              >
                Cadastrar Condomínio
              </Button>
            )}
            no={() => (
              <span data-tip="Você não tem permissão de gerenciar condomínios">
                <Button disabled size="medium" color="secondary">
                  Cadastrar Condomínio
                </Button>
              </span>
            )}
          />
        }
      >
        <Row>
          <Field
            xs={2}
            label="Em Condomínio"
            name="is_condo"
            component={Toggle}
            onChange={this.handleChangeIsCondominium}
          />
          <Col xs={6}>
            <Row>
              <FieldCondosAsync
                xs={6}
                label="Selecione o condomínio"
                disabled={!isCondo}
                onChange={this.handleChangeCondo}
              />
              <Can run={USER_PERMISSIONS.EDIT_CONDOS}>
                <Transition
                  mountOnEnter
                  unmountOnExit
                  in={canShowDelete}
                  timeout={duration}
                >
                  {state => (
                    <>
                      <Col
                        xs={0}
                        style={{
                          ...defaultStyle,
                          ...transitionStyles[state]
                        }}
                      >
                        <Button
                          size="medium"
                          type="button"
                          color="secondary"
                          onClick={this.handleOpenModalCondo(condoId)}
                          style={{ marginTop: 24, verticalAlign: 'text-top' }}
                        >
                          <MdEdit />
                        </Button>
                      </Col>
                      <Col
                        xs={0}
                        style={{
                          ...defaultStyle,
                          ...transitionStyles[state]
                        }}
                      >
                        <Button
                          size="medium"
                          color="danger"
                          type="button"
                          onClick={this.handleRemoveCondo(condoId)}
                          style={{ marginTop: 24 }}
                        >
                          <MdDelete />
                        </Button>
                      </Col>
                    </>
                  )}
                </Transition>
              </Can>
            </Row>
          </Col>
        </Row>
      </FormBox>
    );
  }
}

Condominium.propTypes = propTypes;
Condominium.defaultProps = defaultProps;

const selector = formValueSelector('propertiesAdd');

const mapStateToProps = state => {
  const condoId = selector(state, 'condominium_id');
  return {
    establishments: establishmentsSelector(state),
    condos: condosSelector(state),
    condos_characteristics: condoCharacteristicsSelector(condoId)(state),
    characteristics: characteristicsSelector(state),
    isCondo: selector(state, 'is_condo'),
    condoId
  };
};

const mapDispatchToProps = {
  fetchLocation,
  // openModalCondominium,
  openConfirmation,
  handleRemoveCondo,
  handleAddCondo
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Condominium));
