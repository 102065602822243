import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Components
import MainTitle from 'components/MainTitle';
// Containers
import UploadLogo from 'containers/UploadLogo';
// Services
import * as logosService from 'services/sites/logos';
// Modules
import { openModalGallery } from 'modules/modal';

class LogoOptions extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    isFetchingImage: false
  };

  get params() {
    try {
      return this.props.match.params;
    } catch {
      return {};
    }
  }

  get positionName() {
    const { position } = this.params;
    return position === 'header' ? 'topo' : 'rodapé';
  }

  get deviceName() {
    const { device } = this.params;
    return device === 'mobile' ? 'mobile' : 'desktop';
  }

  get title() {
    const { position } = this.params;

    if (position === 'whatsapp') {
      return `Alterar logo do Whatsapp`;
    }

    return `Alterar logo do ${this.positionName} ${this.deviceName}`;
  }

  get text() {
    const { position } = this.params;

    if (position === 'whatsapp') {
      return `Defina como seu logo aparecerá quando compartilhar o site em redes sociais`;
    }

    return `Defina como seu logo aparecerá no ${this.positionName} do site ${this.deviceName}.`;
  }

  handleUploadLogo = acceptedFiles => {
    const [image] = acceptedFiles;
    const { position, device } = this.params;

    this.setState({ isFetchingImage: true });

    logosService
      .uploadLogo(position, device, image)
      .then(() => {
        this.props.history.goBack();
      })
      .finally(() => {
        this.setState({ isFetchingImage: false });
      });
  };

  render() {
    return (
      <>
        <MainTitle title={this.title} text={this.text} />
        <UploadLogo type={this.params.position} device={this.params.device} />
      </>
    );
  }
}

const mapDispatchToProps = {
  openModalGallery
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(LogoOptions);
