import Avatar from 'components/Avatar';
import Button from 'components/Button';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { whatsappSendUrl } from 'lib/share-helpers';
import { getFirstName, getName } from 'lib/text';
import { Agent } from './styles';

function Realtor({ property }) {
  const currentUser = useCurrentUser();

  const isOnNetwork = property?.is_on_network;

  if (isOnNetwork) {
    const cellphone = property?.user?.cellphone;
    const url = property?.real_estate?.primary_domain?.url;
    const rawUrl = property?.real_estate?.primary_domain?.raw_url;
    const ownerName = property?.user?.name;
    const propertyUrl = `${url}/detalhes/imovel-da-rede/${property.id}`;

    return (
      <div className="h-flex h-flex--center-center h-margin-bottom--15">
        <Avatar
          image={property?.user?.file_url}
          text={ownerName}
          className="h-margin-right--10"
        />
        <div className="h-flex__cell h-flex__cell--grow">
          <h5>{getName(ownerName)}</h5>
          <p>CRECI {property?.user?.creci}</p>
          {url && (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="h-link"
            >
              {rawUrl}
            </a>
          )}
        </div>
        {cellphone && (
          <Button
            color="success"
            target="_blank"
            tagName="a"
            href={whatsappSendUrl(
              cellphone,
              `Olá ${getFirstName(ownerName)}, sou o corretor ${
                currentUser.name
              } e quero conversar sobre este imóvel que encontrei na Rede Guru: ${propertyUrl}`
            )}
          >
            Conversar
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className="h-flex h-flex--center-center h-margin-bottom--15">
      <Avatar
        image={property?.user?.file_url}
        text={property?.user?.name}
        className="h-margin-right--10"
      />
      <div className="h-flex__cell h-flex__cell--grow">
        <h5>Corretor Responsável</h5>
        <p>{getName(property?.user?.name)}</p>
      </div>
      <Agent className="h-flex__cell h-flex__cell--grow">
        <h5>Agenciador</h5>
        <p>
          {property?.agent ? getName(property?.agent?.name) : 'Não informado'}
        </p>
      </Agent>
    </div>
  );
}

export default Realtor;
