import { castArray } from 'lodash';

const transformBoolean = (filter, fieldName) => {
  // Campos booleanos
  if (typeof filter[fieldName] === 'string' && filter[fieldName] !== '') {
    filter[fieldName] = filter[fieldName] === 'true';
  }
};

const transformBooleans = (filter, fieldNames) => {
  fieldNames.forEach((fieldName) => {
    transformBoolean(filter, fieldName);
  });
};

export const transformFilter = (filter) => {
  // Transforma de string pra inteiro
  if (filter.transaction) {
    filter.transaction = parseInt(filter.transaction);
  }

  transformBooleans(filter, [
    'has_furniture',
    'is_exchangeable',
    'is_financeable',
    'is_financeable_mcmv',
    'is_property_titled',
    'is_deeded',
    'is_published',
    'is_price_shown',
    'is_featured',
    'by_exclusivity',
    'has_owner_authorization',
    'has_sale_card',
    'is_keys_ready',
    'is_keys_ready',
    'should_send_owner_report',
    'is_season_available',
  ]);

  // Remove country id por que não existe na modal de pesquisa
  if (filter?.country_id) {
    delete filter?.country_id;
  }

  // Força situation id sempre ser um array
  if (filter.situation_id) {
    filter.situation_id = castArray(filter.situation_id);
  }

  // Verifica se tem filtro por bairro
  // se tiver substitui pelo filtro de by_neighborhood_or_zone_id
  if (filter.neighborhood_id) {
    filter.by_neighborhood_or_zone_id = filter.neighborhood_id;
    delete filter.neighborhood_id;
  }

  return filter;
};
