import styled, { css } from 'styled-components';
import { rgba } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

const iconWrapperColor = ({ isSelected, color, theme: { colors } }) => {
  const currentColor = rgba(colors[color], 0.2);

  return css`
    background: ${isSelected ? currentColor : 'transparent'};
  `;
};

const iconWrapperSize = ({ theme: { size } }) => {
  return css`
    width: ${size}px;
    height: ${size}px;

    img {
      width: 65%;
      height: 65%;
    }
  `;
};

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
  will-change: background-color;

  ${iconWrapperSize};
  ${iconWrapperColor};
`;
