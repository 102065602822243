import React from 'react';
import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
import * as propertiesBooking from 'services/propertiesBooking';
import { openModalProperty } from 'modules/modal';
import { useDispatch } from 'react-redux';

function ModalReservationCreate({
  property,
  reservation,
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  onSubmitSuccess,
}) {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    return propertiesBooking.close({
      property_id: property.id,
      id: reservation.id,
      ...values,
    });
  };

  const close = () => {
    handleClose();
    if (onSubmitSuccess) return onSubmitSuccess();
    dispatch(openModalProperty({ initialPage: 'Reservations', property }));
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={close}
    >
      <ModalTemplate
        title="Encerramento de reserva"
        text="Liberar imóvel para negociação"
        handleClose={close}
      >
        <Form
          initialValues={{
            should_change_publish: true,
          }}
          onSubmit={handleSubmit}
          handleClose={close}
          onSubmitSuccess={() => {
            if (onSubmitSuccess) return onSubmitSuccess();

            dispatch(
              openModalProperty({ initialPage: 'Reservations', property })
            );
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalReservationCreate;
