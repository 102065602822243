import styled from 'styled-components';

export const Header = styled.div`
  background: #edeef2;
  padding: 5px 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-size: 14px;
`;

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

export const Content = styled.div`
  background: #fff;
`;

export const ExpandedDetails = styled.div`
  padding: 20px;
`;
