import React, { useCallback, useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
// Hooks
import useReduxForm from 'hooks/useReduxForm';
// Components
import { Input } from 'components/Form';
import FilterField from 'components/FilterField';
// Modules
import { Actions, getIsReference } from 'pages/Properties/Filter/module';
import { MdInfo } from 'react-icons/md';

const HasExpiryDateTitle = (
  <>
    Referência{' '}
    <span
      data-tip={`
        Você pode procurar por mais<br /> 
        de uma referência ao mesmo tempo<br /> 
        separando-as por uma vírgula. Ex: 101, 102.<br /> 
        Ou então, por uma referência exata. Ex: "101", "102".`}
      data-place="right"
    >
      <MdInfo />
    </span>
  </>
);

const ReferenceField = () => {
  const { form } = useReduxForm();
  const dispatch = useDispatch();
  const isReference = useSelector(getIsReference);

  const handleClick = useCallback(() => {
    dispatch(Actions.showReference());
  }, [dispatch]);

  const clearReference = useCallback(() => {
    dispatch(change(form, 'reference', ''));
    dispatch(Actions.hideReference());
  }, [form, dispatch, change]);

  useEffect(() => {
    Tooltip.rebuild();
  });

  useEffect(() => {
    Tooltip.rebuild();
    return clearReference;
  }, []);

  useEffect(() => {
    if (!isReference) {
      clearReference();
    }
  }, [isReference]);

  if (!isReference) {
    return (
      <p className="h-link" onClick={handleClick} style={{ marginBottom: 15 }}>
        Pesquisar por referência
      </p>
    );
  }

  return (
    <div>
      <FilterField
        autoFocus
        label={HasExpiryDateTitle}
        name="reference"
        component={Input}
      />
    </div>
  );
};

export default ReferenceField;
