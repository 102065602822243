import { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import SearchItem from './components/SearchItem';
import noHistory from './no-history.png';
import { Content, IconSearch, LeftContext, NoContent, Title, Wrapper } from './styles';

import glassSearch from './glass.svg'

function LastSearches({ data }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const noData = !data || data?.length <= 0;
  const hasShowMore = data.length > 3;

  const toggleFullscreen = () => {
    setIsFullScreen((prevState) => !prevState);
  };

  return (
    <ThemeProvider
      theme={{
        isFullScreen,
      }}
    >
      <Wrapper>
        <Title>
          <LeftContext>
            <IconSearch src={glassSearch} alt="" />
            <span>Últimas pesquisas</span>{' '}
          </LeftContext>
            {hasShowMore && (
              <button className="h-link" onClick={toggleFullscreen}>
                {isFullScreen ? 'Voltar' : 'Ver mais'}
              </button>
            )}
        </Title>
        <Content>
          {noData ? (
            <NoContent>
              <img src={noHistory} alt="Sem histórico" />
            </NoContent>
          ) : (
            data.slice(0, isFullScreen ? 10 : 3).map((search) => (
              <SearchItem
                canSearch
                query={search}
                onClick={() => {
                  setIsFullScreen(false);
                }}
              />
            ))
          )}
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
}

export default LastSearches;
