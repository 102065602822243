import anime from 'animejs';

/**
 * Função que da um scroll até um elemento
 * @param target - Query Selector do elemento desejado
 * @param container - Query Selector do elemento pai com overflow
 * @param options - { marginTop, duration, easing }
 */
export const scrollToElement = (target, container = 'body', options) => {
  const _options = {
    marginTop: 20,
    duration: 300,
    easing: 'easeInQuint',
    ...options
  };

  const $target = document.querySelector(target);
  const $container = document.querySelector(container);

  if (!_options.noOffset) {
    _options.marginTop += document.querySelector('.MainHeader').offsetHeight;
  }

  if (!!$target) {
    anime({
      targets: [document.documentElement, $container],
      scrollTop:
        $container.scrollTop +
        $target.getBoundingClientRect().top -
        _options.marginTop,
      duration: _options.duration,
      easing: _options.easing
    });
  }
};
