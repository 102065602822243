import { createValidation } from 'lib/validate-helpers';
import { PROFILE_SEARCH_RANGE_RENT, PROFILE_SEARCH_RANGE_SELL } from 'services/profileSearch';

export default values => {
  const errors = {};

  const validation = createValidation(values, errors);

  // Valida a transacao do imóvel que é necessária
  validation.required('transaction');

  // Valida o select de clientes
  validation.requiredSelect('people_id', 'Selecione um cliente');

  if (values.maximum_price || values.minimum_price) {
    validation.required(['minimun_price', 'maximum_price']);

    if (values.transaction === '1')
      validation.priceRange(
        'minimum_price',
        'maximum_price',
        PROFILE_SEARCH_RANGE_SELL
      );
    else
      validation.priceRange(
        'minimum_price',
        'maximum_price',
        PROFILE_SEARCH_RANGE_RENT
      );
  }

  return errors;
};
