import React from 'react';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { bindActionCreators, compose } from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import cleanDeep from 'clean-deep';
// Components
import ProfileForm from './containers/ProfileForm';
import Wrapper from 'components/Wrapper';
// Services
import * as profileSearchService from 'services/profileSearch';
import * as locationService from 'services/location';
import * as peopleService from 'services/people';
// Modules
import {
  fetchCharacteristics,
  fetchCondoCharacteristics,
  getInitialData,
  handleSubmit,
} from './module';
import { hideLoading, showLoading } from 'modules/loading';
import { openModalPerson } from 'modules/modal';

import {
  Actions as LocationActions,
  FieldsKey,
} from 'containers/LocationFields/module';
import MainTitle from 'components/MainTitle';
import { profileToSearch } from 'lib/transformers/profileToSearch';
import { currentUserSelector } from 'modules/login';

const INITIAL_FORM_DATA = {
  with_condo_price: false,
  is_financeable_mcmv: false,
  is_on_network: false,
  has_expiry_date: false,
  is_property_titled: false,
  is_deeded: false,
  is_exchangeable: false,
  addresses: [{}],
};

class Profile extends React.Component {
  state = {
    formData: {},
    title: 'Adicionar Perfil de Busca',
    text: 'Os dados abaixo serão salvos para o cliente, e assim que cadastrar um imóvel com características compatíveis, você será avisado.',
  };

  async componentDidMount() {
    const { currentUser, isSearching } = this.props;
    const _params = this.props?.match?.params;
    const _location = this.props?.location;
    const person_id = _params?.person_id;
    const profile_id = _params?.profile_id;
    const search = _location?.search;

    const realEstate = currentUser?.real_estate;
    const relations = realEstate?.relations;
    const countryId = relations?.country_id;
    const stateId = relations?.state_id;
    const cityId = relations?.city_id;

    if (!isSearching) {
      this.setState({
        formData: INITIAL_FORM_DATA,
      });
    }

    if (isSearching) {
      this.setState({
        title: 'Procurar por perfil de busca',
        text: 'Não preencha os dados que não devem ser levados em consideração.',
        formData: {
          addresses: [{}],
        },
      });

      return;
    }

    if (search) {
      const formData = this.getFormData();

      // Verifica se tem endereços
      // se não tiver
      if (!formData?.addresses) {
        formData.addresses = [
          ...formData.addresses,
          { country_id: countryId, state_id: stateId, city_id: cityId },
        ];
      }

      this.setState({
        formData,
      });

      return;
    }

    if (person_id && profile_id) {
      this.fetchProfileData(person_id, profile_id);
    } else {
      let formData = INITIAL_FORM_DATA;

      if (cityId) {
        formData.addresses = [
          { country_id: countryId, state_id: stateId, city_id: cityId },
        ];
      }

      this.setState({
        formData,
      });
    }
  }

  getFormData = () => {
    try {
      const { search } = this.props.location;
      const parsedData = qs.parse(search, { ignoreQueryPrefix: true });

      let formData = cleanDeep(parsedData).formData;

      if (!formData.addresses || formData.addresses.length <= 0) {
        formData = {
          ...formData,
          addresses: [{}],
        };
      }

      return formData;
    } catch {
      return {
        addresses: [{}],
      };
    }
  };

  fetchNeighborhoods = (city_id) =>
    locationService
      .getNeighborhoods({ city_id, sort: 'name' })
      .then(({ data: neighborhoods }) => {
        // Atualiza a lista de bairros
        this.props.receive(
          'ProfileForm',
          FieldsKey.neighborhood,
          neighborhoods
        );
      });

  fetchProfileData = async (personId, profileId) => {
    this.props.showLoading();
    const { data: profileData } = await profileSearchService.getOne(
      personId,
      profileId,
      {
        include: 'addresses',
      }
    );

    let formData = profileData;

    if (formData.type_id) {
      this.props.fetchCharacteristics({ type_id: formData.type_id });
      this.props.fetchCondoCharacteristics({ type_id: formData.type_id });
    }

    if (formData.country)
      formData = { ...formData, country_id: formData.country.id };

    if (formData.state) formData = { ...formData, state_id: formData.state.id };

    if (formData.city) {
      const city_id = formData.city.id;
      formData = { ...formData, city_id };
      this.fetchNeighborhoods(city_id);
    }

    if (formData.neighborhoods)
      formData = {
        ...formData,
        neighborhoods: _.keyBy(formData.neighborhoods, 'id'),
      };

    if (profileData.characteristics)
      formData = {
        ...formData,
        characteristics: _.keyBy(formData.characteristics, 'id'),
      };

    if (profileData.condo_characteristics)
      formData = {
        ...formData,
        condo_characteristics: _.keyBy(formData.condo_characteristics, 'id'),
      };

    if (profileData.establishments)
      formData = {
        ...formData,
        establishments: _.keyBy(profileData.establishments, 'id'),
      };

    if (profileData.situations) {
      formData = {
        ...formData,
        situations_id: profileData.situations.map((situation) => situation.id),
      };
    }

    const areas = _(profileData?.type?.area_fields)
      .map((area) => ({
        ...area,
        minimum: '',
        maximum: '',
        measure: area.measures[0],
      }))
      .keyBy('name')
      .value();

    formData = {
      ...formData,
      areas: {
        ...areas,
        ...formData.areas,
      },
      type_or_subtype_id: profileData.subtype_id || profileData.type_id,
    };

    this.props.hideLoading();
    this.setState({ formData });
  };

  updateFormData = (values) => {
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        ...values,
      },
    });
  };

  onSubmit = (values) => {
    const { isSearching } = this.props;
    return this.props.handleSubmit({ ...values }, isSearching);
  };

  onSubmitSuccess = async ({ data: profile }) => {
    const { history, isSearching } = this.props;

    if (isSearching) {
      const clearedProfile = profileToSearch(profile);
      history.push(`/profile/people?${qs.stringify(clearedProfile)}`);
      return null;
    }

    const { data: person } = await peopleService.getOne(profile.people_id);
    history.push('/people/show');
    this.props.openModalPerson(person, { initialPage: 'SearchProfile' });
  };

  render() {
    const { isSearching } = this.props;
    const { title, text } = this.state;

    return (
      <Wrapper>
        <Helmet>
          <title>Perfil</title>
        </Helmet>
        <MainTitle title={title} text={text} />
        <ProfileForm
          isSearching={isSearching}
          initialValues={{
            isSearching,
            ...this.state.formData,
          }}
          onSubmit={this.onSubmit}
          onSubmitSuccess={this.onSubmitSuccess}
          updateFormData={this.updateFormData}
          handleChangeTypeOrSubtype={this.handleChangeTypeOrSubtype}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: currentUserSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInitialData,
      handleSubmit,
      showLoading,
      hideLoading,
      fetchCharacteristics,
      fetchCondoCharacteristics,
      openModalPerson,
      ...LocationActions,
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Profile);
