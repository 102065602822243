import Button from "components/Button";
import Container from "components/Container";
import { Input } from "components/Form";
import ListOptions from "components/ListOptions";
import { Row } from "react-flexbox-grid";
import { MdDelete } from "react-icons/md";
import { Field } from "redux-form";

function Video({ change }) {
  return (
    <Container className="Container--nopadding h-margin-top--15">
      <ListOptions>
        <ListOptions.item
          title="Vídeo do imóvel"
          text="Adicione um vídeo deste imóvel para dar ainda mais informações para este cliente."
          renderOptions={() => (
            <Button
              color="danger"
              onClick={() => {
                change('video', null);
              }}
            >
              <MdDelete /> Remover vídeo
            </Button>
          )}
        >
          <Row className="h-margin-top--15">
            <Field
              xs={12}
              label="Insira a URL de um vídeo do Youtube"
              name="video"
              component={Input}
              placeholder="Exemplo: https://www.youtube.com/watch?v=t3217H8JppI"
            />
          </Row>
        </ListOptions.item>
      </ListOptions>
    </Container>
  );
}

export default Video;
