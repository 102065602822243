import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { IconContext } from 'react-icons';

import { Provider } from 'react-redux';
import { configInterceptors } from './lib/HTTP';
import './lib/json-storage';
import configureStore from './store';
import App from './App';
import { theme } from 'constants/styles';
import ErrorBoundary from './components/ErrorBoundary';
// Assets
import 'cropperjs/dist/cropper.css';
import './assets/scss/app.css';
import 'leaflet/dist/leaflet.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import L from 'leaflet';
import { UploadImageProvider } from 'components/ModalImage/context';
import { GlobalStyles } from './GlobalStyles';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Cria a store
export const store = configureStore();

// Adiciona interceptadores de request
configInterceptors(store);

// LoadFonts
// load the quicksand font
WebFont.load({
  google: { families: ['Barlow:500,600,700', 'Inter:300,400,500,600,700'] },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <UploadImageProvider>
          <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
            <ErrorBoundary>
              <GlobalStyles />
              <App />
            </ErrorBoundary>
          </IconContext.Provider>
        </UploadImageProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

//registerServiceWorker();
