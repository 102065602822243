import React from 'react';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  isPublicationRequested,
  requestAddProperty,
} from 'modules/propertyPublication';
// import { Container } from './styles';

const RequestPropertyPublication = ({
  propertyId,
  children = 'Solicitar publicação',
  color = 'success',
  textRequested = 'Solicitado',
  ...props
}) => {
  const dispatch = useDispatch();
  const isRequested = useSelector(isPublicationRequested(propertyId));

  const onClick = async () => {
    dispatch(requestAddProperty(propertyId));
  };

  if (isRequested) {
    return (
      <Button disabled {...props}>
        {textRequested}
      </Button>
    );
  }

  return (
    <Button onClick={onClick} color={color} {...props}>
      {children}
    </Button>
  );
};

export default RequestPropertyPublication;
