import { isEmpty } from 'lib/object-helpers';

export const useSearchConditions = (params) => {
  // Verifica se está buscando por referencia
  const isSearchingByReference = !!params?.filter?.reference;

  // Verifica se está buscando por condomínio
  const isSearchingByCondo = params?.filter?.condominium_id;

  const isSearchingByCharacteristics =
    !!params?.filter?.count && !isSearchingByCondo;

  //
  const isSearchingByStreetAddress = !!params?.filter?.street_address;

  // Verifica se pode mostrar o campo de pesquisa por referencia
  const canSearchByReference =
    isEmpty(params?.filter) || isSearchingByReference;

  // Verifica se pode mostrar o botão de limpar pesquisa
  const canShowClearSearch = !!params?.filter;

  // Verifica se pode mostrar o Select de condomínio
  const canShowCondo =
    !isSearchingByReference &&
    !isSearchingByStreetAddress &&
    !isSearchingByCharacteristics;

  // Verifica se está buscando por filter
  const isSearchingByFilter = !!params?.filter;

  // Verifica se pode mostrar campo de endereço
  // 1- Quando estiver sem nenhuma pesquisa
  // 2 - Quando estiver buscando por endereço e não esteja buscando por
  // referencia e condomínio
  const canShowStreetAddress =
    isEmpty(params?.filter) ||
    (isSearchingByStreetAddress &&
      !isSearchingByReference &&
      !isSearchingByCondo);

  return {
    isSearchingByReference,
    isSearchingByCondo,
    isSearchingByCharacteristics,
    isSearchingByStreetAddress,
    canSearchByReference,
    canShowClearSearch,
    canShowCondo,
    canShowStreetAddress,
    isSearchingByFilter,
  };
};
