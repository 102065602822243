import React from 'react';
import { Content, Img, Price, Text, Title, Wrapper } from './styles';
import Status from 'containers/ModalRoot/ModalProperty/components/ModalDefault/components/Status';

function TableCardProperty({ property }) {
  return (
    <Wrapper>
      <Img src={property?.cover_image?.file_url} />
      <Content>
        <Title>
          <strong>{property?.title_formatted}</strong>{' '}
          <Status separator="-" property={property} />
        </Title>
        <Text key={property?.id + 'formated'}>
          {property?.address_formatted}
        </Text>
        <Price>{property?.price}</Price>
      </Content>
    </Wrapper>
  );
}

export default TableCardProperty;
