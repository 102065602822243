import React, { useEffect, useState, useMemo } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Row } from 'react-flexbox-grid';

// Components
import MainTitle from 'components/MainTitle';
import { Input, Textarea } from 'components/Form';
import SeeExample from '../../components/SeeExample';
import FormFixedBar from '../../components/FormFixedBar';
import Content from '../../components/Content';
import Reception from '../../components/Reception';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';

// Services
import { update, get } from 'services/buildings/description';
import { useRequest } from 'hooks/useRequest';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { compose } from 'redux';

// Assets
import reception from './reception-description.png';
import Button from 'components/Button';

const Description = ({ handleSubmit, initialize }) => {
  const [isVisible, setIsVisible] = useState(true);

  const { enterpriseId } = useEnterprise();
  const { data, isFetching } = useRequest({
    request: get,
    params: enterpriseId
  });

  const hasData = useMemo(() => {
    return (data?.title || data?.description) && !isFetching;
  }, [data, isFetching]);

  const canShowReception = useMemo(() => {
    if (isVisible && !hasData) return true;
    return false;
  }, [isVisible, hasData]);

  useEffect(() => {
    initialize(data);
  }, [data]);

  if (isFetching) {
    return (
      <div>
        <MainTitle
          title="Descrição"
          text="Encante o cliente com uma boa descrição dos melhores detalhes."
        >
          <SeeExample />
        </MainTitle>
        <Content isFetching={isFetching} />
      </div>
    );
  }

  if (canShowReception) {
    return (
      <Reception
        image={reception}
        title="Descrição"
        text="Este é o ponto onde você vai tocar o emocional do cliente. Ao invés de apenas detalhes técnicos, encante o cliente com uma boa descrição dos melhores detalhes."
      >
        <Button onClick={() => setIsVisible(false)} color="success">
          Escrever Descrição
        </Button>
      </Reception>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Descrição"
        text="Encante o cliente com uma boa descrição dos melhores detalhes."
      >
        <SeeExample />
      </MainTitle>

      <Content isFetching={isFetching}>
        <Row>
          <Field
            autoFocus
            xs={12}
            label="Título da área"
            name="title"
            component={Input}
          />
          <Field height={400} xs={12} name="description" component={Textarea} />
        </Row>
      </Content>

      <FormFixedBar />
    </form>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
    onSubmit: update
  }),
  withFormConfirmation()
)(Description);
