import React from 'react';
import { Field } from 'redux-form';
import Box from 'components/FilterBox';
import { Checkbox } from 'components/Form';

// import from './styles';

function NotAvailableField() {
  return (
    <Box title="Visibilidade no site">
      <Field
        type="checkbox"
        label="Somente não visíveis"
        name="is_not_published"
        component={Checkbox}
      />
    </Box>
  );
}

NotAvailableField.propTypes = {};
NotAvailableField.defaultProps = {};

export default NotAvailableField;
