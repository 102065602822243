import { useExport } from './useExport';

export const useExportFunnels = ({ userId, funnelId }) => {
  return useExport({
    resource: `/crm/funnels/downloadCsv`,
    userId,
    payload: {
      funnel_id: funnelId,
      user_id: userId,
    },
    fileName: 'backup-negocios',
  });
};
