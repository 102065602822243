import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import TextOverflow from 'components/TextOverflow';
// import { Container } from './styles';
import icon from './icon-chat.svg';
import { useDispatch } from 'react-redux';
import { openModalPerson } from 'modules/modal';

const Property = ({ stage, person, property, openProperty }) => {
  const dispatch = useDispatch();

  const openPersonChat = useCallback(
    e => {
      e.stopPropagation();

      dispatch(
        openModalPerson(person, {
          initialPage: 'Notes',
          property: property,
          stage: stage,
          addNote: true
        })
      );
    },
    [dispatch, person, property, stage]
  );

  /**
   * Verifica se o imóvel está indisponível
   * @return {boolean}
   */
  const isUnavailableProperty = useMemo(() => {
    return property?.status !== 'Disponível';
  }, [property]);

  if (!property || !property.id) return null;

  let props = {};

  if (isUnavailableProperty) {
    props = {
      'data-tip': 'Imóvel indisponível',
      'data-effect': 'solid'
    };
  }

  return (
    <div
      className={classnames('CardCrmProperty', {
        isDisabled: isUnavailableProperty
      })}
    >
      <div className="CardCrmProperty__content">
        <p
          className="CardCrmProperty__title h-pointer"
          onClick={openProperty}
          {...props}
        >
          <TextOverflow style={{ width: '100%' }}>
            {property?.type?.title} {property?.reference} {property?.is_booked ? ' - Reservado' : ''}
          </TextOverflow>
        </p>
        <p
          className={classnames('CardCrmProperty__price', {
            'h-color--success': property.transaction === 1,
            'h-color--caution': property.transaction !== 1
          })}
        >
          <strong>{property.calculated_price}</strong>
        </p>
      </div>
      <button
        onClick={openPersonChat}
        data-tip="Adicionar anotação"
        className="CardCrmProperty__action"
      >
        <img src={icon} alt="Icon chat" />
      </button>
    </div>
  );
};

export default Property;
