import React from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { Input } from 'components/Form';
import Button from 'components/Button';

import validate from './validate';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function Form({ handleSubmit }) {
  return (
    <form
      onSubmit={(e, ...rest) => {
        e.preventDefault();
        e.stopPropagation();
        return handleSubmit(...rest);
      }}
    >
      <Field label="Link do vídeo" name="url" component={Input} />
      <Button type="submit" color="success" block>
        Adicionar Vídeo
      </Button>
    </form>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

export default reduxForm({
  form: 'AddVideo',
  validate,
  enableReinitialize: true
})(Form);
