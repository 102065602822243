import dataReducer from 'modules/data';
// Service
import * as emailService from 'services/settings/email';

export const {
  reducer: localAccountsReducer,
  selectors: localAccountsSelector,
  actionCreators: localAccountsActions,
} = dataReducer(`email/localAccounts`);

export const fetchAccounts = (params) => async (dispatch) => {
  dispatch(localAccountsActions.request());
  try {
    const { data } = await emailService.getLocalAccounts();
    dispatch(localAccountsActions.receive(data));
  } catch {
    dispatch(localAccountsActions.reset());
  }
};

export const removeAccount = (userName, params) => async (dispatch) => {
  dispatch(localAccountsActions.removing(userName, 'username'));

  try {
    await emailService.removeMailbox(userName, { params });
    dispatch(localAccountsActions.remove(userName, 'username'));
  } catch {
    dispatch(localAccountsActions.reset());
  }
};
