import qs from 'qs';
import { clearUrlParams } from './url-helpers';

// Key principal aonde armazena o referrer
const KEY_REFERRER = 'tecimob-referrer-1';

/**
 * Retorna o true caso tenha algo de utm
 * @param queryString
 */
export function hasUtm(queryString) {
  const regex = new RegExp(/(utm_source|utm_medium|utm_campaign)/);
  return !!queryString.match(regex);
}

/**
 * Retorna true se a url tem o parametro do google
 * @param queryString
 */
export function isGoogleAds(queryString) {
  return queryString.includes('gclid');
}

export function hasReferrer(queryString) {
  return queryString.includes('referrer');
}

/**
 * Retorna a url do referrer
 * @return {string}
 */
export function getReferrer() {
  return localStorage.getItem(KEY_REFERRER) || '';
}

export function getObjectReferrer() {
  try {
    const queryObject = qs.parse(getReferrer(), { ignoreQueryPrefix: true });

    if (queryObject?.first || queryObject?.current) {
      return queryObject;
    }

    return { first: queryObject, current: queryObject };
  } catch (err) {
    return { first: '', current: '' };
  }
}

/**
 * Seta o referrer
 * @param value
 */
export function setReferrer(value) {
  let currentValue = value;

  if (typeof value === 'object') {
    currentValue = qs.stringify(value, { encode: false, addQueryPrefix: true });
  }

  return localStorage.setItem(KEY_REFERRER, decodeURI(currentValue));
}

function hasNoParams(queryString) {
  const object = qs.parse(queryString, { ignoreQueryPrefix: true });
  return object?.current === '' && object?.first === '';
}

/**
 * Seta o referer se for necessário
 * @param queryString
 * @return {null}
 */
export function setReferrerSource(queryString) {
  const refererStorage = getReferrer();
  const hasSearchParams =
    hasReferrer(queryString) || isGoogleAds(queryString) || hasUtm(queryString);

  // Verifica se os parametros da url estão vazios
  // Se estiver vazio não faz nada não seta nenhum referrer
  if (hasNoParams(queryString)) {
    clearUrlParams();
    return null;
  }

  // Limpa a url se tiver algum parametro
  // relacionado a referência de onde veio o clique
  if (hasSearchParams) clearUrlParams();

  // Se não tem queryString já retorna nulo
  // se ja tiver algo no sourceStorage já retorna null
  // se tiver algo no refererStorage já retorna null também
  if (refererStorage) return null;

  // Verifica se é do google ou tem utm
  if (hasSearchParams) {
    setReferrer(queryString);
    return null;
  }

  // Verifica se tem referrer
  // se tiver seta o que ta vindo se não deixa
  // não seta nada se não tiver origem
  if (document.referrer) setReferrer({ referrer: document.referrer });
}
