import React from 'react';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';

const styles = {
  button: {
    width: '140px'
  }
};

function ModalHasInstallment({ onClickNo, onClickYes, handleClose }) {
  return (
    <ModalTemplate title="Contratar plano" handleClose={handleClose}>
      Deseja que um consultor entre em contato com você para sugerir o plano
      ideal pro seu negócio?
      <ModalFooter>
        <Button
          color="white"
          colorText="secondary"
          onClick={onClickNo}
          style={styles.button}
        >
          Não
        </Button>
        <div className="h-flex__cell--grow" />
        <Button color="success" onClick={onClickYes} style={styles.button}>
          Sim
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

ModalHasInstallment.propTypes = {};
ModalHasInstallment.defaultProps = {};

export default ModalHasInstallment;
