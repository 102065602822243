import moment, { format } from 'lib/moment';

export const formatDate = (event, key) => {
  if (event[key]) {
    event[key] = moment(event[key], format.datetime).toDate();
  }
};

export const formatEvents = (events, startKey = 'start', endKey = 'end') => {
  return events.map(event => {
    formatDate(event, startKey);
    formatDate(event, endKey);
    return event;
  });
};
