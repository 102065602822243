import * as userService from 'services/user';

export const TypesInstance = {
  local: 'local',
  session: 'session',
};

export default class Store {
  static getInstanceByType(type = TypesInstance.local) {
    if (type === TypesInstance.local) {
      return window.localStorage;
    }

    return window.sessionStorage;
  }

  /**
   * Retorna sempre um usuário
   * @returns {Promise<Object>}
   */
  static async getUser() {
    try {
      // Pega o usuário do localStorage
      const currentUser = localStorage.getItem('current-user');

      if (currentUser) {
        return currentUser;
      }

      // Caso não ache o usuário no localStorage
      // busca o usuário no banco
      const { data: user } = await userService.me({
        should_update_login: false,
      });

      return user;
    } catch {
      // Caso de problema
      console.error('Store - Falha ao pegar os dados do usuário logado');
    }
  }

  /**
   *
   * @param key
   * @param type
   * @returns {Promise<string>}
   */
  static async get(key, type = TypesInstance.local) {
    // Pega os valores de um usuario
    try {
      // Pega o id do usuário logado
      const { id } = await this.getUser();

      return this.getInstanceByType(type).getItem(`user-${id}`)[key];
    } catch {
      console.error(`Falha ao colocar valor na chave: ${key}`);
    }
  }

  static async getAll(type = TypesInstance.local) {
    // Pega os valores de um usuario
    try {
      // Pega o id do usuário logado
      const { id } = await this.getUser();

      return this.getInstanceByType(type).getItem(`user-${id}`);
    } catch {
      console.error(`Falha ao pegar os dados`);
    }
  }

  static async set(key, value, type = TypesInstance.local) {
    try {
      const { id } = await this.getUser();

      // Pega dados
      const data = this.getInstanceByType(type).getItem(`user-${id}`);

      if (typeof key === 'object') {
        return this.getInstanceByType(type).setItem(`user-${id}`, key);
      }

      return this.getInstanceByType(type).setItem(`user-${id}`, {
        ...(data || {}),
        [key]: value,
      });
    } catch {
      console.error(`Falha ao setar valor na chave: ${key}`);
    }
  }

  static async remove(key, type = TypesInstance.local) {
    try {
      const { id } = await this.getUser();

      const data = this.getInstanceByType(type).getItem(`user-${id}`);

      // Remove a chave que o usuario passou
      delete data[key];

      return this.getInstanceByType(type).setItem(`user-${id}`, data);
    } catch {
      console.error(`Falha ao remover a chave: ${key}`);
    }
  }
}

export class LocalStore {
  static async get(key) {
    return Store.get(key, TypesInstance.local);
  }
  static async set(key, value) {
    Store.set(key, value, TypesInstance.local);
  }
  static async remove(key) {
    Store.remove(key, TypesInstance.local);
  }
}
