import styled, { css } from 'styled-components';

export const Cell = styled.td`
  background-color: #fff;
  vertical-align: top;
  padding-left: 5px;
  padding-right: 5px;

  ${p => {
    if (p.stickyLeft) {
      return css`
        position: sticky;
        left: 0;
      `;
    }

    if (p.stickyRight) {
      return css`
        position: sticky;
        right: 0;
      `;
    }
  }}
`;

export const CellCheck = styled(Cell)`
  width: 1px;
`;

export const CellNumber = styled(Cell)`
  text-align: right !important;
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
`;

export const Table = styled.table`
  min-width: 100%;
  border-spacing: 0;

  td:first-child,
  th:first-child {
    padding-left: 15px;
  }

  td:last-child,
  th:last-child {
    padding-right: 15px;
  }

  td {
    padding: 3px;
  }

  th {
    text-align: left;
    color: ${p => p.theme.colors.primary};
    border-bottom: 1px solid ${p => p.theme.borderColor};
    padding: 10px 5px 15px 5px;
  }

  tbody tr:first-child td {
    padding-top: 15px;
  }

  tfoot ${Cell} {
    font-size: 14px;
    font-weight: 600;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #f0f4f9;
  }
`;

export const RowPrimary = styled.tr`
  td {
    color: ${p => p.theme.colors.primary};
  }
`;

export const RowSecondary = styled.tr`
  td {
    color: #675c68;
  }
`;

export const RowDivider = styled.tr`
  td {
    padding: 10px 0;
  }

  td:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${p => p.theme.borderColor};
  }
`;
