import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import { useEffect } from 'react';

export default function ModalAlerta({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
}) {
  useEffect(() => {
    sessionStorage.setItem('@tecimob/alerta', true);
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Atenção" handleClose={handleClose}>
        <p className="h-margin-bottom--10">
          Nossa central de atendimento via WhatsApp está temporariamente fora de
          serviço. Caso precise conversar com nossa equipe, por favor, clique no
          ícone de atendimento no canto inferior da tela, que abrirá um chat
          interno diretamente aqui no seu navegador de internet.
        </p>
        <p className="h-margin-bottom--10">
          Pedimos desculpas pelo transtorno e agradecemos pela compreensão.
        </p>

        <ModalFooter>
          <Button color="white" onClick={handleClose}>
            Fechar
          </Button>
          <span className="h-flex__cell--grow" />
          {/*<Button*/}
          {/*  style={{ marginTop: '15px' }}*/}
          {/*  color="tertiary"*/}
          {/*  onClick={() => {*/}
          {/*    window.open(whatsappSendUrl('48999582208', '', 55));*/}
          {/*    handleClose();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Entrar em contato*/}
          {/*</Button>*/}
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}
