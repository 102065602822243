import React from 'react';
import Container from 'components/Container';
import Nodata from 'components/Nodata';
import Button from 'components/Button';
import TableItem from '../TableItem';
import { Wrapper } from './styles';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import api from 'services';
import { responseMessage } from 'lib/service-helpers';
import { openModalCrmRedirectContact } from 'modules/modal';

function Table({ data, onSaveForm }) {
  const dispatch = useDispatch();

  const onClickCreate = () => {
    dispatch(
      openModalCrmRedirectContact({
        onSaveForm
      })
    )
  }

  if (data.length <= 0)
    return (
      <Container padding>
        <Nodata
          image={null}
          title=""
          text="Nenhum redirecionamento ativo no momento."
        >
          <Button className="h-margin-top--15" type="button" onClick={onClickCreate} color="success">
            Adicionar um redirecionamento
          </Button>
        </Nodata>
      </Container>
    );

    const onClickDelete = (id) => {
      dispatch(
        openConfirmation({
          title: 'Remover redirecionamento',
          text: 'Deseja remover este redirecionamento?',
          request: async () => {
            return api
              .delete('users/crm-redirects', id)
              .then(responseMessage('Usuário removido'));
          },
          onSuccess: () => {
            onSaveForm();
          },
        })
      );
    };

    const onClickUpdate = (user) => {
      dispatch(
        openModalCrmRedirectContact({
          item: user,
          onSaveForm
        })
      )
    }

  return (
    <Container padding>
      <Wrapper>
        <thead>
          <tr>
            <th>Data criação</th>
            <th>Situação</th>
            <th>Ativado até</th>
            <th>Criado por</th>
            <th>Retirado de</th>
            <th>Redirecionado para</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <TableItem 
              key={item.id} 
              data={item} 
              onClickDelete={() => onClickDelete(item.id)} 
              onClickUpdate={() => onClickUpdate(item)}
            />
          ))}
        </tbody>
      </Wrapper>
    </Container>
  );
}

export default Table;
