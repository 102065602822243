import React, { useCallback } from 'react';
import { Wrapper } from './styles';
import Checkbox from 'components/Checkbox';
import FormGroup from 'components/Form/components/FormGroup';

const CheckboxBlock = ({ input, label, ...props }) => {
  const handleClick = useCallback(
    (e, value) => {
      e.preventDefault();
      input.onChange(!input.value);
    },
    [input]
  );

  return (
    <FormGroup {...props}>
      <Wrapper onClick={handleClick}>
        <Checkbox input={input} /> {label}
      </Wrapper>
    </FormGroup>
  );
};

export default CheckboxBlock;
