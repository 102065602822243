import React from 'react';
import Alert from 'react-s-alert';
import Clipboard from 'react-clipboard.js';
import Toggle from 'react-toggle';
import Button from 'components/Button';
import { MdArrowDropDown } from 'react-icons/all';
import Dropdown, { Item, List } from 'components/Dropdown';
import Table from 'components/Table';
import { NavLink } from 'react-router-dom';

import { TextToggle } from './styles';

const TableBuildings = ({
  data,
  isFetching,
  handleToggleStatus,
  handleDelete,
  handleDuplicatePage,
}) => {
  if ((!data || data?.length <= 0) && !isFetching) return false;

  return (
    <div>
      <h4 className="h-margin-bottom--10">Páginas</h4>
      <Table
        isFetching={isFetching}
        data={data}
        renderTitle={() => (
          <tr>
            <th>Nome</th>
            <th>Unidades</th>
            <th colSpan={2}>Status</th>
          </tr>
        )}
        renderItem={(building, i) => (
          <tr key={building.id}>
            <td>{building.name || '--'}</td>
            <td>{building.properties_count || '--'}</td>
            <td>
              <Toggle
                checked={building.status}
                icons={false}
                onClick={() => handleToggleStatus(building)}
              />
              <TextToggle>{building.status ? 'Ativo' : 'Desativo'}</TextToggle>
            </td>
            <td className="Table__cell--small">
              <div>
                <Button
                  tagName="a"
                  target="_blank"
                  href={building.link}
                  color="success"
                >
                  Ver página
                </Button>
                <Dropdown isDropHover>
                  <Button color="white" colorText="primary">
                    Opções <MdArrowDropDown />
                  </Button>
                  <List>
                    <Item>
                      <NavLink to={`/enterprise/store/${building.id}`}>
                        Editar página
                      </NavLink>
                    </Item>
                    <Item>
                      <button onClick={handleDuplicatePage(building)}>
                        Duplicar
                      </button>
                    </Item>
                    <Item>
                      <Clipboard
                        data-clipboard-text={building.link}
                        onSuccess={() => {
                          Alert.success('Link copiado');
                        }}
                      >
                        Copiar Link
                      </Clipboard>
                    </Item>
                    <Item>
                      <button
                        className="h-color--danger"
                        onClick={() => handleDelete(building)}
                      >
                        Excluir
                      </button>
                    </Item>
                  </List>
                </Dropdown>
              </div>
            </td>
          </tr>
        )}
      />
    </div>
  );
};

export default TableBuildings;
