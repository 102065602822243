import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
// Components
import Menu from 'components/Menu';
// Pages
import pages from './pages';
// Modules
import {
  actionsProperties,
  discartMatchedProperty,
  fetchPropertiesDiscarded,
  fetchPropertiesInterested,
  fetchPropertiesMatched,
  fetchPropertiesNegotiated,
  fetchPropertiesPeople,
  fetchPropertiesSended,
  selectorsProperties,
  updateMatched,
} from './module';
import {
  openModalDealLoss,
  openModalPerson,
  openModalProperty,
  openModalSendMail,
} from 'modules/modal';
// Assets
import { PERSON_PERMISSIONS } from 'constants/rules';
import RequestPermission from 'containers/ModalRoot/ModalPerson/components/RequestPermission';
import { useCan } from 'hooks/useCan';

const Count = ({ count }) => {
  return <span>({count || 0})</span>;
};

const PropertiesPage = (props) => {
  const {
    isFetching,
    modalPerson: { person },
    pageProperties,
  } = props;

  const canDynamicProperty = useCan({
    run: PERSON_PERMISSIONS.PROPERTY,
    permissions: person.permissions,
  });

  const canDynamicOwnerProperty = useCan({
    run: 'DYNAMIC_OWNER_PROPERTY',
    permissions: person.permissions,
  });

  const dispatch = useDispatch();
  const [currentPage, setPage] = useState(null);

  const handleClickMenu = useCallback(
    (page) => {
      props.reset();
      setPage(page);
    },
    [props.reset]
  );

  const handleOpenMySelf = useCallback(
    (params) => {
      dispatch(openModalPerson(person, params));
    },
    [person, dispatch]
  );

  const renderPage = useMemo(() => {
    if (!currentPage) return null;

    return React.createElement(
      pages[currentPage],
      {
        ...props,
        handleOpenMySelf: handleOpenMySelf,
      },
      null
    );
  }, [props, currentPage]);

  useEffect(() => {
    if (!canDynamicProperty && canDynamicOwnerProperty) {
      setPage('PropertiesOfClient');
    } else {
      setPage('PropertiesInterested');
    }
  }, [canDynamicProperty, canDynamicOwnerProperty]);

  useEffect(() => {
    if (pageProperties) {
      setPage(pageProperties);
    }
  }, [pageProperties]);

  if (!canDynamicProperty && !canDynamicOwnerProperty) {
    return <RequestPermission person={person} />;
  }

  return (
    <>
      <Menu
        modal
        currentPage={currentPage}
        handleClickItem={handleClickMenu}
        style={{
          fontSize: '11px',
          marginBottom: 15,
          pointerEvents: isFetching ? 'none' : 'auto',
        }}
      >
        {canDynamicProperty && [
          <Menu.item
            key="PropertiesInterested"
            component="PropertiesInterested"
          >
            CRM / POSIÇÕES <Count count={person.deals_count} />
          </Menu.item>,
          <Menu.item
            key="PropertiesNewMatched"
            component="PropertiesNewMatched"
          >
            RADAR <Count count={person.matcheds_new_count} />
          </Menu.item>,
          <Menu.item key="PropertiesSended" component="PropertiesSended">
            ENVIADOS <Count count={person.matcheds_sent_count} />
          </Menu.item>,
          <Menu.item key="PropertiesDiscarted" component="PropertiesDiscarted">
            DESCARTADOS <Count count={person.matcheds_discarted_count} />
          </Menu.item>,
          <Menu.item key="PropertiesDiscarted" component="Reservations">
            RESERVADOS <Count count={person.bookings_count} />
          </Menu.item>,
        ]}
        {(canDynamicOwnerProperty || canDynamicProperty) && (
          <Menu.item component="PropertiesOfClient">
            PROPRIETÁRIO <Count count={person.properties_count} />
          </Menu.item>
        )}
        {canDynamicProperty && (
          <Menu.item component="PropertiesNegotiated">
            NEGOCIADOS <Count count={person.earned_deals_count} />
          </Menu.item>
        )}
      </Menu>
      {renderPage}
    </>
  );
};

const mapStateToProps = (state) => ({
  matcheds: selectorsProperties.getAll(state),
  isFetching: selectorsProperties.isFetching(state),
});

const mapDispatchToProps = {
  fetchPropertiesInterested,
  fetchPropertiesNegotiated,
  fetchPropertiesMatched,
  fetchPropertiesSended,
  fetchPropertiesDiscarded,
  fetchPropertiesPeople,
  discartMatchedProperty,
  updateMatched,
  openModalPerson,
  openModalProperty,
  openModalSendMail,
  openModalDealLoss,
  reset: actionsProperties.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesPage);
