import { useRequest } from 'hooks/useRequest';
import api from 'services';
import { useState } from 'react';

export const useChart = (type = 'aggregate') => {
  const [response, setResponse] = useState(null);

  const { data, fetchData } = useRequest({
    request: (values) =>
      api.getList('real-estates/metrics', { filter: { ...values, type } }),
    initialFetch: false,
    onSuccess: (res) => {
      setResponse(res);
    },
  });

  return { data, response, fetchData };
};
