import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  border-bottom: 1px solid ${(p) => p.theme.borderColor};
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 15px 30px;

  &:not(:first-child) {
    border-top: 1px solid ${(p) => p.theme.borderColor};
  }
`;

export const Col = styled.div`
  :first-child {
    width: 200px;
  }
`;

export const Nodata = styled.div`
  padding: 15px 15px 15px 30px;
`;
