import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const WrapItem = styled.div`
  width: 100%;
  max-width: ${100 / 4}%;
  padding: 10px;
`;
