import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 100;
  background: #fff;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.05);
`;

const WrapperChart = styled.div`
  width: 100%;
  filter: blur(5px);
`;

const Overlay = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  opacity: 0.8;
`;

const exampleData = [
  { reason: 'Motivo A', lost_deals_count: 10 },
  { reason: 'Motivo B', lost_deals_count: 15 },
  { reason: 'Motivo C', lost_deals_count: 20 },
  { reason: 'Motivo D', lost_deals_count: 25 },
];

const PlaceholderBarChart = () => (
  <Wrapper>
    <Box>Nenhum resultado encontrado</Box>
    <WrapperChart>
      <Overlay />
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={exampleData}
          layout="vertical"
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis type="number" />
          <YAxis
            dataKey="reason"
            type="category"
            interval={0}
            tick={{ dy: 0 }}
          />
          <Tooltip />
          <Bar dataKey="lost_deals_count" fill="#1AA367" barSize={24} />
        </BarChart>
      </ResponsiveContainer>
    </WrapperChart>
  </Wrapper>
);

export default PlaceholderBarChart;
