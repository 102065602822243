import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 7.5px;
  overflow-y: auto;
`;

export const ListItem = styled.div`
  list-style: none;
  width: calc(100% / 4);
  padding: 7.5px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const Actions = styled.div``;
