import React from 'react';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';

function ModalHasInstallment({ onClickSchedule, onClickContact, handleClose }) {
  return (
    <ModalTemplate title="Contratar plano" handleClose={handleClose}>
      Você possui um parcelamento ativo. Para fazer alterações no seu plano,
      entre em contato com um de nossos consultores ou então agende um horário
      para ele ligar para você:
      <ModalFooter>
        <Button color="white" colorText="secondary" onClick={onClickSchedule}>
          Agendar ligação
        </Button>
        <div className="h-flex__cell--grow" />
        <Button color="success" onClick={onClickContact}>
          Entrar em contato
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

ModalHasInstallment.propTypes = {};
ModalHasInstallment.defaultProps = {};

export default ModalHasInstallment;
