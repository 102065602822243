import React, { useEffect } from 'react';
import { reduxForm, Field } from 'redux-form';

// Component
import MainTitle from 'components/MainTitle';
import SeeExample from 'pages/EnterpriseStore/components/SeeExample';
import Container from 'components/Container';
import FormFixedBar from '../../components/FormFixedBar';
import { FieldBool, ColorPicker } from 'components/Form';
import ListOptions from 'components/ListOptions';
import FieldFontSelect from 'containers/FieldFontSelect';

// Services
import * as appearanceService from 'services/buildings/appearance';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';
import { useRequest } from 'hooks/useRequest';
import { useEnterprise } from 'pages/EnterpriseStore/context';

const AppearancePage = ({ handleSubmit, initialize }) => {
  const { enterpriseId } = useEnterprise();

  const { data: initialValues } = useRequest({
    request: appearanceService.get,
    params: enterpriseId,
  });

  useEffect(() => {
    initialize(initialValues);
  }, [initialValues]);

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Aparência da página"
        text="Defina os detalhes da aparência da sua página."
      >
        <SeeExample />
      </MainTitle>

      <Container padding>
        <ListOptions>
          <ListOptions.item
            title="Mostrar o topo do seu site?"
            text="Defina se o topo padrão do seu site será mostrado nessa página."
            renderOptions={() => <FieldBool name="is_site_header_show" />}
          />
          <ListOptions.item
            title="Mostrar o rodapé do seu site?"
            text="Defina se o rodapé padrão do seu site será mostrado nessa página."
            renderOptions={() => <FieldBool name="is_site_footer_show" />}
          />
          <ListOptions.item
            title="Cor padrão"
            text="Escolha a cor que será utilizada em botões, links e outros detalhes da sua página."
            renderOptions={() => (
              <Field name="color_primary" component={ColorPicker} />
            )}
          />
          <ListOptions.item
            title="Fonte dos títulos"
            text="Defina a fonte que será utilizada nos títulos da sua página."
            renderOptions={() => (
              <FieldFontSelect name="font_titles" style={{ width: 200 }} />
            )}
          />
          <ListOptions.item
            title="Fonte dos textos"
            text="Defina a fonte que será utilizada nos textos da sua página."
            renderOptions={() => (
              <FieldFontSelect name="font_texts" style={{ width: 200 }} />
            )}
          />
        </ListOptions>
      </Container>
      <FormFixedBar />
    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: appearanceService.update,
})(AppearancePage);
