import React from 'react';
import PropTypes from 'prop-types';
// Services
import * as peopleService from 'services/people';
// Containers
import FieldSelectUser from 'containers/FieldSelectUser';
import FormWrapper from 'components/FormWrapper';

class SelectOwner extends React.Component {
  static propTypes = {
    personId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    onChange: PropTypes.func
  };

  static defaultProps = {
    personId: null,
    userId: null,
    onChange: null
  };

  state = {
    isFetching: false
  };

  onSubmit = ({ user_id }) => {
    const { personId } = this.props;

    this.setState({ isFetching: true });
    return peopleService.update({ id: personId, user_id: user_id });
  };

  onSubmitSuccess = () => {
    if (this.props.onChange) this.props.onChange();
    this.setState({ isFetching: false });
  };

  onSubmitFail = () => {
    this.setState({ isFetching: false });
  };

  get initialValues() {
    const { userId } = this.props;

    return {
      user_id: userId
    };
  }

  render() {
    return (
      <FormWrapper
        enableReinitialize={true}
        form="SelectOwner"
        onSubmit={this.onSubmit}
        onSubmitSuccess={this.onSubmitSuccess}
        onSubmitFail={this.onSubmitFail}
        initialValues={this.initialValues}
        className="Form--inline"
      >
        {() => (
          <FieldSelectUser
            isLoading={this.state.isFetching}
            label="Corretor responsável"
            name="user_id"
            style={{ width: '220px' }}
          />
        )}
      </FormWrapper>
    );
  }
}

export default SelectOwner;
