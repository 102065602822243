import React from 'react';
import classnames from 'classnames';
// import PropTypes from 'prop-types';
// import {} from './styles';

function Simple({ input, className, ...props }) {
  return (
    <textarea
      {...props}
      {...input}
      className={classnames('FormControl FormControl--textarea', className)}
    />
  );
}

Simple.defaultProps = {};
Simple.propTypes = {};

export default Simple;
