import { selector } from '../components/FormPeriod';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Função para debounce
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const usePeriod = () => {
  const [period, setPeriod] = useState({ start_date: null, end_date: null });
  const { start_date, end_date } = useSelector((state) =>
    selector(state, 'start_date', 'end_date')
  );

  useEffect(() => {
    setPeriod({ start_date, end_date });
  }, [start_date, end_date]);

  return period;
};
