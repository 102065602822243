import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 20px;

  svg {
    color: ${(p) => p.theme.color};
    margin-top: -3px;
  }

  em {
    text-decoration: underline;
    font-style: normal;
    font-weight: 600;
  }
`;
