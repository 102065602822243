import React, { useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import MainTitle from 'components/MainTitle';
import Logo from '../../components/Logo';
import LogoOpenGraph from '../../components/LogoOpenGraph';
import LogoFavicon from '../../components/LogoFavicon';
import { TYPES, DEVICES } from 'services/sites/logos';

const LogoForms = () => {
  const [logoHeader, setLogoHeader] = useState(null);

  return (
    <>
      <MainTitle
        title="Logo"
        text="Defina como seu logo aparecerá no topo e no rodapé do site."
      />
      <Row>
        <Col xs={6}>
          <Logo
            title="Topo (Computador)"
            type={TYPES.HEADER}
            device={DEVICES.DESKTOP}
            onLoad={(logo) => {
              setLogoHeader(logo);
            }}
          />
        </Col>
        <Col xs={6}>
          <Logo
            title="Rodapé (Computador)"
            type={TYPES.FOOTER}
            device={DEVICES.DESKTOP}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Logo
            title="Topo (Celular)"
            type={TYPES.HEADER}
            device={DEVICES.MOBILE}
          />
        </Col>
        <Col xs={6}>
          <Logo
            title="Rodapé (Celular)"
            type={TYPES.FOOTER}
            device={DEVICES.MOBILE}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <LogoOpenGraph logoHeader={logoHeader} />
        </Col>
        <Col xs={6}>
          <LogoFavicon />
        </Col>
      </Row>
    </>
  );
};

export default LogoForms;
