import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
// Components
import { Input } from 'components/Form';
import Button from 'components/Button';
import FieldSelectPeople from 'containers/FieldPerson';
import { normalizePhone } from 'lib/formHelpers';

function FormChooseNumber({ change, handleSubmit }) {
  return (
    <Row
      tagName="form"
      onClick={e => e.stopPropagation()}
      onSubmit={handleSubmit}
    >
      <FieldSelectPeople
        xs={12}
        name="person"
        parse={value => {
          return value;
        }}
        onChange={person => {
          change('cellphone_number', person?.cellphone_number);
        }}
      />
      <Field
        label="Telefone"
        xs={12}
        name="cellphone_number"
        component={Input}
        normalize={normalizePhone}
        placeholder="Número de telefone"
        onChange={() => {
          change('person', null);
        }}
      />
      <Col xs={12}>
        <Button type="submit" block color="success">
          Compartilhar
        </Button>
      </Col>
    </Row>
  );
}

const WrapperFormChooseNumber = reduxForm({
  form: 'FormChooseNumber',
  enableReinitialize: true
})(FormChooseNumber);

export default WrapperFormChooseNumber;
