import React, { useCallback } from 'react';
import Popover from 'components/Popover';
import Form from './Form';
import Button from 'components/Button';
import useFormValue from 'hooks/useFormValue';
import { whatsappSendUrl } from 'lib/share-helpers';

const SendToWhatsapp = () => {
  const message = useFormValue('email_message');
  const phone = useFormValue('phone');
  const ddi = useFormValue('ddi');
  const site_link = useFormValue('site_link');

  const onSubmit = useCallback(
    ({ phone, ddi }) => {
      const url = whatsappSendUrl(phone, `${message}\n\n${site_link}`, ddi);
      window.open(url, '_blank');
    },
    [message]
  );

  return (
    <Popover
      initialValues={{ phone, ddi, message }}
      component={Form}
      onSubmit={onSubmit}
    >
      <Button className="h-margin-top--15" color="success">
        Enviar por WhatsApp
      </Button>
    </Popover>
  );
};

export default SendToWhatsapp;
