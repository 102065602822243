import React, { useCallback, useEffect } from 'react';
import { reduxForm, initialize } from 'redux-form';

// Components
import MainTitle from 'components/MainTitle';
import SeeExample from '../../components/SeeExample';
import FormFixedBar from '../../components/FormFixedBar';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';
import Container from 'components/Container';
import ListCheck from 'components/ListCheck';

// Services
import * as establishmentsBuildingService from 'services/buildings/establishments';
import * as establishmentsService from 'services/establishments';
import { getList } from 'services/establishments';
import { useRequest } from 'hooks/useRequest';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import Button from 'components/Button';
import Popover from 'components/Popover';
import FormEstablishments from 'components/FormEstablishments';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useDispatch } from 'react-redux';

const Establishments = ({ handleSubmit, initialize }) => {
  const dispatch = useDispatch();
  const { enterpriseId } = useEnterprise();

  const { data, isFetching, fetchData } = useRequest({
    request: getList,
  });

  const { data: initialValues } = useRequest({
    request: establishmentsBuildingService.get,
    params: enterpriseId,
  });

  const handleAddEstablishment = useCallback((data) => {
    return establishmentsService.create(data);
  }, []);

  const handleAddEstablishmentSuccess = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteEstablishment = useCallback(
    ({ id, title }) =>
      async () => {
        try {
          dispatch(
            openConfirmation({
              title: `Remover estabelecimento`,
              text: `Deseja realmente remover o estabelecimento ${title}?`,
              request: () => establishmentsService.remove(id),
              onSuccess: () => {
                fetchData();
              },
            })
          );
        } catch {
          return false;
        }
      },
    [fetchData]
  );

  useEffect(() => {
    initialize(initialValues);
  }, [initialValues]);

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Estabelecimentos"
        text="Defina os estabelecimentos próximos do empreendimento."
      >
        <Popover
          onSubmit={handleAddEstablishment}
          onSubmitSuccess={handleAddEstablishmentSuccess}
          component={FormEstablishments}
        >
          <Button type="button" size="medium" color="secondary">
            Cadastrar Estabelecimento
          </Button>
        </Popover>
        <SeeExample />
      </MainTitle>

      <Container>
        <ListCheck
          cols={4}
          name="establishments"
          data={data}
          isFetching={isFetching}
          handleDeleteItem={handleDeleteEstablishment}
        />
      </Container>

      <FormFixedBar />
    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: async (values, dispatch) => {
    await establishmentsBuildingService.update(values);
    dispatch(initialize(FORM_NAME, values));
  },
})(Establishments);
