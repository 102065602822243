import React, { useState, useCallback, useMemo } from 'react';
import * as menus from 'components/Menus/menus';

export const MenusContext = React.createContext({
  Menu: null,
  menu: null,
  setMenu: null,
  back: null
});

const defaultMenu = 'MenuDefault';

export default function MenuProvider({ children }) {
  const [menu, setMenu] = useState(defaultMenu);

  const handleSetMenu = useCallback(
    menuName => () => {
      setMenu(menuName);
    },
    [setMenu]
  );

  const back = useCallback(() => {
    handleSetMenu(defaultMenu)();
  }, []);

  const Menu = useMemo(() => menus[menu] || 'div', [menu]);

  return (
    <MenusContext.Provider value={{ Menu, menu, setMenu: handleSetMenu, back }}>
      {children}
    </MenusContext.Provider>
  );
}
