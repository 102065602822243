import styled, { css } from 'styled-components';
import ComponentLoading from 'react-loading';
import { MdSearch } from 'react-icons/md';
import { readableColor } from 'polished';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 555px;
  min-width: 525px;
  flex: 20 1;
`;

export const SearchIcon = styled(MdSearch)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 20px;
`;

export const DigitsLeft = styled.span`
  position: absolute;
  top: 50%;
  color: ${p => readableColor(p?.theme?.bgHeader)};
  white-space: nowrap;
  right: 15px;
  transform: translateY(-50%);
`;

export const Loading = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: 10px;

  color: ${p => readableColor(p?.theme?.bgHeader)};

  opacity: 0;
  transform: translateY(-50%) translateX(10px);
  visibility: hidden;
  transition: all 0.2s;

  ${p =>
    p.isFetching &&
    css`
      opacity: 1;
      transform: translateY(-50%) translateX(0);
      visibility: visible;
    `}
`;

export const LoadingIcon = styled(ComponentLoading).attrs(() => ({
  type: 'spin',
  width: '20px',
  height: '20px'
}))`
  font-size: 20px;
  width: 20px;
  margin-left: 10px;
`;

export const Controller = styled.div`
  position: relative;

  svg {
    color: ${p => readableColor(p?.theme?.bgHeader)};
  }
`;
export const Input = styled.input.attrs(() => ({
  placeholder: 'Procure por endereço, nome do cliente ou nome do condomínio'
}))`
  width: 100%;
  height: 40px;
  border: none;
  padding: 10px 15px 10px 35px;
  border-radius: 6px;
  background: ${p =>
    readableColor(
      p.theme.bgHeader,
      'rgba(0,0,0, 0.2)',
      'rgba(255, 255, 255, 0.2)'
    )};
  color: ${p => readableColor(p.theme.bgHeader)};
  font-family: inherit;

  ::placeholder {
    color: ${p => readableColor(p.theme.bgHeader)};
  }
`;
