import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Modules
import { dealsSelector, getDeals } from '../../reducer';
// Components
import Wrapper from 'components/Wrapper';
import TableListDeals from 'components/TableListDeals';
import Header from '../Header';
import { withPagination } from 'HOC/withPagination';
import Pagination from 'components/Pagination';

function DealsHorizontal({ pagination, initialValues }) {
  const dispatch = useDispatch();
  const deals = useSelector(dealsSelector.getAll);
  const isFetching = useSelector(dealsSelector.isFetching);

  const onUpdateDeal = useCallback(() => {
    dispatch(getDeals());
  }, [dispatch]);

  const onPageChange = useCallback(({ selected }) => {
    dispatch(getDeals({ offset: selected + 1 }));
  }, []);

  useEffect(() => {
    dispatch(getDeals(initialValues));
  }, [initialValues]);

  return (
    <Wrapper.inner>
      <Wrapper.container style={{ maxWidth: '1124px' }}>
        <Header onAddDeal={() => dispatch(getDeals())} />
        <TableListDeals
          isFetching={isFetching}
          deals={deals}
          onUpdateDeal={onUpdateDeal}
          pagination={pagination}
        />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </Wrapper.container>
    </Wrapper.inner>
  );
}

DealsHorizontal.defaultProps = {};
DealsHorizontal.propTypes = {};

export default withPagination('crm-horizontal', { limit: 50 })(DealsHorizontal);
