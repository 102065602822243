import styled from 'styled-components';
import { darken, readableColor } from 'polished';

export const WrapperTable = styled.div`
  margin-top: 20px;

  .Table {
    filter: drop-shadow(-1px 1px 5px rgba(0, 0, 0, 0.1));
  }

  .Table tr th {
    background: #f5f5f6;
    padding: 15px;
  }

  .Table tr td {
    padding: 15px;
  }
`;

export const Ball = styled.span`
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  background: ${(p) => p.color};
  border-radius: 50%;
`;

export const Category = styled.span`
  display: block;
  padding: 3px 5px;
  color: ${(p) => readableColor(darken(0.2, p.color))};
  background-color: ${(p) => p.color};
  white-space: nowrap;
  border-radius: 5px;
`;

export const Actions = styled.div`
  display: flex;
  margin: -5px;
`;

export const Action = styled.button`
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;

  svg {
    position: relative;
    left: 1px;
  }

  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
