import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FieldArray, FormName, reduxForm } from 'redux-form';
import Tooltip from 'react-tooltip';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
// Containers
import InfoContainer from '../InfoContainer';
import CharacteristicsContainer from '../CharacteristicsContainer';
import CondoCharacteristicsContainer from '../CondoCharacteristicsContainer';
import EstablishmentsContainer from '../EstablishmentsContainer';
import RoomsContainer from '../RoomsContainer';
import MeasuresContainer from '../MeasuresContainer';
import MultipleLocation from '../MultipleLocation';
import LocationContainer from '../LocationContainer';
// Validate
import validate from './validate';
// Helpers
import { scrollToFirstError } from 'lib/formHelpers';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

class ProfileForm extends React.Component {
  componentDidMount() {
    Tooltip.rebuild();
  }

  render() {
    const { handleSubmit, change, submitting, pristine, isSearching } =
      this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormName>
          {({ form }) => (
            <>
              <InfoContainer isSearching={isSearching} change={change} />
              <RoomsContainer />
              <CharacteristicsContainer />
              <CondoCharacteristicsContainer />
              <EstablishmentsContainer change={change} />
              <MeasuresContainer change={change} />
              {isSearching ? (
                <LocationContainer formName="ProfileForm" change={change} />
              ) : (
                <FieldArray
                  change={change}
                  name="addresses"
                  component={MultipleLocation}
                  rerenderOnEveryChange
                />
              )}
            </>
          )}
        </FormName>
        <FixedBar>
          <span className="h-flex__cell--grow" />
          <Button
            type="submit"
            color="secondary"
            disabled={submitting || pristine}
          >
            {isSearching ? 'Procurar clientes' : 'Salvar'}
          </Button>
        </FixedBar>
      </form>
    );
  }
}

ProfileForm.defaultProps = defaultProps;
ProfileForm.propTypes = propTypes;

export default compose(
  reduxForm({
    form: 'ProfileForm',
    validate,
    enableReinitialize: true,
    onSubmitFail: scrollToFirstError('.Wrapper__inner'),
  })
)(ProfileForm);
