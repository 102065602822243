import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background: #ffffff;
  border: 1px solid #d3dceb;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 16px;
  margin-bottom: 4px;
`;
export const Text = styled.p`
  font-size: 14px;
`;
export const Actions = styled.div`
  display: flex;
`;

export const Content = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
