import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Image from 'components/Image';

export const Arrow = ({ className, style, onClick, children }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    {children}
  </div>
);

export default class CarouselSelect extends React.Component {
  static propTypes = {
    adaptiveHeight: PropTypes.bool,
    autoplay: PropTypes.bool,
    centerMode: PropTypes.bool,
    infinite: PropTypes.bool,

    slidesPerRow: PropTypes.number,
    slidesToScroll: PropTypes.number,
    slidesToShow: PropTypes.number,
    nextArrow: PropTypes.node,
    prevArrow: PropTypes.node,

    // My props
    srcKey: PropTypes.string,
    valueKey: PropTypes.string,
    renderOption: PropTypes.func
  };

  static defaultProps = {
    adaptiveHeight: true,
    autoplay: false,
    centerMode: false,
    centerPadding: '50px',
    infinite: true,

    slidesPerRow: 1,
    slidesToScroll: 1,
    slidesToShow: 1,
    nextArrow: (
      <Arrow>
        Próximo <MdKeyboardArrowRight />
      </Arrow>
    ),
    prevArrow: (
      <Arrow>
        <MdKeyboardArrowLeft /> Anterior
      </Arrow>
    ),

    // My props
    srcKey: 'file_url',
    valueKey: 'type',
    renderOption: null,
    slickImgClassName: 'slick__image',
    slickItemClassName: 'slick__item'
  };

  /**
   * Pega o indice
   * @return {number} - retorna o indice do slide
   */
  getIndexSlide = () => {
    const {
      options,
      valueKey,
      input: { value }
    } = this.props;
    return _.findIndex(options, { [valueKey]: value });
  };

  updateIndex = () => {
    const index = this.getIndexSlide();
    if (index < 0) return;
    this.slider.slickGoTo(index);
  };

  componentDidMount() {
    this.updateIndex();
  }

  componentDidUpdate(prevProps) {
    const {
      input: { value }
    } = this.props;
    const {
      input: { value: prevValue }
    } = prevProps;

    if (value && !prevValue) {
      this.updateIndex();
    }
  }

  componentWillUnmount() {
    this.slider.slickGoTo(0);
  }

  beforeChange = (currentIndex, nextIndex) => {
    const {
      options,
      valueKey,
      input: { onChange },
      beforeChange
    } = this.props;

    // Se não tem nenhuma opção
    // Não pode mudar o slide se não tiver nenhum slide para poder mudar
    if (!options?.length) return;

    // Verifica se tem uma função
    if (beforeChange) beforeChange(currentIndex, nextIndex);

    try {
      onChange(options[nextIndex][valueKey]);
    } catch (e) {
      onChange(null);
    }
  };

  renderOption = option => {
    const {
      renderOption,
      slickItemClassName,
      slickImgClassName,
      srcKey,
      valueKey
    } = this.props;

    if (renderOption) return renderOption(option);

    return (
      <div className={slickItemClassName}>
        <Image
          src={option[srcKey]}
          alt={option[valueKey]}
          className={slickImgClassName}
        />
      </div>
    );
  };

  get renderOptions() {
    const { options } = this.props;
    if (!options) return null;
    return options.map(this.renderOption);
  }

  render() {
    return (
      <Slider
        ref={c => (this.slider = c)}
        beforeChange={this.beforeChange}
        {...this.props}
      >
        {this.renderOptions}
      </Slider>
    );
  }
}
