import React from 'react';
import Menu from '../../components/Menu';
import MenuItem from '../../components/MenuItem';
import {
  MdEdit,
  MdAttachMoney,
  MdDelete,
  MdLabel,
  MdOutlineSaveAlt,
  MdSearch,
} from 'react-icons/md';

const MenuDefault = () => {
  return (
    <Menu title="Imóveis" canBack>
      <MenuItem
        to="/properties/search"
        title="Pesquisar"
        text="Pesquisar imóveis"
        icon={MdSearch}
        color="#595bd3"
      />
      <MenuItem
        to="/properties/condos"
        icon={MdEdit}
        color="#31d084"
        title="Condomínios/Empreendimentos"
        text="Gerenciar condomínios"
      />
      <MenuItem
        to="/properties/revision/show"
        icon={MdEdit}
        color="#0084F4"
        title="Aguardando revisão"
        text="Imóveis com cadastro incompleto"
      />
      <MenuItem
        to="/properties/sold"
        icon={MdAttachMoney}
        color="#00C48C"
        title="Vendidos"
        text="Seus imóveis vendidos"
      />
      <MenuItem
        icon={MdLabel}
        to="/properties/rented"
        color="#FFA26B"
        title="Alugados"
        text="Seus imóveis alugados"
      />
      <MenuItem
        to="/properties/excluded"
        icon={MdDelete}
        color="#FF647C"
        title="Inativos"
        text="Seus imóveis inativos"
      />
      <MenuItem
        to="/properties/booked"
        icon={MdOutlineSaveAlt}
        color="#000000"
        title="Reservados"
        text="Seus imóveis reservados"
      />
    </Menu>
  );
};

export default MenuDefault;
