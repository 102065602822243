import Business from './Business';
import Crm from './Crm';
import Deals from './Deals';
import Documents from './Documents';
import History from './History';
import Notes from './Notes';
import Record from './Record';
import Reports from './Reports';
import Send from './Send';
import Shipping from './Shipping';
import Statistics from './Statistics';
import Reservations from './Reservations';
import Radar from './Radar';

export default {
  Business,
  Crm,
  Deals,
  Documents,
  History,
  Notes,
  Record,
  Reports,
  Send,
  Shipping,
  Statistics,
  Reservations,
  Radar,
};
