import styled from 'styled-components';
import { FaYoutube } from 'react-icons/fa';
import { MdOutlineSubject } from 'react-icons/all';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  border: 1px solid #d3dceb;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 15px;
`;
export const Aside = styled.div`
  width: 145px;
  flex-shrink: 0;
  background: #176ec8;
  color: #fff;
  text-align: center;
  display: grid;
  place-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  p {
    color: inherit;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }
`;

export const Link = styled.a`
  color: #176ec8;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex: 1 0;
  padding: 20px;

  p {
    font-size: 14px;
  }

  ul {
    margin: 10px 0 10px;
    list-style: none;

    ${Link} {
      vertical-align: text-bottom;
    }

    li {
      padding: 2px 0;

      svg {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
`;

export const IconYoutube = styled(FaYoutube)`
  font-size: 20px;
  fill: #f84343;
`;

export const IconText = styled(MdOutlineSubject)`
  font-size: 20px;
  fill: #0084f4;
`;

export const TextWrapper = styled.div`
  flex: 1 0;
`;
export const VideoWrapper = styled.div`
  flex-shrink: 0;
`;

export const Video = styled.div`
  position: relative;
  width: 240px;
  height: 135px;
  cursor: pointer;

  .Video__image {
    width: inherit;
    height: inherit;
    object-fit: cover;
    border-radius: 4px;
  }

  .Video__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px;
  }
`;

export const Title = styled.h2`
  font-size: 16px;
  margin-bottom: 10px;
`;

export const Close = styled.div`
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  position: absolute;
  left: 100%;
  bottom: 100%;
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.borderColor};
  border-radius: 100%;
  margin-left: -15px;
  margin-bottom: -15px;

  svg {
    font-size: 20px;
  }
`;
