import { createContext, useContext, useState } from 'react';
import { formValueSelector } from 'redux-form';
import { useRequest } from 'hooks/useRequest';
import * as contractsService from 'services/financialv2/contracts';

// Selector do formulário de pagamento
const selector = formValueSelector('PaymentForm');

export const ModalPaymentContext = createContext({
  currentContract: null,
  previewPrice: null,
  updatePreviewPrice: null,
  isFetchingContract: false,
  isLoading: false,
  setIsLoading: false,
  page: false,
  setPage: false,
});

// const checkInstallment = () => api.get('financial/has-installment');

export const ModalPaymentProvider = ({ children }) => {
  const [page, setPage] = useState('UpdateContract');

  const [isLoading, setIsLoading] = useState(false);

  const { data: currentContract, isFetching } = useRequest({
    request: contractsService.getCurrentContract,
    params: {
      include: 'values',
    },
  });

  const [previewPrice, setPreviewPrice] = useState(null);

  const updatePreviewPrice = async (date) => {
    const { data } = await contractsService.extendPreview({ end_at: date });
    setPreviewPrice(data.price);
  };

  return (
    <ModalPaymentContext.Provider
      value={{
        currentContract,
        previewPrice,
        updatePreviewPrice,
        isFetchingContract: isFetching,
        isLoading,
        setIsLoading,
        page,
        setPage,
      }}
    >
      {children}
    </ModalPaymentContext.Provider>
  );
};

export const useModalPayment = () => useContext(ModalPaymentContext);
