import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '../../../../../components/Button';
import ModalTemplate from '../../../../../components/Modal/components/ModalTemplate';
// Assets
import successImage from '../../../../../assets/img/domains/domain-success.svg';

const propTypes = {
  handleClose: PropTypes.func,
};
const defaultProps = {
  handleClose: () => {},
};

const RegisterDomainSucess = ({ handleClose }) => (
  <ModalTemplate
    title="Registrar Domínio"
    handleClose={handleClose}
    footerActions={
      <>
        <span className="h-flex__cell--grow"></span>
        <Button color="success" onClick={handleClose}>
          Entendi
        </Button>
      </>
    }
  >
    <img
      className="h-image-center h-margin-bottom--15"
      src={successImage}
      alt="Dados enviados com sucesso"
    />
    <h2 className={'h4 h-color--primary h-text-center h-margin-bottom--15'}>
      Dados enviados com sucesso
    </h2>
    <p className={'h-color--primary-light h-text-center h-margin-bottom--15'}>
      Seus dados foram encaminhados para o setor de registro e você receberá
      e-mails do Registro.br em até 1 dia útil
    </p>
  </ModalTemplate>
);

RegisterDomainSucess.propTypes = propTypes;
RegisterDomainSucess.defaultProps = defaultProps;

export default RegisterDomainSucess;
