import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Services
import * as domainsService from 'services/settings/domains';
// Components
import PreRegisterDomainForm from './components/PreRegisterDomainForm';
import RegisterDomainForm from './components/RegisterDomainForm';
import RegisterDomainAlert from './components/RegisterDomainAlert';
import RegisterDomainSuccess from './components/RegisterDomainSuccess';

class ModalRegisterDomain extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  state = {
    url: '',
    component: 'RegisterDomainAlert'
  };

  constructor(props) {
    super(props);

    this.components = {
      PreRegisterDomainForm: {
        component: PreRegisterDomainForm,
        props: {
          onSubmit: this.handleSubmitPreRegister,
          onSubmitSuccess: async values => {
            this.formValues = values;
          }
        }
      },
      RegisterDomainForm: {
        component: RegisterDomainForm,
        props: {
          onSubmit: this.handleSubmitRegister,
          onSubmitSuccess: res => {
            if (props.onSubmitSuccess) props.onSubmitSuccess(res);
          }
        }
      },
      RegisterDomainAlert: {
        component: RegisterDomainAlert,
        props: {
          onSubmit: this.handleSubmitAlert
        }
      },
      RegisterDomainSuccess: {
        component: RegisterDomainSuccess,
        props: {}
      }
    };
  }

  handleSubmitPreRegister = values => {
    return domainsService.checkDomain(values.url).then(() => {
      this.setContainer('RegisterDomainForm');

      // Adiciona a url do imovel no formulario de registro de dominio
      this.props.change('RegisterDomainForm', 'url', values.url);
      return values;
    });
  };

  handleSubmitRegister = values => {
    return domainsService.createPendingRegister(values).then(res => {
      this.setContainer('RegisterDomainSuccess');
      return res;
    });
  };

  handleSubmitAlert = () => {
    this.setContainer('PreRegisterDomainForm');
  };

  setContainer = component => this.setState({ component });

  renderComponent = () => {
    const { url } = this.props;
    const { component } = this.state;

    return React.createElement(this.components[component].component, {
      ...this.components[component].props,
      initialValues: {
        url
      },
      handleClose: this.props.handleClose
    });
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        {this.renderComponent()}
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  change
};

export default connect(null, mapDispatchToProps)(ModalRegisterDomain);
