import PropTypes from 'prop-types';
import React from 'react';
import { MdEventNote } from 'react-icons/md';
import { connect } from 'react-redux';
// Modules
import {
  dataSelector,
  fetchPeopleInteractions,
  isFetchingSelector,
} from './module';

import { peopleSelector } from 'modules/people';
// Components
import Panel from 'components/Panel';
import Table from 'components/Table';
// Helpers
import TextOverflow from 'components/TextOverflow';
import { dateTimeToDate } from 'lib/date-helpers';

class PeopleScheduled extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    isFetching: PropTypes.bool.isRequired,

    // Handlers
    openModalPerson: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentUser: localStorage.getItem('current-user'),
    data: [],
    isFetching: false,

    // Handlers
    openModalPerson: null,
  };

  componentDidMount() {
    this.props.fetchPeopleInteractions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.people !== prevProps.people) {
      this.props.fetchPeopleInteractions();
    }
  }

  render() {
    const { data, isFetching, openModalPerson } = this.props;

    return (
      <Panel
        hasData={!!data.length}
        renderNoData={() => (
          <>
            <p>
              Nenhum Cliente <br /> com interação
            </p>
          </>
        )}
        title="Últimos clientes com interação"
        icon={MdEventNote}
        color="caution"
      >
        <Table
          type="full"
          data={data}
          isFetching={isFetching}
          renderTitle={() => (
            <tr>
              <th>Cliente</th>
              <th className="Table__cell--small">Data</th>
            </tr>
          )}
          renderItem={(person) => (
            <tr
              key={`interaction-${person.id}`}
              className="h-pointer"
              onClick={openModalPerson(person)}
            >
              <td>
                <strong>
                  <TextOverflow width="285px">{person?.name}</TextOverflow>
                </strong>
              </td>
              <td className="Table__cell--small">
                {dateTimeToDate(person?.interacted_at, 'YYYY-MM-DD')}
              </td>
            </tr>
          )}
        />
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  people: peopleSelector(state),
  data: dataSelector(state),
  isFetching: isFetchingSelector(state),
});

const mapDispatchToProps = {
  fetchPeopleInteractions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleScheduled);
