import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';
import { Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Image from 'components/Image';
import { ColorPicker, Input } from 'components/Form';
import ListOptions from 'components/ListOptions';
// Assets
import noImage from 'assets/img/no-image.png';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const styleItem = { paddingLeft: 0, paddingRight: 0 };

const styleLabel = background => ({
  background: background,
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '5px',
  color: '#ffffff',
  maxWidth: '100%'
});

const Label = ({ text, background }) =>
  text ? (
    <span className="h-text-overflow" style={styleLabel(background)}>
      {text}
    </span>
  ) : null;

const FormStripe = ({ property, stripeBackground, stripeText }) => {
  const imageUrl = property.cover_image
    ? property.cover_image.file_url
    : noImage;

  return (
    <>
      <Row middle="xs">
        <Col xs={8}>
          <ListOptions>
            <ListOptions.item style={styleItem}>
              <Row>
                <Field
                  xs={12}
                  label="Título da Tarja"
                  name="stripe_text"
                  placeholder="Título da Tarja"
                  component={Input}
                  maxLength={120}
                />
              </Row>
            </ListOptions.item>
            <ListOptions.item
              title="Cor do fundo"
              text="É a cor que será usada no fundo da tarja."
              renderOptions={() => (
                <Field
                  name="stripe_background"
                  component={ColorPicker}
                  parse={value => {
                    if (!value) return '#3E587C';
                    return value;
                  }}
                />
              )}
              style={styleItem}
            />
          </ListOptions>
        </Col>
        <Col xs={4}>
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <Image
              size="medium"
              src={imageUrl}
              alt=""
              style={{ width: '100%' }}
            />
            <Label text={stripeText} background={stripeBackground} />
          </div>
        </Col>
      </Row>
    </>
  );
};

FormStripe.defaultProps = defaultProps;
FormStripe.propTypes = propTypes;

const selector = formValueSelector('PublicationWizard');

const mapStateToProps = state => ({
  stripeText: selector(state, 'stripe_text'),
  stripeBackground: selector(state, 'stripe_background')
});

export default connect(mapStateToProps)(FormStripe);
