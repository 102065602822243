import React from 'react';
import { Field, reduxForm } from 'redux-form';
// Components
import { FieldBool, Input } from 'components/Form';
import FieldGallery from '../../containers/FieldGallery';
import ListOptions from 'components/ListOptions';
import Container from 'components/Container';
import FixedBar from 'components/FixedBar';
import FixedBarItem from 'pages/Properties/components/FixedBar/FixedBarItem';
import Button from 'components/Button';
// Hooks
import useFormValue from 'hooks/useFormValue';

const min = number => (value, prevValue) => {
  const currentValue = value.replace(/^0+/, '');

  if (!currentValue) return number;
  if (currentValue < number) return prevValue;
  return currentValue;
};

function FormGallery({ handleSubmit, submitting }) {
  const isShown = useFormValue('gallery_is_popup_shown');

  return (
    <form onSubmit={handleSubmit}>
      <FieldGallery />
      <Container padding className="h-margin-top--15">
        <ListOptions>
          <ListOptions.item
            title="Ativar pop-up pedindo dados do usuário?"
            text="Após seu cliente visualizar um número pré-definido de fotos, mostrar uma pop-up pedindo seus dados."
            renderOptions={() => <FieldBool name="gallery_is_popup_shown" />}
          />
          <ListOptions.item
            isDisabled={!isShown}
            title="Mostrar pop-up após visualizar quantas fotos?"
            text="Defina o número de fotos que o seu cliente poderá visualizar antes de mostrar a pop-up."
            renderOptions={() => (
              <Field
                type="number"
                name="gallery_popup_after"
                component={Input}
                placeholder=""
                normalize={min(0)}
              />
            )}
          />
          <ListOptions.item isDisabled={!isShown} title="Título">
            <div className="h-margin-top--10">
              <Field name="gallery_popup_title" component={Input} />
            </div>
          </ListOptions.item>
          <ListOptions.item isDisabled={!isShown} title="Frase de apoio">
            <div className="h-margin-top--10">
              <Field name="gallery_popup_text" component={Input} />
            </div>
          </ListOptions.item>
        </ListOptions>
      </Container>
      <FixedBar>
        <FixedBar.item>
          <Button color="white" colorText="secondary">
            Cancelar
          </Button>
        </FixedBar.item>
        <FixedBar.spacer />
        <FixedBarItem>
          <Button type="submit" disabled={submitting} color="success">
            Salvar
          </Button>
        </FixedBarItem>
      </FixedBar>
    </form>
  );
}

export default reduxForm({
  form: 'FormGallery',
  enableReinitialize: true
})(FormGallery);
