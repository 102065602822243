import React from 'react';
// import PropTypes from 'prop-types';
import Modal from 'react-modal';

import FormDealDelete from './components/FormDealDelete';

import { ModalTemplate } from '../../../components/Modal';
import { plural } from 'lib/text';
import { useRequest } from '../../../hooks/useRequest';
import * as dealsService from '../../../services/deals';
import Alert from 'react-s-alert';
import { removeStage } from 'modules/pipeline';
import { useDispatch } from 'react-redux';
import api from 'services';

const ModalDealDelete = ({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  stage,
  funnels,
  userId,
  onSuccess,
  ...props
}) => {
  const dispatch = useDispatch();

  const { data: stageData, isFetching } = useRequest({
    request: () =>
      dealsService.getStageById(stage.id, {
        count: 'deals,crms,default_properties',
      }),
  });

  const { data: funnelsAndStages } = useRequest({
    request: dealsService.getAllStages,
  });

  const handleFormSubmit = (values) => {
    let params = {};

    if (values.update_default_properties) {
      params.update_default_properties = true;
    }

    if (stageData.deals_count <= 0) {
      return api.delete(`crm/stages`, stage.id, { params }).then(() => {
        Alert.success('Etapa excluída com sucesso');
        handleClose();
        return dispatch(removeStage(stage.id));
      });
    }

    return dealsService
      .removeStage(stage.id, values.stage_id, params)
      .then(() => {
        Alert.success('Etapa excluída com sucesso');
        handleClose();
        return dispatch(removeStage(stage.id));
      });
  };

  if (isFetching) return null;

  return (
    <Modal
      {...modalConfig}
      {...props}
      isOpen={isOpen}
      className={{
        base:
          stage.people_count < 0 ? 'Modal Modal--medium' : 'Modal Modal--small',
      }}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={`Apagar etapa "${stage.name}"`}
        handleClose={handleClose}
      >
        {stage.people_count > 0 && (
          <p className="h-margin-bottom--15">
            Antes de apagar, por favor, selecione uma das etapas existentes para
            onde os
            <br />{' '}
            <strong>
              {plural(stage.people_count, 'pessoa', 'pessoas')}
            </strong>{' '}
            de “{stage.name}” serão movidos.
          </p>
        )}
        {stageData.crms_count > 0 && (
          <p className="h-margin-bottom--15">
            Esta é a etapa padrão do sistema para todo contato recebido em uma
            dastransações do imóvel. Altere a etapa padrão na confguração do
            funil para poder excluir
          </p>
        )}
        {stage.people_count <= 0 && stageData.crms_count <= 0 && (
          <p className="h-margin-bottom--15">
            Tem certeza que deseja remover esta etapa?
          </p>
        )}
        <FormDealDelete
          stageData={stageData}
          handleClose={handleClose}
          currentStage={stage}
          funnelsAndStages={funnelsAndStages}
          onSubmit={handleFormSubmit}
          onSubmitSuccess={onSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
};

export default ModalDealDelete;
