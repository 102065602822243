import React from 'react';
import { Wrapper } from './styles';
import FiltersHistoryContainer from 'containers/FiltersHistoryContainer';

function Header({ property, onChangeFilter }) {
  return (
    <Wrapper>
      <FiltersHistoryContainer
        logeable_id={property?.id}
        onChange={onChangeFilter}
      />
    </Wrapper>
  );
}

export default Header;
