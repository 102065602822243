import propertyImage1 from 'assets/img/properties/1.jpg';
import propertyImage2 from 'assets/img/properties/2.jpg';
import propertyImage3 from 'assets/img/properties/3.jpg';
import propertyImage4 from 'assets/img/properties/4.jpg';
import propertyImage5 from 'assets/img/properties/5.jpg';

export const PROPERTIES = [
  {
    updated_at: '24/03/2020 15:57:48',
    created_at: '24/03/2020 15:57:18',
    id: '2be0ac50-d659-4fec-aec7-d456a3e370ac',
    title_formatted: 'Terreno Lote',
    calculated_price: 'R$900.000,00',
    address_formatted: 'Morrotes - Tubar\u00e3o/SC',
    complement_address: 'Complemento do endereço bem grande pra caralho',
    transaction: 1,
    street_address: 'Endereço bem grande',
    street_number: '1052',
    reference: '111',
    is_on_network: false,
    areas: {
      back_ground: {
        name: 'back_ground',
        title: 'Terreno Fundo',
        value: 5,
        measure: 'm',
      },
      left_ground: {
        name: 'left_ground',
        title: 'Terreno Esquerda',
        value: 3,
        measure: 'm',
      },
      front_ground: {
        name: 'front_ground',
        title: 'Terreno Frente',
        value: 4,
        measure: 'm',
      },
      right_ground: {
        name: 'right_ground',
        title: 'Terreno Direita',
        value: 2,
        measure: 'm',
      },
      ground_total_area: {
        name: 'ground_total_area',
        title: 'Terreno \u00c1rea Total',
        value: 1,
        measure: 'm\u00b2',
      },
    },
    rooms: {},
    network_type: 'Guru',
    status: 'Alugado',
    site_link:
      'https://lucas.codex.com/detalhes/terreno-a-venda-no-bairro-morrotes-em-tubaraosc/8ab9d045-5886-4743-8189-14ac384febea',
    is_grouped_condo: false,
    condominium_id: null,
    active: true,
    maps_latitude: -28.4829478,
    maps_longitude: -49.0262481,
    exclusive_until: null,
    is_exclusivity_expired: false,
    is_renovation_expired: false,
    next_review_at: null,
    is_draft: false,
    informations: {},
    is_blocked: true,
    user_id: 'f0cf6170-c6a8-4392-a737-7426007d00b4',
    cover_image: {
      file_url: propertyImage1,
    },
    type: {
      id: '477cb884-a249-4b2a-9764-3839a1b05746',
      title: 'Terreno',
    },
    permissions: [
      'DYNAMIC_ASSOCIATE_TO_OTHER',
      'DYNAMIC_EDIT',
      'DYNAMIC_PUBLISH',
      'DYNAMIC_DELETE',
      'DYNAMIC_DOCUMENTS',
      'DYNAMIC_PRIVATE',
      'DYNAMIC_RENT',
      'DYNAMIC_SELL',
      'DYNAMIC_TRASH',
      'DYNAMIC_REACTIVE',
    ],
    subtype: {
      updated_at: '08/04/2019 14:44:03',
      created_at: '08/04/2019 14:44:03',
      id: '69f85063-e0ff-49f6-8ec5-18f7d3bca6c0',
      title: 'Lote',
    },
    situation: {
      updated_at: '06/02/2020 14:27:55',
      created_at: '06/02/2020 14:27:55',
      id: 'c8d83cf1-c472-4c33-93c2-4a74f6df6537',
      title: 'Com vegeta\u00e7\u00e3o',
    },
  },
  {
    updated_at: '24/03/2020 15:55:51',
    created_at: '24/03/2020 15:55:40',
    id: 'a4bd1dd9-00cb-42de-a411-48ffdb82d379',
    title_formatted: 'Apartamento Alto Padr\u00e3o',
    calculated_price: 'R$900.000,00',
    address_formatted: 'Centro - Imbituba/SC',
    street_address: null,
    street_number: null,
    transaction: 1,
    reference: '111202',
    is_on_network: false,
    areas: {
      built_area: {
        name: 'built_area',
        title: '\u00c1rea Construida',
        value: 1,
        measure: 'm\u00b2',
      },
      total_area: {
        name: 'total_area',
        title: '\u00c1rea Total',
        value: 3,
        measure: 'm\u00b2',
      },
      private_area: {
        name: 'private_area',
        title: '\u00c1rea Privativa',
        value: 2,
        measure: 'm\u00b2',
      },
    },
    rooms: {
      suite: {
        name: 'suite',
        title: 'Sendo su\u00edte',
        value: '1',
        title_formated: '1 Su\u00edte',
      },
      garage: {
        name: 'garage',
        extra: {
          is_covered: {
            name: 'is_covered',
            title: 'Coberta?',
            value: true,
          },
          has_box_in_garage: {
            name: 'has_box_in_garage',
            title: 'Box Na Garagem',
            value: true,
          },
        },
        title: 'Garagem',
        value: '1',
        title_formated: '1 Garagem',
      },
      bedroom: {
        name: 'bedroom',
        title: 'Dormit\u00f3rio',
        value: '1',
        title_formated: '1 Dormit\u00f3rio, sendo 1 su\u00edte',
      },
      bathroom: {
        name: 'bathroom',
        title: 'Banheiro',
        value: '1',
        title_formated: '1 Banheiro',
      },
    },
    network_type: 'Guru',
    status: 'Vendido',
    site_link:
      'https://lucas.codex.com/detalhes/apartamento-a-venda-no-bairro-centro-em-imbitubasc/a4bd1dd9-00cb-42de-a411-48ffdb82d379',
    is_grouped_condo: false,
    condominium_id: null,
    active: true,
    maps_latitude: -28.1853357,
    maps_longitude: -48.6990679,
    exclusive_until: null,
    is_exclusivity_expired: false,
    is_renovation_expired: false,
    next_review_at: null,
    is_draft: false,
    informations: {},
    is_blocked: false,
    user_id: 'f0cf6170-c6a8-4392-a737-7426007d00b4',
    cover_image: {
      file_url: propertyImage2,
    },
    type: {
      id: '5d217d2e-4167-4fab-98a0-230fa5a2150d',
      title: 'Apartamento',
    },
    permissions: [
      'DYNAMIC_ASSOCIATE_TO_OTHER',
      'DYNAMIC_EDIT',
      'DYNAMIC_PUBLISH',
      'DYNAMIC_DELETE',
      'DYNAMIC_DOCUMENTS',
      'DYNAMIC_PRIVATE',
      'DYNAMIC_RENT',
      'DYNAMIC_SELL',
      'DYNAMIC_TRASH',
      'DYNAMIC_REACTIVE',
    ],
    subtype: {
      updated_at: '08/04/2019 14:44:03',
      created_at: '08/04/2019 14:44:03',
      id: 'd47eca34-fa7e-4052-bfe7-b55edc5dbd60',
      title: 'Alto Padr\u00e3o',
    },
    situation: {
      updated_at: '06/02/2020 14:27:55',
      created_at: '06/02/2020 14:27:55',
      id: '9b80b3ba-2083-4782-aa91-7f5f258c1d43',
      title: 'Breve lan\u00e7amento',
    },
  },
  {
    updated_at: '24/03/2020 15:32:09',
    created_at: '25/06/2019 15:16:55',
    id: '52654f9b-9ff4-436f-bffe-c16d4f4aea69',
    title_formatted: 'Apartamento Alto Padr\u00e3o',
    calculated_price: 'R$9.000.000,00',
    address_formatted: 'S\u00e3o Jo\u00e3o (Margem Direita) - Tubar\u00e3o/SC',
    transaction: 1,
    reference: '116',
    is_on_network: true,
    street_address: null,
    street_number: null,
    areas: {},
    rooms: {},
    network_type: 'Órulo',
    status: 'Dispon\u00edvel',
    site_link:
      'https://lucas.codex.com/detalhes/apartamento-a-venda-no-bairro-centro-em-imbitubasc/52654f9b-9ff4-436f-bffe-c16d4f4aea69',
    is_grouped_condo: false,
    condominium_id: '930cab79-7828-496b-8010-1d7ae9979bc9',
    active: true,
    maps_latitude: -28.4832849,
    maps_longitude: -49.0261889,
    exclusive_until: null,
    is_exclusivity_expired: false,
    is_renovation_expired: false,
    next_review_at: null,
    is_draft: false,
    informations: {
      apartment_number: {
        name: 'apartment_number',
        title: 'N\u00famero do Apartamento',
        value: '123sadsad',
      },
    },
    is_blocked: false,
    user_id: 'f0cf6170-c6a8-4392-a737-7426007d00b4',
    cover_image: {
      updated_at: '28/10/2019 14:52:59',
      created_at: '25/06/2019 15:16:56',
      id: '95070c3a-cfac-42fe-ab88-abeed7b590bf',
      gallery: 1,
      caption: 'Quarto',
      order: 1,
      property_id: '52654f9b-9ff4-436f-bffe-c16d4f4aea69',
      file_url: propertyImage3,
    },
    type: {
      id: '5d217d2e-4167-4fab-98a0-230fa5a2150d',
      title: 'Apartamento',
    },
    permissions: [
      'DYNAMIC_ASSOCIATE_TO_OTHER',
      'DYNAMIC_EDIT',
      'DYNAMIC_PUBLISH',
      'DYNAMIC_DELETE',
      'DYNAMIC_DOCUMENTS',
      'DYNAMIC_PRIVATE',
      'DYNAMIC_RENT',
      'DYNAMIC_SELL',
      'DYNAMIC_TRASH',
      'DYNAMIC_REACTIVE',
    ],
    subtype: {
      updated_at: '08/04/2019 14:44:03',
      created_at: '08/04/2019 14:44:03',
      id: 'd47eca34-fa7e-4052-bfe7-b55edc5dbd60',
      title: 'Alto Padr\u00e3o',
    },
    situation: {
      updated_at: '06/02/2020 14:27:55',
      created_at: '06/02/2020 14:27:55',
      id: 'd43494bb-7ca9-4a00-a7ff-e6da7bc24cca',
      title: 'Usado',
    },
  },
  {
    updated_at: '23/03/2020 14:52:07',
    created_at: '14/05/2019 21:46:47',
    id: '87fd05f4-d0e1-41d2-9789-3928c8c708e6',
    title_formatted: 'Apartamento Cobertura',
    calculated_price: 'R$900.000,00',
    address_formatted: 'Pican\u00e7o - Guarulhos/SP',
    transaction: 1,
    street_address: null,
    street_number: null,
    reference: '108',
    is_on_network: false,
    areas: {
      built_area: {
        name: 'built_area',
        title: '\u00c1rea Construida',
        value: 80,
        measure: 'm\u00b2',
      },
      total_area: {
        name: 'total_area',
        title: '\u00c1rea Total',
        value: 80,
        measure: 'm\u00b2',
      },
      private_area: {
        name: 'private_area',
        title: '\u00c1rea Privativa',
        value: 80,
        measure: 'm\u00b2',
      },
    },
    rooms: {
      garage: {
        name: 'garage',
        title: 'Garagem',
        value: '1',
        title_formated: '1 Garagem',
      },
      bedroom: {
        name: 'bedroom',
        title: 'Dormit\u00f3rio',
        value: '2',
        title_formated: '2 Dormit\u00f3rios',
      },
      bathroom: {
        name: 'bathroom',
        title: 'Banheiro',
        value: '1',
        title_formated: '1 Banheiro',
      },
    },
    network_type: 'Guru',
    status: 'Dispon\u00edvel',
    site_link:
      'https://lucas.codex.com/detalhes/casa-em-condominio-para-venda-em-forest-hills-jandira-sp/87fd05f4-d0e1-41d2-9789-3928c8c708e6',
    is_grouped_condo: false,
    condominium_id: '8968cca0-8b53-46c0-81a4-84e417e4c50e',
    active: true,
    maps_latitude: -28.237762923642,
    maps_longitude: -48.66502989014,
    exclusive_until: null,
    is_exclusivity_expired: false,
    is_renovation_expired: false,
    next_review_at: null,
    is_draft: false,
    informations: {
      apartment_number: {
        name: 'apartment_number',
        title: 'N\u00famero do Apartamento',
        value: '202',
      },
    },
    is_blocked: false,
    user_id: '8d5a1556-ead8-45f3-aeb0-48d76b4e4489',
    cover_image: {
      updated_at: '30/12/2019 11:15:30',
      created_at: '14/05/2019 21:47:05',
      id: '55e2f023-b61f-4667-ab8b-1f91211e5001',
      gallery: 1,
      caption: 'thiago',
      order: 1,
      property_id: '87fd05f4-d0e1-41d2-9789-3928c8c708e6',
      file_url: propertyImage4,
    },
    type: {
      id: '5d217d2e-4167-4fab-98a0-230fa5a2150d',
      title: 'Apartamento',
    },
    permissions: [
      'DYNAMIC_ASSOCIATE_TO_OTHER',
      'DYNAMIC_EDIT',
      'DYNAMIC_PUBLISH',
      'DYNAMIC_DELETE',
      'DYNAMIC_RENT',
      'DYNAMIC_SELL',
      'DYNAMIC_TRASH',
      'DYNAMIC_DOCUMENTS',
      'DYNAMIC_PRIVATE',
    ],
    subtype: {
      updated_at: '08/04/2019 14:44:03',
      created_at: '08/04/2019 14:44:03',
      id: '8b6b4d99-2731-44ff-abc2-63f0ccc16a46',
      title: 'Cobertura',
    },
    situation: {
      updated_at: '06/02/2020 14:27:55',
      created_at: '06/02/2020 14:27:55',
      id: '24cd263d-539d-426c-b88d-61e684d249ab',
      title: 'Novo',
    },
  },
  {
    updated_at: '23/03/2020 11:54:34',
    created_at: '10/04/2019 12:56:53',
    id: 'bc0eff73-3a71-47ad-96e9-96a976eb1d38',
    title_formatted: 'Apartamento Alto Padr\u00e3o',
    calculated_price: 'R$12.312.313,00',
    address_formatted: 'S\u00e3o Jo\u00e3o (Margem Direita) - Tubar\u00e3o/SC',
    transaction: 1,
    reference: '103',
    is_on_network: false,
    areas: {},
    rooms: {
      suite: {
        name: 'suite',
        title: 'Sendo su\u00edte',
        value: '2',
        title_formated: '2 Su\u00edtes',
      },
      bedroom: {
        name: 'bedroom',
        title: 'Dormit\u00f3rio',
        value: '2',
        title_formated: '2 Dormit\u00f3rios, sendo 2 su\u00edtes',
      },
      bathroom: {
        name: 'bathroom',
        title: 'Banheiro',
        value: '2',
        title_formated: '2 Banheiros',
      },
    },
    network_type: 'Guru',
    status: 'Dispon\u00edvel',
    site_link:
      'https://lucas.codex.com/detalhes/apartamento-a-venda-no-bairro-centro-em-imbitubasc/bc0eff73-3a71-47ad-96e9-96a976eb1d38',
    is_grouped_condo: false,
    condominium_id: '930cab79-7828-496b-8010-1d7ae9979bc9',
    active: true,
    maps_latitude: -28.2147256,
    maps_longitude: -48.7027438,
    exclusive_until: null,
    is_exclusivity_expired: false,
    is_renovation_expired: false,
    next_review_at: null,
    is_draft: false,
    informations: {},
    is_blocked: false,
    user_id: 'f0cf6170-c6a8-4392-a737-7426007d00b4',
    cover_image: {
      updated_at: '28/10/2019 14:52:52',
      created_at: '29/04/2019 10:29:36',
      id: '0108c96d-a4d8-4f71-90e4-3b8c3b4e7528',
      gallery: 1,
      caption: null,
      order: 1,
      property_id: 'bc0eff73-3a71-47ad-96e9-96a976eb1d38',
      file_url: propertyImage5,
    },
    type: {
      id: '5d217d2e-4167-4fab-98a0-230fa5a2150d',
      title: 'Apartamento',
    },
    permissions: [
      'DYNAMIC_ASSOCIATE_TO_OTHER',
      'DYNAMIC_EDIT',
      'DYNAMIC_PUBLISH',
      'DYNAMIC_DELETE',
      'DYNAMIC_DOCUMENTS',
      'DYNAMIC_PRIVATE',
      'DYNAMIC_RENT',
      'DYNAMIC_SELL',
      'DYNAMIC_TRASH',
      'DYNAMIC_REACTIVE',
    ],
    subtype: {
      updated_at: '08/04/2019 14:44:03',
      created_at: '08/04/2019 14:44:03',
      id: 'd47eca34-fa7e-4052-bfe7-b55edc5dbd60',
      title: 'Alto Padr\u00e3o',
    },
    situation: {
      updated_at: '06/02/2020 14:27:55',
      created_at: '06/02/2020 14:27:55',
      id: 'd43494bb-7ca9-4a00-a7ff-e6da7bc24cca',
      title: 'Usado',
    },
  },
];
