import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
// Components
import InputDate from 'components/Form/components/InputDate';
import Select from 'components/Form/components/Select';
import GroupControl from 'components/GroupControl';
import GroupItem from 'components/GroupControl/components/GroupItem';

const PERIOD = [
  { label: '3 Meses', value: 3 },
  { label: '6 Meses', value: 6 },
  { label: '12 Meses', value: 12 },
];

class ChartPeriodForm extends Component {
  static defaultProps = {};
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  handleChangePeriod = (e, value) => {
    this.props.change('end_date', moment().format('DD/MM/YYYY'));
    this.props.change(
      'start_date',
      moment().subtract(value, 'month').format('DD/MM/YYYY')
    );
  };

  handleChangeDates = () => {
    this.props.change('period', null);
  };

  componentDidMount() {
    const { handleSubmit } = this.props;
    handleSubmit();
  }

  render() {
    const { handleSubmit, isFetching, children } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          {children}
          <Field
            style={{ width: '220px' }}
            label="Período"
            name="period"
            component={Select}
            options={PERIOD}
            buttonTemplate
            onChange={this.handleChangePeriod}
            disabled={isFetching || false}
          />
          <GroupItem divider size={10} />
          <Col style={{ width: '205px' }}>
            <GroupControl label="Ou entre as datas">
              <GroupItem>
                <Field
                  format={null}
                  name="start_date"
                  maxDate={moment(this.props.end_date, 'DD/MM/YYYY').toDate()}
                  component={InputDate}
                  selected={this.props.start_date}
                  startDate={this.props.start_date}
                  endDate={this.props.end_date}
                  selectsStart
                  onChange={this.handleChangeDates}
                  disabled={isFetching || false}
                />
              </GroupItem>
              <GroupItem divider size={10} />
              <GroupItem>
                <Field
                  format={null}
                  name="end_date"
                  component={InputDate}
                  minDate={moment(this.props.start_date, 'DD/MM/YYYY').toDate()}
                  maxDate={moment().toDate()}
                  selected={this.props.end_date}
                  startDate={this.props.start_date}
                  endDate={this.props.end_date}
                  selectsEnd
                  onChange={this.handleChangeDates}
                  disabled={isFetching || false}
                />
              </GroupItem>
            </GroupControl>
          </Col>
        </Row>
      </form>
    );
  }
}

export const selector = formValueSelector('ChartPeriodForm');

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    ...ownProps.initialValues,
    period: 3,
    start_date: moment().subtract(3, 'month').format('DD/MM/YYYY'),
    end_date: moment().format('DD/MM/YYYY'),
  },
  period: selector(state, 'period'),
  start_date: selector(state, 'start_date'),
  end_date: selector(state, 'end_date'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'ChartPeriodForm',
    enableReinitialize: false,
    destroyOnUnmount: false,
    onChange: (values, dipatch, props) => {
      props.submit();
    },
  })(ChartPeriodForm)
);
