import React from 'react';

// Components
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
import Reception from './components/Reception';
import Wrapper from 'components/Wrapper';
import TableBuildings from './components/TableBuildings';

// Hooks
import { useBuildings } from 'pages/Enterprise/hooks/useBuildings';

const Enterprise = () => {
  const {
    data,
    isFetching,
    handleToggleStatus,
    handleDelete,
    handleDuplicatePage,
  } = useBuildings();

  if ((!data || data.length <= 0) && !isFetching) {
    return <Reception />;
  }

  return (
    <Wrapper>
      <MainTitle
        title="Hotsite"
        text="Páginas exclusivas para os seus imóveis ou empreendimentos."
      >
        <Button color="secondary" to="/enterprise/store">
          Criar nova página
        </Button>
      </MainTitle>
      <TableBuildings
        data={data}
        isFetching={isFetching}
        handleToggleStatus={handleToggleStatus}
        handleDelete={handleDelete}
        handleDuplicatePage={handleDuplicatePage}
      />
    </Wrapper>
  );
};

export default Enterprise;
