import { useCallback, useEffect } from 'react';

export function useDetectRefresh({ when, message }) {
  const handler = useCallback(
    e => {
      e.preventDefault();

      if (
        (e.ctrlKey && e.keyCode === 82) ||
        (e.metaKey && e.keyCode === 82) ||
        e.keyCode === 116
      ) {
        if (window.confirm(message)) {
          window.location.reload();
        }
        return false;
      }

      return false;
    },
    [message]
  );

  useEffect(() => {
    if (when) {
      document.addEventListener('keydown', handler);
    }

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [when, handler]);

  useEffect(() => {
    if (!when) {
      document.removeEventListener('keydown', handler);
    }
  }, [when]);
}
