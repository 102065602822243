import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Alert from 'react-s-alert';
// Common Components
import { ModalTemplate } from 'components/Modal';
import PropertyItinerary from 'components/PropertyItinerary';
// Services
import * as propertiesService from 'services/properties';

function ModalItinerary({
  propertiesIds,
  modalConfig,
  isOpen,
  handleClose,
  modalType
}) {
  const [properties, setProperties] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  async function fetchProperties() {
    const { data } = await propertiesService.getList({
      filter: {
        by_id: propertiesIds,
        has_location: true
      }
    });

    setProperties(data);
    setIsFetching(false);
  }

  useEffect(() => {
    if (propertiesIds?.length < 2) {
      Alert.success('Você tem que selecionar pelo menos 2 imóveis');
      handleClose();
    } else {
      fetchProperties();
    }
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Roteiro de visita" handleClose={handleClose}>
        <div style={{ margin: '-20px -19px' }}>
          <PropertyItinerary isFetching={isFetching} properties={properties} />
        </div>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalItinerary;
