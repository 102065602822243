import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Tooltip from 'react-tooltip';
// Styles
import { Wrapper } from './styles';

function ActionIcon({
  counter,
  tooltip,
  size,
  active,
  children,
  defaultColor,
  activeColor,
  onClick
}) {
  const tooltipProps = useMemo(() => {
    if (tooltip) {
      return {
        'data-tip': tooltip?.text,
        'data-position': tooltip?.position
      };
    }
  }, [tooltip]);

  useEffect(() => {
    Tooltip.rebuild();
  }, [tooltipProps]);

  const isActive = active || counter > 0;

  return (
    <ThemeProvider
      theme={{
        size,
        active: isActive,
        defaultColor,
        activeColor
      }}
    >
      <Wrapper onClick={onClick} counter={counter} {...tooltipProps}>
        {children}
      </Wrapper>
    </ThemeProvider>
  );
}

ActionIcon.defaultProps = {
  size: 40,
  tooltip: {
    text: '',
    position: 'top'
  },
  defaultColor: 'primary',
  activeColor: 'success'
};

ActionIcon.propTypes = {
  tooltip: PropTypes.shape({
    text: PropTypes.string,
    position: PropTypes.string
  }),
  defaultColor: PropTypes.string,
  activeColor: PropTypes.string
};

export default ActionIcon;
