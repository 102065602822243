import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
// Components
import Location from '../../components/Location';
// Modules
import { Actions as LocationActions, Selectors as LocationSelector } from 'containers/LocationFields/module';
// Service
import * as locationService from 'services/location';
// Constants
import { FieldsKey } from 'containers/LocationFields/constants';

class LocationContainer extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props);

    // Registra o bairro
    props.register(props.formName, FieldsKey.neighborhood);
  }

  requestNeighborhood = ({ name, city_id }) => {
    this.props.request(this.props.formName, FieldsKey.neighborhood);

    return locationService
      .getNeighborhoods({ filter: { name }, city_id })
      .then(res => {
        this.props.receive(
          this.props.formName,
          FieldsKey.neighborhood,
          res.data
        );
        return res;
      });
  };

  handleChangeCity = (e, city_id) => {
    if (!city_id) return;

    this.props.change('neighborhoods', {});
  };

  render() {
    return (
      <Location
        neighborhoods={this.props.neighborhoods}
        fetchData={this.requestNeighborhood}
        location={this.props.location}
        handleChangeCity={this.handleChangeCity}
      />
    );
  }
}

const selector = formValueSelector('ProfileForm');

const mapStateToProps = state => ({
  neighborhoods: LocationSelector.getData(
    'ProfileForm',
    FieldsKey.neighborhood
  )(state),
  location: selector(state, 'country_id', 'state_id')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(LocationActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LocationContainer);
