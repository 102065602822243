import React from 'react';
import { Action, Content, Text, Title, Wrapper } from './styles';

function Box({ title, text, action, children }) {
  return (
    <Wrapper>
      <header>
        <div>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </div>
        {action ? <Action>{action}</Action> : null}
      </header>
      <Content>{children}</Content>
    </Wrapper>
  );
}

export default Box;
