export const transformProperty = (property) => {
  let _property = {};

  _property.image = property?.cover_image?.file_url;
  _property.image_alt = property?.title_formatted;
  _property.title = property?.type?.title;
  _property.price = property?.calculated_price?.replace('R$', '');
  _property.address = property?.address_formatted;

  if (property?.rooms?.bedroom?.value) {
    const bedroom = property?.rooms?.bedroom?.value;
    const suite = property?.rooms?.suite?.value;

    _property.bedroom = `${property?.rooms?.bedroom?.value}`;

    if (suite) {
      _property.bedroom += `| ${suite}`;
      _property.bedroom_tip = `${bedroom} quarto(s) sendo ${suite} suite(s)`;
    }
  }

  if (property?.rooms?.garage?.value) {
    _property.garage = property?.rooms?.garage?.value;
  }

  if (property?.areas?.primary_area?.value) {
    const area = property?.areas?.primary_area;

    _property.primary_area = `${area?.value}${area.measure}`;
    _property.primary_area_tip = `${area?.title}`;
  }

  return _property;
};

export const hasPropertyProblem = (property) => {
  return property?.is_booked || property?.deals_count > 0;
};
