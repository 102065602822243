import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
// Modules
// Service
import * as userService from 'services/user';
// Components
import { SelectMultiple } from 'components/Form';
// Helpers
import { parseSelect } from 'lib/formHelpers';

class FieldSelectUser extends React.Component {
  state = {
    data: [],
    isFetching: false,
  };

  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    component: PropTypes.func.isRequired,
    showAllUsers: PropTypes.bool,
  };

  static defaultProps = {
    multi: false,
    label: 'Selecione um corretor',
    name: 'user_id',
    labelKey: 'name',
    valueKey: 'id',
    component: SelectMultiple,
    parse: parseSelect('id'),
    showAllUsers: false,
  };

  get options() {
    let { data } = this.state;
    const { options, users, filter } = this.props;

    if (users) return users;

    if (options) {
      data = [...options, ...data];
    }

    if (filter) {
      return data.filter(filter);
    }

    return data;
  }

  get isLoading() {
    const { isLoading } = this.props;
    const { isFetching } = this.state;
    if (isLoading) return isLoading;
    return isFetching;
  }

  componentDidMount() {
    if (!this.props.users) {
      this.fetchUsers();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(this.props.params, prevProps.params)) {
      this.fetchUsers();
    }
  }

  async fetchUsers() {
    const { params } = this.props;

    this.setState({ isFetching: true });

    const { data } = await userService.getList({
      filter: {
        realtor: true,
      },
      ...params,
    });

    this.setState({ data, isFetching: false });
  }

  render() {
    const { render, ...props } = this.props;

    if (render)
      return render({
        ...props,
        options: this.options,
      });

    return (
      <Field
        {...this.props}
        isLoading={this.isLoading}
        options={this.options}
      />
    );
  }
}

export default FieldSelectUser;
