import React from 'react';
// Components
import FormError from '../FormError';
// Helpers
import map from 'lodash/map';

export default function Errors({ meta }) {
  // verifica se tem erro o componente
  const hasError = !!(meta?.error && meta?.touched);

  return (
    <FormError isVisible={hasError}>
      {typeof meta?.error === 'object'
        ? map(meta?.error, (text, key) => (
            <div key={`error-${key}`}>{text}</div>
          ))
        : meta?.error}
    </FormError>
  );
}
