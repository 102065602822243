import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div).attrs(() => ({
  initial: { opacity: 0, x: 20 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -20 },
  transition: { duration: 0.2 },
  stagger: { offset: 0.2 },
}))`
  position: relative;
  display: flex;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
    will-change: scale;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

export const Caption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
`;
