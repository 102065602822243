import { ListAside } from 'components/ListAside';
import { Title, Wrapper } from './styles';
import Link from './components/Link';
import { useRouteMatch } from 'react-router';
import { useId } from '../../hooks/useId';
import { useAside } from '../../hooks/useAside';

const Aside = () => {
  const { url } = useRouteMatch();
  const { id } = useId();
  const { pages } = useAside();

  return (
    <Wrapper className="Aside">
      <Title>Passo a passo:</Title>
      <ListAside>
        {pages.map(({ name, path, isDisabled, isHidden, hasError }) => (
          <Link
            hasError={hasError}
            isHidden={isHidden}
            isDisabled={isDisabled}
            to={`${url}${path}/${id}`}
          >
            {name}
          </Link>
        ))}
      </ListAside>
    </Wrapper>
  );
}

export default Aside;
