import React from 'react';
// import PropTypes from 'prop-types';

const defaultProps = {};
const propTypes = {};

const FormBoxRow = ({ children }) => (
  <div className="FormBox__row">{children}</div>
);

FormBoxRow.defaultProps = defaultProps;
FormBoxRow.propTypes = propTypes;

export default FormBoxRow;
