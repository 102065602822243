import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Components
import { ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import { useHistory } from 'react-router';

const propTypes = {
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  bankSlipUrl: PropTypes.string.isRequired,
};

const defaultProps = {
  modalType: null,
  modalConfig: null,
  isOpen: null,
  handleClose: null,
  bankSlipUrl: null,
};

function ModalBankSlipSuccess({
  modalConfig,
  isOpen,
  handleClose: _handleClose,
  modalType,
  bankSlipUrl,
}) {
  const history = useHistory();

  const handleClose = () => {
    window.location.reload(); // Recarrega a página inteira
  };

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Boleto Gerado" handleClose={handleClose}>
        <p
          className="h-color--primary"
          style={{ margin: '15px 0', lineHeight: '20px' }}
        >
          Seu boleto foi gerado e o prazo para <br /> compensação pode variar de
          1 a 5 dias.
        </p>
        <Button
          tagName="a"
          size="medium"
          color="success"
          href={bankSlipUrl}
          target="_blank"
        >
          Ver boleto
        </Button>
      </ModalTemplate>
    </Modal>
  );
}

ModalBankSlipSuccess.propTypes = propTypes;
ModalBankSlipSuccess.defaultProps = defaultProps;

export default ModalBankSlipSuccess;
