import React from 'react';
import { Wrapper } from './styles';
import { MdExpandMore } from 'react-icons/md';
import Button from 'components/Button';
import Dropdown, { Item, List } from 'components/Dropdown';
import { useDispatch } from 'react-redux';
import {
  openModalUser,
  openModalUserPassword,
  openModalUserPropertyTransfer,
} from 'modules/modal';
import { deleteUser } from 'modules/users';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useRemoveContract } from 'hooks/useRemoveContract';
import { ITEM_TYPES } from 'services/financialv2/contracts';

function OptionsCell({ user }) {
  const { handleOpenModal } = useRemoveContract({
    item: ITEM_TYPES.USERS,
    onSuccess: ({ should_change_contract }) => {
      dispatch(
        deleteUser(user, {
          should_change_contract,
        })
      );
    },
  });

  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const isMySelf = currentUser.id === user.id;
  const canTransferProperty =
    user.people_exists ||
    user.properties_exists ||
    user.properties_agent_exists;
  const canDelete = !isMySelf;

  /**
   * Abre modal de transferencia de dados
   */
  const handleTransferProperties = () => {
    dispatch(
      openModalUserPropertyTransfer({
        user,
        initialValues: { from_user_id: user.id },
      })
    );
  };

  /**
   * Abre a modal de mudança de senha
   */
  const handleClickOpenPassword = () => {
    dispatch(openModalUserPassword(user.id));
  };

  /**
   * Edita um usuário
   */
  const handleUserEdit = () => {
    dispatch(openModalUser(user.id));
  };

  /**
   * Remove um usuário
   */
  const handleDeleteUser = () => {
    handleOpenModal();
  };

  return (
    <Wrapper>
      <Dropdown isDropHover>
        <Button color="white" size="medium">
          Opções <MdExpandMore />
        </Button>
        <List>
          {canTransferProperty && (
            <Item>
              <button onClick={handleTransferProperties}>
                Transferir Dados
              </button>
            </Item>
          )}
          <Item>
            <button onClick={handleClickOpenPassword}>Alterar Senha</button>
          </Item>
          <Item>
            <button onClick={handleUserEdit}>Editar</button>
          </Item>
          {canDelete && (
            <Item>
              <button onClick={handleDeleteUser}>Excluir</button>
            </Item>
          )}
        </List>
      </Dropdown>
    </Wrapper>
  );
}

export default OptionsCell;
