import React from 'react';
import PropTypes from 'prop-types';
// Components
import Timeline from 'components/Timeline';
// Shapes
import { AuditShape } from 'constants/shapes';
// Constants
import { AUDIT_ICONS } from 'constants/config';

const propTypes = {
  data: PropTypes.arrayOf(AuditShape),
  limit: PropTypes.number
};

const defaultProps = {
  data: null,
  limit: 5
};

const getAuditIcon = event => {
  try {
    return AUDIT_ICONS[event]
      ? AUDIT_ICONS[event]
      : { icon: 'plus', color: 'primary' };
  } catch {
    return { icon: 'plus', color: 'primary' };
  }
};

const ListAudits = ({ data, ...rest }) => (
  <Timeline
    {...rest}
    data={data}
    renderItem={({ id, title, event, created_at, user }) => {
      const { icon, color } = getAuditIcon(event);

      return (
        <Timeline.item
          icon={icon}
          iconColor={color}
          key={id}
          title={title}
          text={`${created_at} - ${user.name}`}
        />
      );
    }}
  />
);

ListAudits.propTypes = propTypes;
ListAudits.defaultProps = defaultProps;

export default ListAudits;
