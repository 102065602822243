import React from 'react';
import Button from 'components/Button';
import NoContent from 'components/NoContent';
import icon from './icon-watermark.png';

const WatermarkReception = () => {
  return (
    <NoContent
      image={icon}
      title="Marca D'água"
      text="Cadastrar uma marca d’água para as fotos dos seus imóveis faz com que seus clientes identifiquem facilmente sua marca, além de impedir que alguém copie suas fotos!"
    >
      <Button
        to={`/site/layout/style/logo-options/watermark`}
        color="secondary"
      >
        Enviar Marca D’água
      </Button>
    </NoContent>
  );
};

export default WatermarkReception;
