import React from 'react';
import PropTypes from 'prop-types';
import FieldFile2 from 'components/Form/components/FieldFile2';

const defaultProps = {
  value: null,
};

const propTypes = {
  value: PropTypes.string,
};

const FieldDocument = () => {
  return (
    <FieldFile2
      name="image_document"
      title="Enviar Documento"
      text="Envie uma foto do seu RG ou CNH."
    />
  );
};

// const FieldDocument = (props) => (
//   <Field
//     {...props}
//     height={173}
//     name="image_document"
//     buttonText="Enviar Documento"
//     text={<>Envie uma foto do seu RG ou CNH.</>}
//     fileUrlField="document_name"
//     component={FieldFile}
//     renderButtonChangeImage={() => (
//       <Button type="button" color="white" className="DropImage__button">
//         Trocar arquivo
//       </Button>
//     )}
//   />
// );

FieldDocument.defaultProps = defaultProps;
FieldDocument.propTypes = propTypes;

export default FieldDocument;
