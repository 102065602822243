import React from 'react';
import { Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';

const FilterField = props => (
  <Row>
    <Field xs={12} {...props} />
  </Row>
);

export default FilterField;
