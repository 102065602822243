import React from 'react';
import { Wrapper } from './styles';
import { MdInfo } from 'react-icons/md';
import { useHistory } from 'react-router';
import { formatNumber } from '../../helpers';

function PropertyCount({ data }) {
  const history = useHistory();

  const handleClick = () => {
    history.push('/crm/matched-properties');
  };

  if (!data?.properties_count || data?.properties_count <= 0) return null;

  return (
    <Wrapper>
      <MdInfo size={16} /> Você possui mais{' '}
      <em>{formatNumber(data?.properties_count)}</em> imóveis com clientes
      compatíveis!{' '}
      <span className="h-link" onClick={handleClick}>
        Ver mais
      </span>
    </Wrapper>
  );
}

export default PropertyCount;
