import React from 'react';
import PropTypes from 'prop-types';
import ListAside from '../ListAside';

function Aside({ children, title, renderAction }) {
  return (
    <div className="Permissions__aside">
      <div className="Permissions__aside-content">
        <ListAside title={title}>{children}</ListAside>
        {renderAction && (
          <div style={{ padding: '20px' }}>{renderAction()}</div>
        )}
      </div>
    </div>
  );
}

Aside.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  renderAction: PropTypes.func
};

Aside.defaultProps = {
  children: null,
  title: null,
  renderAction: null
};

export default Aside;
