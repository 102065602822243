import React, { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { reduxForm } from 'redux-form';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
import FieldVariables from 'containers/FieldVariables';
import { useRequest } from 'hooks/useRequest';
import {
  getFormData,
  updateTemplate,
} from 'services/settings/sales-authorizations';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'modules/loading';
import api from 'services';

const Store = ({ history, change, initialize, handleSubmit }) => {
  const dispatch = useDispatch();
  const { data } = useRequest({
    request: getFormData,
    initialState: {},
  });

  const handleRestoreModel = async () => {
    dispatch(showLoading());
    const {
      data: { sale_template },
    } = await api.get('settings/sales-authorizations/defaults');
    change('sale_template', sale_template);
    dispatch(hideLoading());
  };

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  useEffect(() => {
    if (!!data) {
      initialize(data);
    }
  }, [data]);

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Nova autorização de venda"
        text="Edite abaixo os dados da sua autorização de venda."
      >
        <Button
          onClick={handleRestoreModel}
          data-tip="Retorna a versão original do modelo"
          data-place="left"
        >
          Restaurar modelo
        </Button>
      </MainTitle>
      <FieldVariables name="sale_template" />
      <FixedBar>
        <FixedBar.item>
          <Button
            type="button"
            color="white"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancelar
          </Button>
        </FixedBar.item>
        <FixedBar.spacer />
        <FixedBar.item>
          <Button type="submit" color="success">
            Salvar
          </Button>
        </FixedBar.item>
      </FixedBar>
    </form>
  );
};

export default reduxForm({
  form: 'Store',
  onSubmit: (values) => {
    return updateTemplate({ sale_template: values.sale_template });
  },
  onSubmitSuccess: (res, dispatch, props) => {
    props.history.goBack();
  },
})(Store);
