import React from 'react';
import Label from 'components/Label';
// import { Container } from './styles';

const Labels = () => {
  return (
    <div>
      <div>
        <Label color="#fff">#FFF</Label>
        <Label color="#000">#000</Label>
        <Label color="#f1f1f1">#f1f1f1</Label>
      </div>
      <div>
        <Label color="white">White</Label>
        <Label color="#ffffff">#ffffff</Label>
        <Label color="primary">Primary</Label>
        <Label color="success">Success</Label>
        <Label color="caution">Caution</Label>
        <Label color="danger">Danger</Label>
        <Label color="tertiary">Tertiary</Label>
      </div>

      <div>
        <Label>Label 1</Label>
        <Label color="success">Label 1</Label>
        <Label color="primary">Label 1</Label>
        <Label color="danger">Label 1</Label>
        <Label color="caution">Label 1</Label>
      </div>
      <div>
        <Label kind="solid" color="#ff0000">
          Label 1
        </Label>
        <Label kind="solid" color="#000000">
          Label 1
        </Label>
        <Label kind="solid" color="#e200e2">
          Label 1
        </Label>
      </div>
    </div>
  );
};

export default Labels;
