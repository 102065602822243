import { useQuery } from 'hooks/useQuery';
import { openModalPropertySearch } from 'modules/modal';
import { setNetwork } from 'modules/propertySearch';
import { useLayout } from 'pages/PortalsOptionSelect/hooks/useLayout';
import { usePortalSelector } from 'pages/PortalsOptionSelect/hooks/usePortalSelector';
import { useSearchConditions } from 'pages/PortalsOptionSelect/hooks/useSearchConditions';
import qs from 'qs';
import { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getFormValues } from 'redux-form';
import { useProperties } from './hooks/useProperties';

export const PortalOptionsContext = createContext({
  dispatch: () => null,
  params: null,
  toggleSelectedProperty: null,
  properties: null,
  meta: null,
  selectedProperties: null,
  isFetching: false,
  conditions: {
    isSearchingByReference: false,
    isSearchingByCondo: false,
    isSearchingByCharacteristics: false,
    isSearchingByStreetAddress: false,
    canSearchByReference: false,
    canShowClearSearch: false,
    canShowCondo: false,
    canShowStreetAddress: false,
    isSearchingByFilter: false,
  },
  portals: [],
  selectedPortal: {
    client_code: null,
    created_at: null,
    id: null,
    name: null,
    options: [],
    portal: {
      created_at: null,
      portal_real_estate: {
        xml_url: null,
      },
      file_url: null,
      id: null,
      leads_link: null,
      name: null,
      portal_model_id: null,
      updated_at: null,
    },
    portal_id: null,
    real_estate_id: null,
    updated_at: null,
  },
  selectedOption: {
    created_at: null,
    id: null,
    name: null,
    portal_option_id: null,
    portal_real_estate_id: null,
    properties_count: null,
    quantity: null,
    updated_at: null,
  },
  layout: '',
  setLayoutTable: () => null,
  setLayoutCard: () => null,
  fetchProperties: () => null,
  fetchPortals: () => null,
  handleRemove: () => null,
  handleRemoveAll: () => null,
  handleClickSearch: () => null,
  clearSelectedProperties: () => null,
});

// Selector dos valores do formulário
export const formValuesSelector = getFormValues('PropertiesPortalForm');

export const PortalOptionsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useQuery({ withParams: true });
  const conditions = useSearchConditions(params);
  const { layout, setLayoutTable, setLayoutCard } = useLayout();

  const { portals, selectedOption, selectedPortal, fetchPortals } =
    usePortalSelector(formValuesSelector);

  const {
    fetchProperties,
    isFetching,
    meta,
    properties,
    selectedProperties,
    toggleSelectedProperty,
    handleRemove,
    handleRemoveAll,
    clearSelectedProperties,
  } = useProperties({ selectedOption, selectedPortal, params, fetchPortals });

  // Lida com o click pra abrir a modal de pesquisa
  const handleClickSearch = () => {
    dispatch(
      openModalPropertySearch({
        isPortals: true,
        filter: {
          ...params?.filter,

          // Adiciona esse filtro para remover todos
          // os imóveis que já estão selecionados no portal
          doesnt_have_portal_real_estate_id: params?.portalRealEstateId,
        },
        onSubmit: (values, dispatch, props) => {
          const count = props.propertiesCount;
          const modalSearch = localStorage.getItem('@tecimob/modalSearch');

          if (values.by_state_id || values.by_city_id) {
            localStorage.setItem('@tecimob/modalSearch', {
              ...modalSearch,
              by_state_id: values?.by_state_id || null,
              by_city_id: values?.by_city_id || null,
            });
          }

          const searchParams = qs.parse(history.location.search, {
            ignoreQueryPrefix: true,
          });

          const filter = qs.stringify({
            ...searchParams,
            filter: { ...values, count },
            limit: 50,
            offset: 1,
            sort: '-calculated_price',
          });

          dispatch(setNetwork('self'));

          props.history.push(`/portals/${selectedPortal?.id}?${filter}`);

          props.handleClose();
        },
      })
    );
  };

  return (
    <PortalOptionsContext.Provider
      value={{
        params,
        dispatch,
        properties,
        selectedProperties,
        isFetching,
        meta,
        fetchProperties,
        portals,
        fetchPortals,
        selectedPortal,
        selectedOption,
        conditions,
        handleRemove,
        handleRemoveAll,
        handleClickSearch,
        toggleSelectedProperty,
        clearSelectedProperties,
        layout,
        setLayoutTable,
        setLayoutCard,
      }}
    >
      {children}
    </PortalOptionsContext.Provider>
  );
};

export const usePortalOptions = () => useContext(PortalOptionsContext);
