import { combineReducers } from 'redux';
import * as propertiesService from 'services/properties';
import dataReducer from 'modules/data';

export const {
  reducer: dealsReducer,
  actionCreators: dealsActions
} = dataReducer('modalProperty/deals');

export default combineReducers({
  deals: dealsReducer
});

export const fetchOpenedDeals = propertyId => dispatch => {
  dispatch(dealsActions.request());
  return propertiesService
    .getOpenedDeals(propertyId)
    .then(({ data: property }) => dispatch(dealsActions.receive(property)));
};

export const fetchLossDeals = propertyId => dispatch => {
  dispatch(dealsActions.request());

  return propertiesService
    .getLossDeals(propertyId)
    .then(({ data: property }) => dispatch(dealsActions.receive(property)));
};
