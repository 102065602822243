import { useEffect, useState } from 'react';

export const useLayout = (initialValue = 'table', localStorageKey) => {
  const [layout, _setLayout] = useState(initialValue);

  const setLayout = (value) => {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, value);
    }
    _setLayout(value);
  };

  const setLayoutTable = () => {
    setLayout('table');
  };

  const setLayoutCard = () => {
    setLayout('card');
  };

  useEffect(() => {
    if (localStorageKey) {
      const storage = localStorage.getItem(localStorageKey);

      if (storage) {
        setLayout(storage);
      }
    }
  }, [localStorageKey]);

  return { layout, setLayout, setLayoutTable, setLayoutCard };
};
