import { FieldBool } from "components/Form";

function IsExchangeable() {
  return (
    <FieldBool
      label="Aceita permuta?"
      name="is_exchangeable"
      canRemoveSelf
    />
  );
}

export default IsExchangeable;
