import React, { useEffect } from 'react';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';

// Components
import Container from 'components/Container';
import MainTitle from 'components/MainTitle';

// HOC
import { withId } from '../../HOC/withId';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Services
import * as areasService from 'services/propertiesPartial/areas';
import { Row, Col } from 'react-flexbox-grid';
import GroupControl, { GroupItem } from 'components/GroupControl';
import { Input, Select } from 'components/Form';
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import { finishResponse } from 'pages/PropertyStore/helpers';
import Loading from 'components/Loading';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { useParams } from 'react-router';

const Measures = ({ handleSubmit, initialize, onSubmit, submitting }) => {
  const { data, fetchData } = useRequest({
    request: areasService.getOne,
    initialFetch: false,
    initialState: {}
  });
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    initialize(data);
  }, [data]);

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle title="Medidas" text="Defina as medidas deste imóvel." />
      <Container>
        <Row>
          {data?.type?.area_fields?.map(({ name, title, measures }) => (
            <Col key={name} xs={3}>
              <GroupControl label={title}>
                <GroupItem>
                  <Field
                    name={`areas.${name}.value`}
                    component={Input}
                    valueFormat
                    prefix=""
                  />
                </GroupItem>
                <GroupItem size="small">
                  <Field
                    name={`areas.${name}.measure`}
                    placeholder=""
                    component={Select}
                    options={measures.map(measure => ({
                      label: measure,
                      value: measure
                    }))}
                  />
                </GroupItem>
              </GroupControl>
            </Col>
          ))}
        </Row>
      </Container>

      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyMeasures',
    initialValues: {},
    onSubmit: (values, d, p) =>
      areasService.update(values).then(finishResponse(values, d, p)),
    onSubmitSuccess: (response, dispatch, p) => {
      p.initialize(response?.data);

      if (!p.isEditting) {
        p.next('financial');
      }
    }
  }),
  withFormConfirmation()
)(Measures);
