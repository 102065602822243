import Button from 'components/Button';
import { deleteInvite } from 'modules/invites';
import { useDispatch } from 'react-redux';
import { Wrapper } from './styles';

function OptionsCell({ invite }) {
  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(deleteInvite(invite));
  }
  
  return (
    <Wrapper>
      <Button color="danger" onClick={handleRemove}>Excluir</Button>
    </Wrapper>
  );
}

export default OptionsCell;
