import React from 'react';
import Button from 'components/Button';
import { Aside, BoxImage, Wrapper } from './styles';
import iconBackup from '../../assets/icon-backup.png';
import ListBackups from './components/ListBackups';

function CardExport({ data, isSubmitting, onGenerateBackup }) {
  // Pega só os 3 primeiros resultados
  const _data = data.slice(0, 3);

  const isGenerating =
    isSubmitting || _data?.some((backup) => backup.is_exporting);

  return (
    <Wrapper>
      <Aside>
        <h2>Exportar dados</h2>
        <p>
          Baixe facilmente os dados de seus clientes, CRM e imóveis em formato
          de planilha.
          <br />
          <br />
          Clique em 'Gerar arquivo de dados' e aguarde, leva menos de 5 minutos
          pra ficar pronto. Você será notificado para fazer o download assim que
          o arquivo estiver pronto.
        </p>

        <Button
          disabled={isGenerating}
          color="success"
          onClick={!isGenerating ? onGenerateBackup : () => {}}
        >
          Gerar Arquivo de dados
        </Button>
      </Aside>
      {_data.length > 0 ? (
        <ListBackups data={_data} />
      ) : (
        <BoxImage>
          <img src={iconBackup} alt="" />
        </BoxImage>
      )}
    </Wrapper>
  );
}

export default CardExport;
