import React from 'react';
import * as PropTypes from 'prop-types';
import DealAudits from 'containers/DealAudits';
import Button from 'components/Button';
import { MdMoreVert } from 'react-icons/md';
import Popover from 'components/Popover';
// import { Container } from './styles';

const Actions = ({ dealId, handleClickCloseDeal, handleClickLoss }) => {
  return (
    <Popover
      width={325}
      component={({ handleClose }) => (
        <div onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              maxHeight: 300,
              overflow: 'auto',
              margin: '-20px -20px -15px',
              padding: '20px',
            }}
          >
            <DealAudits dealId={dealId} />
          </div>
          <div
            className="h-flex"
            style={{
              margin: '15px -20px -20px',
              padding: '15px 20px',
              borderTop: '1px solid #DEDEDE',
            }}
          >
            <Button
              type="button"
              color="success"
              onClick={handleClickCloseDeal(handleClose)}
              style={{ flexGrow: 1 }}
            >
              Fechei negócio
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={handleClickLoss(handleClose)}
              style={{ flexGrow: 1 }}
            >
              Negócio perdido
            </Button>
          </div>
        </div>
      )}
    >
      <div className="CardCrm__action">
        <MdMoreVert />
      </div>
    </Popover>
  );
};

Actions.propTypes = {
  handleClickCloseDeal: PropTypes.func.isRequired,
  handleClickLoss: PropTypes.func.isRequired,
};

export default Actions;
