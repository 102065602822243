import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
// Constants
import { TYPES } from 'pages/Help/constants';

const propTypes = {
  type: PropTypes.oneOf([TYPES.PROBLEM, TYPES.SUGESTION])
};
const defaultProps = {
  type: TYPES.PROBLEM
};

const Header = ({ type, handleOpenIssue }) => {
  if (type === TYPES.PROBLEM) {
    return (
      <MainTitle
        title="Meus tickets de atendimento"
        text="Um ticket leva em média um dia útil para ser atendido."
      >
        <Button color="danger" onClick={handleOpenIssue}>
          Abrir Ticket
        </Button>
      </MainTitle>
    );
  }

  return (
    <MainTitle
      title="Sugestão"
      text="Dê sugestões de melhorias para agilizar o seu trabalho."
    >
      <Button color="success" onClick={handleOpenIssue}>
        Enviar Sugestão
      </Button>
    </MainTitle>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
