import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;

  p {
    margin: 5px 0 10px;
    line-height: 18px;
  }

  h4 {
    margin: 5px 0 5px;
  }

  img {
    margin: 0 auto 10px;
  }
`;
