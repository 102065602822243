import React, { useEffect, useCallback } from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

// HOC
import { withId } from '../../HOC/withId';

// Hooks
// Services
import * as establishmentsService from 'services/propertiesPartial/establishments';
import { transformResponse } from 'services/propertiesPartial/establishments';

// Modules
import Button from 'components/Button';
import Container from 'components/Container';
import Loading from 'components/Loading';
import ListCheck from 'components/ListCheck';
import { useDispatch, useSelector } from 'react-redux';
import {
  establishmentsSelector,
  establishmentsMetaSelector,
  getEstablishments,
  handleCreateEstablishmentWithForm,
} from 'modules/establishments';
import { detectVisibility, undetectVisibility } from 'lib/helpers';
import MainTitle from 'components/MainTitle';
import Popover from 'components/Popover';
import FormEstablishments from 'components/FormEstablishments';
import { USER_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';
import { MdAdd } from 'react-icons/md';
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import { finishResponse } from 'pages/PropertyStore/helpers';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { useParams } from 'react-router';

const Establishments = ({ handleSubmit, initialize, onSubmit, submitting }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const establishments = useSelector(establishmentsSelector);
  const { isFetching } = useSelector(establishmentsMetaSelector);

  /**
   * Cadastro de um estabelecimento
   * @param typeId|String
   */
  const onSubmitEstablishment = useCallback(
    (values, d, p) => dispatch(handleCreateEstablishmentWithForm(values, 'PropertyEstablishments')),
    []
  );

  const handleFocusPage = useCallback(() => {
    const handle = detectVisibility((isVisible) => {
      if (!isVisible) {
        dispatch(getEstablishments());

        undetectVisibility(handle);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEstablishments());
  }, []);

  const initializeForm = useCallback(async () => {
    const { data } = await establishmentsService.getOne(id);
    initialize(data);
  }, [initialize, id]);

  useEffect(() => {
    initializeForm();
  }, [id]);

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle
        title="Proximidades"
        text="Defina os estabelecimentos próximos a este imóvel."
      >
        <Can
          run={USER_PERMISSIONS.EDIT_CHARACTERISTICS}
          yes={() => (
            <>
              <Button
                tagName="a"
                href="/config/registrations/establishments"
                onClick={handleFocusPage}
                target="_blank"
              >
                Gerenciar
              </Button>
              <Popover
                onSubmit={onSubmitEstablishment}
                component={FormEstablishments}
              >
                <Button color="secondary">
                  <MdAdd /> Adicionar estabelecimento
                </Button>
              </Popover>
            </>
          )}
          no={() => (
            <span data-tip="Você não tem permissão para gerenciar estabelecimentos">
              <Button disabled>
                <MdAdd /> Adicionar estabelecimento
              </Button>
            </span>
          )}
        />
      </MainTitle>
      <Container>
        <Loading isVisible={isFetching} isFullComponent />
        <ListCheck cols={4} name="establishments" data={establishments} />
      </Container>

      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyEstablishments',
    initialValues: {
      is_condo: false,
    },
    onSubmit: (values, d, p) =>
      establishmentsService.update(values).then(finishResponse(values, d, p)),
    onSubmitSuccess: (response, dispatch, p) => {
      const newResponse = transformResponse(response);

      p.initialize(newResponse?.data);

      if (!p.isEditting) {
        p.next('description');
      }
    },
  }),
  withFormConfirmation()
)(Establishments);
