import { useSelector } from 'react-redux';
import { currentCreditSelector } from 'modules/financial/currentCredit';

/**
 * Retorna os dados do contrato atual
 * @return {{
 *    data: {
 *      closed_at: string,
 *      contract_changes: string,
 *      created_at: string,
 *      daily_cost: string,
 *      end_at: string,
 *      id: string,
 *      plan: string,
 *      start_at: string,
 *      type: string,
 *      updated_at: string,
 *      user_id: string
 *    },
 *    isFetching: boolean
 * }}
 */
export const useCurrentCredit = () => {
  return useSelector(currentCreditSelector);
};
