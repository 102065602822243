import React from 'react';
import { reduxForm } from 'redux-form';
// Components
import FieldStages from 'containers/FieldStages';
import Button from 'components/Button';

function FormChangeStage({ funnelId, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <FieldStages funnelId={funnelId} label="Mudar para etapa" />
      <Button color="success" block type="submit">
        Concluir
      </Button>
    </form>
  );
}

FormChangeStage.defaultProps = {};
FormChangeStage.propTypes = {};

export default reduxForm({
  form: 'FormChangeStage',
  enableReinitialize: true
})(FormChangeStage);
