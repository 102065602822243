import React from 'react';
import { Field } from 'redux-form';
import { SelectMultiple } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import * as userService from 'services/user';
import { parseSelect } from 'lib/formHelpers';

const EarnedDealField = ({
  label = 'Corretor que fechou negócio',
  name = 'by_earned_deal.user_id'
}) => {
  const { data, isFetching } = useRequest({
    request: userService.getList
  });

  return (
    <Field
      multi={false}
      isLoading={isFetching}
      label={label}
      name={name}
      component={SelectMultiple}
      options={data}
      labelKey="name"
      valueKey="id"
      parse={parseSelect('id')}
    />
  );
};

export default EarnedDealField;
