import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import Label from 'components/Label';
import Table from 'components/Table';
// Constants
import { TYPES } from 'pages/Help/constants';

const propTypes = {
  type: PropTypes.oneOf([TYPES.PROBLEM, TYPES.SUGESTION]),
  data: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool
};

const defaultProps = {
  type: TYPES.PROBLEM,
  data: [],
  isFetching: false
};

const TableTickets = ({ type, data, isFetching, handleClickAvaliation }) => (
  <Table
    data={data}
    isFetching={isFetching}
    renderTitle={() => (
      <tr>
        <th>Data atualização</th>
        <th>Código</th>
        <th>Título</th>
        <th colSpan={2}>Status</th>
      </tr>
    )}
    renderItem={({ id, updated_at, title, serial, status, can_evaluate }) => (
      <tr key={id} className="h-hover">
        <td>{updated_at}</td>
        <td>{serial}</td>
        <td>{title}</td>
        <td>
          <Label color={status.color}>{status.title}</Label>
        </td>
        <td className="Table__cell--small">
          <div className="h-flex" style={{ justifyContent: 'flex-end' }}>
            <Button
              to={`/help/${type}/detail/${id}`}
              className="h-hover__show"
              color="white"
              colorText="secondary"
              size="small"
            >
              Ver detalhes
            </Button>
            {!can_evaluate && (
              <Button
                size="small"
                onClick={handleClickAvaliation(id)}
                data-tip="Avalie nosso atendimento"
              >
                Avaliar
              </Button>
            )}
          </div>
        </td>
      </tr>
    )}
  />
);

TableTickets.propTypes = propTypes;
TableTickets.defaultProps = defaultProps;

export default TableTickets;
