// Widgets.js
import _ from 'lodash';
import { createSelector } from 'reselect';
import { createModule } from 'lib/reducer-helpers';
import { formValueSelector } from 'redux-form';
// Services
import * as portalService from 'services/settings/portals';

// Seletor do formulário
const formSelector = formValueSelector('FormPortalRealEstate');

// Seleciona todas as propriedades que foram inicializadas no formulário
export const propertiesSelector = state => formSelector(state, 'properties');

// Retorna todas as opções do real_estate_portal
export const portalRealEstateSelector = state => state.portalRealEstate.data;
export const optionsSelector = state => state.portalRealEstate.data.options;

export const optionsSelecteds = createSelector(
  [propertiesSelector, portalRealEstateSelector],
  (properties, portalRealEstate) => {
    if (!properties) return {};
    let response = {};

    _.map(properties, property => {
      try {
        const portalRealEstateOption =
          property.portal_real_estate_options[portalRealEstate.id];

        const option = portalRealEstate.options.find(
          option => option.id === portalRealEstateOption.id
        );

        response = {
          ...response,
          [option.name]: {
            ...option,
            ...response[option.name],
            value: response[option.name] ? response[option.name].value + 1 : 1
          }
        };
      } catch {
        return {};
      }

      return property;
    });

    return response;
  }
);

const createAction = createModule('portalRealEstate');

// Actions
export const Types = {
  REQUEST_PORTAL_REALESTATE: createAction('REQUEST_PORTAL_REALESTATE'),
  RECEIVE_PORTAL_REALESTATE: createAction('RECEIVE_PORTAL_REALESTATE'),
  RESET: createAction('RESET')
};

const initialState = {
  data: {
    id: '',
    portal_options: [],
    options: []
  },
  isFetching: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.REQUEST_PORTAL_REALESTATE:
      return {
        ...state,
        isFetching: true
      };
    case Types.RECEIVE_PORTAL_REALESTATE:
      return {
        ...state,
        data: action.data,
        isFetching: false
      };
    case Types.RESET:
      return initialState;
    default:
      return state;
  }
}

export const Actions = {
  requestPortalRealestate: () => ({ type: Types.REQUEST_PORTAL_REALESTATE }),
  receivePortalRealestate: data => {
    return {
      type: Types.RECEIVE_PORTAL_REALESTATE,
      data: {
        ...data,
        options: _.map(data.options, options => options)
      }
    };
  },
  resetPortalRealestate: () => ({ type: Types.RESET })
};

/**
 * Busca os dados da relação de portal e imobiliária
 * @param portalRealEstateId
 * @returns {Function}
 */
export const fetchPortalRealEstate = (
  portalRealEstateId,
  params
) => async dispatch => {
  dispatch(Actions.requestPortalRealestate());
  const { data } = await portalService.getOneRelation(
    portalRealEstateId,
    params
  );
  dispatch(Actions.receivePortalRealestate(data));
  return data;
};
