export const base = {
  borderColor: '#D3DCEB',
  borderRadius: '4px',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.1)'
};

export const colors = {
  primary: '#1C0C1E',
  secondary: '#0084F4',
  success: '#31D084',
  danger: '#F84343',
  caution: '#FD9526',
  tertiary: '#595bd3'
};

export const theme = {
  ...base,
  colors
};
