import React from 'react';
import Button from 'components/Button';
import { ModalTemplate } from 'components/Modal';
import { Content } from './styles';

const PaymentError = ({ setHasError, handleClose }) => {
  return (
    <ModalTemplate title="Pagamento" handleClose={handleClose}>
      <Content>
        <h2>Falha na cobrança</h2>
        <p>
          Verifique se existe alguma mensagem no aplicativo do seu banco,
          <br />
          ou entre em contato com sua operadora de cartão e tente novamente
        </p>
        <Button
          color="tertiary"
          size="small"
          onClick={() => setHasError(false)}
        >
          Voltar
        </Button>
      </Content>
    </ModalTemplate>
  );
};

export default PaymentError;
