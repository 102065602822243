import React from 'react';
import { Col } from 'react-flexbox-grid';
import { TableRow } from 'containers/ModalRoot/ModalProperty/pages/Record/index';
import Realtor from '../Realtor';
import RatingData from 'components/RatingData';

// import { Wrapper } from './styles';

function DetailsProperty({ property, props }) {
  const canShowCondoPrice = !!property.calculated_condominium_price;
  const canShowTerritorialPrice =
    !!property.calculated_territorial_tax_price &&
    property.calculated_territorial_tax_price !== '0';
  const canShowOtherTaxes = !!property.calculated_taxes_price;

  const canShowTaxesSection =
    canShowCondoPrice || canShowTerritorialPrice || canShowOtherTaxes;

  const isOrulo = property.network_type === 'Órulo';
  const isDWV = property.network_type === 'DWV';

  return (
    <Col xs={5} style={{ paddingLeft: 40 }}>
      <h5 style={{ paddingTop: 20, marginBottom: 15 }}>Detalhes do imóvel</h5>
      <Realtor property={property} />
      <table className="table--info">
        <tbody>
          {/*{property.condominium && (*/}
          {/*  <>*/}
          {/*    <TableRow title="Condomínio" value={property.condominium.title} />*/}
          {/*  </>*/}
          {/*)}*/}
          {property?.informations?.apartment_number && (
            <TableRow
              title="Unidade"
              value={property?.informations?.apartment_number?.value}
            />
          )}
          {property?.informations?.lot_number && (
            <TableRow
              title="Unidade"
              value={property?.informations?.lot_number?.value}
            />
          )}
          {property?.informations?.floor && (
            <TableRow
              title="Andar"
              value={property?.informations?.floor?.value}
            />
          )}
          {property?.informations?.units_per_floor && (
            <TableRow
              title="Unidades por andar"
              value={property?.informations?.units_per_floor?.value}
            />
          )}
          {property?.informations?.floors_number && (
            <TableRow
              title="Total de andares"
              value={property?.informations?.floors_number?.value}
            />
          )}
          {property?.informations?.towers_number && (
            <TableRow
              title="Total de torres"
              value={property?.informations?.towers_number?.value}
            />
          )}
          <TableRow
            title="Posição solar"
            value={property?.solar_position?.title}
          />
          <TableRow title="Posição" value={property.formatted_condo_position} />
          <TableRow title="Próximo ao mar" value={property.nearSeaName} />
          <TableRow title="Ano da construção" value={property.lifetime} />
          <TableRow
            title="Previsão de entrega"
            value={property.delivery_forecast}
          />
          <TableRow title="Incorporação" value={property.incorporation} />
          <TableRow
            title="Esquina"
            value={property.is_corner ? 'Sim' : 'Não'}
          />

          <TableRow title="Terreno" value={property.landTypeName} />
          {property.transactionName === 'Venda' && (
            <>
              <TableRow
                title="Tem Financiamento"
                value={property?.has_finance ? 'Sim' : 'Não'}
              />
              <TableRow
                title="Aceita financiamento"
                value={property?.is_financeable ? 'Sim' : 'Não'}
              />
            </>
          )}
          <TableRow
            title="Minha Casa Minha Vida"
            value={property?.is_financeable_mcmv ? 'Sim' : 'Não'}
          />
          <TableRow
            title="Aceita permuta"
            value={property.is_exchangeable ? 'Sim' : 'Não'}
          />
          {property.transaction === 2 ? (
            <TableRow
              title="Hóspedes"
              value={
                property.max_people > 1
                  ? `até ${property.max_people} pessoas`
                  : 'máximo 1 pessoa'
              }
            />
          ) : null}
        </tbody>
      </table>

      {canShowTaxesSection && (
        <>
          <h5 style={{ paddingTop: 30, paddingBottom: 15 }}>
            Taxas{' '}
            {property.transactionName ? `de ${property.transactionName}` : ''}
          </h5>
          <table className="table--info">
            <tbody>
              {canShowCondoPrice && (
                <TableRow
                  title="Condomínio"
                  value={
                    property.calculated_condominium_price &&
                    `${property.calculated_condominium_price} /Mês`
                  }
                />
              )}
              {canShowTerritorialPrice && (
                <TableRow
                  title="IPTU"
                  value={
                    property.calculated_territorial_tax_price &&
                    `${property.calculated_territorial_tax_price} /${
                      property?.territorial_tax_type == '2' ? 'Mês' : 'Ano'
                    }`
                  }
                />
              )}
              {canShowOtherTaxes && (
                <TableRow
                  title={property.taxes_description}
                  value={
                    property.calculated_taxes_price &&
                    `${property.calculated_taxes_price}`
                  }
                />
              )}
            </tbody>
          </table>
        </>
      )}
      {!isOrulo && !isDWV ? (
        <RatingData
          isOpen={props.isOpenRate}
          propertyId={property?.id}
          data={property?.rate?.fields}
          value={property?.rate?.rate}
        />
      ) : null}
    </Col>
  );
}

export default DetailsProperty;
