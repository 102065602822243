import { useDispatch } from 'react-redux';
import { openModalMovimentDetail } from '../modals/ModalMovimentDetail';

export const useModalMovimentDetail = () => {
  const dispatch = useDispatch();

  const handleOpenModalMovimentDetail = ({ postingId, movimentId }) => {
    dispatch(
      openModalMovimentDetail({
        postingId,
        movimentId,
      })
    );
  };

  return { handleOpenModalMovimentDetail };
};
