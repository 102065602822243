import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 15px;
  border: 1px solid #e8e7e8;
  border-radius: 2px;
  background: #fff;
  margin-top: 15px;
`;
export const Header = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const DetailsLink = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
`;

export const Content = styled.div``;
