// import styles from './styles'

import { Field, reduxForm } from 'redux-form';
import { Input } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import { Fields } from 'features/Financial';
import Button from 'components/Button';
import { Col, Row } from 'react-flexbox-grid';
import { normalizeDays } from 'lib/formHelpers';
import useFormValue from 'hooks/useFormValue';
import { closeModal } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { openModalStoreAccount } from '../../index';

function FormStoreCard({ type, handleSubmit, handleClose, reopenModal }) {
  const dispatch = useDispatch();

  const values = useFormValue(['title', 'limit', 'closing_day', 'due_day']);

  const onCreateAccount = (res) => {
    dispatch(closeModal());

    setTimeout(() => {
      reopenModal({
        type: 2,
        values: {
          ...values,
          related_account_id: res.data.id,
        },
      });
    }, 50);
  };

  const onClickNew = (value) => {
    dispatch(closeModal());

    setTimeout(() => {
      dispatch(
        openModalStoreAccount({
          data: {
            title: value.title,
          },
          type: 1,
          onSuccess: onCreateAccount,
        })
      );
    }, 50);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={5}>
          <Fields.Icon />
        </Col>
        <Col xs={7}>
          <Field
            required
            label="Nome da cartão"
            name="title"
            component={Input}
          />
          <Field
            required
            valueFormat
            label="Limite"
            name="limit"
            component={Input}
            prefix=""
            inputProps={{
              decimalScale: 2,
            }}
          />
          <Row>
            <Col xs={6}>
              <Field
                type="number"
                required
                label="Fecha dia"
                name="closing_day"
                component={Input}
                normalize={normalizeDays}
              />
            </Col>
            <Col xs={6}>
              <Field
                type="number"
                required
                label="Vence dia"
                name="due_day"
                component={Input}
                normalize={normalizeDays}
              />
            </Col>
          </Row>
          <Row>
            <Fields.Account
              required
              xs={12}
              label="Conta de pagamento padrão"
              name="related_account_id"
              onCreate={onCreateAccount}
              onClickNew={onClickNew}
            />
          </Row>
        </Col>
      </Row>

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({ form: 'FormStoreCard' })(FormStoreCard);
