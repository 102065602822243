import React from 'react';

export const THead = () => {
  return (
    <thead>
      <tr>
        <th>Categoria</th>
        <th width={130} align="right">
          Valor
        </th>
        <th width={130} align="center">
          Percentual
        </th>
      </tr>
    </thead>
  );
};
