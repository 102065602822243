// Widgets.js
import { forEach } from 'lodash';
import { omit } from 'lodash/object';
import Alert from 'react-s-alert';
import { createModule } from '../lib/reducer-helpers';
import { arrayToObject } from '../lib/array-helpers';
import * as dealsService from '../services/deals';
import { closeModal, openModalDealDelete } from '../modules/modal';
import { getFormValues } from 'redux-form';
import * as peopleService from 'services/people';

const createAction = createModule('pipeline');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const ADD_DEALS = createAction('ADD_DEALS');
const CLEAR_DEALS = createAction('CLEAR_DEALS');
const SET_PAGINATION = createAction('SET_PAGINATION');

const ADD_STAGE = createAction('ADD_STAGE');
const UPDATE_STAGE = createAction('UPDATE_STAGE');

const SET_DRAGGING = createAction('SET_DRAGGING');
const UNSET_DRAGGING = createAction('UNSET_DRAGGING');

const REORDER_STAGE = createAction('REORDER_STAGE');
const REMOVE_STAGE = createAction('REMOVE_STAGE');

const REORDER_DEAL = createAction('REORDER_DEAL');
const MOVE_DEAL = createAction('MOVE_DEAL');
const REMOVE_DEAL = createAction('REMOVE_DEAL');
const UPDATE_META = createAction('UPDATE_META');
const SET_META = createAction('SET_META');

const RESET_PIPELINE = createAction('RESET_PIPELINE');

// pega toda a pipeline incluindo stagios e negocios
export function getPaginationByStageId(state, stageId) {
  try {
    return state.pipeline.data[stageId].pagination;
  } catch (e) {
    throw new Error(e);
  }
}
export const stagesSelector = (state) => state.pipeline.data;
export const isDraggingSelector = (state) => state.pipeline.meta.isDragging;
export const isFetchingSelector = (state) => state.pipeline.meta.isFetching;

const initialState = {
  data: {},
  meta: {
    isFetching: false,
    isDragging: false,
  },
};

function updateOrder(array) {
  array.sort((a, b) => a.order - b.order);

  // Update the order property based on the new order
  array.forEach((item, index) => {
    item.order = index + 1;
  });
}

/**
 * Reordena uma etapa quando está dentro da mesma lista
 * @param list
 * @param startIndex
 * @param endIndex
 * @return {Array}
 */
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const element = result.splice(startIndex, 1)[0];
  result.splice(endIndex, 0, element);

  return result.filter((obj) => obj !== null);
};

/**
 * Move um negócio de uma lista para outra
 * @param source
 * @param destination
 * @param droppableSource
 * @param droppableDestination
 * @param data
 * @return {{}}
 */
export const move = (
  source,
  destination,
  droppableSource,
  droppableDestination,
  data
) => {
  const destId = droppableDestination.droppableId;
  const destStage = data[destId];

  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);

  // remove o source
  let [removed] = sourceClone.splice(droppableSource.index, 1);

  removed = {
    ...removed,
    stage: {
      ...removed?.stage,
      id: destId,
      color: destStage?.color,
      name: destStage?.name,
    },
    stage_id: destId,
  };

  // depois cola no destino
  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const removeStagnant = (destIndex) => (deal, index, deals) => {
  if (deals?.[destIndex]?.id === deal?.id) {
    return { ...deal, stagnant_days: false };
  }
  return deal;
};

const createStage = (stage, state) => {
  return {
    ...state?.data[stage?.id],
    people_count: stage?.people_count,
    pagination: {
      ...state?.data[stage?.id]?.pagination,
      total: stage?.count,
    },
    meta: {
      ...state?.data[stage?.id]?.meta,
      prices_sum: stage?.prices_sum,
    },
  };
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        meta: { ...state.meta, isFetching: true },
      };
    case RECEIVE:
      return {
        ...state,
        data: action.stages,
        meta: { ...state.meta, isFetching: false },
      };

    case ADD_DEALS: {
      return {
        ...state,
        data: {
          ...state?.data,
          [action?.id]: {
            ...state?.data?.[action.id],
            deals_list: [
              ...state?.data?.[action.id]?.deals_list,
              ...action.deals,
            ],
            meta: action.meta,
          },
        },
      };
    }

    case CLEAR_DEALS: {
      return {
        ...state,
        data: {
          ...state?.data,
          [action?.id]: {
            ...state?.data?.[action.id],
            deals_list: [],
          },
        },
      };
    }

    case SET_PAGINATION:
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...state.data[action.id],
            pagination: action.pagination,
          },
        },
      };

    case SET_DRAGGING:
      return { ...state, meta: { ...state.meta, isDragging: true } };
    case UNSET_DRAGGING:
      return { ...state, meta: { ...state.meta, isDragging: false } };

    case REORDER_DEAL: {
      const deals_list = reorder(
        state.data[action.sourceId].deals_list,
        action.sourceIndex,
        action.destIndex
      );

      return {
        ...state,
        data: {
          ...state.data,
          [action.sourceId]: {
            ...state.data[action.sourceId],
            deals_list,
          },
        },
      };
    }
    case MOVE_DEAL: {
      const newResult = move(
        state.data[action.sourceId].deals_list,
        state.data[action.destId].deals_list,
        action.source,
        action.destination,
        state.data
      );

      const anotherResult = Object.keys(newResult).reduce((obj, key) => {
        const stage = state.data[key];
        const isDestination = key === action.destId;

        return {
          ...obj,
          [key]: {
            ...stage,
            deals_list: newResult[key].map(
              removeStagnant(isDestination ? action.destination.index : null)
            ),
          },
        };
      }, {});

      return {
        ...state,
        data: {
          ...state.data,
          ...anotherResult,
        },
      };
    }

    case REMOVE_DEAL: {
      const { sourceIndex: currentDealIndex, sourceId: stageId } = action;

      // Etapa atual que está sendo manipulada
      const currentStage = state.data[stageId];

      // pega todos os negócios menos o index que estava sendo movido para os excluidos
      const deals_list = currentStage.deals_list.filter(
        (deal, index) => index !== currentDealIndex
      );

      return {
        ...state,
        data: {
          ...state.data,
          [stageId]: {
            ...currentStage,
            deals_list,
          },
        },
      };
    }

    case REORDER_STAGE: {
      const stagesKeys = Object.keys(state.data);

      const stages = reorder(
        stagesKeys,
        action.sourceIndex,
        action.destIndex
      ).reduce((obj, value) => {
        obj[value] = {
          ...obj,
          ...state.data[value],
        };
        return obj;
      }, {});

      return {
        ...state,
        data: stages,
      };
    }
    case REMOVE_STAGE: {
      return { ...state, data: omit(state.data, action.id) };
    }

    case UPDATE_META: {
      const { source, destination } = action;

      return {
        ...state,
        data: {
          ...state.data,
          [source?.id]: createStage(source, state),
          [destination?.id]: createStage(destination, state),
        },
      };
    }

    case SET_META: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...state.data[action.id],
            pagination: action.meta.pagination,
            meta: action.meta,
          },
        },
      };
    }

    case ADD_STAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.stage.id]: {
            ...action.stage,
          },
        },
      };
    }

    case UPDATE_STAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.stage.id]: {
            ...action.stage,
          },
        },
      };
    }

    case RESET_PIPELINE:
      return initialState;

    default:
      return state;
  }
}

// Action Creators
export function requestPipeline() {
  return { type: REQUEST };
}

export function receivePipeline(stages) {
  return { type: RECEIVE, stages: arrayToObject(stages) };
}

export function setDragging() {
  return { type: SET_DRAGGING };
}

export function unsetDragging() {
  return { type: UNSET_DRAGGING };
}

export function addStage(stage) {
  return { type: ADD_STAGE, stage };
}

export function updateStage(stage) {
  return { type: UPDATE_STAGE, stage };
}

export function resetPipeline() {
  return { type: RESET_PIPELINE };
}

export function updateStagesMeta(source, destination) {
  return {
    type: UPDATE_META,
    source,
    destination,
  };
}

/**
 * Move um negócio na mesma etapa/estágio
 * @param sourceId - id da etapa
 * @param sourceIndex - a posicao do item na lista
 * @param destIndex - a posicao que o item tem que estar na lista
 * @return {{type, sourceId: *, sourceIndex: *, destIndex: *}}
 */
export function reorderDeal(sourceId, sourceIndex, destIndex) {
  return {
    type: REORDER_DEAL,
    sourceId,
    sourceIndex,
    destIndex,
  };
}

/**
 * Move um negócio para outro estágio
 * @param sourceId - id da etapa atual
 * @param destId - id da etapa destino
 * @param source
 * @param destination
 * @return {{type, sourceId: *, destId: *, source: *, destination: *}}
 */
export function moveDeal(sourceId, destId, source, destination) {
  return {
    type: MOVE_DEAL,
    sourceId,
    destId,
    source,
    destination,
  };
}

/**
 * Remove um negocio da lista
 * @param sourceId - id da etapa atual
 * @param sourceIndex - indice da etapa
 * @return {{type, sourceId: *}}
 */
export function removeDeal(sourceId, sourceIndex) {
  return { type: REMOVE_DEAL, sourceId, sourceIndex };
}

/**
 * Ordena o stage na lista
 * @param sourceId
 * @param sourceIndex
 * @param destIndex
 * @return {{type, sourceId: *, sourceIndex: *, destIndex: *}}
 */
export function reorderStage(sourceId, sourceIndex, destIndex) {
  return { type: REORDER_STAGE, sourceId, sourceIndex, destIndex };
}

/**
 * Adiciona negócios em uma etapa
 * @param id - id da etapa
 * @param deals - array de negócios
 * @returns {{deals: *, id: *, type: string}}
 */
export function addDeals(id, deals, meta) {
  return { type: ADD_DEALS, id, deals, meta };
}

/**
 * Adiciona negócios em uma etapa
 * @param id - id da etapa
 * @param deals - array de negócios
 * @returns {{deals: *, id: *, type: string}}
 */
export function clearDeals(id) {
  return { type: CLEAR_DEALS, id };
}

export function setPagination(id, pagination) {
  return { type: SET_PAGINATION, id, pagination };
}

export function setMeta(id, meta) {
  return { type: SET_META, id, meta };
}

/**
 * Remove uma etapa da lista
 * @param id
 * @return {{type, id: *}}
 */
export function removeStage(id) {
  return { type: REMOVE_STAGE, id };
}

/**
 * Carrega mais negócios com base o estágio que ele está carregando
 * @param stageId
 * @param params
 * @returns {function(...[*]=)}
 */
export const loadMore =
  (stageId, params = {}) =>
  async (dispatch, getState) => {
    // Estado atual da aplicação
    const state = getState();

    // Paginação do stage
    const { current_page } = getPaginationByStageId(state, stageId);

    // Pega os negocios
    const { data: deals, meta } = await peopleService.getPeopleByStage({
      stageId,
      filter: {
        user_id: params.user_id,
      },
      limit: 100,
      offset: current_page + 1,
    });

    // Seta a paginação
    dispatch(setPagination(stageId, meta.pagination));

    // Adiciona novos negócios na lista
    dispatch(addDeals(stageId, deals, meta));
  };

export const updateMeta =
  (sourceId, destId, funnelId, userId) => async (dispatch, getState) => {
    dispatch(
      updateMetaByStageId(sourceId, {
        user_id: userId,
      })
    );
    dispatch(
      updateMetaByStageId(destId, {
        user_id: userId,
      })
    );
  };

export const updateMetaByStageId =
  (stageId, params = {}) =>
  async (dispatch) => {
    const { meta } = await peopleService.getPeopleByStage({
      stageId,
      filter: {
        user_id: params.user_id,
      },
      limit: params?.limit || 100,
      offset: params?.offset || 1,
    });

    // Seta a paginação da etapa
    dispatch(setMeta(stageId, meta));
  };

/**
 * Busca os negócios de uma etapa específica
 * @param stageId
 * @param params
 * @returns {function(...[*]=)}
 */

export const getDealsByStageId =
  (stageId, params = {}) =>
  async (dispatch) => {
    // Limpa a lista de negócios
    dispatch(clearDeals(stageId));

    const {
      data: deals,
      meta: { pagination },
      meta,
    } = await peopleService.getPeopleByStage({
      stageId,
      filter: {
        user_id: params.user_id,
      },
      limit: params?.limit || 100,
      offset: params?.offset || 1,
    });

    // Seta a paginação da etapa
    dispatch(setPagination(stageId, pagination));

    // Adiciona os negócios na etapa
    return dispatch(addDeals(stageId, deals, meta));
  };

/**
 * Atualiza as etapas
 * @param stages
 * @return {(function(*): Promise<void>)|*}
 */
export const updateStagesByIds = (stages) => async (dispatch) => {
  let promises = [];

  forEach(stages, ({ id }) => {
    promises.push(dispatch(getDealsByStageId(id)));
  });

  // Aguarda finalizar todas as promises
  await Promise.all(promises);
};

// side effects, only as applicable
// e.g. thunks, epics, etc
/**
 * Busca todos os estagios/negócios de um funil específico
 * @param funnel
 * @param params
 * @return {function(*)}
 */
export function getStages(funnel, params, config = {}) {
  return async (dispatch, getState) => {
    try {
      const filter = getFormValues('FilterCrm')(getState());
      let _params = { ...params };

      if (_params?.filter?.onMount) {
        delete _params?.filter?.onMount;
        _params = { ..._params, filter: { ..._params?.filter, ...filter } };
      }

      let funnelId = funnel?.id;
      let userId = funnel?.user_id;

      dispatch(requestPipeline());

      // Pega os filtros do stage
      let _filter = {
        id: params?.filter?.stage_id,
        ...params?.filter,
      };

      // dispatch(resetPipeline());

      const { data: stages } = await dealsService.getStages(funnelId, {
        ...params,
        filter: _filter,
      });

      dispatch(receivePipeline(stages));

      let promises = [];

      forEach(stages, ({ id }) => {
        promises.push(
          dispatch(getDealsByStageId(id, { user_id: userId, ..._params }))
        );
      });

      // Aguarda finalizar todas as promises
      await Promise.all(promises);

      return stages;
    } catch (err) {
      console.log('ERROR: ', err);
      return null;
    }
  };
}

/**
 * Lida com o inicio do arraste
 * @return {function(*)}
 */
export function handleDragStart() {
  return (dispatch) => {
    dispatch(setDragging());
  };
}

/**
 * Lida com o evento do plugin react-beautiful-dnd de dragEnd
 * ele retorna um result que dentro dele tem o source/destination
 * source - de onde vem
 * destination - pra onde vai
 * @param result
 * @param stages - as etapas atuais do componente
 * @return {function(*)}
 */
export function handleDragEnd(result, stages) {
  return async (dispatch, getState) => {
    const { source, destination } = result;

    dispatch(unsetDragging());

    // Não faz nada quando joga fora da lista
    if (!destination) {
      return false;
    }

    const { droppableId: destId, index: destIndex } = destination;

    const { droppableId: sourceId, index: sourceIndex } = source;

    // TODO funcao para marcar negocio como ganho/perdido
    if (destId === 'ganhos' || destId === 'perdidos') {
      return dispatch(removeDeal(sourceId, sourceIndex));
    }

    const currentDeal = stages[sourceId].deals_list.find(
      (deal, i) => i === sourceIndex
    );

    await dealsService.updatePositionDeal(
      sourceId,
      destId,
      currentDeal.id,
      destIndex
    );

    // Quando ele estiver movendo na mesma coluna ele só reordena os itens
    if (sourceId === destId) {
      dispatch(reorderDeal(sourceId, sourceIndex, destIndex));
    } else {
      dispatch(moveDeal(sourceId, destId, source, destination));
    }

    dispatch(updateMeta(sourceId, destId));
  };
}

/**
 * Lida com o arraste da etapa
 * @param result
 * @param funnelId
 */
export const handleDragEndStage = (result, funnelId, stages) => (dispatch) => {
  const { source, destination } = result;

  dispatch(unsetDragging());

  // Não faz nada quando joga fora da lista
  if (!destination) {
    return false;
  }

  const stageId = Object.keys(stages).find(
    (stage, index) => source.index === index
  );

  dispatch(reorderStage(source.droppableId, source.index, destination.index));

  dealsService.updatePositionStage(funnelId, stageId, destination.index);
};

/**
 * Remove uma etapa da lista
 * @param funnelId
 * @param stage
 */
export const handleRemoveStage = (funnelId, stage) => (dispatch) => {
  // verifica se a etapa tem negócios
  const hasDeals = stage.has_deals;

  if (!hasDeals) {
    return dealsService.deleteStage(stage.id).then(() => {
      Alert.success('Etapa excluída com sucesso');
      return dispatch(removeStage(stage.id));
    });
  }

  dispatch(
    openModalDealDelete({
      stage,
      handleFormSubmit: (values) => {
        return dealsService.moveDeals(stage.id, values.stage_id.id).then(() => {
          Alert.success('Etapa excluída com sucesso');
          dispatch(closeModal());
          return dispatch(removeStage(stage.id));
        });
      },
    })
  );
};

export const handleRemoveDeal =
  (stageId, dealId, indexDeal) => async (dispatch) => {
    try {
      await dealsService.removeDeal(dealId);
      dispatch(removeDeal(stageId, indexDeal));
    } catch {
      Alert.success('Erro ao remover um negócio');
    }
  };

/**
 * Adiciona uma etapa no funil
 * @param funnelId
 * @param values
 */
export const handleAddStage = (funnelId, values) => (dispatch) =>
  dealsService.createStage(values).then(({ data: stage }) => {
    dispatch(addStage({ ...stage, deals_list: [] }));
  });

/**
 * Atualiza uma etapa especifica
 * @param funnelId
 * @param stageId
 * @param values
 */
export const handleUpdateStage = (funnelId, stageId, values) => (dispatch) =>
  dealsService
    .updateStage(values)
    .then(({ data: stage }) => dispatch(updateStage(stage)));
