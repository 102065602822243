import React, { useEffect, useMemo } from 'react';
import Tooltip from 'react-tooltip';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import TableAdditionalItems from 'containers/ModalRoot/ModalContractExtra/pages/UserAndEmail/components/TableAdditionalItems';
import Button from 'components/Button';
import { useModalUser } from 'containers/ModalRoot/ModalContractExtra/pages/UserAndEmail/contexts/ModalUserContext';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';
import { ITEM_TYPES } from 'services/financialv2/contracts';
import useFormValue from 'hooks/useFormValue';

// import { Wrapper } from './styles';

function Details() {
  const { handleClose, setPage } = useModalUser();
  const quantity = useFormValue('quantity');
  const { item, currentItems } = useModalContractExtra();

  const content = useMemo(() => {
    switch (item) {
      case ITEM_TYPES.EMAIL:
        return 'Quantas contas de e-mail local você precisa?';
      default:
        return 'Quantos corretores/usuários você precisa?';
    }
  }, [item]);

  const isDisabled = useMemo(() => {
    switch (item) {
      case ITEM_TYPES.USERS:
        return quantity === currentItems?.users_quantity;
      case ITEM_TYPES.EMAIL:
        return quantity === currentItems?.emails_quantity;
      default:
        return false;
    }
  }, [quantity, currentItems, item]);

  const tip = useMemo(() => {
    if (isDisabled) {
      return 'Você deve adicionar ou remover algum usuário';
    }

    return undefined;
  }, [isDisabled]);

  useEffect(() => {
    if (tip) {
      Tooltip.rebuild();
    }
  }, [tip]);

  return (
    <ModalTemplate
      title="Alteração de contrato"
      text="Gerenciamento de recursos contratados"
      handleClose={handleClose}
    >
      <p>{content}</p>
      <TableAdditionalItems />
      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button
          data-tip={tip}
          disabled={isDisabled}
          type="button"
          color="success"
          onClick={() => {
            setPage('Preview');
          }}
        >
          Próxima etapa
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

export default Details;
