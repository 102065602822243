import React from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
  required: false,
  children: null
};

const propTypes = {
  required: PropTypes.bool,
  children: PropTypes.node
};

function FormLabel({ children, required, ...rest }) {
  return (
    children && (
      <label {...rest} className="FormLabel">
        {children}
        {required && <span className="h-color--danger"> *</span>}
      </label>
    )
  );
}

FormLabel.defaultProps = defaultProps;
FormLabel.propTypes = propTypes;

export default FormLabel;
