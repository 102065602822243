import React from 'react';
import { ModalTemplate } from 'components/Modal';
import PropertyDetails from 'containers/ModalRoot/ModalGeneratePost/components/PropertyDetails';
import * as Pages from '../../pages';
import Modal from 'react-modal';
import { useModal } from '../../context';

// import { Wrapper } from './styles';

function ModalRoot({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  property,
}) {
  const { page, pages } = useModal();

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Gerar post"
        titleActions={<PropertyDetails property={property} />}
        handleClose={handleClose}
      >
        {page === pages.POST_GENERATOR ? (
          <Pages.GeneratePost />
        ) : (
          <Pages.PropertyImageSelection handleClose={handleClose} />
        )}
      </ModalTemplate>
    </Modal>
  );
}

export default ModalRoot;
