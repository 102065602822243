import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  titleRenderer: PropTypes.func,
  actionRenderer: PropTypes.func
};

const defaultProps = {
  children: null,
  titleRenderer: null,
  actionRenderer: null
};

const ModalSubtitle = props => {
  const {
    title,
    className,
    children,
    titleRenderer,
    actionRenderer,
    ...rest
  } = props;

  return (
    <div
      {...rest}
      className={classnames(
        'Modal__subtitle h-flex h-flex--center-center',
        className
      )}
    >
      {titleRenderer ? (
        createElement(titleRenderer, props)
      ) : (
        <div className="h-flex__cell h-color--primary-light">{title}</div>
      )}
      <div className="h-flex__cell h-flex__cell--grow" />
      {actionRenderer ? (
        createElement(actionRenderer, props)
      ) : (
        <div className="h-flex__cell">{children}</div>
      )}
    </div>
  );
};

ModalSubtitle.propTypes = propTypes;
ModalSubtitle.defaultProps = defaultProps;

export default ModalSubtitle;
