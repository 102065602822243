import { useCurrentRealEstate } from "hooks/useCurrentRealEstate";
// Service

const useIntegrations = () => {
  const { auths } = useCurrentRealEstate();

  return { hasOrulo: auths.orulo, hasDwv: auths.dwv };
}

export default useIntegrations;
