import React from 'react';
import Button from 'components/Button';
import * as slidesService from 'services/buildings/slides';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { useLoading } from 'hooks/useLoading';
import UploadButton from 'components/UploadButton';

const UploadSlides = ({
  color = 'success',
  colorText,
  onUploadEnd,
  children,
  ...props
}) => {
  const { show, hide } = useLoading();
  const { enterpriseId } = useEnterprise();

  const onDropAccepted = async (files) => {
    show();
    await slidesService.create({ image: files[0], buildingId: enterpriseId });
    hide();
    onUploadEnd();
  };

  return (
    <UploadButton onDropAccepted={onDropAccepted}>
      <Button color={color} colorText={colorText} {...props}>
        {children}
      </Button>
    </UploadButton>
  );
};

export default UploadSlides;
