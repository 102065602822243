import { monthlyReport } from '../services/reportsMonthly';
import { useRequest } from 'hooks/useRequest';
import { useMemo } from 'react';

export function useMonthlyReport() {
  const { data, meta, isFetching, fetchData } = useRequest({
    request: monthlyReport,
    initialFetch: false,
  });

  const chartData = useMemo(() => {
    return data.map((entry) => ({
      ...entry,
      debits_raw: Math.abs(entry.debits_raw),
    }));
  }, [data]);

  return {
    monthly: data,
    monthlyChart: chartData,
    metaMonthly: meta,
    isFetchingMonthly: isFetching,
    fetchMonthly: fetchData,
  };
}
