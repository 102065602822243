import React from 'react';
// import { Wrapper } from './styles';

function SearchValue({ value }) {
  if (!value) return null;

  return <span>{value}</span>;
}

export default SearchValue;
