import React from 'react';
import PropTypes from 'prop-types';
import { MdCancel } from 'react-icons/md';
// Components
import Avatar from 'components/Avatar';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool
};
const defaultProps = {
  data: null,
  isFetching: true
};

const renderProperties = ({ handleClickRemoveSelected }) => property => {
  const {
    id,
    calculated_price,
    reference,
    type,
    neighborhood,
    cover_image
  } = property;

  return (
    <tr key={id}>
      <td>
        <Avatar
          image={cover_image && cover_image.file_url}
          size="small"
          text="Apartamento"
        />
      </td>
      <td className="Table__cell--small">Ref.: {reference}</td>
      <td>{type.title}</td>
      <td>{neighborhood && neighborhood.name}</td>
      <td className="Table__cell--small">
        <span className="h-color--success">{calculated_price}</span>
      </td>
      <td>
        <button
          onClick={() => handleClickRemoveSelected(property)}
          className="h-color--danger h-pointer"
        >
          <MdCancel />
        </button>
      </td>
    </tr>
  );
};

const TableProperties = ({
  properties,
  isFetching,
  handleClickRemoveSelected
}) => (
  <table className="Table Table--dropdown">
    <tbody>
      {properties &&
        properties.map(
          renderProperties({
            handleClickRemoveSelected
          })
        )}
    </tbody>
  </table>
);

TableProperties.propTypes = propTypes;
TableProperties.defaultProps = defaultProps;

export default TableProperties;
