import React, { useMemo, useRef, useCallback } from 'react';
import _ from 'lodash';
import {
  Wrapper,
  Header,
  Content,
  List,
  ItemDate,
  Actions,
  LoadMore,
  Empty,
  Filters,
  Divider,
  CustomLabel
} from './styles';
import Notification from 'components/Notification';
import { getKeyName } from './helpers';
import { useNotifications } from 'hooks/useNotifications';
import emptyImage from './empty.svg';
import Loading from 'components/Loading';

const Notifications = ({ handleToggleDrawer }) => {
  const wrapperRef = useRef(null);

  const {
    notifications,
    loadMore,
    filters,
    hasMore,
    readAll,
    clearAll,
    setFilter,
    filter,
    meta
  } = useNotifications({
    onLoadMore: () => {
      wrapperRef.current.scroll({
        top: wrapperRef?.current?.scrollHeight,
        behavior: 'smooth'
      });
    }
  });

  const notificationsByDate = useMemo(() => {
    try {
      const datesObj = notifications.reduce((acc, item) => {
        let key = getKeyName(item.created_at);

        if (!acc[key]) {
          return {
            ...acc,
            [key]: [item]
          };
        }

        return {
          ...acc,
          [key]: [...acc[key], item]
        };
      }, {});

      return datesObj;
    } catch (e) {
      return [];
    }
  }, [notifications]);

  const handleClickReadAll = useCallback(() => {
    readAll({ filter });
    if (handleToggleDrawer) handleToggleDrawer();
  }, [filter]);

  const handleClickClearAll = useCallback(() => {
    clearAll({
      filter,
      onSuccess: () => {
        if (handleToggleDrawer) handleToggleDrawer();
      }
    });
  }, [filter]);

  const isEmpty = useMemo(() => {
    return _.isEmpty(notificationsByDate);
  }, [notificationsByDate]);

  // Verifica se tem algum filtro selecionado
  const hasSelectedFilter = useMemo(() => {
    return filters.some(item => {
      return item.isSelected;
    });
  }, [filters]);

  return (
    <Wrapper ref={wrapperRef}>
      <Loading isVisible={meta.isFetching} isFullComponent />
      <Header>
        <h1>Notificações</h1>
      </Header>
      {filters.length > 0 && (
        <Filters hasSelected={hasSelectedFilter}>
          <h2>Filtros</h2>
          {filters.map(({ title, tag, value, color, isSelected }) => (
            <CustomLabel
              isSelected={isSelected}
              onClick={() => {
                if (tag === filter) {
                  setFilter(null);
                } else {
                  setFilter(tag);
                }
              }}
              size="small"
              color={color}
            >
              {title} <strong>({value})</strong>
            </CustomLabel>
          ))}
        </Filters>
      )}
      {!isEmpty && (
        <Actions>
          <button onClick={handleClickReadAll}>Marcar todas como lidas</button>
          <Divider />
          <button onClick={handleClickClearAll}>Limpar</button>
        </Actions>
      )}
      <Content>
        {isEmpty ? (
          <Empty>
            <img src={emptyImage} alt="Vazio" />
          </Empty>
        ) : (
          <List>
            {_.map(notificationsByDate, (notifications, key) => {
              return (
                <>
                  <ItemDate key={key}>
                    <span>{key}</span>
                  </ItemDate>
                  {notifications.map(({ id, payload, tag, status, type }) => (
                    <Notification
                      key={id}
                      id={id}
                      payload={payload}
                      tag={tag}
                      status={status}
                      type={type}
                      onClick={handleToggleDrawer}
                    />
                  ))}
                </>
              );
            })}
          </List>
        )}
        {hasMore && <LoadMore onClick={loadMore}>Ver mais</LoadMore>}
      </Content>
    </Wrapper>
  );
};

export default Notifications;
