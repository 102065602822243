import React from 'react';
import { Actions, Header, Inner, ListCount, Title, Wrapper } from './styles';

function Content({ className, title, actions, children }) {
  return (
    <Wrapper className={className}>
      <Header>
        <Title>{title}</Title>
        {actions && <Actions>{actions}</Actions>}
      </Header>
      <Inner>{children}</Inner>
    </Wrapper>
  );
}

export { ListCount };

export default Content;
