import React from 'react';
import Analysis from './components/Analysis';
import VisitsGraph from './components/VisitsGraph';
import ContactsGraph from './components/ContactsGraph';
// import { Wrapper } from './styles';

function Reports({ property, infos }) {
  return (
    <div>
      <Analysis property={infos} />
      <VisitsGraph propertyId={property?.id} />
      <ContactsGraph propertyId={property?.id} />
    </div>
  );
}

export default Reports;
