import api from 'services';

/**
 * Pega as informações da integração
 * @returns {Promise<AxiosResponse<never>>}
 */
export const getOne = () => api.getOne('settings/superlogica');

/**
 * Endpoint para conectar na superlogica
 * @param app_token
 * @param secret
 * @param access_token
 * @return {Promise<AxiosResponse<*>>}
 */
export const connect = ({ app_token, secret, access_token }) =>
  api.create('settings/superlogica', {
    app_token,
    secret,
    access_token,
  });

/**
 * Se disconecta da superlógica
 * @return {Promise<AxiosResponse<never>>}
 */
export const disconnect = () => api.delete('settings/superlogica');

/**
 * Enviar imóvel para superlógica
 * @return {Promise<AxiosResponse<never>>}
 */
export const sendProperty = ({ id }) =>
  api.create(`settings/superlogica/store-property/${id}`);

/**
 * Enviar cliente
 * @return {Promise<AxiosResponse<never>>}
 */
export const sendPerson = ({ id, type }) =>
  api.create(`settings/superlogica/store-person/${id}/${type}`);
