import React from 'react';
import AutoSize from 'react-input-autosize';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';
import PropTypes from 'prop-types';
// Components
import FormGroup from '../FormGroup';
// Constants
import { CONTROL_SHAPE } from 'constants/shapes';

const defaultProps = {
  label: null,
  required: false,
  maxLength: null,
  type: 'text',
  className: null,
  formGroupClassName: null,
  placeholder: 'Digite...',
  autoFocus: false,
  prefix: 'R$',
};

const propTypes = {
  ...CONTROL_SHAPE,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
};

const getComponent = ({ autoGrow, valueFormat }) => {
  if (autoGrow) return AutoSize;
  if (valueFormat) return NumberFormat;
  return 'input';
};

function Input({
  autoGrow,
  valueFormat,
  autoComplete,
  maxLength,
  autoFocus,
  tabIndex,
  className,
  type,
  placeholder,
  disabled,
  prefix,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  refName,
  required,
  formGroupClassName,
  label,
  input,
  meta,
  labelRender,
  inputProps,
  hideError,
  children,
  ...anotherProps
}) {
  const handleChange = (id, { value }) => value;
  const Component = getComponent({ autoGrow, valueFormat });

  let defaultInputProps = {
    autoComplete,
    tabIndex,
    autoFocus,
    type,
    className: classnames('FormControl', className),
    placeholder,
    maxLength,
    disabled,
    onKeyDown,
    onKeyUp,
    onKeyPress,
  };

  if (valueFormat) {
    defaultInputProps = {
      ...defaultInputProps,
      id: 'price',
      thousandSeparator: '.',
      decimalSeparator: ',',
      decimalScale: 2,
      fixedDecimalScale: true,
      allowNegative: false,
      ...inputProps,
      prefix,
      onValueChange: () => handleChange.bind(this, 'price'),
    };
  }

  if (autoGrow) {
    defaultInputProps = {
      ...defaultInputProps,
      injectStyles: false,
      className: 'FormControl--autogrow',
    };
  }

  return (
    <FormGroup
      {...anotherProps}
      hideError={hideError}
      className={formGroupClassName}
      maxLength={maxLength}
      labelText={label}
      isRequired={required}
      isDisabled={disabled}
      input={input}
      meta={meta}
      labelRender={labelRender}
    >
      {children ? (
        <div>
          <Component
            ref={refName}
            id={input.name}
            max={maxLength}
            maxLength={maxLength}
            {...defaultInputProps}
            {...input}
          />
          {children}
        </div>
      ) : (
        <Component
          ref={refName}
          id={input.name}
          max={maxLength}
          maxLength={maxLength}
          {...defaultInputProps}
          {...input}
        />
      )}
    </FormGroup>
  );
}

Input.defaultProps = defaultProps;
Input.propTypes = propTypes;

export default Input;
