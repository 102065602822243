import React from 'react';
import { useSelector } from 'react-redux';
import { getHeaderType } from 'modules/login';
import Header1 from 'components/MainHeader2';
import Header2 from 'components/MainHeader3';

const HEADERS = {
  Header1,
  Header2
};

const Header = () => {
  const headerType = useSelector(getHeaderType);

  if (headerType === null) return null;

  const Header = HEADERS[headerType];

  return <Header />;
};

export default Header;
