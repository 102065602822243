import React from 'react';
import Modal from 'react-modal';
import Form from './Form';
import withProvider from 'HOC/withProvider';
import Config from 'containers/ModalRoot/configs';

// Common Components
import Help from 'containers/ModalRoot/ModalFacebookStore/Help';
import ErrorIntegration from './components/ErrorIntegration';
import { ModalTemplate } from 'components/Modal';

// Services
import * as facebookService from 'services/settings/facebook';
import {
  FacebookStoreProvider,
  useFacebookStore,
} from 'containers/ModalRoot/ModalFacebookStore/FacebookStoreContext';

function ModalFacebookStore({ modalConfig, isOpen, handleClose, modalType }) {
  const {
    facebook,
    pages,
    isFetching,
    isConnected,
    data,
    setIsFetching,
    setIsConnected,
    setHasError,
    hasError,
  } = useFacebookStore();

  const connectFacebook = async (values) => {
    const long_lived_access_token = facebook?.accessToken;
    const facebook_user_id =
      facebook?.userID || values?.facebook?.facebook_user_id;
    const page = pages?.find(({ id }) => values.page_id === id);
    const name = page?.name || values?.facebook?.catalog_page?.name;
    const access_token =
      page?.access_token || values?.facebook?.catalog_page?.access_token;
    const page_id = page?.id;
    const catalog_id = values?.catalog_id;
    const business_id = values?.business_id;

    const payload = {
      long_lived_access_token,
      facebook_user_id,
      name,
      access_token,
      catalog_id,
      business_id,
      page_id,
    };

    setIsFetching(true);

    return facebookService
      .connectCatalog(payload)
      .then(() => {
        setIsFetching(false);
        handleClose();
      })
      .catch(() => {
        setHasError(true);
        setIsFetching(false);
      });
  };

  let config = hasError
    ? Config({
        modalSize: 450,
        modalPosition: 'center',
      })
    : modalConfig;

  const handleSubmit = async (values) => {
    try {
      await connectFacebook({
        ...values,
      });
    } catch (err) {
      setIsFetching(false);
      throw err;
    }
  };

  return (
    <Modal
      {...config}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={isFetching}
        title="Facebook - Integracão com Catálogo (Loja)"
        handleClose={handleClose}
      >
        {hasError ? (
          <ErrorIntegration handleClose={handleClose} />
        ) : isConnected ? (
          <Form
            onSubmit={handleSubmit}
            initialValues={data || {}}
            handleClose={() => {
              setIsConnected(false);
            }}
          />
        ) : (
          <Help handleClose={handleClose} />
        )}
      </ModalTemplate>
    </Modal>
  );
}

export default withProvider(FacebookStoreProvider)(ModalFacebookStore);
