import React from 'react';
import Alert from 'react-s-alert';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
// Modules
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { removeIntegration } from 'modules/google';
import { currentUserSelector } from 'modules/login';
// Components
import ListOptions from 'components/ListOptions';
import Button from 'components/Button';
// Assets
import googleAnalytics from './google-analytics.png';
import { openModalAnalyticsInfo } from 'modules/modal';
import { MdHelp } from 'react-icons/md';
import * as scriptsService from 'services/scripts';
import api from 'services';

class AnalyticsIntegration extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    scripts: {
      data: [],
      isFetching: false,
    },
  };

  componentDidMount() {
    ReactTooltip.rebuild();

    this.fetchScripts();
  }

  fetchScripts = async () => {
    this.setState({
      ...this.state,
      scripts: {
        ...this.state.scripts,
        isFetching: true,
      },
    });
    const { data } = await scriptsService.getList();

    this.setState({
      ...this.state,
      scripts: {
        ...this.state.scripts,
        data,
        isFetching: false,
      },
    });
  };

  /**
   * Verifica se o usuário pode usar a integração
   */
  get canUse() {
    const { user } = this.props;
    return user.permissions.includes('EDIT_INTEGRATION');
  }

  getScriptGoogle = () => {
    return this.state.scripts.data.find(
      (obj) => obj.name === 'Google Analytics GA4'
    );
  };

  disconnect = async () => {
    const script = this.getScriptGoogle();
    await api.delete('settings/sites/scripts', script.id);
    this.fetchScripts();
  };

  get hasIntegration() {
    return !!this.getScriptGoogle();
  }

  handleOpenModal = () => {
    this.props.openModalAnalyticsInfo();
  };

  handleClickDisconnect = async () => {
    const { openConfirmation } = this.props;

    openConfirmation({
      request: () => this.disconnect(),
      title: 'Desconectar Google Analytics?',
      text: 'Gostaria de desativar a integração com Google Analytics?',
      submitButtonText: 'Desconectar',
      onSuccess: () => {
        Alert.success('Analytics Desconectado');
      },
    });
  };

  renderOptions = () => {
    if (!this.canUse)
      return (
        <div>
          {this.hasIntegration ? (
            <span className="h-color--success">Conectado</span>
          ) : (
            <span className="h-color--danger">Desconectado</span>
          )}{' '}
          <MdHelp
            data-tip="Você não tem permissão<br/>para se integrar com o analytics"
            style={{ position: 'relative', display: 'inline-block' }}
          />
        </div>
      );

    if (this.hasIntegration) {
      return (
        <>
          <Button
            tagName="a"
            color="caution"
            href="https://analytics.google.com/"
            target="_blank"
          >
            Abrir Google Analytics
          </Button>
          <Button color="danger" onClick={this.handleClickDisconnect}>
            Desconectar
          </Button>
        </>
      );
    }

    // Redireciona para o formulário de integração com órulo
    return (
      <Button color="success" onClick={this.handleOpenModal}>
        Conectar
      </Button>
    );
  };

  render() {
    const { isFetching } = this.state;

    return (
      <ListOptions.item
        isLoading={isFetching}
        image={googleAnalytics}
        title="Google Analytics"
        text="Tenha informações precisas sobre os acessos ao seu site com o Google Analytics."
        renderOptions={this.renderOptions}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: currentUserSelector(state),
});

const mapDispatchToProps = {
  openConfirmation,
  removeIntegration,
  openModalAnalyticsInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsIntegration);
