import React, { memo, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
// Shapes
import { CONTROL_SHAPE } from 'constants/shapes';
// Components
import FormGroup from '../FormGroup';

const defaultProps = {
  label: null,
  className: null,
  required: false,
  placeholder: 'Selecione',
  children: null,
  options: [],
  valueKey: 'value',
  labelKey: 'label',
  autoWidth: false,
  buttonTemplate: false,
  canRemoveSelf: true,
  responsive: true,
};

const propTypes = {
  ...CONTROL_SHAPE,
  children: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
};

function Select({
  label,
  className,
  required,
  input,
  placeholder,
  children,
  formGroupClassName,
  options,
  disabled,
  valueKey,
  labelKey,
  style,
  meta,
  autoWidth,
  buttonTemplate,
  canRemoveSelf,
  onClick,
  responsive,
  defaultValue,
  ...anotherProps
}) {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!!input.value === false && defaultValue !== undefined && !initialized) {
      input.onChange(defaultValue);
      setInitialized(true);
    }
  }, [input, defaultValue, initialized]);

  const handleClickChange = (value) => () => {
    if (value === input.value && canRemoveSelf) {
      input.onChange(null);
    } else {
      input.onChange(value);
    }

    if (onClick) onClick(value || null);
  };

  let stylesControl = {
    ...style,
  };

  let currentLabel;

  // Verifica se tem autoWidth
  // Se tiver tem que deixar o control absolute
  if (autoWidth) {
    stylesControl = {
      ...stylesControl,
      position: 'absolute',
    };
    currentLabel = options.find(
      (option) => option[`${valueKey}`] === input.value
    ) || { [`${labelKey}`]: placeholder };
  }

  const optionsLength = options.length;

  return (
    <FormGroup
      {...anotherProps}
      labelText={label}
      className={formGroupClassName}
      isRequired={required}
      isDisabled={disabled}
      input={input}
      meta={meta}
    >
      {autoWidth && (
        <span
          style={{
            padding: '0 20px 0 12px',
            display: 'block',
            height: 0,
            whiteSpace: 'nowrap',
            visibility: 'hidden',
          }}
        >
          {currentLabel[labelKey]}
        </span>
      )}
      {buttonTemplate ? (
        <div className="h-flex">
          <div
            className={classnames('ButtonGroup', {
              'ButtonGroup--disabled': disabled,
              'ButtonGroup--full': responsive,
              [className]: className,
            })}
            style={style}
          >
            {optionsLength > 0 &&
              options.map((option) => {
                const value = option[`${valueKey}`];
                const label = option[`${labelKey}`];
                const _disabled = option.disabled;
                const color = option._color || 'success';

                return (
                  <button
                    type="button"
                    key={`option-${value}`}
                    onClick={handleClickChange(value)}
                    disabled={_disabled || disabled}
                    className={classnames('ButtonGroup__action', {
                      'is-disabled': _disabled || disabled,
                      'is-active': value === input.value,
                      [`h-bg--${color}`]: value === input.value,
                    })}
                  >
                    {label}
                  </button>
                );
              })}
          </div>
          {children}
        </div>
      ) : (
        <select
          style={stylesControl}
          className={classnames(
            'FormControl',
            'FormControl--select',
            className
          )}
          disabled={disabled}
          {...input}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options.length > 0
            ? options.map((option) => {
                const value = option[`${valueKey}`];
                const label = option[`${labelKey}`];

                return (
                  <option key={`option-${value}`} value={value}>
                    {label}
                  </option>
                );
              })
            : children}
        </select>
      )}
    </FormGroup>
  );
}

Select.defaultProps = defaultProps;
Select.propTypes = propTypes;

export default memo(Select);
