import { Screens } from './screens';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'modules/login';

/**
 * Componente que lida com a renderização do Alerta de CRECI
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function CreciAlert() {
  // Pega o usuário que está logado atualmente
  const currentUser = useSelector(currentUserSelector);

  // Reforça que o status tem que ser um número
  const status = parseInt(currentUser?.creci_status, 10);

  // Verifica se a imobiliária precisa verificar creci
  // se estiver false é por que algum usuário já fez essa verificação
  // A resumo: Se algum usuário da imob fazer creci e for aprovado essa flag vai vir false
  if (currentUser.real_estate?.creci_to_verify === false) return null;

  // Se o creci_status === 2 quer dizer que o nosso suporte está verificando
  if (status === 2) return <Screens.CheckingCreci />;

  // Verifica se foi bloqueado pelo sistema ou pelo suporte ou sistema
  // Se sim mostrar a tela de falha na verificação
  if (status === 5 || status === 4) return <Screens.Fail />;

  // Página de inicio da validação mesmo
  return <Screens.StartValidation />;
}
