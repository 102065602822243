import React from 'react';
import PropTypes from 'prop-types';
import Nestable from 'react-nestable';
// Styles
import { Wrapper } from './styles';

function NestTable({ items, onChange, renderItem, ...props }) {
  return (
    <Wrapper>
      <Nestable
        {...props}
        items={items}
        onChange={onChange}
        renderItem={renderItem}
      />
    </Wrapper>
  );
}

NestTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  renderItem: PropTypes.func
};

NestTable.defaultProps = {
  items: null,
  onChange: null,
  renderItem: null
};

export default NestTable;
