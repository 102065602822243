import React from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Row,
  ColLeft,
  ColRight,
  Ball,
  Content,
  Text,
  File
} from './styles';
import {
  MdCloudDownload as Download,
  MdSearch as Search,
  MdCheckCircle as Check
} from 'react-icons/md';

// Components
import { FileInput } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

// Services
import { createImport } from 'services/imports/people';

// Hooks
import useFormValue from 'hooks/useFormValue';

// Assets
import people from './people.svg';
import clientesXLSX from './planilha-modelo-pessoas.xlsx';
import FieldSelectUser from 'containers/FieldSelectUser';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function FormPeoplePreImport({ handleSubmit, handleClose }) {
  const file = useFormValue('document');

  return (
    <Wrapper onSubmit={handleSubmit}>
      <img src={people} alt="" />

      <Row>
        <ColLeft>
          <Text>
            <Ball color="#3E587C">1</Ball> Baixe o nosso arquivo modelo e
            preencha as informações dos clientes que você deseja importar.
          </Text>
          <Content>
            <Button
              tagName="a"
              href={clientesXLSX}
              download="planilha-modelo-pessoas.xlsx"
              color="primary"
            >
              <Download /> Baixar Arquivo modelo
            </Button>
            <Text>
              Obs: Não altere os títulos das colunas, e salve o arquivo na
              extensão <strong>.csv</strong> para funcionar.
            </Text>
          </Content>
        </ColLeft>
        <ColRight>
          <Text>
            <Ball color="#FF970c">2</Ball> Após preencher, envie o arquivo com
            as informações e selecione o corretor que será responsável por esses
            clientes.
          </Text>
          <Content>
            <FieldSelectUser name="user_id" label="Corretor responsável" />
            <Field
              accept=".csv"
              parse={value => {
                if (!value) return;
                return value[0];
              }}
              name="document"
              component={FileInput}
            >
              <Button color="secondary">
                <Search /> Procurar arquivo
              </Button>
            </Field>
            {file && (
              <File>
                <Check />
                {file?.name}
              </File>
            )}
          </Content>
        </ColRight>
      </Row>

      <ModalFooter>
        <Button
          type="button"
          onClick={handleClose}
          color="white"
          colorText="secondary"
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Enviar
        </Button>
      </ModalFooter>
    </Wrapper>
  );
}

FormPeoplePreImport.defaultProps = defaultProps;
FormPeoplePreImport.propTypes = propTypes;

export default reduxForm({
  form: 'FormPeoplePreImport',
  onSubmit: (values, dispatch, { onSubmitStart }) => {
    if (onSubmitStart) onSubmitStart();

    return createImport(values);
  },
  enableReinitialize: true
})(FormPeoplePreImport);
