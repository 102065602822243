import Button from 'components/Button';
import { PortalsContext } from 'pages/Portals/context';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import List from '../List';
import logoGuru from 'pages/Portals/components/ListPortals/logo-guru.png';
import { CellSmall } from 'pages/Portals/components/ListPortals/components/List/styles';
import { Right } from 'pages/Portals/components/ListPortals/styles';

const ListDisconnecteds = () => {
  const history = useHistory();
  const { guru, portals, connect } = useContext(PortalsContext);

  const hasConnecteds = useMemo(() => {
    return portals.connected.length > 0;
  }, [portals.connected]);

  const handleClickFacebook = () => {
    history.push('/integrations');
  };

  return (
    <List
      title={hasConnecteds ? 'Desconectados' : 'Desconectados'}
      data={portals.disconnected}
      renderActions={(portal) => {
        if (portal.name === 'Facebook') {
          return (
            <Button color="success" onClick={handleClickFacebook}>
              Conectar
            </Button>
          );
        }

        return (
          <Button color="success" onClick={connect(portal)}>
            Conectar
          </Button>
        );
      }}
    >
      {!guru.isConnected && (
        <tr>
          <td>
            <img src={logoGuru} alt="" />
          </td>
          <CellSmall>
            <Right>
              <Button color="success" onClick={guru.connect}>
                Conectar
              </Button>
            </Right>
          </CellSmall>
        </tr>
      )}
    </List>
  );
};

export default ListDisconnecteds;
