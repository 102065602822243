import React from 'react';
import { Box, Table } from 'features/Financial/features/Reports/components/Box';
import { THead } from './components/Thead';
import { Item } from './components/Item';

export function BoxData({ data }) {
  const hasCredits = data?.credits?.length > 0;
  const hasDebits = data?.debits?.length > 0;

  if (!hasDebits && !hasCredits) return null;

  return (
    <Box.Wrapper>
      <Box.Inner>
        {hasCredits ? (
          <>
            <Box.Title>Receitas</Box.Title>
            <Box.Content>
              <Table.Wrapper>
                <THead />
                <tbody>
                  {data?.credits?.map((item) => (
                    <Item data={item} />
                  ))}
                </tbody>
              </Table.Wrapper>
            </Box.Content>
          </>
        ) : null}
        {hasDebits ? (
          <>
            <Box.Title>Despesas</Box.Title>
            <Box.Content>
              <Table.Wrapper>
                <THead />
                <tbody>
                  {data?.debits?.map((item) => (
                    <Item data={item} />
                  ))}
                </tbody>
              </Table.Wrapper>
            </Box.Content>
          </>
        ) : null}
      </Box.Inner>
    </Box.Wrapper>
  );
}
