import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 10px;
`;

export const Ball = styled.div`
  position: relative;
  width: 21px;
  height: 21px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;

  font-size: 12px;
  font-weight: bold;
  background: #d9d9d9;
  z-index: 5;
  color: #fff;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease-in-out;

  &.active {
    background: #4f46e5;
    transition-delay: 0.3s;
  }
`;

export const Bar = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: #d1d5db;

  :after {
    content: '';
    width: ${(p) => p.size}%;
    position: absolute;
    top: 0;
    height: 1px;
    background-color: #4f46e5;
    transition: width 0.3s ease-in-out;
    will-change: width;
  }
`;
