import React from 'react';
import Button from 'components/Button';
import { reduxForm, Field } from 'redux-form';
import * as units from 'services/buildings/units';
import { Input } from 'components/Form';

const Form = ({ handleSubmit }) => {
  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();

        handleSubmit(e);
      }}
    >
      <Field label="Referência" name="reference" component={Input} />
      <Button type="submit" color="success" block>
        Adicionar unidade
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'ImportCondo',
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    return units.addUnity({
      buildingId: props?.enterpriseId,
      reference: values?.reference
    });
  }
})(Form);
