import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { ModalFooter } from '../../../../../components/Modal';
import { FormError, Input } from '../../../../../components/Form';
import Button from '../../../../../components/Button';

import validate from './validate';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

class UserFormPassword extends React.Component {
  render() {
    const { handleClose, error, submitting, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Field
            ref={input => {
              this.password = input;
            }}
            type="password"
            xs={6}
            required
            label="Senha"
            name="password"
            component={Input}
          />
          <Field
            type="password"
            xs={6}
            required
            label="Confirmar senha"
            name="password_confirm"
            component={Input}
          />
        </Row>
        <FormError>{error}</FormError>
        <ModalFooter>
          <Button color="white" colorText="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar Senha
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

UserFormPassword.defaultProps = defaultProps;
UserFormPassword.propTypes = propTypes;

export default connect((state, ownProps) => ({
  initialValues: ownProps.initialValues
}))(
  reduxForm({
    form: 'UserFormPassword',
    validate,
    enableReinitialize: true
  })(UserFormPassword)
);
