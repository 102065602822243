import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, getFormValues } from 'redux-form';
// HOC
import { withPagination } from 'HOC/withPagination';
// Components
import Pagination from 'components/Pagination';
import FormWrapper from 'components/FormWrapper';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import TablePropertiesRented from './components/TablePropertiesRented';
import { CheckboxGroup, FieldBool, Input } from 'components/Form';
import {
  FilterContent,
  FilterTitle,
  FilterWrapper,
} from 'components/FilterAside';
import * as Fields from 'components/PropertyFilters';
import * as Filters from 'components/PropertyFilters';
// Modules
import {
  getPropertiesWithPagination,
  propertiesIsFetchingSelector,
  propertiesSelector,
  resetProperties,
} from 'modules/properties';
import { openModalPerson, openModalProperty } from 'modules/modal';
import { hideLoading, showLoading } from 'modules/loading';
import FieldCondominium from 'containers/FieldCondominium';
import FieldPerson from 'containers/FieldPerson';
import FieldSelectUser from 'containers/FieldSelectUser';
import { isReferenceNotChanged } from 'lib/property-helpers';
import { Actions as FilterActions } from 'pages/Properties/Filter/module';
import _ from 'lodash';
import FormSortProperties from 'components/FormSortProperties';

const defaultParams = {
  limit: 1,
  offset: 1,
};

class PropertiesRented extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  componentDidMount() {
    this.fetchProperties();
  }

  componentWillUnmount() {
    this.props.resetProperties();
  }

  fetchProperties = async (values = defaultParams) => {
    // Mostra o loading
    this.props.showLoading();

    let params = {
      offset: values.offset || 1,
      limit: 50,
    };

    let _values;

    if (values.filter) {
      _values = values.filter;
    } else {
      _values = values;
    }

    delete values.offset;
    delete values.limit;

    let sort =
      localStorage.getItem('@tecimob/filter-properties') || '-updated_at,id';

    if (_values.sort) {
      sort = _values.sort;
      delete _values.sort;
    }

    const res = await this.props.getPropertiesWithPagination(
      {
        include: 'last_earned_deal',
        filter: {
          ..._values,

          // Adiciona o status 3 de alugado para todos os requests
          status: 3,
        },
        limit: 50,
        sort,
        offset: values.offset || defaultParams.offset,
        ...params,
      },
      'properties-rented'
    );

    // Seta a paginação
    this.props.setPagination(res?.meta);

    this.props.hideLoading();

    return res;
  };

  onChangePage = ({ selected }) => {
    const { filter } = this.props;
    this.fetchProperties({
      filter,
      offset: selected + 1,
    });
  };

  onSubmit = async (values) => {
    return this.fetchProperties(values);
  };

  handleClickPerson = (person) => {
    this.props.openModalPerson(person);
  };

  handleClickProperty = (property) => {
    this.props.openModalProperty({
      property,
      afterClose: () => {
        this.fetchProperties();
      },
    });
  };

  get currentUser() {
    try {
      return localStorage.getItem('current-user');
    } catch {
      return {};
    }
  }

  get initialValues() {
    try {
      const { country_id: by_country_id, state_id: by_state_id } = _.get(
        this.currentUser,
        'real_estate.relations'
      );

      return {
        by_country_id,
        by_state_id,
        condominium_id: null,
      };
    } catch {
      return {};
    }
  }

  render() {
    const { pagination } = this.props;

    return (
      <Wrapper full>
        <FormWrapper
          as={FilterWrapper}
          form="PropertiesRented"
          onSubmit={this.onSubmit}
          initialValues={this.initialValues}
          onChange={(values, dispatch, ownProps, prevValues) => {
            if (isReferenceNotChanged(values, prevValues)) {
              this.props.hideReference();
            }
          }}
        >
          {() => (
            <>
              <FilterTitle>Filtrar</FilterTitle>
              <FilterContent>
                <Fields.Reference />
                <Field
                  label="Cliente"
                  name="by_earned_deal.by_people_name"
                  component={Input}
                />
                <FieldPerson
                  creatable={false}
                  label="Proprietários"
                  params={{
                    filter: { has_properties: 'rented' },
                  }}
                />
                <Fields.Price />
                <Fields.Entry
                  nameMin="by_earned_deal.entry_at_lower_equals"
                  nameMax="by_earned_deal.entry_at_greater_equals"
                />
                <Filters.NewLocation />
                <Fields.StreetAddress />
                <Fields.Revision
                  nameMin="by_earned_deal.revision_at_lower_equals"
                  nameMax="by_earned_deal.revision_at_greater_equals"
                />
                <Fields.Departure
                  nameMin="by_earned_deal.departure_at_lower_equals"
                  nameMax="by_earned_deal.departure_at_greater_equals"
                />
                <FieldBool
                  label="Com Garantia"
                  name="by_earned_deal.has_guarantee"
                />
                <Fields.Database hasGuru={false} />
                <FieldCondominium hideEmpty filter={{ status: 3 }} />
                <Fields.EarnedDeal />
                <FieldSelectUser
                  parse={null}
                  render={({ options, ...params }) => (
                    <div>
                      <label className="FormLabel">Corretor Responsável</label>
                      <CheckboxGroup {...params} limit={4} options={options} />
                    </div>
                  )}
                />
                <Fields.Furniture />
              </FilterContent>
            </>
          )}
        </FormWrapper>

        <Wrapper.inner>
          <Wrapper.container style={{ maxWidth: '985px' }}>
            <MainTitle title="Imóveis Alugados" text="">
              <FormSortProperties mainForm="PropertiesRented" />
            </MainTitle>
            {!this.props.isFetching && (
              <TablePropertiesRented
                data={this.props.properties}
                fetchProperties={this.onChangePage}
                handleClickProperty={this.handleClickProperty}
                handleClickPerson={this.handleClickPerson}
              />
            )}
            {this.props.properties || this.props.properties.length > 0 ? (
              <Pagination
                forcePage={pagination.current_page - 1}
                pageCount={pagination.total_pages}
                pageRangeDisplayed={10}
                marginPagesDisplayed={2}
                onPageChange={this.onChangePage}
              />
            ) : null}
          </Wrapper.container>
        </Wrapper.inner>
      </Wrapper>
    );
  }
}

const selector = formValueSelector('PropertiesRented');

const mapStateToProps = (state) => ({
  values: selector(state, 'filter'),
  properties: propertiesSelector(state),
  filter: getFormValues('PropertiesRented')(state),
  isFetching: propertiesIsFetchingSelector(state),
});

const mapDispatchToProps = {
  hideReference: FilterActions.hideReference,
  propertiesIsFetchingSelector,
  getPropertiesWithPagination,
  showLoading,
  hideLoading,
  resetProperties,
  openModalPerson,
  openModalProperty,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPagination('properties-rented', { limit: 50 })
)(PropertiesRented);
