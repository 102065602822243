import api from 'services/index';

export const monthlyReport = ({
  start_date,
  end_date,
  situation,
  posting,
  category_id,
  account_id,
  people_id,
}) => {
  return api.get('financial/reports/monthly', {
    start_date,
    end_date,
    situation,
    posting,
    category_id,
    account_id,
    people_id,
  });
};
