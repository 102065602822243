import { API_STORAGE_URL } from 'constants/config';
import qs from 'qs';

/**
 * Baixar qualquer arquivo vindo do storage
 * @param file_url
 * @param name
 */
export const downloadFile = (file_url, name = 'arquivo') => {
  const query = qs.stringify({
    file_url,
    name,
  });

  return `${API_STORAGE_URL}download-file?${query}`;
};
