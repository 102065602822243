import styled from 'styled-components';

export const Wrapper = styled.td`
  padding: 15px;

  .h-text-overflow {
    vertical-align: middle;
  }

  div:first-child {
    margin-bottom: 5px;
  }
`;

export const ExpiredLabel = styled.span`
  color: ${(p) => p.theme.colors.danger};
  font-size: 12px;
`;
