import React from 'react';
import PropTypes from 'prop-types';
// Components
import Popover from 'components/Popover';
import Button from 'components/Button';
// Services
import * as propertyService from 'services/properties';
// Forms
import FormPropertyRenovation from 'components/FormPropertyRenovation';
import { useRequest } from 'hooks/useRequest';

const propTypes = {
  data: PropTypes.shape({
    created_at: PropTypes.string,
    renew_date: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  children: 'Renovar',
};

const PopoverRevision = ({
  propertyId,
  children,
  onSubmit,
  onSubmitSuccess,
  submitFunc,
  handleDisableRenovation,
}) => {
  const { data } = useRequest({
    request: () => propertyService.getOne(propertyId),
    initialState: {},
  });

  return (
    <Popover
      width={290}
      data={data}
      initialValues={data}
      onSubmit={onSubmit}
      submitFunc={submitFunc}
      onSubmitSuccess={onSubmitSuccess}
      handleDisableRenovation={handleDisableRenovation}
      component={FormPropertyRenovation}
    >
      <Button
        size="small"
        color="white"
        colorText="secondary"
        className="h-hover__show"
      >
        {children}
      </Button>
    </Popover>
  );
};

PopoverRevision.propTypes = propTypes;
PopoverRevision.defaultProps = defaultProps;

export default PopoverRevision;
