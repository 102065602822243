import React from 'react';
import Wrapper from 'components/Wrapper';
import NoContent from 'components/NoContent';
import Button from 'components/Button';
import image from './image.png';

const Reception = () => {
  return (
    <Wrapper>
      <NoContent
        full
        reversed
        image={image}
        title="Hotsite"
        text={
          <>
            Crie páginas exclusivas para empreendimentos
            <br />
            ou imóveis individuais.
          </>
        }
      >
        <Button color="success" to="/enterprise/store" size="large">
          Criar
        </Button>
        {/*<Button color="white" colorText="secondary" size="large">*/}
        {/*  Ver página de exemplo*/}
        {/*</Button>*/}
      </NoContent>
    </Wrapper>
  );
};

export default Reception;
