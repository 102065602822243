import React from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from 'hooks/useRequest';
import api from 'services/index';
import * as smtpService from 'services/settings/smtp';
import { openModalSMTPConfig } from 'modules/modal';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import ListOptions from 'components/ListOptions';
import smtpIcon from './smtp.svg';
import Button from 'components/Button';

// import { Wrapper } from './styles';

function SmtpIntegration() {
  const dispatch = useDispatch();

  const {
    data: { count_sent_30_days },
  } = useRequest({
    request: () => api.getOne('properties/matcheds/count-sents'),
  });

  const { data: smtp, fetchData } = useRequest({
    request: smtpService.show,
    initialState: {},
  });

  const handleClickConfigSMTP = () => {
    dispatch(
      openModalSMTPConfig({
        onSubmitSuccess: () => {
          fetchData();
        },
      })
    );
  };

  const handleRemoveSMTP = async () => {
    dispatch(
      openConfirmation({
        title: 'Remover Configuração SMTP',
        text: 'Tem certeza que deseja remover a configuração do SMTP?',
        submitButtonText: 'Remover configuração',
        cancelButtonText: 'Cancelar',
        request: smtpService.destroy,
        onSuccess: () => {
          fetchData();
        },
      })
    );
  };

  const renderOptions = () => {
    if (smtp.id)
      return (
        <>
          <Button color="white" onClick={handleClickConfigSMTP}>
            Editar
          </Button>
          <Button color="danger" onClick={handleRemoveSMTP}>
            Desconectar
          </Button>
        </>
      );

    return (
      <Button color="success" onClick={handleClickConfigSMTP}>
        Conectar
      </Button>
    );
  };

  return (
    <ListOptions.item
      image={smtpIcon}
      imageStyle={{
        width: 42,
      }}
      title="SMTP Externo"
      text={
        <>
          Configure um servidor SMTP externo para envio de e-mail marketing.{' '}
          <a
            href="https://scribehow.com/page/SMTP_Externo__F4tRbsziTc2-x67xyfDqZQ"
            target="_blank"
            className="h-link"
          >
            Saiba mais
          </a>
        </>
      }
      renderOptions={renderOptions}
    />
  );
}

export default SmtpIntegration;
