import React from 'react';
import ReactModal from 'react-modal';
import { CLASSNAME, OVERLAY_CLASSNAME } from './constants';
import { ModalTemplate } from 'components/Modal';
import { MdCancel, MdCheck } from 'react-icons/md';
import { Table, WrapperTables } from './styles';
import { colors } from 'constants/styles';
import chunk from 'lodash/chunk';

function Modal({
  isOpen,
  className = CLASSNAME,
  overlayClassName = OVERLAY_CLASSNAME,
  handleClose,
  data,
}) {
  const dividedArray = chunk(data, Math.ceil(data?.length / 2));

  const handleClick = () => {};

  return (
    <ReactModal
      isOpen={isOpen}
      className={className}
      overlayClassName={overlayClassName}
    >
      <ModalTemplate title="Avaliação do anuncio" handleClose={handleClose}>
        <p>Veja o que melhorar para aumentar a qualidade de seus anúncios</p>
        <WrapperTables>
          {dividedArray?.map((_data) => (
            <Table>
              <tbody>
                {_data?.map(({ title, value }, i) => (
                  <tr key={i + 'rating'}>
                    <td>{title}</td>
                    <td width={1}>
                      {value ? (
                        <MdCheck color={colors.success} />
                      ) : (
                        <MdCancel color={colors.danger} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ))}
        </WrapperTables>
      </ModalTemplate>
    </ReactModal>
  );
}

export default Modal;
