import React from 'react';
import { reduxForm } from 'redux-form';
import UserAndEmail from '../../pages/UserAndEmail';

function Content({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <UserAndEmail />
    </form>
  );
}

export default reduxForm({
  form: 'ModalContractRemove',
  forceUnregisterOnUnmount: true,
})(Content);
