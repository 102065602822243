import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, initialize, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import Container from 'components/Container';
import FixedBar from 'components/FixedBar';
import { FieldBool, Textarea } from 'components/Form';
import ListOptions from 'components/ListOptions';
import MainTitle from 'components/MainTitle';
import TableSchedule from 'components/TableSchedule';
import { openModalWhatsappUserStore } from 'modules/modal';
import Reception from './components/Reception';
import Nodata from 'components/Nodata';
import Nocontent from './components/Nocontent';
import Preview from './components/Preview';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function FormWhatsapp({
  change,
  isActive,
  whatsappTitle,
  whatsappContacts,
  contacts,
  handleSubmit,
  submitting,
  fetchData,
  pristine,
  array: { push },
}) {
  const dispatch = useDispatch();

  const handleAddUser = () => {
    dispatch(
      openModalWhatsappUserStore({
        onSubmitSuccess: fetchData,
      })
    );
  };

  const renderWhatsappContacts = () => {
    if (!isActive)
      return (
        <ListOptions>
          <Reception />
        </ListOptions>
      );

    if (!contacts || contacts.length === 0)
      return (
        <Nodata
          title="Nenhum usuário encontrado"
          text={
            <>
              Adicione o primeiro usuário do Chat do
              <br /> seu site clicando no botão abaixo.
            </>
          }
        >
          <Button onClick={handleAddUser} className="h-margin-top--15">
            Adicionar usuário
          </Button>
        </Nodata>
      );

    return (
      <ListOptions>
        <ListOptions.item
          title="Usuários do Chat"
          text="Adicione os usuários que estarão disponíveis no seu Chat"
          renderOptions={() => (
            <Button onClick={handleAddUser}>Adicionar usuário</Button>
          )}
        >
          <div style={{ marginTop: '10px' }}>
            <TableSchedule fetchData={fetchData} />
          </div>
        </ListOptions.item>
      </ListOptions>
    );
  };

  if (!isActive) {
    return (
      <Nocontent
        onClick={() => {
          change('whatsapp_is_active', true);
        }}
      />
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <MainTitle
          title="WhatsApp"
          text="Defina como vai funcionar o chat do seu site."
        >
          <Button
            color="danger"
            onClick={() => {
              change('whatsapp_is_active', false);
              setTimeout(() => {
                handleSubmit();
              }, 200);
            }}
          >
            Desativar plugin
          </Button>
        </MainTitle>
        <Row>
          <Col xs={6}>
            <Container padding style={{ marginBottom: '20px' }}>
              <ListOptions>
                <ListOptions.item
                  title="Frase principal"
                  text="Adicione uma frase que será utilizada como frase principal no chat."
                >
                  <Row style={{ marginTop: '10px' }}>
                    <Field
                      type="simple"
                      xs={12}
                      name="whatsapp_title"
                      component={Textarea}
                    />
                  </Row>
                </ListOptions.item>
              </ListOptions>
            </Container>
          </Col>
          <Col xs={6}>
            <Preview value={whatsappTitle} contacts={whatsappContacts} />
          </Col>
        </Row>
        <Container padding style={{ marginBottom: '20px' }}>
          {renderWhatsappContacts()}
        </Container>

        <Container padding>
          <ListOptions>
            <ListOptions.item
              title="Mostrar o ícone flutuante do WhatsApp na página de detalhes do imóvel?"
              text="Manter essa opção desativada, induz o cliente utilizar o botão de WhatsApp no formulário de contato (recomendado)."
              renderOptions={() => (
                <FieldBool
                  name="whatsapp_in_details"
                  onChange={(value) => {
                    change('whatsapp_details_form', value);
                  }}
                />
              )}
            />
            <ListOptions.item
              title="Ao clicar no ícone flutuante do WhatsApp na página de detalhes do imóvel, forçar uso do formulário? (Recomendado)"
              text="Recomendamos que sim, para que ocorra o registro do cliente, imóvel enviado e perfil de busca."
              renderOptions={() => (
                <FieldBool
                  name="whatsapp_details_form"
                  onChange={(value) => {
                    if (value) {
                      change('whatsapp_in_details', true);
                    }
                  }}
                />
              )}
            />
          </ListOptions>
        </Container>

        <FixedBar style={{ left: 256 }}>
          <FixedBar.spacer />
          <Button
            type="submit"
            color="success"
            disabled={submitting || pristine}
          >
            Salvar
          </Button>
        </FixedBar>
      </form>
    </>
  );
}

FormWhatsapp.defaultProps = defaultProps;
FormWhatsapp.propTypes = propTypes;

const selector = formValueSelector('FormWhatsapp');

const mapStateToProps = (state, ownProps) => ({
  isActive: selector(state, 'whatsapp_is_active'),
  contacts: selector(state, 'whatsapp_contacts'),
  whatsappTitle: selector(state, 'whatsapp_title'),
  whatsappContacts: selector(state, 'whatsapp_contacts'),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'FormWhatsapp',
    enableReinitialize: true,
    onSubmitSuccess: (res, dispatch) => {
      // Reinicializa o formulário com os valores
      dispatch(initialize('FormWhatsapp', res.data, false));
    },
  })
)(FormWhatsapp);
