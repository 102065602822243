import React, { useCallback } from 'react';
import DealAudits from 'containers/DealAudits';
import Button from 'components/Button';
import Popover from 'components/Popover';
// Modules
import {
  openModalDealLoss,
  openModalPropertySell,
  openModalPropertyRent,
} from 'modules/modal';
// Services
// Styles
import { Actions, Wrapper } from './styles';
import { useDispatch } from 'react-redux';

export default function DealInteractions({
  property,
  person,
  dealId,
  stageId,
  children,
  onSuccess,
  className,
}) {
  const dispatch = useDispatch();

  const handleClickCloseDeal = useCallback(
    (close) => async () => {
      property.transaction === 1
        ? dispatch(
            openModalPropertySell({
              property,
              personId: person.id,
              onSubmitSuccess: () => {
                if (onSuccess) onSuccess();
              },
            })
          )
        : dispatch(
            openModalPropertyRent({
              property,
              person,
              onSubmitSuccess: () => {
                if (onSuccess) onSuccess();
              },
            })
          );

      // Fecha a popover
      close();
    },
    [onSuccess]
  );

  const handleClickLoss = useCallback(
    (close) => () => {
      dispatch(
        openModalDealLoss(dealId, {
          onSubmitSuccess: onSuccess,
        })
      );

      // Fecha a popover
      close();
    },
    [dispatch]
  );

  return (
    <Popover
      width={325}
      component={({ handleClose }) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Wrapper>
            <DealAudits dealId={dealId} />
          </Wrapper>
          <Actions>
            <Button
              type="button"
              color="success"
              onClick={handleClickCloseDeal(handleClose)}
            >
              Fechei negócio
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={handleClickLoss(handleClose)}
            >
              Negócio perdido
            </Button>
          </Actions>
        </div>
      )}
    >
      {children}
    </Popover>
  );
}
