import React from 'react';
import Modal from 'react-modal';
import Form from './components/Form';

// Common Components
import { ModalTemplate } from 'components/Modal';
import { openModalVisit } from 'modules/modal';
import { useDispatch } from 'react-redux';

function ModalVisit({
  onAfterClose,
  property,
  properties,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  initialValues,
}) {
  const dispatch = useDispatch();

  async function onCreatePerson(person) {
    dispatch(
      openModalVisit({
        property,
        properties,
        onAfterClose,
        initialValues: {
          ...initialValues,
          person_id: person.id,
        },
      })
    );
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Imprimir ficha de visita" handleClose={handleClose}>
        <Form
          onCreatePerson={onCreatePerson}
          handleClose={handleClose}
          initialValues={{ properties, property, ...initialValues }}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalVisit;
