import React from 'react';
import { Bar, SeeMore, Title, Wrapper } from './styles';
import { MdFullscreen } from 'react-icons/all';
import { useCurrentUser } from 'hooks/useCurrentUser';

function GraphBoxBar({
  title,
  text,
  data,
  valueKey = 'visitors',
  labelKey = 'source',
  percentageKey = 'percentage',
  renderDataValue,
  renderItem,
}) {
  const currentUser = useCurrentUser();

  function extractDomain(url) {
    let domain = new URL(url).hostname;
    if (domain.startsWith('www.')) {
      domain = domain.slice(4);
    }
    return domain;
  }

  return (
    <Wrapper>
      <header>
        <Title>{title}</Title>
      </header>
      <table>
        <thead>
          <th>{text}</th>
          <th width={50}>Visitas</th>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr>
              <td>
                <Bar data-percent={`${item[percentageKey]}%`}>
                  {renderItem ? renderItem(item) : item[labelKey]}
                </Bar>
              </td>
              <td width={1} align="right" className="h-text-nowrap">
                {renderDataValue ? renderDataValue(item) : item[valueKey]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <span className="h-flex__cell--grow" />
      <SeeMore
        href={`https://analytics.gerenciarimoveis-cf.com.br/${extractDomain(
          currentUser?.primary_domain_url
        )}?period=30d`}
        target="_blank"
      >
        <MdFullscreen size={18} /> VER MAIS
      </SeeMore>
    </Wrapper>
  );
}

export default GraphBoxBar;
