import React, { useMemo, useCallback } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
// Components
import Checkbox from 'components/Checkbox';
import FormBox from 'components/FormBox';
// Containers
import CityField from 'containers/LocationFields/CityField';
import CountryField from 'containers/LocationFields/CountryField';
import StateField from 'containers/LocationFields/StateField';
import { Input } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import { normalizeSearch } from 'lib/text';
import { useDebouncedCallback } from 'use-lodash-debounce';

const propTypes = {};
const defaultProps = {};

const Location = ({
  neighborhoods,
  handleChangeCity,
  fetchData,
  location: { country_id, state_id }
}) => {
  const cityId = useFormValue('city_id');
  const filter = useFormValue('filter_neighborhoods');
  const filterNormalized = useMemo(() => normalizeSearch(filter), [filter]);

  const filteredNeighborhoods = useMemo(() => {
    if (!neighborhoods) return [];
    if (!filterNormalized) return neighborhoods;

    return neighborhoods.filter(({ name }) =>
      normalizeSearch(name)?.includes(filterNormalized)
    );
  }, [filterNormalized, neighborhoods]);

  const handleChange = useCallback(
    (e, value) => {
      if (value.length > 5) {
        fetchData({ name: value, city_id: cityId });
      }
    },
    [fetchData, cityId]
  );

  const handleChangeDebounced = useDebouncedCallback(handleChange, 800);

  return (
    <FormBox styleHeader={{ width: '100%' }} title="Localização">
      <Row>
        <CountryField xs={3} name="country_id" />
        <StateField xs={3} name="state_id" countryId={country_id} />
        <CityField
          xs={3}
          name="city_id"
          stateId={state_id}
          onChange={handleChangeCity}
        />
      </Row>

      {!!cityId && (
        <Row>
          <Field
            label="Buscar bairro"
            xs={6}
            name="filter_neighborhoods"
            component={Input}
            placeholder="Ex.: Centro"
            onChange={handleChangeDebounced}
          />
        </Row>
      )}

      <Row>
        {filteredNeighborhoods &&
          filteredNeighborhoods.map((neighborhood, i) => (
            <Col key={neighborhood.id} xs={3} className="ListCheck__item">
              <Field
                id={neighborhood.id}
                type="checkbox"
                name={`neighborhoods.${neighborhood.id}`}
                component={Checkbox}
                parse={value => {
                  if (!value) return null;
                  return neighborhood;
                }}
              >
                {neighborhood.name}
              </Field>
            </Col>
          ))}
      </Row>
    </FormBox>
  );
};

Location.propTypes = propTypes;
Location.defaultProps = defaultProps;

export default Location;
