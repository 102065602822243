import styled from 'styled-components';

export const Watermark = styled.div`
  display: flex;

  > div {
    font-weight: 600;
  }

  .FormGroup {
    margin-bottom: 0;
    margin-left: 15px;
  }
`;
