import { ThemeProvider } from 'styled-components';
import * as S from './styles';
import { useFont } from 'hooks/useFont';

const Model1 = ({
  image,
  title,
  subtitle,
  description,
  logoUrl,
  backgroundColor,
  mainColor,
}) => {
  useFont({
    families: ['Montserrat:400,600'],
  });

  return (
    <ThemeProvider
      theme={{
        fontSize: '32px',
        backgroundColor,
        mainColor,
        logoUrl,
      }}
    >
      <S.Wrapper>
        <S.Image src={image} alt="" />
        <S.Box>
          <S.Title>{title}</S.Title>
          <S.Price>{subtitle}</S.Price>
          {description && (
            <S.Text>
              {description?.map((item, i) => (
                <div key={i + 'description'}>{item}</div>
              ))}
            </S.Text>
          )}
        </S.Box>
        {logoUrl && (
          <S.LogoWrapper>
            <img src={logoUrl} alt="" />
          </S.LogoWrapper>
        )}
      </S.Wrapper>
    </ThemeProvider>
  );
};

export default Model1;
