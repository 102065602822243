export const COLORS = {
  blue: '#0084F4',
  green: '#00C48C',
  orange: '#FFA26B',
  purple: '#8257e6',
  lightPurple: '#6979F8',
  yellow: '#FFCF5C',
  red: '#FF647C',
  black: '#000'
};
