// Actions
import api from 'services';
import Alert from 'react-s-alert';
import { createModule } from 'lib/reducer-helpers';

const createAction = createModule('propertyPublication');

const initialState = [];

const ADD_PROPERTY = createAction('ADD_PROPERTY');
const REMOVE_PROPERTY = createAction('REMOVE_PROPERTY');
const RESET = createAction('RESET');

export const isPublicationRequested = (propertyId) => (state) => {
  return state.propertyPublication.includes(propertyId);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PROPERTY: {
      // Verifica se tem algum imóvel dentro do state
      // se já existir não faz nada
      if (state.includes(action.propertyId)) return state;
      return [...state, action.propertyId];
    }
    case REMOVE_PROPERTY: {
      return state.filter((propertyId) => propertyId !== action.propertyId);
    }
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export const addProperty = (propertyId) => ({ type: ADD_PROPERTY, propertyId });
export const removeProperty = (propertyId) => ({
  type: REMOVE_PROPERTY,
  propertyId,
});
export const reset = () => ({ type: RESET });

export const requestAddProperty = (propertyId) => async (dispatch) => {
  try {
    dispatch(addProperty(propertyId));
    await api.create(`properties/${propertyId}/publish-notification`);
  } catch {
    dispatch(removeProperty(propertyId));
    Alert.success(
      'Não foi possível solicitar a publicação tente novamente mais tarde'
    );
  }
};
