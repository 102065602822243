import styled from 'styled-components';
import {
  Content,
  Social,
  SocialItem,
  Wrapper as CardWrapper,
  WrapperImage,
} from 'pages/NewDashboard/components/CardProfile/styles';

export const Wrapper = styled(CardWrapper)`
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
`;

export const Image = styled.div`
  position: relative;
  width: 143px;
  height: 143px;

  img {
    width: 143px;
    height: 143px;
    object-fit: cover;
  }

  div {
    text-align: center;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #d8dee5;
    opacity: 0;
    transition: all 0.2s;

    p {
      margin-top: 5px;
    }
  }

  :hover div,
  &.no-image div {
    opacity: 1;
  }
`;

export { Social, SocialItem, Content, WrapperImage };
