import PropTypes from 'prop-types';
import { parseSelect } from 'lib/formHelpers';
import SelectMultiple from 'components/Form/components/SelectMultiple';

export const FieldsKey = {
  country: 'country',
  state: 'state',
  city: 'city',
  neighborhood: 'neighborhood'
};

export const defaultPropsLocation = {
  multi: false,
  formName: null,
  valueKey: 'id',
  labelKey: 'name',
  params: {},
  component: SelectMultiple,
  normalize: parseSelect()
};

export const propTypesLocation = {
  multi: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  params: PropTypes.object
};
