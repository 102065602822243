import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import BoxResources from './components/BoxResources';
import BoxUsers from './components/BoxUsers';
import BoxPayment from './components/BoxPayment';
import BoxPaymentMethod from './components/BoxPaymentMethod';
import Loading from 'components/Loading';
import Container from 'components/Container';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';

const defaultProps = {
  isLoading: false,
  form: null,
  type: 'select',
  users: 1,
  receiving_method: null,

  creditCard: null,
  bankSlip: null,

  change: null,
  initialValues: {
    users: 1,
    months: 1
  },

  handleSubmit: null,
  onAddCardSuccess: null,
  onAddCardFail: null,

  onBankSlipSuccess: null,
  onBankSlipFail: null
};

const propTypes = {
  isLoading: PropTypes.bool,
  form: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['select', 'payment']),
  users: PropTypes.number,
  receiving_method: PropTypes.oneOf([1, 2]),

  creditCard: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.string,
      brand: PropTypes.string
    }),
    isFetching: PropTypes.bool
  }),
  bankSlip: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string,
      real_estate_id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.number,
      cpf_cnpj: PropTypes.string,
      should_send_email: PropTypes.bool
    }),
    isFetching: PropTypes.bool
  }),

  change: PropTypes.func,

  handleSubmit: PropTypes.func.isRequired,
  onAddCardSuccess: PropTypes.func,
  onAddCardFail: PropTypes.func,

  onBankSlipSuccess: PropTypes.func,
  onBankSlipFail: PropTypes.func
};

const FormChangePlan = ({
  isLoading,
  type,
  users,
  receiving_method,

  creditCard,
  bankSlip,

  change,
  handleSubmit,
  onAddCardSuccess,
  onAddCardFail,

  onBankSlipSuccess,
  onBankSlipFail,

  history
}) => {
  const isSelectedBillet = receiving_method === 1;

  return (
    <form onSubmit={handleSubmit} className="FormChangePlan">
      <Loading isVisible={isLoading} position="center" isFullComponent />
      <Container className="FormChangePlan__container">
        {type === 'payment' && (
          <BoxPaymentMethod
            creditCard={creditCard}
            bankSlip={bankSlip}
            change={change}
            users={users}
            onAddCardSuccess={onAddCardSuccess}
            onAddCardFail={onAddCardFail}
            onBankSlipSuccess={onBankSlipSuccess}
            onBankSlipFail={onBankSlipFail}
          />
        )}
        {type === 'select' && (
          <>
            <BoxResources />
            <BoxUsers />
          </>
        )}
        <BoxPayment isSelectedBillet={isSelectedBillet} users={users} />
        <FixedBar>
          <FixedBar.item>
            <Button
              color="white"
              colorText="secondary"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancelar
            </Button>
          </FixedBar.item>
          <FixedBar.spacer />
          <FixedBar.item>
            <Button disabled={isLoading} type="submit" color="success">
              {type === 'select' ? 'Ir para o pagamento' : 'Contratar'}
            </Button>
          </FixedBar.item>
        </FixedBar>
      </Container>
    </form>
  );
};

FormChangePlan.defaultProps = defaultProps;
FormChangePlan.propTypes = propTypes;

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    users: selector(state, 'users'),
    receiving_method: selector(state, 'receiving_method')
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    enableReinitialize: true
  })
)(FormChangePlan);
