import api, { apiStorage } from 'services';
import { responseMessage } from 'lib/service-helpers';

export const getOne = (id) =>
  api.getOne(`settings/sites/whatsapp-contact/${id}`);

export const updateOrCreate = async ({ image, ...values }) => {
  if (values.id) {
    if (image) {
      await apiStorage.uploadFile(`sites/whatsapp-contacts/${values.id}`, {
        image,
      });
    }

    return api
      .update(`settings/sites/whatsapp-contact`, values)
      .then(responseMessage('Usuário editado'));
  }

  const response = await api
    .create(`settings/sites/whatsapp-contact`, values)
    .then(responseMessage('Usuário cadastrado'));

  if (image) {
    await apiStorage.uploadFile(`sites/whatsapp-contacts/${response.data.id}`, {
      image,
    });
  }

  return response;
};

export const remove = (id) => {
  return api
    .delete('settings/sites/whatsapp-contact', id)
    .then(responseMessage('Usuário removido'));
};
