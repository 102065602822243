import { MdInfo } from 'react-icons/md';
import { TableData } from 'containers/ModalRoot/ModalPayment/styles';
import { formatter } from 'containers/ModalRoot/ModalPayment/utils/moneyFormatter';
import { Field } from 'redux-form';
import Amount from 'containers/ModalRoot/ModalPayment/components/Amount';
import { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';
import useFormValue from 'hooks/useFormValue';

const Mailboxes = () => {
  const quantity = useFormValue('quantity');
  const { preview } = useModalContractExtra();

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  return (
    <tr>
      <td className="item">
        <h3>
          Contas de e-mail local{' '}
          <MdInfo
            className="h-margin-left--5 h-color--secondary"
            data-tip="Envie e recebe e-mails com seu domínio (ex: contato@seudominio.com.br)"
          />
        </h3>
        <p>Limitado a 1GB por conta</p>
      </td>
      <td>
        <Field
          min={preview.emails_quantity}
          name="quantity"
          component={Amount}
        />
      </td>
      <TableData align="right">
        R$ {formatter.format(preview?.mailbox_price)}
      </TableData>
      <TableData align="right">
        R$ {formatter.format(quantity * preview?.mailbox_price)}
      </TableData>
    </tr>
  );
};

export default Mailboxes;
