import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Container
import AnalyticsIntegration from 'containers/AnalyticsIntegration';
// Pages
import pages from './pages';
import { useCurrentUser } from 'hooks/useCurrentUser';

const Routes = () => {
  const currentUser = useCurrentUser();

  const check = (permission) => {
    return currentUser.permissions.includes(permission);
  };

  const redirectTo = useMemo(() => {
    if (check('VIEW_PROPERTY_REPORTS')) {
      return '/reports/property/per-type-transaction';
    }

    if (check('VIEW_PEOPLE_REPORTS')) {
      return '/reports/people/by-realtor';
    }

    return 'reports/admin/info';
  }, [currentUser]);

  return (
    <Switch>
      <Route
        path="/reports/property/per-period"
        component={pages.PropertyPerPeriod}
      />
      <Route
        path="/reports/property/per-period-sum"
        component={pages.PropertyPricePeriod}
      />
      <Route
        path="/reports/property/per-type-transaction"
        component={pages.PropertyPerTypeTransaction}
      />
      <Route
        path="/reports/property/orulo-per-type-transaction"
        component={pages.OruloPerTypeTransaction}
      />
      <Route
        path="/reports/property/dwv-per-type-transaction"
        component={pages.DwvPerTypeTransaction}
      />
      <Route
        path="/reports/people/by-realtor"
        component={pages.PeopleByRealtor}
      />
      <Route
        path="/reports/people/by-channel-type"
        component={pages.PeopleByChannelAndType}
      />
      <Route
        path="/reports/people/per-period"
        component={pages.PeoplePerPeriod}
      />
      <Route
        path="/reports/people/per-channel"
        component={pages.PeoplePerChannel}
      />
      <Route
        path="/reports/deal/per-channel"
        component={pages.DealPerChannel}
      />
      <Route path="/reports/deal/per-broker" component={pages.DealPerBroker} />
      <Route
        path="/reports/deal/per-reason-loss"
        component={pages.DealPerReasonLoss}
      />
      <Route path="/reports/deal/loss" component={pages.DealLoss} />
      <Route path="/reports/deal/won" component={pages.DealWon} />
      <Route
        path="/reports/billing/per-type-transaction"
        component={pages.BillingPerTypeTransaction}
      />
      <Route
        path="/reports/billing/per-broker"
        component={pages.BillingPerBroker}
      />
      <Route
        path="/reports/billing/per-commission"
        component={pages.BillingPerCommission}
      />
      <Route
        path="/reports/billing/per-realestate-commission"
        component={pages.BillingPerRealEstateCommission}
      />

      <Route
        path="/reports/analytics/show"
        render={() => (
          <AnalyticsIntegration redirectTo="/reports/analytics/new-users" />
        )}
      />
      <Route
        path="/reports/analytics/new-users"
        component={pages.AnalyticsNewUsers}
      />
      <Route path="/reports/admin/info" component={pages.AdminInfo} />
      <Route
        path="/reports/analytics/visits"
        component={pages.AnalyticsVisits}
      />

      <Redirect to={redirectTo} />
    </Switch>
  );
};

export default Routes;
