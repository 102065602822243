import React from 'react';
// Components
import { FieldInterval } from 'components/Form';
import useFormValue from 'hooks/useFormValue';

function DepartureField({ nameMin, nameMax }) {
  const startDate = useFormValue(nameMin);
  const endDate = useFormValue(nameMax);

  return (
    <FieldInterval
      title="Saída entre"
      nameMax={nameMin}
      nameMin={nameMax}
      startDate={startDate}
      endDate={endDate}
    />
  );
}

DepartureField.defaultProps = {
  nameMin: 'by_departure_at_greater_equals',
  nameMax: 'by_departure_at_lower_equals'
};

export default DepartureField;
