import React from 'react';
import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
import * as crmService from 'services/settings/crm';

function ModalCrmRedirectContact({
  item,
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  onSaveForm,
}) {
  const handleSubmit = async (values) => {
    try {
      let success;
      if (item) {
        success = await crmService.updateContactRedirect(values);
      } else {
        success = await crmService.createContactRedirect(values);
      }
  
      if (success) {
        handleClose();
        onSaveForm();
      }
    } catch(e) {
      console.erro(e)
    }
  }
  

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={item ? 'Editar Redirecionamento de contato' : 'Redirecionamento de contato'}
        text="Todos os contatos da origem serão encaminhados ao destinatário selecionado"
        handleClose={handleClose}
      >
        <Form
          onSubmit={handleSubmit}
          handleClose={handleClose}
          initialValues={item ? item : {
            options: 1,
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalCrmRedirectContact;
