import { Header } from 'features/Financial/components/Header';
import { Financial } from 'features/Financial';
import Button from 'components/Button';
import { useParams } from 'react-router';
import { useGetCategories } from '../../hooks/useCategories';
import { useEffect, useMemo } from 'react';
import { openModalStoreCategory } from 'features/Financial/modals/ModalStoreCategory';
import { useDispatch } from 'react-redux';
import { List } from './components/List';

export function ListCategories() {
  const dispatch = useDispatch();
  const { type } = useParams();
  const { categories, metaCategories, isFetchingCategories, fetchCategories } =
    useGetCategories();

  const isDebit = type !== 'income';

  const fetchData = ({ offset = 1 }) => {
    fetchCategories({
      filter: { is_debit: isDebit, only_parents: true },
      sort: '-updated_at',
      limit: 50,
      offset,
    });
  };

  useEffect(() => {
    fetchData({});
  }, [type, isDebit]);

  const onPageChange = ({ selected }) => {
    fetchData({
      offset: selected + 1,
    });
  };

  const handleAddCategory = ({ isCategory = true }) => {
    dispatch(
      openModalStoreCategory({
        isDebit,
        isCategory,
        onSuccess: () => {
          fetchData({});
        },
      })
    );
  };

  const texts = useMemo(() => {
    if (type === 'income') {
      return {
        title: 'Categorias de receita',
      };
    }

    return {
      title: 'Categorias de despesa',
    };
  }, [type]);

  return (
    <>
      <Header.Header>
        <Header.Title>{texts.title}</Header.Title>
        <Header.Actions>
          <Button size="small" color="secondary" onClick={handleAddCategory}>
            Adicionar categoria
          </Button>
        </Header.Actions>
      </Header.Header>
      <Financial.Content isFetching={isFetchingCategories}>
        <List
          isFetching={isFetchingCategories}
          isDebit={isDebit}
          data={categories}
          meta={metaCategories}
          onPageChange={onPageChange}
        />
      </Financial.Content>
    </>
  );
}
