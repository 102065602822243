import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ModalTemplate from '../../../components/Modal/components/ModalTemplate';
// Services
import * as emailService from 'services/settings/email';

import EmailBoxRedirectedForm from './components/EmailBoxRedirectedForm';
import MiddlewareDomains from 'containers/MiddlewareDomains';

class ModalAddEmailAccount extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    username: PropTypes.string,
    onSubmitSuccess: PropTypes.func,
    onSubmitFail: PropTypes.func
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null,

    username: null,
    onSubmitSuccess: null,
    onSubmitFail: null
  };

  state = {
    initialValues: {}
  };

  componentDidMount() {
    this.getFormValues();
  }

  async getFormValues() {
    const { username } = this.props;
    try {
      await emailService.getRedirectedAccount(username);
    } catch {
      return {};
    }
  }

  handleSubmit = values => {
    return emailService.createMailbox(values);
  };

  onSubmitSuccess = () => {
    if (this.props.onSubmitSuccess) this.props.onSubmitSuccess();

    // Fecha a modal
    this.props.handleClose();
  };

  onSubmitFail = () => {
    if (this.props.onSubmitFail) this.props.onSubmitFail();
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title="Criar caixa redirecionada"
          handleClose={handleClose}
        >
          <MiddlewareDomains handleClose={handleClose}>
            {domains =>
              domains && (
                <EmailBoxRedirectedForm
                  initialValues={{
                    domain_id: localStorage.getItem('current-user').real_estate
                      .primary_domain.id
                  }}
                  domain={this.props.domain}
                  domains={domains}
                  handleClose={handleClose}
                  onSubmit={this.handleSubmit}
                  onSubmitSuccess={this.onSubmitSuccess}
                  onSubmitFail={this.onSubmitFail}
                />
              )
            }
          </MiddlewareDomains>
        </ModalTemplate>
      </Modal>
    );
  }
}

export default ModalAddEmailAccount;
