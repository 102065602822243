import { useRequest } from 'hooks/useRequest';
import { indexRecurrencyTypes } from 'features/Financial/services/recurrencyTypes';

export const useIndexRecurrencyTypes = () => {
  const { data, isFetching, fetchData } = useRequest({
    request: indexRecurrencyTypes,
  });

  return {
    recurrencyTypes: data,
    isFetchingRecurrencyTypes: isFetching,
    fetchRecurrenceTypes: fetchData,
  };
};
