import styled from 'styled-components';

export const Wrapper = styled.span`
  color: ${(p) => p.color};
  font-size: 14px;
  font-weight: 600;
`;

export const Divider = styled.span`
  padding: 3px;
`;
