import React, { useCallback } from 'react';
import Modal from 'react-modal';
import ModalClose from 'components/Modal/components/ModalClose';
import Button from 'components/Button';
import { withWizard } from 'HOC/withWizard';
import featureImage from './assets/v-card.png';

// Styles
import {
  Wrapper,
  WrapperInner,
  Text,
  Confetti,
  Balloon,
  MainImage
} from './styles';

function ModalNewFeature({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onClose
}) {
  const close = useCallback(() => {
    handleClose();
    onClose();
  }, [handleClose, onClose]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Wrapper>
        <ModalClose handleClose={close} />
        <WrapperInner>
          <Confetti />
          <Balloon />
          <Text>
            <h1>Nova ferramenta a disposição</h1>
            <p>
              Seu painel Tecimob foi atualizado com melhorias para alavancar
              ainda mais o seu negócio e facilitar o seu dia a dia.
            </p>
            <Button color="success" onClick={close}>
              Entendi
            </Button>
          </Text>
          <MainImage src={featureImage} />
        </WrapperInner>
      </Wrapper>
    </Modal>
  );
}

export default withWizard('feature-vcard3')(ModalNewFeature);
