import axios from 'axios';
import qs from 'qs';
import { SubmissionError } from 'redux-form';
import Alert from 'react-s-alert';
// Modules
import { logoutUser } from '../modules/login';
// Constants
import {
  API_BASE_URL,
  API_PDF,
  API_STORAGE_URL,
  API_URL,
} from '../constants/config';
import { openModalSMTPAlert } from 'modules/modal';

export const STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  INVALID_PARAMS: 400,
  NOT_AUTHORIZED: 401,
  NO_PERMISSION: 403,
  NOT_SUPPORTED_METHOD: 405,
  INFORMATION_NOT_FOUND: 422,
  SERVER_ERROR: 500,
};

// Motivos erro quando der um erro 403 de sem permissão
export const REASONS = {
  // - Quando o usuário foi bloqueado por não pagamento
  // - Quando acabou o periodo de teste
  REAL_ESTATE_BLOCKED: 'REAL_ESTATE_BLOCKED',

  // Quando o usuário tenta fazer algo que não pode
  FORBIDDEN: 'FORBIDDEN',
};

const AXIOS_CONFIG = {
  baseURL: API_URL,
  headers: {
    accept: 'application/vnd.api_tecimob.v1+json',
  },
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: 'brackets' }),
};

export const PDF = axios.create({
  baseURL: API_PDF,
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: 'brackets' }),
});

export const HTTP = axios.create(AXIOS_CONFIG);
export const ORIGINAL = axios.create(AXIOS_CONFIG);
export const STORAGE = axios.create({
  baseURL: API_STORAGE_URL,
  headers: {
    accept: 'application/vnd.api_tecimob.v1+json',
  },
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: 'brackets' }),
});

// Métodos aonde é permitido fazer a validacao pelo servidor
const METHODS_VALIDATION = ['post', 'put', 'patch', 'delete'];

export const configInterceptors = (store) => {
  const responseSuccess = (res) => {
    let response = res;
    if (response.data) {
      response = { ...response, ...response.data };
    }

    // Verifica se tem um status false
    if (response.data.status === false && response?.data?.message?.length > 0) {
      Alert.success(response.data.message, { timeout: 3000 });
    }

    return response;
  };

  const responseReject = async (err) => {
    if (axios.isCancel(err)) {
      throw err.message;
    }

    const {
      config,
      response: { data, status },
    } = err;
    const { message, reason, alerts } = data;

    if (status === STATUS.SERVER_ERROR) {
      Alert.success('Erro no servidor');
      return Promise.reject(err);
    }

    if (status === STATUS.INVALID_PARAMS) {
      if (alerts && alerts.length > 0) {
        alerts.forEach((alert, index) => {
          Alert.success(alert, { timeout: (index + 1) * 3000, html: true });
        });

        return Promise.reject(err);
      }

      if (METHODS_VALIDATION.indexOf(config.method) !== -1) {
        throw new SubmissionError(message);
      }
    }

    // Quando não tiver permissão para executar o request deve bloquear a imobiliária
    const canBlockUser =
      status === STATUS.NO_PERMISSION && reason === REASONS.REAL_ESTATE_BLOCKED;

    if (status === STATUS.NO_PERMISSION && reason === REASONS.FORBIDDEN) {
      window.location.replace('/no-permission');
    }

    // Verifica se pode bloquear o usuário
    if (canBlockUser) {
      window.location.reload();
      return Promise.reject(err);
    }

    if (status === STATUS.INFORMATION_NOT_FOUND) {
      if (err.response.data.error === 'Limite de envios atingido!') {
        store.dispatch(openModalSMTPAlert());
      }
    }

    // Verifica se está autorizado
    if (status === STATUS.NOT_AUTHORIZED) {
      const { access_token } = localStorage;

      // Se tiver access token no localStorage
      // Significa que ele tem que mostrar a mensagem de quando alguem invalidou o token
      if (access_token) {
        Alert.success(
          'Você acessou sua conta em outro computador, e por isso, foi desconectado dessa sessão.'
        );

        // Desloga o usuário
        store.dispatch(logoutUser());

        window.location = '/device-logout';
      }

      // Desloga o usuário
      store.dispatch(logoutUser());

      // Rejeita a promise para parar de executar
      return Promise.reject(err);
    }

    return Promise.reject(err);
  };

  HTTP.interceptors.response.use(responseSuccess, responseReject);
  ORIGINAL.interceptors.response.use(responseSuccess, responseReject);
  STORAGE.interceptors.response.use(responseSuccess, responseReject);
};

export { API_BASE_URL, API_URL };
