import React, { useState, useMemo, useCallback } from 'react';
import Modal from 'react-modal';

// Components
import { ModalTemplate } from 'components/Modal';
import ModalConfig from 'containers/ModalRoot/configs';
import FormPeoplePreImport from './components/FormPeoplePreImport';
import FormConfirmImport from './components/FormConfirmImport';
import { useSelector, useDispatch } from 'react-redux';
import { currentUserSelector } from 'modules/login';
import { openModalSuccess } from 'modules/modal';

// Services
import { cancelImport } from 'services/imports/people';

function ModalPeopleImport({ isOpen, handleClose, modalType }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const [importedData, setImportData] = useState({});
  const [isLoading, setLoading] = useState(false);

  const initialValues = useMemo(() => {
    if (currentUser?.realtor) return { user_id: currentUser?.id };
    return {};
  }, [currentUser]);

  const handleClickCancelImport = useCallback(() => {
    cancelImport(importedData.id);
    setImportData({});
  }, []);

  const handleConfirmSuccess = useCallback(() => {
    dispatch(
      openModalSuccess({
        title: 'Importação em andamento!',
        text: 'Você será notificado quando todos os clientes forem importados'
      })
    );
  }, []);

  const isImported = useMemo(() => {
    return !!importedData?.id;
  }, [importedData]);

  const modalConfig = useMemo(() => {
    if (isImported) {
      return ModalConfig({ modalSize: 802, modalPosition: 'top' });
    }

    return ModalConfig({ modalSize: 621, modalPosition: 'center' });
  }, [isImported]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={isLoading}
        title="Importar clientes"
        handleClose={handleClose}
      >
        {isImported ? (
          <FormConfirmImport
            data={importedData}
            initialValues={importedData}
            handleClose={handleClose}
            handleClickCancel={handleClickCancelImport}
            onSubmitSuccess={handleConfirmSuccess}
          />
        ) : (
          <FormPeoplePreImport
            handleClose={handleClose}
            onSubmitStart={() => {
              setLoading(true);
            }}
            onSubmitSuccess={({ data }) => {
              setImportData(data);
              setLoading(false);
            }}
            onSubmitFail={() => {
              setLoading(false);
            }}
            initialValues={initialValues}
          />
        )}
      </ModalTemplate>
    </Modal>
  );
}

export default ModalPeopleImport;
