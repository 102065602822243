import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdArrowDropDown, MdHelp } from 'react-icons/md';
import ReactToggle from 'react-toggle';
import ReactTooltip from 'react-tooltip';
// Helpers
import { plural } from 'lib/text';
// Modules
import {
  documentActions,
  documentsIsFetchingSelector,
  documentsSelector,
  getDocuments,
  handleDeleteDocument,
  toggleDocumentVisibility,
  uploadProperty,
} from 'modules/propertyInfo';
// Components
import Button from 'components/Button';
import FilesLoading from 'components/FilesLoading';
import Nodata from 'components/Nodata';
import Dropdown, { Item, List } from 'components/Dropdown';
import { ModalSubtitle } from 'components/Modal';
// Container Components
import FormUpload from './components/FormUpload';
import Can from 'containers/Can';
import UpdateDocumentName from './containers/UpdateDocumentName';
// Assets
import folderImg from 'assets/img/noData/folder.svg';
import { PROPERTY_PERMISSIONS } from 'constants/rules';

class Documents extends Component {
  static defaultProps = {};

  static propTypes = {};

  get hasPermission() {
    const { permissions } = this.props.infos;
    return permissions.includes(PROPERTY_PERMISSIONS.DOCUMENTS);
  }

  componentDidMount() {
    if (this.hasPermission) {
      // busca os documentos cadastrados
      this.props.getDocuments(this.props.property.id).then(() => {
        ReactTooltip.rebuild();
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // todo dar um jeito nesse rebuild aqui
    ReactTooltip.rebuild();
  }

  handleUploadDocument = (values) =>
    this.props.uploadProperty({
      ...values,
      property_id: this.props.property.id,
    });

  toggleDocumentVisibility = (document) => () => {
    this.props.toggleDocumentVisibility(document, this.props.property.id);
  };

  /**
   * Deleta um documento
   * @param id
   */
  deleteDocument = (id) => () =>
    this.props.handleDeleteDocument({
      id,
      property_id: this.props.property.id,
    });

  render() {
    const { documents, upload, alterDocument } = this.props;

    const length = documents.length;

    return (
      <Can
        run="DYNAMIC_DOCUMENTS"
        permissions={this.props.infos.permissions}
        yes={() => {
          if (length === 0) {
            return (
              <div>
                <Nodata
                  image={folderImg}
                  title="Nenhum Documento encontrado"
                  text="Adicione o primeiro documento no botão abaixo:"
                >
                  <FormUpload
                    className="h-margin-top--10"
                    onChange={this.handleUploadDocument}
                  >
                    Adicionar Documento
                  </FormUpload>
                </Nodata>
              </div>
            );
          }

          return (
            <React.Fragment>
              <ModalSubtitle
                title={plural(
                  length,
                  'documento enviado',
                  'documentos enviados'
                )}
              >
                <FormUpload onChange={this.handleUploadDocument} />
              </ModalSubtitle>

              <FilesLoading
                filesTotal={upload.total}
                filesUploaded={upload.success + upload.error}
              />

              <table
                className="Table Table--full"
                style={{
                  width: 'calc(100% + 39px)',
                  margin: '0 -19px',
                }}
              >
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Nome</th>
                    {/*<th>Enviado Por</th>*/}
                    <th colSpan={2}>
                      Publico?
                      <div
                        data-html
                        className="h-margin-left--5"
                        data-effect="solid"
                        data-tip="<div>Ao tornar público, o documento <br>parecerá na Rede Guru e no seu site.<br> Usado para Memorial Descritivo,<br> Catálogos, entre outros. </div>"
                        style={{ display: 'inline-block' }}
                      >
                        <MdHelp />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.documents &&
                    this.props.documents.map((document) => {
                      const { id, created_at, is_public, name, file_url } =
                        document;
                      return (
                        <tr key={id}>
                          <td width={180}>{created_at}</td>
                          <td>
                            <UpdateDocumentName
                              documentId={id}
                              propertyId={this.props.property.id}
                              onSuccess={({ data }) => {
                                alterDocument(data);
                              }}
                            >
                              {name}
                            </UpdateDocumentName>
                          </td>
                          <td width={140}>
                            <ReactToggle
                              checked={is_public}
                              onChange={this.toggleDocumentVisibility(document)}
                              icons={false}
                              className={`react-toggle--medium`}
                            />
                          </td>
                          <td width="1">
                            <Dropdown isDropHover>
                              <Button
                                color="white"
                                colorText="secondary"
                                size="medium"
                              >
                                Opções <MdArrowDropDown />
                              </Button>
                              <List>
                                <Item>
                                  <a
                                    href={file_url}
                                    download={name}
                                    target="_blank"
                                  >
                                    Baixar
                                  </a>
                                </Item>
                                <Item>
                                  <button onClick={this.deleteDocument(id)}>
                                    Excluir
                                  </button>
                                </Item>
                              </List>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </React.Fragment>
          );
        }}
        no={() => (
          <Nodata
            image={folderImg}
            title="Sem permissão para ver os documentos"
            text="Você não tem permissão para ver os documentos desse imóvel"
          />
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  documents: documentsSelector(state),
  upload: state.modalProperty.rootInfo.propertyDocuments,
  isFetching: documentsIsFetchingSelector(state),
});

const mapDispatchToProps = {
  uploadProperty,
  getDocuments,
  handleDeleteDocument,
  toggleDocumentVisibility,
  alterDocument: documentActions.alter,
  // handleTogglePrivate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
