import styled from 'styled-components';
import Button from 'components/Button';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 16px;
`;

export const Actions = styled.div.attrs(() => ({
  className: 'rbc-btn-group'
}))`
  position: relative;

  .Button {
    margin: 0;
  }
`;

export const Btn = styled(Button).attrs(({ isActive }) => ({
  type: 'button',
  color: isActive ? 'primary' : 'white',
  colorText: isActive ? 'white' : 'secondary'
}))``;
