import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Services
import * as dealsService from 'services/deals';
// Common Components
import { ModalTemplate } from '../../../components/Modal';
import FormDealLoss from './components/FormDealLoss';
import _ from 'lodash';

class ModalDealLoss extends Component {
  static propTypes = {
    dealId: PropTypes.string.isRequired,
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    onSubmitSuccess: PropTypes.func,
    onSubmitFail: PropTypes.func,
  };

  static defaultProps = {
    dealId: null,
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null,

    onSubmitSuccess: null,
    onSubmitFail: null,
  };

  onSubmit = (values) => dealsService.dealLoss(this.props.dealId, values);

  onSubmitSuccess = () => {
    const { onSubmitSuccess } = this.props;
    if (onSubmitSuccess) onSubmitSuccess();
    this.props.handleClose();
  };

  onSubmitFail = () => {
    const { onSubmitFail } = this.props;
    if (onSubmitFail) onSubmitFail();
  };

  get title() {
    if (_.isArray(this.props.dealId)) {
      return `${this.props.dealId.length} negócios perdidos`;
    }

    return 'Negócio perdido';
  }

  render() {
    const { modalConfig, isOpen, handleClose, modalType, dealId } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate title={this.title} handleClose={handleClose}>
          <FormDealLoss
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
            onSubmitFail={this.onSubmitFail}
            handleClose={handleClose}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDealLoss);
