import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Component
import PersonForm from './components/PersonForm';
import PersonFormSimple from './components/PersonFormSimple';
import PersonRegistered from './components/PersonRegistered';
// Modules
import {
  getModalPeopleValues,
  handleFormSubmit,
  receiveInitialValues,
  unsetPerson,
} from 'modules/modalPersonAdd';

import { closeModal, openModalPerson } from 'modules/modal';
import { SubmissionError } from 'redux-form';

const defaultProps = {
  typePerson: null,
  realtors: [{}],
  typesOptions: [{}],
  config: { checkGroupOwner: false, checkGroupByName: '' },
  person: {
    id: null,
    owner: {
      id: null,
    },
  },
};

const propTypes = {
  stateId: PropTypes.object,
  cityId: PropTypes.object,
  config: PropTypes.shape({
    checkGroupOwner: PropTypes.bool,
    checkGroupByName: PropTypes.string,
  }),
  typePerson: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  getModalPeopleValues: PropTypes.func.isRequired,
  realtors: PropTypes.arrayOf(PropTypes.object),
  typesOptions: PropTypes.arrayOf(PropTypes.object),
};

const FORM_TYPES = {
  PersonFormSimple,
  PersonForm,
};

class ModalPersonAdd extends React.Component {
  state = {
    hasPerson: false,
    formType: 'PersonFormSimple',
  };

  async componentDidMount() {
    if (this.props?.config?.formType)
      this.setState({ ...this.state, formType: this.props?.config?.formType });

    this.props.getModalPeopleValues(this.props?.person?.id);
  }

  componentWillUnmount() {
    // Remove o cliente
    this.props.unsetPerson();
  }

  setHasPerson = (person) => this.setState({ person, hasPerson: true });

  /**
   * Lida com o evento de click da modal de cliente que já possui cadastro
   * @param person
   * @return {Function}
   */
  handleClickAction = (person) => () => {
    return this.props.openModalPerson(person);
  };

  handleClickSeeFullForm = () => {
    this.setState({
      formType: 'PersonForm',
    });
  };

  getPerson() {
    return {
      ...this?.props?.myPerson,
      ...this?.props?.person,
    };
  }

  onSubmit = (values) => {
    // Verifica se é formulário completo
    // Se for tem que passar os valores do formulário simples
    // para o formulário completo
    if (values?.isFullForm) {
      // Remove informação extra sem importancia do payload
      delete values?.isFullForm;

      // Inicia os valores iniciais do formulário
      this.props.receiveInitialValues(values);

      // Muda o formulário para a versão completa
      this.setState({ formType: 'PersonForm' });
      return;
    }

    if (!values?.groups_id || values?.groups_id?.length <= 0) {
      throw new SubmissionError({
        groups_id: ['Campo obrigatório'],
      });
    }

    if (values?.type_form) {
      if (values?.type_form === 'simple') {
        delete values.phones;
        delete values.type_form;
      } else {
        delete values.cellphone_number;
        delete values.cellphone_ddi;
        delete values.cellphone_iso2;
      }
    }

    return this.props.handleFormSubmit(values);
  };

  onSubmitSuccess = (currentPerson) => {
    const person = this.getPerson();
    const isEditting = !!person.id;

    // verifica se está editando o imóvel
    if (isEditting && currentPerson?.id) {
      // Se estiver editando chama a modal de vida do cliente
      this.props.openModalPerson({
        id: currentPerson?.id,
        person: { id: currentPerson?.id },
      });
    }
  };

  render() {
    const { modalConfig, isOpen, modalType, handleClose, meta, config } =
      this.props;

    const person = this.getPerson();
    const isEditting = !!person.id;

    // Verifica se tem um cliente registrado
    if (this.state.hasPerson) {
      return (
        <PersonRegistered
          {...this.props}
          person={this.state.person}
          handleClickAction={this.handleClickAction}
        />
      );
    }

    const Form = FORM_TYPES[this.state.formType];

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
        style={{
          content: {
            maxWidth: this.state.formType === 'PersonForm' ? 1200 : 675,
          },
        }}
      >
        <Form
          key={this.state.formType}
          isLoading={meta.isFetching}
          config={config}
          isEditting={isEditting}
          person={person}
          personId={person.id}
          onSubmit={this.onSubmit}
          onSubmitSuccess={this.onSubmitSuccess}
          handleClose={handleClose}
          setHasPerson={this.setHasPerson}
          handleClickSeeFullForm={this.handleClickSeeFullForm}
        />
      </Modal>
    );
  }
}

ModalPersonAdd.defaultProps = defaultProps;
ModalPersonAdd.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => ({
  myPerson: state.modalPersonAdd.person,
  typesOptions: state.modalPersonAdd.types,
  realtors: state.modalPersonAdd.realtors,
  initialValues: state.modalPersonAdd.formData,
  meta: state.modalPersonAdd.meta,
});

const mapDispatchToProps = {
  getModalPeopleValues,
  handleFormSubmit,
  openModalPerson,
  unsetPerson,
  closeModal,
  receiveInitialValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPersonAdd);
