import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
// HOC
import { withFormName } from 'HOC/withFormName';
// Components
import ListPlans from '../ListPlans';
// Modules
import { openModalSupport } from 'modules/modal';

const defaultProps = {
  isSelectedBillet: false,
  users: null,
  openModalSupport: null
};

const propTypes = {
  isSelectedBillet: PropTypes.bool,
  users: PropTypes.number,
  openModalSupport: PropTypes.func
};

function BoxPayment({
  receivingMethod,
  users,
  isSelectedBillet,
  openModalSupport
}) {
  return (
    <div className="BoxPlan BoxPlan--right">
      <header className="BoxPlan__header">
        <h1 className="BoxPlan__title">Selecione o prazo de pagamento</h1>
        <p className="BoxPlan__text">
          Para condições especiais de pagamento,{' '}
          <span
            className="h-link"
            onClick={() => {
              openModalSupport();
            }}
            style={{ cursor: 'pointer' }}
          >
            clique aqui.
          </span>
        </p>
      </header>

      <Field
        receivingMethod={receivingMethod}
        isSelectedBillet={isSelectedBillet}
        users={users}
        name="months"
        component={ListPlans}
      />
    </div>
  );
}

BoxPayment.defaultProps = defaultProps;
BoxPayment.propTypes = propTypes;

const mapStateToProps = (state, { formName }) => {
  const selector = formValueSelector(formName);

  return {
    receivingMethod: selector(state, 'receiving_method')
  };
};

const mapDispatchToProps = {
  openModalSupport
};

export default compose(
  withFormName,
  connect(mapStateToProps, mapDispatchToProps)
)(BoxPayment);
