import React from 'react';
import { compose } from 'redux';
import { Field, initialize, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import GroupControl, { GroupItem } from 'components/GroupControl';
import Container from 'pages/Site/components/ContainerSite';
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { FieldBool, Input } from 'components/Form';
import ListOptions from 'components/ListOptions';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormMap = ({ handleSubmit, submitting, pristine }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <ListOptions>
          <ListOptions.item
            title="Mostrar Mapa"
            text={
              <>
                Disponibilizar um mapa com os seus imóveis para os seus
                clientes?
                <br />
                <strong>
                  (Precisa ativar o mapa e localização exata no imóvel)
                </strong>
              </>
            }
            renderOptions={() => <FieldBool name="maps_is_shown" />}
          />
          <ListOptions.item
            title="Título da área"
            text="Edite o título que ficará acima do mapa."
          >
            <Field
              className="h-margin-top--10"
              name="maps_title"
              component={Input}
            />
          </ListOptions.item>
          <ListOptions.item
            title="Tamanho do mapa"
            text="Informe qual a altura desejada para o mapa na página inicial do seu site."
            renderOptions={() => (
              <div style={{ width: '120px' }}>
                <GroupControl label="&nbsp;" required={false}>
                  <GroupItem>
                    <Field
                      type="number"
                      name="maps_size"
                      component={Input}
                      normalize={(value) => {
                        if (!value) return '';
                        if (value > 2000) {
                          return '2000';
                        }
                        return value;
                      }}
                    />
                  </GroupItem>
                  <GroupItem label="px" size="small" />
                </GroupControl>
              </div>
            )}
          />
        </ListOptions>
        <FixedBar style={{ left: 240 }}>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar
          </Button>
        </FixedBar>
      </Container>
    </form>
  );
};

FormMap.defaultProps = defaultProps;
FormMap.propTypes = propTypes;

//const selector = formValueSelector('FormMap');

export default compose(
  reduxForm({
    form: 'FormMap',
    onSubmitSuccess: (res, dispatch) => {
      // Reinicializa o formulário com os valores
      dispatch(initialize('FormMap', res.data, false));
    },
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
  })
)(FormMap);
