// Widgets.js
import _ from 'lodash';
import { createModule } from 'lib/reducer-helpers';

const createAction = createModule('selecteds');

// Actions
const REGISTER = createAction('REGISTER');
const UNREGISTER = createAction('UNREGISTER');
const SET_SELECTED = createAction('SET_SELECTED');
const SET_ALL = createAction('SET_ALL');
const ADD_ALL = createAction('ADD_ALL');
const REMOVE_ALL = createAction('REMOVE_ALL');
const UNSET_SELECTED = createAction('UNSET_SELECTED');
const TOGGLE_SELECTED = createAction('TOGGLE_SELECTED');
const RESET_SELECTEDS = createAction('RESET_SELECTEDS');

const initialState = {};

export const selectedsSelector = (name) => (state) => state?.selecteds?.[name];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        [action.name]: action.initialValues || [],
      };
    case UNREGISTER:
      return _.omit(state, action.name);

    case SET_ALL:
      return {
        ...state,
        [action.name]: action.value,
      };

    case ADD_ALL:
      return {
        ...state,
        [action.name]: _.union(state[action.name], action.value),
      };

    case REMOVE_ALL:
      return {
        ...state,
        [action.name]: _.difference(state[action.name], action.value),
      };

    case SET_SELECTED:
      return {
        ...state,
        [action.name]: _.union(state[action.name], [action.value]),
      };
    case UNSET_SELECTED:
      return {
        ...state,
        [action.name]: _.without(state[action.name], action.value),
      };
    case TOGGLE_SELECTED:
      return {
        ...state,
        [action.name]: _.xor(state[action.name], [action.value]),
      };
    case RESET_SELECTEDS:
      return {
        ...state,
        [action.name]: [],
      };
    default:
      return state;
  }
}

// Action Creators
export const Actions = {
  registerSelected: (name, initialValues) => ({
    type: REGISTER,
    name,
    initialValues,
  }),
  unregisterSelected: (name) => ({
    type: UNREGISTER,
    name,
  }),
  setSelected: (name, value) => ({
    type: SET_SELECTED,
    name,
    value,
  }),
  setAll: (name, value) => ({
    type: SET_ALL,
    name,
    value,
  }),
  addAll: (name, value) => ({
    type: ADD_ALL,
    name,
    value,
  }),
  removeAll: (name, value) => ({
    type: REMOVE_ALL,
    name,
    value,
  }),
  toggleSelected: (name, value) => ({
    type: TOGGLE_SELECTED,
    name,
    value,
  }),
  unsetSelected: (name, value) => ({
    type: UNSET_SELECTED,
    name,
    value,
  }),
  resetSelecteds: (name) => ({
    type: RESET_SELECTEDS,
    name,
  }),
};
