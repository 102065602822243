import React, { useEffect } from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import FieldSelectUser from 'containers/FieldSelectUser';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useSelector } from 'react-redux';
import { useCan } from 'hooks/useCan';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

export const selector = getFormValues('FormUser');

export const useFormUserId = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);

  const currentUser = useCurrentUser();
  const formUserId = useSelector(
    (state) => selector(state, 'user_id')?.user_id
  );

  // if (queryParams.by_user_id) {
  //   return queryParams.by_user_id;
  // }
  return formUserId || currentUser.id;
};

function FormUser({ isDashboard, handleSubmit, change, initial, initialize }) {
  const currentUser = useCurrentUser();
  const userId = useFormUserId();
  const formUserId = useSelector(
    (state) => selector(state, 'user_id')?.user_id
  );

  const canViewOtherDeal = useCan({
    run: 'VIEW_OTHER_DEAL',
  });

  const canViewOtherPersonProperty = useCan({
    run: 'OTHER_PERSON_PROPERTY',
  });

  const hasPermission = canViewOtherPersonProperty && canViewOtherDeal;

  const location = useLocation();
  const queryParams = qs.parse(location.search);

  useEffect(() => {
    if (queryParams.by_user_id) {
      change('user_id', queryParams.by_user_id);
    } else {
      if (currentUser.realtor) {
        initialize({
          user_id: currentUser.id,
        });
      } else {
        initialize({
          user_id: null,
        });
      }
    }
  }, []);

  const checkUserId = () => {
    if (!isDashboard && !formUserId && !!currentUser.realtor) {
      change('user_id', currentUser.id);
    }
  };

  useEffect(() => {
    checkUserId();
  }, [isDashboard, formUserId]);

  if (!hasPermission) return false;

  return (
    <form onSubmit={handleSubmit}>
      <FieldSelectUser
        clearable={isDashboard}
        label=""
        placeholder="Corretor"
        style={{ marginTop: '10px', marginBottom: '-5px' }}
        params={{
          sort: 'by_name',
        }}
        className="js-user-select"
      />
    </form>
  );
}

export default reduxForm({
  form: 'FormUser',
})(FormUser);
