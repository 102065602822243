import { useRequest } from 'hooks/useRequest';
import { getCondos } from 'services/properties';

export const useCondos = ({ initialFetch = true, network = 'self' }) => {
  return useRequest({
    request: getCondos,
    initialFetch,
    params: {
      filter: {
        on_network: [network],
      },
    },
  });
};
