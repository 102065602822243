// import styles from './styles'

import { Table } from 'features/Financial';
import Button from 'components/Button';
import { MdDelete, MdEdit, MdSwapHoriz } from 'react-icons/md';
import * as S from './styles';
import { useDeleteAccount } from 'features/Financial/hooks/useAccounts';
import { openModalStoreAccount } from 'features/Financial/modals/ModalStoreAccount';
import { useDispatch } from 'react-redux';
import { openModalAjustAccount } from 'features/Financial/modals/ModalAjustAccount';
import { openModalAccountTransfer } from 'features/Financial/modals/ModalAccountTransfer';
import { Nodata } from './Nodata';

export function TableAccounts({ data, meta, onPageChange }) {
  const dispatch = useDispatch();
  const { deleteAccount } = useDeleteAccount();
  const length = data.length;

  const handleClickOpenModal =
    ({ data }) =>
    () => {
      dispatch(
        openModalStoreAccount({
          data,
          type: 1,
          onSuccess: () => {
            onPageChange({ selected: 0 });
          },
        })
      );
    };

  const handleClickAjust = (account) => () => {
    dispatch(
      openModalAjustAccount({
        data: account,
        type: 1,
        onSuccess: () => {
          onPageChange({ selected: 0 });
        },
      })
    );
  };

  const handleClickTransfer = (account) => () => {
    dispatch(
      openModalAccountTransfer({
        data: account,
        onSuccess: () => {
          onPageChange({ selected: 0 });
        },
      })
    );
  };

  if (!data || data.length <= 0)
    return <Nodata onAddAccount={handleClickOpenModal} />;

  return (
    <Table.List data={data} meta={meta} onPageChange={onPageChange}>
      {(item) => (
        <Table.Tr>
          <Table.Td>
            <S.Text>
              <S.Icon src={item.icon.file_url} />
              <span>{item.title}</span>
            </S.Text>
          </Table.Td>
          <Table.Td width={200} onClick={handleClickAjust(item)}>
            <S.WrapperPrice>
              <S.Price>{item.balance}</S.Price>
              <S.Link>Ajustar saldo</S.Link>
            </S.WrapperPrice>
          </Table.Td>
          <Table.Td width="40%">
            <Table.Actions>
              <Button
                data-tip=""
                disabled={length < 2}
                size="small"
                color="white"
                onClick={handleClickTransfer(item)}
              >
                <MdSwapHoriz /> Transferência
              </Button>
              <Button
                size="small"
                color="white"
                onClick={handleClickOpenModal({ data: item })}
              >
                <MdEdit />
              </Button>
              <Button
                size="small"
                color="white"
                onClick={deleteAccount({
                  account: item,
                  onSuccess: () => {
                    onPageChange({ selected: 0 });
                  },
                })}
              >
                <MdDelete className="h-color--danger" />
              </Button>
            </Table.Actions>
          </Table.Td>
        </Table.Tr>
      )}
    </Table.List>
  );
}
