import Alert from 'react-s-alert';
import { createModule } from '../lib/reducer-helpers';
import * as notesService from 'services/crm/notes';

const createAction = createModule('personNotes');

/**
 * Notes
 */
const REQUEST_NOTES = createAction('REQUEST_NOTES');
const RECEIVE_NOTES = createAction('RECEIVE_NOTES');

const RECEIVE_NOTE = createAction('RECEIVE_NOTE');
const ALTER_NOTE = createAction('ALTER_NOTE');

const SHOW_FORM = createAction('SHOW_FORM');
const HIDE_FORM = createAction('HIDE_FORM');

const REQUEST_FORM_DATA = createAction('REQUEST_FORM_DATA');
const RECEIVE_FORM_DATA = createAction('RECEIVE_FORM_DATA');
const CLEAR_FORM_DATA = createAction('CLEAR_FORM_DATA');

const DELETE_NOTE = createAction('DELETE_NOTE');

const RESET_NOTES = createAction('RESET_NOTES');

// Initial State
const initialState = {
  notes: [],
  formData: {},
  meta: {
    isFormAlter: false,
    isFetching: false,
    isFetchingFormData: false,
    isFormVisible: false,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_NOTES:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE_NOTES:
      return {
        ...state,
        notes: action.notes,
        meta: { ...state.meta, isFetching: false },
      };
    case RECEIVE_NOTE:
      return { ...state, notes: [action.note, ...state.notes] };
    case ALTER_NOTE:
      return {
        ...state,
        notes: state.notes.map((note) => {
          if (note.id === action.note.id) {
            return action.note;
          }

          return note;
        }),
      };
    case DELETE_NOTE:
      return {
        ...state,
        notes: state.notes.filter((note) => note.id !== action.id),
      };
    case SHOW_FORM:
      return {
        ...state,
        meta: {
          ...state.meta,
          isFormAlter: !!action.note_id,
          isFormVisible: true,
        },
      };
    case HIDE_FORM:
      return { ...state, meta: { ...state.meta, isFormVisible: false } };
    case REQUEST_FORM_DATA:
      return { ...state, meta: { ...state.meta, isFetchingFormData: true } };
    case RECEIVE_FORM_DATA:
      return {
        ...state,
        formData: action.formData,
        meta: {
          ...state.meta,
          isFetchingFormData: false,
        },
      };
    case CLEAR_FORM_DATA: {
      return {
        ...state,
        formData: {},
        meta: {
          ...state,
          isFetchingFormData: false,
        },
      };
    }
    case RESET_NOTES:
      return initialState;
    default:
      return state;
  }
}

/**
 * Action Creators
 */
export const requestNotes = () => ({ type: REQUEST_NOTES });
export const receiveNotes = (notes) => ({ type: RECEIVE_NOTES, notes });
export const receiveNote = (note) => ({ type: RECEIVE_NOTE, note });
export const alterNote = (note) => ({ type: ALTER_NOTE, note });
export const requestFormData = () => ({ type: REQUEST_FORM_DATA });
export const receiveFormData = (formData) => ({
  type: RECEIVE_FORM_DATA,
  formData,
});
export const deleteNote = (id) => ({ type: DELETE_NOTE, id });
export const clearFormData = () => ({ type: CLEAR_FORM_DATA });
export const resetNotes = () => ({ type: RESET_NOTES });

// Thunks
export const getNotes = (people_id, filter) => (dispatch) => {
  dispatch(requestNotes());

  return notesService.getNotesPeople(people_id, filter).then(({ data }) => {
    dispatch(receiveNotes(data));
    return data;
  });
};

export const removeNote =
  ({ id, people_id }) =>
  (dispatch) =>
    notesService.remove(id).then(() => {
      Alert.success('Anotação removida');
      dispatch(deleteNote(id));
    });

export const handleTogglePinNote = (note, people_id) => (dispatch) =>
  notesService
    .updatePin({ ...note, is_pinned: !note.is_pinned })
    .finally(() => {
      // Atualiza a lista depois que marca como
      getNotes(people_id)(dispatch);
    });

export const handleFormSubmit = (values) => (dispatch) => {
  if (values.id) {
    return notesService.update(values);
  }

  // Cadastrar
  return notesService.create(values);
};

export const clearNotes = () => (dispatch) => dispatch(resetNotes());
