import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Field,
  formValueSelector,
  initialize,
  reduxForm
} from 'redux-form';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import ListOptions from 'components/ListOptions';
import Container from 'pages/Site/components/ContainerSite';
// Components
import Alert from 'components/Alert';
import { CarouselSelect, Input, Select } from 'components/Form';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

class FormSuperHighlights extends React.Component {
  render() {
    const {
      highlights,
      superHighlights,
      is_super_highlights_shown,
      submitting,
      handleSubmit,
    } = this.props;

    const style = {
      opacity: is_super_highlights_shown ? 1 : 0.3,
      pointerEvents: is_super_highlights_shown ? 'auto' : 'none',
    };

    const length = highlights?.length;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="super_highlights_type"
          options={superHighlights}
          component={CarouselSelect}
          className="h-margin-bottom--15"
        />
        {is_super_highlights_shown && length < 3 && (
          <Alert color="secondary" style={{ marginBottom: '15px' }}>
            Para uma melhor aparência no site, adicione 3 destaques.
          </Alert>
        )}
        <Container>
          <ListOptions>
            <ListOptions.item
              title="Mostrar Super destaque na página inicial"
              text="Mostrar na página inicial uma galeria com os super destaques."
              renderOptions={() => (
                <Field
                  buttonTemplate
                  canRemoveSelf={false}
                  name="is_super_highlights_shown"
                  component={Select}
                  options={[
                    { value: true, label: 'Mostrar' },
                    { value: false, label: 'Esconder', _color: 'danger' },
                  ]}
                />
              )}
            />
            <ListOptions.item
              title="Título da área"
              text="Edite o título que ficará acima dos super destaques."
              style={style}
            >
              <div className="h-margin-top--10">
                <Field name="super_highlights_title" component={Input} />
              </div>
            </ListOptions.item>
          </ListOptions>
          <FixedBar style={{ left: 240 }}>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success" disabled={submitting}>
              Salvar
            </Button>
          </FixedBar>
        </Container>
      </form>
    );
  }
}

FormSuperHighlights.defaultProps = defaultProps;
FormSuperHighlights.propTypes = propTypes;

const selector = formValueSelector('FormSuperHighlights');

const mapStateToProps = (state) => ({
  is_super_highlights_shown: selector(state, 'is_super_highlights_shown'),
  super_highlights: selector(state, 'super_highlights'),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'FormSuperHighlights',
    onSubmitSuccess: (res, dispatch) => {
      // Reinicializa o formulário com os valores
      dispatch(initialize('FormSuperHighlights', res.data, false));
    },
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
  })
)(FormSuperHighlights);
