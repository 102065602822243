import { MdEmail } from 'react-icons/md';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
// Modules
import { resellerSelector } from 'modules/login';
// Components
import IconBall from 'components/IconBall';
import { ModalTemplate } from 'components/Modal';
// Helpers
import { isMacOS } from 'lib/device-helpers';
// Assets
import anydeskMacOS from 'assets/programs/anydesk-macOS.dmg';
import anydeskWindows from 'assets/programs/anydesk-windows.exe';
import { FaWhatsapp } from 'react-icons/fa';
import anydesk from './anydesk.svg';

export default function ModalSupport({
  modalConfig,
  isOpen,
  handleClose,
  modalType
}) {
  const resale = useSelector(resellerSelector);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Suporte Técnico" handleClose={handleClose}>
        {resale.phone_numbers && (
          <div className="h-flex" style={{ paddingBottom: '15px' }}>
            <div>
              <IconBall size="large" color="secondary" type="rounded">
                <FaWhatsapp />
              </IconBall>
            </div>
            <div className="h-margin-left--10">
              <h5>WhatsApp</h5>

              <div
                className=""
                style={{ marginTop: '5px' }}
                dangerouslySetInnerHTML={{
                  __html: resale.phone_numbers
                }}
              />
            </div>
          </div>
        )}
        {resale.emails && (
          <div
            className="h-flex"
            style={{
              borderTop: '1px solid #E1E6EC',
              paddingBottom: '15px',
              paddingTop: '15px'
            }}
          >
            <div>
              <IconBall size="large" color="primary" type="rounded">
                <MdEmail />
              </IconBall>
            </div>
            <div className="h-margin-left--10">
              <h5>E-mail</h5>
              <div
                style={{ marginTop: '5px' }}
                dangerouslySetInnerHTML={{
                  __html: resale.emails
                }}
              />
            </div>
          </div>
        )}

        {/* {resale.chat && !resale.is_tecimob && (
          <div
            className="h-flex"
            style={{
              borderTop: '1px solid #E1E6EC',
              paddingTop: '15px'
            }}
          >
            <div>
              <IconBall size="large" color="success" type="rounded">
                <MdChat />
              </IconBall>
            </div>
            <div className="h-margin-left--10 h-margin-bottom--15">
              <h5>Chat</h5>
              <div
                style={{ marginTop: '5px' }}
                dangerouslySetInnerHTML={{
                  __html: resale.chat
                }}
              />
            </div>
          </div>
        )} */}
        {/* {resale.is_tecimob && ( */}
        <div
          className="h-flex"
          style={{
            borderTop: '1px solid #E1E6EC',
            paddingTop: '15px'
          }}
        >
          <div>
            <IconBall size="large" color="caution" type="rounded">
              <img src={anydesk} alt="" />
            </IconBall>
          </div>
          <div className="h-margin-left--10">
            <h5>Acesso Remoto</h5>
            <div>
              <a
                download
                href={isMacOS() ? anydeskMacOS : anydeskWindows}
                className="h-link"
              >
                Clique aqui
              </a>{' '}
              para baixar o programa AnyDesk.
            </div>
          </div>
        </div>
        {/* )} */}
      </ModalTemplate>
    </Modal>
  );
}
