import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import { Row } from 'react-flexbox-grid';
import { reduxForm } from 'redux-form';
// Services
import Loading from 'components/Loading';
import FieldReceptionSources from 'containers/FieldReceptionSources';
import * as receptionSources from 'services/receptionSources';

// import { Container } from './styles';

const Form = ({ handleSubmit, data, onCancel, handleClose, submitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      {submitting && <Loading isVisible isFullScreen />}
      {data.people_count > 0 ? (
        <>
          Esta origem de captação está ligada a {data.people_count} cliente(s).
          <br />
          Defina para qual origem de captação deseja transferi-los:
          <Row className="h-margin-top--15">
            <FieldReceptionSources
              all
              xs={12}
              label="Selecione a origem da captação"
              name="reception_source_id"
              excludeIds={[data?.id]}
            />
          </Row>
        </>
      ) : (
        <p>Você tem certeza que deseja remover essa origem da captação?</p>
      )}

      <ModalFooter>
        <Button
          color="white"
          colorText="secondary"
          type="button"
          onClick={() => {
            handleClose();
            if (onCancel) onCancel();
          }}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button disabled={submitting} type="submit" color="danger">
          Remover
        </Button>
      </ModalFooter>
    </form>
  );
};

export default reduxForm({
  form: 'ModalReceptionSourceRemove',
  validate: (values) => {
    let errors = {};

    // Se tiver pessoas para deletar
    // Fica marcado como obrigatório o campo de group_id
    if (values?.people_count > 0 && !values?.reception_source_id) {
      errors.reception_source_id = ['Campo obrigatório'];
    }

    return errors;
  },
  onSubmit: async (values) => {
    // Se existir pessoas
    // tem que transferir o grupo pra outro lugar
    if (values?.people_count) {
      await receptionSources.transfer(values?.id, values?.reception_source_id);
    }

    return receptionSources.remove(values.id);
  },
  onSubmitSuccess: (response, dispatch, props) => {
    props.handleClose();

    // Verifica se tem a propriedade onSuccess
    if (props?.onSuccess) props?.onSuccess(response);
  },
})(Form);
