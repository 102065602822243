import React from 'react';
import { connect } from 'react-redux';
// Modules
import { fetchRealtors, realtorsSelectors } from './module';
// Components
import { Realtors } from 'components/PropertyFilters';

class RealtorsContainer extends React.Component {
  componentDidMount() {
    // Busca todos os estabelecimentos
    this.props.fetchRealtors();
  }

  render() {
    const { data } = this.props;

    // Verifica
    if (!data || data.length === 0) return null;

    return <Realtors options={data} />;
  }
}

const mapStateToProps = state => ({
  data: realtorsSelectors.getAll(state)
});

const mapDispatchToProps = {
  fetchRealtors
};

export default connect(mapStateToProps, mapDispatchToProps)(RealtorsContainer);
