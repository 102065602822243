import React from 'react';
import { Card, Cards, Title, Wrapper } from './styles';
import TextLoading from 'components/TextLoading';

function BoxPlan({ title, isFetching, renderAction, children }) {
  return (
    <Wrapper>
      <header>
        <Title>{title}</Title>
        {isFetching ? (
          <TextLoading width={85} height={25} />
        ) : (
          renderAction && renderAction()
        )}
      </header>
      {children}
    </Wrapper>
  );
}

BoxPlan.propTypes = {};
BoxPlan.defaultProps = {};
BoxPlan.cards = Cards;
BoxPlan.card = Card;

export default BoxPlan;
