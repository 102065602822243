import React from 'react';
import Modal from 'react-modal';
// Services
import * as dealsService from 'services/deals';
// Components
import { ModalTemplate } from 'components/Modal';
import LossReasonForm from './components/LossReasonForm';
import { useRequest } from '../../../hooks/useRequest';

function ModalLossReason({
  id,
  onSubmitSuccess,
  onSubmitFail,
  afterClose,
  modalConfig,
  isOpen,
  modalType,
  handleClose
}) {
  const { data: initialValues, isFetching } = useRequest({
    request: dealsService.getLossReasonById,
    params: id,
    initialState: {}
  });

  // Verifica se está em modo de edição a modal
  const isEditting = !!id;

  function handleSubmit(values) {
    if (isEditting) {
      return dealsService.updateLossReason(values);
    }
  }

  function handleCloseModal() {
    if (afterClose) afterClose();
  }

  function handleSubmitSuccess(...res) {
    handleClose();
    if (onSubmitSuccess) onSubmitSuccess(...res);
    handleCloseModal();
  }

  function handleSubmitFail(...res) {
    if (onSubmitFail) onSubmitFail(...res);
    handleCloseModal();
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      className={{
        base: 'Modal Modal--small'
      }}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={isFetching}
        title={
          isEditting ? 'Editar Motivo da perda' : 'Cadastrar motivo da perda'
        }
        handleClose={handleClose}
      >
        <LossReasonForm
          initialValues={initialValues}
          isEditting={isEditting}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFail}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}

ModalLossReason.defaultProps = {
  typeId: null
};

ModalLossReason.propTypes = {};

export default ModalLossReason;
