import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
// Components
import { Input } from 'components/Form';
import ListOptions from 'components/ListOptions';
// Containers
import FieldPerson from 'containers/FieldPerson';
// Helpers
import FieldPhone from 'containers/FieldPhone';

class FieldPersonInfo extends React.Component {
  get sizeField() {
    const { type } = this.props;
    return type === 'add-person' ? 12 : 6;
  }

  get renderExtraInfo() {
    const { type } = this.props;

    // Se for adicionar cliente
    // Retorna null
    if (type === 'add-person') return null;

    // Se for e-mail adiciona campo de e-mail
    if (type === 'email') {
      return (
        <Field xs={6} label="Email" name="person_email" component={Input} />
      );
    }

    // Caso não seja nenhuma das anteriores
    // fica o número do celular
    return (
      <FieldPhone
        xs={6}
        label="Celular"
        name="person_cellphone_number"
        nameDDI="person_cellphone_ddi"
        nameISO2="person_cellphone_iso2"
      />
    );
  }

  onChangePerson = (e, person) => {
    const { type, change, onChange } = this.props;

    if (onChange) onChange(person);

    // Não faz nada quando não tem os campos
    if (type === 'add-person') return null;

    // Reseta os 2 campos
    if (!person) {
      change('person_email', null);
      change('person_cellphone_number', null);
      return null;
    }

    if (type === 'email') {
      change('person_email', person.email);
      return null;
    }

    change('person_cellphone_ddi', person?.primary_phone?.ddi || '55');
    change('person_cellphone_iso2', person?.primary_phone?.iso2 || 'br');

    setTimeout(() => {
      change('person_cellphone_number', person?.primary_phone?.number || '');
    }, 200);
  };

  render() {
    const { onCreatePerson, filter } = this.props;

    return (
      <ListOptions.item>
        <Row>
          <FieldPerson
            required
            params={{
              include: 'primary_phone',
            }}
            xs={this.sizeField}
            checkGroupByName="Comprador"
            name="person"
            onCreatePerson={onCreatePerson}
            mapOptions={({ name, cellphone_number, ...option }) => ({
              ...option,
              cellphone_number,
              name,
              originalName: name,
            })}
            labelKey="name"
            parse={null}
            onChange={this.onChangePerson}
            filter={filter}
          />
          {this.renderExtraInfo}
        </Row>
      </ListOptions.item>
    );
  }
}

FieldPersonInfo.propTypes = {
  type: PropTypes.oneOf(['generate-link', 'share', 'email', 'add-person'])
    .isRequired,
  onCreatePerson: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

FieldPersonInfo.defaultProps = {
  type: null,
  onCreatePerson: null,
  change: null,
};

export default FieldPersonInfo;
