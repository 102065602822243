import React, { Component } from 'react';
import { compose } from 'redux';
import Anime from 'animejs';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from 'react-flexbox-grid';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTelegram,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
// Components
import FixedBar from 'components/FixedBar';
import Loading from 'components/Loading';
import FieldOffices from '../FieldOffices';

import Button from 'components/Button';
import { Input } from 'components/Form';
import FormBox from 'components/FormBox';
// Validate
import validate from './validate';
// HOC
import { withFormConfirmation } from 'HOC/withFormConfirmation';
// Assets
import blogger from './blogger.svg';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

class CompanyDataForm extends Component {
  componentDidMount() {
    if (window.location.hash) {
      this.scrollToElement();
    }
  }

  scrollToElement() {
    const element = document.querySelector(window.location.hash);
    const Wrapper = document.querySelector('.Wrapper__inner');

    setTimeout(() => {
      Anime({
        targets: Wrapper,
        scrollTop: element.offsetTop,
        duration: 500,
        easing: 'easeInOutQuad',
      });
    }, 100);
  }

  /**
   * Pega todos os telefones pelo número do office
   * @param index
   * @returns {Array}
   */
  getPhonesByIndex(index) {
    try {
      return this.props.offices[index].phones;
    } catch {
      return [];
    }
  }

  /**
   * Lida com a troca de telefone principal
   * @param indexOffice - indice do array dos escritórios
   * @param index - indice dos telefones
   * @returns {Function}
   */
  handleMainPhone = (indexOffice, index) => async (e, value) => {
    let phones = this.getPhonesByIndex(indexOffice).map((phone) => ({
      ...phone,
      main: false,
    }));

    // Deixa todos os telefones como main === false
    await this.props.change(`offices[${indexOffice}].phones`, phones);

    // Deixa o telefone main ativo
    await this.props.change(
      `offices[${indexOffice}].phones[${index}].main`,
      true
    );
  };

  handleChangeCepByIndex = (index) => async (e, value, prevValue) => {
    const { handleGetLocation, change } = this.props;

    if (!value || value === prevValue) return false;
    if (value.length === 9) {
      const { state, city, neighborhood, street_address, street_number } =
        await handleGetLocation(value);

      if (state) change(`offices[${index}].state_id`, state.id);

      if (city) change(`offices[${index}].city_id`, city.id);

      if (neighborhood)
        change(`offices[${index}].neighborhood_id`, neighborhood.id);

      if (street_address)
        change(`offices[${index}].address_street`, street_address);

      if (street_number)
        change(`offices[${index}].address_number`, street_number);
    }
  };

  handleDeleteLogo = () => {};

  isLegalPerson = () => {
    const { type } = this.props;
    return type === 2 || type === '2';
  };

  handleChangeTypePerson = () => {
    const { change } = this.props;

    change('cpf_cnpj', null);
  };

  render() {
    const { handleSubmit, isFetching, change, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} style={{ position: 'relative' }}>
        <Loading isVisible={isFetching} isFullComponent />

        <FieldArray
          offices={this.props.offices}
          change={change}
          name="offices"
          handleMainPhone={this.handleMainPhone}
          component={FieldOffices}
          handleChangeCepByIndex={this.handleChangeCepByIndex}
          rerenderOnEveryChange
        />

        <FormBox title="Redes Sociais" id="redes-sociais">
          <Row>
            <Field
              xs={4}
              label={
                <>
                  <FaFacebook /> Facebook
                </>
              }
              name="social_medias.facebook"
              component={Input}
              placeholder="Insira o link do seu perfil"
            />
            <Field
              xs={4}
              label={
                <>
                  <FaInstagram /> Instagram
                </>
              }
              name="social_medias.instagram"
              component={Input}
              placeholder="Insira o link do seu perfil"
            />
            <Field
              xs={4}
              label={
                <>
                  <FaTwitter /> Twitter
                </>
              }
              name="social_medias.twitter"
              component={Input}
              placeholder="Insira o link do seu perfil"
            />
            <Field
              xs={4}
              label={
                <>
                  <FaYoutube /> Youtube
                </>
              }
              name="social_medias.youtube"
              component={Input}
              placeholder="Insira o link do seu perfil"
            />
            <Field
              xs={4}
              label={
                <>
                  <FaLinkedin /> Linkedin
                </>
              }
              name="social_medias.linkedin"
              component={Input}
              placeholder="Insira o link do seu perfil"
            />
            <Field
              xs={4}
              label={
                <>
                  <img
                    src={blogger}
                    alt="blogger"
                    width={14}
                    style={{ display: 'inline-block', verticalAlign: 'center' }}
                  />{' '}
                  Blog
                </>
              }
              name="social_medias.blog"
              component={Input}
              placeholder="Insira o link do seu blog"
            />
          </Row>
          <Row>
            <Field
              xs={4}
              label={
                <>
                  <FaTelegram /> Telegram
                </>
              }
              name="social_medias.telegram"
              component={Input}
              placeholder="Insira o link do seu telegram"
            />
            <Field
              xs={4}
              label={
                <>
                  <FaTiktok /> Tiktok
                </>
              }
              name="social_medias.tiktok"
              component={Input}
              placeholder="Insira o link do seu tiktok"
            />
          </Row>
        </FormBox>

        <FixedBar style={{ left: 242 }}>
          <Button disabled={isFetching} color="white" colorText="secondary">
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button
            disabled={isFetching || submitting}
            type="submit"
            color="success"
          >
            Salvar
          </Button>
        </FixedBar>
      </form>
    );
  }
}

CompanyDataForm.defaultProps = defaultProps;
CompanyDataForm.propTypes = propTypes;

const selector = formValueSelector('CompanyDataForm');

const mapStateToProps = (state) => ({
  image: selector(state, 'image'),
  file_url: selector(state, 'file_url'),
  header_background: selector(state, 'header_background'),
  phones: selector(state, 'phones'),
  offices: selector(state, 'offices'),
  state: selector(state, 'state_id'),
  city: selector(state, 'city_id'),
  type: selector(state, 'type'),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'CompanyDataForm',
    keepDirtyOnReinitialize: false,
    enableReinitialize: true,
    validate,
  }),
  withFormConfirmation()
)(CompanyDataForm);
