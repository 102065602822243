import api, { apiStorage } from 'services';
import Alert from 'react-s-alert';
import { responseMessage } from 'lib/service-helpers';

export const getOne = (id) => api.getOne('settings/sites/posts', id);

export const getList = (params) =>
  api.getList('settings/sites/posts', {
    ...params,
    sort: 'order',
  });

export const changeStatus = (id, status) =>
  api.updateWithFile('settings/sites/posts', { id, status });

export const removeImages = async ({ id, file_url, cover_file_url }) => {
  if (!cover_file_url) {
    await apiStorage.delete(`sites/posts/${id}/cover-image`);
  }

  if (!file_url) {
    await apiStorage.delete(`sites/posts/${id}/image`);
  }
};

export const uploadImages = async ({ id, image, cover_image }) => {
  try {
    if (cover_image) {
      await apiStorage.uploadFile(`sites/posts/${id}/cover-image`, {
        image: cover_image,
      });
    }

    if (image) {
      await apiStorage.uploadFile(`sites/posts/${id}/image`, {
        image,
      });
    }
  } catch (err) {
    Alert.success('Erro ao salvar as imagens');
    throw err;
  }
};

export const create = async ({ image, cover_image, ...data }) => {
  try {
    // Cria o post primeiro
    const res = await api.create('settings/sites/posts', data);

    // Envia as imagens
    await uploadImages({ id: res?.data?.id, image, cover_image });

    Alert.success('Postagem criada');
  } catch (err) {
    throw err;
  }
};

export const update = async ({
  id,
  cover_image,
  image,
  file_url,
  cover_file_url,
  ...data
}) => {
  try {
    if (!image || !cover_image)
      await removeImages({ id, file_url, cover_file_url });

    // Envia as imagens
    await uploadImages({ id, image, cover_image });

    // Depois salva o post
    return api.create(`settings/sites/posts/${id}`, data);
  } catch (err) {
    throw err;
  }
};

export const order = (post) => api.updateWithFile('settings/sites/posts', post);

export const remove = (id) =>
  api
    .delete('settings/sites/posts', id)
    .then(responseMessage('Postagem removida'));
