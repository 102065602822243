import React, { useContext } from 'react';
import { BankContext } from '../../BankProvider';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import { MdArrowDropDown } from 'react-icons/md';
import ReactToggle from 'react-toggle';
// import PropTypes from 'prop-types';
// import {} from './styles';

function TableBanks() {
  const { banks, toggleStatus, handleEditById, handleRemoveById } = useContext(
    BankContext
  );

  return (
    <table className="Table h-margin-top--15" style={{ marginBottom: '90px' }}>
      <thead>
        <tr>
          <th className="Table__cell--small">Imagem</th>
          <th>Banco</th>
          <th>Status</th>
          <th className="Table__cell--small" />
        </tr>
      </thead>
      <tbody>
        {banks.map(bank => (
          <tr key={`bank-${bank.id}`}>
            <td>
              <img
                src={bank.file_url}
                alt=""
                style={{ maxWidth: 120, maxHeight: 35 }}
              />
            </td>
            <td>{bank.name}</td>
            <td>
              <div>
                <ReactToggle
                  checked={bank.status}
                  icons={false}
                  style={{ fontSize: 11 }}
                  className="h-margin-right--5"
                  onChange={toggleStatus(bank)}
                />
                {bank.status ? ' Ativa' : ' Inativa'}
              </div>
            </td>
            <td>
              <Dropdown isDropHover>
                <Button color="white" colorText="secondary">
                  Opções <MdArrowDropDown />
                </Button>
                <Dropdown.list>
                  <Dropdown.item>
                    <button onClick={handleEditById(bank.id)}>Editar</button>
                  </Dropdown.item>
                  <Dropdown.item>
                    <button onClick={handleRemoveById(bank.id)}>Excluir</button>
                  </Dropdown.item>
                </Dropdown.list>
              </Dropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

TableBanks.defaultProps = {};
TableBanks.propTypes = {};

export default TableBanks;
