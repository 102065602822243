import { ModalClose } from 'components/Modal';
import Modal from 'react-modal';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 568px;
  padding: 30px 40px;
  background: #fff;

  h1 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
  }

  span {
    text-decoration: underline;
  }

  p:not(:last-child) {
    padding-bottom: 20px;
  }
`;

function ModalRecommendationTerms({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
}) {
  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Wrapper className="Modal__wrapper">
        <ModalClose handleClose={handleClose} />

        <h1>Do Programa Recomende e Ganhe</h1>
        <p>O sistema de recomendações é um programa do TECIMOB que recompensa com dinheiro quem recomenda a novos corretores ou imobiliárias o TECIMOB.</p>
        <p>Cada conta do TECIMOB terá uma identificação única através de um link dedicado que pode ser compartilhado com qualquer pessoa sem limites de envio.</p>
        <p>As recomendações serão válidas para os cadastros realizados <span>exclusivamente</span> através do link disponibilizado pelo CLIENTE INDICADOR.</p>
        <p>Assim que o NOVO CLIENTE realizar o cadastro através do link, o CLIENTE INDICADOR será avisado através da sua conta no Tecimob na sessão <strong>RECOMENDAÇÕES</strong>, onde poderá verificar a situação do cadastro de suas recomendações.</p>
        <p>O NOVO CLIENTE deverá ter seu cadastro junto ao órgão responsável (CRECI) válido e ativo no momento do cadastro, além de se cadastrar exclusivamente através do link disponibilizado pelo CLIENTE INDICADOR para validar a recomendação.</p>
        <p>O cadastro junto ao CRECI do NOVO CLIENTE pode estar ativo como pessoa física ou jurídica, assim como na modalidade “Provisório”.</p>
        <p>Após ser reconhecida a realização do pagamento pelo NOVO CLIENTE, o CLIENTE INDICADOR receberá o valor de R$100,00 da indicação em até 02 dias úteis na modalidade PIX através dos dados bancários cadastrados na <span>página do programa</span> e o NOVO CLIENTE receberá o valor de R$50 reais em crédito direto em sua conta do Tecimob.</p>
        <p>Caso o NOVO CLIENTE não possua CRECI no momento da contratação, o valor será estornado ao NOVO CLIENTE (caso já tenha efetuado o pagamento) e o CLIENTE INDICADOR não receberá nenhuma recompensa.</p>
        <p>Caso o NOVO CLIENTE solicite cancelamento e estorno antes do final do seu primeiro mês como cliente definitivo e o CLIENTE INDICADOR já tenha recebido a recompensa pela recomendação, o Tecimob se resguarda o direito de, na próxima indicação, descontar a recompensa da indicação não efetivada.</p>
        <p>Caso o NOVO CLIENTE realize qualquer ato que vá contra o disposto nos <a href="https://tecimob.com.br/termos-de-uso/" target="_blank" className="h-link" rel="noreferrer">TERMOS DE USO</a> do Tecimob, o CLIENTE INDICADOR poderá ter a recomendação cancelada (caso o NOVO CLIENTE não tenha efetivado o pagamento) ou descontada na próxima recomendação (caso já tenha recebido a recompensa).</p>
      </Wrapper>
    </Modal>
  );
}

export default ModalRecommendationTerms;
