import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { SelectMultiple } from '../../components/Form';
import Button from '../../components/Button';
import { parseValueSelect } from '../../lib/formHelpers';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const normalizeGroupedData = data =>
  data.map(funnel => ({
    ...funnel,
    funnel_id: funnel.id,
    funnel_name: funnel.name,
    options: funnel.stages.map(({ id, name }) => ({
      id,
      name
    }))
  }));

const FormMoveDeal = ({ funnels, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Field
      multi={false}
      label="Transferir para:"
      name="stage_id"
      component={SelectMultiple}
      valueKey="id"
      labelKey="name"
      options={normalizeGroupedData(funnels)}
      parse={value => parseValueSelect(value, 'id')}
    />
    <Button type="submit" block>
      Transferir
    </Button>
  </form>
);

FormMoveDeal.defaultProps = defaultProps;
FormMoveDeal.propTypes = propTypes;

export default reduxForm({
  form: 'FormMoveDeal',
  enableReinitialize: true
})(FormMoveDeal);
