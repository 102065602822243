import React from 'react';
import Button from 'components/Button';
import Popover from 'components/Popover';
import Form from './Form';
import useReduxForm from 'hooks/useReduxForm';
import { useDispatch } from 'react-redux';
import useFormValue from 'hooks/useFormValue';
// import { Container } from './styles';

const AddVideo = ({ color = 'success' }) => {
  const dispatch = useDispatch();
  const { change } = useReduxForm();
  const videoUrl = useFormValue('videos_url');

  const urls = videoUrl || [];

  return (
    <Popover
      width={280}
      component={Form}
      onSubmit={({ url }) => {
        dispatch(change('videos_url', [...urls, url]));
      }}
    >
      <Button color={color}>Adicionar Vídeo</Button>
    </Popover>
  );
};

export default AddVideo;
