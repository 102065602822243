import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const WrapItem = styled.div`
  .CardAction__content {
    padding: 30px;
  }
`;

const propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  image: null,
  title: null,
  text: null,
  onClick: null,
};

const Wrapper = ({ to, children, ...props }) =>
  to ? (
    <Link {...props} to={to}>
      {children}
    </Link>
  ) : (
    <WrapItem {...props}>{children}</WrapItem>
  );

const CardAction = ({ image, title, text, children, ...props }) => {
  return (
    <Wrapper
      {...props}
      className={classnames('CardAction', {
        'CardAction--pointer': !!props.onClick || !!props.to,
      })}
    >
      <header className="CardAction__header">
        <h2 className="CardAction__title h-color--primary h4">{title}</h2>
      </header>
      <div className="CardAction__content">
        <div className="CardAction__image">
          <img
            src={image}
            title={title}
            alt={title}
            className="CardAction__img"
          />
        </div>
        <p className="CardAction__text">{text}</p>
        {children}
      </div>
    </Wrapper>
  );
};

CardAction.defaultProps = defaultProps;
CardAction.propTypes = propTypes;

export default CardAction;
