import styled, { css } from 'styled-components';

export const ButtonGroup = styled.div.attrs(() => ({
  className: 'ButtonGroup'
}))`
  margin-left: 15px;
`;

export const Button = styled.button.attrs(() => ({
  className: 'ButtonGroup__action ButtonGroup__action--large',
  type: 'button',
  color: 'secondary'
}))`
  padding: 0 12px;

  ${p => {
    return (
      p.isActive &&
      css`
        color: #fff !important;
        background-color: ${p.theme?.colors?.primary} !important;
      `
    );
  }}
`;
