import React from 'react';
// Components
import Table from 'components/Table';

const propTypes = {};
const defaultProps = {};

const TableDealsClosed = ({ data, isLoading, onClick }) => (
  <Table
    type="full"
    data={data}
    isFetching={isLoading}
    renderTitle={() => (
      <tr>
        <th>Perdido em</th>
        <th>Cliente</th>
        <th>Etapa</th>
      </tr>
    )}
    renderItem={item => (
      <tr key={item.id} className="h-pointer" onClick={onClick(item)}>
        <td>30/07/17</td>
        <td>{item.name}</td>
        <td>
          {item.stage && `${item.stage.funnel.name} - ${item.stage.name}`}
        </td>
      </tr>
    )}
    noData={{
      title: 'Sem negócios',
      text: 'Nenhum negócio foi fechado'
    }}
  />
);

TableDealsClosed.propTypes = propTypes;
TableDealsClosed.defaultProps = defaultProps;

export default TableDealsClosed;
