import React from 'react';
import Timeline from 'components/Timeline';
import { Number } from './styles';
import Button from 'components/Button';

function ItemDone({ onClick }) {
  return (
    <Timeline.item
      icon={() => <Number>3</Number>}
      iconColor="secondary"
      title="Feito!"
      renderText={() => (
        <>
          <p>
            Após completar o passo acima, é só aguardar as alterações surtirem
            efeito.
          </p>
          <p style={{ marginTop: '5px' }}>
            <strong>Obs:</strong> Domínios <strong>.com.br</strong> levam de{' '}
            <strong>3 a 24 horas</strong> para ativarem, domínios{' '}
            <strong>.com</strong> de <strong>24 a 48 horas</strong>.
          </p>
          <Button onClick={onClick} color="success">
            Testar ativação agora
          </Button>
        </>
      )}
    />
  );
}

ItemDone.propTypes = {};
ItemDone.defaultProps = {};

export default ItemDone;
