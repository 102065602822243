import Button from "components/Button";
import { Description, Title, Wrapper } from "./styles";

function Option({ icon, title, description, action }) {
  return (
    <Wrapper onClick={action}>
      <img src={icon} alt={title} height="128" />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button color="success">Selecionar</Button>
    </Wrapper>
  );
}

export default Option;
