import React, { useMemo, useCallback, useState } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { Wrapper, Btn, Close } from './styles';
import { useDispatch } from 'react-redux';
import { MdClose } from 'react-icons/md';
import { changeUser } from 'modules/login';

const MessageAnotherUser = () => {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const originalUser = localStorage.getItem('original-user');

  const isAnotherUser = useMemo(() => {
    return currentUser?.id !== originalUser?.id;
  }, [currentUser, originalUser]);

  const handleChangeUser = useCallback(() => {
    dispatch(changeUser(originalUser.id));
  }, [originalUser, dispatch]);

  if (!isAnotherUser || !isOpen) return null;

  return (
    <Wrapper>
      Você está logado como <strong>{currentUser.name}</strong>.{' '}
      <Btn onClick={handleChangeUser}>Voltar para meu usuário</Btn>
      <Close onClick={() => setIsOpen(false)}>
        <MdClose />
      </Close>
    </Wrapper>
  );
};

export default MessageAnotherUser;
