import React, { useMemo } from 'react';
import MainTitle from '../../../../components/MainTitle';
import ChartPeriodForm from '../ChartPeriodForm';
import isEmpty from 'lodash/isEmpty';
import NoChart from '../NoChart';
import Container from '../../../../components/Container';
import Loading from '../../../../components/Loading';
import WrapperChart from '../WrapperChart';
import Wrapper from '../../../../components/Wrapper';

export default function WrapperReport({
  title,
  text,
  submit,
  data,
  isFetching,
  renderChart,
  renderTable,
  renderForm,
  renderNoData
}) {
  const noData = useMemo(() => {
    return isEmpty(data) && !isFetching;
  }, [data, isFetching]);

  const _render = useMemo(() => {
    if (noData) {
      if (renderNoData) return renderNoData();
      return <NoChart />;
    }
  }, [noData, renderNoData]);

  return (
    <Wrapper.container>
      <MainTitle title={title} text={text}>
        {renderForm && renderForm()}
        {submit && (
          <ChartPeriodForm onSubmit={submit} isFetching={isFetching} />
        )}
      </MainTitle>
      {_render}
      {isFetching && (
        <Container>
          <Loading isVisible={isFetching} />
        </Container>
      )}
      {!isEmpty(data) && !isFetching && (
        <Container>
          <WrapperChart>{renderChart()}</WrapperChart>
          {renderTable()}
        </Container>
      )}
    </Wrapper.container>
  );
}
