import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';
// Components
import Divider from '../Divider';
import MenuNotifications from '../MenuItems/MenuNotifications';
import MenuViewSite from '../MenuItems/MenuViewSite';
import MenuConfigs from '../MenuItems/MenuConfigs';
import MenuUser from '../MenuItems/MenuUser';
import MenuSearch from '../MenuItems/MenuSearch';

class MenuRight extends React.Component {
  get primaryDomainUrl() {
    const { user } = this.props;

    try {
      return user.primary_domain_url;
    } catch {
      return null;
    }
  }

  render() {
    const {
      hasNewNotifications,
      user,
      onOpenNotifications,
      handleClickLogoff
    } = this.props;

    return (
      <Col tagName="nav" className="Menu Menu--top Menu--white">
        <MenuSearch />
        <Divider style={{ marginLeft: '-5px', marginRight: '6px' }} />
        <MenuViewSite url={this.primaryDomainUrl} />
        <Divider />
        <MenuNotifications
          hasNewNotifications={hasNewNotifications}
          onOpenNotifications={onOpenNotifications}
        />
        <Divider />
        <MenuUser user={user} />
        <Divider />
        <MenuConfigs handleClickLogoff={handleClickLogoff} />
      </Col>
    );
  }
}

MenuRight.defaultProps = {
  hasNewNotifications: false,
  user: null,
  onOpenNotifications: null,
  handleClickNewClient: null,
  handleClickNewSchedule: null,
  handleClickLogoff: null
};

MenuRight.propTypes = {
  hasNewNotifications: PropTypes.bool,
  user: PropTypes.object,
  onOpenNotifications: PropTypes.func.isRequired,
  handleClickNewClient: PropTypes.func.isRequired,
  handleClickNewSchedule: PropTypes.func.isRequired,
  handleClickLogoff: PropTypes.func.isRequired
};

export default MenuRight;
