import React from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
// Components
import Image from 'components/Image';
import Button from 'components/Button';
import Overlay from 'components/Card/components/Overlay';
import { Col } from 'react-flexbox-grid';
// Container
import SlideAddLink from 'containers/SlideAddLink';
import { Handle } from './styles';
import { MdDragIndicator } from 'react-icons/md';

const propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    file_url: PropTypes.string,
    is_sent: PropTypes.bool.isRequired,
  }).isRequired,

  handleRemove: PropTypes.func.isRequired,
};

const defaultProps = {
  height: 120,
  data: {
    id: null,
    file_url: null,
    is_sent: false,
  },
  isEditable: false,

  // Handle Remove
  handleRemoveSlide: null,
};

const OverlayWrapper = ({ isEditable, data, handleRemoveSlide }) => (
  <Overlay className="h-hover" background>
    <Overlay.row position="center">
      <div className="h-flex__cell--grow" />
      <Overlay.item show>
        {!data.is_sent ? (
          <Button
            to={`/site/content-site/slide/update/${data.id}`}
            color="white"
            colorText="secondary"
          >
            Editar
          </Button>
        ) : (
          <SlideAddLink slideId={data.id}>
            <Button
              tagName="div"
              color="white"
              colorText="secondary"
              style={{ marginRight: 10 }}
            >
              Adicionar Link
            </Button>
          </SlideAddLink>
        )}
        <Button tagName="div" color="danger" onClick={handleRemoveSlide(data)}>
          Remover
        </Button>
      </Overlay.item>
      <div className="h-flex__cell--grow" />
    </Overlay.row>
  </Overlay>
);

const SlideItem = SortableElement(({ height, data, handleRemoveSlide }) => (
  <Col key={data.id} xs={6}>
    <div className="SlideItem" style={{ height, marginTop: 15 }}>
      <Handle>
        <MdDragIndicator color="white" size={18} />
      </Handle>
      <Image src={data.file_url} className="SlideItem__img" alt="" />
      <OverlayWrapper data={data} handleRemoveSlide={handleRemoveSlide} />
    </div>
  </Col>
));

SlideItem.propTypes = propTypes;
SlideItem.defaultProps = defaultProps;

export default SlideItem;
