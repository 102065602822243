import React, { useContext } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { MenusContext } from 'components/Menus/index';
import { Wrapper, Content, BackButton } from './styles';

const Menu = ({ cols = 2, title, canBack, children }) => {
  const { back } = useContext(MenusContext);

  return (
    <Wrapper>
      <header>
        <h1>{title}</h1>
        {canBack && (
          <BackButton onClick={back}>
            <MdArrowBack /> Voltar para Menus
          </BackButton>
        )}
      </header>
      <Content cols={cols}>{children}</Content>
    </Wrapper>
  );
};

export default Menu;
