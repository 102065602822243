import React, { memo } from 'react';
import { useRouteMatch } from 'react-router';
import Wrapper from 'components/Wrapper';
import { Helmet } from 'react-helmet';
import NavAside from 'components/NavAside';
import {
  MdChat,
  MdFormatAlignLeft,
  MdHome,
  MdMap,
  MdPin,
  MdStars,
  MdViewCarousel,
} from 'react-icons/md';
import { FaUsers, FaYoutube } from 'react-icons/fa';
import Routes from './Routes';

// import { Wrapper } from './styles';

const ContentSite = () => {
  const { path } = useRouteMatch();

  return (
    <Wrapper full>
      <Helmet>
        <title>Site - Conteúdo do site</title>
      </Helmet>
      <NavAside>
        <NavAside.link icon={MdViewCarousel} to={`${path}/slides`}>
          Slide
        </NavAside.link>
        <NavAside.link icon={MdStars} to={`${path}/super-highlights`}>
          Super Destaque
        </NavAside.link>
        <NavAside.link icon={MdHome} to={`${path}/featured-lists`}>
          Imóveis página inicial
        </NavAside.link>
        <NavAside.link icon={MdFormatAlignLeft} to={`${path}/posts`}>
          Postagens
        </NavAside.link>
        <NavAside.link icon={FaYoutube} to={`${path}/videos`}>
          Vídeos
        </NavAside.link>
        <NavAside.link icon={MdChat} to={`${path}/testimonials`}>
          Depoimentos
        </NavAside.link>
        <NavAside.link icon={FaUsers} to={`${path}/partners`}>
          Parceiros
        </NavAside.link>
        <NavAside.link icon={MdPin} to={`${path}/map`}>
          Mapa
        </NavAside.link>
        <NavAside.link icon={MdMap} to={`${path}/locations`}>
          Postagens sobre regiões
        </NavAside.link>
      </NavAside>
      <Wrapper.inner>
        <Wrapper.container style={{ paddingBottom: '50px' }}>
          <Routes />
        </Wrapper.container>
      </Wrapper.inner>
    </Wrapper>
  );
};

export default memo(ContentSite);
