import Layout, { InnerContainer } from '../../components/Layout';
import DealsByStage from './components/DealsByStage';
import FormPeriod from './components/FormPeriod';
import ReasonsOfLoss from './components/ReasonsOfLoss';
import LostByStage from './components/LostByStage';
// import Filter from 'pages/Crm/pages/People/components/Filter';
import Filter from './components/Filter';
import Counts from './components/Counts';
import React from 'react';
import BoxHelp from 'components/BoxHelp';

function Dashboard() {
  return (
    <Layout title="Visão geral" renderActions={() => <Filter />}>
      <InnerContainer>
        <BoxHelp
          storageKey="crm-2-dashboard"
          topics={[
            {
              text: 'Adicionar negócio ao CRM',
              videoUrl: 'https://www.youtube.com/watch?v=JD_xa23a-3s',
              link: 'https://scribehow.com/shared/Adicionar_negocio_ao_CRM__EpWFnPlYQEOY-JcoqXvguQ',
            },
            {
              text: 'Criar Funil',
              videoUrl: 'https://youtu.be/UXU6XHN3Njg',
              link: 'https://scribehow.com/shared/Criar_Funil_no_CRM__ANSr0P5eRvWvMsr1cSsf8g?referrer=documents',
            },
            {
              text: 'Editar funil',
              videoUrl: 'https://youtu.be/1AITnfcfGso',
              link: 'https://scribehow.com/shared/Editar_Funil_do_CRM_migrar_dados_excluir_etapa_e_funil__KKlkpjU-TdSwq9kdZNET0Q?referrer=documents',
            },
            {
              text: 'Radar de oportunidades',
              videoUrl: 'https://www.youtube.com/watch?v=5GdzEX5EGxo',
              link: 'https://scribehow.com/shared/Radar_de_oportunidades__q6NpL_N3Q3qsQA1NTTt_bw',
            },
            {
              text: 'Distribuição padrão de funil por negócio',
              videoUrl: 'https://youtu.be/7KtqaEfScXU',
              link: 'https://scribehow.com/shared/Distribuicao_padrao_de_funil_por_negocio__akOppQrVRVuNZFNVL02WaQ?referrer=documents',
            },
          ]}
          mainTopic={{
            text: 'Apresentação CRM imobiliário Tecimob',
            videoUrl: 'https://youtu.be/8DX7mudPCNs',
          }}
          helpLink="https://tecimob.com.br/ajuda/crm-2/"
        />
        <DealsByStage />
        <FormPeriod />
        <Counts />
        <LostByStage />
        <ReasonsOfLoss />
      </InnerContainer>
    </Layout>
  );
}

export default Dashboard;
