import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import Container from 'pages/Site/components/ContainerSite';
import FixedBar from 'components/FixedBar';
import Table from 'components/Table';
import {
  Input,
  CarouselSelect,
  ColorPicker,
  FieldBool,
  Toggle,
} from 'components/Form';
import ListOptions from 'components/ListOptions';
import useFormValue from 'hooks/useFormValue';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
};

const FormSearch = ({
  components,
  fields,
  handleSubmit,
  handleStatusChange,
  submitting,
  pristine,
  submit,
  searchFields,
}) => {
  const filterFields = useFormValue('filter_fields');

  return (
    <form onSubmit={handleSubmit}>
      {components && (
        <Field
          name="search_type"
          component={CarouselSelect}
          options={components}
          className="h-margin-bottom--15"
        />
      )}
      <Container padding style={{ marginTop: '15px', marginBottom: '15px' }}>
        <ListOptions>
          <ListOptions.item
            title="Título da pesquisa"
            text="Edite o título que ficará acima dos campos da pesquisa."
          >
            <div className="h-margin-top--10">
              <Field name="search_title" component={Input} />
            </div>
          </ListOptions.item>
        </ListOptions>
      </Container>
      <Container>
        <Table
          data={fields}
          renderTitle={() => (
            <tr>
              <th>Nome</th>
              <th>Status</th>
            </tr>
          )}
          renderItem={(search, index) => {
            const isType = search.title === 'Tipo do Imóvel';

            return (
              <tr>
                <td>{search.title}</td>
                <td>
                  <div className="h-flex h-flex--center-center">
                    <div className="h-flex__cell--grow">
                      <div className="h-flex h-flex--center-center">
                        <Field
                          name={`search_fields[${index}].is_active`}
                          component={Toggle}
                          onChange={() => {
                            setTimeout(() => {
                              submit();
                            }, 200);
                          }}
                        />
                        <span className="h-margin-left--10">
                          {searchFields?.[index]?.is_active
                            ? ' Ativa'
                            : ' Inativa'}
                        </span>
                      </div>
                    </div>
                    {isType && (
                      <div className="h-flex h-flex--center-center">
                        <span className="h-margin-right--10">
                          Mostrar Subtipos?
                        </span>
                        <FieldBool
                          style={{ width: '135px' }}
                          name="has_subtype_filter"
                        />
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            );
          }}
        />
      </Container>
      <Container padding style={{ marginTop: '15px' }}>
        <ListOptions>
          <ListOptions.item
            title="Últimas pesquisas realizadas"
            text="Mostrar as últimas pesquisas realizadas pelo cliente, para refazer a pesquisa em 1 clique"
            renderOptions={() => <FieldBool name="last_searches_shown" />}
          />
          <ListOptions.item
            title="Cor do fundo da pesquisa"
            text="Escolha a cor que será utilizada no fundo da pesquisa do seu site."
            renderOptions={() => (
              <Field
                name="search_background"
                component={ColorPicker}
                position="top"
              />
            )}
          />
        </ListOptions>
      </Container>
      <Container style={{ marginTop: '15px' }}>
        <h4 style={{ padding: '15px 20px 0 20px' }}>Filtros</h4>
        <Table
          data={filterFields}
          renderTitle={() => (
            <tr>
              <th>Nome</th>
              <th>Status</th>
            </tr>
          )}
          renderItem={(filter, index) => {
            return (
              <tr>
                <td>{filter.title}</td>
                <td>
                  <div className="h-flex h-flex--center-center">
                    <Field
                      name={`filter_fields[${index}].is_active`}
                      component={Toggle}
                      onChange={() => {
                        setTimeout(() => {
                          submit();
                        }, 200);
                      }}
                    />
                    <span className="h-margin-left--10">
                      {filterFields?.[index]?.is_active ? ' Ativa' : ' Inativa'}
                    </span>
                  </div>
                </td>
              </tr>
            );
          }}
        />
      </Container>
      <FixedBar style={{ left: 240 }}>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar Pesquisa
        </Button>
      </FixedBar>
    </form>
  );
};

FormSearch.defaultProps = defaultProps;
FormSearch.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  if (!ownProps.form) return {};

  const selector = formValueSelector(ownProps.form);

  return {
    searchFields: selector(state, 'search_fields'),
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })
)(FormSearch);
