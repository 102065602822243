import styled from 'styled-components';

export const Caption = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  width: calc(100% - 10px);
  padding: 10px;
  border-radius: 0 0 5px 5px;

  text-align: center;

  background-color: rgba(255, 0, 0, 0.1);
  color: ${(p) => p.theme.colors.danger};
`;
