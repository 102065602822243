import React from 'react';
import Wrapper from 'components/Wrapper';
import Aside from './components/Aside';
import Routes from './Routes';
import { IdProvider } from './contexts/id';
import { AsideProvider } from 'pages/PropertyStore/contexts/aside';
import GlobalStyles from './GlobalStyles';

const PropertyStore = () => {
  return (
    <IdProvider>
      <AsideProvider>
        <Wrapper full>
          <Aside />
          <Wrapper.inner>
            <Wrapper.container>
              <Routes />
            </Wrapper.container>
          </Wrapper.inner>
        </Wrapper>
        <GlobalStyles />
      </AsideProvider>
    </IdProvider>
  );
};

export default PropertyStore;
