export default (values) => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (!values.group_id) {
    errors.group_id = REQUIRED_MSG;
  }

  if (!values.name) {
    errors.name = REQUIRED_MSG;
  }

  if (!values.cellphone) {
    errors.cellphone = REQUIRED_MSG;
  }

  return errors;
};
