import React from 'react';
import { Row } from 'react-flexbox-grid';
// Input
import { SelectMultiple } from 'components/Form';
// Containers
import FieldPerson from 'containers/FieldPerson';

const FieldPersonWrapper = ({ filter, isFetching, options }) => (
  <Row>
    <FieldPerson
      xs={12}
      label="Proprietários"
      name="people_id"
      params={{
        filter: { has_properties: filter ? filter : true },
      }}
      component={SelectMultiple}
    />
  </Row>
);

export default FieldPersonWrapper;
