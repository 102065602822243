import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
import { Textarea } from 'components/Form';
import { Container } from './styles';
import { useSelector } from 'react-redux';
import { documentSelector } from 'modules/configDocuments';

const Send = ({ history, initialize, handleSubmit }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const document = useSelector(documentSelector);

  useEffect(() => {
    if (!isInitialized && !!document) {
      initialize({
        text: document?.text,
        logo_file_url: document?.logo_file_url,
      });

      setIsInitialized(true);
    }
  }, [isInitialized, document]);

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Nova autorização de venda"
        text="Edite abaixo os dados da sua autorização de venda."
      />
      <Container>
        <Field height={649} name="text" position="top" component={Textarea} />
      </Container>
      <FixedBar>
        <FixedBar.item>
          <Button
            type="button"
            color="white"
            onClick={() => {
              history.goBack();
            }}
          >
            Voltar
          </Button>
        </FixedBar.item>
        <FixedBar.spacer />
        <FixedBar.item>
          <Button type="submit" color="success">
            Imprimir Autorização
          </Button>
        </FixedBar.item>
      </FixedBar>
    </form>
  );
};

export default reduxForm({
  form: 'SalesAuthorizationSend',
  enableReinitialize: false,
  onSubmit: (values) => {
    const KEY = '@tecimob/sales-authorization';
    localStorage.removeItem(KEY);
    // Seta o HTML no localStorage
    localStorage.setItem(KEY, values);

    window.open(`/print/sales-authorization`, '_blank');
  },
})(Send);
