import Button from 'components/Button';
import Dropdown, { Item, List as ListDrop } from 'components/Dropdown';
import { useCan } from 'hooks/useCan';
import { CellSmall } from 'pages/Portals/components/ListPortals/components/List/styles';
import { PortalsContext } from 'pages/Portals/context';
import { useContext, useEffect } from 'react';
import Clipboard from 'react-clipboard.js';
import { MdArrowDropDown } from 'react-icons/md';
import Alert from 'react-s-alert';
import ReactTooltip from 'react-tooltip';
import logoGuru from '../../logo-guru.png';
import { Right } from '../../styles';
import List from '../List';

const ListConnecteds = () => {
  const { guru, portals, connect, remove, disconnect } =
    useContext(PortalsContext);
  const canPublish = useCan({ run: 'PUBLISH_PORTAL_PROPERTY' });
  const canPublishOther = useCan({ run: 'PUBLISH_OTHER_PORTAL_PROPERTY' });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [portals]);

  return (
    <List
      title="Conectados"
      data={portals.connected}
      renderActions={(portal) => (
        <>
          <Button
            disabled={!canPublish && !canPublishOther}
            color="primary"
            to={`/portals/${portal?.portal_real_estate?.id}`}
            onClick={(e) => {
              if (!canPublish && !canPublishOther) {
                e.preventDefault();
              }
            }}
          >
            Selecionar imóveis
          </Button>
          {portal.name === 'Facebook' ? (
            <Button
              disabled={!canPublish && !canPublishOther}
              color="danger"
              onClick={disconnect(portal)}
            >
              Desconectar
            </Button>
          ) : (
            <Dropdown isDropHover>
              <Button
                color="white"
                colorText="primary"
                style={{ width: '118px' }}
              >
                Opções <MdArrowDropDown />
              </Button>
              <ListDrop>
                <Item>
                  <button onClick={connect(portal)}>Editar</button>
                </Item>
                <Item>
                  <Clipboard
                    data-clipboard-text={portal?.portal_real_estate?.xml_url}
                    onClick={() => Alert.success('URL Copiada')}
                  >
                    Copiar URL Imóveis
                  </Clipboard>
                </Item>
                {portal.leads_link && (
                  <Item>
                    <Clipboard
                      data-clipboard-text={portal.leads_link}
                      onClick={() => Alert.success('URL Copiada')}
                    >
                      Copiar URL Leads
                    </Clipboard>
                  </Item>
                )}
                <Item>
                  <button
                    className="h-color--danger"
                    onClick={disconnect(portal)}
                  >
                    Desconectar
                  </button>
                </Item>
                {!!portal.portal_model_id && (
                  <Item onClick={remove(portal)}>
                    <button>Excluir</button>
                  </Item>
                )}
              </ListDrop>
            </Dropdown>
          )}
        </>
      )}
    >
      {guru.isConnected && (
        <tr>
          <td>
            <img src={logoGuru} alt="" />
          </td>
          <CellSmall>
            <Right>
              <Button
                disabled={!canPublish && !canPublishOther}
                onClick={guru.disconnect}
                color="danger"
              >
                Desconectar
              </Button>
            </Right>
          </CellSmall>
        </tr>
      )}
    </List>
  );
};

export default ListConnecteds;
