import React from 'react';
// Components
import ItemNav from '../../ItemNav';
import { useCan } from 'hooks/useCan';

function MenuFinancial() {
  const canShowFinancial = useCan({
    run: 'VIEW_FINANCIAL',
  });

  if (!canShowFinancial) return null;

  return (
    <ItemNav
      active="/financial/"
      label="Financeiro"
      to="/financial/dashboard"
      exact
    />
  );
}

export default MenuFinancial;
