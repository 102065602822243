import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  & + & {
    margin-top: 15px;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
export const Title = styled.h4``;
