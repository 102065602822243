import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  gap: 10px;

  .Select {
    height: 30px;
  }

  .Select-control {
    height: 30px;

    .Select-placeholder {
      line-height: 28px;
    }

    .Select-value {
      line-height: 28px !important;

      .Select-value-label {
        line-height: 28px;
      }
    }

    .Select-input {
      height: 28px;
    }
  }

  .GroupControl {
    margin-left: 0;
  }

  .FormControl,
  .GroupControl__item--divider {
    height: 30px;
    line-height: 30px;
  }

  .FormGroup {
    margin-top: 0;
    margin-bottom: 0;
  }

  .FormControl,
  .Button {
    height: 30px;
    line-height: 30px;
  }

  .GroupControl__item--divider {
    line-height: 30px;
    height: 30px;
  }

  .ButtonGroup__action {
    height: 30px;
    line-height: 26px;
  }
`;
