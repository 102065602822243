import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
`;

export const Text = styled.div`
  margin-left: 15px;
`;

export const Actions = styled.div`
  .FormGroup {
    margin-bottom: 0;
  }
`;
