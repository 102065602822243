import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  border: 1px solid ${(p) => p.theme.color};
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const Text = styled.div`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #fff;
  padding: 12px 20px;
  flex: 1 0;
  min-width: 1px;
  font-size: 14px;

  strong {
    color: ${(p) => p.theme.color};
  }
`;

export const Badge = styled.button`
  font-weight: bold;
  padding: 0 33px;
  flex-shrink: 0;
  background: ${(p) => p.theme.color};
  color: #fff;
  font-size: 14px;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
`;

export const Action = styled(Badge)`
  padding: 0 33px;
  flex-shrink: 0;
  background: ${(p) => p.theme.color};
  color: #fff;
  font-size: 14px;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
