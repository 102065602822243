import qs from 'qs';
import sort from 'sort-by-distance';

const BASE_URL = 'https://www.google.com/maps';
const API_VERSION = 1;

/**
 * Conversão de zoom do streetview para Fov
 * https://stackoverflow.com/questions/35327235/google-street-view-zoom-to-fov
 * @param zoom
 * @returns {number}
 */
export const convertStreetviewZoomToFov = zoom => 180 / Math.pow(2, zoom);

/**
 * Retorna os parametros default da querystring
 * @param params
 * @returns {string}
 */
export const googleParams = params =>
  qs.stringify({ api: API_VERSION, ...params }, { addQueryPrefix: true });

/**
 * Search
 * launch a Google Map that displays a pin for a specific place, or perform a general search and launch a map to display the results:
 * https://developers.google.com/maps/documentation/urls/guide#search-action
 * @example
 * googleSearch({
 *   query: 'City Hall, New York, NY'
 * })
 * @param params
 * @return {string}
 */
export const googleSearch = params =>
  `${BASE_URL}/search/${googleParams(params)}`;

/**
 * Directions
 * Request directions and launch Google Maps with the results
 * https://developers.google.com/maps/documentation/urls/guide#directions-action
 * @example
 * googleDirections({
 *   origin: 'City Hall, New York, NY',
 *   destination: 'City Hall, New York, NY',
 *   travelmode: 'driving',
 * })
 * @param params
 * @return {string}
 */
export const googleDirections = params =>
  `${BASE_URL}/dir/${googleParams(params)}`;

/**
 * Routes
 * @param destinations
 * @returns {Promise<string>}
 */
export const googleRoutes = async destinations => {
  const dest = destinations.reduce((destination, [lat, lng]) => {
    if (lat === null || lng === null) return destination;
    destination += `${lat},${lng}/`;
    return destination;
  }, '');

  return `${BASE_URL}/dir/${dest}`;
};

const removeWaypointByIndex = i => (waypoint, index) => index !== i;
const pointToString = point => `${point.lat},${point.lng}`;
const normalizeWaypoints = (value, waypoint) => {
  value = value + `${pointToString(waypoint)}|`;
  return value;
};

const getWaypoints = (waypoints, lastIndex) => {
  const _waypoints = waypoints
    .filter(removeWaypointByIndex(lastIndex))
    .reduce(normalizeWaypoints, '');
  return _waypoints.slice(0, -1);
};

/**
 *
 * @param origin - { lat: Number, lng: Number }
 * @param points - [{ lat: Number, lng: Number }, ...]
 * @param opts
 * @returns {Promise<void>}
 */
export const googleRouteDirections = async (origin, points) => {
  try {
    if (!origin) throw new Error('Parametro origin obrigatório');
    if (!points || points.length <= 0)
      throw new Error('Deve ter pelo menos um ponto no mapa');

    const pointsLength = points.length;

    let params = {
      origin: pointToString(origin),
      travelmode: 'driving'
    };

    // Variavel aonde fica armazenado o destino
    let destination;

    if (pointsLength === 1) {
      destination = pointToString(points[0]);
    } else {
      delete points[0];

      // Pega o último indice do array
      const lastIndex = pointsLength - 1;

      // Seta o destino como o waypoint mais longe que tem
      destination = pointToString(points[lastIndex]);

      // Remove o ultimo indice
      // e deixa o waypoint no padrão string
      let waypoints = getWaypoints(points, lastIndex);

      params = {
        ...params,
        waypoints
      };
    }

    // Adiciona o destino nos parametros
    params = {
      ...params,
      destination
    };

    return `${BASE_URL}/dir/${googleParams(params)}`;
  } catch (err) {
    return {};
  }
};

/**
 *
 * @param origin - { lat: Number, lng: Number }
 * @param points - [{ lat: Number, lng: Number }, ...]
 * @param opts
 * @returns {Promise<void>}
 */
export const googleRouteSortedDirections = async (
  origin,
  points,
  opts = { yName: 'lat', xName: 'lng' }
) => {
  try {
    if (!origin) throw new Error('Parametro origin obrigatório');
    if (!points || points.length <= 0)
      throw new Error('Deve ter pelo menos um ponto no mapa');

    const pointsLength = points.length;

    let params = {
      origin: pointToString(origin),
      travelmode: 'driving'
    };

    // Variavel aonde fica armazenado o destino
    let destination;

    if (pointsLength === 1) {
      destination = pointToString(points[0]);
    } else {
      // Pega o último indice do array
      const lastIndex = pointsLength - 1;

      // Ordena todos os pontos
      let waypoints = sort(origin, points, opts);

      // Seta o destino como o waypoint mais longe que tem
      destination = pointToString(waypoints[lastIndex]);

      // Remove o ultimo indice
      // e deixa o waypoint no padrão string
      waypoints = getWaypoints(waypoints, lastIndex);

      params = {
        ...params,
        waypoints
      };
    }

    // Adiciona o destino nos parametros
    params = {
      ...params,
      destination
    };

    return `${BASE_URL}/dir/${googleParams(params)}`;
  } catch (err) {
    return {};
  }
};

/**
 * Display a map
 * launch Google Maps with no markers or directions
 * https://developers.google.com/maps/documentation/urls/guide#map-action
 * @example
 * googleDisplayMap({
 *   center: '-33.8569,151.2152',
 *   zoom: '15',
 *   basemap: 'roadmap', // satellite, terrain
 *   layer: 'none', // transit, traffic, bicycling
 * })
 * @param params
 * @return {string}
 */
export const googleDisplayMap = params =>
  `${BASE_URL}/@${googleParams({ map_action: 'map', ...params })}`;

/**
 * Display a Street View panorama
 * launch an interactive panorama image
 * https://developers.google.com/maps/documentation/urls/guide#street-view-action
 * @example
 * googleStreetViewPanorama({
 *   // Precisa de viewpoint ou um Panorama id
 *   viewpoint: '46.414382,10.013988',
 *
 *   // Panorama id
 *   // https://developers.google.com/maps/documentation/urls/guide#pano-id
 *   pano: '46.414382,10.013988',
 *
 *   // Parametros opcionais
 *   heading: '-180',
 *   pitch: '0',
 *   fov: '10'
 * })
 * @param params
 * @return {string}
 */
export const googleStreetViewPanorama = ({ fov, ...params }) => {
  const anotherParams = googleParams({
    map_action: 'pano',
    ...params,
    fov: fov && convertStreetviewZoomToFov(fov)
  });

  return `${BASE_URL}/@${anotherParams}`;
};
