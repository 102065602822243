import {
  locationSelectorsCreate,
  reducerLocation,
  thunksCreator
} from 'modules/location';

// Cria os thunks da localização
export const Thunks = thunksCreator('modalLocation');

// Cria os selectors de localização
export const Selectors = locationSelectorsCreate('modalLocation');

// Exporta o reducer de localização
export default reducerLocation('modalLocation');
