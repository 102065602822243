// import styles from './styles'

import { Field } from 'redux-form';
import { Input, Select } from 'components/Form';
import React from 'react';
import GroupControl, { GroupItem } from 'components/GroupControl';
import { Col } from 'react-flexbox-grid';

export function Measure({ xs, name, label = 'Área', ...props }) {
  return (
    <Col xs={xs}>
      <GroupControl label={label}>
        <GroupItem>
          <Field
            name={`${name}.value`}
            component={Input}
            valueFormat
            prefix=""
          />
        </GroupItem>
        <GroupItem size="small">
          <Field
            name={`${name}.measure`}
            placeholder=""
            component={Select}
            options={[
              { label: 'm²', value: 'm²' },
              { label: 'ha', value: 'ha' },
            ]}
          />
        </GroupItem>
      </GroupControl>
    </Col>
  );
}
