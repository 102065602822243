import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

export const useNavLink = ({
  to,
  rest,
  active,
  disabled,
  className = 'NavAside__item',
  activeClassName = 'NavAside__item--is-active',
}) => {
  const Component = to ? NavLink : 'a';

  // Props do componente que engloba o conteudo
  let componentProps;

  if (!to) {
    componentProps = {
      ...rest,
      className: classnames(className, {
        isDisabled: disabled,
        [activeClassName]: active,
      }),
    };
  } else {
    componentProps = {
      ...rest,
      to,
      activeClassName,
      className: classnames(className, { isDisabled: disabled }),
    };
  }

  return { Component, componentProps };
};
