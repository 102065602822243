import Button from 'components/Button';
import { ModalContent, ModalTitle } from 'components/Modal';
import { Content } from './styles';
import { useMemo } from 'react';

const PaymentError = ({ hasError, setHasError }) => {
  const textError = useMemo(() => {
    try {
      return <p>{hasError?.response?.data?.alerts[0]}</p>;
    } catch {
      return (
        <p>
          Verifique se existe alguma mensagem no aplicativo do seu banco,
          <br />
          ou entre em contato com sua operadora de cartão e tente novamente
        </p>
      );
    }
  }, [hasError]);

  return (
    <>
      <ModalTitle text="Falha">Pagamento</ModalTitle>

      <ModalContent>
        <Content>
          <h2>Falha na cobrança</h2>
          {textError}
          <Button
            color="tertiary"
            size="small"
            onClick={() => setHasError(false)}
          >
            Voltar
          </Button>
        </Content>
      </ModalContent>
    </>
  );
};

export default PaymentError;
