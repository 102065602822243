import { Field } from 'redux-form';
import PlanItem from '../PlanItem';
import { Wrapper } from './styles';

const List = () => {
  return (
    <Wrapper>
      <Field
        name="months"
        component={PlanItem}
        label="Pagamento Anual (Melhor escolha)"
        props={{ value: 12 }}
      />

      <Field
        name="months"
        component={PlanItem}
        label="Pagamento Semestral"
        props={{ value: 6 }}
      />

      <Field
        name="months"
        component={PlanItem}
        label="Pagamento Trimestral"
        props={{ value: 3 }}
      />

      <Field
        name="months"
        component={PlanItem}
        label="Pagamento Mensal"
        props={{ value: 1 }}
      />
    </Wrapper>
  );
}

export default List;
