import React from 'react';
import { ModalGeneratePostProvider } from './context';
import ModalRoot from './components/ModalRoot';

const ModalGeneratePost = (props) => {
  return (
    <ModalGeneratePostProvider property={props?.property}>
      <ModalRoot {...props} />
    </ModalGeneratePostProvider>
  );
};

export default ModalGeneratePost;
