import styled from 'styled-components';
import ImageComponent from 'components/Image';

export const Wrapper = styled.div`
  display: flex;
  padding: 15px 20px;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  flex-grow: 1;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const Number = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

export const Image = styled(ImageComponent)`
  min-width: 1px;
  flex-shrink: 0;
`;
