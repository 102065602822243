import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { SelectMultiple } from 'components/Form';
// Modules
import {
  addCondo,
  condosSelector,
  getCondos,
  isFetchingCondosSelector,
} from 'modules/condos';
// Services
import * as condosService from 'services/condos';
// Helpers
import { parseSelect } from 'lib/formHelpers';
import useFormValue from 'hooks/useFormValue';

let timeout = 0;

function FieldCondosAsync({
  reloadOnOpen,
  name,
  onChange,
  hideEmpty,
  ...props
}) {
  const dispatch = useDispatch();

  // Pega o id do condomínio que está selecionado no formulário
  const condoId = useFormValue(name);

  // Pega todos os condomínios
  const condos = useSelector(condosSelector);
  const isFetching = useSelector(isFetchingCondosSelector);

  // Busca os condomínios quando o componente é montado na tela
  useEffect(() => {
    dispatch(
      getCondos({
        limit: 15,
        offset: 1,
        sort: 'updated_at',
      })
    );
  }, [dispatch]);

  useEffect(() => {
    async function fetchCondo() {
      const hasInList =
        condos.findIndex((condo) => condo.id === condoId) !== -1;

      // Verifica se o id já não está na lista
      if (hasInList) {
        return false;
      }

      const { data: condo } = await condosService.getOne(condoId);
      dispatch(addCondo(condo));
    }

    if (condoId) {
      fetchCondo();
    }
  }, [condos, condoId]);

  const handleInputChange = useCallback(
    (value) => {
      if (!value && condos.length === 0)
        return getCondos({ offset: 1, limit: 15, sort: '-updated_at' });

      if (value.length < 2) return null;

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(getCondos({ filter: { title: value }, sort: 'by_title' }));
      }, 1000);
    },
    [condos]
  );

  return (
    <Field
      isLoading={isFetching}
      label="Condomínio"
      name={name}
      valueKey="id"
      labelKey="title"
      options={condos}
      component={SelectMultiple}
      onInputChange={handleInputChange}
      onChange={onChange}
      onOpen={() => {
        if (reloadOnOpen) {
          dispatch(
            getCondos({
              limit: 15,
              offset: 1,
              sort: 'updated_at',
            })
          );
        }
      }}
      {...props}
    />
  );
}

FieldCondosAsync.propTypes = {
  name: PropTypes.string,
  hideEmpty: PropTypes.bool,
  multi: PropTypes.bool,
};

FieldCondosAsync.defaultProps = {
  name: 'condominium_id',
  hideEmpty: false,
  multi: false,
  parse: parseSelect('id'),
};

export default FieldCondosAsync;
