import React, { memo } from 'react';
import classnames from 'classnames';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
// Components
import Button from 'components/Button';
import FileInput from '../FileInput';
import DropImageOverlay from './DropimageOverlay';
// HOC
import { withFormName } from 'HOC/withFormName';

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  component: PropTypes.node,
  bgSize: PropTypes.oneOf(['contain', 'cover']),

  fileUrlField: PropTypes.string.isRequired,
  hasRemove: PropTypes.bool,

  renderButtonSend: PropTypes.func,
  renderButtonRemove: PropTypes.func,
  renderButtonChangeImage: PropTypes.func,
};

const defaultProps = {
  name: 'image',
  bgSize: 'contain',
  multiple: false,
  component: FileInput,

  hasUrl: false,
  hasRemove: false,
  fileUrlField: 'file_url',
  text: 'Envie uma imagem',
  renderButtonRemove: ({ handleRemove }) => (
    <Button
      type="button"
      color="danger"
      className="DropImage__button"
      onClick={handleRemove}
    >
      Remover
    </Button>
  ),
  renderButtonSend: () => (
    <Button type="button" color="secondary" className="DropImage__button">
      Enviar
    </Button>
  ),
  renderButtonChangeImage: () => (
    <Button
      type="button"
      color="white"
      colorText="secondary"
      className="DropImage__button"
    >
      Trocar Imagem
    </Button>
  ),
};

class FieldImage extends React.Component {
  handleRemoveImage = (onChange) => (e) => {
    const { change, formName, fileUrlField, onRemove } = this.props;

    e.stopPropagation();

    // Limpa o campo
    onChange(null);

    // Limpa o campo que guarda o caminho da imagem já salva
    // para garantir que a imagem será deletada
    change(formName, fileUrlField, null);

    if (onRemove) onRemove();
  };

  /**
   * Renderiza o botão de remover
   * @return {Node}
   */
  renderButtonRemove = (onChange) => () => {
    const { renderButtonRemove, hasRemove } = this.props;

    // Verifica se pode mostrar o botão que remove a imagem
    if (!hasRemove) return null;

    return renderButtonRemove({
      handleRemove: this.handleRemoveImage(onChange),
    });
  };

  get renderButtonSend() {
    const { renderButtonSend } = this.props;
    return renderButtonSend && renderButtonSend();
  }

  get renderButtonChangeImage() {
    const { renderButtonChangeImage } = this.props;
    return renderButtonChangeImage && renderButtonChangeImage();
  }

  renderContent = (fileUrl) => {
    const { text } = this.props;

    return (
      <p
        className={classnames('DropImage__text', {
          'h-color--white': fileUrl,
        })}
      >
        {text}
      </p>
    );
  };

  render() {
    const {
      hasUrl,
      formName,
      fieldUrl,
      fileUrlField,
      width,
      height,
      hasRemove,
      submitButtonText,
      text,
      change,
      bgSize,
      proportion,
      imageSize = 'large',
      ...props
    } = this.props;

    return (
      <Field fileUrl={fieldUrl} {...props}>
        {({ input, input: { onChange } }) => {
          const { value } = input;

          let fileUrl = value.preview;

          if (fieldUrl) {
            fileUrl = value.preview ? value.preview : fieldUrl;
          }

          if (fileUrl) {
            return (
              <DropImageOverlay
                proportion={proportion}
                width={width}
                height={height}
                fileUrl={fileUrl}
                bgSize={bgSize}
                imageSize={imageSize}
              >
                {this.renderContent(fileUrl)}
                {this.renderButtonChangeImage}
                {this.renderButtonRemove(onChange)()}
              </DropImageOverlay>
            );
          }

          return (
            <DropImageOverlay
              proportion={proportion}
              width={width}
              height={height}
              bgSize={bgSize}
              imageSize={imageSize}
            >
              {this.renderContent()}
              {this.renderButtonSend}
            </DropImageOverlay>
          );
        }}
      </Field>
    );
  }
}

FieldImage.propTypes = propTypes;
FieldImage.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  // Seletor do formulario
  const selector = formValueSelector(ownProps.formName);

  return {
    // Busca o valor que está no campo de fileUrlField
    fieldUrl: selector(state, ownProps.fileUrlField),
  };
};

const mapDispatchToProps = {
  change,
};

export default compose(
  withFormName,
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FieldImage);
