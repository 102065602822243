import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import FieldSelectUser from 'containers/FieldSelectUser';
import { FieldDateRange } from 'components/Form';
import { Wrapper, WrapperDateRange } from './styles';
import moment from 'moment';
import useFormValue from 'hooks/useFormValue';
import { useCan } from 'hooks/useCan';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function Form({ handleSubmit, change }) {
  const canViewOthers = useCan({
    run: 'VIEW_OTHER_REPORTS',
  });

  const { start_date, end_date } = useFormValue(['start_date', 'end_date']);

  return (
    <Wrapper onSubmit={handleSubmit}>
      <WrapperDateRange>
        <FieldDateRange
          canClear={false}
          label="Intervalo"
          onChange={() => {
            change('user_id', '');
          }}
        />
      </WrapperDateRange>
      {canViewOthers && (
        <FieldSelectUser
          label="Corretor"
          style={{ width: 200 }}
          clearable={false}
          options={[{ id: '', name: 'Todos' }]}
          params={{
            filter: {
              realtor: true,
              by_people_created_between: `${start_date},${end_date}`,
            },
            sort: 'by_name',
          }}
        />
      )}
    </Wrapper>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

const startDate = moment()
  .subtract(5, 'month')
  .startOf('month')
  .format('DD/MM/YYYY');

const endDate = moment().endOf('month').format('DD/MM/YYYY');

export default reduxForm({
  form: 'ChartPeriodForm',
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: {
    start_date: startDate,
    end_date: endDate,
  },
  onChange: (values, dipatch, props) => {
    props.submit();
  },
})(Form);
