import {
  Button,
  ButtonGroup,
} from 'pages/Crm2/components/LayoutChooser/styles';
import { usePortalOptions } from 'pages/PortalsOptionSelect/context';
import { MdFormatListBulleted, MdViewColumn } from 'react-icons/md';

function ChangeLayout() {
  const { layout, setLayoutCard, setLayoutTable } = usePortalOptions();
  const isTypeCard = layout === 'card';

  return (
    <ButtonGroup>
      <Button
        onClick={() => {
          setLayoutCard();
        }}
        isActive={isTypeCard}
      >
        <MdViewColumn />
      </Button>
      <Button
        onClick={() => {
          setLayoutTable();
        }}
        isActive={!isTypeCard}
      >
        <MdFormatListBulleted />
      </Button>
    </ButtonGroup>
  );
}

export default ChangeLayout;
