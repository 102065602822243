import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import {
  Actions,
  Address,
  Price,
  Spacer,
  TextWrapper,
  Title,
  Wrapper,
} from './styles';

import { openModalProperty } from 'modules/modal';

import noImage from './no-image.png';
import TextOverflow from 'components/TextOverflow';
import * as classnames from 'classnames';

function PropertyItem({
  item,
  className,
  property: p,
  children,
  afterClose,
  renderAction,
  isDanger,
  hideActions,
  isFocused,
  canOpenProperty,
  avatarSize,
}) {
  const dispatch = useDispatch();

  function handleClick() {
    if (canOpenProperty) {
      dispatch(
        openModalProperty({
          property: { ...p },
          afterClose,
        })
      );
    }
  }

  return (
    <Wrapper
      className={classnames('h-hover', className)}
      role="button"
      isFocused={isFocused}
      isDanger={isDanger}
      onClick={handleClick}
      hasClick={canOpenProperty}
    >
      <Avatar
        size={avatarSize}
        hasZoom={false}
        image={p?.cover_image?.file_url || noImage}
      />
      <TextWrapper>
        <Title>
          {p?.type?.title} - Ref.: {p?.reference}
        </Title>
        <Price transaction={p?.transaction}>{p?.calculated_price}</Price>
        <Address>
          <TextOverflow width="100%">{p?.address_formatted}</TextOverflow>
        </Address>
        {children}
      </TextWrapper>
      {renderAction && (
        <>
          <Spacer />
          <Actions className={hideActions && 'h-hover__show'}>
            {renderAction({ item, property: p })}
          </Actions>
        </>
      )}
    </Wrapper>
  );
}

PropertyItem.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    cover_image: PropTypes.shape({
      file_url: PropTypes.string,
    }),
    type: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
    transaction: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    reference: PropTypes.string.isRequired,
    address_formatted: PropTypes.string.isRequired,
  }),
  afterClose: PropTypes.func,
  children: PropTypes.node,
  renderAction: PropTypes.func,
  isDanger: PropTypes.bool,
  hideActions: PropTypes.bool,
  canOpenProperty: PropTypes.bool,
  avatarSize: PropTypes.number,
};
PropertyItem.defaultProps = {
  avatarSize: 75,
  property: {},
  hideActions: true,
  afterClose: () => {},
  children: null,
  renderAction: null,
  isDanger: false,
  canOpenProperty: true,
};

export default PropertyItem;
