import React, { useCallback } from 'react';
import Modal from 'react-modal';
// Services
import { transferAndRemove } from 'services/deals';
// Hooks
// Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
import { plural } from '../../../lib/text';

function ModalLossReasonRemove({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,

  id,
  onSuccess,
  lostDealsCount
}) {
  const handleSubmit = useCallback(({ to_id: toId }) => {
    return transferAndRemove(id, toId);
  }, []);

  const handleSuccess = useCallback(() => {
    if (onSuccess) onSuccess();
    handleClose();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Excluir Motivo de perda" handleClose={handleClose}>
        <p className="h-color--primary h-margin-bottom--10">
          Esse motivo de perda está sendo utilizado em{' '}
          {plural(lostDealsCount, 'negócio', 'negócios')}. Escolha para qual
          motivo de perda transferi-los:
        </p>
        <Form
          reasonId={id}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalLossReasonRemove;
