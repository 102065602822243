// Services
import * as dealsService from '../../../../services/deals';
// Modules
import dataReducer from '../../../../modules/data';

// Constants
export const MODULE_NAME = 'propertyFunnel';

export const {
  reducer: propertyFunnelReducer,
  actionCreators: propertyFunnelActions,
} = dataReducer(`propertyDashboard/${MODULE_NAME}`);

// Selectors
export const dataSelector = (state) =>
  state.propertyDashboard[MODULE_NAME].data;
export const isFetchingSelector = (state) =>
  state.propertyDashboard[MODULE_NAME].meta.isFetching;

// Reducer
export const fetchPropertyFunnels = (funnelId, params) => (dispatch) => {
  dispatch(propertyFunnelActions.request());

  return dealsService.getDeals(params).then(({ data }) => {
    dispatch(propertyFunnelActions.receive(data));
    return data;
  });
};

export default propertyFunnelReducer;
