import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
// Components
import { ModalFooter, ModalTemplate } from 'components/Modal';
// Containers
import CreditCardContainer from 'containers/CreditCardContainer';
// Modules
import { openModalPayInstallment } from 'modules/modal';
import { getCards, onSubmitCreditCard } from 'modules/plan';
// Hooks
import { useRequest } from 'hooks/useRequest';
// Libs
import moment, { format } from 'lib/moment';
// Services
import * as creditsService from 'services/financial/credits';
import Divider from 'components/Divider';
import Button from 'components/Button';
// Styles
import { Details, WrapperCredit } from './styles';

export default function ModalPayInstallment({
  receivingMethod,
  creditId,
  onPaySuccess,
  onSuccess,

  modalConfig,
  isOpen,
  handleClose,
  modalType,
}) {
  const { data } = useRequest({
    request: creditsService.getOne,
    params: creditId,
    initialState: {},
  });
  const { data: cards } = useSelector(getCards);
  const dispatch = useDispatch();

  const hasCard = useMemo(() => {
    return !!cards?.length > 0;
  }, [cards]);

  const openModal = useCallback(() => {
    setTimeout(() => {
      dispatch(
        openModalPayInstallment({ creditId, receivingMethod, onPaySuccess })
      );
    }, 400);
  }, [dispatch, creditId, receivingMethod, onPaySuccess]);

  const payWithCreditCard = useCallback(async () => {
    dispatch(
      await onSubmitCreditCard({
        credit_id: creditId,
        receiving_method: 2,
      })
    );

    if (onPaySuccess) onPaySuccess();
    if (onSuccess) onSuccess();

    handleClose();
  }, [dispatch, creditId, onPaySuccess]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Pagamento de parcela com cartão"
        handleClose={handleClose}
      >
        <WrapperCredit>
          <CreditCardContainer
            onAddCardSuccess={openModal}
            onAddCardFail={openModal}
          />
        </WrapperCredit>
        <Divider spacingY={15} borderStyle="dashed" />
        <Details>
          <p>
            Preço: <strong>{data?.price}</strong>
          </p>
          <p>
            Vencimento: <strong>{data?.due_date}</strong>
          </p>
          <p>
            Data de débito: <strong>{moment().format(format.date)}</strong>
          </p>
        </Details>
        <ModalFooter>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <div className="h-flex__cell--grow" />
          <Button
            onClick={payWithCreditCard}
            disabled={!hasCard}
            color="success"
          >
            Finalizar pagamento
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}
