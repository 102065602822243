import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Person } from 'pages/Site/pages/ContentSite/pages/SuperHighlights/components/SuperhighlightList/styles';
import Button from 'components/Button';
import styled from 'styled-components';
import { DragIcon } from 'pages/Site/pages/ContentSite/pages/Posts/components/TablePosts/styles';
import TextOverflow from 'components/TextOverflow';
import { MdDelete, MdEdit } from 'react-icons/md';
// import { Wrapper } from './styles';

const Handle = styled.div`
  cursor: grab;
  margin-top: -5px;
  margin-right: 4px;
`;

const WrapInfos = styled.div`
  display: flex;
  align-items: center;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">
    <DragIcon />
  </Handle>
));

function TableRow({ data, handleClickUpdate, handleClickRemove }) {
  return (
    <tr key={data.id + data.file_url}>
      <td>
        <WrapInfos>
          <RowHandler />
          <Person className="h-text-nowrap">
            <TextOverflow width={450}>{data.title}</TextOverflow>
          </Person>
        </WrapInfos>
      </td>
      <td className="Table__cell--small">
        <div
          className="h-flex h-flex--center-center"
          style={{ justifyContent: 'flex-end' }}
        >
          {data.is_automatic === 'AUTO' ? (
            <div className="h-color--secondary h-margin-right--15">
              <strong>Galeria automática</strong>
            </div>
          ) : (
            <Button
              color="white"
              to={`/site/content-site/featured-lists/${data.id}/properties/`}
            >
              Gerenciar imóveis
            </Button>
          )}
          <Button
            color="white"
            onClick={handleClickUpdate && handleClickUpdate(data)}
            colorText="primary"
          >
            <MdEdit />
          </Button>
          <Button
            disabled={data.is_default}
            color="white"
            onClick={handleClickRemove && handleClickRemove(data)}
            colorText="danger"
          >
            <MdDelete />
          </Button>
        </div>
      </td>
    </tr>
  );
}

export default TableRow;
