import React from 'react';
import {
  Wrapper,
  WrapImage,
  Content,
  BtnDelete,
} from 'pages/EnterpriseStore/pages/Progress/components/CardPhoto/styles';
import Image from 'components/Image';
import { Field } from 'redux-form';
import { Input, Textarea } from 'components/Form';
import { MdDelete } from 'react-icons/md';

const CardPhoto = ({ name, data, handleRemoveImage }) => {
  return (
    <Wrapper>
      <WrapImage>
        <Image src={data.file_url} alt="" />
      </WrapImage>
      <Content>
        <Field label="Título" name={`${name}.title`} component={Input} />
        <Field
          height={90}
          type="simple"
          label="Descrição"
          name={`${name}.description`}
          component={Textarea}
        />
      </Content>
      <BtnDelete type="button" onClick={handleRemoveImage}>
        <MdDelete />
      </BtnDelete>
    </Wrapper>
  );
};

export default CardPhoto;
