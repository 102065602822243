import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Item from './Item';
import Divider from './Divider';
import List from './List';

class Dropdown extends Component {
  static propTypes = {
    isDropFocus: PropTypes.bool.isRequired,
    isDropHover: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    position: PropTypes.oneOf([
      'left',
      'center',
      'right',
      'left-top',
      'center-top',
      'right-top'
    ]),
    className: PropTypes.string,
    style: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node
    ])
  };

  static defaultProps = {
    isDropFocus: true,
    isDropHover: false,
    position: 'center',
    className: '',
    style: {
      dropdown: {}
    },
    children: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      open: props.open
    };
  }

  close = () => {
    this.setState({ open: false }, () => {
      if (this.props.onClose) this.props.onClose();
    });
  };

  open = () => {
    this.setState({ open: true }, () => {
      if (this.props.onOpen) this.props.onOpen();
    });
  };

  toggle = () => {
    !this.state.open ? this.open() : this.close();
  };

  handleFocusDrop = () => {
    this.open();
  };

  handleBlurDrop = () => {
    this.close();
  };

  /**
   * Lida com o evento de mouse hover do elemento
   */
  handleMouseOver = () => {
    const { isDropHover } = this.props;

    if (isDropHover) {
      this.toggle();
    }
  };

  handleMouseEnter = () => {
    this.open();
  };

  handleMouseLeave = () => {
    this.close();
  };

  render() {
    const {
      className,
      style,
      children,
      isDropFocus,
      isDropHover,
      position,
      onClick
    } = this.props;
    const { open } = this.state;

    const methods = {
      close: this.close,
      open: this.open,
      toggle: this.toggle,
      handleClose: this.close
    };

    const dropDownClassNames = classnames('Dropdown', className, {
      [`Dropdown--${position}`]: position,
      'Dropdown--focus': isDropFocus,
      'Dropdown--hover': isDropHover,
      'Dropdown--open': open,
      'Dropdown--close': !open
      //[`Dropdown--${position}`]: position,
    });

    let events = {
      onFocus: this.handleFocusDrop,
      onBlur: this.handleBlurDrop
    };

    if (isDropHover) {
      events = {
        ...events,
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave
      };
    }

    return (
      <div
        {...events}
        onClick={onClick}
        tabIndex={-1}
        className={dropDownClassNames}
        style={style}
      >
        {typeof children === 'function' ? children(methods) : children}
      </div>
    );
  }
}

Dropdown.divider = Divider;
Dropdown.item = Item;
Dropdown.list = List;

export { Divider, Item, List };
export default Dropdown;
