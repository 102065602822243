import { combineReducers } from 'redux';
// Services
import * as condosService from 'services/condos';
// Modules
import dataReducer from 'modules/data';

export const {
  reducer: propertyGroupReducer,
  actionCreators: propertyGroup,
  selectors: propertyGroupSelector
} = dataReducer(`modalPropertyGroup/properties`);

export const getProperties = state => {
  try {
    return state.modalPropertyGroup.properties;
  } catch {
    return [];
  }
};

// Thunks
export const fetchProperties = (id, filter) => dispatch => {
  dispatch(propertyGroup.request());

  return condosService
    .getGrouped(id, { filter, sort: 'calculated_price' })
    .then(({ data }) => {
      dispatch(propertyGroup.receive(data));
      return data;
    });
};

export default combineReducers({
  properties: propertyGroupReducer
});
