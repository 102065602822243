import createDataReducer from 'modules/data';
// Services
import * as characteristicsService from 'services/characteristics';

export const {
  reducer: characteristicsReducer,
  selectors: characteristicsSelectors,
  actionCreators: characteristicsActions
} = createDataReducer('filter/characteristics');

export const fetchCharacteristics = params => async dispatch => {
  if (!params?.type_id) return false;

  dispatch(characteristicsActions.request());
  const { data: characteristics } = await characteristicsService.getList(
    params
  );
  dispatch(characteristicsActions.receive(characteristics));
};

export default characteristicsReducer;
