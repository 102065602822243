import React, { useState } from 'react';
import ListOptions from 'components/ListOptions';
import logo from './logo.png';
import * as facebookService from 'services/settings/facebook';
import { useDispatch } from 'react-redux';
import { openModalFacebookLeads } from 'modules/modal';
import { useRequest } from 'hooks/useRequest';
import Button from 'components/Button';

const FacebookLeadsIntegration = () => {
  const dispatch = useDispatch();
  const [isRemoving, setIsRemoving] = useState(false);

  const { data, fetchData } = useRequest({
    request: facebookService.getIntegration,
  });

  const isConnected = data?.facebook?.lead_pages?.[0]?.id;

  const disconnect = async () => {
    setIsRemoving(true);
    await facebookService.disconnectLeads();
    fetchData();
    setIsRemoving(false);
  };

  const handleClickConfig = () => {
    dispatch(
      openModalFacebookLeads({
        config: true,
      })
    );
  };

  const connect = async () => {
    dispatch(
      openModalFacebookLeads({
        afterClose: () => {
          fetchData();
        },
      })
    );
  };

  return (
    <ListOptions.item
      image={logo}
      imageStyle={{
        width: 42,
      }}
      title="Facebook - Formulários (Leads)"
      text="Receba automaticamente leads gerado por formulários do Facebook"
      renderOptions={() => {
        if (isConnected) {
          return (
            <>
              <Button
                disabled={isRemoving}
                color="white"
                onClick={handleClickConfig}
              >
                Configurar
              </Button>
              <Button disabled={isRemoving} color="danger" onClick={disconnect}>
                Desconectar
              </Button>
            </>
          );
        }

        return (
          <Button color="success" onClick={connect}>
            Conectar
          </Button>
        );
      }}
    />
  );
};

export default FacebookLeadsIntegration;
