import styled from 'styled-components';
import { Wrapper as ActionIcon } from 'components/ActionIcon/styles';

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -15px;

  ${ActionIcon} {
    margin-right: 5px;
  }
`;

export const Action = styled.div`
  width: 25px;
  height: 40px;
  cursor: pointer;
  font-size: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
