import React from 'react';
import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Field, reduxForm } from 'redux-form';
import { Input } from 'components/Form';
import Button from 'components/Button';
import Alert from 'react-s-alert';
import { whatsappSendUrl } from 'lib/share-helpers';
import styled from 'styled-components';

const Form = styled.form``;

function ModalImport({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  handleSubmit,
}) {
  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Form onSubmit={handleSubmit}>
        <ModalTemplate
          title="Importação de dados"
          text="Traga seus dados para o tecimob"
          handleClose={handleClose}
        >
          <p className="h-margin-bottom--15">
            Por favor, forneça o nome do sistema ou a fonte dos seus dados e, em
            seguida, clique em <strong>”Conversar com a equipe”</strong>.
            Entraremos em contato para esclarecer eventuais dúvidas e avaliar a
            viabilidade da importação.
          </p>

          <Field
            label="Nome da fonte de dados"
            name="name"
            component={Input}
            placeholder="Digite o nome"
          />

          <p>
            O retorno será feito por WhatsApp, em horário comercial, por uma
            pessoa da nossa equipe.
          </p>
          <ModalFooter>
            <Button type="button" onClick={handleClose} color="white">
              Voltar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              Conversar com equipe
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </Form>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalImport',
  onSubmit: (values) => {
    const currentUser = localStorage.getItem('current-user');

    const sendUrl = whatsappSendUrl(
      '554896049333',
      `*[SOLICITAÇÃO DE IMPORTAÇÃO]*
Desejo de importar meus dados para o *Tecimob*

Serial: ${currentUser?.real_estate?.serial}
Nome: ${currentUser?.real_estate?.name}
Usuário: ${currentUser?.name}
Origem: ${values.name}`
    );

    window.open(sendUrl, '_blank', null, false);

    return sendUrl;
  },
  onSubmitSuccess: (res, dispatch, props) => {
    props.handleClose();
    Alert.success('Em breve alguem da equipe ira entrar em contato com você', {
      timeout: 6000,
    });
  },
})(ModalImport);
