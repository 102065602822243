import Container, { Header } from "components/Container";

function TableLocations({ title, children }) {
  return (
    <Container padding style={{ marginTop: '25px' }}>
      <Header>
        <h4>{title}</h4>
      </Header>
      {children}
    </Container>
  );
}

export default TableLocations;
