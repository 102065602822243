import Button from "components/Button";
import Check from "components/Check";
import Label from "components/Label";
import { getPropertyStatus } from "lib/property-helpers";
import { plural } from "lib/text";

function PropertyItem({
  handleOpenProperty,
  property,
  canSelect,
  handleSelectProperty,
  selecteds,
}) {
  const status = getPropertyStatus(property);

  const getBedrooms = () => {
    const bedroom = property.rooms?.bedroom?.value;
    const suite = property.rooms?.suite?.value;

    let text = '--';

    if (bedroom) {
      text = bedroom;
    }

    if (suite) {
      text += plural(
        suite,
        ` (sendo ${suite} suíte)`,
        ` (sendo ${suite} suítes)`,
        false
      );
    }

    return text;
  }

  const getBuiltArea = () => {
    try {
      const builtArea = property.areas?.built_area;
      return builtArea.value + builtArea.measure;
    } catch {
      return '--';
    }
  }

  const getPrivateArea = () => {
    try {
      const privateArea = property.areas?.private_area;
      return privateArea.value + privateArea.measure;
    } catch {
      return '--';
    }
  }

  const getTotalArea = () => {
    try {
      const totalArea = property.areas?.total_area;
      return totalArea.value + totalArea.measure;
    } catch {
      return '--';
    }
  }

  const renderCheck = () => {
    if (property.is_blocked) {
      return (
        <td>
          <Label color="danger">Bloqueado</Label>
        </td>
      );
    }

    if (!canSelect) return null;

    return (
      <td>
        <Check
          checked={(selecteds || []).includes(property.id)}
          onClick={(e) => {
            e.stopPropagation();
            handleSelectProperty(property.id);
          }}
        />
      </td>
    );
  }

  return (
    <tr
      className="h-hover h-pointer"
      onClick={() => handleOpenProperty(property)}
    >
      {renderCheck()}
      <td>{property.informations?.apartment_number?.value ?? '--'}</td>
      <td>
        {property.reference}{' '}
        {status.length > 0 &&
          status.map((item) => {
            if (item.text !== 'Reservado') return null;

            return (
              <span className={`h-color--${item.color}`}> | {item.text}</span>
            );
          })}
      </td>
      <td>{getBedrooms()}</td>
      <td>{property.rooms?.garage?.value ?? '--'}</td>
      <td>{getBuiltArea()}</td>
      <td>{getPrivateArea()}</td>
      <td>{getTotalArea()}</td>
      <td>{property.calculated_price ?? '--'}</td>
      <td>
        <Button color="secondary" colorText="white" size="small">
          Ver imóvel
        </Button>
      </td>
    </tr>
  );
}

export default PropertyItem;
