import styled from 'styled-components';

export const Wrapper = styled.form`
  width: 100%;
  display: grid;
  place-items: center;
  position: relative;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #fff;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
`;
