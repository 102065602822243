import { useEffect, useState } from 'react';
import * as typeService from 'services/types';
import { useRequest } from 'hooks/useRequest';

export const useTypeId = (typeOrSubtypeId) => {
  const { data: types } = useRequest({
    request: typeService.getGroupedTypesSubtypes,
  });
  const [typeId, setTypeId] = useState(typeOrSubtypeId);

  const findTypeId = () => {
    types.forEach((type) => {
      if (type.id === typeOrSubtypeId) {
        setTypeId(type.type_id);
      }
    });

    if (!typeOrSubtypeId) setTypeId(null);
  };

  useEffect(() => {
    findTypeId();
  }, [types, typeOrSubtypeId]);

  return typeId;
};
