import React, { useCallback, useContext } from 'react';
import { openModalPortalAdd } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { PortalsContext } from 'pages/Portals/context';
import Button from 'components/Button';

const AddPortal = () => {
  const { fetchData } = useContext(PortalsContext);
  const dispatch = useDispatch();

  const handleClickAdd = useCallback(() => {
    dispatch(
      openModalPortalAdd({
        onSubmitSuccess: () => {
          fetchData();
        },
      })
    );
  }, []);

  return (
    <Button color="secondary" onClick={handleClickAdd}>
      Adicionar Portal
    </Button>
  );
};

export default AddPortal;
