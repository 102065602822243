import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import styled, { css } from 'styled-components';

// Components
import Container from 'components/Container';
import MainTitle from 'components/MainTitle';
import Reception from './components/Reception';
import ChatGpt from './components/ChatGpt';

// HOC
import { withId } from '../../HOC/withId';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Services
import * as descriptionService from 'services/propertiesPartial/description';
import { Input, Textarea } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import Loading from 'components/Loading';
import LoadingGpt from './components/Loading';
import Error from './components/Error';
import { finishResponse } from 'pages/PropertyStore/helpers';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { useParams } from 'react-router';
import ValidationPortals from './components/ValidationPortals';

const CustomContainer = styled(Container)`
  transition: filter 0.3s ease-in-out;

  ${(p) =>
    p.isFetching &&
    css`
      filter: blur(5px);
    `}
`;

const Wrapper = styled.div`
  position: relative;
`;

const Description = ({
  change,
  handleSubmit,
  initialize,
  onSubmit,
  submitting,
}) => {
  const [isFetchingGpt, setIsFetchingGpt] = useState(false);
  const [error, setError] = useState(null);
  const [hasDescription, setHasDescription] = useState(false);

  const description = useFormValue('description');
  const portals = useFormValue('portals');

  const isLoading = isFetchingGpt || error?.err;

  const { data, fetchData, isFetching } = useRequest({
    request: descriptionService.getOne,
    initialFetch: false,
    initialState: {},
  });
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    let initialValues = data;

    if (data?.description) {
      setHasDescription(true);
    }

    initialize(initialValues);
  }, [data, setHasDescription]);

  if (isFetching) return <Loading isFullComponent isVisible />;

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      {!hasDescription ? (
        <Reception
          onAddDescription={() => {
            setHasDescription(true);
            change(
              'description',
              `
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>As informações estão sujeitas a alterações. Consulte o corretor responsável.</p>
              `
            );
          }}
        />
      ) : (
        <>
          <MainTitle
            title="Descrição"
            text="Descreva o imóvel com as melhores informações possíveis para atrair o seu cliente."
          />
          <Wrapper>
            <CustomContainer isFetching={isLoading}>
              <Row>
                <Field
                  xs={7}
                  label="Titulo da página de detalhamento do imóvel"
                  name="title"
                  placeholder="Digite"
                  component={Input}
                />
                <Col xs={5}>
                  <ChatGpt
                    propertyId={id}
                    onChangeLoading={(isFetching) => {
                      setIsFetchingGpt(isFetching);
                    }}
                    onGenerate={(response) => {}}
                    onError={(error) => {
                      setError(error);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Field
                  height={275}
                  xs={12}
                  label="Texto de apresentação do imóvel"
                  name="description"
                  component={Textarea}
                />
              </Row>

              <ValidationPortals
                portals={portals}
                description={description}
                condoDescription={data.condo_description}
              />
            </CustomContainer>
            <LoadingGpt isVisible={isFetchingGpt} />
            <Error isVisible={error?.err} error={error} setError={setError} />
          </Wrapper>
        </>
      )}

      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyDescription',
    initialValues: {
      description: 'Oi',
    },
    onSubmit: (values, d, p) =>
      descriptionService.update(values).then(finishResponse(values, d, p)),
    onSubmitSuccess: (response, dispatch, p) => {
      p.initialize(response?.data);
      if (!p.isEditting) {
        p.next('complements');
      }
    },
  }),
  withFormConfirmation()
)(Description);
