import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 230px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #dedede;
  padding: 35px 25px 15px;

  .Button--submit {
    margin: 10px 0;
  }
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

export const Number = styled.h2`
  font-size: 60px;
  font-weight: 500;
  text-align: center;

  span {
    width: 45px !important;
    height: 50px !important;
    margin-top: -14px;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    height: 14px;
  }
`;

export const LinkItem = styled.button.attrs(() => ({ type: 'button' }))`
  color: ${(p) => p.theme.colors.secondary};
`;

export const LinkDivider = styled.span`
  color: ${(p) => p.theme.colors.secondary};
  padding: 0 5px;
`;

export const SearchBy = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 30px;
  text-align: center;

  button {
    color: ${(p) => p.theme.colors.secondary};
    text-decoration: underline;
  }

  button + button {
    margin-top: 5px;
  }
`;

export const StatusField = styled.div`
  margin-top: 53px;

  .RadioList__content {
    padding: 15px 20px;
  }
`;
