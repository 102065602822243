import React, { useEffect } from 'react';
import { Row } from 'react-flexbox-grid';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { Input } from 'components/Form';

// Containers
import FieldPerson from 'containers/FieldPerson';
import FieldSelectUser from 'containers/FieldSelectUser';
import { normalizePhone, normalizeCpf } from 'lib/formHelpers';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import useFormValue from 'hooks/useFormValue';

// Services
import { getOne } from 'services/people';
import { fichaDeVisita } from 'services/pdf';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function Form({ onCreatePerson, handleClose, change, handleSubmit }) {
  const personId = useFormValue('person_id');

  async function fetchPerson() {
    const { data: person } = await getOne(personId);

    change('cellphone_number', person?.cellphone_number);
    change('cpf_cnpj', person?.cpf_cnpj);
  }

  useEffect(() => {
    if (!!personId) {
      fetchPerson();
    }
  }, [personId]);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <FieldPerson
          required
          label="Selecione o cliente"
          name="person_id"
          xs={12}
          onCreatePerson={onCreatePerson}
        />
      </Row>
      <Row>
        <Field
          label="Telefone"
          xs={6}
          name="cellphone_number"
          component={Input}
          normalize={normalizePhone}
        />
        <Field
          xs={6}
          label="CPF"
          name="cpf_cnpj"
          component={Input}
          normalize={normalizeCpf}
        />
      </Row>
      <Row>
        <FieldSelectUser
          required
          xs={12}
          label="Selecione o corretor que apresentará o imóvel"
        />
      </Row>
      <ModalFooter>
        <Button color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Gerar Ficha
        </Button>
      </ModalFooter>
    </form>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

export default reduxForm({
  form: 'ModalVisitForm',
  validate: values => {
    const errors = {};

    if (!values.person_id) {
      errors.person_id = ['Campo obrigatório'];
    }

    if (!values.user_id) {
      errors.user_id = ['Campo obrigatório'];
    }

    return errors;
  },
  enableReinitialize: true,
  onSubmit: values => {
    return fichaDeVisita(values);
  }
})(Form);
