import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { ModalClose } from 'components/Modal';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 568px;
  padding: 30px 40px;
  background: #fff;
  margin-top: -30px;

  h1 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
  }
`;

function ModalPriceAdjustment({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
}) {
  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <Wrapper>
        <ModalClose handleClose={handleClose} />

        <h1>Reajuste no preço do Serviço</h1>
        <p>Estamos reajustando nosso plano de R$69,90 para R$99,90 ao mês.</p>
        <p>
          O preço por usuário adicional irá permanecer R$19,90, sem alteração.
        </p>
        <p>&nbsp;</p>
        <p>
          Nosso último ajuste de preços ocorreu em Novembro de 2019, pois nos
          anos de 2020, 2021 e 2022, devido a instabilidade no mercado e
          insegurança causada pela pandemia e seus desdobramentos, fizemos o
          possível e impossível para não repassar o aumento da inflação e do
          câmbio, que muito nos afeta, bem como demais custos operacionais.
        </p>
        <p>&nbsp;</p>
        <p>
          Este novo preço já está vigente aos novos clientes desde Outubro deste
          ano, 2022, mas pra você que já é nosso cliente, somente será aplicado
          à partir de 01 de Fevereiro de 2023. Se você possui um pacote de longo
          prazo ativo conosco, ele será respeitado até seu fim, valendo o
          reajuste somente ao final do período, no ato da renovação do plano.
        </p>
        <p>&nbsp;</p>
        <p>
          Com o realinhamento do preço, poderemos acelerar o desenvolvimento da
          ferramenta e entregar cada vez mais estabilidade e recursos que
          aumentem suas vendas. Agradecemos a compreensão e parceria!
        </p>
      </Wrapper>
    </Modal>
  );
}

export default ModalPriceAdjustment;
