import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MdCheckCircle, MdRadioButtonUnchecked } from 'react-icons/md';
// Components
import Label from 'components/Label';
// Helpers
import PlanModel from 'lib/Plan';

// Cria um formatador de valor
const formatter = new Intl.NumberFormat('pt-BR', {
  useGrouping: false,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const plan = new PlanModel();

class Plan extends React.Component {
  static propTypes = {
    receivingMethod: PropTypes.number,
    name: PropTypes.string.isRequired,
    users: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    selected: PropTypes.number.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    receivingMethod: 1,
    name: 'Nenhum',
    users: null,
    value: null,
    selected: null,
    disabled: false
  };

  state = {
    netMonthlyPrice: null,
    grossMonthlyPrice: null,
    totalNetValue: null,
    totalGrossValue: null,
    percentage: null
  };

  componentDidMount() {
    // Atualiza os preços do componente
    this.updatePrices();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.updatePrices();
    }
  }

  updatePrices = () => {
    const { users, value } = this.props;

    plan.users = users;
    plan.months = value;

    this.setState({
      netMonthlyPrice: formatter.format(plan.getNetMonthlyPayment()),
      grossMonthlyPrice: formatter.format(plan.getGrossMonthlyPayment()),
      totalNetValue: formatter.format(plan.getNetValue()),
      totalGrossValue: formatter.format(plan.getGrossValue()),
      percentage: plan.getDiscountPercentage()
    });
  };

  /**
   * Retorna se o campo está selecionado
   * @returns {boolean}
   */
  get isSelected() {
    const { value, selected } = this.props;
    return value === selected;
  }

  get hasDiscount() {
    const { percentage } = this.state;
    return !!percentage;
  }

  /**
   * Renderiza o icone
   * @returns {Component}
   */
  get renderIcon() {
    if (!this.isSelected) return <MdRadioButtonUnchecked size="15" />;
    return <MdCheckCircle color="#01AFAD" size="15" />;
  }

  /**
   * Retorna o título do mes
   * @returns {string}
   */
  get title() {
    const { name, value } = this.props;
    const { netMonthlyPrice } = this.state;

    // Se o valor liquido mensal for igual ao valor total
    if (value === 1)
      return (
        <>
          <strong>{name}</strong>:
          <strong style={{ marginLeft: '5px' }}>R${netMonthlyPrice}</strong>
        </>
      );

    return (
      <>
        <strong>{name}</strong>: Equivalente à
        <strong style={{ marginLeft: '5px' }}>R${netMonthlyPrice}</strong>
        /mês
      </>
    );
  }

  get text() {
    const { name, value, disabled } = this.props;
    const { totalNetValue, totalGrossValue } = this.state;

    // TODO RENOVAÇÃO AUTOMÁTICA
    //if (this.isSelected && receivingMethod === 2 && value === 1) {
    //  return (
    //    <Field
    //      type="checkbox"
    //      label="Renovar automaticamente"
    //      name="should_auto_renew"
    //      component={Checkbox}
    //      style={{ marginBottom: 0 }}
    //    />
    //  );
    //}

    if (disabled) return `Pagamento ${name} apenas com cartão`;

    if (value === 1) return 'Pago mensalmente';

    // Se não tiver desconto mostra o valor liquido total
    if (!this.hasDiscount) return <>Pagamento único de R${totalNetValue}</>;

    return (
      <>
        Pagamento único de <span className="h-line">R${totalGrossValue}</span>{' '}
        por{' '}
        <strong>
          R$
          {totalNetValue}
        </strong>
      </>
    );
  }

  get renderPercentage() {
    const { percentage } = this.state;

    const color = percentage > 0.05 ? 'caution' : 'primary';

    return (
      <Label color={color} style={{ opacity: percentage > 0 ? 1 : 0 }}>
        POUPE {percentage * 100}%
      </Label>
    );
  }

  onClick = e => {
    e.stopPropagation();
    const { onClick, disabled } = this.props;
    if (disabled) return null;
    if (onClick) onClick(e);
  };

  render() {
    const { disabled } = this.props;

    return (
      <div
        onClick={this.onClick}
        className={classnames('PlanItem', {
          isSelected: this.isSelected,
          isDisabled: disabled
        })}
      >
        <div className="PlanItem__icon">{this.renderIcon}</div>
        <div className="PlanItem__content">
          <div className="PlanItem__title">
            {this.title} <span className="h-flex__cell--grow" />{' '}
            {this.renderPercentage}
          </div>
          <div className="PlanItem__text">{this.text}</div>
        </div>
      </div>
    );
  }
}

export default Plan;
