import { Wrapper, Divider } from './styles';

function Booked({ property }) {
  const isBooked = property?.bookings_active_count >= 1;

  if (!isBooked) return null;

  return (
    <Wrapper>
      <Divider>{' | '}</Divider>
      <span className="h-color--danger">Reservado</span>
    </Wrapper>
  );
}

export default Booked;
