import { createValidation } from '../../../../../lib/validate-helpers';

export default values => {
  const errors = {};
  const validations = createValidation(values, errors);

  validations.required('reason');

  if (values['reason'] === '3') {
    validations.required('description');
  }

  return errors;
};
