import React from 'react';
import PropTypes from 'prop-types';
// Components
import FinancialItem from '../FinancialItem';
import Table from 'components/Table';
// Assets
import img from 'assets/img/noData/index.svg';

const propTypes = {
  actionHandler: PropTypes.func,
  handleDeleteIndex: PropTypes.func.isRequired,
};

const defaultProps = {};

const FinancialTable = ({
  indexes,
  handleOpenModal,
  handleOpenModalUpdate,
  openModalIndexHistory,
  handleDeleteIndex,
  handleTogglePublic,
}) => {
  return (
    <div>
      <Table
        data={indexes}
        isFetching={false}
        renderTitle={() => (
          <tr>
            <th>Índice</th>
            <th>Alterado em</th>
            <th>Preço</th>
            <th>Revisar</th>
            {/*<th>Público</th>*/}
            <th colSpan={2}>Imóveis</th>
          </tr>
        )}
        renderItem={(index) => (
          <FinancialItem
            key={`${index.id}-indices`}
            index={index}
            openModalIndexHistory={openModalIndexHistory}
            handleOpenModalUpdate={handleOpenModalUpdate}
            handleDeleteIndex={handleDeleteIndex}
            handleTogglePublic={handleTogglePublic}
          />
        )}
        noData={{
          image: img,
          title: 'Nenhum índice cadastrado.',
          text: 'Tente adicionar um novo índice',
          actionText: 'Adicionar Índice',
          actionHandler: handleOpenModal,
        }}
      />
    </div>
  );
};

FinancialTable.defaultProps = defaultProps;
FinancialTable.propTypes = propTypes;

export default FinancialTable;
