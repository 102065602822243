import {
  createContext, useCallback, useContext,
  useMemo, useState
} from 'react';

import useFormValue from 'hooks/useFormValue';
import useReduxForm from 'hooks/useReduxForm';

import * as locationService from 'services/location';

const initialValues = {
  data: [],
  isFetching: false
};

export const LocationContext = createContext({
  countryName: 'country_id',
  stateName: 'state_id',
  cityName: 'city_id',
  neighborhoodName: 'neighborhood_id',
  zoneName: 'zone_id',

  countries: initialValues,
  states: initialValues,
  cities: initialValues,
  neighborhoods: initialValues,
  zones: initialValues,

  country: null,
  state: null,
  city: null,
  neighborhood: null,
  zone: null,
  reset: null,

  setCountries: null,
  setStates: null,
  setCities: null,
  setNeighborhoods: null,
  setZones: null,

  initialValues: initialValues
});

export const LocationProvider = ({
  countryName = 'country_id',
  stateName = 'state_id',
  cityName = 'city_id',
  neighborhoodName = 'neighborhood_id',
  zoneName = 'zone_id',
  children
}) => {
  const { change, dispatch } = useReduxForm();
  const countryId = useFormValue(countryName);
  const stateId = useFormValue(stateName);
  const cityId = useFormValue(cityName);
  const neighborhoodId = useFormValue(neighborhoodName);
  const zoneId = useFormValue(zoneName);

  const [countries, setCountries] = useState(initialValues);
  const [states, setStates] = useState(initialValues);
  const [cities, setCities] = useState(initialValues);
  const [neighborhoods, setNeighborhoods] = useState(initialValues);
  const [zones, setZones] = useState(initialValues);

  const get = useCallback((data, id) => {
    try {
      return data.find(item => {
        return item.id === id;
      });
    } catch {
      return null;
    }
  }, []);

  const country = useMemo(() => {
    return get(countries?.data, countryId);
  }, [countryId, countries]);

  const state = useMemo(() => {
    return get(states?.data, stateId);
  }, [stateId, states]);

  const city = useMemo(() => {
    return get(cities?.data, cityId);
  }, [cityId, cities]);

  const neighborhood = useMemo(() => {
    return get(neighborhoods?.data, neighborhoodId);
  }, [neighborhoodId, neighborhoods]);

  const zone = useMemo(() => {
    return get(zones?.data, zoneId);
  }, [zoneId, zones]);

  const reset = useCallback(() => {
    setStates(initialValues);
    setCities(initialValues);
    setNeighborhoods(initialValues);
    setZones(initialValues);

    dispatch(change(countryName, null));
    dispatch(change(stateName, null));
    dispatch(change(cityName, null));
    dispatch(change(neighborhoodName, null));
    dispatch(change(zoneName, null));
  }, [dispatch, change]);

  const fetchCountries = useCallback(async () => {
    setCountries({ isFetching: true });
    const res = await locationService.getCountries();
    setCountries({ data: res?.data });
  }, [setCountries]);

  const refreshOptions = useCallback(() => {
    const currentCity = cityId;
    const currentNeighborhood = neighborhoodId;
    const currentZone = zoneId;

    dispatch(change(cityName, null));
    dispatch(change(neighborhoodName, null));
    dispatch(change(zoneName, null));

    dispatch(change(cityName, currentCity));
    dispatch(change(neighborhoodName, currentNeighborhood));
    dispatch(change(zoneName, currentZone));
  }, [dispatch, change, cityId, neighborhoodId, zoneId, cityName, neighborhoodName, zoneName]);

  return (
    <LocationContext.Provider
      value={{
        countries,
        states,
        cities,
        neighborhoods,
        zones,

        country,
        state,
        city,
        neighborhood,
        zone,

        setCountries,
        setStates,
        setCities,
        setNeighborhoods,
        setZones,

        change,
        dispatch,

        countryName,
        stateName,
        cityName,
        neighborhoodName,
        zoneName,

        fetchCountries,
        refreshOptions,

        initialValues,
        reset
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => useContext(LocationContext);
