import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import BoxWrapper from 'pages/Crm/components/Box';

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 100;
  background: #fff;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.05);
`;

const WrapperChart = styled.div`
  width: 100%;
  filter: blur(5px);
`;

const Overlay = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  opacity: 0.8;
`;

const DATA = [
  {
    id: '3fdeee97-056e-4ad1-b806-1f7bfdf726af',
    name: 'Contato',
    funnel_id: 'a4255622-ee04-4a84-8124-61f6aaf57ec3',
    deals_count: 35,
    percent: 70,
  },
  {
    id: '14eceef4-4876-496c-8bd9-aaf0b9ea996e',
    name: 'Visita',
    funnel_id: 'a4255622-ee04-4a84-8124-61f6aaf57ec3',
    deals_count: 15,
    percent: 57,
  },
  {
    id: '14eceef4-4876-496c-8bd9-aaf0b9ea996e',
    name: 'Proposta',
    funnel_id: 'a4255622-ee04-4a84-8124-61f6aaf57ec3',
    deals_count: 9,
    percent: 25,
  },
  {
    id: '14eceef4-4876-496c-8bd9-aaf0b9ea996e',
    name: 'Contra Proposta',
    funnel_id: 'a4255622-ee04-4a84-8124-61f6aaf57ec3',
    deals_count: 0,
    percent: 0,
  },
  {
    id: '14eceef4-4876-496c-8bd9-aaf0b9ea996e',
    name: 'Contrato',
    funnel_id: 'a4255622-ee04-4a84-8124-61f6aaf57ec3',
    deals_count: 0,
    percent: 0,
  },
  {
    id: '14eceef4-4876-496c-8bd9-aaf0b9ea996e',
    name: 'Pós venda',
    funnel_id: 'a4255622-ee04-4a84-8124-61f6aaf57ec3',
    deals_count: 0,
    percent: 0,
  },
  {
    id: '14eceef4-4876-496c-8bd9-aaf0b9ea996e',
    name: 'Finalização',
    funnel_id: 'a4255622-ee04-4a84-8124-61f6aaf57ec3',
    deals_count: 0,
    percent: 0,
  },
  {
    id: '14eceef4-4876-496c-8bd9-aaf0b9ea996e',
    name: 'Ganho',
    funnel_id: 'a4255622-ee04-4a84-8124-61f6aaf57ec3',
    deals_count: 11,
    percent: 25,
  },
];

const PlaceholderBarChart = () => (
  <BoxWrapper
    title="Negócios Perdidos por Etapa"
    text="Análise de percentual de perdas em cada etapa"
  >
    <Wrapper>
      <Box>Nenhum resultado encontrado</Box>
      <WrapperChart>
        <Overlay />
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={DATA}
            layout="horizontal"
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid vertical={false} />
            <YAxis type="number" />
            <XAxis
              dataKey="name"
              type="category"
              interval={0}
              tick={{ dy: 0 }}
            />
            <Tooltip />
            <Bar
              dataKey="deals_count"
              barSize={55}
              label={({ value, x, y, width }) => {
                const percent = DATA.find(
                  (item) => item.deals_count === value
                ).percent;

                return percent >= 5 ? (
                  <text
                    x={x + width / 2}
                    y={y}
                    dy={-10}
                    textAnchor="middle"
                    fill="#fff"
                  >
                    <tspan x={x + width / 2} dy="1.2em">
                      {value}
                    </tspan>
                    <tspan
                      x={x + width / 2}
                      dy="1.2em"
                    >{`(${percent}%)`}</tspan>
                  </text>
                ) : null;
              }}
            >
              {DATA.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    index === 0
                      ? '#FF9702'
                      : index === DATA.length - 1
                      ? '#31D084'
                      : '#F84343'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </WrapperChart>
    </Wrapper>
  </BoxWrapper>
);

export default PlaceholderBarChart;
