// Modules
import { combineReducers } from 'redux';
import { createModule } from 'lib/reducer-helpers';
// Services
import * as propertiesFormService from 'services/sites/propertyForms';

const createAction = createModule('components/propertyForm');
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');

const dataInitialState = {
  whatsapp_url: null,
  whatsapp_email_url: null,
  email_url: null
};

export function dataReducer(state = dataInitialState, action) {
  switch (action.type) {
    case RECEIVE:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export function metaReducer(state = { isFetching: false }, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case RECEIVE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}

export default combineReducers({
  data: dataReducer,
  meta: metaReducer
});

export const requestForms = () => ({ type: REQUEST });
export const receiveForms = data => ({ type: RECEIVE, data });

/**
 * Busca todos os modelos de formulários
 * @param params
 * @return {Promise}
 */
export const fetchPropertiesForm = params => dispatch => {
  dispatch(requestForms());

  return propertiesFormService.getComponent(params).then(res => {
    dispatch(receiveForms(res.data));
    return res;
  });
};
