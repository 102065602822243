import React from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
// Components
import EventWrapper from './components/EventWrapper';
// Helpers
import moment from 'lib/moment';
import { formatEvents } from './helpers';
import Toolbar from 'components/BigCalendar/components/Toolbar';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const propTypes = {
  // BigCalendar props
  defaultView: PropTypes.oneOf(['month', 'week', 'work_week', 'day', 'agenda']),
  selectable: PropTypes.bool,
  views: PropTypes.oneOfType(
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object
  ),
  messages: PropTypes.object,
  titleAccessor: PropTypes.string,
  startAccessor: PropTypes.string,
  endAccessor: PropTypes.string,

  // Events
  onSelectSlot: PropTypes.func,

  // My Props
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colorAccessor: PropTypes.string,
  canFormatEvents: PropTypes.bool,
  style: PropTypes.object,
};

const defaultProps = {
  // BigCalendar props
  defaultView: 'month',
  selectable: false,
  views: ['month', 'week', 'day'],
  messages: {
    next: 'Próximo',
    previous: 'Anterior',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    today: 'Hoje',
  },

  titleAccessor: 'summary',
  startAccessor: 'start_date',
  endAccessor: 'end_date',

  // Events
  onSelectSlot: null,

  // My Props
  height: 500,
  colorAccessor: 'color',
  canFormatEvents: true,
  style: null,
};

const Calendar = ({
  // BigCalendar props
  selectable,
  events: currentEvents,
  views,
  messages,
  startAccessor,
  endAccessor,
  onSelectSlot,
  components,

  // My Props
  height,
  colorAccessor,
  canFormatEvents,
  style,

  ...props
}) => {
  // Inicializa a variavel eventos
  // com os eventos atuais
  let events = currentEvents;

  // V erifica se pode formatar os eventos
  if (canFormatEvents)
    events = formatEvents(events, startAccessor, endAccessor);

  return (
    <BigCalendar
      {...props}
      selectable={!!onSelectSlot || !!selectable}
      localizer={localizer}
      events={events}
      startAccessor={startAccessor}
      endAccessor={endAccessor}
      style={{ height, ...style }}
      views={views}
      messages={messages}
      onSelectSlot={onSelectSlot}
      components={{
        eventWrapper: EventWrapper,
        toolbar: Toolbar,
        ...components,
      }}
    />
  );
};

Calendar.defaultProps = defaultProps;
Calendar.propTypes = propTypes;

export default Calendar;
