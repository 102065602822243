import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Popover from 'components/Popover';
import Detail from './components/Detail';

// import from './styles';

function DetailsDiscarted({ matchedId }) {
  return (
    <Popover matchedId={matchedId} width={255} component={Detail}>
      <Button size="medium">Ver Detalhes</Button>
    </Popover>
  );
}

DetailsDiscarted.propTypes = {
  matchedId: PropTypes.string.isRequired
};
DetailsDiscarted.defaultProps = {
  matchedId: null
};

export default DetailsDiscarted;
