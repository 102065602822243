import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { Input, FieldBool } from 'components/Form';
import Button from 'components/Button';
// Constants
import { MAX_LENGTH_CHARACTERISTICS } from 'pages/PropertyAdd/constants';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

class FormCharacteristics extends React.Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form
        onSubmit={(e, ...rest) => {
          e.preventDefault();
          e.stopPropagation();
          return handleSubmit(...rest);
        }}
      >
        <Field
          autoFocus
          label="Nome"
          name="title"
          component={Input}
          maxLength={MAX_LENGTH_CHARACTERISTICS}
        />
        <FieldBool label="Perguntar Quantidade?" name="has_quantity" />
        <Button block type="submit" color="success">
          Adicionar
        </Button>
      </form>
    );
  }
}

FormCharacteristics.defaultProps = defaultProps;
FormCharacteristics.propTypes = propTypes;

export default reduxForm({
  form: 'FormCharacteristics',
  enableReinitialize: true,
  initialValues: {
    has_quantity: false
  }
})(FormCharacteristics);
