import React from 'react';
import { Field } from 'redux-form';
import { SelectMultiple } from 'components/Form';
import { parseSelect } from 'lib/formHelpers';

// import { Wrapper } from './styles';

function ClientsWithContact() {
  return (
    <Field
      clearable={false}
      multi={false}
      name="clients_with_contact"
      component={SelectMultiple}
      options={[
        { label: 'Todos os contatos', value: 'ALL_CLIENTS' },
        { label: 'Com posição no CRM', value: 'WITH_CRM' },
        { label: 'Sem posição no CRM', value: 'WITHOUT_CRM' },
      ]}
      parse={parseSelect('value')}
    />
  );
}

export default ClientsWithContact;
