import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Events from 'lib/Socket/Events';
import { contractsSelector, fetchContracts } from 'modules/financial/contracts';

export const useContracts = () => {
  const dispatch = useDispatch();
  const {
    data,
    meta: { isFetching },
  } = useSelector(contractsSelector);

  useEffect(() => {
    let events = new Events();

    events.onPaymentSuccess(() => {
      dispatch(fetchContracts());
    });

    return () => {
      events.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(fetchContracts());
  }, []);

  return { data, isFetching };
};
