import React from 'react';
import Modal from 'react-modal';

// Common Components
import { ModalTemplate } from 'components/Modal';

function ModalFacebookPages({ modalConfig, isOpen, handleClose, modalType }) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Facebook" handleClose={handleClose}>
        Você não possui nenhum página para coleta de leads.
      </ModalTemplate>
    </Modal>
  );
}

export default ModalFacebookPages;
