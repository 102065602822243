import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 15px;

  h5 {
    margin-bottom: 5px;
  }
`;

function Reasons({ reasons }) {
  if (reasons.length === 0) return null;

  return (
    <Wrapper>
      <h5>
        Os seguintes imóveis <strong>NÃO</strong> serão excluídos
      </h5>
      {reasons.map(({ reference, reason }) => {
        return (
          <div key={reference}>
            Referência - {reference} - {reason}
          </div>
        );
      })}
    </Wrapper>
  );
}

Reasons.defaultProps = {};
Reasons.propTypes = {};

export default Reasons;
