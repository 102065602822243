import React from 'react';
import { useRequest } from 'hooks/useRequest';
import * as peopleService from 'services/people';
import Nodata from 'components/Nodata';
import house from 'containers/ModalRoot/ModalPerson/components/Properties/pages/PropertiesNewMatched/house-favorite.svg';
import { ModalSubtitle } from 'components/Modal';
import { plural } from 'lib/text';
import TableMatchedProperties from './components/TableMatchedProperties';
import Loading from 'components/Loading';

const STATUS = {
  NEW: 1,
  SENDED: 2,
  DISCARDED: 3,
};

const PropertiesNewMatched = ({
  fetchPropertiesMatched,
  modalPerson: { person },
  openModalPerson,
  openModalSendMail,
  openModalProperty,
  discartMatchedProperty,
  handleOpenMySelf,
}) => {
  const {
    data: matcheds,
    meta,
    fetchData,
    isFetching,
  } = useRequest({
    request: (params) =>
      peopleService.getPeopleMatched(person.id, {
        filter: { by_status: STATUS.NEW, by_property_status: 1 },
        offset: 1,
        limit: 40,
        // include: 'property.state,property.city',
        ...params,
      }),
  });

  if (matcheds.length === 0) {
    return (
      <>
        <Nodata
          style={{ maxWidth: 330 }}
          image={house}
          title="Nenhum imóvel no radar"
          text="Quando algum imóvel for compatível com o perfil de busca do seu cliente, ele aparecerá aqui."
        />
        <Loading isVisible={isFetching} isFullComponent />
      </>
    );
  }

  return (
    <>
      <ModalSubtitle
        title={plural(
          meta?.pagination?.total,
          'imóvel compatível',
          'imóveis compatíveis'
        )}
      />
      <TableMatchedProperties
        isFetching={isFetching}
        person={person}
        data={matcheds}
        meta={meta}
        fetchData={fetchData}
        afterCloseModalProperty={handleOpenMySelf}
      />
      <Loading isVisible={isFetching} isFullComponent />
    </>
  );
};

export default PropertiesNewMatched;
