import React from 'react';
import TableSort, { CollumHeader } from 'components/TableSort';
import { useRequest } from 'hooks/useRequest';
import Check from 'components/Check';
import { useDispatch } from 'react-redux';
import { openModalPerson, openModalShare } from 'modules/modal';
import useCheckbox from 'hooks/useCheckbox';
import MatchedBar from 'containers/ModalRoot/ModalProperty/pages/Radar/components/MatchedBar';
import Nodata from 'components/Nodata';
import { MdDelete } from 'react-icons/md';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import * as propertiesService from 'services/properties';
import api from 'services';
import { FaWhatsapp } from 'react-icons/fa';

// import { Wrapper } from './styles';

function addZero(number) {
  if (number === 0) return '--';
  return number.toString().padStart(2, '0');
}

function Radar({ infos: property, handleOpenMyself }) {
  const dispatch = useDispatch();
  const {
    checkedItems,
    isAllItemsChecked,
    isChecked,
    handleToggle,
    handleToggleAll,
    clear,
  } = useCheckbox();

  const { data, meta, fetchData } = useRequest({
    request: (params = { sort: '' }) => {
      return propertiesService.getMatcheds(
        {
          id: property.id,
        },
        params?.sort
      );
    },
  });

  const openPerson = (person, params) => (e) => {
    e.stopPropagation();
    dispatch(
      openModalPerson({ ...person, afterClose: handleOpenMyself }, params)
    );
  };

  /**
   * Descarta um imóvel
   * @param matchedId
   * @return {(function(*): void)|*}
   */
  const handleClickDiscart = (matched) => async (e) => {
    e.stopPropagation();
    dispatch(
      openConfirmation({
        title: `Descartar cliente`,
        text: 'Se voce deseja descartar esse cliente clique em Descartar',
        submitButtonText: 'Descartar',
        request: () => {
          return api.delete(`properties/${property.id}/matcheds`, '', {
            params: {
              matcheds_id: [matched.id],
            },
          });
        },
        onSuccess: () => {
          fetchData({ offset: 1 });
          clear();
        },
      })
    );
  };

  const handleDiscartAllSelecteds = () => {
    dispatch(
      openConfirmation({
        title: `Remover da lista ${checkedItems.length} clientes`,
        text: 'Se voce deseja descartar todos os clientes clique em Descartar',
        submitButtonText: 'Descartar',
        request: () => {
          return api.delete(`properties/${property.id}/matcheds`, '', {
            params: {
              matcheds_id: checkedItems,
            },
          });
        },
        onSuccess: () => {
          fetchData({ offset: 1 });
          clear();
        },
      })
    );
  };

  if (!data || data.length <= 0)
    return (
      <Nodata
        title="Nenhum cliente no radar"
        text="Quando algum cliente for compatível com o perfil de busca desse imóvel, ele aparecerá aqui."
      />
    );

  return (
    <>
      <TableSort
        data={data}
        meta={meta}
        fetchData={fetchData}
        className="h-margin-top--15"
        renderHeader={() => (
          <tr>
            <th align="left">
              <Check
                checked={isAllItemsChecked(data)}
                onClick={() => {
                  handleToggleAll(data);
                }}
              />
            </th>
            <CollumHeader width={150} align="left" filterBy="by_person_name">
              Cliente
            </CollumHeader>
            <CollumHeader filterBy="by_person_interacted_at">
              Ultima interação
            </CollumHeader>
            <th>Imóveis no Funil</th>
            <th>No Radar</th>
            <th>Enviados</th>
            <th>Descartados</th>
            <th>Compartilhar</th>
            <th />
          </tr>
        )}
        renderItem={(matched) => (
          <tr key={matched.id}>
            <td align="left">
              <Check
                checked={isChecked(matched.id)}
                onClick={() => {
                  handleToggle(matched.id);
                }}
              />
            </td>
            <td
              onClick={openPerson(matched.person, {
                initialPage: 'Properties',
                pageProperties: 'PropertiesNewMatched',
              })}
            >
              {matched?.person?.name}
            </td>
            <td align="center">{matched?.person?.interacted_at}</td>
            <td
              align="center"
              onClick={openPerson(matched.person, {
                initialPage: 'Properties',
              })}
            >
              <span className="h-link">
                {addZero(matched?.person?.deals_count)}
              </span>
            </td>
            <td
              align="center"
              onClick={openPerson(matched.person, {
                initialPage: 'Properties',
                pageProperties: 'PropertiesNewMatched',
              })}
            >
              <span className="h-link">
                {addZero(matched?.person?.matcheds_new_count)}
              </span>
            </td>
            <td
              align="center"
              onClick={openPerson(matched.person, {
                initialPage: 'Properties',
                pageProperties: 'PropertiesSended',
              })}
            >
              <span className="h-link">
                {addZero(matched?.person?.matcheds_sent_count)}
              </span>
            </td>
            <td
              align="center"
              onClick={openPerson(matched.person, {
                initialPage: 'Properties',
                pageProperties: 'PropertiesDiscarted',
              })}
            >
              <span className="h-link">
                {addZero(matched?.person?.matcheds_discarted_count)}
              </span>
            </td>
            <td align="center">
              <a
                href="#"
                className="h-link"
                onClick={(e) => {
                  e.preventDefault();

                  dispatch(
                    openModalShare({
                      person: matched.person,
                      properties_id: [property.id],
                      afterClose: handleOpenMyself,
                    })
                  );
                }}
              >
                <FaWhatsapp color="#00B849" />
              </a>
            </td>
            <td>
              <button onClick={handleClickDiscart(matched)}>
                <MdDelete />
              </button>
            </td>
          </tr>
        )}
      />
      <MatchedBar
        checkedItems={checkedItems}
        handleDiscartAllSelecteds={handleDiscartAllSelecteds}
      />
    </>
  );
}

export default Radar;
