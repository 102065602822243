import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col } from 'react-flexbox-grid';
// Modules
import { hasIntegration } from 'modules/google';
// Components
import ChartPeriodForm from '../../components/ChartPeriodForm';
import Container from 'components/Container';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import Loading from 'components/Loading';
import Chart from './components/Chart';
import Table from './components/Table';
// Services
import * as analyticsService from 'services/analytics';
// Constantes
import { COLORS_HEXADECIMAL } from 'constants/config';
// Helpers
import { getViewId, transformReports } from 'lib/analytics';
import moment from 'lib/moment';

class AnalyticsNewUsers extends Component {
  state = {
    data: [],
    columns: [],
    isFetching: false
  };

  componentDidMount() {
    this.fetchAnalyticsData({
      period: 3,
      start_date: moment()
        .subtract(3, 'month')
        .format('DD/MM/YYYY'),
      end_date: moment().format('DD/MM/YYYY')
    });
  }

  fetchAnalyticsData = ({ start_date, end_date }) => {
    this.setState({ isFetching: true });

    //const request = new RequestAnalytics();
    //
    //request.setStartDate("20/05/2019");
    //request.setEndDate("25/05/2019");
    //
    //request.addMetric({ expression: "ga:users", alias: "Total de usuários" });
    //request.addMetric({ expression: "ga:newUsers", alias: "Novos usuários" });
    //
    //request.addDimension({ expression: "ga:yearMonth", alias: "Novos usuários" });

    return analyticsService
      .search({
        reportRequests: [
          {
            viewId: getViewId(),
            dateRanges: [
              {
                startDate: moment(start_date, 'DD/MM/YYYY').format(
                  'YYYY-MM-DD'
                ),
                endDate: moment(end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
              }
            ],
            metrics: [
              {
                expression: 'ga:users',
                alias: 'Total de usuários'
              },
              {
                expression: 'ga:newUsers',
                alias: 'Novos usuários'
              }
            ],
            dimensions: [
              {
                name: 'ga:yearMonth'
              }
            ]
          }
        ]
      })
      .then(({ data: { reports } }) => {
        const report = transformReports(reports)[0];
        this.setState({ data: report.data.rows, columns: report.columnHeader });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  };

  handleSubmit = values => {
    const { start_date, end_date } = values;
    this.fetchAnalyticsData({ start_date, end_date });
  };

  render() {
    //const { analytics: { data, meta: { isFetching } } } = this.props;
    const { data, columns, isFetching } = this.state;
    const { hasIntegration } = this.props;

    if (!hasIntegration) {
      return <Redirect to="/reports/analytics/show" />;
    }

    return (
      <Wrapper.container>
        <MainTitle
          title="Novos usuários"
          text="Veja quantos clientes foram captados em determindo período."
        >
          <ChartPeriodForm
            onSubmit={this.handleSubmit}
            isFetching={isFetching}
          />
        </MainTitle>
        <Container>
          <Loading isVisible={isFetching} />
          {!isFetching && (
            <>
              <Col xs={12}>
                <Chart
                  width={'100%'}
                  height={250}
                  data={data}
                  colors={COLORS_HEXADECIMAL}
                />
              </Col>
              <Table data={data} columns={columns} />
            </>
          )}
        </Container>
        <Link
          to="/integrations"
          className="h-link h-color--primary-light"
          style={{ display: 'block', marginTop: '10px' }}
        >
          Remover Integração
        </Link>
      </Wrapper.container>
    );
  }
}

const mapStateToProps = state => ({
  hasIntegration: hasIntegration(state)
});

export default connect(mapStateToProps)(AnalyticsNewUsers);
