import React from 'react';
import { Wrapper, Header, Title, Text, Actions, Content } from './styles';
import Loading from 'components/Loading';

const Box = ({ isLoading, title, text, actions, children }) => (
  <Wrapper>
    {isLoading && <Loading isVisible isFullComponent />}
    <Header>
      <div>
        {title && <Title>{title}</Title>}
        {text && <Text>{text}</Text>}
      </div>
      {!!actions && <Actions>{actions()}</Actions>}
    </Header>
    <Content>{children}</Content>
  </Wrapper>
);

export default Box;
