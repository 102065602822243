import React from 'react';

export const NavigatorHeader = () => (
  <div className="Navigator__header">
    <div className="Navigator__ball--close" />
    <div className="Navigator__ball--minimize" />
    <div className="Navigator__ball--expand" />
  </div>
);

export const NavigatorBody = ({ children }) => (
  <div className="Navigator__body">{children}</div>
);

const Navigator = ({ children, ...props }) => (
  <div {...props} className="Navigator">
    <NavigatorHeader />
    <NavigatorBody>{children}</NavigatorBody>
  </div>
);

export default Navigator;
