import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Button from '../../../../../../../components/Button';
import { FileInput } from '../../../../../../../components/Form';

const defaultProps = {
  children: 'Enviar Documento'
};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const FormUpload = ({
  handleSubmit,
  isFetching,
  submitting,
  children,
  ...rest
}) => (
  <Form onSubmit={handleSubmit} {...rest}>
    <Field accept={null} multiple name="documents" component={FileInput}>
      <Button color="primary" disabled={isFetching}>
        {children}
      </Button>
    </Field>
  </Form>
);

FormUpload.defaultProps = defaultProps;
FormUpload.propTypes = propTypes;

export default reduxForm({
  form: 'PropertyDocumentUpload',
  enableReinitialize: true
})(FormUpload);
