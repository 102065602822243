import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { change } from 'redux-form';
// Components
import { ModalTemplate } from 'components/Modal';
import FormLocation, { selector } from './components/FormLocation';
// Modules
import { Actions } from 'containers/LocationFields/module';
// Service
import * as locationService from 'services/location';

class ModalLocationAdd extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null
  };

  handleSubmit = values => {
    return Promise.resolve(values);
  };

  /**
   * Adiciona uma cidade em um estado específico
   * @param state_id
   * @return {Function}
   */
  handleAddCity = state_id => async city => {
    const {
      data: { id }
    } = await locationService.createCity({ state_id, name: city.name });
    const { data } = await locationService.getCities({ state_id });

    this.props.receive('FormLocation', 'city_id', data);
    this.props.change('FormLocation', 'city_id', id);
  };

  handleAddNeighborhood = city_id => async neighborhood => {
    const {
      data: { id }
    } = await locationService.createNeighborhood({
      city_id,
      name: neighborhood.name
    });
    const { data } = await locationService.getNeighborhoods({ city_id });

    this.props.receive('FormLocation', 'neighborhood_id', data);
    this.props.change('FormLocation', 'neighborhood_id', id);
  };

  handleClickDeleteCity = (city, stateId) => async e => {
    e.stopPropagation();
    await locationService.removeCity(stateId, city.id);
    this.props.remove('FormLocation', 'city_id', city.id);
  };

  handleClickDeleteNeighborhood = neighborhood => async e => {
    e.stopPropagation();
    await locationService.removeNeighborhood(neighborhood.id);
    this.props.remove('FormLocation', 'neighborhood_id', neighborhood.id);
  };

  handleSubmitSuccess = res => {
    const {
      countryId,
      stateId,
      cityId,
      neighborhoodId,
      onSubmitSuccess,
      handleClose
    } = this.props;

    if (onSubmitSuccess) {
      onSubmitSuccess(res, {
        countryId,
        stateId,
        cityId,
        neighborhoodId
      });
    }

    // Fecha a modal quando da submit success
    handleClose();
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title="Cadastrar Cidade/Bairro"
          handleClose={handleClose}
        >
          <FormLocation
            handleAddCity={this.handleAddCity}
            handleClickDeleteCity={this.handleClickDeleteCity}
            handleClickDeleteNeighborhood={this.handleClickDeleteNeighborhood}
            handleAddNeighborhood={this.handleAddNeighborhood}
            onSubmit={this.handleSubmit}
            onSubmitSuccess={this.handleSubmitSuccess}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  countryId: selector(state, 'country_id'),
  stateId: selector(state, 'state_id'),
  cityId: selector(state, 'city_id'),
  neighborhoodId: selector(state, 'neighborhood_id')
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...Actions,
      change
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalLocationAdd);
