import * as S from './styles';
import { Actions, Td, Tr } from './styles';
import Pagination from 'components/Pagination';

function List({ data, meta, onPageChange, children }) {
  return (
    <S.Wrapper>
      <S.Table>{data?.map?.((item) => children(item))}</S.Table>
      {meta ? (
        <Pagination pagination={meta.pagination} onPageChange={onPageChange} />
      ) : null}
    </S.Wrapper>
  );
}

export { List, Tr, Td, Actions };
