import styled from 'styled-components';

export const Box = styled.div`
  margin-bottom: 15px;
`;
export const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(28, 12, 30, 0.5);
`;
export const Value = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export const Textarea = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(28, 12, 30, 0.5);
  width: 100%;
  padding: 5px;
  background: #f4f3f3;
  border-radius: 4px;
  margin-top: 10px;
  min-height: 63px;
  margin-bottom: -10px;
`;
