import api from 'services';
import { getChecked } from 'lib/object-helpers';
import _ from 'lodash';

const transformData = values => {
  let newValues = { ...values };

  if (newValues.cadastral_situations) {
    newValues = {
      ...newValues,
      cadastral_situations: getChecked(newValues.cadastral_situations)
    };
  }

  return newValues;
};

const transformResponse = res => {
  res.data.cadastral_situations = _(res.data.cadastral_situations_id)
    .map(id => ({
      id,
      isChecked: true
    }))
    .keyBy('id')
    .value();

  // Remove establishments_id
  delete res.data.cadastral_situations_id;

  return res;
};

export const getOne = id =>
  api.getOne(`properties/partial/${id}/private`, '').then(transformResponse);

export const update = ({ id, ...data }) =>
  api.patch(`properties/partial/${id}/private`, transformData(data));
