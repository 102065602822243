import createDataReducer from 'modules/data';
// Services
import * as establishmentsService from 'services/establishments';

export const {
  reducer: establishmentsReducer,
  selectors: establishmentsSelectors,
  actionCreators: establishmentsActions
} = createDataReducer('filter/establishments');

export const fetchEstablishments = params => async dispatch => {
  dispatch(establishmentsActions.request());
  const { data } = await establishmentsService.getList(params);
  dispatch(establishmentsActions.receive(data));
};

export default establishmentsReducer;
