import React from 'react';
import Container, { Header } from 'components/Container';
import Button from 'components/Button';
import { closeModal, openModalTestimonialsStore } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { Person, Image } from './styles';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import * as testimonialsService from 'services/sites/testimonials';
import Nodata from 'components/Nodata';
import ReactToggle from 'react-toggle';

function TestimonialsList({
  testimonials,
  fetchTestimonials,
  handleChangeStatus,
}) {
  const dispatch = useDispatch();

  const openModal = (id = null) => {
    dispatch(
      openModalTestimonialsStore({
        id,
        onSubmitSuccess: () => {
          dispatch(closeModal());
          fetchTestimonials();
        },
      })
    );
  };

  const update =
    ({ id }) =>
    () => {
      openModal(id);
    };

  const handleRemove =
    ({ id }) =>
    () => {
      dispatch(
        openConfirmation({
          title: 'Remover depoimento?',
          text: `Você irá remover o depoimento `,
          cancelButtonText: 'Cancelar',
          submitButtonText: 'Remover depoimento',
          request: () => testimonialsService.remove({ id }),
          onSuccess: () => fetchTestimonials(),
        })
      );
    };

  if (testimonials?.length <= 0) {
    return (
      <Container padding style={{ marginTop: '25px' }}>
        <Nodata
          image={null}
          title="Nenhum depoimento"
          text="Adicione o primeiro depoimento abaixo"
        >
          <Button onClick={() => openModal()} className="h-margin-top--15">
            Cadastrar depoimento
          </Button>
        </Nodata>
      </Container>
    );
  }

  return (
    <Container padding style={{ marginTop: '25px' }}>
      <Header>
        <h4>Depoimentos</h4>
        <Button onClick={() => openModal()}>Cadastrar depoimento</Button>
      </Header>
      <table className="Table">
        <thead>
          <tr>
            <th>Título</th>
            <th className="Table__cell--small">Publicado</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {testimonials?.map((testimonial) => (
            <tr key={testimonial.id + testimonial.file_url}>
              <td>
                <Person className="h-text-nowrap">
                  <Image size="small" src={testimonial.file_url} />
                  <span>{testimonial.person_name}</span>
                </Person>
              </td>
              <td className="Table__cell--small h-text-center">
                <div className="h-flex h-flex--center-center">
                  <ReactToggle
                    checked={testimonial.is_published}
                    icons={false}
                    style={{ fontSize: 11 }}
                    className="h-margin-right--5"
                    onChange={handleChangeStatus(testimonial)}
                  />
                  <div style={{ width: 100, textAlign: 'center' }}>
                    {testimonial.is_published ? 'Publicado' : 'Não publicado'}
                  </div>
                </div>
              </td>
              <td className="Table__cell--small">
                <Button
                  onClick={update(testimonial)}
                  color="white"
                  colorText="primary"
                >
                  Editar
                </Button>
                <Button
                  onClick={handleRemove(testimonial)}
                  color="white"
                  colorText="danger"
                >
                  Remover
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

export default TestimonialsList;
