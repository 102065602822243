import MainTitle from "components/MainTitle";

function Header() {
  return (
    <MainTitle
      title="Convites pendentes"
      text="Lista de corretores/usuários com solicitação de cadastro pendente"
    />
  );
}

export default Header;
