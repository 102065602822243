import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-shrink: 0;
  position: relative;
  display: flex;
  background-size: cover;
`;

export const Number = styled.button`
  cursor: pointer;
  position: relative;
  box-sizing: content-box;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: #fff;
  font-weight: 900;
  background-color: ${(p) => p.theme.colors[p.color]};
  border-radius: 4px;
  margin: 0 5px;
`;
