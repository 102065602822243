// Services
import * as propertyService from 'services/properties';
// Modules
import dataReducer from 'modules/data';

// Constants
export const MODULE_NAME = 'propertyExclusivityExpired';

export const {
  reducer: propertyExclusivityExpiredReducer,
  actionCreators: propertyExclusivityActions
} = dataReducer(`propertyDashboard/${MODULE_NAME}`);

// Selectors
export const dataSelector = state => state.propertyDashboard[MODULE_NAME].data;
export const isFetchingSelector = state =>
  state.propertyDashboard[MODULE_NAME].meta.isFetching;

// Reducer
export const fetchPropertyExclusivityExpired = params => dispatch => {
  dispatch(propertyExclusivityActions.request());

  return propertyService
    .getList({
      offset: 1,
      filter: { by_expiring_exclusivity: true },
      ...params
    })
    .then(({ data }) => {
      dispatch(propertyExclusivityActions.receive(data));
      return data;
    });
};

export const handleSubmit = values => dispatch =>
  propertyService
    .alterProperty({
      ...values,
      exclusive_until: values.renew_date
    })
    .then(() => {
      fetchPropertyExclusivityExpired()(dispatch);
    });

export default propertyExclusivityExpiredReducer;
