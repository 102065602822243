import api from '../index';
import { responseMessage } from 'lib/service-helpers';

const crmName = 'rdstation';

/**
 * Pega os dados da modal de configuração
 */
export const get = () =>
  api.getOne(`integrations/crm/${crmName}`, '').then((res) => {
    res.data = {
      groups_id: res?.data?.groups?.map(({ id }) => id),
      recipient_user_id: res?.data?.recipient_user_id,
    };
    return res;
  });

/**
 * Atualiza os grupos
 */
export const update = ({ groups_id, recipient_user_id }) =>
  api
    .update(`integrations/crm/${crmName}`, { groups_id, recipient_user_id })
    .then(responseMessage('Atualizado as categorias'));

/**
 * Conecta com o CRM
 * @param values
 * @returns {Promise<AxiosResponse<any>>}
 */
export const connect = (values) =>
  api.create('integrations/crm', { ...values, crm_name: crmName });

/**
 * Atualiza o codigo de autenticação do CRM
 * @param code
 * @returns {Promise<AxiosResponse<any>>}
 */
export const refreshCode = (code) =>
  api
    .update(`integrations/crm/${crmName}`, { code })
    .then(responseMessage('Integração com RDStation finalizada'));

/**
 * Desconecta a integração com o CRM
 * @returns {Promise<AxiosResponse<any>>}
 */
export const disconnect = () =>
  api
    .delete(`integrations/crm/${crmName}`)
    .then(responseMessage('Desconectado'));
