import React, { useEffect } from 'react';
// Containers
import FieldStages from 'containers/FieldStages';

export default function SearchBy({ change, funnelId }) {
  useEffect(() => {
    change('stage_id', null);
  }, [funnelId]);

  return <FieldStages multi funnelId={funnelId} label="Etapa" />;
}
