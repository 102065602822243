import Modal from 'react-modal';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import logo from '../../assets/logo.png';
import styled from 'styled-components';
import Button from 'components/Button';

export const Wrapper = styled(Row)`
  p {
    margin-bottom: 15px;
  }

  ul {
    margin-bottom: 15px;
    list-style-position: inside;
    margin-left: 5px;
  }
`;

function TermsOfUse({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  onAcceptTerms,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
      style={{
        content: {
          maxWidth: 862,
        },
      }}
    >
      <ModalTemplate
        title="Integração com Superlógica"
        handleClose={handleClose}
        style={{
          width: '862px',
        }}
      >
        <Wrapper>
          <Col xs={3}>
            <img src={logo} alt="Logo pleno imob" />
          </Col>
          <Col xs={9}>
            <p>
              Superlógica é a principal plataforma para gestão financeira e de
              locação de imóveis do Brasil.
              <br />
              Saiba mais visitando{' '}
              <a
                href="https://superlogica.com/imobiliarias/"
                target="_blank"
                className="h-link"
              >
                https://superlogica.com/imobiliarias/
              </a>
            </p>
            <p>
              <strong>Integrações que temos ativo</strong>
            </p>
            <ul>
              <li>
                Envio de dados de clientes (Proprietários, Locatários,
                Fiadores);
              </li>
              <li>
                Envio de dados de imóveis para geração de contrato de locação;
              </li>
            </ul>
            <p>
              <strong>Funcionamento</strong>
            </p>
            <p>
              De posse com uma conta com a Superlógica, clique em conectar e
              seus dados para fazermos a ativação da integração. Após ativado a
              integração, será adicionado novos botões no detalhamento da vida
              do cliente de da vida do imóvel para envio das informações do
              Tecimob para Superlógica.
            </p>
            <p>
              <strong>Legal</strong>
            </p>
            <p>
              Superlógica é uma empresa externa, independente, sem ligação
              jurídica com Tecimob.
              <br />
              Não fazemos recomendação nem prestamos suporte sobre o produto.
            </p>
          </Col>
        </Wrapper>
        <ModalFooter>
          <Button type="button" onClick={handleClose} color="white">
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button color="success" onClick={onAcceptTerms}>
            Li e estou de acordo. Avançar para integração
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default TermsOfUse;
