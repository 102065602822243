import React, { useState } from 'react';
import Modal from 'react-modal';
import { reduxForm } from 'redux-form';
import * as pages from './pages';
import { PAYMENT_METHODS } from 'constants/constants';

function ModalCreditsChatGpt({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  onSuccess,
}) {
  const [page, setPage] = useState('SelectCredits');

  const goTo = (pageName) => () => {
    setPage(pageName);
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      {React.createElement(pages[page], {
        handleClose,
        goTo,
        onSuccess,
      })}
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalCreditsChatGpt',
  initialValues: {
    receiving_method: PAYMENT_METHODS.PIX,
    plan: 0,
  },
  onSubmitSuccess: () => {},
  onSubmitFail: () => {},
})(ModalCreditsChatGpt);
