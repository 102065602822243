import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 10px;
  }

  .FormGroup + .FormGroup {
    margin-left: 10px;
  }
`;
export const Text = styled.div``;
export const Content = styled.div`
  display: flex;
`;
