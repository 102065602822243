import Start from './Start';
import Header from './Header';
import Description from './Description';
import Video from './Video';
import Appearance from './Appearance';
import Progress from './Progress';
import Unity from './Unity';
import Characteristics from './Characteristics';
import Establishments from './Establishments';
import Localization from './Localization';
import ContactForm from './ContactForm';
import Slides from './Slides';
import Images from './Images';
import Plans from './Plans';
import Documents from './Documents';
import Seo from './Seo';
import Tour from './Tour';

export default {
  Start: {
    name: 'Vamos Começar',
    component: Start,
  },
  Header: {
    name: 'Imagem Principal',
    component: Header,
  },
  Description: {
    name: 'Descrição',
    component: Description,
  },
  Slides: {
    name: 'Slides',
    component: Slides,
  },
  Plans: {
    name: 'Plantas',
    component: Plans,
  },
  Images: {
    name: 'Galeria de Imagens',
    component: Images,
  },
  Documents: {
    name: 'Documentos',
    component: Documents,
  },
  Video: {
    name: 'Vídeo',
    component: Video,
  },
  Tour: {
    name: 'Tour Virtual',
    component: Tour,
  },
  Characteristics: {
    name: 'Características',
    component: Characteristics,
  },
  Establishments: {
    name: 'Estabelecimentos',
    component: Establishments,
  },
  Unity: {
    name: 'Unidades',
    component: Unity,
  },
  Progress: {
    name: 'Etapas do progresso',
    component: Progress,
  },
  Localization: {
    name: 'Localização',
    component: Localization,
  },
  ContactForm: {
    name: 'Formulário de contato',
    component: ContactForm,
  },
  Seo: {
    name: 'SEO',
    component: Seo,
  },
  Appearance: {
    name: 'Aparência',
    component: Appearance,
  },
};
