import { shape, string } from 'prop-types';
import { MdDelete, MdEdit } from 'react-icons/md';
// Components
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import { HourBlocks } from 'components/Form';
import { FieldArray } from 'redux-form';
import { Actions, ChangeHours, DragIcon, TableData, Wrapper } from './styles';

function TableItem({
  index,
  field,
  user,
  onClickUpdate,
  onClickDelete,
  onClickHours,
  onSubmitOfficeHours,
}) {
  const isChangeHours = user.isChangeHours;

  const fileUrl = user.image ? user.image.preview : user.file_url;

  const handleDelete = (user) => () => {
    if (onClickDelete) onClickDelete(user);
  };

  return (
    <div>
      <Wrapper>
        <DragIcon />

        <Avatar image={fileUrl} />

        <TableData>{user.name}</TableData>
        <TableData>{user.phone}</TableData>
        <TableData>{user.description}</TableData>

        <Actions>
          <Button
            color="white"
            colorText="secondary"
            onClick={() => {
              if (onClickHours) onClickHours(index, user);
            }}
          >
            Configurar Horários
          </Button>
          <Button
            color="white"
            colorText="secondary"
            onClick={() => {
              if (onClickUpdate) onClickUpdate(user);
            }}
            style={{
              padding: '0 10px',
            }}
          >
            <MdEdit />
          </Button>
          <Button
            color="white"
            colorText="secondary"
            onClick={handleDelete(user)}
            style={{
              padding: '0 10px',
            }}
          >
            <MdDelete />
          </Button>
        </Actions>
      </Wrapper>

      {isChangeHours && (
        <ChangeHours>
          <FieldArray
            rerenderOnEveryChange
            name={`${field}.office_hours`}
            component={HourBlocks}
            onSubmit={(fields) => {
              onSubmitOfficeHours(user, fields);
            }}
          />
        </ChangeHours>
      )}
    </div>
  );

  // return (
  //   <>
  //     <tr className="TableSchedule__row">
  //       <td width={1} className="TableSchedule__avatar">
  //         <Avatar image={fileUrl} />
  //       </td>
  //       <td>{user.name}</td>
  //       <td>{user.phone}</td>
  //       <td>{user.description}</td>
  //       <td className="TableSchedule__options">
  //         <Button
  //           color="white"
  //           colorText="secondary"
  //           onClick={() => {
  //             if (onClickHours) onClickHours(index, user);
  //           }}
  //         >
  //           Configurar Horários
  //         </Button>
  //         <Dropdown isDropHover>
  //           <Button color="white" colorText="primary">
  //             Opções <MdArrowDropDown />
  //           </Button>
  //           <List>
  //             <Item>
  //               <button
  //                 type="button"
  //                 onClick={() => {
  //                   if (onClickUpdate) onClickUpdate(user);
  //                 }}
  //               >
  //                 Editar
  //               </button>
  //             </Item>
  //             <Item>
  //               <button type="button" onClick={handleDelete(user)}>
  //                 Deletar
  //               </button>
  //             </Item>
  //           </List>
  //         </Dropdown>
  //       </td>
  //     </tr>
  //     {isChangeHours && (
  //       <tr className="TableSchedule__row-form">
  //         <td colSpan={5}>
  //           <div className="TableSchedule__container">
  //             <FieldArray
  //               rerenderOnEveryChange
  //               name={`${field}.office_hours`}
  //               component={HourBlocks}
  //               onSubmit={(fields) => {
  //                 onSubmitOfficeHours(user, fields);
  //               }}
  //             />
  //           </div>
  //         </td>
  //       </tr>
  //     )}
  //   </>
  // );
}

TableItem.defaultProps = {
  data: {
    id: null,
    name: null,
    phone: null,
    description: null,
  },
};

TableItem.propTypes = {
  data: shape({
    id: string.isRequired,
    name: string,
    phone: string,
    description: string,
  }).isRequired,
};

export default TableItem;
