import { useEffect } from 'react';
import WebFont from 'webfontloader';

export const useFont = ({ families }) => {
  useEffect(() => {
    WebFont.load({
      google: {
        families,
      },
    });
  }, [families]);
};
