import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Check from 'components/Check';
import { FormError } from 'components/Form';
import { Item, Wrapper } from './styles';

const Checkbox = props => {
  const { input, index, option, valueKey, labelKey, handleChange } = props;

  const { name } = input;
  const inputValue = input.value;

  // pega o texto do checkbox
  const label = option[labelKey];

  // pega o valor do checkbox
  const value = option[valueKey];

  // verifica se está checado
  const checked = inputValue.indexOf(value) !== -1;

  return (
    <label key={`checkbox-${index}`}>
      <input
        type="checkbox"
        name={`${name}[${index}]`}
        value={value}
        checked={checked}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <Check checked={checked} />
      <span>{label}</span>
    </label>
  );
};

function Checklist({ input, canSelectAll, options, labelKey, valueKey, meta }) {
  const [isAllSelected, setAllSelected] = useState();

  const hasError = !!meta?.error;

  useEffect(() => {
    // pega a quantidade de itens selecionados
    const countSelecteds = input.value.length;

    // pega o total de itens que tem nas opções
    const countAllOptions = options.length;

    setAllSelected(countSelecteds === countAllOptions);
  }, [input.value, options]);

  // Seleciona todas as opções
  function checkAll() {
    const allValues = options.map(option => option[valueKey]);
    input.onChange(allValues);
  }

  // Deseleciona todas as opções
  function uncheckAll() {
    input.onChange([]);
  }

  function handleToggleSelection() {
    if (isAllSelected) {
      uncheckAll();
    } else {
      checkAll();
    }
  }

  function handleChange(option) {
    return function(e) {
      const arr = [...input.value];
      const value = option[valueKey];

      if (e.target.checked) {
        arr.push(value);
      } else {
        arr.splice(arr.indexOf(value), 1);
      }

      return input.onChange(arr);
    };
  }

  if (!options || options.length === 0) return null;

  return (
    <Wrapper hasError={hasError}>
      {canSelectAll && (
        <Item>
          <label onClick={handleToggleSelection}>
            <Check checked={isAllSelected} />
            Selecionar todos
          </label>
        </Item>
      )}

      {options.map((option, index) => {
        return (
          <Item>
            <Checkbox
              index={index}
              input={input}
              option={option}
              valueKey={valueKey}
              labelKey={labelKey}
              handleChange={handleChange(option)}
            />
          </Item>
        );
      })}
      <FormError isVisible={meta.touched && meta.error}>{meta.error}</FormError>
    </Wrapper>
  );
}

Checklist.defaultProps = {
  labelKey: 'label',
  valueKey: 'id'
};

Checklist.propTypes = {
  labelKey: PropTypes.string,
  valueKey: PropTypes.string
};

export default memo(Checklist);
