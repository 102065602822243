import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Busca os campos que podem ser enviados
 * @param params
 * @return {*|{params}}
 */
export const getList = params => api.getList('settings/sites/searches', params);

/**
 * Atualiza as informações do site relacionadas ao rodapé
 * @param values
 * @return {Promise}
 */
export const update = values =>
  api.update('settings/sites/searches', values).then(responseMessage('Salvo!'));

/**
 * Retorna os modelos de rodapé
 * @param params
 * @return {Promise}
 */
export const getComponents = params =>
  api.getList('settings/component-examples/searches', params);
