import styled from 'styled-components';

export const WrapperTables = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Table = styled.table`
  position: relative;
  width: 47%;
  margin: 5px -5px 0;
  border-spacing: 0;

  + table:after {
    content: '';
    position: absolute;
    display: block;
    left: -15px;
    top: 15px;
    bottom: 15px;
    width: 1px;
    background: #e3e3e3;
  }

  td {
    padding: 5px;
  }
`;
