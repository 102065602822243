import React from 'react';
import AlertBig, { InnerContent, TitleContent } from 'components/AlertBig';
import radar from './assets/radar.png';
import { Content } from './styles';
import Property from './components/Property';
import PeopleCount from './components/PeopleCount';
import PropertyCount from './components/PropertyCount';
import Action from './components/Action';

function AlertRadar({ data }) {
  return (
    <AlertBig
      storageKey="alert-radar"
      image={radar}
      text={
        <>
          Radar de
          <br /> oportunidades
        </>
      }
      color="#FBA240"
    >
      <InnerContent>
        <TitleContent>Novas oportunidades no seu radar!</TitleContent>
        <Content>
          <Property data={data.property} />
          <PeopleCount value={data.people_count} />
          <Action data={data} />
        </Content>
        <PropertyCount data={data} />
      </InnerContent>
    </AlertBig>
  );
}

export default AlertRadar;
