import React, { useCallback, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
// Components
import { ModalTemplate } from '../../../components/Modal';
import Form from './components/Form';
// Services
import * as financeService from 'services/settings/finance';
import { useRequest } from 'hooks/useRequest';

export default function ModalBankStore({
  modalConfig,
  isOpen,
  modalType,
  handleClose,

  id,
  onSubmitSuccess,
}) {
  const { data: initialValues, fetchData } = useRequest({
    request: () => financeService.getBankById(id),
    initialFetch: false,
    initialState: {}
  });

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleSubmit = useCallback(values => {
    if (id) {
      return financeService.updateBank(values);
    }

    return financeService.createBank(values);
  }, []);

  // Verifica se está editando o banco
  const isEditting = useMemo(() => !!id, [id]);

  // Modal title
  const modalTitle = useMemo(
    () => (isEditting ? 'Editar Banco' : 'Adicionar Banco'),
    [isEditting]
  );

  const handleSubmitSuccess = useCallback(() => {
    if (onSubmitSuccess) onSubmitSuccess();

    // Fecha a modal caso tudo de certo
    handleClose();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title={modalTitle} handleClose={handleClose}>
        <Form
          isEditting={isEditting}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}
