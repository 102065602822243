import styled from 'styled-components';
import { em as _em, rgba } from 'polished';
import { HEIGHT, WIDTH } from '../../constants';
import theme from 'components/ModelsPost/theme';

export const em = (pixels) => _em(pixels, 32);

function generateGradient(color) {
  return `background: linear-gradient(
    180deg,
    ${rgba(color, 0)} 41.64%,  /* ajustado para começar 15% mais acima */
    ${rgba(color, 0.238792)} 47.42%,
    ${rgba(color, 0.452)} 52.59%,
    ${rgba(color, 0.8)} 62.29%
  );`;
}

export const Wrapper = styled.div`
  position: relative;
  width: ${em(WIDTH)};
  height: ${em(HEIGHT)};
  font-family: 'Montserrat', sans-serif;
  color: ${(p) => p.theme.mainColor};
  font-size: ${(p) => p.theme.fontSize};
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${(p) => generateGradient(p.theme.backgroundColor)};
`;

export const Image = styled.img`
  width: inherit;
  height: inherit;
  object-fit: cover;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const HeaderBox = styled.div`
  border: ${em(3)} solid ${(p) => p.theme.main};
  padding: ${em(30)} ${em(70)};
  margin-bottom: ${em(20)};
  text-align: center;
`;

export const Title = styled.div`
  font-size: ${em(64)};
`;

export const Price = styled.div`
  font-size: ${em(32)};
`;

export const Description = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: ${em(32)};
  margin: ${em(40)};

  > div {
    flex-grow: 1;
    padding: 0 ${em(30)};
    margin: ${em(10)} 0;

    &:nth-child(2n + 1) + div {
      border-left: 1px solid #000;
    }
  }
`;

export const Footer = styled.div`
  img {
    height: ${em(theme.logoSize)};
  }
`;
