import React from 'react';

import Container from 'components/Container';

const propTypes = {};
const defaultProps = {};

const ContainerSite = ({ children, ...props }) => (
  <Container padding {...props}>
    {children}
  </Container>
);

ContainerSite.propTypes = propTypes;
ContainerSite.defaultProps = defaultProps;

export default ContainerSite;
