import React, { useContext } from 'react';
import { Wrapper } from './styles';
import noResults from './search-no-results.svg';
import { SearchBarContext } from 'components/SearchBar/context';

const NoResults = () => {
  const { isTyping, hasData, isFetching, canSearch } = useContext(
    SearchBarContext
  );

  if (hasData || isTyping || isFetching || canSearch.digitsLeft > 0)
    return null;

  return (
    <Wrapper>
      <img src={noResults} alt="Sem Resultados" />
      <h1>Sem Resultados</h1>
      <p>Tente Pesquisar novamente</p>
    </Wrapper>
  );
};

export default NoResults;
