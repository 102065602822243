import React from 'react';
import { SelectMultiple } from 'components/Form';
import { normalizeSearch } from 'lib/text';
import defaultFilterOptions from 'react-select-plus/lib/utils/defaultFilterOptions';
import { Field } from 'redux-form';
import useReduxForm from 'hooks/useReduxForm';

const FieldSubtype = ({ options, isFetching }) => {
  const { dispatch, change } = useReduxForm();

  return (
    <Field
      isLoading={isFetching}
      xs={3}
      required
      multi={false}
      label="Tipo/Subtipo"
      name="subtype_id"
      component={SelectMultiple}
      options={options}
      valueRenderer={({ label, type_name, group }) => {
        return `${group?.title || type_name} - ${label}`;
      }}
      filterOptions={(items, search, excludeOptions, props) => {
        const formattedSearch = normalizeSearch(search);

        // Procura pelos tipos
        const types = items?.filter(({ type_name }) => {
          const formattedTypename = normalizeSearch(type_name);
          return formattedTypename?.includes(formattedSearch);
        });

        if (types?.length > 0) {
          return types;
        }

        return defaultFilterOptions(items, search, excludeOptions, props);
      }}
      onChange={() => {
        dispatch(change('condo_position', null));
      }}
    />
  );
};

export default FieldSubtype;
