import styled from 'styled-components';
import Image from 'components/Image';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #dedede;
  padding: 10px 15px;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;

  .Label {
    flex-shrink: 0;
  }
`;

export const Img = styled(Image)`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-right: 10px;
`;
export const Text = styled.div``;
export const Price = styled.strong``;
