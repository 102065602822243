import { useFeaturedLists } from 'hooks/api/featuredLists';
import { useEffect, useMemo } from 'react';
import { Select } from 'components/Form';
import { Field } from 'redux-form';

export default function FieldFeaturedLists({ options, ...props }) {
  const { dataFeaturedLists, fetchFeaturedLists } = useFeaturedLists({
    with_all: false,
  });

  const optionsField = useMemo(() => {
    if (options) return options;
    return dataFeaturedLists.featured_lists;
  }, [options, dataFeaturedLists]);

  useEffect(() => {
    if (!options) {
      fetchFeaturedLists();
    }
  }, [options]);

  return (
    <Field
      name="featured_list_id"
      options={optionsField}
      valueKey="id"
      labelKey="title"
      component={Select}
      {...props}
    />
  );
}
