import styled from "styled-components";

export const PaymentMethods = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

export const PaymentMethodForm = styled.div`
  margin-top: 10px;
`;

export const Form = styled.div`
  margin-top: 10px;
`;
