import React from 'react';
import { useTemplateContext } from '../../contexts/TemplateContext';
import Loading from 'components/Loading';
import CardTemplate from '../CardTemplate';
import { Wrapper } from './styles';

function ListTemplates() {
  const {
    category,
    templates,
    fetchTemplates,
    applyTemplate,
    isFetchingTemplates,
  } = useTemplateContext();

  if (isFetchingTemplates) {
    return <Loading isVisible isBlock />;
  }

  return (
    <Wrapper>
      {templates.map((template) => (
        <CardTemplate
          key={template?.id}
          data={template}
          onApply={async () => {
            await applyTemplate(template.id);
            fetchTemplates(category);
          }}
        />
      ))}
    </Wrapper>
  );
}

export default ListTemplates;
