import React from 'react';
import { connect } from 'react-redux';
// Modules
import { fetchFinancialIndexes, financialIndexesSelectors } from './module';
// Components
import { FinancialIndexes } from 'components/PropertyFilters';

class FinancialIndexesContainer extends React.Component {
  componentDidMount() {
    // Busca todos os estabelecimentos
    this.props.fetchFinancialIndexes();
  }

  render() {
    const { data } = this.props;

    // Verifica
    if (!data || data.length === 0) return null;

    return <FinancialIndexes options={data} />;
  }
}

const mapStateToProps = state => ({
  data: financialIndexesSelectors.getAll(state)
});

const mapDispatchToProps = {
  fetchFinancialIndexes
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancialIndexesContainer);
