import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// Components
import MainTitle from 'components/MainTitle';
// Modules
import { fetchHeaders, headersSelectors } from 'modules/components/headers';
import {
  deleteHeaderImage,
  fetchHeadersImages,
  headerImagesSelectors,
} from 'modules/components/headerImages';
// Container
import FormDevice from './containers/FormDevice';
import { withRouter } from 'react-router';
// HOC

const propTypes = {};
const defaultProps = {};

class Headers extends React.Component {
  state = {
    formValues: { device: 'desktop' },
    isFetching: true,
  };

  get renderForm() {
    const device = this.props.device;

    if (device === 'desktop') {
      return (
        <FormDevice
          key="FormHeaderDesktop"
          form="FormHeaderDesktop"
          device="desktop"
        />
      );
    }

    return (
      <FormDevice
        key="FormHeaderMobile"
        form="FormHeaderMobile"
        device="mobile"
      />
    );
  }

  render() {
    return (
      <>
        <MainTitle
          title={`Topo ${this.props.device}`}
          text="Escolha o modelo de topo que será utilizado em seu site. Depois, configure para que fique da maneira que desejar."
        />
        {/*{this.props.renderChangeDevice}*/}
        {this.renderForm}
      </>
    );
  }
}

Headers.propTypes = propTypes;
Headers.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => ({
  headers: headersSelectors.getAll(state),
  images: headerImagesSelectors.getAll(state),
  device: ownProps.match.params.device,
});

const mapDispatchToProps = {
  fetchHeaders,
  fetchHeadersImages,
  deleteHeaderImage,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Headers);
