import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';

function ItemButton({ children }) {
  return (
    <Col xs className="Menu__item Menu__item--small Menu__item--button">
      <div className="Menu__link">{children}</div>
    </Col>
  );
}

ItemButton.defaultProps = {
  children: null
};

ItemButton.propTypes = {
  children: PropTypes.node
};

export default ItemButton;
