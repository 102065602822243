import PropTypes from 'prop-types';
import Clipboard from 'react-clipboard.js';

import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
import Timeline from 'components/Timeline';
import Alert from 'react-s-alert';
import ItemDNS from './ItemDNS';
import ItemDone from './ItemDone';
import { Container, Dns, Number, Wrapper } from './styles';

function ListConnectDomains({ domains, onClickRemove, onClickCheckDNS }) {
  if (!domains) return null;

  return domains.map((domain) => (
    <Wrapper>
      <MainTitle
        title={
          <>
            Quase lá! Conecte o seu domínio{' '}
            <strong className="h-link" style={{ cursor: 'default' }}>
              {domain.raw_url}
            </strong>
          </>
        }
        text={<>Siga os passos abaixo para conectar o domínio ao seu site.</>}
      >
        <Button color="danger" onClick={onClickRemove(domain)}>
          Remover
        </Button>
      </MainTitle>
      <Container>
        <Timeline>
          <Timeline.item
            icon={() => <Number>1</Number>}
            iconColor="secondary"
            title="Faça login no seu provedor"
            text="Seu provedor é o site onde você comprou o domínio, por exemplo: Registro.br, GoDaddy, entre outros."
          />
          <Timeline.item
            icon={() => <Number>2</Number>}
            iconColor="secondary"
            title="Configure o DNS"
            renderText={() => (
              <>
                <p>Aponte as entradas DNS para:</p>
                <Dns>
                  <table>
                    <tr>
                      <td>
                        <p>DNS Master: {domain.ns1}</p>
                      </td>
                      <td>
                        <Clipboard
                          data-clipboard-text={domain.ns1}
                          onClick={() => {
                            Alert.success('NS1 Copiado');
                          }}
                        >
                          <Button
                            size="small"
                            style={{ margin: '4px 0', marginLeft: '10px' }}
                          >
                            Copiar
                          </Button>
                        </Clipboard>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>DNS Slave 1: {domain.ns2}</p>
                      </td>
                      <td>
                        <Clipboard
                          data-clipboard-text={domain.ns2}
                          onClick={() => {
                            Alert.success('NS2 Copiado');
                          }}
                        >
                          <Button
                            size="small"
                            style={{ marginTop: '0px', marginLeft: '10px' }}
                          >
                            Copiar
                          </Button>
                        </Clipboard>
                      </td>
                    </tr>
                  </table>
                </Dns>
              </>
            )}
          />
          {domain.dns_status === 4 ? (
            <ItemDNS onClick={onClickCheckDNS(domain.id)} />
          ) : (
            <ItemDone onClick={onClickCheckDNS(domain.id)} />
          )}
        </Timeline>
      </Container>
    </Wrapper>
  ));
}

ListConnectDomains.defaultProps = {
  domains: null,
  onClickRemove: null,
};

ListConnectDomains.defaultProps = {
  domains: PropTypes.arrayOf(PropTypes.object),
  onClickRemove: PropTypes.func,
};

export default ListConnectDomains;
