import Loading from 'components/Loading';
import { Content } from './styles';
import { PAYMENT_METHODS } from 'constants/constants';
import React from 'react';
import { ModalTemplate } from 'components/Modal';

const PaymentLoading = ({ receivingMethod, handleClose }) => {
  const getPaymentMethod = () => {
    switch (receivingMethod) {
      case PAYMENT_METHODS.CREDIT_CARD:
        return 'cartão';
      case PAYMENT_METHODS.BOLETO:
        return 'boleto';
      case PAYMENT_METHODS.PIX:
        return 'Pix';
      case PAYMENT_METHODS.BONUS:
        return 'bônus';
      default:
        return '';
    }
  };

  const paymentMethod = getPaymentMethod();

  return (
    <ModalTemplate
      title="Pagamento"
      text={`Processando pagamento ${
        paymentMethod ? `com ${paymentMethod}` : ''
      }`}
      handleClose={handleClose}
    >
      <Content>
        <Loading isVisible isBlock />
        <span>Aguarde...</span>
      </Content>
    </ModalTemplate>
  );
};

export default PaymentLoading;
