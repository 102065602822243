import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Pages
import * as pages from './pages';
import { useRouteMatch } from 'react-router';

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/show`} component={pages.LogoForms} />
      <Route
        path={`${path}/create/:device/:position`}
        component={pages.LogoCreate}
      />
      <Route
        path={`${path}/options/:device/:position`}
        component={pages.LogoOptions}
      />
      <Redirect to={`${path}/show`} />
    </Switch>
  );
};

export default Routes;
