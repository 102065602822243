import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
// Services
import * as receptionSourcesService from 'services/receptionSources';
// Components
import { ModalTemplate } from 'components/Modal';
import ReceptionSourceForm from './components/ReceptionSourceForm';

function ModalCharacteristics({
  id,
  onSubmitSuccess,
  onSubmitFail,
  afterClose,
  modalConfig,
  isOpen,
  modalType,
  handleClose
}) {
  const [initialValues, setValues] = useState({});
  const [isFetching, setFetching] = useState(false);

  // Verifica se está em modo de edição a modal
  const isEditting = !!id;

  useEffect(() => {
    if (id) {
      setFetching(true);
      receptionSourcesService
        .getOne(id)
        .then(({ data }) => {
          setValues(data);
        })
        .finally(() => {
          setFetching(false);
        });
    }
  }, []);

  function handleSubmit(values) {
    if (isEditting) {
      return receptionSourcesService.update(values);
    }
    return receptionSourcesService.create(values);
  }

  function handleCloseModal() {
    if (afterClose) afterClose();
  }

  function handleSubmitSuccess(...res) {
    handleClose();
    if (onSubmitSuccess) onSubmitSuccess(...res);
    handleCloseModal();
  }

  function handleSubmitFail(...res) {
    if (onSubmitFail) onSubmitFail(...res);
    handleCloseModal();
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      className={{
        base: 'Modal Modal--small'
      }}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={isFetching}
        title={
          isEditting
            ? 'Editar origem de captação'
            : 'Cadastrar origem de captação'
        }
        handleClose={handleClose}
      >
        <ReceptionSourceForm
          initialValues={initialValues}
          isEditting={isEditting}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFail}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}

ModalCharacteristics.defaultProps = {
  typeId: null
};

ModalCharacteristics.propTypes = {};

export default ModalCharacteristics;
