import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { getFirstName } from 'lib/text';
import { Col, Row } from 'react-flexbox-grid';
//import WizardVideoSteps from 'components/WizardVideoSteps';
import DashboardAlerts from 'containers/DashboardAlerts';
import WizardDashboard from 'containers/Wizards/WizardDashboard';
import { Link } from 'react-router-dom';
import GraphVisitsSite from 'containers/GraphVisitsSite';
import Shortcuts from './components/Shortcuts';
import Steps from './components/Steps';
import React from 'react';
import Can from 'containers/Can';
import Advertisements from './components/Advertisements';
import AlertRadarContainer from 'containers/AlertRadarContainer';
import LeadsComponent from './components/LeadsComponent';
import CreciAlert from 'containers/CreciAlert';

function NewDashboard() {
  const currentUser = useCurrentUser();
  const realEstate = currentUser?.real_estate;

  // Só vai mostrar pra novos usuários
  const canShowSteps = realEstate?.serial > 35450;
  // const canShowSteps = true;

  return (
    <Wrapper>
      <DashboardAlerts />

      {/*<GraphChannels  />*/}

      <MainTitle
        title={`Olá, ${getFirstName(currentUser.name)}! Seja bem vindo(a). 🎉`}
        text={
          <>
            Abaixo você tem a disposição um breve resumo do seu sistema. Para
            mais relatórios,{' '}
            <Link to="/reports" href="#" className="h-link">
              clique aqui.
            </Link>
          </>
        }
      />

      <CreciAlert />
      <AlertRadarContainer />

      {/*<AlertFacebookIntegration />*/}

      {canShowSteps && <Steps />}
      <Shortcuts />
      <Advertisements />

      {realEstate?.header_type === 'Header1' && <WizardDashboard />}
      {/*<WizardVideoSteps />*/}
      {/*<Row>*/}
      {/*  <Col xs={6}>*/}
      {/*    <CardProfile />*/}
      {/*  </Col>*/}
      {/*  <Col xs={6}>*/}
      {/*    <Counts />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Can run="VIEW_MAIN_DASHBOARD">
        <GraphVisitsSite />
      </Can>
      {/*<IsTecimob>*/}
      {/*  <Can run="EDIT_PORTAL">*/}
      {/*    <PromotionsPortals />*/}
      {/*  </Can>*/}
      {/*</IsTecimob>*/}
      {/*<BannerMobileApp />*/}
      {/*<BannerChromeExtension />*/}
      {/*<Can run="VIEW_MAIN_DASHBOARD">*/}
      {/*  <Row>*/}
      {/*    <Col xs={12}>*/}
      {/*      <AnalyticsReports />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</Can>*/}

      <Row>
        <Col xs={12}>
          <LeadsComponent />
        </Col>
      </Row>

      {/*<Row>*/}
      {/*  <Col xs={12}>*/}
      {/*    <TableLastLeadsReceived />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </Wrapper>
  );
}

export default NewDashboard;
