import dataReducer from 'modules/data';
// Service
import * as emailService from 'services/settings/email';

export const {
  reducer: accountsReducer,
  selectors: accountsSelector,
  actionCreators: accountsActions
} = dataReducer(`email/accounts`);

/**
 * Busca as contas
 * @param params
 * @returns {Function}
 */
export const fetchAccounts = params => async dispatch => {
  dispatch(accountsActions.request());

  try {
    const { data } = await emailService.getRedirectedAccounts(params);
    dispatch(accountsActions.receive(data));
  } catch {
    dispatch(accountsActions.reset());
  }
};

/**
 * Remove uma conta
 * @param {String|Required} userName
 * @returns {Function}
 */
export const removeAccount = userName => async dispatch => {
  dispatch(accountsActions.removing(userName, 'address'));

  try {
    await emailService.removeMailbox(userName);
    dispatch(accountsActions.remove(userName, 'address'));
  } catch {
    dispatch(accountsActions.reset());
  }
};
