import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Modules
import { handleSubmitRegisterDomain } from 'modules/domains';
// Components
import ModalTemplate from 'components/Modal/components/ModalTemplate';
import AddDomainForm from './components/AddDomainForm';

class ModalAddDomain extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    onSubmitSuccess: PropTypes.func
  };

  static defaultProps = {
    onSubmitSuccess: null
  };

  render() {
    const {
      modalConfig,
      isOpen,
      handleClose,
      modalType,
      onSubmitSuccess
    } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate title="Adicionar domínio" handleClose={handleClose}>
          <AddDomainForm
            onSubmitSuccess={() => {
              if (onSubmitSuccess) onSubmitSuccess();
              handleClose();
            }}
            handleClose={handleClose}
            onSubmit={this.props.handleSubmitRegisterDomain}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

export default connect(null, {
  handleSubmitRegisterDomain
})(ModalAddDomain);
