import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Components
import ModalTemplate from '../../../components/Modal/components/ModalTemplate';
import EmailChangePasswordForm from './components/EmailChangePasswordForm';
// Services
import * as emailService from 'services/settings/email';

class ModalEmailPassword extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    username: PropTypes.string.isRequired,
    onSubmitSuccess: PropTypes.func,
    onSubmitFail: PropTypes.func
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null,

    username: null,
    onSubmitSuccess: null,
    onSubmitFail: null
  };

  get initialValues() {
    const { username } = this.props;

    return { username };
  }

  handleSubmit = values => {
    return emailService.updateMailbox(values);
  };

  onSubmitSuccess = () => {
    if (this.props.onSubmitSuccess) this.props.onSubmitSuccess();

    // Fecha a modal
    this.props.handleClose();
  };

  onSubmitFail = () => {
    if (this.props.onSubmitFail) this.props.onSubmitFail();
  };

  render() {
    const {
      modalConfig,
      isOpen,
      handleClose,
      modalType,
      username
    } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title="Alterar Senha"
          handleClose={handleClose}
          titleActions={
            <>
              <p>e-mail: {username}</p>
            </>
          }
        >
          <EmailChangePasswordForm
            initialValues={this.initialValues}
            handleClose={handleClose}
            onSubmit={this.handleSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
            onSubmitFail={this.onSubmitFail}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

export default ModalEmailPassword;
