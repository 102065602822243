import React from 'react';
import { Field } from 'redux-form';
import { Input } from 'components/Form';
import { normalizeNumbers } from 'lib/formHelpers';

const FieldYearBuilding = () => {
  return (
    <Field
      xs={3}
      label="Ano da construção"
      type="number"
      name="lifetime"
      component={Input}
      normalize={normalizeNumbers}
      placeholder="Ex.: 2015"
    />
  );
};

export default FieldYearBuilding;
