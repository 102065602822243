import React, { useCallback } from 'react';
import Menu from 'components/Menus/components/Menu';
import Item from 'components/Menus/components/MenuItem';
import { MdPerson, MdEvent } from 'react-icons/md';
import { Wrapper } from './styles';
import { useDispatch } from 'react-redux';
import { openModalPersonAdd, openModalEvent, openModalPerson } from 'modules/modal';

const Actions = () => {
  const dispatch = useDispatch();

  const openPeople = useCallback(() => {
    dispatch(
      openModalPersonAdd(null, person => {
        dispatch(openModalPerson(person));
      })
    );
  }, []);

  const openEvent = useCallback(() => {
    dispatch(openModalEvent({}));
  }, []);

  return (
    <Wrapper>
      <Menu cols={1} title="Cadastre">
        <Item
          to="/properties/store"
          title="Imóvel"
          text="Cadastre um novo imóvel"
        />
        <Item
          icon={MdPerson}
          color="#0084F4"
          title="Cliente"
          text="Cadastre um novo cliente"
          onClick={openPeople}
        />
        <Item
          icon={MdEvent}
          color="#FFA26B"
          title="Atividade"
          text="Agenda uma atividade"
          onClick={openEvent}
        />
      </Menu>
    </Wrapper>
  );
};

export default Actions;
