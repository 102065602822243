import React from 'react';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Form from './components/Form';
import { useRequest } from 'hooks/useRequest';
import { getOne, update } from 'services/settings/lgpd';

function Lgpd() {
  const { data: initialValues, fetchData } = useRequest({
    request: getOne,
  });

  return (
    <Wrapper.container>
      <MainTitle title="LGPD" text="Configurações padrão e textos sobre LGPD" />
      <Form
        initialValues={initialValues}
        onSubmit={update}
        onSubmitSuccess={() => {
          fetchData();
        }}
      />
    </Wrapper.container>
  );
}

export default Lgpd;
