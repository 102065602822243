import React, { useCallback, useEffect, useState } from 'react';
import { useId } from 'pages/PropertyStore/hooks/useId';
import { getOne } from 'services/propertiesPartial/permission';

export const DEFAULT_PAGES = [
  {
    name: 'Informações',
    path: '/informations',
    isHidden: false,
    isDisabled: false,
    hasError: false,
    isFirstPage: true,
  },
  {
    name: 'Cômodos',
    path: '/rooms',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },
  {
    name: 'Medidas',
    path: '/measures',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },
  {
    name: 'Preço',
    path: '/financial',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },
  {
    name: 'Características do Imóvel',
    path: '/characteristics',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },
  {
    name: 'Características do Condomínio',
    path: '/characteristics-condo',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },
  {
    name: 'Localização',
    path: '/location',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },
  {
    name: 'Proximidades',
    path: '/establishments',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },

  {
    name: 'Descrição',
    path: '/description',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },
  {
    name: 'Complementos',
    path: '/complements',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },
  {
    name: 'Dados privativos',
    path: '/private',
    isHidden: false,
    isDisabled: true,
    hasError: false,
    isLastPage: true,
  },
  {
    name: 'Imagens do imóvel',
    path: '/imagesArea',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },
  {
    name: 'Publicação',
    path: '/publish',
    isHidden: false,
    isDisabled: true,
    hasError: false,
  },
];

export const AsideContext = React.createContext({
  pages: DEFAULT_PAGES,
  hide: null,
  show: null,
  disable: null,
  enable: null,
  enableAll: null,
  reset: null,
  permissions: [],
});

export const AsideProvider = ({ children }) => {
  const { id } = useId();
  const [pages, setPages] = useState(DEFAULT_PAGES);
  const [permissions, setPermissions] = useState([]);

  const setPage = useCallback(
    (pageName, values) => {
      setPages((prevPages) => {
        return prevPages.map((page) => {
          if (page.name === pageName) {
            return {
              ...page,
              ...values,
            };
          }

          return page;
        });
      });
    },
    [setPages]
  );

  // Esconde uma página
  const hide = useCallback(
    (pageName) => setPage(pageName, { isHidden: true }),
    [setPage]
  );

  // Mostra uma pagina
  const show = useCallback(
    (pageName) => setPage(pageName, { isHidden: false }),
    [setPage]
  );

  const disable = useCallback(
    (pageName) => setPage(pageName, { isDisabled: true }),
    [setPage]
  );

  const enable = useCallback(
    (pageName) => setPage(pageName, { isDisabled: false }),
    [setPage]
  );

  const enableAll = useCallback(
    (pageName) =>
      setPages((prevPages) => {
        return prevPages.map((page) => ({ ...page, isDisabled: false }));
      }),
    [setPages]
  );

  // Reseta para o state inicial
  const reset = useCallback(() => {
    setPages(DEFAULT_PAGES);
  }, []);

  useEffect(() => {
    if (id) {
      getOne(id).then(({ data }) => {
        setPermissions(data);
      });
    }
  }, [id]);

  useEffect(() => {
    if (!permissions?.includes('DYNAMIC_DOCUMENTS')) {
      hide('Anexar Documentos');
    } else {
      show('Anexar Documentos');
    }
  }, [permissions]);

  return (
    <AsideContext.Provider
      value={{
        pages,
        hide,
        show,
        disable,
        enable,
        reset,
        enableAll,
        permissions,
      }}
    >
      {children}
    </AsideContext.Provider>
  );
};
