import React from 'react';
import PropTypes from 'prop-types';
// Lib
import { addCurrency } from 'lib/money-helpers';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    broker: PropTypes.string,
    data: PropTypes.array
  })
};

const defaultProps = {
  data: {}
};

const BillingItem = ({ data: { period, value } }) => (
  <tr className="h-hover">
    <td className="h-sticky--left">{period}</td>
    <td className="h-sticky--right Table__cell--money">{addCurrency(value)}</td>
    <td />
  </tr>
);

BillingItem.propTypes = propTypes;
BillingItem.defaultProps = defaultProps;

export default BillingItem;
