import Alert from 'react-s-alert';
import { createModule } from '../lib/reducer-helpers';
import * as people from '../services/people';
import { SubmissionError } from 'redux-form';

const createAction = createModule('peopleGroups');

/**
 * Documents
 */
const REQUEST_DOCUMENTS = createAction('REQUEST_DOCUMENTS');
const RECEIVE_DOCUMENTS = createAction('RECEIVE_DOCUMENTS');
const RECEIVE_DOCUMENT = createAction('RECEIVE_DOCUMENT');
const DELETE_DOCUMENT = createAction('DELETE_DOCUMENT');

const SET_TOTAL_DOCUMENTS = createAction('SET_TOTAL_DOCUMENTS');
const UPLOAD_SUCCESS = createAction('UPLOAD_SUCCESS');
const UPLOAD_FAILED = createAction('UPLOAD_FAILED');
const RESET_UPLOAD = createAction('RESET_UPLOAD');

const RESET_DOCUMENTS = createAction('RESET_DOCUMENTS');

const initialStateUpload = {
  total: 0,
  success: 0,
  error: 0
};

// Initial State
const initialState = {
  documents: [],
  upload: initialStateUpload,
  meta: {
    isFetchingFiles: false,
    isFormAlter: false,
    isFetching: false
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_DOCUMENTS:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
        meta: { ...state.meta, isFetching: false }
      };
    case RECEIVE_DOCUMENT:
      return { ...state, documents: [action.note, ...state.documents] };
    case DELETE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter(note => note.id !== action.id)
      };
    case SET_TOTAL_DOCUMENTS:
      return { ...state, upload: { ...state.upload, total: action.total } };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        upload: {
          ...state.upload,
          success: state.upload.success + 1
        }
      };
    case UPLOAD_FAILED:
      return {
        ...state,
        upload: {
          ...state.upload,
          error: state.upload.error + 1
        }
      };
    case RESET_UPLOAD:
      return {
        ...state,
        upload: initialStateUpload
      };
    case RESET_DOCUMENTS:
      return initialState;
    default:
      return state;
  }
}

/**
 * Action Creators
 */
export function requestDocuments() {
  return { type: REQUEST_DOCUMENTS };
}

export function receiveDocuments(documents) {
  return { type: RECEIVE_DOCUMENTS, documents };
}

export function receiveDocument(note) {
  return { type: RECEIVE_DOCUMENT, note };
}

export function deleteDocument(id) {
  return { type: DELETE_DOCUMENT, id };
}

export function setTotalDocuments(total) {
  return { type: SET_TOTAL_DOCUMENTS, total };
}

export function uploadSuccess() {
  return { type: UPLOAD_SUCCESS };
}

export function uploadFailed() {
  return { type: UPLOAD_FAILED };
}

export function resetUpload() {
  return { type: RESET_UPLOAD };
}

export function resetDocuments() {
  return { type: RESET_DOCUMENTS };
}

export function getDocuments(people_id) {
  return dispatch => {
    dispatch(requestDocuments());

    people.getAllDocuments(people_id).then(({ data }) => {
      dispatch(receiveDocuments(data));
    });
  };
}

export function restaureDocument({ id, people_id }) {
  return dispatch => {
    return people.reactivateDocument({ id, people_id }).then(({ data }) => {
      dispatch(receiveDocument(data));
      Alert.success(`A exclusão do arquivo ${data.name} foi cancelada`);
    });
  };
}

export function removeDocument({ id, people_id }) {
  return dispatch => {
    return people.removeDocument({ id, people_id }).then(() => {
      Alert.success('Documento excluido', {
        customFields: {
          id,
          confirmText: 'Desfazer',
          onConfirm: () => {
            restaureDocument({ id, people_id })(dispatch);
          }
        }
      });
      dispatch(deleteDocument(id));
    });
  };
}

export function handleFormChange({ people_id, documents }) {
  return dispatch => {
    const filesToUpload = [];

    if (documents) {
      const totalDocuments = documents.length;
      dispatch(setTotalDocuments(totalDocuments));

      documents.map(document => {
        filesToUpload.push(
          people
            .addDocument({
              people_id,
              document
            })
            .then(({ data }) => {
              dispatch(receiveDocument(data));
              dispatch(uploadSuccess());
            })
            .catch(error => {
              dispatch(uploadFailed());

              if (error instanceof SubmissionError) {
                if (error.errors.document) {
                  Alert.success(
                    `Erro ao enviar o arquivo ${document.name}. O formato ${document.type} não é suportado`
                  );
                }
              }

              if (error.response) {
                if (error.response.status === 413) {
                  Alert.success(
                    `O arquivo <b>${document.name}</b> excedeu o limite de 10mb.`,
                    {
                      html: true
                    }
                  );
                } else {
                  Alert.success(
                    `O arquivo <b>${document.name}</b> não pode ser enviado`,
                    {
                      html: true
                    }
                  );
                }
              }
            })
        );
        return document;
      });

      // quando termina o upload de todos os arquivos reseta
      Promise.all(filesToUpload).then(res => {
        dispatch(resetUpload());
      });
    }
  };
}

export function clearDocuments() {
  return dispatch => {
    dispatch(resetDocuments());
  };
}
