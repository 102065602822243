import moment, { format } from '../lib/moment';
import { plural } from 'lib/text';

const MAX_MINUTES = 1439;

/**
 *
 * @param date
 * @returns {boolean}
 */
export const isToday = (date) => {
  if (!date) return false;
  const convertedDate = moment(date, 'DD/MM/YYYY').format('MM/DD/YYYY');
  return date ? moment().isAfter(convertedDate) : false;
};

export const now = (fmt = format.date) => moment().format(fmt);

export const minuteToHours = (minute) => {
  const hours = Math.floor(minute / 60)
    .toString()
    .padStart('2', '0');
  const minutes = (minute % 60).toString().padStart('2', '0');

  return `${hours}:${minutes}`;
};

/**
 * Transforma hora em string
 * @param hourMinute - Hour
 * @example
 * hourToMinute("08:00")
 * hourToMinute("12:00")
 * @return {*}
 */
export const hourToMinute = (hourMinute) => {
  let [hour, minute] = hourMinute.split(':');
  hour = parseInt(hour, 10) * 60;
  minute = parseInt(minute, 10);
  return hour + minute;
};

export const getHours = (interval = 30, MAX = MAX_MINUTES) => {
  let hours = [];
  let minutes = 0;

  do {
    hours = [
      ...hours,
      { value: minuteToHours(minutes), label: minuteToHours(minutes) },
    ];

    minutes = minutes + interval;
  } while (minutes < MAX);

  hours = [...hours, { value: minuteToHours(MAX), label: minuteToHours(MAX) }];

  return hours;
};

export const isValidDate = (date) => moment(date, format.date).isValid();

export const getDuration = (endDate, formatParam = format.date) => {
  const start = moment();
  const end = moment(endDate, formatParam);
  return moment.duration(start.diff(end));
};

export const getDurationInDays = (endDate, formatParam = format.date) =>
  Math.abs(parseInt(getDuration(endDate, formatParam).asDays(), 10));

export const pluralizeDays = (interval) => {
  if (interval === 0) return 'hoje';

  if (interval > 30) {
    return plural(interval / 30, ' mês', ' meses', true);
  }
  if (interval > 7) {
    return plural(interval / 7, ' semana', ' semanas', true);
  }

  return plural(interval, ' dia', ' dias', true);
};

/**
 * Date time to date
 * @param date
 * @param fmt
 * @return {string}
 */
export const dateTimeToDate = (date, fmt = format.datetime) =>
  moment(date, fmt).format(format.date);

export function getCurrentWeek() {
  const currentDate = moment();

  const weekStart = currentDate.clone().startOf('week');
  const weekEnd = currentDate.clone().endOf('week');

  return {
    weekStart: weekStart.format(format.date),
    weekEnd: weekEnd.format(format.date),
  };
}

export function formatLastInteraction(interactionDate) {
  const currentDate = moment();
  const diff = moment.duration(
    currentDate.diff(moment(interactionDate, 'DD/MM/YYYY'))
  );
  const days = diff.asDays();

  let finalResult;

  if (days >= 30) {
    const months = diff.asMonths();
    if (months >= 12) {
      const years = diff.asYears();
      finalResult = `${years} ano${years > 1 ? 's' : ''}`;
    } else {
      finalResult = `${months} mês${months > 1 ? 'es' : ''}`;
    }
  } else {
    finalResult = `${days} dia${days > 1 ? 's' : ''}`;
  }

  finalResult += ' - ' + moment(interactionDate, 'DD/MM/YYYY');

  return finalResult;
}

export function humanizeSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const parts = [];

  if (hours > 0) {
    parts.push(`${hours}h`);
  }

  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }

  if (remainingSeconds > 0) {
    parts.push(`${remainingSeconds}s`);
  }

  return parts.join(' e ');
}
