import React, { useCallback, useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import pages from './pages';
import { useSelector } from 'react-redux';
import { permissionsSelector } from 'modules/login';

const Routes = () => {
  const permissions = useSelector(permissionsSelector);
  const { path } = useRouteMatch();

  const can = useCallback(
    (action) => {
      return permissions?.includes(action);
    },
    [permissions]
  );

  const redirect = useMemo(() => {
    if (can('EDIT_REAL_ESTATE')) return path + '/realestate';
    if (can('EDIT_DOMAIN')) return path + '/domains';
    if (can('EDIT_SITE')) return path + '/chat';
    if (can('EDIT_LGPD_CONFIG')) return path + '/lgpd';
  }, [permissions]);

  if (!permissions) return false;

  return (
    <Switch>
      <Route path={`${path}/realestate`} component={pages.CompanyData} />
      <Route path={`${path}/domains`} component={pages.Domains} />
      <Route path={`${path}/logo/:type`} component={pages.LogoOptions} />
      <Route path={`${path}/chat`} component={pages.Chat} />
      <Route path={`${path}/popup`} component={pages.Popup} />
      <Route path={`${path}/gallery`} component={pages.Gallery} />
      <Route path={`${path}/seo`} component={pages.Seo} />
      <Route path={`${path}/crm/own`} component={pages.ConfigCrmOwn} />
      <Route path={`${path}/crm/orulo`} component={pages.ConfigCrmOrulo} />
      <Route path={`${path}/crm/dwv`} component={pages.ConfigCrmDwv} />
      <Route path={`${path}/scripts/show`} component={pages.ScriptsPages} />
      <Route path={`${path}/global-config`} component={pages.GlobalConfig} />
      <Route path={`${path}/lgpd`} component={pages.Lgpd} />
      <Route
        path={`${path}/property-contact`}
        component={pages.PropertyContact}
      />
      <Route
        path={`${path}/scripts/create`}
        component={pages.ScriptsPagesForm}
      />
      <Route
        path={`${path}/scripts/update/:id`}
        component={pages.ScriptsPagesForm}
      />
      <Route path={`${path}/visit-schedule`} component={pages.VisitSchedule} />
      <Redirect to={redirect} />
    </Switch>
  );
};

export default Routes;
