import React from 'react';
import * as PropTypes from 'prop-types';
import { Header, Title, BackButton, List, Item } from './styles';
import { MdChevronLeft } from 'react-icons/all';

const VariableSelect = ({ data, isFetching, onClose, onClick }) => {
  if (isFetching) return false;

  return (
    <>
      <BackButton onClick={onClose}>
        <MdChevronLeft /> Voltar
      </BackButton>
      <Header>
        <Title>{data.title}</Title>
      </Header>
      <List>
        {data?.items?.map(({ title, variable }) => (
          <Item
            key={variable}
            onClick={() => {
              if (onClick) onClick({ type: variable });
            }}
          >
            {title}
          </Item>
        ))}
      </List>
    </>
  );
};

VariableSelect.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
  }),
};

export default VariableSelect;
