import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { Input } from 'components/Form';
import Alert from 'components/Alert';
import Textarea from 'components/Form/components/Textarea';
import GroupControl from 'components/GroupControl';
import GroupItem from 'components/GroupControl/components/GroupItem';
// Assets
import seo from './seo.png';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const FormPublication = () => (
  <>
    <Row>
      <Col xs={12}>
        <Alert color="danger" style={{ marginBottom: '20px' }}>
          Recomendamos que você não edite essa parte, visto que seu site já está
          otimizado para o Google.
        </Alert>
      </Col>
    </Row>
    <Row middle="xs">
      <Col xs={7}>
        <Row>
          <Field
            xs={12}
            label="Título do imóvel para o site"
            name="title"
            placeholder="Digite o título do imóvel"
            component={Input}
            maxLength={22}
          />
          <Col xs={12}>
            <GroupControl label="URL Personalizada">
              <GroupItem label="https://seusite.com.br/" size="small" />
              <GroupItem>
                <Field
                  name="custom_url"
                  component={Input}
                  placeholder="Digite a url"
                />
              </GroupItem>
            </GroupControl>
          </Col>
          <Field
            xs={12}
            label={
              <>
                Título da página{' '}
                <a
                  href="https://support.google.com/webmasters/answer/35624?hl=pt-BR"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="h-link"
                >
                  (saiba mais)
                </a>
              </>
            }
            name="meta_title"
            placeholder="Digite o título"
            component={Input}
            maxLength={120}
          />
          <Field
            xs={12}
            type="simple"
            label="Descrição"
            name="meta_description"
            placeholder="Digite a descrição"
            maxLength={120}
            component={Textarea}
          />
        </Row>
      </Col>
      <Col xs={5}>
        <img
          src={seo}
          alt=""
          style={{ marginLeft: '20px', width: 'calc(100% - 20px)' }}
        />
      </Col>
    </Row>
  </>
);

FormPublication.defaultProps = defaultProps;
FormPublication.propTypes = propTypes;

export default FormPublication;
