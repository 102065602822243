import styled, { css } from 'styled-components';
import { readableColor, transparentize } from 'polished';
import { SIZES } from './constants';
import { colors } from 'constants/styles';

export const Overlay = styled.span`
  display: flex;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 9px;
    height: 100%;
    flex-shrink: 0;
  }
`;

export const Background = styled.span`
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  border-radius: 4px 0 0 4px;
  flex-grow: 1;
  padding: 0 25px;
`;

const size = ({ theme: { size } }) => {
  switch (size) {
    case SIZES.SMALL:
      return css`
        height: 25px;
        line-height: 23px;
        padding: 0 18px 0 15px;
      `;
    case SIZES.LARGE:
      return css`
        height: 30px;
        line-height: 28px;
        padding: 0 18px 0 15px;
      `;
    case SIZES.MEDIUM:
    default:
      return css`
        height: 25px;
        line-height: 23px;
        padding: 0 18px 0 15px;
      `;
  }
};

const getColorText = (p) => {
  const currentColor = p?.theme?.color || colors.primary;
  const isHex = p?.theme?.colorName.includes('#');

  // Verifica se tem a propriedade color text no contexto
  if (p?.theme?.colorText) return p?.theme?.colorText;

  return isHex
    ? readableColor(currentColor, '#000', currentColor)
    : currentColor;
};

const color = (p) => {
  const currentColor = p?.theme?.color || colors.primary;
  const colorText = getColorText(p);

  if (p?.theme.colorName === 'enabled') {
    return css`
      color: #5f6470;
      background: transparent;

      ${Background} {
        background: #e4f4ff;
        border-color: #5f6470;
      }

      svg {
        fill: #e4f4ff !important;
        stroke: #5f6470 !important;
      }
    `;
  }

  if (p?.theme.colorName === 'disabled') {
    return css`
      color: #adb7c5;
      background: transparent;

      ${Background} {
        background: #fff;
        border-color: #adb7c5;
      }

      svg {
        fill: #fff !important;
        stroke: #adb7c5 !important;
      }
    `;
  }

  if (p?.theme?.colorName === 'white') {
    return css`
      color: #000;
      background: #fff;

      ${Background} {
        border-color: #000;
      }

      svg {
        fill: #fff !important;
        stroke: #000 !important;
      }
    `;
  }

  return css`
    color: ${colorText};

    ${Background} {
      background: ${p?.noTransparency
        ? currentColor
        : transparentize(0.8, currentColor)};
      border-color: ${colorText};
    }

    svg {
      stroke: ${colorText};
    }
  `;
};

export const Content = styled.div`
  position: relative;
  z-index: 5;
  white-space: nowrap;
`;

export const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  font-size: 14px;
  cursor: ${(p) => (!!p.onClick ? 'pointer' : 'default')};

  ${size};
  ${color};
`;
