import styled, { css } from 'styled-components';
import { Link } from '../../styles';

const shareButtonColors = ({ theme, color }) => {
  const _color = theme.colors[color];

  return css`
    background-color: ${_color};
  `;
};

export const Name = styled(Link)`
  text-transform: capitalize;
`;

export const ShareWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`;

export const ShareButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  ${shareButtonColors}

  svg {
    vertical-align: middle;
  }

  & + & {
    margin-left: 10px;
  }
`;
