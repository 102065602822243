import { Select } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { Col } from 'react-flexbox-grid';
import { Field } from 'redux-form';
import { getPagesCatalog } from 'services/settings/facebook';
// import { Wrapper } from './styles';

function FieldFacebookPagesCatalog({ facebook, onLoad }) {
  const businessId = useFormValue('business_id');

  const { data, fetchData, isFetching } = useRequest({
    request: getPagesCatalog,
    initialFetch: false,
  });

  useEffect(() => {
    if (businessId && facebook.accessToken) {
      fetchData({
        access_token: facebook.accessToken,
        business_id: businessId,
      });
    }
  }, [facebook, businessId]);

  useEffect(() => {
    if (data.length > 0 && !!onLoad) {
      onLoad(data);
    }
  }, [data]);

  const disabled = data.length <= 0 && !businessId;

  if (!businessId) return null;

  if (data?.length <= 0 && !isFetching) {
    return (
      <Col xs={7}>
        <p className="h-color--danger">Nenhuma página encontrada</p>
        <a
          href="https://tecimob.com.br/ajuda/criar-uma-pagina-no-facebook/"
          className="h-link"
          target="_blank"
          rel="noreferrer"
        >
          Clique aqui pra aprender a criar
        </a>
      </Col>
    );
  }

  return (
    <Field
      xs={7}
      required
      disabled={disabled}
      placeholder={!data ? 'Nenhuma página encontrada' : 'Selecione'}
      name="page_id"
      label="Página"
      valueKey="id"
      labelKey="name"
      options={data}
      component={Select}
    />
  );
}

export default FieldFacebookPagesCatalog;
