export default values => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (!values.description) {
    errors.description = REQUIRED_MSG;
  }

  if (values.type === 'neighborhood' && !values.neighborhood_id) {
    errors.neighborhood_id = REQUIRED_MSG;
  }

  if (values.type === 'city' && !values.city_id) {
    errors.city_id = REQUIRED_MSG;
  }

  return errors;
};
