import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import icone from './icone.png';
import Button from 'components/Button';
import { useFacebookLeads } from '../../context';

function AuthModal({ handleClose }) {
  const { handleLoginFacebook } = useFacebookLeads();

  return (
    <ModalTemplate
      title="Facebook - Integração com Formulários (Leads)"
      handleClose={handleClose}
    >
      <Row>
        <Col xs={5}>
          <img src={icone} alt="" style={{ marginTop: '-15px' }} />
        </Col>
        <Col xs={7}>
          <p>
            Ativando este recurso, ao criar formulários em sua Fan Page, todo
            Lead que preencher um formulário será cadastrado no Tecimob como
            Cliente na categoria Lead.
          </p>
          <br />
          <p className="h-color--danger">
            Importante, antes de clicar em conectar, você precisa ter uma
            página profissional ativa dentro do seu perfil
          </p>
            <br />
          <p>
            <p>
              1 - Ter uma página profissional ativa dentro do seu perfil
              <br />
              <a
                href="https://scribehow.com/shared/Criar_pagina_no_Facebook__3cpXWCg-QbGPaAAnyrDi7Q"
                className="h-link"
                target="_blank"
                rel="noreferrer"
              >
                Clique aqui para saber como fazer
              </a>
            </p>
            <br />
            <p>
              2 - Criar um formulário de coleta de Leads
              <br />{' '}
              <a
                href="https://scribehow.com/shared/Criar_formulario_no_Facebook__yL3z5lYdSsqtE42LV2ZANA"
                className="h-link"
                target="_blank"
                rel="noreferrer"
              >
                Clique aqui para saber como fazer
              </a>
            </p>
          <br />
          </p>

          <p>
            Se você já possui uma página ativa, clique em conectar para
            autorizar a integração do Tecimob com sua página
          </p>
        </Col>
      </Row>
      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button type="submit" color="success" onClick={handleLoginFacebook}>
          Autorizar Facebook
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

export default AuthModal;
