import React from 'react';
import BoxHelp from 'components/BoxHelp';

// import { Wrapper } from './styles';

const TOPICS = [
  {
    text: 'Cadastrar cliente',
    link: 'https://scribehow.com/shared/Cadastrar_Cliente__PK2iC8ThSzqt2_R26ms8WA?referrer=documents',
  },
  {
    text: 'Cadastrar perfil de busca',
    link: 'https://scribehow.com/shared/Cadastrar_perfil_de_busca__63nb6mrBSn-9vUiub1IcHA?referrer=documents',
  },
  {
    text: 'Encontrar imóveis pelo proprietário',
    link: 'https://scribehow.com/shared/Encontrar_imoveis_pelo_proprietario__aJ0-XuXoQxWQTcxoNFf3FA?referrer=documents',
  },
  {
    text: 'Adicionar imóveis a um cliente interessado',
    link: 'https://scribehow.com/shared/Adicionar_imoveis_a_um_cliente_interessado__f5qo1_iZS4C-dLGez64GBA?referrer=documents',
  },
  {
    text: 'Adicionar anotações na ficha do cliente',
    link: 'https://scribehow.com/shared/Adicionar_anotacao_na_ficha_do_cliente__AmfcnDR0R_eQ-RNhEqVtUw?referrer=documents',
  },
];

function Help() {
  return (
    <BoxHelp
      storageKey="people-help"
      topics={TOPICS}
      helpLink="https://tecimob.com.br/ajuda/clientes-2/"
      style={{
        marginTop: '25px',
      }}
    />
  );
}

export default Help;
