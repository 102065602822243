import { ModalClose, ModalContent } from 'components/Modal';
import Modal from 'react-modal';
import { ItemLeft, ItemRight, Items, ModalWrapper, Wrapper } from './styles';
import inviteIcon from './assets/icons/invite.svg';
import addUserIcon from './assets/icons/add-user.svg';
import Option from './components/Option';
import { useDispatch } from 'react-redux';
import { openModalInviteUserReception, openModalUser } from 'modules/modal';

function ModalAddUserOption({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
}) {
  const dispatch = useDispatch();

  const handleOpenModalAddUser = () => {
    dispatch(openModalUser());
  };

  const handleOpenModalInviteUser = () => {
    dispatch(openModalInviteUserReception());
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalWrapper className="Modal__wrapper">
        <ModalClose handleClose={handleClose} />
        <ModalContent>
          <Wrapper>
            <Items>
              <ItemLeft>
                <Option
                  icon={inviteIcon}
                  title="Enviar convite"
                  description={
                    <>Convide um novo usuário para <br />fazer parte da sua conta</>
                  }
                  action={handleOpenModalInviteUser}
                />
              </ItemLeft>
              <ItemRight>
                <Option
                  icon={addUserIcon}
                  title="Cadastrar diretamente"
                  description={<>Cadastre um novo usuário diretamente <br />inserindo os dados de acesso</>}
                  action={handleOpenModalAddUser}
                />
              </ItemRight>
            </Items>
          </Wrapper>
        </ModalContent>
      </ModalWrapper>
    </Modal>
  );
}

export default ModalAddUserOption;
