import styled from 'styled-components';
import google from './google.png';

export const Wrapper = styled.div`
  width: 100%;
  font-family: Arial, sans-serif;
  max-width: 600px;
`;

export const Logo = styled.img.attrs(() => ({
  src: google,
  alt: 'Logo do Google'
}))`
  margin-bottom: 10px;
`;
export const Box = styled.div`
  padding: 20px;
  border: 1px solid ${p => p.theme.borderColor};
  background-color: #fff;
  border-radius: ${p => p.theme.borderRadius};
`;
export const Url = styled.cite`
  font-size: 14px;
  line-height: 18.2px;
  margin-bottom: 5px;
  color: #202124;

  span {
    color: #5f6368;
  }
`;
export const Title = styled.h3`
  font-size: 20px;
  font-weight: 400;
  color: #1a0dab;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 5px 0;
`;
export const Description = styled.div`
  line-height: 1.58;
  color: #4d5156;
`;
