import React from 'react';
import { compose } from 'redux';
// Components
import Button from 'components/Button';
import NoContent from 'components/NoContent';
import MainTitle from 'components/MainTitle';
import FormVideos from './components/FormVideos';
// Services
import * as videosService from 'services/sites/videos';
import { responseMessage } from 'lib/service-helpers';
// HOC
import withReception from 'HOC/withReception';
// Assets
import image from './videos.png';

const propTypes = {};
const defaultProps = {};

class Videos extends React.Component {
  state = {
    formValues: {},
    isFetching: true,
  };

  componentDidMount() {
    videosService.getOne().then(({ data }) => {
      this.setState({ formValues: data });
    });
  }

  handleSubmit = (values) =>
    videosService.update(values).then(responseMessage('Salvo!'));

  render() {
    const { reception, handleClickAction } = this.props;

    if (reception.isVisible) {
      return (
        <NoContent
          full
          reversed
          image={image}
          title="Vídeos"
          text="Adicione quantos vídeos desejar na capa do seu site. Podem ser vídeos institucionais, vídeos de imóveis... a criatividade é sua!"
        >
          <Button color="secondary" size="large" onClick={handleClickAction}>
            Adicionar Vídeo
          </Button>
        </NoContent>
      );
    }

    return (
      <>
        <MainTitle
          title="Vídeos"
          text="Mostre o vídeo dos seus melhores imóveis na página inicial do site."
        />
        <FormVideos
          initialValues={this.state.formValues}
          onSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

Videos.propTypes = propTypes;
Videos.defaultProps = defaultProps;

export default compose(withReception('site/videos', true))(Videos);
