import classnames from 'classnames';

export default ({ modalSize, modalPosition }) => {
  let style = {};

  if (typeof modalSize === 'number') {
    style = {
      content: {
        maxWidth: modalSize
      }
    };
  }

  return {
    style,
    className: {
      base: classnames('Modal', {
        [`Modal--${modalSize}`]: typeof modalSize === 'string',
        [`Modal--${modalPosition}`]: modalPosition
      }),
      afterOpen: 'Modal--after-open',
      beforeClose: 'Modal--before-close'
    },
    overlayClassName: {
      base: `ModalOverlay ModalOverlay--modal-${modalPosition}`,
      afterOpen: 'ModalOverlay--after-open',
      beforeClose: 'ModalOverlay--before-close'
    },
    ariaHideApp: false,
    shouldCloseOnOverlayClick: false,
    closeTimeoutMS: 200,
    shouldCloseOnEsc: false,
    onAfterOpen: () => {
      const input = document.querySelector('.Modal input');

      if (input) {
        document.querySelector('.Modal input').focus();
      }
    }
  };
};
