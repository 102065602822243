import api from 'services';
import { responseMessage } from '../../lib/service-helpers';

const transformResponse = (res) => {
  const daysLimit = res.data.days_limit;
  if (daysLimit && daysLimit > 0) {
    res.data.has_days_limit = true;
  } else {
    res.data.has_days_limit = false;
  }

  return res;
};

/**
 * Busca os dados de visita
 * @returns {Promise<AxiosResponse<never>>}
 */
export const getOne = () => {
  return api.getOne('settings/sites/visit-schedule').then(transformResponse);
};

/**
 * Atualiza os dados de visita
 * @param values
 * @returns {Promise<AxiosResponse<*>>}
 */
export const update = (values) =>
  api
    .create('settings/sites/visit-schedule', values)
    .then(responseMessage('Agendamento atualizado'));

export const remove = () =>
  api
    .delete('settings/sites/visit-schedule')
    .then(responseMessage('Agendamento desativado'));
