import api from 'services';
import { responseMessage } from 'lib/service-helpers';

export const create = (data) =>
  api.create('crm/deals', data).then(responseMessage('Negócio adicionado!'));

export const getPeopleCount = (params) => {
  return api.getList('people/count', params);
};

export const getPropertiesCount = (params) => {
  return api.getList('properties/count', params);
};

export const getBookingCount = (params) => {
  return api.getList('properties/bookings-count', params);
};

export const counts = async ({ user_id }) => {
  const people = getPeopleCount({
    filter: {
      by_user_id: user_id,
      having_staged_deals: true,
    },
  });

  const propertiesPeople = getPropertiesCount({
    filter: {
      on_network: 'all',
      by_funnel_user_id: user_id,
    },
  });

  const matchedPeople = getPeopleCount({
    filter: {
      by_matched_status: 1,
      by_user_id_or_null: user_id,
    },
  });

  const matchedProperties = getPropertiesCount({
    filter: {
      on_network: 'all',
      by_matched_user_id: user_id,
      is_booked: false,
    },
  });

  const bookedProperties = getBookingCount({
    filter: {
      booking_user_id: user_id,
    },
    sort: null,
  });

  const [
    peopleCount,
    matchedCount,
    matchedPropertiesCount,
    bookedPropertiesCount,
    propertiesPeopleCount,
  ] = await Promise.all([
    people,
    matchedPeople,
    matchedProperties,
    bookedProperties,
    propertiesPeople,
  ]);

  return {
    peopleCount: peopleCount.data.count,
    matchedCount: matchedCount.data.count,
    matchedPropertiesCount: matchedPropertiesCount.data.count,
    propertiesPeople: propertiesPeopleCount.data.count,
    bookedPropertiesCount: {
      properties_count: bookedPropertiesCount.data.properties_count,
      people_count: bookedPropertiesCount.data.people_count,
      properties_price_sum: bookedPropertiesCount.data.properties_price_sum,
    },
  };
};

/**
 * Return all deals inactives
 * @param filter
 * @param sort
 * @param offset
 * @return {Promise<AxiosResponse<never>>}
 */
export const getDealsInactives = ({
  filter,
  sort = '-updated_at',
  offset = 1,
}) => {
  if (sort === undefined || sort === '' || sort === null) {
    sort = '-updated_at';
  }

  return api.getList('crm/deals/inactives', {
    filter,
    limit: 20,
    offset,
    sort,
  });
};
