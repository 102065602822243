import api from './index';
import { responseMessage } from 'lib/service-helpers';

export const getAll = (params) => api.getList('real-estates', params);
export const search = (name, params) => getAll({ ...params, filter: { name } });
export const getList = (params) =>
  api.getList('properties/real-estates', params);

export const updateSearchConfig = (values) =>
  api
    .update('real-estates/search-config', values)
    .then(responseMessage('Dados atualizados'));

/**
 * Atualiza os dados da imobiliária
 * @param params
 * @return {Promise}
 */
export const updateNoMessage = ({ id, ...params }) =>
  api.updateWithFile('real-estates/me', params);

export const update = (params) =>
  updateNoMessage(params).then(
    responseMessage('Dados da imobiliária atualizados')
  );

/**
 * Busca os dados da imobiliária
 * @return {Promise}
 */
export const getRealEstate = (params) =>
  api.getOne('real-estates/me', '', params).then((res) => {
    let currentResponse = res;

    currentResponse.data = {
      ...currentResponse.data,
      ...currentResponse.data.relations,
    };

    return res;
  });

/**
 * Atualiza a localização da imobiliária com base as coordenadas
 * @param {Object} coords - latitude e longitude
 * @return {Promise}
 */
export const updateLocationByCoords = (coords) =>
  api.create('real-estates/coordenates', coords).then(() => {
    const currentUser = localStorage.getItem('current-user');

    localStorage.setItem('current-user', {
      ...currentUser,
      real_estate: { ...currentUser.real_estate, has_coordinates: true },
    });
  });

/**
 * Atualiza a localização da imobiliaria
 * se o usuário for admin e se o usuário não estiver com o endereço setado
 */
export const updateRealEstateLocation = () => {
  // Pega o usuário que está atualmente logado
  const currentUser = localStorage.getItem('current-user');

  if (currentUser) {
    const { admin, real_estate } = currentUser;

    // Verifica se usuário é administrador
    // Se a imobiliaria que ele está logada já não tem uma localização setada
    if (admin && !real_estate.has_coordinates) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position.coords) {
          updateLocationByCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        }
      });
    }
  }
};

/**
 * Remove a logo
 * @return {Promise}
 */
export const removeLogo = () =>
  api.delete('real-estates/logo').then(responseMessage('Logo removida'));

export const updateGuru = (with_guru_portal) =>
  api.update('real-estates/guru-portal', { with_guru_portal });
