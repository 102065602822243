import React from 'react';
import { Field, reduxForm } from 'redux-form';
// Components
import { CarouselSelect, FieldBool } from 'components/Form';
// Assets
import Container from 'components/Container';
import ListOptions from 'components/ListOptions';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
// Assets
import cardHorizontal from 'assets/img/card-models/card-horizontal.png';

function FormPropertyConfig({ handleSubmit, submitting, pristine }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        component={CarouselSelect}
        options={[{ image_url: cardHorizontal, value: '1' }]}
        srcKey="image_url"
        valueKey="value"
      />
      <Container padding style={{ marginTop: '15px' }}>
        <ListOptions>
          <ListOptions.item
            title="Agrupar Imóveis"
            text="Agrupar os imóveis que são do mesmo condomínio no resultado da pesquisa do sistema?"
            renderOptions={() => <FieldBool name="with_grouped_condos" />}
          />
          <ListOptions.item
            title="Rede Guru"
            text="Incluir resultados da Rede Guru no resultado das pesquisas de imóveis"
            renderOptions={() => <FieldBool name="with_guru" />}
          />
        </ListOptions>
      </Container>

      <FixedBar>
        <FixedBar.spacer />
        <FixedBar.item>
          <Button
            type="submit"
            color="success"
            disabled={submitting || pristine}
          >
            Salvar
          </Button>
        </FixedBar.item>
      </FixedBar>
    </form>
  );
}

FormPropertyConfig.defaultProps = {};
FormPropertyConfig.propTypes = {};

export default reduxForm({
  form: 'FormPropertyConfig',
  enableReinitialize: true,
})(FormPropertyConfig);
