import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-leaflet';
import L from 'leaflet';

const markerNumber = number =>
  new L.DivIcon({
    className: 'MapIcon',
    popupAnchor: [0, -45],
    iconAnchor: [14, 40],
    iconSize: [28, 40],
    html: `
    <svg width="28" height="40" viewBox="0 0 28 40">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 40C14 40 28 22.2225 28 14.316C28 6.4095 21.732 0 14 0C6.26801 0 0 6.4095 0 14.316C0 22.2225 14 40 14 40Z" fill="#3C4858"/>
        <text x="50%" dy="45%" font-weight="600" fill="#ffffff" dominant-baseline="middle" text-anchor="middle">${number}º</text>
    </svg>
  `
  });

function MarkerNumber({ onClick, number, children, position }) {
  return (
    <Marker onClick={onClick} icon={markerNumber(number)} position={position}>
      {children}
    </Marker>
  );
}

MarkerNumber.propTypes = {
  number: PropTypes.number
};
MarkerNumber.defaultProps = {
  number: 1
};

export default MarkerNumber;
