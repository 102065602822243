import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;

  .Modal__footer {
    margin-top: -15px !important;
  }
`;

export const BoxLeft = styled.div`
  flex-shrink: 0;
  border-right: 1px solid #dedede;
  padding: 20px 20px 20px 0;
  margin-top: -20px;
  margin-bottom: -20px;

  @media all and (max-width: 1366px) {
    width: 77%;
  }

  @media all and (min-width: 1367px) {
    width: 886px;
  }
`;

export const BoxRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 294px;
  max-height: calc(100% + 40px);
  overflow: auto;
  margin-right: -20px;
  margin-top: -20px;
  margin-bottom: -20px;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 1366px) {
    width: calc(23% + 20px);
  }
`;

export const Box = styled.div`
  padding: 20px;
  flex-shrink: 0;
  & + & {
    border-top: 1px solid #dedede;
  }
`;
