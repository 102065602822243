import React, { useCallback } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { Input } from 'components/Form';
import Button from 'components/Button';
import FieldSelectPeople from 'containers/FieldPerson';
import { ModalClose } from 'components/Modal';
import { closeModal, openModalPersonAdd } from 'modules/modal';
import validate from './validate';
import { useDispatch, useSelector } from 'react-redux';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function FormRelatedPeople({
  handleSubmit,
  handleClose,
  data,
  currentIndex,
  handleRemove,
  actions
}) {
  const dispatch = useDispatch();
  const formValues = useSelector(getFormValues('personForm'));
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      return handleSubmit(e);
    },
    [handleSubmit]
  );

  const hasRemove = handleRemove && !!data;

  return (
    <form onSubmit={onSubmit}>
      <ModalClose color="primary" handleClose={handleClose} />
      <FieldSelectPeople
        name="person"
        onNewOptionClick={person => {
          dispatch(closeModal());

          delete person.id;

          setTimeout(() => {
            dispatch(
              openModalPersonAdd(person, () => {
                dispatch(closeModal());

                setTimeout(() => {
                  dispatch(openModalPersonAdd(formValues));
                }, 200);
              })
            );
          }, 200);
        }}
        parse={item => {
          return item;
        }}
      />
      <Field
        label="Descrição"
        name="description"
        component={Input}
        placeholder="Ex: Conjuge"
      />
      <Button type="submit" color="success" block>
        Adicionar
      </Button>
      {hasRemove && (
        <div className="h-margin-top--5">
          <Button
            color="white"
            colorText="danger"
            block
            onClick={() =>
              handleRemove(data, { actions, currentIndex, handleClose })
            }
          >
            Excluir
          </Button>
        </div>
      )}
    </form>
  );
}

FormRelatedPeople.defaultProps = defaultProps;
FormRelatedPeople.propTypes = propTypes;

export default reduxForm({
  form: 'FormRelatedPeople',
  validate,
  enableReinitialize: true
})(FormRelatedPeople);
