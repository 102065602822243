import React, { memo, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import TableItem from './components/Item';
import Head from './components/Head';
import Button from 'components/Button';
import { TableCustom } from './styles';

function TablePeopleStriped({
  isFetching,
  people,
  pagination,
  handlePersonDelete,
  handlePersonInative,
  handlePersonActive,
  handlePersonEdit,
  handleOpenPerson,
  handleOpenModalPerson,
  handleChangePage,
  handleResetFilter,
}) {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <TableCustom
      data={people}
      renderTitle={() => <Head />}
      renderItem={(person) => (
        <TableItem
          key={person.id}
          person={person}
          handlePersonDelete={handlePersonDelete}
          handlePersonEdit={handlePersonEdit}
          handleOpenPerson={handleOpenPerson}
          handlePersonInative={handlePersonInative}
          handlePersonActive={handlePersonActive}
          handleOpenModalPerson={handleOpenModalPerson}
        />
      )}
      pagination={{
        forcePage: pagination?.current_page - 1,
        pageCount: pagination?.total_pages,
        pageRangeDisplayed: 10,
        marginPagesDisplayed: 2,
        onPageChange: handleChangePage,
      }}
      noData={{
        title: 'Nenhum cliente encontrado',
        text: 'Tente pesquisar novamente.',
        children: (
          <Button
            onClick={handleResetFilter}
            color="danger"
            className="h-margin-top--10"
          >
            Limpar Pesquisa
          </Button>
        ),
      }}
    />
  );
}

export default memo(TablePeopleStriped);
