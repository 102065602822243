import api from './index';
import _ from 'lodash';
// Helpers
import { responseMessage } from 'lib/service-helpers';

const getPositionName = position => {
  position = parseInt(position, 10);

  switch (position) {
    case 1:
      return 'Head';
    case 2:
      return 'Início do Body';
    case 3:
      return 'Fim do Body';
    default:
      return 'Nenhuma posição';
  }
};

const transformScript = script => {
  script.positionName = getPositionName(script.position);
  script.locationName = 'Todas as páginas';
  return script;
};

const transformResponse = res => {
  // Verifica se é um array
  if (_.isArray(res.data)) {
    _.map(res.data, transformScript);
    return res;
  }

  transformScript(res.data);

  return res;
};

/**
 * Busca todos os scripts cadastrados ativos
 * @param params
 * @return {Promise<any>}
 */
export const getList = params =>
  api.getList('settings/sites/scripts', params).then(transformResponse);

/**
 * Busca um script
 * @param params - { id: 'ID_SCRIPT' }
 */
export const getOne = (id, params) =>
  api.getOne('settings/sites/scripts', id, params).then(transformScript);

/**
 * Cadastra um script
 * @param values
 * @returns {*|AxiosPromise<any>}
 */
export const create = values =>
  api
    .create('settings/sites/scripts', values)
    .then(responseMessage('Script criado!'))
    .catch(responseMessage('Erro ao criar script!'));

/**
 * Cadastra um script
 * @param values
 * @returns {*|AxiosPromise<any>}
 */
export const update = values =>
  api
    .update('settings/sites/scripts', values)
    .then(responseMessage('Script alterado!'))
    .catch(responseMessage('Erro ao alterar script!'));

export const updateActiveScript = (value, uuid) => {
  api
    .update(`settings/sites/scripts/${uuid}`, {active: value})
    .then(responseMessage('Status do script alterado!'))
    .catch(responseMessage('Erro ao alterar status!'));
}
/**
 * Remove um script
 * @param id
 * @return {Promise<any>}
 */
export const remove = id =>
  api
    .delete('settings/sites/scripts', id)
    .then(responseMessage('Script excluído'));
