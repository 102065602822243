import styled, { css } from 'styled-components';

export const Wrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;

export const LeftSide = styled.div``;
export const RightSide = styled.div``;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 20px;
  }

  .FormGroup {
    margin-bottom: 0;
  }

  input {
    width: 100%;
  }
`;
export const Label = styled.div`
  font-weight: 600;
`;
export const Option = styled.div`
  min-width: 120px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  h1 {
    font-size: 14px;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 15px;

  button {
    cursor: pointer;
    color: ${(p) => p.theme.colors.secondary};
    text-decoration: underline;
  }
`;

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-bottom: 25px;
`;

const btnActive = ({ isActive, theme }) => {
  if (isActive) {
    return css`
      color: ${theme.colors.secondary};
      border-color: ${theme.colors.secondary};
    `;
  }

  return css`
    color: #d3dceb;
    border-color: #d3dceb;
  `;
};

export const Btn = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid transparent;
  background: #ffffff;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  transition: all 0.3s ease-in-out;

  ${btnActive};
`;
