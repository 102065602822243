export const TYPES = {
  APARTAMENTO: '5d217d2e-4167-4fab-98a0-230fa5a2150d',
  CASA: '547b2e22-fe51-41c3-a1b0-439b16eee45b',
  CHACARA: '1f372b14-788d-4a65-ba9b-33368a4574a4',
  FAZENDA: 'fa7be966-14c5-402e-ace8-ef14af142969',
  FLAT: '5fee9199-6760-4263-a218-70dd00c3138f',
  GARAGEM: '7425a24c-a08e-47a4-9794-7fb47dbdbe54',
  HOTEL: 'd966c54d-9bb9-47a5-af13-3cca9a5c4196',
  ILHA: 'e843f993-80c8-4111-9b3e-83ae3a6f0b20',
  INDUSTRIA: 'c42a0fef-1381-48cc-8b0e-04a11943a0ba',
  LOJA: '4085c266-4d69-4253-940f-c2252726286d',
  LOTEAMENTO: '0eb61875-96ff-4336-ad24-5dca3d931950',
  PAVILHAO_GALPAO: 'aed416ec-3744-49fc-839c-3242f4723447',
  PONTO_COMERCIAL: '79e896bd-04d8-4ae6-a76c-9f4366dc6c23',
  POUSADA: 'facf5edf-aeed-4834-a41c-d6d0dece7c69',
  PREDIO: '24274e19-446e-423e-8d82-36c38c157101',
  RANCHO: 'c8967062-f73f-4328-91d4-a959249ec341',
  SALA: '60109a7f-d33b-4e2c-942f-f2fb895a2335',
  SALA_COMERCIAL: 'a5d4e8a1-240c-4234-9666-b48251eb1e40',
  SALAO_COMERCIAL: '79886a07-af96-485b-8053-a54bb33aa19e',
  SOBRADO: '234be176-e611-4d06-9b5b-221dfd4836da',
  SITIO: '97dbea1c-a94e-4bcc-a3f9-005d343d8d29',
  TERRENO: '477cb884-a249-4b2a-9764-3839a1b05746',
  AREA: 'e495b76a-0ee8-4c13-99ee-c98729eae112'
};
