export default values => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (!values.title) {
    errors.title = REQUIRED_MSG;
  }

  if (values.alert === 1) {
    if (!values.renew_date) {
      errors.renew_date = 'Campo obrigatório';
    }
  }

  return errors;
};
