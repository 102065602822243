// Modules
import createDataReducer from 'modules/data';
// Services
import * as footersService from 'services/sites/footers';

export const {
  selectors: footersSelectors,
  actionCreators: headersActions,
  reducer
} = createDataReducer('components/footers');

/**
 * Busca todos os modelos de cabeçalhos
 * @param params
 * @return {Promise}
 */
export const fetchFooters = params => dispatch => {
  dispatch(headersActions.request());

  return footersService.getComponents(params).then(res => {
    dispatch(headersActions.receive(res.data));
    return res;
  });
};

export default reducer;
