import React from 'react';
import { plural } from 'lib/text';
import { Field, reduxForm } from 'redux-form';
import { Checkbox } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
// Services
import * as people from 'services/people';
import Loading from 'components/Loading';

// import { Container } from './styles';

const Form = ({ handleSubmit, person, onCancel, handleClose, submitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      {submitting && <Loading isVisible isFullScreen />}
      {person.properties_count > 0 ? (
        <>
          <span className="h-color--danger">
            Esse cliente possui{' '}
            {plural(
              person.properties_count,
              'imóvel relacionado',
              'imóveis relacionados',
              true
            )}
            . Ao removê-lo, os imóveis também serão removidos. Deseja remover
            mesmo assim?
          </span>
          <div>
            <Field
              type="checkbox"
              label="Excluir os imóveis deste proprietário."
              name="can_delete"
              component={Checkbox}
              className="h-margin-top--10"
            />
          </div>
        </>
      ) : (
        <p>O cliente será excluido e seus dados serão deletados</p>
      )}

      <ModalFooter>
        <Button
          color="white"
          colorText="secondary"
          type="button"
          onClick={() => {
            handleClose();
            if (onCancel) onCancel();
          }}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button disabled={submitting} type="submit" color="danger">
          Remover
        </Button>
      </ModalFooter>
    </form>
  );
};

export default reduxForm({
  form: 'ModalPersonRemove',
  validate: (values) => {
    let errors = {};

    if (
      values?.properties_count > 0 &&
      (!values?.can_delete || values?.can_delete === false)
    ) {
      errors.can_delete = ['Campo obrigatório'];
    }

    return errors;
  },
  enableReinitialize: true,
  onSubmit: (values) => {
    return people.remove(values?.id);
  },
  onSubmitSuccess: (response, dispatch, props) => {
    props?.handleClose();

    // Verifica se tem a propriedade onSuccess
    if (props?.onSuccess) props?.onSuccess(response);
  },
})(Form);
