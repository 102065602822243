import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { Input } from 'components/Form';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Button from 'components/Button';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function FormEstablishment({ handleSubmit, handleClose }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field label="Título" name="title" component={Input} />
      <ModalFooter>
        <Button color="white" colorText="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

FormEstablishment.defaultProps = defaultProps;
FormEstablishment.propTypes = propTypes;

export default reduxForm({
  form: 'FormEstablishment',
  enableReinitialize: true
})(FormEstablishment);
