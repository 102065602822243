import { Financial } from 'features/Financial';
import { Routes } from './routes';

export default function FinancialPage() {
  return (
    <Financial.Layout>
      <Routes />
    </Financial.Layout>
  );
}
