import Cities from "../../components/Cities";
import Neighborhoods from "../../components/Neighborhoods";

function ContainerLocations({ citiesDescriptions, neighborgoodsDescriptions }) {
  return (
    <>
      <Cities data={citiesDescriptions} />
      <Neighborhoods data={neighborgoodsDescriptions} />
    </>
  );
}

export default ContainerLocations;
