import React, { useCallback, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Field } from 'redux-form';

// Components
import GroupControl, { GroupItem } from 'components/GroupControl';
import { Input } from 'components/Form';
import Button from 'components/Button';

// Helpers
import { normalizeCep } from 'lib/formHelpers';
import useReduxForm from 'hooks/useReduxForm';
import { useLocation } from 'containers/LocationAsyncFields/context';
import * as cepsService from 'services/ceps';
import Alert from 'react-s-alert';
import AlertTC from 'components/Alert';
import qs from 'qs';
import { plural } from 'lib/text';
import useFormValue from 'hooks/useFormValue';
import * as propertiesService from 'services/properties';

const Cep = ({ onChangeCep }) => {
  const zipCode = useFormValue('zip_code');
  const [propertiesCount, setPropertiesCount] = useState(0);
  const { change, dispatch } = useReduxForm();
  const { countryName, stateName, cityName, neighborhoodName, reset } =
    useLocation();

  const fetchProperties = useCallback(
    async (cep) => {
      setPropertiesCount(0);
      const count = await propertiesService.getCountPropertiesByCep(cep);
      setPropertiesCount(count);
    },
    [setPropertiesCount]
  );

  const fetchCep = useCallback(
    async (cep) => {
      try {
        const { data } = await cepsService.getLocation({ cep });

        reset();
        fetchProperties(cep);

        dispatch(change(countryName, data?.country?.id));
        dispatch(change(stateName, data?.state?.id));
        dispatch(change(cityName, data?.city?.id));
        dispatch(change(neighborhoodName, data?.neighborhood?.id));

        dispatch(change('street_address', data?.street_address));
        dispatch(change('street_number', data?.street_number));

        if (data?.latitude) dispatch(change('maps_latitude', data?.latitude));
        if (data?.longitude)
          dispatch(change('maps_longitude', data?.longitude));

        if (onChangeCep) onChangeCep(data);
      } catch (e) {
        if (e.response.status === 404) {
          Alert.success(`Ops! Não encontramos dados para o CEP ${cep}.`);
        }
      }
    },
    [countryName, stateName, cityName, neighborhoodName]
  );

  const handleChangeZipCode = useCallback((e, value, prevValue) => {
    if (!value || value === prevValue) return false;
    if (value.length === 9) {
      fetchCep(value);
    }
  }, []);

  return (
    <Row>
      <Col xs={4}>
        <GroupControl label="CEP">
          <GroupItem>
            <Field
              name="zip_code"
              component={Input}
              normalize={normalizeCep}
              onKeyPress={(e) => {
                if (e.which === 13) e.preventDefault();
              }}
              onChange={handleChangeZipCode}
            />
          </GroupItem>
          <GroupItem size="small">
            <Button className="h-margin-left--10">Buscar</Button>
          </GroupItem>
        </GroupControl>
      </Col>
      {propertiesCount > 0 && (
        <Col xs={4}>
          <AlertTC color="danger" style={{ padding: 10, marginTop: 20 }}>
            <a
              href={`/properties/search?${qs.stringify({
                filter: {
                  zip_code: zipCode,
                  status: [1, 2, 3, 4],
                  without_draft_scope: true,
                },
                limit: 50,
                offset: 1,
                sort: '-calculated_price',
              })}`}
              target="_blank"
              rel="noreferrer"
              className="h-link"
            >
              {plural(
                propertiesCount,
                'imóvel encontrado',
                'imóveis encontrados'
              )}{' '}
              com esse CEP
            </a>
          </AlertTC>
        </Col>
      )}
    </Row>
  );
};

export default Cep;
