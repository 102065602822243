import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
// Components
import Button from 'components/Button';
import TextLoading from 'components/TextLoading';
import { RadioCard } from 'components/Form';
// Modules
import { openModalBankSlip, openModalCreditCard } from 'modules/modal';
// Assets
import bolt from './bolt.svg';

const defaultProps = {
  creditCard: null,
  bankSlip: null,

  change: null,
  users: null,
  onAddCardSuccess: null,
  onAddCardFail: null,
  onBankSlipSuccess: null,
  onBankSlipFail: null,
  openModalCreditCard: null
};

const propTypes = {
  creditCard: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.string,
      brand: PropTypes.string
    }),
    isFetching: PropTypes.bool
  }),
  bankSlip: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string,
      real_estate_id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.number,
      cpf_cnpj: PropTypes.string,
      should_send_email: PropTypes.bool
    }),
    isFetching: PropTypes.bool
  }),

  change: PropTypes.func,
  users: PropTypes.number,
  onBankSlipSuccess: PropTypes.func,
  onBankSlipFail: PropTypes.func,
  onAddCardSuccess: PropTypes.func,
  onAddCardFail: PropTypes.func,
  openModalCreditCard: PropTypes.func
};

class BoxPaymentMethod extends React.Component {
  get creditCard() {
    try {
      return this.props.creditCard.data;
    } catch (e) {
      return {};
    }
  }

  get bankSlip() {
    try {
      return this.props.bankSlip.data;
    } catch (e) {
      return {};
    }
  }

  get hasBankSlip() {
    try {
      return !!this.bankSlip.id;
    } catch {
      return false;
    }
  }

  get hasCreditCard() {
    try {
      return !!this.creditCard.id;
    } catch {
      return false;
    }
  }

  get labelCard() {
    const { creditCard } = this.props;

    if (creditCard.isFetching) {
      return (
        <>
          <div>
            <TextLoading width={80} height="1em" />
          </div>
          <div>
            <TextLoading width={120} height="1em" />
          </div>
        </>
      );
    }

    const boltText = (
      <div style={{ fontSize: '12px' }}>
        <img
          src={bolt}
          alt=""
          style={{ display: 'inline-block', marginBottom: '-2px' }}
        />{' '}
        Ativação relampago
      </div>
    );

    if (this.hasCreditCard) {
      const { number, brand } = this.creditCard;

      return (
        <>
          <div>
            <span style={{ textTransform: 'capitalize' }}>{brand}</span> final{' '}
            {number}
          </div>
          {boltText}
        </>
      );
    }

    return (
      <>
        <div>Pagar com cartão</div>
        {boltText}
      </>
    );
  }

  get labelBankSlip() {
    const { bankSlip } = this.props;

    if (bankSlip.isFetching)
      return (
        <>
          <div>
            <TextLoading width={80} height="1em" />
          </div>
          <div>
            <TextLoading width={120} height="1em" />
          </div>
        </>
      );

    return (
      <>
        <div>Pagar com boleto</div>
        <div style={{ fontSize: '12px' }}>Ativação de 1 à 5 dias</div>
      </>
    );
  }

  handleClickChangeCard = e => {
    const { openModalCreditCard, onAddCardSuccess, onAddCardFail } = this.props;

    e.stopPropagation();

    openModalCreditCard({
      onSubmitSuccess: onAddCardSuccess,
      onSubmitFail: onAddCardFail
    });
  };

  handleClickBankSlip = e => {
    const { openModalBankSlip, onBankSlipSuccess, onBankSlipFail } = this.props;
    e.stopPropagation();

    openModalBankSlip({
      bank_slip_id: this.bankSlip.id,
      onSubmitSuccess: onBankSlipSuccess,
      onSubmitFail: onBankSlipFail
    });
  };

  renderCardComponent = () => {
    const { creditCard } = this.props;

    if (creditCard.isFetching) {
      return <TextLoading height="1em" width="90px" />;
    }

    if (this.hasCreditCard) {
      return (
        <span className="h-link" onClick={this.handleClickChangeCard}>
          Trocar
        </span>
      );
    }

    return (
      <Button size="small" onClick={this.handleClickChangeCard}>
        Adicionar
      </Button>
    );
  };

  renderBankSlipComponent = () => {
    const { bankSlip } = this.props;

    if (bankSlip.isFetching) {
      return <TextLoading height="1em" width="90px" />;
    }

    if (this.hasBankSlip) {
      return (
        <span className="h-link" onClick={this.handleClickBankSlip}>
          Alterar dados
        </span>
      );
    }

    return (
      <Button size="small" onClick={this.handleClickBankSlip}>
        Preencher dados
      </Button>
    );
  };

  get options() {
    return [
      {
        label: this.labelCard,
        value: 2,
        component: this.renderCardComponent
      },
      {
        label: this.labelBankSlip,
        value: 1,
        component: this.renderBankSlipComponent
      }
    ];
  }

  handleChangePaymentMethod = () => {
    const { change } = this.props;
    if (!change) return null;

    change('months', 3);
  };

  render() {
    const { users } = this.props;

    return (
      <div className="BoxPlan BoxPlan--method">
        <header className="BoxPlan__header">
          <h1 className="BoxPlan__title">Método de pagamento</h1>
        </header>
        <Field
          name="receiving_method"
          labelKey="label"
          valueKey="value"
          componentKey="component"
          options={this.options}
          onChange={this.handleChangePaymentMethod}
          component={RadioCard}
        />

        {users && (
          <>
            <h4 className="BoxPlan__title" style={{ marginTop: '30px' }}>
              Dados da contratação
            </h4>

            <dl className="DefList">
              <div className="DefList__item">
                <dt>Corretores</dt>
                <dd>{users}</dd>
              </div>
            </dl>
          </>
        )}
      </div>
    );
  }
}

BoxPaymentMethod.defaultProps = defaultProps;
BoxPaymentMethod.propTypes = propTypes;

const mapDispatchToProps = {
  openModalCreditCard,
  openModalBankSlip
};

export default connect(null, mapDispatchToProps)(BoxPaymentMethod);
