import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import { FieldBool, Input } from 'components/Form';
import ModalFooter from 'components/Modal/components/ModalFooter';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormLink = ({ isEditting, handleSubmit, handleClose, submitting }) => (
  <form onSubmit={handleSubmit}>
    <Row style={{ marginBottom: -15 }}>
      <Field xs={6} label="Título do menu" name="label" component={Input} />
      <FieldBool
        xs={6}
        label="Abrir link na:"
        name="opens_link_on_page"
        options={[
          { label: 'Mesma página', value: true },
          { label: 'Outra página', value: false },
        ]}
      />
      <Field xs={12} label="Cole o link" name="link" component={Input} />
    </Row>
    <ModalFooter>
      <Button color="white" colorText="primary" onClick={handleClose}>
        Cancelar
      </Button>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success" disabled={submitting}>
        {isEditting ? 'Editar' : 'Adicionar'}
      </Button>
    </ModalFooter>
  </form>
);

FormLink.defaultProps = defaultProps;
FormLink.propTypes = propTypes;

export default compose(
  reduxForm({
    form: 'FormLink',
    enableReinitialize: true,
  })
)(FormLink);
