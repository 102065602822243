import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { Input } from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import validate from './validate';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

class PropertySaveModelForm extends React.Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form
        onSubmit={(e, ...rest) => {
          e.preventDefault();
          e.stopPropagation();
          return handleSubmit(...rest);
        }}
      >
        <Field
          autoFocus
          label="Nome do Modelo"
          name="name"
          placeholder="Nome do Modelo"
          component={Input}
        />
        <Button block type="submit" color="success">
          Salvar Modelo
        </Button>
      </form>
    );
  }
}

PropertySaveModelForm.defaultProps = defaultProps;
PropertySaveModelForm.propTypes = propTypes;

export default reduxForm({
  form: 'PropertySaveModelForm',
  validate,
  enableReinitialize: true
})(PropertySaveModelForm);
