// Services
import { getPeopleWithInteractions } from 'services/dashboards/people';
// Modules
import dataReducer from '../../../../modules/data';

const MODULE_NAME = 'peopleWithInteraction';

export const {
  reducer: peopleInteractionReducer,
  actionCreators: peopleInteractionActions
} = dataReducer(`peopleDashboard/${MODULE_NAME}`);

// Selectors
export const dataSelector = state => state.peopleDashboard[MODULE_NAME].data;
export const isFetchingSelector = state =>
  state.peopleDashboard[MODULE_NAME].meta.isFetching;

// Reducer
export const fetchPeopleInteractions = params => async dispatch => {
  dispatch(peopleInteractionActions.request());
  const { data } = await getPeopleWithInteractions();
  dispatch(peopleInteractionActions.receive(data));
  return data;
};

export default peopleInteractionReducer;
