// Libs
import { createValidation } from 'lib/validate-helpers';

export default values => {
  const errors = {};
  const validate = createValidation(values, errors);
  const REQUIRED_MSG = 'Campo obrigatório';

  if (!values.password) {
    errors.password = REQUIRED_MSG;
  } else if (values.password.length < 6) {
    errors.password = 'Senha deve possuir mais do que 6 caracteres';
  }

  if (!values.password_confirm) {
    errors.password_confirm = REQUIRED_MSG;
  }

  // Password confirm
  validate.password(['password', 'password_confirm']);

  return errors;
};
