import api from 'services/index';

/**
 * Pega as informações de site relacionadas a cabeçalhos
 * @param params
 * @return {Promise}
 */
export const getOne = params => api.getOne('settings/sites/videos', '', params);

/**
 * Atualiza as informações do site relacionadas a cabeçalhos
 * @param values
 * @return {Promise}
 */
export const update = values => api.update('settings/sites/videos', values);
