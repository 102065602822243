import React from 'react';
import {
  MdChat,
  MdCloudUpload,
  MdCreateNewFolder,
  MdInsertDriveFile,
  MdPalette,
  MdPerson,
  MdSearch,
} from 'react-icons/md';
// Components
import NavAside from 'components/NavAside';
import TreeNav from 'components/TreeNav';
// Containers
import Can from 'containers/Can';
// Constants
import { USER_PERMISSIONS } from 'constants/rules';

const Aside = () => {
  return (
    <NavAside>
      <NavAside.link to="/config/defaultTexts" icon={MdChat}>
        Mensagens Padrão
      </NavAside.link>
      <Can run="EDIT_SALES_AUTHORIZATION">
        <NavAside.link to="/config/documents" icon={MdInsertDriveFile}>
          Modelos de contrato
        </NavAside.link>
      </Can>
      <Can run={USER_PERMISSIONS.EDIT_BACKUP}>
        <NavAside.link to="/config/bkp/show" icon={MdCloudUpload}>
          Exportar dados
        </NavAside.link>
      </Can>
      <NavAside.link to="/config/properties" icon={MdSearch}>
        Pesquisa
      </NavAside.link>
      <NavAside.link to="/config/general" icon={MdPalette}>
        Topo do sistema
      </NavAside.link>
      <Can run="EDIT_SEARCH_PROFILE_CONFIG">
        <NavAside.link to="/config/profile" icon={MdPerson}>
          Perfil de interesse
        </NavAside.link>
      </Can>
      <Can run={USER_PERMISSIONS.EDIT_REGISTRATIONS}>
        <TreeNav
          to="/config/registrations"
          title="Meus Cadastros"
          icon={MdCreateNewFolder}
        >
          <Can run={USER_PERMISSIONS.EDIT_ESTABLISHMENTS}>
            <TreeNav.link to="/config/registrations/establishments">
              Estabelecimentos
            </TreeNav.link>
          </Can>
          <Can run={USER_PERMISSIONS.EDIT_CHARACTERISTICS}>
            <TreeNav.link to="/config/registrations/characteristics">
              Características
            </TreeNav.link>
          </Can>
          <Can run={USER_PERMISSIONS.EDIT_RECEPTION_SOURCES}>
            <TreeNav.link to="/config/registrations/receptionSources">
              Origem da captação
            </TreeNav.link>
          </Can>
          <Can run={USER_PERMISSIONS.EDIT_LOSS_REASONS}>
            <TreeNav.link to="/config/registrations/lossReasons">
              Motivos da perda
            </TreeNav.link>
          </Can>
          <Can run={USER_PERMISSIONS.EDIT_ADDRESSES}>
            <TreeNav.link to="/config/registrations/cities">
              Cidades
            </TreeNav.link>
          </Can>
          <Can run={USER_PERMISSIONS.EDIT_ADDRESSES}>
            <TreeNav.link to="/config/registrations/neighborhoods">
              Bairros
            </TreeNav.link>
          </Can>
          <Can run={USER_PERMISSIONS.EDIT_ADDRESSES}>
            <TreeNav.link to="/config/registrations/zones">Zonas</TreeNav.link>
          </Can>
          <Can run={USER_PERMISSIONS.EDIT_PEOPLE_GROUPS}>
            <TreeNav.link to="/config/registrations/groups">
              Categorias de clientes
            </TreeNav.link>
          </Can>
        </TreeNav>
      </Can>
    </NavAside>
  );
};

export default Aside;
