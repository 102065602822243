import styled from 'styled-components';
import { em as _em, readableColor } from 'polished';
import theme from 'components/ModelsPost/theme';

export const em = (pixels) => _em(pixels, 32);
export const readable = (color) => {
  try {
    return readableColor(color);
  } catch {
    return '#000';
  }
};

export const Wrapper = styled.div`
  position: relative;
  width: ${em(1080)};
  height: ${em(1920)};
  font-family: 'Montserrat', sans-serif;
  color: ${(p) => p.theme.mainColor};
  font-size: ${(p) => p.theme.fontSize};
`;

export const Image = styled.img`
  width: inherit;
  height: inherit;
  object-fit: cover;
`;

export const BoxWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(p) => p.theme.backgroundColor};
`;
export const BoxInner = styled.div`
  position: relative;
`;
export const BoxHeader = styled.div`
  transform: translateY(-40%);
  display: grid;
  place-items: center;
  padding: ${em(40)};
  max-width: 80%;
  margin: 0 auto;
  background: ${(p) => p.theme.mainColor};
  color: ${(p) => readable(p?.theme?.mainColor)};
  margin-top: ${em(-40)};
`;

export const Title = styled.div`
  font-size: ${em(64)};
  white-space: pre-wrap;
  text-align: center;
`;
export const Price = styled.div`
  text-align: center;
  color: ${(p) => readable(p?.theme?.backgroundColor)};
`;
export const Description = styled.div`
  text-align: center;
  margin: ${em(90)};
  color: ${(p) => readable(p?.theme?.backgroundColor)};

  > div {
    margin: ${em(30)};
  }
`;
export const Footer = styled.div`
  padding-bottom: ${em(50)};
  img {
    height: ${em(theme.logoSize, 32)};
    margin: 0 auto;
  }
`;
