import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// Components
import TablePeople from '../TablePeople';
import Container from 'components/Container';
import CardProperty from 'components/CardProperty';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { openModalProperty } from 'modules/modal';
import { useDispatch } from 'react-redux';

const propTypes = {
  people: PropTypes.arrayOf(PropTypes.object),
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title_formatted: PropTypes.string.isRequired,
    address_formatted: PropTypes.string.isRequired,
    calculated_price: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired
  }).isRequired
};
const defaultProps = {
  people: [],
  property: []
};

const ListPropertyMatched = ({
  isFetching,
  people,
  property,
  selected,
  openSendMail,
  handleToggleSelected,
  onClickDiscardPerson,
  handleDiscardSelecteds,
  handleDiscardAll,
  shareWhatsapp,
  onClickPerson
}) => {
  const dispatch = useDispatch();

  const selectedPeople = useMemo(() => {
    return _.filter(people, 'isSelected');
  }, [people]);

  const withEmail = useMemo(() => {
    return _.filter(people, 'email');
  }, [people]);

  const selectedWithEmail = useMemo(() => {
    return _.filter(selectedPeople, 'email');
  }, [selectedPeople]);

  const hasSelecteds = useMemo(() => {
    return selected?.length > 0;
  }, [selected]);

  const hasSelectedsWithEmail = useMemo(() => {
    return selectedWithEmail?.length > 0;
  }, [selectedWithEmail]);

  return (
    <Wrapper.inner>
      <Wrapper.container>
        <MainTitle
          title="Imóveis Compatíveis"
          text="Veja os clientes compatíveis com seu imóvel."
        >
          {hasSelectedsWithEmail ? (
            <Button color="success" onClick={openSendMail(selectedWithEmail)}>
              Enviar para selecionados por e-mail
            </Button>
          ) : (
            <Button
              disabled={withEmail.length <= 0}
              color="success"
              onClick={openSendMail(withEmail)}
            >
              Enviar para todos por e-mail
            </Button>
          )}
          {hasSelecteds ? (
            <Button color="danger" onClick={handleDiscardSelecteds}>
              Descartar selecionados
            </Button>
          ) : (
            <Button color="danger" onClick={handleDiscardAll}>
              Descartar todos
            </Button>
          )}
        </MainTitle>
        <Container>
          {isFetching ? (
            <Loading isVisible />
          ) : (
            <CardProperty
              isFetching={isFetching}
              key={property.id}
              type="horizontal"
              property={property}
              message={`Cadastrado em ${property?.created_at}`}
              handleClickProperty={() => {
                dispatch(openModalProperty({ property }));
              }}
            />
          )}
          <TablePeople
            data={people}
            onClickPerson={onClickPerson}
            handleToggleSelected={handleToggleSelected}
            openSendMail={openSendMail}
            onClickDiscardPerson={onClickDiscardPerson}
            shareWhatsapp={shareWhatsapp}
          />
        </Container>
      </Wrapper.container>
    </Wrapper.inner>
  );
};

ListPropertyMatched.propTypes = propTypes;
ListPropertyMatched.defaultProps = defaultProps;

export default ListPropertyMatched;
