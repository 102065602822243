import React from 'react';
import * as PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
// Components
import Button from 'components/Button';
// Constants
import { ACCEPT_ALL_FILES } from 'constants/config';

const propTypes = {
  onDropAccepted: PropTypes.func
};
const defaultProps = {
  avatar: false,
  accept: ACCEPT_ALL_FILES,
  preventDropOnDocument: false,
  multiple: true,
  className: 'Dropzone',
  activeClassName: 'Dropzone--active',
  acceptClassName: 'Dropzone--accept',
  rejectClassName: 'Dropzone--reject',
  disabledClassName: 'Dropzone--disabled',
  style: {},
  children: <Button color="primary">Enviar Foto</Button>
};

const UploadButton = ({ children, ...props }) => (
  <Dropzone {...props}>{children}</Dropzone>
);

UploadButton.propTypes = propTypes;
UploadButton.defaultProps = defaultProps;

export default UploadButton;
