import React, { useContext, useMemo, useEffect, useCallback } from 'react';
import qs from 'qs';
import { useInView } from 'react-intersection-observer';
import { ContentList } from '../List/styles';
import { Wrapper, Text, Item } from './styles';
import { SearchBarContext } from 'components/SearchBar/context';
import { useHistory } from 'react-router';

function CondoResults({ data }) {
  const history = useHistory();
  const { search, loadMoreByKey } = useContext(SearchBarContext);

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 1,
  });

  const openModal = useCallback(
    (id) => () => {
      const queryString = qs.stringify({
        filter: {
          condominium_id: id,
        },
        limit: 50,
        offset: 1,
        sort: '-calculated_price',
      });

      history.push(`/properties/search?${queryString}`);
    },
    []
  );

  const total = useMemo(() => {
    return data?.length;
  }, [data]);

  useEffect(() => {
    if (inView) {
      loadMoreByKey('condos');
    }
  }, [inView]);

  if (!data || data.length <= 0) return null;

  return (
    <ContentList>
      <header>
        <h1>Condomínios</h1>
      </header>
      <Wrapper>
        {data.map((condo, i) => {
          let _props = {};

          if (i + 1 === total) {
            _props = {
              ref,
            };
          }

          return (
            <Item
              {..._props}
              key={'CONDO-RESULT-' + condo.id}
              onClick={openModal(condo.id)}
            >
              <Text search={search}>{condo.title}</Text>
            </Item>
          );
        })}
      </Wrapper>
    </ContentList>
  );
}

export default CondoResults;
