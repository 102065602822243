import React, { useContext, useCallback, useMemo } from 'react';
import { imageChangeSize } from 'lib/image-helpers';
import { UploadImageContext } from 'components/ModalImage/context';
import { Image } from './styles';
import { closeModal, openModalPreviewImageUser } from 'modules/modal';
import { useDispatch } from 'react-redux';

const UserAvatar = ({
  user,
  user: { file_url: fileUrl },
  handleUploadImage,
}) => {
  const dispatch = useDispatch();
  const { open } = useContext(UploadImageContext);

  const handleClickAvatar = useCallback(() => {
    open({
      onSubmit: async (values) => {
        return handleUploadImage(user)([values.file]);
      },
      cropperOptions: {
        aspectRatio: 1,
      },
    });
  }, [fileUrl, handleUploadImage, open, user]);

  const handleOpenModalPreviewUser = () => {
    dispatch(
      openModalPreviewImageUser({
        imageUrl: fileUrl,
        handleChangeImage: () => {
          dispatch(closeModal());
          handleClickAvatar();
        },
      })
    );
  };

  const src = useMemo(() => {
    return imageChangeSize(fileUrl, 'medium', 'outside');
  }, [fileUrl]);

  if (!fileUrl) {
    return <Image onClick={handleClickAvatar} />;
  }

  return <Image image={src} onClick={handleOpenModalPreviewUser} />;
};

export default UserAvatar;
