import styled from 'styled-components';

export const Row = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const Icon = styled.img`
  width: 49px;
  height: 49px;
  object-fit: contain;
`;
export const Text = styled.p`
  font-size: 17px;
  font-weight: bold;
`;
