import setAuthorizationToken, {
  setOriginalToken,
} from 'lib/setAuthorizationToken';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import { Container } from './styles';

const Loading = () => {
  const { token } = useParams();

  useEffect(() => {
    localStorage.removeItem('original-user');
    localStorage.removeItem('current-user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('original_token');
  }, []);

  useEffect(() => {
    if (token) {
      setOriginalToken(token);
      setAuthorizationToken(token);
      window.location.replace(window.location.origin + '/reception');
    }
  }, [token]);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
      <div class="loading-home-screen">
        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
        <lottie-player
                src="https://assets2.lottiefiles.com/temp/lf20_tUrEqM.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 300px;"
                loop
                autoplay
        >
        </lottie-player>
      </div> 
    `,
      }}
    />
  );
};

export default Loading;
