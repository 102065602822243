import api, { apiStorage } from 'services/index';
import {
  DEVICES,
  getLogo,
  TYPES,
  uploadWhatsappLogo,
} from 'services/sites/logos';
import { responseMessage } from 'lib/service-helpers';
import { STORAGE } from 'lib/HTTP';

export const getAll = (params) => api.getList('settings/logos', params);

export const getLogoHeader = (params) =>
  api.getOne('settings/sites/logos/header', '', params);
export const updateLogoHeader = ({ id, ...values }) =>
  api.updateWithFile('settings/sites/logos/header', values);

export const getLogoFooter = (params) =>
  api.getOne('settings/sites/logos/footer', '', params);
export const updateLogoFooter = ({ id, ...values }) =>
  api.updateWithFile('settings/sites/logos/footer', values);

const isValidDevice = (device) => {
  if (!device) return true;
  return [DEVICES.MOBILE, DEVICES.DESKTOP, 'all'].indexOf(device) >= 0;
};

// Remove uma logo
export const removeLogo = (id) =>
  apiStorage
    .delete('settings/logos', id)
    .then(responseMessage('Logo removida'))
    .catch(responseMessage('Erro ao remover logo'));

/**
 * Envia uma imagem pela posição dela e o dispositivo
 * @param type - ['header', 'footer', 'real-estate', 'watermark']
 * @param device - ['mobile', 'desktop']
 * @param image - {Blob}
 * @returns {*|AxiosPromise<any>}
 */
export const uploadLogo = (type, device, image) => {
  if (!isValidDevice(device)) throw new Error('Dispositívo invalido');
  switch (type) {
    case TYPES.HEADER: {
      return api.updateWithFile(`settings/logos/${device}-header`, { image });
    }
    case TYPES.FOOTER: {
      return api.updateWithFile(`settings/logos/${device}-footer`, { image });
    }
    case TYPES.WHATSAPP: {
      return uploadWhatsappLogo({ image });
    }
    case TYPES.REAL_ESTATE: {
      return api.updateWithFile(`settings/logos/real-estate`, { image });
    }
    case TYPES.WATERMARK: {
      return api.updateWithFile(`settings/logos/watermark`, { image });
    }
    default:
      throw new Error('Tipo invalido');
  }
};

/**
 * Seleciona uma logo na galeria passando o ID
 * @param type
 * @param device
 * @param id
 */
export const selectLogoById = (type, device, id) => {
  if (!isValidDevice(device)) throw new Error('Dispositívo invalido');
  switch (type) {
    case TYPES.HEADER:
      return api
        .update(`settings/logos/${id}/${device}-header`, '')
        .then(responseMessage('Logo do topo atualizada'));
    case TYPES.FOOTER:
      return api
        .update(`settings/logos/${id}/${device}-footer`, '')
        .then(responseMessage('Logo do rodapé atualizada'));
    case TYPES.WHATSAPP:
      return api
        .update(`settings/logos/${id}/site-whatsapp-logo`, '')
        .then(responseMessage('Logo do whatsapp atualizada'));
    case TYPES.REAL_ESTATE:
      return api
        .update(`settings/logos/${id}/real-estate`, '')
        .then(responseMessage('Logo da imobiliária atualizada'));
    case TYPES.WATERMARK:
      return STORAGE.patch(`settings/watermarks/logo`, { logo_id: id }).then(
        responseMessage(`Logo da marca marca d'água`)
      );
    default:
      throw new Error('Tipo invalido');
  }
};

export const getLogosPost = async () => {
  let arr = [];
  arr.push(getLogo({ type: TYPES.HEADER, device: DEVICES.DESKTOP }));
  arr.push(getLogo({ type: TYPES.FOOTER, device: DEVICES.DESKTOP }));

  const [header, footer] = await Promise.all(arr);

  return {
    data: {
      header: header.data,
      footer: footer.data,
    },
  };
};
