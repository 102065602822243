const HEIGHT = 40;

export default {
  control: (provided, state) => ({
    ...provided,
    background: state.isDisabled ? 'rgba(211, 220, 235, 0.7)' : '#fff',
    border: '1px solid #D3DCEB !important',
    outline: 'none',
    boxShadow: 'none',
    height: HEIGHT,
    minHeight: HEIGHT,
  }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  valueContainer: provided => ({
    ...provided,
    height: HEIGHT - 2
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: HEIGHT - 2
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};
