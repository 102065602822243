import React, { useCallback } from 'react';
import FormGroup from '../FormGroup';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Wrapper, Item, Number } from './styles';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const OrderOptions = ({
  formGroupClassName,
  label,
  input,
  meta,
  labelKey = 'title',
  valueKey = 'id'
}) => {
  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items = reorder(
        input.value,
        result.source.index,
        result.destination.index
      );

      input.onChange(items);
    },
    [input]
  );

  if (!input.value) return null;

  return (
    <FormGroup
      className={formGroupClassName}
      labelText={label}
      input={input}
      meta={meta}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-order-options" direction="horizontal">
          {(provided, snapshot) => (
            <Wrapper ref={provided.innerRef} {...provided.droppableProps}>
              {input?.value.map((option, i) => (
                <Draggable
                  key={option[valueKey]}
                  draggableId={option[valueKey]}
                  index={i}
                >
                  {(provided, snapshot) => (
                    <Item
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={provided.draggableProps.style}
                    >
                      <Number>{i + 1}</Number> {option[labelKey]}
                    </Item>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Wrapper>
          )}
        </Droppable>
      </DragDropContext>
    </FormGroup>
  );
};

export default OrderOptions;
