import styled from 'styled-components';
import Button from 'components/Button';

export const ButtonCustom = styled(Button)`
  padding: 0 10px;

  svg {
    margin-top: -2px;
  }
`;
