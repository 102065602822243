// Services
import { getPeopleBirthdays } from 'services/dashboards/people';
// Modules
import dataReducer from 'modules/data';

// Constants
const MODULE_NAME = 'peopleBirthday';

export const {
  reducer: peopleBirthdayReducer,
  actionCreators: peopleBirthdayActions,
} = dataReducer(`peopleDashboard/${MODULE_NAME}`);

// Selectors
export const dataSelector = (state) => state.peopleDashboard[MODULE_NAME].data;
export const isFetchingSelector = (state) =>
  state.peopleDashboard[MODULE_NAME].meta.isFetching;

// Reducer
export const fetchPeopleWithBirthday = (params) => (dispatch) => {
  const currentUser = localStorage.getItem('current-user');

  dispatch(peopleBirthdayActions.request());

  return getPeopleBirthdays({
    ...params,
    filter: {
      ...params?.filter,
      user_id: currentUser.id,
    },
  }).then(({ data }) => {
    dispatch(peopleBirthdayActions.receive(data));
    return data;
  });
};

export default peopleBirthdayReducer;
