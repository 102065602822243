import React, { useCallback, useEffect } from 'react';
import moment from 'lib/moment';
// Components
import PropertyChart from './components/PropertyChart';
import PropertyTable from './components/PropertyTable';
// Constantes
import { getPropertiesPerPeriod } from 'services/reports';
import { useReport } from '../hooks';
import WrapperReport from '../../components/WrapperReport';
import Form from './components/Form';
import NoData from 'pages/Reports/components/NoData';
import iconGraph from 'pages/Reports/pages/PropertyPerTypeTransaction/icon-graph.svg';
import background from './background.png';

export default function PropertyPerPeriod() {
  const {
    data,
    dataSelecteds,
    meta,
    chartData,
    fetchData,
    isFetching,
    selected,
    toggle,
    isAllSelected,
    toggleAll
  } = useReport({
    request: getPropertiesPerPeriod,
    key: 'type'
  });

  const handleSubmit = useCallback(
    values => {
      fetchData(values);
    },
    [fetchData]
  );

  useEffect(() => {
    const startDate = moment()
      .subtract(5, 'month')
      .startOf('month')
      .format('DD/MM/YYYY');

    const endDate = moment()
      .endOf('month')
      .format('DD/MM/YYYY');

    fetchData({
      period: 'previousMonth',
      start_date: startDate,
      end_date: endDate
    });
  }, []);

  return (
    <WrapperReport
      title="Captação"
      text="Veja quantos imóveis foram captados em determinado período."
      data={data}
      meta={meta}
      isFetching={isFetching}
      renderForm={() => <Form onSubmit={handleSubmit} />}
      renderNoData={() => (
        <NoData
          iconImage={iconGraph}
          backgroundImage={background}
          text="Entenda seu estoque geral, por transação e por corretor."
        />
      )}
      renderChart={() => (
        <PropertyChart
          width={'100%'}
          height={250}
          data={chartData}
          meta={meta}
          toggleChecked={toggle}
          selected={selected}
        />
      )}
      renderTable={() => (
        <PropertyTable
          data={dataSelecteds}
          meta={meta}
          isAllSelected={isAllSelected}
          toggleAll={toggleAll}
          toggleChecked={toggle}
        />
      )}
    />
  );
}
