import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';
import { NavLink } from 'react-router-dom';
import Image from 'components/Image';

function Logo({ url }) {
  if (!url) return null;

  return (
    <Col>
      <NavLink to="/dashboard">
        <Image
          key={url}
          src={url}
          size="original"
          alt="Logo do sistema"
          className="MainHeader__brand h-flex__cell"
        />
      </NavLink>
    </Col>
  );
}

Logo.defaultProps = {
  url: null
};

Logo.propTypes = {
  url: PropTypes.string
};

export default Logo;
