import React from 'react';
// Component
import Table from 'components/Table';
import DealItem from '../DealItem';
import Check from 'components/Check';

const DealTable = ({ isAllSelected, toggleAll, data, toggleChecked }) => (
  <Table
    responsive
    data={data}
    isFetching={false}
    renderTitle={() => (
      <tr>
        <th className="h-sticky--left">
          <Check
            checked={isAllSelected}
            onClick={toggleAll}
            className="h-margin-right--15"
          />
          Corretor
        </th>
        {data[0] && data[0].data.map(dat => <th key={dat.type}>{dat.type}</th>)}
        <th className="h-sticky--right">Total</th>
      </tr>
    )}
    renderItem={(data, index) => (
      <DealItem
        key={`item-${data.broker}-${index}`}
        data={data}
        toggleChecked={toggleChecked}
      />
    )}
  />
);

export default DealTable;
