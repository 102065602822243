import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Components
import BoxItem from './BoxItem';

function Box({ withBorder, spacing, children, className }) {
  return (
    <div
      className={classnames('MainBox', className, {
        'MainBox--border': withBorder
      })}
    >
      {React.Children.map(children, (child, i) =>
        React.cloneElement(child, {
          spacing
        })
      )}
    </div>
  );
}

Box.item = BoxItem;

Box.propTypes = {
  withBorder: PropTypes.bool,
  className: PropTypes.string,
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node
};

Box.defaultProps = {
  withBorder: false,
  className: null,
  spacing: null,
  children: null
};

export default Box;
