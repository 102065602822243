import React, { useState } from 'react';
import { Wrapper, Days, Button } from './styles';

function SelectDays({
  label = 'Dias',
  defaultDays = 15,
  days = [15, 30, 60, 90, 'Tudo'],
  onChange,
}) {
  const [value, setValue] = useState(defaultDays);

  const handleClick = (day) => () => {
    setValue(day);
    if (onChange) onChange(day);
  };

  return (
    <Wrapper>
      <Days>{label}</Days>
      {days.map((day) => (
        <Button active={value === day} onClick={handleClick(day)}>
          {day}
        </Button>
      ))}
    </Wrapper>
  );
}

export default SelectDays;
