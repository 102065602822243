import styled, { css } from 'styled-components';
import Label from 'components/Label';
import { darken } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 15px;

  h1 {
    font-size: 16px;
  }
`;

export const CustomLabel = styled(Label)`
  ${p =>
    p.isSelected &&
    css`
      opacity: 1 !important;
    `}
`;

export const Filters = styled.div`
  padding: 0 30px;
  margin-bottom: 15px;

  ${p =>
    p.hasSelected &&
    css`
      ${CustomLabel} {
        opacity: 0.3;
      }
    `}

  h2 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .Label + .Label {
    margin-left: 4px;
  }
`;

export const Actions = styled.div`
  display: flex;
  padding: 0 30px;
  margin-bottom: 15px;

  button {
    color: #0084f4;
    cursor: pointer;

    :hover {
      color: ${darken(0.2, '#0084f4')};
    }
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
export const Empty = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
`;

export const List = styled.ul`
  list-style: none;
  flex-grow: 1;
  min-height: 0;
`;

export const Divider = styled.div`
  position: relative;
  width: 25px;
  height: 16px;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 100%;
    background-color: #d7e0ee;
  }
`;

export const ItemDate = styled.li`
  position: relative;
  font-size: 14px;
  padding: 5px 30px;
  overflow: hidden;

  span {
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding-right: 15px;
    z-index: 5;
    font-weight: 600;
  }

  :before {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: #e4eaf4;
  }
`;

export const LoadMore = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: 100%;
  height: 47px;
  background-color: #d5e9fa;
  position: sticky;
  bottom: 0;
  color: ${p => p.theme.colors.secondary};
  margin-top: 15px;
  cursor: pointer;
  z-index: 10;
`;
