import React from 'react';
import HighlightWords from 'react-highlight-words';

const Highlight = ({ className, text, search = '', children = '' }) => (
  <HighlightWords
    className={className}
    searchWords={[search]}
    // Remove os caracteres especiais da pesquisa
    sanitize={string => {
      try {
        if (!string && Array.isArray(string)) return null;
        return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      } catch (err) {
        return null;
      }
    }}
    autoEscape={true}
    textToHighlight={text || children}
    highlightClassName="h-text-highlight"
  />
);

export default Highlight;
