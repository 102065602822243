import api from './index';
import { CancelToken } from 'axios';

let sourceProperties;
let sourcePeople;
let sourceCondos;

const defaultParams = {
  offset: 1,
  limit: 20,
};

export const cancelAll = () => {
  if (sourceProperties) sourceProperties.cancel('Request Cancelado');
  if (sourcePeople) sourcePeople.cancel('Request Cancelado');
  if (sourceCondos) sourceCondos.cancel('Request Cancelado');
};

export const searchByProperties = (search, params = defaultParams) => {
  if (sourceProperties?.cancel !== undefined)
    sourceProperties.cancel('Request Cancelado');

  sourceProperties = CancelToken.source();

  const filter = params?.filter;
  delete params?.filter;

  return api.get(
    'properties',
    {
      filter: {
        by_main_search: search,
        on_network: ['self', 'external'],
        ...filter,
      },
      ...params,
    },
    { cancelToken: sourceProperties.token }
  );
};
export const searchByPeople = (search, params = defaultParams) => {
  if (sourcePeople?.cancel !== undefined)
    sourcePeople.cancel('Request Cancelado');

  sourcePeople = CancelToken.source();

  return api.get(
    'people',
    { filter: { by_main_search: search }, include: 'groups', ...params },
    { cancelToken: sourcePeople.token }
  );
};
export const searchByCondos = (search, params = defaultParams) => {
  if (sourceCondos?.cancel !== undefined)
    sourceCondos.cancel('Request Cancelado');

  sourceCondos = CancelToken.source();

  return api.get(
    'condos',
    {
      filter: { by_main_search: search, having: 'properties' },
      ...params,
    },
    { cancelToken: sourceCondos.token }
  );
};

export const searchAll = async (search, params = defaultParams) => {
  const [properties, people, condos] = await Promise.all([
    searchByProperties(search, params),
    searchByPeople(search, params),
    searchByCondos(search, params),
  ]);

  return {
    properties: {
      data: properties.data,
      meta: properties.meta,
    },
    people: {
      data: people.data,
      meta: people.meta,
    },
    condos: {
      data: condos.data,
      meta: condos.meta,
    },
  };
};

window.searchAll = searchAll;
