import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { getTotal } from '../../../../lib/formHelpers';

const defaultProps = {
  maxLength: null
};

const propTypes = {
  maxLength: PropTypes.number,
  active: PropTypes.bool.isRequired
};

function FormCount({ input, maxLength, active }) {
  const total = getTotal(input, maxLength);
  const isExeeded = total < 0;

  return (
    active &&
    maxLength && (
      <div
        className={classnames('FormCount', {
          'h-color--success': !isExeeded,
          'h-color--danger': isExeeded
        })}
      >
        {total}
      </div>
    )
  );
}

FormCount.defaultProps = defaultProps;
FormCount.propTypes = propTypes;

export default FormCount;
