import api from 'services/index';

/**
 * Pega os dados do nome
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const get = buildingId =>
  api.getList(`settings/sites/buildings/${buildingId}/name`);

/**
 * Atualiza os dados do nome
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = ({ id, ...values }) =>
  api.patch(`settings/sites/buildings/${id}/name`, values);
