import styled, { css } from 'styled-components';

const stateStyles = ({ isDanger, isFocused }) => {
  if (isDanger) {
    return css`
      background-color: rgba(241, 70, 103, 0.05);
    `;
  }

  if (isFocused) {
    return css`
      background-color: #f6f6f6;
    `;
  }

  return css`
    background-color: #fff;
  `;
};

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 15px;
  cursor: ${p => (p.hasClick ? 'pointer' : 'default')};
  ${stateStyles};
  z-index: 100000;

  & + & {
    border-top: 1px solid #e1e6ec;
  }

  .Avatar {
    width: 70px;
    height: 70px;
    margin-right: 15px;
    flex-shrink: 0;
    min-width: 0;
  }
`;

export const TextWrapper = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  color: ${p => p.theme.colors.primary};
  flex: 10 0;
  min-width: 1px;

  p {
    color: inherit;
  }
`;

export const Title = styled.div`
  color: ${p => p.theme.colors.primary};
  font-weight: 600;
`;

export const Price = styled.div`
  color: ${p => p.theme.colors.success};
  font-weight: 600;
`;

export const Address = styled.address`
  color: ${p => p.theme.colors.primary};
  font-style: normal;
  font-weight: 500;
`;

export const Spacer = styled.div`
  flex: 1 auto;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
