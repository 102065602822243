import React from 'react';
import { Content, Img, Text, Title, Wrapper } from './styles';
import Status from 'containers/ModalRoot/ModalProperty/components/ModalDefault/components/Status';

function TableCardProperty({ property }) {
  const unity = property?.informations?.apartment_number?.value;

  console.log(property?.cover_image?.file_url);

  return (
    <Wrapper>
      <Img src={property?.cover_image?.file_url} />
      <Content>
        <Title>
          <strong>{property.reference}</strong> -{' '}
          <strong>{property?.title_formatted}</strong>{' '}
          <Status separator="-" property={property} />
        </Title>
        {property?.condominium && (
          <Text>
            {property?.condominium?.title} {unity && ` - ${unity}`}
          </Text>
        )}
        <Text key={property?.id + 'formated'}>
          {property?.address_formatted}
        </Text>
      </Content>
    </Wrapper>
  );
}

export default TableCardProperty;
