import api from 'services';
import { responseMessage } from 'lib/service-helpers';

const BASE = 'settings/smtp';

export const test = (values) =>
  api
    .create(`${BASE}/test`, values)
    .then(responseMessage('Sucesso'))
    .catch(responseMessage('Erro'));

export const destroy = () =>
  api.delete(BASE).then(responseMessage('Configuração removida'));
export const updateOrCreate = (values) =>
  api.create(BASE, values).then(responseMessage('Dados atualizados'));
export const show = () => api.get(BASE);
