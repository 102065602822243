import api from './';
import { responseMessage } from 'lib/service-helpers';

/**
 * Lista as clientes que deram matched para o imóvel
 * @param property
 * @param params - parametros adicionais
 */
export const getMatcheds = (property, params) =>
  api.getList(`properties/${property.id}/matcheds`, {
    filter: { by_status: 2 },
    include: 'user',
    sort: '-sent_at',
    ...params,
  });

/**
 * Remove os perfis de interesse de um imóvel em específico
 * @param property - imóvel que voce deseja remover todos os perfis
 * @return {Promise}
 */
export const removeProfile = (property, params) =>
  api
    .delete(`properties/${property.id}/matcheds`, params)
    .then(responseMessage('Perfil removido'));

/**
 * Remove os perfis de interesse de um imóvel em específico
 * @param property - imóvel que voce deseja remover todos os perfis
 * @return {Promise}
 */
export const removeAllProfiles = (property, params) =>
  api
    .delete(`properties/matcheds/discard-all`, params)
    .then(responseMessage('Perfis removidos'));

/**
 * Envia um imóvel por e-mail para todos os seus matcheds novos
 * @param property
 * @param params
 */
export const sendMailMatched = (property, params) =>
  api
    .create(`properties/${property.id}/forwardings/matcheds/email`, params)
    .then(responseMessage('Email enviado'));

/**
 * Envia imóveis compatíveis por e-mail para uma lista de matcheds
 * @param values
 * @param config
 */
export const sendMailMatchedPeople = (values, config) =>
  api
    .create('people/forwardings/matcheds/email', values, config)
    .then(responseMessage('Email enviado'));

/**
 * Remove todos os imóveis/clientes compatíveis de um usuário específico
 * @param user_id
 * @returns {Promise<AxiosResponse<never>>}
 */
export const removeAllMatcheds = ({ user_id }) => {
  return api.delete('properties/all-matcheds', '', {
    params: {
      user_id,
    },
  });
};
