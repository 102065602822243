import React from 'react';
import { compose } from 'redux';
import qs from 'qs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Components
import Header from './components/Header';
import TableTickets from './components/TableTickets';
import NoDataProblem from './components/NoDataProblem';
import NoDataSugestion from './components/NoDataSugestion';
import Container from 'components/Container';
// Services
import * as helpDeskService from 'services/helpDeskResales';
// Modules
import { openModalIssue, openModalServiceEvaluation } from 'modules/modal';
// Constants
import { TYPES } from 'pages/Help/constants';
// Helpers
import { clearUrlParams } from 'lib/url-helpers';

const getType = props => {
  try {
    return props.match.params.type;
  } catch {
    return null;
  }
};

class List extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    data: [],
    isFetching: false
  };

  /**
   * Retorna um tipo da listagem
   * @returns {String}
   */
  get type() {
    return getType(this.props);
  }

  get queryStringParams() {
    try {
      return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    } catch {
      return {};
    }
  }

  componentDidMount() {
    const { openModal } = this.queryStringParams;
    if (openModal) {
      this.handleOpenIssue();
      clearUrlParams();
    }
    this.fetchDataByType();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const currentType = this.type;
    const prevType = getType(prevProps);

    if (currentType !== prevType) {
      this.fetchDataByType();
    }
  }

  goDetail = id => {
    this.props.history.push(`/help/${this.type}/detail/${id}`);
  };

  /**
   * Busca todos os tickets
   * @param params
   * @returns {Promise<*>}
   */
  fetchDataByType = async () => {
    this.setState({ isFetching: true });
    const { data } = await helpDeskService.getTickets(this.type);
    this.setState({ data, isFetching: false });
    return data;
  };

  get renderNoContent() {
    const type = this.type;
    if (type === TYPES.PROBLEM)
      return <NoDataProblem onClick={this.handleOpenIssue} />;
    return <NoDataSugestion onClick={this.handleOpenIssue} />;
  }

  handleOpenIssue = () => {
    this.props.openModalIssue({
      type: this.type,
      onSubmitSuccess: async value => {
        // Atualiza a listagem
        this.fetchDataByType();
      }
    });
  };

  handleClickAvaliation = ticketId => () => {
    this.props.openModalServiceEvaluation({
      ticketId,
      onFinally: () => {
        this.fetchDataByType();
      }
    });
  };

  render() {
    const type = this.type;
    const { data, isFetching } = this.state;

    if (!isFetching && data.length === 0) return this.renderNoContent;

    return (
      <>
        <Header type={type} handleOpenIssue={this.handleOpenIssue} />
        <Container padding>
          <TableTickets
            data={data}
            isFetching={isFetching}
            type={type}
            handleClickAvaliation={this.handleClickAvaliation}
          />
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = {
  openModalIssue,
  openModalServiceEvaluation
};

//export default connect(null, mapDispatchToProps)(List);
export default compose(withRouter, connect(null, mapDispatchToProps))(List);
