import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  MdArrowForward,
  MdCheckCircle,
  MdPlayCircleFilled as PlayIcon,
} from 'react-icons/md';
import { connect } from 'react-redux';
// Components
import Button from 'components/Button';
import WizardVideoItem from './components/WizardVideoItem';
// Modules
import { openModalVideo } from 'modules/modal';
// Constants
import { STATUS } from './constants';
// Assets
import appStore from 'assets/img/app-store.png';
import boneco from 'assets/img/boneco.png';
import googlePlay from 'assets/img/google-play.png';
import Poplink from 'components/WizardVideo/components/Poplink';
import Check from '../Check';

class WizardVideo extends React.Component {
  static propTypes = {
    currentStep: PropTypes.number,
    onChangeStep: PropTypes.func,
  };

  static defaultProps = {
    currentStep: 0,
    onChangeStep: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      step: props.currentStep,
    };
  }

  get totalSteps() {
    try {
      return this.props.steps.length;
    } catch {
      return 0;
    }
  }

  get finishedSteps() {
    try {
      return this.props.steps.filter((step) => step.status === STATUS.FINISHED)
        .length;
    } catch {
      return 0;
    }
  }

  /**
   * Retorna o indice do vídeo
   * @return {number|*}
   */
  get index() {
    try {
      return this.state.step + 1;
    } catch {
      return 0;
    }
  }

  /**
   * Retorna se o vídeo
   * @return {boolean}
   */
  get isLastStep() {
    try {
      return this.props.steps.length === this.state.step + 1;
    } catch {
      return false;
    }
  }

  getVideoByIndex(index) {
    try {
      return this.props.steps[index];
    } catch {
      return {};
    }
  }

  handleClickLearn = (step) => (e) => {
    const { openModalVideo } = this.props;
    e.stopPropagation();

    openModalVideo({
      title: step.text,
      videoUrl: step.videoUrl,
      renderAction: ({ status, handleClose }) => {
        const isEnded = status === 'ended';

        return (
          <span style={{ position: 'relative' }}>
            {isEnded && (
              <MdArrowForward
                className="h-color--danger h-animation-arrow-right"
                style={{
                  verticalAlign: 'middle',
                  position: 'absolute',
                  right: '100%',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  fontSize: '40px',
                  zIndex: 9,
                }}
              />
            )}
            <Button
              color="success"
              onClick={(e) => {
                this.onClickAction(step)(e);
                handleClose();
              }}
            >
              Fazer
            </Button>
          </span>
        );
      },
    });
  };

  /**
   * Vai para o index especificado
   * @param index
   * @returns {null}
   */
  goStepByIndex = (index) => {
    const { onChangeStep } = this.props;

    // Verifica se tem etapas
    const hasSteps = index > this.totalSteps || index < 0;

    if (hasSteps) return null;

    // Pega a etapa pelo indice
    const step = this.getVideoByIndex(index);

    //
    if (!step.videoUrl) return null;
    this.setState({ step: index });

    if (onChangeStep) onChangeStep(step, index);
  };

  /**
   * Pula para a próxima etapa
   */
  goNextStep = (e) => {
    const { checkStep } = this.props;

    const step = this.getVideoByIndex(this.state.step);

    checkStep(step);
    this.goStepByIndex(this.state.step + 1);
  };

  onClickAction = (step) => (e) => {
    e.stopPropagation();
    if (this.props.onClickAction) this.props.onClickAction(step);
  };

  get renderNextButton() {
    // Se for a ultima etapa não renderiza o botão de pular
    if (this.isLastStep) return null;

    return (
      <Button color="white" colorText="secondary" onClick={this.goNextStep}>
        Pular
      </Button>
    );
  }

  renderAction(currentStep) {
    const { actionTo, actionText, renderAction } = currentStep;
    const { step } = this.state;

    // Verifica se tem ação
    // se tiver uma ação personalizada executa
    if (renderAction) renderAction({ index: step });

    // Verifica se é a ultima etapa
    // se for a ultima etapa renderiza o botão de finalizar
    if (this.isLastStep)
      return (
        <Button color="success" onClick={this.onClickAction(currentStep)}>
          Finalizar
        </Button>
      );

    // Verifica se tem um botão
    const hasButton = actionTo && actionText;

    //
    if (hasButton) {
      return (
        <Button color="secondary" onClick={this.onClickAction(currentStep)}>
          {actionText}
        </Button>
      );
    }

    return null;
  }

  get renderCurrentVideo() {
    // Pega a etapa atual do vídeo
    const step = this.getVideoByIndex(this.state.step);

    // Se não ter uma etapa significa que não precisa renderizar nada
    if (!step) return null;

    const { videoUrl, image } = step;

    if (image) {
      return (
        <div className="WizardVideoItem">
          <img src={image} alt="Video item" />
        </div>
      );
    }

    return (
      <WizardVideoItem
        videoUrl={videoUrl}
        onClickAction={this.onClickAction(step)}
      />
    );
  }

  renderStepActions(step, index) {
    if (step.renderAction) return step.renderAction(step, index);

    if (step.app)
      return (
        <>
          <div className="h-flex__cell--grow" />
          <div>
            <Poplink link="https://apps.apple.com/us/app/tecimob/id1486403299">
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/tecimob/id1486403299"
                rel="noopener noreferrer"
                className="h-margin-right--5"
                onClick={this.onClickAction(step)}
              >
                <img src={appStore} alt="" />
              </a>
            </Poplink>
            <Poplink link="https://play.google.com/store/apps/details?id=com.tecimob_app">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.tecimob_app"
                rel="noopener noreferrer"
                className="h-margin-right--5"
              >
                <img
                  src={googlePlay}
                  alt=""
                  onClick={this.onClickAction(step)}
                />
              </a>
            </Poplink>
          </div>
        </>
      );

    return (
      <>
        <div className="h-flex__cell--grow" />
        {!step.noAction && (
          <div className="WizardSteps__actions h-hover__show">
            <Button
              onClick={this.handleClickLearn(step)}
              size="small"
              color="white"
              colorText="primary"
              style={{ padding: '0 8px' }}
            >
              <PlayIcon /> Aprender
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={this.onClickAction(step)}
              style={{ padding: '0 8px' }}
            >
              Fazer
            </Button>
          </div>
        )}
      </>
    );
  }

  renderStep = (item, index) => {
    const { step } = this.state;

    const isFinished = item.status === STATUS.FINISHED;
    const isDisabled = item.disabled;
    const isActive = index === step;

    return (
      <div
        key={`wizard-${index}`}
        className={classnames('WizardSteps__item h-hover', {
          'is-active': isActive,
          'is-finished': isFinished,
          'is-disabled': isDisabled,
        })}
        onClick={() => this.goStepByIndex(index)}
      >
        {isFinished ? (
          <div className="WizardSteps__icon">
            <MdCheckCircle size={18} />
          </div>
        ) : (
          <span className="WizardSteps__number">{index + 1}</span>
        )}
        <span className="WizardSteps__item-text">{item.text}</span>
        {!isDisabled && this.renderStepActions(item, index)}
      </div>
    );
  };

  get renderSteps() {
    const { steps } = this.props;
    if (!steps) return null;
    return steps.map(this.renderStep);
  }

  render() {
    const { showAgain, toggleVisibility, title, text, handleFinishWizard } =
      this.props;

    return (
      <div className="WizardVideo js-video-step">
        <header className="WizardVideo__header h-flex">
          <img src={boneco} alt="" className="h-flex__cell--shrink" />
          <div className="WizardVideo__header-content h-flex__cell--grow">
            <h4 className="WizardVideo__title">{title}</h4>
            <p className="WizardVideo__text">{text}</p>
          </div>
        </header>
        <div className="WizardVideo__content">
          <div className="WizardVideo__aside">
            <div className="WizardSteps">{this.renderSteps}</div>
          </div>
          {this.renderCurrentVideo}
        </div>
        <div className="WizardVideo__footer">
          <label onClick={toggleVisibility} style={{ marginRight: '15px' }}>
            <Check checked={showAgain} /> Não mostrar novamente
          </label>
          <Button size="medium" onClick={handleFinishWizard()}>
            Fechar passo a passo
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(null, { openModalVideo })(WizardVideo);
