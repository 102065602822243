import styled from 'styled-components';
import { FaWhatsapp } from 'react-icons/fa';
import TextOverflow from 'components/TextOverflow';
import Button from 'components/Button';
import Table from 'components/Table';

export const TableCustom = styled(Table)`
  th {
    white-space: nowrap;
  }
  tr:nth-child(even) td {
    background-color: #e9eef5;
  }
`;

export const ListNames = styled.ul`
  list-style: none;

  li + li {
    color: #5c5260;
    margin-left: 10px;
  }
`;

export const IconWhatsapp = styled(FaWhatsapp)`
  color: #31d084;
`;

export const Overflow = styled(TextOverflow)`
  vertical-align: middle;
`;

export const Info = styled.div`
  white-space: nowrap;

  a {
    color: inherit;
  }

  & + & {
    margin-top: 5px;
  }

  svg.icon-left {
    margin-right: 5px;
  }
  svg.icon-right {
    margin-left: 5px;
  }
`;

export const Options = styled(Button)`
  padding: 0 10px;

  svg {
    margin-right: -5px;
  }
`;

export const NameCell = styled.td`
  cursor: pointer;

  :hover .name-link {
    color: ${(p) => p.theme.colors.secondary};
    text-decoration: underline;
  }
`;
