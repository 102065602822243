import { SelectMultiple } from 'components/Form';
import { useLocation } from 'containers/LocationAsyncFields/context';
import useFormValue from 'hooks/useFormValue';
import { parseSelect } from 'lib/formHelpers';
import { useCallback, useEffect } from 'react';
import { Field } from 'redux-form';
import * as locationService from 'services/location';
// import { Container } from './styles';

const States = ({ label = 'Estado', ...props }) => {  
  const {
    dispatch,
    change,
    countryName,
    stateName,
    states,
    setStates,
    cityName,
    neighborhoodName,
    zoneName,
    setCities,
    setNeighborhoods,
    initialValues
  } = useLocation();

  const country = useFormValue(countryName);

  const onChange = useCallback(
    () => {
      dispatch(change(cityName, null));
      dispatch(change(neighborhoodName, null));
      dispatch(change(zoneName, null));

      setCities(initialValues);
      setNeighborhoods(initialValues);
    },
    [initialValues]
  );

  const fetchStates = useCallback(
    async id => {
      setStates({ isFetching: true });
      const res = await locationService.getStates({ country_id: id });
      setStates({ data: res?.data });
    },
    [setStates, country]
  );

  useEffect(() => {
    if (country) {
      fetchStates(country);
    }
  }, [country]);

  return (
    <Field
      multi={false}
      disabled={!states?.data?.length}
      label={label}
      name={stateName}
      component={SelectMultiple}
      isLoading={states?.isFetching}
      options={states?.data}
      labelKey="name"
      valueKey="id"
      onChange={onChange}
      parse={parseSelect('id')}
      {...props}
    />
  );
};

export default States;
