import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'components/Label';
import Button from 'components/Button';
import ViewsGraph from 'pages/Dashboard/containers/ViewsGraph';
import moment, { format } from 'lib/moment';
import { getAllDashboardData } from 'services/dashboard';
import { getViewId } from 'lib/analytics';
import { Box } from './styles';
import { useCan } from 'hooks/useCan';
import { USER_PERMISSIONS } from 'constants/rules';
import Nodata from 'components/Nodata';
import analyticsImage from './google-analytics.svg';
import Loading from 'components/Loading';

function AnalyticsLastVisitors() {
  const canShowAnalytics = useCan({
    run: USER_PERMISSIONS.VIEW_MAIN_DASHBOARD
  });

  const [data, setData] = useState([]);
  const [days, setDays] = useState(30);
  const [devices, setDevices] = useState({
    mobile: 0,
    desktop: 0,
    tablet: 0
  });

  const [isFetching, setIsFetching] = useState(true);

  const request = useCallback(() => {
    setIsFetching(true);
  }, []);

  const receive = useCallback(({ data, byDevice }) => {
    setData(data);
    setDevices(byDevice);
    setIsFetching(false);
  }, []);

  const fetchData = useCallback(async () => {
    request();

    const startDate = moment()
      .subtract(days, 'day')
      .format(format.date);
    const endDate = moment().format(format.date);

    const response = await getAllDashboardData({
      viewId: getViewId(),
      startDate,
      endDate
    });

    receive(response);
  }, [days]);

  const handleClickDay = useCallback(
    _days => () => {
      setDays(_days);
    },
    [days]
  );

  useEffect(() => {
    fetchData();
  }, [days]);

  const isActiveDay = useCallback(day => days === day, [days]);

  const getColorByDay = useCallback(
    day => (isActiveDay(day) ? 'primary' : 'default'),
    [days]
  );

  if (!canShowAnalytics) {
    return (
      <Nodata
        image={analyticsImage}
        title="Analytics"
        text="Você não tem permissão para acessar esses dados."
      />
    );
  }

  return (
    <>
      <Row middle="xs">
        <Col xs={9}>
          <div
            className="h-flex h-flex--center-center"
            style={{ padding: '20px' }}
          >
            <div>
              <h4>Visitantes Únicos Acumulados</h4>
              <p>Integrado ao seu Google Analytics</p>
            </div>
            <span className="h-flex__cell--grow" />
            <Label
              className="h-margin-right--5"
              active={isActiveDay(30)}
              color={getColorByDay(30)}
              handleClick={handleClickDay(30)}
            >
              30 dias
            </Label>
            <Label
              className="h-margin-right--5"
              active={isActiveDay(60)}
              color={getColorByDay(60)}
              handleClick={handleClickDay(60)}
            >
              60 dias
            </Label>
            <Button
              tagName="a"
              href="https://analytics.google.com/analytics/web/provision/?authuser=0#"
              target="_blank"
              color="caution"
              size="small"
            >
              Google analytics
            </Button>
          </div>
          <ViewsGraph data={data} isLoading={isFetching} />
        </Col>
        <Col xs={3}>
          <div style={{ padding: '15px' }}>
            <Box>
              <h1>{devices?.desktop || 0}</h1>
              <p>Visitantes Desktop</p>
            </Box>
            <Box>
              <h1>{devices?.tablet || 0}</h1>
              <p>Visitantes Tablet</p>
            </Box>
            <Box>
              <h1>{devices?.mobile || 0}</h1>
              <p>Visitantes Mobile</p>
            </Box>
          </div>
        </Col>
      </Row>
      <Loading isVisible={isFetching} isFullComponent />
    </>
  );
}

export default AnalyticsLastVisitors;
