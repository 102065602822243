import React from 'react';
import Modal from 'react-modal';

// Common Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
import { useDispatch } from 'react-redux';
import { openModalAuthorizationRent } from 'modules/modal';

function ModalAuthorizationRent({
  property,
  initialValues,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onAfterClose,
}) {
  const dispatch = useDispatch();

  async function onCreatePerson(person) {
    dispatch(
      openModalAuthorizationRent({
        property,
        onAfterClose,
        initialValues: {
          ...initialValues,
          person_id: person.id,
        },
      })
    );
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Imprimir autorização de locação"
        handleClose={handleClose}
      >
        <Form
          onCreatePerson={onCreatePerson}
          handleClose={handleClose}
          property={property}
          initialValues={{
            property,
            should_update_person: false,
            disclaimer: false,
            type: '1',
            ...initialValues,
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalAuthorizationRent;
