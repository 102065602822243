import React from 'react';
// Service
import { getList } from 'services/settings/domains';

export const withDomains = (Component) => {
  class WithDomains extends React.Component {
    state = {
      data: [],
      isFetching: false,
    };

    fetchDomains = async (params) => {
      this.setState({ isFetching: true });
      try {
        const res = await getList({
          ...params,
          filter: { dns_status: '3' },
          sort: '-dns_status,-is_primary',
        });
        this.setState({ data: res.data, isFetching: false });
        return res;
      } finally {
        this.setState({ isFetching: false });
      }
    };

    render() {
      return (
        <Component
          {...this.props}
          domains={this.state}
          fetchDomains={this.fetchDomains}
        />
      );
    }
  }

  return WithDomains;
};
