
// Components
import {
  FilterContent,
  FilterTitle,
  FilterWrapper,
} from 'components/FilterAside';
import CheckboxGroup from 'components/Form/components/CheckboxGroup';
import FormWrapper from 'components/FormWrapper';
import * as Fields from 'components/PropertyFilters';
import FieldSelectUser from 'containers/FieldSelectUser';
import FieldTypeAndSubtype from 'containers/FieldTypeAndSubtype';

// Helpers
import { isReferenceNotChanged } from 'lib/property-helpers';

// Modules
import { typePortalSelector } from 'pages/Properties/Filter/containers/TypesAndSubtypes/module';
import { Actions as FilterActions } from 'pages/Properties/Filter/module';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

export const selector = formValueSelector('PropertiesPortalForm');

const PortalPropertiesFilter = ({
  form = 'PortalPropertiesFilter',
  onSubmit,
  initialValues,
  portalRealEstateId,
}) => {
  const type = useSelector((state) => typePortalSelector(state));
  const typeOrSubtypeId = useSelector((state) =>
    selector(state, 'by_type_or_subtype_id')
  );

  return (
    <FormWrapper
      as={FilterWrapper}
      form="PropertiesPortalForm"
      onSubmit={(values) => {
        const _values = JSON.parse(JSON.stringify(values));
        if (_values?.by_portal_option_id === 'is_portal') {
          _values.by_portal_option_id = null;
          _values.doesnt_have_portal_real_estate_id = portalRealEstateId;
        }

        return onSubmit({ transaction: 1, ..._values });
      }}
      initialValues={{
        transaction: '1',
        ...initialValues,
      }}
      onChange={(values, dispatch, ownProps, prevValues) => {
        if (isReferenceNotChanged(values, prevValues)) {
          dispatch(FilterActions.hideReference());
        }
      }}
    >
      {() => (
        <>
          <FilterTitle>Filtrar</FilterTitle>
          <FilterContent>
            <Fields.Reference />
            <Fields.Transaction canRemoveSelf />
            <FieldTypeAndSubtype />
            <Fields.Person filter={'by_available_for_portals'} />
            <Fields.Price />
            <Fields.Situations typeOrSubtypeId={typeOrSubtypeId} type={type} />
            <Fields.Location />
            <Fields.StreetAddress />
            <FieldSelectUser
              parse={null}
              render={({ options, ...params }) => (
                <div>
                  <label className="FormLabel">Corretor Responsável</label>
                  <CheckboxGroup {...params} limit={4} options={options} />
                </div>
              )}
            />
            <div className="h-margin-top--15">
              <Fields.Database hasGuru={false} />
              <Fields.PersonOrulo />
              <Fields.Portal portalRealEstateId={portalRealEstateId} />
              <Fields.HasExclusivity />
              <Fields.Condominium hideEmpty />
              <Fields.Furniture />
            </div>
          </FilterContent>
        </>
      )}
    </FormWrapper>
  );
};

export default PortalPropertiesFilter;
