import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Components
import ModalTemplate from 'components/Modal/components/ModalTemplate';
import EmailAliasForm from './components/EmailAliasForm';
// Service
import * as emailService from 'services/settings/email';
// Containers
import MiddlewareDomains from 'containers/MiddlewareDomains';

class ModalAddEmailAccount extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    email: PropTypes.string.isRequired,
    onSubmitSuccess: PropTypes.func,
    onSubmitFail: PropTypes.func
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null,

    email: null,
    onSubmitSuccess: null,
    onSubmitFail: null
  };

  componentDidMount() {}

  handleSubmit = values => {
    return emailService.createAlias(values);
  };

  onSubmitSuccess = () => {
    if (this.props.onSubmitSuccess) this.props.onSubmitSuccess();

    // Fecha a modal
    this.props.handleClose();
  };

  onSubmitFail = () => {
    if (this.props.onSubmitFail) this.props.onSubmitFail();
  };

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate title="Cadastrar Alias" handleClose={handleClose}>
          <MiddlewareDomains>
            {domains =>
              domains && (
                <EmailAliasForm
                  initialValues={{
                    domain_id: localStorage.getItem('current-user').real_estate
                      .primary_domain.id,
                    goto: this.props.email
                  }}
                  domains={domains}
                  domain={this.props.domain}
                  handleClose={handleClose}
                  onSubmit={this.handleSubmit}
                  onSubmitSuccess={this.onSubmitSuccess}
                  onSubmitFail={this.onSubmitFail}
                />
              )
            }
          </MiddlewareDomains>
        </ModalTemplate>
      </Modal>
    );
  }
}

export default ModalAddEmailAccount;
