import { createContext, useContext, useState } from 'react';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractRemove/contexts/modalContractExtra';

export const ModalUserContext = createContext({
  page: 'Details',
  setPage: () => null,
  handleClose: () => null,
});

export const ModalUserProvider = ({ children }) => {
  const [page, setPage] = useState('Details');
  const { handleClose } = useModalContractExtra();

  return (
    <ModalUserContext.Provider
      value={{
        handleClose,
        page,
        setPage,
      }}
    >
      {children}
    </ModalUserContext.Provider>
  );
};

export const useModalUser = () => useContext(ModalUserContext);
