import React from 'react';
import PropTypes from 'prop-types';
import FormApplyWatermark from '../FormApplyWatermark';
import Aside from 'components/FilterAside';
import Divider from 'components/Divider';
import IsTecimob from 'containers/IsTecimob';

const CustomDivider = () => (
  <Divider
    top={10}
    bottom={20}
    style={{ width: 'calc(100% + 40px)', marginLeft: '-20px' }}
  />
);

const GalleryAside = ({ property, onChangeWatermark }) => (
  <Aside.wrapper>
    <Aside.title>Opções</Aside.title>
    <Aside.content
      style={{ flex: '1 0', display: 'flex', flexDirection: 'column' }}
    >
      {property.data.id && (
        <FormApplyWatermark
          isOrulo={property?.data?.network_type === 'Órulo'}
          isFetching={property.isFetching}
          blocked={property.data.is_applying_watermark}
          initialValues={{
            id: property.data.id,
            should_apply_watermark: property.data.should_apply_watermark
          }}
          onSubmitSuccess={onChangeWatermark}
        />
      )}
      <CustomDivider />
      <h4 className="h-margin-bottom--10">Dicas</h4>
      <ul className="list-bullet">
        <li>Arraste para ordenar;</li>
        <li>A primeira imagem será a foto principal do imóvel no seu site;</li>
        <li>
          Tamanho máximo das fotos: <strong>10mb.</strong>
          <a href="#test" rel="noreferrer" className="h-link">
            Clique aqui
          </a>{' '}
          para aprender como diminuir o tamanho das suas fotos.
        </li>
      </ul>
      <IsTecimob>
        <div className="h-flex__cell--grow" />
        <p className="h-color--primary h-margin-top--15">
          <strong>Veja no Blog:</strong>
        </p>
        <a href="#test" rel="noreferrer" className="h-link h-margin-bottom--15">
          Como tirar boas fotos
        </a>
      </IsTecimob>
    </Aside.content>
  </Aside.wrapper>
);

GalleryAside.defaultProps = {
  property: {
    data: {},
    isFetching: false
  },
  onChangeWatermark: null
};
GalleryAside.propTypes = {
  property: PropTypes.shape({
    data: PropTypes.object,
    isFetching: PropTypes.bool
  }),
  onChangeWatermark: PropTypes.func
};

export default GalleryAside;
