import createDataReducer from 'modules/data';
import * as postsService from 'services/sites/posts';
import { getPagination, setPagination } from 'modules/pagination';

export const {
  actionCreators: postsActions,
  reducer: postsReducer,
  selectors: postsSelectors
} = createDataReducer('site/posts');

export const fetchPosts = params => async (dispatch, getState) => {
  const state = getState();

  // Pega a paginação que está criada
  const pagination = getPagination(state, 'posts');

  const newParams = { ...pagination, ...params, sort: 'updated_at' };

  dispatch(postsActions.request());
  const { data: posts, meta } = await postsService.getList(newParams);

  if (meta?.pagination) {
    dispatch(setPagination('posts', meta?.pagination));
  }

  dispatch(postsActions.receive(posts));
};

export const orderPost = post => async dispatch => {
  try {
    await postsService.order(post);
  } finally {
    dispatch(fetchPosts());
  }
};

export const removePost = postId => async dispatch => {
  try {
    await postsService.remove(postId);
  } finally {
    dispatch(fetchPosts());
  }
};

export const toggleStatus = post => async dispatch => {
  dispatch(
    postsActions.alter(post, () => ({
      ...post,
      status: !post.status
    }))
  );
  await postsService.changeStatus(post.id, !post.status);
};
