import { Inner, Wrapper } from './styles';
import Loading from 'components/Loading';

export function Content({ isFetching, children }) {
  return (
    <Wrapper>
      <Loading isVisible={isFetching} isFullScreen />
      <Inner>{children}</Inner>
    </Wrapper>
  );
}
