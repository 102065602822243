import FieldBool from 'components/Form/components/FieldBool';
import React from 'react';
import { Row } from 'react-flexbox-grid';
import { Field, initialize, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import HeaderImageField from 'pages/Site/pages/Layout/pages/Header/components/HeaderImageField';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import { Input } from 'components/Form';
import Container from 'pages/Site/components/ContainerSite';
import ListOptions from 'components/ListOptions';
import { parseValueBool } from 'lib/formHelpers';
import UploadButton from 'components/UploadButton';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormBanner = ({
  initialValues: { device },
  images,
  handleSubmit,
  handleRemoveImage,
  submit,
  change,
  submitting,
  pristine,
}) => (
  <Container>
    <form onSubmit={handleSubmit}>
      <ListOptions>
        <ListOptions.item
          title="Mostrar Banner na página inicial"
          text="Mostrar na página inicial um banner a sua escolha."
          renderOptions={() => (
            <FieldBool
              name="can_show_banner"
              onChange={() => {
                change('footer_banner_id', null);
              }}
            />
          )}
        />
        <ListOptions.item title="Link do Banner">
          <Row className="h-margin-top--10">
            <Field
              xs={8}
              label="Adicione um link no seu banner"
              name="footer_banner_link"
              component={Input}
            />
            <FieldBool
              xs={4}
              label="Abrir link na:"
              name="footer_banner_open_on_page"
              options={[
                { label: 'Mesma página', value: true },
                { label: 'Outra página', value: false },
              ]}
              parse={parseValueBool}
            />
          </Row>
        </ListOptions.item>
        <ListOptions.item
          title={`Imagem (Recomendado: ${
            device === 'desktop' ? '1920x300px' : '600x300'
          })`}
          text="Escolha a imagem que será usada entre as opções abaixo, ou então envie a sua própria."
          renderOptions={() => {
            return (
              <UploadButton
                onDropAccepted={(files) => {
                  change('image', files[0]);

                  setTimeout(async () => {
                    await submit();
                    change('image', null);
                  }, 100);
                }}
              >
                <Button color="secondary">Enviar imagem</Button>
              </UploadButton>
            );
          }} /*
          renderOptions={() => (
            <FieldUploadImage
              cropperOptions={{
                aspectRatio: null,
              }}
              name="image"
              nameFileUrl="header_file_url"
              onUpload={() => {
                setTimeout(async () => {
                  await submit();
                  change('image', null);
                }, 100);
              }}
            >
              {({ open }) => {
                return (
                  <Button onClick={open} color="secondary">
                    Enviar Imagem
                  </Button>
                );
              }}
            </FieldUploadImage>
          )}*/
        />
        <ListOptions.item>
          <Field
            device={device}
            canToggle
            name="footer_banner_id"
            options={images}
            component={HeaderImageField}
            handleRemoveImage={handleRemoveImage}
            onChange={() => {
              change('can_show_banner', true);
            }}
          />
        </ListOptions.item>
      </ListOptions>

      <FixedBar style={{ left: 240 }}>
        <FixedBar.spacer />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar
        </Button>
      </FixedBar>
    </form>
  </Container>
);

FormBanner.defaultProps = defaultProps;
FormBanner.propTypes = propTypes;

export default reduxForm({
  onSubmitSuccess: (res, dispatch, props) => {
    // Reinicializa o formulário com os valores
    dispatch(
      initialize(
        props.form,
        {
          ...res.data,
          image: null,
        },
        false
      )
    );
  },
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(FormBanner);
