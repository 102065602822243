import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import ModalTemplate from '../../../components/Modal/components/ModalTemplate';
import { openModalPropertySendMail } from '../../../modules/modal';

class ModalExamplePropertyMail extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  handleClose = () => {
    this.props.openModalPropertySendMail(this.props.property);
  };

  render() {
    const { modalConfig, isOpen, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={this.handleClose}
      >
        <ModalTemplate handleClose={this.handleClose}></ModalTemplate>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  openModalPropertySendMail
};

export default connect(null, mapDispatchToProps)(ModalExamplePropertyMail);
