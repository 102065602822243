import React from 'react';
import { Wrapper } from './styles';

function Item({ isActive, children, onClick }) {
  return (
    <Wrapper isActive={isActive} onClick={onClick}>
      {children}
    </Wrapper>
  );
}

export default Item;
