import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import CardCount from './components/CardCount';
import { useSelector } from 'react-redux';
import { selector } from 'pages/Crm/components/FormUser';
import { useFormFunnelId } from 'pages/Crm/pages/People';
import { useCount } from './hooks/useCount';
import { usePeriod } from 'pages/Crm/pages/Dashboard/hooks/usePeriod';

// import { Wrapper } from './styles';

function Counts() {
  const userId = useSelector((state) => selector(state, 'user_id')?.user_id);
  const funnelId = useFormFunnelId();
  const date = usePeriod();

  const { countPeople, countDeals } = useCount({
    user_id: userId,
    funnel_id: funnelId,
    start_date: date.start_date,
    end_date: date.end_date,
  });

  return (
    <Row>
      <Col xs={6}>
        <CardCount
          title="Negócios"
          count={countDeals.open_count}
          countGain={countDeals.earn_count}
          countLoss={countDeals.lost_count}
        />
      </Col>
      <Col xs={6}>
        <CardCount
          title="Clientes"
          count={countPeople.open_count}
          countGain={countPeople.earn_count}
          countLoss={countPeople.lost_count}
        />
      </Col>
    </Row>
  );
}

export default Counts;
