import React, { useEffect, useState } from 'react';
import ListOptions from 'components/ListOptions';
import logo from './logo.png';
import * as facebookService from 'services/settings/facebook';
import { useDispatch } from 'react-redux';
import { openModalFacebookStore } from 'modules/modal';
import { useRequest } from 'hooks/useRequest';
import Button from 'components/Button';
import { getFacebook } from 'services/settings/portals';

const FacebookStoreIntegration = () => {
  const dispatch = useDispatch();
  const [isRemoving, setIsRemoving] = useState(false);
  const [facebook, setFacebook] = useState({});
  const [isFetchingFacebook, setIsFetchingFacebook] = useState(false);

  const { data, fetchData } = useRequest({
    request: facebookService.getIntegration,
  });

  const isConnected = !!data?.facebook?.catalog_page?.id;

  const updateFacebook = async () => {
    setIsFetchingFacebook(true);
    const fb = await getFacebook();
    setFacebook(fb);
    setIsFetchingFacebook(false);
  };

  useEffect(() => {
    updateFacebook();
  }, []);

  const updateAll = () => {
    updateFacebook();
    fetchData();
  };

  const disconnect = async () => {
    setIsRemoving(true);
    await facebookService.disconnectCatolog();
    fetchData();
    setIsRemoving(false);
  };

  const connect = async () => {
    dispatch(
      openModalFacebookStore({
        afterClose: () => {
          updateAll();
        },
      })
    );
  };

  return (
    <ListOptions.item
      image={logo}
      imageStyle={{
        width: 42,
      }}
      title="Facebook - Catálogo (Loja)"
      text="Publique seus imóveis em sua Fan Page"
      renderOptions={() => {
        if (isConnected) {
          return (
            <>
              <Button
                disabled={isFetchingFacebook}
                to={`/portals/${facebook?.portal_real_estate?.id}`}
                color="white"
              >
                Selecionar imóveis
              </Button>
              <Button disabled={isRemoving} color="danger" onClick={disconnect}>
                Desconectar
              </Button>
            </>
          );
        }

        return (
          <Button color="success" onClick={connect}>
            Conectar
          </Button>
        );
      }}
    />
  );
};

export default FacebookStoreIntegration;
