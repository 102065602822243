import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { MdTimer } from 'react-icons/md';
import { connect } from 'react-redux';
// Modules
import { dataSelector, fetchPeopleEvents, isFetchingSelector } from './module';
// Components
import Ball from 'components/Ball';
import Panel from 'components/Panel';
import Table from 'components/Table';
import TextOverflow from 'components/TextOverflow';
// Helpers

const LabelDate = ({ date, isToday, isExpired }) => {
  const hasMoment = isToday || isExpired;
  let newDate = date;

  let color = 'success';
  if (isExpired) color = 'default';

  return (
    <span
      className={classnames({
        'h-color--success': isToday,
      })}
    >
      {newDate}
      {hasMoment && (
        <Ball color={color} animation={false} className="h-margin-left--5" />
      )}
    </span>
  );
};

class PeopleScheduled extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    isFetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    data: [],
    isFetching: false,
  };

  componentDidMount() {
    this.props.fetchPeopleEvents();
  }

  render() {
    const { data, openModalPerson, isFetching } = this.props;

    return (
      <Panel
        hasData={!!data.length}
        renderNoData={() => (
          <>
            <p>
              Nenhum Cliente <br /> com compromisso agendado
            </p>
          </>
        )}
        title="Clientes com compromissos agendados"
        icon={MdTimer}
        color="primary"
      >
        <Table
          type="full"
          data={data}
          isFetching={isFetching}
          renderTitle={() => (
            <tr>
              <th>Cliente</th>
              <th width={1}>Data e Hora</th>
            </tr>
          )}
          renderItem={(person) => {
            const { id, people, start_date, happens_today, is_expired } =
              person;

            return (
              <tr
                className="h-pointer"
                key={`person-scheduled-${id}`}
                onClick={openModalPerson(people)}
              >
                <td>
                  <TextOverflow width={290}>{people?.name}</TextOverflow>
                </td>
                <td className="h-text-nowrap">
                  <LabelDate
                    date={start_date}
                    isToday={happens_today}
                    isExpired={is_expired}
                  />
                </td>
              </tr>
            );
          }}
        />
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  data: dataSelector(state),
  isFetching: isFetchingSelector(state),
});

const mapDispatchToProps = {
  fetchPeopleEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleScheduled);
