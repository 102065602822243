import styled from 'styled-components';

export const CityList = styled.div`
  flex-shrink: 0;

  > div {
    margin: 5px 0;
  }

  .Button {
    margin-left: 10px;
  }
`;
