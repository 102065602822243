import styled from 'styled-components';
import { transparentize } from 'polished';

export const Wrapper = styled.div`
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  will-change: background-color;
  padding: 15px;
  cursor: pointer;

  :hover {
    background: ${p => transparentize(0.95, p.theme.colors.primary)};
  }

  img {
    margin: 0 auto 15px;
  }

  h1 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5em;
  }

  p {
    color: ${p => p.theme.colors.primary};
    margin-bottom: 1em;
  }
`;
