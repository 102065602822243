import styled from 'styled-components';

export const WatermarkLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 25px 0;
`;
