import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Modal from 'react-modal';

function ModalBoolean({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  title,
  text,
  onNoClick,
  onYesClick,
  config = {},
}) {
  const handleFinish = (action) => {
    action();
    handleClose();
  }

  return (
    <Modal
      {...modalConfig}
      className="Modal Modal--large"
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title={title}>
        {text}
        <ModalFooter>
          <Button color={config.cancelButtonColor ?? 'white'} onClick={handleClose}>
            {config.cancelButtonText ?? 'Cancelar'}
          </Button>
          <span className="h-flex__cell--grow" />
          <Button color={config.noButtonColor ?? 'danger'} onClick={() => handleFinish(onNoClick)}>
            {config.noButtonText ?? 'Não'}
          </Button>
          <Button color={config.yesButtonColor ?? 'success'} onClick={() => handleFinish(onYesClick)}>
            {config.yesButtonText ?? 'Sim'}
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalBoolean;
