import { peopleSelector } from 'modules/people';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdCake } from 'react-icons/md';
// Modules
import {
  dataSelector,
  fetchPeopleWithBirthday,
  isFetchingSelector
} from './module';
// Components
import Panel from 'components/Panel';
import Table from 'components/Table';
import TextOverflow from 'components/TextOverflow';

class PeopleBirthday extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    isFetching: PropTypes.bool.isRequired
  };

  static defaultProps = {
    data: [],
    isFetching: false
  };

  componentDidMount() {
    this.props.fetchPeopleWithBirthday();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.people !== prevProps.people) {
      this.props.fetchPeopleWithBirthday();
    }
  }

  render() {
    const { data, isFetching, openModalPerson } = this.props;

    return (
      <Panel
        hasData={!!data.length}
        title="Aniversariantes da semana"
        icon={MdCake}
        color="danger"
        renderNoData={() => (
          <>
            <p>
              Nenhum Cliente <br /> aniversariante na semana
            </p>
          </>
        )}
      >
        <Table
          size="small"
          type="full"
          data={data}
          isFetching={isFetching}
          renderTitle={() => (
            <tr>
              <th>Nome</th>
              <th>Data</th>
            </tr>
          )}
          renderItem={person => (
            <tr
              key={`birthday-${person.id}`}
              className="h-hover"
              onClick={openModalPerson(person)}
            >
              <td>
                <TextOverflow width={300}>{person?.name}</TextOverflow>
              </td>
              <td width={20}>{person?.birth_date}</td>
            </tr>
          )}
        />
      </Panel>
    );
  }
}

const mapStateToProps = state => ({
  people: peopleSelector(state),
  data: dataSelector(state),
  isFetching: isFetchingSelector(state)
});

const mapDispatchToProps = {
  fetchPeopleWithBirthday
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleBirthday);
