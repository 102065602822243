import Button from 'components/Button';
import Container from 'components/Container';
import FormEstablishments from 'components/FormEstablishments';
import ListCheck from 'components/ListCheck';
import Loading from 'components/Loading';
import MainTitle from 'components/MainTitle';
import Popover from 'components/Popover';
import { USER_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';
import { detectVisibility, undetectVisibility } from 'lib/helpers';
import {
  establishmentsMetaSelector,
  establishmentsSelector,
  getEstablishments,
  handleCreateEstablishmentWithForm,
} from 'modules/establishments';
import { useCallback, useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
import { Checkbox } from 'components/Form';

function Establishments() {
  const dispatch = useDispatch();

  const establishments = useSelector(establishmentsSelector);
  const { isFetching } = useSelector(establishmentsMetaSelector);

  const handleFocusPage = useCallback(() => {
    const handle = detectVisibility((isVisible) => {
      if (!isVisible) {
        dispatch(getEstablishments());
        undetectVisibility(handle);
      }
    });
  }, [dispatch]);

  /**
   * Cadastro de um estabelecimento
   * @param typeId|String
   */
  const onSubmitEstablishment = useCallback(
    (values) =>
      dispatch(handleCreateEstablishmentWithForm(values, 'CondoInformations')),
    []
  );

  useEffect(() => {
    dispatch(getEstablishments());
  }, []);

  return (
    <section id="establishments">
      <MainTitle
        title="Proximidades"
        text="Defina os estabelecimentos próximos a este condomínio/empreendimento"
      >
        <Can
          run={USER_PERMISSIONS.EDIT_CHARACTERISTICS}
          yes={() => (
            <>
              <Button
                tagName="a"
                href="/config/registrations/establishments"
                onClick={handleFocusPage}
                target="_blank"
              >
                Gerenciar
              </Button>
              <Popover
                onSubmit={onSubmitEstablishment}
                component={FormEstablishments}
              >
                <Button color="secondary">
                  <MdAdd /> Adicionar estabelecimento
                </Button>
              </Popover>
            </>
          )}
          no={() => (
            <span data-tip="Você não tem permissão para gerenciar estabelecimentos">
              <Button disabled>
                <MdAdd /> Adicionar estabelecimento
              </Button>
            </span>
          )}
        />
      </MainTitle>
      <Container>
        <Loading isVisible={isFetching} isFullComponent />
        <ListCheck cols={4} name="establishments" data={establishments} />
        <Row style={{ margin: '20px 0 -10px 0' }}>
          <Field
            xs={12}
            type="checkbox"
            responsive={false}
            name="update_properties_establishments"
            component={Checkbox}
            color="secondary"
            colorOn="#fff"
            colorOff="#fff"
            label={
              <span className="h-color--white">
                Ao salvar, aplicar para todos os imóveis ligados a este
                condomínio
              </span>
            }
          />
        </Row>
      </Container>
    </section>
  );
}

export default Establishments;
