import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Textarea } from 'components/Form';
import PropertyBar from 'components/NotesList/components/PropertyBar';
import PersonBar from 'components/NotesList/components/PersonBar';
import { Form, Wrapper } from './styles';
import CategoryField from 'components/NotesForm/components/CategoryField';

const CustomToolbar = ({ children }) => (
  <div id="toolbar-notes" className="ql-toolbar ql-snow ql-toolbar--custom">
    <div className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
    </div>
    <div className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <select className="ql-align" defaultValue="">
        <option value="" />
        <option value="center" />
        <option value="right" />
        <option value="justify" />
      </select>
    </div>
    <div className="h-flex__cell--grow" />
    <div className="ql-formats">{children}</div>
  </div>
);

const NotesForm = (props) => {
  const {
    submit,
    submitting,
    isFormAlter,
    handleSubmit,
    handleHideForm,
    property,
    stage,
    person,
  } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        autoFocus
        name="note"
        component={Textarea}
        customToolbar={CustomToolbar}
        modules={{
          toolbar: {
            container: '#toolbar-notes',
          },
        }}
      >
        <Wrapper className="Form--inline">
          <CategoryField />
          <button
            id="button-custom1"
            className="ql-btn ql-btn--default"
            onClick={handleHideForm}
          >
            Cancelar
          </button>
          <button
            id="button-custom2"
            type="submit"
            onClick={submit}
            disabled={submitting}
            className="ql-btn ql-btn--success"
          >
            {isFormAlter ? 'Editar' : 'Salvar'}
          </button>
        </Wrapper>
      </Field>
      {!!property && <PropertyBar property={property} stage={stage} />}
      {!!person && <PersonBar person={person} stage={stage} />}
    </Form>
  );
};

const selector = formValueSelector('NotesForm');

const mapStateToProps = (state) => ({
  id: selector(state, 'id'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'NotesForm',
  })(NotesForm)
);
