import styled, { css } from 'styled-components';
import Image from 'components/Image';
import { readableColor, darken, rgba } from 'polished';
import Button from 'components/Button';
import { Input } from 'components/SearchBar/styles';

export const Wrapper = styled.header`
  background: ${p => p.theme.bgHeader};
  color: ${p => readableColor(p.theme.bgHeader)};

  .Dropdown,
  .Menu__link {
    height: 40px;
  }

  .Menu__link {
    line-height: 40px;
    color: ${p => readableColor(p.theme.bgHeader)};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 15px;
`;

export const LogoImg = styled(Image).attrs(() => ({
  size: 'original'
}))`
  max-height: 40px;
  margin-right: 15px;
`;

export const Divider = styled.div`
  width: 1px;
  height: 16px;
  background: rgba(255, 255, 255, 0.2);
`;

export const Spacer = styled.div`
  flex: 1 0;
`;

const btnColor = color => {
  return css`
    background-color: ${color};

    :hover {
      background-color: ${darken(0.1, color)} !important;
    }
  `;
};

export const Btn = styled(Button).attrs(() => ({
  size: 'medium'
}))`
  width: 70px;
  border-radius: 4px;
  text-align: center;
`;

export const ButtonSite = styled(Btn).attrs(() => ({
  tagName: 'a',
  target: '_blank'
}))`
  ${btnColor('#01afad')};
  padding: 0;
`;

export const ButtonNew = styled(Btn)`
  ${p => {
    const color = readableColor(p.theme.bgHeader);

    return css`
      padding: 0;
      color: ${color} !important;
      background-color: ${rgba(color, 0.15)} !important;

      :hover {
        background: ${rgba(color, 0.25)} !important;
      }
    `;
  }}
`;

export const MenuItem = styled.div`
  margin: -10px 0;
  padding: 15px 10px;
  cursor: pointer;

  & + & {
    margin-left: -15px;
  }

  svg {
    font-size: 20px;
  }
`;

export const SearchWrapper = styled.div`
  flex: 1;

  ${Input} {
    height: 30px;
  }
`;
