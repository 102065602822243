import { castArray, mapValues } from 'lodash';
import cleanDeep from 'clean-deep';
// Helpers
import { replaceObjectKey } from 'lib/object-helpers';

/**
 *
 * @param values - todos os valores do formulário
 * @param data - informações para que as listas fiquem preenchidas
 * @param valueName - o name do input do formulário
 * @param dataName - a chave aonde armazena a informação
 */
export const normalizeItem = ({ values, data, valueName, dataName }) => {
  if (!values[valueName] || !data[dataName]) return [];
  return data[dataName].filter(
    item => values[valueName].indexOf(item.id) !== -1
  );
};

export { replaceObjectKey };

/**
 * Normaliza as informacoes dos dados iniciais
 * @param values
 * @return {Object}
 */
export const normalizeInitialValues = values => {
  // remove a referência do objeto
  let currentValues = { ...values };

  // remove campo de usuário
  // se enviar usuário cadastra o perfil com o mesmo
  delete currentValues.user_id;

  delete currentValues.areas;

  delete currentValues.by_area;

  // Transforma o campo para bool
  if (currentValues.is_financeable_mcmv) {
    currentValues = {
      ...currentValues,
      is_financeable_mcmv: !!currentValues.is_financeable_mcmv[0]
    };
  }

  // Campos comuns
  currentValues = replaceObjectKey(currentValues, 'profile', 'profiles');
  currentValues = replaceObjectKey(
    currentValues,
    'by_room_greater_equals',
    'rooms'
  );
  currentValues = replaceObjectKey(
    currentValues,
    'by_higher_than_price',
    'minimum_price'
  );
  currentValues = replaceObjectKey(
    currentValues,
    'by_lower_than_price',
    'maximum_price'
  );

  // Listas
  currentValues = replaceObjectKey(
    currentValues,
    'by_characteristic_id',
    'characteristics_id'
  );
  currentValues = replaceObjectKey(
    currentValues,
    'by_condos_characteristic_id',
    'condos_characteristics_id'
  );
  currentValues = replaceObjectKey(
    currentValues,
    'by_establishment_id',
    'establishments_id'
  );

  // Campos de endereço
  currentValues = replaceObjectKey(
    currentValues,
    'neighborhood_id',
    'neighborhoods_id'
  );
  currentValues = replaceObjectKey(
    currentValues,
    'by_country_id',
    'country_id'
  );
  currentValues = replaceObjectKey(currentValues, 'by_state_id', 'state_id');
  currentValues = replaceObjectKey(currentValues, 'by_city_id', 'city_id');

  if (values.by_area) {
    currentValues = {
      ...currentValues,
      area_name: values.by_area.name,
      area_measure: values.by_area.measure,
      maximum_area: values.by_area.lower_equals,
      minimum_area: values.by_area.greater_equals
    };
  }

  if (values.areas) {
    currentValues = {
      ...currentValues,
      area_measure: values.areas.measure
    };
  }

  if (currentValues.rooms) {
    currentValues = {
      ...currentValues,
      rooms: mapValues(currentValues.rooms, value => ({
        label: `${value} ou mais`,
        value: `${value}`
      }))
    };
  }

  // bota os profiles como array
  if (currentValues.profiles) {
    currentValues = {
      ...currentValues,
      profiles: castArray(currentValues.profiles)
    };
  }
  return cleanDeep(currentValues);
};
