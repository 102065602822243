import React from 'react';
import PropTypes from 'prop-types';
import ImageWrapper from 'components/Image';

const propTypes = {
  src: PropTypes.string,
  children: PropTypes.node
};

const defaultProps = {
  src: null,
  children: null
};

const Image = ({ src, alt, children, ...props }) => (
  <figure {...props} className="CardProperty__figure">
    <ImageWrapper
      size="medium"
      src={src}
      alt={alt}
      className="CardProperty__img"
    />
    {children}
  </figure>
);

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
