// Modules
import dataReducer from 'modules/data';
// Services
import { getPropertiesPerTypeTransaction } from 'services/reports';

// Selector
export const propertyPerTypeTransactionSelector = state =>
  state.reports.propertyPerTypeTransaction.data;
export const isFetchingPropertyPerTypeTransactionSelector = state =>
  state.reports.propertyPerTypeTransaction.meta.isFetching;

const {
  reducer: propertyPerTypeTransactionReducer,
  actionCreators: propertyPerTypeTransactionActions
} = dataReducer(`reports/propertyPerTypeTransaction`);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(propertyPerTypeTransactionActions.request());

  getPropertiesPerTypeTransaction(params).then(res => {
    dispatch(propertyPerTypeTransactionActions.receive(res.data));

    return res.data;
  });
};

export default propertyPerTypeTransactionReducer;
