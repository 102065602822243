import { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

export const useTabs = ({ defaultPage = null, pages }) => {
  const history = useHistory();
  const [page, setPage] = useState(defaultPage);

  const totalPages = pages.length;

  const currentIndex = useMemo(() => {
    return pages.findIndex(p => p === page);
  }, [pages, page]);

  const hasNext = useMemo(() => {
    return currentIndex + 1 < totalPages;
  }, [currentIndex, totalPages]);

  const hasPrev = useMemo(() => {
    return currentIndex + 1 > 1;
  }, [currentIndex]);

  const nextPage = useCallback(() => {
    if (hasNext) {
      setPage(pages[currentIndex + 1]);
    } else {
      history.push('/enterprise');
    }
  }, [hasNext, pages, page]);

  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPage(pages[currentIndex - 1]);
    }
  }, [pages, hasPrev]);

  return {
    page,
    currentIndex,
    setPage,
    nextPage,
    prevPage,
    hasNext,
    hasPrev
  };
};
