import { useLocation } from 'react-router';
import parse from 'qs/lib/parse';
import { clearUrlParams } from 'lib/url-helpers';

/**
 * Pega as informações da url e transforma em objct
 * @param clearUrl - limpa a url quando pega
 * @returns {any | {}}
 */
export default function useSearch(clearUrl = true) {
  const { search } = useLocation();

  try {
    const query = parse(search, { ignoreQueryPrefix: true });

    // Limpa os parametros da url
    if (clearUrl) clearUrlParams();

    return query;
  } catch {
    return {};
  }
}
