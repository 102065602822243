import { createSelector } from 'reselect';
import _ from 'lodash';
// Modules
import { LOGOUT_SUCCESS, receiveGoogleAnalytics, RESET_GOOGLE_ANALYTICS, resetGoogleAnalytics } from 'modules/login';
import { setAuth, TYPES_AUTH } from 'modules/authentications';
// Helpers
import { createModule } from 'lib/reducer-helpers';
import { setAnalyticsAuthToken } from 'lib/analytics';

import * as googleService from 'services/google';
import { store } from '../';

const createAction = createModule('google');

// Selectors
export const accessTokenSelector = state => state.google.access_token;
export const refreshTokenSelector = state => state.google.refresh_token;

const userSelector = state => state.login.currentUser;

export const isLoggedSelector = createSelector(
  [refreshTokenSelector, accessTokenSelector],
  (refreshToken, accessToken) => {
    return !!refreshToken && !!accessToken;
  }
);

export const hasIntegration = createSelector(
  [userSelector, isLoggedSelector],
  (currentUser, isLogged) => {
    try {
      const {
        account_id,
        management_id,
        profile_id
      } = currentUser.real_estate.google_analytics;
      return !!account_id && !!management_id && !!profile_id && isLogged;
    } catch {
      return false;
    }
  }
);

// Constants
const SESSION_TIMEOUT = 300;

const Types = {
  SET_CODE: createAction('SET_CODE'),
  SET_ACCESS_TOKEN: createAction('SET_ACCESS_TOKEN'),
  SET_REFRESH_TOKEN: createAction('SET_REFRESH_TOKEN'),
  SET_GRANTED_SCOPES: createAction('SET_GRANTED_SCOPES'),
  RESET: createAction('RESET')
};

const initialState = {
  grantedScopes: '',
  code: '',
  access_token: '',
  refresh_token: '',
  meta: {
    isFetchingCode: false
  }
};

export default function reducerGoogle(state = initialState, action) {
  switch (action.type) {
    case Types.SET_GRANTED_SCOPES:
      return {
        ...state,
        grantedScopes: action.payload.scopes
      };
    case Types.SET_CODE:
      return {
        ...state,
        code: action.payload.code
      };
    case Types.SET_ACCESS_TOKEN:
      return {
        ...state,
        access_token: action.payload.access_token
      };
    case Types.SET_REFRESH_TOKEN:
      return {
        ...state,
        refresh_token: action.payload.refresh_token
      };
    case RESET_GOOGLE_ANALYTICS:
    case Types.RESET:
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}

export const setToken = (tokenName, value) => {
  let currentUser = localStorage.getItem('current-user');
  _.set(currentUser, `real_estate.google_analytics.${tokenName}`, value);
  localStorage.setItem('current-user', currentUser);
};

export const getGoogleAnalytics = () => {
  try {
    return localStorage.getItem('current-user').real_estate.google_analytics;
  } catch {
    return {};
  }
};

export const Actions = {
  setGrantedScopes: scopes => ({
    type: Types.SET_GRANTED_SCOPES,
    payload: { scopes }
  }),
  setGoogleAccessToken: access_token => {
    setToken('access_token', access_token);
    return {
      type: Types.SET_ACCESS_TOKEN,
      payload: { access_token }
    };
  },
  setGoogleRefreshToken: refresh_token => {
    setToken('refresh_token', refresh_token);
    return {
      type: Types.SET_REFRESH_TOKEN,
      payload: { refresh_token }
    };
  },
  resetGoogle: () => ({ type: Types.RESET })
};

let sessionTimeout = 0;
export const setSessionTimeout = duration => {
  clearTimeout(sessionTimeout);
  sessionTimeout = setTimeout(
    refreshAccessToken,
    (duration - SESSION_TIMEOUT) * 1000
  );
};

const onSuccessRefreshToken = ({ access_token, scope, expires_in }) => {
  store.dispatch(Actions.setGoogleAccessToken(access_token));
  store.dispatch(Actions.setGrantedScopes(scope));
  setAnalyticsAuthToken(access_token);
  return { access_token };
};

export const refreshAccessToken = refreshToken => {
  // Pega o refresh token que está na store
  const refresh_token = refreshToken || refreshTokenSelector(store.getState());

  // Verifica se tem um refresh_token
  // Se não existir da um aviso no console
  if (!refresh_token)
    return Promise.reject(
      'Não existe um refresh token! Por favor faça o login novamente'
    );

  return googleService
    .refreshAcessToken(refresh_token)
    .then(onSuccessRefreshToken);
};

export const updateGoogleAnalytics = data => async dispatch => {
  const res = await googleService.updateRefreshToken(data);
  dispatch(setAuth(TYPES_AUTH.GOOGLE_ANALYTICS, true));
  dispatch(receiveGoogleAnalytics(data));
  return res;
};

/**
 * Remove a integração com o google analytics
 */
export const removeIntegration = () => async dispatch => {
  const res = await googleService.removeAnalytics();
  dispatch(setAuth(TYPES_AUTH.GOOGLE_ANALYTICS, false));
  dispatch(resetGoogleAnalytics());
  return res;
};
