import styled, { css } from 'styled-components';
import LabelStatus from 'components/CardProperty/components/LabelStatus';

export const Status = styled(LabelStatus)`
  font-size: 10px;
`;

export const Link = styled.a`
  display: block;
  color: #0063c0;
  cursor: pointer;

  td:hover & {
    text-decoration: underline;
  }

  & + & {
    margin-top: 5px;
  }
`;

const stagnantStyle = ({ isStagnant }) => {
  if (!isStagnant) return;

  return css`
    ::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
      background-color: #ff0000;
    }
  `;
};

export const TableRow = styled.tr`
  td:first-child {
    position: relative;

    ${stagnantStyle}
  }
`;
