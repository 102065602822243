import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, getFormValues } from 'redux-form';
// HOC
import { withPagination } from 'HOC/withPagination';
// Components
import {
  FilterContent,
  FilterTitle,
  FilterWrapper,
} from 'components/FilterAside';
import { CheckboxGroup } from 'components/Form';
import FormWrapper from 'components/FormWrapper';
import MainTitle from 'components/MainTitle';
import Pagination from 'components/Pagination';
import Wrapper from 'components/Wrapper';
import TableProperties from './components/TablePropertiesExcluded';
// Containers
import * as Fields from 'components/PropertyFilters';
import FieldSelectUser from 'containers/FieldSelectUser';
// Modules
import { isReferenceNotChanged } from 'lib/property-helpers';
import { hideLoading, showLoading } from 'modules/loading';
import { openModalPerson, openModalProperty } from 'modules/modal';
import {
  getPropertiesBooked,
  propertiesIsFetchingSelector,
  propertiesSelector,
  resetProperties,
} from 'modules/properties';
import { Actions as FilterActions } from 'pages/Properties/Filter/module';

const defaultParams = {
  filter: {},
  limit: 50,
  offset: 1,
};

class Propertiesbooked extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  componentDidMount() {
    // Busca os dados do imóvel inicial
    this.fetchProperties({
      filter: {
        ...this.initialValues,
        without_draft_scope: true,
      },
    });
  }

  componentWillUnmount() {
    this.props.resetProperties();
  }

  fetchProperties = async (params = defaultParams) => {
    // Mostra o loading
    this.props.showLoading();

    const res = await this.props.getPropertiesBooked(
      {
        ...params,
        include: 'people,user',
        filter: {
          ...params.filter,
          is_booked: true,
        },
        limit: params.limit || defaultParams.limit,
        offset: params.offset || defaultParams.offset,
      },
      'properties-booked'
    );

    // Seta a paginação
    this.props.setPagination(res.meta);

    this.props.hideLoading();

    return res;
  };

  onChangePage = ({ selected = 0 }) => {
    const { values } = this.props;
    this.fetchProperties({
      filter: {
        ...values,
      },
      offset: selected + 1,
    });
  };

  onSubmit = async (values) => {
    return this.fetchProperties({
      filter: {
        ...values,
        without_draft_scope: true,
      },
    });
  };

  handleClickPerson = (person) => {
    this.props.openModalPerson(person);
  };

  handleClickProperty = (property) => {
    this.props.openModalProperty({
      initialPage: 'Reservations',
      property,
      afterClose: () => {
        // Carrega a lista novamente
        this.onChangePage({ selected: 0 });
      },
    });
  };

  get currentUser() {
    try {
      return localStorage.getItem('current-user');
    } catch {
      return {};
    }
  }

  get initialValues() {
    return {};
    // try {
    //   //const { country_id: by_country_id, state_id: by_state_id } = _.get(
    //   //  this.currentUser,
    //   //  'real_estate.relations'
    //   //);

    //   return {
    //     //by_country_id,
    //     //by_state_id,
    //   };
    // } catch {
    //   return {};
    // }
  }

  render() {
    const { pagination } = this.props;

    return (
      <Wrapper full>
        <FormWrapper
          as={FilterWrapper}
          form="Propertiesbooked"
          onSubmit={this.onSubmit}
          initialValues={this.initialValues}
          onChange={(values, dispatch, ownProps, prevValues) => {
            if (isReferenceNotChanged(values, prevValues)) {
              this.props.hideReference();
            }
          }}
        >
          {() => (
            <>
              <FilterTitle>Filtrar</FilterTitle>
              <FilterContent>
                <Fields.Reference />
                <Fields.Transaction canRemoveSelf />
                <Fields.TypeAndSubtype />
                <Fields.Person filter="is_booked" />
                <Fields.Price />
                <Fields.Location />
                <Fields.StreetAddress />
                <FieldSelectUser
                  parse={null}
                  render={({ options, ...params }) => (
                    <div>
                      <label className="FormLabel">Corretor Responsável</label>
                      <CheckboxGroup {...params} limit={4} options={options} />
                    </div>
                  )}
                />
                <div className="h-margin-top--15">
                  <Fields.Database hasGuru={false} />
                  <Fields.Condominium hideEmpty filter={{ is_booked: true }} />
                  <Fields.Furniture />
                </div>
              </FilterContent>
            </>
          )}
        </FormWrapper>

        <Wrapper.inner>
          <Wrapper.container style={{ maxWidth: '985px' }}>
            <MainTitle
              title="Imóveis Reservados"
              text="Cadastre, edite, gerencie tudo que precisar para dar ao seu cliente as melhores informações possíveis!"
            />
            {!this.props.isFetching && (
              <TableProperties
                data={this.props.properties}
                fetchProperties={this.onChangePage}
                handleClickProperty={this.handleClickProperty}
                handleClickPerson={this.handleClickPerson}
              />
            )}
            <Pagination
              forcePage={pagination.current_page - 1}
              pageCount={pagination.total_pages}
              pageRangeDisplayed={10}
              marginPagesDisplayed={2}
              onPageChange={this.onChangePage}
            />
          </Wrapper.container>
        </Wrapper.inner>
      </Wrapper>
    );
  }
}

const selector = formValueSelector('Propertiesbooked');

const mapStateToProps = (state) => ({
  updated_lower_equals: selector(state, 'updated_lower_equals'),
  updated_greater_equals: selector(state, 'updated_greater_equals'),
  properties: propertiesSelector(state),
  values: getFormValues('Propertiesbooked')(state),
  isFetching: propertiesIsFetchingSelector(state),
});

const mapDispatchToProps = {
  hideReference: FilterActions.hideReference,
  propertiesIsFetchingSelector,
  getPropertiesBooked,
  showLoading,
  hideLoading,
  resetProperties,
  openModalPerson,
  openModalProperty,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPagination('properties-booked', { limit: 50 })
)(Propertiesbooked);
