// Modules
import dataReducer from 'modules/data';
// Helpers
import { filterOrAll } from '../helpers';
import { getBillingPerTypeTransaction } from 'services/reports';

export const {
  reducer: billingPerTypeTransactionReducer,
  actionCreators: billingPerTypeTransactionActions,
  selectors: billingPerTypeTransactionSelectors
} = dataReducer(`reports/billingPerTypeTransaction`);

// Selector
export const chartDataSelector = filterOrAll(
  billingPerTypeTransactionSelectors
);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(billingPerTypeTransactionActions.request());

  getBillingPerTypeTransaction(params).then(res => {
    dispatch(billingPerTypeTransactionActions.receive(res.data));

    return res.data;
  });
};

export const toggleChecked = item => dispatch => {
  dispatch(billingPerTypeTransactionActions.toggleSelected(item.id));
};

export default billingPerTypeTransactionReducer;
