import Logo from './Logo';
import Header from './Header';
import Menu from './Menu';
import Search from './Search';
import SlideForm from './SlideForm';
import Properties from './Properties';
import PropertyDetail from './PropertyDetail';
import Banner from './Banner';
import Footer from './Footer';
import About from './About';
import Finance from './Finance';
import Apparence from './Apparence';
import ScriptForm from './ScriptForm';
import Watermark from './Watermark';
import LogoOptions from './LogoOptions';

const Pages = {
  Logo,
  Header,
  Menu,
  Search,
  SlideForm,
  Properties,
  PropertyDetail,
  Banner,
  Footer,
  About,
  Finance,
  Apparence,
  ScriptForm,
  Watermark,
  LogoOptions,
};

export default Pages;
