import styled from 'styled-components';

export const Box = styled.div``;
export const Charts = styled.div`
  display: flex;
  padding-bottom: 20px;

  > div {
    flex: 1 0;
  }
`;
