import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
import { useDispatch, useSelector } from 'react-redux';
import GLogin from 'components/GLogin';
// Modules
import { setGoogleAuth } from 'modules/login';
import { authSelector, TYPES_AUTH } from 'modules/authentications';
// Contants
import Button from 'components/Button';

const propTypes = {
  render: PropTypes.func,
  hideHasIntegration: PropTypes.bool
};

const defaultProps = {
  render: ({ onClick }) => <Button onClick={onClick}>Conectar</Button>,
  hideHasIntegration: true
};

function SyncGoogleCalendar({ render, hideHasIntegration }) {
  const hasAuthentication = useSelector(state =>
    authSelector(TYPES_AUTH.GOOGLE_CALENDAR)(state)
  );

  const dispatch = useDispatch();

  function handleSuccess({ code }) {
    Alert.success('Agenda conectada');
    dispatch(setGoogleAuth(code));
  }

  // Verifica se o usuário já tem autenticação
  // Se ja tem autenticação com o google não pede novamente
  if (hasAuthentication && hideHasIntegration) return null;

  return (
    <GLogin
      scope="profile email https://www.googleapis.com/auth/calendar"
      onSuccess={handleSuccess}
      render={render}
    />
  );
}

SyncGoogleCalendar.propTypes = propTypes;
SyncGoogleCalendar.defaultProps = defaultProps;

export default SyncGoogleCalendar;
