import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
// Services
import * as groupsService from 'services/settings/groups';
// Components
import { Select } from 'components/Form';

class FieldGroups extends React.Component {
  static propTypes = {
    multi: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,

    onFinish: PropTypes.func
  };

  static defaultProps = {
    multi: false,
    name: 'group_id',
    label: 'Selecione o grupo',
    placeholder: 'Selecione o grupo',
    valueKey: 'id',
    labelKey: 'title',
    component: Select,
    onFinish: null
  };

  state = {
    data: [],
    isFetching: false
  };

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async params => {
    this.setState({ isFetching: true });

    try {
      const { data } = await groupsService.getList(params);

      this.setState({ data });

      // Executa alguma coisa quando termina o request
      if (this.props.onFinish) this.props.onFinish(data);
    } finally {
      this.setState({ isFetching: false });
    }
  };

  render() {
    const { data, isFetching } = this.state;
    return <Field {...this.props} options={data} loading={isFetching} />;
  }
}

export default FieldGroups;
