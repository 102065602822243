import React, { useEffect, useState } from 'react';
import useReduxForm from 'hooks/useReduxForm';
import Preview from './pages/Preview';
import withProvider from 'HOC/withProvider';
import { ModalUserProvider } from './contexts/ModalUserContext';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractRemove/contexts/modalContractExtra';

// import { Wrapper } from './styles';

function UserAndEmail() {
  const [canRenderPage, setCanRenderPage] = useState(false);
  const { dispatch, initialize } = useReduxForm();
  const { isCancel } = useModalContractExtra();

  function renderPage() {
    if (!canRenderPage) return null;

    return <Preview />;
  }

  useEffect(() => {
    if (isCancel) {
      dispatch(
        initialize({
          plan: 2,
        })
      );
    } else {
      dispatch(
        initialize({
          plan: 1,
        })
      );
    }

    setCanRenderPage(true);
  }, [dispatch, initialize]);

  return renderPage();
}

export default withProvider(ModalUserProvider)(UserAndEmail);
