import React from 'react';
import { useDispatch } from 'react-redux';
import { change, reduxForm, submit } from 'redux-form';
import PropTypes from 'prop-types';

const defaultProps = {
  shouldSubmitOnChange: true
};
const propTypes = {
  shouldSubmitOnChange: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired
};

function FormSort({
  shouldSubmitOnChange,
  mainForm,
  handleSubmit,
  children,
  ...props
}) {
  const dispatch = useDispatch();

  const handleChangeSelect = fieldName => (e, value) => {
    dispatch(change(mainForm, fieldName, value));

    if (shouldSubmitOnChange) {
      setTimeout(() => {
        dispatch(submit(mainForm));
      }, 50);
    }
  };

  return <form>{children({ handleChangeSelect, ...props })}</form>;
}

FormSort.defaultProps = defaultProps;
FormSort.propTypes = propTypes;

export default reduxForm({
  enableReinitialize: true
})(FormSort);
