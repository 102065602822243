// import styles from './styles'

import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import React, { useEffect } from 'react';
import { useFeaturedList } from 'hooks/api/featuredLists';
import { useHistory, useParams } from 'react-router';
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import SortableProperties from 'pages/Site/pages/ContentSite/pages/FeaturedLists/components/SortableProperties';
import Container from 'components/Container';
import { Nodata } from './styles';
import noData from '../../assets/no-data.svg';

function ListProperties() {
  const history = useHistory();
  const { id } = useParams();
  const {
    dataFeaturedList,
    isFetchingFeaturedList,
    fetchFeaturedList,
    handleSortEndProperties,
    handleRemoveProperty,
    handleUpdateProperties,
  } = useFeaturedList();

  useEffect(() => {
    fetchFeaturedList(id);
  }, [id]);

  if (isFetchingFeaturedList) return null;

  return (
    <Wrapper>
      <MainTitle
        title={dataFeaturedList.title}
        text="Adicione e organize os imóveis nesta galeria, com um limite de até 50 imóveis."
      >
        <Button color="secondary" to={`/featured/${id}/property-add`}>
          Adicionar imóvel
        </Button>
      </MainTitle>

      <Container>
        {dataFeaturedList?.properties?.length > 0 ? (
          <SortableProperties
            data={dataFeaturedList.properties}
            onRemove={handleRemoveProperty}
            onSortEnd={handleSortEndProperties}
          />
        ) : (
          <Nodata>
            <img src={noData} alt="" />
          </Nodata>
        )}
      </Container>

      <FixedBar>
        <Button
          onClick={() => history.push('/site/content-site/featured-lists')}
          color="white"
        >
          Voltar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button color="success" onClick={() => handleUpdateProperties(id)}>
          Salvar
        </Button>
      </FixedBar>
    </Wrapper>
  );
}

export default ListProperties;
