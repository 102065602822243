import Button from "components/Button";
import Loading from "components/Loading";
import MainTitle from "components/MainTitle";
import NoContent from "pages/PropertyStore/components/NoContent";
import { Col, Row } from "react-flexbox-grid";
import { useHistory } from "react-router";
import ContainerLocations from "../../containers/ContainerLocations";
import image from './image.svg';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocations, isFetchingLocationsSelector, locationsSelector } from "../../module";

function List() {
  const dispatch = useDispatch();

  const locations = useSelector(locationsSelector);
  const isFetching = useSelector(isFetchingLocationsSelector);

  useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  const history = useHistory();

  const handleClickDescribeCity = () => {
    history.push('/locations/store/city');
  };

  const handleClickDescribeNeighborhood = () => {
    history.push('/locations/store/neighborhood');
  };

  if (isFetching) {
    return <Loading isVisible isFullScreen />;
  }

  if (locations.cities.length <= 0 && locations.neighborhoods.length <= 0) {
    return (
      <NoContent
        image={image}
        title="Postagens sobre região"
        text="Desenvolver uma postagem sobre uma cidade e/ou um bairro ajuda a melhorar seu posicionamento no Google, pois ao abrir a página de detalhes do imóvel, ao final será anexado dados sobre a cidade e o bairro ao qual o imóvel pertence."
        maxContentWidth="370px"
      >
        <Row>
          <Col xs={0}>
            <Button color="secondary" onClick={handleClickDescribeCity}>
              Falar sobre uma cidade
            </Button>
          </Col>
          <Col xs={0}>
            <Button color="secondary" onClick={handleClickDescribeNeighborhood}>
              Falar sobre um bairro
            </Button>
          </Col>
        </Row>
      </NoContent>
    );
  }

  return (
    <>
      <MainTitle
        title="Página Inicial - Postagens sobre região"
        text="Enriquecimento de SEO"
      >
        <Button onClick={handleClickDescribeCity} color="secondary">
          Descrever uma cidade
        </Button>
        <Button onClick={handleClickDescribeNeighborhood} color="secondary">
          Descrever um bairro
        </Button>
      </MainTitle>
      <ContainerLocations
        citiesDescriptions={locations.cities}
        neighborgoodsDescriptions={locations.neighborhoods}
      />
    </>
  );
}

export default List;
