import React from 'react';
import Wrapper from 'components/Wrapper';
import Aside from './components/Aside';
import Pages from './components/Pages';
import WizardCrm2 from 'containers/Wizards/WizardCrm2';
import { useSelector } from 'react-redux';
import { canShowWizardSelector } from 'pages/Crm/modules/asideCrm';

function Crm() {
  const canShowWizard = useSelector(canShowWizardSelector);

  return (
    <Wrapper full>
      {canShowWizard && <WizardCrm2 />}
      <Aside />
      <Pages />
    </Wrapper>
  );
}

export default Crm;
