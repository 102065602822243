import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Components
import Label from '../Label';
import Icon from '../Icon';
import Dropdown, { Item, List } from '../Dropdown';

const propTypes = {
  wrapperRenderer: PropTypes.func,
  children: PropTypes.node
};

const defaultProps = {
  wrapperRenderer: null,
  children: null
};

// wrapperRenderer
// actionRenderer

const CardListWrapper = ({ children }) => (
  <div className="CardList__wrapper">{children}</div>
);
const CardListText = ({ children }) => (
  <div className="CardList__text">{children}</div>
);
const CardListFooter = ({ children }) => (
  <div className="CardList__footer">{children}</div>
);
const CardListContent = ({ children }) => (
  <div className="CardList__content">{children}</div>
);

const CardList = ({
  tags,
  wrapperRenderer,
  actionRenderer,
  children,
  className,
  style
}) => (
  <div className={classnames('CardList', className)} style={style}>
    {wrapperRenderer ? (
      wrapperRenderer()
    ) : (
      <CardListWrapper>
        <CardListContent>{children}</CardListContent>

        {actionRenderer ? (
          actionRenderer()
        ) : (
          <div className="CardList__action">
            <Dropdown>
              <button>
                <Icon name="chevron-down" />
              </button>
              <List>
                <Item>Teste 1</Item>
                <Item>Teste 2</Item>
              </List>
            </Dropdown>
          </div>
        )}
      </CardListWrapper>
    )}
    {tags && (
      <div className="CardList__tags">
        {tags.map((tag, i) => (
          <Label key={`${tag}-${i}`} color="white">
            {tag}
          </Label>
        ))}
      </div>
    )}
  </div>
);

CardList.propTypes = propTypes;
CardList.defaultProps = defaultProps;

CardList.wrapper = CardListWrapper;
CardList.content = CardListContent;
CardList.footer = CardListFooter;
CardList.text = CardListText;

export default CardList;
