import React from 'react';
import { Text, Title } from './styles';

// import { Wrapper } from './styles';

const Item = ({ title, children }) => {
  return (
    <>
      <Title>{title}</Title>
      <Text>{children}</Text>
    </>
  );
};

export default Item;
