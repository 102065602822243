import React from 'react';
// Components
import MainTitle from 'components/MainTitle';
import Container from 'components/Container';
import Wrapper from 'components/Wrapper';
import TableAdmin from 'pages/Home/components/TableAdmin';

const defaultProps = {};
const propTypes = {};

function DealWon() {
  return (
    <Wrapper.container>
      <MainTitle
        title="Informações da equipe"
        text="Detalhamento sobre clientes, imóveis e CRM por corretor"
      />
      <Container padding>
        <TableAdmin />
      </Container>
    </Wrapper.container>
  );
}

DealWon.defaultProps = defaultProps;
DealWon.propTypes = propTypes;

export default DealWon;
