import { useRequest } from 'hooks/useRequest';
import api from 'services/index';
import { useCurrentUser } from 'hooks/useCurrentUser';
import moment, { format } from 'lib/moment';

export const useGetLeadsByDays = (days = 7) => {
  const currentUser = useCurrentUser();

  const {
    data: leads,
    fetchData: fetchLeads,
    isFetching: isFetchingLeads,
  } = useRequest({
    request: (params) => {
      const currentDate = moment();

      const startDate = currentDate
        .clone()
        .subtract(days, 'days')
        .format(format.date);

      const endDate = currentDate.format(format.date);

      return api.getList('reports/leads/latest', {
        user_id: currentUser.id,
        start_date: startDate,
        end_date: endDate,
      });
    },
  });

  return { leads, fetchLeads, isFetchingLeads };
};
