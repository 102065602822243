import Socket from 'lib/Socket';

export default class Events extends Socket {
  constructor(accessToken) {
    super(accessToken);

    // Conecta no canal de notificações
    this.connect('events');
  }

  onPaymentSuccess(callBack) {
    this.on('paymentSuccess', callBack);
  }

  onRefresh(callBack) {
    this.on('refresh', callBack);
  }
}
