export default values => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (!values.title) {
    errors.title = REQUIRED_MSG;
  }

  return errors;
}
