import { WrapperAll } from 'pages/Site/pages/ContentSite/pages/Posts/components/TablePosts/styles';

import TableRow from './TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { addFunnel, funnelsSelector } from 'modules/funnel';
import { MdAdd, MdHelp } from 'react-icons/md';
import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
import React, { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { openModalFunnelStore } from '../../../../../../modules/modal';
import { useHistory } from 'react-router-dom';
import BoxHelp from 'components/BoxHelp';

function TableFunnels() {
  const dispatch = useDispatch();
  const data = useSelector(funnelsSelector);
  const history = useHistory();

  useEffect(() => {
    Tooltip.rebuild();
  }, [data]);

  const openStoreFunnel = () => {
    dispatch(
      openModalFunnelStore({
        onSuccess: (res) => {
          // Adiciona o funil na listagem de funis
          dispatch(addFunnel(res.data));

          // Manda para a página de edição do funil
          history.push(`/crm/funnel/${res?.data?.id}`);
        },
      })
    );
  };

  if (!data || data.length === 0) return null;

  return (
    <>
      <BoxHelp
        style={{
          marginTop: '30px',
        }}
        storageKey="crm-2-config"
        topics={[
          {
            text: 'Adicionar negócio ao CRM',
            videoUrl: 'https://www.youtube.com/watch?v=JD_xa23a-3s',
            link: 'https://scribehow.com/shared/Adicionar_negocio_ao_CRM__EpWFnPlYQEOY-JcoqXvguQ',
          },
          {
            text: 'Criar Funil',
            videoUrl: 'https://youtu.be/UXU6XHN3Njg',
            link: 'https://scribehow.com/shared/Criar_Funil_no_CRM__ANSr0P5eRvWvMsr1cSsf8g?referrer=documents',
          },
          {
            text: 'Editar funil',
            videoUrl: 'https://youtu.be/1AITnfcfGso',
            link: 'https://scribehow.com/shared/Editar_Funil_do_CRM_migrar_dados_excluir_etapa_e_funil__KKlkpjU-TdSwq9kdZNET0Q?referrer=documents',
          },
          {
            text: 'Radar de oportunidades',
            videoUrl: 'https://www.youtube.com/watch?v=5GdzEX5EGxo',
            link: 'https://scribehow.com/shared/Radar_de_oportunidades__q6NpL_N3Q3qsQA1NTTt_bw',
          },
          {
            text: 'Distribuição padrão de funil por negócio',
            videoUrl: 'https://youtu.be/7KtqaEfScXU',
            link: 'https://scribehow.com/shared/Distribuicao_padrao_de_funil_por_negocio__akOppQrVRVuNZFNVL02WaQ?referrer=documents',
          },
        ]}
        mainTopic={{
          text: 'Apresentação CRM imobiliário Tecimob',
          videoUrl: 'https://youtu.be/8DX7mudPCNs',
        }}
        helpLink="https://tecimob.com.br/ajuda/crm-2/"
      />
      <MainTitle
        title="Edição de funil"
        text="Deixe as etapas simples, baseadas nas atividades do dia a dia de trabalho."
      >
        <Button onClick={openStoreFunnel} color="secondary">
          <MdAdd />
          Criar funil
        </Button>
      </MainTitle>
      <WrapperAll>
        <table className="Table">
          <thead>
            <tr>
              <th>Nome do funil</th>
              <th>
                Limpeza em{' '}
                <MdHelp data-tip="Prazo em dias que o negócio será encerrado automaticamente por estagnação." />
              </th>
              <th>Opções</th>
            </tr>
          </thead>
          {data?.map((value) => (
            <TableRow key={'table-funnels-' + value.id} data={value} />
          ))}
        </table>
      </WrapperAll>
    </>
  );
}

export default TableFunnels;
