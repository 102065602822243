import React from 'react';
import { Field } from 'redux-form';
import { Col } from 'react-flexbox-grid';
import Errors from '../../../../../../components/Form/components/Errors';
import { Tag } from 'primereact/tag';

// import { Wrapper } from 'react';

function SendOwnerReport({ input, meta }) {
  return (
    <Col style={{ marginLeft: '5px', marginTop: '27px' }}>
      {[30, 60, 90, 120, 150, 180].map((number) => {
        let style = {};
        let severity = '';

        if (number !== input.value) {
          style = { background: 'gray' };
        } else {
          severity = 'success';
        }

        return (
          <Tag
            style={{ ...style, cursor: 'pointer' }}
            severity={severity}
            className="h-margin-left--5"
            onClick={() => {
              input.onChange(number);
            }}
          >
            +{number}
          </Tag>
        );
      })}
      <span
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginTop: '4px',
          marginLeft: '10px',
        }}
      >
        dias
      </span>
      <Errors meta={meta} />
    </Col>
  );
}

function FieldSendOwnerReport() {
  return <Field name="should_send_owner_report" component={SendOwnerReport} />;
}

export default FieldSendOwnerReport;
