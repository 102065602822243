import api from 'services';
import _ from 'lodash';
import { getChecked } from 'lib/object-helpers';

export const transformData = values => {
  let newValues = { ...values };

  if (values.establishments) {
    newValues = {
      ...newValues,
      establishments: getChecked(newValues.establishments)
    };
  }

  return newValues;
};

export const transformResponse = res => {
  res.data.establishments = _(res.data.establishments_id)
    .map(id => ({
      id,
      isChecked: true
    }))
    .keyBy('id')
    .value();

  // Remove establishments_id
  delete res.data.establishments_id;

  return res;
};

export const getOne = id =>
  api
    .getOne(`properties/partial/${id}/establishments`, '')
    .then(transformResponse);

export const update = ({ id, ...data }) =>
  api.patch(`properties/partial/${id}/establishments`, transformData(data));
