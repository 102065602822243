import { useEffect, useState } from 'react';
// Components
import FormWhatsapp from 'components/FormWhatsapp';
// Services
import * as whatsappService from 'services/sites/whatsapp';
import Loading from 'components/Loading';

const Chat = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  const fetchData = async () => {
    // busca os dados do whatsapp
    const { data: formData } = await whatsappService.getOne();
    setIsFetching(false);

    setInitialValues(formData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isFetching) {
    return <Loading isVisible isFullScreen />;
  }

  return (
    <FormWhatsapp
      initialValues={initialValues}
      onSubmit={whatsappService.update}
      fetchData={fetchData}
    />
  );
};

export default Chat;
