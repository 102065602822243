// import * as S from './styles'

import { reduxForm } from 'redux-form';
import { RadioList } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

function FormMovimentDestroy({ hasRecurrency, handleSubmit, handleClose }) {
  return (
    <form onSubmit={handleSubmit}>
      {hasRecurrency ? (
        <>
          <p className="h-margin-bottom--15">
            Este lançamento se repete em outras datas. Tem certeza que deseja
            excluir...?
          </p>
          <RadioList
            variant="simple"
            name="repeatment_type"
            label="Esse lançamento de repete em outras datas:"
            options={[
              { label: 'Excluir apenas este lançamento', value: 'unic' },
              { label: 'Excluir este e os próximos', value: 'forward' },
              { label: 'Excluir todos os lançamentos', value: 'all' },
            ]}
          />
        </>
      ) : (
        <p>Tem certeza que deseja excluir este lançamento...?</p>
      )}
      <ModalFooter>
        <Button color={'white'} onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color={'danger'}>
          Excluir
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({ form: 'FormMovimentDestroy' })(FormMovimentDestroy);
