import styled from 'styled-components';
import { MenuItem } from '../../styles';

export const Wrapper = styled(MenuItem)`
  position: relative;

  .Ball {
    position: absolute;
    bottom: 15px;
    right: 10px;
  }
`;
