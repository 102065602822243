import React from 'react';
import { Field } from 'redux-form';
import { Select } from 'components/Form';
import FormSort from 'components/FormSort';

function SortPeople() {
  return (
    <FormSort
      form="SortPeople"
      mainForm="PeopleFilterForm"
      initialValues={{ sort: 'name' }}
    >
      {({ handleChangeSelect }) => (
        <Field
          name="sort"
          options={[
            { label: 'Ordem alfabética', value: 'name' },
            { label: 'Data de interação', value: '-interacted_at' },
          ]}
          onChange={handleChangeSelect('sort')}
          component={Select}
        />
      )}
    </FormSort>
  );
}

SortPeople.propTypes = {};
SortPeople.defaultProps = {};

export default SortPeople;
