import React, { useEffect } from 'react';
import { compose } from 'redux';
import MainTitle from 'components/MainTitle';
import SeeExample from 'pages/EnterpriseStore/components/SeeExample';
import { Field, reduxForm } from 'redux-form';
import { FORM_NAME } from 'pages/EnterpriseStore/constants';
import FormFixedBar from 'pages/EnterpriseStore/components/FormFixedBar';
import withReception from 'HOC/withReception';
import { get, update } from 'services/buildings/tour';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { useRequest } from 'hooks/useRequest';
import Content from 'pages/EnterpriseStore/components/Content';
import { Input } from 'components/Form';

const Tour = ({ handleSubmit, initialize, array }) => {
  const { enterpriseId } = useEnterprise();
  const { data: initialValues, isFetching } = useRequest({
    request: get,
    params: enterpriseId,
  });

  useEffect(() => {
    initialize(initialValues);
  }, [initialValues]);

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Tour Virtual"
        text="Um tour virtual bem feito faz a diferença entre o cliente querer visitar um imóvel ou não."
      >
        <SeeExample />
      </MainTitle>
      <Content isFetching={isFetching}>
        <Field
          label="Url do tour"
          placeholder="Ex.: http://www.url-exemplo-tour.com.br/9999999"
          name="tour_360"
          component={Input}
        />
      </Content>
      <FormFixedBar />
    </form>
  );
};

export default compose(
  withReception('enterprise/tour'),
  reduxForm({
    form: FORM_NAME,
    onSubmit: update,
  })
)(Tour);
