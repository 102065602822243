import React, { useMemo } from 'react';
import getVideoId from 'get-video-id';
import YoutubeVideo from 'components/YoutubeVideo';
// import { Container } from './styles';

const Video = ({ url = null }) => {
  const video = useMemo(() => {
    try {
      return getVideoId(url);
    } catch {
      return { id: null, service: null };
    }
  }, [url]);

  if (video.service === 'youtube') {
    return <YoutubeVideo youtubeProps={{ videoId: video.id }} />;
  }

  return null;
};

export default Video;
