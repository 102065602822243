import React, { useEffect, useState } from 'react';
import { useGallery } from 'components/PropertyGallery/context';
import Button from 'components/Button';
import FormGalleryUpload from 'components/FormGalleryUpload';
import { MdAdd, MdCheckCircle } from 'react-icons/md';
import * as S from './styles';
import Image from 'components/Image';
import { ModalFooter } from 'components/Modal';
import { ThemeProvider } from 'styled-components';
import {
  PAGES,
  useModal,
} from 'containers/ModalRoot/ModalGeneratePost/context';
// import { Wrapper } from './styles';

export default function PropertyImageSelection({ handleClose }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const { propertyId, data, fetchData, uploadImages } = useGallery();
  const { setPage, setImage } = useModal();

  useEffect(() => {
    fetchData(propertyId, {
      gallery: 1,
    });
  }, []);

  const handleSelectImage = (image) => () => {
    setSelectedImage(image);
  };

  const handleClickNext = () => {
    setImage(selectedImage);
    setPage(PAGES.POST_GENERATOR);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <div>
          <S.Title>Selecionar imagem</S.Title>
          <S.Text>
            Escolha uma imagem disponível ou envie uma nova imagem
          </S.Text>
        </div>
        <div>
          <FormGalleryUpload
            form="FormReception"
            key="form-upload-reception"
            initialValues={{
              property_id: propertyId,
              gallery: 1,
            }}
            onChange={uploadImages}
            className="h-margin-left--15"
          >
            <Button color="secondary" size="large">
              <MdAdd /> Enviar fotos
            </Button>
          </FormGalleryUpload>
        </div>
      </S.Header>
      <S.List>
        {data.map((image) => {
          const isSelected = image?.id === selectedImage?.id;

          return (
            <ThemeProvider
              theme={{
                isSelected,
              }}
            >
              <S.Image key={image.id} onClick={handleSelectImage(image)}>
                <Image src={image.file_url} alt="Imagem" />
                {isSelected ? (
                  <S.Overlay>
                    <span>
                      <MdCheckCircle
                        size={16}
                        color="#31D084"
                        className="h-margin-right--10"
                      />
                      Imagem Selecionada
                    </span>
                  </S.Overlay>
                ) : (
                  <S.Overlay>
                    <span>Selecionar</span>
                  </S.Overlay>
                )}
              </S.Image>
            </ThemeProvider>
          );
        })}
      </S.List>
      <ModalFooter>
        <Button color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button
          color="success"
          disabled={!selectedImage}
          onClick={handleClickNext}
        >
          Próximo
        </Button>
      </ModalFooter>
    </S.Wrapper>
  );
}
