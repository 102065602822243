import styled from 'styled-components';

export const Error = styled.div`
  display: block;
  color: ${p => p.theme.colors.danger};

  .GroupControl + & {
    margin-top: -15px;
    margin-bottom: 8px;
  }
`;
