import React from 'react';
// Components
import Wizard from 'components/Wizard';
// HOC
import { withWizard } from 'HOC/withWizard';

const STEPS = [
  {
    target: '.js-aside-enterprise',
    title: 'Áreas do Hotsite',
    content:
      'Aqui você encontra todas as áreas do seu Hotsite. Capriche nas informações!',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    isFixed: true,
    placement: 'right-center'
  },
  {
    target: '.js-button-preview',
    title: 'Pré-visualização',
    content: 'Clique aqui para acompanhar como está ficando o seu Hotsite.',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    isFixed: true,
    placement: 'bottom'
  },
  {
    target: '.js-next-button',
    title: 'Salvar',
    content: 'Para salvar as alterações clique aqui.',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    isFixed: true,
    placement: 'top'
  }
];

function WizardEnterprise({ onClose }) {
  return <Wizard steps={STEPS} onClose={onClose} />;
}
export default withWizard('wizard/enterprise-store2')(WizardEnterprise);
