import React from 'react';
import { Wrapper } from './styles';
import Checkbox from './components/Checkbox';

function SelectBox({
  minOptions = 1,
  maxHeight = 300,
  options,
  input,
  valueKey,
  labelKey,
  ...props
}) {
  const handleChange = (option) => (event) => {
    const arr = [...input.value];
    const value = option[valueKey];

    if (event.target.checked) {
      arr.push(value);
    } else {
      if (input.value.length === minOptions) {
        return false;
      }
      arr.splice(arr.indexOf(value), 1);
    }
    return input.onChange(arr);
  };

  return (
    <Wrapper maxHeight={maxHeight} {...props}>
      {options.map((option, index) => (
        <Checkbox
          key={index}
          index={index}
          input={input}
          option={option}
          valueKey={valueKey}
          labelKey={labelKey}
          handleChange={handleChange(option)}
        />
      ))}
    </Wrapper>
  );
}

export default SelectBox;
