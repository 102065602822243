import styled, { css } from 'styled-components';
import { em, rgba } from 'polished';

const actionIconColor = ({
  theme: { active, colors, defaultColor, activeColor }
}) => {
  const color = colors[defaultColor];
  const colorActive = colors[activeColor];

  if (active) {
    return css`
      color: ${colorActive};

      background-color: ${rgba(colorActive, 0.3)};
    `;
  }

  return css`
    color: ${color};
    background-color: ${rgba(color, 0.3)};
  `;
};

const mixinCounter = ({
  counter,
  theme: {
    colors: { danger }
  }
}) => {
  if (!counter || counter <= 0) return null;

  return css`
      &::after {
        content: "${counter}";
        font-size: .6em;
        position: absolute;
        bottom: 0.5em;
        right: 0.5em;
        border-radius: 23em;
        padding: 0 .2em;
        color: #ffffff;
        background-color: ${danger};
        min-width: 14px;
        height: 14px;
        line-height: 14px;
        text-align: center;
      }
    `;
};

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${p => em(18, p.theme.size / 3)};
  width: ${p => p.theme.size}px;
  height: ${p => p.theme.size}px;
  border-radius: 50%;

  ${p =>
    p.onClick &&
    css`
      cursor: pointer;
    `}

  ${actionIconColor};
  ${mixinCounter};
`;
