// Hooks
import { useRequest } from 'hooks/useRequest';

// Services
import * as buildingsService from 'services/buildings';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useHistory } from 'react-router-dom';

export const useBuildings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, setData, fetchData, isFetching } = useRequest({
    request: buildingsService.getList,
  });

  const setStatus = useCallback(({ id, status }) => {
    setData((currentData) => {
      return currentData.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status,
          };
        }

        return item;
      });
    });
  }, []);

  const handleDelete = useCallback(({ id }) => {
    dispatch(
      openConfirmation({
        title: 'Remover Hotsite',
        text: 'Você deseja realmente apagar este Hotsite?',
        request: () => {
          return buildingsService.remove(id);
        },
        onSuccess: () => {
          fetchData();
        },
      })
    );
  }, []);

  const handleDuplicatePage = (building) => () => {
    dispatch(
      openConfirmation({
        title: `Duplicar hotsite`,
        text: (
          <>
            Tem certeza que você quer duplicar o hotsite:{' '}
            <strong>{building.name}</strong>
          </>
        ),
        submitButtonText: 'Duplicar',
        submitButtonColor: 'success',
        request: async () => {
          const response = await buildingsService.clone(building);

          history.push(`enterprise/store/${response.data.id}`);

          return response;
        },
      })
    );
  };

  const handleToggleStatus = useCallback(
    ({ id, status }) => {
      // Seta o status da página
      buildingsService.update({ id, status: !status }).then(({ data }) => {
        setStatus({
          id,
          status: data.status,
        });
      });

      setStatus({
        id,
        status: !status,
      });
    },
    [setData]
  );

  return {
    data,
    isFetching,
    handleToggleStatus,
    handleDelete,
    handleDuplicatePage,
  };
};
