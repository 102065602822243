import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid
    ${(p) => {
      if (p.theme.isChecked) return p.theme.colors.secondary;
      return p.theme.borderColor;
    }};
  border-radius: 4px;

  font-weight: 600;
  user-select: none;
  cursor: pointer;

  svg {
    font-size: 22px;
    height: 22px;
    color: ${(p) => p.theme.isChecked && p.theme.colors.secondary};
  }

  .icon-radio {
    color: #d3dceb;
    width: 22px;
    height: 22px;
  }

  .icon--remove {
    color: ${(p) => p.theme.colors.danger};
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    margin: -10px;
  }
`;

export const Text = styled.span`
  margin: 0 10px;
`;
