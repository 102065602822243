import { useMovimentsMonthly } from 'features/Financial/hooks/useDashboard';
import { useCurrentUser } from 'hooks/useCurrentUser';

import * as S from './styles';
import { Box } from 'features/Financial';
import { getFirstName } from 'lib/text';
import { useDashboardContext } from '../../hooks/useDashboardContext';
import { useEffect } from 'react';

export function MovimentsMonthly() {
  const currentUser = useCurrentUser();
  const { refreshKey } = useDashboardContext();

  const { movimentsMonthly, fetchMoviments, movimentsMonthlyIsFetching } =
    useMovimentsMonthly();

  useEffect(() => {
    fetchMoviments();
  }, [refreshKey]);

  return (
    <S.Wrapper>
      <S.Left>
        Bom dia,
        <S.UserName>{getFirstName(currentUser.name)}</S.UserName>
      </S.Left>
      <S.Right>
        <Box.Wrapper>
          <Box.Content>
            <Box.Title>Receita mensal</Box.Title>
            <Box.BigText>{movimentsMonthly.credits}</Box.BigText>
          </Box.Content>
        </Box.Wrapper>
        <Box.Wrapper>
          <Box.Content>
            <Box.Title>Despesa mensal</Box.Title>
            <Box.BigText>{movimentsMonthly.debits}</Box.BigText>
          </Box.Content>
        </Box.Wrapper>
        <Box.Wrapper color="#FD9726">
          <Box.Content>
            <Box.Title>Receitas vencidas</Box.Title>
            <Box.BigText>{movimentsMonthly.overdueCredits}</Box.BigText>
          </Box.Content>
        </Box.Wrapper>
        <Box.Wrapper color="#F84343">
          <Box.Content>
            <Box.Title>Despesas vencidas</Box.Title>
            <Box.BigText>{movimentsMonthly.overdueDebits}</Box.BigText>
          </Box.Content>
        </Box.Wrapper>
      </S.Right>
    </S.Wrapper>
  );
}
