import React from 'react';
// Component
import Table from 'components/Table';
import PeopleItem from '../PeopleItem';

const PeopleTable = ({ data, toggleChecked }) => (
  <Table
    responsive
    data={data}
    isFetching={false}
    renderTitle={() => (
      <tr>
        <th className="h-sticky--left">Canal</th>
        {data[0] &&
          data[0].data.map(dat => <th key={dat.period}>{dat.period}</th>)}
        <th className="h-sticky--right">Total</th>
      </tr>
    )}
    renderItem={data => (
      <PeopleItem
        key={`item-${data.type}`}
        data={data}
        toggleChecked={toggleChecked}
      />
    )}
  />
);

export default PeopleTable;
