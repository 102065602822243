import React from 'react';
import { Row } from 'react-flexbox-grid';
import { SortableContainer } from 'react-sortable-hoc';
// Components
import SlideItem from '../SlideItem';

const propTypes = {};
const defaultProps = {
  items: [],
};

const SortableContainerWrapper = SortableContainer(
  ({ items, handleRemoveSlide, ...rest }) => (
    <Row style={{ marginTop: -15 }}>
      {items.map((item, index) => (
        <SlideItem
          {...rest}
          key={item.id}
          index={index}
          data={item}
          handleRemoveSlide={handleRemoveSlide}
        />
      ))}
    </Row>
  )
);

const ListSlides = (props) => <SortableContainerWrapper {...props} />;

ListSlides.propTypes = propTypes;
ListSlides.defaultProps = defaultProps;

export default ListSlides;
