import React from 'react';
import PropTypes from 'prop-types';
import Check from 'components/Check';
// Lib
import { addCurrency } from 'lib/money-helpers';
import { Legend } from '../../../styles';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    broker: PropTypes.string,
    data: PropTypes.array
  })
};

const defaultProps = {
  property: {}
};

const calcTotal = (total, current) => total + parseFloat(current.value);

const BillingItem = ({
  toggleChecked,
  data,
  data: { broker, data: periods, isSelected, color }
}) => (
  <tr role="button" className="h-hover h-pointer" onClick={toggleChecked(data)}>
    <td className="h-sticky--left h-text-nowrap h-flex h-flex--center-center">
      <Check checked={isSelected} className="h-margin-right--15" />
      <Legend color={color} />
      {broker}
    </td>
    {periods.map((period, index) => (
      <td
        className="Table__cell--money h-text-nowrap"
        key={`value-per-broker-${index}-${period.value}`}
      >
        {addCurrency(period.value)}
      </td>
    ))}
    <td className="Table__cell--money h-sticky--right h-text-nowrap">
      {addCurrency(periods.reduce(calcTotal, 0))}
    </td>
  </tr>
);

BillingItem.propTypes = propTypes;
BillingItem.defaultProps = defaultProps;

export default BillingItem;
