import React, { useCallback, useEffect, useMemo } from 'react';
import { FieldArray, getFormValues, reduxForm } from 'redux-form';

// Components
import MainTitle from 'components/MainTitle';
import SeeExample from '../../components/SeeExample';
import FormFixedBar from '../../components/FormFixedBar';
import Content from '../../components/Content';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';

// Services
import * as slidesService from 'services/buildings/slides';
import { useRequest } from 'hooks/useRequest';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { compose } from 'redux';
import Images from 'pages/EnterpriseStore/pages/Slides/components/Images';
import UploadSlide from 'pages/EnterpriseStore/pages/Slides/components/UploadSlide';
import { useDispatch, useSelector } from 'react-redux';
import Reception from 'pages/EnterpriseStore/components/Reception';

// Assets
import image from './reception-slides.png';
import { openConfirmation } from 'containers/ModalConfirmation/module';

const Slides = ({ handleSubmit, initialize }) => {
  const dispatch = useDispatch();
  const values = useSelector(getFormValues(FORM_NAME));

  const { enterpriseId } = useEnterprise();
  const { data, fetchData, isFetching } = useRequest({
    request: slidesService.get,
    params: enterpriseId,
  });

  const fetchSlides = useCallback(async () => {
    try {
      // Caso não tenha nenhum slide
      // força um retorno para que não de erro
      if (!values?.slides || values?.slides?.length <= 0) return false;

      // Antes de atualizar qualquer coisa
      // tem que salvar o que está no formulário
      await slidesService.update({
        id: enterpriseId,
        slides: values?.slides,
      });
    } finally {
      fetchData();
    }
  }, [fetchData, values]);

  const hasImages = useMemo(() => {
    return data?.length > 0;
  }, [data]);

  const handleRemoveImage = useCallback(
    (imageId) => async () => {
      dispatch(
        openConfirmation({
          title: 'Remover Slide',
          text: 'Deseja realmente excluir esse slide?',
          request: () =>
            slidesService.remove({ id: imageId, buildingId: enterpriseId }),
          onSuccess: () => {
            fetchSlides();
          },
        })
      );
    },
    [values, enterpriseId]
  );

  useEffect(() => {
    initialize({
      slides: data,
    });
  }, [data]);

  if (!hasImages) {
    return (
      <Reception
        image={image}
        title="Slides"
        text="Slide e uma galeria especial que privilegia as melhores fotos que apresentam o imovel/empreendimento."
      >
        <UploadSlide onUploadEnd={() => fetchSlides()}>
          Enviar Imagem
        </UploadSlide>
        <p className="h-margin-top--10">
          <em>Tamanho recomendado: 1920x1080px</em>
        </p>
        <p className="h-margin-top--5">
          <em>Tipo de arquivo: JPG, PNG, JPEG</em>
        </p>
      </Reception>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Slides"
        text="Crie slides em tela cheia com fotos e breve descrições sobre os imóveis."
      >
        <div className="h-flex">
          {hasImages && (
            <UploadSlide onUploadEnd={() => fetchSlides()}>
              Adicionar mais imagens
            </UploadSlide>
          )}
          <SeeExample />
        </div>
      </MainTitle>

      <Content isFetching={isFetching}>
        <FieldArray
          rerenderOnEveryChange
          name="slides"
          component={Images}
          handleRemoveImage={handleRemoveImage}
        />
      </Content>

      <FormFixedBar />
    </form>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
    onSubmit: (values, dispatch, props) => {
      return slidesService.update({ ...values, id: props.enterpriseId });
    },
  }),
  withFormConfirmation()
)(Slides);
