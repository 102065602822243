import { useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { reduxForm, setSubmitFailed, stopSubmit } from 'redux-form';

// Components
import { FieldBool } from 'components/Form';
import MainTitle from 'components/MainTitle';
import PropertyGallery from 'components/PropertyGallery';

// HOC
import { withId } from '../../HOC/withId';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Services
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import * as imagesAreaService from 'services/propertiesPartial/imagesArea';

// Styles
import Loading from 'components/Loading';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { finishResponse } from 'pages/PropertyStore/helpers';
import { useParams } from 'react-router';
import { Watermark } from './styles';
import Alert from 'components/Alert';

const ImagesArea = ({
  handleSubmit,
  initialize,
  onSubmit,
  submitting,
  permissions,
}) => {
  const { data, fetchData } = useRequest({
    request: imagesAreaService.getOne,
    initialFetch: false,
    initialState: {},
  });
  const { id } = useParams();

  const isLast = useMemo(() => {
    return !permissions?.includes('DYNAMIC_PUBLISH');
  }, [permissions]);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    initialize(data);
  }, [data]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Loading isVisible={submitting} isFullComponent />
        <MainTitle
          title="Imagens"
          text="Envie todas as imagens que mostrem as qualidades do imóvel."
        >
          <Watermark className="h-flex h-flex--center-center">
            <div>Aplicar marca d'água?</div>
            <FieldBool
              name="should_apply_watermark"
              style={{ marginBottom: 0 }}
              onChange={(value) => {
                imagesAreaService.update({
                  ...data,
                  should_apply_watermark: value,
                });
              }}
            />
          </Watermark>
        </MainTitle>
        {data.condominium_id && (
          <Alert color="danger">
            As imagens do condomínio/empreendimento serão incluidas
            automaticamente ao final da galeria do imóvel no seu site e portais
            conectados.
          </Alert>
        )}
        <FooterActions
          isLast={isLast}
          //submitText={isLast ? 'Finalizar' : 'Salvar e próximo'}
          submitText={'Salvar e próximo'}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
      </form>
      <PropertyGallery />
    </>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyImagesArea',
    onSubmit: (values, d, p) =>
      imagesAreaService.update(values).then(finishResponse(values, d, p)),
    onSubmitSuccess: (response, dispatch, p) => {
      p.initialize(response?.data);

      p.next('publish');

      //if (p?.permissions?.includes('DYNAMIC_PUBLISH')) {
      //  if (!p.isEditting) {
      //    p.next('publish');
      //  }
      //} else {
      //  p.history.push('/properties/search');
      //
      //  dispatch(
      //    openModalProperty({
      //      property: response?.data
      //    })
      //  );
      //}
    },
    onSubmitFail: (errors, dispatch, submit, props) => {
      if (!props?.permissions?.includes('DYNAMIC_PUBLISH')) {
        if (errors?.price || errors?.transaction) {
          props?.next('financial');
          setTimeout(() => {
            dispatch(stopSubmit('PropertyFinancial', { price: errors?.price }));
            dispatch(setSubmitFailed('PropertyFinancial', 'price'));
          }, 1000);
        }
      }
    },
  }),
  withFormConfirmation()
)(ImagesArea);
