import React, { useCallback, useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { MdHelp } from 'react-icons/md';
// Components
import Table from 'components/Table';
import TableItem from './TableItem';
// Services
import * as reportsService from 'services/reports';
// Modules
import { openModalPerson, openModalProperty } from 'modules/modal';
// Hooks
import { useRequest } from 'hooks/useRequest';

function TableLoss() {
  const formValues = useSelector(getFormValues('ChartPeriodForm'));
  const dispatch = useDispatch();

  const { data, fetchData } = useRequest({
    request: reportsService.getLossDeals,
    initialFetch: false
  });

  useEffect(() => {
    fetchData(formValues);
  }, [formValues]);

  useEffect(() => {
    Tooltip.rebuild();
  }, [data]);

  const handleClickPerson = useCallback(
    personId => () => {
      dispatch(openModalPerson({ id: personId }));
    },
    [dispatch]
  );

  const handleClickProperty = useCallback(
    propertyId => () => {
      dispatch(openModalProperty({ property: { id: propertyId } }));
    },
    [dispatch]
  );

  return (
    <Table
      noData={{
        title: 'Nenhum negócio perdido',
        text: 'Você não possui nenhum negócio perdido'
      }}
      isFetching={false}
      data={data}
      renderTitle={() => (
        <tr>
          <th>Perdido em</th>
          <th>Corretor</th>
          <th>Cliente</th>
          <th>Imóvel (Ref.)</th>
          <th>Proprietário</th>
          <th width={120} className="h-text-nowrap">
            Dias (abertura - fim){' '}
            <MdHelp
              className="h-color--primary"
              data-tip={`Quantidade de dias que se<br/> passaram desde a abertura<br/> até o fim do negócio`}
            />
          </th>
        </tr>
      )}
      renderItem={(data, i) => (
        <TableItem
          key={`deal-loss-${i}`}
          data={data}
          handleClickPerson={handleClickPerson}
          handleClickProperty={handleClickProperty}
        />
      )}
    />
  );
}

TableLoss.defaultProps = {};
TableLoss.propTypes = {};

export default TableLoss;
