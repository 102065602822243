import { TableData } from 'containers/ModalRoot/ModalPayment/styles';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import { formatter } from 'containers/ModalRoot/ModalPayment/utils/moneyFormatter';

const Package = () => {
  const { prices } = useModalPayment();

  return (
    <tr>
      <td className="item">
        <h3>Pacote Tecimob</h3>
        <p>Site completo com hospedagem</p>
        <p>CRM + Aplicativo + Plugin WhatsApp</p>
      </td>
      <td colSpan={2} />
      <TableData align="right">
        R$ {formatter.format(prices.subscription_price)}
      </TableData>
    </tr>
  );
};

export default Package;
