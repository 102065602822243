import React from 'react';
import { ButtonCustom } from '../../styles';
import { MdOutlineMood } from 'react-icons/md';
import { colors } from 'constants/styles';

function BtnGain({ onClick, block = true, ...props }) {
  return (
    <ButtonCustom
      onClick={onClick}
      block={block}
      size="medium"
      color="white"
      {...props}
    >
      <MdOutlineMood color={colors.success} /> Ganho
    </ButtonCustom>
  );
}

export default BtnGain;
