import React from 'react';
import { Field } from 'redux-form';
// Components
import Box from 'components/FilterBox';
import { InputDate } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import { Wrapper, Divider } from './styles';

export const FieldInterval = ({
  title,

  startDate,
  endDate,

  nameMin,
  nameMax,
  isClearable,
  onChange,
}) => {
  const start = useFormValue(nameMin);
  const end = useFormValue(nameMax);

  return (
    <Box title={title}>
      <Wrapper>
        <Field
          isClearable={isClearable}
          selectsStart
          startDate={startDate}
          endDate={endDate || end}
          formatDate="DD/MM/YYYY"
          name={nameMin}
          component={InputDate}
          onChange={onChange}
          placeholder="Depois de..."
        />
        <Divider />
        <Field
          isClearable={isClearable}
          selectsEnd
          startDate={startDate || start}
          endDate={endDate}
          formatDate="DD/MM/YYYY"
          name={nameMax}
          component={InputDate}
          onChange={onChange}
          placeholder="Antes de..."
        />
      </Wrapper>
    </Box>
  );
};

FieldInterval.defaultProps = {
  isClearable: true,
};

export default FieldInterval;
