import api from 'services';
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega a lista de
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = (params) =>
  api.getList('settings/sites/buildings', {
    ...params,
    sort: 'created_at',
  });

/**
 * Atualiza uma página de empreendimento
 */
export const update = ({ id, status }) =>
  api.patch(`settings/sites/buildings/${id}/status`, { status });

/**
 * Pega o buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getId = async () => {
  try {
    const {
      data: { id },
    } = await api.create('settings/sites/buildings');

    return id;
  } catch {
    return null;
  }
};

/**
 * Remove uma página
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const remove = (buildingId) =>
  api
    .delete(`settings/sites/buildings/${buildingId}`)
    .then(responseMessage('Página removida'));

export const createPagebyCondo = (condoId) =>
  api.create(`settings/sites/buildings/by-condo/${condoId}`);

export const clone = async ({ id, name }) => {
  const response = await api
    .create(`settings/sites/buildings/${id}/clone`)
    .then(responseMessage(`Página ${name} duplicada com sucesso`));

  return response;
};
