import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
// Common Components
import { ModalTemplate } from 'components/Modal';
import Loading from 'components/Loading';
import TableMonths from './TableMonths';
// Service
import * as debitsService from 'services/financial/debits';

function ModalDebitsMonth({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  monthName
}) {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  async function fetchData() {
    setIsFetching(true);

    try {
      const response = await debitsService.getExtract();
      setData(response.data);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title={`Extrato`} handleClose={handleClose}>
        <Loading isVisible={isFetching} position="center" isBlock />
        {!isFetching && <TableMonths data={data} />}
      </ModalTemplate>
    </Modal>
  );
}

export default ModalDebitsMonth;
