import PropTypes from 'prop-types';
import React from 'react';
import Alert from 'react-s-alert';
// Components
import ListOptions from 'components/ListOptions';
import { ModalFooter } from 'components/Modal';
import FieldPersonInfo from '../Fields/FieldPersonInfo';
import Form from '../Form';
//import FieldOpenDeal from "../Fields/FieldOpenDeal";
import FieldCalendar from '../Fields/FieldCalendar';
import FieldPrivate from '../Fields/FieldPrivate';
//import FieldMessage from "../Fields/FieldMessage";
import Button from 'components/Button';
// Validate
import useFormValue from 'hooks/useFormValue';
import validate from './validate';
import FieldAllStages from 'containers/FieldAllStages';
import { parseSelect } from 'lib/formHelpers';
import { permissionsSelector } from 'modules/login';
import { compose } from 'redux';
import { connect } from 'react-redux';

const propTypes = {
  type: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  onCreatePerson: PropTypes.func.isRequired,

  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  type: null,
  properties: null,
  handleClose: null,
  onCreatePerson: null,

  initialValues: null,
  onSubmit: null,
};

class FormShareEmail extends React.Component {
  onSubmitSuccess = ({ person }) => {
    const { openModalPerson } = this.props;
    Alert.success('Imóveis adicionados');
    openModalPerson(person, { initialPage: 'Properties' });
  };

  filterPerson = () => {
    const { permissions } = this.props;
    const currentUser = localStorage.getItem('current-user');

    if (!permissions.includes('OTHER_PERSON_DEAL'))
      return {
        user_id: currentUser?.id,
      };

    return {};
  };

  render() {
    const { type, onCreatePerson, handleClose, onSubmit, initialValues } =
      this.props;

    return (
      <Form
        onSubmit={onSubmit}
        onSubmitSuccess={this.onSubmitSuccess}
        initialValues={{
          ...initialValues,
          rate: 1,
        }}
        validate={validate}
      >
        {({ clearField, change }) => {
          const person = useFormValue('person');

          return (
            <>
              <ListOptions
                style={{
                  width: 'calc(100% + 34px)',
                  marginLeft: '-19px',
                  marginTop: '-20px',
                }}
              >
                <FieldPersonInfo
                  change={change}
                  type={type}
                  onCreatePerson={onCreatePerson}
                  onChange={() => {
                    change('stage_id', null);
                  }}
                  filter={this.filterPerson()}
                />
                <ListOptions.item
                  title="Qual etapa do CRM?"
                  renderOptions={() => (
                    <FieldAllStages
                      parse={parseSelect('stage_id')}
                      style={{ width: 200 }}
                    />
                  )}
                />
                {/*<FieldRate />*/}
                {/*{properties && properties.length <= 1 && (*/}
                {/*  <FieldOpenDeal clearField={clearField} />*/}
                {/*)}*/}
                <FieldCalendar clearField={clearField} />
                <FieldPrivate />
              </ListOptions>
              <ModalFooter withBorder>
                <Button
                  type="button"
                  color="white"
                  colorText="secondary"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
                <div className="h-flex__cell--grow" />
                <Button type="submit" color="success">
                  Adicionar ao CRM
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Form>
    );
  }
}

FormShareEmail.propTypes = propTypes;
FormShareEmail.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  permissions: permissionsSelector(state),
});

export default compose(connect(mapStateToProps))(FormShareEmail);
