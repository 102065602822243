import React, { useCallback, useEffect, useState } from 'react';
// Components
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import FormChangePlan from 'components/FormChangePlan';
import { useHistory, useParams } from 'react-router';

function PlanSelect() {
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({ users: 1, months: 3 });
  const params = useParams();

  const onSubmit = useCallback(
    ({ users, months }) => {
      history.push(`/plan/contract/${users}/${months}`);
    },
    [history]
  );

  useEffect(() => {
    const countUsers = Math.abs(parseInt(params?.users, 10));

    if (countUsers && countUsers > 0) {
      setInitialValues(prev => ({
        ...prev,
        users: countUsers
      }));
    }
  }, [params]);

  return (
    <Wrapper.inner>
      <Wrapper.container>
        <MainTitle
          title="Alterar seu plano"
          text="Adicione o número de corretores necessário para o seu negócio e escolha o prazo de pagamento."
        />
        <FormChangePlan
          form="FormSelectPlan"
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </Wrapper.container>
    </Wrapper.inner>
  );
}

export default PlanSelect;
