import api from 'services';
import { responseMessage } from 'lib/service-helpers';

export const getOne = params =>
  api.getList('settings/sites/footer-banners', params);

export const update = values =>
  api
    .update('settings/sites/footer-banners', values)
    .then(responseMessage('Alterado'));
