import { ModalTemplate } from 'components/Modal';
import msg from 'lib/msg';
import { getInvites } from 'modules/invites';
import { closeModal } from 'modules/modal';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import Alert from 'react-s-alert';
import * as usersService from 'services/user';
import InvitationForm from './components/InvitationForm';
import { whatsappSendUrl } from 'lib/share-helpers';

function ModalInviteUser({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  onSubmitSuccess,
}) {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    return usersService.createInvite(values).then(async ({ data }) => {
      Alert.success(msg.create(data), { timeout: 2000 });
      await getInvites()(dispatch);
      dispatch(closeModal());
      openWhatsappPage(values, data);
      return data;
    });
  }

  function openWhatsappPage(values, data) {
    const ddi = '55';
    const cellnumber = values?.cellphone;
    const cellPhone = cellnumber.replace(/\D/g, '');

    if (data?.cellphone) {
      window.open(
        whatsappSendUrl(
          cellPhone,
          data.message,
          ddi
        )
      );
    }
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Convidar Usuário" handleClose={handleClose}>
        <InvitationForm
          handleClose={handleClose}
          initialValues={{
            realtor: true,
          }}
          onSubmit={handleSubmit}
          onSubmitSuccess={onSubmitSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalInviteUser;
