import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import Alert from 'react-s-alert';

import * as user from '../../services/user';

import { Input } from '../../components/Form';
import Button from '../../components/Button';

import { resetPassword } from '../../modules/login';

import validate from './validate';

import FormTitle from '../../templates/Login/FormTitle';

const defaultProps = {};
const propTypes = {
  // handleSubmit: PropTypes.func.isRequired,
};

class RecoveryToken extends Component {
  componentDidMount() {
    const { history, match } = this.props;
    const { token, email } = match.params;

    if (token) {
      user
        .isValidResetToken({
          token,
          email
        })
        .catch(() => {
          Alert.error(
            'Algo deu errado por favor verifique seu e-mail ou tente recuperar a senha novamente',
            {
              position: 'bottom-left',
              effect: 'slide',
              timeout: 5000
            }
          );
          history.push('/');
        });
    }
  }
  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormTitle title="Cadastre sua nova senha abaixo" />
        <Row>
          <Field
            autoFocus
            xs={6}
            label="Nova Senha"
            type="password"
            name="password"
            placeholder="Digite sua nova senha"
            component={Input}
          />
          <Field
            xs={6}
            label="Confirmar senha"
            type="password"
            name="password_confirm"
            placeholder="Confirme a nova senha"
            component={Input}
          />
        </Row>
        <Button type="submit" block color="primary">
          Recuperar Senha
        </Button>
      </form>
    );
  }
}

RecoveryToken = reduxForm({
  form: 'recoveryToken',
  validate,
  onSubmit: (values, dispatch) => {
    return dispatch(resetPassword(values));
  },
  onSubmitSuccess: (values, dispatch, props) => {
    Alert.success('Senha alterada com sucesso', {
      position: 'bottom-left',
      effect: 'slide',
      html: false
    });
    props.history.push('/');
  }
})(RecoveryToken);

const mapStateToProps = (state, props) => {
  const { token, email } = props.match.params;

  return {
    login: state.login,
    initialValues: {
      email,
      token
    }
  };
};
RecoveryToken.defaultProps = defaultProps;
RecoveryToken.propTypes = propTypes;

export default withRouter(connect(mapStateToProps)(RecoveryToken));
