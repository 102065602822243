import { Header } from 'features/Financial/components/Header';
import { Financial } from 'features/Financial';
import { useHistory, useParams } from 'react-router';
import { useShowCreditCardBill } from '../../hooks/useCreditCard';
import { useEffect, useState } from 'react';
import moment, { format } from 'lib/moment';
import { BillInformations } from './components/BillInformations';
import { TableMoviments } from './components/TableMoviments';

export function DetailBill() {
  const history = useHistory();
  const { accountId, billId } = useParams();
  const { bill, billIsFetching, fetchBill } = useShowCreditCardBill();

  const [refreshKey, setRefreshKey] = useState(0);

  const changePage = (url) => {
    setRefreshKey((prevKey) => prevKey + 1);
    history.push(url);
  };

  const refreshPage = () => {
    fetchBill({ id: accountId, creditCardBill: billId });
  };

  useEffect(() => {
    fetchBill({ id: accountId, creditCardBill: billId });
  }, [accountId, billId, refreshKey]);

  const handleNext = () => {
    changePage(`/financial/credit-card/${accountId}/${bill.next_reference}`);
  };

  const handlePrev = () => {
    changePage(
      `/financial/credit-card/${accountId}/${bill.previous_reference}`
    );
  };

  return (
    <>
      <Header.Header>
        <Header.Title>Cartões</Header.Title>
        <Header.Actions>
          <Financial.AddPosting
            initialValues={{ account_id: accountId }}
            onSuccess={() => {
              changePage(`/financial/credit-card/${accountId}/${billId}`);
            }}
          />
        </Header.Actions>
      </Header.Header>
      <Financial.Content isFetching={billIsFetching}>
        <Financial.DateSelector
          isFetching={billIsFetching}
          initialValue={moment(bill.billing_date, format.date).format(
            'MM/YYYY'
          )}
          type="month"
          onNext={handleNext}
          onPrev={handlePrev}
        />
        <BillInformations bill={bill} />
        <TableMoviments
          moviments={bill?.moviments}
          isFetching={billIsFetching}
          onChange={refreshPage}
        />
      </Financial.Content>
    </>
  );
}
