import React from 'react';
import { Field, reduxForm } from 'redux-form';
// Components
import { FileInput } from 'components/Form';

const FormGalleryUpload = ({
  className,
  loading,
  maxFiles,
  handleSubmit,
  children,
}) => {
  return (
    <form onSubmit={handleSubmit} className={className}>
      <Field multiple name="files" maxFiles={maxFiles} component={FileInput}>
        {children}
      </Field>
    </form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
})(FormGalleryUpload);
