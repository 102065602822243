// Modules
import createDataReducer from 'modules/data';
// Service
import * as searchesService from 'services/sites/searches';

export const {
  actionCreators: searchActions,
  reducer: searchReducer,
  selectors: searchSelector
} = createDataReducer('site/search');

/**
 * Busca os campos da pesquisa
 * @param params
 * @return {Function}
 */
export const fetchFields = params => async dispatch => {
  dispatch(searchActions.request());
  const {
    data: { search_fields }
  } = await searchesService.getList(params);
  dispatch(searchActions.receive(search_fields));
};

/**
 * Inverte status do campo de pesquisa
 * @param field
 * @return {function|Promise}
 */
export const toggleStatusField = (field, device) => (dispatch, getState) => {
  // Inverte o status do campo
  const newField = { ...field, is_active: !field.is_active };

  // inverte o stautus do item na store
  dispatch(searchActions.alter(newField, null, 'name'));

  // Pega os campos que tem na store
  const search_fields = searchSelector.getAll(getState());

  // Faz o put que atualiza a lista de campos
  return searchesService
    .update({ search_fields, device })
    .then(({ data: { search_fields } }) => {
      dispatch(searchActions.receive(search_fields));
    });
};

/**
 * Atualiza o formulário de pesquisa
 * @return {Function}
 */
export const handleFormSubmit = values => dispatch =>
  searchesService.update(values);

export default searchReducer;
