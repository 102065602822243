import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import Store from './pages/Store';
import Send from './pages/Send';

const Routes = () => {
  return (
    <Switch>
      <Route
        path="/config/documents/rent-authorization/store"
        component={Store}
      />
      <Route
        path="/config/documents/rent-authorization/send"
        component={Send}
      />
      <Redirect to="/config/documents/rent-authorization/store" />
    </Switch>
  );
};

export default Routes;
