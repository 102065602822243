import React from 'react';
import { Field } from 'redux-form';
// Components
import { Input } from 'components/Form';

export default function SearchBy() {
  return (
    <Field
      label="Buscar por"
      name="by_people_search"
      placeholder="Nome, Telefone, E-mail"
      component={Input}
    />
  );
}
