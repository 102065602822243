import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IMAGE_SIZES } from '../../constants/config';
import { imageChangeSize } from '../../lib/image-helpers';
import noImage from 'assets/img/no-image.png';
// Assets

const propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large',
    'original',
    'opengraph',
    'facebook',
    'linkedin',
    'twitter',
    'instagram',
  ]),
  type: PropTypes.oneOf(['cover', 'contain', 'fill', 'inside', 'outside']),
  alt: PropTypes.string.isRequired,
  options: PropTypes.object,
};

const defaultProps = {
  src: null,
  size: 'medium',
  type: 'outside',
  alt: 'Image',
  options: {},
};

const Image = ({
  tagName: Component = 'img',
  src,
  alt,
  size,
  type,
  options,
  ...props
}) => {
  const [_src, setSrc] = useState(
    src ? imageChangeSize(src, size, type, options) : noImage
  );

  const handleError = useCallback(() => {
    setSrc(noImage);
  }, []);

  useEffect(() => {
    if (src) {
      setSrc(imageChangeSize(src, size, type, options));
    }
  }, [src, size, type, options]);

  return <Component {...props} src={_src} alt={alt} onError={handleError} />;
};

Image.sizes = IMAGE_SIZES;
Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
