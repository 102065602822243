import { createContext, useContext } from 'react';

export const ModalPropertySearchContext = createContext({
  handleUpdateCount: null,
});

export const ModalPropertyProvider = ({ handleUpdateCount, children }) => {
  return (
    <ModalPropertySearchContext.Provider
      value={{
        handleUpdateCount,
      }}
    >
      {children}
    </ModalPropertySearchContext.Provider>
  );
};

export const useModalPropertySearch = () =>
  useContext(ModalPropertySearchContext);
