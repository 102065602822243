import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
// Services
import * as characteristicsService from 'services/characteristics';
// Components
import { ModalTemplate } from 'components/Modal';
import CharacteristicsForm from './components/CharacteristicsForm';

function ModalCharacteristics({
  characteristicId,
  typeId,
  onSubmitSuccess,
  onSubmitFail,
  afterClose,
  modalConfig,
  isOpen,
  modalType,
  handleClose
}) {
  const [initialValues, setValues] = useState({});
  const [isFetching, setFetching] = useState(false);

  // Verifica se está em modo de edição a modal
  const isEditting = !!characteristicId;

  useEffect(() => {
    if (characteristicId) {
      setFetching(true);
      characteristicsService
        .getOne({ id: characteristicId })
        .then(({ data }) => {
          setValues({ ...data, type_id: data.type?.id });
        })
        .finally(() => {
          setFetching(false);
        });
    }
  }, []);

  function handleSubmit(values) {
    if (typeId) {
      return characteristicsService.update(typeId, values);
    }
    return characteristicsService.create(typeId, values);
  }

  function handleCloseModal() {
    if (afterClose) afterClose();
  }

  function handleSubmitSuccess(...res) {
    handleClose();
    if (onSubmitSuccess) onSubmitSuccess(...res);
    handleCloseModal();
  }

  function handleSubmitFail(...res) {
    if (onSubmitFail) onSubmitFail(...res);
    handleCloseModal();
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      className={{
        base: 'Modal Modal--small'
      }}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        isLoading={isFetching}
        title={
          isEditting ? 'Editar Característica' : 'Cadastrar Características'
        }
        handleClose={handleClose}
      >
        <CharacteristicsForm
          initialValues={initialValues}
          isEditting={isEditting}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFail}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}

ModalCharacteristics.defaultProps = {
  typeId: null
};

ModalCharacteristics.propTypes = {};

export default ModalCharacteristics;
