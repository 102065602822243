import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega as informações de site relacionadas ao rodapé
 * @param params
 * @return {Promise}
 */
export const getOne = params => api.getOne('settings/sites/maps', '', params);

/**
 * Editar a pagina de sobre
 * @param values
 * @return {*}
 */
export const update = ({ id, ...values }) =>
  api
    .update('settings/sites/maps', values)
    .then(responseMessage('Alterações efetuadas!'));
