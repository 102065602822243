import styled from "styled-components";

export const Confetti = styled.img`
  position: absolute;
`;

export const Split = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0 30px 0;
`;

export const Heading = styled.div`
  margin: 0 70px;
`;

export const Gift = styled.img`
  margin: 0 auto;
`;

export const Title = styled.p`
  margin-top: 50px;
  text-align: center;
  font-size: 18px;
  color: #3F597B;
  font-weight: bold;
  width: max-content;
`;

export const Bonus = styled.span`
  color: #01AFAD;
`;

export const Content = styled.div`
  margin-right: 50px;

  p {
    color: #3F597B;
    font-size: 16px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
`;

export const Highlight = styled.span`
  font-weight: bold;
`;

export const DisableTag = styled.span`
  position: absolute;
  bottom: 53px;
`;
