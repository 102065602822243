import React from 'react';
import * as PropTypes from 'prop-types';
import { useTableSort } from 'components/TableSort/index';
import SortIcon from '../SortIcon';
import { Th } from './styles';

function CollumHeader({ filterBy, children, ...rest }) {
  const { filter, setFilter, direction, setIsFiltering, setDirection } =
    useTableSort();

  const getDirection = () => {
    if (direction === null || filter !== filterBy) return 'asc';
    if (direction === 'asc') return 'desc';
    return null;
  };

  const handleClick = () => {
    const nextDirection = getDirection();
    setDirection(nextDirection);
    setFilter(filterBy);
    setIsFiltering(true);
  };

  return (
    <Th {...rest} onClick={handleClick}>
      {children} <SortIcon direction={filterBy === filter ? direction : null} />
    </Th>
  );
}

CollumHeader.propTypes = {
  filterBy: PropTypes.string.isRequired,
};

export default CollumHeader;
