import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Draggable from 'react-draggable';
import {
  MdFormatAlignCenter as CenterIcon,
  MdFormatAlignLeft as LeftIcon,
  MdFormatAlignRight as RightIcon,
} from 'react-icons/md';

/**
 *  value
    fontSize
    fontFamily
    fontWeight
    color
    isVisible
 */
export const renderTextItem = ({
  value,
  fontSize,
  fontFamily,
  fontWeight,
  color,
  isVisible,
}) => {
  // Retorna nulo caso não possa renderizar
  if (!isVisible) return null;

  return (
    <div
      className="LogoCreator__text"
      style={{ fontSize, fontFamily, fontWeight, color }}
    >
      {value}
    </div>
  );
};

export const Btn = ({ children, currentAlign, name, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    style={{ color: currentAlign === name ? '#0063C0' : '#000' }}
  >
    {children}
  </button>
);

class Logo extends React.Component {
  static defaultProps = {
    defaultImagePosition: PropTypes.arrayOf(PropTypes.string),
    defaultTextPosition: PropTypes.arrayOf(PropTypes.string),
    defaultAlignText: PropTypes.oneOf(['left', 'center', 'right']),
  };

  constructor(props) {
    super(props);

    this.state = {
      imagePosition: props.defaultImagePosition || [0, 20],
      textPosition: props.defaultTextPosition || [0, 50],
      alignText: props.defaultAlignText || 'center',
    };

    this.image = React.createRef();
    this.text = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { image: nextImage, texts: nextTexts } = this.props;
    const { image: prevImage, texts: prevTexts } = prevProps;

    if (nextImage !== prevImage) {
      this.centerImage();
    }

    if (nextTexts !== prevTexts) {
      this.centerText();
    }
  }

  onStopDragByKey = (key, { x, y }) => {
    this.setState({ [key]: [x, y] });
  };

  onStopDragImage = (e, data) => {
    this.onStopDragByKey('imagePosition', data);
  };

  onStopDragText = (e, data) => {
    this.onStopDragByKey('textPosition', data);
  };

  onChangeTextAlign = (align) => () => {
    this.setState({ alignText: align });
  };

  centerElementByKey = (key, ref) => {
    const { width } = this.props;
    const { clientWidth } = ref.current;
    const x = width / 2 - clientWidth / 2;
    this.setState({ [key]: [x, this.state[key][1]] });
  };

  centerImage = () => {
    this.centerElementByKey('imagePosition', this.image);
  };

  centerText = () => {
    this.centerElementByKey('textPosition', this.text);
  };

  // Retorna a posição da imagem
  get imagePosition() {
    const {
      imagePosition: [x, y],
    } = this.state;
    return { x, y };
  }

  get textPosition() {
    const {
      textPosition: [x, y],
    } = this.state;
    return { x, y };
  }

  get renderImage() {
    const { image } = this.props;

    return (
      <Draggable
        bounds="parent"
        position={this.imagePosition}
        onStop={this.onStopDragImage}
      >
        <div ref={this.image} className="LogoCreator__drag">
          <img
            src={image}
            alt=""
            className="LogoCreator__image"
            onLoad={(e) => {
              this.centerImage();
            }}
          />
        </div>
      </Draggable>
    );
  }

  get renderText() {
    return (
      <Draggable
        bounds="parent"
        position={this.textPosition}
        onStop={this.onStopDragText}
      >
        <div ref={this.text} className="LogoCreator__drag">
          {_.map(this.props.texts, renderTextItem)}
        </div>
      </Draggable>
    );
  }

  render() {
    const { alignText } = this.state;
    const { style, logoRef } = this.props;

    return (
      <>
        <div className="LogoCreator" style={{ width: this.props.width }}>
          <div
            className={classnames('LogoCreator__logo', {
              [`h-text-${alignText}`]: alignText,
            })}
            style={{
              ...style,
              width: this.props.width,
              height: this.props.height,
            }}
          >
            <div
              ref={logoRef}
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
              }}
            >
              {this.renderImage}
              {this.renderText}
            </div>
          </div>
          <div className="LogoCreator__alert">
            Você pode clicar e arrastar os elementos
          </div>
          <div className="LogoCreator__actions">
            <Btn
              name="left"
              currentAlign={this.state.alignText}
              onClick={this.onChangeTextAlign('left')}
            >
              <LeftIcon size={18} />
            </Btn>
            <Btn
              name="center"
              currentAlign={this.state.alignText}
              onClick={this.onChangeTextAlign('center')}
            >
              <CenterIcon size={18} />
            </Btn>
            <Btn
              name="right"
              currentAlign={this.state.alignText}
              onClick={this.onChangeTextAlign('right')}
            >
              <RightIcon size={18} />
            </Btn>
          </div>
        </div>
      </>
    );
  }
}

export default Logo;
