import React, { useMemo } from 'react';
import Button from 'components/Button';
import { MdHome } from 'react-icons/md';
import { Wrapper } from './styles';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useSelector } from 'react-redux';
import { personSelector } from 'modules/modalPerson';
import FiltersHistoryContainer from 'containers/FiltersHistoryContainer';

function Header({ onChangeFilter }) {
  const currentUser = useCurrentUser();
  const person = useSelector(personSelector);

  const clientUrl = useMemo(() => {
    return `${currentUser?.real_estate?.primary_domain?.url}/cliente/${person.id}`;
  }, [currentUser]);

  return (
    <Wrapper>
      <FiltersHistoryContainer
        logeable_id={person?.id}
        onChange={onChangeFilter}
      />
      <Button
        tagName={'a'}
        href={clientUrl}
        target="_blank"
        color="white"
        size="medium"
      >
        <MdHome style={{ margin: '-2px 3px 0 0' }} />
        Ver página do cliente
      </Button>
    </Wrapper>
  );
}

export default Header;
