import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import forEach from 'lodash/forEach';
import { connect } from 'react-redux';
// Modules
import { permissionsSelector } from 'modules/login';

export const withPermission = ({
  rules,
  isPage,
  redirectTo = '/no-permission',
  action
}) => Component => {
  let redirectUrl = redirectTo;

  if (action) {
    redirectUrl += `/${action}`;
  }

  class Permission extends React.Component {
    state = {
      permissions: null,
      canRender: false
    };

    get hasFalsePermission() {
      const { permissions } = this.state;

      let hasFalse = false;

      forEach(permissions, permission => {
        if (!permission) hasFalse = true;
      });

      return hasFalse;
    }

    setPermissionByName(rule, status) {
      const { permissions } = this.state;
      this.setState({ permissions: { ...permissions, [rule]: status } });
    }

    /**
     * Verifica se o usuário pode fazer essa tarefa
     * @param rule
     */
    canDo(rule) {
      const { roles } = this.props;

      if (roles.includes(rule)) {
        // Seta que tem permissão
        return this.setPermissionByName(rule, true);
      }

      // Seta que não tem permissão
      return this.setPermissionByName(rule, false);
    }

    /**
     * Verifica todas as regras passadas por parametro
     */
    checkRules = async () => {
      if (!rules) throw new Error('Voce deve adicionar pelo menos uma regra');

      rules.forEach(rule => {
        this.canDo(rule);
      });
    };

    async componentDidMount() {
      const {
        history: { replace }
      } = this.props;
      await this.checkRules();

      if (this.hasFalsePermission) {
        if (isPage) replace(redirectUrl);
      }

      this.setState({ canRender: true });
    }

    render() {
      const { permissions, canRender } = this.state;

      if (!canRender || (!permissions && this.hasFalsePermission)) return null;

      return <Component {...this.props} permissions={permissions} />;
    }
  }

  const mapStateToProps = state => ({
    roles: permissionsSelector(state)
  });

  //return connect(mapStateToProps)(Permission);
  return compose(withRouter, connect(mapStateToProps))(Permission);
};
