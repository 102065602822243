import Button from 'components/Button';
import { Input } from 'components/Form';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import Alert from 'react-s-alert';
import { Field, reduxForm } from 'redux-form';
import * as superlogicaService from 'services/integrations/superlogica';
import validate from './validate';

function ModalForm({
  handleSubmit,
  modalConfig,
  isOpen,
  modalType,
  handleClose,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
      style={{
        content: {
          maxWidth: 440,
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate
          title="Integração com Superlógica"
          handleClose={handleClose}
        >
          <p>
            Para ativar a integração é necessário gerar os dados de acesso.
            <br />
            Acesse o tutorial com passo a passo{' '}
            <a
              href="https://assinaturas.superlogica.com/hc/pt-br/articles/360004013613-Criar-app-e-access-token"
              target="_blank"
              className="h-link"
            >
              clicando aqui
            </a>
          </p>
          <Row className="h-margin-top--15">
            <Field
              required
              xs={12}
              label={`Informe seu "app_token"`}
              name="app_token"
              component={Input}
            />
            <Field
              required
              xs={12}
              label={`Informe seu "secret"`}
              name="secret"
              component={Input}
            />
            <Field
              required
              xs={12}
              label={`Informe seu "access_token"`}
              name="access_token"
              component={Input}
            />
          </Row>
          <ModalFooter>
            <Button type="button" onClick={handleClose} color="white">
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              Conectar
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </form>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalSuperlogica',
  initialValues: {},
  onSubmit: (values) => {
    return superlogicaService.connect(values);
  },
  onSubmitSuccess: (response, dispatch, props) => {
    Alert.success('Superlógica conectado');
    props.handleClose();
    if (props.onSuccess) props.onSuccess(response, dispatch, props);
  },
  validate,
})(ModalForm);
