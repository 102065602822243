import React from 'react';
import PropTypes from 'prop-types';
import { PROPERTY_SHAPE } from '../../constants/shapes';

import CardVertical from './components/CardVertical';

const defaultProps = {
  type: 'vertical',
  handleClickProperty: null,
  handleClickFeatured: null,
  handleClickChecked: null
};

const propTypes = {
  type: PropTypes.oneOf(['vertical', 'horizontal']),
  property: PROPERTY_SHAPE.isRequired,
  handleClickProperty: PropTypes.func,
  handleClickFeatured: PropTypes.func,
  handleClickChecked: PropTypes.func
};

const CardProperty = props => <CardVertical {...props} />;

CardProperty.defaultProps = defaultProps;
CardProperty.propTypes = propTypes;

export default CardProperty;
