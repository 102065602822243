import React, { useEffect, useState } from 'react';
import { SmallText, Table, Wrapper } from './styles';
import Number from './Number';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { checkPropertyDescription } from 'services/properties';
import TextLoading from 'components/TextLoading';

function ValidationPortals({ portals, description, condoDescription }) {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [debouncedString, setDebouncedString] = useState('');

  const getInfoById = (id) => {
    return data?.find((d) => d.id === id);
  };

  const fetchDescription = async () => {
    if (!portals || portals?.length <= 0) return null;

    const res = await checkPropertyDescription(description, condoDescription);
    setData(res.data);
    setIsFetching(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // Realiza a solicitação usando o Axios
      if (debouncedString) {
        fetchDescription();
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [debouncedString, condoDescription]);

  useEffect(() => {
    setDebouncedString(description);
    setIsFetching(true);
  }, [description]);

  if (!portals || portals?.length <= 0) return null;

  return (
    <Wrapper>
      <header>
        <h2>Validação de total de caracteres por portal</h2>
        <p>
          Alguns portais não publicam o imóvel caso exceda o máximo de
          caracteres na descrição
        </p>
      </header>
      <Table>
        <thead>
          <tr>
            <th>Portal</th>
            <th>Imóvel</th>
            <th>Condomínio</th>
            <th>Total</th>
            <th>Limite</th>
            <th>Situação</th>
          </tr>
        </thead>
        <tbody>
          {portals.map(({ id, file_url, max }) => {
            const info = getInfoById(id);
            const total = info?.total + info?.condo_total;
            const isApproved = total <= max;

            return (
              <tr key={'portal-' + id}>
                <td>
                  <img src={file_url} alt="" />
                </td>
                <td>
                  <Number isFetching={isFetching}>{info?.total}</Number>
                  <SmallText>caracteres</SmallText>
                </td>
                <td>
                  <Number isFetching={isFetching}>{info?.condo_total}</Number>
                  <SmallText>caracteres</SmallText>
                </td>
                <td>
                  <Number isFetching={isFetching}>
                    <strong>{total}</strong>
                  </Number>
                  <SmallText>caracteres</SmallText>
                </td>
                <td>
                  <Number isFetching={isFetching}>
                    <strong>{max}</strong>
                  </Number>
                  <SmallText>caracteres</SmallText>
                </td>
                <td>
                  {isFetching ? (
                    <TextLoading width={90} height={14} />
                  ) : isApproved ? (
                    <>
                      <MdCheckCircle className="h-color--success" /> Aprovado
                    </>
                  ) : (
                    <>
                      <MdCancel className="h-color--danger" /> Reprovado
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Wrapper>
  );
}

export default ValidationPortals;
