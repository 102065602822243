import React from 'react';
import { Wrapper, Image, Content, Title, Number } from './styles';
import TextLoading from 'components/TextLoading';

function Item({ title, value, image, isLoading }) {
  if (isLoading) {
    return (
      <Wrapper>
        <Content>
          <Title>
            <TextLoading width={100} height={13} />
          </Title>
          <Number>
            <TextLoading width={30} height={25} />
          </Number>
        </Content>
        <TextLoading width={30} height={25} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Content>
        <Title>{title}</Title>
        <Number>{value}</Number>
      </Content>
      <Image src={image} />
    </Wrapper>
  );
}

export default Item;
