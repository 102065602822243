import { useRequest } from 'hooks/useRequest';
import { changeUrlObj } from 'lib/url-router-helpers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as portalsService from 'services/settings/portals';

export const usePortalSelector = (formValuesSelector) => {
  const history = useHistory();
  const { data: portals, fetchData: fetchPortals } = useRequest({
    request: portalsService.getListConnectedPortalsSelect,
  });

  const portal_id = useSelector((state) => {
    try {
      const { portal_id } = formValuesSelector(state, 'portal_id');
      return portal_id;
    } catch {
      return null;
    }
  });

  const portal_option_id = useSelector((state) => {
    try {
      const { by_portal_option_id } = formValuesSelector(
        state,
        'by_portal_option_id'
      );
      return by_portal_option_id;
    } catch {
      return null;
    }
  });

  // Retorna o portal que foi selecionado no select do filtro
  const selectedPortal = useMemo(() => {
    return portals.find((portal) => portal.id === portal_id);
  }, [portal_id, portals]);

  const selectedOption = useMemo(() => {
    return selectedPortal?.options?.find(
      (option) => option.portal_option_id === portal_option_id
    );
  }, [selectedPortal, portal_option_id]);

  const handleFetchPortals = () => {
    fetchPortals();

    changeUrlObj({
      history,
      offset: 1,
      limit: 50,
    });
  };

  return {
    selectedOption,
    selectedPortal,
    portals,
    fetchPortals: handleFetchPortals,
  };
};
