import Button from 'components/Button';
import { FieldBool, Input } from 'components/Form';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import Alert from 'react-s-alert';
import { Field, reduxForm } from 'redux-form';
import * as plenoIntegration from 'services/integrations/pleno';
import validate from './validate';

function ModalForm({
  handleSubmit,
  modalConfig,
  isOpen,
  modalType,
  handleClose,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
      style={{
        content: {
          maxWidth: 440,
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate
          title="Integração com Pleno Imob"
          handleClose={handleClose}
        >
          <Row>
            <Field
              required
              xs={6}
              label="E-mail"
              name="email"
              component={Input}
            />
            <Field
              required
              xs={6}
              type="password"
              label="Senha"
              name="password"
              component={Input}
            />
          </Row>
          <Row>
            <Field
              required
              xs={12}
              label="Informe seu domínio Pleno Imob"
              name="domain"
              component={Input}
            />
          </Row>
          <Row>
            <FieldBool
              canRemoveSelf={false}
              xs={6}
              name="is_schedule_shown"
              label={`Ativar botão "Agendar visita"?`}
            />
            <FieldBool
              canRemoveSelf={false}
              xs={6}
              name="is_proposal_shown"
              label={`Ativar botão "Enviar Proposta"?`}
            />
          </Row>
          <ModalFooter>
            <Button type="button" onClick={handleClose} color="white">
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              Conectar
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </form>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalPleno',
  initialValues: {
    is_schedule_shown: true,
    is_proposal_shown: true,
  },
  onSubmit: (values) => {
    return plenoIntegration.connect(values);
  },
  onSubmitSuccess: (response, dispatch, props) => {
    Alert.success('Pleno imob conectado');
    props.handleClose();
  },
  onSubmitFail: (errors) => {
    errors.forEach((error) => {
      if (typeof error !== 'string') return;
      Alert.error(error);
    });
  },
  validate,
})(ModalForm);
