import Button from 'components/Button';
import Popover from 'components/Popover';
import useFormValue from 'hooks/useFormValue';
import FormTest from './FormTest';
// import { Wrapper } from './styles';

function SendTest({ setIsLoading, onSuccess }) {
  const values = useFormValue([
    'host',
    'port',
    'encryption',
    'username',
    'password',
    'from_name',
    'from_address',
  ]);

  return (
    <Popover
      initialValues={values}
      onSubmitSuccess={onSuccess}
      component={FormTest}
      setIsLoading={setIsLoading}
    >
      <Button type="button" color="secondary" style={{ margin: '0 10px' }}>
        Enviar e-mail teste
      </Button>
    </Popover>
  );
}

export default SendTest;
