import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  getStories: PropTypes.func.isRequired
};

const defaultProps = {};

class Emails extends React.Component {
  render() {
    return <div></div>;
  }
}

Emails.propTypes = propTypes;
Emails.defaultProps = defaultProps;

export default Emails;
