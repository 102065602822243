import React from 'react';
// Components
import Nodata from 'components/Nodata';
import ListProfiles from '../../components/ListProfiles';
// Assets
import search from 'assets/img/noData/search.svg';

const ProfileRemoved = ({ profiles, buttonCreateProfile }) => {
  if (!profiles.length)
    return (
      <Nodata
        style={{ padding: '20px 0', maxWidth: 330 }}
        image={search}
        title="Nenhum perfil de busca removido."
        text="Quando você remover um perfil de busca do seu cliente, ele aparecerá aqui"
      />
    );

  return (
    <ListProfiles
      profiles={profiles}
      buttonCreateProfile={buttonCreateProfile}
    />
  );
};

export default ProfileRemoved;
