import React from 'react';
import { Btn, Icon, Overlay, Text, Wrapper } from './styles';
import { ThemeProvider } from 'styled-components';
import { MdCheckCircle } from 'react-icons/md';
import { colors } from 'constants/styles';
import { useCurrentUser } from 'hooks/useCurrentUser';

function CardTemplate({ data, onApply }) {
  const currentUser = useCurrentUser();
  return (
    <ThemeProvider
      theme={{
        isSelected: data.is_selected,
      }}
    >
      <Wrapper>
        <img src={data.image} alt="" />
        {data.is_selected ? (
          <Overlay>
            <div>
              <Text>
                <Icon>
                  <MdCheckCircle size={16} color={colors.success} />
                </Icon>{' '}
                Modelo selecionado
              </Text>
              <Btn
                className="Btn--center"
                as="a"
                href={currentUser?.primary_domain_url}
                target="_blank"
              >
                Ver site
              </Btn>
            </div>
          </Overlay>
        ) : (
          <Overlay>
            <div>
              <Btn as="a" href={data.url} target="_blank">
                Ver modelo
              </Btn>
              <Btn
                onClick={() => {
                  if (onApply) onApply(data);
                }}
              >
                Aplicar
              </Btn>
            </div>
          </Overlay>
        )}
      </Wrapper>
    </ThemeProvider>
  );
}

export default CardTemplate;
