import {
  BarItemSelecteds,
  Number,
} from 'pages/PropertiesSearch/components/FixedBarProperties/styles';
import { plural } from 'lib/text';

export default function Selecteds({ properties }) {
  return (
    <BarItemSelecteds>
      <span>
        {plural(
          properties?.length,
          'imóvel selecionado',
          'imóveis selecionados',
          false
        )}
        <Number>{properties?.length.toString().padStart(2, '0')}</Number>
      </span>
    </BarItemSelecteds>
  );
}
