import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  background-color: #ccc;
  width: 100%;
  height: 100%;
  overflow: auto;
  color: #212121;

  @media print {
    padding: 0;
  }
`;

export const PaperSheet = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px;
  width: 21cm;
  min-height: 29.7cm;
  margin: 0 auto;
  background-color: #fff;
  color: #000;

  p,
  span,
  strong,
  a {
    color: #000 !important;
  }

  @media print {
    position: relative;
    padding: 0 0 10px;
    left: 0;
    width: 100%;
    min-height: unset;
    height: auto;
    top: 0;
  }
`;

export const ButtonPrint = styled.button`
  padding: 15px 25px;
  font-size: 18px;
  border: none;
  background-color: #49aee8;
  color: #fff;
  border-radius: 100px;
  z-index: 10;
  outline: none;
  box-shadow: 1px 1px 5px rgb(0, 0, 0, 0, 15);

  position: fixed;
  bottom: 50px;
  right: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.9;
  transition: opacity 0.2s;

  & svg {
    font-size: 18px;
    margin-right: 5px;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  @media print {
    display: none;
  }
`;
