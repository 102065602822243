import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { reduxForm } from 'redux-form';
import * as characteristicsService from 'services/characteristics';

function ModalCharacteristicRemove({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  characteristic,
  initialize,
  handleSubmit,
}) {
  useEffect(() => {
    initialize(characteristic);
  }, [characteristic]);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate
          title={`Remover Característica - ${characteristic.title}`}
          handleClose={handleClose}
        >
          <p>Tem certeza que deseja remover essa característica?</p>
          {characteristic.properties_count > 0 && (
            <p>Esta característica será removida de todos os imóveis</p>
          )}

          <ModalFooter>
            <Button onClick={handleClose} color="white">
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="danger">
              Remover
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </form>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalCharacteristicRemove',
  onSubmit: async (values, dispatch, props) => {
    // Se existir pessoas
    // tem que transferir o grupo pra outro lugar
    if (values?.to_id) {
      await characteristicsService.transfer(values?.id, values?.to_id);
    }

    return characteristicsService.remove(values.id).then((res) => {
      if (props.onSubmitSuccess) props.onSubmitSuccess(res, dispatch, props);
      props.handleClose();
    });
  },
})(ModalCharacteristicRemove);
