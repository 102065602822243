// import styles from './styles'

import { Field } from 'redux-form';
import { Input } from 'components/Form';
import React from 'react';

// Função parse que remove espaços
const removeSpaces = (value) => {
  if (!value) return value;
  return value.replace(/\s+/g, '');
};

export function Reference({
  name,
  label = 'Referência do novo imóvel 1',
  ...props
}) {
  return (
    <Field
      name={name}
      label="Referência do novo imóvel"
      maxLength={19}
      component={Input}
      parse={removeSpaces}
      {...props}
    />
  );
}
