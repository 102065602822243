import React from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import {
  GoogleMap,
  StreetViewPanorama,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';

const Streetview = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}`,
    loadingElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
    defaultOptions: {
      clickToGo: true,
      disableDefaultUI: true,
      fullscreenControl: false,
      enableCloseButton: false,
      addressControl: false,
      linksControl: false,
      motionTracking: false,
      panControl: false,
    },
  }),
  withHandlers(() => {
    const refs = {
      map: undefined,
      streetview: undefined,
    };

    let timeout;

    return {
      onMapMounted: () => (ref) => {
        if (ref) {
          refs.map = ref;
          refs.streetview = ref?.getStreetView();
        }
      },
      handleChangePosition:
        ({ handleChangePosition }) =>
        () => {
          const position = refs?.streetview?.getPosition();
          if (handleChangePosition) handleChangePosition(position);
        },
      handleChangePov:
        ({ handleChangePov }) =>
        () => {
          const pov = refs?.streetview?.getPov();
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            const position = refs?.streetview?.getPosition();
            if (handleChangePov) handleChangePov(pov, position);
          }, 500);
        },
    };
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      ref={props?.onMapMounted}
      defaultCenter={props?.position}
      defaultZoom={10}
      options={{
        ...props?.options,
        disableDefaultUI: true,
      }}
    >
      <StreetViewPanorama
        {...props}
        visible
        position={props?.position}
        pov={props?.pov}
        onPovChanged={props?.handleChangePov}
        // onPositionChanged={props?.handleChangePosition}
      />
    </GoogleMap>
  );
});

export default Streetview;
