import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
// Components
import Button from 'components/Button';
import Loading from 'components/Loading';
import Menu from 'components/Menu';
import ModalSubtitle from 'components/Modal/components/ModalSubtitle';
// Containers
import Can from 'containers/Can';
// Modules
import { closeModal, openModalProfileRemove } from 'modules/modal';
import {
  deleteProfile,
  fetchProfiles,
  isFetchingProfilesSelector,
  profilesSelector,
} from './module';
// Pages
import pages from './pages';
// HOC
// Helpers
import { plural } from 'lib/text';
// Styles
import { PERSON_PERMISSIONS } from 'constants/rules';
import RequestPermission from 'containers/ModalRoot/ModalPerson/components/RequestPermission';

const ButtonCreateProfile = ({ person, ...props }) => (
  <Button
    {...props}
    to={`/profile/create?formData[people_id]=${person.id}`}
    color="secondary"
  >
    Criar perfil
  </Button>
);

class SearchProfile extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    currentPage: 'ProfileActive',
  };

  /**
   * Lida com o click para não mostrar novamente a mensagem
   * @param e
   */
  handleClickDontShowAgain = (e) => {
    const { history, handleClickAction, closeModal } = this.props;

    // Vai para a pagina de busca de imóvel
    history.push('/properties/search');

    // fecha todas as modais que podem estar abertas
    closeModal();

    // chama o evento que foi criado no HOC withReception
    // lida com o localStorage e sessionStorage
    handleClickAction(e);
  };

  requestProfile = (params) =>
    this.props.fetchProfiles(this.props.person, params);

  get canFetch() {
    const {
      modalPerson: { person },
    } = this.props;

    return person.permissions.includes(PERSON_PERMISSIONS.SEARCH_PROFILES);
  }

  fetchActiveProfiles = (params) => {
    if (!this.canFetch) return false;

    return this.requestProfile({
      ...params,
      filter: { by_actives: true },
      include: 'addresses',
    });
  };

  fetchDeletedsProfiles = (params) =>
    this.requestProfile({
      ...params,
      filter: { by_deleteds: true },
      include: 'addresses',
    });

  componentDidMount() {
    this.fetchActiveProfiles();
  }

  handleDeleteProfile = (profile) => {
    this.props.openModalProfileRemove({
      profile,
      onSubmit: ({ can_remove_properties }) => {
        return this.props.deleteProfile(profile, can_remove_properties);
      },
      onSubmitSuccess: () => {
        this.props.reopenModal({
          initialPage: 'SearchProfile',
        });
      },
    });
  };

  handleChangePage = (pageName) => {
    if (pageName === 'ProfileActive') {
      this.fetchActiveProfiles();
    } else {
      this.fetchDeletedsProfiles();
    }

    this.setState({ currentPage: pageName });
  };

  get person() {
    return this.props.modalPerson.person;
  }

  get title() {
    const { profiles } = this.props;
    const { currentPage } = this.state;
    switch (currentPage) {
      case 'ProfileActive':
        return plural(profiles.length, 'Perfil ativo', 'Perfil ativos');
      case 'ProfileRemoved':
      default:
        return plural(profiles.length, 'Perfil removido', 'Perfil removidos');
    }
  }

  render() {
    const { profiles, isFetching, closeModal } = this.props;

    return (
      <Can
        run={PERSON_PERMISSIONS.SEARCH_PROFILES}
        permissions={this.person.permissions}
        no={() => <RequestPermission person={this.person} />}
        yes={() => {
          const { currentPage } = this.state;

          const buttonCreateProfile = () => (
            <ButtonCreateProfile person={this.person} onClick={closeModal} />
          );

          return (
            <div>
              <Menu
                modal
                currentPage={currentPage}
                handleClickItem={this.handleChangePage}
                style={{ marginBottom: 20 }}
              >
                <Menu.item component="ProfileActive">ATIVOS</Menu.item>
                <Menu.item component="ProfileRemoved">REMOVIDOS</Menu.item>
              </Menu>
              {!!profiles.length && (
                <ModalSubtitle
                  title={this.title}
                  actionRenderer={() => buttonCreateProfile()}
                />
              )}
              {isFetching ? (
                <Loading isVisible />
              ) : (
                React.createElement(
                  pages[currentPage],
                  {
                    profiles,
                    person: this.person,
                    requestProfile: this.requestProfile,
                    handleDeleteProfile: this.handleDeleteProfile,
                    closeModal: this.props.closeModal,
                    buttonCreateProfile,
                  },
                  null
                )
              )}
            </div>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  profiles: profilesSelector(state),
  isFetching: isFetchingProfilesSelector(state),
});

const mapDispatchToProps = {
  fetchProfiles,
  deleteProfile,
  openModalProfileRemove,
  closeModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SearchProfile);
