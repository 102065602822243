import classnames from 'classnames';
import PropTypes from 'prop-types';
//import { MdPlayCircleFilled } from "react-icons/md";
import getVideoId from 'get-video-id';
import youtubeIcon from 'components/BoxHelp/youtube-fill.png';
import React from 'react';

function YoutubeImage({ videoUrl, alt, quality, style, onClick }) {
  const VIDEO_ID = getVideoId(videoUrl).id;
  const url = `https://img.youtube.com/vi/${VIDEO_ID}/${quality}.jpg`;

  return (
    <div
      className={classnames('YoutubeImage', {
        'h-pointer': onClick,
      })}
      onClick={onClick}
    >
      <img src={url} alt={alt} style={style} className="YoutubeImage__image" />
      {onClick && (
        <img src={youtubeIcon} alt="" className="YoutubeImage__icon" />
      )}
    </div>
  );
}

YoutubeImage.propTypes = {
  videoUrl: PropTypes.string,
  quality: PropTypes.oneOf([
    'sddefault',
    'mqdefault',
    'hqdefault',
    'maxresdefault',
  ]).isRequired,
  alt: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

YoutubeImage.defaultProps = {
  videoUrl: null,
  quality: 'maxresdefault',
  alt: 'Youtube vídeo cover image',
  style: null,
  onClick: null,
};

export default YoutubeImage;
