import Pagination from 'components/Pagination';
import PropertiesTable from 'components/PropertiesTable';
import {
  openModalProperty,
  openModalPropertyCompare,
  openModalShareProperty,
} from 'modules/modal';
import {
  addLastPropertyVisited,
  selectedPropertiesSelector,
  toggleSelected,
  typeListSelector,
} from 'modules/propertySearch';
import ChangePrice from 'pages/Properties/ChangePrice';
import ChangePublish from 'pages/Properties/ChangePublish';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FixedBarProperties from '../FixedBarProperties';
import Header from '../Header';
import PropertiesList from '../PropertiesList';
import { Actions as SelectedsActions } from 'modules/selecteds';

function Properties({
  handleSearch,
  data,
  meta,
  params,
  fixedBar: FixedBar,
  barProps,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const typeList = useSelector(typeListSelector);
  const selectedProperties = useSelector(selectedPropertiesSelector);
  const [multiplePropertyEditting, setMultiplePropertyEditting] =
    useState(false);
  const [multiplePropertyPublish, setMultiplePropertyPublish] = useState(false);

  const unsetSelected = (propertyId) => {
    dispatch(SelectedsActions.unsetSelected('properties_search', propertyId));
  };

  const resetSelecteds = () => {
    dispatch(SelectedsActions.resetSelecteds('properties_search'));
  };

  const handlePageChange = ({ selected }) => {
    let currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.set('offset', selected + 1);
    history.push({
      search: currentSearchParams.toString(),
    });
  };

  const handleClickProperty = (property) => {
    dispatch(openModalProperty({ property }));
    dispatch(addLastPropertyVisited(property));
  };

  const toggleSelectedProperty = (id) => {
    dispatch(toggleSelected(id));
  };

  const handleToggleEditting = () => {
    setMultiplePropertyEditting((value) => !value);
  };

  const handleTogglePublish = () => {
    setMultiplePropertyPublish((value) => !value);
  };

  const handleClickCompare = () => {
    dispatch(
      openModalPropertyCompare({
        handleRemove: (id) => {
          unsetSelected(id);
        },
        propertiesIds: selectedProperties,
      })
    );
  };

  if (multiplePropertyEditting) {
    return (
      <ChangePrice
        properties={[...selectedProperties, 'to_edit']}
        handleToggleVisibility={handleToggleEditting}
      />
    );
  }

  if (multiplePropertyPublish) {
    return (
      <ChangePublish
        properties={[...selectedProperties, 'to_edit']}
        handleToggle={handleTogglePublish}
      />
    );
  }

  const fixedBarProps = {
    params: params,
    unsetSelected: unsetSelected,
    resetSelecteds: resetSelecteds,
    properties: selectedProperties,
    handleToggleEditting: handleToggleEditting,
    handleTogglePublish: handleTogglePublish,
    handleSearch: handleSearch,
    handleClickRemoveSelected: (property) => {
      unsetSelected(property.id);
    },
    handleUncheckAll: () => {
      resetSelecteds();
    },
    handleClickSendProperties: (type, title, props) => () => {
      dispatch(
        openModalShareProperty({
          title,
          type,
          ...props,
          properties: selectedProperties,
        })
      );
    },
    handleClickCompare: handleClickCompare,
    ...barProps,
  };

  return (
    <>
      <Header params={params} meta={meta} />
      {typeList === 'card' ? (
        <PropertiesList
          type="vertical"
          properties={data}
          filter={params?.filter}
          onClickProperty={handleClickProperty}
          selectedProperties={selectedProperties}
          toggleSelectedProperty={toggleSelectedProperty}
          rowClassName="propertyList"
        />
      ) : (
        <PropertiesTable
          hasCheckbox
          properties={data}
          onClickProperty={handleClickProperty}
          selectedProperties={selectedProperties}
          toggleSelectedProperty={toggleSelectedProperty}
        />
      )}
      {params?.offset && (
        <Pagination
          forcePage={params?.offset - 1}
          pageCount={meta?.pagination?.total_pages}
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
        />
      )}
      {FixedBar ? (
        <FixedBar {...fixedBarProps} />
      ) : (
        <FixedBarProperties {...fixedBarProps} />
      )}
    </>
  );
}

export default Properties;
