// Widgets.js
import { combineReducers } from 'redux';
// Helpers
import { createModule } from '../../../lib/reducer-helpers';
// Modules
import dataReducer from '../../../modules/data';
// import { getInitialData } from '../../../pages/Crm/reducer';
// Services
import * as dealsService from '../../../services/deals';
import * as userService from '../../../services/user';
import * as peopleService from '../../../services/people';
import * as auditsService from '../../../services/audits';

const MODULE_NAME = 'crm/modalDeal';

const createAction = createModule(MODULE_NAME);

const REQUEST_INFOS = createAction('REQUEST_INFOS');
const RECEIVE_INFOS = createAction('RECEIVE_INFOS');
const INTEREST_SET = createAction('INTEREST_SET');
const RESET_INFOS = createAction('RESET_INFOS');

export const infosSelector = (state) => state.modalDeal.infos.data;
export const eventsSelector = (state) => state.modalDeal.events.data;
export const interestSelector = (state) => state.modalDeal.interest;
export const infosFetchingSelector = (state) =>
  state.modalDeal.infos.meta.isFetching;
export const funnelsSelector = (state) => state.modalDeal.funnels.data;
export const reasonsSelector = (state) => state.modalDeal.reasons.data;
export const peopleSelector = (state) => state.modalDeal.people.data;
export const usersSelector = (state) => state.modalDeal.users.data;
export const isFetchingReasonsSelector = (state) =>
  state.modalDeal.reasons.meta.isFetching;

const { reducer: reasonsReducer, actionCreators: reasonsActions } = dataReducer(
  `${MODULE_NAME}/reasons`
);

const { reducer: usersReducer, actionCreators: usersActions } = dataReducer(
  `${MODULE_NAME}/users`
);

const { reducer: peopleReducer, actionCreators: peopleActions } = dataReducer(
  `${MODULE_NAME}/people`
);

const { reducer: funnelsReducer, actionCreators: funnelsActions } = dataReducer(
  `${MODULE_NAME}/funnels`
);

export const {
  reducer: dealsAuditsReducer,
  selectors: dealsAuditsSelectors,
  actionCreators: dealsAuditsActions,
} = dataReducer(`modalDeal/dealsAudits`);

const { reducer: eventsReducer } = dataReducer(`${MODULE_NAME}/events`);

const initialStateInfo = {
  data: {
    id: null,
    interest: null,
    is_stagnant: null,
    order: null,
    person: {
      id: null,
      name: null,
    },
    property: {
      id: null,
      name: null,
      images: [],
    },
  },
  meta: { isFetching: false },
};

export function infoReducer(state = initialStateInfo, action) {
  switch (action.type) {
    case REQUEST_INFOS:
      return {
        ...state,
        meta: { isFetching: true },
      };
    case RECEIVE_INFOS:
      return {
        ...state,
        data: action.payload,
        meta: { isFetching: false },
      };
    case RESET_INFOS:
      return initialStateInfo;
    default:
      return state;
  }
}

export function interestReducer(state = 0, action) {
  switch (action.type) {
    case INTEREST_SET:
      return action.payload;
    default:
      return state;
  }
}

// Actions
export default combineReducers({
  events: eventsReducer,
  interest: interestReducer,
  infos: infoReducer,
  funnels: funnelsReducer,
  reasons: reasonsReducer,
  users: usersReducer,
  people: peopleReducer,
  dealsAudits: dealsAuditsReducer,
});

/**
 * Pega os motivos de perda
 */
export const getReasons = () => (dispatch) => {
  dispatch(reasonsActions.request());
  dispatch(usersActions.request());
  dispatch(peopleActions.request());

  userService.getList().then(({ data: users }) => {
    dispatch(usersActions.receive(users));
  });

  peopleService.getAll().then(({ data: people }) => {
    dispatch(peopleActions.receive(people));
  });

  dealsService.getLossReasons().then(({ data: reasons }) => {
    dispatch(reasonsActions.receive(reasons));
  });
};

export const handleSubmitGain = (stageId, dealId, values) => (dispatch) =>
  dealsService.dealEarn(values).then(() => {
    // getInitialData(stageId)(dispatch);
  });

/**
 *
 * @param stageId
 * @param dealId
 * @param values
 */
export const handleSubmitLoss = (stageId, dealId, values) => (dispatch) => {
  const currentValues = values;

  // verifica se tem razão
  if (values.reason && values.reason.length > 0) {
    delete currentValues.loss_reason_id;
  }

  return dealsService.dealLoss(dealId, values).then(() => {
    // getInitialData(stageId)(dispatch);
  });
};

/**
 * Pega as informacoes da modal de negócio
 * @param stageid
 */
export const getInfos = (stageid, dealId) => (dispatch) => {
  dispatch({ type: REQUEST_INFOS });

  return dealsService.getOne(stageid, dealId).then(({ data: payload }) => {
    dispatch({ type: INTEREST_SET, payload: payload.interest });
    dispatch({ type: RECEIVE_INFOS, payload });
  });
};

/**
 * Pega todas as etapas
 * @param funnelId
 * @param params
 */
export const getFunnels = (funnelId, params) => (dispatch) => {
  dispatch(funnelsActions.request());

  return dealsService
    .getFunnels({
      include: 'stages',
    })
    .then(({ data }) => {
      dispatch(funnelsActions.receive(data));
    });
};

/**
 * Move um negócio para outra etapa
 * @param stageId
 * @param dealId
 * @param values - dentro do values tem o stage_id que é o id do estágio que precisa ser enviado
 * @return {Promise}
 */
export const handleSubmitMoveDeal = (stageId, dealId, values) => (dispatch) => {
  // Este é o id para qual stage está sendo movido o negócio
  const newStageId = values.stage_id;

  return dealsService.updateDeal(stageId, dealId, { ...values }).then(() => {
    // Atualiza as informações do negócio
    // para que o cliente possa alterar a ordem do negócio novamente
    getInfos(newStageId, dealId)(dispatch);
  });
};

export const handleSetInterest = (stageId, dealId, interest) => (dispatch) =>
  dealsService.updateDeal(stageId, dealId, { interest }).then(() => {
    dispatch({ type: INTEREST_SET, payload: interest });
  });

/**
 * Busca todos os
 * @param dealId
 * @return {Function}
 */
export const fetchDealAudits = (dealId) => (dispatch) => {
  dispatch(dealsAuditsActions.request());

  return auditsService.getListDealsAudits({ id: dealId }).then(({ data }) => {
    dispatch(dealsAuditsActions.receive(data));
    return data;
  });
};
