import React, { Component } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { formValueSelector, getFormValues, reset } from 'redux-form';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { MdAddCircle, MdArrowDropDown, MdCheckCircle } from 'react-icons/md';
// Components
import Nodata from 'components/Nodata';
import NoContent from 'components/NoContent';
import Loading from 'components/Loading';
import CardProperty from 'components/CardProperty';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import PropertiesList from 'components/PropertiesList';
import Button from 'components/Button';
import Overlay from 'components/Card/components/Overlay';
import Dropdown, { Item, List } from 'components/Dropdown';
import RequestPropertyPublication from 'components/RequestPropertyPublication';

// Services
import * as propertiesService from 'services/properties';
// Modules
import {
  cleanProperties,
  getPropertiesWithPagination,
  propertiesIsFetchingSelector,
  propertiesSelector,
  removeProperty,
} from 'modules/properties';
import {
  openModalPropertyExcluded,
  openModalPropertyPublication,
  openModalProperty,
} from 'modules/modal';
import { Actions as FilterActions } from 'pages/Properties/Filter/module';
// HOC
import withReception from 'HOC/withReception';
// Assets
import searchEmpty from './property-revision.svg';
import { withPagination } from 'HOC/withPagination';
import Pagination from 'components/Pagination';
import { clearUrlParams } from 'lib/url-helpers';
import Container from 'components/Container';
import {
  FilterContent,
  FilterTitle,
  FilterWrapper,
} from 'components/FilterAside';
import * as Fields from 'components/PropertyFilters';
import FieldSelectUser from 'containers/FieldSelectUser';
import { CheckboxGroup } from 'components/Form';
import FormWrapper from 'components/FormWrapper';
import _ from 'lodash';
import { isReferenceNotChanged } from 'lib/property-helpers';
import LabelStatus from 'components/CardProperty/components/LabelStatus';
import { PropertyImage } from 'components/CardProperty/components/CardVertical/styles';
import Can from 'containers/Can';
import * as Filters from 'components/PropertyFilters';

class Properties extends Component {
  static defaultProps = {
    itemContent: PropTypes.func,
  };

  static propTypes = {};

  fetchProperties = (params = {}, limit = 50, offset = 1) => {
    const { getPropertiesWithPagination, filter } = this.props;

    return getPropertiesWithPagination(
      {
        filter: {
          is_draft: true,
          status: [1, 2, 3],
          ...filter,
          ...filter?.filter,
          ...params?.filter,
        },
        with_grouped_condos: null,
        offset,
        limit,
        sort: '-updated_at',
      },
      'revision'
    );
  };

  onChangePage = (page) => {
    const { filter } = this.props;

    return this.fetchProperties(
      {
        filter,
      },
      50,
      page.selected + 1
    );
  };

  async componentDidMount() {
    const { location } = this.props;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (search?.property) {
      clearUrlParams();

      const { data: property } = await propertiesService.getOne(
        search.property
      );

      this.handleOpenPublication(property)();
    }

    // Buscar imóveis que não estão publicados
    this.fetchProperties({}, 50, 1);
  }

  componentWillUnmount() {
    this.props.cleanProperties();
  }

  handleOpenPublication = (property) => () => {
    const { openModalPropertyPublication } = this.props;

    openModalPropertyPublication({
      property,
      onSubmitSuccess: () => {
        // Atualiza a lista quando termina de atualizar
        this.fetchProperties();
      },
    });
  };

  onSubmit = (filter) => {
    const sort = filter?.sort;
    delete filter.sort;
    return this.fetchProperties({ filter, sort });
  };

  resetSearch = () => {
    this.props.reset('PropertiesRevision');
  };

  get renderPropertiesNoData() {
    return (
      <Nodata
        image={searchEmpty}
        title="Nenhum imóvel para revisar"
        text="Ao cadastrar um novo imóvel, ele aparecerá aqui."
      >
        <Button
          color="danger"
          className="h-margin-top--15"
          onClick={this.resetSearch}
        >
          Limpar filtro
        </Button>
      </Nodata>
    );
  }

  renderImage = (property) => {
    const imageUrl = property.cover_image && property.cover_image.file_url;

    return (
      <Link
        to={`/properties/update/imagesArea/${property.id}`}
        className={classnames('CardProperty__figure', {
          'CardProperty__figure--hasImage': imageUrl,
          'CardProperty__figure--noImage': !imageUrl,
        })}
      >
        {imageUrl && (
          <PropertyImage
            size="medium"
            src={property.cover_image && property.cover_image.file_url}
            alt={property.title}
            className="CardProperty__img"
          />
        )}
        <Overlay>
          <Overlay.row position="top">
            <Overlay.item>
              {!property?.is_on_network && <LabelStatus property={property} />}
            </Overlay.item>
          </Overlay.row>
          <Overlay.row position="center" className="add-photos">
            <span className="h-flex__cell--grow" />
            <Overlay.item
              style={{
                textAlign: 'center',
              }}
            >
              <MdAddCircle className="CardProperty__icon" />
              <p>Adicionar Fotos</p>
            </Overlay.item>
            <span className="h-flex__cell--grow" />
          </Overlay.row>
        </Overlay>
      </Link>
    );
  };

  handleRemoveProperty = (property) => () => {
    const { openModalPropertyExcluded } = this.props;

    openModalPropertyExcluded({
      property,
      onSubmitSuccess: () => {
        this.fetchProperties();
      },
    });
  };

  get currentUser() {
    try {
      return localStorage.getItem('current-user');
    } catch {
      return {};
    }
  }

  get initialValues() {
    try {
      const { country_id: by_country_id, state_id: by_state_id } = _.get(
        this.currentUser,
        'real_estate.relations'
      );

      return {
        by_country_id,
        by_state_id,
      };
    } catch {
      return {};
    }
  }

  get renderProperties() {
    const { openModalProperty, properties } = this.props;

    if (!properties.length) return this.renderPropertiesNoData;

    return (
      <PropertiesList
        data={properties}
        itemRenderer={({ type }) =>
          (property) =>
            (
              <CardProperty
                property={property}
                type={type}
                className="CardProperty--revision h-hover"
                handleClickProperty={() => {
                  openModalProperty({
                    property,
                  });
                }}
              >
                {property.is_draft && (
                  <div className="h-margin-top--10">
                    <Can
                      run="DYNAMIC_PUBLISH"
                      permissions={property?.permissions}
                      yes={() => (
                        <span className="h-margin-right--10">
                          <Button
                            to={`/properties/store/publish/${property.id}`}
                            color="secondary"
                            size="small"
                          >
                            Publicar
                          </Button>
                        </span>
                      )}
                      no={() => (
                        <span
                          className="h-margin-right--10"
                          data-tip="Você não tem permissão<br/>para publicar um imóvel<br/>que não pertence a você."
                        >
                          <RequestPropertyPublication
                            propertyId={property.id}
                            size="small"
                            color="secondary"
                          />
                        </span>
                      )}
                    />
                    <Dropdown isDropHover>
                      <Button color="white" colorText="primary" size="small">
                        Opções <MdArrowDropDown />
                      </Button>
                      <List>
                        <Item>
                          <a
                            href={property.site_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              window.open(property.site_link, '_blank');
                            }}
                          >
                            Ver no site
                          </a>
                        </Item>
                        <Item>
                          <Link
                            to={`/properties/update/imagesArea/${property.id}`}
                          >
                            Adicionar fotos
                          </Link>
                        </Item>
                        <Item>
                          <Link to={`/properties/store/id/${property.id}`}>
                            Editar
                          </Link>
                        </Item>
                        <Item>
                          <button onClick={this.handleRemoveProperty(property)}>
                            Descartar
                          </button>
                        </Item>
                      </List>
                    </Dropdown>
                  </div>
                )}
              </CardProperty>
            )}
      />
    );
  }

  render() {
    const { reception, handleClickAction, isFetching } = this.props;

    if (reception.isVisible) {
      return (
        <Wrapper>
          <Helmet>
            <title>Revisão dos imóveis</title>
          </Helmet>
          <NoContent
            size={655}
            image={searchEmpty}
            styleImage={{ width: '222px' }}
            title="Revisar Imóveis"
            text={
              <>
                Após cadastrar um imóvel, ele aparecerá aqui. Antes de
                publicá-lo oficialmente em seu site, você pode tomar algumas
                ações para deixá-lo o mais completo possível. Por exemplo:
                <ul className="h-list--none" style={{ margin: '15px 0' }}>
                  <li>
                    <MdCheckCircle /> Adicionar Fotos.
                  </li>
                  <li>
                    <MdCheckCircle /> Revisar as informações.
                  </li>
                  <li>
                    <MdCheckCircle /> Definir os dados que devem ser mostrados
                    no site.
                  </li>
                </ul>
                <Button onClick={handleClickAction} color="success">
                  Entendi
                </Button>
              </>
            }
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper full>
        <Helmet>
          <title>Revisão dos imóveis</title>
        </Helmet>
        <FormWrapper
          as={FilterWrapper}
          form="PropertiesRevision"
          initialValues={this.initialValues}
          onSubmit={this.onSubmit}
          onChange={(values, dispatch, ownProps, prevValues) => {
            if (isReferenceNotChanged(values, prevValues)) {
              this.props.hideReference();
            }
          }}
        >
          {() => (
            <>
              <FilterTitle>Filtrar</FilterTitle>
              <FilterContent>
                <Fields.Reference />
                <Fields.Transaction canRemoveSelf />
                <Fields.TypeAndSubtype />
                <Filters.NewLocation />
                <Fields.StreetAddress />
                <Fields.Condominium filter={{ is_draft: true }} />
                <Fields.Price />
                <Fields.Person filter="is_draft" />
                <Fields.Database hasGuru={false} />
                <FieldSelectUser
                  parse={null}
                  render={({ options, ...params }) => (
                    <div>
                      <label className="FormLabel">Corretor Responsável</label>
                      <CheckboxGroup {...params} limit={4} options={options} />
                    </div>
                  )}
                />
                <Fields.Furniture />
              </FilterContent>
            </>
          )}
        </FormWrapper>
        {isFetching && <Loading isVisible isFullScreen />}
        <Wrapper.inner>
          <Wrapper.container>
            <MainTitle
              title="Imóveis para Revisar"
              text="Dê os últimos retoques em seu imóvel antes de publicá-lo."
              className="js-point"
            >
              {/*<SortProperties />*/}
            </MainTitle>
            <Container>
              {this.renderProperties}
              <Pagination
                forcePage={this.props.pagination.current_page - 1}
                pageCount={this.props.pagination.total_pages}
                pageRangeDisplayed={10}
                marginPagesDisplayed={2}
                onPageChange={this.onChangePage}
              />
            </Container>
          </Wrapper.container>
        </Wrapper.inner>
      </Wrapper>
    );
  }
}

const selectorSort = formValueSelector('PropertiesRevisionSort');

const mapStateToProps = (state) => ({
  filter: getFormValues('PropertiesRevision')(state),
  sort: selectorSort(state, 'sort'),
  properties: propertiesSelector(state),
  isFetching: propertiesIsFetchingSelector(state),
});

const mapDispatchToProps = {
  hideReference: FilterActions.hideReference,
  getPropertiesWithPagination,
  cleanProperties,
  removeProperty,
  openModalProperty,
  openModalPropertyPublication,
  openModalPropertyExcluded,
  reset,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPagination('revision', {
    limit: 50,
  }),
  withReception('properties/revision', true)
)(Properties);
