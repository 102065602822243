export const REDIRECT_LAYOUT_PAGE = '/site/layout/style/apparence';

export const CATEGORIES = [
  { label: 'Modelos Urbanos', value: 'Urbanos' },
  { label: 'Modelos Rurais', value: 'Rurais' },
  { label: 'Modelos Praia', value: 'Praias' },
  { label: 'Outros modelos', value: 'Outros' },
];

export const DEFAULT_CATEGORY = 'Urbanos';
