import React, { useState } from 'react';
import { MdChevronRight } from 'react-icons/md';
import * as PropTypes from 'prop-types';
import { Wrapper, Header, Title, Bar } from './styles';
import VariableSelect from './components/VariableSelect';
import { getVariables } from 'services/settings/sales-authorizations';
import { useRequest } from 'hooks/useRequest';

const VariablesBar = ({ textareaRef, variables, onChange }) => {
  const { data: types } = useRequest({
    request: getVariables
  });

  const [variable, setVariable] = useState(null);

  function changeText(text) {
    if (textareaRef?.current) {
      const quill = textareaRef.current?.getEditor();
      quill.focus();

      const index = quill?.getSelection()?.index || 0;

      quill.insertText(index, text, {
        color: '#0084F4'
      });

      quill.insertText(index, ' ', {
        color: '#000'
      });

      quill.insertText(index + text.length + 1, ' ', {
        color: '#000'
      });

      if (onChange) onChange(quill.root.innerHTML);
    }
  }

  if (variable) {
    return (
      <Wrapper>
        <VariableSelect
          data={variable}
          onClick={({ type }) => {
            changeText(type);
          }}
          onClose={() => setVariable(null)}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header>
        <Title>Variáveis</Title>
        <p>Selecione as variáveis:</p>
      </Header>

      {types.map(variable => (
        <Bar key={variable?.title} onClick={() => setVariable(variable)}>
          {variable?.title}
          <MdChevronRight />
        </Bar>
      ))}
    </Wrapper>
  );
};

VariablesBar.propTypes = {
  textareaRef: PropTypes.any.isRequired,
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string
    })
  ).isRequired
};

export default VariablesBar;
