import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: 0 -5px;
`;

export const InputWrapper = styled.div`
  padding: 0 5px;
  flex: 1 0;
`;
