import React, { useEffect, useCallback, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';

// Components
import Container from 'components/Container';
import MainTitle from 'components/MainTitle';
import Divider from 'components/Divider';
import FormBox from 'components/FormBox';
import { BOOL } from 'constants/options';
import Label from 'components/Label';
import { FieldBool, Select, Textarea, Input } from 'components/Form';

// HOC
import { withId } from '../../HOC/withId';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Services
import * as privateService from 'services/propertiesPartial/private';
// Helpers
import { normalizeDate } from 'lib/formHelpers';
// Hooks
import useFormValue from 'hooks/useFormValue';
import { Wrapper } from './styles';
import ListOptions from 'components/ListOptions';
import moment from 'lib/moment';
import { isValidDate } from 'lib/date-helpers';
import { TYPES } from 'constants/types';
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import ListCheck from 'components/ListCheck';
import * as situationsService from 'services/cadastralSituations';
import { finishResponse } from 'pages/PropertyStore/helpers';
import Loading from 'components/Loading';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { useParams } from 'react-router';

const LabelRevision = ({ children, ...props }) => (
  <Label {...props} type="button" className="h-margin-right--5">
    {children}
  </Label>
);

const Private = ({
  handleSubmit,
  initialize,
  change,
  onSubmit,
  submitting
}) => {
  const is_commission_combined = useFormValue('is_commission_combined');
  const is_exclusive = useFormValue('is_exclusive');
  const is_keys_ready = useFormValue('is_keys_ready');
  const fields = useFormValue(['exclusive_until', 'next_review_at']);
  const { data, fetchData } = useRequest({
    request: privateService.getOne,
    initialFetch: false,
    initialState: {}
  });
  const { id } = useParams();

  const { data: situations } = useRequest({
    request: situationsService.getList
  });

  const isGround = useMemo(() => {
    return TYPES.TERRENO === data.type_id;
  }, [data]);

  const getDate = useCallback(
    field => {
      const dateField = fields[field];
      return isValidDate(dateField) ? dateField : moment().format('DD/MM/YYYY');
    },
    [fields]
  );

  const handleAddDays = useCallback(
    (field, days) => () => {
      const date = getDate(field);

      change(
        field,
        moment(date, 'DD/MM/YYYY')
          .add(days, 'days')
          .format('DD/MM/YYYY')
      );
    },
    [getDate, fields]
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    initialize(data);
  }, [data]);

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle
        title="Dados privativos"
        text="Todas as informações abaixo não são mostradas em seu site."
      />
      <Container>
        <FormBox.header size="small">FINANCEIRO</FormBox.header>
        <Row>
          <Field
            xs={3}
            buttonTemplate
            label="Comissão Combinada"
            name="is_commission_combined"
            options={BOOL}
            component={Select}
          />
          <Field
            xs={9}
            growOnFocus
            growHeight={250}
            label="Observação sobre a negociação"
            name="negotiation_note"
            component={Textarea}
            disabled={!is_commission_combined}
          />
        </Row>

        <Row>
          <Col xs={12}>
            <Divider spacingY={5} borderStyle="solid" />
          </Col>
        </Row>
        <FormBox.header size="small">Imóvel</FormBox.header>
        <Row>
          <Field
            xs={3}
            label="Matrícula"
            placeholder="Digite o número"
            name="matriculation"
            component={Input}
          />
          <Field
            xs={3}
            label="Imposto Territorial Rural"
            name="itr"
            component={Input}
          />
        </Row>

        <Row>
          <Col xs={12}>
            <Divider spacingY={5} borderStyle="solid" />
          </Col>
        </Row>
        <FormBox.header size="small">OBSERVAÇÕES</FormBox.header>
        <Row>
          <Field
            xs={6}
            label="Dados sobre ocupação/desocupação"
            growOnFocus
            growHeight={250}
            name="occupation_note"
            component={Textarea}
          />
          <Field
            xs={6}
            growOnFocus
            growHeight={250}
            label="Observação privada sobre o imóvel"
            name="private_note"
            component={Textarea}
          />
          {!isGround && (
            <Field
              xs={6}
              growOnFocus
              growHeight={250}
              label="Descrição da mobília"
              name="furniture_note"
              component={Textarea}
            />
          )}
        </Row>
        <Row>
          <Col xs={12}>
            <Divider spacingY={5} borderStyle="solid" />
          </Col>
        </Row>
        <FormBox.header size="small">Compromissos</FormBox.header>
        <ListOptions>
          <ListOptions.item
            title="Autorização formalizada?"
            renderOptions={() => (
              <FieldBool responsive={false} name="has_owner_authorization" />
            )}
          />
          <ListOptions.item
            title="Com placa?"
            renderOptions={() => (
              <FieldBool responsive={false} name="has_sale_card" />
            )}
          />
          <ListOptions.item
            title="Com exclusividade?"
            renderOptions={() => (
              <>
                <FieldBool
                  responsive={false}
                  name="is_exclusive"
                  className="has-group-next"
                />
                {is_exclusive && (
                  <div className="h-margin-left--15">
                    <Field
                      label="Até quando?"
                      name="exclusive_until"
                      component={Input}
                      normalize={normalizeDate}
                    />
                    <div className="h-margin-top--10">
                      <LabelRevision
                        handleClick={handleAddDays('exclusive_until', 30)}
                      >
                        +30
                      </LabelRevision>
                      <LabelRevision
                        handleClick={handleAddDays('exclusive_until', 60)}
                      >
                        +60
                      </LabelRevision>
                      <LabelRevision
                        handleClick={handleAddDays('exclusive_until', 90)}
                      >
                        +90
                      </LabelRevision>
                      dias
                    </div>
                  </div>
                )}
              </>
            )}
          />
          <ListOptions.item
            title="Chave disponível?"
            renderOptions={() => (
              <>
                <FieldBool
                  responsive={false}
                  name="is_keys_ready"
                  className="has-group-next"
                />
                {is_keys_ready && (
                  <Field
                    label="Onde pegar?"
                    name="keys_location"
                    component={Input}
                  />
                )}
              </>
            )}
          />
          <ListOptions.item title="DOCUMENTOS E CERTIFICADOS">
            <div style={{ marginTop: 15 }}>
              <ListCheck name="cadastral_situations" data={situations} />
            </div>
          </ListOptions.item>
        </ListOptions>
      </Container>

      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </Wrapper>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyPrivate',
    onSubmit: (values, d, p) =>
      privateService.update(values).then(finishResponse(values, d, p)),
    onSubmitSuccess: (response, dispatch, p) => {
      p.initialize(response?.data);
      if (!p.isEditting) {
        p.next('imagesArea');
      }
    }
  }),
  withFormConfirmation()
)(Private);
