import React from 'react';
import { Field } from 'redux-form';
import { PROFILE } from 'constants/options';
import { Select } from 'components/Form';

const FieldProfile = () => {
  return (
    <Field
      xs={3}
      required
      label="Perfil do imóvel"
      name="profile"
      options={PROFILE}
      component={Select}
    />
  );
};

export default FieldProfile;
