import { Actions, Selectors } from 'modules/modalImages';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useImages = (images) => {
  const gallery = useSelector(Selectors.gallery);
  const dispatch = useDispatch();

  /**
   * Retorna todas as imagens separadas por galeria
   *
   * @return {Object} currentImages
   * @return {Array} currentImages.images
   * @return {Array} currentImages.plants
   * @return {Array} currentImages.private
   */
  const currentImages = useMemo(() => {
    return images.reduce(
      (acc, data) => {
        switch (data.gallery) {
          case 2: {
            acc.plants = [...acc.plants, data];
            break;
          }
          case 3: {
            acc.private = [...acc.private, data];
            break;
          }
          case 1:
          default: {
            acc.images = [...acc.images, data];
            break;
          }
        }

        return acc;
      },
      {
        images: [],
        plants: [],
        private: [],
      }
    );
  }, [images]);

  const imagesLength = currentImages.images.length;
  const plantsLength = currentImages.plants.length;
  const privateLength = currentImages.private.length;

  const currentGallery = useMemo(() => {
    return currentImages[gallery];
  }, [currentImages, gallery]);

  const getGallery = () => {
    if (imagesLength > 0) return 'images';
    if (plantsLength > 0) return 'plants';
    if (privateLength > 0) return 'private';
    return 'images';
  };

  const setGallery = (gallery) => {
    dispatch(Actions.setGallery(gallery));
  };

  useEffect(() => {
    setGallery(getGallery());
  }, []);

  return {
    gallery,
    imagesLength,
    plantsLength,
    privateLength,
    currentGallery,
    currentImages,
    setGallery,
  };
};
