import styled, { css } from 'styled-components';
import Image from 'components/Image';

export const Delete = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
  background: ${p => p.theme.colors.danger};
  transition: all 0.3s ease-in-out;
  will-change: opacity, visibility;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  z-index: 10;

  svg {
    color: #fff;
  }
`;

export const WrapperLegend = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  > div {
    display: block !important;
  }
`;

export const Legend = styled.div`
  position: relative;
  width: 100%;
  background: ${p => p.theme.colors.primary};
  color: #fff;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0 0 4px 4px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;

  ${p =>
    p.isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  svg {
    flex-shrink: 0;
    vertical-align: middle;
    margin-right: 2px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: ${p => (p.isEditting ? 20 : 'auto')};
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 4px;
  transition: box-shadow 0.2s ease-in-out;
  will-change: box-shadow;
  z-index: 3;

  ${p =>
    p.theme.isLoading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  :hover {
    box-shadow: 0px 0px 20px rgba(28, 12, 30, 0.4);

    ${Delete},
    ${Legend} {
      opacity: 1;
      visibility: visible;
    }
  }

  .image {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    z-index: 5;
  }
`;

export const Actions = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  z-index: 20;

  .Label {
    padding: 3px 5px 3px 5px;
    margin-bottom: 5px;

    svg {
      margin-top: -1px;
    }
  }
`;

export const ContainerForm = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 220px;
  padding: 20px;
  background: #fff;
  margin-top: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 20;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 5;
`;

export const Img = styled(Image)`
  object-fit: cover;
`;
