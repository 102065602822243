import styled from 'styled-components';
import { Wrapper as Item } from './components/Item/styles';

export const Wrapper = styled.div`
  margin: 15px 0;
  display: flex;
  border: 1px solid ${(p) => p.theme.borderColor};
  border-radius: ${(p) => p.theme.borderRadius};

  ${Item} + ${Item} {
    border-left: 1px solid ${(p) => p.theme.borderColor};
  }
`;
