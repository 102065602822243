import api from 'services/index';
// Helpers
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega as configurações gerais do site
 * @param params
 * @return {Promise}
 */
export const getOne = params => api.getOne('settings/sites/seo', '', params);

/**
 * Atualiza as informações gerais do site
 * @param values
 * @return {Promise}
 */
export const update = values =>
  api.update('settings/sites/seo', values).then(responseMessage('Salvo!'));
