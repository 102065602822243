import { useMemo } from 'react';
// Components
import { FaBuilding } from 'react-icons/fa';
import {
  MdBurstMode,
  MdFormatPaint,
  MdSettings,
  MdWebAsset,
} from 'react-icons/md';
// import ItemDropdown, { Item } from 'components/MainHeader2/components/ItemDropdown';
import MenuHeader, { Item } from 'components/MenuHeader';
import Can from 'containers/Can';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { openModalSiteTemplate } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { colors } from 'constants/styles';

function MenuSite() {
  const dispatch = useDispatch();
  const { permissions } = useCurrentUser();

  const hasMenuSite = useMemo(() => {
    const canEditAppearance = permissions.includes('EDIT_SITE_APPEARANCE');
    const canEditConfig = permissions.includes('EDIT_SITE');
    return canEditAppearance || canEditConfig;
  }, [permissions]);

  if (!permissions || !hasMenuSite) return null;

  return (
    <MenuHeader title="Site" to="/site" active="site">
      <Can run="EDIT_SITE_APPEARANCE">
        <Item
          title="Modelos prontos"
          text="Escolha ou crie layouts exclusivos"
          icon={MdBurstMode}
          iconColor={colors.success}
          onClick={() => {
            dispatch(openModalSiteTemplate());
          }}
        />
        <Item
          to="/site/layout"
          title="Aparência do site"
          text="Personalize sua aparência"
          icon={MdFormatPaint}
          iconColor="secondary"
        />
      </Can>
      <Can run="EDIT_SITE">
        <Item
          to="/site/config"
          title="Configurações"
          text="Configure domínios e recursos"
          icon={MdSettings}
          iconColor="tertiary"
        />
      </Can>
      <Can run="EDIT_SITE_APPEARANCE">
        <Item
          to="/site/content-site"
          title="Conteúdo do site"
          text="Personalize partes do seu site"
          icon={MdWebAsset}
          iconColor="primary"
        />
        <Item
          to="/enterprise"
          title="Hotsite"
          text="Crie páginas dedicadas"
          icon={FaBuilding}
          iconColor="caution"
        />
      </Can>
    </MenuHeader>
  );
}

export default MenuSite;
