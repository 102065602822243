import styled, { css } from 'styled-components';

function btnColors({ color }) {
  switch (color) {
    case 'caution':
      return css`
        background: #ff9702;
      `;
    default:
      return css`
        background: #31d084;
      `;
  }
}

export const Wrapper = styled.div`
  table {
    width: 422px;
    border-spacing: 0;
    margin-top: 10px;
  }

  th {
    padding-left: 9px;

    :first-child {
      padding-left: 0;
    }
  }

  td {
    background: #f3f4f8;
    padding: 9px;
    border-top: 5px solid #fff;

    :first-child {
      padding-left: 20px;
    }

    :last-child {
      padding-right: 20px;
    }
  }
`;
export const Btn = styled.button`
  padding: 3px 5px 3px 5px;
  border-radius: 2px;
  color: #fff;
  cursor: ${(p) => (p.href ? 'pointer' : 'default')};

  svg {
    font-size: 1.1em;
  }

  ${btnColors}
`;
