import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Components
import MenuItem from './MenuItem';
// Constants
import { SIZES } from 'constants/config';
import { Wrapper } from './styles';

const defaultProps = {
  className: '',
  size: 'small',
  border: false,
  position: 'center',
  positionBar: 'bottom',
  style: null,
  children: '',
  containerClassName: '',
  disableActiveItems: true,
  handleClickItem: null,
  modal: false,
};

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(SIZES),
  border: PropTypes.bool,
  position: PropTypes.oneOf(['start', 'center', 'end']),
  positionBar: PropTypes.oneOf(['top', 'bottom']),
  style: PropTypes.object,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  disableActiveItems: PropTypes.bool,
  handleClickItem: PropTypes.func,
};

class Menu extends Component {
  constructor(props) {
    super(props);

    this.menuClassNames = this.menuClassNames.bind(this);
    this.menuItemRender = this.menuItemRender.bind(this);
  }

  menuClassNames() {
    const {
      className,
      border,
      size,
      position,
      positionBar,
      disableActiveItems,
    } = this.props;

    return classnames('Menu', className, {
      'Menu--border': border,
      'Menu--disableActiveItems': disableActiveItems,
      [`Menu--${size}`]: size,
      [`Menu--${position}`]: position,
      [`Menu--${positionBar}`]: positionBar,
    });
  }

  /**
   * Adiciona evento de click em todos os itens do Menu
   * @param element
   * @return Element
   */
  menuItemRender(element) {
    const { currentPage, handleClickItem } = this.props;

    // verifica se não tem nenhum elemento dentro do menu
    if (!element) return null;

    return React.cloneElement(element, {
      ...element.props,
      currentPage,
      onClick: () => {
        handleClickItem(element.props.component);
      },
    });
  }

  render() {
    const { modal, children, style } = this.props;

    return (
      <Wrapper modal={modal} className={this.menuClassNames()} style={style}>
        {React.Children.map(children, this.menuItemRender)}
      </Wrapper>
    );
  }
}

Menu.defaultProps = defaultProps;
Menu.propTypes = propTypes;
Menu.item = MenuItem;

export default Menu;
