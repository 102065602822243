import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';
// Common Components
import { ModalTemplate } from 'components/Modal';
import Image from 'components/Image';
import Button from 'components/Button';
// Modules
import { receiveUser } from 'modules/login';
// Containers
import { TYPES } from 'services/sites/logos';
// Services
import * as logosService from 'services/settings/logos';

class ModalGallery extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onSelect: PropTypes.string,
  };

  state = {
    data: [],
    isFetching: false,
  };

  async componentDidMount() {
    this.fetchLogos();
  }

  fetchLogos = async () => {
    this.setState({ isFetching: true });

    const { data } = await logosService.getAll();

    this.setState({
      data,
      isFetching: false,
    });
  };

  updateRealEstateLogo({ file_url }) {
    const currentUser = localStorage.getItem('current-user');

    this.props.receiveUser({
      ...currentUser,
      real_estate: {
        ...currentUser.real_estate,
        file_url,
      },
    });
  }

  handleClick = (file) => async () => {
    const { onSelect, handleClose, type, device } = this.props;
    if (onSelect) onSelect(file);
    await logosService.selectLogoById(type, device, file.id);
    this.redirectByType(file);
    handleClose();
  };

  /**
   * Remove uma logo
   * @param id
   */
  handleDelete =
    ({ id }) =>
    async () => {
      try {
        await logosService.removeLogo(id);
      } finally {
        this.fetchLogos();
      }
    };

  redirectByType = (file) => {
    const {
      type,
      history: { push },
    } = this.props;

    switch (type) {
      case TYPES.HEADER:
      case TYPES.FOOTER:
      case TYPES.WHATSAPP:
        push('/site/layout/style/logo');
        break;
      case TYPES.REAL_ESTATE: {
        this.updateRealEstateLogo(file);
        push('/site/config/realestate');
        break;
      }
      case TYPES.WATERMARK:
        push('/site/layout/style/watermark');
        break;
      default:
        throw new Error('Tipo invalido');
    }
  };

  get renderLogos() {
    const { data } = this.state;

    return (
      <>
        <Row>
          {data.map((file) => (
            <Col key={file.id} xs={3} style={{ textAlign: 'center' }}>
              <Image
                src={file.file_url}
                alt={file.file_url}
                style={{ width: '100%', background: '#e1e6ec' }}
              />
              <Button
                color="success"
                onClick={this.handleClick(file)}
                style={{ margin: '15px 0 20px' }}
              >
                Usar
              </Button>
              <Button
                color="danger"
                onClick={this.handleDelete(file)}
                style={{ margin: '15px 0 20px 10px' }}
              >
                Remover
              </Button>
            </Col>
          ))}
        </Row>
      </>
    );
  }

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title="Escolher logo pronto"
          handleClose={handleClose}
          isLoading={this.state.isFetching}
        >
          {this.renderLogos}
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  receiveUser,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalGallery)
);
