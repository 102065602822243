import PropTypes from 'prop-types';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// Components
import Button from 'components/Button';
import NestTable from 'components/NestTable';
import { MdOutlineDragIndicator } from 'react-icons/md';
import ReactToggle from 'react-toggle';
import styled from 'styled-components';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,

  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
};

const defaultProps = {
  data: [],
  onChange: null,

  handleUpdate: null,
  handleDelete: null,
};

const Header = styled.header`
  display: flex;
`;

export const Col = styled.div`
  margin-left: 15px;
  width: 95px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
`;

export const Spacer = styled.div`
  width: 500px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #dedede;

  > div {
    line-height: 18px;
  }

  svg {
    font-size: 18px;
    vertical-align: middle;
    margin-right: 3px;
    margin-top: -2px;
  }

  .nestable-list .nestable-list .nestable-item & {
    border-left: 1px solid #dedede;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-left: 15px;
    width: 95px;
  }
`;

const ButtonsGroup = styled.div`
  min-width: 180px;
  text-align: right;
  white-space: nowrap;
`;

const TableMenu = ({
  data,
  onChange,
  handleUpdateMenu,
  handleToggleMenu,
  handleRemoveMenu,
}) => {
  const [isRefreshing] = useState(false);

  // useEffect(() => {
  //   setIsRefreshing(true);
  //
  //   setTimeout(() => {
  //     setIsRefreshing(false);
  //   }, 5);
  // }, [data]);
  return (
    <>
      <Header>
        <Spacer />
        <Col>Mostrar menu no topo</Col>
        <Col>Mostrar menu no rodapé</Col>
      </Header>
      {isRefreshing ? null : (
        <NestTable
          items={data}
          onChange={onChange}
          renderItem={({ item: menu, ...rest }) => {
            const key = uuidv4() + menu.id;
            return (
              <Wrapper key={key}>
                <div className="nestable__label">
                  <MdOutlineDragIndicator />
                  {menu.label}
                </div>
                <Actions>
                  <div>
                    <ReactToggle
                      key={uuidv4() + 'header'}
                      checked={menu.status_header}
                      icons={false}
                      style={{ fontSize: 11 }}
                      className="h-margin-right--5"
                      onChange={(e) => {
                        handleToggleMenu && handleToggleMenu('header', menu);
                      }}
                    />
                  </div>
                  <div>
                    <ReactToggle
                      key={uuidv4() + 'footer'}
                      checked={menu.status_footer}
                      icons={false}
                      style={{ fontSize: 11 }}
                      className="h-margin-right--5"
                      onChange={(e) => {
                        handleToggleMenu && handleToggleMenu('footer', menu);
                      }}
                    />
                  </div>
                  <ButtonsGroup>
                    {menu.modal_component !== 'ContactsUs' && (
                      <Button
                        color="white"
                        colorText="secondary"
                        onClick={() => handleUpdateMenu(menu)}
                      >
                        Editar
                      </Button>
                    )}
                    {!menu.is_default && (
                      <Button
                        color="danger"
                        onClick={() => handleRemoveMenu(menu)}
                      >
                        Excluir
                      </Button>
                    )}
                  </ButtonsGroup>
                </Actions>
              </Wrapper>
            );
          }}
        />
      )}
    </>
  );
};

TableMenu.propTypes = propTypes;
TableMenu.defaultProps = defaultProps;

export default TableMenu;
