import { useRequest } from 'hooks/useRequest';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';
import { useEffect } from 'react';
import { changePreview2 } from 'services/financialv2/contracts';

export const usePreview2 = ({ quantity = 1 } = { quantity: 1 }) => {
  const { item, setPreview2 } = useModalContractExtra();

  const { data, isFetching } = useRequest({
    request: changePreview2,
    params: {
      item,
      quantity,
      is_adding: true,
    },
  });

  useEffect(() => {
    setPreview2(null);
  }, []);

  useEffect(() => {
    setPreview2(data);
  }, [data]);

  return { data, isFetching };
};
