import { useMemo } from 'react';
// Components
import FieldCondominium from 'containers/FieldCondominium';
import useFormValue from 'hooks/useFormValue';

const CondominiumField = ({
  label = 'Condomínio',
  hideEmpty,
  name,
  isClearable = true,
  filter,
  placeholder = 'Condomínio',
  ...props
}) => {
  const onNetwork = useFormValue('on_network');

  const hasOrulo = useMemo(() => {
    return onNetwork?.includes('orulo');
  }, [onNetwork]);

  return (
    <FieldCondominium
      label={label}
      isClearable={isClearable}
      hasOrulo={hasOrulo}
      hideEmpty={hideEmpty}
      name={name}
      filter={filter}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default CondominiumField;
