import React from 'react';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';

// import { Wrapper } from './styles';

function ModalNoPaidCredits({
  isOneInstallment,
  data,
  handleClose,
  handleCancel,
}) {
  if (isOneInstallment) {
    return (
      <ModalTemplate title="Cancelar boleto" handleClose={handleClose}>
        <p>Deseja excluir este boleto em aberto?</p>
        <ModalFooter>
          <Button type="button" onClick={handleClose} color="white">
            Voltar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button color="danger" onClick={handleCancel}>
            Excluir boleto
          </Button>
        </ModalFooter>
      </ModalTemplate>
    );
  }

  return (
    <ModalTemplate title="Cancelamento de contrato" handleClose={handleClose}>
      <p>
        Você gostaria de cancelar este pacote e retornar para o contrato
        anterior? Seu contrato anterior tem crédito até o dia{' '}
        <strong>{data.end_at}</strong>.
      </p>
      <ModalFooter>
        <Button type="button" onClick={handleClose} color="white">
          Voltar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button color="danger" onClick={handleCancel}>
          Cancelar meu pacote
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

export default ModalNoPaidCredits;
