import React from 'react';
import PropTypes from 'prop-types';

const TableMonths = ({ data, onClickRow }) => (
  <table
    className="Table"
    style={{ margin: '-20px -19px -20px', width: 'calc(100% + 40px)' }}
  >
    <thead>
      <tr>
        <th>Data</th>
        <th>Corretores</th>
        <th>E-mail</th>
        <th>Preço</th>
      </tr>
    </thead>
    <tbody>
      {data &&
        data.map((debit) => (
          <tr
            key={`table-months-${debit.id}`}
            className="h-pointer"
            onClick={(e) => {
              e.preventDefault();
              if (onClickRow) onClickRow(debit);
            }}
          >
            <td>{debit.created_at}</td>
            <td>{debit.users_count}</td>
            <td>{debit.mailboxes_count}</td>
            <td>{debit.price}</td>
          </tr>
        ))}
    </tbody>
  </table>
);

TableMonths.defaultProps = {
  data: null,
  onClickRow: null,
};

TableMonths.propTypes = {
  data: PropTypes.array,
  onClickRow: PropTypes.func,
};

export default TableMonths;
