import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Components
import ImageComponent from 'components/Image';
// Helpers
import { googleDirections, googleStreetViewPanorama } from 'lib/google-maps-helper';
import MapLeaflet from 'components/MapLeaflet';
import { Marker } from 'react-leaflet';
import L, { Point } from 'leaflet';

const icon = L.icon({
  iconUrl: '/img/marker-icon.png',
  iconSize: new Point(25, 41)
});

const propTypes = {
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pitch: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  markerType: PropTypes.oneOf(['pin', 'circle']),
  mapUrl: PropTypes.string,
  streetViewUrl: PropTypes.string
};
const defaultProps = {
  markerType: null,
  mapUrl: null,
  streetViewUrl: null
};

const Image = ({ onClick, url, className, children }) =>
  url && (
    <div
      className={classnames('LocationImage__image', className, {
        'h-pointer': onClick
      })}
      onClick={onClick}
    >
      <ImageComponent
        type="outside"
        size="large"
        src={url}
        alt=""
        style={{ height: '175px', objectFit: 'cover' }}
      />
      {children}
    </div>
  );

const getUrl = (markerType, lat, lng) => {
  return googleDirections({
    destination: `${lat},${lng}`
  });
};

const LocationImage = ({
  property,
  lat,
  lng,
  heading,
  pitch,
  zoom,
  markerType,
  mapUrl,
  streetViewUrl
}) => {
  const hasLocation = lat && lng;
  const hasStreetView = !!streetViewUrl && property?.is_streetview_shown;

  if (!hasLocation) return null;

  return (
    <div
      className={classnames('LocationImage', {
        [`LocationImage--${markerType}`]: markerType
      })}
    >
      {hasLocation && (
        <div
          className="LocationImage__image LocationImage__map"
          style={{ height: '175px' }}
        >
          <MapLeaflet
            properties={[property]}
            dragging={false}
            scrollWheelZoom={false}
            zoom={15}
            renderProperties={({ property: p }) => {
              return (
                <Marker
                  icon={icon}
                  key={p.id}
                  position={[p.maps_latitude, p.maps_longitude]}
                  onClick={() => {
                    if (lat && lng) {
                      window.open(getUrl(markerType, lat, lng));
                    }
                  }}
                />
              );
            }}
          />
        </div>
      )}
      {hasStreetView && (
        <Image
          url={streetViewUrl}
          className="LocationImage__streetview"
          onClick={() => {
            window.open(
              googleStreetViewPanorama({
                viewpoint: `${lat},${lng}`,
                heading: heading,
                pitch: pitch,
                fov: zoom
              })
            );
          }}
        />
      )}
    </div>
  );
};

LocationImage.propTypes = propTypes;
LocationImage.defaultProps = defaultProps;

export default LocationImage;
