import api from 'services/index';

export const TYPES = {
  PROPERTIES: 1,
  PEOPLE: 2,
};

export const generateBackupByType = (type, format) =>
  api.create('real-estates/backups', { type, format });

export const getBackupsByType = (type) =>
  api.getList('real-estates/backups', { filter: { type } });

export const getBackups = () => api.getList('real-estates/backups');
export const generateBackup = () => api.create('real-estates/backups');
