import { withValidation } from 'lib/validate-helpers';
import getVideoId from 'get-video-id';

export default values => {
  const { errors, validations } = withValidation(values);

  // Campo url precisa ser preenchido
  validations.required('url');

  if (values?.url) {
    const video = getVideoId(values?.url);
    if (!video.id) {
      errors.url = ['Url de vídeo inválida'];
    }
  }

  return errors;
};
