import api from './index';

/**
 * Normaliza uma lista de tipos e subtipos
 * @param data
 * @return {*}
 */
export const normalizeTypeSubtype = (data) =>
  data.map((type) => ({
    ...type,
    type_id: type.id,
    label: type.title,
    options: type.subtypes.map((subtype) => ({
      label: subtype.title,
      value: subtype.id,
      type_name: type?.title,
      group: type,
    })),
  }));

export const normalizeTypeSubtypeResponse = (res) => ({
  ...res,
  data: normalizeTypeSubtype(res.data),
});

export const getList = (params) =>
  api.getList('types', {
    sort: 'order',
    ...params,
  });

export const getTypesAndSubtypes = (params) =>
  getList(params).then(normalizeTypeSubtypeResponse);

export const getGroupedTypesSubtypes = (params) =>
  getList(params).then((res) => {
    const types = res.data;
    let data = [];

    types.forEach(({ subtypes, ...type }) => {
      data = [...data, { ...type, type_id: type.id, is_type: true }];

      subtypes.forEach((subtype) => {
        data = [
          ...data,
          { ...subtype, type, type_id: type.id, is_subtype: true },
        ];
      });
    });

    return { ...res, data };
  });

export const getOne = (id) => api.getOne('types', id);
export const create = (data) => api.create('types', data);
export const update = (data) => api.update('types', data);
export const remove = (id) => api.delete('types', id);

/**
 * Busca todos os subtipos
 * @param type_id
 * @param params
 * @return {*|{params}}
 */
export const getSubtypes = (type_id, params) =>
  api.getList(`types/${type_id}/subtypes`, {
    sort: 'order',
    ...params,
  });
