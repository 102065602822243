import React, { useEffect, useMemo } from 'react';
import { Row } from 'react-flexbox-grid';
// Input
import { SelectMultiple } from 'components/Form';
// Containers
import FieldPerson from 'containers/FieldPerson';
import useFormValue from 'hooks/useFormValue';
import useReduxForm from 'hooks/useReduxForm';

function FieldPersonOrulo() {
  const { change } = useReduxForm();
  const onNetwork = useFormValue('on_network');

  // Verifica se foi selecionado órulo
  const isOrulo = useMemo(() => onNetwork?.includes('orulo'), [onNetwork]);

  useEffect(() => {
    change('people_id', null);
  }, [isOrulo, change]);

  if (!isOrulo) return null;

  return (
    <Row>
      <FieldPerson
        creatable={false}
        xs={12}
        label="Proprietários"
        name="people_id"
        params={{
          filter: { by_orulo_owner: true }
        }}
        component={SelectMultiple}
      />
    </Row>
  );
}

export default FieldPersonOrulo;
