import React, { useState } from 'react';
import Bar from './components/Bar';
import Modal from './components/Modal';
import { Content, DetailsLink, Header, Title, Wrapper } from './styles';

function RatingData({
  title = 'Nota de qualidade do anúncio',
  value = 5,
  data,
  propertyId,
  isOpen: defaultIsOpen = false,
}) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <>
      <Wrapper>
        <Header>
          <Title>{title}</Title>
          <DetailsLink className="h-link" onClick={() => setIsOpen(true)}>
            Detalhes
          </DetailsLink>
        </Header>
        <Content>
          <Bar min={0} max={10} value={value} />
        </Content>
      </Wrapper>
      <Modal
        propertyId={propertyId}
        isOpen={isOpen}
        data={data}
        handleClose={() => setIsOpen(false)}
      />
    </>
  );
}

export default RatingData;
