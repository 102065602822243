import React, { useContext, useEffect, useRef } from 'react';
import { MdSearch, MdClose } from 'react-icons/md';
import { Wrapper, Btn, Search } from './styles';
import { MainHeaderContext } from 'components/MainHeader2';

const MenuSearch = () => {
  const wrapper = useRef(null);
  const { searchOpen, toggleSearchOpen } = useContext(MainHeaderContext);

  useEffect(() => {
    if (wrapper.current) {
      const $input = wrapper.current.querySelector('input');

      if ($input) {
        $input.focus();
      }
    }
  }, [wrapper, searchOpen]);

  return (
    <Wrapper className="js-search" ref={wrapper}>
      <Btn onClick={toggleSearchOpen}>
        {searchOpen ? (
          <MdClose />
        ) : (
          <span>
            <MdSearch /> Pesquisar
          </span>
        )}
      </Btn>
      {searchOpen && <Search />}
    </Wrapper>
  );
};

export default MenuSearch;
