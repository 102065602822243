import React, { createContext, useCallback, useMemo } from 'react';
import { useRequest } from 'hooks/useRequest';
import { getList } from 'services/user';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector, changeUser } from 'modules/login';
import { useCan } from 'hooks/useCan';

export const UserContext = createContext({
  currentUser: null,
  originalUser: null,
  canChangeUser: null,
  users: [],
  handleChangeUser: null
});

export default function UserProvider({ children }) {
  const currentUser = useSelector(currentUserSelector);
  const originalUser = localStorage.getItem('original-user');

  const isRealEstateBlocked = currentUser?.real_estate?.blocked;

  const hasPermissionToChange = useCan({
    run: 'CHANGE_CURRENT_USER',
    permissions: originalUser?.permissions
  });

  const { data, isFetching } = useRequest({
    request: getList
  });

  const dispatch = useDispatch();

  const handleChangeUser = useCallback(
    id => () => {
      dispatch(changeUser(id));
    },
    []
  );

  const users = useMemo(() => {
    return data?.filter(({ id }) => id !== currentUser.id);
  }, [data]);

  const canChangeUser = useCallback(() => {
    return !isRealEstateBlocked && hasPermissionToChange && users?.length > 0;
  }, [hasPermissionToChange, users, isRealEstateBlocked]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        originalUser,
        canChangeUser,
        users,
        isFetching,
        handleChangeUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
