import { ListAside } from 'components/ListAside';
import { useAside } from 'pages/CondoStore/hooks/useAside';
import { useId } from 'pages/CondoStore/hooks/useId';
import { usePath } from 'pages/CondoStore/hooks/usePath';
import { useState } from 'react';
import { useRouteMatch } from 'react-router';
import Link from '../Link';
import { Title, Wrapper } from './styles';

const Aside = () => {
  const [activeSection, setActiveSection] = useState('informations');

  const { path: basePath } = useRouteMatch();
  const { pages } = useAside();
  const { id } = useId();

  const { path: currentPath } = usePath();

  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) section.scrollIntoView({ behavior: 'smooth' });
  }

  const handleClickSection = (section) => {
    handleScrollToSection(section);
    setActiveSection(section);
  }

  return (
    <Wrapper className="Aside">
      <Title>Passo a passo:</Title>
      <ListAside>
        {pages.map(({ name, hasError, isHidden, isDisabled, path, section }) => {
          if (section && currentPath === '/informations') {
            return (
              <Link
                as="a"
                hasError={hasError}
                isHidden={isHidden}
                isDisabled={isDisabled}
                className={activeSection === section && currentPath === '/informations' ? 'isActive' : undefined}
                onClick={() => handleClickSection(section)}
              >
                {name}
              </Link>
            );
          }

          return (
            <Link
              hasError={hasError}
              isHidden={isHidden}
              isDisabled={isDisabled}
              to={`${basePath}${path}${id ? `/${id}` : ''}${section ? `#${section}` : ''}`}
              onClick={() => {
                if (section) setActiveSection(section);
              }}
            >
              {name}
            </Link>
          );
        })}
      </ListAside>
    </Wrapper>
  );
}

export default Aside;
