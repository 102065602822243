import React, { useCallback } from 'react';
import { Wrapper, Content, Text } from './styles';
import StageLabel from 'components/StageLabel';
import { MdPerson } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { openModalPerson } from 'modules/modal';

const PropertyBar = ({ person: p, stage, afterClose }) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(
      openModalPerson(p, {
        afterClose: () => {
          if (afterClose) afterClose();
        }
      })
    );
  }, [p, dispatch]);

  return (
    <Wrapper onClick={onClick}>
      <Content>
        <Text>
          <MdPerson /> Cliente - <span className="h-link">{p?.name}</span>
        </Text>
      </Content>
      <StageLabel stage={stage} />
    </Wrapper>
  );
};

export default PropertyBar;
