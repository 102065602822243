import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select-plus';
import styled from 'styled-components';

// import from './styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
`;

const Title = styled.div`
  font-size: 11px;
  line-height: 11px;
  margin-bottom: 3px;
  color: #757575;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 14px;
`;

const valueRenderer = (group) => {
  return (
    <Wrapper>
      <Title>{group.group.name}</Title>
      <Text>{group.name}</Text>
    </Wrapper>
  );
};

class SelectStage extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    value: PropTypes.string,
    isLoading: PropTypes.bool,

    // Handlers
    onChange: PropTypes.func,
  };

  static defaultProps = {
    options: [],
    value: null,
    isLoading: false,

    // Handlers
    onChange: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  onChange = (option) => {
    const { onChange } = this.props;
    if (onChange) onChange(option);
    this.setState({ value: option.stage_id });
  };

  render() {
    const { options, isLoading, style } = this.props;
    const { value } = this.state;

    return (
      <Select
        isLoading={isLoading}
        clearable={false}
        value={value}
        options={options}
        onChange={this.onChange}
        labelKey="name"
        valueKey="stage_id"
        placeholder="Selecione uma posição"
        style={{ textAlign: 'left', ...style }}
        // valueRenderer={(group) => {
        //   return `${group.group.name} -> ${group.name}`;
        // }}
        valueRenderer={valueRenderer}
      />
    );
  }
}

export default SelectStage;
