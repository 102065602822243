import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import FormBox from 'components/FormBox';
import Popover from 'components/Popover';
import FormEstablishments from 'components/FormEstablishments';

import ListCheck from 'components/ListCheck';
// Modules
import {
  establishmentsSelector,
  handleFormEstablishment,
  getEstablishments,
  establishmentsMetaSelector,
} from 'modules/establishments';
import { USER_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';
import { detectVisibility, undetectVisibility } from 'lib/helpers';
import Loading from 'components/Loading';

const propTypes = {
  hasCondo: PropTypes.bool
};

const defaultProps = {
  hasCondo: false
};

function Establishments({ hasCondo }) {
  const dispatch = useDispatch();
  const establishments = useSelector(establishmentsSelector);
  const { isFetching } = useSelector(establishmentsMetaSelector);

  const title = useMemo(
    () =>
      hasCondo
        ? 'Estabelecimentos próximos ao condomínio'
        : 'Estabelecimentos próximos ao imóvel',
    [hasCondo]
  );

  const onSubmitEstablishment = useCallback(
    (...params) => dispatch(handleFormEstablishment(...params)),
    [dispatch]
  );

  const handleFocusPage = useCallback(() => {
    const handle = detectVisibility(isVisible => {
      if (!isVisible) {
        dispatch(getEstablishments());

        undetectVisibility(handle);
      }
    });
  }, [dispatch]);

  return (
    <FormBox
      title={title}
      className="js-establishments"
      actions={
        <Can
          run={USER_PERMISSIONS.EDIT_ESTABLISHMENTS}
          yes={() => (
            <>
              <Button
                size="medium"
                className="h-margin-right--15"
                tagName="a"
                href="/config/registrations/establishments"
                onClick={handleFocusPage}
                target="_blank"
              >
                Gerenciar
              </Button>
              <Popover
                onSubmit={onSubmitEstablishment}
                component={FormEstablishments}
              >
                <Button type="button" size="medium" color="secondary">
                  Cadastrar Estabelecimento
                </Button>
              </Popover>
            </>
          )}
          no={() => (
            <span data-tip="Você não tem permissão para gerenciar estabelecimentos">
              <Button disabled type="button" size="medium">
                Cadastrar Estabelecimento
              </Button>
            </span>
          )}
        />
      }
    >
      <Loading isVisible={isFetching} isFullComponent />
      <ListCheck name="establishments" data={establishments} />
    </FormBox>
  );
}

Establishments.defaultProps = defaultProps;
Establishments.propTypes = propTypes;

export default memo(Establishments);
