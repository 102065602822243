import styled from 'styled-components';
import Nodata from 'components/Nodata';

export const NoInformation = styled(Nodata)`
  img {
    margin-right: 25px;
  }

  .Nodata__container {
    max-width: 196px;
  }
`;
