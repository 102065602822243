import React from 'react';
// Component
import Table from 'components/Table';
import PropertyItem from '../PropertyItem';

function PropertyTable({ data, meta }) {
  return (
    <Table
      responsive
      data={data}
      isFetching={false}
      renderTitle={() => (
        <tr>
          <th>Tipo de imóvel</th>
          <th>Venda</th>
          <th>Locação</th>
          <th>Temporada</th>
          <th>Total</th>
        </tr>
      )}
      renderItem={data => (
        <PropertyItem key={`item-${data.title}`} data={data} />
      )}
      renderFooter={() => {
        return (
          <tr>
            <td>
              <strong>TOTAL</strong>
            </td>
            <td>{meta?.totals?.venda}</td>
            <td>{meta?.totals?.locacao}</td>
            <td>{meta?.totals?.temporada}</td>
            <td>{meta?.totals?.total}</td>
          </tr>
        );
      }}
    />
  );
}

export default PropertyTable;
