import React from 'react';
import Modal from 'react-modal';
import { reduxForm, Field } from 'redux-form';

// Common Components
import { ModalFooter, ModalTemplate } from 'components/Modal';

// Services
import * as watermarkService from 'services/watermark';
import { Checkbox } from 'components/Form';
import Button from 'components/Button';

function ModalWatermarkRemove({
  handleSubmit,
  modalConfig,
  isOpen,
  handleClose,
  modalType,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate title="Desativar Marca D’água" handleClose={handleClose}>
          Tem certeza que deseja desativar a marca d’água? esse processo poderá
          levar alguns minutos. Se você deseja apenas trocar sua marca d’água,
          volte e clique no botão laranja “Enviar outra imagem”.
          <div>
            <Field
              type="checkbox"
              label="Remover marca d'água de todas as imagens?"
              name="should_remove"
              component={Checkbox}
              className="h-margin-top--15"
            />
          </div>
          <ModalFooter>
            <Button color="white" colorText="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="danger">
              Desativar Marca D’água
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </form>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalWatermarkRemove',
  initialValues: {
    should_remove: false,
  },
  onSubmit: (values) => {
    return watermarkService.remove(values);
  },
  onSubmitSuccess: (response, dispatch, props) => {
    if (props.onSuccess) props.onSuccess(response);
    props.handleClose();
  },
})(ModalWatermarkRemove);
