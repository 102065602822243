import styled from 'styled-components';
import { em as _em, readableColor } from 'polished';
import { HEIGHT, WIDTH } from '../../constants';
import theme from 'components/ModelsPost/theme';

export const em = (pixels) => _em(pixels, 32);
export const readable = (color) => {
  try {
    return readableColor(color);
  } catch {
    return '#000';
  }
};

export const Wrapper = styled.div`
  position: relative;
  width: ${em(WIDTH)};
  height: ${em(HEIGHT)};
  font-family: 'Montserrat', sans-serif;
  color: ${(p) => p.theme.mainColor};
  font-size: ${(p) => p.theme.fontSize};
`;

export const Image = styled.img`
  position: absolute;
  right: 0;
  width: ${em(838)};
  height: inherit;
  object-fit: cover;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: ${em(364)};
  height: 100%;
  background-color: ${(p) => p.theme.backgroundColor};
`;

export const BoxInner = styled.div`
  position: relative;
  flex: 1 0;
  display: flex;
  flex-direction: column;
`;
export const BoxHeader = styled.div`
  min-width: calc(100% + 60px);
  display: grid;
  place-items: center;
  padding: ${em(30)};
  margin-top: ${em(100)};
  background: ${(p) => p.theme.mainColor};
  color: ${(p) => readable(p?.theme?.mainColor)};
  margin-bottom: ${em(25)};
`;

export const Title = styled.div`
  font-size: ${em(40)};
  white-space: pre-wrap;
  text-align: center;
`;
export const Price = styled.div`
  font-size: ${em(26)};
  text-align: center;
  color: ${(p) => readable(p?.theme?.backgroundColor)};
`;
export const Description = styled.div`
  text-align: center;
  font-size: ${em(20)};
  margin: ${em(35)};
  color: ${(p) => readable(p?.theme?.backgroundColor)};

  > div {
    margin: ${em(30)};
  }
`;
export const Footer = styled.div`
  padding-bottom: ${em(50)};

  img {
    height: ${em(theme.logoSize, 32)};
    margin: 0 auto;
  }
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;
