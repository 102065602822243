import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 50px 0 30px 0;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: bold;
`;

export const Content = styled.div`
  max-width: 320px;

  p {
    font-size: 14px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
`;

export const ExpirationWarning = styled.p`
  font-size: 12px;
  font-style: italic;
`;
