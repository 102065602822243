import WebFont from 'webfontloader';
import { AVALIABLE_FONTS } from 'constants/constants';

/**
 * Carrega todas as fontes que são necessárias
 */
export const loadAvaliableFonts = () => {
  WebFont.load({
    google: {
      families: [
        ...AVALIABLE_FONTS.map(font => font.family),
        'Quicksand:500,700'
      ]
    }
  });
};
