import api from '../';
// Helpers
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega as contas locais
 * @param params
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const getLocalAccounts = params =>
  api.getList('integrations/mail-server/mailboxes', params);

/**
 * Pega todas as caixas de e-mail que são redirecionadas
 * @param params
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const getRedirectedAccounts = params =>
  api.getList('integrations/mail-server/aliases/externals', params);

export const getRedirectedAccount = address =>
  api.getOne(`/integrations/mail-server/aliases/${address}`, '');

/**
 * Pega todos os alias
 * @param params
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const getAliases = params =>
  api.getList('integrations/mail-server/aliases/internals', params);

/**
 * Cria uma caixa de e-mail
 * @param values
 * @param values.domain {String|required}
 * @param values.name {String|required}
 * @param values.local_part {String|required}
 * @param values.destiny_mail {String}
 * @param values.password {String}
 * @param values.password_confirm {String}
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const createMailbox = values =>
  api
    .create('integrations/mail-server/mailboxes', values)
    .then(responseMessage('Caixa de e-mail criada'))
    .catch(responseMessage('Falha ao criar caixa de e-mail'));

export const updateGoTo = (address, goto) =>
  api
    .update(`integrations/mail-server/aliases/${address}`, { goto })
    .then(responseMessage('Endereço alterado'));

/**
 * Altera uma caixa de e-mail
 * @param {Object} values
 * @param {String|required} values.username
 * @param {String|required} values.password
 * @param {String|required} values.password_confirm
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const updateMailbox = values =>
  api
    .update(`integrations/mail-server/mailboxes/${values.username}`, values)
    .then(responseMessage('Senha alterada!'))
    .catch(responseMessage('Erro ao atualizar senha'));

/**
 * Remove uma caixa de e-mail
 * @param {String} username
 * @param {Object} config
 * @example
 * removeMailbox('username@tecimob.com.br', {  })
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const removeMailbox = (username, config) =>
  api
    .delete('integrations/mail-server/mailboxes', username, config)
    .then(responseMessage('Caixa de e-mail removida!'))
    .catch(responseMessage('Erro ao remover caixa de e-mail'));

/**
 * Cria um alias
 * @param {Object} values
 * @param {String|required} values.address
 * @param {String|required} values.goto
 * @param {String|required} values.domain
 * @param config
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const createAlias = (values, config) =>
  api
    .create('integrations/mail-server/aliases', values, config)
    .then(responseMessage('Alias criado'))
    .catch(responseMessage('Erro ao criar alias'));

/**
 * Remove um alias
 * @param
 */
export const removeAlias = address =>
  api
    .delete(`integrations/mail-server/aliases`, address)
    .then(responseMessage('Alias removido'))
    .catch(responseMessage('Erro ao remover alias'));
