import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

export const IconWrapper = styled.div`
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 6px;
  color: ${(p) => p.theme.color};
  margin-right: 10px;

  background: ${(p) => rgba(p.theme.color, 0.25)};
  display: grid;
  place-items: center;
  font-size: 22px;
  transition: box-shadow 0.2s ease-in-out;
  will-change: box-shadow;
`;

export const Container = styled.div``;
export const Title = styled.h1`
  font-size: 16px;
  font-weight: 600;
  color: #1a051d;
  margin-bottom: 3px;
  white-space: nowrap;
`;
export const Text = styled.p`
  font-size: 14px;
  color: #1a051d;
`;

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

export const Content = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
  z-index: 5;
`;

export const Wrapper = styled(motion.button).attrs(() => ({
  type: 'button',
  variants: item,
}))`
  position: relative;
  cursor: pointer;
  transition: all 0.3s;

  :before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: ${(p) => rgba(p.theme.color, 0.1)};
    z-index: 1;
    opacity: 0;
    transform: scale(0.9);
    visibility: hidden;
    transition: all 0.3s;
    border-radius: 6px;
    will-change: visibility, transform, opacity;
  }

  :hover {
    opacity: 1 !important;

    :before {
      opacity: 1;
      transform: scale(1);
      visibility: visible;
    }
  }

  :focus {
    color: red;
  }

  :hover ${IconWrapper} {
    box-shadow: 0 0 25px ${(p) => rgba(p.theme.color, 0.25)};
  }
`;
