import React, { useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Containers
import FieldSelectUser from 'containers/FieldSelectUser';
// Components
import Button from 'components/Button';
import { Checkbox, FormLabel } from 'components/Form';
import ModalFooter from 'components/Modal/components/ModalFooter';
import { STATUS_CRECI } from '../../../constants/constants';
import useFormValue from 'hooks/useFormValue';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormUserPropertyTransfer = ({
  currentUser,
  handleSubmit,
  submitting,
  handleClose,
}) => {
  const people = useFormValue('people');
  const propertiesUser = useFormValue('properties_user');
  const propertiesAgent = useFormValue('properties_agent');
  const propertyCount = currentUser.properties_exists;
  const propertiesAgentExists = currentUser.properties_agent_exists;
  const peopleCount = currentUser.people_exists;

  const msg = useMemo(() => {
    if (people && propertiesUser) {
      return `A quem será atribuída a responsabilidade pelos imóveis e clientes deste corretor?`;
    }

    if (people) {
      return `A quem será atribuída a responsabilidade pelos clientes deste corretor?`;
    }

    if (propertiesUser) {
      return `A quem será atribuída a responsabilidade pelos imóveis deste corretor?`;
    }

    return 'Selecione alguma opção acima';
  }, [people, propertiesUser]);

  return (
    <form onSubmit={handleSubmit}>
      <p>Selecione quais dados você quer transferir:</p>
      <Row>
        <Col xs={12}>
          <ul className="h-list--none" style={{ marginTop: '20px' }}>
            {propertiesAgentExists && (
              <li>
                <Field
                  type="checkbox"
                  label="Agenciador"
                  name="properties_agent"
                  component={Checkbox}
                />
                {propertiesAgent && (
                  <>
                    <Row>
                      <Col xs={12}>
                        <FormLabel>
                          A quem será atribuída a responsabilidade pelo
                          agenciamento dos imóveis dessa pessoa?
                        </FormLabel>
                      </Col>
                    </Row>
                    <Row>
                      <FieldSelectUser
                        label={null}
                        xs={6}
                        name="agent_id"
                        filter={(user) => user.id !== currentUser.id}
                        params={{ filter: {} }}
                      />
                    </Row>
                  </>
                )}
              </li>
            )}
            {!!propertyCount && (
              <li>
                <Field
                  type="checkbox"
                  label="Imóveis"
                  name="properties_user"
                  component={Checkbox}
                />
              </li>
            )}
            {!!peopleCount && (
              <li>
                <Field
                  type="checkbox"
                  label="Clientes/CRM"
                  name="people"
                  component={Checkbox}
                />
              </li>
            )}
          </ul>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormLabel>{msg}</FormLabel>
        </Col>
      </Row>
      <Row>
        <FieldSelectUser
          xs={6}
          label={null}
          name="user_id"
          filter={(user) =>
            user.id !== currentUser.id &&
            user.creci_status === STATUS_CRECI.VERIFIED
          }
          params={{ filter: {} }}
        />
      </Row>

      <ModalFooter>
        <Button
          type="button"
          color="white"
          colorText="secondary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Transferir Dados
        </Button>
      </ModalFooter>
    </form>
  );
};

FormUserPropertyTransfer.defaultProps = defaultProps;
FormUserPropertyTransfer.propTypes = propTypes;

export default reduxForm({
  form: 'FormUserPropertyTransfer',
  enableReinitialize: true,
  validate: (values) => {
    let errors = {};

    if (values.properties_agent && !values.agent_id) {
      errors.agent_id = ['Campo obrigatório'];
    }

    if (values.properties_user && !values.user_id) {
      errors.user_id = ['Campo obrigatório'];
    }

    return errors;
  },
})(FormUserPropertyTransfer);
