import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { reduxForm } from 'redux-form';

// Components
import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import FieldFacebookBusiness from 'containers/FieldFacebookBusiness';
import FieldFacebookCatalog from 'containers/FieldFacebookCatalog';
import FieldFacebookPages from 'containers/FieldFacebookPagesCatalog';
import { useFacebookStore } from 'containers/ModalRoot/ModalFacebookStore/FacebookStoreContext';
import icone from './icone.png';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function Form({ handleSubmit, handleClose, submitting }) {
  const { facebook, setPages, setCatalogs } = useFacebookStore();

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={5}>
          <img src={icone} alt="" style={{ marginTop: '-15px' }} />
        </Col>
        <Col xs={7}>
          <p>Devemos integrar com:</p>
          <Row className="h-margin-top--15">
            <FieldFacebookBusiness facebook={facebook} />
            <FieldFacebookPages
              facebook={facebook}
              onLoad={(pages) => setPages(pages)}
            />
            <FieldFacebookCatalog
              facebook={facebook}
              onLoad={(catalogs) => setCatalogs(catalogs)}
            />
          </Row>
        </Col>
      </Row>

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Voltar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Conectar
        </Button>
      </ModalFooter>
    </form>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

export default reduxForm({
  form: 'FormFacebookStore',
  enableReinitialize: true,
  validate: (values) => {
    let errors = {};

    if (!values.number) errors.number = ['Campo obrigatório'];
    if (!values.page_id) errors.page_id = ['Campo obrigatório'];

    return errors;
  },
})(Form);
