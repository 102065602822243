import styled from 'styled-components';

export const ListLabels = styled.div`
  display: flex;
  margin: -5px;
`;

export const Label = styled.button`
  margin: 5px;
  display: inline-block;
  color: #0084f4;
  background: #d5e9fa;
  padding: 6px 10px;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: #c8e6ff;
  }
`;
