import PropTypes from 'prop-types';
import qs from 'qs';
import { useState } from 'react';
// Components
import Button from 'components/Button';
// Services
import { searchPropertyFilter } from 'services/profileSearch';

function SearchProfile({
  values = {},
  profileId,
  personId,
  children,
  onSuccess,
  cityId,
  ...props
}) {
  const [isFetching, setIsFetching] = useState(false);

  const fetchProfile = async () => {
    setIsFetching(true);
    const response = await searchPropertyFilter({
      personId,
      profileId,
      cityId,
    });
    setIsFetching(false);
    return response;
  };

  const onClick = async () => {
    const { data } = await fetchProfile();

    const searchString = qs.stringify(
      { filter: data, limit: 50, offset: 1, sort: '-calculated_price' },
      {
        addQueryPrefix: true,
      }
    );

    window.open(`/properties/search${searchString}`);

    if (onSuccess) onSuccess();
  };

  return (
    <Button disabled={isFetching} onClick={onClick} {...props}>
      {children}
    </Button>
  );
}

SearchProfile.defaultProps = {
  profileId: null,
  personId: null,
  children: 'Buscar',
};

SearchProfile.propTypes = {
  profileId: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default SearchProfile;
