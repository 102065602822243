import React, { useEffect } from 'react';

import Wrapper from 'components/Wrapper';
// import { useDispatch } from 'react-redux';
// import { Facebook, Twitter } from 'components/ModelsPost';
// import { PROPS } from 'components/ModelsPost/mock';
// import { dispatcherProperty } from 'pages/Tests/pages/DefaultTests/helpers';
import { Screens } from 'containers/CreciAlert/screens';

function DefaultTests() {
  // const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(dispatcherProperty);
  }, []);

  return (
    <Wrapper full>
      <div>
        <div>
          <Screens.StartValidation />
        </div>
        <div>
          <Screens.FormValidation />
        </div>
        <div>
          <Screens.CheckingCreci />
        </div>
        <div>
          <Screens.Success />
        </div>
        <div>
          <Screens.Fail />
        </div>
      </div>
      {/*<Twitter.Model4 {...PROPS} />*/}
      {/*<Facebook.Model4 {...PROPS} />*/}
      {/*<PreviewPost*/}
      {/*  component={MODELS.Stories.model.Model3}*/}
      {/*  componentProps={PROPS}*/}
      {/*/>*/}
    </Wrapper>
  );
}

DefaultTests.defaultProps = {};
DefaultTests.propTypes = {};

export default DefaultTests;
