import PropTypes from 'prop-types';
// Hooks
import { useCan } from 'hooks/useCan';
// Helpers
import { myself, others } from 'constants/rules';

function Can({ run, permissions, children, yes, no }) {
  const canRun = useCan({ permissions, run });
  if (typeof children === 'function') return children(canRun);
  if (children) return canRun ? children : null;
  if (!yes || !no) return null;
  return canRun ? yes() : no();
}

Can.propTypes = {
  run: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  yes: PropTypes.func,
  no: PropTypes.func
};

Can.defaultProps = {
  run: null,
  permissions: null,
  yes: null,
  no: null
};

export { myself, others };
export default Can;
