import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import FormGroup from 'components/Form/components/FormGroup';
// import { Container } from './styles';
import styles from './styles';

const AsyncSelect = ({
  xs,
  sm,
  md,
  lg,
  required,
  input,
  label,
  disabled,
  formGroupClassName,
  meta,
  loadOptions,
  isLoading,
  hidden,
  valueKey = 'id',
  labelKey = 'title',
  options,
  getOptionByValue,
  noOptionsMessage = () => 'Nenhum resultado',
  placeholder = 'Selecione',
  ...rest
}) => {
  const [currentOptions, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);

  const getOptionValue = useCallback(
    item => {
      try {
        return item[valueKey];
      } catch {
        return null;
      }
    },
    [valueKey]
  );

  const getOptionLabel = useCallback(
    item => {
      try {
        return item[labelKey];
      } catch {
        return null;
      }
    },
    [labelKey]
  );

  const handleChange = item => {
    const value = getOptionValue(item);
    setSelected(item);
    return input.onChange(value);
  };

  const value = useMemo(() => {
    try {
      return selected;
    } catch {
      return null;
    }
  }, [input.value, selected]);

  useEffect(() => {
    const fetchOne = async () => {
      try {
        const hasOption = !!currentOptions.find(
          item => item[valueKey] === input.value
        );

        if (input.value && !hasOption) {
          const { data } = await getOptionByValue(input.value);
          setSelected({
            [valueKey]: data[valueKey],
            [labelKey]: data[labelKey],
            ...data
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchOne();
  }, [input.value, currentOptions]);

  useEffect(() => {
    if (!input.value) {
      setSelected(null);
    }
  }, [input.value]);

  return (
    <FormGroup
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      labelText={label}
      className={formGroupClassName}
      isRequired={required}
      isDisabled={disabled}
      input={input}
      meta={meta}
      hidden={hidden}
      isLoading={isLoading}
    >
      <AsyncPaginate
        {...rest}
        isDisabled={disabled}
        styles={styles}
        value={value}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        initialOptions={currentOptions}
        noOptionsMessage={noOptionsMessage}
        placeholder={placeholder}
        loadOptions={(search, options, config) => {
          setOptions(options);
          return loadOptions(search, options, config);
        }}
        onChange={handleChange}
        onBlur={e => e.preventDefault()}
      />
    </FormGroup>
  );
};

export default AsyncSelect;
