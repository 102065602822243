import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// Components
import Button from 'components/Button';
import CardProperty from 'components/CardProperty';
import Container from 'components/Container';
import MainTitle from 'components/MainTitle';
import Nodata from 'components/Nodata';
import Wrapper from 'components/Wrapper';
// Assets
import house from 'assets/img/noData/house.svg';
import Pagination from 'components/Pagination';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { openModalProperty, openModalSMTPConfig } from 'modules/modal';
import { useDispatch } from 'react-redux';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),

  // Event Handlers
  handleSendAllEmail: PropTypes.func,
  handleShowPeople: PropTypes.func,
  handleSendEmail: PropTypes.func,
  handleRemoveProfile: PropTypes.func,
};
const defaultProps = {
  data: [],

  // Event Handlers
  handleSendAllEmail: null,
  handleShowPeople: null,
  handleSendEmail: null,
  handleRemoveProfile: null,
};

const renderActions = (property, sendProperty, handleClickDischart) => {
  let isDisabled = false;

  if (property.is_blocked || property.status !== 'Disponível')
    isDisabled = true;

  return (
    <>
      <Button
        onClick={(e) => {
          if (isDisabled) {
            e.preventDefault();
          }
        }}
        disabled={isDisabled}
        size="small"
        color="white"
        colorText="secondary"
        to={`/crm/matched/show/${property.id}`}
      >
        Ver clientes compatíveis ({property.new_people_matched_count})
      </Button>
      <Button
        disabled={isDisabled}
        size="small"
        color="secondary"
        onClick={sendProperty(property)}
      >
        Enviar por E-mail ({property.new_people_matched_count})
      </Button>
      <Button
        size="small"
        color="danger"
        onClick={handleClickDischart(property)}
      >
        Descartar imóvel
      </Button>
    </>
  );
};

const ListPropertyMatched = ({
  count,
  data: properties,
  handleSendAllEmail,
  handleShowPeople,
  sendPeople,
  sendProperty,
  sendAllProperties,
  handleClickDischart,
  handleDiscardAll,
  pagination,
  onPageChange,
}) => {
  const dispatch = useDispatch();

  const handleClickConfigSMTP = () => {
    dispatch(openModalSMTPConfig());
  };

  const handleClickProperty = useCallback(
    (property) => () => {
      dispatch(openModalProperty({ property }));
    },
    []
  );

  const handleClickDiscardAll = useCallback(() => {
    dispatch(
      openConfirmation({
        title: `Descartar ${properties.length} imóveis compatíveis`,
        text: 'Você deseja realmente excluir todos os imóveis compatíveis',
        submitButtonText: 'Descartar',
        request: handleDiscardAll,
      })
    );
  }, [properties]);

  const canDiscardAll = useMemo(() => {
    return properties.length >= 30;
  }, [properties]);

  const countMessage = useMemo(() => {
    if (count.value === 0)
      return `Nenhuma nutrição enviada nos últimos 30 dias. `;

    return `Enviado ${count.value} nutrições nos últimos 30 dias. `;
  }, [count]);

  return (
    <Wrapper.inner>
      <Wrapper.container>
        <MainTitle
          title="Imóveis Compatíveis"
          text="Separamos os imóveis certos para os clientes com perfil de busca compatível."
        >
          {canDiscardAll && (
            <Button onClick={handleClickDiscardAll} color="danger">
              Descartar todos
            </Button>
          )}
        </MainTitle>
        <Container>
          {!properties.length ? (
            <Nodata
              image={house}
              title="Nenhum imóvel compatível"
              text={
                <>
                  Quando algum imóvel for compatível com o perfil
                  <br /> do seu cliente, ele aparecerá aqui.
                </>
              }
            />
          ) : (
            <TransitionGroup>
              {properties.map((property) => (
                <CSSTransition
                  key={property.id}
                  timeout={300}
                  classNames="fade"
                >
                  <CardProperty
                    type="horizontal"
                    property={property}
                    message={`Cadastrado em ${property?.created_at}`}
                    handleClickProperty={handleClickProperty(property)}
                  >
                    {renderActions(property, sendProperty, handleClickDischart)}
                  </CardProperty>
                </CSSTransition>
              ))}
            </TransitionGroup>
          )}
          <Pagination pagination={pagination} onPageChange={onPageChange} />
        </Container>
        <div className="h-margin-top--15">
          {countMessage}{' '}
          <button className="h-link" onClick={handleClickConfigSMTP}>
            Configurar um SMTP externo.
          </button>
        </div>
      </Wrapper.container>
    </Wrapper.inner>
  );
};

ListPropertyMatched.propTypes = propTypes;
ListPropertyMatched.defaultProps = defaultProps;

export default ListPropertyMatched;
