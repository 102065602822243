import React, { useCallback } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useHistory } from 'react-router';
import Modal from 'react-modal';
// Components
import { ModalTemplate } from '../../../components/Modal';
// Styles
import { Btn, Layout } from './styles';
// Assets
import layout1 from './assets/1.png';
import layout2 from './assets/2.png';
import { useRouteMatch } from 'react-router-dom';

export default function ModalPostLayout({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
}) {
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleClickLayout = useCallback((layout) => () => {
    history.push(`/site/content-site/posts/store/${layout}`);
    handleClose();
  });

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Layout da postagem" handleClose={handleClose}>
        <p className="h-color--primary">
          Escolha que estrutura de layout usar:
        </p>
        <Row>
          <Col xs={6}>
            <Layout onClick={handleClickLayout('Post1')}>
              <img src={layout1} alt="Layout 1" />
              <Btn>Selecionar</Btn>
            </Layout>
          </Col>
          <Col xs={6}>
            <Layout onClick={handleClickLayout('Post2')}>
              <img src={layout2} alt="Layout 2" />
              <Btn>Selecionar</Btn>
            </Layout>
          </Col>
        </Row>
      </ModalTemplate>
    </Modal>
  );
}
