import Button from 'components/Button';
import Container from '../../../../../../../../components/Container';
import Loading from '../../../../../../../../components/Loading';
import React from 'react';

function TableZones({ data, onEdit, onDelete, isFetching }) {
  if (isFetching) {
    return (
      <Container>
        <Loading isVisible isBlock />
      </Container>
    );
  }

  return (
    <table className="Table">
      <thead>
        <tr>
          <th width={1}>UF</th>
          <th width={160}>Cidade</th>
          <th>Nome</th>
          <th>Quantidade</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((zone) => (
            <tr key={zone.id} className="h-hover">
              <td>{zone?.city?.state?.acronym}</td>
              <td>{zone.city?.name}</td>
              <td>{zone.name}</td>
              <td>{zone.properties_count}</td>
              <td className="Table__cell--small">
                <div className="h-hover__show">
                  <Button
                    color="white"
                    colorText="secondary"
                    onClick={onEdit(zone)}
                  >
                    Editar
                  </Button>
                  <Button color="danger" onClick={onDelete(zone)}>
                    Remover
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default TableZones;
