import React, { useMemo } from 'react';
import { CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, BarChart, Bar } from 'recharts';
import { getTotals } from 'lib/report-helpers';

const config = { labelKey: 'period', valueKey: 'total', itemsKey: 'periods' };

const PeopleChart = ({ width, height, data, meta, selected }) => {
  const chartData = useMemo(() => {
    try {
      if (!selected || selected.length <= 0) return getTotals(data, config);

      return getTotals(
        data.filter(({ type }) => selected.includes(type)),
        config
      );
    } catch (e) {
      return [];
    }
  }, [data, selected]);

  return (
    <div style={{ width, height }}>
      <ResponsiveContainer>
        <BarChart
          width={600}
          height={200}
          data={chartData}
          margin={{ left: 25, right: 25 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="total" name="Total" key={data.period} fill="#0088fe" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PeopleChart;
