import { formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';

const selector = formValueSelector('FilterDealHistory');

export const useFilter = () => {
  return useSelector((state) =>
    selector(
      state,
      'by_inactive_status',
      'updated_greater_equals',
      'updated_lower_equals'
    )
  );
};
