import React, { useCallback } from 'react';

export default function CmdControlClick({
  onCmdControlClick,
  onClick,
  component,
  children,
  ...rest
}) {
  const Component = component || 'div';

  const handleClick = useCallback(
    e => {
      if (e.ctrlKey || e.metaKey) return onCmdControlClick(e);
      onClick(e);
    },
    [onCmdControlClick, onClick]
  );

  return (
    <Component {...rest} onClick={handleClick}>
      {children}
    </Component>
  );
}
