import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormBoxRow from './components/FormBoxRow';
import FormBoxHeader from './components/FormBoxHeader';

const defaultProps = {
  isPrivate: false,
  title: '',
  titleBorder: true,
  actions: null,
  children: null,
  styleHeader: null
};

const propTypes = {
  isPrivate: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func
  ]),
  titleBorder: PropTypes.bool,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  children: PropTypes.node,
  styleHeader: PropTypes.object
};

const FormBox = ({
  styleHeader,
  isPrivate,
  title,
  text,
  titleBorder,
  actions,
  children,
  className,
  ...rest
}) => (
  <div
    {...rest}
    className={classnames(className, 'FormBox', {
      'FormBox--private': isPrivate
    })}
  >
    {typeof title === 'function' ? (
      title()
    ) : (
      <FormBoxHeader
        style={styleHeader}
        size="large"
        actions={actions}
        isBordered={titleBorder}
        text={text}
      >
        {title}
      </FormBoxHeader>
    )}
    <div className="FormBox__container">{children}</div>
  </div>
);

FormBox.defaultProps = defaultProps;
FormBox.propTypes = propTypes;

FormBox.header = FormBoxHeader;
FormBox.row = FormBoxRow;

export { FormBoxRow, FormBoxHeader };
export default FormBox;
