import { useEffect, useState } from 'react';
import Orulo from 'lib/Socket/Orulo';
import Alert from 'react-s-alert';
import * as oruloService from 'services/orulo';

export function useOruloIntegration() {
  const [orulo, setOrulo] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  async function fetchOrulo() {
    try {
      setIsFetching(true);
      const { data } = await oruloService.getToken();
      setOrulo(data);
    } finally {
      setIsFetching(false);
    }
  }

  function setMetadata(metadata) {
    setOrulo(value => {
      return {
        ...value,
        meta_data: {
          ...value?.meta_data,
          ...metadata
        }
      };
    });
  }

  function setIsFinished() {
    Alert.success('Todos os imóveis foram importados!');

    setOrulo(value => {
      return {
        ...value,
        is_importing: false
      };
    });
  }

  useEffect(() => {
    fetchOrulo();
  }, []);

  useEffect(() => {
    const socket = new Orulo();

    socket.onBuildingImported(metaData => {
      setMetadata(metaData);
    });

    socket.onImportFinished(response => {
      setIsFinished();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return { orulo, isFetching, fetchOrulo };
}
