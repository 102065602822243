import { useCallback } from 'react';
import Modal from 'react-modal';
// Services
import * as oruloService from 'services/orulo';
// Common Components
import { ModalTemplate } from 'components/Modal';
import FormConnectOrulo from './components/FormConnectOrulo';

function ModalOrulo({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onSubmitFail,
  onSubmitSuccess,
}) {
  const handleSubmit = useCallback((values) => {
    return oruloService.login(values);
  }, []);

  const handleSuccess = useCallback(() => {
    if (onSubmitSuccess) onSubmitSuccess();

    // Fecha a modal quando da certo o request
    handleClose();
  }, []);

  const handleFail = useCallback(() => {
    if (onSubmitFail) onSubmitFail();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Conectar-se ao órulo"
        handleClose={handleClose}
      >
        <FormConnectOrulo
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSuccess}
          onSubmitFail={handleFail}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalOrulo;
