import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import { openModal } from 'modules/modal';
import { useMemo } from 'react';
import { useConfigAjustAccount } from './hooks/useConfig';
import FormAjustAccount from './components/FormAjustAccount';
import { useAjustAccount } from '../../hooks/useAccounts';

export const openModalAjustAccount =
  ({ data, type = 1, onSuccess }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalAjustAccount',
        size: 290,
        position: 'center',
        props: {
          data,
          type,
          onSuccess,
        },
      })
    );
  };

export function ModalAjustAccount({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  data,
  type,
  onSuccess,
}) {
  const config = useConfigAjustAccount({ type });
  const { ajustAccount } = useAjustAccount({ type });

  const initialValues = useMemo(() => {
    if (data) {
      return data;
    }
    return {};
  }, [data]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title={config.titleModal} handleClose={handleClose}>
        <FormAjustAccount
          data={data}
          initialValues={initialValues}
          config={config}
          handleClose={handleClose}
          onSubmit={ajustAccount}
          onSubmitSuccess={(res) => {
            if (onSuccess) onSuccess(res);
            handleClose();
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}
