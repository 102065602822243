import React, { useCallback, useMemo, useState } from 'react';
import Lightbox from 'react-images';
import PropTypes from 'prop-types';
import GalleryList from './components/GalleryList';
// Constants
import { PROPERTY_PHOTO } from '../../constants/shapes';
import { imageChangeSize } from 'lib/image-helpers';

const style = {
  lightBox: {
    container: {
      background: 'rgba(62, 88, 124, 0.85)'
    },
    arrow: {
      background: 'none'
    }
  }
};

const propTypes = {
  axis: PropTypes.string.isRequired,
  distance: PropTypes.number,
  images: PropTypes.arrayOf(PROPERTY_PHOTO),
  cols: PropTypes.object,
  hasFeatured: PropTypes.bool,

  // event handlers
  handleToggleEdit: PropTypes.func,
  handleSubmitEdit: PropTypes.func,
  handleChangeRotation: PropTypes.func,
  handleDeleteImage: PropTypes.func,

  // Sortable
  onSortEnd: PropTypes.func
};

const defaultProps = {
  axis: 'xy',
  distance: 5,
  images: [],
  cols: {
    xs: 2
  },
  hasFeatured: true,

  // event handlers
  handleToggleEdit: null,
  handleSubmitEdit: null,
  handleChangeRotation: null,
  handleDeleteImage: null,

  // Sortable
  onSortEnd: null
};

const GalleryCollection = ({ images, ...params }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const handleClickOpenById = useCallback(
    id => e => {
      const index = images.findIndex(image => image.id === id);

      setCurrentImage(index);
      setIsOpen(true);
    },
    []
  );

  const handleClickNext = useCallback(() => {
    setCurrentImage(prev => prev + 1);
  }, []);
  const handleClickPrev = useCallback(() => {
    setCurrentImage(prev => prev - 1);
  }, []);

  const handleClickThumbnail = useCallback(current => {
    setCurrentImage(current);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const lighboxImages = useMemo(() => {
    return images.map(({ file_url }) => ({
      src: imageChangeSize(file_url, 'large', 'cover'),
      srcset: [
        `${imageChangeSize(file_url, 'large', 'cover')} 1024w`,
        `${imageChangeSize(file_url, 'medium', 'cover')} 500w`,
        `${imageChangeSize(file_url, 'small', 'cover')} 320w`
      ]
    }));
  }, [images]);

  return (
    <>
      <GalleryList
        {...params}
        handleClickOpenById={handleClickOpenById}
        images={images}
        useDragHandle
      />
      <Lightbox
        imageCountSeparator=" de "
        currentImage={currentImage}
        onClickThumbnail={handleClickThumbnail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        images={lighboxImages}
        isOpen={isOpen}
        onClose={handleClose}
        backdropClosesModal
        showThumbnails
        theme={style.lightBox}
      />
    </>
  );
};

GalleryCollection.propTypes = propTypes;
GalleryCollection.defaultProps = defaultProps;

export default GalleryCollection;
