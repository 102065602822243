import qs from 'qs';
import { normalizeNumbers } from './formHelpers';

/**
 * Retorna a url padrão do whatsapp
 * @returns {string}
 */
export const WHATSAPP_BASE_URL = `https://api.whatsapp.com/send`;

export const whatsappSendUrl = (phone, text, ddi = '55') => {
  const params = qs.stringify({
    phone: `${ddi}${normalizeNumbers(phone)}`,
    text
  });

  return `${WHATSAPP_BASE_URL}?${params}`;
};
