import React from 'react';
import { reduxForm } from 'redux-form';

export const Form = ({ handleSubmit, children, change, ...props }) => (
  <form onSubmit={handleSubmit}>
    {children({
      ...props,
      change,
      clearField: fieldName => () => change(fieldName, '')
    })}
  </form>
);

export default reduxForm({
  form: 'FormShareProperty',
  enableReinitialize: true
})(Form);
