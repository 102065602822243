import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Components
import { ModalTemplate } from '../../../components/Modal';
// Modules
import {
  openModalExamplePropertyMail,
  openModalProperty
} from '../../../modules/modal';
import {
  fetchModels,
  fetchPeople,
  handleSubmitFormSaveModel,
  handleSubmitFormSendMail,
  isFetchingModelSelector,
  isFetchingPeopleSelector,
  modelsSelector,
  peopleSelector
} from './module';

import PropertySendMailForm from './components/PropertySendMailForm';

const propTypes = {
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

class ModalPropertySendMail extends Component {
  componentDidMount() {
    this.props.fetchPeople();
    this.props.fetchModels();
  }

  handleClose = () => {
    this.props.handleClose();
  };

  handleSubmitSendMailForm = values => {
    this.props.handleSubmitFormSendMail(values).then(() => {
      this.handleClose();
    });
  };

  handleOpenModalExamplePropertyMail = () => {
    this.props.openModalExamplePropertyMail(
      this.props.property || this.props.properties
    );
  };

  render() {
    const { modalConfig, isOpen, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={this.handleClose}
      >
        <ModalTemplate
          title="Enviar por e-mail"
          titleActions={
            <span
              className="h-color--secondary h5 h-pointer"
              onClick={this.handleOpenModalExamplePropertyMail}
            >
              Ver e-mail de exemplo
            </span>
          }
          handleClose={this.handleClose}
        >
          <p className="h-color--primary h-margin-bottom--15">
            <strong>Obs:</strong> Seus dados de contato e os dados do imóvel são
            preenchidos automaticamente no e-mail.
          </p>
          <PropertySendMailForm
            onSubmit={this.handleSubmitSendMailForm}
            handleSubmitSaveModelForm={this.props.handleSubmitFormSaveModel}
            handleClose={this.handleClose}
            people={this.props.people}
            models={this.props.models}
            isFetchingPeople={this.props.isFetchingPeople}
            isFetchingModel={this.props.isFetchingModel}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

ModalPropertySendMail.propTypes = propTypes;

const mapStateToProps = state => ({
  people: peopleSelector(state),
  isFetchingPeople: isFetchingPeopleSelector(state),
  models: modelsSelector(state),
  isFetchingModel: isFetchingModelSelector(state)
});

const mapDispatchToProps = {
  openModalProperty,
  openModalExamplePropertyMail,
  fetchPeople,
  fetchModels,
  handleSubmitFormSendMail,
  handleSubmitFormSaveModel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPropertySendMail);
