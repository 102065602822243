// Components
import { SelectMultiple } from 'components/Form';
import { parseSelect } from 'lib/formHelpers';
import { Field } from 'redux-form';

const CondoCharacteristicsField = ({ options }) => {
  if (!options || options?.length <= 0) return null;

  return (
    <Field
      name="by_condos_characteristic_id"
      label="Características do Cond."
      options={options}
      component={SelectMultiple}
      valueKey="id"
      labelKey="title"
      parse={parseSelect('id')}
      closeOnSelect={false}
    />
  );
};

export default CondoCharacteristicsField;
