import React from 'react';
import { reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
import { FieldDateRange } from 'components/Form';
import { WrapperDateRange } from 'pages/Reports/pages/PeoplePerPeriod/components/Form/styles';

// import { Wrapper } from './styles';

function CharPeriodFormNew({ handleSubmit, children }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        {children}
        <WrapperDateRange>
          <FieldDateRange
            canClear={false}
            label="Período"
            startDateName="start_date"
            endDateName="end_date"
          />
        </WrapperDateRange>
      </Row>
    </form>
  );
}

export default reduxForm({
  form: 'ChartPeriodForm',
})(CharPeriodFormNew);
