import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
import { formValueSelector, getFormValues, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import Modal from 'react-modal';
// Common Components
import { ModalTemplate } from 'components/Modal';
import FormGenerateLink from './components/FormGenerateLink';
import FormShareWhatsapp from './components/FormShareWhatsapp';
import FormShareEmail from './components/FormShareEmail';
import FormAddClient from './components/FormAddClient';
// Service
import { shareProperties, sharePropertiesUpdate } from 'services/properties';
import * as messagesServices from 'services/settings/messages';
import api from 'services';
// Modules
import { openModalPerson, openModalShareProperty } from 'modules/modal';
import { plural } from 'lib/text';
import { getPropertiesByIds } from 'containers/ModalRoot/ModalShareProperty/helpers';

const _initialValues = {
  should_send_whatsapp: false,
  should_create_calendar: false,
  should_create_deal: false,
  should_create_profiles: false,
  should_send_email: false,
  should_relate_person: true,
};

class ModalShareProperty extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    properties: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.oneOf(['generate-link', 'share', 'email', 'add-person']),
    onSubmitSuccess: PropTypes.func,
  };

  static defaultProps = {
    properties: [],
    people: null,
    type: 'share',
    onSubmitSuccess: null,
  };

  state = {
    initialValues: _initialValues,
    isFetching: true,
  };

  getDetailPageUrl(personId) {
    try {
      const { primary_domain_url } = localStorage.getItem('current-user');
      return `${primary_domain_url}/cliente/${personId}`;
    } catch {
      return '';
    }
  }

  async componentDidMount() {
    const { type, properties, addPerson } = this.props;
    const initialValues = await this.initialValues();

    // Seta os initialValues padrão do formulário
    if (initialValues)
      this.setState({
        initialValues: {
          ...initialValues,
          person_email: initialValues?.person?.email,
          person_cellphone_number: initialValues?.person?.cellphone_number,
        },
      });

    if (properties && !addPerson) {
      if (type === 'generate-link') {
        const {
          data: { id, url, title },
        } = await shareProperties(properties);

        this.setState({
          initialValues: {
            ...this.state.initialValues,
            properties_list_id: id,
            url,
            title,
          },
        });
      }
      if (type !== 'add-person') {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            properties_id: properties,
            message: initialValues.message,
          },
        });
      }
    }

    this.setState({ isFetching: false });
  }

  /**
   * Retorna o título da modal
   * @returns {string}
   */
  get title() {
    const { type, title, properties } = this.props;

    // Verifica se já esta sendo enviado um título
    // Se tiver já retorna o título que foi enviado
    if (title) return title;

    // Se não tenta pegar o título com base o tipo do compartilhamento
    switch (type) {
      case 'share':
        return 'Compartilhar seleção pelo WhatsApp';
      case 'email':
        return 'Compartilhar seleção por e-mail';
      case 'add-person':
        return plural(
          properties,
          'Adicionar imóvel ao cliente',
          'Adicionar imóveis ao cliente',
          false
        );
      default:
        return 'Compartilhar seleção';
    }
  }

  /**
   * Retorna o texto padrão
   * @returns {Promise<string>}
   */
  shareText = async () => {
    const { type, properties } = this.props;
    const {
      data: { share_properties_list, share_property },
    } = await messagesServices.getOne();

    const currentUser = localStorage.getItem('current-user');

    // 1 - Se apenas um imóvel estiver sendo compartilhado, a mensagem deve seguir esse padrão:
    // Texto escrito pelo corretor;
    // Link do imóvel;
    // Espaço
    // Abaixo segue um link com uma página com todos imóveis que selecionei para você:
    // Link da página do cliente

    let text = ``;

    if (properties.length > 1) text = share_properties_list;
    else text = share_property;

    if (currentUser.creci && type === 'share') {
      text += `

${currentUser.name}
CRECI: ${currentUser.creci}
${currentUser.email}
${currentUser.cellphone}`;
    }

    return text;
  };

  /**
   * Retorna os valores iniciais do formulário
   * @returns {Promise<object>}
   */
  initialValues = async () => {
    const { type, initialValues: values, properties, people } = this.props;

    // Seta os valores inicias padrão
    // que todos os formulários tem que ter
    let initialValues = {
      ..._initialValues,
      ...values,
    };

    if (type !== 'add-person') {
      // Pega o texto de compartilhamento
      const shareText = await this.shareText();

      // Inclue a mensagem padrão para o campo texto
      initialValues = {
        ...initialValues,
        message: initialValues.message
          ? initialValues.message
          : shareText || '',
      };
    } else {
      initialValues = {
        ...initialValues,
        properties_id: properties,
        people_id: people,
      };
    }

    // Sempre tem que retornar um
    // objeto contendo os valores iniciais
    return initialValues;
  };

  onSubmit = async ({
    properties,
    properties_list_id,
    title,
    person,
    person_cellphone_number,
    person_email,
    message,
    ...values
  }) => {
    const { type } = this.props;
    let currentPerson = person;
    let currentMessage = message;

    if (person_cellphone_number || person_email) {
      currentPerson = {
        ...currentPerson,
        cellphone_number:
          person_cellphone_number || currentPerson.cellphone_number,
        email: person_email || currentPerson.email,
      };
    }

    switch (type) {
      case 'email': {
        delete currentPerson.cellphone_number;
        break;
      }
      case 'share': {
        if (values?.properties_id?.length === 1) {
          const propertiesObj = await getPropertiesByIds(values.properties_id);
          currentMessage += `\n\n${propertiesObj?.[0]?.site_link}`;
        }

        currentMessage += `\n\nAbaixo segue um link com uma página com todos imóveis que selecionei para você:\n${this.getDetailPageUrl(
          person.id
        )}`;

        delete currentPerson.email;
        break;
      }
      default: {
        delete currentPerson?.cellphone_number;
        delete currentPerson?.email;
      }
    }

    try {
      if (type === 'generate-link') {
        const { data } = await sharePropertiesUpdate({
          id: properties_list_id,
          title,
        });
        return data;
      }

      if (type === 'add-person') {
        await api.create(`people/${currentPerson.id}/deals`, {
          ...values,
          message: currentMessage,
          person: currentPerson,
        });
      } else {
        await api.create(`people/${currentPerson.id}/matcheds/manually-cross`, {
          ...values,
          message: currentMessage,
          person: currentPerson,
        });
      }

      const { onSubmitSuccess } = this.props;
      if (onSubmitSuccess) onSubmitSuccess();
      return { ...values, person: currentPerson, message: currentMessage };
    } catch (err) {
      if (err?.errors?.cellphone_number) {
        throw new SubmissionError({
          ...err?.errors,
          person_cellphone_number: err?.errors?.cellphone_number,
        });
      }

      throw err;
    }
  };

  onSubmitSuccess = async ({ person }, ...rest) => {
    const { type, openModalPerson, onSubmitSuccess } = this.props;

    if (onSubmitSuccess) {
      onSubmitSuccess({ person }, ...rest);
    }

    // Quando for para adicionar os imóveis
    // para um cliente específico
    if (type === 'add-person') {
      Alert.success('Imóveis adicionado ao cliente');
      openModalPerson(person);
      return;
    }
  };

  handleClose = () => {
    const { onClose, handleClose } = this.props;

    handleClose();

    setTimeout(() => {
      if (onClose) onClose();
    }, 1000);
  };

  onCreatePerson = (person) => {
    const { type, properties, values } = this.props;

    let personData = {};

    if (type === 'email') {
      personData = {
        person_email: person.email,
      };
    }

    if (type === 'share') {
      personData = {
        person_cellphone_number: person.cellphone_number,
      };
    }

    // Abre a modal de compartilhamento
    this.props.openModalShareProperty({
      type,
      properties,
      initialValues: {
        ...values,
        ...personData,
        person: {
          ...person,
          name: person.name,
          originalName: person.name,
        },
      },
      addPerson: true,
    });
  };

  get renderForm() {
    const { onSubmitSuccess, properties, people, openModalPerson, type } =
      this.props;

    const formProps = {
      type,
      handleClose: this.handleClose,
      openModalPerson,
      properties,
      people,
      initialValues: this.state.initialValues,
      onSubmit: this.onSubmit,
      onSuccess: onSubmitSuccess,
      onSubmitSuccess: this.onSubmitSuccess,
      onCreatePerson: this.onCreatePerson,
    };

    switch (type) {
      case 'share':
        return <FormShareWhatsapp {...formProps} />;
      case 'generate-link':
        return <FormGenerateLink {...formProps} />;
      case 'email':
        return <FormShareEmail {...formProps} />;
      default:
        return <FormAddClient {...formProps} />;
    }
  }

  render() {
    const { modalConfig, isOpen, handleClose, modalType } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          isLoading={this.state.isFetching}
          title={this.title}
          handleClose={handleClose}
        >
          {this.renderForm}
        </ModalTemplate>
      </Modal>
    );
  }
}

export const selector = formValueSelector('FormShareProperty');

export default connect(
  (state) => ({
    values: getFormValues('FormShareProperty')(state),
  }),
  {
    openModalShareProperty,
    openModalPerson,
  }
)(ModalShareProperty);
