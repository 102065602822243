import React from 'react';
// Components
import { reduxForm } from 'redux-form';
import Reasons from './Reasons';
import Button from 'components/Button';
import ModalFooter from 'components/Modal/components/ModalFooter';
import { plural } from 'lib/text';

function PropertyMultipleForm({
  allowedProperties,
  reasons,
  propertiesId,
  canRemove,
  handleSubmit,
  handleClose
}) {
  if (allowedProperties === 0) {
    return (
      <div>
        <Reasons reasons={reasons} />
        <ModalFooter>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <p className="h-color--primary">
        {plural(
          allowedProperties,
          'Ao inativar esse imóvel',
          'Ao inativar esses imóveis',
          false
        )}
        , todos os negócios, compromissos e dados ligados a ele serão excluídos.
        Deseja continuar?
      </p>
      <Reasons reasons={reasons} />
      <ModalFooter>
        <Button color="white" colorText="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button disabled={!canRemove} type="submit" color="danger">
          {allowedProperties > 0
            ? plural(
                allowedProperties,
                `Inativar ${allowedProperties} imóvel`,
                `Inativar ${allowedProperties} imóveis`,
                false
              )
            : ''}
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'PropertyMultipleForm',
  enableReinitialize: true
})(PropertyMultipleForm);
