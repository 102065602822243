import { connect } from 'react-redux';
// Modules
import { getFilesUploading } from 'modules/fileUpload';

const UploadingFiles = ({ filesUploading, children }) => {
  return children(filesUploading);
};

const mapStateToProps = state => ({
  filesUploading: getFilesUploading(state)
});

export default connect(mapStateToProps)(UploadingFiles);
