import Button from "components/Button";
import Check from "components/Check";
import Label from "components/Label";
import TextOverflow from "components/TextOverflow";

function PropertyItem({
  property,
  handleOpenProperty,
  canSelect,
  handleSelectProperty,
  selecteds,
}) {
  const getTotalArea = () => {
    try {
      const totalArea = property.areas?.total_area;
      const groundTotalArea = property.areas?.ground_total_area;

      if (groundTotalArea) {
        return groundTotalArea.value + groundTotalArea.measure;
      }

      return totalArea.value + totalArea.measure;
    } catch {
      return '--';
    }
  }

  const renderCheck = () => {
    if (property.is_blocked) {
      return (
        <td>
          <Label color="danger">Bloqueado</Label>
        </td>
      );
    }

    if (!canSelect) return null;

    return (
      <td>
        <Check
          checked={(selecteds || []).includes(property.id)}
          onClick={(e) => {
            e.stopPropagation();
            handleSelectProperty(property.id);
          }}
        />
      </td>
    );
  }

  return (
    <tr className="h-hover h-pointer``" onClick={() => handleOpenProperty(property)}>
      {renderCheck()}
      <td>{property.reference ?? '--'}</td>
      <td>
        <TextOverflow style={{ maxWidth: '200px' }}>
          {property.informations?.lot_description?.value ?? '--'}
        </TextOverflow>
      </td>
      <td>{getTotalArea()}</td>
      <td>{property.calculated_price ?? '--'}</td>
      <td width={1}>
        <Button color="secondary" colorText="white" size="small">
          Ver imóvel
        </Button>
      </td>
    </tr>
  );
}

export default PropertyItem;
