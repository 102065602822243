import React, { useMemo } from 'react';
import { Field } from 'redux-form';
// Components
import { Select } from 'components/Form';
// Hooks
import { useRequest } from 'hooks/useRequest';
// Services
import * as dealsService from 'services/deals';

export default function FieldReasons({ label, excludeId }) {
  const { data } = useRequest({
    request: dealsService.getLossReasons
  });

  const reasons = useMemo(() => {
    if (!excludeId) return data;

    return data.filter(reason => reason.id !== excludeId);
  }, [data, excludeId]);

  return (
    <Field
      label={label}
      name="to_id"
      valueKey="id"
      labelKey="reason"
      options={reasons}
      component={Select}
    />
  );
}
