import styled from 'styled-components';
import Highlight from 'components/Highlight';

export const PersonName = styled(Highlight)`
  color: #0084f4;
`;

export const Wrapper = styled.div`
  margin: -10px 0;
`;

export const Item = styled.button`
  display: block;
  width: 100%;
  padding: 10px 0;
  text-align: left;
  cursor: pointer;
`;
