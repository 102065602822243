import Table from 'components/Table';
import styled from 'styled-components';

export const TableStriped = styled(Table)`
  th {
    white-space: nowrap;
  }

  tr:nth-child(even) td {
    background-color: #e9eef5;
  }
`;
