import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Field, reduxForm } from 'redux-form';
import { Input } from 'components/Form';
import Preview from '../Preview';
import { normalizeEmailInternational } from 'lib/formHelpers';
import Button from 'components/Button';
import { LeftSide, RightSide, Spacer, Wrapper } from './styles';

const Form = ({ isFetching, isEditting, handleSubmit, handleClose }) => {
  return (
    <ModalTemplate
      isLoading={isFetching}
      title={isEditting ? 'Editar cartão virtual' : 'Cadastrar cartão virtual'}
      handleClose={handleClose}
    >
      <Wrapper onSubmit={handleSubmit}>
        <LeftSide>
          <Row>
            <Col xs={12}>
              <Preview />
            </Col>
          </Row>
          <Row>
            <Field
              xs={6}
              label="Nome"
              name="name"
              component={Input}
              placeholder="Digite seu nome"
            />
            <Field
              xs={6}
              label="CRECI"
              name="creci"
              component={Input}
              placeholder="Digite seu CRECI"
            />
          </Row>
          <Row>
            <Field
              xs={6}
              label="Celular"
              name="phone"
              component={Input}
              normalize={normalizeEmailInternational}
              placeholder="Digite seu celular"
            />
            <Field
              xs={6}
              label="E-mail"
              name="email"
              component={Input}
              placeholder="Digite seu e-mail"
            />
          </Row>
          <Row>
            <Field
              xs={12}
              name="site_link"
              label="Endereço do site"
              component={Input}
              placeholder="Digite o endereço do seu site"
            />
          </Row>
          <Spacer />
          <ModalFooter>
            <Button
              type="button"
              color="white"
              colorText="secondary"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <div className="h-flex__cell--grow" />
          </ModalFooter>
        </LeftSide>
        <RightSide>
          <Row>
            <Field
              xs={12}
              label="Facebook"
              name="facebook_link"
              placeholder="Cole a url"
              component={Input}
            />
            <Field
              xs={12}
              label="Instagram"
              name="instagram_link"
              placeholder="Cole a url"
              component={Input}
            />
            <Field
              xs={12}
              label="Twitter"
              name="twitter_link"
              placeholder="Cole a url"
              component={Input}
            />
            <Field
              xs={12}
              label="Youtube"
              name="youtube_link"
              placeholder="Cole a url"
              component={Input}
            />
            <Field
              xs={12}
              label="Linkedin"
              name="linkedin_link"
              placeholder="Cole a url"
              component={Input}
            />
          </Row>
          <Spacer />
          <ModalFooter>
            <div className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              Salvar
            </Button>
          </ModalFooter>
        </RightSide>
      </Wrapper>
    </ModalTemplate>
  );
};

export default reduxForm({
  form: 'FormVcard',
  enableReinitialize: true,
})(Form);
