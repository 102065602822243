import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Components
import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
import NoContent from 'components/NoContent';
import FormSuperHighlights from './components/FormSuperHighlights';
// Services
import { responseMessage } from 'lib/service-helpers';
import * as superHighlightsService from 'services/sites/superHighlights';
import SuperhighlightList from './components/SuperhighlightList';
// Modules
import {
  fetchSuperHighlights,
  superHighlightsSelectors,
} from 'modules/components/superHighlights';
// HOC
import withReception from 'HOC/withReception';
// Assets
import { arrayMove } from 'react-sortable-hoc';
import image from './super-destaque.png';

const propTypes = {};
const defaultProps = {};

class SuperHighlights extends React.Component {
  state = {
    formValues: {},
    isFetching: true,
  };

  async componentDidMount() {
    // Busca os components
    await this.props.fetchSuperHighlights();

    this.getFormData();
  }

  getFormData = () => {
    superHighlightsService.getOneConfig().then(({ data }) => {
      this.setState({ formValues: data });
    });
  };

  handleSubmit = (values) => {
    return superHighlightsService
      .updateConfig(values)
      .then(responseMessage('Salvo!'));
  };

  handleChangeOrder = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(
      this.state.formValues.super_highlights,
      oldIndex,
      newIndex
    );

    // pega a imagem para ser modificada
    const item = newItems.find(
      (image, index) => [newIndex].indexOf(index) >= 0
    );

    this.setState({
      ...this.state,
      formValues: {
        ...this.state.formValues,
        super_highlights: newItems,
      },
    });

    superHighlightsService.update({ ...item, order: newIndex + 1 });
  };

  render() {
    const { reception, handleClickAction } = this.props;

    if (reception.isVisible) {
      return (
        <NoContent
          full
          reversed
          image={image}
          title="Super Destaque"
          text="Adicione até 3 imóveis que ficarão super destacados na capa do seu site."
        >
          <Button color="secondary" size="large" onClick={handleClickAction}>
            Entendi
          </Button>
        </NoContent>
      );
    }

    return (
      <>
        <MainTitle
          title="Galeria Super Destaque"
          text="Adicione até 3 imóveis que formarão a galeria ‘Super Destaque’ na capa do site."
        />
        <FormSuperHighlights
          highlights={this.state.formValues.super_highlights}
          superHighlights={this.props.superHighlights}
          initialValues={this.state.formValues}
          onSubmit={this.handleSubmit}
        />
        <SuperhighlightList
          handleChangeOrder={this.handleChangeOrder}
          superhighlights={this.state.formValues.super_highlights}
          fetch={this.getFormData}
        />
      </>
    );
  }
}

SuperHighlights.propTypes = propTypes;
SuperHighlights.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  superHighlights: superHighlightsSelectors.getAll(state),
});

const mapDispatchToProps = {
  fetchSuperHighlights,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withReception('site/superhighlights', true)
)(SuperHighlights);
