import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { SelectMultiple } from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import { ModalFooter } from '../../../../../components/Modal';
import { transferDealsStage } from 'services/deals';
import { parseSelect } from '../../../../../lib/formHelpers';

const normalizeGroupedData = (data, stage) =>
  data
    .filter(({ id }) => id !== stage.id)
    .map(({ id, name }) => ({
      id,
      name,
    }));

const FormDealDelete = ({
  currentStage,
  funnelsAndStages,
  handleSubmit,
  handleClose,
  submitting,
}) => (
  <form onSubmit={handleSubmit}>
    <Field
      label="Mover negócios para:"
      multi={false}
      name="stage_id"
      options={funnelsAndStages}
      labelKey="name"
      valueKey="stage_id"
      component={SelectMultiple}
      valueRenderer={(group) => {
        return `${group.group.name} -> ${group.name}`;
      }}
      parse={parseSelect('stage_id')}
    />
    <ModalFooter>
      <Button color="white" colorText="primary" onClick={handleClose}>
        Cancelar
      </Button>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success" disabled={submitting}>
        Mover negócios
      </Button>
    </ModalFooter>
  </form>
);

export default reduxForm({
  form: 'FormDealTransfer',
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    return transferDealsStage({
      stage_origin: props?.stage?.id,
      stage_destination: values?.stage_id,
    });
  },
  onSubmitSuccess: (response, dispatch, props) => {
    if (props.onSuccess) {
      props.onSuccess(response, dispatch, props);
    }

    // Fecha a modal
    props.handleClose();
  },
})(FormDealDelete);
