import styled from 'styled-components';

export const Box = styled.div`
  max-width: 477px;
  margin: 15vh auto;
  padding: 30px;
  background: #f9fbfc;
  border: 1px solid ${(p) => p.theme.borderColor};
  border-radius: ${(p) => p.theme.borderRadius};
  text-align: center;

  p {
    font-size: 16px;
  }
`;
export const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  gap: 5px;
`;
