import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  width: 100%;
  padding: 15px 0;

  &:not(:first-child) {
    border-top: 1px solid ${(p) => p.theme.borderColor};
  }
`;

export const Header = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Description = styled.div`
  flex-grow: 1;
  min-width: 1px;
  margin-left: 15px;
`;

export const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
`;

export const Transaction = styled.span`
  color: ${(p) => p.theme.colors[p.color]};
`;

export const Table = styled.table`
  width: 300px;
  flex-shrink: 0;

  td {
    line-height: 14px;
  }
`;

export const Label = styled.td`
  text-align: right;
  padding: 3px 0;
  white-space: nowrap;
  width: 1px;
`;

export const Value = styled.td`
  padding: 0 5px;
  font-weight: bold;
  text-align: ${(p) => (p.price ? 'right' : 'left')};
`;
