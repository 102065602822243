import React from 'react';
// import { Wrapper } from './styles';

function Header({ property }) {
  return (
    <div className="Modal__header">
      <h4 className="Modal__title">
        Publicar Imóvel - {property?.title_formatted} -{' '}
        <span className="h-color--success">{property?.calculated_price}</span>
      </h4>
      <p className="h-margin-top--5">
        Defina abaixo quais dados você deseja que apareçam em seu site e os que
        você deseja omitir.
      </p>
    </div>
  );
}

export default Header;
