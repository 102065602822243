import React from 'react';
import { MdCheckCircle, MdDeleteOutline } from 'react-icons/md';
import CreditCardFlag from 'components/CreditCardFlag';
import { TableCustom } from './styles';

function Table({ data, handleClickPrimaryCard, handleClickRemove }) {
  return (
    <TableCustom>
      <tbody>
        {data.map((creditCard) => (
          <tr key={creditCard.id}>
            <td>
              <CreditCardFlag name={creditCard.brand} />
            </td>
            <td>Final *{creditCard.number}</td>
            <td>
              {creditCard.is_primary ? (
                <>
                  <MdCheckCircle color="#6AC259" /> Padrão
                </>
              ) : (
                <a
                  href="#"
                  className="h-link"
                  onClick={handleClickPrimaryCard(creditCard)}
                >
                  Definir padrão
                </a>
              )}
            </td>
            <td>
              <button onClick={handleClickRemove(creditCard)}>
                <MdDeleteOutline size={16} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </TableCustom>
  );
}

export default Table;
