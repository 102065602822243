import React, { useCallback, useMemo } from 'react';
import { MdAlarm, MdMessage, MdMoreVert } from 'react-icons/md';
// Components
import ActionIcon from 'components/ActionIcon';
// Styles
import { Action, Actions } from './styles';
import { plural } from 'lib/text';
import { useDispatch } from 'react-redux';
import { openModalPerson } from 'modules/modal';
import DealInteractions from 'components/DealInteractions';

export default function CellActions({
  person,
  deal: { id, calendars_count, stage, property },
  onUpdateDeal
}) {
  const dispatch = useDispatch();

  const handleClickEvents = useCallback(() => {
    dispatch(
      openModalPerson(person, { initialPage: 'Events', property, stage })
    );
  }, [dispatch, person, property, stage]);

  const handleClickNotes = useCallback(() => {
    dispatch(
      openModalPerson(person, {
        initialPage: 'Notes',
        property: property,
        stage: stage,
        addNote: true
      })
    );
  }, [dispatch, person, property, stage]);

  const tooltip = useMemo(() => {
    if (person.calendars_count === 0)
      return {
        text: 'Nenhuma atividade agendada',
        position: 'top'
      };
    return {
      text: plural(
        person.calendars_count,
        'Atividade agendada',
        'Atividades agendada'
      ),
      position: 'top'
    };
  }, [person.calendars_count]);

  return (
    <td>
      <Actions>
        <ActionIcon
          onClick={handleClickEvents}
          counter={calendars_count}
          tooltip={tooltip}
        >
          <MdAlarm />
        </ActionIcon>
        <ActionIcon onClick={handleClickNotes}>
          <MdMessage />
        </ActionIcon>
        <DealInteractions
          property={property}
          person={person}
          stageId={stage?.id}
          dealId={id}
          onSuccess={onUpdateDeal}
        >
          <Action>
            <MdMoreVert />
          </Action>
        </DealInteractions>
      </Actions>
    </td>
  );
}
