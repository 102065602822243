import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

export const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOf3Months: startOfMonth(subMonths(new Date(), 2)),
  startOf6Months: startOfMonth(subMonths(new Date(), 5)),
  startOf12Months: startOfYear(new Date()),
  last30days: subDays(new Date(), 30),
  last60days: subDays(new Date(), 60),
  last90days: subDays(new Date(), 90),
  startOfThisYear: startOfYear(new Date()),
  endOfThisYear: endOfYear(new Date()),
  startOfLastYear: startOfYear(subYears(new Date(), 1)),
  endOfLastYear: endOfYear(subYears(new Date(), 1)),

  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1)),
  next30days: addDays(new Date(), 30),
  next60days: addDays(new Date(), 60),
  next90days: addDays(new Date(), 90),
};

export const createRange = (label, startDate, endDate) => {
  return {
    label,
    range: () => ({
      startDate,
      endDate,
    }),
  };
};

export const DEFINED_RANGES = {
  TODAY: createRange('Hoje', defineds.startOfToday, defineds.endOfToday),
  YESTERDAY: createRange('Ontem', defineds.startOfToday, defineds.endOfToday),
  CURRENT_WEEK: createRange(
    'Essa semana',
    defineds.startOfWeek,
    defineds.endOfWeek
  ),
  LAST_WEEK: createRange(
    'Última semana',
    defineds.startOfLastWeek,
    defineds.endOfLastWeek
  ),
  CURRENT_MONTH: createRange(
    'Este Mês',
    defineds.startOfMonth,
    defineds.endOfMonth
  ),
  LAST_MONTH: createRange(
    'Mês passado',
    defineds.startOfLastMonth,
    defineds.endOfLastMonth
  ),
  LAST_30_DAYS: createRange(
    'Últimos 30 dias',
    defineds.last30days,
    defineds.startOfToday
  ),
  LAST_60_DAYS: createRange(
    'Últimos 60 dias',
    defineds.last60days,
    defineds.startOfToday
  ),
  LAST_90_DAYS: createRange(
    'Últimos 90 dias',
    defineds.last90days,
    defineds.startOfToday
  ),
  LAST_THREE_MONTHS: createRange(
    'Últimos 3 Meses',
    defineds.startOf3Months,
    defineds.endOfMonth
  ),
  LAST_SIX_MONTHS: createRange(
    'Últimos 6 Meses',
    defineds.startOf6Months,
    defineds.endOfMonth
  ),
  CURRENT_YEAR: createRange(
    'Este ano',
    defineds.startOf12Months,
    defineds.endOfToday
  ),
  PAST_YEAR: createRange(
    'Ano passado',
    defineds.startOfLastYear,
    defineds.endOfLastYear
  ),
  NEXT_MONTH: createRange(
    'Próximo Mês',
    defineds.startOfNextMonth,
    defineds.endOfNextMonth
  ),
  NEXT_30_DAYS: createRange(
    'Próximos 30 dias',
    defineds.startOfToday,
    defineds.next30days
  ),
  NEXT_60_DAYS: createRange(
    'Próximos 60 dias',
    defineds.startOfToday,
    defineds.next60days
  ),
  NEXT_90_DAYS: createRange(
    'Próximos 90 dias',
    defineds.startOfToday,
    defineds.next90days
  ),
  THIS_YEAR: createRange(
    'Este Ano (Jan-Dez)',
    defineds.startOfThisYear,
    defineds.endOfThisYear
  ),
  LAST_YEAR: createRange(
    'Ano Passado (Jan-Dez)',
    defineds.startOfLastYear,
    defineds.endOfLastYear
  ),
};

export const defaultStaticRanges = [
  DEFINED_RANGES.TODAY,
  DEFINED_RANGES.CURRENT_MONTH,
  DEFINED_RANGES.LAST_MONTH,
  DEFINED_RANGES.LAST_30_DAYS,
  DEFINED_RANGES.LAST_60_DAYS,
  DEFINED_RANGES.LAST_90_DAYS,
  DEFINED_RANGES.CURRENT_YEAR,
  DEFINED_RANGES.PAST_YEAR,
];
