import React, { useEffect } from 'react';
import { MdPrint } from 'react-icons/md';
import { Route, Switch } from 'react-router';
import SaleAuthorization from './pages/SaleAuthorization';
import RentAuthorization from './pages/RentAuthorization';
import { ButtonPrint, PaperSheet, Wrapper } from './styles';

const Print = () => {
  function handlePrint() {
    window.print();
  }

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1000);
  }, []);

  return (
    <Wrapper>
      <PaperSheet>
        <Switch>
          <Route
            exact
            path="/print/sales-authorization"
            component={SaleAuthorization}
          />
          <Route
            exact
            path="/print/rent-authorization"
            component={RentAuthorization}
          />
        </Switch>
      </PaperSheet>
      <ButtonPrint onClick={handlePrint}>
        <MdPrint />
        Imprimir
      </ButtonPrint>
    </Wrapper>
  );
};

export default Print;
