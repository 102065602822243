import {
  getStages,
  isFetchingSelector,
  moveDeal,
  reorderDeal,
  stagesSelector,
  unsetDragging,
  updateMeta,
} from 'modules/pipeline';
import { openModalAddDeal, openModalDealsMoveProperty } from 'modules/modal';
import * as dealsService from 'services/deals';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useFormFunnelId } from 'pages/Crm/pages/Deals/components/Filter';
import { useFormUserId } from '../../../components/FormUser';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { change } from 'redux-form';

export const useDeals = () => {
  const isFetching = useSelector(isFetchingSelector);
  const stages = useSelector(stagesSelector);
  const funnelId = useFormFunnelId();
  const userId = useFormUserId();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const fetchStagesByUserId = async () => {
    // Busca os negocios com base os valores do formulário
    dispatch(getStages({ id: funnelId, user_id: userId }));
  };

  const getStageById = (stageId) => {
    try {
      return stages[stageId];
    } catch {
      return null;
    }
  };

  const getDraggableId = (draggableId) => {
    try {
      return draggableId.split(',')[0];
    } catch {
      return null;
    }
  };

  const getPerson = (source, draggableId) => {
    const stage = getStageById(source.droppableId);
    return stage.deals_list.find((person) => person.id === draggableId);
  };

  const handleDragEnd = async ({
    destination,
    draggableId,
    reason,
    source,
    type,
  }) => {
    // Não faz nada quando joga fora da lista
    if (!destination) {
      return false;
    }

    const person = getPerson(source, getDraggableId(draggableId));
    const destinationStage = getStageById(destination.droppableId);
    const sourceStage = getStageById(source.droppableId);

    const hasPersonInDestinationStage = destinationStage?.deals_list.some(
      (currentPerson) => currentPerson.id === person.id
    );

    dispatch(unsetDragging());

    const { droppableId: destId, index: destIndex } = destination;
    const { droppableId: sourceId, index: sourceIndex } = source;

    if (hasPersonInDestinationStage && destId !== sourceId) {
      fetchStagesByUserId(funnelId);
    }

    if (person.deals_count > 1 && destId !== sourceId) {
      dispatch(
        openModalDealsMoveProperty({
          person,
          sourceStage,
          destinationStage,
          destIndex,
          onSubmitSuccess: () => {
            fetchStagesByUserId(funnelId);
          },
        })
      );
      return null;
    }

    if (destId === sourceId) {
      dispatch(reorderDeal(sourceId, sourceIndex, destIndex));
    } else {
      dispatch(moveDeal(sourceId, destId, source, destination));
    }

    await dealsService.updatePositionDeal(
      sourceId,
      destId,
      person?.deals?.[0]?.id,
      destIndex,
      person
    );

    dispatch(updateMeta(sourceId, destId, funnelId, userId));
  };

  const handleClickAddDeal = () => {
    dispatch(
      openModalAddDeal({
        funnelId,
        onSuccess: () => {
          fetchStagesByUserId(funnelId);
        },
      })
    );
  };

  useEffect(() => {
    if (funnelId && userId) {
      fetchStagesByUserId();
    }
  }, [funnelId, userId]);

  useEffect(() => {
    // Verifica se na url tem o parametro by_user_id
    if (queryParams.by_user_id) {
      dispatch(change('FormUser', 'user_id', queryParams.by_user_id));
    }
  }, [queryParams.by_user_id]);

  return {
    stages,
    isFetching,
    fetchStagesByUserId,
    getStageById,
    getDraggableId,
    getPerson,
    handleDragEnd,
    handleClickAddDeal,
  };
};
