import NavAside from 'components/NavAside';
import TreeNav from 'components/TreeNav';
import Wrapper from 'components/Wrapper';
import Can from 'containers/Can';
import { crmConfigSelector, fetchData } from 'modules/configCrm';
import { Helmet } from 'react-helmet';
import { IoMdGlobe, MdCalendarToday } from 'react-icons/all';
import { FaWhatsapp } from 'react-icons/fa';
import {
  MdCallToAction,
  MdCode,
  MdContactPage,
  MdContacts,
  MdFactCheck,
  MdImage,
  MdSearch,
  MdSecurity,
  MdSettings,
} from 'react-icons/md';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Routes from './Routes';
import { useEffect } from 'react';

const Config = ({ data, fetchData }) => {
  const { path } = useRouteMatch();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const hasOrulo = !!data.orulo;
  const hasDwv = !!data.dwv;

  return (
    <Wrapper full>
      <Helmet>
        <title>Site - Config</title>
      </Helmet>
      <NavAside>
        <Can run="EDIT_REAL_ESTATE">
          <NavAside.link icon={MdFactCheck} to={`${path}/realestate`}>
            Dados de contato
          </NavAside.link>
        </Can>
        <Can run="EDIT_DOMAIN">
          <NavAside.link icon={IoMdGlobe} to={`${path}/domains`}>
            Domínio
          </NavAside.link>
        </Can>
        <Can run="EDIT_SITE">
          <NavAside.link icon={FaWhatsapp} to={`${path}/chat`}>
            Whatsapp
          </NavAside.link>
          <NavAside.link icon={MdCalendarToday} to={`${path}/visit-schedule`}>
            Agendar Visitas
          </NavAside.link>
          <NavAside.link icon={MdCallToAction} to={`${path}/popup`}>
            Popup
          </NavAside.link>
          <NavAside.link icon={MdImage} to={`${path}/gallery`}>
            Restrição de fotos
          </NavAside.link>
          <Can run="EDIT_LGPD_CONFIG">
            <NavAside.link icon={MdSecurity} to={`${path}/lgpd`}>
              LGPD
            </NavAside.link>
          </Can>
          <NavAside.link icon={MdSearch} to={`${path}/seo`}>
            SEO
          </NavAside.link>
          <NavAside.link icon={MdContactPage} to={`${path}/property-contact`}>
            Formulário de contato
          </NavAside.link>
          <TreeNav
            to={`${path}/crm`}
            title="Rodízio de leads"
            icon={MdContacts}
          >
            <TreeNav.link to={`${path}/crm/own`}>Meus imóveis</TreeNav.link>
            {hasOrulo && (
              <TreeNav.link to={`${path}/crm/orulo`}>Rede Órulo</TreeNav.link>
            )}
            {hasDwv && (
              <TreeNav.link to={`${path}/crm/dwv`}>Rede DWV</TreeNav.link>
            )}
          </TreeNav>
          <NavAside.link icon={MdCode} to={`${path}/scripts/show`}>
            Injetar script
          </NavAside.link>
          <NavAside.link icon={MdSettings} to={`${path}/global-config`}>
            Manutenção
          </NavAside.link>
        </Can>
      </NavAside>
      <Wrapper.inner>
        <Wrapper.container>
          <Routes />
        </Wrapper.container>
      </Wrapper.inner>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  fetchData,
};

const mapStateToProps = (state) => ({
  data: crmConfigSelector.getAll(state),
  isFetching: crmConfigSelector.isFetching(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Config);
