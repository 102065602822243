import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const buttonColor = ({ isActive, theme }) => {
  if (!isActive) {
    return css`
      background: ${rgba('#C2CEE2', 0.1)};
      border: 1px solid #d2dbe3;
    `;
  }

  return css`
    color: #fff;
    background: ${theme?.colors.success};
    border: 1px solid transparent;
  `;
};

export const Wrapper = styled.div`
  display: flex;
`;

export const Button = styled.button.attrs(() => ({
  type: 'button'
}))`
  cursor: pointer;
  min-width: 35px;
  height: 35px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;

  & + & {
    margin-left: 5px;
  }

  ${buttonColor};
`;
