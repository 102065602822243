import { createSelector } from 'reselect';
import { createModule } from 'lib/reducer-helpers';
import { formValueSelector, getFormValues } from 'redux-form';
import api from 'services';
import { CancelToken } from 'axios';

const createAction = createModule('modalPropertySearch');

const REQUEST_REALTORS = createAction('REQUEST_REALTORS');
const RECEIVE_REALTORS = createAction('RECEIVE_REALTORS');
const REQUEST_PROPERTIES_COUNT = createAction('REQUEST_PROPERTIES_COUNT');
const RECEIVE_PROPERTIES_COUNT = createAction('RECEIVE_PROPERTIES_COUNT');
const RECEIVE_TYPES_AND_SUBTYPES = createAction('RECEIVE_TYPES_AND_SUBTYPES');
const TOGGLE_ADVANCED_SEARCH = createAction('TOGGLE_ADVANCED_SEARCH');
const INITIALIZE_MODAL = createAction('INITIALIZE_MODAL');

const selector = formValueSelector('ModalPropertySearch');

// export const transaction

/**
 * Pega a transação que está ativa no momento
 * @param state
 * @returns {any}
 */
export const isSellTransaction = (state) => {
  const transaction = selector(state, 'transaction');

  return transaction === 1 || transaction === '1' || !transaction;
};

// Pega os tipos da modal de pesquisa
export const typesSelector = (state) =>
  state.modalPropertySearch.typesAndSubtypes;

export const typeOrSubtypeIdSelector = (state) =>
  selector(state, 'by_type_or_subtype_id');

/**
 * Pega o tipo baseado no que foi selecionado no formulário
 */
export const currentTypeSelector = createSelector(
  [typesSelector, typeOrSubtypeIdSelector],
  (types, typeOrSubtypeId) => {
    return types.find((type) => type.id === typeOrSubtypeId);
  }
);

const getTypeTitle = (type) => {
  if (type?.type?.title) return type?.type?.title;
  return type?.title;
};

export const isTypeGroundSelector = createSelector(
  [currentTypeSelector],
  (type) => {
    const title = getTypeTitle(type);

    switch (title) {
      case 'Área':
      case 'Garagem':
      case 'Ilha':
      case 'Loteamento':
      case 'Terreno':
        return true;
      default:
        return false;
    }
  }
);

export const propertiesCountSelector = (state) =>
  state.modalPropertySearch.propertiesCount;

export const isShowAdvancedSearchSelector = (state) =>
  state.modalPropertySearch.advancedSearch;

export const usersSelector = (state) => state.modalPropertySearch.users;
export const realtorsSelector = createSelector([usersSelector], (users) => {
  return users.data.filter((user) => user.realtor);
});

export const initialState = {
  advancedSearch: false,
  users: {
    data: [],
    isFetching: false,
  },
  propertiesCount: {
    self: {
      count: 0,
      isFetching: false,
    },
    orulo: {
      count: 0,
      isFetching: false,
    },
    dwv: {
      count: 0,
      isFetching: false,
    },
    guru: {
      count: 0,
      isFetching: false,
    },
  },
  typesAndSubtypes: [],
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_MODAL:
      return {
        ...state,
        advancedSearch: initialState.advancedSearch,
        propertiesCount: initialState.propertiesCount,
      };
    case REQUEST_REALTORS:
      return { ...state, users: { ...state.users, isFetching: true } };
    case RECEIVE_REALTORS:
      return { ...state, users: { data: action.data, isFetching: true } };
    case REQUEST_PROPERTIES_COUNT:
      return {
        ...state,
        propertiesCount: {
          ...state.propertiesCount,
          [action.name]: {
            count: 0,
            isFetching: true,
          },
        },
      };
    case RECEIVE_PROPERTIES_COUNT:
      return {
        ...state,
        propertiesCount: {
          ...state.propertiesCount,
          [action.name]: {
            count: action.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_TYPES_AND_SUBTYPES:
      return { ...state, typesAndSubtypes: action.data };
    case TOGGLE_ADVANCED_SEARCH:
      return { ...state, advancedSearch: !state.advancedSearch };
    default:
      return state;
  }
}

/**
 * Recebe os tipos e subtipos
 * @param dispatch
 */
export function initialize() {
  return (dispatch) => dispatch({ type: INITIALIZE_MODAL });
}
export function receiveTypes(data) {
  return (dispatch) => dispatch({ type: RECEIVE_TYPES_AND_SUBTYPES, data });
}

export const requestPropertiesCount = (name) => (dispatch) =>
  dispatch({ type: REQUEST_PROPERTIES_COUNT, name });

export const receivePropertiesCount = (name, data) => (dispatch) =>
  dispatch({ type: RECEIVE_PROPERTIES_COUNT, name, data });

export const requestUsers = () => (dispatch) =>
  dispatch({ type: REQUEST_REALTORS });

export const receiveUsers = (data) => (dispatch) =>
  dispatch({ type: RECEIVE_REALTORS, data });

export const toggleAdvancedSearch = () => (dispatch) =>
  dispatch({ type: TOGGLE_ADVANCED_SEARCH });

let sourceSearchSelf;
let sourceSearchGuru;
let sourceSearchOrulo;
let sourceSearchDwv;

const transformValues = (values) => {
  if (values.count) {
    delete values.count;
  }

  if (values.type_name) {
    delete values.type_name;
  }

  return values;
};

export const fetchProperties =
  (name, source, values, _params) => async (dispatch) => {
    if (source?.cancel !== undefined) source.cancel('Request Cancelado');

    source = CancelToken.source();

    try {
      dispatch(requestPropertiesCount(name));

      let params = {};
      params.with_grouped_condos = true;

      if (values.condominium_id) {
        delete params.with_grouped_condos;
      }

      if (values.transaction === 1 && values.price) {
        delete values.price.with_rental_taxes;
      }

      const res = await api.getList(
        `properties/count-${name}`,
        { filter: values, ...params, ..._params },
        {
          cancelToken: source.token,
        }
      );

      dispatch(receivePropertiesCount(name, res?.data?.count || 0));
    } catch (err) {
      console.log(err);
    }
  };

export const fetchPropertiesSelf = (values, params) => async (dispatch) => {
  dispatch(fetchProperties('self', sourceSearchSelf, values, params));
};

export const fetchPropertiesGuru = (values, params) => async (dispatch) => {
  dispatch(fetchProperties('guru', sourceSearchGuru, values, params));
};

export const fetchPropertiesOrulo = (values, params) => async (dispatch) => {
  dispatch(fetchProperties('orulo', sourceSearchOrulo, values, params));
};

export const fetchPropertiesDwv = (values, params) => async (dispatch) => {
  dispatch(fetchProperties('dwv', sourceSearchDwv, values, params));
};

/**
 * Busca os imóveis com contagem
 * @param values
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchPropertiesCount =
  ({ isPortals } = { isPortals: false }) =>
  async (dispatch, getState) => {
    setTimeout(async () => {
      let currentUser = localStorage.getItem('current-user');
      const state = getState();
      const values = getFormValues('ModalPropertySearch')(state);
      let _values = transformValues(values);

      const canFetchGuru =
        currentUser.real_estate.with_guru && isPortals === false;

      if (isPortals) {
        _values = {
          ..._values,
          by_available_for_portals: true,
        };
      }

      const canFetchNetwork =
        !!values?.by_city_id &&
        !!values.by_type_or_subtype_id &&
        !!values.transaction &&
        !values.condominium_id;

      dispatch(fetchPropertiesSelf(_values));
      if (canFetchNetwork) {
        if (canFetchGuru) dispatch(fetchPropertiesGuru(_values));
        dispatch(fetchPropertiesOrulo(_values));
        dispatch(fetchPropertiesDwv(_values));
      } else {
        dispatch(receivePropertiesCount('guru', 0));
        dispatch(receivePropertiesCount('orulo', 0));
        dispatch(receivePropertiesCount('dwv', 0));
      }
    }, 10);
  };

/**
 * Busca os corretores
 * @param values
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchUsers = (values) => async (dispatch) => {
  try {
    dispatch(requestUsers());

    const res = await api.getList('users', {
      filter: values,
      sort: 'by_name',
    });

    dispatch(receiveUsers(res.data));
  } catch (err) {
    console.log(err);
  }
};
