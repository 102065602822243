import React from 'react';
import PropTypes from 'prop-types';
// Components
import ListOptionsItem from './components/ListOptionsItem';
import ListOptionsWrapper from './components/ListOptionsWrapper';
import Loading from 'components/Loading';
import classnames from 'classnames';

const propTypes = {
  title: PropTypes.node,
};

const defaultProps = {
  title: null,
};

const ListOptions = ({
  noSpacingTitle = false,
  title,
  children,
  isLoading,
  ...props
}) => (
  <section className="ListOptions" {...props}>
    <Loading isVisible={isLoading} isFullComponent />
    {title && (
      <h1
        className={classnames('ListOptions__header h5 h-color--primary-light', {
          'ListOptions__header--no-spacing': noSpacingTitle,
        })}
      >
        {title}
      </h1>
    )}
    <ListOptionsWrapper>{children}</ListOptionsWrapper>
  </section>
);

ListOptions.propTypes = propTypes;
ListOptions.defaultProps = defaultProps;
ListOptions.item = ListOptionsItem;

export default ListOptions;
