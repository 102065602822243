import React, { Component } from 'react';
// Service
import * as scriptsService from 'services/scripts';
// Components
import Alert from 'components/Alert';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Form from './components/Form';
import { withRouter } from 'react-router';
import qs from 'qs';

class ScriptsPagesForm extends Component {
  state = {
    initialValues: {},
    isFetching: false,
    isAnalytics: false,
  };

  /**
   * Verifica se tem analytics
   * @return {string | string[] | QueryString.ParsedQs | QueryString.ParsedQs[]}
   */
  isAnalytics = () => {
    const queryObject = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    return (
      queryObject.isAnalytics ||
      this.state.initialValues.name === 'Google Analytics GA4'
    );
  };

  componentDidMount() {
    this.fetchScript();

    this.setState({
      ...this.state,
      isAnalytics: this.isAnalytics(),
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this?.state?.initialValues?.name === 'Google Analytics GA4' &&
      this.state.isAnalytics === false
    ) {
      this.setState({
        ...this.state,
        isAnalytics: true,
      });
    }
  }

  get scriptId() {
    try {
      return this.props.match.params.id;
    } catch {
      return null;
    }
  }

  fetchScript() {
    const scriptId = this.scriptId;

    if (scriptId) {
      this.setState({ isFetching: true });
      scriptsService.getOne(scriptId).then(({ data }) => {
        this.setState({ initialValues: data, isFetching: false });
      });
    }
  }

  handleSubmit = (values) =>
    values.id ? scriptsService.update(values) : scriptsService.create(values);

  handleSubmitSuccess = () => {
    this.props.history.push('/site/config/scripts/show');
  };

  handleSubmitFail = () => {
    // Fail
  };

  get initialValues() {
    if (this.state.isAnalytics) {
      return {
        ...this.state.initialValues,
        trigger: 'all-pages',
        name: 'Google Analytics GA4',
        position: '1',
      };
    }
    return {
      ...this.state.initialValues,
      trigger: 'all-pages',
    };
  }

  render() {
    return (
      <Wrapper.container>
        <MainTitle
          title="Códigos e Scripts"
          text="Insira códigos externos, como Pixel do Facebook, Tag Manager."
        />
        <Alert
          color="caution"
          style={{ marginTop: '-15px', marginBottom: '10px' }}
        >
          Atenção: Não garantimos que scripts de terceiros adicionados em seu
          site funcionarão corretamente.
        </Alert>
        <Form
          isAnalytics={this.state.isAnalytics}
          initialValues={this.initialValues}
          onSubmit={this.handleSubmit}
          onSubmitSuccess={this.handleSubmitSuccess}
          onSubmitFail={this.handleSubmitFail}
        />
      </Wrapper.container>
    );
  }
}

export default withRouter(ScriptsPagesForm);
