import React from 'react';
import { connect } from 'react-redux';
// Components
import MainTitle from 'components/MainTitle';
import FormPropertyDetail from './components/FormPropertyDetail';
// Modules
import {
  fetchComponents,
  propertyDetailsSelectors,
} from 'modules/components/propertyDetails';
import * as propertiesService from 'services/sites/properties';

const propTypes = {};
const defaultProps = {};

class Headers extends React.Component {
  state = {
    formValues: {},
    isFetching: true,
  };

  async componentDidMount() {
    // Busca os componentes relacionados ao detalhe de imóvel
    await this.props.fetchComponents();

    propertiesService.getPropertyDetails().then(({ data: formValues }) => {
      this.setState({ formValues });
    });
  }

  handleSubmit = (values) => propertiesService.updateDetails(values);

  render() {
    const { components } = this.props;

    return (
      <>
        <MainTitle
          title="Detalhes do imóvel"
          text="Escolha o modelo da página de detalhes do imóvel"
        />
        <FormPropertyDetail
          initialValues={this.state.formValues}
          onSubmit={this.handleSubmit}
          components={components}
        />
      </>
    );
  }
}

Headers.propTypes = propTypes;
Headers.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  components: propertyDetailsSelectors.getAll(state),
});

const mapDispatchToProps = { fetchComponents };

export default connect(mapStateToProps, mapDispatchToProps)(Headers);
