import styled from 'styled-components';

export const Header = styled.div`
  background: #edeef2;
  padding: 5px 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-size: 14px;
  padding: 6px 0;
`;

export const Wrapper = styled.div`
  position: relative;
`;
export const Table = styled.table.attrs(() => ({
  className: 'Table',
}))`
  filter: drop-shadow(-1px 1px 5px rgba(0, 0, 0, 0.1));

  thead th {
    background: #f9fbfc;
  }

  th,
  td {
    padding: 10px;
  }
`;
