import React from 'react';
import styled from 'styled-components';
// Containers
import DealHistory from 'containers/DealHistory';
// Components
import Button from 'components/Button';
// Helpers
import { getName, plural } from 'lib/text';

const Days = styled.span`
  display: inline-block;
  min-width: 65px;
`;

function TableItem({
  data: {
    deal_id: id,
    lost_at,
    broker,
    client,
    client_id,
    property,
    property_id,
    owner,
    owner_id,
    days
  },
  handleClickPerson,
  handleClickProperty
}) {
  return (
    <tr key={id}>
      <td>{lost_at}</td>
      <td>{getName(broker)}</td>
      <td>
        <span className="h-link" onClick={handleClickPerson(client_id)}>
          {getName(client)}
        </span>
      </td>
      <td>
        <span className="h-link" onClick={handleClickProperty(property_id)}>
          {property}
        </span>
      </td>
      <td>
        <span className="h-link" onClick={handleClickPerson(owner_id)}>
          {getName(owner)}
        </span>
      </td>
      <td className="h-text-nowrap">
        <Days>{days > 0 ? plural(days, 'dia', 'dias') : 'No mesmo dia'} </Days>
        <DealHistory
          dealId={id}
          params={{
            filter: {
              event: ['deal_stage_updated', 'lost_deal', 'created']
            }
          }}
        >
          <Button size="small" color="white" colorText="secondary">
            Histórico
          </Button>
        </DealHistory>
      </td>
    </tr>
  );
}

TableItem.defaultProps = {};
TableItem.propTypes = {};

export default TableItem;
