import React from 'react';
import { Wrapper, Col, Item, Nodata } from './styles';
import TextOverflow from 'components/TextOverflow';
import { Field } from 'redux-form';
import { Select } from 'components/Form';
import FieldSelectUser from 'containers/FieldSelectUser';
import { Property } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import { useFacebookLeads } from 'containers/ModalRoot/ModalFacebookLeads/context';

function ListFormsFacebook({ indexPage, page, change }) {
  const { users } = useFacebookLeads();

  const formsValue = useFormValue('lead_pages');
  const forms = page.forms;

  const nameField = (name, index) => {
    return `lead_pages[${indexPage}].forms[${index}].${name}`;
  };

  const getOption = (index) => {
    try {
      return formsValue[indexPage].forms[index].option;
    } catch {
      return '';
    }
  };

  if (!forms || forms?.length <= 0) {
    return <Nodata>Nenhum formulário ativo nesta página</Nodata>;
  }

  return (
    <Wrapper>
      {forms?.map((form, i) => {
        const nameOption = nameField('option', i);
        const nameUserId = nameField('user_id', i);
        const namePropertyId = nameField('property_id', i);
        const option = getOption(i);

        return (
          <Item>
            <Col>
              <h4>Formulário</h4>
              <p>
                <TextOverflow width={200}>{form?.name}</TextOverflow>
              </p>
            </Col>
            <Field
              style={{ width: 250 }}
              buttonTemplate
              label="Atribuir contato para"
              name={nameOption}
              component={Select}
              canRemoveSelf={false}
              options={[
                { label: 'Não receber leads', value: 'semacao' },
                { label: 'Corretor', value: 'corretor' },
                { label: 'Imóvel', value: 'imovel' },
              ]}
              onChange={() => {
                change(nameUserId, null);
                change(namePropertyId, null);
              }}
            />
            {option !== 'corretor' && option !== 'imovel' && (
              <div style={{ width: 345 }} />
            )}
            {option === 'corretor' && (
              <FieldSelectUser
                users={users}
                disabled={option === 'semacao'}
                label="Corretor que receberá o lead no sistema"
                name={nameUserId}
                style={{ width: 345 }}
              />
            )}
            {option === 'imovel' && (
              <div style={{ width: 345 }}>
                <Property
                  disabled={option === 'semacao'}
                  label="Imóvel que será associado ao contato"
                  name={namePropertyId}
                />
              </div>
            )}
          </Item>
        );
      })}
    </Wrapper>
  );
}

export default ListFormsFacebook;
