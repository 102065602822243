// Widgets.js
import _ from 'lodash';
import Alert from 'react-s-alert';
import { change, arrayRemove, getFormValues } from 'redux-form';
import { createModule, item } from '../lib/reducer-helpers';
// Services
import * as establishmentsService from '../services/establishments';
// Module
import { setFormData } from 'modules/propertyAdd';

const createAction = createModule('establishments');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const REMOVE = createAction('REMOVE');

// pega todos os estabelecimentos
export const establishmentsSelector = state =>
  state.establishments.establishments;

export const establishmentsMetaSelector = state => state.establishments.meta;

// Initial State
const initialState = {
  establishments: [],
  meta: {
    isFetching: false
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE:
      return {
        ...state,
        establishments: action.establishments,
        meta: { ...state.meta, isFetching: false }
      };
    case REMOVE:
      return {
        ...state,
        establishments: item.remove(state.establishments, action)
      };
    default:
      return state;
  }
}

// Action Creators
export function requestEstablishments() {
  return { type: REQUEST };
}

export function receiveEstablishments(establishments) {
  return { type: RECEIVE, establishments };
}

export function removeEstablishment(id) {
  return { type: REMOVE, id };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export function deleteEstablishment(id) {
  return (dispatch, getState) => {
    const { establishments } = getFormValues('propertiesAdd')(getState());

    return establishmentsService
      .remove(id)
      .then(res => {
        dispatch(
          arrayRemove(
            'propertiesAdd',
            'establishments',
            _.findIndex(establishments, { id })
          )
        );

        // remove a estabelecimento da store
        dispatch(removeEstablishment(id));

        return res;
      })
      .catch(() => {
        Alert.success('Erro ao remover o estabelecimento');
      });
  };
}

/**
 * Pega todos os estabelecimentos de um tipo de imóvel especifico
 * @return {function(*)}
 */
export function getEstablishments(params) {
  return dispatch => {
    // loading dos estabelecimentos
    dispatch(requestEstablishments());

    // faz o request buscando os estabelecimentos
    return establishmentsService
      .getList({
        ...params,
        order_col: 'title',
        order_rule: 'asc'
      })
      .then(({ data: establishments }) => {
        dispatch(receiveEstablishments(establishments));
        return establishments;
      })
      .catch(() => {
        Alert.success('Problema ao buscar os estabelecimentos');
        dispatch(receiveEstablishments([]));
      });
  };
}

// Cadastro de estabelecimento
export function handleFormEstablishment(values) {
  return (dispatch, getState) =>
    establishmentsService.create(values).then(({ data }) => {
      Alert.success(`Estabelecimento ${values.title} adicionado`);
      const formdata = getFormValues('propertiesAdd')(getState());

      getEstablishments()(dispatch);

      /* TODO Refazer essa parte */
      setFormData({
        ...formdata,
        establishments: {
          ...formdata.establishments,
          [data.id]: {
            id: data.id,
            quantity: '',
            isChecked: true
          }
        }
      })(dispatch);

      return data;
    });
}

// Cadastro de estabelecimento
export function handleCreateEstablishmentWithForm(values, form) {
  return (dispatch, getState) =>
    establishmentsService.create(values).then(({ data }) => {
      Alert.success(`Estabelecimento ${values.title} adicionado`);

      // Pega os dados do formulário
      const formdata = getFormValues(form)(getState());

      getEstablishments()(dispatch);

      dispatch(
        change(form, 'establishments', {
          ...formdata?.establishments,
          [data.id]: {
            id: data?.id,
            isChecked: true
          }
        })
      );

      return data;
    });
}
