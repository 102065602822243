import _ from 'lodash';
import { APP_NAME } from '../constants/config';

/**
 * Função que serve para simplificar a criacao de ações de um modulo
 * @param moduleName|String
 * @example
 * const createAction = createModule('MODULE_NAME');
 * const REQUEST = createAction('ACTION_NAME');
 */
export const createModule = (moduleName) => (actionName) =>
  `${APP_NAME}/${moduleName}/${actionName}`;

/**
 * Retorna a store baseado no caminho em string
 * @param {Object} state - o state da aplicação
 * @param {String} moduleName - o nome do modulo clients/
 * @return {Object}
 */
export const getStatePath = (state, moduleName) =>
  _.get(state, moduleName.replace(/\//g, '.'));

/**
 * Adiciona um objeto em um array
 * @param array|Array
 * @param itemObj|Object
 */
export const addItem = (array, itemObj) => [...array, itemObj];
export const addItemUp = (array, itemObj) => [itemObj, ...array];

/**
 * Altera um objeto dentro de um array
 * @param array|Array
 * @param itemObj|Object
 * @param identifier|string
 * @param cb|function
 * @returns Array
 */
export const alterItem = (array, itemObj, cb = null, identifier = 'id') =>
  array.map((item) => {
    if (item[identifier] === itemObj[identifier]) {
      return cb
        ? {
            ...item,
            ...cb(),
          }
        : {
            ...item,
            ...itemObj,
          };
    }
    return item;
  });

//export const selectItem = (state, payload, flagName) =>

/**
 * Exclui um objeto de um array
 * @param array|Array
 * @param itemObj|Object
 * @param identifier|String
 * @retuns Array
 */
export const removeItem = (array, itemObj, identifier = 'id') =>
  _.reject(array, { [identifier]: itemObj[identifier] });

/**
 * Seleciona um id em uma lista de array de strings
 * @param {Array|String} array - a lista de ids
 * @param {String} item - o id que precisa ser selecionado
 * @return {Array|String}
 */
export const selectItem = (array, item) => _.union(array, [item]);

/**
 * Remove um item da lista de array
 * @param array
 * @param item
 * @return {*}
 */
export const unselectItem = (array, item) => _.without(array, item);

/**
 * Inverte os selecionado
 * @param array
 * @param item
 */
export const toogleSelected = (array, item) => _.xor(array, _.castArray(item));

/**
 * Faz Upload de varios arquivos
 * @param {Promise} request - o request que vai ser executado
 * @param {Function|Object} params - os parametros que vão ser enviados para o request
 * @param {Array} files - array de arquivos
 * @param {Axios|CancelToken} cancelToken - token de cancelamento do Axios
 * @param {function} onUploadStart - funcao que é disparada quando inicia os requests
 * @param {function} onUploadSuccess - funcao que é disparada quando o upload de um arquivo da certo
 * @param {function} onUploadFail - função que é disparada quando o upload de um arquivo da errado
 * @param {function} onUploadEnd - função que é disparada quando o upload termina
 * @return {Promise}
 */
export const uploadFiles = ({
  request,
  params,
  files,
  maxFiles = 5,

  // Axios configs
  cancelToken,

  // Events
  onUploadStart,
  onUploadSuccess,
  onUploadFail,
  onUploadEnd,
}) => {
  return new Promise(async (resolve, reject) => {
    const upload = { total: 0, success: 0, error: 0 };

    if (files) {
      // Total de arquivos que vão ser enviados
      upload.total = files.length;

      // Inicia o processo de upload
      if (onUploadStart) onUploadStart({ files, ...upload });

      // Separa os arquivos em partes
      const filesChunks = _.chunk(files, maxFiles);

      for (let _files of filesChunks) {
        const chunkPromises = [];

        _files.forEach((file, index) => {
          chunkPromises.push(
            request(params(file, index), {
              cancelToken,
            })
              .then(({ data }) => {
                upload.success += 1;
                if (onUploadSuccess) onUploadSuccess(data, upload, file);
              })
              .catch(() => {
                upload.error += 1;
                if (onUploadFail) onUploadFail(upload, file);
              })
          );
        });

        await Promise.all(chunkPromises);
      }

      // Se cair aqui é por que todos os uploads já acabaram
      if (onUploadEnd) onUploadEnd(upload);
      resolve(upload);
    }
  });
};

export const item = {
  add: addItem,
  addUp: addItemUp,
  alter: alterItem,
  remove: removeItem,
};
