import React, { useContext } from 'react';
import { Wrapper } from './styles';
import RowImage from '../RowImage';
import RowInfos from '../RowInfos';
import RowPrimaryRooms from '../RowPrimaryRooms';
import RowSecondaryRooms from '../RowSecondaryRooms';
import RowEstablishments from '../RowEstablishments';
import RowSituation from '../RowSituation';
import RowTaxes from '../RowTaxes';
import { ThemeProvider } from 'styled-components';
import { ContextCompare } from '../../index';
import Loading from 'components/Loading';

const Table = () => {
  const { isFetching } = useContext(ContextCompare);

  return (
    <ThemeProvider
      theme={{
        paddingX: '15px',
        paddingY: '10px'
      }}
    >
      {isFetching ? (
        <Loading isVisible isBlock />
      ) : (
        <Wrapper>
          <table>
            <RowImage />
            <RowInfos />
            <RowPrimaryRooms />
            <RowTaxes />
            <RowSituation />
            <RowSecondaryRooms />
            <RowEstablishments />
          </table>
        </Wrapper>
      )}
    </ThemeProvider>
  );
};

export default Table;
