import React from 'react';
// Components
import { FieldInterval } from 'components/Form';
import useFormValue from 'hooks/useFormValue';

function RevisionField({ nameMin, nameMax }) {
  const startDate = useFormValue(nameMin);
  const endDate = useFormValue(nameMax);

  return (
    <FieldInterval
      title="Inativado entre"
      nameMax={nameMin}
      nameMin={nameMax}
      startDate={startDate}
      endDate={endDate}
    />
  );
}

RevisionField.defaultProps = {
  nameMin: 'updated_lower_equals',
  nameMax: 'updated_greater_equals'
};

export default RevisionField;
