import React, { memo } from 'react';
import PropTypes from 'prop-types';
// Components
import TableItem from './components/TableItem';
import NoDeals from './components/NoDeals';
// Shapes
import { DealShape } from 'components/TableListDeals/shapes';
import Loading from 'components/Loading';

function TableListDeals({ deals, isFetching, onUpdateDeal, pagination }) {
  if (isFetching) return <Loading isVisible isBlock />;
  if (deals?.length <= 0) return <NoDeals />;

  return (
    <table className="Table">
      <thead>
        <tr>
          <th>Nome ({pagination.total})</th>
          <th>Imóvel</th>
          <th>Interação</th>
          <th className="Table__cell--small">Etapa</th>
          <th>Qualificação</th>
          <th className="Table__cell--small" />
        </tr>
      </thead>
      <tbody>
        {deals?.map((deal) => (
          <TableItem
            key={`deal-${deal?.updated_at}-${deal.id}`}
            deal={deal}
            onUpdateDeal={onUpdateDeal}
          />
        ))}
      </tbody>
    </table>
  );
}

TableListDeals.defaultProps = {
  deals: [],
};

TableListDeals.propTypes = {
  deals: PropTypes.arrayOf(DealShape),
};

export default memo(TableListDeals);
