import React, { useEffect } from 'react';
import MainTitle from 'components/MainTitle';
import { Field, reduxForm } from 'redux-form';
import { SelectMultiple } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import * as dealsService from 'services/deals';
import { parseSelect } from '../../../../../../lib/formHelpers';

// import { Wrapper } from './styles';

function ConfigFunnel({ handleSubmit, submit, initialize }) {
  const { data: defaultStage } = useRequest({
    request: dealsService.defaultStage,
  });

  const { data: funnelsAndStages } = useRequest({
    request: dealsService.getAllStages,
  });

  const onChangeInput = () => {
    setTimeout(() => {
      submit();
    }, 100);
  };

  useEffect(() => {
    initialize({
      rental: defaultStage?.rental?.default_stage_id,
      sales: defaultStage?.sales?.default_stage_id,
    });
  }, [defaultStage]);

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Distribuição padrão de funil por negócio"
        text="Defina o funil padrão para as transações. No cadastro do imóvel é possível definir um funil personalizado para o imóvel."
      />
      <table className="Table">
        <thead>
          <tr>
            <th>Transação</th>
            <th>Descrição</th>
            <th width={300}>Funil associado</th>
          </tr>
        </thead>
        <tbody>
          <tr key={defaultStage?.sales?.id}>
            <td>
              <strong>{defaultStage?.sales?.name}</strong>
            </td>
            <td>{defaultStage?.sales?.description}</td>
            <td>
              <Field
                clearable={false}
                multi={false}
                name="sales"
                options={funnelsAndStages}
                labelKey="name"
                valueKey="stage_id"
                component={SelectMultiple}
                styleFormGroup={{ width: 300 }}
                valueRenderer={(group) => {
                  return `${group.group.name} -> ${group.name}`;
                }}
                onChange={onChangeInput}
                parse={parseSelect('stage_id')}
              />
            </td>
          </tr>
          <tr key={defaultStage?.rental?.id}>
            <td>
              <strong>{defaultStage?.rental?.name}</strong>
            </td>
            <td>{defaultStage?.rental?.description}</td>
            <td>
              <Field
                clearable={false}
                multi={false}
                name="rental"
                options={funnelsAndStages}
                labelKey="name"
                valueKey="stage_id"
                component={SelectMultiple}
                styleFormGroup={{ width: 300 }}
                valueRenderer={(group) => {
                  return `${group.group.name} -> ${group.name}`;
                }}
                onChange={onChangeInput}
                parse={parseSelect('stage_id')}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
}

export default reduxForm({
  form: 'ConfigFunnel',
  onSubmit: (values) => {
    return dealsService.updateDefaultStage(values);
  },
})(ConfigFunnel);
