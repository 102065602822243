import { combineReducers } from 'redux';
// Modules
import dataReducer from 'modules/data';
import { hideLoading, showLoading } from 'modules/loading';
// Services
import * as propertiesService from 'services/properties';
import * as userService from 'services/user';
import * as propertiesMatchedService from 'services/properties-matched';

export const MODULE_NAME = 'propertiesMatched';

export const {
  selectors: propertiesSelector,
  reducer: propertiesReducer,
  actionCreators: propertiesActions,
} = dataReducer(`propertiesMatched/properties`);

export const {
  selectors: usersSelector,
  reducer: usersReducer,
  actionCreators: usersActions,
} = dataReducer(`propertiesMatched/users`);

export const removeProfile = (property, params) => (dispatch) => {
  dispatch(propertiesActions.removing(property.id));

  return propertiesMatchedService.removeProfile(property).then(() => {
    dispatch(propertiesActions.remove(property.id));
  });
};

export const removeAllProfiles = () => async (dispatch) => {
  try {
    await propertiesMatchedService.removeAllProfiles();
    fetchProperties()(dispatch);
  } catch (e) {
    return false;
  }
};

export const fetchProperties =
  (params = { filter: null }, offset) =>
  (dispatch) => {
    dispatch(propertiesActions.request());

    return propertiesService
      .getList({
        ...params,
        filter: {
          by_matched: 1,
          is_booked: false,
          on_network: 'all',
          ...params.filter,
        },
        count: 'new_people_matched',
        limit: 20,
        offset: offset,
      })
      .then((res) => {
        dispatch(propertiesActions.receive(res.data));
        return res;
      })
      .catch(() => {
        dispatch(propertiesActions.reset());
      });
  };

export const fetchUsers = (params) => (dispatch) => {
  dispatch(usersActions.request());

  return userService.getRealtors(params).then((res) => {
    dispatch(usersActions.receive(res.data));
    return res;
  });
};

export const handleChangeFormProperty = (values) => (dispatch) => {
  dispatch(showLoading());

  return fetchProperties(values)(dispatch).finally(() => {
    dispatch(hideLoading());
  });
};

export default combineReducers({
  properties: propertiesReducer,
  users: usersReducer,
});
