import { useRequest } from 'hooks/useRequest';
import * as userService from 'services/user';

export const useRealtors = () => {
  const { data, isFetching } = useRequest({
    request: userService.getRealtors,
  });

  return { data, isFetching };
};
