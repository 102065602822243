import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, FieldArray, formValueSelector, initialize, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import VideosField from '../VideosField';
import Button from 'components/Button';
import Container from 'pages/Site/components/ContainerSite';
import FixedBar from 'components/FixedBar';
import ListOptions from 'components/ListOptions';
// Components
import { Input, Select } from 'components/Form';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

class FormVideos extends React.Component {
  render() {
    const { is_videos_shown, submitting, handleSubmit } = this.props;

    const style = {
      opacity: is_videos_shown ? 1 : 0.3,
      pointerEvents: is_videos_shown ? 'auto' : 'none'
    };

    return (
      <form onSubmit={handleSubmit}>
        <Container>
          <ListOptions>
            <ListOptions.item
              title="Mostrar Vídeos na página inicial"
              text="Mostrar na página inicial uma galeria com vídeos a sua escolha."
              renderOptions={() => (
                <Field
                  canRemoveSelf={false}
                  buttonTemplate
                  name="is_videos_shown"
                  component={Select}
                  options={[
                    { value: true, label: 'Mostrar' },
                    { value: false, label: 'Esconder', _color: 'danger' }
                  ]}
                />
              )}
            ></ListOptions.item>
            <ListOptions.item
              title="Título da área"
              text="Edite o título que ficará acima dos vídeos."
              style={style}
            >
              <div className="h-margin-top--10">
                <Field name="videos.title" component={Input} />
              </div>
            </ListOptions.item>
            <FieldArray
              style={style}
              name="videos.items"
              component={VideosField}
            />
          </ListOptions>
          <FixedBar style={{ left: 240 }}>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success" disabled={submitting}>
              Salvar
            </Button>
          </FixedBar>
        </Container>
      </form>
    );
  }
}

FormVideos.defaultProps = defaultProps;
FormVideos.propTypes = propTypes;

const selector = formValueSelector('formVideos');

const mapStateToProps = state => ({
  is_videos_shown: selector(state, 'is_videos_shown')
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'formVideos',
    onSubmitSuccess: (res, dispatch) => {
      // Reinicializa o formulário com os valores
      dispatch(initialize('formVideos', res.data, false));
    },
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
  })
)(FormVideos);
