import React from 'react';
import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
import * as propertiesBooking from 'services/propertiesBooking';
import moment from 'lib/moment';
import { useDispatch } from 'react-redux';
import { openModalProperty } from 'modules/modal';

const more15days = moment().add('15', 'days').format('DD/MM/YYYY');

function ModalReservationCreate({
  property,
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  onSuccess,
  afterClose,
}) {
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    return propertiesBooking.create({ property_id: property?.id, ...values });
  };

  const close = () => {
    handleClose();
    dispatch(
      openModalProperty({ initialPage: 'Reservations', property, afterClose })
    );
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Reservar imóvel para cliente"
        text="Sinalize aos corretores que existe um cliente em fase de negociação com este imóvel"
        handleClose={handleClose}
      >
        <Form
          initialValues={{
            booked_until: more15days,
            should_change_publish: true,
            should_close_matcheds: true,
          }}
          onSubmit={handleSubmit}
          handleClose={close}
          onSubmitSuccess={() => {
            dispatch(
              openModalProperty({
                initialPage: 'Reservations',
                property,
                afterClose,
              })
            );
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalReservationCreate;
