import { Route, Switch, useRouteMatch } from 'react-router-dom';
import * as pages from './pages';
import React, { memo } from 'react';

function FeaturedLists() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id/properties`} component={pages.ListProperties} />
      <Route path={`${path}/`} component={pages.ListFeaturedLists} />
    </Switch>
  );
}

export default memo(FeaturedLists);
