import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Components
import TableData from './components/TableData';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
// Services
import * as dealsService from 'services/deals';
// Modules
import { openModalLossReason, openModalLossReasonRemove } from 'modules/modal';
import { useRequest } from 'hooks/useRequest';

function LossReasons() {
  const dispatch = useDispatch();
  const { data, isFetching, fetchData, update } = useRequest({
    request: dealsService.getLossReasonsCustom,
    initialState: []
  });

  const handleDelete = ({ id, lost_deals_count }) => async () => {
    dispatch(
      openModalLossReasonRemove({
        id,
        lostDealsCount: lost_deals_count,
        onSuccess: () => {
          fetchData();
        }
      })
    );

    // dispatch(openConfirmation({
    //   title: 'Deletar motivo',
    //   text: 'Você tem certeza que quer deletar esse motivo?',
    //   request: () => {
    //     dealsService.removeLossReasonById(id);
    //   },
    //   onSuccess: () => {
    //     remove(id);
    //   }
    // }));
  };

  const handleEdit = ({ id }) => () => {
    openModalLossReason({
      id: id,
      onSubmitSuccess: ({ data: item }) => {
        update(item);
      }
    })(dispatch);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper.container>
      <MainTitle
        title="Motivos da perda"
        text="Gerencie aqui todos os motivos de perda que você cadastrou no sistema."
      />
      <TableData
        data={data}
        isFetching={isFetching}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Wrapper.container>
  );
}

LossReasons.propTypes = {};
LossReasons.defaultProps = {};

export default LossReasons;
