import { categoriesReport } from '../services/reportsCategories';
import { useRequest } from 'hooks/useRequest';

function percentageToNumber(percentage) {
  return parseFloat(percentage.replace('%', ''));
}

// Função para formatar os dados da API
const formatData = (items) => {
  if (!items || !items.length) return [];

  return items.map((item) => ({
    name: item?.title,
    value: Math.abs(item?.value_raw),
    color: item?.color,
    percent: percentageToNumber(item?.percent),
  }));
};

export function useCategoriesReport() {
  const { data, meta, isFetching, fetchData } = useRequest({
    request: categoriesReport,
    initialFetch: false,
  });

  // Dados formatados para os gráficos
  const dataIncome = formatData(data.credits);
  const dataExpenses = formatData(data.debits);

  return {
    categories: data,
    dataIncome,
    dataExpenses,
    metaCategories: meta,
    isFetchingCategories: isFetching,
    fetchCategories: fetchData,
  };
}
