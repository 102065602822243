import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: 1px solid ${(p) => p.theme.borderColor};
    border-radius: 4px;
  }

  button {
    position: absolute;
    top: 2px;
    right: 2px;
    height: calc(100% - 4px);
    padding: 0 10px 0 30px;
    background-image: linear-gradient(to right, transparent 0%, #fff 30%);
    color: ${(p) => p.theme.colors.secondary};
  }
`;

export const Copy = styled.div``;
