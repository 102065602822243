import React from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
// Components
import Button from 'components/Button';
import FormError from 'components/Form/components/FormError';
import Avatar from 'components/Avatar';
// Constants
import { ACCEPT_ALL_FILES } from 'constants/config';
// Helpers
import { MAX_SIZE, onDropRejected } from 'lib/file-helpers';
import { FormLabel } from 'components/Form/index';
import { useDispatch } from 'react-redux';

const propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.bool,
  accept: PropTypes.string,
  activeClassName: PropTypes.string,
  acceptClassName: PropTypes.string,
  rejectClassName: PropTypes.string,
  disabledClassName: PropTypes.string,
  preventDropOnDocument: PropTypes.bool,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.bool,
  }).isRequired,
};

const defaultProps = {
  avatar: false,
  accept: ACCEPT_ALL_FILES,
  preventDropOnDocument: false,
  multiple: true,
  className: 'Dropzone',
  activeClassName: 'Dropzone--active',
  acceptClassName: 'Dropzone--accept',
  rejectClassName: 'Dropzone--reject',
  disabledClassName: 'Dropzone--disabled',

  // Máximo que aceita é 5 mb
  maxSize: MAX_SIZE,
  style: {},
  children: <Button color="primary">Enviar Foto</Button>,
};

const FileInput = (props) => {
  const dispatch = useDispatch();
  const {
    fileUrl,
    avatar,
    children,
    clearField,
    label,
    input,
    multiple,
    meta: { touched, error },
    onDropReject,
    maxFiles,
    ...dropzoneProps
  } = props;

  const hasError = touched && error;

  return (
    <div className="Dropzone">
      {!!label && <FormLabel>{label}</FormLabel>}
      <Dropzone
        {...dropzoneProps}
        maxFiles={maxFiles}
        onDrop={(filesToUpload) => {
          if (!filesToUpload) return;
          if (multiple) {
            return input.onChange(filesToUpload);
          }
          input.onChange(filesToUpload[0]);
        }}
        onDropRejected={onDropRejected(onDropReject, dispatch)}
      >
        {_.isFunction(children)
          ? children({
              fileUrl,
              label,
              input,
              ...dropzoneProps,
            })
          : children}
      </Dropzone>
      {clearField && (
        <button type="button" onClick={clearField}>
          Limpar
        </button>
      )}
      <FormError isVisible={hasError} />
      {touched && error && <span className="error">{error}</span>}
      {avatar && (label || input.value[0]) && (
        <Avatar
          image={input.value[0] && input.value[0].file_url}
          text={label}
        />
      )}
    </div>
  );
};

FileInput.propTypes = propTypes;
FileInput.defaultProps = defaultProps;

export default FileInput;
