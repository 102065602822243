import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Modules
import { openModalEvent } from 'modules/modal';

import {
  cancelEvent,
  eventsSelector,
  finishEvent,
  getEvents,
  handleEventFormSubmit,
  hideEventForm,
  showEventForm,
} from 'modules/personEvents';
// Components
import Button from 'components/Button';
import { ModalSubtitle } from 'components/Modal';
import Nodata from 'components/Nodata';
// Assets
import clock from 'assets/img/noData/clock.svg';
// Helpers
import { plural } from 'lib/text';
import Can from 'containers/Can';
import { PERSON_PERMISSIONS } from 'constants/rules';
import Timeline from 'components/Timeline';
import RequestPermission from 'containers/ModalRoot/ModalPerson/components/RequestPermission';
import { currentUserSelector } from 'modules/login';
import { MdDelete } from 'react-icons/md';
import styled from 'styled-components';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { removeEvent } from 'services/people';

const Btn = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  color: ${(p) => p.theme.colors.danger};
`;

const propTypes = {
  getEvents: PropTypes.func.isRequired,
  showEventForm: PropTypes.func.isRequired,
  hideEventForm: PropTypes.func.isRequired,
  cancelEvent: PropTypes.func.isRequired,
  finishEvent: PropTypes.func.isRequired,
};

const defaultProps = {};

class Events extends React.Component {
  get canFetchEvents() {
    const {
      modalPerson: { person },
    } = this.props;

    return person.permissions.includes(PERSON_PERMISSIONS.CALENDAR);
  }

  componentDidMount() {
    if (this.canFetchEvents) {
      this.props.getEvents(this.props.person.id);
    }
  }

  handleClickScheduleEvent = () => {
    const {
      person,
      person: { id: people_id },
      openModalEvent,
      openModalPerson,
    } = this.props;

    openModalEvent({
      initialValues: {
        people_id,
      },
      onSubmitSuccess: () => {
        openModalPerson(person);
      },
    });
  };

  handleEditEvent = (event) => () => {
    this.props.openModalEvent({
      initialValues: event,
      afterClose: () => {
        this.props.reopenModal({ initialPage: 'Events' });
      },
    });
  };

  handleRemoveEvent = (event) => () => {
    this.props.openConfirmation({
      request: () =>
        removeEvent({ people: { id: event?.people_id }, id: event.id }),
      title: 'Deseja remover esse evento?',
      text: (
        <>
          <p>
            Se voce deseja remover o evento: <strong>{event.summary}</strong>
          </p>
          <p>Começa: {event.start_date}</p>
          <p>Termina: {event.end_date}</p>
        </>
      ),
      onSuccess: () => {
        this.props.getEvents(this.props.person.id);
        this.props.fetchPerson();
      },
    });
  };

  render() {
    const {
      modalPerson: { person },
      currentUser,
    } = this.props;

    return (
      <Can
        run={PERSON_PERMISSIONS.CALENDAR}
        permissions={person.permissions}
        no={() => <RequestPermission person={person} />}
        yes={() => {
          const {
            events,
            meta: { isFetching, showEventForm },
          } = this.props;
          const eventsLength = events.length;
          const hasEvents = events && eventsLength !== 0;

          return (
            <div>
              {hasEvents ? (
                <>
                  <ModalSubtitle
                    title={plural(eventsLength, 'atividade', 'atividades')}
                  >
                    <Button
                      color="secondary"
                      onClick={this.handleClickScheduleEvent}
                    >
                      Agendar compromisso
                    </Button>
                  </ModalSubtitle>

                  <div className="Timeline--events">
                    <Timeline
                      data={events}
                      renderItem={(event) => {
                        const {
                          id,
                          color,
                          summary,
                          description,
                          start_date,
                          user,
                        } = event;

                        return (
                          <Timeline.item
                            icon="account"
                            iconColorHex={color}
                            key={id}
                            title={summary}
                            renderText={() => (
                              <>
                                <div
                                  style={{ padding: '5px 0' }}
                                  className="ql-editor"
                                  dangerouslySetInnerHTML={{
                                    __html: description,
                                  }}
                                />
                                <p>
                                  {start_date} - {(user || {}).name}
                                </p>
                              </>
                            )}
                            renderActions={() => {
                              if (event.user.id !== currentUser.id) return null;

                              return (
                                <>
                                  <Button
                                    color="white"
                                    onClick={this.handleEditEvent(event)}
                                  >
                                    Editar
                                  </Button>
                                  <Btn onClick={this.handleRemoveEvent(event)}>
                                    <MdDelete />
                                  </Btn>
                                </>
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </>
              ) : !showEventForm ? (
                <Nodata
                  image={clock}
                  isFetching={isFetching}
                  title="Nenhum compromisso agendado."
                  text="Adicione seu primeiro compromisso no botão abaixo:"
                >
                  <Button
                    color="secondary"
                    className="h-margin-top--10"
                    onClick={this.handleClickScheduleEvent}
                  >
                    Agendar compromisso
                  </Button>
                </Nodata>
              ) : null}
            </div>
          );
        }}
      />
    );
  }
}

Events.propTypes = propTypes;
Events.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  events: eventsSelector(state),
  currentUser: currentUserSelector(state),
  meta: state.personEvents.meta,
  person: state.modalPerson.person,
});

const mapDispatchToProps = {
  getEvents,
  showEventForm,
  hideEventForm,
  cancelEvent,
  finishEvent,
  handleEventFormSubmit,
  openModalEvent,
  openConfirmation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
