import Container from 'components/Container';
import Loading from 'components/Loading';
import Header from './components/Header';
import TableUsers from './components/TableUsers';
import { useSelector } from 'react-redux';
import { invitesSelector, isFetchingSelector } from 'modules/invites';
import Nodata from 'components/Nodata';
import client from 'assets/img/noData/client.svg';

function InvitedUsers() {
  const isFetching = useSelector(isFetchingSelector);
  const invites = useSelector(invitesSelector);

  const hasData = !isFetching && invites?.length > 0;

  return (
    <>
      <Header />
      <Container padding>
        <Loading isFullScreen isVisible={isFetching} />
        {hasData ? (
          <>
            {!isFetching && <TableUsers image={client} invites={invites} />}          
          </>
        ) : (
          <Nodata
            image={client}
            title="Nenhum convite pendente no momento"
          />
        )}
      </Container>
    </>
  );
}

export default InvitedUsers;
