// Widgets.js
import { createModule } from '../lib/reducer-helpers';

const createAction = createModule('configDocuments');

export const documentSelector = (state) => state.configDocuments;

// Actions
const SET = createAction('SET');
const RESET = createAction('RESET');

// Initial State
const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET:
      return action.data;
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export const setDocument = (data) => {
  return { type: SET, data };
};

export const resetDocument = () => {
  return { type: RESET };
};
