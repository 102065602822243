import React from 'react';
// Component
import Table from 'components/Table';
import BillingItem from '../BillingItem';
import Check from 'components/Check';
import { addCurrency } from 'lib/money-helpers';

const BillingTable = ({
  data,
  meta,
  toggleChecked,
  isAllSelected,
  toggleAll
}) => (
  <Table
    responsive
    data={data}
    isFetching={false}
    renderTitle={() => (
      <tr>
        <th className="h-sticky--left h-text-nowrap">
          <Check
            checked={isAllSelected}
            onClick={toggleAll}
            className="h-margin-right--15"
          />
          Corretor
        </th>
        {data[0] &&
          data[0].data.map(dat => (
            <th key={dat.period} className="Table__cell--money">
              {dat.period}
            </th>
          ))}
        <th className="Table__cell--money h-sticky--right">Total</th>
      </tr>
    )}
    renderItem={(data, index) => (
      <BillingItem
        key={`item-${data.broker}-${index}`}
        data={data}
        toggleChecked={toggleChecked}
      />
    )}
    renderFooter={() => (
      <tr>
        <td className="h-sticky--left" />
        {meta?.periods?.map(period => (
          <td className="Table__cell--money" key={`result-${period}`}>
            {addCurrency(meta?.results[period])}
          </td>
        ))}
        <td className="Table__cell--money h-sticky--right">
          {addCurrency(meta?.results?.total)}
        </td>
      </tr>
    )}
  />
);

export default BillingTable;
