import React, { createContext, useContext, useState } from 'react';
import { Wrapper, Content, Spacing, ContentInner } from './styles';
import ItemNav from 'components/MainHeader2/components/ItemNav';
import Item from './components/Item';
import ItemMenu from './components/ItemMenu';
import classnames from 'classnames';

const MenuHeaderContext = createContext({
  isOpen: false,
  setIsOpen: () => null,
});

function MenuHeader({
  title,
  exact,
  activeClassName,
  className,
  onClick,
  href,
  to,
  active,
  children,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = (e) => {
    setIsOpen(true);
  };

  const handleMouseLeave = (e) => {
    setIsOpen(false);
  };

  return (
    <MenuHeaderContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      <Wrapper
        className={classnames({
          'is-open': isOpen,
          className,
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ItemNav
          href={href}
          active={active}
          label={title}
          exact={exact}
          to={to}
          activeClassName={activeClassName}
          className={className}
          onClick={onClick}
        />
        <Content>
          <Spacing />
          <ContentInner>{children}</ContentInner>
        </Content>
      </Wrapper>
    </MenuHeaderContext.Provider>
  );
}

export const useMenuHeader = () => useContext(MenuHeaderContext);

export { Item, ItemMenu };

export default MenuHeader;
