import React from 'react';
import Dropdown from '../Dropdown';
import { ButtonNew, MenuItem } from '../../styles';
import Actions from './components/Actions';

const MenuActionsNew = () => {
  return (
    <MenuItem className="js-button-new">
      <Dropdown width={270} position="center" component={Actions}>
        <ButtonNew>+ Novo</ButtonNew>
      </Dropdown>
    </MenuItem>
  );
};

export default MenuActionsNew;
