import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import Characteristics from '../../components/Characteristics';
// Modules
import {
  condoCharacteristicsSelector,
  fetchCondoCharacteristics,
  typeSelector,
} from '../../module';

const CondoCharacteristicsContainer = () => {
  const dispatch = useDispatch();
  const typeSelected = useSelector(typeSelector);
  const characteristics = useSelector(condoCharacteristicsSelector.getAll);

  useEffect(() => {
    if (typeSelected?.id) {
      dispatch(fetchCondoCharacteristics({ type_id: typeSelected?.id }));
    }
  }, [typeSelected?.id]);

  if (!typeSelected?.id) return null;

  return (
    <Characteristics
      isCondo
      name="condo_characteristics"
      type={typeSelected}
      data={characteristics}
    />
  );
};

export default memo(CondoCharacteristicsContainer);
