// import styles from './styles'

import { Table } from 'features/Financial';
import Button from 'components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import * as S from './styles';
import { useDeleteAccount } from 'features/Financial/hooks/useAccounts';
import { openModalStoreAccount } from 'features/Financial/modals/ModalStoreAccount';
import { useDispatch } from 'react-redux';
import { openModalAjustAccount } from 'features/Financial/modals/ModalAjustAccount';
import { Nodata } from './Nodata';
import { AiOutlineFileText } from 'react-icons/all';

export function TableCards({ data, meta, onPageChange }) {
  const dispatch = useDispatch();
  const { deleteAccount } = useDeleteAccount();
  const length = data.length;

  const handleClickOpenModal =
    ({ data }) =>
    () => {
      dispatch(
        openModalStoreAccount({
          data,
          type: 2,
          onSuccess: () => {
            onPageChange({ selected: 0 });
          },
        })
      );
    };

  const handleClickAjust = (account) => () => {
    dispatch(
      openModalAjustAccount({
        data: account,
        type: 2,
        onSuccess: () => {
          onPageChange({ selected: 0 });
        },
      })
    );
  };

  if (!data || data.length <= 0)
    return <Nodata onAddAccount={handleClickOpenModal} />;

  return (
    <Table.List data={data} meta={meta} onPageChange={onPageChange}>
      {(item) => (
        <Table.Tr>
          <Table.Td>
            <S.Text>
              <S.Icon src={item.icon.file_url} />
              <S.TextContent>
                <S.Title>{item.title}</S.Title>
                <S.Date>
                  Fecha dia {item.closing_day} | Vence dia {item.due_day}
                </S.Date>
              </S.TextContent>
            </S.Text>
          </Table.Td>
          <Table.Td width={200} onClick={handleClickAjust(item)}>
            <S.WrapperPrice>
              <S.Price>Limite {item.limit}</S.Price>
              <S.Link>Ajustar limite</S.Link>
            </S.WrapperPrice>
          </Table.Td>
          <Table.Td width="40%">
            <Table.Actions>
              <Button
                to={`/financial/credit-card/${item.id}`}
                size="small"
                color="white"
              >
                <AiOutlineFileText /> Ver faturas
              </Button>
              <Button
                size="small"
                color="white"
                onClick={handleClickOpenModal({ data: item })}
              >
                <MdEdit />
              </Button>
              <Button
                size="small"
                color="white"
                onClick={deleteAccount({
                  account: item,
                  onSuccess: () => {
                    onPageChange({ selected: 0 });
                  },
                })}
              >
                <MdDelete className="h-color--danger" />
              </Button>
            </Table.Actions>
          </Table.Td>
        </Table.Tr>
      )}
    </Table.List>
  );
}
