// import styles from './styles'

import { Field } from 'redux-form';
import { Input } from 'components/Form';
import React from 'react';

export default function FieldVideoUrl({
  xs = 12,
  label = 'Insira a URL de um vídeo do Youtube',
  name = 'video',
  placeholder = 'Exemplo: https://www.youtube.com/watch?v=t3217H8JppI',
}) {
  return (
    <Field
      xs={xs}
      label={label}
      name={name}
      component={Input}
      placeholder={placeholder}
    />
  );
}
