import React, { useEffect } from 'react';
// Components
import Form from './components/Form';
import Chart from './components/Chart';
import Table from './components/Table';
// Constantes
import { useReport } from '../hooks';
import { getPeoplePerPeriod } from 'services/reportsPeople';
import WrapperReport from '../../components/WrapperReport';
import moment from 'moment';
// Module

export default function PeoplePerPeriod() {
  const {
    data,
    meta,
    selected,
    fetchData,
    chartData,
    isFetching,
    toggle,
    isAllSelected,
    toggleAll,
    dataSelecteds
  } = useReport({
    request: getPeoplePerPeriod,
    key: 'type'
  });

  useEffect(() => {
    const startDate = moment()
      .subtract(5, 'month')
      .startOf('month')
      .format('DD/MM/YYYY');

    const endDate = moment()
      .endOf('month')
      .format('DD/MM/YYYY');

    fetchData({
      start_date: startDate,
      end_date: endDate
    });
  }, []);

  return (
    <WrapperReport
      title="Captação de Clientes Geral"
      text="Veja quantos clientes foram captados em determindo período."
      data={data}
      isFetching={isFetching}
      renderForm={() => <Form onSubmit={fetchData} />}
      renderChart={() => (
        <Chart
          width={'100%'}
          height={250}
          selected={selected}
          data={chartData}
          meta={meta}
        />
      )}
      renderTable={() => (
        <Table
          isAllSelected={isAllSelected}
          toggleAll={toggleAll}
          data={dataSelecteds}
          meta={meta}
          handleToggleItem={toggle}
        />
      )}
    />
  );
}
