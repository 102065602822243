import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Modules
import { openModalProperty } from 'modules/modal';
// Styles
import { Link, Status } from '../../styles';
import { Cell } from './styles';

export default function CellProperty({ isStagnant, property }) {
  const dispatch = useDispatch();

  /**
   * Abre a modal da cliente
   */
  const handleClickProperty = useCallback(() => {
    dispatch(openModalProperty({ property }));
  }, [dispatch, property]);

  return (
    <Cell isStagnant={isStagnant} onClick={handleClickProperty}>
      <Link as="span">
        {property?.type?.title} <Status property={property} />
      </Link>
      <Link as="span">{property?.price}</Link>
    </Cell>
  );
}
