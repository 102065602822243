import styled, { css } from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  .contract--first {
    td:first-child {
      border-top-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
    }
  }

  .contract--last {
    td:first-child {
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-bottom-right-radius: 10px;
    }
  }

  .contract--expanded td {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;
export const MainContract = styled.tr`
  > td {
    background: #0063c0;
    padding: 10px;
    white-space: nowrap;
  }
`;

export const SubContract = styled.tr`
  td {
    background: #0c3b67;
    padding: 10px;
  }

  ${(p) =>
    p.isExpanded &&
    css`
      td {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    `}
`;

export const Spacer = styled.tr.attrs(() => ({
  height: 20,
}))``;

export const Status = styled.td`
  font-weight: 600;
  width: 0;
  white-space: nowrap;
  padding: 0 0 0 15px !important;
  color: #fff;

  ${(p) =>
    p.type === 'active'
      ? css`
          background: ${p.theme.colors.success} !important;
        `
      : css`
          background: #0084f4 !important;
        `}
`;

export const TextStatus = styled.span`
  width: 90px;
`;

export const TdDropdown = styled.td`
  padding: 0 !important;
`;

export const Dropdown = styled.button`
  width: 30px;
  height: 46px;
  cursor: pointer;
  margin-left: 5px;

  :hover {
    background: rgba(255, 255, 255, 0.08);
  }

  svg {
    fill: #fff;
  }
`;

export const DetailRow = styled.tr`
  td {
    background: #fff;
    padding: 15px;
    border: 1px solid #d3dceb;
  }
`;
