import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { getBoundProps } from './helpers';
import CardProperty from 'components/CardProperty';
import { PROPERTY_SHAPE } from 'constants/shapes';

function MapLeaflet({
  properties,
  dragging,
  tab,
  style,
  scrollWheelZoom,
  onClose,
  onOpen,
  renderProperties,
  zoom
}) {
  // Se não tiver nenhum imóvel não deve renderizar o mapa
  if (properties?.length <= 0) return null;

  // Pega as propriedades necessárias
  // para fazer com que o zoom no mapa fique correto
  const boundProps = getBoundProps(properties);

  return (
    <Map
      {...boundProps}
      dragging={dragging}
      tap={tab}
      style={style}
      scrollWheelZoom={scrollWheelZoom}
      zoom={zoom}
    >
      <TileLayer
        url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
      />
      {properties.map((p, index) =>
        renderProperties({ property: p, index, onOpen, onClose })
      )}
    </Map>
  );
}

MapLeaflet.defaultProps = {
  zoom: null,
  properties: [],
  dragging: true,
  tab: true,
  renderProperties: ({ property: p, onClose, onOpen }) => {
    return (
      <Marker key={p.id} position={[p.maps_latitude, p.maps_longitude]}>
        <Popup onClose={onClose} onOpen={() => onOpen && onOpen(p)}>
          <CardProperty property={p} type="vertical" />
        </Popup>
      </Marker>
    );
  },
  scrollWheelZoom: false,
  style: { height: '100%' }
};

MapLeaflet.propTypes = {
  zoom: PropTypes.number,
  properties: PropTypes.arrayOf(PROPERTY_SHAPE),
  dragging: PropTypes.bool,
  marker: PropTypes.node,
  tap: PropTypes.bool,
  scrollWheelZoom: PropTypes.bool,
  style: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};

export default MapLeaflet;
