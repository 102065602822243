import React from 'react';
import { countries } from 'country-flag-icons';
import flags from 'country-flag-icons/react/3x2';

function Flag({ size = 20, style, iso2, ...props }) {
  // Transforma o iso2 vindo pra Uppercase
  // porque o objeto flags exportado pelo
  // pacote country-flag-icons está em maiúsculo
  const iso2Upper = iso2.toUpperCase();

  // Verifica se tem a bandeira na biblioteca
  // Também verifica se o número é brasileiro se for não aparece bandeira
  if (!countries.includes(iso2Upper) || iso2Upper === 'BR') return null;

  return React.createElement(
    flags[iso2Upper],
    { ...props, style: { width: size, ...style } },
    null
  );
}

export default Flag;
