import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
// import PropTypes from 'prop-types';

const defaultProps = {};
const propTypes = {};

const data = [
  {
    data: 'Jun/2017',
    'Acessos no site': 100,
    'Contatos Recebidos': 200,
    'Envios por e-mail': 300
  },
  {
    data: 'Jul/2017',
    'Acessos no site': 250,
    'Contatos Recebidos': 150,
    'Envios por e-mail': 500
  },
  {
    data: 'Ago/2017',
    'Acessos no site': 300,
    'Contatos Recebidos': 400,
    'Envios por e-mail': 350
  }
];

const CalcItems = ({ data }) => {
  return (
    <Row
      style={{ width: 550, margin: '20px auto 20px' }}
      className="Calc h-text-center"
    >
      <Col xs className="Calc__item">
        <h1 className="h-color--success">
          {data.slice(-1)[0]['Acessos no site']}
        </h1>
        <p>Acessos no site</p>
      </Col>
      <Col xs className="Calc__item">
        <h1 className="h-color--caution">
          {data.slice(-1)[0]['Contatos Recebidos']}
        </h1>
        <p>Contatos Recebidos</p>
      </Col>
      <Col xs className="Calc__item">
        <h1 style={{ color: '#0063c0' }}>
          {data.slice(-1)[0]['Envios por e-mail']}
        </h1>
        <p>Envios por e-mail</p>
      </Col>
    </Row>
  );
};

class Statistics extends Component {
  render() {
    return (
      <div>
        <CalcItems data={data} />
        <ResponsiveContainer width="100%" height={350}>
          <LineChart data={data}>
            <CartesianGrid vertical={false} stroke="#F1F1F1" />
            <XAxis
              stroke="#F1F1F1"
              minTickGap={100}
              dataKey="data"
              tick={{
                strokeWidth: 0,
                fill: '#3e587c',
                style: { transform: 'translateY(5px)' }
              }}
            />
            <YAxis
              width={40}
              stroke="#F1F1F1"
              tick={{ strokeWidth: 0, fill: '#3e587c' }}
            />
            <Tooltip />
            <Legend margin={{ top: 10 }} />
            <Line type="monotone" dataKey="Acessos no site" stroke="#00c080" />
            <Line
              type="monotone"
              dataKey="Contatos Recebidos"
              stroke="#f1a331"
            />
            <Line
              type="monotone"
              dataKey="Envios por e-mail"
              stroke="#0063c0"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

Statistics.defaultProps = defaultProps;
Statistics.propTypes = propTypes;

export default Statistics;
