import { scrollToElement } from 'lib/animation-helpers';
import React, { Component } from 'react';
import {
  MdAttachFile,
  MdAttachMoney,
  MdCropFree,
  MdDescription,
  MdEdit,
  MdHome,
  MdHotel,
  MdInfo,
  MdLandscape,
  MdLock,
  MdPlace,
  MdRotateLeft,
  MdStore,
} from 'react-icons/md';
import { FaGoogle } from 'react-icons/fa';

import { TiSocialYoutube } from 'react-icons/ti';

import NavAside from 'components/NavAside';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

class Aside extends Component {
  constructor(props) {
    super(props);

    this.state = {
      links: [
        //{ isVisible: true, active: true, title: 'Proprietário', selector: '.js-owner', icon: MdPerson },
        {
          isVisible: true,
          active: true,
          title: 'Informações',
          selector: '.js-information',
          icon: MdInfo
        },
        {
          isVisible: true,
          active: false,
          title: 'Condomínio',
          selector: '.js-condos',
          icon: MdHome
        },
        {
          isVisible: props.condoId,
          active: false,
          title: 'Carac. do Condomínio',
          selector: '.js-condo-characteristics',
          icon: MdHome
        },
        {
          isVisible: props.hasSubtype,
          active: false,
          title: 'Características',
          selector: '.js-characteristics',
          icon: MdDescription
        },
        {
          isVisible: true,
          active: false,
          title: 'Estabelecimentos',
          selector: '.js-establishments',
          icon: MdStore
        },
        {
          isVisible: props.hasSubtype,
          active: false,
          title: 'Cômodos',
          selector: '.js-rooms',
          icon: MdHotel
        },
        {
          isVisible: true,
          active: false,
          title: 'Localização',
          selector: '.js-location',
          icon: MdPlace
        },
        {
          isVisible: props.hasSubtype,
          active: false,
          title: 'Medidas',
          selector: '.js-measures',
          icon: MdCropFree
        },
        {
          isVisible: true,
          active: false,
          title: 'Preço',
          selector: '.js-price',
          icon: MdAttachMoney
        },
        {
          isVisible: props.isRent,
          active: false,
          title: 'Temporada',
          selector: '.js-season',
          icon: MdLandscape
        },
        {
          isVisible: true,
          active: false,
          title: 'Descrição',
          selector: '.js-description',
          icon: MdEdit
        },
        {
          isVisible: true,
          active: false,
          title: 'SEO',
          selector: '.js-seo',
          icon: FaGoogle
        },
        {
          isVisible: true,
          active: false,
          title: 'Vídeo',
          selector: '.js-video',
          icon: TiSocialYoutube
        },
        {
          isVisible: true,
          active: false,
          title: '360˚',
          selector: '.js-tour',
          icon: MdRotateLeft
        },
        {
          isVisible: true,
          active: false,
          title: 'Documentos/Certificados',
          selector: '.js-cadastral-situations',
          icon: MdAttachFile
        },
        {
          isVisible: true,
          active: false,
          title: 'Dados Privativos',
          selector: '.js-privateData',
          icon: MdLock
        }
      ]
    };
  }

  componentDidMount() {
    this.activateSection();
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      links: this.state.links.map(link => {
        if (
          ['.js-rooms', '.js-characteristics', '.js-measures'].indexOf(
            link.selector
          ) !== -1
        ) {
          return { ...link, isVisible: props.hasSubtype };
        }

        if (['.js-condo-characteristics'].indexOf(link.selector) !== -1) {
          return { ...link, isVisible: props.condoId };
        }

        if (['.js-season'].indexOf(link.selector) !== -1) {
          return { ...link, isVisible: props.isRent };
        }

        return link;
      })
    });
  }

  navigateToSection = (section, container = '.Wrapper__inner') => () => {
    scrollToElement(section, container, { marginTop: 15 });
  };

  updateActiveLink = (link, payload) => {
    this.setState({
      links: [
        ...this.state.links.map(lin => {
          if (lin.selector === link.selector) return { ...lin, ...payload };
          return lin;
        })
      ]
    });
  };

  resetLink = () =>
    this.setState({
      links: [
        ...this.state.links.map(link => ({
          ...link,
          active: false
        }))
      ]
    });

  activeLink = link => this.updateActiveLink(link, { active: true });

  activateSection = (container = '.Wrapper__inner') => {
    const $container = document.querySelector(container); // Pega o elemento pai com o overflow
    const heightHeader = document.querySelector('.MainHeader').offsetHeight; // Pega a altura do header
    const _options = { marginTop: 20 + heightHeader }; // Define uma margin do topo para dizer quando a section está ativa

    $container.onscroll = () => {
      this.state.links.forEach(link => {
        let $target = document.querySelector(link.selector); // Pega o selector da section
        if (!!$target) {
          let topActive =
            $target.getBoundingClientRect().top - _options.marginTop <=
            _options.marginTop; // Verifica se o top dela está <= da margin definida para assim ficar ativo
          let bottomActive =
            $target.getBoundingClientRect().bottom - _options.marginTop >=
            _options.marginTop; // Verifica se o bottom dela esta >= da margin definida para assim ficar ativo

          if (topActive && bottomActive && !link.active) {
            // Se ambos( top, bottom ) estiverem ativas, então ele ativa o link
            this.resetLink();
            this.activeLink(link);
          }
        }
      });
    };
  };

  render() {
    const { links } = this.state;

    return (
      <NavAside>
        <TransitionGroup>
          {links.map(
            link =>
              link.isVisible && (
                <CSSTransition
                  key={`section-${link.selector}`}
                  timeout={400}
                  classNames="bouncing"
                >
                  <NavAside.link
                    onClick={this.navigateToSection(link.selector)}
                    active={link.active}
                    icon={link.icon}
                  >
                    {link.title}
                  </NavAside.link>
                </CSSTransition>
              )
          )}
        </TransitionGroup>
      </NavAside>
    );
  }
}

export default Aside;
