import React from 'react';
import qs from 'qs';
import { Link } from 'react-router-dom';
// import { Container } from './styles';

const PropertyCell = ({ filter, children }) => {
  const query = qs.stringify({
    filter: filter,
    offset: 1,
    limit: 50,
    sort: '-calculated_price',
  });

  return (
    <Link
      to={`/properties/search?${query}`}
      target="_blank"
      rel="noopener noreferrer"
      className="h-link"
    >
      {children}
    </Link>
  );
};

export default PropertyCell;
