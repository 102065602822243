import React from 'react';
import styled from 'styled-components';
// import { Wrapper } from './styles';

const IconComp = styled.svg`
  position: absolute;
  color: #21232c;
  font: inherit;
  width: 10px;
  height: 63px;
  fill: #f5f5f6;
`;

const IconLeft = styled(IconComp)`
  left: -2px;
  top: 0px;
`;

const IconRight = styled(IconComp)`
  right: -8px;
  top: 0px;
`;

function Icon({ position = 'left' }) {
  if (position === 'left') {
    return (
      <IconLeft
        aria-hidden="true"
        width="9"
        height="63"
        viewBox="0 0 9 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        className="sc-cBBlDJ gBELTN filled-svg"
      >
        <rect
          width="8"
          height="62"
          transform="translate(0.905762 0.165466)"
          fill="white"
        ></rect>
        <path d="M1.52703 2.65054C1.21146 1.38824 2.16617 0.165466 3.46731 0.165466H8.90576V32.1655V62.1655H0.905762L8.90576 32.1655L1.52703 2.65054Z"></path>
      </IconLeft>
    );
  }

  return (
    <IconRight
      aria-hidden="true"
      width="10"
      height="63"
      viewBox="0 0 10 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className="sc-kpPvGe blNUqx filled-svg"
    >
      <rect
        width="9.3999"
        height="62"
        transform="translate(0.495605 0.845825)"
        fill="white"
      ></rect>
      <path
        d="M0.495605 0.845825H1.19556V0.845825C1.60692 0.845825 1.9655 1.12579 2.06527 1.52488L9.89551 32.8458L1.89551 62.8458H0.495667L0.495605 0.845825Z"
        className="fill-svg"
      ></path>
    </IconRight>
  );
}

export default Icon;
