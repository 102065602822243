import React from 'react';
import { WrapperItems } from 'containers/ModalRoot/ModalCreditsChatGpt/styles';
import Item from 'containers/ModalRoot/ModalCreditsChatGpt/components/Item';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import { Step } from 'containers/ModalRoot/ModalPayment/styles';

// import { Wrapper } from './styles';

export const PLANS = {
  0: {
    credits: 100,
    discount: '40%',
    oldPrice: 'R$ 50,00',
    price: 'R$ 20,00',
    newPrice: 'R$ 30,00',
  },
  1: {
    credits: 50,
    discount: '20%',
    oldPrice: 'R$ 25,00',
    price: 'R$ 5,00',
    newPrice: 'R$ 20,00',
  },
  2: {
    credits: 10,
    newPrice: 'R$ 10,00',
  },
};

function SelectCredits({ handleClose, goTo }) {
  return (
    <ModalTemplate
      title="Créditos de descrição automática"
      text="Adicione créditos para usar o ChatGPT"
      titleActions={
        <Step>
          Etapa
          <br />
          1/2
        </Step>
      }
      handleClose={handleClose}
    >
      <WrapperItems>
        <Item
          label="Pacote 100 créditos"
          name="plan"
          value={0}
          values={PLANS[0]}
        />
        <Item
          label="Pacote 50 créditos"
          name="plan"
          value={1}
          values={PLANS[1]}
        />
        <Item
          label="Pacote 10 créditos"
          name="plan"
          value={2}
          values={PLANS[2]}
        />
      </WrapperItems>
      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Voltar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="button" onClick={goTo('Payment')} color="success">
          Ir para Pagamento
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

export default SelectCredits;
