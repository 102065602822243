import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Content, Img } from './styles';
import logo from './logo.png';
import { ThemeProvider } from 'styled-components';

const PreviewImage = ({
  opacity = 60,
  image = logo,
  size = 40,
  position = 'NorthWest'
}) => {
  return (
    <ThemeProvider
      theme={{
        opacity,
        size
      }}
    >
      <Wrapper>
        <Content position={position}>
          <Img src={image} alt="Background" />
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
};

PreviewImage.propTypes = {
  image: PropTypes.string,
  size: PropTypes.number,
  opacity: PropTypes.number,
  position: PropTypes.oneOf([
    'NorthWest',
    'North',
    'NorthEast',
    'West',
    'Center',
    'East',
    'SouthWest',
    'South',
    'SouthEast'
  ])
};

export default PreviewImage;
