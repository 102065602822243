import api, { apiStorage } from 'services/index';

/**
 * Pega os dados dos slides
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const get = (buildingId) =>
  api.getList(`settings/sites/buildings/${buildingId}/slides`);

/**
 * Faz o upload de imagem
 * @param buildingId
 * @param image
 * @param order
 * @return {Promise<AxiosResponse<any>>}
 */
export const create = ({ buildingId, image, order = null }) =>
  apiStorage.uploadFile(`sites/buildings/${buildingId}/slides`, {
    image,
    order,
  });

/**
 * Remove uma imagem
 * @param id
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const remove = ({ id, buildingId }) =>
  apiStorage.delete(`sites/buildings/${buildingId}/slides/${id}`);

/**
 * Atualiza os dados da descrição
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = ({ id, ...values }) =>
  api.update(`settings/sites/buildings/${id}/slides/mass-update`, values);

/**
 * Ordena uma imagem indiferente de qual galeria ela pertence
 * @param buildingId
 * @param imageId
 * @param order
 * @return {Promise<AxiosResponse<any>>}
 */
export const move = ({ buildingId, imageId, order }) =>
  api.update(`settings/sites/buildings/${buildingId}/slides/${imageId}`, {
    order,
  });
