import styled from "styled-components";

export const Step = styled.p`
  text-align: right;
  color: #646979;
  font-style: italic;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  tbody:before {
    content: "-";
    display: block;
    line-height: 7px;
    color: transparent;
  }

  tbody tr {
    td {
      padding: 10px 0;
    }

    &:not(:last-child) {
      td {
        border-bottom: 1px solid #EFEFF3;
      }
    }
  }
`;

export const SectionHeader = styled.header`
  padding: 6px;
  background: #F9FBFC;
  border: 1px solid #EFEFF3;
  border-radius: 4px;
  font-weight: bold;
`;

export const Thead = styled.thead`
  th {
    padding: 6px;

    border-top: 1px solid #EFEFF3;
    border-bottom: 1px solid #EFEFF3;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      border-left: 1px solid #EFEFF3;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      border-right: 1px solid #EFEFF3;
    }
  }

  background: #F9FBFC;
`;

export const TableData = styled.td`
  text-align: ${(props) => props.align || 'left'};
  color: #5F6470;
`;

export const ToggleableTd = styled.td`
  text-align: center;
  vertical-align: middle;

  .FormGroup {
    margin-bottom: 0;
  }
`;
