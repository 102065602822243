import React from 'react';
import { Field } from 'redux-form';
import GroupControl, { GroupItem } from 'components/GroupControl';
import Box from 'components/FilterBox';
// Components
import { Input } from 'components/Form';

const AreaRangeField = ({ title, name }) => (
  <Box title={title}>
    <GroupControl>
      <GroupItem>
        <Field
          valueFormat
          prefix=" "
          name={`by_area_greater_equals.${name}`}
          component={Input}
          inputProps={{
            thousandSeparator: null,
            decimalSeparator: '.'
          }}
        />
      </GroupItem>
      <GroupItem divider size={10} />
      <GroupItem>
        <Field
          valueFormat
          prefix=" "
          name={`by_area_lower_equals.${name}`}
          component={Input}
          inputProps={{
            thousandSeparator: null,
            decimalSeparator: '.'
          }}
        />
      </GroupItem>
    </GroupControl>
  </Box>
);

const AreaField = ({ options }) => {
  if (!options || options?.length <= 0) return null;

  return options?.map(option => (
    <AreaRangeField
      key={`area-field-${option.name}`}
      title={option.title}
      name={option.name}
    />
  ));
};

export default AreaField;
