import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  gap: 60px;
  align-items: flex-start;

  h3:not(:first-child) {
    margin-top: 10px;
  }

  h3 {
    margin-bottom: 10px;
  }
  
  h3, p, ul {
    font-size: 14px;
  }

  ul {
    margin-left: 16px;
  }
`;
