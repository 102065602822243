import React from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import {
  Circle,
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}`,
    loadingElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
    options: {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    },
  }),
  withHandlers(() => {
    const refs = {
      map: undefined,
    };

    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      onZoomChanged: ({ onZoomChange }) => {
        onZoomChange({
          zoom: refs?.map?.getZoom(),
          center: refs?.map?.getCenter(),
        });
      },
    };
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      ref={props?.onMapMounted}
      center={props?.position}
      zoom={props?.zoom}
      options={props?.options}
      onZoomChanged={props?.onZoomChanged}
    >
      {props?.isExactPlace ? (
        <Marker
          draggable={!!props?.handleDropMarker}
          position={props?.position}
          onDragEnd={props?.handleDropMarker}
        />
      ) : (
        <Circle
          draggable={!!props?.handleDropMarker}
          center={props?.position}
          onDragEnd={props?.handleDropMarker}
          radius={500}
          defaultOptions={{
            fillColor: '#0063c0',
            fillOpacity: 0.54,
            strokeWeight: 1,
            strokeColor: '#0063c0',
            zIndex: 1,
          }}
        />
      )}
    </GoogleMap>
  );
});

export default Map;
