import React, { useCallback } from 'react';
import Popover from 'components/Popover';
import Form from './Form';
import Button from 'components/Button';
import useFormValue from 'hooks/useFormValue';
import { sendEmail } from 'services/propertiesPartial/publish';

const SendToEmail = ({ propertyId }) => {
  const message = useFormValue('email_message');
  const email = useFormValue('email');
  const email_subject = useFormValue('email_subject');

  const onSubmit = useCallback(
    ({ email, email_subject }) =>
      sendEmail({
        id: propertyId,
        email,
        email_subject,
        email_message: message
      }),
    [message, propertyId]
  );

  return (
    <Popover
      width={400}
      initialValues={{ email_subject, email }}
      component={Form}
      onSubmit={onSubmit}
    >
      <Button className="h-margin-top--15 h-margin-left--15">
        Enviar por E-mail
      </Button>
    </Popover>
  );
};

export default SendToEmail;
