import { TableData } from 'containers/ModalRoot/ModalPayment/styles';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import { formatter } from 'containers/ModalRoot/ModalPayment/utils/moneyFormatter';
import Amount from 'containers/ModalRoot/ModalPayment/components/Amount';
import { Field } from 'redux-form';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';

const Users = () => {
  const { users_count } = useCurrentRealEstate();
  const { additionalUsersTotalPrice, prices } = useModalPayment();

  return (
    <tr>
      <td className="item">
        <h3>Corretores/Usuários</h3>
        <p>Total de pessoas que irão trabalhar com você</p>
      </td>
      <td>
        <Field min={users_count} name="additional_users" component={Amount} />
      </td>
      <TableData align="right">
        R$ {formatter.format(prices.user_price)}
      </TableData>
      <TableData align="right">
        R$ {formatter.format(additionalUsersTotalPrice)}
      </TableData>
    </tr>
  );
};

export default Users;
