import api from 'services/index';
// Helpers
import { responseMessage } from 'lib/service-helpers';

/**
 * Pega todos os menus
 * @param params
 * @return {*}
 */
export const getAll = params =>
  api.getList('settings/sites/navigations', params);

/**
 * Pega uma navegacao em específico
 *
 */
export const getOne = id => api.getOne('settings/sites/navigations', id);

/**
 * Cria um menu
 * @param {Object} values
 * @return {Promise}
 */
export const create = values =>
  api
    .create('settings/sites/navigations', values)
    .then(responseMessage('Menu adicionado!'));

/**
 * Atualiza uma lista de menus
 * @param {Array||Object} values
 * @param {Object} config
 * @return {Promise}
 */
export const update = (values, config) =>
  api
    .update('settings/sites/navigations', values, config)
    .then(responseMessage('Menu atualizado!'));

/**
 * Deleta um menu do site
 * não pode deletar um menu caso ele seja pai de outro menu
 * @param id
 * @return {*}
 */
export const remove = id =>
  api
    .delete('settings/sites/navigations', id)
    .then(responseMessage('Menu removido!'));

export const getNegotiateProperty = () => 
  api.get('/settings/sites/negotiate-property')

export const createNegotiateProperty = values =>
  api.create('/settings/sites/negotiate-property', values)
    .then(responseMessage('Configuração da página criada com sucesso!'));
