import React from 'react';
import { useSelector } from 'react-redux';
import { Wrapper } from './styles';

import { propertiesCountSelector } from 'modules/modalPropertySearch';
import Loading from 'components/Loading';
import Counts from '../Counts';

function AsideFilter() {
  const propertiesCount = useSelector(propertiesCountSelector);

  return (
    <Wrapper>
      <Loading isFullComponent isVisible={propertiesCount?.isFetching} />
      <Counts />
    </Wrapper>
  );
}

export default AsideFilter;
