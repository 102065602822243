import React, { useCallback } from 'react';
import Button from 'components/Button';

function PaymentOptions({
  handleClose,
  onClickPayBillet,
  onClickPayCreditCard,
  onClickPayPix,
}) {
  const onClickBillet = useCallback(() => {
    onClickPayBillet();
    handleClose();
  }, [handleClose, onClickPayBillet]);

  const onClickCreditCard = useCallback(() => {
    onClickPayCreditCard();
    handleClose();
  }, [handleClose, onClickPayCreditCard]);

  return (
    <div>
      <div className="h-margin-bottom--10">
        <Button block onClick={onClickBillet}>
          2ª via do boleto
        </Button>
      </div>
      <div className="h-margin-bottom--10">
        <Button
          block
          color="tertiary"
          onClick={() => {
            handleClose();
            onClickPayPix();
          }}
        >
          Pagar com PIX
        </Button>
      </div>
      <div>
        <Button color="success" block onClick={onClickCreditCard}>
          Pagar com cartão
        </Button>
      </div>
    </div>
  );
}

PaymentOptions.defaultProps = {};
PaymentOptions.propTypes = {};

export default PaymentOptions;
