import { withValidation } from 'lib/validate-helpers';

export default values => {
  const { errors, validations } = withValidation(values);

  validations.required(['people_id', 'user_id', 'date_entry']);

  if (values.entry_at && values.entry_at.length <= 9) {
    errors.entry_at = 'Data invalida';
  }

  if (values.departure_at && values.departure_at.length <= 9) {
    errors.departure_at = 'Data invalida';
  }

  if (values.revision_at && values.revision_at.length <= 9) {
    errors.revision_at = 'Data invalida';
  }

  return errors;
};
