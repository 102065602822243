import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  max-width: 220px;
  z-index: 50;
`;
