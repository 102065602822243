import React from 'react';
import PropTypes from 'prop-types';
// Components
import ForEach from 'components/ForEach';
import CardProperty from 'components/CardProperty';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  keyExtractor: PropTypes.func,
  itemContent: PropTypes.node,
};

const defaultProps = {
  type: 'horizontal',
  data: [],
  keyExtractor: ({ id }) => id,
  itemContent: null,
  itemRenderer:
    ({ type, itemContent, onClick, canShowStages }) =>
    (property) =>
      (
        <CardProperty
          canShowStages={canShowStages}
          property={property}
          type={type}
          handleClickProperty={() => onClick(property)}
        >
          {itemContent && itemContent(property)}
        </CardProperty>
      ),
};

const PropertiesList = ({
  canShowStages = false,
  type,
  data,
  keyExtractor,
  itemRenderer,
  itemContent,
  onClick,
}) => (
  <ForEach
    data={data}
    keyExtractor={keyExtractor}
    itemRenderer={itemRenderer({ type, itemContent, onClick, canShowStages })}
  />
);

PropertiesList.propTypes = propTypes;
PropertiesList.defaultProps = defaultProps;

export default PropertiesList;
