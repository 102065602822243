import styled from 'styled-components';

export const Cell = styled.td`
  min-width: 240px;
  padding: ${p => p.theme.paddingY} ${p => p.theme.paddingX};
  vertical-align: top;
`;

export const CellLabel = styled.td`
  width: 150px;
  padding: ${p => p.theme.paddingY} ${p => p.theme.paddingX}
    ${p => p.theme.paddingY} 30px;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
  background-color: #fff;
  position: sticky;
  left: 0;
  z-index: 7;
`;

export const Wrapper = styled.div`
  padding: 20px 0;
  position: relative;
  width: calc(100% + 40px);
  overflow: auto;
  margin-left: -19px;
  margin-top: -20px;
  margin-bottom: -20px;
  max-height: 70vh;

  img {
    max-width: 100%;
  }

  table {
    border-collapse: collapse;
  }
`;
