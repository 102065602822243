import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
// Components
import Button from 'components/Button';
import { FileInput, Input, Textarea } from 'components/Form';
import { ModalFooter } from 'components/Modal';
// Helpers
import { plural } from 'lib/text';
// Constants
import { TYPES } from 'pages/Help/constants';
// Validation
import validate from './validate';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormIssue = ({ type, documents, handleSubmit, handleClose }) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Field xs={12} label="Título" name="title" component={Input} />
      <Field
        xs={12}
        label={
          type === TYPES.PROBLEM
            ? 'Descreva o que está acontecendo'
            : 'Descreva um caso de uso para sua sugestão'
        }
        name="message"
        component={Textarea}
      />
    </Row>
    <ModalFooter>
      <Button
        type="button"
        color="white"
        colorText="primary"
        onClick={handleClose}
      >
        Cancelar
      </Button>
      <span className="h-flex__cell--grow" />

      <span className="h-margin-right--15">
        {documents &&
          plural(
            documents.length,
            'anexo adicionado',
            'anexos adicionados',
            true
          )}
      </span>
      <Field accept={null} name="documents" component={FileInput}>
        <Button color="primary">Incluir Anexo</Button>
      </Field>
      <Button type="submit" color="success" className="h-margin-left--15">
        Enviar
      </Button>
    </ModalFooter>
  </form>
);

FormIssue.defaultProps = defaultProps;
FormIssue.propTypes = propTypes;

const selector = formValueSelector('FormIssue');

const mapStateToProps = (state) => ({
  documents: selector(state, 'documents'),
});

export default compose(
  reduxForm({
    validate,
    form: 'FormIssue',
    enableReinitialize: true,
  }),
  connect(mapStateToProps)
)(FormIssue);
