const MAX_USERS = 99999;
const MAX_MONTHS = 12;

export default class Plan {
  constructor() {
    this._users = 1;
    this._months = 1;
    this._subscription_price = 80;
    this._user_price = 19.9;
    this._mailboxes = 0;
    this._mailbox_price = 4.9;
    this._min_value_slip = 230;
    this._min_value_credit_card = 160;
    this._credit_card_installments = { 3: 2, 6: 4, 12: 6 };
    this._slip_installments = { 3: 2, 6: 3, 12: 5 };
    this._discount_12m = 20;
    this._discount_6m = 10;
    this._orulo_price = 0;
    this._dwv_price = 0;
  }

  // Seta o valor base do plano
  set subscriptionPrice(value) {
    this._subscription_price = parseFloat(value);
  }

  // Seta a quantidade de caixas de e-mail
  set mailboxes(value) {
    this._mailboxes = value;
  }

  // Seta quanto vale cada caixa de e-mail
  set mailboxPrice(value) {
    this._mailbox_price = parseFloat(value);
  }

  // Seta quanto vale cada usuário
  set userPrice(value) {
    this._user_price = parseFloat(value);
  }

  // seta o valor mínimo do boleto
  set minValueSlip(value) {
    this._min_value_slip = value;
  }

  // seta o valor mínimo do cartão de crédito
  set minValueCreditCard(value) {
    this._min_value_credit_card = value;
  }

  set creditCardInstallments(value) {
    this._credit_card_installments = value;
  }

  set slipInstallments(value) {
    this._slip_installments = value;
  }

  set discount12m(value) {
    this._discount_12m = value;
  }

  set discount6m(value) {
    this._discount_6m = value;
  }

  set oruloPrice(value) {
    this._orulo_price = value;
  }

  set dwvPrice(value) {
    this._dwv_price = value;
  }

  /**
   * Verifica se o plano tem algum desconto
   * @returns {boolean}
   */
  hasDiscount() {
    return this.getGrossValue() >= 400;
  }

  /**
   * A quantidade de usuários no plano
   * @param {Number} newUsers - quantidade de usuários do plano
   */
  set users(newUsers) {
    if (newUsers > MAX_USERS) {
      throw new Error(
        `Quantidade de usuários não pode ser maior que ${MAX_USERS}`
      );
    }

    this._users = newUsers;
  }

  /**
   * Quantidade de meses do plano
   * @param {Number} newMonths
   */
  set months(newMonths) {
    if (newMonths <= 0) {
      throw new Error('Quantidade de meses não pode ser menor ou igual à 0');
    }
    if (newMonths > MAX_MONTHS) {
      throw new Error(
        `Quantidade de meses não pode maior que MAX_MONTHS: ${MAX_MONTHS}`
      );
    }

    this._months = newMonths;
  }

  /**
   * Retorna o nome do plano
   */
  getName() {
    switch (this._months) {
      case 12:
        return 'Anual';
      case 6:
        return 'Semestral';
      case 3:
        return 'Trimestral';
      default:
        return 'Mensal';
    }
  }

  getMaxInstallmentsSlip() {
    return this._slip_installments[this._months];
  }

  getInstallmentsSlip() {
    if (this._months === 1) return 1;

    const MAX = this.getMaxInstallmentsSlip();

    const installments = Math.trunc(this.getNetValue() / this._min_value_slip);

    if (installments < 1) return 1;

    return installments > MAX ? MAX : installments;
  }

  getPriceInstallmentsSlip() {
    return this.getNetValue() / this.getInstallmentsSlip();
  }

  getMaxInstallmentsCreditCard() {
    return this._credit_card_installments[this._months];
  }

  getInstallmentsCreditCard() {
    // Se a quantidade de meses for 1
    // sempre retorna 1 parcela
    if (this._months === 1) return 1;
    const MAX = this.getMaxInstallmentsCreditCard();

    const installments = Math.trunc(
      this.getNetValue() / this._min_value_credit_card
    );

    if (installments < 1) return 1;

    return installments > MAX ? MAX : installments;
  }

  getPriceInstallmentsCreditCard() {
    return this.getNetValue() / this.getInstallmentsCreditCard();
  }

  /**
   * Retorna quanto está pagando por mês bruto
   * @returns {number}
   */
  getGrossMonthlyPayment() {
    return this.getGrossValue() / this._months;
  }

  /**
   * Retorna quanto está pagando por mês liquido
   * @returns {number}
   */
  getNetMonthlyPayment() {
    return this.getNetValue() / this._months;
  }

  // Pega o desconto
  getDiscountPercentage() {
    if (this._months === 12) return this._discount_12m / 100;
    if (this._months === 6) return this._discount_6m / 100;
    return 0;
  }

  getDiscountBasePrice() {
    return this.getGrossValue();
  }

  /**
   * Calcula o desconto total do plano
   * @returns {number}
   */
  getDiscount() {
    return this.getDiscountBasePrice() * this.getDiscountPercentage();
  }

  /**
   * Calcula o valor bruto dos usuários
   * @returns {number}
   */
  getUserPrice() {
    return this._users * this._user_price;
  }

  /**
   * Calcula o valor bruto das caixas de e-mail
   */
  getMailboxPrice() {
    return this._mailboxes * this._mailbox_price;
  }

  /**
   * Calcula o valor total das caixas de e-mail
   * @returns {number}
   */
  getMailboxesTotalPrice() {
    return this.getMailboxPrice() * this._months;
  }

  getOruloPrice() {
    return this._orulo_price;
  }

  getDwvPrice() {
    return this._dwv_price;
  }

  /**
   * Calcula o valor bruto
   * @returns {number} - valor total do plano sem os descontos
   */
  getGrossValue() {
    return (
      (this._subscription_price +
        this.getUserPrice() +
        this.getMailboxPrice() +
        this.getOruloPrice() +
        this.getDwvPrice()) *
      this._months
    );
  }

  getTotalByParcelas(numeroParcelas) {
    return this.getNetValue() / numeroParcelas;
  }

  /**
   * Calcula o valor liquido
   * @returns {number}
   */
  getNetValue() {
    return this.getGrossValue() - this.getDiscount();
  }
}
