import React, { useMemo } from 'react';
import { Wrapper } from './styles';
import { MdContentCopy, MdEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import Clipboard from 'react-clipboard.js';
import Alert from 'react-s-alert';
import TextOverflow from 'components/TextOverflow';
import { useDispatch } from 'react-redux';
import { openModalVcard } from 'modules/modal';
import styled from 'styled-components';
import { API_PDF } from 'constants/config';
import { getUsers } from 'modules/users';
import Flag from 'components/Flag';

export const Btns = styled.div`
  display: flex;
`;

const Btn = styled.button`
  color: #fff;
  background-color: ${(p) => p.color || '#31d084'};
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 10px;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 700;
  white-space: nowrap;

  :hover {
    opacity: 0.8;
  }

  & + & {
    margin-left: 5px;
  }
`;

function InfosCell({ user }) {
  const dispatch = useDispatch();

  const handleClickOpenVcard = () => {
    dispatch(
      openModalVcard({
        user,
        onFinally: () => {
          dispatch(getUsers());
        },
      })
    );
  };

  const handleClickDownload = (e) => {
    if (!user?.vcard?.id) {
      e.preventDefault();
      handleClickOpenVcard();
    }
  };

  const cellphoneNumber = useMemo(() => {
    if (user.ddi === '55' || user.ddi === 55) {
      return user.cellphone;
    }

    return `+${user.ddi} ${user.cellphone}`;
  }, [user]);

  return (
    <Wrapper>
      <div>
        <Clipboard
          data-clipboard-text={user.email}
          onSuccess={() => {
            Alert.success('E-mail copiado');
          }}
        >
          <p>
            <MdEmail /> <TextOverflow width={250}>{user.email}</TextOverflow>{' '}
            <MdContentCopy />
          </p>
        </Clipboard>
      </div>
      <div>
        <Clipboard
          data-clipboard-text={cellphoneNumber}
          onSuccess={() => {
            Alert.success('Telefone copiado');
          }}
        >
          <p>
            <FaWhatsapp />{' '}
            <Flag iso2={user.iso2} style={{ marginRight: '4px' }} />
            <span>{user.cellphone}</span> <MdContentCopy />
          </p>
        </Clipboard>
      </div>
      <Btns>
        <Btn color="#0084F4" onClick={handleClickOpenVcard}>
          Editar cartão virtual
        </Btn>
        <Btn
          as="a"
          href={`${API_PDF}/vcard/${user?.vcard?.id}`}
          target="_blank"
          onClick={handleClickDownload}
        >
          Baixar cartão virtual
        </Btn>
      </Btns>
    </Wrapper>
  );
}

export default InfosCell;
