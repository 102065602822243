import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, initialize, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { ColorPicker, Select } from 'components/Form';
import ListOptions from 'components/ListOptions';
import Container from 'pages/Site/components/ContainerSite';
// Container
import FieldFontSelect from 'containers/FieldFontSelect';
// Constants
import { BOOL } from 'constants/options';
import { parseSelect } from 'lib/formHelpers';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const ApparenceForm = ({
  is_boxed,
  handleSubmit,
  submitting,
}) => {

  return (
    <form onSubmit={handleSubmit}>
      <Container style={{ marginBottom: 15 }}>
        <ListOptions>
          <ListOptions.item
            title="Cor padrão do site"
            text="Escolha a cor que será utilizada em botões, links e outros detalhes do seu site."
            renderOptions={() => (
              <Field name="color_primary" component={ColorPicker} />
            )}
          />
          <ListOptions.item
            title="Cor do fundo"
            text="Escolha a cor que será utilizada no fundo do seu site."
            renderOptions={() => (
              <Field
                width={140}
                name="color_background"
                component={ColorPicker}
                colors={[
                  { name: 'FFFFFF', hex: '#FFFFFF' },
                  { name: 'F1F4F9', hex: '#F1F4F9' },
                  { name: 'FAFCFE', hex: '#FAFCFE' },
                  { name: 'F8F9FA', hex: '#F8F9FA' }
                ]}
              />
            )}
          />
          <ListOptions.item
            title="Cor da fonte no menu"
            text="Escolha a cor que será utilizada na fonte do menu do seu site."
            renderOptions={() => (
              <Field
                canClear
                width={140}
                name="font_color_menu"
                component={ColorPicker}
                colors={[
                  { name: 'FFFFFF', hex: '#FFFFFF' },
                  { name: 'F1F4F9', hex: '#F1F4F9' },
                  { name: 'FAFCFE', hex: '#FAFCFE' },
                  { name: 'F8F9FA', hex: '#F8F9FA' }
                ]}
                placeholder="Automático"
              />
            )}
          />
          <ListOptions.item
            title="Cor dos preços"
            text="Escolha a cor que será utilizada nos preços do seu site."
            renderOptions={() => (
              <>
                <Field
                  label="Cor para venda"
                  name="color_price_sale"
                  component={ColorPicker}
                />
                <Field
                  label="Cor para locação"
                  name="color_price_rent"
                  component={ColorPicker}
                />
              </>
            )}
          />
          <ListOptions.item
            title="Fonte do título"
            text="Defina a fonte que será utilizada nos títulos do seu site."
            renderOptions={() => (
              <FieldFontSelect
                name="title_font_family"
                style={{ width: 200 }}
                parse={parseSelect('family')}
              />
            )}
          />
          <ListOptions.item
            title="Fonte do texto"
            text="Defina a fonte que será utilizada nos textos do seu site."
            renderOptions={() => (
              <FieldFontSelect
                name="text_font_family"
                style={{ width: 200 }}
                parse={parseSelect('family')}
              />
            )}
          />
          <ListOptions.item
            title="Site com abas laterais"
            text="O site ficará centralizado no meio da tela do computador e com bordas laterais."
            renderOptions={() => (
              <>
                {is_boxed && (
                  <Field name="side_flap_color" component={ColorPicker} />
                )}
                <Field
                  canRemoveSelf={false}
                  buttonTemplate
                  name="is_boxed"
                  component={Select}
                  options={BOOL}
                />
              </>
            )}
          />
          <ListOptions.item
            title="Alinhamento dos títulos"
            text="Escolha se os títulos do seu site ficará alinhado a esquerda, centralizado ou a direita."
            renderOptions={() => (
              <Field
                canRemoveSelf={false}
                buttonTemplate
                name="title_section_align"
                options={[
                  { value: 'left', label: 'Esquerda' },
                  { value: 'center', label: 'Centro' },
                  { value: 'right', label: 'Direita' }
                ]}
                component={Select}
              />
            )}
          />
        </ListOptions>
        <FixedBar style={{ left: 240 }}>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar
          </Button>
        </FixedBar>
      </Container>
    </form>
  );
};

ApparenceForm.defaultProps = defaultProps;
ApparenceForm.propTypes = propTypes;

const selector = formValueSelector('ApparenceForm');

const mapStateToProps = state => ({
  is_boxed: selector(state, 'is_boxed'),
  favicon_url: selector(state, 'favicon_url'),
  image: selector(state, 'image')
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'ApparenceForm',
    onSubmitSuccess: (res, dispatch) => {
      // Reinicializa o formulário com os valores
      dispatch(initialize('ApparenceForm', res.data, false));
    },
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
  })
)(ApparenceForm);
