import React from 'react';
import PropTypes from 'prop-types';
// Helpers

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    period: PropTypes.string,
    value: PropTypes.number
  })
};

const defaultProps = {
  data: {}
};

const TableItem = ({ data, columns }) => (
  <tr className="h-hover">
    {columns && columns.map((c, i) => <td key={`${c}-${i}`}>{data[c]}</td>)}
  </tr>
);

TableItem.propTypes = propTypes;
TableItem.defaultProps = defaultProps;

export default TableItem;
