import React from 'react';
import { ContentInner as Inner } from '../../styles';
import {
  Wrapper,
  WrapperIconDrop,
  Icon,
  Content,
  ContentInner,
  Title,
  Text,
} from './styles';
import { MdArrowRight as IconRight } from 'react-icons/md';
import { useMenuHeader } from 'components/MenuHeader/index';
import { NavLink } from 'react-router-dom';

function Item({
  href,
  to,
  title,
  text,
  icon,
  iconColor,
  children,
  target,
  onClick,
}) {
  const { setIsOpen } = useMenuHeader();

  const Link = to ? NavLink : 'a';

  return (
    <Wrapper
      href={href}
      as={Link}
      to={to}
      onClick={(e) => {
        setIsOpen(false);
        if (onClick) onClick(e);
      }}
      target={target}
    >
      <Icon color={iconColor}>{icon?.()}</Icon>
      <Content>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Content>
      {children && (
        <WrapperIconDrop>
          <IconRight />
        </WrapperIconDrop>
      )}
      {children && (
        <ContentInner>
          <Inner>{children}</Inner>
        </ContentInner>
      )}
    </Wrapper>
  );
}

export default Item;
