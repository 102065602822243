import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Days = styled.div`
  margin-right: 10px;
`;

const buttonActive = ({ active }) => {
  if (active) {
    return css`
      color: #fff;
      background: rgba(8, 97, 245, 0.69);
      border: 1px solid rgba(8, 97, 245, 0.69);
      border-radius: 4px;
    `;
  }
};

export const Button = styled.button`
  min-width: 36px;
  height: 36px;
  padding: 0 5px;
  background: rgba(194, 206, 226, 0.1);
  border: 1px solid #d2dbe3;
  border-radius: 4px;
  cursor: pointer;
  ${buttonActive};

  & + & {
    margin-left: 5px;
  }
`;
