import Dropdown from 'components/Dropdown';
import { PROPERTY_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';
import {
  closeModal,
  openModalPropertyRent,
  openModalPropertySell,
} from 'modules/modal';
import { useDispatch } from 'react-redux';
import * as propertiesService from 'services/properties';
// import { Wrapper } from './styles';

function MoveProperty({ property, afterClose }) {
  const dispatch = useDispatch();

  const handleReactivate = async () => {
    await propertiesService.reactivateProperty(property.id);
    if (afterClose) afterClose();
    dispatch(closeModal());
  };

  const isSell = property.transaction === 1 || property.transaction === '1';

  if (property.status !== 'Disponível') {
    return (
      <Can
        run={PROPERTY_PERMISSIONS.REACTIVE}
        permissions={property.permissions}
      >
        <Dropdown.item>
          <button onClick={handleReactivate}>Mover para disponíveis</button>
        </Dropdown.item>
      </Can>
    );
  }

  if (isSell) {
    return (
      <Can
        run="DYNAMIC_SELL"
        permissions={property.permissions}
        yes={() => (
          <Dropdown.item>
            <button
              onClick={() => {
                dispatch(
                  openModalPropertySell({
                    property,
                    onSubmitSuccess: () => {
                      afterClose();
                    },
                  })
                );
              }}
            >
              Mover para vendidos
            </button>
          </Dropdown.item>
        )}
        no={() => null}
      />
    );
  }
  return (
    <Can
      run={PROPERTY_PERMISSIONS.RENT}
      permissions={property.permissions}
      yes={() => (
        <Dropdown.item>
          <button
            onClick={() => {
              dispatch(
                openModalPropertyRent({
                  property: property,
                  onSubmitSuccess: () => {
                    afterClose();
                  },
                })
              );
            }}
          >
            Mover para alugados
          </button>
        </Dropdown.item>
      )}
      no={() => null}
    />
  );
}

export default MoveProperty;
