// Modules
import createDataReducer from 'modules/data';
// Services
import * as calendarsService from 'services/calendars';

export const {
  reducer: eventsReducer,
  actionCreators: eventActions,
  selectors: eventsSelectors
} = createDataReducer('events');

/**
 * Busca os eventos
 * @param params
 * @returns {Function}
 */
export const fetchEvents = params => async dispatch => {
  dispatch(eventActions.request());
  const { data } = await calendarsService.getList(params);
  dispatch(eventActions.receive(data));
};

export default eventsReducer;
