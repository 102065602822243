import api from './index';

export const getList = params => api.getList('financial-indexes', params);
export const getOne = id => api.getOne('financial-indexes', id);
export const getHistory = (id, params) =>
  api.getList(`financial-indexes/${id}/histories`, params);
export const create = values => api.create('financial-indexes', values);
export const update = values => api.update('financial-indexes', values);
export const remove = id => api.delete('financial-indexes', id);
export const reactivate = id => api.reactivate('financial-indexes', id);

/**
 * Transfere um indice financeiro para outro
 * @param from - id do indice que voce quer mover
 * @param to - id para onde vai os imóveis
 * @return {Promise<*>}
 */
export const transfer = (from, to) =>
  api.create(`financial-indexes/${from}/properties/transfers/${to}`);

/**
 * Transfere e remove um indice financeiro
 * @param from - id do indice que voce quer mover
 * @param to - id para onde vai os imóveis
 * @return {Promise<*>}
 */
export const transferAndRemove = async (from, to) => {
  try {
    // Espera a transferencia
    await transfer(from, to);

    // Se a transferencia der certo
    return remove(from);
  } catch {
    // Error
  }
};
