import React, { useContext, useCallback, useMemo, useEffect } from 'react';
import { ContentList } from '../List/styles';
import { Wrapper, PersonName, Item } from './styles';
import { SearchBarContext } from 'components/SearchBar/context';
import { useDispatch } from 'react-redux';
import { openModalPerson } from 'modules/modal';
import { useInView } from 'react-intersection-observer';

function PeopleResults({ data }) {
  const dispatch = useDispatch();
  const { search, loadMoreByKey } = useContext(SearchBarContext);

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 1
  });

  const total = useMemo(() => {
    return data?.length;
  }, [data]);

  const openModal = useCallback(
    id => () => {
      dispatch(openModalPerson({ id }));
    },
    []
  );

  useEffect(() => {
    if (inView) {
      loadMoreByKey('people');
    }
  }, [inView]);

  if (!data || data.length <= 0) return null;

  return (
    <ContentList>
      <header>
        <h1>Clientes</h1>
      </header>
      <Wrapper>
        {data.map((person, i) => {
          let _props = {};

          if (i + 1 === total) {
            _props = {
              ref
            };
          }

          return (
            <Item
              {..._props}
              key={'PERSON-RESULT' + person.id}
              onClick={openModal(person.id)}
            >
              <PersonName search={search}>{person.name}</PersonName> (
              {person.groups.map(({ name }) => name).join(',')})
            </Item>
          );
        })}
      </Wrapper>
    </ContentList>
  );
}

export default PeopleResults;
