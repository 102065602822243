import peopleHandshake from 'pages/People/components/PeopleReception/assets/people-handshake.svg';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
// Components
import Loading from 'components/Loading';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import TableAdmin from './components/TableAdmin';
// Module
import { currentUserIsFetchingSelector, currentUserSelector } from 'modules/login';
// Services
import api from 'services';

class Home extends Component {
  state = {
    people: [],
    isFetching: false
  };

  async componentDidMount() {
    this.fetchData();
  }

  get isFetching() {
    const { currentUserIsFetching } = this.props;
    const { isFetching } = this.state;
    return isFetching || currentUserIsFetching;
  }

  fetchData = async () => {
    try {
      this.setState({ isFetching: true });
      const { data: people } = await api.getList('users/admin-view');
      this.setState({ people, isFetching: false });
    } catch {
      this.setState({ people: [], isFetching: false });
    }
  };

  render() {
    const { currentUser } = this.props;
    const { people, isFetching } = this.state;

    if (this.isFetching) return <Loading isVisible isFullScreen />;

    if (!currentUser.admin) {
      return (
        <div className="BigReception">
          <img src={peopleHandshake} alt="" className="BigReception__image" />
          <div className="BigReception__container">
            <h3 className="BigReception__title">
              Olá {currentUser.name}, bem vindo(a) a versão BETA!
            </h3>
            <p className="BigReception__text">
              Você está utilizando a versão BETA. Os recursos que você tem a
              diposição estão em processo de desenvolvimento, e em breve outros
              serão adicionados. Vamos começar?
            </p>
          </div>
        </div>
      );
    }

    return (
      <Wrapper styleContainer={{ maxWidth: '1139px' }}>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <MainTitle
          title="Bem vindo(a) de volta!"
          text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard."
        />

        <TableAdmin data={people} isFetching={isFetching} />
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: currentUserSelector(state),
  currentUserIsFetching: currentUserIsFetchingSelector(state)
});

export default connect(mapStateToProps)(Home);
