import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import RCAlert from 'react-s-alert';
// Modules
import {
  hasIntegration,
  isLoggedSelector,
  updateGoogleAnalytics
} from 'modules/google';
import { currentUserSelector, googleAnalyticsSelector } from 'modules/login';
// Containers
import GoogleAnalyticsLogin from 'containers/GoogleAnalyticsLogin';
// Components
import Alert from 'components/Alert';
import Button from 'components/Button';
import FormAccountSelect from './components/FormAccountSelect';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Container from 'components/Container';
import NoContent from 'components/NoContent';
// Modules
import { getGoogleAnalytics } from 'lib/analytics';
// Assets
import analytics from 'assets/img/noData/icon-analytics.png';
import IsTecimob from 'containers/IsTecimob';

const styleAlert = {
  marginTop: '25px'
};

class Analytics extends Component {
  static defaultProps = {
    redirectTo: '/reports/analytics/new-users'
  };

  static propTypes = {
    redirectTo: PropTypes.string.isRequired
  };

  state = {
    noAnalyticsAccount: false
  };

  handleError = () => {
    this.setState({ noAnalyticsAccount: true });
  };

  get canShowReception() {
    const { isLogged, noAnalyticsAccount } = this.props;
    return !isLogged || noAnalyticsAccount;
  }

  get renderAlert() {
    if (!this.state.noAnalyticsAccount) return null;

    return (
      <Alert color="caution" style={styleAlert}>
        Você não possui uma conta no Google Analytics. Clique aqui para criar
        uma conta, e tente sincronizar novamente.
      </Alert>
    );
  }

  get initialValues() {
    try {
      return getGoogleAnalytics();
    } catch {
      return {};
    }
  }

  onSubmit = values => this.props.updateGoogleAnalytics(values);
  onSubmitSuccess = () => {
    RCAlert.success('Dados do analytics atualizados');
  };

  render() {
    const { hasIntegration, redirectTo } = this.props;

    if (hasIntegration) {
      return <Redirect to={redirectTo} />;
    }

    if (this.canShowReception) {
      return (
        <Wrapper.container>
          {this.renderAlert}
          <NoContent
            size={595}
            image={analytics}
            title="Google Analytics"
            text={
              <>
                É uma ferramenta gratuita para análise dos acessos ao seu site.
                É necessário que você possua uma conta no Analytics para
                conseguir sincronizar.
                <IsTecimob
                  yes={() => (
                    <>
                      {' '}
                      (Siga{' '}
                      <a
                        href="https://tecimob.com.br/ajuda/criar-conta-no-google-analytics/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="h-link"
                      >
                        estes passos
                      </a>{' '}
                      para criar)
                    </>
                  )}
                  no={() => (
                    <>
                      {' '}
                      (
                      <a
                        href="https://analytics.google.com/analytics/web/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="h-link"
                      >
                        Clique aqui
                      </a>{' '}
                      para criar.)
                    </>
                  )}
                />
              </>
            }
          >
            <GoogleAnalyticsLogin
              onSubmit={this.onSubmit}
              onSuccess={() => {
                this.setState({ noAnalyticsAccount: false });
              }}
              render={({ onClick }) => (
                <Button color="secondary" onClick={onClick}>
                  Sincronizar com o Analytics
                </Button>
              )}
            />
          </NoContent>
        </Wrapper.container>
      );
    }

    return (
      <Wrapper.container>
        <MainTitle
          title="Integração com Google Analytics"
          text="Faz com que seus clientes identifiquem facilmente sua marca, além de impedir que alguém copie suas fotos!"
        />
        <Container>
          <FormAccountSelect
            initialValues={this.initialValues}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
            onError={this.handleError}
          />
        </Container>
      </Wrapper.container>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: isLoggedSelector(state),
  hasIntegration: hasIntegration(state),
  currentUser: currentUserSelector(state),
  googleAnalytics: googleAnalyticsSelector(state)
});

const mapDispatchToProps = {
  updateGoogleAnalytics
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
