import moment from 'moment';
import { ExpiredLabel, Wrapper } from './styles';

function InviteInfoCell({ invite }) {
  const formatDate = (date) => {
    return moment(
      moment(date, 'DD/MM/YYYY HH:mm:ss').toDate()
    ).format('DD/MM/YY HH:mm');
  }

  return (
    <Wrapper>
      <div>
        <p>{formatDate(invite.created_at)}</p>
      </div>
      <div>
        <p>{formatDate(invite.valid_until)} {invite.is_expired && <ExpiredLabel>(Expirado)</ExpiredLabel>}</p>
      </div>
    </Wrapper>
  );
}

export default InviteInfoCell;
