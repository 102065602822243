import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Wrapper, List } from './styles';

// Components
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import TablePreview from './TablePreview';
import { FieldBool } from 'components/Form';

// Services
import { confirmImport } from 'services/imports/people';
import { plural } from 'lib/text';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function FormConfirmImport({ data, handleSubmit, handleClickCancel }) {
  return (
    <Wrapper onSubmit={handleSubmit}>
      <p className="h-color--primary h-margin-bottom--5">
        Confira abaixo se os dados que você importou estão nas colunas corretas
        antes de prosseguir.
      </p>
      <p className="h-color--primary">
        <strong>
          Obs: estamos mostrando dados de apenas{' '}
          {plural(data?.preview?.length, 'cliente', 'clientes')}.
        </strong>
      </p>
      <TablePreview data={data.preview} />
      <List>
        <List.item
          title="Padronizar o texto sempre com a primeira letra maíuscula e o resto minúsculo?"
          renderOptions={() => <FieldBool name="should_capitalize_words" />}
        />
        <List.item
          title="Atualizar dados de telefone e email para os clientes que já estão cadastrados?"
          renderOptions={() => <FieldBool name="should_update_existing" />}
        />
      </List>
      <ModalFooter>
        <Button color="white" colorText="secondary" onClick={handleClickCancel}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Confirmar
        </Button>
      </ModalFooter>
    </Wrapper>
  );
}

FormConfirmImport.defaultProps = defaultProps;
FormConfirmImport.propTypes = propTypes;

export default reduxForm({
  form: 'FormConfirmImport',
  initialValues: {
    should_capitalize_words: true,
    should_update_existing: true
  },
  onSubmit: values => {
    return confirmImport(values);
  },
  enableReinitialize: true
})(FormConfirmImport);
