import React from 'react';
import { Content, Image, Text, Title, Wrapper } from './styles';
import Price from 'components/Price';
import Status from 'containers/ModalRoot/ModalProperty/components/ModalDefault/components/Status';

function Property({ data }) {
  return (
    <Wrapper>
      <Image size="medium" src={data?.cover_image?.file_url} />
      <Content>
        <Title>
          {data?.type?.title} - Ref.: {data?.reference}{' '}
          <Status separator="-" property={data} />
        </Title>
        <Text>{data?.situation?.title}</Text>
        {data?.address_formatted && <Text>{data?.address_formatted}</Text>}
        <Price size={14} transaction={data?.transaction}>
          {data?.price}
        </Price>
      </Content>
    </Wrapper>
  );
}

export default Property;
