import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
// Services
import * as dealsService from 'services/deals';
// Components
import { SelectMultiple } from 'components/Form';
import { parseSelect } from 'lib/formHelpers';

function FieldStages({
  multi = false,
  userId = null,
  funnelId = null,
  onFinish = null,
  ...props
}) {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState();

  function getDefaultFunnelId() {
    try {
      return (
        funnelId ||
        localStorage.getItem('current-user').default_crm_stage.funnel_id
      );
    } catch {
      return null;
    }
  }

  async function fetchData(params) {
    setIsFetching(true);

    try {
      const { data } = await dealsService.getStages(
        getDefaultFunnelId(),
        params
      );

      setData(data);

      // Executa alguma coisa quando termina o request
      if (props.onFinish) props.onFinish(data);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [funnelId]);

  return (
    <Field
      multi={multi}
      options={data}
      name="stage_id"
      valueKey="id"
      labelKey="name"
      loading={isFetching}
      component={SelectMultiple}
      placeholder="Selecione a posição"
      parse={parseSelect('id')}
      {...props}
      disabled={data.length <= 0 || props.disabled}
    />
  );
}

export default FieldStages;
