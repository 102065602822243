import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Tooltip from 'react-tooltip';

const propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  image: PropTypes.string,
  title: PropTypes.node,
  text: PropTypes.node,
  renderOptions: PropTypes.func,
  children: PropTypes.node
};

const defaultProps = {
  isDisabled: false,
  isLoading: false,
  image: null,
  title: null,
  text: null,
  renderOptions: null,
  children: null
};

const ListOptionsItem = ({
  isDisabled,
  isLoading,
  image,
  imageStyle,
  title,
  text,
  children,
  renderOptions,
  className,
  ...rest
}) => {

  useEffect(() => {
    Tooltip.rebuild();
  }, []);
  
  return (
    <li
      {...rest}
      className={classnames('ListOptions__item', className, {
        isLoading,
        'is-disabled': isDisabled
      })}
    >
      <header className="h-flex h-flex--center-center">
        {image && (
          <div className="h-flex__cell h-flex__cell--shrink h-margin-right--15">
            <img src={image} alt="" style={imageStyle} />
          </div>
        )}
        <div className="h-flex__cell h-flex__cell--grow">
          {title && <h1 className="h5 ListOptions__title">{title}</h1>}
          {text && <p className="ListOptions__text">{text}</p>}
        </div>
        <div className="ListOptions__actions h-flex h-flex__cell h-flex__cell--shrink">
          {renderOptions && renderOptions()}
        </div>
      </header>
      {children && <div>{children}</div>}
    </li>
  );
};

ListOptionsItem.propTypes = propTypes;
ListOptionsItem.defaultProps = defaultProps;

export default ListOptionsItem;
