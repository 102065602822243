import React from 'react';
import PropTypes from 'prop-types';
// Component
import Table from 'components/Table';
import PropertyItem from './components/PropertyItem';

const propTypes = {
  properties: PropTypes.array
};

const defaultProps = {
  properties: []
};

const PropertiesTable = ({
  canSelect,
  toggleSelected,
  properties,
  handleOpenProperty
}) => (
  <div>
    <Table
      data={properties}
      isFetching={false}
      renderTitle={() => (
        <tr>
          {canSelect && <th />}
          <th>Ref</th>
          <th>Descrição do lote</th>
          <th>Área Total</th>
          <th>Transação</th>
          <th colSpan={2} />
        </tr>
      )}
      renderItem={property => (
        <PropertyItem
          canSelect={canSelect}
          toggleSelected={toggleSelected}
          handleOpenProperty={handleOpenProperty}
          key={property.id}
          property={property}
        />
      )}
    />
  </div>
);

PropertiesTable.propTypes = propTypes;
PropertiesTable.defaultProps = defaultProps;

export default PropertiesTable;
