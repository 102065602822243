import React, { useMemo } from 'react';
// Hooks
import { useRequest } from 'hooks/useRequest';
// Services
import * as portalsService from 'services/settings/portals';
// Styles
import { Wrapper } from './styles';

// Assets
import ConnectedPortals from '../ConnectedPortals';
import { Link } from 'react-router-dom';

const ListPortals = ({ showGuru = true }) => {
  const { data } = useRequest({
    request: portalsService.getList,
    params: {
      include: 'portal_real_estate,portal_real_estate.options',
      filter: {
        to_property_publish: true,
      },
    },
  });

  /**
   * Separa a lista de portais em conectados/disconectados
   */
  const portals = useMemo(() => {
    return data.reduce(
      (acc, portal) => {
        if (!!portal.portal_real_estate?.id) {
          acc.connected = [...acc.connected, portal];

          return acc;
        }

        acc.disconnected = [...acc.disconnected, portal];

        return acc;
      },
      { connected: [], disconnected: [] }
    );
  }, [data]);

  return (
    <Wrapper>
      <ConnectedPortals data={portals.connected} showGuru={showGuru} />
      <p className="h-margin-top--5">
        Deseja integrar com algum portal que não está nesta lista?{' '}
        <Link to="/portals" target="_blank" className="h-link">
          clique aqui.
        </Link>
      </p>
    </Wrapper>
  );
};

export default ListPortals;
