// Widgets.js
import Alert from 'react-s-alert';
import ReactTooltip from 'react-tooltip';
// Services
import * as userService from '../services/user';
// Helpers
import { openModalUserDelete } from 'modules/modal';
import msg from '../lib/msg';
// Modules
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { hideLoading, showLoading } from 'modules/loading';
import { receiveUser as receiveAuthenticateUser } from 'modules/login';

const { createModule } = require('lib/reducer-helpers');

const createAction = createModule('users');

// Actions
const ADD_USER = createAction('ADD');
const ALTER_USER = createAction('ALTER');

const REQUEST_USERS = createAction('REQUEST');
const RECEIVE_USERS = createAction('RECEIVE');

const REQUEST_USER_DELETE = createAction('REQUEST_DELETE');
const RECEIVE_USER_DELETE = createAction('RECEIVE_DELETE');

export const usersSelector = (state) => state.users.users;
export const isFetchingSelector = (state) => state.users.meta.isFetching;

// Initial State
const initialState = {
  users: [],
  meta: {
    isFetching: false,
  },
  deleting: {
    id: null,
    name: null,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER: {
      return {
        ...state,
        users: [action.user, ...state.users],
      };
    }
    case ALTER_USER: {
      const currentUser = state.users.map((user) => {
        if (user.id === action.user.id) {
          return {
            ...action.user,
          };
        }
        return user;
      });

      return {
        ...state,
        users: currentUser,
      };
    }
    case REQUEST_USERS: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isFetching: true,
        },
      };
    }
    case RECEIVE_USERS: {
      return {
        ...state,
        users: action.users,
        meta: {
          ...state.meta,
          isFetching: false,
        },
      };
    }
    case REQUEST_USER_DELETE: {
      const users = state.users.map((user) => {
        if (action.id === user.id) {
          return {
            ...user,
            isDeleting: true,
          };
        }

        return user;
      });

      return {
        ...state,
        users,
      };
    }
    case RECEIVE_USER_DELETE: {
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.id),
        deleting: {
          ...state.deleting,
          id: action.id,
          name: action.name,
        },
      };
    }
    // do reducer stuff
    default:
      return state;
  }
}

// Action Creators
export const receiveUser = (currentUser) => ({
  type: ADD_USER,
  user: currentUser,
});
export const alterUser = (currentUser) => (dispatch) => {
  const user = localStorage.getItem('current-user');

  // Atualiza o id do usuário se for o mesmo id do cara que está logado
  if (user.id === currentUser.id) {
    dispatch(receiveAuthenticateUser(currentUser));
  }

  return dispatch({ type: ALTER_USER, user: currentUser });
};

export const requestUsers = () => ({ type: REQUEST_USERS });
export const receiveUsers = (users) => ({ type: RECEIVE_USERS, users });
export const requestUserDelete = (id) => ({ type: REQUEST_USER_DELETE, id });
export const receiveUserDelete = (id) => ({ type: RECEIVE_USER_DELETE, id });

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getUsers =
  (params = {}) =>
  (dispatch) => {
    const currentUser = localStorage.getItem('current-user');

    dispatch(requestUsers());

    let _params = {
      ...params,
      with_exists: true,
    };

    // Se não tiver permissão busca somente o usuário que está logado
    if (!currentUser.permissions.includes('EDIT_USER')) {
      _params = {
        filter: {
          id: currentUser.id,
        },
      };
    }

    return userService
      .getList({
        sort: 'name',
        include: 'group,vcard',
        ..._params,
      })
      .then(({ data }) => {
        dispatch(receiveUsers(data));
        ReactTooltip.rebuild();
        return data;
      });
  };

export const addUser = (currentUser) => (dispatch) => {
  dispatch(receiveUser(currentUser));
};

export const updateUser = (currentUser) => (dispatch) => {
  dispatch(alterUser(currentUser));
};

const onSuccessUserDelete = (user) => async (dispatch) => {
  Alert.success(msg.delete({ name: user.name }));

  dispatch(receiveUserDelete(user.id));
};

export const deleteUser = (user, params) => async (dispatch) => {
  dispatch(requestUserDelete(user.id));

  if (
    user.properties_exists ||
    user?.people_exists ||
    user?.properties_agent_exists ||
    user.credits_exists ||
    user.is_financial
  ) {
    openModalUserDelete({
      user,
      initialValues: {
        from_user_id: user.id,
        should_change_contract: false,
        ...params,
      },
      onSubmitSuccess: () => {
        onSuccessUserDelete(user)(dispatch);
      },
    })(dispatch);
    return false;
  }

  dispatch(
    openConfirmation({
      title: `Deletar usuário ${user?.name}`,
      text: 'Se você deseja realmente deletar o usuário',
      request: () => userService.remove(user.id, params),
      onSuccess: () => {
        onSuccessUserDelete(user)(dispatch);
      },
      onFail: (res) => {},
    })
  );
};

export const reverseDelete = (id) => (dispatch) =>
  userService.reactivate(id).then(({ data }) => {
    Alert.success(msg.reactivate(data));
    dispatch(addUser(data));
    return data;
  });

export const handleUploadImage = (currentUser, file) => (dispatch) => {
  dispatch(showLoading());

  return userService.uploadImage(currentUser.id, file[0]).then(({ data }) => {
    dispatch(updateUser({ ...currentUser, file_url: data.file_url }));

    dispatch(hideLoading());
  });
};

export const handleUploadDocuments = (currentUser, params) => (dispatch) => {
  return userService.uploadDocument(currentUser.id, params).then(({ data }) => {
    if (!params.noUpdate) {
      dispatch(
        updateUser({
          ...currentUser,
          // ...data,
          document_url: data.document_url,
          creci_url: data.creci_url,
          creci_status: data.creci_status,
          creci_expiration: data.creci_expiration,
        })
      );
    }
  });
};
