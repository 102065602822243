// Importe os estilos necessários
import { Btn, Qtd, Wrapper } from './styles';

// Cria um formatador de valor
export const formatter = new Intl.NumberFormat('pt-BR', {
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const Amount = ({
  onIncrement,
  onDecrement,
  input,
  initial = 0,
  min = 1,
  max = 9999,
  disabled = false,
}) => {
  const canIncrement = () => {
    const newValue = input.value + 1;
    if (!max) return false;
    if (newValue > max) return false;
    return true;
  };

  const canDecrement = () => {
    if (min === 0) {
      return input.value > 0;
    } else {
      const newValue = input.value - 1;
      if (!min) return false;
      if (newValue < min) return false;
      return true;
    }
  };

  function handleIncrement() {
    const newValue = input.value + 1;

    if (newValue > max) return;

    if (onIncrement) onIncrement(newValue);

    input.onChange(newValue);
  }

  function handleDecrement() {
    const newValue = input.value - 1;

    if (newValue < min) return;

    if (onDecrement) onDecrement(newValue);

    input.onChange(newValue);
  }

  return (
    <Wrapper>
      {!disabled && (
        <Btn disabled={!canDecrement()} type="button" onClick={handleDecrement}>
          <span>-</span>
        </Btn>
      )}
      <Qtd>
        {input.value.toLocaleString('pt-BR', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </Qtd>
      {!disabled && (
        <Btn disabled={!canIncrement()} type="button" onClick={handleIncrement}>
          <span>+</span>
        </Btn>
      )}
    </Wrapper>
  );
};

export default Amount;
