import React, { useEffect, useState } from 'react';
import {
  ChatGptButton,
  Content,
  Credits,
  IconCredit,
  Title,
  Wrapper,
} from './styles';
import Dropdown, { Item, List } from 'components/Dropdown';
import { BsCartPlus, BsStars } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import useReduxForm from 'hooks/useReduxForm';
import {
  openModalChatGpt,
  openModalChatGptExtract,
  openModalChatGptNoCredits,
  openModalCreditsChatGpt,
} from 'modules/modal';
import * as openaiService from 'services/openai';
import { useChatGptCredits } from 'pages/PropertyStore/hooks/useChatGptCredits';
import moment from 'lib/moment';

function ChatGpt({ propertyId, onGenerate, onChangeLoading, onError }) {
  const dispatch = useDispatch();
  const { change } = useReduxForm();
  const [isFetching, setIsFetching] = useState(false);
  const {
    data,
    fetchData: fetchCredits,
    isFetching: isFetchingCredits,
  } = useChatGptCredits();

  useEffect(() => {
    if (onChangeLoading) onChangeLoading(isFetching);
  }, [isFetching]);

  const handleClickExtract = () => {
    dispatch(
      openModalChatGptExtract({
        onSuccess: fetchCredits,
      })
    );
  };

  const generateDescription = async (type) => {
    try {
      setIsFetching(true);

      const response = await openaiService.generateDescription({
        property_id: propertyId,
        tone: type,
      });

      if (response.data.credits === 0) {
      }

      dispatch(change('title', response.data.title));
      dispatch(change('description', response.data.content));

      if (onGenerate) onGenerate(response);

      return response;
    } catch (err) {
      if (onError)
        onError({
          err,
          propertyId,
          tone: type,
          generateDescription: () => generateDescription(type),
        });
    } finally {
      setIsFetching(false);

      fetchCredits();
    }
  };

  const handleClickGenerateDescription = (type, close) => () => {
    close();

    if (data.credits === 0) {
      dispatch(
        openModalChatGptNoCredits({
          onSuccess: fetchCredits,
        })
      );
      return false;
    }

    const dontShowAgain = localStorage.getItem(
      '@tecimob/chat-gpt/dont_show_again'
    );

    // Pode mostrar se tiver a chave ou se está marcado
    // false para se não pode mostrar novamente
    const canShowModal =
      !dontShowAgain ||
      dontShowAgain === 'false' ||
      dontShowAgain === 'undefined';

    if (canShowModal) {
      dispatch(
        openModalChatGpt({
          onConfirm: () => generateDescription(type),
        })
      );
    } else {
      generateDescription(type);
    }
  };

  const isDisabled = isFetching;

  return (
    <>
      <Wrapper>
        <Title>Descrição com ChatGPT</Title>
        <Content>
          <Dropdown isDropHover>
            {({ close }) => (
              <>
                <ChatGptButton disabled={isDisabled}>
                  <BsStars />
                  Gerar descrição
                </ChatGptButton>
                {!isDisabled && (
                  <List>
                    <Item>
                      <button
                        type="button"
                        onClick={handleClickGenerateDescription(
                          'formal',
                          close
                        )}
                      >
                        Tom Formal
                      </button>
                    </Item>
                    <Item>
                      <button
                        type="button"
                        onClick={handleClickGenerateDescription(
                          'informal',
                          close
                        )}
                      >
                        Tom Informal
                      </button>
                    </Item>
                    <Item>
                      <button
                        type="button"
                        onClick={handleClickGenerateDescription(
                          'humorístico',
                          close
                        )}
                      >
                        Tom Humorístico
                      </button>
                    </Item>
                    <Item>
                      <button
                        type="button"
                        onClick={handleClickGenerateDescription('sério', close)}
                      >
                        Tom Sério
                      </button>
                    </Item>
                    <Item>
                      <button
                        type="button"
                        onClick={handleClickGenerateDescription(
                          'profissional',
                          close
                        )}
                      >
                        Tom Profissional
                      </button>
                    </Item>
                    <Item>
                      <button
                        type="button"
                        onClick={handleClickGenerateDescription(
                          'descontraido',
                          close
                        )}
                      >
                        Tom Descontraído
                      </button>
                    </Item>
                  </List>
                )}
              </>
            )}
          </Dropdown>
          <Credits
            onClick={() => {
              dispatch(
                openModalCreditsChatGpt({
                  onSuccess: () => {
                    fetchCredits();
                  },
                })
              );
            }}
          >
            <IconCredit>
              <BsCartPlus size={18} />
            </IconCredit>
            <div>
              <p>{data?.credits} créditos</p>
              <p className="text">
                <i>
                  Renova dia{' '}
                  {data.will_reset_in
                    ? moment(data.will_reset_in, 'DD-MM-YYYY').format('DD')
                    : null}
                </i>
              </p>
              <p
                className="text"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="h-link" onClick={handleClickExtract}>
                  Extrato de consumo
                </span>
              </p>
            </div>
          </Credits>
        </Content>
      </Wrapper>
      {/*<Loading isVisible={isFetching} />*/}
    </>
  );
}

export default ChatGpt;
