import React from 'react';
import Alert from 'react-s-alert';
import { useEnterprise } from 'pages/EnterpriseStore/context';

const Pages = () => {
  const {
    enterpriseId,
    nextPage,
    prevPage,
    currentPage,
    pages,
    isNextPage,
    setIsNextPage
  } = useEnterprise();
  const Page = pages[currentPage].component;
  return (
    <Page
      enterpriseId={enterpriseId}
      nextPage={nextPage}
      prevPage={prevPage}
      onSubmitSuccess={(response, dispatch, props) => {
        if (isNextPage) {
          nextPage();
          setIsNextPage(false);
        }

        // Verifica se tem informações vindas
        if (response?.data) {
          // Se tiver reinicializa o formulário com as novas informações
          props.initialize(response.data);
        }

        Alert.success('Salvo');
      }}
    />
  );
};

export default Pages;
