import { useExport } from './useExport';

export const useExportDealsClosed = ({ userId }) => {
  return useExport({
    resource: '/crm/deals/inactivesDownload',
    filter: {
      by_person_user_id: userId,
      by_inactive_status: 'all',
    },
    sort: '-updated_at',
    userId,
    fileName: 'backup-negocios-encerrados',
  });
};
