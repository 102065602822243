import { openModalRemoveAccount } from 'modules/modal';
import { MdHelp } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Box } from './styles';

function RemoveAccount() {
  const dispatch = useDispatch();

  async function removeAccount(e) {
    e.preventDefault();
    dispatch(openModalRemoveAccount());
  }

  return (
    <Box>
      <h5>Gerenciamento do cadastro</h5>
      <a href="./" onClick={removeAccount} className="h-link h-color--danger">
        Excluir minha conta e todos os dados
      </a>
      <span className="h-margin-left--5">
        <MdHelp
          className="h-color--secondary"
          data-tip="Cuidado! Esta ação não pode ser desfeita. Não faça isso!! :("
        />
      </span>
    </Box>
  );
}

export default RemoveAccount;
