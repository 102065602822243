import React from 'react';
import { Wrapper } from './styles';
import * as PropTypes from 'prop-types';
import { plural } from 'lib/text';

const countDealsBooked = (deals) =>
  deals.reduce((acc, deal) => {
    if (deal.property.is_booked) {
      acc++;
    }

    return acc;
  }, 0);

function Properties({ data }) {
  const dealsLength = data?.deals?.length;
  const hasPropertyDisabled = data?.deals?.some(
    (deal) => deal.property.status !== 'Disponível'
  );

  if (dealsLength === 1) {
    const property = data.deals?.[0]?.property;
    return (
      <Wrapper disabled={hasPropertyDisabled}>
        <p>
          {property?.type}
          {property.is_booked ? <small> (Reservado)</small> : null}
        </p>
        <p>{property?.price}</p>
      </Wrapper>
    );
  }

  const countBooked = countDealsBooked(data.deals);

  return (
    <Wrapper disabled={hasPropertyDisabled}>
      <p>
        {dealsLength} imóveis | {data.price_sum}
      </p>
      {countBooked > 0 ? (
        <p>
          <small>
            {plural(
              countBooked,
              'imóvel está reservado',
              'imóveis estão reservados'
            )}
          </small>
        </p>
      ) : null}
    </Wrapper>
  );
}

Properties.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    is_stagnant: PropTypes.bool,
    stagnant_days: PropTypes.number,
    has_calendar: PropTypes.bool,
    deals_count: PropTypes.number,
    order: PropTypes.number,
    deals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        property: PropTypes.shape({
          id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          subtype: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
          address_formatted: PropTypes.string.isRequired,
          cover_image_url: PropTypes.string,
        }),
      })
    ),
  }),
};

export default Properties;
