import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { openModalInviteUser } from 'modules/modal';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import teamIcon from './assets/team.svg';
import { Content, ExpirationWarning, Title, Wrapper } from './styles';

function ModalInviteUserReception({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
}) {
  const dispatch = useDispatch();
  
  const handleOpenModalInviteUser = () => {
    dispatch(openModalInviteUser());
  }

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate handleClose={handleClose}>
        <Wrapper>
          <img src={teamIcon} alt="Convide pessoas para seu time" />
          <div>
            <Title>Convide usuários para sua equipe</Title>
            <Content>
              <p>Envie convite para corretores e usuários</p>

              <p>Preencha o formulário a seguir com os dados do usuário que você quer convidar. Após o cadastro, o usuário terá acesso ao seu painel administrativo e poderá gerenciar seus clientes e imóveis.</p>

              <ExpirationWarning>O link poderá ser utilizado uma vez e terá validade de 2 dias.</ExpirationWarning>
            </Content>
          </div>
        </Wrapper>

        <ModalFooter>
          <Button
            color="white"
            colorText="danger"
            onClick={handleClose}
            className="h-flex__cell--shrink"
          >
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" onClick={handleOpenModalInviteUser}>
            Próximo
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}

export default ModalInviteUserReception;
