import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 10px;

  & + & {
    margin-top: 15px;
  }
`;
export const Title = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
`;
export const Text = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 14.4px;
`;

export const Close = styled.div`
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
  position: absolute;
  left: 100%;
  bottom: 100%;
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.borderColor};
  border-radius: 100%;
  margin-left: -10px;
  margin-bottom: -10px;
  cursor: pointer;

  svg {
    font-size: 14px;
  }
`;
