import React from 'react';
import classnames from 'classnames';
// Components
import Button from '../Button';
// Assets
import noData from '../../assets/img/noData/client.svg';

const defaultProps = {
  position: 'top',
  title: 'Nenhum dado encontrado',
  text: 'Tente procurar novamente no campo acima!',
  isFetching: false,
  dataLength: 0,
  image: noData,
  children: null,
};

const isVisible = (isFetching, dataLength) => !isFetching && dataLength === 0;

const Nodata = ({
  image,
  position,
  title,
  text,
  isFetching,
  dataLength,
  actionText,
  actionHandler,
  children,
  style,
  className,
  icon: Icon,
  iconSize = 50,
  iconColor = '#D3DCEB',
}) =>
  isVisible(isFetching, dataLength) && (
    <div
      style={style}
      className={classnames('Nodata', className, {
        [`Nodata--${position}`]: position,
      })}
    >
      {Icon ? (
        <Icon size={iconSize} color={iconColor} />
      ) : (
        image && <img src={image} alt={title} className="Nodata__img" />
      )}
      <div className="Nodata__container">
        <h4 className="Nodata__title">{title}</h4>
        {text && <p className="Nodata__text">{text}</p>}
        {children}
        {actionText && (
          <Button className="Nodata__action" onClick={actionHandler}>
            {actionText}
          </Button>
        )}
      </div>
    </div>
  );

Nodata.defaultProps = defaultProps;

export default Nodata;
