import React, { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { Field, reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
import FieldPerson from 'containers/FieldPerson';
import ReservationField from './ReservationField';
import { FieldBool, Textarea } from 'components/Form';
import { MdInfo } from 'react-icons/md';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

const LabelRemove = () => {
  return (
    <div>
      Remover da lista de compatíveis?{' '}
      <MdInfo
        className="h-margin-left--5 h-color--secondary"
        data-tip="Caso este imóvel esteja pendente na lista de nutrição de clientes compatíveis, ele será removido."
      />
    </div>
  );
};

function Form({ change, handleSubmit, handleClose }) {
  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <FieldPerson xs={6} name="people_id" />
        <ReservationField change={change} />
      </Row>
      <Row>
        <FieldBool
          xs={6}
          label="Ocultar imóvel do Site e Portais?"
          name="should_change_publish"
          style={{ width: 130 }}
        />
        <FieldBool
          xs={6}
          label={<LabelRemove />}
          name="should_close_matcheds"
          style={{ width: 130 }}
        />
      </Row>
      <Row>
        <Field
          label="Observações sobre o motivo da reserva"
          type="simple"
          xs={12}
          name="booking_note"
          component={Textarea}
        />
      </Row>
      <ModalFooter withBorder>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormReservationCreate',
})(Form);
