import React from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { hideVideo, showVideo, videoSelector } from '../../../modules/propertyAdd';
import { Row } from 'react-flexbox-grid';
import YoutubeVideo from '../../../components/YoutubeVideo';
import { isValidYoutubeUrl } from '../../../lib/text';

import FormBox from '../../../components/FormBox';
import { Input } from '../../../components/Form';

const defaultProps = {};

const propTypes = {};

class Video extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangeVideo = this.handleChangeVideo.bind(this);
    this.handleOnReadyVideo = this.handleOnReadyVideo.bind(this);
  }

  componentDidUpdate(prevProps) {
    // verifica se o video anterior é diferente do video atual
    if (prevProps.videoInputText !== this.props.videoInputText) {
      // se for diferente atualiza o video que está renderizado se for valido
      this.handleChangeVideo(null, this.props.videoInputText);
    }
  }

  handleChangeVideo(e, value) {
    if (!value) return false;

    // pega o id do video
    const youTubeId = isValidYoutubeUrl(value);

    const {
      video: {
        meta: { isVisible }
      }
    } = this.props;

    // se o video é valido mostra o video
    if (youTubeId) {
      this.props.showVideo(youTubeId);
      return false;
    }

    // se ele estiver visivel e a url não for valida esconde o video
    if (isVisible) {
      this.props.hideVideo();
    }

    return false;
  }

  // verifica se o vídeo é valido checando a duracao dele
  handleOnReadyVideo(e) {
    if (e.target.getDuration() === 0) {
      this.props.hideVideo();
    }
  }

  render() {
    const {
      video: {
        videoId,
        meta: { isVisible }
      }
    } = this.props;

    return (
      <FormBox title="Vídeo" className="js-video">
        <Row>
          <Field
            xs={12}
            label="Insira a URL de um vídeo do Youtube"
            name="video"
            component={Input}
            placeholder="Exemplo: https://www.youtube.com/watch?v=t3217H8JppI"
            onChange={this.handleChangeVideo}
          />
        </Row>
        {isVisible && videoId && (
          <YoutubeVideo
            youtubeProps={{
              videoId,
              onReady: this.handleOnReadyVideo
            }}
          />
        )}
      </FormBox>
    );
  }
}

Video.defaultProps = defaultProps;
Video.propTypes = propTypes;

const selector = formValueSelector('propertiesAdd');

const mapStateToProps = state => ({
  video: videoSelector(state),
  videoInputText: selector(state, 'video')
});

export default connect(mapStateToProps, {
  showVideo,
  hideVideo
})(Video);
