// import styles from './styles'
import { Financial } from 'features/Financial';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { openModalStoreCategory } from '../../../../modals/ModalStoreCategory';
import { useParams } from 'react-router';

export function Nodata({ onAddCategory }) {
  const dispatch = useDispatch();
  const { type } = useParams();
  const isDebit = type !== 'income';

  const handleAddCategory = ({ isCategory = true }) => {
    dispatch(
      openModalStoreCategory({
        isDebit,
        isCategory,
        onSuccess: () => {
          if (onAddCategory) onAddCategory();
        },
      })
    );
  };

  return (
    <Financial.Nodata title="Voce ainda não possui nenhuma categoria cadastrada">
      <Button color="secondary" onClick={handleAddCategory}>
        Adicionar categoria
      </Button>
    </Financial.Nodata>
  );
}
