import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ICON_NAMES, SIZES } from '../../constants/config';
import sprite from '../../assets/img/sprite.svg';

export const icons = {
  'logo-tecimob':
    'M99.6345,15.1896 C99.6345,14.5326 99.1051,14 98.4522,14 L71.1823,14 C70.5293,14 70,14.5326 70,15.1896 L70,45.0219 C70,45.6788 70.5293,46.2114 71.1823,46.2114 L98.4522,46.2114 C99.1051,46.2114 99.6345,45.6788 99.6345,45.0219 L99.6345,15.1896 Z M87.8396,15.796 L97.2531,22.4277 L96.5833,25.7978 L87.8348,19.6346 L75.6179,28.2412 L75.6144,41.0801 L90.1995,41.0801 L90.1995,44.1492 L72.3813,44.1492 L72.3813,26.6793 L87.8396,15.796 Z M81.6556,39.1689 L84.8174,39.1689 L84.8174,35.9878 L81.6556,35.9878 L81.6556,39.1689 Z M77.5438,35.0235 L80.7056,35.0235 L80.7056,31.8424 L77.5438,31.8424 L77.5438,35.0235 Z M81.6556,35.0235 L84.8174,35.0235 L84.8174,31.8424 L81.6556,31.8424 L81.6556,35.0235 Z',
  account:
    'M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z',
  'account-add':
    'M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z',
  'calendar-note':
    'M17 10H7v2h10v-2zm2-7h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zm-5-5H7v2h7v-2z',
  'card-travel':
    'M20 6h-3V4c0-1.11-.89-2-2-2H9c-1.11 0-2 .89-2 2v2H4c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zM9 4h6v2H9V4zm11 15H4v-2h16v2zm0-5H4V8h3v2h2V8h6v2h2V8h3v6z',
  'check-circle':
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z',
  email:
    'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z',
  globe:
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z',
  home: 'M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z',
  notifications:
    'M11.5 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6.5-6v-5.5c0-3.07-2.13-5.64-5-6.32V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5v.68c-2.87.68-5 3.25-5 6.32V16l-2 2v1h17v-1l-2-2z',
  print:
    'M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z',
  'view-dashboard':
    'M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z',
  star:
    'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z',
  'star-outline':
    'M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z',
  'check-square':
    'M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z',
  'square-o':
    'M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z',
  close:
    'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z',
  'close-circle':
    'M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z',
  hotel:
    'M7 13c1.66 0 3-1.34 3-3S8.66 7 7 7s-3 1.34-3 3 1.34 3 3 3zm12-6h-8v7H3V5H1v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4z',
  car:
    'M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z',
  'crop-free':
    'M3 5v4h2V5h4V3H5c-1.1 0-2 .9-2 2zm2 10H3v4c0 1.1.9 2 2 2h4v-2H5v-4zm14 4h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm0-16h-4v2h4v4h2V5c0-1.1-.9-2-2-2z',
  search:
    'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z',
  plus: 'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z',
  'chevron-up': 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z',
  'chevron-down': 'M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z',
  'chevron-left': 'M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z',
  'chevron-right': 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z',
  edit:
    'M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z',
  facebook:
    'M72.089.02L59.624 0C45.62 0 36.57 9.285 36.57 23.656v10.907H24.037a1.96 1.96 0 0 0-1.96 1.961v15.803a1.96 1.96 0 0 0 1.96 1.96H36.57v39.876a1.96 1.96 0 0 0 1.96 1.96h16.352a1.96 1.96 0 0 0 1.96-1.96V54.287h14.654a1.96 1.96 0 0 0 1.96-1.96l.006-15.803a1.963 1.963 0 0 0-1.961-1.961H56.842v-9.246c0-4.444 1.059-6.7 6.848-6.7l8.397-.003a1.96 1.96 0 0 0 1.959-1.96V1.98A1.96 1.96 0 0 0 72.089.02z',
  'google-plus':
    'M168.223 216.2c.008 16.653-.016 33.306.008 49.959 23.287-.008 46.581-.008 69.86 0-2.696 15.958-12.2 30.55-25.67 39.535-8.464 5.687-18.2 9.377-28.23 11.126-10.096 1.717-20.528 1.94-30.6-.096-10.239-2.037-20.014-6.302-28.542-12.3-13.63-9.568-24.038-23.561-29.382-39.304-5.464-16.021-5.504-33.84.024-49.846 3.832-11.277 10.223-21.7 18.607-30.174 10.343-10.575 23.799-18.13 38.286-21.23 12.407-2.643 25.494-2.14 37.638 1.534 10.327 3.13 19.847 8.777 27.638 16.213 7.88-7.827 15.72-15.694 23.591-23.53 4.128-4.216 8.463-8.25 12.455-12.587-11.951-11.038-25.95-19.911-41.253-25.526-27.55-10.127-58.621-10.335-86.356-.75-31.254 10.686-57.868 34.024-72.628 63.52a127.449 127.449 0 0 0-11.143 32.17c-5.672 27.803-1.72 57.443 11.127 82.77 8.352 16.524 20.32 31.22 34.83 42.737 13.696 10.902 29.663 18.961 46.582 23.45 21.359 5.718 44.093 5.59 65.588.703 19.431-4.465 37.814-13.73 52.485-27.26 15.511-14.232 26.583-32.986 32.439-53.137 6.391-21.98 7.271-45.453 3.263-67.952-40.213-.016-80.427-.008-120.641-.008zM440 218.916h-43.902v-43.832h-31.934v43.832h-43.901V250.8h43.901v43.832h31.934V250.8H440z',
  twitter:
    'M612 116.258a250.714 250.714 0 0 1-72.088 19.772c25.929-15.527 45.777-40.155 55.184-69.411-24.322 14.379-51.169 24.82-79.775 30.48-22.907-24.437-55.49-39.658-91.63-39.658-69.334 0-125.551 56.217-125.551 125.513 0 9.828 1.109 19.427 3.251 28.606-104.326-5.24-196.835-55.223-258.75-131.174-10.823 18.51-16.98 40.078-16.98 63.101 0 43.559 22.181 81.993 55.835 104.479a125.556 125.556 0 0 1-56.867-15.756v1.568c0 60.806 43.291 111.554 100.693 123.104-10.517 2.83-21.607 4.398-33.08 4.398-8.107 0-15.947-.803-23.634-2.333 15.985 49.907 62.336 86.199 117.253 87.194-42.947 33.654-97.099 53.655-155.916 53.655-10.134 0-20.116-.612-29.944-1.721 55.567 35.681 121.536 56.485 192.438 56.485 230.948 0 357.188-191.291 357.188-357.188l-.421-16.253c24.666-17.593 46.005-39.697 62.794-64.861z',
  info:
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z',
  phone:
    'M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z',
  'rotate-left':
    'M7.11 8.53L5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z',
  receipt:
    'M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z',
  'accounts-alt':
    'M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7C15.12 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z',
  'sort-amount-desc': 'M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z',
  money:
    'M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z',
  'money-box':
    'M11 17h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1h-3v-1h4V8h-2V7h-2v1h-1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H9v2h2v1zm9-13H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4V6h16v12z',
  spinner:
    'M30.1 4.6c-2.872 0-5.25 2.377-5.25 5.25 0 2.872 2.378 5.25 5.25 5.25 2.873 0 5.25-2.378 5.25-5.25 0-2.873-2.377-5.25-5.25-5.25zm14.551 5.5c-3.419 0-6.25 2.83-6.25 6.25 0 3.419 2.831 6.25 6.25 6.25 3.42 0 6.25-2.831 6.25-6.25 0-3.42-2.83-6.25-6.25-6.25zm-28.75 1.2c-2.57 0-4.7 2.129-4.7 4.7s2.13 4.701 4.7 4.701c2.572 0 4.7-2.13 4.7-4.701 0-2.571-2.128-4.7-4.7-4.7zm-6.7 15.6c-2.19 0-4 1.812-4 4 0 2.189 1.81 4 4 4 2.187 0 4-1.811 4-4 0-2.188-1.813-4-4-4zm41.599 0c-2.188 0-4 1.812-4 4 0 2.189 1.812 4 4 4 2.188 0 4-1.811 4-4 0-2.188-1.812-4-4-4zM15.2 41.8c-2.188 0-4 1.812-4 4 0 2.19 1.812 4 4 4 2.188 0 4-1.81 4-4 0-2.188-1.812-4-4-4zm29.801 0c-2.188 0-4 1.812-4 4 0 2.19 1.812 4 4 4 2.188 0 4-1.81 4-4 0-2.188-1.812-4-4-4zm-14.9 5.6c-2.189 0-4 1.812-4 4 0 2.189 1.811 4 4 4 2.188 0 4-1.811 4-4 0-2.188-1.812-4-4-4z',
  lock:
    'M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z',
  refresh:
    'M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z',
  'trending-up':
    'M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z',
  download: 'M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z',
  linkedin:
    'M271.134 187.916c-4.109 5.136-11.494 7.711-22.17 7.711-13.613 0-23.162-4.01-28.666-12.03-5.504-8.022-8.237-19.177-8.237-33.5V40.169c0-2.17-.813-4.075-2.438-5.71-1.626-1.635-3.59-2.453-5.908-2.453h-57.089c-2.163 0-4.057.818-5.683 2.453-1.626 1.635-2.438 3.54-2.438 5.71v202.598c0 2.176.812 4.088 2.438 5.723 1.626 1.628 3.52 2.446 5.683 2.446h55.47c1.997 0 3.63-.393 4.877-1.178 1.235-.766 2.042-2.215 2.439-4.307.364-2.105.601-3.501.69-4.203.065-.695.11-2.453.11-5.26 14.38 13.545 33.402 20.304 57.082 20.304 26.458 0 47.188-6.637 62.197-19.95C344.48 223.044 352 203.243 352 176.954V40.163c0-2.17-.813-4.075-2.432-5.71-1.626-1.635-3.52-2.453-5.69-2.453H285.4c-2.163 0-4.064.818-5.683 2.453-1.626 1.635-2.438 3.54-2.438 5.71v123.471c0 11.053-2.042 19.125-6.145 24.275zM98.14 285.484c-7.597-7.628-16.724-11.439-27.399-11.439s-19.79 3.811-27.373 11.44C35.795 293.111 32 302.284 32 313.022c0 10.737 3.789 19.916 11.373 27.538S60.077 352 70.746 352c10.67 0 19.802-3.81 27.4-11.439 7.564-7.628 11.372-16.801 11.372-27.538 0-10.738-3.808-19.91-11.373-27.539zm9.267-42.717V40.169c0-2.17-.806-4.075-2.432-5.71-1.625-1.635-3.526-2.453-5.683-2.453H42.195c-2.163 0-4.057.818-5.677 2.453-1.619 1.635-2.438 3.54-2.438 5.71v202.598c0 2.176.813 4.088 2.438 5.723 1.626 1.628 3.52 2.446 5.677 2.446h57.096c2.163 0 4.064-.818 5.683-2.446 1.626-1.635 2.426-3.54 2.426-5.723z',
  whatsapp:
    'M90 43.841c0 24.213-19.779 43.841-44.182 43.841a44.256 44.256 0 0 1-21.357-5.455L0 90l7.975-23.522a43.38 43.38 0 0 1-6.34-22.637C1.635 19.628 21.416 0 45.818 0 70.223 0 90 19.628 90 43.841zM45.818 6.982c-20.484 0-37.146 16.535-37.146 36.859 0 8.065 2.629 15.534 7.076 21.61L11.107 79.14l14.275-4.537A37.122 37.122 0 0 0 45.819 80.7c20.481 0 37.146-16.533 37.146-36.857S66.301 6.982 45.818 6.982zm22.311 46.956c-.273-.447-.994-.717-2.076-1.254-1.084-.537-6.41-3.138-7.4-3.495-.993-.358-1.717-.538-2.438.537-.721 1.076-2.797 3.495-3.43 4.212-.632.719-1.263.809-2.347.271-1.082-.537-4.571-1.673-8.708-5.333-3.219-2.848-5.393-6.364-6.025-7.441-.631-1.075-.066-1.656.475-2.191.488-.482 1.084-1.255 1.625-1.882.543-.628.723-1.075 1.082-1.793.363-.717.182-1.344-.09-1.883-.27-.537-2.438-5.825-3.34-7.977-.902-2.15-1.803-1.792-2.436-1.792-.631 0-1.354-.09-2.076-.09s-1.896.269-2.889 1.344c-.992 1.076-3.789 3.676-3.789 8.963 0 5.288 3.879 10.397 4.422 11.113.541.716 7.49 11.92 18.5 16.223C58.2 65.771 58.2 64.336 60.186 64.156c1.984-.179 6.406-2.599 7.312-5.107.9-2.512.9-4.663.631-5.111z',
  'plus-circle':
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z'
};

const defaultProps = {
  size: 'small',
  className: '',
  handleClick: null,
  style: null
};

const propTypes = {
  name: PropTypes.oneOf(ICON_NAMES).isRequired,
  size: PropTypes.oneOf(SIZES),
  className: PropTypes.string,
  handleClick: PropTypes.func,
  style: PropTypes.object
};

const Icon = ({ color, name, className, size, handleClick, style }) => (
  <svg
    className={classnames('Icon', className, {
      [`Icon--${size}`]: size,
      [`h-color--${color}`]: color
    })}
    onClick={handleClick}
    style={style}
  >
    <use xlinkHref={`${sprite}#${name}`} />
  </svg>
);

Icon.defaultProps = defaultProps;
Icon.propTypes = propTypes;

export default Icon;
