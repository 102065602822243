import React from 'react';
import PropTypes from 'prop-types';
// Component
import Table from 'components/Table';
import PropertyItem from '../PropertyItem';

const propTypes = {
  properties: PropTypes.array,
};

const defaultProps = {
  properties: [],
};

const PropertiesTable = ({
  editted,
  canSelect,
  toggleSelected,
  properties,
  handleOpenProperty,
}) => {
  return (
    <div>
      <Table
        data={properties}
        isFetching={false}
        renderTitle={() => (
          <tr>
            {canSelect && <th />}
            <th>Número</th>
            <th>Ref</th>
            <th>Dormitórios</th>
            <th>Garagens</th>
            <th>Área Construída</th>
            <th>Área Privativa</th>
            <th>Área Total</th>
            <th>Transação</th>
            <th colSpan={2}></th>
          </tr>
        )}
        renderItem={(property) => (
          <PropertyItem
            editted={editted === property.id}
            canSelect={canSelect}
            toggleSelected={toggleSelected}
            handleOpenProperty={handleOpenProperty}
            key={property.id}
            property={property}
          />
        )}
      />
    </div>
  );
};

PropertiesTable.propTypes = propTypes;
PropertiesTable.defaultProps = defaultProps;

export default PropertiesTable;
