import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Row } from 'react-flexbox-grid';

import { selector } from '../';
// Components
import FormBox from 'components/FormBox';
import { FieldBool, Input } from 'components/Form';

const defaultProps = {};
const propTypes = {};

const Tour = ({ hasTour }) => (
  <FormBox
    title={() => (
      <div style={{ padding: '20px 30px 0' }}>
        <div className="h-flex__cell h-flex h-flex--center-center">
          <h4
            className="FormBox__title"
            style={{ paddingTop: '', paddingBottom: '0' }}
          >
            Tour 360
          </h4>
          <span className="h-flex__cell--grow" />
          <FieldBool name="has_tour" style={{ marginBottom: 0 }} />
        </div>
        <p
          className="h-color--primary"
          style={{
            fontSize: '14px',
            paddingTop: '10px',
            paddingBottom: '20px'
          }}
        >
          Para adicionar um tour 360º, é necessário que você tenha cadastrado
          suas imagens em serviços como o{' '}
          <a
            className="h-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.meupasseiovirtual.com/"
          >
            Meu Passeio Virtual
          </a>{' '}
          ou{' '}
          <a
            className="h-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://roundme.com/"
          >
            Roundme
          </a>
          .
        </p>
        <div style={{ width: '100%', height: '1px', background: '#DEDEDE' }} />
      </div>
    )}
    className="js-tour"
  >
    {hasTour && (
      <Row>
        <Field
          xs={12}
          label="Insira a URL 360° do seu imóvel"
          name="tour_360"
          component={Input}
          placeholder="Exemplo: https://roundme.com/tour/000000/view/000000/"
        />
      </Row>
    )}
  </FormBox>
);

Tour.defaultProps = defaultProps;
Tour.propTypes = propTypes;

const mapStateToProps = state => ({
  hasTour: selector(state, 'has_tour')
});

export default connect(mapStateToProps)(Tour);
