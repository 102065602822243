import React, { memo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import * as pages from './pages';

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/featured-lists`} component={pages.FeaturedLists} />

      <Route exact path={`${path}/slides`} component={pages.Slides} />
      <Route exact path={`${path}/slides/create`} component={pages.SlideForm} />
      <Route
        exact
        path={`${path}/slides/property/:property_id`}
        component={pages.SlideForm}
      />
      <Route
        exact
        path={`${path}/slide/update/:slide_id`}
        component={pages.SlideForm}
      />
      <Route
        path={`${path}/super-highlights`}
        component={pages.SuperHighlights}
      />
      <Route path={`${path}/posts`} component={pages.Posts} />
      <Route path={`${path}/map`} component={pages.Map} />
      <Route path={`${path}/partners`} component={pages.Partners} />
      <Route path={`${path}/videos`} component={pages.Videos} />
      <Route path={`${path}/posts`} component={pages.Posts} />
      <Route path={`${path}/testimonials`} component={pages.Testimonials} />
      <Route path={`${path}/locations`} component={pages.Locations} />
      <Redirect to={`${path}/slides`} />
    </Switch>
  );
};

export default memo(Routes);
