import React, { useEffect, useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Select } from 'components/Form';
import { Form } from './styles';
import { useFunnel } from 'pages/Crm/hooks/useFunnel';
import { useFormUserId } from 'pages/Crm/components/FormUser';

function Filter({ change, handleSubmit }) {
  const userId = useFormUserId();
  const { funnels } = useFunnel({ userId });

  const handleChangeFunnel = (event, value) => {
    // Atualiza sempre para o ultimo funnel id selecionado
    localStorage.setItem('@tecimob/funnel_id', value);
  };

  const getFunnelId = () => {
    try {
      const selectedFunnelId = localStorage.getItem('@tecimob/funnel_id');

      const hasFunnelInList = funnels.find((funnel) => {
        return funnel.id === selectedFunnelId;
      });

      // Verifica se existe o funil do localStorage dentro da lista de funis carregados
      if (hasFunnelInList) {
        return selectedFunnelId;
      }

      return funnels?.[0]?.id;
    } catch (err) {
      console.error(err);
      return funnels?.[0]?.id;
    }
  };

  const currentFunnelId = useMemo(() => {
    return getFunnelId();
  }, [funnels]);

  useEffect(() => {
    change('funnel_id', currentFunnelId);
  }, [currentFunnelId]);

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="funnel_id"
        labelKey="name"
        valueKey="id"
        options={funnels}
        component={Select}
        formGroupClassName="h-margin-right--15"
        placeholder="Funil"
        onChange={handleChangeFunnel}
      />
    </Form>
  );
}

export default reduxForm({
  form: 'FilterPeople',
  enableReinitialize: true,
  initialValues: {
    funnel_id: localStorage.getItem('@tecimob/funnel_id'),
  },
})(Filter);
