export default values => {
  const errors = {};

  if (values.password !== values.password_confirm) {
    errors.password = 'As senhas não conferem';
    errors.password_confirm = ' ';
  }

  return errors;
};
