import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'modules/modal';

export const useCloseModalWithEsc = () => {
  const dispatch = useDispatch();

  const handleEsc = (e) => {
    if (e.keyCode === 27 || e.keyCode === '27') {
      dispatch(closeModal());
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEsc, false);

    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, []);
};
