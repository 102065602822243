import * as propertyService from '../../../../services/properties';
// Modules
import dataReducer from '../../../../modules/data';

// Constants
export const MODULE_NAME = 'propertyRecent';

export const {
  reducer: propertyRecentReducer,
  actionCreators: propertyRecentActions
} = dataReducer(`propertyDashboard/${MODULE_NAME}`);

// Selectors
export const dataSelector = state => state.propertyDashboard[MODULE_NAME].data;
export const isFetchingSelector = state =>
  state.propertyDashboard[MODULE_NAME].meta.isFetching;

// Reducer
export const fetchPropertyRecent = params => dispatch => {
  dispatch(propertyRecentActions.request());

  return propertyService
    .getList({
      offset: 1,
      sort: '-updated_at',
      ...params
    })
    .then(({ data }) => {
      dispatch(propertyRecentActions.receive(data));
      return data;
    });
};

export default propertyRecentReducer;
