import Wrapper from "components/Wrapper";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useRequest } from "hooks/useRequest";
import { useEffect, useState } from "react";
import { getRecommendations } from "services/user";
import AsideRecommendations from "./components/AsideRecommendations";
import List from "./components/List";

function Recommendations() {
  const [params, setParams] = useState({});

  const { data, meta, fetchData, isFetching } = useRequest({
    request: (requestParams) => getRecommendations({
      offset: 1,
      limit: 20,
      ...requestParams,
    }),
    initialFetch: false,
  });

  useCurrentUser();

  useEffect(() => fetchData(params), [params, fetchData]);

  return (
    <Wrapper full>
      <AsideRecommendations fetchData={fetchData} setParams={setParams} />
      <List
        data={data}
        pagination={meta?.pagination}
        setParams={setParams}
        isFetching={isFetching}
      />
    </Wrapper>
  );
}

export default Recommendations;
