import React from 'react';
import { Col } from 'react-flexbox-grid';
import { Field } from 'redux-form';
import { Input } from 'components/Form';
import GroupControl, { GroupItem } from 'components/GroupControl';
// import { Container } from './styles';

const Price = ({ label, name, placeholder = 'Indiferente' }) => {
  return (
    <Col xs={3}>
      <GroupControl label={label}>
        <GroupItem>
          <Field
            valueFormat
            name={name}
            component={Input}
            placeholder={placeholder}
          />
        </GroupItem>
        <GroupItem label="R$" />
      </GroupControl>
    </Col>
  );
};

export default Price;
