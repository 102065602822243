import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 90px 0 78px;
`;

export const Icon = styled.div`
  margin: 0 auto;
  font-size: 50px;
  color: #d3dceb;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #5f6470;
  margin-top: 20px;
  margin-bottom: 30px;
`;
