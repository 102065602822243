import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FormTitle from '../../templates/Login/FormTitle';

import envelope from '../../assets/img/login/envelope.svg';

const defaultProps = {
  email: ''
};
const propTypes = {
  email: PropTypes.string
};

const RecoveryMsg = ({ email }) => (
  <div className="h-text-center">
    <img
      src={envelope}
      alt=""
      style={{ display: 'inline-block' }}
      className="h-margin-bottom--15"
    />
    <FormTitle
      title="Verifique seu e-mail"
      text={
        <div>
          Enviamos um e-mail para{' '}
          <strong className="h-color--primary">{email}</strong> com as
          instruções necessárias para recuperar sua senha.
        </div>
      }
    />
    <Link to="/" className="h-color--secondary">
      Voltar para o inicio
    </Link>
  </div>
);

RecoveryMsg.defaultProps = defaultProps;
RecoveryMsg.propTypes = propTypes;

export default RecoveryMsg;
