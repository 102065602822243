import React from 'react';
import { Field } from 'redux-form';
import { Input } from 'components/Form';

const FieldIncorporation = () => {
  return (
    <Field
      xs={3}
      label="Incorporação"
      placeholder="Digite o número"
      name="incorporation"
      component={Input}
    />
  );
};

export default FieldIncorporation;
