import React, { useContext } from 'react';
import Avatar from 'components/Avatar';
import { Wrapper, Title, List, Action } from './styles';
import { getName } from 'lib/text';
import { UserContext } from '../../../../contexts/UserContext';

const ChangeUser = () => {
  const { users, handleChangeUser } = useContext(UserContext);

  return (
    <Wrapper>
      <Title>Trocar de usuário</Title>
      <List>
        {users &&
          users.map(({ id, name, file_url }) => (
            <Action key={id} onClick={handleChangeUser(id)}>
              <Avatar image={file_url} text={getName(name)} hasZoom={false} />
              <p>{getName(name)}</p>
            </Action>
          ))}
      </List>
    </Wrapper>
  );
};

export default ChangeUser;
