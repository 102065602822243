import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';

const FooterActions = ({
  isLast,
  handleSubmit,
  onSubmit,
  submitText = 'Salvar e Adicionar Fotos',
}) => {
  const dispatch = useDispatch();

  let { path: basePath } = useRouteMatch();

  const { push } = useHistory();

  const isEditing = useMemo(() => {
    return basePath?.includes('update');
  }, [basePath]);

  return (
    <FixedBar left={274}>
      <Button
        type="button"
        color="white"
        colorText="secondary"
        onClick={() => {
          dispatch(
            openConfirmation({
              size: 324,
              title: 'Deseja mesmo sair do cadastro?',
              text: 'As informações que você salvou não serão perdidas.',
              cancelButtonText: 'Voltar',
              submitButtonText: 'Sair',
              request: () => {
                push('/site/content-site/locations');
              },
            })
          );
        }}
      >
        Cancelar
      </Button>
      <FixedBar.spacer />
      {isEditing && !isLast ? (
        <>
          <Button type="submit" color="success">
            Salvar
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={handleSubmit((values, dispatch, props) => {
              return onSubmit(
                {
                  ...values,
                  isFinishing: true,
                },
                dispatch,
                props
              );
            })}
          >
            Finalizar
          </Button>
        </>
      ) : (
        <>
          <Button type="submit" color="success">
            {submitText}
          </Button>
        </>
      )}
    </FixedBar>
  );
};

export default FooterActions;
