import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { MdCheckCircle, MdDelete } from 'react-icons/md';
import Image from 'components/Image';
// Components
import Button from 'components/Button';

const Item = ({
  device,
  isSelected,
  canRemove,
  id,
  fileUrl,
  handleDelete,
  ...rest
}) => (
  <Col
    xs={device === 'mobile' ? 3 : 6}
    tagName="button"
    type="button"
    {...rest}
    style={{ position: 'relative', marginTop: '10px' }}
  >
    {isSelected && (
      <div
        className="h-flex h-flex--center-center h-color--white h-text-center"
        style={{
          position: 'absolute',
          top: 0,
          left: 5,
          right: 5,
          bottom: 0,
          backgroundColor: 'rgba(62, 88, 124, 0.8)',
          justifyContent: 'center'
        }}
      >
        <span>
          <MdCheckCircle className="h-color--success" /> Imagem Selecionada
        </span>
      </div>
    )}
    <Image
      size="medium"
      type="outside"
      src={fileUrl}
      alt={id}
      style={{
        width: '100%',
        height: device === 'mobile' ? '255px' : '100px',
        objectFit: 'cover'
      }}
    />
    {canRemove && handleDelete && (
      <Button
        size="small"
        color="danger"
        type="button"
        onClick={handleDelete}
        style={{ position: 'absolute', top: '10px', right: '10px' }}
      >
        <MdDelete />
      </Button>
    )}
  </Col>
);

class HeaderImageField extends React.Component {
  static propTypes = {
    canToggle: PropTypes.bool
  };
  static defaultProps = {
    canToggle: false
  };

  handleClickItem = id => e => {
    const {
      canToggle,
      input: { value, onChange }
    } = this.props;
    e.stopPropagation();

    if (canToggle && value === id) {
      onChange('');
      return;
    }
    onChange(id);
  };

  handleDelete = id => async e => {
    await this.props.handleRemoveImage(id)(e);
  };

  renderItems = () => {
    const {
      device,
      options,
      input: { value }
    } = this.props;

    return options
      ? options.map(({ id, file_url, is_default }) => (
          <Item
            key={id}
            device={device}
            isSelected={id === value}
            canRemove={!is_default}
            id={id}
            fileUrl={file_url}
            onClick={this.handleClickItem(id)}
            handleDelete={this.handleDelete(id)}
          />
        ))
      : null;
  };

  render() {
    return <Row>{this.renderItems()}</Row>;
  }
}

export default HeaderImageField;
