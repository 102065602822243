import { useDispatch } from 'react-redux';
import { openModalPosting } from '../modals/ModalPosting';

export const useModalPosting = () => {
  const dispatch = useDispatch();

  const handleOpenModalPosting = ({
    type = 1,
    postingId,
    movimentId,
    posting,
    onSuccess,
  }) => {
    dispatch(
      openModalPosting({
        postingId,
        movimentId,
        type,
        posting,
        onSuccess,
      })
    );
  };

  return { handleOpenModalPosting };
};
