import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Service
import * as debitsService from 'services/financial/debits';
// Common Components
import { ModalTemplate } from 'components/Modal';
import Loading from 'components/Loading';
import TableData from './TableData';

class ModalDebitsByDay extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    debitId: PropTypes.string.isRequired,
    onAfterClose: PropTypes.func
  };

  static defaultProps = {
    debitId: '',
    date: '',
    onAfterClose: null
  };

  state = {
    date: 'DD/MM/AAAA',
    mailboxes: [],
    users: [],
    isFetching: false
  };

  fetchData = async () => {
    const { debitId } = this.props;
    this.setState({ isFetching: true });

    try {
      const {
        data: { created_at, users, mailboxes }
      } = await debitsService.getDebit(debitId);

      this.setState({
        mailboxes,
        users,
        date: created_at
      });
    } finally {
      this.setState({ isFetching: false });
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  handleClose = () => {
    const { handleClose, onAfterClose } = this.props;

    if (onAfterClose) {
      onAfterClose();
      return;
    }

    // Fecha a modal
    handleClose();
  };

  render() {
    const { modalConfig, isOpen, modalType } = this.props;
    const { date, mailboxes, users, isFetching } = this.state;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={this.handleClose}
      >
        <ModalTemplate
          title={`Debitos do dia ${date}`}
          handleClose={this.handleClose}
        >
          <Loading isVisible={isFetching} position="center" isBlock />
          {!isFetching && <TableData mailboxes={mailboxes} users={users} />}
        </ModalTemplate>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDebitsByDay);
