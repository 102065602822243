import React from 'react';
import { connect } from 'react-redux';
// Components
import Establishments from '../../components/Establishments';
// Modules
import { establishmentsSelector, fetchEstablishments } from '../../module';

class EstablishmentsContainer extends React.Component {
  componentDidMount() {
    this.props.fetchEstablishments();
  }
  render() {
    const { establishments, isFetching } = this.props;

    return <Establishments data={establishments} isFetching={isFetching} />;
  }
}

const mapStateToProps = state => ({
  establishments: establishmentsSelector.getAll(state),
  isFetching: establishmentsSelector.isFetching(state)
});

const mapDispatchToProps = {
  fetchEstablishments
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstablishmentsContainer);
