import React from 'react';
import * as PropTypes from 'prop-types';
import GoogleLogin from 'react-google-authorize';
import { GOOGLE_CLIENT_ID } from 'constants/config';

function GLogin({
  autoLoad,
  scope,
  responseType,
  prompt,
  style,
  render,
  onSuccess,
  onFailure
}) {
  return (
    <GoogleLogin
      autoLoad={autoLoad}
      clientId={GOOGLE_CLIENT_ID}
      onSuccess={onSuccess}
      onFailure={onFailure}
      responseType={responseType}
      scope={scope}
      prompt={prompt}
      style={style}
      render={render}
      cookiePolicy="single_host_origin"
    />
  );
}

GLogin.defaultProps = {
  autoLoad: false,
  onSuccess: null,
  onFailure: null,
  scope: '',
  responseType: 'code',
  prompt: 'consent',
  style: { color: '#0063c0', cursor: 'pointer' }
};

GLogin.propTypes = {
  autoLoad: PropTypes.bool,
  onFailure: PropTypes.func,
  scope: PropTypes.string.isRequired,
  responseType: PropTypes.string,
  prompt: PropTypes.string,
  style: PropTypes.object
};

export default GLogin;
