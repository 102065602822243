import React, { useMemo } from 'react';
import { CellSmall, Wrapper } from './styles';

const List = ({ title, data, renderActions, children }) => {
  const hasData = useMemo(() => {
    return data.length > 0;
  }, [data]);

  if (!hasData && !children) return null;

  return (
    <Wrapper>
      <header>{title}</header>
      <table>
        <tbody>
          {children}
          {data.map((item) => (
            <tr key={item.id}>
              <td>
                {item.file_url ? <img src={item.file_url} alt="" /> : item.name}
                {item?.portal_model?.id ? (
                  <p className="h-color--primary-light">
                    Padrão: {item?.portal_model?.name}
                  </p>
                ) : null}
              </td>
              <CellSmall>{renderActions(item)}</CellSmall>
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default List;
