import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-tooltip';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
// Components
import { Input, SelectMultiple } from 'components/Form';
import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
// Services
// Containers
import FieldSelectUser from 'containers/FieldSelectUser';
// Modules
import { addGroup } from 'modules/peopleGroups';
import {
  addReceptionSource,
  getReceptionSources,
  receptionSourcesSelector,
} from 'modules/receptionSources';
// Helpers
import { parseSelect } from 'lib/formHelpers';

import validate from './validate';
import Can from 'containers/Can';
import { PERSON_PERMISSIONS, USER_PERMISSIONS } from 'constants/rules';
import {
  BoxLeft,
  BoxRight,
  Form,
  SeeFullForm,
  Wrapper,
  WrapSeeForm,
} from './styles';
import FieldPhone from 'containers/FieldPhone';

const PersonForm = ({
  personId,
  handleSubmit,
  handleClose,
  submitting,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const peopleGroups = useSelector((state) => state.peopleGroups.groups);
  const receptionSources = useSelector(receptionSourcesSelector);
  const selector = formValueSelector('personFormSimple');
  const selectedGroupId = useSelector((state) => selector(state, 'groups_id'));

  useEffect(() => {
    dispatch(getReceptionSources());
    Tooltip.rebuild();
  }, [dispatch]);

  const isEditting = !!personId;

  const canEdit = useCallback(() => {
    const hasPermissions = personId && personId.permissions;
    return hasPermissions
      ? personId.permissions.includes(PERSON_PERMISSIONS.EDIT)
      : true;
  }, [personId]);

  const handleNewType = (option) => {
    dispatch(addGroup(option.name)).then((newOption) => {
      const value = selectedGroupId || [];
      dispatch(
        change('personFormSimple', 'groups_id', [...value, newOption.id])
      );
    });
  };

  const handleNewResource = (option) => {
    dispatch(addReceptionSource(option.name)).then((newOption) => {
      dispatch(change('personFormSimple', 'reception_source_id', newOption.id));
    });
  };

  return !canEdit() ? (
    <ModalTemplate
      title="Sem permissão para editar esse cliente"
      handleClose={handleClose}
    />
  ) : (
    <ModalTemplate
      title={isEditting ? 'Editar Cliente' : 'Cadastrar Cliente'}
      isLoading={false}
      handleClose={handleClose}
    >
      <Form>
        <Wrapper>
          <BoxLeft>
            <Row>
              <Field
                xs={12}
                required
                name="name"
                label="Nome"
                component={Input}
                tabIndex={1}
              />
              <FieldPhone
                xs={12}
                name="cellphone_number"
                nameDDI="cellphone_ddi"
                nameISO2="cellphone_iso2"
                label="Telefone"
                telInputProps={{
                  tabIndex: 2,
                }}
              />
              <Field
                xs={12}
                type="email"
                name="email"
                label="E-mail"
                component={Input}
                tabIndex={3}
              />
            </Row>
          </BoxLeft>
          <BoxRight>
            <Row>
              <Can
                run={USER_PERMISSIONS.EDIT_RECEPTION_SOURCES}
                yes={() => (
                  <Field
                    required
                    xs={12}
                    creatable
                    multi={false}
                    autoFocus
                    name="reception_source_id"
                    label="Origem da captação"
                    options={receptionSources}
                    component={SelectMultiple}
                    onNewOptionClick={handleNewResource}
                    valueKey="id"
                    labelKey="name"
                    parse={parseSelect('id')}
                    tabIndex={4}
                  />
                )}
                no={() => (
                  <Field
                    required
                    xs={12}
                    multi={false}
                    autoFocus
                    name="reception_source_id"
                    label="Origem da captação"
                    options={receptionSources}
                    component={SelectMultiple}
                    valueKey="id"
                    labelKey="name"
                    parse={parseSelect('id')}
                    tabIndex={4}
                  />
                )}
              />
              <Can
                run={USER_PERMISSIONS.EDIT_PEOPLE_GROUPS}
                yes={() => (
                  <Field
                    xs={12}
                    required
                    autoFocus
                    creatable
                    name="groups_id"
                    label="Categoria"
                    options={peopleGroups}
                    component={SelectMultiple}
                    onNewOptionClick={handleNewType}
                    valueKey="id"
                    labelKey="name"
                    parse={parseSelect('id')}
                    tabIndex={5}
                  />
                )}
                no={() => (
                  <Field
                    xs={12}
                    required
                    autoFocus
                    name="groups_id"
                    label="Categoria"
                    options={peopleGroups}
                    component={SelectMultiple}
                    valueKey="id"
                    labelKey="name"
                    parse={parseSelect('id')}
                    tabIndex={5}
                  />
                )}
              />
              <FieldSelectUser
                xs={12}
                label="Corretor responsável"
                tabIndex={6}
              />
            </Row>
          </BoxRight>
        </Wrapper>
        <WrapSeeForm>
          <SeeFullForm
            type="button"
            onClick={handleSubmit((values) =>
              onSubmit({ ...values, isFullForm: true })
            )}
          >
            Ver cadastro completo
          </SeeFullForm>
        </WrapSeeForm>
        <ModalFooter>
          <Button color="white" colorText="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button
            tabIndex={6}
            onClick={handleSubmit((values) =>
              onSubmit({ ...values, type_form: 'simple' })
            )}
            type="button"
            color="success"
            disabled={submitting}
          >
            {isEditting ? 'Editar' : 'Cadastrar'}
          </Button>
        </ModalFooter>
      </Form>
    </ModalTemplate>
  );
};

PersonForm.propTypes = {
  personId: PropTypes.object,
  groups_id: PropTypes.any,
  stateId: PropTypes.string,
  cityId: PropTypes.string,
  config: PropTypes.shape({
    checkGroupOwner: PropTypes.bool,
  }),
  typePerson: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const selector = formValueSelector('personForm');

const mapStateToProps = (state, ownProps) => {
  return {
    groups_id: selector(state, 'groups_id'),
    typePerson: selector(state, 'type'),
    receptionSourceId: selector(state, 'reception_source_id'),
    peopleGroups: state.peopleGroups.groups,
    realtors: state.realtors.realtors,
    initialValues: {
      ...state.modalPersonAdd.formData,
      ...ownProps.person,
      groups_id: state?.modalPersonAdd?.formData?.groups?.map(
        (group) => group.id
      ),
    },
    receptionSources: receptionSourcesSelector(state),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'personFormSimple',
    validate,
    enableReinitialize: true,
  })(PersonForm)
);
