import Alert from 'components/Alert';
import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import _ from 'lodash';
import ListPortals from 'pages/PropertyStore/pages/Publish/components/ListPortals';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
// import { Wrapper } from './styles';

// const getFieldByIndex = (fields, index) => {
//   try {
//     return fields[index];
//   } catch {
//     return null;
//   }
// };

const propertyHasDangerPrice = (property, zap) => {
  let errors = [];

  if (!property.is_price_shown) {
    errors = [
      ...errors,
      'O imóvel está marcado para não mostrar preço no site',
    ];
  }

  if (property.is_season_available && zap) {
    errors = [...errors, 'Zap Imóveis não aceita imóveis de temporada'];
  }

  return errors;
};

function Form({
  property,
  is_published,
  fieldOptions,
  portals,
  change,
  handleSubmit,
  handleClose,
  ...props
}) {
  const zap = useMemo(() => {
    return _.find(portals, ({ id, portal }) => {
      try {
        if (portal.name === 'Zap Imóveis') {
          return fieldOptions[id].is_checked;
        }

        return false;
      } catch {
        return false;
      }
    });
  }, [fieldOptions, portals]);

  const dangers = useMemo(() => {
    return propertyHasDangerPrice(property, zap);
  }, [property, portals, zap]);

  if (!property.is_published) {
    return (
      <Alert color="danger">
        Este imóvel esta cadastrado como privado, pra não ser publicado em seu
        site.
        <br /> Imóveis privados não podem ser encaminhados para portais
        imobiliários
      </Alert>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Alert color="secondary" className="h-margin-bottom--15">
        Deseja integrar com um portal que não está listado abaixo?{' '}
        <Link
          to="/config/portals/show"
          target="_blank"
          className="h-color--secondary h-text-underline"
        >
          Clique aqui
        </Link>{' '}
        para ver mais.
      </Alert>

      {dangers.length > 0 && (
        <Alert color="danger" className="h-margin-bottom--15">
          {dangers.map((msg, index) => (
            <div key={`msg-error-${index}`}>{msg}</div>
          ))}
        </Alert>
      )}

      <ListPortals showGuru={false} showDisconnected={false} />
      <ModalFooter>
        <Button
          type="button"
          color="white"
          colorText="secondary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button
          key="submit"
          type="submit"
          color="success"
          disabled={props.submitting}
        >
          Publicar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default compose(
  reduxForm({
    form: 'PropertyPublicationOrulo',
    enableReinitialize: true,
  })
)(Form);
