import styled from 'styled-components';
import { BoxTable } from '../../../Box/styles';

export const TableCustom = styled(BoxTable)`
  td:first-child svg {
    width: 30px;
    height: 15px;
    vertical-align: center;
  }
`;
