// Widgets.js
import Alert from 'react-s-alert';
import * as watermarkService from '../services/watermark';
import { createModule } from '../lib/reducer-helpers';

const createAction = createModule('watermark');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const CHANGE_SIZE = createAction('CHANGE_SIZE');
const CHANGE_POSITION = createAction('CHANGE_POSITION');
const IMAGE_REMOVED = createAction('IMAGE_REMOVED');
const START_JOBS = createAction('START_JOBS');

export const imageSelector = state => state.watermark.image;
export const isFetchingSelector = state => state.watermark.meta.isFetching;
export const isStartedJobsSelector = state =>
  state.watermark.meta.isStartedJobs;

const initialState = {
  image: {
    id: null,
    file_url: null,
    opacity: 100,
    width: 50,
    position: ''
  },
  meta: {
    isFetching: false,
    isStartedJobs: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        meta: { ...state.meta, isFetching: true }
      };
    case RECEIVE:
      return {
        ...state,
        image: { ...state.image, ...action.payload },
        meta: { ...state.meta, isFetching: false }
      };
    case CHANGE_POSITION:
    case CHANGE_SIZE:
      return {
        ...state,
        image: {
          ...state.image,
          ...action
        }
      };
    case IMAGE_REMOVED:
      return initialState;
    case START_JOBS: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isStartedJobs: true
        }
      };
    }
    default:
      return state;
  }
}

// Action Creators
export const requestWatermark = () => ({ type: REQUEST });
export const receiveWatermark = payload => ({ type: RECEIVE, payload });
export const changeSize = ({ size }) => ({ type: CHANGE_SIZE, size });
export const changePosition = ({ position }) => ({
  type: CHANGE_POSITION,
  position
});
export const startJobs = () => ({ type: START_JOBS });
export const imageRemoved = () => ({ type: IMAGE_REMOVED });

/**
 * Lida com a alteração da posição da imagem
 * @param position
 * @return {function(*)}
 */
export const handleChangePosition = position => dispatch => {
  dispatch(changePosition(position));
};

/**
 * Lida com a alteração do tamanho da imagem
 * @param size
 * @return {function(*)}
 */
export const getWatermark = () => dispatch => {
  dispatch(imageRemoved());
  dispatch(requestWatermark());

  return watermarkService.getOne().then(({ data }) => {
    dispatch(receiveWatermark(data));
    return data;
  });
};

export function handleRemoveWatermark() {
  return dispatch => {
    watermarkService.remove().then(({ data }) => {
      if (data.with_jobs) {
        dispatch(startJobs());
      } else {
        dispatch(imageRemoved());
        Alert.success('Removido');
      }
    });
  };
}

export function handleChangeSize(size) {
  return dispatch => {
    dispatch(changeSize(size));
  };
}

export function handleFormSubmit(values) {
  return dispatch => {
    dispatch(requestWatermark());

    return watermarkService
      .create({ image: values.image })
      .then(({ data }) => {
        dispatch(receiveWatermark(data));
      })
      .catch(() => {
        dispatch(imageRemoved());
      });
  };
}

export function handleFormUpdate(values) {
  return dispatch => watermarkService.update(values);
}
