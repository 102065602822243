import React from 'react';
import card from './card-nodata.svg';

// import { Wrapper } from './styles';

function Nodata() {
  return (
    <div className="h-flex h-flex--center-center">
      <img src={card} alt="Cartão" className="h-margin-right--5" /> Nenhum
      cartão cadastrado
    </div>
  );
}

export default Nodata;
