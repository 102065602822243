import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px 20px 0;

  :last-of-type {
    padding-bottom: 20px;
  }
`;

export const Title = styled.h5`
  margin-bottom: 10px;
`;

export const Content = styled.div``;
