import styled from 'styled-components';
import { FieldDateRange } from 'components/Form';

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  .FormControl,
  .Button {
    height: 30px;
    line-height: 30px;
  }

  .GroupControl__item--divider {
    line-height: 30px;
    height: 30px;
  }

  .ButtonGroup__action {
    height: 30px;
    line-height: 26px;
  }
`;

export const CustomFieldDateRange = styled(FieldDateRange)`
  > div {
    display: flex;
  }

  .GroupControl {
    margin-left: 10px;
  }

  .FormControl {
    border: 1px solid #d3dceb !important;
    border-radius: 4px !important;

    :focus {
      border: 1px solid ${(p) => p.theme.colors.borderColor} !important;
    }

    input {
      width: 100%;
    }
  }

  .GroupControl__item:not(.GroupControl__item--divider) {
    flex: 1 0 !important;
    max-width: 105px;
  }

  .FormGroup {
    margin: 0 !important;
    min-width: auto !important;
    width: 100%;
  }
`;
