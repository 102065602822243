import React from 'react';
import Button from 'components/Button';
import { Number, Text, Wrapper } from './styles';
import { useRequest } from 'hooks/useRequest';
import api from 'services';
import TextLoading from 'components/TextLoading';
import { plural } from 'lib/text';

function PropertiesWithClients() {
  const { isFetching, data } = useRequest({
    request: () => api.get('properties/dashboard/count-new-matcheds'),
  });

  const total = data.total;

  if (!isFetching && total === 0) return null;

  return (
    <Wrapper to="/crm/matched-people">
      <Number>
        {isFetching ? <TextLoading width={36} height={37} /> : total}
      </Number>
      <Text>
        {isFetching ? (
          <TextLoading width={120} />
        ) : (
          plural(
            total,
            'Imóvel com cliente compatível',
            'Imóveis com clientes compatíveis',
            false
          )
        )}
      </Text>
      <Button color="white" colorText="secondary">
        Ver
      </Button>
    </Wrapper>
  );
}

PropertiesWithClients.propTypes = {};
PropertiesWithClients.defaultProps = {};

export default PropertiesWithClients;
