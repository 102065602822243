import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';

import FormBox from '../../../components/FormBox';
import { Input, Select } from '../../../components/Form';

import GroupControl, { GroupItem } from '../../../components/GroupControl';

const defaultProps = {
  fields: [],
  isVisible: false
};
const propTypes = {
  fields: PropTypes.array,
  isVisible: PropTypes.bool
};

const Measures = ({ fields, isVisible }) => {
  return (
    isVisible && (
      <FormBox title="Medidas" className="js-measures">
        <Row>
          {fields.map(({ name, title, measures }) => (
            <Col key={name} xs={2}>
              <GroupControl label={title}>
                <GroupItem>
                  <Field
                    name={`areas.${name}.value`}
                    component={Input}
                    valueFormat
                    prefix=""
                  />
                </GroupItem>
                <GroupItem size="small">
                  <Field
                    name={`areas.${name}.measure`}
                    placeholder=""
                    component={Select}
                    options={measures.map(measure => ({
                      label: measure,
                      value: measure
                    }))}
                  />
                </GroupItem>
              </GroupControl>
            </Col>
          ))}
        </Row>
      </FormBox>
    )
  );
};

Measures.defaultProps = defaultProps;
Measures.propTypes = propTypes;

export default Measures;
