import React from 'react';
import UploadButton from 'components/UploadButton';
import Button from 'components/Button';
// import { Container } from './styles';

const Upload = ({ type, onDrop }) => {
  // const { open } = useContext(UploadImageContext);
  //
  // const aspectRatio = useMemo(() => {
  //   if (type === TYPES.WHATSAPP) {
  //     return 1;
  //   } else {
  //     return 270 / 140;
  //   }
  // }, [type]);
  //
  // const handleOpen = useCallback(() => {
  //   open({
  //     onSubmit: onDrop,
  //     cropperOptions: {
  //       aspectRatio,
  //     },
  //   });
  // }, [aspectRatio]);

  return (
    <UploadButton onDropAccepted={onDrop}>
      <Button color="primary">Enviar meu logo</Button>
    </UploadButton>
  );
};

export default Upload;
