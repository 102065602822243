import React from 'react';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';
import PlanItem from '../../../../components/PlanItem';
import { Infos } from '../../../../components/PlanItem/styles';

// import { Wrapper } from './styles';

function Downgrade({ data, setPage, handleClose, handleProgress }) {
  return (
    <ModalTemplate
      title="Alteração de contrato"
      text="Seu prazo final será estendido"
      handleClose={handleClose}
    >
      <p>
        Atualmente seu contrato terminará em <strong>{data?.end_at}</strong>,
        daqui <strong>{data?.end_in}</strong> dias.
      </p>
      <p>
        A alteração no recurso, mudou seu custo de{' '}
        <strong>{data.old_monthly_cost}</strong> para{' '}
        <strong>{data.new_monthly_cost}</strong> ao mês.
      </p>
      <p>
        O total deste novo recurso até seu prazo final, tem um custo de{' '}
        <strong>{data?.total_cost}</strong>
      </p>

      <Infos className="h-margin-top--15">
        <PlanItem
          label="Nova data final"
          text={
            <>
              Quantidade de dias que serão adicionados a sua data final
              <br />
              Sua nova data de crédito final irá mudar para
            </>
          }
          value={1}
          price={
            <>
              <br />
              {data?.change_days}
              <br /> {data?.new_end_at}
            </>
          }
        />
      </Infos>

      <ModalFooter>
        <Button
          type="button"
          color="white"
          onClick={() => {
            setPage('Details');
          }}
        >
          Voltar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="button" color="success" onClick={handleProgress}>
          Concluir
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

export default Downgrade;
