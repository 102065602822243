import api from 'services/index';
// Helpers
import { responseMessage } from 'lib/service-helpers';

const RESOURCE = 'settings/messages';

/**
 * Retorna os textos padrões do site/sistema
 * @param values
 * @returns {*|AxiosPromise<any>}
 */
export const getOne = values => api.getOne(RESOURCE, '', values);

/**
 * Atualiza os textos padrões
 * @param values
 * @param config
 * @returns {*|AxiosPromise<any>}
 */
export const update = (values, config) =>
  api
    .update(RESOURCE, values, config)
    .then(responseMessage('Textos atualizados!'))
    .catch(responseMessage('Erro ao atualizar os textos'));
