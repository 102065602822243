import { createValidation } from 'lib/validate-helpers';

export default values => {
  const errors = {};

  const validations = createValidation(values, errors);

  validations.required('url');

  return errors;
};
