import styled from 'styled-components';

export const Wrapper = styled.td`
  padding: 15px;
  text-align: left;

  div:first-child {
    margin-bottom: 5px;
  }

  .Label {
    white-space: nowrap;
  }
`;
