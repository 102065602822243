import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  background: #fff;
  margin-bottom: 20px;
`;

export const Left = styled.div`
  flex-shrink: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1c0c1e;
`;

export const Right = styled.div`
  display: flex;
  gap: 20px;

  .Button {
    margin: 0;
  }
`;
