import { combineReducers } from 'redux';
// Services
import * as propertiesService from 'services/properties';
import * as peopleService from 'services/people';
import * as dealsService from 'services/deals';
// Modules
import dataReducer from 'modules/data';

export const {
  actionCreators: actionsProperties,
  reducer: reducerProperties,
  selectors: selectorsProperties,
} = dataReducer('modalPersonProperties/properties');

export const request =
  (req, ...args) =>
  async (dispatch) => {
    // Marca que os imóveis estão sendo buscados
    dispatch(actionsProperties.request());

    const res = await req(...args);

    // Recebe os imóveis
    dispatch(actionsProperties.receive(res.data));

    return res;
  };

export const fetchMatched = (personId, params) => async (dispatch) =>
  request(peopleService.getPeopleMatched, personId, params)(dispatch);

export const fetchProperties = (params) => async (dispatch) =>
  request(propertiesService.getList, params)(dispatch);

const STATUS = {
  NEW: 1,
  SENDED: 2,
  DISCARDED: 3,
};

export const fetchPropertiesMatched = (personId, params) => (dispatch) =>
  fetchMatched(personId, {
    ...params,
    filter: { by_status: STATUS.NEW, by_property_status: 1 },
  })(dispatch);

export const fetchPropertiesSended = (personId, params) => (dispatch) =>
  fetchMatched(personId, {
    ...params,
    filter: { by_status: STATUS.SENDED },
    sort: '-created_at,id',
  })(dispatch);

export const fetchPropertiesDiscarded = (personId, params) => (dispatch) =>
  fetchMatched(personId, {
    ...params,
    filter: { by_status: STATUS.DISCARDED },
    include: 'discarted_by',
  })(dispatch);

export const fetchPropertiesPeople = (personId, params) => (dispatch) =>
  fetchProperties({
    ...params,
    filter: {
      people_id: personId,
      status: '1,2,3,4',
      without_draft_scope: true,
    },
    include: 'condominium',
    sort: 'status,-updated_at',
  })(dispatch);

export const fetchPropertiesInterested = (personId, params) => (dispatch) =>
  request(peopleService.getDeals, personId, { ...params, include: 'property' })(
    dispatch
  );

export const fetchPropertiesNegotiated = (personId, params) => (dispatch) =>
  request(dealsService.getDealsOfUser, {
    ...params,
    filter: { people_id: personId },
    include: 'property',
  })(dispatch);

export const discartMatchedProperty = (personId, matchedId) => (dispatch) => {
  dispatch(actionsProperties.removing(matchedId));

  return peopleService.discardMatched(personId, matchedId).then(({ data }) => {
    dispatch(actionsProperties.remove(matchedId));
  });
};

/**
 * Adiciona ou remove os imóveis ao CRM
 * @param matched
 * @param matched.id
 * @returns {function(): Promise<AxiosResponse<T>>}
 */
export const updateMatched = (matched) => () => {
  // Verifica se o imóvel ja está marcado como interessado
  // se sim tem que remover ele do funil tambem por isso endpoint separados
  if (matched.is_interested)
    return dealsService.discardFromInteresteds(matched.id);

  return peopleService.updateMatched({
    id: matched.id,
    is_interested: true,
  });
};

export default combineReducers({
  properties: reducerProperties,
});
