import Box from 'components/FilterBox';
import { Checkbox } from 'components/Form';
import { Field } from 'redux-form';
import { Wrapper } from './styles';

function PaymentStatus() {
  return (
    <Box title="Comissão">
      <Wrapper>
        <Field
          type="checkbox"
          label="Pagamento agendado"
          inputValue="scheduled"
          name="filter.payment_status"
          component={Checkbox}
        />
        <Field
          type="checkbox"
          label="Recebido"
          inputValue="paid"
          name="filter.payment_status"
          component={Checkbox}
        />
      </Wrapper>
    </Box>
  );
}

export default PaymentStatus;
