export default values => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (!values.name) {
    errors.name = REQUIRED_MSG;
  }

  if (!values.value) {
    errors.value = REQUIRED_MSG;
  }

  if (values.alert === '1') {
    if (!values.renew_date) {
      errors.renew_date = 'Campo obrigatório';
    }
  }

  return errors;
};
