import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const propTypes = {
  tagName: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['default', 'secondary', 'danger']),
  children: PropTypes.node
};

const defaultProps = {
  tagName: Link,
  color: 'default',
  children: null
};

const LinkComponent = ({
  children,
  className,
  tagName: Component,
  color,
  ...rest
}) => (
  <Component
    {...rest}
    className={classnames('FixedBar__link', className, {
      [`FixedBar__link--${color}`]: color
    })}
  >
    {children}
  </Component>
);

LinkComponent.propTypes = propTypes;
LinkComponent.defaultProps = defaultProps;

export default LinkComponent;
