import { combineReducers } from 'redux';
// Services
import * as realEstateService from 'services/realEstate';
// Modules
import dataReducer from 'modules/data';
import locationReducer, { thunksCreator } from 'modules/location';
import { setRealEstate } from 'modules/login';

const locationModuleName = 'config/companyData/location';

export const getLocation = (state) => state.config.companyData.location;
export const locationThunks = thunksCreator(locationModuleName);

export const {
  reducer: companyDataReducer,
  selectors: companyDataSelector,
  actionCreators: companyDataActions,
} = dataReducer(`site/companyData`);

// Thunks
export const fetchData = (params) => (dispatch) => {
  dispatch(companyDataActions.request());

  return realEstateService.getRealEstate(params).then(({ data }) => {
    dispatch(companyDataActions.receive(data));
    return data;
  });
};

/**
 * Lida com o evento de submit do formulário de dados da imobiliária
 * @param values - todos os valores do formulário
 * @return {function(*): Promise<any>}
 */
export const handleFormSubmit = (values) => (dispatch) => {
  return realEstateService
    .update(values)
    .then((res) => {
      dispatch(setRealEstate(res.data));
      dispatch(
        companyDataActions.receive({
          ...values,
          ...res.data,
          image: null,
        })
      );
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
//export default companyDataReducer;

export default combineReducers({
  data: companyDataReducer,
  location: locationReducer(locationModuleName),
});
