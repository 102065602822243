import React from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
  children: '',
  style: {},
  isVisible: false
};

const propTypes = {
  isVisible: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node
};

const FormError = ({ style, isVisible, children }) => {
  return (
    isVisible &&
    children && (
      <span style={style} className="FormError h-color--danger">
        {children}
      </span>
    )
  );
};

FormError.defaultProps = defaultProps;
FormError.propTypes = propTypes;

export default FormError;
