import React, { useEffect, useState } from 'react';
import SearchValue from '../SearchValue';
import { transformQuery } from '../../helpers';
import { Wrapper, SearchValueTitle } from './styles';
import qs from 'qs';
import { useHistory } from 'react-router';
import * as typesService from 'services/types';

function SearchItem({ canSearch, query, onClick }) {
  const history = useHistory();
  const values = transformQuery(query);

  const [typeName, setTypeName] = useState('');
                   
  const handleClick = () => {
    const filter = typeof query === 'string' ? query : qs.stringify(query);
    history.push(`/properties/search?${filter}`);
    if (onClick) onClick();
  };

  useEffect(() => {
    async function fetchTypes() {
      const res = await typesService.getGroupedTypesSubtypes();
      const typeOrSubtype = res.data.filter((item) => item.id === values.type_id);
      if (typeOrSubtype.length === 0) {
        setTypeName('Imóvel')
      } else {
        setTypeName(typeOrSubtype[0]?.title);
      }
    }
      fetchTypes();
  }, [query]);

  return (
    <Wrapper 
      transaction={values.transaction} 
      canSearch={canSearch} 
      onClick={handleClick}
    >
      <div>
      <SearchValueTitle>
        {`${typeName} ${values.transactionTitle}`}
      </SearchValueTitle>
      </div>
      <div>
        <SearchValue value={values.bedroom} />
        <SearchValue value={values.suite} />
        <SearchValue value={values.garage} />
        <SearchValue value={values.ground_total_area} />
      </div>
      <div>
        <SearchValue value={values.price_field} />
      </div>
    </Wrapper>
  );
}

export default SearchItem;
