import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';
import List from './components/List';
import { Wrapper, Input, Controller, SearchIcon, Loading, LoadingIcon, DigitsLeft } from './styles';
import { SearchBarContext } from 'components/SearchBar/context';
import { plural } from 'lib/text';

function SearchBar({ ref, className }) {
  const {
    data,
    canSearch,
    isVisible,
    isFetching,
    search,
    onChangeSearch,
    onFocusSearch,
    onBlurSearch
  } = useContext(SearchBarContext);

  const hasDigits = useMemo(() => {
    return !!canSearch?.digitsLeft && canSearch?.digitsLeft > 0;
  }, [canSearch]);

  return (
    <Wrapper className={classnames(className, 'js-search')}>
      <Controller>
        <SearchIcon />
        <Input
          ref={ref}
          value={search}
          onChange={onChangeSearch}
          onFocus={onFocusSearch}
          onBlur={onBlurSearch}
        />
        {hasDigits && (
          <DigitsLeft>
            {plural(
              canSearch?.digitsLeft,
              `Digite mais ${canSearch.digitsLeft} caractere`,
              `Digite mais ${canSearch.digitsLeft} caracteres`,
              false
            )}
          </DigitsLeft>
        )}
        <Loading isFetching={isFetching}>
          Pesquisando
          <LoadingIcon />
        </Loading>
      </Controller>
      <List
        data={data}
        //isOpen
        isOpen={isVisible}
      />
    </Wrapper>
  );
}

export default SearchBar;
