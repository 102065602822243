import styled from 'styled-components';
import { Wrapper as PropertyItem } from 'components/PropertyItem/styles';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
`;

export const WrapperProperties = styled.div`
  background-color: #fff;
  flex-shrink: 0;
  min-width: 0;
  overflow: auto;
  user-select: none;

  cursor: ${p => (p.isDragging ? 'grabbing' : 'grab')} !important;

  ${PropertyItem} {
    cursor: ${p => (p.isDragging ? 'grabbing' : 'grab')} !important;
  }
`;

export const WrapperMap = styled.div`
  height: inherit;
  flex-grow: 1;
  position: relative;
`;

export const MapActions = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99999999999;
  margin: 15px;
  display: flex;
`;

export const ButtonCircle = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;

  font-size: 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 15px;
  cursor: pointer;
`;

export const ButtonWhatsapp = styled(ButtonCircle)`
  background-color: #25d366;
`;

export const ButtonGoogle = styled(ButtonCircle)`
  background-color: #fff;
`;
