import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
// Components
import ListOptions from 'components/ListOptions';
import Button from 'components/Button';
// Context
import RdStationProvider, { RdStationContext } from './context';
// HOCs
import withProvider from 'HOC/withProvider';
// Modules
import { openModalRd, openModalRdConfig } from 'modules/modal';
import { removeAuth, TYPES_AUTH } from 'modules/authentications';
// Services
import * as crmIntegration from 'services/settings/crm-integration';
// Assets
import rdStationLogo from './rd-station.svg';
import { openConfirmation } from 'containers/ModalConfirmation/module';

function RdStationIntegration() {
  const dispatch = useDispatch();
  const { hasIntegration } = useContext(RdStationContext);

  const connect = useCallback(() => {
    dispatch(openModalRd());
  }, [dispatch]);

  const disconnect = useCallback(async () => {
    dispatch(
      openConfirmation({
        title: 'Desconectar RD Station',
        text: 'Você realmente deseja desconectar o RD Station?',
        request: crmIntegration.disconnect,
        submitButtonText: 'Desconectar',
        cancelButtonText: 'Cancelar',
        onSuccess: () => {
          dispatch(removeAuth(TYPES_AUTH.RD_STATION));
        }
      })
    );
  }, [dispatch]);

  const renderOptions = useMemo(
    () => () => {
      if (hasIntegration)
        return (
          <>
            <Button
              color="white"
              className="h-margin-right--10"
              onClick={() => {
                dispatch(openModalRdConfig());
              }}
            >
              Configurar
            </Button>
            <Button color="danger" onClick={disconnect}>
              Desconectar
            </Button>
          </>
        );

      return (
        <Button onClick={connect} color="success">
          Conectar
        </Button>
      );
    },
    [hasIntegration, connect, disconnect]
  );

  return (
    <ListOptions.item
      image={rdStationLogo}
      imageStyle={{
        width: 42
      }}
      title="Rd Station"
      text="Conecte seu sistema ao RD Station e não perca mais seus leads de vista."
      renderOptions={renderOptions}
    />
  );
}

export default withProvider(RdStationProvider)(RdStationIntegration);
