import React, { createContext, useState, useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import * as searchbar from 'services/searchbar';

const requestByKey = {
  people: searchbar.searchByPeople,
  properties: searchbar.searchByProperties,
  condos: searchbar.searchByCondos
};

export const SearchBarContext = createContext({
  hasData: null,
  search: null,
  isFetching: null,
  setIsFetching: null,
  data: null,
  setData: null,
  isVisible: null,
  onChangeSearch: null,
  onFocusSearch: null,
  onBlurSearch: null,
  isHoverList: null,
  setHoverList: null,
  close: null,
  loadMoreByKey: null,
  canSearch: null,
  reset: null,
  isTyping: null
});

const initialData = {
  people: {
    data: [],
    meta: {}
  },
  condos: {
    data: [],
    meta: {}
  },
  properties: {
    data: [],
    meta: {}
  }
};

export const canSearch = search => {
  let total = search.length;
  let isnum = /^\d+$/.test(search);

  if (total === 0) {
    return {
      isNumber: null,
      can: null,
      digitsLeft: null
    };
  }

  if (isnum) {
    return {
      isNumber: true,
      can: total >= 3,
      digitsLeft: 3 - total
    };
  }

  return {
    isNumber: false,
    can: total >= 4,
    digitsLeft: 4 - total
  };
};

export default function SearchBarProvider({ children }) {
  const [isTyping, setIsTyping] = useState(false);
  const [search, setSearch] = useState('');
  const [isFocus, setFocus] = useState(false);
  const [data, setData] = useState(initialData);
  const [isFetching, setIsFetching] = useState(false);
  const [isHoverList, setHoverList] = useState(false);

  const searchAll = useCallback(
    _.debounce(async query => {
      setIsFetching(true);
      searchbar.searchAll(query).then(({ properties, people, condos }) => {
        setData({ people, condos, properties });
        setIsFetching(false);
        setIsTyping(false);
      });
    }, 1000),
    [setData, setIsFetching]
  );

  const onChangeSearch = useCallback(
    e => {
      const { can } = canSearch(e.target.value);
      setIsTyping(true);
      setSearch(e.target.value);

      if (can) {
        searchAll(e.target.value);
      } else {
        setIsTyping(false);
      }
    },
    [setIsTyping]
  );
  const onFocusSearch = useCallback(() => {
    setFocus(true);
  }, []);

  const onBlurSearch = useCallback(() => {
    setFocus(false);
  }, []);

  const loadMoreByKey = useCallback(
    async key => {
      const pagination = data[key]?.meta?.pagination;
      const hasMore = pagination?.current_page < pagination?.total_pages;

      if (hasMore) {
        const { data: currentData, meta } = await requestByKey[key](search, {
          limit: pagination.per_page,
          offset: pagination.current_page + 1
        });

        setData(prevData => ({
          ...prevData,
          [key]: {
            data: [...prevData[key].data, ...currentData],
            meta
          }
        }));
      }
    },
    [data]
  );

  const allResults = useMemo(() => {
    const keys = ['properties', 'people', 'condos'];

    return keys.reduce((acc, key) => {
      acc = acc + data?.[key]?.meta?.pagination?.total || 0;
      return acc;
    }, 0);
  }, [data]);

  const _canSearch = useMemo(() => {
    return canSearch(search);
  }, [search]);

  const hasData = useMemo(() => {
    return !!allResults;
  }, [allResults]);

  const close = useCallback(() => {
    setHoverList(false);
    setFocus(false);
  }, []);

  const reset = useCallback(() => {
    setSearch('');
  }, []);

  const isVisible = useMemo(() => {
    if (!_canSearch.can || isTyping) return false;

    return isFocus || isHoverList;
  }, [isFocus, isHoverList, isTyping, _canSearch]);

  useEffect(() => {
    if (search.length < 3) {
      setData(initialData);
    }
  }, [search]);

  return (
    <SearchBarContext.Provider
      value={{
        search,
        isFetching,
        setIsFetching,
        data,
        setData,
        isVisible,
        onChangeSearch,
        onFocusSearch,
        onBlurSearch,
        isHoverList,
        setHoverList,
        close,
        loadMoreByKey,
        hasData,
        allResults,
        isTyping,
        reset,
        canSearch: _canSearch
      }}
    >
      {children}
    </SearchBarContext.Provider>
  );
}
