import imageCrm from 'assets/img/crm/img-crm.png';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import { useEffect, useState } from 'react';
import Lightbox from 'react-images';
import { connect } from 'react-redux';
import { crmConfigSelector, fetchData, handleSubmit } from 'modules/configCrm';
import {
  getRealtors,
  isFetchingRealtorsSelector,
  realtorsSelector,
} from 'modules/realtors';
import Loading from 'components/Loading';
import ConfigCrmForm from './components/ConfigCrmForm';

const propTypes = {};
const defaultProps = {};

const ConfigCrmDwv = ({
  isFetching,
  realtors,
  isFetchingRealtor,
  data,
  fetchData,
  getRealtors,
  handleSubmit,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchData();
    getRealtors();
  }, [fetchData, getRealtors]);

  const handleClose = () => setIsOpen(false);

  return (
    <Wrapper.container>
      <Lightbox
        width={1920}
        images={[{ src: imageCrm }]}
        isOpen={isOpen}
        onClose={handleClose}
      />
      <MainTitle
        title="Site - Rodízio de leads (Rede DWV)"
        text="Defina como distribuir os contatos recebidos."
      />
      <Loading isFullComponent isVisible={isFetching} />

      <ConfigCrmForm
        realtors={realtors}
        isFetchingRealtor={isFetchingRealtor}
        initialValues={data}
        isFetching={isFetching}
        onSubmit={handleSubmit}
        onSubmitSuccess={() => {
          fetchData();
        }}
      />
    </Wrapper.container>
  );
};

ConfigCrmDwv.propTypes = propTypes;
ConfigCrmDwv.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  data: crmConfigSelector.getAll(state),
  isFetching: crmConfigSelector.isFetching(state),
  realtors: realtorsSelector(state),
  isFetchingRealtor: isFetchingRealtorsSelector(state),
});

const mapDispatchToProps = {
  fetchData,
  handleSubmit,
  getRealtors,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigCrmDwv);
