import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  margin: -30px -20px -20px -19px;
  max-height: ${(p) => p.theme.maxHeight};
  min-height: ${(p) => p.theme.minHeight};
  overflow: auto;
`;

export const DateWrapper = styled.div`
  :not(:first-child) {
    border-top: 1px solid #dedede;
  }
`;

export const Icon = styled(motion.div)`
  display: inline-block;
  margin-right: 5px;
  margin-top: -5px;
`;

export const Title = styled.div`
  font-size: ${(p) => p.theme.titleSize};
  font-weight: 600;
  padding: 15px;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;

  :hover {
    background: #f1f1f1;
  }

  svg {
    font-size: ${(p) => p.theme.iconSize};
    line-height: ${(p) => p.theme.titleSize};
    margin: -3px;
  }
`;

export const Diff = styled.span`
  font-size: 14px;
  color: #adb7c5;
  margin-left: 15px;
`;
