import { useEffect, useState } from 'react';
import { Forms } from '../Forms';
import { getProperty } from 'containers/ModalRoot/ModalPropertyDuplicateMulti/helpers/getProperty';

export const useForm = ({ property }) => {
  const [Form, setForm] = useState(null);
  const [initialValues, setInitialValues] = useState({});

  const initialize = async () => {
    const _property = await getProperty(property);

    setInitialValues({
      properties: [_property],
    });

    switch (property?.type?.title) {
      case 'Apartamento':
        setForm(Forms.FormApartment);
        return;
      case 'Casa':
        setForm(Forms.FormHouse);
        return;
      default:
        setForm(Forms.FormOthers);
        return;
    }
  };

  useEffect(() => {
    if (property) {
      initialize();
    }

    return () => {
      setForm(null);
      setInitialValues({});
    };
  }, [property]);

  return { Form, setForm, initialValues };
};
