import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
import { MdInfo } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
// Modules
import { indexesSelector } from 'modules/financialIndex';
// Components
import FormBox from 'components/FormBox';
import { FieldBool, Input, Select } from 'components/Form';
import GroupControl, { GroupItem } from 'components/GroupControl';
import ResultValue from '../components/ResultValue';
// Constants
import { TYPE_TRANSACTION } from 'constants/options';

const defaultProps = {
  indexes: [],
};
const propTypes = {
  indexes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

class TitlePrice extends React.Component {
  render() {
    return (
      <>
        Preço do Imóvel
        <span
          data-html
          className="h-color--secondary h-margin-left--5"
          data-tip="Você pode cadastrar novos <br /> índices financeiros na área<br /> Ajustes > Índices financeiros."
          ref={(el) => (this.linkElement = el)}
          onClick={() => ReactTooltip.hide(this.linkElement.current)}
        >
          <MdInfo />
        </span>
      </>
    );
  }
}

const isRealIndex = (id, indexes) =>
  indexes.find((index) => id === index.id && index.name === 'Real');

function Price({
  price,
  transaction,
  territorial_tax_price,
  condominium_price,
  taxes_price,
  price_financial_index_id,
  territorial_tax_financial_index_id,
  condominium_financial_index_id,
  taxes_financial_index_id,
  change,
  indexes,
  is_exchangeable,
  is_price_shown,
  has_finance,
  updateMetaTitle,
}) {
  const isRent = useMemo(
    () => transaction === '2' || transaction === 2,
    [transaction]
  );

  const [sizes, setSizes] = useState({
    territorial_tax_price: 2,
    territorial_tax_type: 2,
    condominium_price: 2,
  });

  useEffect(() => {
    if (isRent) {
      setSizes({
        territorial_tax_price: 3,
        territorial_tax_type: 3,
        condominium_price: 3,
      });
    } else {
      setSizes({
        territorial_tax_price: 2,
        territorial_tax_type: 2,
        condominium_price: 2,
      });
    }
  }, [isRent]);

  return (
    <FormBox title="Preços" className="js-price">
      <Row className="no-transition">
        <Field
          xs={3}
          label="Tipo do negócio"
          name="transaction"
          component={Select}
          options={TYPE_TRANSACTION}
          placeholder=""
          onChange={() => {
            change('seasonal_availability', null);
          }}
          onBlur={() => {
            updateMetaTitle();
          }}
        />
        <Col xs={3}>
          <GroupControl label={<TitlePrice />}>
            <GroupItem>
              <Field
                valueFormat
                prefix=""
                name="price"
                component={Input}
                inputProps={{
                  decimalScale: isRealIndex(price_financial_index_id, indexes)
                    ? 2
                    : 4,
                }}
              />
            </GroupItem>
            <GroupItem size="small">
              <Field
                autoWidth
                name="price_financial_index_id"
                component={Select}
                valueKey="id"
                labelKey="symbol"
                options={indexes}
                placeholder=""
              />
            </GroupItem>
          </GroupControl>
          <ResultValue
            financialIndex={price_financial_index_id}
            indexes={indexes}
            price={price}
          />
        </Col>

        <FieldBool
          canRemoveSelf={false}
          xs={3}
          label="Mostrar valor no site?"
          name="is_price_shown"
        />

        <Field
          disabled={is_price_shown}
          xs={3}
          label="Mostrar no lugar do preço:"
          name="price_alternative_text"
          component={Input}
        />

        <Col xs={sizes.territorial_tax_price}>
          <GroupControl label="Valor do IPTU">
            <GroupItem>
              <Field
                valueFormat
                prefix=""
                name="territorial_tax_price"
                component={Input}
                placeholder="Digite o valor do iptu"
                inputProps={{
                  decimalScale: isRealIndex(
                    territorial_tax_financial_index_id,
                    indexes
                  )
                    ? 2
                    : 4,
                }}
              />
            </GroupItem>
            <GroupItem size="small">
              <Field
                autoWidth
                name="territorial_tax_financial_index_id"
                component={Select}
                valueKey="id"
                labelKey="symbol"
                options={indexes}
                placeholder=""
              />
            </GroupItem>
          </GroupControl>
          <ResultValue
            financialIndex={territorial_tax_financial_index_id}
            indexes={indexes}
            price={territorial_tax_price}
          />
        </Col>
        <Col xs={sizes.territorial_tax_type}>
          <FieldBool
            label="Período"
            name="territorial_tax_type"
            canRemoveSelf
            options={[
              { label: 'Anual', value: 1 },
              { label: 'Mensal', value: 2 },
            ]}
          />
        </Col>
        <Col xs={sizes.condominium_price}>
          <GroupControl label="Valor Condomínio">
            <GroupItem>
              <Field
                valueFormat
                prefix=""
                name="condominium_price"
                inputProps={{
                  decimalScale: isRealIndex(
                    condominium_financial_index_id,
                    indexes
                  )
                    ? 2
                    : 4,
                }}
                component={Input}
              />
            </GroupItem>
            <GroupItem size="small">
              <Field
                autoWidth
                name="condominium_financial_index_id"
                component={Select}
                valueKey="id"
                labelKey="symbol"
                options={indexes}
                placeholder=""
              />
            </GroupItem>
          </GroupControl>
          <ResultValue
            financialIndex={condominium_financial_index_id}
            indexes={indexes}
            price={condominium_price}
          />
        </Col>
        {!isRent && (
          <>
            <FieldBool
              buttonTemplate
              xs={3}
              name="has_finance"
              label="Tem financiamento?"
              onChange={(e, value) => {
                if (value) {
                  change('is_financeable', true);
                }
              }}
            />
            <FieldBool
              buttonTemplate
              xs={3}
              name="is_financeable"
              label="Aceita financiamento?"
            />
          </>
        )}
      </Row>

      <Row>
        <Col xs={3}>
          <GroupControl label="Valor das Taxas">
            <GroupItem>
              <Field
                valueFormat
                prefix=""
                name="taxes_price"
                component={Input}
                inputProps={{
                  decimalScale: isRealIndex(taxes_financial_index_id, indexes)
                    ? 2
                    : 4,
                }}
              />
            </GroupItem>
            <GroupItem size="small">
              <Field
                autoWidth
                name="taxes_financial_index_id"
                component={Select}
                valueKey="id"
                labelKey="symbol"
                options={indexes}
                placeholder=""
              />
            </GroupItem>
          </GroupControl>
          <ResultValue
            financialIndex={taxes_financial_index_id}
            indexes={indexes}
            price={taxes_price}
          />
        </Col>
        <Field
          xs={9}
          label="Descrição das Taxas"
          name="taxes_description"
          placeholder="Descrição das taxas"
          component={Input}
        />
      </Row>

      {!isRent && (
        <Row>
          <FieldBool
            buttonTemplate
            xs={3}
            name="is_exchangeable"
            label="Aceita permuta?"
          />
          <Field
            disabled={!is_exchangeable}
            required={is_exchangeable}
            xs={6}
            label="Descrição das Permutas"
            name="exchange_note"
            placeholder="Descrição das Permutas"
            component={Input}
          />
          <FieldBool
            xs={3}
            label="Minha Casa Minha Vida?"
            name="is_financeable_mcmv"
          />
        </Row>
      )}
    </FormBox>
  );
}

Price.defaultProps = defaultProps;
Price.propTypes = propTypes;

const selector = formValueSelector('propertiesAdd');

const mapStateToProps = (state) => ({
  price_financial_index_id: selector(state, 'price_financial_index_id'),
  territorial_tax_financial_index_id: selector(
    state,
    'territorial_tax_financial_index_id'
  ),
  condominium_financial_index_id: selector(
    state,
    'condominium_financial_index_id'
  ),
  taxes_financial_index_id: selector(state, 'taxes_financial_index_id'),
  has_finance: selector(state, 'has_finance'),
  price: selector(state, 'price'),
  territorial_tax_price: selector(state, 'territorial_tax_price'),
  condominium_price: selector(state, 'condominium_price'),
  taxes_price: selector(state, 'taxes_price'),
  transaction: selector(state, 'transaction'),

  indexes: indexesSelector(state),
  is_exchangeable: selector(state, 'is_exchangeable'),
  is_price_shown: selector(state, 'is_price_shown'),
});

export default connect(mapStateToProps)(Price);
