import styled from 'styled-components';

export const Remove = styled.div.attrs(() => ({
  className: 'h-link',
}))`
  margin-bottom: 5px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .GroupControl__controls {
    align-items: center;
  }

  .GroupControl {
    ${Remove} {
      margin-left: 5px;
      margin-bottom: 0;
    }
  }
`;
