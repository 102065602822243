import React, { useCallback, useEffect } from 'react';
// Components
import BillingChart from './components/BillingChart';
import BillingTable from './components/BillingTable';
// Modules
// Constantes
import { useReport } from '../hooks';
import { getBillingPerBroker } from '../../../../services/reports';
import WrapperReport from '../../components/WrapperReport';
import moment from 'lib/moment';
import Form from 'pages/Reports/components/FormInterval';

export default function BillingPerBroker() {
  const {
    data,
    meta,
    chartData,
    isFetching,
    toggle,
    fetchData,
    toggleAll,
    isAllSelected,
    dataSelecteds,
  } = useReport({
    request: getBillingPerBroker,
  });

  const handleSubmit = useCallback(
    (values) => {
      fetchData(values);
    },
    [fetchData]
  );

  useEffect(() => {
    const startDate = moment()
      .subtract(5, 'month')
      .startOf('month')
      .format('DD/MM/YYYY');

    const endDate = moment().endOf('month').format('DD/MM/YYYY');

    fetchData({
      period: 'previousMonth',
      start_date: startDate,
      end_date: endDate,
    });
  }, []);

  return (
    <WrapperReport
      title="Faturamento por Corretor"
      text="Veja quanto cada corretor faturou em determinado período."
      data={data}
      isFetching={isFetching}
      renderForm={() => <Form onSubmit={handleSubmit} />}
      renderChart={() => (
        <BillingChart width={'100%'} height={250} data={chartData} />
      )}
      renderTable={() => (
        <BillingTable
          meta={meta}
          toggleAll={toggleAll}
          isAllSelected={isAllSelected}
          data={dataSelecteds}
          toggleChecked={toggle}
        />
      )}
    />
  );
}
