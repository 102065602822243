import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';
import {
  Content,
  Image,
  Social,
  SocialItem,
  Wrapper,
  WrapperImage,
} from './styles';
import useFormValue from 'hooks/useFormValue';
import Button from 'components/Button';
import {
  FaBlog,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTelegram,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { UploadImageContext } from 'components/ModalImage/context';
import { imageChangeSize } from 'lib/image-helpers';
import { useDispatch } from 'react-redux';
import useReduxForm from 'hooks/useReduxForm';

const SOCIAL = {
  facebook: { icon: FaFacebook, color: '#3B5999' },
  instagram: { icon: FaInstagram, color: '#E4405F' },
  twitter: { icon: FaTwitter, color: '#55ACEE' },
  youtube: { icon: FaYoutube, color: '#F44336' },
  linkedin: { icon: FaLinkedin, color: '#0077b5' },
  blog: { icon: FaBlog, color: '#f57d00' },
  telegram: { icon: FaTelegram, color: '#0088cc' },
};

const Preview = () => {
  const dispatch = useDispatch();
  const { change } = useReduxForm();
  const { open } = useContext(UploadImageContext);

  const {
    image,
    file_url,
    name,
    creci,
    phone,
    email,
    site_link,
    facebook_link,
    instagram_link,
    twitter_link,
    youtube_link,
    linkedin_link,
  } = useFormValue([
    'image',
    'file_url',
    'name',
    'creci',
    'phone',
    'email',
    'site_link',
    'facebook_link',
    'instagram_link',
    'twitter_link',
    'youtube_link',
    'linkedin_link',
  ]);

  const social = useMemo(() => {
    let list = [];

    if (facebook_link)
      list = [...list, { key: 'facebook', value: facebook_link }];

    if (instagram_link)
      list = [...list, { key: 'instagram', value: instagram_link }];

    if (twitter_link) list = [...list, { key: 'twitter', value: twitter_link }];

    if (youtube_link) list = [...list, { key: 'youtube', value: youtube_link }];

    if (linkedin_link)
      list = [...list, { key: 'linkedin', value: linkedin_link }];

    return list;
  }, [
    facebook_link,
    instagram_link,
    twitter_link,
    youtube_link,
    linkedin_link,
  ]);

  const imageUrl = useMemo(() => {
    return file_url || null;
  }, [image, file_url]);

  return (
    <Wrapper>
      <WrapperImage>
        <Image
          className={classnames({
            'no-image': !imageUrl,
          })}
          onClick={() => {
            open({
              cropperOptions: {
                aspectRatio: 1,
              },
              onSubmit: async ({ file, dataUrl }) => {
                dispatch(change('image', file));
                dispatch(change('file_url', dataUrl));
                return 1;
              },
            });
          }}
        >
          {!!imageUrl && (
            <img src={imageChangeSize(imageUrl, 'small', 'cover')} alt="" />
          )}
          <div>
            <span>
              <Button size="small" color="secondary">
                Enviar foto
              </Button>
              <p>143x143px</p>
            </span>
          </div>
        </Image>
        {/*<FieldImage*/}
        {/*  width={110}*/}
        {/*  height={110}*/}
        {/*  bgSize="cover"*/}
        {/*  name="image"*/}
        {/*  fileUrlField="file_url"*/}
        {/*  text="110x110"*/}
        {/*  renderButtonSend={() => (*/}
        {/*    <Button type="button" color="secondary" size="small">*/}
        {/*      Enviar foto*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*  renderButtonChangeImage={() => (*/}
        {/*    <Button*/}
        {/*      type="button"*/}
        {/*      color="white"*/}
        {/*      colorText={'secondary'}*/}
        {/*      size="small"*/}
        {/*    >*/}
        {/*      Trocar*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*/>*/}

        {social && (
          <Social>
            {social.map(({ value, key }) => {
              const Icon = SOCIAL[key].icon;
              const color = SOCIAL[key].color;

              return (
                <SocialItem color={color}>
                  <a href={value} rel="noreferrer" target="_blank">
                    <Icon />
                  </a>
                </SocialItem>
              );
            })}
          </Social>
        )}
      </WrapperImage>
      <Content>
        <h1>{name ?? 'Sem nome'}</h1>
        {creci ? <p>CRECI: {creci}</p> : <p>Sem creci</p>}
        {site_link ? <p>{site_link}</p> : <p>seu-site.com.br</p>}
        {email ? <p>{email}</p> : <p>seuemail@mail.com.br</p>}
        {!!phone && <p>{phone}</p>}
      </Content>
    </Wrapper>
  );
};

export default Preview;
