import React from 'react';
import { Field } from 'redux-form';
import { Input } from 'components/Form';

const getInputTypeByName = (name) => {
  switch (name) {
    case 'floor':
      return 'number';
    default:
      return 'text';
  }
};

const NOT_ALLOWED = [
  'apartment_number',
  'floor',
  'units_per_floor',
  'floors_number',
  'towers_number',
  'lot_description',
  'lot_number',
];

const FieldTypeFields = ({ fields }) => {
  // Verifica se tem fields
  if (!fields || fields.length <= 0) return false;

  return fields
    .filter(({ name }) => !NOT_ALLOWED.includes(name))
    .map(({ name, title, size }) => (
      <Field
        xs={3}
        type={getInputTypeByName(name)}
        key={name}
        lg={size}
        label={title}
        name={`informations.${name}.value`}
        component={Input}
      />
    ));
};

export default FieldTypeFields;
