import Container from 'components/Container';
import ListOptions from 'components/ListOptions';
import { FieldVideoUrl } from 'components/Form';
import { Row } from 'react-flexbox-grid';
import Button from 'components/Button';
import { MdDelete } from 'react-icons/md';
import useReduxForm from 'hooks/useReduxForm';

function Video() {
  const { dispatch, change } = useReduxForm();

  return (
    <section id="Video">
      <Container padding style={{ marginTop: '20px' }}>
        <ListOptions>
          <ListOptions.item
            title="Vídeo do imóvel"
            text="Adicione um vídeo deste imóvel para dar ainda mais informações para este cliente"
            renderOptions={() => (
              <Button
                color="danger"
                onClick={() => {
                  dispatch(change('video', null));
                }}
              >
                <MdDelete /> Remover vídeo
              </Button>
            )}
          >
            <Row className="h-margin-top--10">
              <FieldVideoUrl />
            </Row>
          </ListOptions.item>
        </ListOptions>
      </Container>
    </section>
  );
}

export default Video;
