import React from 'react';
// Containers
import { Field } from 'redux-form';
import { SelectMultiple } from 'components/Form';
import { parseSelect } from 'lib/formHelpers';
import { useRequest } from 'hooks/useRequest';
import { getList } from 'services/receptionSources';

export default function LeadOrigin({
  creatable = true,
  name = 'reception_source_id',
}) {
  const { data } = useRequest({
    request: getList,
  });

  return (
    <Field
      creatable={creatable}
      multi={false}
      autoFocus
      name={name}
      label="Origem da captação"
      options={data}
      component={SelectMultiple}
      valueKey="id"
      labelKey="name"
      parse={parseSelect('id')}
    />
  );
}
