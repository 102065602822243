import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  position: relative;
`;

const getPosition = ({ width, position }) => {
  switch (position) {
    case 'center':
      return css`
        left: 50%;
        margin-left: -${width / 2}px;
      `;
    case 'left':
      return css`
        left: 0;
      `;
    case 'right':
    default:
      return css`
        right: 0;
      `;
  }
};

export const Overlay = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: {
    opacity: 1
  },
  exit: { opacity: 0 }
}))`
  position: fixed;
  top: ${p => p.theme.headerHeight}px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 2000;
`;

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0, y: -5 },
  animate: {
    opacity: 1,
    y: 0
  },
  exit: { opacity: 0, y: 5 }
}))`
  position: absolute;
  top: 100%;
  ${getPosition};

  width: ${p => p.width}px;
  transform-origin: 50% 0;
  z-index: 2500;
`;
