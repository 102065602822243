import React from 'react';
import ListConnecteds from './components/ListConnecteds';
import ListDisconnecteds from './components/ListDisconnecteds';

const ListPortals = () => {
  return (
    <>
      <ListConnecteds />
      <ListDisconnecteds />
    </>
  );
};

export default ListPortals;
