import { combineReducers } from 'redux';
// Services
import * as dealsService from '../../services/deals';
// Modules
import dataReducer from '../../modules/data';
// Modules
import {
  MODULE_NAME as propertyRevisiton,
  propertyRevisionReducer
} from './components/PanelPropertyRevision/module';
import {
  MODULE_NAME as propertyExclusivity,
  propertyExclusivityExpiredReducer
} from './components/PanelPropertyExclusivityExpired/module';
import {
  MODULE_NAME as propertyRent,
  propertyRentExpiredReducer
} from './components/PanelPropertyRentExpired/module';
import {
  MODULE_NAME as propertyDeal,
  propertyDealReducer
} from './components/PanelPropertyDeal/module';
import {
  MODULE_NAME as propertyRecent,
  propertyRecentReducer
} from './components/PanelPropertyRecent/module';
import {
  MODULE_NAME as propertyFunnel,
  propertyFunnelReducer
} from './components/PanelPropertyFunnel/module';

// Selectors
export const funnelsSelector = state => state.propertyDashboard.funnels.data;

export const {
  reducer: funnelReducer,
  actionCreators: funnelActions
} = dataReducer(`propertyDashboard/funnel`);

// Thunks
export const fetchFunnels = params => dispatch => {
  dispatch(funnelActions.request());

  return dealsService.getFunnels(params).then(({ data }) => {
    dispatch(funnelActions.receive(data));
    return data;
  });
};

// Root reducer
export default combineReducers({
  [propertyRevisiton]: propertyRevisionReducer,
  [propertyExclusivity]: propertyExclusivityExpiredReducer,
  [propertyDeal]: propertyDealReducer,
  [propertyRecent]: propertyRecentReducer,
  [propertyFunnel]: propertyFunnelReducer,
  [propertyRent]: propertyRentExpiredReducer,
  funnels: funnelReducer
});
