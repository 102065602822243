import React, { useCallback, useState } from 'react';
// Components
import StarsRating from 'components/StarsRating';
// Services
import { updateDeal } from 'services/deals';

function ChangeRating({ deal }) {
  const [rating, setRating] = useState(deal?.rate);

  //
  const handleChange = useCallback(
    async rate => {
      try {
        setRating(rate);
        return updateDeal(deal?.stage?.id, deal?.id, {
          rate
        });
      } catch {
        // Volta para o rating anterior
        setRating(rating);
      }
    },
    [rating, setRating]
  );

  return (
    <td>
      <StarsRating start={0} end={5} rating={rating} onChange={handleChange} />
    </td>
  );
}

ChangeRating.defaultProps = {};
ChangeRating.propTypes = {};

export default ChangeRating;
