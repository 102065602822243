import React from 'react';

function Title({ property }) {
  return (
    <>
      {property?.reference} - {property?.title_formatted} -{' '}
      {property?.profileName} - {property?.situation?.title}
    </>
  );
}

export default Title;
