import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import FormPropertyConfig from './components/FormPropertyConfig';
// Modules
import { realEstateSelector, setRealEstate } from 'modules/login';
// Services
import * as realEstateService from 'services/realEstate';

export default function Properties() {
  const dispatch = useDispatch();
  const realEstate = useSelector(realEstateSelector);

  // Atualiza os dados da imobiliária
  const updateRealEstate = useCallback(
    (values) => {
      dispatch(setRealEstate(values));
    },
    [dispatch]
  );

  const handleSubmit = useCallback(async (values) => {
    try {
      await realEstateService.updateSearchConfig(values);
      return values;
    } catch {
      throw new Error('Problema na atualização dos dados da imobiliária');
    }
  }, []);

  const handleSubmitSuccess = useCallback((values) => {
    updateRealEstate(values);
  }, []);

  return (
    <Wrapper>
      <MainTitle
        title="Pesquisa"
        text="Defina se o resultado de pesquisa deve agrupar imóveis de um mesmo condomínio."
      />
      <FormPropertyConfig
        initialValues={{
          with_grouped_condos: realEstate?.with_grouped_condos,
          with_guru: realEstate?.with_guru,
        }}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
      />
    </Wrapper>
  );
}
