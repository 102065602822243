import React from 'react';
import ListOptions from 'components/ListOptions';
import { FieldBool, Input } from 'components/Form';
import { Field } from 'redux-form';
import { Content, Text, Wrapper } from './styles';
import useFormValue from 'hooks/useFormValue';
import FieldPhone from 'containers/FieldPhone';

function FieldShareOptions() {
  const { should_send_whatsapp, should_send_email } = useFormValue([
    'should_send_whatsapp',
    'should_send_email',
  ]);
  return (
    <ListOptions.item>
      <Wrapper>
        <Text>Enviar por WhatsApp?</Text>
        <Content>
          <FieldBool name="should_send_whatsapp" />
          <FieldPhone
            disabled={!should_send_whatsapp}
            label=""
            name="person_cellphone_number"
            nameDDI="person_cellphone_ddi"
            nameISO2="person_cellphone_iso2"
            style={{
              width: '169px',
            }}
          />
        </Content>
      </Wrapper>
      <Wrapper>
        <Text>Enviar por e-mail?</Text>
        <Content>
          <FieldBool name="should_send_email" />
          <Field disabled={!should_send_email} name="email" component={Input} />
        </Content>
      </Wrapper>
    </ListOptions.item>
  );
}

export default FieldShareOptions;
