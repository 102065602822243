import api from './index';
// Service
import { responseMessage } from 'lib/service-helpers';
// Constants
import { NOTIFICATION_STATUS } from 'constants/constants';

export const getList = (params) =>
  api.getList('notifications', {
    ...params,
    with_counts: true,
    sort: '-created_at',
  });

export const getListByTag = (tag) => getList({ filter: { tag } });

export const getSearch = (params) => api.getSearch('notifications', params);
export const getOne = (id) => api.getOne('notifications', id);
export const create = (data) => api.create('notifications', data);
export const update = ({ id }) => api.create(`notifications/${id}/receive`);
export const remove = (id) =>
  api
    .delete('notifications', id)
    .then(responseMessage('Notificação removida!'));

export const receiveAll = (params) =>
  api.create('notifications/receive-all', params);
export const clearAll = (params) =>
  api.delete('notifications/clear-all', '', {
    params,
  });
export const read = (id) =>
  update({ id, status: NOTIFICATION_STATUS.RECEIVED });
