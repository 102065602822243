// Modules
import dataReducer from 'modules/data';
// Helpers
import { filterOrAll } from '../helpers';
// Services
import { getPropertiesPerPeriod } from 'services/reports';

export const {
  reducer: propertyPerPeriodReducer,
  actionCreators: propertyPerPeriodActions,
  selectors: propertyPerPeriodSelectors
} = dataReducer(`reports/propertyPerPeriod`);

// Selector
export const chartDataSelector = filterOrAll(propertyPerPeriodSelectors);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(propertyPerPeriodActions.request());

  getPropertiesPerPeriod(params).then(res => {
    dispatch(propertyPerPeriodActions.receive(res.data));

    return res.data;
  });
};

export const toggleChecked = item => dispatch => {
  dispatch(propertyPerPeriodActions.toggleSelected(item.id));
};

export default propertyPerPeriodReducer;
