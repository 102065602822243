// Widgets.js
import Alert from 'react-s-alert';
import * as dealsService from '../services/deals';
import { createModule, item } from '../lib/reducer-helpers';
import { hideLoading, showLoading } from './loading';

const createAction = createModule('funnel');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');
const ADD = createAction('ADD');
const REMOVE = createAction('REMOVE');

const initialState = {
  data: [],
  meta: { isFetching: false },
};

/**
 * Seleciona todos os funis
 * @param state
 */
export const funnelsSelector = (state) => state.funnel.data;
export const funnelsIsFetchingSelector = (state) =>
  state.funnel.meta.isFetching;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, meta: { ...state.meta, isFetching: true } };
    case RECEIVE:
      return {
        ...state,
        data: action.funnels,
        meta: { ...state.meta, isFetching: false },
      };
    case ADD:
      return {
        ...state,
        data: item.addUp(state.data, action.funnel),
      };
    case REMOVE:
      const newData = state.data.filter((item) => item.id !== action.funnel.id);

      return {
        ...state,
        data: newData,
      };
    default:
      return state;
  }
}

// Action Creators

// Marca que está carregando outros funis
export function requestFunnel() {
  return { type: REQUEST };
}

// Recebe uma lista de funis
export function receiveFunnel(funnels) {
  return { type: RECEIVE, funnels };
}

// Adiciona um novo funil na lista
export function addFunnel(funnel) {
  return { type: ADD, funnel };
}

// Adiciona um novo funil na lista
export function removeFunnel(funnel) {
  return { type: REMOVE, funnel };
}

// side effects, only as applicable
// e.g. thunks, epics, etc

/**
 * Pega uma lista de funis
 * @param params
 * @return {function(*)}
 */
export function getFunnel({ user_id }) {
  return async (dispatch) => {
    dispatch(requestFunnel());

    const res = await dealsService.getFunnels({
      include: 'stages',
      sort: 'name',
      with_people_count_by_user_id: user_id,
    });

    // Verifica se tem funil selecionado
    if (localStorage.getItem('@tecimob/funnel_id')) {
      // localStorage.setItem('@tecimob/funnel_id', res.data[0].id);
    }

    // res.data = res.data.map((funnel) => {
    //   return {
    //     ...funnel,
    //     label: `${funnel.name} (${funnel.deals_count})`,
    //   };
    // });

    dispatch(receiveFunnel(res.data));
  };
}

export function handleRemoveFunnel(funnel) {
  return async (dispatch) => {
    // Mostra efeito de loading
    dispatch(showLoading());

    // Deleta efetivamente o funil
    await dealsService.deleteFunnel(funnel.id);

    // Esconde o loading
    dispatch(hideLoading());

    // Remove o funil da listagem do redux
    dispatch(removeFunnel(funnel));
  };
}

/**
 * Adiciona um novo funil na lista
 * @return {function(*)}
 */
export function handleSubmitFunnel(funnel) {
  return (dispatch) => {
    return dealsService
      .createFunnel(funnel)
      .then(({ data: funnel }) => {
        dispatch(addFunnel(funnel));
        return funnel;
      })
      .catch(() => {
        Alert.success(`Não foi possível cadastrar o funil: ${funnel.name}`);
      });
  };
}
