import api from 'services';
import { transformResponse } from 'lib/report-helpers';

export const getRealtorsByPeopleType = async () => {
  try {
    const response = await api.get('reports/people/per-realtor');
    return transformResponse(response);
  } catch (e) {
    throw e;
  }
};

export const getPeopleChannelByTypes = async values => {
  try {
    const response = await api.get('reports/people/per-channel', values);
    return transformResponse(response);
  } catch (e) {
    throw e;
  }
};

export const getPeoplePerPeriod = async values => {
  try {
    const response = await api.get('reports/people/per-period', values);

    const transformedResponse = transformResponse(response, {
      itemsKey: 'periods',
      labelKey: 'period',
      valueKey: 'total'
    });

    return transformedResponse;
  } catch (e) {
    throw e;
  }
};
