import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const Text = styled.span`
  color: ${p => (p.theme.hasFile ? '#fff' : '#000')};
`;
