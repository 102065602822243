import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FaWhatsapp } from 'react-icons/fa';
import Form from '../Form';
// Components
import Button from 'components/Button';
import ListOptions from 'components/ListOptions';
import { ModalFooter } from 'components/Modal';
import FieldCalendar from '../Fields/FieldCalendar';
import FieldMessage from '../Fields/FieldMessage';
import FieldPersonInfo from '../Fields/FieldPersonInfo';
import FieldPrivate from '../Fields/FieldPrivate';
// Helpers
import { whatsappSendUrl } from 'lib/share-helpers';

import validate from './validate';
import { permissionsSelector } from 'modules/login';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Alert from 'components/Alert';

const propTypes = {
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCreatePerson: PropTypes.func.isRequired,

  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  type: null,
  handleClose: null,
  onCreatePerson: null,

  initialValues: null,
  onSubmit: null,
};

class FormShareWhatsapp extends React.Component {
  openWhatsappPage = (values, message) => {
    if (values?.person_cellphone_number) {
      setTimeout(() => {
        window.open(
          whatsappSendUrl(
            values?.person_cellphone_number,
            message,
            values?.person_cellphone_ddi
          )
        );
      }, 50);
    }
  };

  onSubmitSuccess = ({ person, message } = {}, dispatch, props) => {
    const { handleClose } = this.props;

    // Abre a janela do whatsapp
    this.openWhatsappPage(props.values, message);

    // Fecha a modal depois de abrir a janela do whatsapp
    handleClose();
  };

  filterPerson = () => {
    const { permissions } = this.props;
    const currentUser = localStorage.getItem('current-user');

    if (!permissions.includes('CAN_OTHER_PERSON_DEAL'))
      return {
        user_id: currentUser?.id,
      };

    return {};
  };

  render() {
    const { type, onCreatePerson, handleClose, onSubmit, initialValues } =
      this.props;

    return (
      <Form
        validate={validate}
        onSubmit={onSubmit}
        onSubmitSuccess={this.onSubmitSuccess}
        initialValues={{
          ...initialValues,
          should_send_whatsapp: true,
          person_cellphone_ddi: initialValues?.person_cellphone_ddi || '55',
          person_cellphone_iso2: initialValues?.person_cellphone_iso2 || 'br',
        }}
      >
        {({ clearField, change }) => (
          <>
            <Row className="row--divider">
              <Col xs={6} style={{ marginTop: '-20px', paddingTop: '20px' }}>
                <ListOptions
                  style={{
                    width: 'calc(100% + 34px)',
                    marginLeft: '-19px',
                    marginTop: '-20px',
                  }}
                >
                  <FieldPersonInfo
                    change={change}
                    type={type}
                    onCreatePerson={onCreatePerson}
                    filter={{ can_view: true }}
                  />
                  <FieldCalendar clearField={clearField} />
                  <FieldPrivate />
                </ListOptions>
              </Col>
              <Col xs={6} style={{ marginTop: '-20px', paddingTop: '20px' }}>
                <Alert color="secondary" style={{ marginBottom: '10px' }}>
                  O link dos imóveis será adicionado automaticamente na
                  mensagem.
                </Alert>
                <FieldMessage
                  type="simple"
                  height={283}
                  label="Mensagem do whatsapp"
                />
              </Col>
            </Row>
            <ModalFooter withBorder>
              <Button
                type="button"
                color="white"
                colorText="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <div className="h-flex__cell--grow" />
              <Button type="submit" color="success">
                Compartilhar pelo Whatsapp <FaWhatsapp />
              </Button>
            </ModalFooter>
          </>
        )}
      </Form>
    );
  }
}

FormShareWhatsapp.propTypes = propTypes;
FormShareWhatsapp.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  permissions: permissionsSelector(state),
});

export default compose(connect(mapStateToProps))(FormShareWhatsapp);
