import React from 'react';
import { Field } from 'redux-form';
import { parseCheckbox } from 'lib/formHelpers';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import { Col } from 'react-flexbox-grid';
import { useCan } from 'hooks/useCan';
import { USER_PERMISSIONS } from 'constants/rules';
// import { Wrapper } from './styles';

function Item({
  handleDeleteItem,
  name,
  params,
  className,
  itemsCol,
  values: { id, title, is_default, has_quantity },
}) {
  const canEditCondo = useCan({
    run: USER_PERMISSIONS.EDIT_CONDOS,
  });

  // nome que vai antes pra indentificar os itens
  const prefixName = `${name}.${id}`;

  // verifica se pode deletar o item
  const canDeleteItem = !!handleDeleteItem && !is_default && canEditCondo;

  return (
    <Col tagName="label" {...itemsCol} key={id} className={className.item}>
      <Field
        id={id}
        type="checkbox"
        name={`${prefixName}.isChecked`}
        className="h-margin-right--5"
        parse={parseCheckbox}
        component={Checkbox}
      />
      <span className="h-pointer h-text-overflow">{title}</span>
      <div className="h-flex__cell--grow" />
      {has_quantity && (
        <Field
          name={`${prefixName}.quantity`}
          component="input"
          type="number"
          className="FormControl ListCheck__inputqtd"
        />
      )}
      {canDeleteItem && (
        <button
          type="button"
          onClick={handleDeleteItem({ id, title, ...params })}
        >
          <Icon name="close" />
        </button>
      )}
    </Col>
  );
}

export default Item;
