import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Traz um movimento
 */
export const showMoviment = ({ postingId, movimentId }) => {
  return api.getOne(`/financial/postings/${postingId}/moviments/${movimentId}`);
};

/**
 * Atualiza um movimento
 */
export const updateMoviment = ({
  postingId,
  movimentId,
  description,
  value,
  billing_date,
  account_id,
  category_id,
  notes,
  repeatment_type,
}) => {
  return api
    .update(`financial/postings/${postingId}/moviments/${movimentId}`, {
      description,
      value,
      billing_date,
      account_id,
      category_id,
      notes,
      repeatment_type,
    })
    .then(responseMessage('Movimento atualizado'));
};

/**
 * Pagar um movimento
 */
export const payMoviment = ({
  postingId,
  movimentId,
  value,
  billing_date,
  account_id,
}) => {
  return api
    .patch(`financial/postings/${postingId}/moviments/${movimentId}/payment`, {
      value,
      billing_date,
      account_id,
    })
    .then('Pagamento confirmado');
};

/**
 * Cancela o pagamento
 * @param postingId
 * @param movimentId
 * @returns {Promise<AxiosResponse<*>>}
 */
export const removePayment = ({ postingId, movimentId }) => {
  return api
    .patch(
      `financial/postings/${postingId}/moviments/${movimentId}/remove-payment`
    )
    .then(responseMessage('Pagamento cancelado'));
};

/**
 * Remover um movimento
 * @param postingId
 * @param movimentId
 * @param repeatment_type
 * @returns {Promise<AxiosResponse<never>>}
 */
export const destroyMoviment = ({ postingId, movimentId, repeatment_type }) => {
  return api
    .delete(`financial/postings/${postingId}/moviments/${movimentId}`, '', {
      params: {
        repeatment_type,
      },
    })
    .then(responseMessage('Movimento removido'));
};
