import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'components/Form/components/FormGroup';
import { Wrapper, Button } from './styles';

function NumberRating({
  min,
  max,
  input,
  label,
  required,
  disabled,
  meta,
  ...props
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let _options = [];

    for (let i = min; i <= max; i++) {
      _options.push(i);
    }

    setOptions(_options);
  }, [min, max, setOptions]);

  const setNumber = useCallback(
    number => () => {
      input.onChange(number);
    },
    [input]
  );

  return (
    <FormGroup
      {...props}
      labelText={label}
      isRequired={required}
      isDisabled={disabled}
      input={input}
      meta={meta}
    >
      <Wrapper>
        {options.map(number => (
          <Button isActive={number === input.value} onClick={setNumber(number)}>
            {number}
          </Button>
        ))}
      </Wrapper>
    </FormGroup>
  );
}

NumberRating.defaultProps = {
  min: 0,
  max: 10
};
NumberRating.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number
};

export default NumberRating;
