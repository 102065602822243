import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Common Components
import { ModalTemplate } from 'components/Modal';
import FormFinancialDelete from './FormFinancialDelete';
// Services
import * as financialService from 'services/financialIndex';

class ModalUserDelete extends React.Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    modalType: null,
    modalConfig: null,
    isOpen: null,
    handleClose: null
  };

  state = {
    data: [],
    isFetching: false
  };

  get renderMessages() {
    const { message } = this.props;
    return (
      <ul className="h-list--none h-margin-top--15 h-margin-bottom--15">
        {message.map((m, i) => (
          <li key={`financial-${i}`}>{m}</li>
        ))}
      </ul>
    );
  }

  componentDidMount() {
    this.fetchIndexes();
  }

  /**
   * Remove o indice financeiro que veio
   * @param res
   * @return {*}
   */
  transformResponse = res => {
    const { financialIndex } = this.props;
    res.data = res.data.filter(f => financialIndex.id !== f.id);
    return res;
  };

  fetchIndexes = params => {
    this.setState({ isFetching: true });
    return financialService
      .getList(params)
      .then(this.transformResponse)
      .then(res => {
        this.setState({
          data: res.data,
          isFetching: false
        });
        return res;
      });
  };

  handleSubmit = ({ from_financial_index_id, to_financial_index_id }) =>
    financialService.transferAndRemove(
      from_financial_index_id,
      to_financial_index_id
    );

  onSubmitSuccess = (...args) => {
    const { handleClose, onSubmitSuccess } = this.props;
    if (onSubmitSuccess) onSubmitSuccess(...args);

    // Fecha a modal quando termina de transferir e deletar o usuário
    handleClose();
  };

  render() {
    const {
      modalConfig,
      isOpen,
      handleClose,
      modalType,

      financialIndex,
      initialValues
    } = this.props;

    const { data, isFetching } = this.state;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          title={`Apagar ${financialIndex.name}`}
          handleClose={handleClose}
        >
          <FormFinancialDelete
            financialIndexes={data}
            isFetching={isFetching}
            message={this.renderMessages}
            financialIndex={financialIndex}
            onSubmit={this.handleSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
            initialValues={initialValues}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

export default ModalUserDelete;
