import React from 'react';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import house from 'containers/ModalRoot/ModalPerson/components/Properties/pages/PropertiesDiscarted/house-trash.svg';
import CardProperty from 'components/CardProperty';
import DetailsDiscarted from 'containers/ModalRoot/ModalPerson/components/Properties/components/DetailsDiscarted';
import Pagination from 'components/Pagination';
import { useRequest } from 'hooks/useRequest';
import * as peopleService from 'services/people';
// import { Container } from './styles';

const STATUS = {
  NEW: 1,
  SENDED: 2,
  DISCARDED: 3,
};

const PropertiesDiscarted = ({
  openModalProperty,
  modalPerson: { person },
  handleOpenMySelf,
}) => {
  const {
    data: matcheds,
    meta,
    fetchData,
    isFetching,
  } = useRequest({
    request: (params) =>
      peopleService.getPeopleMatched(person.id, {
        filter: { by_status: STATUS.DISCARDED },
        offset: 1,
        limit: 40,
        include: 'discarted_by',
        ...params,
      }),
  });

  if (isFetching) return <Loading isVisible />;

  if (matcheds.length === 0) {
    return (
      <Nodata
        style={{ maxWidth: 330 }}
        image={house}
        title="Nenhum imóvel descartado."
        text="Aqui estarão os imóveis que você ou seu cliente descartar."
      />
    );
  }

  return (
    <>
      {matcheds.map((matched) => {
        const isDiscardtedByUser = !!matched?.discarted_by?.name;

        const name = matched?.discarted_by?.name || '--';

        return (
          <CardProperty
            key={matched.id}
            type="horizontal"
            property={matched.property}
            message={`Descartado por: ${name}`}
            handleClickProperty={() => {
              openModalProperty({
                property: matched.property,
                afterClose: () => {
                  handleOpenMySelf();
                },
              });
            }}
          >
            {isDiscardtedByUser && <DetailsDiscarted matchedId={matched.id} />}
          </CardProperty>
        );
      })}
      <Pagination
        pagination={meta?.pagination}
        onPageChange={({ selected }) => {
          fetchData({ offset: selected + 1 });
        }}
      />
    </>
  );
};

export default PropertiesDiscarted;
