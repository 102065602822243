import { createContext, useContext } from 'react';

export const ModalContext = createContext({
  property: null,
});

export const ModalProvider = ({ children, property }) => {
  return (
    <ModalContext.Provider
      value={{
        property,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
