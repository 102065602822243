import React from 'react';
import { Field, reduxForm } from 'redux-form';
// Components
import { FieldBool, Textarea } from 'components/Form';
// Assets
import Container from 'components/Container';
import ListOptions from 'components/ListOptions';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import Alert from 'components/Alert';

function FormPropertyConfig({ handleSubmit, submitting, pristine }) {
  return (
    <form onSubmit={handleSubmit}>
      <Container padding style={{ marginTop: '15px' }}>
        <Alert color="danger">
          <p>LEI Nº 13.709, DE 14 DE AGOSTO DE 2018</p>
          <br />
          <p>
            As opções abaixo tratam sobre as determinações da Lei Geral de
            Proteção de Dados Pessoais (LGPD). Recomendamos que permaneçam
            ativadas para evitar eventuais sansões pelos órgãos públicos
            competentes  (União, Estados, Distrito Federal e Municípios).
          </p>
          <p>
            Saiba mais em:
            <a
              target="_blank"
              href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm"
              rel="noreferrer"
              className="h-link"
            >
              http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm
            </a>
          </p>
          <br />
          <p>
            A desativação de qualquer uma das opções é de total responsabilidade
            do usuário.
          </p>
        </Alert>
      </Container>

      <Container padding style={{ marginTop: '15px' }}>
        <ListOptions title="Informação ao cliente">
          <ListOptions.item
            text="Ao acessar o site, mostrar no rodapé o aviso que o site utiliza COOKIES para registro de navegação?"
            renderOptions={() => (
              <FieldBool size="small" name="use_cookies_show" />
            )}
          />
          <ListOptions.item
            text="Nos formulários de contato, mostrar e obrigar a marcação de concordância com sua POLÍTICA DE PRIVACIDADE?"
            renderOptions={() => <FieldBool name="privacy_policy_show" />}
          />
          <ListOptions.item title="Texto sobre política de privacidade">
            <div className="h-margin-top--10">
              <Field
                width={940}
                height={540}
                name="privacy_policy_text"
                component={Textarea}
                style={{
                  fontFamily: 'Arial',
                  fontSize: '14px',
                  lineHeight: '1.4em',
                }}
              />
            </div>
          </ListOptions.item>
        </ListOptions>
      </Container>

      <FixedBar>
        <FixedBar.spacer />
        <FixedBar.item>
          <Button
            type="submit"
            color="success"
            disabled={submitting || pristine}
          >
            Salvar
          </Button>
        </FixedBar.item>
      </FixedBar>
    </form>
  );
}

export default reduxForm({
  form: 'FormProfileConfig',
  enableReinitialize: true,
})(FormPropertyConfig);
