import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import { Input } from 'components/Form';
import Button from 'components/Button';
// Constants
import { MAX_LENGTH_ESTABLISHMENTS } from 'pages/PropertyAdd/constants';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const FormEstablishments = ({ handleSubmit }) => (
  <form
    onSubmit={(e, ...rest) => {
      e.preventDefault();
      e.stopPropagation();
      return handleSubmit(...rest);
    }}
  >
    <Field
      label="Nome"
      name="title"
      component={Input}
      maxLength={MAX_LENGTH_ESTABLISHMENTS}
    />
    <Button block type="submit" color="success">
      Adicionar
    </Button>
  </form>
);

FormEstablishments.defaultProps = defaultProps;
FormEstablishments.propTypes = propTypes;

export default reduxForm({
  form: 'FormEstablishments',
  enableReinitialize: true
})(FormEstablishments);
