import Tooltip from 'react-tooltip';
import { WrapperAll } from 'pages/Site/pages/ContentSite/pages/Posts/components/TablePosts/styles';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import TableRow from './TableRow';
import { MdAdd, MdInfo } from 'react-icons/md';
import React, { useEffect } from 'react';
import Nodata from '../../../../../../components/Nodata';
import Button from '../../../../../../components/Button';

// import { Wrapper } from './styles';

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => <TableRow {...props} />);

function TableStages({
  data,
  handleClickUpdate,
  handleClickTransfer,
  handleClickRemove,
  handleChangeOrder,
  handleClickOpenStageStore,
}) {
  useEffect(() => {
    Tooltip.rebuild();
  }, [data]);

  if (!data || data.length === 0)
    return (
      <Nodata
        image={null}
        title="Nenhuma etapa cadastrada"
        text="Voce pode cadastrar uma nova etapa no funil usando o botão abaixo"
      >
        <Button
          className="h-margin-top--15"
          color="secondary"
          onClick={() => handleClickOpenStageStore()}
        >
          <MdAdd />
          Nova etapa
        </Button>
      </Nodata>
    );

  return (
    <WrapperAll>
      <table className="Table">
        <thead>
          <tr>
            <th align={'center'} className="Table__cell--small">
              Arraste
            </th>
            <th>Nome da etapa</th>
            <th>
              Estagnar em{' '}
              <MdInfo data-tip="Após este prazo, o cartão do negócio ira destacar com fundo vermelho" />
            </th>
            <th>
              Qtd Negócios{' '}
              <MdInfo data-tip="Contagem de cartões que existem atualmente nesta etapa" />
            </th>
            <th>Opções</th>
          </tr>
        </thead>
        <SortableCont
          onSortEnd={handleChangeOrder}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={['30%', '50%']}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {data?.map((value, index) => (
            <SortableItem
              key={`item-${value.id}`}
              index={index}
              data={value}
              handleClickUpdate={handleClickUpdate}
              handleClickTransfer={handleClickTransfer}
              handleClickRemove={handleClickRemove}
            />
          ))}
        </SortableCont>
      </table>
    </WrapperAll>
  );
}

export default TableStages;
