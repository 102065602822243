import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  overflow-x: hidden;
  position: relative;
  background: #fff;
  padding: 8px 4px;

  ${(p) =>
    p.layout === 'responsive' &&
    css`
      width: auto;
      grid-template-columns: none;
      grid-auto-flow: column;
      grid-auto-columns: minmax(224px, 1fr);
      overflow: auto;
    `}

  * {
    font-family: Inter, sans-serif;
  }
`;
