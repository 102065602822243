import React from 'react';
import { Field } from 'redux-form';
// Components
import { Select2 } from 'components/Form';

const AgentField = ({ options, ...props }) => (
  <Field
    label="Agenciador"
    name="agent_id"
    labelKey="name"
    valueKey="id"
    options={options}
    component={Select2}
    selectedItemsLabel="agenciadores"
    {...props}
  />
);

export default AgentField;
