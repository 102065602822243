import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Alert from 'react-s-alert';
import ReactTooltip from 'react-tooltip';
//import MainHeader from "components/MainHeader";
import MainHeader from 'containers/Header';
import SearchBarProvider from 'components/SearchBar/context';
// Containers
import AlertTemplate from '../../containers/AlertTemplate';
import MessageAnotherUser from 'components/MessageAnotherUser';
import FileUpload from 'containers/FileUpload';
import ModalConfirmation from '../../containers/ModalConfirmation';
import ModalImage from 'components/ModalImage';
import SatisfactionBar from 'components/SatisfactionBar';
import RecommendationTag from 'components/RecommendationTag';
import { useCurrentUser } from 'hooks/useCurrentUser';

const defaultProps = {
  className: null,
  children: null
};

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const MainTemplate = ({ children, className }) => {
  const currentUser = useCurrentUser();

  return (
    <SearchBarProvider>
      <div className="App">
        <ModalConfirmation />
        <SatisfactionBar />
        <ModalImage />
        <MainHeader />
        <MessageAnotherUser />
        <main role="main" className={classnames(className, 'MainContainer')}>
          {children}
        </main>
        <ReactTooltip effect="solid" html={true} />
        <Alert
          contentTemplate={AlertTemplate}
          position="bottom-left"
          stack
          effect="slide"
          timeout={5000}
        />
        <FileUpload />
        {currentUser.recommendation_button_show && <RecommendationTag />}
      </div>
    </SearchBarProvider>
  );
};

MainTemplate.defaultProps = defaultProps;
MainTemplate.propTypes = propTypes;

export default MainTemplate;
