import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import { Input } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import { canShowDeadline } from 'lib/property-helpers';
// import { Container } from './styles';

const FieldDeliveryForecast = () => {
  const situationId = useFormValue('situation_id');

  // Verifica se pode mostrar esse campo
  const canShow = useMemo(() => {
    return canShowDeadline(situationId);
  }, [situationId]);

  if (!canShow) return null;

  return (
    <Field
      xs={3}
      label="Previsão de Entrega"
      name="delivery_forecast"
      component={Input}
    />
  );
};

export default FieldDeliveryForecast;
