import house from 'assets/img/noData/house.svg';
import Button from 'components/Button';
import LastProperties from 'components/LastProperties';
import LastSearches from 'components/LastSearches';
import PropertyDashboard from 'containers/PropertyDashboard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrentSearch from './components/CurrentSearch';
import Properties from './components/Properties';
import { Aside, Content, Wrapper } from './styles';

// Modules
import {
  clearSearch,
  getProperties,
  getPropertiesSelecteds,
  lastPropertiesVisitedSelector,
  lastSearchesSelector,
  propertiesSelector,
  setNetwork,
  setTypeList,
} from 'modules/propertySearch';

// Hooks
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import { useHashChange } from 'hooks/useHashChange';
import { useQuery } from 'hooks/useQuery';
import { openModalPropertySearch } from 'modules/modal';
import { useHistory } from 'react-router';

const hasPropertiesCount = (count) => {
  return (
    count?.self?.count > 0 ||
    count?.guru?.count > 0 ||
    count?.orulo?.count > 0 ||
    count?.dwv?.count > 0
  );
};

function PropertiesSearch() {
  const dispatch = useDispatch();
  const history = useHistory();

  const isOpenModal = useHashChange('#openSearch');
  const properties = useSelector(getPropertiesSelecteds);
  const { meta } = useSelector(propertiesSelector);
  const lastSearches = useSelector(lastSearchesSelector);
  const lastProperties = useSelector(lastPropertiesVisitedSelector);
  const params = useQuery();

  const handleClearSearch = () => {
    dispatch(clearSearch(history));
  };

  const handleSearch = () => {
    dispatch(
      getProperties({
        ...params,
        type_list: params.type_list,
        count: 'deals',
      })
    );
  };

  const hasProperties =
    properties.length > 0 || hasPropertiesCount(params?.filter?.count);

  useEffect(() => {
    // // Limpa a pesquisa quando entra na listagem
    // handleClearSearch();
  }, []);

  useEffect(() => {
    if (params?.filter) {
      handleSearch();
    }
  }, [params]);

  useEffect(() => {
    if (isOpenModal) {
      dispatch(openModalPropertySearch());
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (params.type_list) {
      dispatch(setTypeList(params.type_list));
    } else {
      dispatch(setTypeList('card'));
    }

    if (params?.filter?.on_network) {
      dispatch(setNetwork(params?.filter?.on_network[0]));
    }
  }, [params]);

  return (
    <Wrapper>
      <Aside className="js-aside">
        <CurrentSearch query={params} />
        <LastSearches data={lastSearches} />
        <LastProperties data={lastProperties} />
      </Aside>
      <Content>
        <Loading isFullScreen isVisible={meta.isFetching} />
        {!meta.isFetching && params?.filter && !hasProperties ? (
          <Nodata
            image={house}
            title="Nenhum imóvel encontrado"
            text="Clique no botão limpar para"
            style={{ marginTop: '45px' }}
          >
            <Button
              color="danger"
              onClick={handleClearSearch}
              className="h-margin-top--15"
            >
              Limpar pesquisa
            </Button>
          </Nodata>
        ) : hasProperties ? (
          <Properties
            handleSearch={handleSearch}
            data={properties}
            params={params}
            meta={meta}
          />
        ) : (
          <PropertyDashboard />
        )}
      </Content>
    </Wrapper>
  );
}

export default PropertiesSearch;
