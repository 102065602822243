// Modules
import dataReducer from 'modules/data';
// Helpers
import { filterOrAll } from '../helpers';
import { getDealPerReasonLoss } from 'services/reports';

export const {
  reducer: dealPerReasonLossReducer,
  actionCreators: dealPerReasonLossAction,
  selectors: dealPerReasonLossSelectors
} = dataReducer(`reports/dealPerReasonLoss`);

export const chartDataSelector = filterOrAll(dealPerReasonLossSelectors);

// Thunks
export const fetchData = params => dispatch => {
  dispatch(dealPerReasonLossAction.request());

  getDealPerReasonLoss(params).then(res => {
    dispatch(dealPerReasonLossAction.receive(res.data));

    return res.data;
  });
};

export const toggleChecked = item => dispatch => {
  dispatch(dealPerReasonLossAction.toggleSelected(item.id));
};

export default dealPerReasonLossReducer;
