import { useSelecteds } from 'hooks/useSelecteds';
import { useEffect } from 'react';

// Services
import { getPropertiesByPortalId } from 'services/settings/portals';
import { useRequest } from 'hooks/useRequest';

export const usePropertiesSelecteds = ({ portalOptionId }) => {
  const {
    selecteds,
    selectArray,
    toggle,
    unselectAll,
    totalSelecteds
  } = useSelecteds();
  const { data, fetchData } = useRequest({
    request: () => getPropertiesByPortalId(portalOptionId)
  });

  useEffect(() => {
    if (data && data?.length > 0) {
      selectArray(data);
    }
  }, [data]);

  return {
    selecteds,
    toggle,
    unselectAll,
    totalSelecteds,
    updatedSelecteds: fetchData
  };
};
