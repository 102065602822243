import React, { Component } from 'react';
import _ from 'lodash';
import qs from 'qs';
import Alert from 'react-s-alert';
import AlertTC from 'components/Alert';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
// Components
import Loading from 'components/Loading';
import FormBox, { FormBoxHeader } from 'components/FormBox';
import Button from 'components/Button';
import { Checkbox, Input, SelectMultiple as Select } from 'components/Form';
import Divider from 'components/Divider';
import GroupControl, { GroupItem } from 'components/GroupControl';
// Containers
import MapContainer from 'containers/MapContainer';
// Modules
import { hideLoading, showLoading } from 'modules/loading';
import {
  openModalAddCity,
  openModalAddNeighborhood,
  openModalLocationAdd,
} from 'modules/modal';
import {
  handleSetPosition,
  handleSetPov,
  mapPositionSelector,
  mapPovSelector,
} from 'modules/mapView';
import {
  getCity,
  getNeighborhood,
  handleChangeCep,
  locationSelector,
  locationThunks,
} from 'modules/propertyAdd';
// Services
import * as propertiesService from 'services/properties';
// Helpers
import { normalizeCep, parseSelect } from 'lib/formHelpers';
import { USER_PERMISSIONS } from 'constants/rules';
import Can from 'containers/Can';
import { plural } from 'lib/text';

const defaultProps = {
  locationFields: {
    public_place: '',
    number: '',
    complement: '',
  },
};
const propTypes = {};

class Location extends Component {
  map = undefined;
  streetview = undefined;
  marker = undefined;

  state = {
    isMapShown: false,
    country: '',
    state: '',
    city: '',
    neighborhood: '',
    street_address: '',
    street_number: '',
    countProperties: 0,
  };

  componentDidMount() {
    this.props.getCountries();
  }

  componentDidUpdate(prevProps) {
    this.updateState('country_id', 'country', 'countries', prevProps);
    this.updateState('state_id', 'state', 'states', prevProps);
    this.updateState('city_id', 'city', 'cities', prevProps);
    this.updateState(
      'neighborhood_id',
      'neighborhood',
      'neighborhoods',
      prevProps
    );
    this.updateState('street_address', 'street_address', null, prevProps);
    this.updateState('street_number', 'street_number', null, prevProps);
  }

  updateState(valueKey, stateKey, dataKey, prevProps) {
    const nextValue = this.props.locationFields[valueKey];
    const prevValue = prevProps.locationFields[valueKey];

    if (!dataKey && nextValue !== prevValue) {
      this.setName(stateKey, nextValue);
      this.props.changeField(valueKey, nextValue);
      return;
    }

    const prevData = prevProps[dataKey];
    const nextData = this.props[dataKey];

    if (nextData !== prevData && nextData && nextValue) {
      this.setName(stateKey, nextValue, nextData);
    }
  }

  setAddress = () => {
    const { handleChangeCep } = this.props;
    setTimeout(async () => {
      const {
        country,
        state,
        city,
        neighborhood,
        street_address,
        street_number,
      } = this.state;

      const { neighborhood_id } = this.props?.locationFields;

      let address = '';

      if (country) address += country;
      if (state) address += ' ' + state;
      if (city) address += ' ' + city;
      if (neighborhood) address += ' ' + neighborhood;
      if (street_address) address += ' ' + street_address;
      if (street_number) address += ' ' + street_number;

      try {
        const data = await handleChangeCep('', {
          neighborhood_id,
          address,
          street_name: street_address,
        });

        const location = {
          lat: data?.[0]?.latitude || 0,
          lng: data?.[0]?.longitude || 0,
        };

        this.props.handleSetPosition(location);
        this.handleChangeMapView(location, {});
      } catch {}
    }, 50);
  };

  setName = (fieldName, value, state) => {
    const name = state ? this.getName(state, value) : value;
    this.setState({ [fieldName]: name });
  };

  getName = (state, value) => {
    try {
      return state?.find((v) => v.id === value).name;
    } catch {
      return '';
    }
  };

  updateLocation({
    first_neighborhood,
    country_id,
    state_id,
    city_id,
    neighborhood_id,
    ...rest
  }) {
    this.props.changeField(
      'country_id',
      first_neighborhood ? first_neighborhood?.country?.id : country_id || ''
    );
    this.props.changeField('state_id', state_id || '');
    this.props.changeField('city_id', city_id || '');
    this.props.changeField(
      'neighborhood_id',
      first_neighborhood ? first_neighborhood?.id : neighborhood_id || ''
    );

    if (country_id) this.props.getStates({ country_id });
    if (state_id) this.props.getCities({ state_id });
    if (city_id) this.props.getNeighborhoods({ city_id });
  }

  handleChangeCountry = (e, value) => {
    this.changeLocationField('country', '');
    this.changeLocationField('state', '');
    this.changeLocationField('city', '');
    this.changeLocationField('neighborhood', '');

    if (!value) return false;
    const current = this.props.countries.find((data) => data.id === value);
    this.changeLocationField('country', current);
    return this.props.getStates({ country_id: value });
  };

  handleChangeState = (e, value) => {
    this.changeLocationField('state', '');
    this.changeLocationField('city', '');
    this.changeLocationField('neighborhood', '');

    if (!value) return false;

    const current = this.props.states.find((data) => data.id === value);
    this.changeLocationField('state', current);

    return this.props.getCities({ state_id: value });
  };

  handleChangeCity = (e, value) => {
    this.changeLocationField('city', '');
    this.changeLocationField('neighborhood', '');
    if (!value) return false;
    const current = this.props.cities.find((data) => data.id === value);
    this.changeLocationField('city', current);
    return this.props.getNeighborhoods({ city_id: value });
  };

  handleChangeNeighborhood = (e, value) => {
    this.changeLocationField('neighborhood', '');
    if (!value) return false;
    const current = this.props.neighborhoods.find((data) => data.id === value);
    this.changeLocationField('neighborhood', current);
    return true;
  };

  changeLocationField = (fieldName, value) => {
    this.setName(fieldName, value ? value.name : '');
    this.props.changeField([`${fieldName}_id`], value ? value.id : null);
  };

  async fetchPropertiesWithCep(cep) {
    this.setState({
      countProperties: 0,
    });

    const countProperties = await propertiesService.getCountPropertiesByCep(
      cep
    );

    this.setState({
      countProperties,
    });
  }

  changeCep = async (cep) => {
    this.fetchPropertiesWithCep(cep);

    return this.props
      .handleChangeCep(cep)
      .then(
        ({
          country,
          state,
          city,
          neighborhood,
          street_address,
          street_number,
          latitude,
          longitude,
        }) => {
          if (country && country.id)
            this.props.getStates({ country_id: country.id });
          if (state && state.id) this.props.getCities({ state_id: state.id });
          if (city && city.id)
            this.props.getNeighborhoods({ city_id: city.id });

          this.changeLocationField('country', country);
          this.changeLocationField('state', state);
          this.changeLocationField('city', city);
          this.changeLocationField('neighborhood', neighborhood);

          this.setName('street_address', street_address);
          this.setName('street_number', street_number);
          this.props.changeField('street_address', street_address);
          this.props.changeField('street_number', street_number);

          const location = { lat: latitude, lng: longitude };
          this.handleChangeMapView(location, {});
          this.props.handleSetPosition(location);
        }
      )
      .catch(() => {
        return false;
      });
  };

  handleChangeCep = (e, value, prevValue) => {
    if (!value || value === prevValue) return false;
    if (value.length === 9) {
      this.changeCep(value);
    }
    return false;
  };

  handleClickSearchCep = () => {
    const { updateMetaTitle } = this.props;
    const { zip_code } = this.props.locationFields;

    if (zip_code.length <= 8) {
      Alert.success('Cep deve ter no mínimo 8 digitos');
      return false;
    }

    updateMetaTitle();

    if (zip_code) {
      this.changeCep(zip_code);
    }
  };

  handleChangeMapView = ({ lat, lng }, { heading, pitch, zoom }) => {
    const { changeField } = this.props;

    if (lat) changeField('maps_latitude', lat);
    if (lng) changeField('maps_longitude', lng);
    if (heading) changeField('maps_heading', heading);
    if (pitch) changeField('maps_pitch', pitch);
    if (zoom) changeField('maps_street_zoom', zoom);
  };

  onZoomChange = ({ zoom }) => {
    const { changeField } = this.props;
    changeField('maps_zoom', zoom);
  };

  get styleMapContainer() {
    if (this.props.isMapShown) return {};
    return { opacity: '.2', pointerEvents: 'none' };
  }

  get apartmentNumber() {
    try {
      const { fields } = this.props;
      return fields.find(({ name }) => name === 'apartment_number');
    } catch {
      return false;
    }
  }

  get hasNumber() {
    try {
      const { fields } = this.props;

      return fields.find;
    } catch {
      return false;
    }
  }

  handleClickAddLocation = () => {
    this.props.openModalLocationAdd({
      onSubmitSuccess: (location) => {
        this.updateLocation(location);
      },
    });
  };

  handleAddCity = () => {
    this.props.openModalAddCity({
      onAddCity: (res) => {
        this.updateLocation({ ...res.data, city_id: res?.data?.id });
      },
    });
  };

  handleAddNeighborhood = () => {
    this.props.openModalAddNeighborhood({
      onAddNeighborhood: (res) => {
        const location = res.data;

        this.updateLocation({
          ...res.data,
          country_id: location?.country?.id,
          state_id: location?.state?.id,
          neighborhood_id: res?.data?.id,
        });
      },
    });
  };

  render() {
    const { isMapShown } = this.state;

    const {
      updateMetaTitle,
      city,
      states,
      countries,
      cities,
      neighborhoods,
      locationFields: { zip_code, country_id, state_id, city_id },
      isFetchingCountries,
      isFetchingStates,
      isFetchingCities,
      isFetchingNeighborhoods,
      isFetchingLocation,
    } = this.props;

    const stateIsDisabled = !country_id;
    const cityIsDisabled = stateIsDisabled || !state_id;
    const neighborhoodIsDisabled = cityIsDisabled || !city_id;

    const apartmentNumber = this.apartmentNumber;
    const hasNumber = !!apartmentNumber?.title;

    const cols = city.has_zone
      ? {
          country_id: 2,
          state_id: 2,
          city_id: 3,
          neighborhood_id: 3,
          zone: 2,
        }
      : {
          country_id: 3,
          state_id: 3,
          city_id: 3,
          neighborhood_id: 3,
        };

    return (
      <FormBox
        title="Localização"
        className="js-location"
        style={{ position: 'relative' }}
        actions={
          <Can
            run={USER_PERMISSIONS.EDIT_ADDRESSES}
            yes={() => (
              <>
                <Button
                  onClick={this.handleAddCity}
                  color="secondary"
                  size="medium"
                >
                  Cadastrar Cidade
                </Button>
                <Button
                  onClick={this.handleAddNeighborhood}
                  color="secondary"
                  size="medium"
                  style={{
                    marginLeft: '15px',
                  }}
                >
                  Cadastrar Bairro
                </Button>
              </>
            )}
            no={() => (
              <span data-tip="Você não tem permissão para<br /> gerenciar bairros/cidades">
                <Button disabled size="medium">
                  Cadastrar Cidade/Bairro
                </Button>
              </span>
            )}
          />
        }
      >
        <Loading
          isFullComponent
          isVisible={isFetchingLocation}
          position="center"
        />
        <Row>
          <Col xs={4}>
            <GroupControl label="CEP">
              <GroupItem>
                <Field
                  name="zip_code"
                  component={Input}
                  normalize={normalizeCep}
                  onChange={this.handleChangeCep}
                  onKeyPress={(e) => {
                    if (e.which === 13) e.preventDefault();
                  }}
                  onBlur={() => {
                    updateMetaTitle();
                  }}
                />
              </GroupItem>
              <GroupItem size="small">
                <Button
                  disabled={isFetchingLocation}
                  onClick={this.handleClickSearchCep}
                  className="h-margin-left--10"
                >
                  Buscar
                </Button>
              </GroupItem>
            </GroupControl>
          </Col>
          {this.state.countProperties > 0 && (
            <Col xs={4}>
              <AlertTC color="danger" style={{ padding: 10, marginTop: 20 }}>
                <a
                  href={`/properties/show?${qs.stringify({
                    filter: {
                      zip_code,
                      status: [1, 2, 3, 4],
                      without_draft_scope: true,
                    },
                    offset: 1,
                    limit: 50,
                    sort: '-calculated_price',
                  })}`}
                  target="_blank"
                  className="h-link"
                  rel="noreferrer"
                >
                  {plural(
                    this.state.countProperties,
                    'imóvel encontrado',
                    'imóveis encontrados'
                  )}{' '}
                  com esse CEP
                </a>
              </AlertTC>
            </Col>
          )}
        </Row>
        <Row className="no-transition">
          <Field
            multi={false}
            xs={cols.country_id}
            label="País"
            name="country_id"
            component={Select}
            options={countries}
            valueKey="id"
            labelKey="name"
            onChange={this.handleChangeCountry}
            parse={parseSelect('id')}
            isLoading={isFetchingCountries}
          />
          <Field
            multi={false}
            xs={cols.state_id}
            label="Estado"
            name="state_id"
            component={Select}
            valueKey="id"
            labelKey="name"
            options={states}
            onChange={this.handleChangeState}
            disabled={stateIsDisabled}
            parse={parseSelect('id')}
            isLoading={isFetchingStates}
          />
          <Field
            multi={false}
            xs={cols.city_id}
            label="Cidade"
            name="city_id"
            valueKey="id"
            labelKey="name"
            options={cities}
            component={Select}
            onChange={this.handleChangeCity}
            disabled={cityIsDisabled}
            parse={parseSelect('id')}
            isLoading={isFetchingCities}
          />
          <Field
            multi={false}
            xs={cols.neighborhood_id}
            label="Bairro"
            name="neighborhood_id"
            valueKey="id"
            labelKey="name"
            options={neighborhoods}
            component={Select}
            onChange={this.handleChangeNeighborhood}
            disabled={neighborhoodIsDisabled}
            parse={parseSelect('id')}
            isLoading={isFetchingNeighborhoods}
            onBlur={() => {
              updateMetaTitle();
            }}
          />
          {city.has_zone && (
            <Field
              multi={false}
              xs={cols.zone}
              label="Zona"
              name="zone_type"
              component={Select}
              options={_.map(city.zones, (label, value) => ({
                label,
                value,
              }))}
              parse={(value) => {
                if (!value) return;
                return parseInt(value.value);
              }}
            />
          )}
          <Field
            xs={3}
            label="Logradouro"
            name="street_address"
            component={Input}
          />
          <Field xs={2} label="Número" name="street_number" component={Input} />
          {hasNumber && (
            <Field
              xs={3}
              label={apartmentNumber.title}
              name="informations.apartment_number.value"
              component={Input}
            />
          )}
          <Field
            xs={hasNumber ? 4 : 7}
            label="Complemento"
            name="complement_address"
            component={Input}
          />
        </Row>
        <Row center="xs">
          <Col xs={6}>
            {this.props.hasCondo && (
              <Field
                type="checkbox"
                responsive={false}
                name="is_shared_address"
                component={Checkbox}
                label={
                  <span className="h-margin-left--5 h-color--primary-light">
                    <i>
                      Definir este endereço para todos os imóveis no condomínio
                    </i>
                  </span>
                }
              />
            )}
          </Col>
          <Col xs={6} className="h-text-right">
            <Button color="secondary" size="medium" onClick={this.setAddress}>
              Buscar no mapa
            </Button>
          </Col>
        </Row>
        <Row style={{ margin: '10px 0' }}>
          <Col xs={12}>
            <Divider spacingY={5} borderStyle="dashed" />
          </Col>
        </Row>

        <FormBoxHeader size="small">Mapa</FormBoxHeader>
        <Field type="hidden" name="maps_latitude" component="input" />
        <Field type="hidden" name="maps_longitude" component="input" />
        <Field type="hidden" name="maps_heading" component="input" />
        <Field type="hidden" name="maps_pitch" component="input" />
        <Field type="hidden" name="maps_zoom" component="input" />

        {!isMapShown && (
          <Button
            onClick={() => {
              this.setState({ isMapShown: !isMapShown });
            }}
          >
            Mostrar mapa
          </Button>
        )}

        {isMapShown && (
          <MapContainer
            isExactPlace
            onChange={this.handleChangeMapView}
            onZoomChange={this.onZoomChange}
          />
        )}
      </FormBox>
    );
  }
}

Location.defaultProps = defaultProps;
Location.propTypes = propTypes;

const selector = formValueSelector('propertiesAdd');

const mapStateToProps = (state) => ({
  locationFields: selector(
    state,
    'zip_code',
    'street_address',
    'street_number',
    'country_id',
    'state_id',
    'city_id',
    'neighborhood_id'
  ),
  exactPlace: selector(state, 'is_exact_map_shown'),
  isMapShown: selector(state, 'is_map_shown'),
  isStreetviewShown: selector(state, 'is_streetview_shown'),
  states: locationSelector.statesSelector(state),
  isFetchingStates: locationSelector.statesIsFetchingSelector(state),
  countries: locationSelector.countriesSelector(state),
  isFetchingCountries: locationSelector.countriesIsFetchingSelector(state),
  cities: locationSelector.citiesSelector(state),
  isFetchingCities: locationSelector.citiesIsFetchingSelector(state),
  neighborhoods: locationSelector.neighborhoodsSelector(state),
  isFetchingNeighborhoods:
    locationSelector.neighborhoodsIsFetchingSelector(state),
  position: mapPositionSelector(state),
  pov: mapPovSelector(state),
  isFetchingLocation: state.propertyAdd.reducer.meta.isFetchingLocation,
  neighborhood: getNeighborhood(state),
  city: getCity(state),
});

const mapDispatchToProps = (dispatch) => ({
  openModalAddCity: (params) => openModalAddCity(params)(dispatch),
  openModalAddNeighborhood: (params) =>
    openModalAddNeighborhood(params)(dispatch),
  showLoading: () => showLoading()(dispatch),
  hideLoading: () => hideLoading()(dispatch),
  getCountries: (params) => locationThunks.getCountries(params)(dispatch),
  getStates: (params) => locationThunks.getStates(params)(dispatch),
  getCities: (params) => locationThunks.getCities(params)(dispatch),
  getNeighborhoods: (params) =>
    locationThunks.getNeighborhoods(params)(dispatch),
  handleChangeCep: (cep, params) => handleChangeCep(cep, params)(dispatch),
  handleSetPov: (pov) => handleSetPov(pov)(dispatch),
  handleSetPosition: (position) => handleSetPosition(position)(dispatch),
  openModalLocationAdd: (params) => openModalLocationAdd(params)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Location);
