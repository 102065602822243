import React, { createContext, useCallback, useMemo } from 'react';
import { useRequest } from 'hooks/useRequest';
import * as portalsService from 'services/settings/portals';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useDispatch } from 'react-redux';
import { openModalPortal } from 'modules/modal';
import { useGuru } from 'pages/Portals/hooks/useGuru';

export const PortalsContext = createContext({
  portals: {
    connected: [],
    disconnected: [],
  },
  guru: {
    isConnected: null,
    disconnect: () => null,
    connect: () => null,
  },
  isFetching: null,
  connect: () => null,
  disconnect: () => null,
  add: () => null,
  update: () => null,
  fetchData: () => null,
  remove: () => null,
});

export const PortalsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const guru = useGuru();

  const {
    data,
    fetchData,
    remove: removeData,
    isFetching,
  } = useRequest({
    request: portalsService.getList,
  });

  /**
   * Separa a lista de portais em conectados/disconectados
   */
  const portals = useMemo(() => {
    return data.reduce(
      (acc, portal) => {
        if (!!portal?.portal_real_estate?.id) {
          acc.connected = [...acc.connected, portal];

          return acc;
        }

        acc.disconnected = [...acc.disconnected, portal];

        return acc;
      },
      { connected: [], disconnected: [] }
    );
  }, [data]);

  /**
   * Remove um portal
   * @type {function({id?: *}): void}
   */
  const remove = useCallback(
    ({ id, name }) =>
      () => {
        dispatch(
          openConfirmation({
            title: `Excluir o portal ${name}`,
            text: 'Ao excluir o portal, todos os imóveis marcados serão desmarcados.',
            submitButtonText: 'Excluir',
            request: () => portalsService.deletePortal(id),
            onSuccess: () => {
              removeData(id, 'id');
            },
          })
        );
      },
    []
  );

  /**
   * Desconecta um portal
   * @type {function(*): void}
   */
  const disconnect = useCallback(
    (portal) => () => {
      dispatch(
        openConfirmation({
          title: `Desconectar do portal ${portal.name}`,
          text: 'Ao desconectar o portal, todos os imóveis marcados serão desmarcados.',
          submitButtonText: 'Desconectar',
          request: () =>
            portalsService.disableIntegration(portal?.portal_real_estate?.id),
          onSuccess: () => {
            fetchData();
          },
        })
      );
    },
    []
  );

  /**
   * Conectar
   * @type {function(*): function()}
   */
  const connect = useCallback(
    (portal) => () => {
      dispatch(
        openModalPortal({
          portal,
          onSubmitSuccess: () => {
            fetchData();
          },
        })
      );
    },
    []
  );

  return (
    <PortalsContext.Provider
      value={{
        portals,
        isFetching,
        fetchData,
        remove,
        disconnect,
        connect,
        guru,
      }}
    >
      {children}
    </PortalsContext.Provider>
  );
};
