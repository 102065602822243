import React from 'react';
import { Field } from 'redux-form';
// Components
import { Checkbox } from 'components/Form';

const IsDeededField = () => (
  <Field
    type="checkbox"
    label="Imóveis escriturados?"
    name="is_deeded"
    component={Checkbox}
    parse={value => {
      if (!value) return null;
      return value;
    }}
  />
);

export default IsDeededField;
