import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
// Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
// Services
import * as locationService from 'services/location';
// Hooks
import { useRequest } from 'hooks/useRequest';

export default function ModalCityStore({
  id,
  onSubmitSuccess,

  modalConfig,
  isOpen,
  modalType,
  handleClose,
}) {
  const { data: initialValues } = useRequest({
    request: locationService.getCityByIdOnly,
    params: [id],
  });

  const isEditting = useMemo(() => !!id, [id]);

  const handleSubmit = useCallback((values) => {
    return locationService.updateCity(values);
  }, []);

  const handleSubmitSuccess = useCallback((...rest) => {
    if (onSubmitSuccess) onSubmitSuccess(...rest);
    handleClose();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={isEditting ? 'Editar Cidade' : 'Cadastrar Cidade'}
        handleClose={handleClose}
      >
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}
