import useFormValue from 'hooks/useFormValue';
import { useDispatch } from 'react-redux';
import useReduxForm from 'hooks/useReduxForm';
import { useContext, useCallback } from 'react';
import { UploadImageContext } from 'components/ModalImage/context';

/**
 * Hook para facilitar o processo de upload de formulário
 * @param name
 * @param nameFileUrl
 * @param cropperOptions
 * @param onUpload
 * @returns {{isOpen: null, options: {onSubmit: null, onCancel: null, imageUrl: null, cropperOptions: {aspectRatio: null, cropBoxResizable: boolean}, onSuccess: null}, setOptions: null, fileUrl: *, close: null, open: (function(): void), remove: (function(): void)}}
 */
export const useImageUpload = ({
  name,
  nameFileUrl,
  cropperOptions,
  onUpload
}) => {
  // Url do preview da imagem setada no formulário
  const fileUrl = useFormValue(nameFileUrl);
  const dispatch = useDispatch();
  const { change } = useReduxForm();

  // Pega o contexto aonde é armazenado as informações da modal de upload
  const { open, close, options, setOptions, isOpen } = useContext(
    UploadImageContext
  );

  /**
   * Funcão que abre o upload de imagem
   * @type {function(): void}
   */
  const handleOpen = useCallback(() => {
    open({
      cropperOptions,
      onSubmit: ({ imagePreview, file, dataUrl }) => {
        // Muda o campo que tem o blob da imagem
        dispatch(change(name, file));

        // Muda o campo que tem o preview da imagem
        dispatch(change(nameFileUrl, dataUrl));

        // Verifica se tem uma funcão para upload da imagem
        if (onUpload) onUpload({ imagePreview, file, dataUrl });
      }
    });
  }, [cropperOptions, dispatch, change, open, onUpload]);

  /**
   * Funcão que lida com a exclusão da imagem
   * @type {function(): void}
   */
  const handleRemove = useCallback(
    e => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      // Remove o blob
      dispatch(change(name, null));

      // Remove o preview da imagem
      dispatch(change(nameFileUrl, null));
    },
    [dispatch, change, name, nameFileUrl]
  );

  return {
    isOpen,
    fileUrl,
    open: handleOpen,
    close,
    remove: handleRemove,
    options,
    setOptions
  };
};
