import React from 'react';
import { connect } from 'react-redux';
// Helpers
// Modules
import { closeModal } from 'modules/modal';
// Components
import Button from 'components/Button';
import SearchProfile from 'containers/ModalRoot/ModalPerson/components/SearchProfile/components/SearchProfile';
import ListInfo from 'components/ListInfo';

import { CityList } from './styles';

const propTypes = {};
const defaultProps = {};

const Header = ({ data, isRemoving, handleClickDelete, closeModal }) => {
  const addreses = data.addresses;

  return (
    <ListInfo.header
      renderTitle={() => (
        <div>
          <ListInfo.title>
            {data.title}
            {data.transaction === 1 ? ' à Venda' : ' para Aluguel'}&nbsp;-&nbsp;
            <span className="h-color--success">{data.price_range}</span>
          </ListInfo.title>
          <ListInfo.text>
            <CityList>
              {addreses.map((address) => {
                return (
                  <div>
                    {address?.city?.state?.acronym} - {address?.city?.name}
                    <SearchProfile
                      personId={data?.people_id}
                      profileId={data?.id}
                      color="caution"
                      size="small"
                      values={{
                        by_state_id: address?.city?.state?.id,
                        by_city_id: address?.city?.id,
                      }}
                      cityId={address?.city?.id}
                    />
                  </div>
                );
              })}
            </CityList>
          </ListInfo.text>
        </div>
      )}
    >
      {handleClickDelete && (
        <Button
          disable={isRemoving}
          color="danger"
          onClick={() => handleClickDelete(data)}
        >
          Remover
        </Button>
      )}
      {data.people_id && data.id && handleClickDelete && (
        <Button
          to={`/profile/update/${data.people_id}/${data.id}`}
          onClick={closeModal}
        >
          Editar
        </Button>
      )}
    </ListInfo.header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default connect(null, { closeModal })(Header);
