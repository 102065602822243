import styled from 'styled-components';

export const Wrapper = styled.form`
  .Container {
    padding: 0 30px 30px;
    background: #fff9ec;
  }

  .FormBox__title {
    color: #8d858e;
  }

  .has-group-next {
    margin-top: 23px;
  }

  .ListOptions__wrapper {
    margin: -30px;
    background-color: transparent;

    .ListOptions__item {
      padding: 20px 30px;
      background-color: transparent;
    }
  }
`;
