import TextOverflow from 'components/TextOverflow';
import { Td } from './styles';

function Item({ user }) {
  const censoredName = (str, maxUncensored, replace, num) => {
    if (str.length <= maxUncensored) return str;
    return str.slice(0, maxUncensored) + replace.repeat(num);
  };

  const getUserName = () => {
    try {
      return user.user
        .split(' ')
        .map((item, index) =>
          index === 0 ? item : censoredName(item, 1, 'x', 3)
        )
        .join(' ');
    } catch {
      return '--';
    }
  };

  const getUserEmail = () => {
    try {
      const splitEmail = user.email.split('@');
      const uncensoredDomain = splitEmail[1].split('.');
      const email = [splitEmail[0], ...uncensoredDomain];

      const name = censoredName(email[0], 2, 'x', 3);
      const domain = censoredName(email[1], 3, 'x', 3);

      return [name, '@', domain, '.', email.slice(2).join('.')].join('');
    } catch {
      return '--';
    }
  };

  const getCellphone = () => {
    try {
      return user.cellphone
        .split('-')
        .map((item, index) =>
          index === 0 ? censoredName(item, 6, 'x', 3) : item
        )
        .join('-');
    } catch {
      return '--';
    }
  };

  return (
    <tr>
      <Td>
        <TextOverflow width={150}>{user?.created_at}</TextOverflow>
        <br />
        {user?.serial}
      </Td>
      <Td>
        <TextOverflow width={200}>{user?.real_estate}</TextOverflow>
        <br />
        {getUserName()}
      </Td>
      <Td>
        {getCellphone()}
        <br />
        {getUserEmail()}
      </Td>
      <Td>{user?.status}</Td>
      <Td>
        <TextOverflow width={200}>{user?.payment_status}</TextOverflow>
        <br />
        {user?.payment_at}
      </Td>
    </tr>
  );
}

export default Item;
