import React from 'react';
// import { Wrapper } from './styles';

function Head() {
  return (
    <tr>
      <th width={200}>
        Nome
        <br />
        Vinculados
      </th>
      <th width={1}>
        Celular <br />
        E-mail
      </th>
      <th>
        Corretor responsável
        <br />
        Última interação
      </th>
      <th colSpan={2}>
        Categoria
        <br />
        Origem
      </th>
    </tr>
  );
}

export default Head;
