import Label from 'components/Label';
import { STATUS_CRECI } from 'constants/constants';
import { plural } from 'lib/text';
import { openModalCreci } from 'modules/modal';
import { useEffect } from 'react';
import { MdCancel, MdOutlineAccessTime, MdOutlineCheck } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-tooltip';
import { Wrapper } from './styles';
import { currentUserSelector } from 'modules/login';

function CreciCell({ user }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  const handleOpenModalCreci = (e) => {
    e.preventDefault();
    dispatch(openModalCreci({ userId: user.id }));
  };

  if (user.realtor === false) return <Wrapper />;

  // Verifica se foi bloqueado pelo sistema
  // if (user.creci_status === STATUS_CRECI.BLOCKED_BY_SYSTEM) {
  if (user.creci_status === STATUS_CRECI.BLOCKED_BY_SYSTEM) {
    return (
      <Wrapper>
        <div>
          <Label color="caution">
            <MdCancel /> Não validado
          </Label>
        </div>
        <a
          href="./"
          onClick={handleOpenModalCreci}
          className="h-color--secondary"
        >
          Verificar CRECI
        </a>
      </Wrapper>
    );
  }

  if (user.creci_status === STATUS_CRECI.BLOCKED_BY_SUPPORT) {
    return (
      <Wrapper>
        <div>
          <Label
            color="danger"
            data-tip="O usuário foi bloqueado pois foram <br /> encontrados erros em seus documentos. <br /> Em caso de dúvidas, contate o suporte"
          >
            <MdCancel /> Bloqueado
          </Label>
        </div>
        <a
          href="./"
          onClick={handleOpenModalCreci}
          className="h-color--secondary"
        >
          Verificar CRECI
        </a>
      </Wrapper>
    );
  }

  if (user.creci_status === STATUS_CRECI.WAITING) {
    return (
      <Wrapper>
        <div>
          <Label
            color="caution"
            data-tip="Os dados do usuário foram enviados <br /> para o suporte e estão sob análise"
          >
            <MdOutlineAccessTime /> Aguardando aprovação
          </Label>
        </div>
      </Wrapper>
    );
  }

  // Se a imobiliaria não precisa verificar então só mostra a opção de verificar creci
  if (
    currentUser.real_estate?.creci_to_verify === false &&
    user.creci_status !== STATUS_CRECI.VERIFIED
  ) {
    return (
      <Wrapper>
        <a
          href="./"
          onClick={handleOpenModalCreci}
          className="h-link h-color--secondary"
        >
          Verificar CRECI
        </a>
      </Wrapper>
    );
  }

  if (user.creci_status === STATUS_CRECI.NOT_VERIFIED) {
    return (
      <Wrapper>
        <div>
          <Label
            color="primary"
            data-tip="O usuário será bloqueado <br /> caso não verifique o CRECI."
          >
            <MdOutlineAccessTime /> Restam{' '}
            {user?.creci_expiration
              ? `(${plural(user?.creci_expiration, 'dia', 'dias')})`
              : null}
          </Label>
        </div>
        <a
          href="./"
          onClick={handleOpenModalCreci}
          className="h-link h-color--secondary"
        >
          Verificar CRECI
        </a>
      </Wrapper>
    );
  }

  if (user.creci_status === STATUS_CRECI.VERIFIED) {
    return (
      <Wrapper>
        <div>
          <Label color="success" data-tip="Usuário verificado">
            <MdOutlineCheck /> Verificado
          </Label>
        </div>
        <p>CRECI {user.creci}</p>
      </Wrapper>
    );
  }

  return <Wrapper />;
}

export default CreciCell;
