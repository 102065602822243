import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// Services
import * as propertiesService from '../../../services/properties';
// Components
import PropertyDuplicateForm from './components/PropertyDuplicateForm';
import { openModalProperty } from '../../../modules/modal';
import { ModalTemplate, ModalFooter } from 'components/Modal';
import Button from 'components/Button';

const propTypes = {
  handleSubmit: PropTypes.func,
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const defaultProps = {
  handleSubmit: () => {},
};

class ModalPropertyDuplicate extends Component {
  state = {
    reference: '',
    isSuccess: false,
  };

  componentDidMount() {
    propertiesService.getReference().then(({ data }) => {
      this.setState({ reference: data.reference });
    });
  }

  handleClose = () => {
    this.props.handleClose();
  };

  redirectToProperty = (id) => {
    this.props.history.push(`/properties/update/informations/${id}`);
  };

  handleSuccess = (res) => {
    this.redirectToProperty(res.data.id);
    this.handleClose();
  };

  handleSubmit = (values) => {
    const { id: propertyId } = this.props.property;

    return propertiesService
      .cloneProperty(propertyId, values)
      .then(this.handleSuccess);
  };

  render() {
    const { modalConfig, isOpen, modalType, property } = this.props;

    if (this.state.isSuccess) {
      return (
        <Modal
          {...modalConfig}
          isOpen={isOpen}
          contentLabel={modalType}
          onRequestClose={this.handleClose}
          style={{
            content: {
              maxWidth: '305px',
            },
          }}
        >
          <ModalTemplate title="Imóvel duplicado">
            As fotos do imóvel estão sendo duplicadas em segundo plano. Pode
            levar alguns minutos até que todas as fotos sejam duplicadas.
            <ModalFooter>
              <span className="h-flex__cell--grow" />
              <Button type="button" onClick={this.handleClose} color="success">
                Entendi
              </Button>
            </ModalFooter>
          </ModalTemplate>
        </Modal>
      );
    }

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={this.handleClose}
        style={{
          content: {
            maxWidth: '305px',
          },
        }}
      >
        <PropertyDuplicateForm
          property={property}
          initialValues={{
            ...this.state,
            old_reference: property.reference,
          }}
          form="modalPropertyDuplicate"
          onSubmit={this.handleSubmit}
          handleClose={this.handleClose}
        />
      </Modal>
    );
  }
}

ModalPropertyDuplicate.propTypes = propTypes;
ModalPropertyDuplicate.defaultProps = defaultProps;

export default connect(null, { openModalProperty })(
  withRouter(ModalPropertyDuplicate)
);
