import React from 'react';
import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
import * as propertiesBooking from 'services/propertiesBooking';
import { openModalProperty } from 'modules/modal';
import { useDispatch } from 'react-redux';

function ModalReservationRemove({
  property,
  reservation,
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  afterClose,
  onSubmitSuccess,
}) {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    return propertiesBooking.remove({
      property_id: property.id,
      id: reservation.id,
      ...values,
    });
  };

  const close = () => {
    handleClose();
    if (onSubmitSuccess) {
      return onSubmitSuccess();
    }

    dispatch(
      openModalProperty({ initialPage: 'Reservations', property, afterClose })
    );
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={close}
    >
      <ModalTemplate title="Remover reserva" handleClose={close}>
        <Form
          reservation={reservation}
          onSubmit={handleSubmit}
          handleClose={close}
          onSubmitSuccess={() => {
            if (onSubmitSuccess) return onSubmitSuccess();

            dispatch(
              openModalProperty({
                initialPage: 'Reservations',
                property,
                afterClose,
              })
            );
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalReservationRemove;
