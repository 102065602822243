import React, { useEffect, useMemo } from 'react';
import { reduxForm } from 'redux-form';

// Components
import MainTitle from 'components/MainTitle';
import SeeExample from '../../components/SeeExample';
import FormFixedBar from '../../components/FormFixedBar';
import Reception from '../../components/Reception';
import FieldImageModal from 'components/Form/components/FieldImageModal';

// Services
import { get, update, remove as removeImage } from 'services/buildings/header';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Context
import { useEnterprise } from 'pages/EnterpriseStore/context';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';
import Loading from 'components/Loading';

// Assets
import iconImage from 'assets/img/icon-no-image.svg';
import reception from './reception-image.png';
import useFormValue from 'hooks/useFormValue';
import { useImageUpload } from 'hooks/useImageUpload';
import Button from 'components/Button';
import { FieldBool } from 'components/Form';
import UploadButton from '../../../../components/UploadButton';

const blobToDataURL = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(blob);
  });
};

const Header = ({ handleSubmit, initialize, change }) => {
  const { open } = useImageUpload({
    name: 'image',
    nameFileUrl: 'file_url',
    onUpload: () => {},
  });

  const fileUrl = useFormValue('file_url');
  const image = useFormValue('image');

  const { enterpriseId } = useEnterprise();
  const { data, fetchData, isFetching } = useRequest({
    request: get,
    params: enterpriseId,
    initialState: {},
  });

  const hasImage = useMemo(() => {
    return !!fileUrl || !!image;
  }, [fileUrl, image]);

  useEffect(() => {
    initialize(data);
  }, [data]);

  if (isFetching) {
    return <Loading isVisible isFullScreen />;
  }

  const onDropAccepted = async (files) => {
    const file = files[0];

    // Muda o campo que tem o blob da imagem
    change('image', file);

    // Convert the file to a data URL
    const dataUrl = await blobToDataURL(file);

    // Use the data URL as needed
    change('file_url', dataUrl);
  };

  if (!hasImage) {
    return (
      <form onSubmit={handleSubmit}>
        <Reception
          image={reception}
          title="Imagem principal"
          text="Não perca a oportunidade de causar uma boa primeira impressão. Esta deve ser a principal foto que representa este imóvel/empreendimento."
        >
          <UploadButton onDropAccepted={onDropAccepted}>
            <Button>Enviar imagem</Button>
          </UploadButton>
          <p className="h-margin-top--10">
            <em>Tamanho recomendado: 1920x1080px</em>
          </p>
          <p className="h-margin-top--5">
            <em>Tipo de arquivo: JPG, PNG, JPEG</em>
          </p>
        </Reception>
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Imagem principal"
        text="Esta deve ser a principal foto que representa este imóvel/empreendimento."
      >
        <div className="Form--inline">
          <FieldBool label="Topo fullscreen?" name="is_top_full_screen" />
          <SeeExample />
        </div>
      </MainTitle>

      <FieldImageModal
        hasRemove
        aspectRatio={[1920, 1080]}
        size="original"
        name={`image`}
        nameFileUrl={`file_url`}
        text={
          <div>
            <img src={iconImage} alt="" className="h-margin-bottom--10" />
            Envie uma imagem
            <br />
            para a capa da sua página.
          </div>
        }
        recommended="1920x1080px"
        handleRemove={async (e) => {
          e.stopPropagation();
          await removeImage(enterpriseId);
          fetchData();
        }}
      />

      <FormFixedBar />
    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: update,
})(Header);
