import React from 'react';
// Components
import MainTitle from 'components/MainTitle';
import Container from 'components/Container';
import Wrapper from 'components/Wrapper';
import TableWon from './components/TableWon';
import { Field } from 'redux-form';
import { Select } from 'components/Form';
import ChartPeriodFormNew from 'pages/Reports/components/ChartPeriodFormNew';
import FieldSelectUser from 'containers/FieldSelectUser';
import { useRequest } from 'hooks/useRequest';
import * as reportsService from 'services/reports';
import moment from 'moment';

const defaultProps = {};
const propTypes = {};

function DealWon() {
  const { data, fetchData } = useRequest({
    request: reportsService.getWonDeals,
    initialFetch: false,
  });

  return (
    <Wrapper.container>
      <MainTitle
        title="Negócios Ganhos"
        text="O histórico de todos os seus negócios ganhos."
      >
        <ChartPeriodFormNew
          initialValues={{
            transaction: 1,
            start_date: moment().subtract(3, 'month').format('DD/MM/YYYY'),
            end_date: moment().format('DD/MM/YYYY'),
          }}
          onChange={fetchData}
        >
          <FieldSelectUser
            name="user_id"
            className="h-margin-right--15"
            style={{ width: 180 }}
          />
          <Field
            label="Transação"
            name="transaction"
            component={Select}
            options={[
              { label: 'Venda', value: 1 },
              { label: 'Locação', value: 2 },
              { label: 'Ambos', value: 3 },
            ]}
            style={{ width: '150px', marginRight: '20px' }}
          />
        </ChartPeriodFormNew>
      </MainTitle>
      <Container padding>
        <TableWon data={data} />
      </Container>
    </Wrapper.container>
  );
}

DealWon.defaultProps = defaultProps;
DealWon.propTypes = propTypes;

export default DealWon;
