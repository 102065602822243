import React from 'react';
import Popover from 'components/Popover';
import { MdEdit } from 'react-icons/md';
import { BoxTable } from 'pages/PlanConfig/components/Box';
import { useCurrentUser } from 'hooks/useCurrentUser';
import FormRecurringCharge from '../FormRecurringCharge';

// import { Wrapper } from './styles';

function RecurringCharge() {
  const currentUser = useCurrentUser();
  const realEstate = currentUser?.real_estate;

  return (
    <BoxTable className="h-margin-top--5">
      <tbody>
        <tr>
          <td>Cobrança automática</td>
          <td>
            <strong>
              {realEstate?.recurring_charge ? 'Ativado' : 'Desativado'}
            </strong>
          </td>
          <td className="Table__cell--small">
            <Popover component={FormRecurringCharge}>
              <a href="" className="h-link">
                <MdEdit />
              </a>
            </Popover>
          </td>
        </tr>
      </tbody>
    </BoxTable>
  );
}

export default RecurringCharge;
