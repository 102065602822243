import React from 'react';
import { Box, Table } from 'features/Financial/features/Reports/components/Box';
import { Item } from './components/Item';

export function BoxData({ data }) {
  if (data?.length <= 0) return null;

  return (
    <Box.Wrapper>
      <Box.Inner>
        <Box.Title>Detalhamento</Box.Title>
        <Box.Content>
          <Table.Wrapper>
            <thead>
              <tr>
                <th>Período</th>
                <th width={130} align="right">
                  Receitas
                </th>
                <th width={130} align="right">
                  Despesas
                </th>
                <th width={130} align="right">
                  Resultado
                </th>
                <th width={130} align="right">
                  Saldo
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <Item data={item} />
              ))}
            </tbody>
          </Table.Wrapper>
        </Box.Content>
      </Box.Inner>
    </Box.Wrapper>
  );
}
