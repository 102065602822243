import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MdBuild, MdPeople, MdPerson, MdWarning } from 'react-icons/md';
import { IoMdHelp } from 'react-icons/io';
// Constants
import { TYPES } from 'pages/Help/constants';
import MenuHeader, { Item } from 'components/MenuHeader';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';

function MenuHelp({ faqUrl, handleClickSupport }) {
  const realEstate = useCurrentRealEstate();

  const canScheduleTraining = useMemo(() => {
    return realEstate.can_schedule_training;
  }, [realEstate]);

  return (
    <MenuHeader
      to="#"
      className="Menu__item js-wizard-ajuda"
      title="Ajuda"
      active="help"
    >
      <Item
        title="Falar com Suporte"
        text="Nossos dados de contato"
        icon={MdBuild}
        iconColor="success"
        onClick={handleClickSupport}
      />
      {faqUrl && (
        <Item
          href={faqUrl}
          target="_blank"
          title="Central de ajuda"
          text="Manual de uso com imagens e vídeos"
          icon={IoMdHelp}
          iconColor="primary"
        />
      )}
      {canScheduleTraining && (
        <Item
          href="https://tecimob.com.br/ajuda/agendamentos/"
          target="_blank"
          title="Agendar treinamento"
          text="Agende uma reunião com nosso time"
          icon={MdPeople}
          iconColor="tertiary"
        />
      )}
      <Item
        to={`/help/${TYPES.SUGESTION}/list`}
        title="Sugerir novos recursos"
        text="Nos ajude a melhorar"
        icon={MdPerson}
        iconColor="secondary"
      />
      <Item
        to={`/help/${TYPES.PROBLEM}/list`}
        title="Relatar um problema"
        text="Informe um problema de uso"
        icon={MdWarning}
        iconColor="danger"
      />
    </MenuHeader>
  );
}

MenuHelp.defaultProps = {
  faqUrl: null,
  handleClickSupport: null,
};

MenuHelp.propTypes = {
  faqUrl: PropTypes.string,
  handleClickSupport: PropTypes.func,
};

export default MenuHelp;
