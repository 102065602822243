import React from 'react';
import NavAside from 'components/NavAside';
import Wrapper from 'components/Wrapper';
import Routes from './Routes';
import {
  MdCallToAction,
  MdDesktopWindows,
  MdHome,
  MdImage,
  MdOtherHouses,
  MdOutlineSmartphone,
  MdPalette,
  MdSearch,
  MdWaterDrop,
} from 'react-icons/md';
import { useRouteMatch } from 'react-router';
import { IoMdGlobe } from 'react-icons/all';
import styled from 'styled-components';
import Can from 'containers/Can';
// import { Container } from './styles';

const IconHeader = styled(MdCallToAction)`
  font-size: 18px;
  rotate: 180deg;
`;

const Config = () => {
  const { path } = useRouteMatch();
  return (
    <Wrapper full>
      <NavAside>
        <NavAside.link icon={MdPalette} to={`${path}/style/apparence`}>
          Aparência
        </NavAside.link>
        <NavAside.group icon={() => <IconHeader />} text="Topo">
          <NavAside.item
            icon={MdDesktopWindows}
            to={`${path}/style/header/desktop`}
          >
            Computador
          </NavAside.item>
          <NavAside.item
            icon={MdOutlineSmartphone}
            to={`${path}/style/header/mobile`}
          >
            Celular
          </NavAside.item>
        </NavAside.group>
        <NavAside.link icon={MdImage} to={`${path}/style/logo`}>
          Logo
        </NavAside.link>
        <Can run="EDIT_WATERMARK">
          <NavAside.link icon={MdWaterDrop} to={`${path}/style/watermark`}>
            Marca d'água
          </NavAside.link>
        </Can>
        <NavAside.link icon={IoMdGlobe} to={`${path}/style/menu`}>
          Menu/Páginas
        </NavAside.link>
        <NavAside.group icon={MdSearch} text="Pesquisa">
          <NavAside.item
            icon={MdDesktopWindows}
            to={`${path}/style/search/desktop`}
          >
            Computador
          </NavAside.item>
          <NavAside.item
            icon={MdOutlineSmartphone}
            to={`${path}/style/search/mobile`}
          >
            Celular
          </NavAside.item>
        </NavAside.group>
        <NavAside.link icon={MdOtherHouses} to={`${path}/style/properties`}>
          Imóveis
        </NavAside.link>
        <NavAside.group icon={MdImage} text="Banner rodapé">
          <NavAside.item
            icon={MdDesktopWindows}
            to={`${path}/style/banner/desktop`}
          >
            Computador
          </NavAside.item>
          <NavAside.item
            icon={MdOutlineSmartphone}
            to={`${path}/style/banner/mobile`}
          >
            Celular
          </NavAside.item>
        </NavAside.group>
        <NavAside.link icon={MdCallToAction} to={`${path}/style/footer`}>
          Rodapé
        </NavAside.link>
        <NavAside.link icon={MdHome} to={`${path}/style/property_detail`}>
          Layout do imóvel
        </NavAside.link>
      </NavAside>
      <Wrapper.inner>
        <Wrapper.container style={{ paddingBottom: '50px' }}>
          <Routes />
        </Wrapper.container>
      </Wrapper.inner>
    </Wrapper>
  );
};

export default Config;
