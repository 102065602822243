import React from 'react';
import NavAsideLink from './components/NavAsideLink';
import NavGroup from './components/NavGroup';
import GroupItem from './components/GroupItem';

const propTypes = {};

const defaultProps = {};

const NavAside = ({ children }) => (
  <aside className="Aside NavAside">{children}</aside>
);

NavAside.propTypes = propTypes;
NavAside.defaultProps = defaultProps;
NavAside.link = NavAsideLink;
NavAside.group = NavGroup;
NavAside.item = GroupItem;

export default NavAside;
export { NavAsideLink, NavGroup, GroupItem };
