import React, { useCallback } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
// Components
import { Input } from '../../../Form';
import Button from '../../../Button';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleToggleEdit: PropTypes.func.isRequired,
};

const FormCaption = ({
  captionName,
  captionInputPlaceholder,
  handleSubmit,
  submitting,
}) => {
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      return handleSubmit(e);
    },
    [handleSubmit]
  );

  return (
    <form onSubmit={onSubmit}>
      <Row>
        <Field
          autoFocus
          xs={12}
          label="Legenda da imagem"
          name={captionName}
          placeholder={captionInputPlaceholder}
          component={Input}
        />
      </Row>
      <div className="h-flex">
        <Button
          type="button"
          onClick={handleSubmit}
          block
          color="success"
          disabled={submitting}
        >
          Salvar legenda
        </Button>
      </div>
    </form>
  );
};

FormCaption.defaultProps = defaultProps;
FormCaption.propTypes = propTypes;

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FormCaption);
