import React from 'react';
import Loading from 'components/Loading';
import Container from 'components/Container';
// import { Container } from './styles';

const Content = ({ isFetching, children, ...props }) => {
  if (isFetching)
    return (
      <Container {...props}>
        <Loading isVisible isBlock />
      </Container>
    );
  return <Container {...props}>{children}</Container>;
};

export default Content;
