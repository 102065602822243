import Wrapper from "components/Wrapper";
import Routes from "./Routes";
import Aside from "./components/Aside";
import { AsideProvider } from "./contexts/aside";
import { IdProvider } from "./contexts/id";

const RegionPostStore = () => {
  return (
    <IdProvider>
      <AsideProvider>
        <Wrapper full>
          <Aside />
          <Wrapper.inner>
            <Wrapper.container>
              <Routes />
            </Wrapper.container>
          </Wrapper.inner>
        </Wrapper>
      </AsideProvider>
    </IdProvider>
  );
}

export default RegionPostStore;
