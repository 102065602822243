import styled from 'styled-components';

export const Wrapper = styled.ul`
  list-style: none;

  img {
    height: 31px;
  }

  .ButtonGroup button,
  .Button {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
  }
`;
export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  :not(:first-child) {
    border-top: 1px solid ${p => p.theme.borderColor};
  }

  .ButtonGroup__action,
  img {
  }

  img {
    height: 31px;
  }
`;
export const Name = styled.strong`
  line-height: 31px;
  padding: 0 15px;
`;
