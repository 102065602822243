import { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import PricePerArea from './components/PricePerArea';
import TableRooms from './components/TableRooms';
import Value from './components/Value';
import { getAreaByName } from './helpers';
import { Box, Label, Table, Title, Wrapper } from './styles';

const getAreaTotal = (property) => {
  if (property?.areas?.ground_total_area?.value)
    return getAreaByName('ground_total_area', property);
  if (property?.areas?.total_area?.value)
    return getAreaByName('total_area', property);

  return '-';
};

function RoomsAndMeasures({ property }) {
  useEffect(() => {
    Tooltip.rebuild();
  }, [property]);

  return (
    <Wrapper>
      <Box>
        <Title>Cômodos</Title>
        <TableRooms property={property} />
      </Box>
      <Box>
        <Title>Medidas das áreas</Title>
        <Table>
          <tbody>
            <tr>
              <Label>Total:</Label>
              <Value>{getAreaTotal(property)}</Value>
            </tr>
            <tr>
              <Label>Construida:</Label>
              <Value>{getAreaByName('built_area', property)}</Value>
            </tr>
            <tr>
              <Label>Privativa:</Label>
              <Value>{getAreaByName('private_area', property)}</Value>
            </tr>

            <PricePerArea property={property} />
          </tbody>
        </Table>
      </Box>
      <Box>
        <Title>Medidas do terreno</Title>
        <Table>
          <tbody>
            <tr>
              <Label>Frente:</Label>
              <Value>{getAreaByName('front_ground', property)}</Value>
            </tr>
            <tr>
              <Label>Fundo:</Label>
              <Value>{getAreaByName('back_ground', property)}</Value>
            </tr>
            <tr>
              <Label>Esquerda:</Label>
              <Value>{getAreaByName('left_ground', property)}</Value>
            </tr>
            <tr>
              <Label>Direita:</Label>
              <Value>{getAreaByName('right_ground', property)}</Value>
            </tr>
          </tbody>
        </Table>
      </Box>
    </Wrapper>
  );
}

export default RoomsAndMeasures;
