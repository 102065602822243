import React from 'react';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Container from 'components/Container';
import TableDocuments from '../../components/TableDocuments';
import NoContent from 'components/NoContent';
import Button from 'components/Button';
import { compose } from 'redux';
import withReception from 'HOC/withReception';
import documents from './modelo-contrato.svg';

const List = ({ reception, handleClickAction }) => {
  if (reception.isVisible) {
    return (
      <Wrapper.container>
        <NoContent
          image={documents}
          title="Modelos de contrato"
          text={
            <>
              Gerencie todos os modelos de contrato
              <br /> do seu negócio em um só lugar.
            </>
          }
        >
          <Button
            type="button"
            color="secondary"
            colorText="white"
            onClick={handleClickAction}
          >
            Gerenciar Modelos de Contrato
          </Button>
        </NoContent>
      </Wrapper.container>
    );
  }

  return (
    <>
      <MainTitle
        title="Modelos de contrato"
        text="Configure os modelos de contrato do seu negócio."
      />
      <Container padding>
        <TableDocuments />
      </Container>
    </>
  );
};

export default compose(withReception('config/documents', true))(List);
