import { Name, Wrapper } from './styles';

const PaymentMethod = ({ label, value, icon: Icon, input }) => {
  const checked = value === input.value;

  function handleSelectPaymentMethod() {
    input.onChange(value);
  }

  return (
    <Wrapper active={checked} onClick={handleSelectPaymentMethod}>
      <Icon size={22} />
      <Name>{label}</Name>
    </Wrapper>
  );
}

export default PaymentMethod;
