import { useEffect, useState, useCallback, useMemo } from 'react';
import * as dwvService from 'services/integrations/dwv';

export function useDwv() {
  const [dwv, setDwv] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  const hasIntegration = useMemo(() => {
    return !!dwv && !isFetching;
  }, [dwv, isFetching]);

  const fetchDwv = useCallback(async () => {
    try {
      setIsFetching(true);
      const { data } = await dwvService.get();
      setDwv(data);
    } finally {
      setIsFetching(false);
    }
  }, [setDwv, setIsFetching]);

  useEffect(() => {
    fetchDwv();
  }, []);

  return { dwv, hasIntegration, isFetching, fetchDwv };
}
