import React from 'react';
// Components
import ListItem from '../ListItem';

function ListAside({ title, children }) {
  return (
    <div className="ListAside">
      <div className="ListAside__title">{title}</div>
      <div className="ListAside__content">{children}</div>
    </div>
  );
}

ListAside.item = ListItem;
ListAside.propTypes = {};
ListAside.defaultProps = {};

export { ListItem };
export default ListAside;
