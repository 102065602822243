import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import TableAdditionalItems from '../TableAdditionalItems';

const Form = ({ onClose }) => {
  const { hasOrulo, hasDwv, values } = useModalPayment();

  const showUsers = values.additional_users > 0;
  const showMailboxes = values.mailboxes_number > 0;

  const showAdditionalItems = true;

  return (
    <>
      {/*<TablePackage*/}
      {/*  showAdditionalItems={showAdditionalItems}*/}
      {/*  onClose={onClose}*/}
      {/*/>*/}
      <TableAdditionalItems />
    </>
  );
};

export default Form;
