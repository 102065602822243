import React from 'react';
import { connect } from 'react-redux';
import Nodata from 'components/Nodata';
import Button from 'components/Button';
import img from './lock-red.png';
import { getPermission, permissionSelector, resetInfos } from 'modules/personInfos';

class RequestPermission extends React.Component {
  componentWillUnmount() {
    this.props.resetInfos();
  }
  getPermission = () => {
    const { person, getPermission } = this.props;
    getPermission(person.id);
  };

  render() {
    const { hasPermission, person } = this.props;

    if (hasPermission) {
      return (
        <Nodata
          image={img}
          style={{ width: '550px' }}
          position="left"
          title="Aguardando aprovação"
          text={
            <>
              <strong>{person.user.name} </strong>
              ainda não aceitou sua solicitação para acessar os dados.
            </>
          }
        />
      );
    }

    return (
      <Nodata
        style={{ width: '550px' }}
        position="left"
        image={img}
        title="Área restrita"
        text={
          <>
            Essa área não está liberada nas suas permissões. <br />
            Peça autorização ao corretor responsável para acessar.
          </>
        }
      >
        <Button
          color="secondary"
          className="h-margin-top--10"
          onClick={this.getPermission}
        >
          Solicitar acesso
        </Button>
      </Nodata>
    );
  }
}

const mapStateToProps = state => ({
  hasPermission: permissionSelector(state)
});

const mapDispatchToProps = {
  getPermission,
  resetInfos
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestPermission);
