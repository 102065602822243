import Socket from 'lib/Socket';

export default class Notifications extends Socket {
  constructor(accessToken) {
    super(accessToken);

    // Conecta no canal de notificações
    this.connect('notifications');
  }
}
