import styled from 'styled-components';

export const Wrapper = styled.div`
  header {
  }

  h2 {
    font-size: 14px;
  }
`;
export const Number = styled.div``;
export const SmallText = styled.div``;
export const Table = styled.table`
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-spacing: 0;
  border-radius: 4px;

  img {
    max-height: 33px;
  }

  tr td {
    border-top: 1px solid #cccccc;
    text-align: left;
    padding: 10px;
  }

  thead tr th {
    padding: 10px;
    background-color: #e9eef5;
    text-align: left;
  }
`;
