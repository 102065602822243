import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {
  children: null
};

const WrapperInner = ({ children, className, style }) => (
  <div className="Wrapper__inner-wrapper">
    <div className={`Wrapper__inner parent ${className}`} style={style}>
      {children}
    </div>
  </div>
);

WrapperInner.propTypes = propTypes;
WrapperInner.defaultProps = defaultProps;

export default WrapperInner;
