import React, { useCallback } from 'react';
import Alert from 'react-s-alert';
import Modal from 'react-modal';

// Components
import { ModalTemplate } from '../../../components/Modal';
import Form from './components/Form';

// Services
import { updateTicket } from 'services/helpDeskResales';

export default function ModalServiceEvaluation({
  ticketId,
  onSuccess,
  onFinally,

  modalConfig,
  isOpen,
  modalType,
  handleClose
}) {
  const _handleClose = useCallback(() => {
    if (onFinally) onFinally();
    handleClose();
  }, [handleClose, onFinally]);

  const handleSubmit = useCallback(values => {
    return updateTicket({ id: ticketId, ...values });
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={_handleClose}
    >
      <ModalTemplate handleClose={_handleClose} title="Avalie o atendimento">
        <Form
          onSubmit={handleSubmit}
          onSubmitSuccess={() => {
            Alert.success('Obrigado pelo seu feedback.');
            if (onSuccess) onSuccess();
            _handleClose();
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}
